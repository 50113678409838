/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from 'moment'

/**Import material UI Section*/
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CalendarIcon from '@material-ui/icons/CalendarToday';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

/**Import components section*/
import AvatarContainer from './AvatarContainer'
/**Import resources section*/
import { getResponsiblesFromBidding } from '../../../store/helpers/BiddingHelper'
import { IsNullOrEmpty } from "../../../store/helpers/StringHelper";
import { canBeEditBiddingInStatusDraft } from '../../../store/helpers/BiddingHelper';
/**Import actions section */
const ShowDesktopViewRightPanel = (props) => {
    const { classes, tabSelectedIndex } = props;
    /**Arrays props section */
    const { internalUsers, userRolesByCompany } = props
    /**Functions props section */
    const { onOpenCloseEditLateralPanel, translate
    } = props
    /**Objects props section */
    const { userProfile, bidding, isExternalUser } = props

    let allResponsibles = (getResponsiblesFromBidding(internalUsers, bidding ? bidding.Responsibles : [], userProfile))

    /**
  * Get the card content
  *
  * @memberof Index
  */
    let time = (bidding && !IsNullOrEmpty(bidding.PlannedClosingDate)) ? moment(bidding.PlannedClosingDate).format("LTS") : null
    var formattedTime = time ? moment(time, ["HH.mm"]).format("hh:mm a"): "-";
    const getCardContent = () => {
        return (

            tabSelectedIndex === 0 && <Grid item xs={4}>
                <Card className={classes.rightPanel}>
                    <Grid container>


                        <Grid item xs={12}>
                            <Card className={classes.cardRight}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Typography variant='subtitle1' color='primary'>{translate("biddingsModule.create.projectName")}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {canBeEditBiddingInStatusDraft(bidding, userRolesByCompany, userProfile) && <div className={classes.btnEditBidding}>
                                            <Tooltip title={translate("common.edit")}>
                                                <IconButton
                                                    color="primary"
                                                    aria-label="More"
                                                    onClick={() => onOpenCloseEditLateralPanel()}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{bidding ? bidding.Name : ""}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1' color='primary' className={classes.margingTopText}>{translate("biddingsModule.create.category")}</Typography>
                                        <Typography>{bidding ? bidding.CategoryName : ""}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.margingTopText} variant='subtitle1' color='primary'>{translate("biddingsModule.create.projectDescription")}</Typography>
                                        <Typography>{bidding ? bidding.Description : ""}</Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.margingTopText}>
                                        <Typography variant='subtitle1' color='primary'>{translate("biddingsModule.create.closingDateLimit")}</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.margingTopText}>
                                        <div className={classes.flex}>
                                            <CalendarIcon
                                                color="primary"
                                            />
                                            <Typography classes={classes.textIcon}>{(bidding && !IsNullOrEmpty(bidding.PlannedClosingDate)) ? moment(bidding.PlannedClosingDate).format("L") : ""}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className={classes.margingTopText}>
                                        <div className={classes.flex}>
                                            <AccessTimeIcon
                                                color="primary"
                                                className={classes.marginLeft}
                                            />
                                            <Typography classes={classes.textIcon}>{formattedTime}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} className={classes.margingTopText}>
                                        <Typography variant='subtitle1' color='primary'>{translate("biddingsModule.create.publishDate")}</Typography>
                                        <div className={classes.flex}>
                                            <CalendarIcon
                                                color="primary"
                                            />
                                            <Typography classes={classes.textIcon}>{(bidding && !IsNullOrEmpty(bidding.PlannedOpeningDate)) ? bidding.PlannedOpeningDate : ""}</Typography>
                                        </div>
                                    </Grid>
                                    {!isExternalUser &&
                                        <>
                                            <Grid item xs={12} className={classes.margingTopText}>
                                                <Typography variant='subtitle1' color='primary'>{translate("biddingsModule.create.responsibles")}</Typography>

                                            </Grid>
                                            <Grid item xs={12} >
                                                {
                                                    (bidding && bidding.Responsibles) &&
                                                    <Grid container style={{ marginLeft: "-17px" }}>
                                                        {
                                                            allResponsibles.map(user => {
                                                                return (
                                                                    <Grid item xs={1} key={user.user_name} style={{ marginLeft: "9px" }}>
                                                                        <AvatarContainer
                                                                            responsible={user}
                                                                            userProfile={userProfile}
                                                                            canBeRemoveResponsible={false}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                }
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }
    return getCardContent()
};

const styles = (theme) => ({
    rightPanel: {
        maxHeight: `calc(100vh - ${245}px)`,
        minHeight: `calc(100vh - ${245}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    cardRight: {
        padding: "20px",
        maxHeight: `calc(100vh - ${265}px)`,
        minHeight: `calc(100vh - ${265}px)`,
        overflowY: "auto",
    },
    btnEditBidding: {
        marginTop: "-10px",
        display: 'flex'
    },
    margingTopText: {
        marginTop: "5px"
    },
    flex: {
        display: "flex"
    },
    textIcon: {
        marginLeft: "10px",
        marginTop: "5px",
    }
});

ShowDesktopViewRightPanel.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
};

ShowDesktopViewRightPanel.defaultProps = {
    isLoading: false,
};

export default withRouter(
    withStyles(styles, { withTheme: true })(ShowDesktopViewRightPanel)
);
