/** Helpers import section */
import axios from 'axios';
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';

export const CANCEL_WORKFLOWS_QUALITY = "CANCEL_WORKFLOWS_QUALITY";
export const SEND_REMINDER_TO_SUPPLIER = "SEND_REMINDER_TO_SUPPLIER";
export const IS_SAVING = "IS_SAVING";
export const IS_CANCEL = "IS_CANCEL";
export const IS_ACTIVE_WORKFLOW = "IS_ACTIVE_WORKFLOW";

/**
 * Action to is saving
 * @param {*} isSaving 
 */
function isSaving(isSaving) {
    return {
        type: IS_SAVING,
        isSaving
    }
}

function isCancel(isCancel) {
    return {
        type: IS_CANCEL,
        isCancel
    }
}

function isActive(isActive) {
    return {
        type: IS_ACTIVE_WORKFLOW,
        isActive
    }
}


/**
 * Action to cancel a workflows document of development
 *
 * @export
 * @param {*} token
 * @returns
 */
export function cancelWorflowFromBackend(token, cancelComment, razonForCancel) {
    return function (dispatch, getState) {
        dispatch(isCancel(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let user = getState().oidc.user;

        let data = {
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            WorkflowToken:token,
            CancellationComment: cancelComment,
            ReasonForCancellation:razonForCancel
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/workflow/cancel`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isCancel(false))
                    return Promise.resolve()
                    
                }
            })
            .catch((error) => {
                dispatch(isCancel(false))
                console.error("Error to cancel a workflows", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to cancel a workflows document project from quality
 *
 * @export
 * @param {*} token
 * @returns
 */
export function cancelWorflowQualityFromBackend(token, cancelComment, razonForCancel) {
    return function (dispatch, getState) {

        dispatch(isSaving(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let user = getState().oidc.user;

        let data = {
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            WorkflowToken:token,
            CancellationComment: cancelComment,
            ReasonForCancellation:razonForCancel
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/workflow/cancel/quality`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve(serverResponse);

                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to cancel a workflows document", error.response)
                return Promise.reject();
            }
        )
    }
}

/**
 * Action send reminder to supplier
 * @param {*} token 
 * @param {*} comment 
 */
export function sendReminderToSupplier(token, comment) {
    return function (dispatch, getState) {

        dispatch(isSaving(true))
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let user = getState().oidc.user;

        let data = {
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            Token: token,
            Comment: comment
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/workflow/send`;
        return axios.post(endPoint, data,  configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error send notification to supplier", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to close workflow manually a workflows from quality
 *
 * @export
 * @param {*} token
 * @returns
 */
export function closedWorkflowManuallyFromBackend(token) {
    return function (dispatch, getState) {

        dispatch(isSaving(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let data = {}
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/workflow/${token}/qualityclose`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve(serverResponse);

                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to close a workflows document", error.response)
                return Promise.reject();
            }
        )
    }
}

/**
 * Action to active workflow
 * @param {*} token 
 * @param {*} commnents 
 */
export function reactiveWorkflowDocument(token, commnents) {
    return function (dispatch, getState) {

        dispatch(isActive(true))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let data = {
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            WorkflowToken: token,
            Comments: commnents
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/workflow/reactive`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isActive(false))
                    return Promise.resolve(serverResponse.data);
                }
            })
            .catch((error) => {
                dispatch(isActive(false))
                console.error("Error in active a workflows document", error.response)
                return Promise.reject();
            }
        )
    }
}