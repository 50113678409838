import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';

/** Material UI import section */
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    ListItemSecondaryAction,
    Chip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import SettingsIcon from '@material-ui/icons/Settings';

/** Resources import section */
import { BACKGROUND_COLOR_GRAY_BIDDING } from '../../../../store/helpers/StatusColorConstants';
import PopperListsComponent from '../PopperListsComponent'
import {
    canBeEditBiddingInStatusDraft, canBeResendEmail, descriptionProposalItem,
    colorStatusProposal
} from '../../../../store/helpers/BiddingHelper';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { BiddingSuppliers } from '../../../../store/helpers/AppConstants'
import { Cancel } from '@material-ui/icons';
import CardUpdateContact from '../suppliers/CardUpdateContact';

const ListSuppliers = props => {

    const { classes, suppliers, isSaving,
        userRolesByCompany, bidding, userProfile, translate, onSendEmailConfigPanel
    } = props
    const onRemoveSupplier = (supplier) => {
        if (props.onDeletedSupplierBiddingFromBackEnd) {
            props.onDeletedSupplierBiddingFromBackEnd(supplier)
        }
    }
    const onCancelSupplier = (status, biddingId, comments, supplierId) => {
        if (props.onCancelSupplierBiddingFromBackEnd) {
            props.onCancelSupplierBiddingFromBackEnd(status, biddingId, comments, supplierId)
        }
    }

    const onUpdateContactEmail = (itemModel) => {
        if (props.onUpdateContactEmailFromBackEnd) {
            props.onUpdateContactEmailFromBackEnd(itemModel)
        }
    }

    const [openModalContact, setOpenModalContact] = useState({ openModal: false, edit: false, delete: false })

    const onGetDescription = (supplier) => {
        let description
        if (!IsNullOrEmpty(supplier.Code) && supplier.Type === BiddingSuppliers.INTERNAL) {
            description = `${supplier.Code}`
        }
        if (!IsNullOrEmpty(supplier.Name) && supplier.Type === BiddingSuppliers.INTERNAL) {
            description = description.concat(` | ${supplier.Name}`)
        }
        if (!IsNullOrEmpty(supplier.Name) && supplier.Type === BiddingSuppliers.EXTERNAL) {
            description = `${supplier.Name}`
        }
        if (!IsNullOrEmpty(supplier.ContactName)) {
            description = description.concat(` | ${supplier.ContactName}`)
        }
        if (!IsNullOrEmpty(supplier.ContactEmail)) {
            description = description.concat(` | ${supplier.ContactEmail}`)
        }
        return description

    }

    /** Function to open update contact Email **/
    const onClickUpdateContact = (biddingId, supplierId, ContactEmail, supplierType) => {
        setOpenModalContact({
            ...openModalContact,
            openModal: !openModalContact.openModal,
            edit: !openModalContact.edit,
            ContactEmail: ContactEmail,
            biddingId: biddingId,
            supplierId: supplierId,
            supplierType: supplierType
        });
    }

    return (
        <List>
            {
                suppliers.map((supplier) => {
                    let buttonsForMoreCard = [

                        {
                            title: <Translate id="common.delete" />,
                            onClick: () => { onRemoveSupplier(supplier) },
                            icon: <DeleteIcon />,
                            isIconMaterial: true,
                            disabled: (!canBeEditBiddingInStatusDraft(bidding, userRolesByCompany, userProfile) || isSaving)
                        }
                    ]
                    buttonsForMoreCard.push(
                        {
                            title: <Translate id="biddingsModule.authorizerContacts" />,
                            onClick: () => { onSendEmailConfigPanel(supplier) },
                            icon: <EmailIcon />,
                            isIconMaterial: true,
                            disabled: (!canBeResendEmail(bidding, userRolesByCompany) || isSaving)
                        },
                    )
                    buttonsForMoreCard.push(
                        {
                            title: <Translate id="common.cancel" />,
                            onClick: () => { onCancelSupplier(3, bidding.Id, "Cancelado por licitador", supplier.Code) },
                            icon: <Cancel />,
                            isIconMaterial: true,
                            disabled: (!canBeResendEmail(bidding, userRolesByCompany) || isSaving)
                        },
                    )
                    if (supplier.Type === BiddingSuppliers.EXTERNAL) {
                        buttonsForMoreCard.push(
                            {
                                title: <Translate id="biddingsModule.supplierUpdateContact" />,
                                onClick: () => { onClickUpdateContact(bidding.Id, supplier.Code, supplier.ContactEmail, supplier.Type) },
                                icon: <SettingsIcon />,
                                isIconMaterial: true,
                                disabled: (!canBeResendEmail(bidding, userRolesByCompany) || isSaving)
                            },
                        )
                    }

                    return (
                        < ListItem
                            key={supplier.Id}
                            className={classes.disabledItem}
                        >
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        {onGetDescription(supplier)}
                                    </Typography>
                                }
                            />

                            <ListItemSecondaryAction className={classes.itemFlex}>
                                <Chip
                                    style={{
                                        backgroundColor: colorStatusProposal(supplier.Status),
                                        width: "100px",
                                        color: "white"
                                    }}
                                    label={descriptionProposalItem(supplier.Status, translate)}
                                />
                                <span className={classes.itemListIcon}>
                                    <PopperListsComponent
                                        title={<Translate id="common.options" />}
                                        listItems={buttonsForMoreCard}
                                    />
                                </span>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })
            }

            {openModalContact.openModal &&
                <CardUpdateContact
                    open={openModalContact.openModal}
                    onClose={onClickUpdateContact}
                    onSaveItem={onUpdateContactEmail}
                    ContactEmail={openModalContact.ContactEmail}
                    biddingId={openModalContact.biddingId}
                    supplierId={openModalContact.supplierId}
                    supplierType={openModalContact.supplierType}
                    isSaving={isSaving}
                    isEdit={openModalContact.edit}
                />
            }

        </List >
    );
}


const useCardStyle = ((theme) => ({
    searchBoxContainer: {
        width: "100%"
    },
    disabledItem: {
        marginTop: 1,
        padding: '1px 0px 1px 5px',
        borderRadius: "4px",
        height: "40px",
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
    },
    itemFlex: {
        display: "flex"
    },
    itemListIcon: {
        cursor: 'pointer',
    },
    titleStatus: {
        color: "white",
        fontSize: "80%",
        margin: "5px",
        padding: "3px"
    }
}))


export default (withStyles(useCardStyle, { withTheme: true })(ListSuppliers))