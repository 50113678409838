import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddTwoTone';
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import MessageIcon from '@material-ui/icons/Message'

/** Components imports section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import InvestigationLines from './components/InvestigationLines';
import ActionsList from './components/ActionsList';
import AddActionToListDialog from './components/AddActionToListDialog';
import LoadingPage from '../common/LoadingPage';
import DataTable from '../common/DataTable';
import FloatingButton from '../common/FloatingButton';
import DialogAvailableOperations from '../common/DialogAvailableOperations';
import Toaster from '../common/Toaster';
import Evidence from '../common/Evidence';
import AddCommonItem from '../common/AddCommonItem';
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow';
import HistoryCard from '../common/HistoryCard';

/** actions and helpers umports section */
import { loadSupplier } from '../../store/suppliers/SupplierActions';
import {
    loadSacFromBackEnd, sendSacNotificationToSupplier,
    addContainmentAction, addNonRecurringAction,
    updatePropertyInvestigationLines, deleteAction,
    sendSacNotificationToDevelopment, acceptRejectActionInList,
    acceptRejectInvestigationLines, uploadFilesEvidence,
    removeFilesEvidence, resendSacNotificationToSupplier,
    sacRequestResponsibleModification, sacRequestAdministratorAuthorization,
    sacCloseAndAccept, removeSacsInDraftFromBackend, editContainmentAction,
    editNonRecurringAction
} from '../../store/correctiveActions/SacActions';
import { IsCurrentNotActiveDirectoryUser } from '../../store/helpers/SessionHelper';
import {
    CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS,
    CORRECTIVE_ACTION_DEVELOPMENT_NON_RECURRING_ACTIONS,
    CORRECTIVE_ACTION_DEVELOPMENT_INVESTIGATION_LINES,
    CORRECTIVE_ACTION_DEVELOPMENT_REQUEST_MODIFY,
    CORRECTIVE_ACTION_DEVELOPMENT_REQUEST_MODIFY_BY_RESPONSIBLE
} from '../../store/helpers/WorkFlowDocumentsType';
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';
import { APPROVAL_REJECTED, APPROVAL_ACEPTED } from '../../store/helpers/ResourcesHelper';
import { converListToArray } from '../../store/helpers/ListHelper'
import { CaBeCancelCorrectiveActions } from '../../store/helpers/RolesHelper';
import {loadEvaluationSuppliersRatingByCompanyFromBackEnd} from '../../store/ratings/ratingActions';

/**
 * Container show notice
 */
class Show extends Component {

    /**
     * Create an instance notice
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openAvailableOperationsDialog: false,
            openAddAction: '',
            openCommentToResponsible: false,
            openCommentToSupplier: false,
            attachments: [],
            openDeleteDraft: false,
            editItemToken: ''
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let correctiveToken = this.props.match.params.token;
        this.props.loadSacFromBackEnd(correctiveToken);
    }

    /**
     * Delete sacs in draft
     */
    onDeleteDraft() {
        this.setState({
            openAvailableOperationsDialog: false,
            openDeleteDraft: true,
        })
    }

    /**
     * componentDidUpdate
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof Index
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoadingSac !== this.props.isLoadingSac) && this.props.corrective !== null) {
            /// Get information full from corrective
            let corrective = this.props.corrective;
            this.props.loadSupplier(corrective.supplier_token);
            if(!this.props.evaluationRating){
                this.props.loadEvaluationSuppliersRatingByCompany(corrective.company_token);
            }
        }
    }

    /**
     * Action to set state to open of available operations dialog
     * @memberof 
     */
    setOpenDialog = (openAvailable) => {
        this.setState({
            openAvailableOperationsDialog: openAvailable,
        });

    }

    /**
     * Handel state to show or hide dialog
     *
     * @param {*} dialogId
     * @memberof Show
     */
    handleShowDialog(dialogId) {
        this.setState({
            [dialogId]: !this.state[dialogId],
        });
    }

    /**
     * Send notification to supplier
     *
     * @memberof Show
     */
    onSacNotificationToSupplier() {
        let sacToken = this.props.match.params.token;
        this.props.sendSacNotificationToSupplier(sacToken)
            .then(() => {
                this.setOpenDialog(false);
                this.showSuccessToaster();
            }).catch(() => {
                this.showErrorToaster();
            });
    }

    /**
     * method to validate if investigation lines has valid and required data
     *
     * @param {*} document
     * @returns
     * @memberof Show
     */
    hasValidDataInvestigationLines(document) {
        if (!IsNullOrEmpty(document.cause_identified)
            && !IsNullOrEmpty(document.line_investigation_why1)
        )
            return true
        return false
    }

    hasAllFiles(actionsList) {
        let actionsWithoutFiles = actionsList.filter(action => {
            return action.attachments.length <= 0
        })
        if (actionsWithoutFiles.length>0)
            return false
        return true
    }
    /**
    * Send notification to 
    *
    * @memberof Show
    */
    onSacNotificationToDevelopment() {
        let sacToken = this.props.match.params.token;
        let documentContainmentActions = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS);
        let documentNonRecurrenceActions = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_NON_RECURRING_ACTIONS);
        let documentInvestigationLines = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_INVESTIGATION_LINES);

        if (documentContainmentActions.actions.length > 0
            && this.hasAllFiles(documentContainmentActions.actions)
            && documentNonRecurrenceActions.actions.length > 0
            && this.hasAllFiles(documentNonRecurrenceActions.actions)
            && this.hasValidDataInvestigationLines(documentInvestigationLines)
        ) {
            this.props.sendSacNotificationToDevelopment(sacToken)
                .then(() => {
                    this.setOpenDialog(false);
                    this.showSuccessToaster();
                }).catch(() => {
                    this.showErrorToaster();
                });
        }
        else
            this.showErrorToaster("sac.requiredDataToNotifyDevelopment");
    }

    /**
     * Function to validate if actions list has items without accept or reject by development
     *
     * @param {*} document
     * @returns
     * @memberof Show
     */
    hasActionsWithoutAuthorizeReject(document) {
        let undefinedStatus = document.actions.find(action => {
            return action.status !== APPROVAL_ACEPTED && action.status !== APPROVAL_REJECTED
        })
        if (undefinedStatus)
            return true
        return false
    }

    /**
     * Function to call resendSacNotificationToSupplier and send back notification to supplier
     *
     * @memberof Show
     */
    onSacReturnNotificationToSupplier(comment) {
        this.props.resendSacNotificationToSupplier(comment)
            .then(() => {
                this.handleShowDialog('openCommentToSupplier');
                this.setOpenDialog(false);
                this.showSuccessToaster();
            })
            .catch(() => {
                this.showErrorToaster();
            })
    }


    onSacRequestResponsibleModification(comment) {
        this.props.sacRequestResponsibleModification(comment)
            .then(() => {
                this.handleShowDialog('openCommentToResponsible');
                this.setOpenDialog(false);
                this.showSuccessToaster();
            })
            .catch(() => {
                this.showErrorToaster();
            })
    }

    onSacRequestAdministratorAuthorization() {
        let documentContainmentActions = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS);
        let documentNonRecurrenceActions = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_NON_RECURRING_ACTIONS);
        let documentInvestigationLines = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_INVESTIGATION_LINES);

        if (!this.hasActionsWithoutAuthorizeReject(documentContainmentActions)
            && !this.hasActionsWithoutAuthorizeReject(documentNonRecurrenceActions)
            && documentInvestigationLines.status !== 0) {
            this.props.sacRequestAdministratorAuthorization()
                .then(() => {
                    this.setOpenDialog(false);
                    this.showSuccessToaster();
                })
        }
        else
            this.showErrorToaster("sac.cantSendToAuthorize");
    }

    /**
     * Function to call sacCloseAndAccept action and end process
     *
     * @memberof Show
     */
    onSacCloseAndAccept() {
        let documentContainmentActions = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS);
        let documentNonRecurrenceActions = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_NON_RECURRING_ACTIONS);
        let documentInvestigationLines = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_INVESTIGATION_LINES);

        if (!this.hasActionsWithoutAuthorizeReject(documentContainmentActions)
            && !this.hasActionsWithoutAuthorizeReject(documentNonRecurrenceActions)
            && documentInvestigationLines.status !== 0)
            this.props.sacCloseAndAccept()
                .then(() => {
                    this.showSuccessToaster();
                    this.setOpenDialog(false);
                }).catch(() => {
                    this.showErrorToaster();
                });
        else
            this.showErrorToaster("sac.cantClose");
    }

    /**
     * Method to show succes toaster 
     * with custom message or default "saveCorrectly"
     *
     * @param {string} [idTranslation="common.saveCorrectly"]
     * @memberof Show
     */
    showSuccessToaster(idTranslation = "common.saveCorrectly") {
        this.setState({
            showToaster: true,
            toasterVariant: "success",
            toasterMessage: <Translate id={idTranslation} />,
        })
    }

    /**
     * Method to show succes toaster 
     * with custom message or default "errorToSave"
     *
     * @param {string} [idTranslation="common.errorToSave"]
     * @memberof Show
     */
    showErrorToaster(idTranslation = "common.errorToSave") {
        this.setState({
            showToaster: true,
            toasterVariant: "error",
            toasterMessage: <Translate id={idTranslation} />,
        })
    }

    /**
     * Function to update property of text field in state
     * @param {*} data
     * @memberof Show
     */
    updateProperty(data, section) {
        this.setState({
            [section]: {
                ...this.state[section],
                [data.id]: data.value,
            }
        })
    }

    /**
     * Function to trigger blur event and save data of input
     * 
     * @param {*} event
     * @memberof DetailsCard
     */
    onKeyDown(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    /**
     * Hendle state to show or hide AddActionDialog 
     *
     * @param {*} actionType
     * @memberof Show
     */
    handleShowAddEditActionDialog(openAddAction, editItemToken = '') {
        this.setState({
            openAddAction,
            editItemToken,
        })
    }
    /**
 * Cancel a corrective actions from back end
 */
    onDeleteCorrectiveActionFromBackend() {
        this.props.removeSacsInDraftFromBackend(this.props.corrective.token)
            .then(() => {
                this.props.history.push(`/correctiveactions`)
            }).catch(() => {
                this.showErrorToaster()
            })
    }


    /**
     * Handle event to add action, call redux action to save data
     *
     * @param {*} isContainment
     * @param {*} data
     * @memberof Show
     */
    onAddAction(isContainment, data) {
        let sacToken = this.props.match.params.token;
        if (isContainment) {
            return this.props.addContainmentAction(sacToken, data)
                .then(response => {
                    this.showSuccessToaster();
                    return Promise.resolve('Ok');
                })
                .catch(error => {
                    this.showErrorToaster();
                    return Promise.reject();
                })
        }
        else {
            return this.props.addNonRecurringAction(sacToken, data)
                .then(response => {
                    this.showSuccessToaster();
                    return Promise.resolve('Ok')
                })
                .catch(error => {
                    this.showErrorToaster();
                    return Promise.reject();
                })
        }
    }

    /**
     * Handle event to add action, call redux action to save data
     *
     * @param {*} isContainment
     * @param {*} data
     * @memberof Show
     */
    onSaveAction(isContainment, itemToken, data) {
        let sacToken = this.props.match.params.token;
        if (isContainment) {
            return this.props.editContainmentAction(sacToken, itemToken, data)
                .then(response => {
                    this.showSuccessToaster();
                    return Promise.resolve('Ok');
                })
                .catch(error => {
                    this.showErrorToaster();
                    return Promise.reject();
                })
        }
        else {
            return this.props.editNonRecurringAction(sacToken, itemToken, data)
                .then(response => {
                    this.showSuccessToaster();
                    return Promise.resolve('Ok')
                })
                .catch(error => {
                    this.showErrorToaster();
                    return Promise.reject();
                })
        }
    }

    //Cancel a corrective actions
    onCancelDocument() {
        this.props.history.push(`/correctiveactions/cancel/${this.props.corrective.token}`)
    }

    /**
     * Method to find document in corrective action by document_type
     *
     * @param {*} documentType
     * @returns
     * @memberof Show
     */
    findDocument(documentType) {
        if (this.props.corrective && this.props.corrective.documents && this.props.corrective.documents.length > 0)
            return this.props.corrective.documents.find(document => {
                return document.document_type === documentType
            })
        else return []
    }

    /**
     * Render hystoryCard for quality failure notification
     *
     * @param {*} planningEngineerName
     * @param {*} developmenEngineerName
     * @param {*} qualityUsersList
     * @returns
     * @memberof Show
     */
    renderAditionalDataComments(comments) {
        return (
            <Grid container spacing={24}>
                <CardHeader
                    style={{ paddingTop: '0px' }}
                    title={
                        <Typography gutterBottom variant="body2" component="p">
                            <strong>
                                <Translate id="common.comments" />:
                            </strong>
                        </Typography>
                    }
                    subheader={
                        <Typography gutterBottom variant="body2" component="p">
                            <MessageIcon color='secondary' />
                            {' ' + comments}
                        </Typography>
                    }
                />
            </Grid>
        )
    }

    /**
     * Render hystory section for all documents
     *
     * @returns
     * @memberof Show
     */
    renderHistory() {
        let documents = this.props.corrective ? this.props.corrective.documents ? this.props.corrective.documents : [] : []
        return (
            documents.map(document => {
                let titleId;
                let aditionalData;
                if (document.document_type === CORRECTIVE_ACTION_DEVELOPMENT_NON_RECURRING_ACTIONS
                    || document.document_type === CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS
                    || document.document_type === CORRECTIVE_ACTION_DEVELOPMENT_INVESTIGATION_LINES) {
                    return "";
                }
                if (document.document_type === CORRECTIVE_ACTION_DEVELOPMENT_REQUEST_MODIFY) {
                    titleId = 'sac.documents.requestModifyBySupplier';
                    aditionalData = this.renderAditionalDataComments(document.comment);
                }
                if (document.document_type === CORRECTIVE_ACTION_DEVELOPMENT_REQUEST_MODIFY_BY_RESPONSIBLE) {
                    titleId = 'sac.documents.requestModifyByResponsible';
                    aditionalData = this.renderAditionalDataComments(document.comment);
                }
                return (
                    <HistoryCard
                        key={document.token}
                        title={titleId}
                        userCreator={document.create_by}
                        creationDate={document.creation_date_long}
                        aditionalData={aditionalData}
                    />
                )

            })
        )
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {

        if (this.props.isLoadingSac === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.corrective !== null) {
            //Get documents list by type
            let documentContainmentActions = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS);
            let documentNonRecurrenceActions = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_NON_RECURRING_ACTIONS);
            let documentInvestigationLines = this.findDocument(CORRECTIVE_ACTION_DEVELOPMENT_INVESTIGATION_LINES);
            let showInvestigationsAndActions = this.props.corrective.actual_operation !== 1;
            let isExternalUser = IsCurrentNotActiveDirectoryUser(this.props.currentUser);

            let itemToEdit;
            if (!IsNullOrEmpty(this.state.editItemToken)) {
                if (this.state.openAddAction === "contaimentAction") {
                    itemToEdit = documentContainmentActions && documentContainmentActions.actions.find(item => {
                        return item.token === this.state.editItemToken
                    })
                } else {
                    itemToEdit = documentNonRecurrenceActions && documentNonRecurrenceActions.actions.find(item => {
                        return item.token === this.state.editItemToken
                    })
                }
            }

            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <InformationCard
                            {...this.props}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Evidence
                            baseApi={process.env.REACT_APP_SIP_URL_FILES}
                            removeFiles={this.props.removeFiles}
                            uploadFiles={this.props.uploadFiles}
                            isDisabled={!this.props.corrective.can_be_update_internal || isExternalUser}
                            files={this.props.corrective.attachments ? this.props.corrective.attachments : []}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RatingsCard
                            {...this.props}
                        />
                    </Grid>
                    {showInvestigationsAndActions && <Grid item xs={12}>
                        <InvestigationLines
                            updateProperty={this.updateProperty}
                            sacToken={this.props.match.params.token}
                            updatePropertyInvestigationLines={this.props.updatePropertyInvestigationLines}
                            onKeyDown={this.onKeyDown}
                            investigationLines={documentInvestigationLines}
                            translate={this.props.translate}
                            user={this.props.currentUser}
                            canBeUpdateSupplier={this.props.corrective.can_be_update_supplier}
                            canBeUpdateInternal={this.props.corrective.can_be_update_internal}
                            acceptReject={this.props.acceptRejectInvestigationLines}
                            showSuccessToaster={this.showSuccessToaster}
                            showErrorToaster={this.showErrorToaster}
                        />
                    </Grid>}
                    {showInvestigationsAndActions && <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title={<Typography variant="button"><Translate id="sac.actions.containmentActions" /></Typography>}
                                action={(IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.props.corrective.can_be_update_supplier) &&
                                    <Fab size="small" color="primary"
                                        onClick={() => { this.handleShowAddEditActionDialog("contaimentAction") }}>
                                        <AddIcon />
                                    </Fab>
                                }
                            />
                            <CardContent>
                                <ActionsList
                                    isContainment
                                    actionsList={documentContainmentActions}
                                    user={this.props.currentUser}
                                    onDeleteAction={this.props.deleteAction}
                                    onEditAction={(itemToken) => this.handleShowAddEditActionDialog("contaimentAction", itemToken)}
                                    translate={this.props.translate}
                                    canBeUpdateSupplier={this.props.corrective.can_be_update_supplier}
                                    canBeUpdateInternal={this.props.corrective.can_be_update_internal}
                                    acceptRejectActionInList={this.props.acceptRejectActionInList}
                                    showSuccessToaster={this.showSuccessToaster}
                                    showErrorToaster={this.showErrorToaster}
                                />
                            </CardContent>
                        </Card >
                    </Grid>}
                    <Divider />
                    {showInvestigationsAndActions && <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title={<Typography variant="button"><Translate id="sac.actions.nonRecurrenceActions" /></Typography>}
                                action={(IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.props.corrective.can_be_update_supplier) &&
                                    <Fab size="small" color="primary"
                                        onClick={() => { this.handleShowAddEditActionDialog("nonRecurrenceAction") }}>
                                        <AddIcon />
                                    </Fab>
                                }
                            />
                            <CardContent>
                                <ActionsList
                                    actionsList={documentNonRecurrenceActions}
                                    user={this.props.currentUser}
                                    onDeleteAction={this.props.deleteAction}
                                    onEditAction={(itemToken) => this.handleShowAddEditActionDialog("nonRecurrenceAction", itemToken)}
                                    translate={this.props.translate}
                                    canBeUpdateSupplier={this.props.corrective.can_be_update_supplier}
                                    canBeUpdateInternal={this.props.corrective.can_be_update_internal}
                                    acceptRejectActionInList={this.props.acceptRejectActionInList}
                                    showSuccessToaster={this.showSuccessToaster}
                                    showErrorToaster={this.showErrorToaster}
                                />
                            </CardContent>
                        </Card >
                    </Grid>}
                    {showInvestigationsAndActions && <Grid item xs={12}>
                        {this.renderHistory()}
                    </Grid>}

                    {/** Dialog to AddReferenceDialog */}
                    <AddActionToListDialog
                        open={this.state.openAddAction !== ''}
                        item={this.state.editItemToken && itemToEdit}
                        onClose={() => { this.handleShowAddEditActionDialog('') }}
                        onAddActions={this.onAddAction}
                        onEditActions={this.onSaveAction}
                        isSaving={this.props.isSaving || this.props.isLoadingActions}
                        isContainment={this.state.openAddAction === "contaimentAction"}
                        user={this.props.currentUser}
                    />
                </Grid>
            )
        }
    }

    render() {
        let availableOptions = this.props.corrective ? this.props.corrective.get_available_operations : [];
        return (
            <Card>
                {availableOptions.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

                <LayoutHeaderWorkFlow {...this.props}
                    workFlow={this.props.corrective}
                    title={<Translate id="sac.title"/>}
                />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                <DialogAvailableOperations
                    {...this.props}
                    options={availableOptions}
                    isValidToCancel={CaBeCancelCorrectiveActions(this.props.userRolesByCompany)}
                    openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
                    setOpenDialog={this.setOpenDialog}
                    sendQuoteRequestedToSupplier={this.onSendSacRequestedToSupplier}
                    onSacNotificationToSupplier={this.onSacNotificationToSupplier}
                    onSacNotificationToDevelopmentEngineer={this.onSacNotificationToDevelopment}
                    onSacReturnNotificationToSupplier={() => this.handleShowDialog('openCommentToSupplier')}
                    onSacRequestResponsibleModification={() => this.handleShowDialog('openCommentToResponsible')}
                    onSacRequestAdministratorAuthorization={this.onSacRequestAdministratorAuthorization}
                    onSacCloseAndAccept={this.onSacCloseAndAccept}
                    onCancelDocument={this.onCancelDocument}
                    onDeleteDraft={this.onDeleteDraft}
                />

                <AddCommonItem
                    title={<Translate id="sac.sendBackToSupplier" />}
                    message={<Translate id="sac.messageSendBackToSupplier" />}
                    open={this.state.openCommentToSupplier}
                    onClose={() => { this.setState({ openCommentToSupplier: false }) }}
                    onSaveItem={this.onSacReturnNotificationToSupplier}
                    isSaving={this.props.isSaving}
                    isTextArea
                />
                <AddCommonItem
                    title={<Translate id="sac.sendBackToResponsible" />}
                    message={<Translate id="sac.messageSendBackToResponsible" />}
                    open={this.state.openCommentToResponsible}
                    onClose={() => { this.setState({ openCommentToResponsible: false }) }}
                    onSaveItem={this.onSacRequestResponsibleModification}
                    isSaving={this.props.isSaving}
                    isTextArea
                />
                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

                {/** Dialog to delete sacs in draft */}
                < AddCommonItem
                    title={<Translate id="sac.removeSac"/>}
                    message={<Translate id="sac.removeTitle"/>}
                    open={this.state.openDeleteDraft}
                    onClose={() => { this.setState({ openDeleteDraft: false }) }}
                    onSaveItem={this.onDeleteCorrectiveActionFromBackend}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                />

            </Card>
        )
    }

}

/**
 * Information MemoCard
 *
 * @class InformationMemoCard
* @extends {Component}
        */
class InformationCard extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Paper>
                <List>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {"Folio: "}
                                    </Typography>
                                    {this.props.corrective.folio}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {"Proveedor: "}
                                    </Typography>
                                    {this.props.corrective.full_supplier}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {"Usuario creador: "}
                                    </Typography>
                                    {this.props.corrective.create_by}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {"Comentarios: "}
                                    </Typography>
                                    {this.props.corrective.comments}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                </List>
            </Paper>
        );
    }
}

/**
 * Component to RatingsCard
 */
class RatingsCard extends Component {
    /**
     * Create an instance of Internal user
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
     * Render
     *
     * @returns
     * @memberof RatingsCard
     */
    render() {
        return (
            <DataTable
                title={<Typography variant="button"><Translate id="sac.ratingSupplier"/> </Typography>}
                data={this.props.corrective.ratings}
                configuration={TableratingsTableConfiguration(this.props.evaluationRating, this.props.translate)}
            />
        )
    }
}


function TableratingsTableConfiguration(evaluation, translate) {
    let columns = [
        {
            header: '',
            dataSource: 'status_indicator',
        }, {
            header: translate('rating.ratingsTable.month'),
            dataSource: 'rating_date',
        },
        {
            header:  `${translate && translate('rating.ratingsTable.deliveries')} ${evaluation ? `${evaluation.delivery_points}` : '25'}%`,
            dataSource: 'delivery_points',
        }, {
            header:  `${translate && translate('rating.ratingsTable.development')} ${evaluation ? `${evaluation.sourcing_points}` : '25'}%`,
            dataSource: 'development_points',
        }, {
            header:  `${translate && translate('rating.ratingsTable.quality')} ${evaluation ? `${evaluation.quality_points}` : '40'}%`,
            dataSource: 'quality_points',
        }, {
            header:  `${translate && translate('rating.ratingsTable.service')} ${evaluation ? `${evaluation.service_points}` : '10'}%`,
            dataSource: 'service_points',
        }, {
            header: <Translate id="rating.ratingsTable.finalScore" />,
            dataSource: 'final_score',
        }];
    return ({ columns });
}

/**
 *  Defines the properties injecteded from the store to view container
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state) {
    let corrective = state.sacs.get("sacInformation");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let selectedCompany = state.profile.get("selectedCompany");
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        currentUser: user,
        isSaving: state.sacs.get('isSaving'),
        isLoadingActions: state.sacs.get('isLoadingActions'),
        isLoadingSac: state.sacs.get('isLoadingSac'),
        corrective: corrective ? corrective.toJS() : null,
        company: selectedCompany ? selectedCompany.toJS() : null,
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
        evaluationRating: state.ratings.get('evaluationRating') ? state.ratings.get('evaluationRating').toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component
 *
 * @param {*} state
 * @returns
 */
const mapDispatchToProps = dispatch => {
    return {
        loadSacFromBackEnd: (token) => {
            return dispatch(loadSacFromBackEnd(token));
        },
        loadSupplier: (token) => {
            return (dispatch(loadSupplier(token)))
        },
        sendSacNotificationToSupplier: (sacToken) => {
            return (dispatch(sendSacNotificationToSupplier(sacToken)))
        },
        addContainmentAction: (sacToken, actionData) => {
            return (dispatch(addContainmentAction(sacToken, actionData)))
        },
        addNonRecurringAction: (sacToken, actionData) => {
            return (dispatch(addNonRecurringAction(sacToken, actionData)))
        },
        editContainmentAction: (sacToken, itemToken, actionData) => {
            return (dispatch(editContainmentAction(sacToken, itemToken, actionData)))
        },
        editNonRecurringAction: (sacToken, itemToken, actionData) => {
            return (dispatch(editNonRecurringAction(sacToken, itemToken, actionData)))
        },
        updatePropertyInvestigationLines: (sacToken, data) => {
            return (dispatch(updatePropertyInvestigationLines(sacToken, data)))
        },
        deleteAction: (itemToken, documentType) => {
            return (dispatch(deleteAction(itemToken, documentType)))
        },
        sendSacNotificationToDevelopment: (sacToken) => {
            return (dispatch(sendSacNotificationToDevelopment(sacToken)))
        },
        acceptRejectActionInList: (comment, tokenAction, isAccepted, documentType) => {
            return (dispatch(acceptRejectActionInList(comment, tokenAction, isAccepted, documentType)))
        },
        acceptRejectInvestigationLines: (comment, sacToken, isAccepted) => {
            return (dispatch(acceptRejectInvestigationLines(comment, sacToken, isAccepted)))
        },
        uploadFiles: (files) => {
            return dispatch(uploadFilesEvidence(files));
        },
        removeFiles: (fileName) => {
            return dispatch(removeFilesEvidence(fileName));
        },
        resendSacNotificationToSupplier: (comment) => {
            return dispatch(resendSacNotificationToSupplier(comment));
        },
        sacRequestResponsibleModification: (comment) => {
            return dispatch(sacRequestResponsibleModification(comment));
        },
        sacRequestAdministratorAuthorization: () => {
            return dispatch(sacRequestAdministratorAuthorization());
        },
        sacCloseAndAccept: () => {
            return dispatch(sacCloseAndAccept());
        },
        removeSacsInDraftFromBackend: (token) => {
            return dispatch(removeSacsInDraftFromBackend(token));
        },
        loadEvaluationSuppliersRatingByCompany:(companyToken) =>{
            return dispatch(loadEvaluationSuppliersRatingByCompanyFromBackEnd(companyToken));
        }
    }
}

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));