
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import {Translate} from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import uuidv4 from 'uuid/v4'

/** section import components */
import Dropzone from '../../common/Dropzone';
import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper';

/**
 * Component ot render Documents List shipping Order
 */
export default class DocumentsList extends Component{

    /**
     * Create an instance shipping Order
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
        }
    }

     /**
     * On change files of dropzone 
     *
     * @param {*} event
     * @memberof DetailsCard
     */
    onChangeFileFreight(files, folder, listItems) {
        if(this.props.freight && this.props.freight.response){
            return this.props.uploadFilesFreight(this.props.freight.response.token, files, folder, listItems)
            .then(() => {
                return Promise.resolve('Ok');
            }).catch(() => {
                return Promise.reject();
            });
        }
    }

    /**
     * On remove files of dropzone 
     *
     * @param {*} event
     * @memberof DetailsCard
     */
    onDeleteFileFreight(fileName, folder, listItems) {
        if(this.props.freight && this.props.freight.response){
            return this.props.removeFilesFreight(this.props.freight.response.token, fileName, folder, listItems)
            .then(() => {
                return Promise.resolve('Ok');
            }).catch(() => {
                return Promise.reject();
            });
        }
    }

    /**
     * onChangeFileInvoices
     * @param {*} files 
     */
    onChangeFileInvoices(files){
        return this.onChangeFileFreight(files, "Invoices", "invoices")
    }

    /**
     * onDeleteFileInvoices
     * @param {*} fileName 
     */
    onDeleteFileInvoices(fileName){
        return this.onDeleteFileFreight(fileName, "Invoices", "invoices")
    }
    
    /**
     * On render files freight
     * @param {*} title 
     * @param {*} onChange 
     * @param {*} onDelete 
     * @param {*} items 
     */    
    renderFilesFreights(titleTranslate, onChange, onDelete, items){        
        let isActive = (IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.props.freight.can_be_update_supplier)       
        return(
            <TableRow key={uuidv4()} >
                <TableCell key={uuidv4()} align="left" padding="dense">{<Translate id={'shippingOrder.'+titleTranslate}/>}</TableCell>
                <TableCell key={uuidv4()} align="left" padding="dense">
                    <Dropzone
                        acceptedFiles={['xml', 'pdf']}                        
                        baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
                        onChange={onChange}
                        onDeleteFile={onDelete}
                        isDisabled={!isActive}
                        files={items}
                    />
                </TableCell>
            </TableRow>
        )
    }

    /**
     * Render
     */
    render() {
        const { classes} = this.props;
        return (
            <Paper>
                <List>
                    <ListItem>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <strong> <Translate id="shippingOrder.documents"/></strong>
                                </Typography>
                            </React.Fragment>
                        } />
                    </ListItem>
                </List>

                <Table style={{ maxWidth: '100%' }}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell><Translate id="shippingOrder.documentName"/></TableCell>
                            <TableCell><Translate id="shippingOrder.documentOptions"/></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderFilesFreights("invoices", this.onChangeFileInvoices, this.onDeleteFileInvoices, this.props.freight.invoices)}
                    </TableBody>
                </Table>

            </Paper>
        );
    }
}

/**
 * freight
 */
DocumentsList.propTypes = {
    freight: PropTypes.object.isRequired
}