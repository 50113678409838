import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

/** Helpers section import */
import userManager from './store/helpers/UserManager'
import reducers from './store/RootReducer'

/** Components section import */
import { LocalizeProvider } from 'react-localize-redux'
import { loadUser, OidcProvider } from 'redux-oidc'
import ProposalExternal from './views/biddings/ProposalExternal'
import CallbackPage from './views/common/CallbackPage'
import LoadAlertTracking from './views/loadAlertTracking/Index'
import RegisterProspect from './views/prospects/RegisterProspect'
import ResetPasswordConfirm from './views/suppliers/users/ResetPasswordConfirm'
import ResetPasswordRequest from './views/suppliers/users/ResetPasswordRequest'

import App from './App'
import * as serviceWorker from './serviceWorker'

/** Theme import section */
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { ExtendedStringifyOptions, QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params'
import themeContent from './resources/themes/themeImbera.json'
const queryStringifyOptions: ExtendedStringifyOptions = {
	transformSearchString: transformSearchStringJsonSafe,
}
const homeTheme = createMuiTheme(themeContent)

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware)))
loadUser(store, userManager)

ReactDOM.render(
	<MuiThemeProvider theme={homeTheme}>
		<Provider store={store}>
			<LocalizeProvider store={store}>
				<OidcProvider store={store} userManager={userManager}>
					<Router basename={`${process.env.REACT_APP_ROOT}`}>
						<QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
							<Switch>
								<Route exact path='/callback' component={CallbackPage} />
								<Route exact path='/user/resetpassword' component={ResetPasswordRequest} />
								<Route
									exact
									path='/user/resetpassword/:request_token'
									component={ResetPasswordConfirm}
								/>
								<Route exact path='/prospect/register/:token' component={RegisterProspect} />
								<Route exact path='/proposal/view/:token' component={ProposalExternal} />
								<Route exact path='/loadalerttracking' component={LoadAlertTracking} />
								<Route path='/' component={App} />
							</Switch>
						</QueryParamProvider>
					</Router>
				</OidcProvider>
			</LocalizeProvider>
		</Provider>
	</MuiThemeProvider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
