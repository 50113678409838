import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import moment from 'moment'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import ReworkIcon from '@material-ui/icons/AutorenewOutlined'; 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DialogActions from '@material-ui/core/DialogActions';

import Toaster from '../../common/Toaster';
import LoadingPage from '../../common/LoadingPage';
import SupplierSelector from '../../common/SupplierSelector';
import DatePicker from '../../common/DateTimePicker/DatePicker';

import {loadRawMaterialNonConformityFromBackEnd, createReworkFromBackEnd} from '../../../store/rawMaterialNonConformity/RawMaterialActions';

/**
 * Component to create rework from raw material
 */
class CreationRework extends Component{

     /**
     * Creation Rework an instance raw material
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            creationDate: moment().format('DD/MM/YYYY'),
            sorterBySelected: null,
            rework:{
                dateMaterial:moment(),
                description: "",
            }
        }
    }

       /**
     * componentDidMount
     */
    componentDidMount(){
        let rawMaterialToken = this.props.match.params.token;
        this.props.loadRawMaterialNonConformity(rawMaterialToken);
    }

    /**
     * On seleted sorter by
     */
    onSorterBySelected(sorter){
        this.setState({sorterBySelected: sorter});
    }

    /**
     * On change data material
     * @param {*} dateMaterial 
     */
    onChangeDateMaterial(dateMaterial){
        let creatRework = {...this.state.rework};
        creatRework["dateMaterial"] = dateMaterial;
        this.setState({
            rework: creatRework
        })
    }

      /**
     * Update the property of the rework
     *
     * @param {*} user
     * @param {*} value
     * @memberof View
     */
    updateProperty(property, value) {
        let creatRework = {...this.state.rework};
        creatRework[property] = value;
        this.setState({
            rework: creatRework
        })
    }

     /**
     * Can be add item in raw material
     */
    canBeSaved(){
        let rework = this.state.rework;
        if(this.state.sorterBySelected === null)
            return false;
        if(rework.description === "" || rework.dateMaterial === "")
            return false;
        else
            return true;
    }


    /**
     * Get action to create rework from raw mateiral 
     */
    onCreateRework(){
        let rawMaterialToken = this.props.match.params.token;
        let dateMaterial = this.state.rework.dateMaterial.format("DD/MM/YYYY");
        this.props.createRework(this.state.sorterBySelected, dateMaterial, this.state.rework.description)
        .then(() =>{
            this.setState({
                showToView:true,
                showToaster: false
            })
            this.props.history.push(`/rawmaterialnonconformity/${rawMaterialToken}`)
        }).catch(() =>{
            this.setState({
                showToaster:true,
                toasterMessage: <Translate id="common.errorToSave"/>,
                toasterVariant: "error",
            })
        });
    }

        /**
    * Get the card content
    *
    * @memberof Show
    */
   getCardContent() {
    if (this.props.isLoading === true) {
        return (
            <LoadingPage />
        )
    }
    else if (this.props.rawMaterial !== null) {
        const { classes } = this.props;
        let rawMaterial = this.props.rawMaterial;
        return(
                <div className={classes.container} noValidate autoComplete="off">
                    <Grid item xs={12}>
                        <div className={classes.textField} >
                            <Typography variant="body2" gutterBottom><strong><Translate id="common.supplier"/></strong>
                            {": "}{rawMaterial.full_supplier}
                            </Typography>
                        </div>

                        <div className={classes.textField} ><br/>
                            <Typography variant="body2" gutterBottom><strong><Translate id="common.partNumber"/></strong>
                            {": "}{rawMaterial.full_part_description}
                            </Typography>
                        </div>

                        <div className={classes.textField} ><br/>
                            <Typography><strong><Translate id="reworks.sorter"/></strong> *</Typography>
                            <SupplierSelector
                                className={classes.textField}
                                onSupplierSelected = {(sorter) => {this.onSorterBySelected(sorter)}}
                            />
                        </div>

                        <div className={classes.textField} ><br/>
                            <Typography variant="body2" gutterBottom><strong><Translate id="reworks.qualityToReworker"/></strong>
                            {": "}{ rawMaterial.quantity_of_material_in_invoice}
                            </Typography>
                        </div>

                        <div className={classes.textField} ><br/>
                            <Typography><strong><Translate id="reworks.dateRework"/></strong> *</Typography>
                            <DatePicker
                                onChangeRange={this.onChangeDateMaterial}
                                minDate={moment()}
                                startDate={this.state.rework.dateMaterial}
                            /><br/>
                        </div>

                        <TextField fullWidth required
                            id="outlined-multiline-flexible"
                            label={<Translate id="reworks.descriptionRework"/>}
                            multiline
                            className={classes.tetxtFieldFull} 
                            rows="4"
                            value={this.state.rework.description}
                            onChange={(event) => {this.updateProperty("description", event.target.value)}}
                            margin="normal"
                            variant="outlined"
                        /><br/>
                    </Grid>

                    <Grid container >
                        <DialogActions>
                            <Button 
                                data-cy="btn-save"
                                type="button" 
                                color="primary" 
                                onClick={this.onCreateRework}
                                disabled = {!this.canBeSaved() || this.props.isSaving}>
                                {<Translate id="common.save"/>}
                            </Button>
                            <Button onClick={() => {this.props.history.push(`/rawmaterialnonconformity/${rawMaterial.token}`)}} color="secondary">
                                {<Translate id="common.cancel"/>}
                            </Button>
                        </DialogActions>
                    </Grid> 

                    <Toaster 
                        message = {this.state.toasterMessage}
                        open = {this.state.showToaster}
                        variant = {this.state.toasterVariant}
                        onClose = {() => {this.setState({ showToaster:false})}}
                    /> 
                </div> 
            )
        }
    }

    render(){
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <ReworkIcon />
                    </Avatar>
                }
                title={<Typography variant="button" ><Translate id="reworks.titleRework"/></Typography>}
                subheader={<Typography variant="caption" color="textPrimary"><Translate id="common.createBy"/>: {this.props.user.profile.name}
                 <br/><Translate id="common.creationDate"/>: {this.state.creationDate}</Typography>}
            />
            <CardContent>
                {this.getCardContent()}
            </CardContent>

            <Toaster 
                message = {this.state.toasterMessage}
                open = {this.state.showToaster}
                variant = {this.state.toasterVariant}
                onClose = {() => {this.setState({ showToaster:false})}}
            /> 

        </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    let rawMaterial = state.rawMaterialNonConformity.get("rawMaterial");
    return {
        user: user,
        isSaving: state.rawMaterialNonConformity.get('isSaving'),
        isLoading: state.rawMaterialNonConformity.get('isLoading'),
        rawMaterial: rawMaterial ? rawMaterial.toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadRawMaterialNonConformity:(token) =>{
            return dispatch(loadRawMaterialNonConformityFromBackEnd(token));
        },
        createRework:(sorterBySelected, dateMaterial, description) =>{
            return dispatch(createReworkFromBackEnd(sorterBySelected, dateMaterial, description))
        }
    }
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: "100px",
        paddingRight: "100px"
    },
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(CreationRework))));