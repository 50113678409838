import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/resource-timegrid'
import esLocale from '@fullcalendar/core/locales/es'
import ptLocale from '@fullcalendar/core/locales/pt'

/** Material-UI import section*/
import { Avatar, Card, CardContent, CardHeader, Typography, withStyles } from '@material-ui/core'
import CalendarIcon from '@material-ui/icons/CalendarToday'

/** Redux import section*/
import { useDispatch, useSelector } from 'react-redux'
import { loadScheduledVehicleControls } from '../../store/controlCO2/controlCO2Action'
import { getScheduledVehicleControlsSelector } from '../../store/controlCO2/controlCO2Selectors'

/** Custom components import section*/
import LoadingPage from '../common/LoadingPage'
import { removeRepeatingValues } from '../../store/helpers/StringHelper'

const eventColors = ['rgb(26,115,232)', 'rgb(217,48,37)', 'rgb(51, 182, 121)', 'rgb(230, 124, 115)']

const buildCalendarEvents = (vehicleControls) => {
	const economicNumbers = removeRepeatingValues(vehicleControls.map((i) => i.vehicleEconomicNumber))
	const vehicleColor = {}
	economicNumbers.forEach((eco, idx) => (vehicleColor[eco] = eventColors[idx % 4]))
	return vehicleControls.map((vehicleControl) => ({
		title: `${vehicleControl.vehicleEconomicNumber || '-'} ${vehicleControl.vehicleLicensePlate || '-'} ${
			vehicleControl.vehicleBrandName || '-'
		} ${vehicleControl.vehicleModelName || '-'}`,
		start: vehicleControl.checkOutDate,
		end: vehicleControl.checkInDate,
		backgroundColor: vehicleColor[vehicleControl.vehicleEconomicNumber],
	}))
}

const CalendarIndex = (props) => {
	const { classes } = props
	/** Defeines local state */
	const [loading, setLoading] = useState(true)
	/** Connect with redux */
	const dispatch = useDispatch()
	const scheduledVehicleControls = useSelector((state) => getScheduledVehicleControlsSelector(state))
	const allEvents = buildCalendarEvents(scheduledVehicleControls)

	/** Load vehicles data */
	useEffect(() => {
		setLoading(true)
		dispatch(loadScheduledVehicleControls()).finally(() => {
			setLoading(false)
		})
	}, [dispatch])

	return (
		<>
			<Card>
				<CardHeader
					className={classes.header}
					avatar={
						<Avatar
							style={{
								height: 35,
								width: 35,
							}}
						>
							<CalendarIcon />
						</Avatar>
					}
					title={
						<Typography>
							<Translate id='controlCO2.tabs.calendar' />
						</Typography>
					}
				/>
				<CardContent>
					{
						{
							true: <LoadingPage />,
							false: (
								<FullCalendar
									defaultView='timeGridWeek'
									plugins={[dayGridPlugin, timeGridPlugin]}
									timeZone='UTC'
									locales={[esLocale, ptLocale]}
									locale='es'
									header={{
										left: 'prev,next',
										center: 'title',
										right: 'timeGridDay,timeGridWeek,dayGridMonth',
									}}
									contentHeight='60vh'
									events={allEvents}
									view
								/>
							),
						}[loading]
					}
				</CardContent>
			</Card>
		</>
	)
}

const styles = (theme) => ({
	header: {
		padding: theme.spacing.unit,
	},
	actionsContainer: {
		display: 'flex',
		marginTop: theme.spacing.unit,
	},
})

export default withStyles(styles, { withTheme: true })(CalendarIndex)
