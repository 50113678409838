import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';

/** Import component section */
import { Grid, Typography, IconButton } from '@material-ui/core';
import HtmlEditor from '../../common/HtmlEditor';
import { GetQuestionnairesType, getSelectedValue } from '../../../store/helpers/SelectOptions';

/** Import actions and helpers section */

class GeneralInformation extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
        };
    };

    /**
     * show edit onEditTextQuesionnaire
     * @param {*} keyText 
     * @param {*} label 
     * @param {*} keyProperty 
     * @param {*} isDisabled 
     */
    onEditTextQuesionnaire(label, keyProperty, isDisabled) {
        return (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <HtmlEditor
                id={label}
                label={label}
                labelVariant="caption"
                labelColor="textSecondary"
                isDisabled={isDisabled}
                initialValue={this.props.questionnaire[keyProperty] ? this.props.questionnaire[keyProperty] : ''}
                onChange={(data) => this.props.updateProperty(keyProperty, data)}
            />
        </Grid>)
    }

    /**
     * Handle event upload files of devidence componet to add files to list
     *
     * @param {*} files
     * @returns
     * @memberof AddActionToListDialog
     */
    uploadFiles(isInternal, files) {
        let questionnaireToken = this.props.questionnaire.token;
        this.props.uploadFilesInQuestionnaire(questionnaireToken, isInternal, files)
        return Promise.resolve('Ok')
    }

    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
     * @memberof AddActionToListDialog
     */
    removeFiles(isInternal, fileName) {
        let questionnaireToken = this.props.questionnaire.token;
        this.props.removeFilesInQuestionnaire(questionnaireToken, isInternal, fileName)
        return Promise.resolve('Ok')
    }

    // onChangeDelivery(deliveryDate) {
    //     let questionnaireToken = this.props.questionnaire.token;
    //     this.props.updatePropertyDeliveryDate(questionnaireToken, "delivery_date_action_plan", deliveryDate)
    // }

    onChangeScheduleDate(scheduledDate) {
        let questionnaireToken = this.props.questionnaire.token;
        if (scheduledDate !== null) {
            this.props.updatePropertyScheduledDate(questionnaireToken, scheduledDate)
        }
    }

    /**
     * Render section resums
     */
    render() {
        var { classes, questionnaire, canEditScheduletDate } = this.props;
        const questionnairesType = GetQuestionnairesType();
        let selectedQuestionnaireType = questionnaire && questionnaire.questionnaire_type;
        let selectedQuestionnaireTypeDescription = getSelectedValue(questionnairesType, selectedQuestionnaireType);
        return (
            <Grid container spacing={24} justify="space-between" style={{ maxWidth: "100%", marginTop: ".5rem" }}>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center" item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={8} xl={8}>
                        <Typography variant="body2">
                            <strong><Translate id="questionnaire.questionnaireType" />: </strong>
                            {selectedQuestionnaireType > 0 ?
                                <React.Fragment>
                                    {selectedQuestionnaireTypeDescription.label}
                                </React.Fragment>
                                : <Translate id="common.withoutData" />
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={3} xl={3}>
                        {
                            <Typography variant="body2">
                                <strong><Translate id="questionnaire.scheduledDate" />: </strong>
                                <Typography variant="body2" gutterBottom>
                                    {questionnaire.scheduled_date_full && questionnaire.scheduled_date_full}
                                </Typography>
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={1} xl={1}>
                        {
                            canEditScheduletDate && <IconButton color="primary" onClick={() => this.props.onEditScheduledDatePanelConfig()}>
                                <EditIcon />
                            </IconButton>
                        }
                    </Grid>

                </Grid>

                {this.onEditTextQuesionnaire("questionnaire.messageToSupplier", "comments", true)}

                {!questionnaire.can_edit_supplier && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body2">
                        <strong>{"Proceso de evaluación proveedores"}: </strong>
                    </Typography>
                    <Table className={classes.table} aria-label="caption table">
                        <TableHead className={classes.headerTable}>
                            <TableRow>
                                <TableCell className={classes.tdBody}><Translate id="questionnaire.shortName" /></TableCell>
                                <TableCell className={classes.tdBody}><Translate id="questionnaire.percentageSection" /></TableCell>
                                <TableCell className={classes.tdBody}><Translate id="questionnaire.maximumPoints" /></TableCell>
                                <TableCell className={classes.tdBody}><Translate id="questionnaire.supplierPoints" /></TableCell>
                                <TableCell className={classes.tdBody}><Translate id="questionnaire.ratingSupplierTtotal" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {questionnaire.summary_results.map((item) => {
                                return (<TableRow key={item.token}>
                                    <TableCell className={classes.tdBody}><strong>{item.short_name}</strong></TableCell>
                                    <TableCell align="center" className={classes.tdBody}>{item.percentage_section} {" % "}</TableCell>
                                    <TableCell align="center" className={classes.tdBody}>{item.maximum_points}</TableCell>
                                    <TableCell align="center" className={classes.tdBody}>{item.supplier_points}</TableCell>
                                    <TableCell align="center" className={classes.tdBody}>{item.rating_supplier_total} {" % "}</TableCell>
                                </TableRow>)
                            })
                            }
                        </TableBody>
                    </Table>
                </Grid>}
            </Grid>
        )
    }

}

GeneralInformation.propTypes = {
    questionnaire: PropTypes.object.isRequired,
    translate: PropTypes.func,
};

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
    },
    headerTable: {
        backgroundColor: "#dee2e6",
        color: "white",
        padding: "1rem",
    },
    tdHeader: {
        border: "1px solid rgba(67, 119, 196, 1)",
        color: "black"
    },
    tdBody: {
        border: "1px solid #000",
    }
});

export default withStyles(styles, { withTheme: true })(GeneralInformation);