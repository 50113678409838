import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import { Grid, ListItem, MenuItem, Select } from '@material-ui/core'

/** Import component section */
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'
import { COMPANY_PTM, SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import { GetPositionPTM } from '../../../store/helpers/SelectOptions'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

/**
 * Class compoenent to add an employee
 *
 * @class AddEmployeeDialog
 * @extends {Component}
 */
class AddEmployeeDialog extends Component {
	/**
	 *Creates an instance of DialogActions.
	 * @param {*} props
	 * @memberof DialogActions
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			token: this.props.editEployeed ? this.props.editEployeed.token : '',
			employee_full_name: this.props.editEployeed ? this.props.editEployeed.name : '',
			social_security_number: this.props.editEployeed ? this.props.editEployeed.insurance_number : '',
			short_Name: this.props.editEployeed ? this.props.editEployeed.short_Name : '',
			first_Surname: this.props.editEployeed ? this.props.editEployeed.first_Surname : '',
			second_Surname: this.props.editEployeed ? this.props.editEployeed.second_Surname : '',
			rfc_number: this.props.editEployeed ? this.props.editEployeed.rfc_number : '',
			curp_Number: this.props.editEployeed ? this.props.editEployeed.curp_Number : '',
			position: this.props.editEployeed ? this.props.editEployeed.position : '',
			department: this.props.editEployeed ? this.props.editEployeed.department : '',
			is_active: this.props.editEployeed ? this.props.editEployeed.is_active : '',
		}
	}

	/**
	 * Function to update cubicaje property of text field in state
	 *
	 * @param {*} data
	 * @memberof AddEmployeeDialog
	 * @param {*} property
	 * @param {*} data
	 * @memberof AddServiceProjectDialog
	 */
	updateProperty(property, data) {
		this.setState({
			[property]: data,
		})
	}

	/**
	 * Function to trigger blur event
	 *
	 * @param {*} event
	 * @memberof AddEmployeeDialog
	 */
	onKeyDown(event) {
		if (event.key === 'Enter') document.activeElement.blur()
	}

	/**
	 * method to call action and add employee
	 *
	 * @memberof AddEmployeeDialog
	 */
	onAddEmployee() {
		if (this.props.addEmployee) {
			this.props.addEmployee(this.state)
		} else if (this.props.updateEmployee) {
			this.props.updateEmployee(this.state)
		}
	}

	/**
	 * Handle  Close or cancel event
	 *
	 * @memberof AddEmployeeDialog
	 */
	handleCloseCancel() {
		this.setState(
			{
				employee_full_name: '',
				social_security_number: '',
				short_Name: '',
				first_Surname: '',
				second_Surname: '',
				rfc_number: '',
				curp_Number: '',
				position: '',
				department: '',
			},
			() => this.props.onClose()
		)
	}

	/**
	 * Method to validate if required properties has data
	 *
	 * @returns
	 * @memberof AddEmployeeDialog
	 */

	isValidToSave() {
		if (this.props.selectedCompany.company_code === COMPANY_PTM) {
			if (
				this.state.short_Name &&
				!IsNullOrEmpty(this.state.short_Name) &&
				this.state.first_Surname &&
				!IsNullOrEmpty(this.state.first_Surname) &&
				this.state.social_security_number &&
				!IsNullOrEmpty(this.state.social_security_number) &&
				this.state.social_security_number.length === 11 &&
				this.validateRFC()
			) {
				return true
			}
			return false
		} else {
			if (
				this.state.employee_full_name &&
				!IsNullOrEmpty(this.state.employee_full_name) &&
				this.state.social_security_number &&
				!IsNullOrEmpty(this.state.social_security_number) &&
				this.state.social_security_number.length === 11
			) {
				return true
			}
			return false
		}
	}

	validateRFC() {
		const { editEmployee } = this.state
		let rfcValidations = []
		if (editEmployee) {
			if (!IsNullOrEmpty(editEmployee.rfc_number)) {
				rfcValidations.push({
					key: 'rfc_number',
				})
			}
			let regexRFC = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/
			if (!regexRFC.test(editEmployee.rfc_number)) {
				rfcValidations.push({
					key: 'rfc_number',
				})
			}
			if (!IsNullOrEmpty(editEmployee.curp_Number)) {
				rfcValidations.push({
					key: 'curp_Number',
				})
			}
			let regexCURP =
				/([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
			if (!regexCURP.test(editEmployee.curp_Number)) {
				rfcValidations.push({
					key: 'curp_Number',
				})
			}
		}
		return rfcValidations
	}

	/**
	 * Method to render text field
	 *
	 * @param {*} textFieldId
	 * @param {*} textFieldTranslateId
	 * @returns
	 * @memberof AddEmployeeDialog
	 */
	renderOnlyTextField(textFieldId, textFieldTranslateId, helperText, typeText, autoFocus) {
		return (
			<TextField
				id={textFieldId}
				label={<Translate id={textFieldTranslateId} />}
				value={this.state[textFieldId] ? this.state[textFieldId] : ''}
				margin='normal'
				type={typeText}
				fullWidth
				required
				helperText={<Translate id={helperText} />}
				onBlur={(event) => this.updateProperty(textFieldId, event.target.value)}
				onChange={(event) => this.updateProperty(textFieldId, event.target.value)}
				onKeyDown={this.onKeyDown}
			/>
		)
	}
	renderOnlyText(textFieldId, textFieldTranslateId, helperText, typeText, textFirst, textSecond) {
		return (
			<Grid container spacing={8} justify='space-between'>
				<Grid item xs={3}>
					<TextField
						id={textFieldId}
						label={<Translate id={textFieldTranslateId} />}
						value={this.state[textFieldId] ? this.state[textFieldId] : ''}
						type={typeText}
						required
						helperText={<Translate id={helperText} />}
						onBlur={(event) => this.updateProperty(textFieldId, event.target.value)}
						onChange={(event) => this.updateProperty(textFieldId, event.target.value)}
						onKeyDown={this.onKeyDown}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						id={textFirst}
						label={<Translate id={'contractorsModule.firstSurname'} />}
						value={this.state[textFirst] ? this.state[textFirst] : ''}
						type={'text'}
						required
						helperText={<Translate id={'contractorsModule.firstSurnameMessage'} />}
						onBlur={(event) => this.updateProperty(textFirst, event.target.value)}
						onChange={(event) => this.updateProperty(textFirst, event.target.value)}
						onKeyDown={this.onKeyDown}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						id={textSecond}
						label={<Translate id={'contractorsModule.secondSurname'} />}
						value={this.state[textSecond] ? this.state[textSecond] : ''}
						type={'text'}
						helperText={<Translate id={'contractorsModule.secondSurnameMessage'} />}
						onBlur={(event) => this.updateProperty(textSecond, event.target.value)}
						onChange={(event) => this.updateProperty(textSecond, event.target.value)}
						onKeyDown={this.onKeyDown}
					/>
				</Grid>
			</Grid>
		)
	}
	renderItemList(textFieldId, textFieldTranslateId, typeText, textFirst, positionPTM) {
		return (
			<Grid container spacing={12} justify='space-between'>
				<Grid item xs={6}>
					<TextField
						id={textFirst}
						label={<Translate id={'contractorsModule.department'} />}
						value={this.state[textFirst] ? this.state[textFirst] : ''}
						type={typeText}
						required
						onBlur={(event) => this.updateProperty(textFirst, event.target.value)}
						onChange={(event) => this.updateProperty(textFirst, event.target.value)}
						onKeyDown={this.onKeyDown}
					/>
				</Grid>
				<Grid item xs={6}>
					<ListItem divider key={textFieldId}>
						<Typography
							component='span'
							variant='body2'
							className={this.props.classes.inline}
							color='rgba(0, 0, 0, 0.54)'
						>
							<Translate id={textFieldTranslateId} />
						</Typography>
						<Select
							fullWidth
							required
							value={this.state[textFieldId] ? this.state[textFieldId] : ''}
							onChange={(event) => {
								this.updateProperty(textFieldId, event.target.value)
							}}
							styles={{
								menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
								menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
								menuPortal: (base) => ({ ...base, zIndex: 9999 }),
							}}
							menuPortalTarget={document.parentNode}
							menuPosition='fixed'
							menuPlacement={'auto'}
						>
							{positionPTM.map((positionPTM) => (
								<MenuItem key={positionPTM.value} value={positionPTM.value}>
									{positionPTM.label}
								</MenuItem>
							))}
						</Select>
					</ListItem>
				</Grid>
			</Grid>
		)
	}

	render() {
		const { classes } = this.props
		const positionPTM = GetPositionPTM()
		return (
			<Dialog
				open={this.props.open}
				onClose={() => this.handleCloseCancel()}
				header={
					<Toolbar>
						{this.props.editEployeed ? (
							<Typography variant='h6'>
								<Translate id='contractorsModule.editEmployee' />
							</Typography>
						) : (
							<Typography variant='h6'>
								<Translate id='contractorsModule.addEmployee' />
							</Typography>
						)}
					</Toolbar>
				}
				actions={
					<div>
						{!this.isValidToSave() && (
							<Typography variant='caption' color='error'>
								{<Translate id='common.requiredFields' />}
							</Typography>
						)}
						<DialogActions
							view={SHOW_EDIT_VIEW}
							classes={classes}
							onCancel={() => this.handleCloseCancel()}
							onClose={() => this.handleCloseCancel()}
							onSaveItem={this.onAddEmployee}
							isSaving={this.props.isSaving}
							canBeSaved={this.isValidToSave()}
							company={this.props.selectedCompany}
						/>
					</div>
				}
			>
				<form className={classes.modal}>
					{this.props.selectedCompany.company_code !== COMPANY_PTM &&
						this.renderOnlyTextField(
							'employee_full_name',
							'users.name',
							'contractorsModule.employeeMesasge',
							'text',
							true
						)}
					{this.props.selectedCompany.company_code === COMPANY_PTM &&
						this.renderOnlyText(
							'short_Name',
							'contractorsModule.shortName',
							'contractorsModule.shortNameMessage',
							'text',
							'first_Surname',
							'second_Surname'
						)}
					{this.renderOnlyTextField(
						'social_security_number',
						'contractorsModule.socialSecurityNumber',
						'contractorsModule.socialSecurityNumberMessage',
						'number'
					)}
					{this.props.selectedCompany.company_code === COMPANY_PTM &&
						this.renderOnlyTextField(
							'rfc_number',
							'contractorsModule.rfcNumber',
							'contractorsModule.rfcNumberMessage',
							'text'
						)}
					{this.props.selectedCompany.company_code === COMPANY_PTM &&
						this.renderOnlyTextField(
							'curp_Number',
							'contractorsModule.curpNumber',
							'contractorsModule.curpNumberMessage',
							'text'
						)}
					{this.props.selectedCompany.company_code === COMPANY_PTM &&
						this.renderItemList(
							'position',
							'contractorsModule.position',
							'text',
							'department',
							positionPTM
						)}
					{this.props.editEployeed && (
						<FormControlLabel
							control={
								<Checkbox
									onChange={(event) => {
										this.updateProperty('is_active', event.target.checked)
									}}
									color='primary'
									value='status'
									checked={this.state.is_active}
								/>
							}
							label={<Translate id='warehouseWorkers.status' />}
						/>
					)}
				</form>
			</Dialog>
		)
	}
}

const styles = (theme) => ({
	modal: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(AddEmployeeDialog))
