import { fromJS, Map } from 'immutable'

import * as employeesActions from './EmployeesAction'

/** Initial state */
export const employeesInitialState = fromJS({
	isLoadingEmployees: true,
	employees: [],
	isLoadingEmployee: false,
	employee: null,
	sortCriteria: {
		by: 'ascending',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	employeesCount: null,
	isSavingEmployee: false,
	isCloseRergisters: false,
})

export default function employeesReducer(state = employeesInitialState, action) {
	switch (action.type) {
		case employeesActions.IS_LOADING_EMPLOYEES: {
			return state.merge({
				isLoadingEmployees: action.isLoadingEmployees,
			})
		}
		case employeesActions.SET_EMPLOYEES_LIST: {
			let employeesLists = fromJS(action.employees)
			return state.merge({
				employees: employeesLists,
				isLoadingEmployees: false,
			})
		}
		case employeesActions.IS_LOADING_EMPLOYEE: {
			return state.merge({
				isLoadingEmployee: action.isLoadingEmployee,
			})
		}
		case employeesActions.IS_SAVING_EMPLOYEE: {
			return state.merge({
				isSavingEmployee: action.isSavingEmployee,
			})
		}
		case employeesActions.SET_EMPLOYEE: {
			return state.merge({
				isLoadingEmployee: false,
				isSavingEmployee: false,
				employee: fromJS(action.employee),
			})
		}
		case employeesActions.CHANGE_EMPLOYEES_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case employeesActions.CHANGE_EMPLOYEES_QUERY: {
			return state.merge({
				searchQuery: action.query,
			})
		}
		case employeesActions.CHANGE_EMPLOYEES_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case employeesActions.SET_EMPLOYEES_COUNT: {
			return state.merge({
				employeesCount: action.count,
			})
		}
		case employeesActions.ADD_EMPLOYEE: {
			let employee = Map(action.employee)
			let employeesList = state.get('employees').push(employee)
			return state.merge({
				employees: employeesList,
				isSavingEmployee: false,
			})
		}
		case employeesActions.UPDATE_EMPLOYEE: {
			return state.merge({
				isSavingEmployee: action.isSavingEmployee,
			})
		}
		case employeesActions.IS_CLOSED_REGISTERS_ACCESS_CONTROL: {
			return state.merge({
				isCloseRergisters: action.isCloseRergisters,
			})
		}
		case employeesActions.ADD_FILE_EMPLOYEE: {
			let oldList = state.getIn(['employee', 'identifications']).toJS()
			let filesToAdd = action.files.filter((newFile) => {
				return !oldList.find((uploadedFile) => {
					return uploadedFile.name === newFile.name
				})
			})
			let newList = [...oldList, ...filesToAdd]
			return state.setIn(['employee', 'identifications'], fromJS(newList))
		}
		case employeesActions.REMOVE_FILE_EMPLOYEE: {
			let oldList = state.getIn(['employee', 'identifications']).toJS()
			let newList = oldList.filter((file) => {
				return file.name !== action.fileName
			})
			return state.setIn(['employee', 'identifications'], fromJS(newList))
		}
		default:
			return state
	}
}
