import { fromJS } from 'immutable'

import * as deliveryQualificationsAction from './deliveryQualificationsActions'

export const deliveryQualificationsInitialState = fromJS({
	isLoading: false,
	sortCriteria: {
		by: 'creation_date',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	deliveryQualificationsCount: null,
	deliveryQualifications: null,
	deliveryQualification: null,
	isSaving: false,
})

export default function deliveryQualificationsReducer(state = deliveryQualificationsInitialState, action) {
	switch (action.type) {
		case deliveryQualificationsAction.CHANGE_DELIVERY_QUALIFICATIONS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case deliveryQualificationsAction.CHANGE_DELIVERY_QUALIFICATIONS_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case deliveryQualificationsAction.SET_DELIVERY_QUALIFICATIONS_LIST: {
			let deliveryQualifications = fromJS(action.deliveryQualifications)
			return state.merge({
				deliveryQualifications: deliveryQualifications,
			})
		}
		case deliveryQualificationsAction.SET_DELIVERY_QUALIFICATION: {
			return state.merge({
				deliveryQualification: fromJS(action.deliveryQualification),
			})
		}
		case deliveryQualificationsAction.CHANGE_DELIVERY_QUALIFICATIONS_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case deliveryQualificationsAction.START_LOADING_DELIVERY_QUALIFICATIONS: {
			return state.merge({
				isLoading: action.isLoading,
			})
		}
		default:
			return state
	}
}
