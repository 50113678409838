import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Translate } from "react-localize-redux";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import SampleIcon from '@material-ui/icons/LocalShipping';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import QuotesIcon from '@material-ui/icons/AttachMoneyTwoTone';
import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import SuppliersSelector from '../../common/SupplierSelector'
import SupplierByCompnaySelector from '../../common/SupplierByCompnaySelector'


/**
 * MassiveNoticeItemsDialog item lateral options panels
 *
 * @class MassiveNoticeItemsDialog
 * @extends {Component}
 */
class MassiveNoticeItemsDialog extends Component {

    /**
     *Creates an instance of MassiveNoticeItemsDialog.
     * @param {*} props
     * @memberof MassiveNoticeItemsDialog
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            suppliersSelected: []
        }
    }

    /**
     * on remove notification to supplier
     *
     * @param {*} item
     * @param {*} quote
     * @memberof MassiveNoticeItemsDialog
     */
    onRemoveNotificationSupplierClick(supplier) {
        if (this.props.onRemoveNoticeSupplier) {
            this.props.onRemoveNoticeSupplier(supplier.token)
                .then(() => {
                    this.setState({
                        suppliersSelected: this.state.suppliersSelected.filter(supplierSelected => supplierSelected.token !== supplier.token)
                    })
                })
        }
    }

    handleChangeIsQuoteOrSampleRequiredInState(supplierToken, isRequired, property) {
        let updatedSuppliers = [...this.state.suppliersSelected];
        let indexToUpdate = updatedSuppliers.findIndex(x => x.token === supplierToken);
        let updatedSupplier = { ...updatedSuppliers[indexToUpdate] };
        updatedSupplier[property] = isRequired;
        updatedSuppliers[indexToUpdate] = updatedSupplier;
        this.setState({ suppliersSelected: updatedSuppliers });
    }

    /**
     * On add sample request to supplier
     */
    onAddSupplierSelectedClick(supplier) {
        if (this.props.onSupplierSelected) {
            this.props.onSupplierSelected(supplier)
                .then(() => {
                    this.handleChangeIsQuoteOrSampleRequiredInState(supplier.token, true, "is_quote_requiered");
                })
        }
    }

    /**
     * On remove supplier
     *
     * @memberof MassiveNoticeItemsDialog
     */
    onRemoveSupplierClick(supplier) {
        if (this.props.onRemoveSupplier) {
            this.props.onRemoveSupplier(supplier.token)
                .then(() => {
                    this.handleChangeIsQuoteOrSampleRequiredInState(supplier.token, false, "is_quote_requiered");
                })
        }
    }


    /**
     * On add sample request to supplier
     */
    onAddSampleRequestSupplierClick(supplier) {
        if (this.props.onAddSampleRequestSupplier) {
            this.props.onAddSampleRequestSupplier(supplier)
            .then(() => {
                this.handleChangeIsQuoteOrSampleRequiredInState(supplier.token, true, "is_sample_requiered");
            })
        }
    }

    /**
     * On remove sample request to supplier
     */
    onRemoveSampleRequestSupplierClick(supplier) {
        if (this.props.onRemoveSampleRequestSupplier) {
            this.props.onRemoveSampleRequestSupplier(supplier.token)
            .then(() => {
                this.handleChangeIsQuoteOrSampleRequiredInState(supplier.token, false, "is_sample_requiered");
            })
        }
    }

    onSupplierNoticeSelected(supplier) {
        this.setState({
            suppliersSelected: this.state.suppliersSelected.concat(supplier)
        }, () => {
            this.props.onSupplierNoticeSelected(null, supplier, true)
        });
    }

    /**
     *
     *
     * @memberof MassiveNoticeItemsDialog
     */
    getSelectedSuppliers() {
        const { classes, companyImbera } = this.props
        return this.state.suppliersSelected.length > 0 && this.state.suppliersSelected.map((supplier) => {
            return (
                <ListItem key={supplier && supplier.token} dense> 
                    <ListItemText primary={supplier && supplier.name} />
                    <ListItemSecondaryAction>
                        <React.Fragment>
                            {
                                supplier &&
                                <Tooltip title={<Translate id={supplier.is_quote_requiered ?
                                    "noticeChanges.removeRequestQuoteTooltipMessage"
                                    : "noticeChanges.requestRequestTooltipMessage"} />}>
                                    <IconButton
                                        color={supplier.is_quote_requiered ? "primary" : 'inherit'}
                                        onClick={() => {
                                            supplier.is_quote_requiered ?
                                                this.onRemoveSupplierClick(supplier)
                                                : this.onAddSupplierSelectedClick(supplier)
                                        }}>
                                        <QuotesIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {(supplier && supplier.is_quote_requiered) && !companyImbera &&
                                <Tooltip title={<Translate id={supplier.is_sample_requiered ?
                                    "noticeChanges.removeRequestSampleTooltipMessage"
                                    : "noticeChanges.requestSampleTooltipMessage"} />}>
                                    <IconButton
                                        color={supplier.is_sample_requiered ? "primary" : 'inherit'}
                                        onClick={() => {
                                            supplier.is_sample_requiered ?
                                                this.onRemoveSampleRequestSupplierClick(supplier)
                                                : this.onAddSampleRequestSupplierClick(supplier)
                                        }}
                                    >
                                        <SampleIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title={<Translate id="noticeChanges.removeSupplierRequestTooltipMessage" />}>
                                <IconButton
                                    className={classes.deleteButton}
                                    onClick={() => { this.onRemoveNotificationSupplierClick(supplier) }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    </ListItemSecondaryAction>
                </ListItem>
            )
        })
    }

    /**
     *
     *
     * @returns
     * @memberof MassiveNoticeItemsDialog
     */
    render() {
         const { companyImbera } = this.props;
        return (
            <Dialog
                open={this.props.show}
            >
                <DialogTitle id="form-dialog-title">
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="caption" gutterBottom>
                                <Translate id="memos.addSupplierToMemoMessage" />
                            </Typography>
                            {!companyImbera && <SuppliersSelector
                                onSupplierSelected={(supplier) => { this.onSupplierNoticeSelected(supplier) }}
                            />}
                            {companyImbera &&<SupplierByCompnaySelector
                                onSupplierSelected={(supplier) => { this.onSupplierNoticeSelected(supplier) }}
                            />}
                            <List dense style={{ maxHeight: "30vh", overflowY: "auto" }}>
                                {this.getSelectedSuppliers()}
                            </List>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        <Translate id="common.close" />
                    </Button>
                </DialogActions>
            </Dialog >
        );
    }
}

const styles = theme => ({
    deleteButton: {
        color: "red"
    }
})

export default withStyles(styles, { withTheme: true })(MassiveNoticeItemsDialog);