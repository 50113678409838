import { fromJS } from 'immutable'

import * as qualityCertificateActions from './qualityCertificateAction'

export const qualityCertificateInitialstate = fromJS({
	isLoading: false,
	requestsCount: 0,
	isSavingAdd: false,
	isSavingCertificate: false,
	request: null,
	qualityCertificate: null,
	identifier: '',
	isDonwloading: false,
})

export default function qualityCertificateReducer(state = qualityCertificateInitialstate, action) {
	switch (action.type) {
		case qualityCertificateActions.START_LOADING_QUALITY_LIST: {
			return state.merge({
				isLoading: action.isLoading,
			})
		}
		case qualityCertificateActions.SET_QUALITY_LIST: {
			return state.merge({
				request: action.qualityList,
				isLoading: false,
			})
		}
		case qualityCertificateActions.SET_QUALITY_COUNT: {
			return state.merge({
				requestsCount: action.count,
			})
		}
		case qualityCertificateActions.START_ADD_QUALIRT_CERTIFICATE: {
			return state.merge({
				isSavingAdd: action.isSaving,
			})
		}
		case qualityCertificateActions.ADD_QUALITY_CERTIFICATE: {
			let qualityCertificate = fromJS(action.qualityCertificate)
			return state.updateIn(['request'], (request) => request.push(qualityCertificate)).set('isSavingAdd', false)
		}
		case qualityCertificateActions.SET_QUALITY_CERTIFICATE: {
			return state.merge({
				qualityCertificate: fromJS(action.qualityCertificate),
				isLoading: false,
			})
		}
		case qualityCertificateActions.IS_SAVING_QUALITY_CERTIFICATE: {
			return state.merge({
				isSavingCertificate: action.isSaving,
			})
		}
		case qualityCertificateActions.IS_DOWNLOADING_CERTIFICATE: {
			return state.merge({
				isDonwloading: action.isDonwloading,
			})
		}
		default:
			return state
	}
}
