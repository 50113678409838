///Import react section
import { useSelector, useDispatch } from 'react-redux'
/**Import actions sections */
import {
	setToasterOptions,
	importIndirectQualificationTemplateFile,
	loadIndirectQualificationFromBackEnd,
	setStatusRating,
	addCommentsToSupplier,
	addIndirectQualificationToSupplier,
	generateActionPlantForSupplier,
	updateActionPlanFromResponsible,
	updateActionPlanFromBackend,
	downloadFilesActionPlant,
	removeFileInActionPlan,
	updateActionPlanFromSupplier,
	addEvidenceInActionPlan,
	setStatusSupplier,
	downloadReportRatingsDetail,
	addItemComments,
} from '../../../store/indirectqualifications/IndirectqualificationsActions'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { converListToArray } from '../../../store/helpers/ListHelper'

export const useIndirectQualificationCustomHookShow = () => {
	const user = useSelector((state) => state.oidc.user)
	const indirectQualification = useSelector((state) =>
		state.indirectQualifications.get('indirectQualification')
			? state.indirectQualifications.get('indirectQualification').toJS()
			: null
	)
	const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'))
	const supplier = useSelector((state) =>
		state.indirectQualifications.get('supplier') ? state.indirectQualifications.get('supplier').toJS() : null
	)
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	/**Render hooks section */
	const dispatch = useDispatch()

	const onCloseToaster = () => {
		dispatch(
			setToasterOptions({
				showToaster: false,
				toasterMessage: '',
				toasterVariant: '',
			})
		)
	}

	const onImportIndirectQualificationTemplateFile = (file, id) => {
		return dispatch(importIndirectQualificationTemplateFile(file, id))
			.then(() => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.addCorrectly',
					})
				)
				return Promise.resolve(true)
			})
			.catch((error_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorImportStatus(error_),
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	/**
	 * Method to show error in import suppliers
	 * @param {*} error
	 */
	const getErrorImportStatus = (response) => {
		if (!IsNullOrEmpty(response.detail) && typeof response.detail === 'string') {
			if (response.detail.includes('invalidSupplierCode'))
				return 'indirectqualifications.error.invalidSupplierCode'
			else if (response.detail.includes('invalidResponsbleEvaluator'))
				return 'indirectqualifications.error.invalidResponsbleEvaluator'
			else if (response.detail.includes('invalidTheSupplierAlreadyRatingInMonth'))
				return 'indirectqualifications.error.invalidTheSupplierAlreadyRatingInMonth'
			else return 'common.errorToSave'
		} else {
			return 'common.errorToSave'
		}
	}

	const onSetStatusInRating = (id, status, comments) => {
		return dispatch(setStatusRating(id, status, comments))
			.then(() => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.addCorrectly',
					})
				)
				return Promise.resolve(true)
			})
			.catch((error_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorInChangeStatus(error_),
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	/**
	 * Method to show error in import suppliers
	 * @param {*} error
	 */
	const getErrorInChangeStatus = (response) => {
		if (!IsNullOrEmpty(response) && typeof response === 'string') {
			if (response.includes('indirectqualifications.show.invalidStatusToSendToEvaluate'))
				return 'indirectqualifications.show.invalidStatusToSendToEvaluate'
			else if (response.includes('indirectqualifications.show.invalidSuppliers'))
				return 'indirectqualifications.show.invalidSuppliers'
			else if (response.includes('indirectqualifications.show.invalidCancelFolio'))
				return 'indirectqualifications.show.invalidCancelFolio'
			else if (response.includes('indirectqualifications.show.invalidGenerateRating'))
				return 'indirectqualifications.show.invalidGenerateRating'
			else if (response.includes('indirectqualifications.show.invalidPublishRating'))
				return 'indirectqualifications.show.invalidPublishRating'
			else if (response.includes('invalidActionsFromSupplier'))
				return 'indirectqualifications.error.invalidActionsFromSupplier'
			else if (response.includes('invalidSendActionsPlanSupplier'))
				return 'sustainability.error.invalidSendActionsPlanSupplier'
			else if (response.includes('notExtistActionPlansGenerated'))
				return 'indirectqualifications.error.notExtistActionPlansGenerated'
			else if (response.includes('invalidToReevaluteQuestionnaire'))
				return 'indirectqualifications.error.invalidToReevaluteQuestionnaire'
			else if (response.includes('indirectqualifications.show.invalidRemoveFolio'))
				return 'indirectqualifications.show.invalidRemoveFolio'
			else if (response.includes('indirectqualifications.show.requiredCommentToQualification'))
				return 'indirectqualifications.show.requiredCommentToQualification'
			else return 'common.errorToSave'
		} else {
			return 'common.errorToSave'
		}
	}

	const onAddCommentsToSupplier = (supplier) => {
		return dispatch(addCommentsToSupplier(supplier))
			.then(() => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.addCorrectly',
					})
				)
				return Promise.resolve(true)
			})
			.catch((_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	const onAddIndirectQualificationToSupplier = (item) => {
		return dispatch(addIndirectQualificationToSupplier(item))
			.then(() => {
				return Promise.resolve(true)
			})
			.catch((error_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorInChangeStatus(error_),
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	const onAddItemComments = (item) => {
		return dispatch(addItemComments(item))
			.then(() => {
				return Promise.resolve(true)
			})
			.catch((error_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorInChangeStatus(error_),
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	const onGenerateActionPlantForSupplier = (item) => {
		return dispatch(generateActionPlantForSupplier(item))
			.then(() => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.addCorrectly',
					})
				)
				return Promise.resolve(true)
			})
			.catch((_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	const onLoadIndirectQualificationFromBackEnd = (id) => {
		return dispatch(loadIndirectQualificationFromBackEnd(id))
	}

	const toasterError = (identifierError, name) => {
		dispatch(
			setToasterOptions({
				showToaster: true,
				toasterMessage: identifierError,
				toasterVariant: 'error',
				name: name,
			})
		)
	}

	/**Action plans actions */
	const onUpdateActionPlanFromResponsible = (ratingId, supplierId, actionPlanModel, checAproved, listActions) => {
		return dispatch(
			updateActionPlanFromResponsible(ratingId, supplierId, actionPlanModel, checAproved, listActions)
		)
			.then(() => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.addCorrectly',
					})
				)
				return Promise.resolve(true)
			})
			.catch((_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	const onUpdateActionPlanFromBackend = (ratingId, supplierId, actionPlanId, property, propertyData, listActions) => {
		return dispatch(
			updateActionPlanFromBackend(ratingId, supplierId, actionPlanId, property, propertyData, listActions)
		)
			.then(() => {
				return Promise.resolve(true)
			})
			.catch((_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	const onUpdateActionPlanFromSupplier = (ratingId, supplierId, actionPlanModel, listActions) => {
		return dispatch(updateActionPlanFromSupplier(ratingId, supplierId, actionPlanModel, listActions))
			.then(() => {
				return Promise.resolve()
			})
			.catch((_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	const onDownloadFilesActionPlant = (id, fileName, extension) => {
		return dispatch(downloadFilesActionPlant(id, fileName, extension))
			.then(() => {
				return Promise.resolve(true)
			})
			.catch((_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	const onRemoveFileInActionPlan = (ratingId, supplierId, actionPlantId, fileName, listActions) => {
		return dispatch(removeFileInActionPlan(ratingId, supplierId, actionPlantId, fileName, listActions))
			.then(() => {
				return Promise.resolve(true)
			})
			.catch((_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	const onAddEvidenceInActionPlan = (id, supplierId, actionPlanId, files, listActions) => {
		return dispatch(addEvidenceInActionPlan(id, supplierId, actionPlanId, files, listActions))
			.then(() => {
				return Promise.resolve(true)
			})
			.catch((_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	const onSetStatusInSupplier = (ratingId, supplierId, status, comments) => {
		return dispatch(setStatusSupplier(ratingId, supplierId, status, comments))
			.then(() => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.addCorrectly',
					})
				)
				return Promise.resolve(true)
			})
			.catch((error_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorInChangeStatus(error_),
						toasterVariant: 'error',
					})
				)
				Promise.reject(false)
			})
	}

	const onDownloadReportRatingsDetail = (id, evaluationType) => {
		return dispatch(downloadReportRatingsDetail(id, evaluationType))
			.then(() => {})
			.catch((response) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error',
					})
				)
			})
	}

	return {
		user,
		userRolesByCompany,
		indirectQualification,
		supplier,
		isSaving,
		onCloseToaster,
		onImportIndirectQualificationTemplateFile,
		onLoadIndirectQualificationFromBackEnd,
		toasterError,
		onSetStatusInRating,
		onAddCommentsToSupplier,
		onAddIndirectQualificationToSupplier,
		onGenerateActionPlantForSupplier,
		onUpdateActionPlanFromBackend,
		onUpdateActionPlanFromResponsible,
		onDownloadFilesActionPlant,
		onRemoveFileInActionPlan,
		onUpdateActionPlanFromSupplier,
		onAddEvidenceInActionPlan,
		onSetStatusInSupplier,
		onDownloadReportRatingsDetail,
		onAddItemComments,
	}
}
