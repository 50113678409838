import { createSelector } from 'reselect'
import { toSafeJS } from '../helpers/ImmutableHelper'

const getIndexState = (state) => state.requestPriceChange

/**
 * Get isLoading
 */
export const getIsLoading = createSelector([getIndexState], (indexState) => {
	const isLoading = indexState.get('isLoadingPrice')

	return isLoading ? toSafeJS(isLoading) : false
})
/**
 * Get request price change in draft
 */
export const getRequestPriceChangeDraft = createSelector([getIndexState], (indexState) => {
	const requestPriceChangeDraft = indexState.get('requestPriceChangeDraft')

	return requestPriceChangeDraft ? toSafeJS(requestPriceChangeDraft) : null
})
/**
 * Get sortCriteria
 */
export const getSortCriteria = createSelector([getIndexState],(indexState)=> {
	const sortCriteria =  indexState.get('sortCriteria')
	return sortCriteria ? toSafeJS(sortCriteria): null
})
/**
 * Get searchQuery
 */
export const getSearchQuery = createSelector([getIndexState],(indexState)=> {
	const searchQuery =  indexState.get('searchQuery')
	return searchQuery ? toSafeJS(searchQuery): null
})
/**
 * Get searchQuery
 */
export const getPagination = createSelector([getIndexState],(indexState)=> {
	const page =  indexState.get('page')
	const rowsPerPage = indexState.get('rowsPerPage')
	const total = indexState.get('priceChangeRequestsCount')
	const pagination = {page,rowsPerPage,total} 
	return pagination ? toSafeJS(pagination): null
})
/**
 * Get requests list
 */
export const getRequestsList = createSelector([getIndexState],(indexState)=> {
	const priceChangeRequests =  indexState.get('priceChangeRequests')
	return priceChangeRequests ? toSafeJS(priceChangeRequests): []
})