import React, { useCallback, useState } from 'react';
import DialogOperationComponent from './DialogOperationComponent';

export default function DialogOperationContainer({
    title,
    options
}) {
    const [isOpen, setIsOpen] = useState(false);

    const onOpenAndClose = () => {
        setIsOpen(!isOpen);
    };

    const DialogOperations = useCallback(
        () => (
            <DialogOperationComponent
                title={title}
                open={isOpen}
                options={options}
                onClose={onOpenAndClose}
            />
        ),
        [isOpen]
    );

    return {
        DialogOperations,
        onOpenAndClose,
        isOpen
    };
}