import React from 'react';
import PropTypes from 'prop-types';

const Spacer = ({ width = '5px' }) => <span style={{ display: 'inline-block', width: width }} />;

Spacer.propTypes = {
	width: PropTypes.string
};

export default Spacer;
