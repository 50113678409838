/** react imports section */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'
/** Material-UI imports section */
import { Card, Divider, Collapse, CardActionArea, withStyles, Typography, Grid, CardHeader } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/AddTwoTone'
import DeleteIcon from '@material-ui/icons/DeleteForever'
/** imports section */
import DeliveriesPanel from './DeliveriesPanel'
import CreateMaterialPanel from './CreateMaterialPanel'

import DealsHeader from './DealsHeader'
import DeliveryContent from './DeliveryContent'
import ThereIsNoInformation from '../../common/ThereIsNoInformation'
import {
	deleteDelivery,
	deleteDestination,
	deleteMaterial,
} from '../../../../store/transportTender/transportTenderAction'
import RequierementClientDialog from './requieremetClientDialog'
import { COLOR_STATUS_ALERT_INFO } from '../../../../store/helpers/StatusColorConstants'
import { TransportTendeStatus } from '../../../../store/helpers/AppConstants'
import PopperListsComponent from '../../../biddings/components/PopperListsComponent'
const DealsCard = (props) => {
	const { classes, deals, transportTenderId, setOpenDealsPanel, setToasterMessageConfig, transportTenderdetail } =
		props
	const dispatch = useDispatch()
	console.log('transportTenderdetail', transportTenderdetail)
	const [openDeliveryPanel, setOpenDeliveryPanel] = useState({
		open: false,
		dealId: null,
		isEdit: false,
		delivery: null,
	})
	const [openCollapese, setOpenCollapese] = useState({ open: false, dealId: null })
	const [openMaterialPanel, setOpenMaterialPanel] = useState({ open: false, dealId: null, deliveryId: null })
	const [openWarningClient, setOpenWarningClient] = useState(false)
	const [repartSelect, setRepartSelected] = useState(null)
	const onDelete = (id) => {
		dispatch(deleteMaterial(id, transportTenderId))
			.then(() => {
				''
			})
			.catch((err) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'Error al eliminar el material',
					toasterVariant: 'error',
				})
			})
			.finally(() => {})
	}

	const onDeleteDelivery = (id) => {
		dispatch(deleteDelivery(id, transportTenderId))
			.then(() => {
				''
			})
			.catch((err) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'Error al eliminar el material',
					toasterVariant: 'error',
				})
			})
			.finally(() => {})
	}
	const onDeleteDestination = (item) => {
		dispatch(deleteDestination(item, transportTenderId))
			.then(() => {
				''
			})
			.catch((err) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'Error al eliminar el destino',
					toasterVariant: 'error',
				})
			})
			.finally(() => {})
	}
	const onWarningClient = (client) => {
		setOpenWarningClient(true)
		setRepartSelected(client)
	}

	const popperLists = (classes, deal) => {
		let options = []
		options.push({
			title: <Translate id={'carrierTenders.edit'} />,
			onClick: () => setOpenDealsPanel({ open: true, isEdit: true, deal: deal }),
			icon: <EditIcon className={classes.deleteIcon} />,
			isIconMaterial: true,
			disabled: false,
		})
		options.push({
			title: <Translate id='common.delete' />,
			onClick: () => onDeleteDestination(deal),
			icon: <DeleteIcon className={classes.editcon} />,
			isIconMaterial: true,
			disabled: false,
		})
		return options
	}

	return (
		<>
			{deals && deals.length > 0 ? (
				deals.map((deal) => {
					return (
						<Card key={deal.id}>
							<CardActionArea>
								<Divider />
								<DealsHeader
									classes={classes}
									deal={deal}
									setOpenPanel={setOpenDeliveryPanel}
									setOpenCollapese={setOpenCollapese}
									openCollapese={openCollapese}
									openPanel={openDeliveryPanel}
									setOpenDealsPanel={setOpenDealsPanel}
									onDeleteDestination={onDeleteDestination}
									transportTenderdetail={transportTenderdetail}
									setOpenWarningClient={onWarningClient}
								/>
							</CardActionArea>
							<Collapse
								in={openCollapese.open && openCollapese.dealId === deal.id}
								// in={true}
								timeout='auto'
								unmountOnExit
							>
								{deal &&
									deal.destinations.map((destinationDeal) => {
										return (
											<Grid container>
												<Grid item xs={12}>
													<CardHeader
														className={classes.card}
														title={
															<Typography>
																Folio letra-{destinationDeal.FolioLetter}
															</Typography>
														}
														action={
															<div className={classes.flexEnd}>
																{(transportTenderdetail.Status ===
																	TransportTendeStatus.DRAFT ||
																	transportTenderdetail.Status ===
																		TransportTendeStatus.SENT_TO_CARRIER) && (
																	<div className={classes.flexEnd}>
																		<Typography
																			gutterBottom
																			variant='body2'
																			component='p'
																		>
																			<Translate
																				id={'carrierTenders.deals.deliveries'}
																			/>
																		</Typography>

																		<AddIcon
																			onClick={() =>
																				setOpenDeliveryPanel({
																					open: true,
																					dealId: destinationDeal.Id,
																					isEdit: false,
																					delivery: null,
																				})
																			}
																			className={classes.addIcon}
																		/>
																	</div>
																)}
																{(transportTenderdetail.Status ===
																	TransportTendeStatus.DRAFT ||
																	transportTenderdetail.Status ===
																		TransportTendeStatus.SENT_TO_CARRIER) && (
																	<div lassName={classes.flexEnd}>
																		<PopperListsComponent
																			title={<Translate id='common.options' />}
																			listItems={popperLists(
																				classes,
																				destinationDeal
																			)}
																		/>
																	</div>
																)}
															</div>
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<DeliveryContent
														deliveries={destinationDeal.Deliveries}
														setOpenMaterialPanel={setOpenMaterialPanel}
														classes={classes}
														deal={destinationDeal}
														setOpenDeliveryPanel={setOpenDeliveryPanel}
														onDelete={onDelete}
														onDeleteDelivery={onDeleteDelivery}
														transportTenderdetail={transportTenderdetail}
													/>
												</Grid>
											</Grid>
										)
									})}
							</Collapse>
							<Divider />
							{openDeliveryPanel.open && (
								<DeliveriesPanel
									onClose={() =>
										setOpenDeliveryPanel({
											open: false,
											dealId: null,
											isEdit: false,
											delivery: null,
										})
									}
									dealId={deal.Id}
									openPanel={openDeliveryPanel}
									isEdit={openDeliveryPanel.isEdit}
									delivery={openDeliveryPanel.delivery}
									setToasterMessageConfig={setToasterMessageConfig}
									transportTenderdetail={transportTenderdetail}
								/>
							)}
							{openMaterialPanel.open && (
								<CreateMaterialPanel
									onClose={() =>
										setOpenMaterialPanel({ open: false, dealId: null, deliveryId: null })
									}
									dealId={deal.Id}
									openMaterialPanel={openMaterialPanel}
									transportTenderId={transportTenderId}
									setToasterMessageConfig={setToasterMessageConfig}
									transportTenderdetail={transportTenderdetail}
								/>
							)}
						</Card>
					)
				})
			) : (
				<ThereIsNoInformation />
			)}
			{openWarningClient && (
				<RequierementClientDialog
					open={openWarningClient}
					onClose={() => setOpenWarningClient(false)}
					client={repartSelect}
				/>
			)}
		</>
	)
}

const styles = (theme) => ({
	addIcon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		borderRadius: '16px',
		margin: '-15px 5px 0px 10px',
	},
	deleteIcon: {
		color: theme.palette.primary.main,
		//	margin: '-15px 10px 0px 5px',
	},
	editcon: {
		color: theme.palette.primary.main,
		borderRadius: '16px',
		//margin: '-15px 5px 0px 10px',
	},
	cardHeader: {
		paddingBottom: '0px',
		paddingTop: '8px',
		backgroundColor: '#F9F9F9',
	},
	expandIcon: {
		marginTop: '10px',
		marginRight: '10px',
	},
	deliveryContainer: {},
	clientContainer: {
		display: 'grid',
		gridTemplateColumns: '6fr  1fr  1fr 20px ',
		gridGap: '5px',
	},
	zoneContainer: {
		display: 'grid',
		gridTemplateColumns: '3fr  5fr',
		gridGap: '0px 5px',
	},
	flexStart: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	flexEnd: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	deliveryHeader: {
		display: 'grid',
		gridTemplateColumns: ' 40px auto',
	},
	warehouseIcon: {
		display: 'flex',
		alignItems: 'flex-start',
	},
	informationContainer: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr  1fr',
	},
	spaceBetween: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	divider: {
		margin: '10px 0px 10px',
	},
	checkbox: {
		margin: '-15px 0px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	maneuvers: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	iconWarning: {
		marginLeft: '8px',
		display: 'flex',
		justifyContent: 'center',
	},
	icon: {
		color: 'orange',
	},
	chipWarningContent: {
		backgroundColor: 'white',
		marginLeft: '8px',
		marginBottom: '2px',
		padding: '8px',
		border: 'solid 2px',
		borderColor: 'orange',
	},
	colorWarning: {
		color: 'orange',
	},
	card: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
		// padding: 0,
		// '&:last-child': {
		// 	padding: 0,
		// },
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(DealsCard))
