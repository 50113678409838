/**
 * Function to return an object of company data required to create PDF in Backend
 *
 * @export
 * @param {*} internalData
 * @returns
 */
export function internalCompanyData(internalData){
    let data=  
        {
            Name: internalData? internalData.name: "",
            ComercialName: internalData? internalData.commercial_name: "",
            RFC: internalData? internalData.rfc: "",
            Address: internalData? internalData.address: "",
            PostalCode: internalData? internalData.zip_code: ""
        }
    return data
  
};

/**
 * Function to return an object of supplier data required to create PDF in Backend
 *
 * @export
 * @param {*} supplierData
 * @returns
 */
export function supplierData(supplierData){
    let data=  
        {
            Name: supplierData? supplierData.name: "",
            FullName: supplierData? supplierData.full_name: "",
            Code: supplierData? supplierData.code: "",
            RFC: supplierData? supplierData.rfc: "",
            Address: supplierData? supplierData.address: "",
            City: supplierData? supplierData.city: "",
            FullAddress: supplierData? supplierData.full_address: "",
            PostalCode: supplierData? supplierData.postalCode: "",
            Logo: supplierData ? supplierData.token: ""
        }
    return data

};
