/**Import react section */
import React, { useState } from 'react';
import propTypes from 'prop-types'
import { withRouter } from "react-router-dom";

/** Language imports **/
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

/** Import component section */
import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';

const CardCreateSubTopic = (props) => {
    /**props section */
    const { open,onClose,
        isSaving, classes,
        onSaveItem, itemSelected, 
        isEdit, onCloseEdit,
        onSaveItemEdit, topicId,
        subTopicId
    } = props
    const [itemModel, setItemModel] = useState(itemSelected ? {topicId: topicId, subTopicId: subTopicId, name: itemSelected.Name } : { name: '' })

    /**
     * Handle update properties to set data in local state
     *
     * @param {*} property
     * @param {*} data
     * @memberof AddModal
     */
    const updateProperty = event => {
        let { name, value } = event.target;
        setItemModel({
            ...itemModel,
            [name]: value
        });
    }

    /**
     * Function on save update
     */
     const onSaveItemUpdate = () =>{
        if(props.onSaveItem)
        onSaveItem(itemModel);
    }
    /**
    * Function on save update
    */
     const onSaveItemEditSubTopic= () =>{
        if(props.onSaveItemEdit)
        onSaveItemEdit(itemModel);
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof AddModal
     */
    const isValidToSave = () => {
        if (IsNullOrEmpty(itemModel.name))
            return false;
        else
            return true;
    }

    return (
        <Dialog
            open = {open}
            onClose= {isEdit ? onCloseEdit : onClose}
            header={
                <Toolbar >
                    <Typography variant="h6">
                        {<Translate id="questionnaire.addSubTopic"/>}
                    </Typography>
                </Toolbar>
            }
            actions={
                <div>
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={isEdit ? onCloseEdit : onClose}
                        onClose={isEdit ? onCloseEdit : onClose}
                        onSaveItem={isEdit ? onSaveItemEditSubTopic : onSaveItemUpdate}
                        isSaving={isSaving}
                        canBeSaved={isValidToSave() || isSaving}
                    />
                </div>
            }>
            <form>
                <TextField fullWidth
                    name={"name"}
                    label={<Translate id="questionnaire.subTopicName"/>}
                    className={classes.textField}
                    multiline
                    rows="2"
                    defaultValue={itemModel.name}
                    onChange={updateProperty}
                    margin="normal"
                    variant="outlined"
                />
            </form>
        </Dialog>
    )
}

const styles = theme => ({
    modal: {
        marginRight: theme.spacing.unit * 2,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
});

CardCreateSubTopic.propTypes = {
    /** Close card */
    onClose: propTypes.func,
    /** Close card edit*/
    onCloseEdit: propTypes.func,
    onSaveItem: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    onSaveItemEdit: propTypes.func,
    topicId: propTypes.string,
    subTopicId: propTypes.string,
}

CardCreateSubTopic.defaultProps = {
    onClose: () =>
        console.warn('Callback [onClose] no defined'),
    onCloseEdit: () =>
        console.warn('Callback [onCloseEdit] no defined'),
    onSaveItem: () =>
        console.warn('Callback [onSaveItem] no defined'),
    onSaveItemEdit: () =>
        console.warn('Callback [onSaveItemEdit] no defined'),
    isSaving: false,
    topicId: '',
    subTopicId: '',
}

export default withRouter(withStyles(styles, { withTheme: true })(CardCreateSubTopic))