import React from 'react';
import PropTypes from 'prop-types';

/**Import material UI Section*/
import { Translate } from 'react-localize-redux';
import Root from '../../../../store/Root';

/**Import components section*/
import ActionsCriterias from './ActionsCriterias';
import ActionOpportunity from './ActionsOppornunity';
import { Document, Page, View, StyleSheet, Text } from '@react-pdf/renderer';
import { withLocalize } from 'react-localize-redux';


const PdfDocument = props => {
    const { actionsCriterias, actionsOppornunity, sustainability } = props

    return (
    <Root>
        <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.tittle}>
            <Text>{<Translate id='sustainability.titlePdfActionPlan'/>}</Text>
            <Text>{`${sustainability.SupplierCode} | ${sustainability.SupplierName}`}</Text>
        </View>
        <View style={styles.container}>
            <View style={styles.actionsCriterias}>
                <ActionsCriterias
                    actionsCriterias={actionsCriterias}/>
            </View>
            <View style={styles.actionsOppornunity}>
                <ActionOpportunity
                    actionsOppornunity={actionsOppornunity}/>
            </View>
        </View>
      </Page>
    </Document> 
    </Root>
    );
};

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    container: {
        display: "flex",
    },
    actionsCriterias: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px',
    },
    actionsOppornunity: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px',
    },
    tittle: {
        textAlign: 'center',
        fontSize: '13',
        fontWeight: 'bold',
        marginTop: '3px',
        marginBottom: '5px',
    }
  });

PdfDocument.propTypes = {
    topics:PropTypes.array  
};

PdfDocument.defaultProps = {
    topics: [],
    sustainability: {}
};
export default withLocalize(PdfDocument);