/**
 * Method to show succes toaster 
 * with custom message or default "saveCorrectly"
 *
 * @export
 * @param {string} [idTranslation="common.saveCorrectly"]
 * @returns
 */
export function showSuccessToaster(idTranslation = "common.saveCorrectly", additionalData = null) {
    let toasterState = {
        additionalToasterData: additionalData,
        toasterMessage: idTranslation,
        toasterVariant: "success",
        showToaster: true,
    }
    return toasterState;
}

/**
 * Method to show info toaster 
 * with custom message or default "saveCorrectly"
 *
 * @export
 * @param {string} [idTranslation="common.saveCorrectly"]
 * @returns
 */
export function showInfoToaster(idTranslation = "common.saveCorrectly", additionalData = null) {
    let toasterState = {
        additionalToasterData: additionalData,
        toasterMessage: idTranslation,
        toasterVariant: "info",
        showToaster: true,
    }
    return toasterState;
}

/**
 * Method to show error toaster 
 * with custom message or default "errorToSave"
 *
 * @export
 * @param {string} [idTranslation="common.errorToSave"]
 * @returns
 */
export function showErrorToaster(idTranslation = "common.errorToSave", additionalData = null) {
    let toasterState = {
        additionalToasterData: additionalData,
        toasterMessage: idTranslation,
        toasterVariant: "error",
        showToaster: true,
    }
    return toasterState;
}

/**
 * Method to show warning toaster 
 * with custom message or default "saveCorrectly"
 *
 * @export
 * @param {string} [idTranslation="common.saveCorrectly"]
 * @returns
 */
export function showWarningToaster(idTranslation = "common.saveCorrectly", additionalData = null) {
    let toasterState = {
        additionalToasterData: additionalData,
        toasterMessage: idTranslation,
        toasterVariant: "warning",
        showToaster: true,
    }
    return toasterState;
}