///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

/** Material-UI imports section */

import { Translate } from 'react-localize-redux'

import DataTable from '../common/DataTable'
import AddIcon from '@material-ui/icons/AddTwoTone'
import { useDispatch, useSelector } from 'react-redux'
import IndexViewIcon from '@material-ui/icons/ViewList'
import SchedulerViewIcon from '@material-ui/icons/CalendarToday'
import DeliveryOrdersIcon from '@material-ui/icons/Assignment'
import { Card, CardHeader, Avatar, Typography, CardContent, IconButton, Tooltip, Fab } from '@material-ui/core'
import ArchiveDownloadIcon from '@material-ui/icons/Archive'

import LoadingPage from '../common/LoadingPage'
import {
	CHANGE_DELIVERY_ORDERS_PAGINATION,
	CHANGE_DELIVERY_ORDERS_QUERY,
	CHANGE_DELIVERY_ORDERS_SORT_CRITERIA,
	downloadOrdersReportByRangeDate,
	loadDeliveryOrders,
	loadDeliveryOrdersEventsFromBackEnd,
} from '../../store/plannigFersa/DeliveryOrderAction'
import DeliveriesCalendar from './components/DeliveriesCalendar'
import { converListToArray } from '../../store/helpers/ListHelper'
import SearchInputOptions from '../common/SearchInput/SearchInputOptions'
import { getDeliveryOrder } from '../../store/plannigFersa/ReleasePlanningSelector'
import RegisterDeliveryButton from '../planning/deliveryOrder/components/RegisterDeliveryButton'
import { IsCurrentActiveDirectoryUser, IsUserSupplierAdministrator } from '../../store/helpers/SessionHelper'
import { canBeModulePlaneationSupplier, canBeOperationDeliveryWarehouse } from '../../store/helpers/RolesHelper'
import OptionsMenu from '../common/OptionsMenu'
import DonwloadReportDialog from '../common/DonwloadReportDialog'

/** Language imports **/

const Index = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	const [stateValue, setStateValue] = useState({
		showIndex: true,
		isDownloadingReport: false,
		additionalFilters: null,
		itemToken: null,
		showMenu: true,
		openModalReportByTime: false,
		openModalByMonthReport: false,
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
	})

	const deliverys = useSelector((state) => getDeliveryOrder(state))
	const deliveryOrdersCount = useSelector((state) => state.deliveryOrder.get('deliveryOrdersCount'))
	const rowsPerPage = useSelector((state) => state.deliveryOrder.get('rowsPerPage'))
	const page = useSelector((state) => state.deliveryOrder.get('page'))
	const searchQuery = useSelector((state) => state.deliveryOrder.get('searchQuery'))
	const sortCriteria = useSelector((state) =>
		state.deliveryOrder.get('sortCriteria') ? state.deliveryOrders.get('sortCriteria').toJS() : null
	)
	const deliveryOrdersEvents = useSelector((state) =>
		state.deliveryOrders.get('deliveryOrdersEvents') ? state.deliveryOrders.get('deliveryOrdersEvents').toJS() : []
	)
	const isDonwloadingReport = useSelector((state) => state.deliveryOrders.get('isDonwloadingReport'))

	const user = useSelector((state) =>
		state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	)
	const isLoadingDeliveryOrders = useSelector((state) => state.deliveryOrder.get('isLoadingDeliveryOrders'))
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)

	useEffect(() => {
		dispatch(
			loadDeliveryOrders(
				searchQuery,
				sortCriteria.by,
				sortCriteria.ascending,
				page,
				rowsPerPage,
				user.profile.company_code
			)
		)
		dispatch(loadDeliveryOrdersEventsFromBackEnd(user.profile.company_code))
	}, [dispatch])

	const onCreate = () => {
		props.history.push(`/planning/deliveryOrderFersa/create`)
	}

	const onRegisterArrival = () => {
		props.history.push(`/deliveryOrderFersa/registerarrival`)
	}

	const toggleView = () => {
		setStateValue((prev) => ({ ...prev, showIndex: !stateValue.showIndex }))
	}
	const getCardContent = (deliverys, isLoadingDeliveryOrders, props, user) => {
		if (isLoadingDeliveryOrders) {
			return <LoadingPage />
		} else if (deliverys === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let tableConfiguration = TableInternalConfiguration
			if (!IsCurrentActiveDirectoryUser(user)) {
				tableConfiguration = TableExtenalInternalConfiguration
			}
			return (
				<DataTable
					data={deliverys}
					configuration={tableConfiguration}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={onChangeSortCriteria}
					onRowClick={(deliveryOrder) => {
						props.history.push(`/planning/deliveryOrderFersa/${deliveryOrder.id}`)
					}}
					showFilters
					isIndex
					totalRows={deliveryOrdersCount}
					page={page}
					rowsPerPage={rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		dispatch({
			type: CHANGE_DELIVERY_ORDERS_SORT_CRITERIA,
			sortCriteria: {
				by: sortBy,
				ascending: sortAscending,
			},
		})
	}
	const changePage = (page, rowsPerPage) => {
		dispatch({
			type: CHANGE_DELIVERY_ORDERS_PAGINATION,
			page,
			rowsPerPage,
		})

		dispatch(
			loadDeliveryOrders(
				searchQuery,
				sortCriteria.by,
				sortCriteria.ascending,
				page,
				rowsPerPage,
				user.profile.company_code
			)
		)
	}

	const onChangeSearchValue = (search) => {
		dispatch({
			type: CHANGE_DELIVERY_ORDERS_QUERY,
			query: search,
		})
		dispatch({
			type: CHANGE_DELIVERY_ORDERS_PAGINATION,
			page,
			rowsPerPage,
		})

		dispatch(
			loadDeliveryOrders(
				searchQuery,
				sortCriteria.by,
				sortCriteria.ascending,
				page,
				rowsPerPage,
				user.profile.company_code
			)
		)
	}

	const handleOpenModal = () => {
		setStateValue((prev) => ({ ...prev, openModalByMonthReport: true }))
	}
	const handleCloseModal = () => {
		setStateValue((prev) => ({ ...prev, openModalByMonthReport: false }))
		setStateValue((prev) => ({ ...prev, startDate: '', endDate: '' }))
	}

	const renderAction = () => {
		var options = []

		options.push({
			itemClick: () => handleOpenModal(),
			tooltipTranslation: <Translate id='deliveryOrderModule.donwloadReportOrders' />,
			menuItemIcon: <ArchiveDownloadIcon color='secondary' />,
			isLoading: isDonwloadingReport,
		})
		if (stateValue.showMenu)
			return (
				<OptionsMenu
					itemToken={'item.id'}
					handleClickOptions={handleClickOptionsMenu}
					open={stateValue.itemToken === 'item.id'}
					handleClickAway={handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	const handleClickOptionsMenu = (token) => {
		setStateValue((prev) => ({ ...prev, itemToken: token }))
	}

	const handleCloseOptionsMenu = () => {
		if (!stateValue.openModalByMonthReport || !stateValue.openModalReportByTime) {
			setStateValue((prev) => ({ ...prev, itemToken: '', showMenu: false }))
			setStateValue((prev) => ({ ...prev, showMenu: true }))
		}
	}

	const handleDownloadReportByDateRange = (startDate, endDate) => {
		dispatch(downloadOrdersReportByRangeDate(startDate, endDate))
			.then(() => {
				handleCloseModal()
				//showSuccessToaster("common.downloadCorrectly");
			})
			.catch((error) => {
				//showErrorToaster("common.errorToDownload");
			})
	}
	return (
		<Card>
			<div className={classes.marginButton}>
				{canBeOperationDeliveryWarehouse(userRolesByCompany) && (
					<span>
						<RegisterDeliveryButton classes={classes} open={onRegisterArrival} />
						<br />
					</span>
				)}
			</div>
			<CardHeader
				title={
					<Typography variant='button'>
						<Translate id='menu.deliveryOrder' />{' '}
					</Typography>
				}
				avatar={
					<Avatar>
						<DeliveryOrdersIcon />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						{stateValue.showIndex && (
							<React.Fragment>
								<SearchInputOptions
									className={classes.searchInput}
									onChangeSearchValue={(searchQuery) => onChangeSearchValue(searchQuery)}
									value={searchQuery}
								/>
								{(canBeModulePlaneationSupplier(userRolesByCompany) ||
									IsUserSupplierAdministrator(user)) && (
									//validación de usuario
									<Tooltip title={<Translate id={'deliveryOrderModule.activeDeliveryOrder'} />}>
										<Fab size='small' color='primary' onClick={onCreate}>
											<AddIcon />
										</Fab>
									</Tooltip>
								)}
							</React.Fragment>
						)}
						<Tooltip
							title={<Translate id={stateValue.showIndex ? 'common.showCalendar' : 'common.showIndex'} />}
						>
							<IconButton color='primary' value onClick={toggleView}>
								{stateValue.showIndex ? <SchedulerViewIcon /> : <IndexViewIcon />}
							</IconButton>
						</Tooltip>
						{IsCurrentActiveDirectoryUser(user) && renderAction()}
					</div>
				}
			/>
			<CardContent>
				{stateValue.showIndex && getCardContent(deliverys, isLoadingDeliveryOrders, props, user)}
				{!stateValue.showIndex && <DeliveriesCalendar events={deliveryOrdersEvents} />}
			</CardContent>

			{/** Dialog to download report */}
			<DonwloadReportDialog
				title={<Translate id='deliveryOrderModule.donwloadReportOrders' />}
				open={stateValue.openModalByMonthReport}
				onClose={handleCloseModal}
				isSaving={isDonwloadingReport}
				onDonwloadItems={handleDownloadReportByDateRange}
				saveButtonTranslate={'common.downloadReport'}
			/>
		</Card>
	)
}

const TableExtenalInternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.state' />,
			dataSource: 'actual_operation_description',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.folio' />,
			dataSource: 'folio',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printingDate' />,
			dataSource: 'sentDate',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printingTime' />,
			dataSource: 'sentTime',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.estimatedDeliveryDate' />,
			dataSource: 'estimatedArrivalDate',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.estimatedDeliveryTime' />,
			dataSource: 'estimatedArrivaltTime',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printedBy' />,
			dataSource: 'supplierName',
		},
	],
}

const TableInternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.state' />,
			dataSource: 'actual_operation_description',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.folio' />,
			dataSource: 'folio',
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printingDate' />,
			dataSource: 'sentDate',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printingTime' />,
			dataSource: 'sentTime',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.estimatedDeliveryDate' />,
			dataSource: 'estimatedArrivalDate',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.estimatedDeliveryTime' />,
			dataSource: 'estimatedArrivaltTime',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printedBy' />,
			dataSource: 'supplierName',
		},
	],
}
const styles = (theme) => ({
	logo: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	marginIcon: {
		marginLeft: '5px',
	},
	marginIconCalendar: {
		marginLeft: '5px',
		//backgroundColor: COLOR_SUSTAINABILITY_PLUM,
	},
	marginIconFile: {
		marginLeft: '5px',
		//backgroundColor: COLOR_SUSTAINABILITY_ROSE,
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
	marginButton: {
		marginTop: '25px',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Index)))
