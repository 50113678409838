/** Helpers import section */
import axios from 'axios'
import moment from 'moment'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'

import { underScoreToTitleCase } from '../helpers/StringHelper'

export const CHANGE_QUESTIONNAIRES_SORT_CRITERIA = 'CHANGE_QUESTIONNAIRES_SORT_CRITERIA'
export const CHANGE_QUESTIONNAIRES_QUERY = 'CHANGE_QUESTIONNAIRES_QUERY'
export const CHANGE_QUESTIONNAIRES_PAGINATION = 'CHANGE_QUESTIONNAIRES_PAGINATION'
export const IS_LOADING_QUESTIONNAIRES_INDEX = 'IS_LOADING_QUESTIONNAIRES_INDEX'
export const SET_QUESTIONNAIRES_LIST = 'SET_QUESTIONNAIRES_LIST'
export const SET_QUESTIONNAIRES_COUNT = 'SET_QUESTIONNAIRES_COUNT'

export const IS_SAVING_QUESTIONNAIRE = 'IS_SAVING_QUESTIONNAIRE'
export const IS_LOADING_QUESTIONNAIRE = 'IS_LOADING_QUESTIONNAIRE'
export const SET_QUESTIONNAIRE = 'SET_QUESTIONNAIRE'
export const UPDATE_SECTION_QUESTION_IN_LIST = 'UPDATE_SECTION_QUESTION_IN_LIST'
export const UPDATE_OPTION_MULTIPLE_IN_QUESTION_LIST = 'UPDATE_OPTION_MULTIPLE_IN_QUESTION_LIST'

export const IS_LOADING_TEMPLATES = 'IS_LOADING_TEMPLATES'
export const SET_TEMPLATES_LIST = 'SET_TEMPLATES_LIST'
export const IS_SAVING_SECTION_TEMPALTE = 'IS_SAVING_SECTION_TEMPALTE'
export const ADD_SECTION_IN_TEMPLATE = 'ADD_SECTION_IN_TEMPLATE'
export const UPDATE_SECTION_IN_TEMPLATE = 'UPDATE_SECTION_IN_TEMPLATE'
export const REMOVE_SECTION_IN_TEMPLATE = 'REMOVE_SECTION_IN_TEMPLATE'
export const SET_SCHUDELE_DATE_HISTORYS = 'SET_SCHUDELE_DATE_HISTORYS'
export const UPDATE_TOTAL_AUDIT = 'UPDATE_TOTAL_AUDIT'

//Action plant
export const ADD_ACTION_PLANT = 'ADD_ACTION_PLANT'
export const REMOVE_ACTION_PLANT = 'REMOVE_ACTION_PLANT'

function addActionPlantAction(actionPlant) {
	return {
		type: ADD_ACTION_PLANT,
		actionPlant,
	}
}

function removeActionPlantAction(token) {
	return {
		type: REMOVE_ACTION_PLANT,
		token,
	}
}

function setSchudeleDatesHistorys(data) {
	return {
		type: SET_SCHUDELE_DATE_HISTORYS,
		data,
	}
}

function isLoadingIndex(isLoadingIndex) {
	return {
		type: IS_LOADING_QUESTIONNAIRES_INDEX,
		isLoadingIndex,
	}
}

function setQuestionnairesList(questionnaires) {
	return {
		type: SET_QUESTIONNAIRES_LIST,
		questionnaires,
	}
}

function setQuestionnairesCount(count) {
	return {
		type: SET_QUESTIONNAIRES_COUNT,
		count,
	}
}

function isSavingQuestionnaire(isSavingQuestionnaire) {
	return {
		type: IS_SAVING_QUESTIONNAIRE,
		isSavingQuestionnaire,
	}
}

function isLoadingQuestionnaire(isLoadingQuestionnaire) {
	return {
		type: IS_LOADING_QUESTIONNAIRE,
		isLoadingQuestionnaire,
	}
}

function setQuestionnaire(questionnaire) {
	return {
		type: SET_QUESTIONNAIRE,
		questionnaire,
	}
}

function updateSectionQuestion(sectionToken, updatedQuestion) {
	return {
		type: UPDATE_SECTION_QUESTION_IN_LIST,
		sectionToken,
		updatedQuestion,
	}
}

function updateTotalAudit(question) {
	return {
		type: UPDATE_TOTAL_AUDIT,
		question,
	}
}

function updateOptionMultipleQuestion(sectionToken, question) {
	return {
		type: UPDATE_OPTION_MULTIPLE_IN_QUESTION_LIST,
		sectionToken,
		question,
	}
}

function isSavingSectionTempalte(isSavingTemplate) {
	return {
		type: IS_SAVING_SECTION_TEMPALTE,
		isSavingTemplate,
	}
}

function addActiontInTemplate(sectionTemplate) {
	return {
		type: ADD_SECTION_IN_TEMPLATE,
		sectionTemplate,
	}
}

function updateSectionInTemplate(section) {
	return {
		type: UPDATE_SECTION_IN_TEMPLATE,
		section,
	}
}

function removeSection(sectionToken) {
	return {
		type: REMOVE_SECTION_IN_TEMPLATE,
		sectionToken,
	}
}

/**
 * Action to load Questionnaires from backend
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @param {*} page
 * @param {*} rowsPerPage
 */
export function loadQuestionnairesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingIndex(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/all`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuestionnairesList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingIndex(false))
				console.error('Error getting the questionnaires by company list', error.response)
			})
	}
}

/**
 * Action to loading Questionnaire
 * @param {*} token
 */
export function getScheduleDateHistorys() {
	return function (dispatch, getState) {
		dispatch(isLoadingQuestionnaire(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${selectedCompany.get(
			'token'
		)}/scheduledDate/${supplierToken}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSchudeleDatesHistorys(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error getting information schudelete dates', error.response)
				dispatch(isLoadingQuestionnaire(false))
				return Promise.reject()
			})
	}
}

/**
 * Count list questionnaires
 */
export function getQuestionnairesCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().sustainability ? getState().sustainability.get('questionnairesCount') : null
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuestionnairesCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error getting the questionnaires count', error.response)
			})
	}
}

/**
 * Action to create questionnaire
 * @param {*} suppliers
 * @param {*} comment
 */
export function createQuestionnaire(questionnaire) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))
		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let data = {
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			CreateEmail: user.profile.email,
			Comments: questionnaire.comments ? questionnaire.comments.value : '',
			QuestionnaireType: questionnaire.questionnairetype ? questionnaire.questionnairetype.value : '',
			Suppliers: questionnaire.suppliers,
			ScheduledDate: questionnaire.scheduledDate ? moment(questionnaire.scheduledDate, 'DD/MM/YYYY') : '',
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingQuestionnaire(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error creating questionnaire', error.response)
				dispatch(isSavingQuestionnaire(false))
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to loading Questionnaire
 * @param {*} token
 */
export function loadQuestionnaireFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingQuestionnaire(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuestionnaire(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load questionnaire', error.response)
				dispatch(isLoadingQuestionnaire(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to update property in list section
 * @param {*} sectionToken
 * @param {*} questionToken
 * @param {*} property
 * @param {*} propertyData
 */
export function updatePropertyInListSectionQuestion(sectionToken, questionToken, property, propertyData) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/section/question/${questionToken}`
		let data = {
			[underScoreToTitleCase(property)]: propertyData,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateSectionQuestion(sectionToken, serverResponse.data))
					dispatch(updateTotalAudit(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to update property in section question', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update question with option multiple in question from section
 * @param {*} sectionToken
 * @param {*} questionToken
 * @param {*} optionToken
 * @param {*} property
 * @param {*} data
 */
export function updateOptionMuptipleInQuestion(sectionToken, questionToken, optionToken, property, propertyData) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/section/question/${questionToken}/optionmultiple/${optionToken}`
		let data = {
			[underScoreToTitleCase(property)]: propertyData,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateOptionMultipleQuestion(sectionToken, serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to update property in option multple in question', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to send questionnaire to client by supplier
 * @param {*} token
 */
export function sendQuestionnaireToClientBySupplier(token) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			QuestionnaireToken: token,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/sendtoclient`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingQuestionnaire(false))
					dispatch(setQuestionnaire(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in send questionnaire to client ', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to resend questionnaire to supplier
 * @param {*} token
 * @param {*} comments
 */
export function resendQuestionnaireToSupplier(token, comments) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			QuestionnaireToken: token,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Comments: comments,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/resendtosupplier`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingQuestionnaire(false))
					dispatch(setQuestionnaire(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in resend questionnaire to supplier ', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update property
 * @param {*} questionnaireToken
 * @param {*} property
 * @param {*} propertyData
 */
export function updateProperty(questionnaireToken, property, propertyData) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${questionnaireToken}`
		let data = {
			[underScoreToTitleCase(property)]: propertyData ? propertyData.value : '',
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuestionnaire(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to update property', error.response)
				return Promise.reject()
			})
	}
}

export function updatePropertyScheduledDate(questionnaireToken, questionnaireModel) {
	return function (dispatch, getState) {
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/scheduledDate`
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		dispatch(isSavingQuestionnaire(true))
		let data = {
			Token: questionnaireToken,
			ScheduledDate: questionnaireModel.scheduled_date,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Comments: questionnaireModel.comments,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingQuestionnaire(false))
					dispatch(setQuestionnaire(serverResponse.data))
					return Promise.resolve()
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error to update property', error.response)
				return Promise.reject()
			})
	}
}

export function onSendQuestionnaireToSuppliers(questionnaireToken) {
	return function (dispatch, getState) {
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/sendToSupplier`
		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(user)
		dispatch(isSavingQuestionnaire(true))
		let data = {
			Token: questionnaireToken,
			CompanyToken: selectedCompany.get('token'),
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingQuestionnaire(false))
					dispatch(setQuestionnaire(serverResponse.data))
					return Promise.resolve()
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error to send to suppliers', error.response)
				return Promise.reject()
			})
	}
}

export function updatePropertyDeliveryDate(questionnaireToken, property, deliveryDate) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${questionnaireToken}`
		let data = {
			[underScoreToTitleCase(property)]: deliveryDate ? deliveryDate.format('DD/MM/YYYY') : '',
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuestionnaire(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to update property', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to clise questionnaire
 * @param {*} token
 */
export function closeQuestionnaireInPlant(token) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			QuestionnaireToken: token,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/closeinplant`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingQuestionnaire(false))
					dispatch(setQuestionnaire(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in close  questionnaires in plant', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to send reminder from all supplier in process equestionnaire
 * @param {*} expedientToken
 */
export function sendReminderToSuppliersFromQuestionnaireInProcess() {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {}
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${companyToken}/sendremindersuppliers`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingQuestionnaire(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in send reminder questionnaire by all supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update evicende in question
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFilesInSectionQuestion(token, sectionToken, questionToken, files) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${token}/question/${questionToken}/upload`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateSectionQuestion(sectionToken, serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload evidence file(s) from question', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} questionToken
 * @param {*} fileName
 * @returns
 */
export function removeFilesEvidenceInQuestion(token, sectionToken, questionToken, fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${token}/question/${questionToken}/remove`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				dispatch(updateSectionQuestion(sectionToken, serverResponse.data))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) from question', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to upload files in questionnaire
 * @param {*} token
 * @param {*} isInternal
 * @param {*} files
 */
export function uploadFilesInQuestionnaire(token, isInternal, files) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${token}/uploadFiles`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		formData.set('IsInternal', isInternal)

		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuestionnaire(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload evidence file(s) from questionnaires', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files in questionnaires
 * @param {*} token
 * @param {*} isInternal
 * @param {*} fileName
 */
export function removeFilesInQuestionnaire(token, isInternal, fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
			isInternal: isInternal,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${token}/removeFiles`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				dispatch(setQuestionnaire(serverResponse.data))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) from questionnaires', error.response)
				return Promise.reject()
			})
	}
}

export function generateQuestionnaireActionsPlans(token) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			QuestionnaireToken: token,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/generateactions`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingQuestionnaire(false))
					dispatch(setQuestionnaire(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in accpeted  questionnaires in plant', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to accpeted questionnaire and sent actions to supplier
 * @param {*} token
 */
export function addQuestionnaireAcceptedToSupplier(token) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			QuestionnaireToken: token,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/acceptedInPlant`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingQuestionnaire(false))
					dispatch(setQuestionnaire(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in accpeted  questionnaires in plant', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * addQuestionnireSupplierAddingActions
 * @param {*} token
 */
export function addQuestionnireSupplierAddingActions(token) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			QuestionnaireToken: token,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/actionsfromsupplier`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingQuestionnaire(false))
					dispatch(setQuestionnaire(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in send questionnaire to client ', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Actions to resend plan actions from supplier
 * @param {*} token
 * @param {*} comments
 */
export function resendActionsQuestionnaireToSupplier(token, comments) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			QuestionnaireToken: token,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Comments: comments,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/resendactionsupplier`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingQuestionnaire(false))
					dispatch(setQuestionnaire(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in resend actions questionnaire to supplier ', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

function isLoadingTempalte(isLoading) {
	return {
		type: IS_LOADING_TEMPLATES,
		isLoading,
	}
}

function setTemplatesList(templates) {
	return {
		type: SET_TEMPLATES_LIST,
		templates,
	}
}

/**
 * Action to load all templates from backend
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @param {*} page
 * @param {*} rowsPerPage
 */
export function loadTemplatesFromBackEnd() {
	return function (dispatch, getState) {
		dispatch(isLoadingTempalte(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/templates`
		let data = {
			CompanyToken: selectedCompany.get('token'),
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTemplatesList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingTempalte(false))
				console.error('Error getting all templates by company by company list', error.response)
			})
	}
}

/**
 * Action to create templated
 * @param {*} name
 * @param {*} questionnaireType
 */
export function createTemplate(name, questionnaireType) {
	return (dispatch, getState) => {
		dispatch(isSavingSectionTempalte(true))
		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			CreateEmail: user.profile.email,
			Name: name,
			QuestionnaireType: questionnaireType ? questionnaireType.value : '',
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/createtemplate`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingSectionTempalte(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingSectionTempalte(false))
				console.error('Error in add section in template', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to add section in template
 * @param {*} templateToken
 * @param {*} section
 */
export function addSectionInTemplate(templateToken, section) {
	return (dispatch, getState) => {
		dispatch(isSavingSectionTempalte(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			QuestionnaireToken: templateToken,
			ShortName: section.shortName,
			Description: section.description,
			ColorSection: section.colorSection,
			PercentageSection: section.percentageSection,
			IsSectionMultiple: section.isSectionMultiple === 1 ? true : false,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/section`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingSectionTempalte(false))
					dispatch(addActiontInTemplate(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingSectionTempalte(false))
				console.error('Error in add section in template', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/***
 * Action to update section in template
 */
export function updateSectionInFromBackend(section) {
	return (dispatch, getState) => {
		dispatch(isSavingSectionTempalte(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			SectionToken: section.token,
			ShortName: section.shortName,
			Description: section.description,
			ColorSection: section.colorSection,
			PercentageSection: section.percentageSection,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/section`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingSectionTempalte(false))
					dispatch(updateSectionInTemplate(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingSectionTempalte(false))
				console.error('Error in update section in template', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/***
 * Action to remove question in section template
 */
export function removeSectionInTemplate(sectionToken) {
	return (dispatch, getState) => {
		dispatch(isSavingSectionTempalte(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/section/${sectionToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingSectionTempalte(false))
					dispatch(removeSection(sectionToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingSectionTempalte(false))
				console.error('Error in remove section in template', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to ad question in section template
 * @param {*} sectionToken
 * @param {*} question
 */
export function addQuestionInSectionTemplate(sectionToken, question) {
	return (dispatch, getState) => {
		dispatch(isSavingSectionTempalte(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			QuestionnaireToken: sectionToken,
			Name: question.name,
			QuestionType: question.questionType,
			Percentage: question.percentage,
			ResponseCorrectly: question.responseCorrectly,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/section/question`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingSectionTempalte(false))
					dispatch(updateSectionInTemplate(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingSectionTempalte(false))
				console.error('Error in add section in template', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/***
 * Action to remove question in section template
 */
export function removeQuestionInSectionTemplate(sectionToken, questionToken) {
	return (dispatch, getState) => {
		dispatch(isSavingSectionTempalte(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/section/${sectionToken}/question/${questionToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingSectionTempalte(false))
					dispatch(updateSectionInTemplate(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingSectionTempalte(false))
				console.error('Error in remove question in template', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update question in section
 * @param {*} sectionToken
 * @param {*} question
 */
export function updateQuestionInSectionTemplate(sectionToken, question) {
	return (dispatch, getState) => {
		dispatch(isSavingSectionTempalte(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let data = {
			SectionToken: sectionToken,
			QuestionToken: question.token,
			Name: question.name,
			QuestionType: question.questionType,
			Percentage: question.percentage,
			ResponseCorrectly: question.responseCorrectly,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/section/question`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingSectionTempalte(false))
					dispatch(updateSectionInTemplate(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingSectionTempalte(false))
				console.error('Error in update section in template', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to action plant
 * @param {*} sectionToken
 * @param {*} question
 */
export function addActionPlant(questionnaireToken, actionPlant) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			QuestionnaireToken: questionnaireToken,
			Name: actionPlant ? actionPlant.name : '',
			RequestedUserName: user.profile.username,
			RequestedBy: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/actionPlant`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addActionPlantAction(serverResponse.data))
					dispatch(isSavingQuestionnaire(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in add action plant', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to action plant
 * @param {*} sectionToken
 * @param {*} question
 */
export function deleteActionPlant(questionnaireToken, actionPlantToken) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${questionnaireToken}/actionPlant/${actionPlantToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeActionPlantAction(actionPlantToken))
					dispatch(isSavingQuestionnaire(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in delete action plant', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Add evidence in action plan
 * @param {*} actionPlanId
 * @param {*} files
 * @returns
 */
export function addEvidenceInActionPlan(questionnaireToken, actionPlantToken, files) {
	return function (dispatch, getState) {
		dispatch(isSavingQuestionnaire(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${questionnaireToken}/actionPlans/${actionPlantToken}/evidences`
		let formData = new FormData()
		files.forEach((file) => {
			formData.append('data', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addActionPlantAction(serverResponse.data))
					dispatch(isSavingQuestionnaire(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error add attachments in the action plan', error.response)
			})
	}
}

/**
 * Action to remove files to supplier from saplme
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeFileInActionPlan(questionnaireToken, actionPlantToken, fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		dispatch(isSavingQuestionnaire(true))
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${questionnaireToken}/actionPlans/${actionPlantToken}/evidences`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addActionPlantAction(serverResponse.data))
					dispatch(isSavingQuestionnaire(false))
					return Promise.resolve()
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error to upload order file(s) from supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to action plant
 * @param {*} sectionToken
 * @param {*} question
 */
export function updateActionPlantSupplier(questionnaireToken, actionPlant, isCurrentActiveDirectoryUser) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))
		let user = getState().oidc.user

		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			CommentsSupplier: actionPlant ? actionPlant.comments_supplier : '',
			CommitmentDate: actionPlant ? actionPlant.commitment_date : '',
			AnsweredBy: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${questionnaireToken}/actionPlants/${
			actionPlant ? actionPlant.token : ''
		}/${isCurrentActiveDirectoryUser}`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addActionPlantAction(serverResponse.data))
					dispatch(isSavingQuestionnaire(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in update action plant supplier', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function updateActionPlanFromResponsible(questionnaireToken, actionPlant, isCurrentActiveDirectoryUser) {
	return (dispatch, getState) => {
		dispatch(isSavingQuestionnaire(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			CommentsPlant: actionPlant ? actionPlant.comments_plant : '',
			ApprovalDate: actionPlant ? actionPlant.approval_date : '',
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/questionnaires/${questionnaireToken}/actionPlants/${
			actionPlant ? actionPlant.token : ''
		}/${isCurrentActiveDirectoryUser}`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addActionPlantAction(serverResponse.data))
					dispatch(isSavingQuestionnaire(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuestionnaire(false))
				console.error('Error in update action plant responsible', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
