import axios from 'axios'
import { getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserFiles } from '../helpers/SessionHelper'

export const IS_LOADING_EXPORT_REQUEST = 'IS_LOADING_EXPORT_REQUEST'
export const SET_EXPORT_REQUEST_LIST = 'SET_EXPORT_REQUEST_LIST'
export const SET_EXPORT_REQUEST_ITEM = 'SET_EXPORT_REQUEST_ITEM'
export const IS_LOADING_EXPORT_REQUEST_ITEM = 'IS_LOADING_EXPORT_REQUEST_ITEM'
export const IS_SAVING_EXPORT_REQUEST_FILE = 'IS_SAVING_EXPORT_REQUEST_FILE'

function isLoadingList(isLoading) {
	return {
		type: IS_LOADING_EXPORT_REQUEST,
		isLoading,
	}
}

function setExportRequestList(exportRequestList) {
	return {
		type: SET_EXPORT_REQUEST_LIST,
		exportRequestList: exportRequestList,
	}
}

function isLoadingItem(isLoading) {
	return {
		type: IS_LOADING_EXPORT_REQUEST_ITEM,
		isLoading,
	}
}

function setExportRequestItem(exportRequestItem) {
	return {
		type: SET_EXPORT_REQUEST_ITEM,
		exportRequestItem: exportRequestItem,
	}
}
function isSavingFile(isSaving) {
	return {
		type: IS_SAVING_EXPORT_REQUEST_FILE,
		isSaving: isSaving,
	}
}

export const getExportRequest = (query, orderBy, sortAscending, page, rowsPerPage) => {
	return function (dispatch, getState) {
		dispatch(isLoadingList(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = undefined
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/exportRequest/paginated`
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
			endPoint = `${process.env.REACT_APP_SIP_PLANNING}/exportRequest/filters`
		}
		let data = {
			Page: `${page + 1}`,
			SortBy: orderBy,
			SearchQuery: query,
			RowsPerPage: rowsPerPage,
			SortAscending: sortAscending,
			SupplierCode: supplierCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingList(false))
					dispatch(setExportRequestList(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingList(false))
				console.error(error)
			})
	}
}

export const getExportRequestByToken = (token) => {
	return function (dispatch, getState) {
		dispatch(isLoadingItem(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/exportRequest/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingItem(false))
					dispatch(setExportRequestItem(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingItem(false))
				console.error(error)
			})
	}
}

export function uploadFilesExportRequestItem(exportRequestToken, exportRequestItemToken, folder, files) {
	return function (dispatch, getState) {
		dispatch(isSavingFile(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/exportRequest/file/${exportRequestToken}/${exportRequestItemToken}/${folder}`
		let formData = new FormData()
		for (var i = 0; i < files.length; i++) {
			formData.append('file', files[i])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingFile(false))
					dispatch(setExportRequestItem(serverResponse.data))

					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingFile(false))
				console.error('Error to upload sample files from export request file(s)', error.response)
				return Promise.reject()
			})
	}
}

export function deleteFilesFromItem(exportRequestToken, exportRequestItemToken, folder, fileName) {
	return function (dispatch, getState) {
		dispatch(isSavingFile(true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/exportRequest/file/${exportRequestToken}/${exportRequestItemToken}/${folder}/${fileName}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setExportRequestItem(serverResponse.data))
					dispatch(isSavingFile(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingFile(false))

				console.error('Error to upload sample files from supplier file(s)', error.response)
				return Promise.reject()
			})
	}
}


export const onSynchronizeSit = (token) => {
	return function (dispatch, getState) {
		dispatch(isLoadingItem(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/exportRequest/synchronize/sit`
        let command ={
            Token: token
        }
		return axios
			.put(endPoint,command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingItem(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingItem(false))
				console.error(error)
			})
	}
}