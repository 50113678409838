import React, { useState } from 'react';
import Select from 'react-select'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'

/**Import MaterialUi section */
import { Grid, Button, Avatar, Typography, TextField } from '@material-ui/core'
import NoteAddIcon from '@material-ui/icons/NoteAdd';

/** section import components */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';

/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader';
import SupplierSelector from '../../common/SupplierSelector'
import DatePicker from '../../common/DateTimePicker/DatePicker';
import { GetTypeAudit } from '../../../store/helpers/SelectOptions';

const PanelMoldsImport = props => {
    /**props section */
    const {
        onCloseButtonClick,
        classes,
        onSaveButtonClick,
        isSaving
    } = props

    const [panelInformation, setPanelInformation] = useState({
        supplier: null,
        comments: "",
        rfc: "",
        auditDate: null,
        typeAudit: 0
    });

    let typeAuditData = GetTypeAudit()

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setPanelInformation({
            ...panelInformation,
            [name]: value
        });
    }

    const onSelectedChange = (option, select) => {
        setPanelInformation({
            ...panelInformation,
            [select]: option
        });
    }

    const canBeSaved = () => {
        if (panelInformation.supplier === null) return false
        if (panelInformation.auditDate === null) return false
        if (panelInformation.typeAudit === null || panelInformation.typeAudit.value === 0) return false
        else return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(panelInformation)
        }
    }

    const onChangeDate = (property, data) => {
        setPanelInformation({
            ...panelInformation,
            [property]: data
        });
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}>
            <PanelHeader
                titlePanel={<Translate id="qualityAudit.senAuditPanel" />}
                icon={<Avatar className={classes.avatar}><NoteAddIcon /></Avatar>}
                onCloseButtonClick={onCloseButtonClick}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container>
                        <Typography color='primary'><Translate id="sustainability.selectSuppliers" /></Typography>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <SupplierSelector
                                onSupplierSelected={(supplier) => onSelectedChange(supplier, "supplier")} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={<Translate id="qualityAudit.rfcInput" />}
                                className={classes.textField}
                                onChange={onPropertyChange}
                                name="rfc"
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Typography color="primary" style={{ marginTop: '1rem' }}><Translate id="qualityAudit.typeAudit" /></Typography>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Select
                                options={typeAuditData}
                                onChange={(typeAuditData) => onSelectedChange(typeAuditData, "typeAudit")}
                                defaultValue={typeAuditData[panelInformation.typeAudit]}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10 }),
                                    menuList: (base) => ({ ...base, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                    control: base => ({
                                        ...base,
                                        height: 50,
                                        minHeight: 50,
                                    })
                                }}
                            />
                        </Grid>
                        <Typography color="primary" style={{ marginTop: '1rem' }}><Translate id="qualityAudit.auditDate" /></Typography>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <DatePicker
                                opens={"left"}
                                drops={"down"}
                                onChangeRange={(event) => onChangeDate('auditDate', event)}
                                startDate={panelInformation.auditDate ? moment(panelInformation.auditDate, 'DD/MM/YYYY') : ''}
                            // minDate={moment()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <Typography color='primary'>{<Translate id="qualityAudit.comments" />}</Typography>
                            <TextField
                                fullWidth
                                label={<Translate id="common.comments" />}
                                className={classes.textField}
                                value={panelInformation.comments}
                                onChange={onPropertyChange}
                                multiline
                                name='comments'
                                margin="normal"
                                variant="outlined"
                                rows="4"
                            />
                        </Grid>
                    </Grid>
                </div>
                <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            className={classes.saveButton}
                            disabled={!canBeSaved() || isSaving}
                            onClick={onSave}>
                            {<Translate id="common.sendBotton" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </LateralPanel >
    );
};

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    saveButton: {
        bottom: 0,
    },

});

PanelMoldsImport.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onDeleteAttachment: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onSaveItem: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onCloseButtonClick: propTypes.func,
    /**All attachments saved */
    /**
     * Validate if template is correctly
     */
    uploadFilesFromSupplier: propTypes.func
};

PanelMoldsImport.defaultProps = {
    onDeleteAttachment: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveItem: () =>
        console.warn('Callback [onSaveItem] no defined'),
    uploadFilesFromSupplier: () =>
        console.warn('Callback [onValidateTemplate] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelMoldsImport))