
import React, { Component } from 'react';

/** Material-UI imports section */
import { Translate } from "react-localize-redux";

import IconButton from '@material-ui/core/IconButton';
import QueriesIcon from '@material-ui/icons/TableChartTwoTone';
import Tooltip from '@material-ui/core/Tooltip';

import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import InvoicesIcon from '@material-ui/icons/ListAltTwoTone';
import InventoryIcon from '@material-ui/icons/StorageTwoTone';
import PurchaseOrdersIcon from '@material-ui/icons/ShoppingCartTwoTone';
import ConsignationIcon from '@material-ui/icons/RvHookupTwoTone';
import WarehouseEntriesIcon from '@material-ui/icons/LocalShippingTwoTone';
import StockEntryIcon from '@material-ui/icons/UpdateTwoTone';
import SocialSecurityIcon from '@material-ui/icons/VerifiedUser';
import ServicesProjectsIcon from '@material-ui/icons/Build';
import InventoryMaquilaIcon from '@material-ui/icons/ViewModule';

import { Divider } from '@material-ui/core';

import { COMPANY_IDENTIFIER_IMBERA_BRASIL, COMPANY_PTM, COMPANY_IDENTIFIER_IMBERA_MX, COMPANY_IDENTIFIER_FERSA } from '../../../store/helpers/ResourcesHelper';

/**
 * QueriesButton
 */
export default class QueriesButton extends Component {

    /**
     *Creates an instance of QueriesButton.
     * @param {*} props
     * @memberof QueriesButton
     */
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open: false,
        };
    }

    /**
     * Change visibility of popper
     *
     * @memberof QueriesButton
     */
    toggleMenu = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: !state.open,
        }));
    };

    getMenuItem() {
        const { classes, selectedCompany, advanceOptionsSupplier } = this.props;
        return (
            <div className={classes.root}>
                <List component="nav">

                    <ListItem button onClick={() => { this.props.onOpenQueryView("invoices") }}>
                        <ListItemIcon>
                            <InvoicesIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={<Translate id="menu.invoice" />} />
                    </ListItem>

                    {selectedCompany && selectedCompany.get_company_identifier !== COMPANY_IDENTIFIER_IMBERA_BRASIL && <ListItem button onClick={() => { this.props.onOpenQueryView("inventories") }}>
                        <ListItemIcon>
                            <InventoryIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={<Translate id="menu.inventory" />} />
                    </ListItem>}

                    <ListItem button onClick={() => { this.props.onOpenQueryView("purchaseOrders") }}>
                        <ListItemIcon>
                            <PurchaseOrdersIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={<Translate id="menu.purchaseOrder" />} />
                    </ListItem>

                    <ListItem button onClick={() => { this.props.onOpenQueryView("consignments") }}>
                        <ListItemIcon>
                            <ConsignationIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={<Translate id="menu.consignation" />} />
                    </ListItem>

                    <ListItem button onClick={() => { this.props.onOpenQueryView("stocks") }}>
                        <ListItemIcon>
                            <WarehouseEntriesIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={<Translate id="menu.entryStock" />} />
                    </ListItem>
                    {selectedCompany && (selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX || selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_FERSA) &&
                        <ListItem button onClick={() => { this.props.onOpenQueryView("inventorymaquila") }}>
                            <ListItemIcon>
                                <InventoryMaquilaIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={<Translate id="queries.inventoryMaquila.inventoryMaquila" />} />
                        </ListItem>}

                </List>
                <Divider />
                { selectedCompany.get_company_identifier !== COMPANY_IDENTIFIER_FERSA &&selectedCompany && selectedCompany.company_modules && selectedCompany.company_modules.PlanningModule && <List component="nav">
                    <ListItem button onClick={() => { this.props.onOpenReleasePlan() }}>
                        <ListItemIcon>
                            <StockEntryIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={<Translate id="menu.deliveryPlan" />} />
                    </ListItem>
                    <Divider />
                </List>}
                { selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_FERSA &&selectedCompany && selectedCompany.company_modules && selectedCompany.company_modules.PlanningModule && <List component="nav">
                    <ListItem button onClick={() => { this.props.onOpenConstracotView(`/planning/relist/${advanceOptionsSupplier.code}`) }}>
                        <ListItemIcon>
                            <StockEntryIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={<Translate id="menu.deliveryPlan" />} />
                    </ListItem>
                    <Divider />
                </List>}
                {selectedCompany && advanceOptionsSupplier && (selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX || selectedCompany.get_company_identifier === COMPANY_PTM)
                    && advanceOptionsSupplier.is_contractor_supplier &&
                    <List component="nav">
                        <ListItem button onClick={() => { this.props.onOpenConstracotView("/socialinsurancepayments") }}>
                            <ListItemIcon>
                                <SocialSecurityIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={<Translate id="menu.socialSecurity" />} />
                        </ListItem>
                    </List>}
                {selectedCompany && advanceOptionsSupplier && (selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX || selectedCompany.get_company_identifier === COMPANY_PTM)
                    && advanceOptionsSupplier.is_contractor_supplier &&
                    <List component="nav">
                        <ListItem button onClick={() => { this.props.onOpenServicesView("/servicesprojects/supplier") }}>
                            <ListItemIcon>
                                <ServicesProjectsIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={<Translate id="menu.servicesProjects" />} />
                        </ListItem>
                    </List>}
            </div>
        )
    }

    render() {
        const { anchorEl, open } = this.state;
        const id = open ? 'simple-popper' : null;
        return (
            <React.Fragment>
                <Tooltip title={<Translate id="suppliers.viewQueries" />}>
                    <IconButton onClick={this.toggleMenu}>
                        <QueriesIcon />
                    </IconButton>
                </Tooltip>
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    transition
                    onMouseLeave={() => { this.setState({ open: false, anchorEl: null }) }}
                    style={{ zIndex: '50', position: 'absolute' }}
                    placement={"right-start"}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={50}>
                            <Paper>
                                {this.getMenuItem()}
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </React.Fragment>
        );
    }
}
