
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { SHOW_EDIT_VIEW, APPROVAL_ACEPTED } from '../../../store/helpers/ResourcesHelper';

/**
 * Dialog to add scale data to quote item
 */
class CorrectiveActionCommentsDialog extends Component {

    /**
     *Creates an instance of DialogActions.
    * @param {*} props
    * @memberof DialogActions
    */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            item: this.props.item
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.item !== this.props.item) {
            this.setState({
                item: this.props.item
            })
        }
    }
    /**
     * Function to update scale property of text field in state
     *
     * @param {*} data
     * @memberof CorrectiveActionCommentsDialog
     */
    updateProperty(data) {
        this.setState({
            item: {
                ...this.state.item,
                [data.id]: data.value,
            }
        })
    }

    /**
     * Handle  Close or cancel event
     *
     * @memberof CorrectiveActionCommentsDialog
     */
    handleCloseCancel() {
        this.setState({ item: {} }, () => this.props.onClose());
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof CorrectiveActionCommentsDialog
     */
    isValidToAdd() {
        if (this.state.item && this.state.item.status === APPROVAL_ACEPTED)
            return true

        if (this.state.item && this.state.item.quality_comments
            && this.state.item.quality_comments !== null
            && this.state.item.quality_comments !== '') {
            return true;
        }
        return false
    }

    /**
     * Handle on save corrective action
     *
     * @memberof CorrectiveActionCommentsDialog
     */
    handleOnSave() {
        this.props.saveComment('showActionRequestCommentsDialog', this.state.item)
            .then((value) => {
                if (value === 'Ok') {
                    this.handleCloseCancel();
                }
            });
    }
    /**
     * Dialog to update information
     */
    render() {
        const { classes } = this.props;
        var labelActionToDo = this.state.item ? this.state.item.status === APPROVAL_ACEPTED ? "common.accept" : "common.reject" : ''
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            <Translate id={labelActionToDo} /> | <Translate id="qualityfailurereport.correctiveAction" />
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <div>
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => this.handleCloseCancel()}
                            onClose={() => this.handleCloseCancel()}
                            isSaving={this.props.isSaving}
                            onSaveItem={() => this.handleOnSave()}
                            canBeSaved={this.isValidToAdd()}
                        />
                    </div>
                }>
                <form >
                    <TextField
                        id={'quality_comments'}
                        autoFocus
                        label={<Translate id={'common.comments'} />}
                        value={this.state.item ? this.state.item.quality_comments ? this.state.item.quality_comments : '' : ''}
                        margin="normal"
                        multiline
                        rows="4"
                        fullWidth
                        variant="outlined"
                        onBlur={(event) => this.updateProperty(event.target)}
                        onChange={(event) => this.updateProperty(event.target)}
                        onKeyDown={this.onKeyDown}
                    />
                </form>
            </Dialog>
        )
    }
}

export default withRouter(CorrectiveActionCommentsDialog);