import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CardImageLink from './../components/CardImageLink'
import Chip from '@material-ui/core/Chip'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
/** Material UI Icons imports section */
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Clear'

/** Section action component */
import {
	loadEmployeeFromBackEnd,
	uploadEmployeePhoto,
	updateDataEmployee,
	uploadFiles,
	removeFiles,
} from '../../../store/contractors/EmployeesAction'
import {
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_WARNING,
} from '../../../store/helpers/StatusColorConstants'
import UploadAvatar from '../../suppliers/users/UploadAvatar'
import Toaster from '../../common/Toaster'
import { IsNullOrEmpty, genereteStringGuid } from '../../../store/helpers/StringHelper'
import AddEmployeeDialog from './AddEmployeeDialog'
import Evidence from '../../common/Evidence'
import DataTable from '../../common/DataTable'

///  Images resources
import yourlogohere from '../../../resources/images/yourlogohere.png'
import imssLogo from '../../../resources/images/imssLogo.png'
import iconService from '../../../resources/images/iconService.png'
import iconsIncidents from '../../../resources/images/iconsIncidents.png'
import iconTrainings from '../../../resources/images/iconTrainings.png'
import { showSuccessToaster, showErrorToaster, showInfoToaster } from '../../../store/helpers/ToasterHelpers'
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper'

/**
 * Component show
 */
class Show extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.uploadLogoFileRef = React.createRef()
		this.state = {
			activeTab: 0,
			showChangeAvatarDialog: false,
			editEmployedDialog: false,
			editEployeed: null,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			let employeeToken = this.props.match.params.token
			this.props.loadEmployee(employeeToken)
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			let employeeToken = this.props.match.params.token
			this.props.loadEmployee(employeeToken)
		}
	}

	/**
	 * Method to change the active tab
	 */
	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 * Upload the employee photo
	 *
	 * @param {*} file
	 * @memberof Show
	 */
	uploadLogoFile(file) {
		let self = this
		let fileUpload = file.target.files[0]
		/// Determine if the image to add not is undefined or null
		if (fileUpload !== undefined && fileUpload !== null) {
			var reader = new FileReader()
			reader.onload = function () {
				self.onSelecteFile(reader.result)
			}
			reader.onerror = function (error) {
				console.log('Error: ', error)
			}
			reader.readAsDataURL(fileUpload)
			self.uploadLogoFileRef.current.value = ''
		}
	}

	/**
	 * On select avatar user
	 *
	 * @param {*} fileBase64
	 * @memberof Profile
	 */
	onSelecteFile(fileBase64) {
		this.setState({
			avatarFileBase64: fileBase64,
			croppedFileBase64: fileBase64,
			showChangeAvatarDialog: true,
		})
	}

	/**
	 * On Upload employee image
	 *
	 * @memberof Show
	 */
	onUploadImage(croppedPhoto) {
		let employeeToken = this.props.match.params.token
		return this.props.uploadEmployeePhoto(employeeToken, croppedPhoto)
	}

	/**
	 * Open modal for edit employeed
	 */
	openEditEmployeed() {
		let employee = this.props.employee
		this.setState({
			editEmployedDialog: true,
			editEployeed: {
				token: employee.token,
				name: employee.name,
				insurance_number: employee.insurance_number,
				short_Name: employee.short_name,
				first_Surname: employee.first_surname,
				second_Surname: employee.second_surname,
				rfc_number: employee.RFC,
				curp_Number: employee.CURP,
				position: employee.position,
				department: employee.department,
				is_active: employee.is_active,
			},
		})
	}

	/**
	 * Aciton to on cancel edit employee
	 */
	onCloseEditEmployee() {
		this.setState({
			editEmployedDialog: false,
			editEployeed: null,
		})
	}

	getErrorMessage(response) {
		if (!IsNullOrEmpty(response.data.Message) && typeof response.data.Message === 'string') {
			if (response.data.Message.includes('El número del seguro ya existe')) {
				return showInfoToaster('validationErrors.invalidNSS')
			} else if (response.data.Message.includes('El CURP no puede ser nulo.'))
				return showInfoToaster('validationErrors.invalidCURP')
			else if (response.data.Message.includes('El RFC no puede ser nulo.'))
				return showInfoToaster('validationErrors.invalidRFC')
			else if (response.data.Message.includes('El nombre no puede estar vacío.'))
				return showInfoToaster('validationErrors.invalidName')
			else if (response.data.Message.includes('El Proveedor no puede estar vacío.'))
				return showInfoToaster('validationErrors.invalidSupplier')
			else if (response.data.Message.includes('El número de seguro no puede estar vacío. '))
				return showInfoToaster('validationErrors.invalidInsuranceNumber')
			else if (response.data.Message.includes('El(Los) nombre(s) no puede estar vacío.'))
				return showInfoToaster('validationErrors.invalidShortName')
			else if (response.data.Message.includes('"El apellido paterno no puede estar vacío.'))
				return showInfoToaster('validationErrors.invalidFirstSurname')
			else if (response.data.Message.includes('El tipo de personal no puede estar vacío. '))
				return showInfoToaster('validationErrors.invalidPosition')
			else if (response.data.Message.includes('El puesto desempeñado no puede estar vacío.'))
				return showInfoToaster('validationErrors.invalidDepartment')
			else if (response.data.Message.includes('La CURP proporcionada no es válida.'))
				return showInfoToaster('validationErrors.invalidCurpFormat')
			else if (response.data.Message.includes('El RFC proporcionada no es válida.'))
				return showInfoToaster('validationErrors.invalidRfcformat')
			else return showErrorToaster()
		} else {
			return showInfoToaster(response.data.Message)
		}
	}

	/**
	 * call to action to update data employee
	 *
	 * @memberof FormView
	 */
	updateEmployeed(editItem) {
		if (editItem !== null) {
			this.props
				.updateDataEmployee(editItem)
				.then(() => {
					this.setState(showSuccessToaster())
					this.setState({
						editEmployedDialog: false,
						editEployeed: null,
					})
				})
				.catch((result) => {
					if (result.status === 400) {
						this.setState(this.getErrorMessage(result))
					} else {
						this.setState(showErrorToaster())
					}
				})
		}
	}

	/**
	 * return custom cell in data table
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @param {*} type
	 * @returns
	 * @memberof Show
	 */
	onRenderCellItem(dataSource, item, type) {
		if (dataSource === 'image') {
			let src = iconsIncidents
			if (type === 'training') src = iconTrainings
			return <img alt={type} src={src} width={40} height={40} />
		}
	}

	/**
	 * Render
	 */
	render() {
		const { classes } = this.props
		//EMPLOYEE PROFILE PHOTO
		let employeePhoto = this.props.employee ? this.props.employee.image_employed : ''
		let urlImageProfile = `${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${employeePhoto}`
		let logoUrl = urlImageProfile + '?token=' + genereteStringGuid()

		//BARCODE IMAGE
		let relativeBarCodeUrl = this.props.employee ? this.props.employee.bar_code_url : ''
		let urlBarCode = `${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${relativeBarCodeUrl}`

		let barCode = (
			<img src={relativeBarCodeUrl ? urlBarCode : yourlogohere} alt='barCode' className={classes.barCode} />
		)
		let accessGranted = this.props.employee && this.props.employee.access_granted

		return (
			<Card>
				<CardHeader
					avatar={
						<Tooltip title='Click para subir una foto'>
							<Avatar
								alt={this.props.employee && this.props.employee.name}
								src={logoUrl}
								className={classes.avatar}
								onClick={() => {
									this.uploadLogoFileRef.current.click()
								}}
							/>
						</Tooltip>
					}
					action={
						<Grid container spacing={0} justify='flex-end'>
							<Grid item xs={3} justify='flex-end'>
								<Tooltip title={<Translate id='common.edit' />}>
									<IconButton
										onClick={() => {
											this.openEditEmployeed()
										}}
									>
										<EditIcon />
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item xs={10}>
								{barCode}
							</Grid>
						</Grid>
					}
					title={
						this.props.employee && (
							<h3>
								<strong>{this.props.employee.name}</strong>
							</h3>
						)
					}
					subheader={
						<Grid container spacing={24}>
							<Grid item xs={12}>
								<strong>
									<Translate id='contractorsModule.socialSecurityNumber' />
								</strong>
								: {this.props.employee && this.props.employee.insurance_number} <br />
								<strong>
									<Translate id='contractorsModule.employee' />
								</strong>
								:{' '}
								{this.props.employee && this.props.employee.is_active ? (
									<Translate id='contractorsModule.available' />
								) : (
									<Translate id='contractorsModule.disabled' />
								)}
							</Grid>
							<Grid item xs={12}>
								<Chip
									style={{
										color: '#fff',
										backgroundColor: accessGranted ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER,
									}}
									icon={
										accessGranted ? (
											<CheckCircleIcon style={{ color: '#fff' }} />
										) : (
											<CancelIcon style={{ color: '#fff' }} />
										)
									}
									label={
										<Translate
											id={
												accessGranted
													? 'contractorsModule.accessGranted'
													: 'contractorsModule.accessDenied'
											}
										/>
									}
								/>
								{this.props.employee && this.props.employee.message_access_granted && (
									<strong style={{ color: COLOR_STATUS_DANGER }}>
										{' '}
										<br />
										{this.props.employee.messages.map((message) => {
											return (
												<span>
													{message}
													<br />
												</span>
											)
										})}
									</strong>
								)}
								<span style={{ color: COLOR_STATUS_WARNING }}>
									{this.props.employee && this.props.employee.waring_incidence}
								</span>
							</Grid>
						</Grid>
					}
				/>

				<input
					className={classes.logoFileInput}
					type='file'
					accept='image/*'
					ref={this.uploadLogoFileRef}
					multiple={false}
					onChange={this.uploadLogoFile}
				/>

				<CardContent>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<Divider />
							<AppBar position='static' color='default'>
								<Tabs
									indicatorColor='secondary'
									textColor='secondary'
									className={classes.styleTab}
									value={this.state.activeTab}
									onChange={this.onClickToActiveTab}
									variant='fullWidth'
								>
									<Tab
										label={
											<Translate id='contractorsModule.employeesTabs.socialSecurityPayments' />
										}
									/>
									<Tab label={<Translate id='contractorsModule.employeesTabs.services' />} />
									<Tab label={<Translate id='contractorsModule.employeesTabs.incidentsReported' />} />
									<Tab
										label={
											<Translate id='contractorsModule.employeesTabs.safetyInductionCourses' />
										}
									/>
									<Tab
										label={
											<Translate id='contractorsModule.employeesTabs.officialIdentification' />
										}
									/>
								</Tabs>
							</AppBar>
							{this.state.activeTab === 0 && (
								<CardImageLink
									existPath={true}
									isPayment={true}
									history={this.props.history}
									data={this.props.employee && this.props.employee.social_insurance_payments}
									baseRedirectionPath={'/socialinsurancepayments'}
									cardImage={imssLogo}
								/>
							)}
							{this.state.activeTab === 1 && (
								<CardImageLink
									existPath={true}
									isService={true}
									history={this.props.history}
									data={this.props.employee && this.props.employee.service_projects}
									baseRedirectionPath={'/servicesprojects'}
									cardImage={iconService}
								/>
							)}
							{this.state.activeTab === 2 && (
								<DataTable
									data={this.props.employee ? this.props.employee.employee_incidences : []}
									configuration={IncidencesTableConfiguration}
									onRenderCellItem={(dataSource, item) =>
										this.onRenderCellItem(dataSource, item, 'incidence')
									}
								/>
							)}
							{this.state.activeTab === 3 && (
								<DataTable
									data={this.props.employee ? this.props.employee.employee_trainings : []}
									configuration={TrainingsTableConfiguration}
									onRenderCellItem={(dataSource, item) =>
										this.onRenderCellItem(dataSource, item, 'training')
									}
								/>
							)}
							{this.state.activeTab === 4 && (
								<Evidence
									acceptedFiles={['pdf']}
									isDisabled={
										(this.props.employee && !this.props.employee.is_active) ||
										IsCurrentActiveDirectoryUser(this.props.currentUser)
									}
									baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
									removeFiles={this.props.removeFiles}
									uploadFiles={this.props.uploadFiles}
									files={this.props.employee.identifications}
									text={<Translate id='contractorsModule.dropzoneIdentification' />}
								/>
							)}
						</Grid>
					</Grid>
				</CardContent>
				{this.state.showChangeAvatarDialog ? (
					<UploadAvatar
						open={this.state.showChangeAvatarDialog}
						fileBase64={this.state.avatarFileBase64}
						onClose={() => {
							this.setState({ showChangeAvatarDialog: false })
						}}
						onUploadImage={this.onUploadImage}
					/>
				) : null}

				{this.state.editEmployedDialog && (
					<AddEmployeeDialog
						editEployeed={this.state.editEployeed}
						open={this.state.editEmployedDialog}
						isSaving={this.props.isSavingEmployee}
						updateEmployee={this.updateEmployeed}
						onClose={this.onCloseEditEmployee}
						selectedCompany={this.props.selectedCompany}
					/>
				)}

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const IncidencesTableConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'image',
		},
		{
			header: <Translate id='contractorsModule.incidences.description' />,
			dataSource: 'description_incidence',
		},
		{
			header: <Translate id='contractorsModule.registeredBy' />,
			dataSource: 'register_by',
		},
		{
			header: <Translate id='contractorsModule.incidences.date' />,
			dataSource: 'incidence_date',
		},
		{
			header: <Translate id='contractorsModule.incidences.restrictAccess' />,
			dataSource: 'restrict_access',
		},
	],
}

const TrainingsTableConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'image',
		},
		{
			header: <Translate id='contractorsModule.trainings.trainingDate' />,
			dataSource: 'course_date',
		},
		{
			header: <Translate id='contractorsModule.trainings.dueDate' />,
			dataSource: 'due_curse_date',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'status',
		},
		{
			header: <Translate id='contractorsModule.registeredBy' />,
			dataSource: 'register_by',
		},
	],
}

const styles = (theme) => ({
	barCode: {
		maxHeight: 80,
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		margin: 10,
		width: 120,
		height: 120,
	},
	styleTab: {
		backgroundColor: '#fff',
	},
	logoFileInput: {
		display: 'none',
	},
})

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	return {
		currentUser: user,
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingEmployee: state.contractorEmployees.get('isLoadingEmployee'),
		isSavingEmployee: state.contractorEmployees.get('isSavingEmployee'),
		employee: state.contractorEmployees.get('employee') ? state.contractorEmployees.get('employee').toJS() : null,
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadEmployee: (token) => {
			dispatch(loadEmployeeFromBackEnd(token))
		},
		uploadEmployeePhoto: (employeeToken, photo) => {
			return dispatch(uploadEmployeePhoto(employeeToken, photo))
		},
		updateDataEmployee: (employee) => {
			return dispatch(updateDataEmployee(employee))
		},
		uploadFiles: (files) => {
			return dispatch(uploadFiles(files))
		},
		removeFiles: (fileName) => {
			return dispatch(removeFiles(fileName))
		},
	}
}

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
