import React from 'react'
import moment from 'moment'
import { Translate } from 'react-localize-redux'

import { Card, CardHeader, List, ListItem, ListItemText, Typography } from '@material-ui/core'

const Information = (props) => {
	const { qualityCertificate, getColorText, descriptionStatus, translate, classes } = props
	let urlImageCompany = `${process.env.REACT_APP_SIP_URL_LOGOS_ADMINISTRATION}/Images/yourlogohere.png` //Logos/f6d7b8cd-5a3c-45d0-88a9-aaf3e6864a8d.png`
	return (
		<Card>
			<CardHeader
				avatar={<img src={urlImageCompany} alt='companyLogo' style={{ maxHeight: 90 }} />}
				title={
					<Typography gutterBottom variant='h6'>
						<strong>{qualityCertificate.Folio}</strong>
						{': '}
						{'Solicitud de Certificado de Calidad '}
					</Typography>
				}
				subheader={
					<p>
						{moment(qualityCertificate.creationDate).format('LLLL')}
						<br /> {qualityCertificate.CreatedName}
						<br />
						<strong
							className={classes.btnStatus}
							style={{ backgroundColor: getColorText(qualityCertificate.Status) }}
						>
							{descriptionStatus(qualityCertificate.Status, translate)}
						</strong>
					</p>
				}
			/>
			<List>
				<ListItem divider>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									component='span'
									variant='body2'
									style={{ display: 'inline' }}
									color='textPrimary'
								>
									<Translate id='qualityCertificate.supplier' />
								</Typography>
								{': '}
								{qualityCertificate.SupplierCode}
								{' | '}
								{qualityCertificate.SupplierName}
							</React.Fragment>
						}
					/>
				</ListItem>
				<ListItem divider>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									component='span'
									variant='body2'
									style={{ display: 'inline' }}
									color='textPrimary'
								>
									<Translate id='qualityCertificate.invoice' />
								</Typography>
								{': '}
								{qualityCertificate.Invoice}
							</React.Fragment>
						}
					/>
				</ListItem>
				<ListItem divider>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									component='span'
									variant='body2'
									style={{ display: 'inline' }}
									color='textPrimary'
								>
									<Translate id='qualityCertificate.comments' />
								</Typography>
								{': '}
								{qualityCertificate.Comments}
							</React.Fragment>
						}
					/>
				</ListItem>
			</List>
		</Card>
	)
}

export default Information
