import React, { Component } from 'react';
import autoBind from 'auto-bind'
import PropTypes from 'prop-types';

/** Translations imports section */
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SuppliersIcon from '@material-ui/icons/People';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Grid } from '@material-ui/core';

import SuppliersSelector from '../../common/SupplierSelector';

/**
 * Component to suppliers in office
 */
class SuppliersCard extends Component {

    /**
   * Create an instance of Internal user
   * @param {*} props 
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            suppliers: [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.suppliers !== this.props.suppliers) {
            this.setState({
                suppliers: this.props.suppliers
            })
        }
    }

    getSuppliersList() {
        if (this.state.suppliers) {
            return (
                <List>
                    {this.state.suppliers.map((supplier) => {
                        return (<div key={supplier.name}>
                            <ListItem divider>
                                <ListItemText primary={<Typography variant="caption" gutterBottom>{supplier.name}</Typography>} />
                                <ListItemSecondaryAction>
                                    <IconButton id={supplier.token}
                                        onClick={(event) => { this.props.removeSupplier(event.currentTarget.id) }}
                                    >
                                        <DeleteIcon color='error' />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </div>
                        )
                    })}
                </List>
            )
        } else {
            return null;
        }
    }


    render() {
        const { classes } = this.props;

        return (
            <Card className={classes.card} style={{ height: '79vh' }}>
                <CardHeader
                    avatar={
                        <Avatar className={classes.userAvatar}>
                            <SuppliersIcon />
                        </Avatar>
                    }
                    title={<Translate id="officialNotices.showNextSuppliers" />}
                    subheader={<Translate id="officialNotices.addSupplier" />}
                />
                <CardContent >
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <br/>
                            <SuppliersSelector
                                className={classes.textField}
                                onSupplierSelected={(supplier) => { this.props.updateSuppliersList(supplier) }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                {this.getSuppliersList()}
                                {this.state.suppliers.length === 0 &&
                                    <div>
                                        <ListItem>
                                            <ListItemText primary={
                                                <Typography variant="subtitle2" gutterBottom>
                                                    <Translate id="questionnaire.noSuppliersToSendQuestionnaire" />
                                                </Typography>
                                            } />
                                        </ListItem><Divider />
                                    </div>
                                }
                            </List>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions className={classes.actions} disableActionSpacing>
                </CardActions>
            </Card>
        );
    }
}

SuppliersCard.propTypes = {
    suppliers: PropTypes.array.isRequired,
};

export default SuppliersCard;