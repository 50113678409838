import React from 'react'
import { Translate } from 'react-localize-redux'

import { QuestionStatusType, CertificateStatus } from '../helpers/AppConstants'
import { IsUserSupplierAdministrator } from '../helpers/SessionHelper'
import { IsNullOrEmpty } from '..//helpers/StringHelper'
import { canBeRespondeModuleQualitySupplier, canUserCreateReportModuleQuality } from '../helpers/RolesHelper'
import {
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_WARNING,
	COLOR_STATUS_GRAY,
} from '../helpers/StatusColorConstants'

export function canBeEditSupplier(qualityCertificate, userRolesByCompany, userProfile) {
	if (userRolesByCompany === undefined) return false
	if (userProfile === null || userProfile === undefined) return false
	if (qualityCertificate === null || qualityCertificate === undefined) return false
	if (!qualityCertificate.CanBeEditSupplier) return false
	let supplierAdmin = IsUserSupplierAdministrator(userProfile)
	return supplierAdmin || canBeRespondeModuleQualitySupplier(userRolesByCompany)
}

export function canBeUpdatePlant(qualityCertificate, userRolesByCompany, userProfile) {
	if (userRolesByCompany === undefined) return false
	if (userProfile === null || userProfile === undefined) return false
	if (qualityCertificate === null || qualityCertificate === undefined) return false
	if (!qualityCertificate.CanBeEditPlant) return false
	return canUserCreateReportModuleQuality(userRolesByCompany)
}

export const getStatusQuestion = (status) => {
	if (status === QuestionStatusType.COMPLIES) return 'Aceptado'
	else if (status === QuestionStatusType.FAILS) return 'Rechazado'
	else return 'Agregado'
}

export const canBeValidate = (status) => {
	return status !== QuestionStatusType.COMPLIES
}

export function TableConfiguration(canbeUpdateSupplier, canbeUpdatePlant, status, onOpenChangeStatus) {
	let columns = []

	if (canbeUpdateSupplier && status === CertificateStatus.DRAFT) {
		columns.push({
			value: CertificateStatus.SENT_TO_VALIDATE,
			title: <Translate id='qualityCertificate.sendToPlant' />,
			onClick: () => onOpenChangeStatus(CertificateStatus.SENT_TO_VALIDATE),
			isIconMaterial: true,
			description: <Translate id='qualityCertificate.sentToPlantMessage' />,
		})
		columns.push({
			value: CertificateStatus.CANCELLED,
			title: <Translate id='qualityCertificate.cancelledFolio' />,
			onClick: () => onOpenChangeStatus(CertificateStatus.CANCELLED),
			isIconMaterial: true,
			description: <Translate id='qualityCertificate.messageCancelledFolio' />,
		})
		columns.push({
			value: CertificateStatus.REMOVE,
			title: <Translate id='qualityCertificate.removeRequest' />,
			onClick: () => onOpenChangeStatus(CertificateStatus.REMOVE),
			isIconMaterial: true,
			description: <Translate id='qualityCertificate.messageRemovedFolio' />,
		})
	}

	if (canbeUpdateSupplier && status === CertificateStatus.FORWAEDED) {
		columns.push({
			value: CertificateStatus.SENT_TO_VALIDATE,
			title: <Translate id='qualityCertificate.sendToPlant' />,
			onClick: () => onOpenChangeStatus(CertificateStatus.SENT_TO_VALIDATE),
			isIconMaterial: true,
			description: <Translate id='qualityCertificate.sentToPlantMessage' />,
		})
		columns.push({
			value: CertificateStatus.CANCELLED,
			title: <Translate id='qualityCertificate.cancelledFolio' />,
			onClick: () => onOpenChangeStatus(CertificateStatus.CANCELLED),
			isIconMaterial: true,
			description: <Translate id='qualityCertificate.messageCancelledFolio' />,
		})
	}

	if (canbeUpdatePlant && status === CertificateStatus.SENT_TO_VALIDATE) {
		columns.push({
			value: CertificateStatus.FORWAEDED,
			title: <Translate id='qualityCertificate.resentdFolio' />,
			onClick: () => onOpenChangeStatus(CertificateStatus.FORWAEDED),
			isIconMaterial: true,
			description: <Translate id='qualityCertificate.messageResentCarriertFolio' />,
		})
		columns.push({
			value: CertificateStatus.COMPLETED,
			title: <Translate id='qualityCertificate.completedFolio' />,
			onClick: () => onOpenChangeStatus(CertificateStatus.COMPLETED),
			isIconMaterial: true,
			description: <Translate id='qualityCertificate.messageCompletedFolio' />,
		})
		columns.push({
			value: CertificateStatus.CANCELLED,
			title: <Translate id='qualityCertificate.cancelledFolio' />,
			onClick: () => onOpenChangeStatus(CertificateStatus.CANCELLED),
			isIconMaterial: true,
			description: <Translate id='qualityCertificate.messageCancelledFolio' />,
		})
	}
	return columns
}

export const getColorText = (status) => {
	switch (Number(status)) {
		case CertificateStatus.DRAFT:
			return COLOR_STATUS_GRAY
		case CertificateStatus.SENT_TO_VALIDATE:
		case CertificateStatus.FORWAEDED:
			return COLOR_STATUS_WARNING
		case CertificateStatus.COMPLETED:
			return COLOR_STATUS_SUCCESS
		case CertificateStatus.CANCELLED:
			return COLOR_STATUS_DANGER
		default:
			return ''
	}
}

export const descriptionStatus = (status, translate) => {
	switch (Number(status)) {
		case CertificateStatus.DRAFT:
			return translate('qualityCertificate.status.draft')
		case CertificateStatus.SENT_TO_VALIDATE:
			return translate('qualityCertificate.status.sedPlant')
		case CertificateStatus.COMPLETED:
			return translate('qualityCertificate.status.completed')
		case CertificateStatus.CANCELLED:
			return translate('qualityCertificate.status.cancelled')
		case CertificateStatus.FORWAEDED:
			return translate('qualityCertificate.status.resentFolio')
		default:
			return ''
	}
}

export function onTitleStatusDialog(status) {
	switch (status) {
		case CertificateStatus.SENT_TO_VALIDATE:
			return 'qualityCertificate.sendToPlant'
		case CertificateStatus.COMPLETED:
			return 'qualityCertificate.completedFolio'
		case CertificateStatus.CANCELLED:
			return 'qualityCertificate.cancelledFolio'
		case CertificateStatus.FORWAEDED:
			return 'qualityCertificate.resentdFolio'
		case CertificateStatus.REMOVE:
			return 'qualityCertificate.removeRequest'
		default:
			return 'consignmentNote.show.noOption'
	}
}
export function onMessageStatusDialog(status) {
	switch (status) {
		case CertificateStatus.SENT_TO_VALIDATE:
			return 'qualityCertificate.sentToPlantMessage'
		case CertificateStatus.COMPLETED:
			return 'qualityCertificate.messageCompletedFolio'
		case CertificateStatus.CANCELLED:
			return 'qualityCertificate.messageCancelledFolio'
		case CertificateStatus.FORWAEDED:
			return 'qualityCertificate.messageResentCarriertFolio'
		case CertificateStatus.REMOVE:
			return 'qualityCertificate.messageRemovedFolio'
		default:
			return 'consignmentNote.show.noOption'
	}
}

export const getErrorInChangeStatus = (response) => {
	if (!IsNullOrEmpty(response.data) && typeof response.data === 'string') {
		if (response.data.includes('invalidNotExtistDetails')) return 'qualityCertificate.error.invalidNotExtistDetails'
		else if (response.data.includes('invalidNotExtistDocuments'))
			return 'qualityCertificate.error.invalidNotExtistDocuments'
		else if (response.data.includes('invalidToIsInValidToResend'))
			return 'qualityCertificate.error.invalidToIsInValidToResend'
		else if (response.data.includes('invalidIsInValidToCompleted'))
			return 'qualityCertificate.error.invalidIsInValidToCompleted'
		else if (response.data.includes('invalidRemoveQualityCertificate'))
			return 'qualityCertificate.error.invalidRemoveQualityCertificate'
		else if (response.data.includes('invalidToCommentsToResend'))
			return 'qualityCertificate.error.invalidToCommentsToResend'
		else if (response.data.includes('invalidToInvoiceAlreadyExists'))
			return 'qualityCertificate.error.invalidToInvoiceAlreadyExists'
		else return 'common.errorToSave'
	} else {
		return 'common.errorToSave'
	}
}
