import React from 'react'
import uuidv4 from 'uuid/v4'
import moment from 'moment'
import { Translate } from 'react-localize-redux'

import {
	List,
	ListItem,
	ListItemText,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Chip,
} from '@material-ui/core'
import LinkMaterial from '@material-ui/core/Link'
import AttachIcon from '@material-ui/icons/AttachFile'
import CloseIcon from '@material-ui/icons/Cancel'

import Evidence from '../../common/Evidence'
import HtmlEditor from '../../common/HtmlEditor'
import pdf from './FO-V-AC-005.pdf'
import { COLOR_CART_PORT } from '../../../store/helpers/StatusColorConstants'

const DocumentList = (props) => {
	const {
		onUploadFilesQualityCertificate,
		qualityCertificate,
		OnDeleteFilesQualityCertificate,
		canBeEditSupplier,
		onGetfileDownload,
	} = props

	const onChangeFilesQualityCertificate = (files) => {
		return onUploadFilesQualityCertificate(qualityCertificate.Id, files, 'qualityCertificate')
	}
	const onChangeFilesInspectionReport = (files) => {
		return onUploadFilesQualityCertificate(qualityCertificate.Id, files, 'inspectionReport')
	}
	const onchangeFilesOtherAnnexes = (files) => {
		return onUploadFilesQualityCertificate(qualityCertificate.Id, files, 'otherAnnexes')
	}
	const onChangeFilesInvoice = (files) => {
		return onUploadFilesQualityCertificate(qualityCertificate.Id, files, 'invoice')
	}
	const onDeleteFilesQualityCertificate = (fileName) => {
		return OnDeleteFilesQualityCertificate(qualityCertificate.Id, fileName, 'qualityCertificate')
	}
	const onDeleteFilesInspecttionReport = (fileName) => {
		return OnDeleteFilesQualityCertificate(qualityCertificate.Id, fileName, 'inspectionReport')
	}
	const onDeleteFilesOtherAnnexes = (fileName) => {
		return OnDeleteFilesQualityCertificate(qualityCertificate.Id, fileName, 'otherAnnexes')
	}
	const onDeleteFilesInvoice = (fileName) => {
		return OnDeleteFilesQualityCertificate(qualityCertificate.Id, fileName, 'invoice')
	}
	const onDownloadFile = (item) => {
		return onGetfileDownload(item)
	}

	const renderFilesShippings = (titleTranslate, onChange, onDelete, items, fileName) => {
		let isrequieresQuality =
			titleTranslate === 'qualityCertificate' ? '**' : titleTranslate === 'invoice' ? '**' : ''
		let isRequieredSerial = ''

		return (
			<TableRow key={uuidv4()}>
				<TableCell key={uuidv4()} align='left' padding='dense'>
					{isRequieredSerial}
					{isrequieresQuality}
					{<Translate id={'qualityCertificate.' + titleTranslate} />}
					<br />
					{titleTranslate === 'inspectionReport' ? (
						<LinkMaterial target='_blank' href={pdf}>
							{'Descargar plantilla'}
						</LinkMaterial>
					) : null}
				</TableCell>
				<TableCell key={uuidv4()} align='left' padding='dense' style={{ width: '40%' }}>
					{renderUploadDates(items)}
				</TableCell>
				<TableCell key={uuidv4()} align='left' padding='dense' style={{ width: '40%' }}>
					<Evidence
						text={<Translate id='dropzone.dropzoneBase' />}
						baseApi={process.env.REACT_APP_SIP_SUSTAINABILITY}
						isDisabled={!canBeEditSupplier}
						removeFiles={onDelete}
						uploadFiles={onChange}
					/>
					<div style={{ margin: '5px' }}>
						{items.map((file) => {
							return (
								<Chip
									icon={<AttachIcon />}
									label={
										<div
											style={{
												maxWidth: '100px',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}
										>
											<Typography noWrap title={file.Name}>
												{file.Name}
											</Typography>
										</div>
									}
									clickable={true}
									component='div'
									key={file.Name}
									onClick={() => {
										onDownloadFile(file)
									}}
									color='primary'
									onDelete={
										canBeEditSupplier
											? () => {
													onDelete(file.Name)
											  }
											: ''
									}
									deleteIcon={canBeEditSupplier ? <CloseIcon id={file.Name} /> : ''}
									style={{
										margin: '1px',
										maxWidth: '450px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								></Chip>
							)
						})}
					</div>
					{fileName && <HtmlEditor id={fileName} labelVariant={'body2'} isDisabled initialValue={fileName} />}
				</TableCell>
			</TableRow>
		)
	}

	const renderUploadDates = (files) => {
		let rows = []
		files.forEach((file) => {
			rows.push(
				<div style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
					<Typography variant='caption' noWrap title={file.Name}>{`${moment(
						file.CreationDate,
						'DD/MM/yyyy'
					).format('DD-MM-YYYY hh:mm')} | ${file.Name}`}</Typography>
					<br />
				</div>
			)
		})
		return rows
	}
	return (
		<Paper>
			<List>
				<ListItem divider style={{ backgroundColor: COLOR_CART_PORT }}>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									component='span'
									variant='body2'
									style={{ display: 'inline' }}
									color='textPrimary'
								>
									<strong>
										<Translate id='shippingOrder.documents' />
									</strong>
								</Typography>
							</React.Fragment>
						}
					/>
				</ListItem>
			</List>

			<Table style={{ width: '100%' }}>
				<TableHead>
					<TableRow>
						<TableCell style={{ width: '20%' }}>
							<Translate id='shippingOrder.documentName' />
						</TableCell>
						<TableCell style={{ width: '40%' }}>
							<Translate id='shippingOrder.uploadDates' />
						</TableCell>
						<TableCell style={{ width: '40%' }}>
							<Translate id='shippingOrder.documentOptions' />
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{renderFilesShippings(
						'qualityCertificate',
						onChangeFilesQualityCertificate,
						onDeleteFilesQualityCertificate,
						qualityCertificate.Documents.filter((x) => x.Status === 0),
						''
					)}
					{renderFilesShippings(
						'invoice',
						onChangeFilesInvoice,
						onDeleteFilesInvoice,
						qualityCertificate.Documents.filter((x) => x.Status === 3),
						''
					)}
					{renderFilesShippings(
						'inspectionReport',
						onChangeFilesInspectionReport,
						onDeleteFilesInspecttionReport,
						qualityCertificate.Documents.filter((x) => x.Status === 1),
						''
					)}
					{renderFilesShippings(
						'otherAnnexes',
						onchangeFilesOtherAnnexes,
						onDeleteFilesOtherAnnexes,
						qualityCertificate.Documents.filter((x) => x.Status === 2),
						''
					)}
				</TableBody>
			</Table>
		</Paper>
	)
}
export default DocumentList
