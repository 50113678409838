import { createSelector } from 'reselect'
import { toSafeJS } from '../helpers/ImmutableHelper'

export const getLoadingTrainings = (state) => state.trainings.get('isLoanding')

export const getIsSavingTraining = (state) => state.trainings.get('isSavingTraining')

export const getIsLoadingTraining = (state) => state.trainings.get('isLoadingTraining')

const getTrainigs = (state) => state.trainings.get('trainings')
export const getTrainigsSelector = createSelector([getTrainigs], (trainings) => toSafeJS(trainings))

const getTrainig = (state) => state.trainings.get('training')
export const getTrainigSelector = createSelector([getTrainig], (training) => toSafeJS(training))

const getTrainigCount = (state) => state.trainings.get('trainingCount')
export const getTrainigCountSelector = createSelector([getTrainigCount], (trainingCount) => toSafeJS(trainingCount))
