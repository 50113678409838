import React from 'react'
import DialogComponent from '../common/DialogComponent'
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import { TextField, Typography } from '@material-ui/core'

const IncidenceDialogProposal = ({ onClose, open, onAddIncidence, setCommentIncident }) => {
	return (
		<DialogComponent
			open={open}
			onClose={() => onClose()}
			children={
				<div>
					<Typography variant='caption'>
						Agrega en comentario la incidencia acerca del transportista en la licitación
					</Typography>
					<TextField
						// className={classes.textField}
						fullWidth
						label={'Comentario'}
						onChange={(event) => setCommentIncident(event.target.value)}
						margin='dense'
						variant='outlined'
						multiline
						rowsMax={4}
					/>
				</div>
			}
			canBeSaved={true}
			// isSaving={isProposal}
			onSave={onAddIncidence}
			title={'Agregar incidencia '}
			view={SHOW_EDIT_VIEW}
		/>
	)
}
export default IncidenceDialogProposal
