//import React from 'react';
import moment from 'moment';
//import { Translate } from "react-localize-redux";
import { capitalize } from '../../../../store/helpers/StringHelper';

export const buildFilterConfig = (dataSource) => {
	let type = '';
	let name = '';
	let queryStringKey;
	let customOptions = [];
	// const url = `${process.env.REACT_APP_CONCILIATION_BASE_API}/services/filtervalues`;
	const url = `${process.env.REACT_APP_CONCILIATION_BASE_API}catalogs/values`;
	const entityName = 'ServiceOrder';
	switch (dataSource) {
		case 'age': {
			queryStringKey = 'CloseDate';
			type = 'DATE';
			name = '';//'Antigüedad';
			const now = moment();
			customOptions = [
				{
					parentDescription: 'HOY',
					key: 'today',
					filterValues: [moment().format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]
				},
				{
					parentDescription: 'UNA SEMANA ATRÁS',
					key: 'this-week',
					filterValues: [moment().subtract(1, 'weeks').format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]
				},
				{
					parentDescription: 'UN MES ATRÁS',
					key: 'this-month',
					filterValues: [moment().subtract(1, 'months').format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]
				},
				{
					parentDescription: 'UN TRIMESTRE ATRÁS',
					key: 'this-trimester',
					filterValues: [moment().subtract(3, 'months').format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]
				},
				{
					parentDescription: 'UN SEMESTRE ATRÁS',
					key: 'this-six-months',
					filterValues: [moment().subtract(6, 'months').format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]
				},
				{
					parentDescription: 'MÁS ANTIGÜAS',
					key: 'older',
					filterValues: ['2010-01-01', now.subtract(6, 'months').format('YYYY-MM-DD')]
				}
			];
			break;
		}
		case 'branchOffice': {
			type = 'TEXT';
			name = 'Sucursal';//<Translate id="common.bussinesUnit" />;
			queryStringKey = 'BranchOfficeId';
			break;
		}
		case 'chainId': {
			type = 'TEXT';
			name = 'Cadena';//<Translate id="common.bussinesUnit" />;
			break;
		}
		case 'branchOfficeName': {
			type = 'TEXT';
			name = 'Unidad de negocio';//<Translate id="common.bussinesUnit" />;
			queryStringKey = 'BranchOfficeId';
			break;
		}
		case 'code': {
			type = 'TEXT';
			name = 'Orden de servicio';//<Translate id="accreditedWorkshops.serviceOrderId" />;
			break;
		}
		case 'corporate': 
		case 'corporateName': {
			type = 'TEXT';
			name = 'Cliente';//<Translate id="common.customer" />;
			queryStringKey = 'CorporateId';
			break;
		}
		case 'serviceType': {
			type = 'TEXT';
			name = 'Tipo de servicio';//<Translate id="accreditedWorkshops.serviceType" />;
			queryStringKey = 'ServiceTypeId';
			customOptions = [
				{ key: '1001', code: '1001', label: 'RUTA', description: '1001 - RUTA' },
				{ key: '1002', code: '1002', label: 'PISO', description: '1002 - PISO' },
				{ key: '1003', code: '1003', label: 'MOVIMIENTOS', description: '1003 - MOVIMIENTOS' },
				{ key: '1004', code: '1004', label: 'INST. NUEVOS', description: '1004 - INST. NUEVOS' },
				{ key: '1005', code: '1005', label: 'INST. ELECTRICAS', description: '1005 - INST. ELECTRICAS' },
				{ key: '1006', code: '1006', label: 'SANITIZACIÓN POST MIX', description: '1006 - SANITIZACIÓN POST MIX' }
			]
			break;
		}
		case 'workshopTechnicianPs9Id': {
			type = 'TEXT';
			name = 'Técnico';//<Translate id="accreditedWorkshops.serviceType" />;
			break;
		}
		case 'attentionDate': {
			type = 'DATE';
			name = 'Fecha de atención';//<Translate id="accreditedWorkshops.osDate" />;
			break;
		}
		case 'closeDate': {
			type = 'DATE';
			name = 'Fecha de cierre';//<Translate id="quotes.closingDate" />;
			break;
		}
		case 'creationDate': {
			type = 'DATE';
			name = 'Fecha de OS';//<Translate id="quotes.closingDate" />;
			break;
		}
		case 'category': {
			type = 'TEXT';
			name = 'Categoría';//<Translate id="officialNotices.category" />;
			break;
		}
		case 'serviceDescription': {
			type = 'TEXT';
			name = 'servicio';//<Translate id="accreditedWorkshops.accreditedWorkshopsService" />;
			queryStringKey = 'ServiceId';
			break;
		}
		case 'tallerImporteServicio': {
			type = 'NUMBER';
			name = 'Importe de servicio';//<Translate id="accreditedWorkshops.serviceAmount" />;
			break;
		}
		case 'tallerImporteMateriales': {
			type = 'NUMBER';
			name = 'Importe de materiales';//<Translate id="accreditedWorkshops.materialsAmount" />;
			break;
		}
		case 'tallerImporteGastos': {
			type = 'NUMBER';
			name = 'Importe de gastos';//<Translate id="accreditedWorkshops.expensesAmount" />;
			break;
		}
		case 'warranty': {
			type = 'TEXT';
			name = 'Garantia';//<Translate id="officialNotices.category" />;
			break;
		}
		default: {
			console.warn(`Configuration for ${dataSource} not found!`);
			return {};
		}
	}
	return {
		filterId: capitalize(dataSource),
		queryStringKey: !queryStringKey ? capitalize(dataSource) : queryStringKey,
		type,
		name,
		url,
		entityName,
		customOptions
	};
};

export const getAdvancedFilterOptions = (type) => {
	switch (type) {
		case 'TEXT': {
			return [
				{
					key: 'submenu-item-1',
					criteria: FilterCriteria.CONTAINS_OR_EQUALS,
					description: 'Contiene...'
				}
			];
		}
		case 'NUMBER': {
			return [
				{
					key: 'submenu-item-1',
					criteria: FilterCriteria.GREATER_THAN,
					description: 'Mayor que...'
				},
				{
					key: 'submenu-item-2',
					criteria: FilterCriteria.GREATER_OR_EQUAL_THAN,
					description: 'Mayor o igual que...'
				},
				{
					key: 'submenu-item-3',
					criteria: FilterCriteria.LESS_THAN,
					description: 'Menor que...'
				},
				{
					key: 'submenu-item-4',
					criteria: FilterCriteria.LESS_OR_EQUAL_THAN,
					description: 'Menor o igual que...'
				},
				{
					key: 'submenu-item-5',
					criteria: FilterCriteria.BETWEEN,
					description: 'Entre...'
				}
			];
		}
		case 'DATE': {
			return [
				{
					key: 'submenu-item-1',
					criteria: FilterCriteria.GREATER_THAN,
					description: 'Mayor a...'
				},
				{
					key: 'submenu-item-2',
					criteria: FilterCriteria.LESS_THAN,
					description: 'Menor a...'
				},
				{
					key: 'submenu-item-3',
					criteria: FilterCriteria.BETWEEN_DATES,
					description: 'Entre fechas...'
				}
			];
		}
		default: {
			return [];
		}
	}
};

export const getAdvancedFilterDescription = (type) => {
	switch (type) {
		case 'TEXT': {
			return 'Filtros de texto';
		}
		case 'NUMBER': {
			return 'Filtros de número';
		}
		case 'DATE': {
			return 'Filtros de fecha';
		}
		default: {
			return 'Filtros avanzados';
		}
	}
};

export const FilterCriteria = {
	UNDEFINED: 0,
	EQUAL: 1,
	BETWEEN: 2,
	ANY: 3,
	NOT_EQUAL: 4,
	CONTAINS_OR_EQUALS: 5,
	BETWEEN_DATES: 6,
	GREATER_THAN: 7,
	LESS_THAN: 8,
	GREATER_OR_EQUAL_THAN: 9,
	LESS_OR_EQUAL_THAN: 10,
	NUMERIC_EQUAL: 11,
	OR_EQUAL: 12
};

export const FilterTypes = {
    BRANCH_OFFICE: 'BranchOffice.Id',
    CATEGORY: 'Category',
    CURRENCY: 'Currency',
    DISTRIBUTOR: 'Distributor.Id',
    MODEL: 'Model',
    PRODUCT_TYPE: 'Product',
    SERVICE_COST: 'ServiceCost',
    SERVICE: 'ServiceDescription',
    SERVICE_TYPE: 'ServiceTypeId',
    ANOTHER_COST: 'AnotherCost',
    MATERIALS_COST: 'MaterialsCost',
    MATERIALS_GCM_COST: 'MaterialsGCMCost',
    MATERIALS_GFB_COST: 'MaterialsGFBCost',
    TOTAL_MATERIALS: 'TotalMaterials',
    WARRANTY: 'Warranty'
} 

export const buildSearchOrdersCommand = (queryStr) => {};
