import { createSelector } from 'reselect'
import { toSafeJS } from '../helpers/ImmutableHelper'

const getSpecificationState = (state) => state.specifications

export const getGuidelinesSelector = createSelector([getSpecificationState], (indexState) => {
	const guidelines = indexState.get('guidelines')
	return guidelines ? toSafeJS(guidelines) : []
})

export const getLoadingSelector = createSelector([getSpecificationState], (indexState) => {
	const isLoading = indexState.get('isLoading')
	return isLoading
})

export const getGuideLinesPageSelector = createSelector([getSpecificationState], (indexState) => {
	const page = indexState.get('page')
	return page
})
export const getGuideLinesPageSizeSelector = createSelector([getSpecificationState], (indexState) => {
	const pageSize = indexState.get('pageSize')
	return pageSize
})
export const getGuideLinesTotalItemsSelector = createSelector([getSpecificationState], (indexState) => {
	const totalGuidelines = indexState.get('totalGuidelines')
	return totalGuidelines
})

export const getGuidelineDataSelector = createSelector([getSpecificationState], (indexState) => {
	const guidelineData = indexState.get('guidelineData')
	return guidelineData ? toSafeJS(guidelineData) : {}
})

export const getPackagesSelector = createSelector([getSpecificationState], (indexState) => {
	const packageList = indexState.get('packageList')
	return packageList ? toSafeJS(packageList) : []
})
export const getPackagesPageSelector = createSelector([getSpecificationState], (indexState) => {
	const page = indexState.get('packagePage')
	return page
})
export const getPackagesPageSizeSelector = createSelector([getSpecificationState], (indexState) => {
	const pageSize = indexState.get('packagePageSize')
	return pageSize
})
export const getPackagesTotalItemsSelector = createSelector([getSpecificationState], (indexState) => {
	const totalPackages = indexState.get('totalPackages')
	return totalPackages
})
export const getPackageDataSelector = createSelector([getSpecificationState], (indexState) => {
	const packageData = indexState.get('packageData')
	return packageData ? toSafeJS(packageData) : {}
})
