import React, { Component } from 'react';
import autoBind from 'auto-bind'
import uuidv4 from 'uuid/v4'
import PropTypes from 'prop-types'

/** Material-UI imports section */
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Chip from '@material-ui/core/Chip';
import { capitaliseFirstLetter } from '@fullcalendar/core';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import NoReleaseIcon from '@material-ui/icons/EventBusy';

/** Language imports **/
//import { Translate, getTranslate } from "react-localize-redux";
import {
    RELEASE_PLAN_COLOR_YELLOW,
    RELEASE_PLAN_COLOR_BLUE_GRAY,
    BACKGROUND_COLOR_GRAY_DARK
} from './../../../store/helpers/StatusColorConstants';
import { Translate } from 'react-localize-redux';

/**
 * ReleasePlan supplier 
 *
 * @class ReleasePlan list
 * @extends {Component}
 */
export default class ShowRelease extends Component {

    /**
     * Create an instance of ReleasePlan list
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    renderNoReleaseCard() {
        return (
            <Grid container spacing={24} justify="center">
                <Grid item xs={5}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar><NoReleaseIcon color="error" /></Avatar>
                            }
                            title={
                                <Typography gutterBottom variant="h5" component="h2" align="justify">
                                    <Translate id="releasePlan.notExistRelease" />
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p" align="justify">
                                <Translate id="releasePlan.notExistReleaseMessage" />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }

    renderDataWithTooltip(value, tooltipId, color, dataTooltip = '') {
        return (
            <Tooltip title={<React.Fragment><Translate id={tooltipId} />{dataTooltip}</React.Fragment>}>
                <Typography style={{ color }}>
                    {value}
                </Typography>
            </Tooltip>
        )
    }

    getQuantityToShow(quantity, quantityWithFolioOrderActive) {
        if (quantity.AdjustedQuantity === "" && quantityWithFolioOrderActive.length !== 0) {
            return (this.renderDataWithTooltip(quantity.Quantity, "releasePlan.error.quantityInTransit", RELEASE_PLAN_COLOR_YELLOW, quantityWithFolioOrderActive));
        }
        else if (quantity.Quantity !== quantity.AdjustedQuantity) {
            if (quantityWithFolioOrderActive.length !== 0) {
                return (this.renderDataWithTooltip(quantity.AdjustedQuantity, "releasePlan.error.foliosWithOrder", RELEASE_PLAN_COLOR_YELLOW, quantityWithFolioOrderActive));
            }
            else {
                if (quantity.IsAddedByUser) {
                    return (this.renderDataWithTooltip(quantity.AdjustedQuantity, "releasePlan.error.quantityAddedByPlanner", RELEASE_PLAN_COLOR_BLUE_GRAY));
                }
                else {
                    return (quantity.AdjustedQuantity);
                }
            }
        }
        else {
            if (quantity.IsAddedByUser) {
                return (this.renderDataWithTooltip(quantity.AdjustedQuantity, "releasePlan.error.quantityAddedByPlanner", RELEASE_PLAN_COLOR_BLUE_GRAY));
            }
            else {
                return (quantity.AdjustedQuantity);
            }
        }
    }

    renderQuantityByDay(item, index, columnMinWidth) {
        let quantity = item.quantities[index];
        return (<TableCell
            key={uuidv4()}
            align={'center'}
            style={{
                wordBreak: 'keep-all',
                minWidth: columnMinWidth,
                paddingLeft: 2,
                paddingRight: 2,
                borderRight: `${BACKGROUND_COLOR_GRAY_DARK} solid 1px`
            }}
        >
            {this.getQuantityToShow(quantity, item.quality_with_folio_the_order_active)}
        </TableCell>)
    }

    getColor(month) {
        switch (month) {
            case 1:
                return "#f09cae";
            case 2:
                return "#9cf0de";
            case 3:
                return "#9caef0";
            case 4:
                return "#f0de9c";
            case 5:
                return "#aef09c";
            case 6:
                return "#f0ad9c";
            case 7:
                return "#7aa7f5";
            case 8:
                return "#cc9cf0";
            case 9:
                return "#f5e877";
            case 10:
                return "#8df5a9";
            case 11:
                return "#f08ab8";
            case 12:
                return "#baf567";
            default:
                return "#fff";
        }
    }

    renderTableHeadMonthsCaption() {
        return (
            <Grid container spacixng={0} justify="flex-end" style={{ padding: 6 }}>
                {this.props.releasePlan.months.map(month => {
                    return (
                        <Chip label={capitaliseFirstLetter(month.name)}
                            style={{ backgroundColor: this.getColor(month.month) }} />
                    )
                })}
            </Grid>
        )
    }

    renderTableHead(configuration) {
        return (
            <TableHead key={uuidv4()}>
                <TableRow key={uuidv4()}>
                    {configuration.columns.map(column => {
                        let maxPaddingCell = 2;
                        return (
                            <TableCell
                                key={uuidv4()}
                                align={column.align ? column.align : 'center'}
                                style={{
                                    paddingLeft: maxPaddingCell,
                                    paddingRight: maxPaddingCell,
                                    minWidth: column.minWidth,
                                    backgroundColor: (this.getColor(column.month)),
                                    position: "sticky",
                                    top: 0,
                                    zIndex: '30'
                                }}>
                                {column.header}
                            </TableCell>
                        )
                    })
                    }
                </TableRow>
            </TableHead>
        )
    }

    render() {
        let releasePlan = this.props.releasePlan;

        if (releasePlan === null) {
            return this.renderNoReleaseCard();
        }
        else {
            let data = releasePlan.materials;
            let configuration = TableReleaseTableConfiguration(releasePlan.days);
            return (
                <Paper style={{
                    maxWidth: '89vw',
                    minHeight: 100,
                    maxHeight: '62vh',
                }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {this.renderTableHeadMonthsCaption()}
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{
                                minHeight: 100,
                                maxHeight: '59vh',
                                overflow: 'auto'
                            }}>
                                <Table>
                                    {this.renderTableHead(configuration)}
                                    <TableBody key={uuidv4()}>
                                        {data.map(item => {
                                            return (
                                                <TableRow hover key={uuidv4()}>
                                                    {configuration.columns.map(column => {
                                                        let cellContent;
                                                        let maxPaddingCell = 2;
                                                        cellContent = item[column.dataSource];

                                                        let rendersCell = "";
                                                        if (column.dataSource === 'quantities') {
                                                            rendersCell = this.renderQuantityByDay(item, column.columnDayIndex, column.minWidth);
                                                        }
                                                        else if (column.dataSource === "total_quantity_material") {
                                                            let tooltipData = Number(cellContent) === Number(item.total_quantity_material_adjusted) ? cellContent : item.total_quantity_material_adjusted;
                                                            rendersCell = <TableCell
                                                                key={uuidv4()}
                                                                align={column.align ? column.align : 'center'}
                                                                style={{
                                                                    wordBreak: 'keep-all',
                                                                    paddingLeft: maxPaddingCell,
                                                                    paddingRight: maxPaddingCell,
                                                                    minWidth: column.minWidth,
                                                                    borderRight: `${BACKGROUND_COLOR_GRAY_DARK} solid 1px`
                                                                }}
                                                            >
                                                                {this.renderDataWithTooltip(item.purchase_order_total, "releasePlan.error.totalPurchaseOrder", "", tooltipData)}
                                                            </TableCell>
                                                        }
                                                        else {
                                                            rendersCell = <TableCell
                                                                key={uuidv4()}
                                                                align={column.align ? column.align : 'center'}
                                                                style={{
                                                                    wordBreak: 'break-word',
                                                                    paddingLeft: maxPaddingCell,
                                                                    paddingRight: maxPaddingCell,
                                                                    minWidth: column.minWidth,
                                                                }}
                                                            >
                                                                {cellContent}
                                                            </TableCell>
                                                        }
                                                        return (rendersCell)
                                                    })}
                                                </TableRow>
                                            )
                                        })
                                        }
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>

            )
        }
    }

}

function TableReleaseTableConfiguration(days) {
    let columns = [
        {
            header: 'Código',
            dataSource: 'code',
            minWidth: 60,
        }, {
            header: 'Descripción',
            dataSource: 'description',
            minWidth: 220,
            align: "left"
        }, {
            header: "UM",
            dataSource: 'unit',
            minWidth: 40,
        }, {
            header: "Orden de compra",
            dataSource: 'purchase_order',
            minWidth: 80,
        }, {
            header: "Posición",
            dataSource: 'position',
            minWidth: 40,
        }, {
            header: "Total",
            dataSource: 'total_quantity_material',
            minWidth: 40,
        }];

    if (days) {
        days.forEach((day, index) => {
            columns.push({
                header: day.day,
                columnDayIndex: index,
                dataSource: 'quantities',
                month: day.month,
                minWidth: 40
            });
        });
    }


    return ({ columns });
}

/**
 * ShowRelease
 */
ShowRelease.propTypes = {
    releasePlan: PropTypes.object.isRequired
}