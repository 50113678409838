import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { getTranslate, Translate } from 'react-localize-redux'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	AppBar,
	Tabs,
	Tab,
	Divider,
	Paper,
	Typography,
	TextField,
} from '@material-ui/core'
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow'
import FloatingButton from '../common/FloatingButton'
import DialogAvailableOperations from '../common/DialogAvailableOperations'
import { showSuccessToaster, showErrorToaster, showWarningToaster } from '../../store/helpers/ToasterHelpers'
import AddCommonItem from '../common/AddCommonItem'
import WorkflowCancel from '../common/WorkflowCancel'

import Select from 'react-select'

import ReprobateIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import ApprovedIcon from '@material-ui/icons/SentimentVerySatisfied'
import NeutralIcon from '@material-ui/icons/SentimentSatisfied'

/** Import actions and helpers section */
import {
	SUSTAINABILITY_QUESTIONAIRE_NOTIFICAITON,
	SUSTAINABILITY_QUESTIONAIRE_RESPONSE,
	WORKFLOW_CANCELLATION,
	SUSTAINABILITY_QUESTIONNAIRE_RESEND_TO_SUPPLIER,
	SUSTAINABILITY_QUESTIONAIRE_CLOSE,
} from '../../store/helpers/WorkFlowDocumentsType'
import * as roles from '../../store/helpers/RolesHelper'
import { loadCompany } from '../../store/companies/CompanyActions'
import { loadSupplier } from '../../store/suppliers/SupplierActions'
import {
	loadQuestionnaireFromBackEnd,
	sendQuestionnaireResponseBySupplier,
	resendQuestionnaireToSupplier,
	updateProperty,
	updatePropertyInGeneralDataList,
	updatePropertyInQuestionnaireList,
	closeQuestionnaireInPlant,
} from '../../store/sustainability/SustainabilityActions'
import Toaster from '../common/Toaster'
import QuestionsList from './components/QuestionsList'
import {
	BACKGROUND_COLOR_GRAY,
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
} from '../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import { GetOpertationType, getSelectedValue } from '../../store/helpers/SelectOptions'
import HistoryCard from '../common/HistoryCard'
import DataTable from '../common/DataTable'
import { converListToArray } from '../../store/helpers/ListHelper'
import { onKeyDown, handleFocus } from '../../store/helpers/EventsHelper'
import { IsUserSupplierAdministrator } from '../../store/helpers/SessionHelper'

class Show extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAvailableOperationsDialog: false,
			activeTab: 0,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			openResendToSuppliert: false,
		}
	}

	changeSelectTab(index) {
		this.setState({ activeTab: Number(index) })
	}

	componentDidMount() {
		let questionnaireToken = this.props.match.params.token
		this.props.loadQuestionnaire(questionnaireToken)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.isLoadingQuestionnaire !== this.props.isLoadingQuestionnaire &&
			this.props.questionnaire !== null
		) {
			let questionnaire = this.props.questionnaire
			this.props.loadCompany(questionnaire.company_token)
			this.props.loadSupplier(questionnaire.supplier_token)
		}
	}

	setOpenDialog = (openAvailable) => {
		this.setState({
			openAvailableOperationsDialog: openAvailable,
		})
	}

	validateAvailableOptions(availableOptions) {
		return availableOptions
	}

	updatePropertyBackend(property, data, item = null, listName = null) {
		if (item !== null && listName === 'workers') {
			//Update property in list
			this.props.updatePropertyInGeneralDataList(item.token, property, data)
		} else if (item !== null && listName !== null && listName !== 'workers') {
			//Update property in list
			this.props.updatePropertyInQuestionnaireList(item.token, property, data, listName)
		} else {
			//Update single property
			this.props.updateProperty(this.props.questionnaire.token, property, data)
		}
	}

	onSendQuestionnaireResponseBySupplier() {
		let questionnaireToken = this.props.match.params.token
		let isFullSupplier = this.props.questionnaire && this.isFullyAnswered('supplier_response')
		if (isFullSupplier) {
			this.props
				.sendQuestionnaireResponseBySupplier(questionnaireToken)
				.then(() => {
					this.setState({
						openAvailableOperationsDialog: false,
					})
					this.setState(showSuccessToaster('common.saveCorrectly'))
				})
				.catch((error) => {
					this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
				})
		} else {
			this.setState(showWarningToaster('sustainability.error.notFullyAnswered'))
		}
	}

	/**
	 * Function to cancel
	 */
	onCancelDocument() {
		let questionnaireToken = this.props.match.params.token
		this.props.history.push(`/sustainability/cancel/${questionnaireToken}`)
	}

	/**
	 * Function to resend to supplier
	 */
	onResendBySupplierOpen() {
		this.setState({
			openAvailableOperationsDialog: false,
			openResendToSuppliert: true,
		})
	}

	resendQuestionnaireToSupplier(commentsResend) {
		let questionnaireToken = this.props.match.params.token
		this.props
			.resendQuestionnaireToSupplier(questionnaireToken, commentsResend)
			.then(() => {
				this.setState({
					openResendToSuppliert: false,
				})
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	isFullyAnswered(responseProperty) {
		let isFull = true
		let { questionnaire } = this.props
		QuestionnaireSections().forEach((questionnaireSection, index) => {
			questionnaireSection.lists.forEach((listInSection) => {
				let foundIndex = questionnaire[listInSection.name].findIndex((questionInList) => {
					return questionInList[responseProperty] === null || questionInList[responseProperty] === '0'
				})
				if (foundIndex >= 0) isFull = false
			})
		})
		if (responseProperty === 'supplier_response') {
			if (questionnaire.operation_type === null || questionnaire.operation_type === 0) isFull = false
			if (questionnaire.operation_type === 6 && IsNullOrEmpty(questionnaire.other_operation_type)) isFull = false
		}
		return isFull
	}

	closeQuestionnaireEvaluatedFromPlant() {
		let questionnaireToken = this.props.match.params.token
		let isFullSupplier = this.props.questionnaire && this.isFullyAnswered('plant_response')
		if (isFullSupplier) {
			this.props
				.closeQuestionnaireInPlant(questionnaireToken)
				.then(() => {
					this.setState({
						openAvailableOperationsDialog: false,
					})
					this.setState(showSuccessToaster('common.saveCorrectly'))
				})
				.catch((error) => {
					this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
				})
		} else {
			this.setState(showWarningToaster('sustainability.error.invalidUnansweredQuestionsInPlant'))
		}
	}

	findIndexItem(listName, itemToken) {
		return this.props.questionnaire[listName].findIndex((item) => item.token === itemToken)
	}
	renderTextField(
		key,
		translationId,
		isMultiple = false,
		item = null,
		type = 'text',
		isDisabled = false,
		listName = null
	) {
		let defaultValue = IsNullOrEmpty(listName)
			? this.props.questionnaire[key]
				? this.props.questionnaire[key]
				: ''
			: this.props.questionnaire[listName]
			? this.props.questionnaire[listName][this.findIndexItem(listName, item.token)][key]
			: ''
		return (
			<TextField
				autoFocus={key === 'operation_type'}
				key={key}
				fullWidth
				type={type}
				label={!IsNullOrEmpty(translationId) && <Translate id={translationId} />}
				defaultValue={defaultValue}
				onBlur={(event) => {
					this.updatePropertyBackend(key, event.target.value, item, listName)
				}}
				onKeyDown={(event) => onKeyDown(event)}
				margin='dense'
				variant={isMultiple ? 'outlined' : 'standard'}
				multiline={isMultiple}
				autoComplete='off'
				rowsMax={4}
				rows={isMultiple ? 3 : 1}
				disabled={isDisabled}
				style={{ maxWidth: type === 'number' ? 150 : 'none' }}
				inputProps={{
					style: { textAlign: type === 'number' ? 'right' : 'left' },
				}}
				onFocus={(event) => handleFocus(event)}
			/>
		)
	}

	renderSelect(key, translationId, options, item = null, isDisabled) {
		let selectedValue = getSelectedValue(options, this.props.questionnaire[key])
		return (
			<React.Fragment>
				<Typography variant='caption' color={'textSecondary'}>
					<Translate id={translationId} />*
				</Typography>
				<Select
					value={selectedValue}
					options={options}
					onChange={(event) => this.updatePropertyBackend(key, event.value, item)}
					styles={{
						menu: (base) => ({ ...base, zIndex: 62 }),
						menuPortal: (base) => ({ ...base, zIndex: 9999 }),
					}}
					menuPortalTarget={document.parentNode}
					menuPlacement={'bottom'}
					isDisabled={isDisabled}
				/>
			</React.Fragment>
		)
	}

	onRenderCellItem(dataSource, item) {
		let canEditSupplier =
			(roles.canBeAdminsitratorSupplier(this.props.userRolesByCompany) ||
				IsUserSupplierAdministrator(this.props.currentUser)) &&
			this.props.questionnaire.can_edit_supplier
		if (dataSource !== 'description' && dataSource !== 'total_workers') {
			return (
				<Typography align='center'>
					{canEditSupplier
						? this.renderTextField(dataSource, '', false, item, 'number', !canEditSupplier, 'workers')
						: item[dataSource]}
				</Typography>
			)
		}
	}

	renderTotalworkersCard(totalTypeTranslationId, totalWomen, totalMen) {
		return (
			<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
				<Card style={{ backgroundColor: BACKGROUND_COLOR_GRAY }}>
					<CardContent style={{ paddingLeft: 0 }}>
						<Typography variant='body1' align='right'>
							{this.props.translate('common.total')} {this.props.translate(totalTypeTranslationId)}
						</Typography>
						<Typography variant='body2' align='right'>
							{this.props.translate('sustainability.workers.women')}: {totalWomen}
						</Typography>
						<Typography variant='body2' align='right'>
							{this.props.translate('sustainability.workers.men')}: {totalMen}
						</Typography>
					</CardContent>
				</Card>
			</Grid>
		)
	}

	renderGeneralInformation() {
		var { questionnaire } = this.props
		let operationTypes = GetOpertationType()
		let canEditSupplier =
			(roles.canBeAdminsitratorSupplier(this.props.userRolesByCompany) ||
				IsUserSupplierAdministrator(this.props.user)) &&
			questionnaire &&
			questionnaire.can_edit_supplier
		let selectedOperationType = questionnaire && questionnaire.operation_type
		let selectedOperationTypeDescription = getSelectedValue(operationTypes, selectedOperationType)
		return (
			<Grid container spacing={16} justify='space-between' style={{ maxWidth: '100%', marginTop: '.5rem' }}>
				{canEditSupplier && (
					<React.Fragment>
						<Grid
							item
							xs={12}
							sm={12}
							md={selectedOperationType === 6 ? 6 : 12}
							lg={selectedOperationType === 6 ? 6 : 12}
							xl={selectedOperationType === 6 ? 6 : 12}
						>
							{this.renderSelect(
								'operation_type',
								'sustainability.operationType',
								operationTypes,
								null,
								!canEditSupplier
							)}
						</Grid>
						{canEditSupplier && selectedOperationType === 6 && (
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								{this.renderTextField(
									'other_operation_type',
									'sustainability.otherOperationType',
									false,
									null,
									'text',
									!canEditSupplier
								)}
							</Grid>
						)}
					</React.Fragment>
				)}
				{!canEditSupplier && (
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography variant='body2'>
							<strong>
								<Translate id='sustainability.operationType' />:{' '}
							</strong>
							{selectedOperationType > 0 ? (
								<React.Fragment>
									{selectedOperationTypeDescription.label}
									{selectedOperationType === 6 && ` ( ${questionnaire.other_operation_type} )`}
								</React.Fragment>
							) : (
								<Translate id='common.withoutData' />
							)}
						</Typography>
					</Grid>
				)}

				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<DataTable
						data={questionnaire.workers}
						configuration={TableConfiguration()}
						onRenderCellItem={this.onRenderCellItem}
					/>
				</Grid>

				<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
					{this.renderTextField('comments', 'common.comments', true, null, 'text', true)}
				</Grid>
				{this.renderTotalworkersCard(
					'sustainability.workers.administrative',
					questionnaire.total_administrative_woman,
					questionnaire.total_administrative_men
				)}
				{this.renderTotalworkersCard(
					'sustainability.workers.operational',
					questionnaire.total_operational_woman,
					questionnaire.total_operational_men
				)}
			</Grid>
		)
	}
	renderQuestionsList() {
		let canEditSupplier =
			(roles.canBeAdminsitratorSupplier(this.props.userRolesByCompany) ||
				IsUserSupplierAdministrator(this.props.user)) &&
			this.props.questionnaire.can_edit_supplier
		let canEditCompany =
			roles.canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany) &&
			this.props.questionnaire.can_edit_company

		return QuestionnaireSections()[this.state.activeTab - 1].lists.map((questionsList) => {
			return (
				<QuestionsList
					listTitle={questionsList.title}
					listPropertyName={questionsList.name}
					questions={this.props.questionnaire[questionsList.name]}
					updateProperty={this.updatePropertyBackend}
					canEditSupplier={canEditSupplier}
					canEditCompany={canEditCompany}
					isDisabledQuestionMandatory={roles.canBeModuleSustantabilityQuestionnaire(
						this.props.userRolesByCompany
					)}
					translate={this.props.translate}
				/>
			)
		})
	}

	renderAditionalDataNotification(usersList, comments) {
		return (
			<Grid container spacing={24}>
				<CardHeader
					style={{ paddingTop: '0px' }}
					title={
						<React.Fragment>
							<Typography gutterBottom component='p'>
								{comments}
							</Typography>
						</React.Fragment>
					}
					subheader={
						<Grid item xs={12}>
							<Typography gutterBottom variant='body2' component='p'>
								<strong>
									<Translate id='common.sentTo' />:
								</strong>
							</Typography>
							<DataTable
								data={usersList}
								configuration={{
									columns: [
										{
											header: <Translate id='officialNotices.name' />,
											dataSource: 'full_name',
										},
									],
								}}
							/>
						</Grid>
					}
				/>
			</Grid>
		)
	}

	renderHistory() {
		let documents = this.props.questionnaire
			? this.props.questionnaire.documents
				? this.props.questionnaire.documents
				: []
			: []
		return documents.map((document) => {
			let titleId
			let aditionalData
			if (document.document_type === SUSTAINABILITY_QUESTIONAIRE_NOTIFICAITON) {
				titleId = 'sustainability.notificationBySupplierReport'
				aditionalData = this.renderAditionalDataNotification(document.users_supplier, '')
			}
			if (document.document_type === SUSTAINABILITY_QUESTIONAIRE_RESPONSE) {
				titleId = 'sustainability.responseBySupplierReport'
			}
			if (document.document_type === SUSTAINABILITY_QUESTIONNAIRE_RESEND_TO_SUPPLIER) {
				titleId = 'sustainability.notificationBySupplierResendReport'
				aditionalData = this.renderAditionalDataNotification(document.users_supplier, document.comments)
			}
			if (document.document_type === SUSTAINABILITY_QUESTIONAIRE_CLOSE) {
				titleId = 'sustainability.closeReport'
			}
			if (document.document_type === WORKFLOW_CANCELLATION) {
				titleId = 'common.folioCancel'
				aditionalData = <WorkflowCancel document={document} />
			}
			return (
				<HistoryCard
					key={document.token}
					title={titleId}
					userCreator={document.create_by}
					creationDate={document.creation_date_long}
					aditionalData={aditionalData}
				/>
			)
		})
	}

	getStatusData(status) {
		switch (status) {
			case 'isApproved':
				return {
					translationId: 'sustainability.isApproved',
					color: COLOR_STATUS_SUCCESS,
					icon: <ApprovedIcon style={{ color: COLOR_STATUS_SUCCESS }} />,
				}
			case 'notApproved':
				return {
					translationId: 'sustainability.notApproved',
					color: COLOR_STATUS_DANGER,
					icon: <ReprobateIcon style={{ color: COLOR_STATUS_DANGER }} />,
				}
			default:
				return {
					translationId: 'common.withoutData',
					color: BACKGROUND_COLOR_GRAY,
					icon: <NeutralIcon style={{ color: BACKGROUND_COLOR_GRAY }} />,
				}
		}
	}

	renderRating(questionnaire, translationId, propertyRating, propertyTotalRating) {
		let statusData = this.getStatusData(questionnaire[propertyRating])
		return (
			<Typography component='span' variant='body2' className={this.props.classes.inline} color='textPrimary'>
				<Translate id={translationId} />
				{': '}
				{statusData.icon}
				<strong style={{ color: statusData.color }}>
					{` ${questionnaire[propertyTotalRating]} % `}
					<Translate id={statusData.translationId} />
				</strong>
			</Typography>
		)
	}

	renderContent() {
		var { questionnaire } = this.props
		return (
			<CardContent className={this.props.classes.withoutPaddingTopBottom} style={{ height: '75vh' }}>
				{this.props.isLoadingQuestionnaire ? (
					<LoadingPage />
				) : (
					<Grid container spacing={0}>
						{roles.canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany) && (
							<Grid item xs={12}>
								{this.renderRating(
									questionnaire,
									'sustainability.ratingSupplier',
									'is_approved_supplier',
									'total_rating_supplier'
								)}
								{this.renderRating(
									questionnaire,
									'sustainability.ratingPlant',
									'is_approved_in_plant',
									'total_raing_plant'
								)}
							</Grid>
						)}
						<Grid item xs={12}>
							<AppBar position='static' color='default' style={{ marginTop: '1rem' }}>
								<Tabs
									indicatorColor={'primary'}
									textColor='textPrimary'
									value={this.state.activeTab}
									onChange={this.onClickToActiveTab}
									variant='fullWidth'
									scrollButtons='auto'
									className={this.props.classes.tabs}
								>
									<Tab
										id={0}
										title={this.props.translate('suppliers.generalInformation')}
										label={<Translate id='suppliers.generalInformation' />}
										onClick={(event) => this.changeSelectTab(event.currentTarget.id)}
										className={this.props.classes.tabs}
										classes={{ selected: this.props.classes.selected }}
									/>
									{QuestionnaireSections().map((questionnaireSection, index) => {
										return (
											<Tab
												id={index + 1}
												title={questionnaireSection.sectionName}
												label={<p>{questionnaireSection.sectionName}</p>}
												onClick={(event) => this.changeSelectTab(event.currentTarget.id)}
												className={this.props.classes.tabs}
												classes={{ selected: this.props.classes.selected }}
											/>
										)
									})}
								</Tabs>
							</AppBar>
						</Grid>
						<Grid item xs={12}>
							<Divider />
							<Paper
								style={{ height: '65vh', overflowY: 'auto' }}
								className={this.props.classes.withoutPaddingTopBottom}
							>
								{this.state.activeTab === 0 && this.renderGeneralInformation()}
								{this.state.activeTab > 0 && this.renderQuestionsList()}
							</Paper>
						</Grid>
					</Grid>
				)}
			</CardContent>
		)
	}

	render() {
		var { questionnaire, supplier, company } = this.props
		let availableOptions = this.props.questionnaire ? this.props.questionnaire.get_available_operations : []
		availableOptions = this.validateAvailableOptions(availableOptions)
		let showHistory =
			this.props.questionnaire && this.props.questionnaire.actual_operation !== 1 && this.state.activeTab === 0
		return (
			<Card>
				{availableOptions && availableOptions.length !== 0 && <FloatingButton open={this.setOpenDialog} />}
				<LayoutHeaderWorkFlow
					title={<Translate id='sustainability.sustainabilityQuestionnaire' />}
					workFlow={questionnaire}
					supplier={supplier}
					company={company}
				/>

				{this.renderContent()}
				{showHistory && this.renderHistory()}

				<DialogAvailableOperations
					{...this.props}
					options={availableOptions}
					isValidToCancel={roles.canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany)}
					openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
					setOpenDialog={this.setOpenDialog}
					onCancelDocument={this.onCancelDocument}
					onClickToQuestionnaireResponseBySupplier={this.onSendQuestionnaireResponseBySupplier}
					onClickToQuestionnaireResendToSupplier={this.onResendBySupplierOpen}
					onClickToCloseQuestionnaireEvaluated={this.closeQuestionnaireEvaluatedFromPlant}
				/>

				{/** Dialog to resend sustainability Questionnaire from supplier */}
				<AddCommonItem
					title={<Translate id='sustainability.resendQuestionnaire' />}
					message={<Translate id='sustainability.messageToResendQuestionnaire' />}
					open={this.state.openResendToSuppliert}
					onClose={() => {
						this.setState({ openResendToSuppliert: false })
					}}
					onSaveItem={this.resendQuestionnaireToSupplier}
					isSaving={this.props.isSaving}
					isTextArea={true}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function QuestionnaireSections() {
	let sections = [
		{
			sectionName: 'I. NUESTRO PLANETA',
			//color: '#4cad51',
			lists: [
				{
					title: 'SISTEMA DE GESTIÓN',
					name: 'system_managements',
				},
				{
					title: 'GESTION DEL AGUA',
					name: 'water_managements',
				},
				{
					title: 'GESTION DE LA ENERGIA',
					name: 'energy_managements',
				},
				{
					title: 'GESTION DE EMISIONES',
					name: 'emission_managements',
				},
				{
					title: 'GESTION DE RESIDUOS',
					name: 'waste_managements',
				},
			],
		},
		{
			sectionName: 'II. NUESTRA GENTE',
			//color: '#e28f4f',
			lists: [
				{
					title: 'TRATO JUSTO (Solicitar entrevista con personal)',
					name: 'fair_treatments',
				},
				{
					title: 'TRABAJO INFANTIL',
					name: 'child_labor',
				},
				{
					title: 'INCLUSIÓN',
					name: 'incorporations',
				},
				{
					title: 'PROHIBICIÓN DE ACOSO',
					name: 'harassment_prohibitions',
				},
				{
					title: 'SALUD Y SEGURIDAD: (Aspectos de Verificación)',
					name: 'health_security',
				},
			],
		},
		{
			sectionName: 'III. CULTURA DE LA LEGALIDAD ',
			//color: '#e25a5a',
			lists: [
				{
					title: 'PERMISOS DE OPERACION',
					name: 'operating_permits',
				},
			],
		},
		{
			sectionName: 'IV. NUESTRA COMUNIDAD ',
			//color: '#cd74db',
			lists: [
				{
					title: 'ESTILOS DE VIDA SALUDABLES',
					name: 'healthy_lifestyles',
				},
				{
					title: 'DESARROLLO COMUNITARIO',
					name: 'community_developments',
				},
				{
					title: 'ABASTECIMIENTO SOSTENIBILE',
					name: 'sustainables_supply',
				},
				{
					title: 'RECORRIDO PERIFÉRICO',
					name: 'peripheral_travels',
				},
			],
		},
	]
	return sections
}

function TableConfiguration() {
	let columns = [
		{
			header: '',
			dataSource: 'description',
		},
		{
			header: <Translate id='sustainability.workers.administrativeWomen' />,
			dataSource: 'administrative_woman',
		},
		{
			header: <Translate id='sustainability.workers.administrativeMen' />,
			dataSource: 'administrarive_men',
		},
		{
			header: <Translate id='sustainability.workers.operationalWomen' />,
			dataSource: 'operational_woman',
		},
		{
			header: <Translate id='sustainability.workers.operationalMen' />,
			dataSource: 'operational_men',
		},
		{
			header: <Translate id='common.total' />,
			dataSource: 'total_workers',
		},
	]

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	return {
		currentUser: user,
		isLoadingQuestionnaire: state.sustainability.get('isLoadingQuestionnaire'),
		questionnaire: state.sustainability.get('questionnaire')
			? state.sustainability.get('questionnaire').toJS()
			: null,
		company: state.companies.get('company') ? state.companies.get('company').toJS() : null,
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isSaving: state.sustainability.get('isSavingQuestionnaire'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadCompany: (token) => {
			return dispatch(loadCompany(token))
		},
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		loadQuestionnaire: (token) => {
			return dispatch(loadQuestionnaireFromBackEnd(token))
		},
		sendQuestionnaireResponseBySupplier: (token) => {
			return dispatch(sendQuestionnaireResponseBySupplier(token))
		},
		updateProperty(questionnaireToken, property, data) {
			return dispatch(updateProperty(questionnaireToken, property, data))
		},
		updatePropertyInGeneralDataList(itemToken, property, data) {
			return dispatch(updatePropertyInGeneralDataList(itemToken, property, data))
		},
		updatePropertyInQuestionnaireList(itemToken, property, data, listName) {
			return dispatch(updatePropertyInQuestionnaireList(itemToken, property, data, listName))
		},
		resendQuestionnaireToSupplier: (token, comments) => {
			return dispatch(resendQuestionnaireToSupplier(token, comments))
		},
		closeQuestionnaireInPlant: (token) => {
			return dispatch(closeQuestionnaireInPlant(token))
		},
	}
}

const styles = (theme) => ({
	indicator: {
		backgroundColor: 'cyan',
		height: '10px',
		top: '45px',
	},
	flexContainer: {
		height: '60px',
		background: 'lightblue',
	},
	selected: {
		background: 'lightblue',
		color: 'white',
	},
	withoutPaddingTopBottom: {
		paddingTop: 0,
		paddingBottom: 0,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
