import React, { useState } from 'react'
import IncidentsReportDialogTemplate from './incidentsReportDialogTemplate'
import { useDispatch, useSelector } from 'react-redux'
import { downloadIncidents } from '../../../../store/transportTender/transportTenderAction'
import { getIsLoadingSavingSelector } from '../../../../store/transportTender/transportTenderSelector'

const IncidentsReportDialog = (props) => {
	const { onClose } = props
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const dispatch = useDispatch()
	const isLoading = useSelector((state) => getIsLoadingSavingSelector(state))
	const onDonwloadIncident = () => {
		startDate.startOf('day')
		endDate.endOf('day').hour(23).minute(59).second(59)
		console.log({ startDate, endDate })
		dispatch(downloadIncidents(startDate, endDate)).then(() => {
			onClose()
		})
	}
	return (
		<IncidentsReportDialogTemplate
			{...props}
			onClose={onClose}
			startDate={startDate}
			onChangeStartDate={setStartDate}
			endDate={endDate}
			onChangeEndDate={setEndDate}
			onDownloadReport={onDonwloadIncident}
			isLoading={isLoading}
		/>
	)
}
export default IncidentsReportDialog
