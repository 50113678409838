/** Import ReactJS */
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
/** Material UI import section */
import { Card, Grid, Divider, Typography, Tooltip } from '@material-ui/core'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import AssignmentIcon from '@material-ui/icons/Assignment'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
//Import components section
import AcceptRejectDialog from '../../../common/AcceptRejectDialog'
/**Import resources section */
import {
	BACKGROUND_COLOR_GRAY,
	COLOR_STATUS_ALERT_INFO,
	COLOR_STATUS_DANGER,
	COLOR_SECONDARY_BLUE_LIGHT,
} from '../../../../store/helpers/StatusColorConstants'
import { FactoringStatus } from '../../../../store/helpers/AppConstants'
import ConfirmPasswordDialog from '../../../common/ConfirmPasswordDialog'
const SignWithBank = (props) => {
	const { factoring, classes, activeStep, isSaving } = props
	const { onUpdateFactoring } = props
	//bool props section
	const { isInternalUser } = props

	const [configurationModal, setConfigurationModal] = useState({
		isOpen: false,
		isRejected: false,
	})
	const [confirmPasswordConfigModal, setConfirmPasswordConfigModal] = useState({
		opened: false,
		item: null,
	})

	const onConfirmPassword = (item) => {
		setConfirmPasswordConfigModal({
			opened: !confirmPasswordConfigModal.opened,
			item: item,
		})
	}

	const onAcceptOrRejetedFactoring = (comments, isAcepted) => {
		onUpdateFactoring(
			activeStep,
			factoring.Currencies,
			factoring ? factoring.paymentConditions : '-',
			factoring ? factoring.AnnualPurchase : '_',
			isAcepted,
			comments
		).then((_) => {
			setConfigurationModal({
				...configurationModal,
				isOpen: false,
				isRejected: false,
			})
		})
	}
	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Card className={classes.card}>
						<CardHeader
							className={classes.cardHeader}
							avatar={
								<Avatar aria-label='Recipe' className={classes.avatar}>
									<AssignmentIcon />
								</Avatar>
							}
							title={'Información referente a las firmas con el banco'}
						/>
						<Divider />
						<CardContent>
							<Grid container direction='row' justify='center' alignItems='flex-start' spacing={8}>
								<Grid item xs={12}>
									<Typography>
										Por favor comprueba la información capturada y una vez que se termine la firma
										con el banco, marca el convenio como finalizado.
									</Typography>
									<Divider />
									<List component='nav' className={classes.root}>
										<ListItem
											className={factoring.AceptedBySupplier ? classes.acceptedSupplier : null}
										>
											<ListItemIcon>
												<ChromeReaderModeIcon />
											</ListItemIcon>
											<ListItemText
												inset
												primary={
													<Typography className={classes.textTitle}>
														Da clic en está opción una vez que se haya concluido las firmas
														con el banco para terminar el proceso de factoraje
													</Typography>
												}
											/>
											{!isInternalUser &&
												factoring &&
												factoring.FactoringStatus === FactoringStatus.SIGNATURES_WITH_BANK && (
													<ListItemSecondaryAction>
														<Tooltip title={'Finalizar el proceso'}>
															<IconButton
																aria-label='Finalizar el proceso'
																onClick={onConfirmPassword}
																disabled={isSaving}
															>
																<ThumbUpIcon />
															</IconButton>
														</Tooltip>
													</ListItemSecondaryAction>
												)}
										</ListItem>
									</List>
									<Divider />
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>

			{confirmPasswordConfigModal && confirmPasswordConfigModal.opened && (
				<ConfirmPasswordDialog
					open={confirmPasswordConfigModal.opened}
					title={<Translate id='common.confirmOperation' />}
					message1={
						'Se terminara el proceso de afiliación del factoraje, por favor confirma cuando ya tengas las firmas con el banco'
					}
					message2={<Translate id='common.enterPassword' />}
					onClickButtonConfirm={(comment) =>
						onUpdateFactoring(
							activeStep + 1,
							factoring.Currencies,
							factoring ? factoring.PaymentConditions : '-',
							factoring ? factoring.AnnualPurchase : '_',
							false,
							factoring ? factoring.Comments : '_'
						).then((_) => {
							onConfirmPassword(null)
						})
					}
					onClose={onConfirmPassword}
					isSaving={isSaving}
					isVisibleComments={false}
					commentRequired={false}
				/>
			)}

			{configurationModal.isOpen && (
				<AcceptRejectDialog
					open={configurationModal.isOpen}
					additionalTitle={': la afiliación de factoraje'}
					onClickAccept={(comment) => onAcceptOrRejetedFactoring(comment, true)}
					isReject={configurationModal.isRejected}
					onClickReject={(comment) => onAcceptOrRejetedFactoring(comment, false)}
					onClickCloseCancel={() =>
						setConfigurationModal({
							...configurationModal,
							isOpen: false,
							isRejected: false,
						})
					}
					isSaving={isSaving}
				/>
			)}
		</>
	)
}

const styles = (theme) => ({
	card: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	cardHeader: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
	},
	rejectedSupplier: {
		backgroundColor: COLOR_STATUS_DANGER,
	},
	acceptedSupplier: {
		backgroundColor: COLOR_SECONDARY_BLUE_LIGHT,
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(SignWithBank))
