/**Import react section */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Select from 'react-select'
/**Import MaterialUi section */
import { Grid, Avatar, TextField, Typography, LinearProgress, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import SupplierSelector from '../../../common/SupplierSelector'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'

/**Import actionsSection */
import { createFactoringAffiliationBackEnd } from '../../../../store/factoringAffiliations/FactoringAffiliationActions'
import { GetSupplierNationalityType } from '../../../../store/helpers/SelectOptions'
import { loadAdministratorSupplier } from '../../../../store/suppliers/ExternalUserActions'

const CreateFactoringAffiliationPanel = (props) => {
	/**Functions props section */
	const { onCloseButtonClick, onRedirectToDetail } = props
	/**Objects props section */
	const { classes, administratorSupplier } = props

	const dispatch = useDispatch()
	const isSaving = useSelector((state) => state.factoringAffiliation.get('isSaving'))

	const [factoringAffiliationModel, setFactoringAffiliationModel] = useState({
		SupplierCode: '',
		Email: '',
		SupplierName: '',
		SupplierRFC: '',
		PhoneNumber: '',
		Nationality: null,
	})
	// /**Use effect section */
	useEffect(() => {
		if (administratorSupplier) {
			setFactoringAffiliationModel({
				...factoringAffiliationModel,
				Email: administratorSupplier ? administratorSupplier.email : '',
				PhoneNumber: administratorSupplier ? administratorSupplier.phone_number : '',
			})
		}
	}, [dispatch, administratorSupplier])

	const onAddFactoringAffiliation = () => {
		return dispatch(createFactoringAffiliationBackEnd(factoringAffiliationModel))
			.then((result) => {
				onRedirectToDetail(result.Id)
			})
			.catch((response) => {})
	}

	/**Verify if can be sabed data*/
	const canBeSaved = () => {
		if (IsNullOrEmpty(factoringAffiliationModel.SupplierCode)) return false
		if (IsNullOrEmpty(factoringAffiliationModel.SupplierName)) return false
		if (IsNullOrEmpty(factoringAffiliationModel.SupplierRFC)) return false
		if (IsNullOrEmpty(factoringAffiliationModel.PhoneNumber)) return false
		if (IsNullOrEmpty(factoringAffiliationModel.Nationality)) return false
		if (IsNullOrEmpty(factoringAffiliationModel.Email)) return false
		return true
	}

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setFactoringAffiliationModel({
			...factoringAffiliationModel,
			[name]: value,
		})
	}

	const onChangeNationality = (option) => {
		setFactoringAffiliationModel({
			...factoringAffiliationModel,
			Nationality: option,
		})
	}

	const onSelectedChange = (option) => {
		setFactoringAffiliationModel({
			...factoringAffiliationModel,
			SupplierCode: option ? option.code : '',
			SupplierName: option ? option.name : '',
			SupplierRFC: option ? option.rfc : '',
		})

		return dispatch(loadAdministratorSupplier(`${option ? option.code : ''}-admin`))
	}
	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={<span>{<Translate id='factoringAffiliation.title' />}</span>}
				onCloseButtonClick={onCloseButtonClick}
				icon={
					<Avatar className={classes.avatar}>
						<AddIcon />
					</Avatar>
				}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid container>
						<Grid container spacing={1}>
							<Grid item xs={12} style={{ marginTop: '1rem' }}>
								<Typography>
									<Translate id='factoringAffiliation.table.supplierCode' />
								</Typography>
								<SupplierSelector onSupplierSelected={(supplier) => onSelectedChange(supplier)} />
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<div className={classes.createTextField}>
								<Typography>
									<Translate id='factoringAffiliation.table.supplierName' />
								</Typography>
								<TextField
									value={factoringAffiliationModel ? factoringAffiliationModel.SupplierName : ''}
									fullWidth
									margin='normal'
									variant='outlined'
									name='SupplierName'
									onChange={onPropertyChange}
									disabled={isSaving}
								/>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div className={classes.createTextField}>
								<Typography>
									<Translate id='factoringAffiliation.table.supplierRFC' />
								</Typography>
								<TextField
									value={factoringAffiliationModel ? factoringAffiliationModel.SupplierRFC : ''}
									fullWidth
									margin='normal'
									variant='outlined'
									name='SupplierRFC'
									onChange={onPropertyChange}
									disabled={isSaving}
								/>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div className={classes.createTextField}>
								<Typography>
									<Translate id='factoringAffiliation.table.phoneNumber' />
								</Typography>
								<TextField
									value={factoringAffiliationModel ? factoringAffiliationModel.PhoneNumber : ''}
									fullWidth
									margin='normal'
									variant='outlined'
									name='PhoneNumber'
									onChange={onPropertyChange}
									disabled={isSaving}
								/>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div className={classes.createTextField}>
								<Typography>
									<Translate id='factoringAffiliation.table.email' />
								</Typography>
								<TextField
									value={factoringAffiliationModel ? factoringAffiliationModel.Email : ''}
									fullWidth
									margin='normal'
									variant='outlined'
									name='Email'
									onChange={onPropertyChange}
									disabled={isSaving}
								/>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div className={classes.createTextField}>
								<Typography>
									<Translate id='factoringAffiliation.nacionality' />
								</Typography>
								<Select
									className={classes.documentTypeSelect}
									options={GetSupplierNationalityType()}
									onChange={onChangeNationality}
									styles={{
										// menu: (base) => ({ ...base, zIndex: 10 })
										menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
										menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
										menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
									}}
									menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
									menuPlacement={'auto'}
								/>
							</div>
						</Grid>
					</Grid>
				</div>
				{isSaving && <LinearProgress />}
				<Button
					className={classes.btnSubmit}
					type='submit'
					variant='contained'
					disabled={isSaving || !canBeSaved()}
					fullWidth
					onClick={onAddFactoringAffiliation}
				>
					<Translate id={'accreditedWorkshops.create'} />
				</Button>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	expansionPanelSummary: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		paddingLeft: '20px',
		paddingTop: '10px',
		paddingBotton: '20px',
		paddingRight: '20px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	btnSubmit: {
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${BACKGROUND_COLOR_GRAY} !important`,
			opacity: 0.5,
		},
	},
})

CreateFactoringAffiliationPanel.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
}

CreateFactoringAffiliationPanel.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(CreateFactoringAffiliationPanel))
