import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { Toolbar, Typography, TextField } from '@material-ui/core';

import { Translate } from 'react-localize-redux';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import autoBind from 'auto-bind'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';

class DialogEditUser extends Component {
    /**
     * Create an instance of Dialog user
     * @param {*} props
     * @memberof DialogEditUser
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            token: this.props.user.token,
            name: this.props.user.name,
            state: this.props.user.status,
        }
    }

     /**
     *method add properties 
     *
     * @param {*} userId
     * @memberof Show
     */
    updateProperty(property, value) {
        this.setState({
            [property]: value,
        })
    }

    /**
     * Function to trigger blur event
     * 
     * @param {*} event
     * @memberof DetailsCard
     */
    onKeyDown(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    /**
     * Validate if Save button is enable 
     * to enable, required properties must has data
     *
     * @returns
     * @memberof DialogAddUser
     */
    canBeSaved() {
        if (!IsNullOrEmpty(this.state.name))
            return true;
        return false;
    }

    /**
     * Handle close or cancel to clean local estate and call prop function after update
     *
     * @memberof DialogAddUser
     */
    handleCloseCancel() {
        this.setState({ name: '' }, () => this.props.onCloseCancel('openModalEditUser'))
    }

    /**
     * Handle OnSave, if response is 'Ok' then clean state
     *
     * @memberof DialogAddUser
     */
    handleSave() {
        if(this.props.onSave)
            this.props.onSave(this.state)
    }

    /**
     * Render user
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.showDialog}
                onClose={() => { this.handleCloseCancel() }}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            {<Translate id="qualityCatalogs.editUser" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <div>
                        {!this.canBeSaved() &&
                            <Typography variant="caption" color='error'>
                                {<Translate id="common.requiredFields" />}
                            </Typography>
                        }
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => { this.handleCloseCancel() }}
                            onClose={() => { this.handleCloseCancel() }}
                            onSaveItem={() => this.handleSave()}
                            isSaving={this.props.isSaving}
                            canBeSaved={this.canBeSaved()}
                        />
                    </div>
                } >
                <form className={classes.ediitForm}>
                    <TextField id="name" fullWidth autoFocus required
                        className={classes.textField}
                        label={<Translate id="qualityCatalogs.name" />}
                        value={this.state.name ? this.state.name : ''}
                        onBlur={(event) => this.updateProperty("name", event.target.value)}
                        onChange={(event) => this.updateProperty("name", event.target.value)}
                        onKeyDown={(event) => this.onKeyDown(event)}
                    />
                    <br/><br/>
                </form>
            </Dialog>
        );
    }
}

DialogEditUser.propTypes = {
    showDialog: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onCloseCancel: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default DialogEditUser;