import React from 'react'
import { Translate } from 'react-localize-redux'

/* Material UI import section */
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/AddTwoTone'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { Card, Divider, Collapse, CardActionArea, Typography, CardHeader, IconButton } from '@material-ui/core'

import PopperListsComponent from '../../../biddings/components/PopperListsComponent'
import ThereIsNoInformation from '../../common/ThereIsNoInformation'

const CardTypeShipment = (props) => {
	const {
		classes,
		isAdministrator,
		openCollapese,
		setOpenCollapese,
		typeShipment,
		setOpenOpenAssignSupplier,
		onDelete,
		onDeleteCarrier,
	} = props
	return (
		<Card>
			<CardActionArea className={classes.typeShipment}>
				<Divider />
				<CardHeader
					avatar={
						<div onClick={() => setOpenCollapese({ open: !openCollapese.open, Id: typeShipment.Id })}>
							{openCollapese.open ? (
								<ExpandLessIcon className={classes.expandIcon} />
							) : (
								<ExpandMoreIcon className={classes.expandIcon} />
							)}
						</div>
					}
					title={
						<div className={classes.titleTabs}>
							<Typography variant='subtitle2'>{typeShipment.Name}</Typography>
							{isAdministrator && (
								<div className={classes.titleTabs}>
									<div className={classes.flex}>
										<div className={classes.flex}>
											<Typography variant='subtitle2'>
												<Translate id={'carrierTenders.configuration.addCarrier'} />
											</Typography>

											<AddIcon
												className={classes.addIcon}
												onClick={() =>
													setOpenOpenAssignSupplier({ open: true, Id: typeShipment.Id })
												}
											/>
										</div>
										<PopperListsComponent
											title={<Translate id='common.options' />}
											listItems={popperLists(onDelete)}
										/>
									</div>
								</div>
							)}
						</div>
					}
				/>
			</CardActionArea>
			<Collapse in={openCollapese.open && openCollapese.Id === typeShipment.Id} timeout='auto' unmountOnExit>
				<Typography variant='body2' className={classes.titleSupplier}>
					<Translate id={'carrierTenders.configuration.supppliers'} />
				</Typography>
				<Divider className={classes.dividerMargin} />

				{typeShipment.Carriers &&
					typeShipment.Carriers.length > 0 &&
					typeShipment.Carriers.map((x, index) => {
						return (
							<div key={index}>
								<div className={classes.containerSupplier}>
									<Typography variant='body2'>
										{x.Code} | {x.Name}
									</Typography>
									<IconButton onClick={() => onDeleteCarrier(x.Id)}>
										<DeleteIcon fontSize='small' color='primary' />
									</IconButton>
								</div>
								<Divider className={classes.dividerMargin} />
							</div>
						)
					})}
				{typeShipment.Carriers && typeShipment.Carriers.length === 0 && <ThereIsNoInformation />}
			</Collapse>
			{/* <Divider /> */}
		</Card>
	)
}

const popperLists = (onDelete) => {
	let options = []
	options.push({
		title: <Translate id='common.delete' />,
		onClick: () => onDelete(),
		icon: <DeleteIcon />,
		isIconMaterial: true,
		disabled: false,
	})
	return options
}
export default CardTypeShipment
