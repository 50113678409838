import {Map, fromJS } from 'immutable';

import * as actions from './ProfileActions';
import {SET_SELETECT_COMPANY} from '../companies/CompanyActions'
import {COMPANY_ACTIVE, COMPANY_THEME_ACTIVE} from '../../store/helpers/ResourcesHelper';


/**
 * Suppliers initial state
 */
export const profileInitialState = fromJS({
    grantedCompanies: [],
    selectedCompany: null,
    isLoadingProfile: false,
    grantedRoles: [],
    userRolesByCompany: [],
    isOpenLateralMenu: true
})

export default function profileReducer(state = profileInitialState, action){
    switch(action.type){
        case actions.IS_LOADING_USER_PROFILE: {
            return state.merge({
                isLoadingProfile: action.isLoading
            })
        }
        case actions.SET_IS_OPEN_LATERAL_MENU:{
            return state.merge({
                isOpenLateralMenu: action.isOpen
            })
        }
        case actions.SET_LOGGED_USER_GRANTED_COMPANIES: {
            let selectedCompany = state.get('selectedCompany');

            if (!selectedCompany) {
                // Check if there is a local storaged company token
                let selectedCompanyTokenInLocalStorage = localStorage.getItem(COMPANY_ACTIVE);
                if (selectedCompanyTokenInLocalStorage) {
                    selectedCompany = action.companies.find((company) =>  company.token === selectedCompanyTokenInLocalStorage )
                }
            }

            if (!selectedCompany && action.companies.length > 0) {
                selectedCompany = action.companies[0]
            }
            
            return state.merge({
                grantedCompanies: action.companies.map((company) => Map(company)),
                selectedCompany: fromJS(selectedCompany)
            })
        }
        case SET_SELETECT_COMPANY: {
            localStorage.setItem(COMPANY_ACTIVE, action.actualCompany.token);
            localStorage.setItem(COMPANY_THEME_ACTIVE, action.actualCompany.document_identifier);
            let userRoles = state.get('grantedRoles').filter((role) =>  role.get("company_token") ===  action.actualCompany.token);
            return state.merge({
                selectedCompany: fromJS(action.actualCompany),
                userRolesByCompany: fromJS(userRoles),
            })
        }
        case actions.SET_LOGGED_USER_ROLES:{
            let selectedCompany = state.get('selectedCompany');
            if (selectedCompany) {
                  // Check if there is a local storaged company token
                  localStorage.setItem(COMPANY_ACTIVE, selectedCompany.get("token"));
                  localStorage.setItem(COMPANY_THEME_ACTIVE, selectedCompany.get("document_identifier"));
                  let selectedCompanyTokenInLocalStorage = localStorage.getItem(COMPANY_ACTIVE);
                  if (selectedCompanyTokenInLocalStorage) {
                    let userRoles = action.roles.filter((role) =>  role.company_token === selectedCompany.get("token") )
                    return state.merge({
                        grantedRoles: action.roles,
                        userRolesByCompany: fromJS(userRoles),
                    })
                }
            }
            break;
        }
        default:
            return state;
    }
}