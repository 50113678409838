/**Import react section */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import moment from 'moment'

/**Import material UI Section*/
import { withStyles } from '@material-ui/core'
import { Typography, List, ListItemText, ListItem, Grid } from '@material-ui/core'
import DatePicker from '../../common/DateTimePicker/DatePicker'

/**Import actions section */
const GeneralInformation = ({ delivery, classes, onUpdateDelivery, canBeAdd }) => {
	const [model, setPanelModel] = useState({
		DeliveryDate: delivery ? (delivery.deliveryDate ? moment(delivery.deliveryDate).format('DD/MM/YYYY') : '') : '',
		EntryDate: delivery ? (delivery.entryDate ? moment(delivery.entryDate).format('DD/MM/YYYY') : '') : '',
		RecipientCode: delivery.recipient ? delivery.recipient.code : '',
	})

	useEffect(() => {
		if (canBeAdd && delivery && model.RecipientCode !== delivery.recipient.code) {
			setPanelModel({
				DeliveryDate: delivery
					? delivery.deliveryDate
						? moment(delivery.deliveryDate).format('DD/MM/YYYY')
						: ''
					: '',
				EntryDate: delivery ? (delivery.entryDate ? moment(delivery.entryDate).format('DD/MM/YYYY') : '') : '',
				RecipientCode: delivery.recipient ? delivery.recipient.code : '',
			})
		}
	}, [delivery, model, setPanelModel])

	const onPropertyDelivery = (name, property) => {
		setPanelModel({
			...model,
			[name]: property,
		})
		onUpdateDelivery({
			DeliveryDate: name === 'DeliveryDate' ? property : '',
			EntryDate: name === 'EntryDate' ? property : '',
			RecipientCode: delivery.recipient ? delivery.recipient.code : '',
		})
	}

	return (
		<List>
			<ListItem divider>
				<ListItemText
					secondary={
						<React.Fragment>
							<Typography component='span' variant='body2' className={classes.inline} color='textPrimary'>
								{'RFC: '}
							</Typography>
							{delivery.recipient && `${delivery.recipient.rfc}`}
						</React.Fragment>
					}
				/>
			</ListItem>
			<ListItem divider>
				<ListItemText
					secondary={
						<React.Fragment>
							<Typography component='span' variant='body2' className={classes.inline} color='textPrimary'>
								{<Translate id='consignmentNote.table.receiver' />} {': '}
							</Typography>
							{delivery.recipient && `${delivery.recipient.code} | ${delivery.recipient.name}`}
						</React.Fragment>
					}
				/>
			</ListItem>
			<ListItem divider>
				<ListItemText
					secondary={
						<React.Fragment>
							<Typography component='span' variant='body2' className={classes.inline} color='textPrimary'>
								{<Translate id='consignmentNote.show.address' />}
								{': '}
							</Typography>
							{delivery.recipient &&
								`${delivery.recipient.street} ${delivery.recipient.suburb}, ${
									delivery.recipient.externalNumber ? delivery.recipient.externalNumber : ''
								}`}
							{delivery.recipient &&
								`${delivery.recipient.town} ${delivery.recipient.stateCode} ${delivery.recipient.countryCode}, CP: ${delivery.recipient.zipCode}`}
						</React.Fragment>
					}
				/>
			</ListItem>
			<ListItem divider>
				<ListItemText
					secondary={
						<React.Fragment>
							<Grid className={classes.contentDate}>
								<Typography
									component='span'
									variant='body2'
									className={classes.inline}
									color='textPrimary'
								>
									{<Translate id='consignmentNote.show.deliveryDate' />}
									{': '}
								</Typography>
								{canBeAdd && (
									<DatePicker
										onChangeRange={(event) => onPropertyDelivery('DeliveryDate', event)}
										startDate={model.DeliveryDate ? moment(model.DeliveryDate, 'DD/MM/YYYY') : ''}
									/>
								)}
								{!canBeAdd && <>{delivery.deliveryDateForm ? delivery.deliveryDateForm : '-'}</>}
								<Typography
									component='span'
									variant='body2'
									className={classes.inlineDate}
									color='textPrimary'
								>
									{<Translate id='consignmentNote.show.entryDate' />}
									{': '}
								</Typography>
								{canBeAdd && (
									<DatePicker
										onChangeRange={(event) => onPropertyDelivery('EntryDate', event)}
										startDate={model.EntryDate ? moment(model.EntryDate, 'DD/MM/YYYY') : ''}
									/>
								)}
								{!canBeAdd && <>{delivery.entryDateForm ? delivery.entryDateForm : '-'}</>}
							</Grid>
						</React.Fragment>
					}
				/>
			</ListItem>
		</List>
	)
}

const styles = () => ({
	inline: {
		display: 'inline',
	},
	contentDate: {
		display: 'flex',
		alignItems: 'center',
	},
	inlineDate: {
		display: 'inline',
		marginLeft: '10px',
	},
})

GeneralInformation.propTypes = {
	delivery: propTypes.object,
}

GeneralInformation.defaultProps = {
	delivery: {},
}

export default withRouter(withStyles(styles)(GeneralInformation))
