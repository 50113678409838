/** React imports section */
import React from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
/** Material-UI imports section */
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	LinearProgress,
	Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Clear } from '@material-ui/icons'

const ConfirmationChangeModal = (props) => {
	const { open, onClose, onActionClick, isLoading, folio } = props
	const classes = useStyles()

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth={'sm'}
			aria-labelledby='confirmation-change-title'
		>
			<DialogTitle id='confirmation-change-title' disableTypography className={classes.dialogTitle}>
				<Typography gutterBottom color='primary' variant='subtitle1' className={classes.title}>
					<strong>
						<Translate id='specificationsSection.package.confirmationModalTitle' />
					</strong>
				</Typography>
				<Fab size='small' className={classes.root} onClick={onClose}>
					<Clear />
				</Fab>
			</DialogTitle>
			<DialogContent>
				<Typography>
					<Translate id='specificationsSection.package.confirmationModalBody1' />
					<br />
					<strong>{folio}</strong>
					<br />
					<Translate id='specificationsSection.package.confirmationModalBody2' />
				</Typography>
				{isLoading && <LinearProgress color='secondary' variant='query' />}
			</DialogContent>
			<DialogActions>
				<Button
					disabled={isLoading}
					onClick={onActionClick}
					data-cy='btn-save'
					type='button'
					color='primary'
					variant='outlined'
				>
					<Translate id='common.confirm' />
				</Button>
				<Button
					disabled={isLoading}
					onClick={onClose}
					data-cy='btn-cancel'
					type='button'
					color='secondary'
					variant='outlained'
				>
					<Translate id='common.cancel' />
				</Button>
			</DialogActions>
		</Dialog>
	)
}

ConfirmationChangeModal.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
	onActionClick: propTypes.func,
	isLoading: propTypes.bool,
	folio: propTypes.string,
}

ConfirmationChangeModal.defaultProps = {
	open: false,
	onClose: () => console.warn('[onClose] is not defined!'),
	onActionClick: () => console.warn('[onActionClick] is not defined!'),
	isLoading: false,
	folio: '',
}

const useStyles = makeStyles((theme) => ({
	dialogTitle: {
		display: 'flex',
		alignItems: 'center',
		'& > *': {
			marginLeft: 5,
		},
	},
	title: {
		flex: '1 1 0',
	},
}))

export default ConfirmationChangeModal
