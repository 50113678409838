import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignSelf: 'center'
	},
	btnFilter: {
		boxShadow: '0px 0px 0px 0px',
		backgroundColor: 'white',//theme.palette.common.white,
		'&:hover': {
			backgroundColor: 'white'//theme.palette.common.white
		}
	},
	iconDelete: {
		width: '19px'
	},
	papperPopper: {
		zIndex: 100,
		padding: 5,
		border: '1px solid gray'
	},
	mainList: {
		padding: '0 0 5px 0'
	},
	filterItemsContainer: {
		minHeight: '6rem',
		maxHeight: '12rem',
		overflowY: 'auto'
	},
	filterItem: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		height: 30
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center'
		//color: COLOR_LIGHT_GRAY,
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end'
	}
}));

export default useStyles;
