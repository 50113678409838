
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/** Material-UI imports section */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';

/** Language imports **/
import {Translate} from "react-localize-redux";

/**
 * Component Form user
 */
class FormatViewUser extends Component {

    
    /* Determine if a key is inclued in the errors list the user
    *
    * @param {*} key
    * @returns
    */
    hasInvalidValue(key) {
    if (!this.props.errors) {
        return false;
    }
    return this.props.errors.some(error => error.key === key);
}

    /**
     * Render show 
     */
    render(){
        const { classes } = this.props;
        let user = this.props.user;
            return(
                <form className={classes.container} noValidate autoComplete="off">
                    
                    {!this.props.editUser && <TextField
                        error = {this.hasInvalidValue("number_supplier")}
                        disabled 
                        id="standard-uncontrolled"
                        label={<Translate id="users.nameUser"/>}
                        value={user.number_supplier}
                        className={classes.textField}
                        margin="normal"
                        onChange={(event) => {this.props.updateProperty("user_name", event.target.value)}}
                    />}

                    {!this.props.editUser &&<TextField
                        error = {this.hasInvalidValue("user_name")}
                        disabled = {this.props.showToView}
                        id="standard-uncontrolled"
                        label={<Translate id="users.nameUser"/>}
                        value={user.user_name}
                        className={classes.textField}
                        margin="normal"
                        onChange={(event) => {this.props.updateProperty("user_name", event.target.value)}}
                        helperText={<Translate id="users.messageUserName"/>}
                    />}

                    {this.props.editUser &&<TextField
                        error = {this.hasInvalidValue("user_name")}
                        disabled
                        id="standard-uncontrolled"
                        label={<Translate id="users.nameUser"/>}
                        value={user.user_name}
                        className={classes.textField}
                        margin="normal"
                        onChange={(event) => {this.props.updateProperty("user_name", event.target.value)}}
                        helperText={<Translate id="users.messageUserName"/>}
                    />}
                    
                    <TextField
                        error = {this.hasInvalidValue("full_name")}
                        disabled = {this.props.showToView}
                        id="standard-read-only-input"
                        label={<Translate id="users.name"/>}
                        value={user.full_name}
                        className={classes.textField}
                        margin="normal"
                        onChange={(event) => {this.props.updateProperty("full_name", event.target.value)}}
                    />

                    <TextField
                        error = {this.hasInvalidValue("email")}
                        disabled = {this.props.showToView}
                        id="standard-read-only-input"
                        label={<Translate id="users.email"/>}
                        value={user.email}
                        className={classes.textField}
                        margin="normal"
                        onChange={(event) => {this.props.updateProperty("email", event.target.value)}}
                    />

                   <Grid container  className={classes.footerForm} >
                   {!this.props.showToView && <DialogActions>
                            <Button 
                                data-cy="btn-save"
                                type="button" 
                                color="primary" 
                                onClick={this.props.onSave}
                                disabled={!this.props.canBeSaved }>
                                {<Translate id="common.save"/>}
                            </Button>
                            <Button onClick={()=>this.props.setViewInEditMode(true)} color="secondary">
                                {<Translate id="common.cancel"/>}
                            </Button>
                        </DialogActions>}
                    </Grid> 

                </form>
            )
    }
}

const viewStyles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingLeft: "100px",
      paddingRight: "150px",
    },
    tetxtFieldFull:{
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 900,
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 440,
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    footerForm:{
        display: "flex",
        paddingTop: "30px",
    },
});

export default withRouter(withStyles(viewStyles, { withTheme:true })(FormatViewUser));