///Import react section
import React, { useEffect, useState } from 'react';
import { Translate, getTranslate } from "react-localize-redux";
import { useSelector, useDispatch, } from 'react-redux'
/** Import component section */
import EditBiddingLateralPanel from './components/EditBiddingLateralPanel'
import Toaster from '../common/Toaster';
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog';
import AddSuppliersLateralPanel from './components/suppliers/AddSuppliersLateralPanel'
import AddSuppliersDialog from './components/suppliers/AddSuppliersDialog'
import AddServicesLateralPanel from './components/materials/AddServicesLateralPanel'
import AddEvaluationCriterialLateralPanel from './components/criterias/AddEvaluationCriterialLateralPanel'
import EditEvaluationCriterialLateralPanel from './components/criterias/EditEvaluationCriterialLateralPanel'
import EditCriteriaGroupLateralPanel from './components/criterias/EditCriteriaGroupLateralPanel'
import AddWeighingLateraPanel from './components/results/AddWeighingLateraPanel'
import AddAttachmentLateralPanel from '../common/LateralPanelAtachment/AddAttachmentLateralPanel'
import AddExchangeRatesLateralPanel from './components/suppliers/AddExchangeRatesLateralPanel'
import AddSupplierResponseByItemLateralPanel from './components/suppliers/AddSupplierResponseByItemLateralPanel'
import AddSupplierResponseGralLateralPanel from './components/suppliers/AddSupplierResponseGralLateralPanel'
import WarningComponent from '../common/WarningComponent'
import AddWinnersLateralPanel from './components/results/AddWinnersLateralPanel'
import AddCommentsSuppliersLateralPanel from './components/results/AddCommentsSuppliersLateralPanel'
import SendEmailsToSuppliersLateralPanel from './components/suppliers/SendEmailsToSuppliersLateralPanel'
/**Import actions section */
import {
    updateBiddingFromBackEnd,
    addSupplierInBiddingFromBackEnd,
    addExternalSupplierInBiddingFromBackEnd,
    addServiceFromBackEnd,
    updateEvaluationCriteria, addEvaluationCriteriaService,
    loadMeasurementUnitsFromBackEnd, importMaterialsFile,
    updateEvaluationCriteriaGroup, ADD_WINNER_SUPPLIER,
    addWinnerFromBackend, deleteAtachmentSupplier,
    changeStatusBiddingFromBackEnd, UPDATE_PUBLISH_FLAG_ENABLED,
    loadBiddingFromBackEnd, importMaterialsPricesBysupplierFile,
    isPublicationEnabled, addMaterialResponsebySupplier,
    isSentProposalEnabled, importMaterialsPricesBySupplierExternalFile,
    addMaterialResponseBySupplierExternal, isSentProposalExternalEnabled,
    addWinnerInItemFromBackend, ADD_WINNER_IN_ITEM_SUPPLIER,
    isPublicationResultsEnabled, PUBLISH_RESULTS_BIDDING,
    publishResultsFromBackEnd, addFinishedCommentsInSupplier,
    getExchangeRatesInBiddingFromBackEnd, downloadAdditionalCostTemplate,
    importAdditionalCostFile, resendEmailToSupplier, loadBiddingBySupplierExternalCode,
    isCalculateEnabled,
} from '../../store/biddings/BiddingsActions'
import {
    searchUsersInRole, loadInternalUsersFromBackEnd
} from '../../store/administration/InternalActions'
/**import helpers section */
import { ROLE_BIDDING_RESPONSIBLE } from '../../store/helpers/RolesHelper'
import { converListToArray } from '../../store/helpers/ListHelper'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';
// import { CategoryType } from '../../store/helpers/AppConstants';
import { ADD_DUPLICATED_BIDDING_NAME_MESSAGE } from '../../store/helpers/MessagesError'
import { BiddingStatus } from '../../store/helpers/AppConstants'
import { canRefreshScreenToPublishBidding } from '../../store/helpers/BiddingHelper'
import downloadTemplateRepare from '../../resources/files/templateMaterials.xlsx'

const Show = (props) => {
    const translate = useSelector((state) => getTranslate(state.localize))
    /**String props section */
    const { supplierExternalCode } = props
    /**Bools props section */
    const { isExternalUser } = props
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onOpenCloseEditLateralPanel, onOpenCloseEditSuppliersLateralPanel,
        onOpenCloseCreateServicesLateralPanel, onCreateEvaluationCriteriaConfigPanel,
        onEditEvaluationCriteriaConfigPanel, onOpenCloseUploadAttachmentsLateralPanel,
        onEditEvaluationCriteriaGroupConfigPanel, onConfirmPassword, onAddWeighingConfigPanel,
        addEvaluationResponsible, onOpenCloseExchangeRatesLateralPanel,
        uploadMaterialsPricesConfigPanel, onAddResponseItemSupplierConfigPanel,
        onAddResponseSupplier, onAddResponseGralSupplierConfigPanel, onAddWinnerConfigPanel,
        onAddCommentsSupplierConfigPanel, onUploadAdditionalCostsConfigPanel,
        onSendEmailConfigPanel
    } = props
    /**Objects props section */
    const { editBiddingConfigPanel, bidding, editSupplierConfigPanel,
        createServicesConfigPanel, createEvaluationCriteriaConfigPanel,
        editEvaluationCriteriaConfigPanel, uploadAttachmentConfigPanel,
        editEvaluationCriteriaGroupConfigPanel, confirmPasswordConfigModal,
        addWeighingConfigPanel, addExchangeRatesConfigPanel,
        onUploadMaterialsPricesConfigPanelLateralPanel, addResponseItemSupplierConfigPanel,
        addResponseGralSupplierConfigPanel, addWinnerConfigPanel,
        addCommentsSupplierConfigPanel, uploadAdditionalCostsConfigPanel, sendEmailConfigPanel
    } = props
    /**Render hooks section */
    const dispatch = useDispatch()
    const [openConfigAddSupplierModal, setOpenConfigAddSupplierModal] = useState({ opened: false })
    /**Use effect section */
    useEffect(() => {
        dispatch(loadInternalUsersFromBackEnd())
        if (!props.isExternalUser)
            dispatch(searchUsersInRole(ROLE_BIDDING_RESPONSIBLE))
    }, [dispatch, props.isExternalUser])

    /**Redux store information section*/
    const isSaving = useSelector((state) => state.biddings.get('isSaving'))
    const usersByRole = useSelector((state) => state.internalUsers.get('usersByRole') ? converListToArray(state.internalUsers.get('usersByRole')) : [])
    const userProfile = useSelector((state) => state.oidc.user ? state.oidc.user.profile : { name: "", email: "", user_name: "" })
    const measurementunits = useSelector((state) => state.biddings.get('measurementunits') ? state.biddings.get('measurementunits').toJS() : [])
    const userRolesByCompany = useSelector((state) => state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : [])
    const divisas = useSelector((state) => state.biddings.get('divisas') ? converListToArray(state.biddings.get('divisas')) : [])
    const incoterms = useSelector((state) => state.biddings.get('incoterms') ? converListToArray(state.biddings.get('incoterms')) : [])
    const [toasterMessageConfig, setToasterMessageConfig] = useState({
        showToaster: false,
        toasterMessage: null,
        toasterVariant: "",
        name: ""
    })

    const onUpdateBidding = (biddingModel,) => {
        return dispatch(updateBiddingFromBackEnd(biddingModel, bidding ? bidding.Id : ""))
            .then(() => {
                if (canRefreshScreenToPublishBidding(bidding)) {
                    dispatch(isPublicationEnabled(bidding ? bidding.Id : ""))
                }
                onOpenCloseEditLateralPanel()
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
            })
            .catch((response) => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: (!IsNullOrEmpty(response.data) && typeof response.data === 'string' && response.data.includes(ADD_DUPLICATED_BIDDING_NAME_MESSAGE)) ? "biddingsModule.errorMessages.addBiddingError" : "common.errorToSave",
                    toasterVariant: "error",
                    name: biddingModel.name
                })
            })
    }

    const onAddSupplierInBiddingFromBackEnd = (biddingModel,) => {
        biddingModel.Id = bidding ? bidding.Id : ""
        return dispatch(addSupplierInBiddingFromBackEnd(biddingModel))
            .then(() => {
                if (canRefreshScreenToPublishBidding(bidding)) {
                    dispatch(isPublicationEnabled(bidding ? bidding.Id : ""))
                }
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onAddService = (biddingModel,) => {
        biddingModel.Id = bidding ? bidding.Id : ""
        return dispatch(addServiceFromBackEnd(biddingModel))
            .then(() => {
                if (canRefreshScreenToPublishBidding(bidding)) {
                    dispatch(isPublicationEnabled(bidding ? bidding.Id : ""))
                }
                onOpenCloseCreateServicesLateralPanel()
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onOpenModalForAddExternalSupplier = () => {
        setOpenConfigAddSupplierModal({ opened: !openConfigAddSupplierModal.opened })
    }

    const onAddExternalSupplierInBiddingFromBackEnd = (biddingModel) => {
        biddingModel.Id = bidding ? bidding.Id : ""
        return dispatch(addExternalSupplierInBiddingFromBackEnd(biddingModel))
            .then(() => {
                if (canRefreshScreenToPublishBidding(bidding)) {
                    dispatch(isPublicationEnabled(bidding ? bidding.Id : ""))
                }
                onOpenModalForAddExternalSupplier()
                onOpenCloseEditSuppliersLateralPanel()
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onAddEvaluationCriteria = (biddingModel) => {
        biddingModel.Id = bidding ? bidding.Id : ""
        return dispatch(addEvaluationCriteriaService(biddingModel))
            .then(() => {
                if (canRefreshScreenToPublishBidding(bidding)) {
                    dispatch(isPublicationEnabled(bidding ? bidding.Id : ""))
                }
                dispatch(loadMeasurementUnitsFromBackEnd())
                onCreateEvaluationCriteriaConfigPanel()
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })

    }

    const onUpdateEvaluationCriteria = (biddingModel) => {
        biddingModel.Id = editEvaluationCriteriaConfigPanel.evaluationCriteria ? editEvaluationCriteriaConfigPanel.evaluationCriteria.Id : ""
        return dispatch(updateEvaluationCriteria(biddingModel))
            .then(() => {
                if (canRefreshScreenToPublishBidding(bidding)) {
                    dispatch(isPublicationEnabled(bidding ? bidding.Id : ""))
                }
                dispatch(loadMeasurementUnitsFromBackEnd())
                onEditEvaluationCriteriaConfigPanel()
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onUpdateEvaluationCriteriaGroup = (biddingModel) => {
        return dispatch(updateEvaluationCriteriaGroup(biddingModel))
            .then(() => {
                onEditEvaluationCriteriaGroupConfigPanel()
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onImportMaterialsFileInBidding = (attachments) => {
        return dispatch(importMaterialsFile(attachments, bidding ? bidding.Id : ""))
            .then(() => {
                if (canRefreshScreenToPublishBidding(bidding)) {
                    dispatch(isPublicationEnabled(bidding ? bidding.Id : ""))
                }
                dispatch(loadBiddingFromBackEnd(bidding ? bidding.Id : ""))
                onOpenCloseUploadAttachmentsLateralPanel()
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onImportMaterialPricesBySupplier = (attachments) => {
        if (isExternalUser) {
            return dispatch(importMaterialsPricesBySupplierExternalFile(attachments, supplierExternalCode))
                .then(() => {
                    dispatch(loadBiddingBySupplierExternalCode(supplierExternalCode))
                    .then((existBidding) => {
                        dispatch(isSentProposalExternalEnabled(bidding ? bidding.Id : "",supplierExternalCode))
                    })
                    onUploadMaterialsPricesConfigPanelLateralPanel()
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterVariant: "success",
                        toasterMessage: "common.addCorrectly",
                    })
                })
                .catch(() => {
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterMessage: "common.errorToSave",
                        toasterVariant: "error",
                    })
                })

        } else {
            return dispatch(importMaterialsPricesBysupplierFile(attachments, bidding ? bidding.Id : ""))
                .then(() => {
                    dispatch(loadBiddingFromBackEnd(bidding ? bidding.Id : "")).then((_) => {
                        dispatch(isSentProposalEnabled(bidding ? bidding.Id : ""))
                    })
                    onUploadMaterialsPricesConfigPanelLateralPanel()
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterVariant: "success",
                        toasterMessage: "common.addCorrectly",
                    })
                })
                .catch(() => {
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterMessage: "common.errorToSave",
                        toasterVariant: "error",
                    })
                })
        }
    }

    const onDeleteAttachmentSupplier = (attachment) => {
        let id = createEvaluationCriteriaConfigPanel.evaluationCriteria ? createEvaluationCriteriaConfigPanel.evaluationCriteria.Id : ""
        return dispatch(deleteAtachmentSupplier(attachment, id))
            .then(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onAddWinnerByBidding = () => {
        return dispatch(addWinnerFromBackend(bidding ? bidding.Id : "", confirmPasswordConfigModal.item))
            .then(() => {
                dispatch(isPublicationResultsEnabled(bidding ? bidding.Id : ""))
                dispatch(loadBiddingFromBackEnd(bidding ? bidding.Id : ""))
                onConfirmPassword()
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onAddWinnerInItemFromBackend = (item, isWinner) => {
        return dispatch(addWinnerInItemFromBackend(item, isWinner))
            .then(() => {
                dispatch(isPublicationResultsEnabled(bidding ? bidding.Id : ""))
                onAddWinnerConfigPanel()
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onCalculateBiddingFromBackEnd = () => {
        return dispatch(changeStatusBiddingFromBackEnd(BiddingStatus.RESOLVED, bidding ? bidding.Id : "", confirmPasswordConfigModal.item))
            .then(() => {
                onConfirmPassword()
                onOpenCloseExchangeRatesLateralPanel()
                dispatch(loadBiddingFromBackEnd(bidding ? bidding.Id : ""))
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }


    const onPublishBiddingFromBackEnd = () => {
        return dispatch(changeStatusBiddingFromBackEnd(BiddingStatus.PUBLISHED, bidding ? bidding.Id : ""))
            .then(() => {
                onConfirmPassword()
                dispatch(loadBiddingFromBackEnd(bidding ? bidding.Id : ""))
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onClosedBiddingFromBackEnd = () => {
        return dispatch(changeStatusBiddingFromBackEnd(BiddingStatus.CLOSED, bidding ? bidding.Id : ""))
            .then(() => {
                onConfirmPassword()
                return dispatch(loadBiddingFromBackEnd(bidding ? bidding.Id : "")).then(_ => {
                    dispatch(getExchangeRatesInBiddingFromBackEnd(bidding ? bidding.Id : ""))
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onPublishResulsFromBackEnd = () => {
        return dispatch(publishResultsFromBackEnd(BiddingStatus.FINISHED, bidding ? bidding.Id : "", []))
            .then(() => {
                onConfirmPassword()
                dispatch(loadBiddingFromBackEnd(bidding ? bidding.Id : ""))
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onAddFinishedCommentsInSupplier = (supplier) => {
        return dispatch(addFinishedCommentsInSupplier(bidding ? bidding.Id : "", supplier))
            .then(() => {
                dispatch(isPublicationResultsEnabled(bidding ? bidding.Id : ""))
                onAddCommentsSupplierConfigPanel()
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onDownloadAdditionalCostTemplate = () => {
        return dispatch(downloadAdditionalCostTemplate(bidding ? bidding.Id : "", uploadAdditionalCostsConfigPanel ? uploadAdditionalCostsConfigPanel.supplier : ""))
            .then(() => {
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onImportAdditionalCostFile = (attachments) => {
        return dispatch(importAdditionalCostFile(attachments, bidding ? bidding.Id : "", uploadAdditionalCostsConfigPanel ? uploadAdditionalCostsConfigPanel.supplier : null))
            .then(() => {
                dispatch(loadBiddingFromBackEnd(bidding ? bidding.Id : ""))
                .then(()=>{
                    dispatch(getExchangeRatesInBiddingFromBackEnd(bidding ? bidding.Id : ""))
                    dispatch(isCalculateEnabled(bidding ? bidding.Id : ""))
                })
                onUploadAdditionalCostsConfigPanel()
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onResendEmailToSupplier = (supplierCode) => {
        return dispatch(resendEmailToSupplier(bidding ? bidding.Id : "", supplierCode))
            .then(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.sendEmailSuccess",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const getMessageByIdentifier = () => {
        switch (confirmPasswordConfigModal.identifier) {
            case ADD_WINNER_IN_ITEM_SUPPLIER:
            case ADD_WINNER_SUPPLIER:
                return translate("biddingsModule.setWinnerMessage")
            case UPDATE_PUBLISH_FLAG_ENABLED:
                return translate("biddingsModule.calculateBiddingMessageTipeChange")
            case PUBLISH_RESULTS_BIDDING:
                return translate("biddingsModule.publishBiddingMessage")
            case BiddingStatus.PUBLISHED:
                return translate("biddingsModule.notificationMessage")
            case BiddingStatus.CLOSED:
                return translate("biddingsModule.closedBidding")
            default:
                return ""
        }
    };

    const getCallBackToInvokeByIndentifier = () => {
        switch (confirmPasswordConfigModal.identifier) {
            case ADD_WINNER_SUPPLIER:
                return onAddWinnerByBidding
            case UPDATE_PUBLISH_FLAG_ENABLED:
                return onCalculateBiddingFromBackEnd
            case PUBLISH_RESULTS_BIDDING:
                return onPublishResulsFromBackEnd
            case BiddingStatus.PUBLISHED:
                return onPublishBiddingFromBackEnd
            case BiddingStatus.CLOSED:
                return onClosedBiddingFromBackEnd
            default:
                return ""
        }
    };

    const getIsRequiredComments = () => {
        switch (confirmPasswordConfigModal.identifier) {
            case BiddingStatus.CLOSED:
            case BiddingStatus.PUBLISHED:
            case ADD_WINNER_SUPPLIER:
            case PUBLISH_RESULTS_BIDDING:
                return false
            default:
                return ""
        }
    };

    const onAddMaterialResponsebySupplier = (materialModel) => {
        if (isExternalUser) {
            return dispatch(addMaterialResponseBySupplierExternal(materialModel, supplierExternalCode))
                .then(() => {
                    dispatch(isSentProposalExternalEnabled(bidding ? bidding.Id : "", supplierExternalCode))
                    onAddResponseItemSupplierConfigPanel()
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterVariant: "success",
                        toasterMessage: "common.addCorrectly",
                    })
                })
                .catch(() => {
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterMessage: "common.errorToSave",
                        toasterVariant: "error",
                    })
                })
        }
        else {
            return dispatch(addMaterialResponsebySupplier(materialModel))
                .then(() => {
                    dispatch(isSentProposalEnabled(bidding ? bidding.Id : ""))
                    onAddResponseItemSupplierConfigPanel()
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterVariant: "success",
                        toasterMessage: "common.saveCorrectly",
                    })
                })
                .catch(() => {
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterMessage: "common.errorToSave",
                        toasterVariant: "error",
                    })
                })
        }
    }


    return (
        <>
            {
                editBiddingConfigPanel && editBiddingConfigPanel.opened &&
                <EditBiddingLateralPanel
                    bidding={bidding}
                    onSaveButtonClick={onUpdateBidding}
                    onCloseButtonClick={onOpenCloseEditLateralPanel}
                    internalUsers={usersByRole}
                    userProfile={userProfile}
                    isSaving={isSaving}

                />
            }

            {
                editSupplierConfigPanel && editSupplierConfigPanel.opened &&
                <AddSuppliersLateralPanel
                    onCloseButtonClick={onOpenCloseEditSuppliersLateralPanel}
                    translate={translate}
                    onOpenModalForAddExternalSupplier={onOpenModalForAddExternalSupplier}
                    onAddSupplierInBiddingFromBackEnd={onAddSupplierInBiddingFromBackEnd}
                    isSaving={isSaving}
                    bidding={bidding}
                />
            }
            {
                openConfigAddSupplierModal && openConfigAddSupplierModal.opened &&
                <AddSuppliersDialog
                    open={openConfigAddSupplierModal.opened}
                    onClose={onOpenModalForAddExternalSupplier}
                    onAddExternalSupplierInBiddingFromBackEnd={onAddExternalSupplierInBiddingFromBackEnd}
                    isSaving={isSaving}
                />
            }
            {
                createServicesConfigPanel && createServicesConfigPanel.opened &&
                <AddServicesLateralPanel
                    translate={translate}
                    bidding={bidding}
                    onCloseButtonClick={onOpenCloseCreateServicesLateralPanel}
                    onSaveButtonClick={onAddService}
                    isSaving={isSaving}
                />
            }

            {
                createEvaluationCriteriaConfigPanel && createEvaluationCriteriaConfigPanel.opened &&
                <AddEvaluationCriterialLateralPanel
                    translate={translate}
                    bidding={bidding}
                    onCloseButtonClick={onCreateEvaluationCriteriaConfigPanel}
                    onSaveButtonClick={onAddEvaluationCriteria}
                    isSaving={isSaving}
                    measurementunits={measurementunits}
                />
            }

            {
                editEvaluationCriteriaConfigPanel && editEvaluationCriteriaConfigPanel.opened &&
                <EditEvaluationCriterialLateralPanel
                    translate={translate}
                    onCloseButtonClick={onEditEvaluationCriteriaConfigPanel}
                    evaluationCriteria={editEvaluationCriteriaConfigPanel ? editEvaluationCriteriaConfigPanel.evaluationCriteria : null}
                    onSaveButtonClick={onUpdateEvaluationCriteria}
                    isSaving={isSaving}
                    measurementunits={measurementunits}
                    bidding={bidding}
                    userProfile={userProfile}
                    userRolesByCompany={userRolesByCompany}
                />
            }
            {
                uploadAttachmentConfigPanel && uploadAttachmentConfigPanel.opened &&
                <AddAttachmentLateralPanel
                    title={translate("biddingsModule.addMaterials")}
                    onSaveButtonClick={onImportMaterialsFileInBidding}
                    onCloseButtonClick={onOpenCloseUploadAttachmentsLateralPanel}
                    acceptedFiles={['XLSX']}
                    maxFiles={1}
                    isSaving={isSaving}
                    onDeleteAttachment={onDeleteAttachmentSupplier}
                    allAttachments={[]}
                    isVisibleTemplate={true}
                    titleTemplate={translate("biddingsModule.downloadFormatMaterial")}
                    templateFile={downloadTemplateRepare}
                    children={
                        <>
                            {
                                bidding && bidding.Items.length !== 0 &&
                                <WarningComponent
                                    title={translate("biddingsModule.warning")}
                                    message={translate("biddingsModule.reemplazeMaterialToImport")}
                                />
                            }
                        </>
                    }
                />
            }

            {
                editEvaluationCriteriaGroupConfigPanel && editEvaluationCriteriaGroupConfigPanel.opened &&
                <EditCriteriaGroupLateralPanel
                    translate={translate}
                    onCloseButtonClick={onEditEvaluationCriteriaGroupConfigPanel}
                    evaluationCriteria={editEvaluationCriteriaGroupConfigPanel ? editEvaluationCriteriaGroupConfigPanel.evaluationCriteria : null}
                    onSaveButtonClick={onUpdateEvaluationCriteriaGroup}
                    isSaving={isSaving}
                    bidding={bidding}
                />
            }
            {
                confirmPasswordConfigModal && confirmPasswordConfigModal.opened && <ConfirmPasswordDialog
                    open={confirmPasswordConfigModal.opened}
                    title={<Translate id='common.confirmOperation' />}
                    message1={getMessageByIdentifier()}
                    message2={<Translate id='common.enterPassword' />}
                    onClickButtonConfirm={getCallBackToInvokeByIndentifier()}
                    onClose={onConfirmPassword}
                    isSaving={isSaving}
                    isVisibleComments={getIsRequiredComments()}
                    commentRequired={getIsRequiredComments()}
                />
            }
            {
                addWeighingConfigPanel && addWeighingConfigPanel.opened &&
                <AddWeighingLateraPanel
                    bidding={bidding}
                    translate={translate}
                    item={addWeighingConfigPanel.item}
                    onCloseButtonClick={onAddWeighingConfigPanel}
                    addEvaluationResponsible={addEvaluationResponsible}
                    isSaving={isSaving}
                />
            }
            {
                addExchangeRatesConfigPanel &&
                addExchangeRatesConfigPanel.opened &&
                <AddExchangeRatesLateralPanel
                    onCloseButtonClick={onOpenCloseExchangeRatesLateralPanel}
                    onSaveButtonClick={onConfirmPassword}
                    exchangeRates={(bidding && bidding.ExchangeRates) ? bidding.ExchangeRates : []}
                    isSaving={isSaving}

                />
            }

            {
                uploadMaterialsPricesConfigPanel && uploadMaterialsPricesConfigPanel.opened &&
                <AddAttachmentLateralPanel
                    title={translate("biddingsModule.importPricesAndAnswers")}
                    onSaveButtonClick={onImportMaterialPricesBySupplier}
                    onCloseButtonClick={onUploadMaterialsPricesConfigPanelLateralPanel}
                    acceptedFiles={['excel']}
                    maxFiles={1}
                    isSaving={isSaving}
                    allAttachments={[]}
                    subtitle={translate("biddingsModule.downloadListMaterialWIthItems")}
                />
            }

            {
                addResponseItemSupplierConfigPanel && addResponseItemSupplierConfigPanel.opened &&
                <AddSupplierResponseByItemLateralPanel
                    selectedItem={(bidding && bidding.Items) ? bidding.Items.find(x => x.Id === addResponseItemSupplierConfigPanel.item.ItemId) : null}
                    bidding={bidding}
                    userProfile={userProfile}
                    userRolesByCompany={userRolesByCompany}
                    onAddResponseSupplier={onAddResponseSupplier}
                    onCloseButtonClick={onAddResponseItemSupplierConfigPanel}
                    isExternalUser={isExternalUser}
                    isSaving={isSaving}
                    onSaveButtonClick={onAddMaterialResponsebySupplier}
                    divisas={divisas}
                    incoterms={incoterms}
                    translate={translate}
                />
            }
            {
                addResponseGralSupplierConfigPanel && addResponseGralSupplierConfigPanel.opened &&
                <AddSupplierResponseGralLateralPanel
                    bidding={bidding}
                    userProfile={userProfile}
                    userRolesByCompany={userRolesByCompany}
                    onAddResponseSupplier={onAddResponseSupplier}
                    onCloseButtonClick={onAddResponseGralSupplierConfigPanel}
                    isExternalUser={isExternalUser}
                    isSaving={isSaving}
                    selectedItem={addResponseGralSupplierConfigPanel.item}
                    translate={translate}
                />
            }

            {
                addWinnerConfigPanel && addWinnerConfigPanel.opened &&
                <AddWinnersLateralPanel
                    item={addWinnerConfigPanel.item}
                    translate={translate}
                    bidding={bidding}
                    userProfile={userProfile}
                    onCloseButtonClick={onAddWinnerConfigPanel}
                    onAddWinnerInItemFromBackend={onAddWinnerInItemFromBackend}
                    isSaving={isSaving}
                />
            }
            {
                addCommentsSupplierConfigPanel && addCommentsSupplierConfigPanel.opened &&
                <AddCommentsSuppliersLateralPanel
                    translate={translate}
                    bidding={bidding}
                    onCloseButtonClick={onAddCommentsSupplierConfigPanel}
                    onSaveButtonClick={onAddFinishedCommentsInSupplier}
                    isSaving={isSaving}
                    supplier={addCommentsSupplierConfigPanel ? addCommentsSupplierConfigPanel.supplier : null}
                    userProfile={userProfile}
                    userRolesByCompany={userRolesByCompany}
                />
            }
            {
                uploadAdditionalCostsConfigPanel && uploadAdditionalCostsConfigPanel.opened &&
                <AddAttachmentLateralPanel
                    title={translate("biddingsModule.importAdditionalCosts")}
                    onSaveButtonClick={onImportAdditionalCostFile}
                    onCloseButtonClick={onUploadAdditionalCostsConfigPanel}
                    acceptedFiles={['XLSX']}
                    maxFiles={1}
                    isSaving={isSaving}
                    onDownloadTemplateFromBackend={onDownloadAdditionalCostTemplate}
                    allAttachments={[]}
                    titleTemplate={translate("biddingsModule.downloadOtherGastImport")}
                    subtitle={translate("biddingsModule.amountOtherGast")}
                />
            }
            {
                sendEmailConfigPanel && sendEmailConfigPanel.opened &&
                <SendEmailsToSuppliersLateralPanel
                    translate={translate}
                    bidding={bidding}
                    onCloseButtonClick={onSendEmailConfigPanel}
                    supplier={sendEmailConfigPanel ? sendEmailConfigPanel.supplier : null}
                    onSaveButtonClick={onResendEmailToSupplier}
                    isSaving={isSaving}
                    userRolesByCompany={userRolesByCompany}
                />
            }
            <Toaster
                message={<Translate id={toasterMessageConfig.toasterMessage} />}
                open={toasterMessageConfig.showToaster}
                variant={toasterMessageConfig.toasterVariant}
                onClose={() => {
                    setToasterMessageConfig({
                        showToaster: false,
                        toasterMessage: "",
                        toasterVariant: "",
                    })
                }}
            />

        </>
    )
}

export default Show