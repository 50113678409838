import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import { CircularProgress, Fab, Tooltip } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/**
 * Download button component.
 */
const ButtonDownload = (props) => {
	const { tooltipTitle, showCircularProgress, icon, onClick } = props

	return (
		<Tooltip title={tooltipTitle}>
			<div style={styles.wrapper}>
				<Fab onClick={onClick} style={styles.root} disabled={showCircularProgress} color='primary'>
					{{ true: icon, false: <DownloadIcon /> }[!!icon]}
				</Fab>
				{showCircularProgress && <CircularProgress style={styles.circularProgress} />}
			</div>
		</Tooltip>
	)
}

const styles = {
	root: {
		boxShadow: 'none',
		height: 35,
		width: 35,
	},
	wrapper: {
		margin: 'spacing',
		position: 'relative',
	},
	circularProgress: {
		height: 35,
		width: 35,
		color: 'green',
		position: 'absolute',
		left: 0,
		zIndex: 1,
	},
}

ButtonDownload.propTypes = {
	tooltipTitle: PropTypes.node.isRequired,
	showCircularProgress: PropTypes.bool,
	icon: PropTypes.element,
	onClick: PropTypes.func.isRequired,
}

ButtonDownload.defaultProps = {
	tooltipTitle: <Translate id='common.download' />,
	showCircularProgress: false,
	onClick: () => console.warn('onClick is not defined!'),
}

export default ButtonDownload
