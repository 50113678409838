import React from 'react'
import { Translate } from 'react-localize-redux'
/** Material UI imports section */
import { 
	Typography, 
	withStyles, 
	Button, 
	TextField, 
	FormControl, 
	FormControlLabel, 
	FormGroup, 
	Checkbox,
} from '@material-ui/core'
import ProspectSupplierIncon from '@material-ui/icons/LocalShipping'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import WarningComponent from '../../../common/WarningComponent'
import { CalendarToday, LocationOn } from '@material-ui/icons'
import moment from 'moment'
import InputAdornment from '@material-ui/core/InputAdornment'
import { TransportTendeStatus } from '../../../../store/helpers/AppConstants'
const CarrierInformationPanel = (props) => {
	const {
		classes,
		onClose,
		onSubmit,
		information,
		setInformation,
		transportTenderinfoDetail,
		isTypeTransportTrailer,
		isLoading,
	} = props

	const canBeSaved = () => {
		if (information.driverName === '') return true
		if (information.driverPhoneNumber === '') return true
		if (information.bearingPlates === '') return true
		if (information.newAmount === '') return true
		if (information.economicalBox === '' && isTypeTransportTrailer) return true
		if (information.economicUnit === '' && isTypeTransportTrailer) return true
		if (information.platesBox === '' && isTypeTransportTrailer) return true
		return false
	}
	const onChange = (event) => {
		const { name, value, checked } = event.target
		if(name === 'existTrackLink')
			setInformation({
				...information,
				[name]: checked,
			})
		else
			setInformation({
				...information,
				[name]: value,
			})
	}

	const onValidate = (event) => {
		onChange(event)
	}
	const haveAnyAppoimentInDestinations = transportTenderinfoDetail.Destinations.some(
		(destination) => destination.BoardingAppointmentDate && destination.BoardingAppointmentDate.length > 0
	)
	let isDisabledOption =
		transportTenderinfoDetail &&
		transportTenderinfoDetail.Status === TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER

	return (
		<LateralPanel onClose={onClose}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id='carrierTenders.carrierInformation.title' />}
				onCloseButtonClick={onClose}
				icon={<ProspectSupplierIncon color='secondary' />}
			/>
			<div className={classes.bodyContainer}>
				<div className={classes.helperDateContent}>
					<div className={classes.helperIconDate}>
						<CalendarToday />
					</div>
					<div>
						<div>
							<Typography variant='subtitle1'>
								<Translate id='carrierTenders.carrierInformation.appointmentDate.info.date' />
								{`: ${
									transportTenderinfoDetail.DateServiceRequest
										? moment(transportTenderinfoDetail.DateServiceRequest).format('DD/MM/YYYY')
										: '-'
								}`}
							</Typography>
						</div>
						<div>
							<Typography variant='caption'>
								<Translate id='carrierTenders.carrierInformation.appointmentDate.info.time' />
								{` ${
									transportTenderinfoDetail.StartTime
										? moment(transportTenderinfoDetail.StartTime).format('HH:mm A')
										: '-'
								}hrs y ${
									transportTenderinfoDetail.EndTime
										? moment(transportTenderinfoDetail.EndTime).format('HH:mm A')
										: '-'
								}hrs`}
							</Typography>
						</div>
					</div>
				</div>
				{haveAnyAppoimentInDestinations && (
					<div className={classes.titleDateInfo}>
						<Typography variant='subtitle1'>
							<Translate id='carrierTenders.carrierInformation.appointmentDate.titleAppoiment' />:
						</Typography>
					</div>
				)}
				{transportTenderinfoDetail.Destinations.map((destination) => {
					const transportZone = destination.TransportZone
					if (destination.BoardingAppointmentDate && destination.BoardingAppointmentDate.length > 0) {
						return (
							<div className={classes.dateInfoContent}>
								<div className={classes.dateInfoIcon}>
									<LocationOn />
								</div>
								<div>
									<Typography variant='subtitle1'>
										{' '}
										{`${transportZone.Direction} | ${moment(
											destination.BoardingAppointmentDate
										).format('DD/MM/YYYY')} a las ${moment(
											destination.BoardingAppointmentDate
										).format('HH:mm')}hrs`}
									</Typography>
								</div>
							</div>
						)
					}
					return null
				})}
				<div className={classes.title}>
					<Typography variant='subtitle1'>
						{<Translate id='carrierTenders.carrierInformation.driverInformation' />}
					</Typography>
				</div>

				<div className={classes.title}>
					<Typography variant='subtitle2'>
						{<Translate id='carrierTenders.carrierInformation.driverName' />}
					</Typography>
				</div>
				<TextField
					id='standard-with-placeholder'
					placeholder='Escribe el nombre del chofer'
					onChange={onValidate}
					name='driverName'
					style={classes.textField}
					inputProps={{
						style: {
							height: '10px',
						},
					}}
					size='small'
					margin='dense'
					fullWidth
					variant='outlined'
					value={information.driverName}
					error={information.driverName.length < 4}
					helperText={
						information.driverName.length < 4 ? 'El nombre debe ir completo con nombre y apellidos' : ''
					}
				/>
				<div className={classes.title}>
					<Typography variant='subtitle2'>
						{<Translate id='carrierTenders.carrierInformation.driverPhoneNumber.title' />}
					</Typography>
				</div>
				<TextField
					id='standard-with-placeholder'
					placeholder='Escribe el teléfono del chofer'
					onChange={onValidate}
					name='driverPhoneNumber'
					style={classes.textField}
					inputProps={{
						style: {
							height: '10px',
						},
					}}
					size='small'
					margin='dense'
					fullWidth
					value={information.driverPhoneNumber}
					variant='outlined'
					error={!/^\d{10}$/.test(information.driverPhoneNumber)}
					helperText={
						!/^\d{10}$/.test(information.driverPhoneNumber) ? (
							<Translate id='carrierTenders.carrierInformation.driverPhoneNumber.error' />
						) : (
							''
						)
					}
				/>
				<div className={classes.title}>
					<Typography variant='body1'>
						{<Translate id='carrierTenders.carrierInformation.transportationInformation' />}
					</Typography>
				</div>
				<div className={classes.title}>
					<Typography variant='subtitle2'>
						{<Translate id='carrierTenders.carrierInformation.bearingPlates' />}
					</Typography>
				</div>
				<TextField
					id='standard-with-placeholder'
					placeholder='Escribe las placas del transporte'
					onChange={onValidate}
					name='bearingPlates'
					style={classes.textField}
					inputProps={{
						style: {
							height: '10px',
						},
					}}
					size='small'
					margin='dense'
					fullWidth
					variant='outlined'
					value={information.bearingPlates}
					error={!(information.bearingPlates.length > 3)}
					helperText={!(information.bearingPlates.length > 3) ? 'Favor de ingresar una placa válida' : ''}
				/>
				{transportTenderinfoDetail.OpenRateTender && (
					<div>
						<div className={classes.title}>
							<Typography variant='body1'>{'Costo de embarque sugerido por transportista '}</Typography>
						</div>
						<div className={classes.title}>
							<Typography variant='subtitle2'>{'Escribe el nuevo monto'}</Typography>
						</div>
						<TextField
							id='standard-with-placeholder'
							placeholder='Escribe el nuevo monto'
							onChange={onChange}
							name='newAmount'
							value={information.newAmount}
							style={classes.textField}
							InputProps={{
								startAdornment: <InputAdornment position='start'>$</InputAdornment>,
							}}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							type='number'
							disabled={isDisabledOption}
						/>
					</div>
				)}

				<div className={classes.title}>
					<Typography variant='subtitle2'>
						{<Translate id='carrierTenders.carrierInformation.comments' />}
					</Typography>
				</div>

				<TextField
					id='standard-with-placeholder'
					placeholder='Escribe comentarios'
					onChange={onChange}
					name='comments'
					style={classes.textField}
					rowsMax={5}
					multiline
					margin='dense'
					fullWidth
					value={information.comments}
					variant='outlined'
				/>
				{isTypeTransportTrailer && (
					<div>
						<div className={classes.title}>
							<Typography variant='subtitle2'>
								{<Translate id='carrierTenders.carrierInformation.economicUnit' />}
							</Typography>
						</div>

						<TextField
							id='standard-with-placeholder'
							placeholder='Escribe el número económico de la unidad'
							onChange={onValidate}
							name='economicUnit'
							style={classes.textField}
							inputProps={{
								style: {
									height: '10px',
								},
							}}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							value={information.economicUnit}
							error={information.economicUnit.length < 3}
							helperText={
								information.economicUnit.length < 3
									? 'Escribe un número económico de la unidad válido'
									: ''
							}
						/>
						<div className={classes.title}>
							<Typography variant='subtitle2'>
								{<Translate id='carrierTenders.carrierInformation.platesBox' />}
							</Typography>
						</div>

						<TextField
							id='standard-with-placeholder'
							placeholder='Escribe la placa de la caja'
							onChange={onValidate}
							name='platesBox'
							style={classes.textField}
							inputProps={{
								style: {
									height: '10px',
								},
							}}
							size='small'
							margin='dense'
							fullWidth
							value={information.platesBox}
							variant='outlined'
							error={!(information.platesBox.length > 3)}
							helperText={!(information.platesBox.length > 3) ? 'Favor de ingresar una placa válida' : ''}
						/>

						<div className={classes.title}>
							<Typography variant='subtitle2'>
								{<Translate id='carrierTenders.carrierInformation.economicalBox' />}
							</Typography>
						</div>

						<TextField
							id='standard-with-placeholder'
							placeholder='Escribe el número económico de la caja'
							onChange={onValidate}
							name='economicalBox'
							style={classes.textField}
							inputProps={{
								style: {
									height: '10px',
								},
							}}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							value={information.economicalBox}
							error={!(information.economicalBox.length > 3)}
							helperText={
								!(information.economicalBox.length > 3)
									? 'Escribe un número económico de la caja válido'
									: ''
							}
						/>
					</div>
				)}
				<div>
					<FormControl component="fieldset" className={classes.formControl}>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox checked={information.existTrackLink} onChange={onChange} name="existTrackLink" />}
									label={information.existTrackLink 
										? <Translate id='carrierTenders.carrierInformation.unitWithLink' /> 
										: <Translate id='carrierTenders.carrierInformation.unitWithoutLink' />}
								/>
								<WarningComponent
									message={information.existTrackLink 
										? <Translate id='carrierTenders.carrierInformation.withLinkNote' />
										: <Translate id='carrierTenders.carrierInformation.withoutLinkNote' />}
                				/>
							</FormGroup>
					</FormControl>
				</div>
				<div className={classes.title}>
					<Typography variant='subtitle2'>
						{<Translate id='carrierTenders.carrierInformation.link' />}
					</Typography>
				</div>

				<TextField
					id='standard-with-placeholder'
					placeholder='Escribe el link'
					onChange={onValidate}
					name='league'
					style={classes.textField}
					inputProps={{
						style: {
							height: '10px',
						},
					}}
					size='small'
					margin='dense'
					fullWidth
					variant='outlined'
					value={information.league}
				/>

				<div className={classes.title}>
					<Typography variant='subtitle2'>
						{<Translate id='carrierTenders.carrierInformation.user' />}
					</Typography>
				</div>
				<TextField
					id='standard-with-placeholder'
					onChange={onValidate}
					name='userName'
					style={classes.textField}
					inputProps={{
						style: {
							height: '10px',
						},
					}}
					size='small'
					margin='dense'
					fullWidth
					variant='outlined'
					value={information.userName}
				/>
				<div className={classes.title}>
					<Typography variant='subtitle2'>
						{<Translate id='carrierTenders.carrierInformation.password' />}
					</Typography>
				</div>
				<TextField
					id='standard-with-placeholder'
					onChange={onValidate}
					name='password'
					style={classes.textField}
					inputProps={{
						style: {
							height: '10px',
						},
					}}
					size='small'
					margin='dense'
					fullWidth
					variant='outlined'
					value={information.password}
				/>
			</div>
			<Button
				data-tut='reactour-save'
				data-cy='btn-save'
				type='button'
				color='primary'
				variant='outlined'
				className={isLoading ? null : classes.btnCreate}
				onClick={onSubmit}
				disabled={canBeSaved() || isLoading}
			>
				{<Translate id='carrierTenders.deals.addCarrier' />}
			</Button>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	bodyContainer: {
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
	},

	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	title: {
		marginTop: '5px',
	},
	flex: {
		marginTop: '5px',
		display: 'flex',
		justifyContent: 'space-between',
	},
	ckeckBox: {
		marginTop: '-15px',
	},
	helperDateContent: {
		backgroundColor: theme.palette.colors.blue,
		color: theme.palette.primary.dark,
		display: 'flex',
		borderRadius: '3px',
		padding: '8px',
	},
	helperIconDate: {
		paddingRight: '8px',
		color: theme.palette.primary.dark,
	},
	titleDateInfo: {
		margin: '8px 0px',
	},
	dateInfoIcon: {
		paddingRight: '8px',
	},
	dateInfoContent: {
		display: 'flex',
		backgroundColor: theme.palette.colors.green,
		borderRadius: '3px',
		padding: '8px',
		margin: '8px 0px',
	},
})
export default withStyles(styles, { withTheme: true })(CarrierInformationPanel)
