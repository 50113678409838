import { fromJS, Map } from 'immutable';

import * as materialDeliveryActions from './MaterialDeliveryAction';

/**
 * materialDelivery Actions initial state
 */
export const materialDeliveriesInitialState = fromJS({
    isLoadingMaterialDeliveries: true,
    materialDeliveries: null,
    isLoadingMaterialDelivery: false,
    materialDelivery: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    materialDeliveriesCount: null,
    isUpdatingPlaneName: false,
    isSaving: false,
    materialDeliveryInformation: null,
})

export default function materialDeliveryReducer(state = materialDeliveriesInitialState, action) {
    switch (action.type) {
        case materialDeliveryActions.START_LOADING_MATERIAL_DELIVERIES: {
            return state.merge({
                isLoadingMaterialDeliveries: action.isLoading
            })
        }
        case materialDeliveryActions.SET_MATERIAL_DELIVERIES_LIST: {
            let deliveriesLists = fromJS(action.deliveries);
            return state.merge({
                materialDeliveries: deliveriesLists,
                isLoadingMaterialDeliveries: false
            })
        }
        case materialDeliveryActions.IS_LOADING_MATERIAL_DELIVERY: {
            return state.merge({
                isLoadingMaterialDelivery: action.isLoadingMaterialDelivery,
                materialDeliveryInformation: null,
                materialDelivery: null,
            })
        }
        case materialDeliveryActions.SET_MATERIAL_DELIVERY: {
            return state.merge({
                isLoadingMaterialDelivery: false,
                materialDeliveryInformation: fromJS(action.materialDelivery)
            })
        }
        case materialDeliveryActions.CHANGE_MATERIAL_DELIVERIES_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case materialDeliveryActions.CHANGE_MATERIAL_DELIVERIES_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case materialDeliveryActions.CHANGE_MATERIAL_DELIVERIES_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case materialDeliveryActions.SET_MATERIAL_DELIVERIES_COUNT: {
            return state.merge({
                materialDeliveriesCount: action.count
            })
        }
        case materialDeliveryActions.GET_INFORMATION_MATERIAL_DELIVERY: {
            return state.merge({
                isLoadingMaterialDelivery: false,
                materialDelivery: fromJS(action.materialDelivery)
            })
        }
        case materialDeliveryActions.SET_MATERIAL_ITEM_SELECTED: {
            let items = state.get('materialDelivery').get('details').map((materialItem) => {
                if (materialItem.get('token') === action.materialItemToken) {
                    return materialItem.set('is_selected', true)
                } else {
                    return materialItem.set('is_selected', false)
                }
            })
            let materialDelivery = state.get('materialDelivery').set('details', items);
            return state.merge({
                materialDelivery
            });
        }
        case materialDeliveryActions.IS_UPDATING_DETAIL_PLANE_NAME: {
            return state.merge({
                isUpdatingPlaneName: action.isUploading
            });
        }
        case materialDeliveryActions.UPDATE_DETAIL_MATERIAL_SCHEMATIC: {
            let items = state.get('materialDelivery').get('details').map((materialItem) => {
                if (materialItem.get('token') === action.detailPlane.token) {
                    return materialItem.set('plot_name', action.detailPlane.plot_name)
                } else {
                    return materialItem
                }
            })
            let materialDelivery = state.get('materialDelivery').set('details', items);
            return state.merge({
                materialDelivery
            });
        }
        case materialDeliveryActions.IS_DELETE_DETAIL_MATERIAL_SCHEMATIC: {
            return state.merge({
                isDeletingPlaneName: action.isDeleting
            });
        }
        case materialDeliveryActions.DELETE_DETAIL_MATERIAL_SCHEMATIC: {
            return state.updateIn(['materialDelivery', 'details'], (allDetails) => {
                let index = allDetails.findIndex((item) => {
                    return item.get('token') === action.deletedToken
                });
                let detailToChange = allDetails.filter(detail => (detail.get('token') === action.deletedToken))
                detailToChange.set('plot_name', '');
                return allDetails.setIn([index], detailToChange).set('isDeleting', false);
            });
        }
        case materialDeliveryActions.REMOVE_SUPPLIER_FROM_MATERIAL_DELIVERY: {
            return state.updateIn(['materialDelivery', 'details'], (allDetails) => {
                let index = allDetails.findIndex((detail) => {
                    return detail.get('token') === action.materialItemToken
                });
                let detailToChange = allDetails.get(index);
                let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
                    return quoteMap.get('supplier_token') === action.supplierToken
                })
                let updatedQuoteList = detailToChange.get('quotes').delete(quoteIndex);
                let updatedDetail = detailToChange.set('quotes', updatedQuoteList);
                return allDetails.setIn([index], updatedDetail);
            });
        }
        case materialDeliveryActions.ADD_SUPPLIER_FROM_MATERIAL_DELIVERY: {
            let quoteToAdd = Map(action.quote);
            return state.updateIn(['materialDelivery', 'details'], (allDetails) => {
                let index = allDetails.findIndex((detail) => {
                    return detail.get('token') === action.materialItemToken
                });
                let detailToChange = allDetails.get(index);
                let updatedQuoteList = detailToChange.get('quotes').push(quoteToAdd);
                let updatedDetail = detailToChange.set('quotes', updatedQuoteList);
                return allDetails.setIn([index], updatedDetail);
            });
        }
        case materialDeliveryActions.ADD_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL: {
            return state.updateIn(['materialDelivery', 'details'], (allDetails) => {
                let index = allDetails.findIndex((detail) => {
                    return detail.get('token') === action.materialItemToken
                });
                let detailToChange = allDetails.get(index);
                let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
                    return quoteMap.get('supplier_token') === action.supplierToken
                })

                let updatedQuoteList = detailToChange.get('quotes');
                let updateQuote = updatedQuoteList.get(quoteIndex);
                updateQuote = updateQuote.set('is_sample_requiered', true);

                updatedQuoteList = updatedQuoteList.setIn([quoteIndex], updateQuote)
                let updatedDetail = detailToChange.set('quotes', updatedQuoteList);
                return allDetails.setIn([index], updatedDetail);
            });
        }
        case materialDeliveryActions.ADD_RMI_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL: {
            return state.updateIn(['materialDelivery', 'details'], (allDetails) => {
                let index = allDetails.findIndex((detail) => {
                    return detail.get('token') === action.materialItemToken
                });
                let detailToChange = allDetails.get(index);
                let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
                    return quoteMap.get('supplier_token') === action.supplierToken
                })

                let updatedQuoteList = detailToChange.get('quotes');
                let updateQuote = updatedQuoteList.get(quoteIndex);
                updateQuote = updateQuote.set('is_rmi_requiered', action.rmi);

                updatedQuoteList = updatedQuoteList.setIn([quoteIndex], updateQuote)
                let updatedDetail = detailToChange.set('quotes', updatedQuoteList);
                return allDetails.setIn([index], updatedDetail);
            });
        }
        case materialDeliveryActions.REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL: {
            return state.updateIn(['materialDelivery', 'details'], (allDetails) => {
                let index = allDetails.findIndex((detail) => {
                    return detail.get('token') === action.materialItemToken
                });
                let detailToChange = allDetails.get(index);
                let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
                    return quoteMap.get('supplier_token') === action.supplierToken
                })

                let updatedQuoteList = detailToChange.get('quotes');
                let updateQuote = updatedQuoteList.get(quoteIndex);
                updateQuote = updateQuote.set('is_sample_requiered', false);

                updatedQuoteList = updatedQuoteList.setIn([quoteIndex], updateQuote)
                let updatedDetail = detailToChange.set('quotes', updatedQuoteList);
                return allDetails.setIn([index], updatedDetail);
            });
        }
        case materialDeliveryActions.SEND_QUOTES_REQUEST_MATERIAL_DELIVERY: {
            return state.merge({
                isSaving: action.isSaving
            });
        }
        case materialDeliveryActions.UPDATE_MATERIAL_DELIVERY: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case materialDeliveryActions.FINALIZE_MATERIAL_DELIVERY: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case materialDeliveryActions.UPDATE_COMMENTS_MATERIAL_DELIVERY: {
            let items = state.get('materialDelivery').get('details').map((item) => {
                if (item.get('token') === action.partNumberDetail.token) {
                    return item.set('comments', action.partNumberDetail.comments)
                } else {
                    return item
                }
            })
            let materialDelivery = state.get('materialDelivery').set('details', items);
            return state.merge({
                materialDelivery
            });
        }
        case materialDeliveryActions.ADD_FILE_MATERIAL_DELIVERY: {
            let detailsList = state.getIn(['materialDelivery', 'details']).toJS();
            let indexDetail = detailsList.findIndex(detail => {
                return detail.token === action.tokenPartNumber
            })
            let oldList = state.getIn(['materialDelivery', 'details', indexDetail, 'attachments']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['materialDelivery', 'details', indexDetail, 'attachments'], fromJS(newList));
        }
        case materialDeliveryActions.REMOVE_FILE_MATERIAL_DELIVERY: {
            let detailsList = state.getIn(['materialDelivery', 'details']).toJS();
            let indexDetail = detailsList.findIndex(detail => {
                return detail.token === action.tokenPartNumber
            })
            let oldList = state.getIn(['materialDelivery', 'details', indexDetail, 'attachments']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['materialDelivery', 'details', indexDetail, 'attachments'], fromJS(newList));
        }
        default:
            return state;
    }
}