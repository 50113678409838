/**Import react section */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/**Import MaterialUi section */
import {
    Button, Grid, Typography
} from '@material-ui/core'
/**Import components section */
import HtmlEditor from "../../../common/HtmlEditor";
/**custom hooks */
import { canBeEditInStatusSendToEvaluate } from "../../../../store/helpers/IndirectQualificationsHelpers";

import { useIndirectQualificationCustomHookShow } from '../../customHooks/UseCustomHookShow'


const SupplierCommentsLateralComponent = (props) => {
    const { onSaveButtonClick, classes } = props
    const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'))

    const [supplier, setSupplier] = useState(null)
    const { user, indirectQualification } = useIndirectQualificationCustomHookShow()

    /**Use effect section */
    useEffect(() => {
        if (props.supplier && supplier === null) {
            setSupplier(props.supplier)
        }
    }, [props.supplier])


    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        // if (attachments.length === 0) return false
        return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(supplier)
        }
    }

    const onChange = (value) => {
        setSupplier({
            ...supplier,
            "Comments": value
        })
    }

    let canBeEdited = !(canBeEditInStatusSendToEvaluate(indirectQualification.Status) && (user && supplier && supplier.ResponsibleUserName === user.profile.username))
    return (
        <div>
            <div className={classes.containerInformation}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography color='primary' className={classes.marginLeft}>{`Comentario del área ${supplier ? supplier.ResponsibleArea : ""} para el proveedor sobre su calificación.`}</Typography>
                    </Grid>
                     <Grid item xs={12}>
                        <br/>
                        <Typography className={classes.marginLeft}>{<Translate id="indirectqualifications.show.scaleCommentsTitle" />}</Typography>
                        <br/>
                    </Grid>
                    <Grid item xs={12}>
                        <HtmlEditor
                            id={"id-modeCreate"}
                            labelVariant="subtitle1"
                            initialValue={supplier ? supplier.Comments : ""}
                            isDisabled={canBeEdited}
                            onEditorChange={onChange}

                        /><br/><br/>
                    </Grid>
                </Grid>
            </div>

            {
                !canBeEdited && <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            className={classes.widthBtnSaved}
                            disabled={!canBeSaved() || isSaving || canBeEdited}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>
            }
        </div>

    )

}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${165}px)`,
        minHeight: `calc(100vh - ${165}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    marginTop: {
        marginTop: "10px",
    },
    marginLeft: {
        marginLeft: "6px",
        marginTop: "5px",

    },
    widthBtnSaved: {
        minWidth: "400px"
    }
});

SupplierCommentsLateralComponent.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

SupplierCommentsLateralComponent.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(SupplierCommentsLateralComponent))