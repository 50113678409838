import React, { Component } from 'react'
import autoBind from 'auto-bind'
import PropTypes from 'prop-types'

/** Material-UI imports section */
import Paper from '@material-ui/core/Paper'
import { IconButton, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Grid from '@material-ui/core/Grid'
import { CloudDownload, DeleteOutline } from '@material-ui/icons'
/**
 * Component ot render history partial delivery order
 */
export default class FilesComponent extends Component {
    /**
     * Create an instance delivery order
     * @param {*} props
     */
    constructor(props) {
        super(props)
        autoBind(this)
    }


    handleClickChip(event) {
        if (this.props.onDownloadFile) {
            this.props.onDownloadFile(event)
        }
    }

    /**
     * Render
     */
    render() {
        const { files, onDeleteFile, folderName, onDownloadFile, isSaving } = this.props

        return (
            <List>
                <Grid container spacing={24}>
                    {files.map(file => {
                        return (
                            <Grid item xs={4}>
                                <Paper elevation={2} style={{ backgroundColor: "#f8f9fa" }}>

                                    <ListItem>
                                        <ListItemText
                                            primary={<Typography style={{maxWidth: '300px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                        }}>{file.name}</Typography> }
                                        />
                                        <ListItemSecondaryAction>
                                            <>
                                                {onDeleteFile &&
                                                    <IconButton
                                                        disabled={isSaving}
                                                        onClick={() => onDeleteFile(file.name, folderName)}
                                                    >
                                                        <DeleteOutline />

                                                    </IconButton>}
                                                {onDownloadFile && <IconButton
                                                    onClick={() => onDownloadFile(file.name, folderName, file.ext)}
                                                    disabled={isSaving}

                                                >
                                                    <CloudDownload />
                                                </IconButton>}
                                            </>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Paper>

                            </Grid>
                        )
                    })}
                </Grid>
            </List>
        )
    }
}

/**
 * deliveryOrder
 */
FilesComponent.propTypes = {
    files: PropTypes.array.isRequired,
}
