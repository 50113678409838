import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import LinkMaterial from '@material-ui/core/Link';
/** cons section import */
import { Typography, Card, CardContent, CardHeader, Avatar } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { COLOR_STATUS_DANGER, COLOR_STATUS_WARNING } from '../../store/helpers/StatusColorConstants';

/**
 * Class compoenent to add an portal
 *
 * @class PendingOutsorcing
 * @extends {Component}
 */
class PendingOutsorcing extends Component {

    /**
   *Creates an instance of PendingOutsorcing.
   * @param {*} props
   * @memberof DialogActions
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {}
    }

    render() {
        const { classes, notificationsSourcing } = this.props;
        let isUserLock = !notificationsSourcing.IsLocked;
        return (
            <Card
                className={isUserLock ? classes.cardInfo : classes.cardError}
                role="alert">
                <CardHeader
                    avatar={
                        <Avatar aria-label="Important" className={classes.avatar}>
                            <InfoIcon color='error' />
                        </Avatar>
                    }
                    title={<span>
                        {isUserLock ? <strong>{"ALERTA: Usted tiene notificaciones pendientes en el portal Outsourcing"}</strong> :
                            <strong>{"EN INCUMPLIMIENTO: Usted tiene notificaciones pendientes en el portal Outsourcing"}</strong>
                        }
                    </span>}
                    subheader={
                        <Typography variant='subtitle2' className={isUserLock ? classes.textBack : classes.textWhile}>
                            {"Estimado proveedor, se le notifica que se han identificado cargas de información pendientes en el portal de empresas de servicios especializados de AlPunto."}
                            {!isUserLock && <strong >     <br />{"PROVEEDOR BLOQUEADO EN SAP PARA PAGOS"}</strong>}
                        </Typography>
                    }
                />
                <CardContent>
                    <Typography className={isUserLock ? classes.textBack : classes.textWhile} variant="body2" color="textSecondary" component="p">
                        {"Nota: Es importante que ingrese al portal de empresas de servicios especializados AlPunto y realice la carga de información solicitada."}
                        <br />
                        <LinkMaterial target="_blank" href='https://sip.efemsa.com/outsourcing'>
                            <strong className={isUserLock ? "" : classes.textBack}>
                                {"Presione aquí ir al portal de servicios especializados"}
                            </strong>
                        </LinkMaterial>
                    </Typography>
                </CardContent>
            </Card>
        )
    }
}

const styles = theme => ({
    cardInfo: {
        backgroundColor: COLOR_STATUS_WARNING
    },
    cardError: {
        backgroundColor: COLOR_STATUS_DANGER
    },
    textWhile: {
        color: "white"
    },
    textBack: {
        color: "black"
    },
});

export default withRouter(withStyles(styles, { withTheme: true })(PendingOutsorcing));