/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'

/**Import MaterialUi section */
import {
    Button, TextField, Grid, Typography
} from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
/**Import components section */

/**Import store section */
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
const CHARACTER_LIMIT_NAME = 100;

const AddItemComponent = (props) => {
    const { onSaveButtonClick, classes, translate
    } = props

    const isSaving = useSelector((state) => state.billings.get('isSaving'))

    const [itemModel, setItemModel] = useState({
        name: "",
        price: 0,
        measurementUnit: ""
    });

    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (IsNullOrEmpty(itemModel.name)) return false
        if (IsNullOrEmpty(itemModel.measurementUnit)) return false
        if (IsNullOrEmpty(itemModel.price)) return false
        return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(itemModel)
        }
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setItemModel({
            ...itemModel,
            [name]: value
        });
    }


    return (
        <div>
            <div className={classes.containerInformation}>
                <Grid container >
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography color="primary">{<Translate id="billings.create.name" />}</Typography>
                        <div className={classes.marginTop}>
                            <TextField
                                fullWidth
                                placeholder={translate("billings.create.name")}
                                className={classes.textField}
                                name='name'
                                size="small"
                                onChange={onPropertyChange}
                                variant="outlined"
                                multiline
                                value={itemModel ? itemModel.name : ""}
                                InputProps={{
                                    inputProps: {
                                        maxLength: CHARACTER_LIMIT_NAME,
                                    }
                                }}
                                helperText={`${itemModel ? itemModel.name.length : ""}/${CHARACTER_LIMIT_NAME}`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" className={classes.marginLeft}>{<Translate id="billings.create.price" />}</Typography>
                        <div className={classes.marginTop}>
                            <CurrencyTextField
                                name='price'
                                variant="outlined"
                                fullWidth
                                value={itemModel ? itemModel.price : ""}
                                currencySymbol="$"
                                outputFormat="string"
                                onChange={onPropertyChange}
                                disabled={isSaving}
                            />

                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography color="primary">{<Translate id="billings.tableItem.measurementUnit" />}</Typography>
                        <div className={classes.marginTop}>
                            <TextField
                                fullWidth
                                placeholder={translate("billings.tableItem.measurementUnit")}
                                className={classes.textField}
                                name='measurementUnit'
                                size="small"
                                onChange={onPropertyChange}
                                variant="outlined"
                                multiline
                                value={itemModel ? itemModel.measurementUnit : ""}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography color="primary">{<Translate id="billings.tableItem.flete" />}</Typography>
                        <div className={classes.marginTop}>
                            <TextField
                                fullWidth
                                placeholder={translate("billings.tableItem.flete")}
                                className={classes.textField}
                                name='measurementUnit'
                                size="small"
                                onChange={onPropertyChange}
                                variant="outlined"
                                multiline
                                value={itemModel ? itemModel.measurementUnit : ""}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Grid container>
                <Grid item xs={12} className={classes.containerBtn}>
                    <Button
                        data-cy="btn-save"
                        type="button"
                        color="primary"
                        variant='outlined'
                        onClick={onSave}
                        className={classes.widthBtnSaved}
                        disabled={!canBeSaved() || isSaving}>
                        {<Translate id="common.save" />}
                    </Button>
                </Grid>
            </Grid>
        </div>

    )

}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${165}px)`,
        minHeight: `calc(100vh - ${165}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    marginTop: {
        marginTop: "10px",
    },
    marginLeft: {
        marginLeft: "6px",
    },
    widthBtnSaved: {
        minWidth: "400px"
    }
});

AddItemComponent.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddItemComponent.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddItemComponent))