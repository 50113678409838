import React, { Component } from 'react'
import autoBind from 'auto-bind'

/** Import component section */
import Evidence from '../../common/Evidence'
/** Translations imports section */
import { Translate } from 'react-localize-redux'
import Select from 'react-select'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ProspectSupplierIncon from '@material-ui/icons/LocalShipping'
import PrivacyIcon from '@material-ui/icons/ChromeReaderMode'

import { getProspectsOptions, getCenterProspects, getNoticeOfPrivacyIMBERA } from '../../../store/helpers/SelectOptions'
import { checkPrivacyNotice } from '../../../store/helpers/NoticeOfPrivacy'
import { COMPANY_IDENTIFIER_IMBERA_MX } from '../../../store/helpers/ResourcesHelper'

/**
 * User card
 *
 * @class UserCard
 * @extends {Component}
 */
class FormCreateCard extends Component {
	constructor(props, context) {
		super(props, context)
		autoBind(this)
	}

	/**
	 * Render
	 */
	render() {
		const { prospect, classes, typesProspects, activeprivacyNotice } = this.props
		const prospectType = getProspectsOptions(typesProspects)
		const centerType = getCenterProspects()
		const noticeOfPrivacyType = getNoticeOfPrivacyIMBERA()

		return (
			<Card style={{ height: '79vh' }}>
				<CardHeader
					avatar={
						<Avatar aria-label='Recipe' className={classes.userAvatar}>
							<ProspectSupplierIncon />
						</Avatar>
					}
					title={<Translate id='prospects.addSupplierPprospectus' />}
				/>
				<CardContent style={{ height: '60vh', overflowY: 'auto' }}>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<Typography variant='caption' color={'textSecondary'}>
								{<Translate id='prospects.prospectName' />}
							</Typography>
							<TextField
								fullWidth
								id='filled-margin-dens'
								label={''}
								value={prospect.prospectName}
								onChange={(event) => {
									this.props.updateProperty('prospectName', event.target.value)
								}}
								margin='dense'
								variant='outlined'
							/>
							<Typography variant='caption' color={'textSecondary'}>
								<Translate id='prospects.selectTypeProspect' />
							</Typography>
							<Select
								value={prospectType ? prospectType['prospectType'] : ''}
								className={classes.documentTypeSelect}
								options={prospectType}
								onChange={(event) => this.props.updateProperty('prospectType', event)}
							/>

							{this.props.selectedCompanyIdentifier === COMPANY_IDENTIFIER_IMBERA_MX && (
								<div>
									<Typography variant='caption' color={'textSecondary'}>
										<Translate id='prospects.selectCenter' />
									</Typography>
									<Select
										value={centerType ? centerType['centerType'] : ''}
										className={classes.documentTypeSelect}
										options={centerType}
										onChange={(event) => this.props.updateProperty('centerType', event)}
									/>
								</div>
							)}
							<Typography variant='caption' color={'textSecondary'}>
								{prospect.prospectType.value !== 'Extranjero' ? (
									<Translate id='prospects.contactName' />
								) : (
									<Translate id='prospects.directorName' />
								)}
							</Typography>
							<TextField
								fullWidth
								id='filled-margin-denses'
								label={''}
								value={prospect.contactName}
								onChange={(event) => {
									this.props.updateProperty('contactName', event.target.value)
								}}
								margin='dense'
								variant='outlined'
							/>
						</Grid>
						<Grid item xs={12}>
							<div>
								{this.props.selectedCompanyIdentifier === COMPANY_IDENTIFIER_IMBERA_MX ? (
									<div>
										<Typography variant='caption' color={'textSecondary'}>
											<Translate id='prospects.selectPrivacyNotice' />
										</Typography>
										<Select
											value={
												noticeOfPrivacyType ? noticeOfPrivacyType['noticeOfPrivacyType'] : ''
											}
											className={classes.documentTypeSelect}
											options={noticeOfPrivacyType}
											onChange={(event) =>
												this.props.updateProperty('noticeOfPrivacyType', event)
											}
											styles={{
												menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
												menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
												menuPortal: (base) => ({ ...base, zIndex: 9999 }),
											}}
											menuPortalTarget={document.parentNode}
											menuPosition='fixed' 
											menuPlacement={'auto'}
										/>

										<div style={{ display: 'flex' }}>
											<PrivacyIcon color='primary' />
											<Typography variant='subtitle1' style={{ marginLeft: '7px' }}>
												<small className='text-muted'>
													<a
														href={checkPrivacyNotice(
															this.props.onChangePrivacyType(),
															localStorage,
															activeprivacyNotice
														)}
														target='_blank'
														rel='noopener noreferrer'
													>
														<Translate id='common.privacyNoticePTM' />
													</a>
												</small>
											</Typography>
										</div>
									</div>
								) : (
									<div style={{ display: 'flex' }}>
										<PrivacyIcon color='primary' />
										<Typography variant='subtitle1' style={{ marginLeft: '7px' }}>
											<small className='text-muted'>
												<a
													href={checkPrivacyNotice(
														this.props.selectedCompanyIdentifier,
														localStorage,
														activeprivacyNotice
													)}
													target='_blank'
													rel='noopener noreferrer'
												>
													<Translate id='common.privacyNoticePTM' />
												</a>
											</small>
										</Typography>
									</div>
								)}
							</div>
							<Typography variant='caption' color={'textSecondary'}>
								<Translate id='prospects.addAdditionalFiles' />
							</Typography>
							<Evidence
								baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
								removeFiles={this.props.removeFiles}
								uploadFiles={this.props.uploadFiles}
								files={prospect.attachments}
								acceptedFiles={['pdf']}
								disabledLink
								text={this.props.dropzoneText}
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions>
					<Grid item xs={12}>
						<Toolbar variant='dense'>
							{this.props.isSaving === true ? (
								<Button variant='outlined' color='secondary' disabled>
									<Translate id='common.saving' />
								</Button>
							) : (
								<Button
									variant='outlined'
									color='secondary'
									onClick={this.props.onCreateContractData}
									disabled={!this.props.canBeSaved}
								>
									<Translate id='common.send' />
								</Button>
							)}
							<Button
								variant='outlined'
								color='primary'
								className={classes.button}
								onClick={() => {
									this.props.history.push(`/prospects`)
								}}
							>
								<Translate id='common.cancel' />
							</Button>
						</Toolbar>
					</Grid>
				</CardActions>
			</Card>
		)
	}
}

export default FormCreateCard
