/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import {Card, CardContent, Fab} from "@material-ui/core";
import { Translate } from 'react-localize-redux';
import DownloadIcon from '@material-ui/icons/CloudDownload';

/**Import components section*/
import PanelRight from './PanelRight'
import HeaderResult from './HeaderResult'
import Recommendations from './Recommendations'

import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from "../PDFs/PdfResult/PdfDocument";

/**Import resources section*/

/**Import actions section */
const ContainerResult = (props) => {
  const { classes, questionnaire, sustainability, totalWorkDetail } = props;
  const {updatePropertyQuestion, canBeEditResults} = props;

    return (
        <Card>
            {sustainability.IsClose &&
            <div className={classes.downloadButton}>
                <PDFDownloadLink
                    document={<PdfDocument
                        topics={questionnaire.Topics}
                        totalRatingSupplier={questionnaire.TotalRatingSupplier} 
                        totalWorkDetail={totalWorkDetail}
                        sustainability={sustainability}
                        />} 
                        fileName={`Resultados - ${sustainability.SupplierCode} | ${sustainability.SupplierName}.pdf`}>
                        {({ blob, url, loading, error }) => (<Fab variant="extended" size="small" color="secondary">
                            <DownloadIcon className={classes.downloadIcon}/>{<Translate id='sustainability.downloadResultReport'/>}
                        </Fab>)}
                    </PDFDownloadLink>
            </div>}
            <CardContent>
                <div className={classes.root} >
                    <div className={classes.panelRight}>
                        <PanelRight 
                            totalRatingSupplier={questionnaire.TotalRatingSupplier}
                            isApproved={sustainability.IsValidAprovedSupplier}
                            totalWorkDetail={totalWorkDetail}
                        />
                    </div>
                    <div className={classes.cardRight}>
                        <HeaderResult 
                            topics={questionnaire.Topics} 
                        />
                        <Recommendations 
                            topics={questionnaire.Topics}
                            updatePropertyQuestion={updatePropertyQuestion}
                            canBeActiveResult={canBeEditResults}
                        />
                    </div>
                </div>
            </CardContent>
        </Card>
    )
};

const styles = (theme) => ({
    root:{
        display: "flex",
    },
    panelRight: {
        marginRight: "10px",
        display: "flex",
    },
    cardRight: {
        marginLeft: "10px",
    },
    contentTittle:{
        width: '100%',
    },
    textField:{
        padding: 3,
    },
    inputProps: {
        height: 6,
    },
    dividerLine:{
        marginTop: "10px",
        marginBottom:"10px"
    },
    titleText:{
        marginLeft: "10px",
    },
    titleOportunity:{
        backgroundColor:"#e0e0e0",
    },
    titleSection:{
        backgroundColor:"#F6F6F6",
    },
    titleSection1:{
        backgroundColor:"#FaFaFa",
    },
    downloadIcon:{
        marginRight: '6px', 
        marginBottom: '4px'
    },
    downloadButton:{
        textAlign: 'right',
        paddingRight: "12px",
    },
    pdfvista: {
        width: '100%',
        height: '500px',
    }
});

ContainerResult.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  questionnaire:PropTypes.object,
};

ContainerResult.defaultProps = {
    isLoading: false,
    questionnaire: {
      Topics: []
    }
};

export default withRouter(
  withStyles(styles, { withTheme: true })(ContainerResult)
);
