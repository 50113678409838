/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'

export const START_LOADING_FAILURE_REPORT_QUALITY = 'START_LOADING_FAILURE_REPORT_QUALITY'
export const SET_FAILURE_REPORTS_QUALITY_LIST = 'SET_FAILURE_REPORTS_QUALITY_LIST'
export const CHANGE_FAILURE_REPORTS_SORT_CRITERIA = 'CHANGE_FAILURE_REPORTS_SORT_CRITERIA'
export const CHANGE_FAILURE_REPORTS_QUERY = 'CHANGE_FAILURE_REPORTS_QUERY'
export const CHANGE_FAILURE_REPORTS_PAGINATION = 'CHANGE_FAILURE_REPORTS_PAGINATION'
export const SET_FAILURE_REPORTS_COUNT = 'SET_FAILURE_REPORTS_COUNT'
export const IS_SAVING = 'IS_SAVING'
export const IS_LOADING = 'IS_LOADING'
export const SET_FAILURE_REPORT_QUALITY = 'SET_FAILURE_REPORT_QUALITY'
export const ADD_EMERGENT_ACTION_TO_LIST = 'ADD_EMERGENT_ACTION_TO_LIST'
export const REMOVE_EMERGENT_ACTION_TO_LIST = 'REMOVE_EMERGENT_ACTION_TO_LIST'
export const ADD_CORRECTIVE_ACTION_TO_LIST = 'ADD_CORRECTIVE_ACTION_TO_LIST'
export const UPDATE_CORRECTIVE_ACTION_TO_LIST = 'UPDATE_CORRECTIVE_ACTION_TO_LIST'
export const ADD_FILE_QUALITY_FAILURE = 'ADD_FILE_QUALITY_FAILURE'
export const REMOVE_FILE_QUALITY_FAILURE = 'REMOVE_FILE_QUALITY_FAILURE'
export const IS_LOADING_ALL_FAILURE_REPORTS = 'IS_LOADING_ALL_FAILURE_REPORTS'

/**
 * Action start loading failure reports quality
 * @param {*} isLoading
 */
function isLoadingFailureReports(isLoading) {
	return {
		type: START_LOADING_FAILURE_REPORT_QUALITY,
		isLoading: isLoading,
	}
}

/**
 * Action to set failure reports quality  list
 * @param {*} rawmaterials
 */
function setfailureRepotsQualityList(failureReports) {
	return {
		type: SET_FAILURE_REPORTS_QUALITY_LIST,
		failureReports: failureReports,
	}
}

/**
 * Action reducer to set the  failure reports quality counter
 *
 * @param {*} count
 * @returns
 */
function setFailureReportsQualityCount(count) {
	return {
		type: SET_FAILURE_REPORTS_COUNT,
		count,
	}
}

/**
 * Action to is saving failure reports quality
 * @param {*} isSaving
 */
function isSaving(isSaving) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
	}
}

/**
 * Action to is loaidng failure reports quality
 * @param {*} isLoading
 */
function isLoading(isLoading) {
	return {
		type: IS_LOADING,
		isLoading: isLoading,
	}
}

/**
 * Action to set failure reports quality
 * @param {*} rawMaterial
 */
function setFailureReportsQuality(failureReport) {
	return {
		type: SET_FAILURE_REPORT_QUALITY,
		failureReport: failureReport,
	}
}

/**
 * Action to add emergent action
 * @param {*} action
 */
function addEmergentAction(action) {
	return {
		type: ADD_EMERGENT_ACTION_TO_LIST,
		emergentAction: action,
	}
}

/**
 * Action to remove emergent action
 * @param {*} annotationToken
 */
function removeEmergentAction(actionToken) {
	return {
		type: REMOVE_EMERGENT_ACTION_TO_LIST,
		actionToken: actionToken,
	}
}

/**
 * Action to add corrective action
 * @param {*} action
 */
function addCorrectiveAction(correctiveAction) {
	return {
		type: ADD_CORRECTIVE_ACTION_TO_LIST,
		correctiveAction,
	}
}

/**
 * Action to update corrective action
 * @param {*} action
 */
function updateCorrectiveAction(correctiveAction) {
	return {
		type: UPDATE_CORRECTIVE_ACTION_TO_LIST,
		correctiveAction,
	}
}

/**
 * Add file to state
 *
 * @param {*} files
 * @returns
 */
function addFile(files) {
	return {
		type: ADD_FILE_QUALITY_FAILURE,
		files,
	}
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName) {
	return {
		type: REMOVE_FILE_QUALITY_FAILURE,
		fileName,
	}
}

/**
 * Get all failure reports quality by company from backend
 */
export function loadQualityFailureReportsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, isAllReports) {
	return function (dispatch, getState) {
		dispatch(isLoadingFailureReports(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/all`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
			GetAllReports: isAllReports,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setfailureRepotsQualityList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingFailureReports(false))
				console.error('Error getting the failure reports quality by company list', error.response)
			})
	}
}

/**
 * Get  failure reports quality's count
 * @export
 * @returns
 */
export function getQualityFailureReportsCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().qualityFailureReport
			? getState().qualityFailureReport.get('failureReportCount')
			: null
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setFailureReportsQualityCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error getting the failure reports quality count', error.response)
			})
	}
}

/**
 * Action to create failure reports quality from backend
 */
export function createQualityFailureReportFromBackEnd(supplier, preparedBy, partNumber, failure, reportQuality) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let data = {
			CompanyToken: selectedCompany.get('token'),
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			SupplierToken: supplier.token,
			SupplierName: supplier.name,
			SupplierCode: supplier.code,

			UserNameReport: preparedBy ? preparedBy.label : '',
			QuantityOfMaterialInInvoice: reportQuality.qualityInPlant,
			QuantityOfMaterialAffected: reportQuality.qualityReport,
			FunctionalityDimension: reportQuality.functionalityDimension,
			FunctionalitySpecification: reportQuality.functionalitySpecification,
			FunctionalityPackaging: reportQuality.functionalityPackaging,
			FunctionalityMisidentified: reportQuality.functionalityMisidentified,
			Functionality: reportQuality.functionality,
			FunctionalityAssembly: reportQuality.functionalityAssembly,
			ProblemDescription: reportQuality.problemDescription,

			Color: reportQuality.color,
			Utilize: reportQuality.utilize,
			IdentifyBy: reportQuality.identifyBy,
			Location: reportQuality.location,
			Legend: reportQuality.legend,
			CreatorContainer: user.profile.preferred_username,
			LotMaterial: reportQuality.lotMaterial,
			CustomerPartNumber: reportQuality.customerPartNumber,

			PartNumberCode: partNumber ? partNumber.code : '',
			Description: partNumber ? partNumber.description : '',
			MeasurementUnit: partNumber ? partNumber.measurementUnit : '',
			NonconformityToken: failure ? failure.value : '',
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error adding a failure report quality from company', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to edit failure reports quality from backend
 */
export function editQualityFailureReportFromBackEnd(supplier, preparedBy, partNumber, failure, reportQuality) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let data = {
			FailureReportQualityToken: reportQuality.tokenFailure,
			SupplierToken: supplier.token,
			SupplierName: supplier.name,
			SupplierCode: supplier.code,

			UserNameReport: preparedBy ? preparedBy.label : '',
			QuantityOfMaterialInInvoice: reportQuality.qualityInPlant,
			QuantityOfMaterialAffected: reportQuality.qualityReport,
			FunctionalityDimension: reportQuality.functionalityDimension,
			FunctionalitySpecification: reportQuality.functionalitySpecification,
			FunctionalityPackaging: reportQuality.functionalityPackaging,
			FunctionalityMisidentified: reportQuality.functionalityMisidentified,
			Functionality: reportQuality.functionality,
			FunctionalityAssembly: reportQuality.functionalityAssembly,
			ProblemDescription: reportQuality.problemDescription,

			Color: reportQuality.color,
			Utilize: reportQuality.utilize,
			IdentifyBy: reportQuality.identifyBy,
			Location: reportQuality.location,
			Legend: reportQuality.legend,
			CreatorContainer: user.profile.preferred_username,
			LotMaterial: reportQuality.lotMaterial,
			CustomerPartNumber: reportQuality.customerPartNumber,

			PartNumberCode: partNumber ? partNumber.code : '',
			Description: partNumber ? partNumber.description : '',
			MeasurementUnit: partNumber ? partNumber.measurementUnit : '',
			NonconformityToken: failure ? failure.value : '',
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error edit a failure report quality from company', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Get and set the failure report quality in the estate from read from backend
 * @param {*} token
 */
export function loadQualityFailureReportFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoading(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setFailureReportsQuality(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load failure report quality', error.response)
				dispatch(isLoading(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to send notification from failure report quality
 * @param {*} planningSelected
 * @param {*} developmentSelected
 * @param {*} externalUsers
 */
export function sendNotificationQualityFailureReport(planningSelected, developmentSelected, usersInternal) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let tokenRawMaterial = getState().qualityFailureReport.get('qualityFailureReport').get('token')

		let data = {
			WorkflowToken: tokenRawMaterial,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			PlanningEmail: planningSelected.user_email,
			PlanningName: planningSelected.full_name ? planningSelected.full_name : planningSelected.name,
			DevelopmentEmail: developmentSelected.user_email,
			DevelopmentName: developmentSelected.full_name ? developmentSelected.full_name : developmentSelected.name,
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			UsersInternal: usersInternal,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/sendnotification`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to send notications from failure report quality', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update status to waiting for complete the containment
 * @param {*} token
 */
export function udateStatusToWaitingForCompleteTheContainment(token) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/${token}/updatetatus`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setFailureReportsQuality(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in update status from failure report quality', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add emergent action from failure report quality
 * @param {*} description
 */
export function addEmergentActionToQualityFailureReport(description) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let tokenQuality = getState().qualityFailureReport.get('qualityFailureReport').get('token')

		let data = {
			FailureReportToken: tokenQuality,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Description: description,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/emergentaction`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addEmergentAction(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to add emegent action from failure report quality', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove emergent action from failure report quality
 * @param {*} annotationToken
 */
export function removeEmergentActionToQualityFailureReport(actionToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/emergentaction/${actionToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeEmergentAction(actionToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error removing emegent action from failure report quality', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add register warehouse entry from failure report quality
 * @param {*} description
 */
export function registerWarehouseEntryFromBackend(folio) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let tokenQuality = getState().qualityFailureReport.get('qualityFailureReport').get('token')

		let data = {
			FailureReportToken: tokenQuality,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Folio: folio,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/registerwarehouseentry`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setFailureReportsQuality(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to register warehouse entry from failure report quality', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update status to waiting for complete the containment
 * @param {*} token
 */
export function closedIsNotAttributableToTheSupplier(token) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/${token}/closed`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setFailureReportsQuality(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in closed from failure report quality', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add corrective action from failure report quality
 *
 * @export
 * @param {*} data
 * @returns
 */
export function addCorrectiveActions(correctiveActionData) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let tokenQuality = getState().qualityFailureReport.get('qualityFailureReport').get('token')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/correctiveactionsupplier`
		let formData = new FormData()
		correctiveActionData.attachments.forEach((file) => {
			formData.append('file', file)
		})
		formData.set('FailureReportToken', tokenQuality)
		formData.set('CreatorUserName', user.profile.preferred_username)
		formData.set('CreateBy', user.profile.name)
		formData.set('Description', correctiveActionData.description)
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addCorrectiveAction(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to add corrective action from failure report quality', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to accept corrective action from failure report quality
 *
 * @export
 * @param {*} data
 * @returns
 */
export function acceptCorrectiveActions(item) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/correctiveactionsupplier`
		let data = {
			ActionToken: item.token,
			InspectedUserName: user.profile.preferred_username,
			InspectedBy: user.profile.name,
			Status: item.status,
			Comment: item.quality_comments,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateCorrectiveAction(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to accept or reject corrective action from failure report quality', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update corrective action from failure report quality
 *
 * @export
 * @param {*} data
 * @returns
 */
export function updateDataCorrectiveAction(correctiveActionData) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let tokenQuality = getState().qualityFailureReport.get('qualityFailureReport').get('token')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/correctiveactionsupplier/update`
		let formData = new FormData()
		correctiveActionData.attachments.forEach((file) => {
			formData.append('file', file)
		})
		formData.set('FailureReportToken', tokenQuality)
		formData.set('CorrectiveActionToken', correctiveActionData.token)
		formData.set('CreatorUserName', user.profile.preferred_username)
		formData.set('CreateBy', user.profile.name)
		formData.set('Description', correctiveActionData.description)
		return axios
			.put(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateCorrectiveAction(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to update corrective action from failure report quality', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to create devolution
 * @param {*} preparedBy
 * @param {*} failure
 * @param {*} devolution
 */
export function createDevolutionFromBackEnd(preparedBy, comments) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let tokenQuality = getState().qualityFailureReport.get('qualityFailureReport').get('token')
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let data = {
			WorkFlowToken: tokenQuality,
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,

			PreparedBy: preparedBy ? preparedBy.label : '',
			Comments: comments,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error adding a devolution from failure quality report', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to create new rework
 * @param {*} sorterBySelected
 * @param {*} dateMaterial
 * @param {*} description
 */
export function createReworkFromBackEnd(sorterBySelected, dateMaterial, description, quality) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let tokenQuality = getState().qualityFailureReport.get('qualityFailureReport').get('token')
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let data = {
			WorkFlowToken: tokenQuality,
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			SorterToken: sorterBySelected ? sorterBySelected.token : '',
			SorterName: sorterBySelected ? sorterBySelected.name : '',
			SorterCode: sorterBySelected ? sorterBySelected.code : '',
			DateReworkMaterial: dateMaterial,
			Description: description,
			QuantityOfMaterial: quality,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks/nonconformityworkflow`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error adding a rework from failue report', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update corrective action from failure report quality
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFilesEvidence(files) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let tokenQuality = getState().qualityFailureReport.get('qualityFailureReport').get('token')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/${tokenQuality}/upload`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to upload evidence file(s) from failure report quality', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} quoteToken
 * @param {*} fileName
 * @returns
 */
export function removeFilesEvidence(fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let tokenQuality = getState().qualityFailureReport.get('qualityFailureReport').get('token')
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/${tokenQuality}/remove`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFile(fileName))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to delete draft failure report
 * @param {*} token
 */
export function deleteDraftFailureReport(token, motive) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = { MotiveDelete: motive }

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/${token}/delete`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in closed from failure report quality', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action get qualityreports information data from backend
 */
export function getFolioDeviationDataFromBackend(folio) {
	return function (dispatch, getState) {
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/deviation/${folio}/${selectedCompany.get(
			'get_company_identifier'
		)}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to get folio deviation', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to send register from failure report
 * @param {*} planningSelected
 * @param {*} developmentSelected
 * @param {*} externalUsers
 */
export function registerDeviationFailureReport(folio) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let tokenQuality = getState().qualityFailureReport.get('qualityFailureReport').get('token')
		let companyIdentifier = getState().qualityFailureReport.get('qualityFailureReport').get('company_identifier')

		let data = {
			RawMaterialToken: tokenQuality,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Folio: folio,
			CompanyIdentifier: companyIdentifier,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/registerdeviation`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in register deviation from raw material failure report', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update status deviation
 * @param {*} tokenDeviation
 */
export function updateStatusDeviation(tokenDeviation) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/deviation/${tokenDeviation}`
		return axios
			.put(endPoint, null, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setFailureReportsQuality(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to delete draft failure report', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action download report contaiment action
 */
export function downloadFailuresWithContainmentActionsFile() {
	return function (dispatch, getState) {
		dispatch(isLoading(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let companyIdentifier = selectedCompany.get('get_company_identifier')
		let companyToken = selectedCompany.get('token')
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/downloadfailures/${companyToken}/${companyIdentifier}`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isLoading(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_acciones_de_contención.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isLoading(false))
				console.error('Error download failures with contaiment actions', error.response)
				return Promise.reject()
			})
	}
}

/**
 *Action to download reporte of failure quality report
 *
 * @export
 * @returns
 */
export function downloadFailureQualityReport() {
	return (dispatch, getState) => {
		dispatch(isLoading(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/downloadfailure/reportquality/${companyToken}`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isLoading(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_falla_de_calidad.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isLoading(false))
				console.error('Error downloading failure quality report', error.response)
				return Promise.reject()
			})
	}
}

/**
 *Action to download report failure by range date
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadFailureQualityReportByRangeDate(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(isLoading(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/qualityreports/downloadFailureByRangeDate`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isLoading(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_falla_por_mes.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isLoading(false))
				console.error('Error downloading failure report by month', error.response)
				return Promise.reject()
			})
	}
}
