import React, { PureComponent } from 'react'
import { IconButton, Tooltip, Popper, Paper, ClickAwayListener, MenuList, MenuItem, Icon } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import MoreOptions from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types'
import uuidv4 from 'uuid/v4'
/**
 * Create a pure componento options menu 
 *
 * @class OptionsMenu
 * @extends {PureComponent}
 */
class OptionsMenu extends PureComponent {
    render() {
        let hasOptions = this.props.options ? this.props.options.length > 0 : false
        let xsButton = this.props.xsSize ? { maxWidth: 30, maxHeight: 30, paddingTop: 3 } : {};
        return (
            <div style={{ textAlign: "center" }}>
                {this.props.options.length > 1 &&
                    <Tooltip title={<Translate id={hasOptions ? "quotes.options" : "quotes.noOptions"} placement="left" />}>
                        <IconButton color="primary"
                            aria-label="More"
                            style={xsButton}
                            onClick={() => this.props.handleClickOptions(this.props.itemToken)}
                        >
                            <MoreOptions />
                        </IconButton>
                    </Tooltip>
                }
                {/** If options array has only 1 item, show only the option directly */}
                {this.props.options.length === 1 &&
                    <Tooltip title={this.props.options[0].tooltipTranslation} placement="left">
                        <IconButton color="primary"
                            style={xsButton}
                            onClick={this.props.options[0].itemClick}
                            disabled={this.props.options[0].isLoading}
                        >
                            {this.props.options[0].menuItemIcon}
                        </IconButton>
                    </Tooltip>
                }
                {this.props.options.length > 1 &&
                    <Popper
                        open={this.props.open}
                        style={{ zIndex: '50', position: 'absolute' }}
                        transition
                        disablePortal
                    >
                        <Paper style={{ backgroundColor: '#fafafa' }}>
                            <ClickAwayListener onClickAway={this.props.handleClickAway}>
                                <MenuList >
                                    {hasOptions && this.props.options.map(option => {
                                        return (
                                            <MenuItem key={uuidv4()}
                                                onClick={option.itemClick}
                                                disabled={option.isLoading}
                                            >
                                                <Icon color="secondary">
                                                    <Tooltip title={option.tooltipTranslation} placement="left-start">
                                                        {option.menuItemIcon}
                                                    </Tooltip>
                                                </Icon>
                                            </MenuItem>
                                        )
                                    })
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                }

            </div>
        )
    }
}

OptionsMenu.propTypes = {
    itemToken: PropTypes.string.isRequired,
    handleClickOptions: PropTypes.func,
    open: PropTypes.bool.isRequired,
    handleClickAway: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            itemClick: PropTypes.func.isRequired,
            tooltipTranslation: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.string,
            ]),
            menuItemIcon: PropTypes.object,
        }),
    ),
}

export default OptionsMenu;
