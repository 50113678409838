import { Map, fromJS } from 'immutable';

import * as companyActions from './CompanyActions';

/**
 * Companies initial state
 */
export const companiesInitialState = fromJS({
    isLoadingCompanies: true,
    companies: null,
    isLoadingCompany: false,
    company: null,
    isSavingCompany: false,
    isSavingWarehouse: false,
    isSavingPrivacyNotice: false,
    isLoadingPrivacyNotice: false,
    privacyNotices: null,
    activePrivacyNotice: null,
    errorThrowMessage: null,
    companiesCount: null,
    sortCriteria: {
        by: "name",
        ascending: false
    },
    searchQuery: "",
    rolesForCompanies: null,
    isLoadingCompaniesRoles: false,
})

export default function companiesReducer(state = companiesInitialState, action) {
    switch (action.type) {
        case companyActions.START_LOADING_COMPANIES: {
            return state.merge({
                isLoadingCompanies: action.isLoading
            })
        }
        case companyActions.SET_COMPANIES_LIST: {
            let companiesLists = fromJS(action.companies);
            return state.merge({
                companies: companiesLists,
                isLoadingCompanies: false
            })
        }
        case companyActions.CHANGE_COMPANIES_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case companyActions.CHANGE_COMPANIES_QUERY: {
            return state.merge({
                searchQuery: action.query
            })
        }
        case companyActions.SET_COMPANIES_COUNT: {
            return state.merge({
                companiesCount: action.count
            })
        }
        case companyActions.IS_LOADING_COMPANY: {
            return state.merge({
                isLoadingCompany: action.isLoadingCompany,
                company: null,
            })
        }
        case companyActions.SET_COMPANY: {
            return state.merge({
                isLoadingCompany: false,
                company: fromJS(action.company)
            })
        }
        case companyActions.IS_SAVING_COMPANY: {
            return state.merge({
                isSavingCompany: action.isSaving
            })
        }
        case companyActions.ADD_COMPANY_TO_LIST: {
            let company = Map(action.company);
            let companiesList = state.get('companies').push(company);
            return state.merge({
                companies: companiesList,
                isSavingCompany: false
            });
        }
        case companyActions.UPDATE_COMPANY: {
            return state.merge({
                isSavingCompany: false,
                company: fromJS(action.company)
            })
        }
        case companyActions.IS_SAVING_WAREHOUSE: {
            return state.merge({
                isSavingWarehouse: action.isSaving
            })
        }
        case companyActions.ADD_WAREHOUSES_IN_COMPANY: {
            let warehouse = fromJS(action.warehouse)
            return state.updateIn(['company', "warehouses"], (warehousesInCompany) => warehousesInCompany.push(warehouse))
                .set('isSavingWarehouse', false);
        }
        case companyActions.REMOVE_WAREHOUSES_IN_COMPANY: {
            return state.updateIn(['company', 'warehouses'], (allWarehouses) => {
                let index = allWarehouses.findIndex((item) => {
                    return item.get('token') === action.tokenWarehouse
                });
                return allWarehouses.delete(index).set('isSavingWarehouse', false);
            })
        }
        case companyActions.IS_LOADING_COMPANIES_ROLES: {
            return state.merge({
                isLoadingCompaniesRoles: action.isLoading
            })
        }
        case companyActions.SET_ROLES_FOR_COMPANIES: {
            return state.merge({
                rolesForCompanies: action.roles
            })
        }
        case companyActions.IS_SAVING_PRIVACY: {
            return state.merge({
                isSavingPrivacyNotice: action.isSaving
            })
        }
        case companyActions.IS_LOADING_PRIVACY_NOTICE:{
            return state.merge({
                isLoadingPrivacyNotice : action.isLoading
            })
        }
        case companyActions.SET_PRIVACY_LIST:{
            let privaycLists = fromJS(action.privacyNotices);
            return state.merge({
                privacyNotices: privaycLists,
                isLoadingPrivacyNotice: false
            })
        }
        case companyActions.ADD_PRIVACY_TO_LIST:{
            let privacyNoticeToAdd = fromJS(action.privacyNotice)
            return state.updateIn(['privacyNotices'], (allPrivacyNotices) => allPrivacyNotices.push(privacyNoticeToAdd))
                .set('isSavingPrivacyNotice', false);
        }
        case companyActions.REMOVE_PRIVACY_TO_LIST:{
            return state.updateIn(['privacyNotices'], (allPrivacyNotices) => {
                let index = allPrivacyNotices.findIndex((item) => {
                    return item.get('token') === action.privacyToken
                });
                return allPrivacyNotices.delete(index).set('isSavingPrivacyNotice', false);
            })
        }
        case companyActions.SET_ACTIVE_PRIVACY_NOTICE:{
            return state.merge({
                activePrivacyNotice: action.activePrivacyNotice
            })
        }
        default:
            return state;
    }
}