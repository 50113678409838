import React from 'react'
import { Translate } from 'react-localize-redux'
import { canSeeModuleForeignTrade } from "./RolesHelper"
import { IsUserSupplierAdministrator } from "./SessionHelper"

export function canBeEditSupplierAduanalInformation(shippingOrder, userRolesByCompany, userProfile) {
	if (userRolesByCompany === undefined) return false
	if (userProfile === null || userProfile === undefined) return false
	if (shippingOrder === null || shippingOrder === undefined) return false
	// if (loadAlert.status !== LoadAlertStatus.DRAFT) return false
	if (!shippingOrder) return false
	if (userProfile.profile.company_code !== shippingOrder.customs_sap_number) return false
	let supplierAdmin = IsUserSupplierAdministrator(userProfile)
	return supplierAdmin || canSeeModuleForeignTrade(userRolesByCompany)
}

/**
 * Get title to chage status
 * @returns
 */
export function onTitleStatusDialog(status) {
	switch (status) {
		case ForeignTradeStatus.Review:
			return 'loadAlerts.dialogsOptions.sendToReview'
		default:
			return ''
	}
}


/**
 * Get message to show in dialog
 * @returns
 */
export function onMessageStatusDialog(status) {
	switch (status) {
		case ForeignTradeStatus.Review:
			return 'loadAlerts.dialogsOptions.messageToSendToReviewFreigTrade'
		default:
			return 'no options'
	}
}
export const ForeignTradeStatus = {
	Undefined: 0,
	Draft: 1,
	Review: 2,
}



export function AvailableOperationsConfiguration(
	status,
	onOpenChangeStatus,
	canBeSendToReview,
) {
	let columns = []
	if (canBeSendToReview && (status === ForeignTradeStatus.Draft || status === ForeignTradeStatus.Undefined)) {
		columns.push({
			value: ForeignTradeStatus.Review,
			title: <Translate id={onTitleStatusDialog(ForeignTradeStatus.Review)} />,
			onClick: () => onOpenChangeStatus(ForeignTradeStatus.Review),
			isIconMaterial: true,
			description: <Translate id={onMessageStatusDialog(ForeignTradeStatus.Review)} />,
		})
	}
	return columns
}
