
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

/** Import component section */
import Dialog from '../../common/Dialog';

/**
 * Dialog to aauthorizer or rejected request rework
 */
class RequestReworkAuthorizer extends Component {

    /**
   *Creates an instance of RequestReworkAuthorizer.
   * @param {*} props
   * @memberof RequestReworkAuthorizer
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            commnet: "",
        }
    }

    /**
     * Function to add commnet
     * @param {*} commnet 
     */
    updatetemProperty(commnet) {
        this.setState({
            commnet: commnet
        })
    }

    /**
   * canBeSaved
   */
    canBeSaved() {
        return this.state.commnet !== "";
    }

    /**
     * Functions to acceted request 
     */
    onAcceptedRequest() {
        if (this.props.onAcceptedRequest)
            this.props.onAcceptedRequest(this.props.document.token, this.state.commnet)
    }

    /**
     * Function to rejected request 
     */
    onRejectedRequest() {
        if (this.props.onRejectedRequest && this.state.commnet !== "")
            this.props.onRejectedRequest(this.props.document.token, this.state.commnet)
    }

    /**
     * Dialog to update information
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            {<Translate id="reworks.authorizerOrRejected" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <React.Fragment>
                        <Toolbar>
                            <div className={classes.grow}></div>
                            {
                                this.props.isSaving === true ?
                                    <Button variant="outlined" color="secondary" className={classes.button} disabled>
                                        <Translate id="common.saving" />
                                    </Button>
                                    :
                                    <div>
                                        <Button size="small" variant="outlined" color="secondary" className={classes.button} onClick={this.onAcceptedRequest}>
                                            <Translate id="common.accept" />
                                        </Button>

                                        <Button size="small" variant="outlined" disabled={!this.canBeSaved()} color="inherit" className={classes.button} onClick={this.onRejectedRequest} >
                                            <Translate id="common.reject" />
                                        </Button>

                                        <Button size="small" variant="outlined" color="primary" className={classes.button} onClick={this.props.onClose}>
                                            <Translate id="common.close" />
                                        </Button>

                                    </div>
                            }

                        </Toolbar>
                        {
                            this.props.isSaving === true ?
                                <LinearProgress color="secondary" variant="query" />
                                : null
                        }
                    </React.Fragment>
                }>

                <form>
                    <TextField fullWidth
                        className={classes.textField}
                        multiline
                        rows="4"
                        value={this.state.commnet}
                        onChange={(event) => { this.updatetemProperty(event.target.value) }}
                        onKeyDown={this.onKeyDown}
                        margin="normal"
                        variant="outlined"
                    />
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(RequestReworkAuthorizer));