/**Import react section */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import uuidv4 from 'uuid/v4'
import { Translate } from 'react-localize-redux'
/**Import material UI Section*/

import {
    withStyles,
    IconButton,
    Tooltip,
    Fab,
    CircularProgress
} from "@material-ui/core";
import CommentIcon from '@material-ui/icons/Comment';

import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import { ScopeType } from '../../../../store/helpers/AppConstants';
import {
    COLOR_STATUS_SUCCESS,
    COLOR_STATUS_BLUE,
    BACKGROUND_COLOR_GRAY_BIDDING
} from '../../../../store/helpers/StatusColorConstants';

/**Import components section*/
import CommentsComponent from '../CommentsComponent'
import {
    onGetHeaderResultGeneralTableV2,
    onMappedDataResultsGeneralsTableV2,
    onMappedDataResultsItemTableV2,
    isCategoryRawMaterials,
    canBeEditBiddingInStatusResolved,
    onGetSuppliersNotDeclined
} from '../../../../store/helpers/BiddingHelper';
import IsWinnerIcon from '../../../../resources/images/winners/isWinner.png'
import NoWinnerIcon from '../../../../resources/images/winners/noWinner.png'
import ResultTableContentV2 from './ResultTableContentV2'
import {
    IsNullOrEmpty
} from '../../../../store/helpers/StringHelper';
import {

    BiddingStatus
} from '../../../../store/helpers/AppConstants';
import { ADD_WINNER_SUPPLIER } from '../../../../store/biddings/BiddingsActions'

const ResultsTableGeneralV2 = (props) => {
    /**String props section */
     const { supplierExternalCode, translate } = props
    /**Bools props section */
    const { isSaving, isVisibleComments, isCurrentActiveDirectoryUser, isOpenLateralMenu, isExternalUser } = props
    /**Arrays props section */
    const { userRolesByCompany } = props
    /**Functions props section */
    const { onConfirmPassword, onAddWinnerConfigPanel,
        onAddWinnerInItemFromBackend, onAddCommentsSupplierConfigPanel
    } = props
    /**Objects props section */
    const { classes, bidding, userProfile } = props
    const [identifierIsSaving, setIdentifierIsSaving] = useState(false)
    const [identifierItemIdIsSaving, setIdentifierItemIdIsSaving] = useState(false)

    const onSetWinner = (event, supplier) => {
        event.stopPropagation()
        if (props.onConfirmPassword) {
            setIdentifierIsSaving(supplier.supplierCode)
            setIdentifierItemIdIsSaving(supplier.itemId)
            onConfirmPassword(supplier, ADD_WINNER_SUPPLIER)
        }
    }
    let generalsCriterias = (bidding.CriteriasResponses.length >= 1 ? (bidding.CriteriasResponses[0].criterias.length >= 1 ? bidding.CriteriasResponses[0].criterias[0] : []) : [])
    let itemsCriterias = (bidding.CriteriasResponses.length >= 2 ? (bidding.CriteriasResponses[1].criterias.length >= 1 ? bidding.CriteriasResponses[1].criterias[0].ItemResponses[0] : []) : [])
    let supplierCodeIdentifier = (supplierExternalCode ? supplierExternalCode: (isCurrentActiveDirectoryUser ? null: (userProfile ? userProfile.preferred_username:"")))
    return (
        <div style={{
            maxHeight: `calc(100vh - ${335}px)`,
            maxWidth: !isOpenLateralMenu ? `calc(100vw - ${155}px)`:`calc(100vw - ${315}px)`,
            overflowX: "auto",
            overflowY: "auto",
        }}>
            {
                isVisibleComments && onGetHeaderResultGeneralTableV2(generalsCriterias, bidding.Suppliers, bidding, supplierCodeIdentifier, !isExternalUser).map(column => {
                    return (column.isVisibleComments ?
                        <CommentsComponent
                            title={<Translate id="common.comments" />}
                            comments={column.finalComment}
                            isVisibleHtmlEditor
                        /> : null
                    )
                })
            }

            <Table
                className={classes.table}
                style={{
                    maxWidth: '100%',
                    minHeight: 90,
                    maxHeight: '100%'
                }} size="small">
                <TableHead key={uuidv4()}>
                    <TableRow>
                        {
                            onGetHeaderResultGeneralTableV2(itemsCriterias.length ===0 ? generalsCriterias.ItemResponses[0] : itemsCriterias, bidding.Suppliers, bidding, supplierCodeIdentifier, !isExternalUser).map(column => {
                            let isVisibleIconWinner = (column.isCategoryService && bidding && (bidding.Status === BiddingStatus.RESOLVED|| bidding.Status === BiddingStatus.FINISHED))
                                return (<TableCell
                            className={(column.isVisibleComments || column.isColumnTotal) ? (column.isColumnTotal ? classes.tableCellHeaderBackgroundColorGray: classes.tableCellHeaderBorderLeft): classes.tableCellHeader}
                            key={uuidv4()}
                            align="center" >
                            {(column.isVisibleComments) ? <div className={isVisibleIconWinner ? classes.commentsComponent : classes.commentsComponent2}>
                                {
                                    isVisibleIconWinner &&
                                    <div className={classes.gridColumnOne}>
                                        <>
                                            <Tooltip title={(column.isSelectedWinner ? <Translate id="biddingsModule.winner" /> : <Translate id="biddingsModule.setAsWinner" />)}>
                                                <Fab
                                                    className={classes.fabWinner}
                                                    size="small"
                                                    disabled={isSaving && column.supplierCode === identifierIsSaving && column.itemId === identifierItemIdIsSaving}
                                                    onClick={(event) => (canBeEditBiddingInStatusResolved(bidding, userRolesByCompany, userProfile) ? onSetWinner(event, column) : null)}
                                                ><img alt="WinnerIcon" className={classes.winnerSize} src={column.isSelectedWinner ? IsWinnerIcon : NoWinnerIcon} />
                                                </Fab>
                                            </Tooltip>
                                            {(isSaving && column.supplierCode === identifierIsSaving && column.itemId === identifierItemIdIsSaving) && (
                                                <CircularProgress size={52} className={classes.fabProgress} />
                                            )}
                                        </>
                                    </div>
                                }
                                <div className={!column.isCategoryService ? classes.gridColumnOne : classes.gridColumnTwo}>
                                    <strong>{column.title}</strong>

                                </div>
                                {column.isVisibleComments && <div className={!column.isCategoryService ? classes.gridColumnTwo : classes.gridColumnThree}>
                                    <IconButton className={IsNullOrEmpty(column.finalComment) ? null : classes.finishedComment} onClick={() => onAddCommentsSupplierConfigPanel(column)}><CommentIcon /></IconButton>
                                </div>
                                }
                            </div> : <strong>{column.title}</strong>}
                        </TableCell>)
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    <ResultTableContentV2
                        title={<Translate id="biddingsModule.globals" />}
                        isSaving={isSaving}
                        bidding={bidding}
                        items={onMappedDataResultsGeneralsTableV2(bidding, ScopeType.GENERAL)}
                        onAddWinnerConfigPanel={onAddWinnerConfigPanel}
                        onAddWinnerInItemFromBackend={onAddWinnerInItemFromBackend}
                        userRolesByCompany={userRolesByCompany}
                        userProfile={userProfile}
                        onAddCommentsSupplierConfigPanel={onAddCommentsSupplierConfigPanel}
                        supplierCodeIdentifier={supplierCodeIdentifier}
                        isExternalUser={isExternalUser}
                        translate={translate}
                    />
                    <ResultTableContentV2
                        title={isCategoryRawMaterials(bidding) ? null : <Translate id="biddingsModule.services.title" />}
                        bidding={bidding}
                        isSaving={isSaving}
                        items={onMappedDataResultsItemTableV2(bidding, ScopeType.ITEM, bidding.Status, supplierCodeIdentifier)}
                        onConfirmPassword={onConfirmPassword}
                        onAddWinnerConfigPanel={onAddWinnerConfigPanel}
                        isListItems
                        onAddWinnerInItemFromBackend={onAddWinnerInItemFromBackend}
                        userRolesByCompany={userRolesByCompany}
                        userProfile={userProfile}
                        onAddCommentsSupplierConfigPanel={onAddCommentsSupplierConfigPanel}
                        suppliersNotDeclined = {onGetSuppliersNotDeclined(bidding, supplierCodeIdentifier)}
                        supplierCodeIdentifier={supplierCodeIdentifier}
                        isExternalUser={isExternalUser}
                        translate={translate}
                    />
                </TableBody>
            </Table>
        </div>
    )
}

const styles = (theme) => ({
    flex: {
        display: "flex"
    },
    percentage: {
        fontWeight: 'bold',
        marginLeft: "5px"
    },
    containerServices: {
        maxHeight: `calc(100vh - ${390}px)`,
        minHeight: `calc(100vh - ${390}px)`,
        overflowY: "auto",
    },
    flexGrow: {
        flexGrow: 1
    },
    inputProps: {
        //height: 8,
        fontSize: 12
    },
    accordionSummary: {
        paddingRight: "0px !important",
        display: "flex",
        backgroundColor: "#f8f9fa"

    },
    accordionDetails: {
        backgroundColor: `${theme.palette.common.white} !important`
    },
    buttonsAcordionSummary: {
        display: "flex",
        justifyItems: "flex-end",
        marginRight: "20px"
    },
    btnAcceptWinner: {
        color: COLOR_STATUS_SUCCESS, //"white",
        borderColor: COLOR_STATUS_SUCCESS,
        marginRight: "5px"
    },
    noWrapText: {
        maxWidth: "100px",
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    btnAcceptNotWinner: {
        color: COLOR_STATUS_BLUE, //"white",
        borderColor: COLOR_STATUS_BLUE,
    },
    marginRightText: {
        marginRight: "10px",
        marginTop: "7px"
    },
    btnWinnerInExpanded: {
        color: COLOR_STATUS_SUCCESS, //"white",
        borderColor: COLOR_STATUS_SUCCESS,
        marginRight: "20px"
    },
    finishedComment: {
        color: COLOR_STATUS_SUCCESS, //"white",
    },
    commentsComponent: {
        display: "grid",
        gridTemplateColumns: "50px 150px 20px"
    },
    commentsComponent2: {
        display: "grid",
        gridTemplateColumns: "150px 20px"
    },
    gridColumnOne: {
        gridColumnStart: "1/2"
    },
    gridColumnTwo: {
        gridColumnStart: "2/3"
    },
    gridColumnThree: {
        gridColumnStart: "3/4"
    },
    tableCellHeader: {
        backgroundColor: '#fff',
        //backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
        position: 'sticky',
        top: 0,
        zIndex: '30'
    },
    tableCellHeaderBackgroundColorGray:{
        position: 'sticky',
        top: 0,
        zIndex: '30',
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING
    },
    tableCellHeaderBorderLeft:{
        //backgroundColor: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: '30',
        borderLeft: "1px solid #000",
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING
    },
    fabWinner: {
        //backgroundColor: "white",
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
        '&:hover': {
            //backgroundColor: "white",
            backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
        },
    },
    winnerSize: {
        marginTop: "10px",
        width: "20px",
        height: "auto"
    },
    fabProgress: {
        color: COLOR_STATUS_SUCCESS,
        position: "absolute",
        top: -6,
        left: 9,
        zIndex: 1
    },
});

ResultsTableGeneralV2.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
    isVisibleComments: PropTypes.bool.isRequired,
}

ResultsTableGeneralV2.defaultProps = {
    isLoading: false,
    isVisibleComments: false
}

export default withRouter(
    withStyles(styles, { withTheme: true })(ResultsTableGeneralV2)
);
