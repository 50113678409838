/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'

export const IS_SAVING_RATING = 'IS_SAVING_RATING'
export const IS_LOADING = 'IS_LOADING'
export const GET_RATING_SUPPLIER = 'GET_RATING_SUPPLIER'
export const SET_RATING_TO_PUBLISH = 'SET_RATING_TO_PUBLISH'
export const IS_LOADING_RATING = 'IS_LOADING_RATING'
export const SET_RATINGS_BY_SUPPLIER = 'SET_RATINGS_BY_SUPPLIER'
export const START_GET_EVALUATION_SUPPLIERS_PERCENTAGE = 'START_GET_EVALUATION_SUPPLIERS_PERCENTAGE'
export const SET_EVALUATION_SUPPLIERS_PERCENTAGE = 'SET_EVALUATION_SUPPLIERS_PERCENTAGE'
export const SET_LIST_RATINGS_PTM = 'SET_LIST_RATINGS_PTM'
export const SET_LIST_RATINGS_FERSA = 'SET_LIST_RATINGS_FERSA'
export const IS_SENDING_RATING = 'IS_SENDING_RATING'

/**
 * Action start loading rating
 * @param {*} isLoading
 */
function isSaving(isSaving) {
	return {
		type: IS_SAVING_RATING,
		isSaving: isSaving,
	}
}

/**
 * Action start sending notifications
 * @param {*} isLoading
 */
function isSending(isSending) {
	return {
		type: IS_SENDING_RATING,
		isSending: isSending,
	}
}

/**
 * Actions is loading rating
 * @param {*} isLoading
 */
function isLoading(isLoading) {
	return {
		type: IS_LOADING,
		isLoading: isLoading,
	}
}

/**
 * Action to get rating
 * @param {*} rating
 */
function getRating(rating) {
	return {
		type: GET_RATING_SUPPLIER,
		rating: rating,
	}
}

/**
 * Actions is loading rating
 * @param {*} isLoading
 */
function isLoadingRating(isLoadingRating) {
	return {
		type: IS_LOADING_RATING,
		isLoadingRating,
	}
}

/**
 * Action to get rating in process
 * @param {*} rating
 */
function getRatingToPublish(ratingToPublish) {
	return {
		type: SET_RATING_TO_PUBLISH,
		ratingToPublish: ratingToPublish,
	}
}

function setRatingsBySupplier(ratings) {
	return {
		type: SET_RATINGS_BY_SUPPLIER,
		ratings,
	}
}

/**
 * Actions is loading evaluation supplier rating
 * @param {*} isLoading
 */
function isLoadingEvaluationSupplier(isLoading) {
	return {
		type: START_GET_EVALUATION_SUPPLIERS_PERCENTAGE,
		isLoading,
	}
}

/**
 * Action to get rating in process
 * @param {*} rating
 */
function setEvaluationSuppliersRating(evaluation) {
	return {
		type: SET_EVALUATION_SUPPLIERS_PERCENTAGE,
		evaluation: evaluation,
	}
}

/**
 * ction to set list rating ptm
 * @param {*} ratings
 */
function setRatingsPTMBySupplier(ratings) {
	return {
		type: SET_LIST_RATINGS_PTM,
		ratings,
	}
}

/**
 * Action to set list rating ptm
 * @param {*} ratings
 */
function setRatingsFERSABySupplier(ratings) {
	return {
		type: SET_LIST_RATINGS_FERSA,
		ratings,
	}
}

/**
 * Get and set the failure report quality in the estate from read from backend
 * @param {*} token
 */
export function loadRatingToPushishFromBackEnd() {
	return function (dispatch, getState) {
		dispatch(isLoadingRating(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/rating`
		let data = {
			CompanyToken: selectedCompany.get('token'),
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(getRatingToPublish(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load failure report quality', error.response)
				dispatch(isLoadingRating(false))
				return Promise.reject()
			})
	}
}

/**
 * Get and set the failure report quality in the estate from read from backend
 * @param {*} token
 */
export function loadRatingsBySupplierFromBackEnd(supplierToken, isHome) {
	return function (dispatch, getState) {
		dispatch(isLoadingRating(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		if (selectedCompany) {
			let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rating/supplier`
			let data = {
				CompanyToken: selectedCompany.get('token'),
				SupplierToken: supplierToken,
				IsHome: isHome,
			}
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setRatingsBySupplier(serverResponse.data))
					}
				})
				.catch((error) => {
					console.error('Error to load rating by supplier', error.response)
					dispatch(isLoadingRating(false))
					return Promise.reject()
				})
		} else {
			dispatch(isLoadingRating(false))
		}
	}
}

/**
 * Action to upload file to import rating
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFiles(files) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rating/import`

		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		formData.set('CreatorUserName', user.profile.preferred_username)
		formData.set('CreateBy', user.profile.name)
		formData.set('CompanyToken', selectedCompany.get('token'))
		formData.set('CompanyIdentifier', selectedCompany.get('get_company_identifier'))

		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(getRatingToPublish(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to upload import rating file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to upload file to import rating
 *
 * @export
 * @param {*} data
 * @returns
 */
export function ImportQualifications(files, year, month) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/generatedrating/imberabrautomatic`

		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		formData.set('Year', year)
		formData.set('Month', month)
		formData.set('CompanyToken', selectedCompany.get('token'))

		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(getRatingToPublish(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to upload import rating file(s)', error.response)
				return Promise.reject()
			})
	}
}

export function getQualificationsBrail(year, month) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let companyToken = selectedCompany.get('token')
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/${companyToken}/reportdownload/${month}/${year}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(getRatingToPublish(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to upload import rating file(s)', error.response)
				return Promise.reject()
			})
	}
}

export function notifySuppliers(year, month) {
	return (dispatch, getState) => {
		dispatch(isSending(true))

		let user = getState().oidc.user
		let userEmail = user.profile.email;
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let companyToken = selectedCompany.get('token')
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/emailsSend/${companyToken}/${month}/${year}/${userEmail}`

		return axios
			.post(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(getRatingToPublish(serverResponse.data))
					dispatch(isSending(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSending(false))
				console.error('Error sending emails', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} quoteToken
 * @param {*} fileName
 * @returns
 */
export function removeFilesEvidence(fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rating/remove`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to remove import rating file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Get and set the rating by supplier in the estate from read from backend
 * @param {*} token
 */
export function getRatingBySupplier(supplierToken) {
	return function (dispatch, getState) {
		dispatch(isLoading(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rating/supplier`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			IsHome: true,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(getRating(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load rating by supplier', error.response)
				dispatch(isLoading(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to upload file to import rating
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadSuppliersFile(files) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/initializesupplier`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		formData.set('CreatorUserName', user.profile.preferred_username)
		formData.set('CreateBy', user.profile.name)
		formData.set('CompanyToken', selectedCompany.get('token'))
		formData.set('CompanyIdentifier', selectedCompany.get('get_company_identifier'))
		formData.set('RatingInProcessToken', '')

		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(getRatingToPublish(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to upload import rating file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to upload file to import rating
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadRatingDeliveryFiles(files) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let ratingInProcess = getState().ratings.get('ratingToPublish')
		if (ratingInProcess) {
			let configuration = getAuthorizedRequestConfigurationUserFiles(user)
			let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/import/levelofdelivery`
			let formData = new FormData()
			for (var index = 0; index <= files.length; index++) {
				formData.append('file', files[index])
			}
			formData.set('RatingInProcessToken', ratingInProcess.get('token'))
			return axios
				.post(endPoint, formData, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(getRatingToPublish(serverResponse.data))
						dispatch(isSaving(false))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSaving(false))
					console.error('Error to upload import rating file(s) from delivery', error.response)
					return Promise.reject()
				})
		} else {
			dispatch(isSaving(false))
			return Promise.reject()
		}
	}
}

/**
 * Action to upload ratings StopInLineQuality from backend quality and development
 * @param {*} tokenRatingInProcess
 * @param {*} files
 */
export function uploadRatingStopInLineQualityFiles(files) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let ratingInProcess = getState().ratings.get('ratingToPublish')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let evaluationRatingOnLine = getState().ratings.get('evaluationRating')
			? getState().ratings.get('evaluationRating')
			: null
		/// Get information from quality
		if (ratingInProcess) {
			let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/ratingautomatic/import/levelstoponline`
			let formData = new FormData()
			for (var index = 0; index <= files.length; index++) {
				formData.append('file', files[index])
			}
			formData.set('CompanyToken', ratingInProcess.get('company_token'))
			formData.set('YearToRating', ratingInProcess.get('year_rating'))
			formData.set('MonthToRating', ratingInProcess.get('month_rating'))
			formData.set(
				'OnLineQualityEvaluation',
				evaluationRatingOnLine ? evaluationRatingOnLine.get('quality_online_stop_rate') : '0'
			)

			return axios
				.post(endPoint, formData, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						/// Send information to development to save o StopInLineQuality
						var ratings = serverResponse.data
						let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/ratingbysupplier/stopinline`
						let configuration = getAuthorizedRequestConfigurationUser(user)
						let data = {
							RatingInProcessToken: ratingInProcess.get('token'),
							ratings,
						}
						return axios
							.post(endPoint, data, configuration)
							.then((serverResponse) => {
								if (serverResponse.status === 200) {
									dispatch(getRatingToPublish(serverResponse.data))
									return Promise.resolve()
								} else {
									dispatch(isSaving(false))
									return Promise.reject()
								}
							})
							.catch((error) => {
								dispatch(isSaving(false))
								console.error('Error getting the companies granted for user', error)
								return Promise.reject()
							})
					} else {
						dispatch(isSaving(false))
					}
				})
				.catch((error) => {
					dispatch(isSaving(false))
					console.error('Error to upload import rating file(s) from delivery', error.response)
					return Promise.reject()
				})
		}
	}
}

/**
 * Action to upload ratings Ppms Quality from backend quality and development
 * @param {*} tokenRatingInProcess
 * @param {*} files
 */
export function uploadRatingPpmsQualityFiles(files) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let ratingInProcess = getState().ratings.get('ratingToPublish')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let evaluationRatingPpms = getState().ratings.get('evaluationRating')
			? getState().ratings.get('evaluationRating')
			: null
		/// Get information from quality
		if (ratingInProcess) {
			let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/ratingautomatic/ppmsquality`
			let formData = new FormData()
			for (var index = 0; index <= files.length; index++) {
				formData.append('file', files[index])
			}
			formData.set('CompanyToken', ratingInProcess.get('company_token'))
			formData.set('YearToRating', ratingInProcess.get('year_rating'))
			formData.set('MonthToRating', ratingInProcess.get('month_rating'))
			formData.set('EvaluationPpms', evaluationRatingPpms ? evaluationRatingPpms.get('quality_ppms_rate') : '0')

			return axios
				.post(endPoint, formData, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						/// Send information to development to save o PpmsQuality
						var ratings = serverResponse.data
						let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/ratingbysupplier/ppms`
						let configuration = getAuthorizedRequestConfigurationUser(user)
						let data = {
							RatingInProcessToken: ratingInProcess.get('token'),
							ratings: ratings,
						}
						return axios
							.post(endPoint, data, configuration)
							.then((serverResponse) => {
								if (serverResponse.status === 200) {
									dispatch(getRatingToPublish(serverResponse.data))
									return Promise.resolve()
								} else {
									dispatch(isSaving(false))
									return Promise.reject()
								}
							})
							.catch((error) => {
								dispatch(isSaving(false))
								console.error('Error getting the companies granted for user', error)
								return Promise.reject()
							})
					} else {
						dispatch(isSaving(false))
					}
				})
				.catch((error) => {
					dispatch(isSaving(false))
					console.error('Error to upload import rating file(s) from ppms', error.response)
					return Promise.reject()
				})
		}
	}
}

/**
 * Action to upload file to import rating
 *
 * @export
 * @param {*} data
 * @returns
 */
export function generateRatingDevelopmentSip() {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let ratingInProcess = getState().ratings.get('ratingToPublish')
		let evaluationRating = getState().ratings.get('evaluationRating')
			? getState().ratings.get('evaluationRating')
			: null

		if (ratingInProcess) {
			let configuration = getAuthorizedRequestConfigurationUser(user)
			let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/import/ratingofdevelopment`
			let data = {
				RatingInProcessToken: ratingInProcess.get('token'),
			}
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						/// Ger rating from quality
						let endPointQuality = `${process.env.REACT_APP_SIP_QUALITY}/ratingautomatic/import/quality/ratingautomatic`
						let configuratin = getAuthorizedRequestConfigurationUser(user)
						let dataQaulity = {
							SupplierRating: serverResponse.data,
							EvaluationFailureuality: evaluationRating
								? evaluationRating.get('quality_failure_process_rate')
								: 0,
							EvaluationSac: evaluationRating ? evaluationRating.get('sac_rate') : 0,
						}
						return axios
							.post(endPointQuality, dataQaulity, configuratin)
							.then((serverResponseQaulity) => {
								if (serverResponseQaulity.status === 200) {
									/// Send information to development to  calidad
									let endPointDevel = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/ratingbysupplier/failure`
									let configurations = getAuthorizedRequestConfigurationUser(user)
									let dataDevel = {
										RatingInProcessToken: ratingInProcess.get('token'),
										ratings: serverResponseQaulity.data,
									}
									/// Actualizar informacion de calidad en desarrollo
									return axios
										.post(endPointDevel, dataDevel, configurations)
										.then((response) => {
											if (response.status === 200) {
												dispatch(getRatingToPublish(response.data))
												return Promise.resolve()
											} else {
												dispatch(isSaving(false))
												return Promise.reject()
											}
										})
										.catch((error) => {
											dispatch(isSaving(false))
											console.error('Error getting the companies granted for user', error)
											return Promise.reject()
										})
								} else {
									dispatch(isSaving(false))
									return Promise.reject()
								}
							})
							.catch((error) => {
								dispatch(isSaving(false))
								console.error('Error getting the companies granted for user', error)
								return Promise.reject()
							})
					}
				})
				.catch((error) => {
					dispatch(isSaving(false))
					console.error('Error to upload import rating file(s) from quality or development', error.response)
					return Promise.reject()
				})
		} else {
			dispatch(isSaving(false))
			return Promise.reject()
		}
	}
}

/**
 * Action to generated rating and publish to supplier
 */
export function generateRatings() {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let ratingInProcess = getState().ratings.get('ratingToPublish')
		if (ratingInProcess) {
			let configuration = getAuthorizedRequestConfigurationUser(user)
			let endPoint = `${
				process.env.REACT_APP_SIP_DEVELOPMENT
			}/ratingautomatic/publishratings/${ratingInProcess.get('company_token')}/${ratingInProcess.get('token')}`
			let data = {
				RatingInProcessToken: ratingInProcess.get('token'),
				CompanyToken: ratingInProcess.get('company_token'),
			}
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					dispatch(getRatingToPublish(serverResponse.data))
				})
				.catch((error) => {
					dispatch(isSaving(false))
					console.error('Error to publish ratings', error.response)
					return Promise.reject()
				})
		} else {
			dispatch(isSaving(false))
			return Promise.reject()
		}
	}
}

/**
 * Get and set the the evaluation supplier the rating from read from backend
 * @param {*} token
 */
export function loadEvaluationSuppliersRatingByCompanyFromBackEnd(companyToken) {
	return function (dispatch, getState) {
		dispatch(isLoadingEvaluationSupplier(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/evaluation/rating`
		let data = {
			CompanyToken: companyToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setEvaluationSuppliersRating(serverResponse.data))
					dispatch(isLoadingEvaluationSupplier(false))
				}
			})
			.catch((error) => {
				console.error('Error to load evaluation supplier rating', error.response)
				dispatch(isLoadingEvaluationSupplier(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to generated rating from PTM
 */
export function generateRatingsPTM(month, year) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/generatedratingmanual/ptm`
		let company = getState().profile.get('selectedCompany')
		let data = {
			Month: month,
			Year: year,
			CompanyToken: company.get('token'),
			CompanyName: company.get('name'),
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(setRatingsPTMBySupplier(serverResponse.data))
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to generated ratings from ptm', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to generated rating from PTM
 */
export function generateRatingsFERSA(month, year) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/generatedratingmanual/fersa`
		let company = getState().profile.get('selectedCompany')
		let data = {
			Month: month,
			Year: year,
			CompanyToken: company.get('token'),
			CompanyName: company.get('name'),
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(setRatingsFERSABySupplier(serverResponse.data))
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to generated ratings from ptm', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to generated rating from PTM
 */
export function generateRatingsPTMBySupplier(month, year, supplier) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/generatedratingmanual/ptm/supplier`
		let company = getState().profile.get('selectedCompany')
		let data = {
			Month: month,
			Year: year,
			CompanyToken: company.get('token'),
			CompanyName: company.get('name'),
			SupplierCode: supplier ? supplier.code : '',
			SupplierName: supplier ? supplier.name : '',
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(setRatingsPTMBySupplier(serverResponse.data))
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to generated ratings from ptm', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to generated PDF rating from PTM
 * @param {*} mont
 * @param {*} year
 */
export function generatePDFRatingsPTM(month, year) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))
		let company = getState().profile.get('selectedCompany')
		let data = {
			Month: month,
			Year: year,
			CompanyToken: company.get('token'),
			CompanyName: company.get('name'),
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/ratingautomatic/generatedPDF/ptm`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var file = new Blob([serverResponse.data], { type: 'application/pdf' })
					var fileURL = URL.createObjectURL(file)
					window.open(fileURL)
					dispatch(isSaving(false))
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in download pdf rating ptm ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to Download ratings by dates
 *
 * @export
 * @param {*} month
 * @returns
 */
export function downloadReportByDateRange(initialDate, finalDate) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let companyToken = getState().profile.get('selectedCompany').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rating/downloadRatingByYear/${companyToken}/${initialDate}/${finalDate}`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Reporte_de_calificaciones_de_proveedores.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in download report rating by year', error.response)
				return Promise.reject()
			})
	}
}

export function downloadReportBySupplierByDateRange(supplierNumber, initialDate, finalDate) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let companyToken = getState().profile.get('selectedCompany').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rating/downloadRatingBySupplier/${companyToken}/${supplierNumber}/${initialDate}/${finalDate}`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_calificaciones_por_proveedor.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in download report rating by supplier', error.response)
				return Promise.reject()
			})
	}
}

export function generateReportExcelRatingPlanningPTM(actualMonth, actualYear) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/rating/${actualYear}/${actualMonth}/download`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_calificaciones_entregas.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in download report rating by supplier', error.response)
				return Promise.reject()
			})
	}
}

export function generateReportExcelRatingQualityPTM(actualMonth, actualYear) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rating/ptm/${actualYear}/${actualMonth}/download`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_calificaciones_calidad.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in download report rating by supplier', error.response)
				return Promise.reject()
			})
	}
}

export function generateReportExcelRatingServicePTM(actualMonth, actualYear) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rating/ptm//${actualYear}/${actualMonth}/download`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_calificaciones_servicios.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in download report rating by supplier', error.response)
				return Promise.reject()
			})
	}
}
