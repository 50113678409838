import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

/** Material-UI imports section */
import { Translate, getTranslate } from "react-localize-redux";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
    ListItemIcon, ListItemAvatar, Avatar, Tooltip, Collapse,
} from '@material-ui/core';
import ExcludeIcon from '@material-ui/icons/ListAlt'; 
import ContactsIcon from '@material-ui/icons/SupervisedUserCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import SuppliersIcon from '@material-ui/icons/Group';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import DownloadIcon from '@material-ui/icons/CloudDownload';

/** Import component section */
import LoadingPage from '../common/LoadingPage';
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import { converListToArray } from '../../store/helpers/ListHelper';
import { ROLE_COMPANYADMINISTRATOR, IsUserInRole } from '../../store/helpers/RolesHelper';

import { loadContactSupplier, downloadContactSuppliersUpdateExcelReport } from '../../store/contactsupplier/ContactSupplierAction';

/**
 * Show ethic code
 */
class Show extends Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openSuppliersList: true,
            openSuppliers:true,
        }
    }

     /**
    * componentDidMount
    */
    componentDidMount() {
        let contactSupplierToken = this.props.match.params.token;
        this.props.loadContactSupplier(contactSupplierToken);
    }

      /**
     * Handle state to show or hide suppliers list
     *
     * @memberof Show
     */
    handleExpandSuppliersList() {
        this.setState({
            openSuppliersList: !this.state.openSuppliersList
        })
    }

    handleExpandSuppliersListExclude() {
        this.setState({
            openSuppliers: !this.state.openSuppliers
        })
    }

    /**
     * render Collapsable section of suppliers list
     *
     * @param {*} idTranslation
     * @param {*} value
     * @param {*} icon
     * @returns
     * @memberof Show
     */
    renderSuppliersList(idTranslation, value, icon, ) {
        return (
            <React.Fragment>
                <Collapse in={this.state.openSuppliersList} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {value.map(supplier => {
                            return (
                                <React.Fragment key={supplier.token} >
                                    <ListItem key={supplier.share_with_supplier} component='li' className={this.props.classes.nested}>
                                        <ListItemIcon style={{ marginLeft: '3rem', marginRight: '0rem' }}>
                                            <Tooltip title={<Translate id={idTranslation} />}>
                                                {icon}
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary={supplier.share_with_supplier} />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                    </List>
                </Collapse>
                <Divider />
            </React.Fragment>
        )
    }

        /**
     * Render item in list of official notice detail
     *
     * @param {*} idTranslation
     * @param {*} value
     * @param {*} icon
     * @param {*} isLink
     * @param {*} isButton
     * @returns
     * @memberof Show
     */
    renderItemList(idTranslation, value, icon, isButton, isBoolean) {
        return (
            <React.Fragment>
                <ListItem key={idTranslation}
                    button={isButton}
                    onClick={() => {
                        if (isButton)
                            this.handleExpandSuppliersList();
                    }}>
                    <ListItemIcon>
                        <Tooltip title={<Translate id={idTranslation} />}>
                            {icon}
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={this.props.classes.inline} color="textPrimary">
                                    <Translate id={idTranslation} />
                                </Typography>
                                {!isButton ?
                                    (
                                        (!isBoolean ?
                                            (" " + value)
                                            : '')
                                    ) : ''
                                }
                            </React.Fragment>
                        } />
                    {isButton && (this.state.openSuppliersList ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
                <Divider />
            </React.Fragment>
        )
    }
    
    renderSuppliersExpander(){
        return(
            <React.Fragment>
                <ListItem key={<Translate id="contactsupplier.supplierToExluded" />}
                    button={true} onClick={() => { this.handleExpandSuppliersListExclude();}}>
                    <ListItemIcon>
                        <Tooltip title={<Translate id="contactsupplier.supplierToExluded" />}>
                            {<SuppliersIcon />}
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={this.props.classes.inline} color="textPrimary">
                                    <Translate id="contactsupplier.supplierToExluded" />
                                </Typography>
                            </React.Fragment>
                        } />
                    {(this.state.openSuppliers ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
                <Divider />
            </React.Fragment>
        )
    }

     /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoadingcontactSupplier === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.contactSupplier !== null) {
            const { classes, contactSupplier } = this.props;
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Paper>
                            <List>
                                <ListItem divider>
                                    <ListItemAvatar>
                                        <Avatar ><ContactsIcon /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography variant="h5" className={classes.inline} color="textPrimary">
                                                    {contactSupplier.name}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <ExcludeIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <React.Fragment >
                                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                                    {contactSupplier.contact_type === 1 && <Translate id="contactsupplier.contactTypeTemplate" />}
                                                    {contactSupplier.contact_type === 2 && <Translate id="contactsupplier.contactTypeExclude" />}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                {contactSupplier.is_accepted_by_supplier && this.renderItemList("ethicCode.isAcceptRequired", contactSupplier.is_accepted_by_supplier, <CheckCircleIcon />, false, true)}
                                {contactSupplier.is_accepted_by_supplier && <React.Fragment>
                                    {IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && <ListItem >
                                        <Link color="primary" onClick={() => this.props.downloadContactSuppliersUpdateExcelReport(this.props.contactSupplier.token)} to="#">
                                            <DownloadIcon />
                                            {" "}{<Translate id="contactsupplier.downloadReportcontacts" />}
                                        </Link>
                                    </ListItem>}
                                    <Divider /></React.Fragment>
                                }

                                {this.renderItemList("officialNotices.suppliers", contactSupplier.share_with_suppliers, <SuppliersIcon />, true, true)}
                                {this.renderSuppliersList("common.supplier", contactSupplier.share_with_suppliers, <PersonIcon color='secondary' />)}

                                {contactSupplier.contact_type === 2 && this.renderSuppliersExpander()}
                                <Collapse in={this.state.openSuppliers} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                    {contactSupplier.extude_suppliers.size &&  <ListItem  component='li' className={this.props.classes.nested}>
                                        {<Translate id="contactsupplier.withoutSuppliersExclide" />}
                                    </ListItem>}
                                     {contactSupplier.extude_suppliers.map(supplier => {
                                        return (
                                            <ListItem divider key={supplier.supplier_name} component='li' className={this.props.classes.nested}>
                                                <span style={{ marginLeft: '3rem', marginRight: '0rem' }}>
                                                    {supplier.supplier_name}
                                                </span>
                                            </ListItem>)
                                        })}
                                    </List>
                                </Collapse>
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            )
        }
    }

    render() {
        return (
            <Card>
                <CardContent>
                    {this.getCardContent()}
                </CardContent>
            </Card>
        )
    }
}


/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        user: user,
        isLoadingUser: state.oidc.isLoadingUser,
        isLoadingcontactSupplier: state.contactSuppliers.get("isLoadingcontactSupplier"),
        contactSupplier: state.contactSuppliers.get('contactSupplier') ? state.contactSuppliers.get('contactSupplier').toJS() : null,
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
        translate: getTranslate(state.localize)
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadContactSupplier: (contactsupplierToken) => {
            return dispatch(loadContactSupplier(contactsupplierToken));
        },
        downloadContactSuppliersUpdateExcelReport: (noticeToken) => {
            return dispatch(downloadContactSuppliersUpdateExcelReport(noticeToken))
        }
        
    }
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    actionsToolbar: {
        display: "flex"
    }
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));