/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
/**Import material UI Section*/
import { withStyles, Paper, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import AddtIcon from '@material-ui/icons/Add'

const drawerWidth = 250
const BiddingBaseLeftPanel = (props) => {
	/**String props section */
	const { classes } = props
	/**Bools props section */
	const { open } = props
	/**Functions props section */
	const { handleDrawerOpenClose, onButtonCreateClick } = props

	return (
		<Paper
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
		>
			<div>{props.sideBarChildren}</div>
			<div className={open ? classes.footerOpened : classes.footerClosed}>
				<ListItem button onClick={onButtonCreateClick}>
					<ListItemIcon>
						<AddtIcon color='primary' />
					</ListItemIcon>
					<ListItemText primary={open ? 'Crear' : null} />
				</ListItem>
				<ListItem button onClick={handleDrawerOpenClose}>
					<ListItemIcon>
						{open ? <ChevronLeftIcon color='primary' /> : <ChevronRightIcon color='primary' />}
					</ListItemIcon>
				</ListItem>
			</div>
		</Paper>
	)
}

const styles = (theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		maxHeight: `calc(100vh - ${185}px)`,
		minHeight: `calc(100vh - ${185}px)`,
		display: 'grid',
		gridTemplateRows: '1fr auto',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: '55px',
	},
	btnFloating: {
		position: 'fixed',
		bottom: '100px',
	},
	footerOpened: {
		display: 'grid',
		gridTemplateColumns: '2fr 1fr',
	},
	footerClosed: {
		display: 'grid',
		gridTemplateColumns: '1fr',
	},
	footerButton: {
		maxWidth: '20px',
	},
})

BiddingBaseLeftPanel.propTypes = {
	bidding: PropTypes.object,
}

BiddingBaseLeftPanel.defaultProps = {
	bidding: null,
}

export default withRouter(withStyles(styles, { withTheme: true })(BiddingBaseLeftPanel))
