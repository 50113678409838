/**Import react section */
import React from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
/**Import MaterialUi section */
import {
    Grid, Typography, Card, CardHeader
} from '@material-ui/core'
/**Import store section */
import {
    BACKGROUND_COLOR_GRAY_BIDDING
} from '../../../../store/helpers/StatusColorConstants'
import HtmlEditor from '../../../common/HtmlEditor'
const CommentsComponent = (props) => {
    /**props section */
    const { comments, classes, title, isVisibleHtmlEditor
    } = props

    return (
        < Grid container >
            <Grid item xs={12}>
                <Card
                    elevation={8}
                    className={classes.card}
                >
                    <CardHeader
                        title={<Typography variant="subtitle1" align="justify" color="primary" className={classes.fontWeight}>{title}</Typography>}
                        subheader={isVisibleHtmlEditor ?
                            <HtmlEditor
                                initialValue={comments ? comments : ""}
                                isDisabled={true}
                            /> :
                            <Typography variant="subtitle2" align="justify">{comments}</Typography>

                        }
                    />


                </Card>
            </Grid>


        </Grid>
    )
}

const styles = theme => ({
    card: {
        border: `1px solid ${BACKGROUND_COLOR_GRAY_BIDDING}`
        // backgroundColor: COLOR_STATUS_ALERT_INFO
    },
    fontWeight: {
        fontWeight: "bold"
    }
});
export default withRouter(withStyles(styles, { withTheme: true })(CommentsComponent))