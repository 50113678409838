/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'

export const START_LOADING_SHIPPING_ORDERS = 'START_LOADING_SHIPPING_ORDERS'
export const START_LOADING_LOAD_ALERTS = 'START_LOADING_LOAD_ALERTS'
export const SET_SHIPPING_ORDERS_LIST = 'SET_SHIPPING_ORDERS_LIST'

export const CHANGE_SHIPPING_ORDERS_SORT_CRITERIA = 'CHANGE_SHIPPING_ORDERS_SORT_CRITERIA'
export const CHANGE_SHIPPING_ORDERS_QUERY = 'CHANGE_SHIPPING_ORDERS_QUERY'
export const CHANGE_SHIPPING_ORDERS_PAGINATION = 'CHANGE_SHIPPING_ORDERS_PAGINATION'
export const SET_SHIPPING_ORDERS_COUNT = 'SET_SHIPPING_ORDERS_COUNT'
export const IS_LOADING_SHIPPING_ORDER = 'IS_LOADING_SHIPPING_ORDER'
export const SET_SHIPPING_ORDER = 'SET_SHIPPING_ORDER'
export const ADD_FILES_SHIPPING_ORDER_SUPPLIER = 'ADD_FILES_SHIPPING_ORDER_SUPPLIER'
export const REMOVE_FILES_SHIPPING_ORDER_SUPPLIER = 'REMOVE_FILES_SHIPPING_ORDER_SUPPLIER'
export const IS_SAVING_SHIPPING_ORDER = 'IS_SAVING_SHIPPING_ORDER'
export const UPDATE_DETAIL_FROM_SHIPPING_ORDER = 'UPDATE_DETAIL_FROM_SHIPPING_ORDER'

/**
 * Action start loading shipping orders
 * @param {*} isLoading
 */
function isLoadingShippingOrders(isLoading) {
	return {
		type: START_LOADING_SHIPPING_ORDERS,
		isLoading: isLoading,
	}
}

export default function changeShippingOrdersPagination(page, rowsPerPage) {
	return {
		type: CHANGE_SHIPPING_ORDERS_PAGINATION,
		page: page,
		rowsPerPage: rowsPerPage,
	}
}

export function changeShippingOrdersQuery(value) {
	return {
		type: CHANGE_SHIPPING_ORDERS_QUERY,
		query: value,
	}
}

/**
 * Action to set shipping orders list
 * @param {*} shippingOrders
 */
function setShippingOrdersist(shippingOrders) {
	return {
		type: SET_SHIPPING_ORDERS_LIST,
		shippingOrders: shippingOrders,
	}
}

/**
 * Action to start load shipping order
 */
function startLoadShippingOrder(isLoading) {
	return {
		type: IS_LOADING_SHIPPING_ORDER,
		isLoadingShipping: isLoading,
	}
}

/**
 * Action to set shipping order
 * @param {*} shippingOrder
 */
function setShippingOrder(shippingOrder) {
	return {
		type: SET_SHIPPING_ORDER,
		shippingOrder: shippingOrder,
	}
}

/**
 * Action reducer to set the shipping order's counter
 *
 * @param {*} count
 * @returns
 */
function setShippingOrdersCount(count) {
	return {
		type: SET_SHIPPING_ORDERS_COUNT,
		count,
	}
}

/**
 * Add file to state by supplier
 *
 * @param {*} files
 * @returns
 */
function addFileBySupplierShipping(files, listItems) {
	return {
		type: ADD_FILES_SHIPPING_ORDER_SUPPLIER,
		files,
		listItems,
	}
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFileBySupplierShipping(fileName, listItems) {
	return {
		type: REMOVE_FILES_SHIPPING_ORDER_SUPPLIER,
		fileName,
		listItems,
	}
}

/**
 * Action start loading samples
 * @param {*} isLoading
 */
function isSaving(isSaving) {
	return {
		type: IS_SAVING_SHIPPING_ORDER,
		isSaving: isSaving,
	}
}

/**
 * Action to update annotation fro RMI
 * @param {*} annotation
 */
function updateDetailShippingOrder(detail) {
	return {
		type: UPDATE_DETAIL_FROM_SHIPPING_ORDER,
		detail: detail,
	}
}

/**
 * Get all shipping order's by company from backend
 */
export function loadShippingOrdersFromBackEnd(
	query,
	orderBy,
	sortAscending,
	page,
	rowsPerPage,
	modeView = 'ShippingOrders'
) {
	return function (dispatch, getState) {
		dispatch(isLoadingShippingOrders(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/filters`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
			ModeView: modeView,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setShippingOrdersist(serverResponse.data))

					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingShippingOrders(false))
				console.error('Error getting the Shipping orders by company list', error.response)
			})
	}
}

/**
 * Get Shipping Order's count
 *
 * @export
 * @returns
 */
export function getShippingOrdersCount(modeView = 'ShippingOrders') {
	return function (dispatch, getState) {
		let actualCounter = getState().shippingOrders.get('shippingOrdersCount')
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
			ModeView: modeView,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setShippingOrdersCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the Shipping's count", error.response)
			})
	}
}

/**
 * Get and set the shipping in the estate from read from backend
 * @param {*} token
 */
export function loadShippingOrderFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(startLoadShippingOrder(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setShippingOrder(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to load Shipping Order', error.response)
				dispatch(startLoadShippingOrder(false))
				return Promise.reject()
			})
	}
}

export function editCustomsData(data) {
	const { token, advance_agent_customs_payment, advance_agent_customs_reference, observations } = data
	return function (dispatch, getState) {
		dispatch(isSaving(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/customsedit`
		let data = {
			AdvanceAgentCustomsData: advance_agent_customs_payment,
			AdvanceAgentCustoms: advance_agent_customs_reference,
			Observations: observations,
			Token: token,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setShippingOrder(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to load Shipping Order', error.response)
				dispatch(isSaving(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to upload files to supplier from sample
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadFilesShippingOrder(responseToken, files, folder, listItems) {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/${responseToken}/${folder}`
		let formData = new FormData()
		for (var index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFileBySupplierShipping(serverResponse.data, listItems))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload sample files from supplier file(s)', error.response)
				dispatch(isSaving(false))

				return Promise.reject()
			})
	}
}

/**
 * Action to remove files to supplier from saplme
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeFilesShippingOrder(responseToken, fileName, folder, listItems) {
	return function (dispatch, getState) {
		dispatch(isSaving(true))

		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/${responseToken}//remove/${folder}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFileBySupplierShipping(fileName, listItems))
					dispatch(isSaving(false))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSaving(false))

				console.error('Error to upload shipping order file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Method to update rmi document information basic
 */
export function updateShippingResponse(responseToken, observtions) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/response`
		let data = {
			Token: responseToken,
			Observations: observtions,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
				}
			})
			.catch((error) => {
				console.error('Error to load quote', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Method to update rmi document information basic
 */
export function updateShippingOrder(token, dateTimeEta) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders`
		let data = {
			Token: token,
			DateTimeEta: dateTimeEta,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setShippingOrder(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load quote', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to accepted In Rejected Detail shipping order By Client
 */
export function acceptedPriceInItemDetailBySupplier(itemToken, status, shippingOrderToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			ShippingOrderToken: shippingOrderToken,
			ItemToken: itemToken,
			Status: status,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/details`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateDetailShippingOrder(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in accept or reject detail from shipping order request ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to send Send Shipping Order To Client
 */
export function sendSendShippingOrderToClient(token) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			NameUserCreator: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/${token}/sendtoclient`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setShippingOrder(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in send shipping orde rto client ', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function foreignTradeSendInformation(token, isImportRequest = '') {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			NameUserCreator: user.profile.name,
			IsImportRequest: isImportRequest,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/${token}/foreignTradeSendInformation`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setShippingOrder(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in send aditional documents shipping orde rto client ', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function sendAditionalDocumentsShippingOrderToClient(token, isImportRequest = '') {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			NameUserCreator: user.profile.name,
			IsImportRequest: isImportRequest,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/${token}/sendaditionaldocumentstoclient`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setShippingOrder(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in send aditional documents shipping orde rto client ', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function getDocumentsToCustomsBroker(token) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/${token}/getdocumentsdatatocustomsbrokeronsip`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in get documents to customs broker', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to cancel a shipping order document
 *
 * @export
 * @param {*} token
 * @returns
 */
export function cancelShippingOrderOrder(token, razonForCancel) {
	return function (dispatch, getState) {
		dispatch(isSaving(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user

		let data = {
			CancelUserName: user.profile.preferred_username,
			CancelBy: user.profile.name,
			ShippingToken: token,
			ReasonCancellation: razonForCancel,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/cancelshipping`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					dispatch(setShippingOrder(serverResponse.data))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to cancel a shipping order', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to forward shipping order to supplier to correct documents
 *
 * @export
 * @param {*} token
 * @returns
 */
export function forwardTheSupplierToCorrectDocuments(token, observations) {
	return function (dispatch, getState) {
		dispatch(isSaving(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user

		let data = {
			CreatorUserName: user.profile.preferred_username,
			CreatedBy: user.profile.name,
			ShippingToken: token,
			Observations: observations,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/forwardthesuppliertocorrectdocuments`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					dispatch(setShippingOrder(serverResponse.data))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to forward shipping order to supplier to correct documents', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 *Action to download report sac's development by range date
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadShippingOrdersReportByRangeDate(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(startLoadShippingOrder(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/shippingorders/downloadByRangeDate`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(startLoadShippingOrder(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reportes_de_importaciones.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(startLoadShippingOrder(false))
				console.error('Error downloading shipping orders report by month', error.response)
				return Promise.reject()
			})
	}
}
