import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import uuidv4 from 'uuid/v4'
import Select from 'react-select';

/** Material-UI imports section */
import {
	Typography,
	Paper,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	IconButton,
	Tooltip,
	TextField,
	Fab
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import AddIcon from '@material-ui/icons/AddTwoTone';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import EmailIcon from '@material-ui/icons/ContactMail';

/** section import components */
import { BACKGROUND_COLOR_GRAY_DARK, COLOR_STATUS_WARNING } from '../../../store/helpers/StatusColorConstants';
import DataTable from '../../common/DataTable';
import AddMaterialModal from './AddMaterialModal';
import AddMoldModal from './AddMoldModal';
import DialogUpdateDate from './DialogUpdateDate';
import PanelFilesMaterial from './PanelFilesMaterial';
import MaterialMachinedPanelDetail from './MaterialMachinedPanelDetail'
import HtmlEditor from '../../common/HtmlEditor';
import Dropzone from '../../common/Dropzone';
import HistorialConsultationTable from './HistorialConsultationTable'
import AddToolingModal from './AddToolingModal'

import {
	canUpdateMaquilaOrder,
	canBeModulePlaneationSupplier,
	IsUserInRole,
	ROLE_MAQUILAORDER_MOLDE,
	ROLE_QUALITYENGINEER,
	ROLE_WAREHOUSEMAN,
	ROLE_WAREHOUSERAWMATERIAL
} from '../../../store/helpers/RolesHelper';
import { IsUserSupplierAdministrator } from '../../../store/helpers/SessionHelper';
import {
	Maquila_Orders_Send_Supplier, Maquila_Orders_Send_Warehouse,
	Maquila_Orders_Valid_Supplier, Draft
} from '../../../store/helpers/DevelopmentHelper'

import { mappedSuppliersExternalOptions } from '../../../store/helpers/SelectOptions';

/**
 * Component ot render Information partial maquila Order
 */
export default class MaterialsMaquilads extends Component {
	/**
	 * Create an instance shipping Order
	 * @param {*} props
	 */
	constructor(props) {
		super(props);
		autoBind(this);
		this.state = {
			openAddMaterial: false,
			openAddMold: false,
			openUpdateMaterial: false,
			openAddTooling: false,
			itemUpdate: {
				token: '',
				recollection_date: '',
				transport: null,
				date_update: ''
			},
			openPanelSupplier: false,
			itemSelected: null,
			isEvidence: false,
			userSupplier: null,
			openPanelTooling: false,
			itemToolingSelected: null,
		};
	}

	onAddMaterialClick() {
		this.setState({
			openAddMaterial: true
		});
	}

	onUpdateMaterialClick(item) {
		this.setState({
			openUpdateMaterial: true,
			itemUpdate: item
		});
	}

	/**
	 * On upload file in maquila order in list name
	 * @param {*} files 
	 * @param {*} maquilaToken 
	 * @param {*} listName 
	 */
	onChangeFile(files, maquilaToken, listName) {
		return this.props.uploadFilesMaquilaOrder(maquilaToken, files, listName)
			.then(() => {
				return Promise.resolve('Ok');
			}).catch(() => {
				return Promise.reject();
			});
	}

	/**
	 * On remove files of dropzone 
	 *
	 * @param {*} event
	 * @memberof DetailsCard
	 */
	onDeleteFile(fileName, maquilaToken, listName) {
		return this.props.removeFilesMaquilaOrder(maquilaToken, fileName, listName)
			.then(() => {
				return Promise.resolve('Ok');
			}).catch(() => {
				return Promise.reject();
			});
	}

	onUpdateMaterial() {
		let itemUpdate = { ...this.state.itemUpdate };
		if (this.props.onUpdateMaterialItem) {
			this.props.onUpdateMaterialItem(itemUpdate);
			this.setState({ openUpdateMaterial: false });
		}
	}

	updateProperty(property, value) {
		let itemUpdate = { ...this.state.itemUpdate };
		itemUpdate[property] = value;
		if (property === "recollection_date") {
			itemUpdate.date_update = value;
		}
		this.setState({
			itemUpdate: itemUpdate
		});
	}

	/**
	 * Function on update file
	 */
	onSaveItemUpdate = (itemMolde) => {
		if (this.props.onAddMoldInMaquila) {
			this.props.onAddMoldInMaquila(itemMolde).then(() => {
				this.setState({ openAddMold: false });
			});
		}
	};

	onSaveTooling = (tooling) => {
		if (this.props.onAddToolingInMaquila) {
			this.props.onAddToolingInMaquila(tooling).then(() => {
				this.setState({ openAddTooling: false });
			});
		}
	};

	/**
	 * Render material
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderMaterialItem(dataSource, item) {
		if (dataSource === 'detail_machined_material_code') {
			return item.position ? `${item.detail_machined_material_code} - ${item.detail_machined_material_description}`:"-";
		}
		if (dataSource === 'position') {
			return item.position ? item.position.replace(/^(0+)/g, '') :"-";
		}
		if (dataSource === 'recollection_date') {
			return item.recollection_date;
		}
		if (dataSource === 'transport') {
			return `${item.transport_code} ${item.transport_name}`;
		}
		if (dataSource === 'files') {
			let canBeEdit = (IsUserInRole(ROLE_WAREHOUSEMAN, this.props.userRolesByCompany) || IsUserInRole(ROLE_WAREHOUSERAWMATERIAL, this.props.userRolesByCompany) ||
				canUpdateMaquilaOrder(this.props.userRolesByCompany)) && (this.props.maquilaOrder.can_be_edit || this.props.maquilaOrder.actual_operation === Maquila_Orders_Send_Warehouse);
			return (
				<div style={{ width: "150px" }}>
					<Tooltip title={<Translate id="maquilaOrders.files" />}>
						<IconButton color="primary" aria-label="More" onClick={() => this.onOpenPanelFiles(item)}>
							<AttachFileIcon style={{ color: item.attachments.length === 0 ? '' : COLOR_STATUS_WARNING }} />
						</IconButton>
					</Tooltip>
					{canBeEdit && (
						<Tooltip title={<Translate id="maquilaOrders.addReconnectionDate" />}>
							<IconButton aria-label="More" onClick={() => this.onUpdateMaterialClick(item)}>
								<EditIcon />
							</IconButton>
						</Tooltip>
					)}
					{canUpdateMaquilaOrder(this.props.userRolesByCompany) && this.props.maquilaOrder.can_be_edit && (
						<Tooltip title={<Translate id="maquilaOrders.deleteLotMaterial" />}>
							<IconButton
								color="inherit"
								aria-label="More"
								onClick={() => this.props.onRemoveMaterialMachined(item.token)}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					)}
				</div>
			);
		}
	}

	/**
	 * Render molds
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItemMold(dataSource, item) {
		const canBeUpdateSupplier =
			(canBeModulePlaneationSupplier(this.props.userRolesByCompany) ||
				IsUserSupplierAdministrator(this.props.currentUser)) &&
			this.props.maquilaOrder.can_be_edit_supplier;
		if (dataSource === 'options') {
			return (
				<Tooltip title={<Translate id="common.delete" />}>
					<DeleteIcon
						style={{ cursor: 'pointer' }}
						color="primary"
						onClick={() => this.props.onDeleteMoldInMaquila(item.token)}
					/>
				</Tooltip>
			);
		}
		if (
			dataSource === 'ciclo_segunds' ||
			dataSource === 'cavidad_molde' ||
			dataSource === 'medida_molde_externa' ||
			dataSource === 'medida_liberacion'
		) {
			if (canBeUpdateSupplier) {
				return this.renderTextField(dataSource, item);
			} else {
				return (
					<Typography gutterBottom variant="caption" component="p">
						{item[dataSource]}
					</Typography>
				);
			}
		}
		if (
			dataSource === 'peso_completo' ||
			dataSource === 'peso_pieza_colado' ||
			dataSource === 'peso_colado' ||
			dataSource === 'peso_pieza' ||
			dataSource === 'peso_pieza_considerado'
		) {
			if (canBeUpdateSupplier) {
				return this.renderTextField(dataSource, item, 'number');
			} else {
				return (
					<Typography gutterBottom variant="caption" component="p">
						{item[dataSource]}
					</Typography>
				);
			}
		}
	}

	onRenderCellItemTooling(dataSource, item) {
		if (dataSource === 'files') {
			return(
				<div style={{ width: "80px" }}>
					<Tooltip title={<Translate id="maquilaOrders.files" />}>
						<IconButton color="primary" aria-label="More" onClick={() => this.onOpenPanelToolingFiles(item)}>
							<AttachFileIcon style={{ color: item.attachments.length === 0 ? '' : COLOR_STATUS_WARNING }} />
						</IconButton>
					</Tooltip>
					{canUpdateMaquilaOrder(this.props.userRolesByCompany) && this.props.maquilaOrder.can_be_edit && (
						<Tooltip title={<Translate id="common.delete" />}>
							<DeleteIcon
								style={{ cursor: 'pointer' }}
								color="primary"
								onClick={() => this.props.onDeleteToolingInMaquila(item.token)}
							/>
						</Tooltip>
					)}
				</div>
			)
		}
	}

	/**
	 * renderTextField
	 * @param {*} dataSource
	 * @param {*} item
	 * @param {*} type
	 */
	renderTextField(dataSource, item, type = 'text') {
		return (
			<TextField
				id={dataSource}
				defaultValue={item[dataSource]}
				margin="normal"
				type={type}
				onBlur={(event) => this.props.onSaveMoldeFromSupplier(item.token, dataSource, event.target.value)}
				onKeyDown={this.onKeyDown}
				step={type === 'type' ? 0.5 : ''}
				inputProps={{
					style: { fontSize: 12 }
				}}
			/>
		);
	}

	/**
	 * On render files shipping order
	 * @param {*} title
	 * @param {*} onChange
	 * @param {*} onDelete
	 * @param {*} items
	 */
	renderFilesMaquilas(titleTranslate, maquilaToken, listName, items, isActive) {
		return (
			<TableRow key={uuidv4()}>
				<TableCell key={uuidv4()} align="left" padding="dense" style={{ maxWidth: '30%' }}>
					{<Translate id={'maquilaOrders.' + titleTranslate} />}
				</TableCell>
				<TableCell key={uuidv4()} align="left" padding="dense" style={{ maxWidth: '40%' }}>
					<Dropzone
						baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
						onChange={(event) => this.onChangeFile(event, maquilaToken, listName)}
						onDeleteFile={(name) => this.onDeleteFile(name, maquilaToken, listName)}
						isDisabled={!isActive}
						files={items}
					/>
				</TableCell>
			</TableRow>
		);
	}

	/**
	 * Open selected option
	 * @param {*} item
	 */
	onOpenPanelFiles(item) {
		this.setState({
			openPanelSupplier: true,
			itemSelected: item
		});
	}

	/**
	 * Open add mold
	 * @param {*} item
	 */
	onOpenAddMold() {
		this.setState({
			openAddMold: true
		});
	}

	onSupplierChange(option) {
		this.setState({
			userSupplier: option
		});
	}

	onOpenTooling() {
		this.setState({
			openAddTooling: true
		});
	}

	onOpenPanelToolingFiles(item) {
		this.setState({
			openPanelTooling: true,
			itemToolingSelected: item
		});
	}

	/**
	 * Render
	 */
	render() {
		const { classes, maquilaOrder, userRolesByCompany, allUsersSupplier } = this.props;
		const canBeUpdateSupplier =
			(canBeModulePlaneationSupplier(this.props.userRolesByCompany) || IsUserSupplierAdministrator(this.props.currentUser)) &&
			maquilaOrder.can_be_edit_supplier;
		let canBeEditDevelopment =
			canUpdateMaquilaOrder(userRolesByCompany) && maquilaOrder.can_be_add_files && maquilaOrder.can_be_edit;
		let canBeEditWarehouse = (IsUserInRole(ROLE_WAREHOUSEMAN, userRolesByCompany) || IsUserInRole(ROLE_WAREHOUSERAWMATERIAL, userRolesByCompany) ||
			canUpdateMaquilaOrder(userRolesByCompany)) &&
			maquilaOrder.can_be_add_files && (maquilaOrder.actual_operation === Maquila_Orders_Send_Warehouse || maquilaOrder.actual_operation === Draft);
		let canBeEdit = canBeUpdateSupplier || canBeEditDevelopment;
		let canBeEdirMolde =
			IsUserInRole(ROLE_MAQUILAORDER_MOLDE, userRolesByCompany) &&
			maquilaOrder.can_be_add_files &&
			(maquilaOrder.actual_operation === Maquila_Orders_Send_Supplier || maquilaOrder.actual_operation === Maquila_Orders_Valid_Supplier) &&
			maquilaOrder.required_molde;
		let maquilaToken = maquilaOrder.token;
		let canBeEditQuality =
			IsUserInRole(ROLE_QUALITYENGINEER, userRolesByCompany) &&
			maquilaOrder.can_be_add_files &&
			(maquilaOrder.actual_operation === Maquila_Orders_Send_Supplier || maquilaOrder.actual_operation === Maquila_Orders_Valid_Supplier) &&
			maquilaOrder.required_quality;

		let externalUserOptions = allUsersSupplier.length !== 0 ? mappedSuppliersExternalOptions(allUsersSupplier) : [];
		return (
			<Paper>
				<List>
					<ListItem divider style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}>
						<ListItemText
							secondary={
								<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
									<strong> {<Translate id="maquilaOrders.materialsSendedMaquilador" />}</strong>
								</Typography>
							}
						/>
						<ListItemSecondaryAction>
							<Tooltip title={<Translate id="common.downloadReport" />}>
								<Link color="primary" onClick={() => this.props.downloadMaquilaOrderExcel(maquilaOrder.token)} to="#">
									<DownloadIcon style={{ cursor: 'pointer', marginRight: !maquilaOrder.can_be_edit ? '5' : '0' }} />
								</Link>
							</Tooltip>
							{canUpdateMaquilaOrder(this.props.userRolesByCompany) && (maquilaOrder.actual_operation === 1825 || 
								maquilaOrder.actual_operation === Maquila_Orders_Send_Warehouse ||
							 	maquilaOrder.actual_operation === 1828 ||  maquilaOrder.actual_operation === 1) && (
								<Tooltip title={<Translate id="maquilaOrders.addMaterial" />}>
									<IconButton color="primary" onClick={() => this.onAddMaterialClick()} edge="end" aria-label="add">
										<AddIcon />
									</IconButton>
								</Tooltip>
							)}
						</ListItemSecondaryAction>
					</ListItem>
				</List>
				<DataTable
					configuration={TableConfigurationSend(!maquilaOrder.can_be_edit)}
					data={maquilaOrder.material_machineds}
					onRenderCellItem={this.onRenderMaterialItem}
				/>

				{maquilaOrder.required_molde && (
					<List>
						<ListItem divider style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}>
							<ListItemText
								secondary={
									<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
										<strong> {<Translate id="maquilaOrders.moldsAndToling" />}</strong>
									</Typography>
								}
							/>
							<ListItemSecondaryAction>
								{!canBeEdirMolde && (
									<Tooltip title={<Translate id="maquilaOrders.addMold" />}>
										<IconButton color="primary" onClick={() => this.onOpenTooling()} edge="end" aria-label="add">
											<AddIcon />
										</IconButton>
									</Tooltip>
								)}
							</ListItemSecondaryAction>
						</ListItem>
					</List>
				)}

				{maquilaOrder.required_molde && (
					<DataTable
						configuration={TableConfigurationMolds(canBeEdirMolde)}
						data={maquilaOrder.maquila_molds}
						onRenderCellItem={this.onRenderCellItemMold}
					/>
				)}

				{maquilaOrder.required_tooling && (
					<List>
						<ListItem divider style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}>
							<ListItemText
								secondary={
									<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
										<strong> {<Translate id="maquilaOrders.toolingTable" />}</strong>
									</Typography>
								}
							/>
							<ListItemSecondaryAction>
								{!canBeEdirMolde && (
									<Tooltip title={<Translate id="maquilaOrders.addTooling" />}>
										<IconButton color="primary" onClick={() => this.onOpenTooling()} edge="end" aria-label="add">
											<AddIcon />
										</IconButton>
									</Tooltip>
								)}
							</ListItemSecondaryAction>
						</ListItem>
					</List>
				)}

				{maquilaOrder.required_tooling && (
					<DataTable
						configuration={TableConfigurationTooling(canBeEdirMolde)}
						data={maquilaOrder.toolings}
						onRenderCellItem={this.onRenderCellItemTooling}
					/>
				)}

				<AddMaterialModal
					open={this.state.openAddMaterial}
					onClose={() => {
						this.setState({ openAddMaterial: false });
					}}
					onSaveItem={this.props.onAddMaterialInList}
					isSaving={this.props.isSavingMaquila}
					isMessage={true}
					maquilaOrder={maquilaOrder}
				/>

				{this.state.openAddMold && (
					<AddMoldModal
						open={this.state.openAddMold}
						onCloseButtonClick={() => {
							this.setState({ openAddMold: false });
						}}
						onSaveItem={this.onSaveItemUpdate}
						isSaving={this.props.isSavingMaquila}
					/>
				)}

				{this.state.openAddTooling && (
					<AddToolingModal
						open={this.state.openAddTooling}
						onCloseButtonClick={() => {
							this.setState({ openAddTooling: false });
						}}
						onSaveItem={this.onSaveTooling}
						isSaving={this.props.isSavingMaquila}
						canBeAddFiles={false}
					/>
				)}

				{this.state.openPanelTooling && (
					<AddToolingModal
						open={this.state.openPanelTooling}
						onCloseButtonClick={() => {
							this.setState({ openPanelTooling: false });
						}}
						onSaveItem={this.onSaveTooling}
						isSaving={this.props.isSavingMaquila}
						canBeEditWarehouse={canBeEditWarehouse}
						itemToolingSelected={this.state.itemToolingSelected}
						canBeAddFiles={true}
						onUploadFilesToolings={this.props.uploadFilesToolings}
						onDeleteFilesTooling={this.props.deleteFilesTooling}
					/>
				)}

				{/** Dialog to DialogEditDates$ */}
				<DialogUpdateDate
					open={this.state.openUpdateMaterial}
					itemUpdate={this.state.itemUpdate}
					onClose={() => {
						this.setState({ openUpdateMaterial: false });
					}}
					updateProperty={this.updateProperty}
					onSaveItem={this.onUpdateMaterial}
					isSaving={this.props.isSavingMaquila}
				/>

				{this.state.openPanelSupplier && this.state.itemSelected && !this.state.itemSelected.is_new_version_partial_tems && (
					<PanelFilesMaterial
						onCloseButtonClick={() => {
							this.setState({ openPanelSupplier: false, itemSelected: null, isEvidence: false });
						}}
						isEvidence={this.state.isEvidence}
						isSaving={this.props.isSavingMaquila}
						itemSelected={this.state.itemSelected}
						dropzoneText={this.props.dropzoneText}
						onSaveItem={this.props.updateMaterialsFromSupplier}
						uploadFilesFromSupplier={this.props.uploadFilesFromSupplier}
						onDeleteAttachment={this.props.removeFilesFromSupplier}
						canBeEdit={canBeEdit}
						uploadFilesDetailMachineds={this.props.uploadFilesDetailMachineds}
						removeFilesDetailMachineds={this.props.removeFilesDetailMachineds}
						isMaquilaDraft={this.props.maquilaOrder.can_be_edit}
						updatProductMachineds={this.props.updatProductMachineds}
						canBeEditWarehouse={canBeEditWarehouse}
					/>
				)}

				{
					this.state.openPanelSupplier && this.state.itemSelected && this.state.itemSelected.is_new_version_partial_tems && 
					<MaterialMachinedPanelDetail
						onCloseButtonClick={() => {
							this.setState({ openPanelSupplier: false, itemSelected: null, isEvidence: false });
						}}
						isEvidence={this.state.isEvidence}
						isSaving={this.props.isSavingMaquila}
						itemSelected={this.state.itemSelected}
						dropzoneText={this.props.dropzoneText}
						uploadFilesFromSupplier={this.props.uploadFilesFromSupplier}
						onDeleteAttachment={this.props.removeFilesFromSupplier}
						onSaveItem={this.props.updateMaterialsFromSupplier}
						// canBeEdit={canBeEdit}
						uploadFilesDetailMachineds={this.props.uploadFilesDetailMachineds}
						removeFilesDetailMachineds={this.props.removeFilesDetailMachineds}
						// canBeEditWarehouse={canBeEditWarehouse}
						maquilaOrder={maquilaOrder}
						userRolesByCompany={userRolesByCompany}
						currentUser={this.props.currentUser}
					/>
				}

				<List>
					<ListItem divider style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}>
						<ListItemText
							secondary={
								<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
									<strong> {<Translate id="maquilaOrders.qualityFiles" />}</strong>
								</Typography>
							}
						/>
					</ListItem>
				</List>

				<Table style={{ maxWidth: '100%' }}>
					<TableHead className={classes.tableHead}>
						<TableRow>
							<TableCell>
								<Translate id="shippingOrder.documentName" />
							</TableCell>
							<TableCell>
								<Translate id="shippingOrder.documentOptions" />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.renderFilesMaquilas(
							'filesDeliverQuality',
							maquilaToken,
							'files_delivery_quality',
							this.props.maquilaOrder.files_delivery_quality,
							canBeEditQuality
						)}
					</TableBody>
				</Table>

				<List>
					<ListItem divider style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}>
						<ListItemText
							secondary={
								<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
									<strong> {<Translate id="maquilaOrders.commentsAdmin" />}</strong>
								</Typography>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
									{<Translate id="maquilaOrders.commentsAdmin" />}
									<HtmlEditor
										id={'comments'}
										labelVariant="caption"
										labelColor="textSecondary"
										isDisabled={!canBeEditDevelopment && !canBeEditQuality}
										initialValue={maquilaOrder.comments}
										onChange={(data) => this.props.updateCommentsMaquilaOrder('comments', data)}
									/>
								</Typography>
							}
						/>
					</ListItem>
				</List>

				{!maquilaOrder.is_closed && <List>
					<ListItem divider style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}>
						<ListItemText
							secondary={
								<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
									<strong> {"Notificaciones enviadas a"}</strong>
								</Typography>
							}
						/>
					</ListItem>
					{maquilaOrder.actual_operation === Maquila_Orders_Send_Supplier && canBeEditDevelopment && <ListItem divider >
						<ListItemText
							secondary={
								<div component="span" style={{ width: '500px' }}>
									<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
										{"Agregar usuario de planeación del proveedor para enviar notificación"}
									</Typography>
									<Select
										placeholder={<Translate id="expedientSupplier.sendNotification" />}
										className={classes.documentTypeSelect}
										options={externalUserOptions}
										onChange={this.onSupplierChange}
										styles={{
											menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
											menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
											menuPortal: (base) => ({ ...base, zIndex: 9999 }) /// THIS IS TO SHOW MENU OVER MODAL
										}}
										menuPosition="fixed" //THIS IS REQUIRED IN MODAL!!
										menuPlacement={'auto'}
										value={this.state.supplier}
									/>
								</div>
							}
						/>
						<ListItemSecondaryAction>
							<Tooltip title={<Translate id="expedientSupplier.sendNotification" />}>
								<Fab
									component="span"
									size="small"
									color="primary"
									style={{ marginLeft: '4px' }}
									disabled={this.state.userSupplier === null || this.props.isSavingMaquila}
									onClick={() =>
										this.props.onAddNotificationInUserSpplier(this.state.userSupplier)
									}
								>
									<EmailIcon />
								</Fab>
							</Tooltip>
						</ListItemSecondaryAction>
					</ListItem>}
					<ListItem>
						<ListItemText
							secondary={
								<DataTable
									data={maquilaOrder.users_notifications}
									configuration={{
										columns: [{
											header: <Translate id="officialNotices.name" />,
											dataSource: 'name',
										},
										{
											header: "Correo electrónico",
											dataSource: 'email',
										}]
									}}
								/>
							}
						/>
					</ListItem>
				</List>}
				<HistorialConsultationTable maquilaOrder={maquilaOrder} />

			</Paper>
		);
	}
}

function TableConfigurationSend(isDisabled) {
	let columns = [
		{
			header: <Translate id="maquilaOrders.materialToSend" />,
			dataSource: 'material'
		},
		{
			header: "Posición",
			dataSource: 'position'
		},
		{
			header: "Línea de pedido",
			dataSource: 'detail_machined_material_code'
		},
		{
			header: "Unidad de medida",
			dataSource: 'measurement_unit'
		},
		{
			header: <Translate id="maquilaOrders.amount" />,
			dataSource: 'quantity'
		},
		{
			header: <Translate id="maquilaOrders.transport" />,
			dataSource: 'transport'
		},
		{
			header: <Translate id="maquilaOrders.recollectionDate" />,
			dataSource: 'recollection_date'
		}
	];

	if (isDisabled) {
		columns.push({
			header: <Translate id="maquilaOrders.quantitySend" />,
			dataSource: 'partial_total_sent'
		});
		columns.push({
			header: <Translate id="maquilaOrders.quantityRecivide" />,
			dataSource: 'partial_total_received'
		});
	}

	columns.push({
		header: <Translate id="maquilaOrders.files" />,
		dataSource: 'files'
	});

	return { columns };
}

function TableConfigurationMolds(isDisabled) {
	let columns = [
		{
			header: <Translate id="maquilaOrders.moldDescription" />,
			dataSource: 'description'
		},
		{
			header: <Translate id="maquilaOrders.moldDimension" />,
			dataSource: 'medida_molde_externa'
		},
		{
			header: <Translate id="maquilaOrders.moldWeight" />,
			dataSource: 'peso'
		},
		{
			header: <Translate id="maquilaOrders.deadline" />,
			dataSource: 'delivery_date'
		},
		{
			header: <Translate id="maquilaOrders.cycleSeg" />,
			dataSource: 'ciclo_segunds'
		},
		{
			header: <Translate id="maquilaOrders.moldCavity" />,
			dataSource: 'cavidad_molde'
		}
	];
	columns.push({
		header: <Translate id="maquilaOrders.fullWeightWithCasting" />,
		dataSource: 'peso_completo'
	});
	columns.push({
		header: <Translate id="maquilaOrders.fullWeightWithoutCasting" />,
		dataSource: 'peso_pieza_colado'
	});
	columns.push({
		header: <Translate id="maquilaOrders.weightCasting" />,
		dataSource: 'peso_colado'
	});
	columns.push({
		header: <Translate id="maquilaOrders.weightForPiece" />,
		dataSource: 'peso_pieza'
	});
	columns.push({
		header: <Translate id="maquilaOrders.weightForPieceWithCasting" />,
		dataSource: 'peso_pieza_considerado'
	});
	columns.push({
		header: <Translate id="maquilaOrders.measuresReleasedPieces" />,
		dataSource: 'medida_liberacion'
	});

	if (isDisabled) {
		columns.push({
			header: '',
			dataSource: 'options'
		});
	}

	return { columns };
}

function TableConfigurationTooling(isDisabled) {
	let columns = [
		{
			header: "Material",
			dataSource: 'code'
		},
		{
			header: "Descripción",
			dataSource: 'description'
		},
		{
			header: "Cantidad",
			dataSource: 'quality'
		},
		{
			header: "Posición",
			dataSource: 'position'
		},
		{
			header: "Piezas",
			dataSource: 'measurement_unit'
		},
	];
	columns.push({
		header: <Translate id="maquilaOrders.files" />,
		dataSource: 'files'
	});

	return { columns };
}

/**
 * maquilaOrder
 */
MaterialsMaquilads.propTypes = {
	maquilaOrder: PropTypes.object.isRequired
};
