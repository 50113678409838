import { fromJS } from 'immutable'
import * as sparePartPriceChangeRequest from './sparePartPriceChangeAction'

export const sparePartPriceChangeInitialState = fromJS({
	isLoadingList: false,
	isLoadingPrice: false,
	isSaving: false,
	identifier: '',
	sortCriteria: {
		by: 'Folio',
		ascending: true,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	sparePartPriceChangeRequests: [],
	sparePartPriceChangeCount: 0,
	requestPriceChange: null,
	isLoadingSAP: false,
	prices: [],
	isDownloadPrices: false,
})

export default function sparePartPriceChangeReducer(state = sparePartPriceChangeInitialState, action) {
	switch (action.type) {
		case sparePartPriceChangeRequest.IS_SAVING: {
			return state.merge({
				isSaving: action.isSaving,
				identifier: action.identifier,
			})
		}
		case sparePartPriceChangeRequest.IS_LOADING_LIST_PRICES: {
			return state.merge({
				isLoadingList: action.isLoadingList,
			})
		}
		case sparePartPriceChangeRequest.GET_ALL_REQUESTS: {
			return state.merge({
				isLoadingPrice: false,
				sparePartPriceChangeRequests: fromJS(action.sparePartPriceChangeRequests),
			})
		}
		case sparePartPriceChangeRequest.SET_REQUESTS_COUNT: {
			return state.merge({
				sparePartPriceChangeCount: action.count,
			})
		}
		case sparePartPriceChangeRequest.CHANGE_REQUESTS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case sparePartPriceChangeRequest.CHANGE_REQUEST_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case sparePartPriceChangeRequest.CHANGE_REQUEST_QUERY: {
			return state.merge({
				searchQuery: action.query,
			})
		}
		case sparePartPriceChangeRequest.IS_LOADING_PRICE: {
			return state.merge({
				isLoadingPrice: action.isLoading,
			})
		}
		case sparePartPriceChangeRequest.SET_SPARE_PART_PRICE: {
			return state.merge({
				isLoadingPrice: false,
				requestPriceChange: fromJS(action.request),
			})
		}
		case sparePartPriceChangeRequest.ADD_FILE_SPARE_PART_PRICE: {
			let oldList = state.getIn(['requestPriceChange', 'attachments']).toJS()
			let filesToAdd = action.files.filter((newFile) => {
				return !oldList.find((uploadedFile) => {
					return uploadedFile.name === newFile.name
				})
			})
			let newList = [...oldList, ...filesToAdd]
			return state.setIn(['requestPriceChange', 'attachments'], fromJS(newList))
		}
		case sparePartPriceChangeRequest.REMOVE_FILE_SPARE_PART_PRICE: {
			let oldList = state.getIn(['requestPriceChange', 'attachments']).toJS()
			let newList = oldList.filter((file) => {
				return file.name !== action.fileName
			})
			return state.setIn(['requestPriceChange', 'attachments'], fromJS(newList))
		}
		case sparePartPriceChangeRequest.IS_LOADING_PRICE_SAP: {
			return state.merge({
				isLoadingSAP: action.isLoadingSAP,
			})
		}
		case sparePartPriceChangeRequest.SET_PRICES_SAP_LIST: {
			return state.merge({
				isLoadingSAP: false,
				prices: fromJS(action.prices),
			})
		}
		case sparePartPriceChangeRequest.IS_DOWNLOAD_PRICE_SAP: {
			return state.merge({
				isDownloadPrices: action.isDownloadPrices,
			})
		}
		default:
			return state
	}
}
