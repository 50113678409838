import { Button, Grid, IconButton, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core'
import { AddBox, AddCircle, CloudDownload, Delete } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
/** Resources import section */
const useStyle = (theme) => ({})

const ListDocumentComponent = (props) => {
	const { onLateralPanelConfig, documents, title, panel, deleteFiles, item, canBeEditData, isSaving } = props
	return (
		<List>
			<ListItem>
				<ListItemText
					primary={
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex', gap: '15px' }}>
								<AddBox />
								<Typography
									component='span'
									variant='body2'
									color='textSecondary'
									style={{ whiteSpace: 'nowrap' }}
								>
									<strong>
										{' '}
										<Translate id={title} />
									</strong>
								</Typography>
							</div>
							{canBeEditData && (
								<Button onClick={() => onLateralPanelConfig(panel, item)}>
									<Typography component='span' variant='body2' style={{ marginRight: '8px' }}>
										Agregar documento
									</Typography>
									<AddCircle style={{ color: '#4377C4' }} />
								</Button>
							)}
						</div>
					}
				/>
			</ListItem>
			{documents && documents.length > 0 && (
				<Grid container spacing={3}>
					{documents.map((document, index) => (
						<Grid item xs={12} sm={6} md={4} key={index}>
							<ListItem>
								<Paper
									style={{
										display: 'flex',
										alignItems: 'center',
										border: '1px solid black',
										borderRadius: '10px',
										padding: '16px',
									}}
								>
									<ListItemText primary={<Typography>{document.name}</Typography>} />
									<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
										<IconButton
											target='_blank'
											style={{ padding: '5px' }}
											href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${document.relativePath}`}
										>
											<CloudDownload style={{ color: '#4377C4', fontSize: '20px' }} />
										</IconButton>
										{canBeEditData && (
											<IconButton
												style={{ padding: '5px' }}
												onClick={() => deleteFiles(document.name, item)}
												disabled={isSaving}
											>
												<Delete style={{ color: '#4377C4', fontSize: '20px' }} />
											</IconButton>
										)}
									</div>
								</Paper>
							</ListItem>
						</Grid>
					))}
				</Grid>
			)}
		</List>
	)
}

export default withRouter(withStyles(useStyle, { withTheme: true })(ListDocumentComponent))
