import React, { useCallback, useState } from 'react';
import DialogOperationComponent from './InformationComponent';

export default function DialogRatingInformation() {
    const [isOpen, setIsOpen] = useState(false);

    const onOpenAndCloseDialogInformation = () => {
        setIsOpen(!isOpen);
    };

    const DialogRatingInformation = useCallback(
        () => (
            <DialogOperationComponent
                open={isOpen}
                onClose={onOpenAndCloseDialogInformation}
            />
        ),
        [isOpen]
    );

    return {
        DialogRatingInformation,
        onOpenAndCloseDialogInformation,
    };
}