/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import uuidv4 from 'uuid/v4'
/**Import material UI Section*/

import {
    withStyles,
    IconButton
} from "@material-ui/core";

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants'
import SettingsSupplierCassificationIcon from '@material-ui/icons/Settings';

import { BTN_DELETE } from '../../../../store/helpers/ExpedientSupplier'
import { canBeModuleExpeditByCompany } from '../../../../store/helpers/RolesHelper';
const DocumentsHeaderTable = (props) => {
    /**Arrays props section */
    const { mappedHeadersDocuments, userRolesByCompany } = props
    /**Functions props section */
    const { onLateralPanelConfig } = props
    /**Objects props section */
    const { classes } = props


    return (

        <TableHead key={uuidv4()}>
            <TableRow>
                {
                    mappedHeadersDocuments.map(column => {
                        return (<TableCell
                            style={{
                                backgroundColor: column.backgroundColor ? column.backgroundColor : BACKGROUND_COLOR_GRAY, minWidth: column.width, maxWidth: column.width,
                                position: column.title === 'Nomenclatura' ? "sticky" : column.title === '' ? "sticky" : '',
                                left: column.title === 'Nomenclatura' ? '80px' : column.title === '' ? 0 : '',
                                zIndex: column.title === 'Nomenclatura' ? "40" : column.title === '' ? "40" : '',
                            }}
                            className={classes.tableCellHeader}
                            key={uuidv4()}
                            align="center" >
                            {column.title === BTN_DELETE ?
                                canBeModuleExpeditByCompany(userRolesByCompany) ? <IconButton onClick={() => onLateralPanelConfig('IndexDeleteClassification')}>
                                    <SettingsSupplierCassificationIcon />
                                </IconButton> : null : column.title}
                        </TableCell>)
                    })
                }
            </TableRow>
        </TableHead>
    )
}

const styles = (theme) => ({
    tableCellHeader: {
        height: "15px",
        position: 'sticky',
        top: 0,
        zIndex: '30'
    },
    colorTextWhite: {
        color: "white"
    },
});

DocumentsHeaderTable.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
}

DocumentsHeaderTable.defaultProps = {
    isLoading: false,
}

export default withRouter(
    withStyles(styles, { withTheme: true })(DocumentsHeaderTable)
);
