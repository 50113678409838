/**Import react section */
import React from 'react'
import { withRouter } from "react-router-dom";

/**Import MaterialUi section */
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Badge, Tooltip } from '@material-ui/core';
import { TextField } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
//Import components section
import Evidence from '../../common/Evidence'


const MaterialMachinedPartialItemTable = (props) => {
    //Object props section
    const { classes, itemModel } = props
    //Array props section
    const { informationPartialItems } = props
    //Bool props section
    const { canBeEditWarehouse, canBeUpdateSupplier, isSaving } = props
    //Functions props section
    const { onUpdateMaterialMachinedPartialItem, uploadFilesInPartialItem, removeFilesInPartialItem } = props
    const onRenderCellByName = (dataSource, item) => {
        let isDatasourceForUserWarehouse = (dataSource === 'shipping_ticket' || dataSource === 'quantity_send')
        let canBeEditInformation = isDatasourceForUserWarehouse ? canBeEditWarehouse : canBeUpdateSupplier
        if (dataSource === "shipping_ticket" || dataSource === 'entrance_weighing_ticket') {
            return canBeEditInformation ? <TextField
                size="small"
                fullWidth
                className={classes.textField}
                name={dataSource}
                type='number'
                margin="dense"
                variant="outlined"
                // step={0.5}
                onBlur={(event) => onUpdateMaterialMachinedPartialItem(event, item)}
                disabled={isSaving}
                defaultValue={item[dataSource]}
                InputProps={{ classes: { input: classes.inputProps } }}
                InputLabelProps={{
                    shrink: true,
                }}
            /> : item[dataSource]
        }

        if (dataSource === "quantity_send" || dataSource === 'quantity_received') {
            return canBeEditInformation ? <TextField
                size="small"
                fullWidth
                className={classes.textField}
                name={dataSource}
                type='number'
                margin="dense"
                variant="outlined"
                step={0.5}
                onBlur={(event) => onUpdateMaterialMachinedPartialItem(event, item)}
                disabled={isSaving}
                defaultValue={item[dataSource]}
                InputProps={{ classes: { input: classes.inputProps } }}
                InputLabelProps={{
                    shrink: true,
                }}
            /> : item[dataSource]
        }
    }
    let styleTableCellHeader = {
        paddingLeft: 12,
        paddingRight: 12,
        maxWidth: "1fr",
        backgroundColor: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: '30'
    }

    let styleTableCellContent = {
        wordBreak: 'break-word',
        paddingLeft: 12,
        paddingRight: 12,
        maxWidth: "1fr"
    }
    return (
        <Paper className={classes.root}>
            {informationPartialItems && informationPartialItems.length !== 0 ? <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell padding="none" style={styleTableCellHeader}>Ticket de salida</TableCell>
                        <TableCell style={styleTableCellHeader}>Cantidad enviada</TableCell>
                        <TableCell style={styleTableCellHeader}>Ticket de entrada</TableCell>
                        <TableCell style={styleTableCellHeader}>Cantidad recibida</TableCell>
                        <TableCell style={styleTableCellHeader}>Diferencia</TableCell>
                        <TableCell padding="checkbox" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <>
                        {informationPartialItems.map(item => (
                            <ExpandableTableRow
                                key={item.token}
                                totalFiles={item.attachments_plant.length + item.attachments_supplier.length}
                                expandComponent={<TableCell colSpan="7"><Grid container >
                                    <Grid item xs={6}>
                                        <Typography color='primary'>{"Evidencias Planta"}</Typography>
                                        <Evidence
                                            text={"Subir evidencias planta"}
                                            files={item.attachments_plant}
                                            uploadFiles={(files) => uploadFilesInPartialItem(files, item)}
                                            removeFiles={(fileName) => removeFilesInPartialItem(fileName, item)}
                                            baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
                                            isDisabled={!canBeEditWarehouse}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography color='primary'>{"Evidencias proveedor"}</Typography>
                                        <Evidence
                                            text={"Subir evidencias proveedor"}
                                            files={item.attachments_supplier}
                                            uploadFiles={(files) => uploadFilesInPartialItem(files, item)}
                                            removeFiles={(fileName) => removeFilesInPartialItem(fileName, item)}
                                            baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
                                            isDisabled={!canBeUpdateSupplier}
                                        />
                                    </Grid>
                                </Grid>
                                </TableCell>}
                            >
                                <TableCell
                                    align={'left'}
                                    padding="none"
                                    style={styleTableCellContent}
                                >{onRenderCellByName("shipping_ticket", item)}</TableCell>
                                <TableCell
                                    align={'left'}
                                    padding="none"
                                    style={styleTableCellContent}
                                >{onRenderCellByName("quantity_send", item)}</TableCell>
                                <TableCell
                                    align={'left'}
                                    padding="none"
                                    style={styleTableCellContent}
                                >{onRenderCellByName("entrance_weighing_ticket", item)}</TableCell>
                                <TableCell
                                    align={'left'}
                                    padding="none"
                                    style={styleTableCellContent}
                                >{onRenderCellByName("quantity_received", item)}</TableCell>
                                <TableCell
                                    align={'left'}
                                    padding="none"
                                    style={styleTableCellContent}
                                >{item.difference_total}</TableCell>
                            </ExpandableTableRow>
                        ))}
                        <TableRow style={{ backgroundColor: "rgba(121, 169, 216, 1)" }}>
                            <TableCell
                                align={'left'}
                                padding="none"
                                style={styleTableCellContent}
                            >Total</TableCell>
                            <TableCell
                                align={'left'}
                                padding="none"
                                style={styleTableCellContent}
                            >{itemModel ? itemModel.partial_total_sent : 0}</TableCell>
                            <TableCell
                                align={'left'}
                                padding="none"
                                style={styleTableCellContent}
                            />
                            <TableCell
                                align={'left'}
                                padding="none"
                                style={styleTableCellContent}
                            >{itemModel ? itemModel.partial_total_received : 0}</TableCell>
                            <TableCell
                                align={'left'}
                                padding="none"
                                style={styleTableCellContent}
                            >{itemModel ? itemModel.total_difference : 0}</TableCell>
                            <TableCell padding="checkbox" />
                        </TableRow>
                    </>

                </TableBody>
            </Table> : "No existen parcialidades agregadas"}
        </Paper>
    )
}


const ExpandableTableRow = ({ children, expandComponent, totalFiles, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <>
            <TableRow {...otherProps}>
                {children}
                <TableCell>
                    <Badge badgeContent={totalFiles} color="primary">
                        <Tooltip title={isExpanded ? "Ocultar evidencias" : "Ver evidencias"}>
                            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>

                        </Tooltip>

                    </Badge>
                </TableCell>
            </TableRow>
            {isExpanded && (
                <TableRow>
                    {expandComponent}
                </TableRow>
            )}
        </>
    );
};

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto'
    },
    table: {
        minWidth: 650
    }
});

export default withRouter(withStyles(styles, { withTheme: true })(MaterialMachinedPartialItemTable))
