import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import QuotesIcon from '@material-ui/icons/AttachMoneyTwoTone'
import SamplesIcon from '@material-ui/icons/UnarchiveTwoTone'
import ReactiveIcon from '@material-ui/icons/FindReplace'
import { Checkbox } from '@material-ui/core'

/** Custom components import section */
import AddCommonItem from '../../common/AddCommonItem'
import DataTable from '../../common/DataTable'
import Toaster from '../../common/Toaster'
import LoadingPage from '../../common/LoadingPage'

/** Resources import section */
import { reactiveWorkflowDocument } from '../../../store/workflows/workflowActions'
import { SET_REACTIVE_QUOTE_IN_MEMO } from '../../../store/memos/MemoActions'
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import { caBeActiveQuote } from '../../../store/helpers/RolesHelper'
import { converListToArray } from '../../../store/helpers/ListHelper'
import { COLOR_STATUS_SUCCESS } from '../../../store/helpers/StatusColorConstants'
import { Quote_Declined_BySupplier } from '../../../store/helpers/DevelopmentHelper'
import { showSuccessToaster, showErrorToaster } from '../../../store/helpers/ToasterHelpers'

/**
 * Container show memo
 */
class SuppliersCard extends Component {
	/**
	 * Create an instance ofMemos
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openActiveQuote: false,
			quoteToActive: '',
			disabledSampleRequestTokens: [],
			selectedSampleRequestTokens: [],
		}
	}

	/**
	 * Action to open reactive sample request
	 */
	onOpenActiveQuote(tokenQuote) {
		this.setState({
			openActiveQuote: true,
			quoteToActive: tokenQuote,
		})
	}

	/**
	 * Action to reactive quote from supplier
	 * @param {*} commnents
	 */
	reactiveQuoteFromSupplier(commnents) {
		this.props
			.reactiveQuoteFromSupplier(this.state.quoteToActive, commnents)
			.then((response) => {
				this.props.changeQuoteActive(response)
				this.setState(showSuccessToaster('common.saveCorrectly'))
				this.setState({
					quoteToActive: '',
					openActiveQuote: false,
				})
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToSave'))
			})
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'folio') {
			if (item.type_request === 'QuoteSamples') {
				return (
					<div>
						<QuotesIcon color='primary' /> {item.folio}
					</div>
				)
			}
			if (item.type_request === 'SampleRequest') {
				return (
					<div>
						<SamplesIcon color='secondary' /> {item.folio}
					</div>
				)
			}
		}
		if (dataSource === 'type_request') {
			if (item.type_request === 'QuoteSamples') return <Translate id='quotes.quote' />
			else if (item.type_request === 'SampleRequest') return <Translate id='samplesrequest.samplesrequest' />
		}
		if (dataSource === 'option') {
			if (
				item.type_request === 'QuoteSamples' &&
				item.actual_operation === Quote_Declined_BySupplier &&
				caBeActiveQuote(this.props.userRolesByCompany)
			) {
				return (
					<Tooltip title={<Translate id='quotes.rectiveQuote' />}>
						<IconButton
							style={{ color: COLOR_STATUS_SUCCESS }}
							data-cy='btn-active'
							onClick={() => {
								this.onOpenActiveQuote(item.token)
							}}
						>
							<ReactiveIcon />
						</IconButton>
					</Tooltip>
				)
			} else return ''
		}
		if (dataSource === 'checkbox') {
			const checked = this.state.selectedSampleRequestTokens.includes(item.token)
			const disabled =
				this.state.disabledSampleRequestTokens.includes(item.token) ||
				!item.available_operations ||
				item.available_operations.length === 0

			if (item.type_request === 'QuoteSamples') {
				return <span></span>
			}
			return (
				<Tooltip
					title={checked ? <Translate id='common.unselect' /> : <Translate id='common.select' />}
					placement='top'
				>
					<Checkbox
						onChange={this.onSampleRequestCheckboxChange}
						color='primary'
						value={item.token}
						checked={checked}
						disabled={disabled}
						style={{ padding: '0px' }}
					/>
				</Tooltip>
			)
		}
	}

	/**
	 * Get a filtered list of suppliers
	 *
	 * @returns
	 * @memberof SuppliersCard
	 */
	getFilteredSuppliersList(data) {
		return data.suppliers.filter((supplier) => {
			return supplier.supplier_token === this.props.user.profile.company_token
		})
	}

	/**
	 * On render row click
	 * @param {*} item
	 * @param {*} event
	 */
	onRowClick(item, event) {
		const { tagName } = event.target
		if (tagName !== 'svg' && tagName !== 'INPUT') {
			if (item.type_request === 'QuoteSamples') {
				this.props.history.push(`/quotes/${item.token}`)
			} else if (item.type_request === 'SampleRequest') {
				this.props.history.push(`/samplerequests/${item.token}`)
			}
		}
	}

	onSampleRequestCheckboxChange(event) {
		const { value, checked } = event.target
		const selectedTokens = checked
			? [...this.state.selectedSampleRequestTokens, value]
			: this.state.selectedSampleRequestTokens.filter((token) => token !== value)
		const disabledTokens = getDisabledSampleRequestTokens(this.props.memo, selectedTokens)
		this.setState({ selectedSampleRequestTokens: selectedTokens, disabledSampleRequestTokens: disabledTokens })
		this.props.onSelectedSampleRequestChange(selectedTokens)
	}

	/**
	 * Get the companies list
	 *
	 * @returns
	 * @memberof CompaniesCard
	 */
	renderSuppliersList() {
		const { memo, user } = this.props
		if (memo) {
			if (IsCurrentActiveDirectoryUser(user)) {
				return memo.suppliers.map((supplier) => {
					return (
						<DataTable
							key={supplier.supplier_token}
							title={
								<Typography variant='subtitle2' gutterBottom>
									<strong>
										{supplier.supplier_code} | {supplier.supplier_name.toUpperCase()}
									</strong>
								</Typography>
							}
							data={supplier.quotes}
							configuration={createTableQuotesConfiguration(true)}
							onRenderCellItem={this.onRenderCellItem}
							onRowClick={this.onRowClick}
							selectedTokens={this.state.selectedSampleRequestTokens}
						/>
					)
				})
			} else {
				return this.getFilteredSuppliersList(memo).map((supplier) => {
					return (
						<DataTable
							title={
								<Typography variant='subtitle2' gutterBottom>
									<strong>
										{supplier.supplier_code} | {supplier.supplier_name.toUpperCase()}
									</strong>
								</Typography>
							}
							data={supplier.quotes}
							configuration={createTableQuotesConfiguration(false)}
							onRenderCellItem={this.onRenderCellItem}
							onRowClick={this.onRowClick}
						/>
					)
				})
			}
		} else {
			return <LoadingPage />
		}
	}

	render() {
		return (
			<Paper>
				{this.renderSuppliersList()}

				{/** Dialog to reactive quote decline by supplier */}
				<AddCommonItem
					title={<Translate id='quotes.rectiveQuote' />}
					message={<Translate id='quotes.messageReactiveQuote' />}
					open={this.state.openActiveQuote}
					onClose={() => {
						this.setState({ openActiveQuote: false })
					}}
					onSaveItem={this.reactiveQuoteFromSupplier}
					isSaving={this.props.isActive}
					isTextArea={true}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Paper>
		)
	}
}

SuppliersCard.propTypes = {
	onSelectedSampleRequestChange: PropTypes.func,
}

SuppliersCard.defaultProps = {
	onSelectedSampleRequestChange: () => console.warn('onSelectedSampleRequestChange not defined!'),
}

function createTableQuotesConfiguration(showCheckSelect = false) {
	const columns = [
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
		},
		{
			header: <Translate id='memos.documentType' />,
			dataSource: 'type_request',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'status',
		},
		{
			header: '',
			dataSource: 'option',
		},
	]
	if (showCheckSelect) {
		columns.push({
			header: '',
			dataSource: 'checkbox',
		})
	}
	return { columns }
}

function getDisabledSampleRequestTokens(memo, selectedSampleRequestTokens) {
	if (!selectedSampleRequestTokens || selectedSampleRequestTokens.length === 0) {
		return []
	}
	if (memo) {
		const { suppliers } = memo
		let disabledTokens = []
		suppliers.forEach((supplier) => {
			if (
				supplier.quotes &&
				!supplier.quotes.some((quote) => selectedSampleRequestTokens.includes(quote.token))
			) {
				disabledTokens = [...disabledTokens, ...supplier.quotes.map((quote) => quote.token)]
			}
		})
		return disabledTokens
	}
	return []
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		isActive: state.workflow.get('isActive'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		reactiveQuoteFromSupplier: (quoteToken, commnents) => {
			return dispatch(reactiveWorkflowDocument(quoteToken, commnents))
		},
		changeQuoteActive: (quote) => {
			dispatch({
				type: SET_REACTIVE_QUOTE_IN_MEMO,
				quote: quote,
			})
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersCard)
