import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import { Translate } from 'react-localize-redux'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import RatesIcon from '@material-ui/icons/BarChartTwoTone'
import { Divider } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import RatesIndirIcon from '@material-ui/icons/ShowChartOutlined'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import GeneralInformation from './common/GeneralInformation'
import BankData from './common/BankData'
import QueriesButton from './common/QueriesButton'
import UploadLogo from './common/UploadLogo'

import { genereteStringGuid } from '../../store/helpers/StringHelper'
import { getCompanyByUser, loadBankAccountsSupplier, loadPartnership } from '../../store/suppliers/SupplierActions'
import { COMPANY_IMBERA } from '../../store/helpers/ResourcesHelper'

/**
 * Container to show information from supplier
 */
class GetProfile extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.uploadLogoFileRef = React.createRef()
		this.state = {
			activeTab: 0,
			showChangeLogoDialog: false,
			logoFileBase64: null,
			croppedFileBase64: null,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		this.props.getCompanyByUser()
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoadingSupplier !== this.props.isLoadingSupplier && this.props.supplier !== null) {
			/// Get information full from supplier
			let supplier = this.props.supplier
			let companyToken = this.props.selectedCompany.token
			this.props.loadPartnership(supplier.code, companyToken)
			this.props.loadBankAccountsSupplier(supplier.code, companyToken)
		}
	}

	/**
	 * Method to change the active tab
	 */
	onClickToChangeActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 *
	 *
	 * @memberof Show
	 */
	onOpenQueryView(queryRequested) {
		let supplier = this.props.supplier
		this.props.history.push(`/suppliers/${queryRequested}/${supplier.token}/`)
	}

	/**
	 * Upload the log file
	 *
	 * @memberof UserProfileHeader
	 */
	uploadLogoFile(file) {
		let self = this
		let fileUpload = file.target.files[0]
		/// Determine if the image to add not is undefined or null
		if (fileUpload !== undefined && fileUpload !== null) {
			var reader = new FileReader()
			reader.onload = function () {
				self.onSelecteFile(reader.result)
			}
			reader.onerror = function (error) {
				console.log('Error: ', error)
			}
			reader.readAsDataURL(fileUpload)
			self.uploadLogoFileRef.current.value = ''
		}
	}

	/**
	 * On select logo file
	 *
	 * @param {*} fileBase64
	 * @memberof Profile
	 */
	onSelecteFile(fileBase64) {
		this.setState({
			logoFileBase64: fileBase64,
			croppedFileBase64: fileBase64,
			showChangeLogoDialog: true,
		})
	}

	/**
	 * Render view
	 */
	render() {
		const { classes } = this.props
		if (this.props.isLoadingSupplier === true) {
			return <LoadingPage />
		}
		if (this.props.supplier === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let supplier = this.props.supplier
			let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${supplier.logo}`
			let logoUrl = urlImage + '?token=' + genereteStringGuid()

			let logoAvatar = (
				<IconButton
					className={classes.avatar}
					onClick={() => {
						this.uploadLogoFileRef.current.click()
					}}
				>
					P
				</IconButton>
			)
			if (supplier.logo) {
				logoAvatar = (
					<Avatar
						alt='Remy Sharp'
						src={logoUrl}
						className={classes.avatar}
						onClick={() => {
							this.uploadLogoFileRef.current.click()
						}}
					/>
				)
			}

			return (
				<Card className={classes.card}>
					<CardHeader avatar={logoAvatar} title={supplier.full_name} subheader={supplier.rfc} />
					<CardContent>
						<GeneralInformation
							supplier={supplier}
							partnership={this.props.partnership}
							selectedCompany={this.props.selectedCompany}
						/>
					</CardContent>
					<CardActions className={classes.actions} disableActionSpacing>
						<Tooltip title={<Translate id='suppliers.viewScores' />}>
							<IconButton
								aria-label='Show ratings'
								onClick={() => {
									this.props.history.push(`/suppliers/ratings/${supplier.token}`)
								}}
							>
								<RatesIcon />
							</IconButton>
						</Tooltip>
						{this.props.selectedCompany &&
							this.props.selectedCompany.company_code === COMPANY_IMBERA &&
							this.props.advanceOptionsSupplier &&
							this.props.advanceOptionsSupplier.rating_type === '2' && (
								<Tooltip title={<Translate id='suppliers.ratingScoresIndirects' />}>
									<IconButton
										aria-label='Show ratings indirects'
										onClick={() => {
											this.props.history.push(`/suppliers/ratings/${supplier.token}/indirects`)
										}}
									>
										<RatesIndirIcon />
									</IconButton>
								</Tooltip>
							)}
						<QueriesButton {...this.props} onOpenQueryView={this.onOpenQueryView} />
					</CardActions>
					<Divider />
					<BankData bancks={this.props.bancks} isLoadingBanck={this.props.isLoadingBanck} />

					<input
						className={classes.logoFileInput}
						type='file'
						accept='image/*'
						ref={this.uploadLogoFileRef}
						multiple={false}
						onChange={this.uploadLogoFile}
					/>

					{this.state.showChangeLogoDialog ? (
						<UploadLogo
							open={this.state.showChangeLogoDialog}
							fileBase64={this.state.logoFileBase64}
							onClose={() => {
								this.setState({ showChangeLogoDialog: false })
							}}
						/>
					) : null}
				</Card>
			)
		}
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		isLoadingSupplier: state.suppliers.get('isLoadingSupplier'),
		partnership: state.suppliers.get('partnership') ? state.suppliers.get('partnership').toJS() : null,
		isLoadingBanck: state.suppliers.get('isLoadingBanck'),
		bancks: state.suppliers.get('bancks') ? state.suppliers.get('bancks').toJS() : null,
		advanceOptionsSupplier: state.suppliers.get('advanceOptionsSupplier')
			? state.suppliers.get('advanceOptionsSupplier').toJS()
			: null,
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		getCompanyByUser: () => {
			return dispatch(getCompanyByUser())
		},
		loadPartnership: (supplierCode, companyToken) => {
			return dispatch(loadPartnership(supplierCode, companyToken))
		},
		loadBankAccountsSupplier: (supplierCode, companyToken) => {
			return dispatch(loadBankAccountsSupplier(supplierCode, companyToken))
		},
	}
}

const viewStyles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: '100px',
		paddingRight: '150px',
	},
	containerWarehouse: {
		paddingLeft: '100px',
		paddingRight: '30px',
	},
	styleTab: {
		backgroundColor: '#fff',
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		marginLeft: 10,
		margin: 10,
		width: 80,
		height: 80,
	},
	logoFileInput: {
		display: 'none',
	},
	edit: {
		marginRight: 30,
		backgroundColor: theme.palette.text.hint,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(GetProfile)))
)
