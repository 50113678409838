import { fromJS } from 'immutable';

import * as releaseCalendarActions from './ReleaseCalendarAction';

/**
 * Release Calendar initial state
 */
 export const releaseCalendarInitialState = fromJS({
    isLoadingReleaseCalendars: true,
    releaseCalendarLocks: null,
    sortCriteria: {
        by: "Id",
        ascending: true
    },
    searchQuery: "",
    releaseCalendarLocksCount: null,
    isSaving: false,
    isLoading: false,
    releaseCalendarLock: null,
})
export default function releaseCalendarReducer(state = releaseCalendarInitialState, action) {
    switch (action.type) {
        case releaseCalendarActions.START_LOADING_RELEASE_CALENDARS: {
            return state.merge({
                isLoadingReleaseCalendars: action.isLoading
            })
        }
        case releaseCalendarActions.SET_RELEASE_CALENDARS_LIST: {
            let calendarsLocksLists = fromJS(action.calendarsLocks);
            return state.merge({
                releaseCalendarLocks: calendarsLocksLists,
                isLoadingReleaseCalendars: false,
                isLoading: false,
                isSaving:false,
            })
        }
        case releaseCalendarActions.IS_LOADING: {
            return state.merge({
                isLoading: action.isLoading
            })
        }
        case releaseCalendarActions.SET_RELEASE_CALENDAR_LOCK: {
            return state.merge({
                isLoading: false,
                isSaving: false,
                releaseCalendarLock: fromJS(action.calendarLock)
            })
        }
        case releaseCalendarActions.CHANGE_RELEASE_CALENDARS_QUERY: {
            return state.merge({
                searchQuery: action.query
            })
        }
        default:
            return state;
    }
}
