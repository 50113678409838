/**import react section */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import autoBind from 'auto-bind';
import uuidv4 from 'uuid/v4'
import { Translate } from 'react-localize-redux'
import { AutoSizer, Column, SortDirection, Table } from 'react-virtualized';
/**import material ui section */
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import AddIcon from "@material-ui/icons/NoteAdd";
import RejectedIcon from "@material-ui/icons/ThumbDown";
import ActivarIcon from "@material-ui/icons/ThumbUp";
/**Import components section */
import SupplierResponseByItem from './SupplierResponseByItem'
import {
    Tooltip, IconButton
} from '@material-ui/core';
/**Import resources section */
import { COLOR_STATUS_DANGER } from '../../../../store/helpers/StatusColorConstants'
import { ItemProposalStatus } from '../../../../store/helpers/AppConstants';
const styles = theme => ({
    table: {
        fontFamily: theme.typography.fontFamily,
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableRow: {
        cursor: 'pointer',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    tableCell: {
        flex: 1,
    },
    noClick: {
        cursor: 'initial',
    },
});

class MuiVirtualizedTable extends React.PureComponent {
    componentWillReceiveProps() {
        this.tableRef.forceUpdate();

    }
    getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = this.props;

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        });
    };

    cellRenderer = ({ cellData, dataKey, rowData, columnIndex = null }) => {
        const { columns, classes, rowHeight, onRowClick } = this.props;
        if (dataKey === "Name" || dataKey === "DivisaCode" || dataKey === "PurchaseMeasurementUnit" || dataKey ==="IncotermCode") {
            return (
                <TableCell
                    component="div"
                    className={classNames(classes.tableCell, classes.flexContainer, {
                        [classes.noClick]: onRowClick == null,
                    })}
                    variant="body"
                    style={rowData.Status === ItemProposalStatus.DECLINED ? { backgroundColor: "#ff6666", height: rowHeight } : { height: rowHeight }}
                    align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
                >
                    {cellData}
                </TableCell>
            );
        }
        if (dataKey === "Icon") {
            return <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                })}
                variant="body"
                style={rowData.Status === ItemProposalStatus.DECLINED ? { backgroundColor: "#ff6666", height: rowHeight } : { height: rowHeight }}
                align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
            >
                <div style={{ display: "flex" }}>
                    <Tooltip title={<Translate id={'biddingsModule.addSupplierAnswer'} />}>
                        <IconButton
                            color="primary"
                            aria-label="More"
                            onClick={() => this.props.onAddResponseItemSupplierConfigPanel(rowData)}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                    {
                        (rowData.Status === ItemProposalStatus.DRAFT && rowData.Status !== ItemProposalStatus.DECLINED) &&
                        <Tooltip title={<Translate id={'biddingsModule.rejected'} />} >
                            <IconButton
                                color="primary"
                                aria-label="More"
                                disabled={this.props.isSaving}
                                onClick={() => this.props.onSetMaterialStatusBySupplierInternal(rowData, ItemProposalStatus.DECLINED)}
                            >
                                <RejectedIcon style={{ color: COLOR_STATUS_DANGER }} />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        (rowData.Status === ItemProposalStatus.DECLINED && this.props.canBeEditedInformation) &&
                        <Tooltip title={<Translate id={'biddingsModule.active'} />} >
                            <IconButton
                                color="primary"
                                aria-label="More"
                                disabled={this.props.isSaving}
                                onClick={() => this.props.onSetMaterialStatusBySupplierInternal(rowData, ItemProposalStatus.DRAFT)}
                            >
                                <ActivarIcon style={{ color: "white" }} />
                            </IconButton>
                        </Tooltip>
                    }

                </div>

            </TableCell>

        }
        if (dataKey !== 'Name' && dataKey !== "Icon") {
            return <TableCell
                key={this.props.identifierLoading === rowData.ItemId ? uuidv4() : rowData[dataKey]}
                // key={rowData[dataKey]}
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                })}
                variant="body"
                style={rowData.Status === ItemProposalStatus.DECLINED ? { backgroundColor: "#ff6666", height: rowHeight } : { height: rowHeight }}
                align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
            >
                <SupplierResponseByItem
                    dataSource={dataKey}
                    item={rowData}
                    isSaving={this.props.isSaving}
                    isGeneralResponse={false}
                    onAddResponseSupplier={this.props.onAddResponseSupplier}
                    serviceCriteriaId={rowData.ItemId}
                    isDisabled={((rowData.Status !== ItemProposalStatus.DECLINED) && this.props.canBeEditedInformation)}
                    setIdentifierLoading={this.props.setIdentifierLoading}
                    identifierLoading={this.props.identifierLoading}
                    translate={this.props.translate}
                />

            </TableCell>

        }
        return null
    };

    headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
        const { headerHeight, columns, classes, sort } = this.props;
        const direction = {
            [SortDirection.ASC]: 'asc',
            [SortDirection.DESC]: 'desc',
        };

        const inner =
            !columns[columnIndex].disableSort && sort != null ? (
                <TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
                    {label}
                </TableSortLabel>
            ) : (
                label
            );
        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{ height: headerHeight }}
                align={columns[columnIndex].numeric || false ? 'right' : 'left'}
            >
                {inner}
            </TableCell>
        );
    };

    render() {
        const { classes, columns, items, ...tableProps
        } = this.props;

        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        ref={(ref) => this.tableRef = ref}
                        className={classes.table}
                        height={height}
                        width={width}
                        {...tableProps}
                        rowClassName={this.getRowClassName}
                        data={items}
                    >
                        {columns.map(({ cellContentRenderer = null, className, dataKey, rowData, ...other }, index) => {
                            other.width = other.width ? other.width : (width / columns.length)
                            let renderer;
                            if (cellContentRenderer != null) {
                                renderer = cellRendererProps =>
                                    this.cellRenderer({
                                        cellData: cellContentRenderer(cellRendererProps),
                                        columnIndex: index,
                                        dataKey: dataKey,
                                        rowData: rowData,
                                    });
                            } else {
                                renderer = this.cellRenderer;
                            }
                            return (
                                <Column
                                    // key={uuidv4()}
                                    headerRenderer={headerProps =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    className={classNames(classes.flexContainer, className)}
                                    style={{ margin: 0 }}
                                    cellRenderer={renderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        );
    }
}

MuiVirtualizedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            cellContentRenderer: PropTypes.func,
            dataKey: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
        }),
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowClassName: PropTypes.string,
    rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    sort: PropTypes.func,
};

MuiVirtualizedTable.defaultProps = {
    headerHeight: 56,
    rowHeight: 56,
};

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

class ReactVirtualizedTable extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }
    render() {
        return (
            <Paper style={{ height: 400, width: '100%' }}>
                <WrappedVirtualizedTable
                    {...this.props}
                    rowCount={this.props.items.length}
                    rowGetter={({ index }) => this.props.items[index]}
                    columns={this.props.columns}
                />
            </Paper>
        );
    }
}

export default ReactVirtualizedTable;

