import React from 'react'
import propTypes from 'prop-types'
import DialogComponent from '../../common/DialogComponent'
import Dropzone from '../../../common/Dropzone'
import { withStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import { BugReport, CloudDownload } from '@material-ui/icons'
import { Translate } from 'react-localize-redux'

const DialogCatalogUploadMassive = (props) => {
	const {
		title,
		onClose,
		classes,
		open,
		onSave,
		canBeSaved,
		isLoading,
		file,
		setfile,
		errorList,
		onDownloadTemplateFilled,
		typeLoadTranslate,
	} = props

	/**
	 *
	 * @param {*} files
	 * @returns set file in state
	 */
	const onChangeFile = (files) => {
		setfile(files)
		return Promise.resolve('Ok')
	}
	const onDeleteFile = (fileName) => {
		let newFiles = file.filter((file) => {
			return file.name !== fileName
		})
		setfile(newFiles)
		return Promise.resolve('Ok')
	}
	return (
		<DialogComponent
			title={title}
			open={open}
			onSave={onSave}
			onClose={onClose}
			isSaving={isLoading}
			canBeSaved={canBeSaved}
			view={'SHOW_EDIT_VIEW'}
			children={
				<div>
					<div>
						<Typography variant='caption'>
							<Translate id={`carrierTenders.file.downloadUpload`} />
						</Typography>
						<Button
							data-tut='reactour-save'
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='outlined'
							className={isLoading ? classes.btnCreateDisable : classes.btnCreate}
							disabled={isLoading}
							onClick={() => onDownloadTemplateFilled()}
						>
							<CloudDownload />
							<Typography className={classes.titleBtn}>
								<Translate id={'carrierTenders.uploadMossiveTemplate.downloadFilledTemlate'} />
							</Typography>
						</Button>
					</div>
					<Typography variant='caption' className={classes.title}>
						<Translate id={'carrierTenders.uploadMossiveTemplate.textHelpTemplate'} />
					</Typography>
					<Dropzone
						files={file}
						onChange={(event) => onChangeFile(event)}
						onDeleteFile={onDeleteFile}
						baseApi={''}
						acceptedFiles={['XLSX']}
						isDisabled={isLoading}
						disabledLink={false}
						maxFiles={1}
						dropzoneText={<Translate id='dropzone.dropzoneBaseText' />}
					/>
					{errorList.length > 0 && (
						<div>
							<Typography variant='caption' className={classes.errorTitle}>
								{<BugReport className={classes.errorIcon} />}{' '}
								<Translate id={`carrierTenders.file.informationErrors`} />
							</Typography>
							<div
								style={{
									overflowY: 'scroll',
									maxHeight: '195px',
								}}
							>
								{errorList.map((error) => {
									return (
										<div styles={{ display: 'flex' }}>
											<Typography variant='caption' className={classes.titleError}>
												Fila {error.excelRow} -
												{error.message.includes('empty') ? (
													<div styles={{ display: 'flex' }}>
														{' '}
														El campo{' '}
														{
															<Translate
																id={`carrierTenders.errorFiles.${typeLoadTranslate}.${error.message}`}
															/>
														}{' '}
														esta vacio
													</div>
												) : (
													<Translate
														id={`carrierTenders.errorFiles.${typeLoadTranslate}.${error.message}`}
													/>
												)}
											</Typography>
										</div>
									)
								})}
							</div>
						</div>
					)}
				</div>
			}
		/>
	)
}
DialogCatalogUploadMassive.propTypes = {
	title: propTypes.string,
	open: propTypes.bool,
	canBeSaved: propTypes.bool,
	isLoading: propTypes.bool,
	typeLoadTranslate: propTypes.string,
	errorList: propTypes.array,
	onClose: propTypes.func,
	onSave: propTypes.func,
	onDownloadTemplateFilled: propTypes.func,
}
DialogCatalogUploadMassive.defaultProps = {
	title: '',
	open: false,
	canBeSaved: false,
	isLoading: false,
	errorList: [],
	typeLoadTranslate: 'massiveTransportZones',
	onClose: () => console.warn('No [onClose] CallBack defined'),
	onSave: () => console.warn('No [onSave] CallBack defined'),
	onDownloadTemplateFilled: () => console.warn('No [onDownloadTemplateFilled] CallBack defined'),
}
const styles = (theme) => ({
	btnCreate: {
		marginTop: '10px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	btnCreateDisable: {
		marginTop: '10px',
		width: '100%',
		'&.Mui-disabled': {
			opacity: 0.5,
		},
	},
	titleBtn: {
		color: theme.palette.common.white,
		marginLeft: '8px',
	},
	title: {
		marginTop: '10px',
		display: 'flex',
	},
	errorTitle: {
		color: `${theme.palette.error.main} !important`,
	},
	errorIcon: {
		color: `${theme.palette.error.main} !important`,
	},
})
export default withStyles(styles, { withTheme: true })(DialogCatalogUploadMassive)
