import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { Translate, getTranslate } from 'react-localize-redux';

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import LayoutHeaderWorkFlow from './../../common/LayoutHeaderWorkFlow';
import MaterialsTable from './components/MaterialsTable';
import AddMaterialModal from './components/AddMaterialModal';

import CertificatesTable from './components/CertificatesTable';
import AddCertificateModal from './components/AddCertificateModal';

/** Material UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AlertIcon from '@material-ui/icons/ErrorOutline';
import NoReleaseIcon from '@material-ui/icons/EventBusy';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

//import DownloadIcon from '@material-ui/icons/CloudDownload';

/** Actions and helpers imports section */
import { loadSupplier, loadPartnership } from '../../../store/suppliers/SupplierActions';
import {
    getActiveDeliveryOrderFromBackEnd,
    loadPartNumberCodesFromBackEnd,
    loadPurchaseOrdersFromBackEnd,
    loadPositionsFromBackEnd,
    addMaterialToDeliveryOrder,
    removeMaterialFromDeliveryOrder,
    closeAndPrintDeliveryOrder,
    updateMaterialFromDeliveryOrder,
    updateMaterialProperty,
    checkScheduleAvailability,
    uploadFilesQualityCertificatesDraft,
    removeFilesQualityCertificatesDraft,
    updatePropertyDeliveryOrder,
    onRemoveQualityCertificateDocument,
    addQualityCertificatesDraft,
    addQualityCertificates,
    addItemInCertificateInDeliveryOrder,
    updateItemInCertificateInDeliveryOrder,
    removeItemInCertificateInDeliveryOrder
} from './../../../store/planning/DeliveryOrdersAction';
import CloseAndPrintModal from './components/CloseAndPrintModal';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import CarbonFootprint from './components/CarbonFootprint';

import Toaster from '../../common/Toaster';
import { showSuccessToaster, showErrorToaster, showWarningToaster } from '../../../store/helpers/ToasterHelpers';
import LoadingPage from '../../common/LoadingPage';
import { converListToArray } from '../../../store/helpers/ListHelper'
import Evidence from '../../common/Evidence';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
//import downloadTemplateCertificate from '../../../resources/files/Plantilla_Certificado_Calidad_ptm.xlsx'

class Create extends Component {

    /**
     *Creates an instance of Create.
     * @param {*} props
     * @memberof Create
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            activeTab: 0,
            openAddMaterialModal: false,
            openRemoveMaterialModal: false,
            //Toaster state data
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            additionalToasterData: null
        };
    };

    /**
     * componentDidMount
     */
    componentDidMount() {
        if (!this.props.isLoadingUser) {
            this.props.loadSupplier(this.props.user.profile.company_token)
                .then(() => {
                    this.props.loadPartnership(this.props.user.profile.company_code, this.props.company.token)
                        .then(() => {
                            this.props.getActive()
                                .then(() => {
                                    this.props.getPartNumerCodes();
                                    return Promise.resolve('Ok');
                                })
                                .catch((error) => {
                                    this.setState(showErrorToaster(error));
                                    return Promise.reject();
                                })
                        })

                })
        }
    }

    /**
     * Method to change the active tab
     */
    onClickToActiveTab = (event, value) => {
        this.setState({ activeTab: value });
    };

    /**
     * Method to handle state and open any modal
     *
     * @param {*} modalName
     * @param {*} [item=null]
     * @memberof Create
     */
    onOpenModal(modalName, item = null) {
        this.setState({
            item,
            [modalName]: true
        });
    }

    /**
     * Method to handle state and close any modal
     *
     * @param {*} modalName
     * @memberof Create
     */
    onCloseModal(modalName) {
        this.setState({
            item: null,
            [modalName]: false
        })
    }

    /**
     * Method to cll action and add material
     *
     * @param {*} material
     * @returns
     * @memberof Create
     */
    onAddMaterial(material) {
        return this.props.addMaterial(material)
            .then(() => {
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    /**
     * Method to call action and remove material
     *
     * @returns
     * @memberof Create
     */
    removeMaterial() {
        return this.props.removeMaterial(this.state.item.token)
            .then(() => {
                this.onCloseModal("openRemoveMaterialModal")
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    /**
     * Method to call action and update Material item data
     *
     * @param {*} material
     * @returns
     * @memberof Create
     */
    onUpdateMaterial(material) {
        return this.props.updateMaterial(this.state.item.token, material)
            .then(() => {
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    /**
     * Update any material property in the data source 
     *
     * @param {*} property
     * @memberof Create
     */
    onUpdateProperty(item, property) {
        return this.props.updatePropertyInItem(item.token, property, item[property])
            .then(() => {
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok');
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
     * Method to call action and close draft delivery order
 *
 * @param {*} arrivalData
 * @returns
 * @memberof Create
     */
    closeAndPrintDeliveryOrder(arrivalData) {
        let deliveryOrderToken = this.props.deliveryOrder.token;
        let deliveryInDraft = this.props.deliveryOrder;
        let partnership = this.props.partnership;
        if (deliveryInDraft && partnership && partnership.is_requiered_quality_certificate && deliveryInDraft.attachments.length <= 0) {
            this.setState(showWarningToaster("deliveryOrderModule.requiredCetificate"));
            return Promise.reject();
        }
        if (deliveryInDraft && partnership && partnership.is_requiered_cerificate_by_template && deliveryInDraft.certificates.length <= 0) {
            this.setState(showWarningToaster("deliveryOrderModule.requiredCetificate"));
            return Promise.reject();
        }
        else {
            return this.props.closeAndPrint(arrivalData, deliveryOrderToken)
                .then(() => {
                    this.props.history.push(`/planning/deliveryorder/${deliveryOrderToken}`)
                    return Promise.resolve('Ok')
                })
                .catch((error) => {
                    if (error) {
                        let errors = error.split("|");
                        if (errors.length > 1) {
                            this.setState(showWarningToaster(errors[0]));
                        }
                        else
                            this.setState(showErrorToaster(error));
                        return Promise.reject();
                    }
                    else {
                        this.setState(showErrorToaster());
                        return Promise.reject();
                    }
                })
        }
    }

    /**
     * Action to save option from carbon Footprint in order by supplier
     * @param {*} carbonFootprint 
     */
    saveOptionAditionalCarbon(carbonFootprint, destinationSelected) {
        let deliveryOrderToken = this.props.deliveryOrder.token;
        this.props.updatePropertyDeliveryOrder(carbonFootprint, destinationSelected, deliveryOrderToken)
            .then(() => {
                this.setState(showSuccessToaster());
            })
            .catch((error) => {
                this.setState(showErrorToaster());
            })
    }

    /**
     * Function to ckac availability to entry order
     * @param {*} arrivalData 
     */
    checkAvailability(arrivalData) {
        let deliveryOrderToken = this.props.deliveryOrder.token;
        return this.props.checkScheduleAvailability(arrivalData, deliveryOrderToken)
            .then((response) => {
                return Promise.resolve(response)
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
     * On remove certicate in order
     * @param {*} certificateToken 
     */
    onRemoveQualityCertificate(certificateToken) {
        return this.props.onRemoveQualityCertificateDocument(certificateToken)
            .then(() => {
                return Promise.resolve()
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    /**
     * onAddQualityCertificatesDraft
     * @param {*} certificateToken 
     */
    onAddQualityCertificatesDraft(certificateToken) {
        return this.props.addQualityCertificatesDraft(certificateToken)
            .then(() => {
                return Promise.resolve()
            })
            .catch((error) => {
                this.setState(showWarningToaster(error));
                return Promise.reject();
            })
    }


    /**
     * onAddCertificate
     * @param {*} certificate 
     */
    onAddCertificate(certificate) {
        return this.props.addQualityCertificates(certificate)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showWarningToaster(error));
                return Promise.reject();
            })
    }

    /**
     * onAddItemInCertificateInDeliveryOrder
     * @param {*} certificateToken 
 */
    onAddItemInCertificateInDeliveryOrder(certificateToken) {
        return this.props.addItemInCertificateInDeliveryOrder(certificateToken)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showWarningToaster(error));
                return Promise.reject();
            })
    }

    /**
     * onUpdateItemInCertificateInDeliveryOrder
     * @param {*} certificateToken 
     * @param {*} itemToken 
     * @param {*} property 
     * @param {*} valueProperty 
     */
    onUpdateItemInCertificateInDeliveryOrder(certificateToken, itemToken, property, valueProperty) {
        return this.props.updateItemInCertificateInDeliveryOrder(certificateToken, itemToken, property, valueProperty)
            .then(() => {
                return Promise.resolve('Ok');
            })
            .catch((error) => {
                console.log("error", error)
                return Promise.reject();
            })
    }

    /**
     * onRemoveItemInCertificateInDeliveryOrder
     * @param {*} certificateToken 
     * @param {*} itemToken 
     */
    onRemoveItemInCertificateInDeliveryOrder(certificateToken, itemToken) {
        return this.props.removeItemInCertificateInDeliveryOrder(certificateToken, itemToken)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                return Promise.reject();
            })
    }

    /**
     * 
     */
    render() {
        const { classes } = this.props;
        if (this.props.isLoadingSupplier === true || this.props.isLoadingDeliveryOrder === true) {
            return (
                <Card>
                    <LoadingPage />
                </Card>
            )
        }
        else {
            return (
                <Card>
                    {this.props.deliveryOrder !== null &&
                        <React.Fragment>
                            <LayoutHeaderWorkFlow {...this.props}
                                workFlow={this.props.deliveryOrder}
                                title={<Translate id="deliveryOrderModule.deliveryOrder" />}
                            />
                            <CardContent>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <Divider />
                                        <AppBar position="static" color="default">
                                            <Tabs indicatorColor="secondary" textColor="secondary"
                                                className={classes.styleTab}
                                                value={this.state.activeTab}
                                                onChange={this.onClickToActiveTab}>
                                                <Tab label={<Translate id="common.information" />} />
                                                <Tab label={<Translate id="deliveryOrderModule.qualityCertificates" />} />
                                                {this.props.partnership.is_supplier_to_add_carbon_foot_print &&
                                                    <Tab label={<Translate id="deliveryOrderModule.carbonFootprint.titleTransport" />} />}
                                            </Tabs>
                                        </AppBar>
                                        {this.state.activeTab === 0 &&
                                            <MaterialsTable
                                                title={
                                                    <Typography variant="caption" >
                                                        <AlertIcon color="primary" />
                                                        <Translate id="deliveryOrderModule.materialsNote" />
                                                    </Typography>
                                                }
                                                isDraft
                                                onAddClick={this.onOpenModal}
                                                onMenuOptionItemClick={this.onOpenModal}
                                                onCloseAndPrint={() => this.onOpenModal("openCloseAndPrintModal")}
                                                data={this.props.deliveryOrder && this.props.deliveryOrder.materials}
                                                companyToken={this.props.deliveryOrder && this.props.deliveryOrder.company_token}
                                                totalOrderItems={this.props.deliveryOrder ? this.props.deliveryOrder.total_order_items : 0}
                                                onUpdateProperty={this.onUpdateProperty}
                                                roles={this.props.userRolesByCompany}
                                                user={this.props.user}
                                            />
                                        }
                                        {this.state.activeTab === 1 &&
                                            <Grid>
                                                {/* {this.props.partnership && this.props.partnership.is_requiered_cerificate_by_template && <Grid item xs={12} style={{ marginTop: '1rem' }}>
                                                <Typography variant="caption" ><AlertIcon color="primary" />
                                                    {" "}<Translate id="deliveryOrderModule.messageDownloadTemplate" />
                                                    <a href={downloadTemplateCertificate}>
                                                        <DownloadIcon fontSize="small" style={{ margin: '5px', marginBottom:'8px' }} /> 
                                                        <Translate id="deliveryOrderModule.donwloadTemplateCertificate"/>
                                                    </a>
                                                    </Typography>
                                                <Divider />
                                            </Grid>} */}

                                                {/* {this.props.partnership && this.props.partnership.is_requiered_cerificate_by_template && <Evidence
                                                text={this.props.translate('dropzone.dropzoneBase')}
                                                baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
                                                removeFiles={this.props.removeFiles}
                                                uploadFiles={this.onAddQualityCertificatesDraft}
                                                files={[]}
                                            />} */}

                                                {this.props.partnership && this.props.partnership.is_requiered_cerificate_by_template &&
                                                    <CertificatesTable
                                                        deliveryOrder={this.props.deliveryOrder}
                                                        partnership={this.props.partnership}
                                                        onRemoveQualityCertificate={this.onRemoveQualityCertificate}
                                                        canBeEditSupplier={true}
                                                        onAddClick={this.onOpenModal}
                                                        onMenuOptionItemClick={this.onOpenModal}
                                                        onAddItemInCertificateInDeliveryOrder={this.onAddItemInCertificateInDeliveryOrder}
                                                        onUpdateItemInCertificateInDeliveryOrder={this.onUpdateItemInCertificateInDeliveryOrder}
                                                        onRemoveItemInCertificateInDeliveryOrder={this.onRemoveItemInCertificateInDeliveryOrder}
                                                        isSaving={this.props.isSavingDeliveryOrder}
                                                    />
                                                }

                                                {this.props.partnership && !this.props.partnership.is_requiered_cerificate_by_template && <Evidence
                                                    text={this.props.translate('dropzone.dropzoneBaseText')}
                                                    baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
                                                    removeFiles={this.props.removeFiles}
                                                    uploadFiles={this.props.uploadFiles}
                                                    files={this.props.deliveryOrder.attachments}
                                                />}

                                                <br />
                                                {this.props.partnership && !this.props.partnership.is_requiered_cerificate_by_template &&
                                                    <Typography variant="body2" align="center">
                                                        <AlertIcon color="primary" />
                                                        <strong><Translate id={"deliveryOrderModule.notExistCetificate"} /></strong>
                                                    </Typography>
                                                }

                                            </Grid>

                                        }
                                        {this.state.activeTab === 2 &&
                                            <CarbonFootprint
                                                isSaving={this.props.isSavingDeliveryOrder}
                                                deliveryOrder={this.props.deliveryOrder}
                                                onSaveOptionAditional={this.saveOptionAditionalCarbon}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>

                            <AddMaterialModal
                                open={this.state.openAddMaterialModal}
                                title="deliveryOrderModule.addMaterial"
                                addItem={this.state.item ? this.onUpdateMaterial : this.onAddMaterial}
                                onClose={() => this.onCloseModal("openAddMaterialModal")}
                                codes={this.props.partNumberCodes ? this.props.partNumberCodes : []}
                                purchaseOrders={this.props.purchaseOrders ? this.props.purchaseOrders : []}
                                positions={this.props.positions ? this.props.positions : []}
                                getPurchaseOrders={this.props.getPurchaseOrders}
                                getPositions={this.props.getPositions}
                                isSaving={this.props.isSavingMaterial}
                                item={this.state.item}
                            />
                            <DeleteConfirmationModal
                                open={this.state.openRemoveMaterialModal}
                                title="deliveryOrderModule.removeMaterial"
                                onRemoveMaterial={this.removeMaterial}
                                onClose={() => this.onCloseModal("openRemoveMaterialModal")}
                                isSaving={this.props.isSavingDeliveryOrder}
                                item={this.state.item}

                            />

                            <CloseAndPrintModal
                                open={this.state.openCloseAndPrintModal}
                                title="deliveryOrderModule.closeConfirmationMessage"
                                onCloseAndPrint={this.closeAndPrintDeliveryOrder}
                                onCheckAvailability={this.checkAvailability}
                                onClose={() => this.onCloseModal("openCloseAndPrintModal")}
                                isSaving={this.props.isSavingDeliveryOrder}
                                partnership={this.props.partnership}
                            />

                            <AddCertificateModal
                                open={this.state.openAddCertificateModal}
                                title="deliveryOrderModule.addMaterial"
                                addICertificate={this.state.certificate ? this.onAddCertificate : this.onAddCertificate}
                                onClose={() => this.onCloseModal("openAddCertificateModal")}
                                certificate={this.state.certificate}
                                isSaving={this.props.isSavingDeliveryOrder}
                            />

                        </React.Fragment>}

                    {this.props.deliveryOrder === null &&
                        <React.Fragment>
                            <CardContent>
                                <Grid container spacing={24} justify="center">
                                    <Grid item xs={12}>
                                        <Typography align="center">
                                            <NoReleaseIcon color="error" />
                                            <Translate id="deliveryOrderModule.error.doesNotExistReleasePlan" />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </React.Fragment>}
                    <Toaster
                        message={!IsNullOrEmpty(this.state.additionalToasterData) ? `${this.props.translate(this.state.toasterMessage)} ${this.state.additionalToasterData}` : <Translate id={this.state.toasterMessage} />}
                        open={this.state.showToaster}
                        variant={this.state.toasterVariant}
                        onClose={() => { this.setState({ showToaster: false }) }}
                    />
                </Card>
            );
        }
    }
}



/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let selectedCompany = state.profile.get("selectedCompany");
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        company: selectedCompany ? selectedCompany.toJS() : null,
        isLoadingUser: state.oidc.isLoadingUser,
        user: state.oidc.user,
        isLoadingSupplier: state.suppliers.get("isLoadingSupplier"),
        partnership: state.suppliers.get("partnership") ? state.suppliers.get("partnership").toJS() : null,
        supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
        isLoadingDeliveryOrder: state.deliveryOrders.get('isLoadingDeliveryOrder'),
        isSavingDeliveryOrder: state.deliveryOrders.get('isSavingDeliveryOrder'),
        deliveryOrder: state.deliveryOrders.get('deliveryOrderDraft') ? state.deliveryOrders.get('deliveryOrderDraft').toJS() : null,
        partNumberCodes: state.deliveryOrders.get('partNumberCodes') ? state.deliveryOrders.get('partNumberCodes').toJS() : null,
        purchaseOrders: state.deliveryOrders.get('purchaseOrders') ? state.deliveryOrders.get('purchaseOrders').toJS() : null,
        positions: state.deliveryOrders.get('positions') ? state.deliveryOrders.get('positions').toJS() : null,
        isSavingMaterial: state.deliveryOrders.get('isSavingMaterial'),
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadSupplier: (token) => {
            return (dispatch(loadSupplier(token)))
        },
        loadPartnership: (supplierCode, companyToken) => {
            return (dispatch(loadPartnership(supplierCode, companyToken)))
        },
        getActive: () => {
            return (dispatch(getActiveDeliveryOrderFromBackEnd()))
        },
        getPartNumerCodes: () => {
            return (dispatch(loadPartNumberCodesFromBackEnd()))
        },
        getPurchaseOrders: (selectedPartNumber) => {
            return (dispatch(loadPurchaseOrdersFromBackEnd(selectedPartNumber)))
        },
        getPositions: (selectedPartNumber, selectedPurchaseOrder) => {
            return (dispatch(loadPositionsFromBackEnd(selectedPartNumber, selectedPurchaseOrder)))
        },
        addMaterial: (selectedPartNumber, selectedPurchaseOrder) => {
            return (dispatch(addMaterialToDeliveryOrder(selectedPartNumber, selectedPurchaseOrder)))
        },
        removeMaterial: (materialToken) => {
            return (dispatch(removeMaterialFromDeliveryOrder(materialToken)))
        },
        updateMaterial: (materialItemToken, material) => {
            return (dispatch(updateMaterialFromDeliveryOrder(materialItemToken, material)))
        },
        closeAndPrint: (arrivalData, deliveryOrderToken) => {
            return (dispatch(closeAndPrintDeliveryOrder(arrivalData, deliveryOrderToken)));
        },
        checkScheduleAvailability: (arrivalData, deliveryOrderToken) => {
            return (dispatch(checkScheduleAvailability(arrivalData, deliveryOrderToken)));
        },
        updatePropertyInItem: (materialItemToken, property, data) => {
            return (dispatch(updateMaterialProperty(materialItemToken, property, data, "deliveryOrderDraft")))
        },
        uploadFiles: (files) => {
            return dispatch(uploadFilesQualityCertificatesDraft(files));
        },
        removeFiles: (fileName) => {
            return dispatch(removeFilesQualityCertificatesDraft(fileName));
        },
        updatePropertyDeliveryOrder: (carbonFootprint, destination, deliveryOrderToken) => {
            return dispatch(updatePropertyDeliveryOrder(carbonFootprint, destination, deliveryOrderToken));
        },
        addQualityCertificatesDraft: (files) => {
            return dispatch(addQualityCertificatesDraft(files));
        },
        onRemoveQualityCertificateDocument: (certificateToken) => {
            return dispatch(onRemoveQualityCertificateDocument(certificateToken));
        },
        addQualityCertificates: (certificate) => {
            return dispatch(addQualityCertificates(certificate));
        },
        addItemInCertificateInDeliveryOrder: (certificateToken) => {
            return dispatch(addItemInCertificateInDeliveryOrder(certificateToken, true));
        },
        updateItemInCertificateInDeliveryOrder: (certificateToken, itemToken, property, data) => {
            return dispatch(updateItemInCertificateInDeliveryOrder(certificateToken, itemToken, property, data, true));
        },
        removeItemInCertificateInDeliveryOrder: (certificateToken, itemToken) => {
            return dispatch(removeItemInCertificateInDeliveryOrder(certificateToken, itemToken, true));
        }

    }
}


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create))));