import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import Select from 'react-select'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import ReworkIcon from '@material-ui/icons/AutorenewOutlined'; 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormLabel from '@material-ui/core/FormLabel';

/** Import component section */
import Toaster from '../../common/Toaster';
import LoadingPage from '../../common/LoadingPage';
import Dropzone from '../../common/Dropzone';
import { GetContentBrandsList } from '../../../store/helpers/SelectOptions';

import {loadReworkFromBackEnd, createReworkReportExecuted} from '../../../store/reworks/ReworkAction';

/**
 * Component to create Rework Report from rework 
 */
class ReworkReport extends Component{

     /**
     * Creation Rework Report an instance rework
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            attachments: [],
            reworkReport:{
                piecesOk: "",
                piecesNotOk: "",
                containmentMark: "",
                commentBySorter: "",
            }
        }
    }

       /**
     * componentDidMount
     */
    componentDidMount(){
        let reworkToken = this.props.match.params.token;
        this.props.loadRework(reworkToken);
    }

    /**
     * On seleted sorter by
     */
    onSorterBySelected(sorter){
        this.setState({sorterBySelected: sorter});
    }

      /**
     * Update the property of the rework
     *
     * @param {*} user
     * @param {*} value
     * @memberof View
     */
    updateProperty(property, value) {
        let creatRework = {...this.state.reworkReport};
        creatRework[property] = value;
        if(property === "piecesOk"){
            let quality = this.props.rework.quantity_of_material;
            let piecesNotOk = quality -  value;
            creatRework["piecesNotOk"] = piecesNotOk;
        }
        this.setState({
            reworkReport: creatRework
        })
    }

        /**
     * On change files of dropzone 
     *
     * @param {*} event
     * @memberof DetailsCard
     */
    onChangeFile(attachments) {
        this.setState({
            attachments
        })
        return Promise.resolve('Ok')
    }

    /**
     * On remove files of dropzone 
     *
     * @param {*} event
     * @memberof onDeleteFile
     */
    onDeleteFile(fileName) {
        let files = this.state.attachments.filter(file => {
            return file.name !== fileName;
        })
        this.setState({
            attachments: files
        })
        return Promise.resolve('Ok')
    }

     /**
     * Can be add item in quality Failure
     */
    canBeSaved(){
        let rework = this.state.reworkReport;
        if(this.state.attachments.length === 0)
            return false;
        if(rework.piecesOk === "" || rework.containmentMark === "" || rework.commentBySorter === "")
            return false;
        if(this.hasInvalidValueQantity())
            return false;
        else
            return true;
    }

        /**
     * Has valid quantity
     */
    hasInvalidValueQantity() {
        /// Validar que la cantidad retrabjada no puede ser mayor a la cantiad ok
        if(this.state.reworkReport.piecesOk !== "" && this.props.rework){
            let qualityRework = Number(this.props.rework.quantity_of_material);
            let piecesOk = Number(this.state.reworkReport.piecesOk);
            if(qualityRework < piecesOk){
                return true;
            }
            else
                return false;
        }
        else 
            return false;
    }

    /**
     * Get action to create createReworkReportExecuted from rework
     */
    onCreateRework(){
        let reworkToken = this.props.match.params.token;
        this.props.createReworkReportExecuted(this.state.attachments, this.state.reworkReport)
        .then(() =>{
            this.setState({
                showToView:true,
                showToaster: false
            })
            this.props.history.push(`/reworks/${reworkToken}`)
        }).catch(() =>{
            this.setState({
                showToaster:true,
                toasterMessage: <Translate id="common.errorToSave"/>,
                toasterVariant: "error",
            })
        });
    }

        /**
    * Get the card content
    *
    * @memberof Show
    */
   getCardContent() {
    if (this.props.isLoading === true) {
        return (
            <LoadingPage />
        )
    }
    else if (this.props.rework !== null) {
        const { classes } = this.props;
        let contentBrandsList = GetContentBrandsList();
        return(
                <Grid container spacing={24} style={{ paddingLeft: '80px', paddingRight: "100px", }}>
                    <Grid item xs={6}>
                        <div className={classes.textField} >
                            <Typography variant="body2" gutterBottom><strong><Translate id="reworks.qualityToReworker"/></strong>
                            {": "}{ this.props.rework.quantity_of_material}
                            </Typography>
                        </div>

                        <TextField required fullWidth
                            error = {this.hasInvalidValueQantity()}
                            id="standard-read-only-input"
                            label={<Translate id="reworks.piecesOk"/>}
                            value={this.state.reworkReport.piecesOk}
                            className={classes.textField}
                            type="number"
                            margin="normal"
                            onChange={(event) => {this.updateProperty("piecesOk", event.target.value)}}
                        />
                        
                    </Grid>

                    <Grid item xs={6}><br/>
                        <TextField required fullWidth disabled
                            error = {this.hasInvalidValueQantity()}
                            id="standard-read-only-input"
                            label={<Translate id="reworks.piecesNotOk"/>}
                            value={this.state.reworkReport.piecesNotOk}
                            className={classes.textField}
                            type="number"
                            margin="normal"
                            onChange={(event) => {this.updateProperty("quality", event.target.value)}}
                        />

                    </Grid>

                    <Grid item xs={12}>
                        <div className={classes.textFielsd} >
                            <FormLabel component="legend"><Translate id="reworks.containmentMark"/> *</FormLabel>
                            <Select
                                defaultValue={contentBrandsList[0]}
                                options={contentBrandsList}
                                fullWidth
                                onChange={(event) => this.updateProperty("containmentMark", event.value)}
                            />
                            <br />
                        </div>

                        <Typography variant='body2' component='p'><Translate id={'qualityfailurereport.jobAttachment'} /></Typography>
                        <Dropzone
                            files={this.state.attachments}
                            onChange={(event) => this.onChangeFile(event)}
                            onDeleteFile={this.onDeleteFile}
                            baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                            disabledLink
                        />

                        <TextField fullWidth required
                            id="outlined-multiline-flexible"
                            label={<Translate id="reworks.descriptionRework"/>}
                            multiline
                            className={classes.tetxtFieldFull} 
                            rows="3"
                            value={this.state.reworkReport.commentBySorter}
                            onChange={(event) => {this.updateProperty("commentBySorter", event.target.value)}}
                            margin="normal"
                            variant="outlined"
                        /><br/>
                    </Grid>

                    <Grid container >
                        <DialogActions>
                            <Button 
                                data-cy="btn-save"
                                type="button" 
                                color="primary" 
                                onClick={this.onCreateRework}
                                disabled = {!this.canBeSaved() || this.props.isSaving}>
                                {<Translate id="common.save"/>}
                            </Button>
                            <Button onClick={() => {this.props.history.push(`/reworks/${this.props.rework.token}`)}} color="secondary">
                                {<Translate id="common.cancel"/>}
                            </Button>
                        </DialogActions>
                    </Grid> 

                    <Toaster 
                        message = {this.state.toasterMessage}
                        open = {this.state.showToaster}
                        variant = {this.state.toasterVariant}
                        onClose = {() => {this.setState({ showToaster:false})}}
                    /> 
                </Grid> 
            )
        }
    }

    render(){
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <ReworkIcon />
                    </Avatar>
                }
                title={<Typography variant="button" ><Translate id="reworks.detailsMaterialRework"/></Typography>}
                subheader={<Typography variant="caption" color="textPrimary"><Translate id="common.createBy"/>: {this.props.user.profile.name}
                 <br/><Translate id="common.creationDate"/>: {this.state.creationDate}</Typography>}
            />
            <CardContent>
                {this.getCardContent()}
            </CardContent>

            <Toaster 
                message = {this.state.toasterMessage}
                open = {this.state.showToaster}
                variant = {this.state.toasterVariant}
                onClose = {() => {this.setState({ showToaster:false})}}
            /> 

        </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    return {
        user: user,
        isSaving: state.reworks.get('isSaving'),
        isLoading: state.reworks.get('isLoading'),
        rework: state.reworks.get("rework") ? state.reworks.get("rework").toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadRework: (token) => {
            return dispatch(loadReworkFromBackEnd(token));
        },
        createReworkReportExecuted:(attachments, reworkReport) =>{
            return dispatch(createReworkReportExecuted(attachments, reworkReport))
        }
    }
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: "100px",
        paddingRight: "100px"
    },
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    },
    textField:{
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        maxWidth: 450,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(ReworkReport))));