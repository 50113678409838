import axios from 'axios'

import { descriptionStatus, backgroundColorTableStatus } from '../helpers/DeliveryQualificationsHelper'
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper'

export const START_LOADING_DELIVERY_QUALIFICATIONS = 'START_LOADING_DELIVERY_QUALIFICATIONS'
export const SET_DELIVERY_QUALIFICATIONS_LIST = 'SET_DELIVERY_QUALIFICATIONS_LIST'
export const SET_DELIVERY_QUALIFICATION = 'SET_DELIVERY_QUALIFICATION'
export const CHANGE_DELIVERY_QUALIFICATIONS_PAGINATION = 'CHANGE_DELIVERY_QUALIFICATIONS_PAGINATION'
export const CHANGE_DELIVERY_QUALIFICATIONS_QUERY = 'CHANGE_DELIVERY_QUALIFICATIONS_QUERY'
export const CHANGE_DELIVERY_QUALIFICATIONS_SORT_CRITERIA = 'CHANGE_DELIVERY_QUALIFICATIONS_SORT_CRITERIA'

function isLoadingDeliveryqualifications(isLoading) {
	return {
		type: START_LOADING_DELIVERY_QUALIFICATIONS,
		isLoading: isLoading,
	}
}

function setDeliveryQualificationsList(deliveryQualifications) {
	return {
		type: SET_DELIVERY_QUALIFICATIONS_LIST,
		deliveryQualifications: deliveryQualifications,
	}
}

function setDeliveryQualification(deliveryQualification) {
	return {
		type: SET_DELIVERY_QUALIFICATION,
		deliveryQualification: deliveryQualification,
	}
}

export function loadDeliveryQualifications(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingDeliveryqualifications(true))
		let supplierNumber = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierNumber = getState().oidc.user.profile.company_code
		} else supplierNumber = ''
		let data = {
			SupplierNumber: supplierNumber,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/deliveryqualifications/query`

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let mappedResponse = serverResponse.data.items.Data.items.map((qualificationFolio) => {
						qualificationFolio.color_status_table = backgroundColorTableStatus(qualificationFolio.status)
						qualificationFolio.status_translated = descriptionStatus(qualificationFolio.status)
						qualificationFolio.total_items = serverResponse.data.items.Data.total_items
						qualificationFolio.items_by_page = serverResponse.data.items.Data.items_by_page
						return qualificationFolio
					})
					serverResponse.data.items.Data.items = mappedResponse
					dispatch(setDeliveryQualificationsList(serverResponse.data.items.Data))
					dispatch(isLoadingDeliveryqualifications(false))
				}
			})
			.catch((error) => {
				console.error('Error to load  delivery qualifications ', error.response)
				dispatch(isLoadingDeliveryqualifications(false))
				return Promise.reject()
			})
	}
}

export function getDeliveryQualificationByToken(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingDeliveryqualifications(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/deliveryqualification/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.item.Data[0].color_status_table = backgroundColorTableStatus(
						serverResponse.data.item.Data[0].status
					)
					serverResponse.data.item.Data[0].status_translated = descriptionStatus(
						serverResponse.data.item.Data[0].status
					)
					dispatch(setDeliveryQualification(serverResponse.data.item.Data))
				}
			})
			.catch((error) => {
				console.error('Error to load  delivery qualifications ', error.response)
				dispatch(isLoadingDeliveryqualifications(false))
				return Promise.reject()
			})
	}
}
