import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/**Import MaterialUi section */
import { Grid, Avatar, Typography, Button } from '@material-ui/core'
import FindInPage from '@material-ui/icons/FileCopy'

/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import Evidence from '../../common/Evidence'

const PanelNoteFiles = (props) => {
	/**props section */
	const { onClose, onUploadFiles, isSaving, classes, isConsignmentNote, isacknowledgments } = props

	const [attachments, setAttachments] = useState([])
	const [errors, setErrors] = useState([])
	const [message, setMessage] = useState('')

	const canBeSaved = () => {
		if (attachments.length === 0) return false
		else return true
	}

	/**
	 * On upload file in maquila order in list name
	 * @param {*} files
	 */
	const uploadFiles = (files) => {
		let filesList = attachments.concat(files[0])
		if (files.length > 0) {
			for (var index = 1; index < files.length; index++) {
				filesList.push(files[index])
			}
		}
		setAttachments(filesList)
		setMessage('')
		return Promise.resolve('Ok')
	}

	/**
	 * Handle event remove files of evidence componet to remove files of list
	 *
	 */
	const removeFiles = (fileName) => {
		let newList = attachments.filter((files) => {
			return files.name !== fileName
		})
		setAttachments(newList)
		setMessage('')
		return Promise.resolve('Ok')
	}

	const onSaveAttahcments = () => {
		if (props.onUploadFiles) {
			if (!isacknowledgments) {
				if (attachments.length !== 2) {
					setMessage('Debe agregar un archivo PDF y un XML')
				} else {
					onUploadFiles(attachments)
						.then((res) => {
							onClose()
						})
						.catch((data) => {
							setErrors((data && data.errors) || [])
						})
				}
			} else {
				if (attachments.length === 0) {
					setMessage('Debe agregar un archivo para poder guradar la información')
				} else {
					onUploadFiles(attachments)
						.then((res) => {
							onClose()
						})
						.catch((data) => {
							setErrors((data && data.errors) || [])
						})
				}
			}
		}
	}

	return (
		<LateralPanel onClose={onClose}>
			<PanelHeader
				titlePanel={props.title}
				icon={
					<Avatar className={classes.avatar}>
						<FindInPage />
					</Avatar>
				}
				onCloseButtonClick={onClose}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid item xs={12}>
						<Typography className={classes.textTitle} color='primary'>
							{props.message}
						</Typography>
						{isacknowledgments && (
							<Evidence
								text={props.text}
								files={attachments}
								uploadFiles={uploadFiles}
								removeFiles={removeFiles}
								isDisabled={false}
								acceptedFiles={['pdf', 'images']}
								disabledLink
								successToasterDisplayed={false}
							/>
						)}
						{!isacknowledgments && (
							<Evidence
								text={props.text}
								files={attachments}
								uploadFiles={uploadFiles}
								removeFiles={removeFiles}
								isDisabled={false}
								acceptedFiles={['pdf', 'xml']}
								disabledLink
								successToasterDisplayed={false}
							/>
						)}
						{isConsignmentNote && (
							<Typography color='primary'>
								<br />
								<Translate id='consignmentNote.show.noteconsignmentNote' />
							</Typography>
						)}
					</Grid>
					{message && (
						<Grid>
							<br />
							<Typography className={classes.textTitle} color='error'>
								{message}
							</Typography>
						</Grid>
					)}
					{errors.length > 0 && (
						<div className={classes.errorsContainer}>
							<Typography variant='overline'>
								<Translate id='accreditedWorkshops.errors' />
							</Typography>
							{errors.map(({ code, data }) => (
								<Typography>
									{`- ${data}: ` || ''}
									<Translate id={`consignmentNote.errors.${code}`} />
								</Typography>
							))}
						</div>
					)}
				</div>
				<Grid container>
					<Grid item xs={12} className={classes.containerBtn}>
						<Button
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='outlined'
							className={classes.saveButton}
							disabled={!canBeSaved() || isSaving}
							onClick={onSaveAttahcments}
						>
							{<Translate id='common.save' />}
						</Button>
						<Button
							data-cy='btn-cancel'
							type='button'
							color='secondary'
							variant='outlined'
							className={classes.cancelButton}
							onClick={onClose}
						>
							{<Translate id='common.cancel' />}
						</Button>
					</Grid>
				</Grid>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	textTitle: {
		textAlign: 'center',
		paddingBottom: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	saveButton: {
		bottom: 0,
		margin: 2,
	},
	cancelButton: {
		color: theme.palette.error.main,
		bottom: 0,
		margin: 2,
	},
	errorsContainer: {
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: 10,
		marginTop: theme.spacing.unit,
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
	},
})

PanelNoteFiles.propTypes = {
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onSave: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
	/**
	 * Validate if template is correctly
	 */
	onClose: propTypes.func,
}

PanelNoteFiles.defaultProps = {
	onSave: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onClose: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelNoteFiles))
