import React, { useState, useEffect } from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { decodeJson, decodeNumericArray, decodeArray } from 'use-query-params'
import { withRouter } from 'react-router-dom'

/* Material UI import section */
import { Avatar, Card, CardContent, CardHeader, Typography, withStyles } from '@material-ui/core'
import AccreditedWorkShopsIcon from '@material-ui/icons/HowToReg'

/** Custom components import section */
import DataTable from '../../common/DataTable'
import LoadingPage from '../../common/LoadingPage'
import ContextFilterMenu from '../../common/contextFilters/ContextFilterMenu'
import ServiceOrderDetail from './components/ServiceOrderDetail'
import ButtonDownload from '../components/ButtonDownload'
import MetricsCard from './components/MetricsCard'
import ServiceOrdersSourceSwitch from '../components/ServiceOrdersSourceSwitch'
import ButtonTableColumnSettings from '../components/ButtonColumnsTableSettings'
import ColumnsConfigurator from '../../common/DataGrid/ColumnsConfigurator'
import Spacer from '../../common/Spacer'

/** Actions imoprt section */
import {
	loadAccreditedWorkshopsServicesFromBackEnd,
	setInvoice,
	setPaymentRequest,
	downloadServiceOrdersReport,
	loadAccreditedWorkshopsServicesTotalFromBackEnd,
	loadAccreditedWorkshopsServicesTotalAmountFromBackEnd,
	setServicesList,
	loadAccreditedWorkshopsServicesInPs9FromBackEnd,
	downloadServiceOrdersInPs9Report,
} from '../../../store/accreditedWorkshops/accreditedWorkshopsActions.js'

/** Redux selectors import section */
import {
	getServiceOrderListSelector,
	getServiceOrdersTotal,
	getServiceOrdersAmount,
	getServiceTypesOnConfigurationSelector,
	getAllowedStatusForNotReleasedServicesSelector,
} from '../../../store/accreditedWorkshops/accreditedWorkshopsSelectors'
import { getSupplierSelector } from '../../../store/suppliers/SupplierSelectors'

/** Resources import section */
import serviceOrderTableConfiguration from './serviceHelpers/TableConfiguration'
import { buildFilterConfig, FilterCriteria } from './serviceHelpers/FilterConfiguration'
import { decodeDelimitedPipeArray } from '../../common/contextFilters//CustomArrayParam'
import { renderCustomCellItem } from './serviceHelpers/TableHelpers'

/** Custom hooks import section */
import useClearFiltersButton from '../hooks/ClearFiltersButtonHook'
import useSorting from '../hooks/SortingHook'
import { useConfigurator } from '../../common/DataGrid/UseConfigurator'

const Source = {
	CRM: 'CRM',
	PS9: 'PS9',
}

const tableId = 'workshops-not-released-service-orders-table-config'

const NotReleasedServices = (props) => {
	/** Desctructuring properties */
	const { location, translate, classes } = props

	/** Defines local state */
	const [serviceOrdersSource, setServiceOrdersSource] = useState('CRM')
	const [loadingServiceOrders, setLoadingServiceOrders] = useState(false)
	const [loadingTotalAmount, setLoadingTotalAmount] = useState(false)
	const [showCircularProgress, setShowCircularProgress] = useState(false)
	const [rowsPerPage, setRowsPerPage] = useState(20)
	const [page, setPage] = useState(0)
	const [serviceTableConfiguration, setServiceTableConfiguration] = useConfigurator(
		tableId,
		serviceOrderTableConfiguration
	)
	const [increasedTableConfiguration, setIncreasedTableConfiguration] = useState(serviceTableConfiguration)
	const [configuratorAnchorEl, setConfigurationAnchorEl] = useState(null)
	// const [selectedServiceOrders, setSelectedServiceOrders] = useState([]);
	const [serviceOrderDetailPanelOptions, setServiceOrderDetailPanelOptions] = useState({
		opened: false,
		onCloseButtonClick: null,
		token: '',
	})
	const [filters, setFilters] = useState([])
	const [filterSettings, setFilterSettings] = useState({
		open: false,
		anchorEl: null,
		type: '',
		filterIdentificator: '',
	})
	const servicesComesFromCRM = serviceOrdersSource === Source.CRM

	/** Connect with redux */
	const dispatch = useDispatch()
	const supplier = useSelector((state) => getSupplierSelector(state))
	const serviceOrdersTotal = useSelector((state) => getServiceOrdersTotal(state))
	const serviceOrders = useSelector((state) => getServiceOrderListSelector(state))
	const totalAmount = useSelector((state) => getServiceOrdersAmount(state))
	const serviceTypes = useSelector((state) => getServiceTypesOnConfigurationSelector(state))
	const allowedServiceOrderStatus = useSelector((state) => getAllowedStatusForNotReleasedServicesSelector(state))

	const [sortingBy, sortingDirection, dataSource, orderAsc, handleSortingCriteriaChange] = useSorting()
	const [ClearFiltersButton] = useClearFiltersButton()

	/** Set initial filters */
	useEffect(() => {
		if (!!supplier) {
			dispatch(setInvoice(null))
			dispatch(setPaymentRequest(null))
		}
	}, [supplier])

	useEffect(() => {
		if (!!supplier && !!location && serviceTypes.length > 0 && allowedServiceOrderStatus.length > 0) {
			const queryParams = queryString.parse(location.search)
			const searchFilters = []

			Object.entries(queryParams).forEach(([name, value]) => {
				if (name.startsWith('Advanced')) {
					let newName = name.replace('Advanced', '')
					switch (newName) {
						case 'ServiceCost': {
							const decodedFilter = decodeJson(value)
							const decodedValues = decodeNumericArray(decodedFilter.values)
							searchFilters.push({
								PropertyIdentificator: newName,
								RuleValue: decodedValues,
								FilterCategorization: decodedFilter.criteria,
							})
							break
						}
						default: {
							const decodedFilter = decodeJson(value)
							const decodedValues = decodeArray(decodedFilter.values)
							searchFilters.push({
								PropertyIdentificator: newName,
								RuleValue: decodedValues,
								FilterCategorization: decodedFilter.criteria,
							})
							break
						}
					}
				} else if (!name.includes('order')) {
					switch (name) {
						case 'pageNumber': {
							break
						}
						case 'TallerImporteServicio':
						case 'TallerImporteGastos': {
							const decodedValues = decodeDelimitedPipeArray(value)
							searchFilters.push({
								PropertyIdentificator: name,
								RuleValue: decodedValues,
								FilterCategorization: FilterCriteria.NUMERIC_EQUAL,
							})
							break
						}
						default: {
							const decodedValues = decodeDelimitedPipeArray(value)
							searchFilters.push({
								PropertyIdentificator: name,
								RuleValue: decodedValues.map((val) => (val === '(VACIO)' ? ' ' : val)),
								FilterCategorization: name.includes('Date')
									? FilterCriteria.BETWEEN_DATES
									: FilterCriteria.EQUAL,
							})
							break
						}
					}
				}
			})
			searchFilters.push({
				PropertyIdentificator: 'ServiceTypeId',
				RuleValue: serviceTypes.map((st) => st.Type),
			})
			setFilters([
				...searchFilters,
				{
					PropertyIdentificator: 'WorkshopTechnicianSapId',
					RuleValue: [supplier.code],
				},
				{
					PropertyIdentificator: 'OrdenStatus',
					RuleValue: allowedServiceOrderStatus, //['10']
				},
				{
					PropertyIdentificator: 'WorkshopStatus',
					RuleValue: ['100'],
				},
			])
		}
	}, [supplier, serviceOrdersSource, serviceTypes, location, allowedServiceOrderStatus])

	/** Load service orders */
	useEffect(() => {
		if (serviceOrdersSource === Source.CRM) {
			if (filters.length > 0) {
				setLoadingServiceOrders(true)
				dispatch(
					loadAccreditedWorkshopsServicesFromBackEnd(filters, page, rowsPerPage, sortingBy, sortingDirection)
				).finally(() => setLoadingServiceOrders(false))
				dispatch(loadAccreditedWorkshopsServicesTotalFromBackEnd(filters))
				setLoadingTotalAmount(true)
				dispatch(loadAccreditedWorkshopsServicesTotalAmountFromBackEnd(filters)).finally(() =>
					setLoadingTotalAmount(false)
				)
			} else {
				dispatch(setServicesList([]))
				setLoadingServiceOrders(false)
			}
		} else if (serviceOrdersSource === Source.PS9 && supplier) {
			setLoadingServiceOrders(true)
			const serviceTypeIds = serviceTypes.map(({ Type }) => Type)
			dispatch(loadAccreditedWorkshopsServicesInPs9FromBackEnd([supplier.code], serviceTypeIds)).finally(() =>
				setLoadingServiceOrders(false)
			)
		}
	}, [filters, serviceOrdersSource, sortingBy, sortingDirection, page, rowsPerPage, supplier, serviceTypes])

	useEffect(() => {
		if (servicesComesFromCRM) {
			setIncreasedTableConfiguration(serviceTableConfiguration.filter((c) => c.dataSource !== 'selectCheck'))
		} else {
			setIncreasedTableConfiguration([
				...serviceTableConfiguration
					.filter((c) => c.dataSource !== 'selectCheck')
					.map((c) => ({ ...c, isSortable: false, filterEnabled: false })),
				{
					header: <Translate id='common.status' />,
					dataSource: 'statusDescription',
					isSortable: false,
					filterEnabled: false,
				},
			])
		}
	}, [serviceTableConfiguration, servicesComesFromCRM])
	/**
	 * Handle the service orders selection and unpdate local state.
	 * @param {Array} selectedTokens
	 */
	const handleServiceOrdersSelection = (selectedTokens) => {
		// const selectedSerciceOrderCodes = serviceOrders.reduce(
		// 	(serviceCodes, serviceOrder) =>
		// 		selectedTokens.includes(serviceOrder.token) ? [...serviceCodes, serviceOrder.code] : serviceCodes,
		// 	[]
		// );
		// setSelectedServiceOrders(selectedSerciceOrderCodes);
	}

	const handlePageChange = (newPage, newRowsPerPage) => {
		setRowsPerPage((prevRowsPerPage) => {
			const pageToSet = prevRowsPerPage !== newRowsPerPage ? 0 : newPage
			setPage(pageToSet)
			return newRowsPerPage
		})
	}

	const openContextFilterMenu = (event, dataSource) => {
		let filterConfig = buildFilterConfig(dataSource)
		const rect = event.currentTarget.getBoundingClientRect()
		setFilterSettings({
			...filterConfig,
			open: true,
			anchorEl: event.currentTarget,
			x: rect.x,
			y: rect.y + rect.height,
		})
	}

	const closeContextFilterMenu = () => {
		setFilterSettings({
			open: false,
			anchorEl: null,
			type: '',
			name: '',
			dataSource: '',
			filterIdentificator: '',
		})
	}

	/**
	 * Update state in order to show the lateral panel with service order detail.
	 * @param {Object} item
	 * @param {Object} event
	 */
	const openServiceOrderDetail = (item, event) => {
		const { tagName } = event.target
		if (tagName !== 'INPUT') {
			const { token } = item
			setServiceOrderDetailPanelOptions({ opened: true, onCloseButtonClick: closeServiceOrderDetail, token })
		}
	}

	/**
	 * Update state in order to close the lateral panel with service order detail
	 */
	const closeServiceOrderDetail = () => {
		setServiceOrderDetailPanelOptions({ opened: false, token: '' })
	}

	/**
	 * Get counter
	 *
	 * @returns
	 * @memberof NotReleasedServices
	 */
	const getCounterMessage = () => {
		if (serviceOrders) {
			let quantityOfServices = serviceOrders.length
			return (
				<div>
					{translate('common.showing')}&nbsp;
					<span>{quantityOfServices}</span>&nbsp;
					{translate('common.of')}&nbsp;
					<span className={classes.totalCounter}>{serviceOrdersTotal}</span>
					&nbsp;
					{translate('common.records')}
				</div>
			)
		}
		return <span></span>
	}

	/*
	 * DOWNLOAD SERVICES REPORT
	 */
	const downloandServiceOrdersReportNotReleasedServices = () => {
		setShowCircularProgress(true)
		if (servicesComesFromCRM) {
			dispatch(downloadServiceOrdersReport(filters)).finally(() => setShowCircularProgress(false))
		} else {
			const serviceTypeIds = serviceTypes.map(({ Type }) => Type)
			dispatch(downloadServiceOrdersInPs9Report([supplier.code], serviceTypeIds)).finally(() =>
				setShowCircularProgress(false)
			)
		}
	}

	const openTableColumnsConfigurator = (event) => {
		setConfigurationAnchorEl(configuratorAnchorEl ? null : event.currentTarget)
	}

	/**
	 * The user change the service table configuration
	 */
	const onTableConfigChange = (configuration) => {
		setServiceTableConfiguration(configuration)
		setConfigurationAnchorEl(null)
	}

	return (
		<>
			<Card>
				<CardHeader
					className={classes.cardHeader}
					avatar={
						<Avatar>
							<AccreditedWorkShopsIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							{servicesComesFromCRM && (
								<>
									<MetricsCard
										loading={loadingTotalAmount}
										quantityOfServices={serviceOrdersTotal}
										amount={totalAmount}
									/>
									<Spacer />
									<ClearFiltersButton />
									<Spacer />
								</>
							)}
							<ButtonDownload
								onClick={downloandServiceOrdersReportNotReleasedServices}
								showCircularProgress={showCircularProgress}
							/>
							<Spacer />
							<Spacer />
							<ButtonTableColumnSettings onShowConfiguration={openTableColumnsConfigurator} />
							<ColumnsConfigurator
								open={configuratorAnchorEl ? true : false}
								anchorEl={configuratorAnchorEl}
								configuration={serviceTableConfiguration}
								onConfigurationChange={onTableConfigChange}
								onClickAway={() => setConfigurationAnchorEl(null)}
							/>
							<Spacer />
							<ServiceOrdersSourceSwitch
								checked={!servicesComesFromCRM}
								onChange={() => setServiceOrdersSource(servicesComesFromCRM ? Source.PS9 : Source.CRM)}
							/>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='accreditedWorkshops.accreditedWorkshopsService' />{' '}
						</Typography>
					}
					subheader={getCounterMessage}
				/>
				<CardContent className={classes.cardContent}>
					{
						{
							true: <LoadingPage />,
							false: (
								<DataTable
									isIndex
									rowHeight={32}
									data={serviceOrders}
									configuration={{ columns: increasedTableConfiguration }}
									sortBy={dataSource}
									sortAscending={orderAsc}
									onChangeSortCriteria={handleSortingCriteriaChange}
									onRowClick={servicesComesFromCRM ? openServiceOrderDetail : undefined}
									isNotReleasedServices
									onRenderCellItem={renderCustomCellItem}
									totalRows={servicesComesFromCRM ? serviceOrdersTotal : undefined}
									page={page}
									rowsPerPage={rowsPerPage}
									onChangePage={handlePageChange}
									onSelectedCheck={handleServiceOrdersSelection}
									onFilterClick={openContextFilterMenu}
								/>
							),
						}[loadingServiceOrders]
					}
				</CardContent>
			</Card>
			{filterSettings.open && (
				<ContextFilterMenu
					{...filterSettings}
					filters={filters}
					onClose={closeContextFilterMenu}
					//onAvancedFilterClick={openAvancedFilterModal}
				/>
			)}
			{/* {advancedFilterModalConfig.open && (
				<AdvancedFiltersModal {...advancedFilterModalConfig} onClose={closeAvancedFilterModal} />
			)} */}
			{serviceOrderDetailPanelOptions.opened && (
				<ServiceOrderDetail classes={classes} {...serviceOrderDetailPanelOptions} />
			)}
		</>
	)
}

const styles = (theme) => ({
	cardHeader: {
		padding: '8px 16px 0px 4px',
	},
	cardContent: {
		padding: '4px 4px',
		maxWidth: 'calc(100vw - 300px)',
	},
	actionsToolbar: {
		display: 'flex',
		alignItems: 'center',
	},
	instructionsDiv: {
		padding: theme.spacing.unit,
		maxWidth: '20vw',
	},
})

export default withRouter(withLocalize(withStyles(styles, { withTheme: true })(NotReleasedServices)))
