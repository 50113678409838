import axios from 'axios'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'

export const IS_LOADING_PRICE = 'IS_LOADING_PRICE'
export const GET_PRICE_CHANGE_IN_DRAFT = 'GET_PRICE_CHANGE_IN_DRAFT'
export const ADD_FILE_PRICE_CHANGE = 'ADD_FILE_PRICE_CHANGE'
export const REMOVE_FILE_PRICE_CHANGE = 'REMOVE_FILE_PRICE_CHANGE'
export const SET_REQUESTS_LIST = 'SET_REQUESTS_LIST'
export const SET_REQUESTS_COUNT = 'SET_REQUESTS_COUNT'
export const CHANGE_REQUESTS_PAGINATION = 'CHANGE_REQUESTS_PAGINATION'
export const CHANGE_REQUEST_QUERY = 'CHANGE_REQUEST_QUERY'
export const CHANGE_REQUEST_SORT_CRITERIA = 'CHANGE_REQUEST_SORT_CRITERIA'
export const GET_REQUEST_PRICE_CHANGE_IN_DRAFT = 'GET_REQUEST_PRICE_CHANGE_IN_DRAFT'
export const UPDATE_DETAIL_PLANE_REQUEST = 'UPDATE_DETAIL_PLANE_REQUEST'
export const UPDATE_COMMENTS_REQUEST_PRICE_CHANGE = 'UPDATE_COMMENTS_REQUEST_PRICE_CHANGE'

/**
 * Action start loading price change
 * @param {*} isLoading
 */
function isLoadingPrice(isLoading) {
	return {
		type: IS_LOADING_PRICE,
		isLoading: isLoading,
	}
}
/**
 * Action load alternative proveduria in draft
 * @param {*} requestPriceChangeDraft
 */
function loadRequestPriceChangeInDraft(requestPriceChangeDraft) {
	return {
		type: GET_PRICE_CHANGE_IN_DRAFT,
		requestPriceChangeDraft: requestPriceChangeDraft,
	}
}
/**
 * Add file
 *
 * @param {*} files
 * @returns
 */
function addFile(files, tokenPartNumber) {
	return {
		type: ADD_FILE_PRICE_CHANGE,
		files,
		tokenPartNumber,
	}
}

/**
 * Remove file
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName, tokenPartNumber) {
	return {
		type: REMOVE_FILE_PRICE_CHANGE,
		fileName,
		tokenPartNumber,
	}
}

/**
 * Action to set request list
 * @param {*} provedures
 */
function setRequestsList(requests) {
	return {
		type: SET_REQUESTS_LIST,
		requests: requests,
	}
}
/**
 * Action reducer to set the provedurie's counter
 *
 * @param {*} count
 * @returns
 */
function setRequestsCount(count) {
	return {
		type: SET_REQUESTS_COUNT,
		count: count,
	}
}
/**
 * Action to upate plot
 * @param {*} detailPlane
 */
function updateDetailSchematic(detailPlane) {
	return {
		type: UPDATE_DETAIL_PLANE_REQUEST,
		detailPlane: detailPlane,
	}
}
/**
 * Action to update comments of part number in details list
 *
 * @param {*} partNumberDetail
 * @returns
 */
function updateComments(partNumberDetail) {
	return {
		type: UPDATE_COMMENTS_REQUEST_PRICE_CHANGE,
		partNumberDetail,
	}
}

export function initialRequestPriceChangeInDraft() {
	return (dispatch, getState) => {
		dispatch(isLoadingPrice(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/requestPriceChange/initial`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(loadRequestPriceChangeInDraft(serverResponse.data))
					dispatch(isLoadingPrice(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingPrice(false))
				console.error('Error adding a request price change', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add item in request price change
 * @param {*} item
 */
export function addItemInRequestPriceChange(priceChange, item, supplierSelected, developmentSelected) {
	return (dispatch, getState) => {
		dispatch(isLoadingPrice(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			CompanyToken: priceChange.company_token,
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CenterInitial: selectedCompany.get('center'),
			CenterFin: selectedCompany.get('center_fin'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			CreateEmail: typeof user.profile.email === 'string' ? user.profile.email : user.profile.email[0],

			SupplierToken: supplierSelected ? supplierSelected.token : priceChange.supplier_token,
			SupplierName: supplierSelected ? supplierSelected.name : priceChange.supplier_name,
			SupplierNumber: supplierSelected ? supplierSelected.code : priceChange.supplier_code,
			SupplierCountry: supplierSelected ? supplierSelected.country : priceChange.country,

			DevelopmentName: developmentSelected ? developmentSelected.name : '',
			DevelopmentEmail: developmentSelected ? developmentSelected.user_email : '',
			DevelopmentUserName: developmentSelected ? developmentSelected.user_name : '',

			PartNumber: item.partNumber.code,
			PartDescription: item.partNumber.description,
			Revise: item.partNumber.revision,
			Volumen: item.volumen,
			VolumenUnit: item.volumnetMeaurement,
			Commnets: item.observations,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/requestPriceChange`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(loadRequestPriceChangeInDraft(serverResponse.data))
					dispatch(isLoadingPrice(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingPrice(false))
				console.error('Error adding a quote for a memo', error.response)
				return Promise.reject(error.response)
			})
	}
}
/**
 * Action to remove item in request price change
 */
export function onRemoveItemInRequestPriceChange(itemToken, supplierSelected) {
	return (dispatch, getState) => {
		dispatch(isLoadingPrice(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let tokenRequest = getState().requestPriceChange.get('requestPriceChangeDraft').get('token')
		let supplierToken = supplierSelected
			? supplierSelected.token
			: getState().requestPriceChange.get('requestPriceChangeDraft').get('supplier_token')
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/requestPriceChange/${tokenRequest}/details/${itemToken}/supplier/${supplierToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingPrice(false))
					dispatch(loadRequestPriceChangeInDraft(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingPrice(false))
				console.error('Error removing a item from a request price change', error.response)
				return Promise.reject()
			})
	}
}
export function onAddFiles(files, tokenPartNumber) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumbers/upload/${tokenPartNumber}`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse.data, tokenPartNumber))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error to upload file(s) in material delivery', error.response)
				return Promise.reject()
			})
	}
}
/**
 * Action to remove files
 *
 * @export
 * @param {*} fileName
 * @returns
 */
export function onRemoveFiles(fileName, tokenPartNumber) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/remove/${tokenPartNumber}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFile(fileName, tokenPartNumber))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s)', error.response)
				return Promise.reject()
			})
	}
}
/**
 * Action from send quotes and sample request from request price change
 */
export function sendQuotesRequestFromRequestPriceChange() {
	return (dispatch, getState) => {
		dispatch(isLoadingPrice(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')

		let token = getState().requestPriceChange.get('requestPriceChangeDraft').get('token')
		let documentIdentifier = selectedCompany.get('document_identifier')
		let data = {}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/requestPriceChange/${token}/${documentIdentifier}/send`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingPrice(false))
					return Promise.resolve(serverResponse)
				}
			})
			.catch((error) => {
				console.error('Error in send quotes and a sample request from alternative provedurie', error.response)
				dispatch(isLoadingPrice(false))
				return Promise.reject()
			})
	}
}
/**
 * Get all request price change by company from backend
 */
export function loadRequestFromBackEnd(queryParameters) {
	return function (dispatch, getState) {
		dispatch(isLoadingPrice(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/requestPriceChange/request`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			Page: queryParameters && queryParameters.pageNumber ? parseInt(queryParameters.pageNumber) + 1 : 1,
			RowsPerPage: queryParameters && queryParameters.rowsPerPage ? queryParameters.rowsPerPage : 20,
			SortBy: queryParameters && queryParameters.sortBy ? queryParameters.sortBy : 'Folio',
			SortAscending:
				queryParameters && queryParameters.sortDirection
					? queryParameters.sortDirection === 'asc'
						? false
						: true
					: false,
			Query: queryParameters && queryParameters.query ? queryParameters.query : '',
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setRequestsList(serverResponse.data))
					dispatch(isLoadingPrice(false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingPrice(false))
				console.error('Error getting the alternative provedures by company list', error.response)
			})
	}
}
/**
 * Get proveduries's count
 *
 * @export
 * @returns
 */
export function getRequestsCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().requestPriceChange.get('priceChangeRequestsCount')
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/requestPriceChange/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setRequestsCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the provedure's count", error.response)
			})
	}
}
/**
 * Get and set the request price change in the estate from read from backend
 * @param {*} token
 */
export function loadRequestPriceChangeFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingPrice(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/requestPriceChange/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(loadRequestPriceChangeInDraft(serverResponse.data))
					dispatch(isLoadingPrice(false))
				}
			})
			.catch((error) => {
				console.error('Error to load request price change', error.response)
				dispatch(isLoadingPrice(false))
				return Promise.reject()
			})
	}
}
/**
 * Action to update detal part number plane
 * @param {*} detailToken
 * @param {*} planeName
 */
export function updateDetailPartnumberPlaneNameRequest(detailToken, planeName) {
	return (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partNumbers/plots`
		let data = {
			Name: planeName,
			TokenPartNumber: detailToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateDetailSchematic(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upate plot', error.response)
				return Promise.reject()
			})
	}
}
/**
 * Action to add comment
 *
 * @export
 * @param {*} item
 * @param {*} comments
 * @returns
 */
export function onAddCommentRequest(item, comments, requieredPlotArt) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			Description: comments,
			PartNumberToken: item.token,
			RequieredPlotArt: requieredPlotArt,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/project`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateComments(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding comments', error.response)
				return Promise.reject()
			})
	}
}
