/** React import section */
import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import autoBind from 'auto-bind'

/** Helpers import section */
import userManager from '../../store/helpers/UserManager';


/**
 * Callback page
 *
 * @class CallbackPage
 * @extends {React.Component}
 */
class CallbackPage extends React.Component {

    /**
     *Creates an instance of CallbackPage.
     * @param {*} props
     * @memberof CallbackPage
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }    

    /**
     * Redirect to home
     *
     * @param {*} params
     * @memberof CallbackPage
     */
    redirectToHome(params) {
        let homeUrl =  "/";         
        this.props.history.push(homeUrl);
    }
    
    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={this.redirectToHome}
                errorCallback={error => {
                    console.error(error);
                }}>
                <div>Loading...</div>
            </CallbackComponent>
        );
    }
}

/**
 * Map state to props
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state){
    return {
    }
}

/**
 * Map dispatch to props
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);
