import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';
import { DialogActions, Button, Grid, TextField } from '@material-ui/core';

import Toaster from '../../common/Toaster';
import Dropzone from '../../common/Dropzone';

/**
 * Class component of form to create sacQuality
 *
 * @class ActionsForm
 * @extends {Component}
 */
class ActionsForm extends Component {
    constructor(props) {
        super(props)
        autoBind(this);
        this.state = {
            files: [],
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
        };
    };

    /**
     * Update property in state
     *
     * @param {*} property
     * @param {*} value
     * @memberof ActionsForm
     */
    updateProperty(property, value) {
        this.setState({
            [property]: value
        })
    }
    /**
     * call to action to save data and create sacQuality
     *
     * @memberof ActionsForm
     */
    onSaveActionsSacQuality() {
        let sacToken = this.props.match.params.token;
        if (this.props.createActionsSacQuality)
            this.props.createActionsSacQuality(this.state)
                .then(response => {
                    this.props.history.push(`/sacsquality/${sacToken}`)
                }).catch(() => {
                    this.setState({
                        showToaster: true,
                        toasterMessage: <Translate id="common.errorToSave" />,
                        toasterVariant: "error",
                    })
                });
    }

    /**
     * Render textFiel Enabled to edit
     *
     * @param {*} textFieldId
     * @param {*} translationTextFieldId
     * @param {boolean} [isMultiple=false]
     * @param {string} [type='text']
     * @returns
     * @memberof ActionsForm
     */
    renderTextField(textFieldId, translationTextFieldId, isMultiple = false, type = 'text') {
        return (
            <div >
                <TextField
                    id={textFieldId}
                    fullWidth
                    margin="normal"
                    value={this.state[textFieldId] ? this.state[textFieldId] : ''}
                    label={<Translate id={translationTextFieldId} />}
                    onChange={(event) => { this.updateProperty(textFieldId, event.target.value) }}
                    className={isMultiple ? this.props.classes.textAreaField : this.props.classes.textField}
                    type={type}
                    //Only textArea
                    multiline={isMultiple}
                    rows={isMultiple ? "4" : "1"}
                    variant={isMultiple ? "outlined" : 'standard'}
                />
            </div>
        )
    }

    /**
     * Method to validate required data is filled
     *
     * @returns
     * @memberof ActionsForm
     */
    canBeSaved() {
        if ((this.state.root_cause)
            && (this.state.comments)
            && (this.state.files && this.state.files.length > 0)
        ) {
            return true;
        }
        return false;
    }


    /**
     * On change files of dropzone 
     *
     * @param {*} event
     * @memberof DetailsCard
     */
    onChangeFile(files) {
        this.setState({
            files,
        })
        return Promise.resolve('Ok');
    }

    /**
     * On remove files of dropzone 
     *
     * @param {*} event
     * @memberof DetailsCard
     */
    onDeleteFile(fileName) {
        let newFiles = this.state.files.filter(file => {
            return file.name !== fileName
        })
        this.setState({ files: newFiles })
        return Promise.resolve('Ok');
    }

    render() {
        // const { classes } = this.props;
        return (
            <Grid container spacing={24} style={{ paddingLeft: '80px', paddingRight: "100px", }}>
                <Grid item xs={12} >
                    {/* <div className={classes.textField} ><br />
                        <Typography variant="subtitle1" gutterBottom><Translate id="sacsQuality.responseDate"/></Typography>
                        <DateTimePicker
                            onChangeRange={this.onChangeRange}
                            startDate={this.state.delivery_date}
                        />
                    </div> */}
                    {this.renderTextField('root_cause', 'sacsQuality.rootCause', true)}
                    {this.renderTextField('comments', 'common.comments', true)}
                    <br />

                    <Dropzone
                        files={this.state.files}//this.props.sacQuality.attachments}
                        onChange={this.onChangeFile}
                        onDeleteFile={this.onDeleteFile}
                        baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                        disabledLink
                    />
                </Grid>
                <Grid item xs={12} >
                    <DialogActions>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            onClick={() => this.onSaveActionsSacQuality()}
                            disabled={!this.canBeSaved() || this.props.isSaving}
                        >
                            {<Translate id="common.save" />}
                        </Button>
                        <Button onClick={() => { this.props.history.push(`/sacsquality`) }} color="secondary">
                            {<Translate id="common.cancel" />}
                        </Button>
                    </DialogActions>
                </Grid>
                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Grid>
        )
    }
}


const viewStyles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textAreaField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    footerForm: {
        display: "flex",
        paddingRight: "60px",
    },
    formControl: { margin: theme.spacing.unit },
    toolbar: {},
    searchInput: {},
    button: {},
    actionsToolbar: {},
    showingUsersCounter: {},
    grow: {},
    supplierBadge: {}
});

export default withRouter(withStyles(viewStyles, { withTheme: true })(ActionsForm));