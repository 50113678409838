import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import { Translate } from 'react-localize-redux';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';
import PersonIcon from '@material-ui/icons/Person';

class EmployeeCard extends Component {

    /**
    * Render Check to set boolean enter_weekends
    *
    * @param {*} key
    * @param {*} translationId
    * @returns
    */
    renderCheck(userToken, isChecked, isDisabled) {
        return (
            <Checkbox
                onChange={(event) => { this.props.onCheck(event.target.value, event.target.checked) }}
                color="primary"
                value={userToken}
                checked={isChecked}
                disabled={isDisabled}
            />

        )
    }

    render() {
        let { classes, user } = this.props
        //User Photo
        let userPhoto = user ? user.image_employed : "";
        let urlUserPhoto = `${process.env.REACT_APP_SIP_URL_LOGOS_ADMINISTRATION}/${userPhoto}`;
        //BARCODE IMAGE
        let relativeBarCodeUrl = user ? user.bar_code_url : "";
        let urlBarCode = `${process.env.REACT_APP_SIP_URL_LOGOS_ADMINISTRATION}/${relativeBarCodeUrl}`;

        return (
            <Card className={classes.card}>
                {!userPhoto && <Typography variant="subtitle2" color="error" align="center">
                    <Translate id="contractorsModule.addPhotoToPrint" />
                </Typography>}
                <CardHeader className={classes.cardHeader}
                    avatar={
                        <Avatar alt="user"
                            src={userPhoto ? urlUserPhoto : ""}
                            className={classes.avatar}>
                            <PersonIcon />
                        </Avatar>
                    }
                    action={this.renderCheck(user.token, this.props.isChecked, !userPhoto)}
                    title={
                        <Typography variant="body1" color="textPrimary">
                            {user.name}
                        </Typography>
                    }
                    subheader={
                        <React.Fragment>
                            <Typography variant="body2" color="textSecondary" component="p">
                                <Translate id="contractorsModule.socialSecurityNumber" />: {user.insurance_number}
                            </Typography>
                            <Typography variant="body2" color="textPrimary">
                                {!IsNullOrEmpty(user.supplier_name) ? user.supplier_name : "Sin proveedor"}
                            </Typography>
                        </React.Fragment>
                    }
                />
                <CardContent>
                    <img className={classes.media} alt={"alt"} src={urlBarCode}/>
                </CardContent>
            </Card>
        )
    }
}


const styles = theme => ({
    card: {
        border: "#c6c5c5 double 3px",
        maxHeight: 285,
    },
    media: {
        height: "100px",
        width: "300px",
        marginBottom: 16,
        paddingLeft: "50px",
    },
    avatar: {
        margin: 0,
        width: 100,
        height: 100,
    },
    cardHeader:{
        minHeight: 140,
   
    }
});
export default withStyles(styles, { withTheme: true })(EmployeeCard);
