import React, { useState } from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import uuidv4 from 'uuid/v4'
import PropTypes from 'prop-types'

/** Material-UI imports section */
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import { Button, Checkbox, Chip, Paper, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import InfoIcon from '@material-ui/icons/Info'
import { COLOR_STATUS_YELLOW_LIGHT } from '../../../store/helpers/StatusColorConstants'
import Tooltip from '@material-ui/core/Tooltip'

/** Import component section */
import { getColor } from '../../../store/helpers/PlanningColorConstants'
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import { ConfirmQualityEntryItem } from '../../../store/plannigFersa/ReleasePlanningAction'
import { useDispatch } from 'react-redux'
import { canBeModulePlaneationSupplier } from '../../../store/helpers/RolesHelper'
import DialogConfirmOrders from './DialogConfirmOrders'
import { Translate } from 'react-localize-redux'

const PanelDetailItem = (props) => {
	/** Destructuring properties */
	const { classes, onClose, item, release, userRolesByCompany, partnership,setToaster } = props

	const configuration = TableReleaseTableConfiguration()
	const configurationIternalUser = TableReleaseTableConfigurationUserIternal()

	const [selectItemsId, setSelectItemsId] = useState([])
	const [saving, setSaving] = useState(false)
	const [confirmationReport, setConfirmationReport] = useState({
		open: false,
	})
	const dispatch = useDispatch()

	const handleChange = (event) => {
		if (event.target.checked === true) {
			const items = [...selectItemsId, event.target.name]
			setSelectItemsId(items)
		}
		if (event.target.checked === false) {
			const items = [...selectItemsId]
			const removeItem = items.filter((x) => x !== event.target.name)
			setSelectItemsId(removeItem)
		}
	}
	const isChecked = (status) => {
		if (selectItemsId.includes(status)) return true
		else return false
	}

	const save = () => {
		setSaving(true)
		dispatch(ConfirmQualityEntryItem([...selectItemsId], partnership.month_confirm)).catch((result) => {
            if (result.status === 400) {
                setToaster({
                    showToaster: true,
                    toasterMessage: result.data,
                    toasterVariant: "error",
                })
            }
            else {
                setToaster({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            }
        }).finally(
			() => onClose(),
			setSaving(false)
		)
	}
	const statusItem = (month) => {
		switch (month) {
			case 0:
				return <Typography>Sin confirmar</Typography>
			case 1:
				return <Typography className={classes.check}>Confirmardo</Typography>
			default:
				return ''
		}
	}
	const openConfirmation = () => {
		setConfirmationReport({
			open: true,
		})
	}

	const closeConfirmation = () => {
		setConfirmationReport({
			open: false,
		})
	}
	const monthAndTotal = (monthName, sum, month) => {
		return (
			<div className={classes.flexChip}>
				<div>
					<Chip label={monthName} style={{ backgroundColor: getColor(month) }} />
				</div>
				<div>Total:{sum}</div>
			</div>
		)
	}
	return (
		<LateralPanel onClose={onClose} classes={classes}>
			<PanelHeader
				isIconMaterial
				titlePanel={
					<Typography className={classes.titlePanel} variant='h6'>
						Confirmar entregas
					</Typography>
				}
				onCloseButtonClick={onClose}
				icon={<PlaylistAddCheckIcon className={classes.icon} />}
			/>
			<div>
				<div className={classes.divContainer}>
					{release.length !== 0 &&
						release.months.map((month) => {
							let itemMoth = item.quantities.filter((x) => x.month === month.month)
							let sum = itemMoth
								? itemMoth.map((x) => x.quantityValue).reduce((prev, curr) => prev + curr, 0)
								: 0
							if (itemMoth.length === 0) {
								return (
									<>
										{monthAndTotal(month.name, sum, month.month)}
										<div>
											<br />
											<Typography className={classes.noDeliveries} variant='h6'>
												No hay entregas
											</Typography>{' '}
										</div>
									</>
								)
							}
							return (
								<>
									{monthAndTotal(month.name, sum, month.month)}

									<Paper>
										<Table>
											{canBeModulePlaneationSupplier(userRolesByCompany) === false
												? renderTableHead(configurationIternalUser)
												: renderTableHead(configuration)}

											<TableBody>
												{itemMoth &&
													itemMoth.map((item) => {
														let valid = partnership
															? partnership.month_confirm === 0
																? moment().month() + 1 + 1
																: partnership.month_confirm + moment().month() + 1
															: 1
														let confirmMonth =
															moment(item['dateOrder']).month() + 1 <= valid
																? true
																: false
														return (
															<TableRow hover key={uuidv4()}>
																{configuration.columns.map((column) => {
																	let cellContent
																	let maxPaddingCell = 2
																	cellContent = item[column.dataSource]

																	let rendersCell = ''
																	if (column.dataSource === 'dateOrder') {
																		rendersCell = (
																			<TableCell
																				key={uuidv4()}
																				align={
																					column.align
																						? column.align
																						: 'center'
																				}
																				style={{
																					wordBreak: 'break-word',
																					paddingLeft: maxPaddingCell,
																					paddingRight: maxPaddingCell,
																					minWidth: column.minWidth,
																				}}
																			>
																				{cellContent
																					? moment(cellContent).format(
																							'YYYY/MM/DD'
																					  )
																					: ''}
																			</TableCell>
																		)
																	} else if (
																		column.dataSource === 'statusOrder' &&
																		column.header !== 'Confirmar entrega'
																	) {
																		rendersCell = (
																			<TableCell
																				key={uuidv4()}
																				align={
																					column.align
																						? column.align
																						: 'center'
																				}
																				style={{
																					wordBreak: 'break-word',
																					paddingLeft: maxPaddingCell,
																					paddingRight: maxPaddingCell,
																					minWidth: column.minWidth,
																				}}
																			>
																				{statusItem(cellContent)}
																			</TableCell>
																		)
																	} else if (
																		column.dataSource === 'statusOrder' &&
																		column.header === 'Confirmar entrega' &&
																		canBeModulePlaneationSupplier(
																			userRolesByCompany
																		) === true
																	) {
																		rendersCell = (
																			<TableCell
																				key={uuidv4()}
																				align='center'
																				style={{
																					wordBreak: 'break-word',
																					paddingLeft: maxPaddingCell,
																					paddingRight: maxPaddingCell,
																					minWidth: column.minWidth,
																				}}
																			>
																				{confirmMonth === false && (
																					<Tooltip
																						title={
																							<Translate id='releasePlanningFersa.unconfirmedMaterial' />
																						}
																					>
																						<InfoIcon
																							className={
																								classes.colorIcon
																							}
																						/>
																					</Tooltip>
																				)}
																				{confirmMonth && (
																					<Checkbox
																						name={item.id}
																						disabled={
																							canBeModulePlaneationSupplier(
																								userRolesByCompany
																							) === false
																								? true
																								: cellContent === 1
																								? true
																								: false
																						}
																						checked={
																							cellContent === 1
																								? true
																								: isChecked(item.id)
																						}
																						onChange={handleChange}
																						className={classes.check}
																					/>
																				)}
																			</TableCell>
																		)
																	} else {
																		if (column.header !== 'Confirmar entrega') {
																			rendersCell = (
																				<TableCell
																					key={uuidv4()}
																					align={
																						column.align
																							? column.align
																							: 'center'
																					}
																					style={{
																						wordBreak: 'break-word',
																						paddingLeft: maxPaddingCell,
																						paddingRight: maxPaddingCell,
																						minWidth: column.minWidth,
																					}}
																				>
																					{cellContent}
																				</TableCell>
																			)
																		}
																	}
																	return rendersCell
																})}
															</TableRow>
														)
													})}
											</TableBody>
										</Table>
									</Paper>
								</>
							)
						})}
				</div>
				<div className={classes.buttonDiv}>
					{canBeModulePlaneationSupplier(userRolesByCompany) && (
						<div>
							<Button
								variant='outlined'
								className={selectItemsId.length === 0 ? '' : classes.button}
								onClick={openConfirmation}
								disabled={item.status === 1 ? true : saving}
							>
								Confirmar
							</Button>
						</div>
					)}
				</div>
				{confirmationReport.open && (
					<DialogConfirmOrders
						{...confirmationReport}
						onClose={closeConfirmation}
						save={save}
						saving={saving}
						selectItemsId={selectItemsId}
					/>
				)}
			</div>
		</LateralPanel>
	)
}

const TableReleaseTableConfiguration = () => {
	let columns = [
		{
			header: 'Fecha',
			dataSource: 'dateOrder',
			minWidth: 60,
		},
		{
			header: 'Cantidad',
			dataSource: 'quantityValue',
			minWidth: 60,
		},
		{
			header: 'Estatus',
			dataSource: 'statusOrder',
			minWidth: 60,
		},
		{
			header: 'Confirmar entrega',
			dataSource: 'statusOrder',
			minWidth: 60,
			align: 'left',
		},
		{
			header: 'Confirmado por',
			dataSource: 'confirmBy',
			minWidth: 60,
			align: 'left',
		},
	]
	return { columns }
}

const TableReleaseTableConfigurationUserIternal = () => {
	let columns = [
		{
			header: 'Fecha',
			dataSource: 'dateOrder',
			minWidth: 60,
		},
		{
			header: 'Cantidad',
			dataSource: 'quantityValue',
			minWidth: 60,
		},

		{
			header: 'Confirmar entrega',
			dataSource: 'statusOrder',
			minWidth: 60,
			align: 'left',
		},
		{
			header: 'Confirmado por',
			dataSource: 'confirmBy',
			minWidth: 60,
			align: 'left',
		},
	]
	return { columns }
}
const renderTableHead = (configuration) => {
	return (
		<TableHead key={uuidv4()}>
			<TableRow key={uuidv4()}>
				{configuration.columns.map((column) => {
					let maxPaddingCell = 2
					return (
						<TableCell
							key={uuidv4()}
							align={column.align ? column.align : 'center'}
							style={{
								paddingLeft: maxPaddingCell,
								paddingRight: maxPaddingCell,
								minWidth: column.minWidth,

								position: 'sticky',
								top: 0,
								zIndex: '30',
							}}
						>
							{column.header}
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)
}

PanelDetailItem.propTypes = {
	onClose: PropTypes.func.isRequired,
}

const styles = (theme) => ({
	flexChip: {
		display: 'flex',
		justifyContent: 'space-between !important',
		marginLeft: '25px',
		marginRight: '25px',
		marginTop: '15px',
	},
	divContainer: {
		//maxHeight: '490px',
		overflowY: 'auto',
		marginLeft: '15px',
		marginRight: '15px',
		minHeight: `calc(100vh - ${230}px)`,
		maxHeight: `calc(100vh - ${230}px)`,
	},
	divider: {
		marginTop: '15px',
		marginBottom: '15px',
		backgroundColor: 'red',
	},
	buttonDiv: {
		marginTop: '35px',
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		color: 'red',
	},
	icon: {
		marginTop: '35px',
		backgroundColor: 'red',
		WebkitBorderRadius: '27px',
		color: '#ffffff',
		fontSize: 37,
		padding: '5px',
	},
	check: {
		color: '#28A745',
	},
	colorIcon: {
		color: COLOR_STATUS_YELLOW_LIGHT,
	},
	titlePanel: {
		color: 'rgba(233, 57, 66, 1)',
	},
	noDeliveries: {
		marginBottom: '15px',
		marginLeft: '15px',
		backgroundColor: '#f5f5f5',
		textAlign: 'center',
	},
})

export default withStyles(styles, { withTheme: true })(PanelDetailItem)
