import AvisoPrivacidadGeneral from '../../resources/files/Aviso de Privacidad.pdf'
import AvisoPrivacidadPTMES from '../../resources/files/Aviso de Privacidad PTM ES.pdf'
import AvisoPrivacidadPTMEN from '../../resources/files/Aviso de Privacidad PTM EN.pdf'
import AvisoPrivacidadMetalicosES from '../../resources/files/Aviso de Privacidad Metalicos ES.pdf'
import AvisoPrivacidadMetalicosEN from '../../resources/files/Aviso de Privacidad Metalicos EN.pdf'
import AvisoPrivacidadImberaEN from '../../resources/files/Aviso de Privacidad Imbera EN.pdf'
import AvisoPrivacidadImberaES from '../../resources/files/Aviso de Privacidad Imbera ES.pdf'
import AvisoPrivacidadRepareEN from '../../resources/files/Aviso de Privacidad Repare ES.pdf'
import AvisoPrivacidadRepareES from '../../resources/files/Aviso de Privacidad Repare EN.pdf'
import AvisoPrivacidadFersaES from '../../resources/files/Aviso de Privacidad Fersa ES.pdf'
import AvisoPrivacidadPTMT from '../../resources/files/Aviso Privacidad PTMT.pdf'

import {
	COMPANY_PTM,
	COMPANY_IDENTIFIER__METALLIC,
	COMPANY_IMBERA,
	COMPANY_IDENTIFIER_IMBERA_MX,
	COMPANY_IDENTIFIER_IMBERA_WARRANTY,
	COMPANY_IDENTIFIER_IMBERA_REPARE,
	COMPANY_IDENTIFIER_FERSA,
	COMPANY_IDENTIFIER_PTMT,
} from '../helpers/ResourcesHelper'
import { IsNullOrEmpty } from './StringHelper'

export const checkPrivacyNotice = (companyIdentifier, localStorage, activeprivacyNotice) => {
	if (activeprivacyNotice === null || activeprivacyNotice === '')
		return getNoticeOfPrivacy(companyIdentifier, localStorage)
	else
		return `${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/Documents/PrivacyNotices/${activeprivacyNotice.token}.pdf`
}

export const checkPrivacyNoticePDF = (companyIdentifier, localStorage, activeprivacyNotice) => {
	if (activeprivacyNotice === null || activeprivacyNotice === '')
		return getNoticeOfPrivacy(companyIdentifier, localStorage)
	else
		return `${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/Documents\\PrivacyNotices\\${activeprivacyNotice.token}.pdf`
}

export const getNoticeOfPrivacy = (selectedCompanyIdentifier, localStorage) => {
	switch (selectedCompanyIdentifier) {
		case COMPANY_IDENTIFIER_IMBERA_MX:
		case COMPANY_IMBERA:
		case COMPANY_IDENTIFIER_IMBERA_WARRANTY:
			return getNoticeOfPrivacyIMBERAByLanguaje(localStorage)
		case COMPANY_PTM:
			return getNoticeOfPrivacyPTMByLanguaje(localStorage)
		case COMPANY_IDENTIFIER__METALLIC:
			return getNoticeOfPrivacyMETALLICByLanguaje(localStorage)
		case COMPANY_IDENTIFIER_IMBERA_REPARE:
			return getNoticeOfPrivacyREPAREByLanguaje(localStorage)
		case COMPANY_IDENTIFIER_FERSA:
			return getNoticeOfPrivacyFERSAByLanguaje(localStorage)
		case COMPANY_IDENTIFIER_PTMT:
			return getNoticeOfPrivacyPTMTByLanguaje(localStorage)
		default:
			return AvisoPrivacidadGeneral
	}
}

export const getNoticeOfPrivacyPTMByLanguaje = (localStorage) => {
	let defaultLanguage = localStorage.getItem('language')
	if (IsNullOrEmpty(defaultLanguage)) return AvisoPrivacidadPTMES
	if (defaultLanguage === 'es') return AvisoPrivacidadPTMES
	if (defaultLanguage === 'en') return AvisoPrivacidadPTMEN
	if (defaultLanguage === 'pt') return AvisoPrivacidadPTMES
	return AvisoPrivacidadPTMES
}

export const getNoticeOfPrivacyMETALLICByLanguaje = (localStorage) => {
	let defaultLanguage = localStorage.getItem('language')
	if (IsNullOrEmpty(defaultLanguage)) return AvisoPrivacidadMetalicosES
	if (defaultLanguage === 'es') return AvisoPrivacidadMetalicosES
	if (defaultLanguage === 'en') return AvisoPrivacidadMetalicosEN
	if (defaultLanguage === 'pt') return AvisoPrivacidadMetalicosES
	return AvisoPrivacidadMetalicosES
}

export const getNoticeOfPrivacyIMBERAByLanguaje = (localStorage) => {
	let defaultLanguage = localStorage.getItem('language')
	if (IsNullOrEmpty(defaultLanguage)) return AvisoPrivacidadImberaES
	if (defaultLanguage === 'es') return AvisoPrivacidadImberaES
	if (defaultLanguage === 'en') return AvisoPrivacidadImberaEN
	if (defaultLanguage === 'pt') return AvisoPrivacidadImberaES
	return AvisoPrivacidadImberaES
}

export const getNoticeOfPrivacyREPAREByLanguaje = (localStorage) => {
	let defaultLanguage = localStorage.getItem('language')
	if (IsNullOrEmpty(defaultLanguage)) return AvisoPrivacidadRepareES
	if (defaultLanguage === 'es') return AvisoPrivacidadRepareES
	if (defaultLanguage === 'en') return AvisoPrivacidadRepareEN
	if (defaultLanguage === 'pt') return AvisoPrivacidadRepareES
	return AvisoPrivacidadRepareES
}

export const getNoticeOfPrivacyFERSAByLanguaje = (localStorage) => {
	let defaultLanguage = localStorage.getItem('language')
	if (IsNullOrEmpty(defaultLanguage)) return AvisoPrivacidadFersaES
	if (defaultLanguage === 'es') return AvisoPrivacidadFersaES
	if (defaultLanguage === 'en') return AvisoPrivacidadFersaES
	if (defaultLanguage === 'pt') return AvisoPrivacidadFersaES
	return AvisoPrivacidadFersaES
}

export const getNoticeOfPrivacyPTMTByLanguaje = (localStorage) => {
	let defaultLanguage = localStorage.getItem('language')
	if (IsNullOrEmpty(defaultLanguage)) return AvisoPrivacidadPTMT
	if (defaultLanguage === 'es') return AvisoPrivacidadPTMT
	return AvisoPrivacidadFersaES
}
