///Import react section
import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import Fab from '@material-ui/core/Fab'
import Avatar from '@material-ui/core/Avatar'
import AddIcon from '@material-ui/icons/AddTwoTone'
import Typography from '@material-ui/core/Typography'
import CardHeader from '@material-ui/core/CardHeader'
/** Import component sections */
import propTypes from 'prop-types'
import SearchInput from '../../../common/SearchInput/SearchInput'

const CatalogHeader = (props) => {
	const { setOpenDialog, icon, isInternalUse, title, isAdministrator, showSearch, onChangeSearchValue, queryParams } =
		props
	const classes = styles()
	return (
		<CardHeader
			avatar={<Avatar>{icon}</Avatar>}
			action={
				<div style={classes.constainer}>
					{showSearch && (
						<div
							style={{
								paddingRight: '8px',
							}}
						>
							<SearchInput
								onChangeSearchValue={onChangeSearchValue}
								value={!!queryParams && queryParams.query ? queryParams.query : ''}
							/>
						</div>
					)}
					{isInternalUse && isAdministrator && (
						<Fab size='small' color='primary' onClick={() => setOpenDialog(true)}>
							<AddIcon />
						</Fab>
					)}
				</div>
			}
			title={<Typography variant='button'>{title}</Typography>}
		/>
	)
}
CatalogHeader.propTypes = {
	isAdministrator: propTypes.bool,
	showSearch: propTypes.bool,
	onChangeSearchValue: propTypes.func,
	queryParams: propTypes.object,
}
CatalogHeader.defaultProps = {
	isAdministrator: true,
	showSearch: false,
	onChangeSearchValue: () => console.warn('No [onChangeSearchValue] Callback defined'),
	queryParams: {},
}
const styles = (theme) => ({
	constainer: {
		display: 'flex',
	},
	marginSearch: {
		marginRight: '5px',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(CatalogHeader))
