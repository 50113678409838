/**Import react section */
import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
/**Import material UI Section*/
import { withStyles, Typography, IconButton, Tooltip } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/Add'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import VisibilityIcon from '@material-ui/icons/Visibility'
/**Import components section */
import DialogOperationContainer from '../../../common/DialogOperations/DialogOperationContainer'
import EvaluatorsHeaderTable from './EvaluatorsHeaderTable'
import ItemsContentTable from './ItemsContentTable'
/**import helper section */
import {
	RELEASE_PLAN_COLOR_YELLOW,
	COLOR_STATUS_GRAY,
	COLOR_STATUS_DANGER,
} from '../../../../store/helpers/StatusColorConstants'
import {
	onGetItemsTableIndirectQualification,
	descriptionSupplierType,
	GetColorEvaluation,
	GetDescriptionEvaluation,
} from '../../../../store/helpers/IndirectQualificationsHelpers'
import { onGetHeaderTableIndirectQualificationItemTypeType } from '../../../../store/helpers/IndirectQualificationsHelpers'
import { useIndirectQualificationCustomHookShow } from '../../customHooks/UseCustomHookShow'
import {
	IndirectQualificationsStatus,
	IndirectQualificationsSupplierStatus,
	Evaluationclassification,
} from '../../../../store/helpers/AppConstants'
import { GENERATE_ACTIONS_PLAN } from '../../../../store/indirectqualifications/IndirectqualificationsActions'
import { IsCurrentNotActiveDirectoryUser } from '../../../../store/helpers/SessionHelper'
const QualificationContentTable = (props) => {
	/**String props section */
	/**Bools props section */
	//const { isSaving } = props
	/**Arrays props section */
	//const { } = props
	/**Functions props section */
	const { onConfirmPassword, onRedirectToActionPlant, onLateralPanelConfig } = props
	/**Objects props section */
	const { indirectQualification, classes, translate } = props
	const { user } = useIndirectQualificationCustomHookShow()

	const [item, setItem] = useState(null)
	const [identifierExpanded, setIdentifierExpanded] = useState([])

	let isUserCreator = indirectQualification && user && user.profile.username === indirectQualification.CreatedBy
	let availableOperationsInStatusPublishRatings = []

	const onAddActionsPlan = (itemUpdate) => {
		setItem(itemUpdate)
		onOpenAndClose()
	}

	if (
		indirectQualification &&
		(indirectQualification.Status === IndirectQualificationsStatus.GENERATE_RATING ||
			indirectQualification.Status === IndirectQualificationsStatus.PUBLISH_RATINGS) &&
		isUserCreator
	) {
		availableOperationsInStatusPublishRatings.push({
			title: <Translate id='indirectqualifications.operationsAvailable.messageActionPlanTitle' />,
			onClick: () => onConfirmPassword(item, GENERATE_ACTIONS_PLAN),
			isIconMaterial: true,
			description: <Translate id='indirectqualifications.operationsAvailable.messageActionPlan' />,
		})
	}

	const onGetOperationsAvailable = () => {
		switch (indirectQualification ? indirectQualification.Status : 0) {
			case IndirectQualificationsStatus.PUBLISH_RATINGS:
			case IndirectQualificationsStatus.GENERATE_RATING:
				return availableOperationsInStatusPublishRatings
			default:
				return []
		}
	}

	const { DialogOperations, onOpenAndClose } = DialogOperationContainer({
		options: onGetOperationsAvailable(),
	})

	const CanBeAddActionPlant = (description) => {
		switch (description) {
			case Evaluationclassification.RELIABILITY_WHITH_OPPORTUNITY:
			case Evaluationclassification.RELIABILITY_SUPPLIER:
				return false
			case Evaluationclassification.REGURAR_WHITH_OPPORTUNITY:
			case Evaluationclassification.UNREALIABLE_SUPPLIER:
				return true
			default:
				return false
		}
	}

	let isVisibleColumnsAditionals = indirectQualification.Status >= IndirectQualificationsStatus.GENERATE_RATING

	const handleExpandClick = (identifier, isExpanded) => {
		if (isExpanded === true) {
			setIdentifierExpanded(identifierExpanded.concat(identifier))
		} else {
			let newListIdentifier = identifierExpanded.filter((x) => x !== identifier)
			setIdentifierExpanded(newListIdentifier)
		}
	}

	return (
		<>
			{onGetItemsTableIndirectQualification(indirectQualification, translate).map((supplier) => {
				let isResponsibleAssigned = user && supplier.ResponsibleUserName === user.profile.username
				let isSupplierAssigned = IsCurrentNotActiveDirectoryUser(user)
				return (
					<Fragment>
						<TableRow>
							<TableCell
								style={{
									backgroundColor: supplier.ColorStatustable,
									wordBreak: 'break-word',
									paddingLeft: 3,
									paddingRight: 3,
								}}
							/>
							<TableCell align='center' className={classes.cellTable}>
								<IconButton
									size='small'
									onClick={
										identifierExpanded.includes(supplier.Id)
											? () => handleExpandClick(supplier.Id, false)
											: () => handleExpandClick(supplier.Id, true)
									}
									aria-label='Show more'
								>
									{identifierExpanded.includes(supplier.Id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
								</IconButton>
							</TableCell>
							<TableCell align='left' className={classes.cellTable}>
								{supplier ? supplier.StatusDescription : ''}
							</TableCell>
							<TableCell className={classes.cellTable}>
								<Typography
									className={classes.nameSupplier}
									variant='subtitle2'
								>{`${supplier.Code} | ${supplier.Name}`}</Typography>
							</TableCell>
							<TableCell align='justify' className={classes.cellTable}>
								<Typography variant='subtitle2'>
									{descriptionSupplierType(supplier.SupplierType, translate)}
								</Typography>
							</TableCell>
							<TableCell align='justify' className={classes.cellTable}>
								<Typography className={classes.nameSupplier} variant='subtitle2'>
									{supplier.Concept}
								</Typography>
							</TableCell>
							<ItemsContentTable
								items={supplier.Items}
								isResponsibleAssigned={isResponsibleAssigned}
								indirectQualification={indirectQualification}
								supplier={supplier}
								isVisibleComment={false}
							/>

							{isVisibleColumnsAditionals && (
								<TableCell align='center'>
									<Typography
										variant='subtitle2'
										style={{ color: GetColorEvaluation(supplier.DescriptionEvaluation) }}
									>
										{supplier.TotalRating}
									</Typography>
								</TableCell>
							)}
							{isVisibleColumnsAditionals && (
								<TableCell align='center'>
									<Typography
										variant='subtitle2'
										style={{ color: GetColorEvaluation(supplier.DescriptionEvaluation) }}
									>
										{GetDescriptionEvaluation(supplier.DescriptionEvaluation, translate)}
									</Typography>
								</TableCell>
							)}
							{isVisibleColumnsAditionals &&
								isUserCreator &&
								supplier.Status === IndirectQualificationsSupplierStatus.PUBLISH_RATING &&
								CanBeAddActionPlant(supplier.DescriptionEvaluation) && (
									<TableCell align='center'>
										<Tooltip title={<Translate id='indirectqualifications.show.addActionPlant' />}>
											<IconButton onClick={() => onAddActionsPlan(supplier)}>
												<AddIcon color='primary' />
											</IconButton>
										</Tooltip>
									</TableCell>
								)}
							{isVisibleColumnsAditionals &&
								isUserCreator &&
								(supplier.Status >= IndirectQualificationsSupplierStatus.GENERATE_ACTION_PLANS ||
									supplier.Status === IndirectQualificationsSupplierStatus.CANCEL) &&
								CanBeAddActionPlant(supplier.DescriptionEvaluation) && (
									<TableCell align='center'>
										{' '}
										<Tooltip title={<Translate id='indirectqualifications.show.seeActionPlant' />}>
											<IconButton onClick={() => onRedirectToActionPlant(supplier)}>
												<VisibilityIcon color='primary' />
											</IconButton>
										</Tooltip>
									</TableCell>
								)}

							{isVisibleColumnsAditionals &&
								isSupplierAssigned &&
								(supplier.Status >=
									IndirectQualificationsSupplierStatus.SEND_ACTION_PLANT_TO_SUPPLIER ||
									supplier.Status === IndirectQualificationsSupplierStatus.CANCEL) && (
									<TableCell align='center'>
										{' '}
										<IconButton onClick={() => onRedirectToActionPlant(supplier)}>
											{' '}
											<VisibilityIcon color='primary' />{' '}
										</IconButton>
									</TableCell>
								)}
						</TableRow>
						{identifierExpanded.includes(supplier.Id) && (
							<TableRow>
								<TableCell
									padding='none'
									colSpan={
										onGetHeaderTableIndirectQualificationItemTypeType(
											indirectQualification,
											isSupplierAssigned
										).length
									}
								>
									<EvaluatorsHeaderTable
										evaluators={supplier.Evaluators}
										supplier={supplier}
										indirectQualification={indirectQualification}
										onLateralPanelConfig={onLateralPanelConfig}
									/>
								</TableCell>
							</TableRow>
						)}
					</Fragment>
				)
			})}
			<DialogOperations />
		</>
	)
}

const styles = (theme) => ({
	containsCommnets: {
		color: RELEASE_PLAN_COLOR_YELLOW,
	},
	emptyComments: {
		color: COLOR_STATUS_GRAY,
	},
	commentsIconDanger: {
		color: COLOR_STATUS_DANGER,
	},
	nameSupplier: {
		width: '200px',
	},
	responsibleName: {
		width: '250px',
	},
	inputTextfield: {
		height: 6,
		width: 8,
	},
	total: {
		color: 'white',
	},
	cellTable: {
		paddingRight: 2,
	},
})

QualificationContentTable.propTypes = {
	/**
	 * Determinate if is loading information from back end
	 * and see view skeleton
	 */
	isLoading: PropTypes.bool.isRequired,
}

QualificationContentTable.defaultProps = {
	isLoading: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(QualificationContentTable))
