import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import moment from 'moment'

/** Import component section */
import LoadingPage from '../../common/LoadingPage'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import DateTimePicker from '../../common/DateTimePicker/DatePicker'

import DataTable from '../../common/DataTable'
import Select from 'react-select'

/** Material Ui imports section */
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import InvoicesIcon from '@material-ui/icons/ListAltTwoTone'
import { Paper, Grid } from '@material-ui/core'

import { getInvoicesFromSAP, loadSupplier, donwlodInvoicesFromSAP } from '../../../store/suppliers/SupplierActions'
import { COMPANY_IMBERA, COMPANY_METALICOS, COMPANY_IMBERA_WARRANTY } from '../../../store/helpers/ResourcesHelper'

/**
 * Supplier list
 *
 * @class Suppliers list
 * @extends {Component}
 */
class Invoices extends Component {
	/**
	 * Create an instance of suppliers list
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			startDate: moment(),
			endDate: moment(),
			sortCriteria: {
				by: 'status',
				ascending: false,
			},
			isSearching: false,
			dateType: 0,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let supplierToken = this.props.match.params.token
		this.props.loadSupplier(supplierToken)
	}

	/**
	 * On change range
	 *
	 * @param {*} startDate
	 * @param {*} endDate
	 * @memberof Invoices
	 */
	onChangeRange(startDate, endDate) {
		this.setState({
			startDate,
			endDate,
		})
	}

	onChangeRangeStart(startDate) {
		this.setState({
			startDate,
		})
	}

	onChangeRangeEnd(endDate) {
		this.setState({
			endDate,
		})
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		let sortCriteria = {
			by: sortBy,
			ascending: sortAscending,
		}
		this.setState({
			sortCriteria,
		})
	}

	/**
	 * Action to change invoice type
	 * @param {*} typeInvoice
	 */
	onChangeDateType(option) {
		this.setState({
			dateType: option.value,
		})
	}

	/**
	 * On search invoices click
	 *
	 * @memberof Invoices
	 */
	onSearchClick() {
		if (this.props.supplier) {
			let supplierCode = this.props.supplier.code
			let startDate = this.state.startDate.format('DD/MM/YYYY')
			let endDate = this.state.endDate.format('DD/MM/YYYY')
			this.props.getInvoices(supplierCode, startDate, endDate, this.state.dateType)
		}
	}

	/**
	 * On donwload click
	 */
	onDonwlodClick() {
		if (this.props.supplier) {
			let supplierCode = this.props.supplier.code
			let startDate = this.state.startDate.format('DD/MM/YYYY')
			let endDate = this.state.endDate.format('DD/MM/YYYY')
			this.props.donwlodInvoices(supplierCode, startDate, endDate, this.state.dateType)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'amount') {
			return `${item.amount} ${item.currency}`
		}
		if (dataSource === 'status') {
			if (item.status) {
				return <Translate id='queries.invoices.paid' />
			} else return <Translate id='queries.invoices.isPaid' />
		}
		if (dataSource === 'method_payment') {
			if (item.method_payment === 'Transferencia') {
				return <Translate id='queries.invoices.transfer' />
			} else return <Translate id='queries.invoices.factoring' />
		}
		if (dataSource === 'compensation_date') {
			if (item.compensation_date === '01/01/0001') return '-'
			else return item.compensation_date
		}
		if (dataSource === 'text') {
			let isCompany =
				this.props.selectedCompany.company_code === COMPANY_IMBERA ||
				this.props.selectedCompany.company_code === COMPANY_METALICOS
			if (isCompany) {
				if (item.assignment === 'FINANCIADO') return item.text
				else return '-'
			} else return item.text
		}
	}

	/**
	 * Get card content
	 *
	 * @returns
	 * @memberof Invoices
	 */
	getCardContent() {
		const { sortCriteria } = this.state
		let isCompanyImb =
			this.props.selectedCompany.company_code === COMPANY_IMBERA ||
			this.props.selectedCompany.company_code === COMPANY_METALICOS ||
			this.props.selectedCompany.company_code === COMPANY_IMBERA_WARRANTY

		if (this.props.invoices) {
			return (
				<Paper
					style={{
						maxWidth: '79vw',
						minHeight: 100,
						maxHeight: '62vh',
					}}
				>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							{isCompanyImb && (
								<div className='alert alert-warning' role='alert'>
									<p>
										<strong>
											<Translate id='queries.invoices.noteInvoice' />:
										</strong>
										{this.props.selectedCompany.company_code === COMPANY_METALICOS && (
											<Translate id='queries.invoices.messageInvoiceMt' />
										)}
										{this.props.selectedCompany.company_code === COMPANY_IMBERA && (
											<Translate id='queries.invoices.messageInvoiceImb' />
										)}
										{this.props.selectedCompany.company_code !== COMPANY_METALICOS &&
											this.props.selectedCompany.company_code !== COMPANY_IMBERA && (
												<Translate id='queries.invoices.messageInvoice' />
											)}
									</p>
								</div>
							)}
							<Paper
								style={{
									minHeight: 100,
									maxHeight: '59vh',
									overflow: 'auto',
								}}
							>
								<DataTable
									data={this.props.invoices}
									configuration={
										isCompanyImb
											? TableConfigurationIMB(this.props.selectedCompany.company_code)
											: TableConfiguration
									}
									onRenderCellItem={this.onRenderCellItem}
									mode={'clientSide'}
									sortBy={sortCriteria.by}
									sortAscending={sortCriteria.ascending}
									onChangeSortCriteria={this.onChangeSortCriteria}
									showFilters
									widthFilters={100}
								/>
							</Paper>
						</Grid>
					</Grid>
				</Paper>
			)
		} else if (this.props.isLoadingInvoices) {
			return <LoadingPage />
		} else {
			return (
				<div className='alert alert-info' role='alert'>
					<h6 className='alert-heading'>
						<i className='fab fa-searchengin'></i>
						<Translate id='queries.invoices.searchInvoicesTitle' />
					</h6>
					<p>
						<Translate id='queries.invoices.searchInvoicesMessage' />{' '}
					</p>
				</div>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof Supplier
	 */
	render() {
		const { classes } = this.props
		return (
			<Card className={classes.card}>
				<CardHeader
					avatar={
						<Avatar color='primary'>
							<InvoicesIcon />
						</Avatar>
					}
					title={
						<span>
							<Translate id='menu.consults' /> | <Translate id='menu.invoice' />
						</span>
					}
					subheader={
						<span>
							{this.props.supplier ? this.props.supplier.full_name : ''}
							<div className={classes.toolbar}>
								<DocumentTypeSelect
									{...this.props}
									dateType={this.state.dateType}
									onChangeDateType={this.onChangeDateType}
								/>
								<DateTimePicker
									onChangeRange={this.onChangeRangeStart}
									startDate={this.state.startDate}
								/>

								<DateTimePicker onChangeRange={this.onChangeRangeEnd} startDate={this.state.endDate} />
								<Button
									variant='contained'
									color='default'
									disabled={this.props.isLoadingInvoices}
									style={{ marginLeft: '3px', fontSize: 'smaller' }}
									onClick={this.onSearchClick}
								>
									<Translate id='common.search' />
								</Button>
								<Button
									variant='contained'
									color='default'
									disabled={this.props.isDownloadQueries}
									style={{ marginLeft: '3px', fontSize: 'smaller' }}
									onClick={this.onDonwlodClick}
								>
									{' '}
									<Translate id='common.download' />
								</Button>
							</div>
						</span>
					}
				/>
				<CardContent>{this.getCardContent()}</CardContent>
			</Card>
		)
	}
}

/**
 * Get the document selection
 *
 * @class DocumentTypeSelect
 * @extends {Component}
 */
class DocumentTypeSelect extends Component {
	/**
	 * Get the select options
	 *
	 * @returns
	 * @memberof DocumentTypeSelect
	 */
	getOptions() {
		return [
			{ value: '0', label: 'Por pagar' },
			{ value: '1', label: 'Pagada' },
		]
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof DocumentTypeSelect
	 */
	render() {
		const { classes } = this.props
		let options = this.getOptions()
		return (
			<Select
				value={options[this.props.dateType]}
				className={classes.documentTypeSelect}
				options={options}
				onChange={this.props.onChangeDateType}
				styles={{
					menu: (base) => ({ ...base, zIndex: 100 }),
				}}
			/>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='queries.invoices.status' />,
			dataSource: 'status',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.invoice' />,
			dataSource: 'invoice_number',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.invoiceDate' />,
			dataSource: 'invoice_date',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.paymentDate' />,
			dataSource: 'term_date',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.ammount' />,
			dataSource: 'amount',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.methodPayment' />,
			dataSource: 'method_payment',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.assignment' />,
			dataSource: 'assignment',
		},
		{
			header: <Translate id='queries.invoices.text' />,
			dataSource: 'text',
		},
		{
			header: <Translate id='queries.invoices.entry' />,
			dataSource: 'document_compensation',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.compensationDate' />,
			dataSource: 'compensation_date',
		},
		{
			header: <Translate id='queries.invoices.documentCompensation' />,
			dataSource: 'document_imbera',
		},
	],
}

function TableConfigurationIMB(companyCode) {
	let columns = [
		{
			header: <Translate id='queries.invoices.status' />,
			dataSource: 'status',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.invoice' />,
			dataSource: 'invoice_number',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.invoiceDate' />,
			dataSource: 'invoice_date',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.contableDate' />,
			dataSource: 'contable_date',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.termDate' />,
			dataSource: 'term_date',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.ammount' />,
			dataSource: 'amount',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.methodPayment' />,
			dataSource: 'method_payment',
			isSortable: true,
		},
		{
			header: <Translate id='queries.invoices.assignment' />,
			dataSource: 'assignment',
		},
	]
	if (companyCode === COMPANY_IMBERA || companyCode === COMPANY_METALICOS) {
		columns.push({
			header: <Translate id='queries.invoices.text' />,
			dataSource: 'text',
		})
	} else {
		columns.push({
			header: <Translate id='queries.invoices.entry' />,
			dataSource: 'document_compensation',
			isSortable: true,
		})
	}
	columns.push({
		header: <Translate id='queries.invoices.paymentDate' />,
		dataSource: 'compensation_date',
	})
	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		isLoadingInvoices: state.suppliers.get('isLoadingInvoices'),
		invoices: state.suppliers.get('invoices') ? state.suppliers.get('invoices').toJS() : null,
		isDownloadQueries: state.suppliers.get('isDownloadQueries'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSupplier: (token) => {
			dispatch(loadSupplier(token))
		},
		getInvoices: (supplierCode, startDate, endDate, dateType) => {
			dispatch(getInvoicesFromSAP(supplierCode, startDate, endDate, dateType))
		},
		donwlodInvoices: (supplierCode, startDate, endDate, dateType) => {
			dispatch(donwlodInvoicesFromSAP(supplierCode, startDate, endDate, dateType))
		},
	}
}

const styles = (theme) => ({
	toolbar: {
		display: 'flex',
		flexGrow: 1,
		width: '750px',
		margin: theme.spacing.unit,
	},
	documentTypeSelect: {
		width: '180px',
		marginRight: '5px',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Invoices)))
)
