import { fromJS } from 'immutable'
import * as releasePlanningAction from './ReleasePlanningAction'

/**
 * releasePlan InitialState initial state
 */
export const releasePlanningInitialState = fromJS({
	isLoadingReleasePlanning: true,
	releasePlanning: null,
	isLoadingMaterials: false,
	materialsRelease: [],
	isSaving: false,
	isUpdatingReleasePlan: false,
})

export default function releasePlanReducer(state = releasePlanningInitialState, action) {
	switch (action.type) {
		case releasePlanningAction.START_LOADING_RELEASE_PLANIING: {
			return state.merge({
				isLoadingReleasePlanning: action.isLoading,
			})
		}
		case releasePlanningAction.IS_SAVING_RELEASE_PLANIING: {
			return state.merge({
				isSaving: action.isSaving,
			})
		}
		case releasePlanningAction.SET_RELEASE_PLANIING: {
			return state.merge({
				isLoadingReleasePlan: false,
				releasePlanning:  action.releasePlan,
				materialsRelease: action.releasePlan.items,
			})
			//return state
		}
		case releasePlanningAction.UPDATE_RELEASE: {
			return state
				.updateIn(['releasePlanning', 'statusOrder'], action.releasePlan.statusOrder)
		}
		default:
			return state
	}
}
