import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import SearchInput from '../../common/SearchInput/SearchInput'
import AddMedicalCheckDialog from './components/AddMedicalCheckDialog'

/** Material UI imports section */
import { withStyles } from '@material-ui/core/styles'
import { Card, CardHeader, Avatar, Fab, Typography, CardContent, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'
import MedicalChecksIcon from '@material-ui/icons/SpellcheckOutlined'

/** Actions and helpers imports section */
import LoadingPage from '../../common/LoadingPage'
import DataTable from '../../common/DataTable'
import {
	CHANGE_MEDICAL_CHECKS_SORT_CRITERIA,
	CHANGE_MEDICAL_CHECKS_QUERY,
	CHANGE_MEDICAL_CHECKS_PAGINATION,
	getMedicalChecksCount,
	loadMedicalChecksFromBackEnd,
	addMedicalCheck,
} from '../../../store/contractors/MedicalCheckActions'
import {
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
	BACKGROUND_COLOR_GRAY,
	COLOR_STATUS_WARNING,
} from '../../../store/helpers/StatusColorConstants'
import Toaster from '../../common/Toaster'
import { converListToArray } from '../../../store/helpers/ListHelper'
import { IsCurrentNotActiveDirectoryUser, IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import { showErrorToaster } from '../../../store/helpers/ToasterHelpers'

/**
 * Class container to show services / projects index
 *
 * @class Index
 * @extends {Component}
 */
class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAddCheckModal: false,
			itemToken: null,
			showMenu: true,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadMedicalChecks(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getMedicalChecksCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadMedicalChecks()
			this.props.getMedicalChecksCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadMedicalChecks(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPag
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * HAndle state to show or hide add medical check modal
	 *
	 * @memberof Index
	 */
	handleShowAddMedicalCheckDialog() {
		this.setState({
			openAddCheckModal: !this.state.openAddCheckModal,
		})
	}

	/**
	 * Handle event to call action and add medical check
	 *
	 * @param {*} medicalCheck
	 * @returns
	 * @memberof Index
	 */
	handleOnAddMedicalCheck(medicalCheck) {
		return this.props
			.addMedicalCheck(medicalCheck)
			.then((tokenMedical) => {
				console.log('tokenMedical', tokenMedical)
				this.props.history.push(`/medicalchecks/${tokenMedical}`)
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToAdd'))
				return Promise.reject()
			})
	}

	/**
	 * function to determine status description and color to show chip in header
	 *
	 * @param {*} status
	 * @returns
	 * @memberof Show
	 */
	determineStatus(status) {
		let statusDescription
		let statusColor
		switch (status) {
			case 1: //addiedCheck
				statusDescription = 'contractorsModule.status.addiedCheck'
				statusColor = COLOR_STATUS_WARNING
				break
			case 2: //closedCheck
				statusDescription = 'contractorsModule.status.closedCheck'
				statusColor = COLOR_STATUS_SUCCESS
				break
			case 3: //validateCheck
				statusDescription = 'contractorsModule.status.validateCheck'
				statusColor = COLOR_STATUS_SUCCESS
				break
			case 4: //rejectedCheck
				statusDescription = 'contractorsModule.status.rejectedCheck'
				statusColor = COLOR_STATUS_DANGER
				break
			default:
				statusDescription = 'contractorsModule.status.closedCheck'
				statusColor = BACKGROUND_COLOR_GRAY
				break
		}
		return { label: <Translate id={statusDescription} />, color: statusColor }
	}

	/**
	 * onRenderCellItem
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		if (dataSource === 'is_active') {
			let status = this.determineStatus(item.status)
			return (
				<div className={classes.titleItem}>
					<MedicalChecksIcon style={{ color: status.color }} />
					{status.label}
				</div>
			)
		}
		if (dataSource === 'description') {
			return <div style={{ width: '220px' }}>{item.description}</div>
		}
		if (dataSource === 'supplier_name') {
			return <div style={{ width: '250px' }}>{item.supplier_name} </div>
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingChecks === true) {
			return <LoadingPage />
		} else if (this.props.medicalchecks === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.medicalchecks}
					configuration={TableConfiguration(IsCurrentActiveDirectoryUser(this.props.user))}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(medicalcheck) => {
						this.props.history.push(`/medicalchecks/${medicalcheck.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.medicalchecksCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get material deliverie's counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		if (this.props.medicalchecks) {
			let totalmedicalchecks = this.props.medicalchecks.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalmedicalchecks}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.medicalchecksCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<MedicalChecksIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>
							{IsCurrentNotActiveDirectoryUser(this.props.user) && (
								<Tooltip title={<Translate id='contractorsModule.medicalchecks.addMedicalCheck' />}>
									<Fab
										size='small'
										color='primary'
										onClick={() => {
											this.setState({ openAddCheckModal: true })
										}}
									>
										<AddIcon />
									</Fab>
								</Tooltip>
							)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='contractorsModule.medicalchecks.medicalchecksTitle' />
						</Typography>
					}
					subheader={this.getCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>

				<AddMedicalCheckDialog
					open={this.state.openAddCheckModal}
					isSaving={this.props.isSavingChecks}
					addMedicalCheck={this.handleOnAddMedicalCheck}
					onClose={this.handleShowAddMedicalCheckDialog}
					dropzoneBaseText={this.props.translate('dropzone.dropzoneBase')}
				/>
			</Card>
		)
	}
}

function TableConfiguration(isUserInternal) {
	let columns = [
		{
			header: <Translate id='common.status' />,
			dataSource: 'is_active',
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
		},
		{
			header: <Translate id='contractorsModule.startDate' />,
			dataSource: 'start_date',
		},
		{
			header: <Translate id='contractorsModule.endDate' />,
			dataSource: 'end_date',
		},
		{
			header: <Translate id='contractorsModule.medicalchecks.fileName' />,
			dataSource: 'description',
		},
		{
			header: <Translate id='contractorsModule.employeedName' />,
			dataSource: 'employed_name',
		},
	]

	if (isUserInternal) {
		columns.push({
			header: <Translate id='common.supplier' />,
			dataSource: 'supplier_name',
		})
	}

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingChecks: state.contractorMedicalcheck.get('isLoadingChecks'),
		isSavingChecks: state.contractorMedicalcheck.get('isSavingChecks'),
		medicalchecks: state.contractorMedicalcheck.get('medicalchecks')
			? state.contractorMedicalcheck.get('medicalchecks').toJS()
			: null,
		medicalchecksCount: state.contractorMedicalcheck.get('medicalchecksCount'),
		sortCriteria: state.contractorMedicalcheck.get('sortCriteria')
			? state.contractorMedicalcheck.get('sortCriteria').toJS()
			: null,
		searchQuery: state.contractorMedicalcheck.get('searchQuery'),
		page: state.contractorMedicalcheck.get('page'),
		rowsPerPage: state.contractorMedicalcheck.get('rowsPerPage'),
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		employees: state.contractorEmployees.get('employees') ? state.contractorEmployees.get('employees').toJS() : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadMedicalChecks: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadMedicalChecksFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		getMedicalChecksCount: () => {
			dispatch(getMedicalChecksCount())
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_MEDICAL_CHECKS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_MEDICAL_CHECKS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_MEDICAL_CHECKS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		addMedicalCheck: (medical) => {
			return dispatch(addMedicalCheck(medical))
		},
	}
}

const styles = (theme) => ({
	active: {
		color: '#28A745',
		margin: '5px',
	},
	inactive: {
		color: '#DC3545',
		margin: '5px',
	},
	titleItem: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 0,
		width: '110px',
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
