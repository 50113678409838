
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import autoBind from 'auto-bind'

/** Material-UI imports section */
import {Translate} from "react-localize-redux";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Toaster from '../../common/Toaster';
import { addMaterialToReleasePlan } from '../../../store/plannigFersa/ReleasePlanningAction';


/**
 * Component to add item in release plan supplier
 */
class AddItemInRelease extends Component {

    /**
     * Creates an instance of View.
     * @param {*} props
     * @memberof View
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            material: {
                part_number_code: '',
                purchase_order: '',
                sol_ped_number: '',
                position: 0,
            },
        }
    }

    /**
     * Update the property
     *
     * @param {*} user
     * @param {*} value
     * @memberof View
     */
    updateProperty(property, value) {
        let updatedMaterial = {...this.state.material};
        updatedMaterial[property] = value;
        this.setState({
            material: updatedMaterial
        })
    }

      /**
     * Can be add mateiral in release
     */
    canBeSaved() {
        let material = this.state.material;
        if (material.part_number_code === "" || material.purchase_order === "" ||
        material.position === "")
            return false;
        return true;
    }

    /**
     * Method to add mateiral
     */
    canBeAddMaterial() {
        this.props.addMaterial(this.state.material)
        .then(() => {
           this.props.updateRelease()
            this.setState({
                showToaster: true,
                toasterVariant: "success",
                toasterMessage: <Translate id="common.saveCorrectly" />,
            })
        }).catch((result) => {
            if (result.status === 400) {
                this.setState({
                    showToaster: true,
                    toasterMessage: result.data.Message,
                    toasterVariant: "error",
                })
            }
            else {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            }
        });
    }

    /**
     * Render add mateiral in release
     */
    render(){
        const { classes } = this.props;
        return (
            <div>
                <Grid>
                    <div className={classes.margin}>
                    <br/>
                    <Typography variant="subtitle2">
                        <strong>{<Translate id="releasePlan.addMaterialNotExist"/>} </strong>
                    </Typography>
                    </div>
                </Grid>
                <Grid item xs={4}>

                    <Grid item xs={12}>
                        <TextField
                            id="standard-read-only-input"
                            label={<span><Translate id="releasePlan.partNumberCode" /> </span>}
                            value={this.state.material.part_number_code}
                            onChange={(event) => { this.updateProperty("part_number_code", event.target.value) }}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="standard-read-only-input"
                            label={<span><Translate id="releasePlan.orderRequest" /> </span>}
                            value={this.state.material.sol_ped_number}
                            onChange={(event) => { this.updateProperty("sol_ped_number", event.target.value) }}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="standard-read-only-input"
                            label={<span><Translate id="releasePlan.purchaseOrder" /> </span>}
                            value={this.state.material.purchase_order}
                            onChange={(event) => { this.updateProperty("purchase_order", event.target.value) }}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="standard-read-only-input"
                            label={<span><Translate id="releasePlan.position" /> </span>}
                            value={this.state.material.position}
                            onChange={(event) => { this.updateProperty("position", event.target.value) }}
                            type="number"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid container >
                        <DialogActions>
                            <Button 
                                data-cy="btn-save"
                                type="button" 
                                color="primary" 
                                onClick={this.canBeAddMaterial}
                                disabled = {this.props.isSaving || !this.canBeSaved()}
                                >
                                {<Translate id="releasePlan.addMaterial"/>}
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid> 

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </div>
        )
    }

}

/**
 * Map state to props mapStateToProps
 * @param {*} state 
 */
function  mapStateToProps(state){
    return {
        isSaving: state.releasePlanning.get("isSaving"),
     }
}

/**
 * mapDispatchToProps
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return{
        addMaterial:(material) =>{
            return dispatch(addMaterialToReleasePlan(material));
         }
    }
}

const viewStyles = theme => ({
    margin: {
        margin: theme.spacing.unit,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },

});

export default withRouter(withStyles(viewStyles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(AddItemInRelease)));
