import React from 'react'
import propTypes from 'prop-types'
import { Dialog, DialogContentText, Paper, Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { DialogContent } from '@material-ui/core'
import { Divider } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { Translate } from 'react-localize-redux'

const RequierementClientDialog = (props) => {
	const { open, onClose, client, classes } = props
	return (
		<Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
			<DialogTitle id='form-dialog-title'>
				<DialogContentText>
					<div className={classes.titleContent}>
						<Typography variant='subtitle'>
							<Translate id={'carrierTenders.warningRequirement.title'} /> : {client.Name}
						</Typography>
					</div>
				</DialogContentText>
			</DialogTitle>
			<Divider />
			<DialogContent>
				<Typography variant='body2'>
					<Translate id={'carrierTenders.warningRequirement.subTitle'} />:
				</Typography>
				<div className={classes.listRequirement}>
					{client.CustomerRequirements.map((requirement) => {
						return (
							<Paper className={classes.paperContent}>
								<Typography variant='caption'>{requirement.Description}</Typography>
							</Paper>
						)
					})}
				</div>
			</DialogContent>
		</Dialog>
	)
}
RequierementClientDialog.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
	client: propTypes.any,
}
RequierementClientDialog.defaultProps = {
	open: false,
	onClose: console.warn('No [onClose] CallBack defined'),
	client: {
		Name: '',
		CustomerRequirements: [],
	},
}
const styles = (theme) => ({
	titleContent: {
		display: 'flex',
	},
	title: {
		color: theme.palette.background.default,
	},
	listRequirement: {
		maxHeight: '150px',
		overflowX: 'hidden',
		overflowY: 'auto',
	},
	paperContent: {
		borderColor: theme.palette.primary.light,
		border: 'solid 2px',
		margin: '8px 0px',
		width: '300px',
		padding: '8px',
	},
})
export default withRouter(withStyles(styles, { withTheme: true })(RequierementClientDialog))
