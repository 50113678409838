import React from 'react';
import PropTypes from 'prop-types';

/**Import components section*/
import { Text, View, StyleSheet } from '@react-pdf/renderer';

/** Language imports **/
import { Translate } from "react-localize-redux";
import Root from '../../../../store/Root';

/**Import components section*/

/**Import resources section*/
import { COLOR_STATUS_DANGER, COLOR_STATUS_SUCCESS, COLOR_STATUS_PROCESS,
        BACKGROUND_COLOR_GRAY, COLOR_STATUS_GRAY } from '../../../../store/helpers/StatusColorConstants';

const ActionOpportunity = props => {
    const { actionsOppornunity } = props   

    /**
    * Detemine Title answer
    */
    const statusTitle = (status) => {
        if (status === 1)
        return <Text>{<Translate id="sustainability.status.pendient"/>}</Text>
        if (status === 2)
        return <Text>{<Translate id="sustainability.status.open"/>}</Text>
        if (status === 3)
        return <Text>{<Translate id="sustainability.status.process"/>}</Text>
        if (status === 4)
        return <Text>{<Translate id="sustainability.status.close"/>}</Text>
    }

    /**
    * Detemine status color
    */
    const statusColor = (status) => {
        if (status === 1)
        return COLOR_STATUS_GRAY
        if (status === 2)
        return COLOR_STATUS_DANGER
        if (status === 3)
        return COLOR_STATUS_PROCESS
        if (status === 4)
        return COLOR_STATUS_SUCCESS
    }

    /**
    * Detemine answer
    */
    const answerUser = (answerSupplier) => {
        if (answerSupplier === "1")
        return <Text>{<Translate id="sustainability.answerYes"/>}</Text>
        if (answerSupplier === "2")
        return <Text>{<Translate id="sustainability.answerNo"/>}</Text>
    }

    return (
        <Root>
            <View style={styles.root}>
                
                <View>
                    <View style={styles.contentList}>
                        <View style={styles.listHeader}><Text><Translate id="sustainability.ActionOpportunityTitle"/></Text></View>
                    </View>
                    <View>
                    <View style={styles.contentTitleTable}>
                            <View style={styles.listStatus}><Text>{<Translate id="common.status"/>}</Text></View>
                            <View style={styles.listSection}><Text>{<Translate id="sustainability.section"/>}</Text></View>
                            <View style={styles.listQuestion}><Text>{<Translate id="sustainability.questionName"/>}</Text></View>
                            <View style={styles.listAnswers}><Text>{<Translate id="sustainability.answers"/>}</Text></View>
                            <View style={styles.listTips}><Text>{<Translate id="sustainability.tips"/>}</Text></View>
                            <View style={styles.listRecomendation}><Text>{<Translate id="sustainability.recommendations"/>}</Text></View>
                        </View>
                    </View>
                    <View>
                        {actionsOppornunity.map((oppornunity) => {
                            return (
                                <View key={oppornunity.Id} style={styles.contentTableRow}>
                                    <View style={[styles.listStatus, {color: statusColor(oppornunity.Status), marginTop: '10px'}]}>
                                            {statusTitle(oppornunity.Status)}
                                    </View>
                                    <View style={styles.listSection}><Text>{oppornunity.Section}</Text></View>
                                    <View style={styles.listQuestion}><Text>{oppornunity.QuestionName}</Text></View>
                                    <View style={styles.listAnswers}>{answerUser(oppornunity.AnswerSupplier)}</View>
                                    <View style={styles.listTips}><Text>{oppornunity.Recommendation}</Text></View>
                                    <View style={styles.listRecomendation}><Text>{oppornunity.Description}</Text></View>
                                </View>
                            )
                        })}
                    </View>
                </View>                                           
            </View>
        </Root>
    );
};

// Create styles
const styles = StyleSheet.create({
    root:{
        width: '100%',
        marginTop: "20px",
        fontSize: 8
    },
    contentList: {
        height: "25px",
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'green',
        color: 'white',
        fontSize: 12,
    },
    listHeader:{
        marginTop: '5px',
        marginLeft: '20px',
    },
    contentTitleTable: {
        textAlign: 'center',
        height: "25px",
        display: 'flex',
        flexDirection: 'row',
        color: 'black',
        fontSize: 10,
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    listStatus: {
        width:"10%",
        textAlign: 'center',
        marginTop: '2px'
    },
    listStatusColor: {
        width:"10%",
        height: 'auto',
        textAlign: 'left',
        flexDirection: 'row',
    },
    listStatusText: {
        width:"90%",
        textAlign: 'center',
    },
    listSection: {
        width:"10%",
        textAlign: 'center',
        marginTop: '2px'
    },
    listQuestion: {
        width:"25%",
        textAlign: 'center',
        marginTop: '2px'
    },
    listAnswers: {
        width:"10%",
        textAlign: 'center',
        marginTop: '2px'
    },
    listTips: {
        width:"25%",
        textAlign: 'center',
        marginTop: '2px'
    },
    listRecomendation: {
        width:"20%",
        textAlign: 'center',
        marginTop: '2px'
    },
    contentTableRow: {
        height: "auto",
        display: 'flex',
        flexDirection: 'row',
        marginTop: '5px',
        marginBottom: '5px',
    }
  });

ActionOpportunity.propTypes = {
    actionsOppornunity:PropTypes.array
};
  
ActionOpportunity.defaultProps = {
    actionsOppornunity: [],
};

export default ActionOpportunity;