/** React imports section */
import React, { useEffect } from 'react'
import { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

/** Layout imports section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

/** Material UI imports section */
import { Card } from '@material-ui/core'

/** Store imports section */
import {
	addProposalIncident,
	loadAllTransportTypeCatalog,
	loadTransportTenderDetailBackEnd,
	loadTypeShipmentCatalog,
} from '../../store/transportTender/transportTenderAction'

/** Common imports section */
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import DialogInfoTarif from './common/DialogInfoTarif'
/** Common imports section */
import Template from './detailTransportTender/Template'
import DetailTabs from './detailTransportTender/DetailTabs'
import DealsCreatePanel from './detailTransportTender/deals/DealsCreatePanel'
import DealsCard from './detailTransportTender/deals/DealsCard'
import Carrier from './detailTransportTender/carrier/Carrier'
/**Helpers imports section */
import { getTypeShipOptions } from '../../store/helpers/TransportTenderHelper'
import { TransportTendeStatus } from '../../store/helpers/AppConstants'

import ViewDealsSupplier from './detailTransportTender/deals/ViewDealsSupplier'
import CarrierSupplierView from './detailTransportTender/carrier/CarrierSupplierView'
import ResultsTender from './detailTransportTender/deals/ResultsTender'
import * as selector from '../../store/transportTender/transportTenderSelector'
import AddRequiredWineCellarDate from './detailTransportTender/AddDialogRequiredWineCellarDate'
import { getCarrierInformation } from '../../store/transportTender/transportTenderAction'
import IncidenceDialogProposal from './detailTransportTender/IncidenceDialogProposal'

const detail = () => {
	const dispatch = useDispatch()
	let location = useLocation()

	const [activeTab, setActiveTab] = useState(0)
	const [openDealsPanel, setOpenDealsPanel] = useState({ open: false, isEdit: false, deal: null })
	const [openWineCellarDateDialog, setOpenWineCellarDateDialog] = useState(false)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: '',
		toasterVariant: '',
	})
	const [openCalculateTarif, setOpenCalculateTarif] = useState(false)
	const [tariffAmount, setTariffAmount] = useState(0)
	const [openDialog, setOpenDialog] = useState({ open: false, supplier: null, proposalId: null, status: null })
	const [openDialogIncidence, setOpenDialogIncidence] = useState(false)
	const [proposalSelceted, setProposalselected] = useState(null)
	const [commentIncident, setCommentIncident] = useState(null)

	/** Get state */
	const islodigSaving = useSelector((state) => selector.getIsLoadingSavingSelector(state))
	const transportTenderdetail = useSelector((state) => selector.getTransportTenderDetailSelector(state))
	const selectedCompany = useSelector((state) => selector.getSelectedCompanySelector(state))
	const isLoading = useSelector((state) => selector.getIsLoadingTransportTenderSelector(state))
	const typeShipment = useSelector((state) => selector.getTypeShipmentSelector(state))
	const transportType = useSelector((state) => selector.getTransportTypeSelector(state))
	const carrierInformation = useSelector((state) => selector.getCarrierInformationSelector(state))
	const isProposal = useSelector((state) => selector.getIsProposalSelector(state))
	const isLoadingDownlaod = useSelector((state) => selector.getIsloadingDownloadLog(state))

	/**Get the user is internal */
	const userProfile = useSelector((state) => state.oidc.user)
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : false)
	const transportTenderId = location.pathname.replace('/transportTenderShow/', '')
	const typeShipmentsOptions = getTypeShipOptions(typeShipment)
	const winnerCarrierCode = () => {
		if (transportTenderdetail && transportTenderdetail.ProposalWinner)
			return transportTenderdetail && transportTenderdetail.ProposalWinner.CarrierBidding.Code
		else return ''
	}
	const winnerCode = winnerCarrierCode()
	const isTransportWinner = !isInternalUser()
		? userProfile &&
		  userProfile.profile &&
		  userProfile.profile.preferred_username &&
		  userProfile.profile.preferred_username === `${winnerCode}-admin`
		: false

	useEffect(() => {
		dispatch(loadTransportTenderDetailBackEnd(transportTenderId))
	}, [dispatch, location.search])

	useEffect(() => {
		dispatch(loadAllTransportTypeCatalog())
		dispatch(loadTypeShipmentCatalog())
	}, [dispatch])

	const reloadDetailInfo = () => dispatch(loadTransportTenderDetailBackEnd(transportTenderId))

	useEffect(() => {
		const profileCompanyCode =
			userProfile && userProfile.profile && userProfile.profile.company_code
				? userProfile.profile.company_code
				: ''
		dispatch(getCarrierInformation(profileCompanyCode, transportTenderId))
	}, [dispatch])

	const onClose = () => {
		setOpenWineCellarDateDialog(false)
		setOpenDialog({ open: false, supplier: null, proposalId: null, status: null })
	}
	const onAddIncidence = (idProposal) => {
		setProposalselected(idProposal)
		setOpenDialogIncidence(true)
	}
	const onAddIncidenceProposal = () => {
		const createdByName =
			userProfile && userProfile.profile && userProfile.profile.name ? userProfile.profile.name : ''
		const createdByUserName =
			userProfile && userProfile.profile && userProfile.profile.username ? userProfile.profile.username : ''
		dispatch(
			addProposalIncident(
				commentIncident,
				proposalSelceted,
				createdByName,
				createdByUserName,
				transportTenderdetail.Id
			)
		).then(() => {
			setOpenDialogIncidence(false)
			reloadDetailInfo()
		})
	}
	if (isLoading || !transportTenderdetail) {
		return <LoadingPage />
	}

	const onChangeTarif = (open, tariffAmount) => {
		setTariffAmount(tariffAmount)
		setOpenCalculateTarif(open)
	}
	return (
		<Card>
			<Template
				isInternalUser={isInternalUser()}
				transportTenderdetail={transportTenderdetail}
				transportTenderId={transportTenderId}
				selectedCompany={selectedCompany}
				transportType={transportType}
				setToasterMessageConfig={setToasterMessageConfig}
				islodigSaving={islodigSaving}
				typeShipmentsOptions={typeShipmentsOptions}
				userProfile={userProfile}
				carrierInformation={carrierInformation}
				isLoadingDownlaod={isLoadingDownlaod}
				setOpenWineCellarDateDialog={setOpenWineCellarDateDialog}
				isTransportWinner={isTransportWinner}
			/>
			{/* Tender Taps */}
			<DetailTabs
				setActiveTab={setActiveTab}
				activeTab={activeTab}
				setOpenPanel={setOpenDealsPanel}
				isInternalUser={isInternalUser()}
				transportTenderdetail={transportTenderdetail}
			/>
			{/* Deals create */}
			{openDealsPanel.open && (
				<DealsCreatePanel
					onClose={() => setOpenDealsPanel({ open: false, isEdit: false, deal: null })}
					transportTenderId={transportTenderId}
					isEdit={openDealsPanel.isEdit}
					deal={openDealsPanel.deal}
					setToasterMessageConfig={setToasterMessageConfig}
				/>
			)}
			{/* View of deals by internal user */}
			{activeTab === 0 && isInternalUser() && (
				<DealsCard
					deals={transportTenderdetail.Destinations}
					transportTenderdetail={transportTenderdetail}
					transportTenderId={transportTenderId}
					setOpenDealsPanel={setOpenDealsPanel}
					setToasterMessageConfig={setToasterMessageConfig}
				/>
			)}
			{/* View of Deals Supplier by internal  */}
			{activeTab === 0 && !isInternalUser() && transportTenderdetail.Status !== TransportTendeStatus.DRAFT && (
				<ViewDealsSupplier transportTenderdetail={transportTenderdetail} />
			)}
			{/* View of Carrier by internal user */}
			{activeTab === 1 && isInternalUser() && <Carrier transportTenderId={transportTenderId} />}
			{/* View of Carrier by  supplier */}
			{activeTab === 1 && !isInternalUser() && (
				<CarrierSupplierView
					transportTenderdetail={transportTenderdetail}
					transportTenderId={transportTenderId}
				/>
			)}
			{/* View of Results Tender by internal user */}
			{activeTab === 2 && isInternalUser() && (
				<ResultsTender
					proposals={transportTenderdetail.Proposals}
					transportTenderdetail={transportTenderdetail}
					isProposal={isProposal}
					setOpenDialog={setOpenDialog}
					onAddIncidence={onAddIncidence}
					onOpenCalculateTarif={onChangeTarif}
				/>
			)}
			<Toaster
				message={toasterMessageConfig.toasterMessage}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>{' '}
			<AddRequiredWineCellarDate
				open={openWineCellarDateDialog}
				openDialog={openDialog}
				transportTenderdetail={transportTenderdetail}
				onClose={onClose}
				isLoading={islodigSaving}
				setToasterMessageConfig={setToasterMessageConfig}
				onReloadDetail={reloadDetailInfo}
			/>
			<IncidenceDialogProposal
				open={openDialogIncidence}
				onClose={() => setOpenDialogIncidence(false)}
				onAddIncidence={onAddIncidenceProposal}
				setCommentIncident={setCommentIncident}
				commentIncident={commentIncident}
			/>
			<DialogInfoTarif
				open={openCalculateTarif}
				onClose={() => setOpenCalculateTarif(false)}
				transportType={transportTenderdetail.TransportTypeDescription}
				maxKm={
					transportTenderdetail &&
					transportTenderdetail.MaxTransportZoneDirection &&
					transportTenderdetail.MaxTransportZoneDirection.KM
				}
				total={tariffAmount ? tariffAmount : 0}
				maxTransportZoneDirection={
					transportTenderdetail &&
					transportTenderdetail.MaxTransportZoneDirection &&
					transportTenderdetail.MaxTransportZoneDirection.Direction &&
					transportTenderdetail.MaxTransportZoneDirection.Direction
				}
			/>
		</Card>
	)
}

export default withauthenticatedLayout(withRouter(detail))
