import { fromJS} from 'immutable';

import * as customerGuaranteeActions from './customerGuaranteeActions';

/**
 *  Initial state
 */
export const moduleGuaranteInitialState = fromJS({
    isLoadingCustomerGuarantee: true,
    customerGuaranteeData: [],
    isSavingCustomerGuarantee: false,
    sortCriteria: {
        by: "name",
        ascending: false
    },
    searchQuery: "",
    customerGuaranteeCount: null,
})

export default function moduleGuaranteeReducer(state = moduleGuaranteInitialState, action) {
    switch (action.type) {
        case customerGuaranteeActions.ADD_CUSTOMER_GUARANTEE_TO_LIST: {
            let customerGuarantee = fromJS(action.customerGuarantee);
            let customerGuaranteeList = state.get('customerGuaranteeData').push(customerGuarantee);
            return state.merge({
                customerGuaranteeData: customerGuaranteeList,
                isSavingCustomerGuarantee: false
            });
        }
        case customerGuaranteeActions.SET_CUSTOMER_GUARANTEE_TO_LIST:{
            let customerGuaranteeLists = fromJS(action.customerGuaranteeData)
            return state.merge({
                customerGuaranteeData: customerGuaranteeLists,
                isLoadingCustomerGuarantee: false
            })
        }
        case customerGuaranteeActions.CHANGE_CUSTOMER_GUARANTEE_SORT_QUERY:{
            return state.merge({
                searchQuery: action.query
            })
        }
        case customerGuaranteeActions.SET_CUSTOMER_GUARANTEE_COUNT:{
            return state.merge({
                customerGuaranteeCount: action.count
            })
        }
        case customerGuaranteeActions.CHANGE_CUSTOMER_GUARANTEE_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case customerGuaranteeActions.IS_SAVING_CUSTOMER_GUARANTEE: {
            return state.merge({
                isSavingCustomerGuarantee: action.isSaving
            })
        }
        default:
            return state;
    }

}