import React from 'react'
import propTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { Popper, ClickAwayListener, Paper } from '@material-ui/core'
import BaseConfiguration from './BaseConfigurator'

/**
 * A data grid configurator contained in a popper
 */
const ColumnsConfigurator = (props) => {
	const { open, anchorEl, translate } = props
	return (
		<Popper open={open} anchorEl={anchorEl} style={{ zIndex: 500}}>
			<ClickAwayListener onClickAway={props.onClickAway}>
				<Paper>
					<BaseConfiguration
						{...props}
						saveButtonText={translate('serviceOrdersToolbar.saveConfiguration')}
					/>
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}
ColumnsConfigurator.propTypes = {
	/**
	 *  It's used to set the position of the popper. The return value will passed as the reference object of the Popper instance.
	 */
	anchorEl: propTypes.object,
	/**
	 * If true, the popper is visible.
	 */
	open: propTypes.bool.isRequired,
	/**
	 * Callback executed when the user clicks away from the popper
	 */
	onClickAway: propTypes.func.isRequired,
	/**
	 * The placement of the popper relative to the anchorel
	 */
	placement: propTypes.oneOf([
		'bottom-end',
		'bottom-start',
		'bottom',
		'left-end',
		'left-start',
		'left',
		'right-end',
		'right-start',
		'right',
		'top-end',
		'top-start',
		'top',
	]),
	/**
	 * The table configuration with visible and index properties
	 */
	configuration: propTypes.arrayOf(
		propTypes.shape({
			header: propTypes.oneOfType([propTypes.node, propTypes.string]).isRequired,
			visible: propTypes.bool.isRequired,
		})
	).isRequired,
	/**
	 * Callback executed when the user save the configuration
	 */
	onConfigurationChange: propTypes.func.isRequired,
	/**
	 * The max height of the columns configuration container
	 */
	maxHeight: propTypes.string,
}
ColumnsConfigurator.defaultProps = {
	open: false,
	placement: 'bottom',
	maxHeight: '300px',
}
export default withLocalize(ColumnsConfigurator)
