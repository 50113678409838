
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import RejectIcon from '@material-ui/icons/ThumbDown';

/** Import component section */
import Dialog from '../../common/Dialog';
import { APPROVAL_REJECTED, APPROVAL_ACEPTED } from '../../../store/helpers/ResourcesHelper';
import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER } from '../../../store/helpers/StatusColorConstants';

/**
 * Dialog to accepted, rejected or decline quote
 */
class AcceptedRejectedAnnRmi extends Component {

    /**
   *Creates an instance of DialogActions.
   * @param {*} props
   * @memberof DialogActions
   */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
     * Dialog to update information
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            {<Translate id="samplesrequest.accetedOrRejected" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <React.Fragment>
                        <Toolbar>
                            <div className={classes.grow}></div>
                            {
                                this.props.isSaving === true ?
                                    <Button variant="outlined" color="secondary" className={classes.button} disabled>
                                        <Translate id="common.saving" />
                                    </Button>
                                    :
                                    <React.Fragment>
                                        <Button variant="outlined" size="small" style={{ color: COLOR_STATUS_SUCCESS, borderColor: COLOR_STATUS_SUCCESS }} className={classes.button}
                                            data-cy="btn-accept"
                                            onClick={() => this.props.onClickAcceptRejectAnnotations(APPROVAL_ACEPTED)}
                                        >
                                            <Translate id="common.accept" />
                                        </Button>

                                        <Button variant="outlined" size="small" style={{ color: COLOR_STATUS_DANGER, borderColor: COLOR_STATUS_DANGER }} className={classes.button}
                                            data-cy="btn-reject"
                                            onClick={() => this.props.onClickAcceptRejectAnnotations(APPROVAL_REJECTED)}
                                            startIcon={<RejectIcon />}
                                        >
                                            <Translate id="common.reject" />
                                        </Button>

                                    </React.Fragment>
                            }
                        </Toolbar>
                        {
                            this.props.isSaving === true ?
                                <LinearProgress color="secondary" variant="query" />
                                : null
                        }
                    </React.Fragment>
                }>
                <div>
                    <span><Translate id="samplesrequest.messaeToAccepted" /></span>
                </div>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(AcceptedRejectedAnnRmi));