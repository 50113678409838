//blob
export const onGetBlobType = (blobType) => {
	switch (blobType) {
		case '.pdf':
		case 'pdf':
			return 'application/pdf'
		case '.xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		case '.txt':
			return 'text/plain'
		case '.docx':
			return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
		case '.png':
		case 'png':
			return 'image/png'
		case '.jpg':
		case 'jpg':
			return 'image/jpg'
		case '.jpeg':
		case 'jpeg':
			return 'image/jpeg'
		case '.jfif':
		case 'jfif':
			return 'image/jpeg'
		case 'mp4':
		case '.mp4':
			return 'video/mp4'
		default:
			return 'application/pdf'
	}
}
