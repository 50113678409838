/**Import react section */
import React, { useState } from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Select from "react-select";

/** Language imports **/
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

/** Import component section */
import Dialog from "../../../common/Dialog";
import DialogActions from "../../../common/DialogActions";

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from "../../../../store/helpers/ResourcesHelper";
import { IsNullOrEmpty } from "../../../../store/helpers/StringHelper";
import {
  GetQuestionTypeSustainability,
  GetClassificationType,
  getSelectedValue,
  GetBooleanOptions,
} from "../../../../store/helpers/SelectOptions";

const CardCreateQuestion = (props) => {
  /**props section */
  const {
    open,
    onClose,
    isSaving,
    classes,
    onSaveItem,
    itemSelected,
    isEdit,
    onCloseEdit,
    onSaveItemEdit,
    topicId,
    subTopicId,
    questionId,
  } = props;

  const questionType = GetQuestionTypeSustainability();
  const classificationType = GetClassificationType();
  const responseType = GetBooleanOptions();

  const [itemModel, setItemModel] = useState(
    itemSelected
      ? {
          topicId: topicId,
          subTopicId: subTopicId,
          questionId: questionId,
          questionName: itemSelected.Name,
          questionType: itemSelected.QuestionType,
          percentage: itemSelected.Percentage,
          recomendationQuestion: itemSelected.Recommendation,
          warning: itemSelected.Warning,
          answer: itemSelected.CorrectAnswer,
          classificationType: itemSelected.ClassificationType,
          requiredDocument: itemSelected.RequiredDocument === true ? 1 : 2,
        }
      : {
          questionName: "",
          questionType: "",
          percentage: "",
          recomendationQuestion: "",
          warning: "",
          answer: 0,
          classificationType,
          requiredDocument: 0,
        }
  );

  /**
   * Handle update properties to set data in local state
   *
   * @param {*} property
   * @param {*} data
   * @memberof AddModal
   */
  const updateProperty = (event) => {
    let { name, value } = event.target;
    setItemModel({
      ...itemModel,
      [name]: value,
    });
  };

  /**
   * On change type
   * @param {*} dataDelivery
   */
  const onPropertyClassification = (classification) => {
    setItemModel({
      ...itemModel,
      classificationType: classification,
    });
  };

  /**
   * On change question type
   * @param {*} dataDelivery
   */
  const onPropertyQuestion = (questionSelected) => {
    setItemModel({
      ...itemModel,
      questionType: questionSelected,
    });
  };

  /**
   * On change type
   * @param {*} dataDelivery
   */
  const onPropertyAnswer = (answer) => {
    setItemModel({
      ...itemModel,
      answer: answer,
    });
  };

   /**
   * On change type
   * @param {*} dataDelivery
   */
    const onPropertyDocument = (requiredDocument) => {
      setItemModel({
        ...itemModel,
        requiredDocument: requiredDocument,
      });
    };

  /**
   * Function on save update
   */
  const onSaveItemUpdate = () => {
    if (props.onSaveItem) onSaveItem(itemModel);
  };

  /**
   * Function on edit
   */
  const onSaveItemEditQuestion = () => {
    if (props.onSaveItemEdit) onSaveItemEdit(itemModel);
  };

  /**
   * Method to validate if required properties has data
   *
   * @returns
   * @memberof AddModal
   */
  const isValidToSave = () => {
    if (IsNullOrEmpty(itemModel.questionName)) return false;
    if (itemModel.questionType === null) return false;
    if (itemModel.classificationType === null) return false;
    else return true;
  };

  return (
    <Dialog
      open={open}
      onClose={isEdit ? onCloseEdit : onClose}
      header={
        <Toolbar>
          <Typography variant="h6">
            {<Translate id="questionnaire.addQuestion" />}
          </Typography>
        </Toolbar>
      }
      actions={
        <div>
          <DialogActions
            view={SHOW_EDIT_VIEW}
            classes={classes}
            onCancel={isEdit ? onCloseEdit : onClose}
            onClose={isEdit ? onCloseEdit : onClose}
            onSaveItem={isEdit ? onSaveItemEditQuestion : onSaveItemUpdate}
            isSaving={isSaving}
            canBeSaved={isValidToSave() || isSaving}
          />
        </div>
      }
    >
      <Grid container className={classes.modal} justify="space-between">
        <TextField
          name="questionName"
          label={<Translate id="questionnaire.nameQuestion" />}
          defaultValue={itemModel.questionName}
          size="small"
          margin="dense"
          type={"text"}
          fullWidth
          required={true}
          onChange={updateProperty}
          variant={"outlined"}
        />
        <Grid container spacing={12} xs={12}>
          <Grid item xs={5}>
            <Typography variant="caption" color={"textSecondary"}>
              <Translate id="questionnaire.questionType" />
            </Typography>
            <Select
              name={"questionType"}
              value={getSelectedValue(questionType, itemModel.questionType)}
              className={classes.documentTypeSelect}
              options={questionType}
              onChange={onPropertyQuestion}
              styles={{
                menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: 200,
                  paddingTop: 0,
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
              }}
              menuPortalTarget={document.parentNode}
              menuPosition="fixed" //THIS IS REQUIRED IN MODAL!!
              menuPlacement={"auto"}
            />
          </Grid>
          <Grid item xs={6} style={{ marginLeft: "auto" }}>
            <Typography variant="caption" color={"textSecondary"}>
              <Translate id="questionnaire.classificationType" />
            </Typography>
            <Select
              name={"classificationType"}
              value={getSelectedValue(
                classificationType,
                itemModel.classificationType
              )}
              className={classes.documentTypeSelect}
              options={classificationType}
              onChange={onPropertyClassification}
              styles={{
                menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: 200,
                  paddingTop: 0,
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
              }}
              menuPortalTarget={document.parentNode}
              menuPosition="fixed" //THIS IS REQUIRED IN MODAL!!
              menuPlacement={"auto"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={12} xs={12}>
          <Grid item xs={5}>
            <TextField
              name={"warning"}
              label={<Translate id="questionnaire.warning" />}
              defaultValue={itemModel.warning}
              size="small"
              type={"text"}
              margin="dense"
              fullWidth
              required={true}
              onChange={updateProperty}
              variant={"outlined"}
            />
          </Grid>

          <Grid item xs={6} style={{ marginLeft: "auto" }}>
            {itemModel.questionType != null && (itemModel.questionType === 0 || itemModel.questionType.value === 0) && (
              <TextField
                name={"percentage"}
                label={<Translate id="questionnaire.percentage" />}
                defaultValue={itemModel.percentage}
                size="small"
                type="number"
                margin="dense"
                step={0.5}
                fullWidth
                required={true}
                onChange={updateProperty}
                variant={"outlined"}
              />
            )}
          </Grid>
        </Grid>
        <TextField
          name={"recomendationQuestion"}
          label={<Translate id="questionnaire.recomendationQuestion" />}
          defaultValue={itemModel.recomendationQuestion}
          size="small"
          margin="dense"
          type={"text"}
          fullWidth
          required={true}
          onChange={updateProperty}
          variant={"outlined"}
        />
        <Grid container spacing={12} xs={12}>
          {itemModel.questionType != null && (itemModel.questionType === 0 || itemModel.questionType.value === 0) && (
            <Grid item xs={5}>
              <Typography variant="caption" color={"textSecondary"}>
                <Translate id="questionnaire.Answer" /> {"correcta"}
              </Typography>
              <Select
                name={"answer"}
                value={getSelectedValue(responseType, itemModel.answer)}
                className={classes.documentTypeSelect}
                options={responseType}
                onChange={onPropertyAnswer}
                styles={{
                  menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                  menuList: (base) => ({
                    ...base,
                    maxHeight: 200,
                    paddingTop: 0,
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                }}
                menuPortalTarget={document.parentNode}
                menuPosition="fixed" //THIS IS REQUIRED IN MODAL!!
                menuPlacement={"auto"}
              />
            </Grid>
          )}
          {itemModel.questionType != null && (
            <Grid item xs={(itemModel.questionType === 0 || itemModel.questionType.value === 0) ? 6 : 12} style={{ marginLeft: "auto" }}>
              <Typography variant="caption" color={"textSecondary"}>
                {"Marcar documento como obligatorio"}
              </Typography>
              <Select
                name={"requiredDocument"}
                value={getSelectedValue(responseType, itemModel.requiredDocument)}
                className={classes.documentTypeSelect}
                options={responseType}
                onChange={onPropertyDocument}
                styles={{
                  menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                  menuList: (base) => ({
                    ...base,
                    maxHeight: 200,
                    paddingTop: 0,
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                }}
                menuPortalTarget={document.parentNode}
                menuPosition="fixed" //THIS IS REQUIRED IN MODAL!!
                menuPlacement={"auto"}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

const styles = (theme) => ({
  modal: {
    marginRight: theme.spacing.unit * 2,
  },
});

CardCreateQuestion.propTypes = {
  /** Close ADD card */
  onClose: propTypes.func,
  /** Close EDIT card */
  onCloseEdit: propTypes.func,
  onSaveItem: propTypes.func,
  /**
   * Determinate if is saving data
   */
  isSaving: propTypes.bool.isRequired,
  onSaveItemEdit: propTypes.func,
  topicId: propTypes.string,
  subTopicId: propTypes.string,
  questionId: propTypes.string,
  itemSelected: propTypes.object,
  isEdit: propTypes.bool,
};

CardCreateQuestion.defaultProps = {
  onClose: () => console.warn("Callback [onClose] no defined"),
  onCloseEdit: () => console.warn("Callback [onCloseEdit] no defined"),
  onSaveItem: () => console.warn("Callback [onSaveItem] no defined"),
  onSaveItemEdit: () => console.warn("Callback [onSaveItemEdit] no defined"),
  isSaving: false,
  topicId: "",
  subTopicId: "",
  questionId: "",
  itemSelected: {},
  isEdit: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(CardCreateQuestion)
);
