import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import QuestionnaireIcon from '@material-ui/icons/NaturePeople'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import EmailIcon from '@material-ui/icons/ContactMail'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import Tooltip from '@material-ui/core/Tooltip'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import DataTable from '../../common/DataTable'
import LoadingPage from '../../common/LoadingPage'
import SearchInput from '../../common/SearchInput/SearchInput'
import AddCommonItem from '../../common/AddCommonItem'
import Toaster from '../../common/Toaster'

import {
	CHANGE_QUESTIONNAIRES_SORT_CRITERIA,
	CHANGE_QUESTIONNAIRES_QUERY,
	CHANGE_QUESTIONNAIRES_PAGINATION,
	loadQuestionnairesFromBackEnd,
	getQuestionnairesCount,
	sendReminderToSuppliersFromQuestionnaireInProcess,
} from '../../../store/questionnaires/QuestionnairesAction'
import { converListToArray } from '../../../store/helpers/ListHelper'
import { showSuccessToaster, showErrorToaster } from '../../../store/helpers/ToasterHelpers'
import { canBeModuleSustantabilityQuestionnaire } from '../../../store/helpers/RolesHelper'
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper'

class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openSendReminder: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadQuestionnaires(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.props.isAllReports
			)
			this.props.getQuestionnairesCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadQuestionnaires()
			this.props.getQuestionnairesCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadQuestionnaires(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * onClickCloseReport
	 *
	 * @param {*} item
	 * @memberof Create
	 */
	onClickSendReminderToSuppliers() {
		this.setState({
			openSendReminder: true,
		})
	}

	/**
	 * on send reminder to supplie rin questionnare in process
	 * @param {*} comments
	 */
	onSendReminderSupplier() {
		this.props
			.sendReminderToSuppliersFromQuestionnaireInProcess()
			.then(() => {
				this.setState({ openSendReminder: false })
				this.setState(showSuccessToaster('common.reminderSendCorrectly'))
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToSave'))
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingQuestionnaires === true) {
			return <LoadingPage />
		} else if (this.props.questionnaires === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.questionnaires}
					configuration={TableConfiguration(IsCurrentActiveDirectoryUser(this.props.user))}
					showFilters
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(sustainability) => {
						this.props.history.push(`/questionnaire/${sustainability.token}`)
					}}
					isIndex
					totalRows={this.props.questionnairesCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get moemos counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getSacsCounterMessage() {
		const { classes } = this.props
		if (this.props.questionnaires) {
			let totalQuestionnaires = this.props.questionnaires.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalQuestionnaires}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span className={classes.totalCounter}>{this.props.questionnairesCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof Sacs
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<QuestionnaireIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>
							{canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany) && (
								<Fab
									className={classes.iconButon}
									size='small'
									color='primary'
									onClick={() => {
										this.props.history.push(`/questionnaire/create`)
									}}
								>
									<AddtIcon />
								</Fab>
							)}

							<Fab
								className={classes.iconButon}
								size='small'
								color='secondary'
								onClick={() => {
									this.props.history.push(`/questionnaire/calendar`)
								}}
							>
								<CalendarIcon />
							</Fab>
							{canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany) && (
								<Tooltip
									className={classes.iconButon}
									title={<Translate id='questionnaire.sendMassMailToSuppliers' />}
								>
									<Fab
										size='small'
										disabled={this.props.isLoading}
										onClick={() => {
											this.onClickSendReminderToSuppliers()
										}}
									>
										<EmailIcon color='primary' />
									</Fab>
								</Tooltip>
							)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='questionnaire.titleQuestionnaire' />{' '}
						</Typography>
					}
					subheader={this.getSacsCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				<AddCommonItem
					title={<Translate id='questionnaire.sendMassMailToSuppliers' />}
					message={<Translate id='questionnaire.messageSendMassMailToSuppliers' />}
					open={this.state.openSendReminder}
					onClose={() => {
						this.setState({ openSendReminder: false })
					}}
					onSaveItem={this.onSendReminderSupplier}
					isSaving={this.props.isSaving}
					isMessage={true}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableConfiguration(isUserInternal) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='questionnaire.questionnaireType' />,
			dataSource: 'description_questionnaire_type',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='common.createBy' />,
			dataSource: 'create_by',
			isSortable: true,
		},
	]

	if (isUserInternal) {
		columns.push({
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		})
	}

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingQuestionnaires: state.questionnaires.get('isLoadingQuestionnaires'),
		questionnaires: state.questionnaires.get('questionnaires')
			? state.questionnaires.get('questionnaires').toJS()
			: null,
		sortCriteria: state.questionnaires.get('sortCriteria') ? state.questionnaires.get('sortCriteria').toJS() : null,
		searchQuery: state.questionnaires.get('searchQuery'),
		page: state.questionnaires.get('page'),
		rowsPerPage: state.questionnaires.get('rowsPerPage'),
		questionnairesCount: state.questionnaires.get('questionnairesCount'),
		isSaving: state.questionnaires.get('isSavingQuestionnaire'),
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_QUESTIONNAIRES_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_QUESTIONNAIRES_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_QUESTIONNAIRES_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		loadQuestionnaires: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadQuestionnairesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		getQuestionnairesCount: () => {
			dispatch(getQuestionnairesCount())
		},
		sendReminderToSuppliersFromQuestionnaireInProcess: () => {
			return dispatch(sendReminderToSuppliersFromQuestionnaireInProcess())
		},
	}
}

const styles = (theme) => ({
	logo: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
	iconButon: {
		marginLeft: '5px',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
