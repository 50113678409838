import {
    WarehouseWindowsStatus
} from './AppConstants'

export const descriptionStatus = (status, translate) => {
    switch (Number(status)) {
        case WarehouseWindowsStatus.IN_PROCESS:
            return translate("warehouseWindows.status.inProcess")
        case WarehouseWindowsStatus.CLOSED:
            return translate("warehouseWindows.status.closed")
        case WarehouseWindowsStatus.CANCEL:
            return translate("warehouseWindows.status.cancel")
        default:
            return ""
    }
};


export const backgroundColorTableStatus = (status) => {
    switch (Number(status)) {
        case WarehouseWindowsStatus.IN_PROCESS:
            return "warning"
        case WarehouseWindowsStatus.CLOSED:
            return "success"
        case WarehouseWindowsStatus.CANCEL:
            return "danger"
        default:
            return ""
    }
};