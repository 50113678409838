import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import FormView from './FormView'
import ChangeLogo from './ChangeLogo'
import DataGrid from '../common/DataGrid/DataGrid'
import MemoryPager from '../common/DataGrid/MemoryPager'
import Dialog from '../common/Dialog'
import DialogActions from '../common/DialogActions'
import AddCommonItem from '../common/AddCommonItem'

/** Material-UI imports section */
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import EditIcon from '@material-ui/icons/Edit'
import BusinessIcon from '@material-ui/icons/BusinessTwoTone'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Translate, getTranslate } from 'react-localize-redux'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import LinkMaterial from '@material-ui/core/Link'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Import section actions */
import { SHOW_EDIT_VIEW } from '../../store/helpers/ResourcesHelper'
import { IsNullOrEmpty, genereteStringGuid } from '../../store/helpers/StringHelper'
import {
	loadCompany,
	updateCompanyData,
	addWarehousesInCompany,
	addCompanyModule,
	onRemoveWarehouse,
	createPrivacyNoticeData,
	getAllPrivacyNotice,
	removePrivacyNotice,
	getActivePrivacyNotice,
} from '../../store/companies/CompanyActions'
import { loadEvaluationSuppliersRatingByCompanyFromBackEnd } from '../../store/ratings/ratingActions'
import MenuConfiguration from './components/MenuConfiguration'
import { Grid, Chip } from '@material-ui/core'
import EvaluationSupplier from './components/EvaluationSupplier'
import AddPrivacy from '../manuals/AddManual'

/**
 * Container to show company
 */
class Show extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.uploadLogoFileRef = React.createRef()
		this.state = {
			showToView: true,
			editCompany: null,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			activeTab: 0,
			showChangeLogoDialog: false,
			logoFileBase64: null,
			croppedFileBase64: null,
			name: '',
			showAddWarehouse: false,
			openRemoveItem: false,
			itemId: '',
			openAddPrivacyModal: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let companyToken = this.props.match.params.token
		this.props.loadCompany(companyToken)
		this.props.loadEvaluationSuppliersRatingByCompany(companyToken)
		this.props.loadPrivacyNotices(
			this.props.searchQuery,
			this.props.sortCriteria.by,
			this.props.sortCriteria.ascending,
			companyToken
		)
	}

	/**
	 * Set view to edit information company
	 */
	setViewInEditMode(activeShow) {
		this.setState({
			showToView: activeShow,
			editCompany: { ...this.props.company },
		})
	}

	/**
	 * Update the property of the company
	 *
	 * @param {*} user
	 * @param {*} value
	 * @memberof View
	 */
	updateProperty(property, value) {
		let updatedCompany = { ...this.state.editCompany }
		updatedCompany[property] = value
		this.setState({
			editCompany: updatedCompany,
		})
	}

	/**
	 * On seleted value of select
	 */
	onSelected(propertyId, value) {
		this.setState({ [propertyId]: value })
	}

	/**
	 * Execute Serie data validation
	 */
	validateData() {
		const { editCompany } = this.state
		let validations = []
		if (editCompany) {
			if (IsNullOrEmpty(editCompany.name)) {
				validations.push({
					key: 'name',
				})
			}
			if (IsNullOrEmpty(editCompany.commercial_name)) {
				validations.push({
					key: 'commercial_name',
				})
			}
			if (IsNullOrEmpty(editCompany.rfc)) {
				validations.push({
					key: 'rfc',
				})
			}
			if (IsNullOrEmpty(editCompany.address)) {
				validations.push({
					key: 'address',
				})
			}
			if (IsNullOrEmpty(editCompany.partnership_code)) {
				validations.push({
					key: 'partnership_code',
				})
			}
			if (IsNullOrEmpty(editCompany.center)) {
				validations.push({
					key: 'center',
				})
			}
			if (IsNullOrEmpty(editCompany.center_fin)) {
				validations.push({
					key: 'center_fin',
				})
			}
			if (IsNullOrEmpty(editCompany.division)) {
				validations.push({
					key: 'division',
				})
			}
			if (IsNullOrEmpty(editCompany.administrator_token)) {
				validations.push({
					key: 'administrator_token',
				})
			}
		}
		return validations
	}

	/**
	 * Action to can be update information serie
	 */
	canBeSaved() {
		let validations = []
		validations = this.validateData()
		if (this.props.isSavingCompany) return false
		else return validations.length === 0
	}

	/**
	 * Action to save company
	 */
	onSaveCompany() {
		this.props
			.updateCompanyData(this.state.editCompany)
			.then(() => {
				this.setState({
					showToView: true,
					showToaster: true,
					toasterMessage: <Translate id='common.saveCorrectly' />,
					toasterVariant: 'success',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Method to change the active tab
	 */
	onClickToChangeActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 * Upload the log file
	 *
	 * @memberof UserProfileHeader
	 */
	uploadLogoFile(file) {
		let self = this
		let fileUpload = file.target.files[0]
		/// Determine if the image to add not is undefined or null
		if (fileUpload !== undefined && fileUpload !== null) {
			var reader = new FileReader()
			reader.onload = function () {
				self.onSelecteFile(reader.result)
			}
			reader.onerror = function (error) {
				console.log('Error: ', error)
			}
			reader.readAsDataURL(fileUpload)
			self.uploadLogoFileRef.current.value = ''
		}
	}

	/**
	 * On select logo file
	 *
	 * @param {*} fileBase64
	 * @memberof Profile
	 */
	onSelecteFile(fileBase64) {
		this.setState({
			logoFileBase64: fileBase64,
			croppedFileBase64: fileBase64,
			showChangeLogoDialog: true,
		})
	}

	/**
	 * Method to set the panel to add warehouse
	 */
	setShowAddWarehouseState(open) {
		this.setState({
			showAddWarehouse: open,
			name: '',
		})
	}

	/**
	 * Method to set the panel to add warehouse
	 */
	handleShowAddPrivacyDialog(open) {
		this.setState({
			openAddPrivacyModal: open,
		})
	}

	/**
	 * openAddPrivacyModal
	 * @param {*} openAddPrivacyModal
	 */
	handleShowAddDialog(openAddPrivacyModal) {
		this.setState({
			openAddPrivacyModal,
		})
	}

	/**
	 * Action to can be  information warehouses
	 */
	canBeSavedStock() {
		return this.state.name !== ''
	}

	/**
	 *method that has the warehouses's id
	 *
	 * @param {*} userId
	 * @memberof Show
	 */
	updatePropertyStock(name) {
		this.setState({
			name: name,
		})
	}

	/**
	 * Function to create new warehouses in company
	 * @param {*} name
	 */
	onCreateWarehouse() {
		this.props
			.onCreateWarehouse(this.props.company.token, this.state.name)
			.then(() => {
				this.setState({
					showAddWarehouse: false,
					showToView: true,
					showToaster: true,
					toasterMessage: <Translate id='common.saveCorrectly' />,
					toasterVariant: 'success',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Function to call action if switch change value to add module to company
	 *
	 * @param {*} event
	 * @memberof Show
	 */
	toggleActiveMenu(event) {
		this.props
			.onChangeActive(this.props.company.token, event)
			.then(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.saveCorrectly' />,
					toasterVariant: 'success',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * onRemoveItem
	 */
	onRemoveItem(itemId) {
		this.setState({
			openRemoveItem: true,
			itemId: itemId,
		})
	}

	/**
	 * Function on remove warehouses
	 */
	onRemoveWarehouse() {
		this.props
			.onRemoveWarehouse(this.props.company.token, this.state.itemId)
			.then(() => {
				this.setState({
					openRemoveItem: false,
					itemId: '',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}
	/**
	 * Handle event to add action, call redux action to save data
	 * @param {*} data
	 */
	onAddPrivacyNotice(data) {
		return this.props
			.createPrivacyNoticeData(data, this.props.match.params.token ? this.props.match.params.token : '')
			.then(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.addCorrectly' />,
					toasterVariant: 'success',
				})
				this.props.loadPrivacyNotices(
					this.props.searchQuery,
					this.props.sortCriteria.by,
					this.props.sortCriteria.ascending,
					this.props.match.params.token
				)
				this.props.getActivePrivacyNotice(this.props.match.params.token, localStorage)
				this.setState({ openAddPrivacyModal: false })
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToAdd' />,
					toasterVariant: 'error',
				})
				return Promise.reject()
			})
	}

	/**
	 * onRemovePrivacyNotice
	 */
	onRemovePrivacyNotice() {
		this.props
			.removePrivacyNotice(this.state.itemId)
			.then(() => {
				this.setState({
					openRemoveItem: false,
					itemId: '',

					showToaster: true,
					toasterMessage: <Translate id='common.removeCorrectly' />,
					toasterVariant: 'success',
				})
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToRemove' />,
					toasterVariant: 'error',
				})
				return Promise.reject()
			})
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		if (dataSource === 'token') {
			if (!item.isActive) {
				return (
					<Tooltip title={<Translate id='companies.removeWarehouse' />}>
						<DeleteOutlinedIcon
							onClick={() => {
								this.onRemoveItem(item.token)
							}}
							style={{ zIndex: '990', cursor: 'pointer' }}
							color='error'
							className={classes.icon}
						/>
					</Tooltip>
				)
			} else {
				return <></>
			}
		} else if (dataSource === 'isActive') {
			if (item.isActive) {
				return <Chip label={<Translate id='common.active' />} className={classes.activeChip}></Chip>
			} else {
				return <Chip label={<Translate id='common.inactive' />} />
			}
		} else if (dataSource === 'name') {
			return (
				<LinkMaterial
					key={item.token}
					component={'a'}
					target='_blank'
					color='primary'
					href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/Documents/PrivacyNotices/${item.token}.pdf`}
				>
					<DownloadIcon style={{ marginTop: '-7px' }} /> {item.name}
				</LinkMaterial>
			)
		} else if (dataSource === 'LanguageSelected') {
			switch (item.LanguageSelected) {
				case 'es':
					return <Translate id='common.spanish' />
				case 'en':
					return <Translate id='common.english' />
				case 'pt':
					return <Translate id='common.portugues' />
				default:
					return ''
			}
		}
	}

	getPrivacyNoticeContent() {
		if (this.props.isLoadingPrivacyNotice === true) {
			return <LoadingPage />
		} else if (this.props.privacyNotices === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataGrid
					data={this.props.privacyNotices}
					configuration={privacyTableConfiguration}
					onRenderCellItem={this.onRenderCellItem}
					showAdd
					onClickAddButton={() => this.handleShowAddPrivacyDialog(true)}
					pager={{ pagerType: MemoryPager, pagerOptions: [10, 15, 20] }}
				/>
			)
		}
	}

	/**
	 * Render show
	 */
	render() {
		const { classes } = this.props
		if (this.props.isLoadingCompany === true) {
			return <LoadingPage />
		}
		if (this.props.company === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let company = this.props.company
			let warehouses = company ? this.props.company.warehouses : []
			let urlImage = `${process.env.REACT_APP_SIP_URL_LOGOS_ADMINISTRATION}/${company.logo}`
			let logoUrl = urlImage + '?token=' + genereteStringGuid()
			return (
				<Paper>
					<AppBar position='static' color='default'>
						<Tabs
							className={classes.styleTab}
							value={this.state.activeTab}
							onChange={this.onClickToChangeActiveTab}
							indicatorColor='primary'
							textColor='primary'
							variant='scrollable'
							scrollButtons='auto'
						>
							<Tab label={<Translate id='companies.informationCompany' />} />
							<Tab label={<Translate id='companies.menuOptions' />} />
							<Tab label={<Translate id='companies.warehouses' />} />
							<Tab label={<Translate id='companies.evaluations.evaluation' />} />
							<Tab label={<Translate id='common.privacyNotice' />} />
						</Tabs>
					</AppBar>

					{this.state.activeTab === 0 && (
						<div className={classes.containerOptionsMenu}>
							<Grid container justify='space-between' alignItems='center'>
								{company.logo && (
									<Grid item xs={2}>
										<Avatar
											alt='Remy Sharp'
											src={logoUrl}
											className={classes.logoCompany}
											onClick={() => {
												this.uploadLogoFileRef.current.click()
											}}
										/>
										<input
											className={classes.logoFileInput}
											type='file'
											accept='image/*'
											ref={this.uploadLogoFileRef}
											multiple={false}
											onChange={this.uploadLogoFile}
										/>
									</Grid>
								)}

								{!company.logo && (
									<Grid item xs={1}>
										<IconButton
											alt='Remy Sharp'
											className={classes.logoCompany}
											onClick={() => {
												this.uploadLogoFileRef.current.click()
											}}
										>
											<BusinessIcon />
										</IconButton>
										<input
											className={classes.logoFileInput}
											type='file'
											accept='image/*'
											ref={this.uploadLogoFileRef}
											multiple={false}
											onChange={this.uploadLogoFile}
										/>
									</Grid>
								)}
								<Grid item xs={1}>
									<Tooltip title='Editar'>
										<IconButton
											data-cy='btn-edit'
											className={classes.edit}
											onClick={() => this.setViewInEditMode(false)}
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>

							{this.state.showToView && (
								<FormView
									company={company}
									setViewInEditMode={this.setViewInEditMode}
									showToView={this.state.showToView}
									errors={this.validateData()}
								/>
							)}

							{!this.state.showToView && (
								<FormView
									company={this.state.editCompany}
									setViewInEditMode={this.setViewInEditMode}
									showToView={this.state.showToView}
									updateProperty={this.updateProperty}
									onSelected={this.onSelected}
									errors={this.validateData()}
									canBeSaved={this.canBeSaved()}
									onSave={this.onSaveCompany}
								/>
							)}
						</div>
					)}
					{this.state.activeTab === 1 && (
						<div className={classes.containerOptionsMenu}>
							<MenuConfiguration
								moduleEnabledStatus={this.props.companyModules}
								toggleActiveMenu={this.toggleActiveMenu}
							/>
						</div>
					)}

					{this.state.activeTab === 2 && (
						<div className={classes.containerWarehouse}>
							<DataGrid
								titleSize='h6'
								title={<Translate id='companies.warehouses' />}
								data={warehouses}
								configuration={tableConfiguration}
								onRenderCellItem={this.onRenderCellItem}
								showAdd
								onClickAddButton={() => this.setShowAddWarehouseState(true)}
								pager={{ pagerType: MemoryPager, pagerOptions: [10, 15, 20] }}
							/>

							{/** Dialog to add information */}
							<Dialog
								open={this.state.showAddWarehouse}
								onClose={() => {
									this.setState({ showAddWarehouse: false })
								}}
								header={
									<Toolbar>
										<Typography variant='h6'>
											{<Translate id='companies.addWarehouse' />}
										</Typography>
									</Toolbar>
								}
								actions={
									<DialogActions
										view={SHOW_EDIT_VIEW}
										onCancel={() => {
											this.setState({ showAddWarehouse: false })
										}}
										onClose={() => {
											this.setState({ showAddWarehouse: false })
										}}
										onSaveItem={() => this.onCreateWarehouse()}
										isSaving={this.state.isSavingWarehouse && this.state.name !== ''}
										canBeSaved={this.canBeSavedStock()}
									/>
								}
							>
								<form className={classes.ediitForm}>
									<TextField
										fullWidth
										autoFocus
										className={classes.textField}
										label={<Translate id='companies.nameWarehouse' />}
										defaultValue={this.state.name}
										onChange={(event) => {
											this.updatePropertyStock(event.target.value)
										}}
									/>
								</form>
							</Dialog>

							{/** Dialog to Unlocked user */}
							<AddCommonItem
								title={<Translate id='companies.removeWarehouse' />}
								message={<Translate id='companies.messageRemove' />}
								open={this.state.openRemoveItem}
								onClose={() => {
									this.setState({ openRemoveItem: false })
								}}
								onSaveItem={this.onRemoveWarehouse}
								isSaving={this.props.isSavingWarehouse}
								isMessage={true}
							/>
						</div>
					)}

					{this.state.activeTab === 3 && (
						<div className={classes.containerOptionsMenu}>
							<EvaluationSupplier
								sections={this.props.evaluationRating}
								toggleActiveMenu={this.toggleActiveMenu}
							/>
						</div>
					)}
					{this.state.activeTab === 4 && (
						<div className={classes.containerWarehouse}>
							{this.getPrivacyNoticeContent()}

							{/** Dialog to add privacy notice */}
							<AddPrivacy
								text={this.props.translate('dropzone.dropzoneBaseText')}
								title={'common.privacyNotice'}
								isPrivacyNotice={true}
								open={this.state.openAddPrivacyModal}
								onClose={() => {
									this.handleShowAddDialog(false)
								}}
								onAddManual={this.onAddPrivacyNotice}
								isSaving={this.props.isSavingPrivacyNotice}
								user={this.props.currentUser}
							/>

							{/** Dialog to delete privacy notice */}
							<AddCommonItem
								title={<Translate id='companies.removePrivacyNotice' />}
								message={<Translate id='companies.messageToRemovePrivacyNotice' />}
								open={this.state.openRemoveItem}
								onClose={() => {
									this.setState({ openRemoveItem: false })
								}}
								onSaveItem={this.onRemovePrivacyNotice}
								isSaving={this.props.isSavingPrivacyNotice}
								isMessage={true}
							/>
						</div>
					)}
					{this.state.showChangeLogoDialog ? (
						<ChangeLogo
							open={this.state.showChangeLogoDialog}
							fileBase64={this.state.logoFileBase64}
							onClose={() => {
								this.setState({ showChangeLogoDialog: false })
							}}
						/>
					) : null}

					<Toaster
						message={this.state.toasterMessage}
						open={this.state.showToaster}
						variant={this.state.toasterVariant}
						onClose={() => {
							this.setState({ showToaster: false })
						}}
					/>
				</Paper>
			)
		}
	}
}

const tableConfiguration = {
	columns: [
		{
			header: <Translate id='companies.nameWarehouse' />,
			dataSource: 'name',
		},
		{
			header: '',
			dataSource: 'token',
		},
	],
}
const privacyTableConfiguration = {
	columns: [
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
		},
		{
			header: <Translate id='common.privacyNotice' />,
			dataSource: 'name',
		},
		{
			header: <Translate id='deliveryOrderModule.materialsTable.version' />,
			dataSource: 'PrivacyVersion',
		},
		{
			header: <Translate id='companies.changePrivacyLanguage' />,
			dataSource: 'LanguageSelected',
		},

		{
			header: <Translate id='common.active' />,
			dataSource: 'isActive',
		},
		{
			header: '',
			dataSource: 'token',
		},
	],
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		company: state.companies.get('company') ? state.companies.get('company').toJS() : null,
		companyModules: state.companies.get('company')
			? state.companies.get('company').get('company_modules').toJS()
			: null,
		isLoadingCompany: state.companies.get('isLoadingCompany'),
		isSavingCompany: state.companies.get('isSavingCompany'),
		isSavingWarehouse: state.companies.get('isSavingWarehouse'),
		privacyNotices: state.companies.get('privacyNotices') ? state.companies.get('privacyNotices').toJS() : null,
		isLoadingPrivacyNotice: state.companies.get('isLoadingPrivacyNotice'),
		isSavingPrivacyNotice: state.companies.get('isSavingPrivacyNotice'),
		isLoadingEvaluation: state.ratings.get('isLoadingEvaluation'),
		evaluationRating: state.ratings.get('evaluationRating') ? state.ratings.get('evaluationRating').toJS() : null,
		searchQuery: state.companies.get('searchQuery'),
		sortCriteria: state.companies.get('sortCriteria') ? state.companies.get('sortCriteria').toJS() : null,
		translate: getTranslate(state.localize),
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadCompany: (token) => {
			return dispatch(loadCompany(token))
		},
		updateCompanyData: (company) => {
			return dispatch(updateCompanyData(company))
		},
		onCreateWarehouse: (tokenCompany, name) => {
			return dispatch(addWarehousesInCompany(tokenCompany, name))
		},
		onChangeActive: (tokenCompany, data) => {
			return dispatch(addCompanyModule(tokenCompany, data))
		},
		onRemoveWarehouse: (tokenCompany, tokenWarehouse) => {
			return dispatch(onRemoveWarehouse(tokenCompany, tokenWarehouse))
		},
		loadEvaluationSuppliersRatingByCompany: (companyToken) => {
			return dispatch(loadEvaluationSuppliersRatingByCompanyFromBackEnd(companyToken))
		},
		loadPrivacyNotices: (query, orderBy, sortAscending, companyToken) => {
			dispatch(getAllPrivacyNotice(query, orderBy, sortAscending, companyToken))
		},
		createPrivacyNoticeData: (privacyNotice, companyToken) => {
			return dispatch(createPrivacyNoticeData(privacyNotice, companyToken))
		},
		getActivePrivacyNotice: (companyToken, localStorage) => {
			return dispatch(getActivePrivacyNotice(companyToken, localStorage))
		},
		removePrivacyNotice: (privacyToken) => {
			return dispatch(removePrivacyNotice(privacyToken))
		},
	}
}

const viewStyles = (theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: '80px',
		paddingRight: '80px',
	},
	containerWarehouse: {
		paddingLeft: '80px',
		paddingRight: '80px',
	},
	containerOptionsMenu: {
		paddingLeft: '80px',
		paddingRight: '80px',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 440,
		marginTop: 10,
		marginBottom: 10,
	},
	dense: {
		marginTop: 19,
	},
	menu: {
		width: 200,
	},
	footerForm: {
		display: 'flex',
		paddingRight: '40px',
	},
	styleTab: {
		backgroundColor: '#fff',
	},
	logoFileInput: {
		display: 'none',
	},
	grow: {
		flexGrow: 1,
	},
	toolBar: {
		backgroundColor: 'red',
	},
	logoCompany: {
		backgroundColor: theme.palette.secondary.main,
		margin: 10,
		width: 80,
		height: 80,
	},
	edit: {
		margin: 10,
		backgroundColor: theme.palette.text.hint,
	},
	activeChip: {
		backgroundColor: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
