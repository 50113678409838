/**Import react section */
import React from "react";
import PropTypes from "prop-types";

/**Import components section*/
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

/**Import material UI Section*/
import { Translate } from "react-localize-redux";
import Root from "../../../../store/Root";

/**Import resources section*/
import { COLOR_STATUS_SUCCESS } from "../../../../store/helpers/StatusColorConstants";
import GoldTrophyResultImberMX from "../../../../resources/images/GoldTrophyResult.png";
import GoldTrophyResultRepare from "../../../../resources/images/GoldTrophyResultRepare.png";
import {
  COMPANY_REPARE,
  COMPANY_IMBERA,
} from "../../../../store/helpers/ResourcesHelper";
const ResultInformation = (props) => {
  const { sustainability } = props;
  console.log("sustainability=>>>", sustainability)

  const onGetResulWinnerLogo = () => {
    switch (sustainability ? sustainability.CompanyCode : "") {
      case COMPANY_REPARE: {
        return GoldTrophyResultRepare
      }
      case COMPANY_IMBERA: {
        return GoldTrophyResultImberMX
      }
      default:
        return GoldTrophyResultImberMX
    }
  };
  return (
    <Root>
      <View style={styles.page}>
        <Image style={styles.image} src={onGetResulWinnerLogo()} />
        <View>
          <Text style={styles.textRecognition}>
            {<Translate id="sustainability.PdfResult.recognition" />}
          </Text>
          <Text style={styles.textSustainable}>
            {<Translate id="sustainability.PdfResult.merit" />}
          </Text>
          <Text
            style={styles.supplier}
          >{`${sustainability.SupplierCode} | ${sustainability.SupplierName}`}</Text>
        </View>
        <View style={styles.DividerLineBlue} />
        <View>
          <Text style={styles.textInformation}>
            {<Translate id="sustainability.PdfResult.implementation" />}
          </Text>
          <Text style={styles.textInvitation}>
            {<Translate id="sustainability.PdfResult.invitation" />}
          </Text>
        </View>
        <View>
          <Text style={styles.textCongratulation}>
            {<Translate id="sustainability.PdfResult.congratulation" />}
          </Text>
        </View>
      </View>
    </Root>
  );
};

// Create styles
const styles = StyleSheet.create({
  root: {
    display: "flex",
    width: "100%",
    height: "auto",
    fontSize: 12,
  },
  page: {
    textAlign: "center",
  },
  image: {
    marginLeft: "110px",
    marginTop: "30px",
    marginBottom: "30px",
    textAlign: "center",
    width: "60%",
    height: "42%",
  },
  DividerLineBlue: {
    height: 4,
    backgroundColor: COLOR_STATUS_SUCCESS,
    marginLeft: "170px",
    marginRight: "170px",
    marginTop: "10px",
    marginBottom: "25px",
  },
  textRecognition: {
    fontWeight: "bold",
    fontSize: "28",
    color: COLOR_STATUS_SUCCESS,
  },
  textSustainable: {
    fontWeight: "bold",
    fontSize: "28",
  },
  textInformation: {
    fontWeight: "bold",
    fontSize: "20",
    marginRight: "40",
    marginLeft: "40",
    marginTop: "20",
  },
  textInvitation: {
    width: "300",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    fontSize: "20",
    marginRight: "40",
    marginLeft: "40",
    marginTop: "25",
  },
  textCongratulation: {
    fontSize: "20",
    color: COLOR_STATUS_SUCCESS,
    marginTop: "35",
  },
  supplier: {
    fontSize: "18",
    marginTop: "20",
  },
});

ResultInformation.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  isLoading: PropTypes.bool.isRequired,
};

ResultInformation.defaultProps = {
  isLoading: false,
};

export default ResultInformation;
