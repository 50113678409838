import React, { Component } from 'react';
import { connect } from 'react-redux'
import autoBind from 'auto-bind';
import {Translate} from "react-localize-redux";
import Select from 'react-select'

import { searchByUsersQualityByQuery } from '../../store/administration/WarehouseWorkersActions';


/**
 * Prepared By selector container
 *
 * @class PreparedBySelector
 * @extends {Component}
 */
class PreparedBySelector extends Component {

    /**
     *Creates an instance of PreparedBySelector.
     * @param {*} props
     * @memberof PreparedBySelector
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            isLoading: true,
            usersQuality: [],
            prepareBy: this.props.prepareBy,
        }
    }

      /**
     * componentDidMount
     */
    componentDidMount() {
        if (!this.props.isLoadingUser) {
            this.props.searchByUsersQuality()
            .then((usersQuality) => {
                let users = usersQuality.map((userQulity) =>{
                    return {
                        value: userQulity.token,
                        label: userQulity.name
                    }
                })
                this.setState({
                    usersQuality:users
                })
                return usersQuality.map((user) => {
                    return {
                        value: user.token,
                        label: user.name
                    }
                })
            })
        }
    }

    /**
     * On select a PreparedBySelector 
     *
     * @param {*} option
     * @memberof PreparedBySelector
     */
    onSelectedUsersQualityClick(option) {
        if(this.state.usersQuality){
            let selectedUser = this.state.usersQuality.find((user) => {
                return user.value === option.value
            })
            if (this.props.onUsersQualitySelected) {
                this.props.onUsersQualitySelected(selectedUser)
            }
        }
    }
  
    /**
     *  
     *
     * @returns
     * @memberof PreparedBySelector
     */
    render() {
        return (
            <Select 
                placeholder={<Translate id="common.searchBy" />}
                defaultValue={this.state.prepareBy}
                options={this.state.usersQuality}
                onChange={this.onSelectedUsersQualityClick}
                isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
                styles={{
                    menu: (base) => ({ ...base, zIndex: 10 })
                  }}
            />
        );
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    return {
        isLoadingUser: state.oidc.isLoadingUser,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        searchByUsersQuality:(query) => {
            return dispatch(searchByUsersQualityByQuery(query))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreparedBySelector);