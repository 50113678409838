import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'auto-bind';
import { List, ListItem, ListItemText, TextField, ListSubheader, Grid, withStyles, Typography, Tooltip, IconButton } from '@material-ui/core';
import { BACKGROUND_COLOR_GRAY_DARK, COLOR_STATUS_DANGER, COLOR_STATUS_BLUE } from '../../../store/helpers/StatusColorConstants';
import { onKeyDown } from '../../../store/helpers/EventsHelper';
import { Translate } from 'react-localize-redux';
import NoIcon from '@material-ui/icons/Clear';
import YesIcon from '@material-ui/icons/Check';

/**
 * Class component to show question section
 *
 * @class QuestionsList
 * @extends {PureComponent}
 */
class QuestionsList extends PureComponent {

    /**
     *Creates an instance of QuestionsList.
     * @param {*} props
     * @memberof QuestionsList
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
        };
    };

    /**
     * Method to render buttop option
     *
     * @param {*} title
     * @param {*} optionValue
     * @param {*} key
     * @param {*} item
     * @param {*} listName
     * @param {*} isDisabled
     * @returns
     * @memberof QuestionsList
     */
    renderOptionButton(title, optionValue, key, item, listName, isDisabled) {
        let xsButton = {
            maxWidth: 25, maxHeight: 25,
            paddingTop: 5,
            cursor: isDisabled ? 'context-menu' : 'pointer',
            backgroundColor: item[key] === optionValue ? COLOR_STATUS_BLUE : BACKGROUND_COLOR_GRAY_DARK
        };
        let fontColor = item[key] === optionValue ? "#fff" : "#000";
     
        return (
            <Grid item >
                <Tooltip title={this.props.translate(title)}>
                     <IconButton
                        aria-label="More"
                        value={optionValue}
                        style={xsButton}
                         onClick={(event) => !isDisabled && this.props.updateProperty(key, event.currentTarget.value, item, listName)}
                    >
                        {optionValue === "1" && <YesIcon style={{ width: '.7em', height: '.7em', color: fontColor }} />}
                        {optionValue === "2" && <NoIcon style={{ width: '.7em', height: '.7em', color: fontColor }} />}
                        {optionValue === "3" && <Typography style={{ fontSize: '.5em', color: fontColor }}>{"N/A"}</Typography>}
                    </IconButton>
                    
                </Tooltip>
            </Grid>
        )
    }

    /**
     * Method to render section of options
     *
     * @param {*} key
     * @param {*} item
     * @param {*} listName
     * @param {*} isDisabled
     * @param {*} size
     * @returns
     * @memberof QuestionsList
     */
    renderOptionsButtons(key, item, listName, isDisabled, size) {
        return (
            <Grid item xs={12} sm={12} md={size} lg={size} xl={size}>
                {!isDisabled &&
                    <Grid container spacing={0} justify="space-evenly">
                        {this.renderOptionButton("sustainability.options.1", "1", key, item, listName, isDisabled)}
                        {this.renderOptionButton("sustainability.options.2", "2", key, item, listName, isDisabled)}
                        {this.renderOptionButton("sustainability.options.3", "3", key, item, listName, isDisabled)}
                    </Grid>
                }
                {isDisabled &&
                    <Typography align="center" variant="caption">
                        {(item[key] === null || item[key] === 0) && <Translate id="common.withoutData" />}
                        {(item[key] && (item[key] !== null && item[key] !== 0)) &&
                            this.renderOptionButton(`sustainability.options.${item[key]}`, item[key], key, item, listName, true)}
                    </Typography>
                }
            </Grid >
        )
    }

    /**
     * Method to render Text Field input
     *
     * @param {*} key
     * @param {*} item
     * @param {*} listName
     * @param {*} isDisabled
     * @returns
     * @memberof QuestionsList
     */
    renderTextArea(key, item, listName, isDisabled) {
        return (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {!isDisabled &&
                    <TextField
                        autoFocus={key === 'name'}
                        key={key}
                        fullWidth
                        defaultValue={item[key] ? item[key] : ''}
                        onBlur={(event) => { this.props.updateProperty(key, event.target.value, item, listName) }}
                        onKeyDown={(event) => onKeyDown(event)}
                        margin="dense"
                        disabled={isDisabled}
                    />}
                {isDisabled &&
                    <Typography align="center" variant="caption">
                        {item[key] ? item[key] : <Translate id="common.withoutData" />}
                    </Typography>
                }
            </Grid>
        )
    }

    /**
     * Method to render Title section
     *
     * @param {*} translationId
     * @param {*} size
     * @returns
     * @memberof QuestionsList
     */
    renderTitleSection(translationId, size) {
        return (
            <Grid item xs={12} sm={12} md={size} lg={size} xl={size}>
                <Typography align="center" variant="caption">
                    <Translate id={translationId} />
                </Typography>
            </Grid>
        )
    }

    /**
     * Main method to render QuestionsList
     *
     * @returns
     * @memberof QuestionsList
     */
    render() {
        const { questions, listTitle, listPropertyName, canEditCompany, canEditSupplier } = this.props;
        let sizeSupplierResponse = (this.props.isDisabledQuestionMandatory) ? 3 : 6;
        return (
            <List key={listPropertyName} dense
                subheader={
                    <ListSubheader sticky={true}
                        color="default"
                        style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}
                    >
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                {listTitle}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <Grid container spacing={0} justify="flex-end" alignItems="center">
                                    {this.renderTitleSection("sustainability.supplierAnswer", sizeSupplierResponse)}
                                    {this.props.isDisabledQuestionMandatory && this.renderTitleSection("sustainability.internalAnswer", 3)}
                                    {this.props.isDisabledQuestionMandatory && this.renderTitleSection("common.observations", 6)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListSubheader>
                }>
                {questions.map(question => {
                    return (<ListItem key={question.token} dense divider>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <ListItemText>
                                    {question.description}{question.is_mandatory_question && this.props.isDisabledQuestionMandatory &&
                                        <span style={{ color: COLOR_STATUS_DANGER }}><strong> * </strong></span>}
                                </ListItemText>

                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <Grid container spacing={16} justify="flex-end">
                                    {this.renderOptionsButtons("supplier_response", question, listPropertyName, !canEditSupplier, sizeSupplierResponse)}
                                    {this.props.isDisabledQuestionMandatory && this.renderOptionsButtons("plant_response", question, listPropertyName, !canEditCompany, 3)}
                                    {this.props.isDisabledQuestionMandatory && this.renderTextArea("observations_plant", question, listPropertyName, !canEditCompany)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>)
                })}
            </List>
        );
    }
}

QuestionsList.propTypes = {
    listTitle: PropTypes.string,
    listPropertyName: PropTypes.string.isRequired,
    questions: PropTypes.array.isRequired,
    canEditCompany: PropTypes.bool,
    canEditSupplier: PropTypes.bool,
    translate: PropTypes.func,
};

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
    },
});

export default withStyles(styles, { withTheme: true })(QuestionsList);