/**Import react section */
import React, { useState } from 'react';
import propTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import Select from 'react-select'

/** Language imports **/
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

/** Import component section */
import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';
import { GetColorInSectionType, getSelectedValue } from '../../../../store/helpers/SelectOptions';

const CardCreateTemplate = (props) => {
    /**props section */
    const { open,onClose,
        isSaving, classes,
        onSaveItem, itemSelected,
        isEdit, onCloseEdit, 
        onSaveItemEdit, topicId
    } = props
    
    const colorType = GetColorInSectionType();

    const [itemModel, setItemModel] = useState(itemSelected ? {
        topicId: topicId,
        name: itemSelected.Name,
        shortName: itemSelected.ShortName,
        color: itemSelected.ColorTopic
    } : {
        name: '',
        shortName: '',
        color: {}
    })
    
    /**
    * Handle update properties to set data in local state
    *
    * @param {*} property
    * @param {*} data
    * @memberof AddModal
    */
    const updateProperty = event => {
        let { name, value } = event.target;
        setItemModel({
            ...itemModel,
            [name]: value
        });
    }

    /**
    * On change color
    * @param {*} dataDelivery 
    */
     const onPropertyColor = (colorSelected) => {
        setItemModel({
            ...itemModel,
            "color": colorSelected
        });
    }

    /**
    * Function on save update
    */
    const onSaveItemUpdate = () =>{
        if(props.onSaveItem)
        onSaveItem(itemModel);
    }

    /**
    * Function on save update
    */
     const onSaveItemEditTemplate = () =>{
        if(props.onSaveItemEdit)
        onSaveItemEdit(itemModel);
    }

    /**
    * Method to validate if required properties has data
    *
    * @returns
    * @memberof AddModal
    */
    const isValidToSave = () => {
        if(IsNullOrEmpty(itemModel.name))
            return false;
        if (IsNullOrEmpty(itemModel.shortName))
            return false;
        if(IsNullOrEmpty(itemModel.color))
            return false;
        else
            return true;
    }

    return (
        <Dialog
            open = {open}
            onClose= {isEdit ? onCloseEdit : onClose}
            header={
                <Toolbar >
                    <Typography variant="h6">
                        {isEdit ? <Translate id="questionnaire.editTopic"/> : <Translate id="questionnaire.addTopic"/>}
                    </Typography>
                </Toolbar>
            }
            actions={
                <div>
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={isEdit ? onCloseEdit : onClose}
                        onClose={isEdit ? onCloseEdit : onClose}
                        onSaveItem={isEdit ? onSaveItemEditTemplate : onSaveItemUpdate}
                        isSaving={isSaving}
                        canBeSaved={isValidToSave() || isSaving}
                    />
                </div>
            }>
            <Grid container className={classes.modal} justify='space-between'>
                    <TextField
                        name={"shortName"}
                        label={<Translate id="questionnaire.shortNameTopic"/>}
                        defaultValue={itemModel.shortName}
                        size="small" 
                        margin="dense"
                        type={"text"}
                        fullWidth
                        required={true}
                        onChange={updateProperty}
                        variant={'outlined'}
                    /> <br/>
                    <TextField
                        name="name"
                        label={<Translate id="questionnaire.nameTopic"/>}
                        defaultValue={itemModel.name}
                        size="small" 
                        margin="dense"
                        type={"text"}
                        fullWidth
                        required={true}
                        onChange={updateProperty}
                        variant={'outlined'}
                    />
                    <br/><Grid item xs={12}>
                        <Typography variant="caption" color={'textSecondary'}><Translate id="questionnaire.colorSection"/></Typography>
                        <Select
                            name={'color'}
                            value={getSelectedValue(colorType, itemModel.color)}
                            className={classes.documentTypeSelect}
                            options={colorType}
                            onChange={onPropertyColor}
                            styles={{
                                menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                                menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.parentNode}
                            menuPosition="fixed"
                            menuPlacement={'auto'}
                        />
                    </Grid><br/>
                </Grid>
        </Dialog>
    )
}

const styles = theme => ({
    modal: {
        marginRight: theme.spacing.unit * 2,
    }
});

CardCreateTemplate.propTypes = {
    /** Close card */
    onClose: propTypes.func,
    onCloseEdit: propTypes.func,
    onSaveItem: propTypes.func,
    onSaveItemEdit: propTypes.func,
    topicId: propTypes.string,
    /**
    * Determinate if is saving data
    */
    isSaving: propTypes.bool.isRequired,
    itemSelected: propTypes.object,
    isEdit: propTypes.bool,
}

CardCreateTemplate.defaultProps = {
    onClose: () =>
        console.warn('Callback [onClose] no defined'),
    onCloseEdit: () =>
        console.warn('Callback [onCloseEdit] no defined'),
    onSaveItem: () =>
        console.warn('Callback [onSaveItem] no defined'),
    onSaveItemEdit: () =>
        console.warn('Callback [onSaveItemEdit] no defined'),
    isSaving: false,
    itemSelected: {},
    isEdit: false,
    topicId: '',
}

export default withRouter(withStyles(styles, { withTheme: true })(CardCreateTemplate))