import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import moment from 'moment'

/** Material UI import section */
import { withStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography, Toolbar, TextField, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

/** Custom components import section */
import Dialog from '../../common/Dialog'
import Evidence from '../../common/Evidence'
import DialogActions from '../../common/DialogActions'
import DatePicker from '../../common/DateTimePicker/DatePicker'
import HourPickers from '../../transportTender/common/HourPickers'
import WarningComponent from '../../common/WarningComponent'
import VehicleSelect from './VehicleSelect'
import Toaster from '../../common/Toaster'

/** Redux import section*/
import { useDispatch, useSelector } from 'react-redux'
import { loadVehicles, setToasterOptions } from '../../../store/controlCO2/controlCO2Action'
import { getVehiclesSelector } from '../../../store/controlCO2/controlCO2Selectors'

/** Resources import section */
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'

const buildVehicleOption = (vehicle) => ({ value: vehicle.token, label: `${vehicle.economicNumber}` })

const AddControlModal = (props) => {
	const { open, onClose, onAddRequest, isBlocked } = props
	const toasterConfiguration = useSelector((state) => state.controlCO2.get('toasterConfiguration').toJS())
	const isSaving = useSelector((state) => state.controlCO2.get('isSaving'))

	const [control, setcontrol] = useState({
		destination: '',
		km: 0,
		carbonResult: '',
		cleaningCheck: '',
		observations: '',
		gasoline: 'NOK',
		oil: 'NOK',
		water: 'NOK',
		tires: 'NOK',
		isLicenseVerified: 'NOK',
		checkOutDate: moment().format('L'),
		checkInDate: moment().format('L'),
		attachments: [],
		vehicleToken: '',
	})

	/** Connect with redux */
	const dispatch = useDispatch()
	const allVehicles = useSelector((state) => getVehiclesSelector(state))
	const vehicleOptions = allVehicles.map((vehicle) => buildVehicleOption(vehicle))

	/** Load vehicles data */
	useEffect(() => {
		dispatch(loadVehicles())
	}, [dispatch])

	const removeFiles = (fileName) => {
		let newControl = control
		let newAttachments = control.attachments.filter((file) => {
			return file.name !== fileName
		})
		newControl.attachments = newAttachments
		setcontrol(newControl)
		return Promise.resolve('Ok')
	}

	const uploadFiles = (files) => {
		let filesList = control
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.attachments.push(files[index])
			}
		}
		setcontrol(filesList)

		return Promise.resolve('Ok')
	}
	const updateProperty = (property, value) => {
		let controlUpdate = { ...control }
		controlUpdate[property] = value
		if (property === 'km') controlUpdate['carbonResult'] = 0
		if (property === 'checkOutTime') {
			var day = moment(control.checkOutDate, 'DD/MM/YYYY')
			var splitTime = value.split(/:/)
			day.hours(parseInt(splitTime[0])).minutes(parseInt(splitTime[1])).seconds(0).milliseconds(0)
			controlUpdate['checkOutDate'] = day
		}
		if (property === 'checkInTime') {
			var dayIn = moment(control.checkInDate, 'DD/MM/YYYY')
			var splitTimeIn = value.split(/:/)
			dayIn.hours(parseInt(splitTimeIn[0])).minutes(parseInt(splitTimeIn[1])).seconds(0).milliseconds(0)
			controlUpdate['checkInDate'] = dayIn
		}
		setcontrol(controlUpdate)
	}

	const onAddRequestControl = () => {
		onAddRequest(control).then(() => {
			onClose()
			setcontrol({
				destination: '',
				km: 0,
				carbonResult: '',
				cleaningCheck: '',
				observations: '',
				gasoline: 'NOK',
				oil: 'NOK',
				water: 'NOK',
				tires: 'NOK',
				isLicenseVerified: 'NOK',
				checkOutDate: moment().format('L'),
				checkInDate: moment().add(1, 'days').format('L'),
				attachments: [],
				vehicleToken: '',
			})
		})
	}

	const canBeSaved = () => {
		if (isSaving || isBlocked) return false
		else {
			if (control.destination.length === 0) return false

			if (control.attachments.length <= 0) return false
		}
		return true
	}

	return (
		<Paper style={{ overflowX: 'hidden' }}>
			<Dialog
				style={{ overflowX: 'hidden' }}
				open={open}
				onClose={onClose}
				header={
					<Toolbar>
						<Typography variant='h6'>{<Translate id='controlCO2.addControl' />}</Typography>
					</Toolbar>
				}
				actions={
					<DialogActions
						view={SHOW_EDIT_VIEW}
						onCancel={onClose}
						onClose={onClose}
						onSaveItem={() => onAddRequestControl()}
						isSaving={isSaving}
						canBeSaved={canBeSaved()}
					/>
				}
			>
				{isBlocked && <Grid container style={{ width: 500 }} justify='space-between'>
					<WarningComponent message={<Translate id='controlCO2.blockMessage' />} />
				</Grid>}
				{!isBlocked && <Grid container style={{ width: 500 }} justify='space-between'>
					<Grid item xs={12}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							{<Translate id='controlCO2.destinationPlace' />}
						</Typography>
						<TextField
							fullWidth
							id='outlined-size-small'
							label={''}
							defaultValue={control.destination}
							onChange={(event) => {
								updateProperty('destination', event.target.value)
							}}
							variant='outlined'
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							{<Translate id='controlCO2.vehicle' />}
						</Typography>
						<VehicleSelect
							options={vehicleOptions}
							onOptionChange={(selectedOption) => {
								updateProperty('vehicleToken', selectedOption.value)
							}}
						/>
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={5}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							{<Translate id='controlCO2.kmInitial' />}
						</Typography> 
						<TextField
							size="small"
							fullWidth
							id="outlined-size-small"
							label={''}
							defaultValue={control.km}
							type="number"
							onChange={(event) => {
								updateProperty('km', event.target.value)
							}}
							variant='outlined'
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							{<Translate id='controlCO2.arrivalLevelsCheck' />}
						</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							Gasolina
						</Typography>
						<RadioGroup
							aria-labelledby='demo-error-radios'
							name='quiz'
							defaultValue={control.gasoline}
							style={{ marginLeft: '10px' }}
							onChange={(e) => {
								updateProperty('gasoline', e.target.value)
							}}
						>
							<FormControlLabel value={'OK'} control={<Radio />} label='OK' />
							<FormControlLabel value={'NOK'} control={<Radio />} label='NOK' />
						</RadioGroup>
					</Grid>
					<Grid item xs={2}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							Aceite
						</Typography>
						<RadioGroup
							aria-labelledby='demo-error-radios'
							name='quiz'
							defaultValue={control.oil}
							style={{ marginLeft: '10px' }}
							onChange={(e) => {
								updateProperty('oil', e.target.value)
							}}
						>
							<FormControlLabel value={'OK'} control={<Radio />} label='OK' />
							<FormControlLabel value={'NOK'} control={<Radio />} label='NOK' />
						</RadioGroup>
					</Grid>
					<Grid item xs={2}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							Agua
						</Typography>
						<RadioGroup
							aria-labelledby='demo-error-radios'
							name='quiz'
							defaultValue={control.water}
							style={{ marginLeft: '10px' }}
							onChange={(e) => {
								updateProperty('water', e.target.value)
							}}
						>
							<FormControlLabel value={'OK'} control={<Radio />} label='OK' />
							<FormControlLabel value={'NOK'} control={<Radio />} label='NOK' />
						</RadioGroup>
					</Grid>
					<Grid item xs={2}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							Llantas
						</Typography>
						<RadioGroup
							aria-labelledby='demo-error-radios'
							name='quiz'
							defaultValue={control.tires}
							style={{ marginLeft: '10px' }}
							onChange={(e) => {
								updateProperty('tires', e.target.value)
							}}
						>
							<FormControlLabel value={'OK'} control={<Radio />} label='OK' />
							<FormControlLabel value={'NOK'} control={<Radio />} label='NOK' />
						</RadioGroup>
					</Grid>
					<Grid item xs={3}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							¿Licencia vigente?
						</Typography>
						<RadioGroup
							aria-labelledby='demo-error-radios'
							name='quiz'
							defaultValue={control.gasoline}
							style={{ marginLeft: '10px' }}
							onChange={(e) => {
								updateProperty('isLicenseVerified', e.target.value)
							}}
						>
							<FormControlLabel value={'OK'} control={<Radio />} label='OK' />
							<FormControlLabel value={'NOK'} control={<Radio />} label='NOK' />
						</RadioGroup>
					</Grid>
					<Grid item xs={6}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							Fecha y Hora de salida
						</Typography>
						<DatePicker
							onChangeRange={(event) => updateProperty('checkOutDate', event)}
							minDate={moment()}
							startDate={control.checkOutDate ? moment(control.checkOutDate, 'DD/MM/YYYY') : ''}
						/>
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={5}>
						<div style={{ marginTop: '10px' }}>
							<HourPickers
								name='checkOutTime'
								value={control.checkOutTime ? control.checkOutTime : null}
								onChange={(event) => updateProperty(event.target.name, event.target.value)}
							/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							Fecha y Hora de entrega
						</Typography>
						<DatePicker
							onChangeRange={(event) => updateProperty('checkInDate', event)}
							minDate={moment().add(1, 'days')}
							startDate={control.checkInDate ? moment(control.checkInDate, 'DD/MM/YYYY') : ''}
						/>
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={5}>
						<div style={{ marginTop: '10px' }}>
							<HourPickers
								name='checkInTime'
								value={control.checkInTime ? control.checkInTime : null}
								onChange={(event) => updateProperty(event.target.name, event.target.value)}
							/>
						</div>
					</Grid>
					<Grid item xs={12}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							{<Translate id='controlCO2.cleaningCheck' />}
						</Typography>
						<TextField
							fullWidth
							id='outlined-multiline'
							label={''}
							defaultValue={control.Cleaningcheck}
							onChange={(event) => {
								updateProperty('cleaningCheck', event.target.value)
							}}
							variant='outlined'
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							{<Translate id='controlCO2.observations' />}
						</Typography>
						<TextField
							fullWidth
							id='outlined-multiline'
							label={''}
							defaultValue={control.observations}
							onChange={(event) => {
								updateProperty('observations', event.target.value)
							}}
							variant='outlined'
						/>
					</Grid>
					<Grid item xs={12}>
						<br />
						<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
							{<Translate id='controlCO2.leavingEvidence' />}
						</Typography>
						<Evidence
							baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
							removeFiles={removeFiles}
							uploadFiles={uploadFiles}
							files={control.attachments}
							acceptedFiles={['images', 'officeDocuments', 'pdf', 'otherFiles']}
							disabledLink
						/>
					</Grid>
				</Grid>}
			</Dialog>
			<Toaster
                message={<Translate id={toasterConfiguration.toasterMessage} />}
                open={toasterConfiguration.showToaster}
                variant={toasterConfiguration.toasterVariant}
                onClose={() => {
					dispatch(
						setToasterOptions({
							showToaster: false,
							toasterVariant: '',
							toasterMessage: '',
						})
					)
                }}
            />
		</Paper>
	)
}

const dialogStyles = (theme) => ({
	textField: {
		'&.MuiInputBase-root': {
			padding: '10px 14px',
		},
	},
})

AddControlModal.propTypes = {
	open: propTypes.bool, 
	onClose: propTypes.func, 
	onAddRequest: propTypes.func, 
	isBlocked: propTypes.func
}

AddControlModal.defaultProps = {
	open: false, 
	onClose: () =>
        console.warn('Callback [onClose] no defined'),
	onAddRequest: () =>
        console.warn('Callback [onAddRequest] no defined'),
	isBlocked: () =>
        console.warn('Callback [isBlocked] no defined'),
}

export default withStyles(dialogStyles, { withTheme: true })(AddControlModal)
