/** Import react section **/
import React from 'react';
/** Translation components */
import { LocalizeProvider } from "react-localize-redux";
import { addTranslation } from 'react-localize-redux';
import translations from '../resources/translations';
/** Import component section **/
/** Import helpers section **/
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { OidcProvider, loadUser } from 'redux-oidc';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { initialize } from 'react-localize-redux';
/** Import resources section **/
import rootReducer from './RootReducer';
import userManager from './helpers/UserManager';
/** Theme definition section */
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import themeContent from '../resources/themes/theme';
import themeContent from '../resources/themes/themeCommon.json';
const appTheme = createMuiTheme(themeContent);
/**
 * Create proposal store and apply middleware.
 * @type {Store<Action>}
 */
export let store;
store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
/**
 * Here we define our supported languages.
 * @type {(
 * {code: string, name: string}|
 * {code: string, name: string}|
 * {code: string, name: string})
 * []}
 */
const languages = [
	{ name: 'Español Mx',    code: 'es_mx' },
	{ name: 'English',    code: 'en' },
	{ name: 'Portugues',  code: 'pt' },
	{ name: 'Español Col',    code: 'es_cl' },
];
let defaultLanguage = localStorage.getItem("language");

if (!defaultLanguage) {
	defaultLanguage = 'es_mx';
}
/**
 * Once we defined, we need to initialize and pass it to redux store.
 * Then we enable or default language and some properties.
 */
store.dispatch(initialize({
		languages,
		options: {
			renderToStaticMarkup: false,
			renderInnerHtml: true,
			defaultLanguage: defaultLanguage,
			// onMissingTranslation
		}
	}
));
/**
 * Here we load our translations file
 */
store.dispatch(addTranslation(translations));
loadUser(store, userManager).then(() => {
});
store.dispatch(
	initialize({
		languages,
		options: {
			renderToStaticMarkup: false,
			renderInnerHtml: true,
			defaultLanguage: defaultLanguage
		}
	})
);
store.dispatch(addTranslation(translations));
export default (props) => (
	<MuiThemeProvider theme={appTheme}>
		<Provider store={store}>
			<OidcProvider store={store} userManager={userManager}>
				<LocalizeProvider store={store} >
					{ props.children }
				</LocalizeProvider>
			</OidcProvider>
		</Provider>
	</MuiThemeProvider>
);