import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

/** Material UI import section */
import {
	Avatar,
	Chip,
	Collapse,
	Divider,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
	withStyles
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';

const WorkshopItem = ({ name = '', sapCode = '', technicians = [], classes }) => {

	const [techniciansDisplayed, setTechniciansDisplayed] = useState(false);

	const handleClick = () => {
		setTechniciansDisplayed(!techniciansDisplayed);
	};

	return (
		<>
			<ListItem button onClick={handleClick}>
				<ListItemAvatar>
					<Avatar>
						<BuildTwoToneIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={name} secondary={sapCode} />
				<ListItemSecondaryAction>
					<IconButton>
						{
							{
								true: <ExpandLess />,
								false: <ExpandMore />
							}[techniciansDisplayed]
						}
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
			<Collapse in={techniciansDisplayed} timeout="auto" unmountOnExit>
				<div className={classes.techniciansContainer}>
					<Typography>
						<Translate id="accreditedWorkshops.techniciansIndex.technicians" />:{' '}
					</Typography>
					{{
                        true: <Typography><Translate id="accreditedWorkshops.techniciansIndex.noTechnicians" /></Typography>,
                        false: technicians.map((code) => (
						<Chip key={code} variant="outlined" size="small" label={code} />
					))}[technicians.length === 0]}
				</div>
			</Collapse>
            <Divider />
		</>
	);
};

WorkshopItem.propTypes = {
	name: PropTypes.string.isRequired,
	sapCode: PropTypes.string.isRequired,
	technicians: PropTypes.arrayOf(PropTypes.string)
};

const styles = (theme) => ({
	techniciansContainer: {
		padding: theme.spacing.unit * 2,
		display: 'flex',
		alignItems: 'center',
		'& > *': {
			marginLeft: theme.spacing.unit * 2
		}
	}
});

export default withStyles(styles, { withTheme: true })(WorkshopItem);
