import React, { Component } from 'react';
import { connect } from 'react-redux'
import autoBind from 'auto-bind';
import {Translate} from "react-localize-redux";
import Select from 'react-select'

import { searchTransportDestinationByQuery } from '../../../store/planning/TransportDestinationAction';


/**
 * Destination selector container
 *
 * @class DestinationSelector
 * @extends {Component}
 */
class DestinationSelector extends Component {

    /**
     *Creates an instance of DestinationSelector.
     * @param {*} props
     * @memberof DestinationSelector
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            isLoading: true,
            destinations: [],
            destination: this.props.destination
        }
    }

          /**
     * componentDidMount
     */
    componentDidMount() {
        if (!this.props.isLoadingUser) {
            this.props.searchTransportDestinationByQuery()
            .then((allDestinations) => {
                let destinations = allDestinations.map((destination) =>{
                    return {
                        value: destination.token,
                        label: destination.destination
                    }
                })
                this.setState({
                    destinations:destinations
                })
                return destinations.map((destination) => {
                    return {
                        value: destination.token,
                        label: destination.destination
                    }
                })
            })
        }
    }
  
    /**
     * On select a DestinationSelector 
     *
     * @param {*} option
     * @memberof DestinationSelector
     */
    onSelectedDestinationsClick(option) {
        if(this.state.destinations){
            let selectedDestination = this.state.destinations.find((destination) => {
                return destination.value === option.value
            })
            if (this.props.onDestinationSelected) {
                this.props.onDestinationSelected(selectedDestination)
            }
        }
    }
  
    /**
     *  
     *
     * @returns
     * @memberof DestinationSelector
     */
    render() {
        return (
            <Select 
                placeholder={<Translate id="deliveryOrderModule.carbonFootprint.searchTranpsort" />}
                defaultValue={this.state.destination}
                options={this.state.destinations}
                onChange={this.onSelectedDestinationsClick}
                isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
                styles={{
                    menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                    menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                }}
            />
        );
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    return {
        isLoadingUser: state.oidc.isLoadingUser,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        searchTransportDestinationByQuery:() => {
            return dispatch(searchTransportDestinationByQuery())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DestinationSelector);