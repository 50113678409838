import React, { useCallback, useEffect, useState } from 'react'
import { Translate, getTranslate } from 'react-localize-redux'
import moment from 'moment'

/** Material-UI import section*/
import { Avatar, Card, CardContent, CardHeader, Tooltip, Typography, withStyles, Fab } from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'
import AddIcon from '@material-ui/icons/AddTwoTone'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'

/** Redux import section*/
import { useDispatch, useSelector } from 'react-redux'
import { deleteService, loadServices, loadVehicles } from '../../store/controlCO2/controlCO2Action'
import { getServicesSelector } from '../../store/controlCO2/controlCO2Selectors'

/** Custom components import section*/
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import LateralPanelContainer from '../common/LateralPanelComponents/LateralPanelContainer'
import ViewServiceComponent from './components/ViewService'
import AddCommonItem from '../common/AddCommonItem'
import AddServiceModal from './components/AddServiceModal'

/** Resources import section */
import { ServicesTableConfiguration } from './helpers/Services.helper'
import { converListToArray } from '../../store/helpers/ListHelper'
import { ROLE_CO2_COTROL_SERVICE_EDITOR, IsUserInRole } from '../../store/helpers/RolesHelper'

const VehiclesServices = (props) => {
	const { classes } = props


	const [loading, setLoading] = useState(true)

	/** Connect with redux */
	const dispatch = useDispatch()
	const allServices = useSelector((state) => getServicesSelector(state))
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)

	const translate = useSelector((state) => getTranslate(state.localize))
	const [identifierPanelLateral, setIdentifierPanelLateral] = useState('')
	const [modify, setModify] = useState(false)
	const [selectedItem, setSelectedItem] = useState()
	const [openAddModal, setOpenAddModal] = useState(false)
	const [newService, setNewService] = useState({
		lastVerificationDate: moment().format('YYYY-MM-DD'),
		lastDateOfService: moment().format('YYYY-MM-DD'),
		lastHoldingDate: moment().format('YYYY-MM-DD'),
		vehicleToken: '',
		originOfTransportation: '',
		TypeOfTransportation: '',
		FireExtinguisherInspection: moment().format('YYYY-MM-DD'),
		LastVerificationDateAttachments: [],
		lastDateOfServiceAttachments: [],
		lastHoldingDateAttachments: [],
		otherAttachments: [],
	})
	const [deletingServicePanelConfig, setDeletingServicePanelConfig] = useState({
		opened: false,
		deleting: false,
		serviceToken: null,
	})
	

	const modifyService = () => {
		setModify(!modify)
	}

	const removefilesLastVerificationDate = (fileName) => {
		let newControl = newService
		let newAttachments = newService.LastVerificationDateAttachments.filter((file) => {
			return file.name !== fileName
		})
		newControl.LastVerificationDateAttachments = newAttachments
		setNewService(newControl)
		return Promise.resolve('Ok')
	}

	const uploadFilesLastVerificationDate = (files) => {
		let filesList = newService
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.LastVerificationDateAttachments.push(files[index])
			}
		}
		setNewService(filesList)

		return Promise.resolve('Ok')
	}

	const removeFilesLastHoldingDate = (fileName) => {
		let newControl = newService
		let newAttachments = newService.lastHoldingDateAttachments.filter((file) => {
			return file.name !== fileName
		})
		newControl.lastHoldingDateAttachments = newAttachments
		setNewService(newControl)
		return Promise.resolve('Ok')
	}

	const uploadFilesLastHoldingDate = (files) => {
		let filesList = newService
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.lastHoldingDateAttachments.push(files[index])
			}
		}
		setNewService(filesList)

		return Promise.resolve('Ok')
	}

	const removeFilesLastDateOfServiceAttachments = (fileName) => {
		let newControl = newService
		let newAttachments = newService.lastDateOfServiceAttachments.filter((file) => {
			return file.name !== fileName
		})
		newControl.lastDateOfServiceAttachments = newAttachments
		setNewService(newControl)
		return Promise.resolve('Ok')
	}

	const uploadFilesLastDateOfServiceAttachments = (files) => {
		let filesList = newService
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.lastDateOfServiceAttachments.push(files[index])
			}
		}
		setNewService(filesList)
		return Promise.resolve('Ok')
	}

	const removeFilesOtherAttachments = (fileName) => {
		let newControl = newService
		let newAttachments = newService.otherAttachments.filter((file) => {
			return file.name !== fileName
		})
		newControl.otherAttachments = newAttachments
		setNewService(newControl)
		return Promise.resolve('Ok')
	}

	const uploadFilesOtherAttachments = (files) => {
		let filesList = newService
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.otherAttachments.push(files[index])
			}
		}
		setNewService(filesList)
		return Promise.resolve('Ok')
	}

	const OnUpdateProperty = (p, value) => {
		let updateService = newService
		updateService[p] = value
		setNewService(updateService)
	}

	const onRowClick = (item, event) => {
		onLateralPanelConfig('IndexViewService')
		setSelectedItem(item)
	}

	const onLateralPanelConfig = (identifier) => {
		setIdentifierPanelLateral(identifier)
		onOpenAndCloseLateralPanel()
	}

	/**
	 * Show the confimation modal to add new vehicle.
	 */
	const openServiceDeleteConfirmationModal = (event) => {
		event.preventDefault()
		event.stopPropagation()
		const { id } = event.currentTarget
		console.log('🚀 ~ openServiceDeleteConfirmationModal ~ id:', id)
		setDeletingServicePanelConfig((prevStatus) => ({ ...prevStatus, opened: true, serviceToken: id }))
	}

	const handleDeleteServiceConfirmation = () => {
		setDeletingServicePanelConfig((prevStatus) => ({ ...prevStatus, deleting: true }))
		dispatch(deleteService(deletingServicePanelConfig.serviceToken)).finally(() => {
			setDeletingServicePanelConfig({
				opened: false,
				deleting: false,
				serviceToken: null,
			})
		})
	}

	/** Load services and vehicles data */
	useEffect(() => {
		setLoading(true)
		dispatch(loadServices()).finally(() => {
			setLoading(false)
		})
		dispatch(loadVehicles())
	}, [dispatch])


	const onRenderCellItem = (dataSource, item) => {
		switch (dataSource) {
			case 'vehicleEconomicNumber':
				return `${item.vehicleEconomicNumber || '-'} | ${item.vehicleLicensePlate || '-'}`
			case 'options':
				return (
					<Tooltip title={<Translate id='controlCO2.services.deleteService' />}>
						<DeleteOutlinedIcon
							id={item.token}
							onClick={openServiceDeleteConfirmationModal}
							color='error'
						/>
					</Tooltip>
				)
			default:
				return null
		}
	}

	const PanelLateralChildren = useCallback({
		IndexViewService: (
			<ViewServiceComponent
				translate={translate}
				item={selectedItem}
				onCloseButtonClick={() => {
					setSelectedItem(null)
					setModify(false)
				}}
				modify={modify}
				setModify={modifyService}
				newService={newService}
				uploadFilesLastVerificationDate={uploadFilesLastVerificationDate}
				removefilesLastVerificationDate={removefilesLastVerificationDate}
				uploadFilesLastHoldingDate={uploadFilesLastHoldingDate}
				removeFilesLastHoldingDate={removeFilesLastHoldingDate}
				uploadFilesLastDateOfServiceAttachments={uploadFilesLastDateOfServiceAttachments}
				removeFilesLastDateOfServiceAttachments={removeFilesLastDateOfServiceAttachments}
				uploadFilesOtherAttachments={uploadFilesOtherAttachments}
				removeFilesOtherAttachments={removeFilesOtherAttachments}
				OnUpdateProperty={OnUpdateProperty}
				onCloseModal={() => onOpenAndCloseLateralPanel()}
				canBeModify={IsUserInRole(ROLE_CO2_COTROL_SERVICE_EDITOR, userRolesByCompany)}
			/>
		),
	})

	const { LateralPanelComponent, onOpenAndCloseLateralPanel } = LateralPanelContainer({
		children: PanelLateralChildren[identifierPanelLateral],
		identifier: identifierPanelLateral,
	})

	return (
		<>
			<Card>
				<CardHeader
					className={classes.header}
					avatar={
						<Avatar
							style={{
								height: 35,
								width: 35,
							}}
						>
							<BuildIcon />
						</Avatar>
					}
					title={
						<Typography>
							<Translate id='controlCO2.tabs.services' />
						</Typography>
					}
					action={
							IsUserInRole(ROLE_CO2_COTROL_SERVICE_EDITOR, userRolesByCompany) && (
								<Tooltip title={<Translate id='controlCO2.addService' />}>
									<Fab
										className={classes.iconButon}
										size='small'
										color='primary'
										onClick={() => {
											setOpenAddModal(!openAddModal)
										}}
									>
										<AddIcon />
									</Fab>
								</Tooltip>
							)}
				/>
				<CardContent>
					{
						{
							true: <LoadingPage />,
							false: (
								<DataTable
									data={allServices}
									configuration={ServicesTableConfiguration}
									onRenderCellItem={onRenderCellItem}
									onRowClick={onRowClick}
									isIndex
								/>
							),
						}[loading]
					}
				</CardContent>
				<LateralPanelComponent />
				{/** Dialog to remove service */}
				<AddCommonItem
					title={<Translate id='controlCO2.services.deleteService' />}
					message={<Translate id='controlCO2.services.deleteServiceMessageConfirmation' />}
					open={deletingServicePanelConfig.opened}
					onClose={() => {
						setDeletingServicePanelConfig({ opened: false, serviceToken: null })
					}}
					onSaveItem={handleDeleteServiceConfirmation}
					isSaving={deletingServicePanelConfig.deleting}
					isMessage={true}
				/>
				<AddServiceModal
					open={openAddModal}
					onCloseModal={() => {
						setOpenAddModal(false)
					}}
					newService={newService}
					uploadFilesLastVerificationDate={uploadFilesLastVerificationDate}
					removefilesLastVerificationDate={removefilesLastVerificationDate}
					uploadFilesLastHoldingDate={uploadFilesLastHoldingDate}
					removeFilesLastHoldingDate={removeFilesLastHoldingDate}
					uploadFilesLastDateOfServiceAttachments={uploadFilesLastDateOfServiceAttachments}
					removeFilesLastDateOfServiceAttachments={removeFilesLastDateOfServiceAttachments}
					uploadFilesOtherAttachments={uploadFilesOtherAttachments}
					removeFilesOtherAttachments={removeFilesOtherAttachments}
					OnUpdateProperty={OnUpdateProperty}
				/>
			</Card>
		</>
	)
}

const styles = (theme) => ({
	header: {
		padding: theme.spacing.unit,
	},
	actionsContainer: {
		display: 'flex',
		marginTop: theme.spacing.unit,
	},
})

export default withStyles(styles, { withTheme: true })(VehiclesServices)
