/** React import section */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { useQueryParam } from 'use-query-params'
import { DelimitedPipeArrayParam } from '../../../common/contextFilters/CustomArrayParam'

/* Material UI component section import  */
import { DialogContentText, DialogContent, DialogActions, LinearProgress } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'

/** Language imports **/
import { Translate } from 'react-localize-redux'

/** Redux import seciton */
import { loadFilterOptions } from '../../../../store/accreditedWorkshops/accreditedWorkshopsActions'
import { getAllFilterOptionsSelector } from '../../../../store/accreditedWorkshops/accreditedWorkshopsSelectors'

/** Resources import section */
import { buildFilterConfig } from '../serviceHelpers/FilterConfiguration'

/**
 * Dictionary for the label of the service type options based on the code
 */
const serviceTypeLabelDictionary = {
	1001: '1001 - RUTA',
	1002: '1002 - PISO',
	1003: '1003 - MOVIMIENTOS',
	1004: '1004 - INST. NUEVOS',
	1005: '1005 - INST. ELECTRICAS',
	1006: '1006 - SANITIZACIÓN POST MIX',
}

const filterConstants = {
	ChainId: 'ChainId',
	BranchOfficeId: 'BranchOfficeId',
	WorkshopTechnicianPs9Id: 'WorkshopTechnicianPs9Id',
	ServiceTypeId: 'ServiceTypeId',
}

const buildDefaultFilters = (filters, defaultServiceTypes = []) => {
	const filterKeys = Object.keys(filterConstants)
	const defaultFilters = filters.filter((f) => !filterKeys.includes(f.PropertyIdentificator))
	if (defaultServiceTypes.length > 0) {
		return [
			...defaultFilters,
			{
				PropertyIdentificator: filterConstants.ServiceTypeId,
				RuleValue: defaultServiceTypes.map(({ Type }) => Type),
			},
		]
	}

	return defaultFilters
}

/**
 * Component view dialog quick filtering
 */
const DialogQuickFilter = (props) => {
	const { openDialog, filters, defaultServiceTypes, setOpenDialog } = props

	const classes = useStyles()

	const [, setChainFilter] = useQueryParam(filterConstants.ChainId, DelimitedPipeArrayParam)
	const [, setBranchOfficeFilter] = useQueryParam(filterConstants.BranchOfficeId, DelimitedPipeArrayParam)
	const [, setWorkshopTechnicianSapFilter] = useQueryParam(
		filterConstants.WorkshopTechnicianPs9Id,
		DelimitedPipeArrayParam
	)
	const [, setServiceTypeFilter] = useQueryParam(filterConstants.ServiceTypeId, DelimitedPipeArrayParam)

	/**
	 * Connet with redux
	 */
	const dispatch = useDispatch()
	const allChainIdFilterOptions = useSelector((state) =>
		getAllFilterOptionsSelector(state, { filterType: filterConstants.ChainId })
	)
	const allBusinessUnitIdFilterOptions = useSelector((state) =>
		getAllFilterOptionsSelector(state, { filterType: filterConstants.BranchOfficeId })
	)
	const allTechnicianIdFilterOptions = useSelector((state) =>
		getAllFilterOptionsSelector(state, { filterType: filterConstants.WorkshopTechnicianPs9Id })
	)
	const allServiceTypeFilterOptions = useSelector((state) =>
		getAllFilterOptionsSelector(state, { filterType: filterConstants.ServiceTypeId })
	)

	/**
	 * Defines local state
	 */
	const [localFilters, setLocalFilters] = useState([])
	const [loading, setLoading] = useState(false)
	const [chainSelected, setChainSelected] = useState(null)
	const [businessUnitSelected, setBusinessUnitSelected] = useState(null)
	const [technicianSelected, setTechnicianSelected] = useState(null)
	const [serviceTypeSelected, setServiceTypeSelected] = useState(null)
	const [businessUnitEnabled, setBusinessUnitEnabled] = useState(false)
	const [technicianEnabled, setTechnicianEnabled] = useState(false)
	const [serviceTypeEnabled, setServiceTypeEnabled] = useState(false)
	const [uploadAttemps, setUploadAttemps] = useState(0)

	/**
	 * Bool Variables that enable a selector based on their respective selector
	 */
	let isChainEnabled = allChainIdFilterOptions.length > 0
	let isBusinessUnitEnabled = allBusinessUnitIdFilterOptions.length > 0
	let isTechnicianEnabled = allTechnicianIdFilterOptions.length > 0
	let isServiceTypeEnabled = allServiceTypeFilterOptions.length > 0

	/**
	 * UseEffect hook that initializes the options for the chain selector
	 */
	useEffect(() => {
		// Checks that there are filters from the props, and no filters in the selector state, before procceding
		if (filters.length > 0 && allChainIdFilterOptions.length < 1 && uploadAttemps < 3 && openDialog) {
			// Build Filter Configuration
			const { url, entityName, queryStringKey } = buildFilterConfig('chainId')
			const searchFilters = buildDefaultFilters(filters, defaultServiceTypes)
			setUploadAttemps(prev => prev + 1)
			// Dispatch to load the options for the selector into the state
			dispatch(loadFilterOptions(url, entityName, queryStringKey, searchFilters)).then((data) => {
				if (data && data.length > 0) {
					setUploadAttemps(0)
				}
			})
		}
	}, [filters, defaultServiceTypes, allChainIdFilterOptions, uploadAttemps, openDialog])

	/**
	 * General function that obtains the options to display on a selector
	 */
	const getOptionsList = (dataSource, additionalFilters) => {
		// Disables the corresponding selector when getting the options
		if (dataSource === 'branchOfficeName') {
			setBusinessUnitEnabled(false)
		} else if (dataSource === 'workshopTechnicianPs9Id') {
			setTechnicianEnabled(false)
		} else if (dataSource === 'serviceType') {
			setServiceTypeEnabled(false)
		}

		// Build filter configuration
		const { url, entityName, queryStringKey } = buildFilterConfig(dataSource)
		// Build object concatenating props filters with the additional filters
		let searchFilters = buildDefaultFilters(filters, defaultServiceTypes).concat(additionalFilters)

		// Checks that there are filters
		if (searchFilters.length > 0) {
			// Loads the options for the filter into the state
			setLoading(true)
			dispatch(loadFilterOptions(url, entityName, queryStringKey, searchFilters)).finally(() => {
				// Enables the selector previously disabled upon completing the dispatch
				if (dataSource === 'branchOfficeName') {
					setBusinessUnitEnabled(true)
				} else if (dataSource === 'workshopTechnicianPs9Id') {
					setTechnicianEnabled(true)
				} else if (dataSource === 'serviceType') {
					setServiceTypeEnabled(true)
				}
				setLoading(false)
			})
		}
	}

	/**
	 * Function to be called when a chain is selected
	 */
	const onChangeChainSelected = (event) => {
		// Builds the new filter
		let newChainFilter = {
			PropertyIdentificator: filterConstants.ChainId,
			RuleValue: [event.value],
		}

		// Sets the selected value into the respective state
		setChainSelected(event)

		// Clears the temporary filters and sets its value as the new filter
		setLocalFilters(() => [newChainFilter])

		// Clears the selected value for the next selectors
		setBusinessUnitSelected(null)
		setTechnicianSelected(null)
		setServiceTypeSelected(null)

		// Disables the selectors following the next selector
		setTechnicianEnabled(false)
		setServiceTypeEnabled(false)

		// Gets the option list for the next selector
		getOptionsList('branchOfficeName', [newChainFilter])
	}

	/**
	 * Function to be called when a business unit is selected
	 */
	const onChangeBusinessUnitSelected = (event) => {
		// Builds the new filter
		let newBusinessUnitFilter = {
			PropertyIdentificator: filterConstants.BranchOfficeId,
			RuleValue: [event.value],
		}

		// Gets the option list for the next selector
		getOptionsList('workshopTechnicianPs9Id', [
			...localFilters.filter((x) => x.PropertyIdentificator !== filterConstants.BranchOfficeId),
			newBusinessUnitFilter,
		])

		// Sets the selected value into the respective state
		setBusinessUnitSelected(event)

		// Enables the next selector and disables the selector following that one
		setTechnicianEnabled(true)
		setServiceTypeEnabled(false)

		// Clears the selected value for the next selectors
		setTechnicianSelected(null)
		setServiceTypeSelected(null)

		// If there are a certain amount of filters, updates the corresponding filter, otherwise it creates the new filter
		if (localFilters.length > 1) {
			setLocalFilters((previousState) => {
				// Creates new state with the updated value
				const newState = previousState.map((filter) => {
					// Cycles through the filters until finding the filter to update
					if (filter.PropertyIdentificator === filterConstants.BranchOfficeId) {
						return { ...filter, RuleValue: newBusinessUnitFilter.RuleValue }
					}

					// Returns filters that correspond to previous selectors
					else if (filter.PropertyIdentificator === filterConstants.ChainId) {
						return filter
					}

					// Returns null for filters of the next selectors
					else {
						return null
					}
				})

				// Filters the null values from the updated state
				const filteredNewState = newState.filter(function (x) {
					return x !== null
				})

				// Returns filtered updated state
				return filteredNewState
			})
		} else {
			setLocalFilters((previousFilters) => [...previousFilters, newBusinessUnitFilter])
		}
	}

	/**
	 * Function to be called when a technician is selected
	 */
	const onChangeTechnicianSelected = (event) => {
		// Builds the new filter
		let newTechnicianFilter = {
			PropertyIdentificator: filterConstants.WorkshopTechnicianPs9Id,
			RuleValue: [event.value],
		}

		// Gets the option list for the next selector
		getOptionsList('serviceType', [
			...localFilters.filter((x) => x.PropertyIdentificator !== filterConstants.WorkshopTechnicianPs9Id),
			newTechnicianFilter,
		])

		// Sets the selected value into the respective state
		setTechnicianSelected(event)

		// Enables the next selector
		setServiceTypeEnabled(true)

		// Clears the selected value for the next selector
		setServiceTypeSelected(null)

		// If there are a certain amount of filters, updates the corresponding filter, otherwise it creates the new filter
		if (localFilters.length > 2) {
			setLocalFilters((previousState) => {
				// Creates new state with the updated value
				const newState = previousState.map((filter) => {
					// Cycles through the filters until finding the filter to update
					if (filter.PropertyIdentificator === filterConstants.WorkshopTechnicianPs9Id) {
						return { ...filter, RuleValue: newTechnicianFilter.RuleValue }
					}
					// Returns filters that correspond to previous selectors
					else if (
						filter.PropertyIdentificator === filterConstants.ChainId ||
						filter.PropertyIdentificator === filterConstants.BranchOfficeId
					) {
						return filter
					}
					// Returns null for filters of the next selectors
					else {
						return null
					}
				})

				// Filters the null values from the updated state
				const filteredNewState = newState.filter(function (x) {
					return x !== null
				})

				// Returns filtered updated state
				return filteredNewState
			})
		} else {
			setLocalFilters((previousFilters) => [...previousFilters, newTechnicianFilter])
		}
	}

	/**
	 * Function to be called when a service type is selected
	 */
	const onChangeServiceTypeSelected = (selectedItems) => {
		const selectedServiceTypes = Array.isArray(selectedItems) ? selectedItems : []

		// Builds the new filter
		const newServiceTypeFilter = {
			PropertyIdentificator: filterConstants.ServiceTypeId,
			RuleValue: selectedServiceTypes.map((x) => x.value),
		}
		// Sets the selected value into the respective state
		setServiceTypeSelected(selectedServiceTypes)

		// If there are a certain amount of filters, updates the corresponding filter, otherwise it creates the new filter
		if (localFilters.length > 3) {
			setLocalFilters((previousState) => {
				// Creates new state with the updated value
				const newState = previousState.map((filter) => {
					// Cycles through the filters until finding the filter to update
					if (filter.PropertyIdentificator === filterConstants.ServiceTypeId) {
						return { ...filter, RuleValue: newServiceTypeFilter.RuleValue }
					}

					// Returns filters that correspond to previous selectors
					return filter
				})

				// Returns updated state
				return newState
			})
		} else {
			setLocalFilters((previousFilters) => [...previousFilters, newServiceTypeFilter])
		}
	}

	/**
	 * Clean all selected values in filters
	 */
	const cleanFilters = () => {
		setServiceTypeSelected([])
		setTechnicianSelected([])
		setBusinessUnitSelected([])
		setChainSelected([])
	}

	/**
	 * Function to be called on the confirm button
	 */
	const redirectWithFilters = () => {
		localFilters.forEach((filter) => {
			switch (filter.PropertyIdentificator) {
				case filterConstants.ChainId: {
					if (filter && filter.RuleValue.length > 0) {
						setChainFilter(filter.RuleValue, 'pushIn')
					}
					break
				}
				case filterConstants.BranchOfficeId: {
					if (filter && filter.RuleValue.length > 0) {
						setBranchOfficeFilter(filter.RuleValue, 'pushIn')
					}
					break
				}
				case filterConstants.WorkshopTechnicianPs9Id: {
					if (filter && filter.RuleValue.length > 0) {
						setWorkshopTechnicianSapFilter(filter.RuleValue, 'pushIn')
					}
					break
				}
				case filterConstants.ServiceTypeId: {
					if (filter && filter.RuleValue.length > 0) {
						setServiceTypeFilter(filter.RuleValue, 'pushIn')
					}
					break
				}
				default: {
					console.warn(`${filter.PropertyIdentificator} not supported`)
					break
				}
			}
		})
		setOpenDialog(false)
	}

	/**
	 * Render dialog
	 */
	return (
		<Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby='Cambiar compañia'>
			<DialogTitle id='form-dialog-title'>
				<DialogContentText>
					<Translate id='common.quickFiltering' />
				</DialogContentText>
			</DialogTitle>
			<Divider />
			<DialogContent className={classes.mainContainer}>
				<br />
				<Select
					options={allChainIdFilterOptions.map((option) => ({
						value: option.key,
						label: option.key + ' - ' + option.label,
					}))}
					placeholder={'Cadena...'}
					isDisabled={!isChainEnabled}
					onChange={onChangeChainSelected}
					value={chainSelected}
				/>
				<br />
				<Select
					options={allBusinessUnitIdFilterOptions.map((option) => ({
						value: option.key,
						label: option.key + ' - ' + option.label,
					}))}
					placeholder={'Unidad de negocio...'}
					isDisabled={!isBusinessUnitEnabled || !businessUnitEnabled}
					onChange={onChangeBusinessUnitSelected}
					value={businessUnitSelected}
				/>
				<br />
				<Select
					options={allTechnicianIdFilterOptions.map((option) => ({
						value: option.key,
						label: option.key,
					}))}
					placeholder={'Técnico...'}
					isDisabled={!isTechnicianEnabled || !technicianEnabled}
					onChange={onChangeTechnicianSelected}
					value={technicianSelected}
				/>
				<br />
				<Select
					options={allServiceTypeFilterOptions.map((option) => ({
						value: option.key,
						label: serviceTypeLabelDictionary[option.key],
					}))}
					styles={{
						menu: (base) => ({ ...base, zIndex: 50 }),
						menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
					}}
					menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
					isMulti
					placeholder={'Tipo de servicio...'}
					isDisabled={!isServiceTypeEnabled || !serviceTypeEnabled}
					onChange={onChangeServiceTypeSelected}
					value={serviceTypeSelected}
				/>
			</DialogContent>
			{loading && <LinearProgress />}
			<Divider />

			<DialogActions>
				<Button onClick={() => setOpenDialog(false)} color='secondary'>
					<Translate id='common.cancel' />
				</Button>
				<Button onClick={cleanFilters} color='secondary'>
					<Translate id='common.clean' />
				</Button>
				{localFilters.length > 3 && (
					<Button onClick={redirectWithFilters} color='primary'>
						<Translate id='common.apply' />
					</Button>
				)}
			</DialogActions>
		</Dialog>
	)
}

export default DialogQuickFilter

const useStyles = makeStyles(() => ({
	usedMaterialRow: {
		margin: '2px 5px',
		//borderLeft: `3px solid ${COLOR_ALTERNATE_NORMAL}`,
		display: 'flex',
	},
	usedMaterialName: {
		flex: '6 1 0',
	},
	usedMaterialWarranty: {
		flex: '1 1 0',
	},
	usedMaterialDetail: {
		flex: '2 1 0',
	},
	mainContainer: {
		width: '300px',
		height: '300px',
	},
}))
