import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import { Grid, Avatar, CardContent, Card, CardHeader, Typography} from '@material-ui/core';
import NotUpdateIcon from '@material-ui/icons/RemoveCircle';
import UpdateIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import ContactsIcon from '@material-ui/icons/SupervisedUserCircle';
import TextField from '@material-ui/core/TextField';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LoadingPage from '../common/LoadingPage';
import Toaster from '../common/Toaster';

/** Import action section */
import { loadSupplier } from '../../store/suppliers/SupplierActions';
import {addContactSupplierUpdate} from '../../store/contactsupplier/ContactSupplierAction';
import {getUsersSaleAndBulling} from '../../store/suppliers/ExternalUserActions';
import { COLOR_STATUS_LIGHT, COLOR_STATUS_ALERT_INFO } from './../../store/helpers/StatusColorConstants';
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';
import { showErrorToaster } from '../../store/helpers/ToasterHelpers'
/**
 * Container show update contactd
 */
class UpdateContact extends Component {

    /**
     * Create an instance Official notice
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            editCompany: null,
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            contact:{
                sale_name: "",
                sale_email: "",
                sale_phone: "",
                billing_name: "",
                billing_email: "",
                billing_phone: "",
            }
        }
    }

    componentDidMount() {
        if (!this.props.isLoading) {
            this.props.getUsersSaleAndBulling();
        }
    }
    
    /**
    * componentDidUpdate
    *
    * @param {*} prevProps
    * @param {*} prevState
    * @memberof Index
    */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.contactsListUpdate !== null) {
            this.props.loadSupplier(this.props.currentUser.profile.company_token);
            this.props.getUsersSaleAndBulling();
        }
    }

    /**
     * Can be saved update contact
     */
    canBeSaved() {
        if (this.props.isSavingContactSupplier) return false;
        else {
            let contact = this.state.contact;
            if (IsNullOrEmpty(contact.sale_name) && IsNullOrEmpty(contact.sale_email) &&
                IsNullOrEmpty(contact.sale_phone) && IsNullOrEmpty(contact.billing_name) &&
                IsNullOrEmpty(contact.billing_email) && IsNullOrEmpty(contact.billing_phone))
             return false;
        }
        return true;
    }

    updateProperty(property, value) {
        let contactsUpdate = { ...this.state.contact };
        contactsUpdate[property] = value;
        this.setState({
            contact: contactsUpdate
        })
    }

    onKeyDown(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    renderTextArea(key) {
        return (
            <TextField autoFocus={key === 'sale_name'}
                className={this.props.classes.textField}
                key={key}
                type="text"
                fullWidth
                defaultValue={this.state[key] ? this.state[key] : ''}
                onBlur={(event) => { this.updateProperty(key, event.target.value) }}
                onKeyDown={this.onKeyDown}
            />
        )
    }

    onNotUpdateContactsSupplier(contactToken) {
        this.props.addContactSupplierUpdate(contactToken, 1, this.state.contact)
            .then(() => {
                if (this.props.contactsListUpdate.length === 0) {
                    this.props.history.push(`/`)
                }
            }).catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : "common.errorToSave"));
            });
    }

    /**
    * Function on add ifficial notice accepted by supplier
    */
    onUpdateContactsSupplier(contactToken) {
        this.props.addContactSupplierUpdate(contactToken, 2, this.state.contact)
            .then(() => {
                if (this.props.contactsListUpdate.length === 0) {
                    this.props.history.push(`/`)
                }
            }).catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : "common.errorToSave"));
            });
    }

    /// Get user sale to update
    getUserSale(){
        let userSales = null;
        if(this.props.usersSaleBilling !== null && this.props.usersSaleBilling.length >= 1){
            userSales = this.props.usersSaleBilling[0];
        }
        return userSales;
    }

    /**
     * Get user billing to update
     */
    getUserBilling(){
        let userBilling = null;
        if(this.props.usersSaleBilling !== null && this.props.usersSaleBilling.length >= 2){
            userBilling = this.props.usersSaleBilling[1];
        }
        return userBilling;
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.contactsListUpdate !== null) {
            const { classes, contactsListUpdate } = this.props;
            let userSale = this.getUserSale();
            let userBilling = this.getUserBilling();
            return (
                <Grid container spacing={16}>
                    {contactsListUpdate.map(contact => {
                        return (
                            <React.Fragment key={`${contact.token}${contact.name}`} >
                                 <Grid container
                                        direction="column"
                                        justify="center"
                                        alignItems="center">
                                    <Typography color="textSecondary" variant="body1">
                                        <strong>{contact.name}</strong>
                                    </Typography>
                                 </Grid>
                                 <Grid container item xs={12} spacing={8}>
                                    <Table className={classes.table} aria-label="caption table">
                                        <TableHead className={classes.headerTable}>
                                        <TableRow>
                                            <TableCell className={classes.tdHeader} align="center" colSpan={4}><strong><Translate id="contactsupplier.actualContact" /></strong></TableCell>
                                            <TableCell className={classes.tdHeader} align="center" colSpan={3}><strong><Translate id="contactsupplier.newContact" /></strong></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.tdBody}><Translate id="contactsupplier.area" /></TableCell>
                                            <TableCell className={classes.tdBody}><Translate id="contactsupplier.name" /></TableCell>
                                            <TableCell className={classes.tdBody}><Translate id="contactsupplier.email" /></TableCell>
                                            <TableCell className={classes.tdBody}><Translate id="contactsupplier.phone" /></TableCell>
                                            <TableCell className={classes.tdBody}><Translate id="contactsupplier.name" /></TableCell>
                                            <TableCell className={classes.tdBody}><Translate id="contactsupplier.email" /></TableCell>
                                            <TableCell className={classes.tdBody}><Translate id="contactsupplier.phone" /></TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={"sales"}>
                                                <TableCell className={classes.tdBody} component="th" scope="row"><strong>{<Translate id="contactsupplier.sale" />}</strong></TableCell>
                                                <TableCell className={classes.tdBody}>{userSale && userSale.name}</TableCell>
                                                <TableCell className={classes.tdBody}>{userSale && userSale.user_email}</TableCell>
                                                <TableCell className={classes.tdBody}>{""}</TableCell>
                                                <TableCell className={classes.tdBody}>{this.renderTextArea("sale_name")}</TableCell>
                                                <TableCell className={classes.tdBody}>{this.renderTextArea("sale_email")}</TableCell>
                                                <TableCell className={classes.tdBody}>{this.renderTextArea("sale_phone")}</TableCell>
                                            </TableRow >
                                            <TableRow key={"billing"}>
                                                <TableCell className={classes.tdBody} component="th" scope="row"><strong>{<Translate id="contactsupplier.billing" />}</strong></TableCell>
                                                <TableCell className={classes.tdBody}>{userBilling && userBilling.name}</TableCell>
                                                <TableCell className={classes.tdBody}>{userBilling && userBilling.user_email}</TableCell>
                                                <TableCell className={classes.tdBody}>{""}</TableCell>
                                                <TableCell className={classes.tdBody}>{this.renderTextArea("billing_name")}</TableCell>
                                                <TableCell className={classes.tdBody}>{this.renderTextArea("billing_email")}</TableCell>
                                                <TableCell className={classes.tdBody}>{this.renderTextArea("billing_phone")}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid container item xs={12} spacing={8}>  
                                    <Fab
                                        variant="extended"
                                        size="small"
                                        color="secondary"
                                        aria-label="accepted"
                                        disabled={this.props.isSavingContactSupplier}
                                        onClick={() => { this.onNotUpdateContactsSupplier(contact.token) }}
                                    >
                                        <NotUpdateIcon className={classes.extendedIcon} />
                                        <Translate id="contactsupplier.noModification" />
                                    </Fab>
                                    <div className={classes.grow}></div>
                                    <Fab
                                        variant="extended"
                                        size="small"
                                        color="primary"
                                        aria-label="accepted"
                                        disabled={this.props.isSavingContactSupplier || !this.canBeSaved()}
                                        onClick={() => { this.onUpdateContactsSupplier(contact.token) }}
                                    >
                                        <UpdateIcon className={classes.extendedIcon} />
                                        <Translate id="contactsupplier.updateContact" />
                                    </Fab>
                                </Grid>     
                            </React.Fragment>
                        )
                    })}
                </Grid>
            )
        }
    }

    render() {
        return (
            <Card>
                <CardHeader
                    avatar={<Avatar color="primary">
                                <ContactsIcon />
                            </Avatar>}
                    title={ <Typography variant="h5" color="textPrimary">
                            <Translate id="contactsupplier.listContactsUpdate" />
                        </Typography>}
                />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Card>
        )
    }

}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    return {
        currentUser: user,
        isLoading: state.contactSuppliers.get('isLoadingContactSuppliers'),
        contactsListUpdate: state.contactSuppliers.get('contactsListUpdate') ? state.contactSuppliers.get('contactsListUpdate').toJS() : null,
        isSavingContactSupplier: state.contactSuppliers.get('isSavingContactSupplier'),
        isLoadingExternalUserRoles: state.externalUsers.get("isLoadingExternalUserRoles"),
        usersSaleBilling: state.externalUsers.get('usersSaleBilling') ? state.externalUsers.get('usersSaleBilling').toJS() : null,
        translate: getTranslate(state.localize),
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        addContactSupplierUpdate: (contactToken, contactStatus, contactUpdate) => {
            return dispatch(addContactSupplierUpdate(contactToken, contactStatus, contactUpdate))
        },
        loadSupplier: (token) => {
            return dispatch(loadSupplier(token))
        },
        getUsersSaleAndBulling:() =>{
            return dispatch(getUsersSaleAndBulling())
        }
    }
}

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    cardInfo: {
        backgroundColor: COLOR_STATUS_ALERT_INFO
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 80
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    avatar: {
        backgroundColor: COLOR_STATUS_LIGHT
    },
    headerTable:{
        backgroundColor: "#dee2e6",
        color: "white",
        padding: "1rem",
    },
    tdHeader:{
        border: "1px solid rgba(67, 119, 196, 1)",
        color: "black"
    },
    tdBody:{
        border: "1px solid #000",
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(UpdateContact))));