///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'
import queryString from 'query-string'

/** Material-UI imports section */
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Fab from '@material-ui/core/Fab'
import { Typography, Tooltip } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { CreditCardOutlined } from '@material-ui/icons'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import { COLOR_SUSTAINABILITY_PLUM, COLOR_SUSTAINABILITY_ROSE } from '../../store/helpers/StatusColorConstants'

/** Import component section */
import Toaster from '../common/Toaster'
import PanelQualityCertificate from './components/PanelQualityCertificate'
import DataTable from '../common/DataTable'
import { converListToArray } from '../../store/helpers/ListHelper'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import OptionsMenu from '../common/OptionsMenu'
import DowloadReport from './components/DowloadReport'

import {
	addQualityCertificate,
	loadAllQualityCertificationsFromBackEnd,
	downloadReportCertificate,
} from '../../store/qualityCertificate/qualityCertificateAction'
import { getErrorInChangeStatus } from '../../store/qualityCertificate/qualityCertificateResults'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'

const Index = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()
	const queryParams = queryString.parse(location.search)
	const translate = useSelector((state) => getTranslate(state.localize))

	const isLoadingList = useSelector((state) => state.qualityCertificate.get('isLoading'))
	const isSavingAdd = useSelector((state) => state.qualityCertificate.get('isSavingAdd'))
	const isDownloadingReport = useSelector((state) => state.qualityCertificate.get('isDonwloading'))
	const user = useSelector((state) => state.oidc.user)
	const qualityCount = useSelector((state) => state.qualityCertificate.get('requestsCount'))
	const qualytyList = useSelector((state) =>
		state.qualityCertificate.get('request') ? converListToArray(state.qualityCertificate.get('request')) : []
	)
	const [showMenu, setShowMenu] = useState(true)
	const [itemToken, setItemToken] = useState(null)
	const [openLateralPanel, setOpenLateralPanel] = useState(false)
	const [isOpenDownloadReport, setIsOpenDownloadReport] = useState(false)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	/**Use effect section */
	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(loadAllQualityCertificationsFromBackEnd(queryParams, translate))
	}, [dispatch, location.search])

	const onOpenLateralPanel = () => {
		setOpenLateralPanel(!openLateralPanel)
	}

	const onRowClick = (qualityCertificate, event) => {
		let tagName = event.target.tagName
		if (tagName !== 'svg' && tagName !== 'A') {
		}
		history.push(`/qualityCertificate/${qualityCertificate.Id}`)
	}

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	const handleCloseOptionsMenu = () => {
		setItemToken(null)
		setShowMenu(true)
	}

	const handleClickOptionsMenu = (token) => {
		setItemToken(token)
	}

	const onAddQualityCertificate = (model) => {
		return dispatch(addQualityCertificate(model))
			.then((requestToken) => {
				onOpenLateralPanel()
				history.push(`/qualityCertificate/${requestToken}`)
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: getErrorInChangeStatus(response),
					toasterVariant: 'warning',
				})
			})
	}

	const onDownloadReport = (supplier, modelDate) => {
		dispatch(downloadReportCertificate(supplier, modelDate))
			.then(() => {
				setIsOpenDownloadReport(false)
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToDownload',
					toasterVariant: 'error',
				})
			})
	}
	const renderAction = () => {
		var options = [
			{
				itemClick: () => setIsOpenDownloadReport(true),
				tooltipTranslation: <Translate id='common.downloadReport' />,
				menuItemIcon: <DownloadIcon color='secondary' />,
				isLoading: isDownloadingReport,
			},
		]
		if (showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={handleClickOptionsMenu}
					open={itemToken && itemToken === 'item.token'}
					handleClickAway={handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	const getCounterMessage = () => {
		if (qualytyList) {
			let qualityTotal = qualytyList.length
			return (
				<div>
					{<Translate id='common.showing' />}&nbsp;
					<span className={classes.totalCounter}>{qualityTotal}</span>&nbsp;
					{<Translate id='common.of' />}&nbsp;
					<span className={classes.totalCounter}>{qualityCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'FullSupplier') {
			return item.SupplierCode + ' - ' + item.SupplierName
		}
	}

	const getCardContent = () => {
		if (isLoadingList === true) {
			return <LoadingPage />
		} else if (qualytyList === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={qualytyList}
					configuration={TableConfigurationQuality}
					onRowClick={onRowClick}
					onChangeSortCriteria={onChangeSortCriteria}
					onRenderCellItem={onRenderCellItem}
					isIndex
					showFilters
					totalRows={qualityCount}
					sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'FormatedFolio'}
					sortAscending={
						queryParams && queryParams.sortDirection
							? queryParams.sortDirection === 'desc'
								? false
								: true
							: false
					}
					page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
					rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
					onChangePage={(newPage, newRowsPerPage) => {
						onChangePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	return (
		<Card>
			<CardHeader
				title={
					<Typography variant='button'>{<Translate id='qualityCertificate.qualityCertificate' />}</Typography>
				}
				avatar={
					<Avatar>
						<CreditCardOutlined />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						<SearchInput
							onChangeSearchValue={onChangeSearchValue}
							value={!!queryParams && queryParams.query ? queryParams.query : ''}
						/>

						{!IsCurrentActiveDirectoryUser(user) && (
							<Tooltip title={<Translate id='qualityCertificate.addFolio' />}>
								<Fab
									className={classes.marginIcon}
									size='small'
									color='primary'
									onClick={() => {
										onOpenLateralPanel()
									}}
								>
									<AddtIcon />
								</Fab>
							</Tooltip>
						)}
						{IsCurrentActiveDirectoryUser(user) && renderAction()}
					</div>
				}
				subheader={getCounterMessage()}
			/>
			<CardContent>{getCardContent()}</CardContent>

			{openLateralPanel && (
				<PanelQualityCertificate
					onCloseButtonClick={onOpenLateralPanel}
					onSaveButtonClick={onAddQualityCertificate}
					isSaving={isSavingAdd}
				/>
			)}

			{isOpenDownloadReport && (
				<DowloadReport
					open={isOpenDownloadReport}
					onDownloadReport={onDownloadReport}
					onClose={() => setIsOpenDownloadReport(false)}
					isSaving={isDownloadingReport}
				/>
			)}

			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</Card>
	)
}

const styles = (theme) => ({
	logo: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	marginIcon: {
		marginLeft: '5px',
	},
	marginIconCalendar: {
		marginLeft: '5px',
		backgroundColor: COLOR_SUSTAINABILITY_PLUM,
	},
	marginIconFile: {
		marginLeft: '5px',
		backgroundColor: COLOR_SUSTAINABILITY_ROSE,
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
})

const TableConfigurationQuality = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'Status',
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'Folio',
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'CreationFolio',
		},
		{
			header: <Translate id='common.invoice' />,
			dataSource: 'Invoice',
		},
		{
			header: <Translate id='common.createBy' />,
			dataSource: 'CreatedName',
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'FullSupplier',
		},
	],
}

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Index)))
