/**Import React sections */
import React from 'react'
import { withRouter } from 'react-router-dom'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import autoBind from 'auto-bind'

/**Import material Ui section */
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import SendEmailIcon from '@material-ui/icons/Email'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

/**Import componentes section */
import UserInRoleSelector from '../../common/UserInRoleSelector'
import { ROLE_SUPPLIERDEVELOPMENTENGINEER, ROLE_SUPPLIER_QUALITYNGINEER } from '../../../store/helpers/RolesHelper'
import { searchUsersInRole } from '../../../store/administration/InternalActions'
import LoadingPage from '../../common/LoadingPage'
import DataTable from '../../common/DataTable'
import Toaster from '../../common/Toaster'

/**Import actions sections */
import { loadSupplier } from '../../../store/suppliers/SupplierActions'
import {
	loadGuaranteeReport,
	sendNotificationGuaranteeReport,
} from '../../../store/guaranteeReport/guaranteeReportAction'

class SendNotificationView extends React.Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			developmentSelected: null,
			users: null,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let guaranteeReportToken = this.props.match.params.token
		this.props.loadGuaranteeReport(guaranteeReportToken)
	}

	// /**
	//  * componentDidUpdate
	//  * @param {*} prevProps
	//  * @param {*} prevState
	//  * @memberof Index
	//  */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoading !== this.props.isLoading && this.props.guaranteeReport !== null) {
			/// Get information full from corrective
			let guaranteeReport = this.props.guaranteeReport

			// Get User by role from supplier
			this.props
				.searchUsers(
					ROLE_SUPPLIER_QUALITYNGINEER,
					guaranteeReport.supplier_code ? guaranteeReport.supplier_code : null
				)
				.then((response) => {
					this.setState({ users: response })
				})
		}
	}

	/**
	 * Can be add item in send notification
	 */
	canBeSaved() {
		if (this.state.developmentSelected === null) return false
		return true
	}

	/**
	 * On seletecd supplier Development Engineer
	 */
	onDevelopmentSelected(development) {
		this.setState({ developmentSelected: development })
	}

	/**
	 * Get action to send corrective action
	 */
	sendNotificationGuaranteeReport() {
		this.props
			.sendNotificationGuaranteeReport(this.state.developmentSelected)
			.then(() => {
				this.setState({
					showToView: true,
					showToaster: false,
				})
				let guatanteeReportToken = this.props.match.params.token
				this.props.history.push(`/guaranteereport/${guatanteeReportToken}`)
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { classes } = this.props
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.guaranteeReport === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<Grid container>
					<Grid item xs={12} sm={6}>
						<br />
						<div className={classes.textField}>
							<Typography variant='subtitle1' gutterBottom>
								<Translate id='rawmaterialnonconformity.supplierDevelopmentEngineer' />
							</Typography>
							<UserInRoleSelector
								className={classes.textField}
								role={ROLE_SUPPLIERDEVELOPMENTENGINEER}
								onUserSelected={(development) => {
									this.onDevelopmentSelected(development)
								}}
							/>
						</div>

						<div className={classes.textField}>
							<br />
							<Button
								data-cy='btn-save'
								type='button'
								color='primary'
								onClick={this.sendNotificationGuaranteeReport}
								disabled={!this.canBeSaved() || this.props.isSaving}
							>
								{<Translate id='common.save' />}
							</Button>
							<Button
								onClick={() => {
									this.props.history.push(`/guaranteereport/${this.props.match.params.token}`)
								}}
								color='secondary'
							>
								{<Translate id='common.cancel' />}
							</Button>
						</div>
					</Grid>

					<Grid item xs={12} sm={6}>
						<div className={classes.textField}>
							<DataTable
								data={this.state.users !== null ? this.state.users : []}
								configuration={TableConfiguration}
							/>
						</div>
					</Grid>
				</Grid>
			)
		}
	}

	render() {
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<SendEmailIcon />
						</Avatar>
					}
					title={
						<Typography variant='button'>
							<Translate id='moduleGuarantee.sendNotification' />
						</Typography>
					}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='rawmaterialnonconformity.usersSupplierQuality' />,
			dataSource: 'name',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let guaranteeReport = state.guaranteeReport.get('guaranteeReport')
	return {
		guaranteeReport: guaranteeReport ? guaranteeReport.toJS() : null,
		isLoading: state.guaranteeReport.get('isLoadingGuarantee'),
		isSaving: state.guaranteeReport.get('isSaving'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadGuaranteeReport: (token) => {
			return dispatch(loadGuaranteeReport(token))
		},
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		searchUsers: (role, supplierCode) => {
			return dispatch(searchUsersInRole(role, supplierCode))
		},
		sendNotificationGuaranteeReport: (developmentSelected) => {
			return dispatch(sendNotificationGuaranteeReport(developmentSelected))
		},
	}
}

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	actionsToolbar: {
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 500,
		marginTop: 20,
		marginBottom: 10,
	},
})

export default withauthenticatedLayout(
	withRouter(
		withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(SendNotificationView))
	)
)
