///Import react section
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import LinkMaterial from '@material-ui/core/Link'
import TutorialIcon from '@material-ui/icons/LibraryBooks'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import { Translate, getTranslate } from 'react-localize-redux'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import AddManual from './AddManual'
import Toaster from '../common/Toaster'
import SearchInput from '../common/SearchInput/SearchInput'
import AddCommonItem from '../common/AddCommonItem'

/** Action and helpers */
import {
	loadManualsFromBackEnd,
	getManualsCount,
	createManualData,
	CHANGE_MANUALS_SORT_CRITERIA,
	CHANGE_MANUALS_SORT_QUERY,
	CHANGE_MANUALS_PAGINATION,
	removeManualPortal,
} from '../../store/manuals/ManualPortalActions'
import { converListToArray } from '../../store/helpers/ListHelper'
import { ROLE_COMPANYADMINISTRATOR, IsUserInRole } from '../../store/helpers/RolesHelper'
import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers'

/**
 * ManualsPortal view
 */
class ManualsPortal extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			openModalManual: false,
			manual: {
				attachments: [],
				name: '',
			},
			openRemoveItem: false,
			itemId: '',
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadManuals(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getManualsCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadManuals()
			this.props.getManualsCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadManuals(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Get manuals portal counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		const { classes } = this.props
		if (this.props.manuals) {
			let totalManuals = this.props.manuals.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{totalManuals}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.manualsCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * handleShowAddDialog
	 * @param {*} openModalManual
	 */
	handleShowAddDialog(openModalManual) {
		this.setState({
			openModalManual,
		})
	}

	/**
	 * Handle event to add action, call redux action to save data
	 * @param {*} data
	 */
	onAddManual(data) {
		return this.props
			.createManualData(data)
			.then(() => {
				this.setState(showSuccessToaster('common.addCorrectly'))
				this.setState({ openModalManual: false })
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToAdd'))
				return Promise.reject()
			})
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		if (dataSource === 'logo') {
			return (
				<Avatar data-cy='manuals-avatar' className={classes.avatar}>
					<TutorialIcon />
				</Avatar>
			)
		}
		if (dataSource === 'name') {
			return (
				<LinkMaterial
					key={item.token}
					component={'a'}
					target='_blank'
					color='primary'
					href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/Documents/Manuals/${item.token}.pdf`}
				>
					<DownloadIcon style={{ marginTop: '-7px' }} /> {item.name}
				</LinkMaterial>
			)
		}
		if (dataSource === 'token') {
			return (
				<Tooltip title={<Translate id='companies.removeManual' />}>
					<DeleteOutlinedIcon
						onClick={() => {
							this.onRemoveItem(item.token)
						}}
						style={{ zIndex: '990', cursor: 'pointer' }}
						color='error'
						className={classes.icon}
					/>
				</Tooltip>
			)
		}
	}

	/**
	 * onRemoveItem
	 */
	onRemoveItem(itemId) {
		this.setState({
			openRemoveItem: true,
			itemId: itemId,
		})
	}

	/**
	 * onRemoveManual
	 */
	onRemoveManual() {
		this.props
			.removeManualPortal(this.state.itemId)
			.then(() => {
				this.setState({
					openRemoveItem: false,
					itemId: '',
				})
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToAdd'))
				return Promise.reject()
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingManuals === true) {
			return <LoadingPage />
		} else if (this.props.manuals === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.manuals}
					configuration={TableConfiguration(
						IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany)
					)}
					onRenderCellItem={this.onRenderCellItem}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					isIndex
					totalRows={this.props.manualsCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Create instance
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<TutorialIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput onChangeSearchValue={this.onChangeSearchValue} />

							{IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
								<Fab size='small' color='primary' onClick={() => this.handleShowAddDialog(true)}>
									<AddtIcon />
								</Fab>
							)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.tutorials' />
						</Typography>
					}
					subheader={this.getCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to add non AddManual*/}
				<AddManual
					text={this.props.translate('dropzone.dropzoneBaseText')}
					open={this.state.openModalManual}
					onClose={() => {
						this.handleShowAddDialog(false)
					}}
					onAddManual={this.onAddManual}
					isSaving={this.props.isSavingManual}
					user={this.props.currentUser}
				/>

				{/** Dialog to remove manuañ */}
				<AddCommonItem
					title={<Translate id='companies.removeManual' />}
					message={<Translate id='companies.messageToRemoveManual' />}
					open={this.state.openRemoveItem}
					onClose={() => {
						this.setState({ openRemoveItem: false })
					}}
					onSaveItem={this.onRemoveManual}
					isSaving={this.props.isSavingManual}
					isMessage={true}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: '',
			dataSource: 'logo',
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
		},
		{
			header: <Translate id='menu.tutorials' />,
			dataSource: 'name',
		},
	]

	if (isDisabled) {
		columns.push({
			header: '',
			dataSource: 'token',
		})
	}

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingManuals: state.manualsportal.get('isLoadingManuals'),
		manuals: state.manualsportal.get('manuals') ? state.manualsportal.get('manuals').toJS() : null,
		isSavingManual: state.manualsportal.get('isSavingManual'),
		sortCriteria: state.manualsportal.get('sortCriteria') ? state.manualsportal.get('sortCriteria').toJS() : null,
		searchQuery: state.manualsportal.get('searchQuery'),
		page: state.manualsportal.get('page'),
		rowsPerPage: state.manualsportal.get('rowsPerPage'),
		manualsCount: state.manualsportal.get('manualsCount'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadManuals: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadManualsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_MANUALS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_MANUALS_SORT_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_MANUALS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getManualsCount: () => {
			return dispatch(getManualsCount())
		},
		createManualData: (manual) => {
			return dispatch(createManualData(manual))
		},
		removeManualPortal: (manualToken) => {
			return dispatch(removeManualPortal(manualToken))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ManualsPortal)))
)
