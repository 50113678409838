///Import react section
import { useSelector, useDispatch, } from 'react-redux'

/**Import actions sections */
import {
    setToasterOptions,
} from '../../../store/indirectqualifications/IndirectqualificationsActions'
import {
    addItemBilling
} from '../../../store/billings/BillingsActions'

export const useBillingCustomHookShow = () => {
    /**Render hooks section */
    const dispatch = useDispatch()
    const billing = useSelector((state) => state.billings.get('billing') ? state.billings.get('billing').toJS() : null)
    const user = useSelector((state) => state.oidc.user)

    const onCloseToaster = () => {
        dispatch(setToasterOptions({
            showToaster: false,
            toasterMessage: "",
            toasterVariant: "",
        }))
    }

    const onAddItemInBidding = (itemModel) => {
        return dispatch(addItemBilling(billing.token, itemModel))
            .then(() => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                }))
                return Promise.resolve(true)
            })
            .catch((_) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                }))
                Promise.reject(false)
            })
    }

    return { onCloseToaster, onAddItemInBidding, billing, user }
}