import { Button, Drawer, Icon, IconButton, Typography } from '@material-ui/core'
import React from 'react'
import { Translate } from 'react-localize-redux'
import CancelIcon from '@material-ui/icons/Cancel'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Dropzone from './Dropzone'

const DocumentToAddModal = ({ open, onClose, handleSave, isSavingFile }) => {
	const [files, setFiles] = React.useState([]);
	const handleFileChange = (files) => {
		return new Promise((resolve) => {
			setFiles(files);
			resolve('Ok');
		});
	}
	const handleSaveBtn = (files) => {
		onClose();
		handleSave(files);
		setFiles([]);
	}
	return (
		<Drawer anchor='right' open={open} onClose={onClose} style={{ width: '100%', height: '100%' }}>
			<div style={{ backgroundColor: 'white', width: '30vw', height: '100%' }}>
				<IconButton style={{ position: 'absolute', right: 0 }} onClick={onClose}>
					<CancelIcon />
				</IconButton>
				<div style={{ width: '100%', textAlign: 'center' }}>
					<Icon style={{ color: '#86EDCD' }}>
						<NotificationsIcon />
					</Icon>
					<Typography variant='h6' style={{ color: '#757575' }}>
						<Translate id='common.addDocument' />
					</Typography>
				</div>
				<div style={{ margin: '2rem 1rem 1rem 1rem' }}>
					<Typography variant='subtitle2'>
						<Translate id='common.addDocument' />
					</Typography>
				</div>
				<div style={{ margin: '2rem' }}>
					<Dropzone onChange={handleFileChange} showPreviewInside={true}/>
					<Button
						data-cy='btn-save'
						type='button'
						color='primary'
						variant='outlined'
						disabled={isSavingFile || files.length === 0}
						style={{ width: '100%', marginTop: '5rem' }}
						onClick={() => handleSaveBtn(files)}
						>
						{<Translate id='common.save' />}
					</Button>
				</div>
			</div>
		</Drawer>
	)
}

export default DocumentToAddModal
