import { useState } from 'react';
import { buildFilterConfig } from '../serviceOrders/serviceHelpers/FilterConfiguration';

export default function useSorting() {
	const [sortingCriteria, setSortingCriteria] = useState({
		dataSource: 'attentionDate',
		by: 'AttentionDate',
		orderAsc: false
	});

	const handleSortingCriteriaChange = (sortBy, sortAscending) => {
		const { queryStringKey } = buildFilterConfig(sortBy);
		setSortingCriteria({ dataSource: sortBy, by: queryStringKey, orderAsc: sortAscending });
	};

	const order = sortingCriteria.orderAsc ? 'asc' : 'desc';

	return [
		sortingCriteria.by,
		order,
		sortingCriteria.dataSource,
		sortingCriteria.orderAsc,
		handleSortingCriteriaChange
	];
}
