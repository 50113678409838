import {fromJS } from 'immutable';
import * as workflowActions from './workflowActions';

export const workflowInitialState = fromJS({
    isLoadingWorflows: false,
    isSaving: false,
    isCancel: false,
    isActive: false,
})

export default function workflowsReducer(state = workflowInitialState, action){
    switch(action.type){
        case workflowActions.SEND_REMINDER_TO_SUPPLIER:{
            return state.merge({
            })
        }
        case workflowActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving,
                isCancel:false,
            })
        }
        case workflowActions.IS_CANCEL: {
            return state.merge({
                isCancel: action.isCancel,
                isSaving: false
            })
        }
        case workflowActions.IS_ACTIVE_WORKFLOW: {
            return state.merge({
                isActive: action.isActive,
            })
        }
        default:
            return state;
    }
}