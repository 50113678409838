import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Translate, getTranslate } from 'react-localize-redux'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

/** Material-UI import section */
import { Avatar, Card, CardContent, CardHeader, Tooltip, Fab, Typography } from '@material-ui/core'
import FindInPage from '@material-ui/icons/FindInPage'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import AcusesReciboIcon from '@material-ui/icons/PlayForWork'

/** Custom compoents import section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import ConsignmentsTable from './components/ConsignmentsTable'
import PanelConsignmentNote from './components/PanelConsignmentNote'
import OptionsMenu from '../common/OptionsMenu'
import DownloadAcknowledgmentFilesDialog from './components/DownloadAcknowledgmentFilesDialog'
import DownloadPendingFolio from './components/DownloadPendingFolio.js'
/** Redux import section */
import { useDispatch, useSelector } from 'react-redux'
import {
	loadAllConsignmentNoteFromBackEnd,
	addConsignmentNote,
	ADD_CONSIGNMENTNOTE,
	downloadReportRequests,
	downloadAllAcknowledgmentZipFile,
	downloadPendingFolioReport,
} from '../../store/consignmentNote/consignmentNoteActions'

/** Resources import section */
import { IsNullOrEmpty } from '../../store/helpers/StringHelper.js'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canBeModuleConsignmentNote, canBeModuleConsignmentNoteSupplier } from '../../store/helpers/RolesHelper'

const Index = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const queryParams = queryString.parse(location.search)

	/**Store local this component */
	const [showMenu, setShowMenu] = useState(true)
	const [itemToken, setItemToken] = useState(null)
	/** Defeines local state */
	const [loading, setLoading] = useState(true)
	const [openLateralPanel, setOpenLateralPanel] = useState(false)
	const [isOpenDownloadAllFiles, setIsOpenDownloadAllFiles] = useState(false)
	const [openModalReport, setOpenModalReport] = useState(false)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	/** Connect with redux */
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const consignmentNotes = useSelector((state) =>
		state.consignmentNote.get('consignmentNoteList')
			? converListToArray(state.consignmentNote.get('consignmentNoteList'))
			: []
	)
	const consignmentNoteCount = useSelector((state) => state.consignmentNote.get('consignmentNoteCount'))
	const selectedCompany = useSelector((state) =>
		state.profile.get('selectedCompany') ? state.profile.get('selectedCompany').toJS() : null
	)
	const isSaving = useSelector((state) => state.consignmentNote.get('isSaving'))
	const identifier = useSelector((state) => state.consignmentNote.get('identifier'))
	const isDownloadingReport = useSelector((state) => state.consignmentNote.get('isDonwloading'))
	const translate = useSelector((state) => getTranslate(state.localize))

	useEffect(() => {
		setLoading(true)
		const queryParams = queryString.parse(location.search)
		dispatch(loadAllConsignmentNoteFromBackEnd(queryParams, translate)).finally(() => {
			setLoading(false)
		})
	}, [dispatch, location.search])

	/**
	 * Function to open side panel
	 */
	const onOpenLateralPanel = () => {
		setOpenLateralPanel(!openLateralPanel)
	}

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	const getCounterMessage = () => {
		if (consignmentNotes) {
			let consignmentNotesTotal = consignmentNotes.length
			return (
				<div>
					{<Translate id='common.showing' />}&nbsp;
					<span className={classes.totalCounter}>{consignmentNotesTotal}</span>&nbsp;
					{<Translate id='common.of' />}&nbsp;
					<span className={classes.totalCounter}>{consignmentNoteCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	const getErrorInChangeStatus = (response) => {
		if (!IsNullOrEmpty(response.data) && typeof response.data === 'string') {
			if (response.data.includes('invalidTransporCodeExist'))
				return 'consignmentNote.errors.invalidTransporCodeExist'
			else return 'common.errorToSave'
		} else {
			return 'common.errorToSave'
		}
	}

	const onAddconsignmentNote = (itemModel) => {
		return dispatch(addConsignmentNote(itemModel))
			.then((requestToken) => {
				onOpenLateralPanel()
				if (!IsNullOrEmpty(requestToken)) {
					props.history.push(`/consignmentNote/${requestToken}`)
				}
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: getErrorInChangeStatus(response),
					toasterVariant: 'warning',
				})
			})
	}

	const handleCloseOptionsMenu = () => {
		setItemToken(null)
		setShowMenu(true)
	}

	const handleCloseModalReport = () =>{
		setOpenModalReport(false)
	}
	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof Index
	 */
	const handleClickOptionsMenu = (token) => {
		setItemToken(token)
	}

	const onDownloadReport = () => {
		dispatch(downloadReportRequests())
			.then(() => {})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToDownload',
					toasterVariant: 'error',
				})
			})
	}

	const onDownloadPendingFolioReport = (dateConfiguration) => {
		dispatch(downloadPendingFolioReport(dateConfiguration))
			.then(() => {
				setOpenModalReport(false)
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToDownload',
					toasterVariant: 'error',
				})
			})
	}

	const onDownloadAllAcknowledgmentZipFile = (allCodes, dateConfiguration, downloadType) => {
		return dispatch(downloadAllAcknowledgmentZipFile(allCodes, dateConfiguration, downloadType))
			.then(() => {
				setIsOpenDownloadAllFiles(false)
				setShowMenu(true)
				setItemToken(null)
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.downloadCorrectly',
					toasterVariant: 'success',
				})
			})
			.catch(() => {
				setShowMenu(true)
				setItemToken(null)
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToDownloadAcuses',
					toasterVariant: 'info',
				})
			})
	}

	const renderAction = () => {
		var options =[];
		if(canBeModuleConsignmentNote(userRolesByCompany)){
			options.push(
				{
					itemClick: () => onDownloadReport(),
					tooltipTranslation: <Translate id='common.downloadReport' />,
					menuItemIcon: <DownloadIcon color='secondary' />,
					isLoading: isDownloadingReport,
				},
				{
					itemClick: () => setIsOpenDownloadAllFiles(true),
					tooltipTranslation: <Translate id='common.downloadReportAcuses' />,
					menuItemIcon: <AcusesReciboIcon color='secondary' />,
					isLoading: isDownloadingReport,
				},
			)
		}
	
		options.push(
			{
				itemClick: () => setOpenModalReport(true),
				tooltipTranslation: <Translate id='common.downloadPendingFolioReport' />,
				menuItemIcon: <DownloadIcon color='secondary' />,
				isLoading: isDownloadingReport,
			}
		)

		if (showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={handleClickOptionsMenu}
					open={itemToken && itemToken === 'item.token'}
					handleClickAway={handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	return (
		<>
			<Card>
				<CardHeader
					title={<Typography variant='button'>{<Translate id='consignmentNote.title' />}</Typography>}
					avatar={
						<Avatar>
							<FindInPage />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								onChangeSearchValue={onChangeSearchValue}
								value={!!queryParams && queryParams.query ? queryParams.query : ''}
							/>
							{(canBeModuleConsignmentNote(userRolesByCompany) ||
								canBeModuleConsignmentNoteSupplier(userRolesByCompany)) && (
								<Tooltip title={<Translate id='consignmentNote.create.createNewfolio' />}>
									<Fab
										className={classes.marginIcon}
										size='small'
										color='primary'
										onClick={() => {
											onOpenLateralPanel()
										}}
									>
										<AddtIcon />
									</Fab>
								</Tooltip>
							)}
							{(canBeModuleConsignmentNote(userRolesByCompany) ||
								canBeModuleConsignmentNoteSupplier(userRolesByCompany)) &&
								renderAction()}
						</div>
					}
					subheader={getCounterMessage()}
				/>
				<CardContent>
					{
						{
							true: <LoadingPage />,
							false: (
								<ConsignmentsTable
									consignmentNotes={consignmentNotes}
									consignmentNoteCount={consignmentNoteCount}
								/>
							),
						}[loading]
					}
				</CardContent>
			</Card>

			{openLateralPanel && (
				<PanelConsignmentNote
					selectedCompany={selectedCompany}
					onCloseButtonClick={onOpenLateralPanel}
					onSaveButtonClick={onAddconsignmentNote}
					isSaving={identifier === ADD_CONSIGNMENTNOTE && isSaving}
				/>
			)}

			{isOpenDownloadAllFiles && (
				<DownloadAcknowledgmentFilesDialog
					open={isOpenDownloadAllFiles}
					onDownloadAllAcknowledgmentZipFile={onDownloadAllAcknowledgmentZipFile}
					onClose={() => setIsOpenDownloadAllFiles(false)}
					isSaving={isSaving}
				/>
			)}
			<DownloadPendingFolio
				open={openModalReport}
				onClose={handleCloseModalReport}
				isSaving={isDownloadingReport}
				onDonwloadItems={onDownloadPendingFolioReport}
			/>
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</>
	)
}

const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	marginIcon: {
		marginLeft: '5px',
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Index)))
