import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'
import { components } from 'react-select'
import Select from 'react-select/async'

import { Avatar, Grid } from '@material-ui/core'
import yourlogohere from '../../../resources/images/yourlogohere.png'
import Toaster from '../../common/Toaster'
import { loaAlldEmployeesFromBackEnd } from '../../../store/contractors/EmployeesAction'

/**
 * Create emplyoees selector
 *
 * @class EmployeesSelector
 * @extends {Component}
 */
class EmployeesSelector extends Component {
	/**
	 *Creates an instance of EmployeesSelector.
	 * @param {*} props
	 * @memberof EmployeesSelector
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			employeesList: [],
			isSearching: false,
			selectedEmployee: '',
			employees: [],
		}
	}

	/**
	 * React life-cycle
	 * If component did mount then load employees list on redux state
	 *
	 * @memberof EmployeesSelector
	 */
	componentDidMount() {
		this.setState({ isSearching: true })
		if (!this.props.isLoadingUser) {
			this.props.loadEmployees().then((employees) => {
				if (employees !== undefined) {
					this.setState({
						employees: employees,
						employeesList: employees,
						isSearching: false,
					})
					return employees
				} else return []
			})
		}
	}

	/**
	 * React life-cycle
	 * select default option if prop.value is found in list
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof EmployeesSelector
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.state.employeesList.length !== this.state.employees.length) {
			this.setState({ employeesList: this.state.employees })
			let selectedEmployee = this.state.employees.find((employee) => {
				return employee.user_name === this.props.value
			})
			if (selectedEmployee) {
				selectedEmployee = {
					label: selectedEmployee.full_name,
					value: selectedEmployee.user_name,
					avatar: selectedEmployee.image_employed,
				}
				this.setState({ selectedEmployee })
			}
		}
	}

	/**
	 * handler to search options in employees list
	 *
	 * @param {*} query
	 * @returns
	 * @memberof EmployeesSelector
	 */
	search(query) {
		this.setState({ isSearching: true })
		let filteredList = this.state.employeesList.filter((employee) => {
			return employee.name.toLowerCase().includes(query.toLowerCase())
		})
		return Promise.resolve(filteredList)
	}
	/**
	 * Get the employee matching with a query
	 *
	 * @param {*} query
	 * @returns
	 * @memberof EmployeesSelector
	 */
	getEmployees(query) {
		return this.search(query).then((employeesList) => {
			this.setState({
				employeesList,
				isSearching: false,
			})
			return employeesList.map((employee) => {
				return {
					value: employee.token,
					label: employee.name,
					avatar: employee.image_employed,
				}
			})
		})
	}

	/**
	 * Method to determine if input value has >=3 characters
	 * then isSearching is true and filter results
	 *
	 * @param {*} value
	 * @returns
	 * @memberof EmployeesSelector
	 */
	onInputChange(value) {
		let isSearching = value.length >= 2
		this.setState({ isSearching })
		return value
	}
	/**
	 * On selec a employee
	 *
	 * @param {*} option
	 * @memberof EmployeesSelector
	 */
	onSelectedEmployeeClick(option) {
		if (this.state.employeesList) {
			let selectedEmployee = this.state.employeesList.find((employee) => {
				return employee.token === option.value
			})
			if (selectedEmployee && this.props.onEmployeeSelected) {
				this.setState({
					selectedEmployee: {
						label: selectedEmployee.full_name,
						value: selectedEmployee.user_name,
						avatar: selectedEmployee.image_employed,
					},
				})
				this.props.onEmployeeSelected(selectedEmployee)
			} else {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			}
		}
	}

	/**
	 * Method to determine place holder message
	 *
	 * @returns
	 * @memberof EmployeesSelector
	 */
	getPlaceholder() {
		let id = 'contractorsModule.searchEmployees'
		if (this.state.isSearching || this.props.isLoadingEmployees) id = 'common.loadingPleaseWait'

		return <Translate id={id} />
	}

	/**
	 *
	 *
	 * @returns
	 * @memberof EmployeesSelector
	 */
	render() {
		if (this.state.employeesList !== undefined) {
			let selectedEmployee = this.state.employeesList.find((user) => {
				return user.user_name === this.props.value
			})
			if (selectedEmployee)
				selectedEmployee = {
					label: selectedEmployee.full_name,
					value: selectedEmployee.user_name,
					avatar: selectedEmployee.image_employed,
				}
		}
		return (
			<React.Fragment>
				<Select
					placeholder={this.getPlaceholder()}
					value={this.state.selectedEmployee}
					loadOptions={this.state.isSearching ? this.getEmployees : ''}
					cacheOptions
					onChange={this.onSelectedEmployeeClick}
					onInputChange={this.onInputChange}
					isDisabled={
						this.props.isLoadingEmployees || this.props.isDisabled
							? this.props.isLoadingEmployees || this.props.isDisabled
							: false
					}
					isLoading={this.props.isLoadingEmployees || this.state.isSearching}
					styles={customStyles}
					components={{ Option }}
				/>
				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</React.Fragment>
		)
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingEmployees: state.contractorEmployees.get('isLoadingEmployees'),
		isSavingEmployee: state.contractorEmployees.get('isSavingEmployee'),
		employees: state.contractorEmployees.get('employees')
			? state.contractorEmployees.get('employees').toJS()
			: null,
		sortCriteria: state.contractorEmployees.get('sortCriteria')
			? state.contractorEmployees.get('sortCriteria').toJS()
			: null,
		searchQuery: state.contractorEmployees.get('searchQuery'),
		page: state.contractorEmployees.get('page'),
		rowsPerPage: state.contractorEmployees.get('rowsPerPage'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadEmployees: () => {
			return dispatch(loaAlldEmployeesFromBackEnd(true))
		},
	}
}

/** Custom styles for the component */
const customStyles = {
	menu: (base) => ({ ...base, zIndex: 20 }),
	loadingIndicator: (base) => ({ ...base, zIndex: 60 }),
}

const Option = (props) => {
	const { data } = props
	let urlImageProfile = yourlogohere
	if (data.avatar) urlImageProfile = `${process.env.REACT_APP_SIP_URL_LOGOS_ADMINISTRATION}/${data.avatar}`
	return (
		<components.Option {...props}>
			<Grid container spacing={24} alignItems='center'>
				<Grid item xs={2}>
					<Avatar component='div' name={data.label} src={urlImageProfile} />
				</Grid>
				<Grid item xs={10}>
					{data.label}
				</Grid>
			</Grid>
		</components.Option>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesSelector)
