import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInputOptions from '../common/SearchInput/SearchInputOptions'
import TrackingStatus from '../common/TrackingStatus'
import Toaster from '../common/Toaster'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import RawMaterialNonConfIcon from '@material-ui/icons/BugReport'
import AddIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadRawMaterialNonConformitiesFromBackEnd,
	getRawMaterialNonConformitiesCount,
	CHANGE_RAW_MATERIALS_SORT_CRITERIA,
	CHANGE_RAW_MATERIALS_QUERY,
	downloadRawMaterialReport,
	CHANGE_RAW_MATERIALS_PAGINATION,
	IS_LOADING_ALL_RAW_MATERIALS,
} from '../../store/rawMaterialNonConformity/RawMaterialActions'

import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canUserCreateReportModuleQuality } from '../../store/helpers/RolesHelper'
import { COMPANY_IMBERA_BRASIL } from '../../store/helpers/ResourcesHelper'
import DataTable from '../common/DataTable'

/**
 * Raw material non conformities view
 *
 * @class rawMaterialNonConformities
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance Raw material non conformities
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadRawMaterialNonConformity(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.props.isAllReports
			)
			this.props.getRawMaterialNonConformityCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadRawMaterialNonConformity()
			this.props.getRawMaterialNonConformityCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage ||
			prevProps.isAllReports !== this.props.isAllReports
		) {
			this.props.loadRawMaterialNonConformity(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.props.isAllReports
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.onLoadingAllReports(false)
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteriaReports() {
		this.props.onLoadingAllReports(true)
	}

	/**
	 *On download report raw material non conformities
	 *
	 * @memberof Index
	 */
	handleDownloadReportRawMaterial() {
		this.props
			.downloadRawMaterialReport()
			.then(() => {
				this.showSuccessToaster('common.downloadCorrectly')
			})
			.catch(() => {
				this.showErrorToaster('common.errorToDownload')
			})
	}

	/**
	 * Method to custom render in datalist
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof Index
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'tracking') {
			return <TrackingStatus tracking={item.tracings} />
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingRawMaterials === true) {
			return <LoadingPage />
		} else if (this.props.rawMaterialNonConformities === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let tableConfiguration = TableInternalConfiguration
			if (!IsCurrentActiveDirectoryUser(this.props.user)) {
				tableConfiguration = TableExtenalInternalConfiguration
			}
			return (
				<DataTable
					data={this.props.rawMaterialNonConformities}
					configuration={tableConfiguration}
					showFilters
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(rawMaterialNonConformity) => {
						this.props.history.push(`/rawmaterialnonconformity/${rawMaterialNonConformity.token}`)
					}}
					isIndex
					totalRows={this.props.rawMaterialCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "errorToSave"
	 *
	 * @param {string} [idTranslation="common.errorToSave"]
	 * @memberof show
	 */
	showErrorToaster(idTranslation = 'common.errorToSave') {
		this.setState({
			showToaster: true,
			toasterVariant: 'error',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "saveCorrectly"
	 *
	 * @param {string} [idTranslation="common.saveCorrectly"]
	 * @memberof Show
	 */
	showSuccessToaster(idTranslation = 'common.saveCorrectly') {
		this.setState({
			showToaster: true,
			toasterVariant: 'success',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Get raw materials non conformity counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getRawMaterialsCounterMessage() {
		if (this.props.rawMaterialNonConformities) {
			let totalRawMaterials = this.props.rawMaterialNonConformities.length
			let companyBrasil = this.props.selectedCompany.company_code === COMPANY_IMBERA_BRASIL
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalRawMaterials}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.rawMaterialCount}</span>&nbsp;
					{this.props.translate('common.records')}
					<br />
					{!companyBrasil && this.props.translate('rawmaterialnonconformity.folioTracking')}
					{companyBrasil && this.props.translate('qualityfailurereport.folioTracking')}
				</div>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof rawMaterialNonConformities
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<RawMaterialNonConfIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInputOptions
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
								isSearchAllReports
								onSearchAllReports={this.onChangeSortCriteriaReports}
							/>

							{IsCurrentActiveDirectoryUser(this.props.user) &&
								canUserCreateReportModuleQuality(this.props.userRolesByCompany) && (
									<Tooltip title={<Translate id='rawmaterialnonconformity.addRawMaterial' />}>
										<Fab
											size='small'
											color='primary'
											onClick={() => {
												this.props.history.push(`/rawmaterialnonconformity/create`)
											}}
										>
											<AddIcon />
										</Fab>
									</Tooltip>
								)}
							{IsCurrentActiveDirectoryUser(this.props.user) &&
								canUserCreateReportModuleQuality(this.props.userRolesByCompany) && (
									<Tooltip title={<Translate id='rawmaterialnonconformity.downloadReport' />}>
										<IconButton
											disabled={this.props.isLoading}
											onClick={() => this.handleDownloadReportRawMaterial()}
											color='secondary'
										>
											<DownloadIcon />
										</IconButton>
									</Tooltip>
								)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.rawMaterialsNotification' />{' '}
						</Typography>
					}
					subheader={this.getRawMaterialsCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableInternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'tracking',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		},
		{
			header: <Translate id='common.partNumber' />,
			dataSource: 'full_part_description',
		},
		{
			header: <Translate id='qualityfailurereport.failure' />,
			dataSource: 'failure_name',
			isSortable: true,
		},
	],
}

const TableExtenalInternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'tracking',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='common.partNumber' />,
			dataSource: 'full_part_description',
		},
		{
			header: <Translate id='qualityfailurereport.failure' />,
			dataSource: 'failure_name',
			isSortable: true,
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingRawMaterials: state.rawMaterialNonConformity.get('isLoadingRawMaterials'),
		rawMaterialNonConformities: state.rawMaterialNonConformity.get('rawMaterialNonConformities')
			? state.rawMaterialNonConformity.get('rawMaterialNonConformities').toJS()
			: null,
		sortCriteria: state.rawMaterialNonConformity.get('sortCriteria')
			? state.rawMaterialNonConformity.get('sortCriteria').toJS()
			: null,
		searchQuery: state.rawMaterialNonConformity.get('searchQuery'),
		page: state.rawMaterialNonConformity.get('page'),
		rowsPerPage: state.rawMaterialNonConformity.get('rowsPerPage'),
		isAllReports: state.rawMaterialNonConformity.get('isAllRawMaaterialsReports'),
		rawMaterialCount: state.rawMaterialNonConformity.get('rawMaterialCount'),
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isLoading: state.rawMaterialNonConformity.get('isLoading'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadRawMaterialNonConformity: (query, orderBy, sortAscending, page, rowsPerPage, isAllReports) => {
			dispatch(
				loadRawMaterialNonConformitiesFromBackEnd(
					query,
					orderBy,
					sortAscending,
					page,
					rowsPerPage,
					isAllReports
				)
			)
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_RAW_MATERIALS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_RAW_MATERIALS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_RAW_MATERIALS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		onLoadingAllReports: (isAllReports) => {
			dispatch({
				type: IS_LOADING_ALL_RAW_MATERIALS,
				isAllReports: isAllReports,
			})
		},
		getRawMaterialNonConformityCount: () => {
			dispatch(getRawMaterialNonConformitiesCount())
		},
		downloadRawMaterialReport: () => {
			return dispatch(downloadRawMaterialReport())
		},
	}
}

const styles = (theme) => ({
	avatar: {},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
