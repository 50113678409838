import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInputOptions from '../common/SearchInput/SearchInputOptions'
import TrackingStatus from '../common/TrackingStatus'
import OptionsMenu from '../common/OptionsMenu'
import Toaster from '../common/Toaster'
import DataTable from '../common/DataTable'
import DonwloadReportDialog from '../common/DonwloadReportDialog'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import QualityFailureReportIcon from '@material-ui/icons/WarningOutlined'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import ArchiveDownloadIcon from '@material-ui/icons/Archive'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadQualityFailureReportsFromBackEnd,
	getQualityFailureReportsCount,
	CHANGE_FAILURE_REPORTS_SORT_CRITERIA,
	CHANGE_FAILURE_REPORTS_QUERY,
	downloadFailuresWithContainmentActionsFile,
	downloadFailureQualityReport,
	downloadFailureQualityReportByRangeDate,
	CHANGE_FAILURE_REPORTS_PAGINATION,
	IS_LOADING_ALL_FAILURE_REPORTS,
} from '../../store/qualityFailureReport/QualityFailureReportAction'

import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canUserCreateReportModuleQuality } from '../../store/helpers/RolesHelper'

/**
 * Quality Failure Reports view
 *
 * @class quality Failure Reports
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance quality Failure Reports
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			itemToken: null,
			showMenu: true,
			openModalFailureMonthlyReport: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadQualityFailureReports(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.props.isAllReports
			)
			this.props.getQualityFailureReportsCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadQualityFailureReports()
			this.props.getQualityFailureReportsCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage ||
			prevProps.isAllReports !== this.props.isAllReports
		) {
			this.props.loadQualityFailureReports(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.props.isAllReports
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.onLoadingAllReports(false)
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteriaReports() {
		this.props.onLoadingAllReports(true)
	}

	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof InvestigationLines
	 */
	handleClickOptionsMenu(token) {
		this.setState({
			itemToken: token,
		})
	}

	/**
	 * Download contaiment actions report
	 *
	 * @memberof Index
	 */
	handleDownloadReportActions() {
		this.props
			.downloadFailuresWithContainmentActionsFile()
			.then(() => {
				this.showSuccessToaster('common.downloadCorrectly')
				this.handleCloseOptionsMenu()
			})
			.catch(() => {
				this.showErrorToaster('common.errorToDownload')
			})
	}

	/**
	 * Download quality failure report
	 *
	 * @memberof Index
	 */
	handleDownloadQualityFailureReport() {
		this.props
			.downloadFailureQualityReport()
			.then(() => {
				this.showSuccessToaster('common.downloadCorrectly')
				this.handleCloseOptionsMenu()
			})
			.catch(() => {
				this.showErrorToaster('common.errorToDownload')
			})
	}

	/**
	 *Download report of failure by month
	 *
	 * @memberof Index
	 */
	onDownloadReportByRangeDate(startDate, endDate) {
		this.props
			.downloadFailureQualityReportByRangeDate(startDate, endDate)
			.then(() => {
				this.showSuccessToaster('common.downloadCorrectly')
				this.handleCloseModal()
			})
			.catch((error) => {
				this.showErrorToaster('common.errorToDownload')
			})
	}

	/**
	 * Handle on close optons menu to set void item token in state
	 *
	 * @memberof index
	 */
	handleCloseOptionsMenu() {
		if (!this.state.openModalFailureMonthlyReport) {
			this.setState(
				{
					itemToken: '',
					showMenu: false,
				},
				() => {
					this.setState({ showMenu: true })
				}
			)
		}
	}

	/**
	 *Open modal for set report by mont
	 *
	 * @memberof Index
	 */
	handleOpenModal() {
		this.setState({
			openModalFailureMonthlyReport: true,
		})
	}

	/**
	 * close modal to report by month
	 */
	handleCloseModal() {
		this.setState(
			{
				openModalFailureMonthlyReport: false,
			},
			() => this.handleCloseOptionsMenu()
		)
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "saveCorrectly"
	 *
	 * @param {string} [idTranslation="common.saveCorrectly"]
	 * @memberof Show
	 */
	showSuccessToaster(idTranslation = 'common.saveCorrectly') {
		this.setState({
			showToaster: true,
			toasterVariant: 'success',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "errorToSave"
	 *
	 * @param {string} [idTranslation="common.errorToSave"]
	 * @memberof show
	 */
	showErrorToaster(idTranslation = 'common.errorToSave') {
		this.setState({
			showToaster: true,
			toasterVariant: 'error',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 *Render action button in card quality failure report
	 *
	 * @memberof Index
	 */
	renderAction() {
		var options = []
		options.push({
			itemClick: () => this.handleDownloadReportActions(),
			tooltipTranslation: <Translate id='qualityfailurereport.downloadFailureReportContainedAction' />,
			menuItemIcon: <DownloadIcon color='secondary' />,
			isLoading: this.props.isLoading,
		})
		options.push({
			itemClick: () => this.handleOpenModal(),
			tooltipTranslation: <Translate id='qualityfailurereport.downloadFailureReportByMonth' />,
			menuItemIcon: <ArchiveDownloadIcon color='secondary' />,
			isLoading: this.props.isLoading,
		})
		options.push({
			itemClick: () => this.handleDownloadQualityFailureReport(),
			tooltipTranslation: <Translate id='qualityfailurereport.downloadFailureReport' />,
			menuItemIcon: <DownloadIcon color='secondary' />,
			isLoading: this.props.isLoading,
		})
		if (this.state.showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === 'item.token'}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	/**
	 * Method to custom render in datalist
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof Index
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'tracking') {
			return <TrackingStatus tracking={item.tracings} />
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingFailureReports === true) {
			return <LoadingPage />
		} else if (this.props.qualityFailureReports === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let tableConfiguration = TableInternalConfiguration
			if (!IsCurrentActiveDirectoryUser(this.props.user)) {
				tableConfiguration = TableExtenalConfiguration
			}
			return (
				<DataTable
					data={this.props.qualityFailureReports}
					configuration={tableConfiguration}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(qualityFailureReport) => {
						this.props.history.push(`/qualityfailurereport/${qualityFailureReport.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.failureReportCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get quality Failure Reports counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getRawMaterialsCounterMessage() {
		if (this.props.qualityFailureReports) {
			let totalReports = this.props.qualityFailureReports.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalReports}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.failureReportCount}</span>&nbsp;
					{this.props.translate('common.records')}
					<br />
					{this.props.translate('qualityfailurereport.folioTracking')}
				</div>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof isLoadingFailureReports
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<QualityFailureReportIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInputOptions
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
								isSearchAllReports
								onSearchAllReports={this.onChangeSortCriteriaReports}
							/>

							{IsCurrentActiveDirectoryUser(this.props.user) &&
								canUserCreateReportModuleQuality(this.props.userRolesByCompany) && (
									<Tooltip title={<Translate id='qualityfailurereport.addQualityFailure' />}>
										<Fab
											size='small'
											color='primary'
											onClick={() => {
												this.props.history.push(`/qualityfailurereport/create`)
											}}
										>
											<AddtIcon />
										</Fab>
									</Tooltip>
								)}
							{IsCurrentActiveDirectoryUser(this.props.user) &&
								canUserCreateReportModuleQuality(this.props.userRolesByCompany) &&
								this.renderAction()}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.failureReportQuality' />{' '}
						</Typography>
					}
					subheader={this.getRawMaterialsCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog for select month to download report */}
				<DonwloadReportDialog
					title={<Translate id='qualityfailurereport.downloadFailureReportByMonth' />}
					open={this.state.openModalFailureMonthlyReport}
					onClose={this.handleCloseModal}
					isSaving={this.props.isLoading}
					onDonwloadItems={this.onDownloadReportByRangeDate}
				/>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableInternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'tracking',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='common.preparedBy' />,
			dataSource: 'user_name_report',
			isSortable: true,
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		},
		{
			header: <Translate id='common.partNumber' />,
			dataSource: 'full_part_description',
			isSortable: true,
		},
		{
			header: <Translate id='qualityfailurereport.failure' />,
			dataSource: 'failure_name',
			isSortable: true,
		},
	],
}

const TableExtenalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'tracking',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='common.preparedBy' />,
			dataSource: 'user_name_report',
		},
		{
			header: <Translate id='common.partNumber' />,
			dataSource: 'full_part_description',
			isSortable: true,
		},
		{
			header: <Translate id='qualityfailurereport.failure' />,
			dataSource: 'failure_name',
			isSortable: true,
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingFailureReports: state.qualityFailureReport.get('isLoadingFailureReports'),
		qualityFailureReports: state.qualityFailureReport.get('qualityFailureReports')
			? state.qualityFailureReport.get('qualityFailureReports').toJS()
			: null,
		sortCriteria: state.qualityFailureReport.get('sortCriteria')
			? state.qualityFailureReport.get('sortCriteria').toJS()
			: null,
		searchQuery: state.qualityFailureReport.get('searchQuery'),
		page: state.qualityFailureReport.get('page'),
		rowsPerPage: state.qualityFailureReport.get('rowsPerPage'),
		failureReportCount: state.qualityFailureReport.get('failureReportCount'),
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isLoading: state.qualityFailureReport.get('isLoading'),
		isAllReports: state.qualityFailureReport.get('isAllReports'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadQualityFailureReports: (query, orderBy, sortAscending, page, rowsPerPage, isAllReports) => {
			dispatch(
				loadQualityFailureReportsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, isAllReports)
			)
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_FAILURE_REPORTS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_FAILURE_REPORTS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_FAILURE_REPORTS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		onLoadingAllReports: (isAllReports) => {
			dispatch({
				type: IS_LOADING_ALL_FAILURE_REPORTS,
				isAllReports: isAllReports,
			})
		},
		getQualityFailureReportsCount: () => {
			dispatch(getQualityFailureReportsCount())
		},
		downloadFailuresWithContainmentActionsFile: () => {
			return dispatch(downloadFailuresWithContainmentActionsFile())
		},
		downloadFailureQualityReport: () => {
			return dispatch(downloadFailureQualityReport())
		},
		downloadFailureQualityReportByRangeDate: (startDate, endDate) => {
			return dispatch(downloadFailureQualityReportByRangeDate(startDate, endDate))
		},
	}
}

const styles = (theme) => ({
	avatar: {},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
