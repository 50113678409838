import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'auto-bind'
import ReactGA from 'react-ga';

/** Import language sections */
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import translations from './resources/translations.json'
import UserManager from './store/helpers/UserManager';

/** Import components */
import AnonymousView from './views/AnonymousView'
import MainContainer from './views/MainContainer';

/** Resources import themes portal */
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themeImbera from './resources/themes/themeImbera.json';
import themeGarantias from './resources/themes/themeGarantias.json';
import themePtm from './resources/themes/themePTM.json';
import themeRepare from './resources/themes/themeRepare.json';
import themeMetallic from './resources/themes/themeMetalicos.json';
import themeTorrey from './resources/themes/themeTorrey.json';

import {
    COMPANY_THEME_ACTIVE, COMPANY_IMBERA, COMPANY_PTM, COMPANY_REPARE, COMPANY_METALICOS, COMPANY_IMBERA_WARRANTY,
    COMPANY_FERSA, COMPANY_COMITOR, COMPANY_TEQMA, COMPANY_PACTO, COMPANY_FABATSA, COMPANY_IMBERA_COLOMBIA, COMPANY_IMBERA_BRASIL
} from './store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from './store/helpers/StringHelper.js';
import { ENVIROMENT_TYPE_PRODUCTION } from './store/helpers/ResourcesHelper'

/**
 * App component
 *
 * @class App
 * @extends {Component}
 */
class App extends Component {

    /**
     *Creates an instance of App.
     * @param {*} props
     * @memberof App
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            themeActive: createMuiTheme(themeImbera),
            user: null,
        }

        let defaultLanguage = localStorage.getItem("language");
        if (!defaultLanguage) {
            defaultLanguage = 'es';
        }

        this.props.initialize({
            languages: [
                { name: "EspaÃ±ol", code: "es" },
                { name: "English", code: "en" },
                { name: "Portugues", code: "pt" },
            ],
            translation: translations,
            options: {
                renderToStaticMarkup,
                defaultLanguage: defaultLanguage
            }
        })
    }

    initializeReactGA(pageAddress) {
        ReactGA.initialize('UA-147073792-1');
        ReactGA.pageview(pageAddress);
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        /** Redirect to url stored in local storage if it exist when is open from link */
        if (process.env.PUBLIC_URL === ENVIROMENT_TYPE_PRODUCTION) {
            let urlToRedirect = localStorage.getItem('relativeUrl')
            if (!IsNullOrEmpty(urlToRedirect)) {
                this.props.history.push(urlToRedirect);
            }
        }

        /// get theme active or change theme active
        let themeActive = localStorage.getItem(COMPANY_THEME_ACTIVE);
        if (themeActive) {
            this.changeTheme(themeActive);
        }
        /// verify if exit uder 
        UserManager.getUser().then(user => {
            this.setState({ user })
        })

    }

    /**
    * Function to change theme company
    * @param {*} company 
    */
    changeTheme(company) {
        switch (company) {
            case COMPANY_IMBERA:
            case COMPANY_IMBERA_COLOMBIA:
            case COMPANY_IMBERA_BRASIL:
                this.setState({ themeActive: createMuiTheme(themeImbera) });
                break;
            case COMPANY_PTM:
                this.setState({ themeActive: createMuiTheme(themePtm) });
                break;
            case COMPANY_REPARE:
                this.setState({ themeActive: createMuiTheme(themeRepare) });
                break;
            case COMPANY_METALICOS:
                this.setState({ themeActive: createMuiTheme(themeMetallic) });
                break;
            case COMPANY_IMBERA_WARRANTY:
                this.setState({ themeActive: createMuiTheme(themeGarantias), });
                break;
            case COMPANY_FERSA:
            case COMPANY_COMITOR:
            case COMPANY_TEQMA:
            case COMPANY_PACTO:
            case COMPANY_FABATSA:
                this.setState({ themeActive: createMuiTheme(themeTorrey) });
                break;
            default:
                break;
        }
    }

    /**
     * Render
     *
     * @returns
     * @memberof App
     */
    render() {
        const search = this.props.location.search
        if (!IsNullOrEmpty(search)) {
            localStorage.setItem("url", search)
        }
        this.initializeReactGA(this.props.location.pathname);
        let container = "";
        if (!this.state.user) {
            container = <AnonymousView {...this.props} />
        }
        else {
            container = <MainContainer {...this.props} />
        }
        return (<MuiThemeProvider theme={this.state.themeActive}>
            {container}
        </MuiThemeProvider>)
    }
}


/**
 *
 * Defines the properties injecteded from the state to App props
 *
 * @param {object} state
 * @returns
 */
function mapStateToProps(store) {
    return {
        oidc: store.oidc
    }
}

export default withLocalize(connect(mapStateToProps, null)(App));
