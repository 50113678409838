
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

/** Import component section */
import Dialog from '../../common/Dialog';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
import {
    APPROVAL_REQUOTE, APPROVAL_REJECTED,
    APPROVAL_ACEPTED
} from '../../../store/helpers/ResourcesHelper';
import {
    COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER,
    COLOR_STATUS_INFO
} from '../../../store/helpers/StatusColorConstants';

/**
 * Dialog to accepted, rejected or decline quote
 */
class AcceptedRejectedDialog extends Component {

    /**
   *Creates an instance of DialogActions.
   * @param {*} props
   * @memberof DialogActions
   */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
     * Dialog to update information
     */
    render() {
        const { classes } = this.props;
        let hasComment = !IsNullOrEmpty(this.props.comments);
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            {<Translate id="quotes.acceptedRejectDetail" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <React.Fragment>
                        <Toolbar>
                            <div className={classes.grow}></div>
                            {
                                this.props.isSaving === true ?
                                    <Button variant="outlined" color="secondary" className={classes.button} disabled>
                                        <Translate id="common.saving" />
                                    </Button>
                                    :
                                    <React.Fragment>
                                        <Button variant="outlined" style={{ color: COLOR_STATUS_SUCCESS, borderColor: COLOR_STATUS_SUCCESS }} className={classes.button}
                                            data-cy="btn-accept"
                                            onClick={() => this.props.onClickAcceptRejectRequote(APPROVAL_ACEPTED)}
                                        >
                                            <Translate id="common.accept" />
                                        </Button>

                                        <Button variant="outlined" style={{ color: hasComment && COLOR_STATUS_DANGER, borderColor: hasComment && COLOR_STATUS_DANGER }} className={classes.button}
                                            data-cy="btn-reject"
                                            disabled={!hasComment}
                                            onClick={() => this.props.onClickAcceptRejectRequote(APPROVAL_REJECTED)}
                                        >
                                            <Translate id="common.reject" />
                                        </Button>

                                        <Button variant="outlined" style={{ color: hasComment && COLOR_STATUS_INFO, borderColor: hasComment && COLOR_STATUS_INFO }} className={classes.button}
                                            data-cy="btn-requote"
                                            disabled={!hasComment}
                                            onClick={() => this.props.onClickAcceptRejectRequote(APPROVAL_REQUOTE)}
                                        >
                                            <Translate id="quotes.requote" />
                                        </Button>
                                    </React.Fragment>
                            }
                        </Toolbar>
                        {
                            this.props.isSaving === true ?
                                <LinearProgress color="secondary" variant="query" />
                                : null
                        }
                    </React.Fragment>
                }>

                <form>
                    <TextField fullWidth
                        className={classes.textField}
                        multiline
                        rowsMax="8"
                        value={this.props.comments}
                        onChange={(event) => { this.props.updateAcceptedItemProperty(event.target.value) }}
                        onKeyDown={this.onKeyDown}
                        margin="normal"
                        variant="outlined"
                    />
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(AcceptedRejectedDialog));