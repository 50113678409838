import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Translate } from "react-localize-redux";
import { Grid } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import SuppliersSelector from '../../common/SupplierSelector'


/**
 * Provedure resend provedurie from otrhe rsupplier
 *
 * @class ResendProvedureDialog
 * @extends {Component}
 */
class ResendProvedureDialog extends Component {

    /**
     *Creates an instance of MemoItemDialog.
     * @param {*} props
     * @memberof MemoItemDialog
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            supplierSelected: null,
        }
    }

      /**
     * On seletecd supplier
     */
    onSupplierSelected(supplier) {
        this.setState({ supplierSelected: supplier });
    }

    /**
     * canBeSaved
     */
    canBeSaved() {
        if (this.state.supplierSelected === null)
            return false;
        return true;
    }

    /**
     * resendAlternativeProvedurie
     */
    resendAlternativeProvedurie() {
        this.props.onResendProveduriaFromSupplier(this.state.supplierSelected)
    }

    /**
     * onCancelOption
     */
    onCancelOption(){
        this.setState({ supplierSelected: null });
        this.props.onClose();
    }

    /**
     *
     *
     * @returns
     * @memberof MemoItemDialog
     */
    render() {
        let open = this.props.show ? this.props.show : false;
        return (
            <Dialog
                open={open}>
                <DialogTitle id="form-dialog-title">
                    <Translate id="alternatingProveeduria.resendAlternativeProveduria" />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="caption" gutterBottom>
                                <Translate id="alternatingProveeduria.messageResend" />
                            </Typography>
                            <br />
                            <SuppliersSelector
                                onSupplierSelected={(supplier) => { this.onSupplierSelected(supplier) }}
                            />
                            <br />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type="button" color="primary"
                        onClick={this.resendAlternativeProvedurie}
                        disabled={!this.canBeSaved() || this.props.isSaving}>
                        <Translate id="common.send" />
                    </Button>
                    <Button onClick={this.onCancelOption} color="primary">
                        <Translate id="common.cancel" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ResendProvedureDialog.defaultProps = {
    show: false,
}

const dialogStyles = theme => ({
});

export default withStyles(dialogStyles, { withTheme: true })(ResendProvedureDialog);