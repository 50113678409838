/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import { Typography, CardContent} from "@material-ui/core";

/**Import components section*/

/**Import resources section*/
import {BACKGROUND_COLOR_GRAY_BIDDING } from '../../../store/helpers/StatusColorConstants';

/**Import actions section */
const HeaderResult = (props) => {
  const { classes, topics } = props;

  return (
    <div className={classes.cardContent} >
      {topics.map((topic, index) => (    
        <CardContent key={topic.Id} className={classes.content} >
          <div className={classes.display}>
            <div className={classes.percent}>
              <Typography variant="h5" >{topic.PercentageForSupplier}%</Typography>
            </div>
            <div className={classes.line} style={{backgroundColor: topic.ColorTopic}}></div>
            <div className={classes.title}>
                <Typography variant="caption" style={{color: topic.ColorTopic}}>
                  {topic.ShortName}
                </Typography>
            </div>
          </div>
        </CardContent>
        ))}
    </div>
    )
};

const styles = (theme) => ({
  cardContent:{
        display: 'flex',
        padding: 4,
        marginLeft: "5px",
        borderRadius: '10px',
    },
    content:{
        marginLeft: '8px',
        marginRight: '2px',
        textAlign: 'center',
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
        borderRadius: '15px',
        flex: '1 1 0',
    },
    display:{
      display: 'flex',      
    },
    line: {
      width: '5px',
      height: 'auto',
      margin: 2
    },
    title: {
        flex: '3 1 0',
        marginTop: '10px',
        marginBottom: '10px',
        fontSize: "10"
    },
    percent: {
        flex: '2 1 0',
        marginTop: '15px',
        marginBottom: '10px',
    },
});

HeaderResult.propTypes = {
  topics:PropTypes.array,
  isSummary: PropTypes.bool,
};

HeaderResult.defaultProps = {
  topics: [],
  isSummary: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(HeaderResult)
);