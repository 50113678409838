/**Import react section */
import React from 'react'
import { Translate } from "react-localize-redux";
import { ROLE_BIDDING_RESPONSIBLE, IsUserInRole, canBeModuleBiddingSupplier } from './RolesHelper'
import {
    BiddingStatus, CriteriaType, BiddingMaterialOrServices,
    ItemProposalStatus, CategoryType, NumberFormatType, ScopeType,
    BiddingSuppliers
} from './AppConstants'
import { IsUserSupplierAdminExternal } from './SessionHelper';
import { currencyFormat, percengeFormat, intRound } from './AmountHelper'

import {
    COLOR_STATUS_GRAY, COLOR_STATUS_DANGER, COLOR_STATUS_INFO,
    COLOR_STATUS_WARNING, COLOR_STATUS_SUCCESS
} from '../helpers/StatusColorConstants'
import { IsNullOrEmpty } from './StringHelper'

export const WINNER_RESULT_BUTTONS = 'WINNER_RESULT_BUTTONS';
export const RESULT_TOTAL = 'TOTAL';
export const RESULT_TOTAL_WINNER_IN_ITEMS = 'ITEMS GANADOS';
export const SUPPLIERS_FINAL_COMMENTS = 'COMENTARIOS';
export const ADDITIONAL_COSTS = 'Otros gastos';
export const IS_MATERIAL_FROM_SAP = 'IS_MATERIAL_FROM_SAP';
export const IS_MATERIAL_FROM_GENERAL = 'IS_MATERIAL_FROM_GENERAL';
export const IS_DECLINED_MATERIAL_BY_SUPPLIER = 'IS_DECLINED_MATERIAL_BY_SUPPLIER';

export function getPercentageDecimal(weighing) {
    if (!isNaN(weighing)) {
        return intRound(weighing / 100, 3)
    }
    return 0
}

export function getPercentageInt(weighing) {
    if (!isNaN(weighing)) {
        return intRound(weighing * 100, 2)
    }
    return 0
}

export function getExchangeRates(exchangeRates) {
    let exchangeRatesMapped = {};
    exchangeRates.forEach(exchangeRate => {
        Object.entries(exchangeRate).forEach(([key, value]) => {
            if (key !== "Name" && key !== "ExchangeRate") {
                exchangeRatesMapped[value] = exchangeRate.ExchangeRate
            }
        });
    })
    return exchangeRatesMapped
}

export function getCommentsSuppliersForFinishedBidding(suppliers) {
    let comentsMapped = {};
    suppliers.forEach(supplier => {
        Object.entries(supplier).forEach(([key, value]) => {
            if (key !== "Name" && key !== "Comments") {
                comentsMapped[value] = supplier.Comments
            }
        });
    })
    return comentsMapped
}

/**
 * Get user mapped
 * @param {*} roles 
 */
export function getResponsiblesFromBidding(internalUsers, responsibles, userProfile) {
    let allResponsibles = []
    if (internalUsers === undefined)
        return []
    if (userProfile === undefined)
        return []
    let users = internalUsers.find(role => {
        return Object.keys(role)[0] === ROLE_BIDDING_RESPONSIBLE
    })
    let mappedUserProfile = {
        name: userProfile ? userProfile.name : "",
        user_name: userProfile ? userProfile.username : "",
    }
    let allUsersInRoleAdministrator = users ? users[ROLE_BIDDING_RESPONSIBLE] : []
    allResponsibles.push(mappedUserProfile)
    responsibles.forEach(userName => {
        let existUser = allResponsibles.find(x => x.user_name === userName)
        if (existUser === undefined) {
            let userAddedMapped = allUsersInRoleAdministrator.find(x => x.user_name === userName)
            if (userAddedMapped)
                allResponsibles.push(userAddedMapped)
        }
    })
    return allResponsibles
}

export const canBeAddQuestionAndAnswerBySupplier = (bidding, userProfile, roles, isExternalUser) => {
    if (bidding === null || bidding === undefined)
        return false
    if (bidding.ProposalStatus === undefined)
        return false
    if (isExternalUser) {
        switch (Number(bidding.ProposalStatus)) {
            case ItemProposalStatus.DRAFT:
                return true
            case ItemProposalStatus.COMPLETED: {
                if (bidding.Status !== BiddingStatus.FINISHED)
                    return true
                else
                    return false
            }
            case ItemProposalStatus.DECLINED:
                return false
            default:
                return false
        }
    } else {
        switch (Number(bidding.ProposalStatus)) {
            case ItemProposalStatus.DRAFT:
                return true
            case ItemProposalStatus.COMPLETED: {
                if (bidding.Status !== BiddingStatus.FINISHED)
                    return true
                else
                    return false
            }
            case ItemProposalStatus.DECLINED:
                return false
            default:
                return false
        }
    }
}

export const canBeAddAnswer = (bidding) => {
    if (bidding === null || bidding === undefined)
        return false
    if (bidding.ProposalStatus && bidding.ProposalStatus === ItemProposalStatus.DECLINED)
        return false
    if (bidding.Status === BiddingStatus.FINISHED)
        return false
    if (bidding.Status !== BiddingStatus.FINISHED)
        return true
    return false
}
export const getSupplierByUserLogged = (bidding, userProfile, isExternalUser, supplierExternalCode) => {
    if (bidding === null || bidding === undefined)
        return null
    if (bidding.Status !== BiddingStatus.FINISHED)
        return null

    if (isExternalUser) {
        let supplier = bidding.Suppliers.find(x => x.Code === supplierExternalCode);
        return supplier;
    } else {
        if(bidding.Suppliers.length >0){
            return bidding.Suppliers[0]
        }
        return null

    }
}

export const isInternalUserLogged = (userProfile, isExternalUser) => {
    if (userProfile === null)
        return false
    if(isExternalUser)
        return true
    

}

export function canBeEditBiddingInStatusDraftIndex(bidding, userRolesByCompany, userProfile, status) {
    if (userRolesByCompany === undefined)
        return false
    if (userProfile === undefined)
        return false
    if (userRolesByCompany.length === 0)
        return false
    if (bidding === null || bidding === undefined)
        return false
    if (userProfile === null || userProfile === undefined)
        return false
    if (!bidding.Responsibles.includes(userProfile.username))
        return false
    return ((status === BiddingStatus.DRAFT) && IsUserInRole(ROLE_BIDDING_RESPONSIBLE, userRolesByCompany))
}

export function canBeEditBiddingInStatusResolved(bidding, userRolesByCompany, userProfile) {
    if (userRolesByCompany === undefined)
        return false
    if (userProfile === undefined)
        return false
    if (userRolesByCompany.length === 0)
        return false
    if (bidding === null || bidding === undefined)
        return false
    if (userProfile === null || userProfile === undefined)
        return false
    if (!bidding.Responsibles.includes(userProfile.username))
        return false
    return ((bidding.Status === BiddingStatus.RESOLVED) && IsUserInRole(ROLE_BIDDING_RESPONSIBLE, userRolesByCompany))
}

export function canBeEditBiddingInStatusDraft(bidding, userRolesByCompany, userProfile) {
    if (userRolesByCompany === undefined)
        return false
    if (userProfile === undefined)
        return false
    if (userRolesByCompany.length === 0)
        return false
    if (bidding === null || bidding === undefined)
        return false
    if (userProfile === null || userProfile === undefined)
        return false
    if (!bidding.Responsibles.includes(userProfile.username))
        return false
    return ((bidding.Status === BiddingStatus.DRAFT) && IsUserInRole(ROLE_BIDDING_RESPONSIBLE, userRolesByCompany))
}


export function isVisibleTabQuestionsAndAnswers(bidding) {
    if (bidding === null || bidding === undefined)
        return false
    if (bidding.Status === BiddingStatus.DRAFT)
        return false
    return true
}

export function isVisibleTabResults(bidding) {
    if (bidding === null || bidding === undefined)
        return false
    if (bidding.Status === BiddingStatus.FINISHED)
        return true
    return false
}

export function canRefreshScreenToPublishBidding(bidding) {
    if (bidding === null || bidding === undefined)
        return false
    if (bidding.Status === BiddingStatus.DRAFT) {
        if (bidding.Sections.length <= 1)
            return true
        if (bidding.Suppliers.length <= 1)
            return true
        if (bidding.Items.length <= 1)
            return true
        if (bidding.Criterias.length <= 10)
            return true
    }
    return false
}


export function canBeChangeStatusResponsible(bidding, userRolesByCompany, userProfile, status) {
    if (userRolesByCompany === undefined)
        return false
    if (userRolesByCompany.length === 0)
        return false
    if (bidding === null || bidding === undefined)
        return false
    if (userProfile === null || userProfile === undefined)
        return false
    if (!bidding.Responsibles.includes(userProfile.username))
        return false
    return ((bidding.Status === status) && IsUserInRole(ROLE_BIDDING_RESPONSIBLE, userRolesByCompany))
}

/**
 * Action to determine if the user supplier is administrator on is role supplier bidding 
 * @param {*} oidcUser 
 */
export function supplierCanEditBidding(bidding, userProfile, roles, isExternalUser) {
    if (bidding === null || bidding === undefined)
        return false
    if (isExternalUser) {
        return (bidding.ProposalStatus === ItemProposalStatus.DRAFT)
    } else {
        let supplierAdmin = IsUserSupplierAdminExternal(userProfile);
        return (bidding.ProposalStatus === ItemProposalStatus.DRAFT && (supplierAdmin || canBeModuleBiddingSupplier(roles)))
    }
}

export function canBeResendEmail(bidding, userRolesByCompany) {
    if (bidding === null || bidding === undefined)
        return false

    if (userRolesByCompany.length === 0)
        return false

    // if (bidding.Status === BiddingStatus.RESOLVED || bidding.Status === BiddingStatus.CLOSED)
    //     return false

    return bidding.Status === BiddingStatus.PUBLISHED && IsUserInRole(ROLE_BIDDING_RESPONSIBLE, userRolesByCompany)
}

export function isCategoryService(bidding) {
    if (bidding === null || bidding === undefined)
        return false
    return bidding.CategoryName === CategoryType.SERVICE
}

export function isCategoryRawMaterials(bidding) {
    if (bidding === null || bidding === undefined)
        return false
    return bidding.CategoryName === CategoryType.MATERIAL
}


export const backgroundColorTableStatus = (status) => {
    switch (Number(status)) {
        case BiddingStatus.DRAFT:
            return "secondary"
        case BiddingStatus.PUBLISHED:
            return "warning"
        case BiddingStatus.CLOSED:
            return "primary"
        case BiddingStatus.RESOLVED:
            return "success"
        case BiddingStatus.FINISHED:
            return "info"
        default:
            return ""
    }
};

export const descriptionStatus = (status, translate) => {
    switch (Number(status)) {
        case BiddingStatus.DRAFT:
            return translate("biddingsModule.status.draft")
        case BiddingStatus.PUBLISHED:
            return translate("biddingsModule.status.published")
        case BiddingStatus.CLOSED:
            return translate("biddingsModule.status.closed")
        case BiddingStatus.RESOLVED:
            return translate("biddingsModule.status.resolved")
        case BiddingStatus.FINISHED:
            return translate("biddingsModule.status.finished")
        default:
            return ""
    }
};

export const getStatusIndex = (status, translate) => {
    switch (status) {
        case translate("biddingsModule.status.draft"):
            return 0
        case translate("biddingsModule.status.published"):
            return 1
        case translate("biddingsModule.status.closed"):
            return 3
        case translate("biddingsModule.status.resolved"):
            return 4
        case translate("biddingsModule.status.finished"):
            return 5
        default:
            return ""
    }
};

export const colorStatusBidding = (status) => {
    switch (Number(status)) {
        case BiddingStatus.DRAFT:
            return COLOR_STATUS_GRAY
        case BiddingStatus.PUBLISHED:
            return COLOR_STATUS_WARNING
        case BiddingStatus.CLOSED:
            return COLOR_STATUS_INFO
        case BiddingStatus.RESOLVED:
            return COLOR_STATUS_SUCCESS
        default:
            return COLOR_STATUS_GRAY
    }
};

export const descriptionCriteriaType = (type) => {
    switch (type) {
        case CriteriaType.NUMERIC:
            return <Translate id="biddingsModule.services.criteriaType.numeric" />
        case CriteriaType.TEXT:
            return <Translate id="biddingsModule.services.criteriaType.text" />
        case CriteriaType.LIST:
            return <Translate id="biddingsModule.services.criteriaType.list" />
        case CriteriaType.BOOLEAN:
            return <Translate id="biddingsModule.services.criteriaType.bolean" />
        case CriteriaType.MONEY:
            return <Translate id="biddingsModule.services.criteriaType.currency" />
        default:
            return ""
    }
};

export const descriptionServiceOrMaterialType = (type) => {
    switch (Number(type)) {
        case BiddingMaterialOrServices.MATERIAL:
            return <Translate id="biddingsModule.services.serviceOrMaterial.material" />
        case BiddingMaterialOrServices.SERVICE:
            return <Translate id="biddingsModule.services.serviceOrMaterial.service" />
        default:
            return ""
    }
};


export const backgroundColorTableStatusProposal = (status) => {
    switch (Number(status)) {
        case ItemProposalStatus.DRAFT:
            return "secondary"
        case ItemProposalStatus.COMPLETED:
            return "success"
        case ItemProposalStatus.DECLINED:
            return "danger"
        case ItemProposalStatus.CANCELLED:
            return "danger"
        default:
            return ""
    }
}


export const descriptionProposalItem = (status, translate) => {
    switch (Number(status)) {
        case ItemProposalStatus.DRAFT:
            return translate("biddingsModule.itemStatus.draft")
        case ItemProposalStatus.COMPLETED:
            return translate("biddingsModule.itemStatus.completed")
        case ItemProposalStatus.DECLINED:
            return translate("biddingsModule.itemStatus.declined")
        case ItemProposalStatus.CANCELLED:
            return translate("biddingsModule.itemStatus.cancelled")
        default:
            return "-"
    }
};

export const colorStatusProposal = (status) => {
    switch (Number(status)) {
        case ItemProposalStatus.DRAFT:
            return COLOR_STATUS_GRAY
        case ItemProposalStatus.COMPLETED:
            return COLOR_STATUS_SUCCESS
        case ItemProposalStatus.DECLINED:
            return COLOR_STATUS_DANGER
        case ItemProposalStatus.CANCELLED:
            return COLOR_STATUS_DANGER
        default:
            return COLOR_STATUS_GRAY
    }
};


export const getResultInform = (item) => {
    if (item) {
        if (item.criteriaType === CriteriaType.BOOLEAN) {
            if (item.result === true) return "Si";
            if (item.result === false) return "No";
            else return "-";
        }
        else if (item.criteriaType === CriteriaType.TEXT) {
            let data = item.result;
            if (item.result !== null) {
                data = stripHtml(data)
            }
            return data;
        }
        else if (item.criteriaType === CriteriaType.NUMERIC) {
            if (item.format === NumberFormatType.CURRENCY || item.format === "$0.0") {
                return `${currencyFormat(item.result)}`
            }
            if (item.format === NumberFormatType.PERCENT) {
                return `${percengeFormat(item.result)}`
            }
            else {
                return `${item.result}`
            }
        }
        else if (item.criteriaType === CriteriaType.MONEY) {
            return `${item.result}`
        }
        else
            return "-";
    }
    else {
        return "-"
    }
}

export const getSupplierResponseWithFormat = (item) => {
    if (item) {
        if (item.CriteriaType === CriteriaType.BOOLEAN) {
            if (item.Response === true) return "Si";
            if (item.Response === false) return "No";
            else return "-";
        }
        else if (item.CriteriaType === CriteriaType.TEXT) {
            return <div style={{
                maxWidth: "200px",
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }}>{stripHtml(item.Response)}</div>
        }
        else if (item.CriteriaType === CriteriaType.NUMERIC) {
            if (item.format === NumberFormatType.CURRENCY || item.format === "$0.0") {
                return `${currencyFormat(item.Response)}`
            }
            if (item.format === NumberFormatType.PERCENT) {
                return `${percengeFormat(item.Response)}`
            }
            else {
                return `${item.Response ? item.Response : "-"}`
            }
        }
        else if (item.CriteriaType === CriteriaType.MONEY) {
            return `${item.Response}`
        }
        else
            return "-";
    }
    else {
        return "-"
    }
}

export function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

/**
 * Function get total Percentage
 */
export const getBiddingResultTotalPercentage = (items, groupName) => {
    let total = 0;
    let filteredItems = items.filter(x => x.group === groupName)
    if (filteredItems.length !== 0) {
        let sumPercetage = filteredItems.reduce((sum, grup) => sum + grup.weighing, 0);
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        })
        total = formatter.format(sumPercetage);
    }
    return total;
}

export function orderGroups(result) {
    result.sort(function (a, b) {
        var x = a.group.toLowerCase();
        var y = b.group.toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
    });
    return result
}

export const onGetHeaderResultTable = (item) => {
    let TableConfiguration = [
        {
            title: "Criterio ",
            dataSource: 'Criteria'
        },
        {
            title: "Nombre",
            dataSource: 'Name'
        },
    ]
    item.SuppliersProposal.forEach(supplier => {
        TableConfiguration.push({
            title: supplier.supplierCode,
            dataSource: supplier.supplierCode,
        })
    })

    return TableConfiguration
}

export const onAddedResultsButtonsWithWinnerTable = (result, status) => {
    let allResultWithWiner = result
    let ganador = {
        group: WINNER_RESULT_BUTTONS,
        nombreCriteria: "",
        totalItems: 1,
        isGroup: true,
        weighing: 0,
        suppliers: []
    }
    if (result.length !== 0 && (status === BiddingStatus.RESOLVED)) {
        let itemResult = result[0]
        itemResult.suppliers.forEach((supplier) => {
            ganador.suppliers.push(supplier)
        })
        allResultWithWiner.push(ganador)
    }
    return allResultWithWiner
}

export const onAddedResultsTotalTable = (result, status) => {
    let allResultWithTotal = result
    let totalResult = {
        group: RESULT_TOTAL,
        nombreCriteria: "",
        totalItems: 1,
        isGroup: true,
        weighing: 0,
        suppliers: []
    }
    if (result.length !== 0 && status === BiddingStatus.RESOLVED) {
        let itemResult = result[0]
        itemResult.suppliers.forEach((supplier) => {
            totalResult.suppliers.push(supplier)
        })
        allResultWithTotal.push(totalResult)
    }
    return allResultWithTotal
}

export const onMappedDataResultsTable = (itemPoposal, status) => {
    let result = []
    itemPoposal.SuppliersProposal.forEach(supplier => {
        supplier.criterias.forEach(evaluation => {
            let existGroup = result.find(x => x.group === evaluation.Group && x.nombreCriteria === evaluation.Name)
            if (existGroup) {
                let supplierNew = {
                    supplierCode: supplier.supplierCode,
                    itemPoposalId: itemPoposal.Id,
                    evaluationId: evaluation.Id,
                    result: evaluation.Response,
                    evaluated: evaluation.EvaluatedWeighing,
                    criteriaType: evaluation.CriteriaType,
                    format: evaluation.Format,
                    unit: evaluation.MeasurementUnit,
                    isDefaultWinner: supplier.isDefaultWinner,
                    evaluatedWeighing: supplier.evaluatedWeighing
                }
                existGroup.suppliers.push(supplierNew)

            }
            else {
                let existGroup = result.find(x => x.group === evaluation.Group)
                let totalItems = 1;
                totalItems = existGroup ? existGroup.totalItems++ : 1
                result.push({
                    group: evaluation.Group,
                    nombreCriteria: evaluation.Name,
                    totalItems: totalItems,
                    isGroup: existGroup ? false : true,
                    weighing: evaluation.Weighing,
                    suppliers: [{
                        supplierCode: supplier.supplierCode,
                        itemPoposalId: itemPoposal.Id,
                        evaluationId: evaluation.Id,
                        result: evaluation.Response,
                        evaluated: evaluation.EvaluatedWeighing,
                        criteriaType: evaluation.CriteriaType,
                        format: evaluation.Format,
                        unit: evaluation.MeasurementUnit,
                        isDefaultWinner: supplier.isDefaultWinner,
                        evaluatedWeighing: supplier.evaluatedWeighing
                    }]
                })
            }
            return existGroup
        })
    })

    let ordersResult = orderGroups(result)
    let resultWithTotalesult = onAddedResultsTotalTable(ordersResult, status)
    return onAddedResultsButtonsWithWinnerTable(resultWithTotalesult, status)
}


export const onGetWinnerSupplier = (results) => {
    let winnerItem = results ? results.find(x => x.group === WINNER_RESULT_BUTTONS) : null
    if (winnerItem) {
        let winnerSupplier = winnerItem.suppliers.find(x => x.isDefaultWinner === true)
        return winnerSupplier
    }
    return null
}

export const filterCriteriasResponses = (bidding, scopeFilter) => {
    return bidding.CriteriaResponses.filter(x => x.Scope === scopeFilter)
}

export const onAddAdditionalCostsTable = (result, bidding) => {
    let allResultWithOtherExpenses = result
    let totalResult = {
        group: ADDITIONAL_COSTS,
        nombreCriteria: "",
        totalItems: 1,
        isGroup: true,
        weighing: 0,
        suppliers: []
    }
    if (result.length !== 0 && bidding.Status === BiddingStatus.CLOSED) {
        let itemResult = result[0]
        itemResult.suppliers.forEach((supplier) => {
            totalResult.suppliers.push(supplier)
        })
        allResultWithOtherExpenses.push(totalResult)
    }
    return allResultWithOtherExpenses
}

export const onMappedDataResultsGeneralsTable = (bidding, scopeFilter) => {
    let criteriaResponse = filterCriteriasResponses(bidding, scopeFilter)
    let result = []
    criteriaResponse.forEach(criteria => {
        criteria.Responses.forEach(response => {
            let existGroup = result.find(x => x.group === criteria.Group && x.nombreCriteria === criteria.Name)
            let existSupplier = bidding.Suppliers.find(x => x.Code === response.supplierCode)
            if (existGroup) {
                let supplierNew = {
                    supplierCode: response.supplierCode,
                    supplierName: existSupplier ? existSupplier.Name : "",
                    evaluationId: criteria.Id,
                    responseId: response.Id,
                    result: response.response,
                    criteriaType: criteria.CriteriaType,
                    isAutomaticallyEvaluated: criteria.IsAutomaticallyEvaluated,
                    nombreCriteria: criteria.Name,
                    weighing: criteria.Weighing,
                    evaluatedWeighing: response.evaluatedWeighing,
                    hasAdditionalCosts: existSupplier ? existSupplier.HasAdditionalCosts:false,
                    comments: response ? response.comments:"",
                }
                existGroup.suppliers.push(supplierNew)
            }
            else {
                let existGroup = result.find(x => x.group === criteria.Group)
                let totalItems = 1;
                totalItems = existGroup ? existGroup.totalItems++ : 1
                result.push({
                    group: criteria.Group,
                    nombreCriteria: criteria.Name,
                    totalItems: totalItems,
                    isGroup: existGroup ? false : true,
                    weighing: criteria.Weighing,
                    suppliers: [{
                        supplierCode: response.supplierCode,
                        supplierName: existSupplier ? existSupplier.Name : "",
                        hasAdditionalCosts: existSupplier ? existSupplier.HasAdditionalCosts:false,
                        evaluationId: criteria.Id,
                        responseId: response.Id,
                        result: response.response,
                        criteriaType: criteria.CriteriaType,
                        isAutomaticallyEvaluated: criteria.IsAutomaticallyEvaluated,
                        nombreCriteria: criteria.Name,
                        weighing: criteria.Weighing,
                        evaluatedWeighing: response.evaluatedWeighing,
                        comments: response ? response.comments:"",
                    }]
                })
            }
            return existGroup
        })
    })
    let ordersResult = orderGroups(result)
    if (isCategoryRawMaterials(bidding)) {
        ordersResult = onAddAdditionalCostsTable(result, bidding)
    }
    return ordersResult
}

export const onGetHeaderResultGeneralTable = (item) => {
    if (item.length === 0) {
        return []
    }
    else {
        let TableConfiguration = [
            {
                title: "Criterio ",
                dataSource: 'Criteria'
            },
            {
                title: "Nombre",
                dataSource: 'Name'
            },
        ]
        item.suppliers.forEach(supplier => {
            TableConfiguration.push({
                title: `${supplier.supplierName}`,
                dataSource: supplier.supplierCode,
            })
        })

        return TableConfiguration

    }
}

export const onGetHeaderResultAdicionalCostTable = (bidding) => {
    if(bidding ===null)
        return []
    if (bidding.Suppliers.length === 0) {
        return []
    }
    let supplierNotDeclined = bidding.Suppliers.filter(x=>x.Status !==ItemProposalStatus.DECLINED)
    let TableConfiguration = [
        {
            title: "Nombre",
            dataSource: 'Name'
        },
    ]
    supplierNotDeclined.forEach(supplier => {
        TableConfiguration.push({
            title: supplier.Name,
            dataSource: supplier.Code,
        })
    })

    return TableConfiguration

    
}

/**
 * Get user mapped
 * @param {*} roles 
 */
export function getInternalUserName(internalUsers, userName) {
    if (internalUsers === undefined)
        return []
    let users = internalUsers.find(role => {
        return Object.keys(role)[0] === ROLE_BIDDING_RESPONSIBLE
    })
    let allUsersInRoleAdministrator = users ? users[ROLE_BIDDING_RESPONSIBLE] : []
    let existUser = allUsersInRoleAdministrator.find(x => x.user_name === userName)
    if (existUser)
        return existUser.name
    return ""
}

export const getFiltersBidding = (query) => {
    if (IsNullOrEmpty(query))
        return []

    let filters = [
        {
            Property: "Name",
            Values: [query],
            CriteriaType: 5
        },
    ]

    return filters
}

/**
 * Determinate if supplier accept terms and conditions external
 */
export const supplierAccepTermsAndConditions = (bidding, supplierCode) => {
    if (bidding === undefined || bidding === null)
        return false
    if (bidding.Suppliers && bidding.Suppliers.length === 0)
        return false
    if (bidding.Suppliers && bidding.Suppliers.length !== 0) {
        let existSupplier = bidding.Suppliers.find(x => x.Code === supplierCode)
        if (existSupplier) {
            return existSupplier.AcceptTermsAndConditions
        }
    }
    return false
}


/**BIDDING TABLE VERSION 2 METHODS SECTION */

export const getSupplierWithTotal = (criteriaResponse) => {
    let suppliersTotal = []
    criteriaResponse.forEach(criteriaResponseItem => {
        criteriaResponseItem.suppliers.forEach(supplier => {
            let existSupplier = suppliersTotal.find(x => x.supplierCode === supplier.supplierCode)
            if (existSupplier)
                existSupplier.evaluatedWeighingTotal = existSupplier.evaluatedWeighingTotal + supplier.evaluatedWeighingTotal
            else
                suppliersTotal.push(supplier)
        })
    })
    return suppliersTotal
}

export const onAddedResultsTotalTableV2 = (bidding, criteriaResponseItemsOriginal, status) => {
    let criteriaResponseGeneral = onMappedDataResultsGeneralsTableV2(bidding, ScopeType.GENERAL)
    let criteriaResponseItems = onMappedDataResultsItemTableV2(bidding, ScopeType.ITEM, BiddingStatus.DRAFT)
    let suppliersGeneral = getSupplierWithTotal(criteriaResponseGeneral)
    let suppliersItem = getSupplierWithTotal(criteriaResponseItems)
    suppliersGeneral.forEach(supplierGral => {
        let existSupplier = suppliersItem.find(x => x.supplierCode === supplierGral.supplierCode)
        if (existSupplier)
            supplierGral.evaluatedWeighingTotal = supplierGral.evaluatedWeighingTotal + existSupplier.evaluatedWeighingTotal
    })
    let totalResult = {
        group: RESULT_TOTAL,
        nombreCriteria: "",
        totalItems: 1,
        isGroup: true,
        weighing: 0,
        suppliers: suppliersGeneral,
        isCatergoryMaterial: isCategoryRawMaterials(bidding)
    }
    if (criteriaResponseItemsOriginal.length !== 0 && (status === BiddingStatus.RESOLVED || status === BiddingStatus.FINISHED)) {
        criteriaResponseItemsOriginal.push(totalResult)
    }
    return criteriaResponseItemsOriginal
}

export const onAddedResultsTotalWinnerInItems = (bidding, criteriaResponseItemsOriginal, supplierCode) => {
    let suppliers = []
    if(IsNullOrEmpty(supplierCode)){
        bidding.CriteriasResponses[1].supplierResults.forEach(supplierResult => {
            supplierResult.CriteriaResults.forEach(criterialResult => {
                let supplier = bidding.Suppliers.find(x=> x.Code===supplierResult.Code && x.Status ===ItemProposalStatus.COMPLETED)
        if(supplier){
            let newSupplier = {
                supplierName: supplierResult ? supplierResult.Name : "",
                supplierCode: supplierResult.Code,
                evaluatedWeighingTotal: criterialResult.GroupWeighing
            }
            suppliers.push(newSupplier)
            }
            })
        })

    }
    else{
        let supplier = bidding.CriteriasResponses[1].supplierResults.find(x=> supplierCode.includes(x.Code))
        supplier.CriteriaResults.forEach(criterialResult => {
            let newSupplier = {
                supplierName: supplier ? supplier.Name : "",
                supplierCode: supplier.Code,
                evaluatedWeighingTotal: criterialResult.GroupWeighing
            }
            suppliers.push(newSupplier)
        })
        
    }
    let totalResult = {
        group: RESULT_TOTAL_WINNER_IN_ITEMS,
        nombreCriteria: "",
        totalItems: 1,
        isGroup: true,
        weighing: 0,
        suppliers: suppliers,
        isCatergoryMaterial: isCategoryRawMaterials(bidding)
    }
    if ((bidding.Status === BiddingStatus.FINISHED) && isCategoryRawMaterials(bidding)) {
        criteriaResponseItemsOriginal.unshift(totalResult)
        // criteriaResponseItemsOriginal.push(totalResult)
    }
    return criteriaResponseItemsOriginal
}

export const filterCriteriasResponsesV2 = (bidding, scopeFilter) => {
    let filterCriteria = []
    bidding.CriteriasResponses.forEach(criteriaResponse => {
        criteriaResponse.criterias.forEach(criteria => {
            if (criteria.Scope === scopeFilter) {
                filterCriteria.push(criteria);
            }
        })
    })
    return filterCriteria
}
export const onMappedDataResultsGeneralsTableV2 = (bidding, scopeFilter) => {
    let criteriaResponse = filterCriteriasResponsesV2(bidding, scopeFilter)
    let result = []
    criteriaResponse.forEach(criteria => {
        criteria.SuppliersProposal.forEach(supplierProposal => {
            let existGroup = result.find(x => x.group === criteria.Group && x.nombreCriteria === criteria.Name)
            let existSupplier = bidding.Suppliers.find(x => x.Code === supplierProposal.supplierCode)
            if (existGroup) {
                let supplierNew = {
                    supplierName: existSupplier ? existSupplier.Name : "",
                    supplierCode: supplierProposal.supplierCode,
                    evaluationId: criteria.Id,
                    responseId: supplierProposal.Id,
                    result: criteria.CriteriaType === CriteriaType.TEXT ? stripHtml(supplierProposal.response): supplierProposal.response,
                    criteriaType: criteria.CriteriaType,
                    nombreCriteria: criteria.Name,
                    weighing: criteria.Weighing,
                    criteriaId: criteria.Id,/**THIS */
                    evaluatedWeighingTotal: supplierProposal.evaluatedWeighing ? supplierProposal.evaluatedWeighing : 0,
                    unit: supplierProposal.MeasurementUnit ? supplierProposal.MeasurementUnit : "",
                    format: criteria.Format,
                    isDefaultWinner: supplierProposal.isDefaultWinner === true ? true : false,
                    finalComment: supplierProposal ? supplierProposal.finalComment : "",
                }
                existGroup.suppliers.push(supplierNew)
            }
            else {
                let existGroup = result.find(x => x.group === criteria.Group)
                let totalItems = 1;
                totalItems = existGroup ? existGroup.totalItems++ : 1
                result.push({
                    group: criteria.Group,
                    nombreCriteria: criteria.Name,
                    totalItems: totalItems,
                    isGroup: existGroup ? false : true,
                    weighing: criteria.Weighing,
                    quantity: null,
                    measurementUnit: criteria.MeasurementUnit,
                    isCatergoryMaterial: isCategoryRawMaterials(bidding),
                    suppliers: [{
                        supplierName: existSupplier ? existSupplier.Name : "",
                        supplierCode: supplierProposal.supplierCode,
                        evaluationId: criteria.Id,
                        responseId: supplierProposal.Id,
                        result: criteria.CriteriaType === CriteriaType.TEXT ? stripHtml(supplierProposal.response) : supplierProposal.response,
                        criteriaType: criteria.CriteriaType,
                        nombreCriteria: criteria.Name,
                        weighing: criteria.Weighing,
                        criteriaId: criteria.Id,
                        evaluatedWeighingTotal: supplierProposal.evaluatedWeighing ? supplierProposal.evaluatedWeighing : 0,
                        unit: supplierProposal.MeasurementUnit ? supplierProposal.MeasurementUnit : "",
                        format: criteria.Format,
                        isDefaultWinner: supplierProposal.isDefaultWinner === true ? true : false,
                        finalComment: supplierProposal ? supplierProposal.finalComment : "",
                    }]
                })
            }
            return existGroup
        })
    })

    let ordersResult = orderGroups(result)
    return ordersResult
}


export const onMappedResulItemstTable = (resultsItem) => {
    let items = resultsItem.map(result => {
        let suppliers = result.suppliers.map(supplier => {
            return {
                SupplierCode: supplier.supplierCode,
                EvaluatedWeighingTotal: supplier.evaluatedWeighingTotal,/**THIS */
                Format: supplier.format ? supplier.format : "",/**THIS */
                MeasurementUnit: supplier.unit ? supplier.unit : "",/**THIS */
                Response: supplier.result,
                CriteriaType: supplier.criteriaType,
                NombreCriteria: supplier.nombreCriteria,
                Weighing: supplier.weighing,
            }
        })
        return (
            {
                Group: result.group,
                NombreCriteria: result.nombreCriteria,
                TotalItems: result.totalItems,
                IsGroup: result.isGroup,
                Quantity: result.quantity ? result.quantity : 0,
                MeasurementUnit: result.measurementUnit ? result.measurementUnit : "",
                Weighing: result.weighing,
                Suppliers: suppliers
            })
    })
    return items
}
export const onGetHeaderReportTableV2 = (item, suppliers, supplierCode, isVisibleColumnTotal) => {
    let TableConfiguration = [
        {
            title: "Grupo ",
        },
        {
            title: "Porcentaje ",
        },
        {
            title: "Cantidad",
        },
        {
            title: "UM",
        },
        {
            title: "Criterios globales ",
        },
        {
            title: "Ponderación ",
        }
    ]
    if(suppliers.length ===0)
        return TableConfiguration
    if(IsNullOrEmpty(supplierCode))
        suppliers = suppliers.filter(x=>x.Status ===ItemProposalStatus.COMPLETED )
    if(!IsNullOrEmpty(supplierCode))
        suppliers = suppliers.filter(x=>supplierCode.includes(x.Code))

    if (item && item.SuppliersProposal) {
        let index = 1;
        suppliers.forEach(supplier=>{
            let existSupplier = item.SuppliersProposal.find(x => x.supplierCode === supplier.Code)
            if(existSupplier ===undefined){
                TableConfiguration.push({
                    title: supplier ? (supplier.Type ===BiddingSuppliers.EXTERNAL ? supplier.Name: `${supplier.Code} | ${supplier.Name}`) : supplier.Code,
                    dataSource: supplier.supplierCode,
                    isVisibleComments: true,
                    finalComment: supplier ? (supplier.FinalComment ? supplier.FinalComment : "") : "",
                    supplierCode: supplier ? (supplier.Code ? supplier.Code : "") : "",
                    supplierName: supplier ? (supplier.Name ? supplier.Name : "") : "",
    
                })
                if(isVisibleColumnTotal){
                    TableConfiguration.push({
                        title: "Total",
                        isColumnTotal:true,
                        dataSource: `${supplier.Code}-percentage`,
                    })
                }
                index++
            }
            else{
                TableConfiguration.push({
                    title: supplier ? (supplier.Type ===BiddingSuppliers.EXTERNAL ? supplier.Name: `${supplier.Code} | ${supplier.Name}`) : existSupplier.supplierCode,
                    dataSource: existSupplier.supplierCode,
                    isVisibleComments: true,
                    finalComment: supplier ? (supplier.FinalComment ? supplier.FinalComment : "") : "",
                    supplierCode: supplier ? (supplier.Code ? supplier.Code : "") : "",
                    supplierName: supplier ? (supplier.Name ? supplier.Name : "") : "",
    
                })
                if(isVisibleColumnTotal){
                    TableConfiguration.push({
                        title: `Total-${index}`,
                    })
                }
                index++
            }
        })

    }
    else if (item && item.SupplierProposal) {
        let index = 1;
        suppliers.forEach((supplier)=>{
            let existSupplier = item.SupplierProposal.find(x=>x.SupplierCode ===supplier.Code)
            if(existSupplier===undefined){
                TableConfiguration.push({
                    title: supplier ? (supplier.Type ===BiddingSuppliers.EXTERNAL ? supplier.Name: `${supplier.Code} | ${supplier.Name}`) : supplier.Code,
                })
                TableConfiguration.push({
                    title: `Total-${index}`,
                })
                index++
            }
            else{
                TableConfiguration.push({
                    title: supplier ? (supplier.Type ===BiddingSuppliers.EXTERNAL ? supplier.Name: `${supplier.Code} | ${supplier.Name}`) : supplier.supplierCode,
                })
                TableConfiguration.push({
                    title: `Total-${index}`
                })
                index++
            }
        })
    }
    return TableConfiguration
}

export const onGetHeaderResultGeneralTableV2 = (item, suppliers, bidding, supplierCode, isVisibleColumnTotal) => {
    let TableConfiguration = [
        {
            title: "Grupo ",
            dataSource: 'group'
        },
    ]
    if (isCategoryRawMaterials(bidding)) {
        TableConfiguration.push(
            {
                title: "Ganadores",
                dataSource: 'winners'
            },
        )
    }
    TableConfiguration.push(
        {
            title: "Criterios globales ",
            dataSource: 'scope'
        },
    )
    TableConfiguration.push(
        {
            title: "Ponderación ",
            dataSource: 'weighing'
        }
    )
    TableConfiguration.push(
        {
            title: "Cantidad",
            dataSource: 'quantity'
        },
    )
    TableConfiguration.push(
        {
            title: "UM",
            dataSource: 'measurementUnit'
        },
    )
    if(suppliers.length ===0)
        return TableConfiguration
    if(IsNullOrEmpty(supplierCode))
        suppliers = suppliers.filter(x=>x.Status ===ItemProposalStatus.COMPLETED )
    if(!IsNullOrEmpty(supplierCode))
        suppliers = suppliers.filter(x=>supplierCode.includes(x.Code))
        
    if (item && item.SuppliersProposal) {
        suppliers.forEach(supplier=>{
            let existSupplier = item.SuppliersProposal.find(x => x.supplierCode === supplier.Code)
            if(existSupplier ===undefined){
                TableConfiguration.push({
                    title: supplier ? (supplier.Type ===BiddingSuppliers.EXTERNAL ? supplier.Name: `${supplier.Code} | ${supplier.Name}`) : supplier.Code,
                    dataSource: supplier.supplierCode,
                    isVisibleComments: true,
                    finalComment: supplier ? (supplier.FinalComment ? supplier.FinalComment : "") : "",
                    supplierCode: supplier ? (supplier.Code ? supplier.Code : "") : "",
                    supplierName: supplier ? (supplier.Name ? supplier.Name : "") : "",
    
                })
                if(isVisibleColumnTotal){
                    TableConfiguration.push({
                        title: "Total",
                        isColumnTotal:true,
                        dataSource: `${supplier.Code}-percentage`,
                    })
                }
            }
            else{
                TableConfiguration.push({
                    title: supplier ? (supplier.Type ===BiddingSuppliers.EXTERNAL ? supplier.Name: `${supplier.Code} | ${supplier.Name}`) : existSupplier.supplierCode,
                    dataSource: existSupplier.supplierCode,
                    isVisibleComments: true,
                    finalComment: supplier ? (supplier.FinalComment ? supplier.FinalComment : "") : "",
                    supplierCode: supplier ? (supplier.Code ? supplier.Code : "") : "",
                    supplierName: supplier ? (supplier.Name ? supplier.Name : "") : "",
    
                })
                if(isVisibleColumnTotal){
                    TableConfiguration.push({
                        title: "Total",
                        isColumnTotal:true,
                        dataSource: `${existSupplier.supplierCode}-percentage`,
                    })
                }
            }
        })

    }
    else if (item && item.SupplierProposal) {
        suppliers.forEach(supplier=>{
            let existSupplier = item.SupplierProposal.find(x=>x.SupplierCode ===supplier.Code)
            if(existSupplier===undefined){
                TableConfiguration.push({
                    title: supplier ? (supplier.Type ===BiddingSuppliers.EXTERNAL ? supplier.Name: `${supplier.Code} | ${supplier.Name}`) : supplier.Code,
                    dataSource: supplier.Code,
                    isVisibleComments: true,
                    finalComment: supplier ? (supplier.FinalComment ? supplier.FinalComment : "") : "",
                    supplierCode: supplier ? (supplier.Code ? supplier.Code : "") : "",
                    supplierName: supplier ? (supplier.Name ? supplier.Name : "") : "",
                    isSelectedWinner: false,
                    isCategoryService: isCategoryService(bidding)
                })
                if(isVisibleColumnTotal){
                    TableConfiguration.push({
                        title: "Total",
                        isColumnTotal:true,
                        dataSource: `${supplier.Code}-percentage`,
                    })
                }
            }
            else{
                TableConfiguration.push({
                    title: supplier ? (supplier.Type ===BiddingSuppliers.EXTERNAL ? supplier.Name: `${supplier.Code} | ${supplier.Name}`) : supplier.supplierCode,
                    dataSource: supplier.supplierCode,
                    isVisibleComments: true,
                    finalComment: supplier ? (supplier.FinalComment ? supplier.FinalComment : "") : "",
                    supplierCode: supplier ? (supplier.Code ? supplier.Code : "") : "",
                    supplierName: supplier ? (supplier.Name ? supplier.Name : "") : "",
                    isSelectedWinner: existSupplier.IsSelectedWinner,
                    isCategoryService: isCategoryService(bidding)
                })
                if(isVisibleColumnTotal){
                    TableConfiguration.push({
                        title: "Total",
                        isColumnTotal:true,
                        dataSource: `${supplier.Code}-percentage`,
                    })
                }
            }
        })
    }
    return TableConfiguration
}

export const filterCriteriasResponsesItemsV2 = (bidding, scopeFilter) => {
    let filterCriteria = []
    bidding.CriteriasResponses.forEach(criteriaResponse => {
        criteriaResponse.criterias.forEach(criteria => {
            if (criteria.ItemResponses) {
                criteria.ItemResponses.forEach(item => {
                    item.SupplierProposal.forEach(supplierProposal => {
                        if (supplierProposal.Scope === scopeFilter) {
                            let existGroup = filterCriteria.find(x => x.group === criteriaResponse.group)
                            if (existGroup === undefined || existGroup === null)
                                filterCriteria.push(criteriaResponse);
                        }

                    })
                })
            }
        })
    })
    return filterCriteria
}
export const onGetSuppliersNotDeclined = (bidding, supplierCode) => {
    let supplierHeaders = []
    let suppliers = []
    if(bidding===undefined)
        return supplierHeaders
    if(bidding.Suppliers.length ===0)
        return supplierHeaders
    if(IsNullOrEmpty(supplierCode))
        suppliers = bidding.Suppliers.filter(x=>x.Status ===ItemProposalStatus.COMPLETED )
    if(!IsNullOrEmpty(supplierCode))
        suppliers = bidding.Suppliers.filter(x=>supplierCode.includes(x.Code))
    suppliers.forEach(supplier=>{
        supplierHeaders.push(supplier)
    })
    return supplierHeaders
}

export const onAddSuppliersDeclinedMaterial = (suppliersNotDeclined, resultWithTotalResult) => {
    suppliersNotDeclined.forEach((supplierNotDeclined, index)=>{
        resultWithTotalResult.forEach(itemGroup=>{
            let suppliersInItems = [...itemGroup.suppliers]
            let existSupplier =itemGroup.suppliers.find(x=>x.supplierCode ===supplierNotDeclined.Code)
            if(existSupplier ===undefined){
                let newSupplier ={
                    supplierName: supplierNotDeclined.Name,
                    supplierCode: supplierNotDeclined.Code,
                    evaluationId: "",
                    responseId: "",
                    result:  "",
                    criteriaType: IS_DECLINED_MATERIAL_BY_SUPPLIER,
                    nombreCriteria: "" ,
                    weighing: 0,
                    itemId: "",
                    criteriaId: "" ,
                    evaluatedWeighingTotal: 0 ,
                    format: "",
                    unit: "",
                    isCatergoryMaterial: "",
                    isDefaultWinner: "",
                    isSelectedWinner: false,
                    group: IS_DECLINED_MATERIAL_BY_SUPPLIER,
                    supplierId: "",
                    finalComment: "",
            }
            suppliersInItems.splice(index, 0, newSupplier);
            }
            itemGroup.suppliers = suppliersInItems
           
        })
    })

    return resultWithTotalResult
}

export const onMappedDataResultsItemTableV2 = (bidding, scopeFilter, status, supplierCode) => {
    let result = []
    let criteriaResponse = filterCriteriasResponsesItemsV2(bidding, scopeFilter)
    criteriaResponse.forEach(criteriaResponse => {
        criteriaResponse.criterias.forEach(criteria => {
            if (criteria.ItemResponses) {
                criteria.ItemResponses.forEach(item => {
                    item.SupplierProposal.forEach(supplierProposal => {
                        let existSupplierInResult = bidding.Suppliers.find(x => x.Code === supplierProposal.SupplierCode)
                        let existGroup = result.find(x => x.group === criteria.Group && x.nombreCriteria === (isCategoryRawMaterials(bidding) ? `${item.Code} - ${item.Name}` : item.Name))
                        if (existGroup) {
                            let existSupplier = existGroup.suppliers.find(x => x.supplierCode === supplierProposal.SupplierCode)
                            if (existSupplier === undefined) {
                                let supplierNew = {
                                    supplierName: existSupplierInResult ? existSupplierInResult.Name : "",
                                    supplierCode: supplierProposal.SupplierCode,
                                    evaluationId: criteria.Id,
                                    responseId: supplierProposal.Id,
                                    result:  criteria.CriteriaType === CriteriaType.TEXT ? stripHtml(supplierProposal.Response) : supplierProposal.Response,
                                    criteriaType: criteria.CriteriaType,
                                    nombreCriteria: isCategoryRawMaterials(bidding) ? `${item.Code} - ${item.Name}` : item.Name,
                                    weighing: criteria.Weighing,
                                    itemId: item.Id, /**THIS */
                                    criteriaId: criteria.Id,/**THIS */
                                    evaluatedWeighingTotal: supplierProposal.EvaluatedWeighing,/**THIS */
                                    format: criteria.Format,/**THIS */
                                    unit: criteria.MeasurementUnit,/**THIS */
                                    isCatergoryMaterial: isCategoryRawMaterials(bidding),
                                    isDefaultWinner: supplierProposal ? supplierProposal.IsDefaultWinner : false,
                                    isSelectedWinner: supplierProposal ? supplierProposal.IsSelectedWinner : false,
                                    group: criteria.Group,
                                    supplierId: supplierProposal.Id,
                                    finalComment: supplierProposal ? supplierProposal.FinalComment : "",
                                    responseMXN: supplierProposal ? supplierProposal.ResponseMXN : "",
                                }
                                existGroup.suppliers.push(supplierNew)
                            }
                        }
                        else {
                            let existGroup = result.find(x => x.group === criteria.Group)
                            let existGroupWithCriteria = result.find(x => x.group === criteria.Group && x.nombreCriteria === (isCategoryRawMaterials(bidding) ? `${item.Code} - ${item.Name}` : item.Name))
                            let totalItems = 1;
                            totalItems = existGroup ? existGroup.totalItems++ : 1
                            if (existGroupWithCriteria === undefined) {
                                result.push({
                                    group: criteria.Group,
                                    nombreCriteria: isCategoryRawMaterials(bidding) ? `${item.Code} - ${item.Name}` : item.Name,
                                    totalItems: totalItems,
                                    isGroup: existGroup ? false : true,
                                    weighing: criteria.Weighing,
                                    quantity: item ? item.Quantity : 0,
                                    measurementUnit: item ? item.MeasurementUnit : "-",
                                    isCatergoryMaterial: isCategoryRawMaterials(bidding),
                                    suppliers: [{
                                        finalComment: supplierProposal ? supplierProposal.FinalComment : "",
                                        group: criteria.Group,
                                        isDefaultWinner: supplierProposal ? supplierProposal.IsDefaultWinner : false,
                                        isSelectedWinner: supplierProposal ? supplierProposal.IsSelectedWinner : false,
                                        supplierName: existSupplierInResult ? existSupplierInResult.Name : "",
                                        responseMXN: supplierProposal ? supplierProposal.ResponseMXN : "",
                                        supplierCode: supplierProposal.SupplierCode,
                                        itemId: item.Id, /**THIS */
                                        criteriaId: criteria.Id,/**THIS */
                                        evaluatedWeighingTotal: supplierProposal.EvaluatedWeighing,/**THIS */
                                        format: criteria.Format,/**THIS */
                                        unit: criteria.MeasurementUnit,/**THIS */
                                        supplierId: supplierProposal.Id,
                                        result: criteria.CriteriaType === CriteriaType.TEXT? stripHtml(supplierProposal.Response):supplierProposal.Response,
                                        criteriaType: criteria.CriteriaType,
                                        nombreCriteria: isCategoryRawMaterials(bidding) ? `${item.Code} - ${item.Name}` : item.Name,
                                        weighing: criteria.Weighing,
                                        isCatergoryMaterial: isCategoryRawMaterials(bidding),
                                    }]
                                })
                            }
                        }
                        return existGroup
                    })
                })
            }
        })

    })
    let resultWithTotalesult = result
    let suppliersNotDeclined = onGetSuppliersNotDeclined(bidding, supplierCode)

    //Agrega los proveedores que declinaron un material
    if(suppliersNotDeclined.length !==0 && isCategoryRawMaterials(bidding)){
        suppliersNotDeclined = onAddSuppliersDeclinedMaterial(suppliersNotDeclined, resultWithTotalesult)
    }
    if ((status === BiddingStatus.RESOLVED || status === BiddingStatus.FINISHED)) {
        resultWithTotalesult = onAddedResultsTotalTableV2(bidding, result, status)
    }
    if ((status === BiddingStatus.FINISHED)) {
        resultWithTotalesult = onAddedResultsTotalWinnerInItems(bidding, resultWithTotalesult, supplierCode)
    }
    return resultWithTotalesult
}

export const getTotalWinners = (item) => {
    let total = 0;
    if (item.suppliers && item.suppliers.length !== 0) {
        total = item.suppliers.reduce((sum, grup) => sum + grup.isSelectedWinner, 0);
    }
    return total;
}



        