/**Import react section */
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";

/** Material UI import section */
import {
    Button,
    Grid,
    TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
/**Import section components */
import StepperQuestion from './StepperQuestion'
import EmptyInformation from '../EmptyInformation'
/**Import resources section */
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';
import { canBeAddQuestionAndAnswerBySupplier } from '../../../../store/helpers/BiddingHelper'
const QuestionContainer = props => {
    const { classes, isSaving, isDisabledAddQuestion, preferred_username } = props
    const { onAddQuestion, onAddAnswer, bidding, usersByRole, userProfile, isExternalUser, userRolesByCompany,isCurrentActiveDirectoryUser } = props

    const [binddingModel, setBinddingModel] = useState({
        question: "",
    });

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setBinddingModel({
            ...binddingModel,
            [name]: value
        });
    }

    const onSave = () => {
        if (props.onAddQuestion) {
            onAddQuestion(binddingModel)
                .then(() => {
                    setBinddingModel({
                        question: ""
                    })
                })
        }
    }


    /**Verify if can be sabed data*/
    const canBeSavedQuestion = () => {
        if (IsNullOrEmpty(binddingModel.question)) return false
        return true;
    }

    return (
        <Grid container>
            {
                (!isDisabledAddQuestion || canBeAddQuestionAndAnswerBySupplier(bidding, userProfile, userRolesByCompany, isExternalUser)) && <>
                    <Grid item xs={11}>
                        <TextField
                            fullWidth
                            label={<Translate id="biddingsModule.questions.title" />}
                            className={classes.textField}
                            name='question'
                            onChange={onPropertyChange}
                            margin="normal"
                            variant="outlined"
                            multiline
                            disabled={isDisabledAddQuestion || !canBeAddQuestionAndAnswerBySupplier(bidding, userProfile, userRolesByCompany, isExternalUser)}
                            value={binddingModel ? binddingModel.question : ""}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            size="small"
                            variant='outlined'
                            onClick={onSave}
                            className={classes.btnSave}
                            disabled={!canBeSavedQuestion() || isSaving || isDisabledAddQuestion || !canBeAddQuestionAndAnswerBySupplier(bidding, userProfile, userRolesByCompany, isExternalUser)}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </>
            }
            <Grid item xs={12} className={classes.answersContainer}>
                {
                    (bidding && bidding.Questions.length !== 0) ?
                        <StepperQuestion
                            questions={bidding.Questions}
                            onAddAnswer={onAddAnswer}
                            isSaving={isSaving}
                            usersByRole={usersByRole}
                            bidding={bidding}
                            isCurrentActiveDirectoryUser={isCurrentActiveDirectoryUser}
                            isExternalUser={isExternalUser}
                            preferred_username={preferred_username}
                        /> : <EmptyInformation
                            title={<Translate id="biddingsModule.questions.notQuestions" />}
                        />
                }
            </Grid>
        </Grid>
    )
}

const useStyle = ((theme) => ({
    containerBtn: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center"
    },
    btnSave: {
        marginLeft: "5px",
        maxHeight: "50px"
    },
    answersContainer: {
        maxHeight: `calc(100vh - ${405}px)`,
        minHeight: `calc(100vh - ${405}px)`,
        overflowY: "auto",
    }
}))

QuestionContainer.propTypes = {
    isSaving: PropTypes.bool.isRequired,
    isDisabledAddQuestion: PropTypes.bool.isRequired
}

QuestionContainer.defaultProps = {
    isSaving: false,
    isDisabledAddQuestion: false

}
export default withRouter(withStyles(useStyle, { withTheme: true })(QuestionContainer))