import propTypes from 'prop-types'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
/**Import MaterialUi section */
import { Button, List, ListItem, ListItemText, Typography, LinearProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Dropzone from '../../common/Dropzone'

/**Import components section */

const ViewComponent = (props) => {
	const { onCloseModal, onUploadInvoiceDocument, documents, deleteInvoiceFiles } = props

	const onUpdateRequestControl = () => {
		onCloseModal()
	}
	const isSaving = useSelector((state) => (state.loadAlert.get('isSaving') ? state.loadAlert.get('isSaving') : false))

	return (
		<div>
			<List dense>
				<ListItem style={{ width: '100%' }}>
					<ListItemText
						primary={
							<div style={{ display: 'flex', gap: '15px' }}>
								<Typography component='span' variant='body2' color='textSecondary'>
									<strong>
										{' '}
										<Translate id='importRequests.addDocument' />
									</strong>
								</Typography>
							</div>
						}
					/>
				</ListItem>
				<ListItem style={{ width: '100%' }}>
					{!isSaving ? (
						<Dropzone
							baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
							onChange={onUploadInvoiceDocument}
							onDeleteFile={deleteInvoiceFiles}
							files={documents}
							acceptedFiles={['xml', 'pdf', 'excel', 'images', 'XLSX']}
						/>
					) : (
						<LinearProgress color='primary' />
					)}
				</ListItem>
				<ListItem>
					<Button
						onClick={() => onUpdateRequestControl()}
						data-cy='btn-save'
						type='button'
						color='primary'
						variant='outlined'
						fullWidth
                        disabled={isSaving}

					>
						Guardar
					</Button>
				</ListItem>
				<br />
			</List>
		</div>
	)
}

const styles = (theme) => ({
	containerInformation: {
		maxHeight: `calc(100vh - ${165}px)`,
		minHeight: `calc(100vh - ${165}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	widthBtnSaved: {
		minWidth: '200px',
	},
	divider: {
		marginRight: '10px',
	},
	bgRed: {
		backgroundColor: 'red',
	},
})

ViewComponent.propTypes = {
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseModal: propTypes.func,
	/**
	 * Selected item data
	 */
	item: propTypes.object,
}

ViewComponent.defaultProps = {
	onCloseModal: () => console.warn('Callback [onCloseModal] no defined'),
	isSaving: false,
	item: {},
}

export default withRouter(withStyles(styles, { withTheme: true })(ViewComponent))
