//Import react section
import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
//Import material ui section
import { withStyles } from '@material-ui/core/styles';
/** Material-UI imports section */
import {
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
//import  components section
import DataTable from '../../common/DataTable';

/** section import components */
import { BACKGROUND_COLOR_GRAY_DARK } from '../../../store/helpers/StatusColorConstants';
// import {HistorialConsultationMaquilaOrderType} from '../../../store/helpers/AppConstants'
/**
 * Component ot render Information partial maquila Order
 */
const HistorialConsultationTable = (props) => {
    const { classes, maquilaOrder } = props;


    	/**
	 * Render material
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderMaterialItem=(dataSource, item)=> {
		if (dataSource === 'actual_operation') {
			return `${item.actual_operation}`
		}
	}
    return (
        <Paper>
            <List>
                <ListItem divider style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}>
                    <ListItemText
                        secondary={
                            <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                <strong> {<Translate id="maquilaOrders.historialConsultation.title" />}</strong>
                            </Typography>
                        }
                    />
                </ListItem>
            </List>
            <DataTable
                configuration={TableConfigurationSend()}
                data={maquilaOrder.history_consultations}
                onRenderCellItem={onRenderMaterialItem}
            />
        </Paper>
    );

}

function TableConfigurationSend(isDisabled) {
    let columns = [
        {
            header: <Translate id="common.creationDate" />,
            dataSource: 'creation_date'
        },
        {
            header: <Translate id="maquilaOrders.historialConsultation.userName" />,
            dataSource: 'user_name'
        },
        {
            header: <Translate id="maquilaOrders.historialConsultation.name" />,
            dataSource: 'name'
        },
        {
            header: <Translate id="maquilaOrders.historialConsultation.status" />,
            dataSource: 'actual_operation'
        },
        {
            header: <Translate id="maquilaOrders.historialConsultation.operationType" />,
            dataSource: 'operation_description'
        }
    ];

    return { columns };
}

/**
 * maquilaOrder
 */
HistorialConsultationTable.propTypes = {
    maquilaOrder: PropTypes.object.isRequired
};


const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    actionsToolbar: {
        display: "flex",
    },
    itemsAction: {
        marginRight: "6px"
    },
    searchInput: {
        marginTop: "3px",
        marginRight: "25px"
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    }
});
export default (withStyles(styles, { withTheme: true })(HistorialConsultationTable))