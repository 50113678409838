
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import autoBind from 'auto-bind'
import Select from 'react-select'

/** Material-UI imports section */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';

/** Import section component */
import Toaster from '../../common/Toaster';
import DialogActions from '@material-ui/core/DialogActions';
import SuppliersSelector from '../../common/SupplierSelector';
import PartNumberSelector from '../../common/PartNumberSelector';
import FailureNonConformitySelector from '../../common/FailureNonConformitySelector';
import PreparedBySelector from '../../common/PreparedBySelector';

import {
    GetContainmentUtilizeType, GetContainmentColorType,
    GetContainmentLocationType, GetContainmentIdentifyByType, getSelectedValue
} from '../../../store/helpers/SelectOptions';
import {canUserValideModuleQuality} from '../../../store/helpers/RolesHelper';
import {COMPANY_PTM} from '../../../store/helpers/ResourcesHelper';

/**
 * Component Form Raw material non conformity
 */
class FormViewEdit extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            preparedBySelected: {
                value: this.props.qualityReport.user_name_report,
                label: this.props.qualityReport.user_name_report,
            },
            supplierSelected: {
                token: this.props.qualityReport.supplier_token,
                name: this.props.qualityReport.supplier_name,
                code: this.props.qualityReport.supplier_code,
            },
            partNumberSelected: {
                code: this.props.qualityReport.part_number,
                description: this.props.qualityReport.part_description,
                measurementUnit: this.props.qualityReport.measurement_unit,
            },
            failureSelected: {
                value: this.props.qualityReport.failure_non_conformity.token,
                label: this.props.qualityReport.failure_non_conformity.name
            },
            reportQuality: {
                tokenFailure: this.props.qualityReport.token,
                qualityInPlant: this.props.qualityReport.quantity_of_material_in_invoice,
                qualityReport: this.props.qualityReport.quantity_of_material_affected,
                functionalityDimension: this.props.qualityReport.functionality_dimension,
                functionalitySpecification: this.props.qualityReport.functionality_specification,
                functionalityPackaging: this.props.qualityReport.functionality_packaging,
                functionalityMisidentified: this.props.qualityReport.functionality_misidentified,
                functionality: this.props.qualityReport.functionality,
                functionalityAssembly: this.props.qualityReport.functionality_assembly,
                problemDescription: this.props.qualityReport.problem_description,
                color: this.props.qualityReport.containment_action ?  this.props.qualityReport.containment_action.color: '',
                utilize:  this.props.qualityReport.containment_action ? this.props.qualityReport.containment_action.utilize: '',
                identifyBy:  this.props.qualityReport.containment_action ? this.props.qualityReport.containment_action.identify_by: '',
                location:  this.props.qualityReport.containment_action ? this.props.qualityReport.containment_action.location: '',
                legend:  this.props.qualityReport.containment_action ? this.props.qualityReport.containment_action.legend: '',
                lotMaterial: this.props.qualityReport.lot_material,
                customerPartNumber: this.props.qualityReport.customer_part_number,
            }
        }
    }

    /**
    * On seleted prepare by
    */
   onPrepareBySelected(prepareBy) {
        this.setState({ preparedBySelected: prepareBy });
    }

    /**
     * On seletecd supplier
     */
    onSupplierSelected(supplier) {
        this.setState({ supplierSelected: supplier });
    }

    /**
     * On seleted part number
     */
    onPartNumberSelected(partNumber) {
        this.setState({ partNumberSelected: partNumber });
    }

    /**
     * On seleted failure
     */
    onFailureSelected(failure) {
        this.setState({ failureSelected: failure });
    }

    /**
    * Update the property of the report Quality failure
    *
    * @param {*} user
    * @param {*} value
    * @memberof View
    */
    updateProperty(property, value) {
        let creatReportQuality = { ...this.state.reportQuality };
        creatReportQuality[property] = value;
        this.setState({
            reportQuality: creatReportQuality
        })
    }

    /**
     * Update selected options
     * @param {*} option 
     * @param {*} property 
     */
    updatePropertySelected(option, property) {
        let creatRepoQuality = { ...this.state.reportQuality };
        creatRepoQuality[property] = option;
        this.setState({
            reportQuality: creatRepoQuality
        })
    }

    /**
     * Can be add item in quality failure report
     */
    canBeSaved() {
        let reportQuality = this.state.reportQuality;
        if (this.state.preparedBySelected === null)
            return false;
        if (this.state.supplierSelected === null)
            return false;
        if (this.state.partNumberSelected === null)
            return false;
        if (this.state.failureSelected === null)
            return false;
        if (reportQuality.qualityInPlant === "" ||
            reportQuality.qualityReport === "" || reportQuality.problemDescription === "")
            return false;
        if (this.hasInvalidValueQantity())
            return false;
        else {
            return true;
        }
    }

    /**
     * Has valid quantity
     */
    hasInvalidValueQantity() {
        /// Validar que la cantidad inventario no puede ser mayor a la cantiad reportada
        if (this.state.reportQuality.qualityInPlant !== "" && this.state.reportQuality.qualityReport !== "") {
            let qualityInPlant = Number(this.state.reportQuality.qualityInPlant);
            let qualityReport = Number(this.state.reportQuality.qualityReport);
            if (qualityInPlant < qualityReport) {
                return true;
            }
            else
                return false;
        }
        else
            return false;
    }

    /**
     * Metho to can be created quality failure report
     */
    canBeEditQualityFailureReport() {
        if (this.hasInvalidValueQantity()) {
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="qualityfailurereport.messageInventory" />,
                toasterVariant: "error",
            })
        }
        else if (this.props.canBeSaveToEdit) {
            this.props.canBeSaveToEdit(this.state.supplierSelected, this.state.preparedBySelected, this.state.partNumberSelected, this.state.failureSelected, this.state.reportQuality)
        }
    }

    /**
     * Render show 
     */
    render() {
        const { classes } = this.props;
        let containmentIdentifyByType = GetContainmentIdentifyByType();
        let containmentColorType = GetContainmentColorType();
        let containmentUtilizeType = GetContainmentUtilizeType();
        let containmentLocationType = GetContainmentLocationType();
        return (
            <div className={classes.container} noValidate autoComplete="off">
                <Grid item xs={6}>
                    
                    <div className={classes.textField} >
                        <FormLabel component="legend"><Translate id="common.preparedBy"/> *</FormLabel>
                        <PreparedBySelector
                            prepareBy={this.state.preparedBySelected}
                            className={classes.textField}
                            onUsersQualitySelected={(user) => { this.onPrepareBySelected(user) }}
                        /><br/>
                    </div>

                    <div className={classes.textField} >
                        <FormLabel component="legend"><Translate id="common.supplier" /> *</FormLabel>
                        <SuppliersSelector
                            value={this.state.supplierSelected.token}
                            className={classes.textField}
                            onSupplierSelected={(supplier) => { this.onSupplierSelected(supplier) }}
                        />
                    </div>

                    {this.props.qualityReport && this.props.qualityReport.company_identifier === COMPANY_PTM && 
                            <TextField
                                id="standard-read-only-input"
                                label={"Lote"}
                                value={this.state.reportQuality.lotMaterial}
                                type="text"
                                margin="normal"
                                className={classes.textField}
                                onChange={(event) => { this.updateProperty("lotMaterial", event.target.value) }}
                                fullWidth
                            />
                        }
                        {this.props.qualityReport && this.props.qualityReport.company_identifier === COMPANY_PTM &&
                            <TextField
                                id="standard-read-only-input"
                                label={"Número de parte del cliente"}
                                value={this.state.reportQuality.customerPartNumber}
                                type="text"
                                margin="normal"
                                className={classes.textField}
                                onChange={(event) => { this.updateProperty("customerPartNumber", event.target.value) }}
                                fullWidth
                            />
                        }

                    <div className={classes.formatCheck}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend"><Translate id="qualityfailurereport.affect" /> *</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.reportQuality.functionalityDimension}
                                        onChange={(event) => { this.updateProperty('functionalityDimension', event.target.checked) }} />}
                                    label={<Translate id="qualityfailurereport.functionalityDimension" />}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.reportQuality.functionalitySpecification}
                                        onChange={(event) => { this.updateProperty('functionalitySpecification', event.target.checked) }} />}
                                    label={<Translate id="qualityfailurereport.functionalitySpecification" />}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={this.state.reportQuality.functionalityPackaging}
                                            onChange={(event) => { this.updateProperty('functionalityPackaging', event.target.checked) }} />}
                                    label={<Translate id="qualityfailurereport.functionalityPackaging" />}
                                />
                            </FormGroup>
                        </FormControl>

                        <FormControl required component="fieldset" className={classes.formControl} >
                            <FormHelperText>{<Translate id="common.selectedOption" />}</FormHelperText>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.reportQuality.functionalityMisidentified}
                                        onChange={(event) => { this.updateProperty('functionalityMisidentified', event.target.checked) }} />}
                                    label={<Translate id="qualityfailurereport.functionalityMisidentified" />}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.reportQuality.functionality}
                                        onChange={(event) => { this.updateProperty('functionality', event.target.checked) }} />}
                                    label={<Translate id="qualityfailurereport.functionality" />}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={this.state.reportQuality.functionalityAssembly}
                                            onChange={(event) => { this.updateProperty('functionalityAssembly', event.target.checked) }} />}
                                    label={<Translate id="qualityfailurereport.functionalityAssembly" />}
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <div className={classes.textField} >
                        <FormLabel component="legend"><Translate id="common.partNumber" /> *</FormLabel>
                        <PartNumberSelector
                            className={classes.textField}
                            value={this.state.partNumberSelected.code}
                            onPartNumberSelected={(partNumber) => { this.onPartNumberSelected(partNumber) }}
                        />
                    </div>

                    <TextField fullWidth
                        disabled={true}
                        id="standard-read-only-input"
                        label={<Translate id="common.descriptionPartNumber" />}
                        value={this.state.partNumberSelected ? this.state.partNumberSelected.description : ""}
                        className={classes.textField}
                        margin="normal"
                        onChange={(event) => { this.updateProperty("description", event.target.value) }}
                    />

                    <TextField fullWidth
                        disabled={true}
                        id="standard-read-only-input"
                        label={<Translate id="common.measurementUnit" />}
                        value={this.state.partNumberSelected ? this.state.partNumberSelected.measurementUnit : ""}
                        className={classes.textField}
                        margin="normal"
                        onChange={(event) => { this.updateProperty("measurementUnit", event.target.value) }}
                    />

                    <div className={classes.textField} ><br />
                        <FormLabel component="legend"><Translate id="rawmaterialnonconformity.failure" /> *</FormLabel>
                        <FailureNonConformitySelector
                            className={classes.textField}
                            failure={this.state.failureSelected}
                            onNonConformitySelected={(failure) => { this.onFailureSelected(failure) }}
                        />
                    </div>

                    <TextField fullWidth required
                        id="standard-read-only-input"
                        error={this.hasInvalidValueQantity()}
                        label={<Translate id="qualityfailurereport.qualityInPlant" />}
                        value={this.state.reportQuality.qualityInPlant}
                        className={classes.textField}
                        type="number"
                        margin="normal"
                        onChange={(event) => { this.updateProperty("qualityInPlant", event.target.value) }}
                    />

                    <TextField fullWidth required
                        id="standard-read-only-input"
                        error={this.hasInvalidValueQantity()}
                        label={<Translate id="qualityfailurereport.qualityReport" />}
                        value={this.state.reportQuality.qualityReport}
                        className={classes.textField}
                        type="number"
                        margin="normal"
                        onChange={(event) => { this.updateProperty("qualityReport", event.target.value) }}
                    /> <br /><br />

                </Grid>

                <Grid item xs={12}>
                    <TextField fullWidth required
                        id="outlined-multiline-flexible"
                        label={<Translate id="qualityfailurereport.problemDescription" />}
                        multiline
                        className={classes.tetxtFieldFull}
                        rows="6"
                        value={this.state.reportQuality.problemDescription}
                        onChange={(event) => { this.updateProperty("problemDescription", event.target.value) }}
                        margin="normal"
                        variant="outlined"
                    /><br /><br />
                </Grid>

                {canUserValideModuleQuality(this.props.userRolesByCompany) &&  <Grid item xs={6}>
                    <FormControl component="fieldset" className={classes.formControl}><br />
                        <FormLabel component="legend"><Translate id="qualityfailurereport.actionContainer" /></FormLabel>
                        <FormLabel component="legend"><Translate id="qualityfailurereport.containmentColor" /></FormLabel>
                        <FormGroup>
                            <Select
                                value={getSelectedValue(containmentColorType, this.state.reportQuality.color)}
                                options={containmentColorType}
                                fullWidth
                                onChange={(event) => { this.updatePropertySelected(event.value, "color") }}
                            />
                            <br />
                            <FormLabel component="legend"><Translate id="qualityfailurereport.containmentIdentify" /></FormLabel>
                            <Select
                                value={getSelectedValue(containmentIdentifyByType, this.state.reportQuality.identifyBy)}
                                options={containmentIdentifyByType}
                                fullWidth
                                onChange={(event) => { this.updatePropertySelected(event.value, "identifyBy") }}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>}

                {canUserValideModuleQuality(this.props.userRolesByCompany) &&  <Grid item xs={6}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend"><Translate id="qualityfailurereport.containmentUtilize" /></FormLabel>
                        <FormGroup>
                            <Select
                                value={getSelectedValue(containmentUtilizeType, this.state.reportQuality.utilize)}
                                options={containmentUtilizeType}
                                fullWidth
                                onChange={(event) => { this.updatePropertySelected(event.value, "utilize") }}
                            />
                            <br />
                            <FormLabel component="legend"><Translate id="qualityfailurereport.containmentLocation" /></FormLabel>
                            <Select
                                value={getSelectedValue(containmentLocationType, this.state.reportQuality.location)}
                                options={containmentLocationType}
                                fullWidth
                                onChange={(event) => { this.updatePropertySelected(event.value, "location") }}
                            />

                            <TextField fullWidth
                                id="standard-read-only-input"
                                label={<Translate id="qualityfailurereport.legend" />}
                                value={this.state.reportQuality.legend}
                                className={classes.textField}
                                margin="normal"
                                onChange={(event) => { this.updateProperty("legend", event.target.value) }}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>}

                <Grid container >
                    <DialogActions>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            onClick={this.canBeEditQualityFailureReport}
                            disabled={!this.canBeSaved() || this.props.isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                        <Button onClick={() => { this.props.history.push(`/qualityfailurereport/${this.props.match.params.token}`) }} color="secondary">
                            {<Translate id="common.cancel" />}
                        </Button>
                    </DialogActions>
                </Grid>

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </div>
        )
    }
}

const viewStyles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: "100px",
    },
    tetxtFieldFull: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        maxWidth: 1000,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        maxWidth: 450,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    footerForm: {
        display: "flex",
        paddingRight: "60px",
    },
    formatCheck: {
        display: 'flex',
        marginLeft: theme.spacing.unit,
        width: 460,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    },
    formControl: { margin: theme.spacing.unit },
    toolbar: {},
    searchInput: {},
    button: {},
    actionsToolbar: {},
    showingUsersCounter: {},
    grow: {},

});

export default withRouter(withStyles(viewStyles, { withTheme: true })(FormViewEdit));