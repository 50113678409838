import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { RViewer, RViewerTrigger } from 'react-viewerjs'

/**Import components materialUI */
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/**Import MaterialUi section */
import { List, ListItem, ListItemText, Divider, TextField, Grid, Button, Typography } from '@material-ui/core'
import Evidence from '../../common/Evidence'
import { saveServiceCO2 } from '../../../store/controlCO2/controlCO2Action'
import { getVehiclesSelector } from '../../../store/controlCO2/controlCO2Selectors'

/**Import components section */
import VehicleSelect from './VehicleSelect'

const ViewComponent = (props) => {
	const {
		classes,
		item,
		newService,
		OnUpdateProperty,
		modify,
		setModify,
		removefilesLastVerificationDate,
		uploadFilesLastVerificationDate,
		uploadFilesLastHoldingDate,
		removeFilesLastHoldingDate,
		uploadFilesLastDateOfServiceAttachments,
		removeFilesLastDateOfServiceAttachments,
		removeFilesOtherAttachments,
		uploadFilesOtherAttachments,
		onCloseButtonClick,
		onCloseModal,
		canBeModify
	} = props

	const buildVehicleOption = (vehicle) => ({ value: vehicle.token, label: `${vehicle.economicNumber}` })

	const [ imageView, setImageView ] = useState(false)
	const isSaving = useSelector((state) => state.controlCO2.get('isSaving'))
	const dispatch = useDispatch()
  
	/** Connect with redux */
	const allVehicles = useSelector((state) => getVehiclesSelector(state))
	const vehicleOptions = allVehicles.map((vehicle) => buildVehicleOption(vehicle))

	const saveService = () => {
		return dispatch(saveServiceCO2(newService))
			.then(() => {
				onCloseModal()
				onCloseButtonClick()
			})
			.catch((response) => {
				console.log('Error', response)
			})
	}

	const fileName = (fullName) => {
		let name = fullName.split('\\')[5]
		return name
	}

	const handleImageModal = () => {
        setImageView(!imageView)
    }

	if (modify)
		return (
			<>
				<div className={classes.containerInformation}>
					<List sx={{ width: '100%' }}>
						<ListItem>
							<TextField
								label='Ultima Fecha de verificación:	'
								fullWidth
								defaultValue={newService.lastVerificationDate}
								type='date'
								onChange={(event) => OnUpdateProperty('lastVerificationDate', event.target.value)}
							/>
						</ListItem>
						<ListItem>
							<Grid container style={{ width: 425 }} justify='space-between'>
								<Grid item xs={12}>
									<Evidence
										baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
										removeFiles={removefilesLastVerificationDate}
										uploadFiles={uploadFilesLastVerificationDate}
										files={newService.LastVerificationDateAttachments}
										text='Arrastra y suelta una imagen'
										acceptedFiles={['images']}
										disabledLink
									/>
								</Grid>
							</Grid>
						</ListItem>
						<ListItem>
							<TextField
								label='Ultima Fecha de servicio:	'
								fullWidth
								defaultValue={newService.lastDateOfService}
								type='date'
								onChange={(event) => OnUpdateProperty('lastDateOfService', event.target.value)}
							/>
						</ListItem>
						<ListItem>
							<Grid container style={{ width: 425 }} justify='space-between'>
								<Grid item xs={12}>
									<Evidence
										baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
										removeFiles={removeFilesLastDateOfServiceAttachments}
										uploadFiles={uploadFilesLastDateOfServiceAttachments}
										files={newService.lastDateOfServiceAttachments}
										text='Arrastra y suelta una imagen'
										acceptedFiles={['images']}
										disabledLink
									/>
								</Grid>
							</Grid>
						</ListItem>
						<ListItem>
							<TextField
								label='Ultima Fecha tenencia: '
								fullWidth
								defaultValue={newService.lastHoldingDate}
								type='date'
								onChange={(event) => OnUpdateProperty('lastHoldingDate', event.target.value)}
							/>
						</ListItem>
						<ListItem>
							<Grid container style={{ width: 425 }} justify='space-between'>
								<Grid item xs={12}>
									<Evidence
										baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
										removeFiles={removeFilesLastHoldingDate}
										uploadFiles={uploadFilesLastHoldingDate}
										text='Arrastra y suelta una imagen'
										files={newService.lastHoldingDateAttachments}
										acceptedFiles={['images']}
										disabledLink
									/>
								</Grid>
							</Grid>
						</ListItem>
						<ListItem>
							<Typography>Archivos adjuntos</Typography>
						</ListItem>
						<ListItem>
							<Grid container style={{ width: 425 }} justify='space-between'>
									<Grid item xs={12}>
										<Evidence
											baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
											removeFiles={removeFilesOtherAttachments}
											uploadFiles={uploadFilesOtherAttachments}
											text='Arrastra y suelta un archivo'
											files={newService.otherAttachments}
											acceptedFiles={['images', 'officeDocuments', 'pdf', 'otherFiles']}
											disabledLink
										/>
									</Grid>
								</Grid>
						</ListItem>
						<ListItem>
							<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
								{<Translate id='controlCO2.vehicle' />}
							</Typography>
						</ListItem>
						<ListItem>
							<Grid container style={{ width: 425 }} justify='space-between'>
								<Grid item xs={12}>
									<VehicleSelect
										options={vehicleOptions}
										onOptionChange={(selectedOption) => {
											OnUpdateProperty('vehicleToken', selectedOption.value)
										}}
									/>
								</Grid>
							</Grid>
						</ListItem>
						<ListItem>
							<TextField
								label='Origen del Transporte: '
								fullWidth
								onChange={(event) => OnUpdateProperty('originOfTransportation', event.target.value)}
							/>
						</ListItem>
						<ListItem>
							<TextField
								label='Tipo de Transporte:'
								fullWidth
								onChange={(event) => OnUpdateProperty('TypeOfTransportation', event.target.value)}
							/>
						</ListItem>
						<ListItem>
							<TextField
								label='Revisión de extintor:'
								fullWidth
								defaultValue={newService.FireExtinguisherInspection}
								type='date'
								onChange={(event) => OnUpdateProperty('FireExtinguisherInspection', event.target.value)}
							/>
						</ListItem>
					</List>
				</div>
				<Grid container className={classes.containerBtn}>
				{isSaving === true ?
					<Button size="small" variant="outlined" color="secondary" className={classes.button} disabled>
						<Translate id="common.saving" />
					</Button> : <Button
						data-cy='btn-save'
						type='button'
						color='primary'
						variant='outlined'
						onClick={saveService}
						className={classes.widthBtnSaved}
						disabled={isSaving}
					>
						{<Translate id='controlCO2.save' />}
					</Button>}
				</Grid>
			</>
		)
	else if(item !== null)
		{
		return (
			<>
				<div className={classes.containerInformation}>
					<List className={classes.listStyle} dense>
						<Divider component='li' className={classes.divider} />
						<ListItem>
							<ListItemText
								primary={<Translate id='controlCO2.lastVerificationDate' />}
								secondary={item && item.lastVerificationDate ? item.lastVerificationDate : ''}
							/>
						</ListItem>
						<ListItem>
							<RViewer imageUrls={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item && item.image1 ? item.image1 : ''}.jpg?w=164&h=164&fit=crop&auto=format`}>
								<RViewerTrigger>
									<button onClick={() => handleImageModal()}>
										<img
											srcSet={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item && item.image1 ? item.image1 : ''}.jpg?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
											src={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item && item.image1 ? item.image1 : ''}.jpg?w=164&h=164&fit=crop&auto=format`}
											alt={item && item.id ? item.id : ''}
											loading='lazy'
											width={'400px'}
										/>
									</button>
								</RViewerTrigger>
							</RViewer>
						</ListItem> 
						<Divider component='li' className={classes.divider} />
						<ListItem>
							<ListItemText
								primary={<Translate id='controlCO2.lastDateOfService' />}
								secondary={item && item.lastDateOfService ? item.lastDateOfService : ''}
							/>
						</ListItem>
						<ListItem>
							<RViewer imageUrls={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item && item.image2 ? item.image2 : ''}.jpg?w=164&h=164&fit=crop&auto=format`}>
								<RViewerTrigger>
									<button onClick={() => handleImageModal()}>
										<img
											srcSet={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item && item.image2 ? item.image2 : ''}.jpg?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
											src={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item && item.image2 ? item.image2 : ''}.jpg?w=164&h=164&fit=crop&auto=format`}
											alt={item && item.id ? item.id : ''}
											loading='lazy'
											width={'400px'}
										/>
									</button>
								</RViewerTrigger>
							</RViewer>
						</ListItem>
						<Divider component='li' className={classes.divider} />
						<ListItem>
							<ListItemText
								primary={<Translate id='controlCO2.lastHoldingDate' />}
								secondary={item && item.lastHoldingDate ? item.lastHoldingDate : ''}
							/>
						</ListItem>
						<ListItem>
							<RViewer imageUrls={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item && item.image3 ? item.image3 : ''}.jpg?w=164&h=164&fit=crop&auto=format`}>
								<RViewerTrigger>
									<button onClick={() => handleImageModal()}>
										<img
											srcSet={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item && item.image3 ? item.image3 : ''}.jpg?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
											src={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item && item.image3 ? item.image3 : ''}.jpg?w=164&h=164&fit=crop&auto=format`}
											alt={item && item.id ? item.id : ''}
											loading='lazy'
											width={'400px'}
										/>
									</button>
								</RViewerTrigger>
							</RViewer>
						</ListItem>
						<Divider component='li' className={classes.divider} />
						<ListItem>
							<ListItemText
								primary={<Translate id='controlCO2.attachments' />}
							/>
						</ListItem>
						{item && item.attachmentQty && item.attachmentQty.length > 0 && item.attachmentQty.map((attachment) => (
							<ListItem>
								<a href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${attachment}`}>{fileName(attachment)}</a>
							</ListItem>
						))}
						<Divider component='li' className={classes.divider} />
						<ListItem>
							<ListItemText 
								primary={<Translate id='controlCO2.vehicle' />}
								secondary={<Typography>{item.vehicleEconomicNumber} | {item.vehicleBrandName} {item.vehicleModelName} | {item.vehicleLicensePlate}</Typography>}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary={<Translate id='controlCO2.originOfTransportation' />}
								secondary={item.originOfTransportation}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary={<Translate id='controlCO2.typeOfTransportation' />}
								secondary={item.typeOfTransportation}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary={<Translate id='controlCO2.fireExtinguisherInspection' />}
								secondary={item.fireExtinguisherInspection}
							/>
						</ListItem>
					</List>
				</div>
				{canBeModify && <Grid container className={classes.containerBtn}>
					<Button
						data-cy='btn-save'
						type='button'
						color='secondary'
						variant='outlined'
						onClick={() => setModify(true)}
						className={classes.widthBtnSaved}
					>
						{<Translate id='controlCO2.modify' />}
					</Button>
				</Grid>}
			</>
		)}
}

const styles = (theme) => ({
	containerInformation: {
		maxHeight: `calc(100vh - ${165}px)`,
		minHeight: `calc(100vh - ${165}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
		padding: '5px',
	},
	widthBtnSaved: {
		minWidth: '200px',
	},
	divider: {
		marginRight: '10px',
	},
})

ViewComponent.propTypes = {
	onCloseButtonClick: propTypes.func,
	item: propTypes.object,
	newService: propTypes.object,
	OnUpdateProperty: propTypes.func,
	modify: propTypes.bool,
	setModify: propTypes.func,
	removefilesLastVerificationDate: propTypes.func,
	uploadFilesLastVerificationDate: propTypes.func,
	uploadFilesLastHoldingDate: propTypes.func,
	removeFilesLastHoldingDate: propTypes.func,
	uploadFilesLastDateOfServiceAttachments: propTypes.func,
	removeFilesLastDateOfServiceAttachments: propTypes.func,
	removeFilesOtherAttachments: propTypes.func,
	uploadFilesOtherAttachments: propTypes.func,
	onCloseModal: propTypes.func,
	canBeModify: propTypes.bool,
}

ViewComponent.defaultProps = {
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	item: {},
	newService: {},
	OnUpdateProperty: () => console.warn('Callback [OnUpdateProperty] no defined'),
	modify: false,
	setModify: () => console.warn('Callback [setModify] no defined'),
	removefilesLastVerificationDate: () => console.warn('Callback [removefilesLastVerificationDate] no defined'),
	uploadFilesLastVerificationDate: () => console.warn('Callback [uploadFilesLastVerificationDate] no defined'),
	uploadFilesLastHoldingDate: () => console.warn('Callback [uploadFilesLastHoldingDate] no defined'),
	removeFilesLastHoldingDate: () => console.warn('Callback [removeFilesLastHoldingDate] no defined'),
	uploadFilesLastDateOfServiceAttachments: () => console.warn('Callback [uploadFilesLastDateOfServiceAttachments] no defined'),
	removeFilesLastDateOfServiceAttachments: () => console.warn('Callback [removeFilesLastDateOfServiceAttachments] no defined'),
	removeFilesOtherAttachments: () => console.warn('Callback [removeFilesOtherAttachments] no defined'),
	uploadFilesOtherAttachments: () => console.warn('Callback [uploadFilesOtherAttachments] no defined'),
	onCloseModal: () => console.warn('Callback [onCloseModal] no defined'),
	canBeModify: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(ViewComponent))
