import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
/** Material UI impoerts sections */
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import { Typography, CardHeader, CardContent, IconButton, Avatar, Grid, Tooltip, Button } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import Dialog from '../common/Dialog'
import DialogActions from '@material-ui/core/DialogActions'

/** Import component section */
import Select from 'react-select'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import BarChart from '../common/BarChart'
import DataTable from '../common/DataTable'

/** Actions and helpers imports section */
import { GetYears, GetMonths, getSelectedValue } from '../../store/helpers/SelectOptions'
import LoadingPage from '../common/LoadingPage'
import { loadSupplier } from '../../store/suppliers/SupplierActions'
import { genereteStringGuid } from '../../store/helpers/StringHelper'
import moment from 'moment'
import {
	YEAR_MONTHS,
	COMPANY_IDENTIFIER_IMBERA_REPARE,
	COMPANY_IDENTIFIER_IMBERA_BRASIL,
	COMPANY_IDENTIFIER_IMBERA_MX,
} from '../../store/helpers/ResourcesHelper'
import {
	loadRatingsBySupplierFromBackEnd,
	loadEvaluationSuppliersRatingByCompanyFromBackEnd,
	downloadReportBySupplierByDateRange,
} from '../../store/ratings/ratingActions'
import { COMPANY_PTM } from '../../store/helpers/ResourcesHelper'
import { showSuccessToaster, showErrorToaster } from '../../store/helpers/ToasterHelpers'
import ModalRatingDetails from './common/ModalRatingDetails'

class RatingsView extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			ratingsChart: [],
			average: 0,
			filteredRatings: [],
			showModalDownloadReport: false,
			initialDate: {
				selectedMonth: moment().month(),
				selectedYear: moment().year(),
			},
			finalDate: {
				selectedMonth: moment().month(),
				selectedYear: moment().year(),
			},
		}
	}

	componentWillMount() {
		this.setState({ ratingsChart: [] })
	}

	convertRatingChartData() {
		let dataSet = []
		let labelsSections = YEAR_MONTHS
		let years = [...new Set(this.props.ratings.map((rating) => rating.rating_year))]
		years.forEach((year) => {
			let ratingsValues = []
			let ratingsColors = []
			labelsSections.forEach((month, index) => {
				this.props.ratings.forEach((rating) => {
					if (year === rating.rating_year) {
						let monthIndex = moment().month(rating.rating_date).format('M')
						if (Number(monthIndex) === index + 1) {
							ratingsValues[index] = rating.final_score
							ratingsColors[index] = rating.color_final_score
						}
					}
				})
			})
			dataSet.push({
				data: ratingsValues,
				labelGroup: year,
				labelColor: '#ff0000',
				backgroundColors: ratingsColors,
				hiddenLegend: year !== moment().format('YYYY'),
			})
		})
		return dataSet
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			let supplierToken = this.props.match.params.token
			this.props.loadSupplier(supplierToken).then(() => {
				if (this.props.supplier)
					this.props.loadRatings(this.props.supplier.code).then(() => {
						this.setState(
							{
								ratingsChart: this.convertRatingChartData(),
							},
							() => {
								let availableYears = [
									...new Set(this.props.ratings.map((rating) => Number(rating.rating_year))),
								]
								let maxYear = Math.max(...availableYears)
								let selectedYears = [String(maxYear)]
								this.filterRatings(selectedYears)
							}
						)
					})
			})
			if (!this.props.evaluationRating) {
				this.props.loadEvaluationSuppliersRatingByCompany(this.props.selectedCompany.token)
			}
		}
	}

	onRenderRatingsCellItem(dataSource, item) {
		if (dataSource === 'supplier_name') {
			return `${item['supplier_number']} | ${item[dataSource]}`
		}
		if (dataSource === 'final_score') {
			return <Typography style={{ color: item.color_final_score }}>{item[dataSource]}</Typography>
		}
		if (dataSource === 'details' && item.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX) {
			return item.items_rating.length > 0 ? <ModalRatingDetails rating={item} /> : <></>
		}
	}

	/**
	 * On Render aditional row data
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof DetailsCard
	 */
	onRenderCellAditionalRowItem(dataSource, item) {
		if (dataSource === 'items' && item[dataSource]) {
			return (
				<React.Fragment>
					{item.company_identifier === COMPANY_IDENTIFIER_IMBERA_BRASIL ? (
						<DataTable data={item[dataSource]} configuration={TableConfigurationItemsBR} />
					) : item.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ? (
						<DataTable data={item[dataSource]} configuration={TableImberaSJRConfigurationItems} />
					) : (
						<DataTable data={item[dataSource]} configuration={TableConfigurationItems} />
					)}
				</React.Fragment>
			)
		}
	}

	calculateAverage() {
		if (this.state.filteredRatings.length > 0) {
			let scores = this.state.filteredRatings.map((x) => x.final_score) //.reduce((a, b) => a + b, 0) / this.state.filteredRatings.length;
			let avg = scores.reduce((a, b) => a + b, 0) / scores.length
			this.setState({ average: avg.toFixed(2) })
		}
	}

	filterRatings(selectedYears) {
		this.setState(
			{
				filteredRatings: this.props.ratings.filter(
					(x) => selectedYears.find((year) => year === x.rating_year) !== undefined
				),
			},
			() => this.calculateAverage()
		)
	}

	handlerLegendClick(legendItems) {
		let selectedYears = legendItems.filter((x) => !x.hidden).map((x) => x.text)
		this.filterRatings(selectedYears)
		// this.calculateAverage();
	}

	showModalDownloadReport() {
		this.setState(
			{
				showModalDownloadReport: !this.state.showModalDownloadReport,
			},
			() => {
				if (!this.state.showModalDownloadReport)
					this.setState({
						...this.state,
						initialDate: {
							selectedMonth: moment().month(),
							selectedYear: moment().year(),
						},
						finalDate: {
							selectedMonth: moment().month(),
							selectedYear: moment().year(),
						},
					})
			}
		)
	}

	onSetSelection(selection, id) {
		this.setState({
			[id.split('.')[0]]: {
				...this.state[id.split('.')[0]],
				[id.split('.')[1]]: selection.value,
			},
		})
	}
	renderSelect(id, value, options) {
		return (
			<Select
				id={id}
				value={value}
				options={options}
				onChange={(selection) => this.onSetSelection(selection, id)}
				styles={{
					menu: (base) => ({ ...base, zIndex: 1000, height: 150 }),
					menuList: (base) => ({ ...base, height: 150, paddingTop: 0 }),
					menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
				}}
				menuPortalTarget={document.parentNode}
				menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
				menuPlacement={'auto'}
				isDisabled={this.props.isSaving}
			/>
		)
	}
	renderCalendar() {
		let years = GetYears()
		let months = GetMonths()
		return (
			<div style={{ margin: 10, width: 500 }}>
				<Grid container spacing={8}>
					<Grid item xs={12} md={6}>
						<Typography variant='caption'>
							<Translate id='rating.selectInitialDate' />
						</Typography>
						{this.renderSelect(
							'initialDate.selectedMonth',
							months[this.state.initialDate.selectedMonth],
							months
						)}
						{this.renderSelect(
							'initialDate.selectedYear',
							getSelectedValue(years, this.state.initialDate.selectedYear),
							years
						)}
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant='caption'>
							<Translate id='rating.selectFinalDate' />
						</Typography>
						{this.renderSelect(
							'finalDate.selectedMonth',
							months[this.state.finalDate.selectedMonth],
							months
						)}
						{this.renderSelect(
							'finalDate.selectedYear',
							getSelectedValue(years, this.state.finalDate.selectedYear),
							years
						)}
					</Grid>
				</Grid>
			</div>
		)
	}

	/**
	 * Render buttons for download report by month
	 */
	renderModalButtons() {
		return (
			<DialogActions>
				<Button
					disabled={
						this.state.initialDate.selectedYear > this.state.finalDate.selectedYear ||
						(this.state.initialDate.selectedYear === this.state.finalDate.selectedYear &&
							this.state.initialDate.selectedMonth > this.state.finalDate.selectedMonth) ||
						this.props.isSaving
					}
					onClick={this.onDownloadReportByDateRange}
					color='primary'
				>
					<Translate id={this.props.isSaving ? 'common.downloadingReport' : 'common.download'} />
				</Button>
				<Button onClick={this.showModalDownloadReport} color='secondary' disabled={this.props.isSaving}>
					<Translate id='common.cancel' />
				</Button>
			</DialogActions>
		)
	}

	/**
	 *Download report rating by supplier by date range
	 *
	 * @memberof Index
	 */
	onDownloadReportByDateRange() {
		let initialDate = `01-${this.state.initialDate.selectedMonth}-${this.state.initialDate.selectedYear}`
		let lastDayOfMonth = moment(`${this.state.finalDate.selectedYear}-${this.state.finalDate.selectedMonth}-01`)
			.endOf('month')
			.format('DD')
		let finalDate = `${lastDayOfMonth}-${this.state.finalDate.selectedMonth}-${this.state.finalDate.selectedYear}`

		this.props
			.downloadReportBySupplierByDateRange(this.props.supplier.code, initialDate, finalDate)
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
				this.showModalDownloadReport()
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	render() {
		const { classes } = this.props
		if (this.props.isLoadingSupplier === true || this.props.isLoadingRating === true) {
			return <LoadingPage />
		} else if (this.props.supplier === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let supplier = this.props.supplier
			let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${supplier.logo}`
			let logoUrl = urlImage + '?token=' + genereteStringGuid()

			let logoAvatar = <IconButton className={classes.avatar}>P </IconButton>
			if (supplier.logo) {
				logoAvatar = <Avatar alt='Remy Sharp' src={logoUrl} className={classes.avatar} />
			}
			let tableConfiguration = TableratingsTableConfiguration(this.props.evaluationRating, this.props.translate)
			if (this.props.selectedCompany.get_company_identifier === COMPANY_PTM) {
				tableConfiguration = TableratingsPTMConfiguration(this.props.translate)
			}
			if (this.props.selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_BRASIL) {
				tableConfiguration = TableratingsTableBrasil(this.props.translate)
			}
			if (this.props.selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE) {
				tableConfiguration = RatingsRepareTableConfiguration(this.props.translate)
			}
			if (this.props.selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX) {
				tableConfiguration = RatingsImberaSJRTableConfiguration(this.props.translate)
			}
			return (
				<Paper className={this.props.classes.root}>
					<CardHeader
						avatar={logoAvatar}
						title={supplier.full_name}
						subheader={supplier.rfc}
						action={
							<Tooltip title={<Translate id='rating.downloadReportBySupplier' />}>
								<IconButton color='primary' onClick={this.showModalDownloadReport}>
									<DownloadIcon />
								</IconButton>
							</Tooltip>
						}
					/>
					<CardContent>
						{this.state.ratingsChart.length > 0 && (
							<BarChart dataSets={this.state.ratingsChart} handlerLegendClick={this.handlerLegendClick} />
						)}
						<Card className={this.props.classes.paper}>
							<DataTable
								alignCellContent='center'
								data={this.state.filteredRatings}
								configuration={tableConfiguration}
								onRenderCellItem={this.onRenderRatingsCellItem}
								collapsableChild
								aditionalRows={AditionalRowsPTM}
								onRenderCellAditionalRowItem={this.onRenderCellAditionalRowItem}
							/>
							<Grid container justify='flex-end' alignItems='flex-end'>
								<Grid item xs={2}>
									<strong>
										<Translate id='rating.average' />:
									</strong>
								</Grid>
								<Grid item xs={1}>
									<Typography align='left'>{this.state.average}</Typography>
								</Grid>
							</Grid>
						</Card>
					</CardContent>
					<Dialog
						open={this.state.showModalDownloadReport}
						onClose={this.showModalDownloadReport}
						header={
							<Typography style={{ margin: 15 }}>
								<Translate id='rating.downloadReportBySupplier' />
							</Typography>
						}
						children={this.renderCalendar()}
						actions={this.renderModalButtons()}
					/>
				</Paper>
			)
		}
	}
}

function TableratingsTableConfiguration(evaluation, translate) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: translate('rating.ratingsTable.month'),
			dataSource: 'rating_date',
		},
		{
			header: `${translate && translate('rating.ratingsTable.deliveries')} ${
				evaluation ? `${evaluation.delivery_points}` : '25'
			}%`,
			dataSource: 'delivery_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.development')} ${
				evaluation ? `${evaluation.sourcing_points}` : '25'
			}%`,
			dataSource: 'development_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.quality')} ${
				evaluation ? `${evaluation.quality_points}` : '40'
			}%`,
			dataSource: 'quality_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.service')} ${
				evaluation ? `${evaluation.service_points}` : '10'
			}%`,
			dataSource: 'service_points',
		},
		{
			header: <Translate id='rating.ratingsTable.finalScore' />,
			dataSource: 'final_score',
		},
	]
	return { columns }
}

function RatingsRepareTableConfiguration(translate) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: translate('rating.ratingsTable.month'),
			dataSource: 'rating_date',
		},
		{
			header: `${translate && translate('rating.ratingsTable.deliveries')} ${'45'}%`,
			dataSource: 'delivery_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.quality')} ${'45'}%`,
			dataSource: 'quality_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.service')} ${'10'}%`,
			dataSource: 'service_points',
		},
		{
			header: <Translate id='rating.ratingsTable.finalScore' />,
			dataSource: 'final_score',
		},
	]
	return { columns }
}

function TableratingsPTMConfiguration(translate) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'expand',
		},
		{
			header: translate('rating.ratingsTable.month'),
			dataSource: 'rating_date',
		},
		{
			header: `${translate && translate('rating.ratingsTable.deliveries')} ${'40'}%`,
			dataSource: 'delivery_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.quality')} ${'50'}%`,
			dataSource: 'quality_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.service')} ${'10'}%`,
			dataSource: 'service_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.garrantiePtm')}`,
			dataSource: 'guarantee_points',
		},
		{
			header: <Translate id='rating.ratingsTable.finalScore' />,
			dataSource: 'final_score',
		},
	]
	return { columns }
}
function RatingsImberaSJRTableConfiguration(translate) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'expand',
		},
		{
			header: translate('rating.ratingsTable.month'),
			dataSource: 'rating_date',
		},
		{
			header: `${translate && translate('rating.ratingsTable.deliveries')} ${'25'}%`,
			dataSource: 'delivery_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.development')} ${'25'}%`,
			dataSource: 'development_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.quality')} ${'40'}%`,
			dataSource: 'quality_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.service')} ${'10'}%`,
			dataSource: 'service_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.garrantiePtm')}`,
			dataSource: 'guarantee_points',
		},
		{
			header: ``,
			dataSource: 'details',
		},
		{
			header: <Translate id='rating.ratingsTable.finalScore' />,
			dataSource: 'final_score',
		},
	]
	return { columns }
}
/** Configuration to show additional rows items */
const AditionalRowsPTM = {
	rows: [
		{
			dataSource: 'items',
			type: 'dataList',
		},
	],
}

function TableratingsTableBrasil(translate) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'expand',
		},
		{
			header: translate('rating.ratingsTable.month'),
			dataSource: 'rating_date',
		},
		{
			header: `${translate && translate('rating.ratingsTable.ppm')} ${'45'}%`,
			dataSource: 'quality_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.rnc')} ${'20'}%`,
			dataSource: 'service_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.deliveries')} ${'25'}%`,
			dataSource: 'delivery_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.warehouse')} ${'10'}%`,
			dataSource: 'development_points',
		},
		{
			header: <Translate id='rating.ratingsTable.finalScore' />,
			dataSource: 'final_score',
		},
	]
	return { columns }
}

const TableConfigurationItemsBR = {
	columns: [
		{
			header: 'Quantidade de Entregas ',
			dataSource: 'quantity_rate',
		},
		{
			header: 'Quantidade Rejeitada',
			dataSource: 'sample_rejections_rate',
		},
		{
			header: 'PPM´s',
			dataSource: 'quality_ppms_rate',
		},
		{
			header: 'RNC Recebimento',
			dataSource: 'delivery_order_rate',
		},
		{
			header: 'RNC Processo',
			dataSource: 'service_rate',
		},
		{
			header: 'Não atendimento - lead time',
			dataSource: 'date_arrive_rate',
		},
		{
			header: 'Documentação Divergente no recebimento ',
			dataSource: 'quotes_rate',
		},
	],
}

/** Table configuration to render Datatable of aditional row */
const TableConfigurationItems = {
	columns: [
		{
			header: <Translate id='rating.ratingsTable.deliveryDocumentation' />,
			dataSource: 'delivery_order_rate',
		},
		{
			header: <Translate id='rating.ratingsTable.deliveryHour' />,
			dataSource: 'date_arrive_rate',
		},
		{
			header: <Translate id='rating.ratingsTable.deliveryVolumen' />,
			dataSource: 'quantity_rate',
		},
		{
			header: <Translate id='rating.ratingsTable.qualityPPTM' />,
			dataSource: 'quality_ppms_rate',
		},
		{
			header: <Translate id='rating.ratingsTable.qualityRejectedRamp' />,
			dataSource: 'quality_raw_material_ramp_rate',
		},
		{
			header: <Translate id='rating.ratingsTable.qualitRejectedProcess' />,
			dataSource: 'quality_failure_process_rate',
		},
		{
			header: <Translate id='rating.ratingsTable.serviceQuote' />,
			dataSource: 'quotes_rate',
		},
		{
			header: <Translate id='rating.ratingsTable.serviceSample' />,
			dataSource: 'response_samples_rate',
		},
		{
			header: <Translate id='rating.ratingsTable.serviceSacs' />,
			dataSource: 'sac_rate',
		},
	],
}

const TableImberaSJRConfigurationItems = {
	columns: [
		{
			header: (
				<>
					<Translate id='rating.ratingsTable.deliveries' /> (20%)
				</>
			),
			dataSource: 'date_arrive_rate',
		},
		{
			header: (
				<>
					<Translate id='companies.evaluations.quotesRate' /> (9%)
				</>
			),
			dataSource: 'quotes_rate',
		},
		{
			header: (
				<>
					<Translate id='companies.evaluations.responseSamplesRate' /> (2%)
				</>
			),
			dataSource: 'response_samples_rate',
		},
		{
			header: (
				<>
					<Translate id='companies.evaluations.compromiseDateSampleRate' /> (5%)
				</>
			),
			dataSource: 'compliance_date_sample_rate',
		},
		{
			header: (
				<>
					<Translate id='companies.evaluations.sampleRejectionsRate' /> (9%)
				</>
			),
			dataSource: 'sample_rejections_rate',
		},
		{
			header: (
				<>
					<Translate id='companies.evaluations.qualityFailureProcessRate' /> (20%)
				</>
			),
			dataSource: 'quality_failure_process_rate',
		},
		{
			header: (
				<>
					<Translate id='companies.evaluations.qualityOnlineStopRate' /> (10%)
				</>
			),
			dataSource: 'quality_raw_material_ramp_rate',
		},
		{
			header: (
				<>
					<Translate id='companies.evaluations.qualityPPMSRate' /> (5%)
				</>
			),
			dataSource: 'quality_ppms_rate',
		},
		{
			header: (
				<>
					<Translate id='companies.evaluations.sacRate' /> (5%)
				</>
			),
			dataSource: 'sac_rate',
		},
		{
			header: (
				<>
					<Translate id='companies.evaluations.servicePoints' /> (10%)
				</>
			),
			dataSource: 'service_rate',
		},
	],
}

const styles = (theme) => ({
	paper: {
		margin: `${theme.spacing.unit}px auto`,
		padding: theme.spacing.unit * 2,
	},
	root: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: `0 ${theme.spacing.unit * 3}px`,
	},
})

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingSupplier: state.suppliers.get('isLoadingSupplier'),
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		isLoadingRating: state.ratings.get('isLoadingRating'),
		ratings: state.ratings.get('ratings') ? state.ratings.get('ratings').toJS() : null,
		translate: getTranslate(state.localize),
		evaluationRating: state.ratings.get('evaluationRating') ? state.ratings.get('evaluationRating').toJS() : null,
		isSaving: state.ratings.get('isSaving'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		loadRatings: (supplierToken) => {
			return dispatch(loadRatingsBySupplierFromBackEnd(supplierToken, false))
		},
		loadEvaluationSuppliersRatingByCompany: (companyToken) => {
			return dispatch(loadEvaluationSuppliersRatingByCompanyFromBackEnd(companyToken))
		},
		downloadReportBySupplierByDateRange: (supplierNumber, initialDate, finalDate) => {
			return dispatch(downloadReportBySupplierByDateRange(supplierNumber, initialDate, finalDate))
		},
	}
}

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RatingsView)))
)
