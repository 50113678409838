
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';

/**
 * Dialog to register material devolution
 */
class RegisterMaterial extends Component {

    /**
   *Creates an instance of RegisterMaterial.
   * @param {*} props
   * @memberof RegisterMaterial
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            carrierName: "",
            carrierUnitPlates: "",
        }
    }

    /**
     * Function to add carrierName
     * @param {*} carrierName 
     */
    updateteCarrierNameProperty(carrierName) {
        this.setState({
            carrierName: carrierName
        })
    }

    /**
     * Function to update carrierUnitPlates
     */
    updatetecarrierUnitPlatesProperty(carrierUnitPlates){
        this.setState({
            carrierUnitPlates: carrierUnitPlates
        })
    }

    /**
     * onSave
     */
    onSaveItem() {
        if (this.state.carrierName) {
            if(this.props.onSaveItem)
                this.props.onSaveItem(this.state.carrierName, this.state.carrierUnitPlates);
            this.setState({carrierName: "", carrierUnitPlates: ""})
        }
    }

    /**
     * Dialog information
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
            open = {this.props.open}
            onClose={this.props.onClose}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id="devolutions.registerMaterial"/>}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={this.onSaveItem}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.state.carrierName !== ""}
                    />
                }>

                <DialogContentText>
                    <strong className="text-warning"><i className="fa fa-exclamation-circle"></i> {<Translate id="devolutions.warning"/>}</strong><br/>
                    <Translate id="devolutions.messageRegisterMaterial"/>
                </DialogContentText>
                <form><br/>
                    <TextField fullWidth autoFocus required
                        id="standard-read-only-input"
                        className={classes.textField}
                        label={<Translate id="devolutions.carrierNameRequierd"/>}
                        defaultValue={this.state.carrierName}
                        margin="normal"
                        onChange={(event) => { this.updateteCarrierNameProperty(event.target.value) }}
                    />
                    <TextField fullWidth
                        className={classes.textField}
                        id="standard-read-only-input"
                        label={<Translate id="devolutions.carrierUnitPlates"/>}
                        defaultValue={this.state.carrierUnitPlates}
                        margin="normal"
                        onChange={(event) => { this.updatetecarrierUnitPlatesProperty(event.target.value) }}
                    />
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
    },
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(RegisterMaterial));