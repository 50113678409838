import React from 'react'
import { useSelector } from 'react-redux'

import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

/** Import section */
import WorkshopIndex from './WorkshopIndex'
import AdministratorIndex from './AdministratorIndex'

const IndexRouter = (props) => {
	/** Connect with redux */
	const userProfile = useSelector((state) => state.oidc.user)

	function isInternalUser() {
		if (userProfile.profile.user_type === 'ActiveDirectoryUser') return true
		return false
	}

	if (!userProfile) return null

	return <>{isInternalUser() ? <AdministratorIndex /> : <WorkshopIndex />}</>
}

export default withauthenticatedLayout(IndexRouter)
