
import React, { Component } from 'react';

/** Material-UI imports section */
import {Translate} from "react-localize-redux";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";

import DataTable from '../../common/DataTable';

/**
 * Component to show list AlternativeProvedurias data
 */
export default class AlternativeProvedurias extends Component{

     /**
     * On render cell item custom
     * @param {*} dataSource 
     * @param {*} item 
     */
    onRenderCellItem(dataSource, item) {
        if(dataSource === "type_request"){
            if(item.type_request === "QuoteSamples")
                return (<Translate id="quotes.quote"/>)
            else if(item.type_request === "SampleRequest")
                return (<Translate id="samplesrequest.samplesrequest"/>)
        }
    }
    
          /**
     * Get the card content
     *
     * @memberof Index
     */
    getCardContent() {
        if(this.props.proveduries === null){
            return(
                <div className="loader-container">            
                    <Typography variant="subtitle1" gutterBottom> <Translate id="common.notInformation"/></Typography>
                </div>
            )
        } else {
            return (
                <DataTable 
                    data = {this.props.proveduries}
                    configuration = {TableConfiguration}
                    onRenderCellItem = {this.onRenderCellItem}
                    onRowClick = {(provedure) => {
                        this.props.history.push(`/alternativeprovedure/${provedure.token}`)
                    }}
                />
            )
        }
    }

    render(){
        return(
            <Card>
                <CardContent>
                    {this.getCardContent()}                
                </CardContent>
            </Card>
        )
    }
} 

const TableConfiguration = {
    columns: [{
        header: <Translate id="common.folio"/>,
        dataSource: 'folio'
    },{
        header: <Translate id="common.status"/>,
        dataSource: 'status'
    },{
        header: <Translate id="common.supplier"/>,
        dataSource: 'supplier'
    },{
        header: <Translate id="memos.documentType"/>,
        dataSource: 'type_request',
    }]
}