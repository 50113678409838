/**Import reatc section */
import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
/**Import componet password strength */
import PasswordStrengthBar from 'react-password-strength-bar';

const PasswordStrength = (props) => {
    /**
     * Function props section
     */
    const { onChangeScore } = props;

    /**
     * Local store this component
     */
    const [passwordStrength, setPasswordStrength] = useState('');

    useEffect(() => {
        setPasswordStrength(props.password)
    }, [props.password])

    return (
        <PasswordStrengthBar
            password={passwordStrength}
            scoreWords={['Demasiado corta', 'Baja', 'Regular', 'Buena', 'Excelente']}
            shortScoreWord="Demasiado corta"
            onChangeScore={onChangeScore}
        />
    )
}

PasswordStrength.propTypes = {
    /**
     * Function invoke callback for is good  Punctuation
     */
    onChangeScore: propTypes.func,

    /**
     * Password new
     */
    password: propTypes.string.isRequired

}
export default PasswordStrength
