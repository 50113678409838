/** react imports section */
import React from 'react'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import propTypes from 'prop-types'
/** Material-UI imports section */
import { makeStyles } from '@material-ui/styles'
import { List, ListItem, ListItemText, Typography } from '@material-ui/core'
/** components imports section */
import DataTable from '../../../common/DataTable'
/** helpers imports section */
import {
	backgroundColorDocumentStatus,
	descriptionDocumentStatus,
} from '../../../../store/helpers/SpecificationsHelper'
import { COLOR_CART_PORT } from '../../../../store/helpers/StatusColorConstants'

const GuidelineDetailContent = (props) => {
	const { controlChanges, isLoading, activities, authrizations } = props
	const classes = useStyles()

	const onRenderCellItemControlChanges = (dataSource, item) => {
		if (dataSource === 'creation_date') {
			return moment(item.creation_date, 'DD/MM/YYYY').format('DD/MM/YYYY')
		}
		if (dataSource === 'no_warning') {
			return <div dangerouslySetInnerHTML={{ __html: item.no_warning }} />
		}
	}

	const onRenderCellItemActivities = (dataSource, item) => {
		if (dataSource === 'description') {
			return <div dangerouslySetInnerHTML={{ __html: item.description }} />
		}
		if (dataSource === 'visual_aid') {
			return (
				<div
					dangerouslySetInnerHTML={{ __html: makeHTMLmediaTag(item.visual_aid) }}
					className={classes.htmlContainer}
				/>
			)
		}
	}

	const onRenderCellItemAuthorizer = (dataSource, item) => {
		if (dataSource === 'authorization_date') {
			return moment(item.authorization_date, 'DD/MM/YYYY').format('DD/MM/YYYY')
		}
		if (dataSource === 'status') {
			return (
				<Typography variant='caption'>
					<strong
						className={classes.btnStatus}
						style={{ backgroundColor: backgroundColorDocumentStatus(item.status) }}
					>
						{descriptionDocumentStatus(item.status)}
					</strong>
				</Typography>
			)
		}
	}

	const makeHTMLmediaTag = (value) => {
		const parse = new DOMParser()
		const doc = parse.parseFromString(value, 'text/html')
		const imgElement = doc.querySelector('img')
		const urlImg = imgElement ? imgElement.src : null
		if (urlImg !== null) {
			return `<img class="${classes.media}" src="${urlImg}"/>`
		} else {
			const videoElement = doc.querySelector('video')
			const urlVideo = videoElement ? videoElement.src : null
			if (urlVideo !== null) {
				return `<video controls="controls" preload="metadata" src="${urlVideo}" class="${classes.media}"/>`
			} else return value
		}
	}

	return (
		<List>
			<ListItem style={{ backgroundColor: COLOR_CART_PORT }}>
				<ListItemText
					secondary={
						<Typography component='span' variant='body2' color='textPrimary'>
							<strong>
								<Translate id='specificationsSection.guidelines.controlChange' />
							</strong>
						</Typography>
					}
				/>
			</ListItem>
			{isLoading === false && controlChanges && (
				<DataTable
					data={controlChanges}
					configuration={ControlChangesTableConfiguration()}
					onRenderCellItem={onRenderCellItemControlChanges}
				/>
			)}
			<ListItem style={{ backgroundColor: COLOR_CART_PORT }}>
				<ListItemText
					secondary={
						<Typography component='span' variant='body2' color='textPrimary'>
							<strong>
								<Translate id='specificationsSection.guidelines.authorizations' />
							</strong>
						</Typography>
					}
				/>
			</ListItem>
			{isLoading === false && authrizations && (
				<DataTable
					data={authrizations}
					configuration={AuthorizationsTableConfiguration()}
					onRenderCellItem={onRenderCellItemAuthorizer}
				/>
			)}
			<ListItem style={{ backgroundColor: COLOR_CART_PORT }}>
				<ListItemText
					secondary={
						<Typography component='span' variant='body2' color='textPrimary'>
							<strong>
								<Translate id='specificationsSection.guidelines.activities' />
							</strong>
						</Typography>
					}
				/>
			</ListItem>
			{isLoading === false && activities && (
				<DataTable
					data={activities}
					configuration={ActivitiesTableConfiguration()}
					onRenderCellItem={onRenderCellItemActivities}
				/>
			)}
		</List>
	)
}

function ControlChangesTableConfiguration() {
	let columns = [
		{
			header: <Translate id='specificationsSection.guidelines.date' />,
			dataSource: 'creation_date',
		},
		{
			header: <Translate id='specificationsSection.guidelines.description' />,
			dataSource: 'description',
		},
		{
			header: <Translate id='specificationsSection.guidelines.revision' />,
			dataSource: 'revision',
		},
		{
			header: <Translate id='specificationsSection.guidelines.createdBy' />,
			dataSource: 'created_by',
		},
		{
			header: <Translate id='specificationsSection.guidelines.authorize' />,
			dataSource: 'authorized_by',
		},
		{
			header: <Translate id='specificationsSection.guidelines.notice' />,
			dataSource: 'no_warning',
		},
	]
	return { columns }
}

function ActivitiesTableConfiguration() {
	let columns = [
		{
			header: <Translate id='specificationsSection.guidelines.numHeader' />,
			dataSource: 'number',
		},
		{
			header: <Translate id='specificationsSection.guidelines.description' />,
			dataSource: 'description',
		},
		{
			header: <Translate id='specificationsSection.guidelines.visualAid' />,
			dataSource: 'visual_aid',
		},
	]
	return { columns }
}

function AuthorizationsTableConfiguration() {
	let columns = [
		{
			header: <Translate id='specificationsSection.guidelines.authorizer' />,
			dataSource: 'authorized_name',
		},
		{
			header: <Translate id='specificationsSection.guidelines.statusTitle' />,
			dataSource: 'status',
		},
		{
			header: <Translate id='specificationsSection.guidelines.authorizationDate' />,
			dataSource: 'authorization_date',
		},
		{
			header: <Translate id='specificationsSection.guidelines.comments' />,
			dataSource: 'comment',
		},
	]
	return { columns }
}

GuidelineDetailContent.propTypes = {
	controlChanges: propTypes.array,
	isLoading: propTypes.bool,
	activities: propTypes.array,
	authrizations: propTypes.array,
}

GuidelineDetailContent.defaultProps = {
	controlChanges: [],
	isLoading: false,
	activities: [],
	authrizations: [],
}

const useStyles = makeStyles(() => ({
	btnStatus: {
		fontSize: '106%',
		whiteSpace: 'normal',
		color: 'white',
		padding: '5px',
	},
	htmlContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	media: {
		width: '300px',
		padding: '5px',
	},
}))

export default GuidelineDetailContent
