
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';

import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';

/**
 * Dialog to AddReferenceDialog
 */
class AddReferenceDialog extends Component {

    /**
   *Creates an instance of DialogActions.
   * @param {*} props
   * @memberof DialogActions
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            refresh: false
        }
    }

    onAddReference() {
        this.props.onAddReference()
            .then(response => {
                if (response) {
                    this.setState({ refresh: response },
                        () => this.setState({ refresh: false }))
                }
            })
    }

    renderTextField(key, translationId, xs = 12) {
        return (
            <Grid item xs={xs}>
                <TextField
                    autoFocus={key === "reference"}
                    fullWidth
                    margin="dense"
                    label={<Translate id={translationId} />}
                    defaultValue={this.props.reference[key]}
                    autoComplete="off"
                    onChange={(event) => { this.props.updatePropertyReference(key, event.target.value) }}
                />
            </Grid>
        )
    }

    /**
     * Dialog to update information
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            {<Translate id="samplesrequest.addReferenceRMI" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <Grid container spacing={0} className={classes.cardActions}>
                        {!this.props.itemToken &&
                            <Grid item xs={12} className={this.props.classes.checkBox}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.props.reference.keepAdding && this.props.reference.keepAdding}
                                            onChange={(event) => this.props.updatePropertyReference("keepAdding", event.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant="caption">
                                        <Translate id="samplesrequest.keepAddingReferences" />
                                    </Typography>}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <DialogActions
                                view={SHOW_EDIT_VIEW}
                                classes={classes}
                                onCancel={this.props.onClose}
                                onClose={this.props.onClose}
                                onSaveItem={this.props.itemToken ? this.props.onEditReference : this.onAddReference}
                                isSaving={this.props.isSaving}
                                canBeSaved={this.props.canBeSaved}
                            />
                        </Grid>
                    </Grid>
                }>
                {!this.state.refresh &&
                    <Grid container spacing={16} className={classes.formBody}>
                        {this.renderTextField("reference", "samplesrequest.reference")}
                        {this.renderTextField("specification", "samplesrequest.specification")}
                        {this.renderTextField("tolerance", "samplesrequest.tolerance", 6)}
                        {this.renderTextField("unitMeasure", "samplesrequest.medicionUnit", 6)}
                        {this.renderTextField("piece1", "samplesrequest.piece1", 6)}
                        {this.renderTextField("piece2", "samplesrequest.piece2", 6)}
                        {this.renderTextField("piece3", "samplesrequest.piece3", 6)}
                        {this.renderTextField("piece4", "samplesrequest.piece4", 6)}
                        {this.renderTextField("piece5", "samplesrequest.piece5", 6)}
                    </Grid>
                }
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    formBody: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        maxWidth: 450,
    },
    cardActions: {
        maxWidth: 475,
    },
    checkBox: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        marginTop: '-17px',
        maxHeight: '33px',
        paddingTop: '-20px',
    }
});

export default withStyles(dialogStyles, { withTheme: true })(AddReferenceDialog);