import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	IconButton,
	Typography,
	Divider,
	Grid,
	TableFooter,
	TablePagination,
	Button,
	Badge,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import FilterListIcon from '@material-ui/icons/FilterList'
import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import SearchInput from '../../common/SearchInput/SearchInput'
import LoadAlertCard from './LoadAlertCard'
import ColumnsConfigurator from '../../common/ConfiguratorColumns/ColumnsConfigurator'
import { useConfigurator } from '../../common/DataGrid/UseConfigurator'
import LoadAlertTrackingToolbar from './LoadAlertTrackingToolbar'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import LoadingPage from '../../common/LoadingPage'

const trackingConfiguratorTableConfig = [
	{
		title: 'Estatus Intenacional',
		dataSource: 'Status',
		isMovable: true,
	},
	{
		title: 'Estatus Alerta',
		dataSource: 'StatusAlerta',
		isMovable: true,
	},
	{
		title: <Translate id='common.linked' />,
		dataSource: 'Linked',
		isMovable: true,
	},
	{
		title: <Translate id='common.referenceNumber' />,
		dataSource: 'ReferenceNumber',
		isMovable: true,
	},
	{
		title: <Translate id='common.supplier' />,
		dataSource: 'Supplier',
		isMovable: true,
	},
	{
		title: <Translate id='common.place' />,
		dataSource: 'Place',
		isMovable: true,
	},
	{
		title: 'I. ETD',
		dataSource: 'IETD',
		isMovable: true,
	},
	{
		title: 'I. ETA',
		dataSource: 'IETA',
		isMovable: true,
	},
	{
		title: <Translate id='common.nationalETD' />,
		dataSource: 'NationalETD',
		isMovable: true,
	},
	{
		title: <Translate id='common.nationalETA' />,
		dataSource: 'NationalETA',
		isMovable: true,
	},
	{
		title: <Translate id='common.plant' />,
		dataSource: 'CollectionDate',
		isMovable: true,
	},
	{
		title: 'Opciones',
		dataSource: 'Opciones',
		isMovable: true,
	},
	{
		title: 'Mapa',
		dataSource: 'liveMapUrl',
		isMovable: true,
	},
	{
		title: 'C. espejo',
		dataSource: 'liveMapUrlNational',
		isMovable: true,
	},
]

const IndexDesktopView = (props) => {
	// STRING PROPS SECTION
	const { query, page, rowsPerPage, trackingCount } = props
	// objects
	const { classes } = props
	// functions
	const { onChangeSearchValue, cleanFilter, onChangePage, handleFilterOpen } = props
	//bool
	const { isLoading } = props
	// array props section
	const { trackings } = props
	//
	const { countAppliedFilters } = props
	const [filteredTrackings, setFilteredTrackings] = useState([])
	const tableNameId = 'trackings-tv-datagrid-config5'
	const [trackingTableConfigurationDefault] = useState(trackingConfiguratorTableConfig)
	const [trackingsTableConfiguration, setTrackingsTableConfiguration] = useConfigurator(
		tableNameId,
		trackingTableConfigurationDefault
	)

	useEffect(() => {
		setFilteredTrackings(trackings)
	}, [trackings])

	const onResetConfigurationTable = () => {
		localStorage.removeItem(tableNameId)
		window.location.reload()
	}

	const onConfigurationChange = (configuration) => {
		setTrackingsTableConfiguration(configuration)
	}

	// if (isLoading) return <LoadingPage />
	return (
		<div className={classes.parentContainer}>
			<LoadAlertTrackingToolbar />
			<div className={classes.content}>
				<Grid container>
					<Grid item xs={4}>
						<Typography variant='h6' color='primary'>
							Seguimientos de importación
						</Typography>
					</Grid>
					<Grid item xs={2}>
						<Button
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='contained'
							onClick={cleanFilter}
							fullWidth
						>
							Limpiar filtros
						</Button>
					</Grid>
					<Grid item xs={2}>
						<ColumnsConfigurator
							title='Configuración de tabla'
							onConfigurationChange={onConfigurationChange}
							onResetConfigurationTable={onResetConfigurationTable}
							headerTableConfiguration={trackingsTableConfiguration}
						/>
					</Grid>

					<Grid item xs={1}>
						<IconButton color='primary' className={classes.filterButton} onClick={handleFilterOpen}>
							<Typography variant='subtitle2' color='primary' className={classes.textFilter}>
								<Translate id='common.filter' />
							</Typography>
							<Badge badgeContent={countAppliedFilters} color='primary'>
								<FilterListIcon />
							</Badge>
						</IconButton>
					</Grid>
					<Grid item xs={3}>
						<SearchInput value={query} onChangeSearchValue={onChangeSearchValue} />
					</Grid>
				</Grid>
				<Divider />
				<div className={classes.tableContainer}>
					<>
						{isLoading ? (
							<LoadingPage />
						) : (
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										{trackingsTableConfiguration.map((column, index) =>
											column.visible ? (
												<TableCell padding='none' key={index} className={classes.tableHeader}>
													{column.title}
												</TableCell>
											) : null
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredTrackings && filteredTrackings.length !== 0 ? (
										<>
											{filteredTrackings.map((tracking, index) => (
												<LoadAlertCard
													key={tracking.id}
													index={index}
													item={tracking}
													trackingsTableConfiguration={trackingsTableConfiguration}
												/>
											))}
										</>
									) : (
										<div className={classes.noDataContent}>
											<Grid container className={classes.grid}>
												<Grid item xs={12}>
													<Typography align='center'>
														<ImportContactsIcon color='primary' />
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography align='center'>No existen alertas de carga</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography align='center'>
														Verifica que exista una alerta de carga en proceso o utiliza los
														filtros para buscar una alerta de carga
													</Typography>
												</Grid>
											</Grid>
										</div>
									)}
								</TableBody>
							</Table>
						)}
					</>
				</div>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[10, 20, 50]}
							colSpan={filteredTrackings.length}
							count={trackingCount}
							rowsPerPage={rowsPerPage}
							page={page}
							labelRowsPerPage={<Translate id='pagination.rowsPerPage' />}
							// labelDisplayedRows={({ from, to, count }) =>
							// 	this.renderFromToPage(from, to, count)
							// }
							SelectProps={{
								inputProps: { 'aria-label': 'Rows por page' },
								native: true,
							}}
							onChangePage={(event, newPage) => onChangePage(newPage, rowsPerPage)}
							// onChangeRowsPerPage={this.handleChangeRowsPerPage}
							// ActionsComponent={DataTablePaginationActions}
							// style={{
							// 	backgroundColor: '#fff',
							// 	position: 'sticky',
							// 	bottom: -0.5,
							// 	zIndex: '30',
							// }}
						/>
					</TableRow>
				</TableFooter>
			</div>
		</div>
	)
}

const styles = (theme) => ({
	content: {
		padding: '15px',
		backgroundColor: '#ffffff',
	},
	filterButton: {
		display: 'flex',
		alignItems: 'center',
	},
	textFilter: {
		marginRight: '8px',
	},
	searchDiv: {
		marginRight: '28px',
	},
	chipContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: '10px',
	},
	parentContainer: {
		backgroundColor: '#f9f9f9',
		padding: '10px',
		paddingTop: '60px',
	},
	stickySearch: {
		position: 'sticky',
		top: '64px',
		backgroundColor: '#ffffff',
		zIndex: '1',
	},
	tableContainer: {
		width: '100%',
		// maxHeight: '500px', // Ajusta esto según sea necesario
		maxHeight: `calc(100vh - ${205}px)`,
		minHeight: `calc(100vh - ${205}px)`,
		overflowY: 'auto',
	},
	table: {
		width: '100%',
		tableLayout: 'fixed',
	},
	tableHeader: {
		backgroundColor: '#ffffff',
		color: '#757575',
		fontSize: '16px',
		position: 'sticky',
		top: 0,
		zIndex: 1,
	},
	noDataContent: {
		width: '100%',
	},
	grid: {
		position: 'absolute',
		top: '30%',
		bottom: '50%',
	},
})

export default withStyles(styles)(IndexDesktopView)
