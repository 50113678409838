/**Import react section */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import { Translate } from 'react-localize-redux'

/**Import material UI Section*/
import { withStyles, Typography, Button } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import {
	COLOR_STATUS_LIGHT
} from '../../../../store/helpers/StatusColorConstants';

/**Import components section*/
import {
	onGetHeaderResultAdicionalCostTable
} from '../../../../store/helpers/BiddingHelper';

const AddicionalCostTable = (props) => {
	const { bidding, onUploadAdditionalCostsConfigPanel, classes } = props;

	return (
		<div style={{ maxWidth: `calc(100vw - ${315}px)`, overflowX: 'auto' }}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						{onGetHeaderResultAdicionalCostTable(bidding).map((column) => {
							return (
								<TableCell key={uuidv4()} align="center">
									<strong>{column.title}</strong>
								</TableCell>
							);
						})}
					</TableRow>
					<TableCell key={uuidv4()} ><Typography><Translate id="biddingsModule.addictionalCosts" /></Typography></TableCell>
					{bidding &&
						bidding.Suppliers.map((supplier) => {
							return (
								<TableCell key={uuidv4()} align="center">
									<Button
										size="small"
										type="button"
										variant="outlined"
										className={classes.btnAddAdditionsCost}
										onClick={() => onUploadAdditionalCostsConfigPanel({supplierCode: supplier.Code, supplierName: supplier.Name})}
									>
										<Translate id="biddingsModule.otherCosts" />
									</Button>
								</TableCell>
							);
						})}
				</TableHead>
			</Table>
		</div>
	);
};

const styles = (theme) => ({
	btnAddAdditionsCost: {
		color: COLOR_STATUS_LIGHT, //"white",
		borderColor: COLOR_STATUS_LIGHT,
		marginRight: '20px',
		minWidth: '130px'
	}
});

AddicionalCostTable.propTypes = {
	/**
	 * Determinate if is loading information from back end
	 * and see view skeleton
	 */
	isLoading: PropTypes.bool.isRequired
};

AddicionalCostTable.defaultProps = {
	isLoading: false
};

export default withRouter(withStyles(styles, { withTheme: true })(AddicionalCostTable));
