import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

/** Import component */
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import QualityUsersIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/BorderColor'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

import {
	loadUsersQualityFromBackEnd,
	CHANGE_WORKERS_SORT_CRITERIA,
	CHANGE_WORKERS_SORT_QUERY,
	getUsersQualityCount,
	addUserData,
	updateUserQualityData,
	CHANGE_WORKERS_PAGINATION,
} from '../../store/administration/WarehouseWorkersActions'
import DialogAddUser from './components/DialogAddUser'
import Toaster from '../common/Toaster'
import DataTable from '../common/DataTable'
import DialogEditUser from './components/DialogEditUser'

/**
 * component UsersQuality
 */
class UsersQuality extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showAddUserDialog: false,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			openModalEditUser: false,
			editUser: null,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadUsersQuality(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getUsersQualityCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadUsersQuality()
			this.props.getUsersQualityCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadUsersQuality(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * Handle state of any Dialog to show or close
	 *
	 * @param {*} dialogId
	 * @memberof UsersQuality
	 */
	handleOpenCloseAddUserDialog(dialogId) {
		this.setState({ [dialogId]: !this.state[dialogId] })
	}
	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof UsersQuality
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 * @param {*} searchQuery
	 * @memberof UsersQuality
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Open modal for edit user
	 */
	opentoEditUser(user) {
		this.setState({
			openModalEditUser: true,
			editUser: user,
		})
	}

	/**
	 * On render cell item user
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'edit') {
			return (
				<Tooltip key={item.id} color='secondary' title={<Translate id='common.edit' />}>
					<EditIcon
						color='primary'
						style={{ zIndex: '990', cursor: 'pointer' }}
						onClick={() => {
							this.opentoEditUser(item)
						}}
					/>
				</Tooltip>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.usersQuality === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.usersQuality}
					configuration={TableConfiguration}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					showFilters
					isIndex
					totalRows={this.props.usersQualityCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get raw users quality counter
	 *
	 * @returns
	 * @memberof Index
	 */
	geCounterMessage() {
		if (this.props.usersQuality) {
			let totalRawMaterials = this.props.usersQuality.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalRawMaterials}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.usersQualityCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Handle On save, call action and show toaster if done or not
	 *
	 * @param {*} name
	 * @returns
	 * @memberof UsersQuality
	 */
	handleOnSaveAddUser(name) {
		return this.props
			.addUserData(name)
			.then(() => {
				this.setState({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
				this.handleOpenCloseAddUserDialog('showAddUserDialog')
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
				return Promise.reject()
			})
	}

	/**
	 * call to action to update data non conformity
	 *
	 * @memberof FormView
	 */
	updateUserQuality(editUser) {
		if (editUser !== null) {
			this.props
				.updateUserQuality(editUser)
				.then(() => {
					this.setState({
						openModalEditUser: false,
						editUser: null,
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: <Translate id='common.saveCorrectly' />,
					})
				})
				.catch(() => {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				})
		}
	}

	/**
	 * Render list role
	 *
	 * @returns
	 * @memberof Show
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<QualityUsersIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>

							<Tooltip title={<Translate id='qualityCatalogs.addUser' />}>
								<Fab
									size='small'
									color='primary'
									onClick={() => this.handleOpenCloseAddUserDialog('showAddUserDialog')}
								>
									<AddtIcon />
								</Fab>
							</Tooltip>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.usersquality' />{' '}
						</Typography>
					}
					subheader={this.geCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to add user*/}
				<DialogAddUser
					classes={this.props.classes}
					isSaving={this.props.isSavingWorker}
					onSave={this.handleOnSaveAddUser}
					onCloseCancel={this.handleOpenCloseAddUserDialog}
					showDialog={this.state.showAddUserDialog}
				/>

				{/** Dialog to edit user*/}
				{this.state.editUser && (
					<DialogEditUser
						classes={this.props.classes}
						showDialog={this.state.openModalEditUser}
						user={this.state.editUser}
						onSave={this.updateUserQuality}
						onCloseCancel={() => {
							this.setState({ openModalEditUser: false, editUser: null })
						}}
						isSaving={this.props.isSavingWorker}
					/>
				)}

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='qualityCatalogs.userquality' />,
			dataSource: 'name',
			isSortable: true,
		},
		{
			header: '',
			dataSource: 'edit',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoading: state.warehouseWorkers.get('isLoadingWarehouseWorker'),
		usersQuality: state.warehouseWorkers.get('warehouseWorkers')
			? state.warehouseWorkers.get('warehouseWorkers').toJS()
			: null,
		isSavingWorker: state.warehouseWorkers.get('isSavingWorker'),
		sortCriteria: state.warehouseWorkers.get('sortCriteria')
			? state.warehouseWorkers.get('sortCriteria').toJS()
			: null,
		searchQuery: state.warehouseWorkers.get('searchQuery'),
		page: state.warehouseWorkers.get('page'),
		rowsPerPage: state.warehouseWorkers.get('rowsPerPage'),
		usersQualityCount: state.warehouseWorkers.get('usersQualityCount'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadUsersQuality: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadUsersQualityFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		getUsersQualityCount: () => {
			dispatch(getUsersQualityCount())
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_WORKERS_SORT_QUERY,
				query: query,
			})
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_WORKERS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_WORKERS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		addUserData: (name) => {
			return dispatch(addUserData(name))
		},
		updateUserQuality: (data) => {
			return dispatch(updateUserQualityData(data))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(UsersQuality)))
)
