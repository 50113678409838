/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { List } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { ListItem } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';

/**Import components section*/


/**Import resources section*/

/**Import actions section */
const DialogOperation = (props) => {
  const { open, onClose, options } = props;

    /**
     * Get the list of available options
    */
   const getOperationsList = () => {
        if (options) {
            return (<Card>
                {options.map((option) => {
                    const handleClick = () => {
                        onClose()
                        option.onClick()
                    }
                    return (
                        <ListItem key={option.value} divider button onClick={() => handleClick()}>
                            <ListItemText 
                                primary={
                                    <Typography component="span" color="primary" variant="subtitle2" gutterBottom>
                                        {option.title}
                                    </Typography>
                                }
                                secondary={
                                    <Typography component="span" display="block" color="textSecondary" variant="caption" gutterBottom>
                                        {<i className="fa fa-info"> </i>}{" "}
                                        {option.description}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    )
                })}
            </Card>
            )
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title" >
        {/* Header title of the dialog */}
        <DialogTitle id="form-dialog-title">
            <DialogContentText>
                <Typography component="span" variant="h6"><Translate id="common.AvailableOperations" /></Typography>
            </DialogContentText>
        </DialogTitle>
        <Divider />
        {/* Body of the dialog */}
        <DialogContent>
        <List>
            {getOperationsList()}
        </List>
        </DialogContent>
        {/* Button actions on the footer of the dialog */}
        <DialogActions>
            <Button onClick={onClose} color="secondary">
                <Translate id="common.close" />
            </Button>
        </DialogActions>
        </Dialog>
    )
};

const styles = (theme) => ({
});

DialogOperation.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.array,
};

DialogOperation.defaultProps = {
    open: false,
    onClose: () =>
        console.warn('Callback [onClose] no defined'),
    option: [],
};

export default withRouter(
  withStyles(styles, { withTheme: true })(DialogOperation)
);
