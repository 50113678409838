

import React, { Component } from 'react';
import ReactPlayer from 'react-player'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, ListItemAvatar, Avatar, ListItemSecondaryAction, CardHeader } from '@material-ui/core';
import NoticesIcon from '@material-ui/icons/Chat';
import Fab from '@material-ui/core/Fab';
import AcceptIcon from '@material-ui/icons/ThumbUp';
import CloundDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import Toolbar from '@material-ui/core/Toolbar';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LoadingPage from '../common/LoadingPage';
import Toaster from '../common/Toaster';

/** Import action section */
import { loadOfficialNoticeForAccepted, addOfficeNoticeAcceptedBySupplier } from '../../store/administration/OfficialNoticeActions';
import { loadAdministratorSupplier } from '../../store/suppliers/ExternalUserActions';
import { loadSupplier } from '../../store/suppliers/SupplierActions';
import InfoIcon from '@material-ui/icons/Info';
import { COLOR_STATUS_LIGHT, COLOR_STATUS_ALERT_INFO } from './../../store/helpers/StatusColorConstants';
import { IsUserSupplierAdministrator } from '../../store/helpers/SessionHelper';
import { OfficialNoticeType } from '../../store/helpers/AppConstants';

/**
 * Container show Official notice
 */
class AcceptedNotices extends Component {

    /**
     * Create an instance Official notice
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToView: true,
            editCompany: null,
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            noticeShow: null,
            videoDuration:0,
            videoProgress:0,
        }
    }

    /**
* componentDidUpdate
*
* @param {*} prevProps
* @param {*} prevState
* @memberof Index
*/
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.acceptedNotices !== null) {
            /// Get information full from adminsitrator supplier
            let adminUserName = `${this.props.currentUser.profile.company_code}-admin`
            this.props.loadAdministratorSupplier(adminUserName);
            this.props.loadSupplier(this.props.currentUser.profile.company_token);
        }
    }

    /**
    * Function on add ifficial notice accepted by supplier
    */
    onAddOfficeNoticeAcceptedBySupplier(noticeToken, documentName) {
        this.props.addOfficeNoticeAcceptedBySupplier(noticeToken, documentName, this.state.videoDuration, this.state.videoProgress)
            .then(() => {
                this.setState({
                    openRemoveItem: false,
                    itemId: "",
                    noticeShow: null,
                })
                if (this.props.acceptedNotices.length === 0) {
                    this.props.history.push(`/`)
                }
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
     * On click on view notice
     * @param {*} notice 
     */
    onClickViewNotice(notice) {
        this.setState({ noticeShow: notice })
    }

    /**
    * Function to render documents
    * @param {*} documents 
    */
    renderDocuments() {
        const { classes, acceptedNotices } = this.props;
        if (this.props.isSavingToAccepted === true) {
            return (
                <LoadingPage />
            )
        }
        else {
            return (
                <React.Fragment>
                    <List component="div" disablePadding>
                        {acceptedNotices.map(notice => {
                            return (
                                <React.Fragment key={`${notice.token}${notice.document_name}`} >
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <React.Fragment>
                                                    <Typography color="textSecondary">
                                                        {<strong>{<Translate id="officialNotices.notes" />}{": "}</strong>}{notice.notes}
                                                    </Typography><br />
                                                </React.Fragment>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    <Link component={'a'} target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${notice.document_base_url}`}>
                                                        <CloundDownloadIcon /> {"  "}{notice.document_name}
                                                    </Link>
                                                </React.Fragment>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <Fab
                                                variant="extended"
                                                size="small"
                                                color="secondary"
                                                aria-label="accepted"
                                                className={classes.margin}
                                                onClick={() => { this.onClickViewNotice(notice) }}
                                            >
                                                <NoticesIcon className={classes.extendedIcon} />
                                                <Translate id="common.viewNotice" />
                                            </Fab>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                    </List>
                </React.Fragment>
            )
        }
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.acceptedNotices !== null) {
            const { classes, administratorSupplier } = this.props;
            let noticeToShow = this.state.noticeShow;
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Paper>
                            <Card
                                className={classes.cardInfo}
                                role="alert">
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="Important" className={classes.avatar}>
                                            <InfoIcon />
                                        </Avatar>
                                    }
                                    title={<Translate id="officialNotices.acceptedTitle" />}
                                    subheader={
                                        administratorSupplier &&
                                        <React.Fragment>
                                            <Grid item >
                                                <strong><Translate id="officialNotices.administrator" />{": "}</strong>
                                                {administratorSupplier.name}
                                            </Grid>
                                            <Grid item >
                                                <strong><Translate id="officialNotices.email" />{": "}</strong>
                                                {administratorSupplier.email}
                                            </Grid>
                                        </React.Fragment>
                                    }
                                />
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        <Translate id="officialNotices.messageToAccepted" />
                                    </Typography>
                                </CardContent>
                            </Card>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar >
                                            <NoticesIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography variant="h5" className={classes.inline} color="textPrimary">
                                                    <Translate id="officialNotices.noticeAccepted" />
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                {this.renderDocuments()}
                            </List>

                            <Toaster
                                message={this.state.toasterMessage}
                                open={this.state.showToaster}
                                variant={this.state.toasterVariant}
                                onClose={() => { this.setState({ showToaster: false }) }}
                            />
                        </Paper>

                        {noticeToShow && <Grid item xs={12}>
                            <div className="embed-responsive embed-responsive-16by9" style={{ overflow: "hidden", height: '100%', width: '100%' }}>
                                {
                                    noticeToShow.official_notice_type !==OfficialNoticeType.VIDEO && <iframe title="official_notice" className="embed-responsive-item"
                                    src={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${noticeToShow.document_base_url}`}></iframe>
                                }
                                    {noticeToShow.official_notice_type ===OfficialNoticeType.VIDEO && <ReactPlayer progressInterval={3000} onDuration={(duration)=>this.setState({videoDuration: duration})} playing={true} controls={true} url={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${noticeToShow.document_base_url}`}  onProgress={(data)=>this.setState({videoProgress: data.playedSeconds})}/>}
                            </div>

                            {(IsUserSupplierAdministrator(this.props.currentUser) || 
                                (administratorSupplier && administratorSupplier.id === this.props.currentUser.profile.sub)) &&
                                <Toolbar>
                                    <div className={classes.grow}></div>
                                    <Fab
                                        variant="extended"
                                        size="small"
                                        color="primary"
                                        aria-label="accepted"
                                        disabled={this.props.isSavingToAccepted}
                                        onClick={() => { this.onAddOfficeNoticeAcceptedBySupplier(noticeToShow.token, noticeToShow.document_name) }}
                                    >
                                        <AcceptIcon className={classes.extendedIcon} />
                                        <Translate id="common.accept" />
                                    </Fab>
                                </Toolbar>}

                        </Grid>}

                    </Grid>
                </Grid>
            )
        }
    }

    render() {
        return (
            <Card>
                <CardContent>
                    {this.getCardContent()}
                </CardContent>
            </Card>
        )
    }

}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let acceptedNotices = state.officialNotices.get("acceptedNotices");

    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    return {
        currentUser: user,
        isLoading: state.officialNotices.get('isLoadingNotices'),
        acceptedNotices: acceptedNotices ? acceptedNotices.toJS() : null,
        isSavingToAccepted: state.officialNotices.get('isSavingToAccepted'),
        translate: getTranslate(state.localize),
        administratorSupplier: state.externalUsers.get('administratorSupplier') ? state.externalUsers.get('administratorSupplier').toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadOfficialNoticeForAccepted: () => {
            return dispatch(loadOfficialNoticeForAccepted());
        },
        addOfficeNoticeAcceptedBySupplier: (noticeToken, documentName, videoDuration, videoProgress) => {
            return dispatch(addOfficeNoticeAcceptedBySupplier(noticeToken, documentName, videoDuration, videoProgress))
        },
        loadAdministratorSupplier: (userId) => {
            return dispatch(loadAdministratorSupplier(userId))
        },
        loadSupplier: (token) => {
            return dispatch(loadSupplier(token))
        }
    }
}

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    cardInfo: {
        backgroundColor: COLOR_STATUS_ALERT_INFO
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 80
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    avatar: {
        backgroundColor: COLOR_STATUS_LIGHT
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(AcceptedNotices))));