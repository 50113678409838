import { fromJS } from 'immutable'
import * as requestPriceChange from './requestPriceChangeAction'

export const priceChangeInitialState = fromJS({
	isLoadingPrice: false,
	sortCriteria: {
		by: 'Folio',
		ascending: true,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	requestPriceChangeDraft: null,
	priceChangeRequests: null,
	priceChangeRequestsCount: null,
})
export default function priceChangeReducer(state = priceChangeInitialState, action) {
	switch (action.type) {
		case requestPriceChange.IS_LOADING_PRICE: {
			return state.merge({
				isLoadingPrice: action.isLoading,
			})
		}
		case requestPriceChange.GET_PRICE_CHANGE_IN_DRAFT: {
			return state.merge({
				isLoadingPrice: false,
				requestPriceChangeDraft: fromJS(action.requestPriceChangeDraft),
			})
		}
		case requestPriceChange.ADD_FILE_PRICE_CHANGE: {
			let detailsList = state.getIn(['requestPriceChangeDraft', 'details']).toJS()
			let indexDetail = detailsList.findIndex((detail) => {
				return detail.token === action.tokenPartNumber
			})
			let oldList = state.getIn(['requestPriceChangeDraft', 'details', indexDetail, 'attachments']).toJS()
			let filesToAdd = action.files.filter((newFile) => {
				return !oldList.find((uploadedFile) => {
					return uploadedFile.name === newFile.name
				})
			})
			let newList = [...oldList, ...filesToAdd]
			return state.setIn(['requestPriceChangeDraft', 'details', indexDetail, 'attachments'], fromJS(newList))
		}
		case requestPriceChange.REMOVE_FILE_PRICE_CHANGE: {
			let detailsList = state.getIn(['requestPriceChangeDraft', 'details']).toJS()
			let indexDetail = detailsList.findIndex((detail) => {
				return detail.token === action.tokenPartNumber
			})
			let oldList = state.getIn(['requestPriceChangeDraft', 'details', indexDetail, 'attachments']).toJS()
			let newList = oldList.filter((file) => {
				return file.name !== action.fileName
			})
			return state.setIn(['requestPriceChangeDraft', 'details', indexDetail, 'attachments'], fromJS(newList))
		}
		case requestPriceChange.SET_REQUESTS_LIST: {
			let requestsLists = fromJS(action.requests)
			return state.merge({
				priceChangeRequests: requestsLists,
			})
		}
		case requestPriceChange.SET_REQUESTS_COUNT: {
			return state.merge({
				priceChangeRequestsCount: action.count,
			})
		}
		case requestPriceChange.CHANGE_REQUESTS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case requestPriceChange.CHANGE_REQUEST_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case requestPriceChange.CHANGE_REQUEST_QUERY: {
			return state.merge({
				searchQuery: action.query,
			})
		}
		case requestPriceChange.UPDATE_DETAIL_PLANE_REQUEST: {
			let items = state
				.get('requestPriceChangeDraft')
				.get('details')
				.map((item) => {
					if (item.get('token') === action.detailPlane.token) {
						return item.set('plot_name', action.detailPlane.plot_name)
					} else {
						return item
					}
				})
			let requestPriceChangeDraft = state.get('requestPriceChangeDraft').set('details', items)
			return state.merge({
				requestPriceChangeDraft,
			})
		}
		case requestPriceChange.UPDATE_COMMENTS_REQUEST_PRICE_CHANGE: {
			let items = state
				.get('requestPriceChangeDraft')
				.get('details')
				.map((item) => {
					if (item.get('token') === action.partNumberDetail.token) {
						return item
							.set('comments', action.partNumberDetail.comments)
							.set('requiered_plot_art', action.partNumberDetail.requiered_plot_art)
					} else {
						return item
					}
				})
			let requestPriceChangeDraft = state.get('requestPriceChangeDraft').set('details', items)
			return state.merge({
				requestPriceChangeDraft,
			})
		}
		default:
			return state
	}
}
