/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'

/** SET_MEDICAL_CHECKS_LIST index constants */
export const IS_LOADING_MEDICAL_CHECKS = 'IS_LOADING_MEDICAL_CHECKS'
export const SET_MEDICAL_CHECKS_LIST = 'SET_MEDICAL_CHECKS_LIST'
export const CHANGE_MEDICAL_CHECKS_SORT_CRITERIA = 'CHANGE_MEDICAL_CHECKS_SORT_CRITERIA'
export const CHANGE_MEDICAL_CHECKS_QUERY = 'CHANGE_MEDICAL_CHECKS_QUERY'
export const CHANGE_MEDICAL_CHECKS_PAGINATION = 'CHANGE_MEDICAL_CHECKS_PAGINATION'
export const SET_MEDICAL_CHECKS_COUNT = 'SET_MEDICAL_CHECKS_COUNT'
export const IS_SAVING_MEDICAL_CHECK = 'IS_SAVING_MEDICAL_CHECK'
export const IS_LOADING_MEDICAL_CHECK = 'IS_LOADING_MEDICAL_CHECK'
export const SET_MEDICAL_CHECK = 'SET_MEDICAL_CHECK'

/**
 * is loading
 * @param {} isLoadingCheck
 */
function isLoadingCheck(isLoadingChecks) {
	return {
		type: IS_LOADING_MEDICAL_CHECKS,
		isLoadingChecks,
	}
}

/**
 * Set medicalchecks list in store
 *
 * @param {*} medicalchecks
 * @returns
 */
function setMedicalChecksList(medicalchecks) {
	return {
		type: SET_MEDICAL_CHECKS_LIST,
		medicalchecks,
	}
}

/**
 * Set medicalchecks list count in store
 *
 * @param {*} count
 * @returns
 */
function setMedicalChecksCount(count) {
	return {
		type: SET_MEDICAL_CHECKS_COUNT,
		count,
	}
}

/**
 * Is saving medicak check
 *
 * @param {*} isSavingMedical
 * @returns
 */
function isSavingMedicalCheck(isSavingMedical) {
	return {
		type: IS_SAVING_MEDICAL_CHECK,
		isSavingMedical,
	}
}

/**
 * Set flag to determine if is loading specific isSavingMedical data
 *
 * @param {*} isSavingMedical
 * @returns
 */
function isisLoadingMedical(isLoadingMedical) {
	return {
		type: IS_LOADING_MEDICAL_CHECK,
		isLoadingMedical,
	}
}

/**
 * Set medicalCheck data in store
 *
 * @param {*} medicalCheck
 * @returns
 */
function setMedicalCheck(medicalCheck) {
	return {
		type: SET_MEDICAL_CHECK,
		medicalCheck,
	}
}

/**
 * Get medical checks list from backend
 *
 * @export
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @returns
 */
export function loadMedicalChecksFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingCheck(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/medicalcheck/all`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SuppliertToken: '',
			SupplierCode: supplierCode,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMedicalChecksList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingCheck(false))
				console.error('Error getting the Medical Checks by company list', error.response)
			})
	}
}

/**
 * Get medical checks  list count
 *
 * @export
 * @returns
 */
export function getMedicalChecksCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().contractorMedicalcheck.get('medicalchecksCount')
		if (actualCounter) {
			return
		}
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/medicalcheck/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMedicalChecksCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the medicalcheck's count", error.response)
			})
	}
}

/**
 * Add new medicalCheck to list
 *
 * @export
 * @param {*} medicalCheck
 * @returns
 */
export function addMedicalCheck(medicalCheck) {
	return (dispatch, getState) => {
		dispatch(isSavingMedicalCheck(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (user.profile && user.profile.company_code) {
			supplierCode = user.profile.company_code
		}

		let formData = new FormData()
		medicalCheck.medicalCheckFiles.forEach((file) => {
			formData.append('MedicalCheckFiles', file)
		})
		medicalCheck.dopingFiles.forEach((file) => {
			formData.append('DopingFiles', file)
		})
		formData.set('CompanyToken', selectedCompany.get('token'))
		formData.set('SupplierCode', supplierCode)
		formData.set('StartDateMedicalCheck', medicalCheck.startDateMedicalCheck.format('DD/MM/YYYY'))
		formData.set('EndDateMedicalCheck', medicalCheck.endDateMedicalCheck.format('DD/MM/YYYY'))
		// formData.set('StartDateDoping', medicalCheck.startDateDoping.format("DD/MM/YYYY"));
		// formData.set('EndDateDoping', medicalCheck.endDateDoping.format("DD/MM/YYYY"));
		formData.set('EmployeedToken', medicalCheck.selectedEmployee ? medicalCheck.selectedEmployee.value : '')

		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/medicalcheck`
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingMedicalCheck(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingMedicalCheck(false))
				console.error('Error adding a medical check', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Get an MedicalCheck data from backend
 *
 * @export
 * @param {*} token
 * @returns
 */
export function loadMedicalCheckFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isisLoadingMedical(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/medicalcheck/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMedicalCheck(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to load MedicalCheck', error.response)
				dispatch(isisLoadingMedical(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to validate medical check in plant
 * @param {*} medicalCheckToken
 * @param {*} status
 * @param {*} comments
 */
export function validateMedicalCheckInPlant(medicalCheckToken, status, comments) {
	return (dispatch, getState) => {
		dispatch(isSavingMedicalCheck(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			MedicalCheckToken: medicalCheckToken,
			Status: status,
			ValidateBy: user.profile.name,
			Comments: comments,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/medicalcheck/validate`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMedicalCheck(serverResponse.data))
					dispatch(isSavingMedicalCheck(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMedicalCheck(false))
				console.error('Error in validate medicak check in plant ', error.response)
				return Promise.reject()
			})
	}
}
