/**import react section */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

function TimePickers(props) {
  const { classes, onChange, name, value, label, disabled } = props;

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="time"
        label={label}
        type="time"
        fullWidth
        name={name}
        disabled={disabled}
        defaultValue="18:00"
        onChange={onChange}
        value={value}
        // className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
    </form>
  );
}

TimePickers.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

TimePickers.defaultProps = {
  disabled:false,
  name:"",
  label:"",
  onChange: () =>
      console.warn('Callback [onChange] no defined'),
}

export default withStyles(styles)(TimePickers);