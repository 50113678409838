
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import { Translate } from "react-localize-redux";

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';

/**
 * Dialog to add destination dialog item
 */
class AddDestinationDialog extends Component {

    /**
   *Creates an instance of AddDestinationDialog.
   * @param {*} props
   * @memberof AddDestinationDialog
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            destination: {
                kilometres: "",
                destination: '',
            }
        }
    }

      /**
     * React lyfe-cycle method
     * If item prop was updated, state data should be update 
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof EditMaterialQuantityModal
     */
    componentDidUpdate(prevProps, prevState) {
        if ((this.props.itemEdit !== prevProps.itemEdit)
            || (this.props.open !== prevProps.open && this.props.open === true)) {
                this.setState({
                    destination:{
                        token: this.props.itemEdit ? this.props.itemEdit.token : "",
                        destination: this.props.itemEdit ? this.props.itemEdit.destination : "",
                        kilometres: this.props.itemEdit ? this.props.itemEdit.kilometres : "",
                    }
                });
        }
    }

     /**
     * Handle  Close or cancel event
     *
     * @memberof handleCloseCancel
     */
    handleCloseCancel() {
        this.props.onClose();
        this.setState({
            destination:{
                destination: '',
                kilometres: "",
            }
        });
    }

    /**
     * Function to add information from destination
     * @param {*} description 
     */
    updatetemProperty(property, value) {
        let destionationUpdate = { ...this.state.destination };
        destionationUpdate[property] = value;
        this.setState({
            destination: destionationUpdate
        })
    }


    /**
     * onSave
     */
    onSaveItem() {
        this.props.onSaveItem(this.state.destination)
            .then((response) => {
                this.setState({
                    destination:{
                        destination: '',
                        kilometres: "",
                    }
                })
            })
    }

    /**
     * canBeSaved
     */
    canBeSaved(){
        if(this.state.destination.destination === "")
            return false;
        if(this.state.destination.kilometres === "" || this.state.destination.kilometres === 0)
            return false;
        return true;
    }

    /**
     * Dialog information
     */
    render() {
        const { classes } = this.props;
        let open = this.props.open ? this.props.open : false;
        return (
            <Dialog
            open = {open}
            onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            <Translate id="deliveryOrderModule.carbonFootprint.addDestination" />
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={() => this.handleCloseCancel()}
                        onClose={() => this.handleCloseCancel()}
                        onSaveItem={this.onSaveItem}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.canBeSaved()}
                    />
                }>

                <form>
                   <TextField fullWidth
                        label={<Translate id="deliveryOrderModule.carbonFootprint.destination" />}
                        className={classes.textField}
                        required
                        defaultValue={this.state.destination.destination}
                        onChange={(event) => { this.updatetemProperty("destination", event.target.value) }}
                        margin="normal"
                    />
                    <TextField fullWidth required
                        className={classes.textField}
                        id="standard-read-only-input"
                        label={<Translate id="deliveryOrderModule.carbonFootprint.kilometres" />}
                        defaultValue={this.state.destination.kilometres}
                        margin="normal"
                        type="number"
                        onChange={(event) => { this.updatetemProperty("kilometres", event.target.value) }}
                        helperText={<Translate id="common.requiredFields" />}
                    />
                </form>
            </Dialog>
        )
    }
}

AddDestinationDialog.defaultProps = {
    open: false,
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    },
    actionsToolbar: {},
    searchInput: {},
    iconOption:{}
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(AddDestinationDialog));