import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import autoBind from 'auto-bind';

/** Components imports section */
import DataTable from '../../common/DataTable';
import OptionsMenu from './../../common/OptionsMenu';

/** Actions and helpers imports section */
import { GetBooleanOptions, GetActionTypes } from '../../../store/helpers/SelectOptions';

/** Material UI imports section */
import { withStyles } from '@material-ui/core/styles';
import { Link, Tooltip, Grid } from '@material-ui/core';
import AttachIcon from '@material-ui/icons/AttachFile';
import AcceptIcon from '@material-ui/icons/ThumbUp';
import RejectIcon from '@material-ui/icons/ThumbDown';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AcceptRejectDialog from '../../common/AcceptRejectDialog';
import { CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS } from '../../../store/helpers/WorkFlowDocumentsType';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
import { APPROVAL_ACEPTED, APPROVAL_REJECTED } from '../../../store/helpers/ResourcesHelper';
import { COLOR_STATUS_ACEPTED, COLOR_STATUS_REJECTED, COLOR_STATUS_SUCCESS } from '../../../store/helpers/StatusColorConstants';
import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper';

/**
 * Class to render actions list table
 *
 * @class ActionsList
 * @extends {Component}
 */
class ActionsList extends Component {

    /**
     *Creates an instance of ActionsList.
     * @param {*} props
     * @memberof ActionsList
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            itemToken: '',
            actionsList: this.props.actionsList ? this.props.actionsList : [],
            openModalAuthorization: false,
            deletingTokenItem: '',
            editingTokenItem: ''
        }
    }

    /**
     * React life-cylec method
     * refresh state if actions list in props has change
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof ActionsList
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.actionsList !== this.props.actionsList) {
            this.setState({
                actionsList: this.props.actionsList
            })
        }
    }


    /**
     * Handle state to set token of item to show options menu list
     *
     * @param {*} itemToken
     * @memberof ActionsList
     */
    handleClickOptionsMenu(itemToken) {
        this.setState({
            itemToken,
        });
    };

    /**
     * Handle dialog status to show or hide 
     *
     * @param {*} dialogId
     * @param {*} isReject
     * @memberof ActionsList
     */
    handleOpenItemDialog(dialogId, documentType = '', isReject = false) {
        this.setState({
            [dialogId]: !this.state[dialogId],
            documentType,
            isReject
        }, () => {
            if (!this.state[dialogId])
                this.handleCloseOptionsMenu()
        });
    }


    /**
     * Handle state to show or hide menu options list
     *
     * @memberof ActionsList
     */
    handleCloseOptionsMenu() {
        if (!this.state.openModalAuthorization) {
            this.setState(state => ({
                itemToken: ''
            }));
        }
    };

    /**
     * Handele on delete event action item 
     *
     * @param {*} itemToken
     * @memberof ActionsList
     */
    handleDeleteAction(itemToken) {
        if (this.props.onDeleteAction) {
            this.setState({
                deletingTokenItem: itemToken
            })
            this.props.onDeleteAction(itemToken, this.props.actionsList.document_type);
        }
    }

    handleEditAction(itemToken) {
        if (this.props.onEditAction) {
            // this.setState({
            //     editingTokenItem: itemToken
            // })
            this.props.onEditAction(itemToken, this.props.actionsList.document_type);
        }
    }

    /**
     * Handle on Accept or reject action
     *
     * @param {*} comment
     * @memberof ActionsList
     */
    onAcceptRejectAction(comment) {
        this.props.acceptRejectActionInList(comment, this.state.itemToken, !this.state.isReject, this.props.actionsList.document_type)
            .then(() => {
                this.props.showSuccessToaster();
                this.handleOpenItemDialog('openModalAuthorization');
            })
            .catch(() => {
                this.props.showErrorToaster();
            })
    }

    /**
     * Method to render attachments cell in table
     *
     * @param {*} dataSource
     * @param {*} item
     * @returns
     * @memberof ActionsList
     */
    renderAttachOnItem(dataSource, item) {
        return (
            item[dataSource] && item[dataSource].map(file => {
                return (
                    <Grid item xs={12}>
                        <Tooltip title={<Translate id='common.downloadFilesTooltip' />} placement="top">
                            <Link
                                target="_blank"
                                href={`${process.env.REACT_APP_SIP_URL_FILES}/${file.relativePath}`}>
                                <AttachIcon style={{ fontSize: '18px' }} color='primary' />{'' + file.name}
                            </Link>
                        </Tooltip>
                    </Grid>
                )
            })
        )
    }

    /**
     * Method to render OptionsMenu
     *
     * @param {*} item
     * @returns
     * @memberof ActionsList
     */
    renderOptionsMenu(item, optionToShow) {
        var options = [];
        let isExternalUser = IsCurrentNotActiveDirectoryUser(this.props.user);
        if (this.props.canBeUpdateInternal && !isExternalUser) {
            if (optionToShow === 1)
                options.push({
                    itemClick: () => {
                        this.setState({ itemToken: item.token },
                            () => this.handleOpenItemDialog('openModalAuthorization', this.props.actionsList.document_type));
                    },
                    tooltipTranslation: <Translate id="common.accept" />,
                    menuItemIcon: <AcceptIcon style={{color:COLOR_STATUS_SUCCESS}} />
                });
            if (optionToShow === 2)
                options.push({
                    itemClick: () => {
                        this.setState({ itemToken: item.token },
                            () => this.handleOpenItemDialog('openModalAuthorization', this.props.actionsList.document_type, true)
                        );
                    },
                    tooltipTranslation: <Translate id="common.reject" />,
                    menuItemIcon: <RejectIcon color='error' />
                });
        }
        else {
            if (this.props.canBeUpdateSupplier && isExternalUser && IsNullOrEmpty(item.status)) {
                if (optionToShow === 1)
                    options.push({
                        itemClick: () => this.handleDeleteAction(item.token),
                        tooltipTranslation: <Translate id="common.delete" />,
                        menuItemIcon: <DeleteIcon color={this.state.deletingTokenItem === item.token ? 'disabled' : 'error'} />,
                        isLoading: this.state.deletingTokenItem === item.token
                    });
                if (optionToShow === 2)
                    options.push({
                        itemClick: () => this.handleEditAction(item.token),
                        tooltipTranslation: <Translate id="common.edit" />,
                        menuItemIcon: <EditIcon color='secondary' />,
                    });
            }
        }

        return (<OptionsMenu
            itemToken={item.token}
            handleClickOptions={this.handleClickOptionsMenu}
            open={this.state.itemToken === item.token}
            handleClickAway={this.handleCloseOptionsMenu}
            options={options}
            xsSize
        />)
    }

    /**
     * method to custom render cell on table
     *
     * @param {*} dataSource
     * @param {*} item
     * @returns
     * @memberof ActionsList
     */
    onRenderCellItem(dataSource, item) {
        if (dataSource === "attachments")
            return (this.renderAttachOnItem(dataSource, item))
        if (dataSource === "options") { //Validar opción para supplier es agregar en el renglon superios luego solo eliminar y no es editble salvo anexos
            return (<React.Fragment>
                {this.renderOptionsMenu(item, 1)}
                {this.renderOptionsMenu(item, 2)}
            </React.Fragment>)
        }
        if (dataSource === 'is_resolved') {
            let optionSelected = GetBooleanOptions()[item[dataSource]];
            return (optionSelected.label)
        }
        if (dataSource === 'action_type') {
            let optionSelected = GetActionTypes()[item[dataSource]];
            return (optionSelected.label)
        }
        if (dataSource === 'status') {
            if (item.status === APPROVAL_ACEPTED)
                return (<Tooltip title={<Translate id="common.accepted" />}>
                    <AcceptIcon style={{color:COLOR_STATUS_SUCCESS}} />
                </Tooltip>)
            if (item.status === APPROVAL_REJECTED)
                return (<Tooltip title={<Translate id="common.rejected" />}>
                    <RejectIcon color='error' />
                </Tooltip>)
        }
    }

    /**
     * Set Custom background color foreach action
     *
     * @memberof ActionsList
     */
    setCustomColorRow() {
        (this.props.actionsList && this.props.actionsList.actions) &&
            this.props.actionsList.actions.map((item) => {
                var customColor;
                if (item.status === APPROVAL_ACEPTED)
                    customColor = COLOR_STATUS_ACEPTED
                if (item.status === APPROVAL_REJECTED)
                    customColor = COLOR_STATUS_REJECTED
                return item.custom_color = customColor;
            });
    }

    render() {
        if (this.state.actionsList.actions) {
            this.setCustomColorRow();
            return (
                <React.Fragment>
                    <DataTable
                        data={this.state.actionsList.actions}
                        configuration={TableActionsConfiguration}
                        onRenderCellItem={this.onRenderCellItem}
                    />
                    {this.state.openModalAuthorization && <AcceptRejectDialog
                        open={this.state.openModalAuthorization}
                        isReject={this.state.isReject}
                        additionalTitle={': ' +
                            this.props.translate(
                                this.state.documentType === CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS ?
                                    "sac.actions.containmentAction" : "sac.actions.nonRecurrenceAction"
                            )
                        }
                        onClickAccept={this.onAcceptRejectAction}
                        onClickReject={this.onAcceptRejectAction}
                        onClickCloseCancel={() => this.handleOpenItemDialog('openModalAuthorization')}
                        isSaving={this.props.isSaving}
                    />}
                </React.Fragment>
            );
        }
        else {
            return ("");
        }
    }
}


const viewStyles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textAreaField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    footerForm: {
        display: "flex",
        paddingRight: "60px",
    },
    formControl: { margin: theme.spacing.unit },
    toolbar: {},
    searchInput: {},
    button: {},
    actionsToolbar: {},
    showingUsersCounter: {},
    grow: {},
    supplierBadge: {}
});

const TableActionsConfiguration = {
    columns: [
        {
            header: <Translate id="sac.actions.actions" />,
            dataSource: 'description',
        },
        {
            header: <Translate id="sac.actions.comments" />,
            dataSource: 'comments',
        }, {
            header: <Translate id="sac.actions.userCreator" />,
            dataSource: 'create_by',
        }, {
            header: <Translate id="sac.actions.actionType" />,
            dataSource: 'action_type',
        }, {
            header: <Translate id="sac.actions.endDate" />,
            dataSource: 'end_date_time',
        }, {
            header: <Translate id="sac.actions.solved" />,
            dataSource: 'is_resolved',
        }, {
            header: <Translate id="sac.actions.attachments" />,
            dataSource: 'attachments',
        }, {
            header: <Translate id="sac.actions.state" />,
            dataSource: 'status',
        }, {
            header: <Translate id="sac.inspectorComments" />,
            dataSource: 'inspector_comments',
        }, {
            header: '',
            dataSource: 'options',
        }]
}

export default withStyles(viewStyles, { withTheme: true })(ActionsList);