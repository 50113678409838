import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import { Button, LinearProgress, TextField, withStyles } from '@material-ui/core'
import DriveEtaIcon from '@material-ui/icons/DriveEta'

/** Custom components import section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import Toaster from '../../common/Toaster'

/** Redux import section */
import { useDispatch } from 'react-redux'
import { updateVehicle } from '../../../store/controlCO2/controlCO2Action'

/** Resources import section */
import { showErrorToaster } from '../../../store/helpers/ToasterHelpers'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'

const VehicleEditingPanel = (props) => {
	const { opened, vehicle, onClose = () => console.warn('[onClose] not defined!'), classes } = props

	/** Defines local state */
	const [saving, setSaving] = useState(false)
	const [vehicleData, setVehicleData] = useState({})
	const [toasterState, setToasterState] = useState({ showToaster: false })

	const dispatch = useDispatch()

	useEffect(() => {
		if (vehicle) {
			setVehicleData(vehicle)
		}
	}, [vehicle])

	const updateProperty = (event) => {
		const { value, name } = event.target
		setVehicleData((prevState) => ({ ...prevState, [name]: value }))
	}

	const isValidVehicle = () => {
		const { economicNumber, licensePlate, brandName } = vehicleData
		if (IsNullOrEmpty(economicNumber) || IsNullOrEmpty(licensePlate) || IsNullOrEmpty(brandName)) {
			return false
		}
		return true
	}

	const handleSubmit = (event) => {
		setSaving(true)
		event.preventDefault()
		dispatch(updateVehicle(vehicleData))
			.then(() => {
				onClose()
			})
			.catch((error) => {
				setToasterState(showErrorToaster())
			})
			.finally(() => {
				setSaving(false)
			})
	}

	if (!opened) return null
	return (
		<LateralPanel onClose={onClose}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id='controlCO2.vehicles.addVehicle' />}
				onCloseButtonClick={onClose}
				icon={<DriveEtaIcon color='primary' />}
			/>
			<div className={classes.formContainer}>
				<form onSubmit={handleSubmit} autoComplete='off'>
					<TextField
						fullWidth
						label={<Translate id='controlCO2.vehicles.economicNumber' />}
						name='economicNumber'
						value={vehicleData.economicNumber}
						onChange={updateProperty}
						className={classes.textField}
						disabled
					/>
					<TextField
						fullWidth
						label={<Translate id='controlCO2.vehicles.licensePlate' />}
						name='licensePlate'
						value={vehicleData.licensePlate}
						onChange={updateProperty}
						className={classes.textField}
					/>
					<TextField
						fullWidth
						label={<Translate id='controlCO2.vehicles.brandName' />}
						name='brandName'
						value={vehicleData.brandName}
						onChange={updateProperty}
						className={classes.textField}
					/>
					<TextField
						fullWidth
						label={<Translate id='controlCO2.vehicles.modelName' />}
						name='modelName'
						value={vehicleData.modelName}
						onChange={updateProperty}
						className={classes.textField}
					/>
					{saving && <LinearProgress />}
					<Button
						className={classes.btnSubmit}
						type='submit'
						variant='contained'
						disabled={saving || !isValidVehicle()}
						fullWidth
					>
						<Translate id={'common.save'} />
					</Button>
				</form>
			</div>
			<Toaster
				message={<Translate id={toasterState.toasterMessage} />}
				open={toasterState.showToaster}
				variant={toasterState.toasterVariant}
				onClose={() => setToasterState({ showToaster: false })}
			/>
		</LateralPanel>
	)
}

VehicleEditingPanel.propTypes = {
	opened: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
}

const styles = (theme) => ({
	formContainer: {
		padding: theme.spacing.unit,
	},
	textField: {
		marginBottom: theme.spacing.unit,
	},
	btnSubmit: {
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
})

export default withStyles(styles, { withTheme: true })(VehicleEditingPanel)
