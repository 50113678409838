///Import react section
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch, } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import Toaster from '../common/Toaster';
import DesktopView from './components/DesktopView'

import {
    loadSustainabilityFromBackEnd,
    updateSustainabilityFromBackend,
    updateWorkerDetailFromBackend,
    updateQuestionFromBackend,
    changeStatusFromBackEnd,
    updateActionPlanFromBackend,
    programAuditFromBackEnd,
    downloadQuestionnaries,
    duplicateQuestionnarie,
} from '../../store/sustainability2/SustainabilityActions'
/**Import actions section */
import { converListToArray } from '../../store/helpers/ListHelper'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';

const Show = (props) => {
    /**Render hooks section */
    const dispatch = useDispatch()

    /**Redux store information section*/
    const isLoading = useSelector((state) => state.sustainabilityV2.get('isLoading'))
    const identifier = useSelector((state) => state.sustainabilityV2.get('identifier'))
    const sustainability = useSelector((state) => state.sustainabilityV2.get('sustainability') ? state.sustainabilityV2.get('sustainability').toJS() : null)
    const translate = useSelector((state) => getTranslate(state.localize))
    const userProfile = useSelector((state) => state.oidc.user)
    const userRolesByCompany = useSelector((state) => state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : [])
    const isSaving = useSelector((state) => state.sustainabilityV2.get('isSaving'))
    const totalWorkerDetail = useSelector((state) => state.sustainabilityV2.get('totalWorkerDetail') ? state.sustainabilityV2.get('totalWorkerDetail').toJS() : null)

    /**Store local this component */
    const [toasterMessageConfig, setToasterMessageConfig] = useState({
        showToaster: false,
        toasterMessage: null,
        toasterVariant: "",
        name: ""
    })

    /**Use effect section */
    useEffect(() => {
        dispatch(loadSustainabilityFromBackEnd(props.match.params.token))
    }, [dispatch, props.match.params.token])


    /**
     * Action to update properties
     * @param {*} upa 
     */
    const updatePropertySustainability = (property, value) => {
        let sustainabilityId = props.match.params.token;
        return dispatch(updateSustainabilityFromBackend(sustainabilityId, property, value))
            .then(() => {
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    /**
     * Update worker detail
     * @param {*} property 
     * @param {*} value 
     * @param {*} detailId 
     * @returns 
     */
    const updatePropertyDetail = (property, value, detailId) => {
        let sustainabilityId = props.match.params.token;
        return dispatch(updateWorkerDetailFromBackend(sustainabilityId, detailId, property, value))
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    /**
     * Update question
     * @param {*} property 
     * @param {*} value 
     * @param {*} questionId 
     * @param {*} subTopic 
     * @param {*} topicId 
     * @returns 
     */
    const updatePropertyQuestion = (property, value, questionId, subTopic, topicId) => {
        let sustainabilityId = props.match.params.token;
        return dispatch(updateQuestionFromBackend(sustainabilityId, topicId, subTopic, questionId, property, value))
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    /**
     * Update action plan
     * @param {*} property 
     * @param {*} value 
     * @param {*} actionPlanId 
     * @returns 
     */
    const updatePropertyActionPlan = (property, value, actionPlanId, listActions) => {
        let sustainabilityId = props.match.params.token;
        return dispatch(updateActionPlanFromBackend(sustainabilityId, actionPlanId, property, value, listActions))
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    /**
     * Function to chage status 
     * @param {*} status 
     */
    const onChangeStatusFromBackEnd = (status, comments) => {
        let sustainabilityId = props.match.params.token;
        return dispatch(changeStatusFromBackEnd(status, sustainabilityId, comments))
            .then(() => {
                if (status !== 17) {
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterVariant: "success",
                        toasterMessage: "common.saveCorrectly",
                    })
                }
                else {
                    props.history.push(`/sustainabilityv2`)
                }
            })
            .catch((response) => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: (getErrorInChangeStatus(response)),
                    toasterVariant: "error",
                })
            })
    }

    const downloadQuestionnarie = () => {
        let sustainabilityId = props.match.params.token;
        return dispatch(downloadQuestionnaries(sustainabilityId))
    }

    /**
     * Function to duplicate questionnaire
     * @param {*} comments 
     * @returns 
     */
    const onDuplicateQuestionnarie = (comments) => {
        let sustainabilityId = props.match.params.token;
        return dispatch(duplicateQuestionnarie(sustainabilityId, comments))
            .then((requestToken) => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
                if (!IsNullOrEmpty(requestToken)) {
                    props.history.push(`/sustainabilityv2/${requestToken}`)
                }
            })
            .catch((response) => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: (getErrorInChangeStatus(response)),
                    toasterVariant: "error",
                })
            })
    }

    /**
   * Method to show error in change status
   * @param {*} error 
   */
    const getErrorInChangeStatus = (response) => {
        if (!IsNullOrEmpty(response.data) && typeof response.data === 'string') {
            if (response.data.includes("invalidUnansweredQuestionsFromSupplier"))
                return ("sustainability.error.invalidUnansweredQuestionsFromSupplier")
            else if (response.data.includes("notExtistActionPlansGenerated"))
                return ("sustainability.error.notExtistActionPlansGenerated")
            else if (response.data.includes("invalidSendActionsPlanSupplier"))
                return ("sustainability.error.invalidSendActionsPlanSupplier")
            else if (response.data.includes("invalidActionsFromSupplier"))
                return ("sustainability.error.invalidActionsFromSupplier")
            else if (response.data.includes("invalidToReevaluteQuestionnaire"))
                return ("sustainability.error.invalidToReevaluteQuestionnaire")
            else if (response.data.includes("invalidUnansweredQuestionsFromAuditor"))
                return ("sustainability.error.invalidUnansweredQuestionsFromAuditor")
            else if (response.data.includes("notExtistFinningsGenerated"))
                return ("sustainability.error.notExtistFinningsGenerated")
            else if (response.data.includes("invalidNoExistWorkerDetail"))
                return ("sustainability.error.invalidNoExistWorkerDetail")
            else if (response.data.includes("invaliInformationBusiniss"))
                return ("sustainability.error.invaliInformationBusiniss")
            else if (response.data.includes("invalidSendCloseFindingsSupplier"))
                return ("sustainability.error.invalidSendCloseFindingsSupplier")
            else if (response.data.includes("invalidCloseFindingsFromSupplier"))
                return ("sustainability.error.invalidCloseFindingsFromSupplier")
            else if (response.data.includes("invalidToReevalutFindings"))
                return ("sustainability.error.invalidToReevalutFindings")
            else if (response.data.includes("invalidDocumentsQuestionsFromSupplier"))
                return ("sustainability.error.invalidDocumentsQuestionsFromSupplier")
            else
                return ("common.errorToSave");
        }
        else {
            return ("common.errorToSave");
        }
    }

    /**
    * Function to progrm audit 
    * @param {*} status 
    */
    const onProgramAuditFromBackEnd = (status, model) => {
        let sustainabilityId = props.match.params.token;
        return dispatch(programAuditFromBackEnd(status, sustainabilityId, model))
            .then(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
            })
            .catch((response) => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: (getErrorInChangeStatus(response)),
                    toasterVariant: "error",
                })
            })
    }

    return (
        <>
            <DesktopView
                isLoading={isLoading}
                identifier={identifier}
                sustainability={sustainability}
                translate={translate}
                userProfile={userProfile}
                updateProperty={updatePropertySustainability}
                updatePropertyDetail={updatePropertyDetail}
                userRolesByCompany={userRolesByCompany}
                updatePropertyQuestion={updatePropertyQuestion}
                isSaving={isSaving}
                changeStatusFromBackEnd={onChangeStatusFromBackEnd}
                updatePropertyActionPlan={updatePropertyActionPlan}
                programAuditFromBackEnd={onProgramAuditFromBackEnd}
                totalWorkerDetail={totalWorkerDetail}
                downloadQuestionnarie={downloadQuestionnarie}
                duplicateQuestionnarie={onDuplicateQuestionnarie}
            />

            <Toaster
                message={<Translate id={toasterMessageConfig.toasterMessage} />}
                open={toasterMessageConfig.showToaster}
                variant={toasterMessageConfig.toasterVariant}
                onClose={() => {
                    setToasterMessageConfig({
                        showToaster: false,
                        toasterMessage: "",
                        toasterVariant: "",
                    })
                }}
            />
        </>
    )
}

const styles = ((theme) => ({

}))

export default withauthenticatedLayout(withRouter((withStyles(styles, { withTheme: true })(Show))))