
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { CardHeader } from '@material-ui/core';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LoadingPage from '../common/LoadingPage';
import FloatingButton from '../common/FloatingButton';
import DialogAvailableOperations from '../common/DialogAvailableOperations';
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow';
import Toaster from '../common/Toaster';
import InformationPartial from './components/InformationPartial';
import DocumentsList from './components/DocumentsList';
import HistoryCard from '../common/HistoryCard';
import AddCommonItem from '../common/AddCommonItem';
import WorkflowCancel from '../common/WorkflowCancel';

/** Import action section */
import { FREIGHT_FORWARDTHESUPPLIER, WORKFLOW_CANCELLATION } from '../../store/helpers/WorkFlowDocumentsType';
import { loadSupplierCode } from '../../store/suppliers/SupplierActions';
import {loadFreightFromBackEnd, uploadFilesFreight, removeFilesFreight, updateFreightResponse, sendSendFreightToClient,
    cancelFreightOrder, forwardTheSupplierToCorrectDocuments} from '../../store/freight/FreightAction';

import { converListToArray } from '../../store/helpers/ListHelper'
import { showSuccessToaster, showErrorToaster } from '../../store/helpers/ToasterHelpers';

/**
 * Container show freightToken
 */
class Show extends Component {

    /**
     * Create an instance loadFreightFromBackEnd
     * @param {*} props
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            activeTab: 0,
            openAvailableOperationsDialog: false,
            openForwardDialog: false,
            openCancelDialog: false,
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let freightToken = this.props.match.params.token;
        this.props.loadFreight(freightToken);
    }

    /**
     * componentDidUpdate
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof Index
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.freight !== null) {
            /// Get information full from freight
            let freight = this.props.freight;
            this.props.loadSupplierCode(freight.supplier_code);
        }
    }

    /**
 * Action to set state to open of available operations dialog
 * @memberof 
 */
    setOpenDialog = (openAvailable) => {
        this.setState({
            openAvailableOperationsDialog: openAvailable,
        });
    }    

    /**
     * Method to validate documents
     */
    hasValidDocumentsToSend(){
        let freight = this.props.freight;
        if(freight.invoices.length === 0){
            return false;
        }        
        else{
            return true;
        }
    }

    /**
   * onSendSendFreightToClient
   *
   * @param {*} item
   * @param {*} supplier
   * @memberof Create
   */
    onSendSendFreightToClient() {        
        if(!this.hasValidDocumentsToSend()){
            this.setState(showErrorToaster("freight.validateDocuments"));
            return Promise.reject();
        }
        if(this.hasValidDocumentsToSend()){
            this.props.sendSendFreightToClient(this.props.freight.token)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                })
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            }).catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            });
        }
    }

      /**
     * onCancelFolio
     */
    onCancelFreightOpen() {
        this.setState({
            openAvailableOperationsDialog: false,
            openCancelDialog: true,
        })
    }

    /**
     * Function to on cancel shipping order
     * @param {*} common 
     */
    onCancelFreightOrder(common) {
        let freightToken = this.props.match.params.token;
        return this.props.cancelFreightOrder(freightToken, common)
            .then(() => {
                this.setState({
                    openCancelDialog: false,
                })
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
     * onForwardFreightOpen
     */
    onForwardFreightOpen() {
        this.setState({
            openAvailableOperationsDialog: false,
            openForwardDialog: true,
        })
    }

    /**
     * Function to on forware supplier shipping order
     * @param {*} common 
     */
    onForwardTheSupplierToCorrectDocuments(common) {
        let freightToken = this.props.match.params.token;
        return this.props.forwardTheSupplierToCorrectDocuments(freightToken, common)
            .then(() => {
                this.setState({
                    openForwardDialog: false,
                })
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
     * Function to show foraward supplier
     * @param {*} document 
     */
    renderDocumentForwardTheSupplier(document) {
        return (
            <Grid container spacing={24} >
                <CardHeader
                    style={{ paddingTop: '0px' }}
                    title={
                        <Typography gutterBottom variant="body2" component="p">
                            <strong>
                            <Translate id="shippingOrder.messageForwardTheSupplier"/>:
                            </strong>
                            {' ' + document.observations}
                        </Typography>
                    }
                />
            </Grid>
        )
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {        
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.freight !== null) {            
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <InformationPartial  {...this.props} />
                    </Grid>
                    <Grid item xs={12}>
                        <DocumentsList  {...this.props} />
                    </Grid>
                </Grid>
            )
        }
    }

    /**
     * Render hystory section for all documents
     *
     * @returns
     * @memberof Show
     */
    renderHistory() {
        let documents = this.props.freight ? this.props.freight.documents ? this.props.freight.documents : [] : []
        return (
            documents.map(document => {
                let titleId;
                let aditionalData;
                if (document.document_type === FREIGHT_FORWARDTHESUPPLIER) {
                    titleId = 'shippingOrder.invalidDocuments';
                    aditionalData = this.renderDocumentForwardTheSupplier(document);
                }
                if (document.document_type === WORKFLOW_CANCELLATION) {
                    titleId = 'common.folioCancel';
                    aditionalData = <WorkflowCancel document={document} />;
                }
                return (
                    <HistoryCard
                        key={document.token}
                        title={titleId}
                        userCreator={document.create_by}
                        creationDate={document.creation_date_long}
                        aditionalData={aditionalData}
                    />
                )

            })
        )
    }

    render() {
        let optonsAvalinable = this.props.freight ? this.props.freight.get_available_operations : [];        
        return (
            <Card>
                {optonsAvalinable.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

                <LayoutHeaderWorkFlow {...this.props}
                    workFlow={this.props.freight}
                    title={<Translate id="freight.freightTitle"/>}
                />

                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {this.renderHistory()}

                <DialogAvailableOperations
                    {...this.props}
                    options={optonsAvalinable}
                    openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
                    setOpenDialog={this.setOpenDialog}
                    onSendSendFreightToClient={this.onSendSendFreightToClient}
                    onCancelDocument ={this.onCancelFreightOpen}
                    onForwardFreightOpen={this.onForwardFreightOpen}
                />

                {/** Dialog to forward supplier shipping */}
                < AddCommonItem
                    title={<Translate id="statusDescriptions.shippingOrderForwardTheSupplierToCorrectDocuments"/>}
                    message={<Translate id="shippingOrder.messageForwardTheSupplier"/>}
                    open={this.state.openForwardDialog}
                    onClose={() => { this.setState({ openForwardDialog: false }) }}
                    onSaveItem={this.onForwardTheSupplierToCorrectDocuments}
                    isSaving={this.props.isSaving}
                    isTextArea={true}
                />
                
                {/** Dialog to cancel shipping */}
                < AddCommonItem
                    title={<Translate id="shippingOrder.cancelRequest"/>}
                    message={<Translate id="shippingOrder.messageToCancel"/>}
                    open={this.state.openCancelDialog}
                    onClose={() => { this.setState({ openCancelDialog: false }) }}
                    onSaveItem={this.onCancelFreightOrder}
                    isSaving={this.props.isSaving}
                    isTextArea={true}
                />

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        )
    }

}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let freight = state.freights.get("freight");
    let selectedCompany = state.profile.get("selectedCompany");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        currentUser: user,
        isLoading: state.freights.get('isLoadingFreight'),
        freight: freight ? freight.toJS() : null,
        isSaving: state.freights.get('isSaving'),
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        company: selectedCompany ? selectedCompany.toJS() : null,
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadFreight: (token) => {
            return dispatch(loadFreightFromBackEnd(token));
        },
        loadSupplierCode: (token) => {
            return (dispatch(loadSupplierCode(token)))
        },
        uploadFilesFreight: (responseToken, files, folder, listItems) => {
            return dispatch(uploadFilesFreight(responseToken, files, folder, listItems));
        },
        removeFilesFreight: (responseToken, fileName, folder, listItems) => {
            return dispatch(removeFilesFreight(responseToken, fileName, folder, listItems));
        },
        updateFreightResponse: (responseToken, observtions) => {
            return dispatch(updateFreightResponse(responseToken, observtions));
        },
        sendSendFreightToClient:(token) =>{
            console.log("sendSendFreightToClient", token);
            return dispatch(sendSendFreightToClient(token));
        },
        cancelFreightOrder:(token, reasonCancellation) => {
            return dispatch(cancelFreightOrder(token, reasonCancellation));
        },
        forwardTheSupplierToCorrectDocuments:(token, observations) =>{
            return dispatch(forwardTheSupplierToCorrectDocuments(token, observations));
        }
    }
}

const styles = theme => ({
    actionsToolbar: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    margin: {
        margin: theme.spacing.unit * 2,
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));