///Import react section
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch, } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import CreateBaseDesktopView from './components/sections/CreateBaseDesktopView'

import {
    loadBiddingFromBackEnd,
    addBiddingBaseFromBackEnd,
    editBiddingBaseFromBackEnd,
    deletedBiddingBaseFromBackEnd,
    updateSectionFromBackEnd
} from '../../store/biddings/BiddingsActions'
/**Import actions section */
import { converListToArray } from '../../store/helpers/ListHelper'
import Toaster from '../common/Toaster';

const CreateBase = (props) => {
    /**Render hooks section */
    const dispatch = useDispatch()

    /**Redux store information section*/
    const bidding = useSelector((state) => state.biddings.get('bidding') ? state.biddings.get('bidding').toJS() : null)
    const isLoading = useSelector((state) => state.biddings.get('isLoading'))
    const userProfile = useSelector((state) => state.oidc.user ? state.oidc.user.profile : { name: "", email: "", user_name: "" })
    const usersByRole = useSelector((state) => state.internalUsers.get('usersByRole') ? converListToArray(state.internalUsers.get('usersByRole')) : [])
    const isSaving = useSelector((state) => state.biddings.get('isSaving'))

    const translate = useSelector((state) => getTranslate(state.localize))

    /**Store local this component */
    const [editBiddingConfigPanel, setEditBiddingConfigPanel] = useState({ opened: false, bidding: null })
    const [mode, setMode] = useState('MODE_EMPTY')
    const [isEdited, setIdEdited] = useState(false)
    const [editedBiddingBase, setEditedBiddingBase] = useState(null)
    const [toasterMessageConfig, setToasterMessageConfig] = useState({
        showToaster: false,
        toasterMessage: "",
        toasterVariant: "",
    })

    /**Use effect section */
    useEffect(() => {
        dispatch(loadBiddingFromBackEnd(props.match.params.token))
    }, [dispatch, props.match.params.token])

    const onOpenCloseEditLateralPanel = () => {
        setEditBiddingConfigPanel({ opened: !editBiddingConfigPanel.opened });
    }

    const onButtonCreateClick = () => {
        props.history.push(`/biddings/base/create/${props.match.params.token}`)
        setMode("MODE_CREATE");
    }

    const onEditBaseItemClick = (biddingBase) => {
        setMode("MODE_EDIT");
        setIdEdited(true)
        setEditedBiddingBase(biddingBase)
    }

    const onAddBiddingBaseFromBackEnd = (baseModel) => {
        return dispatch(addBiddingBaseFromBackEnd(baseModel, props.match.params.token))
            .then((biddingBase) => {
                onEditBaseItemClick(biddingBase)
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    
    const onEditBiddingBaseFromBackEnd = (baseModel) => {
        return dispatch(editBiddingBaseFromBackEnd(baseModel))
            .then(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onUpdatePositionSection = (section) => {
        return dispatch(updateSectionFromBackEnd(section))
            .then(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onDeletedBiddingSectionFromBackEnd = (sectionModel) => {
        return dispatch(deletedBiddingBaseFromBackEnd(sectionModel))
            .then(_ => {
                setMode('MODE_EMPTY')
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    return (
        <>
            <CreateBaseDesktopView
                /**String */
                mode={mode}
                /**Objects */
                bidding={bidding}
                userProfile={userProfile}
                /**Arrays */
                internalUsers={usersByRole}
                /**Bools */
                isLoading={isLoading}
                isSaving={isSaving}
                isEdited={isEdited}
                /**Functions */
                onOpenCloseEditLateralPanel={onOpenCloseEditLateralPanel}
                translate={translate}
                onButtonCreateClick={onButtonCreateClick}
                onAddBiddingBaseFromBackEnd={onAddBiddingBaseFromBackEnd}
                editedBiddingBase={editedBiddingBase}
                onEditBaseItemClick={onEditBaseItemClick}
                onEditBiddingBaseFromBackEnd={onEditBiddingBaseFromBackEnd}
                onDeletedBiddingSectionFromBackEnd={onDeletedBiddingSectionFromBackEnd}
                setIdEdited={setIdEdited}
                onUpdatePositionSection={onUpdatePositionSection}
            />

            <Toaster
                message={translate(toasterMessageConfig.toasterMessage)}
                open={toasterMessageConfig.showToaster}
                variant={toasterMessageConfig.toasterVariant}
                onClose={() => {
                    setToasterMessageConfig({
                        showToaster: false,
                        toasterMessage: "",
                        toasterVariant: "",
                    })
                }}
            />

        </>
    )
}


export default withauthenticatedLayout(withRouter((CreateBase)))