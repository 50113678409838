
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { Translate } from "react-localize-redux";
import PropTypes from 'prop-types'

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

/** section import components */
import HtmlEditor from '../../common/HtmlEditor';

import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper';

/**
 * Component ot render Information partial freight
 */
export default class InformationPartial extends Component {

    /**
     * Create an instance freight
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {            
            observationsSupplier: "",
        }
    }

    /**
     * Function to update observations Supplier
     */
    updatePropertyObservationsSupplier(value) {
        this.setState({
            observationsSupplier: value
        })
        let response = this.props.freight.response;
        this.props.updateFreightResponse(response.token, value)
    }    

    /**
     * Render
     */
    render() {
        const { classes } = this.props;
        let isExternalUser = (IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.props.freight.can_be_update_supplier);
        return (
            <Paper> 
                <Divider />
                <List>
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="queries.inventories.total" />
                                    </Typography>
                                    {": "}{this.props.freight.total}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="shippingOrder.currency" />
                                    </Typography>
                                    {": "} {this.props.freight.currency_description}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <HtmlEditor
                            id="observations_coordinator"
                            label={"shippingOrder.commentsFromApplicant"}
                            labelVariant={"body2"}
                            isDisabled
                            initialValue={this.props.freight.observations_coordinator}
                        />                        
                    </ListItem>
                    <Divider />
                    {this.props.freight.response && <ListItem>
                        <HtmlEditor
                            id="observations"
                            label={"freight.suppliersComments"}
                            labelVariant={"body2"}
                            isDisabled={!isExternalUser}
                            initialValue={this.props.freight.response.observations}
                            onChange={(data) => this.updatePropertyObservationsSupplier(data.value)}
                        />
                    </ListItem>}
                </List>                
            </Paper>
        );
    }
}

/**
 * freight
 */
InformationPartial.propTypes = {
    freight: PropTypes.object.isRequired
}