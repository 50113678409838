import React from 'react'
import propTypes from 'prop-types'
/**
 * A tab panel component
 */
const TabPanel = (props) => {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	)
}

TabPanel.propTypes = {
	children: propTypes.node,
	index: propTypes.any.isRequired,
	value: propTypes.any.isRequired
}

export default TabPanel