/**Import react section */
import React from "react";
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
/**Import material UI Section*/
import { Typography, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
/**import helpers section */
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/AddTwoTone';
import ReportIcon from '@material-ui/icons/CloudDownload';
import RateReviewTwoToneIcon from '@material-ui/icons/RateReviewTwoTone';
import InfoIcon from '@material-ui/icons/Info';

/**Import components sections */
import FloatingButton from './FloatingButton';
import DialogOperationContainer from '../../common/DialogOperations/DialogOperationContainer';
import DialogRatingInformationContainer from './informationRating/DialogRatingInformationContainer'
import { IndirectQualificationsStatus } from '../../../store/helpers/AppConstants'
import { COLOR_STATUS_WARNING } from '../../../store/helpers/StatusColorConstants'
import {
    colorStatusIndirectQualification, descriptionStatus,
    canBeEditInStatusDraft, descriptionIndirectEvaluationTypeTranslate,
    canBeEditInStatusGenerateRatings,
    canBeEditInStatusSendToEvaluate,
    canBeRemoveFolioCancel
} from '../../../store/helpers/IndirectQualificationsHelpers'
import { ROLE_INDIRECT_RATING_RESPONSIBLE, IsUserInRole} from '../../../store/helpers/RolesHelper'
/**Custom hooks index */
import { useIndirectQualificationCustomHookShow } from '../customHooks/UseCustomHookShow'

const ShowDesktopViewHeader = (props) => {
    /**String props section */
    const { classes } = props
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onLateralPanelConfig, onConfirmPassword,  } = props
    /**Objects props section */
    const { translate, indirectQualification } = props
    /**Render hooks section */

    const { user, userRolesByCompany, onDownloadReportRatingsDetail, isSaving} = useIndirectQualificationCustomHookShow()
    let availableOperationsInStatusDraft = []
    let availableOperationsInStatusSendToEvaluate = []
    let availableOperationsInStatusGenerateRatings = []
    let availableOperationsInStatusRemoveRating = []


    let isUserCreator = (indirectQualification && ((user && (user.profile && user.profile.username === indirectQualification.CreatedBy)) 
    || IsUserInRole(ROLE_INDIRECT_RATING_RESPONSIBLE, userRolesByCompany)))
    if (indirectQualification && canBeEditInStatusDraft(indirectQualification.Status) && isUserCreator) {
        availableOperationsInStatusDraft.push({
            title: <Translate id="indirectqualifications.operationsAvailable.messageSendQualificationTitle" />,
            onClick: () => onConfirmPassword(indirectQualification, IndirectQualificationsStatus.SEND_TO_EVALUATE),
            isIconMaterial: true,
            description: <Translate id="indirectqualifications.operationsAvailable.messageSentQualification" />
        })
        availableOperationsInStatusDraft.push({
            title: <Translate id="sustainability.status.cancelFolio" />,
            onClick: () => onConfirmPassword(indirectQualification, IndirectQualificationsStatus.CANCEL),
            isIconMaterial: true,
            description: <Translate id="indirectqualifications.operationsAvailable.messageCancelQualification" />
        })

    }
    if ((indirectQualification && indirectQualification.IsExpireShippingDate && canBeEditInStatusSendToEvaluate(indirectQualification.Status) && isUserCreator) 
        || (indirectQualification && user && user.profile && user.profile.username === "adimbptm")) {
        availableOperationsInStatusSendToEvaluate.push({
            title: <Translate id="indirectqualifications.operationsAvailable.messageGenerateRatingsTitle" />,
            onClick: () => onConfirmPassword(indirectQualification, IndirectQualificationsStatus.GENERATE_RATING),
            isIconMaterial: true,
            description: <Translate id="indirectqualifications.operationsAvailable.messageGenerateRatingsMessage" />
        })
    }
    if (indirectQualification && canBeEditInStatusGenerateRatings(indirectQualification.Status) && isUserCreator) {
        availableOperationsInStatusGenerateRatings.push({
            title: <Translate id="indirectqualifications.operationsAvailable.messagePublishTitle" />,
            onClick: () => onConfirmPassword(indirectQualification, IndirectQualificationsStatus.PUBLISH_RATINGS),
            isIconMaterial: true,
            description: <Translate id="indirectqualifications.operationsAvailable.messagePublishMessage" />
        })
    }
    if (indirectQualification && canBeRemoveFolioCancel(indirectQualification.Status) && IsUserInRole(ROLE_INDIRECT_RATING_RESPONSIBLE, userRolesByCompany)) {
        availableOperationsInStatusRemoveRating.push({
            title: <Translate id="indirectqualifications.operationsAvailable.messageRemoveRatinTitle" />,
            onClick: () => onConfirmPassword(indirectQualification, IndirectQualificationsStatus.REMOVE),
            isIconMaterial: true,
            description: <Translate id="indirectqualifications.operationsAvailable.messageRemoveRatingResponse" />
        })
    }

    const onGetOperationsAvailable = () => {
        switch (indirectQualification ? indirectQualification.Status : 0) {
            case IndirectQualificationsStatus.DRAFT:
                return availableOperationsInStatusDraft
            case IndirectQualificationsStatus.SEND_TO_EVALUATE:
                return availableOperationsInStatusSendToEvaluate
            case IndirectQualificationsStatus.GENERATE_RATING:
                return availableOperationsInStatusGenerateRatings
            case IndirectQualificationsStatus.CANCEL:
                return availableOperationsInStatusRemoveRating
            default:
                return []
        }
    }

    const { DialogOperations, onOpenAndClose, } = DialogOperationContainer({
        options: onGetOperationsAvailable()
    })

    const { DialogRatingInformation, onOpenAndCloseDialogInformation } = DialogRatingInformationContainer({})



    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <CardHeader
                    avatar={
                        <Tooltip title={descriptionStatus(indirectQualification ? indirectQualification.Status : "", translate)}>
                            <Avatar aria-label="recipe" className={classes.avatar} style={{ backgroundColor: colorStatusIndirectQualification(indirectQualification ? indirectQualification.Status : "") }}>
                                <RateReviewTwoToneIcon />
                            </Avatar>
                        </Tooltip>
                    }
                    action={
                        <div>
                            <div className={classes.displayAlignRight}>
                                {
                                    onGetOperationsAvailable().length !== 0 && <div className={classes.operationAvailable}>
                                        <FloatingButton
                                            onOpenAndCloseDialog={onOpenAndClose}
                                        />
                                    </div>
                                }
                                {
                                    canBeEditInStatusDraft(indirectQualification ? indirectQualification.Status : "")
                                    && <Fab size="small" color="primary"
                                        onClick={() => { onLateralPanelConfig("IndexCreateIndirectQualification") }}>
                                        <AddIcon />
                                    </Fab>
                                }
                                {
                                    indirectQualification && indirectQualification.Suppliers && indirectQualification.Suppliers.length !== 0 &&  
                                    <Fab size="small" color="secondary" className={classes.reportBtn}
                                        disabled={isSaving}
                                        onClick={() => { onDownloadReportRatingsDetail(indirectQualification.Id, indirectQualification.EvaluationType) }}>
                                        <ReportIcon />
                                    </Fab>
                                }

                            </div>
                            {indirectQualification && indirectQualification.Status !== IndirectQualificationsStatus.DRAFT &&
                             <div className={classes.displayAlignRight}>
                                <Typography className={classes.indicadorText}>{translate("indirectqualifications.indicatorRating")}</Typography>
                                <InfoIcon className={classes.inforIcon} onClick={()=>onOpenAndCloseDialogInformation()}/>
                            </div>}

                        </div>
                    }
                    title={`Calificaciones de indirectos | Tipo de evaluación: ${descriptionIndirectEvaluationTypeTranslate(indirectQualification ? indirectQualification.EvaluationType : "", translate)}`}
                    subheader={
                        <div>
                            <Typography className={classes.subtitleText}>
                                {`${translate("indirectqualifications.show.folio")} ${indirectQualification ? indirectQualification.FormatedFolio : ""} ${translate("indirectqualifications.show.evaluationDate")} ${indirectQualification ? moment(indirectQualification.EvaluationDate).format("MMMM YYYY") : ""}`}
                            </Typography>
                            {indirectQualification && indirectQualification.ShippingDate && <Typography className={classes.subtitleText}>
                                {`${translate("indirectqualifications.create.shipphingDate")}: ${moment(indirectQualification.ShippingDate).format("L")}`}
                            </Typography>}
                        </div>}
                />
            </Grid>

            <DialogOperations />
            <DialogRatingInformation/>


        </Grid>
    )
}

const styles = (theme) => ({
    flex: {
        justifyContent: "flex-end"
    },
    reportBtn: {
        marginLeft: "5px"
    },
    operationAvailable: {
        marginTop: "-6px"
    },
    subtitleText: {
        fontSize: "12px"
    },
    displayAlignRight: {
        display: "flex",
        justifyContent: "flex-end"
    },
    inforIcon:{
        marginLeft:"10px",
        cursor:"pointer",
        color:COLOR_STATUS_WARNING

    },
    indicadorText:{
        color:COLOR_STATUS_WARNING
    }
});

export default withRouter(
    withStyles(styles, { withTheme: true })(ShowDesktopViewHeader)
);
