/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import { withStyles, Grid } from '@material-ui/core';
import { Translate } from 'react-localize-redux'
/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper';

import {IsNullOrEmpty} from '../../../../store/helpers/StringHelper'

const AddSuppliersLateralPanel = (props) => {

    /**props section */
    const { classes } = props

    const [binddingModel, setBinddingModel] = useState({
        name: "",
        email: "",
    });

    const [errors, setErrors] = useState({});

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        const errors = validate(event.target);
        setErrors(errors);
        setBinddingModel({
            ...binddingModel,
            [name]: value
        });
    }

    const validate = data => {
        const errors = {};
        if (data.name === "email" && data.value.length > 0) {
            let regexQuantity = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regexQuantity.test(data.value)) errors.email = "Correo electrónico inválido";
        }
        return errors;
    };

    const canBeSaved = () => {
        if(IsNullOrEmpty(binddingModel.name)) return false
        if(IsNullOrEmpty(binddingModel.contactName)) return false
        if(IsNullOrEmpty(binddingModel.email)) return false
        if(Object.entries(errors).length !== 0 ) return false
        return true;
    }

    const onSaveButtonClick = ()=>{
        if(props.onAddExternalSupplierInBiddingFromBackEnd)
            props.onAddExternalSupplierInBiddingFromBackEnd(binddingModel)
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            header={
                <Toolbar>
                    <Typography variant="h6">
                        <Translate id="biddingsModule.addSuppliers" />
                    </Typography>
                </Toolbar>
            }
            actions={
                <DialogActions
                    view={SHOW_EDIT_VIEW}
                    classes={classes}
                    onCancel={props.onClose}
                    onClose={props.onClose}
                    onSaveItem={onSaveButtonClick}
                    isSaving={props.isSaving}
                    canBeSaved={canBeSaved()}
                />
            }
        >
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={<Translate id="biddingsModule.nameBusinessUnit" />}
                        name='name'
                        onChange={onPropertyChange}
                        margin="normal"
                        variant="outlined"
                        multiline
                        value={binddingModel ? binddingModel.name : ""}
                    />

                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={<Translate id="biddingsModule.ejecutiveName" />}
                        name='contactName'
                        onChange={onPropertyChange}
                        margin="normal"
                        variant="outlined"
                        multiline
                        value={binddingModel ? binddingModel.contactName : ""}
                    />

                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        error={Object.entries(errors).length !== 0 }
                        label={<Translate id="biddingsModule.email" />}
                        name='email'
                        onChange={onPropertyChange}
                        margin="normal"
                        variant="outlined"
                        multiline
                        value={binddingModel ? binddingModel.email : ""}
                        helperText={(errors.email) ? errors.email : ""}
                    />

                </Grid>
            </Grid>
        </Dialog >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
});

AddSuppliersLateralPanel.propTypes = {
    open: propTypes.bool.isRequired
}

AddSuppliersLateralPanel.defaultProps = {
    open: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddSuppliersLateralPanel))