import { fromJS, Map } from 'immutable'

import * as noticeChangeActions from './NoticeChangeAction'

/**
 * noticeChangeActions initial state
 */
export const noticeChangeInitialState = fromJS({
	isLoadingNoticeChanges: true,
	noticechanges: null,
	isLoadingNoticeChange: false,
	noticechange: null,
	sortCriteria: {
		by: 'creation_date',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	noticechangesCount: null,
	isUpdatingPlaneName: false,
	noticeInformation: null,
	notificationNotice: null,
	isLoadingNotification: false,
	isLoadingUpdateQuote: false,
})

export default function noticeChangeReducer(state = noticeChangeInitialState, action) {
	switch (action.type) {
		case noticeChangeActions.START_LOADING_NOTICE_CHANGES: {
			return state.merge({
				isLoadingNoticeChanges: action.isLoading,
			})
		}
		case noticeChangeActions.SET_NOTICE_CHANGES_LIST: {
			let noticesLists = fromJS(action.notices)
			return state.merge({
				noticechanges: noticesLists,
				isLoadingNoticeChanges: false,
			})
		}
		case noticeChangeActions.CHANGE_NOTICE_CHANGES_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case noticeChangeActions.CHANGE_NOTICE_CHANGES_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case noticeChangeActions.CHANGE_NOTICE_CHANGES_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case noticeChangeActions.SET_NOTICE_CHANGES_COUNT: {
			return state.merge({
				noticechangesCount: action.count,
			})
		}
		case noticeChangeActions.IS_LOADING_NOTICE_CHANGE: {
			return state.merge({
				isLoadingNoticeChange: action.isLoadingNotice,
				noticechange: null,
				noticeInformation: null,
			})
		}
		case noticeChangeActions.GET_INFORMATION_NOTICE_CHANGE: {
			return state.merge({
				isLoadingNoticeChange: false,
				noticechange: fromJS(action.notice),
			})
		}
		case noticeChangeActions.SET_NOTICE_CHANGE: {
			return state.merge({
				isLoadingNoticeChange: false,
				isSaving: false,
				noticeInformation: fromJS(action.notice),
			})
		}
		case noticeChangeActions.SET_NOTICE_ITEM_SELECTED: {
			let items = state
				.get('noticechange')
				.get('details')
				.map((noticeItem) => {
					if (noticeItem.get('token') === action.noticeItemToken) {
						return noticeItem.set('is_selected', true)
					} else {
						return noticeItem.set('is_selected', false)
					}
				})
			let noticechange = state.get('noticechange').set('details', items)
			return state.merge({
				noticechange,
			})
		}
		case noticeChangeActions.SET_QUOTE_UPDATE_IN_NOTICE_OF_CHANGE: {
			let items = state
				.get('noticeInformation')
				.get('suppliers')
				.map((supplier) => {
					const quoteUpdate = action.quoteUpdate
					let quotes = supplier.get('quotes').map((quote) => {
						let operation = quote.get('actual_operation')
						if (quote.get('token') === quoteUpdate.token) operation = quoteUpdate.actual_operation
						return quote.set('actual_operation', operation)
					})
					return supplier.set('quotes', quotes)
				})
			let noticeInformation = state.get('noticeInformation').set('suppliers', items)
			return state.merge({
				noticeInformation,
				isLoadingUpdateQuote: false,
			})
		}
		case noticeChangeActions.IS_LOADING_QUOTE_NOTICE_OF_CHANGE: {
			return state.merge({
				isLoadingUpdateQuote: action.isLoading,
			})
		}
		case noticeChangeActions.ADD_SUPPLIER_FROM_NOTICE_CHANGE: {
			let quoteToAdd = Map(action.quote)
			return state.updateIn(['noticechange', 'details'], (allDetails) => {
				let index = allDetails.findIndex((detail) => {
					return detail.get('token') === action.noticeItemToken
				})
				let detailToChange = allDetails.get(index)
				let updatedQuoteList = detailToChange.get('quotes').push(quoteToAdd)
				let updatedDetail = detailToChange.set('quotes', updatedQuoteList)
				return allDetails.setIn([index], updatedDetail)
			})
		}
		case noticeChangeActions.REMOVE_SUPPLIER_FROM_NOTICE_CHANGE: {
			return state.updateIn(['noticechange', 'details'], (allDetails) => {
				let index = allDetails.findIndex((detail) => {
					return detail.get('token') === action.noticeItemToken
				})
				let detailToChange = allDetails.get(index)
				let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
					return quoteMap.get('supplier_token') === action.supplierToken
				})
				let updatedQuoteList = detailToChange.get('quotes').delete(quoteIndex)
				let updatedDetail = detailToChange.set('quotes', updatedQuoteList)
				return allDetails.setIn([index], updatedDetail)
			})
		}
		case noticeChangeActions.ADD_QUOTE_SUPPLIER_FROM_NOTICE_CHANGE: {
			return state.updateIn(['noticechange', 'details'], (allDetails) => {
				let index = allDetails.findIndex((detail) => {
					return detail.get('token') === action.noticeItemToken
				})
				let detailToChange = allDetails.get(index)
				let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
					return quoteMap.get('supplier_token') === action.supplierToken
				})

				let updatedQuoteList = detailToChange.get('quotes')
				let updateQuote = updatedQuoteList.get(quoteIndex)
				updateQuote = updateQuote.set('is_quote_requiered', true)

				updatedQuoteList = updatedQuoteList.setIn([quoteIndex], updateQuote)
				let updatedDetail = detailToChange.set('quotes', updatedQuoteList)
				return allDetails.setIn([index], updatedDetail)
			})
		}
		case noticeChangeActions.REMOVE_QUOTE_SUPPLIER_FROM_NOTICE_CHANGE: {
			return state.updateIn(['noticechange', 'details'], (allDetails) => {
				let index = allDetails.findIndex((detail) => {
					return detail.get('token') === action.noticeItemToken
				})
				let detailToChange = allDetails.get(index)
				let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
					return quoteMap.get('supplier_token') === action.supplierToken
				})

				let updatedQuoteList = detailToChange.get('quotes')
				let updateQuote = updatedQuoteList.get(quoteIndex)
				updateQuote = updateQuote.set('is_quote_requiered', false)
				updateQuote = updateQuote.set('is_sample_requiered', false)

				updatedQuoteList = updatedQuoteList.setIn([quoteIndex], updateQuote)
				let updatedDetail = detailToChange.set('quotes', updatedQuoteList)
				return allDetails.setIn([index], updatedDetail)
			})
		}
		case noticeChangeActions.ADD_SAMPLE_REQUEST_SUPPLIER_FROM_NOTICE_CHANGE: {
			return state.updateIn(['noticechange', 'details'], (allDetails) => {
				let index = allDetails.findIndex((detail) => {
					return detail.get('token') === action.noticeItemToken
				})
				let detailToChange = allDetails.get(index)
				let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
					return quoteMap.get('supplier_token') === action.supplierToken
				})

				let updatedQuoteList = detailToChange.get('quotes')
				let updateQuote = updatedQuoteList.get(quoteIndex)
				updateQuote = updateQuote.set('is_sample_requiered', true)

				updatedQuoteList = updatedQuoteList.setIn([quoteIndex], updateQuote)
				let updatedDetail = detailToChange.set('quotes', updatedQuoteList)
				return allDetails.setIn([index], updatedDetail)
			})
		}
		case noticeChangeActions.REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_NOTICE_CHANGE: {
			return state.updateIn(['noticechange', 'details'], (allDetails) => {
				let index = allDetails.findIndex((detail) => {
					return detail.get('token') === action.noticeItemToken
				})
				let detailToChange = allDetails.get(index)
				let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
					return quoteMap.get('supplier_token') === action.supplierToken
				})

				let updatedQuoteList = detailToChange.get('quotes')
				let updateQuote = updatedQuoteList.get(quoteIndex)
				updateQuote = updateQuote.set('is_sample_requiered', false)

				updatedQuoteList = updatedQuoteList.setIn([quoteIndex], updateQuote)
				let updatedDetail = detailToChange.set('quotes', updatedQuoteList)
				return allDetails.setIn([index], updatedDetail)
			})
		}
		case noticeChangeActions.SEND_QUOTES_REQUEST_NOTICE_CHANGE: {
			return state.merge({
				isSaving: action.isSaving,
			})
		}
		case noticeChangeActions.UPDATE_DETAIL_SCHEMATICS: {
			let items = state
				.get('noticechange')
				.get('details')
				.map((item) => {
					if (item.get('token') === action.detailPlane.token) {
						return item.set('plot_name', action.detailPlane.plot_name)
					} else {
						return item
					}
				})
			let noticechange = state.get('noticechange').set('details', items)
			return state.merge({
				noticechange,
			})
		}
		case noticeChangeActions.IS_SAVING_NOTICE_OF_CHANGE: {
			return state.merge({
				isSaving: action.isSaving,
			})
		}
		case noticeChangeActions.FINALIZE_NOTICE_OF_CHANGE: {
			let noticeUpdate = state.get('noticeInformation')
			noticeUpdate = noticeUpdate.set('is_closed', true)
			return state.merge({
				isSaving: false,
				noticeInformation: noticeUpdate,
			})
		}
		case noticeChangeActions.IS_LOADING_NOTIFICATION_NOTICE: {
			return state.merge({
				isLoadingNotification: action.isLoadingNotification,
				notificationNotice: null,
			})
		}
		case noticeChangeActions.GET_INFORMATION_NOTIFICATION_NOTICE: {
			return state.merge({
				isLoadingNotification: false,
				isSaving: false,
				notificationNotice: fromJS(action.notification),
			})
		}
		case noticeChangeActions.UPDATE_COMMENTS: {
			let items = state
				.get('noticechange')
				.get('details')
				.map((item) => {
					if (item.get('token') === action.partNumberDetail.token) {
						return item
							.set('comments', action.partNumberDetail.comments)
							.set('requiered_plot_art', action.partNumberDetail.requiered_plot_art)
					} else {
						return item
					}
				})
			let noticechange = state.get('noticechange').set('details', items)
			return state.merge({
				noticechange,
			})
		}
		case noticeChangeActions.ADD_FILE_NOTICE: {
			let detailsList = state.getIn(['noticechange', 'details']).toJS()
			let indexDetail = detailsList.findIndex((detail) => {
				return detail.token === action.tokenPartNumber
			})
			let oldList = state.getIn(['noticechange', 'details', indexDetail, 'attachments']).toJS()
			let filesToAdd = action.files.filter((newFile) => {
				return !oldList.find((uploadedFile) => {
					return uploadedFile.name === newFile.name
				})
			})
			let newList = [...oldList, ...filesToAdd]
			return state.setIn(['noticechange', 'details', indexDetail, 'attachments'], fromJS(newList))
		}
		case noticeChangeActions.REMOVE_FILE_NOTICE: {
			let detailsList = state.getIn(['noticechange', 'details']).toJS()
			let indexDetail = detailsList.findIndex((detail) => {
				return detail.token === action.tokenPartNumber
			})
			let oldList = state.getIn(['noticechange', 'details', indexDetail, 'attachments']).toJS()
			let newList = oldList.filter((file) => {
				return file.name !== action.fileName
			})
			return state.setIn(['noticechange', 'details', indexDetail, 'attachments'], fromJS(newList))
		}
		case noticeChangeActions.UPDATE_NOTICE_DETAIL_ITEM_PROPERTY: {
			let items = state
				.get('noticechange')
				.get('details')
				.map((item) => {
					if (item.get('token') === action.detailItem.token) {
						return item.set(action.propertyName, action.detailItem[action.propertyName])
					} else {
						return item
					}
				})
			let noticechange = state.get('noticechange').set('details', items)
			return state.merge({
				noticechange,
			})
		}
		case noticeChangeActions.SET_REACTIVE_QUOTE_IN_NOTICE: {
			return state.updateIn(['noticeInformation', 'suppliers'], (allSuppliers) => {
				let index = allSuppliers.findIndex((supplier) => {
					return supplier.get('supplier_token') === action.quote.supplier_token
				})
				let quoteToChange = allSuppliers.get(index)
				let updatedQuoteList = quoteToChange.get('quotes').map((item) => {
					if (item.get('token') === action.quote.token) {
						return item
							.set('status', action.quote.actual_operation_description)
							.set('actual_operation', action.quote.actual_operation)
					} else {
						return item
					}
				})
				let updatedDetail = quoteToChange.set('quotes', updatedQuoteList)
				return allSuppliers.setIn([index], updatedDetail)
			})
		}
		default:
			return state
	}
}
