import {fromJS } from 'immutable';

import * as partNumbersActions from './PartNumberAction';

/**
 * Parts numbers initial state
 */
export const partNumbersInitialState = fromJS({
    isSaving: false,
    specPlanos:null,
    specifications: null,
    isLoadingPartNumber: false,
    partNumber: null,
})

export default function partNumbersReducer(state = partNumbersInitialState, action){
    switch(action.type){
        case partNumbersActions.IS_SAVING:{
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case partNumbersActions.SET_PLANES_LIST:{
            return state.merge({
                specPlanos: action.specPlanos,
                isSaving: false,
            })
        }
        case partNumbersActions.SET_SPECIFICATIONS_LIST:{
            return state.merge({
                specifications: action.spects,
                isSaving: false,
            })
        }
        case partNumbersActions.IS_LOADING_PART_NUMBER:{
            return state.merge({
                isLoadingPartNumber: action.isLoading,
                partNumber: null,
            })
        }
        case partNumbersActions.GET_INFORMATION_PART_NUMBER:{
            return state.merge({
                isLoadingPartNumber: false,
                partNumber: fromJS(action.partNumber)
            })
        }
        default:
            return state;
    }
}