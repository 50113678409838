/** Import ReactJS */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
/** Material UI import section */
import { Avatar, Card, Grid, Divider, Typography, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import AssignmentIcon from '@material-ui/icons/Assignment'
/**Import section components */
/**Import resources section */
import { COLOR_STATUS_ALERT_INFO } from '../../../../store/helpers/StatusColorConstants'
import { IsUserInRole, ROLE_FACTORING_AFFILIATION_ADMINISTRATOR } from '../../../../store/helpers/RolesHelper'
/** Resources import section */
const useStyle = (theme) => ({
	card: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
		padding: 0,
		'&:last-child': {
			padding: 0,
		},
	},
	cardcontent: {
		padding: 0,
		'&:last-child': {
			padding: 0,
		},
	},
	textTitle: {
		fontWeight: 'bold',
	},
})

const HeaderComponent = (props) => {
	const { classes, factoringAffiliation, onUpdateSupplierFactoring } = props
	//Array props section
	const { userRolesByCompany } = props
	let isUserAdministrator = IsUserInRole(ROLE_FACTORING_AFFILIATION_ADMINISTRATOR, userRolesByCompany)
	return (
		<Grid container spacing={16}>
			<Grid item xs={12}>
				<Card>
					<CardHeader
						className={classes.card}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={<Translate id='factoringAffiliation.show.creatorInformation' />}
					/>
					<Divider />
					<CardContent>
						<Grid container spacing={8}>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.createdBy' />
								</Typography>
								<Typography>
									{factoringAffiliation ? factoringAffiliation.CreatedByFactoring.CreatedBy : '-'}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.user' />
								</Typography>
								<Typography>
									{factoringAffiliation
										? factoringAffiliation.CreatedByFactoring.CreatedUserName
										: '-'}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.emailCreator' />
								</Typography>
								<Typography>
									{factoringAffiliation ? factoringAffiliation.CreatedByFactoring.EmailCreator : '-'}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader
						className={classes.card}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={<Translate id='factoringAffiliation.show.supplierInformation' />}
					/>
					<CardContent>
						<Grid container spacing={8}>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.supplierInformation' />
								</Typography>
								<Typography>
									{factoringAffiliation ? factoringAffiliation.SupplierInFactoring.Code : '-'}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.emailCreator' />:
								</Typography>
								{factoringAffiliation && factoringAffiliation.SupplierInFactoring && (
									<TextField
										defaultValue={
											factoringAffiliation ? factoringAffiliation.SupplierInFactoring.Email : ''
										}
										onBlur={(event) =>
											onUpdateSupplierFactoring(
												event.target.value,
												factoringAffiliation
													? factoringAffiliation.SupplierInFactoring.PhoneNumber
													: ''
											)
										}
										disabled={!isUserAdministrator}
									/>
								)}
								{/* <Typography>
									{factoringAffiliation ? factoringAffiliation.SupplierInFactoring.Email : '-'}
								</Typography> */}
							</Grid>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.phoneNumber' />
								</Typography>
								{factoringAffiliation && factoringAffiliation.SupplierInFactoring && (
									<TextField
										defaultValue={
											factoringAffiliation
												? factoringAffiliation.SupplierInFactoring.PhoneNumber
												: ''
										}
										onBlur={(event) =>
											onUpdateSupplierFactoring(
												factoringAffiliation
													? factoringAffiliation.SupplierInFactoring.Email
													: '',
												event.target.value
											)
										}
										disabled={!isUserAdministrator}
									/>
								)}
								{/* <Typography>
									{factoringAffiliation ? factoringAffiliation.SupplierInFactoring.PhoneNumber : '-'}
								</Typography> */}
							</Grid>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.supplierName' />
								</Typography>
								<Typography>
									{factoringAffiliation ? factoringAffiliation.SupplierInFactoring.Name : '-'}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.supplierRFC' />
								</Typography>
								<Typography>
									{factoringAffiliation ? factoringAffiliation.SupplierInFactoring.RFC : '-'}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)
}

HeaderComponent.propTypes = {}

HeaderComponent.defaultProps = {}
export default withRouter(withStyles(useStyle, { withTheme: true })(HeaderComponent))
