/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
/**Import material UI Section*/
import { withStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
/**Import components section */
import TextfieldQualificationResponse from './TextfieldQualificationResponse'
import { IconButton } from '@material-ui/core'
import CommentIcon from '@material-ui/icons/Comment'

/**import helper section */
import {
	RELEASE_PLAN_COLOR_YELLOW,
	COLOR_STATUS_GRAY,
	BACKGROUND_COLOR_GRAY_BIDDING,
	COLOR_STATUS_DANGER,
} from '../../../../store/helpers/StatusColorConstants'
import { canBeEditInStatusSendToEvaluate } from '../../../../store/helpers/IndirectQualificationsHelpers'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'

const ItemsContentTable = (props) => {
	/**Objects props section */
	const { indirectQualification, items, isResponsibleAssigned, supplier, evaluator, classes, isVisibleComment } =
		props
	//Functions
	const { onOpenLateralPanelForAddComments } = props

	const GetColorIconComments = (item) => {
		let existSomeCalificationSmallerThan = item.Scope > 0 && item.Scope <= 3
		if (existSomeCalificationSmallerThan && IsNullOrEmpty(item.Comments)) return classes.commentsIconDanger
		if (!IsNullOrEmpty(item.Comments)) return classes.containsCommnets
		else return classes.emptyComments
	}

	return (
		<>
			{items.map((item) => {
				item.SupplierId = supplier ? supplier.Id : ''
				item.IndirectQualificationId = indirectQualification.Id
				item.EvaluatorId = evaluator ? evaluator.Id : ''
				return (
					<>
						{item.IsRequiered &&
						canBeEditInStatusSendToEvaluate(indirectQualification.Status) &&
						isResponsibleAssigned ? (
							<>
								<TableCell align='center'>
									<div className={classes.gridConatiner}>
										<div>
											<TextfieldQualificationResponse item={item} />
										</div>
										<div>
											<IconButton
												onClick={() =>
													onOpenLateralPanelForAddComments(
														'SupplierRatingCommentsLateralPanelComponent',
														{ evaluator, item }
													)
												}
											>
												<CommentIcon className={GetColorIconComments(item)} />
											</IconButton>
										</div>
									</div>
								</TableCell>
							</>
						) : (
							<TableCell
								style={{
									backgroundColor: item.IsRequiered ? BACKGROUND_COLOR_GRAY_BIDDING : '',
								}}
								align='center'
							>
								<div className={classes.gridConatiner}>
									<div>{item.IsRequiered ? (item.Scope !== 0 ? item.Scope : '-') : ''}</div>
									<div>
										{item.IsRequiered && item.Scope !== 0 && isVisibleComment && (
											<IconButton
												onClick={() =>
													onOpenLateralPanelForAddComments(
														'SupplierRatingCommentsLateralPanelComponent',
														{ evaluator, item }
													)
												}
											>
												<CommentIcon className={GetColorIconComments(item)} />
											</IconButton>
										)}
									</div>
								</div>
							</TableCell>
						)}
					</>
				)
			})}
		</>
	)
}

const styles = (theme) => ({
	containsCommnets: {
		color: RELEASE_PLAN_COLOR_YELLOW,
	},
	emptyComments: {
		color: COLOR_STATUS_GRAY,
	},
	commentsIconDanger: {
		color: COLOR_STATUS_DANGER,
	},
	nameSupplier: {
		width: '200px',
	},
	responsibleName: {
		width: '250px',
	},
	inputTextfield: {
		height: 6,
		width: 8,
	},
	total: {
		color: 'white',
	},
	cellTable: {
		paddingRight: 2,
	},
	commentSupplier2: {
		width: '140px',
	},
	gridConatiner: {
		display: 'grid',
		gridTemplateRows: '1fr auto',
	},
})

ItemsContentTable.propTypes = {
	/**
	 * Determinate if is loading information from back end
	 * and see view skeleton
	 */
	isLoading: PropTypes.bool.isRequired,
}

ItemsContentTable.defaultProps = {
	isLoading: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(ItemsContentTable))
