/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserFiles,
     getAuthorizedRequestConfigurationUserParameters } from '../helpers/SessionHelper';

import { underScoreToTitleCase } from '../helpers/StringHelper';
import { CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS, CORRECTIVE_ACTION_DEVELOPMENT_NON_RECURRING_ACTIONS } from '../helpers/WorkFlowDocumentsType';

export const START_LOADING_SACS = "START_LOADING_SACS";
export const SET_SACS_LIST = "SET_SACS_LIST";
export const IS_LOADING_SAC = "IS_LOADING_SAC";
export const SET_SAC = "SET_SAC";
export const CHANGE_SACS_SORT_CRITERIA = "CHANGE_SACS_SORT_CRITERIA";
export const CHANGE_SACS_QUERY = "CHANGE_SACS_QUERY";
export const CHANGE_SACS_PAGINATION = "CHANGE_SACS_PAGINATION";
export const SET_SACS_COUNT = "SET_SACS_COUNT";
export const GET_INFORMATION_SAC = "GET_INFORMATION_SAC";
export const UPDATE_INVESTIGATION_LINES = "UPDATE_INVESTIGATION_LINES";
export const ADD_ACTION_IN_DOCUMENT = "ADD_ACTION_IN_DOCUMENT";
export const REMOVE_ACTION_OF_DOCUMENT = "REMOVE_ACTION_OF_DOCUMENT";
export const IS_LOADING_ACTIONS = "IS_LOADING_ACTIONS";
export const UPDATE_ACTION_IN_LIST = "UPDATE_ACTION_IN_LIST";
export const IS_SAVING = "IS_SAVING";
export const ADD_FILE_SAC_DEVELOPMENT = "ADD_FILE_SAC_DEVELOPMENT";
export const REMOVE_FILE_SAC_DEVELOPMENT = "REMOVE_FILE_SAC_DEVELOPMENT";

/**
 * Action start loading loadSacs
 * @param {*} isLoading 
 */
function isLoadingSacs(isLoading) {
    return {
        type: START_LOADING_SACS,
        isLoading: isLoading
    }
}

/**
 * Action to set loadSacs list 
 * @param {*} loadSacs 
 */
function setSacsList(sacs) {
    return {
        type: SET_SACS_LIST,
        sacs: sacs
    }
}

/**
 * Action to start load sac
 */
function startLoadSac(isLoading) {
    return {
        type: IS_LOADING_SAC,
        isLoadingSac: isLoading
    }
}

/**
 * Action to set sac
 * @param {*} sac 
 */
function setSac(sac) {
    return {
        type: SET_SAC,
        sac: sac
    }
}

/**
 * Action reducer to set the sac's counter
 *
 * @param {*} count
 * @returns
 */
function setSacsCount(count) {
    return {
        type: SET_SACS_COUNT,
        count
    }
}

/**
 * Action to update properties of investigation lines
 *
 * @param {*} investigationLines
 * @returns
 */
function updateInvestigationLines(investigationLines) {
    return {
        type: UPDATE_INVESTIGATION_LINES,
        investigationLines
    }
}

/**
 * Action to add non recurrence or containment actions in document
 *
 * @param {*} actionToAdd
 * @param {*} documentType
 * @returns
 */
function addActionInDocument(actionToAdd, documentType) {
    return {
        type: ADD_ACTION_IN_DOCUMENT,
        actionToAdd,
        documentType
    }
}

/**
 * Action to remove non recurrence or containment action of document
 *
 * @param {*} tokenToRemove
 * @param {*} documentType
 * @returns
 */
function removeActionOfDocument(tokenToRemove, documentType) {
    return {
        type: REMOVE_ACTION_OF_DOCUMENT,
        tokenToRemove,
        documentType
    }
}

/**
 * Action to set flag is loading actions list
 *
 * @param {*} isLoadingActions
 * @returns
 */
function isLoadingActions(isLoadingActions) {
    return {
        type: IS_LOADING_ACTIONS,
        isLoadingActions
    }
}

/**
 * Action to update action in list
 *
 * @param {*} actionToken
 * @param {*} actionToUpdate
 * @param {*} documentType
 * @returns
 */
function updateAction(actionToken, actionToUpdate, documentType) {
    return {
        type: UPDATE_ACTION_IN_LIST,
        actionToken,
        actionToUpdate,
        documentType
    }
}

/**
 * Add file to state
 *
 * @param {*} files
 * @returns
 */
function addFile(files) {
    return {
        type: ADD_FILE_SAC_DEVELOPMENT,
        files
    }
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName) {
    return {
        type: REMOVE_FILE_SAC_DEVELOPMENT,
        fileName
    }
}

/**
 * Action to is saving sac
 * @param {*} isSaving 
 */
function isSaving(isSaving) {
    return {
        type: IS_SAVING,
        isSaving: isSaving
    }
}

/**
 * Get all loadSacs by company from backend 
 * DONE
 */
export function loadSacsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {
        dispatch(isLoadingSacs(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/actions`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSacsList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingSacs(false));
                console.error("Error getting the sacs by company list", error.response)
            })
    }
}


/**
 * Get sac's count
 * DONE
 * @export
 * @returns
 */
export function getSacsCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().loadSacs ? getState().loadSacs.get('loadSacsCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSacsCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the sacs's count", error.response)
            })
    }
}


/**
 * Get and set the sac in the estate from read from backend
 * @param {*} token 
 */
export function loadSacFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(startLoadSac(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/correctiveaction/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSac(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load sac", error.response)
                dispatch(startLoadSac(false))
                return Promise.reject();
            })
    }
}

/**
 * Action to create corrective action 
 */
export function createCorrectiveAction(supplier, comment) {
    return (dispatch, getState) => {

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let data = {
            CompanyToken: selectedCompany.get('token'),
            CompanyIdentifier: selectedCompany.get('get_company_identifier'),
            DocumentIdentifier: selectedCompany.get('document_identifier'),
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            SupplierToken: supplier.token,
            SupplierName: supplier.name,
            SupplierCode: supplier.code,
            Comments: comment,
        }

        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                console.error("Error creating corrective action", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to send notification to suplier
 *
 * @export
 * @param {*} sacToken
 * @returns
 */
export function sendSacNotificationToSupplier(sacToken) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/sendcorrective/${sacToken}`;
        return axios.post(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSac(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error sending sac notification to supplier", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to add containment action to sac
 *
 * @export
 * @param {*} sacToken
 * @param {*} actionData
 * @returns
 */
export function addContainmentAction(sacToken, actionData) {
    return (dispatch, getState) => {
        dispatch(isLoadingActions(true));
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUserFiles(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/actiontosac/${sacToken}`;
        let formData = new FormData();
        formData.set('UserName', user.profile.preferred_username);
        formData.set('CreatedBy', user.profile.name);
        formData.set('Description', actionData.description);
        formData.set('Comments', actionData.comments);
        formData.set('ActionType', actionData.action_type);
        formData.set('EndDate', actionData.end_date_time.format("DD/MM/YYYY"));
        formData.set('IsResolved', actionData.is_resolved);
        for (var index = 0; index < actionData.annexe.length; index++) {
            formData.append('file', actionData.annexe[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addActionInDocument(serverResponse.data, CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS))
                    dispatch(isLoadingActions(false));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error adding containment action to sac", error.response)
                dispatch(isLoadingActions(false));
                return Promise.reject();
            })
    }
}

/**
 * Action to update containment action to sac
 *
 * @export
 * @param {*} sacToken
 * @param {*} actionData
 * @returns
 */
export function editContainmentAction(sacToken, itemToken, actionData) {
    return (dispatch, getState) => {
        dispatch(isLoadingActions(true));
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUserFiles(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/actiontosac/update/${sacToken}`;
        let formData = new FormData();
        formData.set('ItemToken', itemToken);
        formData.set('UserName', user.profile.preferred_username);
        formData.set('CreatedBy', user.profile.name);
        formData.set('Description', actionData.description);
        formData.set('Comments', actionData.comments);
        formData.set('ActionType', actionData.action_type);
        formData.set('EndDate', actionData.end_date_time.format("DD/MM/YYYY"));
        formData.set('IsResolved', actionData.is_resolved);
        for (var index = 0; index < actionData.annexe.length; index++) {
            formData.append('FileNames', actionData.annexe[index].name);
            formData.append('file', actionData.annexe[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateAction(itemToken, serverResponse.data, CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS))
                    dispatch(isLoadingActions(false));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error adding containment action to sac", error.response)
                dispatch(isLoadingActions(false));
                return Promise.reject();
            })
    }
}

/**
 * Action to add non recurring action to sac
 *
 * @export
 * @param {*} sacToken
 * @param {*} actionData
 * @returns
 */
export function addNonRecurringAction(sacToken, actionData) {
    return (dispatch, getState) => {
        dispatch(isLoadingActions(true));
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUserFiles(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/norecurring/${sacToken}`;
        let formData = new FormData();
        formData.set('UserName', user.profile.preferred_username);
        formData.set('CreatedBy', user.profile.name);
        formData.set('Description', actionData.description);
        formData.set('Comments', actionData.comments);
        formData.set('ActionType', actionData.action_type);
        formData.set('EndDate', actionData.end_date_time.format("DD/MM/YYYY"));
        formData.set('IsResolved', actionData.is_resolved);
        for (var index = 0; index < actionData.annexe.length; index++) {
            formData.append('file', actionData.annexe[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addActionInDocument(serverResponse.data, CORRECTIVE_ACTION_DEVELOPMENT_NON_RECURRING_ACTIONS))
                    dispatch(isLoadingActions(false));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error adding non recurring action to sac", error.response)
                dispatch(isLoadingActions(false));
                return Promise.reject();
            })
    }
}

/**
 * Action to update containment action to sac
 *
 * @export
 * @param {*} sacToken
 * @param {*} actionData
 * @returns
 */
export function editNonRecurringAction(sacToken, itemToken, actionData) {
    return (dispatch, getState) => {
        dispatch(isLoadingActions(true));
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUserFiles(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/norecurring/update/${sacToken}`;
        let formData = new FormData();
        formData.set('ItemToken', itemToken);
        formData.set('UserName', user.profile.preferred_username);
        formData.set('CreatedBy', user.profile.name);
        formData.set('Description', actionData.description);
        formData.set('Comments', actionData.comments);
        formData.set('ActionType', actionData.action_type);
        formData.set('EndDate', actionData.end_date_time.format("DD/MM/YYYY"));
        formData.set('IsResolved', actionData.is_resolved);
        for (var index = 0; index < actionData.annexe.length; index++) {
            formData.append('FileNames', actionData.annexe[index].name);
            formData.append('file', actionData.annexe[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateAction(itemToken, serverResponse.data, CORRECTIVE_ACTION_DEVELOPMENT_NON_RECURRING_ACTIONS))
                    dispatch(isLoadingActions(false));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error adding containment action to sac", error.response)
                dispatch(isLoadingActions(false));
                return Promise.reject();
            })
    }
}

/**
 * Action to update property or properties in  investigation lines
 *
 * @export
 * @param {*} sacToken
 * @param {*} propertyData
 * @returns
 */
export function updatePropertyInvestigationLines(sacToken, propertyData) {
    return (dispatch, getState) => {
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/${sacToken}/linesinvestigation`;
        let data = {
            [underScoreToTitleCase(propertyData.id)]: propertyData.value
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateInvestigationLines(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error updating data in investigaton lines", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to delete item of actions list
 *
 * @export
 * @param {*} itemToken
 * @param {*} documentType
 * @returns
 */
export function deleteAction(itemToken, documentType) {
    return (dispatch, getState) => {
        dispatch(isLoadingActions(true));
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/${itemToken}/correctiveaction`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeActionOfDocument(itemToken, documentType))
                    dispatch(isLoadingActions(false));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error removing action to sac", error.response)
                dispatch(isLoadingActions(false));
                return Promise.reject();
            })
    }
}

/**
 * Action to send notification to development engineer
 *
 * @export
 * @param {*} sacToken
 * @returns
 */
export function sendSacNotificationToDevelopment(sacToken) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/${sacToken}/sendcorrective`;
        return axios.post(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSac(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error sending sac notification to supplier", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to accept or reject action in list
 *
 * @export
 * @param {*} comment
 * @param {*} tokenAction
 * @param {*} isAccepted
 * @param {*} documentType
 * @returns
 */
export function acceptRejectActionInList(comment, tokenAction, isAccepted, documentType) {
    return (dispatch, getState) => {
        dispatch(isLoadingActions(true));
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/${tokenAction}/actiontosac`;
        let data = {
            UserName: user.profile.preferred_username,
            InspectorComments: comment,
            IsAccepted: isAccepted
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateAction(tokenAction, serverResponse.data, documentType))
                }
            })
            .catch((error) => {
                console.error("Error accepting action by Engineer", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to accept or reject Investigation lines
 *
 * @export
 * @param {*} comment
 * @param {*} sacToken
 * @param {*} isAccepted
 * @returns
 */
export function acceptRejectInvestigationLines(comment, sacToken, isAccepted) {
    return (dispatch, getState) => {
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/${sacToken}/acceptorreject`;
        let data = {
            UserName: user.profile.preferred_username,
            RejectReason: comment,
            IsAccepted: isAccepted
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateInvestigationLines(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error accepting action by Engineer", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to upload files evidence
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFilesEvidence(files) {
    return (dispatch, getState) => {
        let user = getState().oidc.user;
        let tokenSac = getState().sacs.get('sacInformation').get('token');
        let configuration = getAuthorizedRequestConfigurationUserFiles(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/upload/${tokenSac}`;
        let formData = new FormData();
        for (var index = 0; index <= files.length; index++) {
            formData.append('file', files[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addFile(serverResponse.data))
                    return Promise.resolve('Ok');
                }
            })
            .catch((error) => {
                console.error("Error to upload evidence file(s) from failure report quality", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} fileName
 * @returns
 */
export function removeFilesEvidence(fileName) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let tokenSac = getState().sacs.get('sacInformation').get('token');
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/remove/${tokenSac}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeFile(fileName))
                    return Promise.resolve('Ok');
                }
            })
            .catch((error) => {
                console.error("Error to remove evidence file(s)", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to resend notification to supplier 
 *
 * @export
 * @param {*} comments
 * @returns
 */
export function resendSacNotificationToSupplier(comments) {
    return (dispatch, getState) => {
        dispatch(isSaving(true))
        let tokenSac = getState().sacs.get('sacInformation').get('token');
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/resendcorrective/supplier`;
        let data = {
            SacToken: tokenSac,
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            Comments: comments
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSac(serverResponse.data))
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to remove evidence file(s)", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to request modifications to responsible
 *
 * @export
 * @param {*} comments
 * @returns
 */
export function sacRequestResponsibleModification(comments) {
    return (dispatch, getState) => {
        dispatch(isSaving(true))
        let tokenSac = getState().sacs.get('sacInformation').get('token');
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/resendcorrective/responsible`;
        let data = {
            SacToken: tokenSac,
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            Comments: comments
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSac(serverResponse.data))
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))

                console.error("Error to remove evidence file(s)", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to request authorization of suppliers development administrator
 *
 * @export
 * @returns
 */
export function sacRequestAdministratorAuthorization() {
    return (dispatch, getState) => {
        dispatch(isSaving(true))
        let tokenSac = getState().sacs.get('sacInformation').get('token');
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/requireauthorization/${tokenSac}`;
        return axios.put(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSac(serverResponse.data))
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))

                console.error("Error to remove evidence file(s)", error.response)
                return Promise.reject();
            })
    }

}

/**
 * Action to close and accept Sac
 *
 * @export
 * @returns
 */
export function sacCloseAndAccept() {
    return (dispatch, getState) => {
        dispatch(isSaving(true))

        let tokenSac = getState().sacs.get('sacInformation').get('token');
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/${tokenSac}/close`;
        return axios.post(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSac(serverResponse.data))
                }
                return Promise.resolve();
            })
            .catch((error) => {
                dispatch(isSaving(false))

                console.error("Error to remove evidence file(s)", error.response)
                return Promise.reject();
            })
    }

}

/**
 * Action delete a sac in status in draft
 * @param {*} token 
 */
export function removeSacsInDraftFromBackend(token) {
    return function (dispatch, getState) {
        dispatch(isSaving(true))

        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/${token}/removedraft`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve('Ok');
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to remove sacs", error.response)
                return Promise.reject();
            })
    }
}

/**
 *Action to download report sac's development by range date
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadSacDevReportByRangeDate(startDate, endDate) {
    return (dispatch, getState) => {
        dispatch(startLoadSac(true));
        let companyToken = getState().profile.get("selectedCompany").get('token');
        let data = {
            CompanyToken: companyToken,
            StartDate: startDate,
            EndDate: endDate
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sacs/downloadSacsByRangeDate`;
        return axios.post(endPoint, data, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(startLoadSac(false));
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reportes_de_sacs_desarrollo.xlsx";
                    a.click();
                }
            })
            .catch((error) => {
                dispatch(startLoadSac(false));
                console.error("Error downloading sac's development report by month", error.response)
                return Promise.reject();
            })
    }
}