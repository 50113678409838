
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';
import Toaster from '../../common/Toaster';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import Evidence from '../../common/Evidence';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import { GetAnalysisReportType } from '../../../store/helpers/SelectOptions';



/**
 * Dialog to add common item in workflow
 */
class DialogAnalysisReport extends Component {

    /**
   *Creates an instance of DialogAnalysisReport.
   * @param {*} props
   * @memberof DialogAnalysisReport
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            files: [],
            failureSelected: null,
            folioAnalysis: null,
            comments: null
        }
    }

    /**
     * canBeSaved
     */
    canBeSaved() {
        if (!this.state.failureSelected) return false;
        if (!this.state.folioAnalysis) return false;
        if (!this.state.comments) return false;
        if (this.state.files.length === 0) return false;
        return true;
    }

    /**
 * Update property in state
 *
 * @param {*} property
 * @param {*} value
 * @memberof FormView
 */
    updateProperty(property, value) {
        this.setState({
            [property]: value
        })
    }

    /**
     * Render textFiel Enabled to edit
     *
     * @param {*} textFieldId
     * @param {*} translationTextFieldId
     * @param {boolean} [isMultiple=false]
     * @param {string} [type='text']
     * @returns
     * @memberof FormView
     */
    renderTextField(textFieldId, translationTextFieldId, isMultiple = false, type = 'text', isDisabled = false) {
        const { classes } = this.props;
        return (
            <div >
                <TextField
                    disabled={isDisabled}
                    id={textFieldId}
                    fullWidth
                    value={this.state[textFieldId] ? this.state[textFieldId] : ''}
                    label={<Translate id={translationTextFieldId} />}
                    onChange={(event) => { this.updateProperty(textFieldId, event.target.value) }}
                    className={isMultiple ? classes.textAreaField : classes.textField}
                    type={type}
                    //Only textArea
                    multiline={isMultiple}
                    rows={isMultiple ? "3" : "1"}
                    variant={isMultiple ? "outlined" : 'standard'}
                />
            </div>
        )
    }

    /**
     * On seleted part number
     */
    onSelected(propertyId, value) {
        this.setState({ [propertyId]: value });
    }

    /**
 * On remove files of evidence 
 *
 * @param {*} event
 * @memberof FormView
 */
    onDeleteFile(fileName) {
        let newFiles = this.state.files.filter(file => {
            return file.name !== fileName
        })
        this.setState({ files: newFiles })
        return Promise.resolve('Ok');
    }

    /**
     * On change files of evidence 
     *
     * @param {*} event
     * @memberof FormView
     */
    onChangeFile(files) {
        this.setState({
            files,
        })
        return Promise.resolve('Ok');
    }

    /**
     * Save data of analisys report
     */
    onSaveAnalisysReport() {
        if (this.props.createAnalysisReport)
            this.props.createAnalysisReport(this.state)
                .then(response => {
                    this.setState({
                        showToaster: true,
                        toasterVariant: "success",
                        toasterMessage: <Translate id="common.saveCorrectly" />
                    })
                }).catch(() => {
                    this.setState({
                        showToaster: true,
                        toasterMessage: <Translate id="common.errorToSave" />,
                        toasterVariant: "error",
                    }
                    )
                });
    }


    /**
     * On change analysis report type
     *
     * @param {*} option
     * @memberof onChangeAnalysisReport
     */
    onChangeAnalysisReport(option) {
        this.setState({
            failureSelected: option.value
        })
    }

    /**
     * Dialog information
     */
    render() {
        const { classes } = this.props;
        let analysisReport = GetAnalysisReportType();
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {this.props.title}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={() => this.onSaveAnalisysReport()}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.canBeSaved()}
                    />
                }>

                <Grid container spacing={24}>
                    <Grid item xs={12} >
                        <div className={classes.textField} >
                            {this.renderTextField('folioAnalysis', 'moduleGuarantee.reportGuarantee.folioAnalysis', false)}
                        </div>
                    </Grid>
                    <Grid item xs={12} >
                        <div className={classes.textField} >
                            <Typography variant="subtitle1" gutterBottom><Translate id="moduleGuarantee.reportGuarantee.failure"/></Typography>
                            <Select
                                fullWidth
                                defaultValue={analysisReport[0]}
                                options={analysisReport}
                                onChange={this.onChangeAnalysisReport}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10 })
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} >
                        <div className={classes.textField} >
                            {this.renderTextField('comments', 'moduleGuarantee.reportGuarantee.Comments', true)}
                        </div>
                    </Grid>
                    <Grid item xs={12} >

                        <Evidence
                            // isDisabled={this.props.rawMaterial.is_closed || !IsCurrentActiveDirectoryUser(this.props.currentUser)}
                            files={this.state.files}
                            baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                            removeFiles={(event) => this.onDeleteFile(event)}
                            uploadFiles={(event) => this.onChangeFile(event)}
                        // files={this.props.item.attachments}

                        />
                    </Grid>
                </Grid>
                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        // flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginRight: theme.spacing.unit,
    },
    textAreaField: {
        marginRight: theme.spacing.unit,
    },
    marginSelect: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(DialogAnalysisReport));