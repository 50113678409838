import React from 'react';
import PropTypes from 'prop-types';

/**Import components section*/
import {BACKGROUND_COLOR_GRAY_BIDDING } from '../../../../store/helpers/StatusColorConstants';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


const HeaderResultPdf = props => {
    const { topics } = props
    return (
        <View style={styles.cardContent}>
        {topics.map((topic) => (    
            <View key={topic.Id} style={styles.content}>
            <View style={styles.display}>
                <View style={styles.percent}>
                    <Text>{topic.PercentageForSupplier}%</Text>
                </View>
                <View style={[styles.line, {backgroundColor: topic.ColorTopic}]}></View>
                <View style={styles.title}>
                    <Text style={{color: topic.ColorTopic}}>
                        {topic.ShortName}
                    </Text>
                </View>
                </View>
            </View>
            ))}
        </View>
    );
};

// Create styles
const styles = StyleSheet.create({
    cardContent:{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: 4,
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
    },
    content:{
        width: '22%',
		flexShrink: 'auto',
		flexBasis: 'auto',
        marginLeft: 10,
        textAlign: 'center',
        backgroundColor: 'white',
        border: '15px',
    },
    display:{
      display: 'flex',
      textOverflow: 'initial'
    },
    line: {
        width: '90%',
        marginLeft: '5%',
        height: 4
    },
    title: {
        flex: 3,
		flexShrink: 1,
		flexBasis: 0,
        marginTop: '10px',
        marginBottom: '10px',
        fontSize: 8,
    },
    percent: {
        flex: 2,
		flexShrink: 1,
		flexBasis: 0,
        marginTop: '10px',
        marginBottom: '10px',
    }
  });

HeaderResultPdf.propTypes = {
    topics:PropTypes.array
};
  
HeaderResultPdf.defaultProps = {
    topics: [],
};

export default HeaderResultPdf;