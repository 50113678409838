import React from 'react';
import { Translate } from "react-localize-redux";

import {
    SustainabilityStatus,
    SustainabilityActionStatus
} from './AppConstants'

import {
    COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER,
    COLOR_STATUS_WARNING, COLOR_STATUS_INFO,
    COLOR_QUESTIONNAIRE_CHERRY
} from '../helpers/StatusColorConstants'

import { IsUserSupplierAdministrator } from './SessionHelper';
import { canBeModuleSustainabilitySupplier, canBeModuleSustantabilityQuestionnaire } from './RolesHelper'
import { IsNullOrEmpty } from './StringHelper.js';

export const descriptionStatusQualityCertificate = (status) => {
    switch (Number(status)) {
        case 0:
            return 'Borrador'
        case 1:
            return 'Enviado a validación'
        case 2:
            return 'Finalizado'
        case 3:
            return 'Reenviado'
        case 4:
            return 'Cancelado'
        default:
            return ''
    }
}

export const backgroundColorTableStatusQualityCertificate = (status) => {
    switch (Number(status)) {
        case 0:
            return "secondary"
        case 1:
            return "warning"
        case 3:
            return "primary"
        case 2:
            return "success"
        case 4:
            return "danger"
        default:
            return ""
    }
};

export const descriptionStatus = (status, translate) => {
    switch (Number(status)) {
        case SustainabilityStatus.SEND_SUPPLIER:
            return translate("sustainability.status.sendSupplier")
        case SustainabilityStatus.RESPONSE_SUPPLIER:
            return translate("sustainability.status.responseSupplier")
        case SustainabilityStatus.PUBLISH_RESULTS:
            return translate("sustainability.status.publishResults")
        case SustainabilityStatus.INITIAL_ACTIONS_PLAN:
            return translate("sustainability.status.generatedActionsPlan")
        case SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN:
            return translate("sustainability.status.inProcessActions")
        case SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER:
            return translate("sustainability.status.actionsResponseSupplier")
        case SustainabilityStatus.REEVALUATE_QUETIOONAIRE:
            return translate("sustainability.status.questionnareReevaluate")
        case SustainabilityStatus.PROGRAM_AUDIT:
            return translate("sustainability.status.programAuditInProces")
        case SustainabilityStatus.AUDIT_RESPONSE:
            return translate("sustainability.status.responseAudit")
        case SustainabilityStatus.AUDIT_IN_PROCESS:
            return translate("sustainability.status.inProcessAudit")
        case SustainabilityStatus.GENERATED_FINDINGS:
            return translate("sustainability.status.findingGenerated")
        case SustainabilityStatus.IN_PROCESS_FINFINGS:
            return translate("sustainability.status.inProcessFinnings")
        case SustainabilityStatus.FIDDINGS_RESPONSE:
            return translate("sustainability.status.finningsResponseSupplier")
        case SustainabilityStatus.REEVALUATE_AUDIT:
            return translate("sustainability.status.auditReevaluate")
        case SustainabilityStatus.COMPLETED:
            return translate("sustainability.status.completed")
        case SustainabilityStatus.CANCELLED:
            return translate("sustainability.status.cancelled")
        case SustainabilityStatus.REACTIVATE_FINDING:
            return translate("sustainability.status.statusReactivate")
        default:
            return ""
    }
};


export const backgroundColorTableStatus = (status) => {
    switch (Number(status)) {
        case SustainabilityStatus.DRAFT:
            return "secondary"
        case SustainabilityStatus.SEND_SUPPLIER:
        case SustainabilityStatus.RESPONSE_SUPPLIER:
        case SustainabilityStatus.PUBLISH_RESULTS:
        case SustainabilityStatus.INITIAL_ACTIONS_PLAN:
        case SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN:
        case SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER:
        case SustainabilityStatus.REEVALUATE_QUETIOONAIRE:
            return "warning"
        case SustainabilityStatus.AUDIT_RESPONSE:
        case SustainabilityStatus.PROGRAM_AUDIT:
        case SustainabilityStatus.GENERATED_FINDINGS:
        case SustainabilityStatus.IN_PROCESS_FINFINGS:
        case SustainabilityStatus.FIDDINGS_RESPONSE:
        case SustainabilityStatus.REEVALUATE_AUDIT:
        case SustainabilityStatus.AUDIT_IN_PROCESS:
            return "primary"
        case SustainabilityStatus.COMPLETED:
            return "success"
        case SustainabilityStatus.CANCELLED:
            return "danger"
        default:
            return ""
    }
};

export const getColorStatusQuestionnaire = (status) => {
    switch (Number(status)) {
        case SustainabilityStatus.DRAFT:
        case SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN:
        case SustainabilityStatus.SEND_SUPPLIER:
        case SustainabilityStatus.PUBLISH_RESULTS:
        case SustainabilityStatus.INITIAL_ACTIONS_PLAN:
        case SustainabilityStatus.RESPONSE_SUPPLIER:
        case SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER:
        case SustainabilityStatus.REEVALUATE_QUETIOONAIRE:
            return COLOR_STATUS_WARNING
        case SustainabilityStatus.AUDIT_RESPONSE:
        case SustainabilityStatus.PROGRAM_AUDIT:
        case SustainabilityStatus.GENERATED_FINDINGS:
        case SustainabilityStatus.IN_PROCESS_FINFINGS:
        case SustainabilityStatus.FIDDINGS_RESPONSE:
        case SustainabilityStatus.REEVALUATE_AUDIT:
        case SustainabilityStatus.AUDIT_IN_PROCESS:
            return COLOR_STATUS_INFO
        case SustainabilityStatus.COMPLETED:
            return COLOR_STATUS_SUCCESS
        case SustainabilityStatus.CANCELLED:
            return COLOR_STATUS_DANGER
        default:
            return null
    }
};

/**
 * Action to determine if the user supplier is administrator on is role supplier  Tecate14#*.
 * @param {*} sustainability 
 * @param {*} userRolesByCompany 
 * @param {*} userProfile 
 */
export function canBeEditSupplier(sustainability, userRolesByCompany, userProfile) {
    if (userRolesByCompany === undefined)
        return false
    if (userProfile === null || userProfile === undefined)
        return false
    if (userRolesByCompany.length === 0)
        return false
    if (sustainability === null || sustainability === undefined)
        return false
    let supplierAdmin = IsUserSupplierAdministrator(userProfile);
    return (supplierAdmin || canBeModuleSustainabilitySupplier(userRolesByCompany))
}

/**
 * Determne can be edti responsible in plant
 * @param {*} sustainability 
 * @param {*} userRolesByCompany 
 * @param {*} userProfile 
 */
export function canBeResponsible(sustainability, userRolesByCompany, userProfile) {
    if (userRolesByCompany === undefined)
        return false
    if (userProfile === null || userProfile === undefined)
        return false
    if (userRolesByCompany.length === 0)
        return false
    if (sustainability === null || sustainability === undefined)
        return false
    return (canBeModuleSustantabilityQuestionnaire(userRolesByCompany))
}

/**
 * status action 
 * @param {*} status 
 * @param {*} translate 
 */
export const descriptionStatusAction = (status, translate) => {
    switch (Number(status)) {
        case SustainabilityActionStatus.PENDIENT:
            return translate("sustainability.status.pendient")
        case SustainabilityActionStatus.OPEN:
            return translate("sustainability.status.open")
        case SustainabilityActionStatus.PROCESS:
            return translate("sustainability.status.process")
        case SustainabilityActionStatus.ACCEPTED:
            return translate("sustainability.status.accepted")
        case SustainabilityActionStatus.CLOSE:
            return translate("sustainability.status.close")
        default:
            return ""
    }
};

export const getColorStatusAction = (status) => {
    switch (Number(status)) {
        case SustainabilityActionStatus.PENDIENT:
            return "secondary"
        case SustainabilityActionStatus.OPEN:
            return "warning"
        case SustainabilityActionStatus.PROCESS:
            return "primary"
        case SustainabilityActionStatus.ACCEPTED:
        case SustainabilityActionStatus.CLOSE:
            return "success"
        default:
            return ""
    }
};

export const getColorText = (status) => {
    switch (Number(status)) {
        case SustainabilityActionStatus.PENDIENT:
            return COLOR_STATUS_INFO
        case SustainabilityActionStatus.OPEN:
            return COLOR_STATUS_WARNING
        case SustainabilityActionStatus.PROCESS:
            return COLOR_QUESTIONNAIRE_CHERRY
        case SustainabilityActionStatus.ACCEPTED:
        case SustainabilityActionStatus.CLOSE:
            return COLOR_STATUS_SUCCESS
        default:
            return ""
    }
};

export const getFiltersFolios = (query) => {
    if (IsNullOrEmpty(query))
        return []

    let filters = [
        {
            Property: "SupplierName",
            Values: [query],
            CriteriaType: 5
        },
    ]

    return filters
}

/**
   * Get title to chage status
   * @returns
   */
export function onTitleStatusDialog(status) {
    switch (status) {
        case SustainabilityStatus.RESPONSE_SUPPLIER:
        case SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER:
            return "sustainability.sendQuestionnaireClient";
        case SustainabilityStatus.PUBLISH_RESULTS:
            return "sustainability.status.publishResultQuestionniare";
        case SustainabilityStatus.INITIAL_ACTIONS_PLAN:
            return "sustainability.titleGenreateActions";
        case SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN:
            return "sustainability.status.sendActionsPlan";
        case SustainabilityStatus.REEVALUATE_QUETIOONAIRE:
            return "sustainability.status.reevaluateQuestionniare";
        case SustainabilityStatus.AUDIT_RESPONSE:
            return "sustainability.status.evaluateAudit";
        case SustainabilityStatus.GENERATED_FINDINGS:
            return "sustainability.status.generatedFindings";
        case SustainabilityStatus.IN_PROCESS_FINFINGS:
        case SustainabilityStatus.FIDDINGS_RESPONSE:
            return "sustainability.status.sendFinnings";
        case SustainabilityStatus.REEVALUATE_AUDIT:
            return "sustainability.status.revaluateAudit";
        case SustainabilityStatus.COMPLETED:
            return "sustainability.status.completedAudit";
        case SustainabilityStatus.CANCELLED:
            return "sustainability.status.cancelFolio";
        case SustainabilityStatus.PROGRAM_AUDIT:
            return "sustainability.status.programAudit";
        case SustainabilityStatus.REMOVE:
            return "sustainability.removeQueslionaire";
        case SustainabilityStatus.REACTIVATE_FINDING:
            return "sustainability.status.reactivateFolio";
        default:
            return "";
    }
};

/**
   * Get message to show in dialog
   * @returns
   */
export function onMessageStatusDialog(status) {
    switch (status) {
        case SustainabilityStatus.RESPONSE_SUPPLIER:
            return "sustainability.messageToSendClient";
        case SustainabilityStatus.PUBLISH_RESULTS:
            return "sustainability.messagePublishResults";
        case SustainabilityStatus.INITIAL_ACTIONS_PLAN:
            return "sustainability.messageGenerateActionPlan";
        case SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN:
            return "sustainability.messageSendActionsPlan";
        case SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER:
            return "sustainability.messageSendActionsPlanSupplier";
        case SustainabilityStatus.REEVALUATE_QUETIOONAIRE:
            return "sustainability.messageReevaluateQuestionniare";
        case SustainabilityStatus.AUDIT_RESPONSE:
            return "sustainability.messageEvaluateAudit";
        case SustainabilityStatus.GENERATED_FINDINGS:
            return "sustainability.messageGeneratedFiddings";
        case SustainabilityStatus.IN_PROCESS_FINFINGS:
            return "sustainability.messageSendFiddings";
        case SustainabilityStatus.FIDDINGS_RESPONSE:
            return "sustainability.messageSendFiddingsSupplier";
        case SustainabilityStatus.REEVALUATE_AUDIT:
            return "sustainability.messageReevaluateAudit";
        case SustainabilityStatus.COMPLETED:
            return "sustainability.messageCompleteQuestionnaire";
        case SustainabilityStatus.CANCELLED:
            return "sustainability.messageCancelQuestionnaire";
        case SustainabilityStatus.REMOVE:
            return "sustainability.messageRemoveQuestionnaire";
        default:
            return "sustainability.messageReactivateQuestionnaire";
    }
};

/**
 * Get options to show
 * @param {*} canChangeResponsibleStatus 
 * @param {*} canSendSupplier 
 * @param {*} status 
 * @param {*} onOpenChangeStatus 
 * @param {*} CanBeSendCloseFindings 
 * @param {*} CanBeSendActionPlan 
 * @param {*} CanBeGeneratedActionsPlan 
 * @param {*} CanPublishResult 
 * @param {*} onOpenDialogDuplicate 
 * @param {*} onOpenProgramAudit 
 * @returns 
 */
export function AvailableOperationsConfiguration(canChangeResponsibleStatus, canSendSupplier, status, onOpenChangeStatus, CanBeSendCloseFindings,
    CanBeSendActionPlan, CanBeGeneratedActionsPlan, CanPublishResult, onOpenDialogDuplicate, onOpenProgramAudit) {
    let columns = [];
    if (canSendSupplier && status === SustainabilityStatus.SEND_SUPPLIER) {
        columns.push({
            value: SustainabilityStatus.RESPONSE_SUPPLIER,
            title: <Translate id={onTitleStatusDialog(SustainabilityStatus.RESPONSE_SUPPLIER)} />,
            onClick: () => onOpenChangeStatus(SustainabilityStatus.RESPONSE_SUPPLIER),
            isIconMaterial: true,
            description: <Translate id={onMessageStatusDialog(SustainabilityStatus.RESPONSE_SUPPLIER)} />,
        })
    }

    if (canChangeResponsibleStatus && status === SustainabilityStatus.SEND_SUPPLIER) {
        columns.push({
            value: SustainabilityStatus.CANCELLED,
            title: <Translate id={onTitleStatusDialog(SustainabilityStatus.CANCELLED)} />,
            onClick: () => onOpenChangeStatus(SustainabilityStatus.CANCELLED),
            isIconMaterial: true,
            description: <Translate id={onMessageStatusDialog(SustainabilityStatus.CANCELLED)} />,
        })
    }

    /*******start section canChangeResponsibleStatus */
    if (canChangeResponsibleStatus && status !== SustainabilityStatus.CANCELLED && status !== SustainabilityStatus.AUDIT_IN_PROCESS
        && status !== SustainabilityStatus.SEND_SUPPLIER && status !== SustainabilityStatus.COMPLETED) {

        if (CanPublishResult) {
            columns.push({
                value: SustainabilityStatus.PUBLISH_RESULTS,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.PUBLISH_RESULTS)} />,
                onClick: () => onOpenChangeStatus(SustainabilityStatus.PUBLISH_RESULTS),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.PUBLISH_RESULTS)} />,
            })
            columns.push({
                title: <Translate id="sustainability.status.duplicateFoli" />,
                onClick: () => onOpenDialogDuplicate(),
                isIconMaterial: true,
                description: <Translate id="sustainability.messageDuplicateFoli" />,
            })
        }

        if (CanBeGeneratedActionsPlan) {
            columns.push({
                value: SustainabilityStatus.INITIAL_ACTIONS_PLAN,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.INITIAL_ACTIONS_PLAN)} />,
                onClick: () => onOpenChangeStatus(SustainabilityStatus.INITIAL_ACTIONS_PLAN),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.INITIAL_ACTIONS_PLAN)} />,
            })
            columns.push({
                title: <Translate id="sustainability.status.duplicateFoli" />,
                onClick: () => onOpenDialogDuplicate(),
                isIconMaterial: true,
                description: <Translate id="sustainability.messageDuplicateFoli" />,
            })
        }

        if (CanBeSendActionPlan) {
            columns.push({
                value: SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN)} />,
                onClick: () => onOpenChangeStatus(SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN)} />,
            })
            columns.push({
                title: <Translate id="sustainability.status.duplicateFoli" />,
                onClick: () => onOpenDialogDuplicate(),
                isIconMaterial: true,
                description: <Translate id="sustainability.messageDuplicateFoli" />,
            })
        }

        if (status === SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER) {
            columns.push({
                value: SustainabilityStatus.REEVALUATE_QUETIOONAIRE,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.REEVALUATE_QUETIOONAIRE)} />,
                onClick: () => onOpenChangeStatus(SustainabilityStatus.REEVALUATE_QUETIOONAIRE),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.REEVALUATE_QUETIOONAIRE)} />,
            })
            columns.push({
                title: <Translate id="sustainability.status.duplicateFoli" />,
                onClick: () => onOpenDialogDuplicate(),
                isIconMaterial: true,
                description: <Translate id="sustainability.messageDuplicateFoli" />,
            })
        }

        if (status === SustainabilityStatus.REEVALUATE_QUETIOONAIRE) {
            columns.push({
                value: SustainabilityStatus.PROGRAM_AUDIT,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.PROGRAM_AUDIT)} />,
                onClick: () => onOpenProgramAudit(SustainabilityStatus.PROGRAM_AUDIT),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.PROGRAM_AUDIT)} />,
            })
            columns.push({
                value: SustainabilityStatus.REEVALUATE_QUETIOONAIRE,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.REEVALUATE_QUETIOONAIRE)} />,
                onClick: () => onOpenChangeStatus(SustainabilityStatus.REEVALUATE_QUETIOONAIRE),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.REEVALUATE_QUETIOONAIRE)} />,
            })
            columns.push({
                title: <Translate id="sustainability.status.duplicateFoli" />,
                onClick: () => onOpenDialogDuplicate(),
                isIconMaterial: true,
                description: <Translate id="sustainability.messageDuplicateFoli" />,
            })
        }

        if (status === SustainabilityStatus.PROGRAM_AUDIT) {
            columns.push({
                value: SustainabilityStatus.AUDIT_RESPONSE,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.AUDIT_RESPONSE)} />,
                onClick: () => onOpenChangeStatus(SustainabilityStatus.AUDIT_RESPONSE),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.AUDIT_RESPONSE)} />,
            })
        }

        if (status === SustainabilityStatus.AUDIT_RESPONSE) {
            columns.push({
                value: SustainabilityStatus.GENERATED_FINDINGS,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.GENERATED_FINDINGS)} />,
                onClick: () => onOpenChangeStatus(SustainabilityStatus.GENERATED_FINDINGS),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.GENERATED_FINDINGS)} />,
            })
        }

        if (CanBeSendCloseFindings) {
            columns.push({
                value: SustainabilityStatus.IN_PROCESS_FINFINGS,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.IN_PROCESS_FINFINGS)} />,
                onClick: () => onOpenChangeStatus(SustainabilityStatus.IN_PROCESS_FINFINGS),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.IN_PROCESS_FINFINGS)} />,
            })
        }

        if (status === SustainabilityStatus.FIDDINGS_RESPONSE) {
            columns.push({
                value: SustainabilityStatus.REEVALUATE_AUDIT,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.REEVALUATE_AUDIT)} />,
                onClick: () => onOpenChangeStatus(SustainabilityStatus.REEVALUATE_AUDIT),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.REEVALUATE_AUDIT)} />,
            })
        }

        if (status === SustainabilityStatus.REEVALUATE_AUDIT) {
            columns.push({
                value: SustainabilityStatus.REACTIVATE_FINDING,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.REACTIVATE_FINDING)} />,
                onClick: () => onOpenChangeStatus(SustainabilityStatus.REACTIVATE_FINDING),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.REACTIVATE_FINDING)} />,
            })
        }

        if (status === SustainabilityStatus.REACTIVATE_FINDING) {
            columns.push({
                value: SustainabilityStatus.REEVALUATE_AUDIT,
                title: <Translate id={onTitleStatusDialog(SustainabilityStatus.REEVALUATE_AUDIT)} />,
                onClick: () => onOpenChangeStatus(SustainabilityStatus.REEVALUATE_AUDIT),
                isIconMaterial: true,
                description: <Translate id={onMessageStatusDialog(SustainabilityStatus.REEVALUATE_AUDIT)} />,
            })
        }

        columns.push({
            value: SustainabilityStatus.COMPLETED,
            title: <Translate id={onTitleStatusDialog(SustainabilityStatus.COMPLETED)} />,
            onClick: () => onOpenChangeStatus(SustainabilityStatus.COMPLETED),
            isIconMaterial: true,
            description: <Translate id={onMessageStatusDialog(SustainabilityStatus.COMPLETED)} />,
        })
        columns.push({
            value: SustainabilityStatus.CANCELLED,
            title: <Translate id={onTitleStatusDialog(SustainabilityStatus.CANCELLED)} />,
            onClick: () => onOpenChangeStatus(SustainabilityStatus.CANCELLED),
            isIconMaterial: true,
            description: <Translate id={onMessageStatusDialog(SustainabilityStatus.CANCELLED)} />,
        })
    }
    /*******end section canChangeResponsibleStatus*/

    if (canSendSupplier && status === SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN) {
        columns.push({
            value: SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER,
            title: <Translate id={onTitleStatusDialog(SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER)} />,
            onClick: () => onOpenChangeStatus(SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER),
            isIconMaterial: true,
            description: <Translate id={onMessageStatusDialog(SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER)} />,
        })
    }

    if (canSendSupplier && status === SustainabilityStatus.IN_PROCESS_FINFINGS) {
        columns.push({
            value: SustainabilityStatus.FIDDINGS_RESPONSE,
            title: <Translate id={onTitleStatusDialog(SustainabilityStatus.FIDDINGS_RESPONSE)} />,
            onClick: () => onOpenChangeStatus(SustainabilityStatus.FIDDINGS_RESPONSE),
            isIconMaterial: true,
            description: <Translate id={onMessageStatusDialog(SustainabilityStatus.FIDDINGS_RESPONSE)} />,
        })
    }

    if (canChangeResponsibleStatus && status === SustainabilityStatus.CANCELLED) {
        columns.push({
            value: SustainabilityStatus.REMOVE,
            title: <Translate id={onTitleStatusDialog(SustainabilityStatus.REMOVE)} />,
            onClick: () => onOpenChangeStatus(SustainabilityStatus.REMOVE),
            isIconMaterial: true,
            description: <Translate id={onMessageStatusDialog(SustainabilityStatus.REMOVE)} />,
        })
    }

    if (canChangeResponsibleStatus && status === SustainabilityStatus.AUDIT_IN_PROCESS) {
        columns.push({
            value: SustainabilityStatus.PROGRAM_AUDIT,
            title: <Translate id={onTitleStatusDialog(SustainabilityStatus.PROGRAM_AUDIT)} />,
            onClick: () => onOpenProgramAudit(SustainabilityStatus.PROGRAM_AUDIT),
            isIconMaterial: true,
            description: <Translate id={onMessageStatusDialog(SustainabilityStatus.PROGRAM_AUDIT)} />,
        })
    }

    return columns;
}