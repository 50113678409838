import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import moment from 'moment'

export const IS_LOANDING_CONTROLCO2 = 'IS_LOANDING_CONTROLCO2'
export const START_LOADING_REQUESTS = 'START_LOADING_REQUESTS'
export const IS_SAVING_COTROL = 'IS_SAVING_COTROL'
export const SET_REQUESTS_COUNT = 'SET_REQUESTS_COUNT'
export const SET_REQUESTS_LIST = 'SET_REQUESTS_LIST'
export const SET_SERVICE = 'SET_SERVICE'
export const EDIT_CONTROLCO2_REQUEST = 'EDIT_CONTROLCO2_REQUEST'

export const ADD_CONTROL = 'ADD_CONTROL'
export const REMOVE_CONTROL = 'REMOVE_CONTROL'
export const SAVE_CONTROL = 'SAVE_CONTROL'

export const SET_SERVICES = 'SET_SERVICES'
export const REMOVE_SERVICE = 'REMOVE_SERVICE'

export const SET_VEHICLES = 'SET_VEHICLES'
export const ADD_VEHICLE = 'ADD_VEHICLE'
export const EDIT_VEHICLE = 'EDIT_VEHICLE'
export const REMOVE_VEHICLE = 'REMOVE_VEHICLE'

export const SET_SCHEDULED_VEHICLE_CONTROLS = 'SET_SCHEDULED_VEHICLE_CONTROLS'
export const SET_TOASTER_OPTIONS = 'SET_TOASTER_OPTIONS'

function isLoadingCommon(isLoading, identifier) {
	return {
		type: START_LOADING_REQUESTS,
		isLoading: isLoading,
		identifier: identifier,
	}
}

function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING_COTROL,
		isSaving: isSaving,
		identifier: identifier,
	}
}

export function setToasterOptions(configuration) {
	return {
		type: SET_TOASTER_OPTIONS,
		configuration: configuration,
	}
}

function isDonwloadingReport(isLoanding) {
	return {
		type: IS_LOANDING_CONTROLCO2,
		isLoanding,
	}
}

function setAddRequest(request) {
	return {
		type: ADD_CONTROL,
		request,
	}
}

function setService(service) {
	return {
		type: SET_SERVICE,
		service,
	}
}
function setRequestsCount(count) {
	return {
		type: SET_REQUESTS_COUNT,
		count,
	}
}

function setRequestsList(requests) {
	return {
		type: SET_REQUESTS_LIST,
		requests: requests,
	}
}

function editRequest(request) {
	return {
		type: EDIT_CONTROLCO2_REQUEST,
		request,
	}
}

export function loadAllRequests(queryParameters, translate) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, START_LOADING_REQUESTS))
		let user = getState().oidc.user.profile
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2`

			let sortAscending = queryParameters && queryParameters.sortDirection ? true : false
			if (!sortAscending) sortAscending = false
			let data = {
				CompanyCode: selectedCompany.get('company_code'),
				SortBy: queryParameters && queryParameters.sortBy ? queryParameters.sortBy : 'CreationDate',
				SortAscending: sortAscending,
				Query: queryParameters.query ? queryParameters.query : '',
				Page: queryParameters && queryParameters.pageNumber ? parseInt(queryParameters.pageNumber) + 1 : 1,
				RowsPerPage: queryParameters && queryParameters.rowsPerPage ? queryParameters.rowsPerPage : 20,
				UserName: user && user.username ? user.username : '',
			}
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						let mappedResponse = serverResponse.data.map((request) => {
							return request
						})
						dispatch(setRequestsList(mappedResponse))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isLoadingCommon(false, SET_REQUESTS_LIST))
					console.error('Error getting the requests list by company', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isLoadingCommon(false, SET_REQUESTS_LIST))
		}
	}
}

export function loadService(queryParameters, translate) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, START_LOADING_REQUESTS))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/getlastService`

			let data = {}
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setService(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isLoadingCommon(false, SET_REQUESTS_LIST))
					console.error('Error getting the service CO2', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isLoadingCommon(false, SET_REQUESTS_LIST))
		}
	}
}

export function saveServiceCO2(service) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SAVE_CONTROL))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/getlastService`

			let formData = new FormData()
			service.LastVerificationDateAttachments.forEach((file) => {
				formData.append('file', file, 'LastVerificationDate')
			})
			service.lastDateOfServiceAttachments.forEach((file) => {
				formData.append('file', file, 'LastDateOfService')
			})
			service.lastHoldingDateAttachments.forEach((file) => {
				formData.append('file', file, 'lastHoldingDate')
			})
			service.otherAttachments.forEach((file) => {
				formData.append('file', file)
			})

			formData.set(
				'LastVerificationDate',
				moment(service.lastVerificationDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
			)
			formData.set('LastDateOfService', moment(service.lastDateOfService, 'YYYY-MM-DD').format('DD/MM/YYYY'))
			formData.set('lastHoldingDate', moment(service.lastHoldingDate, 'YYYY-MM-DD').format('DD/MM/YYYY'))
			formData.set('vehicleToken', service.vehicleToken)
			formData.set('originOfTransportation', service.originOfTransportation)
			formData.set('typeOfTransportation', service.TypeOfTransportation)
			formData.set(
				'fireExtinguisherInspection',
				moment(service.FireExtinguisherInspection, 'YYYY-MM-DD').format('DD/MM/YYYY')
			)
			formData.set('add', true)

			return axios
				.post(endPoint, formData, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, SAVE_CONTROL))
						dispatch(setService(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, SAVE_CONTROL))
					console.error('Error in save the service CO2', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, SAVE_CONTROL))
		}
	}
}

export function downloadHistorialControlCO2Excel(startDate, endDate) {
	return (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		dispatch(isDonwloadingReport(true))
		let data = {
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/downloadreport`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_historial_controlco2.xlsx'
					a.click()
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading report control co2', error.response)
				return Promise.reject()
			})
	}
}

export function addRequest(requestModel, translate) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_CONTROL))
		let selectedCompany = getState().profile.get('selectedCompany')
		let user = getState().oidc.user
		let isLicensed = requestModel.isLicenseVerified === 'OK' ? true : false
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/create`
			let formData = new FormData()
			requestModel.attachments.forEach((file) => {
				formData.append('file', file)
			})

			formData.set('User', requestModel.user)
			formData.set('DestinationPlace', requestModel.destination)
			formData.set('Km', requestModel.km)
			formData.set('CarbonResult', requestModel.carbonResult)
			formData.set(
				'ArrivalLevelsCheck',
				requestModel.gasoline + '|' + requestModel.oil + '|' + requestModel.water + '|' + requestModel.tires
			)
			formData.set('CleaningCheck', requestModel.cleaningCheck)
			formData.set('Observations', requestModel.observations)
			formData.set('CreatorUserName', user.profile.username)
			formData.set('CompanyCode', selectedCompany.get('company_code'))
			formData.set('CreatorName', user.profile.name)
			formData.set('CheckOutDate', moment(requestModel.checkOutDate).format().toString())
			formData.set('CheckInDate', moment(requestModel.checkInDate).format().toString())
			formData.set('isLicenseVerified', isLicensed)
			formData.set('VehicleToken', requestModel.vehicleToken)

			return axios
				.post(endPoint, formData, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						var response = serverResponse.data
						dispatch(isSavingCommon(false, ADD_CONTROL))
						dispatch(setAddRequest(response))
						return Promise.resolve(response)
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_CONTROL))
					console.error('Error in adding Control CO2 request', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_CONTROL))
		}
	}
}

export const removeRequest = (controlRequestToken) => {
	return {
		type: REMOVE_CONTROL,
		controlRequestToken,
	}
}

export const deleteRequest = (controlRequestToken) => {
	return async (dispatch, getState) => {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/${controlRequestToken}`

		try {
			const result = await axios.delete(endPoint, configuration)
			if (result.status === 200) {
				dispatch(removeRequest(controlRequestToken))
			}
		} catch (error) {
			console.error('Error deleting control request', error)
			return Promise.reject()
		}
	}
}

export function updateRequest(requestModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/${requestModel.token}`
			let formData = new FormData()
			requestModel.attachments.forEach((file) => {
				formData.append('file', file)
			})

			let command = {
				KmFinal: requestModel.kmFinal ? requestModel.kmFinal : 0,
				CheckInObservations: requestModel.checkInObservations ? requestModel.checkInObservations : '',
			}
			formData.append('command', JSON.stringify(command))

			return axios
				.put(endPoint, formData, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false))
						return Promise.resolve(true)
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false))
					console.error('Error in updating Control CO2 request', error.response)
					return Promise.reject(false)
				})

		}
	}
}

export function getRequestCount() {
	return function (dispatch, getState) {
		let selectedCompany = getState().profile.get('selectedCompany')
		let companyCode = selectedCompany.get('company_code')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/${companyCode}/Count`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setRequestsCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the requests' count", error.response)
			})
	}
}

export function blockUserFromControlCO2(controlRequest) {
	return function (dispatch, getState) {
		let selectedCompany = getState().profile.get('selectedCompany')
		let companyCode = selectedCompany.get('company_code')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/${controlRequest.id}/${companyCode}`
		return axios
			.put(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(editRequest(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error blocking user', error.response)
			})
	}
}

export function updateCheckInDate(controlId) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/checkInDate/${controlId}`
		return axios
			.put(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					console.log('UPDATED CHECKIN DATE')
				}
			})
			.catch((error) => {
				console.error('Error updating checkInDate', error.response)
			})
	}
}

export const setServices = (services) => {
	return {
		type: SET_SERVICES,
		services,
	}
}

export const loadServices = () => {
	return async (dispatch, getState) => {
		const endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/services/all`
		try {
			const result = await axios.get(endPoint)
			if (result.status === 200) {
				dispatch(setServices(result.data.items))
			}
		} catch (error) {
			console.error('Error loading services', error)
		}
	}
}

export const setVehicles = (vehicles) => {
	return {
		type: SET_VEHICLES,
		vehicles,
	}
}

export const loadVehicles = () => {
	return async (dispatch, getState) => {
		const endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/vehicles/all`
		try {
			const result = await axios.get(endPoint)
			if (result.status === 200) {
				dispatch(setVehicles(result.data.items))
			}
		} catch (error) {
			console.error('Error loading vehicles', error)
		}
	}
}

export const removeService = (serviceToken) => {
	return {
		type: REMOVE_SERVICE,
		serviceToken,
	}
}

export const deleteService = (serviceToken) => {
	return async (dispatch, getState) => {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/services/${serviceToken}`

		try {
			const result = await axios.delete(endPoint, configuration)
			if (result.status === 200) {
				dispatch(removeService(serviceToken))
			}
		} catch (error) {
			console.error('Error deleting service service', error)
			return Promise.reject()
		}
	}
}

export const addVehicle = (vehicle) => {
	return {
		type: ADD_VEHICLE,
		vehicle,
	}
}

export const createVehicle = (vehicle) => {
	return async (dispatch, getState) => {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/vehicles`
		const command = vehicle

		try {
			const result = await axios.post(endPoint, command, configuration)
			if (result.status === 201) {
				dispatch(addVehicle(result.data))
			}
		} catch (error) {
			console.error('Error creating new workshop', error)
			return Promise.reject()
		}
	}
}

export const editVehicle = (vehicle) => {
	return {
		type: EDIT_VEHICLE,
		vehicle,
	}
}

export const updateVehicle = (vehicle) => {
	return async (dispatch, getState) => {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/vehicles/${vehicle.token}`
		const command = vehicle

		try {
			const result = await axios.put(endPoint, command, configuration)
			if (result.status === 200) {
				dispatch(editVehicle(vehicle))
			}
		} catch (error) {
			console.error('Error creating new workshop', error)
			return Promise.reject()
		}
	}
}

export const removeVehicle = (vehicleToken) => {
	return {
		type: REMOVE_VEHICLE,
		vehicleToken,
	}
}

export const deleteVehicle = (vehicleToken) => {
	return async (dispatch, getState) => {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/vehicles/${vehicleToken}`

		try {
			const result = await axios.delete(endPoint, configuration)
			if (result.status === 200) {
				dispatch(removeVehicle(vehicleToken))
			}
		} catch (error) {
			console.error('Error creating new workshop', error)
			return Promise.reject()
		}
	}
}

export const setScheduledVehicleControls = (controls) => {
	return {
		type: SET_SCHEDULED_VEHICLE_CONTROLS,
		controls,
	}
}

export const loadScheduledVehicleControls = () => {
	return async (dispatch, getState) => {
		const endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/controlCO2/scheduled`
		try {
			const result = await axios.get(endPoint)
			if (result.status === 200) {
				dispatch(setScheduledVehicleControls(result.data))
			}
		} catch (error) {
			console.error('Error scheduled vehicle controls', error)
		}
	}
}
