/** Helpers import section */
import axios from 'axios'

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper'
export const START_LOADING_RELEASE_PLANIING = 'START_LOADING_RELEASE_PLANNING'
export const IS_SAVING_RELEASE_PLANIING = 'IS_SAVING_RELEASE_PLANNING'
export const SET_RELEASE_PLANIING = 'SET_RELEASE_PLANNING'
export const UPDATE_RELEASE = 'UPDATE_RELEASE_PLANNING'

/**
 * Action start loading release plan supplier
 * @param {*} isLoading
 */
function isLoadingReleasePlanning(isLoading) {
	return {
		type: START_LOADING_RELEASE_PLANIING,
		isLoading: isLoading,
	}
}

function isSavingReleasePlanning(isSaving) {
	return {
		type: IS_SAVING_RELEASE_PLANIING,
		isSaving: isSaving,
	}
}
/**
 * Action to set release plan
 * @param {*} releasePlan
 */

function setRelase(release) {
	return {
		type: SET_RELEASE_PLANIING,
		releasePlan: release,
	}
}


/**
 * Action to set release plan
 * @param {*} releasePlan
 */

export function loadReleasePlanFrom(supplierConde, month_confirm) {
	return function (dispatch, getState) {
		dispatch(isLoadingReleasePlanning(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}release/${supplierConde}`
		if (selectedCompany) {
			return axios
				.get(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setRelase(serverResponse.data))
						dispatch(isLoadingReleasePlanning(false))
					}
				})
				.catch((error) => {
					console.error('Error to load release plan', error.response)
					dispatch(isLoadingReleasePlanning(false))
					return Promise.reject()
				})
		}
	}
}

export function ConfirmQualityEntryItem(ids, month_confirm) {
	return function (dispatch, getState) {
		let confirmBy = getState().oidc.user.profile.name
		dispatch(isLoadingReleasePlanning(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}release/Update/${confirmBy}/${month_confirm}`
		if (ids) {
			return axios
				.post(endPoint, ids, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setRelase(serverResponse.data))
						dispatch(isLoadingReleasePlanning(false))
					}

					dispatch(isLoadingReleasePlanning(false))
				})
				.catch((error) => {
					console.error('Error to load release plan', error.response)
					dispatch(isLoadingReleasePlanning(false))
					return Promise.reject(error.response)
				})
		}
	}
}

/**
 * Action to add material from release
 * @param {*} material
 * @param {*} supplierName
 */
export function addMaterialToReleasePlan(material) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let selectedCompany = getState().profile.get('selectedCompany')
		let supplier = getState().suppliers.get('supplier')

		let data = {
			CompanyCenter: selectedCompany.get('center'),
			SupplierCode: supplier ? supplier.get('code') : '',
			SupplierName: supplier ? supplier.get('name') : '',
			Material: material.part_number_code,
			PurchaseOrder: material.purchase_order,
			SolPedNumber: material.sol_ped_number,
			Position: material.position,
			CompanyCode: 'FERSA',
		}
		dispatch(isSavingReleasePlanning(true))

		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}release/items`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				dispatch(isSavingReleasePlanning(false))
			})
			.catch((error) => {
				dispatch(isSavingReleasePlanning(false))
				console.error('Error adding a material in reelase', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Get and set the release plan supplier in the estate from read from backend
 * @param {*} token
 */
export function loadReleasePlanFromBackEnd(supplierConde) {
	return function (dispatch, getState) {
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}release/Fersa/${supplierConde}`
		if (selectedCompany) {
			return axios
				.get(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setRelase(serverResponse.data))
					}
				})
				.catch((error) => {
					console.error('Error to load release plan', error.response)
					return Promise.reject()
				})
		}
	}
}

/**
 * Remove a material to release plan from backend
 * @param {*} annotationToken
 */
export function removeMaterialFromReleasePlan(materialId) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		dispatch(isSavingReleasePlanning(true))
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}release/items/${materialId}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingReleasePlanning(false))
				} else {
					dispatch(isSavingReleasePlanning(false))
					return Promise.reject(serverResponse)
				}
			})
			.catch((error) => {
				dispatch(isSavingReleasePlanning(false))
				console.error('Error removing a material from release', error.response)
				return Promise.reject(error.response)
			})
	}
}
