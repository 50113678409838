/** Import react section */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'

/** Material-UI imports section */
import Fab from '@material-ui/core/Fab'
import Avatar from '@material-ui/core/Avatar'
import AddIcon from '@material-ui/icons/AddTwoTone'
import Typography from '@material-ui/core/Typography'
import CardHeader from '@material-ui/core/CardHeader'
import BiddingIcon from '@material-ui/icons/ViewCarousel'
import IndexViewIcon from '@material-ui/icons/ViewList'
import SchedulerViewIcon from '@material-ui/icons/CalendarToday'
import IconButton from '@material-ui/core/IconButton'
import { AppBar, Tabs } from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import HelpIcon from '@material-ui/icons/Help'
/** Import common section */
import SearchInput from '../../common/SearchInput/SearchInput'
import SelectorComponent from '../common/SelectorComponent'
import ReportOptions from './ReportOptions'
import { Button, Tooltip } from '@material-ui/core'
import { Sync } from '@material-ui/icons'

const TransportTenderHeader = (props) => {
	const {
		classes,
		showIndex,
		setOpenDialog,
		onChangeSearchValue,
		isInternalUse,
		onChangeModeView,
		onSearch,
		wineCellarOptions,
		setWineCellerSelected,
		optionsSupplier,
		setSupplierSelected,
		wineCellarSelected,
		supplierSelected,
		onReset,
		profileSupplier,
		onOpenWinnersInSap,
		setOpenIncidentDialog,
		setOpenModalReport,
		activeTab,
		onChangeTab,
		searchQuery,
	} = props
	const isOnlySupplier = optionsSupplier.length === 1

	const onRedirectToDocumenation = () => {
		if (isInternalUse) {
			return `${process.env.REACT_APP_SIP_DOCUMENTATION_INTERNAL}`
		} else {
			return `${process.env.REACT_APP_SIP_DOCUMENTATION_EXTERNAL}`
		}
	}
	return (
		<>
			<CardHeader
				avatar={
					<Avatar>
						<BiddingIcon />
					</Avatar>
				}
				action={
					<div className={classes.constainer}>
						{showIndex ? (
							<div className={classes.contentSelector}>
								<Tooltip title={'Ayuda'}>
									<Fab size='small' href={onRedirectToDocumenation()}>
										<HelpIcon size='small' color='primary' />
									</Fab>
								</Tooltip>
								<div className={classes.marginSearch}>
									<SearchInput onChangeSearchValue={onChangeSearchValue} value={searchQuery} />
								</div>
								{isInternalUse && (
									<Fab size='small' color='primary' onClick={() => setOpenDialog(true)}>
										<AddIcon />
									</Fab>
								)}
							</div>
						) : (
							<div className={classes.contentSelector}>
								<div className={classes.selectItem}>
									{isOnlySupplier || !isInternalUse ? (
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<Typography>
												<Translate id={'carrierTenders.carrier'} />
											</Typography>
											<Typography variant='caption'>
												{`${profileSupplier.company_code} | ${profileSupplier.name}`}
											</Typography>
										</div>
									) : (
										<SelectorComponent
											title={<Translate id={'carrierTenders.carrier'} />}
											placeholder={<Translate id={'carrierTenders.selectCarrier'} />}
											options={optionsSupplier}
											onChange={(option) => setSupplierSelected(option)}
											value={supplierSelected}
										/>
									)}
								</div>
								<div className={classes.selectItem}>
									<SelectorComponent
										title={<Translate id='carrierTenders.wineCellar' />}
										placeholder={<Translate id='carrierTenders.selectWineCellar' />}
										options={wineCellarOptions}
										onChange={(option) => setWineCellerSelected(option)}
										value={wineCellarSelected}
									/>
								</div>

								<Button className={classes.btnSearch} onClick={onSearch}>
									<Translate id='carrierTenders.search' />
								</Button>
								<Button
									className={classes.btnSearch}
									disabled={wineCellarSelected == null && supplierSelected == null}
									onClick={onReset}
								>
									<Translate id='carrierTenders.reset' />
								</Button>
							</div>
						)}

						<IconButton color='primary' value onClick={onChangeModeView}>
							{showIndex ? <SchedulerViewIcon /> : <IndexViewIcon />}
						</IconButton>

						{isInternalUse && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Tooltip title={'Sincronizar ganadores'}>
									<Button className={classes.btnSyncSAP} onClick={() => onOpenWinnersInSap(true)}>
										<Sync fontSize='small' />
										<Typography className={classes.colorText} variant='caption'>
											<Translate id={'carrierTenders.SAP'} />
										</Typography>
									</Button>
								</Tooltip>
								<ReportOptions
									onClickIncidentsReport={setOpenIncidentDialog}
									onClickInTransitReport={setOpenModalReport}
								/>
							</div>
						)}
					</div>
				}
				title={<Typography variant='button'>{<Translate id='carrierTenders.title' />}</Typography>}
			/>
			<AppBar position='static' color='default'>
				<Tabs
					indicatorColor='primary'
					textColor='primary'
					className={classes.styleTab}
					value={activeTab}
					onChange={onChangeTab}
				>
					{isInternalUse && <Tab label={'Borradores'} />}
					{isInternalUse ? (
						<Tab label={'Enviados a transportista'} />
					) : (
						<Tab label={'Embarques disponibles'} />
					)}
					{isInternalUse && <Tab label={'Segunda ronda'} />}
					{!isInternalUse && <Tab label={'Participado'} />}
					{isInternalUse && <Tab label={'En evaluación'} />}
					{isInternalUse && <Tab label={'Expirados'} />}
					<Tab label={'Embarques asignados'} />
				</Tabs>
			</AppBar>
		</>
	)
}

const styles = (theme) => ({
	constainer: {
		display: 'flex',
	},
	marginSearch: {
		marginRight: '5px',
	},
	contentSelector: {
		display: 'flex',
		alignItems: 'flex-end',
	},
	selectItem: {
		width: '300px',
		marginRight: '8px',
	},
	btnSearch: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		marginRight: '8px',
	},
	btnSyncSAP: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		height: '25px',
	},
	colorText: {
		color: theme.palette.common.white,
	},
	btnDonwloadIncident: {
		marginLeft: '8px',
		backgroundColor: theme.palette.primary.main,
		height: '25px',
	},
	iconDonwnlaod: {
		paddingRight: '8px',
		color: theme.palette.common.white,
	},
})
TransportTenderHeader.propTypes = {
	isInternalUse: PropTypes.bool.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
	onChangeSearchValue: PropTypes.func.isRequired,
	showIndex: PropTypes.bool,
	onChangeModeView: PropTypes.func,
	onSearch: PropTypes.func,
	wineCellarOptions: PropTypes.array,
	setWineCellerSelected: PropTypes.func,
	optionsSupplier: PropTypes.array,
	setSupplierSelected: PropTypes.func,
	wineCellarSelected: PropTypes.object,
	supplierSelected: PropTypes.object,
	onReset: PropTypes.func,
	profileSupplier: PropTypes.object,
	onOpenWinnersInSap: PropTypes.func,
	setOpenIncidentDialog: PropTypes.func,
	setOpenModalReport: PropTypes.func,
	activeTab: PropTypes.number,
	onChangeTab: PropTypes.func,
	searchQuery: PropTypes.string,
}

TransportTenderHeader.defaultProps = {
	setOpenDialog: () => console.warn('[setOpenDialog] not defined!'),
	onChangeSearchValue: () => console.warn('[onChangeSearchValue] not defined!'),
	isInternalUse: false,
	showIndex: true,
	onChangeModeView: () => console.warn('[onChangeModeView] not defined!'),
	onSearch: () => console.warn('[onSearch] not defined!'),
	wineCellarOptions: [],
	setWineCellerSelected: () => console.warn('[setWineCellerSelected] not defined!'),
	optionsSupplier: [],
	setSupplierSelected: () => console.warn('[setSupplierSelected] not defined!'),
	wineCellarSelected: {},
	supplierSelected: {},
	onReset: () => console.warn('[onReset] not defined!'),
	profileSupplier: PropTypes.object,
	onOpenWinnersInSap: () => console.warn('[onOpenWinnersInSap] not defined!'),
	setOpenIncidentDialog: () => console.warn('[setOpenIncidentDialog] not defined!'),
	setOpenModalReport: () => console.warn('[setOpenModalReport] not defined!'),
	activeTab: 1,
	onChangeTab: () => console.warn('[onChangeTab] not defined!'),
	searchQuery: '',
}

export default withRouter(withStyles(styles, { withTheme: true })(TransportTenderHeader))
