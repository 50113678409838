import React, { Component } from 'react';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

/**
 * Information MemoCard
 *
 * @class InformationMemoCard
 * @extends {Component}
 */
class InformationMemoCard extends Component {
    render() {
        return (
            <Paper>
                <List>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="memos.project" /></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.memo.project_name}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="memos.stage" /></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.memo.stage}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="memos.dateRequiredEngineering" /></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.memo.date_required_by_engineer}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="memos.developerComments" /></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.memo.developer_comments}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="memos.relationFolio" /></Typography>}
                            secondary={
                                <React.Fragment>
                                    <strong>{this.props.memo.related_project_document_base}</strong>
                                </React.Fragment>
                            } />
                    </ListItem>
                </List>
            </Paper>
        );
    }
}

export default InformationMemoCard;