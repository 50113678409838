import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import { Translate } from 'react-localize-redux'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import FavoriteIcon from '@material-ui/icons/FavoriteTwoTone'
import RatesIcon from '@material-ui/icons/BarChartTwoTone'
import RatesIndirIcon from '@material-ui/icons/ShowChartOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Tooltip from '@material-ui/core/Tooltip'
import { Button, Divider } from '@material-ui/core'
import LockIcon from '@material-ui/icons/LockTwoTone'
import UnLockIcon from '@material-ui/icons/LockOpen'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import GeneralInformation from './common/GeneralInformation'
import AdvancedOptions from './common/AdvancedOptions'
import UsersSupplier from './common/UsersSupplier'
import BankData from './common/BankData'
import QueriesButton from './common/QueriesButton'
import UploadLogo from './common/UploadLogo'
import AddCommonItem from '../common/AddCommonItem'

import { genereteStringGuid } from '../../store/helpers/StringHelper'
import * as roles from '../../store/helpers/RolesHelper'
import { converListToArray } from '../../store/helpers/ListHelper'
import {
	loadSupplier,
	loadPartnership,
	loadBankAccountsSupplier,
	lockOnUnlockSupplier,
	loadAdvanceOptionsSupplier,
	loadUserFollowedSuppliers,
	toggleAddSupplierFollowedToUser,
	toggleRemoveSupplierFollowedToUser,
	synchronizeSupplierAutomaticallyByCode,
	getSalesCollectContact,
} from '../../store/suppliers/SupplierActions'

import { COMPANY_IMBERA } from '../../store/helpers/ResourcesHelper'
import { ROLE_COMPANYADMINISTRATOR, IsUserInRole } from '../../store/helpers/RolesHelper'
import { DoneAll } from '@material-ui/icons'

/**
 * Container to show information from supplier
 */
class Show extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.uploadLogoFileRef = React.createRef()
		this.state = {
			activeTab: 0,
			showDetails: true,
			showChangeLogoDialog: false,
			logoFileBase64: null,
			croppedFileBase64: null,
			openEnableSupplier: false,
			openUnlockedSupplier: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let supplierToken = this.props.match.params.token
		this.props.loadSupplier(supplierToken)
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoadingSupplier !== this.props.isLoadingSupplier && this.props.supplier !== null) {
			/// Get information full from supplier
			let supplier = this.props.supplier
			let companyToken = this.props.selectedCompany.token
			this.props.loadPartnership(supplier.code, companyToken)
			this.props.loadBankAccountsSupplier(supplier.code, companyToken)
			this.props.loadAdvanceOptionsSupplier(supplier.code)
			this.props.loadUserFollowedSuppliers()
		}
	}

	/**
	 * Method to change the active tab
	 */
	onClickToChangeActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 * On open queries view
	 * @memberof Show
	 */
	onOpenQueryView(queryRequested) {
		this.props.history.push(`/suppliers/${queryRequested}/${this.props.match.params.token}/`)
	}

	/**
	 *
	 * @memberof Show
	 */
	onOpenConstracotView(queryRequested) {
		this.props.history.push(queryRequested)
	}

	onOpenServicesView(queryRequested) {
		this.props.history.push(`${queryRequested}/${this.props.supplier.code}`)
	}

	/**
	 * On open reelase plan
	 */
	onOpenReleasePlan() {
		if (this.props.supplier !== null) {
			this.props.history.push(`/planning/releaseplan/${this.props.supplier.code}`)
		}
	}

	/**
	 * Upload the log file
	 *
	 * @memberof UserProfileHeader
	 */
	uploadLogoFile(file) {
		let self = this
		let fileUpload = file.target.files[0]
		/// Determine if the image to add not is undefined or null
		if (fileUpload !== undefined && fileUpload !== null) {
			var reader = new FileReader()
			reader.onload = function () {
				self.onSelecteFile(reader.result)
			}
			reader.onerror = function (error) {
				console.log('Error: ', error)
			}
			reader.readAsDataURL(fileUpload)
			self.uploadLogoFileRef.current.value = ''
		}
	}

	/**
	 * On select logo file
	 *
	 * @param {*} fileBase64
	 * @memberof Profile
	 */
	onSelecteFile(fileBase64) {
		this.setState({
			logoFileBase64: fileBase64,
			croppedFileBase64: fileBase64,
			showChangeLogoDialog: true,
		})
	}

	/**
	 * onEnableSupplier
	 */
	onEnableSupplier() {
		this.setState({
			openEnableSupplier: true,
		})
	}

	/**
	 * Function on block supplier
	 */
	onBlockSupplier(comments) {
		this.props
			.lockOnUnlockSupplier(this.props.supplier.code, true, comments)
			.then(() => {
				this.setState({
					openEnableSupplier: false,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * onUnLockedSupplier
	 */
	onUnLockedSupplier() {
		this.setState({
			openUnlockedSupplier: true,
		})
	}

	/**
	 * Function on unloked user
	 * @param {*} comments
	 */
	onUnlockedUser(comments) {
		this.props
			.lockOnUnlockSupplier(this.props.supplier.code, false, 'Unlocked')
			.then(() => {
				this.setState({
					openUnlockedSupplier: false,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	onSynchronizeSupplierAutomaticallyByCode() {
		return this.props
			.synchronizeSupplierAutomaticallyByCode(this.props.supplier.code)
			.then(() => {
				this.props.loadSupplier(this.props.match.params.token)
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Get sales and collect contacts for supplier and unit
	 * @returns
	 */
	onGetSalesCollectContact() {
		return this.props.getSalesCollectContact(this.props.supplier.code)
	}

	/**
	 * Determines if the current user is following a supplier
	 */
	isFollowingSupplier() {
		let followedSuppliers = this.props.followedSuppliers
		if (followedSuppliers) {
			let findSupplier = followedSuppliers.find(
				(supplier) => supplier.supplier_token === this.props.supplier.token
			)
			return findSupplier ? true : false
		}
		return false
	}

	/**
	 * Render view
	 */
	render() {
		const { classes } = this.props
		if (this.props.isLoadingSupplier === true) {
			return <LoadingPage />
		}
		if (this.props.supplier === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let supplier = this.props.supplier
			let lockSupplier = this.props.advanceOptionsSupplier
			let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${supplier.logo}`
			let logoUrl = urlImage + '?token=' + genereteStringGuid()

			let logoAvatar = (
				<IconButton
					className={classes.avatar}
					onClick={() => {
						this.uploadLogoFileRef.current.click()
					}}
				>
					P
				</IconButton>
			)
			if (supplier.logo) {
				logoAvatar = (
					<Avatar
						alt='Remy Sharp'
						src={logoUrl}
						className={classes.avatar}
						onClick={() => {
							this.uploadLogoFileRef.current.click()
						}}
					/>
				)
			}

			return (
				<Card className={classes.card}>
					<CardHeader avatar={logoAvatar} title={supplier.full_name} subheader={supplier.rfc} />
					<CardContent>
						<GeneralInformation
							supplier={supplier}
							partnership={this.props.partnership}
							isSaving={this.props.isSaving}
							onSynchronizeSupplierAutomaticallyByCode={this.onSynchronizeSupplierAutomaticallyByCode}
							isVisibleBtnSynchronizeSupplier={
								IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) &&
								this.props.currentUser.profile &&
								this.props.currentUser.profile.preferred_username === 'adimbptm'
							}
							selectedCompany={this.props.selectedCompany}
							salesContactList={this.props.salesContactList}
						/>
					</CardContent>
					<CardActions className={classes.actions} disableActionSpacing>
						{!this.isFollowingSupplier() && (
							<Tooltip title={<Translate id='suppliers.markAsFavorite' />}>
								<IconButton
									onClick={() => {
										this.props.toggleAddSupplierFollowedToUser(supplier)
									}}
								>
									<FavoriteIcon />
								</IconButton>
							</Tooltip>
						)}
						{this.isFollowingSupplier() && (
							<Tooltip title={<Translate id='suppliers.stopFollowing' />}>
								<IconButton
									onClick={() => {
										this.props.toggleRemoveSupplierFollowedToUser(supplier.token)
									}}
								>
									<FavoriteIcon color='secondary' />
								</IconButton>
							</Tooltip>
						)}
						{roles.caBeModuleProfileSupplier(this.props.userRolesByCompany) && (
							<QueriesButton
								{...this.props}
								onOpenQueryView={this.onOpenQueryView}
								onOpenConstracotView={this.onOpenConstracotView}
								onOpenServicesView={this.onOpenServicesView}
								onOpenReleasePlan={this.onOpenReleasePlan}
								advanceOptionsSupplier={this.props.advanceOptionsSupplier}
							/>
						)}
						<Tooltip title={<Translate id='suppliers.viewScores' />}>
							<IconButton
								aria-label='Show ratings'
								onClick={() => {
									this.props.history.push(`/suppliers/ratings/${supplier.token}`)
								}}
							>
								<RatesIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title={<Translate id='certifications.certificationOption' />}>
							<IconButton
								aria-label='Show ratings'
								onClick={() => {
									this.props.history.push(`/certifications/${supplier.code}`)
								}}
							>
								<DoneAll />
							</IconButton>
						</Tooltip>
						{this.props.selectedCompany &&
							this.props.selectedCompany.company_code === COMPANY_IMBERA &&
							lockSupplier &&
							lockSupplier.rating_type === '2' && (
								<Tooltip title={<Translate id='suppliers.ratingScoresIndirects' />}>
									<IconButton
										aria-label='Show ratings indirects'
										onClick={() => {
											this.props.history.push(`/suppliers/ratings/${supplier.token}/indirects`)
										}}
									>
										<RatesIndirIcon />
									</IconButton>
								</Tooltip>
							)}
						<IconButton
							className={classnames(classes.expand, {
								[classes.expandOpen]: this.state.showDetails,
							})}
							onClick={() => {
								this.setState({
									showDetails: !this.state.showDetails,
								})
							}}
							aria-expanded={this.state.showDetails}
							aria-label='Show more'
						>
							{this.state.showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</IconButton>
						<div className={classes.grow}></div>

						{roles.CaBeBlockSupplier(this.props.userRolesByCompany) && (
							<Button
								variant='contained'
								color='primary'
								size='small'
								disabled={this.props.isLoadingContact}
								onClick={this.onGetSalesCollectContact}
							>
								{<Translate id='suppliers.btnSalesCollection' />}
							</Button>
						)}

						{roles.CaBeBlockSupplier(this.props.userRolesByCompany) && (
							<span>
								{lockSupplier && !lockSupplier.locked && (
									<Tooltip title={<Translate id='suppliers.lockSupplier' />}>
										<IconButton onClick={this.onEnableSupplier}>
											<LockIcon color='primary' />
										</IconButton>
									</Tooltip>
								)}

								{lockSupplier && lockSupplier.locked && (
									<Tooltip title={<Translate id='suppliers.unlockSupplier' />}>
										<IconButton onClick={this.onUnLockedSupplier}>
											<Typography variant='subtitle2'>
												<strong>{<Translate id='suppliers.supplierLock' />} </strong>
											</Typography>
											<UnLockIcon color='error' />
										</IconButton>
									</Tooltip>
								)}
							</span>
						)}
					</CardActions>
					<Divider />
					<Collapse in={this.state.showDetails} timeout='auto' unmountOnExit>
						<AppBar position='static' color='default'>
							<Tabs
								variant='fullWidth'
								indicatorColor='secondary'
								textColor='secondary'
								value={this.state.activeTab}
								onChange={this.onClickToChangeActiveTab}
							>
								<Tab label={<Translate id='suppliers.users' />} />
								{roles.CaBeBlockSupplier(this.props.userRolesByCompany) && (
									<Tab label={<Translate id='suppliers.dataBanck' />} />
								)}
								{roles.CaBeBlockSupplier(this.props.userRolesByCompany) && (
									<Tab label={<Translate id='suppliers.advanceOptions' />} />
								)}
							</Tabs>
						</AppBar>
						{this.state.activeTab === 0 && <UsersSupplier />}
						{this.state.activeTab === 1 && (
							<BankData bancks={this.props.bancks} isLoadingBanck={this.props.isLoadingBanck} />
						)}
						{this.state.activeTab === 2 && <AdvancedOptions supplierCode={supplier.code} {...this.props} />}
					</Collapse>

					<input
						className={classes.logoFileInput}
						type='file'
						accept='image/*'
						ref={this.uploadLogoFileRef}
						multiple={false}
						onChange={this.uploadLogoFile}
					/>

					{this.state.showChangeLogoDialog ? (
						<UploadLogo
							open={this.state.showChangeLogoDialog}
							fileBase64={this.state.logoFileBase64}
							onClose={() => {
								this.setState({ showChangeLogoDialog: false })
							}}
						/>
					) : null}

					{/** Dialog to Block supplier */}
					<AddCommonItem
						title={<Translate id='suppliers.lockSupplier' />}
						message={<Translate id='suppliers.messageBlock' />}
						open={this.state.openEnableSupplier}
						onClose={() => {
							this.setState({ openEnableSupplier: false })
						}}
						onSaveItem={this.onBlockSupplier}
						isSaving={this.props.isSaving}
						isTextArea={true}
					/>

					{/** Dialog to Unlocked supplier */}
					<AddCommonItem
						title={<Translate id='suppliers.unlockSupplier' />}
						message={<Translate id='suppliers.messageunlock' />}
						open={this.state.openUnlockedSupplier}
						onClose={() => {
							this.setState({ openUnlockedSupplier: false })
						}}
						onSaveItem={this.onUnlockedUser}
						isSaving={this.props.isSaving}
						isMessage={true}
					/>
				</Card>
			)
		}
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	return {
		currentUser: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		isLoadingSupplier: state.suppliers.get('isLoadingSupplier'),
		partnership: state.suppliers.get('partnership') ? state.suppliers.get('partnership').toJS() : null,
		isLoadingBanck: state.suppliers.get('isLoadingBanck'),
		bancks: state.suppliers.get('bancks') ? state.suppliers.get('bancks').toJS() : null,
		isSaving: state.suppliers.get('isSaving'),
		advanceOptionsSupplier: state.suppliers.get('advanceOptionsSupplier')
			? state.suppliers.get('advanceOptionsSupplier').toJS()
			: null,
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		followedSuppliers: state.suppliers.get('followedSuppliers')
			? state.suppliers.get('followedSuppliers').toJS()
			: null,
		salesContactList: state.suppliers.get('salesContactList')
			? state.suppliers.get('salesContactList').toJS()
			: null,
		isLoadingContact: state.suppliers.get('isLoadingContact') ? state.suppliers.get('isLoadingContact') : false,
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		loadPartnership: (supplierCode, companyToken) => {
			return dispatch(loadPartnership(supplierCode, companyToken))
		},
		loadBankAccountsSupplier: (supplierCode, companyToken) => {
			return dispatch(loadBankAccountsSupplier(supplierCode, companyToken))
		},
		loadAdvanceOptionsSupplier: (supplierCode) => {
			return dispatch(loadAdvanceOptionsSupplier(supplierCode))
		},
		lockOnUnlockSupplier: (supplierCode, enabled, reason) => {
			return dispatch(lockOnUnlockSupplier(supplierCode, enabled, reason))
		},
		loadUserFollowedSuppliers: () => {
			return dispatch(loadUserFollowedSuppliers())
		},
		toggleAddSupplierFollowedToUser: (supplier) => {
			return dispatch(toggleAddSupplierFollowedToUser(supplier))
		},
		toggleRemoveSupplierFollowedToUser: (supplierToken) => {
			return dispatch(toggleRemoveSupplierFollowedToUser(supplierToken))
		},
		synchronizeSupplierAutomaticallyByCode: (supplierCode) => {
			return dispatch(synchronizeSupplierAutomaticallyByCode(supplierCode))
		},
		getSalesCollectContact: (supplierCode) => {
			return dispatch(getSalesCollectContact(supplierCode))
		},
	}
}

const viewStyles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: '100px',
		paddingRight: '150px',
	},
	styleTab: {
		backgroundColor: '#fff',
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		marginLeft: 10,
		margin: 10,
		width: 80,
		height: 80,
	},
	logoFileInput: {
		display: 'none',
	},
	edit: {
		marginRight: 30,
		backgroundColor: theme.palette.text.hint,
	},
	grow: {
		flexGrow: 1,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
