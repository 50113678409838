import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types'
import { withRouter } from "react-router-dom";

import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Popper from '@material-ui/core/Popper';
import { Grid, Card, CardContent } from '@material-ui/core'
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Translate } from 'react-localize-redux';

const PendingNotificationsOutsourcing = (props) => {
    const { onOpen, translate, badgetCount, classes, onActive, anchorEl, isLocked } = props
    return (
        <div>
            <Tooltip title={<Translate id={translate} />}>
                <IconButton color='inherit'
                    onClick={(event) => {
                        onActive(
                            event
                        )
                    }}
                >
                    <Badge color='error' badgeContent={badgetCount}>
                        <NotificationsNoneIcon color='inherit' />
                    </Badge>
                </IconButton>
            </Tooltip>

            {(onOpen) &&
                <Popper
                    open={onOpen}
                    transition
                    anchorEl={anchorEl}
                    placement='bottom-end'
                    style={{
                        zIndex: 40
                    }}
                >
                    <Card style={{
                        backgroundColor: '#E5E5E5',
                        padding: 2,
                        border: 'lightgray solid 1px',
                        maxWidth: 300
                    }}>
                        <CardContent>
                            <Grid container>
                                <Typography variant="subtitle2" gutterBottom
                                    color="inherit"
                                    className={classnames(classes.grow)}
                                    align="justify"
                                >
                                    {isLocked && <strong>{"PROVEEDOR BLOQUEADO PARA PAGOS"} <br /></strong>}
                                    Estimado proveedor, se le notifica que se han identificado cargas de información pendientes en el portal de empresas de servicios especializados de AlPunto.
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom
                                    color="inherit"
                                    className={classnames(classes.grow)}
                                    align="justify"
                                >
                                    Es importante que ingrese al portal de empresas de servicios especializados AlPunto y realice la carga de información solicitada.  <strong>Total de Incidentes {badgetCount}</strong>
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom
                                    color="inherit"
                                    className={classnames(classes.grow)}
                                    align="justify"
                                >
                                    Presione aqui:&nbsp;
                                    <Link href='https://sip.efemsa.com/outsourcing/' target='_blank' rel="noopener">
                                        ir al portal de servicios especializados
                                    </Link>
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                </Popper>
            }
        </div>
    )
}

PendingNotificationsOutsourcing.propTypes = {
    /**
     * If true, the popper is visible.
     */
    onOpen: propTypes.bool.isRequired,
    /**
     * Translate or tooltip
     */
    translate: propTypes.string.isRequired,
    /**
     * number of notifications
     */
    badgetCount: propTypes.string.isRequired,
    /**
     * classes
     */
    classes: propTypes.array.isRequired,
    /**
     * function to open or close popper
     */
    onActive: propTypes.func.isRequired,
    /**
     * It's used to set the position of the popper. The return value will passed as the reference object of the Popper instance.
     */
    anchorEl: propTypes.object,
}
PendingNotificationsOutsourcing.defaultProps = {
    onOpen: false,
    translate: 'common.notifications',
    badgetCount: '0',
}

export default withRouter((PendingNotificationsOutsourcing))