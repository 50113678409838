import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select'
import { Translate } from "react-localize-redux";

/** Material UI import section */
import {
    Grid, Typography
} from '@material-ui/core';
import { supplierCanEditBidding } from '../../../../store/helpers/BiddingHelper'

import { getDivisasOptions } from '../../../../store/helpers/SelectOptions'


const ListSuppliers = props => {
    const { divisas,
        isSaving,
        bidding, classes, userProfile, 
        userRolesByCompany,isExternalUser,
        translate
    } = props
    
    let divisasOptions = divisas ? getDivisasOptions(divisas) : []
    let isDisabledInformation = !(supplierCanEditBidding(bidding, userProfile, userRolesByCompany, isExternalUser))
    const [divisaInItem, setDivisaInItem] = useState(null);
    useEffect(() => {
        if (divisaInItem === null && props.bidding && props.bidding.Divisa) {
            let divisasOptions = divisas ? getDivisasOptions(divisas) : []
            let existDivisa = divisasOptions.find(x=>x.value ===props.bidding.Divisa.Code)
            setDivisaInItem(existDivisa)
        }
    }, [props.bidding]);


    const onSetDivisaInBidding = (divisa) => {
        if (props.onDivisaInBiddingFromBackEnd) {
            setDivisaInItem(divisa)
            props.onDivisaInBiddingFromBackEnd(divisa)
        }
    }
    return (
        <Grid container spacing={24}>
            <Grid item xs={12} >
                <Typography className={classes.paddingColor} color="primary" variant='subtitle1'><Translate id="biddingsModule.configurationDivisa" /></Typography>
            </Grid>
            <Grid item xs={12} >
                <Grid container spacing={24}>
                    <Grid item xs={7} >
                        <Typography variant='subtitle2' align="justify"><Translate id="biddingsModule.amountDivisaExpresed" /></Typography>
                    </Grid>
                    <Grid item xs={5} >
                        <Select
                            placeholder={translate("biddingsModule.selectedCurrency")}
                            options={divisasOptions}
                            onChange={(option) => onSetDivisaInBidding(option)}
                            styles={{
                                menu: (base) => ({ ...base, zIndex: 9999 }),
                                menuList: (base) => ({ ...base, paddingTop: 0 }),
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                control: base => ({
                                    ...base,
                                    height: 40,
                                    minHeight: 40,
                                })
                            }}
                            value={divisaInItem}
                            menuPortalTarget={document.parentNode}
                            menuPlacement={'bottom'}
                            isDisabled={isSaving || isDisabledInformation}
                        />
                    </Grid>
                </Grid>
            </Grid><br /><br /><br />

        </Grid>
    );
}


const useCardStyle = ((theme) => ({
    searchBoxContainer: {
        width: "100%"
    },
    paddingColor: {
        paddingRight: "0px !important",
        display: "flex",
        backgroundColor: "#f8f9fa"

    },
    inputProps: {
        height: 6,
    },
    alingItemsCenter: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center"
    },
    colorTypography: {
        marginTop: "-10px"
    }
}))


export default (withStyles(useCardStyle, { withTheme: true })(ListSuppliers))