import { fromJS } from 'immutable';

import * as billingsActions from './BillingsActions'

/**
 * contraActions initial state
 */
export const billingsInitialState = fromJS({
    isLoading: true,
    identifier: "",
    isSaving: false,
    billings: [],
    billing: null,
    billingsCount: 0,
    isDownloadingReport: false,
})

export default function billingsReducer(state = billingsInitialState, action) {
    switch (action.type) {
        case billingsActions.START_LOADING_BILLINGS: {
            return state.merge({
                isLoading: action.isLoading,
                identifier: action.identifier,
            })
        }
        case billingsActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving,
                identifier: action.identifier,
            })
        }
        case billingsActions.SET_BILLINGS_LIST: {
            return state.merge({
                billings: fromJS(action.billings),
                isLoading: false,
                identifier: ""
            })
        }
        case billingsActions.ADD_BILLING:{
            let data = fromJS(action.billing);
            return state.updateIn(['billings'], allBillings => pushBilling(allBillings, data)).set("isSaving", false)
        }
        case billingsActions.SET_BILLINGS_COUNT:{
            return state.merge({
                billingsCount: action.count
            })
        }
        case billingsActions.SET_BILLING:{
            return state.merge({
                isLoading: false,
                identifier: "",
                billing: action.billing
            })
        }
        case billingsActions.ADD_BILLING_ITEM:{
            let data = fromJS(action.item);
            return state.updateIn(['billing', 'items'], allItems => pushItem(allItems, data)).set("isSaving", false)
        }
        default:
            return state;
    }
}

const pushBilling = (allBillings, newBilling) => {
    let indexOfBilling = allBillings.findIndex(d => d.get('token') === newBilling.get('token'));
    if (indexOfBilling >= 0) {
        return allBillings.setIn([indexOfBilling], newBilling);
    }
    return allBillings.unshift(newBilling);
}

const pushItem = (allItems, newItems) => {
    let indexOfItem = allItems.findIndex(d => d.get('token') === newItems.get('token'));
    if (indexOfItem >= 0) {
        return allItems.setIn([indexOfItem], newItems);
    }
    return allItems.push(newItems);
}