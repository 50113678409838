/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserParameters,
     getAuthorizedRequestConfigurationUserFiles } from '../helpers/SessionHelper';


export const START_LOADING_WARRANTY_REPORTS = "START_LOADING_WARRANTY_REPORTS";
export const SET_WARRANTY_REPORTS_LIST = "SET_WARRANTY_REPORTS_LIST";
export const CHANGE_WARRANTY_REPORTS_SORT_CRITERIA = "CHANGE_WARRANTY_REPORTS_SORT_CRITERIA";
export const CHANGE_WARRANTY_REPORTS_QUERY = "CHANGE_WARRANTY_REPORTS_QUERY";
export const CHANGE_WARRANTY_REPORTS_PAGINATION = "CHANGE_WARRANTY_REPORTS_PAGINATION";
export const SET_WARRANTY_REPORTS_COUNT = "SET_WARRANTY_REPORTS_COUNT";
export const IS_SAVING = "IS_SAVING";
export const IS_LOADING = "IS_LOADING";
export const SET_WARRANTY_REPORT = "SET_WARRANTY_REPORT";
export const ADD_FILE_WARRANTY_REPORT_PTM = "ADD_FILE_WARRANTY_REPORT_PTM";
export const REMOVE_FILE_WARRANTY_REPORT_PTM = "REMOVE_FILE_WARRANTY_REPORT_PTM";

/**
 * Action start loading Warranty Reports Ptm
 * @param {*} isLoading 
 */
function isLoadingWarrantyReportsPtm(isLoading) {
    return {
        type: START_LOADING_WARRANTY_REPORTS,
        isLoading: isLoading
    }
}

/**
 * Action to set Warranty Reports Ptm list 
 * @param {*} warrantyReports 
 */
function setWarrantyReportsPtmList(warrantyReports) {
    return {
        type: SET_WARRANTY_REPORTS_LIST,
        warrantyReports: warrantyReports
    }
}

/**
 * Action reducer to set the warrantyReports counter
 *
 * @param {*} count
 * @returns
 */
function setWarrantyReportsPtmCount(count) {
    return {
        type: SET_WARRANTY_REPORTS_COUNT,
        count
    }
}

/**
 * Action to is saving  Warranty Reports Ptm
 * @param {*} isSaving 
 */
function isSaving(isSaving) {
    return {
        type: IS_SAVING,
        isSaving: isSaving
    }
}

/**
 * Action to is loading  Warranty Reports Ptm
 * @param {*} isLoading 
 */
function isLoading(isLoading) {
    return {
        type: IS_LOADING,
        isLoading: isLoading
    }
}

/**
 * Action to set  Warranty Report Ptm
 * @param {*} warrantyReport 
 */
function setWarrantyReport(warrantyReport) {
    return {
        type: SET_WARRANTY_REPORT,
        warrantyReport: warrantyReport
    }
}

/**
 * Add file to state
 *
 * @param {*} files
 * @returns
 */
function addFile(files) {
    return {
        type: ADD_FILE_WARRANTY_REPORT_PTM,
        files
    }
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName) {
    return {
        type: REMOVE_FILE_WARRANTY_REPORT_PTM,
        fileName
    }
}

/**
 * Get all WarrantyReportsPtm by company from backend 
 */
export function loadWarrantyReportsPtmFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {
        dispatch(isLoadingWarrantyReportsPtm(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/warrantyreportptm/all`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setWarrantyReportsPtmList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingWarrantyReportsPtm(false));
                console.error("Error getting the WarrantyReportsPtm by company list", error.response)
            })
    }
}


/**
 * Get WarrantyReportsPtm's count
 * @export
 * @returns
 */
export function getWarrantyReportsPtmCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().warrantyReports ? getState().warrantyReports.get('warrantyReportsCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/warrantyreportptm/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setWarrantyReportsPtmCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the WarrantyReportsPtm count", error.response)
            })
    }
}

/**
 * Action to create warranty report ptm from backend
 */
export function createWarrantyReportPtmFromBackEnd(supplier, description) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let data = {
            CompanyToken: selectedCompany.get('token'),
            DocumentIdentifier: selectedCompany.get('document_identifier'),
            CompanyIdentifier: selectedCompany.get("get_company_identifier"),
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            SupplierToken: supplier.token,
            SupplierName: supplier.name,
            SupplierCode: supplier.code,
            Description: description,
        }

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/warrantyreportptm`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error adding a warranty report ptm from company", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to edit warranty report ptm from backend
 */
export function editWarrantyReportPtmFromBackEnd(supplier, description) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let data = {
            SupplierToken: supplier.token,
            SupplierName: supplier.name,
            SupplierCode: supplier.code,
            Description: description,

        }

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/warrantyreportptm`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error edit a raw material non conformity from company", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Get and set the WarrantyReportPtm in the estate from read from backend
 * DONE
 * @param {*} token 
 */
export function loadWarrantyReportPtmFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(isLoading(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/warrantyreportptm/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setWarrantyReport(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load warranty report ptm", error.response)
                dispatch(isLoading(false))
                return Promise.reject();
            })
    }
}

/**
 * Action to send notification from non conformity
 * @param {*} planningSelected 
 * @param {*} developmentSelected 
 * @param {*} externalUsers 
 */
export function sendWarrantyReportPtmToSupplier(token) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            WarrantyToken: token,
        }

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/warrantyreportptm/sendsupplier`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setWarrantyReport(serverResponse.data))
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to send notications from warrantyreportptm", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to update warrantyreportptm
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFilesEvidence(files) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let tokenQuality = getState().warrantyReports.get('warrantyReport').get('token');
        let configuration = getAuthorizedRequestConfigurationUserFiles(user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/warrantyreportptm/${tokenQuality}/upload`;
        let formData = new FormData();
        for (var index = 0; index <= files.length; index++) {
            formData.append('file', files[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addFile(serverResponse.data));
                    dispatch(isSaving(false));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to upload evidence file(s) from warranty report", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} quoteToken
 * @param {*} fileName
 * @returns
 */
export function removeFilesEvidence(fileName) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let token = getState().warrantyReports.get('warrantyReport').get('token');
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/warrantyreportptm/${token}/remove`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeFile(fileName))
                }
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to remove evidence file(s) from warranty report", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to delete draft warranty
 * @param {*} token 
 */
export function deleteWarrantyInDraft(token) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/workflow/${token}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to delete draft warranty ptm", error.response)
                return Promise.reject();
            })
    }
}