import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
import Select from 'react-select'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

/** Import component section */
import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';
import { GetQuestionnairesType } from '../../../../store/helpers/SelectOptions';

/**
 * Class compoenent to add a template questionnaire
 *
 * @class CardCreateTemplated
 * @extends {Component}
 */
class CardCreateTemplated extends Component {

    /**
     *Creates an instance of DialogActions.
     * @param {*} props
     * @memberof DialogActions
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = this.initialState();
    }

       /**
     * Return initial state
     *
     * @returns
     * @memberof AddModal
     */
    initialState() {
        return ({
            name: null,
            questionnaireType: null,
        })
    }

    /**
     * Handle update properties to set data in local state
     *
     * @param {*} property
     * @param {*} data
     * @memberof AddModal
     */
    updateProperty(property, data) {
        this.setState({
            [property]: data,
        })
    }

    /**
     * method to call action and add item
     *
     * @memberof AddModal
     */
    onAddItem() {
        this.props.addItem(this.state)
            .then((response) => {
                if (response === 'Ok')
                    this.handleCloseCancel()
            })
    }

    /**
     * Handle  Close or cancel event
     *
     * @memberof AddModal
     */
    handleCloseCancel() {
        this.setState(this.initialState(),
            () => this.props.onClose());
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof AddModal
     */
    isValidToSave() {
        if(this.state.name && !IsNullOrEmpty(this.state.name) && 
            this.state.questionnaireType && !IsNullOrEmpty(this.state.questionnaireType))
            return true;
        else
            return false;
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;
        const questionnairesType = GetQuestionnairesType();
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id="questionnaire.addTemplated"/>}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <div>
                        {!this.isValidToSave() &&
                            <Typography variant="caption" color='error'>
                                {<Translate id="common.requiredFields" />}
                            </Typography>
                        }
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => this.handleCloseCancel()}
                            onClose={() => this.handleCloseCancel()}
                            onSaveItem={this.onAddItem}
                            isSaving={this.props.isSaving}
                            canBeSaved={this.isValidToSave()}
                        />
                    </div>
                }>

                <Grid container className={classes.modal} justify='space-between'>
                    <TextField
                        id={"name"}
                        label={<Translate id="questionnaire.nameQuestionnaire"/>}
                        defaultValue={this.state.name}
                        margin="normal"
                        type={"text"}
                        fullWidth
                        required={true}
                        onChange={(event) => this.updateProperty("name", event.target.value)}
                        variant={'outlined'}
                    />
                    <Grid item xs={12}>
                        <br/><Typography variant="caption" color={'textSecondary'}><Translate id="questionnaire.questionnaireTypeSelected" /></Typography>
                        <Select
                            value={questionnairesType ? questionnairesType['questionnaireType'] : ''}
                            className={classes.documentTypeSelect}
                            options={questionnairesType}
                            onChange={(event) => this.updateProperty("questionnaireType", event)}
                            styles={{
                                menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                                menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                            }}
                            menuPortalTarget={document.parentNode}
                            menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
                            menuPlacement={'auto'}
                        /><br/>
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
}

const styles = theme => ({
    modal: {
        marginRight: theme.spacing.unit * 2,
    }
});

export default withRouter(withStyles(styles, { withTheme: true })(CardCreateTemplated));