
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import {Translate} from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import {DELIVERYORDER_ARRIVAL, 
    DELIVERYORDER_WAREHOUSEVERIFICATION, DELIVERYORDER_CLOSED} from '../../../../store/helpers/WorkFlowDocumentsType'


/**
 * Component ot render history partial delivery order
 */
export default class HistoryPartial extends Component{

    /**
     * Create an instance delivery order
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
    }

    /**
     * Method to find document in delivery order action by document_type
     *
     * @param {*} documentType
     * @returns
     * @memberof Show
     */
    findDocument(documentType) {
        if (this.props.deliveryOrder && this.props.deliveryOrder.documents && this.props.deliveryOrder.documents.length > 0)
            return this.props.deliveryOrder.documents.find(document => {
                return document.document_type === documentType
            })
        else return null
    }

    /**
     * Render
     */
    render() {
        const { classes} = this.props;
        let documentArrval = this.findDocument(DELIVERYORDER_ARRIVAL);
        let documentWrehouse = this.findDocument(DELIVERYORDER_WAREHOUSEVERIFICATION);
        let documentClosed = this.findDocument(DELIVERYORDER_CLOSED);

        return (
            <Paper>
                <List>
                    <ListItem>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="deliveryOrderModule.deliveryOrderArrivalDate"/>
                                </Typography>
                                {": "} {documentArrval && documentArrval.creation_date_short}
                            </React.Fragment>
                        } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="deliveryOrderModule.deliveryWarehouseVelification" />
                                    </Typography>
                                    {": "}{documentWrehouse && documentWrehouse.creation_date_short} {documentClosed && " - " + documentClosed.creation_date_short}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="deliveryOrderModule.deliveryClose" />
                                    </Typography>
                                    {": "} {documentClosed && documentClosed.creation_date_short}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                </List>

            </Paper>
        );
    }
}

/**
 * deliveryOrder
 */
HistoryPartial.propTypes = {
    deliveryOrder: PropTypes.object.isRequired
}