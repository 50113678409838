import { fromJS } from 'immutable'

import * as ethicCodeActions from './ethicCodeAction'

/**
 *  Initial state
 */
export const ethicCodeInitialState = fromJS({
	isLoadingEthicCodes: true,
	ethicCodes: [],
	ethicCodeData: null,
	isSavingEthicCode: false,
	sortCriteria: {
		by: 'creation_date',
		ascending: true,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	ethicCodesCount: 0,
	isLoadingEthicCode: true,
	ethicCode: null,
	isLoadingHome: true,
	ethicCodesListHome: null,
	templateEthicCode: null,
})

export default function moduleGuaranteeReducer(state = ethicCodeInitialState, action) {
	switch (action.type) {
		case ethicCodeActions.SET_ETHIC_CODES_COUNT: {
			return state.merge({
				ethicCodesCount: action.ethicCodesCount,
			})
		}
		case ethicCodeActions.SET_ETHIC_CODES_LIST: {
			let ethicCodesLists = fromJS(action.ethicCodesList)
			return state.merge({
				ethicCodes: ethicCodesLists,
				isLoadingEthicCodes: false,
			})
		}
		case ethicCodeActions.CHANGE_ETHIC_CODES_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case ethicCodeActions.CHANGE_ETHIC_CODES_SORT_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case ethicCodeActions.CHANGE_ETHIC_CODES_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case ethicCodeActions.IS_SAVING_ETHIC_CODE: {
			return state.merge({
				isSavingEthicCode: action.isSavingEthicCode,
			})
		}
		case ethicCodeActions.IS_LOADING_ETHIC_CODE: {
			return state.merge({
				isLoadingEthicCode: action.isLoadingEthicCode,
			})
		}
		case ethicCodeActions.SET_ETHIC_CODE: {
			return state.merge({
				isLoadingEthicCode: false,
				ethicCode: fromJS(action.ethicCode),
			})
		}
		case ethicCodeActions.IS_LOADING_IN_VIEW_HOME: {
			return state.merge({
				isLoadingHome: action.isLoadingHome,
			})
		}
		case ethicCodeActions.SET_ETHICS_CODE_IN_HOME: {
			return state.merge({
				isLoadingHome: false,
				ethicCodesListHome: fromJS(action.ethicCodesListHome),
			})
		}
		case ethicCodeActions.SET_TEMPLATE_ETHIC_CODE: {
			return state.merge({
				isLoadingEthicCode: false,
				templateEthicCode: fromJS(action.templeteCode),
			})
		}

		case ethicCodeActions.ADD_SUPPLIER_TO_EXCLUDE_IN_ETHIC_CODE: {
			let supplier = fromJS(action.supplier)
			return state
				.updateIn(['ethicCode', 'exclude_suppliers'], (suppliers) => suppliers.push(supplier))
				.set('isLoadingEthicCodes', false)
		}
		case ethicCodeActions.REMOVE_SUPPLIER_TO_EXCLUDE_IN_ETHIC_CODE: {
			return state.updateIn(['ethicCode', 'exclude_suppliers'], (allSuppliers) => {
				let index = allSuppliers.findIndex((item) => {
					return item.get('supplier_code') === action.supplierCode
				})
				return allSuppliers.delete(index).set('isLoadingEthicCodes', false)
			})
		}

		case ethicCodeActions.ADD_QUESTION_IN_TEMPLATED: {
			let questionToUpdate = fromJS(action.question)
			let stateUpdate = state.updateIn(['templateEthicCode', 'questions'], (questions) => {
				let indexQuestion = questions.findIndex((question) => {
					return question.get('token') === action.question.token
				})

				if (indexQuestion >= 0) {
					return questions.setIn([indexQuestion], questionToUpdate)
				} else {
					return questions.push(questionToUpdate)
				}
			})
			let totalPercentage = stateUpdate
				.getIn(['templateEthicCode', 'questions'])
				.reduce((sum, question) => sum + question.get('percentage'), 0)
			return stateUpdate.setIn(['templateEthicCode', 'percentage_total_plantilla'], totalPercentage)
		}
		case ethicCodeActions.REMOVE_QUESTION_IN_TEMPLATED: {
			let updatedState = state.updateIn(['templateEthicCode', 'questions'], (allQuestions) => {
				let index = allQuestions.findIndex((item) => {
					return item.get('token') === action.questionToken
				})
				return allQuestions.delete(index)
			})
			let totalPercentage = updatedState
				.getIn(['templateEthicCode', 'questions'])
				.reduce((sum, question) => sum + question.get('percentage'), 0)
			return updatedState.setIn(['templateEthicCode', 'percentage_total_plantilla'], totalPercentage)
		}
		case ethicCodeActions.UPDATE_QUESTION_DATA: {
			return state
				.updateIn(['ethicCodesListHome'], (questionnaires) => {
					let index = questionnaires.findIndex((questionnaire) => {
						return questionnaire.get('token') === action.questionnaireToken
					})
					return questionnaires.updateIn([index, 'questions'], (allQuestions) => {
						let questionIndex = allQuestions.findIndex((question) => {
							return question.get('token') === action.questionData.token
						})
						return allQuestions.setIn([questionIndex], fromJS(action.questionData))
					})
				})
				.set('isSavingEthicCode', false)
		}
		case ethicCodeActions.UPDATE_QUESTION_IN_TEMPLATED: {
			let questionUpdate = action.question
			return state
				.updateIn(['templateEthicCode', 'questions'], (questions) => {
					let index = questions.findIndex((item) => {
						return item.get('token') === questionUpdate.token
					})
					return questions.setIn([index], fromJS(action.question))
				})
				.set('isSaving', false)
		}
		case ethicCodeActions.UPDATE_QUESTIONNAIRE_DATA: {
			var questionairesToUpdate = state.get('ethicCodesListHome').toJS()
			let index = questionairesToUpdate.findIndex((questionnaire) => {
				return questionnaire.token === action.questionnaireData.token
			})
			questionairesToUpdate[index] = action.questionnaireData

			return state.merge({
				ethicCodesListHome: questionairesToUpdate,
				isSavingEthicCode: false,
			})
		}
		default:
			return state
	}
}
