///Import react section
import React, { useCallback, useEffect } from 'react';
import { Translate, getTranslate } from "react-localize-redux";
import { useSelector, } from 'react-redux'
/** Import component section */
import Toaster from '../common/Toaster';

/**import helpers section */
import { BillingStatus } from '../../store/helpers/AppConstants'

/**Custom hooks index */
import { useBillingCustomHookShow } from './customHooks/UseCustomHookShow'
import LateralPanelContainer from '../common/LateralPanelComponents/LateralPanelContainer'
import AddItemComponent from './components/items/AddItem'
import AddBillingFile from './components/items/AddBillingFile'
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog';


const Show = (props) => {
    /**String props section */
    const { identifierPanelLateral } = props
    /**Bools props section */
    //const { } = props
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onConfirmPassword } = props
    /**Objects props section */
    const { setIdentifierPanelLateral, confirmPasswordConfigModal } = props
    /**Render hooks section */
    /**Redux store information section*/
    const toasterMessageConfig = useSelector((state) => state.indirectQualifications.get('toasterConfiguration').toJS())
    const isSaving = useSelector((state) => state.billings.get('isSaving'))
    
    const { onCloseToaster, onAddItemInBidding } = useBillingCustomHookShow()
    const translate = useSelector((state) => getTranslate(state.localize))

    /**
     * On add billing from back end
     */
    const onAddItemInBiddingFromBackEnd = (itemModel) => {
        onAddItemInBidding(itemModel)
            .then(response => {
                if (response === true) {
                    onCloseLateralPanel()
                }
            })
    }
    /**Determinate that panel lateral is selected for open */
    const PanelLateralChildren = useCallback({
        ShowAddItemBilling: <AddItemComponent
            onSaveButtonClick={onAddItemInBiddingFromBackEnd}
            translate={translate}
        />,
        ShowAddBillingFile: <AddBillingFile
            onSaveButtonClick={onAddItemInBiddingFromBackEnd}
            translate={translate}
        />,
    },
        [identifierPanelLateral]
    )

    const { LateralPanelComponent, onOpenAndCloseLateralPanel, onCloseLateralPanel } = LateralPanelContainer({
        children: PanelLateralChildren[identifierPanelLateral],
        identifier: identifierPanelLateral,
        setIdentifierPanelLateral: setIdentifierPanelLateral

    });

    const onLateralPanelConfigChange = useCallback(() => {
        if (identifierPanelLateral) {
            onOpenAndCloseLateralPanel()
        }
    }, [identifierPanelLateral]);

    /**Open lateral panel when identifier of panel change */
    useEffect(() => {
        onLateralPanelConfigChange()
    }, [onLateralPanelConfigChange]);

    const onCancelFolio = (confirmationModel) => {
       
    }

    const onSendToSuppliers= () => {
       
    }

    const getMessageByIdentifier = () => {
        switch (confirmPasswordConfigModal.identifier) {
            case BillingStatus.CANCEL:
                return "indirectqualifications.operationsAvailable.messageCancelQualification"
            case BillingStatus.SEND_TO_SUPPLIERS:
                return "billings.operationsAvailable.messageSentToSupplier"
            default:
                return ""
        }
    };

    const getCallBackToInvokeByIndentifier = () => {
        switch (confirmPasswordConfigModal.identifier) {
            case BillingStatus.CANCEL:
                return onCancelFolio
            case BillingStatus.SEND_TO_SUPPLIERS:
                return onSendToSuppliers
            default:
                return ""
        }
    };

    const getIsRequiredComments = () => {
        switch (confirmPasswordConfigModal.identifier) {
            case BillingStatus.CANCEL:
                return true
            default:
                return false
        }
    };

    return (
        <>
            {
                confirmPasswordConfigModal && confirmPasswordConfigModal.opened && <ConfirmPasswordDialog
                    open={confirmPasswordConfigModal.opened}
                    title={<Translate id='common.confirmOperation' />}
                    message1={<Translate id={getMessageByIdentifier()} />}
                    message2={<Translate id='common.enterPassword' />}
                    onClickButtonConfirm={getCallBackToInvokeByIndentifier()}
                    onClose={onConfirmPassword}
                    isSaving={isSaving}
                    isVisibleComments={getIsRequiredComments()}
                    commentRequired={getIsRequiredComments()}
                />
            }

            <LateralPanelComponent />
            <Toaster
                message={<Translate id={toasterMessageConfig.toasterMessage} />}
                open={toasterMessageConfig.showToaster}
                variant={toasterMessageConfig.toasterVariant}
                onClose={() => onCloseToaster()}
            />

        </>
    )
}

export default Show