/**Import react section */
import React from "react";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import Grid from "@material-ui/core/Grid";
/** Import component section */
import LoadingPage from '../../../common/LoadingPage';
import ShowActionPlantViewHeader from './ShowActionPlantViewHeader'
import ActionPlantTable from './ActionPlantTable'
/**import helpers section */
import { COLOR_STATUS_DANGER, COLOR_STATUS_SUCCESS } from '../../../../store/helpers/StatusColorConstants';
/**Custom hooks index */
import { getActionsByCriticalList, getActionsByOpportunityList } from '../../../../store/helpers/IndirectQualificationsHelpers'

const ShowDesktopViewActionPlant = (props) => {
    /**String props section */
    //const { } = props
    /**Bools props section */
    const { isLoading } = props
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onLateralPanelConfig, onConfirmPassword } = props
    /**Objects props section */
    const { supplier, translate, classes } = props
    /**Render hooks section */
    /**Redux store information section*/
    /**
    * Get the card content
    *
    * @memberof Index
    */
    const getCardContent = () => {
        if (isLoading === true) {
            return (
                <LoadingPage />
            )
        } else {
            return (

                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Card className={classes.papper}>
                            <ShowActionPlantViewHeader
                                supplier={supplier}
                                translate={translate}
                                onLateralPanelConfig={onLateralPanelConfig}
                                onConfirmPassword={onConfirmPassword}

                            />
                            <br />
                            <ActionPlantTable
                                title={translate("indirectqualifications.actionPlans.areaCitical")}
                                backgroundColor={classes.backGroundColorActionPlantCriticalTitle}
                                items={getActionsByCriticalList(supplier)}
                                supplier={supplier}
                                onLateralPanelConfig={onLateralPanelConfig}
                            /><br />

                            <ActionPlantTable
                                title={translate("indirectqualifications.actionPlans.areaOpportunity")}
                                backgroundColor={classes.backGroundColorActionPlantOpportunityTitle}
                                items={getActionsByOpportunityList(supplier)}
                                supplier={supplier}
                                onLateralPanelConfig={onLateralPanelConfig}

                            />

                        </Card>
                    </Grid>
                </Grid>
            )
        }
    }
    return getCardContent()
}

const styles = (theme) => ({
    papper: {
        marginTop: "10px"
    },
    root: {
        maxHeight: `calc(100vh - ${265}px)`,
        minHeight: `calc(100vh - ${265}px)`,
        overflowY: "auto",
        padding: "10px",
        overflowX: "auto"
    },
    emptyData: {
        maxHeight: `calc(100vh - ${265}px)`,
        minHeight: `calc(100vh - ${265}px)`,
        overflowY: "auto",
        padding: "10px",
        overflowX: "auto",
        justifyContent: "center",
        alingItems: "center"
    },
    backGroundColorActionPlantCriticalTitle: {
        backgroundColor: COLOR_STATUS_DANGER,
        color: "white",
        height: "30px"
    },
    backGroundColorActionPlantOpportunityTitle: {
        backgroundColor: COLOR_STATUS_SUCCESS,
        color: "white",
        height: "30px"
    }

});

export default withRouter(
    withStyles(styles, { withTheme: true })(ShowDesktopViewActionPlant)
);
