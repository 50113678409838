/** Helpers import section */
import axios from 'axios'
import { IsCurrentNotActiveDirectoryUser } from '../helpers/SessionHelper'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'
// import { searchByPartNumbersByQury } from '../partNumbers/PartNumberAction'
import { underScoreToTitleCase } from '../helpers/StringHelper'

export const CHANGE_MAQUILAS_SORT_CRITERIA = 'CHANGE_MAQUILAS_SORT_CRITERIA'
export const CHANGE_MAQUILAS_SORT_QUERY = 'CHANGE_MAQUILAS_SORT_QUERY'
export const CHANGE_MAQUILAS_PAGINATION = 'CHANGE_MAQUILAS_PAGINATION'
export const START_LOADING_MAQUILAS = 'START_LOADING_MAQUILAS'
export const SET_MAQUILAS_LIST = 'SET_MAQUILAS_LIST'
export const SET_MAQUILAS_COUNT = 'SET_MAQUILAS_COUNT'

export const IS_LOADING_MAQUILA_ORDER = 'IS_LOADING_MAQUILA_ORDER'
export const SET_MAQUILA_ORDER = 'SET_MAQUILA_ORDER'
export const UPDATE_MAQUILA_ORDER = 'UPDATE_MAQUILA_ORDER'
export const ADD_MATERIAL_MACHINED_IN_MAQUILA = 'ADD_MATERIAL_MACHINED_IN_MAQUILA'
export const IS_SAVING_MAQUILA = 'IS_SAVING_MAQUILA'
export const UPDATE_MATERIAL_MACHINED_IN_MAQUILA = 'UPDATE_MATERIAL_MACHINED_IN_MAQUILA'
export const REMOVE_MATERIAL_MACHINED_IN_MAQUILA = 'REMOVE_MATERIAL_MACHINED_IN_MAQUILA'
export const ADD_FILES_MAQUILA_ORDER = 'ADD_FILES_MAQUILA_ORDER'
export const REMOVE_FILES_MAQUILA_ORDER = 'REMOVE_FILES_MAQUILA_ORDER'
export const UPDATE_DETAIL_MACHINED_IN_MAQUILA = 'UPDATE_DETAIL_MACHINED_IN_MAQUILA'
export const ADD_MOLD_IN_MAQUILA = 'ADD_MOLD_IN_MAQUILA'
export const UPDATE_MOLD_IN_MAQUILA = 'UPDATE_MOLD_IN_MAQUILA'
export const REMOVE_MOLD_IN_MAQUILA = 'REMOVE_MOLD_IN_MAQUILA'
export const IS_DOWNLOADING_REPORT_MAQUILA = 'IS_DOWNLOADING_REPORT_MAQUILA'
export const UPDATE_FILE_MATERIAL_MACHINED_PARTIAL_ITEM = 'UPDATE_FILE_MATERIAL_MACHINED_PARTIAL_ITEM'
export const ADD_TOOLING_IN_MAQUILA = 'ADD_TOOLING_IN_MAQUILA'
export const UPDATE_TOOLING_IN_MAQUILA = 'UPDATE_TOOLING_IN_MAQUILA'
export const REMOVE_TOOLING_IN_MAQUILA = 'REMOVE_TOOLING_IN_MAQUILA'

/**
 * Action start loading maquilas
 * @param {*} isLoading
 */
function isLoadingMaquilas(isLoading) {
	return {
		type: START_LOADING_MAQUILAS,
		isLoading: isLoading,
	}
}

/**
 * Action to set maquilas list
 * @param {*} maquilas
 */
function setMaquilassList(maquilas) {
	return {
		type: SET_MAQUILAS_LIST,
		maquilas,
	}
}

/**
 * Action to set maquilas count
 * @param {*} count
 */
function setMaquilasCount(count) {
	return {
		type: SET_MAQUILAS_COUNT,
		count,
	}
}

/**
 * Action to start load maquila order
 */
function startLoadMaquilaOrder(isLoading) {
	return {
		type: IS_LOADING_MAQUILA_ORDER,
		isLoadingMaquila: isLoading,
	}
}

/**
 * Action to set mauqila order
 * @param {*} mauqila
 */
function setMaquilaOrder(maquilaOrder) {
	return {
		type: SET_MAQUILA_ORDER,
		maquilaOrder: maquilaOrder,
	}
}

function isSavingMaquila(isSaving) {
	return {
		type: IS_SAVING_MAQUILA,
		isSavingMaquila: isSaving,
	}
}

/**
 * Add file in maquila orders
 *
 * @param {*} files
 * @returns
 */
function addFileInMaquila(files, listItems) {
	return {
		type: ADD_FILES_MAQUILA_ORDER,
		files,
		listItems,
	}
}

function updateFilesMaterialMachinedPartialItem(materialToken, itemToken, newMaterialPartialItem) {
	return {
		type: UPDATE_FILE_MATERIAL_MACHINED_PARTIAL_ITEM,
		materialToken,
		itemToken,
		newMaterialPartialItem,
	}
}
/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFileInMaquila(fileName, listItems) {
	return {
		type: REMOVE_FILES_MAQUILA_ORDER,
		fileName,
		listItems,
	}
}

/**
 * Action load maquilas from company backend
 */
export function loadMaquilasFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingMaquilas(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorders/filters`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMaquilassList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingMaquilas(false))
				console.error('Error getting the maquila orders by company list', error.response)
			})
	}
}

/**
 *Action to get supplier maquilas count
 *
 * @export
 * @returns
 */
export function getMaquilasCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().shippingOrders.get('maquilasCount')
		if (actualCounter) {
			return
		}
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorders/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMaquilasCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the maquila's count", error.response)
			})
	}
}

/**
 * Get and set the maquila in the estate from read from backend
 * @param {*} token
 */
export function loadMaquilaOrderFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(startLoadMaquilaOrder(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let command = {
			Token: token,
			UserName: user.profile.preferred_username,
			Name: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/detail`
		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMaquilaOrder(serverResponse.data))
					dispatch(startLoadMaquilaOrder(false))
				}
			})
			.catch((error) => {
				console.error('Error to load Shipping Order', error.response)
				dispatch(startLoadMaquilaOrder(false))
				return Promise.reject()
			})
	}
}

function updateMaquilaOrder(maquilaOrder) {
	return {
		type: UPDATE_MAQUILA_ORDER,
		maquilaOrder,
	}
}

/**
 * Action to update maquila order
 * @param {*} token
 * @param {*} property
 * @param {*} propertyData
 */
export function updateMaquilaOrderFromBack(token, requieredDate, required_quality, required_molde, required_tooling) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/update/${token}`
		let data = {
			RequiredPlantDate: requieredDate,
			RequiredQuality: required_quality,
			RequiredMolde: required_molde,
			RequiredTooling: required_tooling,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateMaquilaOrder(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to update maquila order', error.response)
				return Promise.reject()
			})
	}
}

function addMaterialInList(material) {
	return {
		type: ADD_MATERIAL_MACHINED_IN_MAQUILA,
		material,
	}
}

export function addMaterialMachined(maquilaToken, material, productMachined) {
	return (dispatch, getState) => {
		dispatch(isSavingMaquila(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/materialMachined`
		let data = {
			MaquilaToken: maquilaToken,
			Code: material.partNumber ? material.partNumber.value : '',
			Description: material.partNumber ? material.partNumber.name : '',
			MeasurementUnit: material.partNumber.measurement_unit ? material.partNumber.measurement_unit : '',
			Quantity: material.quantity,
			TransportCode: material.transport ? material.transport.code : '',
			TransportName: material.transport ? material.transport.name : '',
			Position: productMachined ? productMachined.position : '',
			DetailMachinedMaterialToken: productMachined ? productMachined.token : '',
			DetailMachinedMaterialCode: productMachined ? productMachined.code : '',
			DetailMachinedMaterialDescription: productMachined ? productMachined.description : '',
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addMaterialInList(serverResponse.data))
					dispatch(isSavingMaquila(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to add material in maquila order', error.response)
				return Promise.reject()
			})
	}
}

function updateMaterialMachined(material) {
	return {
		type: UPDATE_MATERIAL_MACHINED_IN_MAQUILA,
		material,
	}
}

export function updateMaterialMachinedFromBack(maquilaToken, itemUpdate) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/materialMachined/${itemUpdate.token}`
		let data = {
			RecollectionDate: itemUpdate.date_update
				? itemUpdate.date_update.format('DD/MM/YYYY')
				: itemUpdate.recollection_date,
			TransportCode: itemUpdate.supplier ? itemUpdate.supplier.code : itemUpdate.transport_code,
			TransportName: itemUpdate.supplier ? itemUpdate.supplier.name : itemUpdate.transport_name,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateMaterialMachined(serverResponse.data))
					dispatch(isSavingMaquila(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to update maquila order', error.response)
				return Promise.reject()
			})
	}
}

function removeMaterialMachined(actionToken) {
	return {
		type: REMOVE_MATERIAL_MACHINED_IN_MAQUILA,
		actionToken,
	}
}

export function removeMaterialMachinedBackend(maquilaToken, itemToken) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/materialMachined/${itemToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeMaterialMachined(itemToken))
					dispatch(isSavingMaquila(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to remove maquila order', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action Send maquil order from supplier
 * @param {*} folio
 */
export function sendMaquialOrderToSupplier(maquilaToken) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user
		let data = {
			MaquilaToken: maquilaToken,
			UserName: user.profile.preferred_username,
			CreatedBy: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/send`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateMaquilaOrder(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to send maquila order from supplier' + error.response.data.Message, error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to cancel a maquila order
 *
 * @export
 * @param {*} token
 * @returns
 */
export function cancelMaquilaOrderFromBackend(token, razonForCancel) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user
		let data = {
			CancelUserName: user.profile.preferred_username,
			CancelBy: user.profile.name,
			MaquilaToken: token,
			ReasonCancellation: razonForCancel,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/cancel`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingMaquila(false))
					dispatch(updateMaquilaOrder(serverResponse.data))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to cancel a shipping order', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to upload files to supplier from sample
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadFilesMaquilaOrder(maquilaToken, files, listName) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/${listName}`
		let formData = new FormData()
		for (var index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFileInMaquila(serverResponse.data, listName))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload files with list name', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files to supplier from saplme
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeFilesMaquilaOrder(maquilaToken, fileName, listName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}//remove/${listName}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFileInMaquila(fileName, listName))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to upload maquila order file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update maquila order item from supplier
 * @param {*} token
 * @param {*} property
 * @param {*} propertyData
 */
export function updateMaterialsFromSupplier(maquilaToken, itemUpdate) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/materialMachined/${itemUpdate.token}`
		let data = {
			QuantitySend: itemUpdate.quantity_send,
			QuantityWarehouse: itemUpdate.quantity_warehouse,
			Comments: itemUpdate.comments,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateMaterialMachined(serverResponse.data))
					dispatch(isSavingMaquila(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to update materials in maquila order from supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to upload files to supplier in list material
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadFilesInMaterialFromSupplier(maquilaToken, itemToken, files) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/materials/${itemToken}/files`
		let formData = new FormData()
		for (var index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateMaterialMachined(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload files with list name', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files to supplier from saplme
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeFilesMaterialFromSupplier(maquilaToken, itemToken, fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/materials/${itemToken}/files/remove`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateMaterialMachined(serverResponse.data))
					return Promise.resolve()
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to upload maquila order file(s) from supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action add comments
 * @param {*} maquilaToken
 * @param {*} property
 * @param {*} propertyData
 */
export function updateCommentsMaquilaOrder(maquilaToken, property, propertyData) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/update/${maquilaToken}`
		let data = {
			[underScoreToTitleCase(property)]: propertyData ? propertyData.value : '',
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateMaquilaOrder(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to update maquila order', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to close maquila order
 * @param {*} token
 */
export function closeMaquilaOrderFromBackend(token) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user
		let data = {
			UserName: user.profile.preferred_username,
			CloseBy: user.profile.name,
			MaquilaToken: token,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/closed`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingMaquila(false))
					dispatch(updateMaquilaOrder(serverResponse.data))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to close a order', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

function updateDetailMachined(material) {
	return {
		type: UPDATE_DETAIL_MACHINED_IN_MAQUILA,
		material,
	}
}

/**
 * Action to upload files detail machineds
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadFilesDetailMachinedsInMaterial(maquilaToken, itemToken, files) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/products/${itemToken}/evidences`
		let formData = new FormData()
		for (var index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateDetailMachined(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload files with list name', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files detail machineds
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeFilesDetailMachinedsInMaterial(maquilaToken, itemToken, fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/products/${itemToken}/evidences`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateDetailMachined(serverResponse.data))
					return Promise.resolve()
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to upload maquila order file(s) from supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update maquila order item from supplier
 * @param {*} token
 * @param {*} property
 * @param {*} propertyData
 */
export function updatProductMachineds(maquilaToken, itemUpdate) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/products/${itemUpdate.token}`
		let data = {
			Comments: itemUpdate.comments,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateDetailMachined(serverResponse.data))
					dispatch(isSavingMaquila(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to update materials in maquila order from supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to sen notification in plant
 * @param {*} token
 */
export function sendNotificationFromBackend(token) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let data = {
			MaquilaToken: token,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${token}/notifications`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingMaquila(false))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to send notification a order', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

function addMoldInList(molde) {
	return {
		type: ADD_MOLD_IN_MAQUILA,
		molde,
	}
}

/**
 * Action to add molde in list maquila
 * @param {*} maquilaToken
 * @param {*} material
 * @returns
 */
export function addMoldInMaquila(maquilaToken, model) {
	return (dispatch, getState) => {
		dispatch(isSavingMaquila(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/moldsMaquila`
		let data = {
			MaquilaToken: maquilaToken,
			Description: model.description,
			MedidaMoldeExterna: model.medidaMoldeExterna,
			Peso: model.weight,
			DeliveryDate: model.deadline_delivery ? model.deadline_delivery.format('DD/MM/YYYY') : '',
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addMoldInList(serverResponse.data))
					dispatch(isSavingMaquila(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to add molde in maquila order', error.response)
				return Promise.reject()
			})
	}
}

function updateMoldInList(molde) {
	return {
		type: UPDATE_MOLD_IN_MAQUILA,
		molde,
	}
}

/**
 * Action to update molde
 * @param {*} maquilaToken
 * @param {*} property
 * @param {*} propertyData
 */
export function updateMoldeMaquila(maquilaToken, itemToken, property, propertyData) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/moldsMaquila/${itemToken}`
		let data = {
			[underScoreToTitleCase(property)]: propertyData ? propertyData : '',
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateMoldInList(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to update maquila order', error.response)
				return Promise.reject()
			})
	}
}

function deleteMoldInList(actionToken) {
	return {
		type: REMOVE_MOLD_IN_MAQUILA,
		actionToken,
	}
}

/**
 * Action to delete molde in list maquila
 * @param {*} maquilaToken
 * @param {*} material
 * @returns
 */
export function deleteMoldInMaquila(maquilaToken, itemToken) {
	return (dispatch, getState) => {
		dispatch(isSavingMaquila(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/moldsMaquila/${itemToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(deleteMoldInList(serverResponse.data))
					dispatch(isSavingMaquila(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to remove molde in maquila order', error.response)
				return Promise.reject()
			})
	}
}

/**
 * is loading report
 * @param {*} isDonwloading
 */
function isDonwloadingReport(isDonwloading) {
	return {
		type: IS_DOWNLOADING_REPORT_MAQUILA,
		isDonwloading,
	}
}

/**
 *Download report of delivery orders
 *
 * @export
 * @returns
 */
export function downloadMaquilaOrderExcel(maquilaOrderId) {
	return (dispatch, getState) => {
		dispatch(isDonwloadingReport(true))
		let data = {}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaOrderId}/download`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_orden_maquila.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading report maquila', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to cancel a maquila order
 *
 * @export
 * @param {*} token
 * @returns
 */
export function changeStatusMaquilaOrderFromBackend(token, comments, status) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user
		let data = {
			UserName: user.profile.preferred_username,
			CreatedBy: user.profile.name,
			MaquilaToken: token,
			Comments: comments,
			Status: status,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/changestatus`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingMaquila(false))
					dispatch(updateMaquilaOrder(serverResponse.data))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to change status maquila order', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to sync order sap a maquila order
 *
 * @export
 * @param {*} token
 * @returns
 */
export function sendSyncUpFromSAP() {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaordes/synchronize`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingMaquila(false))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to sync orders maquila from sap', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to sync order maquila SAP from pursha order
 * @param {*} orderNumber
 * @returns
 */
export function sendSyncUpFromSAPByOrderNumber(orderNumber) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaordes/synchronize/order/${orderNumber}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingMaquila(false))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to sync orders maquila from sap', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function addUserIInNotificationMaquila(token, userSupplier) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let data = {
			MaquilaToken: token,
			UserName: userSupplier ? userSupplier.value : '',
			CreatedBy: userSupplier ? userSupplier.full_name : '',
			Email: userSupplier ? userSupplier.email : '',
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/usersactions`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingMaquila(false))
					dispatch(updateMaquilaOrder(serverResponse.data))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to change status maquila order', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function AddMaterialMachinedPartialItem(maquilaToken, itemModel) {
	return (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/materialpartialitem`
		let user = getState().oidc.user
		let data = {
			MaquilaOrderToken: maquilaToken,
			MaterialMachinedToken: itemModel ? itemModel.token : '',
			QuantitySend: itemModel ? itemModel.quantity_send : '',
			ShippingTicket: itemModel ? itemModel.shipping_ticket : '',
			QuantityReceived: itemModel ? itemModel.quantity_received : '',
			EntranceWeighingTicket: itemModel ? itemModel.entrance_weighing_ticket : '',
			IsPartialItemBySupplier: IsCurrentNotActiveDirectoryUser(getState().oidc.user),
			UserName: user.profile.preferred_username,
			Name: user.profile.name,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingMaquila(false))
					dispatch(updateMaterialMachined(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to add material partial item in maquila order', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function UpdateMaterialMachinedPartialItem(maquilaToken, materialMachinedToken, item) {
	return (dispatch, getState) => {
		dispatch(isSavingMaquila(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/materialpartialitem`
		let data = {
			MaquilaOrderToken: maquilaToken,
			MaterialMachinedToken: materialMachinedToken,
			MaterialMachinedItemPartialToken: item ? item.token : '',
			QuantitySend: item ? item.quantity_send : '',
			ShippingTicket: item ? item.shipping_ticket : '',
			QuantityReceived: item ? item.quantity_received : '',
			EntranceWeighingTicket: item ? item.entrance_weighing_ticket : '',
			IsPartialItemBySupplier: IsCurrentNotActiveDirectoryUser(getState().oidc.user),
			UserName: user.profile.preferred_username,
			Name: user.profile.name,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingMaquila(false))
					dispatch(updateMaterialMachined(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to update material partial item in maquila order', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Action to upload files to material machined partial item
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function AddEvidenceMaterialMachinedPartialItem(
	maquilaToken,
	materialMachinedToken,
	materialMachinedItemPartialToken,
	files,
	listName
) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let isPartialItemBySupplier = IsCurrentNotActiveDirectoryUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/materialmachinedpartialitem/${materialMachinedToken}/evidences/${materialMachinedItemPartialToken}/${isPartialItemBySupplier}`

		let formData = new FormData()
		for (var index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}

		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(
						updateFilesMaterialMachinedPartialItem(
							materialMachinedToken,
							materialMachinedItemPartialToken,
							serverResponse.data
						)
					)
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to upload files with list name', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files material machined partial item
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function RemoveFileMaterialMachinedPartialItem(
	maquilaToken,
	materialMachinedToken,
	materialMachinedItemPartialToken,
	fileName,
	listName
) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let isPartialItemBySupplier = IsCurrentNotActiveDirectoryUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/materialmachinedpartialitem/${materialMachinedToken}/evidences/${materialMachinedItemPartialToken}/${isPartialItemBySupplier}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(
						updateFilesMaterialMachinedPartialItem(
							materialMachinedToken,
							materialMachinedItemPartialToken,
							serverResponse.data
						)
					)
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				console.error('Error to upload maquila order file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 *Get historial consultation report by range dates
 *
 * @export
 * @returns
 */
export function downloadHistorialConsultationMaquilaOrderExcel(startDate, endDate) {
	return (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		dispatch(isDonwloadingReport(true))
		let data = {
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/historialconsultationreport`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_historial_consulta_orden_de_maquila.xlsx'
					a.click()
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading report maquila', error.response)
				return Promise.reject()
			})
	}
}

function addToolingInList(tooling) {
	return {
		type: ADD_TOOLING_IN_MAQUILA,
		tooling,
	}
}

/**
 * Action to add molde in list maquila
 * @param {*} maquilaToken
 * @param {*} material
 * @returns
 */
export function addToolingInMaquila(maquilaToken, tooling) {
	return (dispatch, getState) => {
		dispatch(isSavingMaquila(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/toolings`
		let data = {
			MaquilaToken: maquilaToken,
			Code: tooling.code,
			Description: tooling.description,
			Quality: tooling.quality,
			Position: tooling.position,
			MU: tooling.mu,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addToolingInList(serverResponse.data))
					dispatch(isSavingMaquila(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to add tooing in maquila order', error.response)
				return Promise.reject()
			})
	}
}

function updateToolingInList(tooling) {
	return {
		type: UPDATE_TOOLING_IN_MAQUILA,
		tooling,
	}
}

/**
 * Action to update molde
 * @param {*} maquilaToken
 * @param {*} property
 * @param {*} propertyData
 */
export function updateToolingMaquila(maquilaToken, itemToken, property, propertyData) {
	return function (dispatch, getState) {
		dispatch(isSavingMaquila(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/toolings/${itemToken}`
		let data = {
			[underScoreToTitleCase(property)]: propertyData ? propertyData : '',
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateToolingInList(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to update tooling maquila order', error.response)
				return Promise.reject()
			})
	}
}

function deleteToolingInList(actionToken) {
	return {
		type: REMOVE_TOOLING_IN_MAQUILA,
		actionToken,
	}
}

/**
 * Action to delete tooling in list maquila
 * @param {*} maquilaToken
 * @param {*} material
 * @returns
 */
export function deleteToolingInMaquila(maquilaToken, itemToken) {
	return (dispatch, getState) => {
		dispatch(isSavingMaquila(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/toolings/${itemToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(deleteToolingInList(serverResponse.data))
					dispatch(isSavingMaquila(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaquila(false))
				console.error('Error to remove tooling in maquila order', error.response)
				return Promise.reject()
			})
	}
}

export function uploadFilesToolings(maquilaToken, itemToken, files) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/toolings/${itemToken}/evidences`
		let formData = new FormData()
		for (var index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateToolingInList(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload files in tooling', error.response)
				return Promise.reject()
			})
	}
}

export function deleteFilesTooling(maquilaToken, itemToken, fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/maquilaorder/${maquilaToken}/toolings/${itemToken}/evidences`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateToolingInList(serverResponse.data))
					return Promise.resolve()
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to upload maquila order file(s) in tooling', error.response)
				return Promise.reject()
			})
	}
}
