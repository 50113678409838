import React from 'react'
import classnames from 'classnames'
import autoBind from 'auto-bind'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import LanguageIcon from '@material-ui/icons/Language'
import ExitToApp from '@material-ui/icons/ExitToApp'
import Tooltip from '@material-ui/core/Tooltip'
import MenuIcon from '@material-ui/icons/Menu'
import BusinessIcon from '@material-ui/icons/BusinessTwoTone'
import NoticesIcon from '@material-ui/icons/Chat'
import PendingNotificationsOutsourcing from '../layouts/PendingNotificationsOutsourcing'

import { withLocalize, Translate } from 'react-localize-redux'

/** Import component */
import DialogChangeLanguage from '../common/DialogChangeLanguage'
import DialogChangeCompany from '../common/DialogChangeCompany'

/** Import imges */
import logotypeSip from '../../resources/images/logotype-sip.png'

/** Resources import themes portal */
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import themeImbera from '../../resources/themes/themeImbera.json'
import themeGarantias from '../../resources/themes/themeGarantias.json'
import themePtm from '../../resources/themes/themePTM.json'
import themeRepare from '../../resources/themes/themeRepare.json'
import themeMetallic from '../../resources/themes/themeMetalicos.json'
import themeTorrey from '../../resources/themes/themeTorrey.json'

import {
	COMPANY_THEME_ACTIVE,
	COMPANY_IMBERA,
	COMPANY_PTM,
	COMPANY_REPARE,
	COMPANY_METALICOS,
	COMPANY_IMBERA_WARRANTY,
	COMPANY_FERSA,
	COMPANY_COMITOR,
	COMPANY_TEQMA,
	COMPANY_PACTO,
	COMPANY_FABATSA,
	COMPANY_IMBERA_COLOMBIA,
	COMPANY_IMBERA_BRASIL,
} from '../../store/helpers/ResourcesHelper'
import { SET_SELETECT_COMPANY } from '../../store/companies/CompanyActions'
import { converListToArray } from '../../store/helpers/ListHelper'

import { ENVIROMENT_TYPE_PRODUCTION } from '../../store/helpers/ResourcesHelper'

/**
 * Anonymous application bar component
 *
 * @class AuthenticatedApplicationBar
 * @extends {React.Component}
 */
class AuthenticatedApplicationBar extends React.Component {
	/**
	 * Constructor
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openLanguageDialog: false,
			openCompanyDialog: false,
			themeActive: createMuiTheme(themeImbera),
			grantingAccessToCompany: null,
			open: false,
			anchorEl: null,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		/// get theme active or change theme
		let themeActive = localStorage.getItem(COMPANY_THEME_ACTIVE)
		if (themeActive) {
			this.onchangeTheme(themeActive)
		}
	}

	/**
	 * Function to change company active or selected
	 * @param {*} companyToChange
	 */
	onChangeCompanySelected(companyToChange) {
		if (this.props.grantedCompanies && this.props.grantedCompanies.length > 0) {
			let grantedCompany = this.props.grantedCompanies.find((company) => {
				return company.document_identifier === companyToChange
			})
			if (grantedCompany) {
				this.props.changeCompanySelected(grantedCompany)
			}
		}
	}

	/**
	 *
	 * Action to set open change lenguage
	 * @memberof CardsContainer
	 */
	setOpelLanguage = (openLaguange) => {
		this.setState({
			openLanguageDialog: openLaguange,
		})
	}

	/**
	 * Change the language in portal
	 * @param {*} laguange
	 */
	changeLanguage(laguange) {
		this.props.setActiveLanguage(laguange)
		localStorage.setItem('language', laguange)
		this.setState({ openLanguageDialog: false })
	}

	/**
	 *
	 * Action to set open change company
	 * @memberof
	 */
	setOpenDialogCompany = (openCompany) => {
		this.setState({
			openCompanyDialog: openCompany,
		})
	}

	/**
	 * Change the company in portal and save company active in local starage
	 * and change theme active
	 * @param {*} company
	 */
	changeCompany(company) {
		this.onchangeTheme(company)
		this.onChangeCompanySelected(company)
		window.location.reload()
	}

	/**
	 * Function to open notifications
	 * @param {*} event
	 */
	onActivNot(event) {
		this.setState({
			open: !this.state.open,
			anchorEl: event.currentTarget,
		})
	}

	/**
	 * Function to change theme company
	 * @param {*} company
	 */
	onchangeTheme(company) {
		switch (company) {
			case COMPANY_IMBERA:
			case COMPANY_IMBERA_COLOMBIA:
			case COMPANY_IMBERA_BRASIL: {
				this.setState({
					themeActive: createMuiTheme(themeImbera),
					openCompanyDialog: false,
				})
				break
			}
			case COMPANY_PTM: {
				this.setState({
					themeActive: createMuiTheme(themePtm),
					openCompanyDialog: false,
				})
				break
			}
			case COMPANY_REPARE: {
				this.setState({
					themeActive: createMuiTheme(themeRepare),
					openCompanyDialog: false,
				})
				break
			}
			case COMPANY_METALICOS: {
				this.setState({
					themeActive: createMuiTheme(themeMetallic),
					openCompanyDialog: false,
				})
				break
			}
			case COMPANY_IMBERA_WARRANTY: {
				this.setState({
					themeActive: createMuiTheme(themeGarantias),
					openCompanyDialog: false,
				})
				break
			}
			case COMPANY_FERSA:
			case COMPANY_COMITOR:
			case COMPANY_TEQMA:
			case COMPANY_PACTO:
			case COMPANY_FABATSA: {
				this.setState({
					themeActive: createMuiTheme(themeTorrey),
					openCompanyDialog: false,
				})
				break
			}
			default:
				break
		}
	}

	/**
	 * Render component
	 *
	 * @returns
	 * @memberof AuthenticatedApplicationBar
	 */
	render() {
		const { classes, open } = this.props
		return (
			<MuiThemeProvider theme={this.state.themeActive}>
				<AppBar
					position='fixed'
					className={classnames(classes.appBar, {
						[classes.appBarShift]: open,
					})}
				>
					<Toolbar disableGutters={!open}>
						<IconButton
							color='inherit'
							aria-label='Open drawer'
							onClick={this.props.onMenuIconClick}
							className={classnames(classes.menuButton, {
								[classes.hide]: open,
							})}
						>
							<MenuIcon />
						</IconButton>
						<span className={classes.barText}>
							<img className='logotype-sip' src={logotypeSip} alt='logotypeSip' />{' '}
						</span>
						<Typography variant='h6' gutterBottom color='inherit' className={classnames(classes.grow)}>
							<Translate id='common.applicationName' />
						</Typography>
						{this.props.grantedCompanies && this.props.grantedCompanies.length > 0 && (
							<React.Fragment>
								{process.env.PUBLIC_URL !== ENVIROMENT_TYPE_PRODUCTION && (
									<Typography variant='h4' gutterBottom className={classnames(classes.grow)}>
										<strong>{<Translate id='common.testingSite' />}</strong>
									</Typography>
								)}
								<span className={classes.barText}>
									<strong>
										<h5> {this.props.selectedCompany.commercial_name}</h5>
									</strong>
								</span>
								<Tooltip title={<Translate id='common.changeCompany' />}>
									<IconButton
										onClick={() => this.setOpenDialogCompany(true)}
										data-cy='btn-exit'
										color='inherit'
									>
										<BusinessIcon color='inherit' />
									</IconButton>
								</Tooltip>
							</React.Fragment>
						)}
						{/**Notifications from Outsoursing */}
						{this.props.notificationsSourcing &&
							this.props.notificationsSourcing.Incidentes.length !== 0 && (
								<PendingNotificationsOutsourcing
									{...this.props}
									onOpen={this.state.open}
									translate={'common.notificationsOutsorcing'}
									badgetCount={this.props.notificationsSourcing.Incidentes.length}
									isLocked={this.props.notificationsSourcing.IsLocked}
									classes={classes}
									onActive={this.onActivNot}
									anchorEl={this.state.anchorEl}
								/>
							)}
						<Tooltip title={<Translate id='menu.officeNotice' />}>
							<IconButton
								onClick={() => {
									this.props.history.push('/officialnotices')
								}}
								color='inherit'
							>
								<NoticesIcon color='inherit' />
							</IconButton>
						</Tooltip>

						<Tooltip title={<Translate id='common.language' />}>
							<IconButton onClick={() => this.setOpelLanguage(true)} data-cy='btn-exit' color='inherit'>
								<LanguageIcon color='inherit' />
							</IconButton>
						</Tooltip>

						<Tooltip title={<Translate id='common.logout' />}>
							<IconButton onClick={this.props.onLogoutRequest} data-cy='btn-exit' color='inherit'>
								<ExitToApp className={classes.logoutButton} color='inherit' />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>

				{/** Dialog change language */}
				<DialogChangeLanguage
					{...this.props}
					openLanguageDialog={this.state.openLanguageDialog}
					setOpelLanguage={this.setOpelLanguage}
					changeLanguage={this.changeLanguage}
				/>

				{/** Dialog change company */}
				<DialogChangeCompany
					{...this.props}
					openCompanyDialog={this.state.openCompanyDialog}
					setOpenDialogCompany={this.setOpenDialogCompany}
					changeCompany={this.changeCompany}
					grantedCompanies={this.props.grantedCompanies}
				/>
			</MuiThemeProvider>
		)
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { sub: '', name: '', email: '', user_type: '' } }
	let grantedCompanies = state.profile.get('grantedCompanies')
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		user: user,
		grantedCompanies: grantedCompanies ? converListToArray(grantedCompanies) : [],
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		notificationsSourcing: state.notifications.get('notificationsSourcing')
			? state.notifications.get('notificationsSourcing').toJS()
			: null,
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		changeCompanySelected: (company) => {
			dispatch({
				type: SET_SELETECT_COMPANY,
				actualCompany: company,
			})
		},
	}
}

const drawerWidth = 240

const layoutStyles = (theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	grow: {
		flexGrow: 1,
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
	},
	button: {
		margin: theme.spacing.unit * 1,
	},
	barText: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
})

export default withLocalize(
	withRouter(
		withStyles(layoutStyles, { withTheme: true })(
			connect(mapStateToProps, mapDispatchToProps)(AuthenticatedApplicationBar)
		)
	)
)
