/**Import react section */
import React from 'react'
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types'
import { Button, Grid, Avatar, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { Translate } from 'react-localize-redux'
import ReactSelect from 'react-select'
import { useQueryParam, DelimitedArrayParam } from 'use-query-params'
import { FilterCaseOptions } from '../../../store/helpers/LoadAlertHelper'

import DateRangePicker from '../../common/DateTimePicker/DateRangePicker'
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'

import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants'

const FilterConfigurationPanel = (props) => {
	//bool props section

	//functions props section
	const {
		onCloseButtonClick,
		handleFilterChange,
		onHandleETARangeChange,
		cleanFilter,
		handleCreationDateRangeChange,
	} = props

	//object props section
	const { classes, dateRangeETA, dateRangeCreationDate } = props
	//bool props section

	//array props section
	const {
		supplierAduanalCatalogOptions,
		sitFolioCatalogOptions,
		trackingLinkCatalogOptions,
		supplierMaterialCatalogOptions,
		referenceInternationalCatalogOptions,
		shippingOrderCatalogOptions,
		bookingNumberCatalogOptions,
	} = props

	const [filterSitFoliosFromUrl] = useQueryParam('SitFolios', DelimitedArrayParam)
	const [filterStatusFromUrlFromUrl] = useQueryParam('statusShipsGo', DelimitedArrayParam)

	const [filterVinculatedFromUrl] = useQueryParam('Vinculated', DelimitedArrayParam)
	const [filterTrackingLinksFromUrl] = useQueryParam('trackingLinks', DelimitedArrayParam)
	const [filterSupplierMaterialsFromUrl] = useQueryParam('supplierMaterials', DelimitedArrayParam)
	const [filterReferenceInternationalsFromUrl] = useQueryParam('referenceInternationals', DelimitedArrayParam)
	const [filterShippingOrdersFromUrl] = useQueryParam('shippingOrders', DelimitedArrayParam)
	const [filterBookingNumbersFromUrl] = useQueryParam('bookingNumbers', DelimitedArrayParam)
	const [filterSupplierAduanalsFromUrl] = useQueryParam('supplierAduanals', DelimitedArrayParam)

	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={<Translate id='common.filter' />}
				onCloseButtonClick={onCloseButtonClick}
				icon={
					<Avatar className={classes.avatar}>
						<AttachmentIcon />
					</Avatar>
				}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								<Translate id='common.selectAnOption' />
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								Fecha de creación
							</Typography>
							<DateRangePicker
								startDate={dateRangeCreationDate.startDate}
								endDate={dateRangeCreationDate.endDate}
								onChangeRange={handleCreationDateRangeChange}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								ETA
							</Typography>
							<DateRangePicker
								startDate={dateRangeETA.startDate}
								endDate={dateRangeETA.endDate}
								onChangeRange={onHandleETARangeChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								<Translate id='common.status' />
							</Typography>
							<ReactSelect
								options={[
									{ value: 'transit', label: <Translate id='common.transit' /> },
									{ value: 'Sailing', label: <Translate id='loadAlerts.shipsGoStatus.sailing' /> },
									{ value: 'closed', label: <Translate id='common.closed' /> },
								]}
								onChange={(options) => handleFilterChange(FilterCaseOptions.StatusShipsGo, options)}
								placeholder='Selecciona una opción'
								isMulti
								value={
									filterStatusFromUrlFromUrl
										? filterStatusFromUrlFromUrl.map((x) => {
												return { value: x, label: x }
										  })
										: []
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								<Translate id='common.linked' />
							</Typography>
							<ReactSelect
								options={[
									{ value: 'NoFolio', label: <em>Sin vincular</em> },
									{ value: 'SIT', label: <em>SIT</em> },
								]}
								onChange={(options) => handleFilterChange(FilterCaseOptions.Vinculate, options)}
								placeholder='Selecciona una opción'
								isMulti
								value={
									filterVinculatedFromUrl
										? filterVinculatedFromUrl.map((x) => {
												return { value: x, label: x }
										  })
										: []
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								Proveedor de material
							</Typography>
							<ReactSelect
								options={supplierMaterialCatalogOptions}
								onChange={(options) => handleFilterChange(FilterCaseOptions.SupplierMaterials, options)}
								placeholder='Selecciona una opción'
								isMulti
								value={
									filterSupplierMaterialsFromUrl
										? filterSupplierMaterialsFromUrl.map((x) => {
												return { value: x, label: x }
										  })
										: []
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								Folios
							</Typography>
							<ReactSelect
								options={sitFolioCatalogOptions}
								onChange={(options) => handleFilterChange(FilterCaseOptions.FoliosSit, options)}
								placeholder='Selecciona una opción'
								isMulti
								value={
									filterSitFoliosFromUrl
										? filterSitFoliosFromUrl.map((x) => {
												return { value: x, label: x }
										  })
										: []
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								Proveedor nacional, extranjero o previo origen
							</Typography>
							<ReactSelect
								options={supplierAduanalCatalogOptions}
								onChange={(options) => handleFilterChange(FilterCaseOptions.SupplierAduanals, options)}
								placeholder='Selecciona una opción'
								isMulti
								value={
									filterSupplierAduanalsFromUrl
										? filterSupplierAduanalsFromUrl.map((x) => {
												return { value: x, label: x }
										  })
										: []
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								Guia de rastreo
							</Typography>
							<ReactSelect
								options={trackingLinkCatalogOptions}
								onChange={(options) => handleFilterChange(FilterCaseOptions.TrackingLinks, options)}
								placeholder='Selecciona una opción'
								isMulti
								value={
									filterTrackingLinksFromUrl
										? filterTrackingLinksFromUrl.map((x) => {
												return { value: x, label: x }
										  })
										: []
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								Referencia internacional
							</Typography>
							<ReactSelect
								options={referenceInternationalCatalogOptions}
								onChange={(options) =>
									handleFilterChange(FilterCaseOptions.ReferenceInternationals, options)
								}
								placeholder='Selecciona una opción'
								isMulti
								value={
									filterReferenceInternationalsFromUrl
										? filterReferenceInternationalsFromUrl.map((x) => {
												return { value: x, label: x }
										  })
										: []
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								Orden de compra
							</Typography>
							<ReactSelect
								options={shippingOrderCatalogOptions}
								onChange={(options) => handleFilterChange(FilterCaseOptions.ShippingOrders, options)}
								placeholder='Selecciona una opción'
								isMulti
								value={
									filterShippingOrdersFromUrl
										? filterShippingOrdersFromUrl.map((x) => {
												return { value: x, label: x }
										  })
										: []
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography variant='subtitle1' color='primary'>
								BL
							</Typography>
							<ReactSelect
								options={bookingNumberCatalogOptions}
								onChange={(options) => handleFilterChange(FilterCaseOptions.BookingNumbers, options)}
								placeholder='Selecciona una opción'
								isMulti
								value={
									filterBookingNumbersFromUrl
										? filterBookingNumbersFromUrl.map((x) => {
												return { value: x, label: x }
										  })
										: []
								}
							/>
						</Grid>
					</Grid>
				</div>
				<Grid container>
					<Grid item xs={12} className={classes.containerBtn}>
						<Button
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='outlined'
							onClick={cleanFilter}
						>
							{<Translate id='common.save' />}
						</Button>
					</Grid>
				</Grid>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	expansionPanelSummary: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputProps: {
		height: 6,
	},
})

FilterConfigurationPanel.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
	/**
	 * Validate if template is correctly
	 */
	onValidateTemplate: propTypes.func,
}

FilterConfigurationPanel.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	onValidateTemplate: () => console.warn('Callback [onValidateTemplate] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(FilterConfigurationPanel))
