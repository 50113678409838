///Import react section
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import { CardHeader, Tooltip, CardContent, Fab } from '@material-ui/core'
import { Translate, getTranslate } from 'react-localize-redux'
import MaquilaIcon from '@material-ui/icons/Description'
import PanelMoldsImport from './components/PanelMoldsImport'
import SettingsIcon from '@material-ui/icons/Settings'
import SyncUpIcon from '@material-ui/icons/AutorenewOutlined'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import SearchInput from '../common/SearchInput/SearchInput'
import IndexMolds from './IndexMolds'
import DonwloadReportDialog from '../common/DonwloadReportDialog'
import PanelSyncUpSap from '../maquilaOrders/components/PanelSyncUpSap'

/** Action and helpers */
import {
	loadMaquilasFromBackEnd,
	getMaquilasCount,
	CHANGE_MAQUILAS_SORT_CRITERIA,
	CHANGE_MAQUILAS_SORT_QUERY,
	CHANGE_MAQUILAS_PAGINATION,
	sendSyncUpFromSAP,
	sendSyncUpFromSAPByOrderNumber,
	downloadHistorialConsultationMaquilaOrderExcel,
} from '../../store/maquilaOrders/MaquilaOrderActions'

import { converListToArray } from '../../store/helpers/ListHelper'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import {
	IsUserInRole,
	ROLE_COMPANYADMINISTRATOR,
	ROLE_SUPPLIERDEVELOPMENT,
	ROLE_SUPPLIERDEVELOPMENTENGINEER,
} from '../../store/helpers/RolesHelper'
import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'

/**
 * Index view
 */
class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showIndex: true,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			itemId: '',
			showMenu: true,
			openSlidePanel: false,
			openSyncUpSap: false,
			openModalHistorialConsultationReport: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadMaquilaOrders(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getMaquilasCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadMaquilaOrders()
			this.props.getMaquilasCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadMaquilaOrders(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Get contracts portal counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		const { classes } = this.props
		if (this.props.maquilas) {
			let totalMaquilas = this.props.maquilas.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{totalMaquilas}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.maquilasCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Get quality Molds
	 */
	getCounterMessageMolds() {
		const { classes } = this.props
		let totalMolds = 0
		return (
			<div>
				{this.props.translate('common.showing')}&nbsp;
				<span className={classes.showingCounter}>{totalMolds}</span>&nbsp;
				{this.props.translate('common.of')}&nbsp;
				<span>{totalMolds}</span>&nbsp;
				{this.props.translate('common.records')}
			</div>
		)
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingMaquilas === true) {
			return <LoadingPage />
		} else if (this.props.maquilas === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.maquilas}
					configuration={TableConfiguration(IsCurrentActiveDirectoryUser(this.props.user))}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(maquilaOrder) => {
						this.props.history.push(`/maquilaOrders/${maquilaOrder.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.maquilasCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Function to open side panel
	 */
	onClickAddButton() {
		this.setState({
			openSlidePanel: true,
		})
	}

	/**
	 * Switch view maquila Order index and import molds
	 *
	 * @memberof Index
	 */
	toggleView() {
		this.setState({ showIndex: !this.state.showIndex })
	}

	onClickSyncUpSAP() {
		this.setState({ openSyncUpSap: true })
	}

	/**
	 * onSendDocumentsProspect
	 * @param {*} comments
	 */
	onSendSyncUpFromSAP() {
		this.props
			.sendSyncUpFromSAP()
			.then(() => {
				this.setState({ openSyncUpSap: false })
				this.props.loadMaquilaOrders(
					this.props.searchQuery,
					this.props.sortCriteria.by,
					this.props.sortCriteria.ascending,
					this.props.page,
					this.props.rowsPerPage
				)
				this.props.getMaquilasCount()
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * onSendDocumentsProspectByOrderNumber
	 * @param {*} comments
	 */
	onSendSyncUpFromSAPByOrderNumber(OrderNumber) {
		this.props
			.sendSyncUpFromSAPByOrderNumber(OrderNumber)
			.then(() => {
				this.setState({ openSyncUpSap: false })
				this.props.loadMaquilaOrders(
					this.props.searchQuery,
					this.props.sortCriteria.by,
					this.props.sortCriteria.ascending,
					this.props.page,
					this.props.rowsPerPage
				)
				this.props.getMaquilasCount()
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * Close modal to report by month
	 *
	 * @memberof Index
	 */
	handleCloseModalHistorialConsultationReport() {
		this.setState({
			openModalHistorialConsultationReport: !this.state.openModalHistorialConsultationReport,
		})
	}

	/**
	 *Download report of from contacts
	 *
	 * @memberof Index
	 */
	handleDownloadReportByDateRangeHistorialConsultation(startDate, endDate) {
		return this.props
			.downloadHistorialConsultationMaquilaOrderExcel(startDate, endDate)
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
				this.handleCloseModalHistorialConsultationReport()
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	/**
	 * Create instance
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={<Avatar>{this.state.showIndex ? <MaquilaIcon /> : <SettingsIcon />}</Avatar>}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.itemsAction}
								onChangeSearchValue={this.onChangeSearchValue}
							/>
							{(IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) ||
								IsUserInRole(ROLE_SUPPLIERDEVELOPMENT, this.props.userRolesByCompany) ||
								IsUserInRole(ROLE_SUPPLIERDEVELOPMENTENGINEER, this.props.userRolesByCompany)) && (
								<Tooltip title={'Sincronizar ordenes de SAP'}>
									<Fab size='small' color='primary' onClick={this.onClickSyncUpSAP}>
										<SyncUpIcon />
									</Fab>
								</Tooltip>
							)}
							{(IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) ||
								IsUserInRole(ROLE_SUPPLIERDEVELOPMENT, this.props.userRolesByCompany) ||
								IsUserInRole(ROLE_SUPPLIERDEVELOPMENTENGINEER, this.props.userRolesByCompany)) && (
								<Tooltip title={<Translate id='maquilaOrders.historialConsultation.reportHistorial' />}>
									<Fab
										size='small'
										color={'primary'}
										className={classes.btnDownloadReport}
										onClick={this.handleCloseModalHistorialConsultationReport}
										isSaving={this.props.isDonwloading}
									>
										<DownloadIcon />
									</Fab>
								</Tooltip>
							)}
						</div>
					}
					title={
						<Typography variant='button'>
							{this.state.showIndex ? (
								<Translate id='maquilaOrders.title' />
							) : (
								<Translate id='maquilaOrders.titleMoldsList' />
							)}
						</Typography>
					}
					subheader={this.state.showIndex ? this.getCounterMessage() : this.getCounterMessageMolds()}
				/>

				<CardContent>{this.state.showIndex ? this.getCardContent() : <IndexMolds />}</CardContent>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>

				{this.state.openSlidePanel && (
					<PanelMoldsImport
						onCloseButtonClick={() => {
							this.setState({ openSlidePanel: false })
						}}
						onSaveItem={this.props.updateMaterialsFromSupplier}
					/>
				)}

				{/** Dialog to sync SAP */}
				<PanelSyncUpSap
					open={this.state.openSyncUpSap}
					onClose={() => {
						this.setState({ openSyncUpSap: false })
					}}
					onSaveItem={this.onSendSyncUpFromSAP}
					onSaveItemByOrderNumber={this.onSendSyncUpFromSAPByOrderNumber}
					isSaving={this.props.isSaving}
				/>

				{/** Dialog to download report */}
				<DonwloadReportDialog
					title={<Translate id='maquilaOrders.historialConsultation.reportHistorial' />}
					open={this.state.openModalHistorialConsultationReport}
					onClose={this.handleCloseModalHistorialConsultationReport}
					isSaving={this.props.isDonwloading}
					onDonwloadItems={this.handleDownloadReportByDateRangeHistorialConsultation}
				/>
			</Card>
		)
	}
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='maquilaOrders.purchaseOrder' />,
			dataSource: 'purchase_order',
		},
		{
			header: <Translate id='maquilaOrders.supplier' />,
			dataSource: 'full_supplier',
		},
		{
			header: <Translate id='maquilaOrders.requieredDate' />,
			dataSource: 'required_plant_date',
		},
	]

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingMaquilas: state.maquilaOrders.get('isLoadingMaquilas'),
		maquilas: state.maquilaOrders.get('maquilas') ? state.maquilaOrders.get('maquilas').toJS() : null,
		sortCriteria: state.maquilaOrders.get('sortCriteria') ? state.maquilaOrders.get('sortCriteria').toJS() : null,
		searchQuery: state.maquilaOrders.get('searchQuery'),
		page: state.maquilaOrders.get('page'),
		rowsPerPage: state.maquilaOrders.get('rowsPerPage'),
		maquilasCount: state.maquilaOrders.get('maquilasCount'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize),
		isSaving: state.maquilaOrders.get('isSavingMaquila'),
		isDonwloading: state.maquilaOrders.get('isDonwloading'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadMaquilaOrders: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadMaquilasFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_MAQUILAS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_MAQUILAS_SORT_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_MAQUILAS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getMaquilasCount: () => {
			return dispatch(getMaquilasCount())
		},
		sendSyncUpFromSAP: () => {
			return dispatch(sendSyncUpFromSAP())
		},
		sendSyncUpFromSAPByOrderNumber: (orderNumber) => {
			return dispatch(sendSyncUpFromSAPByOrderNumber(orderNumber))
		},
		downloadHistorialConsultationMaquilaOrderExcel: (startDate, endDate) => {
			return dispatch(downloadHistorialConsultationMaquilaOrderExcel(startDate, endDate))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	itemsAction: {
		marginRight: '6px',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '25px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	btnDownloadReport: {
		marginLeft: '5px',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
