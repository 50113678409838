import React from "react";
import { Translate } from "react-localize-redux";
import Select from "react-select";

/** Custom components section */
import LateralPanel from "../../../common/LateralPanelComponents/LateralPanel";
import PanelHeader from "../../../common/LateralPanelComponents/PanelHeader";
import { Typography, withStyles } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";

/**
 * Service type panel
 * @param {*} props
 */
const ServiceTypePanel = (props) => {
  const { classes, onClose, serviceTypes, onServiceTypeChange } = props;

  const getSupplierOptions = () => {
    return serviceTypes.map((service) => {
      return {
        value: service.Type,
        label: `${service.Type} - ${service.Description}`,
      };
    });
  };

  return (
    <LateralPanel onClose={onClose} classes={classes}>
      <PanelHeader
        isIconMaterial
        titlePanel={"Tipos de Servicio"}
        onCloseButtonClick={onClose}
        icon={<SettingsIcon color="primary" />}
      />
      <div className={classes.bodyContainer}>
        <Typography variant="body2">
          <Translate id="accreditedWorkshops.serviceTypes.serviceTypes" />
        </Typography>
        <Select
          className={classes.documentTypeSelect}
          options={getSupplierOptions()}
          onChange={onServiceTypeChange}
          styles={{
            // menu: (base) => ({ ...base, zIndex: 10 })
            menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
            menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
          }}
          menuPosition="fixed" //THIS IS REQUIRED IN MODAL!!
          menuPlacement={"auto"}
        />
      </div>
    </LateralPanel>
  );
};

const styles = (theme) => ({
  bodyContainer: {
    padding: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  tabContainer: {
    padding: 10,
  },
  btnCreate: {
    marginTop: "20px",
    width: "100%",
    color: `${theme.palette.secondary.contrastText} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    "&.Mui-disabled": {
      color: `${theme.palette.secondary.contrastText} !important`,
      backgroundColor: `${theme.palette.colors.green} !important`,
      opacity: 0.5,
    },
  },
});

export default withStyles(styles, { withTheme: true })(ServiceTypePanel);
