import React, { Component } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withAnonymousLayout from './layouts/withAnonymousLayout'

/** Material-UI import section */
import slide01 from '../resources/images/home_view.png'
import userManager from '../store/helpers/UserManager'
import { IsNullOrEmpty } from '../store/helpers/StringHelper'
import { ENVIROMENT_TYPE_PRODUCTION } from '../store/helpers/ResourcesHelper'

/**
 * Anonymous view
 *
 * @class AnonymousView
 * @extends {Component}
 */
class AnonymousView extends Component {
	componentDidMount() {
		let relativeUrl = ''
		let relativeUrlActive = window.location.pathname
		if (process.env.PUBLIC_URL === ENVIROMENT_TYPE_PRODUCTION) {
			if (!relativeUrlActive.includes('index.html') && !relativeUrlActive.includes('v1.0')) {
				if (process.env.PUBLIC_URL === ENVIROMENT_TYPE_PRODUCTION) {
					relativeUrl = window.location.pathname.replace('/v2.0/portal/', '')
				} else {
					relativeUrl = window.location.pathname.replace('/', '')
				}
				if (!IsNullOrEmpty(relativeUrl)) {
					localStorage.setItem('relativeUrl', relativeUrl)
					userManager.signinRedirect()
				}
			}
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof AnonymousView
	 */
	render() {
		const { classes } = this.props
		return (
			<React.Fragment>
				<div style={{ backgroundColor: 'rgba(178, 178, 178, 1)' }}>
					<img src={slide01} alt='slider01' className={classes.mainImage} />
				</div>
			</React.Fragment>
		)
	}
}

const styles = (theme) => ({
	mainImage: {
		width: '100vw',
		margin: 'auto',
		paddingTop: '0px',
	},
})

export default withAnonymousLayout(withStyles(styles)(AnonymousView))
