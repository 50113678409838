import { Map, fromJS } from 'immutable';

import * as nonConformitiesActions from './NonConformitiesActions';

/**
 *  Initial state
 */
export const nonConformitiesInitialState = fromJS({
    isLoadingNonConformity: true,
    nonConformities: null,
    isSavingNonConformity: false,
    nonConformitiesCount: null,
    sortCriteria: {
        by: "name",
        ascending: true
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
})

export default function companiesReducer(state = nonConformitiesInitialState, action) {
    switch (action.type) {
        case nonConformitiesActions.START_LOADING_NON_CONFORMITIES: {
            return state.merge({
                isLoadingNonConformity: action.isLoading
            })
        }
        
        case nonConformitiesActions.SET_NON_CONFORMITIES_LIST: {
            let nonConformitiesLists = fromJS(action.nonConformities);
            return state.merge({
                nonConformities: nonConformitiesLists,
                isLoadingNonConformity: false
            })
        }
        case nonConformitiesActions.IS_SAVING_NON_CONFORMITY: {
            return state.merge({
                isSavingNonConformity: action.isSaving
            })
        }
        case nonConformitiesActions.ADD_NON_CONFORMITY_TO_LIST: {
            let nonConformity = Map(action.nonConformity);
            let nonConformitiesList = state.get('nonConformities').push(nonConformity);
            return state.merge({
                nonConformities: nonConformitiesList,
                isSavingNonConformity: false
            });
        }
        case nonConformitiesActions.UPDATE_NON_CONFORMITY: {
            let nonConformitiesList = state.get('nonConformities').map((nonConformityMap) => {
                if (nonConformityMap.get("token") === action.nonConformity.token) {
                    return Map(action.nonConformity);
                }
                return nonConformityMap;
            })
            return state.merge({
                nonConformities: nonConformitiesList,
                isSavingNonConformity: false,
            })
        }
        case nonConformitiesActions.CHANGE_NON_CONFORMITIES_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case nonConformitiesActions.CHANGE_NON_CONFORMITIES_SORT_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case nonConformitiesActions.CHANGE_NON_CONFORMITIES_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case nonConformitiesActions.SET_NON_CONFORMITIES_COUNT: {
            return state.merge({
                nonConformitiesCount: action.count
            })
        }
        
        default:
            return state;
    }
}