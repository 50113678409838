import { fromJS } from 'immutable'

import * as prospectActions from './ProspectAction'

/**
 * prospect action initial state
 */
export const prospectInitialState = fromJS({
	isLoadingProspects: true,
	prospects: null,
	isSavingProspect: false,
	prospectsCount: null,
	sortCriteria: {
		by: 'creation_date',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	isSavingPropect: false,
	isLoadingProspect: true,
	prospect: null,
	prospectHistory: null,
})

export default function companiesReducer(state = prospectInitialState, action) {
	switch (action.type) {
		case prospectActions.START_LOADING_PROSPECTS: {
			return state.merge({
				isLoadingProspects: action.isLoading,
			})
		}
		case prospectActions.SET_PROSPECTS_LIST: {
			return state.merge({
				prospects: fromJS(action.prospects),
				isLoadingProspects: false,
			})
		}
		case prospectActions.CHANGE_PROSPECTS_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case prospectActions.CHANGE_PROSPECTS_SORT_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case prospectActions.CHANGE_PROSPECTS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case prospectActions.SET_PROSPECTS_COUNT: {
			return state.merge({
				prospectsCount: action.count,
			})
		}
		case prospectActions.IS_SAVING_PROSPECT: {
			return state.merge({
				isSavingPropect: action.isSaving,
			})
		}
		case prospectActions.IS_LOADING_PROSPECT: {
			return state.merge({
				isLoadingProspect: action.isLoading,
				prospect: null,
			})
		}
		case prospectActions.SET_PROSPECT: {
			return state.merge({
				isLoadingProspect: false,
				prospect: fromJS(action.prospect),
			})
		}
		case prospectActions.SET_PROSPECTHISTORY: {
			return state.merge({
				isLoadingProspect: false,
				prospectHistory: fromJS(action.prospectHistory),
			})
		}
		case prospectActions.UPDATE_FILE_PROSPECT: {
			return state.updateIn(['prospect', 'prospectus_download_files'], (fileProspect) => {
				let index = fileProspect.findIndex((item) => {
					return item.get('token') === action.fileProspect.token
				})
				return fileProspect.setIn([index], fromJS(action.fileProspect))
			})
		}
		case prospectActions.UPDATE_FILE_SEN_PROSPECT: {
			let oldList = state.getIn(['prospect', 'send_prospectus_download_files']).toJS()
			let filesToAdd = action.fileProspect.filter((newFile) => {
				return !oldList.find((uploadedFile) => {
					return uploadedFile.name === newFile.name
				})
			})
			let newList = [...oldList, ...filesToAdd]
			console.log('🚀 ~ file: ProspectReducer.js:102 ~ companiesReducer ~ newList', newList)
			return state.setIn(['prospect', 'send_prospectus_download_files'], fromJS(newList))
		}
		case prospectActions.ADD_FILE_BY_PROSPECT: {
			let oldList = state.getIn(['prospect', 'attachments']).toJS()
			let filesToAdd = action.files.filter((newFile) => {
				return !oldList.find((uploadedFile) => {
					return uploadedFile.name === newFile.name
				})
			})
			let newList = [...oldList, ...filesToAdd]
			return state.setIn(['prospect', 'attachments'], fromJS(newList))
		}
		case prospectActions.REMOVE_FILE_BY_PROSPECT: {
			let oldList = state.getIn(['prospect', 'attachments']).toJS()
			let newList = oldList.filter((file) => {
				return file.name !== action.fileName
			})
			return state.setIn(['prospect', 'attachments'], fromJS(newList))
		}
		case prospectActions.REMOVE_FILE_SEND_PROSPECT: {
			let oldList = state.getIn(['prospect', 'send_prospectus_download_files']).toJS()
			let newList = oldList.filter((file) => {
				return file.name !== action.fileName
			})
			return state.setIn(['prospect', 'send_prospectus_download_files'], fromJS(newList))
		}
		case prospectActions.REMOVE_DOCUMENT_FROM_PROSPECT: {
			const updatedState = state
				.updateIn(['prospect', 'file_prospects'], (files) => files.filter(f => f.get('token') !== action.itemToken))
				.updateIn(['prospect', 'prospectus_download_files'], (files) => files.filter(f => f.get('token') !== action.itemToken))
				.updateIn(['prospect', 'countTotalFiles'], (countTotalFiles) => countTotalFiles - 1)
			return updatedState
		}
		default:
			return state
	}
}
