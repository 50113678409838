
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import moment from 'moment';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
    ListItemIcon, ListItemAvatar, Avatar, Tooltip, Collapse,
    ListItemSecondaryAction, Fab
} from '@material-ui/core';
import LinkMaterial from '@material-ui/core/Link';
import NoticesIcon from '@material-ui/icons/Chat';
import CategoryIcon from '@material-ui/icons/Category';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import FileIcon from '@material-ui/icons/AttachFile';
import CloundDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import SuppliersIcon from '@material-ui/icons/Group';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import EditUser from '@material-ui/icons/BorderColor';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import SupplierIcon from '@material-ui/icons/ShoppingCartTwoTone';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LoadingPage from '../common/LoadingPage';
import Toaster from '../common/Toaster';
import AddCommonItem from '../common/AddCommonItem';
import DialogEditDates from './components/DialogEditDates';
import SuppliersSelector from '../common/SupplierSelector';
import UploadSuppliersExclude from './components/UploadSuppliersExclude';

/** Import action section */
import { ROLE_COMPANYADMINISTRATOR, IsUserInRole } from '../../store/helpers/RolesHelper';
import { converListToArray } from '../../store/helpers/ListHelper'
import { loadOfficialNotice, removeOfficalNotice, updateOfficeNoticeData, 
    downloadSuppliersExcelReport, addSupplierInListExcludesFromOfficialNotice,
    removeSupplierInListExcludesFromOfficialNotice, importSupplierToExclude, addNoticeRelease} from '../../store/administration/OfficialNoticeActions';
import {IsCurrentActiveDirectoryUser} from '../../store/helpers/SessionHelper';

/**
 * Container show Official notice
 */
class Show extends Component {

    /**
     * Create an instance Official notice
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openSuppliersList: true,
            openDeleteNotice: false,
            openEditNotice: false,
            openSuppliers: true,
            openExcludeSuppliers: false,
            notice: {
                token: "",
                activationDate: "",
                expirationDate: "",
                officialToken:"",
            }
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let officialNoticeToken = this.props.match.params.token;
        this.props.loadOfficialNotice(officialNoticeToken);
        this.props.addNoticeRelease(officialNoticeToken);
    }

    /**
     * componentDidUpdate
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof Index
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.officialNotice !== null) {
            let notice = {
                token: this.props.officialNotice.token, activationDate: this.props.officialNotice.activation_date,
                expirationDate: this.props.officialNotice.expiration_date
            }
            this.setState({
                notice: notice
            })
        }
    }

    /**
     * Handle state to show or hide suppliers list
     *
     * @memberof Show
     */
    handleExpandSuppliersList() {
        this.setState({
            openSuppliersList: !this.state.openSuppliersList
        })
    }

    /**
     * render Collapsable section of suppliers list
     *
     * @param {*} idTranslation
     * @param {*} value
     * @param {*} icon
     * @returns
     * @memberof Show
     */
    renderSuppliersList(idTranslation, value, icon, ) {
        return (
            <React.Fragment>
                <Collapse in={this.state.openSuppliersList} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {value.map(supplier => {
                            return (
                                <React.Fragment key={supplier.token} >
                                    <ListItem key={supplier.share_with_supplier} component='li' className={this.props.classes.nested}>
                                        <ListItemIcon style={{ marginLeft: '3rem', marginRight: '0rem' }}>
                                            <Tooltip title={<Translate id={idTranslation} />}>
                                                {icon}
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary={supplier.share_with_supplier} />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                    </List>
                </Collapse>
                <Divider />
            </React.Fragment>
        )
    }

    /**
     * Function to render documents
     * @param {*} documents 
     */
    renderDocuments(documents, icon) {
        return (
            <React.Fragment>
                <Collapse in={true} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {documents.map(document => {
                            return (
                                <React.Fragment key={document.name}>
                                    <ListItem key={document.name} component='li' className={this.props.classes.nested}>
                                        <LinkMaterial component={'a'} style={{ marginLeft: '3rem' }} target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${document.baseUrl}`}>
                                            {icon}{"  "}{document.name}
                                        </LinkMaterial>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                    </List>
                </Collapse>
            </React.Fragment>
        )
    }

    handleExpandSuppliersListExclude() {
        this.setState({
            openSuppliers: !this.state.openSuppliers
        })
    }

    /**
     * renderSuppliersExpander
     */
    renderSuppliersExpander(){
        return(
            <React.Fragment>
                <ListItem key={<Translate id="contactsupplier.supplierToExluded" />}
                    button={true} onClick={() => { this.handleExpandSuppliersListExclude();}}>
                    <ListItemIcon>
                        <Tooltip title={<Translate id="contactsupplier.supplierToExluded" />}>
                            {<SuppliersIcon />}
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={this.props.classes.inline} color="textPrimary">
                                    <Translate id="contactsupplier.supplierToExluded" />
                                </Typography>
                            </React.Fragment>
                        } />
                    {(this.state.openSuppliers ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
                <Divider />
            </React.Fragment>
        )
    }

            /**
     * On action to open modal masiva
     */
    onClickOptionExclideSuppliers() {
        this.setState({ openExcludeSuppliers: true });
    }


    /**
     * rederListExcludeSuppliers
     * @param {*} excludeSuppliers 
     */
    rederListExcludeSuppliers(excludeSuppliers){
        return(
            <Collapse in={this.state.openSuppliers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                {IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && <ListItem divider key="name" component='li' className={this.props.classes.nested}>
                    <Grid item xs={10} style={{ marginLeft: '3rem'}}>
                        <SuppliersSelector
                            className={this.props.classes.textField}
                            onSupplierSelected={(supplier) => { this.addSupplierInListExcludesInList(supplier) }}
                        />
                    </Grid>
                    <ListItemSecondaryAction>
                        <Tooltip title={<Translate id={"officialNotices.downloadTemplate"} />}>
                            <Fab
                                size="small"
                                color="secondary"
                                onClick={() => { this.onClickOptionExclideSuppliers() }}>
                                <DownloadIcon />
                            </Fab>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>}
                    {excludeSuppliers.size &&  <ListItem  component='li' className={this.props.classes.nested}>
                    {<Translate id="contactsupplier.withoutSuppliersExclide" />}
                </ListItem>}
                    {excludeSuppliers.map(supplier => {
                    return (
                        <ListItem divider key={supplier.supplier_code}>
                            <ListItemIcon style={{ marginLeft: '3rem', marginRight: '0rem'}}>
                                <SupplierIcon style={{ marginTop: '-5px' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="caption" gutterBottom>{supplier.supplier_name}</Typography>} />
                            {IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && <ListItemSecondaryAction>
                                <Tooltip title={<Translate id="common.delete" />}>
                                    <IconButton id={supplier.supplier_code}
                                        disabled={this.props.isSaving}
                                        onClick={(event) => { this.removeSupplierInListExcludesInList(event.currentTarget.id) }}
                                    >
                                        <DeleteOutlinedIcon color='error' />
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>}
                        </ListItem>
                        )
                    })}
                </List>
            </Collapse>
        )
    }

    /**
     * Render item in list of official notice detail
     *
     * @param {*} idTranslation
     * @param {*} value
     * @param {*} icon
     * @param {*} isLink
     * @param {*} isButton
     * @returns
     * @memberof Show
     */
    renderItemList(idTranslation, value, icon, isButton, isBoolean) {
        return (
            <React.Fragment>
                <ListItem key={idTranslation}
                    button={isButton}
                    onClick={() => {
                        if (isButton)
                            this.handleExpandSuppliersList();
                    }}>
                    <ListItemIcon>
                        <Tooltip title={<Translate id={idTranslation} />}>
                            {icon}
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={this.props.classes.inline} color="textPrimary">
                                    <Translate id={idTranslation} />
                                </Typography>
                                {!isButton ?
                                    (
                                        (!isBoolean ?
                                            (": " + value)
                                            : '')
                                    ) : ''
                                }
                            </React.Fragment>
                        } />
                    {isButton && (this.state.openSuppliersList ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
                <Divider />
            </React.Fragment>
        )
    }

    /**
     * Function on delete notice 
     */
    onDeleteNotice() {
        this.setState({
            openDeleteNotice: true,
        })
    }

    /**
     * onDeleteOfficialNotice
     */
    onDeleteOfficialNotice() {
        let officialNoticeToken = this.props.match.params.token;
        this.props.deleteOfficialNotice(officialNoticeToken)
            .then(() => {
                this.setState({
                    openDeleteNotice: false,
                })
                this.props.history.push(`/officialnotices`)
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
    * Function on delete notice 
    */
    onEditNotice() {
        this.setState({
            openEditNotice: true,
        })
    }

    /**
   * Update the properties in notice in state
   *
   * @param {*} user
   * @param {*} value
   * @memberof View
   */
    updateProperty(property, value) {
        let noticeUpdate = { ...this.state.notice };
        noticeUpdate[property] = value;
        this.setState({
            notice: noticeUpdate
        })
    }

    /**
     * Function on update offical notice
     */
    onUpdateOfficialNotice() {
        let noticeUpdate = { ...this.state.notice };
        if (typeof this.state.notice.activationDate === 'string') {
            noticeUpdate["activationDate"] = moment(this.state.notice.activationDate, 'DD/MM/YYYY');
        }
        if (typeof this.state.notice.expirationDate === 'string') {
            noticeUpdate["expirationDate"] = moment(this.state.notice.expirationDate, 'DD/MM/YYYY');
        }
        this.setState({
            notice: noticeUpdate
        }, () => {
            this.props.updateOfficeNoticeData(this.state.notice)
                .then(() => {
                    this.setState({
                        openEditNotice: false,
                        showToaster: true,
                        toasterMessage: <Translate id="common.saveCorrectly" />,
                        toasterVariant: "success"
                    })
                }).catch(() => {
                    this.setState({
                        showToaster: true,
                        toasterMessage: <Translate id="common.errorToSave" />,
                        toasterVariant: "error"
                    })
                });
        });
    }

    /**
     * Function to add supplier in list excludes
     */
    addSupplierInListExcludesInList(supplier){
        if(supplier){
            this.props.addSupplierInListExcludes(this.props.match.params.token, supplier.code)
        }
    }

    /**
     *  Function to remove supplier in list excludes
     * @param {*} supplierCode 
     */
    removeSupplierInListExcludesInList(supplierCode){
        if(supplierCode){
            this.props.removeSupplierInListExcludes(this.props.match.params.token, supplierCode)
        }
    }

    /**
     * Function to upload suppliers list
     * @param {*} attachments 
     */
    uploadListSuppliersExclude(attachments) {
        return this.props.importSupplierToExclude(this.props.match.params.token, attachments)
            .then(() => {
                this.setState({openExcludeSuppliers: false,})
                return Promise.resolve('Ok');
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
                return Promise.reject();
            });
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.officialNotice !== null) {
            const { classes, officialNotice } = this.props;
            let status = officialNotice.is_active ? <Translate id="officialNotices.active" /> : <Translate id="officialNotices.notActive" />;
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Paper>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={officialNotice.is_active ? classes.avatar : ''} >
                                            <Tooltip title={status}>
                                                <NoticesIcon />
                                            </Tooltip>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5" className={classes.inline} color="textPrimary">
                                                {officialNotice.official_notice_type === 1 && <Translate id="officialNotices.notice" />}
                                                {officialNotice.official_notice_type === 2 && <Translate id="officialNotices.comminique" />}
                                            </Typography>
                                        }
                                        secondary={status}
                                    />
                                    {IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && <ListItemSecondaryAction>
                                        <ListItemIcon >
                                            <Tooltip title={<Translate id="common.edit" />}>
                                                <EditUser color="secondary" onClick={this.onEditNotice} />
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemIcon >
                                            <Tooltip title={<Translate id="common.delete" />}>
                                                <DeleteOutlinedIcon color="error" onClick={this.onDeleteNotice} />
                                            </Tooltip>
                                        </ListItemIcon>
                                    </ListItemSecondaryAction>}
                                </ListItem>
                                {this.renderItemList("officialNotices.activationDate", officialNotice.activation_date_long, <CalendarIcon />)}
                                {this.renderItemList("officialNotices.expirationDate", officialNotice.expiration_date_long, <CalendarIcon />)}
                                {officialNotice.accepted_by_supplier && this.renderItemList("officialNotices.setedToAcceptBySupplier", officialNotice.is_office_notice_for_accepted, <CheckCircleIcon />, false, true)}
                                
                                {officialNotice.notes && this.renderItemList("officialNotices.notes", officialNotice.notes, <CategoryIcon />)}

                                {IsCurrentActiveDirectoryUser(this.props.currentUser) && (!officialNotice.nationals && !officialNotice.foreigns) && this.renderItemList("officialNotices.suppliers", this.props.translate("officialNotices.optionAll"), <CheckCircleIcon />, false, false)}
                                {officialNotice.nationals && this.renderItemList("officialNotices.suppliers", this.props.translate("officialNotices.optionNationals"), <CheckCircleIcon />, false, false)}
                                {officialNotice.foreigns && this.renderItemList("officialNotices.suppliers", this.props.translate("officialNotices.optionExtranjeros"), <CheckCircleIcon />, false, false)}
                                {officialNotice.accepted_by_supplier && IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && <ListItem divider>
                                    <Link color="primary" onClick={() => this.props.downloadSuppliersExcelReports(this.props.officialNotice.token)} to="#">
                                        <DownloadIcon />
                                        {" "}{<Translate id="officialNotices.messageToDownload" />}
                                    </Link>
                                </ListItem>}
                                {this.renderItemList("officialNotices.downloadDocument", "", <FileIcon />, false, false)}
                                {this.renderDocuments(officialNotice.documents, <CloundDownloadIcon color='primary' style={{ marginTop: '-5px' }} />)}
                              
                                {IsCurrentActiveDirectoryUser(this.props.currentUser) && this.renderItemList("officialNotices.suppliers", officialNotice.share_with_suppliers, <SuppliersIcon />, false, true)}
                                {IsCurrentActiveDirectoryUser(this.props.currentUser) && this.renderSuppliersList("common.supplier", officialNotice.share_with_suppliers, <PersonIcon color='secondary' />)}

                                {IsCurrentActiveDirectoryUser(this.props.currentUser) && this.renderSuppliersExpander()}
                                {IsCurrentActiveDirectoryUser(this.props.currentUser) && this.rederListExcludeSuppliers(officialNotice.exclude_suppliers)}
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            )
        }
    }

    render() {
        return (
            <Card>
                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {/** Dialog to onDeleteOfficialNotice */}
                < AddCommonItem
                    title={<Translate id="officialNotices.removeOfficial" />}
                    message={<Translate id="officialNotices.messageToRemove" />}
                    open={this.state.openDeleteNotice}
                    onClose={() => { this.setState({ openDeleteNotice: false }) }}
                    onSaveItem={this.onDeleteOfficialNotice}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                />

                <UploadSuppliersExclude
                    open={this.state.openExcludeSuppliers}
                    dropzoneText={this.props.translate('dropzone.dropzoneBaseText')}
                    onClose={() => { this.setState({ openExcludeSuppliers: false }) }}
                    uploadListSuppliersExclude={this.uploadListSuppliersExclude}
                    isSaving={this.props.isSaving}
                />

                {/** Dialog to DialogEditDates */}
                <DialogEditDates
                    open={this.state.openEditNotice}
                    notice={this.state.notice}
                    onClose={() => { this.setState({ openEditNotice: false }) }}
                    updateProperty={this.updateProperty}
                    onSaveItem={this.onUpdateOfficialNotice}
                    isSaving={this.props.isSaving}
                />

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        )
    }

}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let officialNotice = state.officialNotices.get("officialNotice");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        currentUser: user,
        isLoading: state.officialNotices.get('isLoading'),
        officialNotice: officialNotice ? officialNotice.toJS() : null,
        isSaving: state.officialNotices.get('isSaving'),
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadOfficialNotice: (token) => {
            return dispatch(loadOfficialNotice(token));
        },
        addNoticeRelease: (officialNoticeToken) => {
            return dispatch(addNoticeRelease(officialNoticeToken))
        },
        deleteOfficialNotice: (token) => {
            return dispatch(removeOfficalNotice(token))
        },
        updateOfficeNoticeData: (notice) => {
            return dispatch(updateOfficeNoticeData(notice))
        },
        downloadSuppliersExcelReports: (noticeToken) => {
            return dispatch(downloadSuppliersExcelReport(noticeToken))
        },
        addSupplierInListExcludes:(noticeToken, supplierCode) =>{
            return dispatch(addSupplierInListExcludesFromOfficialNotice(noticeToken, supplierCode))
        },
        removeSupplierInListExcludes:(noticeToken, supplierCode) =>{
            return dispatch(removeSupplierInListExcludesFromOfficialNotice(noticeToken, supplierCode))
        },
        importSupplierToExclude:(noticeToken, attachments) =>{
            return dispatch(importSupplierToExclude(noticeToken, attachments))
        }
    }
}

const styles = theme => ({
    actionsToolbar: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    margin: {
        margin: theme.spacing.unit * 2,
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));