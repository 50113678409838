/**import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 200,
	},
})

function HourPickers(props) {
	const { classes, onChange, name, value, label, disabled } = props

	return (
		<form style={classes.hourTextField} noValidate>
			<TextField
				id='time'
				label={label}
				name={name}
				disabled={disabled}
				//defaultValue='18:00'
				onChange={onChange}
				value={value}
				InputLabelProps={{
					shrink: true,
				}}
				inputProps={{
					step: 300, // 5 min
					style: {
						height: '10px',
					},
				}}
				fullWidth
				margin='dense'
				type='time'
				variant='filled'
			/>
		</form>
	)
}

HourPickers.propTypes = {
	classes: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
}

HourPickers.defaultProps = {
	disabled: false,
	name: '',
	label: '',
	onChange: () => console.warn('Callback [onChange] no defined'),
	value: '',
}

export default withStyles(styles)(HourPickers)
