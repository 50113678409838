/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import { ACTIVE_DIRECTORY_USER } from '../helpers/ResourcesHelper'
import { capitalizeFirstLetter } from '../helpers/StringHelper'

export const START_LOADING_COMPANIES = 'START_LOADING_COMPANIES'
export const SET_COMPANIES_LIST = 'SET_COMPANIES_LIST'
export const IS_LOADING_COMPANY = 'IS_LOADING_COMPANY'
export const SET_COMPANY = 'SET_COMPANY'
export const SET_SELETECT_COMPANY = 'SET_SELETECT_COMPANY'
export const IS_SAVING_COMPANY = 'IS_SAVING_COMPANY'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const ADD_COMPANY_TO_LIST = 'ADD_COMPANY_TO_LIST'
export const IS_SAVING_WAREHOUSE = 'IS_SAVING_WAREHOUSE'
export const ADD_WAREHOUSES_IN_COMPANY = 'ADD_WAREHOUSES_IN_COMPANY'
export const REMOVE_WAREHOUSES_IN_COMPANY = 'REMOVE_WAREHOUSES_IN_COMPANY'
export const CHANGE_COMPANIES_SORT_CRITERIA = 'CHANGE_COMPANIES_SORT_CRITERIA'
export const CHANGE_COMPANIES_QUERY = 'CHANGE_COMPANIES_QUERY'
export const SET_ROLES_FOR_COMPANIES = 'SET_ROLES_FOR_COMPANIES'
export const IS_LOADING_COMPANIES_ROLES = 'IS_LOADING_COMPANIES_ROLES'
export const SET_COMPANIES_COUNT = 'SET_COMPANIES_COUNT'
export const IS_SAVING_PRIVACY = 'IS_SAVING_PRIVACY'
export const IS_LOADING_PRIVACY_NOTICE = 'IS_LOADING_PRIVACY_NOTICE'
export const SET_PRIVACY_LIST = 'SET_PRIVACY_LIST'
export const ADD_PRIVACY_TO_LIST = 'ADD_PRIVACY_TO_LIST'
export const REMOVE_PRIVACY_TO_LIST = 'REMOVE_PRIVACY_TO_LIST'
export const SET_ACTIVE_PRIVACY_NOTICE = 'SET_ACTIVE_PRIVACY_NOTICE'

/**
 * Action start loading companies
 * @param {*} isLoading
 */
function isLoadingCompanies(isLoading) {
	return {
		type: START_LOADING_COMPANIES,
		isLoading: isLoading,
	}
}

/**
 * Action to set companies list
 * @param {*} comapnies
 */
function setCompaniesList(companies) {
	return {
		type: SET_COMPANIES_LIST,
		companies: companies,
	}
}

/**
 * Action to start load company
 */
function startLoadCompany(isLoading) {
	return {
		type: IS_LOADING_COMPANY,
		isLoadingCompany: isLoading,
	}
}

/**
 * Action to set company
 * @param {*} company
 */
function setCompany(company) {
	return {
		type: SET_COMPANY,
		company: company,
	}
}

/**
 * Action to is saving Company data
 * @param {*} isSaving
 */
function isSavingCompany(isSaving) {
	return {
		type: IS_SAVING_COMPANY,
		isSaving: isSaving,
	}
}

/**
 * Action from update Company
 * @param {*} cutomer
 */
function updateCompany(company) {
	return {
		type: UPDATE_COMPANY,
		company: company,
	}
}

/**
 * Action from add company to list
 */
function addCompanyToList(company) {
	return {
		type: ADD_COMPANY_TO_LIST,
		company: company,
	}
}

/**
 * Action to is saving warehouse data
 * @param {*} isSaving
 */
function isSavingWarehouse(isSaving) {
	return {
		type: IS_SAVING_WAREHOUSE,
		isSaving: isSaving,
	}
}

/**
 * Action from add warehouse to list
 */
function addWarehousesToList(warehouse) {
	return {
		type: ADD_WAREHOUSES_IN_COMPANY,
		warehouse: warehouse,
	}
}

/**
 * Action from remove warehouse to list
 */
function removeWarehousesToList(tokenWarehouse) {
	return {
		type: REMOVE_WAREHOUSES_IN_COMPANY,
		tokenWarehouse: tokenWarehouse,
	}
}

/**
 * Set the roles for a company
 *
 * @param {*} company
 * @param {*} roles
 */
function setRolesForCompanies(roles) {
	return {
		type: SET_ROLES_FOR_COMPANIES,
		roles,
	}
}

/**
 * Is loading companies roles
 *
 * @param {*} isLoading
 * @returns
 */
function isLoadingCompaniesRoles(isLoading) {
	return {
		type: IS_LOADING_COMPANIES_ROLES,
		isLoading,
	}
}

/**
 * Action reducer to set the companies counter
 *
 * @param {*} count
 * @returns
 */
function setCompaniesCount(count) {
	return {
		type: SET_COMPANIES_COUNT,
		count,
	}
}

/**
 * Action to start load privacy notices
 */
function isSavingPrivacyNotice(isSaving) {
	return {
		type: IS_SAVING_PRIVACY,
		isSaving: isSaving,
	}
}

/**
 * Action start loading manuals
 * @param {*} isLoading
 */
function isLoadingPrivacyNotice(isLoading) {
	return {
		type: IS_LOADING_PRIVACY_NOTICE,
		isLoading: isLoading,
	}
}

/**
 * Action to set privacy Notice list
 * @param {*} privacyNotices
 */
function setprivacyNoticesList(privacyNotices) {
	return {
		type: SET_PRIVACY_LIST,
		privacyNotices: privacyNotices,
	}
}
/**
 * add privacy notice in list
 * @param {*} privacyNotice
 */
function addPrivacyNoticeToList(privacyNotice) {
	return {
		type: ADD_PRIVACY_TO_LIST,
		privacyNotice,
	}
}
/**
 * remove privacy notice in list
 * @param {*} privacyToken
 */
function removePrivacyNoticeToList(privacyToken) {
	return {
		type: REMOVE_PRIVACY_TO_LIST,
		privacyToken,
	}
}

function setActivePrivacyNotice(activePrivacyNotice) {
	return {
		type: SET_ACTIVE_PRIVACY_NOTICE,
		activePrivacyNotice,
	}
}

/**
 * Get all companies from backend
 */
export function loadCompaniesFromBackEnd(query, orderBy, sortAscending) {
	return function (dispatch, getState) {
		dispatch(isLoadingCompanies(true))
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/all`
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let data = {
			CompanyToken: '',
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: 1,
			RowsPerPage: 20,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCompaniesList(serverResponse.data))
					return Promise.resolve(serverResponse)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCompanies(false))
				console.error('Error getting the companies list', error.response)
				return Promise.reject(error)
			})
	}
}

/**
 * Get companies's count
 * DONE
 * @export
 * @returns
 */
export function getCompaniesCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().companies ? getState().companies.get('companiesCount') : null
		if (actualCounter) {
			return
		}

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/count`
		let data = {}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCompaniesCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error getting the companies count', error.response)
			})
	}
}

/**
 * Get the companies granted for a user
 *
 * @export
 * @param {*} userId
 */
export function getCompaniesGrantedToUser(userId) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		// Get the user info
		let identityServerEndPoint = `${process.env.REACT_APP_IDENTITY_SERVER}identity/connect/userinfo`
		return axios
			.post(identityServerEndPoint, null, configuration)
			.then((identityResponse) => {
				if (identityResponse.status === 200) {
					if (identityResponse.data.user_type === ACTIVE_DIRECTORY_USER) {
						// It's an active directory user
						let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${userId}/companies`
						return axios
							.get(endPoint, configuration)
							.then((serverResponse) => {
								if (serverResponse.status === 200) {
									return Promise.resolve(serverResponse)
								} else {
									return Promise.reject()
								}
							})
							.catch((error) => {
								console.error('Error getting the companies granted for user', error)
								return Promise.reject()
							})
					} else {
						// It's a non-active directory user
						let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/supplier/${identityResponse.data.company_code}/companies`
						return axios
							.get(endPoint, configuration)
							.then((serverResponse) => {
								if (serverResponse.status === 200) {
									return Promise.resolve(serverResponse)
								} else {
									return Promise.reject()
								}
							})
							.catch((error) => {
								console.error('Error getting the companies granted for user', error)
								return Promise.reject()
							})
					}
				} else {
				}
			})
			.catch((error) => {
				console.error('Error getting the user info', error)
			})
	}
}

/**
 * Grant access for a company
 *
 * @export
 * @param {*} companyId
 * @param {*} userId
 * @returns
 */
export function grantAccessToCompany(companyId, userId) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${userId}/companies/${companyId}`
		let data = {}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					return Promise.resolve(serverResponse)
				}
			})
			.catch((error) => {
				console.error('Error granting access for a company', error)
				return Promise.reject(error)
			})
	}
}

/**
 * Remove access from a company
 *
 * @export
 * @param {*} companyId
 * @param {*} userId
 * @returns
 */
export function removeAccessFromCompany(companyId, userId) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${userId}/companies/${companyId}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					return Promise.resolve(serverResponse)
				}
			})
			.catch((error) => {
				console.error('Error removing access for a company', error)
				return Promise.reject(error)
			})
	}
}

/**
 * Get and set the company in the estate from read from backend
 * @param {*} token
 */
export function loadCompany(token) {
	return function (dispatch, getState) {
		dispatch(startLoadCompany(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCompany(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to set company', error.response)
				dispatch(startLoadCompany(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to create company
 * @param {*} company
 */
export function createCompanyData(company) {
	return function (dispatch, getState) {
		dispatch(isSavingCompany(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies`
		let data = {
			Name: company.name,
			CommercialName: company.commercial_name,
			RFC: company.rfc,
			Address: company.address,
			ZipCode: company.zip_code,
			PartnershipCode: company.partnership_code,
			Center: company.center,
			CenterFin: company.center_fin,
			AdministratorToken: company.administrator_token,
			Division: company.division,
			DocumentIdentifier: company.document_identifier,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(addCompanyToList(response.data))
				} else {
					dispatch(isSavingCompany(false))
				}
			})
			.catch((error) => {
				console.error('Error create company', error.response)
				dispatch(isSavingCompany(false))
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update company data
 * @param {*} provider
 */
export function updateCompanyData(company) {
	return function (dispatch, getState) {
		dispatch(isSavingCompany(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/${company.token}`

		let data = {
			Name: company.name,
			CommercialName: company.commercial_name,
			RFC: company.rfc,
			Address: company.address,
			ZipCode: company.zip_code,
			PartnershipCode: company.partnership_code,
			Center: company.center,
			CenterFin: company.center_fin,
			AdministratorToken: company.administrator_token,
			Division: company.division,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(updateCompany(response.data))
				} else {
					dispatch(isSavingCompany(false))
				}
			})
			.catch((error) => {
				console.error('Error update company', error.response)
				dispatch(isSavingCompany(false))
				return Promise.reject()
			})
	}
}

/**
 * Upload logo company
 *
 * @export
 * @param {*} user
 * @param {*} fileBase64
 * @returns
 */
export function uploadLogoCompany(companyToken, fileBase64) {
	return function (dispatch, getState) {
		dispatch(isSavingCompany(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/${companyToken}/savelogo`
		let data = { fileBase64 }
		return axios
			.put(endPoint, data, configuration)
			.then((response) => {
				dispatch(updateCompany(response.data))
				return Promise.resolve()
			})
			.catch((err) => {
				dispatch(isSavingCompany(false))
				console.error('Error uploading user avatar', err.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to create company
 * @param {*} company
 */
export function addWarehousesInCompany(tokenCompany, name) {
	return function (dispatch, getState) {
		dispatch(isSavingWarehouse(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/${tokenCompany}/warehouses`
		let data = {
			Name: name,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(addWarehousesToList(response.data))
				}
			})
			.catch((error) => {
				console.error('Error create warehouse in company', error.response)
				dispatch(isSavingWarehouse(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to remove warehouse from company
 * @param {*} company
 */
export function onRemoveWarehouse(tokenCompany, tokenStock) {
	return function (dispatch, getState) {
		dispatch(isSavingWarehouse(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/${tokenCompany}/warehouses/${tokenStock}`

		return axios
			.delete(endPoint, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(removeWarehousesToList(tokenStock))
					dispatch(isSavingWarehouse(false))
				}
			})
			.catch((error) => {
				console.error('Error remove warehouse in company', error.response)
				dispatch(isSavingWarehouse(false))
				return Promise.reject()
			})
	}
}

/**
 * Load the roles for every company
 *
 * @export
 */
export function loadRolesByCompanies() {
	return function (dispatch, getState) {
		dispatch(isLoadingCompaniesRoles(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/roles`
		let data = {}
		return axios
			.post(endPoint, data, configuration)
			.then((rolesResponse) => {
				if (rolesResponse.status === 200) {
					dispatch(isLoadingCompaniesRoles(false))
					dispatch(setRolesForCompanies(rolesResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error getting the companies roles', error.response)
				dispatch(isLoadingCompaniesRoles(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to add company module
 *
 * @export
 * @param {*} tokenCompany
 * @param {*} dataProperty
 * @returns
 */
export function addCompanyModule(tokenCompany, dataProperty) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/companiesmodule/${tokenCompany}`
		let property = capitalizeFirstLetter(dataProperty.value)
		let data = {
			[property]: dataProperty.checked,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(updateCompany(response.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error updating the company module enabled status', error.response)
				dispatch(isLoadingCompaniesRoles(false))
				return Promise.reject()
			})
	}
}

/**
 * Load the external roles for every company
 *
 * @export
 */
export function loadExternalRolesByCompanies() {
	return function (dispatch, getState) {
		dispatch(isLoadingCompaniesRoles(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/roles/external`
		let data = {}
		return axios
			.post(endPoint, data, configuration)
			.then((rolesResponse) => {
				if (rolesResponse.status === 200) {
					dispatch(isLoadingCompaniesRoles(false))
					dispatch(setRolesForCompanies(rolesResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error getting the companies external roles', error.response)
				dispatch(isLoadingCompaniesRoles(false))
				return Promise.reject()
			})
	}
}

/**
 * Action load privacy notices from company backend
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @param {*} companyToken
 */
export function getAllPrivacyNotice(query, orderBy, sortAscending, companyToken) {
	return function (dispatch, getState) {
		dispatch(isLoadingPrivacyNotice(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/getAllPrivacyNotice`
			let data = {
				CompanyToken: companyToken,
				SortBy: orderBy,
				SortAscending: sortAscending,
				Query: query,
				Page: 1,
				RowsPerPage: 20,
			}
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setprivacyNoticesList(serverResponse.data))
					}
				})
				.catch((error) => {
					dispatch(isLoadingPrivacyNotice(false))
					console.error('Error getting manuals list by company', error.response)
				})
		} else {
			dispatch(isLoadingPrivacyNotice(false))
		}
	}
}

export function createPrivacyNoticeData(privacyNotice, companyToken) {
	return function (dispatch, getState) {
		dispatch(isSavingPrivacyNotice(true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/addPrivacyNotice`
		let formData = new FormData()
		formData.append('file', privacyNotice.annexe[0])
		formData.set('CompanyToken', companyToken)
		formData.set('Name', privacyNotice.name)
		formData.set('PrivacyVersion', privacyNotice.version)
		formData.set('IsActive', privacyNotice.isActive)
		formData.set('LanguageSelected', privacyNotice.language ? privacyNotice.language.value : '')

		return axios
			.post(endPoint, formData, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(addPrivacyNoticeToList(response.data))
					return Promise.resolve()
				} else {
					dispatch(isSavingPrivacyNotice(false))
				}
			})
			.catch((error) => {
				console.error('Error create privacy notice portal', error.response)
				dispatch(isSavingPrivacyNotice(false))
				return Promise.reject()
			})
	}
}
/**
 * Remove privacy Notice
 * @param {*} privacyToken
 */
export function removePrivacyNotice(privacyToken) {
	return function (dispatch, getState) {
		dispatch(isSavingPrivacyNotice(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/deletePrivacyNotice/${privacyToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingPrivacyNotice(false))
					dispatch(removePrivacyNoticeToList(privacyToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingPrivacyNotice(false))
				console.error('Error in remove manual token', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Get active privacy Notice
 * @param {*} companyToken
 */
export function getActivePrivacyNotice(companyToken, localStorage) {
	const language = localStorage ? localStorage.getItem('language') :'';
	return function (dispatch, getState) {
		let activePrivacyNotice = getState().companies.get('activePrivacyNotice')
		if (activePrivacyNotice) {
			return
		}

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/activePrivacyNotice/${companyToken}/${language}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setActivePrivacyNotice(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in remove manual token', error.response)
				return Promise.reject()
			})
	}
}
