import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import Toaster from '../common/Toaster'
import OptionsMenu from '../common/OptionsMenu'
import DonwloadReportDialog from '../common/DonwloadReportDialog'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import WarrantyQualityIcon from '@material-ui/icons/FormatAlignJustify'
import AddIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import ArchiveDownloadIcon from '@material-ui/icons/Archive'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadWarehouseRawMaterialsFromBackEnd,
	getWarehouseRawMaterialsCount,
	downloadRawMaterialsReportByRangeDate,
	CHANGE_WAREHOUSE_RAW_MATERIALS_SORT_CRITERIA,
	CHANGE_WAREHOUSE_RAW_MATERIALS_QUERY,
	CHANGE_WAREHOUSE_RAW_MATERIALS_PAGINATION,
} from '../../store/warehouseRawMaterial/WarehouseRawMaterialAction'

import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canBeCreateWarehouseRawMaterial } from '../../store/helpers/RolesHelper'
import DataTable from '../common/DataTable'

/**
 * WarrantyReportsPtmFromBackEnd view
 *
 * @class loadWarehouseRawMaterialsFromBackEnd
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance Warranty Reports Ptm From BackEnd
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			itemToken: null,
			showMenu: true,
			openModalReport: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadWarehouseRawMaterials(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.paper,
				this.props.rowsPerPage
			)
			this.props.getWarehouseRawMaterialsCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadWarehouseRawMaterials()
			this.props.getWarehouseRawMaterialsCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadWarehouseRawMaterials(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.paper,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof Index
	 */
	handleClickOptionsMenu(token) {
		this.setState({
			itemToken: token,
		})
	}

	/**
	 * Handle on close optons menu to set void item token in state
	 *
	 * @memberof Index
	 */
	handleCloseOptionsMenu() {
		if (!this.state.openModalReport) {
			this.setState(
				{
					itemToken: '',
					showMenu: false,
				},
				() => {
					this.setState({ showMenu: true })
				}
			)
		}
	}

	/**
	 * Open modal for set report by mont
	 *
	 * @memberof Index
	 */
	handleOpenModal() {
		this.setState({
			openModalReport: true,
		})
	}

	/**
	 * Close modal to report by month
	 *
	 * @memberof Index
	 */
	handleCloseModal() {
		this.setState(
			{
				openModalReport: false,
			},
			() => this.handleCloseOptionsMenu()
		)
		this.setState({ startDate: '', endDate: '' })
	}

	/**
	 *Download report of raw material by month
	 *
	 * @memberof Index
	 */
	onDownloadReportByRangeDate(startDate, endDate) {
		this.props
			.downloadRawMaterialsReportByRangeDate(startDate, endDate)
			.then(() => {
				this.showSuccessToaster('common.downloadCorrectly')
				this.handleCloseModal()
			})
			.catch((error) => {
				this.showErrorToaster('common.errorToDownload')
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingWarehouseRawMaterials === true) {
			return <LoadingPage />
		} else if (this.props.warehouseRawMaterialsList === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let tableConfiguration = TableInternalConfiguration
			if (!IsCurrentActiveDirectoryUser(this.props.user)) {
				tableConfiguration = TableExtenalInternalConfiguration
			}
			return (
				<DataTable
					data={this.props.warehouseRawMaterialsList}
					configuration={tableConfiguration}
					showFilters
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(warehouseRawMaterial) => {
						this.props.history.push(`/warehouseRawMaterials/${warehouseRawMaterial.token}`)
					}}
					isIndex
					totalRows={this.props.warehouseRawMaterialsCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "errorToSave"
	 *
	 * @param {string} [idTranslation="common.errorToSave"]
	 * @memberof show
	 */
	showErrorToaster(idTranslation = 'common.errorToSave') {
		this.setState({
			showToaster: true,
			toasterVariant: 'error',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "saveCorrectly"
	 *
	 * @param {string} [idTranslation="common.saveCorrectly"]
	 * @memberof Show
	 */
	showSuccessToaster(idTranslation = 'common.saveCorrectly') {
		this.setState({
			showToaster: true,
			toasterVariant: 'success',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 *Render action button in card quality failure report
	 *
	 * @memberof Index
	 */
	renderAction() {
		var options = []
		options.push({
			itemClick: () => this.handleOpenModal(),
			tooltipTranslation: <Translate id='warehouseRawMaterial.downloadReportByMonth' />,
			menuItemIcon: <ArchiveDownloadIcon color='secondary' />,
			isLoading: this.props.isLoading,
		})
		if (this.state.showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === 'item.token'}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	/**
	 * Get warranties report counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getReportCounterMessage() {
		if (this.props.warehouseRawMaterialsList) {
			let totalReports = this.props.warehouseRawMaterialsList.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalReports}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.warehouseRawMaterialsCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof warehouseRawMaterials
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<WarrantyQualityIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>

							{IsCurrentActiveDirectoryUser(this.props.user) &&
								canBeCreateWarehouseRawMaterial(this.props.userRolesByCompany) && (
									<Tooltip title={<Translate id='warehouseRawMaterial.warehouseRawMaterial' />}>
										<Fab
											size='small'
											color='primary'
											onClick={() => {
												this.props.history.push(`/warehouseRawMaterials/create`)
											}}
										>
											<AddIcon />
										</Fab>
									</Tooltip>
								)}

							{IsCurrentActiveDirectoryUser(this.props.user) && this.renderAction()}
						</div>
					}
					title={
						<Typography variant='button'>
							{<Translate id='warehouseRawMaterial.warehouseRawMaterial' />}{' '}
						</Typography>
					}
					subheader={this.getReportCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to download report */}
				<DonwloadReportDialog
					title={<Translate id='warehouseRawMaterial.reportMaterial' />}
					open={this.state.openModalReport}
					onClose={this.handleCloseModal}
					isSaving={this.props.isLoading}
					onDonwloadItems={this.onDownloadReportByRangeDate}
				/>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableInternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		},
		{
			header: <Translate id='warehouseRawMaterial.purchaseOrder' />,
			dataSource: 'purchase_order_number',
		},
		{
			header: <Translate id='warehouseRawMaterial.dateMaterial' />,
			dataSource: 'delivery_time',
		},
		{
			header: <Translate id='warehouseRawMaterial.totalIncidence' />,
			dataSource: 'total_incidence',
		},
	],
}

const TableExtenalInternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='warehouseRawMaterial.purchaseOrder' />,
			dataSource: 'purchase_order_number',
		},
		{
			header: <Translate id='warehouseRawMaterial.dateMaterial' />,
			dataSource: 'delivery_time',
		},
		{
			header: <Translate id='warehouseRawMaterial.totalIncidence' />,
			dataSource: 'total_incidence',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingWarehouseRawMaterials: state.warehouseRawMaterials.get('isLoadingWarehouseRawMaterials'),
		warehouseRawMaterialsList: state.warehouseRawMaterials.get('warehouseRawMaterialsList')
			? state.warehouseRawMaterials.get('warehouseRawMaterialsList').toJS()
			: null,
		sortCriteria: state.warehouseRawMaterials.get('sortCriteria')
			? state.warehouseRawMaterials.get('sortCriteria').toJS()
			: null,
		searchQuery: state.warehouseRawMaterials.get('searchQuery'),
		page: state.warehouseRawMaterials.get('page'),
		rowsPerPage: state.warehouseRawMaterials.get('rowsPerPage'),
		warehouseRawMaterialsCount: state.warehouseRawMaterials.get('warehouseRawMaterialsCount'),
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isLoading: state.warehouseRawMaterials.get('isLoading'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadWarehouseRawMaterials: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadWarehouseRawMaterialsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_WAREHOUSE_RAW_MATERIALS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_WAREHOUSE_RAW_MATERIALS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_WAREHOUSE_RAW_MATERIALS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getWarehouseRawMaterialsCount: () => {
			dispatch(getWarehouseRawMaterialsCount())
		},
		downloadRawMaterialsReportByRangeDate: (startDate, endDate) => {
			return dispatch(downloadRawMaterialsReportByRangeDate(startDate, endDate))
		},
	}
}

const styles = (theme) => ({
	avatar: {},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
