import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import ShippingIcon from '@material-ui/icons/ShoppingCart'
import React, { useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import changeShippingOrdersPagination, {
	CHANGE_SHIPPING_ORDERS_SORT_CRITERIA,
	changeShippingOrdersQuery,
	getShippingOrdersCount,
	loadShippingOrdersFromBackEnd,
} from '../../store/shippingOrders/ShippingOrderAction'
import DataTable from '../common/DataTable'
import SearchInput from '../common/SearchInput/SearchInput'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import { ForeignTradeStatus } from '../../store/helpers/ImportRequestHelper'
import { COLOR_STATUS_INFO, COLOR_STATUS_WARNING } from '../../store/helpers/StatusColorConstants'
import { Chip } from '@material-ui/core'

const Index = (props) => {
	const dispatch = useDispatch()
	let history = useHistory()
	const userProfile = useSelector((state) => state.oidc.user)
	const shippingOrders = useSelector((state) =>
		state.shippingOrders.get('shippingOrders') ? state.shippingOrders.get('shippingOrders').toJS() : []
	)
	const page = useSelector((state) => state.shippingOrders.get('page'))
	const rowsPerPage = useSelector((state) => state.shippingOrders.get('rowsPerPage'))
	const shippingOrdersCount = useSelector((state) => state.shippingOrders.get('shippingOrdersCount'))
	const sortCriteria = useSelector((state) => state.shippingOrders.get('sortCriteria').toJS())
	const query = useSelector((state) => state.shippingOrders.get('searchQuery'))

	const isLoadingShippings = useSelector((state) => state.shippingOrders.get('isLoadingShippings'))

	useEffect(() => {
		dispatch(
			loadShippingOrdersFromBackEnd(
				query,
				sortCriteria.by,
				sortCriteria.ascending,
				page,
				rowsPerPage,
				'LoadAlertView'
			)
		)
		dispatch(getShippingOrdersCount('LoadAlertView'))
	}, [dispatch, query, sortCriteria.by, sortCriteria.ascending, page, rowsPerPage])

	const onChangeSearchValue = (value) => {
		dispatch(changeShippingOrdersQuery(value))
	}

	const onChangeSortCriteria = (sortBy, sortAscending) => {
		dispatch({
			type: CHANGE_SHIPPING_ORDERS_SORT_CRITERIA,
			sortCriteria: {
				by: sortBy,
				ascending: sortAscending,
			},
		})
	}

	const onChangePage = (page, rowsPerPage) => {
		dispatch(changeShippingOrdersPagination(page, rowsPerPage))
	}

	const getShippingOrdersCounterMessage = () => {
		if (shippingOrders) {
			let totalshippingOrders = shippingOrders.length
			return (
				<div>
					{<Translate id='common.showing' />}&nbsp;
					<span>{totalshippingOrders}</span>&nbsp;
					{<Translate id='common.of' />}&nbsp;
					<span>{shippingOrdersCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	/**
	 * Get title to chage status
	 * @returns
	 */
	function onGetStatusDescriptionChip(status) {
		switch (status) {
			case ForeignTradeStatus.Undefined:
			case ForeignTradeStatus.Draft:
				return 'Enviada a proveedor'
			case ForeignTradeStatus.Review:
				return 'En revisión'
			default:
				return ''
		}
	}

	/**
	 * Get title to chage status
	 * @returns
	 */
	function onGetBackgroundColorChip(status) {
		switch (status) {
			case ForeignTradeStatus.Draft:
			case ForeignTradeStatus.Undefined:
				return COLOR_STATUS_WARNING
			case ForeignTradeStatus.Review:
				return COLOR_STATUS_INFO
			default:
				return ''
		}
	}

	const onRendercustomCell = (dataSource, item) => {
		switch (dataSource) {
			case 'foreign_trade_status': {
				return (
					<Chip
						label={onGetStatusDescriptionChip(item.foreign_trade_status)}
						style={{
							borderRadius: '5px',
							width: '100%',
							height: '25px',
							backgroundColor: onGetBackgroundColorChip(item.foreign_trade_status),
						}}
						color='inherit'
					/>
				)
			}
			default:
				return ''
		}
	}

	if (!userProfile) return null

	if (isLoadingShippings === true) {
		return <LoadingPage />
	}
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<ShippingIcon />
					</Avatar>
				}
				action={
					<div>
						<SearchInput onChangeSearchValue={onChangeSearchValue} value={query} />
					</div>
				}
				title={
					<Typography variant='button'>
						<Translate id='menu.importRequests' />{' '}
					</Typography>
				}
				subheader={getShippingOrdersCounterMessage()}
			/>
			<CardContent>
				{
					<DataTable
						data={shippingOrders}
						configuration={TableConfiguration(IsCurrentActiveDirectoryUser(userProfile))}
						sortBy={sortCriteria.by}
						sortAscending={sortCriteria.ascending}
						onChangeSortCriteria={onChangeSortCriteria}
						onRowClick={(shippingOrder) => {
							history.push(`/importRequests/${shippingOrder.token}`)
						}}
						showFilters
						isIndex
						totalRows={shippingOrdersCount}
						page={page}
						onRenderCellItem={onRendercustomCell}
						rowsPerPage={rowsPerPage}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				}
			</CardContent>
		</Card>
	)
}

Index.propTypes = {}

function TableConfiguration(isUserInternal) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'foreign_trade_status',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.code' />,
			dataSource: 'customs_sap_number',
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'customs_sap_name',
			isSortable: true,
		},
		{
			header: <Translate id='importRequests.customsAgentReference' />,
			dataSource: 'advance_agent_customs_reference',
		},
		{
			header: <Translate id='importRequests.customsAgentAdvancePayment' />,
			dataSource: 'advance_agent_customs_payment', //
		},
		{
			header: <Translate id='importRequests.customsAgentComments' />,
			dataSource: 'observations',
		},
	]

	return { columns }
}

export default withauthenticatedLayout(Index)
