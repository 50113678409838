/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { List } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { ListItem } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';

/**Import components section*/


/**Import resources section*/

/**Import actions section */
const DialogOperation = (props) => {
    const { open, onClose } = props;

    /**
     * Get the list of available options
    */
    const getOperationsList = () => {
        if (options.length !== 0) {
            return (<Card>
                {options.map((option) => {
                    return (
                        <ListItem key={option.value} divider>
                            <ListItemText
                                primary={
                                    <Typography component="span" color="primary" variant="subtitle2" gutterBottom>
                                        {option.title}
                                    </Typography>
                                }
                                secondary={
                                    <Typography component="span" display="block" color="textSecondary" variant="caption" gutterBottom>
                                        {<i className="fa fa-info"> </i>}{" "}
                                        {option.description}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    )
                })}
            </Card>
            )
        }
    }

    let options = [
        {
            title: <Translate id="indirectqualifications.scale.bad" />,
        },
        {
            title: <Translate id="indirectqualifications.scale.opportunityArea" />,
        },
        {
            title: <Translate id="indirectqualifications.scale.regular" />,
        },
        {
            title: <Translate id="indirectqualifications.scale.well" />,
        },
        {
            title: <Translate id="indirectqualifications.scale.excelent" />,
        }
    ]

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title" >
            {/* Header title of the dialog */}
            <DialogTitle id="form-dialog-title">
                <DialogContentText>
                    <Typography component="span" variant="h6" color="primary">{<Translate id="indirectqualifications.scale.title" />}</Typography>
                </DialogContentText>
            </DialogTitle>
            <Divider />
            {/* Body of the dialog */}
            <DialogContent>
                <Typography style={{marginTop:"5px"}} variant="subtitle2" align='justify'>Califica a los proveedores en un rango del 1 al 5 donde la numeración significa:</Typography>
                <List>
                    {getOperationsList()}
                </List>
            </DialogContent>
            {/* Button actions on the footer of the dialog */}
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    <Translate id="common.close" />
                </Button>
            </DialogActions>
        </Dialog>
    )
};

const styles = (theme) => ({
});

DialogOperation.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    options: PropTypes.array,
};

DialogOperation.defaultProps = {
    open: false,
    onClose: () =>
        console.warn('Callback [onClose] no defined'),
    options: [],
};

export default withRouter(
    withStyles(styles, { withTheme: true })(DialogOperation)
);
