import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import moment from 'moment'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'

/** Import resources */
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import DatePicker from '../../common/DateTimePicker/DatePicker'

/**
 * Component o ArriveRegisterDate by client
 */
class ArriveRegisterDate extends Component {
	/**
	 * Create an instance ofMemos
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			arriveDate: moment(),
			numberSamples: '',
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.props.numberSampleRequest !== '' && this.state.numberSamples === '') {
			this.setState({ numberSamples: this.props.numberSampleRequest })
		}
	}

	/**
	 * On change date arriveDate
	 * @param {*} arriveDate
	 */
	onChangeArriveDate(arriveDate) {
		this.setState({
			arriveDate: arriveDate,
		})
	}

	/**
	 * Function to update sample number
	 */
	updatePropertyNumberSample(value) {
		this.setState({
			numberSamples: value,
		})
	}

	/**
	 * onSaveRegisterArriveDate
	 */
	onSaveRegisterArriveDate() {
		const arrivalDate = this.state.arriveDate.format('DD/MM/YYYY')
		if (this.props.onRegisterDateArrriveByClient) {
			this.props.onRegisterDateArrriveByClient(arrivalDate, this.state.numberSamples)
		}
	}

	/**
	 * Can be save
	 */
	canBeSave() {
		if (this.state.arriveDate.length !== 0 && this.state.numberSamples.length !== '') {
			return true
		} else {
			return false
		}
	}

	/**
	 *  Dialog to revister arrive date
	 */
	render() {
		const { open, numberOfSampleRequestsDisplayed, classes, onClose } = this.props

        return (
			<Dialog
				open={open}
				onClose={onClose}
				header={
					<Toolbar>
						<Typography variant='h6'>{<Translate id='samplesrequest.regitaArriveDate' />}</Typography>
					</Toolbar>
				}
				actions={
					<DialogActions
						view={SHOW_EDIT_VIEW}
						classes={classes}
						onCancel={onClose}
						onClose={onClose}
						onSaveItem={this.onSaveRegisterArriveDate}
						isSaving={this.props.isSaving}
						canBeSaved={this.canBeSave}
					/>
				}
			>
				<form>
					<Typography>{<Translate id='samplesrequest.dateArrive' />}</Typography>
					<DatePicker
						onChangeRange={this.onChangeArriveDate}
						startDate={this.state.arriveDate}
						maxDate={moment()}
						notshowCalendar={true}
					/>
					<br />
					{
						{
							true: (
								<TextField
									fullWidth
									label={<Translate id='samplesrequest.sampleNumberRecibed' />}
									className={classes.textField}
									defaultValue={this.state.numberSamples}
									onChange={(event) => {
										this.updatePropertyNumberSample(event.target.value)
									}}
									margin='normal'
									helperText={'Número de muestras solicitadas:  ' + this.props.numberSampleRequest}
								/>
							),
							false: (
								<div>
									<Translate id='samplesrequest.sampleNumberRecibedDefatultMessage' />
								</div>
							),
						}[numberOfSampleRequestsDisplayed]
					}
				</form>
			</Dialog>
		)
	}
}

ArriveRegisterDate.props = {
	open: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool,
	numberOfSampleRequestsDisplayed: PropTypes.bool,
	numberSampleRequest: PropTypes.number,
	onClose: PropTypes.func.isRequired,
	onRegisterDateArrriveByClient: PropTypes.func.isRequired,
}

ArriveRegisterDate.defaultProps = {
	open: false,
	isSaving: false,
	numberOfSampleRequestsDisplayed: true,
	onClose: () => console.warn('[onClose] is not defined!'),
	onRegisterDateArrriveByClient: () => console.warn('[onRegisterDateArrriveByClient] is not defined!'),
}

const dialogStyles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	toolBar: {
		backgroundColor: 'red',
	},
	button: {
		margin: theme.spacing.unit,
	},
	errorMessage: {
		color: theme.palette.common.white,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 500,
		marginTop: 10,
		marginBottom: 10,
	},
})

export default withRouter(withStyles(dialogStyles, { withTheme: true })(ArriveRegisterDate))
