import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { getTranslate, Translate } from 'react-localize-redux';

/** Import Material UI section */
import Avatar from '@material-ui/core/Avatar';
import TemplatesIcon from '@material-ui/icons/LibraryBooks';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/AddTwoTone';
import {
    Tooltip, Grid, Button, TextField, Divider, Paper, Typography,
    Card, Tabs, Tab, CardContent, CardHeader, AppBar
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Select from 'react-select'
import InfoIcon from '@material-ui/icons/Announcement';

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import LoadingPage from '../../common/LoadingPage';
import Toaster from '../../common/Toaster';
import QuestionTemplate from './components/QuestionTemplate';
import Dialog from '../../common/Dialog';
import AddCommonItem from '../../common/AddCommonItem';

/** Import action section */
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
import { loadCompany } from '../../../store/companies/CompanyActions';
import { loadSupplier } from '../../../store/suppliers/SupplierActions';
import { converListToArray } from '../../../store/helpers/ListHelper'
import { canBeModuleSustantabilityQuestionnaire } from '../../../store/helpers/RolesHelper';
import { GetSeccionType, GetColorInSectionType, GetQuestionBySection, getSelectedValue } from '../../../store/helpers/SelectOptions';
import { showWarningToaster, showErrorToaster } from '../../../store/helpers/ToasterHelpers';
import {
    loadQuestionnaireFromBackEnd, addSectionInTemplate, removeSectionInTemplate, updateSectionInFromBackend,
    addQuestionInSectionTemplate, removeQuestionInSectionTemplate, updateQuestionInSectionTemplate
} from '../../../store/questionnaires/QuestionnairesAction';


/**
 * Component to show template
 */
class Show extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openAvailableOperationsDialog: false,
            activeTab: 0,
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            openModalSection: false,
            openModalQuestion: false,
            openRemoveSection: false,
            sectionTokenRemove: null,
            section: this.initialStateSectionTemplate(),
            question: this.initStateQuestionTemplate(),
        };
    };

    /**
     * initialStateSectionTemplate
     * @param {*} initialData 
     */
    initialStateSectionTemplate(initialData = null) {
        return ({
            token: initialData ? initialData.token : '',
            shortName: initialData ? initialData.short_name : '',
            description: initialData ? initialData.description : '',
            colorSection: initialData ? initialData.color_section : '',
            percentageSection: initialData ? initialData.percentage_section : '',
            isSectionMultiple: initialData ? (initialData.is_section_multiple ? 1 : 2) : '',
        })
    }

    /**
     * Init question
     * @param {*} initialData 
     */
    initStateQuestionTemplate(initialData = null) {
        return ({
            token: initialData ? initialData.token : '',
            name: initialData ? initialData.name : '',
            questionType: initialData ? initialData.question_type : '',
            percentage: initialData ? initialData.percentage : '',
            responseCorrectly: initialData ? initialData.response_correctly : '',
        })
    }


    /**
     * componentDidMount
     */
    componentDidMount() {
        let questionnaireToken = this.props.match.params.token;
        this.props.loadQuestionnaire(questionnaireToken);
    }

    /**
     * changeSelectTab
     * @param {*} index 
     */
    changeSelectTab(index) {
        this.setState({ activeTab: Number(index) })
    }

    /**
     * 
     * @param {*} sectionToken 
     */
    onClickAddEditSection(sectionToken = null) {
        var sectionData = null;
        let isSectionEdit = false;
        if (!IsNullOrEmpty(sectionToken)) {
            sectionData = this.props.questionnaire.sections.find(section => section.token === sectionToken);
            isSectionEdit = true;
        }
        this.setState({
            openModalSection: true,
            isSectionEdit: isSectionEdit,
            section: this.initialStateSectionTemplate(sectionData),
        });
    }

    /**
    * On close and clear modal 
    */
    onCloseModalAddEditSection() {
        this.setState({
            openModalSection: false,
            isSectionEdit: false,
            section: this.initialStateSectionTemplate(),
        });
    }

    /**
* Render Add Section
*/
    renderAddSection() {
        const seccionType = GetSeccionType();
        const colorType = GetColorInSectionType();
        return (
            <div style={{ margin: 10, width: 500 }}>
                <Grid container spacing={24}>
                    {this.state.isSectionEdit && <Typography style={{ marginLeft: "12px" }}><strong>{this.state.section.isSectionMultiple && getSelectedValue(seccionType, this.state.section.isSectionMultiple).label}</strong></Typography>}
                    {this.renderInput("questionnaire.nameSection", "shortName", 12, null, "text", true)}
                    {this.renderInput("questionnaire.descriptionSection", "description", 12, null, "text", true, true)}
                    {!this.state.isSectionEdit && this.renderInput("questionnaire.sectionType", "isSectionMultiple", 6, seccionType, "selected", true)}
                    {this.renderInput("questionnaire.colorSection", "colorSection", 6, colorType, "selected", true)}
                    {/* {this.state.section.isSectionMultiple === 2 && this.renderInput("ethicCode.questionPercentage", "percentageSection", 6, null, "number", true)} */}
                </Grid>
            </div>
        )
    }

    /**
     * Handle update properties to set data in local state
     *
     * @param {*} property
     * @param {*} data
     * @memberof 
     */
    updateProperty(property, value, isSection) {
        if (isSection) {
            let sectionUpdate = { ...this.state.section };
            sectionUpdate[property] = value;
            this.setState({
                section: sectionUpdate
            })
        }
        else {
            let questionUpdate = { ...this.state.question };
            questionUpdate[property] = value;
            this.setState({
                question: questionUpdate
            })
        }
    }

    /**
    * canBeSaved
    */
    canBeSaved(isSection) {
        if (isSection) {
            if (this.state.section.shortName === "" || this.state.section.description === "" ||
                this.state.section.colorSection === "" || this.state.section.isSectionMultiple === "")
                return false;
            return true;
        }
        else {
            if (this.state.question.name === "" || this.state.question.questionType === "")
                return false;
            if (this.state.question.questionType === 3 || this.state.question.questionType === 4) {
                if (this.state.question.percentage === "" || this.state.question.responseCorrectly === "")
                    return false;
            }
            return true;
        }
    }

    /**
    * onClickRemoveSection
    *
    * @param {*} item
    * @memberof Create
    */
    onClickRemoveSection(sectionToken) {
        this.setState({
            openRemoveSection: true,
            sectionTokenRemove: sectionToken,
        })
    }

    /**
     * Render Text Area component
     *
     * @param {*} key
     * @param {*} translationId
     * @returns
     * @memberof 
     */
    renderInput(translationId, key, lg = 12, options, type, isSection, isMultiple = false) {
        return (
            <Grid item xs={12} sm={12} md={12} lg={lg} xl={lg} className={this.props.classes.textField}>
                {(type === "text" || type === "number") &&
                    <TextField
                        autoFocus={key === 'name'}
                        key={key}
                        fullWidth
                        type={type}
                        label={<Translate id={translationId} />}
                        value={isSection ? (this.state.section[key] ? this.state.section[key] : '') : (this.state.question[key] ? this.state.question[key] : '')}
                        onChange={(event) => { this.updateProperty(key, event.target.value, isSection) }}
                        multiline={isMultiple}
                        rowsMax={isMultiple ? 3 : 1}
                        variant={isMultiple ? 'outlined' : 'standard'}
                    />}
                {type === "selected" && <div>
                    <Typography variant="caption" color={'textSecondary'}><Translate id={translationId} /></Typography>
                    <Select
                        defaultValue={isSection ? (getSelectedValue(options, this.state.section[key])) : (getSelectedValue(options, this.state.question[key]))}
                        className={this.props.classes.documentTypeSelect}
                        options={options}
                        onChange={(event) => this.updateProperty(key, event.value, isSection)}
                        styles={{
                            menu: (base) => ({ ...base, zIndex: 62 }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 })
                        }}
                        menuPortalTarget={document.parentNode}
                        menuPlacement={'bottom'}
                        menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
                    /></div>}
            </Grid>
        )
    }

    /**
     * Render button action dialog
     * @param {*} isSection 
     */
    renderModalButtons(isSection) {
        return (
            <DialogActions>
                <Button
                    disabled={!this.canBeSaved(isSection) || this.props.isSavingTemplate}
                    onClick={isSection ? (() => (this.onAddSectionInTemplated())) : () => (this.onAddQuestionInSectionTemplated())}
                    color="primary">
                    <Translate id="common.save" />
                </Button>
                <Button
                    onClick={isSection ? (() => this.onCloseModalAddEditSection()) : (() => this.onCloseModalAddEditQuestion())}
                    color="secondary">
                    <Translate id="common.cancel" />
                </Button>
            </DialogActions>
        )
    }

    /**
     * onAddSectionInTemplated
     */
    onAddSectionInTemplated() {
        if (this.state.section.token === "") {
            this.props.addSectionInTemplate(this.props.questionnaire.token, this.state.section)
                .then(() => {
                    this.onCloseModalAddEditSection();
                })
                .catch(error => {
                    this.setState(showWarningToaster(!IsNullOrEmpty(error) ? error : "common.errorToAdd"));
                })
        }
        else {
            this.props.updateSectionInTemplate(this.state.section)
                .then(() => {
                    this.onCloseModalAddEditSection();
                })
                .catch(error => {
                    this.setState(showWarningToaster(!IsNullOrEmpty(error) ? error : "common.errorToAdd"));
                })
        }
    }

    /**
     * Function on remove section 
     * @param {*} sectionToken 
     */
    onRemoveSection() {
        this.props.removeSectionInTemplate(this.state.sectionTokenRemove)
            .then(() => {
                this.setState({
                    openRemoveSection: false,
                    sectionTokenRemove: null,
                })
            })
            .catch(error => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : "common.errorToSave"));
            })
    }

    /**
     * onAddQuestionInSectionTemplated
     */
    onAddQuestionInSectionTemplated() {
        if (this.state.question.token === "") {
            this.props.addQuestionInSectionTemplate(this.state.sectionToken, this.state.question)
                .then(() => {
                    this.onCloseModalAddEditQuestion();
                })
                .catch(error => {
                    this.setState(showWarningToaster(!IsNullOrEmpty(error) ? error : "common.errorToAdd"));
                })
        }
        else {
            this.props.updateQuestionInSectionTemplate(this.state.sectionToken, this.state.question)
                .then(() => {
                    this.onCloseModalAddEditQuestion();
                })
                .catch(error => {
                    this.setState(showWarningToaster(!IsNullOrEmpty(error) ? error : "common.errorToSave"));
                })
        }
    }


    /**
     * Arr or edit question 
     * @param {*} sectionToken 
     * @param {*} questionToken 
     */
    onClickAddEditQuestion(sectionToken, sectionMultiple, questionToken = null) {
        var questionData = null;
        if (!IsNullOrEmpty(questionToken)) {
            let section = this.props.questionnaire.sections.find(section => section.token === sectionToken);
            if (section) {
                questionData = section.section_questions.find(question => question.token === questionToken);
            }
        }
        this.setState({
            openModalQuestion: true,
            sectionToken: sectionToken,
            sectionMultiple: sectionMultiple,
            question: this.initStateQuestionTemplate(questionData),
        });
    }

    /**
    * On close and clear modal 
    */
    onCloseModalAddEditQuestion() {
        this.setState({
            openModalQuestion: false,
            sectionToken: null,
            sectionMultiple: false,
            question: this.initStateQuestionTemplate(),
        });
    }

    /**
     * on remove question in section
     * @param {*} sectionToken 
     * @param {*} questionToken 
     */
    onRemoveQuestionInSection(sectionToken, questionToken) {
        this.props.removeQuestionInSectionTemplate(sectionToken, questionToken)
            .catch(error => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : "common.errorToSave"));
            })
    }

    /**
   * Render Add Question
   */
    renderAddQuestion() {
        let questionType = GetQuestionBySection();
        if (this.state.sectionMultiple === true)
            questionType = GetQuestionBySection(true);

        return (
            <div style={{ margin: 10, width: 500 }}>
                <Grid container spacing={24}>
                    {this.renderInput("questionnaire.nameQuestion", "name", 12, null, "text", false, true)}
                    {this.renderInput("questionnaire.questionType", "questionType", 6, questionType, "selected", false)}
                </Grid>
            </div>
        )
    }

    /**
     * Render question list 
     */
    renderQuestionsList() {
        let sections = this.props.questionnaire.sections;
        let sectionSelected = sections[this.state.activeTab];
        if (sectionSelected !== undefined) {
            return (<QuestionTemplate
                sectionQuestionnaire={sectionSelected}
                updateProperty={this.updatePropertyQuestion}
                translate={this.props.translate}
                updateOptionMuptiple={this.updateOptionMuptiple}
                dropzoneText={this.props.translate('dropzone.dropzoneBase')}
                uploadFilesInQuestion={this.uploadFilesInQuestion}
                removeFilesInQuestion={this.removeFilesInQuestion}
                onClickAddEditSection={this.onClickAddEditSection}
                onClickRemoveSection={this.onClickRemoveSection}
                isSavingTemplate={this.props.isSavingTemplate}
                onClickAddEditQuestion={this.onClickAddEditQuestion}
                onRemoveQuestionInSection={this.onRemoveQuestionInSection}
            />)
        }
        else {
            return (<Grid>
                <br /><Typography variant="subtitle2"><InfoIcon /> <Translate id="questionnaire.withoutSectionInTemplate" /> </Typography>
            </Grid>)
        }
    }

    /**
     * Render 
     */
    renderContent() {
        var { questionnaire, classes } = this.props;
        return (
            <CardContent className={this.props.classes.withoutPaddingTopBottom}>
                {this.props.isLoadingQuestionnaire ?
                    <LoadingPage />
                    : <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar><TemplatesIcon /></Avatar>}
                                    action={
                                        <div className={classes.actionsToolbar}>
                                            {canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany) &&
                                                <Tooltip title={<Translate id="questionnaire.addSectionInTemplate" />}>
                                                    <Fab size="small" color="secondary" onClick={() => { this.onClickAddEditSection() }}>
                                                        <AddIcon />
                                                    </Fab>
                                                </Tooltip>}
                                        </div>
                                    }
                                    title={<Typography variant="button"><Translate id="questionnaire.titleQuestionnaire" /> </Typography>}
                                    subheader={questionnaire && questionnaire.name}
                                />
                                <Divider />
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <AppBar position="static" color="default" style={{ marginTop: "1rem" }}>
                                <Tabs
                                    indicatorColor="primary"
                                    textColor="textPrimary"
                                    value={this.state.activeTab}
                                    onChange={this.onClickToActiveTab}
                                    variant="fullWidth"
                                    scrollButtons="auto"
                                    className={this.props.classes.tabs}
                                >
                                    {questionnaire.sections.map((questionnaireSection, index) => {
                                        return (
                                            <Tab id={index}
                                                key={questionnaireSection.token}
                                                title={questionnaireSection.short_name}
                                                label={<p>{questionnaireSection.short_name}</p>}
                                                onClick={(event) => this.changeSelectTab(event.currentTarget.id)}
                                                className={this.props.classes.tabs}
                                                style={{ backgroundColor: questionnaireSection.color_section, color: "#fff" }}
                                            />
                                        )
                                    })}
                                </Tabs>
                            </AppBar>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                            <Paper className={this.props.classes.withoutPaddingTopBottom}>
                                {this.renderQuestionsList()}
                            </Paper>
                        </Grid>
                    </Grid>}
                <br /><br />
            </CardContent>
        );
    }


    render() {
        return (
            <Card>

                {this.renderContent()}

                {/** Dialog for add new section*/}
                <Dialog
                    open={this.state.openModalSection}
                    onClose={() => this.onCloseModalAddEditSection()}
                    header={<Typography style={{ margin: 10 }}><Translate id="questionnaire.addSectionInTemplate" /></Typography>}
                    children={this.renderAddSection(true)}
                    actions={this.renderModalButtons(true)}
                />

                {/** Dialog for add new question */}
                <Dialog
                    open={this.state.openModalQuestion}
                    onClose={() => this.onCloseModalAddEditQuestion()}
                    header={<Typography style={{ margin: 10 }}><Translate id="questionnaire.addQuestion" /></Typography>}
                    children={this.renderAddQuestion(false)}
                    actions={this.renderModalButtons(false)}
                />

                {/** Dialog to remove all section */}
                < AddCommonItem
                    title={<Translate id="questionnaire.removeSection" />}
                    message={<Translate id="questionnaire.messageRemoveSection" />}
                    open={this.state.openRemoveSection}
                    onClose={() => { this.setState({ openRemoveSection: false }) }}
                    onSaveItem={this.onRemoveSection}
                    isSaving={this.props.isSavingTemplate}
                    isMessage={true}
                />

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Card>
        );
    }
}


/**
 *  Defines the properties injecteded from the store to view container
* @param {*} state
                        */
function mapStateToProps(state) {
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    return {
        currentUser: user,
        isLoadingQuestionnaire: state.questionnaires.get("isLoadingQuestionnaire"),
        questionnaire: state.questionnaires.get('questionnaire') ? state.questionnaires.get('questionnaire').toJS() : null,
        company: state.companies.get("company") ? state.companies.get("company").toJS() : null,
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
        isSaving: state.questionnaires.get("isSavingQuestionnaire"),
        isSavingTemplate: state.questionnaires.get("isSavingTemplate"),
    }
}

/**
 * Defines the actions injectes to the component
* @param {*} dispatch
                */
const mapDispatchToProps = dispatch => {
    return {
        loadCompany: (token) => {
            return (dispatch(loadCompany(token)));
        },
        loadSupplier: (token) => {
            return (dispatch(loadSupplier(token)))
        },
        loadQuestionnaire: (token) => {
            return (dispatch(loadQuestionnaireFromBackEnd(token)))
        },
        addSectionInTemplate: (tokenTemplate, section) => {
            return dispatch(addSectionInTemplate(tokenTemplate, section))
        },
        removeSectionInTemplate: (sectionToken) => {
            return dispatch(removeSectionInTemplate(sectionToken))
        },
        updateSectionInTemplate: (section) => {
            return dispatch(updateSectionInFromBackend(section))
        },
        addQuestionInSectionTemplate: (tokenSection, question) => {
            return dispatch(addQuestionInSectionTemplate(tokenSection, question))
        },
        removeQuestionInSectionTemplate: (tokenSection, tokenQuestion) => {
            return dispatch(removeQuestionInSectionTemplate(tokenSection, tokenQuestion))
        },
        updateQuestionInSectionTemplate: (sectionToken, question) => {
            return dispatch(updateQuestionInSectionTemplate(sectionToken, question))
        }
    }
}

const styles = theme => ({
    tabs: {
        maxHeight: 40,
        minHeight: 35,
    },
    actionsToolbar: {
        display: "flex"
    },
    withoutPaddingTopBottom: {
        paddingTop: 0,
        paddingBottom: 0
    }
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));