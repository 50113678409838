import React, { useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import queryString from 'query-string'
import { useLocation, useHistory, withRouter } from 'react-router-dom'

import { Avatar, Card, CardContent, CardHeader, Fab, Typography } from '@material-ui/core'
import { Add, CreditCard } from '@material-ui/icons'
import SearchInput from '../common/SearchInput/SearchInput'

import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '../../store/requestPriceChange/requestPriceChangeSelector'
import { getRequestsCount, loadRequestFromBackEnd } from '../../store/requestPriceChange/requestPriceChangeAction'

const Index = (props) => {
	const { classes } = props
	let location = useLocation()
	const history = useHistory()
	const dispatch = useDispatch()
	const queryParams = queryString.parse(location.search)
	const priceChangeRequests = useSelector((state) => selectors.getRequestsList(state))
	const pagination = useSelector((state) => selectors.getPagination(state))
	/** Get user Information*/
	const userProfile = useSelector((state) => state.oidc.user)
	/** Get the information if you are an internal user*/
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')

	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(loadRequestFromBackEnd(queryParams))
	}, [dispatch, location.search])

	useEffect(() => {
		dispatch(getRequestsCount())
	}, [dispatch])

	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<CreditCard />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						<SearchInput
							onChangeSearchValue={onChangeSearchValue}
							value={!!queryParams && queryParams.query ? queryParams.query : ''}
						/>
						{!isInternalUser() && (
							<Fab
								size='small'
								color='primary'
								onClick={() => {
									history.push(`/priceChangeRequest/create`)
								}}
							>
								<Add />
							</Fab>
						)}
					</div>
				}
				title={
					<Typography variant='button'>
						<Translate id='menu.priceChangeRequest' />{' '}
					</Typography>
				}
				subheader={
					<div>
						<Translate id='common.showing' />
						&nbsp;
						<span>{pagination.total}</span>&nbsp;
						<Translate id='common.of' />
						&nbsp;
						<span>{pagination.total}</span>&nbsp;
						<Translate id='common.records' />
						&nbsp;
					</div>
				}
			/>
			<CardContent>
				<DataTable
					showFilters
					isIndex
					data={priceChangeRequests}
					configuration={TableConfiguration(isInternalUser())}
					onRowClick={(request) => {
						history.push(`/priceChangeRequest/${request.token}`)
					}}
					onChangeSortCriteria={onChangeSortCriteria}
					totalRows={pagination.total}
					sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'Folio'}
					sortAscending={
						queryParams && queryParams.sortDirection
							? queryParams.sortDirection === 'desc'
								? false
								: true
							: false
					}
					page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
					rowsPerPage={queryParams && queryParams.rowsPerPage ? parseInt(queryParams.rowsPerPage) : 20}
					onChangePage={(newPage, newRowsPerPage) => {
						onChangePage(newPage, newRowsPerPage)
					}}
				/>
			</CardContent>
		</Card>
	)
}
function TableConfiguration(isUserInternal) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'status_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='memos.stage' />,
			dataSource: 'stage',
			isSortable: true,
		},
		{
			header: <Translate id='memos.developmentEngineer' />,
			dataSource: 'development_name',
			isSortable: true,
		},
	]
	if (isUserInternal) {
		columns.push({
			header: <Translate id='priceChangeRequest.index.table.supplier' />,
			dataSource: 'supplier_name',
			isSortable: true,
		})
	}
	return { columns }
}
const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
	},
})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Index)))
