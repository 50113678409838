import { fromJS } from 'immutable';

import * as officialNoticeAction from './OfficialNoticeActions';

/**
 * Official notices initial state
 */
export const noticesInitialState = fromJS({
    isLoadingNotices: true,
    totalNotifications: 0,
    officialNotices: null,
    sortCriteria: {
        by: "creation_date",
        ascending: true
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    noticesCount: null,
    isSaving: false,
    isLoading: false,
    officialNotice: null,
    categories: [],
    acceptedNotices: [],
    isSavingToAccepted: false,
    noticesInitialView: null,
    isLoadingNoticesInitial: false,
})

export default function noticesReducer(state = noticesInitialState, action) {
    switch (action.type) {
        case officialNoticeAction.START_LOADING_OFFICIAL_NOTICES: {
            return state.merge({
                isLoadingNotices: action.isLoading
            })
        }
        case officialNoticeAction.SET_OFFICIAL_NOTICES_LIST: {
            let noticesLists = fromJS(action.notices);
            return state.merge({
                officialNotices: noticesLists,
                isLoadingNotices: false
            })
        }
        case officialNoticeAction.CHANGE_OFFICIAL_NOTICE_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case officialNoticeAction.CHANGE_OFFICIAL_NOTICE_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case officialNoticeAction.CHANGE_OFFICIAL_NOTICE_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case officialNoticeAction.SET_OFFICES_NOTICES_COUNT: {
            return state.merge({
                noticesCount: action.count
            })
        }
        case officialNoticeAction.SET_OFFICIAL_NOTICE_COUNT:{
            return state.merge({
                totalNotifications: action.officialNoticeCount
            })
        }
        case officialNoticeAction.IS_SAVING_OFFICE_NOTICE: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case officialNoticeAction.IS_LOADING_OFFICIAL_NOTICE: {
            return state.merge({
                isLoading: action.isLoading,
                officialNotice: null,
            })
        }
        case officialNoticeAction.SET_OFFICIAL_NOTICE: {
            return state.merge({
                isLoading: false,
                officialNotice: fromJS(action.officialNotice)
            })
        }
        case officialNoticeAction.SET_CATEGORIES: {
            return state.merge({
                isLoading: false,
                categories: fromJS(action.categories)
            })
        }
        case officialNoticeAction.SET_OFFICIAL_NOTICE_TO_ACCEPTED: {
            return state.merge({
                isLoadingNotices: false,
                acceptedNotices: fromJS(action.notices)
            })
        }
        case officialNoticeAction.IS_SAVING_ADD_ACCEPTED_NOTICE: {
            return state.merge({
                isSavingToAccepted: action.isSaving
            })
        }
        case officialNoticeAction.REMOVE_OFFICIAL_NOTICE_ACCEPTED_SUPPLIER: {
            let actualNotices = state.get('acceptedNotices')
            if (actualNotices) {
                let updatedNotices = actualNotices.filter((noticeAccepted) => {
                    return !(noticeAccepted.get('token') === action.tokenNotice && noticeAccepted.get('document_name') === action.documentName)
                })
                return state.merge({
                    acceptedNotices: updatedNotices,
                    isSavingToAccepted: false
                });
            } else {
                return state;
            }
        }
        case officialNoticeAction.IS_LOADING_NOTICES_INITIAL: {
            return state.merge({
                isLoadingNoticesInitial: action.isLoading
            })
        }
        case officialNoticeAction.SET_NOTICES_INITIAL_VIEW: {
            return state.merge({
                isLoadingNoticesInitial: false,
                noticesInitialView: fromJS(action.notices)
            })
        }
        case officialNoticeAction.ADD_SUPPLIER_TO_EXCLUDE_IN_NOTICE: {
            let supplier = fromJS(action.supplier)
            return state.updateIn(['officialNotice', "exclude_suppliers"], (suppliers) => suppliers.push(supplier))
            .set('isSaving', false);
        }
        case officialNoticeAction.REMOVE_SUPPLIER_TO_EXCLUDE_IN_NOTICE: {
            return state.updateIn(['officialNotice', 'exclude_suppliers'], (allSuppliers) => {
                let index = allSuppliers.findIndex((item) => {
                    return item.get('supplier_code') === action.supplierCode
                });
                return allSuppliers.delete(index).set('isSaving', false);
            })
        }
        default:
            return state;
    }
}