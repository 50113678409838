
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DevolutionIcon from '@material-ui/icons/ReplyAllOutlined';
import PrintIcon from '@material-ui/icons/Print';
import { Typography, Fab, AppBar, Tabs, Tab } from '@material-ui/core';

import LoadingPage from '../common/LoadingPage';
import Toaster from '../common/Toaster';
import FloatingButton from '../common/FloatingButton';
import DialogAvailableOperations from '../common/DialogAvailableOperations';
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow';
import InformationPartial from './components/InformationPartial';
import DevolutionItems from './components/DevolutionItems';
import RegisterMaterial from './components/RegisterMaterial';
import ItemsInvoices from './components/ItemsInvoices';
import AddCommonItem from '../common/AddCommonItem';
import Evidence from '../common/Evidence';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper';

/** Import action section */
import { loadSupplier } from '../../store/suppliers/SupplierActions';
import {
    loadDevolutionFromBackEnd, markDevolutionTakenForSupplierFromBackend,
    updateStatusToDevolutionWaitingByTheSupplierPickedUp, 
    deleteDraftDevolution, onReactiveDevolutionFromBackend,
    uploadFilesEvidence, removeFilesEvidence
} from '../../store/devolutions/DevolutionActions';
import { converListToArray } from '../../store/helpers/ListHelper';
import { canUserValideModuleQuality, canUserCloseModuleQuality } from '../../store/helpers/RolesHelper';

/**
 * Container show Devolution
 */
class Show extends Component {

    /**
     * Create an instance Devolution
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            activeTab: 0,
            openAvailableOperationsDialog: false,
            openRegisterMaterial: false,
            openDeleteDraft: false,
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let devolutionToken = this.props.match.params.token;
        this.props.loadDevolution(devolutionToken);
    }

    /**
     * componentDidUpdate
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof Index
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.devolution !== null) {
            /// Get information full from devolution
            let devolution = this.props.devolution;
            this.props.loadSupplier(devolution.supplier_token);
        }
    }

    /**
 * Action to set state to open of available operations dialog
 * @memberof 
 */
    setOpenDialog = (openAvailable) => {
        this.setState({
            openAvailableOperationsDialog: openAvailable,
        });
    }

    /**
     * Function on click open register
     */
    onClickOpenRegister() {
        this.setState({
            openRegisterMaterial: true,
        })
    }

    /**
    * Action on mark Devolution Taken For Supplier
    */
    onMarkDevolutionTakenForSupplier(carrierName, carrierUnitPlates) {
        this.props.markDevolutionTakenForSupplier(carrierName, carrierUnitPlates)
            .then(() => {
                this.setState({
                    openRegisterMaterial: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

        /**
     * Method to change the active tab
     */
         onClickToActiveTab = (event, value) => {
            this.setState({ activeTab: value });
        };

    /**
     * Function to update status form devolution
     */
    onUpdateDevolutionWaitingByTheSupplierPickedUp() {
        this.props.updateStatusToDevolutionWaitingByTheSupplierPickedUp()
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
* onDeleteDraft
*
* @param {*} item
* @param {*} supplier
* @memberof Create
*/
    onDeleteDraft() {
        this.setState({
            openAvailableOperationsDialog: false,
            openDeleteDraft: true,
        })
    }

    /**
    * onDeleteDraftDevolution
    */
    onDeleteDraftDevolution() {
        this.props.deleteDraftDevolution(this.props.devolution.token)
            .then(() => {
                this.setState({
                    openDeleteDraft: false,
                })
                this.props.history.push(`/devolutions`)
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
* Reactive devolution
*/
    onReactiveDevolution() {
        this.props.onReactiveDevolutionFromBackend(this.props.devolution.token)
            .then(() => {
                this.setState({
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    //Cancel a Sacks document
    onCancelDocument() {
        this.props.history.push(`/devolutions/cancel/${this.props.devolution.token}`)
    }


    /**
     * Function to donwload and print report devolution 
     */
    downloadDevolutionReportPdf() {
        let devolutionToken = this.props.devolution.token;
        let language = this.props.selectedLanguage.code;
        let urlPlot = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/${devolutionToken}/pdf/${language}`;
        window.open(urlPlot, '_blank');
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.devolution !== null) {
            const { classes } = this.props;
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Divider />
                        {/* <InformationPartial  {...this.props} /> */}
                        <AppBar position="static" color="default">
                            <Tabs indicatorColor="secondary" textColor="secondary"
                                className={classes.styleTab}
                                value={this.state.activeTab}
                                onChange={this.onClickToActiveTab}>
                                <Tab label={<p>{<Translate id="common.information" />} </p>} />
                                <Tab label={<Translate id="common.evidence" />} />
                            </Tabs>
                        </AppBar>

                        {this.state.activeTab === 0 && <>
                            <InformationPartial  {...this.props} />

                        </>}
                        {this.state.activeTab === 1 &&
                            <Evidence
                                isDisabled={this.props.devolution.is_closed || !IsCurrentActiveDirectoryUser(this.props.currentUser)}
                                baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                                removeFiles={(fileName)=>this.props.removeFiles(fileName, this.props.devolution.token)}
                                uploadFiles={(files)=>this.props.uploadFiles(files, this.props.devolution.token)}
                                files={this.props.devolution.attachments}
                            />
                        }
                    </Grid>

                   { this.state.activeTab === 0 && <Grid item xs={12}>
                        {this.props.devolution.is_orgin_raw_material && <DevolutionItems {...this.props} />}

                        {!this.props.devolution.is_orgin_raw_material && <ItemsInvoices {...this.props} />}
                    </Grid>}

                   { this.state.activeTab === 0 && <Grid item xs={12}>
                        {this.props.devolution.can_be_close && IsCurrentActiveDirectoryUser(this.props.currentUser) &&
                            <Button variant="contained" onClick={this.onClickOpenRegister} color="primary" className={classes.button}>
                                <DevolutionIcon className={classes.leftIcon} />
                                <Translate id="devolutions.registerMaterial" />
                            </Button>}

                        <Button variant="outlined" color="secondary" className={classes.button} onClick={() => this.downloadDevolutionReportPdf()}>
                            <PrintIcon style={{ fontSize: '18px' }} />
                            <Translate id="devolutions.printReport" />
                        </Button>
                    </Grid>}
                </Grid>
            )
        }
    }

    render() {
        let optionsAvailable = this.props.devolution ? this.props.devolution.get_available_operations : [];
        return (
            <Card>
                {optionsAvailable.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

                <LayoutHeaderWorkFlow {...this.props}
                    workFlow={this.props.devolution}
                    title={<span><Translate id="devolutions.titleDevolution" /> {this.props.devolution ? this.props.devolution.get_provenance : ""}</span>}
                />

                {this.props.devolution && this.props.devolution.is_closed && canUserCloseModuleQuality(this.props.userRolesByCompany) &&
                    <Fab variant="extended" color="primary" size="small" style={{ marginLeft: 16 }}
                        onClick={this.onReactiveDevolution} disabled={this.props.isSaving}>
                        <DevolutionIcon /> <Typography style={{ color: "white" }} variant="caption">
                            <Translate id="devolutions.reactiveDevolution" />
                        </Typography>
                    </Fab>}

                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                <DialogAvailableOperations
                    {...this.props}
                    options={optionsAvailable}
                    isValidToCancel={canUserValideModuleQuality(this.props.userRolesByCompany)}
                    openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
                    onClickDevolutionWaitingByTheSupplierPickedUp={this.onUpdateDevolutionWaitingByTheSupplierPickedUp}
                    setOpenDialog={this.setOpenDialog}
                    onDeleteDraft={this.onDeleteDraft}
                    onCancelDocument={this.onCancelDocument}
                />

                {/** Dialog to register material */}
                < RegisterMaterial
                    open={this.state.openRegisterMaterial}
                    onClose={() => { this.setState({ openRegisterMaterial: false }) }}
                    onSaveItem={this.onMarkDevolutionTakenForSupplier}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                />

                {/** Dialog to delete draft */}
                < AddCommonItem
                    title={<Translate id="devolutions.removeDevolution" />}
                    message={<Translate id="devolutions.messageToDevolution" />}
                    open={this.state.openDeleteDraft}
                    onClose={() => { this.setState({ openDeleteDraft: false }) }}
                    onSaveItem={this.onDeleteDraftDevolution}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                />

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let devolution = state.devolutions.get("devolution");
    let selectedCompany = state.profile.get("selectedCompany");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        currentUser: user,
        isLoading: state.devolutions.get('isLoading'),
        isSaving: state.devolutions.get('isSaving'),
        devolution: devolution ? devolution.toJS() : null,
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        company: selectedCompany ? selectedCompany.toJS() : null,
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
        selectedLanguage: state.localize.languages.find(language => language.active === true)
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadDevolution: (token) => {
            return dispatch(loadDevolutionFromBackEnd(token));
        },
        loadSupplier: (token) => {
            return (dispatch(loadSupplier(token)))
        },
        markDevolutionTakenForSupplier: (carrierName, carrierUnitPlates) => {
            return dispatch(markDevolutionTakenForSupplierFromBackend(carrierName, carrierUnitPlates))
        },
        updateStatusToDevolutionWaitingByTheSupplierPickedUp: () => {
            return dispatch(updateStatusToDevolutionWaitingByTheSupplierPickedUp())
        },
        deleteDraftDevolution: (token) => {
            return dispatch(deleteDraftDevolution(token))
        },
        onReactiveDevolutionFromBackend: (token) => {
            return dispatch(onReactiveDevolutionFromBackend(token))
        },
        uploadFiles: (files, token) => {
            return dispatch(uploadFilesEvidence(files, token));
        },
        removeFiles: (fileName, token) => {
            return dispatch(removeFilesEvidence(fileName, token));
        },
    }
}

const styles = theme => ({
    actionsToolbar: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    margin: {
        margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit * 1,
    },
    leftIcon: {
        marginRight: theme.spacing.unit * 1,
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));