import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	LinearProgress,
	Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DeleteIcon from '@material-ui/icons/Clear'

/** Custom components import section */
import DateRangePicker from '../../../common/DateTimePicker/DateRangePicker'

/** Redux import section */
import { useDispatch } from 'react-redux'
import { downloadInvoicedServiceOrdersReport } from '../../../../store/accreditedWorkshops/accreditedWorkshopsActions'

/** Helpers import section */

const ConfirmationOrdersReportModal = (props) => {
	const { open, onClose } = props

	/** Define local state */
	const [dateRange, setDateRange] = useState({ startDate: moment(), endDate: moment() })
	const [downloadingReport, setDownloadingReport] = useState(false)

	/** Connect with redux */
	const dispatch = useDispatch()

	const classes = useStyles()

	const handleRangeChange = (dateRange) => {
		setDateRange(dateRange)
	}

	const downloadServiceOrdersReport = () => {
		setDownloadingReport(true)
		const { startDate, endDate } = dateRange
		const filters = [
			{ FieldName: 'PaymentRequestId', RelationalOperatorType: 'NOT', Values: [null] },
			{
				FieldName: 'CloseDate',
				RelationalOperatorType: 'BETWEEN_DATES',
				Values: [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')],
			},
		]
		dispatch(downloadInvoicedServiceOrdersReport(filters))
			.then(() => {
				onClose()
			})
			.finally(() => setDownloadingReport(false))
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'} aria-labelledby='form-dialog-title'>
			<DialogTitle id='form-dialog-title' disableTypography className={classes.dialogTitle}>
				<Typography gutterBottom color='secondary' variant='subtitle1' className={classes.title}>
					<strong>
						<Translate id='accreditedWorkshops.serviceOrdersReport' />
					</strong>
				</Typography>
				<Fab size='small' className={classes.root} onClick={onClose}>
					<DeleteIcon />
				</Fab>
			</DialogTitle>
			<DialogContent>
				<DateRangePicker
					startDate={dateRange.startDate}
					endDate={dateRange.endDate}
					onChangeRange={handleRangeChange}
				/>
				{downloadingReport && (
					<>
						<LinearProgress />
						<LinearProgress color='secondary' variant='query' />
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={downloadServiceOrdersReport} disabled={downloadingReport}>
					<Translate id='accreditedWorkshops.downloadServiceOrdersReport' />
				</Button>
			</DialogActions>
		</Dialog>
	)
}

ConfirmationOrdersReportModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
}

ConfirmationOrdersReportModal.propTypes = {
	onClose: () => console.warn('onClose not defined!'),
}

const useStyles = makeStyles((theme) => ({
	dialogTitle: {
		display: 'flex',
		alignItems: 'center',
		'& > *': {
			marginLeft: 5, //theme.spacing(0.5)
		},
	},
	title: {
		flex: '1 1 0',
	},
}))

export default ConfirmationOrdersReportModal
