import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

/** Import component */
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import DataTable from '../common/DataTable'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import NonConformitiesIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/BorderColor'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

import {
	loadNonConformityFromBackEnd,
	CHANGE_NON_CONFORMITIES_SORT_CRITERIA,
	CHANGE_NON_CONFORMITIES_SORT_QUERY,
	getNonConformityCount,
	addNonConformityData,
	updateNonConformityData,
	CHANGE_NON_CONFORMITIES_PAGINATION,
} from '../../store/administration/NonConformitiesActions'
import DialogAddNonConformity from './components/DialogAddNonConformity'
import DialogEditNonConformity from './components/DialogEditNonConformity'
import Toaster from '../common/Toaster'

/**
 * component NonConformity
 */
class NonConformity extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showAddNonConformityDialog: false,
			openModalEditNonConformity: false,
			editItem: null,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
		}
		base = this.props.translate('menu.nonConformity')
		spanish = this.props.translate('common.spanish')
		english = this.props.translate('common.english')
		portuguese = this.props.translate('common.portugues')
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadNonConformity(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getNonConformityCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadNonConformity()
			this.props.getNonConformityCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadNonConformity(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * Handle state of any Dialog to show or close
	 *
	 * @param {*} dialogId
	 * @memberof NonConformity
	 */
	handleOpenCloseAddNonConformityDialog(dialogId) {
		this.setState({ [dialogId]: !this.state[dialogId] })
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof NonConformity
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof NonConformity
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Open modal for edit non conformity failure
	 */
	opentoEditNonConformityFailure(item) {
		this.setState({
			openModalEditNonConformity: true,
			editItem: item,
		})
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'edit') {
			return (
				<Tooltip key={item.id} color='secondary' title={<Translate id='common.edit' />}>
					<EditIcon
						color='primary'
						style={{ zIndex: '990', cursor: 'pointer' }}
						onClick={() => {
							this.opentoEditNonConformityFailure(item)
						}}
					/>
				</Tooltip>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.nonConformities === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.nonConformities}
					configuration={TableConfiguration(spanish, english, portuguese)}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					showFilters
					isIndex
					totalRows={this.props.nonConformitiesCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get raw non conformity quality counter
	 *
	 * @returns
	 * @memberof Index
	 */
	geCounterMessage() {
		if (this.props.nonConformities) {
			let totalNonConformities = this.props.nonConformities.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalNonConformities}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.nonConformitiesCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Handle On save, call action and show toaster if done or not
	 *
	 * @param {*} name
	 * @returns
	 * @memberof NonConformity
	 */
	handleOnSaveAddNonConformity(names) {
		return this.props
			.addNonConformityData(names)
			.then(() => {
				this.setState({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
				this.handleOpenCloseAddNonConformityDialog('showAddNonConformityDialog')
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
				return Promise.reject()
			})
	}

	/**
	 * call to action to update data non conformity
	 *
	 * @memberof FormView
	 */
	updateNonConformity(editItem) {
		if (editItem !== null) {
			this.props
				.updateNonConformityData(editItem)
				.then(() => {
					this.setState({
						openModalEditNonConformity: false,
						editItem: null,
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: <Translate id='common.saveCorrectly' />,
					})
				})
				.catch(() => {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				})
		}
	}

	/**
	 * Render list role
	 *
	 * @returns
	 * @memberof Show
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<NonConformitiesIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>

							<Tooltip title={<Translate id='qualityCatalogs.addNonConformity' />}>
								<Fab
									size='small'
									color='primary'
									onClick={() =>
										this.handleOpenCloseAddNonConformityDialog('showAddNonConformityDialog')
									}
								>
									<AddtIcon />
								</Fab>
							</Tooltip>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.nonConformity' />{' '}
						</Typography>
					}
					subheader={this.geCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to add non conformity*/}
				<DialogAddNonConformity
					classes={this.props.classes}
					isSaving={this.props.isSavingNonConformity}
					onSave={this.handleOnSaveAddNonConformity}
					onCloseCancel={this.handleOpenCloseAddNonConformityDialog}
					showDialog={this.state.showAddNonConformityDialog}
				/>

				{/** Dialog to edit non conformity*/}
				{this.state.editItem && (
					<DialogEditNonConformity
						classes={this.props.classes}
						showDialog={this.state.openModalEditNonConformity}
						nonConformity={this.state.editItem}
						onSaveNonConformity={this.updateNonConformity}
						onCloseCancel={() => {
							this.setState({ openModalEditNonConformity: false, editItem: null })
						}}
						isSaving={this.props.isSavingNonConformity}
					/>
				)}

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

let base
let spanish
let english
let portuguese
function TableConfiguration(spanish, english, portuguese) {
	return {
		columns: [
			{
				header: base + ' (' + spanish + ')',
				dataSource: 'name',
				isSortable: true,
			},
			{
				header: base + ' (' + english + ')',
				dataSource: 'name_english',
			},
			{
				header: base + ' (' + portuguese + ')',
				dataSource: 'name_portuguese',
			},
			{
				header: '',
				dataSource: 'edit',
			},
		],
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoading: state.nonConformities.get('isLoadingNonConformity'),
		nonConformities: state.nonConformities.get('nonConformities')
			? state.nonConformities.get('nonConformities').toJS()
			: null,
		isSavingNonConformity: state.nonConformities.get('isSavingNonConformity'),
		sortCriteria: state.nonConformities.get('sortCriteria')
			? state.nonConformities.get('sortCriteria').toJS()
			: null,
		searchQuery: state.nonConformities.get('searchQuery'),
		page: state.nonConformities.get('page'),
		rowsPerPage: state.nonConformities.get('rowsPerPage'),
		nonConformitiesCount: state.nonConformities.get('nonConformitiesCount'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadNonConformity: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadNonConformityFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		getNonConformityCount: () => {
			dispatch(getNonConformityCount())
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_NON_CONFORMITIES_SORT_QUERY,
				query: query,
			})
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_NON_CONFORMITIES_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_NON_CONFORMITIES_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		addNonConformityData: (name) => {
			return dispatch(addNonConformityData(name))
		},
		updateNonConformityData: (data) => {
			return dispatch(updateNonConformityData(data))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(NonConformity)))
)
