import React, { Component } from 'react';
import autoBind from 'auto-bind'

/** Translations imports section */
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SuppliersIcon from '@material-ui/icons/People';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import Evidence from '../../common/Evidence';
import downloadTemplate from '../../../resources/files/Formato_Cargar_Proveedores.xlsx'
import SuppliersCompaniesSelector from './SuppliersCompaniesSelector';

/**
 * Component to suppliers in office
 */
class SuppliersCard extends Component {

    /**
   * Create an instance of Internal user
   * @param {*} props 
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            suppliers: [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.suppliers !== this.props.suppliers) {
            this.setState({
                suppliers: this.props.suppliers
            })
        }
    }

    /**
     * Get the companies list
     *
     * @returns
     * @memberof CompaniesCard
     */
    getSupplierInOfficialNoticeList() {
        if (this.state.suppliers) {
            return (
                <List>
                    {this.state.suppliers.map((supplier) => {
                        return (<div key={supplier.name}>
                            <ListItem divider>
                                <ListItemText primary={<Typography variant="caption" gutterBottom>{supplier.name}</Typography>} />
                                <ListItemSecondaryAction>
                                        <IconButton id={supplier.token}
                                            onClick={(event) => { this.props.removeSupplier(event.currentTarget.id) }}
                                        >
                                            <DeleteIcon color='error' />
                                        </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </div>
                        )
                    })}
                </List>
            )
        } else {
            return null;
        }
    }



    render() {
        const { classes, notice } = this.props;

        return (
                <Card className={classes.card} style={{height:'79vh'}}>
                    <CardHeader
                        avatar={
                            <Avatar className={classes.userAvatar}>
                                <SuppliersIcon />
                            </Avatar>
                        }
                        title={<Translate id="officialNotices.showNextSuppliers" />}
                        subheader={<Translate id="officialNotices.addSupplier" />}
                    />
                    <CardContent>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <div className={classes.formatCheck}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormControlLabel
                                            control={<Checkbox  checked={notice ? notice.optionByTemplate: false}
                                            onChange={(event) => {this.props.updateProperty('optionByTemplate', event.target.checked)}}  />}
                                            label={<Translate id="officialNotices.typeAddByCompany" />}
                                        />
                                    </FormControl>
                                </div>
                                {!notice.optionByTemplate && <div className={classes.formatCheck}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormControlLabel
                                            control={<Checkbox  checked={notice ? notice.optionAll: false}
                                            onChange={(event) => {this.props.updateProperty('optionAll', event.target.checked)}} />}
                                            label={<Translate id="officialNotices.optionAll" />}
                                        />
                                    </FormControl>

                                    <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                            control={<Checkbox  checked={notice ? notice.optionNationals: false}
                                            onChange={(event) => {this.props.updateProperty('optionNationals', event.target.checked)}}  />}
                                            label={<Translate id="officialNotices.optionNationals" />}
                                        />
                                    </FormControl>

                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormControlLabel
                                            control={<Checkbox  checked={notice ? notice.optionForeigns: false}
                                            onChange={(event) => {this.props.updateProperty('optionForeigns', event.target.checked)}}  />}
                                            label={<Translate id="officialNotices.optionExtranjeros" />}
                                        />
                                    </FormControl>
                                </div>}
                            </Grid>
                            {!notice.optionByTemplate && <Grid item xs={12}>
                                <SuppliersCompaniesSelector
                                    className={classes.textField}
                                    onSupplierSelected={(supplier) => { this.props.updateSuppliersList(supplier) }}
                                />
                            </Grid>}
                            {!notice.optionByTemplate && <Grid item xs={12}>
                                <List>
                                    {this.getSupplierInOfficialNoticeList()}
                                    {this.state.suppliers.length === 0 &&
                                        <div>
                                            <ListItem>
                                                <ListItemText primary={
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        <Translate id="officialNotices.noSuppliersToSendNotice" />
                                                    </Typography>
                                                } />
                                            </ListItem><Divider />
                                        </div>
                                    }
                                </List>
                            </Grid>}
                            {notice.optionByTemplate  && <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Divider/>
                                    <br/>
                                    <Typography variant="subtitle2" color={'textSecondary'}>
                                        <Translate id={"officialNotices.addSupplierTempalted"} />
                                    </Typography>
                                    <Evidence
                                        text={this.props.dropzoneText}
                                        baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
                                        removeFiles={this.props.removeTemplate}
                                        uploadFiles={this.props.uploadTemplate}
                                        files={notice.templates}
                                        disabledLink
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '1rem' }}>
                                    <small className="text-muted"> 
                                        <DownloadIcon fontSize="small" color="secondary" style={{ margin: '5px', marginBottom:'8px' }} /> 
                                        <a href={downloadTemplate}><Translate id="contactsupplier.downloadTemplate"/></a>
                                    </small>
                                </Grid>
                                
                            </Grid>}
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.actions} disableActionSpacing>
                    </CardActions>
                </Card>
        );
    }
}

export default SuppliersCard;