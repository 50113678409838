import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';
import { DialogActions, Button, Grid, TextField, Typography } from '@material-ui/core';
import SuppliersSelector from '../../common/SupplierSelector';
import PreparedBySelector from '../../common/PreparedBySelector';
import Toaster from '../../common/Toaster';
import UserSelector from '../../common/UserSelector';

/**
 * Class component of form to create sacQuality
 *
 * @class FormView
 * @extends {Component}
 */
class FormView extends Component {
    constructor(props) {
        super(props)
        autoBind(this);
        this.state = {
            preparedBy: null,
            supplier: null,
            supplierDevelopmentEngineer:null,
            planningEngineer: null,
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            comments: "",
        };
    };

    /**
     * Update property in state
     *
     * @param {*} property
     * @param {*} value
     * @memberof FormView
     */
    updateProperty(property, value) {
        this.setState({
            [property]: value
        })
    }

    /**
     * On seleted part number
     */
    onSelected(propertyId, value) {
        this.setState({ [propertyId]: value });
    }

    /**
     * call to action to save data and create sacQuality
     *
     * @memberof FormView
     */
    onSaveDevolution() {
        if (this.props.createDevolution)
            this.props.createDevolution(this.state)
                .then(response => {
                    this.props.history.push(`/devolutions/${response.token}`)
                }).catch(() => {
                    this.setState({
                        showToaster: true,
                        toasterMessage: <Translate id="common.errorToSave" />,
                        toasterVariant: "error",
                    })
                });
    }

    /**
     * Render textFiel Enabled to edit
     *
     * @param {*} textFieldId
     * @param {*} translationTextFieldId
     * @param {boolean} [isMultiple=false]
     * @param {string} [type='text']
     * @returns
     * @memberof FormView
     */
    renderTextField(textFieldId, translationTextFieldId, isMultiple = false, type = 'text') {
        return (
            <div >
                <TextField
                    id={textFieldId}
                    fullWidth
                    margin="normal"
                    value={this.state[textFieldId] ? this.state[textFieldId] : ''}
                    label={<span><Translate id={translationTextFieldId} /> *</span>}
                    onChange={(event) => { this.updateProperty(textFieldId, event.target.value) }}
                    className={isMultiple ? this.props.classes.textAreaField : this.props.classes.textField}
                    type={type}
                    //Only textArea
                    multiline={isMultiple}
                    rows={isMultiple ? "6" : "1"}
                    variant={isMultiple ? "outlined" : 'standard'}
                />
            </div>
        )
    }

    /**
     * Method to validate required data is filled
     *
     * @returns
     * @memberof FormView
     */
    canBeSaved() {
        if(this.state.preparedBy === null) return false;
        if(this.state.comments === "") return false;
        if(this.state.supplier === null) return false;
        if(this.state.planningEngineer === null) return false;
        if(this.state.supplierDevelopmentEngineer === null) return false;
        return true;
    }
    
    /**
     * Perforn render of component
     *
     * @returns
     * @memberof FormView
     */
    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={24} style={{ paddingLeft: '80px', paddingRight: "100px", }}>
                <Grid item xs={6} >
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="common.preparedBy"/> *</Typography>

                        <PreparedBySelector
                            className={classes.textField}
                            onUsersQualitySelected={(preparedBy) => { this.onSelected('preparedBy', preparedBy) }}
                        /><br />
                    </div>
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="common.planningEngineer"/> *</Typography>
                        <UserSelector
                            className={classes.textField}
                            onUserSelected={(engineer) => { this.onSelected('planningEngineer', engineer) }}
                        />
                        <br />
                    </div>
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="common.supplierDevelopmentEngineer"/> *</Typography>
                        <UserSelector
                            className={classes.textField}
                            onUserSelected={(engineer) => { this.onSelected('supplierDevelopmentEngineer', engineer) }}
                        />
                        <br />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="common.supplier"/> *</Typography>
                        <SuppliersSelector
                            className={classes.textField}
                            onSupplierSelected={(supplier) => { this.onSelected('supplier', supplier) }}
                        />
                        <br />
                    </div>

                    {this.renderTextField('comments', 'common.comments', true)}
                </Grid>
                <Grid item xs={12} >
                    <DialogActions>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            onClick={() => this.onSaveDevolution()}
                            disabled={!this.canBeSaved() || this.props.isSaving}
                        >
                            {<Translate id="common.save" />}
                        </Button>
                        <Button onClick={() => { this.props.history.push(`/devolutions`) }} color="secondary">
                            {<Translate id="common.cancel" />}
                        </Button>
                    </DialogActions>
                </Grid>
                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Grid>
        )
    }
}


const viewStyles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textAreaField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    footerForm: {
        display: "flex",
        paddingRight: "60px",
    },
    formControl: { margin: theme.spacing.unit },
    toolbar: {},
    searchInput: {},
    button: {},
    actionsToolbar: {},
    showingUsersCounter: {},
    grow: {},
    supplierBadge: {}
});

export default withRouter(withStyles(viewStyles, { withTheme: true })(FormView));