import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { getTranslate, Translate } from 'react-localize-redux';
/** Components imports Section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import EmployeeCard from './components/EmployeeCard';

/** Material UI imports Section */
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EmployeesIcon from '@material-ui/icons/People'

/** Material UI Icons imports Section */
import PrintIcon from '@material-ui/icons/Print';
import PrintDisabledIcon from '@material-ui/icons/PrintDisabled';

/** Actions and helpers imports Section */
import { loadEmployeesFromBackEnd, downloadEmployeesCardsPDF } from '../../../store/contractors/EmployeesAction';
import { loadSupplier } from '../../../store/suppliers/SupplierActions';
import { showSuccessToaster, showErrorToaster } from '../../../store/helpers/ToasterHelpers';
import Toaster from '../../common/Toaster';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';

/**
 * Class container to show EmployeesCards to print
 *
 * @class ShowEmployeesCards
 * @extends {Component}
 */
class ShowEmployeesCards extends Component {

    /**
     *Creates an instance of ShowEmployeesCards.
     * @param {*} props
     * @memberof ShowEmployeesCards
     */
    constructor(props) {
        super(props);
        this.state = {
            cardsToPrint: []
        };
    };

    /**
     * React life-cycle method
     * If user was loaded then load employees list to show cards
     *
     * @memberof ShowEmployeesCards
     */
    componentDidMount() {
        if (!this.props.isLoadingUser) {
            this.props.loadEmployees();
        }
    }

    /**
     * componentDidUpdate
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof Index
     */
    componentDidUpdate(prevProps, prevState) {
        let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : "";
        if ((prevProps.isLoadingEmployees !== this.props.isLoadingEmployees) && this.props.supplier === null) {
            this.props.loadSupplier(this.props.user.profile.company_token);
        }
        if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
            this.props.loadEmployees();
        }
    }

    /**
     * Function to call action and download report PrintAccessCards to print 
     *
     * @memberof ShowEmployeesCards
     */
    downloadPrintAccessCardsPdf() {
        let supplierCode = this.props.user ? this.props.user.profile.company_code : "";
        this.props.downloadEmployeeCards(supplierCode, this.state.cardsToPrint)
            .then(() => {
                this.setState(showSuccessToaster("common.downloadCorrectly"));
            }).catch(() => {
                this.setState(showErrorToaster("common.errorToDownload"));
            });
    }

    /**
     * Handle check events for each card and the check to select all cards
     * each token card is added or removed from cardsToPrint array
     * the array will be send to the action and backend to create report to print selected cards
     *
     * @param {*} userToken
     * @param {*} checked
     * @memberof ShowEmployeesCards
     */
    handleCheckCard(userToken, checked) {
        let cardsToPrint = [...this.state.cardsToPrint];
        if (checked) {
            if (userToken === 'allCards') {
                this.props.employees.forEach(employee => {
                    if (cardsToPrint.findIndex(cardToken => cardToken === employee.token) < 0 && !IsNullOrEmpty(employee.image_employed))
                        cardsToPrint.push(employee.token)
                })
            }
            else
                cardsToPrint.push(userToken)
        }
        else {
            if (userToken === 'allCards')
                cardsToPrint = []
            else {
                cardsToPrint = this.state.cardsToPrint.filter((user) => { return user !== userToken })
            }
        }
        this.setState({
            cardsToPrint
        })
    }

    handleClickToShowEmployeesCards() {
        this.props.history.push(`/employees/`)
    }

    /**
     * Main render method to show access cards
     *
     * @returns
     * @memberof ShowEmployeesCards
     */
    render() {
        let disabledToPrint = this.state.cardsToPrint.length <= 0;
        return (
            <Card>
                <CardHeader
                    title={<Translate id="contractorsModule.accessCards" />}
                    action={
                        <React.Fragment>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => { this.handleCheckCard(event.target.value, event.target.checked) }}
                                        color="primary"
                                        value={"allCards"}
                                        checked={this.props.isSelected}
                                    />
                                }
                                label={<Translate id="contractorsModule.selectAllCardsToPrint" />}
                                labelPlacement="start"
                                style={{ marginRight: 0, marginTop: 8 }}
                            />
                            <Tooltip title={<Translate id="menu.employees" />}>
                                <IconButton onClick={() => this.handleClickToShowEmployeesCards()} color="primary">
                                    {<EmployeesIcon />}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={<Translate id="contractorsModule.printCards" />}>
                                <IconButton onClick={() => this.downloadPrintAccessCardsPdf()} disabled={disabledToPrint} color="secondary">
                                    {disabledToPrint ? <PrintDisabledIcon /> : <PrintIcon />}
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    }
                />
                <CardContent>
                    <Grid container spacing={24}>
                        {this.props.employees.map(employee => {
                            return (<Grid item xs={4}>
                                <EmployeeCard
                                    user={employee}
                                    onCheck={(userToken, checked) => this.handleCheckCard(userToken, checked)}
                                    isChecked={this.state.cardsToPrint.length > 0 && this.state.cardsToPrint.find(userToken => userToken === employee.token) !== undefined}
                                />
                            </Grid>
                            )
                        })}
                    </Grid>
                </CardContent>
                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Card>
        );
    }
}

const styles = theme => ({
    barCode: {
        maxHeight: 80
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        margin: 10,
        width: 120,
        height: 120,
    }
});

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let selectedCompany = state.profile.get('selectedCompany');
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    return {
        isLoadingUser: state.oidc.isLoadingUser,
        user: user,
        selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
        isLoadingEmployees: state.contractorEmployees.get("isLoadingEmployees"),
        isSavingEmployee: state.contractorEmployees.get("isSavingEmployee"),
        employees: state.contractorEmployees.get('employees') ? state.contractorEmployees.get('employees').toJS() : null,
        translate: getTranslate(state.localize),
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadSupplier: (token) => {
            return (dispatch(loadSupplier(token)))
        },
        loadEmployees: () => {
            dispatch(loadEmployeesFromBackEnd("", "", "",  "", 1000, true))
        },
        downloadEmployeeCards: (supplierCode, employeesTokens) => {
            return dispatch(downloadEmployeesCardsPDF(supplierCode, employeesTokens))
        }
    }
}
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ShowEmployeesCards))));
