///Import react section
import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'

/** Language imports **/
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import SustainabilityIcon from '@material-ui/icons/NaturePeople';
import ListIcon from '@material-ui/icons/List';
import { Typography, Fab, Tooltip } from "@material-ui/core";

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/resource-timegrid';
import list from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import ptLocale from '@fullcalendar/core/locales/pt';
import moment from 'moment';
import { converListToArray } from '../../../store/helpers/ListHelper'
import { COLOR_STATUS_ACEPTED, COLOR_STATUS_REJECTED } from '../../../store/helpers/StatusColorConstants'
import '@fullcalendar/list/main.css';
import '@fullcalendar/timegrid/main.css';

import { getScheduleDateHistorys } from '../../../store/questionnaires/QuestionnairesAction'

/**
 * Component load calenar
 * @param {*} props 
 */
const CalendarIndex = (props) => {
    const dispatch = useDispatch()
    const { classes } = props;

    let locale = props.languages ? props.languages.find(language => { return language.active })["code"] : "es";

    const auditCalendar = useSelector((state) => state.questionnaires.get('schudeleDatesHistorys') ? converListToArray(state.questionnaires.get('schudeleDatesHistorys')) : [])
    /**Use effect section */
    useEffect(() => {
        dispatch(getScheduleDateHistorys("","creation_date", false,0, 1000))
    }, [dispatch])

    /**
     * Function to show sustainabilityv2
     */
    const onReturnToQuestionnaire= (event) => {
        props.history.push(`/questionnaire`)
    }

    const formatObjectEvents = (events) => {
        let allEvents = [];
        events.forEach(event => {
            let scheduledDate = moment(event.scheduled_date).format('YYYY-MM-DD');
            allEvents.push({
                title: `${event.supplier_name}`,
                date: scheduledDate,
                backgroundColor: event.status ===1 ? COLOR_STATUS_REJECTED: COLOR_STATUS_ACEPTED,
                color: event.status ===1 ? COLOR_STATUS_REJECTED: COLOR_STATUS_ACEPTED,
                description: event.comments,
            })
        });
        return allEvents;
    }

    const allEvents = auditCalendar ? formatObjectEvents(auditCalendar) : [];
    return (
        <Card>
            <CardHeader
                title={<Typography variant="button">{<Translate id="questionnaire.scheduledQuestionnaires" />}</Typography>}
                avatar={
                    <Avatar>
                        <SustainabilityIcon />
                    </Avatar>
                }
                action={
                    <Tooltip title={<Translate id="sustainability.returnView" />}>
                        <Fab className={classes.marginIconReturn} size="small" color="primary" onClick={() => { onReturnToQuestionnaire() }}>
                            <ListIcon />
                        </Fab>
                    </Tooltip>
                }
            />
            <CardContent className={classes.calendar}>
                <FullCalendar
                    defaultView="dayGridMonth"
                    locales={[esLocale, ptLocale]}
                    locale={locale}
                    timeZone="UTC"
                    plugins={[dayGridPlugin, timeGridPlugin, list]}
                    header={{
                        left: 'prev,next',
                        center: 'title',
                        right: 'timeGridWeek,dayGridMonth,listMonth'
                    }}
                    events={allEvents}
                    views={{
                        listMonth: {
                            titleFormat: { month: 'long', year: 'numeric', day: 'numeric' },
                            noEventsMessage: "No hay cuestionarios programados para mostrar"
                        }
                    }}
                    height="parent"
                    contentHeight="auto"
                    aspectRatio="default"
                />
            </CardContent>
        </Card>
    )
}

const styles = ((theme) => ({
    calendar: {
        minHeight: '400px',
        height: '100%',
        with: '100%'
    },
    marginIconReturn: {
        marginLeft: "5px",
    },
}))



export default withauthenticatedLayout(withRouter((withStyles(styles, { withTheme: true })(CalendarIndex))))