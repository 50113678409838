///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
import { Card, CardContent, Grid, Fab } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/MenuOutlined'
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
import LoadingPage from '../common/LoadingPage'

/**Import actions section */
import {
	loadQualityCertificateFromBackEnd,
	removeFilesQualityCertificate,
	changeStatusQualityCertificate,
	updateQualityCertificate,
	uploadFilesQualityCertificate,
	addPurchaseOrderDetail,
	removePurchaseOrderDetail,
	validateQualityCertificate,
	downloadFileCertificate,
	importPurchaseOrdersFromExcel,
} from '../../store/qualityCertificate/qualityCertificateAction'
import {
	TableConfiguration,
	onTitleStatusDialog,
	onMessageStatusDialog,
	canBeEditSupplier,
	canBeUpdatePlant,
	getColorText,
	descriptionStatus,
	getErrorInChangeStatus,
	canBeValidate,
} from '../../store/qualityCertificate/qualityCertificateResults'
import { CertificateStatus } from '../../store/helpers/AppConstants'
import { converListToArray } from '../../store/helpers/ListHelper'

import Information from './components/Information'
import DocumentList from './components/DocumentList'
import PurcherOrdersList from './components/PurcherOrdersList'
import PanelAddDetails from './components/PanelAddDetails'
import DialogOperation from '../common/DialogOperation'
import AddCommonItem from '../common/AddCommonItem'
import NotesValidate from './components/NotesValidate'

const Show = (props) => {
	/**Render hooks section */
	const { classes } = props
	const dispatch = useDispatch()
	const translate = useSelector((state) => getTranslate(state.localize))
	const userProfile = useSelector((state) => state.oidc.user)
	const isLoading = useSelector((state) => state.qualityCertificate.get('isLoading'))
	const isSaving = useSelector((state) => state.qualityCertificate.get('isSavingCertificate'))
	const qualityCertificate = useSelector((state) =>
		state.qualityCertificate.get('qualityCertificate')
			? state.qualityCertificate.get('qualityCertificate').toJS()
			: null
	)
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const [openPanelAdd, setOpenAddPanel] = useState(false)
	const [openDialogOperation, setDialogOperation] = useState(false)
	const [openDialogConfirm, setDialogConfirm] = useState({ open: false, status: null })
	let canbeUpdateSupplier = canBeEditSupplier(qualityCertificate, userRolesByCompany, userProfile)
	let canbeUpdatePlant = canBeUpdatePlant(qualityCertificate, userRolesByCompany, userProfile)
	/**Store local this component */
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	/**Use effect section */
	useEffect(() => {
		dispatch(loadQualityCertificateFromBackEnd(props.match.params.token))
	}, [dispatch, props.match.params.token])

	const onUploadFilesQualityCertificate = (id, files, folder) => {
		return dispatch(uploadFilesQualityCertificate(id, files, folder)).then(() => {
			return Promise.resolve('Ok')
		})
	}

	const OnDeleteFilesQualityCertificate = (id, fileName, folder) => {
		return dispatch(removeFilesQualityCertificate(id, fileName, folder)).then(() => {
			return Promise.resolve('Ok')
		})
	}

	const onDialogOperation = () => {
		setDialogOperation(!openDialogOperation)
	}

	const onOpenPanel = () => {
		setOpenAddPanel(!openPanelAdd)
	}

	const onOpenConfirmDialog = (status) => {
		setDialogConfirm({ open: true, status: status })
	}

	const onClosedDialog = () => {
		setDialogConfirm({ open: false, status: null })
	}

	const onAddPurchaseOrder = (detail) => {
		return dispatch(addPurchaseOrderDetail(props.match.params.token, detail))
			.then(() => {
				onOpenPanel()
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
	}

	const onImportPurchaseOrders = (attachments) => {
		return dispatch(importPurchaseOrdersFromExcel(props.match.params.token, attachments))
			.then(() => {
				onOpenPanel()
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: getErrorInChangeStatus(response),
					toasterVariant: 'warning',
				})
			})
	}

	const onRemovePurchaseOrder = (itemId) => {
		return dispatch(removePurchaseOrderDetail(props.match.params.token, itemId)).catch(() => {
			setToasterMessageConfig({
				showToaster: true,
				toasterMessage: 'common.errorToSave',
				toasterVariant: 'error',
			})
		})
	}

	const onChageStatus = () => {
		return dispatch(changeStatusQualityCertificate(props.match.params.token, openDialogConfirm.status))
			.then(() => {
				if (openDialogConfirm.status !== CertificateStatus.REMOVE) {
					setToasterMessageConfig({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.saveCorrectly',
					})
					onClosedDialog()
				} else {
					props.history.push(`/qualityCertificate`)
				}
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: getErrorInChangeStatus(response),
					toasterVariant: 'warning',
				})
			})
	}

	const onValidatePurchaseOrder = (itemId, status) => {
		dispatch(validateQualityCertificate(props.match.params.token, itemId, status))
	}

	const onUpdatePropertyNotes = (comments) => {
		dispatch(updateQualityCertificate(props.match.params.token, comments))
	}

	const getfileDownload = (item) => {
		return dispatch(downloadFileCertificate(props.match.params.token, item))
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}

	const getCardContent = () => {
		if (isLoading === true) {
			return <LoadingPage />
		} else if (qualityCertificate !== null) {
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Information
							qualityCertificate={qualityCertificate}
							getColorText={getColorText}
							descriptionStatus={descriptionStatus}
							translate={translate}
							classes={classes}
						/>
					</Grid>
					<Grid item xs={12}>
						<PurcherOrdersList
							isSaving={isSaving}
							qualityCertificate={qualityCertificate}
							onAddDetail={onOpenPanel}
							onRemoveItem={onRemovePurchaseOrder}
							canBeEditSupplier={canbeUpdateSupplier}
							canBeEditPlant={canbeUpdatePlant}
							canBeValidate={canBeValidate}
							onValidatePurchaseOrder={onValidatePurchaseOrder}
						/>
					</Grid>
					{qualityCertificate.Status !== 0 && (
						<Grid item xs={12}>
							<NotesValidate
								canBeAdd={canbeUpdatePlant}
								qualityCertificate={qualityCertificate}
								onUpdatePropertyNotes={onUpdatePropertyNotes}
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<DocumentList
							canBeEditSupplier={canbeUpdateSupplier}
							qualityCertificate={qualityCertificate}
							onUploadFilesQualityCertificate={onUploadFilesQualityCertificate}
							OnDeleteFilesQualityCertificate={OnDeleteFilesQualityCertificate}
							onGetfileDownload={getfileDownload}
						/>
					</Grid>
				</Grid>
			)
		}
	}

	return (
		<Card>
			{(canbeUpdatePlant || canbeUpdateSupplier) && (
				<Fab
					variant='extended'
					size='small'
					color='primary'
					className={classes.margin}
					onClick={onDialogOperation}
				>
					<MenuIcon className={classes.extendedIcon} />
					{<Translate id='common.AvailableOperations' />}
				</Fab>
			)}
			<CardContent>{getCardContent()}</CardContent>

			{openPanelAdd && (
				<PanelAddDetails
					onCloseButtonClick={onOpenPanel}
					onSaveButtonClick={onAddPurchaseOrder}
					onImportPurchaseOrders={onImportPurchaseOrders}
					isSaving={isSaving}
				/>
			)}

			<ComponentChangeStatus
				onOpenChangeStatus={onOpenConfirmDialog}
				onDialogOperation={onDialogOperation}
				openDialogOperation={openDialogOperation}
				canbeUpdateSupplier={qualityCertificate ? qualityCertificate.CanBeEditSupplier : false}
				canbeUpdatePlant={qualityCertificate ? !qualityCertificate.CanBeEditSupplier : false}
				onTableConfiguration={TableConfiguration}
				status={qualityCertificate ? qualityCertificate.Status : null}
			/>

			{openDialogConfirm.open && (
				<AddCommonItem
					title={<Translate id={onTitleStatusDialog(openDialogConfirm.status)} />}
					message={translate(onMessageStatusDialog(openDialogConfirm.status))}
					open={openDialogConfirm.open}
					onClose={onClosedDialog}
					onSaveItem={onChageStatus}
					isSaving={isSaving}
					saveButtonTranslate={'common.confirm'}
					isMessage={true}
				/>
			)}

			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</Card>
	)
}

const ComponentChangeStatus = (props) => {
	const {
		status,
		openDialogOperation,
		onDialogOperation,
		onOpenChangeStatus,
		canbeUpdateSupplier,
		canbeUpdatePlant,
		onTableConfiguration,
	} = props

	let options = onTableConfiguration(canbeUpdateSupplier, canbeUpdatePlant, status, onOpenChangeStatus)
	return (
		<>
			{openDialogOperation && (
				<DialogOperation open={openDialogOperation} onClose={onDialogOperation} options={options} />
			)}
		</>
	)
}
ComponentChangeStatus.propTypes = {}

const styles = (theme) => ({
	margin: {
		margin: theme.spacing.unit,
	},
	extendedIcon: {
		marginRight: theme.spacing.unit,
	},
	btnStatus: {
		fontSize: '106%',
		whiteSpace: 'normal',
		color: 'white',
		padding: '5px',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
