import autoBind from 'auto-bind'

/**
 * Class definition of Pager based on memory content (synchronous)
 *
 * @export
 * @class MemoryPager
 */
export default class MemoryPager {
    /**
     *Creates an instance of MemoryPager.
     * @param {func} onChangePager Function to handle the change of values an update the DataGrid State
     * @param {*} dataSource Data source to be paginated by pager
     * @param {*} pagerOptions Extra pager options
     * @memberof MemoryPager
     */
    constructor(onChangePager, dataSource, pagerOptions) {
        autoBind(this);
        this.dataSourceRaw = dataSource;
        this.totalCount = dataSource.length;
        this.onChangePager = onChangePager;

        // Definition of initial pagination source values
        this.rowsPerPageOptions = [5, 10, 25];
        if (pagerOptions)
            this.rowsPerPageOptions = pagerOptions;
        this.rowsPerPage = this.rowsPerPageOptions[0];
        this.currentPage = 0;

        // Definition of initialSort values
        this.orderBy = null;
        this.orderType = "desc";

        this.dataRowsPaginated = this.getDataRowsPaginated();
    }

    /**
     * Handle event when change the page number on pager 
     *
     * @param {event} e
     * @param {int} newPage
     * @memberof MemoryPager
     */
    onPageChange(e, newPage) {
        e.preventDefault();
        this.currentPage = newPage;
        this.dataRowsPaginated = this.getDataRowsPaginated();
        this.onChangePager(this);
    }

    /**
     * Handle event when the size of page changes
     *
     * @param {event} e
     * @memberof MemoryPager
     */
    onPageSizeChange(e) {
        e.preventDefault();
        this.rowsPerPage = e.target.value;
        this.currentPage = 0;
        this.dataRowsPaginated = this.getDataRowsPaginated();
        this.onChangePager(this);
    }

    /**
     * Handle when sort change has performed
     *
     * @param {string} orderBy
     * @memberof MemoryPager
     */
    onSortSelection(orderBy) {
        if (this.orderBy === orderBy) {
            this.orderType = this.getNewSortType();
        } else {
            this.orderBy = orderBy;
            this.orderType = "desc";
        }
        this.dataRowsPaginated = this.getDataRowsPaginated();
        this.onChangePager(this);
    }

    /**
     * Function to get inverse sort type of current
     *
     * @returns {string}
     * @memberof MemoryPager
     */
    getNewSortType() {
        return this.orderType === 'desc' ? 'asc' : 'desc';
    }

    /**
     * Function to get property by dot notation
     *
     * @param {object} object Object to get property
     * @param {string} path Path in object param by dot notation
     * @returns {any}
     * @memberof MemoryPager
     */
    getPropertyByPath(object, path) {
        var pathArray = path.split(".");
        while (pathArray.length) {
            object = object[pathArray.shift()];
        }
        return object;
    }

    /**
     * Funcion to get sorted data rows based on values in instance
     *
     * @param {array} dataSource object list to be sorted
     * @param {string} orderType values can be "asc" and "desc"
     * @param {string} orderBy column in property
     * @returns {array} new sorted <dataSource>
     * @memberof MemoryPager
     */
    getDataRowsSorted(dataSource, orderType, orderBy) {
        const orderCriteria = (object) => orderBy.includes(".") ? this.getPropertyByPath(object, orderBy) : object[orderBy];
        const ascSort = (a, b) => orderCriteria(a) < orderCriteria(b) ? 1 : ((orderCriteria(b) < orderCriteria(a)) ? -1 : 0);
        const descSort = (a, b) => orderCriteria(a) > orderCriteria(b) ? 1 : ((orderCriteria(b) > orderCriteria(a)) ? -1 : 0);
        return dataSource.sort((a, b) => (orderType === "asc")? ascSort(a, b): descSort(a, b));
    }

    /**
     * Function to return array of paginated elements based on values provided
     *
     * @returns {Array} 
     * @memberof MemoryPager
     */
    getDataRowsPaginated() {
        let dataDisplayStart = this.currentPage * this.rowsPerPage;
        let dataDisplayEnd = ((this.currentPage + 1) * this.rowsPerPage) - 1;
        let newDataSourceRaw = this.dataSourceRaw;
        if (this.orderBy) {
            newDataSourceRaw = this.getDataRowsSorted(newDataSourceRaw, this.orderType, this.orderBy);
        }
        newDataSourceRaw = newDataSourceRaw.filter((data, indexDataCollection) => (indexDataCollection >= dataDisplayStart && indexDataCollection <= dataDisplayEnd))

        if (newDataSourceRaw.length < this.rowsPerPage) {
            for (let finalOfArray = newDataSourceRaw.length; finalOfArray < this.rowsPerPage; finalOfArray++) {
                newDataSourceRaw.push(null);
            }
        }
        return newDataSourceRaw;
    }
}