/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'

/**Import MaterialUi section */
import {
    Grid, Typography, Avatar, Button
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/DateRangeTwoTone';
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import DatePicker from '../../../common/DateTimePicker/DatePicker'
import HtmlEditor from '../../../common/HtmlEditor'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';
const EditScheduledDateLateralPanel = (props) => {

    /**props section */
    const { onCloseButtonClick,
        isSaving, classes
    } = props
    const [questionnaireModel, setQuestionnaireModel] = useState({ scheduled_date: null, comments: "" });

    /**Use effect section */
    useEffect(() => {
        if ((questionnaireModel.scheduled_date === null) && props.questionnaire) {
            setQuestionnaireModel({
                ...questionnaireModel,
                scheduled_date: props.questionnaire.scheduled_date,
                comments: props.questionnaire.comments,
            })

        }
    }, [])

    /**Verify if can be saved data*/
    const canBeSaved = () => {
        if (questionnaireModel.scheduled_date === null) return false
        if (IsNullOrEmpty(questionnaireModel.comments)) return false
        return true
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            props.onSaveButtonClick(props.questionnaire.token, questionnaireModel)
        }
    }

    const onChangeScheduleDate = (event) => {
        setQuestionnaireModel({
            ...questionnaireModel,
            scheduled_date: event
        })
    }

    const onChange = (value) => {
        setQuestionnaireModel({
            ...questionnaireModel,
            "comments": value
        })
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<Translate id="questionnaire.editScheduled" />}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >
                        <Grid item xs={12} data-tut="reactour-grouper">
                            <Typography className={classes.textTitle} color='primary' variant='subtitle2'>{<Translate id="questionnaire.scheduledDate" />}</Typography>
                            <div style={{marginLeft:"-5px"}}>
                                <DatePicker
                                    onChangeRange={(event) => onChangeScheduleDate(event)}
                                    startDate={moment(questionnaireModel.scheduled_date, 'DD/MM/YYYY')}
                                    minDate={moment()}
                                />
                            </div>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.textTitle} color='primary' variant='subtitle2'>{<Translate id="common.comments" />}</Typography>

                            <HtmlEditor
                                id={"id-modeCreate"}
                                labelVariant="subtitle1"
                                onEditorChange={onChange}
                                initialValue={questionnaireModel ? questionnaireModel.comments : ""}
                                isDisabled={false}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Grid container >
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-tut="reactour-save"
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            disabled={!canBeSaved() || isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "20px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    textTitle: {
        // marginTop:"3px",
        marginBottom: "5px",

    }
});

EditScheduledDateLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

EditScheduledDateLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(EditScheduledDateLateralPanel))