import { Chip, Fab, TableCell, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Visibility, PinDrop } from '@material-ui/icons'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import {
	onGetBackgroundColorChip,
	onGetStatusDescriptionChip,
	ShipsGoStatus,
} from '../../../store/helpers/LoadAlertHelper'
import {
	COLOR_STATUS_DANGER,
	COLOR_STATUS_INFO,
	COLOR_STATUS_SUCCESS,
	COLOR_SUSTAINABILITY_PLUM,
} from '../../../store/helpers/StatusColorConstants'

const LoadAlertCard = (props) => {
	const { classes } = props
	const { item, trackingsTableConfiguration, index } = props
	let history = useHistory()

	const renderStatus = () => {
		switch (item.status) {
			case ShipsGoStatus.Transit:
				return <Translate id='loadAlerts.shipsGoStatus.transit' />
			case ShipsGoStatus.Sailing:
				return <Translate id='loadAlerts.shipsGoStatus.sailing' />
			case ShipsGoStatus.Discharged:
				return <Translate id='loadAlerts.shipsGoStatus.closed' />
			default:
				// Opcional: manejar casos no previstos
				return 'Desconocido'
		}
	}

	// const getStatusBackgroundColor = () => {
	// 	switch (item.status) {
	// 		case ShipsGoStatus.Transit:
	// 			return classes.itemOneTransit
	// 		case ShipsGoStatus.Discharged:
	// 			return classes.itemOneGreen
	// 		case ShipsGoStatus.Sailing:
	// 			return classes.itemSailing
	// 		default:
	// 			// Opcional: manejar casos no previstos
	// 			return classes.itemNoStatus
	// 	}
	// }

	const getStatusBackgroundColor = () => {
		switch (item.status) {
			case ShipsGoStatus.Transit:
				return COLOR_STATUS_INFO
			case ShipsGoStatus.Discharged:
				return COLOR_STATUS_SUCCESS
			case ShipsGoStatus.Sailing:
				return COLOR_SUSTAINABILITY_PLUM
			default:
				// Opcional: manejar casos no previstos
				return COLOR_STATUS_DANGER
		}
	}

	const getBorderLeftStyle = () => {
		switch (item.status) {
			case ShipsGoStatus.Transit:
				return '5px solid rgb(66, 138, 255, 0.435)' // Ejemplo de estilo
			case ShipsGoStatus.Discharged:
				return '5px solid rgb(40, 167, 69)'
			case ShipsGoStatus.Sailing:
				return `5px solid rgb(183, 158, 255)` // Usando el color del tema
			default:
				return '5px solid rgb(220, 53, 69)' // Color de borde por defecto
		}
	}

	// const getStatusCircleColor = () => {
	// 	switch (item.status) {
	// 		case ShipsGoStatus.Transit: // Asegúrate de que 'Transit' sea el valor correcto a comparar
	// 			return classes.divLayout
	// 		case ShipsGoStatus.Discharged: // Asegúrate de que 'Discharged' sea el valor correcto a comparar
	// 			return classes.divLayoutGreen
	// 		case ShipsGoStatus.Sailing:
	// 			return classes.divLayoutSaling
	// 		default:
	// 			return classes.divLayout // O un valor por defecto si es necesario
	// 	}
	// }

	const renderLinked = () => {
		if (item.linked) {
			return (
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<div
						style={{
							width: '12px',
							height: '12px',
							backgroundColor: '#86EDCD',
							marginRight: '4px',
							borderRadius: '50%',
						}}
					></div>
					SIT
				</div>
			)
		} else {
			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						justifyContent: 'center',
					}}
				>
					-
				</div>
			)
		}
	}

	const esPar = (numero) => {
		if (numero % 2 === 0) {
			return true // El número es par
		} else {
			return false // El número es impar
		}
	}

	return (
		<>
			{/* <div style={{ marginTop: '10px' }} /> */}

			<TableRow
				key={item.token}
				style={{
					backgroundColor: esPar(index) ? 'rgba(0,0,0,.05)' : 'white',
					minHeight: '300px !important',
					height: 100,
				}}
			>
				{trackingsTableConfiguration.map((columnItem) => {
					if (columnItem.dataSource === 'Status' && columnItem.visible) {
						return (
							<TableCell style={{ borderLeft: getBorderLeftStyle() }}>
								<Chip
									label={renderStatus()}
									style={{
										borderRadius: '5px',
										width: '100px',
										height: '25px',
										backgroundColor: getStatusBackgroundColor(),
										color: 'white',
									}}
									color='inherit'
								/>
							</TableCell>
						)
					}

					if (columnItem.dataSource === 'StatusAlerta' && columnItem.visible) {
						return (
							<TableCell>
								<Chip
									label={<Translate id={onGetStatusDescriptionChip(item.statusAlert)} />}
									style={{
										borderRadius: '5px',
										width: '110px',
										height: '25px',
										color: 'white',
										backgroundColor: onGetBackgroundColorChip(item.statusAlert),
									}}
									color='inherit'
								/>
							</TableCell>
						)
					}

					if (columnItem.dataSource === 'Linked' && columnItem.visible) {
						return <TableCell padding='none'>{renderLinked()}</TableCell>
					}
					if (columnItem.dataSource === 'ReferenceNumber' && columnItem.visible) {
						return (
							<TableCell padding='none' className={classes.boldText}>
								{item.referenceNumber}
							</TableCell>
						)
					}
					if (columnItem.dataSource === 'Supplier' && columnItem.visible) {
						return (
							<TableCell padding='none' className={classes.wideColumn} style={{ width: 400 }}>
								{item.supplier}
							</TableCell>
						)
					}
					if (columnItem.dataSource === 'Place' && columnItem.visible) {
						return (
							<TableCell padding='none' className={classes.boldText}>
								{item.place}
							</TableCell>
						)
					}
					if (columnItem.dataSource === 'IETD' && columnItem.visible) {
						return (
							<TableCell padding='none' className={classes.boldText}>
								{item.internationalEtd}
							</TableCell>
						)
					}
					if (columnItem.dataSource === 'IETA' && columnItem.visible) {
						return (
							<TableCell padding='none' className={classes.boldText}>
								{item.internationalEta}
							</TableCell>
						)
					}
					if (columnItem.dataSource === 'NationalETA' && columnItem.visible) {
						return (
							<TableCell padding='none' className={classes.boldText}>
								{item.nationalEta}
							</TableCell>
						)
					}
					if (columnItem.dataSource === 'NationalETD' && columnItem.visible) {
						return (
							<TableCell padding='none' className={classes.boldText}>
								{item.nationalEtd}
							</TableCell>
						)
					}
					if (columnItem.dataSource === 'CollectionDate' && columnItem.visible) {
						return (
							<TableCell padding='none' className={classes.boldText}>
								{item.collectionDate}
							</TableCell>
						)
					}
					if (columnItem.dataSource === 'Opciones' && columnItem.visible) {
						return (
							<TableCell
								padding='none'
								align='center'
							>
								<Fab
									size='small'
									color='primary'
									onClick={() => history.push(`/loadAlerts/${item.token}`)}
								>
									<Visibility />
								</Fab>
							</TableCell>
						)
					}

					if (columnItem.dataSource === 'liveMapUrlNational' && columnItem.visible) {
						return (
							<TableCell
								padding='none'
								align='center'
							>
								<a
									href={item.liveMapUrlNational}
									target='_blank'
									rel='noopener noreferrer'
									style={{ textDecoration: 'none' }}
								>
									<Fab size='small' color='primary' >
										<PinDrop />
									</Fab>
								</a>
							</TableCell>
						)
					}
					if (columnItem.dataSource === 'liveMapUrl' && columnItem.visible) {
						return (
							<TableCell
								padding='none'
								align='center'
								style={{
									paddingLeft: 0,
									paddingRight: 0,
									maxWidth: 10,
									wordBreak: 'break-word',
								}}
							>
								<a
									href={item.liveMapUrl}
									target='_blank'
									rel='noopener noreferrer'
									style={{ textDecoration: 'none' }}
								>
									<Fab size='small' color='primary'>
										<PinDrop />
									</Fab>
								</a>
							</TableCell>
						)
					}
					return null // Agregar esta línea para manejar los casos en los que ninguna condición es verdadera
				})}
			</TableRow>
			{/* <div style={{ marginTop: '10px' }} /> */}
		</>
	)
}
const styles = (theme) => ({
	card: {
		marginTop: '8px',
		backgroundColor: '#F9F9F9',
	},
	itemOneTransit: {
		backgroundColor: 'rgba(255, 193, 7, 0.3)',
		// borderRadius: '24px',
		color: '#FFFFFF',
		// display: 'flex',
		// alignItems: 'center',
		// marginLeft: '0',
		// marginRight: '0rem',
		// height: '2.5rem',
		// width: '100%',
	},
	itemOneGreen: {
		backgroundColor: 'rgba(0, 128, 0, 0.3)',
		// borderRadius: '24px',
		color: '#FFFFFF',
		// display: 'flex',
		// alignItems: 'center',
		// marginLeft: '0rem',
		// marginRight: '0rem',
		// height: '2.5rem',
	},
	itemSailing: {
		backgroundColor: theme.palette.secondary.main,
		// borderRadius: '24px',
		color: '#FFFFFF',
		// display: 'flex',
		// alignItems: 'center',
		// marginLeft: '0rem',
		// marginRight: '0rem',
		// height: '2.5rem',
	},
	itemNoStatus: {
		backgroundColor: theme.palette.error.main,
		// borderRadius: '24px',
		color: '#FFFFFF',
		// display: 'flex',
		// alignItems: 'center',
		// marginLeft: '0rem',
		// marginRight: '0rem',
		// height: '2.5rem',
	},
	divLayout: {
		backgroundColor: '#FFC107',
		height: '12px',
		width: '12px',
		borderRadius: '50%',
		marginLeft: '8px',
	},
	divLayoutGreen: {
		backgroundColor: '#388E3C',
		height: '12px',
		width: '12px',
		borderRadius: '50%',
		marginLeft: '8px',
	},
	divLayoutSaling: {
		backgroundColor: theme.palette.primary.main,
		height: '12px',
		width: '12px',
		borderRadius: '50%',
		marginLeft: '8px',
	},
	primaryText: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		// textOverflow: 'ellipsis',
	},
	tableCellContent: {
		display: 'flex',
	},
	boldText: {
		fontWeight: 'bold',
		color: '#6c757d', // Cambia esto al color que prefieras
		paddingLeft: 5,
		paddingRight: 5,
	},
	wideColumn: {
		// maxWidth: 500,
		// minWidth: 500,
		fontWeight: 'bold',
		color: '#6c757d', // Cambia esto al color que prefieras
		paddingLeft: 5,
		paddingRight: 8,
		// whiteSpace: 'nowrap',
		// overflow: 'hidden',
		// textOverflow: 'ellipsis',
	},
})

export default withStyles(styles)(LoadAlertCard)
