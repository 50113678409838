/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/**Import MaterialUi section */
import {
    Grid, Typography, Avatar, Tooltip, IconButton
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import CreateIcon from '@material-ui/icons/Add';
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import SuppliersSelector from '../../../common/SupplierSelector'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';

const AddSuppliersLateralPanel = (props) => {

    /**props section */
    const { onCloseButtonClick,
        isSaving, classes, translate, onOpenModalForAddExternalSupplier,
        onAddSupplierInBiddingFromBackEnd
    } = props

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<Translate id="biddingsModule.create.suppliers" />}
                subTitlePanel={`${translate("biddingsModule.table.folio")} | ${props.bidding ? props.bidding.FormatedFolio : ""}`}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >

                        <Grid item xs={12}>
                            <Typography >{<Translate id="biddingsModule.create.suppliersParticipate" />}</Typography>
                            <SuppliersSelector
                                onSupplierSelected={(supplier) => onAddSupplierInBiddingFromBackEnd(supplier)}
                                isDisabled={isSaving}
                            /><br/>
                        </Grid><br/><br/><br/>

                        <Grid item xs={11}>
                            <Typography color='primary'>
                                <Translate id="biddingsModule.addSupplierNotRegister" />
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title={translate("common.add")}>
                                <IconButton
                                    color="primary"
                                    aria-label="More"
                                    onClick={onOpenModalForAddExternalSupplier}
                                >
                                    <CreateIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>

                </div>
            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "20px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    }


});

AddSuppliersLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddSuppliersLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddSuppliersLateralPanel))