export const COLOR_JANUARY="#f09cae";
export const COLOR_FEBRUARY="#9cf0de";
export const COLOR_MARCH="#9caef0";
export const COLOR_APRIL="#f0de9c";
export const COLOR_MAY="#aef09c";
export const COLOR_JUNE="#f0ad9c";
export const COLOR_JULY="#7aa7f5";
export const COLOR_AUGUST="#cc9cf0";
export const COLOR_SEPTEMBER="#f5e877";
export const COLOR_OCTOBER="#8df5a9";
export const COLOR_NOVEMBER="#f08ab8";
export const COLOR_DECEMBER="#baf567";

export const getColor = (month) => {
	switch (month) {
		case 1:
			return COLOR_JANUARY
		case 2:
			return COLOR_FEBRUARY
		case 3:
			return COLOR_MARCH
		case 4:
			return COLOR_APRIL
		case 5:
			return COLOR_MAY
		case 6:
			return COLOR_JUNE
		case 7:
			return COLOR_JULY
		case 8:
			return COLOR_AUGUST
		case 9:
			return COLOR_SEPTEMBER
		case 10:
			return COLOR_OCTOBER
		case 11:
			return COLOR_NOVEMBER
		case 12:
			return COLOR_DECEMBER
		default:
			return '#fff'
	}
}


