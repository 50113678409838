import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/** Material UI imoprt section */
import {
	CircularProgress,
	Collapse,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
	withStyles
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

/** Custom components import section */
import LoadingPage from '../../../common/LoadingPage';

const SapMessages = (props) => {
	const { loading, attemps, classes, onAttempClick } = props;

	const [openedAttemps, setOpenedAttemps] = React.useState([]);

	const handleClick = (item) => {
		if (openedAttemps.includes(item.key)) {
			let newOpenedAttemps = [...openedAttemps];
			let index = newOpenedAttemps.indexOf(item.key);
			newOpenedAttemps.splice(index, 1);
			setOpenedAttemps(newOpenedAttemps);
		} else {
			if (item.errors.length === 0) {
				onAttempClick(item);
			}
			setOpenedAttemps([...openedAttemps, item.key]);
		}
	};

	const renderEmptyMessage = () => {
		return <Typography>Sin mensajes registrados</Typography>;
	};

	const renderAttempList = () => {
		return (
			<List>
				{attemps.map((item) => (
					<>
						<ListItem button key={item.key} onClick={(_) => handleClick(item)}>
							<ListItemText
								primary={`FECHA: ${item.creationDate ? moment(item.creationDate).format('DD/MM/YYYY HH:mm') : ''}`}
							/>
							{openedAttemps.includes(item.key) ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={openedAttemps.includes(item.key)} timeout="auto" unmountOnExit>
							<List component="div" disablePadding dense>
								{
									{
										true: (
											<ListItem className={classes.nested}>
												<ListItemText primary={`» Cargando lista de errores...`} />
												<ListItemSecondaryAction>
													<CircularProgress size={18} disableShrink />
												</ListItemSecondaryAction>
											</ListItem>
										),
										false: item.errors.map((error) => (
											<ListItem key={error.token} className={classes.nested}>
												<ListItemText primary={`» ${error.sapIdError} | ${error.errorMessage}`} />
											</ListItem>
										))
									}[item.errors.length === 0]
								}
							</List>
						</Collapse>
					</>
				))}
			</List>
		);
	};

	const renderContent = () => {
		return (
			<>
				{<Typography>Mensajes de SAP</Typography>}
				{attemps.length === 0 ? renderEmptyMessage() : renderAttempList()}
			</>
		);
	};

	return (
		<div className={classes.contentContainer}>
			{
				{
					true: <LoadingPage />,
					false: renderContent()
				}[loading]
			}
		</div>
	);
};

const styles = (theme) => ({
	contentContainer: {
		padding: '5px 24px',
		overflowY: 'auto'
	},
	nested: {
		paddingLeft: 8,
		maxHeight: 40
	}
});

SapMessages.propTypes = {
	attemps: PropTypes.array.isRequired,
	onAttempClick: PropTypes.func.isRequired,
	isOrderRequest: PropTypes.bool.isRequired
};

SapMessages.defaultProps = {
	attemps: [],
	onAttempClick: () => console.warn('[onAttempClick] not defined!')
};

export default withStyles(styles, { withTheme: true })(SapMessages);
