import React from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'

import CatalogHeader from './components/CatalogHeader'
import ThereIsNoInformation from '../common/ThereIsNoInformation'

const AvailableSchedules = () => {
	return (
		<div>
			<CatalogHeader
				setOpenDialog={() => ''}
				icon={<AccessAlarmIcon />}
				isInternalUse={true}
				title={<Translate id={'carrierTenders.configuration.rangeChargingSchedules'} />}
			/>
			<ThereIsNoInformation />
		</div>
	)
}

export default withauthenticatedLayout(withRouter(AvailableSchedules))
