///Import react section
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { Translate } from 'react-localize-redux';

/**Import material ui section */
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import ReportGuaranteIcon from '@material-ui/icons/FileCopyOutlined';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import FormView from './components/FormView';
import {createGuaranteeReport} from'../../store/guaranteeReport/guaranteeReportAction';


class Create extends Component {
  constructor (props) {
    super(props)
    this.state = {
      creationDate: moment().format('DD/MM/YYYY'),
    }
  }

  render () {
    return (
      <Card>
        <CardHeader
            avatar={<ReportGuaranteIcon />}
            title={<Typography variant="button" ><Translate id="moduleGuarantee.reportGuarantee.titleReportGuarantee" /></Typography>}
            subheader={<Typography variant="caption" color="textPrimary"><Translate id="moduleGuarantee.branchOffice" /> {this.props.user.profile.name}
                <br /><Translate id="common.creationDate" />: {this.state.creationDate}</Typography>}
        />
        <CardContent>
            <FormView
                {...this.props}
                isSaving = {this.props.isSaving}
            />
        </CardContent>
      </Card>
    )
  }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
  let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };

  return {
    user: user,
    isSaving: state.guaranteeReport.get('isSaving'),
  }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
  return {
    createGuaranteeReport: (data) => {
      return dispatch(createGuaranteeReport(data))
    }
  }
}
const styles = theme => ({
  toolbar: {
      display: "flex"
  },
  actionsToolbar: {
      display: "flex"
  },
  searchInput: {
      marginTop: "5px"
  },
  button: {
      margin: theme.spacing.unit,
  },
  showingUsersCounter: {
      color: theme.palette.secondary.main
  },
  grow: {
      flexGrow: 1,
  },
  formControl: {
      margin: theme.spacing.unit * 3,
  },
  supplierBadge: {
      padding: `0 ${theme.spacing.unit * 2}px`,
  }
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create))));

