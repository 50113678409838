import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

/** Components imports section */
import Evidence from './Evidence';

/** Material UI imports Component */
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

/**
 * Class component to show dialog with a dropzone to attach files in item on list
 *
 * @class AttachmentsDialog
 * @extends {Component}
 */
class AttachmentsDialog extends Component {

    /**
     *Creates an instance of AttachmentsDialog.
     * @param {*} props
     * @memberof AttachmentsDialog
     */
    constructor(props) {
        super(props)
        this.state = {
            attachments: []
        };
    };

    /**
     * React life-cycle method
     * Update status if props has change
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof AttachmentsDialog
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.item !== prevProps.item) {
            this.setState({
                attachments: this.props.item.attachments
            })
        }
    }

    /**
     * Handle upload files event and call action to save them
     *
     * @param {*} files
     * @returns
     * @memberof AttachmentsDialog
     */
    handleUploadFiles(files) {
        if (this.props.uploadFiles)
            return this.props.uploadFiles(files, this.props.item.token)
                .then(() => {
                    return Promise.resolve('Ok')
                })
                .catch(() => {
                    return Promise.reject()
                })
    }

    /**
     * Handle remove event and call action to delete selected file
     *
     * @param {*} fileName
     * @returns
     * @memberof AttachmentsDialog
     */
    handleRemoveFiles(fileName) {
        if (this.props.removeFiles)
            return this.props.removeFiles(fileName, this.props.item.token)
                .then(() => {
                    return Promise.resolve('Ok')
                })
                .catch(() => {
                    return Promise.reject()
                })
    }

    render() {
        let open = this.props.show ? this.props.show : false;
        return (
            <Dialog open={open}>
                <DialogTitle id="form-dialog-title">{this.props.item.part_number} {this.props.item.part_description}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography><Translate id="common.attachments" /></Typography>
                            <Evidence
                                text={this.props.dropzoneText}
                                baseApi={process.env.REACT_APP_SIP_URL_FILES}
                                removeFiles={(event) => this.handleRemoveFiles(event)}
                                uploadFiles={(event) => this.handleUploadFiles(event)}
                                files={this.props.item.attachments}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        <Translate id="common.close" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AttachmentsDialog.propTypes = {
    item: PropTypes.object.isRequired,
    removeFiles: PropTypes.func.isRequired,
    uploadFiles: PropTypes.func.isRequired
};

AttachmentsDialog.defaultProps = {
    show: false,
}


export default AttachmentsDialog;