/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';

export const START_LOADING_NON_CONFORMITIES = "START_LOADING_NON_CONFORMITIES";
export const SET_NON_CONFORMITIES_LIST = "SET_NON_CONFORMITIES_LIST";
export const IS_SAVING_NON_CONFORMITY = "IS_SAVING_NON_CONFORMITY";
export const UPDATE_NON_CONFORMITY = "UPDATE_NON_CONFORMITY";
export const ADD_NON_CONFORMITY_TO_LIST = "ADD_NON_CONFORMITY_TO_LIST";
export const CHANGE_NON_CONFORMITIES_SORT_CRITERIA = "CHANGE_NON_CONFORMITIES_SORT_CRITERIA"
export const CHANGE_NON_CONFORMITIES_SORT_QUERY = "CHANGE_NON_CONFORMITIES_SORT_QUERY";
export const CHANGE_NON_CONFORMITIES_PAGINATION = "CHANGE_NON_CONFORMITIES_PAGINATION";
export const SET_NON_CONFORMITIES_COUNT = "SET_NON_CONFORMITIES_COUNT";

/**
 * Action start loading non conformities
 * @param {*} isLoading 
 */
function isLoading(isLoading) {
    return {
        type: START_LOADING_NON_CONFORMITIES,
        isLoading
    }
}

/**
 * Action to set non conformities list
 *
 * @param {*} nonConformities
 * @returns
 */
function setNonConformitiesList(nonConformities) {
    return {
        type: SET_NON_CONFORMITIES_LIST,
        nonConformities
    }
}


/**
 * Action to set flag isSaving 
 *
 * @param {*} isSaving
 * @returns
 */
function isSavingNonConformity(isSaving) {
    return {
        type: IS_SAVING_NON_CONFORMITY,
        isSaving
    }
}

/**
 * action to updte non conformity data
 * Currently is not in use, will be development late
 *
 * @param {*} nonConformity
 * @returns
 */
function updateNonConformity(nonConformity) {
    return {
        type: UPDATE_NON_CONFORMITY,
        nonConformity
    }
}

/**
 * Action to add nonConformity to the list nonConformities
 *
 * @param {*} nonConformity
 * @returns
 */
function addNonConformityToList(nonConformity) {
    return {
        type: ADD_NON_CONFORMITY_TO_LIST,
        nonConformity
    }
}

/**
 * Action to set non conformities count
 *
 * @param {*} count
 * @returns
 */
function setNonConformitiesCount(count) {
    return {
        type: SET_NON_CONFORMITIES_COUNT,
        count
    }
}

/**
 * Action to load Non conformities list from backend endpoint
 *
 * @export
 * @returns
 */
export function loadNonConformityFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {

        dispatch(isLoading(true));
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/nonconformities/filters`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
            GetAllReports: false,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setNonConformitiesList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoading(false));
                console.error("Error getting the non conformities  list", error.response)
            })
    }
}

/**
 * Action to get Non conformity count
 *
 * @export
 * @returns
 */
export function getNonConformityCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().nonConformities ? getState().nonConformities.get('nonConformitiesCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/nonconformity/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setNonConformitiesCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the non conformities count", error.response)
            })
    }
}
/**
 * Action to add non conformity data
 *
 * @export
 * @param {*} nonConformity
 * @returns
 */
export function addNonConformityData(nonConformity) {
    return function (dispatch, getState) {
        dispatch(isSavingNonConformity(true));
        let selectedCompanyToken = getState().profile.get("selectedCompany").get('token');
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/nonconformity`;
        let data = {
            NameSpanish: nonConformity.name,
            NameEnglish: nonConformity.name_english,
            NamePortuguese: nonConformity.name_portuguese,
            CompanyToken: selectedCompanyToken,
        }
        return axios.post(endPoint, data)
            .then((response) => {
                dispatch(addNonConformityToList(response.data))
            })
            .catch((error) => {
                console.error("Error to add user", error.response);
                dispatch(isSavingNonConformity(false));
                return Promise.reject();
            })
    }
}

/**
 * Action to serch Failure Non Conformity by Query 
 * @param {*} query 
 */
export function searchByFailureNonConformityByQuery(){
    return function(dispatch, getState){

        dispatch(isLoading(true));
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/nonconformities/filters`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            Query: "",
            Page: 1,
            RowsPerPage: 50,
            GetAllReports: true,
        }
        return axios.post(endPoint, data, configuration)
        .then((response) => {
            if (response.status === 200) {
                dispatch(isLoading(false));
                return Promise.resolve(response.data);
            }
        })
        .catch((error) => {
            dispatch(isLoading(false));
            console.error(error);
        });        
    }
}

/**
 * Action to update non conformity failure
 *
 * @export
 * @param {*} nonConformity
 * @returns
 */
export function updateNonConformityData(nonConformity) {

    return function (dispatch, getState) {

        dispatch(isSavingNonConformity(true));
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/nonconformity`;
        let data = {
            TokenConformity: nonConformity.token,
            NameSpanish: nonConformity.name,
            NameEnglish: nonConformity.name_english,
            NamePortuguese: nonConformity.name_portuguese
        }
        return axios.put(endPoint, data)
            .then((response) => {
                dispatch(updateNonConformity(response.data))
                dispatch(isSavingNonConformity(false));
            })
            .catch((error) => {
                console.error("Error to update non conformity failure", error.response);
                dispatch(isSavingNonConformity(false));
                return Promise.reject();
            })
    }
}