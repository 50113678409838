import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import Select from 'react-select'
import moment from 'moment'

/** Material-UI imports section */
import { Grid, Paper, Typography, Toolbar, TextField, FormGroup, FormControlLabel } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'

/** Import component section */
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'
import DatePicker from '../../common/DateTimePicker/DatePicker'
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import Evidence from '../../common/Evidence'
import { GetTrackingContractType } from '../../../store/helpers/SelectOptions';

/**
 * Dialog to EditContractModal
 */
class EditContractModal extends Component {
	/**
	 *Creates an instance of AddContractModal.
	 * @param {*} props
	 * @memberof AddContractModal
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			contract: {
				id: this.props.item ? this.props.item.id : '',
				token: this.props.item ? this.props.item.token : '',
				start_date: this.props.item ? this.props.item.start_date : '',
				end_date: this.props.item ? this.props.item.end_date : '',
				name: this.props.item ? this.props.item.name : '',
				is_contract_main: this.props.item ? this.props.item.is_contract_main : false,
				notes: this.props.item ? this.props.item.notes : '',
				contractType: this.props.item ? this.props.item.contractType : 0,
				update_start_date: false,
				update_end_date: false,
				attachments: [],
			},
		}
	}

	/**
	 * Action to can be  information
	 */
	canBeSaved() {
		if (this.props.isSaving) return false
		else {
			let contract = this.state.contract
			if (contract.start_date.length === 0) return false
			if (contract.end_date.length === 0) return false
			if (moment(contract.end_date).isBefore(contract.start_date)) return false
		}
		return true
	}

	/**
	 * Update the properties in state
	 *
	 * @param {*} user
	 * @param {*} value
	 * @memberof View
	 */
	updateProperty(property, value) {
		let contractpdate = { ...this.state.contract }
		contractpdate[property] = value
		if (property === 'start_date') contractpdate['update_start_date'] = true
		if (property === 'end_date') contractpdate['update_end_date'] = true
		this.setState({
			contract: contractpdate,
		})
	}

	/**
	 * Remove files
	 */
	removeFiles(fileName) {
		let attachments = this.state.contract.attachments.filter((file) => {
			return file.name !== fileName
		})
		this.setState({
			contract: {
				...this.state.contract,
				attachments,
			},
		})
		return Promise.resolve('Ok')
	}

	/**
	 * Add file
	 * @param {*} attachments
	 */
	uploadFiles(files) {
		let filesList = this.state.contract.attachments
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.push(files[index])
			}
		}
		this.setState({
			contract: {
				...this.state.contract,
				attachments: filesList,
			},
		})
		return Promise.resolve('Ok')
	}

	/**
	 * handle event on close or on cancel to clean state of modal
	 *
	 * @memberof AddActionToListDialog
	 */
	handleOnCloseCancel() {
		this.setState({
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			contract: {
				attachments: [],
				start_date: '',
				end_date: '',
				name: '',
				notes: '',
				contractType: 0,
				is_contract_main: false,
			},
		})
		if (this.props.onClose) this.props.onClose()
	}

	/**
	 * Function to create
	 * @param {*} worker
	 */
	onEditNewContract() {
		this.props
			.editContract(this.state.contract)
			.then(() => {
				this.setState({
					contract: {
						attachments: [],
						start_date: '',
						end_date: '',
						name: '',
						notes: '',
						contractType: 0,
						is_contract_main: false,
					},
				})
			})
			.catch(() => {})
	}

	/**
	 * Dialog to update information
	 */
	render() {
		const { classes } = this.props
		let open = this.props.open ? this.props.open : false
		let options = GetTrackingContractType()
		return (
			<Paper style={{ overflowX: 'hidden' }}>
				<Dialog
					style={{ overflowX: 'hidden' }}
					open={open}
					onClose={this.props.onClose}
					header={
						<Toolbar>
							<Typography variant='h6'>{'Editar folio legal'}</Typography>
						</Toolbar>
					}
					actions={
						<DialogActions
							view={SHOW_EDIT_VIEW}
							classes={classes}
							onCancel={() => this.handleOnCloseCancel()}
							onClose={this.props.onClose}
							onSaveItem={this.onEditNewContract}
							isSaving={this.props.isSaving}
							canBeSaved={this.canBeSaved()}
						/>
					}
				>
					<Grid container style={{ maxWidth: 500 }} justify='space-between'>
						<Grid item xs={12}>
							<Typography variant="caption" color={'textSecondary'} style={{ paddingBottom: "1rem"}}>{<Translate id="constracts.contractType" />}</Typography>
							<Select 
                                value={options[this.state.contract.contractType]}
                                options={options}
                                onChange={(event) => { this.updateProperty("contractType", event.value) }}
                                menuPosition="fixed" 
                                menuPlacement={"auto"}
                            />
						</Grid>
						<Grid item xs={6}>
							<Typography variant='caption' color={'textSecondary'} style={{ paddingTop: "1rem"}}>
								{<Translate id='constracts.nameDocument' />}
							</Typography>
							<TextField
								autoFocus
								fullWidth
								label={''}
								value={this.state.contract.name}
								onChange={(event) => {
									this.updateProperty('name', event.target.value)
								}}
								margin='normal'
							/>
						</Grid>
						<Grid item xs={6}>
							<FormGroup style={{ marginLeft: '32px', marginTop: '38px' }}>
								<FormControlLabel
									label='Marcar como activo'
									control={
										<Switch
											onChange={(event) => {
												this.updateProperty('is_contract_main', event.target.checked)
											}}
											checked={this.state.contract.is_contract_main ? true : false}
										/>
									}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={6}>
							<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
								<Translate id='officialNotices.activationDate' />
							</Typography>
							<DatePicker
								onChangeRange={(event) => {
									this.updateProperty('start_date', event)
								}}
								startDate={moment(this.state.contract.start_date, 'DD/MM/YYYY')}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
								<Translate id='officialNotices.expirationDate' />
							</Typography>
							<DatePicker
								onChangeRange={(event) => {
									this.updateProperty('end_date', event)
								}}
								startDate={moment(this.state.contract.end_date, 'DD/MM/YYYY')}
								minDate={moment(this.state.contract.start_date, 'DD/MM/YYYY')}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
								{<Translate id='common.comments' />}
							</Typography>
							<TextField
								fullWidth
								id='outlined-multiline'
								label={''}
								value={this.state.contract.notes}
								onChange={(event) => {
									this.updateProperty('notes', event.target.value)
								}}
								variant='outlined'
							/>
						</Grid>
						<Grid item xs={12}>
							<br />
							<Evidence
								baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
								removeFiles={this.removeFiles}
								uploadFiles={this.uploadFiles}
								files={this.state.contract.attachments}
								acceptedFiles={['pdf']}
								disabledLink
								text={this.props.dropzoneText}
							/>
						</Grid>
					</Grid>
				</Dialog>
			</Paper>
		)
	}
}

EditContractModal.defaultProps = {
	open: false,
}

const dialogStyles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	toolBar: {
		backgroundColor: 'red',
	},
	button: {
		margin: theme.spacing.unit,
	},
	errorMessage: {
		color: theme.palette.common.white,
	},
	textField: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit,
	},
})

export default withStyles(dialogStyles, { withTheme: true })(EditContractModal)
