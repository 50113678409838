import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';

/** Material UI import section */
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Popover,
    ListItemSecondaryAction
} from '@material-ui/core';

/** Resources import section */
import { BACKGROUND_COLOR_GRAY_BIDDING, RELEASE_PLAN_COLOR_YELLOW } from '../../../../store/helpers/StatusColorConstants';
import { getLogosByCountry } from '../../../../store/helpers/DivisasHelper'
const DivisaComponent = props => {
    const { classes, divisas, isSaving, divisa, isDisabled } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [divisaInItem, setDivisaInItem] = React.useState(null);

    const handlePopoverOpen = (event) => {
        if (open) {
            setAnchorEl(null);
            setOpen(false)
        } else {
            setOpen(true)
            setAnchorEl(event.currentTarget);
        }
    };

    useEffect(() => {
        if (divisaInItem === null && props.divisa) {
            setDivisaInItem(divisa)
        }
    }, [props.divisaCode]);
    
    const onSetDivisaInBidding = (divisa) => {
        if (props.onDivisaInBiddingFromBackEnd) {
            props.onDivisaInBiddingFromBackEnd(divisa)
                .then(() => {
                    setDivisaInItem(divisa)
                    setAnchorEl(null);
                    setOpen(false)
                })
        }
    }

    return (
        <div>
            <List className={(divisaInItem && divisaInItem.Code)  ? classes.divisaInBidding : classes.noSelectedDivisaInBidding}>
                < ListItem
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    button
                    onClick={handlePopoverOpen}
                    disabled={isDisabled}
                    className={classes.listItem}
                >
                    <ListItemText
                        primary={
                            <Typography variant="subtitle2" color='primary'>
                                {divisaInItem ? (divisaInItem.Code ? divisaInItem.Code : "MXN") : "MXN"}
                            </Typography>
                        }
                    />

                    <ListItemSecondaryAction className={classes.paddingNone}>
                        {getLogosByCountry(divisaInItem ? divisaInItem.Code : "") && <img
                            onClick={isDisabled ? null: handlePopoverOpen}
                            src={getLogosByCountry(divisaInItem ? divisaInItem.Code : "")}
                            alt='contryLogo'
                            width='30px'
                            height='auto'
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                        />}
                    </ListItemSecondaryAction>
                </ListItem>

            </List>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverOpen}
            >
                <List>
                    {
                        (divisas && divisas.length !== 0) ? divisas.map(divisa => {
                            return (
                                < ListItem
                                    key={divisa.Id}
                                    button
                                    disabled={isSaving}
                                    onClick={() => onSetDivisaInBidding(divisa)}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography variant="subtitle2" color="primary">
                                                {divisa.Name}
                                            </Typography>
                                        }
                                    />

                                    <ListItemSecondaryAction className={classes.itemFlex}>
                                        <img src={getLogosByCountry(divisa.Code)} alt='contryLogo' width='30px' height='auto' />
                                    </ListItemSecondaryAction>
                                </ListItem>

                            )
                        }) : <Typography variant="subtitle2"> <Translate id="biddingsModule.notExistDivisa" /></Typography>
                    }

                </List>


            </Popover>
        </div>
    );
}


const useCardStyle = ((theme) => ({
    searchBoxContainer: {
        width: "100%"
    },
    divisascontainer: {
    },
    paper: {
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
        padding: "10px"
    },
    divisaInBidding: {
        width: "90px",
        padding: "0",
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING
    },
    noSelectedDivisaInBidding: {
        width: "90px",
        padding: "0",
        backgroundColor: RELEASE_PLAN_COLOR_YELLOW
    },
    listItem:{
        height:"40px"
    }
}))


export default (withStyles(useCardStyle, { withTheme: true })(DivisaComponent))