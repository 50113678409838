/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';

export const START_LOADING_REWORKS = "START_LOADING_REWORKS";
export const SET_REWORKS_LIST = "SET_REWORKS_LIST";
export const CHANGE_REWORKS_SORT_CRITERIA = "CHANGE_REWORKS_SORT_CRITERIA";
export const CHANGE_REWORKS_QUERY = "CHANGE_REWORKS_QUERY";
export const CHANGE_REWORKS_PAGINATION = "CHANGE_REWORKS_PAGINATION";
export const SET_REWORKS_COUNT = "SET_REWORKS_COUNT";
export const IS_SAVING = "IS_SAVING";
export const IS_LOADING = "IS_LOADING";
export const SET_REWORK = "SET_REWORK";
export const IS_LOADING_ALL_REWORKS_REPORTS = "IS_LOADING_ALL_REWORKS_REPORTS";

/**
 * Action start loading reworks
 * @param {*} isLoading 
 */
function isLoadingReworks(isLoading) {
    return {
        type: START_LOADING_REWORKS,
        isLoading: isLoading
    }
}

/**
 * Action to set reworks  list 
 * @param {*} reworks 
 */
function setreworksList(reworks) {
    return {
        type: SET_REWORKS_LIST,
        reworks: reworks
    }
}

/**
 * Action reducer to set reworks counter
 *
 * @param {*} count
 * @returns
 */
function setReworksCount(count) {
    return {
        type: SET_REWORKS_COUNT,
        count
    }
}

/**
 * Action to is rework
 * @param {*} isSaving 
 */
function isSaving(isSaving){
    return {
        type: IS_SAVING,
        isSaving:isSaving
    }
}

/**
 * Action to is loaidng rework
 * @param {*} isLoading 
 */
function isLoading(isLoading) {
    return {
        type: IS_LOADING,
        isLoading: isLoading
    }
}

/**
 * Action to set rework
 * @param {*} rework 
 */
function setRework(rework) {
    return {
        type: SET_REWORK,
        rework: rework
    }
}

/**
 * Get all Reworks by company from backend 
 * DONE
 */
export function loadReworksFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, isAllReports) {
    return function (dispatch, getState) {
        dispatch(isLoadingReworks(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks/all`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
            GetAllReports: isAllReports,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setreworksList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingReworks(false));
                console.error("Error getting reworks by company list", error.response)
            })
    }
}


/**
 * Get rework's count
 * @export
 * @returns
 */
export function getReworksCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().reworks ? getState().reworks.get('reworksCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setReworksCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the reworks count", error.response)
            })
    }
}

/**
 * Get and set the Rework in the estate from read from backend
 * @param {*} token 
 */
export function loadReworkFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(isLoading(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks/rework/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setRework(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load Rework", error.response)
                dispatch(isLoading(false))
                return Promise.reject();
            })
    }
}

/**
 * Authorizer Request Rework
 *
 * @export
 * @param {*} requestToken
 * @param {*} commnet
 * @returns
 */
export function authorizerRequestRework(requestToken, commnet) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            RequestToken: requestToken,
            AuthorizeUserName: user.profile.preferred_username,
            AuthorizeBy: user.profile.name,
            Comment: commnet,
        }
        
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks/requestrework/authorizer`;
        return axios.put(endPoint, data, configuration)
        .then((serverResponse) => {
            if (serverResponse.status === 200) {
                dispatch(setRework(serverResponse.data))
                dispatch(isSaving(false))
                return Promise.resolve()
            }
        })
        .catch((error) => {
            dispatch(isSaving(false))
            console.error("Error in authorizer request rework ", error.response)
            return Promise.reject();
        })
    }
}

/**
 * Rejected Request Rework
 *
 * @export
 * @param {*} requestToken
 * @param {*} commnet
 * @returns
 */
export function rejectedRequestRework(requestToken, commnet) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            RequestToken: requestToken,
            AuthorizeUserName: user.profile.preferred_username,
            AuthorizeBy: user.profile.name,
            Comment: commnet,
        }
        
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks/requestrework/rejected`;
        return axios.put(endPoint, data, configuration)
        .then((serverResponse) => {
            if (serverResponse.status === 200) {
                dispatch(setRework(serverResponse.data))
                dispatch(isSaving(false))
                return Promise.resolve()
            }
        })
        .catch((error) => {
            dispatch(isSaving(false))
            console.error("Error in rejected request rework ", error.response)
            return Promise.reject();
        })
    }
}

/**
 * Action to create a rework
 *
 * @export
 * @param {*} token
 * @returns
 */
export function createRework(data) {
    return function (dispatch, getState) {

        dispatch(isLoading(true))
        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks`;

        var rework = {
            CompanyToken: selectedCompany.get('token'),
            DocumentIdentifier: selectedCompany.get('document_identifier'),
            CompanyIdentifier: selectedCompany.get("get_company_identifier"),
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            PreparedBy: data.preparedBySelected.label,

            PartNumberCode: data.partNumber.code,
            PartDescription: data.partNumber.description,
            PartMeasurementUnit: data.partNumber.measurementUnit,

            QualityMaterial: data.quantity_of_material,
            DeliveryDate: data.delivery_date,
            DescriptionOfRework: data.activity_description_to_be_performed,
            Area: data.area,
            CostCenter: data.cost_center,
            SupplierToken: data.supplierSelected.token,
            SupplierCode: data.supplierSelected.code,
            SupplierName: data.supplierSelected.name,

            SorterToken: data.sorter.token,
            SorterName: data.sorter.name,
            SorterNumber: data.sorter.code,
        }

        return axios.post(endPoint, rework, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isLoading(false))
                    return Promise.resolve(serverResponse.data);
                }
            })
            .catch((error) => {
                console.error("Error to load Rework", error.response)
                dispatch(isLoading(false))
                return Promise.reject();
            })
    }
}

/**
 * Action to created rework re
 */
export function createReworkReportExecuted(attachments, reworkReport){
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let tokenRework = getState().reworks.get('rework').get('token');
        let formData = new FormData();
        attachments.forEach((file) => {
            formData.append('file', file);
        })
        formData.set('RequestToken', tokenRework);
        formData.set('CreatorUserName', user.profile.preferred_username);
        formData.set('CreateBy', user.profile.name);
        formData.set('PiecesOk', reworkReport.piecesOk);
        formData.set('ContainmentMark', reworkReport.containmentMark);
        formData.set('CommentBySorter', reworkReport.commentBySorter);

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks/reworkreport`;
        return axios.put(endPoint, formData, configuration)
        .then((serverResponse) => {
            if (serverResponse.status === 200) {
                dispatch(isSaving(false))
                return Promise.resolve()
            }
        })
        .catch((error) => {
            dispatch(isSaving(false))
            console.error("Error add rework by sorter in request rework ", error.response)
            return Promise.reject();
        })
    }
}

/**
 * Authorizer Request Rework
 *
 * @export
 * @param {*} requestToken
 * @param {*} commnet
 * @returns
 */
export function acceptedRequestReworkReport(requestToken, commnet) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            RequestToken: requestToken,
            AuthorizeUserName: user.profile.preferred_username,
            AuthorizeBy: user.profile.name,
            Comment: commnet,
        }
        
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks/aceptedreworkreport`;
        return axios.put(endPoint, data, configuration)
        .then((serverResponse) => {
            if (serverResponse.status === 200) {
                dispatch(setRework(serverResponse.data))
                dispatch(isSaving(false))
                return Promise.resolve()
            }
        })
        .catch((error) => {
            dispatch(isSaving(false))
            console.error("Error in authorizer rework report ", error.response)
            return Promise.reject();
        })
    }
}

/**
 * Rejected Request Rework
 *
 * @export
 * @param {*} requestToken
 * @param {*} commnet
 * @returns
 */
export function rejectedRequestReworkReport(requestToken, commnet) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            RequestToken: requestToken,
            AuthorizeUserName: user.profile.preferred_username,
            AuthorizeBy: user.profile.name,
            Comment: commnet,
        }
        
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks/rejectreworkreport`;
        return axios.put(endPoint, data, configuration)
        .then((serverResponse) => {
            if (serverResponse.status === 200) {
                dispatch(setRework(serverResponse.data))
                dispatch(isSaving(false))
                return Promise.resolve()
            }
        })
        .catch((error) => {
            dispatch(isSaving(false))
            console.error("Error in rejected rework report ", error.response)
            return Promise.reject();
        })
    }
}

/**
 * Action to rework Close To Scrap
 * @param {*} token 
 */
export function reworkCloseToScrap(token) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {}
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks/${token}/closetoscrap`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setRework(serverResponse.data))
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in close rework to scrap", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to create devolution 
 * @param {*} preparedBy 
 * @param {*} failure 
 * @param {*} devolution 
 */
export function createDevolutionFromRework(preparedBy, planningSelected, developmentSelected, comments){
    return (dispatch, getState)=>{
        
        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let tokenRework = getState().reworks.get('rework').get('token');
        let configuration = getAuthorizedRequestConfigurationUser(user);
        
        let data = {
            WorkFlowToken : tokenRework,
            DocumentIdentifier: selectedCompany.get('document_identifier'),
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,

            PreparedBy: preparedBy ? preparedBy.label : "",
            Comments: comments,
            PlanningEmail: planningSelected ? planningSelected.user_email : "",
            PlanningName: planningSelected ? planningSelected.full_name : "",
            DevelopmentEmail: developmentSelected ? developmentSelected.user_email : "",
            DevelopmentName: developmentSelected ? developmentSelected.full_name : "",
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                     dispatch(isSaving(false))
                     return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error adding a devolution from report rework", error.response)
                return Promise.reject();
        })
    }
}

