import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import {
	Button,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	withStyles,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'

/** Resources import section */
import {
	FREIGHT_RETENTION_PERCENTAGE,
	SERVICE_RETENTION_PERCENTAGE,
} from '../../../../store/helpers/AccreditedWorkshopsHelper'

const RetentionPercentages = (props) => {
	const { serviceRetention, freightRetention, classes, onEditButtonClick } = props
	const serviceRetentionPercentage = serviceRetention.value ? serviceRetention.value.toFixed(2) : 0
	const freightRetentionPercentage = freightRetention.value ? freightRetention.value.toFixed(2) : 0
	return (
		<div>
			<div className={classes.alertInfo}>
				<InfoIcon size='small' /> <Translate id='accreditedWorkshops.setting.retentionPercentagesInfo' />
			</div>
			<div className={classes.header}>
				<Typography variant='subtitle1' color='primary' fontWeight={500}>
					<Translate id='accreditedWorkshops.setting.retentionPercentages' />
				</Typography>
				<Button variant='contained' color='primary' onClick={onEditButtonClick}>
					<EditIcon className={classes.leftIcon} />
					<Translate id='common.edit' />
				</Button>
			</div>
			<Divider />
			<div>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography variant='subtitle2' color='primary' fontWeight={500}>
									<Translate id='accreditedWorkshops.setting.retentionType' />
								</Typography>
							</TableCell>
							<TableCell align='center'>
								<Typography variant='body2' color='primary'>
									<Translate id='accreditedWorkshops.setting.percentage' />
								</Typography>
							</TableCell>
							<TableCell align='center'>
								<Typography variant='body2' color='primary'>
									<Translate id='accreditedWorkshops.setting.assignedSuppliers' />
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								<Typography variant='body2'>
									<Translate id='accreditedWorkshops.setting.serviceRetention' />
								</Typography>
							</TableCell>
							<TableCell align='center'>
								<Typography variant='body2'>{serviceRetentionPercentage} %</Typography>
							</TableCell>
							<TableCell align='center'>
								<Typography variant='body2'>{serviceRetention.numberOfSuppliers}</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant='body2'>
									<Translate id='accreditedWorkshops.setting.freightRetention' />
								</Typography>
							</TableCell>
							<TableCell align='center'>
								<Typography variant='body2'>{freightRetentionPercentage} %</Typography>
							</TableCell>
							<TableCell align='center'>
								<Typography variant='body2'>{freightRetention.numberOfSuppliers}</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</div>
		</div>
	)
}

RetentionPercentages.propTypes = {
	serviceRetention: PropTypes.shape({
		code: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		numberOfSuppliers: PropTypes.number.isRequired,
	}).isRequired,
	freightRetention: PropTypes.shape({
		code: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		numberOfSuppliers: PropTypes.number.isRequired,
	}).isRequired,
	onEditButtonClick: PropTypes.func.isRequired,
}

RetentionPercentages.defaultProps = {
	serviceRetention: {
		code: SERVICE_RETENTION_PERCENTAGE,
		value: 0,
		numberOfSuppliers: 0,
	},
	freightRetention: {
		code: FREIGHT_RETENTION_PERCENTAGE,
		value: 0,
		numberOfSuppliers: 0,
	},
	onEditButtonClick: () => console.warn('[onEditButtonClick] not defined'),
}

const styles = (theme) => ({
	header: {
		padding: 8,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	leftIcon: {
		marginRight: theme.spacing.unit,
	},
	alertInfo: {
		margin: '10px 0px',
		padding: '10px 7px 10px 40px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '10px center',
		fontSize: '13px',
		textAlign: 'left',
		width: 'auto',
		color: '#00529B',
		backgroundColor: '#BDE5F8',
	},
})

export default withStyles(styles, { withTheme: true })(RetentionPercentages)
