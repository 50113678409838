/**Import react section */
import React, { useState } from 'react'
import { useSelector, useDispatch, } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
/**Import MaterialUi section */
import {
    Grid, Avatar, List,
    ListItem, ListItemText,
    ListItemSecondaryAction,
    IconButton, Divider,
    TextField, Tooltip,
    Typography
} from '@material-ui/core'
import CategoryIcon from '@material-ui/icons/Widgets';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'

/**Import actionsSection */
import {
    createCategoriesFromBackEnd,
    editCategoriesFromBackEnd,
    deleteCategoryFromBackEnd
} from '../../../../store/categories/CategoriesActions'

const CategoriesLateralPanel = (props) => {
    /**props section */
    const { onCloseButtonClick,
        classes, categories
    } = props

    const dispatch = useDispatch()
    const isSaving = useSelector((state) => state.categories.get('isSaving'))

    const [categoryModel, setCategoryModel] = useState({
        name: ""
    });
    const [mode, setMode] = useState("VIEW");

    const onAddCategory = () => {
        return dispatch(createCategoriesFromBackEnd(categoryModel))
            .then(() => {
                setMode("VIEW")
                setCategoryModel({ name: "" })
            })
            .catch((response) => {

            })
    }

    const onEditCategory = (name, id) => {
        return dispatch(editCategoriesFromBackEnd(name, id))
            .then(() => {
                setMode("VIEW")
            })
            .catch((response) => {

            })
    }

    const onDeleteCategory = (id) => {
        return dispatch(deleteCategoryFromBackEnd(id))
    }

    const onChangeMode = (mode) => {
        setMode(mode)
    }


    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (IsNullOrEmpty(categoryModel.name)) return false
        return true;
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setCategoryModel({
            ...categoryModel,
            [name]: value
        });
    }


    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<span>{<Translate id="biddingsModule.categories.title" />}</span>}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><CategoryIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >
                        <Grid item xs={12} >
                            <Typography color="primary" align="center" variant="subtitle1">{<Translate id="biddingsModule.categories.admin" />}</Typography>
                            {

                                <List>
                                    < ListItem >
                                        <ListItemSecondaryAction>
                                            <>
                                                <Tooltip title={(mode === "VIEW" ? <Translate id="common.edit" /> : <Translate id="common.cancel" />)}>
                                                    <IconButton
                                                        color="primary"
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={() => onChangeMode(mode === "VIEW" ? "EDIT" : "VIEW")}
                                                    >
                                                        {(mode === "VIEW") ? <EditIcon /> : <CancelIcon />}
                                                    </IconButton>

                                                </Tooltip>
                                                <Tooltip title={<Translate id="common.add" />}>
                                                    <IconButton
                                                        color="primary"
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={onAddCategory}
                                                        disabled={!canBeSaved() || isSaving}
                                                    >

                                                        <AddIcon />
                                                    </IconButton>

                                                </Tooltip>
                                            </>
                                        </ListItemSecondaryAction>
                                    </ListItem>

                                    <div className={classes.createTextField}>
                                        <TextField
                                            value={categoryModel ? categoryModel.name : ""}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            name="name"
                                            label={<Translate id="biddingsModule.categories.name" />}
                                            onChange={onPropertyChange}
                                            disabled={isSaving}
                                        />
                                    </div>
                                    <Divider />
                                    {
                                        categories && categories.length !== 0 &&
                                        categories.map(category => {
                                            return (
                                                <>
                                                    < ListItem>
                                                        {(mode === "VIEW") && <ListItemText primary={category.Name} />}
                                                        {
                                                            mode === "EDIT" &&
                                                            <TextField
                                                                defaultValue={category.Name}
                                                                fullWidth
                                                                name="name"
                                                                label={<Translate id="biddingsModule.categories.name" />}
                                                                // onChange={onPropertyChange}
                                                                onBlur={(event) => onEditCategory(event.target.value, category.Id)}
                                                                disabled={isSaving}

                                                            />
                                                        }
                                                        <ListItemSecondaryAction>
                                                            <Tooltip title={<Translate id="common.delete" />}>
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="delete"
                                                                    disabled={isSaving}
                                                                    onClick={() => onDeleteCategory(category.Id)}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>

                                                            </Tooltip>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <Divider />

                                                </>
                                            )
                                        })
                                    }
                                </List>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        paddingLeft: "20px",
        paddingTop: "10px",
        paddingBotton: "20px",
        paddingRight: "20px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    createTextField: {
        paddingTop: "15px",
        PaddingBottom: "15px",
    }

});

CategoriesLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

CategoriesLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(CategoriesLateralPanel))