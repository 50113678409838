import React from 'react';
import PropTypes from 'prop-types';

/** Material UI import section */
import { List } from '@material-ui/core';

/** Custom component import section */
import WorkshopItem from './WorkshopItem';

const Workshops = ({ workshops = [] }) => {
	return (
		<List dense>
			{workshops.map(({ token, sapCode, name, technicians }) => (
				<WorkshopItem key={token} name={name} sapCode={sapCode} technicians={technicians} />
			))}
		</List>
	);
};

Workshops.propTypes = {
	workshops: PropTypes.arrayOf(
		PropTypes.shape({
			token: PropTypes.string.isRequired,
			sapCode: PropTypes.string.isRequired,
			ps9Code: PropTypes.string.isRequired
		})
	).isRequired
};

export default Workshops;
