import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, TextField, Typography, Grid } from '@material-ui/core'

import MaterialsTable from './MaterialsTable'
import { ErrorOutline, InfoOutlined } from '@material-ui/icons'
import { Translate } from 'react-localize-redux'

const CreateManualMaterial = (props) => {
	const {
		classes,
		information,
		setInformation,
		onSearchSAPCode,
		isLoadingCodeFromSAP,
		materialFromSAP,
		transportTenderdetail,
	} = props
	const [material, setMaterial] = useState({
		Description: materialFromSAP ? materialFromSAP.Description : '-',
		Code: '',
		Quantity: 0,
		BaseMeasurementUnit: materialFromSAP ? materialFromSAP.MeasurementUnit : '-',
		GrossWeight: 0,
		WeightMeasurementUnit: '',
		NetWeight: 0,
		TotalWeight: 0,
		ProductCode: '',
		ProductDescription: '',
		TariffFraction: 0,
		SatMeasurementUnit: '',
		WeightMeasurementUnitTotal: '',
		Id: Date.now().toString(),
	})
	const addMaterial = () => {
		material.TariffFraction = material.TariffFraction ? material.TariffFraction : 0
		setInformation((prevArreglo) => [...prevArreglo, material])
		setMaterial({
			Code: '',
			Quantity: 0,
			Description: '',
			Id: Date.now().toString(),
			BaseMeasurementUnit: '',
			GrossWeight: 0,
			WeightMeasurementUnit: '',
			NetWeight: 0,
			TotalWeight: 0,
			ProductCode: '',
			ProductDescription: '',
			TariffFraction: 0,
			SatMeasurementUnit: '',
			WeightMeasurementUnitTotal: '',
		})
	}
	const deleteMaterial = (id) => {
		let arra = information.filter((x) => x.Id !== id)
		setInformation(arra)
	}

	const onChange = (event) => {
		let { name, value } = event.target
		if (event.target.name === 'WeightMeasurementUnit') {
			setMaterial({
				...material,
				[name]: value,
				WeightMeasurementUnitTotal: value,
			})
		} else if (event.target.name === 'GrossWeight') {
			setMaterial({
				...material,
				[name]: value,
				TotalWeight: event.target.value * material.Quantity,
			})
		} else {
			setMaterial({
				...material,
				[name]: value,
			})
		}
	}
	const canBeSaved = () => {
		if (material.Code === '') return false
		if (material.Quantity === '') return false
		if (material.Description === '') return false
		if (material.BaseMeasurementUnit === '') return false
		if (material.GrossWeight === 0) return false
		if (material.WeightMeasurementUnit === '') return false
		if (material.NetWeight === 0) return false
		if (material.TotalWeight === 0) return false
		if (material.ProductCode === '') return false
		if (material.ProductDescription === '') return false
		// if (material.TariffFraction === 0) return false
		if (material.SatMeasurementUnit === '') return false
		if (material.WeightMeasurementUnitTotal === '') return false
		return true
	}
	useEffect(() => {
		let materialWhitInfoFromSAP = { ...material }
		materialWhitInfoFromSAP.Description = materialFromSAP ? materialFromSAP.Description : '-'
		materialWhitInfoFromSAP.BaseMeasurementUnit = materialFromSAP ? materialFromSAP.MeasurementUnit : '-'
		setMaterial(materialWhitInfoFromSAP)
	}, [materialFromSAP])
	return (
		<div>
			<div className={classes.createContainer}>
				<div className={classes.texthelpContent}>
					<ErrorOutline />
					<Typography variant='caption' className={classes.colorText}>
						<Translate id={'carrierTenders.deals.material.add.helpText'} />
					</Typography>
				</div>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<div className={classes.codeText}>
							<TextField
								autoFocus
								id='standard-with-placeholder'
								label={<Translate id={'carrierTenders.deals.material.add.code'} />}
								onChange={onChange}
								name='Code'
								value={material.Code}
								inputProps={{
									style: {
										height: '10px',
									},
								}}
								size='small'
								margin='dense'
								fullWidth
								variant='outlined'
								error={materialFromSAP && materialFromSAP.Description === null}
								helperText={
									materialFromSAP && materialFromSAP.Description === null ? (
										<Translate id={'carrierTenders.deals.material.add.errorCode'} />
									) : null
								}
							/>
							{isLoadingCodeFromSAP ? (
								<CircularProgress />
							) : (
								<Button
									className={classes.btnSearch}
									size='small'
									onClick={() => onSearchSAPCode(material.Code)}
									disabled={isLoadingCodeFromSAP}
								>
									<Translate id={'carrierTenders.deals.material.add.search'} />
								</Button>
							)}
						</div>
					</Grid>

					<Grid item xs={6}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={<Translate id={'carrierTenders.deals.material.add.description'} />}
							className={classes.descriptionText}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							name='Description'
							onChange={onChange}
							value={
								materialFromSAP && materialFromSAP.Description && materialFromSAP.Description.length > 0
									? materialFromSAP.Description
									: material.Description
							}
							disabled={
								materialFromSAP && materialFromSAP.Description && materialFromSAP.Description.length > 0
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={<Translate id={'carrierTenders.deals.material.add.amount'} />}
							onChange={onChange}
							name='Quantity'
							value={material.Quantity}
							className={classes.amountText}
							// inputProps={{
							// 	style: {
							// 		height: '10px',
							// 	},
							// }}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							type='number'
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={'Unidad de medida'}
							onChange={onChange}
							name='BaseMeasurementUnit'
							value={material.BaseMeasurementUnit}
							className={classes.amountText}
							// inputProps={{
							// 	style: {
							// 		height: '10px',
							// 	},
							// }}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							// type='number'
						/>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.texthelpContent}>
							<InfoOutlined />
							<Typography variant='caption' className={classes.colorText}>
								Información para carta porte
								{/* <Translate id={'carrierTenders.deals.material.add.helpText'} /> */}
							</Typography>
						</div>
					</Grid>
					<Grid item xs={3}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={'Peso bruto'}
							onChange={onChange}
							name='GrossWeight'
							value={material.GrossWeight}
							className={classes.amountText}
							// inputProps={{
							// 	style: {
							// 		height: '10px',
							// 	},
							// }}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							type='number'
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={'Unidad de peso'}
							onChange={onChange}
							name='WeightMeasurementUnit'
							value={material.WeightMeasurementUnit}
							className={classes.amountText}
							// inputProps={{
							// 	style: {
							// 		height: '10px',
							// 	},
							// }}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							// type='number'
						/>
					</Grid>

					<Grid item xs={3}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={'Peso neto'}
							onChange={onChange}
							name='NetWeight'
							value={material.NetWeight}
							className={classes.amountText}
							// inputProps={{
							// 	style: {
							// 		height: '10px',
							// 	},
							// }}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							type='number'
						/>
					</Grid>
					{/* <Grid item xs={3}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={'Total Peso bruto'}
							onChange={onChange}
							name='TotalWeight'
							value={material.NetWeight}
							className={classes.TotalWeight}
							// inputProps={{
							// 	style: {
							// 		height: '10px',
							// 	},
							// }}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							type='number'
						/>
					</Grid> */}
					<Grid item xs={3}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={'Clave unidad SAT'}
							onChange={onChange}
							name='SatMeasurementUnit'
							value={material.SatMeasurementUnit}
							className={classes.TotalWeight}
							// inputProps={{
							// 	style: {
							// 		height: '10px',
							// 	},
							// }}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							// type='number'
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={'Clave producto'}
							onChange={onChange}
							name='ProductCode'
							value={material.ProductCode}
							className={classes.TotalWeight}
							// inputProps={{
							// 	style: {
							// 		height: '10px',
							// 	},
							// }}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							// type='number'
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={'Descripción producto'}
							onChange={onChange}
							name='ProductDescription'
							value={material.ProductDescription}
							className={classes.TotalWeight}
							// inputProps={{
							// 	style: {
							// 		height: '10px',
							// 	},
							// }}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							// type='number'
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={'Fracción arancelaria'}
							onChange={onChange}
							name='TariffFraction'
							value={material.TariffFraction}
							className={classes.TotalWeight}
							// inputProps={{
							// 	style: {
							// 		height: '10px',
							// 	},
							// }}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							type='number'
						/>
					</Grid>

					{/* <Grid item xs={3}>
						<TextField
							autoFocus
							id='standard-with-placeholder'
							label={'Unidad de medida total'}
							onChange={onChange}
							name='WeightMeasurementUnitTotal'
							value={material.WeightMeasurementUnitTotal}
							className={classes.TotalWeight}
							// inputProps={{
							// 	style: {
							// 		height: '10px',
							// 	},
							// }}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
							// type='number'
						/>
					</Grid> */}
				</Grid>
				<div className={classes.addContent}>
					<Button className={classes.addIcon} onClick={addMaterial} disabled={!canBeSaved()}>
						<Translate id='carrierTenders.deals.addMaterial' />
					</Button>
				</div>
			</div>

			<div className={classes.tableContainer}>
				<MaterialsTable
					data={information}
					deleteMaterial={deleteMaterial}
					transportTenderdetail={transportTenderdetail}
				/>
			</div>
		</div>
	)
}

export default CreateManualMaterial
