/**Import react section */
import React from 'react';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
/**Import MaterialUi section */
import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
	List,
	ListItemText,
	ListItem,
	ListItemSecondaryAction,
	IconButton,
	Typography,
	LinearProgress
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Clear';
/**Import components section */
/**Import actions section */
import { useExpedientSupplierCustomHookIndex } from '../../customHooks/UseCustomHookIndex';
import { BACKGROUND_COLOR_GRAY_DARK, COLOR_STATUS_DANGER } from '../../../../store/helpers/StatusColorConstants';
import { REMOVE_EXPEDIENT_CLASSIFICATION } from '../../../../store/expedientSupplier/ExpedientSupplierAction';

const DeleteClassificationPanel = (props) => {
	const { onDownloadSuppliersClassificationsReport, isSaving } =
		useExpedientSupplierCustomHookIndex();
	const { classes, supplierClassifications, onConfirmPassword } = props;

	return (
		<div>
			{isSaving && (
				<Grid container>
					<Grid item xs={12}>
						<LinearProgress color="primary" />
					</Grid>
				</Grid>
			)}
			<div className={classes.containerInformation}>
				<Grid container>
					<Grid item xs={12}>
						<List>
							{supplierClassifications &&
								supplierClassifications.map((item) => {
									return (
										<ListItem key={item.token} className={classes.listItem} divider>
											<ListItemText primary={<Typography className={classes.nameEllipse}>{item.Name}</Typography>} />
											<ListItemSecondaryAction>
												<IconButton
													className={classes.iconDisabled}
													disabled={isSaving}
													onClick={() => onDownloadSuppliersClassificationsReport(item.Name)}
												>
													<DownloadIcon color="primary" />
												</IconButton>

												<IconButton disabled={isSaving} onClick={() => onConfirmPassword(item, REMOVE_EXPEDIENT_CLASSIFICATION)}>
													<DeleteIcon className={classes.deleteIcon} />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									);
								})}
						</List>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

const styles = (theme) => ({
	listItem: {
		backgroundColor: BACKGROUND_COLOR_GRAY_DARK,
		marginTop: '2px'
	},
	deleteIcon: {
		color: COLOR_STATUS_DANGER,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			//backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5
		}
	},
	nameEllipse: {
		maxWidth: '340px',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden'
	},

	containerInformation: {
		maxHeight: `calc(100vh - ${105}px)`,
		minHeight: `calc(100vh - ${105}px)`,
		overflowY: 'auto',
		padding: '10px'
	}
});

DeleteClassificationPanel.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired
};

DeleteClassificationPanel.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false
};

export default withRouter(withStyles(styles, { withTheme: true })(DeleteClassificationPanel));
