

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import {Translate} from "react-localize-redux";

/** Material-UI imports section */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/AddTwoTone';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

/** Section componet */
import DataTable from '../../common/DataTable';
import LoadingPage from '../../common/LoadingPage';
import Toaster from '../../common/Toaster';
import PartNumberSelector from '../../common/PartNumberSelector';
import FailureNonConformitySelector from '../../common/FailureNonConformitySelector';

import {getAllInvoicesByPartNumberDevolutionFromSAP, updateDescriptionDevolutionItem, loadDevolutionFromBackEnd,
    updateNonconformityToDevolutionItem, addInvoiceDevolutionItemFromBackend,
    deleteInvoiceItemDevolution, deleteDevolutionItem} from '../../../store/devolutions/DevolutionActions'

/**
 * Component render devolution items
 */
class ItemsInvoices extends Component{

    /**
     * Create an instance devolution items
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            partNumberSelected: null,
            failureSelected: null,
            description:"",
            toasterMessage: null,
            toasterVariant: "",
            invoicesItems: null,
            isDisabled: false,
        }
    }

     /**
     * On seleted part number
     */
    onPartNumberSelected(partNumber){
        this.setState({partNumberSelected: partNumber});
        /// Exist folio get invoices from part number
        if(partNumber){
            this.getInvoiceByPartNumber(partNumber.code);
        }
    }

    /**
     * Function to trigger blur event and save data of input
     * 
     * @param {*} event
     * @memberof DetailsCard
     */
    onKeyDownDescription(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    /**
     * Method to update description
     * @param {*} description 
     */
    onUpdateDescription(description) {
        this.setState({description: description})
        this.props.updateDescriptionDevolutionItem(this.state.partNumberSelected, description)
    }

    /**
     * On seleted failure 
     */
    onFailureSelected(failure){
        this.setState({failureSelected: failure})
        this.props.updateNonconformityToDevolutionItem(this.state.partNumberSelected, failure)
    }
    

    /**
     * Get action to get information memo 
     */
    getInvoiceByPartNumber(partNumberCode){
        this.props.getAllInvoicesByPartNumberDevolutionFromSAP(partNumberCode)
        .then(() =>{
            if(this.props.invoices.length === 0){
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="devolutions.withountInvoices"/>,
                    toasterVariant: "warning",
                })
            }
            else{
                this.setState({
                    showToaster: false,
                    isDisabled: true,
                    invoicesItems: this.props.invoices,
                })
            }
        }).catch(() =>{
            this.setState({
                showToaster:true,
                toasterMessage: <Translate id="common.errorToAdd"/>,
                toasterVariant: "error",
            })
        });
    }

        /**
     * Function to save changes of data
     *
     * @param {*} data
     * @param {*} token
     * @memberof DetailsCard
     */
    updateInvoice(data, item) {
        if(!this.canBeInvoice()){
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="devolutions.invalidToAddItem"/>,
                toasterVariant: "error",
            })
        }
        let quantity_can_be_returned = Number(item.quantity_can_be_returned);
        let quantity_returne = Number(data.value);
        if(quantity_can_be_returned < quantity_returne){
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="devolutions.messageQualityMaxim"/>,
                toasterVariant: "error",
            })
        }
        else{
            var detailUpdate = this.state.invoicesItems.filter(x => x.folio === item.folio);
            if (detailUpdate.length !== 0) {
                detailUpdate[0][data.id] = data.value;
                this.props.addInvoiceDevolutionItem(this.state.partNumberSelected, item)
            }
        }
    }

    /**
     * Deternie if can add invoice
     */
    canBeInvoice(){
        if(this.state.failureSelected === null && this.state.description === "")
            return false;
        else
            return true;
    }

    /**
     * Method to add invoices in item from devolution
     */
    addInvoicesInItem(){
        if(!this.canBeInvoice()){
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="devolutions.invalidToAddItem"/>,
                toasterVariant: "error",
            })
        }
        else{
            this.props.loadDevolution(this.props.devolution.token);
        }
    }

    /**
    * On render cell item custom
    * @param {*} dataSource 
    * @param {*} item 
    */
   onRenderCellItem(dataSource, item) {
        if (dataSource === "quantity_to_return") {
            return (<TextField
                id={dataSource}
                defaultValue={item[dataSource]}
                margin="normal"
                type="number"
                onBlur={(event) => this.updateInvoice(event.target, item)}
                onKeyDown={this.onKeyDown}
            />)
        }
    }

      /**
     * Method to delete devolution item in item from devolution
     */
    onDeleteDevolutionItem(itemToken){
        if(this.props.devolution.items.length === 1){
            this.setState({
                showToaster: true,
                toasterMessage:  <Translate id="devolutions.messageToRemoveItem"/>,
                toasterVariant: "warning",
            })
        }
        else{
          this.props.deleteDevolutionItem(itemToken);
        }
    }
    
    /**
    * On render cell item custom invoices
    * @param {*} dataSource 
    * @param {*} item 
    */
   onRenderCellItemInvoices(dataSource, item) {
        if (dataSource === "quantity_to_return") {
            return (<span>{item.quantity_to_return} {item.measurement_unit}</span>)
        }
        if(dataSource === "token"){
            return(<span>
                 {this.props.devolution.is_draft &&
                     <IconButton edge="end" aria-label="Delete" onClick={() => {this.props.deleteInvoiceItemDevolution(item.devolution_item_token, item.token)}} >
                        <Tooltip title={<Translate id="common.delete"/>}>
                            <DeleteOutlinedIcon color="error" />
                        </Tooltip>
                </IconButton>}
            </span>   
            )
        }
    }

    /**
     * Get annottion rows
     */
    getInvoicesInItemsAdding(){
        const { classes } = this.props;
        const { devolution } = this.props;
        if (devolution) {
            return devolution.items.map((item) => {
                return (
                    <Grid item xs={12} key={item.token}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography component="span" variant="body2" className={classes.inline} color="primary">
                                                {item.full_part_description}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            {<Translate id="devolutions.nonConformity"/>}:  {item.non_conformity} <br/>
                                            { <Translate id="devolutions.descriptionIncumple"/>}: { item.problem_description}
                                        </React.Fragment>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Typography component="span" variant="body2" className={classes.inline} color="primary">
                                        { <Translate id="devolutions.totalToReturn"/>} {": "} {item.total_quantity_to_return}
                                    </Typography>
                                    {this.props.devolution.is_draft &&
                                         <IconButton edge="end" aria-label="Delete" onClick={() => {this.onDeleteDevolutionItem(item.token)}}>
                                            <Tooltip title={<Translate id="common.delete"/>}>
                                                <DeleteOutlinedIcon color="error" />
                                            </Tooltip>
                                    </IconButton>}
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                        <DataTable 
                            data = {item.invoices}
                            configuration = {TableInvoicesInItems}
                            onRenderCellItem={this.onRenderCellItemInvoices}
                        />
                    </Grid>
                )
            })
        }
        return(<Translate id="devolutions.notExistIntems"/>);
    }


     /**
     * Get the card content
     *
     * @memberof Index
     */
    getInvociesContent() {
        if (this.props.isLoadingInvoices === true) {
            return (
                <LoadingPage />
            )
        }
        else if(this.props.invoices !== null){
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <DataTable 
                            data = {this.state.invoicesItems ? this.state.invoicesItems : []}
                            configuration = {TableInvoices}
                            onRenderCellItem={this.onRenderCellItem}
                        />
                    </Grid>
                </Grid>
            )
        }
    }


    /**
     * Render
     */
    render() {
        const { classes} = this.props;
        return(
            <Grid container spacing={24}>
                {this.props.devolution.is_draft &&<Grid item xs={12}>
                 <div className={classes.tableContainer}>
                    <Table style={{ maxWidth: '100%' }}>
                        <TableBody className={classes.rowsSection}>
                            <TableRow>
                                <TableCell align="left" padding="dense">
                                    <div className={classes.textField} >
                                        <PartNumberSelector
                                            onPartNumberSelected = {(partNumber) => {this.onPartNumberSelected(partNumber)}}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell align="left" padding="dense">
                                    <div className={classes.textField} >
                                        <FailureNonConformitySelector
                                            isDisabled={!this.state.isDisabled}
                                            className={classes.textField}
                                            onNonConformitySelected = {(failure) => {this.onFailureSelected(failure)}}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell align="left" padding="dense">
                                    <TextField
                                        id="standard-flexible"
                                        label={<Translate id="devolutions.descriptionIncumple"/>}
                                        multiline
                                        rowsMax="4"
                                        disabled={!this.state.isDisabled}
                                        defaultValue={this.state.description}
                                        className={classes.textField}
                                        onKeyDown={this.onKeyDownDescription}
                                        onBlur={(event) => this.onUpdateDescription(event.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Fab size="small" color="primary" disabled={!this.state.isDisabled}
                                        onClick={() => {this.addInvoicesInItem()}}>
                                        <Tooltip title={<Translate id="common.add"/>}>
                                            <AddIcon />
                                        </Tooltip>
                                    </Fab>
                                </TableCell>  
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                </Grid>}
                <Grid item xs={12}>
                    {this.getInvociesContent()}
                </Grid>
                <Grid item xs={12}>
                    {this.getInvoicesInItemsAdding()}
                </Grid>

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Grid>
        )
    }

}
/**
 * devolution
 */
ItemsInvoices.propTypes = {
    devolution: PropTypes.object.isRequired
}

 /**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        isLoadingInvoices: state.devolutions.get('isLoadingInvoices'),
        invoices: state.devolutions.get('invoices') ? state.devolutions.get('invoices').toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadDevolution: (token) => {
            return dispatch(loadDevolutionFromBackEnd(token));
        },
        getAllInvoicesByPartNumberDevolutionFromSAP:(folio) =>{
            return dispatch(getAllInvoicesByPartNumberDevolutionFromSAP(folio));
        },
        updateDescriptionDevolutionItem:(partNumberSelected, description) =>{
            return dispatch(updateDescriptionDevolutionItem(partNumberSelected, description));
        },
        updateNonconformityToDevolutionItem:(partNumberSelected, failureSelected) =>{
            return dispatch(updateNonconformityToDevolutionItem(partNumberSelected, failureSelected))
        },
        addInvoiceDevolutionItem:(partNumberSelected, invoice) =>{
            return dispatch(addInvoiceDevolutionItemFromBackend(partNumberSelected, invoice))
        },
        deleteInvoiceItemDevolution:(devolutionIitemToken, token) =>{
            return dispatch(deleteInvoiceItemDevolution(devolutionIitemToken, token))
        },
        deleteDevolutionItem:(token) =>{
            return dispatch(deleteDevolutionItem(token))
        }
    }
}

const TableInvoices = {
    columns: [{
        header: <Translate id="devolutions.center"/>,
        dataSource: 'company_center'
    },{
        header: "L",
        dataSource: 'purchase_order_line'
    },{
        header: <Translate id="devolutions.purchaseOrder"/>,
        dataSource: 'purchase_order'
    },{
        header: <Translate id="devolutions.numRea"/>,
        dataSource: 'supplier_reference'
    },{
        header: <Translate id="devolutions.reference"/>,
        dataSource: 'supplier_reference'
    },{
        header: <Translate id="common.partNumber"/>,
        dataSource: 'full_part_description'
    },{
        header: <Translate id="devolutions.maxToReturn"/>,
        dataSource: 'quantity_can_be_returned'
    },{
        header: <Translate id="devolutions.deliveryDate"/>,
        dataSource: 'creation_date'
    },{
        header: <Translate id="devolutions.qualityToReturn"/>,
        dataSource: 'quantity_to_return'
    },{
        header: <Translate id="devolutions.currency"/>,
        dataSource: 'currency'
    }]
}

const TableInvoicesInItems = {
    columns: [{
        header: <Translate id="devolutions.center"/>,
        dataSource: 'company_center'
    },{
        header: "L",
        dataSource: 'purchase_order_line'
    },{
        header: <Translate id="devolutions.purchaseOrder"/>,
        dataSource: 'purchase_order'
    },{
        header: <Translate id="devolutions.numRea"/>,
        dataSource: 'folio'
    },{
        header: <Translate id="devolutions.reference"/>,
        dataSource: 'supplier_reference'
    },{
        header: <Translate id="devolutions.maxToReturn"/>,
        dataSource: 'quantity_can_be_returned'
    },{
        header: <Translate id="devolutions.deliveryDate"/>,
        dataSource: 'creation_date'
    },{
        header: <Translate id="devolutions.qualityToReturn"/>,
        dataSource: 'quantity_to_return'
    },{
        header: <Translate id="devolutions.currency"/>,
        dataSource: 'currency'
    },{
        header: "",
        dataSource: 'token'
    }]
}

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    actionsToolbar: {
        display: "flex"
    },
    logoCompany: {
        maxHeight: 100
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300,
        marginTop: 10,
        marginBottom: 10
    },
    tableContainer: {
        zIndex: '30',
        minWidth: 300,
        height: "calc(100% - 30px)",
    },
    rowsSection: {
        overflowX: 'auto',
    },
    inline: {
        display: 'inline',
      },
    container:{},
    leftIcon:{},
    margin:{},
    logoSupplier:{},
    styleTab:{},
    formControl: { margin: theme.spacing.unit },
    toolbar: {},
    searchInput: {},
    button: {},
    showingUsersCounter: {},
    supplierBadge: {}
});

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ItemsInvoices)));