import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Select from 'react-select'

/** Import component section */
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'

import { SHOW_EDIT_VIEW, COMPANY_PTM, COMPANY_IDENTIFIER_IMBERA_REPARE } from '../../../store/helpers/ResourcesHelper'
import { GetMeasurementUnits } from '../../../store/helpers/SelectOptions'

/**
 * Dialog to AddReferenceDialog
 */
class AddItemDialog extends Component {
	/**
	 *Creates an instance of DialogActions.
	 * @param {*} props
	 * @memberof DialogActions
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * Dialog to update information
	 */
	render() {
		const { classes, selectedCompany } = this.props
		let item = this.props.item
		let measurements = GetMeasurementUnits()
		if (
			selectedCompany &&
			selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE &&
			this.props.item.partNumber === 'NA'
		) {
			item.quote = true
		}
		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.onClose}
				header={
					<Toolbar>
						<Typography variant='h6'>{<Translate id='alternatingProveeduria.addItem' />}</Typography>
					</Toolbar>
				}
				actions={
					<DialogActions
						view={SHOW_EDIT_VIEW}
						classes={classes}
						onCancel={this.props.onClose}
						onClose={this.props.onClose}
						onSaveItem={this.props.onAddItemProveduria}
						isSaving={this.props.isSaving}
						canBeSaved={this.props.canBeSaved}
					/>
				}
			>
				<form>
					{selectedCompany && selectedCompany.get_company_identifier !== COMPANY_IDENTIFIER_IMBERA_REPARE && (
						<FormControlLabel
							className={classes.controlCheck}
							control={
								<Checkbox
									onChange={(event) => {
										this.props.updatePropertyItem('sample', event.target.checked)
									}}
									color='primary'
									value='status'
									checked={item.sample}
								/>
							}
							label={<Translate id='alternatingProveeduria.requieredSample' />}
						/>
					)}
					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE &&
						this.props.item.partNumber !== 'NA' && (
							<FormControlLabel
								className={classes.controlCheck}
								control={
									<Checkbox
										onChange={(event) => {
											this.props.updatePropertyItem('sample', event.target.checked)
										}}
										color='primary'
										value='status'
										checked={item.sample}
									/>
								}
								label={<Translate id='alternatingProveeduria.requieredSample' />}
							/>
						)}
					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && (
						<FormControlLabel
							className={classes.controlCheck}
							control={
								<Checkbox
									onChange={(event) => {
										this.props.updatePropertyItem('rmi', event.target.checked)
									}}
									color='primary'
									value='status'
									checked={item.rmi}
								/>
							}
							label={<Translate id='alternatingProveeduria.requieredRMI' />}
						/>
					)}
					<FormControlLabel
						className={classes.controlCheck}
						control={
							<Checkbox
								onChange={(event) => {
									this.props.updatePropertyItem('quote', event.target.checked)
								}}
								color='primary'
								value='status'
								checked={item.quote}
							/>
						}
						label={<Translate id='alternatingProveeduria.requieredQuote' />}
					/>
					<br />
					{selectedCompany && selectedCompany.get_company_identifier !== COMPANY_IDENTIFIER_IMBERA_REPARE && (
						<TextField
							autoFocus
							className={classes.textField}
							label={<Translate id='alternatingProveeduria.partNumber' />}
							defaultValue={item.partNumber}
							onChange={(event) => {
								this.props.updatePropertyItem('partNumber', event.target.value)
							}}
						/>
					)}
					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE && (
						<TextField
							autoFocus
							className={classes.textField}
							label={<Translate id='alternatingProveeduria.partNumberImbs' />}
							defaultValue={item.partNumber}
							onChange={(event) => {
								this.props.updatePropertyItem('partNumber', event.target.value)
							}}
						/>
					)}
					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE &&
						this.props.item.partNumber === 'NA' && (
							<TextField
								className={classes.textField}
								label={<Translate id='alternatingProveeduria.description' />}
								defaultValue={item.partDescription}
								onChange={(event) => {
									this.props.updatePropertyItem('partDescription', event.target.value)
								}}
							/>
						)}
					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE &&
						this.props.item.partNumber === 'NA' && (
							<TextField
								className={classes.textField}
								label={<Translate id='alternatingProveeduria.revision' />}
								defaultValue={item.revise}
								onChange={(event) => {
									this.props.updatePropertyItem('revise', event.target.value)
								}}
							/>
						)}

					<TextField
						className={classes.textField}
						label={<Translate id='alternatingProveeduria.numberSample' />}
						defaultValue={item.sampleNumber}
						type='number'
						onChange={(event) => {
							this.props.updatePropertyItem('sampleNumber', event.target.value)
						}}
					/>

					<TextField
						className={classes.textField}
						label={<Translate id='alternatingProveeduria.volumen' />}
						defaultValue={item.volumen}
						type='number'
						onChange={(event) => {
							this.props.updatePropertyItem('volumen', event.target.value)
						}}
					/>

					<div style={{ zIndex: 5000, display: 'inline-block', width: '92%', paddingLeft: '38px' }}>
						<Translate id='alternatingProveeduria.volumenMasurement' />
						<Select
							value={measurements[item.volumnetMeaurement]}
							className={classes.documentTypeSelect}
							options={measurements}
							onChange={this.props.onChangeMeasurementUnit}
							styles={{
								// menu: (base) => ({ ...base, zIndex: 10 })
								menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
								menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
								menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
							}}
							menuPortalTarget={document.parentNode}
							menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
							menuPlacement={'auto'}
						/>
					</div>

					<TextField
						margin='normal'
						variant='outlined'
						multiline
						rowsMax='5'
						className={classes.textField}
						label={<Translate id='alternatingProveeduria.observations' />}
						defaultValue={item.observations}
						onChange={(event) => {
							this.props.updatePropertyItem('observations', event.target.value)
						}}
					/>
				</form>
			</Dialog>
		)
	}
}

const dialogStyles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	toolBar: {
		backgroundColor: 'red',
	},
	button: {
		margin: theme.spacing.unit,
	},
	errorMessage: {
		color: theme.palette.common.white,
	},
	textField: {
		marginLeft: 40,
		marginRight: theme.spacing.unit,
		width: 500,
		marginBottom: 10,
	},
	controlCheck: {
		marginLeft: 30,
	},
	searchInput: {},
	actionsToolbar: {},
	showingUsersCounter: {},
	supplierBadge: {},
	fabBotton: {},
	formControl: {},
	toolbar: {},
})

export default withStyles(dialogStyles, { withTheme: true })(AddItemDialog)
