import React, { Component } from 'react'
import { Translate } from 'react-localize-redux'
import Select from 'react-select'
import moment from 'moment'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

/** Component imports section */
import DatePicker from '../../common/DateTimePicker/DatePicker'
import SuppliersSelector from '../../common/SupplierSelector'

/** Actions and helpers imports section */
import { GetTypeProveduria } from '../../../store/helpers/SelectOptions'
/**
 * Information MemoCard
 *
 * @class InformationMemoCard
 * @extends {Component}
 */
class InformationMemoCard extends Component {
	render() {
		const { classes, provedure, selectedCompany } = this.props
		let options = GetTypeProveduria(selectedCompany.company_code)
		return (
			<Grid container spacing={24}>
				<Grid item xs={3}>
					<Typography variant='subtitle2'>
						<Translate id='alternatingProveeduria.deliveryDateSample' />
					</Typography>
					<DatePicker
						onChangeRange={this.props.onChangeRangeDeliveryDate}
						minDate={moment()}
						startDate={
							provedure.deadline_delivery
								? moment(provedure.deadline_delivery, 'DD/MM/YYYY')
								: this.props.dataDelivery
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<Typography variant='subtitle2'>
						<Translate id='alternatingProveeduria.typeProvedure' />
					</Typography>
					<Select
						value={options[provedure.alternating_proveeduria_type]}
						className={classes.documentTypeSelect}
						options={options}
						onChange={this.props.onChangeAlternativeType}
						style={{
							menu: (base) => ({ ...base, zIndex: 62 }),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant='subtitle2'>
						<Translate id='alternatingProveeduria.supplier' />
					</Typography>
					{!provedure.supplier_name && (
						<SuppliersSelector
							onSupplierSelected={(supplier) => {
								this.props.onSupplierSelected(supplier)
							}}
						/>
					)}
					{provedure.supplier_name && provedure.supplier_name}
				</Grid>
			</Grid>
		)
	}
}

export default InformationMemoCard
