import { QualityAuditStatus } from './AppConstants'
import {
    COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER,
    COLOR_STATUS_WARNING, COLOR_STATUS_INFO
} from '../helpers/StatusColorConstants'
import { canUserValideModuleQuality } from './RolesHelper'

export const backgroundColorTableStatus = (status) => {
    switch (Number(status)) {
        case QualityAuditStatus.DRAFT:
            return "secondary"
        case QualityAuditStatus.SEND_SUPPLIER:
        case QualityAuditStatus.RESEND_TO_SUPPLIER:
        case QualityAuditStatus.REQUEST_EVIDENCE:
            return "warning"
        case QualityAuditStatus.ANSWERED_SUPPLIER:
        case QualityAuditStatus.EVIDENCE_SENT:
            return "primary"
        case QualityAuditStatus.ACCEPTED:
        case QualityAuditStatus.CLOSED:
            return "success"
        case QualityAuditStatus.CANCELLED:
            return "danger"
        default:
            return ""
    }
};

export const getColorStatusAudit = (status) => {
    switch (Number(status)) {
        case QualityAuditStatus.SEND_SUPPLIER:
        case QualityAuditStatus.RESEND_TO_SUPPLIER:
        case QualityAuditStatus.REQUEST_EVIDENCE:
            return COLOR_STATUS_WARNING
        case QualityAuditStatus.ANSWERED_SUPPLIER:
        case QualityAuditStatus.EVIDENCE_SENT:
            return COLOR_STATUS_INFO
        case QualityAuditStatus.ACCEPTED:
        case QualityAuditStatus.CLOSED:
            return COLOR_STATUS_SUCCESS
        case QualityAuditStatus.CANCELLED:
            return COLOR_STATUS_DANGER
        default:
            return null
    }
};

export function canBeResponsible(audit, userRolesByCompany, userProfile) {
    if (userRolesByCompany === undefined)
        return false
    if (userProfile === null || userProfile === undefined)
        return false
    if (userRolesByCompany.length === 0)
        return false
    if (audit === null || audit === undefined)
        return false
    return (canUserValideModuleQuality(userRolesByCompany))
}