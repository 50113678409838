import { fromJS,List } from 'immutable'
import * as certificationsActions from './certificationsActions'

/**
 * contraActions initial state
 */
export const certificationsInitialState = fromJS({
	isLoading: false,
	isDownloadLoader:false,
	identifier: '',
	certificationsPaginated: {
		items: [],
		pageNumber: 0,
		pageSize: 20,
		total: 0,
		sort: {
			by: 'CertificationName',
			direction: 'desc',
		},
	},
})
export default function companiesReducer(state = certificationsInitialState, action) {
	switch (action.type) {
		case certificationsActions.IS_SAVING: {
			return state.merge({
				isLoading: action.isSaving,
				identifier: action.identifier,
			})
		}
		case certificationsActions.IS_LOADING: {
			return state.merge({
				[action.nameIsloading]: action.valueIsloading,
			})
		}
		case certificationsActions.SET_CERTIFICATIONS: {
			return state.merge({
				certificationsPaginated: fromJS(action.cert),
				isLoading: false,
			})
		}
		case certificationsActions.SET_CERTIFICATION: {
			const certification = fromJS(action.cert);
			let certificationsUpdate = state.getIn(['certificationsPaginated', 'items'], List());
			certificationsUpdate = certificationsUpdate.push(certification);
			let certificationPaginated = state.get('certificationsPaginated').set('items', certificationsUpdate);
			return state.merge({
				isLoading: false,
				certificationsPaginated: certificationPaginated,
			});
		}
		
		default:
			return state
	}
}
