import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

/** Material UI import section */
import {
	Typography,
	List,
	ListItemText,
	ListItem,
	ListItemSecondaryAction,
	Tooltip,
	withStyles,
	Fab
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddTwoTone';

/** Custom component import section */
import AddDestinationLateralPanel from './AddDestinationLateralPanel';

/** Resources import section */
import { COLOR_CART_PORT } from '../../../store/helpers/StatusColorConstants';

const DestinationsHeader = (props) => {
	const {
		title,
		classes,
		canBeAdd,
        consignmentNote
	} = props;

	/** Defienes local state */
	const [openCreateDestinationDialogOperation, setCreateDestinationDialogOperation] = useState(false);

	const onOpenCreateDestinationPanel = () => {
		setCreateDestinationDialogOperation(!openCreateDestinationDialogOperation);
	};
	return (
		<>
			<List>
				<ListItem divider style={{ backgroundColor: COLOR_CART_PORT }}>
					<ListItemText
						secondary={
							<Typography component="span" variant="body2" color="textPrimary">
								<strong>{title}</strong>
							</Typography>
						}
					/>
					<ListItemSecondaryAction>
						{canBeAdd &&
							<Tooltip title={<Translate id="consignmentNote.createDestination.addDestination" />}>
								<Fab
									className={classes.marginIcon}
									size="small"
									color="primary"
									onClick={() => onOpenCreateDestinationPanel()}
								>
									<AddIcon />
								</Fab>
							</Tooltip>
						}
					</ListItemSecondaryAction>
				</ListItem>
			</List>

			{openCreateDestinationDialogOperation && (
				<AddDestinationLateralPanel
					open={openCreateDestinationDialogOperation}
					onCloseButtonClick={onOpenCreateDestinationPanel}
                    consignmentNote={consignmentNote}
				/>
			)}
		</>
	);
};

DestinationsHeader.propTypes = {
	title: PropTypes.node,
};

const styles = (theme) => ({
	attachmentsContainer: {
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: 4,
		paddingLeft: theme.spacing.unit
	},
	optionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: 80
	},
	marginIcon: {
		marginLeft: '5px'
	},
});

export default withStyles(styles, { withTheme: true })(DestinationsHeader);
