
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import DevolutionIcon from '@material-ui/icons/ReplyAllOutlined';

/** section import components */
import DataTable from '../../common/DataTable';
import { GetAreas } from '../../../store/helpers/SelectOptions';

/**
 * Component ot render Information partial devolution
 */
export default class InformationPartial extends Component {

    /**
     * Create an instance devolution
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
 * Onrender items deriver workflows
 */
    onRenderItemDeriverdWorkFlows(dataSource, item) {
        if (dataSource === "workflow_type") {
            if (item.workflow_type === 6) {
                return (<Typography color="primary" gutterBottom><DevolutionIcon /> {item.folio}</Typography>)
            }
        }
    }

    /**
     * Render
     */
    render() {
        let optionsAreas = GetAreas();
        const { classes} = this.props;
        return (
            <Paper>
                <List>
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="reworks.sorter" />
                                    </Typography>
                                    {": "}{this.props.rework.full_sorter}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="common.partNumber" />
                                    </Typography>
                                    {": "}{this.props.rework.full_part_description}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="reworks.dateRework" />
                                    </Typography>
                                    {": "}{this.props.rework.delivery_date}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="reworks.supplierToRework" />
                                    </Typography>
                                    {": "}{this.props.rework.full_supplier}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="reworks.qualityToReworker" />
                                    </Typography>
                                    {": "}<Badge color="secondary" className={classes.margin} badgeContent={this.props.rework.quantity_of_material} max={9999}></Badge>
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                   {this.props.rework.area && optionsAreas[this.props.rework.area].label && <ListItem>
                        <ListItemText
                         secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="common.area"/>
                                </Typography>
                                {": "}{optionsAreas[this.props.rework.area].label} - <Translate id="common.ceco"/>: {this.props.rework.cost_center}
                            </React.Fragment>
                        }/>
                    </ListItem>}
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="reworks.descriptionRework" />
                                    </Typography>
                                    {": "}{this.props.rework.activity_description_to_be_performed}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                </List>

                {/* List from derived_workflows  */}
                {this.props.rework.derived_workflows.length > 0 && <span><br />
                    <DataTable
                        title={<Typography gutterBottom variant="body2"><strong>{<Translate id="qualityfailurereport.trackingReport" />}</strong>  </Typography>}
                        data={this.props.rework.derived_workflows}
                        configuration={TableDerivedWorkflows}
                        onRenderCellItem={this.onRenderItemDeriverdWorkFlows}
                        onRowClick={(item) => {
                            if (item.workflow_type === 6) {
                                this.props.history.push(`/devolutions/${item.token}`)
                            }
                        }}
                    />
                </span>
                }

            </Paper>
        );
    }
}

const TableDerivedWorkflows = {
    columns: [{
        header: <Translate id="common.folio" />,
        dataSource: 'workflow_type'
    }, {
        header: <Translate id="common.creationDate" />,
        dataSource: 'creation_date'
    }, {
        header: <Translate id="common.createBy" />,
        dataSource: 'create_by'
    }, {
        header: <Translate id="common.supplier" />,
        dataSource: 'full_supplier'
    }]
}

/**
 * rework
 */
InformationPartial.propTypes = {
    rework: PropTypes.object.isRequired
}