import { fromJS } from 'immutable';

import * as failureReportAction from './QualityFailureReportAction';

/**
 * FailureReportReducer initial state
 */
export const failureReportInitialState = fromJS({
    isLoadingFailureReports: true,
    qualityFailureReports: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    failureReportCount: null,
    isSaving: false,
    isLoading: false,
    qualityFailureReport: null,
    isAllReports: false,
})

export default function failureReportReducer(state = failureReportInitialState, action) {
    switch (action.type) {
        case failureReportAction.START_LOADING_FAILURE_REPORT_QUALITY: {
            return state.merge({
                isLoadingFailureReports: action.isLoading
            })
        }
        case failureReportAction.SET_FAILURE_REPORTS_QUALITY_LIST: {
            let failureReportsLists = fromJS(action.failureReports);
            return state.merge({
                qualityFailureReports: failureReportsLists,
                isLoadingFailureReports: false,
                isLoading: false,
                isSaving: false,
            })
        }
        case failureReportAction.CHANGE_FAILURE_REPORTS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case failureReportAction.CHANGE_FAILURE_REPORTS_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case failureReportAction.CHANGE_FAILURE_REPORTS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case failureReportAction.IS_LOADING_ALL_FAILURE_REPORTS:{
            return state.merge({
                isAllReports: action.isAllReports
            })
        }
        case failureReportAction.SET_FAILURE_REPORTS_COUNT: {
            return state.merge({
                failureReportCount: action.count
            })
        }
        case failureReportAction.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case failureReportAction.IS_LOADING: {
            return state.merge({
                isLoading: action.isLoading
            })
        }
        case failureReportAction.SET_FAILURE_REPORT_QUALITY: {
            return state.merge({
                isLoading: false,
                isSaving: false,
                qualityFailureReport: fromJS(action.failureReport)
            })
        }
        case failureReportAction.ADD_EMERGENT_ACTION_TO_LIST: {
            let emergentAction = fromJS(action.emergentAction)
            return state.updateIn(['qualityFailureReport', "emergent_actions"], (actions) => actions.push(emergentAction))
                .set('isSaving', false);
        }
        case failureReportAction.REMOVE_EMERGENT_ACTION_TO_LIST: {
            return state.updateIn(['qualityFailureReport', 'emergent_actions'], (allActions) => {
                let index = allActions.findIndex((item) => {
                    return item.get('token') === action.actionToken
                });
                return allActions.delete(index).set('isSaving', false);
            })
        }
        case failureReportAction.ADD_CORRECTIVE_ACTION_TO_LIST: {
            let correctiveAction = fromJS(action.correctiveAction)
            return state.updateIn(['qualityFailureReport', "corrective_actions"], (actions) => actions.push(correctiveAction))
                .set('isSaving', false);
        }
        case failureReportAction.UPDATE_CORRECTIVE_ACTION_TO_LIST: {
            let correctiveAction = (action.correctiveAction);
            return state.updateIn(['qualityFailureReport', "corrective_actions"], (actions) => {
                let index = actions.findIndex((item) => {
                    return item.get('token') === correctiveAction.token
                });
                return actions.setIn([index], fromJS(action.correctiveAction));
            }
            ).set('isSaving', false);
        }
        case failureReportAction.ADD_FILE_QUALITY_FAILURE: {
            let oldList = state.getIn(['qualityFailureReport', 'attachments']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['qualityFailureReport', 'attachments'], fromJS(newList));
        }
        case failureReportAction.REMOVE_FILE_QUALITY_FAILURE: {
            let oldList = state.getIn(['qualityFailureReport', 'attachments']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['qualityFailureReport', 'attachments'], fromJS(newList));
        }
        default:
            return state;
    }
}