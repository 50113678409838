/** React imports section */
import React, { useEffect, useState } from 'react'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import { withRouter, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { Translate } from 'react-localize-redux'
/** Material-UI imports section */
import { Card, CardContent } from '@material-ui/core'
/** Component imports section */
import PackageCraetePanel from './components/PackageCraetePanel'
import PackageHeader from './components/PackageHeader'
import Toaster from '../../common/Toaster'
import PackageTable from './components/PackageTable'
/** Selector imports section */
import * as selector from '../../../store/specifications/specifications.Selector'
/** Action imports section */
import {
	createNewPackagingSpecificationDocument,
	loadPackageSpecifications,
} from '../../../store/specifications/specifications.Action'

const PackageSpecification = () => {
	let history = useHistory()
	let location = useLocation()
	const dispatch = useDispatch()

	const queryParams = queryString.parse(location.search)

	const [openPanel, setOpenPanel] = useState(false)
	const [documentName, setDocumentName] = useState('')
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	const isLoading = useSelector((state) => selector.getLoadingSelector(state))
	const page = useSelector((state) => selector.getPackagesPageSelector(state))
	const pageSize = useSelector((state) => selector.getPackagesPageSizeSelector(state))
	const packageList = useSelector((state) => selector.getPackagesSelector(state))
	const totalItems = useSelector((state) => selector.getPackagesTotalItemsSelector(state))
	/** Get user Information*/
	const userProfile = useSelector((state) => state.oidc.user)
	/** Get the information if you are an internal user*/
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')
	/**On change search value */
	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onPropertyChange = (event) => {
		let { value } = event.target
		setDocumentName(value)
	}

	const onCreateDocument = () => {
		let user = {
			userName: userProfile.profile.preferred_username,
			email: userProfile.profile.email,
			name: userProfile.profile.name,
			supplierCode: userProfile.profile.company_code,
		}
		dispatch(createNewPackagingSpecificationDocument(documentName, user))
			.then((response) => {
				setOpenPanel(false)
				dispatch(loadPackageSpecifications('', 0, 20, userProfile.profile.company_code))
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: <Translate id='specificationsSection.package.errorToCreate' />,
					toasterVariant: 'error',
				})
			})
	}

	useEffect(() => {
		let queryValue = queryParams.query ? queryParams.query : ''
		let pageValue = parseInt(queryParams.pageNumber > 0 ? queryParams.pageNumber : 0)
		let newPageSize = parseInt(queryParams.rowsPerPage ? queryParams.rowsPerPage : 20)
		if (queryValue) pageValue = 0
		if (newPageSize !== pageSize) {
			pageValue = 0
			queryParams.pageNumber = 0
			queryParams.rowsPerPage = newPageSize
			history.push({ search: queryString.stringify(queryParams) })
		}
		dispatch(
			loadPackageSpecifications(
				queryValue,
				pageValue,
				newPageSize,
				isInternalUser() ? '' : userProfile.profile.company_code
			)
		)
	}, [dispatch, location.search])

	return (
		<>
			<Card>
				<CardContent>
					<PackageHeader
						// isInternallUser={isInternalUser()}
						onChangeSearchValue={onChangeSearchValue}
						queryParams={queryParams}
						// setOpenPanel={setOpenPanel}
					/>
					<PackageTable
						isLoading={isLoading}
						data={packageList}
						totalItems={totalItems}
						pageNumber={page > 0 ? page - 1 : 0}
						rowsPerPage={pageSize}
					/>
				</CardContent>
			</Card>
			{openPanel && (
				<PackageCraetePanel
					onClose={() => setOpenPanel(false)}
					isSaving={isLoading}
					onSave={onCreateDocument}
					documentName={documentName}
					onPropertyChange={onPropertyChange}
				/>
			)}
			<Toaster
				message={toasterMessageConfig.toasterMessage}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</>
	)
}

export default withauthenticatedLayout(withRouter(PackageSpecification))
