/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserParameters,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import { supplierData, internalCompanyData } from '../helpers/BasePdfData'
import { underScoreToTitleCase } from '../helpers/StringHelper'

export const IS_LOADING_DELIVERY_ORDERS = 'IS_LOADING_DELIVERY_ORDERS'
export const SET_DELIVERY_ORDERS_LIST = 'SET_DELIVERY_ORDERS_LIST'
export const IS_LOADING_DELIVERY_ORDER = 'IS_LOADING_DELIVERY_ORDER'
export const IS_SAVING_DELIVERY_ORDER = 'IS_SAVING_DELIVERY_ORDER'
export const SET_DELIVERY_ORDER = 'SET_DELIVERY_ORDER'
export const CHANGE_DELIVERY_ORDERS_SORT_CRITERIA = 'CHANGE_DELIVERY_ORDERS_SORT_CRITERIA'
export const CHANGE_DELIVERY_ORDERS_QUERY = 'CHANGE_DELIVERY_ORDERS_QUERY'
export const CHANGE_DELIVERY_ORDERS_PAGINATION = 'CHANGE_DELIVERY_ORDERS_PAGINATION'
export const SET_DELIVERY_ORDERS_COUNT = 'SET_DELIVERY_ORDERS_COUNT'
export const ADD_DELIVERY_ORDER = 'ADD_DELIVERY_ORDER'
export const SET_PART_NUMBER_CODES = 'SET_PART_NUMBER_CODES'
export const IS_LOADING_PART_NUMBER_CODES = 'IS_LOADING_PART_NUMBER_CODES'
export const IS_LOADING_SELECTOR_VALUES = 'IS_LOADING_SELECTOR_VALUES'
export const SET_SELECTOR_VALUES = 'SET_SELECTOR_VALUES'
export const ADD_MATERIAL = 'ADD_MATERIAL'
export const REMOVE_MATERIAL = 'REMOVE_MATERIAL'
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL'
export const IS_SAVING_MATERIAL = 'IS_SAVING_MATERIAL'
export const ADD_FILE_INDRAFT_DELIVERY_ORDER = 'ADD_FILE_INDRAFT_DELIVERY_ORDER'
export const REMOVE_INDRAFT_FILE_DELIVERY_ORDER = 'REMOVE_INDRAFT_FILE_DELIVERY_ORDER'
export const ADD_FILE_DELIVERY_ORDER = 'ADD_FILE_DELIVERY_ORDER'
export const REMOVE_FILE_DELIVERY_ORDER = 'REMOVE_FILE_DELIVERY_ORDER'
export const UPDATE_DELIVERY_IN_DRAFT = 'UPDATE_DELIVERY_IN_DRAFT'
export const ADD_INDRAFT_CERTIFICATE_DELIVERY_ORDER = 'ADD_INDRAFT_CERTIFICATE_DELIVERY_ORDER'
export const UPDATE_INDRAFT_CERTIFICATE_DELIVERY_ORDER = 'UPDATE_INDRAFT_CERTIFICATE_DELIVERY_ORDER'
export const REMOVE_INDRAFT_CERTIFICATE_DELIVERY_ORDER = 'REMOVE_INDRAFT_CERTIFICATE_DELIVERY_ORDER'
export const IS_DOWNLOADING_REPORT_ORDERS = 'IS_DOWNLOADING_REPORT_ORDERS'
export const IS_LOADING_CERTIFICATED = 'IS_LOADING_CERTIFICATED'
export const UPDATE_INVIEW_CERTIFICATE_DELIVERY_ORDER = 'UPDATE_INVIEW_CERTIFICATE_DELIVERY_ORDER'
export const UPDATE_DELIVERY_ARRIVE_TIME = 'UPDATE_DELIVERY_ARRIVE_TIME'

// Full calendar events
export const IS_LOADING_DELIVERY_ORDERS_EVENTS = 'IS_LOADING_DELIVERY_ORDERS_EVENTS'
export const SET_DELIVERY_ORDERS_EVENTS = 'SET_DELIVERY_ORDERS_EVENTS'
/**
 * Set flag to determine if is loading deliveryOrders list
 *
 * @param {*} isLoadingDeliveryOrders
 * @returns
 */
function isLoadingDeliveryOrders(isLoadingDeliveryOrders) {
	return {
		type: IS_LOADING_DELIVERY_ORDERS,
		isLoadingDeliveryOrders,
	}
}

/**
 * Set deliveryOrders list in store
 *
 * @param {*} deliveryOrders
 * @returns
 */
function setDeliveryOrdersList(deliveryOrders) {
	return {
		type: SET_DELIVERY_ORDERS_LIST,
		deliveryOrders,
	}
}

/**
 * Set deliveryOrders list count in store
 *
 * @param {*} count
 * @returns
 */
function setDeliveryOrdersCount(count) {
	return {
		type: SET_DELIVERY_ORDERS_COUNT,
		count,
	}
}

/**
 * Set flag to determine if is loading specific deliveryOrder data
 *
 * @param {*} isLoadingDeliveryOrder
 * @returns
 */
function isLoadingDeliveryOrder(isLoadingDeliveryOrder, deliveryOrderType = 'deliveryOrder') {
	return {
		type: IS_LOADING_DELIVERY_ORDER,
		isLoadingDeliveryOrder,
		deliveryOrderType,
	}
}

/**
 * Set flag to determine if is saving specific deliveryOrder data
 *
 * @param {*} isSavingDeliveryOrder
 * @returns
 */
function isSavingDeliveryOrder(isSavingDeliveryOrder) {
	return {
		type: IS_SAVING_DELIVERY_ORDER,
		isSavingDeliveryOrder,
	}
}

/**
 * Update order in draft
 * @param {*} carbonFootprint
 */
function updateDeliveryInDraft(carbonFootprint) {
	return {
		type: UPDATE_DELIVERY_IN_DRAFT,
		carbonFootprint,
	}
}

/**
 * Set deliveryOrder data in store
 *
 * @param {*} deliveryOrder
 * @returns
 */
function setDeliveryOrderData(deliveryOrder, deliveryOrderType = 'deliveryOrder') {
	return {
		type: SET_DELIVERY_ORDER,
		deliveryOrder,
		deliveryOrderType,
	}
}

/**
 * Set flag to determine if is loading PartNumberCodes data
 *
 * @param {*} isLoadingPartNumberCodes
 * @returns
 */
function isLoadingPartNumberCodes(isLoadingPartNumberCodes) {
	return {
		type: IS_LOADING_PART_NUMBER_CODES,
		isLoadingPartNumberCodes,
	}
}

/**
 * Set PartNumerCodes data in store
 *
 * @param {*} deliveryOrder
 * @returns
 */
function setPartNumberCodes(partNumberCodes) {
	return {
		type: SET_PART_NUMBER_CODES,
		partNumberCodes,
	}
}

/**
 * Set flag to determine if is loading PartNumberCodes data
 *
 * @param {*} isLoadingPartNumberCodes
 * @returns
 */
function isLoadingSelectorValues(isLoadingSelectorValues, selectorName) {
	return {
		type: IS_LOADING_SELECTOR_VALUES,
		isLoadingSelectorValues,
		selectorName,
	}
}

/**
 * Set PartNumerCodes data in store
 *
 * @param {*} deliveryOrder
 * @returns
 */
function setSelectorValues(selectorValues, selectorName, propertyName) {
	return {
		type: SET_SELECTOR_VALUES,
		selectorValues,
		selectorName,
		propertyName,
	}
}

/**
 * Set flag to determine if is saving material in deliveryOrder
 *
 * @param {*} isSavingDeliveryOrder
 * @returns
 */
function isSavingMaterial(isSavingMaterial) {
	return {
		type: IS_SAVING_MATERIAL,
		isSavingMaterial,
	}
}

function updateArrivalTime(arrivalTime) {
	return {
		type: UPDATE_DELIVERY_ARRIVE_TIME,
		arrivalTime
	}
}

/**
 *Add material to list in delivery order
 *
 * @param {*} material
 * @returns
 */
function addMaterialToList(material) {
	return {
		type: ADD_MATERIAL,
		material,
	}
}

/**
 * Remove material from list in delivery order
 *
 * @param {*} materialItemtoken
 * @returns
 */
function removeMaterialFromList(itemToken) {
	return {
		type: REMOVE_MATERIAL,
		itemToken,
	}
}

/**
 * Update material item
 *
 * @param {*} detail
 * @returns
 */
function updateMaterialFromList(material, deliveryOrderType = 'deliveryOrder') {
	return {
		type: UPDATE_MATERIAL,
		deliveryOrderType,
		material,
	}
}

/**
 * Add file to state delivery order
 *
 * @param {*} files
 * @returns
 */
function addFile(files) {
	return {
		type: ADD_FILE_DELIVERY_ORDER,
		files,
	}
}

/**
 * Remove file of state delivery order
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName) {
	return {
		type: REMOVE_FILE_DELIVERY_ORDER,
		fileName,
	}
}

/**
 * Add file to state delivery order
 *
 * @param {*} files
 * @returns
 */
function addFileInDraft(files) {
	return {
		type: ADD_FILE_INDRAFT_DELIVERY_ORDER,
		files,
	}
}

/**
 * Remove file of state delivery order
 *
 * @param {*} fileName
 * @returns
 */
function removeFileInDraft(fileName) {
	return {
		type: REMOVE_INDRAFT_FILE_DELIVERY_ORDER,
		fileName,
	}
}

function addCertificateDocument(certificate) {
	return {
		type: ADD_INDRAFT_CERTIFICATE_DELIVERY_ORDER,
		certificate,
	}
}

function updateCertificateDocument(certificate) {
	return {
		type: UPDATE_INDRAFT_CERTIFICATE_DELIVERY_ORDER,
		certificate,
	}
}

function removeCertifaceDocument(certificateToken) {
	return {
		type: REMOVE_INDRAFT_CERTIFICATE_DELIVERY_ORDER,
		certificateToken,
	}
}

function updateCertificateInView(certificate) {
	return {
		type: UPDATE_INVIEW_CERTIFICATE_DELIVERY_ORDER,
		certificate,
	}
}

/**
 * Set flag to determine if is loading Delivery orders events
 *
 * @param {*} isLoadingDeliveryOrdersEvents
 * @returns
 */
function isLoadingDeliveryOrdersEvents(isLoadingDeliveryOrdersEvents) {
	return {
		type: IS_LOADING_DELIVERY_ORDERS_EVENTS,
		isLoadingDeliveryOrdersEvents,
	}
}

/**
 * Set deliveryOrders events list in store
 *
 * @param {*} deliveryOrders
 * @returns
 */
function setDeliveryOrdersEvents(deliveryOrdersEvents) {
	return {
		type: SET_DELIVERY_ORDERS_EVENTS,
		deliveryOrdersEvents,
	}
}

/**
 * Get deliveryOrders list from backend
 *
 * @export
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @returns
 */
export function loadDeliveryOrdersFromBackEnd(
	query,
	orderBy,
	sortAscending,
	page,
	rowsPerPage,
	additionalFilters = null
) {
	return function (dispatch, getState) {
		dispatch(isLoadingDeliveryOrders(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let supplierToken = undefined
		if (user.profile && user.profile.company_code) {
			supplierToken = user.profile.company_token
		}
		let selectedCompany = getState().profile.get('selectedCompany')
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorders/filters`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
			StartPrintDate:
				additionalFilters && additionalFilters.start_print_date
					? additionalFilters.start_print_date.format('DD/MM/YYYY')
					: null,
			EndPrintDate:
				additionalFilters && additionalFilters.end_print_date
					? additionalFilters.end_print_date.format('DD/MM/YYYY')
					: null,
			StartEstimatedArrivalDate:
				additionalFilters && additionalFilters.start_estimated_arrival_date
					? additionalFilters.start_estimated_arrival_date.format('DD/MM/YYYY')
					: null,
			EndEstimatedArrivalDate:
				additionalFilters && additionalFilters.end_estimated_arrival_date
					? additionalFilters.end_estimated_arrival_date.format('DD/MM/YYYY')
					: null,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDeliveryOrdersList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingDeliveryOrders(false))
				console.error('Error getting the delivery orders by company list', error.response)
			})
	}
}

/**
 * Get DeliveryOrders list count
 *
 * @export
 * @returns
 */
export function getDeliveryOrdersCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().deliveryOrders.get('deliveryOrdersCount')
		if (actualCounter) {
			return
		}
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		// let supplierCode = undefined;
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierToken = undefined
		if (user.profile && user.profile.company_token) {
			supplierToken = user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorders/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDeliveryOrdersCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the delivery order's count", error.response)
			})
	}
}

/**
 * Get an specific DeliveryOrder data from backend
 *
 * @export
 * @param {*} token
 * @returns
 */
export function getActiveDeliveryOrderFromBackEnd() {
	return function (dispatch, getState) {
		dispatch(isLoadingDeliveryOrder(true, 'deliveryOrderDraft'))

		let user = getState().oidc.user
		let supplierInformation = getState().suppliers.get('supplier')
		let partnershipInformation = getState().suppliers.get('partnership').toJS()
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/getactive`

		let supplierCode = undefined
		let supplierToken = undefined
		let supplierName = undefined
		if (supplierInformation && supplierInformation.get('code')) {
			supplierCode = supplierInformation.get('code')
			supplierToken = supplierInformation.get('token')
			supplierName = supplierInformation.get('name')
		}
		let dataObject = {
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreatedBy: user.profile.name,
			CreatorEmail: typeof user.profile.email === 'string' ? user.profile.email : user.profile.email[0],
			SupplierToken: supplierToken,
			SupplierName: supplierName,
			SupplierCode: supplierCode,
			MaterialDeliveryTime: partnershipInformation ? partnershipInformation.off_set_delivery_days : 5,
			DaysRequiredForDeliveryMaterial: partnershipInformation
				? partnershipInformation.days_required_for_delivery_material
				: 0,
			AddHoursForDeliveryMaterial: partnershipInformation
				? partnershipInformation.time_hours_required_for_delivery_material
				: '30',
			Incoterm: partnershipInformation ? partnershipInformation.incoterm : 'DDP',
		}
		return axios
			.post(endPoint, dataObject, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDeliveryOrderData(serverResponse.data, 'deliveryOrderDraft'))
				}
			})
			.catch((error) => {
				console.error('Error to load deliveryOrder', error.response)
				dispatch(isLoadingDeliveryOrder(false, 'deliveryOrderDraft'))
				return Promise.reject(error.response.data.Message) //Return Message to show toaster error
			})
		// }
	}
}

/**
 * Get an specific DeliveryOrder data from backend
 *
 * @export
 * @param {*} token
 * @returns
 */
export function loadPartNumberCodesFromBackEnd() {
	return function (dispatch, getState) {
		dispatch(isLoadingPartNumberCodes(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/releasepartnumbers`

		let selectedCompany = getState().profile.get('selectedCompany')

		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: user.profile.company_code,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setPartNumberCodes(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load deliveryOrder', error.response)
				dispatch(isLoadingPartNumberCodes(false))
				return Promise.reject()
			})
	}
}

/**
 * Get an specific DeliveryOrder data from backend
 *
 * @export
 * @param {*} token
 * @returns
 */
export function loadPurchaseOrdersFromBackEnd(selectedPartNumber) {
	return function (dispatch, getState) {
		dispatch(isLoadingSelectorValues(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/releasepurchaseorders`

		let selectedCompany = getState().profile.get('selectedCompany')

		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: user.profile.company_code,
			PartNumber: selectedPartNumber,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSelectorValues(serverResponse.data, 'PurchaseOrders', 'purchaseOrders'))
				}
			})
			.catch((error) => {
				console.error('Error to load deliveryOrder', error.response)
				dispatch(isLoadingSelectorValues(false))
				return Promise.reject()
			})
	}
}

/**
 * Get an specific DeliveryOrder data from backend
 *
 * @export
 * @param {*} token
 * @returns
 */
export function loadPositionsFromBackEnd(selectedPartNumber, selectedPurchaseOrder) {
	return function (dispatch, getState) {
		dispatch(isLoadingSelectorValues(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/releasepositions`

		let selectedCompany = getState().profile.get('selectedCompany')

		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: user.profile.company_code,
			PartNumber: selectedPartNumber,
			PurchaseOrder: selectedPurchaseOrder,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSelectorValues(serverResponse.data, 'Positions', 'positions'))
				}
			})
			.catch((error) => {
				console.error('Error to load deliveryOrder', error.response)
				dispatch(isLoadingSelectorValues(false))
				return Promise.reject()
			})
	}
}

/**
 * Add material item to deliveryOrder
 *
 * @export
 * @param {*} deliveryOrder
 * @returns
 */
export function addMaterialToDeliveryOrder(material, supplierName) {
	return (dispatch, getState) => {
		dispatch(isSavingMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/items`

		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierToken = undefined
		if (user.profile && user.profile.company_code) {
			supplierToken = user.profile.company_token
		}
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			Code: material.part_number_code,
			PurchaseOrder: material.purchase_order,
			Position: material.position,
			RevisionConfirmation: material.revision_confirmation,
			Quantity: material.quantity_of_material,
			InvoiceRemission: material.invoice_or_remission,
			Lote: material.lot,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addMaterialToList(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaterial(false))
				console.error('Error adding a deliveryOrder', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Remove material item from deliveryOrder
 *
 * @export
 * @param {*} deliveryOrder
 * @returns
 */
export function removeMaterialFromDeliveryOrder(materialItemToken) {
	return (dispatch, getState) => {
		dispatch(isSavingMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/materialitems/delete/${materialItemToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeMaterialFromList(materialItemToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error adding a deliveryOrder', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update material item in draft delivery order
 *
 * @export
 * @param {*} materialItemToken
 * @param {*} material
 * @returns
 */
export function updateMaterialFromDeliveryOrder(materialItemToken, material) {
	return function (dispatch, getState) {
		dispatch(isSavingMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/updateitem`
		let data = {
			Id: materialItemToken,
			PurchaseOrder: material.purchase_order,
			Position: material.position,
			Revision: material.revision_confirmation,
			Quantity: material.quantity_of_material,
			InvoiceRemission: material.invoice_or_remission,
			Lote: material.lot,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateMaterialFromList(serverResponse.data, 'deliveryOrderDraft'))
				}
			})
			.catch((error) => {
				console.error('Error to update material', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Close delivery order and get download
 *
 * @export
 * @param {*} token
 * @returns
 */
export function closeAndPrintDeliveryOrder(arrivalData, deliveryOrderToken) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let supplierInformation = getState().suppliers.get('supplier').toJS()
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let partnershipInformation = getState().suppliers.get('partnership').toJS()
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/verifyavailabletimes/tosend`
		let data = {
			DeliveryOrderToken: deliveryOrderToken,
			EstimatedArrivalDateTime: `${arrivalData.estimated_date.format('DD/MM/YYYY')} ${
				arrivalData.estimated_time
			}`,
			SendName: user.profile.name,
			SendEmail: typeof user.profile.email === 'string' ? user.profile.email : user.profile.email[0],
			SendUserName: user.profile.preferred_username,
			RampNumber: String(arrivalData.ramp_number),
			Supplier: supplierData(supplierInformation),
			Company: internalCompanyData(companyInformation),
			MaterialDeliveryTime: partnershipInformation ? partnershipInformation.off_set_delivery_days : 5,
			DaysRequiredForDeliveryMaterial: partnershipInformation
				? partnershipInformation.days_required_for_delivery_material
				: 0,
			AddHoursForDeliveryMaterial: partnershipInformation
				? partnershipInformation.time_hours_required_for_delivery_material
				: '1',
			Incoterm: partnershipInformation ? partnershipInformation.incoterm : 'DDP',
			IsRequieredCarbonFootprint: partnershipInformation
				? partnershipInformation.is_supplier_to_add_carbon_foot_print
				: false,
			IsRequieredCerificateByTemplate: partnershipInformation
				? partnershipInformation.is_requiered_cerificate_by_template
				: false,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/sendbyclient`
					return axios
						.post(endPoint, data, configuration)
						.then((response) => {
							if (response.status === 200) {
								dispatch(isSavingDeliveryOrder(false))
								return Promise.resolve('Ok')
							}
						})
						.catch((error) => {
							console.error('Error to close deliveryOrder', error.response)
							dispatch(isSavingDeliveryOrder(false))
							return Promise.reject(error) //IMPORTANT!! Return full error response
						})
				}
			})
			.catch((error) => {
				console.error('Error to verify available times delivery order', error.response)
				dispatch(isSavingDeliveryOrder(false))
				return Promise.reject(error.response.data.Message) //Return Message to show toaster error
			})
	}
}

/**
 * check schedule availability by ramp and date
 *
 * @export
 * @param {*} token
 * @returns
 */
export function checkScheduleAvailability(arrivalData, deliveryOrderToken) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/unavailableHours`
		let data = {
			EstimatedArrivalDateTime: `${arrivalData.estimated_date.format('DD/MM/YYYY')}`,
			CompanyToken: companyInformation['token'],
			RampNumber: String(arrivalData.ramp_number),
			DeliveryOrderToken: deliveryOrderToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDeliveryOrder(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to verify available times delivery order', error.response)
				dispatch(isSavingDeliveryOrder(false))
				return Promise.reject(error.response.data.Message) //Return Message to show toaster error
			})
	}
}

/**
 * Get an specific DeliveryOrder data from backend
 *
 * @export
 * @param {*} token
 * @returns
 */
export function loadDeliveryOrderFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingDeliveryOrder(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDeliveryOrderData(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load deliveryOrder', error.response)
				dispatch(isLoadingDeliveryOrder(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to update delivery order from fQuality certificates
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFilesQualityCertificates(files) {
	return (dispatch, getState) => {
		dispatch(isSavingMaterial(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let tokenDelivery = getState().deliveryOrders.get('deliveryOrder').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/${tokenDelivery}/upload`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse.data))
					dispatch(isSavingMaterial(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingMaterial(false))
				console.error('Error to upload evidence file(s) from delivery order', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files of Quality Certificates
 *
 * @export
 * @param {*} quoteToken
 * @param {*} fileName
 * @returns
 */
export function removeFilesQualityCertificates(fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let tokenDelivery = getState().deliveryOrders.get('deliveryOrder').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/${tokenDelivery}/remove`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFile(fileName))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) from delivery order', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update delivery order from fQuality certificates
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFilesQualityCertificatesDraft(files) {
	return (dispatch, getState) => {
		dispatch(isSavingDeliveryOrder(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let tokenDelivery = getState().deliveryOrders.get('deliveryOrderDraft').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/${tokenDelivery}/upload`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFileInDraft(serverResponse.data))
					dispatch(isSavingDeliveryOrder(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to upload evidence file(s) from delivery order', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files of Quality Certificates
 *
 * @export
 * @param {*} quoteToken
 * @param {*} fileName
 * @returns
 */
export function removeFilesQualityCertificatesDraft(fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let tokenDelivery = getState().deliveryOrders.get('deliveryOrderDraft').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/${tokenDelivery}/remove`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFileInDraft(fileName))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) from delivery order', error.response)
				return Promise.reject()
			})
	}
}

export function addQualityCertificatesDraft(files) {
	return (dispatch, getState) => {
		dispatch(isSavingDeliveryOrder(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let tokenDelivery = getState().deliveryOrders.get('deliveryOrderDraft').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/${tokenDelivery}/importcertificate`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addCertificateDocument(serverResponse.data))
					dispatch(isSavingDeliveryOrder(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to add certificate from delivery order', error.response)
				return Promise.reject(error.response.data.Message) //Return Message to show toaster error
			})
	}
}

export function onRemoveQualityCertificateDocument(certificateToken) {
	return (dispatch, getState) => {
		dispatch(isSavingDeliveryOrder(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let tokenDelivery = getState().deliveryOrders.get('deliveryOrderDraft').get('token')

		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/${tokenDelivery}/remove/${certificateToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeCertifaceDocument(certificateToken))
					dispatch(isSavingDeliveryOrder(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to remove certificate document from delivery order', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action add Quality Certificates
 * @param {*} certificate
 */
export function addQualityCertificates(certificate) {
	return (dispatch, getState) => {
		dispatch(isSavingDeliveryOrder(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let tokenDelivery = getState().deliveryOrders.get('deliveryOrderDraft').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/addCertificate`

		let data = {
			DeliveryToken: tokenDelivery,
			PartNumberCode: certificate.part_number_code,
			PurchaseOrder: certificate.purchase_order,
			TransportLine: certificate.transport_line,
			LoadConditions: certificate.load_conditions,
			NameWhoCertifies: certificate.name_who_certifies,
			NameVoBoTransport: certificate.name_vobo_transport,
			NumberRegisterQuality: certificate.number_register_quality,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addCertificateDocument(serverResponse.data))
					dispatch(isSavingDeliveryOrder(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to add certificate from delivery order', error.response)
				return Promise.reject(error.response.data.Message) //Return Message to show toaster error
			})
	}
}

/**
 * Action add Item In Certificate In Delivery Order
 * @param {*} certificate
 */
export function addItemInCertificateInDeliveryOrder(certificateToken, isDraft) {
	return (dispatch, getState) => {
		dispatch(isSavingDeliveryOrder(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/certificate/${certificateToken}/addItem`

		let data = {}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					if (isDraft) {
						dispatch(updateCertificateDocument(serverResponse.data))
					} else {
						dispatch(updateCertificateInView(serverResponse.data))
					}
					dispatch(isSavingDeliveryOrder(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to add certificate from delivery order', error.response)
				return Promise.reject(error.response.data.Message) //Return Message to show toaster error
			})
	}
}

/**
 * Action to update Item In Certificate In Delivery Order
 *
 * @export
 * @param {*} materialItemToken
 * @param {*} material
 * @returns
 */
export function updateItemInCertificateInDeliveryOrder(certificateToken, tokenItem, property, propertyData, isDraft) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/certificate/${certificateToken}/updateItem/${tokenItem}`
		let data = {
			[underScoreToTitleCase(property)]: propertyData,
		}

		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDeliveryOrder(false))
					if (isDraft) {
						dispatch(updateCertificateDocument(serverResponse.data))
					} else {
						dispatch(updateCertificateInView(serverResponse.data))
					}
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to update certificate', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to remove Item In Certificate In Delivery Order
 * @param {*} certificateToken
 * @param {*} tokenItem
 */
export function removeItemInCertificateInDeliveryOrder(certificateToken, tokenItem, isDraft) {
	return (dispatch, getState) => {
		dispatch(isSavingDeliveryOrder(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/certificate/${certificateToken}/removeItem/${tokenItem}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					if (isDraft) {
						dispatch(updateCertificateDocument(serverResponse.data))
					} else {
						dispatch(updateCertificateInView(serverResponse.data))
					}
					dispatch(isSavingDeliveryOrder(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to remove certificate document from delivery order', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to cancel a delivery order document by client
 *
 * @export
 * @param {*} token
 * @returns
 */
export function cancelDeliveryOrderToClient(token, razonForCancel) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user

		let data = {
			CancelUserName: user.profile.preferred_username,
			CancelBy: user.profile.name,
			DeliveryOrderToken: token,
			ReasonCancellation: razonForCancel,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/cancelbyclient`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDeliveryOrder(false))
					dispatch(setDeliveryOrderData(serverResponse.data))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to cancel a delivery order', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to register the folio from delivery arrval
 * @param {*} folio
 */
export function registerArrivalDeliveryOrder(folio) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user

		let data = {
			RegisterByUserName: user.profile.preferred_username,
			RegisterByName: user.profile.name,
			Folio: folio,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/registerarrival`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDeliveryOrder(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to cancel a delivery order', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to Notifiy To Warehouse About Quality Verification
 * @param {*} folio
 */
export function notifiyToWarehouseAboutQualityVerification(token) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user

		let data = {
			DeliveryOrderToken: token,
			UserName: user.profile.preferred_username,
			Name: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/notifywarehousequalitynotification`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDeliveryOrder(false))
					dispatch(setDeliveryOrderData(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to notify warehouse quality', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to acceted mateiral b warehouseman
 * @param {*} itemToken
 * @param {*} workerName
 */
export function acceptMaterialItemByWarehouseman(itemToken, workerName) {
	return function (dispatch, getState) {
		dispatch(isSavingMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			DeliveryOrderItemToken: itemToken,
			Name: user.profile.name,
			UserName: user.profile.preferred_username,
			WorkerName: workerName.label,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/acceptitemwarehouseman`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingMaterial(false))
					dispatch(setDeliveryOrderData(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isSavingMaterial(false))
				console.error('Error to accept material item', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action Send the delivery order to SAP from user warehouse
 * @param {*} folio
 */
export function sendDeliveryOrderToSAP(token) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user

		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			DeliveryOrderToken: token,
			CreatorUserName: user.profile.preferred_username,
			CreatedBy: user.profile.name,
			CompanyCenter: selectedCompany.get('center'),
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/senddeliveryordertosap`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDeliveryOrder(false))
					dispatch(setDeliveryOrderData(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to send delivery order to sap' + error.response.data.Message, error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to cancel delivery order in SAP
 * @param {*} token
 */
export function cancelDeliveryOrderInSAP(token, razonForCancel) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user

		let data = {
			DeliveryOrderToken: token,
			CancelUserName: user.profile.preferred_username,
			CancelBy: user.profile.name,
			ReasonCancellation: razonForCancel,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/canceldeliveryorderinsap`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDeliveryOrder(false))
					dispatch(setDeliveryOrderData(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to cancel delivery order to sap' + error.response.data.Message, error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 *Download report of delivery orders
 *
 * @export
 * @returns
 */
export function downloadDeliveryOrderReport(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(isDonwloadingReport(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate,
		}

		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/downloadreportbytime`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'ordenes_de_entrega_por_creación.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading report of delivery orders by time', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update property in material item
 *
 * @export
 * @param {*} materialItemToken
 * @param {*} material
 * @returns
 */
export function updateMaterialProperty(materialItemToken, property, propertyData, deliveryOrderType = 'deliveryOrder') {
	return function (dispatch, getState) {
		dispatch(isSavingMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/updatematerial/${materialItemToken}`
		let data = {
			[underScoreToTitleCase(property)]: propertyData,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateMaterialFromList(serverResponse.data, deliveryOrderType))
				}
			})
			.catch((error) => {
				dispatch(isSavingMaterial(false))
				console.error('Error to update material', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function updateDeliveryTimeProperty(token, value) {
	return function (dispatch, getState) {
		dispatch(isSavingMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryOrder/updatedeliverytime/${token}`
		let data = {
			Value: value,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				dispatch(updateArrivalTime(serverResponse.data.arrival_time))				
			})
			.catch((error) => {
				dispatch(isSavingMaterial(false))
				console.error('Error to update property', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update property in material item
 *
 * @export
 * @param {*} materialItemToken
 * @param {*} material
 * @returns
 */
export function updateQuantityMaterial(materialItemToken, propertyData) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/updatematerial/${materialItemToken}/qualityChange`
		let data = {
			DeliveryItemToken: materialItemToken,
			UserName: user.profile.preferred_username,
			Name: user.profile.name,
			Quantity: propertyData,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDeliveryOrderData(serverResponse.data, 'deliveryOrder'))
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to update material', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update sap number property in material item
 *
 * @export
 * @param {*} materialItemToken
 * @param {*} material
 * @returns
 */
export function updateSapNumberMaterial(materialItemToken, propertyData) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/manualregisterentrysapitem`
		let data = {
			DeliveryItemToken: materialItemToken,
			UserName: user.profile.preferred_username,
			Name: user.profile.name,
			SapNumber: propertyData,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDeliveryOrderData(serverResponse.data, 'deliveryOrder'))
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				console.error('Error to update material', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Load delivery orders events to show in Full Calendar
 *
 * @export
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @returns
 */
export function loadDeliveryOrdersEventsFromBackEnd() {
	return function (dispatch, getState) {
		dispatch(isLoadingDeliveryOrdersEvents(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let supplierToken = undefined
		if (user.profile && user.profile.company_code) {
			supplierToken = user.profile.company_token
		}
		let selectedCompany = getState().profile.get('selectedCompany')
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorders/calendarlocks`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDeliveryOrdersEvents(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingDeliveryOrdersEvents(false))
				console.error('Error getting the delivery orders by company list', error.response)
			})
	}
}

/**
 * Action to Download delivery orders with Material Returned Or Missing
 */
export function downloadDeliveryMaterialReturnedOrMissingPdf(token) {
	return (dispatch, getState) => {
		let supplierInformation = getState().suppliers.get('supplier').toJS()
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let data = {
			Supplier: supplierData(supplierInformation),
			Company: internalCompanyData(companyInformation),
		}

		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/${token}/pdfreturn`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var file = new Blob([serverResponse.data], { type: 'application/pdf' })
					var fileURL = URL.createObjectURL(file)
					window.open(fileURL)
				}
			})
			.catch((error) => {
				console.error('Error in download Material Returned Or Missing in delivery order', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update carbon Footprint
 * @param {*} carbonFootprint
 * @param {*} deliveryOrderToken
 */
export function updatePropertyDeliveryOrder(carbonFootprint, destination, deliveryOrderToken) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/update`
		let data = {
			DeliveryToken: deliveryOrderToken,
			TypeTransport: carbonFootprint ? carbonFootprint.type_transport : '',
			DestinationToken: destination ? destination.value : '',
			FreightName: carbonFootprint ? carbonFootprint.freight_name : '',
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDeliveryOrder(false))
					dispatch(updateDeliveryInDraft(serverResponse.data))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error in add information transport in order', error.response)
				dispatch(isSavingDeliveryOrder(false))
				return Promise.reject()
			})
	}
}

/**
 * is loading report
 * @param {*} isDonwloading
 */
function isDonwloadingReport(isDonwloading) {
	return {
		type: IS_DOWNLOADING_REPORT_ORDERS,
		isDonwloading,
	}
}

/**
 *Download report of delivery orders geral
 *
 * @export
 * @returns
 */
export function downloadOrdersReportByRangeDate(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(isDonwloadingReport(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/downloadreport/byrangedate`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_general_ordenes.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading report of delivery orders', error.response)
				return Promise.reject()
			})
	}
}

export function downloadOrdersReportByDeliveryDate(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(isDonwloadingReport(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/downloadreport/bydeliverydate`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_general_ordenes_by_delivery_date.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading report of delivery orders', error.response)
				return Promise.reject()
			})
	}
}

/**
 *Download report of delivery orders
 *
 * @export
 * @returns
 */
export function downloadCertificateQuaityExcel(deliveryToken, certificateToken) {
	return (dispatch, getState) => {
		dispatch(isDonwloadingReport(true))
		let data = {
			DeliveryToken: deliveryToken,
			CertificateToken: certificateToken,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/downloadcertificatequality`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Certificado_de_calidad.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading report certificate quality', error.response)
				return Promise.reject()
			})
	}
}

function isLoadingCertificateExist(isLoadingCertificate) {
	return {
		type: IS_LOADING_CERTIFICATED,
		isLoadingCertificate,
	}
}

/**
 * Get an specific DeliveryOrder data from backend
 *
 * @export
 * @param {*} token
 * @returns
 */
export function getDeliveryOrderByPartNumberCertificate(supplierToken, partNumber, lote) {
	return function (dispatch, getState) {
		dispatch(isLoadingCertificateExist(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/deliveryorder/certificate/${supplierToken}/${partNumber}/${lote}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				dispatch(isLoadingCertificateExist(false))
				if (serverResponse.status === 200) {
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to load certificate by part number and supplier token', error.response)
				dispatch(isLoadingCertificateExist(false))
				return Promise.reject()
			})
	}
}
