import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'

import { Grid, Tooltip, IconButton, Typography } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import AcceptedIcon from '@material-ui/icons/ThumbUp'
import RejectedIcon from '@material-ui/icons/ThumbDown'

import { COLOR_CART_PORT } from '../../../store/helpers/StatusColorConstants'
import { getStatusQuestion } from '../../../store/qualityCertificate/qualityCertificateResults'

import DataTable from '../../common/DataTable'
import OptionsMenu from '../../common/OptionsMenu'

const PurcherOrdersList = (props) => {
	const {
		qualityCertificate,
		canBeEditSupplier,
		isSaving,
		onAddDetail,
		onRemoveItem,
		canBeEditPlant,
		onValidatePurchaseOrder,
		canBeValidate,
	} = props
	const [openToken, setOpenToken] = useState(false)

	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'StatusValid') {
			return getStatusQuestion(item.StatusValid)
		}
		if (dataSource === 'Options') {
			return (
				<>
					{canBeEditSupplier && renderOptionsMenu(item, 0)}
					{canBeEditPlant && renderOptionsMenu(item, 1)}
					{canBeEditPlant && renderOptionsMenu(item, 2)}
				</>
			)
		}
	}

	const handleClickOptionsMenu = (itemToken) => {
		setOpenToken(itemToken)
	}

	const handleCloseOptionsMenu = () => {
		setOpenToken('')
	}

	const renderOptionsMenu = (item, status) => {
		var options = []
		if (canBeEditSupplier && status === 0 && canBeValidate(item.StatusValid)) {
			options.push({
				itemClick: () => onRemoveItem(item.Id),
				tooltipTranslation: <Translate id='common.delete' />,
				menuItemIcon: <DeleteOutlinedIcon color='error' />,
			})
		}
		if (canBeEditPlant) {
			if (status === 1) {
				options.push({
					itemClick: () => onValidatePurchaseOrder(item.Id, status),
					tooltipTranslation: <Translate id='common.accept' />,
					menuItemIcon: <AcceptedIcon color='secondary' />,
				})
			}
			if (status === 2) {
				options.push({
					itemClick: () => onValidatePurchaseOrder(item.Id, status),
					tooltipTranslation: <Translate id='common.reject' />,
					menuItemIcon: <RejectedIcon color='error' />,
				})
			}
		}
		return (
			<OptionsMenu
				itemToken={item.id}
				handleClickOptions={handleClickOptionsMenu}
				open={openToken === item.id}
				handleClickAway={handleCloseOptionsMenu}
				options={options}
				xsSize
			/>
		)
	}

	return (
		<>
			<Grid
				container
				direction='row'
				justifycontent='space-between'
				alignItems='center'
				style={{ backgroundColor: COLOR_CART_PORT, padding: '10px' }}
			>
				<Grid item xs={12} sm={6}>
					<Typography component='span' variant='body2' style={{ display: 'inline' }} color='textPrimary'>
						<strong>
							<Translate id='shippingOrder.details' />
						</strong>
					</Typography>
					<br />
				</Grid>
				{canBeEditSupplier && (
					<Grid item xs={12} sm={6} style={{ textAlign: 'end' }}>
						<Tooltip title={'Agregar orden de entrega'}>
							<IconButton color='primary' onClick={() => onAddDetail()} disabled={isSaving}>
								<AddCircleOutlineIcon />
							</IconButton>
						</Tooltip>
					</Grid>
				)}
			</Grid>
			<DataTable
				data={qualityCertificate.Details}
				configuration={TableConfiguration}
				onRenderCellItem={onRenderCellItem}
			/>
		</>
	)
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='shippingOrder.purchaseOrder' />,
			dataSource: 'PurchaseOrder',
		},
		{
			header: <Translate id='shippingOrder.codePartNumber' />,
			dataSource: 'PartNumberCode',
		},
		{
			header: <Translate id='shippingOrder.productoDescription' />,
			dataSource: 'PartNumberDescription',
		},
		{
			header: <Translate id='shippingOrder.quantityofMaterial' />,
			dataSource: 'QuantityOfMaterial',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'StatusValid',
		},
		{
			header: '',
			dataSource: 'Options',
		},
	],
}

export default PurcherOrdersList
