/** React imports section */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
import { Avatar, CardHeader, Typography } from '@material-ui/core'
import { Description } from '@material-ui/icons'
/** Component imports section */
import SearchInput from '../../../common/SearchInput/SearchInput'

const PackageHeader = (props) => {
	const { onChangeSearchValue, queryParams } = props
	//setOpenPanel, isInternallUser
	const classes = styles()

	return (
		<CardHeader
			avatar={
				<Avatar>
					<Description />
				</Avatar>
			}
			action={
				<div style={classes.constainer}>
					<div style={classes.marginSearch}>
						<SearchInput
							onChangeSearchValue={onChangeSearchValue}
							value={!queryParams && queryParams.query ? queryParams.query : ''}
						/>
					</div>
					{/* {!isInternallUser && (
						<div style={classes.marginSearch}>
							<Tooltip title={<Translate id='specificationsSection.package.addSpecificationLabel' />}>
								<Fab
									size='small'
									color='primary'
									className={classes.categoryIcon}
									onClick={() => setOpenPanel(true)}
								>
									<Add />
								</Fab>
							</Tooltip>
						</div>
					)} */}
				</div>
			}
			title={
				<Typography>
					<Translate id='specificationsSection.package.title' />
				</Typography>
			}
		/>
	)
}
const styles = (theme) => ({
	constainer: {
		display: 'flex',
	},
	marginSearch: {
		marginRight: '5px',
	},
})
PackageHeader.propTypes = {
	onChangeSearchValue: PropTypes.func,
	queryParams: PropTypes.shape({
		query: PropTypes.string,
	}),
	// setOpenPanel: PropTypes.func,
	// isInternallUser: PropTypes.bool,
}
PackageHeader.defaultProps = {
	onChangeSearchValue: () => console.warn('[onChangeSearchValue] not defined!'),
	queryParams: { query: '' },
	// setOpenPanel: () => console.warn('[setOpenPanel] is not defined!'),
	// isInternallUser: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(PackageHeader))
