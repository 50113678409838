import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import moment from 'moment'

/** Import component section */
import '../../resources/styles/autosuggest.css'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
import FormHeaderCard from './Components/FormHeaderCard'
import SuppliersCard from './Components/SuppliersCard'

/** Translations imports section */
import { Translate, getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

/** Import section actions */
import { searchBySuppliersByQury } from '../../store/suppliers/SupplierActions'
import { createContractData } from '../../store/supplierconstracts/SupplierConstractAction'
import { DOCUMENT_CONTRACTOR } from '../../store/helpers/ResourcesHelper'

/**
 * Container to Create contract
 */
class Create extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			contract: {
				attachments: [],
				activationDate: '',
				expirationDate: '',
				supplierSelected: null,
				name: '',
				notes: '',
				documentType: DOCUMENT_CONTRACTOR,
				requestedBy: '',
				typeDeal: 0,
				typeOfCurrency: 0,
				legal: '',
				language: '',
				daysToExpire: 0,
				repse: false,
				contrapartEmployees: 0,
				counterpartEmployerRegistration: '',
				counterpartRFC: '',
				personnelServiceAvailable: false,
				isFilial: false,
				personnelEntersPlaceToPerformServices: false,
				descriptionOfServices: '',
				descriptionOfService: ['', '', '', '', '', '', ''],
				department: '',
				consideration: '',
				wayToPay: '',
				specialConditions: '',
				specialCondition: ['', '', '', '', '', '', ''],
				contractMotive: '',
				signature: '',
				isSupplier: true,
				isNewSupplier: false,
				clientCode: '',
				clientName: '',
			},
		}
	}

	/**
	 * Update the properties in state
	 *
	 * @param {*} user
	 * @param {*} element
	 * @memberof View
	 */
	updateProperty(property, element) {
		const { id, value } = element
		let contractpdate = { ...this.state.contract }
		if (property === 'specialCondition') {
			contractpdate['specialConditions'] = ''
			switch (element) {
				case 'Garantias':
					contractpdate['specialCondition'][0].length > 0
						? (contractpdate['specialCondition'][0] = '')
						: (contractpdate['specialCondition'][0] = element)
					contractpdate['specialCondition'].forEach(
						(element) =>
							(contractpdate['specialConditions'] = contractpdate['specialConditions'].concat(
								contractpdate['specialConditions'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'Seguros':
					contractpdate['specialCondition'][1].length > 0
						? (contractpdate['specialCondition'][1] = '')
						: (contractpdate['specialCondition'][1] = element)
					contractpdate['specialCondition'].forEach(
						(element) =>
							(contractpdate['specialConditions'] = contractpdate['specialConditions'].concat(
								contractpdate['specialConditions'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'Fianzas':
					contractpdate['specialCondition'][2].length > 0
						? (contractpdate['specialCondition'][2] = '')
						: (contractpdate['specialCondition'][2] = element)
					contractpdate['specialCondition'].forEach(
						(element) =>
							(contractpdate['specialConditions'] = contractpdate['specialConditions'].concat(
								contractpdate['specialConditions'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'Penalizaciones':
					contractpdate['specialCondition'][3].length > 0
						? (contractpdate['specialCondition'][3] = '')
						: (contractpdate['specialCondition'][3] = element)
					contractpdate['specialCondition'].forEach(
						(element) =>
							(contractpdate['specialConditions'] = contractpdate['specialConditions'].concat(
								contractpdate['specialConditions'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'Rebate':
					contractpdate['specialCondition'][4].length > 0
						? (contractpdate['specialCondition'][4] = '')
						: (contractpdate['specialCondition'][4] = element)
					contractpdate['specialCondition'].forEach(
						(element) =>
							(contractpdate['specialConditions'] = contractpdate['specialConditions'].concat(
								contractpdate['specialConditions'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'Otra':
					contractpdate['specialCondition'][5].length > 0
						? (contractpdate['specialCondition'][5] = '')
						: (contractpdate['specialCondition'][5] = element)
					contractpdate['specialCondition'].forEach(
						(element) =>
							(contractpdate['specialConditions'] = contractpdate['specialConditions'].concat(
								contractpdate['specialConditions'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'NA':
					contractpdate['specialCondition'][6].length > 0
						? (contractpdate['specialCondition'][6] = '')
						: (contractpdate['specialCondition'][6] = element)
					contractpdate['specialCondition'].forEach(
						(element) =>
							(contractpdate['specialConditions'] = contractpdate['specialConditions'].concat(
								contractpdate['specialConditions'].length > 0 ? ',' : '',
								element
							))
					)
					break
				default:
					break
			}
		} else if (property === 'descriptionOfServices') {
			contractpdate['descriptionOfServices'] = ''
			switch (id) {
				case 'filled-margin-dense-0':
					contractpdate['descriptionOfService'][0] = value
					contractpdate['descriptionOfService'].forEach(
						(element) =>
							(contractpdate['descriptionOfServices'] = contractpdate['descriptionOfServices'].concat(
								contractpdate['descriptionOfServices'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'filled-margin-dense-1':
					contractpdate['descriptionOfService'][1] = value
					contractpdate['descriptionOfService'].forEach(
						(element) =>
							(contractpdate['descriptionOfServices'] = contractpdate['descriptionOfServices'].concat(
								contractpdate['descriptionOfServices'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'filled-margin-dense-2':
					contractpdate['descriptionOfService'][2] = value
					contractpdate['descriptionOfService'].forEach(
						(element) =>
							(contractpdate['descriptionOfServices'] = contractpdate['descriptionOfServices'].concat(
								contractpdate['descriptionOfServices'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'filled-margin-dense-3':
					contractpdate['descriptionOfService'][3] = value
					contractpdate['descriptionOfService'].forEach(
						(element) =>
							(contractpdate['descriptionOfServices'] = contractpdate['descriptionOfServices'].concat(
								contractpdate['descriptionOfServices'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'filled-margin-dense-4':
					contractpdate['descriptionOfService'][4] = value
					contractpdate['descriptionOfService'].forEach(
						(element) =>
							(contractpdate['descriptionOfServices'] = contractpdate['descriptionOfServices'].concat(
								contractpdate['descriptionOfServices'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'filled-margin-dense-5':
					contractpdate['descriptionOfService'][5] = value
					contractpdate['descriptionOfService'].forEach(
						(element) =>
							(contractpdate['descriptionOfServices'] = contractpdate['descriptionOfServices'].concat(
								contractpdate['descriptionOfServices'].length > 0 ? ',' : '',
								element
							))
					)
					break
				case 'filled-margin-dense-6':
					contractpdate['descriptionOfService'][6] = value
					contractpdate['descriptionOfService'].forEach(
						(element) =>
							(contractpdate['descriptionOfServices'] = contractpdate['descriptionOfServices'].concat(
								contractpdate['descriptionOfServices'].length > 0 ? ',' : '',
								element
							))
					)
					break
				default:
					break
			}
		} else contractpdate[property] = element
		this.setState({
			contract: contractpdate,
		})
	}

	/**
	 * On seletecd supplier
	 */
	onSupplierSelected(supplier) {
		let contractpdate = { ...this.state.contract }
		contractpdate.supplierSelected = supplier
		this.setState({
			contract: contractpdate,
		})
	}

	/**
	 * Remove files
	 */
	removeFiles(fileName) {
		let attachments = this.state.contract.attachments.filter((file) => {
			return file.name !== fileName
		})
		this.setState({
			contract: {
				...this.state.contract,
				attachments,
			},
		})
		return Promise.resolve('Ok')
	}

	/**
	 * Add file
	 * @param {*} attachments
	 */
	uploadFiles(files) {
		let filesList = this.state.contract.attachments
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.push(files[index])
			}
		}
		this.setState({
			contract: {
				...this.state.contract,
				attachments: filesList,
			},
		})
		return Promise.resolve('Ok')
	}

	/**
	 * Action to can be  information
	 */
	canBeSaved() {
		if (this.props.isSaving) return false
		else {
			let contract = this.state.contract
			if (contract.activationDate.length === 0) return false
			if (contract.expirationDate.length === 0) return false
			if (moment(contract.expirationDate).isBefore(contract.activationDate)) return false
			if (contract.attachments.length <= 0) return false
			if (
				contract.supplierSelected === null &&
				contract.clientCode.length <= 1 &&
				contract.clientName.length <= 1
			)
				return false
			if (contract.requestedBy.length <= 1) return false
			if (contract.typeDeal === 0) return false
			if (contract.typeOfCurrency === 0) return false
			if (contract.legal.length <= 1) return false
			if (contract.language.length <= 1) return false
			if (contract.counterpartRFC <= 1) return false
			if (contract.department.length <= 1) return false
			if (contract.consideration.length <= 1) return false
			if (contract.wayToPay.length <= 1) return false
			if (contract.contractMotive.length <= 1) return false
		}
		return true
	}

	/**
	 * Function to create
	 * @param {*} worker
	 */
	onCreateContractData() {
		this.props
			.createContractData(this.state.contract)
			.then(() => {
				this.setState({
					showAddWorker: false,
					showToView: true,
					showToaster: true,
					toasterMessage: <Translate id='common.addCorrectly' />,
					toasterVariant: 'success',
				})
				this.props.history.push(`/contracts`)
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToAdd' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Render show
	 */
	render() {
		return (
			<Grid container spacing={24}>
				<Grid item xs={7}>
					<FormHeaderCard
						updateProperty={this.updateProperty}
						contract={this.state.contract}
						onCreateContractData={this.onCreateContractData}
						canBeSaved={this.canBeSaved()}
						uploadFiles={this.uploadFiles}
						removeFiles={this.removeFiles}
						dropzoneText={this.props.translate('dropzone.dropzoneBase')}
						{...this.props}
					/>
				</Grid>
				<Grid item xs={5}>
					<SuppliersCard
						contract={this.state.contract}
						updateProperty={this.updateProperty}
						onSupplierSelected={this.onSupplierSelected}
						{...this.props}
					/>
				</Grid>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Grid>
		)
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompanyIdentifier = state.profile.get('selectedCompany').get('get_company_identifier')

	return {
		selectedCompanyIdentifier: selectedCompanyIdentifier ? selectedCompanyIdentifier : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingSuppliers: state.suppliers.get('isLoadingSuppliers'),
		isSaving: state.contracts.get('isSavingContract'),
		translate: getTranslate(state.localize),
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		searchBySuppliersByQury: (query) => {
			dispatch(searchBySuppliersByQury(query))
		},
		createContractData: (contract) => {
			return dispatch(createContractData(contract))
		},
	}
}

const viewStyles = (theme) => ({
	userAvatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 240,
	},
	toolBar: {
		backgroundColor: 'red',
	},
	button: {
		margin: theme.spacing.unit,
	},
	errorMessage: {
		color: theme.palette.common.white,
	},
	formatCheck: {
		display: 'flex',
		marginLeft: theme.spacing.unit,
		width: 460,
	},
	documentTypeSelect: {
		marginRight: '5px',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create)))
)
