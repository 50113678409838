import React, { useState } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import ReleaseClockIcon from '@material-ui/icons/Restore'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

/** Import component */
import FormView from './FormView'
import Toaster from '../../common/Toaster'
import { createReleaseCalendarLockFromBackEnd } from '../../../store/plannigFersa/ReleaseCalendarAction'

const Create = (props) => {
	const dispatch = useDispatch()
	const [stateValue, setStateValue] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		creationDate: moment().format('DD/MM/YYYY'),
	})

	const isSaving = useSelector((state) => state.releaseCalendarFersaLock.get('isSaving'))

	const createReleaseCalendarLock = (calendarlock) => {
		dispatch(createReleaseCalendarLockFromBackEnd(calendarlock))
			.then((calendarLock) => {
				setStateValue((prev) => ({ ...prev, showToView: true, showToaster: false }))
				props.history.push(`/releasecalendarFersa/${calendarLock.id}`)
			})
			.catch((result) => {
				if (result) {
					if (result.status === 404) {
						setStateValue((prev) => ({
							...prev,
							showToaster: true,
							toasterMessage: result.data,
							toasterVariant: 'error',
						}))
					} else {
						setStateValue((prev) => ({
							...prev,
							showToaster: true,
							toasterMessage: <Translate id='common.errorToSave' />,
							toasterVariant: 'error',
						}))
					}
				} else {
					setStateValue((prev) => ({
						...prev,
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					}))
				}
			})
	}
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<ReleaseClockIcon />
					</Avatar>
				}
				title={
					<Typography variant='button'>
						<Translate id='releaseCalendarLock.addReleaseCalendar' />
					</Typography>
				}
				subheader={
					<Typography variant='caption' color='textPrimary'>
						<Translate id='common.creationDate' />: {stateValue.creationDate}
					</Typography>
				}
			/>
			<CardContent>
				<FormView {...props} isSaving={isSaving} canBeCreate={createReleaseCalendarLock} />
			</CardContent>
			<Toaster
				message={stateValue.toasterMessage}
				open={stateValue.showToaster}
				variant={stateValue.toasterVariant}
				onClose={() => {
					setStateValue((prev) => ({
						...prev,
						showToaster: false,
					}))
				}}
			/>
		</Card>
	)
}

const styles = (theme) => ({
	toolbar: {
		display: 'flex',
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	button: {
		margin: theme.spacing.unit,
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	grow: {
		flexGrow: 1,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	supplierBadge: {
		padding: `0 ${theme.spacing.unit * 2}px`,
	},
})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Create)))
