/**Import react section */
import React from "react";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import Grid from "@material-ui/core/Grid";
/** Import component section */
import LoadingPage from '../../../common/LoadingPage';
import ShowDesktopViewHeader from '../ShowDesktopViewHeader'
import EmptyIndirectQuaifications from '../EmptyIndirectQuaifications'
import QualificationContainerTable from '../suppliers/QualificationContainerTable'
/**import helpers section */
/**Custom hooks index */

const ShowDesktopViewInternalUser = (props) => {
    /**String props section */
    //const { } = props
    /**Bools props section */
    const { isLoading, isOpenLateralMenu } = props
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onLateralPanelConfig, onConfirmPassword, onRedirectToActionPlant } = props
    /**Objects props section */
    const { indirectQualification, translate, classes } = props
    /**Render hooks section */
    /**Redux store information section*/


    /**
    * Get the card content
    *
    * @memberof Index
    */
    const getCardContent = () => {
        if (isLoading === true) {
            return (
                <LoadingPage />
            )
        } else {
            return (

                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Card className={classes.papper}>
                            <ShowDesktopViewHeader
                                indirectQualification={indirectQualification}
                                translate={translate}
                                onLateralPanelConfig={onLateralPanelConfig}
                                onConfirmPassword={onConfirmPassword}

                            />
                            {indirectQualification && indirectQualification.Suppliers.length === 0 &&
                                <div className={classes.emptyData}>
                                    <EmptyIndirectQuaifications
                                    />
                                </div>
                            }
                            {

                                indirectQualification && indirectQualification.Suppliers.length !== 0 &&
                                <div className={isOpenLateralMenu ? classes.rootOpen:classes.rootClose }>
                                    <QualificationContainerTable
                                        indirectQualification={indirectQualification}
                                        onLateralPanelConfig={onLateralPanelConfig}
                                        onConfirmPassword={onConfirmPassword}
                                        onRedirectToActionPlant={onRedirectToActionPlant}
                                        translate={translate}
                                    />
                                </div>
                            }
                        </Card>
                    </Grid>
                </Grid>
            )
        }
    }
    return getCardContent()
}

const styles = (theme) => ({
    papper: {
        marginTop: "10px"
    },
    rootOpen: {
        //maxHeight: `calc(100vh - ${265}px)`,
        //minHeight: `calc(100vh - ${265}px)`,
        padding: "10px",
        maxWidth: `calc(100vw - ${335}px)`,
        overflowX: "auto",
        overflowY: "auto",

    },
    rootClose:{
        maxWidth: `calc(100vw - ${175}px)`,
        overflowX: "auto",
        overflowY: "auto",
    },
    emptyData: {
        maxHeight: `calc(100vh - ${265}px)`,
        minHeight: `calc(100vh - ${265}px)`,
        overflowY: "auto",
        padding: "10px",
        overflowX: "auto",
        justifyContent: "center",
        alingItems: "center"

    }

});

export default withRouter(
    withStyles(styles, { withTheme: true })(ShowDesktopViewInternalUser)
);
