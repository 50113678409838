/**Import react section */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
/**Import material UI Section*/
import { withStyles, TextField, InputAdornment, Typography, Tooltip } from '@material-ui/core';
import { CriteriaType, NumberFormatType } from '../../../../store/helpers/AppConstants';
import { GetBooleanOptions, getSelectedValue } from '../../../../store/helpers/SelectOptions';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';

const SupplierResponseByItem = (props) => {
	const { classes } = props;
	/**String props section */
	const { dataSource } = props;
	/**Bools props section */
	const { isSaving, isDisabled, isGeneralResponse, identifierLoading, translate } = props;
	/**Arrays props section */
	/**Functions props section */
	/**String props section */
	const { item, serviceCriteriaId, setIdentifierLoading } = props;
	const [errors, setErrors] = useState({});
	let identifierIsLoading = identifierLoading === serviceCriteriaId;
	const validate = (data) => {
		const errors = {};
		if (!IsNullOrEmpty(data.value) && data.name !== 'currency') {
			let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
			if (!regexQuantity.test(data.value)) {
				errors.decimals = translate("biddingsModule.maximumTwoDigits");
			}
			if (data.name === 'minimumRange') {
				let lowerLimit = item[`${serviceCriteriaId}/${dataSource}`];
				let upperLimit = item[`${serviceCriteriaId}_${dataSource}`];
				if (lowerLimit === 0 && upperLimit === 0) {
					if (data.value < 0 || data.value > 100) errors.minimumRange = translate("biddingsModule.value0And100");
				} else {
					if (data.value < lowerLimit || data.value > upperLimit)
						errors.minimumRange = `${translate("biddingsModule.valueEnter")} ${lowerLimit} y ${upperLimit}`;
				}
			}
		}
		if (!IsNullOrEmpty(data.value) && data.name === 'currency') {
			let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
			if (!regexQuantity.test(data.value)) {
				errors.decimals = translate("biddingsModule.regexQuantity");
			}
		}
		return errors;
	};

	const onTextFieldTextPropertyChange = (event) => {
		if (props.onAddResponseSupplier) {
			let criteriaId = item[`${serviceCriteriaId}*${dataSource}`];
			if (props.setIdentifierLoading) setIdentifierLoading(serviceCriteriaId);
			props.onAddResponseSupplier(serviceCriteriaId, criteriaId, event.target.value, isGeneralResponse);
		}
	};

	const onTextFieldNumberPropertyChange = (event) => {
		let { value, name } = event.target;
		const errors = validate(event.target);
		setErrors(errors);
		if (!IsNullOrEmpty(value) && name !== 'currency') {
			let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
			if (!regexQuantity.test(value)) return;
			if (name === 'minimumRange') {
				let lowerLimit = item[`${serviceCriteriaId}/${dataSource}`];
				let upperLimit = item[`${serviceCriteriaId}_${dataSource}`];
				if (lowerLimit === 0 && upperLimit === 0) {
					if (value < 0 || value > 100) return;
				} else {
					if (value < lowerLimit || value > upperLimit) return;
				}
			}
		}
		if (!IsNullOrEmpty(value) && name === 'currency') {
			let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
			if (!regexQuantity.test(value)) return;
		}

		let criteriaId = item[`${serviceCriteriaId}*${dataSource}`];

		if (props.setIdentifierLoading) setIdentifierLoading(serviceCriteriaId);
		if (props.onAddResponseSupplier) {
			props.onAddResponseSupplier(
				serviceCriteriaId,
				criteriaId,
				event.target.value ? event.target.value : item[dataSource],
				isGeneralResponse
			);
		}
	};

	const onSelectorPropertyChange = (option) => {
		let criteriaId = item[`${serviceCriteriaId}*${dataSource}`];
		let result = option.value === '1' ? true : false;
		if (props.onAddResponseSupplier) {
			props.onAddResponseSupplier(serviceCriteriaId, criteriaId, result, isGeneralResponse);
		}
	};

	const getComponetByCriteriaType = () => {
		let criteriaId = item[`${serviceCriteriaId}*${dataSource}`];
		switch (item[`${criteriaId}-${dataSource}`]) {
			case CriteriaType.BOOLEAN:
				let result = '0';
				if (item[dataSource] === true) result = '1';
				if (item[dataSource] === false) result = '2';
				return (
					<div style={{ width: '100px' }}>
						<Select
							placeholder={translate("biddingsModule.selectedOption")}
							options={GetBooleanOptions()}
							onChange={onSelectorPropertyChange}
							defaultValue={getSelectedValue(GetBooleanOptions(), result)}
							styles={{
								menu: (base) => ({ ...base, zIndex: 9999 }),
								menuList: (base) => ({ ...base, paddingTop: 0 }),
								menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
								control: (base) => ({
									...base,
									height: 40,
									minHeight: 40
								})
							}}
							menuPortalTarget={document.body}
							// menuPosition={'fixed'}
							menuPlacement={'bottom'}
							isDisabled={(isSaving && identifierIsLoading) || !isDisabled}
						/>
					</div>
				);
			case CriteriaType.TEXT:
				return (
					<TextField
						size="small"
						fullWidth
						placeholder={translate("biddingsModule.description")}
						className={classes.textField}
						name="name"
						margin="dense"
						variant="outlined"
						multiline
						onBlur={onTextFieldTextPropertyChange}
						disabled={(isSaving && identifierIsLoading) || !isDisabled}
						defaultValue={item[dataSource]}
						inputProps={{
							style: { fontSize: 12, height: 15 }
						}}
					/>
				);
			case CriteriaType.MONEY:
				return (
					<Tooltip
						title={errors && errors.decimals ? errors.decimals : errors && errors.currency ? errors.currency : ''}
					>
						<TextField
							placeholder={translate("biddingsModule.value")}
							name="currency"
							variant="outlined"
							margin="dense"
							fullWidth
                            type='number'
                            step={2.5}
							//type="number"
							defaultValue={item[dataSource]}
							currencySymbol="$"
							outputFormat="string"
							onBlur={onTextFieldNumberPropertyChange}
							disabled={(isSaving && identifierIsLoading) || !isDisabled}
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
								classes: { input: classes.inputProps }
							}}
							error={
								(errors && Object.entries(errors).length !== 0 && errors.decimals) ||
								(errors && Object.entries(errors).length !== 0 && errors.currency)
							}
							//helperText={(errors && errors.decimals) ? errors.decimals : ((errors && errors.currency) ? errors.currency : "")}
						/>
					</Tooltip>
				);
			case CriteriaType.NUMERIC:
				let formatNumber = item[`${serviceCriteriaId}+${dataSource}`];
				switch (formatNumber) {
					case NumberFormatType.CURRENCY:
					case '$0.0':
						return (
							<Tooltip
								title={errors && errors.decimals ? errors.decimals : errors && errors.currency ? errors.currency : ''}
							>
								<TextField
									placeholder={translate("biddingsModule.value")}
									name="currency"
									variant="outlined"
									margin="dense"
									fullWidth
                                    type='number'
                                    step={2.5}
									defaultValue={item[dataSource]}
									currencySymbol="$"
									outputFormat="string"
									onBlur={onTextFieldNumberPropertyChange}
									disabled={(isSaving && identifierIsLoading) || !isDisabled}
									//size="small"
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>,
										classes: { input: classes.inputProps }
									}}
									error={
										(errors && Object.entries(errors).length !== 0 && errors.decimals) ||
										(errors && Object.entries(errors).length !== 0 && errors.currency)
									}
									//helperText={(errors && errors.decimals) ? errors.decimals : ((errors && errors.currency) ? errors.currency : "")}
								/>
							</Tooltip>
						);
					case NumberFormatType.DECIMAL:
						return (
							<TextField
								fullWidth
								placeholder={translate("biddingsModule.value")}
								margin="dense"
								className={classes.textField}
								onBlur={onTextFieldNumberPropertyChange}
								type="number"
								step={0.5}
								name="maximumRange"
								variant="outlined"
								defaultValue={item[dataSource]}
								disabled={(isSaving && identifierIsLoading) || !isDisabled}
								InputProps={{ classes: { input: classes.inputProps } }}
								error={errors && Object.entries(errors).length !== 0 && errors.decimals}
								helperText={errors && errors.decimals ? errors.decimals : ''}
							/>
						);
					case NumberFormatType.PERCENT:
						return (
							<TextField
								fullWidth
								placeholder={translate("biddingsModule.value")}
								margin="dense"
								className={classes.textField}
								step={0.5}
								name="minimumRange"
								type="result"
								variant="outlined"
								onBlur={onTextFieldNumberPropertyChange}
								defaultValue={item[dataSource]}
								InputProps={{
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
									classes: { input: classes.inputProps }
								}}
								disabled={(isSaving && identifierIsLoading) || !isDisabled}
								helperText={
									errors && errors.decimals ? (
										<Typography style={{ marginTop: '-8px', fontSize: '11px' }}>{errors.decimals}</Typography>
									) : errors && errors.minimumRange ? (
										<Typography color="error" style={{ marginTop: '-8px', fontSize: '11px' }}>
											{errors.minimumRange}
										</Typography>
									) : (
										''
									)
								}
								error={
									(errors && Object.entries(errors).length !== 0 && errors.decimals) ||
									(errors && Object.entries(errors).length !== 0 && errors.minimumRange)
								}
							/>
						);
					default:
						return (
							<TextField
								size="small"
								fullWidth
								placeholder={translate("biddingsModule.value")}
								className={classes.textField}
								name="name"
								type="number"
								margin="dense"
								variant="outlined"
								step={0.5}
								onBlur={onTextFieldNumberPropertyChange}
								disabled={(isSaving && identifierIsLoading) || !isDisabled}
								defaultValue={item[dataSource]}
								// helperText={`min ${2} max ${100} `}
								InputProps={{ classes: { input: classes.inputProps } }}
								error={errors && Object.entries(errors).length !== 0 && errors.decimals}
								helperText={errors && errors.decimals ? errors.decimals : ''}
							/>
						);
				}
			default:
				return null;
		}
	};

	return getComponetByCriteriaType();
};

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.primary.main
	},
	textField: {},
	inputProps: {
		height: 6
	}
});

SupplierResponseByItem.propTypes = {
	bidding: PropTypes.object
};

SupplierResponseByItem.defaultProps = {
	bidding: null
};

export default withRouter(withStyles(styles, { withTheme: true })(SupplierResponseByItem));
