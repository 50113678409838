import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory, withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import queryString from 'query-string'

import { Avatar, Card, CardContent, CardHeader, Chip, Fab, Tooltip, Typography } from '@material-ui/core'
import { School } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/AddTwoTone'
import ArchiveDownloadIcon from '@material-ui/icons/Archive'

import SearchInput from '../../common/SearchInput/SearchInput'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import LoadingPage from '../../common/LoadingPage'
import DataTable from '../../common/DataTable'
import AddTrainingDialog from './components/AddTrainingDialog'
import DonwloadReportDialog from '../../common/DonwloadReportDialog'
import Toaster from '../../common/Toaster'

import {
	getTrainings,
	getTrainingCount,
	addEmployeeTraining,
	downloadReportTraining,
} from '../../../store/contractors/TrainingActions'
import {
	getLoadingTrainings,
	getTrainigCountSelector,
	getTrainigsSelector,
	getIsSavingTraining,
} from '../../../store/contractors/TrainingSelector'
import { IsCurrentNotActiveDirectoryUser, IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import {
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_WARNING,
	COLOR_STATUS_TRAINING,
} from '../../../store/helpers/StatusColorConstants'

const Trainings = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	let location = useLocation()
	const history = useHistory()
	const queryParams = queryString.parse(location.search)

	const [openAddTrainingModal, onOpenAddTrainingModal] = useState(false)
	const [openModalByMonthReport, onOpenModalByMonthReport] = useState(false)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	const OnOpenModal = () => {
		onOpenAddTrainingModal(!openAddTrainingModal)
	}
	const handleOpenModal = () => {
		onOpenModalByMonthReport(!openModalByMonthReport)
	}
	const trainings = useSelector((state) => getTrainigsSelector(state))
	const isLoanding = useSelector((state) => getLoadingTrainings(state))
	const trainingCount = useSelector((state) => getTrainigCountSelector(state))
	const isSavingTraining = useSelector((state) => getIsSavingTraining(state))
	const userProfileLogged = useSelector((state) => state.oidc.user)

	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(getTrainings(queryParams))
		dispatch(getTrainingCount())
	}, [dispatch, location.search])

	const addTraining = (file, employee, startDate, endDate, name) => {
		return dispatch(addEmployeeTraining(file, employee, startDate, endDate, name))
			.then((token) => {
				history.push(`/trainings/${token}`)
				OnOpenModal()
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
	}

	const handleDownloadReportByDateRange = (startDate, endDate) => {
		return dispatch(downloadReportTraining(startDate, endDate)).then(() => {
			handleOpenModal()
		})
	}

	const getCounterMessage = () => {
		let totalmedicalchecks = trainings.length
		return (
			<div>
				<Translate id='common.showing' />
				&nbsp;
				<span>{totalmedicalchecks}</span>&nbsp;
				<Translate id='common.of' />
				&nbsp;
				<span>{`${trainingCount}`}</span>&nbsp;
				<Translate id='common.records' />
			</div>
		)
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'status_description') {
			return (
				<Typography variant='body2' style={{ color: determineStatus(item.status) }}>
					{item.status_description}
				</Typography>
			)
		}
		if (dataSource === 'status') {
			if (item.get_active === 2)
				return <Chip style={{ backgroundColor: COLOR_STATUS_WARNING }} label='Próximo a vencer' />
			if (item.get_active === 3) return <Chip style={{ backgroundColor: COLOR_STATUS_DANGER }} label='Vencido' />
			else return <Chip style={{ backgroundColor: COLOR_STATUS_TRAINING }} label='Activo' />
		}
	}

	const determineStatus = (status) => {
		let statusColor
		switch (status) {
			case 4:
				statusColor = COLOR_STATUS_SUCCESS
				break
			case 5:
				statusColor = COLOR_STATUS_DANGER
				break
			default:
				statusColor = COLOR_STATUS_WARNING
				break
		}
		return statusColor
	}

	const getCardContent = () => {
		if (isLoanding) {
			return <LoadingPage />
		} else {
			return (
				<DataTable
					data={trainings}
					configuration={TableConfiguration(IsCurrentActiveDirectoryUser(userProfileLogged))}
					onChangeSortCriteria={onChangeSortCriteria}
					onRenderCellItem={onRenderCellItem}
					onRowClick={(training) => {
						history.push(`/trainings/${training.token}`)
					}}
					showFilters
					isIndex
					totalRows={trainingCount}
					sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'name'}
					sortAscending={
						queryParams && queryParams.sortDirection
							? queryParams.sortDirection === 'desc'
								? false
								: true
							: false
					}
					page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
					rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
					onChangePage={(newPage, newRowsPerPage) => {
						onChangePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	return (
		<>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<School />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								onChangeSearchValue={onChangeSearchValue}
								value={!!queryParams && queryParams.query ? queryParams.query : ''}
							/>
							{IsCurrentNotActiveDirectoryUser(userProfileLogged) && (
								<Tooltip title={<Translate id='contractorsModule.medicalchecks.addTrainig' />}>
									<Fab size='small' color='primary' onClick={() => OnOpenModal()}>
										<AddIcon />
									</Fab>
								</Tooltip>
							)}
							<Tooltip title={<Translate id='contractorsModule.medicalchecks.downloadReportTraining' />}>
								<Fab
									size='small'
									className={classes.marginIconCalendar}
									color='primary'
									onClick={() => handleOpenModal()}
								>
									<ArchiveDownloadIcon />
								</Fab>
							</Tooltip>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='contractorsModule.medicalchecks.trainingsTitle' />
						</Typography>
					}
					subheader={getCounterMessage()}
				/>
				<CardContent>{getCardContent()}</CardContent>
			</Card>
			<AddTrainingDialog
				open={openAddTrainingModal}
				onClose={OnOpenModal}
				addTraining={addTraining}
				isSavingTraining={isSavingTraining}
			/>
			<DonwloadReportDialog
				title={<Translate id='contractorsModule.medicalchecks.downloadReportTraining' />}
				open={openModalByMonthReport}
				onClose={handleOpenModal}
				isSaving={isSavingTraining}
				onDonwloadItems={handleDownloadReportByDateRange}
			/>

			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</>
	)
}

function TableConfiguration(isUserInternal) {
	let columns = [
		{
			header: 'Vigencia',
			dataSource: 'status',
		},
		{
			header: <Translate id='sac.actions.state' />,
			dataSource: 'status_description',
		},
		{
			header: <Translate id='contractorsModule.startDate' />,
			dataSource: 'start_date',
		},
		{
			header: <Translate id='contractorsModule.endDate' />,
			dataSource: 'end_date',
		},
		{
			header: <Translate id='contractorsModule.trainingName' />,
			dataSource: 'name',
		},
		{
			header: <Translate id='contractorsModule.employeedName' />,
			dataSource: 'employed_name',
		},
	]

	if (isUserInternal) {
		columns.push({
			header: <Translate id='common.supplier' />,
			dataSource: 'full_name',
		})
	}

	return { columns }
}

const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	marginIconCalendar: {
		marginLeft: '5px',
	},
})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Trainings)))
