import { fromJS } from 'immutable';

import * as sacActions from './SacActions';
import { CORRECTIVE_ACTION_DEVELOPMENT_INVESTIGATION_LINES } from '../helpers/WorkFlowDocumentsType';

/**
 * sacActions initial state
 */
export const sacsInitialState = fromJS({
    isLoadingSacs: true,
    sacs: null,
    isLoadingSac: false,
    sac: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    sacsCount: null,
    isUpdatingPlaneName: false,
    isSaving: false,
    sacInformation: null,
    isLoadingActions: false
})

export default function sacsReducer(state = sacsInitialState, action) {
    switch (action.type) {
        case sacActions.START_LOADING_SACS: {
            return state.merge({
                isLoadingSacs: action.isLoading
            })
        }
        case sacActions.SET_SACS_LIST: {
            let sacsLists = fromJS(action.sacs);
            return state.merge({
                sacs: sacsLists,
                isLoadingSacs: false
            })
        }
        case sacActions.IS_LOADING_SAC: {
            return state.merge({
                isLoadingSac: action.isLoadingSac,
                sacInformation: null,
            })
        }
        case sacActions.IS_LOADING_ACTIONS: {
            return state.merge({
                isLoadingActions: action.isLoadingActions,
            })
        }
        case sacActions.SET_SAC: {
            return state.merge({
                isLoadingSac: false,
                isSaving: false,
                sacInformation: fromJS(action.sac)
            })
        }
        case sacActions.CHANGE_SACS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case sacActions.CHANGE_SACS_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case sacActions.CHANGE_SACS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case sacActions.SET_SACS_COUNT: {
            return state.merge({
                sacsCount: action.count
            })
        }
        case sacActions.GET_INFORMATION_SAC: {
            return state.merge({
                isLoadingSac: false,
                isSaving: false,
                sac: fromJS(action.sac)
            })
        }
        case sacActions.UPDATE_INVESTIGATION_LINES: {
            return state.updateIn(['sacInformation', 'documents'], (documents) => {
                let index = documents.toJS().findIndex(document => {
                    return document.document_type === CORRECTIVE_ACTION_DEVELOPMENT_INVESTIGATION_LINES
                });
                return documents.setIn([index], action.investigationLines)
            });
        }
        case sacActions.ADD_ACTION_IN_DOCUMENT: {
            let actionToAdd = fromJS(action.actionToAdd);
            return state.updateIn(['sacInformation', 'documents'], (documents) => {
                let indexDocument = documents.findIndex(document => {
                    return document.get('document_type') === action.documentType
                });

                let documentToChange = documents.get(indexDocument);
                let updatedActionsList = documentToChange.get('actions').push(actionToAdd);
                let updatedDocument = documentToChange.set('actions', updatedActionsList);
                return documents.setIn([indexDocument], updatedDocument);
            }).set('isLoadingActions', false);
        }
        case sacActions.REMOVE_ACTION_OF_DOCUMENT: {
            return state.updateIn(['sacInformation', 'documents'], (documents) => {
                let indexDocument = documents.findIndex(document => {
                    return document.get('document_type') === action.documentType
                });
                let documentToUpdate = documents.get(indexDocument);
                let indexActionToRemove = documentToUpdate.get('actions').findIndex(actionInList => {
                    return actionInList.get('token') === action.tokenToRemove
                });
                let updatedActionsList = documentToUpdate.get('actions').delete(indexActionToRemove);
                let updatedDocument = documentToUpdate.set('actions', updatedActionsList);
                return documents.setIn([indexDocument], updatedDocument);
            }).set('isLoadingActions', false);
        }
        case sacActions.UPDATE_ACTION_IN_LIST: {
            return state.updateIn(['sacInformation', 'documents'], (documents) => {
                let indexDocument = documents.findIndex(document => {
                    return document.get('document_type') === action.documentType
                });
                let documentToUpdate = documents.get(indexDocument);
                let indexActionToUpdate = documentToUpdate.get('actions').findIndex(actionInList => {
                    return actionInList.get('token') === action.actionToken
                });
                let updatedActionsList = documentToUpdate.get('actions').setIn([indexActionToUpdate], fromJS(action.actionToUpdate));
                let updatedDocument = documentToUpdate.set('actions', updatedActionsList);
                return documents.setIn([indexDocument], updatedDocument);
            }).set('isLoadingActions', false);
        }
        case sacActions.ADD_FILE_SAC_DEVELOPMENT: {
            let oldList = state.getIn(['sacInformation', 'attachments']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['sacInformation', 'attachments'], fromJS(newList));
        }
        case sacActions.REMOVE_FILE_SAC_DEVELOPMENT: {
            let oldList = state.getIn(['sacInformation', 'attachments']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['sacInformation', 'attachments'], fromJS(newList));
        }
        case sacActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        default:
            return state;
    }
}