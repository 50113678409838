import React, { useState } from 'react'
import { useEffect } from 'react'

import DataTable from '../../common/DataTable'
import { Translate } from 'react-localize-redux'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import * as action from '../../../store/transportTender/transportTenderAction'
import * as selector from '../../../store/transportTender/transportTenderSelector'
import CatalogHeader from './components/CatalogHeader'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import DialogCatalogUploadMassive from './components/DialogCatalogUploadMassive'
import { getLoggedUserSelector } from '../../../store/profile/ProfileSelectors'
import { isTransportTenderAdministrator } from '../../../store/helpers/TransportTenderHelper'

import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import queryString from 'query-string'
import LoadingPage from '../../common/LoadingPage'

const TransportType = (props) => {
	const { classes } = props
	let history = useHistory()
	let location = useLocation()
	const queryParams = queryString.parse(location.search)
	const isLoadig = useSelector((state) => selector.getIsLoadingSavingSelector(state))
	const transportTenderinfo = useSelector((state) => selector.getTransportTenderfilterSelector(state))
	const loggetUser = useSelector((state) => getLoggedUserSelector(state))
	const userRoles = loggetUser && loggetUser.grantedRoles ? loggetUser.grantedRoles : []
	const isTransportTenderAdminitsrator = isTransportTenderAdministrator(userRoles)
	const dispatch = useDispatch()
	const [openDialog, setOpenDialog] = useState(false)
	const [file, setfile] = useState([])

	const errorTransportTypeFile = useSelector((state) => selector.getErrorTransportTypeFileSelector(state))

	useEffect(() => {
		dispatch(action.loadTransportTypeCatalog(queryParams))
	}, [dispatch, location.search])

	const onDownloadTemplateFilled = () => {
		return dispatch(action.downloadFilledTransportTypeTemplate())
	}
	useEffect(() => {
		if (file.length > 0) {
			dispatch(action.validateTransportTypeTemplate(file))
		}
	}, [file])
	const canBeSaved = () => {
		if (errorTransportTypeFile.length > 0) return false
		if (file.length === 0) return false
		return true
	}
	const onSave = () => {
		dispatch(action.uploadTransportTypeTemplate(file))
			.then(() => {
				setOpenDialog(false)
				dispatch(action.loadTransportTypeCatalog(queryParams))
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}
	const onChangeQuery = (query) => {
		if (query) queryParams.query = query
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onChangePage = (page, rowsPerPage) => {
		queryParams.pageNumber = page + 1
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	return (
		<div className={classes.containerTitle}>
			<CatalogHeader
				setOpenDialog={setOpenDialog}
				icon={<LocalShippingIcon />}
				isInternalUse={true}
				title={<Translate id={'carrierTenders.configuration.generalTypeTransport'} />}
				isAdministrator={isTransportTenderAdminitsrator}
				showSearch
				queryParams={queryParams}
				onChangeSearchValue={onChangeQuery}
			/>
			{isLoadig ? (
				<LoadingPage />
			) : (
				<DataTable
					data={transportTenderinfo.transportTypePagination.items}
					configuration={TableConfiguration()}
					showFilters
					isIndex
					page={transportTenderinfo.transportTypePagination.pageNumber}
					rowsPerPage={transportTenderinfo.transportTypePagination.pageSize}
					totalRows={transportTenderinfo.transportTypePagination.total}
					onChangePage={onChangePage}
				/>
			)}

			<DialogCatalogUploadMassive
				title={'Importación masiva de tipos de transporte'}
				typeLoadTranslate={'massiveTransportType'}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				file={file}
				setfile={setfile}
				onDownloadTemplateFilled={onDownloadTemplateFilled}
				isLoading={isLoadig}
				canBeSaved={canBeSaved()}
				errorList={errorTransportTypeFile}
				onSave={onSave}
			/>
		</div>
	)
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: <Translate id={'carrierTenders.configuration.typesTransport'} />,
			dataSource: 'type',
		},
		{
			header: <Translate id={'carrierTenders.deals.code'} />,
			dataSource: 'code',
		},
		{
			header: <Translate id={'carrierTenders.deals.description'} />,
			dataSource: 'description',
		},
	]

	return { columns }
}
const styles = (theme) => ({
	addIcon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		borderRadius: '16px',
		margin: '0px 5px 0px 10px',
	},
	titleTabs: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	containerTitle: {
		margin: '15px',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(TransportType)))
