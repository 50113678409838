import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
import Select from 'react-select'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
import SupplierSelector from '../../common/SupplierShortSelector';
import { getProductsMaterials, getConsumsMaterials} from '../../../store/maquilaOrders/MaquilaOrderSelector';

/**
 * Class compoenent to add a service/project
 *
 * @class AddMaterialModal
 * @extends {Component}
 */
class AddMaterialModal extends Component {

    /**
     *Creates an instance of DialogActions.
     * @param {*} props
     * @memberof DialogActions
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            material: {
                partNumber: "",
                quantity: "",
                transport: null,
            },
            productMachined: null
        }
    }

    updateProperty(property, value) {
        let material = { ...this.state.material };
        material[property] = value;
        this.setState({
            material: material
        })
    }

    updatePropertyPartNumber(partNumber) {
        let material = { ...this.state.material };
        material['partNumber'] = partNumber;
        material['quantity'] = partNumber.quantity ? partNumber.quantity.replace(',', '') : 0;
        this.setState({
            material: material
        })
    }

    updatePropertyProductMachined(option) {
        this.setState({
            productMachined: option
        })
    }


    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof AddMaterialModal
     */
    isValidToSave() {
        if (!IsNullOrEmpty(this.state.material.partNumber)
            && Number(this.state.material.quantity) > 0
            && this.state.productMachined
        )
            return true;
        return false
    }

    /**
     * Function to add item
     * 
     */
    onAddItem() {
        this.props.onSaveItem(this.state.material, this.state.productMachined)
            .then(() => {
                this.props.onClose()
            })
    }

    render() {
        const { classes, maquilaOrder } = this.props;
        let poductMachinedSelector = getProductsMaterials(maquilaOrder, this.state.material)
        let materials = getConsumsMaterials(maquilaOrder, this.state.productMachined)
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.props.onClose()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id="maquilaOrders.addMaterialToMaquilador" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <DialogActions
                                view={SHOW_EDIT_VIEW}
                                classes={classes}
                                onCancel={() => this.props.onClose()}
                                onClose={() => this.props.onClose()}
                                onSaveItem={this.onAddItem}
                                isSaving={this.props.isSaving}
                                canBeSaved={this.isValidToSave()}
                            />
                        </Grid>
                    </Grid>

                }>

                <Grid item xs={12}>
                    <Typography variant="caption"> Línea de pedido *</Typography>
                    <Select
                        options={poductMachinedSelector}
                        fullWidth
                        onChange={(productMachined) => { this.updatePropertyProductMachined(productMachined) }}
                        value={this.state.productMachined}
                    />
                    <br />
                    <Typography variant="caption"> {<Translate id="maquilaOrders.sendedMaterialsControl" />} *</Typography>
                    <Select
                        options={materials}
                        fullWidth
                        onChange={(partNumber) => { this.updatePropertyPartNumber(partNumber) }}
                    />
                    <br />
                    <Typography variant="caption"> {<Translate id="maquilaOrders.addCarrier" />} </Typography>
                    <SupplierSelector
                        className={classes.textField}
                        onSupplierSelected={(supplier) => { this.updateProperty("transport", supplier) }}
                    />
                    <TextField fullWidth required
                        id="standard-read-only-input"
                        label={<Translate id="maquilaOrders.requestedQuantity" />}
                        value={this.state.material.quantity}
                        className={classes.textField}
                        name="quantity"
                        type="number"
                        margin="normal"
                        onChange={(event) => { this.updateProperty("quantity", event.target.value) }}
                    />
                </Grid>
            </Dialog>
        )
    }
}

const styles = theme => ({
    modal: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxWidth: 600
    },
    textField: {
        marginBottom: theme.spacing.unit * 2
    },
    checkBox: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxHeight: '25px'
    }
});

export default withRouter(withStyles(styles, { withTheme: true })(AddMaterialModal));