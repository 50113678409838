///Import react section
import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import uuidv4 from 'uuid/v4'
import propTypes from 'prop-types'
import { RViewer, RViewerTrigger } from 'react-viewerjs'

/** Material-UI imports section */
import { Button, Fab, Grid, List, ListItem, ListItemText, Tooltip, Typography, withStyles } from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'
/** Component imports section */
import DataTable from '../../../common/DataTable'
import HtmlEditor from '../../../common/HtmlEditor'
import Evidence from '../../../common/Evidence'
/** Helper imports section */
import {
	backgroundColorDocumentStatus,
	descriptionDocumentStatus,
} from '../../../../store/helpers/SpecificationsHelper'
import { COLOR_CART_PORT } from '../../../../store/helpers/StatusColorConstants'
import { specificationStatus } from '../../../../store/helpers/SpecificationsHelper'

const PackageDetailContent = (props) => {
	const {
		controlChanges,
		isLoading,
		activities,
		authrizations,
		isInternalUser,
		addActivityItem,
		updateItem,
		updateVisualAid,
		classes,
		status,
		deleteActivity,
	} = props

	const [openHtmlEditor, setOpenHtmlEditor] = useState({ open: false, number: 0 })
	const [openVisualAidEditor, setOpenVisualAidEditor] = useState({ open: false, number: 0 })
	const [image, setImage] = useState([])
	let aux = null

	const handelSaveEdit = (number) => {
		if (aux) {
			updateItem(number, aux)
		}
		handelCloseEditor()
	}

	const handelSaveVisualAid = (number) => {
		if (image.length > 0) {
			updateVisualAid(number, image)
		}
		setOpenVisualAidEditor({ open: false, number: 0 })
	}

	const handelOpenEditor = (item) => {
		setOpenHtmlEditor({ open: true, number: item.number })
	}

	const handelCloseEditor = () => {
		setOpenHtmlEditor({ open: false, number: 0 })
		aux = null
	}

	const onHandleChange = (value) => {
		aux = value
	}

	const onRenderCellItemControlChanges = (dataSource, item) => {
		if (dataSource === 'creation_date') {
			return item.creation_date ? moment(item.creation_date, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
		}
		if (dataSource === 'no_warning') {
			return <div dangerouslySetInnerHTML={{ __html: item.no_warning }} />
		}
	}

	const renderEditDescription = (item) => {
		return (
			<>
				{(!openHtmlEditor.open || (openHtmlEditor.open && openHtmlEditor.number !== item.number)) && (
					<>
						{status === specificationStatus.draft && !isInternalUser && (
							<Tooltip title={<Translate id='specificationsSection.package.edit' />}>
								<Fab color='primary' size='small' onClick={() => handelOpenEditor(item)}>
									<Edit />
								</Fab>
							</Tooltip>
						)}
						<div dangerouslySetInnerHTML={{ __html: item.description }} />
					</>
				)}
				{openHtmlEditor.open && openHtmlEditor.number === item.number && (
					<div className={classes.htmlContainer}>
						<div className={classes.htmlEditContainer}>
							<HtmlEditor
								initialValue={item.description ? item.description : ''}
								id={uuidv4()}
								isDisabled={!openHtmlEditor.open}
								onEditorChange={onHandleChange}
							/>
							<Grid container>
								<Grid item xs={12} className={classes.containerBtn}>
									<Button
										data-cy='btn-save'
										type='button'
										color='primary'
										variant='outlined'
										className={classes.saveButton}
										onClick={() => handelSaveEdit(item.number)}
									>
										<Translate id='common.save' />
									</Button>
									<Button
										data-cy='btn-cancel'
										type='button'
										color='secondary'
										variant='outlained'
										className={classes.cancelButton}
										onClick={() => handelCloseEditor()}
									>
										<Translate id='common.cancel' />
									</Button>
								</Grid>
							</Grid>
						</div>
					</div>
				)}
			</>
		)
	}

	const removeFiles = (fileName) => {
		let newImage = image
		let newAttachments = image.filter((file) => {
			return file.name !== fileName
		})
		newImage = newAttachments
		setImage(newImage)
		return Promise.resolve('Ok')
	}

	const uploadFiles = (files) => {
		let fileList = image
		if (files.length > 0) {
			for (var i = 0; i < files.length; i++) {
				fileList.push(files[i])
			}
		}
		setImage(fileList)
		return Promise.resolve('Ok')
	}

	const renderVisualAid = (item) => {
		let urls = item.visual_aid ? getUrlImg(item.visual_aid) : []
		return (
			<>
				{(!openVisualAidEditor.open ||
					(openVisualAidEditor.open && openVisualAidEditor.number !== item.number)) && (
					<Grid container className={classes.editContainer}>
						{item.visual_aid ? (
							<div className={classes.htmlContainer}>
								<RViewer imageUrls={urls}>
									<div className={classes.imgContainer}>
										{urls.map((url, index) => {
											return (
												<RViewerTrigger index={index}>
													<button>
														<img
															srcSet={`${url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
															src={`${url}?w=164&h=164&fit=crop&auto=format`}
															alt={'not found'}
															loading='lazy'
															width={'400px'}
															border='0px'
														/>
													</button>
												</RViewerTrigger>
											)
										})}
									</div>
								</RViewer>
							</div>
						) : (
							<div className={classes.emptyRow}></div>
						)}
						{status === specificationStatus.draft && !isInternalUser && (
							<Tooltip title={<Translate id='specificationsSection.package.edit' />}>
								<Fab
									color='primary'
									size='small'
									onClick={() => setOpenVisualAidEditor({ open: true, number: item.number })}
									className={classes.editFab}
								>
									<Edit />
								</Fab>
							</Tooltip>
						)}
					</Grid>
				)}
				{openVisualAidEditor.open && openVisualAidEditor.number === item.number && (
					<div>
						<Evidence
							text={<Translate id='specificationsSection.package.uploadImage' />}
							removeFiles={removeFiles}
							uploadFiles={uploadFiles}
							files={image}
							acceptedFiles={['images']}
							disableLink
						/>
						<Grid container>
							<Grid item xs={12} className={classes.containerBtn}>
								<Button
									data-cy='btn-save'
									type='button'
									color='primary'
									variant='outlined'
									className={classes.saveButton}
									onClick={() => handelSaveVisualAid(item.number)}
								>
									<Translate id='common.save' />
								</Button>
								<Button
									data-cy='btn-cancel'
									type='button'
									color='secondary'
									variant='outlained'
									className={classes.cancelButton}
									onClick={() => setOpenVisualAidEditor({ open: false, number: 0 })}
								>
									<Translate id='common.cancel' />
								</Button>
							</Grid>
						</Grid>
					</div>
				)}
			</>
		)
	}

	const onRenderCellItemActivities = (dataSource, item) => {
		if (dataSource === 'description') {
			return renderEditDescription(item)
		}
		if (dataSource === 'visual_aid') {
			return renderVisualAid(item)
		}
		if (dataSource === 'number') {
			return (
				<div className={classes.numberContainer}>
					{status === specificationStatus.draft && !isInternalUser && (
						<Tooltip title={<Translate id='common.delete' />} onClick={() => deleteActivity(item.number)}>
							<Delete className={classes.deleteIcon} />
						</Tooltip>
					)}
					{item.number}
				</div>
			)
		}
	}

	const getUrlImg = (value) => {
		const parse = new DOMParser()
		const doc = parse.parseFromString(value, 'text/html')
		const imgElements = doc.querySelectorAll('img')
		const imgUrls = []
		if (imgElements.length > 0)
			imgElements.forEach((imgElement) => {
				imgUrls.push(imgElement.src)
			})
		return imgUrls.length > 0 ? imgUrls : null
	}

	const onRenderCellItemAuthorizer = (dataSource, item) => {
		if (dataSource === 'authorization_date') {
			return item.authorization_date ? moment(item.authorization_date, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
		}
		if (dataSource === 'status') {
			return (
				<Typography variant='caption'>
					<strong
						className={classes.btnStatus}
						style={{ backgroundColor: backgroundColorDocumentStatus(item.status) }}
					>
						{descriptionDocumentStatus(item.status)}
					</strong>
				</Typography>
			)
		}
	}

	return (
		<List>
			<ListItem style={{ backgroundColor: COLOR_CART_PORT }}>
				<ListItemText
					secondary={
						<Typography component='span' variant='body2' color='textPrimary'>
							<strong>
								<Translate id='specificationsSection.guidelines.activities' />
							</strong>
						</Typography>
					}
				/>
			</ListItem>
			{isLoading === false && activities && (
				<>
					<DataTable
						data={activities}
						configuration={ActivitiesTableConfiguration()}
						onRenderCellItem={onRenderCellItemActivities}
					/>
					{status === specificationStatus.draft && !isInternalUser && (
						<div className={classes.addActivityContainer}>
							<Button
								variant='contained'
								type='button'
								color='primary'
								onClick={() => addActivityItem()}
								className={classes.addActivity}
							>
								<Add className={classes.leftIcon} />
								<Translate id='specificationsSection.package.newActivity' />
							</Button>
						</div>
					)}
					<br />
					<br />
				</>
			)}
			{(isInternalUser ||
				(!isInternalUser && authrizations.some((item) => item.status === 3 || item.status === 2))) && (
				<>
					<ListItem style={{ backgroundColor: COLOR_CART_PORT }}>
						<ListItemText
							secondary={
								<Typography component='span' variant='body2' color='textPrimary'>
									<strong>
										<Translate id='specificationsSection.guidelines.authorizations' />
									</strong>
								</Typography>
							}
						/>
					</ListItem>
					{isLoading === false && authrizations && (
						<DataTable
							data={authrizations}
							configuration={AuthorizationsTableConfiguration()}
							onRenderCellItem={onRenderCellItemAuthorizer}
						/>
					)}
				</>
			)}
			{isInternalUser && (
				<>
					<ListItem style={{ backgroundColor: COLOR_CART_PORT }}>
						<ListItemText
							secondary={
								<Typography component='span' variant='body2' color='textPrimary'>
									<strong>
										<Translate id='specificationsSection.guidelines.controlChange' />
									</strong>
								</Typography>
							}
						/>
					</ListItem>
					{isLoading === false && controlChanges && (
						<DataTable
							data={controlChanges}
							configuration={ControlChangesTableConfiguration()}
							onRenderCellItem={onRenderCellItemControlChanges}
						/>
					)}
				</>
			)}
		</List>
	)
}

function ControlChangesTableConfiguration() {
	let columns = [
		{
			header: <Translate id='specificationsSection.guidelines.date' />,
			dataSource: 'creation_date',
		},
		{
			header: <Translate id='specificationsSection.guidelines.description' />,
			dataSource: 'description',
		},
		{
			header: <Translate id='specificationsSection.guidelines.revision' />,
			dataSource: 'revision',
		},
		{
			header: <Translate id='specificationsSection.guidelines.createdBy' />,
			dataSource: 'created_by',
		},
		{
			header: <Translate id='specificationsSection.guidelines.authorize' />,
			dataSource: 'authorized_by',
		},
		{
			header: <Translate id='specificationsSection.guidelines.notice' />,
			dataSource: 'no_warning',
		},
	]
	return { columns }
}

function ActivitiesTableConfiguration() {
	let columns = [
		{
			header: <Translate id='specificationsSection.guidelines.numHeader' />,
			dataSource: 'number',
		},
		{
			header: <Translate id='specificationsSection.guidelines.description' />,
			dataSource: 'description',
		},
		{
			header: <Translate id='specificationsSection.guidelines.visualAid' />,
			dataSource: 'visual_aid',
		},
	]
	return { columns }
}

function AuthorizationsTableConfiguration() {
	let columns = [
		{
			header: <Translate id='specificationsSection.guidelines.statusTitle' />,
			dataSource: 'status',
		},
		{
			header: <Translate id='specificationsSection.guidelines.authorizationDate' />,
			dataSource: 'authorization_date',
		},
		{
			header: <Translate id='specificationsSection.guidelines.comments' />,
			dataSource: 'comment',
		},
	]
	return { columns }
}

PackageDetailContent.propTypes = {
	controlChanges: propTypes.array,
	isLoading: propTypes.bool,
	activities: propTypes.array,
	authrizations: propTypes.array,
	isInternalUser: propTypes.bool,
	addActivityItem: propTypes.func,
	updateItem: propTypes.func,
	updateVisualAid: propTypes.func,
	status: propTypes.number,
	deleteActivity: propTypes.func,
}

PackageDetailContent.defaultProps = {
	controlChanges: [],
	isLoading: false,
	activities: [],
	authrizations: [],
	isInternalUser: false,
	addActivityItem: () => console.warn('[addActivityItem] is not defined!'),
	updateItem: () => console.warn('[updateItem] is not defined!'),
	updateVisualAid: () => console.warn('[updateVisualAid] is not defined!'),
	status: 0,
	deleteActivity: () => console.warn('[deleteActivity] is not defined!'),
}

const styles = (theme) => ({
	btnStatus: {
		fontSize: '106%',
		whiteSpace: 'normal',
		color: 'white',
		padding: '5px',
	},
	htmlContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	media: {
		width: '300px',
		padding: '5px',
	},
	leftIcon: {
		marginRight: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	saveButton: {
		bottom: 0,
		margin: 2,
	},
	cancelButton: {
		color: theme.palette.error.main,
		bottom: 0,
		margin: 2,
	},
	htmlEditContainer: {
		width: '90%',
	},
	editButton: {
		display: 'flex',
		justifyContent: 'end',
	},
	addActivityContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	addActivity: {
		borderRadius: '15px',
		margin: '10px',
		'&:hover': {
			backgroundColor: 'white',
			color: theme.palette.primary.main,
			border: '1px solid',
		},
	},
	editFab: {
		top: '5%',
		left: '0%',
		position: 'absolute',
		'&:hover': {
			backgroundColor: 'white',
			color: theme.palette.primary.main,
			border: '1px solid',
		},
	},
	editContainer: {
		position: 'relative',
	},
	emptyRow: {
		height: '50px',
	},
	imgContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	deleteIcon: {
		width: '25px',
		height: '25px',
		color: theme.palette.error.main,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	numberContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
	},
})

export default withStyles(styles, { withTheme: true })(PackageDetailContent)
