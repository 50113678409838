import React from 'react'
import propTypes from 'prop-types'
import { Popper, ClickAwayListener, Paper } from '@material-ui/core'
import BaseConfiguration from './BaseConfigurator'
import { withStyles } from '@material-ui/core/styles'

/**
 * A data grid configurator contained in a popper
 */
const PopperConfigurator = (props) => {
	const { open, anchorEl,classes } = props
	return (
		<Popper open={open} anchorEl={anchorEl} className={classes.popperZIndex}>
			<ClickAwayListener onClickAway={props.onClickAway}>
				<Paper>
					<BaseConfiguration {...props}  />
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}
PopperConfigurator.propTypes = {
	/**
	 *  It's used to set the position of the popper. The return value will passed as the reference object of the Popper instance.
	 */
	anchorEl: propTypes.object,
	/**
	 * If true, the popper is visible.
	 */
	open: propTypes.bool.isRequired,
	/**
	 * Callback executed when the user clicks away from the popper
	 */
	onClickAway: propTypes.func.isRequired,
	/**
	 * The placement of the popper relative to the anchorel
	 */
	placement: propTypes.oneOf([
		'bottom-end',
		'bottom-start',
		'bottom',
		'left-end',
		'left-start',
		'left',
		'right-end',
		'right-start',
		'right',
		'top-end',
		'top-start',
		'top'
	]),
	/**
	 * The table configuration with visible and index properties
	 */
	configuration: propTypes.arrayOf(
		propTypes.shape({
			title: propTypes.oneOfType(propTypes.string, propTypes.node).isRequired,
			visible: propTypes.bool.isRequired
		})
	).isRequired,
	/**
	 * Callback executed when the user save the configuration
	 */
	onConfigurationChange: propTypes.func.isRequired,
	/**
	 * The max height of the columns configuration container
	 */
	maxHeight: propTypes.string
}
PopperConfigurator.defaultProps = {
	open: false,
	placement: 'bottom',
	maxHeight: '300px',
	configuration: [],
}

const styles = (theme) => ({
	popperZIndex: {
		zIndex: 9999,
	},
})

export default withStyles(styles)(PopperConfigurator)