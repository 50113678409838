/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

/**Import material UI Section*/
import { CardContent, withStyles, Typography } from '@material-ui/core'
import { Card, StepIcon, StepConnector } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import StepLabel from '@material-ui/core/StepLabel'
import CardHeader from '@material-ui/core/CardHeader'
/** Import component section */
import SupplierInformation from './SupplierInformation'
import { FactoringStatus } from '../../../../store/helpers/AppConstants'
import BankInformation from './BankInformation'
import FilesInformation from './Files'
import VoBoSupplier from './VoBoSupplier'
import ConvenantInformation from './ConvenantInformation'
import SignWithBank from './SignWithBank'
import PaymentConfiguration from './PaymentConfiguration'
import { descriptionStatus } from '../../../../store/helpers/FactoringAfiliationHelper'
const StepperHeader = (props) => {
	//Array props section
	const { userRolesByCompany } = props
	const { translate, classes, factoring } = props
	//Functins props
	const { onUpdateFactoring, onAddAttachmets, onDeleteFactoringsFiles, onDownloadFactoringFile, SynchronizeSapFactoring, onSendNotificationToSupplier } = props

	//bool props section
	const { isInternalUser, isSaving, isSavingNotification } = props
	const [activeStep, setActiveStep] = React.useState(0)

	const [completed] = React.useState(new Set())
	const [skipped] = React.useState(new Set())

	/**
	 * Function to get steps
	 */
	const getSteps = () => {
		let steps = []
		steps = [
			translate('factoringAffiliation.steps.informationSupplier'),
			translate('factoringAffiliation.steps.bankInformation'),
			translate('factoringAffiliation.steps.files'),
			translate('factoringAffiliation.steps.voboSupplier'),
			translate('factoringAffiliation.steps.paymenConfiguration'),
			translate('factoringAffiliation.steps.convenant'),
			translate('factoringAffiliation.steps.signingBank')
		]
		if(!isInternalUser){
			steps.splice(4,1)
		}
		return steps
	}

	const getStepContent = (step, factoring) => {
		let canBeEditInformation = factoring && factoring.FactoringStatus === FactoringStatus.DRAFT
		switch (step) {
			case 0:
				return (
					<SupplierInformation
						//array props section
						userRolesByCompany={userRolesByCompany}
						//int props section
						activeStep={activeStep}
						//Object props section
						factoring={factoring}
						//Function props section
						onUpdateFactoring={onUpdateFactoring}
						//bool props section
						isInternalUser={isInternalUser}
						canBeEditInformation={canBeEditInformation}
						isSaving={isSaving}
					/>
				)
			case 1:
				return (
					<BankInformation
						//array props section
						userRolesByCompany={userRolesByCompany}
						//int props section
						activeStep={activeStep}
						//Object props section
						factoring={factoring}
						//Function props section
						onUpdateFactoring={onUpdateFactoring}
						onAddAttachmets={onAddAttachmets}
						onDeleteFactoringsFiles={onDeleteFactoringsFiles}
						onDownloadFactoringFile={onDownloadFactoringFile}
						//bool props section
						isInternalUser={isInternalUser}
						canBeEditInformation={canBeEditInformation}
						isSaving={isSaving}
					/>
				)
			case 2:
				return (
					<FilesInformation
						//array props section
						userRolesByCompany={userRolesByCompany}
						//int props section
						activeStep={activeStep}
						//Object props section
						factoring={factoring}
						//Function props section
						onUpdateFactoring={onUpdateFactoring}
						onAddAttachmets={onAddAttachmets}
						onDeleteFactoringsFiles={onDeleteFactoringsFiles}
						onDownloadFactoringFile={onDownloadFactoringFile}
						//bool props section
						isInternalUser={isInternalUser}
						canBeEditInformation={factoring && factoring.FactoringStatus === FactoringStatus.IN_PROCESS}
						isSaving={isSaving}
					/>
				)
			case 3:
				return (
					<VoBoSupplier
						//array props section
						userRolesByCompany={userRolesByCompany}
						//int props section
						activeStep={activeStep}
						//Object props section
						factoring={factoring}
						//Function props section
						onUpdateFactoring={onUpdateFactoring}
						//bool props section
						isInternalUser={isInternalUser}
						isSaving={isSaving}
					/>
				)
			case 4:
				return (
					isInternalUser ? 
					<PaymentConfiguration
						//array props section
						userRolesByCompany={userRolesByCompany}
						//int props section
						activeStep={activeStep}
						//Object props section
						factoring={factoring}
						//Function props section
						onUpdateFactoring={onUpdateFactoring}
						//bool props section
						isInternalUser={isInternalUser}
						isSaving={isSaving}
					/> :
					<ConvenantInformation
						//array props section
						userRolesByCompany={userRolesByCompany}
						//int props section
						activeStep={activeStep}
						//Object props section
						factoring={factoring}
						//Function props section
						onUpdateFactoring={onUpdateFactoring}
						SynchronizeSapFactoring = {SynchronizeSapFactoring}
						isSavingNotification ={isSavingNotification}
						//bool props section
						isInternalUser={isInternalUser}
						isSaving={isSaving}
						onSendNotificationToSupplier = {onSendNotificationToSupplier}
					/> 

				)
			case 5:
				return (
					isInternalUser ? 
					<ConvenantInformation
						//array props section
						userRolesByCompany={userRolesByCompany}
						//int props section
						activeStep={activeStep}
						//Object props section
						factoring={factoring}
						//Function props section
						onUpdateFactoring={onUpdateFactoring}
						SynchronizeSapFactoring = {SynchronizeSapFactoring}
						onSendNotificationToSupplier = {onSendNotificationToSupplier}
						//bool props section
						isInternalUser={isInternalUser}
						isSaving={isSaving}
						isSavingNotification ={isSavingNotification}
					/>  :
					<SignWithBank
						//array props section
						userRolesByCompany={userRolesByCompany}
						//int props section
						activeStep={activeStep}
						//Object props section
						factoring={factoring}
						//Function props section
						onUpdateFactoring={onUpdateFactoring}
						//bool props section
						isInternalUser={isInternalUser}
						isSaving={isSaving}
					/>
				)
			case 6:
				return (
					isInternalUser ? 

					<SignWithBank
						//array props section
						userRolesByCompany={userRolesByCompany}
						//int props section
						activeStep={activeStep}
						//Object props section
						factoring={factoring}
						//Function props section
						onUpdateFactoring={onUpdateFactoring}
						//bool props section
						isInternalUser={isInternalUser}
						isSaving={isSaving}
					/> :<></>
				)
			default:
				return translate('factoringAffiliation.steps.noExistStep')
		}
	}

	const onChangeStep = (step) => () => {
		setActiveStep(step)
	}

	const isStepSkipped = (step) => {
		return skipped.has(step)
	}

	const isStepComplete = (step) => {
		return completed.has(step)
	}

	/**
	 * Action to get active steper
	 * @returns
	 */
	const getSteperActived = () => {
		const steps = [];
		if(factoring.FactoringStatus === FactoringStatus.DRAFT)
				steps.push(0,1);
		else if(factoring.FactoringStatus === FactoringStatus.IN_PROCESS)		
			steps.push(0,1,2);
		else if(factoring.FactoringStatus === FactoringStatus.VALIDATION_SUPPLIER || factoring.FactoringStatus === FactoringStatus.REJECTED_BY_SUPPLIER)
			steps.push(0,1,2,3);
		else if(factoring.FactoringStatus === FactoringStatus.PAYMENT_SETUP_COMPLETATION){
			steps.push(0,1,2,3,4);
			if(!isInternalUser)
				steps.pop();
		}
		else{
			steps.push(0,1,2,3,4,5,6);
			if(!isInternalUser)
				steps.pop();
		}

		return (
			<>
				{' '}
				<Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorLibConnector />}>
					{getSteps().map((label, index) => {
						const stepProps = {}
						if (isStepSkipped(index)) {
							stepProps.completed = false
						}
						return (
							<Step key={label}>
								{steps.includes(index)? (
									<StepButton onClick={onChangeStep(index)} completed={isStepComplete(index)}>
										{label}{' '}
									</StepButton>
								) : (
									<StepLabel StepIconComponent={IconComponent}>{label}</StepLabel>
								)}
							</Step>
						)
					})}
				</Stepper>
			</>
		)
	}

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar aria-label='recipe' className={classes.avatar}>
						AF
					</Avatar>
				}
				action={
					<>
						<Typography className={classes.textTitle}>Estatus</Typography>
						{factoring && descriptionStatus(factoring.FactoringStatus)}
					</>
				}
				title='Afiliación Factoraje'
				subheader={factoring ? factoring.CreatedBy.CreatedBy : '-'}
			/>

			<div className={classes.root}>
				{factoring && getSteperActived()}
				<CardContent>{getStepContent(activeStep, factoring)}</CardContent>
			</div>
		</Card>
	)
}

const IconComponent = withStyles((theme) => ({
	root: {
		color: '#EBEEF7',
		'&$active': {
			backgroundColor: '#6369D7',
			color: '#6369D7',
		},
		'&$completed': {
			backgroundColor: '#6369D7',
			color: '#6369D7',
		},
	},
	active: {},
	completed: {},
}))(StepIcon)

const ColorLibConnector = withStyles((theme) => ({
	alternativeLabel: {
		top: 15,
	},
	active: {
		'& $line': {
			backgroundColor: '#6369D7',
			color: '#6369D7',
		},
	},
	completed: {
		'& $line': {
			backgroundColor: '#464A97',
			color: '#6369D7',
		},
	},
	line: {
		backgroundColor: '#EBEEF7',
		color: '#6369D7',
	},
}))(StepConnector)

const styles = (theme) => ({
	root: {
		width: '100%',
	},
	button: {
		marginRight: 0,
	},
	backButton: {
		marginRight: 0,
	},
	completed: {
		display: 'inline-block',
	},
	instructions: {
		marginTop: 0,
		marginBottom: 0,
	},
	textTitle: {
		fontWeight: 'bold',
	},
})

StepperHeader.propTypes = {
	isLoading: PropTypes.bool.isRequired,
}

StepperHeader.defaultProps = {
	isLoading: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(StepperHeader))
