import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import moment from 'moment'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import QualityFailureReportIcon from  '@material-ui/icons/WarningOutlined';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Toaster from '../common/Toaster';
import FormView from './components/FormView';

import { IsNullOrEmpty } from '../../store/helpers/StringHelper';
import { converListToArray } from '../../store/helpers/ListHelper'
import {createQualityFailureReportFromBackEnd} from '../../store/qualityFailureReport/QualityFailureReportAction';
import { showErrorToaster } from '../../store/helpers/ToasterHelpers';

/**
 * Component to create corrective action supplier
 */
class Create extends Component{

     /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            creationDate: moment().format('DD/MM/YYYY'),
        }
    }


    /**
     * Get action to create quality failure report
     */
    createdQualityFailureReport(supplier, preparedBy, partNumber, failure, reportQuality){
        this.props.createQualityFailureReport(supplier, preparedBy, partNumber, failure, reportQuality)
        .then((reportQuality) =>{
            this.setState({
                showToView:true,
                showToaster: false
            })
            this.props.history.push(`/qualityfailurereport/${reportQuality.token}`)
        }).catch((error) =>{
            this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : "common.errorToSave"));
        });
    }

    render(){
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <QualityFailureReportIcon />
                    </Avatar>
                }
                title={<Typography variant="button" ><Translate id="qualityfailurereport.titleReport"/></Typography>}
                subheader={<Typography variant="caption" color="textPrimary"><Translate id="common.createBy"/>: {this.props.user.profile.name}
                 <br/><Translate id="common.creationDate"/>: {this.state.creationDate}</Typography>}
            />
            <CardContent>
                <FormView  
                    {...this.props}
                    isSaving = {this.props.isSaving}
                    canBeCreate={this.createdQualityFailureReport}
                 />
            </CardContent>

            <Toaster
                message={<Translate id={this.state.toasterMessage} />}
                open={this.state.showToaster}
                variant={this.state.toasterVariant}
                onClose={() => { this.setState({ showToaster: false }) }}
            />

        </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    let selectedCompany = state.profile.get('selectedCompany');
    return {
        user: user,
        isSaving: state.rawMaterialNonConformity.get('isSaving'),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
        selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        createQualityFailureReport:(supplier, preparedBy, partNumber, failure, reportQuality) => {
            return dispatch(createQualityFailureReportFromBackEnd(supplier, preparedBy, partNumber, failure, reportQuality)) 
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(Create))));