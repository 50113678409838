///Import react section
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/** Language imports **/
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Toaster from '../../common/Toaster';
import TextField from '@material-ui/core/TextField';


/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';

/**
 * Component to add or edit worker
 */
class DialogEditFailureGuarantee extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
        }
    }

    // canBeSaved() {
    //     if(!this.state.problemDescription) return false;
    //     return true;
    // }

    render() {
        const { classes } = this.props;
        let failureGuarantee= this.props.failureGuarantee;
        if (failureGuarantee !== null) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.onClose}
                    header={
                        <Toolbar >
                            <Typography variant="h6">
                                {this.props.title}
                            </Typography>
                        </Toolbar>
                    }
                    actions={
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={this.props.onClose}
                            onClose={this.props.onClose}
                            onSaveItem={() => this.props.updateFailureGuaranteeData()}
                            isSaving={this.props.isSaving}
                            canBeSaved={true}
                        />
                    }>

                    <Grid container spacing={24}>
                        <Grid item xs={12} >
                            <div className={classes.textField} >
                                <TextField fullWidth autoFocus
                                    // error={this.props.validateData("name")}
                                    label={<Translate id="moduleGuarantee.name" />}
                                    defaultValue={failureGuarantee.name}
                                    onChange={(event) => { this.props.updateProperty("name", event.target.value) }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            <div className={classes.textField} >
                                <TextField fullWidth autoFocus
                                    label={<Translate id="moduleGuarantee.nameEnglish" />}
                                    defaultValue={failureGuarantee.name_english}
                                    onChange={(event) => { this.props.updateProperty("name_english", event.target.value) }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            <div className={classes.textField} >
                                <TextField fullWidth autoFocus
                                    label={<Translate id="moduleGuarantee.namePortuguese" />}
                                    defaultValue={failureGuarantee.name_portuguese}
                                    onChange={(event) => { this.props.updateProperty("name_portuguese", event.target.value) }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Toaster
                        message={this.state.toasterMessage}
                        open={this.state.showToaster}
                        variant={this.state.toasterVariant}
                        onClose={() => { this.setState({ showToaster: false }) }}
                    />
                </Dialog>
            )
        }
        else {
            return "";
        }

    }

}

const dialogStyles = theme => ({
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginRight: theme.spacing.unit,
    },
    textAreaField: {
        marginRight: theme.spacing.unit,
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(DialogEditFailureGuarantee));
