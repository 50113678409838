import moment from 'moment';
import { capitalize } from '../../../../store/helpers/StringHelper';

export const buildFilterConfig = (dataSource) => {
	let type = '';
	let name = '';
	let queryStringKey;
	let customOptions = [];
	const url = `${process.env.REACT_APP_CONCILIATION_BASE_API}/paymentrequests/filtervalues`;
	const entityName = 'PaymetRequest'; 
	let mapOption = null;
	switch (dataSource) {
		case 'age': {
			queryStringKey = 'CloseDate';
			type = 'DATE';
			name = '';//'Antigüedad';
			const now = moment();
			customOptions = [
				{
					parentDescription: 'HOY',
					key: 'today',
					filterValues: [moment().format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]
				},
				{
					parentDescription: 'UNA SEMANA ATRÁS',
					key: 'this-week',
					filterValues: [moment().subtract(1, 'weeks').format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]
				},
				{
					parentDescription: 'UN MES ATRÁS',
					key: 'this-month',
					filterValues: [moment().subtract(1, 'months').format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]
				},
				{
					parentDescription: 'UN TRIMESTRE ATRÁS',
					key: 'this-trimester',
					filterValues: [moment().subtract(3, 'months').format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]
				},
				{
					parentDescription: 'UN SEMESTRE ATRÁS',
					key: 'this-six-months',
					filterValues: [moment().subtract(6, 'months').format('YYYY-MM-DD'), now.format('YYYY-MM-DD')]
				},
				{
					parentDescription: 'MÁS ANTIGÜAS',
					key: 'older',
					filterValues: ['2010-01-01', now.subtract(6, 'months').format('YYYY-MM-DD')]
				}
			];
			break;
		}
		case 'documentNumber': {
			type = 'TEXT';
			name = 'Documento';//<Translate id="accreditedWorkshops.osDate" />;
			break;
		}
		case 'folio': {
			type = 'TEXT';
			name = 'Folio';//<Translate id="accreditedWorkshops.serviceOrderId" />;
			break;
		}
		case 'factura': {
			type = 'TEXT';
			name = 'Folio';//<Translate id="accreditedWorkshops.serviceOrderId" />;
			break;
		}
		case 'creditNoteFolio': {
			type = 'TEXT';
			name = 'Nota de crédito';//<Translate id="accreditedWorkshops.serviceOrderId" />;
			break;
		}
		case 'creationDate': {
			type = 'DATE';
			name = 'Fecha de creación';//<Translate id="accreditedWorkshops.osDate" />;
			break;
		}
		case 'payDate': {
			queryStringKey = 'PaymentDate';
			type = 'DATE';
			name = 'Fecha de estatus';//<Translate id="accreditedWorkshops.osDate" />;
			break;
		}
		case 'status': {
			type = 'TEXT';
			name = 'Estatus';//<Translate id="accreditedWorkshops.serviceOrderId" />;
			mapOption = (item) => {
				let description = '';
				switch (item.key) {
					case '11':
						description = "Enviada a SAP";
						break;
					case '12':
						description = "Documento generado";
						break;
					case '18':
						description = "Pagado";
						break;
					case '21':
						description = "Compensado";
						break;
					case '51':
						description = "Cancelado";
						break;
					case '22':
						description = "Preeliminar generado";
						break;
					case '23':
						description = "Preeliminar candelado";
						break;
					case '16':
						description = "Error de envío";
						break;
					
					default:
						break;
				}
				item.label = description;
				return item;
			}
			break;
		}
		case 'serviceOrdersNumber': {
			type = 'NUMBER';
			name = 'Número de órdenes de servicio';//"accreditedWorkshops.serviceOrderId";
			break;
		}
		case 'totalAmount': {
			type = 'NUMBER';
			name = 'Monto total';//<Translate id="accreditedWorkshops.serviceOrderId" />;
			break;
		}
		case 'workshopCode': {
			type = 'TEXT';
			name = 'Proveedor';//<Translate id="accreditedWorkshops.osDate" />;
			break;
		}
		default: {
			console.warn(`Configuration for ${dataSource} not found!`);
			return {};
		}
	}
	return {
		filterId: capitalize(dataSource),
		queryStringKey: !queryStringKey ? capitalize(dataSource) : queryStringKey,
		type,
		name,
		url,
		entityName,
		customOptions,
		mapOption
	};
};

export const getAdvancedFilterOptions = (type) => {
	switch (type) {
		case 'TEXT': {
			return [
				{
					key: 'submenu-item-1',
					criteria: FilterCriteria.CONTAINS_OR_EQUALS,
					description: 'Contiene...'
				}
			];
		}
		case 'NUMBER': {
			return [
				{
					key: 'submenu-item-1',
					criteria: FilterCriteria.GREATER_THAN,
					description: 'Mayor que...'
				},
				{
					key: 'submenu-item-2',
					criteria: FilterCriteria.GREATER_OR_EQUAL_THAN,
					description: 'Mayor o igual que...'
				},
				{
					key: 'submenu-item-3',
					criteria: FilterCriteria.LESS_THAN,
					description: 'Menor que...'
				},
				{
					key: 'submenu-item-4',
					criteria: FilterCriteria.LESS_OR_EQUAL_THAN,
					description: 'Menor o igual que...'
				},
				{
					key: 'submenu-item-5',
					criteria: FilterCriteria.BETWEEN,
					description: 'Entre...'
				}
			];
		}
		case 'DATE': {
			return [
				{
					key: 'submenu-item-1',
					criteria: FilterCriteria.GREATER_THAN,
					description: 'Mayor a...'
				},
				{
					key: 'submenu-item-2',
					criteria: FilterCriteria.LESS_THAN,
					description: 'Menor a...'
				},
				{
					key: 'submenu-item-3',
					criteria: FilterCriteria.BETWEEN_DATES,
					description: 'Entre fechas...'
				}
			];
		}
		default: {
			return [];
		}
	}
};

export const getAdvancedFilterDescription = (type) => {
	switch (type) {
		case 'TEXT': {
			return 'Filtros de texto';
		}
		case 'NUMBER': {
			return 'Filtros de número';
		}
		case 'DATE': {
			return 'Filtros de fecha';
		}
		default: {
			return 'Filtros avanzados';
		}
	}
};

export const FilterCriteria = {
	UNDEFINED: 0,
	EQUAL: 1,
	BETWEEN: 2,
	ANY: 3,
	NOT_EQUAL: 4,
	CONTAINS_OR_EQUALS: 5,
	BETWEEN_DATES: 6,
	GREATER_THAN: 7,
	LESS_THAN: 8,
	GREATER_OR_EQUAL_THAN: 9,
	LESS_OR_EQUAL_THAN: 10,
	NUMERIC_EQUAL: 11,
	OR_EQUAL: 12
};

export const FilterTypes = {
    BRANCH_OFFICE: 'BranchOffice.Id',
    CATEGORY: 'Category',
    CURRENCY: 'Currency',
    DISTRIBUTOR: 'Distributor.Id',
    MODEL: 'Model',
    PRODUCT_TYPE: 'Product',
    SERVICE_COST: 'ServiceCost',
    SERVICE: 'ServiceDescription',
    SERVICE_TYPE: 'ServiceTypeId',
    ANOTHER_COST: 'AnotherCost',
    MATERIALS_COST: 'MaterialsCost',
    MATERIALS_GCM_COST: 'MaterialsGCMCost',
    MATERIALS_GFB_COST: 'MaterialsGFBCost',
    TOTAL_MATERIALS: 'TotalMaterials',
    WARRANTY: 'Warranty'
} 

export const buildSearchOrdersCommand = (queryStr) => {};
