
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import RawMaterialNonConfIcon from '@material-ui/icons/BugReport';
import QualityFailureReportIcon from '@material-ui/icons/WarningOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Tooltip from '@material-ui/core/Tooltip';

/** section import components */
import DataTable from '../../common/DataTable';
import AddCommonItem from '../../common/AddCommonItem';

import {canUserValideModuleQuality} from '../../../store/helpers/RolesHelper';

/**
 * Component ot render Information partialraw material not conformiti
 */
export default class InformationPartial extends Component {

    /**
     * Create an instance RawMaterial not conformity
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openDialogRemove: false,
            tokenRemove: "",
        }
    }

      /**
     * onRemoveFolio
     */
    onRemoveFolio(tokenRemove) {
        this.setState({
            openDialogRemove: true,
            tokenRemove: tokenRemove,
        })
    }

    /**
    * Function on remove folio cancel
    */
   onRemoveFolioCancel() {
       if(this.props.removeNonConformityFromCancel){
        this.props.removeNonConformityFromCancel(this.props.sacQuality.token, this.state.tokenRemove)
            .then(() => {
                this.setState({
                    openDialogRemove: false,
                    tokenRemove: "",
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
        }
    }   


    /**
    * Onrender items deriver workflows
    */
    onRenderItemDeriverdWorkFlows(dataSource, item) {
        if (dataSource === "workflow_type") {
            if (item.workflow_type === 1) {
                return (<React.Fragment key={item.id} >
                    <span onClick={() => { this.props.history.push(`/rawmaterialnonconformity/${item.token}`) }} >
                    <Typography color="primary" gutterBottom><RawMaterialNonConfIcon /> {item.folio}</Typography></span>
                </React.Fragment>)
            }
            if (item.workflow_type === 2) {
                return (<React.Fragment key={item.id} >
                    <span onClick={() => { this.props.history.push(`/qualityfailurereport/${item.token}`) }} >
                    <Typography color="primary" gutterBottom><QualityFailureReportIcon /> {item.folio}</Typography></span>
                </React.Fragment>)
            }
        }
        if(dataSource === "token"){
            if(item.is_canceled && canUserValideModuleQuality(this.props.userRolesByCompany)){
                return(<React.Fragment key={item.token} >
                    <Tooltip title= {<Translate id="common.delete"/>}>
                        <span ><DeleteOutlinedIcon onClick={() => {this.onRemoveFolio(item.token)}}
                        style={{zIndex: '990', cursor: "pointer" }} color="error" /> </span>
                    </Tooltip>
                </React.Fragment>)
            }
            else{
                return(".")
            }
        }
    }

    /**
     * Render
     */
    render() {
        const { classes } = this.props;
        let document = null;
        if (this.props.sacQuality.documents.length !== 0) {
            document = this.props.sacQuality.documents[0];
        }
        return (
            <Paper> <Divider />
                <List>
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="common.supplier" />
                                    </Typography>
                                    {": "}{this.props.sacQuality.full_supplier}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="common.partNumber" />
                                    </Typography>
                                    {": "}{this.props.sacQuality.full_part_description ? this.props.sacQuality.full_part_description : ''}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="common.supplierDevelopmentEngineer" />
                                    </Typography>
                                    {": "}{!document && this.props.sacQuality.development_engineer_non_conformity}
                                    {document && document.development_engineer_name}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    {this.props.sacQuality.failure_non_conformity && <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="common.failure" />
                                    </Typography>
                                    {": "}{this.props.sacQuality.failure_non_conformity && this.props.sacQuality.failure_non_conformity.name}
                                </React.Fragment>
                            } />
                    </ListItem>}
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="rawmaterialnonconformity.descriptionBrach" />
                                    </Typography>
                                    {": "}{this.props.sacQuality.problem_description && this.props.sacQuality.problem_description}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                </List>
                {/* List from corrective Actions  */}
                {this.props.sacQuality.derived_workflows.length > 0 && <span><br />
                    <Typography gutterBottom variant="body2"><strong>{<Translate id="sacsQuality.history" />}</strong>  </Typography>
                    <DataTable
                        data={this.props.sacQuality.derived_workflows}
                        configuration={TableDerivedWorkflows}
                        onRenderCellItem={this.onRenderItemDeriverdWorkFlows}
                    />
                </span>
                }

                {/** Dialog to AddCommonItem remove folio cancel */}
                < AddCommonItem
                    title={<Translate id="sacsQuality.removeFolio"/>}
                    message={<Translate id="sacsQuality.removeFolioMessage"/>}
                    open={this.state.openDialogRemove}
                    onClose={() => { this.setState({ openDialogRemove: false }) }}
                    onSaveItem={this.onRemoveFolioCancel}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                />

            </Paper>
        );
    }
}

const TableDerivedWorkflows = {
    columns: [{
        header: <Translate id="common.folio" />,
        dataSource: 'workflow_type'
    }, {
        header: <Translate id="common.creationDate" />,
        dataSource: 'creation_date'
    }, {
        header: <Translate id="common.createBy" />,
        dataSource: 'create_by'
    }, {
        header: <Translate id="common.supplier" />,
        dataSource: 'full_supplier'
    }, {
        header: <Translate id="common.status" />,
        dataSource: 'actual_operation_description'
    }, {
        header: "",
        dataSource: 'token'
    }]
}


/**
 * sacQuality
 */
InformationPartial.propTypes = {
    sacQuality: PropTypes.object.isRequired
}