/**React import section */
import React,{Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

/** Component import section */
import {IsCurrentActiveDirectoryUser} from '../store/helpers/SessionHelper';
import IndexInternalUser from './MainInternalUserView';
import ExternalUserView from './MainExternalUserView';

/** Resources select import **/

/**
 * Class implement main Conteiner home
 */
class Index extends Component{

    /**
     * Render view to internal user or external user
     */
    render(){
      let oidc = this.props.oidc;
      if(IsCurrentActiveDirectoryUser(oidc.user)){ 
            return <IndexInternalUser {...this.props} />
    }   
        if(!IsCurrentActiveDirectoryUser(oidc.user)){
            return  <ExternalUserView {...this.props} />
        }
    }
}

/**
 *
 * Defines the properties injecteded from the state to App props
 *
 * @param {object} state
 * @returns
 */
function mapStateToProps(state) {
    return {
        oidc: state.oidc,
    }
}
export default withRouter((connect(mapStateToProps, null)(Index)));


