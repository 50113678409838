///Import react section
import React, { Component } from 'react';

/** Material-UI imports section */
import { Translate } from "react-localize-redux";

/** Import component section */
import DataTable from '../common/DataTable';

class IndexMolds extends Component {

    /**
    * Get the card content
    *
    * @memberof Index
    */
   getCardContent() {
        return (
            <DataTable
                data={this.props.molds}
                configuration={TableConfiguration()}
            />
        )
    }

    render() {
        return this.getCardContent()
    }
};

function TableConfiguration(isDisabled) {
    let columns = [
        {
            header: <Translate id="common.creationDate" />,
            dataSource: 'status_indicator',
        }, {
            header: <Translate id="common.code" />,
            dataSource: 'actual_operation_description',
        },{
            header: <Translate id="maquilaOrders.name" />,
            dataSource: 'required_plant_date'
        }]

    return ({ columns });
}


export default (IndexMolds);