import React, { Component } from 'react';
import { connect } from 'react-redux'
import autoBind from 'auto-bind';
import {Translate} from "react-localize-redux";
import Select from 'react-select'

import { searchByFailureNonConformityByQuery } from '../../store/administration/NonConformitiesActions';


/**
 * Failure Non Conformity selector container
 *
 * @class FailureNonConformitySelector
 * @extends {Component}
 */
class FailureNonConformitySelector extends Component {

    /**
     *Creates an instance of FailureNonConformitySelector.
     * @param {*} props
     * @memberof FailureNonConformitySelector
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            isLoading: true,
            nonConformities: [],
            failure: this.props.failure
        }
    }

          /**
     * componentDidMount
     */
    componentDidMount() {
        if (!this.props.isLoadingUser) {
            this.props.searchByFailureNonConformity()
            .then((nonConformities) => {
                let conformities = nonConformities.map((conformity) =>{
                    return {
                        value: conformity.token,
                        label: conformity.name
                    }
                })
                this.setState({
                    nonConformities:conformities
                })
                return nonConformities.map((nonConformity) => {
                    return {
                        value: nonConformity.token,
                        label: nonConformity.name
                    }
                })
            })
        }
    }
  
    /**
     * On select a FailureNonConformitySelector 
     *
     * @param {*} option
     * @memberof FailureNonConformitySelector
     */
    onSelectedNonConformityClick(option) {
        if(this.state.nonConformities){
            let selectedNonConformity = this.state.nonConformities.find((nonConformity) => {
                return nonConformity.value === option.value
            })
            if (this.props.onNonConformitySelected) {
                this.props.onNonConformitySelected(selectedNonConformity)
            }
        }
    }
  
    /**
     *  
     *
     * @returns
     * @memberof FailureNonConformitySelector
     */
    render() {
        return (
            <Select 
                placeholder={<Translate id="qualityfailurereport.searchFailure" />}
                defaultValue={this.state.failure}
                options={this.state.nonConformities}
                onChange={this.onSelectedNonConformityClick}
                isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
                styles={{
                    menu: (base) => ({ ...base, zIndex: 31 })
                  }}
            />
        );
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    return {
        isLoadingUser: state.oidc.isLoadingUser,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        searchByFailureNonConformity:() => {
            return dispatch(searchByFailureNonConformityByQuery())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FailureNonConformitySelector);