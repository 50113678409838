///Import react section
import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import { CardHeader, Typography, Fab } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/MenuOutlined'
import { CreditCard } from '@material-ui/icons'
import { Translate } from 'react-localize-redux'

/** Import component section */
import yourlogohere from '../../../resources/images/logo_ptm.png'

/** Helpers */
import { isActiveAvailableOperations } from '../../../store/helpers/SparePartPriceChangeHelper'
import { sparePartPriceChangeRequestStatus } from '../../../store/helpers/AppConstants'

const HeaderRequest = (props) => {
	/**Render hooks section */
	const { requestPriceChange, onDialogOperation, isInternalUser, onDownloadPDF, isSaving } = props
	const { classes } = props

	return (
		<CardHeader
			avatar={<img src={yourlogohere} alt='logo' className={classes.logoImg} />}
			title={
				<Typography variant='button'>
					{'Solicitud de refacciones de precios: '}
					<strong>{requestPriceChange && requestPriceChange.folio ? requestPriceChange.folio : ''}</strong>
				</Typography>
			}
			subheader={
				<Typography variant='caption' display='block'>
					{
						<strong>
							{'Fecha de creación: '}
							{requestPriceChange && requestPriceChange.creationDate}
						</strong>
					}{' '}
					<br />
					{'Solicitante: '} {requestPriceChange && requestPriceChange.createdBy} <br />
					{requestPriceChange && `${requestPriceChange.supplierNumber} | ${requestPriceChange.supplierName}`}
					<br />
				</Typography>
			}
			action={
				<div className={classes.actionsToolbar}>
					{requestPriceChange &&
						requestPriceChange.status !== sparePartPriceChangeRequestStatus.DRAFT &&
						isInternalUser && (
							<Fab
								variant='extended'
								size='small'
								color='secondary'
								className={classes.margin}
								onClick={onDownloadPDF}
								disabled={isSaving}
							>
								<CreditCard />
								<Typography style={{ color: '#fff' }} variant='caption'>
									{isSaving ? (
										<Translate id='common.downloadingReport' />
									) : (
										<Translate id='common.generatePDFCom' />
									)}
								</Typography>
							</Fab>
						)}
					{requestPriceChange && isActiveAvailableOperations(requestPriceChange.status, isInternalUser) && (
						<Fab
							variant='extended'
							size='small'
							color='primary'
							className={classes.margin}
							onClick={onDialogOperation}
						>
							<MenuIcon className={classes.extendedIcon} />
							{<Translate id='common.AvailableOperations' />}
						</Fab>
					)}
				</div>
			}
		/>
	)
}

HeaderRequest.propTypes = {
	requestPriceChange: propTypes.object,
	onDownloadPDF: propTypes.func,
}

HeaderRequest.defaultProps = {
	isSaving: false,
	onDownloadPDF: () => console.warn('Callback [onDownloadPDF] no defined'),
}

const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
	},
	margin: {
		margin: theme.spacing.unit,
	},
	logoImg: {
		maxHeight: 85,
	},
})
export default withRouter(withStyles(styles, { withTheme: true })(HeaderRequest))
