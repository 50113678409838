/** Import ReactJs section */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { renderToStaticMarkup } from 'react-dom/server'
import { getTranslate } from 'react-localize-redux'
import { Translate } from 'react-localize-redux'
import Toaster from '../common/Toaster'
/** Import MaterialUI section */
import { withStyles } from '@material-ui/core/styles'
import { converListToArray } from '../../store/helpers/ListHelper'

import { Typography, Grid, Card, CardHeader, CardContent, Button } from '@material-ui/core'
// import componets section
import StepOneValidateData from './components/externalUser/StepOneValidateData'
import ShowDesktopViewExternalUser from './components/ShowDesktopViewExternalUser'
import StepTwoTermsAndConditions from './components/externalUser/StepTwoTermsAndConditions'
import { withLocalize } from 'react-localize-redux'
import ApplicationBar from '../layouts/AnonymousApplicationBar'
import FooterContainer from '../layouts/FooterContainer'
import ShowEdit from './ShowEdit'
/**Import actions sections */
import {
	updateStepSupplierExternal,
	loadBiddingFromBackEnd,
	addResponseSupplierExternal,
	changeStatusSupplierExternalFromBackEnd,
	onAddQuestionSupplierExternalFromBackEnd,
	onAddAnswerSupplierExternalFromBackEnd,
	isSentProposalExternalEnabled,
	addTermsAndConditionsFromBackend,
	loadBiddingBySupplierExternalCode,
	loadDivisas,
	addDivisaInBiddingResultSupplierExternalFromBackEnd,
	downloadMaterialsReportSupplierExternal,
	loadIncoterms,
	downloadResultBiddingReport,
	setMaterialStatusBySupplierExternal,
} from '../../store/biddings/BiddingsActions'
/**Import resources section */
import { supplierAccepTermsAndConditions } from '../../store/helpers/BiddingHelper'
import translations from '../../resources/translations.json'
import ImberaLogo from '../../resources/images/header-imbera.png'

/**
 * RegisterProspect
 *
 * @class RegisterProspect
 * @extends {Component}
 */
class ProposalExternal extends Component {
	/**
	 *Creates an instance of RegisterProspect.
	 * @param {*} props
	 * @memberof RegisterProspect
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			statusDocument: 1,
			activeTab: 0,
			isValid: true,
			canBeHeader: false,
			canSeeBiddingInformation: false,
			showToaster: false,
			toasterMessage: '',
			toasterVariant: '',
			uploadMaterialsPricesConfigPanel: {
				opened: false,
			},
			addResponseSupplierConfigPanel: {
				opened: false,
				item: null,
			},
			addResponseGralSupplierConfigPanel: {
				opened: false,
				item: null,
			},
			addCommentsSupplierConfigPanel: {
				opened: false,
				supplier: null,
			},
		}

		let defaultLanguage = localStorage.getItem('language')
		if (!defaultLanguage) {
			defaultLanguage = 'es'
		}
		this.props.initialize({
			languages: [
				{ name: 'EspaÃ±ol', code: 'es' },
				{ name: 'English', code: 'en' },
				{ name: 'Portugues', code: 'pt' },
			],
			translation: translations,
			options: {
				renderToStaticMarkup,
				defaultLanguage: defaultLanguage,
			},
		})
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let supplierCode = this.props.match.params.token
		this.props.loadDivisas()
		this.props.loadIncoterms()
		this.props.loadBiddingBySupplierExternalCode(supplierCode).then((existBidding) => {
			if (existBidding) {
				if (supplierAccepTermsAndConditions(existBidding, supplierCode)) {
					this.props.isSentProposalExternalEnabled(existBidding.Id, supplierCode)
					this.seeBidding()
				} else {
					let statusActive = this.props.step + 1
					this.props.updateStepSupplierExternal(statusActive)
					this.setState({ statusDocument: statusActive, isValid: true, canBeHeader: true })
				}
			} else {
				this.setState({ isValid: false, canBeHeader: false })
			}
		})
	}

	termAndConditions() {
		let statusActive = this.props.step + 1
		this.props.updateStepSupplierExternal(statusActive)
		let supplierCode = this.props.match.params.token
		this.props.addTermsAndConditionsFromBackend(supplierCode)
	}

	seeBidding() {
		this.setState({
			canSeeBiddingInformation: true,
		})
	}

	onUploadMaterialsPricesConfigPanelLateralPanel() {
		this.setState({
			uploadMaterialsPricesConfigPanel: { opened: !this.state.uploadMaterialsPricesConfigPanel.opened },
		})
	}

	onLoadBiddingDetail() {
		let supplierCode = this.props.match.params.token
		this.props.loadBiddingBySupplierExternalCode(supplierCode)
	}

	onAddCommentsSupplierConfigPanel = (supplier) => {
		this.setState({
			addCommentsSupplierConfigPanel: {
				opened: !this.state.addCommentsSupplierConfigPanel.opened,
				supplier: supplier,
			},
		})
	}

	onAddResponseSupplierConfigPanel(item) {
		this.setState({
			addResponseSupplierConfigPanel: { opened: !this.state.addResponseSupplierConfigPanel.opened, item: item },
		})
	}

	onAddResponseGralSupplierConfigPanel(item) {
		this.setState({
			addResponseGralSupplierConfigPanel: {
				opened: !this.state.addResponseGralSupplierConfigPanel.opened,
				item: item,
			},
		})
	}

	onAddResponseSupplierExternal(itemId, id, suppliersResponse, isGeneralResponse, comments) {
		return this.props
			.addResponseSupplierExternal(
				itemId,
				id,
				suppliersResponse,
				isGeneralResponse,
				this.props.match.params.token,
				comments
			)
			.then(() => {
				this.props.isSentProposalExternalEnabled(
					this.props.bidding ? this.props.bidding.Id : '',
					this.props.match.params.token
				)
				if (isGeneralResponse) {
					this.onAddResponseGralSupplierConfigPanel()
				}
			})
			.catch(() => {})
	}

	onChangeStatusSupplierExternalFromBackEnd(status, biddingId, comments) {
		return this.props
			.changeStatusSupplierExternalFromBackEnd(status, biddingId, comments, this.props.match.params.token)
			.then(() => {
				this.setState({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	onAddDivisaInBiddingFromBackEnd(divisa) {
		return this.props
			.addDivisaInBiddingResultSupplierExternalFromBackEnd(
				this.props.bidding ? this.props.bidding.Id : '',
				divisa,
				this.props.match.params.token
			)
			.then(() => {
				this.props.isSentProposalExternalEnabled(
					this.props.bidding ? this.props.bidding.Id : '',
					this.props.match.params.token
				)
				this.setState({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	onAddQuestion(questionModel) {
		questionModel.Id = this.props.bidding.Id
		return this.props
			.onAddQuestionSupplierExternalFromBackEnd(questionModel, this.props.match.params.token)
			.then(() => {})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	onAddAnswer(questionModel) {
		return this.props
			.onAddAnswerSupplierExternalFromBackEnd(questionModel, this.props.match.params.token)
			.then(() => {})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	onDownloadMaterialsReport() {
		return this.props
			.downloadMaterialsReportSupplierExternal(this.props.match.params.token)
			.then(() => {})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	onDownloadBiddingResultsReport(resultsGeneral, resultsItem, headersTable) {
		return this.props
			.downloadResultBiddingReport(resultsGeneral, resultsItem, headersTable, this.props.bidding)
			.then(() => {})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	onSetMaterialStatusBySupplierExternal(item, status) {
		return this.props
			.setMaterialStatusBySupplierExternal(item, status)
			.then(() => {})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * On render container information
	 */
	renderContainer() {
		const { classes } = this.props
		let statusActive = this.props.step
		if (this.props.isLoading === true) {
			return (
				<div>
					<Translate id='common.loadingPleaseWait' />
				</div>
			)
		} else {
			switch (statusActive) {
				case 1:
					return (
						<StepOneValidateData
							isValid={this.state.isValid}
							translate={this.props.translate}
							isSaving={this.props.isSaving}
						/>
					)
				case 2:
					return (
						<StepTwoTermsAndConditions
							termAndConditions={this.termAndConditions}
							translate={this.props.translate}
						/>
					)
				case 3:
					return (
						<Grid item xs={12}>
							<Typography variant='h6' align='center'>
								<span class='text-success'>
									{<Translate id='biddingsModule.externalUsers.successData' />}
								</span>
							</Typography>
							<Typography variant='subtitle1' align='center'>
								<Translate id='biddingsModule.externalUsers.completeSteps' />
							</Typography>
							<div className={classes.btnSeeBidding}>
								<Button
									variant='outlined'
									color='primary'
									data-cy='btn-close'
									onClick={this.seeBidding}
								>
									<Translate id='biddingsModule.externalUsers.seeBidding' />
								</Button>
							</div>
						</Grid>
					)
				default:
					return null
			}
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof
	 */
	render() {
		const { classes } = this.props
		return (
			<div className='authenticated-container'>
				<div id='application-bar'>
					<ApplicationBar openLogin={true} {...this.props} />
				</div>
				<main className='registerContainer'>
					<Card>
						{!this.state.canSeeBiddingInformation && (
							<CardHeader
								avatar={
									<>
										<img alt='imberaLogo' src={ImberaLogo} className={classes.imberaLogo} />
									</>
								}
								action={<div></div>}
								title={
									<Typography color='primary' variant='h6'>
										Imbera SA de CV
									</Typography>
								}
								subheader={
									<Typography color='primary' variant='subtitle2'>
										{this.props.step !== 0 && this.props.bidding
											? `${this.props.translate('biddingsModule.externalUsers.folioBidding')} ${
													this.props.bidding ? this.props.bidding.FormatedFolio : ''
											  }`
											: ''}
									</Typography>
								}
							/>
						)}

						<CardContent>
							{!this.state.canSeeBiddingInformation ? (
								this.renderContainer()
							) : (
								<ShowDesktopViewExternalUser
									/**Objects */
									bidding={this.props.bidding}
									translate={this.props.translate}
									/**Bools */
									isSaving={this.props.isSaving}
									isExternalUser={true}
									canBePublicationEnabled={this.props.canBePublicationEnabled}
									canBeSentProposalEnabled={this.props.canBeSentProposalEnabled}
									/**Functions */
									onAddResponseSupplier={this.onAddResponseSupplierExternal}
									onAddQuestion={this.onAddQuestion}
									onAddAnswer={this.onAddAnswer}
									onChangeStatusSupplierFromBackEnd={this.onChangeStatusSupplierExternalFromBackEnd}
									onDivisaInBiddingFromBackEnd={this.onAddDivisaInBiddingFromBackEnd}
									onUploadMaterialsPricesConfigPanelLateralPanel={
										this.onUploadMaterialsPricesConfigPanelLateralPanel
									}
									onAddResponseItemSupplierConfigPanel={this.onAddResponseSupplierConfigPanel}
									onAddResponseGralSupplierConfigPanel={this.onAddResponseGralSupplierConfigPanel}
									onDownloadMaterialsReport={this.onDownloadMaterialsReport}
									onDownloadBiddingResultsReport={this.onDownloadBiddingResultsReport}
									onAddCommentsSupplierConfigPanel={this.onAddCommentsSupplierConfigPanel}
									onSetMaterialStatusBySupplierInternal={this.onSetMaterialStatusBySupplierExternal}
									onLoadBiddingDetail={this.onLoadBiddingDetail}
									/**Arrays */
									divisas={this.props.divisas}
									/**String */
									supplierExternalCode={this.props.match.params.token}
								/>
							)}
							<ShowEdit
								bidding={this.props.bidding}
								onUploadMaterialsPricesConfigPanelLateralPanel={
									this.onUploadMaterialsPricesConfigPanelLateralPanel
								}
								uploadMaterialsPricesConfigPanel={this.state.uploadMaterialsPricesConfigPanel}
								isExternalUser={true}
								addResponseItemSupplierConfigPanel={this.state.addResponseSupplierConfigPanel}
								onAddResponseItemSupplierConfigPanel={this.onAddResponseSupplierConfigPanel}
								onAddResponseGralSupplierConfigPanel={this.onAddResponseGralSupplierConfigPanel}
								addResponseGralSupplierConfigPanel={this.state.addResponseGralSupplierConfigPanel}
								supplierExternalCode={this.props.match.params.token}
								onAddResponseSupplier={this.onAddResponseSupplierExternal}
								onAddCommentsSupplierConfigPanel={this.onAddCommentsSupplierConfigPanel}
								addCommentsSupplierConfigPanel={this.state.addCommentsSupplierConfigPanel}
							/>
						</CardContent>
					</Card>
					<Toaster
						message={<Translate id={this.state.toasterMessage} />}
						open={this.state.showToaster}
						variant={this.state.toasterVariant}
						onClose={() => {
							this.setState({
								showToaster: false,
								toasterMessage: '',
								toasterVariant: '',
							})
						}}
					/>
					<FooterContainer />
				</main>
			</div>
		)
	}
}

/**
 * Map state to props
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state) {
	return {
		translate: getTranslate(state.localize),
		step: state.biddings.get('step'),
		isLoading: state.biddings.get('isLoading'),
		isSaving: state.biddings.get('isSaving'),
		bidding: state.biddings.get('bidding') ? state.biddings.get('bidding').toJS() : null,
		canBePublicationEnabled: state.biddings.get('isPublicationEnabled'),
		canBeSentProposalEnabled: state.biddings.get('isSentProposalEnabled'),
		divisas: state.biddings.get('divisas') ? converListToArray(state.biddings.get('divisas')) : [],
	}
}
/**
 * Map dispatch to props
 */
const mapDispatchToProps = (dispatch) => {
	return {
		updateStepSupplierExternal: (step) => {
			return dispatch(updateStepSupplierExternal(step))
		},
		loadBiddingFromBackEnd: (token) => {
			return dispatch(loadBiddingFromBackEnd(token))
		},
		changeStatusSupplierExternalFromBackEnd: (status, biddingId, comments, supplierCode) => {
			return dispatch(changeStatusSupplierExternalFromBackEnd(status, biddingId, comments, supplierCode))
		},
		onAddAnswerSupplierExternalFromBackEnd: (questionModel, supplierCode) => {
			return dispatch(onAddAnswerSupplierExternalFromBackEnd(questionModel, supplierCode))
		},
		onAddQuestionSupplierExternalFromBackEnd: (questionModel, supplierCode) => {
			return dispatch(onAddQuestionSupplierExternalFromBackEnd(questionModel, supplierCode))
		},
		isSentProposalExternalEnabled: (token, supplierCode) => {
			return dispatch(isSentProposalExternalEnabled(token, supplierCode))
		},
		addTermsAndConditionsFromBackend: (token) => {
			return dispatch(addTermsAndConditionsFromBackend(token))
		},
		loadBiddingBySupplierExternalCode: (biddingModel) => {
			return dispatch(loadBiddingBySupplierExternalCode(biddingModel))
		},
		loadDivisas: () => {
			return dispatch(loadDivisas())
		},
		addDivisaInBiddingResultSupplierExternalFromBackEnd: (id, divisa, supplierCode) => {
			return dispatch(addDivisaInBiddingResultSupplierExternalFromBackEnd(id, divisa, supplierCode))
		},
		downloadMaterialsReportSupplierExternal: (id, supplierCode) => {
			return dispatch(downloadMaterialsReportSupplierExternal(id, supplierCode))
		},
		addResponseSupplierExternal: (itemId, id, suppliersResponse, isGeneralResponse, supplierCode, comments) => {
			return dispatch(
				addResponseSupplierExternal(itemId, id, suppliersResponse, isGeneralResponse, supplierCode, comments)
			)
		},
		loadIncoterms: () => {
			return dispatch(loadIncoterms())
		},
		downloadResultBiddingReport: (resultsGeneral, resultsItem, headersTable, bidding) => {
			return dispatch(downloadResultBiddingReport(resultsGeneral, resultsItem, headersTable, bidding))
		},
		setMaterialStatusBySupplierExternal: (item, status) => {
			return dispatch(setMaterialStatusBySupplierExternal(item, status))
		},
	}
}

const styles = (theme) => ({
	imberaLogo: {
		width: '240px',
		height: 'auto',
	},
	btnSeeBidding: {
		marginTop: '15px',
		display: 'flex',
		justifyContent: 'center',
	},
})

export default withLocalize(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProposalExternal)))
)
