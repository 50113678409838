import { fromJS } from 'immutable';

import * as warehouseWindowsActions from './WarehouseWindowsActions'

/**
 * contraActions initial state
 */
export const warehouseWindowsInitialState = fromJS({
    isLoading: true,
    identifier: "",
    isSaving: false,
    requests: [],
    request: null,
    requestsCount: 0,
    isDownloadingReport: false,
    deliveryDatesHistory: []
})

export default function warehouseWindowsReducer(state = warehouseWindowsInitialState, action) {
    switch (action.type) {
        case warehouseWindowsActions.START_LOADING_REQUESTS: {
            return state.merge({
                isLoading: action.isLoading,
                identifier: action.identifier,
            })
        }
        case warehouseWindowsActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving,
                identifier: action.identifier,
            })
        }
        case warehouseWindowsActions.SET_REQUESTS_LIST: {
            return state.merge({
                requests: fromJS(action.requests),
                isLoading: false,
                identifier: ""
            })
        }
        case warehouseWindowsActions.ADD_REQUEST:{
            let data = fromJS(action.request);
            return state.updateIn(['requests'], allRequests => pushRequest(allRequests, data)).set("isSaving", false)
        }
        case warehouseWindowsActions.SET_REQUESTS_COUNT:{
            return state.merge({
                requestsCount: action.count
            })
        }
        case warehouseWindowsActions.SET_REQUEST:{
            return state.merge({
                isLoading: false,
                identifier: "",
                request: action.request
            })
        }
        case warehouseWindowsActions.SET_NEW_STATUS:{
            let data = fromJS(action.request);
            return state.updateIn(['requests'], allRequests => pushRequest(allRequests, data)).set("isSaving", false)
        }
        case warehouseWindowsActions.SET_DELIVERY_DATE_HISTORY: {
            let deliveryDatesLists = fromJS(action.data);
            return state.merge({
                deliveryDatesHistory: deliveryDatesLists,
                isLoadingQuestionnaires: false
            })
        }
        default:
            return state;
    }
}

const pushRequest = (allRequests, newRequest) => {
    let indexOfRequest = allRequests.findIndex(d => d.get('token') === newRequest.get('token'));
    if (indexOfRequest >= 0) {
        return allRequests.setIn([indexOfRequest], newRequest);
    }
    return allRequests.unshift(newRequest);
}