import React, { Component } from 'react';
import autoBind from 'auto-bind';
import moment from 'moment'

/** Import component section */
import DatePicker from '../../common/DateTimePicker/DatePicker';
import Evidence from '../../common/Evidence';
import Select from 'react-select'

/** Translations imports section */
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import NoticesIcon from '@material-ui/icons/ChatTwoTone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { GetOfficialNoticeType } from '../../../store/helpers/SelectOptions';
import { COMPANY_PTM, COMPANY_IDENTIFIER_IMBERA_MX, COMPANY_IDENTIFIER__METALLIC, COMPANY_IDENTIFIER_IMBERA_REPARE} from '../../../store/helpers/ResourcesHelper';

/**
 * User card
 *
 * @class UserCard
 * @extends {Component}
 */
class UserCard extends Component {

    constructor(props, context) {
        super(props, context);
        autoBind(this);

    }

    /**
     * Render
     */
    render() {
        const { notice, classes } = this.props;
        let isCompanyWithVideo = (this.props.selectedCompanyIdentifier === COMPANY_PTM || this.props.selectedCompanyIdentifier === COMPANY_IDENTIFIER_IMBERA_MX ||
            this.props.selectedCompanyIdentifier === COMPANY_IDENTIFIER__METALLIC || this.props.selectedCompanyIdentifier === COMPANY_IDENTIFIER_IMBERA_REPARE)

        const noticesType = GetOfficialNoticeType(isCompanyWithVideo);
        return (
            <Card className={classes.card} style={{ height: '79vh' }}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="Recipe" className={classes.userAvatar}>
                            <NoticesIcon />
                        </Avatar>
                    }
                    title={<Translate id="officialNotices.addOfficialNotice" />}
                />
                <CardContent style={{ height: '60vh', overflowY: "auto" }}>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="caption" color={'textSecondary'}><Translate id="officialNotices.officialNoticeType" /></Typography>
                            <Select
                                value={noticesType ? noticesType['notice_type'] : ''}
                                className={classes.documentTypeSelect}
                                options={noticesType}
                                onChange={(event) => this.props.updateProperty("notice_type", event)}
                            />
                        </Grid>
                        {(notice.notice_type && notice.notice_type.value > 1) && <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => { this.props.updateProperty('acceptedBySupplier', event.target.checked) }}
                                        color="primary"
                                        value="status"
                                    />
                                }
                                label={<Translate id="officialNotices.setToAcceptBySupplier" />}
                            />
                        </Grid>}
                        <Grid item xs={6}>
                            <Typography variant="caption" color={'textSecondary'}><Translate id="officialNotices.activationDate" /></Typography>
                            <DatePicker
                                onChangeRange={(event) => { this.props.updateProperty('activationDate', event) }}
                                startDate={notice.activationDate}
                                minDate={moment()}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" color={'textSecondary'}><Translate id="officialNotices.expirationDate" /></Typography>
                            <DatePicker
                                onChangeRange={(event) => { this.props.updateProperty('expirationDate', event) }}
                                startDate={notice.expirationDate}
                                minDate={notice.activationDate ? moment(notice.activationDate, 'DD/MM/YYYY').add(1, 'days') : moment()}
                            />
                        </Grid>
                        {notice.notice_type &&
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" color={'textSecondary'}>
                                    <Translate id={notice.notice_type.value === 1 ?
                                        "officialNotices.messageToAddImages"
                                        : (notice.notice_type.value === 2 ?
                                            "officialNotices.messageToAddDocuments" :
                                            "officialNotices.messageToAddVideo")
                                    } />
                                </Typography>
                                <Evidence
                                    baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                                    removeFiles={this.props.removeFiles}
                                    uploadFiles={this.props.uploadFiles}
                                    files={notice.attachments}
                                    acceptedFiles={notice.notice_type.value === 1 ? ['images'] : (notice.notice_type.value === 2 ? ['pdf'] : ['video'])}
                                    disabledLink
                                    text={notice.notice_type.value === 3 ? this.props.dropzoneVideo : this.props.dropzoneText}
                                />
                            </Grid>}
                        {notice.notice_type && (notice.notice_type.value === 2 || notice.notice_type.value === 1) && <Grid item xs={12}>
                            <TextField fullWidth
                                    id="outlined-multiline"
                                    label={<Translate id="officialNotices.notes" />}
                                    multiline
                                    className={classes.textAreaField}
                                    rows="3"
                                    value={notice.notes}
                                    onChange={(event) => { this.props.updateProperty("notes", event.target.value) }}
                                    variant="outlined"
                                />
                        </Grid>}
                    </Grid>
                </CardContent>
                <CardActions className={classes.actions} >
                    <Grid item xs={12}>
                        <Toolbar variant="dense">
                            {
                                this.props.isSaving === true ?
                                    <Button variant="outlined" color="secondary" className={classes.button} disabled>
                                        <Translate id="common.saving" />
                                    </Button>
                                    :
                                    <Button variant="outlined" color="secondary" className={classes.button}
                                        data-cy="btn-save"
                                        onClick={this.props.onCreateNoticeOffice}
                                        disabled={!this.props.canBeSaved}
                                    >
                                        <Translate id="common.save" />
                                    </Button>
                            }
                            <Button variant="outlined" color="primary" className={classes.button}
                                data-cy="btn-close"
                                onClick={() => { this.props.history.push(`/officialNotices`) }} >
                                <Translate id="common.cancel" />
                            </Button>
                        </Toolbar>
                    </Grid>

                </CardActions>
            </Card>
        );
    }
}

export default UserCard;