import React, { useState } from 'react'
import propTypes from 'prop-types'

/** Material UI import section*/
import { withStyles } from '@material-ui/core/styles';
import { 
    Typography,
    Button,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    List,
    Divider,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssessmentIcon from '@material-ui/icons/Assessment';

/** Custom components import section*/
import Dialog from '../../common/Dialog'
import DataTable from '../../common/DataTable'

/** Redux selectors import section */
import { Translate, getTranslate } from 'react-localize-redux'
import { useSelector } from 'react-redux'

const ModalRatingDetails = (props) => {
    const { rating, classes } = props

    const translate = useSelector((state) => getTranslate(state.localize))
    
    const [open, setOpen] = useState(false)
    const handleClose = () =>{
        setOpen(false)
    }
    const handleOpen = () =>{
        setOpen(true)
    }
    
    const getSectionTitle = (sectionType) => {
        switch (sectionType) {
            case 1:
                return '.materialDelivery'
            case 2:
                return '.quoteTime'
            case 3:
                return '.timeResponse'
            case 4:
                return '.commitmentDate'
            case 5:
                return '.rmi'
            case 6:
                return '.reports'
            case 7:
                return '.strike'
            case 8:
                return '.ppm'
            case 9:
                return '.sac'
            case 10:
                return '.documentation'
            default:
                return '.rating'
        }
    }

    const getSectionSubtitle = (sectionType) => {
        switch (sectionType) {
            case 1:
                return '.planning'
            case 2:
            case 3:
            case 4:
            case 5:
                return '.development'
            case 6:
            case 7:
            case 8:
            case 9:
                return '.quality'
            case 10:
                return '.service'
            default:
                return '.rating'
        }
    }

    const getTableConfiguration =(sectionType)=>{
        switch (sectionType) {
            case 1:
                return materialDeliveryConfiguration(translate)
            case 2:
                return quoteTypeConfiguration(translate)
            case 3:
                return timeResponseConfiguration(translate)
            case 4:
                return commitmentDateConfiguration(translate)
            case 5:
                return rmiConfiguration(translate)
                case 6:
                    return reportsConfiguration(translate)
            case 7:
                return strikeConfiguration(translate)
            case 8:
                return ppmConfiguration(translate)
            case 9:
                return sacConfiguration(translate)
            case 10:
                return documentationConfiguration(translate)
            default:
                return []
        }
    }

    const buttons = ()=>{
        return(
            <Button onClick={()=> handleClose()} color='primary' >
                <Translate id='common.close' />
            </Button>
        )
    }

    const getDataTable=(rating, sectionType) => {
        const section = rating.items_rating.filter((item) => item.SectionType === sectionType);
        if (section.length > 0){
            let tableConfiguration = getTableConfiguration(sectionType)
            return (
                <ExpansionPanel>
                    <ExpansionPanelSummary 
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography className={classes.heading}>
                            <Translate id={'rating.ratingsDetailTable' + getSectionTitle(sectionType)} />
                        </Typography>
                        <Typography className={classes.secondaryHeading}>
                            <Translate id={'rating.ratingsDetailTable' + getSectionSubtitle(sectionType)} />
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div style={{ flexBasis: '100%' }}>
                            <List>
                                <Divider />
                                <DataTable
                                    alignCellContent='center'
                                    data={section}
                                    configuration={tableConfiguration}
                                />
                            </List>
                        </div>
                    </ExpansionPanelDetails>
                    <Divider />
                </ExpansionPanel>
            )
        }     
    }
    const getModalData =() =>{
        return (
            <>
                <div style={{ margin: 10, width: 500 }}>
                    {getDataTable(rating, 1)}
                    {getDataTable(rating, 2)}
                    {getDataTable(rating, 3)}
                    {getDataTable(rating, 4)}
                    {getDataTable(rating, 5)}
                    {getDataTable(rating, 6)}
                    {getDataTable(rating, 7)}
                    {getDataTable(rating, 8)}
                    {getDataTable(rating, 9)}
                    {getDataTable(rating, 10)}
                </div>
            </>
        )
    }
    return (
        <div>
            <Button
                color='primary'
                variant="outlined"
                size="small"
                onClick={handleOpen}
            >
                <AssessmentIcon className={classes.extendedIcon} />
                <Translate id='rating.ratingsDetailTable.details' />
			</Button>
            <Dialog 
                open={open}
                onClose={handleClose}
                header={
                    <Typography style={{ margin: 15 }}>
                        <Translate id='rating.RatingDetailTitle' />
                    </Typography>
                }
                children={getModalData()}
                actions={buttons()}
            />
        </div>
    );
}

const materialDeliveryConfiguration = (translate) => {
	let columns = [
		{
			header: '#OC',
			dataSource: 'Folio',
		},
		{
			header: translate('rating.ratingsDetailTable.deliveryDate'),
			dataSource: 'MaterialDeliveryDate',
		},
		{
			header: translate('rating.ratingsDetailTable.sapNumber'),
			dataSource: 'SAP',
		},
		{
			header: translate('rating.ratingsDetailTable.material'),
			dataSource: 'Material',
		},
		{
			header: translate('rating.ratingsDetailTable.comments'),
			dataSource: 'Comment',
		},
	]
	return { columns }
}
const quoteTypeConfiguration = (translate) => {
	let columns = [
		{
			header: translate('rating.ratingsDetailTable.origin'),
			dataSource: 'Folio',
		},
		{
			header: translate('rating.ratingsDetailTable.quoteDate'),
			dataSource: 'SectionDate',
		},
		{
			header: translate('rating.ratingsDetailTable.supplierResponseDate'),
			dataSource: 'ResponseDate',
		},
		{
			header: translate('rating.ratingsDetailTable.responseDays'),
			dataSource: 'ResponseDays',
		},
		{
			header: translate('rating.ratingsDetailTable.rating'),
			dataSource: 'Score',
		},
	]
	return { columns }
}
const timeResponseConfiguration = (translate) => {
	let columns = [
		{
			header: translate('rating.ratingsDetailTable.folio'),
			dataSource: 'Folio',
		},
		{
			header: translate('rating.ratingsDetailTable.sampleRequestDate'),
			dataSource: 'SectionDate',
		},
		{
			header: translate('rating.ratingsDetailTable.supplierResponseDate'),
			dataSource: 'ResponseDate',
		},
		{
			header: translate('rating.ratingsDetailTable.responseDays'),
			dataSource: 'ResponseDays',
		},
		{
			header: translate('rating.ratingsDetailTable.rating'),
			dataSource: 'Score',
		},
	]
	return { columns }
}
const commitmentDateConfiguration = (translate) => {
	let columns = [
		{
			header: translate('rating.ratingsDetailTable.folio'),
			dataSource: 'Folio',
		},
		{
			header: translate('rating.ratingsDetailTable.commitmentDateDelivery'),
			dataSource: 'SectionDate',
		},
		{
			header: translate('rating.ratingsDetailTable.deliveryDate'),
			dataSource: 'ResponseDate',
		},
		{
			header: translate('rating.ratingsDetailTable.complianceDays'),
			dataSource: 'ResponseDays',
		},
		{
			header: translate('rating.ratingsDetailTable.rating'),
			dataSource: 'Score',
		},
	]
	return { columns }
}
const rmiConfiguration = (translate) => {
	let columns = [
		{
			header: translate('rating.ratingsDetailTable.folio'),
			dataSource: 'Folio',
		},
		{
			header: translate('rating.ratingsDetailTable.sampleStatus'),
			dataSource: 'SampleStatus',
		},
		{
			header: translate('rating.ratingsDetailTable.rating'),
			dataSource: 'Score',
		},
	]
	return { columns }
}
const reportsConfiguration = (translate) => {
	let columns = [
		{
			header: translate('rating.ratingsDetailTable.numberReports'),
			dataSource: 'ReportsNumber',
		},
		{
			header: translate('rating.ratingsDetailTable.rating'),
			dataSource: 'Score',
		},
	]
	return { columns }
}
const strikeConfiguration = (translate) => {
	let columns = [
		{
			header: `${translate('rating.ratingsDetailTable.incidents')} (hrs)`,
			dataSource: 'IncidentsHours',
		},
		{
			header: translate('rating.ratingsDetailTable.date'),
			dataSource: 'SectionDate',
		},
		{
			header: translate('rating.ratingsDetailTable.rating'),
			dataSource: 'Score',
		},
	]
	return { columns }
}
const ppmConfiguration = (translate) => {
	let columns = [
		{
			header: translate('rating.ratingsDetailTable.totalDelivered'),
			dataSource: 'TotalDelivered',
		},
		{
			header: translate('rating.ratingsDetailTable.totalRejections'),
			dataSource: 'TotalRejected',
		},
		{
			header: translate('rating.ratingsDetailTable.ppm'),
			dataSource: 'PPM',
		},
		{
			header: translate('rating.ratingsDetailTable.rating'),
			dataSource: 'Score',
		},
	]
	return { columns }
}
const sacConfiguration = (translate) => {
	let columns = [
		{
			header: translate('rating.ratingsDetailTable.folio'),
			dataSource: 'Folio',
		},
		{
			header: translate('rating.ratingsDetailTable.creationDate'),
			dataSource: 'SACCreationDate',
		},
	]
	return { columns }
}
const documentationConfiguration = (translate) => {
	let columns = [
		{
			header: translate('rating.ratingsDetailTable.folio'),
			dataSource: 'Folio',
		},
		{
			header: translate('rating.ratingsDetailTable.purchaseOrder'),
			dataSource: 'PurchaseOrder',
		},
		{
			header: translate('rating.ratingsDetailTable.incidents'),
			dataSource: 'DocumentationIncidents',
		},
	]
	return { columns }
}
const styles = theme => ({
    extendedIcon: {
        marginRight: theme.spacing.unit,
        fontSize: 20,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '40%',
        flexShrink: 0,
    },
    secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    },
});

ModalRatingDetails.propTypes = {
	rating: propTypes.object.isRequired,
}

ModalRatingDetails.defaultProps = {
	rating: {
		items_rating: [],
	},
}
export default withStyles(styles)(ModalRatingDetails);
