
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import {Grid } from '@material-ui/core';

/** Import component section */
import Dialog from './Dialog';
import DialogActions from './DialogActions';
import DatePicker from './DateTimePicker/DatePicker';
import {SHOW_EDIT_VIEW} from '../../store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';

/** Language imports **/
import { Translate } from "react-localize-redux";
import SupplierSelector from './SupplierSelector';

/**
 * Dialog to generrated Report Dialog item in workflow
 */
class DonwloadReportDialog extends Component {

    /**
   *Creates an instance of DonwloadReportDialog.
   * @param {*} props
   * @memberof DonwloadReportDialog
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            startDate: "",
            endDate: "",
            supplier: null,
        }
    }
    
    /**
     * Close modal to report
     *
     * @memberof Index
     */
    handleCloseModal() {
        if(this.props.onClose){
            this.props.onClose();
            this.setState({ startDate: "", endDate: "", supplier: null});
        }
    }

        /**
     * On update propoerties
     * @param {*} property 
     * @param {*} value 
     */
    onUpdateProperty(property, value) {
        if (IsNullOrEmpty(this.state[property]) || !moment(this.state[property], "DD/MM/YYYY").isSame(moment(value, "DD/MM/YYYY"))) {
            this.setState({
                [property]: value,
            })
        }
    }


         /**
     * Render start Date
     * @param {*} propertyTranslation 
     * @param {*} propertyName 
     * @param {*} minDateProperty 
     * @param {*} dependentPropertyName 
     * @param {*} xs 
     */
    renderDateTimePicker(propertyTranslation, propertyName, minDateProperty, dependentPropertyName, xs) {
        return (
            <Grid item xs={xs}>
                <Typography variant="caption" color={'textSecondary'}>
                    <Translate id={propertyTranslation} />
                </Typography>
                <DatePicker
                    onChangeRange={(event) => { this.onUpdateProperty(propertyName, event, dependentPropertyName) }}
                    startDate={this.state[propertyName] ? moment(this.state[propertyName], 'DD/MM/YYYY') : null}
                    minDate={this.state[minDateProperty] ? moment(this.state[minDateProperty], 'DD/MM/YYYY') : null}
                />
            </Grid>
        )
    }

    /**
     * Render donwlod report
     */
    renderCalendar() {
        const {showMessageDownloadReport = true} = this.props
        return (
            <div style={{ margin: 10, width: 500 }}>
                <Grid container spacing={24}>
                    <Grid item xs={6} >
                        {this.renderDateTimePicker("common.startDate", "startDate", null, "endDate", 12)}
                    </Grid>
                    <Grid item xs={6} >
                        {this.renderDateTimePicker("common.endDate", "endDate", "startDate", null, 12)}
                    </Grid>
                    {this.props.isSupplierDownloadReport && (
                        <Grid item xs={12} >
                            <SupplierSelector
                                onSupplierSelected = {(supplierSelected) => { this.setState({ supplier: supplierSelected})}}
                            />
                        </Grid>
                    )}
                    { showMessageDownloadReport &&<Grid item xs={12} >
                        <Typography variant="caption" gutterBottom color={'textSecondary'}><Translate id="moduleGuarantee.messageTodownloadReport"/></Typography>
                    </Grid>}
                </Grid>
            </div>
        )
    }

    /**
     * Render buttons for download report by month
     */
    renderModalButtons() {
        const { classes } = this.props;
        const {titleBtnConfirm} = this.props
        return (
            <DialogActions
                view={SHOW_EDIT_VIEW}
                loadingItem={titleBtnConfirm ? <Translate id={titleBtnConfirm}/>:<Translate id="common.downloadingReport"/>}
                saveButtonTranslate={titleBtnConfirm ? titleBtnConfirm:"common.download"}
                classes={classes}
                onCancel={this.handleCloseModal}
                onClose={this.handleCloseModal}
                onSaveItem={this.onDonwloadItems}
                isSaving={this.props.isSaving}
                canBeSaved={!this.canBeSaved()}
            />
        )
    }

    /**
     * on Donwload Items
     */
    onDonwloadItems() {
        if (!this.canBeSaved()) {
            if(this.props.onDonwloadItems){
                let startDate = this.state.startDate.format("DD/MM/YYYY");
                let endDate = this.state.endDate.format("DD/MM/YYYY");
                let supplierCode = this.state.supplier ? this.state.supplier.code : null;
                this.props.onDonwloadItems(startDate, endDate, supplierCode);
                this.setState({ startDate: "", endDate: "", supprier: null});
            }
        }
    }

    /**
     * canBeSaved
     */
    canBeSaved(){
        if(this.props.isSupplierDownloadReport)
            return (!this.state.startDate || !this.state.endDate || !this.state.supplier || this.props.isSaving);
        else
            return (!this.state.startDate || !this.state.endDate || this.props.isSaving);
    }

    /**
     * Dialog information
     */
    render() {
        return (
                <Dialog
                    open={this.props.open}
                    onClose={this.handleCloseModal}
                    header={<Typography style={{ margin: 10 }}>{this.props.title ? this.props.title : <Translate id="memos.downloadReportByMonth" />}</Typography>}
                    children={this.renderCalendar()}
                    actions={this.renderModalButtons()}
                />
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(DonwloadReportDialog));