import React, { Component } from 'react'
import autoBind from 'auto-bind'
import moment from 'moment'

/** Import component section */
import DatePicker from '../../common/DateTimePicker/DatePicker'
import Evidence from '../../common/Evidence'
/** Translations imports section */
import { Translate } from 'react-localize-redux'
import Select from 'react-select'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import ContractIcon from '@material-ui/icons/ImportContacts'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import {
	GetTypeDealContract,
	GetCurrencyTypeContract,
	GetSignatureConstract,
	GetLegaContract,
	GetLanguageContract,
	GetOptionResolved,
	GetConditionsContract,
} from '../../../store/helpers/SelectOptions'

/**
 * User card
 *
 * @class UserCard
 * @extends {Component}
 */
class FormHeaderCard extends Component {
	constructor(props, context) {
		super(props, context)
		autoBind(this)
	}

	/**
	 * Render
	 */
	render() {
		const { contract, classes, request, requestDocument } = this.props
		let optionsTypeDeal = GetTypeDealContract()
		let optionsTypeOfCurrency = GetCurrencyTypeContract()
		let optionsSignature = GetSignatureConstract()
		let legalOptions = GetLegaContract()
		let languageOptions = GetLanguageContract()
		let optionsResolved = GetOptionResolved()
		let optionsConditions = GetConditionsContract()
		return (
			<Card className={classes.card} style={{ height: '79vh' }}>
				<CardHeader
					avatar={
						<Avatar aria-label='Recipe' className={classes.userAvatar}>
							<ContractIcon />
						</Avatar>
					}
					title={<Translate id='constracts.addRequest' />}
				/>
				<CardContent style={{ height: '60vh', overflowY: 'auto' }}>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<Typography variant='caption' color={'textSecondary'}>
								{<Translate id='constracts.addDocument' />}
							</Typography>
							<TextField
								fullWidth
								id='filled-margin-dense'
								label={''}
								value={contract.name}
								onChange={(event) => {
									this.props.updateProperty('name', event.target.value)
								}}
								helperText={contract.name.length <= 1 ? 'Introduzca al menos dos caracteres' : ''}
								error={contract.name.length <= 1}
								margin='dense'
								variant='outlined'
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
								<Translate id='constracts.activationDate' />
							</Typography>
							<DatePicker
								onChangeRange={(event) => {
									this.props.updateProperty('activationDate', event)
								}}
								startDate={contract.activationDate}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
								<Translate id='constracts.expirationDate' />
							</Typography>
							<DatePicker
								onChangeRange={(event) => {
									this.props.updateProperty('expirationDate', event)
								}}
								startDate={contract.expirationDate}
								minDate={
									contract.activationDate
										? moment(contract.activationDate, 'DD/MM/YYYY').add(1, 'days')
										: moment()
								}
							/>
						</Grid>
						<Grid item xs={!requestDocument ? 8 : 12}>
							<Evidence
								baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
								removeFiles={this.props.removeFiles}
								uploadFiles={this.props.uploadFiles}
								files={contract.attachments}
								acceptedFiles={['pdf']}
								disabledLink
								text={this.props.dropzoneText}
							/>
						</Grid>
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.signature' />}
								</Typography>
								<Select
									options={optionsSignature}
									defaultValue={contract.signature}
									onChange={(event) => {
										this.props.updateProperty('signature', event.value)
									}}
									styles={{
										menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
										menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
										menuPortal: (base) => ({ ...base, zIndex: 9999 }),
									}}
									menuPosition='fixed'
									menuPlacement={'auto'}
								/>
							</Grid>
						)}
						{!requestDocument && (
							<Grid item xs={12}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.requestedBy' />}
								</Typography>
								<TextField
									fullWidth
									id='filled-margin-dense'
									label={''}
									value={contract.requestedBy}
									onChange={(event) => {
										this.props.updateProperty('requestedBy', event.target.value)
									}}
									helperText={
										contract.requestedBy.length <= 1 ? 'Introduzca al menos dos caracteres' : ''
									}
									error={contract.requestedBy.length <= 1}
									margin='dense'
									variant='outlined'
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.typeDeal' />}
								</Typography>
								<Select
									options={optionsTypeDeal}
									defaultValue={contract.typeDeal}
									onChange={(event) => {
										this.props.updateProperty('typeDeal', event.value)
									}}
									styles={{
										menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
										menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
										menuPortal: (base) => ({ ...base, zIndex: 9999 }),
									}}
									menuPosition='fixed'
									menuPlacement={'auto'}
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.typeOfCurrency' />}
								</Typography>
									<Select
										options={optionsTypeOfCurrency}
										defaultValue={contract.typeOfCurrency}
										onChange={(event) => {
											this.props.updateProperty('typeOfCurrency', event.value)
										}}
										styles={{
											menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
											menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
											menuPortal: (base) => ({ ...base, zIndex: 9999 }),
										}}
										menuPosition='fixed'
										menuPlacement={'auto'}
									/>
									<FormHelperText>{contract.typeOfCurrency === 0 ? "Introduzca tipo de moneda" : ""}</FormHelperText>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.daysToExpire' />}
								</Typography>
								<TextField
									fullWidth
									id='filled-margin-dense'
									label={''}
									value={moment(contract.expirationDate, 'YYYYMMDD').diff(moment(), 'days') + ' Dias'}
									disabled
									onChange={(event) => {
										this.props.updateProperty('daysToExpire', event.target.value)
									}}
									margin='dense'
									variant='outlined'
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.legal' />}
								</Typography>
								<Select
									options={legalOptions}
									defaultValue={contract.legal}
									onChange={(event) => {
										this.props.updateProperty('legal', event.value)
									}}
									styles={{
										menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
										menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
										menuPortal: (base) => ({ ...base, zIndex: 9999 }),
									}}
									menuPosition='fixed'
									menuPlacement={'auto'}
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.language' />}
								</Typography>
								<Select
									options={languageOptions}
									defaultValue={contract.language}
									onChange={(event) => {
										this.props.updateProperty('language', event.value)
									}}
									styles={{
										menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
										menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
										menuPortal: (base) => ({ ...base, zIndex: 9999 }),
									}}
									menuPosition='fixed'
									menuPlacement={'auto'}
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.repse' />}
								</Typography>
								<Select
									options={optionsResolved}
									defaultValue={contract.repse}
									onChange={(event) => {
										this.props.updateProperty('repse', event.value)
									}}
									styles={{
										menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
										menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
										menuPortal: (base) => ({ ...base, zIndex: 9999 }),
									}}
									menuPosition='fixed'
									menuPlacement={'auto'}
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.contrapartEmployees' />}
								</Typography>
								<TextField
									fullWidth
									id='filled-margin-dense'
									label={''}
									value={contract.repse ? contract.contrapartEmployees : 'NA'}
									disabled={!contract.repse}
									onChange={(event) => {
										this.props.updateProperty('contrapartEmployees', event.target.value)
									}}
									margin='dense'
									variant='outlined'
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.counterpartEmployerRegistration' />}
								</Typography>
								<TextField
									fullWidth
									id='filled-margin-dense'
									label={''}
									value={contract.repse ? contract.counterpartEmployerRegistration : 'NA'}
									disabled={!contract.repse}
									onChange={(event) => {
										this.props.updateProperty('counterpartEmployerRegistration', event.target.value)
									}}
									margin='dense'
									variant='outlined'
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.counterpartRFC' />}
								</Typography>
								<TextField
									fullWidth
									id='filled-margin-dense'
									label={''}
									value={contract.counterpartRFC}
									onChange={(event) => {
										this.props.updateProperty('counterpartRFC', event.target.value)
									}}
									helperText={
										contract.counterpartRFC.length <= 1 ? 'Introduzca al menos dos caracteres' : ''
									}
									error={contract.counterpartRFC.length <= 1}
									margin='dense'
									variant='outlined'
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.personnelServiceAvailable' />}
								</Typography>
								<Select
									options={optionsResolved}
									defaultValue={contract.personnelServiceAvailable}
									onChange={(event) => {
										this.props.updateProperty('personnelServiceAvailable', event.value)
									}}
									styles={{
										menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
										menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
										menuPortal: (base) => ({ ...base, zIndex: 9999 }),
									}}
									menuPosition='fixed'
									menuPlacement={'auto'}
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.isFilial' />}
								</Typography>
								<br />
								<Select
									options={optionsResolved}
									defaultValue={contract.isFilial}
									onChange={(event) => {
										this.props.updateProperty('isFilial', event.value)
									}}
									styles={{
										menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
										menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
										menuPortal: (base) => ({ ...base, zIndex: 9999 }),
									}}
									menuPosition='fixed'
									menuPlacement={'auto'}
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.personnelEntersPlaceToPerformServices' />}
								</Typography>
								<br />
								<Select
									options={optionsResolved}
									defaultValue={contract.personnelEntersPlaceToPerformServices}
									onChange={(event) => {
										this.props.updateProperty('personnelEntersPlaceToPerformServices', event.value)
									}}
									styles={{
										menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
										menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
										menuPortal: (base) => ({ ...base, zIndex: 9999 }),
									}}
									menuPosition='fixed'
									menuPlacement={'auto'}
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={12}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.contractMotive' />}
								</Typography>
								<TextField
									fullWidth
									id='filled-margin-dense'
									label={''}
									value={contract.contractMotive}
									onChange={(event) => {
										this.props.updateProperty('contractMotive', event.target.value)
									}}
									helperText={
										contract.contractMotive.length <= 1 ? 'Introduzca al menos dos caracteres' : ''
									}
									error={contract.contractMotive.length <= 1}
									margin='dense'
									multiline
									variant='outlined'
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.department' />}
								</Typography>
								<TextField
									fullWidth
									id='filled-margin-dense'
									label={''}
									value={contract.department}
									onChange={(event) => {
										this.props.updateProperty('department', event.target.value)
									}}
									helperText={
										contract.department.length <= 1 ? 'Introduzca al menos dos caracteres' : ''
									}
									error={contract.department.length <= 1}
									margin='dense'
									variant='outlined'
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.consideration' />}
								</Typography>
								<TextField
									fullWidth
									id='filled-margin-dense'
									label={''}
									value={contract.consideration}
									onChange={(event) => {
										this.props.updateProperty('consideration', event.target.value)
									}}
									helperText={
										contract.consideration.length <= 1 ? 'Introduzca al menos dos caracteres' : ''
									}
									error={contract.consideration.length <= 1}
									margin='dense'
									variant='outlined'
								/>
							</Grid>
						)}
						{!request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.wayToPay' />}
								</Typography>
								<TextField
									fullWidth
									id='filled-margin-dense'
									label={''}
									value={contract.wayToPay}
									onChange={(event) => {
										this.props.updateProperty('wayToPay', event.target.value)
									}}
									helperText={
										contract.wayToPay.length <= 1 ? 'Introduzca al menos dos caracteres' : ''
									}
									error={contract.wayToPay.length <= 1}
									margin='dense'
									variant='outlined'
								/>
							</Grid>
						)}
						{!requestDocument && !request && (
							<Grid item xs={4}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.specialConditions' />}
								</Typography>
								<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
									{optionsConditions.map((value) => {
										const labelId = `checkbox-list-label-${value.value}`
										return (
											<ListItem key={value.value}>
												<ListItemIcon>
													<Checkbox
														edge='start'
														checked={contract.specialCondition.includes(value.label)}
														tabIndex={-1}
														disableRipple
														inputProps={{ 'aria-labelledby': labelId }}
														onClick={() =>
															this.props.updateProperty('specialCondition', value.label)
														}
													/>
												</ListItemIcon>
												<ListItemText id={labelId} primary={value.label} />
											</ListItem>
										)
									})}
								</List>
							</Grid>
						)}
						{!requestDocument && !request && (
							<Grid item xs={8}>
								<Typography variant='caption' color={'textSecondary'}>
									{<Translate id='constracts.descriptionOfServices' />}
								</Typography>
								<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
									{optionsConditions.map((value) => {
										const labelId = `filled-margin-dense-${value.value}`
										return (
											<TextField
												key={value.value}
												fullWidth
												id={labelId}
												label={''}
												value={contract.descriptionOfServices.split(',')[value]}
												onChange={(event) => {
													this.props.updateProperty('descriptionOfServices', event.target)
												}}
												margin='dense'
												InputProps={{
													inputProps: {
														minLength: 2,
													},
												}}
												multiline
												variant='outlined'
											/>
										)
									})}
								</List>
							</Grid>
						)}
					</Grid>
				</CardContent>
				<CardActions className={classes.actions}>
					<Grid item xs={12}>
						<Toolbar variant='dense'>
							{this.props.isSaving === true ? (
								<Button variant='outlined' color='secondary' className={classes.button} disabled>
									<Translate id='common.saving' />
								</Button>
							) : (
								<Button
									variant='outlined'
									color='secondary'
									className={classes.button}
									data-cy='btn-save'
									onClick={this.props.onCreateContractData}
									disabled={!this.props.canBeSaved}
								>
									<Translate id='common.save' />
								</Button>
							)}
							<Button
								variant='outlined'
								color='primary'
								className={classes.button}
								data-cy='btn-close'
								onClick={() => {
									contract.documentType === 1
										? this.props.history.push(`/contracts`)
										: this.props.history.push(`/documents`)
								}}
							>
								<Translate id='common.cancel' />
							</Button>
						</Toolbar>
					</Grid>
				</CardActions>
			</Card>
		)
	}
}

export default FormHeaderCard
