import { fromJS } from 'immutable'
import * as specificationsAction from './specifications.Action'

export const specificationsinitialState = fromJS({
	isLoading: false,
	page: 0,
	pageSize: 20,
	totalGuidelines: 0,
	guidelines: null,
	guidelineData: null,
	packageList: null,
	packagePage: 0,
	packagePageSize: 20,
	totalPackages: 0,
	packageData: null,
})

export default function specificationsReduce(state = specificationsinitialState, action) {
	switch (action.type) {
		case specificationsAction.CHANGE_GUIDELINE_PAGINATION: {
			return state.merge({
				page: action.page,
				pageSize: action.pageSize,
			})
		}
		case specificationsAction.SET_GUIDELINE_LIST: {
			let guidelines = fromJS(action.guidelines)
			return state.merge({
				guidelines: guidelines,
				totalGuidelines: action.totalGuidelines,
			})
		}
		case specificationsAction.LOADING_SPECIFICATIONS: {
			return state.merge({
				isLoading: action.isLoading,
			})
		}
		case specificationsAction.SET_GUIDELINE_INFO: {
			let data = fromJS(action.guidelineData)
			return state.merge({
				guidelineData: data,
			})
		}
		case specificationsAction.SET_PACKAGE_LIST: {
			let packages = fromJS(action.packages)
			return state.merge({
				packageList: packages,
				totalPackages: action.totalPackages,
			})
		}
		case specificationsAction.CHANGE_PACKAGE_PAGINATION: {
			return state.merge({
				packagePage: action.page,
				packagePageSize: action.pageSize,
			})
		}
		case specificationsAction.SET_PACKAGE_INFO: {
			return state.merge({
				packageData: fromJS(action.packageData),
			})
		}
		default:
			return state
	}
}
