///Import react section
import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import autoBind from 'auto-bind';
import { withRouter } from "react-router-dom";
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import {loadSacFromBackEnd} from '../../store/correctiveActions/SacActions'
import { connect } from 'react-redux';
import {cancelWorflowFromBackend} from '../../store/workflows/workflowActions';
import CancelWorkflow from '../common/CancelWorkflow';

class CancelContainer extends Component {
  constructor (props) {
    super(props);
    autoBind(this);
    this.state = {

    }
  }

    /**
    * componentDidMount
    */
   componentDidMount() {
    let correctiveToken = this.props.match.params.token;
    this.props.loadSacFromBackEnd(correctiveToken);
}

      /**
     * Cancel a corrective actions from back end
     */
    onCancelWorkflowsDocument(cancelComment, razonForCancel){
      let token =this.props.match.params.token;
      this.props.cancelWorflowFromBackend(token, cancelComment, razonForCancel)
      .then(() =>{
        this.props.history.push(`/correctiveactions/${this.props.corrective.token}`)
      }).catch(() =>{
          this.setState({
              showToaster: true,
              toasterMessage: <Translate id="common.errorToSave" />,
              toasterVariant: "error",
          })
      })
  }

  render () {
    return (
      <CancelWorkflow
        {...this.props}
          workflow= {this.props.corrective}
          onCancelWorkflowsDocument={this.onCancelWorkflowsDocument}
          isSaving={this.props.isCancel}
          link = {'correctiveactions'}

      />
    )
  }
}

/**
 *  Defines the properties injecteded from the store to view container
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state) {
  let corrective = state.sacs.get("sacInformation");
   return {
      isCancel: state.workflow.get('isCancel'),
      corrective: corrective ? corrective.toJS() : null,
  }
}

/**
 * Defines the actions injectes to the component
 *
 * @param {*} state
 * @returns
 */
const mapDispatchToProps = dispatch => {
  return {
    loadSacFromBackEnd: (token) => {
          return dispatch(loadSacFromBackEnd(token));
    },
    cancelWorflowFromBackend: (token, cancelComment, razonForCancel) => {
        return dispatch(cancelWorflowFromBackend(token, cancelComment, razonForCancel));
    },
    
  }
}

export default withauthenticatedLayout((withRouter) (connect(mapStateToProps, mapDispatchToProps)(CancelContainer)));