/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { withStyles, Tooltip } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import BiddingIcon from "@material-ui/icons/ViewCarousel";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import WarningIcon from '@material-ui/icons/Warning';
/**Import resources section*/
import {
  canBeEditBiddingInStatusDraft, canBeChangeStatusResponsible,
  supplierCanEditBidding, colorStatusProposal,
  colorStatusBidding, descriptionProposalItem, descriptionStatus,
  canBeEditBiddingInStatusResolved, isCategoryRawMaterials
} from '../../../store/helpers/BiddingHelper';
/**Import actions section */
import { UPDATE_PUBLISH_FLAG_ENABLED, PUBLISH_RESULTS_BIDDING } from '../../../store/biddings/BiddingsActions'
import { BiddingStatus, ItemProposalStatus, ActionsStatus } from '../../../store/helpers/AppConstants'
import {
  COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER, COLOR_STATUS_WARNING
} from '../../../store/helpers/StatusColorConstants';
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper';

const ShowDesktopView = (props) => {
  const { classes, bidding } = props;
  /**String props section */
  const { identifier } = props
  /**Bools props section */
  const { isSaving, canBePublicationEnabled, canBeSentProposalEnabled,
    isExternalUser, canBeCalculateEnabled, canBePublicationResultsEnabled,
  } = props
  /**Arrays props section */
  const { userRolesByCompany } = props
  /**Functions props section */
  const { translate, onOpenConfirmDialog,
    onOpenCloseExchangeRatesLateralPanel,
    onConfirmPassword
  } = props
  /**Objects props section */
  const { userProfile, userProfileLogged } = props
  /**
* Get the card content
*
* @memberof Index
*/
  const getCardContent = () => {

    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <Tooltip title={IsCurrentActiveDirectoryUser(userProfileLogged) ? descriptionStatus(bidding ? bidding.Status : "", translate) : descriptionProposalItem(bidding ? bidding.ProposalStatus : "", translate)}>
                  <Avatar style={{ backgroundColor: IsCurrentActiveDirectoryUser(userProfileLogged) ? colorStatusBidding(bidding ? bidding.Status : "") : colorStatusProposal(bidding ? bidding.ProposalStatus : "") }} >
                    <BiddingIcon />
                  </Avatar>
                </Tooltip>
              }
              action={
                <div>
                  {
                    canBeEditBiddingInStatusDraft(bidding, userRolesByCompany, userProfile) &&
                    <Grid item xs={12} className={classes.margingTopText}>
                      <Button
                        data-cy="btn-save"
                        type="button"
                        color="primary"
                        variant='outlined'
                        onClick={() => onConfirmPassword(bidding, BiddingStatus.PUBLISHED)}

                        disabled={isSaving || !canBePublicationEnabled}
                      >
                        {(isSaving && identifier === UPDATE_PUBLISH_FLAG_ENABLED) ? translate("common.saving") : translate("biddingsModule.publish")}
                      </Button>
                    </Grid>
                  }
                  { // Change status publish 
                    canBeChangeStatusResponsible(bidding, userRolesByCompany, userProfile, BiddingStatus.PUBLISHED) &&
                    <Grid item xs={12} className={classes.margingTopText}>
                      <Button
                        data-cy="btn-save"
                        type="button"
                        variant='outlined'
                        // onClick={() => onOpenConfirmDialog(BiddingStatus.CLOSED, bidding ? bidding.Id : "", ActionsStatus.ACTION_CLOSED)}
                        onClick={() => onConfirmPassword(bidding, BiddingStatus.CLOSED)}
                        disabled={isSaving || !bidding.CanBeClosed}
                        className={classes.btnAccept}
                      >
                        {(isSaving && identifier === UPDATE_PUBLISH_FLAG_ENABLED) ? translate("common.saving") : translate("biddingsModule.closeBidding")}
                      </Button>
                    </Grid>
                  }

                  { // Can be calculate proposals all 
                    canBeChangeStatusResponsible(bidding, userRolesByCompany, userProfile, BiddingStatus.CLOSED) &&
                    <Grid item xs={12} className={classes.margingTopText}>
                      <Button
                        color="primary"
                        type="button"
                        variant='outlined'
                        onClick={() => onOpenCloseExchangeRatesLateralPanel()}
                        disabled={isSaving || !canBeCalculateEnabled}
                      >
                        {(isSaving && identifier === UPDATE_PUBLISH_FLAG_ENABLED) ? translate("common.saving") : translate("biddingsModule.calculateBidding")}
                      </Button>
                    </Grid>
                  }

                  {
                    supplierCanEditBidding(bidding, userProfile, userRolesByCompany, isExternalUser) &&
                    <div className={classes.flex}>
                      <Button
                        size="small"
                        data-cy="btn-save"
                        type="button"
                        variant='outlined'
                        onClick={() => onOpenConfirmDialog(ItemProposalStatus.COMPLETED, bidding ? bidding.Id : "", ActionsStatus.ACTION_SEND_PROPOSAL)}
                        disabled={isSaving || !canBeSentProposalEnabled}
                        className={classes.btnAccept}
                      >
                        {(isSaving && identifier === UPDATE_PUBLISH_FLAG_ENABLED) ? translate("common.saving") : translate("biddingsModule.sendBySupplier")}
                      </Button>

                      <Button
                        size="small"
                        data-cy="btn-reject"
                        type="button"
                        variant='outlined'
                        className={classes.btnReject}
                        onClick={() => onOpenConfirmDialog(ItemProposalStatus.DECLINED, bidding ? bidding.Id : "", ActionsStatus.ACTION_DECLINED_PROPOSAL)}
                        disabled={isSaving}
                      >
                        {(isSaving && identifier === UPDATE_PUBLISH_FLAG_ENABLED) ? translate("common.saving") : translate("biddingsModule.declineSupplier")}
                      </Button>

                    </div>
                  }

                  {
                    canBeEditBiddingInStatusResolved(bidding, userRolesByCompany, userProfile, BiddingStatus.PUBLISHED) &&
                    <div className={classes.flex}>
                      <Button
                        size="small"
                        data-cy="btn-save"
                        type="button"
                        variant='outlined'
                        onClick={() => onConfirmPassword(bidding, PUBLISH_RESULTS_BIDDING)}
                        disabled={isSaving || !canBePublicationResultsEnabled}
                        className={classes.btnAccept}
                      >
                        {(isSaving) ? translate("common.saving") : translate("biddingsModule.publishResults")}
                      </Button>
                      <Tooltip title={
                        <div style={{ backgroundColor: "white", padding: "10px" }}>
                          <Typography>{translate("biddingsModule.requiredComments")}</Typography>
                          {isCategoryRawMaterials() ? <Typography>{translate("biddingsModule.winnerByPart")}</Typography> : <Typography>{translate("biddingsModule.winnerBiddingService")}</Typography>}
                        </div>
                      }>
                        <Avatar
                          size="small"
                          className={classes.avatarIcon}
                        >
                          <WarningIcon />
                        </Avatar>
                      </Tooltip>
                    </div>
                  }
                </div>
              }
              title={
                <Typography variant="button">{`${translate("biddingsModule.table.folio")}: ${bidding ? bidding.FormatedFolio : ""}`}</Typography>
              }
              subheader={`${bidding ? bidding.Name : ""}`}
            />
          </Card>
        </Grid>

      </Grid>
    )
  }
  return getCardContent()
};

const styles = (theme) => ({
  margingTopText: {
    marginTop: "5px"
  },
  flex: {
    display: "flex"
  },
  btnReject: {
    color: COLOR_STATUS_DANGER, //"white",
    borderColor: COLOR_STATUS_DANGER
  },
  btnAccept: {
    color: COLOR_STATUS_SUCCESS, //"white",
    borderColor: COLOR_STATUS_SUCCESS,
    marginRight: "5px"
  },
  titleStatus: {
    color: "white",
    fontSize: "80%",
    margin: "5px",
    padding: "3px"
  },
  avatarIcon: {
    backgroundColor: COLOR_STATUS_WARNING
  },

});

ShowDesktopView.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  isLoading: PropTypes.bool.isRequired,
};

ShowDesktopView.defaultProps = {
  isLoading: false,
  canBeSentProposalEnabled: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(ShowDesktopView)
);
