///Import react section
import React, { useCallback, useEffect, useState } from 'react';
import { Translate, getTranslate } from "react-localize-redux";
import { useSelector, } from 'react-redux'
/** Import component section */
import Toaster from '../common/Toaster';
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog';

/**import helpers section */
import LateralPanelContainer from '../common/LateralPanelComponents/LateralPanelContainer'
import PanelActionPanel from './components/actionPlant/PanelActionPanel'
/**Custom hooks index */
import { useIndirectQualificationCustomHookShow } from './customHooks/UseCustomHookShow'
import { IndirectQualificationsSupplierStatus } from '../../store/helpers/AppConstants'

const ShowEditActionPlant = (props) => {
    /**String props section */
    const { identifierPanelLateral } = props
    /**Bools props section */
    //const { } = props
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onConfirmPassword } = props

    /**Objects props section */
    const { setIdentifierPanelLateral, item, confirmPasswordConfigModal } = props
    /**Render hooks section */
    /**Redux store information section*/
    const toasterMessageConfig = useSelector((state) => state.indirectQualifications.get('toasterConfiguration').toJS())
    const {
        onCloseToaster,
        onSetStatusInSupplier
    } = useIndirectQualificationCustomHookShow()

    const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'))
    const translate = useSelector((state) => getTranslate(state.localize))
    const [isCloseLateralPanel, setIsCloseLateralPlanel] = useState(false)


    /**Determinate that panel lateral is selected for open */
    const PanelLateralChildren = useCallback({
        ShowEditActionPlant: <PanelActionPanel
            translate={translate}
            actionPlan={item}
            onCloseButtonClick={() => setIsCloseLateralPlanel(true)}
        />
    },
        [identifierPanelLateral]
    )
    const { LateralPanelComponent, onOpenAndCloseLateralPanel } = LateralPanelContainer({
        children: PanelLateralChildren[identifierPanelLateral],
        identifier: identifierPanelLateral,
        setIdentifierPanelLateral: setIdentifierPanelLateral

    });

    const onLateralPanelConfigChange = useCallback(() => {
        if (identifierPanelLateral) {
            onOpenAndCloseLateralPanel()
        }
    }, [identifierPanelLateral]);

    /**Open lateral panel when identifier of panel change */
    useEffect(() => {
        onLateralPanelConfigChange()
    }, [onLateralPanelConfigChange]);

    /**Open lateral panel when identifier of panel change */
    useEffect(() => {
        if (isCloseLateralPanel) {
            onOpenAndCloseLateralPanel()
            setIsCloseLateralPlanel(false)
        }
    }, [isCloseLateralPanel]);

    const onSendActionPlantToSupplier = () => {
        onSetStatusInSupplier(props.ratingId, confirmPasswordConfigModal.item ? confirmPasswordConfigModal.item.Id : "", IndirectQualificationsSupplierStatus.SEND_ACTION_PLANT_TO_SUPPLIER)
            .then(response => {
                if (response === true) {
                    onConfirmPassword()
                }
            })
    }

    const onSendActionPlantToResponsibleFromPlant = () => {
        onSetStatusInSupplier(props.ratingId, confirmPasswordConfigModal.item ? confirmPasswordConfigModal.item.Id : "", IndirectQualificationsSupplierStatus.ACTION_PLANT_RESPONSE)
            .then(response => {
                if (response === true) {
                    onConfirmPassword()
                }
            })
    }

    const onSendActionPlantToClose = () => {
        onSetStatusInSupplier(props.ratingId, confirmPasswordConfigModal.item ? confirmPasswordConfigModal.item.Id : "", IndirectQualificationsSupplierStatus.CLOSE)
            .then(response => {
                if (response === true) {
                    onConfirmPassword()
                }
            })
    }

    const onCancelActionPlant = (confirmationModel) => {
        onSetStatusInSupplier(props.ratingId, confirmPasswordConfigModal.item ? confirmPasswordConfigModal.item.Id : "", IndirectQualificationsSupplierStatus.CANCEL, confirmationModel.comments)
            .then(response => {
                if (response === true) {
                    onConfirmPassword()
                }
            })
    }

    const getMessageByIdentifier = () => {
        switch (confirmPasswordConfigModal.identifier) {
            case IndirectQualificationsSupplierStatus.SEND_ACTION_PLANT_TO_SUPPLIER:
                return "indirectqualifications.operationsAvailable.messageActionPlanToSupplier"
            case IndirectQualificationsSupplierStatus.ACTION_PLANT_RESPONSE:
                return "indirectqualifications.operationsAvailable.messageActionPlanToResponse"
            case IndirectQualificationsSupplierStatus.CLOSE:
                return "indirectqualifications.operationsAvailable.messageActionPlanToCloseResponse"
            case IndirectQualificationsSupplierStatus.CANCEL:
                return "indirectqualifications.operationsAvailable.messageActionPlanToCancelResponse"
            default:
                return ""
        }
    };

    const getCallBackToInvokeByIndentifier = () => {
        switch (confirmPasswordConfigModal.identifier) {
            case IndirectQualificationsSupplierStatus.SEND_ACTION_PLANT_TO_SUPPLIER:
                return onSendActionPlantToSupplier
            case IndirectQualificationsSupplierStatus.ACTION_PLANT_RESPONSE:
                return onSendActionPlantToResponsibleFromPlant
            case IndirectQualificationsSupplierStatus.CLOSE:
                return onSendActionPlantToClose
            case IndirectQualificationsSupplierStatus.CANCEL:
                return onCancelActionPlant
            default:
                return ""
        }
    };

    const getIsRequiredComments = () => {
        switch (confirmPasswordConfigModal.identifier) {
            case IndirectQualificationsSupplierStatus.CANCEL:
                return true
            default:
                return false
        }
    };


    return (
        <>
            {
                confirmPasswordConfigModal && confirmPasswordConfigModal.opened && <ConfirmPasswordDialog
                    open={confirmPasswordConfigModal.opened}
                    title={<Translate id='common.confirmOperation' />}
                    message1={<Translate id={getMessageByIdentifier()} />}
                    message2={<Translate id='common.enterPassword' />}
                    onClickButtonConfirm={getCallBackToInvokeByIndentifier()}
                    onClose={onConfirmPassword}
                    isSaving={isSaving}
                    isVisibleComments={getIsRequiredComments()}
                    commentRequired={getIsRequiredComments()}
                />
            }
            <LateralPanelComponent />
            <Toaster
                message={<Translate id={toasterMessageConfig.toasterMessage} data={{ name: toasterMessageConfig ? toasterMessageConfig.name : "" }} />}
                open={toasterMessageConfig.showToaster}
                variant={toasterMessageConfig.toasterVariant}
                onClose={() => onCloseToaster()}
            />

        </>
    )
}

export default ShowEditActionPlant