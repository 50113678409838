import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography';

/** Import component section */
import withauthenticatedLayout from './layouts/withauthenticatedLayout'
import UserProfileIcon from '@material-ui/icons/PermIdentity';
import Grid from '@material-ui/core/Grid';

/** Icons section import */
import { Paper, Tabs, Tab, Tooltip } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import BarChart from './common/BarChart';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RatesIcon from '@material-ui/icons/BarChart';
import { YEAR_MONTHS } from '../store/helpers/ResourcesHelper';
import moment from 'moment';
import { loadRatingsBySupplierFromBackEnd } from '../store/ratings/ratingActions';
import { loadRatingsIndirectsBySupplierFromBackEnd } from '../store/indirectqualifications/IndirectqualificationsActions'

import NoticeCarousel from './home/NoticeCarousel';
import PendingTasks from './home/PendingTasks';
import PendingOutsorcing from './home/PendingOutsorcing'

/**
 * Anonymous view 
 *
 * @class AnonymousView
 * @extends {Component}
 */
class MainExternalUserView extends Component {

    /**
     *Creates an instance of MainExternalUserView.
     * @param {*} props
     * @memberof MainExternalUserView
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            activeTab: 0,
            ratingsChart: this.props.ratings ? this.convertRatingChartData() : [],
            ratingsIndirects: this.props.ratings ? this.convertRatingIndirectsChartData() : [],
        };
    }

    /**
     * React life-cycle method
     * When mount component should load rating data of the supplier 
     * to show ratings chart
     *
     * @memberof MainExternalUserView
     */
    componentDidMount() {
        if (!this.props.isLoadingUser) {
            this.props.loadRatings(this.props.user.profile.company_code);
            this.props.loadRatingsIndirects(this.props.user.profile.company_code)
        }
    }

    /**
     * React life-cycle method
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof MainExternalUserView
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.ratings !== prevProps.ratings) {
            this.setState({
                ratingsChart: this.convertRatingChartData(),
            })
        }
        if (this.props.ratingsIndirects !== prevProps.ratingsIndirects) {
            this.setState({
                ratingsIndirects: this.convertRatingIndirectsChartData(),
            })
        }
    }

    /**
     * Set rates in state to show chart
     *
     * @returns
     * @memberof MainInternalUserView
     */
    convertRatingChartData() {
        let ratingsValues = []
        let ratingsColors = []
        let dataSet = []
        let labelsSections = YEAR_MONTHS;
        if (this.props.ratings !== null && this.props.ratings !== undefined) {
            let years = [...new Set(this.props.ratings.map(rating => rating.rating_year))];
            years.forEach(year => {
                labelsSections.forEach((month, index) => {
                    this.props.ratings.forEach(rating => {
                        if (year === rating.rating_year) {
                            let monthIndex = moment().month(rating.rating_date).format("M");
                            if (Number(monthIndex) === (index + 1)) {
                                ratingsValues[index] = rating.final_score
                                ratingsColors[index] = rating.color_final_score
                            }
                        }
                    });
                });
                dataSet.push({
                    data: ratingsValues,
                    labelGroup: year,
                    backgroundColors: ratingsColors
                })
            })
        }
        return dataSet;
    }

    /**
     * Set rates in state to show chart
     *
     * @returns
     * @memberof MainInternalUserView
     */
    convertRatingIndirectsChartData() {
        let ratingsValues = []
        let ratingsColors = []
        let dataSet = []
        let labelsSections = YEAR_MONTHS;
        if (this.props.ratingsIndirects !== null && this.props.ratingsIndirects !== undefined) {
            let years = [...new Set(this.props.ratingsIndirects.map(rating => rating.rating_year))];
            years.forEach(year => {
                labelsSections.forEach((month, index) => {
                    this.props.ratingsIndirects.forEach(rating => {
                        if (year === rating.rating_year) {
                            let monthIndex = moment().month(rating.rating_date).format("M");
                            if (Number(monthIndex) === (index + 1)) {
                                ratingsValues[index] = rating.final_score
                                ratingsColors[index] = rating.color_final_score
                            }
                        }
                    });
                });
                dataSet.push({
                    data: ratingsValues,
                    labelGroup: year,
                    backgroundColors: ratingsColors
                })
            })
        }
        return dataSet;
    }


    /**
    * Method to change the active tab
    */
    onClickToActiveTab = (event, value) => {
        this.setState({ activeTab: value });
    };

    /**
     * Render
     *
     * @returns
     * @memberof AnonymousView
     */
    render() {
        const { classes, user, advanceOptionsSupplier } = this.props;
        let locale = this.props.languages ? this.props.languages.find(language => { return language.active })["code"] : "es";
        moment.locale(locale);
        return (
            <Grid container spacing={24} display="flex">
                {this.props.notificationsSourcing && this.props.notificationsSourcing.Incidentes.length !== 0 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <PendingOutsorcing
                        isLoadingSourcing={this.props.isLoadingSourcing}
                        notificationsSourcing={this.props.notificationsSourcing}
                    />
                </Grid>}
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Paper className={classes.paper}>
                        <div className="user-profile-header">
                            <Grid container>
                                <Grid item xs={1}>
                                    <UserProfileIcon className={classes.avatar} color="secondary" />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant="h6">
                                        <Translate id="common.welcome" />{` ${user.profile.name}`}
                                    </Typography>
                                    <Typography variant="body2">
                                        {` ${this.props.supplier ? this.props.supplier.full_name : ""}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Tabs
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    className={classes.tabs}
                                    value={this.state.activeTab}
                                    onChange={this.onClickToActiveTab}>
                                    <Tab
                                        icon={<Tooltip title={<Translate id="common.notifications" />}><NotificationsIcon /></Tooltip>}
                                        className={classes.tabs}
                                    />
                                    <Tab
                                        icon={<Tooltip title={<Translate id="common.rates" />}><RatesIcon /></Tooltip>}
                                        className={classes.tabs}
                                    />
                                </Tabs>
                                {this.state.activeTab === 0 &&
                                    <PendingTasks {...this.props} />
                                }
                                {this.state.activeTab === 1 &&
                                    <Paper style={{ zIndex: 200 }}>
                                        {advanceOptionsSupplier && advanceOptionsSupplier.rating_type !== "2" && <BarChart
                                            positionTitle="top"
                                            positionLegend="bottom"
                                            height={770}
                                            dataSets={this.state.ratingsChart}
                                        />
                                        }
                                        {advanceOptionsSupplier && advanceOptionsSupplier.rating_type === "2" && <BarChart
                                            positionTitle="top"
                                            positionLegend="bottom"
                                            height={770}
                                            dataSets={this.state.ratingsIndirects}
                                        />
                                        }
                                    </Paper>
                                }
                            </Grid>
                        </Grid>
                    </Paper>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Paper className={classes.paper}>
                        <NoticeCarousel {...this.props} />
                    </Paper>
                </Grid>
            </Grid >
        );
    }
}


function mapStateToProps(state) {
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "" } };
    let selectedCompany = state.profile.get('selectedCompany')
    return {
        user,
        isLoadingUser: state.oidc.isLoadingUser,
        selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
        isLoadingSupplier: state.suppliers.get('isLoadingSupplier'),
        supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
        isLoadingRating: state.ratings.get("isLoadingRating"),
        ratings: state.ratings.get("ratings") ? state.ratings.get("ratings").toJS() : null,
        isLoadingNotices: state.officialNotices.get("isLoadingNotices"),
        noticesInitialView: state.officialNotices.get('noticesInitialView') ? state.officialNotices.get('noticesInitialView').toJS() : null,
        isLoadingNotifications: state.notifications.get("isLoadingNotifications"),
        notifications: state.notifications.get('notifications') ? state.notifications.get('notifications').toJS() : null,
        languages: state.localize.languages,
        ratingsIndirects: state.indirectQualifications.get("ratingsGraph") ? state.indirectQualifications.get("ratingsGraph").toJS() : null,
        advanceOptionsSupplier: state.suppliers.get('advanceOptionsSupplier') ? state.suppliers.get('advanceOptionsSupplier').toJS() : null,
        isLoadingSourcing: state.notifications.get("isLoadingSourcing"),
        notificationsSourcing: state.notifications.get('notificationsSourcing') ? state.notifications.get('notificationsSourcing').toJS() : null,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadRatings: (supplierToken) => {
            return (dispatch(loadRatingsBySupplierFromBackEnd(supplierToken, true)))
        },
        loadRatingsIndirects: (supplierCode) => {
            return (dispatch(loadRatingsIndirectsBySupplierFromBackEnd(supplierCode)))
        },
    }
}


const viewStyles = theme => ({
    card: {
        margin: "5px"
    },
    cardContent: {
        backgroundColor: "white"
    },
    profileOptionTitle: {
        fontWeight: "lighter"
    },
    avatar: {
        margin: 10,
        width: 40,
        height: 40,
    },
    userAvatar: {
        backgroundColor: theme.palette.secondary.main
    },
    userIcon: {
        width: 40,
        height: 40,
    },
    accountAvatar: {
    },
    avatarFileInput: {
        display: "none"
    },
    paper: {
        height: '85vh',
        overflow: 'hidden'
    },
    tabs: {
        maxHeight: 25,
        minHeight: 20
    }
});

export default withauthenticatedLayout(withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(MainExternalUserView))));