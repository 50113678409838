import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import Select from 'react-select';

/** Material UI import section*/
import {
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
	withStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';

/** Resources import section */
import { REALEASED_SERVICES_SECTION } from '../../../../store/helpers/AccreditedWorkshopsHelper';

const StatusInSectionTab = (props) => {
	const {
		sectionName,
		serviceOrderStatus,
		statusInSection,
		classes,
		onServiceOrderStatusChange,
		onRemoveServiceStatusButtonClick
	} = props;

	const getStatusLabel = (code) => {
		const supplier = serviceOrderStatus.find((s) => s.value === code);
		return supplier ? supplier.label : `${code} - Desconocido`;
	};

	const selectOptions = serviceOrderStatus.filter((s) => !statusInSection.some(i => s.value === i.ServiceOrderStatus));

	return (
		<div>
			<div className={classes.suppliersContainer}>
				<Typography variant="body2">
					<Translate id="accreditedWorkshops.serviceStatusInSection.assignedStatus" />
				</Typography>
				<Select
					className={classes.documentTypeSelect}
					options={selectOptions}
					onChange={onServiceOrderStatusChange}
					styles={{
						// menu: (base) => ({ ...base, zIndex: 10 })
						menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
						menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
						menuPortal: (base) => ({ ...base, zIndex: 9999 }) /// THIS IS TO SHOW MENU OVER MODAL
					}}
					menuPosition="fixed" //THIS IS REQUIRED IN MODAL!!
					menuPlacement={'auto'}
				/>
				<div>
					{statusInSection
						.filter((i) => i.SectionName === sectionName)
						.map((statusItem) => (
							<ListItem key={statusItem.Id} ContainerComponent="div" className={classes.activeItem}>
								<ListItemText
									primary={
										<Typography variant="subtitle2" noWrap>
											{getStatusLabel(statusItem.ServiceOrderStatus)}
										</Typography>
									}
								/>
								<ListItemSecondaryAction className={classes.itemListIcon}>
									<ListItemIcon
										data-code={statusItem}
										style={{ cursor: 'pointer' }}
										onClick={() => onRemoveServiceStatusButtonClick(statusItem.ServiceOrderStatus)}
									>
										<DeleteIcon />
									</ListItemIcon>
								</ListItemSecondaryAction>
							</ListItem>
						))}
				</div>
			</div>
		</div>
	);
};

StatusInSectionTab.propTypes = {
	sectionName: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	statusInSection: PropTypes.arrayOf(PropTypes.string).isRequired,
	serviceOrderStatus: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired
		})
	).isRequired,
	onServiceOrderStatusChange: PropTypes.func.isRequired,
	onRemoveServiceStatusButtonClick: PropTypes.func.isRequired
};

StatusInSectionTab.defaultProps = {
	sectionName: REALEASED_SERVICES_SECTION,
	value: 0,
	statusInSection: [],
	serviceOrderStatus: [],
	onServiceOrderStatusChange: () => console.warn('[onServiceOrderStatusChange] not defined!'),
	onRemoveServiceStatusButtonClick: () => console.warn('[onRemoveServiceStatusButtonClick] not defined!')
};

const styles = (theme) => ({
	retentionValue: {
		padding: '10px 0',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	suppliersContainer: {
		'& > *': {
			marginTop: 16
		}
	},
	textField: {
		height: 20,
		width: 100,
		padding: 8
	},
	activeItem: {
		margin: '4px 0',
		padding: '8px 15px',
		backgroundColor: '#F6F6F6',
		borderRadius: '4px',
		color: theme.palette.common.white
	}
});

export default withStyles(styles, { withTheme: true })(StatusInSectionTab);
