import React, {useState} from 'react';
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

/**Import MaterialUi section */
import {Grid, Button, Avatar, Typography,TextField} from '@material-ui/core'
import AddNoticetIcon from '@material-ui/icons/AddTwoTone';

/** section import components */

/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader';
import DatePicker from '../../common/DateTimePicker/DatePicker';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'


const PanelMoldsImport = props => {
    /**props section */
    const {
        onCloseButtonClick,
        classes,
        isSaving,
        onSaveItem,
    } = props


    const [itemModel, setItemModel] = useState(
        {
            description: '',
            medidaMoldeExterna: '',
            weight: '',
            deadline_delivery: ''
        }
    );

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setItemModel({
            ...itemModel,
            [name]: value
        });
    }

    /**
     * On change date delivery
     * @param {*} dataDelivery 
     */
    const onPropertyDeliveryDate = (dataDelivery) => {
        setItemModel({
            ...itemModel,
            "deadline_delivery": dataDelivery
        });
    }

    /**
     * can be add
     */
    const canBeSaved =() =>{
        if (itemModel.deadline_delivery === null)
            return false;
        if (itemModel.deadline_delivery.length === 0)
            return false;
        if(IsNullOrEmpty(itemModel.description))
            return false;
        if (IsNullOrEmpty(itemModel.medidaMoldeExterna))
            return false;
        if (IsNullOrEmpty(itemModel.weight))
            return false;
        else
            return true;
    }

    /**
     * Function on update
     */
     const onSaveItemUpdate = () =>{
        if(props.onSaveItem)
        onSaveItem(itemModel);
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}>
            <PanelHeader
                titlePanel={<Translate id="maquilaOrders.addMold" />}
                icon={<Avatar className={classes.avatar}><AddNoticetIcon /></Avatar>}
                onCloseButtonClick={onCloseButtonClick}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container>
                            <Grid item xs={12}>
                            <Typography>{<Translate id="maquilaOrders.moldDescription"/>}</Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                onChange={onPropertyChange}
                                name='description'
                                margin="normal"
                                variant="outlined"
                                defaultValue={itemModel.description}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.textTitle}><Translate id="maquilaOrders.deadline"/></Typography>
                            <DatePicker
                                onChangeRange={onPropertyDeliveryDate}
                                startDate={itemModel.deadline_delivery ? moment(itemModel.deadline_delivery, 'DD/MM/YYYY') : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                        <br/>
                            <Typography className={classes.textTitle}><Translate id="maquilaOrders.moldDimension"/></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                name='medidaMoldeExterna'
                                margin="dense"
                                variant="outlined"
                                onBlur={onPropertyChange}
                                defaultValue={itemModel.medidaMoldeExterna}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br/>
                            <Typography className={classes.textTitle}><Translate id="maquilaOrders.moldWeight"/></Typography>
                            <TextField
                                fullWidth
                                label={<Translate id="maquilaOrders.moldWeight"/>}
                                className={classes.textField}
                                onChange={onPropertyChange}
                                name='weight'
                                margin="normal"
                                type='number'
                                step={0.5}
                                variant="outlined"
                                defaultValue={itemModel.weight}                               
                            />
                        </Grid>
                    </Grid>
                </div>
                <Grid container>
                    <Grid item xs={12}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            fullWidth
                            variant='outlined'
                            className={classes.saveButton}
                            onClick={onSaveItemUpdate}
                            disabled={!canBeSaved() || isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </LateralPanel >
    );
};

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    saveButton: {
        bottom: 0
    },
    textTitle:{
        marginLeft:"5px",
    }
});

PanelMoldsImport.propTypes = {
    /**
     * close modal
     */
    onCloseButtonClick: propTypes.func,
};

PanelMoldsImport.defaultProps = {
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelMoldsImport))