import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

/** Import component section */
import Dialog from './../../../common/Dialog';
import DialogActions from './../../../common/DialogActions';
import Evidence from './../../../common/Evidence';

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';

/**
 * Class compoenent to add an employee
 *
 * @class EditSocialInsurancePayment
 * @extends {Component}
 */
class EditSocialInsurancePayment extends Component {

    /**
   *Creates an instance of DialogActions.
   * @param {*} props
   * @memberof DialogActions
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = this.initialState();
    }

    /**
     * return initial state of component
     *
     * @returns
     * @memberof EditSocialInsurancePayment
     */
    initialState() {
        return ({
            token: this.props.payment ? this.props.payment.token : '',
            payment_description: this.props.payment ? this.props.payment.description : '',
            file_sua: [],
            file_proof_payment: []
        })
    }

    /**
     * Handle update properties to set data in local state
     *
     * @param {*} property
     * @param {*} data
     * @memberof EditSocialInsurancePayment
     */
    updateProperty(property, data) {
        this.setState({
            [property]: data,
        })
    }

    /**
     * Handle event upload files of evidence componet to add files to list
     *
     * @param {*} files
     * @returns
     * @memberof EditSocialInsurancePayment
     */
    uploadFiles(files, key) {
        let filesList = this.state[key].concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
        }

        this.setState({
            [key]: filesList
        })
        return Promise.resolve('Ok')
    }

    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
     * @memberof AddSocialInsurancePaymentDialog
     */
    removeFiles(fileName, key) {
        let newList = this.state[key].filter(files => {
            return files.name !== fileName
        })
        this.setState({
            [key]: newList
        })
        return Promise.resolve('Ok')
    }

    /**
     * method to call action and edit payment
     *
     * @memberof EditSocialInsurancePayment
     */
    onUpdatePayment() {
        if(this.props.updatePayment){
            this.props.updatePayment(this.state)
        }
    }

    /**
     * Handle  Close or cancel event
     *
     * @memberof EditSocialInsurancePayment
     */
    handleCloseCancel() {
        this.setState(this.initialState(),
            () => this.props.onClose());
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof EditSocialInsurancePayment
     */
    isValidToSave() {
        if ((this.state.payment_description && !IsNullOrEmpty(this.state.payment_description))
            && (this.state.file_sua && this.state.file_sua.length > 0)
            && (this.state.file_proof_payment && this.state.file_proof_payment.length > 0)
        ) {
            return true;
        }
        return false
    }

    /**
     * Method to render text field 
     *
     * @param {*} textFieldId
     * @param {*} textFieldTranslateId
     * @returns
     * @memberof EditSocialInsurancePayment
     */
    renderTextArea(textFieldId, textFieldTranslateId) {
        return (
            <TextField
                id={textFieldId}
                label={<Translate id={textFieldTranslateId}/>}
                value={this.state[textFieldId] ? this.state[textFieldId] : ''}
                margin="normal"
                type={"text"}
                fullWidth
                required
                onBlur={(event) => this.updateProperty(textFieldId, event.target.value)}
                onChange={(event) => this.updateProperty(textFieldId, event.target.value)}
                multiline
                variant='outlined'
                rowsMax={4}
            />
        )
    }

    /**
     * Render Evidence component
     *
     * @param {*} key
     * @param {*} translationId
     * @param {number} [xs=12]
     * @returns
     * @memberof AddSocialInsurancePaymentDialog
     */
    renderEvidence(key, translationId, xs = 12) {
        return (
            <Grid item xs={xs} className={this.props.classes.checkBox}>
                <Typography variant="subtitle2" color={'textSecondary'}>
                    <Translate id={translationId} /> PDF *
                </Typography>
                <Evidence
                    baseApi={process.env.REACT_APP_SIP_URL_FILES}
                    removeFiles={(fileName) => this.removeFiles(fileName, key)}
                    uploadFiles={(files) => this.uploadFiles(files, key)}
                    files={this.state[key] ? this.state[key] : []}
                    acceptedFiles={['pdf']}
                    disabledLink
                    maxFiles={1}
                />
            </Grid>
        )
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id="contractorsModule.addSocialSecurityPayment" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <div>
                        {!this.isValidToSave() &&
                            <Typography variant="caption" color='error'>
                                {<Translate id="common.requiredFields" />}
                            </Typography>
                        }
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => this.handleCloseCancel()}
                            onClose={() => this.handleCloseCancel()}
                            onSaveItem={this.onUpdatePayment}
                            isSaving={this.props.isSaving}
                            canBeSaved={this.isValidToSave()}
                        />
                    </div>
                }>

                <Grid container className={classes.modal} justify='space-between'>
                    {this.renderTextArea('payment_description', 'contractorsModule.paymentDescription')}
                    {this.renderEvidence("file_sua", "contractorsModule.fileSUA")}
                    {this.renderEvidence("file_proof_payment", "contractorsModule.depositReceipt")}
                </Grid>
            </Dialog>
        )
    }
}

const styles = theme => ({
    modal: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxWidth: 500
    }
});

export default withRouter(withStyles(styles, { withTheme: true })(EditSocialInsurancePayment));