///Import react section
import React, { useState, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Translate, getTranslate } from 'react-localize-redux'
import queryString from 'query-string'
/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AddIcon from '@material-ui/icons/AddTwoTone'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import WarehouseWindowsIcon from '@material-ui/icons/Store'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import Fab from '@material-ui/core/Fab'
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import LateralPanelContainer from '../common/LateralPanelComponents/LateralPanelContainer'
import AddRequestComponent from './components/index/AddRequest'
import ViewRequestComponent from './components/index/ViewRequest'
import DonwloadReportDialog from '../common/DonwloadReportDialog'
/**Custom hooks index */
import { useRequestsCustomHookIndex } from './customHooks/UseCustomHookIndex'
import { Tooltip } from '@material-ui/core'
import { downloadHistorialWerehouseWindowsExcel } from '../../store/warehouseWindows/WarehouseWindowsActions'

const IndexView = (props) => {
	const { classes } = props
	// /**Render hooks section */
	// // const classes = styles()
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()
	const queryParams = queryString.parse(location.search)
	const { requests, onAddRequest, onChangeStatus } = useRequestsCustomHookIndex()

	// /**Redux store information section*/
	const isLoading = useSelector((state) => state.warehouseWindows.get('isLoading'))
	const isSaving = useSelector((state) => state.warehouseWindows.get('isSaving'))
	const requestsCount = useSelector((state) => state.warehouseWindows.get('requestsCount'))
	const translate = useSelector((state) => getTranslate(state.localize))
	const [identifierPanelLateral, setIdentifierPanelLateral] = useState('')
	const [selectedItem, setSelectedItem] = useState(null)
	const [opneModal, setOpenModal] = useState(false)

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	const getCounterMessage = () => {
		if (requests) {
			let totalRequests = requests.length
			return (
				<div>
					{<Translate id='common.showing' />}
					<span className={classes.showingCounter}>{totalRequests}</span>&nbsp;
					{<Translate id='common.of' />}
					<span className={classes.showingCounter}>{requestsCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	const handleCloseModalReport = () => {
		setOpenModal(!opneModal)
	}
	const handleDownloadReportByDateRange = (startDate, endDate) => {
		return dispatch(downloadHistorialWerehouseWindowsExcel(startDate, endDate))
		// .then(() => {
		//     this.setState(showSuccessToaster('common.downloadCorrectly'));
		//     this.handleCloseModalHistorialConsultationReport();
		// })
		// .catch((error) => {
		//     this.setState(showErrorToaster('common.errorToDownload'));
		// });
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * onRowClick
	 * @param {*} calendar
	 * @param {*} event
	 */
	const onRowClick = (item, event) => {
		onLateralPanelConfig('IndexViewRequest')
		setSelectedItem(item)
	}

	const onAddRequestFromBackend = (model) => {
		return onAddRequest(model).then(() => {
			onOpenAndCloseLateralPanel()
		})
	}

	const onChangeRequestStatusFromBackend = (item, newStatus) => {
		if (item !== null)
			return onChangeStatus(item, newStatus).then(() => {
				onOpenAndCloseLateralPanel()
			})
	}

	const PanelLateralChildren = useCallback(
		{
			IndexCreateRequest: (
				<AddRequestComponent
					isSaving={isSaving}
					onSaveButtonClick={onAddRequestFromBackend}
					translate={translate}
				/>
			),
			IndexViewRequest: (
				<ViewRequestComponent
					isSaving={isSaving}
					updateStatus={onChangeRequestStatusFromBackend}
					translate={translate}
					item={selectedItem}
					onCloseButtonClick={() => {
						setSelectedItem(null)
					}}
				/>
			),
		},
		[identifierPanelLateral, selectedItem]
	)

	const { LateralPanelComponent, onOpenAndCloseLateralPanel } = LateralPanelContainer({
		children: PanelLateralChildren[identifierPanelLateral],
		identifier: identifierPanelLateral,
	})

	const onLateralPanelConfig = (identifier) => {
		setIdentifierPanelLateral(identifier)
		onOpenAndCloseLateralPanel()
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	const getCardContent = () => {
		if (isLoading === true) {
			return <LoadingPage />
		} else if (requests === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<>
					<DataTable
						data={requests}
						configuration={TableConfiguration()}
						onRenderCellItem={onRenderCellItem}
						onChangeSortCriteria={onChangeSortCriteria}
						onRowClick={onRowClick}
						isIndex
						showFilters
						totalRows={requestsCount}
						sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'FormatedFolio'}
						sortAscending={
							queryParams && queryParams.sortDirection
								? queryParams.sortDirection === 'desc'
									? false
									: true
								: false
						}
						page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
						rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				</>
			)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'Name') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item.Name}
				</Typography>
			)
		}
	}

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<WarehouseWindowsIcon />
					</Avatar>
				}
				action={
					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '5px' }}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={onChangeSearchValue}
								value={!!queryParams && queryParams.query ? queryParams.query : ''}
							/>
						</div>

						<Fab
							className={classes.iconButon}
							size='small'
							color='primary'
							onClick={() => {
								onLateralPanelConfig('IndexCreateRequest')
							}}
						>
							<AddIcon />
						</Fab>
						<Fab
							className={classes.iconButon}
							size='small'
							color='secondary'
							onClick={() => {
								history.push(`/warehouseWindows/calendar`)
							}}
						>
							<CalendarIcon />
						</Fab>
						<Tooltip title={<Translate id='warehouseWindows.reportWherehouse' />}>
							<Fab
								size='small'
								color={'primary'}
								className={classes.btnDownloadReport}
								onClick={handleCloseModalReport}
								// isSaving={this.props.isDonwloading}
							>
								<DownloadIcon />
							</Fab>
						</Tooltip>
					</div>
				}
				title={<Typography variant='button'>{<Translate id='warehouseWindows.title' />}</Typography>}
				subheader={getCounterMessage()}
			/>

			<CardContent>{getCardContent()}</CardContent>

			<LateralPanelComponent />
			<DonwloadReportDialog
				title={<Translate id='warehouseWindows.titleModal' />}
				open={opneModal}
				onClose={handleCloseModalReport}
				// isSaving={this.props.isDonwloading}
				onDonwloadItems={handleDownloadReportByDateRange}
			/>
		</Card>
	)
}

function TableConfiguration() {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='warehouseWindows.table.status' />,
			dataSource: 'status',
		},
		{
			header: <Translate id='warehouseWindows.table.folio' />,
			dataSource: 'Folio',
		},
		{
			header: <Translate id='warehouseWindows.table.creator' />,
			dataSource: 'CreatorName',
			isSortable: true,
		},
		{
			header: <Translate id='warehouseWindows.table.plateNumber' />,
			dataSource: 'PlateNumber',
		},
		{
			header: <Translate id='warehouseWindows.table.deliveryDate' />,
			dataSource: 'DeliverDate',
			isSortable: true,
		},
		{
			header: <Translate id='warehouseWindows.table.operativeUnit' />,
			dataSource: 'OperativeUnit',
			isSortable: true,
		},
		{
			header: <Translate id='warehouseWindows.table.transportType' />,
			dataSource: 'TransportType',
			isSortable: true,
		},
		{
			header: <Translate id='warehouseWindows.table.material' />,
			dataSource: 'Material',
			isSortable: true,
		},
	]

	return { columns }
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	showingCounter: {
		marginLeft: '5px',
	},
	biddingContainer: {
		maxHeight: `calc(100vh - ${275}px)`,
		minHeight: `calc(100vh - ${275}px)`,
		overflowY: 'hidden',
		backgroundColor: 'red',
	},
	categoryIcon: {
		marginLeft: '5px',
	},
	iconButon: {
		marginLeft: '5px',
	},
	btnDownloadReport: {
		marginLeft: '5px',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(IndexView)))
