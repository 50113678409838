import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '../common/Dialog';
import DialogActions from '../common/DialogActions';
import { Toolbar, Typography, TextField } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import { SHOW_EDIT_VIEW } from '../../store/helpers/ResourcesHelper';
import autoBind from 'auto-bind'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';

class DialogAddGuarantee extends Component {
    /**
     * Create an instance of Dialog
     * @param {*} props
     * @memberof DialogAddGuarantee
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            name: "",
            name_english: "",
            name_portuguese: "",
        }
    }


    /**
     * Function to update property of text field in state
     *
     * @param {*} data
     * @memberof DialogAddGuarantee
     */
    updateProperty(data) {
        this.setState({
            [data.id]: data.value,
        })
    }

    /**
     * Function to trigger blur event
     * 
     * @param {*} event
     * @memberof DetailsCard
     */
    onKeyDown(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    /**
     * Validate if Save button is enable 
     * to enable, required properties must has data
     *
     * @returns
     * @memberof DialogAddGuarantee
     */
    canBeSaved() {
        if (!IsNullOrEmpty(this.state.name))
            return true;
        return false;
    }

    /**
     * Handle close or cancel to clean local estate and call prop function after update
     *
     * @memberof DialogAddGuarantee
     */
    handleCloseCancel() {
        this.setState({
            name: "",
            name_english: "",
            name_portuguese: "",
        }, () => this.props.onCloseCancel('showAddCustomerGuaranteeDialog'))
    }

    /**
     * Handle OnSave, if response is 'Ok' then clean state
     *
     * @memberof DialogAddGuarantee
     */
    handleSave() {
        this.props.onSave(this.state).then(value => {
            if (value === 'Ok')
                this.setState({
                    name: "",
                    name_english: "",
                    name_portuguese: "",
                })
        })
    }

    renderTextField(id, translateId) {
        return (
            <TextField id={id} fullWidth
                required={id === 'name'}
                autoFocus={id === 'name'}
                className={this.props.classes.textField}
                label={<Translate id={translateId} />}
                value={this.state[id] ? this.state[id] : ''}
                onBlur={(event) => this.updateProperty(event.target)}
                onChange={(event) => this.updateProperty(event.target)}
                onKeyDown={(event) => this.onKeyDown(event)}
            />
        )
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.showDialog}
                onClose={() => { this.handleCloseCancel() }}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            {this.props.title}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <div>
                        {!this.canBeSaved() &&
                            <Typography variant="caption" color='error'>
                                {<Translate id="common.requiredFields" />}
                            </Typography>
                        }
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => { this.handleCloseCancel() }}
                            onClose={() => { this.handleCloseCancel() }}
                            onSaveItem={() => this.handleSave()}
                            isSaving={this.props.isSaving}
                            canBeSaved={this.canBeSaved()}
                        />
                    </div>
                } >
                <form className={classes.ediitForm}>
                    {this.renderTextField("name", "moduleGuarantee.name")}
                    {this.renderTextField("name_english", "moduleGuarantee.nameEnglish")}
                    {this.renderTextField("name_portuguese", "moduleGuarantee.namePortuguese")}
                </form>
            </Dialog>
        );
    }
}

DialogAddGuarantee.propTypes = {
    showDialog: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onCloseCancel: PropTypes.func.isRequired
};

export default DialogAddGuarantee;