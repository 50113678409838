import React, { Component } from 'react'
import autoBind from 'auto-bind'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { Link } from 'react-router-dom'

/** Material-UI imports section */
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import DevolutionIcon from '@material-ui/icons/ReplyAllOutlined'
import ReworkIcon from '@material-ui/icons/AutorenewOutlined'
import SacsQualityIcon from '@material-ui/icons/Report'
import { Fab, Typography, Paper, IconButton, Tooltip } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import DeliveryOrdersIcon from '@material-ui/icons/Assignment'
import Chip from '@material-ui/core/Chip'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import AcceptIcon from '@material-ui/icons/ThumbUp'
import RejectIcon from '@material-ui/icons/ThumbDown'
import Edit from '@material-ui/icons/Edit'

/** section import components */
import { COMPANY_PTM } from '../../../store/helpers/ResourcesHelper'
import DataTable from '../../common/DataTable'
import OptionsMenu from '../../common/OptionsMenu'

/** section import actions */
import CorrectiveActionCommentsDialog from '../../qualityFailureReport/components/CorrectiveActionCommentsDialog'
import { APPROVAL_ACEPTED, APPROVAL_REJECTED } from '../../../store/helpers/ResourcesHelper'
import { IsCurrentNotActiveDirectoryUser, IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import {
	COLOR_STATUS_ACEPTED,
	COLOR_STATUS_REJECTED,
	COLOR_STATUS_LIGHT,
} from '../../../store/helpers/StatusColorConstants'

/**
 * Component ot render Information partialraw material not conformiti
 */
export default class InformationPartial extends Component {
	/**
	 * Create an instance RawMaterial not conformity
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			orderDelivery: { folio: '', token: '', message: '' },
		}
	}

	/**
	 * Onrender items deriver workflows
	 */
	onRenderItemDeriverdWorkFlows(dataSource, item) {
		if (dataSource === 'workflow_type') {
			if (item.workflow_type === 3) {
				return (
					<Typography color='primary' gutterBottom>
						<ReworkIcon /> {item.folio}
					</Typography>
				)
			}
			if (item.workflow_type === 6) {
				return (
					<Typography color='primary' gutterBottom>
						<DevolutionIcon /> {item.folio}
					</Typography>
				)
			}
			if (item.workflow_type === 4) {
				return (
					<Typography color='primary' gutterBottom>
						<SacsQualityIcon /> {item.folio}
					</Typography>
				)
			}
		}
	}

	/**
	 * Function to verify ext certificado calidat to show
	 * @param {*} supplierToken
	 * @param {*} partNumber
	 * @param {*} lote
	 */
	onVerifExistCertificate(supplierToken, partNumber, lote) {
		this.setState({ orderDelivery: { folio: '', token: '', message: '' } })
		if (this.props.getDeliveryOrderByPartNumberCertificate) {
			this.props
				.getDeliveryOrderByPartNumberCertificate(supplierToken, partNumber, lote)
				.then((result) => {
					if (result) {
						this.setState({ orderDelivery: { folio: result.folio, token: result.token, message: '' } })
					} else {
						this.setState({
							orderDelivery: {
								folio: '',
								token: '',
								message:
									'No existe certificado de calidad para este incumplimineto de material con este proveedor',
							},
						})
					}
				})
				.catch(() => {
					this.setState({
						orderDelivery: {
							folio: '',
							token: '',
							message:
								'No existe certificado de calidad para este incumplimineto de material con este proveedor',
						},
					})
				})
		}
	}

	onRenderEmergentAction(dataSource, item) {
		if (dataSource === 'token') {
			return (
				<span>
					{this.props.rawMaterial.can_delete_emergent_action &&
						IsCurrentActiveDirectoryUser(this.props.currentUser) && (
							<IconButton
								edge='end'
								aria-label='Delete'
								onClick={() => {
									this.props.removeEmergentAction(item.token)
								}}
							>
								<Tooltip title={<Translate id='common.delete' />}>
									<DeleteOutlinedIcon color='error' />
								</Tooltip>
							</IconButton>
						)}
				</span>
			)
		}
	}

	handleOpenItemDialog(dialogId, item, status) {
		this.setState(
			{
				item: {
					...item,
					status,
				},
			},
			() => {
				this.props.handleOpenCloseItemDialog(dialogId)
			}
		)
	}

	onRenderOptionsMenuItemDocument(item, optionToShow) {
		var options = []
		if (
			IsCurrentActiveDirectoryUser(this.props.currentUser) &&
			this.props.rawMaterial.can_delete_emergent_action &&
			item.status !== APPROVAL_ACEPTED &&
			item.status !== APPROVAL_REJECTED
		) {
			if (optionToShow === 1)
				options.push({
					itemClick: () =>
						this.handleOpenItemDialog('showActionRequestCommentsDialog', item, APPROVAL_ACEPTED),
					tooltipTranslation: <Translate id='common.accept' />,
					menuItemIcon: <AcceptIcon color='secondary' />,
				})
			if (optionToShow === 2)
				options.push({
					itemClick: () =>
						this.handleOpenItemDialog('showActionRequestCommentsDialog', item, APPROVAL_REJECTED),
					tooltipTranslation: <Translate id='common.reject' />,
					menuItemIcon: <RejectIcon color='error' />,
				})
		}
		if (
			IsCurrentNotActiveDirectoryUser(this.props.currentUser) &&
			this.props.rawMaterial.can_delete_emergent_action &&
			item.status !== APPROVAL_ACEPTED
		) {
			if (optionToShow === 1)
				options.push({
					itemClick: () => this.props.handleOpenCorrectiveActionDialog('openModalAddCorrectiveAction', item),
					tooltipTranslation: <Translate id='common.edit' />,
					menuItemIcon: <Edit />,
				})
		}
		if (options.length > 0) {
			return (
				<OptionsMenu
					itemToken={item.token}
					handleClickOptions={this.openMenuOptions}
					open={this.state.itemToken === item.token}
					handleClickAway={this.closeMenuOptions}
					options={options}
				/>
			)
		}
	}

	onRenderItemDocument(dataSource, item) {
		if (dataSource === 'document') {
			let doc = `${
				process.env.REACT_APP_SIP_QUALITY_URL_FILES
			}Documents/RawMaterialActionCorrectiveBySupplier/${encodeURIComponent(item.document)}`
			return (
				<a component={'a'} rel='noopener noreferrer' target='_blank' href={doc}>
					{item.document}
				</a>
			)
		}
		if (dataSource === 'options') {
			return (
				<>
					{this.onRenderOptionsMenuItemDocument(item, 1)}
					{this.onRenderOptionsMenuItemDocument(item, 2)}
				</>
			)
		}
	}

	setCustomColorRow() {
		this.props.rawMaterial.corrective_actions.map((item) => {
			var customColor
			if (item.status === APPROVAL_ACEPTED) customColor = COLOR_STATUS_ACEPTED
			if (item.status === APPROVAL_REJECTED) customColor = COLOR_STATUS_REJECTED
			return (item.custom_color = customColor)
		})
	}

	/**
	 * Render
	 */
	render() {
		const { classes } = this.props
		this.setCustomColorRow()
		return (
			<Paper>
				{' '}
				<Divider />
				<List>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='common.supplier' />
									</Typography>
									{': '}
									{this.props.rawMaterial.full_supplier}
								</React.Fragment>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='common.partNumber' />
									</Typography>
									{': '}
									{this.props.rawMaterial.full_part_description}
								</React.Fragment>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='common.preparedBy' />
									</Typography>
									{': '}
									{this.props.rawMaterial.prepared_by}
								</React.Fragment>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='rawmaterialnonconformity.invoicedAmount' />
									</Typography>
									{': '}{' '}
									<Chip
										component='span'
										size='small'
										color='secondary'
										label={this.props.rawMaterial.quantity_of_material_in_invoice}
									/>{' '}
									{' | '}
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='rawmaterialnonconformity.quantityInspected' />
									</Typography>
									{': '}
									<Chip
										component='span'
										size='small'
										color='secondary'
										label={this.props.rawMaterial.quantity_of_material_affected}
									/>
								</React.Fragment>
							}
						/>
					</ListItem>
					<Divider />
					{this.props.rawMaterial && this.props.rawMaterial.company_identifier === COMPANY_PTM && (
						<ListItem divider>
							<ListItemText
								secondary={
									<React.Fragment>
										<Typography
											component='span'
											variant='body2'
											className={classes.inline}
											color='textPrimary'
										>
											{'Lote'}
										</Typography>
										{': '}
										{this.props.rawMaterial.lot_material} {' | '}
										<Typography
											component='span'
											variant='body2'
											className={classes.inline}
											color='textPrimary'
										>
											{'Número de parte del cliente'}
										</Typography>
										{': '}
										{this.props.rawMaterial.customer_part_number}
									</React.Fragment>
								}
							/>
						</ListItem>
					)}
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='common.failure' />
									</Typography>
									{': '}
									{this.props.rawMaterial.failure_non_conformity &&
										this.props.rawMaterial.failure_non_conformity.name}
								</React.Fragment>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='rawmaterialnonconformity.descriptionBrach' />
									</Typography>
									{': '}
									{this.props.rawMaterial.problem_description}
								</React.Fragment>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='rawmaterialnonconformity.specificationCorreclty' />
									</Typography>
									{': '}
									{this.props.rawMaterial.correct_material_condition}
								</React.Fragment>
							}
						/>
					</ListItem>
					{this.props.rawMaterial && this.props.rawMaterial.company_identifier === COMPANY_PTM && (
						<ListItem divider>
							<ListItemText
								secondary={
									<React.Fragment>
										<Typography
											component='span'
											variant='body2'
											className={classes.inline}
											color='textPrimary'
										>
											{'Verificar si existe certificado de calidad: '}
										</Typography>
										{!this.props.rawMaterial.delivery_order_token && (
											<>
												<Fab
													variant='extended'
													size='small'
													component='a'
													target='_blank'
													style={{
														margin: '0.5em 1%',
														color: '#fff',
														backgroundColor: COLOR_STATUS_LIGHT,
													}}
													onClick={() => {
														this.onVerifExistCertificate(
															this.props.rawMaterial.supplier_token,
															this.props.rawMaterial.part_number,
															this.props.rawMaterial.lot_material
														)
													}}
													disabled={this.props.isLoadingCertificate}
												>
													<DoneIcon
														style={{ color: '#fff', backgroundColor: COLOR_STATUS_LIGHT }}
													/>
													<Typography
														style={{ color: '#fff', fontSize: '0.65rem' }}
														variant='caption'
													>
														{' '}
														{'Obtener certificado'}
													</Typography>
												</Fab>

												{this.state.orderDelivery.token && (
													<Link
														color='primary'
														onClick={() =>
															this.props.history.push(
																`/planning/deliveryorder/${this.state.orderDelivery.token}`
															)
														}
														to='#'
													>
														{' '}
														<DeliveryOrdersIcon /> {' Folio: '}
														{this.state.orderDelivery.folio}
													</Link>
												)}
												{this.state.orderDelivery.message && (
													<span> {this.state.orderDelivery.message}</span>
												)}
											</>
										)}
										{this.props.rawMaterial.delivery_order_token && (
											<>
												<Link
													color='primary'
													onClick={() =>
														this.props.history.push(
															`/planning/deliveryorder/${this.props.rawMaterial.delivery_order_token}`
														)
													}
													to='#'
												>
													{' '}
													<DeliveryOrdersIcon /> {' Folio: '}
													{this.props.rawMaterial.delivery_order_folio}
												</Link>
											</>
										)}
									</React.Fragment>
								}
							/>
						</ListItem>
					)}
				</List>
				{this.props.rawMaterial.emergent_actions.length > 0 && (
					<span>
						<br />
						<Typography gutterBottom variant='body2'>
							<strong>{<Translate id='qualityfailurereport.emergentActions' />}</strong>{' '}
						</Typography>
						<DataTable
							data={this.props.rawMaterial.emergent_actions}
							configuration={TableEmergentActionsConfiguration}
							onRenderCellItem={this.onRenderEmergentAction}
						/>
					</span>
				)}
				{this.props.rawMaterial.corrective_actions.length > 0 && (
					<span>
						<br />
						<Typography gutterBottom variant='body2'>
							<strong>{<Translate id='qualityfailurereport.correctiveActionSupplier' />}</strong>{' '}
						</Typography>
						<DataTable
							data={this.props.rawMaterial.corrective_actions}
							configuration={TableConfigurationCorrectiveActions}
							onRenderCellItem={this.onRenderItemDocument}
						/>
					</span>
				)}
				{/* List from corrective Actions  */}
				{this.props.rawMaterial.derived_workflows.length > 0 && (
					<span>
						<br />
						<Typography gutterBottom variant='body2'>
							<strong>{<Translate id='qualityfailurereport.trackingReport' />}</strong>{' '}
						</Typography>
						<DataTable
							data={this.props.rawMaterial.derived_workflows}
							configuration={TableDerivedWorkflows}
							onRenderCellItem={this.onRenderItemDeriverdWorkFlows}
							onRowClick={(item) => {
								if (item.workflow_type === 3) {
									this.props.history.push(`/reworks/${item.token}`)
								} else if (item.workflow_type === 6) {
									this.props.history.push(`/devolutions/${item.token}`)
								} else if (item.workflow_type === 4) {
									this.props.history.push(`/sacsquality/${item.token}`)
								}
							}}
						/>
					</span>
				)}
				<CorrectiveActionCommentsDialog
					isSaving={this.props.isSaving}
					open={this.props.open}
					onClose={() => this.props.handleOpenCloseItemDialog('showActionRequestCommentsDialog')}
					item={this.state.item}
					saveComment={this.props.onAcceptCorrectiveAction}
					classes={this.props.classes}
				/>
			</Paper>
		)
	}
}

const TableEmergentActionsConfiguration = {
	columns: [
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
		},
		{
			header: <Translate id='common.createBy' />,
			dataSource: 'create_by',
		},
		{
			header: <Translate id='qualityfailurereport.descriptionEmergentAction' />,
			dataSource: 'description',
		},
		{
			header: '',
			dataSource: 'token',
		},
	],
}

const TableConfigurationCorrectiveActions = {
	columns: [
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
		},
		{
			header: <Translate id='common.createBy' />,
			dataSource: 'create_by',
		},
		{
			header: <Translate id='qualityfailurereport.descriptionCorrectiveAction' />,
			dataSource: 'description',
		},
		{
			header: <Translate id='common.document' />,
			dataSource: 'document',
		},
		{
			header: <Translate id='common.comments' />,
			dataSource: 'quality_comments',
		},
		{
			header: '',
			dataSource: 'options',
		},
	],
}

const TableDerivedWorkflows = {
	columns: [
		{
			header: <Translate id='common.folio' />,
			dataSource: 'workflow_type',
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
		},
		{
			header: <Translate id='common.createBy' />,
			dataSource: 'create_by',
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
		},
	],
}

/**
 * rawMaterial
 */
InformationPartial.propTypes = {
	rawMaterial: PropTypes.object.isRequired,
}
