/** Helpers import section */
import axios from 'axios'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import { onGetBlobType } from '../helpers/BlobTypeHelper'
export const IS_SAVING = 'IS_SAVING'
export const START_LOADING_FACTORING_AFILIATION = 'START_LOADING_FACTORING_AFILIATION'
export const SET_FACTORING_AFILIATION = 'SET_FACTORING_AFILIATION'
export const SET_FACTORING_AFILIATION_DETAIL = 'SET_FACTORING_AFILIATION_DETAIL'
export const SET_FACTORING_DETAIL = 'SET_FACTORING_DETAIL'
/**
 * Action start loading common data
 * @param {*} isLoading
 */
function isLoadingCommon(isLoading, identifier) {
	return {
		type: START_LOADING_FACTORING_AFILIATION,
		isLoading: isLoading,
		identifier: identifier,
	}
}

/**
 * Action start saving information
 * @param {*} isLoading
 */
function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
		identifier: identifier,
	}
}

function setFactoringAffiliationPaginated(factoringAffiliation) {
	return {
		type: SET_FACTORING_AFILIATION,
		factoringAffiliation: factoringAffiliation,
	}
}

function setFactoringAffiliationDetail(factoringAffiliationDetail) {
	return {
		type: SET_FACTORING_AFILIATION_DETAIL,
		factoringAffiliationDetail: factoringAffiliationDetail,
	}
}

function setFactoringDetail(factoring) {
	return {
		type: SET_FACTORING_DETAIL,
		factoring: factoring,
	}
}

export function loadFactoringAffiliationPaginatedFromBackEnd(queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, START_LOADING_FACTORING_AFILIATION))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings?`
		if (queryParams && queryParams.query) endPoint = endPoint + `query=${queryParams.query}`
		if (queryParams && queryParams.pageNumber) endPoint = endPoint + `&pageNumber=${queryParams.pageNumber - 1}`
		if (queryParams && queryParams.rowsPerPage) endPoint = endPoint + `&pageSize=${queryParams.rowsPerPage}`
		if (queryParams && queryParams.SupplierCode)
			endPoint = endPoint + `SupplierCode=${queryParams.SupplierCode}||equal`

		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		if (selectedCompany && selectedCompany.company_code)
			endPoint = endPoint + `CompanyCode=${selectedCompany.company_code}||equal`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setFactoringAffiliationPaginated(serverResponse.data))
					dispatch(isLoadingCommon(false, START_LOADING_FACTORING_AFILIATION))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, START_LOADING_FACTORING_AFILIATION))
				console.error('Error getting factoring afiliation', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function createFactoringAffiliationBackEnd(factoringAffiliationModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		let user = getState().oidc.user
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings`
			let command = {
				SupplierInFactoring: {
					Name: factoringAffiliationModel ? factoringAffiliationModel.SupplierName : '',
					Code: factoringAffiliationModel ? factoringAffiliationModel.SupplierCode : '',
					Email: factoringAffiliationModel ? factoringAffiliationModel.Email : '',
					PhoneNumber: factoringAffiliationModel ? factoringAffiliationModel.PhoneNumber : '',
					RFC: factoringAffiliationModel ? factoringAffiliationModel.SupplierRFC : '',
					Nationality: factoringAffiliationModel ? factoringAffiliationModel.Nationality.value : '',
				},
				CreatedByFactoring: {
					CreatedBy: user.profile.name,
					CreatedUserName: user.profile.preferred_username,
					EmailCreator: user.profile.email ? user.profile.email : '-',
				},
				CompanyCode: selectedCompany.company_code,
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, IS_SAVING))
						return Promise.resolve(serverResponse.data)
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, IS_SAVING))
					console.error('Error create factoring affiliation', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, IS_SAVING))
		}
	}
}

export function updateSupplierInFactoringAffiliationBackEnd(id, email, phoneNumber) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings`
			let command = {
				Id: id,
				Email: email,
				PhoneNumber: phoneNumber,
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, IS_SAVING))
						return Promise.resolve(serverResponse.data)
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, IS_SAVING))
					console.error('Error create factoring affiliation', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, IS_SAVING))
		}
	}
}

export function loadFactoringAffiliationFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_FACTORING_AFILIATION_DETAIL))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings/${id}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SET_FACTORING_AFILIATION_DETAIL))
					dispatch(setFactoringAffiliationDetail(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SET_FACTORING_AFILIATION_DETAIL))
				console.error('Error getting factoring request detail', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function createFactoringHistoryBackEnd(factoringRequestId) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		let user = getState().oidc.user
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings/create/history`
			let command = {
				FactoringRequestId: factoringRequestId,
				CreatedByFactoring: {
					CreatedBy: user.profile.name,
					CreatedUserName: user.profile.preferred_username,
					EmailCreator: user.profile.email ? user.profile.email : '-',
				},
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, IS_SAVING))
						return Promise.resolve(serverResponse.data)
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, IS_SAVING))
					console.error('Error create factoring', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, IS_SAVING))
		}
	}
}

export function loadFactoringHistoryFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_FACTORING_AFILIATION_DETAIL))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings/history/${id}/detail`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SET_FACTORING_AFILIATION_DETAIL))
					dispatch(setFactoringDetail(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SET_FACTORING_AFILIATION_DETAIL))
				console.error('Error getting factoring detail', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function updateFactoringBackEnd(
	factoringRequestId,
	step,
	currencies,
	paymentConditions,
	annualPurchase,
	aceptedBySupplier,
	commentRejectedSupplier
) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings/history/detail`
			let command = {
				FactoringId: factoringRequestId,
				Step: step,
				Currencies: currencies,
				PaymentConditions: paymentConditions ? paymentConditions : '-',
				AnnualPurchase: annualPurchase ? annualPurchase : 0,
				AceptedBySupplier: aceptedBySupplier,
				CommentRejectedSupplier: commentRejectedSupplier ? commentRejectedSupplier : '-',
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, IS_SAVING))
						dispatch(setFactoringDetail(serverResponse.data))
						return Promise.resolve(serverResponse.data)
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, IS_SAVING))
					console.error('Error create factoring', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, IS_SAVING))
		}
	}
}

export function addFactoringsFiles(id, attachments, folderName) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings/history/detail/${id}/${folderName}`
		let formData = new FormData()
		attachments.forEach((file) => {
			formData.append('data', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setFactoringDetail(serverResponse.data))
					dispatch(isSavingCommon(false, IS_SAVING))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, IS_SAVING))
				console.error('Error add attachments in the factoring', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Delete consignment note zip attachments
 * @param {string} id
 * @returns
 */
export function deleteFactoringsFiles(id, fileName, folderName) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))

		const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings/history/detail/${id}/${fileName}/${folderName}`
		return axios
			.delete(endPoint, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setFactoringDetail(serverResponse.data))
					dispatch(isSavingCommon(false, IS_SAVING))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, IS_SAVING))
				console.error('Error add attachments in the factoring', error.response)
				return Promise.reject()
			})
	}
}

export const downloadFactoringFile = (id, fileName, folderName) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			config.responseType = 'arraybuffer'
			const endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings/history/detail/${id}/${fileName}/${folderName}`
			let command = {}
			let serverResponse = await axios.put(endPoint, command, {
				responseType: 'arraybuffer',
				headers: config.headers,
			})
			let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName
			if (serverResponse.status === 200) {
				let fileBlog = new Blob([serverResponse.data], {
					type: onGetBlobType(fileExtension),
				})
				let url = window.URL.createObjectURL(fileBlog)
				let a = document.createElement('a')
				a.href = url
				a.download = `${fileName}`
				a.click()
				return Promise.resolve(url)
			}
			return Promise.resolve('Ok')
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}

export function synchronizeFactoringSap(factoringId, covenantId) {
	return function (dispatch, getState) {
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		if (selectedCompany) {
			dispatch(isSavingCommon(true, IS_SAVING))
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings/factoringSynchronizeSap/${selectedCompany.partnership_code}/${factoringId}/${covenantId}`
			return axios
				.post(endPoint, [], configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, IS_SAVING))
						dispatch(setFactoringDetail(serverResponse.data))
						return Promise.resolve(serverResponse.data)
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, IS_SAVING))
					console.error('Error synchronize factoring with SAP', error.response)
					return Promise.reject(error.response)
				})
		}
	}
}

export function notificateSupplierFromFactoring(factoringId){
	return function (dispatch,getState){
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Factorings/factoringNotificateSupplier/${factoringId}`
		return axios
			.post(endPoint, [], configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error while notificate supplier', error.response)
				return Promise.reject(error.response)
			})
	}
}