import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'

/** Material UI import section */
import { Fab, Tooltip, Typography, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'

/** Custom components import section */
import MetricsCard from './MetricsCard'
import ButtonDownload from '../../components/ButtonDownload'
import Spacer from '../../../common/Spacer'
import InstructionsCard from './InstructionsCard'
import ColumnsConfigurator from '../../../common/DataGrid/ColumnsConfigurator'

/** Custom hooks import section */
import useClearFiltersButton from '../../hooks/ClearFiltersButtonHook'
import ButtonTableColumnSettings from '../../components/ButtonColumnsTableSettings'
import ButtonQuickFilters from '../../components/ButtonQuickFilters'


const ServiceOrdersToolbar = (props) => {
	const {
		translate,
		allServiceMetrics: { loading: loadingTotalMetrics, totalAmount, numberOfServiceOrders },
		selectedServiceMetrics: {
			displayed: displayedSelectedMetrics,
			loading: loadingSelectedMetrics,
			totalAmount: totalSelectedAmount,
			numberOfServiceOrders: totalSelectedServiceOrders,
		},
		columnSettingsButton: {
			displayed: displayedColumnSettings,
			tableSettings: serviceTableConfiguration,
			onTableSettingsChange: onConfigurationChange,
		},
		downloadingButton: { downloading, onClick: onDownloadButtonClick },
		creatingPaymentRequestButton: { locked, disabled, onClick: onCreatingPymentRequestButtonClick },
		setOpenQuickFilterDialog,
		classes,
	} = props

	const tooltipMessage = locked
		? translate('accreditedWorkshops.makePaymentRequestLocked')
		: translate('accreditedWorkshops.makePaymentRequest')

	const [ClearFiltersButton] = useClearFiltersButton()
	const [configuratorAnchorEl, setConfigurationAnchorEl] = useState(null)

	const openTableColumnsConfigurator = (event) => {
		setConfigurationAnchorEl(configuratorAnchorEl ? null : event.currentTarget)
	}

	const handleTableSettingsChange = (configuration) => {
		setConfigurationAnchorEl(null)
		onConfigurationChange(configuration)
	}

	return (
		<div className={classes.root}>
			<Spacer />
			<InstructionsCard />
			<Spacer />
			{displayedSelectedMetrics && (
				<MetricsCard
					title='Servicios seleccionados'
					loading={loadingTotalMetrics}
					quantityOfServices={totalSelectedServiceOrders}
					amount={totalSelectedAmount}
				/>
			)}
			<Spacer />
			<MetricsCard
				title='Total de servicios'
				loading={loadingSelectedMetrics}
				quantityOfServices={numberOfServiceOrders}
				amount={totalAmount}
			/>
			<Spacer />
			<ClearFiltersButton />
			<Spacer />
			<ButtonQuickFilters onClick={setOpenQuickFilterDialog} />
			<Spacer />
			<ButtonDownload onClick={onDownloadButtonClick} showCircularProgress={downloading} />
			{displayedColumnSettings && (
				<>
					<Spacer />
					<ButtonTableColumnSettings onShowConfiguration={openTableColumnsConfigurator} />
					<ColumnsConfigurator
						open={configuratorAnchorEl ? true : false}
						anchorEl={configuratorAnchorEl}
						configuration={serviceTableConfiguration}
						onConfigurationChange={handleTableSettingsChange}
						onClickAway={() => setConfigurationAnchorEl(null)}
					/>
				</>
			)}
			<Spacer />
			<Tooltip
				title={
					<Typography variant='body1' style={{ color: 'white' }}>
						{tooltipMessage}
					</Typography>
				}
			>
				<div>
					<Fab
						size='small'
						color={!locked ? 'secondary' : 'primary'}
						onClick={onCreatingPymentRequestButtonClick}
						disabled={locked || disabled}
						style={{
							height: 35,
							width: 35,
						}}
					>
						<AddIcon />
					</Fab>
				</div>
			</Tooltip>
		</div>
	)
}

ServiceOrdersToolbar.propTypes = {
	columnSettingsButton: PropTypes.shape({
		displayed: PropTypes.bool,
		onClick: PropTypes.func,
		tableSettings: PropTypes.array,
	}),
	allServiceMetrics: PropTypes.shape({
		loading: PropTypes.bool,
		totalAmount: PropTypes.number,
		numberOfServiceOrders: PropTypes.number,
	}).isRequired,
	selectedServiceMetrics: PropTypes.shape({
		displayed: PropTypes.bool,
		loading: PropTypes.bool,
		totalAmount: PropTypes.number,
		numberOfServiceOrders: PropTypes.number,
	}).isRequired,
	downloadingButton: PropTypes.shape({
		downloading: PropTypes.bool,
		onClick: PropTypes.func,
	}).isRequired,
	creatingPaymentRequestButton: PropTypes.shape({
		locked: PropTypes.bool,
		disabled: PropTypes.bool,
		onClick: PropTypes.func,
	}).isRequired,
	cleanAllFiltersButton: {
		disabled: true,
		onClick: PropTypes.func,
	},
}

ServiceOrdersToolbar.defaultProps = {
	columnSettingsButton: {
		displayed: false,
		onClick: () => console.log('[onClick] method from columnSettingsButton config is undefined'),
	},
	allServiceMetrics: {
		loading: false,
		totalAmount: 0,
		totalServiceOrders: 0,
	},
	selectedServiceMetrics: {
		displayed: false,
		loading: false,
		totalAmount: 0,
		totalServiceOrders: 0,
	},
	downloadingButton: {
		downloading: false,
		onClick: () => console.log('[onClick] method from downloadingButton config is undefined'),
	},
	creatingPaymentRequestButton: {
		locked: false,
		disabled: false,
		onClick: () => console.log('[onClick] method from creatingPaymentRequestButton config is undefined'),
	},
}

const styles = (theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
})

export default withLocalize(withStyles(styles, { withTheme: true })(ServiceOrdersToolbar))
