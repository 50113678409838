import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import LinkMaterial from '@material-ui/core/Link'

/** Material-UI imports section */
import { Grid, Tooltip } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import ContractIcon from '@material-ui/icons/ImportContacts'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { Edit } from '@material-ui/icons'

/** Import component section */
import DataTable from '../../common/DataTable'
import { getColorStatusContract, getDescriptionStatusContract } from '../../../store/helpers/DocumentsHelper'
import { GetTrackingContractType } from '../../../store/helpers/SelectOptions'
import { COLOR_STATUS_WARNING } from '../../../store/helpers/StatusColorConstants'

/**
 * Tab to ContractsTabs
 */
class ContractsTabs extends Component {
	/**
	 *Creates an instance of ContractsTabs.
	 * @param {*} props
	 * @memberof ContractsTabs
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * onRenderCellItem
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'status') {
			return (
				<Tooltip title={getDescriptionStatusContract(item.status)}>
					<Avatar data-cy='avatar' style={{ backgroundColor: getColorStatusContract(item.status) }}>
						<ContractIcon />
					</Avatar>
				</Tooltip>
			)
		}
		if (dataSource === 'name') {
			return (
				<LinkMaterial
					key={item.token}
					component={'a'}
					target='_blank'
					color='primary'
					href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/Documents/SupplierContract/${this.props.supplierContractor.token}/${item.token}.pdf`}
				>
					<DownloadIcon style={{ marginTop: '-7px' }} /> {item.name}
				</LinkMaterial>
			)
		}
		if (dataSource === 'contractType') {
			let option = GetTrackingContractType()[item.contractType]
			return item.contractType === 0 ? '-' : option.label
		}
		if (dataSource === 'notes') {
			return <div style={{ width: '280px' }}>{item.notes}</div>
		}
		if (dataSource === 'is_contract_main') {
			if (item.is_contract_main) return 'Si'
			else return '-'
		}
		if (dataSource === 'token') {
			return (
				<Grid>
					<Tooltip title={<Translate id='common.edit' />}>
						<Edit
							onClick={() => {
								this.props.onEditItem(item)
							}}
							style={{
								zIndex: '990',
								cursor: 'pointer',
								color: COLOR_STATUS_WARNING,
							}}
						/>
					</Tooltip>
					<Tooltip title={<Translate id='common.delete' />}>
						<DeleteOutlinedIcon
							onClick={() => {
								this.props.onRemoveItem(item.token)
							}}
							style={{ zIndex: '990', cursor: 'pointer' }}
							color='primary'
						/>
					</Tooltip>
				</Grid>
			)
		}
	}

	/**
	 * Dialog to update information
	 */
	render() {
		const { supplierContractor, canBeModuleContractsSupplier } = this.props
		return (
			<Grid>
				<DataTable
					data={supplierContractor.contracts_detail}
					configuration={TableConfiguration(canBeModuleContractsSupplier(this.props.userRolesByCompany))}
					onRenderCellItem={this.onRenderCellItem}
				/>
			</Grid>
		)
	}
}

ContractsTabs.defaultProps = {}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: <Translate id='officialNotices.status' />,
			dataSource: 'status',
		},
		{
			header: <Translate id='constracts.title' />,
			dataSource: 'name',
		},
		{
			header: 'Tipo de contrato',
			dataSource: 'contractType',
		},
		{
			header: <Translate id='officialNotices.activationDate' />,
			dataSource: 'start_date',
		},
		{
			header: <Translate id='officialNotices.expirationDate' />,
			dataSource: 'end_date',
		},
		{
			header: <Translate id='common.createBy' />,
			dataSource: 'creator_name',
		},
		{
			header: <Translate id='common.comments' />,
			dataSource: 'notes',
		},
		{
			header: 'Marcar como activo',
			dataSource: 'is_contract_main',
		},
	]
	if (isDisabled) {
		columns.push({
			header: '',
			dataSource: 'token',
		})
	}
	return { columns }
}

const dialogStyles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
})

export default withStyles(dialogStyles, { withTheme: true })(ContractsTabs)
