import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { TextField } from '@material-ui/core'
import LinkMaterial from '@material-ui/core/Link'
import AcceptIcon from '@material-ui/icons/ThumbUp'
import RejectIcon from '@material-ui/icons/ThumbDown'
import SuppliersIcon from '@material-ui/icons/LibraryBooks'
import RequestIcon from '@material-ui/icons/ExploreOff'
import WaitingIcon from '@material-ui/icons/Explore'
import DoesNotApplyIcon from '@material-ui/icons/FileCopy'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

/** Import component section */
import Evidence from '../../common/Evidence'
import DataTable from '../../common/DataTable'
import OptionsMenu from '../../common/OptionsMenu'
import DialogConfirmationRemove from '../Components/DialogConfirmation'

/** Import action section */
import { onKeyDown } from '../../../store/helpers/EventsHelper'
import {
	COMPANY_IDENTIFIER_IMBERA_MX,
	DOCUMENT_WAITING,
	DOCUMENT_REQUEST,
	DOCUMENT_ADDED,
	DOCUMENT_REJECTED,
	DOCUMENT_ACEPTED,
	DOCUENT_DOESNOTAPPLY,
} from '../../../store/helpers/ResourcesHelper'
import { COLOR_ALTERNATE, COLOR_QUESTIONNAIRE_CHERRY } from '../../../store/helpers/StatusColorConstants'
import { GettipeFileProspects } from '../../../store/helpers/ExpedientSupplier'

/**
 * Container show prospect
 */
class DocumentsProspect extends Component {
	/**
	 * Create an instance prospect
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAcepted: false,
			openRejected: false,
			openConfirmationDialog: false,
			removingDocumentMessage: '',
			prospectToken: '',
			fileToken: '',
			itemDescription: '',
		}
	}

	/**
	 * Handle event upload files of devidence componet to add files to list
	 *
	 * @param {*} files
	 * @returns
	 * @memberof AddActionToListDialog
	 */
	uploadFiles(fileToken, files) {
		this.props.uploadDocumentsProspect(fileToken, files)
		return Promise.resolve('Ok')
	}

	/**
	 * Remove files
	 */
	removeFiles(fileName) {
		return Promise.resolve('Ok')
	}

	/**
	 * onRenderCellItem
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		let prospectToken = this.props.prospect.token
		let canbeSupplier = this.props.prospect.can_be_edit_prospect && this.props.is_active_prospect
		let canUpdatePlant = this.props.prospect.can_be_update_in_plant && (this.props.canBeAuthorizer || (this.props.isSupplierDevelopAdministrator && item.nomenclature_name === '_CEI_MP'))
		if (dataSource === 'logo') {
			return <SuppliersIcon style={{ color: item.color_status }} />
		}
		if (dataSource === 'description') {
			return (
				<Typography style={{ maxWidth: '300px' }} variant='caption'>
					{item.description}
					<br />
					{item.notes && (
						<Typography variant='caption' style={{ color: '#1e72cc' }}>
							<strong>
								<Translate id='expedientSupplier.notes' />:
							</strong>{' '}
							{item.notes}
						</Typography>
					)}

					<span>
						{item.attachments &&
							item.attachments.map((file) => {
								return (
									<span key={file.name}>
										<LinkMaterial
											target='_blank'
											href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${file.relativePath}`}
										>
											{file.name}
										</LinkMaterial>
										<br />
									</span>
								)
							})}
					</span>
				</Typography>
			)
		}
		if (dataSource === 'file_status') {
			if (item.file_status === 2)
				return (
					<Typography style={{ color: item.color_status, width: '62px' }} variant='caption'>
						<Translate id='expedientSupplier.fileExpedientStatus.documentAdded' />
					</Typography>
				)
			if (item.file_status === 3)
				return (
					<Typography style={{ color: item.color_status, width: '62px' }} variant='caption'>
						<Translate id='expedientSupplier.fileExpedientStatus.documentAccepted' />
					</Typography>
				)
			if (item.file_status === 4)
				return (
					<Typography style={{ color: item.color_status, width: '62px' }} variant='caption'>
						<Translate id='expedientSupplier.fileExpedientStatus.documentRejected' />
					</Typography>
				)
			if (item.file_status === 5)
				return (
					<Typography style={{ color: item.color_status, width: '62px' }} variant='caption'>
						<Translate id='expedientSupplier.fileExpedientStatus.documentInWaiting' />
					</Typography>
				)
			if (item.file_status === 6)
				return (
					<Typography style={{ color: item.color_status, width: '62px' }} variant='caption'>
						<Translate id='expedientSupplier.fileExpedientStatus.documentAlreadyValid' />
					</Typography>
				)
			else
				return (
					<Typography style={{ color: item.color_status, width: '62px' }} variant='caption'>
						<Translate id='expedientSupplier.fileExpedientStatus.documentRequest' />
					</Typography>
				)
		}
		if (dataSource === 'document_name') {
			return (
				<React.Fragment>
					{canbeSupplier && item.can_be_add_file && (
						<div style={{ width: '280px' }}>
							{' '}
							<Evidence
								text={this.props.translate('dropzone.dropzoneBase')}
								baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
								removeFiles={(fileName) => this.removeFiles(item.token, fileName)}
								uploadFiles={(files) => this.uploadFiles(item.token, files)}
								files={[]}
								acceptedFiles={GettipeFileProspects(item.file_type)}
								disabledLink
							/>
						</div>
					)}
					<LinkMaterial
						component={'a'}
						target='_blank'
						href={`${process.env.REACT_APP_SIP_URL_LOGOS_ADMINISTRATION}/Documents/Prospects/${prospectToken}/Files/${item.document_name}`}
					>
						{' '}
						{item.document_name}
					</LinkMaterial>
				</React.Fragment>
			)
		}
		if (dataSource === 'observations_supplier') {
			return this.renderTextArea('observations_supplier', item, !canbeSupplier)
		}
		if (dataSource === 'observations_in_plant') {
			return this.renderTextArea('observations_in_plant', item, !canUpdatePlant)
		}
		if (dataSource === 'options') {
			const prospectType =
				this.props.prospect && this.props.prospect.prospect_type ? this.props.prospect.prospect_type : 0
			return this.renderOptionsMenu(item, prospectType)
		}
	}

	/**
	 * renderTextArea
	 * @param {*} key
	 * @param {*} item
	 * @param {*} isDisabled
	 */
	renderTextArea(key, item, isDisabled) {
		return (
			<React.Fragment>
				{!isDisabled && (
					<TextField
						fullWidth
						key={key}
						defaultValue={item[key] ? item[key] : ''}
						onBlur={(event) => {
							this.props.updatePropertyProspect(item.token, key, event.target.value)
						}}
						onKeyDown={(event) => onKeyDown(event)}
						margin='normal'
						disabled={isDisabled}
						inputProps={{
							style: { fontSize: 12 },
						}}
					/>
				)}
				{isDisabled && (
					<Typography align='center' variant='caption'>
						{item[key] ? item[key] : ''}
					</Typography>
				)}
			</React.Fragment>
		)
	}

	/**
	 * Handle state to set token of item to show options menu list
	 *
	 * @param {*} itemToken
	 * @memberof ShowExpedientBySupplier
	 */
	handleClickOptionsMenu(itemToken) {
		this.setState({
			itemToken,
		})
	}

	/**
	 * Handle state to show or hide menu options list
	 *
	 * @memberof ShowExpedientBySupplier
	 */
	handleCloseOptionsMenu() {
		if (!this.state.openModalAuthorization) {
			this.setState((state) => ({
				itemToken: '',
			}))
		}
	}

	/**
	 * Change status docunet in on update status file
	 * @param {*} token
	 * @param {*} status
	 */
	onUpdateStatusFile(token, status) {
		this.props.updateStatusFileInPlant(token, status).then(() => {
			this.handleCloseOptionsMenu()
		})
	}

	/**
	 * Remove document from index
	 * @param {*} token
	 */
	onRemoveDocument(prospect, description, token) {
		this.setState({
			openConfirmationDialog: true,
			prospectToken: prospect,
			itemDescription: description,
			fileToken: token,
		})
		this.handleCloseOptionsMenu()
	}

	/**
	 * Method to render OptionsMenu
	 *
	 * @param {*} item
	 * @returns
	 * @memberof AnnotationsRMI
	 */
	renderOptionsMenu(item, prospectType) {
		var options = []
		if (!this.props.prospect.is_closed && this.props.canBeAuthorizer && item.file_status === DOCUMENT_REQUEST) {
			//File change status to DOCUMENT_WAITING
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUMENT_WAITING),
				tooltipTranslation: <Translate id='expedientSupplier.documentWaiting' />,
				menuItemIcon: <WaitingIcon color='primary' />,
			})
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUENT_DOESNOTAPPLY),
				tooltipTranslation: <Translate id='expedientSupplier.documentDoesNotApply' />,
				menuItemIcon: <DoesNotApplyIcon style={{ color: COLOR_ALTERNATE }} />,
			})
			if (this.props.prospect.company_code === COMPANY_IDENTIFIER_IMBERA_MX && this.props.prospect.status === 1) {
				options.push({
					itemClick: () => this.onRemoveDocument(this.props.prospect.token, item.description, item.token),
					tooltipTranslation: <Translate id='expedientSupplier.documentRemove' />,
					menuItemIcon: <DeleteForeverIcon style={{ color: COLOR_QUESTIONNAIRE_CHERRY }} />,
				})
			}
		}
		if (!this.props.prospect.is_closed && this.props.canBeAuthorizer && item.file_status === DOCUMENT_WAITING) {
			//File change status to DOCUMENT_REQUEST
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUMENT_REQUEST),
				tooltipTranslation: <Translate id='expedientSupplier.documentRequest' />,
				menuItemIcon: <RequestIcon color='secondary' />,
			})
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUENT_DOESNOTAPPLY),
				tooltipTranslation: <Translate id='expedientSupplier.documentDoesNotApply' />,
				menuItemIcon: <DoesNotApplyIcon style={{ color: COLOR_ALTERNATE }} />,
			})
			if (this.props.prospect.company_code === COMPANY_IDENTIFIER_IMBERA_MX && this.props.prospect.status === 1) {
				options.push({
					itemClick: () => this.onRemoveDocument(this.props.prospect.token, item.description, item.token),
					tooltipTranslation: <Translate id='expedientSupplier.documentRemove' />,
					menuItemIcon: <DeleteForeverIcon style={{ color: COLOR_QUESTIONNAIRE_CHERRY }} />,
				})
			}
		}
		if (
			this.props.prospect.can_be_update_in_plant &&
			this.props.canBeAuthorizer &&
			item.nomenclature_name !== '_CEI_MP' &&
			(item.file_status === DOCUMENT_ADDED ||
				item.file_status === DOCUMENT_REJECTED ||
				item.file_status === DOCUMENT_ACEPTED)
		) {
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUMENT_ACEPTED),
				tooltipTranslation: <Translate id='common.accept' />,
				menuItemIcon: <AcceptIcon style={{ color: '#3AC47D' }} />,
			})
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUMENT_REJECTED),
				tooltipTranslation: <Translate id='common.reject' />,
				menuItemIcon: <RejectIcon color='error' />,
			})
		}
		// Opciones específicas para el tipo de prospecto "Materia prima" y descripción "Cuestionario de evaluación Inicial (Ind)"
		if (
			(prospectType === 1 ||
				prospectType === 8 ||
				prospectType === 11 ||
				prospectType === 13 ||
				prospectType === 15) &&
			item.nomenclature_name === '_CEI_MP' &&
			this.props.prospect.can_be_update_in_plant &&
			this.props.isSupplierDevelopAdministrator &&
			item.file_status !== DOCUMENT_ACEPTED &&
			item.file_status !== DOCUMENT_REJECTED
		) {
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUMENT_ACEPTED),
				tooltipTranslation: <Translate id='common.accept' />,
				menuItemIcon: <AcceptIcon style={{ color: '#3AC47D' }} />,
			})
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUMENT_REJECTED),
				tooltipTranslation: <Translate id='common.reject' />,
				menuItemIcon: <RejectIcon color='error' />,
			})
		}
		return (
			<React.Fragment>
				<OptionsMenu
					itemToken={item.token}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === item.token}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
					xsSize
				/>
				<DialogConfirmationRemove
					title={'Confirmacion para eliminar documento'}
					opened={this.state.openConfirmationDialog}
					onClose={() => {
						this.setState({ openConfirmationDialog: false })
					}}
					message={
						<Typography>
							<Translate id='expedientSupplier.messageRemoveconfirmation' />
							{this.state.itemDescription}
							{'?'}
						</Typography>
					}
					action={() => {
						this.props.removeDocument(this.state.prospectToken, this.state.fileToken)
					}}
				/>
			</React.Fragment>
		)
	}
	render() {
		const { prospect, canBeAuthorizer,isSupplierDevelopAdministrator , isDisabledObsPlant } = this.props
		return (
			<Grid item xs={12}>
				<Typography style={{ marginLeft: '30px' }} gutterBottom variant='body2'>
					<strong>{'Total de documentos requeridos del prospecto: '}</strong>{' '}
					{prospect && prospect.countTotalFiles.length !== 0 && prospect.countTotalFiles}
				</Typography>
				<DataTable
					data={prospect.prospectus_download_files}
					configuration={TableFilesConfiguration(canBeAuthorizer || isSupplierDevelopAdministrator, isDisabledObsPlant)}
					onRenderCellItem={this.onRenderCellItem}
				/>
			</Grid>
		)
	}
}

function TableFilesConfiguration(isDisableOptions, isDisabledObsPlant) {
	let columns = [
		{
			header: '',
			dataSource: 'logo',
		},
		{
			header: <Translate id='expedientSupplier.documents' />,
			dataSource: 'description',
		},
		{
			header: <Translate id='expedientSupplier.formatType' />,
			dataSource: 'file_type',
		},
		{
			header: <Translate id='expedientSupplier.attachDocument' />,
			dataSource: 'document_name',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'file_status',
		},
		{
			header: 'Observaciones del prospecto',
			dataSource: 'observations_supplier',
		},
	]

	if (isDisabledObsPlant)
		columns.push({
			header: <Translate id='expedientSupplier.observationsInPlant' />,
			dataSource: 'observations_in_plant',
		})

	//Validation to show or hide this column
	if (isDisableOptions)
		columns.push({
			header: '',
			dataSource: 'options',
		})

	return { columns }
}

export default DocumentsProspect
