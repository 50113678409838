import React, { Component } from 'react';
import { connect } from 'react-redux'
import autoBind from 'auto-bind';
import {Translate} from "react-localize-redux";
import Select from 'react-select'

import { loaAlldEmployeesFromBackEnd } from '../../../store/contractors/EmployeesAction';

/**
 * EmployeeSelector selector container
 *
 * @class EmployeeSelector
 * @extends {Component}
 */
class EmployeeSelector extends Component {

    /**
     *Creates an instance of EmployeeSelector.
     * @param {*} props
     * @memberof EmployeeSelector
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            isLoading: true,
            employees: []
        }
    }

          /**
     * componentDidMount
     */
    componentDidMount() {
        if (!this.props.isLoadingUser) {
            this.props.loadEmployees()
                .then((employees) => {
                let employeesd = employees.map((employee) =>{
                    return {
                        value: employee.token,
                        label: employee.name
                    }
                })
                this.setState({
                    employees:employeesd
                })
                return employees.map((employee) => {
                    return {
                        value: employee.token,
                        label: employee.name
                    }
                })
            })
        }
    }
  
    /**
     * On select a employee 
     *
     * @param {*} option
     * @memberof employees
     */
    onSelectedEmployeeClick(option) {
        if(this.state.employees){
            let selectedEmployeed = this.state.employees.find((employeed) => {
                return employeed.value === option.value
            })
            if (this.props.onEmployeedSelected) {
                this.props.onEmployeedSelected(selectedEmployeed)
            }
        }
    }
  
    /**
     *  
     *
     * @returns
     * @memberof onSelectedEmployeeClick
     */
    render() {
        return (
            <Select 
                placeholder={<Translate id="contractorsModule.medicalchecks.searchEmployeed"/>}
                options={this.state.employees}
                onChange={this.onSelectedEmployeeClick}
                isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
                styles={{
                    menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                    menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                }}
            />
        );
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    return {
        isLoadingUser: state.oidc.isLoadingUser,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadEmployees: () => {
            return dispatch(loaAlldEmployeesFromBackEnd())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSelector);