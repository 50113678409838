/** React import section */
import React, { Component } from 'react';


/* Material UI component section import  */
import {DialogContentText, DialogContent,DialogActions} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

/** Language imports **/
import {Translate} from "react-localize-redux";

/**
 * Component view dialog change company
 */
class DialogChangeCompany extends Component{

    /**
     * Option to show list the companies to change
     */
    getCompaniesList() {
        const { grantedCompanies } = this.props;
        if (grantedCompanies) {
            return (
                <List>
                    {grantedCompanies.map((company) => {
                        return (
                            <div key={company.token}>
                                <ListItem button onClick={() => {this.props.changeCompany(company.document_identifier)}}>
                                    <ListItemText color="primary" primary={company.commercial_name.toUpperCase()} />
                                </ListItem>
                                <Divider />
                            </div>
                        )
                    })}
                </List>
            )
        } else {
            return ;
        }
    }

    /**
     * Render dialog
     */
    render(){
        return(
            <Dialog
                open={this.props.openCompanyDialog}
                onClose={() => this.props.setOpenDialogCompany(false)}
                aria-labelledby="Cambiar compañia" >
                <DialogTitle id="form-dialog-title"><DialogContentText><Translate id="companies.selectCompany"/></DialogContentText></DialogTitle>
                <Divider />
                <DialogContent className="dialogContent-padding-custom">
                    <List>
                        <ListItem>{this.getCompaniesList()}</ListItem>
                        <Divider light />
                    </List>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => this.props.setOpenDialogCompany(false)} color="secondary">
                        <Translate id="common.cancel"/>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
export default DialogChangeCompany;