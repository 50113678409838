import { fromJS } from 'immutable'

import * as factoringActions from './FactoringAffiliationActions'

/**
 * contraActions initial state
 */
export const factoringInitialState = fromJS({
	isLoading: true,
	identifier: '',
	factoringAffiliationPaginated: {
		items: [],
		pageNumber: 0,
		pageSize: 20,
		total: 0,
		sort: {
			by: 'SupplierCode',
			direction: 'desc',
		},
	},
	factoringAffiliationDetail: null,
	factoring: null,
})

export default function companiesReducer(state = factoringInitialState, action) {
	switch (action.type) {
		case factoringActions.START_LOADING_FACTORING_AFILIATION: {
			return state.merge({
				isLoading: action.isLoading,
				identifier: action.identifier,
			})
		}
		case factoringActions.IS_SAVING: {
			return state.merge({
				isSaving: action.isSaving,
				identifier: action.identifier,
			})
		}
		case factoringActions.SET_FACTORING_AFILIATION: {
			return state.merge({
				factoringAffiliationPaginated: fromJS(action.factoringAffiliation),
				isSaving: false,
				identifier: '',
			})
		}
		case factoringActions.SET_FACTORING_AFILIATION_DETAIL: {
			return state.merge({
				isLoading: false,
				identifier: '',
				factoringAffiliationDetail: action.factoringAffiliationDetail,
			})
		}
		case factoringActions.SET_FACTORING_DETAIL: {
			return state.merge({
				isLoading: false,
				identifier: '',
				factoring: action.factoring,
			})
		}
		default:
			return state
	}
}
