import { fromJS } from 'immutable';
import * as sacsQualityActions from './SacsQualityActions';

/**
 * Defines the initial state of SacsQuality
 */
export const initialState = fromJS({
    isLoadingSacsQuality: true,
    sacsQuality: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    sacsQualityCount: null,
    isSaving: false,
    isLoading: false,
    sacQuality: null,
    isAllSacsReports: false,
})

/**
 * function to Action cases of SacsQuality 
 * @param {*} state 
 * @param {*} action 
 */
export default function SacsQualityReducer(state = initialState, action) {
    switch (action.type) {
        case sacsQualityActions.START_LOADING_SACS_QUALITY: {
            return state.merge({
                isLoadingSacsQuality: action.isLoading
            })
        }
        case sacsQualityActions.SET_SACS_QUALITY_LIST: {
            let sacsQualityLists = fromJS(action.sacsQuality);
            return state.merge({
                sacsQuality: sacsQualityLists,
                isLoadingSacsQuality: false,
                isLoading: false,
                isSaving: false,
            })
        }
        case sacsQualityActions.CHANGE_SACS_QUALITY_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case sacsQualityActions.CHANGE_SACS_QUALITY_QUERY: {
            return state.merge({
                searchQuery: action.queryquery,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case sacsQualityActions.CHANGE_SACS_QUALITY_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case sacsQualityActions.IS_LOADING_ALL_SACS_REPORTS: {
            return state.merge({
                isAllSacsReports: action.isAllReports
            })
        }
        case sacsQualityActions.SET_SACS_QUALITY_COUNT: {
            return state.merge({
                sacsQualityCount: action.count
            })
        }
        case sacsQualityActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case sacsQualityActions.IS_LOADING: {
            return state.merge({
                isLoading: action.isLoading
            })
        }
        case sacsQualityActions.SET_SAC_QUALITY: {
            return state.merge({
                isLoading: false,
                isSaving: false,
                sacQuality: fromJS(action.sacQuality)
            })
        }
        case sacsQualityActions.ADD_FILE_SACS_QUALITY: {
            let oldList = state.getIn(['sacQuality', 'attachments']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['sacQuality', 'attachments'], fromJS(newList));
        }
        case sacsQualityActions.REMOVE_FILE_SACS_QUALITY: {
            let oldList = state.getIn(['sacQuality', 'attachments']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['sacQuality', 'attachments'], fromJS(newList));
        }
        case sacsQualityActions.REMOVE_NON_CONFORMITY_CANCEL_TO_LIST: {
            return state.updateIn(['sacQuality', 'derived_workflows'], (allWorkflows) => {
                let index = allWorkflows.findIndex((item) => {
                    return item.get('token') === action.tokenNonConformity
                });
                return allWorkflows.delete(index).set('isSaving', false);
            })
        }
        default:
            return state;
    }
}