import { createSelector } from 'reselect'
import { toSafeObject } from '../../store/helpers/ListHelper'

export const consignmentShowSelector = createSelector(
	(state) => state.consignmentNote.get('consignmentNote'),
	(showConsignmentMap) => {
		let deliveries = []
		let showConsignment = toSafeObject(showConsignmentMap)
		// Define all the deliveries
		let destionId = 0
		if (showConsignment) {
			showConsignment.deliveries.forEach((delivery) => {
				if (delivery.recipient) {
					var existRecipient = deliveries.find((d) => d.code === delivery.recipient.code)
					if (existRecipient === undefined) {
						deliveries.push({
							id: delivery.id,
							destionId: destionId + 1,
							rfc: delivery.recipient.rfc,
							code: delivery.recipient.code,
							name: delivery.recipient.name,
							recipient: delivery.recipient,
						})
					} else {
						destionId++
					}
				}
			})
		}
		return deliveries
	}
)

/**
 * Get materials list by  recipient and deliveries
 * @param {*} recipient
 * @param {*} deliveries
 * @returns
 */
export function getDeliveriesByEntry(recipient, consignmentNote) {
	if (recipient === null) return []

	let deliveriesList = consignmentNote.deliveries.filter((d) => d.recipient !== null)
	deliveriesList = deliveriesList.filter((d) => d.recipient.code === recipient.code)
	return deliveriesList
}

export const deliveriesListSelector = createSelector(
	(state) => state.consignmentNote.get('deliveriesList'),
	(deliveriesListMaps) => toSafeObject(deliveriesListMaps)
)

export const deliveriesSelector = createSelector(deliveriesListSelector, (deliveriesList) => {
	let deliveries = []
	let destionId = 0
	deliveriesList.forEach((delivery) => {
		if (delivery.code) {
			var existDelidery = deliveries.find((d) => d.code === delivery.code)
			if (existDelidery === undefined) {
				deliveries.push({
					destionId: destionId + 1,
					id: delivery.id,
					code: delivery.code,
					value: delivery.code,
					label: delivery.code,
					materials: delivery.materials,
				})
			} else {
				destionId++
			}
		}
	})
	return deliveries
})

export const getMaterials = (materialsList) => {
	let materials = []

	if (materialsList === null) return materials

	let destionId = 0
	materialsList.forEach((material) => {
		var existMaterial = materials.find((d) => d.code === material.code)
		if (existMaterial === undefined) {
			materials.push({
				destionId: destionId + 1,
				code: material.code,
				value: material.code,
				label: material.code,
				material: material,
			})
		} else {
			destionId++
		}
	})
	return materials
}
