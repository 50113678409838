import React from 'react'
import propTypes from 'prop-types'
import Dialog from '../../../common/Dialog'
import { Toolbar, Typography, withStyles, Grid } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import DialogActions from '../../../common/DialogActions'
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper'
import DatePicker from '../../../common/DateTimePicker/DatePicker'
import moment from 'moment'
import { Translate } from 'react-localize-redux'

const IncidentsReportDialogTemplate = (props) => {
	const {
		classes,
		open,
		onClose,
		endDate,
		startDate,
		isLoading,
		onDownloadReport,
		onChangeStartDate,
		onChangeEndDate,
	} = props
	const canBeSavedDates = () => {
		if (endDate === null) return false
		if (startDate === null) return false
		return true
	}
	return (
		<Dialog
			open={open}
			onClose={onClose}
			header={
				<Toolbar>
					<Typography>
						<Translate id={'carrierTenders.incident.title'} />
					</Typography>
				</Toolbar>
			}
			actions={
				<DialogActions
					view={SHOW_EDIT_VIEW}
					classes={classes}
					onCancel={onClose}
					onClose={onClose}
					onSaveItem={onDownloadReport}
					isSaving={isLoading}
					canBeSaved={canBeSavedDates()}
				/>
			}
		>
			<Grid container>
				<Typography color='primary'>
					<Translate id={'carrierTenders.incident.helpText'} />
				</Typography>
				<Grid item xs={6}>
					<Typography variant='subtitle2' color='primary'>
						<Translate id={'carrierTenders.incident.startDate'} />
					</Typography>
					<DatePicker
						opens={'left'}
						onChangeRange={(event) => onChangeStartDate(event)}
						startDate={startDate ? moment(startDate) : ''}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant='subtitle2' color='primary'>
						<Translate id={'carrierTenders.incident.endDate'} />
					</Typography>
					<DatePicker
						opens={'left'}
						onChangeRange={(event) => onChangeEndDate(event)}
						startDate={endDate ? moment(endDate, 'DD/MM/YYYY') : ''}
						minDate={startDate}
					/>
				</Grid>
			</Grid>
		</Dialog>
	)
}
const styles = (theme) => ({})
IncidentsReportDialogTemplate.propTypes = {
	open: propTypes.bool,
	isLoading: propTypes.bool,
	onClose: propTypes.func,
	onChangeDate: propTypes.func,
	onDownloadReport: propTypes.func,
}
IncidentsReportDialogTemplate.defaultProps = {
	open: false,
	isLoading: false,
	onClose: () => console.warn('No [onClose] Call Back defined'),
	onChangeDate: () => console.warn('No [onChangeDate] Call Back defined'),
	onDownloadReport: () => console.warn('No [onDownloadReport] CallBack defined'),
}
export default withRouter(withStyles(styles, { withTheme: true })(IncidentsReportDialogTemplate))
