import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import Select from 'react-select'
import moment from 'moment'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import DataTable from '../common/DataTable'
import Dialog from '../common/Dialog'
import OptionsMenu from '../common/OptionsMenu'
import DonwloadReportDialog from '../common/DonwloadReportDialog'
import SincronizeSApPanel from './components/SincronizeSApPanel'
import Toaster from '../common/Toaster'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import BusinessIcon from '@material-ui/icons/BusinessTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import SuppliersIcon from '@material-ui/icons/ShoppingCartTwoTone'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import ArchiveDownloadIcon from '@material-ui/icons/Archive'
import Grid from '@material-ui/core/Grid'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import SyncUpIcon from '@material-ui/icons/AutorenewOutlined'
import { withStyles } from '@material-ui/core/styles'
import { GetApp } from '@material-ui/icons'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadSuppliersFromBackEnd,
	SincronizeSapSupplier,
	getSuppliersCount,
	CHANGE_SUPPLIERS_SORT_CRITERIA,
	CHANGE_SUPPLIERS_QUERY,
	CHANGE_SUPPLIERS_PAGINATION,
} from '../../store/suppliers/SupplierActions'
import { downloadReportRampSuppliers, downloadReportSignUsersExternal } from '../../store/suppliers/ExternalUserActions'
import { downloadReportEmployeeInsurancePayment } from '../../store/suppliers/SupplierActions'
import { downloadReportByDateRange } from '../../store/ratings/ratingActions'
import { GetYears, GetMonths, getSelectedValue } from '../../store/helpers/SelectOptions'
import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers'
import { COMPANY_IDENTIFIER_IMBERA_MX, COMPANY_PTM } from '../../store/helpers/ResourcesHelper'
import { ROLE_COMPANYADMINISTRATOR, IsUserInRole } from '../../store/helpers/RolesHelper'
import { converListToArray } from '../../store/helpers/ListHelper'

/**
 * Supplier list
 *
 * @class Suppliers list
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance of suppliers list
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showMenu: true,
			itemToken: null,
			showModalDownloadReport: false,
			showModalReportUsers: false,
			showModalReportRamp: false,
			showSapPanel: false,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			initialDate: {
				selectedMonth: moment().month(),
				selectedYear: moment().year(),
			},
			finalDate: {
				selectedMonth: moment().month(),
				selectedYear: moment().year(),
			},
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadSuppliers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getSuppliersCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoadingUser !== this.props.isLoadingUser && this.props.isLoadingUser === false) {
			this.props.loadSuppliers()
			this.props.getSuppliersCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadSuppliers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		if (dataSource === 'logo') {
			if (item.logo) {
				let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${item.logo}`
				return <Avatar src={urlImage} className={classes.logo} data-cy='suppier-avatar' />
			} else {
				return (
					<Avatar data-cy='suppier-avatar' className={classes.logo}>
						<BusinessIcon />
					</Avatar>
				)
			}
		}
	}

	/**
	 * Handle on close optons menu to set void item token in state
	 *
	 * @memberof index
	 */
	handleCloseOptionsMenu() {
		if (!this.state.showModalDownloadReport || !this.showModalReportUsers) {
			this.setState(
				{
					itemToken: '',
					showMenu: false,
				},
				() => {
					this.setState({ showMenu: true })
				}
			)
		}
	}

	/**
	 *Open modal for set report by mont
	 *
	 * @memberof Index
	 */
	handleOpenModal() {
		this.setState({
			showModalDownloadReport: true,
		})
	}

	handlerOpenModalUsers() {
		this.setState({
			showModalReportUsers: true,
		})
	}

	handlerOpenModalRamp() {
		this.setState({
			showModalReportRamp: true,
		})
	}

	handleOpenSapPanel() {
		this.setState({
			showSapPanel: true,
		})
	}

	/**
	 * close modal to report by month
	 */
	handleCloseModal() {
		this.setState(
			{
				showModalDownloadReport: false,
				showModalReportUsers: false,
				showModalReportRamp: false,
				showSapPanel: false,
				initialDate: {
					selectedMonth: moment().month(),
					selectedYear: moment().year(),
				},
				finalDate: {
					selectedMonth: moment().month(),
					selectedYear: moment().year(),
				},
			},
			() => this.handleCloseOptionsMenu()
		)
	}

	/**
	 *Download report rating by supplier by date range
	 *
	 * @memberof Index
	 */
	onDownloadReportByDateRange() {
		let initialDate = `01-${this.state.initialDate.selectedMonth}-${this.state.initialDate.selectedYear}`
		let lastDayOfMonth = moment(`${this.state.finalDate.selectedYear}-${this.state.finalDate.selectedMonth}-01`)
			.endOf('month')
			.format('DD')
		let finalDate = `${lastDayOfMonth}-${this.state.finalDate.selectedMonth}-${this.state.finalDate.selectedYear}`

		this.props
			.downloadReportByDateRange(initialDate, finalDate)
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
				this.showModalDownloadReport()
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	showModalDownloadReport() {
		this.setState(
			{
				showModalDownloadReport: !this.state.showModalDownloadReport,
			},
			() => {
				if (!this.state.showModalDownloadReport)
					this.setState({
						...this.state,
						initialDate: {
							selectedMonth: moment().month(),
							selectedYear: moment().year(),
						},
						finalDate: {
							selectedMonth: moment().month(),
							selectedYear: moment().year(),
						},
					})
			}
		)
	}

	onSetSelection(selection, id) {
		this.setState({
			[id.split('.')[0]]: {
				...this.state[id.split('.')[0]],
				[id.split('.')[1]]: selection.value,
			},
		})
	}

	renderSelect(id, value, options) {
		return (
			<Select
				id={id}
				value={value}
				options={options}
				onChange={(selection) => this.onSetSelection(selection, id)}
				styles={{
					menu: (base) => ({ ...base, zIndex: 1000, height: 150 }),
					menuList: (base) => ({ ...base, height: 150, paddingTop: 0 }),
					menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
				}}
				menuPortalTarget={document.parentNode}
				menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
				menuPlacement={'auto'}
				isDisabled={this.props.isSaving}
			/>
		)
	}

	renderCalendar() {
		let years = GetYears()
		let months = GetMonths()
		return (
			<div style={{ margin: 10, width: 500 }}>
				<Grid container spacing={8}>
					<Grid item xs={12} md={6}>
						<Typography variant='caption'>
							<Translate id='rating.selectInitialDate' />
						</Typography>
						{this.renderSelect(
							'initialDate.selectedMonth',
							months[this.state.initialDate.selectedMonth],
							months
						)}{' '}
						<br />
						{this.renderSelect(
							'initialDate.selectedYear',
							getSelectedValue(years, this.state.initialDate.selectedYear),
							years
						)}
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant='caption'>
							<Translate id='rating.selectFinalDate' />
						</Typography>
						{this.renderSelect(
							'finalDate.selectedMonth',
							months[this.state.finalDate.selectedMonth],
							months
						)}{' '}
						<br />
						{this.renderSelect(
							'finalDate.selectedYear',
							getSelectedValue(years, this.state.finalDate.selectedYear),
							years
						)}
					</Grid>
				</Grid>
			</div>
		)
	}

	/**
	 * Render buttons for download report by month
	 */
	renderModalButtons() {
		return (
			<DialogActions>
				<Button
					disabled={
						this.state.initialDate.selectedYear > this.state.finalDate.selectedYear ||
						(this.state.initialDate.selectedYear === this.state.finalDate.selectedYear &&
							this.state.initialDate.selectedMonth > this.state.finalDate.selectedMonth) ||
						this.props.isSaving
					}
					onClick={this.onDownloadReportByDateRange}
					color='primary'
				>
					<Translate id={this.props.isSaving ? 'common.downloadingReport' : 'common.download'} />
				</Button>
				<Button
					onClick={() => this.handleCloseModal()}
					color='secondary'
					disabled={this.props.isLoadingReportUsers}
				>
					<Translate id='common.cancel' />
				</Button>
			</DialogActions>
		)
	}

	renderModalButtonsRamp() {
		return (
			<DialogActions>
				<Button
					disabled={
						this.state.initialDate.selectedYear > this.state.finalDate.selectedYear ||
						(this.state.initialDate.selectedYear === this.state.finalDate.selectedYear &&
							this.state.initialDate.selectedMonth > this.state.finalDate.selectedMonth) ||
						this.props.isSaving
					}
					onClick={this.onDownloadReportRamp}
					color='primary'
				>
					<Translate id={this.props.isSaving ? 'common.downloadingReport' : 'common.download'} />
				</Button>
				<Button onClick={() => this.handleCloseModal()} color='secondary' disabled={this.props.isSaving}>
					<Translate id='common.cancel' />
				</Button>
			</DialogActions>
		)
	}

	/**
	 * Function to donwload report.
	 */
	onDownloadReportUsers(startDate, endDate) {
		this.props
			.downloadReportSignUsersExternal(startDate, endDate)
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
				this.setState({ showModalReportUsers: false })
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	onDownloadReportRamp(startDate, endDate) {
		this.props
			.downloadReportRampSuppliers(startDate, endDate)
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
				this.setState({ showModalReportRamp: false })
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	/**
	 * Function to donwload report.
	 */
	onDownloadReportEmployeeInsurancePayment() {
		this.props
			.downloadReportEmployeeInsurancePayment()
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
				this.setState(
					{
						itemToken: '',
						showMenu: false,
					},
					() => {
						this.setState({ showMenu: true })
					}
				)
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	onSincronizeSapSupplier(supplierNumber) {
		this.props
			.sincronizeSapSupplier(supplierNumber)
			.then(() => {
				this.setState(showSuccessToaster('users.successSynchronization'))
				this.setState({ showSapPanel: false })
			})
			.catch((error) => {
				this.setState(showErrorToaster('users.errorSynchronization'))
			})
	}

	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof Index
	 */
	handleClickOptionsMenu(token) {
		this.setState({
			itemToken: token,
		})
	}

	/**
	 *Render action button in card rating report
	 *
	 * @memberof Index
	 */
	renderAction() {
		let isVisibleReportSupplierInsurancePayment =
			this.props.selectedCompany &&
			(this.props.selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
				this.props.selectedCompany.get_company_identifier === COMPANY_PTM)
		var options = []
		options.push({
			itemClick: () => this.handleOpenModal(),
			tooltipTranslation: <Translate id='rating.downloadReport' />,
			menuItemIcon: <ArchiveDownloadIcon color='secondary' />,
			isLoading: this.props.isSaving,
		})
		options.push({
			itemClick: () => this.handlerOpenModalUsers(),
			tooltipTranslation: <Translate id='users.downloadReportUsers' />,
			menuItemIcon: <DownloadIcon color='secondary' />,
			isLoading: this.props.isLoadingReportUsers,
		})
		if (
			isVisibleReportSupplierInsurancePayment &&
			IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany)
		) {
			options.push({
				itemClick: () => this.onDownloadReportEmployeeInsurancePayment(),
				tooltipTranslation: <Translate id='users.titleReportEmployeeInsurancePayment' />,
				menuItemIcon: <DownloadIcon color='secondary' />,
				isLoading: this.props.isLoadingReportSupplier,
			})
		}
		options.push({
			itemClick: () => this.handlerOpenModalRamp(),
			tooltipTranslation: <Translate id='users.downloadReportRamp' />,
			menuItemIcon: <GetApp color='secondary' />,
			isLoading: this.props.isLoadingReportUsers,
		})
		if (IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany)) {
			options.push({
				itemClick: () => this.handleOpenSapPanel(),
				tooltipTranslation: <Translate id='users.synchronizeSapSupplier' />,
				menuItemIcon: <SyncUpIcon color='secondary' />,
				isLoading: this.props.isLoadingSapSupplier,
			})
		}
		if (this.state.showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === 'item.token'}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	/**
	 * Get suppliers counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getSuppliersCounterMessage() {
		const { classes } = this.props
		if (this.props.suppliers) {
			let totalSuppliers = this.props.suppliers.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{totalSuppliers}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.suppliersCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingSuppliers === true) {
			return <LoadingPage />
		} else if (this.props.suppliers === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.suppliers}
					configuration={TableConfiguration}
					onRenderCellItem={this.onRenderCellItem}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(supplier) => {
						this.props.history.push(`/suppliers/${supplier.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.suppliersCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof Supplier
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<SuppliersIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>
							{this.renderAction()}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='suppliers.suppliers' />{' '}
						</Typography>
					}
					subheader={this.getSuppliersCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				<Dialog
					open={this.state.showModalDownloadReport}
					onClose={this.handleCloseModal}
					header={
						<Typography style={{ margin: 15 }}>
							<Translate id='rating.downloadReport' />
						</Typography>
					}
					children={this.renderCalendar()}
					actions={this.renderModalButtons()}
				/>

				{/** Dialog to download report user sing portal external */}
				<DonwloadReportDialog
					title={<Translate id='users.titleReportUsers' />}
					open={this.state.showModalReportUsers}
					onClose={this.handleCloseModal}
					isSaving={this.props.isLoadingReportUsers}
					onDonwloadItems={this.onDownloadReportUsers}
					saveButtonTranslate={'common.downloadReport'}
				/>

				{/** Dialog to download report user sing portal external */}
				<Dialog
					header={
						<Typography style={{ margin: 15 }}>
							<Translate id='users.downloadReportRamp' />
						</Typography>
					}
					open={this.state.showModalReportRamp}
					onClose={this.handleCloseModal}
					isSaving={this.props.isLoadingReportUsers}
					children={
						<Typography style={{ margin: 15 }}>{'Descargar reporte de rampas por proveedor'}</Typography>
					}
					actions={this.renderModalButtonsRamp()}
				/>
				{/** Panel to synchronize sap whit supplier number */}
				<SincronizeSApPanel
					open={this.state.showSapPanel}
					onClose={this.handleCloseModal}
					onSave={this.onSincronizeSapSupplier}
					isSaving={this.props.isLoadingSapSupplier}
				/>
				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='suppliers.supplierName' />,
			dataSource: 'full_name',
		},
		{
			header: <Translate id='suppliers.address' />,
			dataSource: 'full_address',
		},
		{
			header: '',
			dataSource: 'logo',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingSuppliers: state.suppliers.get('isLoadingSuppliers'),
		isLoadingReportSupplier: state.suppliers.get('isLoadingReportSupplier'),
		isLoadingSapSupplier: state.suppliers.get('isLoadingSapSupplier'),
		suppliers: state.suppliers.get('suppliers') ? state.suppliers.get('suppliers').toJS() : null,
		sortCriteria: state.suppliers.get('sortCriteria') ? state.suppliers.get('sortCriteria').toJS() : null,
		searchQuery: state.suppliers.get('searchQuery'),
		page: state.suppliers.get('page'),
		rowsPerPage: state.suppliers.get('rowsPerPage'),
		suppliersCount: state.suppliers.get('suppliersCount'),
		isSaving: state.ratings.get('isSaving'),
		isLoadingReportUsers: state.externalUsers.get('isLoadingReportUsers'),
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSuppliers: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadSuppliersFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_SUPPLIERS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_SUPPLIERS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_SUPPLIERS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getSuppliersCount: () => {
			dispatch(getSuppliersCount())
		},
		downloadReportByDateRange: (initialDate, finalDate) => {
			return dispatch(downloadReportByDateRange(initialDate, finalDate))
		},
		downloadReportSignUsersExternal: (startDate, endDate) => {
			return dispatch(downloadReportSignUsersExternal(startDate, endDate))
		},
		downloadReportRampSuppliers: (startDate, endDate) => {
			return dispatch(downloadReportRampSuppliers(startDate, endDate))
		},
		downloadReportEmployeeInsurancePayment: () => {
			return dispatch(downloadReportEmployeeInsurancePayment())
		},
		sincronizeSapSupplier: (supplierNumber) => {
			return dispatch(SincronizeSapSupplier(supplierNumber))
		},
	}
}

const styles = (theme) => ({
	logo: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
