import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import GuaranteeReportIcon from '@material-ui/icons/FileCopyOutlined';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Toaster from '../common/Toaster';
import FormViewEdit from './components/FormatViewEdit';
import LoadingPage from '../common/LoadingPage';

import { converListToArray } from '../../store/helpers/ListHelper'
import {loadGuaranteeReport, editGuarranteeeReportFromBackEnd} from '../../store/guaranteeReport/guaranteeReportAction';

/**
 * Component to edit guarantee report
 */
class EditReport extends Component{

     /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
        }
    }

    /**
     * componentDidMount
     */
    componentDidMount(){
        let guarranteeToken = this.props.match.params.token;
        this.props.loadGuaranteeReport(guarranteeToken);
    }


    /**
     * Get action to Edit report guarrantee in draft
     */
    saveEditGuarranteeReport(supplier, partNumber, customerSelected, failureSelected, guarrantee){
        this.props.editGuarranteeeReportFromBackEnd(supplier, partNumber, customerSelected, failureSelected, guarrantee)
        .then(() =>{
            this.setState({
                showToView:true,
                showToaster: false,
            })
            let guarranteeToken = this.props.match.params.token;
            this.props.history.push(`/guaranteereport/${guarranteeToken}`)
        }).catch(() =>{
            this.setState({
                showToaster:true,
                toasterMessage: <Translate id="common.errorToSave"/>,
                toasterVariant: "error",
            })
        });
    }

      /**
    * Get the card content
    *
    * @memberof Show
    */
   getCardContent() {
    if (this.props.isLoading === true) {
        return (
            <LoadingPage />
        )
    }
    else if (this.props.guaranteeReport !== null) {
        return (
            <FormViewEdit  
                {...this.props}
                isSaving = {this.props.isSaving}
                guaranteeReport={this.props.guaranteeReport}
                canBeSaveToEdit={this.saveEditGuarranteeReport}
            />
        )
    }
}


    render(){
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <GuaranteeReportIcon />
                    </Avatar>
                }
                title={<Typography variant="button" ><Translate id="moduleGuarantee.reportGuarantee.titleReportGuarantee"/></Typography>}
                subheader={<Typography variant="caption" color="textPrimary"><Translate id="common.createBy"/>: {this.props.guaranteeReport && this.props.guaranteeReport.create_by}
                 <br/><Translate id="common.creationDate"/>: {this.props.guaranteeReport && this.props.guaranteeReport.creation_date}</Typography>}
            />
            <CardContent>
                {this.getCardContent()}
            </CardContent>

            <Toaster 
                message = {this.state.toasterMessage}
                open = {this.state.showToaster}
                variant = {this.state.toasterVariant}
                onClose = {() => {this.setState({ showToaster:false})}}
            /> 

        </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    let guaranteeReport = state.guaranteeReport.get("guaranteeReport");
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        user: user,
        isLoading: state.guaranteeReport.get('isLoadingGuarantee'),
        isSaving: state.guaranteeReport.get('isSaving'),
        guaranteeReport: guaranteeReport ? guaranteeReport.toJS() : null,
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadGuaranteeReport:(token) =>{
            return dispatch(loadGuaranteeReport(token));
        },
        editGuarranteeeReportFromBackEnd: (supplier, partNumber, customerSelected, failureSelected, guarrantee) =>{
            return dispatch(editGuarranteeeReportFromBackEnd(supplier, partNumber, customerSelected, failureSelected, guarrantee))
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(EditReport))));