///Import react section
import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select'

/** Material-UI imports section */
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Clear';

/** Import component section */
import {GetCancelFolio} from '../../store/helpers/SelectOptions';
import { Avatar } from '@material-ui/core';

class CancelWorkflow extends Component {
  constructor (props) {
    super(props);
    autoBind(this);
    this.state = {
      cancelComment: "", 
      razonForCancel:"",
    }
  }

      /**
     * On change document type
     *
     * @param {*} option
     * @memberof DocumentTypeSelect
     */
    onChangeMeasurementUnit(option) {
      this.setState({
          razonForCancel: option.value
      })
  }

      /**
     * Function to add cancelComment
     * @param {*} comment 
     */
    updatetemProperty(cancelComment) {
      this.setState({
          cancelComment: cancelComment
      })
  }

   /**
   * canBeSaved
   */
  canBeSaved() {
    return this.state.cancelComment !== "" && this.state.razonForCancel !== "";
}

  render () {
    let measurements = GetCancelFolio();

    const { classes } = this.props;

    return (
        <Card>
          <CardHeader
          avatar={<Avatar><CancelIcon /></Avatar>}
                title={<Typography variant="button" ><strong><Translate id="common.cancelWorkflows"/></strong></Typography>}
              />
          <Grid container spacing={24} style={{display:"flex", height: '50vh'}}>
                <Grid item xs={6}>
                    <React.Fragment>
                        <div className={classes.textField}>
                            <Typography variant="body2" gutterBottom> 
                            {<Translate id="common.ReasonForCancellation"/>}</Typography>
                            <Select  
                                fullWidth
                                defaultValue={measurements[0]}
                                options={measurements}
                                onChange={this.onChangeMeasurementUnit}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10 })
                                  }}
                            />
                        </div>
                    </React.Fragment>

                    {<TextField fullWidth
                          label={this.props.message}
                          className={classes.textField}
                          multiline
                          rows="3"
                          defaultValue={this.state.description}
                          onChange={(event) => { this.updatetemProperty(event.target.value) }}
                          margin="normal"
                          variant="outlined"
                      />}
                    </Grid>
                    <Grid item xs={12}>
                      <Button 
                        variant="outlined" color="primary" className={classes.button}
                        disabled={!this.canBeSaved() || this.props.isSaving}
                        onClick={()=>this.props.onCancelWorkflowsDocument(this.state.cancelComment, this.state.razonForCancel)}>
                        {<Translate id="common.save"/>}
                      </Button>
                      <Button variant="outlined" color="secondary" className={classes.button}
                        onClick={()=>this.props.history.push(`/${this.props.link}/${this.props.match.params.token}`)}>
                        
                        {<Translate id="common.cancel"/>}
                      </Button>
                    </Grid>
                
          </Grid>

        </Card>
    )
  }
}

const cancelStyles = theme => ({
  grow: {
      flexGrow: 1,
  },
  toolBar: {
      backgroundColor: "#ea0b34",
  },
  button: {
      margin: theme.spacing.unit,
  },
  errorMessage: {
      color: theme.palette.common.white
  },
  textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 500,
      marginTop: 10,
      marginBottom: 10
  },
  marginSelect:{
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
  },
});

export default  withStyles(cancelStyles, { withTheme: true })(CancelWorkflow);