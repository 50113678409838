import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import { TextField } from '@material-ui/core'

/** Import component section */
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'

/**
 * Dialog to register warehouse user
 */
class RegisterWarehouseUser extends Component {
	/**
	 *Creates an instance of AddCommonItem.
	 * @param {*} props
	 * @memberof AddEmergentAction
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			description: '',
			preparedBySelected: null,
		}
	}

	/**
	 * On seleted prepare by
	 */
	onPrepareBySelected(prepareBy) {
		this.setState({ preparedBySelected: prepareBy })

	}

	/**
	 * onSave
	 */
	onSaveWarehouseUser() {
		if (this.props.onRegisterWarehouseUser) {

			this.props.onRegisterWarehouseUser(this.state.preparedBySelected)
			this.props.onClose()
		}
	}

	/**
	 * Can be add item
	 */
	canBeSaved() {
		if (this.state.preparedBySelected === null) return false
		return true
	}

	/**
	 * Dialog information
	 */
	render() {
		const { classes } = this.props
		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.onClose}
				header={
					<Toolbar>
						<Typography variant='h6'>{this.props.title}</Typography>
					</Toolbar>
				}
				actions={
					<DialogActions
						view={SHOW_EDIT_VIEW}
						classes={classes}
						onCancel={this.props.onClose}
						onClose={this.props.onClose}
						onSaveItem={this.onSaveWarehouseUser}
						isSaving={this.props.isSaving}
						canBeSaved={this.canBeSaved()}
					/>
				}
			>
				<form>
					<Grid item xs={12}>
						<Typography variant='subtitle1' gutterBottom>
							{"Para comenzar a trabajar con esta orden de entrega debe agregar su nombre. No puede omitir este paso si quiere tener disponible toda la información de los materiales que se encuentran en esta orden de entrega"}
						</Typography>
						<br />
						<TextField
							fullWidth
							variant='outlined'
							label={'Nombre'}
							multiline
							rowsMax={5}
							defaultValue={this.props.material ? this.props.material.warehouse_worker_name : ''}
							onChange={(event) => this.onPrepareBySelected(event.target.value)}
							InputLabelProps={{
								classes: {
									root: classes.cssLabel,
									focused: classes.cssFocused,
								},
							}}
							InputProps={{
								classes: {
									root: classes.cssOutlinedInput,
									focused: classes.cssFocused,
									notchedOutline: classes.notchedOutline,
								},
								inputMode: 'text',
							}}
						/>
						<br />
						<br />
					</Grid>
				</form>
			</Dialog>
		)
	}
}

const dialogStyles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	toolBar: {
		backgroundColor: '#ea0b34',
	},
	button: {
		margin: theme.spacing.unit,
	},
	errorMessage: {
		color: theme.palette.common.white,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 500,
		marginTop: 10,
		marginBottom: 10,
	},
})

export default withRouter(withStyles(dialogStyles, { withTheme: true })(RegisterWarehouseUser))
