/** Base react imports section */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
/** Material UI Imports section */
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
/** Translation importas section */
import { Translate } from 'react-localize-redux';

/** Custom styles for the component */
const styles = theme => ({
    stickyDiv: {
        position: "fixed",
        top: 88,
        zIndex: '300',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
});

/**
 *Class to create FloatingButton component
 *
 * @class FloatingButton
 * @extends {PureComponent}
 */
class FloatingButton extends PureComponent {
    render() {
        const { classes } = this.props;
        return (
            <span>
                {/* <div className={classes.stickyDiv}> */}
                    <Fab
                        variant="extended"
                        size="small"
                        color="primary"
                        className={classes.margin}
                        onClick={() => this.props.onOpenAndCloseDialog()}
                        data-cy="btn-available-operations"
                    >
                        <MenuIcon
                            className={classes.extendedIcon}
                        />
                        <Translate id="common.AvailableOperations" />
                    </Fab>
                {/* </div> */}
                {/* <br /><br /> */}
            </span>
        )
    }
}

FloatingButton.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.func.isRequired, //Function to set state of modal (open/close)
};

export default withStyles(styles)(FloatingButton);
