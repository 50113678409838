/** Import ReactJS */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import Select from 'react-select'

/** Material UI import section */
import { Card, Grid, Divider, Typography } from '@material-ui/core'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BusinessIcon from '@material-ui/icons/Business'
import EmailIcon from '@material-ui/icons/Email'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import ContactSupportIcon from '@material-ui/icons/SupervisedUserCircle'
import VpnLockIcon from '@material-ui/icons/VpnLock'
import LanguageIcon from '@material-ui/icons/Language'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
/**Import resources section */
import { BACKGROUND_COLOR_GRAY, COLOR_STATUS_ALERT_INFO } from '../../../../store/helpers/StatusColorConstants'
import { GetPaymentConditionsCurrencyType } from '../../../../store/helpers/SelectOptions'
import { IsUserInRole, ROLE_FACTORING_AFFILIATION_CREATOR } from '../../../../store/helpers/RolesHelper'
const SupplierInformation = (props) => {
	//Array props section
	const { userRolesByCompany } = props
	//int props section
	const { activeStep } = props
	//Function props section
	const { onUpdateFactoring } = props
	//bool props section
	const { isInternalUser, canBeEditInformation, isSaving } = props
	const { factoring, classes } = props
	let currencies =
		factoring &&
		factoring.Currencies.map((x) => {
			return {
				label: x,
				value: x,
			}
		})
	let isUserCreator = IsUserInRole(ROLE_FACTORING_AFFILIATION_CREATOR, userRolesByCompany)
	return (
		<Grid container>
			<Grid item xs={12}>
				<Card className={classes.card}>
					<CardHeader
						className={classes.cardHeader}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={'Información del proveedor a afiliar a factoraje'}
					/>
					<Divider />
					<CardContent>
						<Grid container direction='row' justify='center' alignItems='flex-start' spacing={8}>
							<Grid item xs={12}>
								<List component='nav' className={classes.root}>
									<ListItem>
										<ListItemIcon>
											<ContactSupportIcon color='primary' />
										</ListItemIcon>
										<ListItemText
											inset
											primary={
												<Typography className={classes.textTitle}>
													<Translate id='factoringAffiliation.table.supplierCode' />
												</Typography>
											}
											secondary={
												factoring &&
												factoring.FactoringRequest &&
												factoring.FactoringRequest.SupplierInFactoring &&
												factoring.FactoringRequest.SupplierInFactoring.Code
											}
										/>
									</ListItem>
									<Divider />
									<ListItem>
										<ListItemIcon>
											<BusinessIcon color='primary' />
										</ListItemIcon>
										<ListItemText
											inset
											primary={
												<Typography className={classes.textTitle}>
													<Translate id='factoringAffiliation.show.supplierName' />
												</Typography>
											}
											secondary={
												factoring &&
												factoring.FactoringRequest &&
												factoring.FactoringRequest.SupplierInFactoring &&
												factoring.FactoringRequest.SupplierInFactoring.Name
											}
										/>
									</ListItem>
									<Divider />

									<ListItem>
										<ListItemIcon>
											<EmailIcon color='primary' />
										</ListItemIcon>
										<ListItemText
											inset
											primary={
												<Typography className={classes.textTitle}>
													<Translate id='factoringAffiliation.show.emailCreator' />
												</Typography>
											}
											secondary={
												factoring &&
												factoring.FactoringRequest &&
												factoring.FactoringRequest.SupplierInFactoring &&
												factoring.FactoringRequest.SupplierInFactoring.Email
											}
										/>
									</ListItem>
									<Divider />

									<ListItem>
										<ListItemIcon>
											<LanguageIcon color='primary' />
										</ListItemIcon>
										<ListItemText
											inset
											primary={
												<Typography className={classes.textTitle}>
													<Translate id='factoringAffiliation.steps.werks' />
												</Typography>
											}
											secondary={factoring && factoring.Center}
										/>
									</ListItem>
									<Divider />

									<ListItem>
										<ListItemIcon>
											<VpnLockIcon color='primary' />
										</ListItemIcon>
										<ListItemText
											inset
											primary={
												<Typography className={classes.textTitle}>
													<Translate id='factoringAffiliation.nacionality' />
												</Typography>
											}
											secondary={
												(factoring &&
													factoring.FactoringRequest &&
													factoring.FactoringRequest.SupplierInFactoring &&
													factoring.FactoringRequest.SupplierInFactoring.Nationality) ===
												1 ? (
													<Translate id='factoringAffiliation.national' />
												) : (
													<Translate id='factoringAffiliation.foreigner' />
												)
											}
										/>
									</ListItem>
									<Divider />

									<ListItem>
										<ListItemIcon>
											<MoneyIcon color='primary' />
										</ListItemIcon>
										<ListItemText
											inset
											primary={
												<Typography className={classes.textTitle}>
													<Translate id='factoringAffiliation.table.currency' />
												</Typography>
											}
											secondary={
												<Select
													className={classes.documentTypeSelect}
													options={GetPaymentConditionsCurrencyType()}
													onChange={(paymenOption) =>
														onUpdateFactoring(
															activeStep,
															paymenOption.map((x) => x.value),
															factoring ? factoring.PaymentConditions : '-',
															factoring ? factoring.AnnualPurchase : '-',
															factoring ? factoring.AceptedBySupplier : '-',
															factoring ? factoring.CommentRejectedSupplier : '-'
														)
													}
													value={currencies}
													styles={{
														menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
														menuList: (base) => ({
															...base,
															maxHeight: 200,
															paddingTop: 0,
														}),
														menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
													}}
													menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
													menuPlacement={'auto'}
													isMulti
													isDisabled={
														!isInternalUser ||
														!canBeEditInformation ||
														isSaving ||
														!isUserCreator
													}
												/>
											}
										/>
									</ListItem>
								</List>
								<Divider />
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)
}

const styles = (theme) => ({
	card: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	cardHeader: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(SupplierInformation))
