
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { Translate } from "react-localize-redux";

/** Import component section */
import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper';

import WarehouseUserSelector from '../../../common/WarehouseUserSelector';

/**
 * Dialog to register warehouse user
 */
class RegisterWarehouseUser extends Component {

    /**
   *Creates an instance of AddCommonItem.
   * @param {*} props
   * @memberof AddEmergentAction
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            description: "",
            preparedBySelected: null,
        }
    }

    /**
    * On seleted prepare by
    */
   onPrepareBySelected(prepareBy) {
        this.setState({ preparedBySelected: prepareBy });
    }

    /**
     * onSave
     */
    onSaveWarehouseUser() {
        if(this.props.onRegisterWarehouseUser)
            this.props.onRegisterWarehouseUser(this.state.preparedBySelected);
    }

     /**
     * Can be add item 
     */
    canBeSaved() {
        if (this.state.preparedBySelected === null)
            return false;
        return true;
    }

    /**
     * Dialog information
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
            open = {this.props.open}
            onClose={this.props.onClose}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {this.props.title}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={this.onSaveWarehouseUser}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.canBeSaved()}
                    />
                }>

                <form>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom><Translate id="deliveryOrderModule.messageToAddWarehouse"/></Typography>
                        <br/>
                        <WarehouseUserSelector
                            className={classes.textField}
                            onUsersWarehouseSelected={(warehouse) => { this.onPrepareBySelected(warehouse) }}
                        />
                        <br/><br/>
                    </Grid>
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(RegisterWarehouseUser));