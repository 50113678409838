import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import DataTable from '../common/DataTable'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import UsersIcon from '@material-ui/icons/PermIdentityTwoTone'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadInternalUsersFromBackEnd,
	getInternalUsersCount,
	CHANGE_INTERAL_USER_SORT_CRITERIA,
	CHANGE_INTERAL_USER_QUERY,
} from '../../store/administration/InternalActions'
import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER } from '../../store/helpers/StatusColorConstants'
import { Tooltip } from '@material-ui/core'

/**
 * Internal user view
 *
 * @class Internal user
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance of Internal user
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadInternalUsers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
			this.props.getUsersCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoadingUser !== this.props.isLoadingUser && this.props.isLoadingUser === false) {
			this.props.loadInternalUsers()
			this.props.getUsersCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery
		) {
			this.props.loadInternalUsers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props

		if (dataSource === 'enabled') {
			if (item.avatar_url) {
				let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${item.avatar}`
				return (
					<Tooltip title={<Translate id={item.enabled ? 'users.enabled' : 'users.disabled'} />}>
						<Avatar
							src={urlImage}
							className={item.enabled ? classes.avatar : ''}
							data-cy='user-avatar'
							style={{ backgroundColor: item.enabled ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER }}
						/>
					</Tooltip>
				)
			} else {
				return (
					<Tooltip title={<Translate id={item.enabled ? 'users.enabled' : 'users.disabled'} />}>
						<Avatar
							data-cy='user-avatar'
							className={item.enabled ? classes.avatar : ''}
							style={{ backgroundColor: item.enabled ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER }}
						>
							<PersonIcon />
						</Avatar>
					</Tooltip>
				)
			}
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props

		if (this.props.isLoadingUsers === true) {
			return <LoadingPage />
		} else if (this.props.internalUsers === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.internalUsers}
					configuration={TableConfiguration}
					onRenderCellItem={this.onRenderCellItem}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(user) => {
						this.props.history.push(`/internalusers/${user.id}`)
					}}
					showFilters
					isIndex
				/>
			)
		}
	}

	/**
	 * Get users counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getUsersCounterMessage() {
		const { classes } = this.props
		if (this.props.internalUsers) {
			let totalUsers = this.props.internalUsers.length
			return (
				<div className={classes.usersCounterRoot}>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingUsersCounter}>{totalUsers}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span className={classes.totalUsersCounter}>{this.props.usersCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof InternalUser
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<UsersIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.internalUsers' />{' '}
						</Typography>
					}
					subheader={this.getUsersCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='common.partnershipCode' />,
			dataSource: 'user_name',
			isSortable: true,
		},
		{
			header: <Translate id='users.name' />,
			dataSource: 'full_name',
			isSortable: true,
		},
		{
			header: <Translate id='users.email' />,
			dataSource: 'email',
			isSortable: true,
		},
		{
			header: <Translate id='users.userAccess' />,
			dataSource: 'enabled',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingUsers: state.internalUsers.get('isLoadingUsers'),
		internalUsers: state.internalUsers.get('internalUsers')
			? state.internalUsers.get('internalUsers').toJS()
			: null,
		sortCriteria: state.internalUsers.get('sortCriteria') ? state.internalUsers.get('sortCriteria').toJS() : null,
		searchQuery: state.internalUsers.get('searchQuery'),
		usersCount: state.internalUsers.get('internalUsersCount'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadInternalUsers: (query, orderBy, sortAscending) => {
			dispatch(loadInternalUsersFromBackEnd(query, orderBy, sortAscending))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_INTERAL_USER_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_INTERAL_USER_QUERY,
				query: query,
			})
		},
		getUsersCount: () => {
			dispatch(getInternalUsersCount())
		},
	}
}

const styles = (theme) => ({
	avatar: {},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
