import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'

/** Material-UI import section*/
import { Avatar, Card, CardContent, CardHeader, Fab, Typography, withStyles } from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'
import AddIcon from '@material-ui/icons/AddTwoTone'

/** Custom components import section*/
import Workshops from './components/Workshops'
import LoadingPage from '../../common/LoadingPage'
import AddingWorkshopPanel from './components/WorkshopAddingPanel'

/** Actions import section*/
import { loadAccreditedWorkshops } from '../../../store/accreditedWorkshops/accreditedWorkshopsActions'
import { getAccreditedWorkshopsSelector } from '../../../store/accreditedWorkshops/accreditedWorkshopsSelectors'

const Index = (props) => {
	const { classes } = props
	/** Defeines local state */
	const [loading, setLoading] = useState(true)
	const [addingWorkshopPanelConfig, setAddingWorkshopPanelConfig] = useState({ opened: false })

	/** Connect with redux */
	const dispatch = useDispatch()
	const allWorkshops = useSelector((state) => getAccreditedWorkshopsSelector(state))

	/** Load workshops data */
	useEffect(() => {
		setLoading(true)
		dispatch(loadAccreditedWorkshops()).finally(() => {
			setLoading(false)
		})
	}, [dispatch])

	/**
	 * Show the lateral panel to add new workshop.
	 */
	const openWorkshopAddingPanel = () => {
		setAddingWorkshopPanelConfig({ opened: true, onClose: closeWorkshopAddingPanel })
	}

	/**
	 * Hide the lateral panel to add new workshop.
	 */
	const closeWorkshopAddingPanel = () => {
		setAddingWorkshopPanelConfig({ opened: false })
	}

	return (
		<>
			<Card>
				<CardHeader
					className={classes.header}
					avatar={
						<Avatar
							style={{
								height: 35,
								width: 35,
							}}
						>
							<BuildIcon />
						</Avatar>
					}
					title={
						<Typography>
							<Translate id='accreditedWorkshops.setting.certifiedWorkshops' />
						</Typography>
					}
					action={
						<div className={classes.actionsContainer}>
							<Fab
								size='small'
								color='primary'
								style={{
									height: 35,
									width: 35,
								}}
								onClick={openWorkshopAddingPanel}
							>
								<AddIcon />
							</Fab>
						</div>
					}
				/>
				<CardContent>
					{
						{
							true: <LoadingPage />,
							false: <Workshops workshops={allWorkshops} />,
						}[loading]
					}
				</CardContent>
			</Card>
			<AddingWorkshopPanel {...addingWorkshopPanelConfig} />
		</>
	)
}

const styles = (theme) => ({
	header: {
		padding: theme.spacing.unit,
	},
	actionsContainer: {
		display: 'flex',
		marginTop: theme.spacing.unit,
	},
})

export default withStyles(styles, { withTheme: true })(Index)
