import React, { Component } from 'react';
import { Translate, getTranslate } from 'react-localize-redux';
import autoBind from 'auto-bind'
import { connect } from 'react-redux'

/** Components imports section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import { withRouter } from "react-router-dom";
import Toaster from '../common/Toaster';
import DataTable from '../common/DataTable';
import LoadingPage from '../common/LoadingPage';
import SuppliersSelector from '../common/SupplierSelector'

/** Material UI imports section */
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/** Material UI Icons imports section */
import RatesIcon from '@material-ui/icons/BarChartTwoTone';
import ImportIcon from '@material-ui/icons/ImportExport';
import InfoIcon from '@material-ui/icons/Info';
import RratingPDFIcon from '@material-ui/icons/PictureAsPdf';
import ReportExcelIcon from '@material-ui/icons/FileCopyTwoTone';
import Select from 'react-select'

import {
    generateRatingsPTM, generateRatingsPTMBySupplier, generatePDFRatingsPTM, generateReportExcelRatingPlanningPTM,
    generateReportExcelRatingQualityPTM, generateReportExcelRatingServicePTM
} from '../../store/ratings/ratingActions';
import { GetMonths, GetYears } from '../../store/helpers/SelectOptions'

import { showSuccessToaster, showErrorToaster, showWarningToaster } from '../../store/helpers/ToasterHelpers';

/**
 * Class container to show Generate ratings view from ptm
 *
 * @class GenerateRatings
 * @extends {Component}
 */
class GenerateRatingPtm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            monthDate: '',
            yearDate: '',
            supplier: null,
            singleRating: false,
        }
    }

    /**
     * Action to genertes rating from ptm rating
     */
    generateRatingsPTM() {
        if (this.state.yearDate === '' || this.state.monthDate === '') {
            return this.setState(showWarningToaster('rating.messageSelctMonth'));
        }
        else {
            this.props.generateRatingsPTM(this.state.monthDate, this.state.yearDate)
                .then(() => {
                    this.setState(showSuccessToaster());
                    return Promise.resolve('Ok')
                }).catch(() => {
                    this.setState(showErrorToaster());
                    return Promise.reject();
                });
        }
    }

    /**
 * Action to genertes rating from ptm rating
 */
    generateRatingsPTMBySupplier() {
        if (this.state.yearDate === '' || this.state.monthDate === '') {
            return this.setState(showWarningToaster('rating.messageSelctMonth'));
        }
        else {
            this.props.generateRatingsPTMBySupplier(this.state.monthDate, this.state.yearDate, this.state.supplier)
                .then(() => {
                    this.setState(showSuccessToaster());
                    return Promise.resolve('Ok')
                }).catch(() => {
                    this.setState(showErrorToaster());
                    return Promise.reject();
                });
        }
    }

    /**
 *Set month to state
 *
 * @param {*} date
 * @memberof Index
 */
    onSetMonthData(montdte) {
        this.setState({
            monthDate: montdte.value
        });
    }

    /**
     * Set year date
     * @param {*} year 
     */
    onSetYearData(year) {
        this.setState({
            yearDate: year.value
        });
    }

    /**
     * Set supplier
     * @param {*} year 
     */
    onSelectSupplier(supplier) {
        this.setState({
            supplier: supplier
        });
    }

    /**
     * Set single Rating
     * @param {*} year 
     */
    onSetSingleRating(singleRat) {
        this.setState({
            singleRating: singleRat,
            supplier: null
        });
    }

    /**
    * Set buttons for rating
    * @param {*} year 
    */
    RatingAvaiable() {
        if (this.state.singleRating) {
            if (this.state.monthDate && this.state.yearDate && this.state.supplier) return true;
            else return false;
        }
        else {
            if (this.state.monthDate && this.state.yearDate) return true;
            else return false;
        }
    }



    /**
     * Action to generated rpdf rate
     *
     * @memberof Invoices
     */
    onGeneratedPDFRateClick() {
        if (this.state.yearDate === '' || this.state.monthDate === '') {
            return this.setState(showWarningToaster('rating.messageSelctMonth'));
        }
        else {
            this.props.generatePDFRatingsPTM(this.state.monthDate, this.state.yearDate);
        }
    }

    /**
     * function to generated report excel from rating by month from ptm rating
     */
    onGenerateReportExcelPlanningPTM() {
        if (this.state.yearDate === '' || this.state.monthDate === '') {
            return this.setState(showWarningToaster('rating.messageSelctMonth'));
        }
        else {
            this.props.generateReportExcelRatingPlanningPTM(this.state.monthDate, this.state.yearDate)
                .then(() => {
                    this.setState(showSuccessToaster());
                    return Promise.resolve('Ok')
                }).catch(() => {
                    this.setState(showErrorToaster());
                    return Promise.reject();
                });
        }
    }

    onGenerateReportExcelQualityPTM() {
        if (this.state.yearDate === '' || this.state.monthDate === '') {
            return this.setState(showWarningToaster('rating.messageSelctMonth'));
        }
        else {
            this.props.generateReportExcelRatingQualityPTM(this.state.monthDate, this.state.yearDate)
                .then(() => {
                    this.setState(showSuccessToaster());
                    return Promise.resolve('Ok')
                }).catch(() => {
                    this.setState(showErrorToaster());
                    return Promise.reject();
                });
        }
    }

    onGenerateReportExcelServicePTM() {
        if (this.state.yearDate === '' || this.state.monthDate === '') {
            return this.setState(showWarningToaster('rating.messageSelctMonth'));
        }
        else {
            this.props.generateReportExcelRatingServicePTM(this.state.monthDate, this.state.yearDate)
                .then(() => {
                    this.setState(showSuccessToaster());
                    return Promise.resolve('Ok')
                }).catch(() => {
                    this.setState(showErrorToaster());
                    return Promise.reject();
                });
        }
    }

    /**
     * Get the card content
     *
     * @memberof Index
     */
    getCardContent() {
        if (this.props.isSaving === true) {
            return (
                <LoadingPage />
            )
        }
        else {
            return (
                <Grid item xs={12} style={{ marginTop: '.5rem' }}>
                    <DataTable
                        title={<Translate id="companies.functionModules.SupplierQualification" />}
                        data={this.props.ratingsPtm ? this.props.ratingsPtm : []}
                        configuration={TableratingsTableConfiguration(this.props.translate)}
                        onRenderCellItem={this.onRenderRatingsCellItem}
                    />
                </Grid>
            )
        }
    }




    render() {
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <RatesIcon />
                        </Avatar>
                    }
                    title={<Typography variant="button">{<Translate id="rating.generateRating" />} </Typography>}
                />
                <CardContent>
                    <Grid container spacing={24} >
                        <Grid item xs={12} className="alert alert-info" role="alert" style={{ marginLeft: '.7rem', marginRight: '.7rem' }}>
                            <React.Fragment>
                                <h6 className="alert-heading">
                                    <InfoIcon />
                                    <Translate id="rating.generatedRatingptm" />
                                    <Typography component="p"><strong> <Translate id="rating.messageToGeneratedRatingPtm" /></strong></Typography>
                                </h6>
                            </React.Fragment>
                        </Grid>
                        <Grid item xs={12} style={{ marginLeft: '.7rem', marginRight: '.7rem' }}>

                            <Grid container justify="flex-start" alignItems="center">
                                <Grid item xs={3}>
                                    <MonthsTypeSelect
                                        monthDate={this.state.monthDate}
                                        setMonthData={this.onSetMonthData}
                                    />
                                </Grid>
                                <Grid item xs={3} style={{ marginLeft: '.7rem', marginRight: '.3rem' }}>
                                    <YearsTypeSelect
                                        yearDate={this.state.yearDate}
                                        setYearData={this.onSetYearData}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        backgroundColor="red"
                                        control={<Switch name="Single Rating" checked={this.state.singleRating ? true : false} onChange={(event) => this.onSetSingleRating(event.target.checked)} inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                                        label=" Generar calificación para un proveedor"
                                    />
                                </Grid>
                                <Grid item xs={3} style={{ marginLeft: '.2rem', marginRight: '1.4rem' }}>
                                    {this.state.singleRating &&
                                        <SuppliersSelector
                                            onSupplierSelected={(supplier) => { this.onSelectSupplier(supplier) }}
                                        />}
                                </Grid>

                            </Grid>
                            <Grid container justify="flex-start" alignItems="center" style={{ marginTop: '.8rem' }}>
                                <Grid item xs={3} style={{ marginLeft: '.7rem', marginRight: '.7rem' }}>
                                    <Button variant="contained" fullWidth
                                        disabled={this.props.isSaving || !this.RatingAvaiable()}
                                        size="small"
                                        style={{ margin: '0.5em 1%', width: '95%', backgroundColor: this.props.isSaving || !this.RatingAvaiable() ? "#e0e0e0" : (this.state.singleRating ? "#1e72cc" : "#7d586e"), color: "white" }}
                                        onClick={this.state.singleRating ? () => this.generateRatingsPTMBySupplier() : () => this.generateRatingsPTM()}>
                                        <ImportIcon /><Translate id={this.state.singleRating ? 'rating.generateRating' : 'rating.generateRatings'} />
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="contained" color="secondary" fullWidth
                                        style={{ margin: '0.5em 1%', width: '95%' }}
                                        disabled={this.props.isSaving || !this.RatingAvaiable()}
                                        size="small"
                                        onClick={() => { this.onGeneratedPDFRateClick() }}>
                                        <RratingPDFIcon /><Translate id='rating.generateRatingpdfPtm' />
                                    </Button>
                                </Grid>
                                <Grid item xs={2} style={{ marginLeft: '.7rem', marginRight: '.7rem' }}>
                                    <Button variant="contained" fullWidth
                                        disabled={this.props.isSaving || !this.RatingAvaiable()}
                                        size="small"
                                        style={{ margin: '0.5em 1%', width: '95%', backgroundColor: (this.props.isSaving || !this.RatingAvaiable()) && !this.state.singleRating ? "#e0e0e0" : "#17A2B8", color: "white" }}
                                        onClick={() => { this.onGenerateReportExcelPlanningPTM() }}>
                                        <ReportExcelIcon />{"Reporte Entregas"}
                                    </Button>
                                </Grid>
                                <Grid item xs={2} style={{ marginLeft: '.7rem', marginRight: '.7rem' }}>
                                    <Button variant="contained" fullWidth
                                        disabled={this.props.isSaving || !this.RatingAvaiable()}
                                        size="small"
                                        style={{ margin: '0.5em 1%', width: '95%', backgroundColor: (this.props.isSaving || !this.RatingAvaiable()) && !this.state.singleRating ? "#e0e0e0" : "#17A2B8", color: "white" }}
                                        onClick={() => { this.onGenerateReportExcelQualityPTM() }}>
                                        <ReportExcelIcon />{"Reporte Calidad"}
                                    </Button>
                                </Grid>
                                <Grid item xs={2} style={{ marginLeft: '.7rem', marginRight: '.7rem' }}>
                                    <Button variant="contained" fullWidth
                                        disabled={this.props.isSaving || !this.RatingAvaiable()}
                                        size="small"
                                        style={{ margin: '0.5em 1%', width: '95%', backgroundColor: (this.props.isSaving || !this.RatingAvaiable()) && !this.state.singleRating ? "#e0e0e0" : "#17A2B8", color: "white" }}
                                        onClick={() => { this.onGenerateReportExcelServicePTM() }}>
                                        <ReportExcelIcon />{"Reporte Servicios"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.getCardContent()}

                </CardContent>
                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        );
    }
}

/**
 * Get the date selection
 *
 * @class MonthsTypeSelect
 * @extends {Component}
 */
class MonthsTypeSelect extends Component {

    /**
     * Render
     *
     * @returns
     * @memberof MonthsTypeSelect
     */
    render() {
        let months = GetMonths();
        return (
            <Select
                value={months[this.props.monthDate]}
                options={months}
                onChange={this.props.setMonthData}
                styles={{
                    menu: (base) => ({ ...base, zIndex: 1000, height: 150 }),
                    menuList: (base) => ({ ...base, height: 150, paddingTop: 0 }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }) /// THIS IS TO SHOW MENU OVER MODAL
                }}
                menuPortalTarget={document.parentNode}
                menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
                menuPlacement={'auto'}
            />
        );
    }
}

/**
 * Get the date selection
 *
 * @class YearsTypeSelect
 * @extends {Component}
 */
class YearsTypeSelect extends Component {

    /**
     * Render
     *
     * @returns
     * @memberof YearsTypeSelect
     */
    render() {
        let years = GetYears();
        return (
            <Select
                value={years[this.props.yearDate]}
                options={years}
                onChange={this.props.setYearData}
                styles={{
                    menu: (base) => ({ ...base, zIndex: 1000, height: 150 }),
                    menuList: (base) => ({ ...base, height: 150, paddingTop: 0 }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }) /// THIS IS TO SHOW MENU OVER MODAL
                }}
                menuPortalTarget={document.parentNode}
                menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
                menuPlacement={'auto'}
            />
        );
    }
}


function TableratingsTableConfiguration(translate) {
    let columns = [
        {
            header: '',
            dataSource: 'status_indicator',
        }, {
            header: <Translate id="common.supplier" />,
            dataSource: 'supplier_full',
        },
        {
            header: `${translate && translate('rating.ratingsTable.deliveries')} ${'40'}%`,
            dataSource: 'delivery_points',
        }, {
            header: `${translate && translate('rating.ratingsTable.quality')} ${'50'}%`,
            dataSource: 'quality_points',
        }, {
            header: `${translate && translate('rating.ratingsTable.service')} ${'10'}%`,
            dataSource: 'service_points',
        }, {
            header: `${translate && translate('rating.ratingsTable.garrantiePtm')}`,
            dataSource: 'guarantee_points',
        }, {
            header: <Translate id="rating.ratingsTable.finalScore" />,
            dataSource: 'final_score',
        }];
    return ({ columns });
}

/**
 *  Defines the properties injecteded from the store to view container
* @param {*} state
            */
function mapStateToProps(state) {
    let selectedCompany = state.profile.get('selectedCompany')
    return {
        isLoadingUser: state.oidc.isLoadingUser,
        selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
        isSaving: state.ratings.get("isSaving"),
        ratingsPtm: state.ratings.get('ratingsPtm') ? state.ratings.get('ratingsPtm').toJS() : null,
        translate: getTranslate(state.localize),
    }
}

/**
 * Defines the actions injectes to the component
* @param {*} dispatch
        */
const mapDispatchToProps = dispatch => {
    return {
        generateRatingsPTM: (monthDate, yearDate) => {
            return (dispatch(generateRatingsPTM(monthDate, yearDate)))
        },
        generateRatingsPTMBySupplier: (monthDate, yearDate, supplier) => {
            return (dispatch(generateRatingsPTMBySupplier(monthDate, yearDate, supplier)))
        },
        generatePDFRatingsPTM: (monthDate, yearDate) => {
            return dispatch(generatePDFRatingsPTM(monthDate, yearDate));
        },
        generateReportExcelRatingPlanningPTM: (monthDate, yearDate) => {
            return dispatch(generateReportExcelRatingPlanningPTM(monthDate, yearDate));
        },
        generateReportExcelRatingQualityPTM: (monthDate, yearDate) => {
            return dispatch(generateReportExcelRatingQualityPTM(monthDate, yearDate));
        },
        generateReportExcelRatingServicePTM: (monthDate, yearDate) => {
            return dispatch(generateReportExcelRatingServicePTM(monthDate, yearDate));
        }
    }
}

export default withauthenticatedLayout(withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateRatingPtm)));