//import react section
import React from 'react'
import { withRouter } from 'react-router-dom'
/** Material UI import section */
import { Card, CardHeader, Chip, Fab, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import moment from 'moment'
import { Translate } from 'react-localize-redux'
import { onGetStatusDescriptionChip, onGetBackgroundColorChip } from '../../../../store/helpers/LoadAlertHelper'
// import DialogAvailableOperations from '../../../common/DialogAvailableOperations'

// import { Translate } from 'react-localize-redux'
//import helper test

/** Resources import section */
const useStyle = (theme) => ({})

const HeaderComponent = (props) => {
	// //object props section
	// const { loadAlert, supplier, userRolesByCompany, userProfile } = props
	// //bool props section
	// const { supplierLogo, openAvailableOperationsDialog } = props
	// //string props section
	// const { urlImageSupplier, yourlogohere } = props
	// //function props
	// const { setOpenDialog, changeLoadAlertStatus, onModalConfirmLoadAlert, onModalConfirmLoadAlertDelete } = props
	// let optonsAvalinable = loadAlert ? loadAlert.get_available_operations : []

	//object props section
	const { loadAlert, supplier } = props
	//bool props section
	const { supplierLogo } = props
	//string props section
	const { urlImageSupplier, yourlogohere } = props
	//function props
	const { closeDialogOperation } = props

	return (
		<Card style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
			{/* <DialogAvailableOperations
				currentUser={userProfile}
                userRolesByCompany={userRolesByCompany}
                options={optonsAvalinable}
                openAvailableOperationsDialog={openAvailableOperationsDialog}
                setOpenDialog={setOpenDialog}
				onChageToProcessLoadAlert = {changeLoadAlertStatus}
				onAcceptLoadAlert={onModalConfirmLoadAlert}
				onRejectLoadAlert={onModalConfirmLoadAlertDelete}
            /> */}
			<CardHeader
				title={'Folio :' + loadAlert.formattedFolio}
				avatar={
					<img
						src={supplierLogo ? urlImageSupplier : yourlogohere}
						alt='logoSupplier'
						style={{
							maxHeight: 80,
						}}
					/>
				}
				subheader={
					supplier && (
						<Typography component={'div'} style={{ color: 'gray' }}>
							{supplier.rfc}{' '}
							<Typography component={'div'} style={{ color: 'gray' }}>
								{supplier.full_address}
							</Typography>
						</Typography>
					)
				}
			/>
			<div style={{ display: 'flex', flexDirection: 'column', margin: '20px', alignItems: 'flex-end' }}>
				<Chip
					label={<Translate id={onGetStatusDescriptionChip(loadAlert.status)} />}
					style={{ borderRadius: '5px', width: '100%', height: '25px', backgroundColor:onGetBackgroundColorChip(loadAlert.status) }}
					color='inherit'
					
				/>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Typography component={'div'} variant='subtitle2'>
						Fecha de creación:
					</Typography>
					<Typography component={'div'} variant='body2' style={{ color: 'gray' }}>
						{moment(loadAlert.creationDate).format('L LT')}
					</Typography>
				</div>
				{
					<Fab
						variant='extended'
						size='small'
						color='primary'
						// onClick={() => setOpenDialog(true)}
						onClick={closeDialogOperation}
						data-cy='btn-available-operations'
					>
						<MenuIcon />
						<Translate id='common.AvailableOperations' />
					</Fab>
				}
			</div>
		</Card>
	)
}

HeaderComponent.propTypes = {}

HeaderComponent.defaultProps = {}
export default withRouter(withStyles(useStyle, { withTheme: true })(HeaderComponent))
