import {fromJS } from 'immutable';

import * as externalUsersActions from './ExternalUserActions';
import { converListToArray } from '../helpers/ListHelper'

/**
 * Etender user initial state
 */
export const externalUsersInitialState = fromJS({
    isLoadingUsers: true,
    externalUsers: null,
    isLoadingUser:false,
    externalUser: null,
    isSavingUser: false,
    externalUserRoles: null,
    isLoadingExternalUserRoles: false,
    sortCriteria: {
        by: "user_name",
        ascending: false
    },
    searchQuery: "",
    suppliersCount: null,
    externalUsersCount: null,
    administratorSupplier: null,
    usersSaleBilling: null,
    isLoadingReportUsers: false,
    isSavingPassword:false,
})

export default function externalUsersReducer(state = externalUsersInitialState, action){
    switch(action.type){
        case externalUsersActions.START_LOADING_EXTERNAL_USERS:{
            return state.merge({
                isLoadingUsers: action.isLoading
            })
        }
        case externalUsersActions.SET_EXTERNAL_USERS_LIST:{
            let usersLists = fromJS(action.users);
            return state.merge({
                externalUsers: usersLists,
                isLoadingUsers: false
            })
        }
        case externalUsersActions.CHANGE_EXTERNAL_USERS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case externalUsersActions.CHANGE_EXTERNAL_USERS_QUERY: {
            return state.merge({
                searchQuery: action.query
            })
        }
        case externalUsersActions.SET_EXTERNAL_USERS_COUNT: {
            return state.merge({
                externalUsersCount: action.count
            })
        }
        case externalUsersActions.START_UPDATING_PASSWORD_USER_EXTERNAL_DATA:{
            return state.merge({
                isSavingPassword: action.password,
            })  
        }
        case externalUsersActions.IS_LOADING_EXTERNAL_USER:{
            return state.merge({
                isLoadingUser: action.isLoadingUser,
                externalUser: null,
            })
        }
        case externalUsersActions.SET_EXTERNAL_USER:{
            return state.merge({
                isLoadingUser: false,
                externalUser: fromJS(action.externalUser)
            })
        }
        case externalUsersActions.IS_SAVING_EXTERNAL_USER:{
            return state.merge({
                isSavingUser: action.isSaving
            })
        }
        case externalUsersActions.SET_ENABLE_EXTERNAL_USER:{
            let externalUser = state.get('externalUser');
            externalUser = externalUser.set('enabled', action.enabled);
            return state.merge({
                isLoadingUser: false,
                isSavingUser: false,
                externalUser: externalUser
            })
        }
        case externalUsersActions.SET_ENABLE_EXTERNAL_USER_INDEX:{
            let actualexternalUsers = state.get('externalUsers')
            if (actualexternalUsers) {
                let actualexternalUsersUp = actualexternalUsers.filter((user) => {
                    return !(user.get('id') === action.userId)
                })
                return state.merge({
                    externalUsers: actualexternalUsersUp,
                    isSavingUser: false,
                });
            } else {
                return state;
            }

        }
        case externalUsersActions.ADD_EXTERNAL_USER_TO_LIST:{
            let user = fromJS(action.user);
            let usersList = state.get('externalUsers').push(user);
            return state.merge({
                externalUsers: usersList,
                isSavingUser: false 
            });
        }
        case externalUsersActions.UPDATE_EXTERNAL_USER:{
            return state.merge({
                isSavingUser: false,
                externalUser: fromJS(action.externalUser)
            })
        }

        case externalUsersActions.SET_EXTERNAL_USERS_ROLES:{
            return state.merge({
                externalUserRoles: action.roles
            })
        }
        case externalUsersActions.IS_LOADING_EXTERNAL_USERS_ROLES: {
            return state.merge({
                isLoadingExternalUserRoles: action.isLoading
            })
        }
        case externalUsersActions.ADD_EXTERNAL_USER_TO_ROLE: {
            let actualRoles = state.get('externalUserRoles')
            if (actualRoles) {
                let updatedRoles = converListToArray(actualRoles);
                updatedRoles.push({company_token: action.companyToken,id: action.roleId})
                return state.merge({
                    externalUserRoles: fromJS(updatedRoles)
                });
            } else {
                return state;
            }
        }
        case externalUsersActions.REMOVE_EXTERNAL_USER_FROM_ROLE: {
            let actualRoles = state.get('externalUserRoles')
            if (actualRoles) {
                let updatedRoles = actualRoles.filter((roleAssigned) => {
                    return !(roleAssigned.get('company_token') === action.companyToken && roleAssigned.get('id') === action.roleId)
                })
                return state.merge({
                    externalUserRoles: updatedRoles
                });
            } else {
                return state;
            }
        }
        case externalUsersActions.UPDATE_EMIAL_EXTERNAL_USER:{
            let actualexternalUsers = state.get('externalUsers')
            if (actualexternalUsers) {
                let index = actualexternalUsers.findIndex((detail) => {
                    return detail.get('id') === action.user.id
                });
                let usersList = actualexternalUsers.setIn([index], fromJS(action.user));
                return state.merge({
                    externalUsers: usersList,
                    isSavingUser: false,
                });
            } else {
                return state;
            }
        }
        case externalUsersActions.SET_INFORMATION_USER_ADMINISTRATOR:{
            return state.merge({
                administratorSupplier: fromJS(action.user)
            })
        }
        case externalUsersActions.SET_USERS_IN_ROLE_SALE_AND_BILLING:{
            let usersLists = fromJS(action.users);
            return state.merge({
                usersSaleBilling: usersLists,
                isLoadingExternalUserRoles: false
            })
        }
        case externalUsersActions.IS_DOWNLOADING_REPORT_USERS:{
            return state.merge({
                isLoadingReportUsers: action.isLoading
            })
        }
        default:
            return state;
    }
}