/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'
import { EMPLOYEES_TYPE, MATERIALS_TYPE } from '../helpers/ServicesListTypes'
import moment from 'moment'
import { internalCompanyData, supplierData } from '../helpers/BasePdfData'

/** ServicesProjects index constants */
export const IS_LOADING_SERVICES_PROJECTS = 'IS_LOADING_SERVICES_PROJECTS'
export const IS_LOADING_PURCHASE_ORDER = 'IS_LOADING_PURCHASE_ORDER'
export const SET_PURCHASE_ORDERS = 'SET_PURCHASE_ORDERS'
export const SET_SERVICES_PROJECTS_LIST = 'SET_SERVICES_PROJECTS_LIST'
export const SET_SERVICES_PROJECTS_DELETED_LIST = 'SET_SERVICES_PROJECTS_DELETED_LIST'
export const CHANGE_SERVICES_PROJECTS_SORT_CRITERIA = 'CHANGE_SERVICES_PROJECTS_SORT_CRITERIA'
export const CHANGE_SERVICES_PROJECTS_QUERY = 'CHANGE_SERVICES_PROJECTS_QUERY'
export const CHANGE_SERVICES_PROJECTS_PAGINATION = 'CHANGE_SERVICES_PROJECTS_PAGINATION'
export const SET_SERVICES_PROJECTS_COUNT = 'SET_SERVICES_PROJECTS_COUNT'
export const SET_SERVICES_PROJECTS_DELETED_COUNT = 'SET_SERVICES_PROJECTS_DELETED_COUNT'
export const ADD_SERVICE_PROJECT = 'ADD_SERVICE_PROJECT'

/** ServiceProject detail Constants */
export const IS_LOADING_SERVICE_PROJECT = 'IS_LOADING_SERVICE_PROJECT'
export const SET_SERVICE_PROJECT = 'SET_SERVICE_PROJECT'
export const IS_SAVING_SERVICE_PROJECT = 'IS_SAVING_SERVICE_PROJECT'
export const UPDATE_SERVICE_PROJECT = 'UPDATE_SERVICE_PROJECT'
export const ADD_ITEM_IN_LIST_IN_SERVICE_PROJECT = 'ADD_ITEM_IN_LIST_IN_SERVICE_PROJECT'
export const REMOVE_ITEM_FROM_LIST_IN_SERVICE_PROJECT = 'REMOVE_ITEM_FROM_LIST_IN_SERVICE_PROJECT'
export const REMOVE_SERVICE_FROM_CONTRACT = 'REMOVE_SERVICE_FROM_CONTRACT'

/**
 * Set flag to determine if is loading servicesProjects list
 *
 * @param {*} isLoadingServicesProjects
 * @returns
 */
function isLoadingServicesProjects(isLoadingServicesProjects) {
	return {
		type: IS_LOADING_SERVICES_PROJECTS,
		isLoadingServicesProjects,
	}
}

function isLoadingPurchaseOrder(isLoadingPurchaseOrder) {
	return {
		type: IS_LOADING_PURCHASE_ORDER,
		isLoadingPurchaseOrder,
	}
}

function setPurchaseOrders(order) {
	return {
		type: SET_PURCHASE_ORDERS,
		order,
	}
}

/**
 * Set servicesProjects list in store
 *
 * @param {*} servicesProjects
 * @returns
 */
function setServicesProjectsList(servicesProjects) {
	return {
		type: SET_SERVICES_PROJECTS_LIST,
		servicesProjects,
	}
}

function setServicesProjectsDeletedList(servicesProjectsDeleted) {
	return {
		type: SET_SERVICES_PROJECTS_DELETED_LIST,
		servicesProjectsDeleted,
	}
}

/**
 * Set servicesProjects list count in store
 *
 * @param {*} count
 * @returns
 */
function setServicesProjectsCount(count) {
	return {
		type: SET_SERVICES_PROJECTS_COUNT,
		count,
	}
}

function setServicesProjectsDeletedCount(count) {
	return {
		type: SET_SERVICES_PROJECTS_DELETED_COUNT,
		count,
	}
}

/**
 * Add an serviceProject to list
 *
 * @param {*} serviceProject
 * @returns
 */
function createServiceProject(serviceProject) {
	return {
		type: ADD_SERVICE_PROJECT,
		serviceProject,
	}
}

/**
 * Set flag to determine if is loading specific serviceProject data
 *
 * @param {*} isLoadingServiceProject
 * @returns
 */
function isLoadingServiceProject(isLoadingServiceProject) {
	return {
		type: IS_LOADING_SERVICE_PROJECT,
		isLoadingServiceProject,
	}
}

/**
 * Set flag to determine if is saving specific serviceProject data
 *
 * @param {*} isSavingServiceProject
 * @returns
 */
function isSavingServiceProject(isSavingServiceProject) {
	return {
		type: IS_SAVING_SERVICE_PROJECT,
		isSavingServiceProject,
	}
}

/**
 * Set serviceProject data in store
 *
 * @param {*} serviceProject
 * @returns
 */
function setServiceProject(serviceProject) {
	return {
		type: SET_SERVICE_PROJECT,
		serviceProject,
	}
}

/**
 * Update serviceProject data in store
 *
 * @param {*} serviceProject
 * @returns
 */
function updateServiceProject(serviceProject) {
	return {
		type: UPDATE_SERVICE_PROJECT,
		serviceProject,
	}
}

/**
 * Add item in list in a service project
 *
 * @param {*} item
 * @returns
 */
function addItemToListInServiceProject(item, listType) {
	return {
		type: ADD_ITEM_IN_LIST_IN_SERVICE_PROJECT,
		item,
		listType,
	}
}

/**
 * Remove an item from a list in service/project
 *
 * @param {*} itemToken
 * @param {*} listType
 * @returns
 */
function removeItemFromListInServiceProject(itemToken, listType) {
	return {
		type: REMOVE_ITEM_FROM_LIST_IN_SERVICE_PROJECT,
		itemToken,
		listType,
	}
}

/**
 * Move to trash service/project
 * @param {*} itemToken
 * @returns
 */
function removeServiceFromContract(itemToken) {
	return {
		type: REMOVE_SERVICE_FROM_CONTRACT,
		itemToken,
	}
}

/**
 * Get servicesProjects list from backend
 *
 * @export
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @returns
 */
export function loadPurchaseOrder(supplierCode, order) {
	return (dispatch, getState) => {
		dispatch(isLoadingPurchaseOrder(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/${supplierCode}/${order}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingPurchaseOrder(false))
					dispatch(setPurchaseOrders(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error adding a serviceProject', error.response)
				dispatch(isLoadingPurchaseOrder(false))
				return Promise.reject()
			})
	}
}

export function loadServicesProjectsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingServicesProjects(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/all`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setServicesProjectsList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingServicesProjects(false))
				console.error('Error getting the servicesProjects by company list', error.response)
			})
	}
}
export function loadServicesProjectsDeletedFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingServicesProjects(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/allDeleted`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setServicesProjectsDeletedList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingServicesProjects(false))
				console.error('Error getting the servicesProjects by company list', error.response)
			})
	}
}

export function loadServicesProjectsBSupplierFromBackEnd(
	supplierCode,
	query,
	orderBy,
	sortAscending,
	page,
	rowsPerPage
) {
	return function (dispatch, getState) {
		dispatch(isLoadingServicesProjects(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/all`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setServicesProjectsList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingServicesProjects(false))
				console.error('Error getting the servicesProjects by company list', error.response)
			})
	}
}

/**
 * Get ServicesProjects list count
 *
 * @export
 * @returns
 */
export function getServicesProjectsCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().contractorServicesProjects.get('servicesProjectsCount')
		if (actualCounter) {
			return
		}
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setServicesProjectsCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the serviceProject's count", error.response)
			})
	}
}

export function getServicesProjectsDeletedCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().contractorServicesProjects.get('servicesProjectsCount')
		if (actualCounter) {
			return
		}
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceprojectdeleted/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setServicesProjectsDeletedCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the serviceProject's count", error.response)
			})
	}
}

/**
 * Add new serviceProject to list
 *
 * @export
 * @param {*} serviceProject
 * @returns
 */
export function addServiceProject(serviceProject, supplierName) {
	return (dispatch, getState) => {
		dispatch(isSavingServiceProject(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (user.profile && user.profile.company_code) {
			supplierCode = user.profile.company_code
		}
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
			SupplierName: supplierName,
			StartDate: serviceProject.start_date.format('DD/MM/YYYY'),
			EndDate: serviceProject.end_date.format('DD/MM/YYYY'),
			Description: serviceProject.description,
			PurchaseOrder: serviceProject.purchase_order,
			ServiceArea: serviceProject.service_areas,
			CreateEmailBy: user.profile.email,
			RequestOrder: serviceProject.request_order,
			ServiceProjectType: serviceProject.option_select,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(createServiceProject(serverResponse.data))
					dispatch(isSavingServiceProject(false))
					return Promise.resolve(serverResponse.data.token)
				}
			})
			.catch((error) => {
				console.error('Error adding a serviceProject', error.response)
				dispatch(isSavingServiceProject(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to update service/project data
 *
 * @export
 * @param {*} serviceProject
 * @param {*} supplierName
 * @returns
 */
export function editServiceProject(serviceProject, serviceToken) {
	return (dispatch, getState) => {
		dispatch(isSavingServiceProject(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let startDate = moment(serviceProject.start_date, 'DD/MM/YYYY').format('DD/MM/YYYY')
		let endDate = moment(serviceProject.end_date, 'DD/MM/YYYY').format('DD/MM/YYYY')

		let data = {
			StartDate:
				startDate !== 'Invalid date'
					? moment(serviceProject.start_date, 'DD/MM/YYYY').format('DD/MM/YYYY')
					: serviceProject.start_date,
			EndDate:
				endDate !== 'Invalid date'
					? moment(serviceProject.end_date, 'DD/MM/YYYY').format('DD/MM/YYYY')
					: serviceProject.end_date,
			Description: serviceProject.description,
			PurchaseOrder: serviceProject.purchase_order,
			ServiceAreas: serviceProject.service_areas,
			EnterWeekends: serviceProject.enter_weekends,
			RequestOrder: serviceProject.request_order,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/update/serviceproject/${serviceToken}`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateServiceProject(serverResponse.data))
					dispatch(isSavingServiceProject(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a serviceProject', error.response)
				dispatch(isSavingServiceProject(false))
				return Promise.reject()
			})
	}
}

/**
 * Get an specific ServiceProject data from backend
 *
 * @export
 * @param {*} token
 * @returns
 */
export function loadServiceProjectFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingServiceProject(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setServiceProject(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to load service / project', error.response)
				dispatch(isLoadingServiceProject(false))
				return Promise.reject()
			})
	}
}

/**
 * Add an item in a list of a service/project
 *
 * @export
 * @param {*} employeeToken
 * @param {*} paymentToken
 * @returns
 */
export function addEmployeeInServiceProject(employeeToken, serviceToken) {
	return (dispatch, getState) => {
		dispatch(isSavingServiceProject(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/addemployee/${serviceToken}/${employeeToken}`
		return axios
			.post(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.forEach((employeed) => {
						dispatch(addItemToListInServiceProject(employeed, EMPLOYEES_TYPE))
					})
					dispatch(isSavingServiceProject(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingServiceProject(false))
				console.error('Error adding an employee in Service/Project', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Add a material item in a list of a service/project
 *
 * @export
 * @param {*} material
 * @param {*} serviceToken
 * @returns
 */
export function addMaterialInServiceProject(material, serviceToken) {
	return (dispatch, getState) => {
		dispatch(isSavingServiceProject(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/addmaterial`

		let formData = new FormData()
		material.attachments.forEach((file) => {
			formData.append('attachments', file)
		})
		material.filesSecurity.forEach((file) => {
			formData.append('filesSecurity', file)
		})
		formData.set('ServiceToken', serviceToken)
		formData.set('Name', material.name)
		formData.set('Description', material.description)
		formData.set('MaterialType', material.materialType)

		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addItemToListInServiceProject(serverResponse.data, MATERIALS_TYPE))
					dispatch(isSavingServiceProject(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingServiceProject(false))
				console.error('Error adding a material in Service/Project', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Add a contact item in a list of a service/project
 *
 * @export
 * @param {*} contact
 * @param {*} serviceToken
 * @returns
 */
export function addContactInServiceProject(contact, serviceToken) {
	return (dispatch, getState) => {
		dispatch(isSavingServiceProject(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/addcontact`
		let data = {
			ServiceToken: serviceToken,
			Name: contact.name,
			email: contact.email,
			Phone: contact.phone_number,
			Extension: contact.phone_extension,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setServiceProject(serverResponse.data))
					dispatch(isSavingServiceProject(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingServiceProject(false))
				console.error('Error adding a contact in Service/Project', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function removeContactInServiceProject(tokenItem, serviceToken) {
	return (dispatch, getState) => {
		dispatch(isSavingServiceProject(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/${serviceToken}/removecontact/${tokenItem}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setServiceProject(serverResponse.data))
					dispatch(isSavingServiceProject(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingServiceProject(false))
				console.error('Error removing an employee in Service/Project', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Remove an item from a list in service/project
 *
 * @export
 * @param {*} tokenItem
 * @param {*} serviceToken
 * @param {*} type
 * @returns
 */
export function removeItemInServiceProject(tokenItem, serviceToken, type) {
	return (dispatch, getState) => {
		dispatch(isSavingServiceProject(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/removeitem/${serviceToken}/${tokenItem}/${type}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingServiceProject(false))
					dispatch(removeItemFromListInServiceProject(tokenItem, type))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingServiceProject(false))
				console.error('Error removing an employee in Service/Project', error.response)
				return Promise.reject()
			})
	}
}

export function downloadMaterialsListReportPdf(serviceToken) {
	return (dispatch, getState) => {
		let supplierInformation = getState().suppliers.get('supplier')
			? getState().suppliers.get('supplier').toJS()
			: null
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let data = {
			Supplier: supplierInformation ? supplierData(supplierInformation) : null,
			Company: internalCompanyData(companyInformation),
			ServiceToken: serviceToken,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/materialslistpdf`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var file = new Blob([serverResponse.data], { type: 'application/pdf' })
					var fileURL = URL.createObjectURL(file)
					window.open(fileURL)
				}
			})
			.catch((error) => {
				console.error('Error in download rmi document ', error.response)
				return Promise.reject()
			})
	}
}

/**
 *Action to download report general of services by range date
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadServiceReportByRangeDate(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(isSavingServiceProject(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/downloadReportByRangeDate`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingServiceProject(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_servicios.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isSavingServiceProject(false))
				console.error('Error downloading report report by month', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update service/project data
 *
 * @export
 * @param {*} serviceProject
 * @param {*} supplierName
 * @returns
 */
export function addDocumentOnServiceProject(serviceToken, documentType, files) {
	return (dispatch, getState) => {
		dispatch(isSavingServiceProject(true))
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		const endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/serviceproject/${serviceToken}/documents/${documentType}`
		return axios
			.put(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingServiceProject(false))
					dispatch(updateServiceProject(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingServiceProject(false))
				console.error('Error in add document fiscal in service a serviceProject', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} serviceToken
 * @param {*} fileName
 * @returns
 */
export function removeDocumentFiscalInServiceProject(fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		dispatch(isSavingServiceProject(false))
		let serviceToken = getState().contractorServicesProjects.get('serviceProject').get('token')
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/serviceproject/${serviceToken}/removeconstancyfiscal`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateServiceProject(serverResponse.data))
				}
				dispatch(isSavingServiceProject(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingServiceProject(false))
				console.error('Error to remove evidence file(s) from service project', error.response)
				return Promise.reject()
			})
	}
}

export function removeServicesBycontractors(itemToken) {
	return function (dispatch) {
		let configuration = {
			headers: {
				Accept: 'application/json',
			},
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/movetotrash/serviceproject/${itemToken}`
		return axios
			.post(endPoint, null, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeServiceFromContract(itemToken))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) by prospect', error.response)
				return Promise.reject()
			})
	}
}
