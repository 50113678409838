/**Import react section */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import autoBind from 'auto-bind'
import moment from 'moment'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import ArchiveDownloadIcon from '@material-ui/icons/Archive'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import GuaranteeReportIcon from '@material-ui/icons/FileCopyOutlined'
import { Grid } from '@material-ui/core'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import OptionsMenu from '../common/OptionsMenu'
import Dialog from '../common/Dialog'
import UserSelector from '../common/UserSelector'
import DatePicker from '../common/DateTimePicker/DatePicker'
import Toaster from '../common/Toaster'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/**Import actions section */
import {
	loadGuaranteeReportFromBackEnd,
	getGuaranteeReportCount,
	CHANGE_GUARANTEE_REPORT_SORT_QUERY,
	CHANGE_GUARANTEE_PAGINATION,
	CHANGE_GUARANTEE_REPORT_SORT_CRITERIA,
	downloadGuarranteeReportByMonth,
} from '../../store/guaranteeReport/guaranteeReportAction'

import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canUserCreateReportModuleQuality } from '../../store/helpers/RolesHelper'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'

/**
 *Guarantee report view
 *
 * @class Index
 * @extends {Component}
 */
class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			itemToken: null,
			showMenu: true,
			openModalGuaranteeMonthlyReport: false,
			startDate: '',
			endDate: '',
			sucursalUser: '',
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadGuaranteeReportFromBackEnd(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getGuaranteeReportCount()
		}
	}

	/**
	 *componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadGuaranteeReportFromBackEnd()
			this.props.getGuaranteeReportCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadGuaranteeReportFromBackEnd(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.guaranteeReport === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.guaranteeReport}
					configuration={TableConfiguration(IsCurrentActiveDirectoryUser(this.props.user))}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(guaranteeReport) => {
						this.props.history.push(`/guaranteereport/${guaranteeReport.token}`)
					}}
					isIndex
					totalRows={this.props.guaranteeReportCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Handle on close optons menu to set void item token in state
	 *
	 * @memberof index
	 */
	handleCloseOptionsMenu() {
		if (!this.state.openModalGuaranteeMonthlyReport) {
			this.setState(
				{
					itemToken: '',
					showMenu: false,
				},
				() => {
					this.setState({ showMenu: true })
				}
			)
		}
	}

	/**
	 *Open modal for set report by mont
	 *
	 * @memberof Index
	 */
	handleOpenModal() {
		this.setState({
			openModalGuaranteeMonthlyReport: true,
		})
	}

	/**
	 * close modal to report by month
	 */
	handleCloseModal() {
		this.setState(
			{
				openModalGuaranteeMonthlyReport: false,
			},
			() => this.handleCloseOptionsMenu()
		)
		this.setState({ startDate: '', endDate: '', sucursalUser: '' })
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "saveCorrectly"
	 *
	 * @param {string} [idTranslation="common.saveCorrectly"]
	 * @memberof Show
	 */
	showSuccessToaster(idTranslation = 'common.saveCorrectly') {
		this.setState({
			showToaster: true,
			toasterVariant: 'success',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "errorToSave"
	 *
	 * @param {string} [idTranslation="common.errorToSave"]
	 * @memberof show
	 */
	showErrorToaster(idTranslation = 'common.errorToSave') {
		this.setState({
			showToaster: true,
			toasterVariant: 'error',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * On update propoerties
	 * @param {*} property
	 * @param {*} value
	 */
	onUpdateProperty(property, value) {
		if (
			IsNullOrEmpty(this.state[property]) ||
			!moment(this.state[property], 'DD/MM/YYYY').isSame(moment(value, 'DD/MM/YYYY'))
		) {
			this.setState({
				[property]: value,
			})
		}
	}

	/**
	 *Set month to state
	 *
	 * @param {*} date
	 * @memberof Index
	 */
	setUserNameReport(sucursalUser) {
		this.setState({
			sucursalUser: sucursalUser.user_name,
		})
	}

	/**
	 *Download report of guarantee
	 *
	 * @memberof Index
	 */
	onDownloadReportByMonth() {
		let startDate = this.state.startDate.format('DD/MM/YYYY')
		let endDate = this.state.endDate.format('DD/MM/YYYY')
		this.props
			.downloadGuarranteeReportByMonth(startDate, endDate, this.state.sucursalUser)
			.then(() => {
				this.showSuccessToaster('common.downloadCorrectly')
				this.handleCloseModal()
			})
			.catch((error) => {
				this.showErrorToaster('common.errorToDownload')
			})
	}

	/**
	 * Render start Date
	 * @param {*} propertyTranslation
	 * @param {*} propertyName
	 * @param {*} minDateProperty
	 * @param {*} dependentPropertyName
	 * @param {*} xs
	 */
	renderDateTimePicker(propertyTranslation, propertyName, minDateProperty, dependentPropertyName, xs) {
		return (
			<Grid item xs={xs}>
				<Typography variant='caption' color={'textSecondary'}>
					<Translate id={propertyTranslation} />
				</Typography>
				<DatePicker
					onChangeRange={(event) => {
						this.onUpdateProperty(propertyName, event, dependentPropertyName)
					}}
					startDate={this.state[propertyName] ? moment(this.state[propertyName], 'DD/MM/YYYY') : null}
					minDate={this.state[minDateProperty] ? moment(this.state[minDateProperty], 'DD/MM/YYYY') : null}
				/>
			</Grid>
		)
	}

	/**
	 * Render donwlod report
	 */
	renderCalendar() {
		return (
			<div style={{ margin: 10, width: 500 }}>
				<Grid container spacing={24}>
					<Grid item xs={6}>
						{this.renderDateTimePicker('common.startDate', 'startDate', null, 'endDate', 12)}
					</Grid>
					<Grid item xs={6}>
						{this.renderDateTimePicker('common.endDate', 'endDate', 'startDate', null, 12)}
					</Grid>
					<Grid item xs={12}>
						<Typography variant='caption' gutterBottom color={'textSecondary'}>
							<Translate id='moduleGuarantee.branchOffice' />
						</Typography>
						<UserSelector
							onUserSelected={(sucursalUser) => {
								this.setUserNameReport(sucursalUser)
							}}
						/>
						<Typography variant='caption' gutterBottom color={'textSecondary'}>
							<Translate id='moduleGuarantee.messageTodownloadReport' />
						</Typography>
						<br />
					</Grid>
				</Grid>
			</div>
		)
	}

	/**
	 * Render buttons for download report by month
	 */
	renderModalButtons() {
		return (
			<DialogActions>
				<Button
					disabled={!this.state.startDate || !this.state.endDate || this.props.isSaving}
					onClick={() => this.onDownloadReportByMonth()}
					color='primary'
				>
					<Translate id='common.download' />
				</Button>
				<Button onClick={() => this.handleCloseModal()} color='secondary'>
					<Translate id='common.cancel' />
				</Button>
			</DialogActions>
		)
	}

	/**
	 *Render action button in card quality failure report
	 *
	 * @memberof Index
	 */
	renderAction() {
		var options = []
		options.push({
			itemClick: () => this.handleOpenModal(),
			tooltipTranslation: <Translate id='moduleGuarantee.downloadGuarranteeReportByMonth' />,
			menuItemIcon: <ArchiveDownloadIcon color='secondary' />,
			isLoading: this.props.isSaving,
		})
		if (this.state.showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === 'item.token'}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	/**
	 *Get guarantee report counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		if (this.props.guaranteeReport) {
			let totalguaranteeReport = this.props.guaranteeReport.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalguaranteeReport}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.guaranteeReportCount}</span>&nbsp;
					{this.props.translate('common.records')}
					<br />
					{this.props.translate('moduleGuarantee.tracing')}
				</div>
			)
		}
	}

	/**
	 * Create instance report guarantee
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<GuaranteeReportIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>

							{IsCurrentActiveDirectoryUser(this.props.user) &&
								canUserCreateReportModuleQuality(this.props.userRolesByCompany) && (
									<Tooltip title={'Agregar nueva garantía'}>
										<Fab
											size='small'
											color='primary'
											onClick={() => {
												this.props.history.push(`/guaranteereport/create`)
											}}
										>
											<AddtIcon />
										</Fab>
									</Tooltip>
								)}

							{IsCurrentActiveDirectoryUser(this.props.user) &&
								canUserCreateReportModuleQuality(this.props.userRolesByCompany) &&
								this.renderAction()}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.guaranteeReport' />{' '}
						</Typography>
					}
					subheader={this.getCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog for select month to download report */}
				<Dialog
					open={this.state.openModalGuaranteeMonthlyReport}
					onClose={this.handleCloseModal}
					header={
						<Typography style={{ margin: 10 }}>
							<Translate id='moduleGuarantee.downloadGuarranteeReportByMonth' />
						</Typography>
					}
					children={this.renderCalendar()}
					actions={this.renderModalButtons()}
				/>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableConfiguration(isUserInternal) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='moduleGuarantee.branchOffice' />,
			dataSource: 'create_by',
		},
		{
			header: <Translate id='moduleGuarantee.elaboratedBy' />,
			dataSource: 'user_name_report',
		},
		{
			header: <Translate id='moduleGuarantee.originFailure' />,
			dataSource: 'purchase_order_number',
		},
		{
			header: <Translate id='moduleGuarantee.numberPieze' />,
			dataSource: 'part_number',
		},
	]

	if (isUserInternal) {
		columns.push({
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		})
	}

	return { columns }
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		guaranteeReport: state.guaranteeReport.get('guaranteeReportList')
			? state.guaranteeReport.get('guaranteeReportList').toJS()
			: null,
		guaranteeReportCount: state.guaranteeReport.get('guaranteeReportCount'),
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isSaving: state.guaranteeReport.get('isSaving'),
		isLoading: state.guaranteeReport.get('isLoadingGuarantee'),

		sortCriteria: state.guaranteeReport.get('sortCriteria')
			? state.guaranteeReport.get('sortCriteria').toJS()
			: null,
		searchQuery: state.guaranteeReport.get('searchQuery'),
		page: state.guaranteeReport.get('page'),
		rowsPerPage: state.guaranteeReport.get('rowsPerPage'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadGuaranteeReportFromBackEnd: (query, orderBy, sortAscending, page, rowsPerPage) => {
			return dispatch(loadGuaranteeReportFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		getGuaranteeReportCount: () => {
			dispatch(getGuaranteeReportCount())
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_GUARANTEE_REPORT_SORT_QUERY,
				query: query,
			})
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_GUARANTEE_REPORT_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_GUARANTEE_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		downloadGuarranteeReportByMonth: (startDate, endDate, sucursalUser) => {
			return dispatch(downloadGuarranteeReportByMonth(startDate, endDate, sucursalUser))
		},
	}
}

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
