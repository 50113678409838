import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DialogSynchronizeWinners from './DialogSynchronizeWinners'

import {
	getIsLoadingWinners,
	getWinnersTransportTenders,
	getIsLoadingTransportTenderSelector,
	getIsLoadingSavingSelector,
} from '../../../../store/transportTender/transportTenderSelector'
import { onGetAllWinners, sendMassiveBinnacleAndSyncSAP } from '../../../../store/transportTender/transportTenderAction'

const SynchronizeWinnerInSap = (props) => {
	const { onClose, onResetIndex } = props
	const dispatch = useDispatch()

	const isLoadingWinners = useSelector((state) => getIsLoadingWinners(state))
	const winnersTransportTender = useSelector((state) => getWinnersTransportTenders(state))
	const isLoading = useSelector((state) => getIsLoadingTransportTenderSelector(state))
	const isSaving = useSelector((state) => getIsLoadingSavingSelector(state))

	const getAllWinners = () => dispatch(onGetAllWinners())

	const onSendBinnacleAndSyncSAP = () => {
		const proposalSyncSAPList = winnersTransportTender.map((winner) => {
			const idProposal = winner && winner.idProposal ? winner.idProposal : ''
			const idTransportTender = winner && winner.id ? winner.id : ''
			return {
				IdProposal: idProposal,
				IdTransportTender: idTransportTender,
				BitacoraStream: new Uint8Array(0),
			}
		})
		return dispatch(sendMassiveBinnacleAndSyncSAP(proposalSyncSAPList)).then(() => {
			onClose(false)
			onResetIndex()
			getAllWinners()
		})
	}

	const groupedByCarrierBiddingCode = [].concat(
		...winnersTransportTender
			.reduce((grouped, currentItem) => {
				const code = currentItem.carrierBiddingCode
				const existingGroup = grouped.find((group) => group[0].carrierBiddingCode === code)
				if (existingGroup) {
					existingGroup.push(currentItem)
				} else {
					grouped.push([currentItem])
				}
				return grouped
			}, [])
			.map((list) => list)
	)

	return (
		<DialogSynchronizeWinners
			{...props}
			onClose={onClose}
			getAllWinners={getAllWinners}
			isLoadingWinners={isLoadingWinners}
			winnersTransportTender={groupedByCarrierBiddingCode}
			onSendBinnacleAndSyncSAP={onSendBinnacleAndSyncSAP}
			isLoading={isLoading || isSaving}
		/>
	)
}
export default SynchronizeWinnerInSap
