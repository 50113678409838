/**Import react section */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
/**Import material UI Section*/
import { withStyles, TextField } from '@material-ui/core'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { RELEASE_PLAN_COLOR_BLUE_GRAY } from '../../../../store/helpers/StatusColorConstants'

/**Custom hooks index */
import { useIndirectQualificationCustomHookShow } from '../../customHooks/UseCustomHookShow'
const TextfieldQualificationResponse = (props) => {
	const { item, classes } = props
	const [value, setValue] = useState('')
	const [isVisibleError, setIsVisibleError] = useState(false)

	/**Use effect section */
	useEffect(() => {
		if (item && IsNullOrEmpty(value)) {
			setValue(props.item.Scope)
		}
	}, [])

	const { toasterError, onAddIndirectQualificationToSupplier } = useIndirectQualificationCustomHookShow()
	/**String props section */
	/**Bools props section */

	/**On change properties */
	const onPropertyChange = (event) => {
		let { value } = event.target
		if (value !== '') {
			let regexQuantity = /^\d+$/
			if (!regexQuantity.test(value) && value.length > 0) {
				toasterError('indirectqualifications.show.erroValidator', value)
				setIsVisibleError(true)
				return
			} else if (value > 5) {
				toasterError('indirectqualifications.show.gradingErrorGreaterThan')
				setIsVisibleError(true)
			} else if (value < 0) {
				toasterError('indirectqualifications.show.minorGradeError')
				setIsVisibleError(true)
			} else {
				item.Scope = value
				return onAddIndirectQualificationToSupplier(item).then((result_) => {
					if (result_) {
						setValue(value)
						setIsVisibleError(false)
					}
				})
			}
		} else {
			item.Scope = ''
			setValue(value)
			setIsVisibleError(false)
		}
	}

	return (
		<TextField
			margin='dense'
			name='Scope'
			variant='outlined'
			color='primary'
			size='small'
			InputProps={{ classes: { input: item.Scope === 0 ? classes.inputTextfield : classes.inputTextfieldColor } }}
			value={value}
			onChange={(event) => onPropertyChange(event)}
			defaultValue={item.Scope}
			error={isVisibleError}
		/>
	)
}

const styles = (theme) => ({
	inputTextfield: {
		height: 6,
		width: 8,
	},
	inputTextfieldColor: {
		height: 6,
		width: 8,
		color: RELEASE_PLAN_COLOR_BLUE_GRAY,
	},
})

TextfieldQualificationResponse.propTypes = {
	/**
	 * Determinate if is loading information from back end
	 * and see view skeleton
	 */
	isLoading: PropTypes.bool.isRequired,
}

TextfieldQualificationResponse.defaultProps = {
	isLoading: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(TextfieldQualificationResponse))
