import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInputOptions from '../common/SearchInput/SearchInputOptions'
import TrackingStatus from '../common/TrackingStatus'
import DataTable from '../common/DataTable'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import ReworkIcon from '@material-ui/icons/AutorenewOutlined'
import AddIcon from '@material-ui/icons/AddTwoTone'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadReworksFromBackEnd,
	getReworksCount,
	CHANGE_REWORKS_SORT_CRITERIA,
	CHANGE_REWORKS_QUERY,
	CHANGE_REWORKS_PAGINATION,
	IS_LOADING_ALL_REWORKS_REPORTS,
} from '../../store/reworks/ReworkAction'

import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canUserCreateRework } from '../../store/helpers/RolesHelper'

/**
 * Reworks  view
 *
 * @class Reworks
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance Reworks
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadReworks(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.props.isAllReports
			)
			this.props.getReworksCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadReworks()
			this.props.getReworksCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage ||
			prevProps.isAllReports !== this.props.isAllReports
		) {
			this.props.loadReworks(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.props.isAllReports
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.onLoadingAllReports(false)
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * On change get all reports
	 */
	onChangeGetAllReports() {
		this.props.onLoadingAllReports(true)
	}

	/**
	 * Method to custom render in datalist
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof Index
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'tracking') {
			return <TrackingStatus tracking={item.tracings} />
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingReworks === true) {
			return <LoadingPage />
		} else if (this.props.reworks === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let tableConfiguration = TableInternalConfiguration
			if (!IsCurrentActiveDirectoryUser(this.props.user)) {
				tableConfiguration = TableExternalConfiguration
			}
			return (
				<DataTable
					data={this.props.reworks}
					configuration={tableConfiguration}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(rework) => {
						this.props.history.push(`/reworks/${rework.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.reworksCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get reworks counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getReworksCounterMessage() {
		if (this.props.reworks) {
			let totalReworks = this.props.reworks.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalReworks}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.reworksCount}</span>&nbsp;
					{this.props.translate('common.records')}
					<br />
					{this.props.translate('reworks.folioTracking')}
				</div>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof Reworks
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<ReworkIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInputOptions
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
								isSearchAllReports
								onSearchAllReports={this.onChangeGetAllReports}
							/>

							{IsCurrentActiveDirectoryUser(this.props.user) &&
								canUserCreateRework(this.props.userRolesByCompany) && (
									<Tooltip title={<Translate id='reworks.addRework' />}>
										<Fab
											size='small'
											color='primary'
											onClick={() => {
												this.props.history.push(`/reworks/create`)
											}}
										>
											<AddIcon />
										</Fab>
									</Tooltip>
								)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.rework' />{' '}
						</Typography>
					}
					subheader={this.getReworksCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>
			</Card>
		)
	}
}

const TableInternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'tracking',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		},
		{
			header: <Translate id='common.partNumber' />,
			dataSource: 'full_part_description',
		},
		{
			header: <Translate id='devolutions.provenance' />,
			dataSource: 'get_provenance',
		},
	],
}

const TableExternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'tracking',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='common.partNumber' />,
			dataSource: 'full_part_description',
		},
		{
			header: <Translate id='devolutions.provenance' />,
			dataSource: 'get_provenance',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingReworks: state.reworks.get('isLoadingReworks'),
		reworks: state.reworks.get('reworks') ? state.reworks.get('reworks').toJS() : null,
		sortCriteria: state.reworks.get('sortCriteria') ? state.reworks.get('sortCriteria').toJS() : null,
		searchQuery: state.reworks.get('searchQuery'),
		page: state.memos.get('page'),
		rowsPerPage: state.memos.get('rowsPerPage'),
		reworksCount: state.reworks.get('reworksCount'),
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isAllReports: state.reworks.get('isAllReworksReports'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadReworks: (query, orderBy, sortAscending, page, rowsPerPage, isAllReports) => {
			dispatch(loadReworksFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, isAllReports))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_REWORKS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_REWORKS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_REWORKS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		onLoadingAllReports: (isAllReports) => {
			dispatch({
				type: IS_LOADING_ALL_REWORKS_REPORTS,
				isAllReports,
			})
		},
		getReworksCount: () => {
			dispatch(getReworksCount())
		},
	}
}

const styles = (theme) => ({
	avatar: {},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
