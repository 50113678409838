import { fromJS, Map } from 'immutable'

import * as servicesProjectsActions from './ServicesProjectsAction'
import { NameListByType } from '../helpers/ServicesListTypes'

/** Initial state */
export const servicesProjectsInitialState = fromJS({
	isLoadingServicesProjects: true,
	servicesProjects: [],
	isLoadingServiceProject: false,
	serviceProject: null,
	sortCriteria: {
		by: 'creation_date',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	servicesProjectsCount: null,
	isSavingServiceProject: false,
	isLoadingPurchaseOrders: false,
	purchaseOrders: null,
})

export default function servicesProjectsReducer(state = servicesProjectsInitialState, action) {
	switch (action.type) {
		case servicesProjectsActions.IS_LOADING_SERVICES_PROJECTS: {
			return state.merge({
				isLoadingServicesProjects: action.isLoadingServicesProjects,
			})
		}
		case servicesProjectsActions.SET_SERVICES_PROJECTS_LIST: {
			let servicesProjectsLists = fromJS(action.servicesProjects)
			return state.merge({
				servicesProjects: servicesProjectsLists,
				isLoadingServicesProjects: false,
			})
		}
		case servicesProjectsActions.SET_SERVICES_PROJECTS_DELETED_LIST: {
			let servicesProjectsLists = fromJS(action.servicesProjectsDeleted)
			return state.merge({
				servicesProjectsDeleted: servicesProjectsLists,
				isLoadingServicesProjects: false,
			})
		}
		case servicesProjectsActions.IS_LOADING_SERVICE_PROJECT: {
			return state.merge({
				isLoadingServiceProject: action.isLoadingServiceProject,
			})
		}
		case servicesProjectsActions.SET_SERVICE_PROJECT: {
			return state.merge({
				isLoadingServiceProject: false,
				serviceProject: fromJS(action.serviceProject),
			})
		}
		case servicesProjectsActions.CHANGE_SERVICES_PROJECTS_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case servicesProjectsActions.CHANGE_SERVICES_PROJECTS_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case servicesProjectsActions.CHANGE_SERVICES_PROJECTS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case servicesProjectsActions.SET_SERVICES_PROJECTS_COUNT: {
			return state.merge({
				servicesProjectsCount: action.count,
			})
		}
		case servicesProjectsActions.SET_SERVICES_PROJECTS_DELETED_COUNT: {
			return state.merge({
				servicesProjectsDeletedCount: action.count,
			})
		}
		case servicesProjectsActions.IS_SAVING_SERVICE_PROJECT: {
			return state.merge({
				isSavingServiceProject: action.isSavingServiceProject,
			})
		}
		case servicesProjectsActions.ADD_SERVICE_PROJECT: {
			let serviceProject = Map(action.serviceProject)
			let servicesProjectsList = state.get('servicesProjects').push(serviceProject)
			return state.merge({
				servicesProjects: servicesProjectsList,
				isSavingServiceProject: false,
			})
		}
		case servicesProjectsActions.IS_LOADING_PURCHASE_ORDER: {
			return state.merge({
				isLoadingPurchaseOrders: action.isLoadingPurchaseOrder,
			})
		}
		case servicesProjectsActions.SET_PURCHASE_ORDERS: {
			return state.merge({
				purchaseOrders: fromJS(action.order),
			})
		}
		case servicesProjectsActions.UPDATE_SERVICE_PROJECT: {
			return state.merge({
				isSavingServiceProject: false,
				serviceProject: fromJS(action.serviceProject),
			})
		}
		case servicesProjectsActions.ADD_ITEM_IN_LIST_IN_SERVICE_PROJECT: {
			let list = NameListByType[action.listType]
			let item = fromJS(action.item)
			let newList = state.get('serviceProject').get(list).push(item)
			return state.setIn(['serviceProject', list], fromJS(newList)).set('isSavingServiceProject', false)
		}
		case servicesProjectsActions.REMOVE_ITEM_FROM_LIST_IN_SERVICE_PROJECT: {
			let list = NameListByType[action.listType]
			let oldList = state.getIn(['serviceProject', list]).toJS()
			let newList = oldList.filter((item) => {
				return item.token !== action.itemToken
			})
			return state.setIn(['serviceProject', list], fromJS(newList)).set('isSavingServiceProject', false)
		}
		default:
			return state
	}
}
