/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
/**Import material UI Section*/

import { withStyles, Collapse, Typography, IconButton } from '@material-ui/core'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import CommentIcon from '@material-ui/icons/Comment'
/**Import components section*/
import ItemsContentTable from './ItemsContentTable'
import DialogWarningQualificationMessage from './DialogWarningQualificationMessage'
import { onGetHeaderTableEvaluatorsByItemType } from '../../../../store/helpers/IndirectQualificationsHelpers'
import {
	COLOR_SUBSECTION_EXTRA,
	RELEASE_PLAN_COLOR_YELLOW,
	COLOR_STATUS_GRAY,
	COLOR_STATUS_DANGER,
} from '../../../../store/helpers/StatusColorConstants'
import { IsCurrentNotActiveDirectoryUser } from '../../../../store/helpers/SessionHelper'
import { useIndirectQualificationCustomHookShow } from '../../customHooks/UseCustomHookShow'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { IndirectQualificationType, IndirectQualificationsStatus } from '../../../../store/helpers/AppConstants'

const EvaluatorsHeaderTable = (props) => {
	const { user } = useIndirectQualificationCustomHookShow()

	/**String props section */
	// const { } = props
	/**Bools props section */
	/**Arrays props section */
	const { evaluators } = props
	/**Functions props section */
	//const {} = props
	/**Objects props section */
	const { classes, supplier, indirectQualification } = props
	let isUserNotActiveDirectory = IsCurrentNotActiveDirectoryUser(user)

	const onOpenLateralPanelForAddComments = (identifier, supplier) => {
		if (props.onLateralPanelConfig) {
			props.onLateralPanelConfig(identifier, supplier)
		}
	}

	const GetColorIconComments = (evaluator) => {
		let existSomeCalificationSmallerThan = evaluator.Items.some((x) => x.Scope > 0 && x.Scope <= 3)
		if (existSomeCalificationSmallerThan && IsNullOrEmpty(evaluator.Comments)) return classes.commentsIconDanger
		if (!IsNullOrEmpty(evaluator.Comments)) return classes.containsCommnets
		else return classes.emptyComments
	}
	let isVisibleColumnsAditionals = indirectQualification.Status >= IndirectQualificationsStatus.GENERATE_RATING
	return (
		<TableHead
		//key={uuidv4()}
		>
			<TableRow>
				{onGetHeaderTableEvaluatorsByItemType(evaluators, isUserNotActiveDirectory, indirectQualification).map(
					(column) => {
						return (
							<TableCell
								padding={column.title ? '' : 'none'}
								style={{
									height: 'auto',
									backgroundColor: column.backgroundColor
										? column.backgroundColor
										: COLOR_SUBSECTION_EXTRA,
									color: column.backgroundColor === 'white' ? column.backgroundColor : null,
								}}
								//key={uuidv4()}
								align='center'
							>
								<div style={{ display: 'grid', gridTemplateRows: '3fr 1fr' }}>
									<div>
										<strong>{column.title}</strong>
									</div>
									{column.isVisibleWarning && (
										<div>
											<DialogWarningQualificationMessage columnItem={column} />
										</div>
									)}
								</div>
							</TableCell>
						)
					}
				)}
			</TableRow>
			{evaluators.map((evaluator) => {
				let isResponsibleAssigned = user && evaluator.ResponsibleUserName === user.profile.username
				evaluator.SupplierId = supplier ? supplier.Id : ''
				evaluator.IndirectQualificationId = indirectQualification ? indirectQualification.Id : ''
				return (
					<TableRow>
						<TableCell
							style={{
								backgroundColor: supplier.ColorStatustable,
								wordBreak: 'break-word',
								paddingLeft: 3,
								paddingRight: 3,
							}}
						/>
						<TableCell align='center' className={classes.cellTable}>
							<Typography className={classes.expandedIcon} variant='subtitle2'></Typography>
						</TableCell>
						<TableCell align='left' className={classes.cellTable}>
							{supplier ? supplier.StatusDescription : ''}
						</TableCell>

						<TableCell className={classes.cellTable}>
							<Collapse in={true} unmountOnExit={true}>
								{isUserNotActiveDirectory ? '' : evaluator.ResponsibleArea}
							</Collapse>
						</TableCell>
						<TableCell className={classes.cellTable}>
							<Collapse in={true} unmountOnExit={true}>
								<Typography className={classes.nameSupplier} variant='caption'>
									{isUserNotActiveDirectory ? '' : evaluator.ResponsibleName}
								</Typography>
							</Collapse>
						</TableCell>
						<TableCell align='center' className={classes.nameSupplier}>
							<div className={classes.commentSupplier2}>
								<IconButton
									onClick={() =>
										onOpenLateralPanelForAddComments(
											'ShowAddCommentsSupplierIndirectQualification',
											evaluator
										)
									}
								>
									<CommentIcon className={GetColorIconComments(evaluator)} />
								</IconButton>
								{!isUserNotActiveDirectory && (
									<strong>
										{evaluator.TotalItemsRequiered} / {evaluator.TotalItemsEvaluated}
									</strong>
								)}
							</div>
						</TableCell>
						<ItemsContentTable
							items={evaluator.Items}
							isResponsibleAssigned={isResponsibleAssigned}
							indirectQualification={indirectQualification}
							supplier={supplier}
							evaluator={evaluator}
							onOpenLateralPanelForAddComments={onOpenLateralPanelForAddComments}
							isVisibleComment={
								true && indirectQualification.EvaluationType === IndirectQualificationType.CARRIER
							}
						/>
						{isVisibleColumnsAditionals && (
							<TableCell align='center'>
								<Collapse in={true} unmountOnExit={true}>
									{evaluator.RatingEvaluator}
								</Collapse>
							</TableCell>
						)}
					</TableRow>
				)
			})}
		</TableHead>
	)
}

const styles = (theme) => ({
	tableCellHeader: {
		height: '15px',
		position: 'sticky',
		top: 0,
		zIndex: '30',
	},
	colorTextWhite: {
		color: 'white',
	},
	containsCommnets: {
		color: RELEASE_PLAN_COLOR_YELLOW,
	},
	emptyComments: {
		color: COLOR_STATUS_GRAY,
	},
	commentSupplier2: {
		width: '140px',
	},
	commentsIconDanger: {
		color: COLOR_STATUS_DANGER,
	},
	nameSupplier: {
		width: '185px',
	},
	responsibleName: {
		width: '250px',
	},
	expandedIcon: {
		width: '40px',
	},
	inputTextfield: {
		height: 6,
		width: 8,
	},
	total: {
		color: 'white',
	},
	cellTable: {
		paddingRight: 2,
	},
})

EvaluatorsHeaderTable.propTypes = {
	/**
	 * Determinate if is loading information from back end
	 * and see view skeleton
	 */
	isLoading: PropTypes.bool.isRequired,
	indirectQualifications: PropTypes.object.isRequired,
}

EvaluatorsHeaderTable.defaultProps = {
	isLoading: false,
	indirectQualifications: [],
}

export default withRouter(withStyles(styles, { withTheme: true })(EvaluatorsHeaderTable))
