import React, { Component } from 'react';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import { Translate } from "react-localize-redux";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import RolesIcon from '@material-ui/icons/VpnKey';

/** Import section actions */
import { getDescriptionRole } from '../../../../store/helpers/RolesHelper';

/**
 * User card
 *
 * @class RolesCard
 * @extends {Component}
 */
class ProfileRolesCard extends Component {

    /**
    * Get roles 
    *
    * @returns
    * @memberof RolesCard
    */
    getRolesList() {
        const { userRolesByCompany } = this.props
        return (
            <List dense>
                {(userRolesByCompany.map((role) => {
                    let roleDescription = getDescriptionRole(role.name);
                    return (
                        <ListItem key={role.id} divider>
                            <ListItemText primary={<Translate id={`rolesDescription.${roleDescription}`}/>} />
                        </ListItem>
                    )
                })
                )}
            </List>
        )
    }

    /**
     * Render
     *
     * @returns
     * @memberof RolesCard
     */
    render() {
        const { classes } = this.props;
        return (
            <Paper>
                <Card className={classes.card}>
                    <CardHeader
                        avatar={
                            <Avatar className={classes.rolesAvatar}>
                                <RolesIcon />
                            </Avatar>
                        }
                        title={<Typography variant="subtitle2" gutterBottom><Translate id="users.roles"/></Typography>}
                        subheader={<Translate id="users.accessBycompany"/>}
                    />
                    <CardContent>
                        {this.getRolesList()}
                    </CardContent>
                    <CardActions className={classes.actions} disableActionSpacing>
                    </CardActions>
                </Card>
            </Paper>
        );
    }
}


export default ProfileRolesCard;