/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
/**Import MaterialUi section */
import {
    Grid, Avatar, Button, Typography
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import HtmlEditor from '../../../common/HtmlEditor'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { canBeEditBiddingInStatusResolved, stripHtml } from '../../../../store/helpers/BiddingHelper'


const AddCommentsSuppliersLateralPanel = (props) => {
    /**props section */
    const { onCloseButtonClick, onSaveButtonClick,
        isSaving, classes, userRolesByCompany, userProfile, bidding
    } = props

    const [supplier, setSupplier] = useState(null);

    /**Use effect section */
    useEffect(() => {
        if (props.supplier && supplier === null) {
            props.supplier.Comment = props.supplier ? props.supplier.finalComment:""
            setSupplier(props.supplier)
        }
    }, [props.item])

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(supplier)
        }
    }



    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (supplier && IsNullOrEmpty(supplier.Comment)) return false
        return true
    }


    /**On change properties */
    const onTextFieldTextPropertyChange = (name, value) => {
        setSupplier({
            ...supplier,
            [name]: value
        })
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<span>{<Translate id="biddingsModule.commentsToSupplier" />} </span>}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid item xs={12} >
                        <Typography variant="subtitle2" color="primary">{supplier ? supplier.supplierName : ""}</Typography>
                        {canBeEditBiddingInStatusResolved(bidding, userRolesByCompany, userProfile) && <HtmlEditor
                            onEditorChange={(value) => onTextFieldTextPropertyChange("Comment", value)}
                            initialValue={supplier ? supplier.Comment : ""}
                            isDisabled={!canBeEditBiddingInStatusResolved(bidding, userRolesByCompany, userProfile)}
                        />}
                        {!canBeEditBiddingInStatusResolved(bidding, userRolesByCompany, userProfile) && stripHtml(supplier ? supplier.Comment : "")}
                        <br />

                    </Grid>
                </div>
                {
                    canBeEditBiddingInStatusResolved(bidding, userRolesByCompany, userProfile) && <Grid container>
                        <Grid item xs={12} className={classes.containerBtn}>
                            <Button
                                data-cy="btn-save"
                                type="button"
                                color="primary"
                                variant='outlined'
                                onClick={onSave}
                                disabled={!canBeSaved() || isSaving}>
                                {<Translate id="common.save" />}
                            </Button>
                        </Grid>
                    </Grid>
                }

            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "20px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    titleDisplay: {
        display: "flex"
    }

});

AddCommentsSuppliersLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddCommentsSuppliersLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddCommentsSuppliersLateralPanel))