import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import ProspectSupplierIncon from '@material-ui/icons/LocalShipping'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import AddNoticetIcon from '@material-ui/icons/AddTwoTone'
import Fab from '@material-ui/core/Fab'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadProspectsFromBackEnd,
	getProspectsCount,
	CHANGE_PROSPECTS_SORT_CRITERIA,
	CHANGE_PROSPECTS_SORT_QUERY,
	CHANGE_PROSPECTS_PAGINATION,
	downloadProspectsReportByRangeDate,
} from '../../store/prospects/ProspectAction'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canBeModuleProspectsSupplier } from '../../store/helpers/RolesHelper'
import { Tooltip } from '@material-ui/core'
import DataTable from '../common/DataTable'
import Toaster from '../common/Toaster'
import { backgroundColorSelect } from '../../store/helpers/ProspectHelper.js'
import DonwloadReportDialog from '../common/DonwloadReportDialog'
import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers'
import { COMPANY_IMBERA } from '../../store/helpers/ResourcesHelper'

/**
 * contact Prospects view
 *
 * @class Prospects supplier
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance of Prospects
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			itemToken: null,
			showMenu: true,
			openModalByMonthReport: false,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadProspects(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getProspectsCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadProspects()
			this.props.getProspectsCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadProspects(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'logo') {
			return (
				<Avatar data-cy='option-avatar' style={{ backgroundColor: backgroundColorSelect(item.status) }}>
					<ProspectSupplierIncon />
				</Avatar>
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Function to add new propsect
	 */
	onClickAddButton() {
		this.props.history.push(`/prospects/create`)
	}

	/**
	 * Open modal for set report by mont
	 *
	 * @memberof Index
	 */
	handleOpenModal() {
		this.setState({
			openModalByMonthReport: true,
		})
	}

	/**
	 * Close modal to report by month
	 *
	 * @memberof Index
	 */
	handleCloseModal() {
		this.setState({
			openModalByMonthReport: false,
		})
		this.setState({ startDate: '', endDate: '' })
	}

	/**
	 *Download report of from contacts
	 *
	 * @memberof Index
	 */
	handleDownloadReportByDateRange(startDate, endDate) {
		console.log('handleDownloadReportByDateRange')
		this.props
			.downloadProspectsReportByRangeDate(startDate, endDate)
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
				this.handleCloseModal()
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	/**
	 * Get contactSuppliers counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCountMessage() {
		const { classes } = this.props
		if (this.props.prospects) {
			let totalprospects = this.props.prospects.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{totalprospects}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.prospectsCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingProspects === true) {
			return <LoadingPage />
		} else if (this.props.prospects === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.prospects}
					configuration={TableConfiguration(this.props.companyCode === COMPANY_IMBERA)}
					onRenderCellItem={this.onRenderCellItem}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(prospect) => {
						this.props.history.push(`/prospects/${prospect.token}`)
					}}
					isIndex
					showFilters
					totalRows={this.props.prospectsCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof prospects
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<ProspectSupplierIncon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput onChangeSearchValue={this.onChangeSearchValue} />
							{canBeModuleProspectsSupplier(this.props.userRolesByCompany) && (
								<Tooltip title={<Translate id={'prospects.addProspect'} />}>
									<Fab
										size='small'
										color='primary'
										onClick={() => {
											this.onClickAddButton()
										}}
									>
										<AddNoticetIcon />
									</Fab>
								</Tooltip>
							)}
							{canBeModuleProspectsSupplier(this.props.userRolesByCompany) && (
								<Tooltip title={<Translate id='prospects.downloadReportByMonth' />}>
									<Fab
										size='small'
										color={'primary'}
										className={classes.categoryIcon}
										onClick={() => this.handleOpenModal()}
										isSaving={this.props.isSaving}
									>
										<DownloadIcon />
									</Fab>
								</Tooltip>
							)}
						</div>
					}
					title={
						<Typography variant='button'>
							{this.props.companyCode === COMPANY_IMBERA ? (
								<Translate id='prospects.titleProviderregistration' />
							) : (
								<Translate id='prospects.title' />
							)}
						</Typography>
					}
					subheader={this.getCountMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to download report */}
				<DonwloadReportDialog
					title={<Translate id='prospects.downloadReportByMonth' />}
					open={this.state.openModalByMonthReport}
					onClose={this.handleCloseModal}
					isSaving={this.props.isSaving}
					onDonwloadItems={this.handleDownloadReportByDateRange}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableConfiguration(iscompanyIMB) {
	let columns = [
		{
			header: '',
			dataSource: 'logo',
		},
		{
			header: 'Folio',
			dataSource: 'folio',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'description_status',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='prospects.prospectName' />,
			dataSource: 'prospect_name',
			isSortable: true,
		},
		{
			header: <Translate id='prospects.contactName' />,
			dataSource: 'contact_name',
			isSortable: true,
		},
		{
			header: <Translate id='common.createBy' />,
			dataSource: 'creator_name',
		},
		{
			header: <Translate id='prospects.vendorCode' />,
			dataSource: 'supplier_code',
			isSortable: true,
		},
	]
	if (iscompanyIMB) {
		columns.push({
			header: <Translate id='prospects.folioSit' />,
			dataSource: 'folio_sit',
			isSortable: true,
		})
	}
	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingProspects: state.prospects.get('isLoadingProspects'),
		prospects: state.prospects.get('prospects') ? state.prospects.get('prospects').toJS() : null,
		sortCriteria: state.prospects.get('sortCriteria') ? state.prospects.get('sortCriteria').toJS() : null,
		searchQuery: state.prospects.get('searchQuery'),
		page: state.prospects.get('page'),
		rowsPerPage: state.prospects.get('rowsPerPage'),
		prospectsCount: state.prospects.get('prospectsCount'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		companyCode: state.profile.get('selectedCompany').get('company_code'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadProspects: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadProspectsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_PROSPECTS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_PROSPECTS_SORT_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_PROSPECTS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getProspectsCount: () => {
			dispatch(getProspectsCount())
		},
		downloadProspectsReportByRangeDate: (startDate, endDate) => {
			return dispatch(downloadProspectsReportByRangeDate(startDate, endDate))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	categoryIcon: {
		marginLeft: '5px',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
