/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import {
    Typography,
    withStyles,
    ListItemSecondaryAction,
    IconButton,
} from "@material-ui/core";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import DeleteIcon from '@material-ui/icons/Delete';
const BaseList = (props) => {
    const { classes } = props;
    /**String props section */
    // const { } = props
    /**Bools props section */
    const { isSaving, isSelected } = props
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onEditBaseItemClick, onDeletedBiddingSectionFromBackEnd } = props
    /**Objects props section */
    const { base } = props

    const handleClick = (item) => {
        if (props.onEditBaseItemClick) {
            onEditBaseItemClick(item)
        }
    };

    const onDeleteSection = (item) => {
        if (props.onDeletedBiddingSectionFromBackEnd) {
            onDeletedBiddingSectionFromBackEnd(item)
        }

    }
    return (
        <ListItem className={isSelected ? classes.selectedItem : null} key={base.Id} button onClick={() => handleClick(base)} disabled={isSaving}>
            <ListItemIcon>
                <LibraryBooksIcon />
            </ListItemIcon>
            <ListItemText primary={<Typography className={classes.ellipsisPrimaryText}> {base ? base.Name : ""}</Typography>} />
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    title='Eliminar sección'
                    onClick={() => onDeleteSection(base)}
                >
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
};

const styles = (theme) => ({
    nested: {
        paddingLeft: "44px",
    },
    ellipsisSecondaryText: {
        width: "100px",
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    ellipsisPrimaryText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: "-15px",
        width: "150px",
    },
    selectedItem: {
		backgroundColor: theme.palette.secondary.main,

    }
});

BaseList.propTypes = {
    base: PropTypes.object
};

BaseList.defaultProps = {
    base: null
};

export default withRouter(
    withStyles(styles, { withTheme: true })(BaseList)
);
