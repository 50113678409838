/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'

/** Import material UI Section */

/** Custom components import section */
import AcknowledgmentReceipt from './AcknowledgmentReceipt.js'
import GeneralInformation from './GeneralInformation'
import MaterialsTable from './MaterialsTable.js'
import PanelNoteFiles from './PanelNoteFiles'
import { AcknowledgmentsReceiptFilesVersions } from '../../../store/helpers/AppConstants.js'

/**
 * A tab panel component
 */
const TabPanelComponent = ({
	deliveries,
	activeTab,
	codeTab,
	consignmentNote,
	onUploadAcknowledgmentReceipts = () => console.warn('onUploadAcknowledgmentReceipts is not defined!'),
	onRemoveAcknowledgmentReceipts = () => console.warn('onRemoveAcknowledgmentReceipts is not defined!'),
	onDownloadAcknowledgmentReceipts = () => console.warn('onDownloadAcknowledgmentReceipts is not defined!'),
	onGetgetAcknowledgmentFileByName = () => console.warn('onGetgetAcknowledgmentFileByName is not defined!'),

	downloadingAcknowledgmentReceipts = false,
	canBeAdd,
	canbeUpdatePlant,
	canBeCreateDevolution,
	isSaving,
	onDialogCreateDevolution = () => console.warn('onDialogCreateDevolution is not defined!'),
	onOpenCloseDeleteMaterialDialog,
	onUpdateDelivery,
}) => {
	let deliverySelected = deliveries.find((d) => d.recipient.code === codeTab)
	console.log('deliverySelected', deliverySelected)
	if (deliverySelected === undefined) {
		deliverySelected = deliveries[activeTab]
	}
	//const deliverySelected = deliveries[activeTab]
	const [configurationAcknowledgmentFileDialog, setConfigurationAcknowledgmentFileDialog] = useState({
		open: false,
		deliveryCode: '',
		attachments: [],
	})
	console.log('configurationAcknowledgmentFileDialog', configurationAcknowledgmentFileDialog)

	const onConfigurationAcknowledgmentFileDialog = (deliveryCode, attachments = []) => {
		setConfigurationAcknowledgmentFileDialog({
			open: !configurationAcknowledgmentFileDialog.open,
			deliveryCode: deliveryCode,
			attachments: attachments,
		})
	}

	const onSaveAttahcments = (attachments) => {
		let code = deliverySelected.recipient ? deliverySelected.recipient.code : deliverySelected.id
		return onUploadAcknowledgmentReceipts(code, attachments, configurationAcknowledgmentFileDialog.deliveryCode)
			.then(() => {
				onConfigurationAcknowledgmentFileDialog('', [])
				return Promise.resolve()
			})
			.catch(() => {
				onConfigurationAcknowledgmentFileDialog('', [])
				Promise.reject()
			})
	}

	const onRemoveAttahcments = (deliveryCode) => {
		let code = deliverySelected.recipient ? deliverySelected.recipient.code : deliverySelected.id
		onRemoveAcknowledgmentReceipts(deliverySelected.id, code, deliveryCode)
	}

	const onDownloadFiles = (deliveryCode) => {
		let code = deliverySelected.recipient ? deliverySelected.recipient.code : deliverySelected.id
		onDownloadAcknowledgmentReceipts(deliverySelected.id, code, deliveryCode)
	}

	const onSeeGetAcknowledgmentFileByName = (deliveryCode, fileName, attachmentType) => {
		let code = deliverySelected.recipient ? deliverySelected.recipient.code : deliverySelected.id
		onGetgetAcknowledgmentFileByName(deliverySelected.id, code, deliveryCode, fileName, attachmentType)
	}

	if (deliverySelected !== null && deliverySelected !== undefined) {
		const acknowledgments = deliverySelected.attachments || []
		return (
			<div value={activeTab}>
				<GeneralInformation
					delivery={deliverySelected}
					onUpdateDelivery={onUpdateDelivery}
					canBeAdd={canBeAdd}
				/>
				<MaterialsTable
					delivery={deliverySelected}
					consignmentNote={consignmentNote}
					canbeUpdatePlant={canbeUpdatePlant}
					canBeCreateDevolution={canBeCreateDevolution}
					onDialogCreateDevolution={onDialogCreateDevolution}
					onOpenCloseDeleteMaterialDialog={onOpenCloseDeleteMaterialDialog}
					isSaving={isSaving}
					//AcknowledgmentReceipt
					canBeAddAcknowledgmentFile={canBeAdd}
					onAddAcknowledgmentFile={onConfigurationAcknowledgmentFileDialog}
					acknowledgmentFiles={deliverySelected ? deliverySelected.attachments : []}
					onRemoveAcknowledgmentFiles={onRemoveAttahcments}
					onDownloadAcknowledgmentFiles={onDownloadFiles}
					onSeeGetAcknowledgmentFileByName={onSeeGetAcknowledgmentFileByName}
					downloadingAcknowledgmentFiles={downloadingAcknowledgmentReceipts}
				/>
				{consignmentNote &&
					consignmentNote.acknowledgmentsReceiptFilesVersion === AcknowledgmentsReceiptFilesVersions.V1 && (
						<AcknowledgmentReceipt
							canBeAdd={canBeAdd}
							onAddingButtonClick={onConfigurationAcknowledgmentFileDialog}
							files={deliverySelected ? deliverySelected.attachments : []}
							onRemoveFiles={onRemoveAttahcments}
							onDownloadFiles={onDownloadFiles}
							onSeeGetAcknowledgmentFileByName={onSeeGetAcknowledgmentFileByName}
							downloadingFiles={downloadingAcknowledgmentReceipts}
							deliveryCode='NA' //Not deleted this line applica for files V1
							isSaving={isSaving}
						/>
					)}

				{configurationAcknowledgmentFileDialog.open && (
					<PanelNoteFiles
						title={'Acuse de recibo'}
						message={'Cargar archivo pdf, imágenes de acuses de recibo'}
						text={'Seleccionar pdf de acuses'}
						open={configurationAcknowledgmentFileDialog.open}
						onClose={onConfigurationAcknowledgmentFileDialog}
						attachments={
							consignmentNote &&
							consignmentNote.acknowledgmentsReceiptFilesVersion ===
								AcknowledgmentsReceiptFilesVersions.V1
								? acknowledgments
								: configurationAcknowledgmentFileDialog.attachments
						}
						onUploadFiles={onSaveAttahcments}
						isacknowledgments={true}
						deliveryCode={configurationAcknowledgmentFileDialog.deliveryCode}
						isSaving={isSaving}
					/>
				)}
			</div>
		)
	} else {
		return 'Sin destinatarios agregados'
	}
}

TabPanelComponent.propTypes = {
	activeTab: propTypes.any.isRequired,
	deliveries: propTypes.array,
	consignmentNote: propTypes.object,
	onUploadAcknowledgmentReceipts: propTypes.func,
	downloadingAcknowledgmentReceipts: propTypes.bool,
	onDialogCreateDevolution: propTypes.func,
	canBeAdd: propTypes.bool,
	canbeUpdatePlant: propTypes.bool,
}

export default TabPanelComponent
