/**Import react section */
import React from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
/**Import MaterialUi section */
import {
    Grid, Typography, Card, CardHeader
} from '@material-ui/core'
/**Import store section */
import {
    COLOR_STATUS_ALERT_INFO
} from '../../../store/helpers/StatusColorConstants'
import {
    stripHtml
} from '../../../store/helpers/BiddingHelper'
const CommentsComponent = (props) => {
    /**props section */
    const { comments, classes, title, isVisibleHtmlEditor
    } = props

    return (
        < Grid container >
            <Grid item xs={12}>
                <Card
                    elevation={4}
                    className={classes.card}
                >
                    <CardHeader
                        title={<Typography variant="subtitle1" align="justify" color="primary" className={classes.fontWeight}>{title}</Typography>}
                        subheader={isVisibleHtmlEditor ?
                            stripHtml(comments ? comments : "")
                            :
                            <Typography variant="subtitle2" align="justify">{comments}</Typography>

                        }
                    />


                </Card>
            </Grid>


        </Grid>
    )
}

const styles = theme => ({
    card: {
        backgroundColor: COLOR_STATUS_ALERT_INFO
    },
    fontWeight: {
        fontWeight: "bold"
    }
});
export default withRouter(withStyles(styles, { withTheme: true })(CommentsComponent))