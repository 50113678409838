import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER, COLOR_STATUS_WARNING } from '../helpers/StatusColorConstants'

export const descriptionStatus = (status) => {
	switch (status) {
		case 1: //AddedPayment
			return 'Borrador'
		case 300: //IsValidated
			return 'Abierto'
		case 4: //IsRejected
			return 'Cancelado'
		case 5: //modifiedBySupplier
			return 'Completado'
		default:
			return ''
	}
}

export const backgroundColorTableStatus = (status) => {
	switch (Number(status)) {
		case 1:
			return 'secondary'
		case 300:
			return 'warning'
		case 4:
			return 'danger'
		case 5:
			return 'success'
		default:
			return ''
	}
}

export const getColorStatusFolios = (status) => {
	switch (Number(status)) {
		case 300:
			return COLOR_STATUS_WARNING
		case 4:
			return COLOR_STATUS_DANGER
		case 5:
			return COLOR_STATUS_SUCCESS
		default:
			return null
	}
}
