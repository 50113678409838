/** react imports section */
import React, { useEffect } from 'react'
import { withRouter, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'

/** Material-UI imports section */
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import GuidelinesHeader from './components/GuidelinesHeader'
import GuidelinesTable from './components/GuidelinesTable'
/** Import actions section */
import { loadGuidelines } from '../../../store/specifications/specifications.Action'
import * as selector from '../../../store/specifications/specifications.Selector'

const GuidelinesIndex = () => {
	let history = useHistory()
	let location = useLocation()
	const dispatch = useDispatch()

	const queryParams = queryString.parse(location.search)

	/** Get selectors Information*/
	const isLoading = useSelector((state) => selector.getLoadingSelector(state))
	const page = useSelector((state) => selector.getGuideLinesPageSelector(state))
	const pageSize = useSelector((state) => selector.getGuideLinesPageSizeSelector(state))
	const guidelineList = useSelector((state) => selector.getGuidelinesSelector(state))
	const totalItems = useSelector((state) => selector.getGuideLinesTotalItemsSelector(state))
	/**On change search value */
	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	useEffect(() => {
		let queryValue = queryParams.query ? queryParams.query : ''
		let pageValue = queryParams.pageNumber > 0 ? queryParams.pageNumber : 0
		if (queryValue) pageValue = 0
		if (queryParams.rowsPerPage !== '20') pageValue = 0
		dispatch(loadGuidelines(queryValue, parseInt(pageValue), pageSize))
	}, [dispatch, location.search])

	return (
		<Card>
			<CardContent>
				<GuidelinesHeader onChangeSearchValue={onChangeSearchValue} queryParams={queryParams} />
				<GuidelinesTable
					isLoading={isLoading}
					guidelinesData={guidelineList}
					totalItems={totalItems}
					pageNumber={page > 0 ? page - 1 : 0}
					rowsPerPage={pageSize}
				/>
			</CardContent>
		</Card>
	)
}

export default withauthenticatedLayout(withRouter(GuidelinesIndex))
