import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import Toaster from './Toaster'
import { loadCompany } from '../../store/companies/CompanyActions'
import Select from 'react-select'

/**
 * Warehouse Selector component
 *
 * @class WarehouseSelector
 * @extends {Component}
 */
class WarehouseSelector extends Component {
	/**
	 *Creates an instance of WarehouseSelector.
	 * @param {*} props
	 * @memberof WarehouseSelector
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			isSearching: false,
			selectedWarehouse: '',
			options: [],
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		this.setState({ isSearching: true })
		this.props.loadCompany(this.props.companyToken).then(() => {
			var options = this.props.warehouses
				? this.props.warehouses.map((warehouse) => {
						return {
							value: warehouse.name, //Should save name like value
							label: warehouse.name,
						}
				  })
				: []
			this.setState({ options, isSearching: false })
		})
	}

	/**
	 * React life-cycle
	 * select default option if prop.value is found in list
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof WarehouseSelector
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.props.warehouses !== prevProps.warehouses && this.props.warehouses !== null) {
			let selectedWarehouse = this.state.options.find((warehouse) => {
				return warehouse.value === this.props.value
			})
			if (selectedWarehouse) {
				this.setState({ selectedWarehouse })
			}
		}
	}

	/**
	 * On select a warehouse
	 *
	 * @param {*} option
	 * @memberof WarehouseSelector
	 */
	onSelectedWarehouseClick(option) {
		if (this.state.options) {
			let selectedWarehouse = this.state.options.find((warehouse) => {
				return warehouse.value === option.value
			})
			if (selectedWarehouse && this.props.onWarehouseSelected) {
				this.setState({ selectedWarehouse })
				this.props.onWarehouseSelected(selectedWarehouse)
			} else {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			}
		}
	}

	/**
	 * Method to determine place holder message
	 *
	 * @returns
	 * @memberof WarehouseSelector
	 */
	getPlaceholder() {
		let id = 'common.selectWarehouses'
		if (this.state.isSearching || this.props.isLoadingCompany) id = 'common.loadingPleaseWait'
		return <Translate id={id} />
	}

	render() {
		if (!this.props.isDisabled) {
			return (
				<React.Fragment>
					<Select
						placeholder={this.getPlaceholder()}
						value={this.state.selectedWarehouse}
						options={this.state.options}
						isDisabled={this.props.isDisabled}
						onChange={this.onSelectedWarehouseClick}
						styles={{
							menu: (base) => ({ ...base, zIndex: 10 }),
						}}
					/>
					<Toaster
						message={this.state.toasterMessage}
						open={this.state.showToaster}
						variant={this.state.toasterVariant}
						onClose={() => {
							this.setState({ showToaster: false })
						}}
					/>
				</React.Fragment>
			)
		} else {
			if (this.state.selectedWarehouse && this.state.selectedWarehouse.label !== '') {
				return this.state.selectedWarehouse.label
			}
			return <Translate id='common.withoutSelectWarehouse' />
		}
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		isLoadingCompany: state.companies.get('isLoadingCompany'),
		warehouses: state.companies.get('company') ? state.companies.get('company').get('warehouses').toJS() : null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadCompany: (companyToken) => {
			return dispatch(loadCompany(companyToken))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseSelector)
