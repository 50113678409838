/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'

import { underScoreToTitleCase } from '../helpers/StringHelper'

export const CHANGE_PROSPECTS_SORT_CRITERIA = 'CHANGE_PROSPECTS_SORT_CRITERIA'
export const CHANGE_PROSPECTS_SORT_QUERY = 'CHANGE_PROSPECTS_SORT_QUERY'
export const CHANGE_PROSPECTS_PAGINATION = 'CHANGE_PROSPECTS_PAGINATION'

export const START_LOADING_PROSPECTS = 'START_LOADING_PROSPECTS'
export const SET_PROSPECTS_LIST = 'SET_PROSPECTS_LIST'
export const SET_PROSPECTS_COUNT = 'SET_PROSPECTS_COUNT'
export const IS_SAVING_PROSPECT = 'IS_SAVING_PROSPECT'
export const IS_LOADING_PROSPECT = 'IS_LOADING_PROSPECT'
export const SET_PROSPECT = 'SET_PROSPECT'
export const SET_PROSPECTHISTORY = 'SET_PROSPECTHISTORY'

export const UPDATE_FILE_PROSPECT = 'UPDATE_FILE_PROSPECT'
export const UPDATE_FILE_SEN_PROSPECT = 'UPDATE_FILE_SEN_PROSPECT'
export const ADD_FILE_BY_PROSPECT = 'ADD_FILE_BY_PROSPECT'
export const REMOVE_FILE_BY_PROSPECT = 'REMOVE_FILE_BY_PROSPECT'
export const REMOVE_FILE_SEND_PROSPECT = 'REMOVE_FILE_SEND_PROSPECT'
export const REMOVE_DOCUMENT_FROM_PROSPECT = 'REMOVING_DOCUMENT_FROM_PROSPECT'

/**
 * Action start loading prospects
 * @param {*} isLoading
 */
function isLoadingProspects(isLoading) {
	return {
		type: START_LOADING_PROSPECTS,
		isLoading: isLoading,
	}
}

/**
 * Action to set prospects list
 * @param {*} prospects
 */
function setProspectsList(prospects) {
	return {
		type: SET_PROSPECTS_LIST,
		prospects,
	}
}

/**
 * Action to set prospects count
 * @param {*} count
 */
function setpProspectsCount(count) {
	return {
		type: SET_PROSPECTS_COUNT,
		count,
	}
}

/**
 * Action to start load contract
 */
function isSavingProspecct(isSaving) {
	return {
		type: IS_SAVING_PROSPECT,
		isSaving: isSaving,
	}
}

function isLoadingProspect(isLoading) {
	return {
		type: IS_LOADING_PROSPECT,
		isLoading,
	}
}

function setProspect(prospect) {
	return {
		type: SET_PROSPECT,
		prospect,
	}
}

function setProspectHistory(prospectHistory) {
	return {
		type: SET_PROSPECTHISTORY,
		prospectHistory,
	}
}
/**
 * Add file to state
 *
 * @param {*} files
 * @returns
 */
function updateFileProspect(fileProspect) {
	return {
		type: UPDATE_FILE_PROSPECT,
		fileProspect,
	}
}
function updateFileSenProspect(fileProspect) {
	return {
		type: UPDATE_FILE_SEN_PROSPECT,
		fileProspect,
	}
}

/**
 * Add file to state
 *
 * @param {*} files
 * @returns
 */
function addFile(files) {
	return {
		type: ADD_FILE_BY_PROSPECT,
		files,
	}
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName) {
	return {
		type: REMOVE_FILE_BY_PROSPECT,
		fileName,
	}
}
function removeFileSendProspect(fileName) {
	return {
		type: REMOVE_FILE_SEND_PROSPECT,
		fileName,
	}
}
function removeDocumentFromProspect(itemToken) {
	return {
		type: REMOVE_DOCUMENT_FROM_PROSPECT,
		itemToken,
	}
}
/**
 * Action load prospects from company backend
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @param {*} page
 * @param {*} rowsPerPage
 */
export function loadProspectsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingProspects(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospects/all`
			let data = {
				CompanyToken: selectedCompany.get('token'),
				SortBy: orderBy,
				SortAscending: sortAscending,
				Query: query,
				Page: `${page + 1}`,
				RowsPerPage: rowsPerPage,
			}
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setProspectsList(serverResponse.data))
					}
				})
				.catch((error) => {
					dispatch(isLoadingProspects(false))
					console.error('Error getting prospects list by company', error.response)
				})
		} else {
			dispatch(isLoadingProspects(false))
		}
	}
}
/**
 * Action to get supplier prospects count
 */
export function getProspectsCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().prospects ? getState().prospects.get('prospectsCount') : null
		if (actualCounter) {
			return
		}
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospects/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setpProspectsCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error getting the prospects count', error.response)
			})
	}
}

/**
 * Action to create Prospect in company
 * @param {*} company
 */
export function createProspectData(prospect) {
	return function (dispatch, getState) {
		dispatch(isSavingProspecct(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospects`
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let formData = new FormData()
		prospect.attachments.forEach((file) => {
			formData.append('file', file)
		})
		formData.set('CompanyToken', companyToken)
		formData.set('ProspectName', prospect.prospectName)
		formData.set('ContactName', prospect.contactName)
		formData.set('ContactEmail', prospect.contactEmail)
		formData.set('Notes', prospect.notes)
		formData.set('CreatorName', user.profile.name)
		formData.set('CreatorEmail', user.profile.email)
		formData.set('CreatedUserName', user.profile.preferred_username)
		formData.set('ProspectType', prospect.prospectType.value)
		if(prospect.centerType === "")
			formData.set('CenterType', "")
		else {
			if(prospect.centerType.value !== '0')
				formData.set('CenterType', prospect.centerType.label)
			else
				formData.set('CenterType', "")	
		}

		return axios
			.post(endPoint, formData, configuration)
			.then(() => {
				dispatch(isSavingProspecct(false))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to create prospect in company', error.response)
				dispatch(isSavingProspecct(false))
				return Promise.reject()
			})
	}
}

/**
 * Get and set the prospect in the estate from read from backend
 * @param {*} token
 */
export function loadProspect(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingProspect(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProspect(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load prospect', error.response)
				dispatch(isLoadingProspect(false))
				return Promise.reject()
			})
	}
}

/**
 * Method to update rmi document information basic
 */
export function updateCommentsData(token, observations) {
	return function (dispatch, getState) {
		let configuration = {
			headers: {
				Accept: 'application/json',
			},
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}/comments`
		let data = {
			Observations: observations,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					// Update observations
				}
			})
			.catch((error) => {
				console.error('Error to load observations', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to send request to client
 * @param {*} token
 */
export function sendRequestByProspect(token) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}/send`
		let data = {}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProspect(serverResponse.data))
					dispatch(isSavingProspecct(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error to send request by prospect', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to accepted request
 * @param {*} token
 */
export function acceptedByClient(token, comments) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let username = getState().oidc.user.profile.name
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}/accepted/${username}`
		let data = {
			Comments: comments,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProspect(serverResponse.data))
					dispatch(isSavingProspecct(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error to accepted request', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to processToSap request
 * @param {*} token
 */
export function processToSapByClient(token) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let username = getState().oidc.user.profile.name
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}/processToSap/${username}`
		let data = {}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProspect(serverResponse.data))
					dispatch(isSavingProspecct(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error to accepted request', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to rejected request
 * @param {*} token
 * @param {*} comments
 */
export function rejectedByClient(token, comments) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let username = getState().oidc.user.profile.name
		let data = {
			Comments: comments,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}/rejected/${username}`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProspect(serverResponse.data))
					dispatch(isSavingProspecct(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error to rejected request', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update status file
 * @param {*} token
 * @param {*} disposition
 */
export function updateStatusFileInPlant(token, status) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			FileStatus: status,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/file/${token}`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateFileProspect(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in update status file ', error.response)
				return Promise.reject()
			})
	}
}

export function removeDocumentByProspect(prospectToken, itemToken) {
	return function (dispatch) {
		let configuration = {
			headers: {
				Accept: 'application/json',
			},
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${prospectToken}/files/${itemToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeDocumentFromProspect(itemToken))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) by prospect', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to upload files to supplier from expedient file
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadDocumentsProspect(fileToken, files) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${fileToken}/upload`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateFileProspect(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload files from supplier file(s)', error.response)
				return Promise.reject()
			})
	}
}

export function uploadDocumentsSendProspect(fileToken, files) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${fileToken}/sendProspect`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateFileSenProspect(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload files from supplier file(s)', error.response)
				return Promise.reject()
			})
	}
}
export function removeFilesSendProspect(fileName) {
	return function (dispatch, getState) {
		let tokenProspect = getState().prospects.get('prospect').get('token')
		let configuration = {
			headers: {
				Accept: 'application/json',
			},
			data: { name: fileName },
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${tokenProspect}/sendProspect`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFileSendProspect(fileName))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) by prspect', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update property files
 * @param {*} fileToken
 * @param {*} property
 * @param {*} propertyData
 */
export function updatePropertyProspect(fileToken, property, propertyData) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/file/${fileToken}`
		let data = {
			[underScoreToTitleCase(property)]: propertyData,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateFileProspect(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to update property', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to rejected request
 * @param {*} token
 * @param {*} comments
 */
export function canceledByClient(token, comments) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let username = getState().oidc.user.profile.name
		let data = {
			Comments: comments,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}/canceled/${username}`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProspect(serverResponse.data))
					dispatch(isSavingProspecct(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error to canceled request', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to rejected request
 * @param {*} token
 * @param {*} comments
 */
export function sendDocumentsToProspect(token, comments) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let userName = getState().oidc.user.profile.name
		let configuration = {
			headers: {
				Accept: 'application/json',
			},
		}
		let data = {
			Comments: comments,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}/send/documents/${userName}`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProspect(serverResponse.data))
					dispatch(isSavingProspecct(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error to send documents to prospect request', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update files by prospect
 * @param {*} files
 */
export function uploadFilesEvidence(files) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let configuration = {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			},
		}
		let tokenProspect = getState().prospects.get('prospect').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${tokenProspect}/files`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse.data))
					dispatch(isSavingProspecct(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error to upload evidence file(s) by prospect', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} quoteToken
 * @param {*} fileName
 * @returns
 */
export function removeFilesEvidence(fileName) {
	return function (dispatch, getState) {
		let tokenProspect = getState().prospects.get('prospect').get('token')
		let configuration = {
			headers: {
				Accept: 'application/json',
			},
			data: { name: fileName },
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${tokenProspect}/files`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFile(fileName))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) by prspect', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to rejected request
 * @param {*} token
 * @param {*} comments
 */
export function sendEmailReminderProspect(token, comments) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let data = {
			Comments: comments,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}/sendreminder`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProspect(serverResponse.data))
					dispatch(isSavingProspecct(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error to send documents to prospect request', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove prospect request
 * @param {*} token
 */
export function removeProspectByClient(token) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}/delete`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingProspecct(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error to remove request', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Get and set the prospect in the estate from read from backend
 * @param {*} token
 */
export function loadProspectHistory(token) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/prospectHistory/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProspectHistory(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load prospect', error.response)
				dispatch(isLoadingProspect(false))
				return Promise.reject()
			})
	}
}

export function updateProspect(token, value, name) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let data = {
			Token: token,
			Name: name,
			ValueProspect: value,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}/updateProspect`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProspect(serverResponse.data))
					dispatch(isSavingProspecct(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error to rejected request', error.response)
				return Promise.reject(error.response)
			})
	}
}
export function registration(token, comments, emails) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let data = {
			comments: comments,
			emails: emails,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${token}/registration`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProspect(serverResponse.data))
					dispatch(isSavingProspecct(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error to rejected request', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 *Action to download report general of prospect by range date
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadProspectsReportByRangeDate(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(isSavingProspecct(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/downloadReportGeenralByRangeDate`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingProspecct(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_general.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false))
				console.error('Error downloading general report by month', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update status document
 * @param {*} expedientToken 
 * @param {*} documentToken 
 * @returns 
 */
export function saveDocumentToAddProspect(expedientToken, documentToken) {
	return function (dispatch, getState) {
		dispatch(isSavingProspecct(true));
		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/senddocument`;
		let command = {
			ExpedientToken: expedientToken,
			DocumentToken: documentToken
		}
		return axios.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingProspecct(false));
					dispatch(setProspect(serverResponse.data));
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingProspecct(false));
				console.error("Error, enter valid token ", error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}