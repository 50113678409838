/** React imports section */
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

/** Material UI imports section */
import { Button, withStyles } from '@material-ui/core'
import PlaceIcon from '@material-ui/icons/PlaceTwoTone'

/** common imports section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import LoadTypeSelector from '../../common/LoadTypeSelector'

/** store imports section */
import {
	addDestinations,
	loadAllTransportZone,
	loadClientsCatalog,
	updateDestinations,
} from '../../../../store/transportTender/transportTenderAction'
import { getTransportTenderfilterSelector } from '../../../../store/transportTender/transportTenderSelector'

import CreateManualDeals from './CreateManualDeals'

const DealsCreatePanel = (props) => {
	const { classes, onClose, transportTenderId, isEdit, deal, setToasterMessageConfig } = props
	const dispatch = useDispatch()

	const [changeSelect, setChangeSelect] = useState({ value: 0, label: 'Manual' })
	const [isLoading, setIsLoading] = useState(null)

	const [information, setInformation] = useState({
		client: null,
		zone: null,
		requiresManeuvering: false,
		street: '',
		externalNumber: '',
		internalnumber: '',
		suburb: '',
		countryCode: '',
		town: '',
		state: '',
		country: '',
		salesManager: '',
		folioLetter: '',
		isFolioSit: false,
		requiresBoardingAppointment: false,
		boardingAppointmentDate: null,
		boardingAppointmentTime: null,
		boardingAppointmentFolio: '',
		SelectManeuverType: null,
	})

	const transportTenderinfo = useSelector((state) => getTransportTenderfilterSelector(state))

	const onSubmit = () => {
		if (isEdit) {
			dispatch(updateDestinations(information, deal.Id, transportTenderId))
				.then(() => {
					setIsLoading(true)
				})
				.catch((err) => {
					setToasterMessageConfig({
						showToaster: true,
						toasterMessage: 'Error al intentar actualizar el reparto',
						toasterVariant: 'error',
					})
				})
				.finally(() => {
					onClose()
					setIsLoading(false)
				})
		} else {
			dispatch(addDestinations(information, transportTenderId))
				.then(() => {
					''
				})
				.catch((err) => {
					setToasterMessageConfig({
						showToaster: true,
						toasterMessage: 'Error al guardar el reparto',
						toasterVariant: 'error',
					})
				})
				.finally(() => {
					onClose()
				})
		}
	}

	const canBeSaved = () => {
		if ((changeSelect && changeSelect.value === 0) || isEdit) {
			if (information.client === null) return true
			if (information.zone === null) return true
			if (!information.isFolioSit && information.folioLetter === '') return true

			// if (information.salesManager === '') return true
			if (
				information.requiresBoardingAppointment &&
				(information.boardingAppointmentDate === null || information.boardingAppointmentTime === null)
			)
				return true
			return false
		} else return true
	}
	useEffect(() => {
		dispatch(loadClientsCatalog())
		dispatch(loadAllTransportZone())
	}, [dispatch])
	return (
		<LateralPanel onClose={onClose}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id='carrierTenders.deals.dealsTitle' />}
				onCloseButtonClick={onClose}
				icon={<PlaceIcon color='secondary' />}
			/>
			<div className={classes.bodyContainer}>
				{!isEdit ? (
					<LoadTypeSelector
						setChange={setChangeSelect}
						classes={classes}
						value={changeSelect}
						disabled={true}
					/>
				) : (
					''
				)}
				{((changeSelect && changeSelect.value === 0) || isEdit) && (
					<CreateManualDeals
						classes={classes}
						deal={deal}
						information={information}
						setInformation={setInformation}
						clientsCatalog={transportTenderinfo.clientsCatalog ? transportTenderinfo.clientsCatalog : []}
						transportZone={transportTenderinfo.transportZone ? transportTenderinfo.transportZone : []}
					/>
				)}
			</div>
			<Button
				data-tut='reactour-save'
				data-cy='btn-save'
				type='button'
				color='primary'
				variant='outlined'
				className={classes.btnCreate}
				onClick={onSubmit}
				disabled={canBeSaved() || isLoading}
			>
				{deal ? (
					<Translate id='carrierTenders.deals.editCast' />
				) : (
					<Translate id='carrierTenders.deals.addCast' />
				)}
			</Button>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	bodyContainer: {
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
	},

	titleSelect: {
		marginTop: '7px',
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	selectStyles: {
		marginTop: '10px',
	},
	createManualStyles: {
		marginTop: '25px',
	},
	requiresManeuvering: {
		marginTop: '25px',
		display: 'flex',
		alignItems: 'flex-start',
	},
	checkbox: {
		marginTop: '-15px',
	},
	addressContainer: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridGap: '5px  20px',
	},
	gridColumn1: {
		gridColumnStart: '1/2',
	},
	gridColumn3: {
		gridColumnStart: '3/4',
	},
	manoeuvringContainer: {
		backgroundColor: '#F9F9F9',
		display: 'grid',
		gridTemplateColumns: '15px 1fr',
		alignItems: 'center',
		margin: '10px 0px -5px',
		gridGap: '20px',
		width: '100%',
	},
	divColor: {
		backgroundColor: theme.palette.secondary.main,
		height: '100%',
		borderRadius: '6px',
		margin: '3px 0px 3px',
	},
	manoeuvringTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	datecontainer: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridGap: '0px  20px',
	},
	datePicker: {
		marginTop: '10px',
	},
	baseRate: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '5px',
	},
})

DealsCreatePanel.propTypes = {
	transportTenderId: PropTypes.string.isRequired,
	isEdit: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	setToasterMessageConfig: PropTypes.func.isRequired,
	deal: PropTypes.object,
}

DealsCreatePanel.defaultProps = {
	transportTenderId: '',
	isEdit: false,
	onClose: () => console.warn('[onClose] not defined'),
	setToasterMessageConfig: () => console.warn('[setToasterMessageConfig] not defined'),
	deal: null,
}

export default withStyles(styles, { withTheme: true })(DealsCreatePanel)
