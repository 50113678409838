import React, { useEffect } from 'react'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Card, Divider, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import FloatingButton from '../common/FloatingButton'
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow'
import { Translate } from 'react-localize-redux'
import TabsInShow from './components/TabsInShow'
import { useDispatch, useSelector } from 'react-redux'
import { getExportRequestByToken, uploadFilesExportRequestItem, deleteFilesFromItem, onSynchronizeSit } from '../../store/exportRequest/ExportRequestAction'
import dataDetail from './dataDetail'
import { ExportRequestItemType } from '../../store/helpers/ExportRequestHelper'

const Show = (props) => {
	const dispatch = useDispatch()
	const userProfile = useSelector((state) => state.oidc.user)
	// this is the token from the url
	const { token } = props.match.params
	useEffect(() => {
		dispatch(getExportRequestByToken(token))
	}, [dispatch, token])

	
	const onUploadFileInItem = (item,files, folderName) => {
		dispatch(uploadFilesExportRequestItem(token, item.token,folderName,files));
	}


	const onDeleteFilesFromItem = (item, folderName, fileName) => {
		dispatch(deleteFilesFromItem(token, item.token,folderName,fileName));
	}

	const onSynchronizeSitInformation = () => {
		dispatch(onSynchronizeSit(token));
	}

	

	const { classes } = props
	const optonsAvalinable = [{ options: 'Option1' }]

	const exportRequest = useSelector((state) => state.exportRequest.get('exportRequestItem').toJS())
	const isSavingFile = useSelector((state) => state.exportRequest.get('isSavingFile'));
		
	const nationalProviderData =
		exportRequest && exportRequest.ExportRequestItems
			? exportRequest.ExportRequestItems.find((x) => x.ExportRequestType === ExportRequestItemType.NationalProvider)
			: null

	const mxProviderData =
		exportRequest && exportRequest.ExportRequestItems
			? exportRequest.ExportRequestItems.find((x) => x.ExportRequestType === ExportRequestItemType.MxProvider)
			: null


	const foreignTransportProviderData =
		exportRequest && exportRequest.ExportRequestItems
			? exportRequest.ExportRequestItems.find((x) => x.ExportRequestType === ExportRequestItemType.ForeignTransportProvider)
			: null

	const foreignProviderData =
		exportRequest && exportRequest.ExportRequestItems
			? exportRequest.ExportRequestItems.find((x) => x.ExportRequestType === ExportRequestItemType.UsaProvider)
			: null
	
	// let canbeUpdateSupplierInternational = canBeEditSupplierInformation(
	// 	exportRequest,
	// 	userRolesByCompany,
	// 	userProfile,
	// 	transportationSupplierNationalData
	// )

	return (
		<Card style={{ width: '100%' }}>
			{optonsAvalinable.length !== 0 && <FloatingButton open={true} />}
			<LayoutHeaderWorkFlow
				{...exportRequest}
				workFlow={{
					folio: exportRequest.SITFolio,
					origin: 'SIT',
					creation_date_long: exportRequest.creation_date,
					create_by: '-',
					full_supplier: '-',
					class_status: '-',
					actual_operation_description: 'Borrador',
				}}
				company={{
					logo: '',
					name: 'IMBERA SA DE CV',
					rfc: 'VME 840820 RT9',
					zip_code: 76803,
					address: 'Carr. México - Tequisquiapan Km 3.2 Zona Ind. Valle de Oro San Juan del Río, Querétaro',
				}}
				title={'Solicitud de exportación'}
				additionalData={exportRequest.CreationDate}
			/>
			<Divider />

			{/*  */}
			<Paper className={classes.root}>
				<Table className={classes.table}>
					<TableBody>
						<TableRow key={'indexFolio'}>
							<TableCell component='th' scope='row' className={classes.tableCell}>
								<Typography>
									<strong>
										<Translate id='common.folioSIT' />
									</strong>
								</Typography>
							</TableCell>
							<TableCell align='right' className={classes.tableCell}>
								{exportRequest.SITFolio}
							</TableCell>

							<TableCell align='right' className={classes.tableCell}>
								<Typography>
									<strong>
										<Translate id='common.transportType' />
									</strong>
								</Typography>
							</TableCell>
							<TableCell align='right' className={classes.tableCell}>
								{exportRequest.TransportType}
							</TableCell>
						</TableRow>

						<TableRow key={'indexType'}>
							<TableCell component='th' scope='row' className={classes.tableCell}>
								<Typography>
									<strong>
										<Translate id='common.orderType' />
									</strong>
								</Typography>
							</TableCell>
							<TableCell align='right' className={classes.tableCell}>
								{exportRequest.OrderType}
							</TableCell>

							<TableCell align='right' className={classes.tableCell}>
								<Typography>
									<strong>
										<Translate id='common.incoterm' />
									</strong>
								</Typography>
							</TableCell>
							<TableCell align='right' className={classes.tableCell}>
								{exportRequest.Incoterm}
							</TableCell>

							<TableCell align='right' className={classes.tableCell}>
								<Typography>
									<strong>
										<Translate id='common.incotermPlace' />
									</strong>
								</Typography>
							</TableCell>
							<TableCell align='right' className={classes.tableCell}>
								{exportRequest.IncotermPlace}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
			{/*  */}
			<div style={{ marginTop: '2rem' }}>
				<TabsInShow
					userProfile={userProfile}
					dataDetail={dataDetail}
					exportRequestItems={exportRequest.ExportRequestItems}
					//object props section
					nationalProviderData={nationalProviderData}
					mxProviderData={mxProviderData}
					foreignTransportProviderData={foreignTransportProviderData}
					usaForeignProviderData={foreignProviderData}
					onUploadFileInItem={onUploadFileInItem}
					onDeleteFilesFromItem={onDeleteFilesFromItem}
					onSynchronizeSit={onSynchronizeSitInformation}
					isSavingFile={isSavingFile}
				/>
			</div>
		</Card>
	)
}
const styles = (theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
	table: {
		width: '100%',
		tableLayout: 'fixed',
	},
	tableCell: {
		textAlign: 'left',
		wordWrap: 'break-word',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
