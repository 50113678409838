/** Import ReactJS */
import React from 'react';
import uuidv4 from 'uuid/v4'
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";

/** Material UI import section */
import {
    Avatar,
    Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
/**Import section components */
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
/**Import resources section */
import {
    COLOR_STATUS_BLUE, 
    COLOR_STATUS_LIGHT
} from '../../../store/helpers/StatusColorConstants'
/** Resources import section */
const useStyle = ((theme) => ({
    avatarResponsible: {
        height: "35px",
        width: "35px",
        '&:hover': {
            opacity:.5, 
            cursor:"pointer"
        },
        backgroundColor:COLOR_STATUS_LIGHT
    },
    avatarUserLogged: {
        height: "35px",
        width: "35px",
        '&:hover': {
            opacity:.5, 
        },
        backgroundColor:COLOR_STATUS_BLUE
    },
    tooltip: {
        position: "relative",
        display: "inlineBlock"
    },
}))


const AvatarContainer = props => {
    const {classes, responsible, userProfile, canBeRemoveResponsible, isVisibleTooltip}= props

    const getAvatarUrl = (avatarUrl) => {
        if (!IsNullOrEmpty(avatarUrl)) {
            let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${avatarUrl}`;
            let logoUrl = urlImage + "?token=" + uuidv4();
            return logoUrl;
        }
        else
            return null
    }
    let isDisabled = (userProfile && (userProfile.username ===responsible.user_name))
    return (
        <Tooltip
            title={!!isVisibleTooltip? `${responsible.user_name} | ${responsible.name}`: "-"}
        >
            <Avatar
                onClick={(event) => !isDisabled ? (canBeRemoveResponsible ? props.onRemoveResponsible(responsible): null) :null}
                src={getAvatarUrl(responsible.avatar_url)}
                className={!isDisabled ?classes.avatarResponsible : classes.avatarUserLogged}
                disabled={isDisabled||props.isLoading}
                color='primary'
            >
                <span styles={{ fontSize: 40 }}>{responsible.name.charAt(0).toUpperCase()}</span>
            </Avatar>
        </Tooltip>
    )
}

AvatarContainer.propTypes = {
    responsiblesAdded: PropTypes.array,
    onDeleteResponsible: PropTypes.func,
    onSelectedAvatarResponsible: PropTypes.func,
}

AvatarContainer.defaultProps = {
    responsiblesAdded: []
}
export default withRouter(withStyles(useStyle, { withTheme: true }) (AvatarContainer))