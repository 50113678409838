/** Import ReactJS */
import React from 'react'
import { withRouter } from 'react-router-dom'
/** Material UI import section */
import { withStyles } from '@material-ui/core/styles'
/**Import section components */

import { Divider, List, ListItem, ListItemText, TextField, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import ListDocumentsComponent from './ListDocumentsComponent'
import SelectSupplierComponent from './SelectSupplierComponent'
/** Resources import section */
const useStyle = (theme) => ({})

const PreviousOriginComponent = (props) => {
	//function props section
	const {
		updateLoadAlertItemProperty,
		onSendNotificationToSupplier,
		onLateralPanelConfig,
		deleteInvoiceFiles,
		deleteAnnexesFiles,
		deletePreviousFiles,
		deleteInspectionFiles,
	} = props
	//object props section
	const { previousOriginData } = props
	//tab props section
	const { tabSelected } = props
	//bool props section
	const { canBeEditData, canBeEditInternalUserData, isSaving} = props

	const onUpdatePropertyCommon = (property, value) => {
		let objectProperties = {
			token: previousOriginData.token,
		}
		objectProperties[property] = value
		updateLoadAlertItemProperty(objectProperties)
	}

	return (
		<>
			{tabSelected === '3' && (
				<List>
					<SelectSupplierComponent
						title={<Translate id='loadAlerts.preoriginSupplier' />}
						updateLoadAlertItemProperty={updateLoadAlertItemProperty}
						item={previousOriginData}
						onSendNotificationToSupplier={onSendNotificationToSupplier}
						//bool props section
						canBeEditInternalUserData={canBeEditInternalUserData}
					/>
					<ListItem>
						<ListItemText
							secondary={
								<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
									<Typography
										component='span'
										variant='body2'
										color='textPrimary'
										style={{ whiteSpace: 'nowrap' }}
									>
										<strong>
											{' '}
											<Translate id='loadAlerts.originPriceSupplier' />:
										</strong>
									</Typography>
									{/* {previousOriginData && previousOriginData.originPriceSupplier} */}
									{/* {previousOriginData && IsNullOrEmpty(previousOriginData.originPriceSupplier) && ( */}
									{previousOriginData && (
										<TextField
											fullWidth
											id='outlined-size-small'
											label={''}
											// disabled={roles.canBeModuleLoadAlert(userRolesByCompany)}
											disabled={!canBeEditData}
											onBlur={(event) => {
												onUpdatePropertyCommon('OriginPriceSupplier', event.target.value)
											}}
											variant='outlined'
											InputProps={{
												style: {
													padding: '5px',
													height: '32px',
												},
												startAdornment: '$',
											}}
											defaultValue={previousOriginData.originPriceSupplier}
										/>
									)}
								</div>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							secondary={
								<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
									<Typography
										component='div'
										variant='body2'
										color='textPrimary'
										style={{ whiteSpace: 'nowrap' }}
									>
										<strong>
											{' '}
											<Translate id='loadAlerts.comments' />:
										</strong>
									</Typography>
									{/* {previousOriginData && previousOriginData.comments} */}
									{/* {previousOriginData && IsNullOrEmpty(previousOriginData.comments) && ( */}
									{previousOriginData && (
										<TextField
											fullWidth
											id='outlined-size-small'
											label={''}
											onBlur={(event) => {
												onUpdatePropertyCommon('Comments', event.target.value)
											}}
											disabled={!canBeEditData}
											variant='outlined'
											InputProps={{
												style: {
													padding: '5px',
													height: '32px',
												},
											}}
											defaultValue={previousOriginData.comments}
										/>
									)}
								</div>
							}
						/>
					</ListItem>
					<Divider />
					<ListDocumentsComponent
						panel='AddPreviousDocument'
						title='loadAlerts.previousDocument'
						documents={previousOriginData.previousDocument}
						onLateralPanelConfig={onLateralPanelConfig}
						item={previousOriginData}
						deleteFiles={deletePreviousFiles}
						canBeEditData={canBeEditData}
						isSaving={isSaving}
					/>
					<ListDocumentsComponent
						panel='AddInvoiceDocuments'
						title='loadAlerts.invoice'
						documents={previousOriginData.invoiceDocument}
						onLateralPanelConfig={onLateralPanelConfig}
						item={previousOriginData}
						deleteFiles={deleteInvoiceFiles}
						canBeEditData={canBeEditData}
						isSaving={isSaving}
					/>
					<ListDocumentsComponent
						panel='AddAnnexesDocumentes'
						title='loadAlerts.annexes'
						documents={previousOriginData.annexesDocument}
						onLateralPanelConfig={onLateralPanelConfig}
						item={previousOriginData}
						deleteFiles={deleteAnnexesFiles}
						canBeEditData={canBeEditData}
						isSaving={isSaving}
					/>
					<ListDocumentsComponent
						panel='AddInspectionDocument'
						title='loadAlerts.inspectionDocument'
						documents={previousOriginData.inspectionDocument}
						onLateralPanelConfig={onLateralPanelConfig}
						deleteFiles={deleteInspectionFiles}
						item={previousOriginData}
						canBeEditData={canBeEditData}
						isSaving={isSaving}
					/>
				</List>
			)}
		</>
	)
}

PreviousOriginComponent.propTypes = {}

PreviousOriginComponent.defaultProps = {}
export default withRouter(withStyles(useStyle, { withTheme: true })(PreviousOriginComponent))
