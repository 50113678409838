/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'

/**Import MaterialUi section */
import {
    Button, Grid, Typography
} from '@material-ui/core'
/**Import components section */
import Evidence from '../../../common/Evidence'
/**Import store section */

const AddBillingFile = (props) => {
    const { onSaveButtonClick, classes
    } = props

    const isSaving = useSelector((state) => state.billings.get('isSaving'))

    const [attachments, setAttachments] = useState([])

    //const onDelete = (attachment) => {
      //  if (props.onDeleteAttachment) {
        //    props.onDeleteAttachment(attachment)
        //}
    //}


    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (attachments.length === 0) return false
        return true;
    }

    const uploadFiles = (files) => {
        let filesList = attachments.concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
        }
        setAttachments(filesList)
        return Promise.resolve('Ok')
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(attachments)
        }
    }


    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
     * @memberof ModalAddMasivaMaterials
     */
    const removeFiles = (fileName) => {
        let newList = attachments.filter(files => {
            return files.name !== fileName
        })
        setAttachments(newList)
        return Promise.resolve('Ok')
    }



    return (
        <div>
            <div className={classes.containerInformation}>
                <Grid container >
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography color='primary'><Translate id="common.selectfiles" /></Typography>
                        <Evidence
                            text={<Translate id="common.selectfiles" />}
                            uploadFiles={uploadFiles}
                            files={attachments}
                            removeFiles={removeFiles}
                            acceptedFiles={['XLSX']}
                            maxFiles={1}
                        />
                    </Grid>
                </Grid>

            </div>

            <Grid container>
                <Grid item xs={12} className={classes.containerBtn}>
                    <Button
                        data-cy="btn-save"
                        type="button"
                        color="primary"
                        variant='outlined'
                        onClick={onSave}
                        className={classes.widthBtnSaved}
                        disabled={!canBeSaved() || isSaving}>
                        {<Translate id="common.save" />}
                    </Button>
                </Grid>
            </Grid>
        </div>

    )

}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${165}px)`,
        minHeight: `calc(100vh - ${165}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    marginTop: {
        marginTop: "10px",
    },
    marginLeft: {
        marginLeft: "6px",
    },
    widthBtnSaved: {
        minWidth: "400px"
    }
});

AddBillingFile.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddBillingFile.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddBillingFile))