import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

/** Import component section */
import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';
import Evidence from '../../../common/Evidence';

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';
import { CONTACTS_TYPE, MATERIALS_TYPE } from '../../../../store/helpers/ServicesListTypes';

/**
 * Class compoenent to add a service/project
 *
 * @class AddModal
 * @extends {Component}
 */
class AddModal extends Component {

    /**
     *Creates an instance of DialogActions.
     * @param {*} props
     * @memberof DialogActions
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = this.initialState();
    }

    /**
     * Return initial state
     *
     * @returns
     * @memberof AddModal
     */
    initialState() {
        return ({
            name: null,
            email: null,
            phone_number: '',
            phone_extension: '',
            description: null,
            attachments: [],
            filesSecurity: [],
        })
    }

    /**
     * Handle update properties to set data in local state
     *
     * @param {*} property
     * @param {*} data
     * @memberof AddModal
     */
    updateProperty(property, data) {
        this.setState({
            [property]: data,
        })
    }

    /**
     * Function to trigger blur event
     *
     * @param {*} event
     * @memberof AddModal
     */
    onKeyDown(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    /**
     * Handle event upload files of devidence componet to add files to list
     *
     * @param {*} files
     * @param {*} key
     * @returns
     * @memberof AddModal
     */
    uploadFiles(files, key) {
        let filesList = this.state[key].concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
        }

        this.setState({
            [key]: filesList
        })
        return Promise.resolve('Ok')
    }

    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @param {*} key
     * @returns
     * @memberof AddModal
     */
    removeFiles(fileName, key) {
        let newList = this.state[key].filter(files => {
            return files.name !== fileName
        })
        this.setState({
            [key]: newList
        })
        return Promise.resolve('Ok')
    }


    /**
     * method to call action and add item
     *
     * @memberof AddModal
     */
    onAddItem() {
        this.props.addItem(this.state)
            .then((response) => {
                if (response === 'Ok')
                    this.handleCloseCancel()
            })
    }

    /**
     * Handle  Close or cancel event
     *
     * @memberof AddModal
     */
    handleCloseCancel() {
        this.setState(this.initialState(),
            () => this.props.onClose());
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof AddModal
     */
    isValidToSave() {
        if (this.props.type === CONTACTS_TYPE)
            if ((this.state.name && !IsNullOrEmpty(this.state.name))
                && (this.state.phone_extension && !IsNullOrEmpty(this.state.phone_extension))
            ) {
                return true;
            }
        if (this.props.type === MATERIALS_TYPE)
            if ((this.state.name && !IsNullOrEmpty(this.state.name))
                && (this.state.description && !IsNullOrEmpty(this.state.description))
            ) {
                return true;
            }
        return false
    }

    /**
     * Method to render text field 
     *
     * @param {*} textFieldId
     * @param {*} textFieldTranslateId
     * @returns
     * @memberof AddModal
     */
    renderTextArea(textFieldId, textFieldTranslateId, isMultiline = false, isRequired = true) {
        return (
            <TextField
                id={textFieldId}
                label={<Translate id={textFieldTranslateId}/>}
                value={this.state[textFieldId] ? this.state[textFieldId] : ''}
                margin="normal"
                type={"text"}
                fullWidth
                required={isRequired}
                onBlur={(event) => this.updateProperty(textFieldId, event.target.value)}
                onChange={(event) => this.updateProperty(textFieldId, event.target.value)}
                onKeyDown={!isMultiline && this.onKeyDown}
                multiline={isMultiline}
                variant={isMultiline ? 'outlined' : 'standard'}
                rowsMax={4}
            />
        )
    }

    /**
     * Render Evidence component
     *
     * @param {*} key
     * @param {*} translationId
     * @param {number} [xs=12]
     * @returns
     * @memberof AddModal
     */
    renderEvidence(key, translationId, xs = 12) {
        return (
            <Grid item xs={xs} className={this.props.classes.checkBox}>
                <Typography variant="subtitle2" color={'textSecondary'}>
                    <Translate id={translationId} />
                </Typography>
                <Evidence
                    baseApi={process.env.REACT_APP_SIP_URL_FILES}
                    removeFiles={(fileName) => this.removeFiles(fileName, key)}
                    uploadFiles={(files) => this.uploadFiles(files, key)}
                    files={this.state[key] ? this.state[key] : []}
                    disabledLink={!this.props.item}
                    text={this.props.dropzoneText}
                />
            </Grid>
        )
    }

    render() {
        const { classes } = this.props;
        let contactMode = this.props.type === CONTACTS_TYPE;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id={this.props.title} />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <div>
                        {!this.isValidToSave() &&
                            <Typography variant="caption" color='error'>
                                {<Translate id="common.requiredFields" />}
                            </Typography>
                        }
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => this.handleCloseCancel()}
                            onClose={() => this.handleCloseCancel()}
                            onSaveItem={this.onAddItem}
                            isSaving={this.props.isSaving}
                            canBeSaved={this.isValidToSave()}
                        />
                    </div>
                }>

                <Grid container className={classes.modal} justify='space-between'>
                    {this.renderTextArea('name', contactMode?'contractorsModule.contacts.name': 'contractorsModule.materials.name', false, true)}
                    {contactMode && this.renderTextArea('email', 'contractorsModule.contacts.email', false, false)}
                    {contactMode && this.renderTextArea('phone_number', 'contractorsModule.contacts.phone', false, false)}
                    {contactMode && this.renderTextArea('phone_extension', 'contractorsModule.contacts.extension', false, true)}
                    {!contactMode && this.renderTextArea('description', 'contractorsModule.materials.description', false, true)}
                    {!contactMode && this.renderEvidence("attachments", "contractorsModule.materials.attachments")}
                    {!contactMode && this.props.canBeFileSecurity && this.renderEvidence("filesSecurity", "contractorsModule.materials.fileSecurity")}
                </Grid>
            </Dialog>
        )
    }
}

const styles = theme => ({
    modal: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxWidth: 500
    }
});

export default withRouter(withStyles(styles, { withTheme: true })(AddModal));