import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

/** Import component section */
import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';
import Select from 'react-select'

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { getSelectedValue } from '../../../../store/helpers/SelectOptions';
import { isNullOrUndefined } from 'util';

/**
 * Class compoenent to add a service/project
 *
 * @class AddMaterialModal
 * @extends {Component}
 */
class AddMaterialModal extends Component {

    /**
     *Creates an instance of DialogActions.
     * @param {*} props
     * @memberof DialogActions
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = this.initialState();
    }

    /**
     * Return initial state
     *
     * @returns
     * @memberof AddMaterialModal
     */
    initialState(keepAdding = false, isClose = false) {
        return ({
            part_number_code: this.props.item ? (isClose ? null : this.props.item.part_number_code) : null,
            purchase_order: this.props.item ? (isClose ? null : this.props.item.purchase_order) : null,
            position: this.props.item ? (isClose ? null : this.props.item.position) : null,
            revision_confirmation: this.props.item ? (isClose ? null : this.props.item.revision_confirmation) : null,
            quantity_of_material: this.props.item ? (isClose ? null : this.props.item.quantity_of_material) : null,
            lot: this.props.item ? (isClose ? null : this.props.item.lot) : null,
            expected_quantity_today: this.props.item ? (isClose ? null : this.props.item.expected_quantity_today) : null,
            revision: this.props.item ? (isClose ? null : this.props.item.revision) : null,
            invoice_or_remission: this.props.item ? (isClose ? null : this.props.item.invoice_or_remission) : null,
            keepAdding
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.item !== this.props.item && !isNullOrUndefined(this.props.item)) {
            this.setState(this.initialState(),
                () => {
                    if (this.state.part_number_code)
                        this.props.getPurchaseOrders(this.state.part_number_code)
                    if (this.state.purchase_order)
                        this.props.getPositions(this.state.part_number_code, this.state.purchase_order)

                });
        }
    }


    /**
     * Handle state checked option
     *
     * @memberof AddMaterialModal
     */
    handleCheck() {
        this.setState({
            keepAdding: !this.state.keepAdding
        })
    }

    /**
     * Handle update properties to set data in local state
     *
     * @param {*} property
     * @param {*} data
     * @memberof AddMaterialModal
     */
    updateProperty(property, data) {
        this.setState({
            [property]: data,
        },
            () => {
                if (property === "part_number_code") {
                    this.setState({
                        purchase_order: null,
                        position: null
                    },
                        () => this.props.getPurchaseOrders(data));
                }
                if (property === "purchase_order") {
                    this.setState({
                        position: null
                    },
                        () => this.props.getPositions(this.state.part_number_code, data));
                }
            });
    }

    /**
     * Function to trigger blur event
     *
     * @param {*} event
     * @memberof AddMaterialModal
     */
    onKeyDown(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }


    /**
     * method to call action and add item
     *
     * @memberof AddMaterialModal
     */
    onAddItem() {
        this.props.addItem(this.state)
            .then((response) => {
                if (response === 'Ok' && !this.state.keepAdding) {
                    this.handleCloseCancel()
                }
                else {
                    this.setState(this.initialState(true));
                }
            })
    }

    /**
     * Handle  Close or cancel event
     *
     * @memberof AddMaterialModal
     */
    handleCloseCancel() {
        this.setState(this.initialState(false, true),
            () => this.props.onClose());
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof AddMaterialModal
     */
    isValidToSave() {
        if (!IsNullOrEmpty(this.state.part_number_code)
            && !IsNullOrEmpty(this.state.purchase_order)
            && !IsNullOrEmpty(this.state.position)
            && !IsNullOrEmpty(this.state.revision_confirmation)
            && !IsNullOrEmpty(this.state.quantity_of_material)
            && Number(this.state.quantity_of_material) > 0
        )
            return true;
        return false
    }

    formatSelectValues(optionsList, valueProperty, nameProperty) {
        return optionsList.map(item => {
            if (!IsNullOrEmpty(valueProperty) && !IsNullOrEmpty(nameProperty))
                return { value: item[valueProperty], label: item[nameProperty] }
            return { value: String(item), label: String(item) }
        })
    }

    /**
     * Method to render text field 
     *
     * @param {*} textFieldId
     * @param {*} textFieldTranslateId
     * @param {boolean} [isRequired=true]
     * @returns
     * @memberof AddMaterialModal
     */
    renderTextField(textFieldId, textFieldTranslateId, isRequired = true, type = "text", showHelper = false) {
        let helperValue = "";
        let helperText = "";
        if (showHelper) {
            helperValue = this.state[textFieldId === "revision_confirmation" ? "revision" : "expected_quantity_today"];
            helperText = <Translate id={textFieldId === "revision_confirmation" ? "deliveryOrderModule.requestedRevision" : "deliveryOrderModule.error.quantityCantExceedsPurchaseOrder"} />
        }

        return (
            <TextField
                id={textFieldId}
                label={<Translate id={textFieldTranslateId} />}
                value={this.state[textFieldId] ? this.state[textFieldId] : ''}
                margin="dense"
                type={type}
                fullWidth
                required={isRequired}
                onBlur={(event) => this.updateProperty(textFieldId, event.target.value)}
                onChange={(event) => this.updateProperty(textFieldId, event.target.value)}
                onKeyDown={this.onKeyDown}
                helperText={
                    (this.props.item && showHelper) &&
                    <React.Fragment>
                        {helperText}{`: ${helperValue}`}
                    </React.Fragment>
                }
            />
        )
    }

    /**
    * Render Select component
    *
    * @param {*} key
    * @param {*} translationId
    * @param {*} options
    * @returns
    * @memberof AddActionToListDialog
    */
    renderSelect(key, translationId, options, valueProperty, nameProperty) {
        options = this.formatSelectValues(options, valueProperty, nameProperty)
        return (
            <Grid item xs={12} className={this.props.classes.textField}>
                <Typography variant="caption" color={'textSecondary'}><Translate id={translationId} />*</Typography>
                <Select
                    value={getSelectedValue(options, String(this.state[key]))}
                    options={options}
                    onChange={(event) => this.updateProperty(key, event.value)}
                    menuPortalTarget={document.parentElement}
                />
            </Grid>
        )
    }


    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id={this.props.title} />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <Grid container spacing={0}>
                        {!this.isValidToSave() &&
                            <Grid item xs={12} className={this.props.classes.checkBox}>
                                <Typography variant="caption" color='error'>
                                    {<Translate id="common.requiredFields" />}
                                </Typography>
                            </Grid>
                        }
                        {!this.props.item &&
                            <Grid item xs={12} className={this.props.classes.checkBox}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.keepAdding}
                                            onChange={() => this.handleCheck()}
                                            value="checked"
                                            color="primary"
                                        />
                                    }
                                    label={<Translate id="deliveryOrderModule.keepAddingMaterial" />}
                                />
                            </Grid>}
                        <Grid item xs={12}>
                            <DialogActions
                                view={SHOW_EDIT_VIEW}
                                classes={classes}
                                onCancel={() => this.handleCloseCancel()}
                                onClose={() => this.handleCloseCancel()}
                                onSaveItem={this.onAddItem}
                                isSaving={this.props.isSaving}
                                canBeSaved={this.isValidToSave()}
                            />
                        </Grid>
                    </Grid>

                                }>

                <Grid container className={classes.modal} justify='space-between'>
                    {this.renderSelect('part_number_code', 'deliveryOrderModule.materialsTable.code', this.props.codes, "Code", "Description")}
                    {this.renderSelect('purchase_order', 'deliveryOrderModule.materialsTable.purchaseOrder', this.props.purchaseOrders, "", "")}
                    {this.renderSelect('position', 'deliveryOrderModule.materialsTable.position', this.props.positions, "", "")}
                    {this.renderTextField('revision_confirmation', 'deliveryOrderModule.materialsTable.version', true, "number", true)}
                    {this.renderTextField('quantity_of_material', 'deliveryOrderModule.materialsTable.quantity', true, "number", true)}
                    {this.renderTextField('invoice_or_remission', 'deliveryOrderModule.materialsTable.invoice', false, "text", false)}
                    {this.renderTextField('lot', 'deliveryOrderModule.materialsTable.lot', false)}
                </Grid>
            </Dialog>
        )
    }
}

const styles = theme => ({
    modal: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxWidth: 500
    },
    textField: {
        marginBottom: theme.spacing.unit * 2
    },
    checkBox: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxHeight: '25px'
    }
});

export default withRouter(withStyles(styles, { withTheme: true })(AddMaterialModal));