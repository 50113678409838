import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import moment from 'moment';
import 'moment/locale/es';
import uuidv4 from 'uuid/v4'

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import LockIcon from '@material-ui/icons/LockTwoTone';
import UnLockIcon from '@material-ui/icons/LockOpen';
import EmailIcon from '@material-ui/icons/Email';
import EnabledIcon from '@material-ui/icons/ThumbUp';
import DisabledIcon from '@material-ui/icons/ThumbDown';
import PersonIcon from '@material-ui/icons/Person';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import RemoveIcon from '@material-ui/icons/Delete';

import NoticesOfChangeIcon from '@material-ui/icons/SwapHorizTwoTone';
import QuotesIcon from '@material-ui/icons/AttachMoneyTwoTone';
import SamplesIcon from '@material-ui/icons/UnarchiveTwoTone';
import SACRequestIcon from '@material-ui/icons/BugReportTwoTone';

import RawMaterialNonConfIcon from '@material-ui/icons/BugReport';
import QualityFailureReportIcon from '@material-ui/icons/WarningOutlined';
import ReworkIcon from '@material-ui/icons/AutorenewOutlined';
import DevolutionIcon from '@material-ui/icons/ReplyAllOutlined';
import SacsQualityIcon from '@material-ui/icons/Report';
import WarrantyQualityIcon from '@material-ui/icons/VerticalSplit';

import QuestionnaireIcon from '@material-ui/icons/NaturePeople';
import GuaranteeReportIcon from '@material-ui/icons/FileCopyOutlined';

import PurchaseIcon from '@material-ui/icons/CalendarTodayOutlined';
import ShippingIcon from '@material-ui/icons/ShoppingCart';
import FreightIcon from '@material-ui/icons/LocalShipping';
import MaquilaIcon from '@material-ui/icons/Description';
import FindInPage from '@material-ui/icons/FindInPage';

import BiddingIcon from '@material-ui/icons/ViewCarousel';
import RateReviewTwoToneIcon from '@material-ui/icons/RateReviewTwoTone';
import ExpedientsIcon from '@material-ui/icons/FormatListNumbered';

import PendingTasksIcon from '@material-ui/icons/AssignmentTwoTone';
/** Import components section */

/** Import actions nd helpers section  */
import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER } from '../../../store/helpers/StatusColorConstants';

/**
 * User card component
 *
 * @class UserCard
 * @extends {Component}
 */
class UserCard extends Component {

    /**
  * get icon to show in tacks
  */
    getDocumentIcon(workflow_type) {
        switch (workflow_type) {
            case "QuoteSamples":
                return <QuotesIcon color="primary" />
            case "SampleRequest":
                return <SamplesIcon color="primary" />
            case "NoticeOfChange":
                return <NoticesOfChangeIcon color="primary" />
            case "CorrectiveActionDevelopment":
                return <SACRequestIcon color="primary" />
            case "RawMaterialNonConformity":
                return <RawMaterialNonConfIcon color="primary" />
            case "FailureReport":
                return <QualityFailureReportIcon color="primary" />
            case "Rework":
                return <ReworkIcon color="primary" />
            case "CorrectiveAction":
                return <SacsQualityIcon color="primary" />
            case "Devolution":
                return <DevolutionIcon color="primary" />
            case "WarrantyReportsPtm":
                return <WarrantyQualityIcon color="primary" />
            case "DeliverOrder":
                return <PurchaseIcon color="primary" />
            case "ShippingOrder":
                return <ShippingIcon color="primary" />
            case "Freight":
                return <FreightIcon color="primary" />
            case "Sustainability":
                return <QuestionnaireIcon color="primary" />
            case "Guarantee":
                return <GuaranteeReportIcon color="primary" />
            case "QuestionnairePTM":
                return <QuestionnaireIcon color="primary" />
            case "MaquilaOrder":
                return <MaquilaIcon color="primary" />
            case "Bidding":
                return <BiddingIcon color="primary" />
            case "RatingIndirect":
                return <RateReviewTwoToneIcon color="primary" />
            case "ExpedientSupplier":
                return <ExpedientsIcon color="primary" />
            case "AuditQuality":
                return <FindInPage color="primary" />
            default:
                return <PendingTasksIcon color="primary" />;
        }
    }

    /**
   * Get roles 
   *
   * @returns
   * @memberof CompaniesCard
   */
    getTasksList() {
        const { notifications, isLoadingNotifications } = this.props;
        if (isLoadingNotifications === true)
            return (<Typography>{<Translate id="common.loadingNotifcaitons" />}</Typography>)
        else if (notifications !== null && notifications.length !== 0) {
            return (
                <List dense >
                    <ListItem divider>
                        <ListItemText
                            primary={<span>{notifications.length} {" Tareas pendientes"}</span>}
                        />
                    </ListItem>
                    {(
                        notifications.map((notification) => {
                            moment.locale('es');
                            moment().utc();
                            let dateTime = moment(notification.workflow_date_time, "DD-MM-YYYY hh:mm:ss am pm").from();
                            return (
                                <ListItem key={uuidv4()} dense button divider
                                    onClick={() => { this.props.history.push(`/${notification.workflow_url_document}/${notification.workflow_token}`) }}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="overline" color="primary" gutterBottom>{this.getDocumentIcon(notification.workflow_type)}
                                                <strong> {notification.workflow_folio}</strong></Typography>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <span className="text-muted"> {dateTime}</span>
                                        {(notification.workflow_type === "ShippingOrder" || notification.workflow_type === "Freight"
                                            || notification.workflow_type === "DeliverOrder" || notification.workflow_type === "MaquilaOrder"
                                            || notification.workflow_type === "QuoteSamples" || notification.workflow_type === "SampleRequest"
                                            || notification.workflow_type === "NoticeOfChange" || notification.workflow_type === "CorrectiveActionDevelopment") &&
                                            <Tooltip title={"Eliminar"}>
                                                <RemoveIcon color="secondary" style={{ cursor: "pointer" }}
                                                    onClick={() => { this.props.onRemoveNotificationOpen(notification.workflow_token, notification.workflow_type) }} />
                                            </Tooltip>
                                        }
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })
                    )}
                </List>
            )
        }
    }



    render() {
        const { internalUser, classes } = this.props;
        return (
            <Paper>
                <Card className={classes.card}>
                    <CardHeader
                        avatar={<Tooltip title={<Translate id={internalUser.enabled ? "users.enabled" : "users.disabled"} />}>
                            <Avatar className={classes.userAvatar}
                                style={{
                                    backgroundColor: internalUser.enabled ?
                                        COLOR_STATUS_SUCCESS :
                                        COLOR_STATUS_DANGER
                                }} >
                                <PersonIcon />
                            </Avatar>
                        </Tooltip>
                        }
                        title={internalUser.full_name}
                        subheader={internalUser.user_name}
                    />
                    <CardContent>
                        <List>
                            <ListItem divider>
                                <ListItemIcon>
                                    <EmailIcon color="secondary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Translate id="users.email" />}
                                    secondary={internalUser.email} />
                            </ListItem>
                            <ListItem divider>
                                <ListItemIcon>
                                    {internalUser.enabled ?
                                        <EnabledIcon color="secondary" /> :
                                        <DisabledIcon color="error" />
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Translate id="users.userEnable" />}
                                    secondary={<Translate id={internalUser.enabled ? "users.enabled" : "users.disabled"} />}
                                />
                            </ListItem>
                            <Divider />
                            {internalUser.id !== this.props.currentUser.profile.sub &&
                                <React.Fragment>
                                    <ListItem button
                                        onClick={internalUser.enabled ? this.props.onEnableUser : this.props.onUnLockedUser}>
                                        <ListItemIcon>
                                            {internalUser.enabled ?
                                                <LockIcon color="primary" /> :
                                                <UnLockIcon />
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary={
                                            <Typography variant="subtitle1" gutterBottom>
                                                <Translate id={internalUser.enabled ? "users.lock" : "users.unlockUser"} />
                                            </Typography>
                                        }
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            }
                        </List>
                    </CardContent>
                    <CardContent style={{ height: '50vh', overflow: 'auto' }}>
                        {this.getTasksList()}
                    </CardContent>
                </Card>
            </Paper>
        );
    }
}

export default UserCard;