import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

import SampleIcon from '@material-ui/icons/LocalShipping'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import QuotesIcon from '@material-ui/icons/AttachMoneyTwoTone'
import {
	Grid,
	TextField,
	Tooltip,
	Typography,
	List,
	ListItem,
	ListItemText,
	IconButton,
	ListItemSecondaryAction,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Switch,
} from '@material-ui/core'

import PlotSelector from '../../common/PlotSelector'
import SpectSelector from '../../common/SpectSelector'
import SuppliersSelector from '../../common/SupplierSelector'
import SupplierByCompnaySelector from '../../common/SupplierByCompnaySelector'

/**
 * NoticeItemDialog item lateral options panels
 *
 * @class NoticeItemDialog
 * @extends {Component}
 */
class NoticeItemDialog extends Component {
	/**
	 *Creates an instance of NoticeItemDialog.
	 * @param {*} props
	 * @memberof NoticeItemDialog
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			comments: this.props.item ? this.props.item.comments : null,
			requiered_plot_art: this.props.item ? this.props.item.requiered_plot_art : null,
		}
	}

	/**
	 * React life-cycle method
	 * Update status if props has change
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof NoticeItemDialog
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.props.item !== prevProps.item) {
			this.setState({ comments: this.props.item.comments })
		}
	}

	/**
	 * on remove notification to supplier
	 *
	 * @param {*} item
	 * @param {*} quote
	 * @memberof NoticeItemDialog
	 */
	onRemoveNotificationSupplierClick(item, quote) {
		if (this.props.onRemoveNoticeSupplier) {
			this.props.onRemoveNoticeSupplier(item, quote.supplier_token)
		}
	}

	/**
	 * On add sample request to supplier
	 */
	onAddSupplierSelectedClick(item, quote) {
		if (this.props.onSupplierSelected) {
			this.props.onSupplierSelected(item, quote)
		}
	}

	/**
	 * On remove supplier
	 *
	 * @memberof NoticeItemDialog
	 */
	onRemoveSupplierClick(item, quote) {
		if (this.props.onRemoveSupplier) {
			this.props.onRemoveSupplier(item, quote.supplier_token)
		}
	}

	/**
	 * On add sample request to supplier
	 */
	onAddSampleRequestSupplierClick(item, quote) {
		if (this.props.onAddSampleRequestSupplier) {
			this.props.onAddSampleRequestSupplier(item, quote)
		}
	}

	/**
	 * On remove sample request to supplier
	 */
	onRemoveSampleRequestSupplierClick(item, quote) {
		if (this.props.onRemoveSampleRequestSupplier) {
			this.props.onRemoveSampleRequestSupplier(item, quote.supplier_token)
		}
	}

	/**
	 * Update property in state
	 *
	 * @param {*} property
	 * @param {*} value
	 * @memberof NoticeItemDialog
	 */
	updateProperty(property, value) {
		this.setState({
			[property]: value,
		})
		if (property === 'requiered_plot_art') {
			if (this.props.onAddComment) this.props.onAddComment(this.props.item, this.state.comments, value)
		}
	}

	/**
	 * on Add comment to item
	 *
	 * @param {*} item
	 * @memberof NoticeItemDialog
	 */
	onAddComment(item) {
		if (this.props.onAddComment) this.props.onAddComment(item, this.state.comments, this.state.requiered_plot_art)
	}

	/**
	 *
	 *
	 * @memberof NoticeItemDialog
	 */
	getSelectedSuppliers() {
		const { classes, item, companyImbera } = this.props
		return item.quotes.map((quote) => {
			return (
				<ListItem key={quote.quote_token}>
					<ListItemText
						primary={
							<React.Fragment>
								{quote.supplier_code} | {quote.supplier_name}{' '}
							</React.Fragment>
						}
					/>
					<ListItemSecondaryAction>
						<React.Fragment>
							<Tooltip
								title={
									<Translate
										id={
											quote.is_quote_requiered
												? 'noticeChanges.removeRequestQuoteTooltipMessage'
												: 'noticeChanges.requestRequestTooltipMessage'
										}
									/>
								}
							>
								<IconButton
									color={quote.is_quote_requiered ? 'primary' : 'inherit'}
									onClick={() => {
										quote.is_quote_requiered
											? this.onRemoveSupplierClick(item, quote)
											: this.onAddSupplierSelectedClick(item, quote)
									}}
								>
									<QuotesIcon />
								</IconButton>
							</Tooltip>
							{quote.is_quote_requiered && !companyImbera && (
								<Tooltip
									title={
										<Translate
											id={
												quote.is_sample_requiered
													? 'noticeChanges.removeRequestSampleTooltipMessage'
													: 'noticeChanges.requestSampleTooltipMessage'
											}
										/>
									}
								>
									<IconButton
										color={quote.is_sample_requiered ? 'primary' : 'inherit'}
										onClick={() => {
											quote.is_sample_requiered
												? this.onRemoveSampleRequestSupplierClick(item, quote)
												: this.onAddSampleRequestSupplierClick(item, quote)
										}}
									>
										<SampleIcon />
									</IconButton>
								</Tooltip>
							)}
							{quote.is_draft && (
								<Tooltip title={<Translate id='noticeChanges.removeSupplierRequestTooltipMessage' />}>
									<IconButton
										className={classes.deleteButton}
										onClick={() => {
											this.onRemoveNotificationSupplierClick(item, quote)
										}}
									>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							)}
						</React.Fragment>
					</ListItemSecondaryAction>
				</ListItem>
			)
		})
	}

	/**
	 *
	 *
	 * @returns
	 * @memberof NoticeItemDialog
	 */
	render() {
		const { item, companyImbera, classes } = this.props
		return (
			<Dialog open={this.props.show}>
				<DialogTitle id='form-dialog-title'>
					<Translate id='deliveryOrderModule.materialsTable.plot' />: {item.part_number} Rev. {item.review}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<Typography>
								<Translate id='plotSelector.addPlots' />
							</Typography>
							<PlotSelector
								specPlano={item.plano_number}
								revision={item.review}
								selectedPlotName={item.plot_name}
								onPlotSelected={(plotSelectd) => {
									this.props.onPlotSelected(item, plotSelectd)
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography>
								<Translate id='plotSelector.addSpects' />
							</Typography>
							<SpectSelector
								specPlano={item.plano_number}
								revision={item.review}
								selectedPlotName={item.plot_name}
								onPlotSelected={(plotSelectd) => {
									this.props.onPlotSelected(item, plotSelectd)
								}}
							/>
						</Grid>
						{companyImbera && (
							<Grid item xs={12} className={classes.itemsDetail}>
								<Typography color='primary'>
									<Translate id='plotSelector.addPlotArt' />
								</Typography>
								<Switch
									checked={this.state.requiered_plot_art}
									onChange={(event) => {
										this.updateProperty('requiered_plot_art', event.target.checked)
									}}
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<Typography>
								<Translate id='plotSelector.addComments' />
							</Typography>
							<TextField
								key={'comments'}
								fullWidth
								variant='outlined'
								multiline
								rowsMax='4'
								value={this.state['comments'] ? this.state['comments'] : ''}
								onChange={(event) => {
									this.updateProperty('comments', event.target.value)
								}}
								onBlur={() => this.onAddComment(item)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption' gutterBottom>
								<Translate id='memos.addSupplierToMemoMessage' />
							</Typography>
							{!companyImbera && (
								<SuppliersSelector
									onSupplierSelected={(supplier) => {
										this.props.onSupplierNoticeSelected(item, supplier)
									}}
								/>
							)}
							{companyImbera && (
								<SupplierByCompnaySelector
									onSupplierSelected={(supplier) => {
										this.props.onSupplierNoticeSelected(item, supplier)
									}}
								/>
							)}
							<List dense style={{ maxHeight: '20vh', overflowY: 'auto' }}>
								{this.getSelectedSuppliers()}
							</List>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.onClose} color='primary'>
						<Translate id='common.close' />
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = (theme) => ({
	deleteButton: {
		color: 'red',
	},
	itemsDetail: {
		display: 'flex',
		alignItems: 'center',
	},
})

export default withStyles(styles, { withTheme: true })(NoticeItemDialog)
