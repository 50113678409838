import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
/**Import material UI Section*/
import {
    withStyles, ExpansionPanel, ExpansionPanelSummary, Typography,ExpansionPanelDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';

/**Import components section*/
import PopperListsComponent from '../PopperListsComponent'
import CommentsComponent from '../CommentsComponent'
import { canBeEditBiddingInStatusDraft } from '../../../../store/helpers/BiddingHelper'

/**Import resources section*/
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { IsCurrentActiveDirectoryUser } from '../../../../store/helpers/SessionHelper';

const ServicesTabs = (props) => {
    /**String props section */
    const { classes, index } = props;
    // /**Bools props section */
    const { isSaving } = props
    // /**Arrays props section */
    const { userProfileLogged, userRolesByCompany, itemProposal } = props
    // /**Functions props section */
    const { onDeleteServiceOrMaterial
    } = props

    /**Objects props section */
    const { bidding, } = props

    let canBeEditedInformation = canBeEditBiddingInStatusDraft(bidding, userRolesByCompany, userProfileLogged ? userProfileLogged.profile : null)
    let isUserActiveDirectory = IsCurrentActiveDirectoryUser(userProfileLogged);
    const [expanded, setExpanded] = React.useState(index === 0 ? itemProposal.Id : false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    let buttonsForMoreCard = []
    if (canBeEditedInformation && isUserActiveDirectory) {
        buttonsForMoreCard.push({
            title: <Translate id="common.delete" />,
            icon: <DeleteIcon />,
            onClick: (event) => { onDeleteItem(event, itemProposal.Id) },
            isIconMaterial: true,
            disabled: (isSaving || !canBeEditedInformation)
        })
    }


    const onDeleteItem = (event, id) => {
        if (props.onDeleteServiceOrMaterial) {
            onDeleteServiceOrMaterial(event, id)
        }
    }


    const onGetDescription = () => {
        let description
        if (!IsNullOrEmpty(itemProposal.Code) && itemProposal.Code) {
            description = `${itemProposal.Code}`
        }
        if (!IsNullOrEmpty(itemProposal.Name) && itemProposal.Code) {
            description = description.concat(` | ${itemProposal.Name}`)
        }
        if (!IsNullOrEmpty(itemProposal.Name) && IsNullOrEmpty(itemProposal.Code)) {
            description = (`${itemProposal.Name}`)
        }
        return description

    }

    const renderContainer = () => {
        return (
            <ExpansionPanel key={itemProposal.Id} expanded={expanded === itemProposal.Id} onChange={handleChange(itemProposal.Id)}>
                <ExpansionPanelSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                    <Typography color="primary">{onGetDescription()}</Typography>
                    <div className={classes.flexGrow} />
                    {
                        canBeEditedInformation && isUserActiveDirectory &&
                        <div className={classes.buttonsAcordionSummary}>
                            <PopperListsComponent
                                title={<Translate id="common.options" />}
                                listItems={buttonsForMoreCard}
                            />
                        </div>
                    }
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div style={{ flexBasis: '100%' }}>
                        <CommentsComponent
                            title="Comentario"
                            comments={itemProposal.Notes}
                        />
                    </div>
                </ExpansionPanelDetails>

            </ExpansionPanel>
        )
    }

    /**
     * Render componet 
     */
    return (renderContainer())
}

const styles = (theme) => ({
    tab: {
        minHeight: "auto",
    },
    tabs: {
        display: "flex",
        justifyContent: 'space-between',
    },
    flexGrow: {
        flexGrow: 1
    },
    accordionSummary: {
        paddingRight: "0px !important",
        display: "flex",
        backgroundColor: "#f8f9fa"

    },
    buttonsAcordionSummary: {
        display: "flex",
        justifyItems: "flex-end",
        marginRight: "20px"
    }
});

ServicesTabs.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
}

ServicesTabs.defaultProps = {
    isLoading: false,
}

export default withRouter(
    withStyles(styles, { withTheme: true })(ServicesTabs)
);
