import React, { Component } from 'react'
import autoBind from 'auto-bind'
import moment from 'moment'
/** Translations imports section */
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import EthicCodeIcon from '@material-ui/icons/ListAlt'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DateTimePicker from '../../../common/DateTimePicker/DatePicker'
import Evidence from '../../../common/Evidence'

class BaseInfoEthicCode extends Component {
	/**
	 *Creates an instance of BaseInfoEthicCode.
	 * @param {*} props
	 * @param {*} context
	 * @memberof BaseInfoEthicCode
	 */
	constructor(props, context) {
		super(props, context)
		autoBind(this)
	}

	render() {
		const { ethicCode, classes } = this.props
		return (
			<Card className={classes.card} style={{ height: '79vh' }}>
				<CardHeader
					avatar={
						<Avatar className={classes.userAvatar}>
							<EthicCodeIcon />
						</Avatar>
					}
					title={<Translate id='surveys.addSurvey' />}
				/>
				<CardContent style={{ height: '60vh', overflowY: 'auto' }}>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								id='name'
								label={<Translate id='surveys.name' />}
								className={classes.textAreaField}
								value={ethicCode.name}
								onChange={(event) => {
									this.props.updateProperty('name', event.target.value)
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										onChange={(event) => {
											this.props.updateProperty('acceptedBySupplier', event.target.checked)
										}}
										color='primary'
										value='status'
									/>
								}
								label={<Translate id='surveys.setToAcceptBySupplier' />}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography>{<Translate id='officialNotices.activationDate' />}</Typography>
							<DateTimePicker
								onChangeRange={(deliveryDate) => {
									this.props.updateProperty('activationDate', deliveryDate)
								}}
								minDate={moment()}
								startDate={ethicCode.activationDate ? ethicCode.activationDate : null}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography>{<Translate id='officialNotices.expirationDate' />}</Typography>
							<DateTimePicker
								onChangeRange={(deliveryDate) => {
									this.props.updateProperty('expirationDate', deliveryDate)
								}}
								minDate={moment()}
								startDate={ethicCode.expirationDate ? ethicCode.expirationDate : null}

								// startDate={this.state.delivery_date}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle2' color={'textSecondary'}>
								<Translate id={'surveys.messageToAddFile'} />
							</Typography>
							<Evidence
								text={this.props.dropzoneText}
								baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
								removeFiles={this.props.removeFiles}
								uploadFiles={this.props.uploadFiles}
								files={ethicCode.attachments}
								disabledLink
							/>
						</Grid>
					</Grid>
				</CardContent>

				<CardActions className={classes.actions}>
					<Grid item xs={12}>
						<Toolbar>
							<Button
								variant='outlined'
								color='secondary'
								className={classes.button}
								onClick={this.props.onCreate}
								disabled={!this.props.canBeSaved || this.props.isSaving}
							>
								<Translate id={this.props.isSaving ? 'common.saving' : 'common.save'} />
							</Button>
							<Button
								variant='outlined'
								color='primary'
								className={classes.button}
								data-cy='btn-close'
								onClick={() => {
									this.props.history.push(`/ethiccode`)
								}}
							>
								<Translate id='common.cancel' />
							</Button>
						</Toolbar>
					</Grid>
				</CardActions>
			</Card>
		)
	}
}

export default BaseInfoEthicCode
