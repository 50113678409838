import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

/**
 * RMIReception
 */
export default class RMIReception extends Component {

  /**
     * Create an instance of sample request
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        if(this.props.sampleRequest){
            return (
                <List>
                    <ListItem divider>
                        <ListItemText primary={<Typography variant="body2" gutterBottom>
                            <strong>{<Translate id="samplesrequest.dateReviceSample"/>}: </strong>{this.props.sampleRequest.reception_sample_date_long} {" "}
                            {this.props.sampleRequest.is_sample_delivered_on_time &&  <strong style={{ fontSize: '85%',}} className={"badge badge-success"} > {" Entregada a tiempo"}</strong>}
                            {!this.props.sampleRequest.is_sample_delivered_on_time &&  <strong style={{ fontSize: '85%',}} className={"badge badge-danger"} > {" Fuera de tiempo"}</strong>}
                        </Typography>} />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary={<Typography variant="body2" gutterBottom>
                            <strong>{<Translate id="samplesrequest.sampleNumberRecibed"/>}: </strong>{this.props.sampleRequest.number_of_samples_received}</Typography>} />
                    </ListItem>
                </List>
            );
        }
        else{
            return("");
        }
    }
}
