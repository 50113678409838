/** Oidc section imports */
import { createUserManager } from 'redux-oidc';
import { getAbsoluteUrlAddresss } from './UrlHelper'


/** Defines the user manager configuration */
const userManagerConfig = {
  client_id: 'sip20_web_app',
  redirect_uri: getAbsoluteUrlAddresss('callback'),
  post_logout_redirect_uri: getAbsoluteUrlAddresss(''),
  response_type: 'id_token token',
  scope: 'openid profile sip20_admistration_api',
  authority: `${process.env.REACT_APP_IDENTITY_SERVER}/identity`,
  filterProtocolClaims: true,
  loadUserInfo: true,
  clockSkew: 900
};

const userManager = createUserManager(userManagerConfig);
export default userManager;