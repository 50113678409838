import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
//import moment from 'moment'
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import {Typography} from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';

/** Import resources */
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
//import DatePicker from '../../common/DateTimePicker/DatePicker';

/**
 * Component o DialogEditDates
 */
class DialogRequieredDate extends Component {

    /**
    * Create an instance ofMemos
    * @param {*} props 
    */
    constructor(props) {
        super(props);
        autoBind(this);
        
    }

    /**
     * Can be save
     */
    canbeSave() {
        if (this.props.maquilaOrder.required_plant_date.length !== 0)
            return true;
        else
            return false;
    }

    /**
     *  Dialog to revister arrive date
     */
    render() {
        const { classes, maquilaOrder } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            {<Translate id="maquilaOrders.additionalInformationMaquilaOrder"/>}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={this.props.onSaveItem}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.canbeSave()}
                    />
                }>

                <form className={classes.textField}>
                    <FormControl required component="fieldset" className={classes.formControl} >
                        <FormHelperText>{<Translate id="maquilaOrders.activatedRequiredOption"/>}</FormHelperText>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={maquilaOrder.required_quality}
                                onChange={(event) => { this.props.updateProperty('required_quality', event.target.checked) }} />}
                                label={<Translate id="maquilaOrders.requieredQuality"/>}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={maquilaOrder.required_molde}
                                onChange={(event) => { this.props.updateProperty('required_molde', event.target.checked) }} />}
                                label={<Translate id="maquilaOrders.requieredMolde"/>}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={maquilaOrder.required_tooling}
                                onChange={(event) => { this.props.updateProperty('required_tooling', event.target.checked) }} />}
                                label={<Translate id="maquilaOrders.requieredTool"/>}
                            />
                        </FormGroup>
                    </FormControl>
            </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 280,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(DialogRequieredDate));