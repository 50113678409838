import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'
import Select from 'react-select/async'

import { searchByPartNumbersByQury } from '../../store/partNumbers/PartNumberAction'

/**
 * Part number selector container
 *
 * @class PartNumberSelector
 * @extends {Component}
 */
class PartNumberSelector extends Component {
	/**
	 *Creates an instance of PartNumberSelector.
	 * @param {*} props
	 * @memberof PartNumberSelector
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			isLoading: true,
			partNumbers: [],
			selectedPartNumber: '',
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (this.props.value) {
			this.setState({ isSearching: true })
			this.getPartNumbers('').then(() => {
				this.setState({ isSearching: false })
			})
		}
	}

	/**
	 * React life-cycle
	 * select default option if prop.value is found in list
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof partNumberSelector
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.props.value) {
			if (
				this.state.partNumbers &&
				(this.state.partNumbers.length !== prevState.partNumbers.length || this.props.value !== prevProps.value)
			) {
				let selectedPartNumber = this.state.partNumbers.find((partNumber) => {
					return partNumber.code === this.props.value
				})
				if (selectedPartNumber) {
					selectedPartNumber = { label: selectedPartNumber.part_number_full, value: selectedPartNumber.code }
					this.setState({ selectedPartNumber })
				}
			}
		}
	}

	/**
	 * Get the part number matching with a query
	 *
	 * @param {*} query
	 * @returns
	 * @memberof PartNumberSelector
	 */
	getPartNumbers(query) {
		return this.props.searchByPartNumber(query).then((partNumbers) => {
			this.setState({
				partNumbers,
			})
			if (partNumbers) {
				return partNumbers.map((partNumber) => {
					return {
						value: partNumber.code,
						label: partNumber.part_number_full,
					}
				})
			}
		})
	}

	/**
	 * On selec a part number
	 *
	 * @param {*} option
	 * @memberof PartNumberSelector
	 */
	onSelectedPartNumberClick(option) {
		if (this.state.partNumbers) {
			let selectedPartNumber = this.state.partNumbers.find((partNumber) => {
				return partNumber.code === option.value
			})
			if (this.props.onPartNumberSelected) {
				this.props.onPartNumberSelected(selectedPartNumber)
			}
		}
	}

	/**
	 * Method to determine place holder message
	 *
	 * @returns
	 * @memberof UserSelector
	 */
	getPlaceholder() {
		let id = 'plotSelector.searchPartNumbers'
		if (this.props.value) {
			if (this.state.isSearching || this.props.isLoadingUsers) id = 'common.loadingPleaseWait'
		}
		return <Translate id={id} />
	}

	/**
	 *
	 *
	 * @returns
	 * @memberof PartNumberSelector
	 */
	render() {
		return (
			<Select
				placeholder={this.getPlaceholder()}
				value={this.props.value && this.state.selectedPartNumber}
				loadOptions={this.getPartNumbers}
				onChange={this.onSelectedPartNumberClick}
				isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
				styles={{
					menu: (base) => ({ ...base, zIndex: 31 }),
				}}
			/>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		searchByPartNumber: (query) => {
			return dispatch(searchByPartNumbersByQury(query))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PartNumberSelector)
