import React, { Component } from 'react';
import { Tooltip, IconButton, Popper, Typography, Grid, Card, CardContent, CardActions, Button } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import MoreFiltersOutlined from '@material-ui/icons/BallotOutlined';
import MoreFilters from '@material-ui/icons/Ballot';
import uuidv4 from 'uuid/v4'
import { withStyles } from '@material-ui/core/styles';
import DatePicker from '../DateTimePicker/DatePicker';
import moment from 'moment';
import autoBind from 'auto-bind';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';

class SearchFilters extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = this.initialState();
    };

    initialState() {
        return {
            open: false,
            anchorEl: null
        };
    }

    onUpdateProperty(property, value, dependentProperty) {
        if (IsNullOrEmpty(this.state[property]) || !moment(this.state[property], "DD/MM/YYYY").isSame(moment(value, "DD/MM/YYYY"))) {
            this.setState({
                [property]: value,
                [dependentProperty]: null
            })
        }
    }

    handleOnCloseCancel() {
        this.setCleanProperties();
        this.setState(this.initialState());
    }

    /**
     * Set all filter properties to initial state
     *
     * @memberof SearchFilters
     */
    setCleanProperties() {
        this.props.options.forEach(option => {
            option.properties.forEach(property => {
                this.setState({ [property.propertyName]: null })
            })
        });
    }
    componentDidMount() {
        this.setCleanProperties();
    }

    handleOnClickSearch() {
        this.props.onSearch(this.state)
            .then(response => {
                if (response === 'Ok')
                    this.setState(this.initialState());
            })
    }

    canSearch() {
        let canBeSearch = false;
        this.props.options.forEach(option => {
            option.properties.forEach(property => {
                if (!IsNullOrEmpty(this.state[property.propertyName])
                    && Object.keys(property).findIndex(key => key === 'dependentPropertyName') >= 0
                    && !IsNullOrEmpty(this.state[property.dependentPropertyName])) {
                    canBeSearch = true;
                }
            })
        });
        return canBeSearch;
    }

    renderDateTimePicker(property, xs) {
        return (
            <Grid item xs={xs}>
                <Typography variant="caption" color={'textSecondary'}>
                    <Translate id={property.translation} />
                </Typography>
                <DatePicker
                    onChangeRange={(event) => { this.onUpdateProperty(property.propertyName, event, property.dependentPropertyName) }}
                    startDate={this.state[property.propertyName] ? moment(this.state[property.propertyName], 'DD/MM/YYYY') : null}
                    minDate={this.state[property.minDateProperty] ? moment(this.state[property.minDateProperty], 'DD/MM/YYYY') : null}
                />
            </Grid>
        )
    }

    render() {
        const { classes } = this.props;
        let hasOptions = this.props.options ? this.props.options.length > 0 : false;
        return (
            <div style={{ textAlign: "center" }}>
                {hasOptions &&
                    <Tooltip title={<Translate id={hasOptions ? "quotes.options" : "quotes.noOptions"} placement="left" />}>
                        <IconButton color="primary"
                            aria-label="search filters"
                            // style={xsButton}
                            className={classes.iconButton}
                            onClick={(event) => {
                                this.setState({
                                    open: !this.state.open,
                                    anchorEl: event.currentTarget
                                })
                            }}
                        >
                            {Object.keys(this.state).length > 2 ? <MoreFilters color="primary" /> : <MoreFiltersOutlined color="inherit" />}
                        </IconButton>
                    </Tooltip>
                }
                {(hasOptions && this.state.open) &&
                    <Popper
                        open={this.state.open}
                        transition
                        anchorEl={this.state.anchorEl}
                        placement='bottom-end'
                        style={{
                            zIndex: 40
                        }}
                    >
                        <Card style={{
                            backgroundColor: '#fafafa',
                            padding: 2,
                            border: 'lightgray solid 1px',
                            maxWidth: 500
                        }}>
                            <CardContent>
                                <Grid container spacing={8} key={uuidv4()}>
                                    {hasOptions && this.props.options.map(option => {
                                        let xs = (12 / option.properties.length);
                                        return (
                                            <Grid item xs={12} key={uuidv4()}>
                                                <Grid container spacing={24} key={uuidv4()}>
                                                    {option.type === 'datetime' && option.properties.map(property => {
                                                        return (this.renderDateTimePicker(property, xs));
                                                    })}
                                                </Grid>
                                            </Grid>)
                                    })}
                                </Grid>
                            </CardContent>
                            <CardActions style={{ textAlign: "right" }}>
                                <Button
                                    color="primary"
                                    onClick={this.handleOnClickSearch}
                                    disabled={!this.canSearch()}>
                                    <Translate id="common.search" />
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={this.handleOnCloseCancel}>
                                    <Translate id="common.cancel" />
                                </Button>
                            </CardActions>
                        </Card>
                    </Popper>
                }
            </div>
        );
    }
}


const styles = theme => ({
    iconButton: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        transition: theme.transitions.create('width'),
    },
});

export default withStyles(styles)(SearchFilters);