import React from 'react'
import { useHistory, useParams } from 'react-router'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import { Tab, Tabs, withStyles } from '@material-ui/core'

/** Components imports section */
import VehicleControls from './VehicleControls'
import VehiclesIndex from './VehiclesIndex'
import VehicleServices from './VehicleServices'
import CalendarIndex from './CalendarIndex'

const AdministratorIndex = (props) => {
	const { classes, isBlocked, isAdminUser, disabledBlock } = props
	const { activeTab } = useParams()
	const history = useHistory()

	/**
	 * Handle the change of tab
	 */
	const handleChange = (event, newValue) => {
		history.push(`/controlCO2/${newValue}`)
	}

	/**
	 * Render content for current selected tab
	 */
	const renderTabContent = () => {
		switch (activeTab) {
			case tabs.VehicleControls:
				return <VehicleControls isBlocked={isBlocked} isAdminUser={isAdminUser} disabledBlock={disabledBlock}/>
			case tabs.VehicleServices:
				return <VehicleServices />
			case tabs.Vehicles:
				return <VehiclesIndex />
			case tabs.Calendar:
				return <CalendarIndex />
			default:
				return <VehicleControls isBlocked={isBlocked} isAdminUser={isAdminUser} disabledBlock={disabledBlock}/>
		}
	}

	return (
		<>
			<Tabs value={activeTab} onChange={handleChange} className={classes.root} aria-label='Tabs'>
				<Tab
					className={classes.tabItem}
					label={<Translate id='controlCO2.tabs.controls' />}
					{...a11yProps(tabs.VehicleControls)}
				/>
				<Tab
					className={classes.tabItem}
					label={<Translate id='controlCO2.tabs.calendar' />}
					{...a11yProps(tabs.Calendar)}
				/>
				<Tab
					className={classes.tabItem}
					label={<Translate id='controlCO2.tabs.services' />}
					{...a11yProps(tabs.VehicleServices)}
				/>
				<Tab
					className={classes.tabItem}
					label={<Translate id='controlCO2.tabs.vehicles' />}
					{...a11yProps(tabs.Vehicles)}
				/>
			</Tabs>
			{renderTabContent()}
		</>
	)
}

AdministratorIndex.propTypes = {}

const tabs = {
	VehicleControls: 'vehicle-controls',
	VehicleServices: 'vehicle-services',
	Vehicles: 'vehicles',
	Calendar: 'calendar',
}

const a11yProps = (id) => {
	return {
		id: `tab-${id}`,
		value: `${id}`,
		'aria-controls': `simple-tabpanel-${id}`,
	}
}

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.common.white,
		minHeight: '30px !important',
	},
	tabItem: {
		minHeight: '30px !important',
	},
})

export default withStyles(styles, { withTheme: true })(AdministratorIndex)
