
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import PasswordStrength from './PasswordStrength'
/**
 * Dialog to add common item in workflow
 */
class ChangePassword extends Component {

    /**
   *Creates an instance for change a password
   * @param {*} props
   * @memberof 
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
        }
    }
    /**
   * Determine if a key is included in the user inside errors
   * 
   * @param {*} key
   * @returns
   * @memberof EditUserForm
   */
  hasInvalidValue(key) {
    if (!this.props.errors) {
      return false;
    }
    return this.props.errors.some(error => error.key === key);
  }

    /**
     * Function to add newPassword
     * @param {*} comment 
     */
    updateValidatePasswordProperty(validatePassword) {
        this.setState({
            validatePassword: validatePassword
        })
    }

    /**
    * Function invoke callback for is good  Punctuation
    */
    onChangePunctuation = (punctuation) => {}

    /**
     * Dialog information
     */
    render() {
        const { classes, externalUser } = this.props;
        return (
            <Dialog
            open = {this.props.open}
            onClose={this.props.onClose}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {this.props.title}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={this.props.onUpdatePasswordUserExternal}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.props.canBeSaved}
                    />
                }>

                <form  className={classes.EditPasswordUser}>
                    <TextField label={<Translate id="users.currentPassword"/>} fullWidth
                        data-cy="txt-current-password" 
                        className={classes.textFieldMargin}
                        value={externalUser.actualPassword}
                        error = {this.hasInvalidValue("actualPassword")}
                        onChange={(event) => {this.props.updateUserPasswordProperty("actualPassword", event.target.value)}}
                        type = "password" 
                    />

                    <TextField label={<Translate id="users.newPassword"/>} fullWidth
                        data-cy="txt-new-password" 
                        className={classes.textFieldMargin}
                        value={externalUser.newPassword}
                        error = {this.hasInvalidValue("newPassword")}
                        onChange={(event) => {this.props.updateUserPasswordProperty("newPassword", event.target.value)}}
                        type = "password"
                    />
                    <br/>
                    <PasswordStrength
                        password={externalUser.newPassword}
                        onChangeScore={this.onChangePunctuation}
                    />

                    <TextField label={<Translate id="users.checkPassword"/>} fullWidth
                        data-cy="txt-confirm-password" 
                        className={classes.textFieldMargin}
                        value={externalUser.checkPassword}
                        error = {this.hasInvalidValue("checkPassword")}
                        onChange={(event) => {this.props.updateUserPasswordProperty("checkPassword", event.target.value)}}
                        type = "password"
                        helperText={<Translate id="users.validatePassword"/>} 
                        />
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    },
    marginSelect:{
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(ChangePassword));