/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
/**Import MaterialUi section */
import {
    Grid, Avatar, Button, Typography, TextField
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { UPDATE_PUBLISH_FLAG_ENABLED } from '../../../../store/biddings/BiddingsActions'
const AddExchangeRatesLateralPanel = (props) => {
    /**props section */
    const { onCloseButtonClick, onSaveButtonClick,
        isSaving, classes
    } = props
    
    const [allDivisas, setAllDivisas] = useState([]);

    /**Use effect section */
    useEffect(() => {
        if (props.exchangeRates && (allDivisas.length === 0) && (props.exchangeRates.length !== 0)) {
            let mappedExchangeRates = []
            props.exchangeRates.forEach(exchangeRate => {
                let mappedExchangeRate = {
                    Code: exchangeRate.DivisaCode,
                    Name: exchangeRate.DivisaCode,
                }
                mappedExchangeRates.push(mappedExchangeRate)

            })
            setAllDivisas(mappedExchangeRates)
        }
    }, [props.exchangeRates])

    /**Verify if can be saved data*/
    const canBeSaved = () => {
        if (!allDivisas.every(divisa => (divisa.ExchangeRate && !IsNullOrEmpty(divisa.ExchangeRate))))
            return false
        return true
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(allDivisas, UPDATE_PUBLISH_FLAG_ENABLED)
        }
    }

    /**On change properties */
    const onPropertyChange = (event, divisaCode) => {
        let { name, value } = event.target;
        let indexOfDivisa = allDivisas.findIndex(x => x.Code === divisaCode)
        let newArray = [];
        for (var i = 0; i < allDivisas.length; i++) {
            newArray.push(allDivisas[i]);
        }
        if (indexOfDivisa >= 0) {
            newArray[indexOfDivisa][name] = value
            newArray[indexOfDivisa].Name = value
        }
        setAllDivisas(newArray);
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<Translate id="biddingsModule.exchangeRate" />}
                // subTitlePanel={``}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >
                        <Grid item xs={12} className={classes.titleDisplay}>
                            <Typography variant="subtitle2" color="primary" align="justify"><Translate id="biddingsModule.addExchangeRate" /></Typography>
                        </Grid>
                        {
                            allDivisas.map(divisa => {
                                return (
                                    <Grid item xs={12}>
                                        <Grid container spacing={24}>
                                            <Grid item xs={1} className={classes.containerDivisaCode}>
                                                {divisa.Code}
                                            </Grid>
                                            <Grid item xs={11} >
                                                <TextField
                                                    fullWidth
                                                    margin="dense"
                                                    onChange={(event) => onPropertyChange(event, divisa.Code)}
                                                    type='number'
                                                    step={0.5}
                                                    name='ExchangeRate'
                                                    variant="outlined"
                                                    value={divisa.ExchangeRate}
                                                    disabled={isSaving}
                                                    InputProps={{ classes: { input: classes.inputProps } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>


                </div>
                <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            disabled={!canBeSaved()}>
                            {<Translate id="biddingsModule.calculateBidding" />}
                        </Button>
                    </Grid>
                </Grid>

            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "20px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    titleDisplay: {
        display: "flex"
    },
    containerDivisaCode: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center"
    },
    inputProps: {
        height: 6,
    }

});

AddExchangeRatesLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddExchangeRatesLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddExchangeRatesLateralPanel))