import React from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
/**Import MaterialUi section */
import {
    Button, Grid, List, ListItem, ListItemText, Divider
} from '@material-ui/core'
/**Import components section */
/**Import store section */
/**import helper section */
import { converListToArray } from "../../../../store/helpers/ListHelper";
import { CaBeShowListSuppliersEngineer, canBeOperationDeliveryWarehouse } from "../../../../store/helpers/RolesHelper";
import { Cancel, Closed } from '../../../../store/helpers/DevelopmentHelper'

const ViewComponent = (props) => {
    const { updateStatus, classes, item
    } = props
    const isSaving = useSelector((state) => state.warehouseWindows.get('isSaving'))

    const userRolesByCompany = useSelector((state) =>
    state.profile.get("userRolesByCompany")
      ? converListToArray(state.profile.get("userRolesByCompany"))
      : []
  );

    const onCancelFolio = () => {
        if (props.updateStatus) {
            updateStatus(item, Cancel)
        }
    }

    const onCloseFolio = () => {
        if (props.updateStatus) {
            updateStatus(item, Closed)
        }

    }

    return (
        <div>
            <div className={classes.containerInformation}>
                <List className={classes.listStyle} dense>
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="warehouseWindows.view.folio" />}
                            secondary={item.Folio}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider}/>
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="warehouseWindows.view.status" />}
                            secondary={item.status}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider}/>
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="warehouseWindows.view.creator" />}
                            secondary={item.CreatorName}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider}/>
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="warehouseWindows.view.deliveryDate" />}
                            secondary={item.DeliverDate}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider}/>
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="warehouseWindows.view.material" />}
                            secondary={item.Material}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider}/>
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="warehouseWindows.view.operativeUnit" />}
                            secondary={item.OperativeUnit}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider}/>
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="warehouseWindows.view.transportType" />}
                            secondary={item.TransportType}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider}/>
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="warehouseWindows.view.plateNumber" />}
                            secondary={item.PlateNumber}
                        />
                    </ListItem>
                </List>
            </div>

            {(item.Status === 4 && (CaBeShowListSuppliersEngineer(userRolesByCompany) || canBeOperationDeliveryWarehouse(userRolesByCompany))) && <Grid container>
                <Grid item xs={6} className={classes.containerBtn}>
                    <Button
                        data-cy="btn-save"
                        type="button"
                        color="primary"
                        variant='outlined'
                        onClick={onCancelFolio}
                        className={classes.widthBtnSaved}
                        disabled={isSaving}>
                        {<Translate id="warehouseWindows.view.cancel" />}
                    </Button>
                </Grid>
                <Grid item xs={6} className={classes.containerBtn}>
                    <Button
                        data-cy="btn-save"
                        type="button"
                        color="secondary"
                        variant='outlined'
                        onClick={onCloseFolio}
                        className={classes.widthBtnSaved}
                        disabled={isSaving}>
                        {<Translate id="warehouseWindows.view.close" />}
                    </Button>
                </Grid>
            </Grid>}
        </div>

    )

}

const styles = theme => ({
    containerInformation: {
        maxHeight: `calc(100vh - ${165}px)`,
        minHeight: `calc(100vh - ${165}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    widthBtnSaved: {
        minWidth: "200px",
    },
    divider: {
        marginRight: "10px",
    },
});

ViewComponent.propTypes = {
    /**
     * Updates status in the request
     */
     updateStatus: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Selected item data
     */
    item: propTypes.object,
}

ViewComponent.defaultProps = {
    updateStatus: () =>
        console.warn('Callback [updateStatus] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false,
    item: {}
}

export default withRouter(withStyles(styles, { withTheme: true })(ViewComponent))