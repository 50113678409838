
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import Button from '@material-ui/core/Button';

import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';
import {GetTransportType} from '../../../../store/helpers/SelectOptions'
import DestinationSelector from '../../transportDestination/DestinationSelector';

/**
 * Component ot render CarbonFootprint delivery order
 */
export default class CarbonFootprint extends Component {

    /**
     * Create an instance delivery order
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            carbonFootprint: this.props.deliveryOrder,
            destinationSelected: {
                value: this.props.deliveryOrder.destination_token,
                label: this.props.deliveryOrder.destination_name,
            },
        }
    }

     /**
     * On seleted destination
     */
    onTransportDestinationSelected(destination) {
        this.setState({ destinationSelected: destination });
    }

    /**
     * updateProperty
     * @param {*} property 
     * @param {*} value 
     */
    updateProperty(property, value) {
        let carbonFootprintUpdate = { ...this.state.carbonFootprint };
        carbonFootprintUpdate[property] = value;
        this.setState({
            carbonFootprint: carbonFootprintUpdate
        })
    }

          /**
     * On change TransportType
     *
     * @param {*} option
     * @memberof TransportType
     */
    onChangeTransportType(option) {
        this.updateProperty("type_transport", option.value)
    }

    onKeyDown(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    renderTextArea(key) {
        return (
            <TextField
                key={key}
                type="text"
                fullWidth
                defaultValue={this.state.carbonFootprint[key] ? this.state.carbonFootprint[key] : ''}
                onBlur={(event) => { this.updateProperty(key, event.target.value) }}
                onKeyDown={this.onKeyDown}
            />
        )
    }

    canBeSaved() {
        if (this.props.isSaving) return false;
        else {
            if (this.state.destinationSelected === null) return false;
            let carbonFootprint = this.state.carbonFootprint;
            if (IsNullOrEmpty(carbonFootprint.type_transport))
             return false;
        }
        return true;
    }

    /**
     * Render
     */
    render() {
        let transportsType = GetTransportType();
        return (
            <Grid container direction="row" justify="flex-start" alignItems="center">
                <List style={{width: "700px"}}>
                    <ListItem>
                        <ListItemText
                            secondary={
                                <Typography component="span" variant="body2" color="textPrimary">
                                    <strong><Translate id="deliveryOrderModule.carbonFootprint.typeTransport" />{": "}</strong>
                                    <Select
                                        value={transportsType[this.state.carbonFootprint.type_transport]}
                                        options={transportsType}
                                        onChange={this.onChangeTransportType}
                                        styles={{
                                            menu: (base) => ({ ...base, zIndex: 60 }),
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }) /// THIS IS TO SHOW MENU OVER ANY ELEMENT
                                        }}
                                    />
                                </Typography>
                            } />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            secondary={
                                <Typography component="span" variant="body2" color="textPrimary">
                                    <strong><Translate id="deliveryOrderModule.carbonFootprint.originTransport" />{": "}</strong>
                                    <DestinationSelector
                                       destination={this.state.destinationSelected}
                                       onDestinationSelected={(destination) => { this.onTransportDestinationSelected(destination) }}
                                   />
                                </Typography>
                            } />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            secondary={
                                <Typography component="span" variant="body2" color="textPrimary">
                                    <strong><Translate id="deliveryOrderModule.carbonFootprint.transportFreightName" />{": "}</strong>
                                    {this.renderTextArea("freight_name")}
                                    <br/><br/>
                                </Typography>
                            } />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            secondary={
                                <Button 
                                    type="button" 
                                    variant="outlined"
                                    color="secondary" 
                                    onClick={() => {this.props.onSaveOptionAditional(this.state.carbonFootprint, this.state.destinationSelected)}}
                                    disabled={!this.canBeSaved()}>
                                    {<Translate id="common.save"/>}
                                </Button>
                            } />
                    </ListItem>
                </List>

            </Grid>
        );
    }
}

/**
 * CarbonFootprint
 */
CarbonFootprint.propTypes = {
    deliveryOrder: PropTypes.object.isRequired
}