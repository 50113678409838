import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const VehicleSelect = ({ options = [], onOptionChange = () => '[onOptionChange] is not defined!' }) => {
	return (
		<div>
			<Select
				options={options}
				onChange={onOptionChange}
				styles={{
					menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
					menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
					menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
				}}
				menuPortalTarget={document.parentNode}
				menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
				menuPlacement={'auto'}
			/>
		</div>
	)
}

VehicleSelect.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({ value: PropTypes.string.isRequiredObject, label: PropTypes.string.isRequired })
	).isRequired,
	onOptionChange: PropTypes.func.isRequired,
}

export default VehicleSelect
