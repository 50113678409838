import React from 'react';
import PropTypes from 'prop-types';

/** Material UI import section */
import { 
    Collapse,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popover
} from '@material-ui/core'

const ContextFilterSubMenu = props => {
    const { id, anchorEl, open, items, onItemClick } = props;

    const handleClick = (event) => {
        console.log(`🚀 ~ file: ContextFilterSubMenu.js ~ line 18 ~ handleClick ~ event`, event);
        if (onItemClick) {
            onItemClick(event)
        }
    }

    const renderItems = () => {
        return (
            items.map(item => (
                <ListItem divider
                    key={item.key}
                    button
                    data-criteria={item.criteria}
                    onClick={handleClick}
                >
                    <ListItemText primary={item.description} />
                </ListItem>
            ))
        );
    }

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            //placement="right-start"
            style={{ border: '1px solid gray' }}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
        >
            <Paper>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" dense disablePadding>
                        { renderItems() }
                    </List>
                </Collapse>
            </Paper>
        </Popover>
    );
}

ContextFilterSubMenu.propTypes = {
    anchorEl: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

export default ContextFilterSubMenu;
