/**Import react section */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/**Import MaterialUi section */
import { Grid, Avatar, Typography, Divider, List, ListItemIcon, ListItemText, ListItem } from '@material-ui/core'
import FileIcon from '@material-ui/icons/AttachFile'
import AddIcon from '@material-ui/icons/Add'
/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import Toaster from '../../common/Toaster'
import AddInformationPartialComponent from './AddInformationPartialComponent'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants'
import Evidence from '../../common/Evidence'
import MaterialMachinedPartialItemTable from './MaterialMachinedPartialItemTable'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import {
	AddMaterialMachinedPartialItem,
	UpdateMaterialMachinedPartialItem,
	RemoveFileMaterialMachinedPartialItem,
	AddEvidenceMaterialMachinedPartialItem,
} from '../../../store/maquilaOrders/MaquilaOrderActions'
import {
	canUpdateMaquilaOrder,
	canBeModulePlaneationSupplier,
	IsUserInRole,
	ROLE_WAREHOUSEMAN,
	ROLE_WAREHOUSERAWMATERIAL,
} from '../../../store/helpers/RolesHelper'
import { IsUserSupplierAdministrator, IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import {
	Maquila_Orders_Send_Warehouse,
	Maquila_Orders_Send_Supplier,
	Draft,
} from '../../../store/helpers/DevelopmentHelper'
import HtmlEditor from '../../common/HtmlEditor'

const MaterialMachinedPanelDetail = (props) => {
	// const classes = styles()
	const dispatch = useDispatch()
	/**props section */
	const {
		onCloseButtonClick,
		isSaving,
		classes,
		itemSelected,
		// canBeEdit,
		// canBeEditWarehouse,
		maquilaOrder,
		userRolesByCompany,
		currentUser,
		onSaveItem,
	} = props

	const canBeUpdateSupplier =
		(canBeModulePlaneationSupplier(userRolesByCompany) || IsUserSupplierAdministrator(currentUser)) &&
		maquilaOrder.can_be_edit_supplier_material_machined &&
		IsCurrentNotActiveDirectoryUser(currentUser) &&
		maquilaOrder.can_be_add_material_machined_partial_item
	let canBeEditWarehouse =
		(IsUserInRole(ROLE_WAREHOUSEMAN, userRolesByCompany) ||
			IsUserInRole(ROLE_WAREHOUSERAWMATERIAL, userRolesByCompany) ||
			canUpdateMaquilaOrder(userRolesByCompany)) &&
		maquilaOrder.can_be_add_material_machined_partial_item
	let canBeUploadMaterialMachinedFiles =
		(IsUserInRole(ROLE_WAREHOUSEMAN, userRolesByCompany) ||
			IsUserInRole(ROLE_WAREHOUSERAWMATERIAL, userRolesByCompany) ||
			canUpdateMaquilaOrder(userRolesByCompany)) &&
		maquilaOrder.can_be_add_files &&
		(maquilaOrder.actual_operation === Maquila_Orders_Send_Warehouse ||
			maquilaOrder.actual_operation === Draft ||
			maquilaOrder.actual_operation === Maquila_Orders_Send_Supplier)
	let canBeEditDevelopment =
		canUpdateMaquilaOrder(userRolesByCompany) && maquilaOrder.can_be_add_files && maquilaOrder.can_be_edit

	let canBeEdit = canBeUpdateSupplier || canBeEditDevelopment || canBeEditWarehouse

	const [attachments, setAttachments] = useState(itemSelected.attachments ? itemSelected.attachments : [])
	const [itemModel, setItemModel] = useState(itemSelected)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})
	let informationPartialItems = itemModel ? itemModel.items : []
	let isMaquilaDraft = maquilaOrder.can_be_edit

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setItemModel({
			...itemModel,
			[name]: value,
		})
	}

	const onCleanTextFielForm = () => {
		setItemModel({
			...itemModel,
			quantity_send: 0,
			shipping_ticket: 0,
			quantity_received: 0,
			entrance_weighing_ticket: 0,
		})
	}

	/**Verify if can be sabed data*/
	const canBeAddedPartiality = () => {
		if (canBeEditWarehouse) {
			if (itemModel.quantity_send === null || Number(itemModel.quantity_send) === 0) return true
			else return false
		} else {
			if (IsNullOrEmpty(itemModel.entrance_weighing_ticket)) return true
			else return false
		}
	}

	/**
	 * On upload file in maquila order in list name
	 * @param {*} files
	 * @param {*} maquilaToken
	 * @param {*} listName
	 */
	const uploadFiles = (files) => {
		let maquilaOrderToken = props.match.params.token
		let filesList = attachments.concat(files[0])
		if (files.length > 0) {
			for (var index = 1; index < files.length; index++) {
				filesList.push(files[index])
			}
			return props
				.uploadFilesFromSupplier(maquilaOrderToken, itemModel.token, files)
				.then(() => {
					setAttachments(filesList)
				})
				.catch(() => {
					return Promise.reject()
				})
		}
		return Promise.resolve('Ok')
	}

	/**
	 * Handle event remove files of evidence componet to remove files of list
	 *
	 * @param {*} fileName
	 * @returns
	 */
	const removeFiles = (fileName) => {
		let maquilaOrderToken = props.match.params.token
		let newList = attachments.filter((files) => {
			return files.name !== fileName
		})
		return props
			.onDeleteAttachment(maquilaOrderToken, itemModel.token, fileName)
			.then(() => {
				setAttachments(newList)
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}

	const onAddMaterialMachinedPartialItem = () => {
		return dispatch(AddMaterialMachinedPartialItem(maquilaOrder ? maquilaOrder.token : '', itemModel))
			.then((result) => {
				onCleanTextFielForm()
				setItemModel(result)
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage:
						!IsNullOrEmpty(response.data.Message) && typeof response.data.Message === 'string'
							? response.data.Message
							: 'common.errorToSave',

					toasterVariant: 'error',
				})
			})
	}

	const onUpdateMaterialMachinedPartialItem = (event, item) => {
		let { name, value } = event.target
		item[name] = value
		return dispatch(
			UpdateMaterialMachinedPartialItem(
				maquilaOrder ? maquilaOrder.token : '',
				itemModel ? itemModel.token : '',
				item
			)
		)
			.then((result) => {
				onCleanTextFielForm()
				setItemModel(result)
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage:
						!IsNullOrEmpty(response.data.Message) && typeof response.data.Message === 'string'
							? response.data.Message
							: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
	}

	const onUpdateItemPartialInformation = (itemToUpdate) => {
		let indexOfItem = itemModel.items.findIndex((x) => x.token === itemToUpdate.token)
		if (indexOfItem >= 0) {
			let allItemsUpdate = [...itemModel.items]
			allItemsUpdate[indexOfItem] = itemToUpdate
			setItemModel({
				...itemModel,
				items: allItemsUpdate,
			})
		}
	}

	/**
	 * Add files in the material partial item
	 * @param {*} files
	 * @param {*} item
	 * @returns
	 */
	const uploadFilesInPartialItem = (files, item) => {
		let maquilaOrderToken = props.match.params.token
		let filesList = attachments.concat(files[0])
		if (files.length > 0) {
			for (var index = 1; index < files.length; index++) {
				filesList.push(files[index])
			}
			return dispatch(
				AddEvidenceMaterialMachinedPartialItem(maquilaOrderToken, itemModel.token, item.token, files)
			)
				.then((result) => {
					onUpdateItemPartialInformation(result)
				})
				.catch(() => {
					return Promise.reject()
				})
		}
		return Promise.resolve('Ok')
	}

	/**
	 * Handle event remove files of evidence componet to remove files of list
	 *
	 * @param {*} fileName
	 * @returns
	 */
	const removeFilesInPartialItem = (fileName, item) => {
		let maquilaOrderToken = props.match.params.token
		return dispatch(RemoveFileMaterialMachinedPartialItem(maquilaOrderToken, itemModel.token, item.token, fileName))
			.then((result) => {
				onUpdateItemPartialInformation(result)
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}

	/**
	 * Function on update file
	 */
	const onSaveItemUpdate = (name, data) => {
		console.log('onSaveItemUpdate', name, data)
		itemModel[name] = data.value
		if (props.onSaveItem) onSaveItem(itemModel)
	}

	return (
		<LateralPanel onClose={onCloseButtonClick} drawerWidth={classes.drawerContent}>
			<PanelHeader
				titlePanel={<Translate id={'maquilaOrders.informationSupplier'} />}
				onCloseButtonClick={onCloseButtonClick}
				icon={
					<Avatar className={classes.avatar}>
						<FileIcon />
					</Avatar>
				}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid container>
						<Grid item xs={12}>
							<Typography color='primary'>{'Evidencias de planta'}</Typography>
							<Evidence
								text={props.dropzoneText}
								files={attachments}
								uploadFiles={uploadFiles}
								removeFiles={removeFiles}
								baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
								isDisabled={!canBeUploadMaterialMachinedFiles}
							/>
						</Grid>

						<AddInformationPartialComponent
							//Bool props section
							isMaquilaDraft={isMaquilaDraft}
							itemModel={itemModel}
							isSaving={isSaving}
							canBeUpdateSupplier={canBeUpdateSupplier}
							canBeEditWarehouse={canBeEditWarehouse}
							//Funtion props section
							onPropertyChange={onPropertyChange}
							onAddMaterialMachinedPartialItem={onAddMaterialMachinedPartialItem}
							canBeAddedPartiality={canBeAddedPartiality}
						/>

						{!isMaquilaDraft && (
							<>
								<Grid item xs={12}>
									<Typography color='primary'>Información general</Typography>
									<List component='nav'>
										<ListItem>
											<ListItemIcon>
												<AddIcon />
											</ListItemIcon>
											<ListItemText primary={`Cantidad total solicitad`} />
											{itemModel ? itemModel.quantity_total : ''}
										</ListItem>
										<Divider />
										<ListItem>
											<ListItemIcon>
												<AddIcon />
											</ListItemIcon>
											<ListItemText primary={`Parcialidad total enviada`} />
											{itemModel ? itemModel.partial_total_sent : 0}
										</ListItem>
										<Divider />
										<ListItem>
											<ListItemIcon>
												<AddIcon />
											</ListItemIcon>
											<ListItemText primary={`Cantidad total recibida`} />
											{itemModel ? itemModel.partial_total_received : 0}
										</ListItem>
										<Divider />
										<ListItem>
											<ListItemIcon>
												<AddIcon />
											</ListItemIcon>
											<ListItemText primary={`Diferencia total`} />
											{itemModel ? itemModel.total_difference : 0}
										</ListItem>
										<Divider />
									</List>
								</Grid>

								<Grid item xs={12}>
									<Typography color='primary'>Información parcial</Typography>
									<MaterialMachinedPartialItemTable
										//Array props section
										informationPartialItems={informationPartialItems}
										//Bool props section
										canBeEditWarehouse={canBeEditWarehouse}
										canBeUpdateSupplier={canBeUpdateSupplier}
										isSaving={isSaving}
										//Functions props section
										onUpdateMaterialMachinedPartialItem={onUpdateMaterialMachinedPartialItem}
										uploadFilesInPartialItem={uploadFilesInPartialItem}
										removeFilesInPartialItem={removeFilesInPartialItem}
										//Object props section
										itemModel={itemModel}
									/>
								</Grid>
							</>
						)}

						{!isMaquilaDraft && (
							<Grid item xs={12}>
								<Typography component='span' variant='body2' color='textPrimary'>
									<br />
									{<Translate id={'maquilaOrders.usedMoldersAndComments'} />}
								</Typography>
								<HtmlEditor
									id={'comments-item'}
									labelVariant='caption'
									labelColor='textSecondary'
									isDisabled={!canBeEdit}
									initialValue={itemModel ? itemModel.comments : ''}
									onChange={(data) => onSaveItemUpdate('comments', data)}
								/>
							</Grid>
						)}
					</Grid>
				</div>
				<Toaster
					message={
						<Translate
							id={toasterMessageConfig.toasterMessage}
							data={{ name: toasterMessageConfig.name }}
						/>
					}
					open={toasterMessageConfig.showToaster}
					variant={toasterMessageConfig.toasterVariant}
					onClose={() => {
						setToasterMessageConfig({
							showToaster: false,
							toasterMessage: '',
							toasterVariant: '',
						})
					}}
				/>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	expansionPanelSummary: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingBottom: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputProps: {
		height: 6,
	},
	drawerContent: {
		width: '680px',
		height: '100vh',
		display: 'grid',
		gridTemplateRows: 'auto 1fr',
		overflowX: 'hidden',
		overflowY: 'hidden',
		backgroundColor: 'white',
	},
})

MaterialMachinedPanelDetail.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onDeleteAttachment: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onSaveItem: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
	/**
	 * Validate if template is correctly
	 */
	onCloseButtonClick: propTypes.func,
	/**All attachments saved */
	uploadFilesFromSupplier: propTypes.func,
	/**Detail machineds saved */
	uploadFilesDetailMachineds: propTypes.func,
	/**Delete detail machineds*/
	removeFilesDetailMachineds: propTypes.func,
}

MaterialMachinedPanelDetail.defaultProps = {
	onDeleteAttachment: () => console.warn('Callback [onDeleteAttachment] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	onSaveItem: () => console.warn('Callback [onSaveItem] no defined'),
	uploadFilesFromSupplier: () => console.warn('Callback [uploadFilesFromSupplier] no defined'),
	uploadFilesDetailMachineds: () => console.warn('Callback [uploadFilesDetailMachineds] no defined'),
	removeFilesDetailMachineds: () => console.warn('Callback [removeFilesDetailMachineds] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(MaterialMachinedPanelDetail))
