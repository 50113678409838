/** components imports section */
import React from 'react'
import propTypes from 'prop-types'
import moment from 'moment'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
/** helpers imports section */
import { colorFolioGuidelineStatus, descriptionGuidelineStatus } from '../../../../store/helpers/SpecificationsHelper'
import imbLogo from '../../../../resources/images/imagotipo.png'

const LayoutHeaderGuidelineDetail = (props) => {
	const { folio, creation_date, created_by, document_status, release_date, name } = props
	const classes = useStyles()

	return (
		<Card>
			<CardHeader
				avatar={<img src={imbLogo} alt='logo' className={classes.logoImg} />}
				title={
					<Typography gutterBottom variant='h6'>
						<strong>
							{<Translate id='specificationsSection.guidelines.tileMenu' />} | {folio}
						</strong>
					</Typography>
				}
				subheader={
					<p>
						{<Translate id='specificationsSection.guidelines.creationDate' />}:{' '}
						{moment(creation_date, 'DD/MM/YYYY HH:mm:ss').format('LLLL')}
						<br />
						{<Translate id='specificationsSection.guidelines.createdBy' />}: {created_by}
						<br />
						{<Translate id='specificationsSection.guidelines.documentName' />}: {name}
					</p>
				}
				action={
					<Typography variant='caption' className={classes.textHeader}>
						<strong
							className={classes.btnStatus}
							style={{ backgroundColor: colorFolioGuidelineStatus(document_status) }}
						>
							{descriptionGuidelineStatus(document_status)}
						</strong>
						<br />
						{<Translate id='specificationsSection.guidelines.releaseDate' />}:{' '}
						{moment(release_date, 'DD/MM/YYYY HH:mm:ss').format('LLLL')}
					</Typography>
				}
			/>
			<CardContent className={classes.withoutPaddingTopBottom}>
				<Divider />
			</CardContent>
		</Card>
	)
}

LayoutHeaderGuidelineDetail.propTypes = {
	folio: propTypes.string,
	creation_date: propTypes.string,
	created_by: propTypes.string,
	document_status: propTypes.number,
	release_date: propTypes.string,
	name: propTypes.string,
}

LayoutHeaderGuidelineDetail.defaultProps = {
	folio: '',
	creation_date: moment().format('DD/MM/YYYY HH:mm:ss').toString(),
	created_by: '',
	document_status: 0,
	release_date: moment().format('DD/MM/YYYY HH:mm:ss').toString(),
	name: '',
}

const useStyles = makeStyles(() => ({
	withoutPaddingTopBottom: {
		paddingTop: '0 !important',
		paddingBottom: '0 !important',
	},
	logoImg: {
		maxHeight: 85,
	},
	textHeader: {
		textAlign: 'end',
	},
	btnStatus: {
		fontSize: '106%',
		whiteSpace: 'normal',
		color: 'white',
		padding: '5px',
	},
}))

export default LayoutHeaderGuidelineDetail
