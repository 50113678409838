/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
/** Material-UI imports section */
import { withStyles, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
//Import compinents section
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'
import SuppliersSelector from '../../common/SupplierSelector'

import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import DatePicker from '../../common/DateTimePicker/DatePicker'

const DowloadReport = (props) => {
	/**props section */
	const { classes } = props

	const [selectedValue, setSelectedValue] = React.useState('All')
	const [supplier, setSupplier] = React.useState('')
	const [dateConfigurationModel, setDateConfigurationModelModel] = React.useState({
		endDate: null,
		startDate: null,
	})
	const canBeSaved = () => {
		if (supplier === '') return false
		return true
	}

	const canBeSavedDates = () => {
		if (dateConfigurationModel.endDate === null) return false
		if (dateConfigurationModel.startDate === null) return false
		return true
	}

	const handleChange = (event) => {
		setSupplier('')
		setSelectedValue(event.target.value)
	}

	const onSaveButtonClick = () => {
		if (props.onDownloadReport) {
			props.onDownloadReport(supplier, dateConfigurationModel)
		}
	}

	const onChangeDate = (property, data) => {
		if (property === 'startDate') {
			setDateConfigurationModelModel({
				...dateConfigurationModel,
				[property]: data,
				endDate: null,
			})
		} else {
			setDateConfigurationModelModel({
				...dateConfigurationModel,
				[property]: data,
			})
		}
	}

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			header={
				<Toolbar>
					<Typography variant='h6'>Descagar reporte de certificados de calidad</Typography>
				</Toolbar>
			}
			actions={
				<DialogActions
					view={SHOW_EDIT_VIEW}
					classes={classes}
					onCancel={props.onClose}
					onClose={props.onClose}
					onSaveItem={onSaveButtonClick}
					isSaving={props.isSaving}
					canBeSaved={selectedValue === 'BySuppliers' ? canBeSaved() && canBeSavedDates() : canBeSavedDates()}
				/>
			}
		>
			<Grid container>
				<Grid item xs={12}>
					<FormControl component='fieldset'>
						<FormLabel component='legend'>Selecciona la opción deseada</FormLabel>
						<RadioGroup row aria-label='position' name='position' defaultValue='All'>
							<FormControlLabel
								value='All'
								checked={selectedValue === 'All'}
								control={<Radio color='primary' />}
								label='Todo'
								labelPlacement='All'
								onChange={handleChange}
							/>
							<FormControlLabel
								value='BySuppliers'
								checked={selectedValue === 'BySuppliers'}
								control={<Radio color='primary' />}
								label='Por proveedor'
								labelPlacement='BySuppliers'
								onChange={handleChange}
							/>
						</RadioGroup>
					</FormControl>
				</Grid>
				{selectedValue === 'BySuppliers' && (
					<Grid item xs={12}>
						<Typography variant='subtitle1' gutterBottom>
							<Translate id='common.supplier' />
						</Typography>
						<SuppliersSelector
							onSupplierSelected={(supplier) => {
								setSupplier(supplier)
							}}
						/>
						<br />
					</Grid>
				)}

				<Grid item xs={6}>
					<Typography variant='subtitle2' color='primary' className={classes.titleText}>
						Fecha de inicio de periodo
					</Typography>
					<DatePicker
						opens={'left'}
						onChangeRange={(event) => onChangeDate('startDate', event)}
						startDate={dateConfigurationModel.startDate ? moment(dateConfigurationModel.startDate) : ''}
					/>
				</Grid>

				<Grid item xs={6}>
					<Typography variant='subtitle2' color='primary' className={classes.titleText}>
						Fecha fin de periodo
					</Typography>
					<DatePicker
						opens={'left'}
						onChangeRange={(event) => onChangeDate('endDate', event)}
						startDate={
							dateConfigurationModel.endDate ? moment(dateConfigurationModel.endDate, 'DD/MM/YYYY') : ''
						}
					/>
				</Grid>
			</Grid>
		</Dialog>
	)
}

const styles = (theme) => ({})

DowloadReport.propTypes = {
	open: propTypes.bool.isRequired,
}

DowloadReport.defaultProps = {
	open: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(DowloadReport))
