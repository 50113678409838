import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate, getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { CardHeader, Tooltip } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/PersonOutline'
import ProcessIcon from '@material-ui/icons/ThumbsUpDownOutlined'
import AceptedIcon from '@material-ui/icons/ThumbUp'
import RejectedIcon from '@material-ui/icons/ThumbDown'
import Button from '@material-ui/core/Button'

import LoadingPage from '../common/LoadingPage'
import FloatingButton from '../common/FloatingButton'
import DialogAvailableOperations from '../common/DialogAvailableOperations'
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow'
import InformationPartial from './components/InformationPartial'
import TrackingPartial from './components/TrackingPartial'
import AddEmergentAction from './components/AddEmergentAction'
import HistoryCard from '../common/HistoryCard'
import AddCommonItem from '../common/AddCommonItem'
import WorkflowCancel from '../common/WorkflowCancel'
import DataTable from '../common/DataTable'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

/** Import action section */
import { loadSupplier } from '../../store/suppliers/SupplierActions'
import {
	loadQualityFailureReportFromBackEnd,
	udateStatusToWaitingForCompleteTheContainment,
	addEmergentActionToQualityFailureReport,
	removeEmergentActionToQualityFailureReport,
	registerWarehouseEntryFromBackend,
	closedIsNotAttributableToTheSupplier,
	addCorrectiveActions,
	acceptCorrectiveActions,
	updateDataCorrectiveAction,
	uploadFilesEvidence,
	removeFilesEvidence,
	deleteDraftFailureReport,
	updateStatusDeviation,
} from '../../store/qualityFailureReport/QualityFailureReportAction'
import { getDeliveryOrderByPartNumberCertificate } from '../../store/planning/DeliveryOrdersAction'

import {
	FAILURE_REPORT_DOCUMENT_NOTIFICATION,
	FAILURE_REPORT_CORRECTIVE_ACTIONS_IMPROVEMENT_REQUEST,
	DEVIATION_AUTHORIZATION,
	WORKFLOW_CANCELLATION,
} from '../../store/helpers/WorkFlowDocumentsType'
import CreateCorrectiveActionDialog from './components/CreateCorrectiveActionDialog'
import Toaster from '../common/Toaster'
import Evidence from '../common/Evidence'
import { converListToArray } from '../../store/helpers/ListHelper'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import {
	canUserCloseModuleQuality,
	canUserCreateReportModuleQuality,
	canUserValideModuleQuality,
} from '../../store/helpers/RolesHelper'
import { COMPANY_IMBERA, COMPANY_PTM } from '../../store/helpers/ResourcesHelper'

/**
 * Container show QualityFailureReport
 */
class Show extends Component {
	/**
	 * Create an instance QualityFailureReport
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			activeTab: 0,
			openAvailableOperationsDialog: false,
			openCloseReport: false,
			openAddActionDialog: false,
			openAddRegisterSAPDialog: false,
			notExtistFolio: '',
			openModalAddCorrectiveAction: false,
			showActionRequestCommentsDialog: false,
			openDeleteDraft: false,
			//Toaster props
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			correctiveActionItem: null,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let qualityFailureToken = this.props.match.params.token
		this.props.loadQualityFailureReport(qualityFailureToken)
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoading !== this.props.isLoading && this.props.qualityFailure !== null) {
			/// Get information full from corrective
			let qualityFailure = this.props.qualityFailure
			this.props.loadSupplier(qualityFailure.supplier_token)
		}
	}

	/**
	 * Action to set state to open of available operations dialog
	 * @memberof
	 */
	setOpenDialog = (openAvailable) => {
		this.setState({
			openAvailableOperationsDialog: openAvailable,
		})
	}

	/**
	 * Function to edit QualityFailureReport
	 */
	onClickEditFailureReportQualirtInDraft() {
		let qualityFailureToken = this.props.match.params.token
		this.props.history.push(`/qualityfailurereport/edit/${qualityFailureToken}`)
	}

	/**
	 * Function to send notification from QualityFailureReport
	 */
	onClickSendNotificationQualityFailureReport() {
		if (this.props.qualityFailure.exist_container_action) {
			let qualityFailureToken = this.props.match.params.token
			this.props.history.push(`/qualityfailurereport/sendnotification/${qualityFailureToken}`)
		} else {
			this.setState({
				showToaster: true,
				toasterMessage: <Translate id='qualityfailurereport.isNeedAddContainerAction' />,
				toasterVariant: 'error',
			})
		}
	}

	/**
	 * Method to change the active tab
	 */
	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 * Action on onSendRequoteToSupplier
	 */
	onUdateStatusToWaitingForCompleteTheContainment() {
		this.props
			.udateStatusToWaitingForCompleteTheContainment(this.props.qualityFailure.token)
			.then(() => {
				this.setState({
					openAvailableOperationsDialog: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * onClickAddEmergentAction
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onClickAddEmergentAction() {
		this.setState({
			openAddActionDialog: true,
			openAvailableOperationsDialog: false,
		})
	}

	/**
	 * handle on add corrective action
	 *
	 * @memberof Show
	 */
	onAddCorrectiveActionRequest() {
		this.setState({ correctiveActionItem: null })
		this.handleOpenCloseItemDialog('openModalAddCorrectiveAction')
		this.handleOpenCloseItemDialog('openAvailableOperationsDialog')
	}

	/**
	 * Method to set state og open or close dialogs
	 *
	 * @param {*} dialogId
	 * @memberof Show
	 */
	handleOpenCloseItemDialog(dialogId) {
		this.setState({
			[dialogId]: !this.state[dialogId],
		})
	}

	/**
	 * Function to Add Emergent Action
	 */
	addEmergentAction(description) {
		this.props.addEmergentAction(description)
		this.setState({ openAddActionDialog: false })
	}

	/**
	 * onClickAddRegisterWarehouseEntry
	 *
	 * @param {*} item
	 * @memberof Create
	 */
	onClickAddRegisterWarehouseEntry() {
		this.setState({
			notExtistFolio: '',
			openAddRegisterSAPDialog: true,
			openAvailableOperationsDialog: false,
		})
	}

	/**
	 * Functions to regiset warehouse entry
	 * @param {*} folio
	 */
	registerWarehouseEntry(folio) {
		this.props
			.registerWarehouseEntry(folio)
			.then((response) => {
				this.setState({
					openAddRegisterSAPDialog: false,
					notExtistFolio: '',
				})
			})
			.catch(() => {
				this.setState({
					notExtistFolio: <Translate id='qualityfailurereport.entryFolioAlready' />,
				})
			})
	}

	/**
	 * onClickCloseReport
	 *
	 * @param {*} item
	 * @memberof Create
	 */
	onClickCloseReport() {
		this.setState({
			openCloseReport: true,
			openAvailableOperationsDialog: false,
		})
	}

	/**
	 * Action on onClosedIsNotAttributableToTheSupplier
	 */
	closedIsNotAttributableToTheSupplier() {
		this.props
			.closedIsNotAttributableToTheSupplier(this.props.qualityFailure.token)
			.then(() => {
				this.setState({
					openCloseReport: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * handle on accept corrective action
	 *
	 * @param {*} dialogId
	 * @param {*} item
	 * @returns
	 * @memberof Show
	 */
	onAcceptCorrectiveAction(dialogId, item) {
		return this.props
			.acceptCorrectiveActions(item)
			.then(() => {
				this.setState({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
				return Promise.reject()
			})
	}

	/**
	 * Handle open corrective Action Dialog to edit data
	 *
	 * @param {*} dialogId
	 * @param {*} item
	 * @memberof Show
	 */
	handleOpenCorrectiveActionDialog(dialogId, item) {
		this.setState(
			{
				correctiveActionItem: item,
			},
			() => this.handleOpenCloseItemDialog(dialogId)
		)
	}

	/**
	 * Function to create devolution from QualityFailureReport
	 */
	onClickCreateDevolutionFailure() {
		let qualityFailureToken = this.props.match.params.token
		this.props.history.push(`/qualityfailurereport/createdevolution/${qualityFailureToken}`)
	}

	/**
	 * Function to create rework from QualityFailureReport
	 */
	onClickCreateReworkFailure() {
		let qualityFailureToken = this.props.match.params.token
		this.props.history.push(`/qualityfailurereport/createrework/${qualityFailureToken}`)
	}

	/**
	 * Funcction register folio desviation from QualityFailureReport
	 */
	onClickRegisterFolioDeviation() {
		let qualityFailureToken = this.props.match.params.token
		this.props.history.push(`/qualityfailurereport/registerDeviation/${qualityFailureToken}`)
	}

	/**
	 * onDeleteDraft
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onDeleteDraft() {
		this.setState({
			openAvailableOperationsDialog: false,
			openDeleteDraft: true,
		})
	}

	/**
	 * onDeleteDraftFailureReport
	 */
	onDeleteDraftFailureReport(removeMotive) {
		this.props
			.deleteDraftFailureReport(this.props.qualityFailure.token, removeMotive)
			.then(() => {
				this.setState({
					openDeleteDraft: false,
				})
				this.props.history.push(`/qualityfailurereport`)
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Function to update status deviation
	 * @param {*} tokenRequest
	 * @param {*} comment
	 */
	onUpdateStatusDeviation(tokenDeviation) {
		this.props
			.updateStatusDeviation(tokenDeviation)
			.then(() => {
				this.setState({
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	//Cancel a Quality failure report
	onCancelDocument() {
		this.props.history.push(`/qualityfailurereport/cancel/${this.props.qualityFailure.token}`)
	}

	/**
	 * Get the card content
	 *
	 * @memberof Show
	 */
	getCardContent() {
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.qualityFailure !== null) {
			const { classes } = this.props
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Divider />
						<AppBar position='static' color='default'>
							<Tabs
								indicatorColor='secondary'
								textColor='secondary'
								className={classes.styleTab}
								value={this.state.activeTab}
								onChange={this.onClickToActiveTab}
							>
								<Tab
									label={
										<p>
											<i className='fa fa-exclamation-triangle'></i>
											<Translate id='common.information' />
										</p>
									}
								/>
								<Tab
									label={
										<p>
											<i className='fa fa-file-image'></i>
											<Translate id='common.evidence' />
										</p>
									}
								/>
								{this.props.qualityFailure.containment_action && (
									<Tab
										label={
											<p>
												<i className='fa fa-eye'></i>{' '}
												<Translate id='qualityfailurereport.tracking' />
											</p>
										}
									/>
								)}
							</Tabs>
						</AppBar>
						{this.state.activeTab === 0 && (
							<InformationPartial
								{...this.props}
								handleOpenCloseItemDialog={this.handleOpenCloseItemDialog}
								open={this.state.showActionRequestCommentsDialog}
								failureReportActualOperation={this.props.qualityFailure.actual_operation}
								handleOpenCorrectiveActionDialog={this.handleOpenCorrectiveActionDialog}
								onAcceptCorrectiveAction={this.onAcceptCorrectiveAction}
							/>
						)}
						{this.state.activeTab === 1 && (
							<Evidence
								isDisabled={
									this.props.qualityFailure.is_closed ||
									!IsCurrentActiveDirectoryUser(this.props.currentUser)
								}
								baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
								removeFiles={this.props.removeFiles}
								uploadFiles={this.props.uploadFiles}
								files={this.props.qualityFailure.attachments}
							/>
						)}
						{this.state.activeTab === 2 && <TrackingPartial {...this.props} />}
					</Grid>

					{/** Dialog to Add Emergent Action*/}
					<AddEmergentAction
						title={
							<span>
								{this.props.qualityFailure.folio}:{' '}
								<Translate id='qualityfailurereport.emergentAction' />
							</span>
						}
						message={<Translate id='qualityfailurereport.descriptionEmergentAction' />}
						open={this.state.openAddActionDialog}
						onClose={() => {
							this.setState({ openAddActionDialog: false })
						}}
						onSaveItem={this.addEmergentAction}
						isSaving={this.props.isSaving}
					/>

					{/** Dialog to Add Register Warehouse Entry */}
					<AddCommonItem
						title={<Translate id='qualityfailurereport.registerEntrySap' />}
						message={<Translate id='qualityfailurereport.entryFolioSap' />}
						open={this.state.openAddRegisterSAPDialog}
						onClose={() => {
							this.setState({ openAddRegisterSAPDialog: false })
						}}
						onSaveItem={this.registerWarehouseEntry}
						isSaving={this.props.isSaving}
						notExtistFolio={this.state.notExtistFolio}
						isInput={true}
					/>

					{/** Dialog to close report */}
					<AddCommonItem
						title={<Translate id='qualityfailurereport.closeReport' />}
						message={<Translate id='qualityfailurereport.messagecloseReport' />}
						open={this.state.openCloseReport}
						onClose={() => {
							this.setState({ openCloseReport: false })
						}}
						onSaveItem={this.closedIsNotAttributableToTheSupplier}
						isSaving={this.props.isSaving}
						isMessage={true}
					/>
				</Grid>
			)
		}
	}

	/**
	 * Render hystoryCard for quality failure notification
	 *
	 * @param {*} planningEngineerName
	 * @param {*} developmenEngineerName
	 * @param {*} qualityUsersList
	 * @returns
	 * @memberof Show
	 */
	renderAditionalDataQualityFailure(planningEngineerName, developmenEngineerName, qualityUsersList) {
		return (
			<Grid container spacing={24}>
				<CardHeader
					style={{ paddingTop: '0px' }}
					title={
						<Typography gutterBottom variant='body2' component='p'>
							<strong>
								<Translate id='common.sentTo' />:
							</strong>
						</Typography>
					}
					subheader={
						<Typography gutterBottom variant='body2' component='p'>
							<Tooltip title={<Translate id='rawmaterialnonconformity.planningEnginner' />}>
								<PersonIcon color='secondary' />
							</Tooltip>
							{' ' + planningEngineerName}
							<br />
							<Tooltip title={<Translate id='rawmaterialnonconformity.developmentEnginner' />}>
								<PersonIcon color='secondary' />
							</Tooltip>
							{' ' + developmenEngineerName}
						</Typography>
					}
				/>
				<Grid item xs={12}>
					<Typography gutterBottom variant='body2' component='p'>
						<strong>
							{this.props.qualityFailure.company_identifier !== COMPANY_PTM && (
								<Translate id='rawmaterialnonconformity.qualityUsersSupplier' />
							)}
							{this.props.qualityFailure.company_identifier === COMPANY_PTM &&
								'Usuarios de calidad del proveedor y usuarios internos para notificar'}
						</strong>
					</Typography>
					<DataTable
						data={qualityUsersList}
						configuration={{
							columns: [
								{
									header: <Translate id='officialNotices.name' />,
									dataSource: 'full_name',
								},
							],
						}}
					/>
				</Grid>
			</Grid>
		)
	}

	/**
	 * Render hystoryCard for Deviations
	 *
	 * @param {*} folio
	 * @param {*} status
	 * @returns
	 * @memberof Show
	 */
	renderAditionalDataDeviation(document) {
		return (
			<Grid container spacing={24}>
				<CardHeader
					style={{ paddingTop: '0px' }}
					title={
						<Typography gutterBottom variant='body2' component='p'>
							<strong>
								<Translate id='rawmaterialnonconformity.deviation' />:
							</strong>
						</Typography>
					}
					subheader={
						<React.Fragment>
							<Typography gutterBottom variant='body2' component='p'>
								<Translate id='common.folio' />:<strong> {' ' + document.folio}</strong>
								<br />
							</Typography>

							{document.status && (
								<p>
									{(document.status === 1 || document.status === 0) && (
										<p>
											<Tooltip title={<Translate id='common.status' />}>
												<ProcessIcon color='primary' />
											</Tooltip>{' '}
											<Translate id='common.statusFolio' />: <Translate id='common.inProcess' />
											<br />
											<br />
											{!this.props.qualityFailure.is_closed && (
												<Button
													variant='contained'
													onClick={() => {
														this.onUpdateStatusDeviation(document.token)
													}}
													disabled={this.props.isSaving}
													color='secondary'
												>
													<ProcessIcon /> {' - '}{' '}
													<Translate id='rawmaterialnonconformity.updateStatusDeviation' />
												</Button>
											)}
										</p>
									)}
									{document.status === 2 && (
										<p>
											<Tooltip title={<Translate id='common.status' />}>
												<AceptedIcon color='secondary' />
											</Tooltip>{' '}
											<Translate id='common.statusFolio' />: <Translate id='common.auhtorized' />
										</p>
									)}
									{document.status === 3 && (
										<p>
											<Tooltip title={<Translate id='common.status' />}>
												<RejectedIcon color='error' />
											</Tooltip>{' '}
											<Translate id='common.statusFolio' />: <Translate id='common.reject' />
										</p>
									)}
								</p>
							)}
						</React.Fragment>
					}
				/>
			</Grid>
		)
	}

	/**
	 * Render hystory section for all documents
	 *
	 * @returns
	 * @memberof Show
	 */
	renderHistory() {
		let documents = this.props.qualityFailure
			? this.props.qualityFailure.documents
				? this.props.qualityFailure.documents
				: []
			: []
		return documents.map((document) => {
			let titleId
			let aditionalData
			if (document.document_type === FAILURE_REPORT_DOCUMENT_NOTIFICATION) {
				titleId = 'qualityfailurereport.qualityFailureNotification'
				aditionalData = this.renderAditionalDataQualityFailure(
					document.planning_engineer_name,
					document.development_engineer_name,
					document.users_quality
				)
			}
			if (document.document_type === FAILURE_REPORT_CORRECTIVE_ACTIONS_IMPROVEMENT_REQUEST) {
				titleId = 'common.requestForwardedSupplier'
			}
			if (document.document_type === DEVIATION_AUTHORIZATION) {
				titleId = 'rawmaterialnonconformity.authorizationDeviation'
				aditionalData = this.renderAditionalDataDeviation(document)
			}
			if (document.document_type === WORKFLOW_CANCELLATION) {
				titleId = 'common.folioCancel'
				aditionalData = <WorkflowCancel document={document} />
			}
			return (
				<HistoryCard
					key={document.token}
					title={titleId}
					userCreator={document.create_by}
					creationDate={document.creation_date_long}
					aditionalData={aditionalData}
				/>
			)
		})
	}

	/**
	 * Add or update corrective action data
	 *
	 * @param {*} data
	 * @param {*} isUpdate
	 * @returns
	 * @memberof Show
	 */
	saveCorrectiveAction(data, isUpdate) {
		if (!isUpdate) {
			return this.props
				.addCorrectiveActions(data)
				.then(() => {
					this.setState({
						showToView: true,
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: <Translate id='common.saveCorrectly' />,
					})
					return Promise.resolve('Ok')
				})
				.catch(() => {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
					return Promise.reject()
				})
		} else {
			return this.props
				.updateDataCorrectiveAction(data)
				.then(() => {
					this.setState({
						showToView: true,
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: <Translate id='common.saveCorrectly' />,
					})
					return Promise.resolve('Ok')
				})
				.catch(() => {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
					return Promise.reject()
				})
		}
	}

	/**
	 * Preform render react life cycle
	 *
	 * @returns
	 * @memberof Show
	 */
	render() {
		let optonsAvalinable = this.props.qualityFailure ? this.props.qualityFailure.get_available_operations : []
		let companyCanBeDelete =
			this.props.company.document_identifier !== COMPANY_IMBERA
				? canUserCreateReportModuleQuality(this.props.userRolesByCompany)
				: canUserCloseModuleQuality(this.props.userRolesByCompany)
		return (
			<Card>
				{optonsAvalinable.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

				<LayoutHeaderWorkFlow
					{...this.props}
					workFlow={this.props.qualityFailure}
					title={<Translate id='qualityfailurereport.titleReport' />}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{this.renderHistory()}

				<DialogAvailableOperations
					{...this.props}
					options={optonsAvalinable}
					isValidToCancel={canUserValideModuleQuality(this.props.userRolesByCompany)}
					isValidToDelete={companyCanBeDelete}
					openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
					setOpenDialog={this.setOpenDialog}
					onClickEditFailureReportQualirtInDraft={this.onClickEditFailureReportQualirtInDraft}
					onClickToSendNotificationQualityReport={this.onClickSendNotificationQualityFailureReport}
					onClosedIsNotAttributableToTheSupplier={this.onClosedIsNotAttributableToTheSupplier}
					onClickCreateDevolutionFailure={this.onClickCreateDevolutionFailure}
					onClickCreateReworkFailure={this.onClickCreateReworkFailure}
					onUdateStatusToWaitingForCompleteTheContainment={
						this.onUdateStatusToWaitingForCompleteTheContainment
					}
					onAddEmergentActionInFailure={this.onClickAddEmergentAction}
					onRegisterWarehouseEntryInFailure={this.onClickAddRegisterWarehouseEntry}
					onClickCloseReport={this.onClickCloseReport}
					onAddCorrectiveActionRequest={this.onAddCorrectiveActionRequest}
					onDeleteDraft={this.onDeleteDraft}
					onClickRegisterFolioDeviation={this.onClickRegisterFolioDeviation}
					onCancelDocument={this.onCancelDocument}
				/>

				<CreateCorrectiveActionDialog
					isSaving={this.props.isSaving}
					open={this.state.openModalAddCorrectiveAction}
					folio={this.props.qualityFailure ? this.props.qualityFailure.folio : ''}
					onClose={() => this.handleOpenCloseItemDialog('openModalAddCorrectiveAction')}
					saveCorrectiveAction={this.saveCorrectiveAction}
					classes={this.props.classes}
					item={this.state.correctiveActionItem}
				/>

				{/** Dialog to delete draft */}
				<AddCommonItem
					title={<Translate id='qualityfailurereport.removeFailure' />}
					message={<Translate id='qualityfailurereport.removeMotive' />}
					open={this.state.openDeleteDraft}
					onClose={() => {
						this.setState({ openDeleteDraft: false })
					}}
					onSaveItem={this.onDeleteDraftFailureReport}
					isSaving={this.props.isSaving}
					isTextArea={true}
				/>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let qualityFailure = state.qualityFailureReport.get('qualityFailureReport')
	let selectedCompany = state.profile.get('selectedCompany')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		currentUser: user,
		isLoading: state.qualityFailureReport.get('isLoading'),
		isSaving: state.qualityFailureReport.get('isSaving'),
		qualityFailure: qualityFailure ? qualityFailure.toJS() : null,
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		company: selectedCompany ? selectedCompany.toJS() : null,
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadQualityFailureReport: (token) => {
			return dispatch(loadQualityFailureReportFromBackEnd(token))
		},
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		udateStatusToWaitingForCompleteTheContainment: (token) => {
			return dispatch(udateStatusToWaitingForCompleteTheContainment(token))
		},
		addEmergentAction: (description) => {
			return dispatch(addEmergentActionToQualityFailureReport(description))
		},
		registerWarehouseEntry: (folio) => {
			return dispatch(registerWarehouseEntryFromBackend(folio))
		},
		closedIsNotAttributableToTheSupplier: (token) => {
			return dispatch(closedIsNotAttributableToTheSupplier(token))
		},
		addCorrectiveActions: (data) => {
			return dispatch(addCorrectiveActions(data))
		},
		acceptCorrectiveActions: (item) => {
			return dispatch(acceptCorrectiveActions(item))
		},
		updateDataCorrectiveAction: (data) => {
			return dispatch(updateDataCorrectiveAction(data))
		},
		uploadFiles: (files) => {
			return dispatch(uploadFilesEvidence(files))
		},
		removeFiles: (fileName) => {
			return dispatch(removeFilesEvidence(fileName))
		},
		removeEmergentActionToQualityFailureReport: (token) => {
			return dispatch(removeEmergentActionToQualityFailureReport(token))
		},
		deleteDraftFailureReport: (token, removeMotive) => {
			return dispatch(deleteDraftFailureReport(token, removeMotive))
		},
		updateStatusDeviation: (tokenDeviation) => {
			return dispatch(updateStatusDeviation(tokenDeviation))
		},
		getDeliveryOrderByPartNumberCertificate: (supplierToken, partNumber, lote) => {
			return dispatch(getDeliveryOrderByPartNumberCertificate(supplierToken, partNumber, lote))
		},
	}
}

const styles = (theme) => ({
	actionsToolbar: {
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
	inline: {
		display: 'inline',
	},
	logoCompany: {
		maxHeight: 100,
	},
	logoSupplier: {
		marginLeft: 80,
		maxHeight: 80,
	},
	styleTab: {
		backgroundColor: '#fff',
	},
	margin: {
		margin: theme.spacing.unit * 2,
	},
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
