/** Import ReactJS */
import React from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
/** Material UI import section */
import { withStyles } from '@material-ui/core/styles'
/**Import section components */

import { Button, Grid, ListItem, ListItemText, Typography } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import SupplierSelector from '../../../common/SupplierSelector'
//Import alert section
import { loadAdministratorSupplier } from '../../../../store/suppliers/ExternalUserActions'
/** Resources import section */
const useStyle = (theme) => ({})

const PreviousOriginComponent = (props) => {
	const dispatch = useDispatch()
	//function props section
	const { updateLoadAlertItemProperty, onSendNotificationToSupplier } = props
	//object props section
	const { item } = props
	//bool props section
	const { canBeEditInternalUserData, title, generalData } = props

	const onSelectSupplierChange = (supplier) => {
		return dispatch(loadAdministratorSupplier(`${supplier ? supplier.code : ''}-admin`)).then((data) => {
			let objectProperties = {
				SupplierCode: supplier.code,
				SupplierName: supplier.name,
				SupplierEmail: data.email,
				token: item.token,
			}
			updateLoadAlertItemProperty(objectProperties)
		})
	}

	return (
		<Grid container>
			{!generalData && (
				<Grid item xs={2}>
					<ListItem>
						<ListItemText
							secondary={
								<React.Fragment>
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<Typography
											component='span'
											variant='body2'
											color='textPrimary'
											style={{ whiteSpace: 'nowrap' }}
										>
											<strong>{title}</strong>
										</Typography>
									</div>
								</React.Fragment>
							}
						/>
					</ListItem>
				</Grid>
			)}
			{canBeEditInternalUserData && (
				<Grid item xs={generalData ? 5 : 3}>
					{generalData && (
						<Typography
							component='span'
							variant='body2'
							color='textPrimary'
							style={{ whiteSpace: 'nowrap' }}
						>
							<strong>{title}</strong>
						</Typography>
					)}
					<SupplierSelector onSupplierSelected={(supplier) => onSelectSupplierChange(supplier)} />
				</Grid>
			)}
			<Grid item xs={6}>
				<ListItem>
					<ListItemText
						secondary={
							<React.Fragment>
								{generalData && (
									<Typography
										component='span'
										variant='body2'
										color='textPrimary'
										style={{ whiteSpace: 'nowrap' }}
									>
										<strong>{title}</strong>
									</Typography>
								)}
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{item && (
										<Typography color='textPrimary'>
											{`${item.supplierCode}-${item.supplierName}`}
										</Typography>
									)}
								</div>
							</React.Fragment>
						}
					/>
				</ListItem>
			</Grid>
			{canBeEditInternalUserData && (
				<Grid item xs={1}>
					<Button
						variant='contained'
						color='primary'
						disabled={!item.canBeSendAlert}
						onClick={() => onSendNotificationToSupplier(item.token)}
					>
						<Send style={{ margin: '0px' }} />
					</Button>
				</Grid>
			)}
		</Grid>
	)
}

PreviousOriginComponent.propTypes = {}

PreviousOriginComponent.defaultProps = {}
export default withRouter(withStyles(useStyle, { withTheme: true })(PreviousOriginComponent))
