/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
/**Import MaterialUi section */
import {
    Grid, Avatar, Button, TextField, Typography, InputAdornment
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import Tour from '../../../common/Tour'
import WarningComponent from '../../../common/WarningComponent'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import {
    GetBiddingEvaluationCriteriaType,
    GetBiddingEvaluationRuleType, GetNumberFormatsOptions,
    getOptionsGroupReactSelect,
    GetBiddingBoolEvaluationRuleType, getMeasurementsOptions,
    GetCriteriaOptionsType
} from '../../../../store/helpers/SelectOptions'
import {
    CriteriaType, NumberFormatType, ScopeType
} from '../../../../store/helpers/AppConstants'
import { getCriteriaGroup, getTotalCompleted, getTotalPercentage } from '../../../../store/biddings/BindingsSelectors'
import { StepsForAddCriteriaHelper } from '../../../../store/helpers/TourHelper'
const AddEvaluationCriterialLateralPanel = (props) => {
    const CHARACTER_LIMIT_DESCRIPTION = 250;
    /**props section */
    const { onCloseButtonClick, onSaveButtonClick,
        isSaving, classes, translate, bidding, measurementunits
    } = props

    const [biddingModel, setBiddingModel] = useState({
        criteriaType: null,
        evaluationRule: null,
        comments: "",
        name: "",
        weighing: "",
        criteria: null,
        minimumRange: null,
        maximumRange: null,
        numberFormat: "",
        measurementUnit: null,
        grouper: null
    });
    const [errors, setErrors] = useState({});
    const [isVisibleTour, setIsVisibleTour] = useState(false);
    const groups = getCriteriaGroup(bidding);
    let options = getOptionsGroupReactSelect(groups)
    let measurementOptions = getMeasurementsOptions(measurementunits)

    /**Use effect section */
    useEffect(() => {
        if ((biddingModel.grouper === null) && props.bidding) {
            const groups = getCriteriaGroup(bidding);
            const optionsReact = getOptionsGroupReactSelect(groups);
            let grouper = optionsReact.find(x => x.label === 'Precio')
            setBiddingModel({
                ...biddingModel,
                grouper: grouper ? grouper : null
            })
        }
    }, [])

    const onSelectedChange = (name, option) => {
        if (name === "criteriaType") {
            setBiddingModel({
                ...biddingModel,
                [name]: option,
                "evaluationRule": null
            });
        }
        else if (name === "numberFormat") {
            setBiddingModel({
                ...biddingModel,
                [name]: option,
                "minimumRange": "",
                "maximumRange": "",
            });
            setErrors({})
        }
        else {
            setBiddingModel({
                ...biddingModel,
                [name]: option
            });
        }
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            biddingModel.CategoryName = bidding ? bidding.CategoryName : ""
            onSaveButtonClick(biddingModel)
        }
    }

    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (IsNullOrEmpty(biddingModel.weighing)) return false
        if (IsNullOrEmpty(biddingModel.name)) return false
        if (biddingModel.criteria === null) return false
        if (biddingModel.criteriaType === null) return false
        if (biddingModel.grouper === null) return false
        if (biddingModel && biddingModel.criteriaType && biddingModel.criteriaType.value === CriteriaType.NUMERIC) {
            if (biddingModel.measurementUnit === null) return false
            if (IsNullOrEmpty(biddingModel.numberFormat)) return false
            if (biddingModel.evaluationRule === null) return false
        }
        if (biddingModel && biddingModel.criteriaType && biddingModel.criteriaType.value === CriteriaType.BOOLEAN) {
            if (biddingModel.evaluationRule === null) return false
        }
        if (biddingModel && biddingModel.criteriaType && biddingModel.criteriaType.value === CriteriaType.MONEY) {
            if (biddingModel.evaluationRule === null) return false
        }
        return true;
    }

    const validate = data => {
        const errors = {};
        if (data.name === "weighing" && (data.value < 0 || data.value > 100)) {
            errors.weighing = translate("biddingsModule.percentajeValue")
        }
        if (data.name === "maximumRange" && (biddingModel.numberFormat && biddingModel.numberFormat.value === NumberFormatType.PERCENT) && (data.value < 0 || data.value > 100)) {
            errors.weighing = translate("biddingsModule.percentajeValue")
        }
        if (data.name === "minimumRange" && (biddingModel.numberFormat && biddingModel.numberFormat.value === NumberFormatType.PERCENT) && (data.value < 0 || data.value > 100)) {
            errors.weighing = translate("biddingsModule.percentajeValue")
        }
        if ((data.name === 'minimumRange' && !IsNullOrEmpty(data.value) && (biddingModel.numberFormat && biddingModel.numberFormat.value === NumberFormatType.NUMBER) && !IsNullOrEmpty(biddingModel.minimumRange))) {
            let regexQuantity = /^([0-9]+$)/;
            if (!regexQuantity.test(data.value)) return;
        }
        if ((data.name === 'maximumRange' && !IsNullOrEmpty(data.value) && (biddingModel.numberFormat && biddingModel.numberFormat.value === NumberFormatType.NUMBER))) {
            let regexQuantity = /^([0-9]+$)/;
            if (!regexQuantity.test(data.value)) return;
        }
        if (biddingModel.numberFormat && biddingModel.numberFormat.value === NumberFormatType.NUMBER) {
            if ((data.name === 'minimumRange' && parseInt(data.value) >= biddingModel.maximumRange)) {
                if (!IsNullOrEmpty(biddingModel.maximumRange) && !IsNullOrEmpty(data.value))
                    errors.minimumRange = `${translate("biddingsModule.valueGreaterThan")} ${biddingModel.maximumRange}`
            }
            if ((data.name === 'maximumRange' && parseInt(data.value) <= biddingModel.minimumRange)) {
                if (!IsNullOrEmpty(biddingModel.minimumRange) && !IsNullOrEmpty(data.value))
                    errors.maximumRange = `${translate("biddingsModule.valueLessThan")} ${biddingModel.minimumRange}`
            }
        } else {
            if ((data.name === 'minimumRange' && parseFloat(data.value) >= (biddingModel.maximumRange ? parseFloat(biddingModel.maximumRange) : null))) {
                if (!IsNullOrEmpty(biddingModel.maximumRange) && !IsNullOrEmpty(data.value))
                    errors.minimumRange = `${translate("biddingsModule.valueGreaterThan")} ${biddingModel.maximumRange}`
            }
            if ((data.name === 'maximumRange' && parseFloat(data.value) <= (biddingModel.minimumRange ? parseFloat(biddingModel.minimumRange) : null))) {
                if (!IsNullOrEmpty(biddingModel.minimumRange) && !IsNullOrEmpty(data.value))
                    errors.maximumRange = `${translate("biddingsModule.valueLessThan")} ${biddingModel.minimumRange}`
            }
        }
        return errors;
    };


    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        const errors = validate(event.target);
        setErrors(errors);
        if (name === 'weighing' && (value < 0 || value > 100))
            return
        if (name === 'weighing' && !IsNullOrEmpty(value)) {
            let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (!regexQuantity.test(value)) return;
        }
        if (name === 'maximumRange' && (biddingModel.numberFormat && biddingModel.numberFormat.value === NumberFormatType.PERCENT) && (value < 0 || value > 100))
            return
        if (name === 'minimumRange' && (biddingModel.numberFormat && biddingModel.numberFormat.value === NumberFormatType.PERCENT) && (value < 0 || value > 100))
            return
        if (!IsNullOrEmpty(value) && (name === 'minimumRange' && (biddingModel.numberFormat && biddingModel.numberFormat.value === NumberFormatType.NUMBER))) {
            let regexQuantity = /^([0-9]+$)/;
            if (!regexQuantity.test(value)) return;
        }
        if (!IsNullOrEmpty(value) && (name === 'maximumRange' && (biddingModel.numberFormat && biddingModel.numberFormat.value === NumberFormatType.NUMBER))) {
            let regexQuantity = /^([0-9]+$)/;
            if (!regexQuantity.test(value)) return;
        }
        if ((name === 'minimumRange' && !IsNullOrEmpty(value) && (biddingModel.numberFormat && (biddingModel.numberFormat.value === NumberFormatType.PERCENT || biddingModel.numberFormat.value === NumberFormatType.DECIMAL)) && !IsNullOrEmpty(biddingModel.minimumRange))) {
            let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (!regexQuantity.test(value)) return;
        }
        if ((name === 'maximumRange' && !IsNullOrEmpty(value) && (biddingModel.numberFormat && (biddingModel.numberFormat.value === NumberFormatType.PERCENT || biddingModel.numberFormat.value === NumberFormatType.DECIMAL)))) {
            let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (!regexQuantity.test(value)) return;
        }
        setBiddingModel({
            ...biddingModel,
            [name]: value
        });
    }

    const onChangeCurrencyFormat = (name, value) => {
        const errors = {};
        if (name === 'minimumRange' && value >= biddingModel.maximumRange) {
            errors.minimumRange = `${translate("biddingsModule.valueGreaterThan")} ${biddingModel.maximumRange}`
        }
        if (name === 'maximumRange' && value <= biddingModel.minimumRange) {
            errors.maximumRange = `${translate("biddingsModule.valueLessThan")} ${biddingModel.minimumRange}`
        }
        setErrors(errors);
        setBiddingModel({
            ...biddingModel,
            [name]: value
        });
    }

    /**On change properties */
    const onGetMaximumTextFiel = (numberFormat) => {
         //Determina if is open tour
         if(isVisibleTour){
            numberFormat = NumberFormatType.NUMBER
        }
        switch (numberFormat) {
            case NumberFormatType.CURRENCY:
                return <CurrencyTextField
                    data-tut="reactour-maximumRange"
                    label={translate("biddingsModule.valueMax")}
                    value={biddingModel ? biddingModel.maximumRange : 0}
                    currencySymbol="$"
                    onChange={(e, value) => onChangeCurrencyFormat('maximumRange', value)}
                    helperText={(errors && errors.maximumRange) ? errors.maximumRange : ""}
                    error={errors && Object.entries(errors).length !== 0 && errors.maximumRange}
                    decimalCharacter="."
                    digitGroupSeparator=","
                    variant='outlined'
                    fullWidth
                />;
            case NumberFormatType.DECIMAL:
                return <TextField
                    data-tut="reactour-maximumRange"
                    fullWidth
                    error={errors && Object.entries(errors).length !== 0 && errors.maximumRange}
                    label={translate("biddingsModule.rangeMax")}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    type='number'
                    step={0.5}
                    name='maximumRange'
                    margin="dense"
                    variant="outlined"
                    value={biddingModel ? biddingModel.maximumRange : 0}
                    helperText={(errors && errors.maximumRange) ? errors.maximumRange : ""}
                />
            case NumberFormatType.NUMBER:
                return <TextField
                    data-tut="reactour-maximumRange"
                    fullWidth
                    type='number'
                    step={0.5}
                    error={errors && Object.entries(errors).length !== 0 && errors.maximumRange}
                    helperText={(errors && errors.maximumRange) ? errors.maximumRange : ""}
                    label={translate("biddingsModule.rangeMax")}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    name='maximumRange'
                    margin="dense"
                    variant="outlined"
                    value={biddingModel ? biddingModel.maximumRange : 0}
                />
            case NumberFormatType.PERCENT:
                return <TextField
                    data-tut="reactour-maximumRange"
                    fullWidth
                    error={errors && Object.entries(errors).length !== 0 && errors.maximumRange}
                    label={translate("biddingsModule.rangeMax")}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    step={0.5}
                    name='maximumRange'
                    margin="dense"
                    type='number'
                    variant="outlined"
                    value={biddingModel ? biddingModel.maximumRange : ""}
                    helperText={(errors && errors.maximumRange) ? errors.maximumRange : ""}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
            default:
                return null
        }

    }

    /**On change properties */
    const onGetMinimumTextFiel = (numberFormat) => {
        //Determina if is open tour
        if(isVisibleTour){
            numberFormat = NumberFormatType.NUMBER
        }
        switch (numberFormat) {
            case NumberFormatType.CURRENCY:
                return <CurrencyTextField
                    data-tut="reactour-minimumRange"
                    label={translate("biddingsModule.valueMin")}
                    value={biddingModel ? biddingModel.minimumRange : 0}
                    currencySymbol="$"
                    onChange={(e, value) => onChangeCurrencyFormat('minimumRange', value)}
                    error={errors && Object.entries(errors).length !== 0 && errors.minimumRange}
                    helperText={(errors && errors.minimumRange) ? errors.minimumRange : ""}
                    decimalCharacter="."
                    digitGroupSeparator=","
                    variant='outlined'
                    fullWidth
                />;
            case NumberFormatType.DECIMAL:
                return <TextField
                    fullWidth
                    data-tut="reactour-minimumRange"
                    label={translate("biddingsModule.rangeMin")}
                    error={errors && Object.entries(errors).length !== 0 && errors.minimumRange}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    type='number'
                    step={0.5}
                    name='minimumRange'
                    margin="dense"
                    variant="outlined"
                    value={biddingModel ? biddingModel.minimumRange : 0}
                    helperText={(errors && errors.minimumRange) ? errors.minimumRange : ""}
                />
            case NumberFormatType.NUMBER:
                return <TextField
                    data-tut="reactour-minimumRange"
                    fullWidth
                    error={errors && Object.entries(errors).length !== 0 && errors.minimumRange}
                    label={translate("biddingsModule.rangeMin")}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    type='number'
                    step={0}
                    minimumValue={0}
                    name='minimumRange'
                    margin="dense"
                    variant="outlined"
                    value={biddingModel ? biddingModel.minimumRange : 0}
                    helperText={(errors && errors.minimumRange) ? errors.minimumRange : ""}
                />
            case NumberFormatType.PERCENT:
                return <TextField
                    data-tut="reactour-minimumRange"
                    fullWidth
                    error={errors && Object.entries(errors).length !== 0 && errors.minimumRange}
                    label={translate("biddingsModule.rangeMin")}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    step={0.5}
                    name='minimumRange'
                    margin="dense"
                    type='number'
                    variant="outlined"
                    value={biddingModel ? biddingModel.minimumRange : ""}
                    helperText={(errors && errors.minimumRange) ? errors.minimumRange : ""}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
            default:
                return null
        }

    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<Translate id="biddingsModule.evaluationCriteria.title" />}
                subTitlePanel={`${translate("biddingsModule.table.folio")} | ${props.bidding ? props.bidding.FormatedFolio : ""}`}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >
                        {
                            !getTotalCompleted(groups) && <WarningComponent
                                bidding={bidding}
                                title={translate("biddingsModule.warning")}
                                message={`${translate("biddingsModule.percentajeTotal")} ${getTotalPercentage(groups)}, ${translate("biddingsModule.percentajeTotalAjust")}`}
                            />
                        }

                        <Grid item xs={9}>
                            <Typography color='primary' variant='subtitle2' style={{ marginTop: "15px" }}>{translate("biddingsModule.selectedCriteria")}</Typography>
                        </Grid>

                        <Grid item xs={3} className={classes.containerBtnTour}>
                            <Tour
                                steps={StepsForAddCriteriaHelper()}
                                setIsVisibleTour={setIsVisibleTour}
                            />
                        </Grid>
                        <Grid item xs={12} data-tut="reactour-criteria">
                            <Select
                                placeholder={translate("biddingsModule.selectedCriteria")}
                                options={GetCriteriaOptionsType(bidding, false)}
                                onChange={(item) => onSelectedChange('criteria', item)}
                                value={biddingModel ? biddingModel.criteria : null}
                                isOptionDisabled={(option) => option.isOptionDisabled}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10 }),
                                    menuList: (base) => ({ ...base, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                    control: base => ({
                                        ...base,
                                        height: 50,
                                        minHeight: 50,
                                    })
                                }}
                            />
                        </Grid>
                        {
                            (biddingModel && biddingModel.criteria && biddingModel.criteria.value === ScopeType.ITEM) &&
                            <Grid item xs={12}>

                                <Typography variant='subtitle2' align="justify" className={classes.gridMarginTop}>
                                    {translate("biddingsModule.criteriaService")}
                                    {translate("biddingsModule.supplierResponseByItem")}
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {translate("biddingsModule.examplePrice")}
                                </Typography>
                            </Grid>
                        }
                        {
                            (biddingModel && biddingModel.criteria && biddingModel.criteria.value === ScopeType.GENERAL) &&
                            <Grid item xs={12}>

                                <Typography variant='subtitle2' align="justify" className={classes.gridMarginTop}>
                                    {translate("biddingsModule.selectedCriteriaGeneral")}
                                    {translate("biddingsModule.supplierAnswered")}
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {translate("biddingsModule.exampleCreditDays")}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12} data-tut="reactour-name-criteria">
                            <TextField
                                fullWidth
                                label={<Translate id="biddingsModule.services.name" />}
                                className={classes.textField}
                                onChange={onPropertyChange}
                                multiline
                                name='name'
                                margin="dense"
                                variant="outlined"
                                value={biddingModel ? biddingModel.name : ""}
                            />

                        </Grid>
                        <Grid item xs={12} data-tut="reactour-weighing">
                            <TextField
                                fullWidth
                                error={errors && Object.entries(errors).length !== 0 && errors.weighing}
                                label={<Translate id="biddingsModule.services.table.weighing" />}
                                className={classes.textField}
                                onChange={onPropertyChange}
                                step={0.5}
                                name='weighing'
                                margin="dense"
                                type='number'
                                variant="outlined"
                                value={biddingModel ? biddingModel.weighing : ""}
                                helperText={(errors && errors.weighing) ? errors.weighing : ""}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} data-tut="reactour-grouper">
                            <Typography color='primary' variant='subtitle2'><Translate id="biddingsModule.evaluationCriteria.grouper" /></Typography>
                            <CreatableSelect
                                formatCreateLabel={userInput => `Crear ${userInput}`}
                                placeholder={<Translate id="biddingsModule.evaluationCriteria.grouper" />}
                                options={options}
                                onChange={(item) => onSelectedChange('grouper', item)}
                                value={biddingModel ? biddingModel.grouper : null}
                                isOptionDisabled={(option) => option.isdisabled}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10 }),
                                    menuList: (base) => ({ ...base, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                    control: base => ({
                                        ...base,
                                        height: 50,
                                        minHeight: 50,
                                    })
                                }}
                            /><br />
                        </Grid>

                        <Grid item xs={12} data-tut="reactour-criteriaType">
                            <Typography color='primary' variant='subtitle2'><Translate id="biddingsModule.evaluationCriteria.criteriaType" /></Typography>
                            <Select
                                placeholder={<Translate id="biddingsModule.evaluationCriteria.criteriaType" />}
                                options={GetBiddingEvaluationCriteriaType()}
                                onChange={(item) => onSelectedChange('criteriaType', item)}
                                value={biddingModel ? biddingModel.criteriaType : null}
                                isOptionDisabled={(option) => option.isdisabled}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10 }),
                                    menuList: (base) => ({ ...base, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                    control: base => ({
                                        ...base,
                                        height: 50,
                                        minHeight: 50,
                                    })
                                }}
                            /><br />
                        </Grid>

                        {
                            ((biddingModel && biddingModel.criteriaType &&
                                biddingModel.criteriaType.value === CriteriaType.NUMERIC) || isVisibleTour) &&
                            <>
                                <Grid item xs={12} data-tut="reactour-measurementUnit">
                                    <Typography color='primary' variant='subtitle2'><Translate id="biddingsModule.services.measurementUnit" /></Typography>
                                    <CreatableSelect
                                        placeholder={<Translate id="biddingsModule.services.measurementUnit" />}
                                        options={measurementOptions}
                                        onChange={(item) => onSelectedChange('measurementUnit', item)}
                                        value={biddingModel ? biddingModel.measurementUnit : null}
                                        styles={{
                                            menu: (base) => ({ ...base, zIndex: 10 }),
                                            menuList: (base) => ({ ...base, paddingTop: 0 }),
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                        }}
                                    />
                                    <br />
                                </Grid>
                                <Grid item xs={12} data-tut="reactour-format">
                                    <Typography color='primary' variant='subtitle2'><Translate id="biddingsModule.numberFormat.format" /></Typography>
                                    <Select
                                        placeholder={<Translate id="biddingsModule.numberFormat.format" />}
                                        options={GetNumberFormatsOptions()}
                                        onChange={(item) => onSelectedChange('numberFormat', item)}
                                        value={biddingModel ? biddingModel.numberFormat : null}
                                        isOptionDisabled={(option) => option.isdisabled}
                                        styles={{
                                            menu: (base) => ({ ...base, zIndex: 10 }),
                                            menuList: (base) => ({ ...base, paddingTop: 0 }),
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                        }}
                                    /><br />
                                </Grid>
                            </>
                        }

                        {
                            ((biddingModel.criteriaType && (biddingModel.criteriaType.value === CriteriaType.NUMERIC || biddingModel.criteriaType.value === CriteriaType.MONEY)) || isVisibleTour) &&
                            <Grid item xs={12} data-tut="reactour-rule1">
                                <Typography color='primary' variant='subtitle2'><Translate id="biddingsModule.evaluationCriteria.rule" /></Typography>

                                <Select
                                    placeholder={<Translate id="biddingsModule.evaluationCriteria.rule" />}
                                    options={GetBiddingEvaluationRuleType()}
                                    onChange={(item) => onSelectedChange('evaluationRule', item)}
                                    value={biddingModel ? biddingModel.evaluationRule : null}
                                    styles={{
                                        menu: (base) => ({ ...base, zIndex: 10 }),
                                        menuList: (base) => ({ ...base, paddingTop: 0 }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                    }}
                                />
                            </Grid>
                        }

                        {
                            biddingModel && biddingModel.criteriaType &&
                            biddingModel.criteriaType.value === CriteriaType.BOOLEAN &&
                            <Grid item xs={12} data-tut="reactour-rule1">
                                <Typography color='primary' variant='subtitle2'><Translate id="biddingsModule.evaluationCriteria.rule" /></Typography>

                                <Select
                                    placeholder={<Translate id="biddingsModule.evaluationCriteria.rule" />}
                                    options={GetBiddingBoolEvaluationRuleType()}
                                    onChange={(item) => onSelectedChange('evaluationRule', item)}
                                    value={biddingModel ? biddingModel.evaluationRule : null}
                                    styles={{
                                        menu: (base) => ({ ...base, zIndex: 10 }),
                                        menuList: (base) => ({ ...base, paddingTop: 0 }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                    }}
                                />
                            </Grid>
                        }

                        {
                            ((
                                (biddingModel && biddingModel.criteriaType &&
                                    biddingModel.criteriaType.value === CriteriaType.NUMERIC
                                ) && biddingModel && biddingModel.numberFormat) || isVisibleTour)
                            && <>
                                <Grid item xs={12} className={classes.gridMarginTop}>
                                    {onGetMinimumTextFiel(biddingModel.numberFormat ? biddingModel.numberFormat.value : null)}
                                </Grid>
                                <Grid item xs={12} className={classes.gridMarginTop}>
                                    {onGetMaximumTextFiel(biddingModel.numberFormat ? biddingModel.numberFormat.value : null)}
                                </Grid>
                            </>
                        }

                        <Grid item xs={12} data-tut="reactour-comments" className={classes.gridMarginTop}>
                            <TextField
                                fullWidth
                                label={<Translate id="common.comments" />}
                                className={classes.textField}
                                onChange={onPropertyChange}
                                multiline
                                name='comments'
                                margin="dense"
                                variant="outlined"
                                value={biddingModel ? biddingModel.comments : 0}
                                inputProps={{ maxLength: CHARACTER_LIMIT_DESCRIPTION }}
                                helperText={`${biddingModel ? biddingModel.comments.length : 0}/${CHARACTER_LIMIT_DESCRIPTION}`}
                            /><br />

                        </Grid>

                    </Grid>

                </div>
                <Grid container >
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-tut="reactour-save"
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            disabled={!canBeSaved() || isSaving || (errors && Object.entries(errors).length !== 0)}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>

            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "20px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    containerBtnTour: {
        display: "flex",
        justifyContent: "flex-end"
    },
    gridMarginTop: {
        marginTop: "10px",
    }

});

AddEvaluationCriterialLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddEvaluationCriterialLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddEvaluationCriterialLateralPanel))
