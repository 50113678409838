//Import react section
import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
//Import Mui section
import { Group } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

//Impoprt components section
import CatalogHeader from './components/CatalogHeader'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import LoadingPage from '../../common/LoadingPage'
import DataTable from '../../common/DataTable'
import DialogCatalogUploadMassive from './components/DialogCatalogUploadMassive'
//Import store section
import { getManeuverTypeByNumber, isTransportTenderAdministrator } from '../../../store/helpers/TransportTenderHelper'
import { getLoggedUserSelector } from '../../../store/profile/ProfileSelectors'
import * as selector from '../../../store/transportTender/transportTenderSelector'
import * as action from '../../../store/transportTender/transportTenderAction'

const Maneuver = (props) => {
	const { classes } = props
	let history = useHistory()
	let location = useLocation()
	const dispatch = useDispatch()

	const [openDialog, setOpenDialog] = useState(false)
	const [file, setfile] = useState([])

	const queryParams = queryString.parse(location.search)
	const loggetUser = useSelector((state) => getLoggedUserSelector(state))
	const isLoadig = useSelector((state) => selector.getIsLoadingSavingSelector(state))
	const transportTenderinfo = useSelector((state) => selector.getTransportTenderfilterSelector(state))
	const ErrorsManeuversFile = useSelector((state) => selector.getErrorManeuversFile(state))

	const userRoles = loggetUser && loggetUser.grantedRoles ? loggetUser.grantedRoles : []
	const isTransportTenderAdminitsrator = isTransportTenderAdministrator(userRoles)

	const onChangeQuery = (query) => {
		if (query) queryParams.query = query
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onChangePage = (page, rowsPerPage) => {
		queryParams.pageNumber = page + 1
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}
	useEffect(() => {
		dispatch(action.loadManeuversPaginatedCatalog(queryParams))
	}, [dispatch, location.search])

	useEffect(() => {
		if (file.length > 0) {
			dispatch(action.validateManeuversTemplate(file))
		}
	}, [file])

	const onDownloadTemplateFilled = () => {
		return dispatch(action.downloadManeuversTemplate())
	}
	const canBeSaved = () => {
		if (ErrorsManeuversFile.length > 0) return false
		if (file.length === 0) return false
		return true
	}
	const onSave = () => {
		dispatch(action.importManeuverCatalog(file))
			.then(() => {
				setOpenDialog(false)
				dispatch(action.loadManeuversPaginatedCatalog(queryParams))
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}

	const onDeleteManeuvers = (item) => {
		dispatch(action.deleteSupplierManeuver(item.id)).then(() =>
			dispatch(action.loadManeuversPaginatedCatalog(queryParams))
		)
	}
	const onRenderCellItem = (dataSource, item) => {
		console.log("item", item)
		if (dataSource === 'creationDate')
			return <Typography>{moment(item.creationDate).format('DD/MM/YYYY HH:mm A')}</Typography>
		if (dataSource === 'client') return <Typography>{`${item.clientCode}-${item.clientName}`}</Typography>
		if (dataSource === 'transportZone')
			return <Typography>{`${item.transportZoneCode}-${item.transportZoneName}`}</Typography>
		if (dataSource === 'supplier')
			return <Typography>{item.supplierCode ? `${item.supplierCode}-${item.supplierName}` : ''}</Typography>
		if (dataSource === 'maneuverType')
			return <Typography>{<Translate id={`${getManeuverTypeByNumber(item.maneuverType)}`} />}</Typography>
		if (dataSource === 'isDirectTrip') return item.isDirectTrip ? 'Si' : 'No'
		if (dataSource === 'cCEmails') return item.ccEmails.length !==0 ?item.ccEmails.join():""
		if (dataSource === 'options')
			return (
				<div>
					<IconButton disabled={isLoadig} onClick={() => onDeleteManeuvers(item)}>
						<Delete fontSize='small' color='primary' />
					</IconButton>
				</div>
			)
	}

	return (
		<div className={classes.containerTitle}>
			<CatalogHeader
				setOpenDialog={setOpenDialog}
				icon={<Group />}
				isInternalUse={true}
				title={<Translate id={'carrierTenders.configuration.maneuver'} />}
				isAdministrator={isTransportTenderAdminitsrator}
				showSearch
				queryParams={queryParams}
				onChangeSearchValue={onChangeQuery}
			/>
			{isLoadig ? (
				<LoadingPage />
			) : (
				<DataTable
					data={transportTenderinfo.maneuversPagination.items}
					configuration={TableConfiguration()}
					onRenderCellItem={onRenderCellItem}
					showFilters
					isIndex
					page={transportTenderinfo.maneuversPagination.pageNumber}
					rowsPerPage={transportTenderinfo.maneuversPagination.pageSize}
					totalRows={transportTenderinfo.maneuversPagination.total}
					onChangePage={onChangePage}
				/>
			)}
			{openDialog && (
				<DialogCatalogUploadMassive
					title={'Importación masiva de maniobras'}
					typeLoadTranslate={'massiveManeuvers'}
					open={openDialog}
					onClose={() => setOpenDialog(false)}
					file={file}
					setfile={setfile}
					onDownloadTemplateFilled={onDownloadTemplateFilled}
					isLoading={isLoadig}
					canBeSaved={canBeSaved()}
					errorList={ErrorsManeuversFile}
					onSave={onSave}
				/>
			)}
		</div>
	)
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: <Translate id={'carrierTenders.maneuvers.table.transportZone'} />,
			dataSource: 'transportZone',
		},
		{
			header: <Translate id={'carrierTenders.maneuvers.table.client'} />,
			dataSource: 'client',
		},
		{
			header: <Translate id={'carrierTenders.maneuvers.table.supplier'} />,
			dataSource: 'supplier',
		},
		{
			header: <Translate id={'carrierTenders.maneuvers.table.maneuverType'} />,
			dataSource: 'maneuverType',
		},
		{
			header: <Translate id={'carrierTenders.maneuvers.table.isDirectTrip'} />,
			dataSource: 'isDirectTrip',
		},
		{
			header: <Translate id={'carrierTenders.maneuvers.table.contactSupplier'} />,
			dataSource: 'contactSupplier',
		},
		{
			header: <Translate id={'carrierTenders.maneuvers.table.ccEmails'} />,
			dataSource: 'cCEmails',
		},
		{
			header: <Translate id={'carrierTenders.deals.requirementsCarriers.table.options'} />,
			dataSource: 'options',
		},
	]
	return { columns }
}

const styles = (theme) => ({
	containerTitle: {
		margin: '15px',
	},
})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Maneuver)))
