import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { converListToArray } from '../../../store/helpers/ListHelper'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import { Translate } from 'react-localize-redux'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Switch from '@material-ui/core/Switch'
import Divider from '@material-ui/core/Divider'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import CompanyIcon from '@material-ui/icons/DomainTwoTone'
import LockIcon from '@material-ui/icons/LockTwoTone'
import UnLockIcon from '@material-ui/icons/LockOpen'
import EmailIcon from '@material-ui/icons/Email'
import EnabledIcon from '@material-ui/icons/ThumbUp'
import DisabledIcon from '@material-ui/icons/ThumbDown'
import PersonIcon from '@material-ui/icons/Person'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import LoadingPage from '../../common/LoadingPage'
import AddCommonItem from '../../common/AddCommonItem'

/** Import section actions */
import { getCompaniesGrantedToUser, loadExternalRolesByCompanies } from '../../../store/companies/CompanyActions'
import { getDescriptionRole } from '../../../store/helpers/RolesHelper'

import {
	loadUser,
	setLockoutEnabledExternal,
	loadUserExternalRoles,
	toggleRoleAssignentExternalUser,
} from '../../../store/suppliers/ExternalUserActions'
import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER } from '../../../store/helpers/StatusColorConstants'
import { Tooltip } from '@material-ui/core'

/**
 * Container to show user
 */
class Show extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			grantedCompanies: null,
			grantingAccessToCompany: null,
			expandedCompany: null,
			openEnableUser: false,
			openUnlockedUser: false,
		}
	}

	/**
	 * componentWillMount
	 *
	 * @memberof Show
	 */
	componentWillMount() {
		this.props
			.getCompaniesGranted(this.props.match.params.token)
			.then((serverResponse) => {
				this.setState({
					grantedCompanies: serverResponse.data,
				})
			})
			.catch(() => {})
		this.props.loadExternalRolesByCompanies()
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let userToken = this.props.match.params.token
		this.props.loadUser(userToken).then(() => {
			this.props.loadUserExternalRoles(userToken)
		})
	}

	/**
	 * onEnableUser
	 */
	onEnableUser() {
		this.setState({
			openEnableUser: true,
		})
	}

	/**
	 * Function on block user
	 */
	onBlockUser(comments) {
		let userToken = this.props.match.params.token
		this.props
			.setLockoutEnabledExternal(userToken, false, comments)
			.then(() => {
				this.setState({
					openEnableUser: false,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * onUnLockedUser
	 */
	onUnLockedUser() {
		this.setState({
			openUnlockedUser: true,
		})
	}

	/**
	 * Function on unloked user
	 * @param {*} comments
	 */
	onUnlockedUser(comments) {
		let userToken = this.props.match.params.token
		this.props
			.setLockoutEnabledExternal(userToken, true, comments)
			.then(() => {
				this.setState({
					openUnlockedUser: false,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Render show
	 */
	render() {
		const { externalUser, isLoadingUser } = this.props
		if (isLoadingUser === true) {
			return <LoadingPage />
		}
		if (externalUser === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<Grid container spacing={24}>
					<Grid item xs={4}>
						<UserCard
							{...this.props}
							onEnableUser={this.onEnableUser}
							onUnLockedUser={this.onUnLockedUser}
						/>
					</Grid>
					<Grid item xs={8}>
						<CompaniesCard {...this.props} {...this.state} />
					</Grid>

					{/** Dialog to Block user */}
					<AddCommonItem
						title={<Translate id='users.blockUser' />}
						message={<Translate id='users.messageToBlock' />}
						open={this.state.openEnableUser}
						onClose={() => {
							this.setState({ openEnableUser: false })
						}}
						onSaveItem={this.onBlockUser}
						isSaving={this.props.isSaving}
						isTextArea={true}
					/>

					{/** Dialog to Unlocked user */}
					<AddCommonItem
						title={<Translate id='users.unlockUser' />}
						message={<Translate id='users.messageTounlockUser' />}
						open={this.state.openUnlockedUser}
						onClose={() => {
							this.setState({ openUnlockedUser: false })
						}}
						onSaveItem={this.onUnlockedUser}
						isSaving={this.props.isSaving}
						isMessage={true}
					/>
				</Grid>
			)
		}
	}
}

/**
 * User card
 *
 * @class UserCard
 * @extends {Component}
 */
class UserCard extends Component {
	render() {
		const { externalUser, classes } = this.props
		return (
			<Paper>
				<Card className={classes.card}>
					<CardHeader
						avatar={
							<Tooltip
								title={<Translate id={externalUser.enabled ? 'users.enabled' : 'users.disabled'} />}
							>
								<Avatar
									className={classes.userAvatar}
									style={{
										backgroundColor: externalUser.enabled
											? COLOR_STATUS_SUCCESS
											: COLOR_STATUS_DANGER,
									}}
								>
									<PersonIcon />
								</Avatar>
							</Tooltip>
						}
						title={externalUser.full_name}
						subheader={externalUser.user_name}
					/>
					<CardContent>
						<List>
							<Divider />
							<ListItem>
								<ListItemIcon>
									<EmailIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='users.email' />} secondary={externalUser.email} />
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemIcon>
									{externalUser.enabled ? (
										<EnabledIcon color='secondary' />
									) : (
										<DisabledIcon color='error' />
									)}
								</ListItemIcon>
								<ListItemText
									primary={<Translate id='users.userEnable' />}
									secondary={
										<Translate id={externalUser.enabled ? 'users.enabled' : 'users.disabled'} />
									}
								/>
							</ListItem>
							<Divider />
							{externalUser.id !== this.props.currentUser.profile.sub && (
								<React.Fragment>
									<ListItem
										button
										onClick={
											externalUser.enabled ? this.props.onEnableUser : this.props.onUnLockedUser
										}
									>
										<ListItemIcon>
											{externalUser.enabled ? <LockIcon color='primary' /> : <UnLockIcon />}
										</ListItemIcon>
										<ListItemText
											primary={
												<Typography variant='subtitle1' gutterBottom>
													<Translate
														id={externalUser.enabled ? 'users.lock' : 'users.unlockUser'}
													/>
												</Typography>
											}
										/>
									</ListItem>
									<Divider />
								</React.Fragment>
							)}
						</List>
					</CardContent>
					<CardActions className={classes.actions} disableActionSpacing></CardActions>
				</Card>
			</Paper>
		)
	}
}

/**
 * User card
 *
 * @class UserCard
 * @extends {Component}
 */
class CompaniesCard extends Component {
	/**
	 * Toggle the role assigned
	 *
	 * @param {*} assigned
	 * @param {*} company
	 * @param {*} role
	 * @memberof CompaniesCard
	 */
	toggleRoleAssignent(assigned, company, role) {
		const { externalUser } = this.props
		return this.props.toggleRoleAssignentExternalUser(externalUser, assigned, company, role)
	}

	/**
	 * Get roles
	 *
	 * @returns
	 * @memberof CompaniesCard
	 */
	getRolesList(company) {
		const {
			grantedCompanies,
			rolesForCompanies,
			isLoadingCompaniesRoles,
			isLoadingExternalUserRoles,
			externalUserRoles,
		} = this.props
		if (isLoadingCompaniesRoles === false && isLoadingExternalUserRoles === false) {
			let selectedCompany = grantedCompanies.find((cmp) => cmp.token === company.token)
			if (selectedCompany) {
				let companyRoles = rolesForCompanies.filter((role) => role.company_token === company.token)
				return (
					<List component='div' dense>
						{companyRoles.map((role) => {
							let assignedRole = externalUserRoles.find(
								(internalUserRole) =>
									internalUserRole.id === role.id && internalUserRole.company_token === company.token
							)
							let roleDescription = getDescriptionRole(role.name)
							return (
								<ListItem key={role.id} divider>
									{roleDescription && (
										<ListItemText
											primary={<Translate id={`rolesDescription.${roleDescription}`} />}
										/>
									)}
									{!roleDescription && <ListItemText primary={role.name} />}
									<ListItemSecondaryAction>
										<Switch
											onChange={(event) => {
												this.toggleRoleAssignent(event.target.checked, selectedCompany, role)
											}}
											checked={assignedRole ? true : false}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							)
						})}
					</List>
				)
			}
		} else {
			return <LoadingPage />
		}
	}

	/**
	 * Get the companies list
	 *
	 * @returns
	 * @memberof CompaniesCard
	 */
	getCompaniesList() {
		const { grantedCompanies, classes } = this.props
		if (grantedCompanies) {
			return this.props.grantedCompanies.map((company) => {
				return (
					<ExpansionPanel key={company.token}>
						<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
							<Typography className={classes.companyGrantedHeader}>{company.commercial_name}</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<div style={{ flexBasis: '100%' }}>{this.getRolesList(company)}</div>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				)
			})
		} else {
			return <LoadingPage />
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof CompaniesCard
	 */
	render() {
		const { classes } = this.props
		return (
			<Paper>
				<Card className={classes.card}>
					<CardHeader
						avatar={
							<Avatar className={classes.companyAvatar}>
								<CompanyIcon />
							</Avatar>
						}
						title={
							<Typography variant='subtitle2' gutterBottom>
								<Translate id='companies.companies' />
							</Typography>
						}
					/>
					<CardContent>{this.getCompaniesList()}</CardContent>
					<CardActions className={classes.actions} disableActionSpacing></CardActions>
				</Card>
			</Paper>
		)
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	return {
		currentUser: user,
		rolesForCompanies: converListToArray(state.companies.get('rolesForCompanies')),
		isLoadingCompaniesRoles: state.companies.get('isLoadingCompaniesRoles'),
		externalUser: state.externalUsers.get('externalUser') ? state.externalUsers.get('externalUser').toJS() : null,
		isLoadingUser: state.externalUsers.get('isLoadingUser'),
		isSaving: state.externalUsers.get('isSavingUser'),
		externalUserRoles: state.externalUsers.get('externalUserRoles')
			? state.externalUsers.get('externalUserRoles').toJS()
			: null,
		isLoadingExternalUserRoles: state.externalUsers.get('isLoadingExternalUserRoles'),
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadUser: (token) => {
			return dispatch(loadUser(token))
		},
		loadUserExternalRoles: (userId) => {
			return dispatch(loadUserExternalRoles(userId))
		},
		getCompaniesGranted: (userId) => {
			return dispatch(getCompaniesGrantedToUser(userId))
		},
		loadExternalRolesByCompanies: () => {
			return dispatch(loadExternalRolesByCompanies())
		},
		toggleRoleAssignentExternalUser: (externalUser, assigned, company, role) => {
			return dispatch(toggleRoleAssignentExternalUser(externalUser, assigned, company, role))
		},
		setLockoutEnabledExternal: (userId, enabled, comments) => {
			return dispatch(setLockoutEnabledExternal(userId, enabled, comments, false))
		},
	}
}

const viewStyles = (theme) => ({
	secondaryToolbar: {
		display: 'flex',
	},
	waitMessage: {
		marginTop: '15px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: '100px',
		paddingRight: '150px',
	},
	userAvatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	companyAvatar: {},
	companyGrantedHeader: {
		color: theme.palette.primary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
