import { fromJS } from 'immutable';

import * as FreightActions from './FreightAction';

/**
 * shippingsInitialState initial state
 */
export const freightsInitialState = fromJS({
    isLoadingFreights: true,
    freights: null,
    isLoadingFreight: false,
    freight: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    page: 0,
    rowsPerPage: 20,
    searchQuery: "",
    freightsCount: null,
    isSaving: false,
})

export default function freightsReducer(state = freightsInitialState, action) {    
    switch (action.type) {
        case FreightActions.START_LOADING_FREIGHTS: {
            return state.merge({
                isLoadingFreights: action.isLoading
            })
        }
        case FreightActions.SET_FREIGHTS_LIST: {
            let freightsLists = fromJS(action.freights);
            return state.merge({
                freights: freightsLists,
                isLoadingFreights: false
            })
        }
        case FreightActions.CHANGE_FREIGHTS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case FreightActions.CHANGE_FREIGHTS_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case FreightActions.CHANGE_FREIGHT_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case FreightActions.IS_LOADING_FREIGHT: {
            return state.merge({
                isLoadingFreights: action.isLoadingFreight,
                freight: null,
            })
        }
        case FreightActions.SET_FREIGHT: {            
            return state.merge({
                isSaving: false,
                isLoadingFreight: false,
                freight: fromJS(action.freight)
            })
        }
        case FreightActions.IS_SAVING_FREIGHT:{
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case FreightActions.SET_FREIGHTS_COUNT: {
            return state.merge({
                freightsCount: action.count
            })
        }
        case FreightActions.UPDATE_DETAIL_FROM_FREIGHT:{
            return state.updateIn(['freight', 'details'], (allDetails) => {
                let index = allDetails.findIndex((item) => {
                    return item.get('token') === action.detail.token
                });
                 return allDetails.setIn([index], fromJS(action.detail)).set('isSaving', false);
            });
        }
        case FreightActions.ADD_FILES_FREIGHT_SUPPLIER:{
            let oldList = state.getIn(['freight', action.listItems]).toJS();
            let filesToAdda = action.files.filter(newFileSupl => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFileSupl.name
                })
            })
            let newList = [...oldList, ...(filesToAdda)];
            return state.setIn(['freight', action.listItems], fromJS(newList));
        }
        case FreightActions.REMOVE_FILES_FREIGHT_SUPPLIER:{
            let oldList = state.getIn(['freight', action.listItems]).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['freight', action.listItems], fromJS(newList));
        }
        default:
            return state;
    }
}