/**Import react section */
import React from 'react';
import PropTypes from 'prop-types';

/**Import components section*/
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

/**Import material UI Section*/
import { Translate } from 'react-localize-redux';
import Root from '../../../../store/Root';

/**Import resources section*/
import {
  COLOR_STATUS_DANGER, COLOR_STATUS_SUCCESS,
  BACKGROUND_COLOR_GRAY_BIDDING, COLOR_STATUS_BLUE,
  RELEASE_PLAN_COLOR_BLUE_GRAY
} from '../../../../store/helpers/StatusColorConstants';
import WorkerMan from '../../../../resources/images/WorkerMan.png'
import WorkerWoman from '../../../../resources/images/WorkerWoman.png'
import Certificate from '../../../../resources/images/certificate.png'

const PanelRightPdf = props => {
  const { totalRatingSupplier, totalWorkDetail } = props;

  /**
 * Detemine is aproved
 */
  const isAproved = () => {
    if (totalRatingSupplier <= 85)
      return false;
    else
      return true;
  }

  return (
    <Root>
      <View style={styles.root}>
        <View style={styles.ratingCard}>
          <View style={styles.card}>
            <View style={styles.donutChart}>
              <View style={styles.sliceOne}>
                <View style={[styles.sliceTwo, {backgroundColor: isAproved() ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER,}]}>
                  <View style={styles.chartCenter}>
                    <Text style={[styles.chartLabel, {color: isAproved() ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER,}]}>
                      {`${totalRatingSupplier}%`}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View>
            <Text style={{color: isAproved() ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER, textAlign: 'center', fontSize: '15'}}>
              {isAproved() ? <Translate id='sustainability.isApproved'/> : <Translate id='sustainability.notApproved'/>}
            </Text>
          </View>

          
          <View style={styles.information}>
            <Text>{<Translate id='sustainability.totalWorkers' />}</Text>
            <Text style={styles.totalRatingPecernt}>{
                    totalWorkDetail.TotalAdministrativeWoman+
                    totalWorkDetail.TotalAdministrativeMen+
                    totalWorkDetail.TotalOperationalWoman+
                    totalWorkDetail.TotalOperationalMen}
            </Text>

            <View style={styles.DividerLineBlue} />
            <Image style={styles.image} src={WorkerMan} />
            <View><Text>{<Translate id='sustainability.workers.administrative' />}</Text></View>
            <View><Text style={styles.totalRatingPecernt}>{totalWorkDetail.TotalAdministrativeWoman}</Text></View>
            <View><Text>{<Translate id='sustainability.workers.operational' />}</Text></View>
            <View><Text style={styles.totalRatingPecernt}>{totalWorkDetail.TotalAdministrativeMen}</Text></View>

            <View style={styles.DividerLineBlue} />
            <Image style={styles.image} src={WorkerWoman} />
            <View><Text>{<Translate id='sustainability.workers.administrative' />}</Text></View>
            <View><Text style={styles.totalRatingPecernt}>{totalWorkDetail.TotalOperationalWoman}</Text></View>
            <View><Text>{<Translate id='sustainability.workers.operational' />}</Text></View>
            <View><Text style={styles.totalRatingPecernt}>{totalWorkDetail.TotalOperationalMen}</Text></View>
          </View>
        </View>
        <View style={styles.centerPlace}>
          <Image src={Certificate} style={styles.certificateImg}/>
        </View>
      </View>
    </Root>
  );
};

// Create styles
const styles = StyleSheet.create({
  root: {
    display: "flex",
    width: "100%",
    height: 'auto',
    fontSize: 12
  },
  ratingCard: {
    backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
    padding: "15px",
    height: "55%",
    border: '15px',
    width: "120px",
  },
  title: {
    marginTop: '15px',
  },
  information: {
    textAlign: 'center',
    fontSize: '10',
    marginTop: "15px",
    '& > *': {
      marginRight: '5px'
    }
  },
  DividerLineBlue: {
    height: 4,
    backgroundColor: COLOR_STATUS_BLUE,
    marginTop: '5px',
    marginBottom: '5px',
  },
  image: {
    marginLeft: '25px',
    marginTop: '10px',
    marginBottom: '10px',
    width: '36px',
    height: '50px',
  },
  centerPlace: {
    marginTop: '20px',
    textAlign: 'center',
  },
  certificateImg: {
    width: "90%",
    marginTop: "120px",
    marginLeft: '6px',
    paddingTop: "0px"
  },
  totalRatingPecernt:{
    color: RELEASE_PLAN_COLOR_BLUE_GRAY,
  },
  card: {
		float: 'left',
		background: '#fff',
		margin: '0 0 0 0',
		width: 100,
		height: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
	},
	donutChart: {
    position: 'relative',
		borderTopLeftRadius: 100,
		borderTopRightRadius: 100,
		borderBottomLeftRadius: 100,
		borderBottomRightRadius: 100,
		overflow: 'hidden',
		width: 83,
		height: 83
	},
	sliceOne: {
		backgroundColor: 'white',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%'
	},
	sliceTwo: {
    // backgroundColor: 'green',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
	},
  chartCenter: {
    backgroundColor: 'white',
		borderTopLeftRadius: 80,
		borderTopRightRadius: 80,
		borderBottomLeftRadius: 80,
		borderBottomRightRadius: 80,
		width: 66,
		height: 66,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  chartLabel: {
    textAlign: 'left',
    fontSize: '20'
  }
});

PanelRightPdf.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  isLoading: PropTypes.bool.isRequired,
};

PanelRightPdf.defaultProps = {
  isLoading: false,
};

export default PanelRightPdf;