import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import moment from 'moment'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import DevolutionIcon from '@material-ui/icons/ReplyAllOutlined'; 

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Toaster from '../../common/Toaster';
import LoadingPage from '../../common/LoadingPage';
import DevolutionForm from './DevolutionForm';

import {loadReworkFromBackEnd, createDevolutionFromRework} from '../../../store/reworks/ReworkAction';

/**
 * Component to create corrective action supplier
 */
class CreateDevolution extends Component{

     /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            creationDate: moment().format('DD/MM/YYYY'),
        }
    }

       /**
     * componentDidMount
     */
    componentDidMount(){
        let reworkToken = this.props.match.params.token;
        this.props.loadRework(reworkToken);
    }

    /**
     * Get action to create devolution from failure report 
     */
    createDevolutionFromRework(preparedBy, planningSelected, developmentSelected, comments){
        let reworkToken = this.props.match.params.token;
        this.props.createDevolution(preparedBy, planningSelected, developmentSelected, comments)
        .then(() =>{
            this.setState({
                showToView:true,
                showToaster: false
            })
            this.props.history.push(`/reworks/${reworkToken}`)
        }).catch(() =>{
            this.setState({
                showToaster:true,
                toasterMessage: <Translate id="common.errorToSave"/>,
                toasterVariant: "error",
            })
        });
    }

        /**
    * Get the card content
    *
    * @memberof Show
    */
   getCardContent() {
    if (this.props.isLoading === true) {
        return (
            <LoadingPage />
        )
    }
    else if (this.props.rework !== null) {
        return (
            <DevolutionForm  
                {...this.props}
                isSaving = {this.props.isSaving}
                canBeCreate={this.createDevolutionFromRework}
            /> 
        )
    }
}

    render(){
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <DevolutionIcon />
                    </Avatar>
                }
                title={<Typography variant="button" ><Translate id="devolutions.devolutionToSupplier"/></Typography>}
                subheader={<Typography variant="caption" color="textPrimary"><Translate id="common.createBy"/>: {this.props.user.profile.name}
                 <br/><Translate id="common.creationDate"/>: {this.state.creationDate}</Typography>}
            />
            <CardContent>
                {this.getCardContent()}
            </CardContent>

            <Toaster 
                message = {this.state.toasterMessage}
                open = {this.state.showToaster}
                variant = {this.state.toasterVariant}
                onClose = {() => {this.setState({ showToaster:false})}}
            /> 

        </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    let rework = state.reworks.get("rework");
    return {
        user: user,
        isLoading: state.reworks.get('isLoading'),
        isSaving: state.reworks.get('isSaving'),
        rework: rework ? rework.toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadRework: (token) => {
            return dispatch(loadReworkFromBackEnd(token));
        },
        createDevolution:(preparedBy, planningSelected, developmentSelected, comments) =>{
            return dispatch(createDevolutionFromRework(preparedBy, planningSelected, developmentSelected, comments))
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(CreateDevolution))));