import { fromJS } from 'immutable';

import * as releaseCalendarActions from './ReleaseCalendarAction';

/**
 * Release Calendar initial state
 */
export const releaseCalendarInitialState = fromJS({
    isLoadingReleaseCalendars: true,
    releaseCalendarLocks: null,
    sortCriteria: {
        by: "id",
        ascending: true
    },
    searchQuery: "",
    releaseCalendarLocksCount: null,
    isSaving: false,
    isLoading: false,
    releaseCalendarLock: null,
    page: 0,
    rowsPerPage: 20,
})

export default function releaseCalendarReducer(state = releaseCalendarInitialState, action) {
    switch (action.type) {
        case releaseCalendarActions.START_LOADING_RELEASE_CALENDARS: {
            return state.merge({
                isLoadingReleaseCalendars: action.isLoading
            })
        }
        case releaseCalendarActions.SET_RELEASE_CALENDARS_LIST: {
            let calendarsLocksLists = fromJS(action.calendarsLocks);
            return state.merge({
                releaseCalendarLocks: calendarsLocksLists,
                isLoadingReleaseCalendars: false,
                isLoading: false,
                isSaving:false,
            })
        }
        case releaseCalendarActions.CHANGE_RELEASE_CALENDARS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case releaseCalendarActions.CHANGE_RELEASE_CALENDARS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case releaseCalendarActions.CHANGE_RELEASE_CALENDARS_QUERY: {
            return state.merge({
                searchQuery: action.query
            })
        }
        case releaseCalendarActions.SET_RELEASE_CALENDARS_COUNT: {
            return state.merge({
                releaseCalendarLocksCount: action.count
            })
        }
        case releaseCalendarActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case releaseCalendarActions.IS_LOADING: {
            return state.merge({
                isLoading: action.isLoading
            })
        }
        case releaseCalendarActions.SET_RELEASE_CALENDAR_LOCK: {
            return state.merge({
                isLoading: false,
                isSaving: false,
                releaseCalendarLock: fromJS(action.calendarLock)
            })
        }
        case releaseCalendarActions.REMOVE_RELEASE_CALENDAR_FROM_LIST: {
            return state.updateIn(['releaseCalendarLocks'], (allLocks) => {
                let index = allLocks.findIndex((item) => {
                    return item.get('token') === action.calendaroken
                });
                return allLocks.delete(index);
            })
        }
        default:
            return state;
    }
}