import React, { Component } from 'react';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import HtmlEditor from '../../common/HtmlEditor';

/**
 * Card to add aditional information 
 */
class AditionalMemoCard extends Component {
    render() {
        return (
            <Paper>
                <List>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom>
                            {<Translate id="memos.annualVolume" />}</Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.memo.project_volume}<br />
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="memos.typeManufacturing" /></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.memo.manufacture_type}<br />
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <HtmlEditor
                            id="engineer_observations"
                            label="memos.engineeringObservations"
                            labelVariant="subtitle1"
                            isDisabled
                            initialValue={this.props.memo.engineer_observations}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <HtmlEditor
                            id="supplier_development_observations"
                            label="memos.observationsDevelopment"
                            labelVariant="subtitle1"
                            isDisabled
                            initialValue={this.props.memo.supplier_development_observations}
                        />
                    </ListItem>
                </List>
            </Paper>
        );
    }
}
export default AditionalMemoCard;