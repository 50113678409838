import React from 'react'
import { Typography } from '@material-ui/core'
const ThereIsNoInformation = () => {
	return (
		<div
			style={{
				height: '100px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: '15px',
				backgroundColor: '#F9F9F9',
			}}
		>
			<div>
				<Typography variant='caption' display='block'>
					<strong>No existe información para mostrar</strong>
				</Typography>
			</div>
		</div>
	)
}

export default ThereIsNoInformation
