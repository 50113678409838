/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
/**Import Material UI section */
import {
	Button, Grid, FormControlLabel, Checkbox, Typography
} from '@material-ui/core';
import AvisoPrivacidad from '../../../../resources/files/Aviso de Privacidad.pdf'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const StepTwoTermsAndConditions = (props) => {
	const { classes, termAndConditions, translate } = props
	const [acceptTerms, setAccepTems] = useState(false);
	/**On change properties */
	const onPropertyChange = event => {
		setAccepTems(event.target.checked)
	}
	return (
		<>
			<Grid item xs={12} >
				<Typography variant="subtitle2" align="justify">{translate("biddingsModule.externalUsers.termsAndConditionsTitle")}</Typography>
				<div className="embed-responsive embed-responsive-16by9" style={{ overflow: "hidden", height: '100%', width: '100%' }}>
					<iframe title="official_notice" className="embed-responsive-item"
						src={AvisoPrivacidad}></iframe>
				</div>
			</Grid><br />
			<Grid container>
				<Grid item xs={9}>
					<FormControlLabel
						control={
							<Checkbox
								onClick={onPropertyChange}
								icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
								checkedIcon={<CheckBoxIcon fontSize="small" />}
								value={acceptTerms}
							/>
						}
						label={translate("biddingsModule.externalUsers.accepTerms")}
					/>
				</Grid>
				<Grid item xs={3}>
					<Button
						variant="outlined"
						color="primary"
						className={classes.button}
						data-cy="btn-close"
						onClick={termAndConditions}
						disabled={!acceptTerms}
					>
						{translate("biddingsModule.externalUsers.saveOrContinue")}
					</Button>

				</Grid>

			</Grid>



		</>

	)
}


StepTwoTermsAndConditions.propTypes = {
	isValid: propTypes.bool.isRequired
}
StepTwoTermsAndConditions.defaultProps = {
	isValid: false
}

/**
 * Styles definition
 */
const styles = theme => ({
	button: {
		margin: theme.spacing.unit,
	},
	flex: {
		display: "flex",
		justifyContent: "flex-end"
	},
	padding: {
		paddingLeft: "100px",
		paddingRight: "100px"
	}


});

export default withStyles(styles, { withTheme: true })(StepTwoTermsAndConditions);
