/**Import react section */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import propTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

/**Import MaterialUi section */
import {
  Grid,
  Button,
  Avatar,
  Typography,
  TextField,
  ListSubheader,
  ListItemSecondaryAction,
  Link
} from "@material-ui/core";
import FileIcon from "@material-ui/icons/AttachFile";
import {
  ListItemAvatar,
  List,
  ListItemText,
  ListItem,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
/**Import components section */
import DatePicker from "../../../common/DateTimePicker/DatePicker";
import Evidence from "../../../common/Evidence";

/**Import store section */
import { IsNullOrEmpty } from "../../../../store/helpers/StringHelper";
import {
  ActionPlantQuestionnaireStatus,
} from "../../../../store/helpers/AppConstants";
import { COLOR_STATUS_WARNING } from "../../../../store/helpers/StatusColorConstants";
import { IsCurrentActiveDirectoryUser } from "../../../../store/helpers/SessionHelper";

/**custom hooks */
import { useQuestionnaireCustomHookShow } from "../customHooks/UseCustomHookShow";

const EditActionPanel = (props) => {
  const { classes, translate, actionPlan, canEditSupplier,
    canEditCompany, onCloseButtonClick, listActions, 
    //deliveryDateActionPlanFull
   } =
    props;

  const isSavingQuestionnaire = useSelector((state) =>
    state.questionnaires.get("isSavingQuestionnaire")
  );
  let questionnaireToken = props.match.params.token;
  const {
    user,
    onRemoveFileInActionPlan,
    onUpdateActionPlanFromResponsible,
    onAddEvidenceInActionPlan,
    onUpdateActionPlantSupplier,
  } = useQuestionnaireCustomHookShow();
  let isCurrentActiveDirectoryUser = IsCurrentActiveDirectoryUser(user);

  const [attachments, setAttachments] = useState(
    actionPlan.attachments ? actionPlan.attachments : []
  );
  const [itemModel, setItemModel] = useState(actionPlan);

  let isUserCreator =
    actionPlan && user && user.profile.username === actionPlan.created_user_name;
  const canUpdateSupplierAction =canEditSupplier && itemModel && (itemModel.status === ActionPlantQuestionnaireStatus.PENDIENT || 
    itemModel.status === ActionPlantQuestionnaireStatus.OPEN || itemModel.status === ActionPlantQuestionnaireStatus.PROCESS);
  const canBeUpdateResponsible = canEditCompany && isUserCreator && itemModel && (itemModel.status === ActionPlantQuestionnaireStatus.OPEN || itemModel.status === ActionPlantQuestionnaireStatus.PROCESS);
  const canBeUpdateActtachmentsSupplierAction = canEditSupplier && itemModel && (itemModel.status === ActionPlantQuestionnaireStatus.PENDIENT 
    || (itemModel.status === ActionPlantQuestionnaireStatus.OPEN  || actionPlan.status === ActionPlantQuestionnaireStatus.PROCESS))

  //let maxCommitmentDate = deliveryDateActionPlanFull ? moment(deliveryDateActionPlanFull, 'DD/MM/YYYY') : moment();

  /**
   * On upload file in maquila order in list name
   * @param {*} files
   * @param {*} maquilaToken
   * @param {*} listName
   */
  const uploadFiles = (files) => {
    let filesList = attachments.concat(files[0]);
    if (files.length > 0) {
      for (var index = 1; index < files.length; index++) {
        filesList.push(files[index]);
      }
      return onAddEvidenceInActionPlan(
        questionnaireToken,
        itemModel.token,
        files,
        listActions
      )
        .then((result) => {
          setAttachments(filesList);
          return Promise.resolve("Ok");
        })
        .catch(() => {
          return Promise.reject();
        });
    }
    return Promise.resolve("Ok");
  };

  /**
   * Handle event remove files of evidence componet to remove files of list
   *
   * @param {*} fileName
   * @returns
   */
  const removeFiles = (fileName) => {
    let newList = attachments.filter((files) => {
      return files.name !== fileName;
    });
    return onRemoveFileInActionPlan(
      questionnaireToken,
      itemModel.token,
      fileName
    )
      .then(() => {
        setAttachments(newList);
        return Promise.resolve("Ok");
      })
      .catch(() => {
        return Promise.reject();
      });
  };

  /**On change properties */
  const onChangeDate = (property, data) => {
    if (
      property === "commitment_date" ||
      property === "approval_date" ||
      property === "ApprovalDate"
    )
      data = data ? moment(data).format("DD/MM/YYYY") : "";
    setItemModel({
      ...itemModel,
      [property]: data,
    });
  };

  /**On change properties */
  const onPropertyChange = (event) => {
    let { name, value } = event.target;
    setItemModel({
      ...itemModel,
      [name]: value,
    });
  };

  /**
   * Update action plan
   * @param {*} property
   * @param {*} value
   * @param {*} actionPlanId
   * @returns
   */
  const onSaveItemUpdate = () => {
    if (canUpdateSupplierAction) {
      return onUpdateActionPlantSupplier(
        questionnaireToken,
        itemModel,
        isCurrentActiveDirectoryUser
      ).then(() => {
        onCloseButtonClick();
      });
    } else {
      return onUpdateActionPlanFromResponsible(
        questionnaireToken,
        itemModel,
        isCurrentActiveDirectoryUser
      ).then(() => {
        onCloseButtonClick();
      });
    }
  };

  /**Verify if can be sabed data*/
  const canBeSaved = () => {
    if (canEditSupplier) {
      if (IsNullOrEmpty(itemModel.comments_supplier)) return false;
      if (
        IsNullOrEmpty(itemModel.commitment_date) ||
        itemModel.commitment_date.length === 0
      )
        return false;
      else return true;
    }
    else return true;
  };

  return (
    <div>
      <div className={classes.containerInformation}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={translate('common.commentsActionPlant')}
              className={classes.textField}
              onChange={onPropertyChange}
              multiline
              name="comments_supplier"
              margin="normal"
              variant="outlined"
              value={itemModel ? itemModel.comments_supplier : ""}
              rows="2"
              disabled={isSavingQuestionnaire || !canUpdateSupplierAction}
            />
          </Grid>
          {canUpdateSupplierAction && (
            <Grid item xs={12}>
              <Typography color="primary">{"Fecha compromiso"}</Typography>
              <div className={classes.marginTop}>
                <DatePicker
                  onChangeRange={(event) =>
                    onChangeDate("commitment_date", event)
                  }
                  startDate={
                    itemModel.commitment_date
                      ? moment(itemModel.commitment_date, "DD/MM/YYYY")
                      : ""
                  }
                  minDate={moment()}
                 // maxDate={maxCommitmentDate}
                />
              </div>
            </Grid>
          )}
          {!canUpdateSupplierAction && (
            <Grid item xs={12}>
              <List className={classes.root}>
                <ListItem divider>
                  <ListItemText
                    style={{ marginLeft: "-14px" }}
                    secondary={"Fecha compromiso: "}
                  />
                  <ListItemSecondaryAction>
                    {itemModel.commitment_date && itemModel.commitment_date}
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          )}
          <Grid item xs={12} className={classes.marginTop}>
            <Typography color="primary" className={classes.textTitle}>
              {"Evidencias del proveedor"}
            </Typography>
            {canBeUpdateActtachmentsSupplierAction && (
              <div className={classes.alertPanel}>
                <div className={classes.alert}>
                  <Typography
                    variant="caption"
                    align="justify"
                    className={classes.marginTop}
                  >
                    <InfoIcon style={{ color: COLOR_STATUS_WARNING }} />
                    {
                      translate('common.uploadFilesActionPlant')
                    }
                  </Typography>
                </div>
              </div>
            )}
            {canBeUpdateActtachmentsSupplierAction && (
              <div className={classes.marginTop}>
                <Evidence
                  text={translate('common.selectfiles')}
                  uploadFiles={uploadFiles}
                  isDisabled={!canBeUpdateActtachmentsSupplierAction}
                  maxFiles={1}
                  files={[]}
                />
              </div>
            )}
            {attachments.length === 0 && (
              <Typography className={classes.textTitle}>
                <Translate id="common.noFiles" />
              </Typography>
            )}
            <List>
              {attachments.map((evidence, index) => {
                return (
                  <ListItem key={index} divider>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar} size="small">
                        <FileIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          color="primary"
                          className={classes.fileName}
                        >
                            <Link target="_blank"
													    href={`${process.env.REACT_APP_SIP_QUALITY_URL_FILES}${evidence.relativePath}`}
													  >
														{evidence.name}
													  </Link>
                        </Typography>
                      }
                      style={{ cursor: "pointer" }}
                    />
                    {canBeUpdateActtachmentsSupplierAction && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={() => removeFiles(evidence.name)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          {!canBeUpdateResponsible && (
            <Grid item xs={12}>
              <List
                className={classes.root}
                subheader={
                  <ListSubheader
                    style={{ marginLeft: "-12px", marginTop: "20px" }}
                    color="primary"
                    component="div"
                    id="nested-list-subheader"
                  >
                    {"Información adicional del responsable"}
                  </ListSubheader>
                }
              >
                <ListItem divider className={classes.marginTop}>
                  <ListItemText
                    style={{ marginLeft: "-12px" }}
                    secondary={translate('common.acceptActionPlant')}
                  />
                  <div className={classes.marginTop}>
                    <ListItemSecondaryAction>
                      {itemModel.approval_date && itemModel.approval_date}
                    </ListItemSecondaryAction>
                  </div>
                </ListItem>
              </List>
            </Grid>
          )}
          {canBeUpdateResponsible && (
            <Grid item xs={12} className={classes.marginTop}>
              <Typography className={classes.textTitle}>
              {translate('common.acceptActionPlant')}
              </Typography>
              <div className={classes.marginTop}>
                <DatePicker
                  onChangeRange={(event) =>
                    onChangeDate("approval_date", event)
                  }
                  startDate={
                    itemModel.approval_date
                      ? moment(itemModel.approval_date, "DD/MM/YYYY")
                      : ""
                  }
                  minDate={moment()}
                  opens={"left"}
                  drops={"up"}
                />
              </div>
            </Grid>
          )}
          {
            // !canUpdateSupplierAction &&
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={translate('common.commentsInPlant')}
                className={classes.textField}
                onChange={onPropertyChange}
                multiline
                name="comments_plant"
                margin="normal"
                variant="outlined"
                value={itemModel ? itemModel.comments_plant : ""}
                rows="2"
                disabled={isSavingQuestionnaire || !canBeUpdateResponsible}
              />
            </Grid>
          }
        </Grid>
      </div>

      {(canUpdateSupplierAction || canBeUpdateResponsible) && (
        <Grid container>
          <Grid item xs={12} className={classes.containerBtn}>
            <Button
              data-cy="btn-save"
              type="button"
              color="primary"
              variant="outlined"
              onClick={onSaveItemUpdate}
              className={classes.widthBtnSaved}
              disabled={!canBeSaved() || isSavingQuestionnaire}
            >
              {<Translate id="common.save" />}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const styles = (theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  containerInformation: {
    maxHeight: `calc(100vh - ${150}px)`,
    minHeight: `calc(100vh - ${150}px)`,
    overflowY: "auto",
    overflowX: "auto",
    padding: "10px",
  },
  containerBtn: {
    display: "flex",
    justifyContent: "center",
  },
  inputProps: {
    height: 6,
  },
  marginTop: {
    marginTop: "10px",
  },
  marginLeft: {
    marginLeft: "6px",
  },
  widthBtnSaved: {
    minWidth: "400px",
  },
  fileName: {
    maxWidth: "340px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
});

EditActionPanel.propTypes = {
  /**
   * callback executed add new user in the list
   */
  onSaveButtonClick: propTypes.func,
  /**
   * open and closedlateral panel for add an new user in customer
   */
  onCloseButtonClick: propTypes.func,
  /**
   * Determinate if is saving data
   */
  isSavingQuestionnaire: propTypes.bool.isRequired,
};

EditActionPanel.defaultProps = {
  onSaveButtonClick: () =>
    console.warn("Callback [onSaveButtonClick] no defined"),
  onCloseButtonClick: () =>
    console.warn("Callback [onCloseButtonClick] no defined"),
  isSavingQuestionnaire: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(EditActionPanel)
);
