import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CatalogHeader from './components/CatalogHeader'
import * as action from '../../../store/transportTender/transportTenderAction'
import DataTable from '../../common/DataTable'
import { AttachMoney } from '@material-ui/icons'
import { Translate } from 'react-localize-redux'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import * as selector from '../../../store/transportTender/transportTenderSelector'
import DialogCatalogUploadMassive from './components/DialogCatalogUploadMassive'
import { getLoggedUserSelector } from '../../../store/profile/ProfileSelectors'
import { isTransportTenderAdministrator } from '../../../store/helpers/TransportTenderHelper'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import queryString from 'query-string'
import LoadingPage from '../../common/LoadingPage'

const Rates = (props) => {
	const { classes } = props
	let history = useHistory()
	let location = useLocation()
	const queryParams = queryString.parse(location.search)
	const transportTenderinfo = useSelector((state) => selector.getTransportTenderfilterSelector(state))
	const isLoadig = useSelector((state) => selector.getIsLoadingSavingSelector(state))
	const loggetUser = useSelector((state) => getLoggedUserSelector(state))
	const userRoles = loggetUser && loggetUser.grantedRoles ? loggetUser.grantedRoles : []
	const isTransportTenderAdminitsrator = isTransportTenderAdministrator(userRoles)
	const dispatch = useDispatch()
	const [openDialog, setOpenDialog] = useState(false)
	const [file, setfile] = useState([])

	const errorTariffSAPFile = useSelector((state) => selector.getErrorTariffSAPFile(state))
	const onChangeQuery = (query) => {
		if (query) queryParams.query = query
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onChangePage = (page, rowsPerPage) => {
		queryParams.pageNumber = page + 1
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	useEffect(() => {
		dispatch(action.loadRateCatalog(queryParams))
	}, [dispatch, location.search])

	useEffect(() => {
		if (file.length > 0) {
			dispatch(action.validateTariffSAPTemplate(file))
		}
	}, [file])

	const onDownloadTemplateFilled = () => {
		return dispatch(action.downloadFilledTariffSAPTemplate())
	}

	const canBeSaved = () => {
		if (errorTariffSAPFile.length > 0) return false
		if (file.length === 0) return false
		return true
	}
	const onSave = () => {
		dispatch(action.uploadTariffSAPTemplate(file))
			.then(() => {
				setOpenDialog(false)
				dispatch(action.loadRateCatalog(queryParams))
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}
	const transformRate = transportTenderinfo.ratePagination.items.map((item) => {
		const transformItem = { ...item }
		if (transformItem && transformItem.amount) {
			const amount = transformItem.amount
			transformItem.amount = amount.toLocaleString('es-MX', {
				style: 'currency',
				currency: 'MXN',
			})
		}
		return transformItem
	})
	return (
		<div className={classes.containerTitle}>
			<CatalogHeader
				setOpenDialog={setOpenDialog}
				icon={<AttachMoney />}
				isInternalUse={true}
				title={<Translate id={'carrierTenders.configuration.rateTitle'} />}
				isAdministrator={isTransportTenderAdminitsrator}
				showSearch
				queryParams={queryParams}
				onChangeSearchValue={onChangeQuery}
			/>
			{isLoadig ? (
				<LoadingPage />
			) : (
				<DataTable
					data={transformRate}
					configuration={TableConfiguration()}
					showFilters
					isIndex
					page={transportTenderinfo.ratePagination.pageNumber}
					rowsPerPage={transportTenderinfo.ratePagination.pageSize}
					totalRows={transportTenderinfo.ratePagination.total}
					onChangePage={onChangePage}
				/>
			)}

			<DialogCatalogUploadMassive
				title={'Importación masiva de tarifas'}
				typeLoadTranslate={'massiveTariffSAP'}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				file={file}
				setfile={setfile}
				onDownloadTemplateFilled={onDownloadTemplateFilled}
				isLoading={isLoadig}
				canBeSaved={canBeSaved()}
				errorList={errorTariffSAPFile}
				onSave={onSave}
			/>
		</div>
	)
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: <Translate id={'carrierTenders.rateInformation.freightExpense'} />,
			dataSource: 'freightExpense',
		},
		{
			header: <Translate id={'carrierTenders.rateInformation.supplierCode'} />,
			dataSource: 'supplierCode',
		},
		{
			header: <Translate id={'carrierTenders.rateInformation.supplierDescription'} />,
			dataSource: 'supplierDescription',
		},
		{
			header: <Translate id={'carrierTenders.rateInformation.departureEmbarkationCode'} />,
			dataSource: 'departureEmbarkationCode',
		},
		{
			header: <Translate id={'carrierTenders.rateInformation.finishedProductCode'} />,
			dataSource: 'finishedProductCode',
		},
		{
			header: <Translate id={'carrierTenders.rateInformation.transportZoneCode'} />,
			dataSource: 'transportZoneCode',
		},
		{
			header: <Translate id={'carrierTenders.rateInformation.transportZoneName'} />,
			dataSource: 'transportZoneName',
		},
		{
			header: <Translate id={'carrierTenders.rateInformation.transportTypeCode'} />,
			dataSource: 'transportTypeCode',
		},
		{
			header: <Translate id={'carrierTenders.rateInformation.transportTypeDescription'} />,
			dataSource: 'transportTypeDescription',
		},
		{
			header: <Translate id={'carrierTenders.rateInformation.amount'} />,
			dataSource: 'amount',
		},
		{
			header: <Translate id={'carrierTenders.rateInformation.currency'} />,
			dataSource: 'currency',
		},
		// {
		// 	header: <Translate id={'carrierTenders.rateInformation.startDate'} />,
		// 	dataSource: 'StartDate',
		// },
		// {
		// 	header: <Translate id={'carrierTenders.rateInformation.expirationDate'} />,
		// 	dataSource: 'ExpirationDate',
		// },
	]

	return { columns }
}

const styles = (theme) => ({
	addIcon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		borderRadius: '16px',
		margin: '0px 5px 0px 10px',
	},
	titleTabs: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	containerTitle: {
		margin: '15px',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Rates)))
