/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux'
import Select from 'react-select'

/**Import MaterialUi section */
import {
    Grid, Avatar, Typography, TextField, Button, InputAdornment
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import CommentsComponent from '../CommentsComponent'

/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';
import { supplierCanEditBidding } from '../../../../store/helpers/BiddingHelper'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { getIncotermsOptions, getDivisasOptions } from '../../../../store/helpers/SelectOptions'
import { CriteriaType, ItemProposalStatus } from '../../../../store/helpers/AppConstants'
const AddSupplierResponseByItemLateralPanel = (props) => {
    /**props section */
    const { onCloseButtonClick,
        isSaving, classes, selectedItem, bidding,
        userProfile, userRolesByCompany, isExternalUser,
        onSaveButtonClick, incoterms, divisas, translate
    } = props
    let incotermsOptions = incoterms ? getIncotermsOptions(incoterms) : []
    let divisasOptions = divisas ? getDivisasOptions(divisas) : []
    const [materialModel, setMaterialModel] = useState({
        purchaseQuantity: "",
        purchaseMeasurementUnit: "",
        purchasePrice: "",
        purchaseDivisa: null,
        materialPrice: "",
        materialDivisa: null,
        incoterm: null,
        destination: "",
        purchaseMinimumQuantity: "",
        notes: "",
    });

    /**Use effect section */
    useEffect(() => {
        if (selectedItem && props.bidding && IsNullOrEmpty(materialModel.purchaseQuantity) && IsNullOrEmpty(materialModel.destination) && IsNullOrEmpty(materialModel.incotermCode)) {
            let incotermsOptions = incoterms ? getIncotermsOptions(incoterms) : []
            let divisasOptions = divisas ? getDivisasOptions(divisas) : []

            let existIncoterm = incotermsOptions.find(x => x.value === selectedItem.IncotermCode);
            let existMaterialDivisa = divisasOptions.find(x => x.value === selectedItem.DivisaCode);
            let existPurchaseDivisa = divisasOptions.find(x => x.value === selectedItem.PurchaseDivisaCode);
            setMaterialModel({
                ...materialModel,
                Id: selectedItem ? selectedItem.ItemProposalId : "",
                ItemId: selectedItem ? selectedItem.Id : "",
                criteriaId: "",
                purchaseQuantity: selectedItem ? selectedItem.PurchaseQuantity : "",
                purchaseMeasurementUnit: selectedItem ? selectedItem.PurchaseMeasurementUnit : "",
                purchasePrice: selectedItem ? selectedItem.PurchasePrice : "",
                purchaseDivisa: existPurchaseDivisa ? existPurchaseDivisa : null,
                materialPrice: selectedItem ? selectedItem.materialPrice : "",
                materialDivisa: existMaterialDivisa ? existMaterialDivisa : null,
                incoterm: existIncoterm ? existIncoterm : null,
                destination: selectedItem ? selectedItem.Destination : "",
                purchaseMinimumQuantity: selectedItem ? selectedItem.PurchaseMinimumQuantity : ""
            })
        }
    }, [props.bidding])
    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;

        if (name === 'purchaseQuantity' && !IsNullOrEmpty(value)) {
            let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (!regexQuantity.test(value)) return;
        }
        if (name === 'materialPrice' && !IsNullOrEmpty(value)) {
            let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (!regexQuantity.test(value)) return;
        }
        if (name === 'purchasePrice' && !IsNullOrEmpty(value)) {
            let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (!regexQuantity.test(value)) return;
        }
        if (name === 'purchaseMinimumQuantity' && !IsNullOrEmpty(value)) {
            let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (!regexQuantity.test(value)) return;
        }
        setMaterialModel({
            ...materialModel,
            [name]: value
        });
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(materialModel)
        }
    }

    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (IsNullOrEmpty(materialModel.purchaseQuantity)) return false
        if (IsNullOrEmpty(materialModel.purchaseMeasurementUnit)) return false
        if (IsNullOrEmpty(materialModel.purchasePrice)) return false
        if (IsNullOrEmpty(materialModel.materialPrice)) return false
        if (IsNullOrEmpty(materialModel.purchaseMinimumQuantity)) return false
        if (materialModel.incoterm === null) return false
        if (materialModel.purchaseDivisa === null) return false
        if (materialModel.materialDivisa === null) return false
        return true;
    }

    const onSelectedChange = (option, name) => {
        setMaterialModel({
            ...materialModel,
            [name]: option
        });
    }

    let isDisabledInformation = ((selectedItem && selectedItem.Status === ItemProposalStatus.DECLINED) || !(supplierCanEditBidding(bidding, userProfile, userRolesByCompany, isExternalUser)))
    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<Translate id="biddingsModule.responseSupplier" />}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} className={classes.titleDisplay}>
                            <Typography variant="subtitle2" color="primary" align="justify">{selectedItem ? (selectedItem.Name ? selectedItem.Name : "") : ""}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CommentsComponent
                                title={<Translate id="common.comments" />}
                                comments={materialModel.notes}
                            />
                        </Grid>
                        <br /><br />

                        <Grid item xs={12}>
                            <Typography color="primary" variant="subtitle1"><Translate id="biddingsModule.pricesSupplier" /></Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth
                                id="standard-read-only-input-code"
                                label={<Translate id="biddingsModule.quantityBaseCot" />}
                                value={materialModel.purchaseQuantity ? materialModel.purchaseQuantity : ""}
                                className={classes.textField}
                                margin="dense"
                                variant="outlined"
                                name='purchaseQuantity'
                                onChange={onPropertyChange}
                                disabled={isSaving || isDisabledInformation}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth
                                id="standard-read-only-input-code"
                                label={<Translate id="biddingsModule.measurementUnit" />}
                                value={materialModel.purchaseMeasurementUnit ? materialModel.purchaseMeasurementUnit : ""}
                                className={classes.textField}
                                margin="dense"
                                variant="outlined"
                                name='purchaseMeasurementUnit'
                                onChange={onPropertyChange}
                                disabled={isSaving || isDisabledInformation}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.currencyTextfield}>
                            <TextField
                                label={<Translate id="biddingsModule.price" />}
                                name='purchasePrice'
                                variant="outlined"
                                fullWidth
                                type='number'
                                step={2.5}
                                value={materialModel.purchasePrice ? materialModel.purchasePrice : ""}
                                //currencySymbol="$"
                                //outputFormat="string"
                                onChange={onPropertyChange}
                                disabled={isSaving || isDisabledInformation}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    //classes: { input: classes.inputProps }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color="primary" className={classes.fontSize}>Moneda</Typography>
                            <Select
                                placeholder={translate('biddingsModule.selectedCurrency')}
                                options={divisasOptions}
                                onChange={(option) => onSelectedChange(option, "purchaseDivisa")}
                                value={materialModel ? materialModel.purchaseDivisa : null}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 9999 }),
                                    menuList: (base) => ({ ...base, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                    control: base => ({
                                        ...base,
                                        height: 50,
                                        minHeight: 50,
                                    })
                                }}
                                menuPortalTarget={document.parentNode}
                                menuPlacement={'bottom'}
                                isDisabled={isSaving || isDisabledInformation}
                            />
                        </Grid>

                        {
                            selectedItem.Criterias.map(criteria => {
                                if (criteria.CriteriaType === CriteriaType.MONEY) {
                                    if (IsNullOrEmpty(materialModel.criteriaId)) {
                                        setMaterialModel({
                                            ...materialModel,
                                            "criteriaId": criteria.Id,
                                            "materialPrice": criteria.Response,
                                            "notes": criteria.Notes
                                        })
                                    }
                                    return (
                                        <Grid container key={criteria.Id} spacing={8}>
                                            <Grid item xs={12}>
                                                <Typography color="primary" variant="subtitle1">{translate('biddingsModule.pricesMeasurementUnit')}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField fullWidth
                                                    id="standard-read-only-input-code"
                                                    label={<Translate id="biddingsModule.quantityBaseCot" />}
                                                    value={materialModel.purchaseQuantity ? materialModel.purchaseQuantity : ""}
                                                    className={classes.textField}
                                                    margin="dense"
                                                    variant="outlined"
                                                    name='purchaseQuantity'
                                                    onChange={onPropertyChange}
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField fullWidth
                                                    id="standard-read-only-input-code"
                                                    label={<Translate id="biddingsModule.measurementUnit" />}
                                                    value={materialModel.purchaseMeasurementUnit ? materialModel.purchaseMeasurementUnit : ""}
                                                    className={classes.textField}
                                                    margin="dense"
                                                    variant="outlined"
                                                    name='purchaseMeasurementUnit'
                                                    onChange={onPropertyChange}
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item xs={6} className={classes.currencyTextfield}>
                                                <TextField
                                                    label={<Translate id="biddingsModule.materialPrice" />}
                                                    name='materialPrice'
                                                    variant="outlined"
                                                    fullWidth
                                                    type='number'
                                                    step={2.5}
                                                    value={materialModel.materialPrice ? materialModel.materialPrice : ""}
                                                    defaultValue={criteria.Response}
                                                    onChange={onPropertyChange}
                                                    disabled={isSaving || isDisabledInformation}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography color="primary" className={classes.fontSize}><Translate id="biddingsModule.currency" /></Typography>
                                                <Select
                                                    placeholder={translate("biddingsModule.selectedCurrency")}
                                                    options={divisasOptions}
                                                    onChange={(option) => onSelectedChange(option, "materialDivisa")}
                                                    value={materialModel ? materialModel.materialDivisa : null}
                                                    styles={{
                                                        menu: (base) => ({ ...base, zIndex: 9999 }),
                                                        menuList: (base) => ({ ...base, paddingTop: 0 }),
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                                        control: base => ({
                                                            ...base,
                                                            height: 50,
                                                            minHeight: 50,
                                                        })
                                                    }}
                                                    menuPortalTarget={document.parentNode}
                                                    menuPlacement={'bottom'}
                                                    isDisabled={isSaving || isDisabledInformation}
                                                />
                                            </Grid>
                                        </Grid>
                                    )

                                } else return null
                            }
                            )
                        }
                        <Grid item xs={12}>
                            <TextField fullWidth
                                id="standard-read-only-input-code"
                                label={<Translate id="biddingsModule.purchaseMinumum" />}
                                value={materialModel.purchaseMinimumQuantity ? materialModel.purchaseMinimumQuantity : ""}
                                className={classes.textField}
                                margin="dense"
                                variant="outlined"
                                name='purchaseMinimumQuantity'
                                onChange={onPropertyChange}
                                disabled={isSaving || isDisabledInformation}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="primary" variant="subtitle1"><Translate id="biddingsModule.informationIncoterm" /></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="primary" className={classes.fontSize}><Translate id="biddingsModule.selectedIncoterm" /></Typography>
                            <Select
                                placeholder={translate("biddingsModule.selectedIncoterm")}
                                options={incotermsOptions}
                                onChange={(option) => onSelectedChange(option, "incoterm")}
                                value={materialModel ? materialModel.incoterm : null}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 9999 }),
                                    menuList: (base) => ({ ...base, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                    control: base => ({
                                        ...base,
                                        height: 44,
                                        minHeight: 44,
                                    })
                                }}
                                menuPortalTarget={document.parentNode}
                                menuPlacement={'bottom'}
                                isDisabled={isSaving || isDisabledInformation}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                                fullWidth
                                label={<Translate id="biddingsModule.destination" />}
                                name='destination'
                                onChange={onPropertyChange}
                                margin="dense"
                                variant="outlined"
                                value={materialModel ? materialModel.destination : ""}
                                disabled={isSaving || isDisabledInformation}
                            />

                        </Grid>

                    </Grid>


                </div>

                <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            disabled={!canBeSaved() || isDisabledInformation}
                        >
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "20px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    titleDisplay: {
        display: "flex"
    },
    containerDivisaCode: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center"
    },
    inputProps: {
        height: 6,
    },
    currencyTextfield: {
        marginTop: "10px"
    },
    fontSize: {
        fontSize: 10,
    },

});

AddSupplierResponseByItemLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddSupplierResponseByItemLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddSupplierResponseByItemLateralPanel))