/**Import react section */
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'

/**Import material UI Section*/
import { withStyles } from '@material-ui/core'
import { Card, CardHeader, Typography, CardContent, Avatar, Fab } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import MenuIcon from '@material-ui/icons/MenuOutlined'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import FindInPage from '@material-ui/icons/FindInPage'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

/**Import components section */
import LoadingPage from '../../common/LoadingPage'
import Evidence from '../../common/Evidence'
import Toaster from '../../common/Toaster'
import AddCommonItem from '../../common/AddCommonItem'
import HtmlEditor from '../../common/HtmlEditor'

import { getStatePlan, getSelectedValue } from '../../../store/helpers/SelectOptions'
import { QualityAuditStatus } from '../../../store/helpers/AppConstants'
import { canBeResponsible, getColorStatusAudit } from '../../../store/helpers/QualityAuditHelper'

import {
	addEvidenceSupplierAudit,
	removeFileInSupplierAudit,
	updateAuditStatus,
	updateAuditCommentsSupplierState,
} from '../../../store/qualityaudit/QualityAuditActions'
import DialogOperation from '../../sustainability2/components/DialogOperation'

const DesktopView = (props) => {
	const { isLoading, isSaving, classes, audit, userProfile, userRolesByCompany } = props
	const [attachments, setAttachments] = useState(audit && audit.attachments ? audit.attachments : [])
	const [planAction, setPlanFile] = useState(audit && audit.planAction ? audit.planAction : [])
	const [actionPlanEvidence, setActionPlanEvidence] = useState(
		audit && audit.actionPlanEvidence ? audit.actionPlanEvidence : []
	)
	const [supplierSate, setSupplierSate] = useState({ planState: audit !== null ? audit.supplierStatus : 0 })
	const [supplierComment, setSupplierCom] = useState({
		auditorComments: audit && audit.comentarios ? audit.comentarios : '',
		supComment: audit && audit.supplierComments ? audit.supplierComments : '',
	})
	const translate = useSelector((state) => getTranslate(state.localize))
	let selectPlanData = getStatePlan()
	let ShowEvidence = audit
		? audit.actual_operation === QualityAuditStatus.ACCEPTED ||
		  audit.actual_operation === QualityAuditStatus.CLOSED ||
		  audit.actual_operation === QualityAuditStatus.EVIDENCE_SENT ||
		  audit.actual_operation === QualityAuditStatus.REQUEST_EVIDENCE ||
		  audit.actual_operation === QualityAuditStatus.CANCELLED
		: false

	const dispatch = useDispatch()

	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	useEffect(() => {
		if (audit !== null) {
			setSupplierSate({ planState: audit.supplierStatus })
			setSupplierCom({
				auditorComments: audit.comentarios,
				supComment: audit.supplierComments,
			})
		}
	}, [audit])

	const onOpenConfirmDialog = (status, isCancel) => {
		setDialogConfirm({ open: true, status: status, isCancel: isCancel })
	}

	const onUpdateAuditStatus = () => {
		return dispatch(updateAuditStatus(props.match.params.token, openDialogConfirm.status))
			.then(() => {
				onClosedDialog()
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
	}

	const [openDialogConfirm, setDialogConfirm] = useState({
		open: false,
		status: null,
		comments: '',
	})

	const onClosedDialog = () => {
		setDialogConfirm({
			open: false,
			status: null,
			sustainabilityId: null,
			comments: '',
		})
	}

	let canChangeResponsibleStatus = canBeResponsible(audit, userRolesByCompany, userProfile)

	const updateCommentsAndStatus = (property, data) => {
		return dispatch(updateAuditCommentsSupplierState(props.match.params.token, property, data))
	}

	const uploadFiles = (files, type) => {
		let auditToken = props.match.params.token
		let filesList = attachments.concat(files[0])
		let planActionList = planAction.concat(files[0])
		let actionPlanEvidenceList = actionPlanEvidence.concat(files[0])
		if (files.length > 0) {
			for (var index = 1; index < files.length; index++) {
				if (type === 1) filesList.push(files[index])
				if (type === 2) planActionList.push(files[index])
				if (type === 3) actionPlanEvidenceList.push(files[index])
			}
			return dispatch(addEvidenceSupplierAudit(auditToken, files, type))
				.then(() => {
					if (type === 1) setAttachments(filesList)
					if (type === 2) setPlanFile(planActionList)
					if (type === 3) setActionPlanEvidence(actionPlanEvidenceList)
					return Promise.resolve('Ok')
				})
				.catch(() => {
					return Promise.reject()
				})
		}
		return Promise.resolve('Ok')
	}

	const removeFiles = (fileName, type) => {
		let auditToken = props.match.params.token
		return dispatch(removeFileInSupplierAudit(auditToken, fileName, type))
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}

	const getCardContent = () => {
		if (isLoading === true) {
			return <LoadingPage />
		} else if (audit === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let selectedValue = getSelectedValue(selectPlanData, supplierSate.planState)
			return (
				<div>
					<List>
						<ListItem divider>
							<ListItemText
								primary={
									<>
										<strong>
											<Translate id='common.supplier' />
											{': '}
										</strong>
										{audit.full_supplier}
									</>
								}
							/>
						</ListItem>
						<ListItem divider>
							<ListItemText
								primary={
									<>
										<strong>
											<Translate id='common.preparedBy' />
											{': '}
										</strong>
										{audit.create_by}
									</>
								}
							/>
						</ListItem>
						<ListItem divider>
							<ListItemText
								primary={
									<>
										<strong>
											<Translate id='qualityAudit.auditDate' />
											{': '}
										</strong>
										{moment(audit.auditDate).format('DD/MM/YYYY')}
									</>
								}
							/>
						</ListItem>
						<ListItem divider>
							<ListItemText
								primary={
									<>
										<strong>
											{'RFC'}
											{': '}
										</strong>
										{audit.rfc}
									</>
								}
							/>
						</ListItem>
						<ListItem divider>
							<ListItemText
								primary={
									<>
										<strong>
											<Translate id='qualityAudit.typeAudit' />
											{': '}
										</strong>
										{audit.typeAudit === 1 ? 'Inicial' : audit.typeAudit === 2 ? 'Seguimiento' : ''}
									</>
								}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary={
									<>
										<strong>
											<Translate id='common.comments' />
											{': '}
										</strong>
									</>
								}
							/>
						</ListItem>
						<ListItem>
							<HtmlEditor
								id={'Comentarios'}
								labelVariant='caption'
								labelColor='textSecondary'
								isDisabled={
									!canChangeResponsibleStatus ||
									(canChangeResponsibleStatus &&
										audit.actual_operation !== QualityAuditStatus.ANSWERED_SUPPLIER &&
										audit.actual_operation !== QualityAuditStatus.EVIDENCE_SENT)
								}
								initialValue={supplierComment.auditorComments}
								onChange={(data) => updateCommentsAndStatus('Comentarios', data)}
							/>
						</ListItem>
					</List>
					<CardHeader
						style={{ paddingBottom: '0px', paddingTop: '8px', backgroundColor: '#e8eff2f6' }}
						title={
							<Typography gutterBottom variant='subtitle1' component='span' color='textPrimary'>
								<AttachFileIcon color='primary' /> <Translate id='qualityAudit.auditInfo' />
							</Typography>
						}
					/>
					<Table className={classes.table} aria-label='simple table'>
						<TableBody>
							{canChangeResponsibleStatus && (
								<TableRow key={'supplierStatus'}>
									<TableCell>
										<Translate id='qualityAudit.supplierStatus' />
										{': '}
									</TableCell>
									<TableCell>
										<Select
											isDisabled={
												audit.actual_operation === QualityAuditStatus.CLOSED ||
												audit.actual_operation === QualityAuditStatus.CANCELLED
											}
											options={selectPlanData}
											onChange={(data) => updateCommentsAndStatus('SupplierStatus', data)}
											value={selectedValue}
											menuPlacement='top'
										/>
									</TableCell>
								</TableRow>
							)}
							<TableRow key={'supplierAudit'}>
								<TableCell width='40%'>
									<AttachFileIcon /> <Translate id='qualityAudit.supplierAudit' />
								</TableCell>
								<TableCell>
									<Evidence
										baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
										removeFiles={(fileName) => removeFiles(fileName, 1)}
										text={translate('common.selectfiles')}
										isDisabled={audit.actual_operation !== QualityAuditStatus.DRAFT}
										// maxFiles={1}
										uploadFiles={(files) => uploadFiles(files, 1)}
										files={audit.attachments}
									/>
								</TableCell>
							</TableRow>
							<TableRow key={'actionPlan'}>
								<TableCell width='40%'>
									<AttachFileIcon /> <Translate id='qualityAudit.actionPlan' />
								</TableCell>
								<TableCell>
									<Evidence
										isDisabled={
											canChangeResponsibleStatus ||
											(!canChangeResponsibleStatus &&
												audit.actual_operation !== QualityAuditStatus.SEND_SUPPLIER &&
												audit.actual_operation !== QualityAuditStatus.RESEND_TO_SUPPLIER)
										}
										baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
										removeFiles={(fileName) => removeFiles(fileName, 2)}
										text={translate('common.selectfiles')}
										// maxFiles={1}
										uploadFiles={(files) => uploadFiles(files, 2)}
										files={audit.planAction}
									/>
								</TableCell>
							</TableRow>
							{ShowEvidence && (
								<TableRow key={'actionPlanEvidence'}>
									<TableCell width='40%'>
										<AttachFileIcon /> <Translate id='qualityAudit.actionPlanEvidence' />
									</TableCell>
									<TableCell>
										<Evidence
											isDisabled={
												canChangeResponsibleStatus ||
												(!canChangeResponsibleStatus &&
													audit.actual_operation !== QualityAuditStatus.ACCEPTED &&
													audit.actual_operation !== QualityAuditStatus.REQUEST_EVIDENCE)
											}
											baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
											removeFiles={(fileName) => removeFiles(fileName, 3)}
											text={translate('common.selectfiles')}
											// maxFiles={1}
											uploadFiles={(files) => uploadFiles(files, 3)}
											files={audit.actionPlanEvidence}
										/>
									</TableCell>
								</TableRow>
							)}
							<TableRow key={'statePlan'}>
								<TableCell width='40%'>
									<Translate id='qualityAudit.statePlan' />
									{': '}
								</TableCell>
								<TableCell>{audit.actual_operation_description}</TableCell>
							</TableRow>
							<TableRow key={'supplierComment'}>
								<TableCell width='40%'>
									<Translate id={'qualityAudit.supplierComment'} />
									{':'}
								</TableCell>
								<TableCell>
									<HtmlEditor
										id={'SupplierComments'}
										labelVariant='caption'
										labelColor='textSecondary'
										isDisabled={
											canChangeResponsibleStatus ||
											(!canChangeResponsibleStatus &&
												audit.actual_operation !== QualityAuditStatus.SEND_SUPPLIER &&
												audit.actual_operation !== QualityAuditStatus.RESEND_TO_SUPPLIER &&
												audit.actual_operation !== QualityAuditStatus.REQUEST_EVIDENCE &&
												audit.actual_operation !== QualityAuditStatus.ACCEPTED)
										}
										initialValue={supplierComment.supComment}
										onChange={(data) => updateCommentsAndStatus('SupplierComments', data)}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</div>
			)
		}
	}

	const onTitleStatusDialog = () => {
		switch (openDialogConfirm.status) {
			case QualityAuditStatus.SEND_SUPPLIER:
				return 'qualityAudit.sendToSupplier'
			case QualityAuditStatus.ANSWERED_SUPPLIER:
				return 'qualityAudit.sendActionPlan'
			case QualityAuditStatus.CLOSED:
				return 'qualityAudit.closedAudit'
			case QualityAuditStatus.CANCELLED:
				return 'qualityAudit.cancelAudit'
			case QualityAuditStatus.RESEND_TO_SUPPLIER:
				return 'qualityAudit.resendAudit'
			case QualityAuditStatus.ACCEPTED:
				return 'qualityAudit.aceptAudit'
			case QualityAuditStatus.EVIDENCE_SENT:
				return 'qualityAudit.sendEvidencePlan'
			case QualityAuditStatus.REQUEST_EVIDENCE:
				return 'qualityAudit.requestEvidence'
			default:
				return ''
		}
	}

	const onMessageStatusDialog = () => {
		switch (openDialogConfirm.status) {
			case QualityAuditStatus.SEND_SUPPLIER:
				return 'qualityAudit.supplierNotifi'
			case QualityAuditStatus.ANSWERED_SUPPLIER:
				return 'qualityAudit.supplierRespons'
			case QualityAuditStatus.ACCEPTED:
				return 'qualityAudit.aceptAuditMessage'
			case QualityAuditStatus.CLOSED:
				return 'qualityAudit.closMess'
			case QualityAuditStatus.CANCELLED:
				return 'qualityAudit.cancelAuditMessage'
			case QualityAuditStatus.RESEND_TO_SUPPLIER:
				return 'qualityAudit.resendAuditMessage'
			case QualityAuditStatus.EVIDENCE_SENT:
				return 'qualityAudit.sendEvidencePlanMessage'
			case QualityAuditStatus.REQUEST_EVIDENCE:
				return 'qualityAudit.requestEvidenceMessage'
			default:
				return ''
		}
	}

	return (
		<Card>
			<CardHeader
				title={
					<Typography variant='button'>
						{<Translate id='menu.audit' />} | {audit && audit.folio}
					</Typography>
				}
				subheader={
					<span>
						{audit && audit.creation_date_long}
						<br />
						<strong>{audit && audit.actual_operation_description}</strong>
					</span>
				}
				avatar={
					<Avatar
						style={{
							backgroundColor: getColorStatusAudit(audit && audit.actual_operation),
						}}
					>
						<FindInPage />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						{audit && (
							<div className={classes.stickyDiv}>
								{canChangeResponsibleStatus && audit.actual_operation === QualityAuditStatus.DRAFT && (
									<ComponentChangeStatus
										classes={classes}
										isDisabled={audit.attachments.length < 1}
										message={'qualityAudit.sendAudit'}
										openDialog={() => onOpenConfirmDialog(QualityAuditStatus.SEND_SUPPLIER)}
										status={QualityAuditStatus.SEND_SUPPLIER}
										numberOption={1}
									/>
								)}
								{!canChangeResponsibleStatus &&
									(audit.actual_operation === QualityAuditStatus.SEND_SUPPLIER ||
										audit.actual_operation === QualityAuditStatus.RESEND_TO_SUPPLIER) && (
										<ComponentChangeStatus
											classes={classes}
											isDisabled={audit.planAction.length < 1}
											message={'qualityAudit.sendActionPlan'}
											openDialog={() => onOpenConfirmDialog(QualityAuditStatus.ANSWERED_SUPPLIER)}
											status={QualityAuditStatus.ANSWERED_SUPPLIER}
											numberOption={1}
										/>
									)}
								{!canChangeResponsibleStatus &&
									(audit.actual_operation === QualityAuditStatus.ACCEPTED ||
										audit.actual_operation === QualityAuditStatus.REQUEST_EVIDENCE) && (
										<ComponentChangeStatus
											classes={classes}
											isDisabled={audit.actionPlanEvidence.length < 1}
											message={'qualityAudit.sendEvidencePlan'}
											openDialog={() => onOpenConfirmDialog(QualityAuditStatus.EVIDENCE_SENT)}
											numberOption={1}
										/>
									)}
								{canChangeResponsibleStatus &&
									audit.actual_operation === QualityAuditStatus.ANSWERED_SUPPLIER &&
									supplierSate.planState === 1 && (
										<ComponentChangeStatus
											classes={classes}
											isDisabled={false}
											message={'qualityAudit.aceptAudit'}
											openDialog={() => onOpenConfirmDialog(QualityAuditStatus.ACCEPTED)}
											openDialogCancel={() => onOpenConfirmDialog(QualityAuditStatus.CANCELLED)}
											openDialogResend={() =>
												onOpenConfirmDialog(QualityAuditStatus.RESEND_TO_SUPPLIER)
											}
											status={QualityAuditStatus.ACCEPTED}
											numberOption={3}
										/>
									)}
								{canChangeResponsibleStatus &&
									audit.actual_operation === QualityAuditStatus.ANSWERED_SUPPLIER &&
									supplierSate.planState !== 1 && (
										<ComponentChangeStatus
											classes={classes}
											isDisabled={false}
											message={'qualityAudit.resendAudit'}
											openDialog={() =>
												onOpenConfirmDialog(QualityAuditStatus.RESEND_TO_SUPPLIER)
											}
											openDialogCancel={() => onOpenConfirmDialog(QualityAuditStatus.CANCELLED)}
											status={QualityAuditStatus.RESEND_TO_SUPPLIER}
											numberOption={4}
										/>
									)}
								{canChangeResponsibleStatus &&
									audit.actual_operation === QualityAuditStatus.EVIDENCE_SENT &&
									supplierSate.planState === 1 && (
										<ComponentChangeStatus
											classes={classes}
											isDisabled={false}
											message={'qualityAudit.closedAudit'}
											openDialog={() => onOpenConfirmDialog(QualityAuditStatus.CLOSED)}
											openDialogResend={() =>
												onOpenConfirmDialog(QualityAuditStatus.REQUEST_EVIDENCE)
											}
											openDialogCancel={() => onOpenConfirmDialog(QualityAuditStatus.CANCELLED)}
											status={QualityAuditStatus.CLOSED}
											numberOption={5}
										/>
									)}
								{canChangeResponsibleStatus &&
									audit.actual_operation === QualityAuditStatus.EVIDENCE_SENT &&
									supplierSate.planState !== 1 && (
										<ComponentChangeStatus
											classes={classes}
											isDisabled={false}
											message={'qualityAudit.requestEvidence'}
											openDialog={() => onOpenConfirmDialog(QualityAuditStatus.REQUEST_EVIDENCE)}
											openDialogCancel={() => onOpenConfirmDialog(QualityAuditStatus.CANCELLED)}
											status={QualityAuditStatus.REQUEST_EVIDENCE}
											numberOption={6}
										/>
									)}
							</div>
						)}
					</div>
				}
			/>
			<CardContent>{getCardContent()}</CardContent>

			{openDialogConfirm.open && (
				<AddCommonItem
					title={<Translate id={onTitleStatusDialog()} />}
					message={translate(onMessageStatusDialog())}
					open={openDialogConfirm.open}
					onClose={onClosedDialog}
					onSaveItem={onUpdateAuditStatus}
					isSaving={isSaving}
					saveButtonTranslate={'common.confirm'}
					isMessage={true}
					isTextArea={false}
				/>
			)}
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</Card>
	)
}

const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
	},
	stickyDiv: {
		top: 88,
		zIndex: '300',
	},
	margin: {
		margin: theme.spacing.unit,
	},
	extendedIcon: {
		marginRight: theme.spacing.unit,
	},
	alert: {
		color: '#0D3C61',
		backgroundColor: '#ccf2ff',
		borderRadius: '.5em',
		margin: '10px 0px',
		padding: '12px',
	},
	alertPanel: {
		margin: '20px',
		minWidth: '400px',
		maxWidth: '600px',
		textAlign: 'center',
	},
})

DesktopView.propTypes = {
	/**
	 * Determinate if is loading information from back end
	 * and see view skeleton
	 */
	isLoading: PropTypes.bool.isRequired,
}

DesktopView.defaultProps = {
	isLoading: false,
	sustainability: {},
}
export default withRouter(withStyles(styles, { withTheme: true })(DesktopView))

/**
 * A component change status
 */
const ComponentChangeStatus = (props) => {
	const { classes, isDisabled, openDialog, message, numberOption, status, openDialogCancel, openDialogResend } = props
	const [openDialogOperation, setDialogOperation] = useState(false)

	const onDialogOperation = () => {
		setDialogOperation(!openDialogOperation)
	}

	const onMessageStatusDialog = () => {
		switch (status) {
			case QualityAuditStatus.SEND_SUPPLIER:
				return 'qualityAudit.supplierNotifi'
			case QualityAuditStatus.ANSWERED_SUPPLIER:
				return 'qualityAudit.supplierRespons'
			case QualityAuditStatus.ACCEPTED:
				return 'qualityAudit.aceptAuditMessage'
			case QualityAuditStatus.CLOSED:
				return 'qualityAudit.closMess'
			case QualityAuditStatus.CANCELLED:
				return 'qualityAudit.cancelAuditMessage'
			case QualityAuditStatus.RESEND_TO_SUPPLIER:
				return 'qualityAudit.resendAuditMessage'
			case QualityAuditStatus.EVIDENCE_SENT:
				return 'qualityAudit.sendEvidencePlanMessage'
			case QualityAuditStatus.REQUEST_EVIDENCE:
				return 'qualityAudit.requestEvidenceMessage'
			default:
				return ''
		}
	}

	function TableConfiguration(numberOption) {
		let columns = [
			{
				title: <Translate id={message} />,
				onClick: openDialog,
				isIconMaterial: true,
				description: <Translate id={onMessageStatusDialog()} />,
			},
		]
		if (numberOption === 3) {
			columns.push({
				title: <Translate id='qualityAudit.resendAudit' />,
				onClick: openDialogResend,
				isIconMaterial: true,
				description: <Translate id='qualityAudit.resendAuditMessage' />,
			})
		}
		if (numberOption === 5) {
			columns.push({
				title: <Translate id='qualityAudit.requestEvidence' />,
				onClick: openDialogResend,
				isIconMaterial: true,
				description: <Translate id='qualityAudit.requestEvidenceMessage' />,
			})
		}
		if (numberOption > 2) {
			columns.push({
				title: <Translate id='qualityAudit.cancelAudit' />,
				onClick: openDialogCancel,
				isIconMaterial: true,
				description: <Translate id='qualityAudit.cancelAuditMessage' />,
			})
		}
		return columns
	}

	return (
		<>
			{numberOption !== 1 && (
				<Fab
					variant='extended'
					size='small'
					color='primary'
					disabled={isDisabled}
					className={classes.margin}
					onClick={() => onDialogOperation()}
				>
					<MenuIcon />
					{<Translate id='common.AvailableOperations' />}
				</Fab>
			)}
			{numberOption === 1 && (
				<Fab
					variant='extended'
					size='small'
					color='primary'
					disabled={isDisabled}
					className={classes.margin}
					onClick={openDialog}
				>
					<MenuIcon className={classes.extendedIcon} />
					{<Translate id={message} />}
				</Fab>
			)}

			{
				<DialogOperation
					open={openDialogOperation}
					onClose={onDialogOperation}
					options={TableConfiguration(numberOption)}
				/>
			}
		</>
	)
}
ComponentChangeStatus.propTypes = {
	isDisabled: PropTypes.bool.isRequired,
	openDialog: PropTypes.func.isRequired,
	message: PropTypes.string.isRequired,
}
