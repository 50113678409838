
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import RawMaterialNonConfIcon from '@material-ui/icons/BugReport';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { CardHeader, Tooltip, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutline';
import ListIcon from '@material-ui/icons/ViewList';
import AceptedAutorizerIcon from '@material-ui/icons/ThumbsUpDownTwoTone';
import { Link } from '@material-ui/core';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LoadingPage from '../common/LoadingPage';
import FloatingButton from '../common/FloatingButton';
import DialogAvailableOperations from '../common/DialogAvailableOperations';
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow';
import InformationPartial from './components/InformationPartial';
import TrackingPartial from './components/TrackingPartial';
import HistoryCard from '../common/HistoryCard';
import DataTable from '../common/DataTable';
import Evidence from '../common/Evidence';
import AddCommonItem from '../common/AddCommonItem';
import Toaster from '../common/Toaster';
import QualityAuthorizersDialog from '../common/QualityAuthorizersDialog';
import AuthorizeDialog from '../common/AuthorizeDialog';
import WorkflowCancel from '../common/WorkflowCancel';

/** section actions */
import { loadSupplier } from '../../store/suppliers/SupplierActions';
import { CORRECTIVE_ACTION_NOTIFICATION, CORRECTIVE_ACTION_RESPONSE, WORKFLOW_CANCELLATION } from '../../store/helpers/WorkFlowDocumentsType';
import {
    loadSacQualityFromBackEnd, uploadFilesEvidence, removeFilesEvidence, closedCorrectiveActionSAC,
    authorizerRequest, rejectedRequest, deleteDraftCorrectiveAction, registerWarehouseEntrySACFromBackend,
    removeNonConformityFromCancel
} from '../../store/sacsQuality/SacsQualityActions';
import { converListToArray } from '../../store/helpers/ListHelper';
import { canUserValideModuleQuality,  } from '../../store/helpers/RolesHelper';
import {IsCurrentActiveDirectoryUser} from '../../store/helpers/SessionHelper';

/**
 * Container show RawMaterialNonConformity
 */
class Show extends Component {

    /**
     * Create an instance RawMaterialNonConformity
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            activeTab: 0,
            openAvailableOperationsDialog: false,
            openDeleteDraft: false,
            openViewToAuthorizer: false,
            openViewAutorizations: false,
            documentToken: '',
            openAddRegisterSAPDialog: false,
            notExtistFolio: "",
            openCloseReport: false,
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let sacQualityToken = this.props.match.params.token;
        this.props.loadSacQuality(sacQualityToken);
    }

    /**
     * componentDidUpdate
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof Index
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.sacQuality !== null) {
            /// Get information full from corrective
            let sacQuality = this.props.sacQuality;
            this.props.loadSupplier(sacQuality.supplier_token);
        }
    }

    /**
     * Action to set state to open of available operations dialog
     * @memberof 
     */
    setOpenDialog = (openAvailable) => {
        this.setState({
            openAvailableOperationsDialog: openAvailable,
        });
    }

    /**
     * Function to send notification from new raw material 
     */
    onClickToSendNotificationSacQuality() {
        let sacQualityToken = this.props.match.params.token;
        this.props.history.push(`/sacsquality/sendNotification/${sacQualityToken}`)
    }

    /**
     * Method to change the active tab
     */
    onClickToActiveTab = (event, value) => {
        this.setState({ activeTab: value });
    };

    /**
   * onDeleteDraft
   *
   * @param {*} item
   * @param {*} supplier
   * @memberof Create
   */
    onDeleteDraft() {
        this.setState({
            openAvailableOperationsDialog: false,
            openDeleteDraft: true,

        })
    }

    /**
    * onDeleteDraftCorrectiveAction
    */
    onDeleteDraftCorrectiveAction() {
        this.props.deleteDraftCorrectiveAction(this.props.sacQuality.token)
            .then(() => {
                this.setState({
                    openDeleteDraft: false,
                })
                this.props.history.push(`/sacsquality`)
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
     * method to change view to crreate actions by supplier.
     *
     * @memberof Show
     */
    onClickCorrectiveActionReponseFromSupplier() {
        let sacToken = this.props.match.params.token;
        this.props.history.push(`/sacsquality/createActionsBySupplier/${sacToken}`)
    }

    /**
    *  Determines if requires a user authorization
    */
    requiresUserAuthorization(document) {
        let currentUser = this.props.currentUser;
        let authorizers = document ? document.authorizers ? document.authorizers : [] : [];
        let isRequiered = false;
        if (document.status === 0 || document.status === 1) {
            authorizers.map(authorizer => {
                let user = authorizer.possible_authorizes.find(posible => (posible.user_name) === currentUser.profile.preferred_username);
                if (user || isRequiered)
                    return isRequiered = true;
                else
                    return isRequiered = user ? true : false;
            })
        }
        return isRequiered;

    }

    /**
     * Function to open dialog authoriser
     */
    onOpenViewToAutorizer() {
        this.setState({
            openViewToAuthorizer: true,
        })
    }

    /**
     * Function on click open view autorizations
     */
    onClickOpenAutorizations(documentToken) {
        this.setState({
            openViewAutorizations: true,
            documentToken
        })
    }

    /**
     * Function to authorizer request rework
     * @param {*} tokenRequest 
     * @param {*} comment 
     */
    onAcceptedRequest(tokenRequest, comment) {
        this.props.authorizerRequest(tokenRequest, comment)
            .then(() => {
                this.setState({
                    openViewToAuthorizer: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
     * Function to rejected rquest rework
     * @param {*} tokenRequest 
     * @param {*} comment 
     */
    onRejectedRequest(tokenRequest, comment) {
        this.props.rejectedRequest(tokenRequest, comment)
            .then(() => {
                this.setState({
                    openViewToAuthorizer: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    //Cancel a Sacks document
    onCancelDocument() {
        this.props.history.push(`/sacsquality/cancel/${this.props.sacQuality.token}`)
    }

    /**
 * method to change view to crreate actions by supplier.
 *
 * @memberof Show
 */
    onClickSendNotificationSacQuality() {
        let sacToken = this.props.match.params.token;
        this.props.history.push(`/sacsquality/sendNotification/${sacToken}`)
    }

    /**
     * onClickAddRegisterWarehouseEntry
     *
     * @param {*} item
     * @memberof Create
     */
    onClickAddRegisterWarehouseEntry() {
        this.setState({
            notExtistFolio: "",
            openAddRegisterSAPDialog: true,
            openAvailableOperationsDialog: false,
        })
    }

    /**
  * Functions to regiset warehouse entry 
  * @param {*} folio 
  */
    registerWarehouseEntry(folio) {
        this.props.registerWarehouseEntrySAC(folio)
            .then(() => {
                this.setState({
                    openAddRegisterSAPDialog: false,
                    notExtistFolio: "",
                })
            }).catch(() => {
                this.setState({
                    notExtistFolio: <Translate id="qualityfailurereport.entryFolioAlready" />,
                })
            });
    }

    /**
 * onClickCloseReport
 *
 * @param {*} item
 * @memberof Create
 */
    onClickCloseReportSAC() {
        this.setState({
            openCloseReport: true,
            openAvailableOperationsDialog: false,
        })
    }

    /**
     * Action on closedCorrectiveActionSAC
     */
    closedCorrectiveActionSAC() {
        this.props.closedCorrectiveActionSAC(this.props.sacQuality.token)
            .then(() => {
                this.setState({
                    openCloseReport: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.sacQuality !== null) {
            const { classes } = this.props;
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Divider />
                        <AppBar position="static" color="default">
                            <Tabs indicatorColor="secondary" textColor="secondary"
                                className={classes.styleTab}
                                value={this.state.activeTab}
                                onChange={this.onClickToActiveTab}>
                                <Tab label={<p><RawMaterialNonConfIcon />{" Información"} </p>} />
                                <Tab label={<p><i className="fa fa-file-image"></i> <Translate id="common.evidence"/></p>} />
                                <Tab label={<p> <Translate id="qualityfailurereport.tracking"/></p>} />
                            </Tabs>
                        </AppBar>
                        {this.state.activeTab === 0 &&
                            <InformationPartial  {...this.props} />
                        }
                        {this.state.activeTab === 1 &&
                            <Evidence
                                isDisabled={this.props.sacQuality.is_closed || !IsCurrentActiveDirectoryUser(this.props.currentUser)}
                                baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                                removeFiles={this.props.removeFiles}
                                uploadFiles={this.props.uploadFiles}
                                files={this.props.sacQuality.attachments}
                            />
                        }
                        {this.state.activeTab === 2 && <TrackingPartial {...this.props} />}

                    </Grid>
                </Grid>
            )
        }
    }

    /**
     * Render hystoryCard for Non conformity notification
     *
     * @param {*} planningEngineerName
     * @param {*} developmenEngineerName
     * @param {*} qualityUsersList
     * @returns
     * @memberof Show
     */
    renderAditionalDataSac(developmenEngineerName, qualityUsersList) {
        return (
            <Grid container spacing={24}>
                <CardHeader
                    style={{ paddingTop: '0px' }}
                    title={
                        <Typography gutterBottom variant="body2" component="p">
                            <strong>
                                <Translate id="common.sentTo" />:
                            </strong>
                        </Typography>
                    }
                    subheader={
                        <Typography gutterBottom variant="body2" component="p">
                            <Tooltip title={<Translate id='rawmaterialnonconformity.developmentEnginner' />}>
                                <PersonIcon color='secondary' />
                            </Tooltip>{' ' + developmenEngineerName}
                        </Typography>
                    }
                />
                <Grid item xs={12}>
                    <DataTable
                        title={
                            <Typography gutterBottom variant="body2" component="p">
                                <strong>
                                    <Translate id="rawmaterialnonconformity.qualityUsersSupplier" />:
                                </strong>
                            </Typography>
                        }
                        data={qualityUsersList}
                        configuration={{
                            columns: [{
                                header: <Translate id="officialNotices.name" />,
                                dataSource: 'full_name',
                            }]
                        }}
                    />
                </Grid>

            </Grid>
        )
    }

    /**
     * Render hystoryCard for Deviations
     *
     * @param {*} folio
     * @param {*} status
     * @returns
     * @memberof Show
     */
    renderAditionalDataActionsBySupplier(document) {
        const { classes } = this.props;
        let isRequieredToAutorizer = this.requiresUserAuthorization(document);
        let attachments = [];
        if (document.attachments) {
            document.attachments.map(attachment => {
                return (attachments.push(<span key={attachment.name}>
                    <Link component={'a'} target="_blank" href={`${process.env.REACT_APP_SIP_QUALITY_URL_FILES}/${attachment.relativePath}`}>
                        {attachment.name}
                    </Link><br />
                </span>))
            })
        }
        return (
            <Grid container spacing={24} >
                <CardHeader
                    style={{ paddingTop: '0px' }}
                    title={
                        <Typography gutterBottom variant="body2" component="p">
                            <Translate id="sacsQuality.rootCause" />:{' ' + document.problem_cause}<br />
                            <Translate id="common.comments" />:{' ' + document.comment} <br />
                        </Typography>
                    }
                />
                <Grid item xs={12}>
                    <Typography gutterBottom variant="body2" component="p">
                        <strong>
                            <Translate id="common.evidence" />:
                        </strong>
                    </Typography>
                    {attachments}<br />
                </Grid>
                <Grid container spacing={24} >
                    <Button variant="contained" onClick={() => { this.onClickOpenAutorizations(document.token) }} color="primary" className={classes.button}>
                        <ListIcon className={classes.leftIcon} />
                        {<Translate id="reworks.viewAutorizers"/>}
                    </Button>

                    <div className={classes.grow}></div>
                    {isRequieredToAutorizer &&
                        <Button variant="contained" onClick={this.onOpenViewToAutorizer} color="secondary" className={classes.button}>
                            <AceptedAutorizerIcon className={classes.leftIcon} />
                            {<Translate id="reworks.autorizer"/>}
                        </Button>
                    }

                    {/** Dialog view autoritations */}
                    < QualityAuthorizersDialog
                        open={this.state.openViewAutorizations && this.state.documentToken === document.token}
                        onClose={() => { this.setState({ openViewAutorizations: false, documentToken: null }) }}
                        isSaving={this.props.isLoading}
                        document={document}
                        dialogTitle={'sacsQuality.viewAutorizersTitle'}
                    />
                    <AuthorizeDialog
                        open={this.state.openViewToAuthorizer}
                        onClose={() => { this.setState({ openViewToAuthorizer: false }) }}
                        isSaving={this.props.isLoading}
                        document={document}
                        onAcceptedRequest={this.onAcceptedRequest}
                        onRejectedRequest={this.onRejectedRequest}
                    />

                </Grid>
            </Grid>
        )
    }

    /**
     * Render hystory section for all documents
     *
     * @returns
     * @memberof Show
     */
    renderHistory() {
        let documents = this.props.sacQuality ? this.props.sacQuality.documents ? this.props.sacQuality.documents : [] : []
        return (
            documents.map(document => {
                let titleId;
                let aditionalData;
                if (document.document_type === CORRECTIVE_ACTION_NOTIFICATION) {
                    titleId = 'sacsQuality.sacQualityNotification';
                    aditionalData = this.renderAditionalDataSac(document.development_engineer_name, document.users_quality);
                }
                if (document.document_type === CORRECTIVE_ACTION_RESPONSE) {
                    titleId = 'sacsQuality.actionsBySupplierNotification';
                    aditionalData = this.renderAditionalDataActionsBySupplier(document);
                }
                if (document.document_type === WORKFLOW_CANCELLATION) {
                    titleId = 'common.folioCancel';
                    aditionalData = <WorkflowCancel document={document} />;
                }
                return (
                    <HistoryCard
                        key={document.token}
                        title={titleId}
                        userCreator={document.create_by}
                        creationDate={document.creation_date_long}
                        status={document.status}
                        aditionalData={aditionalData}
                    />
                )

            })
        )
    }

    render() {
        let optonsAvalinable = this.props.sacQuality ? this.props.sacQuality.get_available_operations : [];
        return (
            <Card>
                {optonsAvalinable.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

                <LayoutHeaderWorkFlow {...this.props}
                    workFlow={this.props.sacQuality}
                    title={<Translate id="sacsQuality.titleSac"/>}
                />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {this.renderHistory()}

                <DialogAvailableOperations
                    {...this.props}
                    options={optonsAvalinable}
                    isValidToCancel={canUserValideModuleQuality(this.props.userRolesByCompany)}
                    openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
                    setOpenDialog={this.setOpenDialog}
                    onClickToSendNotificationSacQuality={this.onClickSendNotificationSacQuality}
                    onDeleteDraft={this.onDeleteDraft}
                    onClickCorrectiveActionReponseFromSupplier={this.onClickCorrectiveActionReponseFromSupplier}
                    onCancelDocument={this.onCancelDocument}
                    onRegisterWarehouseEntryCorrective={this.onClickAddRegisterWarehouseEntry}
                    onClickCloseReportSAC={this.onClickCloseReportSAC}
                />


                {/** Dialog to delete draft */}
                < AddCommonItem
                    title={<Translate id="sacsQuality.removeSac"/>}
                    message={<Translate id="sacsQuality.messageToSac"/>}
                    open={this.state.openDeleteDraft}
                    onClose={() => { this.setState({ openDeleteDraft: false }) }}
                    onSaveItem={this.onDeleteDraftCorrectiveAction}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                />


                {/** Dialog to Add Register Warehouse Entry */}
                < AddCommonItem
                    title={<Translate id="qualityfailurereport.registerEntrySap"/>}
                    message={<Translate id="qualityfailurereport.entryFolioSap"/>}
                    open={this.state.openAddRegisterSAPDialog}
                    onClose={() => { this.setState({ openAddRegisterSAPDialog: false }) }}
                    onSaveItem={this.registerWarehouseEntry}
                    isSaving={this.props.isSaving}
                    notExtistFolio={this.state.notExtistFolio}
                    isInput={true}
                />

                {/** Dialog to close report */}
                < AddCommonItem
                    title={<Translate id="statusDescriptions.close"/>}
                    message={<Translate id="statusDescriptions.messageClose"/>}
                    open={this.state.openCloseReport}
                    onClose={() => { this.setState({ openCloseReport: false }) }}
                    onSaveItem={this.closedCorrectiveActionSAC}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                />

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        )
    }

}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let sacQuality = state.sacsQuality.get("sacQuality");
    let selectedCompany = state.profile.get("selectedCompany");
    let userRolesByCompany = state.profile.get('userRolesByCompany');

    return {
        user: user,
        isLoadingUser: state.oidc.isLoadingUser,
        currentUser: user,
        isLoading: state.sacsQuality.get('isLoading'),
        sacQuality: sacQuality ? sacQuality.toJS() : null,
        isSaving: state.sacsQuality.get('isSaving'),
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        company: selectedCompany ? selectedCompany.toJS() : null,
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],

    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadSacQuality: (token) => {
            return dispatch(loadSacQualityFromBackEnd(token));
        },
        loadSupplier: (token) => {
            return (dispatch(loadSupplier(token)))
        },
        uploadFiles: (files) => {
            return dispatch(uploadFilesEvidence(files));
        },
        removeFiles: (fileName) => {
            return dispatch(removeFilesEvidence(fileName));
        },
        deleteDraftCorrectiveAction: (token) => {
            return dispatch(deleteDraftCorrectiveAction(token));
        },
        authorizerRequest: (tokenRequest, comment) => {
            return (dispatch(authorizerRequest(tokenRequest, comment)))
        },
        rejectedRequest: (tokenRequest, comment) => {
            return (dispatch(rejectedRequest(tokenRequest, comment)))
        },
        registerWarehouseEntrySAC: (folio) => {
            return dispatch(registerWarehouseEntrySACFromBackend(folio))
        },
        closedCorrectiveActionSAC: (token) => {
            return dispatch(closedCorrectiveActionSAC(token))
        },
        removeNonConformityFromCancel: (token, tokenNonConformity) => {
            return (dispatch(removeNonConformityFromCancel(token, tokenNonConformity)))
        },
    }
}

const styles = theme => ({
    actionsToolbar: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    margin: {
        margin: theme.spacing.unit * 2,
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));