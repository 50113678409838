import axios from 'axios'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import moment from 'moment'
import { onGetBlobType } from '../helpers/BlobTypeHelper'
import { SecurityCourseStatusEnum } from '../helpers/SecurityCoursesHelper'

export const IS_SAVING = 'IS_SAVING'
export const IS_LOADING_VIDEO = 'IS_LOADING_VIDEO'
export const SET_SECURITY_COURSES = 'SET_SECURITY_COURSES'
export const SET_SECURITY_COURSE = 'SET_SECURITY_COURSE'
export const SET_DETAIL_COURSE = 'SET_DETAIL_COURSE'

/**
 * Action start saving information
 * @param {*} isLoading
 */
function isSavingCommon(isSaving) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
	}
}
/**
 * Action start saving information
 * @param {*} isLoading
 */
export function isLoadingVideo(isLoading) {
	return {
		type: IS_LOADING_VIDEO,
		isLoadVideo: isLoading,
	}
}
function setSecurityCoursesPaginated(courses) {
	return {
		type: SET_SECURITY_COURSES,
		courses: courses,
	}
}
function setSecurityCourse(course) {
	return {
		type: SET_SECURITY_COURSE,
		course: course,
	}
}
function setDetailCourse(course) {
	return {
		type: SET_DETAIL_COURSE,
		course: course,
	}
}
export function createSecurityCourses(information) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SecurityCourse/create`
		const userName = getState().oidc.user.profile.username
		let formData = new FormData()
		formData.append('RequestBy', userName)
		formData.append('Status', SecurityCourseStatusEnum.New)
		formData.append('CourseName', information.CourseName)
		formData.append('CourseNotes', information.CourseNotes)
		formData.append('ActivationDate', moment(information.ActiveDate).format('DD/MM/YYYY'))
		formData.append('ExpirationDate', moment(information.ExpirationDate).format('DD/MM/YYYY'))
		information.MediaFile.forEach((file) => {
			formData.append('data', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSecurityCourse(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function loadSecurityCoursesPaginated(queryParams) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SecurityCourse?`
		console.log({ queryParams })
		if (queryParams && queryParams.query) endPoint = endPoint + `query=${queryParams.query}`
		if (queryParams && queryParams.pageNumber) endPoint = endPoint + `&pageNumber=${queryParams.pageNumber - 1}`
		if (queryParams && queryParams.rowsPerPage) endPoint = endPoint + `&pageSize=${queryParams.rowsPerPage}`
		if (queryParams && queryParams.sortBy)
			endPoint =
				endPoint +
				`&sortBy=${queryParams.sortBy && queryParams.sortBy.length > 0 ? queryParams.sortBy : 'ActiveDate'}`
		if (queryParams && queryParams.sortDirection)
			endPoint =
				endPoint +
				`&sortDirection=${
					queryParams.sortDirection && queryParams.sortDirection.length > 0
						? queryParams.sortDirection
						: 'desc'
				}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSecurityCoursesPaginated(serverResponse.data))
					dispatch(isSavingCommon(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error getting security courses paginated', error.response)
				return Promise.reject(error.response)
			})
	}
}
export function getSecurityCourseById(id) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SecurityCourse/detail/${id}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDetailCourse(serverResponse.data))
					dispatch(isSavingCommon(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error getting security course', error.response)
				return Promise.reject(error.response)
			})
	}
}
export function getSecurityCourseVideo(id, fileName, folderName) {
	return function (dispatch, getState) {
		dispatch(isLoadingVideo(true))
		const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		config.responseType = 'arraybuffer'
		const endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SecurityCourse/detail/${id}/${fileName}/${folderName}`
		return axios
			.put(
				endPoint,
				{},
				{
					responseType: 'arraybuffer',
					headers: config.headers,
				}
			)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1) || fileName
					const fileBlob = new Blob([serverResponse.data], {
						type: onGetBlobType(fileExtension),
					})
					const url = window.URL.createObjectURL(fileBlob)
					const type = fileBlob.type
					const fileSizeBytes = fileBlob.size
					const fileSizeMB = (fileSizeBytes / (1024 * 1024)).toFixed(2)
					return Promise.resolve({ url, type, fileSizeMB })
				}
			})
			.catch((error) => {
				dispatch(isLoadingVideo(false))
				console.error('Error for get security course video:', error.response)
				return Promise.reject(error.response)
			})
	}
}
export function deleteSecurityCourse(id, fileName, folderName) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SecurityCourse/detail/${id}/${fileName}/${folderName}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error delete security course', error.response)
				return Promise.reject(error.response)
			})
	}
}
