import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import moment from 'moment'

/** Import component section */
// import '../../resources/styles/autosuggest.css'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import Toaster from '../../common/Toaster'
import SuppliersCard from './components/SuppliersCard'
// import UserCard from './components/UserCard';

/** Translations imports section */
import { Translate, getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

/** Import section actions */
import { searchBySuppliersByQury } from '../../../store/suppliers/SupplierActions'
import { createEthicCodeData } from '../../../store/ethicCode/ethicCodeAction'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import BaseInfoEthicCode from './components/BaseInfoEthicCode'
import { showSuccessToaster, showErrorToaster, showInfoToaster } from '../../../store/helpers/ToasterHelpers'

class Create extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			ethicCode: {
				suppliers: [],
				attachments: [],
				name: '',
				activationDate: '',
				expirationDate: '',
				acceptedBySupplier: false,
				optionAll: true,
				optionNationals: false,
				optionForeigns: false,
				startDate: null,
				endDate: null,
				option: 'optionAll',
			},
		}
	}

	validateChecks(ethicCodeUpdate, property, value) {
		if (property === 'optionNationals' && value) {
			ethicCodeUpdate['optionAll'] = false
			ethicCodeUpdate['optionForeigns'] = false
		} else if (property === 'optionForeigns' && value) {
			ethicCodeUpdate['optionAll'] = false
			ethicCodeUpdate['optionNationals'] = false
		} else if (property === 'optionAll' && value) {
			ethicCodeUpdate['optionNationals'] = false
			ethicCodeUpdate['optionForeigns'] = false
		}
		ethicCodeUpdate[property] = value
		if (!ethicCodeUpdate['optionNationals'] && !ethicCodeUpdate['optionForeigns'])
			ethicCodeUpdate['optionAll'] = true

		return ethicCodeUpdate
	}

	updateProperty(property, value) {
		let ethicCodeUpdate = { ...this.state.ethicCode }
		if (property === 'optionNationals' || property === 'optionForeigns' || property === 'optionAll')
			ethicCodeUpdate = this.validateChecks(ethicCodeUpdate, property, value)
		else ethicCodeUpdate[property] = value
		this.setState({
			ethicCode: ethicCodeUpdate,
		})
	}

	updateSuppliersList(value) {
		let ethicCodeUpdate = { ...this.state.ethicCode }
		if (value !== null && value !== undefined) {
			if (
				!ethicCodeUpdate['suppliers'].find((supplier) => {
					return supplier.token === value.token
				})
			) {
				if (value.full_name) {
					let supplier = { name: value.full_name, token: value.token }
					ethicCodeUpdate['suppliers'] = ethicCodeUpdate['suppliers'].concat(supplier)
					this.setState({
						ethicCode: ethicCodeUpdate,
					})
				} else {
					let supplier = { name: value.name, token: value.token }
					ethicCodeUpdate['suppliers'] = ethicCodeUpdate['suppliers'].concat(supplier)
					this.setState({
						ethicCode: ethicCodeUpdate,
					})
				}
			} else {
				this.setState(showInfoToaster('common.repeatedSelection'))
			}
		}
	}

	removeSupplierFromList(supplierToken) {
		let ethicCodeUpdate = { ...this.state.ethicCode }
		let newSuppliersList = ethicCodeUpdate['suppliers'].filter((supplier) => {
			return supplier.token !== supplierToken
		})
		ethicCodeUpdate['suppliers'] = newSuppliersList
		this.setState({
			ethicCode: ethicCodeUpdate,
		})
	}

	canBeSaved() {
		if (this.props.isSaving) return false
		else {
			let ethicCode = this.state.ethicCode
			if (IsNullOrEmpty(ethicCode.name)) return false
			if (ethicCode.suppliers.length <= 0) return false
			if (ethicCode.activationDate.length === 0) return false
			if (ethicCode.expirationDate.length === 0) return false
			if (moment(ethicCode.expirationDate).isBefore(ethicCode.activationDate)) return false
		}
		return true
	}

	/**
	 * Remove files
	 */
	removeFiles(fileName) {
		let attachments = this.state.ethicCode.attachments.filter((file) => {
			return file.name !== fileName
		})
		this.setState({
			ethicCode: {
				...this.state.ethicCode,
				attachments,
			},
		})
		return Promise.resolve('Ok')
	}

	/**
	 * Add file
	 * @param {*} attachments
	 */
	uploadFiles(files) {
		let filesList = this.state.ethicCode.attachments
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.push(files[index])
			}
		}
		this.setState({
			ethicCode: {
				...this.state.ethicCode,
				attachments: filesList,
			},
		})
		return Promise.resolve('Ok')
	}

	onCreateEthicCode() {
		this.props
			.createEthicCodeData(this.state.ethicCode)
			.then((ethicCodeToken) => {
				this.setState(showSuccessToaster('common.addCorrectly'))
				this.props.history.push(`/ethiccode/${ethicCodeToken}`)
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToAdd'))
			})
	}

	render() {
		return (
			<Grid container spacing={24}>
				<Grid item xs={6}>
					<BaseInfoEthicCode
						dropzoneText={this.props.translate('dropzone.dropzoneBaseText')}
						updateProperty={this.updateProperty}
						ethicCode={this.state.ethicCode}
						onCreate={this.onCreateEthicCode}
						canBeSaved={this.canBeSaved()}
						uploadFiles={this.uploadFiles}
						removeFiles={this.removeFiles}
						{...this.props}
					/>
				</Grid>
				<Grid item xs={6}>
					<SuppliersCard
						ethicCode={this.state.ethicCode}
						updateProperty={this.updateProperty}
						updateSuppliersList={this.updateSuppliersList}
						suppliers={this.state.ethicCode.suppliers}
						removeSupplier={this.removeSupplierFromList}
						{...this.props}
					/>
				</Grid>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Grid>
		)
	}
}

function mapStateToProps(state) {
	return {
		isLoadingSuppliers: state.suppliers.get('isLoadingSuppliers'),
		isSaving: state.ethicCodes.get('isSavingEthicCode'),
		translate: getTranslate(state.localize),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		searchBySuppliersByQury: (query) => {
			dispatch(searchBySuppliersByQury(query))
		},
		createEthicCodeData: (ethicCode) => {
			return dispatch(createEthicCodeData(ethicCode))
		},
	}
}

const viewStyles = (theme) => ({
	userAvatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 240,
	},
	toolBar: {
		backgroundColor: 'red',
	},
	button: {
		margin: theme.spacing.unit,
	},
	errorMessage: {
		color: theme.palette.common.white,
	},
	formatCheck: {
		display: 'flex',
		marginLeft: theme.spacing.unit,
		width: 460,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create)))
)
