import React, { Component } from 'react'
import autoBind from 'auto-bind'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import { TextField, ListItem, ListItemText, Typography, InputAdornment } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import DeliveryOrdersIcon from '@material-ui/icons/DepartureBoard'
import { GetTransportType } from '../../../../store/helpers/SelectOptions'

import { WORKFLOW_CANCELLATION } from '../../../../store/helpers/WorkFlowDocumentsType'
import { IsCurrentActiveDirectoryUser } from '../../../../store/helpers/SessionHelper'
import { Check } from '@material-ui/icons'

/**
 * Component ot render Information partialr delivery order
 */
export default class InformationPartial extends Component {
	/**
	 * Create an instance delivery order
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * Method to find document in delivery order action by document_type
	 *
	 * @param {*} documentType
	 * @returns
	 * @memberof Show
	 */
	findDocument(documentType) {
		if (
			this.props.deliveryOrder &&
			this.props.deliveryOrder.documents &&
			this.props.deliveryOrder.documents.length > 0
		)
			return this.props.deliveryOrder.documents.find((document) => {
				return document.document_type === documentType
			})
		else return null
	}

	/**
	 * getInformationOrder
	 * @param {*} propertyData
	 * @param {*} textFieldTranslateId
	 */
	getInformationOrder(propertyData, textFieldTranslateId) {
		return (
			<ListItem divider>
				<ListItemText
					secondary={
						<React.Fragment>
							<Typography
								component='span'
								variant='body2'
								className={this.props.classes.inline}
								color='textPrimary'
							>
								<Translate id={textFieldTranslateId} />
							</Typography>
							{': '}
							{propertyData}{' '}
						</React.Fragment>
					}
				/>
			</ListItem>
		)
	}

	setArrivalTime() {
		return (
			<TextField
				defaultValue={this.props.deliveryOrder.arrival_time}
				name='Arrival Time'
				component='span'
				onChange={this.props.setArrivalTime}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end' component='span'>
							<Check  onClick={this.props.updateArrivalTime} cursor={'pointer'} style={{color: "rgba(121, 216, 126, 1)"}} />
						</InputAdornment>
					),
				}}
			/>
		)
	}

	/**
	 * Render
	 */
	render() {
		const { classes } = this.props
		let documentCancel = this.findDocument(WORKFLOW_CANCELLATION)
		let transportsType = GetTransportType()
		let typeTranport = this.props.deliveryOrder.type_transport
			? transportsType[this.props.deliveryOrder.type_transport].label
			: ''
		let descriptionTrasnport = this.props.deliveryOrder.is_transport_gasoline
		return (
			<Paper>
				<List>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='deliveryOrderModule.printDateTime' />
									</Typography>
									{': '}
									{this.props.deliveryOrder.print_date} {this.props.deliveryOrder.print_time}
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										{' - '}
										<Translate id='deliveryOrderModule.estimatedArrivalDateTime' />
									</Typography>
									{': '}
									{this.props.deliveryOrder.delivery_date} {this.props.deliveryOrder.delivery_time}
								</React.Fragment>
							}
						/>
					</ListItem>
					{this.getInformationOrder(
						this.props.deliveryOrder.hours_entry_plant,
						'deliveryOrderModule.estimatedPlantDischargeTime'
					)}
					{this.getInformationOrder(this.props.deliveryOrder.ramp_number, 'deliveryOrderModule.rampNumber')}
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='deliveryOrderModule.status' />
									</Typography>
									{': '}
									{
										<strong
											style={{ fontSize: '100%', color: 'white' }}
											className={this.props.deliveryOrder.status_color}
										>
											<DeliveryOrdersIcon /> {this.props.deliveryOrder.get_status_in_time}
										</strong>
									}
								</React.Fragment>
							}
						/>
					</ListItem>
					{this.props.deliveryOrder.ramp_number === 3 && IsCurrentActiveDirectoryUser(this.props.user) && (
						<ListItem divider>
							<ListItemText
								secondary={
									<React.Fragment>
										<Typography
											component='span'
											variant='body2'
											className={classes.inline}
											color='textPrimary'
										>
											<Translate id='deliveryOrderModule.arrivalTime' />
										</Typography>
										{': '}
										{!this.props.deliveryOrder.is_closed
											? this.setArrivalTime()
											: this.props.deliveryOrder.arrival_time}
									</React.Fragment>
								}
							/>
						</ListItem>
					)}
					{typeTranport && (
						<ListItem divider>
							<ListItemText
								primary={
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<strong>
											<Translate id='deliveryOrderModule.carbonFootprint.transportInformation' />
										</strong>
									</Typography>
								}
								secondary={
									<React.Fragment>
										{
											<span>
												{' — '}
												<Translate id='deliveryOrderModule.carbonFootprint.typeTransport' />:{' '}
												{typeTranport}
												{' | '}
												{descriptionTrasnport && (
													<Translate id='deliveryOrderModule.carbonFootprint.gasoline' />
												)}
												{!descriptionTrasnport && (
													<Translate id='deliveryOrderModule.carbonFootprint.diesel' />
												)}{' '}
												<br />
											</span>
										}
										{
											<span>
												{' — '}
												<Translate id='deliveryOrderModule.carbonFootprint.originTransport' />:{' '}
												{this.props.deliveryOrder.destination_name} {' - '}{' '}
												{this.props.deliveryOrder.destionation_kilometres} {' km'}
												<br />
											</span>
										}
										{
											<span>
												{' — '}
												<Translate id='deliveryOrderModule.carbonFootprint.transportFreightName' />
												: {this.props.deliveryOrder.freight_name}
												<br />
											</span>
										}
										{
											<span>
												{' — '}
												<Translate id='deliveryOrderModule.carbonFootprint.emissionFactor' />:{' '}
												{this.props.deliveryOrder.emission_factor}{' '}
												<Translate id='deliveryOrderModule.carbonFootprint.emissionFactorUnit' />
												<br />
											</span>
										}
									</React.Fragment>
								}
							/>
						</ListItem>
					)}
					{documentCancel && (
						<span>
							{this.getInformationOrder(
								documentCancel.creation_date_long,
								'deliveryOrderModule.dateCancel'
							)}
							{this.getInformationOrder(documentCancel.create_by, 'deliveryOrderModule.cancelBy')}
							{this.getInformationOrder(
								documentCancel.reason_for_cancellation,
								'deliveryOrderModule.commentCancel'
							)}
						</span>
					)}
				</List>
			</Paper>
		)
	}
}

/**
 * deliveryOrder
 */
InformationPartial.propTypes = {
	deliveryOrder: PropTypes.object.isRequired,
}
