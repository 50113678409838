import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';

/** Import Material-UI */
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import EditIcon from '@material-ui/icons/Edit';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/BusinessTwoTone';
import PersonIcon from '@material-ui/icons/Person';
//import ChevronRightIcon from '@material-ui/icons/ChevronLeft';
import Tooltip from "@material-ui/core/Tooltip";

/**
 * ToolbarDialog component
 *
 * @class ToolbarDialog
 * @extends {Component}
 */
class ToolbarDialog extends Component {

    /**
     * Render
     *
     * @returns
     * @memberof Toast
     */
    render() {
        const { classes } = this.props;
        return (
            <Toolbar>
                  {this.props.isCompany &&<Avatar alt="Remy Sharp" className={classes.logoCompany}>
                     <BusinessIcon />
                </Avatar>}

                {this.props.isUser &&<Avatar alt="Remy Sharp" className={classes.avatar}>
                    <PersonIcon />
                </Avatar>}
                
                <Typography variant="h5">
                    {this.props.title}
                </Typography>
                <div className={classes.grow}></div>

                {this.props.canBeEdit &&  <Tooltip title="Editar"><IconButton
                    data-cy="btn-edit"
                    className={classes.edit}
                    onClick={() => {
                        if (this.props.setViewInEditMode) {
                            this.props.setViewInEditMode()
                        }
                    }}>
                    <EditIcon />
                </IconButton></Tooltip>}

            </Toolbar>
        );
    }
}

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    logoCompany:{
        backgroundColor: theme.palette.secondary.main,
        marginLeft : 80,
        margin: 10,
        width: 60,
        height: 60,
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        marginLeft : 80,
        margin: 10,
        width: 60,
        height: 60,
    },
    avatarFileInput: {
        display: "none"
    },
    edit:{
        marginRight: 30,
        backgroundColor: theme.palette.text.hint
    },
    return:{
         margin: 5,
        backgroundColor: theme.palette.text.hint
    }
});

export default withStyles(styles)(ToolbarDialog);