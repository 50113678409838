import { createSelector } from 'reselect'
import { toSafeJS } from '../helpers/ImmutableHelper'

const getIndexState = (state) => state.sparePartPriceChange

/**
 * Get sortCriteria
 */
export const getSortCriteria = createSelector([getIndexState], (indexState) => {
	const sortCriteria = indexState.get('sortCriteria')
	return sortCriteria ? toSafeJS(sortCriteria) : null
})
/**
 * Get searchQuery
 */
export const getSearchQuery = createSelector([getIndexState], (indexState) => {
	const searchQuery = indexState.get('searchQuery')
	return searchQuery ? toSafeJS(searchQuery) : null
})
/**
 * Get searchQuery
 */
export const getPagination = createSelector([getIndexState], (indexState) => {
	const page = indexState.get('page')
	const rowsPerPage = indexState.get('rowsPerPage')
	const total = indexState.get('sparePartPriceChangeCount')
	const pagination = { page, rowsPerPage, total }
	return pagination ? toSafeJS(pagination) : null
})
/**
 * Get requests list
 */
export const getRequestsList = createSelector([getIndexState], (indexState) => {
	const sparePartPriceChangeRequests = indexState.get('sparePartPriceChangeRequests')
	return sparePartPriceChangeRequests ? toSafeJS(sparePartPriceChangeRequests) : []
})

export const getPricesList = createSelector([getIndexState], (indexState) => {
	const sparePartPrices = indexState.get('prices')
	return sparePartPrices ? toSafeJS(sparePartPrices) : []
})
