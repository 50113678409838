import { fromJS } from 'immutable';

import * as contraActions from './SupplierConstractAction'

/**
 * contraActions initial state
 */
export const contractInitialState = fromJS({
    isLoadingContracts: true,
    contracts: null,
    isSavingContract: false,
    contractsCount: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    expedientSupplierCount: 0,
    isLoadingContractor: false,
    supplierContractor: null,
    isDownloadingReport: false,
})

export default function companiesReducer(state = contractInitialState, action) {
    switch (action.type) {
        case contraActions.START_LOADING_CONTRACTS: {
            return state.merge({
                isLoadingContracts: action.isLoading
            })
        }
        case contraActions.SET_CONTRACTS_LIST: {
            return state.merge({
                contracts: fromJS(action.contracts),
                isLoadingContracts: false
            })
        }
        case contraActions.CHANGE_CONTRACTS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case contraActions.CHANGE_CONTRACTS_SORT_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case contraActions.CHANGE_CONTRACTS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case contraActions.SET_CONTRACTS_COUNT:{
            return state.merge({
                contractsCount: action.count
            })
        }
        case contraActions.IS_SAVING_CONTRACT: {
            return state.merge({
                isSavingContract: action.isSaving
            })
        }
        case contraActions.ADD_CONTRACT_TO_LIST:{
            let contractToAdd = fromJS(action.contract)
            return state.updateIn(["contracts"], (allcontracts) => allcontracts.push(contractToAdd))
            .set('isSavingContract', false);
        }
        case contraActions.REMOVE_CONTRACT_TO_LIST:{
            return state.updateIn(['contracts'], (allcontracts) => {
                let index = allcontracts.findIndex((item) => {
                    return item.get('token') === action.contractToken
                });
                return allcontracts.delete(index).set('isSavingContract', false);
            })
        }
        case contraActions.IS_LOADING_SUPPLIER_CONTRACT: {
            return state.merge({
                isLoadingContractor: action.isLoading,
                supplierContractor: null,
            })
        }
        case contraActions.SET_SUPPLIER_CONTRACT: {
            return state.merge({
                isLoadingContractor: false,
                supplierContractor: fromJS(action.supplierContractor)
            })
        }
        case contraActions.IS_DOWNLOAD_REPORT_CONTRACT:{
            return state.merge({
                isDownloadingReport: action.isDownloading,
            })
        }
        default:
            return state;
    }
}