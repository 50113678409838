import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { Grid, TextField, Toolbar, Typography, withStyles } from '@material-ui/core'

import Evidence from '../../../common/Evidence'
import EmployeeSelector from '../../components/EmployeeSelector'
import DialogActions from '../../../common/DialogActions'
import Dialog from '../../../common/Dialog'
import DatePicker from '../../../common/DateTimePicker/DatePicker'
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper'

const AddTrainingDialog = (props) => {
	const { classes, open, onClose, addTraining, isSavingTraining } = props
	const [file, OnFile] = useState([])
	const [selectedEmployee, onSelectedEmployee] = useState(null)
	const [startDate, onStartDate] = useState(moment())
	const [endDate, onEndDate] = useState(moment().add(1, 'year'))
	const [name, onName] = useState('')

	const uploadTrainingFiles = (files) => {
		let filesList = file.concat(files[0])
		if (files.length > 0) {
			for (var index = 1; index < files.length; index++) {
				filesList.push(files[index])
			}
		}
		OnFile(filesList)
		return Promise.resolve('Ok')
	}

	const removeTrainingsFiles = (fileName) => {
		let newList = file.filter((files) => {
			return files.name !== fileName
		})
		OnFile(newList)
		return Promise.resolve('Ok')
	}

	const onEmployeedSelected = (emploeed) => {
		onSelectedEmployee(emploeed)
	}

	const isValidToSave = () => {
		if (selectedEmployee === null) return false
		if (file && file.length === 0) return false
		return true
	}

	const onAddTraining = () => {
		if (addTraining) {
			addTraining(file, selectedEmployee, startDate, endDate, name)
		}
	}

	const updateProperty = (key, event) => {
		if (key === 'startDate') {
			onStartDate(event)
			onEndDate(moment(event).add(1, 'year'))
		} else if (key === 'endDate') onEndDate(event)
		else onName(event)
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			header={
				<Toolbar>
					<Typography variant='h6'>
						{<Translate id='contractorsModule.medicalchecks.addTraining' />}
					</Typography>
				</Toolbar>
			}
			actions={
				<div>
					{!isValidToSave() && (
						<Typography variant='caption' color='error'>
							{<Translate id='common.requiredFields' />}
						</Typography>
					)}
					<DialogActions
						view={SHOW_EDIT_VIEW}
						classes={classes}
						onCancel={onClose}
						onClose={onClose}
						onSaveItem={onAddTraining}
						isSaving={isSavingTraining}
						canBeSaved={isValidToSave()}
					/>
				</div>
			}
		>
			<Grid container className={classes.modal} justify='space-between'>
				<TextField
					id='name'
					label={<Translate id='contractorsModule.medicalchecks.trainingName' />}
					value={name}
					margin='normal'
					type={'text'}
					fullWidth
					required
					onBlur={(event) => updateProperty('name', event.target.value)}
					onChange={(event) => updateProperty('name', event.target.value)}
					multiline
					variant='outlined'
					rowsMax={4}
				/>
				<Grid item xs={12}>
					<Typography variant='caption' color={'textSecondary'}>
						<Translate id='contractorsModule.medicalchecks.searchEmployeed' />*
					</Typography>
					<EmployeeSelector
						className={classes.textField}
						onEmployeedSelected={(employeed) => onEmployeedSelected(employeed)}
					/>
					<br />
				</Grid>
				<Grid item xs={5}>
					<Typography variant='caption' color={'textSecondary'}>
						<Translate id='common.startDate' />*
					</Typography>
					<DatePicker
						onChangeRange={(event) => {
							updateProperty('startDate', event)
						}}
						startDate={startDate}
					/>
				</Grid>
				<Grid item xs={1}></Grid>
				<Grid item xs={5}>
					<Typography variant='caption' color={'textSecondary'}>
						<Translate id='common.endDate' />
					</Typography>
					<DatePicker
						onChangeRange={(event) => {
							updateProperty('endDate', event)
						}}
						startDate={moment(endDate)}
						notshowCalendar={true}
						disabled={true}
					/>
					<br />
				</Grid>
				<Grid item xs={12}>
					<Typography variant='subtitle2' color={'textSecondary'}>
						{<Translate id='contractorsModule.medicalchecks.addTrainingfile' />} PDF *
					</Typography>
					<Evidence
						baseApi={process.env.REACT_APP_SIP_URL_FILES}
						removeFiles={(fileName) => removeTrainingsFiles(fileName)}
						uploadFiles={(files) => uploadTrainingFiles(files)}
						files={file}
						acceptedFiles={['pdf']}
						disabledLink
						maxFiles={1}
						text={<Translate id='dropzone.dropzoneBase' />}
						successToasterDisplayed={false}
					/>
				</Grid>
			</Grid>
		</Dialog>
	)
}
const styles = (theme) => ({
	modal: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
		width: 520,
	},
})
export default withRouter(withStyles(styles, { withTheme: true })(AddTrainingDialog))
