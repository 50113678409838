import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Translate, withLocalize } from 'react-localize-redux'
import queryString from 'query-string'
import { decodeJson, decodeNumericArray, decodeArray } from 'use-query-params'
import moment from 'moment'

/* Material UI import section */
import {
	Avatar,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Button,
	CircularProgress,
	Tooltip,
	IconButton,
	withStyles,
} from '@material-ui/core'
import PaymentRequestsIcon from '@material-ui/icons/Receipt'
import VisibilityIcon from '@material-ui/icons/Visibility'
import SendIcon from '@material-ui/icons/SendTwoTone'
import XmlFileIcon from '@material-ui/icons/CloudDownload'
import Receipt from '@material-ui/icons/Receipt'

/** Custom components import section */
import DataTable from '../../common/DataTable'
import LoadingPage from '../../common/LoadingPage'
import { DataGridCurrencyCell, DataGridDateCell, DataGridTextCell } from '../../common/DataGrid/DataGridCells'
import PaymentRequestStatus from './components/PaymentRequestStatus'
import ButtonDownload from '../components/ButtonDownload'
import PaymentRequestDetail from './components/PaymentRequestDetail'
import ContextFilterMenu from '../../common/contextFilters/ContextFilterMenu'
import AdvancedFiltersModal from '../../common/contextFilters/AdvancedFiltersModal'
import ConfirmationOrdersReportModal from './components/ConfirmationOrdersReportModal'
import ButtonTableColumnSettings from '../components/ButtonColumnsTableSettings'
import ColumnsConfigurator from '../../common/DataGrid/ColumnsConfigurator'
import Spacer from '../../common/Spacer'

/** Redux selectors import section */
import { getSupplierSelector } from '../../../store/suppliers/SupplierSelectors'
import {
	getPaymentRequestListSelector,
	getLoadingPaymentRequestsFlag,
	getPaymentRequestsTotal,
	getWorkshopCodesOnConfigurationSelector,
	getConfigurationSelector,
} from '../../../store/accreditedWorkshops/accreditedWorkshopsSelectors'
import {
	loadPaymentRequestsFromBackEnd,
	downloandPaymentRequestsReport,
	sendPaymentRequestToSap,
	downloandZipInvoiceFile,
	downloandZipCreditNoteFile,
	cancelPaymentrequests,
} from '../../../store/accreditedWorkshops/accreditedWorkshopsActions'

/** Resources and helpers imoprt section */
import { buildFilterConfig, FilterCriteria } from './paymentRequestHelpers/FilterConfiguration'
import { decodeDelimitedPipeArray } from '../../common/contextFilters/CustomArrayParam'
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import { getLoggedUserSelector } from '../../../store/profile/ProfileSelectors'
import { paymentRequestTableConfiguration } from './resources/PaymentRequestTable.resources'

/** Custom hooks import section */
import useClearFiltersButton from '../hooks/ClearFiltersButtonHook'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { useConfigurator } from '../../common/DataGrid/UseConfigurator'
import { DeleteForever } from '@material-ui/icons'
import ConfirmPasswordDialog from '../../common/ConfirmPasswordDialog'
import Toaster from '../../common/Toaster'

const tableId = 'payment-requests-table-config'

const Index = (props) => {
	/** Destructuring properties */
	const { history, location, translate, classes } = props

	/** Defines local state */
	const [showCircularProgress, setShowCircularProgress] = useState(false)
	const [rowsPerPage, setRowsPerPage] = useState(20)
	const [page, setPage] = useState(0)
	const [tableConfiguration, setTableConfiguration] = useConfigurator(tableId, paymentRequestTableConfiguration)
	// const [increasedTableConfiguration, setIncreasedTableConfiguration] = useState(serviceTableConfiguration)
	const [configuratorAnchorEl, setConfigurationAnchorEl] = useState(null)
	const [loadingCancelation, setLoadingCancelation] = useState(false)
	const [confirmPasswordConfigModal, setConfirmPasswordConfigModal] = useState({
		opened: false,
		item: null,
	})

	const onConfirmPassword = (item) => {
		setConfirmPasswordConfigModal({
			opened: !confirmPasswordConfigModal.opened,
			item: item,
		})
	}

	const settings = useSelector((state) => getConfigurationSelector(state))
	console.log('🚀 ~ file: Index.js:85 ~ Index ~ settings:', settings)

	const [sortCriteria, setSortCriteria] = useState({
		by: 'Folio',
		ascending: true,
	})
	const [paymentRequestPanelOptions, setPaymentRequestPanelOptions] = useState({
		opened: false,
		onCloseButtonClick: null,
		token: '',
		folio: '',
		status: '',
	})
	const [filters, setFilters] = useState([])
	const [filterSettings, setFilterSettings] = useState({
		open: false,
		anchorEl: null,
		type: '',
		filterIdentificator: '',
	})
	const [advancedFilterModalConfig, setAdvancedFilterModalConfig] = useState({
		open: false,
		queryStringKey: '',
		filterId: '',
		criteria: 0,
	})
	const [confirmationReportModalConfig, setConfirmationReportModalConfig] = useState({
		open: false,
	})
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		message: '',
		variant: '',
	})

	/** Connect with redux */
	const dispatch = useDispatch()
	const userProfile = useSelector((state) => state.oidc.user)
	const profile = useSelector((state) => getLoggedUserSelector(state))
	const supplier = useSelector((state) => getSupplierSelector(state))
	const loadingPaymentRequests = useSelector((state) => getLoadingPaymentRequestsFlag(state))
	const paymentRequests = useSelector((state) => getPaymentRequestListSelector(state))
	const paymentRequestsTotal = useSelector((state) => getPaymentRequestsTotal(state))
	const workshopCodes = useSelector((state) => getWorkshopCodesOnConfigurationSelector(state))
	const [ClearFiltersButton] = useClearFiltersButton()

	useEffect(() => {
		const isInternalUser = () => userProfile.profile.user_type === 'ActiveDirectoryUser'

		if (!!location) {
			const queryParams = queryString.parse(location.search)

			const searchFilters = []
			Object.entries(queryParams).forEach(([name, value]) => {
				if (name.startsWith('Advanced')) {
					let newName = name.replace('Advanced', '')
					switch (newName) {
						case 'ServiceOrdersNumber':
						case 'TotalAmount': {
							const decodedFilter = decodeJson(value)
							const decodedValues = decodeNumericArray(decodedFilter.values)
							searchFilters.push({
								PropertyIdentificator: newName,
								RuleValue: decodedValues,
								FilterCategorization: decodedFilter.criteria,
							})
							break
						}
						default: {
							const decodedFilter = decodeJson(value)
							const decodedValues = decodeArray(decodedFilter.values)
							searchFilters.push({
								PropertyIdentificator: newName,
								RuleValue: decodedValues,
								FilterCategorization: decodedFilter.criteria,
							})
							break
						}
					}
				} else if (!name.includes('order')) {
					switch (name) {
						case 'pageNumber': {
							break
						}
						case 'TotalAmount':
						case 'ServiceOrdersNumber': {
							const decodedValues = decodeDelimitedPipeArray(value)
							searchFilters.push({
								PropertyIdentificator: name,
								RuleValue: decodedValues,
								FilterCategorization: FilterCriteria.NUMERIC_EQUAL,
							})
							break
						}
						default: {
							const decodedValues = decodeDelimitedPipeArray(value)
							searchFilters.push({
								PropertyIdentificator: name,
								RuleValue: decodedValues.map((val) => (val === '(VACIO)' ? ' ' : val)),
								FilterCategorization: name.includes('Date')
									? FilterCriteria.BETWEEN_DATES
									: FilterCriteria.EQUAL,
							})
							break
						}
					}
				}
			})
			if (isInternalUser()) {
				setFilters([
					...searchFilters,
					{
						PropertyIdentificator: 'WorkshopCode',
						RuleValue: workshopCodes,
					},
				])
			} else if (!!supplier) {
				setFilters([
					...searchFilters,
					{
						PropertyIdentificator: 'WorkshopCode',
						RuleValue: [supplier.code],
					},
				])
			}
		}
	}, [userProfile, supplier, workshopCodes, location])

	useEffect(() => {
		if (filters.length > 0 && page >= 0 && rowsPerPage > 0) {
			const workshopCode = supplier ? supplier.code : ''
			dispatch(
				loadPaymentRequestsFromBackEnd(
					filters,
					page,
					rowsPerPage,
					profile.selectedCompany.partnership_code,
					workshopCode
				)
			)
		}
	}, [filters, page, rowsPerPage, profile, supplier])

	const openPaymentRequestDetailPanel = (event, item) => {
		event.stopPropagation()
		const { token, folio, status } = item
		setPaymentRequestPanelOptions({
			opened: true,
			onCloseButtonClick: null,
			token,
			folio,
			status,
		})
	}

	const closePaymentRequestDetailPanel = () => {
		setPaymentRequestPanelOptions({
			opened: false,
			onCloseButtonClick: null,
			token: '',
			folio: '',
			status: '',
		})
	}

	const onCancelButtonConfirm = () => {
		setLoadingCancelation(true)
		dispatch(cancelPaymentrequests(confirmPasswordConfigModal.item.token))
			.then(() => {
				setLoadingCancelation(false)
				setConfirmPasswordConfigModal({
					opened: false,
					item: null,
				})
				setToasterOptions({
					open: true,
					message: translate('accreditedWorkshops.successCancel'),
					variant: 'success',
				})
				const workshopCode = supplier ? supplier.code : ''
				dispatch(
					loadPaymentRequestsFromBackEnd(
						filters,
						page,
						rowsPerPage,
						profile.selectedCompany.partnership_code,
						workshopCode
					)
				)
			})
			.catch((error) => {
				setLoadingCancelation(false)
				setToasterOptions({
					open: true,
					message: translate('accreditedWorkshops.errorCancel'),
					variant: 'error',
				})
			})
	}

	const getCallBackToInvokeByIndentifier = () => {
		return onCancelButtonConfirm()
	}

	/**
	 * Custom render for some payment request columns
	 * @param {string} dataSource
	 * @param {object} item
	 */
	const renderCellItem = (dataSource, item) => {
		switch (dataSource) {
			case 'creationDate': {
				const { creationDate } = item
				return <DataGridDateCell date={creationDate} />
			}
			case 'folio': {
				const { folio } = item
				return <DataGridTextCell text={folio} />
			}
			case 'lastError': {
				const { lastError } = item
				return (
					<Typography
						variant='overline'
						style={{ whiteSpace: 'nowrap', overflowX: 'auto', display: 'block' }}
					>
						{lastError}
					</Typography>
				)
			}
			case 'totalAmount': {
				const { totalAmount } = item
				return <DataGridCurrencyCell amount={totalAmount} />
			}
			case 'payDate': {
				const { paymentDate } = item
				return <DataGridDateCell date={paymentDate} />
			}
			case 'status': {
				const { status, creationDate } = item
				const duration = moment.duration(moment().diff(creationDate))
				const durationExceded = duration.asMinutes() > 10
				const statusToShow = status === '11' && durationExceded ? '11E' : status
				return <PaymentRequestStatus status={statusToShow} />
			}
			case 'cancelButton': {
				return (
					<>
						{userProfile.profile.user_type === 'ActiveDirectoryUser' ? (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{(item.status === '12' || item.status === '22') && (
									<Tooltip title={<Translate id='common.cancel' />}>
										<IconButton
											id={item.token}
											variant='contained'
											size='small'
											onClick={() => onConfirmPassword(item)}
											disabled={item.status === '51' ? true : false}
										>
											<DeleteForever color={item.status === '51' ? '' : 'error'} />
										</IconButton>
									</Tooltip>
								)}
							</div>
						) : (
							<></>
						)}
					</>
				)
			}
			case 'actions': {
				const { status, sending, token, creditNoteUUID, creationDate } = item
				const duration = moment.duration(moment().diff(creationDate))
				const durationExceded = duration.asMinutes() > 10
				let customDisabled = false
				try {
					const lastSentStr = localStorage.getItem(`ServiceOrder-${token}`)
					const lastSentDate = new moment(lastSentStr)
					const lastSentDuration = moment.duration(moment().diff(lastSentDate))
					customDisabled = lastSentDuration.asMinutes() < 5
				} catch {
					customDisabled = false
				}
				let buttonDisabled = false
				if (settings.configuration && settings.configuration.PaymentRequestCreationStatusFull === false) {
					buttonDisabled = true
				}
				const resendButtonDisplayed = status === '16' || (status === '11' && durationExceded)
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Tooltip title={<Translate id='accreditedWorkshops.showShipmentDetails' />}>
							<IconButton
								id={item.token}
								variant='contained'
								size='small'
								color='primary'
								onClick={(event) => openPaymentRequestDetailPanel(event, item)}
							>
								<VisibilityIcon />
							</IconButton>
						</Tooltip>
						<Spacer width='1px' />
						<Tooltip title={<Translate id='accreditedWorkshops.downloadXmlInvoiceFile' />}>
							<IconButton
								id={item.token}
								variant='contained'
								size='small'
								color='primary'
								onClick={(event) => downloadInvoiceFile(event, item)}
							>
								<XmlFileIcon />
							</IconButton>
						</Tooltip>
						<Spacer width='1px' />
						{!IsNullOrEmpty(creditNoteUUID) && (
							<Tooltip title={<Translate id='accreditedWorkshops.downloadXmlCreditNoteFile' />}>
								<IconButton
									id={item.token}
									variant='contained'
									size='small'
									color='primary'
									onClick={(event) => downloadCreditNoteFile(event, item)}
								>
									<XmlFileIcon />
								</IconButton>
							</Tooltip>
						)}
						{resendButtonDisplayed && (
							<Button
								id={token}
								variant='contained'
								style={{
									borderRadius: 25,
									boxShadow: 'none',
									backgroundColor: 'lightblue',
									color: 'white',
									width: buttonDisabled ? 150 : 120,
									height: 25,
								}}
								size='small'
								color='primary'
								onClick={(e) => onSendToSapButtonClick(e, token)}
								disabled={sending || customDisabled || buttonDisabled}
							>
								<Typography variant='caption' style={{ color: 'inherit' }} component='label'>
									{buttonDisabled ? 'No disponible' : 'Reenviar'}
								</Typography>
								<Spacer />
								{sending || customDisabled ? (
									<CircularProgress color='primary' size={20} disableShrink />
								) : (
									<SendIcon size={15} />
								)}
							</Button>
						)}
					</div>
				)
			}

			default:
				return null
		}
	}

	const showPaymentRequestDetail = (item, event) => {
		const { tagName } = event.target
		console.log(`🚀 ~ file: Index.js ~ line 325 ~ showPaymentRequestDetail`, {
			tagName,
			currentTagName: event.currentTarget.tagName,
		})
		const { token } = item
		if (tagName !== 'svg' && tagName !== 'BUTTON' && tagName !== 'LABEL' && tagName !== 'path') {
			history.push(`/accreditedWorkshops/paymentRequest/${token}`)
		}
	}

	/*
	 * Handle action to download payment requests excel report
	 */
	const downloandPaymentRequestReport = () => {
		setShowCircularProgress(true)
		let supplierCodes = []
		if (IsCurrentActiveDirectoryUser(userProfile)) {
			supplierCodes = workshopCodes
		} else if (supplier) {
			supplierCodes = [supplier.code]
		}
		dispatch(
			downloandPaymentRequestsReport(filters, profile.selectedCompany.partnership_code, supplierCodes)
		).finally(() => setShowCircularProgress(false))
	}

	const downloadInvoiceFile = (event, item) => {
		event.stopPropagation()
		dispatch(downloandZipInvoiceFile(item.token))
	}

	const downloadCreditNoteFile = (event, item) => {
		event.stopPropagation()
		dispatch(downloandZipCreditNoteFile(item.token))
	}

	const onSendToSapButtonClick = (event, token) => {
		const now = moment().toString('YYYY-MM-DD HH:mm:ss')
		localStorage.setItem(`ServiceOrder-${token}`, now)
		event.stopPropagation()
		dispatch(sendPaymentRequestToSap(token, profile.selectedCompany.partnership_code))
		return false
	}

	const handlePageChange = (newPage, newRowsPerPage) => {
		setRowsPerPage((prevRowsPerPage) => {
			const pageToSet = prevRowsPerPage !== newRowsPerPage ? 0 : newPage
			setPage(pageToSet)
			return newRowsPerPage
		})
	}

	const handleSortCriteriaChange = (sortBy, sortAscending) => {
		setSortCriteria(sortBy, sortAscending)
	}

	const openContextFilterMenu = (event, dataSource) => {
		let filterConfig = buildFilterConfig(dataSource)
		const rect = event.currentTarget.getBoundingClientRect()
		if (dataSource === 'workshopCode') {
			filterConfig.customOptions = workshopCodes
				.sort((c1, c2) => c1 - c2)
				.map((code) => ({ key: code, code, label: code, description: code }))
		}
		setFilterSettings({
			...filterConfig,
			open: true,
			anchorEl: event.currentTarget,
			x: rect.x,
			y: rect.y + rect.height,
		})
	}

	const closeContextFilterMenu = () => {
		setFilterSettings({
			open: false,
			anchorEl: null,
			type: '',
			name: '',
			dataSource: '',
			filterIdentificator: '',
		})
	}

	const openAvancedFilterModal = (queryStringKey, criteria) => {
		setAdvancedFilterModalConfig({
			open: true,
			queryStringKey: queryStringKey,
			criteria: criteria,
		})
		closeContextFilterMenu()
	}

	const closeAvancedFilterModal = () => {
		setAdvancedFilterModalConfig({ open: false, criteria: 0 })
	}

	const openTableColumnsConfigurator = (event) => {
		setConfigurationAnchorEl(configuratorAnchorEl ? null : event.currentTarget)
	}

	/**
	 * The user change the service table configuration
	 */
	const onConfigurationChange = (configuration) => {
		setTableConfiguration(configuration)
		setConfigurationAnchorEl(null)
	}

	const openConfirmationReportModal = () => {
		setConfirmationReportModalConfig({
			open: true,
		})
	}

	const closeConfirmationReportModal = () => {
		setConfirmationReportModalConfig({
			open: false,
		})
	}

	const invoicedServiceOrderOptionDisplayed = userProfile && userProfile.profile.user_type === 'ActiveDirectoryUser'

	return (
		<>
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<PaymentRequestsIcon />
						</Avatar>
					}
					title={
						<Typography variant='button'>
							<Translate id='accreditedWorkshops.paymentRequests' />{' '}
						</Typography>
					}
					action={
						<div style={{ display: 'flex' }}>
							<ClearFiltersButton />
							<Spacer />
							<ButtonDownload
								onClick={downloandPaymentRequestReport}
								showCircularProgress={showCircularProgress}
							/>
							{invoicedServiceOrderOptionDisplayed && (
								<>
									<Spacer />
									<ButtonDownload
										tooltipTitle={translate('accreditedWorkshops.serviceOrdersReport')}
										onClick={openConfirmationReportModal}
										icon={<Receipt />}
									/>
									<Spacer />
								</>
							)}
							<ButtonTableColumnSettings onShowConfiguration={openTableColumnsConfigurator} />
							<ColumnsConfigurator
								open={configuratorAnchorEl ? true : false}
								anchorEl={configuratorAnchorEl}
								configuration={tableConfiguration}
								onConfigurationChange={onConfigurationChange}
								onClickAway={() => setConfigurationAnchorEl(null)}
							/>
							<Spacer />
						</div>
					}
				/>
				<CardContent className={classes.cardContent}>
					{
						{
							true: <LoadingPage />,
							false: (
								<DataTable
									isIndex
									configuration={{ columns: tableConfiguration }}
									data={paymentRequests}
									totalRows={paymentRequestsTotal}
									onRowClick={showPaymentRequestDetail}
									onRenderCellItem={renderCellItem}
									page={page}
									rowsPerPage={rowsPerPage}
									onChangePage={handlePageChange}
									sortBy={sortCriteria.by}
									sortAscending={sortCriteria.ascending}
									onChangeSortCriteria={handleSortCriteriaChange}
									onFilterClick={openContextFilterMenu}
								/>
							),
						}[loadingPaymentRequests]
					}
				</CardContent>
			</Card>
			{paymentRequestPanelOptions.opened && (
				<PaymentRequestDetail {...paymentRequestPanelOptions} onClose={closePaymentRequestDetailPanel} />
			)}
			{filterSettings.open && (
				<ContextFilterMenu
					{...filterSettings}
					filters={filters}
					onClose={closeContextFilterMenu}
					onAvancedFilterClick={openAvancedFilterModal}
				/>
			)}
			{advancedFilterModalConfig.open && (
				<AdvancedFiltersModal {...advancedFilterModalConfig} onClose={closeAvancedFilterModal} />
			)}
			{confirmationReportModalConfig.open && (
				<ConfirmationOrdersReportModal
					{...confirmationReportModalConfig}
					onClose={closeConfirmationReportModal}
				/>
			)}
			{confirmPasswordConfigModal && confirmPasswordConfigModal.opened && (
				<ConfirmPasswordDialog
					open={confirmPasswordConfigModal.opened}
					title={<Translate id='common.confirmOperation' />}
					message1={
						translate('accreditedWorkshops.cancelReques') +
						`: ${confirmPasswordConfigModal.item.folio}` +
						', este cambio solo afectará la información del SIP, por lo que esta acción podrá dejar información inconsistente en SAP.'
					}
					message2={<Translate id='common.enterPassword' />}
					onClickButtonConfirm={() => getCallBackToInvokeByIndentifier()}
					onClose={onConfirmPassword}
					isSaving={loadingCancelation}
					isVisibleComments={false}
					commentRequired={false}
					saveButtonTranslate='common.accept'
				/>
			)}
			<Toaster
				{...toasterOptions}
				onClose={() => {
					setToasterOptions({
						open: false,
						message: '',
						variant: '',
					})
				}}
			/>
		</>
	)
}

const styles = (theme) => ({
	cardContent: {
		padding: '4px 4px',
		maxWidth: 'calc(100vw - 300px)',
	},
})

export default withRouter(withLocalize(withStyles(styles, { withTheme: true })(Index)))
