import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import autoBind from 'auto-bind';
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';

/** Import component section */
import Reminder from '../common/Reminder';
import {sendReminderToSupplier} from '../../store/workflows/workflowActions';
import {loadSampleRequestFromBackEnd} from '../../store/samplerequests/SampleRequestAction';
/**
 * Container reminder to supplier of sample request
 */
class ReminderContainerToSupplier extends Component {

    /**
     * Create instance of sample request
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {

        }
    }

    /**
    * componentDidMount
    */
   componentDidMount() {
        let quoteToken = this.props.match.params.token;
        this.props.loadSampleRequest(quoteToken);
    }

    /**
     * Send reminder to supplier
     * @param {*} comment 
     * @param {*} members 
     */
    onSendReminderToSupplier(comment){
         this.props.sendReminderToSupplier(this.props.match.params.token, comment)
         .then(() =>{
            let token =this.props.match.params.token;
            this.props.history.push(`/samplerequests/${token}`)
         }).catch(() =>{
             this.setState({
                 showToaster: true,
                 toasterMessage: <Translate id="common.errorToSave" />,
                 toasterVariant: "error",
             })
         })
    }
    

    /**
     * Render to reminder
     */
    render() {
    return (
        <Reminder 
            {...this.props}
            workflow= {this.props.sampleRequest}
            onSendReminderToSupplier={this.onSendReminderToSupplier}
        />

    );
  }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let sampleRequest = state.samplerequests.get("sampleRequest");
    return {
        sampleRequest: sampleRequest ? sampleRequest.toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        sendReminderToSupplier: (token, comment) => {
            return dispatch(sendReminderToSupplier(token, comment));
        },
        loadSampleRequest: (token) => {
            return dispatch(loadSampleRequestFromBackEnd(token));
        },

    }
}

export default withauthenticatedLayout((withRouter) (connect(mapStateToProps, mapDispatchToProps)(ReminderContainerToSupplier)));