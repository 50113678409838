import React, { Component } from 'react'

/** Material-UI imports section */
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	List,
	ListItem,
	ListItemText,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { Translate } from 'react-localize-redux'

import LoadingPage from './LoadingPage'

import * as availableOperations from '../../store/helpers/DevelopmentHelper'
import { canUserCreateReportModuleQuality } from '../../store/helpers/RolesHelper'
import { IsCurrentActiveDirectoryUser, IsUserSupplierAdministrator } from '../../store/helpers/SessionHelper'

/**
 * Class to create dialog with available operations on quotes
 *
 * @class DialogAvailableOperations
 * @extends {Component}
 */
class DialogAvailableOperations extends Component {
	/**
	 * Function to
	 * @param {*} request
	 */
	onClickAvailableOperationLink(request) {
		switch (request) {
			/* Generated */
			case availableOperations.DeleteDraft:
				if (this.props.onDeleteDraft) {
					this.props.onDeleteDraft()
				}
				break
			case availableOperations.Cancel:
				if (this.props.onCancelDocument) {
					this.props.onCancelDocument()
				}
				break
			case availableOperations.Quote_SendReminderToSupplier:
				if (this.props.onReminderToSupplier) {
					this.props.onReminderToSupplier()
				}
				break
			/* Development */
			case availableOperations.Quote_RequestedToSupplier:
				if (this.props.sendQuoteRequestedToSupplier) {
					this.props.sendQuoteRequestedToSupplier()
				}
				break
			case availableOperations.Quote_Declined_BySupplier:
				if (this.props.onDeclineQuoteBySupplier) {
					this.props.onDeclineQuoteBySupplier()
				}
				break
			case availableOperations.Quote_AcceptedByEngineer:
				if (this.props.onQuoteAcceptedByEngineer) {
					this.props.onQuoteAcceptedByEngineer()
				}
				break
			case availableOperations.Quote_RejectedByEngineer:
				if (this.props.onQuoteRejectedByEngineer) {
					this.props.onQuoteRejectedByEngineer()
				}
				break
			case availableOperations.Quote_Send_RequoteToSupplier:
				if (this.props.onSendRequoteToSupplier) {
					this.props.onSendRequoteToSupplier()
				}
				break
			case availableOperations.Sample_RequestWithoutRMIInWaitingToSampleArrive:
				if (this.props.onSendSampleRequestToClient) {
					this.props.onSendSampleRequestToClient()
				}
				break
			case availableOperations.Sample_RequestDeclined:
				if (this.props.onDeclineSampleBySupplier) {
					this.props.onDeclineSampleBySupplier()
				}
				break
			case availableOperations.RMI_RespondedBySupplier:
				if (this.props.sendRMIToClient) {
					this.props.sendRMIToClient()
				}
				break
			case availableOperations.RMI_SampleArrivedToClientFacilities:
				if (this.props.onArriveRegisterDateDialog) {
					this.props.onArriveRegisterDateDialog()
				}
				break
			case availableOperations.RMI_Accepted:
				if (this.props.onAcceptRMISampleRequestByClient) {
					this.props.onAcceptRMISampleRequestByClient()
				}
				break
			case availableOperations.RMI_Rejected:
				if (this.props.onRejectRMISampleRequestByClient) {
					this.props.onRejectRMISampleRequestByClient()
				}
				break
			case availableOperations.RMI_ActivateToTheSupplierForModify:
				if (this.props.onRmiActivateToTheSupplierForModify) {
					this.props.onRmiActivateToTheSupplierForModify()
				}
				break
			case availableOperations.NoticeOfChangeRespondedBySupplier:
				if (this.props.onRespondNoticeOfChangeBySupplier) {
					this.props.onRespondNoticeOfChangeBySupplier()
				}
				break
			case availableOperations.CorrectiveActionDevelopment_NotificationSent:
				if (this.props.onSacNotificationToSupplier) {
					this.props.onSacNotificationToSupplier()
				}
				break
			case availableOperations.CorrectiveActionDevelopment_SendToDevelopmentEngineer:
				if (this.props.onSacNotificationToDevelopmentEngineer) {
					this.props.onSacNotificationToDevelopmentEngineer()
				}
				break
			case availableOperations.CorrectiveActionDevelopment_ResendCorrectiveAction:
				if (this.props.onSacReturnNotificationToSupplier) {
					this.props.onSacReturnNotificationToSupplier()
				}
				break
			case availableOperations.CorrectiveActionDevelopment_IsRequestForResponsible_ForModification:
				if (this.props.onSacRequestResponsibleModification) {
					this.props.onSacRequestResponsibleModification()
				}
				break
			case availableOperations.CorrectiveActionDevelopment_RequireAuthorizationDevelopmentAdministrator:
				if (this.props.onSacRequestAdministratorAuthorization) {
					this.props.onSacRequestAdministratorAuthorization()
				}
				break
			case availableOperations.CorrectiveActionDevelopment_Close_And_Accepted:
				if (this.props.onSacCloseAndAccept) {
					this.props.onSacCloseAndAccept()
				}
				break
			/* Quality */
			case availableOperations.RawMaterialNonConformity_NotificationSent:
				if (this.props.onClickToSendNotificationRawMaterial) {
					this.props.onClickToSendNotificationRawMaterial()
				}
				break
			case availableOperations.RawMaterialNonConformity_EditDraft: {
				if (this.props.onClickEditRawMaterialNonConformityInDraft) {
					this.props.onClickEditRawMaterialNonConformityInDraft()
				}
				break
			}
			case availableOperations.RawMaterial_ReturnToSupplier: {
				if (this.props.onClickCreateDevolutionRawMaterial) {
					this.props.onClickCreateDevolutionRawMaterial()
				}
				break
			}
			case availableOperations.RawMaterialNonConformity_DeviationAuthorization: {
				if (this.props.onClickRegisterFolioDeviation) {
					this.props.onClickRegisterFolioDeviation()
				}
				break
			}
			case availableOperations.RawMaterialNonConformity_ReworkRequest: {
				if (this.props.onClickCreateReworkRawMaterial) {
					this.props.onClickCreateReworkRawMaterial()
				}
				break
			}
			case availableOperations.RawMaterialNonConformity_DevolutionRequest: {
				if (this.props.onClickDevolutionRawMaterial) {
					this.props.onClickDevolutionRawMaterial()
				}
				break
			}
			case availableOperations.FailureReport_NotificationSent: {
				if (this.props.onClickToSendNotificationQualityReport) {
					this.props.onClickToSendNotificationQualityReport()
				}
				break
			}
			case availableOperations.FailureReport_ClosedIsNotAttributableToTheSupplier: {
				if (this.props.onClickCloseReport) {
					this.props.onClickCloseReport()
				}
				break
			}
			case availableOperations.FailureReport_MaterialReturnedToSupplier: {
				if (this.props.onClickCreateDevolutionFailure) {
					this.props.onClickCreateDevolutionFailure()
				}
				break
			}
			case availableOperations.WaitingForTheCompletionDateOfTheContainmentToClose: {
				if (this.props.onUdateStatusToWaitingForCompleteTheContainment) {
					this.props.onUdateStatusToWaitingForCompleteTheContainment()
				}
				break
			}
			case availableOperations.FailureReportQualirtEditDraft: {
				if (this.props.onClickEditFailureReportQualirtInDraft) {
					this.props.onClickEditFailureReportQualirtInDraft()
				}
				break
			}
			case availableOperations.FailureReport_AddEmergentAction: {
				if (this.props.onAddEmergentActionInFailure) {
					this.props.onAddEmergentActionInFailure()
				}
				break
			}
			case availableOperations.FailureReport_RequestRework: {
				if (this.props.onClickCreateReworkFailure) {
					this.props.onClickCreateReworkFailure()
				}
				break
			}
			case availableOperations.FailureReport_DeviationAuthorized: {
				if (this.props.onClickRegisterFolioDeviation) {
					this.props.onClickRegisterFolioDeviation()
				}
				break
			}
			case availableOperations.FailureReport_AddCorrectiveActionBysupplier: {
				if (this.props.onAddCorrectiveActionRequest) {
					this.props.onAddCorrectiveActionRequest()
				}
				break
			}
			case availableOperations.FailureReport_RegisterWarehouseEntry: {
				if (this.props.onRegisterWarehouseEntryInFailure) {
					this.props.onRegisterWarehouseEntryInFailure()
				}
				break
			}
			case availableOperations.Rework_ReworkExecuted: {
				if (this.props.onClickCreateReworkReworkExecuted) {
					this.props.onClickCreateReworkReworkExecuted()
				}
				break
			}
			case availableOperations.Rework_CloseToScrap: {
				if (this.props.onClickReworkCloseToScrap) {
					this.props.onClickReworkCloseToScrap()
				}
				break
			}
			case availableOperations.Rework_ReworkReturnToSupplier: {
				if (this.props.onClickReworkReturnToSupplier) {
					this.props.onClickReworkReturnToSupplier()
				}
				break
			}
			case availableOperations.Devolution_WaitingByTheSupplierPickedUp: {
				if (this.props.onClickDevolutionWaitingByTheSupplierPickedUp) {
					this.props.onClickDevolutionWaitingByTheSupplierPickedUp()
				}
				break
			}
			case availableOperations.CorrectiveAction_ReponseFromSupplier: {
				if (this.props.onClickCorrectiveActionReponseFromSupplier) {
					this.props.onClickCorrectiveActionReponseFromSupplier()
				}
				break
			}
			case availableOperations.SacQuality_SendNotification: {
				if (this.props.onClickToSendNotificationSacQuality) {
					this.props.onClickToSendNotificationSacQuality()
				}
				break
			}
			case availableOperations.CorrectiveAction_RegisterWarehouseEntry: {
				if (this.props.onRegisterWarehouseEntryCorrective) {
					this.props.onRegisterWarehouseEntryCorrective()
				}
				break
			}
			case availableOperations.WarrantyReportsPtm_SendNotification_To_Supplier: {
				if (this.props.onSendWarrantyReportPtmToSupplier) {
					this.props.onSendWarrantyReportPtmToSupplier()
				}
				break
			}
			case availableOperations.Closed: {
				if (this.props.onClickCloseReportSAC) {
					this.props.onClickCloseReportSAC()
				}
				break
			}
			case availableOperations.ShippingOrder_SendShippingOrderToClient: {
				if (this.props.onSendSendShippingOrderToClient) {
					this.props.onSendSendShippingOrderToClient()
				}
				break
			}
			case availableOperations.Import_Requests_send_Information: {
				if (this.props.onSendCustomsBrokerInformation) {
					this.props.onSendCustomsBrokerInformation()
				}
				break
			}
			case availableOperations.ShippingOrder_ForwardTheSupplierToCorrectDocuments: {
				if (this.props.onForwardShippingOpen) {
					this.props.onForwardShippingOpen()
				}
				break
			}
			case availableOperations.UpdateDraft: {
				if (this.props.onClickEditGuarranteeReportInDraft) {
					this.props.onClickEditGuarranteeReportInDraft()
				}
				break
			}
			case availableOperations.GuaranteeReport_SendGuaranteeToReliabilityArea: {
				if (this.props.onClickToSendGuaranteeToReliabilityArea) {
					this.props.onClickToSendGuaranteeToReliabilityArea()
				}
				break
			}
			case availableOperations.Guarantee_SendGuaranteeToSupplier: {
				if (this.props.onClickToSendGaranteeToSupplier) {
					this.props.onClickToSendGaranteeToSupplier()
				}
				break
			}
			case availableOperations.Guarantee_AddReport_GuaranteeToNoteCredit_BySupplier: {
				if (this.props.onAddCreditNote) {
					this.props.onAddCreditNote()
				}
				break
			}
			case availableOperations.Guarantee_Accept_And_Close: {
				if (this.props.onClickToAcceptAndClose) {
					this.props.onClickToAcceptAndClose()
				}
				break
			}
			case availableOperations.Guarantee_Forward_To_Supplier: {
				if (this.props.onClickToForwardToSupplier) {
					this.props.onClickToForwardToSupplier()
				}
				break
			}
			case availableOperations.Questionnaire_ResponseBySupplier: {
				if (this.props.onClickToQuestionnaireResponseBySupplier) {
					this.props.onClickToQuestionnaireResponseBySupplier()
				} else if (this.props.onSendToQuestionnaireBySupplier) {
					this.props.onSendToQuestionnaireBySupplier()
				}
				break
			}
			case availableOperations.Questionnaire_Resend_To_Supplier: {
				if (this.props.onClickToQuestionnaireResendToSupplier) {
					this.props.onClickToQuestionnaireResendToSupplier()
				} else if (this.props.onResendQuestionnaireToSupplier) {
					this.props.onResendQuestionnaireToSupplier()
				}
				break
			}
			case availableOperations.Questionnaire_Evaluated: {
				if (this.props.onClickToCloseQuestionnaireEvaluated) {
					this.props.onClickToCloseQuestionnaireEvaluated()
				} else if (this.props.onCloseQuestionnaireInPlant) {
					this.props.onCloseQuestionnaireInPlant()
				}
				break
			}
			case availableOperations.Questionnaire_Generated_Actions_Plan: {
				if (this.props.onGenerateQuestionnaireActionsPlan) {
					this.props.onGenerateQuestionnaireActionsPlan()
				}
				break
			}
			case availableOperations.Questionnaire_Accept_And_Send_To_Supplier: {
				if (this.props.onAddQuestionnaireAcceptedToSupplier) {
					this.props.onAddQuestionnaireAcceptedToSupplier()
				}
				break
			}
			case availableOperations.Questionnire_Supplier_Adding_Actions: {
				if (this.props.onAddQuestionnireSupplierAddingActions) {
					this.props.onAddQuestionnireSupplierAddingActions()
				}
				break
			}
			case availableOperations.Questionnire_Resend_Actions_To_Supplier: {
				if (this.props.onResendQuestionnanreWithActionsToSupplier) {
					this.props.onResendQuestionnanreWithActionsToSupplier()
				}
				break
			}
			case availableOperations.ExpedientStatus_AnsweredSupplier: {
				if (this.props.onClickToSendDocumentsBySupplier) {
					this.props.onClickToSendDocumentsBySupplier()
				}
				break
			}
			case availableOperations.ExpedientStatus_ResendToSupplier: {
				if (this.props.onClickExpedientStatusResendToSupplier) {
					this.props.onClickExpedientStatusResendToSupplier()
				}
				break
			}
			case availableOperations.ExpedientStatus_CancelInPlant: {
				if (this.props.onClickCancelExpedient) {
					this.props.onClickCancelExpedient()
				}
				break
			}
			case availableOperations.ExpedientStatus_ClosedInPlant: {
				if (this.props.onCloseExpedientBySupplier) {
					this.props.onCloseExpedientBySupplier()
				}
				break
			}
			case availableOperations.Freight_SendFreigthToClient: {
				if (this.props.onSendSendFreightToClient) {
					this.props.onSendSendFreightToClient()
				}
				break
			}
			case availableOperations.Freight_ForwardTheSupplierToCorrectDocuments: {
				if (this.props.onForwardFreightOpen) {
					this.props.onForwardFreightOpen()
				}
				break
			}
			case availableOperations.Maquila_Orders_Send_Supplier: {
				if (this.props.onSendMaquilaOrderFromSupplier) {
					this.props.onSendMaquilaOrderFromSupplier()
				}
				break
			}
			case availableOperations.Maquila_Orders_Close: {
				if (this.props.onCloseMaquilaOrder) {
					this.props.onCloseMaquilaOrder()
				}
				break
			}
			case availableOperations.Maquila_Orders_Send_Notification: {
				if (this.props.onSendNotificationAPlant) {
					this.props.onSendNotificationAPlant()
				}
				break
			}
			case availableOperations.Questionnaire_SendToSuppliers: {
				if (this.props.onSendQuestionnaireToSuppliers) {
					this.props.onSendQuestionnaireToSuppliers()
				}
				break
			}
			case availableOperations.Maquila_Orders_Valid_Planning: {
				if (this.props.onValidOrderMaquilaForPlanning) {
					this.props.onValidOrderMaquilaForPlanning()
				}
				break
			}
			case availableOperations.Maquila_Orders_Rejected_Planning: {
				if (this.props.onRejectedOrderMaquilaForPlanning) {
					this.props.onRejectedOrderMaquilaForPlanning()
				}
				break
			}
			case availableOperations.Maquila_Orders_Send_Warehouse: {
				if (this.props.onSentOpenSenWarehouseOrder) {
					this.props.onSentOpenSenWarehouseOrder()
				}
				break
			}
			case availableOperations.Maquila_Orders_Valid_Supplier: {
				if (this.props.onSentOpenValidSsupplierOrder) {
					this.props.onSentOpenValidSsupplierOrder()
				}
				break
			}
			case availableOperations.Load_Alert_In_Process: {
				if (this.props.onChageToProcessLoadAlert) {
					this.props.onChageToProcessLoadAlert()
				}
				break
			}
			case availableOperations.Reject_Load_Alert: {
				if (this.props.onRejectLoadAlert) {
					this.props.onRejectLoadAlert()
				}
				break
			}
			case availableOperations.Accept_Load_Alert: {
				if (this.props.onAcceptLoadAlert) {
					this.props.onAcceptLoadAlert()
				}
				break
			}
			default:
				console.log('Sin opciones')
				break
		}
	}

	/**
	 * Get the list of available options
	 *
	 * @returns
	 * @memberof DialogAvailableOperations
	 */
	getOperationsList() {
		if (this.props.isSaving === true) {
			return <LoadingPage isSavingPage={true} />
		} else if (this.props.options) {
			let canBeDelete =
				this.props.isValidToDelete !== undefined
					? this.props.isValidToDelete
					: canUserCreateReportModuleQuality(this.props.userRolesByCompany)
			return (
				<Card>
					{this.props.options.map((option) => {
						let getOption = availableOperations.AvailableOperationLink(
							option,
							IsCurrentActiveDirectoryUser(this.props.currentUser),
							this.props.userRolesByCompany,
							this.props.isValidToCancel,
							canBeDelete,
							IsUserSupplierAdministrator(this.props.currentUser),
							this.props.isMessageCustomDelete
						)
						if (getOption === null) {
							return ''
						}
						return (
							<ListItem
								key={getOption.value}
								divider
								button
								onClick={() => {
									this.onClickAvailableOperationLink(getOption.option)
								}}
							>
								{/* add available operations items */}
								<ListItemText
									primary={
										<Typography component='span' color='primary' variant='subtitle2' gutterBottom>
											{<Translate id={`statusDescriptions.${getOption.title}`} />}
										</Typography>
									}
									secondary={
										<React.Fragment>
											<Typography
												component='span'
												display='block'
												color='textSecondary'
												variant='caption'
												gutterBottom
											>
												{<i className='fa fa-info'> </i>}{' '}
												{<Translate id={`statusDescriptions.${getOption.message}`} />}
											</Typography>
										</React.Fragment>
									}
								/>
							</ListItem>
						)
					})}
				</Card>
			)
		} else {
			return (
				<List>
					<ListItem key={'operation'} divider button>
						{/* not avaliable operations */}
						<ListItemText
							color='primary'
							primary={<Translate id='statusDescriptions.notAvaliableOperations' />}
						/>
					</ListItem>
				</List>
			)
		}
	}

	/**
	 * Render dialog to show available options
	 *
	 * @returns
	 * @memberof DialogAvailableOperations
	 */
	render() {
		return (
			<Dialog
				open={this.props.openAvailableOperationsDialog}
				onClose={() => this.props.setOpenDialog(false)}
				aria-labelledby='form-dialog-title'
			>
				{/* Header title of the dialog */}
				<DialogTitle id='form-dialog-title'>
					<DialogContentText>
						<Typography component='span' variant='h6'>
							<Translate id='common.AvailableOperations' />
						</Typography>
					</DialogContentText>
				</DialogTitle>
				<Divider />
				{/* Body of the dialog */}
				<DialogContent>
					<List>{this.getOperationsList()}</List>
				</DialogContent>
				{/* Button actions on the footer of the dialog */}
				<DialogActions>
					<Button onClick={() => this.props.setOpenDialog(false)} color='secondary'>
						<Translate id='common.close' />
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default DialogAvailableOperations
