/** Redux import section */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select'
import { useSelector } from 'react-redux';

/**Import MaterialUi section */
import { Grid, Button, Avatar, Typography, TextField } from '@material-ui/core'
import AddtIcon from '@material-ui/icons/AddTwoTone';
import Switch from '@material-ui/core/Switch';

/** section import components */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';

/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
import Toaster from '../../common/Toaster';

//Import store section
import { createMaterial } from '../../../store/consignmentNote/consignmentNoteActions';
import { getMaterials, deliveriesSelector} from '../../../store/consignmentNote/consignmentNoteSelectors';

const AddMaterialLateralPanel = props => {
    const dispatch = useDispatch();
    const deliveriesOptions = useSelector(deliveriesSelector);
    /**props section */
    const {
        onCloseButtonClick,
        classes,
        isSaving,
        delivery,
    } = props

    const [panelInformation, setPanelInformation] = useState({
        code: "",
        description: "",
        requestCode:"",
        quantity: 0,
        baseMeasurementUnit: "",
        position: "",
        grossWeight: 0,
        satMeasurementUnit: "",
        weightMeasurementUnit: "",
        productCode: "",
        unitCode: "",
        hazardousMaterialCode: "",
        packagingCode: "",
        weightInKg: 0,

    });
    const [toasterMessageConfig, setToasterMessageConfig] = useState({
        showToaster: false,
        toasterMessage: null,
        toasterVariant: '',
        name: ''
    });
    const [panelDelivery, setPanelDelivery] = useState({
        codeDelivery: "",
        material: "",
    })
    const [activeOption, setActiveOption] = useState(false)

    const toggleActiveOption = (option) =>{
        setActiveOption(option)
    }

    const onPropertyDelivery = (option) => {
        setPanelDelivery({
            ...panelDelivery,
            "codeDelivery": option
        });
    }

    const onPropertyMaterial =(option) =>{
        let material = option.material;
        setPanelDelivery({
            ...panelDelivery,
            "material": option
        });
        if(material){
            setPanelInformation({
                ...panelInformation,
                code: material.code,
                description:  material.name,
                requestCode: material.requestCode,
                quantity: material.quantity,
                baseMeasurementUnit:  material.baseMeasurementUnit,
                position:  material.position,
                grossWeight: material.grossWeight,
                satMeasurementUnit:  material.satMeasurementUnit,
                weightMeasurementUnit: material.weightMeasurementUnit,
                productCode:  material.productCode,
                unitCode: material.unitCode,
                hazardousMaterialCode:  material.hazardousMaterialCode,
                packagingCode:  material.packagingCode,
                weightInKg:  material.weightInKg,
            });
        }
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setPanelInformation({
            ...panelInformation,
            [name]: value
        });
    }

    const canBeSaved = () => {
        if (IsNullOrEmpty(panelInformation.code)) return false
        if (IsNullOrEmpty(panelInformation.description)) return false
        if (IsNullOrEmpty(panelInformation.baseMeasurementUnit)) return false
        if (IsNullOrEmpty(panelInformation.requestCode)) return false
        else return true;
    }

    const onCreateMaterialFromBackEnd = () => {
        const consignmentNoteId = props.match.params.token;
        return dispatch(createMaterial(consignmentNoteId, delivery, panelInformation))
            .then(() => {
                onCloseButtonClick()
                Promise.resolve();
            })
            .catch((response) => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: 'common.errorToSave',
                    toasterVariant: "error",
                })
            })
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}>
            <PanelHeader
                titlePanel={<Translate id="consignmentNote.createMaterial.addMaterial" />}
                icon={<Avatar className={classes.avatar}><AddtIcon /></Avatar>}
                onCloseButtonClick={onCloseButtonClick}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container spacing={8}>
                    <Grid item xs={8}>
                        <Typography className={classes.title} color='primary'>{"Agregar material de una entrega"}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Switch
                            onChange={(event) => {toggleActiveOption(event.target.checked) }}
                            checked={activeOption ? true : false}
                        />
                    </Grid>
                    {activeOption && <Grid item xs={6}>
                            <Typography color='primary'>{"Entrega"}</Typography>
                            <Select
                                className={classes.documentTypeSelect}
                                options={deliveriesOptions}
                                onChange={onPropertyDelivery}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                                    menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                }}
                                menuPortalTarget={document.parentNode}
                                menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
                                menuPlacement={'auto'}
                            />
                        </Grid>}
                        {activeOption && <Grid item xs={6}>
                            <Typography color='primary'>{"Material"}</Typography>
                            <Select
                                className={classes.documentTypeSelect}
                                options={panelDelivery.codeDelivery ? getMaterials(panelDelivery.codeDelivery.materials): []}
                                onChange={onPropertyMaterial}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                                    menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                }}
                                menuPortalTarget={document.parentNode}
                                menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
                                menuPlacement={'auto'}
                            />
                        </Grid>}
                        <Grid item xs={12}>
                            <Typography color='primary'><Translate id="consignmentNote.table.code" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.code : ""}
                                onChange={onPropertyChange}
                                name='code'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color='primary'><Translate id="consignmentNote.table.material" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.description : ""}
                                onChange={onPropertyChange}
                                name='description'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color='primary'><Translate id="consignmentNote.table.requestCode" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.requestCode : ""}
                                onChange={onPropertyChange}
                                name='requestCode'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color='primary'><Translate id="consignmentNote.createMaterial.measurementUnit" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.baseMeasurementUnit : ""}
                                onChange={onPropertyChange}
                                name='baseMeasurementUnit'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color='primary'><Translate id="consignmentNote.table.position" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.position : ""}
                                onChange={onPropertyChange}
                                name='position'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography color='primary'><Translate id="consignmentNote.table.grossWeight" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.grossWeight : 0}
                                onChange={onPropertyChange}
                                name='grossWeight'
                                margin="dense"
                                variant="outlined"
                                type='number'
                                step={0.5}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color='primary'><Translate id="consignmentNote.table.unitWeight" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.weightMeasurementUnit : ""}
                                onChange={onPropertyChange}
                                name='weightMeasurementUnit'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color='primary'><Translate id="consignmentNote.table.keyProduct" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.productCode : ""}
                                onChange={onPropertyChange}
                                name='productCode'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography color='primary'><Translate id="consignmentNote.table.deliveryQuantity" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.quantity : 0}
                                onChange={onPropertyChange}
                                name='quantity'
                                margin="dense"
                                variant="outlined"
                                type='number'
                                step={0.5}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography color='primary'><Translate id="consignmentNote.table.unitCode" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.unitCode : ""}
                                onChange={onPropertyChange}
                                name='unitCode'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color='primary'><Translate id="consignmentNote.table.keyMaterial" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.hazardousMaterialCode : ""}
                                onChange={onPropertyChange}
                                name='hazardousMaterialCode'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color='primary'><Translate id="consignmentNote.table.codeKeyPacking" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.packagingCode : ""}
                                onChange={onPropertyChange}
                                name='packagingCode'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color='primary'><Translate id="consignmentNote.table.totalWeight" /></Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.weightInKg : 0}
                                onChange={onPropertyChange}
                                name='weightInKg'
                                margin="dense"
                                variant="outlined"
                                type='number'
                                step={0.5}
                            />
                        </Grid>
                        <br />
                    </Grid>
                </div>
                <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            className={classes.saveButton}
                            disabled={!canBeSaved() || isSaving}
                            onClick={onCreateMaterialFromBackEnd}>
                            {<Translate id="common.save" />}
                        </Button>
                        <Button
                            data-cy="btn-cancel"
                            type="button"
                            color="secondary"
                            variant='outlined'
                            className={classes.cancelButton}
                            onClick={onCloseButtonClick}>
                            {<Translate id="common.cancel" />}
                        </Button>
                    </Grid>
                </Grid>

                <Toaster
                    message={<Translate id={toasterMessageConfig.toasterMessage} />}
                    open={toasterMessageConfig.showToaster}
                    variant={toasterMessageConfig.toasterVariant}
                    onClose={() => {
                        setToasterMessageConfig({
                            showToaster: false,
                            toasterMessage: '',
                            toasterVariant: ''
                        });
                    }}
                />
            </div>

        </LateralPanel >
    );
};

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    saveButton: {
        bottom: 0,
        margin: 2
    },
    cancelButton: {
        color: theme.palette.error.main,
        bottom: 0,
        margin: 2
    },
    title:{
        margin: "12px"
    }

});

AddMaterialLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    selectedCompany: propTypes.object,
    /**
     * open and closedlateral panel for add an new material
     */
    onSaveButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onCloseButtonClick: propTypes.func,
};

AddMaterialLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddMaterialLateralPanel))