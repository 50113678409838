
/**
 * Get currency format to a number
 * 
 * @param {number} amount 
 */
export const currencyFormat = amount => {
    let result = amount;
    result = amount && Math.round(amount);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    })
    return `${result && formatter.format(result)}`;
}

/**
 * Get percenge formtat
 * @param {*} percentage 
 */
export const percengeFormat = percentage => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    })
    return `${formatter.format(percentage)}`;
}


export const percengeFormatResultTable = percentage => {
   const formatter = new Intl.NumberFormat('en-US', {
       style: 'percent',
       minimumFractionDigits: 2,
       maximumFractionDigits: 2
   })
   return `${formatter.format(percentage)}`;
}

export function intRound(numero, decimales = 2, usarComa = false) {
    var opciones = {
        maximumFractionDigits: decimales,
        useGrouping: false
    };
    usarComa = usarComa ? "es" : "en";
    return new Intl.NumberFormat(usarComa, opciones).format(numero);
}

export const formatterDolarValue= (value)=>{
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })
      return `${formatterDolar.format(value)}`;

}