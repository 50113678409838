import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'
import { converListToArray } from '../../store/helpers/ListHelper'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import AddCommonItem from '../common/AddCommonItem'
import CompaniesCard from './components/CompaniesCard'
import UserCard from './components/UserCard'

/** Import actions nd helpers section  */
import {
	loadUser,
	loadUserRoles,
	toggleRoleAssignent,
	setLockoutEnabledUser,
	addRolesInAllCompaniesGrandAccess,
} from '../../store/administration/InternalActions'
import {
	loadCompaniesFromBackEnd,
	getCompaniesGrantedToUser,
	loadRolesByCompanies,
	grantAccessToCompany,
	removeAccessFromCompany,
} from '../../store/companies/CompanyActions'
import {
	removeNotificationsPlanning,
	loadNotificationsByUserSelected,
} from '../../store/notifications/NotificationAction'

/**
 * Container to show user
 */
class Show extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			grantedCompanies: null,
			grantingAccessToCompany: null,
			showRemoveAccessDialog: false,
			removingCompanyAccess: null,
			expandedCompany: null,
			openEnableUser: false,
			openUnlockedUser: false,
			openAddRolesInAllCompanies: false,
			openRemoveNotification: false,
			itemTokenRemove: null,
			workflowType: null,
		}
	}

	/**
	 * componentWillMount
	 *
	 * @memberof Show
	 */
	componentWillMount() {
		this.props
			.loadCompanies()
			.then(() => {
				// Get the grantes companies
				this.props
					.getCompaniesGranted(this.props.match.params.token)
					.then((serverResponse) => {
						this.setState({
							grantedCompanies: serverResponse.data,
						})
					})
					.catch(() => {})
				// Load the roles for every companies
				this.props.loadRolesByCompanies()
			})
			.catch(() => {})
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let userToken = this.props.match.params.token
		this.props.loadUser(userToken).then((user) => {
			this.props.loadUserRoles(userToken)
			this.props.loadNotificationsByUserSelected(user.user_name)
		})
	}

	/**
	 * On grant access in a company
	 *
	 * @param {*} company
	 * @memberof Show
	 */
	onGrantAccessRequest(company) {
		this.setState({
			grantingAccessToCompany: company,
		})
		this.props.grantAccessToCompany(company.token, this.props.match.params.token).then(() => {
			let updatedState = { ...this.state }
			updatedState.grantedCompanies.push(company)
			updatedState.grantingAccessToCompany = null
			this.setState(updatedState)
		})
	}

	/**
	 * On remove access to company. Show the confirmation dialog
	 *
	 * @param {*} company
	 * @memberof Show
	 */
	onRemoveAccessRequest(company) {
		this.setState({
			showRemoveAccessDialog: true,
			removingCompanyAccess: company,
		})
	}

	/**
	 * On cancel remove
	 *
	 * @memberof Show
	 */
	onCancelRemove() {
		this.setState({
			showRemoveAccessDialog: false,
			removingCompanyAccess: null,
		})
	}

	/**
	 * Execute removing access from company
	 *
	 * @memberof Show
	 */
	removeAccessFromCompany() {
		let companyObject = this.state.removingCompanyAccess
		this.setState({
			grantingAccessToCompany: companyObject,
			showRemoveAccessDialog: false,
		})
		this.props.removeAccessFromCompany(companyObject.token, this.props.match.params.token).then(() => {
			let updatedState = { ...this.state }
			updatedState.grantedCompanies = this.state.grantedCompanies.filter((grntCompany) => {
				return grntCompany.token !== companyObject.token
			})
			this.props.loadUserRoles(this.props.match.params.token)
			updatedState.grantingAccessToCompany = null
			updatedState.removingCompanyAccess = null
			this.setState(updatedState)
		})
	}

	/**
	 * onEnableUser
	 */
	onEnableUser() {
		this.setState({
			openEnableUser: true,
		})
	}

	/**
	 * Function on block user
	 */
	onBlockUser(comments) {
		let userToken = this.props.match.params.token
		this.props
			.setLockoutEnabledUser(userToken, false, comments)
			.then(() => {
				this.setState({
					openEnableUser: false,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * onUnLockedUser
	 */
	onUnLockedUser() {
		this.setState({
			openUnlockedUser: true,
		})
	}

	/**
	 * Function on unloked user
	 * @param {*} comments
	 */
	onUnlockedUser(comments) {
		let userToken = this.props.match.params.token
		this.props
			.setLockoutEnabledUser(userToken, true, comments)
			.then(() => {
				this.setState({
					openUnlockedUser: false,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * on open dialog to add roles from all companies
	 */
	addRolesInCompanies() {
		this.setState({
			openAddRolesInAllCompanies: true,
		})
	}

	/**
	 * Function to add roles in all companies grand access supplier
	 * @param {*} comments
	 */
	onAddRolesInAllCompaniesGrandAccess() {
		let userToken = this.props.match.params.token
		this.props
			.addRolesInAllCompaniesGrandAccess(userToken)
			.then(() => {
				this.setState({
					openAddRolesInAllCompanies: false,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Get the remove dialog confirmation
	 *
	 * @returns
	 * @memberof Show
	 */
	getRemoveAccessDialog() {
		var companyName = this.state.removingCompanyAccess ? this.state.removingCompanyAccess.name : ''
		return (
			<Dialog open={this.state.showRemoveAccessDialog} keepMounted onClose={this.onCancelRemove}>
				<DialogTitle id='alert-dialog-remove-access'>
					{<Translate id='users.removeAccessFromCompanyTitle' />}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-slide-description'>
						{<Translate id='users.removeAccessFromCompanyMessage' data={{ companyName }} />}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.removeAccessFromCompany} color='primary'>
						<Translate id='common.accept' data={{ companyName }} />
					</Button>
					<Button onClick={this.onCancelRemove} color='secondary'>
						<Translate id='common.cancel' data={{ companyName }} />
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	/**
	 * onRemoveNotificationOpen
	 */
	onRemoveNotificationOpen(itemTokenRemove, workflowType) {
		this.setState({
			openRemoveNotification: true,
			itemTokenRemove: itemTokenRemove,
			workflowType: workflowType,
		})
	}

	/**
	 * Function on remove removeNotificationsPlanning
	 */
	removeNotificationsPlanning() {
		if (this.state.itemTokenRemove !== null && this.state.workflowType !== null) {
			this.props
				.removeNotificationsPlanning(this.state.itemTokenRemove, this.state.workflowType)
				.then(() => {
					this.setState({
						openRemoveNotification: false,
						itemTokenRemove: null,
						workflowType: null,
					})
				})
				.catch(() => {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				})
		}
	}

	/**
	 * Render show
	 */
	render() {
		const { internalUser, isLoadingUser } = this.props
		if (isLoadingUser === true) {
			return <LoadingPage />
		}
		if (internalUser === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<Grid container spacing={24}>
					<Grid item xs={4}>
						<UserCard
							{...this.props}
							onEnableUser={this.onEnableUser}
							onUnLockedUser={this.onUnLockedUser}
							isLoadingNotifications={this.props.isLoadingNotifications}
							notifications={this.props.notificationsUserSelected}
							onRemoveNotificationOpen={this.onRemoveNotificationOpen}
						/>
					</Grid>
					<Grid item xs={8}>
						<CompaniesCard
							{...this.props}
							{...this.state}
							onGrantAccessRequest={this.onGrantAccessRequest}
							onRemoveAccessRequest={this.onRemoveAccessRequest}
							addRolesInCompanies={this.addRolesInCompanies}
						/>
						{this.getRemoveAccessDialog()}
					</Grid>

					{/** Dialog to Block user */}
					<AddCommonItem
						title={<Translate id='users.blockUser' />}
						message={<Translate id='users.messageToBlock' />}
						open={this.state.openEnableUser}
						onClose={() => {
							this.setState({ openEnableUser: false })
						}}
						onSaveItem={this.onBlockUser}
						isSaving={this.props.isSaving}
						isTextArea={true}
					/>

					{/** Dialog to Unlocked user */}
					<AddCommonItem
						title={<Translate id='users.unlockUser' />}
						message={<Translate id='users.messageTounlockUser' />}
						open={this.state.openUnlockedUser}
						onClose={() => {
							this.setState({ openUnlockedUser: false })
						}}
						onSaveItem={this.onUnlockedUser}
						isSaving={this.props.isSaving}
						isMessage={true}
					/>

					{/** Dialog to Unlocked user */}
					<AddCommonItem
						title={<Translate id='companies.addRolesToAllCompanies' />}
						message={<Translate id='companies.messageToAddRoles' />}
						open={this.state.openAddRolesInAllCompanies}
						onClose={() => {
							this.setState({ openAddRolesInAllCompanies: false })
						}}
						onSaveItem={this.onAddRolesInAllCompaniesGrandAccess}
						isSaving={this.props.isLoadingInternalUserRoles}
						isMessage={true}
					/>

					{/** Dialog remove notificaiton */}
					<AddCommonItem
						title={<Translate id='common.removeNotifications' />}
						message={<Translate id='common.messageRemoveNotifications' />}
						open={this.state.openRemoveNotification}
						onClose={() => {
							this.setState({ openRemoveNotification: false })
						}}
						onSaveItem={this.removeNotificationsPlanning}
						isSaving={this.props.isRemovingNotification}
						saveButtonTranslate={'common.delete'}
						isMessage={true}
					/>
				</Grid>
			)
		}
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	return {
		currentUser: user,
		internalUser: state.internalUsers.get('internalUser') ? state.internalUsers.get('internalUser').toJS() : null,
		internalUserRoles: state.internalUsers.get('internalUserRoles')
			? state.internalUsers.get('internalUserRoles').toJS()
			: null,
		isLoadingUser: state.internalUsers.get('isLoadingUser'),
		companies: converListToArray(state.companies.get('companies')),
		rolesForCompanies: converListToArray(state.companies.get('rolesForCompanies')),
		isLoadingCompaniesRoles: state.companies.get('isLoadingCompaniesRoles'),
		isLoadingInternalUserRoles: state.internalUsers.get('isLoadingInternalUserRoles'),
		isSaving: state.internalUsers.get('isSaving'),
		isLoadingNotifications: state.notifications.get('isLoadingUserSelectedNotifications'),
		notificationsUserSelected: state.notifications.get('notificationsUserSelected')
			? state.notifications.get('notificationsUserSelected').toJS()
			: null,
		isRemovingNotification: state.notifications.get('isRemovingNotification'),
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadUser: (token) => {
			return dispatch(loadUser(token))
		},
		loadUserRoles: (userId) => {
			return dispatch(loadUserRoles(userId))
		},
		loadCompanies: () => {
			return dispatch(loadCompaniesFromBackEnd())
		},
		getCompaniesGranted: (userId) => {
			return dispatch(getCompaniesGrantedToUser(userId))
		},
		grantAccessToCompany: (companyId, userId) => {
			return dispatch(grantAccessToCompany(companyId, userId))
		},
		removeAccessFromCompany: (companyId, userId) => {
			return dispatch(removeAccessFromCompany(companyId, userId))
		},
		loadRolesByCompanies: () => {
			return dispatch(loadRolesByCompanies())
		},
		toggleRoleAssignent: (internalUser, assigned, company, role) => {
			return dispatch(toggleRoleAssignent(internalUser, assigned, company, role))
		},
		setLockoutEnabledUser: (userId, enabled, comments) => {
			return dispatch(setLockoutEnabledUser(userId, enabled, comments))
		},
		addRolesInAllCompaniesGrandAccess: (userId) => {
			return dispatch(addRolesInAllCompaniesGrandAccess(userId))
		},
		loadNotificationsByUserSelected: (userName) => {
			return dispatch(loadNotificationsByUserSelected(userName))
		},
		removeNotificationsPlanning: (token, workflowType) => {
			return dispatch(removeNotificationsPlanning(token, workflowType))
		},
	}
}

const viewStyles = (theme) => ({
	secondaryToolbar: {
		display: 'flex',
	},
	waitMessage: {
		marginTop: '15px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: '100px',
		paddingRight: '150px',
	},
	userAvatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	companyAvatar: {},
	companyGrantedHeader: {
		color: theme.palette.primary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
