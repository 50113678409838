import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'auto-bind';
import { Link } from 'react-router-dom';

/** Material-UI imports section */
import { Translate } from 'react-localize-redux';
import {
    Card, Grid,Fab,
    CardContent, CardHeader, Typography,
    Collapse, Tooltip,TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import CertificateIcon from '@material-ui/icons/HighQuality';
import AddItemIcon from  '@material-ui/icons/AddToPhotos';
import DownloadIcon from  '@material-ui/icons/CloudDownload';

import { COLOR_STATUS_ACEPTED, COLOR_CERTIFICATE_BLUE } from '../../../../store/helpers/StatusColorConstants';
import DataTable from '../../../common/DataTable';
import AddCommonItem from '../../../common/AddCommonItem';

/**
 * Class component to show hystory data card 
 *
 * @class QualityCertificate
 * @extends {QualityCertificate}
 */
class QualityCertificate extends PureComponent {

    /**
     *Creates an instance of QualityCertificate.
     * @param {*} props
     * @memberof QualityCertificate
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            expanded: true,
            openRemoveCertificate: false,
            certificateItems: [],
        }
    }

    componentDidMount() {
        this.setState({ certificateItems: this.props.certificate.items});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.certificateItems !== this.props.certificate.items) {
            this.setState({certificateItems: this.props.certificate.items});
        }
    }

    /**
     * Handle state to expand or collapse card content
     *
     * @memberof HistoryCard
     */
    handleExpand() {
        this.setState({ expanded: !this.state.expanded })
    }

    onDeleteCertificate() {
        this.setState({
            openRemoveCertificate: true,
        })
    }

    onRemoveQualityCertificate(certificateToken){
        return this.props.onRemoveQualityCertificate(certificateToken)
        .then(() => {
            this.setState({
                openRemoveCertificate: false,
            })
        })
        .catch((error) => {
            return Promise.reject();
        })
    }
    
        /**
     * Method to render custom cell in DataTable
     *
     * @param {*} dataSource
     * @param {*} item
     * @returns
     * @memberof MaterialsTable
     */
    onRenderCellItem(dataSource, item) {
        if(dataSource === "option"){
            if (this.props.canBeEditSupplier){
                return  <Tooltip title={"Eliminar lote del material"}>
                        <DeleteIcon onClick={() => this.props.onRemoveItem(this.props.certificate.token, item.token)} color="error" style={{cursor: "pointer"}}  /> 
                    </Tooltip>
            }
            return("")
        }
        if (dataSource === "number_caja"){
            if (this.props.canBeEditSupplier){
                return this.renderTextField(dataSource, item, "number")
            }else{
                return(<Typography gutterBottom variant="caption" component="p">{item.number_caja}</Typography>)
            }
        }
        if (dataSource === "inspection_quantity"){
            if (this.props.canBeEditSupplier){
                return this.renderTextField(dataSource, item, "number")
            }else{
                return(<Typography gutterBottom variant="caption" component="p">{item.inspection_quantity}</Typography>)
            }
        }
        if (dataSource !== "option" && dataSource !== "number_caja" && dataSource !== "inspection_quantity") {
            if (this.props.canBeEditSupplier){
                return this.renderTextField(dataSource, item)
            }else{
                return(<Typography gutterBottom variant="caption" component="p">{item[dataSource]}</Typography>)
            }
        }
    }

    /**
     * renderTextField
     * @param {*} dataSource 
     * @param {*} item 
     * @param {*} type 
     */
    renderTextField(dataSource, item, type = "text") {
        return (
            <TextField
                id={dataSource}
                defaultValue={item[dataSource]}
                margin="normal"
                type={type}
                onBlur={(event) => this.props.onUpdateItem(this.props.certificate.token, item.token, dataSource, event.target.value)}
                onKeyDown={this.onKeyDown}
                inputProps={{
                    style: {fontSize: 12} 
                }}
            />
        )
    }

    /**
     * Perform the render lifcycle
     *
     * @returns
     * @memberof HistoryCard
     */
    render() {
        const {certificate, canBeEditSupplier, canbeDeleteCetificate } = this.props;
        return (
            <Card>
                <CardHeader
                    style={{ paddingBottom: '0px', paddingTop: '8px', backgroundColor: '#e8eff2f6' }}
                    action={
                        this.state.expanded ? 
                            <span>
                                {!canBeEditSupplier && <Tooltip title={<Translate id='deliveryOrderModule.donwloadCerificateQuality' />}>
                                    <Link color="primary" onClick={() => this.props.downloadCertificateQuaity(this.props.deliveryOrderToken, certificate.token)} to="#">
                                        <DownloadIcon style={{cursor: "pointer", marginTop: '10px', marginRight: '10px' }}  />
                                    </Link>
                                </Tooltip>}
                                 {canbeDeleteCetificate && <Tooltip title={<Translate id='common.delete' />}>
                                    <DeleteIcon onClick={() => this.onDeleteCertificate()} color="error" style={{cursor: "pointer", marginTop: '10px', marginRight: '10px' }}  /> 
                                </Tooltip>}
                                <Tooltip title={<Translate id='common.clickToContract' />}>
                                    <ExpandLessIcon onClick={() => this.handleExpand()} style={{cursor: "pointer", marginTop: '10px', marginRight: '10px' }} />
                                </Tooltip>
                            </span> :
                            <span>
                                  {!canBeEditSupplier &&<Tooltip title={<Translate id='deliveryOrderModule.donwloadCerificateQuality' />}>
                                    <Link color="primary" onClick={() => this.props.downloadCertificateQuaity(this.props.deliveryOrderToken, certificate.token)} to="#">
                                        <DownloadIcon style={{cursor: "pointer", marginTop: '10px', marginRight: '10px' }}  />
                                    </Link>
                                </Tooltip>}
                                  {canbeDeleteCetificate && <Tooltip title={<Translate id='common.delete' />}>
                                    <DeleteIcon onClick={() => this.onDeleteCertificate()} color="error" style={{cursor: "pointer", marginTop: '10px', marginRight: '10px' }} /> 
                                </Tooltip>}
                                <Tooltip title={<Translate id='common.clickToExpand' />}>
                                    <ExpandMoreIcon onClick={() => this.handleExpand()} style={{cursor: "pointer", marginTop: '10px', marginRight: '10px' }} />
                                </Tooltip>
                            </span>
                            
                    }
                    title={
                        <Typography onClick={() => this.handleExpand()} style={{cursor: "pointer"}} gutterBottom variant="subtitle1"
                            component="span" color="textPrimary">
                            <CertificateIcon color='secondary' />
                            {' '}<Translate id={"deliveryOrderModule.certificateMessage"} /> {' '}: <strong>{certificate.part_number_code + ' | ' + certificate.part_number_description}</strong>
                        </Typography>
                    }
                />
               
                {/** Content of card*/}
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <CardHeader
                            style={{ backgroundColor: COLOR_CERTIFICATE_BLUE }}
                            title={
                                <Typography gutterBottom variant="body2" component="p">
                                    <strong><Translate id={"deliveryOrderModule.materialsTable.codePtm"} /></strong>: {' ' + certificate.part_number_code + ' | ' + certificate.part_number_description} <br/>
                                    <strong><Translate id={"deliveryOrderModule.materialsTable.purchaseOrder"} /></strong>: {' ' + certificate.purchase_order}<br/>
                                    <strong>{"Cantiad en la orden"}</strong>: {certificate.order_quantity}<br/>
                                    <strong>{"Cantidad que ispecciono"}</strong>: {certificate.inspection_quantity}<br/>
                                    <strong>{"Porcetaje de material inspeccionado"}</strong>: {certificate.percentage_inspection} % <br/>
                                </Typography>
                            }
                            action={
                                <Typography gutterBottom variant="body2" component="p">
                                    <strong><Translate id={"deliveryOrderModule.numberCertificate"} /></strong>: {' ' + certificate.number_certificate}<br/>
                                    <strong><Translate id="deliveryOrderModule.transportLine" /></strong>: {certificate.transport_line}<br/>
                                    <strong><Translate id="deliveryOrderModule.loadConditions" /></strong>: {certificate.load_conditions}<br/>
                                    <strong><Translate id="deliveryOrderModule.numberRegisterQuality" /></strong>: {certificate.number_register_quality}<br/>
                                    <strong><Translate id="deliveryOrderModule.namewhocertifies" /></strong>: {certificate.name_who_certifies}<br/>
                                    <strong><Translate id="deliveryOrderModule.namevoboTransport" /></strong>: {certificate.name_vobo_transport}
                                </Typography>
                            }
                        />
                        <br/>
                        {certificate.items &&
                            <Grid container spacing={24} justify="flex-end" alignItems="center">
                                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                    <Card  style={{ backgroundColor: COLOR_STATUS_ACEPTED, height:"55px" }}>
                                        <CardContent>
                                            <Typography variant="overline" align="center">
                                                <strong><Translate id={"deliveryOrderModule.presentation"} /></strong>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                    <Card style={{ backgroundColor: COLOR_STATUS_ACEPTED, height:"55px" }}>
                                        <CardContent style={{ paddingLeft: 0 }}>
                                            <Typography variant="overline" align="center">
                                                <strong><Translate id={"deliveryOrderModule.packingSpec"} /></strong>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                {canBeEditSupplier && <Grid item xs={1}>
                                    <Tooltip title={"Agregar lote del material"}>
                                         <Fab size="small" color="secondary"
                                            style={{ marginLeft: '1em' }}
                                            onClick={() => this.props.onAddItem(certificate.token)}>
                                            <AddItemIcon />
                                        </Fab>
                                    </Tooltip>
                                </Grid>}
                                <DataTable
                                    data={this.state.certificateItems ? this.state.certificateItems : []}
                                    configuration={TableItemsConfiguration}
                                    onRenderCellItem={this.onRenderCellItem}
                                    isSaving={this.props.isSaving}
                                />
                            </Grid>
                        }
                    </CardContent>
                </Collapse>
                <br/>

                < AddCommonItem
                    title={<Translate id="deliveryOrderModule.removeCertificate" />}
                    message={<Translate id="deliveryOrderModule.messageRemoveCertifica" />}
                    open={this.state.openRemoveCertificate}
                    onClose={() => { this.setState({ openRemoveCertificate: false }) }}
                    onSaveItem={() => {this.onRemoveQualityCertificate(certificate.token)}}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                />
            </Card>
        );
    }
}

const TableItemsConfiguration = {
    columns: [{
        header: <Translate id={"deliveryOrderModule.materialsTable.materialLot"} />,
        dataSource: 'lot',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.numberCaja"} />,
        dataSource: 'number_caja',
    },{
        header:<Translate id={"deliveryOrderModule.materialsTable.inspectionQuantity"} />,
        dataSource: 'inspection_quantity',
    },{
        header:<Translate id={"deliveryOrderModule.materialsTable.color"} />,
        dataSource: 'color',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.particleSize"} />,
        dataSource: 'particle_size',
    },{
        header:<Translate id={"deliveryOrderModule.materialsTable.pelet"} />,
        dataSource: 'pelet',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.pollutionFreeMaterial"} />,
        dataSource: 'pollution_free_material',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.moistureFree"} />,
        dataSource: 'moisture_free',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.saco"} />,
        dataSource: 'saco',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.costal"} />,
        dataSource: 'costal',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.caja"} />,
        dataSource: 'caja',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.tarima"} />,
        dataSource: 'tarima',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.others"} />,
        dataSource: 'others',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.turn"} />,
        dataSource: 'turn',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.impact"} />,
        dataSource: 'impact',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.fluenct"} />,
        dataSource: 'fluenct',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.operator"} />,
        dataSource: 'operator_turn',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.elongancion"} />,
        dataSource: 'elongancion',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.releaseTurn"} />,
        dataSource: 'release_turn',
    },{
        header: <Translate id={"deliveryOrderModule.materialsTable.ashContainer"} />,
        dataSource: 'ash_container',
    },{
        header: "",
        dataSource: 'option',
    },]
}


QualityCertificate.propTypes = {
    certificate: PropTypes.object.isRequired,
    onRemoveQualityCertificate: PropTypes.func,
    canBeEditSupplier: PropTypes.bool.isRequired,
};

export default (QualityCertificate);