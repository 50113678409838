/**Import react section */
import React, { useState } from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
/**Import MaterialUi section */
import {
  Grid,
  Avatar,
  ListItemText,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
  Tooltip,
  Fab,
  CircularProgress,
} from "@material-ui/core";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
/**Import components section */
import LateralPanel from "../../../common/LateralPanelComponents/LateralPanel";
import PanelHeader from "../../../common/LateralPanelComponents/PanelHeader";
/**Import store section */
import IsWinnerIcon from "../../../../resources/images/winners/isWinner.png";
import NoWinnerIcon from "../../../../resources/images/winners/noWinner.png";

import { IS_DECLINED_MATERIAL_BY_SUPPLIER } from "../../../../store/helpers/BiddingHelper";
import {
  COLOR_STATUS_SUCCESS,
  COLOR_STATUS_DANGER,
} from "../../../../store/helpers/StatusColorConstants";

const AddWinnersLateralPanel = (props) => {
  /**props section */
  const {
    onCloseButtonClick,
    onAddWinnerInItemFromBackend,
    isSaving,
    classes,
    translate,
    bidding,
    item,
  } = props;
  const [identifierIsSaving, setIdentifierIsSaving] = useState(false);
  const [identifierItemIdIsSaving, setIdentifierItemIdIsSaving] =
    useState(false);

  const onAddWinnerInItem = (supplier, isWinner) => {
    if (props.onAddWinnerInItemFromBackend) {
      setIdentifierIsSaving(supplier.supplierCode);
      setIdentifierItemIdIsSaving(supplier.itemId);
      onAddWinnerInItemFromBackend(supplier, isWinner);
    }
  };

  return (
    <LateralPanel onClose={onCloseButtonClick}>
      <PanelHeader
        titlePanel={
          <span>
            {translate("biddingsModule.winners")} {bidding ? bidding.CategoryName : ""}{" "}
          </span>
        }
        subTitlePanel={`${translate("biddingsModule.table.folio")} | ${
          bidding ? bidding.FormatedFolio : ""
        }`}
        onCloseButtonClick={onCloseButtonClick}
        icon={
          <Avatar className={classes.avatar}>
            <ViewCarouselIcon />
          </Avatar>
        }
        isIconMaterial={true}
      />
      <div>
        <div className={classes.containerInformation}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="primary">
                {item ? item.nombreCriteria : ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                {item && item.suppliers && item.suppliers.lenght !== 0 ? (
                  item.suppliers.map((supplier) => {
                    let isDeclinedMaterial =
                      supplier.group === IS_DECLINED_MATERIAL_BY_SUPPLIER;
                    return (
                      <ListItem divider>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle2"
                              className={classes.txtWraped}
                            >
                              {supplier.supplierName}
                            </Typography>
                          }
                          secondary={
                            isDeclinedMaterial ? (
                              <Typography
                                variant="subtitle2"
                                className={classes.declinedMaterial}
                              >
                                {translate("biddingsModule.declinedMaterial")}
                              </Typography>
                            ) : null
                          }
                        />

                        {!isDeclinedMaterial && (
                          <ListItemSecondaryAction>
                            <Tooltip
                              title={
                                supplier.isSelectedWinner
                                  ? translate("biddingsModule.deletedWinner")
                                  : translate("biddingsModule.setAsWinner")
                              }
                            >
                              <Fab
                                className={classes.fabWinner}
                                size="small"
                                disabled={
                                  isSaving &&
                                  supplier.supplierCode ===
                                    identifierIsSaving &&
                                  supplier.itemId === identifierItemIdIsSaving
                                }
                                onClick={(event) =>
                                  onAddWinnerInItem(
                                    supplier,
                                    !supplier.isSelectedWinner
                                  )
                                }
                              >
                                <img
                                  alt="WinnerIcon"
                                  className={classes.winnerSize}
                                  src={
                                    supplier.isSelectedWinner
                                      ? IsWinnerIcon
                                      : NoWinnerIcon
                                  }
                                />
                              </Fab>
                            </Tooltip>
                            {isSaving &&
                              supplier.supplierCode === identifierIsSaving &&
                              supplier.itemId === identifierItemIdIsSaving && (
                                <CircularProgress
                                  size={52}
                                  className={classes.fabProgress}
                                />
                              )}
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    );
                  })
                ) : (
                  <Typography variant="subtitle1" color="primary">
                    {translate("biddingsModule.notExistSuppliers")}
                  </Typography>
                )}
              </List>
            </Grid>
          </Grid>
        </div>
      </div>
    </LateralPanel>
  );
};

const styles = (theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  containerInformation: {
    maxHeight: `calc(100vh - ${135}px)`,
    minHeight: `calc(100vh - ${135}px)`,
    overflowY: "auto",
    padding: "20px",
  },
  txtWraped: {
    maxWidth: "250px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  winnerSize: {
    width: "20px",
    height: "auto",
  },
  fabWinner: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  fabProgress: {
    color: COLOR_STATUS_SUCCESS,
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  declinedMaterial: {
    color: COLOR_STATUS_DANGER,
  },
});

AddWinnersLateralPanel.propTypes = {
  /**
   * callback executed add new user in the list
   */
  onSaveButtonClick: propTypes.func,
  /**
   * open and closedlateral panel for add an new user in customer
   */
  onCloseButtonClick: propTypes.func,
  /**
   * Determinate if is saving data
   */
  isSaving: propTypes.bool.isRequired,
};

AddWinnersLateralPanel.defaultProps = {
  onSaveButtonClick: () =>
    console.warn("Callback [onSaveButtonClick] no defined"),
  onCloseButtonClick: () =>
    console.warn("Callback [onCloseButtonClick] no defined"),
  isSaving: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(AddWinnersLateralPanel)
);
