/** Import ReactJS */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
/** Material UI import section */
import { Avatar, Card, Grid, Divider, IconButton, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ReplyAllIcon from '@material-ui/icons/ReplyAll'

/**Import section components */
import DataTable from '../../../common/DataTable'

/**Import resources section */
import { COLOR_STATUS_ALERT_INFO } from '../../../../store/helpers/StatusColorConstants'
import { descriptionStatus } from '../../../../store/helpers/FactoringAfiliationHelper'
import { IsUserInRole, ROLE_FACTORING_AFFILIATION_CREATOR } from '../../../../store/helpers/RolesHelper'
/** Resources import section */
const useStyle = (theme) => ({
	card: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
	},
	cardcontent: {
		padding: 0,
		'&:last-child': {
			padding: 0,
		},
	},
	textTitle: {
		fontWeight: 'bold',
	},
	detailsIcon:{
		transform: 'rotate(180deg)',
	}
})

const FactoringTable = (props) => {
	const { classes } = props
	//Props section
	const { onRedirectToCreateFactoringAfiliation, factoringAffiliation, onRowClick, isInternalUser } = props
	//Array props section
	const { userRolesByCompany } = props
	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'Center') {
			return item.Center
		}
		if (dataSource === 'FactoringStatus') {
			return descriptionStatus(item.FactoringStatus)
		}
		if (dataSource === 'BankType') {
			return item.BankType === 1 ? 'BBVA' : 'CITI'
		}
		if (dataSource === 'Currencies') {
			return item.Currencies.join(', ')
		}
		if(dataSource === "icon"){
			return (
			<Tooltip title={<Translate id='factoringAffiliation.show.details' />}>
				<IconButton
					color="primary"
					variant="outlined"
					size="small"
					onClick={()=>onRowClick(item)}
				>
					<ReplyAllIcon className={classes.detailsIcon}/>
				</IconButton>
			</Tooltip>
			)
		}
	}
	let isUserCreator = IsUserInRole(ROLE_FACTORING_AFFILIATION_CREATOR, userRolesByCompany)

	return (
		<Grid container spacing={16}>
			<Grid item xs={12}>
				<Card>
					<CardHeader
						className={classes.card}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						action={
							isInternalUser &&
							isUserCreator && (
								<IconButton onClick={onRedirectToCreateFactoringAfiliation}>
									<AddIcon />
								</IconButton>
							)
						}
						title={<Translate id='factoringAffiliation.show.historyFactoring' />}
					/>
					<Divider />
					<CardContent>
						<DataTable
							data={
								factoringAffiliation && factoringAffiliation.Factorings
									? factoringAffiliation.Factorings
									: []
							}
							configuration={{ columns: tableConfiguration(true) }}
							onRenderCellItem={onRenderCellItem}
						/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)
}

/**
 * Set the service orders table configuration
 */
const tableConfiguration = (canBeEditInformation) => {
	let defaultColumns = [
		{
			header: <Translate id='factoringAffiliation.table.status' />,
			dataSource: 'FactoringStatus',
			isMovable: false,
		},
		{
			header: <Translate id='factoringAffiliation.table.center' />,
			dataSource: 'Center',
			isMovable: false,
		},
		{
			header: <Translate id='factoringAffiliation.table.paymentConditions' />,
			dataSource: 'PaymentConditions',
			isMovable: false,
		},
		{
			header: <Translate id='factoringAffiliation.table.bankType' />,
			dataSource: 'BankType',
			isMovable: false,
		},
		{
			header: <Translate id='factoringAffiliation.table.currency' />,
			dataSource: 'Currencies',
			isMovable: false,
		},
		{
			header: <Translate id='factoringAffiliation.table.comments' />,
			dataSource: 'Comments',
			isMovable: false,
		},
		{
			header: "",
			dataSource : "icon",
			isMovable: false,
		}
	]
	return defaultColumns
}

FactoringTable.propTypes = {}

FactoringTable.defaultProps = {}
export default withRouter(withStyles(useStyle, { withTheme: true })(FactoringTable))
