import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'

import { Avatar, Card, CardContent, CardHeader, IconButton, Typography } from '@material-ui/core'
import { Add, Delete, Security } from '@material-ui/icons'

import SearchInput from '../common/SearchInput/SearchInput'
import DataTable from '../common/DataTable'
import CreateCoursesPanel from './components/CreateCoursesPanel'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '../../store/securityCourses/securityCoursesSelecteor'
import {
	createSecurityCourses,
	deleteSecurityCourse,
	loadSecurityCoursesPaginated,
} from '../../store/securityCourses/securityCoursesActions'
import { FolderName, descriptionSecurityCoursesStatus } from '../../store/helpers/SecurityCoursesHelper'

import moment from 'moment'
import { canCloseRegistersAccessControl } from '../../store/helpers/RolesHelper'
import { converListToArray } from '../../store/helpers/ListHelper'

const Index = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	let location = useLocation()
	const history = useHistory()
	const queryParams = queryString.parse(location.search)

	const [openCreatePanel, setOpenCreatePanel] = useState(false)
	const [courseInformation, setCourseInformation] = useState(CourseDefaultInformation)

	const securityCoursesPaginated = useSelector((state) => selectors.getSecurityCoursesSelector(state))
	const isLoading = useSelector((state) => selectors.getIsLoadingSaving(state))
	const userProfile = useSelector((state) => state.oidc.user)

	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')
	const canActionsCreateAndDelete = canCloseRegistersAccessControl(
		props.userRolesByCompany ? props.userRolesByCompany : []
	)
	const onGetSecurityCoursesPaginated = () =>
		dispatch(
			loadSecurityCoursesPaginated({
				query: '',
				pageNumber: queryParams && queryParams.pageNumber ? parseInt(securityCoursesPaginated.pageNumber) : 0,
				rowsPerPage: queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20,
				sortBy: queryParams.sortBy ? queryParams.sortBy : '',
				sortDirection: queryParams.sortDirection ? queryParams.sortDirection : '',
			})
		)
	const onCreateSecurityCourse = () => {
		dispatch(createSecurityCourses(courseInformation)).then(() => {
			setOpenCreatePanel(false)
			setCourseInformation(CourseDefaultInformation)
		})
	}
	const onRowClick = (securityCourses, event) => {
		history.push(`/securityCourses/${securityCourses.id}`)
	}
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onDeleteSecurityCourse = (item, event) => {
		event.stopPropagation()
		if (item)
			dispatch(deleteSecurityCourse(item.id, item.file, FolderName.SECURITY_CURSES_VIDEO)).then(() => {
				onGetSecurityCoursesPaginated()
			})
	}
	useEffect(() => {
		onGetSecurityCoursesPaginated()
	}, [dispatch, location.search])
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'status')
			return <Typography variant='caption'>{descriptionSecurityCoursesStatus(item.status)}</Typography>
		if (dataSource === 'courseName')
			return (
				<Typography variant='caption' style={{ maxWidth: '200px' }}>
					{item.courseName}
				</Typography>
			)
		if (dataSource === 'courseNotes')
			return (
				<Typography variant='caption' style={{ maxWidth: '350px', maxHeight: '200px', overflow: 'auto' }}>
					{item.courseNotes}
				</Typography>
			)
		if (dataSource === 'activeDate')
			return <Typography variant='caption'>{moment(item.activeDate).format('DD/MM/YYYY')}</Typography>

		if (dataSource === 'expirationDate')
			return <Typography variant='caption'>{moment(item.expirationDate).format('DD/MM/YYYY')}</Typography>
		if (dataSource === 'options')
			return (
				<div>
					{isInternalUser() && canActionsCreateAndDelete && (
						<IconButton
							color='primary'
							onClick={(event) => onDeleteSecurityCourse(item, event)}
							disabled={isLoading}
						>
							<Delete />
						</IconButton>
					)}
				</div>
			)
	}
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar className={classes.avatar}>
						<Security />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						<SearchInput />
						{isInternalUser() && canActionsCreateAndDelete && (
							<IconButton className={classes.addButton} onClick={() => setOpenCreatePanel(true)}>
								<Add fontSize='small' />
							</IconButton>
						)}
					</div>
				}
				title={
					<Typography>
						<Translate id={'securityCourses.index.title'} />
					</Typography>
				}
			/>
			<CardContent>
				<div className={classes.cardContent}>
					<DataTable
						data={
							securityCoursesPaginated && securityCoursesPaginated.items
								? securityCoursesPaginated.items
								: []
						}
						configuration={TableConfiguration}
						onRenderCellItem={onRenderCellItem}
						isIndex
						totalRows={
							securityCoursesPaginated && securityCoursesPaginated.total
								? securityCoursesPaginated.total
								: 0
						}
						page={
							securityCoursesPaginated && securityCoursesPaginated.pageNumber
								? securityCoursesPaginated.pageNumber - 1
								: 0
						}
						rowsPerPage={
							securityCoursesPaginated && securityCoursesPaginated.pageSize
								? securityCoursesPaginated.pageSize
								: 20
						}
						sortBy={
							securityCoursesPaginated.sort && securityCoursesPaginated.sort.by
								? securityCoursesPaginated.sort.by
								: 'ActiveDate'
						}
						sortAscending={
							securityCoursesPaginated.sort && securityCoursesPaginated.sort.direction
								? securityCoursesPaginated.sort.direction === 'desc'
									? false
									: true
								: false
						}
						onChangeSortCriteria={onChangeSortCriteria}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
						onRowClick={onRowClick}
					/>
				</div>
			</CardContent>
			<CreateCoursesPanel
				open={openCreatePanel}
				courseInformation={courseInformation}
				setCourseInformation={setCourseInformation}
				onClose={() => setOpenCreatePanel(false)}
				onSubmit={onCreateSecurityCourse}
				isLoading={isLoading}
			/>
		</Card>
	)
}
const TableConfiguration = {
	columns: [
		{
			header: <Translate id='securityCourses.index.table.status' />,
			dataSource: 'status',
			isSortable: true,
		},
		{
			header: <Translate id='securityCourses.index.table.name' />,
			dataSource: 'courseName',
			isSortable: true,
		},
		{
			header: <Translate id='securityCourses.index.table.notes' />,
			dataSource: 'courseNotes',
			isSortable: true,
		},
		{
			header: <Translate id='securityCourses.index.table.activationDate' />,
			dataSource: 'activeDate',
			isSortable: true,
		},
		{
			header: <Translate id='securityCourses.index.table.expirationDate' />,
			dataSource: 'expirationDate',
			isSortable: true,
		},
		{
			header: '',
			dataSource: 'options',
		},
		{
			header: '',
			dataSource: '',
		},
	],
}

const CourseDefaultInformation = {
	CourseName: '',
	ActiveDate: '',
	ExpirationDate: '',
	CourseNotes: '',
	MediaFile: [],
}
const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
		alignItems: 'center',
	},
	addButton: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		marginLeft: '8px',
	},
	cardContent: {
		height: 'calc(100vh - 260px)',
	},
})
/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}
export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Index)))
)
