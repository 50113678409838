import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'

import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import { DoneAll, WarningRounded } from '@material-ui/icons'
import { Button, Typography } from '@material-ui/core'
import DatePicker from '../../common/DateTimePicker/DatePicker'

import moment from 'moment'

const DownloadLateralPanel = ({
	classes,
	open,
	onClose,
	onSubmit,
	isLoading,
	filtersCertificationReport,
	setFiltersCertificationReport,
}) => {
	const canBeSaved = () => {
		if (validationDates()) return false
		if (filtersCertificationReport && filtersCertificationReport.StartDate.length === 0) return false
		if (filtersCertificationReport && filtersCertificationReport.EndDate.length === 0) return false
		return true
	}
	const validationDates = () => {
		const startDate = filtersCertificationReport && filtersCertificationReport.StartDate.valueOf()
		const endDate = filtersCertificationReport && filtersCertificationReport.EndDate.valueOf()
		if (startDate <= endDate) return false
		if (
			filtersCertificationReport && filtersCertificationReport.StartDate.length !== 0 &&
			filtersCertificationReport && filtersCertificationReport.EndDate.length !== 0
		)
			return true
		return false
	}
	const onChangeDate = (property, data) => {
		setFiltersCertificationReport({
			...filtersCertificationReport,
			[property]: data,
		})
	}
	return (
		<LateralPanel open={open} onClose={onClose}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id={'certifications.downloadPanel.title'} />}
				onCloseButtonClick={onClose}
				icon={<DoneAll color='secondary' />}
			/>
			<div className={classes.content}>
				<div className={classes.dateContent}>
					<div>
						<Typography variant='caption'>
							<Translate id={'certifications.downloadPanel.startDate'} />
						</Typography>
						<DatePicker
							opens={'left'}
							drops={'down'}
							onChangeRange={(event) => onChangeDate('StartDate', event)}
							startDate={
								filtersCertificationReport && filtersCertificationReport.StartDate
									? moment(filtersCertificationReport.StartDate, 'DD/MM/YYYY')
									: null
							}
						/>
					</div>
					<div>
						<Typography variant='caption'>
							<Translate id={'certifications.downloadPanel.endDate'} />
						</Typography>
						<DatePicker
							opens={'left'}
							drops={'down'}
							onChangeRange={(event) => onChangeDate('EndDate', event)}
							startDate={
								filtersCertificationReport && filtersCertificationReport.EndDate
									? moment(filtersCertificationReport.EndDate, 'DD/MM/YYYY')
									: null
							}
						/>
					</div>
				</div>
				{validationDates() && (
					<div className={classes.helpText}>
						<WarningRounded />
						<Typography style={{ color: 'white', marginLeft: '2px' }}>
							<Translate id='certifications.downloadPanel.errorDates' />
						</Typography>
					</div>
				)}
			</div>
			<Button
				data-tut='reactour-save'
				data-cy='btn-save'
				type='button'
				color='primary'
				variant='outlined'
				className={!canBeSaved() || isLoading ? classes.btnCreateDisabled : classes.btnCreate}
				onClick={onSubmit}
				disabled={!canBeSaved() || isLoading}
			>
				{<Translate id='certifications.downloadPanel.download' />}
			</Button>
		</LateralPanel>
	)
}
const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	content: {
		margin: '8px',
	},
	dateContent: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	btnCreateDisabled: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `rgba(223, 226, 230, 1)`,
	},
	cardMedia: {
		backgroundColor: 'rgba(223, 226, 230, 1)',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		borderRadius: '8px',
	},
	actionBtn: {
		width: '453px',
		display: 'flex',
		justifyContent: 'flex-end',
		paddingTop: '5px',
	},
	cardContent: {
		padding: '8px',
	},
	infoCardMedia: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	infoTitleCardMedia: {
		color: theme.palette.primary.dark,
		fontWeight: 'bold',
	},
	helpText: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.common.white,
		padding: '8px',
		borderRadius: '8px',
		margin: '3px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
})
DownloadLateralPanel.propTypes = {
	open: propTypes.bool,
	isLoading: propTypes.bool,
	onClose: propTypes.func,
	onSubmit: propTypes.func,
	setFiltersCertificationReport: propTypes.func,
}
DownloadLateralPanel.defaultProps = {
	open: false,
	isLoading: false,
	onClose: () => console.warn('No [onclose] callBack defined'),
	onSubmit: () => console.warn('No [onSubmit] callBack defined'),
	setFiltersCertificationReport: () => console.warn('No [setFiltersCertificationReport] Callback defined'),
}
export default withRouter(withStyles(styles, { withTheme: true })(DownloadLateralPanel))
