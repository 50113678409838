/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';

export const START_LOADING_EXTERNAL_USERS = "START_LOADING_EXTERNAL_USERS";
export const SET_EXTERNAL_USERS_LIST = "SET_COMPANIES_LIST";
export const IS_LOADING_EXTERNAL_USER = "IS_LOADING_EXTERNAL_USER";
export const SET_EXTERNAL_USER = "SET_EXTERNAL_USER";
export const IS_SAVING_EXTERNAL_USER = "IS_SAVING_EXTERNAL_USER";
export const UPDATE_EXTERNAL_USER = "UPDATE_EXTERNAL_USER";
export const ADD_EXTERNAL_USER_TO_LIST = "ADD_EXTERNAL_USER_TO_LIST";
export const CHANGE_EXTERNAL_USERS_SORT_CRITERIA = "CHANGE_EXTERNAL_USERS_SORT_CRITERIA";
export const CHANGE_EXTERNAL_USERS_QUERY = "CHANGE_EXTERNAL_USERS_QUERY";
export const SET_EXTERNAL_USERS_COUNT = "SET_EXTERNAL_USERSS_COUNT";
export const SET_ENABLE_EXTERNAL_USER = "SET_ENABLE_EXTERNAL_USER";
export const SET_ENABLE_EXTERNAL_USER_INDEX = "SET_ENABLE_EXTERNAL_USER_INDEX";
export const SET_EXTERNAL_USERS_ROLES = "SET_EXTERNAL_USERS_ROLES";
export const IS_LOADING_EXTERNAL_USERS_ROLES = "IS_LOADING_EXTERNAL_USERS_ROLES";
export const ADD_EXTERNAL_USER_TO_ROLE = "ADD_EXTERNAL_USER_TO_ROLE";
export const REMOVE_EXTERNAL_USER_FROM_ROLE = "REMOVE_EXTERNAL_USER_FROM_ROLE";
export const UPDATE_EMIAL_EXTERNAL_USER = "UPDATE_EMIAL_EXTERNAL_USER";
export const SET_INFORMATION_USER_ADMINISTRATOR = "SET_INFORMATION_USER_ADMINISTRATOR";
export const UPDATE_USER_EXTERNAL_PASSWORD_ERROR_MESSAGE = "UPDATE_USER_EXTERNAL_PASSWORD_ERROR_MESSAGE";
export const START_UPDATING_PASSWORD_USER_EXTERNAL_DATA = "START_UPDATING_PASSWORD_USER_EXTERNAL_DATA";
export const SET_USERS_IN_ROLE_SALE_AND_BILLING = "SET_USERS_IN_ROLE_SALE_AND_BILLING";
export const IS_DOWNLOADING_REPORT_USERS = "IS_DOWNLOADING_REPORT_USERS";

/**
 * Action start loading users
 * @param {*} isLoading 
 */
function isLoadingUsers(isLoading) {
    return {
        type: START_LOADING_EXTERNAL_USERS,
        isLoading: isLoading
    }
}

/**
 * Action to set users list 
 * @param {*} users 
 */
function setUersList(users) {
    return {
        type: SET_EXTERNAL_USERS_LIST,
        users: users
    }
}

/**
 * Action reducer to set the extrenal users's counter
 */
function setExternalUsersCount(count) {
    return {
        type: SET_EXTERNAL_USERS_COUNT,
        count
    }
}


/**
 * Action to start load user
 */
function startLoadUser(isLoading) {
    return {
        type: IS_LOADING_EXTERNAL_USER,
        isLoadingUser: isLoading
    }
}

/**
 * Action to set user
 * @param {*} user 
 */
function setExternalUser(user) {
    return {
        type: SET_EXTERNAL_USER,
        externalUser: user
    }
}

/**
 * Action to is saving user data
 * @param {*} isSaving 
 */
function isSavingUser(isSaving) {
    return {
        type: IS_SAVING_EXTERNAL_USER,
        isSaving: isSaving
    }
}

/**
 * Action from add user to list
 */
function addUserToList(user) {
    return {
        type: ADD_EXTERNAL_USER_TO_LIST,
        user: user
    }
}

/**
 * Action from update user
 * @param {*} user 
 */
function updateUser(user) {
    return {
        type: UPDATE_EXTERNAL_USER,
        externalUser: user
    }
}

/**
 * Action to set enabled user
 * @param {*} enabled 
 */
function setEnabledUser(enabled) {
    return {
        type: SET_ENABLE_EXTERNAL_USER,
        enabled: enabled
    }
}

/**
 * Action to set enabled user
 * @param {*} enabled 
 */
function setEnabledUserIndex(userId) {
    return {
        type: SET_ENABLE_EXTERNAL_USER_INDEX,
        userId: userId
    }
}

/**
 * Action to update email
 * @param {*} enabled 
 */
function setEmailUpdateUser(user) {
    return {
        type: UPDATE_EMIAL_EXTERNAL_USER,
        user: user
    }
}

/**
 * Set external user roles
 *
 * @param {*} userId
 * @param {*} roles
 * @returns
 */
function setExternalUserRoles(userId, roles) {
    return {
        type: SET_EXTERNAL_USERS_ROLES,
        userId,
        roles
    }
}

/**
 * Is loading external user's roles
 *
 * @param {*} isLoading
 * @returns
 */
function isLoadingExternalUserRoles(isLoading) {
    return {
        type: IS_LOADING_EXTERNAL_USERS_ROLES,
        isLoading
    }
}

/**
 * Function to set information from user administrator
 * @param {*} user 
 */
function setUserAdministrator(user) {
    return {
        type: SET_INFORMATION_USER_ADMINISTRATOR,
        user: user
    }
}

/**
 * Action error update user´s external password
 * @param {*} message 
 */
function errorUpdatePasswordExternalUser(message) {
    return {
        type: UPDATE_USER_EXTERNAL_PASSWORD_ERROR_MESSAGE,
        errorMessage: message
    }
}

/**
 *Action is updating password for external user data
 *
 * @param {*} password
 * @returns
 */
function isUpdatingPassword(password) {
    return {

        type: START_UPDATING_PASSWORD_USER_EXTERNAL_DATA,
        password: password
    }
}

/**
 * Action to set users list with role sales an billing to update information
 * @param {*} users 
 */
function setUersSalesBillingInRoleList(users) {
    return {
        type: SET_USERS_IN_ROLE_SALE_AND_BILLING,
        users: users
    }
}

function isDonwloadingReportExtUsers(isLoading) {
    return {
        type: IS_DOWNLOADING_REPORT_USERS,
        isLoading
    }
}

/**
 * Get all users by supplier from backend 
 */
export function loadUsersSupplierFromBackEnd(query, orderBy, sortAscending, userEnabled, supplierToken) {
    return function (dispatch, getState) {
        dispatch(isLoadingUsers(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/company/users/external`;
        let data = {
            SupplierToken: supplierToken,
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            UserEnabled: userEnabled,
        }
        if (supplierToken) {
            endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/company/users/external/supplier`;
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setUersList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingUsers(false));
                console.error("Error getting the users by supplier list", error.response)
            })
    }
}

/**
 * Get supplier's count
 *
 * @export
 * @returns
 */
export function getExternalUserCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().suppliers.get('externalUsersCount');
        if (actualCounter) {
            return;
        }

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/company/users/external/count`;
        let data = {}
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setExternalUsersCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the users's count", error.response)
            })
    }
}

/**
 * Get and set the user in the estate from read from backend
 * @param {*} token 
 */
export function loadUser(token) {
    return function (dispatch, getState) {
        dispatch(startLoadUser(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user/${token}`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setExternalUser(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load user", error.response)
                dispatch(startLoadUser(false))
                return Promise.reject();
            })
    }
}

/**
 * Action to create user  
 * @param {*} user 
 */
export function createUserData(user) {
    return function (dispatch, getState) {

        dispatch(isSavingUser(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user`;
        let data = {
            UserName: `${user.number_supplier}${user.user_name}`,
            Name: user.full_name,
            Email: user.email,
        }
        return axios.post(endPoint, data, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(addUserToList(response.data))
                }
                else {
                    dispatch(isSavingUser(false));
                }
            })
            .catch((error) => {
                console.error("Error create user", error.response);
                dispatch(isSavingUser(false));
                return Promise.reject(error.response);
            })
    }
}

/**
 * Action to update user data
 * @param {*} provider 
 */
export function updateUserData(user) {
    return function (dispatch, getState) {

        dispatch(isSavingUser(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user/${user.id}`;
        let data = {
            Name: user.full_name,
            Email: user.email
        }
        return axios.put(endPoint, data, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updateUser(response.data))
                }
                else {
                    dispatch(isSavingUser(false));
                }
            })
            .catch((error) => {
                console.error("Error update user", error.response);
                dispatch(isSavingUser(false));
                return Promise.reject();
            })
    }
}

/**
 * Upload avatar user
 *
 * @export
 * @param {*} userId
 * @param {*} fileBase64
 * @returns
 */
export function uploadAvatarUserExternals(userId, fileBase64) {
    return function (dispatch, getState) {

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user/${userId}/avatar`;
        let data = { fileBase64 }

        return axios.put(endPoint, data, configuration)
            .then((response) => {
                dispatch(setExternalUser(response.data))
                return Promise.resolve();
            })
            .catch((err) => {
                console.error("Error uploading user avatar", err.response)
                return Promise.reject();
            })
    }
}


/**
 * Reset a user password
 *
 * @export
 * @param {*} userName
 */
export function resetPassword(userName) {
    return function (dispatch, getState) {

        dispatch(isSavingUser(true));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/users/resetpassword`;
        let data = { userName: userName }
        return axios.post(endPoint, data, configuration)
            .then((response) => {
                dispatch(isSavingUser(false));
                return Promise.resolve(response);
            })
            .catch((err) => {
                console.error("Unexpdected error restoring user's password", err.response)
                dispatch(isSavingUser(false));
                if (err.status === 400) {
                    return Promise.reject(err.response);
                } else {
                    return Promise.reject(err.response);
                }
            })

    }
}

/**
 * Action to reset password confirmation 
 */
export function resetPasswordConfirmation(requestToken) {
    return function (dispatch, getState) {

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/users/resetpassword/${requestToken}`;
        let data = {}

        return axios.post(endPoint, data, configuration)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                console.error("Unexpdected error restoring user's password", err.response)
                if (err.response.status === 400) {
                    return Promise.reject(err);
                } else {
                    console.error("Unexpdected error restoring user's password", err.response)
                    return Promise.reject(err);
                }
            })
    }
}


/**
 * Action to set lockout enable user external
 * @param {*} userId 
 * @param {*} enabled 
 */
export function setLockoutEnabledExternal(userId, enabled, comments, isInIndex) {
    return function (dispatch, getState) {
        dispatch(isSavingUser(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user/${userId}/lockoutenabled`;
        let data = {
            LockoutEnabled: enabled,
            Comments: comments,
        }

        return axios.put(endPoint, data, configuration)
            .then((response) => {
                if (response.status === 200) {
                    if (isInIndex) {
                        dispatch(setEnabledUserIndex(userId))
                    }
                    else {
                        dispatch(setEnabledUser(enabled))
                    }
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingUser(false))
                console.error(error);
            });
    }
}

/**
 * Load user's role
 *
 * @export
 * @param {*} userId
 * @returns
 */
export function loadUserExternalRoles(userId) {
    return function (dispatch, getState) {
        dispatch(isLoadingExternalUserRoles(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${userId}/roles`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setExternalUserRoles(userId, serverResponse.data))
                    dispatch(isLoadingExternalUserRoles(false));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error loading user's roles", error.response)
                dispatch(isLoadingExternalUserRoles(false));
                return Promise.reject();
            })
    }
}


/**
 * Toggle the assignation of a user in a role
 *
 * @export
 * @param {*} assigned
 * @param {*} company
 * @param {*} role
 */
export function toggleRoleAssignentExternalUser(externalUser, assigned, company, role) {
    return function (dispatch, getState) {
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${externalUser.id}/roles/${role.id}`;
        let endPointAdd = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/role`;
        if (assigned === true) {
            let supplierCode = "";
            if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
                supplierCode = getState().oidc.user.profile.company_code;
            }
            let data = {
                UserId: externalUser.id,
                RoleId: role.id,
                UserName: externalUser.user_name,
                Name: externalUser.full_name,
                UserEmail: externalUser.email,
                SupplierCode: supplierCode,
            }
            return axios.post(endPointAdd, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch({
                            type: ADD_EXTERNAL_USER_TO_ROLE,
                            companyToken: company.token,
                            roleId: role.id
                        })
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    console.error("Error adding a user into a role", error.response)
                    dispatch(isLoadingExternalUserRoles(false));
                    return Promise.reject();
                })

        } else {
            return axios.delete(endPoint, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch({
                            type: REMOVE_EXTERNAL_USER_FROM_ROLE,
                            companyToken: company.token,
                            roleId: role.id
                        })
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    console.error("Error removing the user from a role", error.response)
                    dispatch(isLoadingExternalUserRoles(false));
                    return Promise.reject();
                })
        }
    }
}

/**
 * Action to updateEmailUsersSupplier
 * @param {*} provider 
 */
export function updateEmailUsersSupplier(user) {
    return function (dispatch, getState) {

        dispatch(isSavingUser(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user/${user.id}`;
        let data = {
            Name: user.full_name,
            Email: user.email
        }
        return axios.put(endPoint, data, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(setEmailUpdateUser(response.data))
                }
                else {
                    dispatch(isSavingUser(false));
                }
            })
            .catch((error) => {
                console.error("Error update user", error.response);
                dispatch(isSavingUser(false));
                return Promise.reject();
            })
    }
}

/**
 * Get and set the user in the estate from read from backend
 * @param {*} token 
 */
export function loadAdministratorSupplier(userName) {
    return function (dispatch, getState) {

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user/userName/${userName}`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setUserAdministrator(serverResponse.data))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                console.error("Error to load user administrator", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to set setUserNameLoggedInSupplier
 * @param {*} provider 
 */
export function setUserNameLoggedInSupplier(user_name) {
    return function (dispatch, getState) {

        dispatch(isSavingUser(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let user = getState().oidc.user;
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/users/setUserNameLoggedInSupplier/${user.profile.sub}/${user_name}`;

        return axios.post(endPoint, null, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isSavingUser(false));
                    return Promise.resolve(response.data);
                }
                else {
                    dispatch(isSavingUser(false));
                }
            })
            .catch((error) => {
                console.error("Error update user", error.response);
                dispatch(isSavingUser(false));
                return Promise.reject();
            })
    }
}

/**
 *Update a user´s external password by id from backend
 *
 * @export
 */
export function updateUserExternalPasswordDataFromBackend(updatePassword, userToken) {
    return function (dispatch, getState) {
        dispatch(isUpdatingPassword(true))
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/users/${userToken}/password`;
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        return axios.put(endPoint, updatePassword, configuration)
            .then(() => {
                dispatch(isUpdatingPassword(false))
            })
            .catch((error) => {
                dispatch(isUpdatingPassword(false))
                dispatch(errorUpdatePasswordExternalUser("Se generó un error al actualizar la contraseña del usuario"));

                return Promise.reject();
            })
    }
}

/**
 * Action load users in role sales and billidn to update information
 */
export function getUsersSaleAndBulling() {
    return function (dispatch, getState) {

        let usersROles = getState().externalUsers ? getState().externalUsers.get('usersRoleSaleBilling') : null;
        if (usersROles) {
            return Promise.resolve();
        }

        dispatch(isLoadingExternalUserRoles(true));

        let supplierCode = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierCode = getState().oidc.user.profile.company_code;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/roles/externalusersroles/bulling`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SupplierCode: supplierCode
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setUersSalesBillingInRoleList(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error loading user's roles", error.response)
                dispatch(isLoadingExternalUserRoles(false));
                return Promise.reject();
            })
    }
}



/**
 * Action to set setUserNameLoggedInSupplier
 * @param {*} provider 
 */
export function activeUserSupplierExpiredToSingPortal(userIdActive) {
    return function (dispatch, getState) {
        dispatch(isSavingUser(true));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/users/active/${userIdActive}/expired`;
        return axios.put(endPoint, null, configuration)
            .then((response) => {
                dispatch(isSavingUser(false));
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error update user", error.response);
                dispatch(isSavingUser(false));
                return Promise.reject();
            })
    }
}

/**
 * Action to set setUserNameLoggedInSupplier
 * @param {*} provider 
 */
export function activeAllUserSupplierExpiredToSingPortal(allUserIds) {
    return function (dispatch, getState) {
        dispatch(isSavingUser(true));
        const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        // const endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/users/active/${userIdActive}/expired`;
        const allRequests = allUserIds.map(user => axios.put(`${process.env.REACT_APP_IDENTITY_SERVER}api/users/active/${user.id}/expired`), null, configuration)
        return Promise.all(allRequests)
            .then((allResponses) => {
                dispatch(isSavingUser(false));
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error update user", error.response);
                dispatch(isSavingUser(false));
                return Promise.reject();
            })
    }
}

/**
 *Download report of users sing portal 
 *
 * @export
 * @returns
 */
export function downloadReportSignUsersExternal(startDate, endDate) {
    return (dispatch, getState) => {
        dispatch(isDonwloadingReportExtUsers(true));
        let data = {
            StartDate: startDate,
            EndDate: endDate
        }
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/users/report/externalusers`;
        return axios.post(endPoint, data, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isDonwloadingReportExtUsers(false));
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reporte_ingreso_portal.xlsx";
                    a.click();
                }
            })
            .catch((error) => {
                dispatch(isDonwloadingReportExtUsers(false));
                console.error("Error downloading report sing external users", error.response)
                return Promise.reject();
            })
    }
}

export function downloadReportRampSuppliers() {
    return (dispatch, getState) => {
        dispatch(isDonwloadingReportExtUsers(true));
        let data = {
        }
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/report/RampSuppliers`;
        return axios.post(endPoint, data, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isDonwloadingReportExtUsers(false));
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reporte_rampas_proveedores.xlsx";
                    a.click();
                }
            })
            .catch((error) => {
                dispatch(isDonwloadingReportExtUsers(false));
                console.error("Error downloading report sing external users", error.response)
                return Promise.reject();
            })
    }
}
