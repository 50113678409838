/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';
import { underScoreToTitleCase } from '../helpers/StringHelper';

export const CHANGE_SUSTAINABILITY_SORT_CRITERIA = "CHANGE_SUSTAINABILITY_SORT_CRITERIA";
export const CHANGE_SUSTAINABILITY_QUERY = "CHANGE_SUSTAINABILITY_QUERY";
export const CHANGE_SUSTAINABILITY_PAGINATION = "CHANGE_SUSTAINABILITY_PAGINATION";
export const IS_LOADING_QUESTIONNAIRES_INDEX = "IS_LOADING_QUESTIONNAIRES_INDEX";

export const SET_SUSTAINABILITY_QUESTIONNAIRES_LIST = "SET_SUSTAINABILITY_QUESTIONNAIRES_LIST";
export const SET_SUSTAINABILITY_QUESTIONNAIRES_COUNT = "SET_SUSTAINABILITY_QUESTIONNAIRES_COUNT";

//Single questionnaire consts
export const IS_LOADING_QUESTIONNAIRE = "IS_LOADING_QUESTIONNAIRE";
export const IS_SAVING_QUESTIONNAIRE = "IS_SAVING_QUESTIONNAIRE";
export const SET_SUSTAINABILITY_QUESTIONNAIRE = "SET_SUSTAINABILITY_QUESTIONNAIRE";
export const UPDATE_WORKERS_LIST = "UPDATE_WORKERS_LIST";
export const UPDATE_QUESTION_IN_LIST = "UPDATE_QUESTION_IN_LIST";

function isLoadingIndex(isLoadingIndex) {
    return {
        type: IS_LOADING_QUESTIONNAIRES_INDEX,
        isLoadingIndex
    }
}

function setQuestionnairesList(questionnaires) {
    return {
        type: SET_SUSTAINABILITY_QUESTIONNAIRES_LIST,
        questionnaires
    }
}

function setQuestionnairesCount(count) {
    return {
        type: SET_SUSTAINABILITY_QUESTIONNAIRES_COUNT,
        count
    }
}

///SINGLE QUESTIONNAIRE FUNCTIONS

function isLoadingQuestionnaire(isLoadingQuestionnaire) {
    return {
        type: IS_LOADING_QUESTIONNAIRE,
        isLoadingQuestionnaire
    }
}

function isSavingQuestionnaire(isSavingQuestionnaire) {
    return {
        type: IS_SAVING_QUESTIONNAIRE,
        isSavingQuestionnaire
    }
}

function setQuestionnaire(questionnaire) {
    return {
        type: SET_SUSTAINABILITY_QUESTIONNAIRE,
        questionnaire
    }
}

function updateQuestion(updatedQuestion, listName) {
    return {
        type: UPDATE_QUESTION_IN_LIST,
        updatedQuestion,
        listName
    }
}

export function loadSustainabilityQuestionnairesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {
        dispatch(isLoadingIndex(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/sustainability/${selectedCompany.get('token')}/${supplierToken}?`;

        if (orderBy) {
            if (!sortAscending) {
                sortAscending = false
            }
            endPoint = `${endPoint}sortBy=${orderBy}&sortAscending=${sortAscending}`;
        }
        if (page)
            endPoint = `${endPoint}&page=${page + 1}`;
        if (rowsPerPage)
            endPoint = `${endPoint}&rowsPerPage=${rowsPerPage}`;
        if (query) {
            endPoint = `${endPoint}&query=${query}`;
        }

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setQuestionnairesList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingIndex(false));
                console.error("Error getting the questionnaires by company list", error.response)
            })
    }
}


export function getSustainabilityQuestionnairesCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().sustainability ? getState().sustainability.get('questionnairesCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/sustainability/count/${selectedCompany.get('token')}/${supplierToken}`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setQuestionnairesCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the questionnaires count", error.response)
            })
    }
}

export function loadQuestionnaireFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(isLoadingQuestionnaire(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/sustainability/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setQuestionnaire(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load sustainability questionnaire", error.response)
                dispatch(isLoadingQuestionnaire(false))
                return Promise.reject();
            })
    }
}

/**
 * Action to create sustainability questionnaire
 * @param {*} suppliers 
 * @param {*} comment 
 */
export function createSustainabilityQuestionnaire(sustainability) {
    return (dispatch, getState) => {

        dispatch(isSavingQuestionnaire(true))
        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let data = {
            CompanyToken: selectedCompany.get('token'),
            CompanyIdentifier: selectedCompany.get('get_company_identifier'),
            DocumentIdentifier: selectedCompany.get('document_identifier'),
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            CreateEmail: user.profile.email,
            Comments: sustainability.comments,
            Suppliers: sustainability.suppliers,
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/sustainability`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingQuestionnaire(false))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                console.error("Error creating sustainability questionnaire", error.response)
                dispatch(isSavingQuestionnaire(false))
                return Promise.reject(error.response.data.Message);
            })
    }
}

export function sendQuestionnaireResponseBySupplier(token) {
    return (dispatch, getState) => {

        dispatch(isSavingQuestionnaire(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            QuestionnaireToken: token,
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/sustainability/responsebysupplier`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingQuestionnaire(false))
                    dispatch(setQuestionnaire(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingQuestionnaire(false))
                console.error("Error in send client ", error.response)
                return Promise.reject(error.response.data.Message);
            })
    }
}

export function resendQuestionnaireToSupplier(token, comments) {
    return (dispatch, getState) => {

        dispatch(isSavingQuestionnaire(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            QuestionnaireToken: token,
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            Comments: comments
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/sustainability/resendtosupplier`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingQuestionnaire(false))
                    dispatch(setQuestionnaire(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingQuestionnaire(false))
                console.error("Error in resend to supplier ", error.response)
                return Promise.reject(error.response.data.Message);
            })
    }
}


export function updateProperty(questionnaireToken, property, propertyData) {
    return function (dispatch, getState) {
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/sustainability/${questionnaireToken}`;
        let data = {
            [underScoreToTitleCase(property)]: propertyData
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setQuestionnaire(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to update property", error.response)
                return Promise.reject();
            })
    }
}

export function updatePropertyInGeneralDataList(itemToken, property, propertyData) {
    return function (dispatch, getState) {
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/sustainability/workers/${itemToken}`;
        let data = {
            [underScoreToTitleCase(property)]: propertyData
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setQuestionnaire(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to update property in workers list", error.response)
                return Promise.reject();
            })
    }
}

export function updatePropertyInQuestionnaireList(itemToken, property, propertyData, listName) {
    return function (dispatch, getState) {
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/sustainability/questions/${itemToken}`;
        let data = {
            [underScoreToTitleCase(property)]: propertyData
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateQuestion(serverResponse.data, listName))
                }
            })
            .catch((error) => {
                console.error("Error to update property in quaetionnaire list", error.response)
                return Promise.reject();
            })
    }
}

export function closeQuestionnaireInPlant(token) {
    return (dispatch, getState) => {

        dispatch(isSavingQuestionnaire(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            QuestionnaireToken: token,
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/sustainability/closeinplant`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingQuestionnaire(false))
                    dispatch(setQuestionnaire(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingQuestionnaire(false))
                console.error("Error in close  questionnaires in plant", error.response)
                return Promise.reject(error.response.data.Message);
            })
    }
}