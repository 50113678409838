
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ListIcon from '@material-ui/icons/ViewList';
import AceptedIcon from '@material-ui/icons/ThumbsUpDownTwoTone';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from "@material-ui/core/Typography";
import Badge from '@material-ui/core/Badge';
import { Link } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import ReworkIcon from '@material-ui/icons/AutorenewOutlined';

import LoadingPage from '../common/LoadingPage';
import FloatingButton from '../common/FloatingButton';
import DialogAvailableOperations from '../common/DialogAvailableOperations';
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow';
import InformationPartial from './components/InformationPartial';
import HistoryCard from '../common/HistoryCard';
import DialogAutorizactions from './components/DialogAutorizactions';
import RequestReworkAuthorizer from './components/RequestReworkAuthorizer';
import DialogoQuality from './components/DialogoQuality';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import { NONCONFORMITYDOCUMENT_REQUEST_REWORK, REWORKREPORT } from '../../store/helpers/WorkFlowDocumentsType';

/** Import action section */
import { loadSupplier } from '../../store/suppliers/SupplierActions';
import {
    loadReworkFromBackEnd, authorizerRequestRework, rejectedRequestRework,
    acceptedRequestReworkReport, rejectedRequestReworkReport, reworkCloseToScrap
} from '../../store/reworks/ReworkAction';
import {closedWorkflowManuallyFromBackend} from '../../store/workflows/workflowActions';
import { converListToArray } from '../../store/helpers/ListHelper'
import { canUserCloseModuleQuality } from '../../store/helpers/RolesHelper';

/**
 * Container show Devolution
 */
class Show extends Component {

    /**
     * Create an instance Devolution
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            activeTab: 0,
            openAvailableOperationsDialog: false,
            openViewAutorizations: false,
            openViewToAuthorizer: false,
            openAutorizationReport: false,
            openViewToAuthorizerReport: false,
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let reworkToken = this.props.match.params.token;
        this.props.loadRework(reworkToken);
    }

    /**
     * componentDidUpdate
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof Index
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.rework !== null) {
            /// Get information full from rework
            this.props.loadSupplier(this.props.rework.supplier_token);
        }
    }

    /**
 * Action to set state to open of available operations dialog
 * @memberof 
 */
    setOpenDialog = (openAvailable) => {
        this.setState({
            openAvailableOperationsDialog: openAvailable,
        });
    }

    /**
     * Function on click open view autorizations
     */
    onClickOpenAutorizations() {
        this.setState({
            openViewAutorizations: true,
        })
    }

    /**
     *  Determines if requires a user authorization
     */
    requiresUserAuthorization(document) {
        let currentUser = this.props.currentUser;
        let authorizers = document ? document.authorizers ? document.authorizers : [] : [];
        let isRequiered = false;
        if (document.status === 1) {
            authorizers.map(authorizer => {
                let user = authorizer.possible_authorizes.find(posible => (posible.user_name) === currentUser.profile.preferred_username);
                if(user || isRequiered)
                    return isRequiered = true;
                else
                    return isRequiered = user ? true : false;
            })
        }
        return isRequiered;
    }

    /**
     * Function to open dialog authoriser
     */
    onOpenViewToAutorizer() {
        this.setState({
            openViewToAuthorizer: true,
        })
    }

    /**
     * Function to authorizer request rework
     * @param {*} tokenRequest 
     * @param {*} comment 
     */
    onAcceptedRequest(tokenRequest, comment) {
        this.props.authorizerRequestRework(tokenRequest, comment)
            .then(() => {
                this.setState({
                    openViewToAuthorizer: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
     * Function to rejected rquest rework
     * @param {*} tokenRequest 
     * @param {*} comment 
     */
    onRejectedRequest(tokenRequest, comment) {
        this.props.rejectedRequestRework(tokenRequest, comment)
            .then(() => {
                this.setState({
                    openViewToAuthorizer: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
     * Function to send notification from Rework Rework Executed
     */
    onClickCreateReworkReworkExecuted() {
        let reworkToken = this.props.match.params.token;
        this.props.history.push(`/reworks/reworkreport/${reworkToken}`)
    }

    /**
     * Function on click open view autorizations
     */
    onClickOpenAutorizationsReport() {
        this.setState({
            openAutorizationReport: true,
        })
    }

    /**
     * On close rework manually
     */
    onCloseReworkManually(){
        this.props.closedWorkflowManually(this.props.rework.token)
        .then(() => {
            this.props.loadRework(this.props.rework.token);
            this.setState({
                showToaster: true,
                toasterVariant: "success",
                toasterMessage: <Translate id="common.saveCorrectly" />,
            })
        }).catch(() => {
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="common.errorToSave" />,
                toasterVariant: "error",
            })
        });
    }

    /**
     * Render hystoryCard for Deviations
     *
     * @param {*} folio
     * @param {*} status
     * @returns
     * @memberof Show
     */
    renderAditionalRequestRework(document) {
        const { classes } = this.props;
        let isRequieredToAutorizer = this.requiresUserAuthorization(document);
        return (
            <Grid container spacing={24} >
                <Button variant="contained" onClick={this.onClickOpenAutorizations} color="primary" className={classes.button}>
                    <ListIcon className={classes.leftIcon} />
                    {<Translate id="reworks.viewAutorizers"/>}
                </Button>

                <div className={classes.grow}></div>
                {isRequieredToAutorizer && <Button variant="contained" onClick={this.onOpenViewToAutorizer} color="secondary" className={classes.button}>
                    <AceptedIcon className={classes.leftIcon} />
                    {<Translate id="reworks.autorizer"/>}
                </Button>}

                {/** Dialog view autoritations */}
                < DialogAutorizactions
                    open={this.state.openViewAutorizations}
                    onClose={() => { this.setState({ openViewAutorizations: false }) }}
                    currentUser={this.props.currentUser}
                    isSaving={this.props.isSaving}
                    document={document}
                />

                <RequestReworkAuthorizer
                    open={this.state.openViewToAuthorizer}
                    onClose={() => { this.setState({ openViewToAuthorizer: false }) }}
                    isSaving={this.props.isSaving}
                    document={document}
                    onAcceptedRequest={this.onAcceptedRequest}
                    onRejectedRequest={this.onRejectedRequest}
                />

            </Grid>
        )
    }

    /**
     * Function to open dialog authoriser
     */
    onOpenViewToAutorizerReport() {
        this.setState({
            openViewToAuthorizerReport: true,
        })
    }

    /**
     * Function to authorizer request rework
     * @param {*} tokenRequest 
     * @param {*} comment 
     */
    onAcceptedRequestReworkReport(tokenRequest, comment) {
        this.props.acceptedRequestReworkReport(tokenRequest, comment)
            .then(() => {
                this.setState({
                    openViewToAuthorizerReport: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
     * Function to rejected rquest rework
     * @param {*} tokenRequest 
     * @param {*} comment 
     */
    onRejectedRequestReport(tokenRequest, comment) {
        this.props.rejectedRequestReworkReport(tokenRequest, comment)
            .then(() => {
                this.setState({
                    openViewToAuthorizerReport: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
     * Action on onReworkCloseToScrap
     */
    onReworkCloseToScrap() {
        this.props.reworkCloseToScrap(this.props.rework.token)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }


    /**
      * Function to create devolution from rework
      */
    onClickReworkReturnToSupplier() {
        let reworkToken = this.props.match.params.token;
        this.props.history.push(`/reworks/createdevolution/${reworkToken}`)
    }


    /**
     * Render hystory for rework report
     * @param {*} document 
     */
    renderReworkReport(document) {
        const { classes } = this.props;
        let isRequieredToAutorizer = this.requiresUserAuthorization(document);
        let attachments = [];
        if (document.attachments) {
            document.attachments.map(attachment => {
                return (attachments.push(<span key={attachment.name}>
                    <Link component={'a'} target="_blank" href={`${process.env.REACT_APP_SIP_QUALITY_URL_FILES}/${attachment.relativePath}`}>
                        {attachment.name}
                    </Link><br />
                </span>))
            })
        }
        else return ("")

        return (<div>
            <List>
                <ListItem>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="reworks.qualityToReworker" />
                                </Typography>
                                {": "}<Badge color="secondary" className={classes.margin} badgeContent={this.props.rework.quantity_of_material} max={9999}></Badge>
                                {"  "}<Translate id="common.of" /> {this.props.rework.full_part_description}
                            </React.Fragment>
                        } />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="reworks.piecesOk" />
                                </Typography>
                                {": "}<Badge color="secondary" className={classes.margin} badgeContent={document.pieces_ok} max={9999}></Badge> {" | "}
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="reworks.piecesNotOk" />
                                </Typography>
                                {": "}<Badge color="secondary" className={classes.margin} badgeContent={document.pieces_not_ok} max={9999}></Badge>
                            </React.Fragment>
                        } />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="reworks.containmentMark" />
                                </Typography>
                                {": "} {document.containment_mark}
                            </React.Fragment>
                        } />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="reworks.commentBySorter" />
                                </Typography>
                                {": "} {document.comment_by_sorter}
                            </React.Fragment>
                        } />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="qualityfailurereport.jobAttachment" />
                                </Typography>
                                {": "} {attachments}
                            </React.Fragment>
                        } />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Grid container spacing={24} >
                                    <Button variant="contained" onClick={this.onClickOpenAutorizationsReport} color="primary" className={classes.button}>
                                        <ListIcon className={classes.leftIcon} />
                                        {<Translate id="reworks.viewAutorizers"/>}
                                    </Button>

                                    <div className={classes.grow}></div>
                                    {isRequieredToAutorizer && <Button variant="contained" onClick={this.onOpenViewToAutorizerReport} color="secondary" className={classes.button}>
                                        <AceptedIcon className={classes.leftIcon} />
                                        {<Translate id="reworks.autorizer"/>}
                                    </Button>}
                                </Grid>
                            </React.Fragment>
                        } />
                </ListItem>
            </List>

            {/** Dialog view autoritations */}
            < DialogoQuality
                open={this.state.openAutorizationReport}
                onClose={() => { this.setState({ openAutorizationReport: false }) }}
                currentUser={this.props.currentUser}
                isSaving={this.props.isSaving}
                document={document}
            />

            <RequestReworkAuthorizer
                open={this.state.openViewToAuthorizerReport}
                onClose={() => { this.setState({ openViewToAuthorizerReport: false }) }}
                isSaving={this.props.isSaving}
                document={document}
                onAcceptedRequest={this.onAcceptedRequestReworkReport}
                onRejectedRequest={this.onRejectedRequestReworkReport}
            />

        </div>
        )
    }

    /**
       * Render hystory section for all documents
       *
       * @returns
       * @memberof Show
       */
    renderHistory() {
        let documents = this.props.rework ? this.props.rework.documents ? this.props.rework.documents : [] : []
        return (
            documents.map(document => {
                let titleId;
                let aditionalData;
                if (document.document_type === NONCONFORMITYDOCUMENT_REQUEST_REWORK) {
                    titleId = 'reworks.titleRework';
                    aditionalData = this.renderAditionalRequestRework(document);
                }
                else if (document.document_type === REWORKREPORT) {
                    titleId = 'reworks.reportRework';
                    aditionalData = this.renderReworkReport(document);
                }
                return (
                    <HistoryCard
                        key={document.token}
                        title={titleId}
                        userCreator={document.create_by}
                        creationDate={document.creation_date_long}
                        status={document.status}
                        aditionalData={aditionalData}
                    />
                )

            })
        )
    }

       /**
     * Method to validate available options to show on DialogAvailableOperations 
     *
     * @param {*} availableOptions
     * @returns
     * @memberof Show
     */
    validateAvailableOptions(availableOptions) {
        let sorteadorNumber = this.props.rework && this.props.rework.sorter_number
        let currentUser = this.props.currentUser;
        let existAddSorter = this.props.rework && this.props.rework.get_available_operations.find(option => {
            return option === 402
        })

        if (existAddSorter) {
            availableOptions = availableOptions.filter(option => {
                if(option === 402 && sorteadorNumber && sorteadorNumber !== null && currentUser && currentUser.profile.preferred_username !== undefined)
                {
                    let userName = currentUser.profile.preferred_username;
                    if(!userName.includes(sorteadorNumber)){
                        return (option !== 402)
                    }
                    else
                        return option;
                }
                return (option !== 402)
            })
        }
        return availableOptions
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.rework !== null) {
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Divider />
                        <InformationPartial  {...this.props} />
                    </Grid>
                </Grid>
            )
        }
    }

    render() {
        let optonsAvalinable = this.props.rework ? this.props.rework.get_available_operations : [];
        optonsAvalinable = this.validateAvailableOptions(optonsAvalinable);
        return (
            <Card>
                {optonsAvalinable.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

                <LayoutHeaderWorkFlow {...this.props}
                    workFlow={this.props.rework}
                    title={<span><Translate id="reworks.rework"/> {this.props.rework ? this.props.rework.get_provenance: ""}</span>}
                />

                    {this.props.rework && !this.props.rework.is_closed && canUserCloseModuleQuality(this.props.userRolesByCompany) &&
                    <Fab variant="extended" color="primary" size="small" style={{ marginLeft: 16 }}
                        onClick={this.onCloseReworkManually} disabled={this.props.isSaving}>
                        <ReworkIcon /> <Typography style={{color:"white"}} variant="caption">
                            <Translate id="reworks.closeRework" />
                        </Typography>
                    </Fab>}

                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {this.renderHistory()}

                <DialogAvailableOperations
                    {...this.props}
                    options={optonsAvalinable}
                    openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
                    setOpenDialog={this.setOpenDialog}
                    onClickCreateReworkReworkExecuted={this.onClickCreateReworkReworkExecuted}
                    onClickReworkCloseToScrap={this.onReworkCloseToScrap}
                    onClickReworkReturnToSupplier={this.onClickReworkReturnToSupplier}
                />
            </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let selectedCompany = state.profile.get("selectedCompany");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        currentUser: user,
        isLoading: state.reworks.get('isLoading'),
        rework: state.reworks.get("rework") ? state.reworks.get("rework").toJS() : null,
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        company: selectedCompany ? selectedCompany.toJS() : null,
        isSaving: state.reworks.get('isSaving'),
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadRework: (token) => {
            return dispatch(loadReworkFromBackEnd(token));
        },
        loadSupplier: (token) => {
            return (dispatch(loadSupplier(token)))
        },
        authorizerRequestRework: (tokenRequest, comment) => {
            return (dispatch(authorizerRequestRework(tokenRequest, comment)))
        },
        rejectedRequestRework: (tokenRequest, comment) => {
            return (dispatch(rejectedRequestRework(tokenRequest, comment)))
        },
        acceptedRequestReworkReport: (tokenRequest, comment) => {
            return (dispatch(acceptedRequestReworkReport(tokenRequest, comment)))
        },
        rejectedRequestReworkReport: (tokenRequest, comment) => {
            return (dispatch(rejectedRequestReworkReport(tokenRequest, comment)))
        },
        reworkCloseToScrap: (token) => {
            return dispatch(reworkCloseToScrap(token))
        },
        closedWorkflowManually:(token)=>{
            return dispatch(closedWorkflowManuallyFromBackend(token))
        }
    }
}

const styles = theme => ({
    actionsToolbar: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    margin: {
        margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit * 1,
    },
    leftIcon: {
        marginRight: theme.spacing.unit * 1,
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));