
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import {Typography, Divider, Paper, List, ListItem,
    Tooltip,  ListItemText} from "@material-ui/core";
import EditIcon from '@material-ui/icons/EditOutlined';

import DialogRequieredDate from './DialogRequieredDate'
import { canUpdateMaquilaOrder} from '../../../store/helpers/RolesHelper';

/**
 * Component ot render Information partialr maquila order
 */
export default class GeneralInformation extends Component {

    /**
     * Create an instance maquila order
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openUpdateOrder: false,
            order: {
                token: "",
                required_plant_date: "",
                required_quality: false,
                required_molde: false, 
            }
        }
    }

    onUpdateMaterialClick(order) {
        this.setState({
            openUpdateOrder: true,
            order:order
        })
    }

    updateProperty(property, value) {
        let itemUpdate = { ...this.state.order };
        itemUpdate[property] = value;
        this.setState({
            order: itemUpdate
        })
    }

    onUpdateMaquilaOrder() {
        let orderUpdate = { ...this.state.order };
        if(this.props.onUpdateMaquilaOrder){
            this.props.onUpdateMaquilaOrder(orderUpdate)
            .then(() => {
                this.setState({ openUpdateOrder: false})
            })
        }
    }



    /**
     * getInformationOrder
     * @param {*} propertyData 
     * @param {*} textFieldTranslateId 
     */
    getInformationOrder(propertyData, textFieldTranslateId){
        return(
            <ListItem divider>
            <ListItemText
                secondary={
                    <React.Fragment>
                        <Typography component="span" variant="body2" className={this.props.classes.inline} color="textPrimary">
                        <Translate id={textFieldTranslateId} />
                        </Typography>
                        {": "}{propertyData} {" "}
                    </React.Fragment>
                } />
        </ListItem>
        )
    }

    /**
     * Render
     */
    render() {
        const { classes, maquilaOrder } = this.props;
        return (
            <Paper><Divider />
                <List>
                    {this.getInformationOrder(maquilaOrder.purchase_order, "maquilaOrders.maquilaOrder")}
                    {this.getInformationOrder(maquilaOrder.full_supplier, "common.supplier")}
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="maquilaOrders.requieredQuality" /> 
                                    </Typography>
                                    {": "}{maquilaOrder.required_quality ? "Si" : "No"} {" "}
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        {" - "}<Translate id="maquilaOrders.requieredMolde" /> 
                                    </Typography>
                                    {": "}{maquilaOrder.required_molde ? "Si" : "No"} {" "}
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        {" - "}<Translate id="maquilaOrders.requieredTool" /> 
                                    </Typography>
                                    {": "}{maquilaOrder.required_tooling ? "Si" : "No"} {" "}
                                    
                                    {(canUpdateMaquilaOrder(this.props.userRolesByCompany) && maquilaOrder.can_be_edit) &&<Tooltip title={"Editar"}>
                                        <EditIcon onClick={() => this.onUpdateMaterialClick(maquilaOrder)} color="primary" style={{cursor: "pointer", marginLeft:"5px"}}  /> 
                                    </Tooltip>}
                                </React.Fragment>
                            } />
                    </ListItem>
                    {maquilaOrder.closed_date_time && <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        {"Fecha de cierre"}
                                        {": "}{maquilaOrder.closed_date_time && maquilaOrder.closed_date_time}
                                    </Typography>
                                </React.Fragment>
                            } />
                    </ListItem>}
                </List>

                {/** Dialog to DialogEditDates */}
                <DialogRequieredDate
                    open={this.state.openUpdateOrder}
                    maquilaOrder={this.state.order}
                    onClose={() => { this.setState({ openUpdateOrder: false }) }}
                    updateProperty={this.updateProperty}
                    onSaveItem={this.onUpdateMaquilaOrder}
                    isSaving={this.props.isSavingMaquila}
                />
            </Paper>
        );
    }
}

/**
 * deliveryOrder
 */
GeneralInformation.propTypes = {
    maquilaOrder: PropTypes.object.isRequired
}