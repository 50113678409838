
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import { Translate } from "react-localize-redux";
import moment from 'moment'

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AcceptedIcon from '@material-ui/icons/ThumbUp';
import RejectedIcon from '@material-ui/icons/ThumbDown';

/** section import components */
import DataTable from '../../common/DataTable';
import OptionsMenu from './../../common/OptionsMenu';
import DatePicker from '../../common/DateTimePicker/DatePicker';
import HtmlEditor from '../../common/HtmlEditor';

import { APPROVAL_ACEPTED, APPROVAL_REJECTED, APPROVAL_UPDATED } from '../../../store/helpers/ResourcesHelper';
import { COLOR_STATUS_ACEPTED, COLOR_STATUS_REJECTED, COLOR_STATUS_INFO } from '../../../store/helpers/StatusColorConstants';
import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper';

/**
 * Component ot render Information partial shipping Order
 */
export default class InformationPartial extends Component {

    /**
     * Create an instance shipping Order
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            dateTimeEta: this.props.shippingOrder.estimated_arrival_date_time_ETA ? this.props.shippingOrder.estimated_arrival_date_time_ETA : moment(),
            observationsSupplier: "",
            itemToken: '',
        }
    }

    /**
     * Function to update observations Supplier
     */
    updatePropertyObservationsSupplier(value) {
        this.setState({
            observationsSupplier: value
        })
        let response = this.props.shippingOrder.response;
        this.props.updateShippingResponse(response.token, value)
    }

    /**
     * Function to update observations Supplier
     */
    updateShippingOrderEta(dateTimeEta) {
        this.setState({
            dateTimeEta
        })
        let dateTimeEtaValue = dateTimeEta.format("DD/MM/YYYY");
        this.props.updateShippingOrderEta(this.props.shippingOrder.token, dateTimeEtaValue)
    }


    /**
     * Handle state to set token of item to show options menu list
     *
     * @param {*} itemToken
     * @memberof ActionsList
     */
    handleClickOptionsMenu(itemToken) {
        this.setState({
            itemToken,
        });
    };

    /**
    * Handle dialog status to show or hide 
    *
    * @param {*} dialogId
    * @param {*} isReject
    * @memberof ActionsList
    */
    handleOpenItemDialog(dialogId, isReject = false) {
        this.setState({
            [dialogId]: !this.state[dialogId],
            isReject
        }, () => {
            if (!this.state[dialogId])
                this.handleCloseOptionsMenu()
        });
    }


    /**
     * Handle state to show or hide menu options list
     *
     * @memberof ActionsList
     */
    handleCloseOptionsMenu() {
        if (!this.state.openModalAuthorization) {
            this.setState(state => ({
                itemToken: ''
            }));
        }
    };

    /**
     * Action to rejected item  APPROVAL_REJECTED or APPROVAL_ACEPTED
     * @param {*} itemToken 
     */
    acceptedPriceInItemDetailBySupplier(itemToken, status) {
        let shippingOrderToken = this.props.match.params.token;
        this.props.acceptedPriceInItemDetailBySupplier(itemToken, status, shippingOrderToken)
        this.setState({
            itemToken: ''
        })
    }

    /**
  * Method to render OptionsMenu
  *
  * @param {*} item
  * @returns
  * @memberof ActionsList
  */
    renderOptionsMenu(item, optionToShow) {
        var options = [];
        let isExternalUser = (IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.props.shippingOrder.can_be_update_supplier)
        if (isExternalUser) {
            if (optionToShow === 1)
                options.push({
                    itemClick: () => this.acceptedPriceInItemDetailBySupplier(item.token, APPROVAL_ACEPTED),
                    tooltipTranslation: <Translate id="common.accept" />,
                    menuItemIcon: <AcceptedIcon color="secondary" className={this.props.classes.icon} />
                });
            if (optionToShow === 2)
                options.push({
                    itemClick: () => this.acceptedPriceInItemDetailBySupplier(item.token, APPROVAL_REJECTED),
                    tooltipTranslation: <Translate id="common.reject" />,
                    menuItemIcon: <RejectedIcon color="error" className={this.props.classes.icon} />
                });
        }
        return (<OptionsMenu
            itemToken={item.token}
            handleClickOptions={this.handleClickOptionsMenu}
            open={this.state.itemToken === item.token}
            handleClickAway={this.handleCloseOptionsMenu}
            options={options}
            xsSize
        />)
    }


    /**
     * method to custom render cell on table
     *
     * @param {*} dataSource
     * @param {*} item
     * @returns
     * @memberof ActionsList
     */
    onRenderCellItem(dataSource, item) {
        if (dataSource === "options") //Validar opción para supplier
            return (<React.Fragment>
                {this.renderOptionsMenu(item, 1)}
                {this.renderOptionsMenu(item, 2)}
            </React.Fragment>)
    }

    /**
 * Set Custom background color foreach action
 *
 * @memberof ActionsList
 */
    setCustomColorRow() {
        (this.props.shippingOrder && this.props.shippingOrder.details) &&
            this.props.shippingOrder.details.map((item) => {
                var customColor;
                if (item.status === APPROVAL_ACEPTED)
                    customColor = COLOR_STATUS_ACEPTED
                if (item.status === APPROVAL_REJECTED)
                    customColor = COLOR_STATUS_REJECTED
                if (item.status === APPROVAL_UPDATED)
                    customColor = COLOR_STATUS_INFO
                return item.custom_color = customColor;
            });
    }

    /**
     * Render
     */
    render() {
        const { classes } = this.props;
        this.setCustomColorRow();
        let isExternalUser = (IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.props.shippingOrder.can_be_update_supplier)
        let TableContainer = TableConfiguration;
        if (isExternalUser) {
            TableContainer = TableConfigurationEdit;
        }
        return (
            <Paper> <Divider />
                <List>
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="shippingOrder.incoterm" />
                                    </Typography>
                                    {": "}{this.props.shippingOrder.incoterm}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="shippingOrder.currency" />
                                    </Typography>
                                    {": "}{this.props.shippingOrder.currency_description}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="shippingOrder.materialDdestination" />
                                    </Typography>
                                    {": "}{this.props.shippingOrder.use_of_materials_description}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                </List>

                <List>
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <strong> <Translate id="shippingOrder.details" /></strong>
                                    </Typography>
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                </List>

                <DataTable
                    data={this.props.shippingOrder.details}
                    configuration={TableContainer}
                    onRenderCellItem={this.onRenderCellItem}
                />

                {!this.props.shippingOrder.can_be_update_supplier && !isExternalUser && <List>
                    <ListItem>
                        <ListItemText
                            secondary={<strong><Translate id="shippingOrder.messageMustValidateAllPrices" /></strong>}
                        />
                    </ListItem>
                    <Divider />
                </List>}
                <List>
                    <ListItem>
                        <ListItemText primary={<Typography variant="body2" gutterBottom className={classes.inline} color="textPrimary">
                            <Translate id="shippingOrder.estimatedTimeOfArrival" /></Typography>}
                            secondary={
                                <React.Fragment>
                                    {isExternalUser && <DatePicker
                                        onChangeRange={this.updateShippingOrderEta}
                                        startDate={moment(this.state.dateTimeEta, 'DD/MM/YYYY')}
                                        minDate={moment()}
                                    />}
                                    {!isExternalUser && <strong>{this.props.shippingOrder.estimated_arrival_ETA_long}</strong>}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <HtmlEditor
                            id="observations_coordinator"
                            label={"shippingOrder.commentsFromApplicant"}
                            labelVariant={"body2"}
                            isDisabled
                            initialValue={this.props.shippingOrder.observations_coordinator}
                        />
                    </ListItem>
                    {this.props.shippingOrder.response && <ListItem>
                        <HtmlEditor
                            id="observations"
                            label={"shippingOrder.suppliersComments"}
                            labelVariant={"body2"}
                            isDisabled={!isExternalUser}
                            initialValue={this.props.shippingOrder.response.observations}
                            onChange={(data) => this.updatePropertyObservationsSupplier(data.value)}
                        />
                    </ListItem>}
                </List>

            </Paper>
        );
    }
}

const TableConfigurationEdit = {
    columns: [
        {
            header: <Translate id="shippingOrder.purchaseOrder" />,
            dataSource: 'purchase_order',
        },
        {
            header: <Translate id="shippingOrder.codePartNumber" />,
            dataSource: 'part_number_code',
        }, {
            header: <Translate id="shippingOrder.productoDescription" />,
            dataSource: 'part_number_description',
        }, {
            header: <Translate id="shippingOrder.quantityofMaterial" />,
            dataSource: 'quantity_of_material',
        }, {
            header: <Translate id="shippingOrder.productoMeasurementUnit" />,
            dataSource: 'part_number_um',
        }, {
            header: <Translate id="shippingOrder.unitPrice" />,
            dataSource: 'price',
        }, {
            header: <Translate id="shippingOrder.validatePrice" />,
            dataSource: 'options',
        }]
}

const TableConfiguration = {
    columns: [
        {
            header: <Translate id="shippingOrder.purchaseOrder" />,
            dataSource: 'purchase_order',
        },
        {
            header: <Translate id="shippingOrder.codePartNumber" />,
            dataSource: 'part_number_code',
        }, {
            header: <Translate id="shippingOrder.productoDescription" />,
            dataSource: 'part_number_description',
        }, {
            header: <Translate id="shippingOrder.quantityofMaterial" />,
            dataSource: 'quantity_of_material',
        }, {
            header: <Translate id="shippingOrder.productoMeasurementUnit" />,
            dataSource: 'part_number_um',
        }, {
            header: <Translate id="shippingOrder.unitPrice" />,
            dataSource: 'price',
        }]
}

/**
 * shippingOrder
 */
InformationPartial.propTypes = {
    shippingOrder: PropTypes.object.isRequired
}