import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'auto-bind'
import $ from "jquery";

/** Components imports section */
import Chart from 'chart.js';

/** Material UI imports section */
import Card from '@material-ui/core/Card';
import { YEAR_MONTHS } from '../../store/helpers/ResourcesHelper';

var chart;
/**
 * Class component of Bars chart
 *
 * @class BarChart
 * @extends {Component}
 */
class BarChart extends Component {

    /**
     *Creates an instance of BarChart.
     * @param {*} props
     * @memberof BarChart
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            canvasHeight: this.props.height == null ? 250 : this.props.height,
            canvasWidth: this.props.width == null ? 800 : this.props.width
        }
    }

    getLegendClicked(e, legendItem) {
        //Default click changes
        var index = legendItem.datasetIndex;
        var ci = chart;
        var meta = ci.getDatasetMeta(index);
        meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
        ci.update();

        //After default click changes then call the handler
        if (this.props.handlerLegendClick)
            this.props.handlerLegendClick(ci.legend.legendItems);
    }

    /**
     * React life-cycle method
     * to mount Chart component 
     *
     * @memberof BarChart
     */
    componentDidMount() {
        var ctx = $("#bar-chart-container");
        let dataSets = this.props.dataSets ? this.props.dataSets : mockDataSets
        let labelsSections = this.props.labelsSections ? this.props.labelsSections : YEAR_MONTHS

        let chartTitle = this.props.chartTitle ? this.props.chartTitle : mockChartTitle
        let showtitle = this.props.showtitle ? this.props.showtitle : true
        let positionTitle = this.props.positionTitle ? this.props.positionTitle : 'left'

        let showLegend = this.props.showLegend ? this.props.showLegend : true
        let positionLegend = this.props.positionLegend ? this.props.positionLegend : 'top'

        let yAxisTicksMin = this.props.yAxisTicksMin ? this.props.yAxisTicksMin : 0
        let yAxisTicksMax = this.props.yAxisTicksMax ? this.props.yAxisTicksMax : 100
        let yAxisTicksStepSize = this.props.yAxisTicksStepSize ? this.props.yAxisTicksStepSize : 25

        chart = new Chart(ctx, {
            type: 'bar',
            data: {
                datasets: dataSets.map(dataSet => {
                    return ({
                        data: dataSet.data,
                        label: dataSet.labelGroup,
                        backgroundColor: dataSet.backgroundColors,
                        borderColor: "#FFFFFF",
                        borderWidth: 1,
                        fill: dataSet.fill ? dataSet.fill : true,
                        yAxisID: dataSet.yAxisID ? dataSet.yAxisID : "y-axis",
                        hidden: dataSet.hiddenLegend ? dataSet.hiddenLegend : false // To set default striked legend label
                    })
                }),
                labels: labelsSections
            },
            options: {
                title: {
                    display: showtitle,
                    text: chartTitle,
                    position: positionTitle
                },
                legend: {
                    display: showLegend,
                    position: positionLegend,
                    fullWidth: true,
                    onClick: this.getLegendClicked,
                    labels: {
                        boxWidth: 0 //To remove Box side on label in legend
                    }
                },
                scales: {
                    xAxes: [{
                        id: "x-axis",
                        barPercentage: .5,
                        barThickness: 17,
                        maxBarThickness: 40,
                        minBarLength: 0,
                        gridLines: {
                            offsetGridLines: true
                        }
                    }],
                    yAxes: [{
                        id: "y-axis",
                        type: "linear",
                        position: "left",
                        ticks: {
                            beginAtZero: true,
                            min: yAxisTicksMin,
                            stepSize: yAxisTicksStepSize,
                            max: yAxisTicksMax
                        }
                    }]
                }
            }
        });
    }

    render() {
        return (
            <Card style={{ margin: '1rem', padding: '1rem' }}>
                <div className="bar-chart-container">
                    <canvas id="bar-chart-container" height={this.state.canvasHeight} width={this.state.canvasWidth}></canvas>
                </div>
            </Card>
        )
    }
}

/** Mock data */
const mockChartTitle = "Calificaciones"
const data = [12, 25, 21, 35, 24, 76, 74, 2, 75, 9, 34, 88];
const labelGroup = "2018"
const backgroundColors = ["#ff6666", "#ff6676", "#cc6667", "#ff7666", "#fc6666", "#ff6776", "#cf6666", "#ff6666", "#ff6676", "#cc6667", "#ff7666", "#fc6666"]

const data1 = [74, 2, 75, 9, 34, 88, 12, 25, 21, 35, 24, 76,];
const labelGroup1 = "2019"
const backgroundColors1 = ["#006666", "#006676", "#006667", "#007666", "#006666", "#006776", "#006666", "#006666", "#006676", "#006667", "#007666", "#006666"]
let mockDataSets = [
    {
        data: data,
        labelGroup: labelGroup,
        backgroundColors: backgroundColors
    },
    {
        data: data1,
        labelGroup: labelGroup1,
        backgroundColors: backgroundColors1
    }
]

BarChart.propTypes = {
    /** Data sets proptypes section */
    dataSets: PropTypes.arrayOf(PropTypes.shape({
        //Array of values to set bars height
        data: PropTypes.array.isRequired,

        //To identify data group, this lable will be show in legend section
        labelGroup: PropTypes.string.isRequired,

        //backgroundColor default is gray with low opacity
        // if pass single string color apply to all bars
        // if pass array its length should be equal to data array,
        // otherwise data without color in array will be default color
        backgroundColors: PropTypes.oneOfType(
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string)
        )
    })).isRequired,

    //Array of names by section in chart, to show in X axe, length must be equal to data length 
    labelsSections: PropTypes.arrayOf(PropTypes.string.isRequired),

    /** Title proptypes section*/
    chartTitle: PropTypes.string, //String title of the chart
    showtitle: PropTypes.bool, //Boolean value to show or hide Title, this prop by default is true if has chartTitle
    positionTitle: PropTypes.string, //String value to position title (top, bottom, left, right)

    /** Legend proptypes section  */
    showLegend: PropTypes.bool, //Booleand value to show or hide legend
    positionLegend: PropTypes.string, //String value to set position of legend (top, bottom, left, right)

    /** Axes configuration proptypes section */
    yAxisTicksMin: PropTypes.number, //Numeric value to determine lowest value in "Y axe"
    yAxisTicksMax: PropTypes.number, //Numeric value to determine highest value in "Y axe"
    yAxisTicksStepSize: PropTypes.number, //Numeric value to determine hops in "Y axe"

    handlerLegendClick: PropTypes.func, //Function handler to get legend item clicked
}

export default BarChart;
