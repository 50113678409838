
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { Grid, Paper, Divider, InputAdornment, Fab, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DataTable from '../../common/DataTable';

/**
 * Dialog to add scale data to quote item
 */
class ScaleQuoteDialog extends Component {

    /**
     *Creates an instance of DialogActions.
    * @param {*} props
    * @memberof DialogActions
    */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            scales: [],
            scaleToAdd: {},
        }
    }

    /**
     * React life cycle 
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof ScaleQuoteDialog
     */
    componentDidUpdate(prevProps, prevState) {
        //Se verifica si esta establecido un token de item, entonces se establece la informacion de scale de ese item
        let index = this.props.detailsList.findIndex((item) => {
            return item.token === this.props.quoteItemToken
        });
        if (index >= 0) {
            if (this.state.scales !== this.props.detailsList[index].scales) {
                this.setState({
                    scales: this.props.detailsList[index].scales
                })
            }
        }
    }

    /**
     * Function to update scale property of text field in state
     *
     * @param {*} data
     * @memberof ScaleQuoteDialog
     */
    updateProperty(data) {
        this.setState({
            scaleToAdd: {
                ...this.state.scaleToAdd,
                [data.id]: data.value,
            }
        })
    }

    /**
     * On save scale quote, call saveScaleItem action from props
     *
     * @memberof ScaleQuoteDialog
     */
    addScale() {
        if (this.state.scaleToAdd) {
            this.setState({
                scales: this.state.scales.concat([this.state.scaleToAdd]),
                scaleToAdd: {},
            },
                () => { this.props.saveScaleItem(this.props.quoteItemToken, this.state.scales) }
            )
        }
    }

    /**
     * Handle  Close or cancel event
     *
     * @memberof ScaleQuoteDialog
     */
    handleCloseCancel() {
        this.setState({ scaleToAdd: {}, scales: [] }, () => this.props.onClose());
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof ScaleQuoteDialog
     */
    isValidToAdd() {
        if (this.state.scaleToAdd === null || this.state.scaleToAdd === undefined || Object.keys(this.state.scaleToAdd).length <= 0)
            return false
        if ((this.state.scaleToAdd.lower_limit && this.state.scaleToAdd.lower_limit !== null && this.state.scaleToAdd.lower_limit !== '')
            && (this.state.scaleToAdd.upper_limit && this.state.scaleToAdd.upper_limit !== null && this.state.scaleToAdd.upper_limit !== '')
            && (this.state.scaleToAdd.cost && this.state.scaleToAdd.cost !== null && this.state.scaleToAdd.cost !== '')) {
            return true;
        }
        return false
    }


    /**
    * On render cell item custom
    * @param {*} dataSource 
    * @param {*} item 
    */
    onRenderCellItem(dataSource, item) {
        if (dataSource === 'scale') {
            return `${item.lower_limit} - ${item.upper_limit}`
        }
        if (this.props.isVisible && dataSource === "options") {
            return (<IconButton color="secondary" onClick={() => { this.props.deleteScaleItem(item.token) }} ><DeleteIcon /></IconButton>)
        }
    }

    /**
     * Method to render TextField by params 
     *
     * @param {*} textFieldId
     * @param {*} textFieldTranslateId
     * @param {*} required
     * @param {*} type
     * @param {*} withStartAdornment
     * @param {*} withEndAdornment
     * @returns
     * @memberof ScaleQuoteDialog
     */
    renderOnlyTextField(textFieldId, textFieldTranslateId, required, type, withStartAdornment, withEndAdornment) {
        let inputPropsAdornment = {}
        if (withStartAdornment) {
            inputPropsAdornment.startAdornment = <InputAdornment position="start">{withStartAdornment}</InputAdornment>
        }
        if (withEndAdornment) {
            inputPropsAdornment.endAdornment = <InputAdornment position="start">{withEndAdornment}</InputAdornment>
        }
        return (
            <TextField
                id={textFieldId}
                autoFocus={textFieldId === 'lower_limit'}
                label={<Translate id={textFieldTranslateId}/>}
                value={this.state.scaleToAdd ? this.state.scaleToAdd[textFieldId] ? this.state.scaleToAdd[textFieldId] : '' : ''}
                margin="normal"
                type={type ? type : "number"}
                fullWidth
                required={required ? required : false}
                onBlur={(event) => this.updateProperty(event.target)}
                onChange={(event) => this.updateProperty(event.target)}
                InputProps={inputPropsAdornment}
            />
        )
    }

    /**
     * Method to render grid item
     *
     * @param {*} content
     * @param {number} [xs=12]
     * @returns
     * @memberof DetailsCard
     */
    renderGridProperty(content, xs = 12) {
        return (
            <Grid item xs={xs}>
                <Paper className={this.props.classes.paper} >
                    {content}
                </Paper>
            </Grid>
        );
    }


    /**
     * Dialog to update information
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id="quotes.quantityScales" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <div>
                        <DialogActions
                            classes={classes}
                            onCancel={() => this.handleCloseCancel()}
                            onClose={() => this.handleCloseCancel()}
                            isSaving={this.props.isSaving}
                        />
                    </div>
                }>
                <form >
                    <Grid container spacing={24} >
                        {this.props.isVisible &&
                            <Grid item xs={12}>
                                <Grid container spacing={24} >
                                    {this.renderGridProperty(this.renderOnlyTextField('lower_limit', 'quotes.lowerLimit', true), 4)}
                                    {this.renderGridProperty(this.renderOnlyTextField('upper_limit', 'quotes.upperLimit', true), 4)}
                                    {this.renderGridProperty(this.renderOnlyTextField('cost', 'quotes.cost', true, 'number', '$', this.props.currency), 4)}
                                </Grid>
                            </Grid>}
                        {this.props.isVisible &&
                            <Grid item xs={12}>
                                <Grid container spacing={24} >
                                    {this.renderGridProperty(<Typography variant="caption" color='primary'><Translate id="quotes.scaleNote" /></Typography>, 8)}
                                    {this.renderGridProperty(
                                        <div style={{ textAlign: 'center' }}>
                                            <Fab color="primary" aria-label="Add" variant="extended" size="small" className={classes.fab} disabled={!this.isValidToAdd()} onClick={() => this.addScale()}>
                                                <Translate id="common.add" />
                                            </Fab>
                                            {!this.isValidToAdd() &&
                                                <Typography variant="caption" color='error'>
                                                    {<Translate id="common.requiredFields" />}
                                                </Typography>
                                            }
                                        </div>
                                        , 4)}
                                </Grid>
                            </Grid>}
                        <Grid item xs={12}>
                            <Divider />
                            <Grid container spacing={24} >
                                {this.renderGridProperty(
                                    <DataTable
                                        data={this.state.scales}
                                        configuration={this.props.tableScalesConfiguration}
                                        onRenderCellItem={this.onRenderCellItem}
                                    />)}
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>
        )
    }
}

export default withRouter(ScaleQuoteDialog);