import React from 'react'
import { Translate } from 'react-localize-redux'
/* Common UI import section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import SelectorComponent from '../../common/SelectorComponent'
/* Material UI import section */
import { Button, Typography, withStyles } from '@material-ui/core'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import CloseIcon from '@material-ui/icons/Cancel'

const AssignSupplierTransportTypePanel = (props) => {
	const { onClose, classes, onSave, setSupplier, supplier, optionsSupplie, isLoading } = props

	const canBeSaved = () => {
		if (supplier === null) return false
		return true
	}
	return (
		<LateralPanel onClose={onClose}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id='carrierTenders.deals.dealsTransport' />}
				onCloseButtonClick={onClose}
				icon={<BubbleChartIcon color='secondary' />}
			/>
			<div className={classes.bodyContainer}>
				<SelectorComponent
					title={'Selecciona el proveedor'}
					placeholder={'Selecciona el proveedor'}
					options={optionsSupplie}
					onChange={(option) => setSupplier(option)}
					value={supplier}
				/>
				{supplier && (
					<div className={classes.typeTranportContainer}>
						<Typography variant='subtitle2' noWrap className={classes.codeSupplier}>
							{supplier.label}
						</Typography>

						<div>
							<CloseIcon
								color='primary'
								className={classes.closeIcon}
								onClick={() => setSupplier(null)}
							/>
						</div>
					</div>
				)}
			</div>

			<Button
				data-tut='reactour-save'
				data-cy='btn-save'
				type='button'
				color='primary'
				variant='outlined'
				className={classes.btnCreate}
				onClick={onSave}
				disabled={!canBeSaved() || isLoading}
			>
				{<Translate id='carrierTenders.configuration.addCarrier' />}
			</Button>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	bodyContainer: {
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
	},

	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	typeTranportContainer: {
		height: ' 56px',
		border: '1px solid #BDBDBD',
		display: 'Grid',
		gridTemplateColumns: '8fr 1fr',
		alignItems: 'center',
		marginTop: '10px',
	},
	displayFlex: {
		display: 'flex',
	},
	displayFlexStart: {
		display: 'flex',
		justifyContent: 'flex-start',
		backgroundColor: 'red',
	},
	prospectSupplierInconStyles: {
		marginLeft: '10px',
		color: '#BDBDBD',
	},
	closeIcon: {
		margin: '-20px 10px',
	},
	codeSupplier: {
		margin: '0px 0px 0px 10px',
	},
})

export default withStyles(styles, { withTheme: true })(AssignSupplierTransportTypePanel)
