
import React, { Component } from 'react';

/** Material-UI imports section */
import {Translate} from "react-localize-redux";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';

import LoadingPage from '../../common/LoadingPage';
import DataTable from '../../common/DataTable';

/**
 * Component to show list back data
 */
export default class BankData extends Component{

          /**
     * Get the card content
     *
     * @memberof Index
     */
    getCardContent() {
        if (this.props.isLoadingBanck === true) {
            return (
                <LoadingPage />
            )
        }
        else if(this.props.bancks === null){
            return(
                <div className="loader-container">            
                    <Typography variant="subtitle1" gutterBottom> <Translate id="common.notInformation"/></Typography>
                </div>
            )
        } else {
            return (
                <DataTable
                    data = {this.props.bancks}
                    configuration = {TableConfiguration}
                />
            )
        }
    }

    render(){
        return(
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            B
                        </Avatar>
                    }
                    title={<Typography variant="button"><Translate id="suppliers.dataBanck"/> </Typography>}
                />
                <CardContent>
                    {this.getCardContent()}                
                </CardContent>
            </Card>
        )
    }
} 

const TableConfiguration = {
    columns: [{
        header: <Translate id="suppliers.banck"/>,
        dataSource: 'bank_name'
    },{
        header: <Translate id="suppliers.accountNumber"/>,
        dataSource: 'account_number'
    },{
        header: <Translate id="suppliers.titleAccount"/>,
        dataSource: 'account_holder'
    },{
        header: <Translate id="suppliers.reference"/>,
        dataSource: 'reference'
    },{
        header: <Translate id="suppliers.country"/>,
        dataSource: 'bank_country'
    },{
        header: <Translate id="suppliers.paymentVia"/>,
        dataSource: 'way_of_payment'
    }]
}