//Consts of type of list in Service/Projects
export const EMPLOYEES_TYPE = "employeesType";
export const MATERIALS_TYPE = "materialsType";
export const CONTACTS_TYPE = "contactsType";
export const RATINGS_TYPE = "ratingsType";

/** Dictionary to get name of list by type  of each list in Service/Project */
export const NameListByType = {};
NameListByType[EMPLOYEES_TYPE] = "employees";
NameListByType[MATERIALS_TYPE] = "materials";
NameListByType[CONTACTS_TYPE] = "contacts";
NameListByType[RATINGS_TYPE] = "ratings";

/**
 * Change type of service/project from int to id translation 
 * @param {*} typeValue 
 * @returns 
 */
export function changeTypeServiceToIdTranslate(typeValue) {
    switch (typeValue) {
        case 1:
            return 'contractorsModule.purchaseOrder'
        case 2:
            return 'contractorsModule.contract'
        case 3:
            return 'contractorsModule.surveyQuotation'
        default:
            return 'contractorsModule.noType'
    }
}