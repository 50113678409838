///Import react section
import { useSelector, useDispatch } from 'react-redux';
/**Import actions sections */
import { setToasterOptions } from '../../../store/indirectqualifications/IndirectqualificationsActions';

import {
	addOrRemoveDocumentInExpedientSupplier,
	setStatusExpedientSupplier,
	loadExpedientSupplier,
	updateColumnInExpedientSupplier,
	removeExpedientSupplier
} from '../../../store/expedientSupplier/ExpedientSupplierAction';

import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';

export const useExpedientSupplierCustomHookShow = () => {
	const user = useSelector((state) => state.oidc.user);
	const isSaving = useSelector((state) => state.expedientSuppliers.get('isSaving'));

	/**Render hooks section */
	const dispatch = useDispatch();

	const onCloseToaster = () => {
		dispatch(
			setToasterOptions({
				showToaster: false,
				toasterMessage: '',
				toasterVariant: ''
			})
		);
	};

	/**
	 * Method to show error in import suppliers
	 * @param {*} error
	 */
	const getErrorImportStatus = (response) => {
		if (!IsNullOrEmpty(response) && typeof response === 'string') {
			if (response.includes('expedientSupplier.errors.invalidDocumentAlreadyExistInClassification'))
				return 'expedientSupplier.errors.invalidDocumentAlreadyExistInClassification';
			else if (response.includes('expedientSupplier.errors.invalidDocumentNotExtistToRemove'))
				return 'expedientSupplier.errors.invalidDocumentNotExtistToRemove';
			else if (response.includes('expedientSupplier.errors.invalidNomenclatureAlreadyInTemplate'))
				return 'expedientSupplier.errors.invalidNomenclatureAlreadyInTemplate';
			else if (response.includes('expedientSupplier.errors.invalidContaineClassificationsList'))
				return 'expedientSupplier.errors.invalidContaineClassificationsList';
			else if (response.includes('expedientSupplier.errorNotExistdDocumentsToSupplier'))
				return 'expedientSupplier.errorNotExistdDocumentsToSupplier';
			else return 'common.errorToSave';
		} else {
			return 'common.errorToSave';
		}
	};

	const onAddOrRemoveDocumentInExpedientSupplier = (documentToken, file) => {
		return dispatch(addOrRemoveDocumentInExpedientSupplier(documentToken, file))
			.then(() => {
				return Promise.resolve(true);
			})
			.catch((result) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorImportStatus(result),
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onAddOrRemoveDocumentColumnInExpedientSupplier = (nomenclatureName, isChecked, expedientToken) => {
		return dispatch(updateColumnInExpedientSupplier(expedientToken, nomenclatureName, isChecked))
			.then(() => {
				return Promise.resolve(true);
			})
			.catch((result) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorImportStatus(result),
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onSetStatusExpedientSupplier = (expedient, status, comments) => {
		return dispatch(setStatusExpedientSupplier(expedient, status, comments))
			.then(() => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.addCorrectly'
					})
				);
				return Promise.resolve(true);
			})
			.catch((error_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorImportStatus(error_),
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onRemoveExpedientSupplier = (token, id) => {
		return dispatch(removeExpedientSupplier(token, id))
			.then(() => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.saveCorrectly'
					})
				);
				return Promise.resolve(true);
			})
			.catch((result) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorImportStatus(result),
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onLoadExpedientSupplier = (token) => {
		return dispatch(loadExpedientSupplier(token))
			.then(() => {
				return Promise.resolve(true);
			})
			.catch((error_) => {
				Promise.reject(false);
			});
	};

	return {
		user,
		isSaving,
		onCloseToaster,
		onAddOrRemoveDocumentInExpedientSupplier,
		onAddOrRemoveDocumentColumnInExpedientSupplier,
		onSetStatusExpedientSupplier,
		onLoadExpedientSupplier,
		onRemoveExpedientSupplier
	};
};
