/** Redux import section */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/**Import MaterialUi section */
import { Grid, Button, Avatar, Typography, TextField } from '@material-ui/core'
import AddtIcon from '@material-ui/icons/AddTwoTone';

/** section import components */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';

/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
import Toaster from '../../common/Toaster';

//Import store section
import { createDestination, loadConsignmentNoteDetail } from '../../../store/consignmentNote/consignmentNoteActions';


const PanelConsignmentNote = props => {
    const dispatch = useDispatch();
    /**props section */
    const {
        onCloseButtonClick,
        classes,
        isSaving,
    } = props

    const [panelInformation, setPanelInformation] = useState({
        Code: "",
        Name: "",
        RFC: "",
        Street: "",
        ExternalNumber: "",
        Suburb: "",
        Town: "",
        StateCode: "",
        CountryCode: "",
        ZipCode: "",
        RequestCode: ""
    });

    const [toasterMessageConfig, setToasterMessageConfig] = useState({
        showToaster: false,
        toasterMessage: null,
        toasterVariant: '',
        name: ''
    });

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setPanelInformation({
            ...panelInformation,
            [name]: value
        });
    }

    const canBeSaved = () => {
        if (IsNullOrEmpty(panelInformation.Code)) return false
        if (IsNullOrEmpty(panelInformation.Name)) return false
        if (IsNullOrEmpty(panelInformation.RFC)) return false
        if (IsNullOrEmpty(panelInformation.Street)) return false
        if (IsNullOrEmpty(panelInformation.ZipCode)) return false
        if (IsNullOrEmpty(panelInformation.RequestCode)) return false
        if (IsNullOrEmpty(panelInformation.ExternalNumber)) return false
        if (IsNullOrEmpty(panelInformation.Suburb)) return false
        if (IsNullOrEmpty(panelInformation.Town)) return false
        if (IsNullOrEmpty(panelInformation.StateCode)) return false
        if (IsNullOrEmpty(panelInformation.CountryCode)) return false
        else return true;
    }

    const onCreateDestinationFromBackEnd = () => {
        const consignmentNoteId = props.match.params.token;
        return dispatch(createDestination(consignmentNoteId, panelInformation))
            .then(() => {
                dispatch(loadConsignmentNoteDetail(consignmentNoteId));
                onCloseButtonClick()
                Promise.resolve();
            })
            .catch((response) => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: 'common.errorToSave',
                    toasterVariant: "error",
                })
            })
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}>
            <PanelHeader
                titlePanel={<Translate id="consignmentNote.createDestination.addDestination" />}
                icon={<Avatar className={classes.avatar}><AddtIcon /></Avatar>}
                onCloseButtonClick={onCloseButtonClick}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Typography color='primary'><Translate id="consignmentNote.createDestination.requestNumber" /></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Typography color='primary'><Translate id="consignmentNote.createDestination.RFC" /></Typography> */}
                            <TextField
                                label={<Translate id="consignmentNote.createDestination.requestCode" />}
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.RequestCode : ""}
                                onChange={onPropertyChange}
                                name='RequestCode'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color='primary'><Translate id="consignmentNote.createDestination.destinoTitle" /></Typography>
                        </Grid>
                        <Grid item xs={4}>
                            {/* <Typography color='primary'><Translate id="consignmentNote.createDestination.code" /></Typography> */}
                            <TextField
                                label={<Translate id="consignmentNote.createDestination.code" />}
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.Code : ""}
                                onChange={onPropertyChange}
                                name='Code'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            {/* <Typography color='primary'><Translate id="consignmentNote.createDestination.name" /></Typography> */}
                            <TextField
                                label={<Translate id="consignmentNote.createDestination.name" />}
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.Name : ""}
                                onChange={onPropertyChange}
                                name='Name'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Typography color='primary'><Translate id="consignmentNote.createDestination.RFC" /></Typography> */}
                            <TextField
                                label={<Translate id="consignmentNote.createDestination.RFC" />}
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.RFC : ""}
                                onChange={onPropertyChange}
                                name='RFC'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography color='primary'><Translate id="consignmentNote.createDestination.address" /></Typography>
                        </Grid>
                        <Grid item xs={8}>
                            {/* <Typography color='primary'></Typography> */}
                            <TextField
                                label={<Translate id="consignmentNote.createDestination.street" />}
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.Street : ""}
                                onChange={onPropertyChange}
                                name='Street'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {/* <Typography color='primary'></Typography> */}
                            <TextField
                                label={<Translate id="consignmentNote.createDestination.externalNumber" />}
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.ExternalNumber : ""}
                                onChange={onPropertyChange}
                                name='ExternalNumber'
                                margin="dense"
                                variant="outlined"
                            // type='number'
                            />
                        </Grid>

                        <Grid item xs={8}>
                            {/* <Typography color='primary'></Typography> */}
                            <TextField
                                label={<Translate id="consignmentNote.createDestination.suburb" />}
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.Suburb : ""}
                                onChange={onPropertyChange}
                                name='Suburb'
                                margin="dense"
                                variant="outlined"
                            // type='number'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {/* <Typography color='primary'></Typography> */}
                            <TextField
                                label={<Translate id="consignmentNote.createDestination.zipCode" />}
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.ZipCode : ""}
                                onChange={onPropertyChange}
                                name='ZipCode'
                                margin="dense"
                                variant="outlined"
                                type='number'
                            />
                        </Grid>
                        <Grid item xs={8}>
                            {/* <Typography color='primary'></Typography> */}
                            <TextField
                                label={<Translate id="consignmentNote.createDestination.town" />}
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.Town : ""}
                                onChange={onPropertyChange}
                                name='Town'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {/* <Typography color='primary'></Typography> */}
                            <TextField
                                label={<Translate id="consignmentNote.createDestination.stateCode" />}
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.StateCode : ""}
                                onChange={onPropertyChange}
                                name='StateCode'
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {/* <Typography color='primary'></Typography> */}
                            <TextField
                                label={<Translate id="consignmentNote.createDestination.countryCode" />}
                                fullWidth
                                className={classes.textField}
                                value={panelInformation ? panelInformation.CountryCode : ""}
                                onChange={onPropertyChange}
                                name='CountryCode'
                                margin="dense"
                                variant="outlined"
                            // type='number'
                            />
                        </Grid>
                        <br />
                    </Grid>
                </div>
                <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            className={classes.saveButton}
                            disabled={!canBeSaved() || isSaving}
                            onClick={onCreateDestinationFromBackEnd}>
                            {<Translate id="common.save" />}
                        </Button>
                        <Button
                            data-cy="btn-cancel"
                            type="button"
                            color="secondary"
                            variant='outlined'
                            className={classes.cancelButton}
                            onClick={onCloseButtonClick}>
                            {<Translate id="common.cancel" />}
                        </Button>
                    </Grid>
                </Grid>

                <Toaster
                    message={<Translate id={toasterMessageConfig.toasterMessage} />}
                    open={toasterMessageConfig.showToaster}
                    variant={toasterMessageConfig.toasterVariant}
                    onClose={() => {
                        setToasterMessageConfig({
                            showToaster: false,
                            toasterMessage: '',
                            toasterVariant: ''
                        });
                    }}
                />
            </div>
        </LateralPanel >
    );
};

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    saveButton: {
        bottom: 0,
        margin: 2
    },
    cancelButton: {
        color: theme.palette.error.main,
        bottom: 0,
        margin: 2
    }

});

PanelConsignmentNote.propTypes = {
    /**
     * callback executed add new user in the list
     */
    selectedCompany: propTypes.object,
    /**
     * open and closedlateral panel for add an new material
     */
    onSaveButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onCloseButtonClick: propTypes.func,
};

PanelConsignmentNote.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelConsignmentNote))