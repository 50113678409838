///Import react section
import React, { useCallback, useEffect, useState } from 'react';
import { Translate, getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';

/** Import component section */
import Toaster from '../common/Toaster';
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog';

/**import helpers section */
import LateralPanelContainer from '../common/LateralPanelComponents/LateralPanelContainer';
import AddAttachment from './components/create/AddAttachment';
import EditExpedientSupplier from './components/create/EditExpedientSupplier';
import CreateExpedient from './components/create/CreateExpedient';
import DeleteClassificationPanel from './components/create/DeleteClassificationPanel';
/**Custom hooks index */
import { useExpedientSupplierCustomHookIndex } from './customHooks/UseCustomHookIndex';
import { REMOVE_EXPEDIENT_DOCUMENT, REMOVE_EXPEDIENT_CLASSIFICATION } from '../../store/expedientSupplier/ExpedientSupplierAction';
const IndexEdit = (props) => {
	const { onCloseToaster, onUpdateDocumentType, onRemoveExpedientDocument, onDeleteAllClassification } = useExpedientSupplierCustomHookIndex();
	/**String props section */
	const { identifierPanelLateral } = props;
	/**Bools props section */
	//const { } = props
	/**Arrays props section */
	const { supplierClassifications } = props;
	/**Functions props section */
	const { onConfirmPassword } = props;

	/**Objects props section */
	const { setIdentifierPanelLateral, item, confirmPasswordConfigModal } = props;
	/**Render hooks section */
	/**Redux store information section*/
	const toasterMessageConfig = useSelector((state) => state.indirectQualifications.get('toasterConfiguration').toJS());

	const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'));
	const translate = useSelector((state) => getTranslate(state.localize));
	const [isCloseLateralPanel, setIsCloseLateralPlanel] = useState(false);

	const onUpdateDocumentTypeData = (model) => {
		onUpdateDocumentType(model).then((response) => {
			if (response === true) {
				setIsCloseLateralPlanel(true);
			}
		});
	};

	const onRemoveDocument = () => {
		onRemoveExpedientDocument(confirmPasswordConfigModal.item.token).then((response) => {
			if (response === true) {
				onConfirmPassword();
			}
		});
	};

	/**Determinate that panel lateral is selected for open */
	const PanelLateralChildren = useCallback(
		{
			IndexAddAttachments: (
				<AddAttachment translate={translate} item={item} onCloseButtonClick={() => setIsCloseLateralPlanel(true)} />
			),
			EditExpedientSupplier: (
				<EditExpedientSupplier
					translate={translate}
					item={item}
					onSaveButtonClick={onUpdateDocumentTypeData}
					onCloseButtonClick={() => setIsCloseLateralPlanel(true)}
				/>
			),
			CreateExpedient: (
				<CreateExpedient
					translate={translate}
					item={item}
					onSaveButtonClick={onUpdateDocumentTypeData}
					onCloseButtonClick={() => setIsCloseLateralPlanel(true)}
				/>
			),
			IndexDeleteClassification: (
				<DeleteClassificationPanel 
					translate={translate} 
					supplierClassifications={supplierClassifications} 
					onConfirmPassword={onConfirmPassword}
				/>
			)
		},
		[identifierPanelLateral]
	);
	const { LateralPanelComponent, onOpenAndCloseLateralPanel } = LateralPanelContainer({
		children: PanelLateralChildren[identifierPanelLateral],
		identifier: identifierPanelLateral,
		setIdentifierPanelLateral: setIdentifierPanelLateral
	});

	const onLateralPanelConfigChange = useCallback(() => {
		if (identifierPanelLateral) {
			onOpenAndCloseLateralPanel();
		}
	}, [identifierPanelLateral]);

	/**Open lateral panel when identifier of panel change */
	useEffect(() => {
		onLateralPanelConfigChange();
	}, [onLateralPanelConfigChange]);

	/**Open lateral panel when identifier of panel change */
	useEffect(() => {
		if (isCloseLateralPanel) {
			onOpenAndCloseLateralPanel();
			setIsCloseLateralPlanel(false);
		}
	}, [isCloseLateralPanel]);

	const deleteAllClassification = () => {
		onDeleteAllClassification(confirmPasswordConfigModal.item.token).then(() => {
			onOpenAndCloseLateralPanel();
			onConfirmPassword();
		});
	};

	const getMessageByIdentifier = () => {
		switch (confirmPasswordConfigModal.identifier) {
			case REMOVE_EXPEDIENT_DOCUMENT:
				return 'expedientSupplier.removeDocument';
			case REMOVE_EXPEDIENT_CLASSIFICATION:
				return 'expedientSupplier.removeSupplierClassification';
			default:
				return '';
		}
	};

	const getCallBackToInvokeByIndentifier = () => {
		switch (confirmPasswordConfigModal.identifier) {
			case REMOVE_EXPEDIENT_DOCUMENT:
				return onRemoveDocument;
			case REMOVE_EXPEDIENT_CLASSIFICATION:
				return deleteAllClassification
			default:
				return '';
		}
	};

	const getIsRequiredComments = () => {
		switch (confirmPasswordConfigModal.identifier) {
			default:
				return false;
		}
	};

	return (
		<>
			{confirmPasswordConfigModal && confirmPasswordConfigModal.opened && (
				<ConfirmPasswordDialog
					open={confirmPasswordConfigModal.opened}
					title={<Translate id="common.confirmOperation" />}
					message1={<Translate id={getMessageByIdentifier()} />}
					message2={<Translate id="common.enterPassword" />}
					message3={
						<>
							{confirmPasswordConfigModal && confirmPasswordConfigModal.item && (
								<Typography variant="subtitle2">{confirmPasswordConfigModal.item.Name}</Typography>
							)}
						</>
					}
					onClickButtonConfirm={getCallBackToInvokeByIndentifier()}
					onClose={onConfirmPassword}
					isSaving={isSaving}
					isVisibleComments={getIsRequiredComments()}
					commentRequired={getIsRequiredComments()}
				/>
			)}
			<LateralPanelComponent />
			<Toaster
				message={
					<Translate
						id={toasterMessageConfig.toasterMessage}
						data={{ name: toasterMessageConfig ? toasterMessageConfig.name : '' }}
					/>
				}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => onCloseToaster()}
			/>
		</>
	);
};

export default IndexEdit;
