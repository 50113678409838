import React, { Component } from 'react';
import autoBind from 'auto-bind'
import PropTypes from 'prop-types';
import moment from 'moment'

/** Material-UI imports section */
import { Translate } from 'react-localize-redux';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import QuestionnaireIcon from '@material-ui/icons/NaturePeople';
import Select from 'react-select'
/**import common components */
import HtmlEditor from '../../common/HtmlEditor';
import DatePicker from '../../common/DateTimePicker/DatePicker';
import { GetQuestionnairesType } from '../../../store/helpers/SelectOptions';

/**
 * Component to create format
 */
class InformationCreate extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            suppliers: [],
        };
    }

    render() {
        const { questionnaire, classes } = this.props;
        const questionnairesType = GetQuestionnairesType();
        return (
            <Card className={classes.card} style={{ height: '79vh' }} >
                <CardHeader
                    avatar={
                        <Avatar className={classes.userAvatar}>
                            <QuestionnaireIcon />
                        </Avatar>
                    }
                    title={<Typography variant="button">{<Translate id="questionnaire.questionnaire" />} </Typography>}
                    subheader={<Translate id="questionnaire.messageToSendQuestionnaire" />}
                />
                <CardContent style={{ height: '60vh', overflowY: "auto" }}>
                    <Grid container spacing={24}>
                        <Grid item xs={7}>
                            <Typography variant="caption" color={'textSecondary'}><Translate id="questionnaire.questionnaireTypeSelected" /></Typography>
                            <Select
                                value={questionnairesType ? questionnairesType['questionnairetype'] : ''}
                                className={classes.documentTypeSelect}
                                options={questionnairesType}
                                onChange={(event) => this.props.updateProperty("questionnairetype", event)}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 60 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }) /// THIS IS TO SHOW MENU OVER ANY ELEMENT
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="caption" color={'textSecondary'}><Translate id="questionnaire.scheduledDate" /></Typography>
                            <DatePicker
                                onChangeRange={(event) => this.props.updateProperty('scheduledDate', event)}
                                startDate={questionnaire.scheduledDate ? moment(questionnaire.scheduledDate, 'DD/MM/YYYY') : ''}
                                minDate={moment()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <HtmlEditor
                                id="questionnaire.messageToSupplier"
                                label={"questionnaire.messageToSupplier"}
                                labelVariant="caption"
                                labelColor="textSecondary"
                                isDisabled={false}
                                initialValue={questionnaire.comments}
                                onChange={(data) => this.props.updateProperty("comments", data)}
                            />
                        </Grid>
                    </Grid>
                </CardContent>

                <CardActions className={classes.actions} >
                    <Grid item xs={12}>
                        <Toolbar>
                            <Button variant="outlined" color="secondary" className={classes.button}
                                onClick={this.props.onCreate}
                                disabled={!this.props.canBeSaved || this.props.isSaving}
                            >
                                <Translate id={this.props.isSaving ? "common.saving" : "common.save"} />
                            </Button>
                            <Button variant="outlined" color="primary" className={classes.button}
                                data-cy="btn-close"
                                onClick={() => { this.props.history.push(`/questionnaire`) }} >
                                <Translate id="common.cancel" />
                            </Button>
                        </Toolbar>
                    </Grid>
                </CardActions>
            </Card>
        );
    }
}
InformationCreate.propTypes = {
    questionnaire: PropTypes.object.isRequired
};

export default InformationCreate;