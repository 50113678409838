/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import CreatableSelect from 'react-select/creatable'
/**Import material UI Section*/
import { withStyles } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Select from 'react-select'
import PurchaseIcon from '@material-ui/icons/CalendarTodayOutlined'
import TextField from '@material-ui/core/TextField'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import FindInPageIcon from '@material-ui/icons/FindInPage';
import List from '@material-ui/core/List'
import GroupIcon from '@material-ui/icons/SupervisorAccountTwoTone'

import {
    COLOR_STATUS_GRAY,
    COLOR_STATUS_WARNING,
    COLOR_STATUS_DANGER,
    COLOR_STATUS_SUCCESS,
} from '../../../store/helpers/StatusColorConstants'

const AdvancedOptionsPTM = (props) => {
    const { classes,
        canBeModuleExpeditByCompany,
        onChangeSupplierType,
        existSupplierType,
        researchOptions,
        onChangeTimeToEntryDeliveryOrder,
        partnership,
        updatePartnershipProperty,
        onChangeNumberRampsDeliveryOrder,
        numberRamps,
        timeDeliveryMateril,
        supplierTypes,
        userRolesByCompany,
        onChangeResearchOption } = props

    /**
     * get color for research value
     * @param {*} option 
     * @returns 
     */
    const getReserchColor = (option) => {
        let color
        switch (option ? option.value : 0) {
            case 1:
                color = COLOR_STATUS_SUCCESS
                break;
            case 2:
                color = COLOR_STATUS_DANGER
                break;
            case 3:
                color = COLOR_STATUS_WARNING
                break;
            default:
                color = COLOR_STATUS_GRAY
                break;
        }
        return color;
    }

    return (
        <List className={classes.root}>
            <Grid container>
                <Grid item xs={6}>
                    {canBeModuleExpeditByCompany(userRolesByCompany) && (
                        <>
                            <ListItem alignItems='flex-start'>
                                <GroupIcon />
                                <ListItemText
                                    secondary={
                                        <React.Fragment>
                                            {<Translate id='suppliers.optionsClasiffication' />}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <ListItem alignItems='flex-start'>
                                <ListItemText
                                    primary={<Translate id='suppliers.supplierType.title' />}
                                    secondary={
                                        <div style={{ width: '310px' }}>
                                            <CreatableSelect
                                                placeholder={
                                                    <Translate id='suppliers.supplierType.title' />
                                                }
                                                options={supplierTypes}
                                                onChange={onChangeSupplierType}
                                                value={existSupplierType}
                                                styles={{
                                                    menu: (base) => ({ ...base, zIndex: 9999 }),
                                                    menuList: (base) => ({
                                                        ...base,
                                                        paddingTop: 0,
                                                    }),
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                    }), /// THIS IS TO SHOW MENU OVER MODAL
                                                    control: (base) => ({
                                                        ...base,
                                                        height: 40,
                                                        minHeight: 40,
                                                    }),
                                                }}
                                                //menuPortalTarget={document.parentNode}
                                                menuPortalTarget={document.body}
                                                menuPlacement={'bottom'}
                                            />
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}
                </Grid>
                <Grid item xs={6}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end'
                    }}>
                    <div style={{ width: '350px', padding: '10px' }}>
                        <Grid container>
                            <Grid item xs={2}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <FindInPageIcon style={{ fontSize: 50, color: getReserchColor(researchOptions[partnership.research]) }} />
                            </Grid>
                            <Grid item xs={10}>
                                <Translate id='suppliers.research' />
                                <Select
                                    value={researchOptions[partnership.research]}
                                    className={classes.documentTypeSelect}
                                    options={researchOptions}
                                    onChange={onChangeResearchOption}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <ListItem alignItems='flex-start'>
                <PurchaseIcon />
                <ListItemText
                    secondary={
                        <React.Fragment>
                            {<Translate id='suppliers.daysDeliveryMaterial' />}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <ListItem alignItems='flex-start'>
                <ListItemText
                    primary={<Translate id='suppliers.timeHoursforDeliveryMaterial' />}
                    secondary={
                        <div style={{ width: '310px' }}>
                            <Select
                                value={
                                    timeDeliveryMateril[
                                    partnership
                                        .time_hours_required_for_delivery_material
                                    ]
                                }
                                className={classes.documentTypeSelect}
                                options={timeDeliveryMateril}
                                onChange={onChangeTimeToEntryDeliveryOrder}
                            />
                        </div>
                    }
                />
                <ListItemSecondaryAction>
                    <TextField
                        style={{ width: '290px' }}
                        id='standard-read-only-input'
                        label={<Translate id='suppliers.daysRequiredForDeliveryMaterial' />}
                        value={partnership.days_required_for_delivery_material}
                        onChange={(event) => {
                            updatePartnershipProperty(
                                'days_required_for_delivery_material',
                                event.target.value
                            )
                        }}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem alignItems='flex-start'>
                <ListItemText
                    primary={<Translate id='suppliers.supplierType.title' />}
                    secondary={
                        <div style={{ width: '310px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => {
                                            updatePartnershipProperty(
                                                'is_automotive_supplier',
                                                event.target.checked
                                            )
                                        }}
                                        color='primary'
                                        value='status'
                                        checked={partnership.is_automotive_supplier}
                                    />
                                }
                                label={<Translate id='suppliers.isAutomotiveSupplier' />}
                            />
                        </div>
                    }
                />
                <ListItemSecondaryAction>
                    <TextField
                        style={{ width: '290px' }}
                        id='standard-read-only-input'
                        label={<Translate id='suppliers.numberPpmsBySupplier' />}
                        value={partnership.number_ppms_by_supplier}
                        onChange={(event) => {
                            updatePartnershipProperty(
                                'number_ppms_by_supplier',
                                event.target.value
                            )
                        }}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem alignItems='flex-start'>
                <ListItemText
                    primary={<Translate id='suppliers.numberPampsPTM' />}
                    secondary={
                        <div style={{ width: '310px' }}>
                            <Select
                                value={numberRamps[partnership.number_ramps]}
                                className={classes.documentTypeSelect}
                                options={numberRamps}
                                onChange={onChangeNumberRampsDeliveryOrder}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER ANY ELEMENT
                                    menuList: (base) => ({
                                        ...base,
                                        maxHeight: 150,
                                        paddingTop: 0,
                                    }),
                                    clearIndicator: (base) => ({
                                        ...base,
                                        color: '#ff0000',
                                        padding: 0,
                                    }),
                                    dropdownIndicator: (base) => ({ ...base, padding: 0 }),
                                    indicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    }
                />
                <ListItemSecondaryAction style={{ padding: '20px' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(event) => {
                                    updatePartnershipProperty(
                                        'is_supplier_to_add_carbon_foot_print',
                                        event.target.checked
                                    )
                                }}
                                color='primary'
                                value='status_is'
                                checked={partnership.is_supplier_to_add_carbon_foot_print}
                            />
                        }
                        label={<Translate id='suppliers.IsSupplierToAddCarbonFootprint' />}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem alignItems='flex-start'>
                <ListItemText
                    secondary={
                        <div style={{ width: '310px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => {
                                            updatePartnershipProperty(
                                                'is_requiered_cerificate_by_template',
                                                event.target.checked
                                            )
                                        }}
                                        color='primary'
                                        value='status'
                                        checked={
                                            partnership.is_requiered_cerificate_by_template
                                        }
                                    />
                                }
                                label={
                                    <Translate id='suppliers.isRequieredTemplateCertificate' />
                                }
                            />
                        </div>
                    }
                />
                <ListItemSecondaryAction style={{ padding: '17px' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(event) => {
                                    updatePartnershipProperty(
                                        'is_requiered_quality_certificate',
                                        event.target.checked
                                    )
                                }}
                                color='primary'
                                value='status'
                                checked={partnership.is_requiered_quality_certificate}
                            />
                        }
                        label={<Translate id='suppliers.isRequieredQualityCertificate' />}
                    />
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    )
}

const styles = (theme) => ({
    flexEnd: {
        display: 'flex',
        marginTop: '7px',
    },
    margin: {
        marginTop: '15px',
    },
})
AdvancedOptionsPTM.propTypes = {
    canBeModuleExpeditByCompany: PropTypes.func.isRequired,
    onChangeSupplierType: PropTypes.func.isRequired,
    existSupplierType: PropTypes.array.isRequired,
    researchOptions: PropTypes.func.isRequired,
    onChangeTimeToEntryDeliveryOrder: PropTypes.func.isRequired,
    partnership: PropTypes.array.isRequired,
    updatePartnershipProperty: PropTypes.func.isRequired,
    onChangeNumberRampsDeliveryOrder: PropTypes.func.isRequired,
    numberRamps: PropTypes.func.isRequired,
    timeDeliveryMateril: PropTypes.func.isRequired,
    supplierTypes: PropTypes.array.isRequired,
    userRolesByCompany: PropTypes.array.isRequired,
    onChangeResearchOption: PropTypes.func.isRequired,
}

AdvancedOptionsPTM.defaultProps = {
    canBeModuleExpeditByCompany: () => console.warm('[canBeModuleExpeditByCompany] not defined'),
    onChangeSupplierType: () => console.warm('[onChangeSupplierType] not defined'),
    existSupplierType: [],
    researchOptions: () => console.warm('[researchOptions] not defined'),
    onChangeTimeToEntryDeliveryOrder: () => console.warm('[onChangeTimeToEntryDeliveryOrder] not defined'),
    partnership: [],
    updatePartnershipProperty: () => console.warm('[updatePartnershipProperty] not defined'),
    onChangeNumberRampsDeliveryOrder: () => console.warm('[onChangeNumberRampsDeliveryOrder] not defined'),
    numberRamps: () => console.warm('[numberRamps] not defined'),
    timeDeliveryMateril: () => console.warm('[timeDeliveryMateril] not defined'),
    supplierTypes: [],
    userRolesByCompany: [],
    onChangeResearchOption: () => console.warm('[onChangeResearchOption] not defined'),
}

export default withRouter(withStyles(styles, { withTheme: true })(AdvancedOptionsPTM));