import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'

/** Import component section */
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'
import Dropzone from '../../common/Dropzone'
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'

/**
 * Dialog to add scale data to quote item
 */
class CreateCorrectiveActionDialog extends Component {
	/**
	 *Creates an instance of DialogActions.
	 * @param {*} props
	 * @memberof DialogActions
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			description: '',
			attachments: [],
		}
	}

	/**
	 * React life cycle method
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof CreateCorrectiveActionDialog
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.props.item) {
			if (prevProps.item !== this.props.item) {
				let files = [{ name: this.props.item.document }]
				this.setState({
					description: this.props.item.description,
					token: this.props.item.token,
					attachments: files,
				})
			}
		}
	}

	/**
	 * Function to update scale property of text field in state
	 *
	 * @param {*} data
	 * @memberof CreateCorrectiveActionDialog
	 */
	updateProperty(data) {
		this.setState({
			[data.id]: data.value,
		})
	}

	/**
	 * Handle  Close or cancel event
	 *
	 * @memberof CreateCorrectiveActionDialog
	 */
	handleCloseCancel() {
		this.setState({ description: '', attachments: [] }, () => this.props.onClose())
	}

	/**
	 * Method to validate if required properties has data
	 *
	 * @returns
	 * @memberof CreateCorrectiveActionDialog
	 */
	isValidToAdd() {
		if (
			this.state.description &&
			this.state.description !== null &&
			this.state.description !== '' &&
			this.state.attachments &&
			this.state.attachments.length > 0
		) {
			return true
		}
		return false
	}

	/**
	 * On change files of dropzone
	 *
	 * @param {*} event
	 * @memberof DetailsCard
	 */
	onChangeFile(attachments) {
		this.setState({
			attachments,
		})
		return Promise.resolve('Ok')
	}

	/**
	 * On remove files of dropzone
	 *
	 * @param {*} event
	 * @memberof DetailsCard
	 */
	onDeleteFile(fileName) {
		let files = this.state.attachments.filter((file) => {
			return file.name !== fileName
		})
		this.setState({
			attachments: files,
		})
		return Promise.resolve('Ok')
	}

	/**
	 * Handle on save corrective action to add or update data
	 *
	 * @memberof CreateCorrectiveActionDialog
	 */
	handleOnSave() {
		let isUpdate = this.props.item ? true : false
		this.props
			.saveCorrectiveAction(this.state, isUpdate)
			.then((value) => {
				if (value === 'Ok') {
					this.handleCloseCancel()
				}
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
				return Promise.reject()
			})
	}
	/**
	 * Dialog to update information
	 */
	render() {
		const { classes } = this.props
		return (
			<Dialog
				open={this.props.open}
				onClose={() => this.handleCloseCancel()}
				header={
					<Toolbar>
						<Typography variant='h6'>
							{this.props.folio + ' | '}
							<Translate id='qualityfailurereport.correctiveAction' />
						</Typography>
					</Toolbar>
				}
				actions={
					<div>
						<DialogActions
							view={SHOW_EDIT_VIEW}
							classes={classes}
							onCancel={() => this.handleCloseCancel()}
							onClose={() => this.handleCloseCancel()}
							isSaving={this.props.isSaving}
							onSaveItem={() => this.handleOnSave()}
							canBeSaved={this.isValidToAdd()}
						/>
					</div>
				}
			>
				<form>
					<TextField
						id={'description'}
						autoFocus
						label={<Translate id={'qualityfailurereport.descriptionCorrectiveAction'} />}
						value={this.state.description ? this.state.description : ''}
						margin='normal'
						fullWidth
						required
						onBlur={(event) => this.updateProperty(event.target)}
						onChange={(event) => this.updateProperty(event.target)}
						onKeyDown={this.onKeyDown}
					/>
					<br />
					<br />
					<Typography variant='body2' component='p'>
						<Translate id={'qualityfailurereport.jobAttachment'} />
					</Typography>
					<Dropzone
						files={this.state.attachments}
						onChange={(event) => this.onChangeFile(event)}
						onDeleteFile={this.onDeleteFile}
						baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
						dropzoneText={'Agregar evidencia'}
						disabledLink
					/>
				</form>
			</Dialog>
		)
	}
}

export default withRouter(CreateCorrectiveActionDialog)
