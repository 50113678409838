import React from 'react'
import { Translate } from 'react-localize-redux'

import { ConsignementNoteStatus, ConsingmentType } from './AppConstants'

import {
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_WARNING,
	COLOR_STATUS_GRAY,
} from './StatusColorConstants'

import { IsUserSupplierAdministrator } from './SessionHelper'
import { canBeModuleConsignmentNoteSupplier, canBeModuleConsignmentNote } from './RolesHelper'
import { IsNullOrEmpty } from './StringHelper'

export const backgroundColorTableStatus = (status) => {
	switch (Number(status)) {
		case ConsignementNoteStatus.DRAFT:
			return 'secondary'
		case ConsignementNoteStatus.SENT_TO_CARRIER:
		case ConsignementNoteStatus.IN_PROCESS:
		case ConsignementNoteStatus.RESENT_TO_CARRIERT:
			return 'warning'
		case ConsignementNoteStatus.COMPLETED:
			return 'success'
		case ConsignementNoteStatus.CANCELLED:
		case ConsignementNoteStatus.PENDING_ACKNOWLEDGMENT:
			return 'danger'
		default:
			return ''
	}
}

export const getColorText = (status) => {
	switch (Number(status)) {
		case ConsignementNoteStatus.DRAFT:
			return COLOR_STATUS_GRAY
		case ConsignementNoteStatus.SENT_TO_CARRIER:
		case ConsignementNoteStatus.IN_PROCESS:
		case ConsignementNoteStatus.RESENT_TO_CARRIERT:
			return COLOR_STATUS_WARNING
		case ConsignementNoteStatus.COMPLETED:
			return COLOR_STATUS_SUCCESS
		case ConsignementNoteStatus.CANCELLED:
		case ConsignementNoteStatus.PENDING_ACKNOWLEDGMENT:
		case ConsignementNoteStatus.PENDING_ADDITIONAL_EXPENSES:
			return COLOR_STATUS_DANGER
		default:
			return ''
	}
}

export const descriptionStatus = (status, translate, source) => {
	switch (Number(status)) {
		case ConsignementNoteStatus.DRAFT:
			return translate('consignmentNote.status.draft')
		case ConsignementNoteStatus.SENT_TO_CARRIER:
			return translate('consignmentNote.status.sentToCarrier')
		case ConsignementNoteStatus.PENDING_ACKNOWLEDGMENT:
			if (source === ConsingmentType.DEVOLUTION) return translate('consignmentNote.status.pendingAcuseDevolution')
			else return translate('consignmentNote.status.pendingAcuseRecibo')
		case ConsignementNoteStatus.IN_PROCESS:
			return translate('consignmentNote.status.inProcess')
		case ConsignementNoteStatus.COMPLETED:
			return translate('consignmentNote.status.completed')
		case ConsignementNoteStatus.CANCELLED:
			return translate('consignmentNote.status.cancelled')
		case ConsignementNoteStatus.RESENT_TO_CARRIERT:
			return translate('consignmentNote.status.resentFolio')
		case ConsignementNoteStatus.PENDING_ADDITIONAL_EXPENSES:
			return translate('consignmentNote.status.PendingAdditionalExpenses')
		default:
			return ''
	}
}

/**
 * Action to determine if the user supplier is administrator on is role supplier
 * @param {*} consignmentNote
 * @param {*} userRolesByCompany
 * @param {*} userProfile
 */
export function canBeEditSupplier(consignmentNote, userRolesByCompany, userProfile) {
	if (userRolesByCompany === undefined) return false
	if (userProfile === null || userProfile === undefined) return false
	if (consignmentNote === null || consignmentNote === undefined) return false
	if (
		consignmentNote.status !== ConsignementNoteStatus.PENDING_ACKNOWLEDGMENT &&
		consignmentNote.status !== ConsignementNoteStatus.SENT_TO_CARRIER &&
		consignmentNote.status !== ConsignementNoteStatus.RESENT_TO_CARRIERT &&
		consignmentNote.status !== ConsignementNoteStatus.PENDING_ADDITIONAL_EXPENSES
	)
		return false
	let supplierAdmin = IsUserSupplierAdministrator(userProfile)
	return supplierAdmin || canBeModuleConsignmentNoteSupplier(userRolesByCompany)
}

export function canBeUpdatePlant(consignmentNote, userRolesByCompany, userProfile) {
	if (userRolesByCompany === undefined) return false
	if (userProfile === null || userProfile === undefined) return false
	if (consignmentNote === null || consignmentNote === undefined) return false
	if (
		consignmentNote.status === ConsignementNoteStatus.COMPLETED ||
		consignmentNote.status === ConsignementNoteStatus.CANCELLED
	)
		return false
	return canBeModuleConsignmentNote(userRolesByCompany)
}

export function canBeCreateDevolution(consignmentNote, userRolesByCompany, userProfile) {
	if (userRolesByCompany === undefined) return false
	if (userProfile === null || userProfile === undefined) return false
	if (consignmentNote === null || consignmentNote === undefined) return false
	return canBeModuleConsignmentNote(userRolesByCompany)
}

export function TableConfiguration(canbeUpdateSupplier, canbeUpdatePlant, status, onOpenChangeStatus) {
	let columns = []

	if (canbeUpdatePlant && status === ConsignementNoteStatus.DRAFT) {
		columns.push({
			value: ConsignementNoteStatus.SENT_TO_CARRIER,
			title: <Translate id='consignmentNote.show.sentRequestCarrier' />,
			onClick: () => onOpenChangeStatus(ConsignementNoteStatus.SENT_TO_CARRIER),
			isIconMaterial: true,
			description: <Translate id='consignmentNote.show.messageSentRequestCarrier' />,
		})
		columns.push({
			value: ConsignementNoteStatus.CANCELLED,
			title: <Translate id='consignmentNote.show.cancelledFolio' />,
			onClick: () => onOpenChangeStatus(ConsignementNoteStatus.CANCELLED),
			isIconMaterial: true,
			description: <Translate id='consignmentNote.show.messageCancelledFolio' />,
		})
	}

	if (
		canbeUpdatePlant &&
		(status === ConsignementNoteStatus.SENT_TO_CARRIER ||
			status === ConsignementNoteStatus.PENDING_ACKNOWLEDGMENT ||
			status === ConsignementNoteStatus.RESENT_TO_CARRIERT)
	) {
		columns.push({
			value: ConsignementNoteStatus.CANCELLED,
			title: <Translate id='consignmentNote.show.cancelledFolio' />,
			onClick: () => onOpenChangeStatus(ConsignementNoteStatus.CANCELLED),
			isIconMaterial: true,
			description: <Translate id='consignmentNote.show.messageCancelledFolio' />,
		})
	}

	if (canbeUpdatePlant && status === ConsignementNoteStatus.IN_PROCESS) {
		columns.push({
			value: ConsignementNoteStatus.RESENT_TO_CARRIERT,
			title: <Translate id='consignmentNote.show.resentdFolio' />,
			onClick: () => onOpenChangeStatus(ConsignementNoteStatus.RESENT_TO_CARRIERT),
			isIconMaterial: true,
			description: <Translate id='consignmentNote.show.messageResentCarriertFolio' />,
		})
		columns.push({
			value: ConsignementNoteStatus.COMPLETED,
			title: <Translate id='consignmentNote.show.completedFolio' />,
			onClick: () => onOpenChangeStatus(ConsignementNoteStatus.COMPLETED),
			isIconMaterial: true,
			description: <Translate id='consignmentNote.show.messageCompletedFolio' />,
		})
		columns.push({
			value: ConsignementNoteStatus.CANCELLED,
			title: <Translate id='consignmentNote.show.cancelledFolio' />,
			onClick: () => onOpenChangeStatus(ConsignementNoteStatus.CANCELLED),
			isIconMaterial: true,
			description: <Translate id='consignmentNote.show.messageCancelledFolio' />,
		})
	}

	if (
		canbeUpdateSupplier &&
		(status === ConsignementNoteStatus.SENT_TO_CARRIER || status === ConsignementNoteStatus.RESENT_TO_CARRIERT)
	) {
		columns.push({
			value: ConsignementNoteStatus.PENDING_ACKNOWLEDGMENT,
			title: <Translate id='consignmentNote.show.sendRequestPort' />,
			onClick: () => onOpenChangeStatus(ConsignementNoteStatus.PENDING_ACKNOWLEDGMENT),
			isIconMaterial: true,
			description: <Translate id='consignmentNote.show.messageSendRequestPort' />,
		})
	}

	// if (canbeUpdateSupplier && status === ConsignementNoteStatus.PENDING_ACKNOWLEDGMENT) {
	// 	columns.push({
	// 		value: ConsignementNoteStatus.IN_PROCESS,
	// 		title: <Translate id='consignmentNote.show.sendRequestPlant' />,
	// 		onClick: () => onOpenChangeStatus(ConsignementNoteStatus.IN_PROCESS),
	// 		isIconMaterial: true,
	// 		description: <Translate id='consignmentNote.show.messageSendRequestPlant' />,
	// 	})
	// }

	if (canbeUpdateSupplier && status === ConsignementNoteStatus.PENDING_ACKNOWLEDGMENT) {
		columns.push({
			value: ConsignementNoteStatus.PENDING_ADDITIONAL_EXPENSES,
			title: <Translate id='consignmentNote.show.pendingAdditionalExpenses' />,
			onClick: () => onOpenChangeStatus(ConsignementNoteStatus.PENDING_ADDITIONAL_EXPENSES),
			isIconMaterial: true,
			description: <Translate id='consignmentNote.show.messagependingAdditionalExpenses' />,
		})
	}

	if (status === ConsignementNoteStatus.PENDING_ADDITIONAL_EXPENSES) {
		columns.push({
			value: ConsignementNoteStatus.IN_PROCESS,
			title: <Translate id='consignmentNote.show.sendRequestPlant' />,
			onClick: () => onOpenChangeStatus(ConsignementNoteStatus.IN_PROCESS),
			isIconMaterial: true,
			description: <Translate id='consignmentNote.show.messageSendRequestPlant' />,
		})
	}
	return columns
}

export function onTitleStatusDialog(status) {
	switch (status) {
		case ConsignementNoteStatus.SENT_TO_CARRIER:
			return 'consignmentNote.show.sentRequestCarrier'
		case ConsignementNoteStatus.PENDING_ACKNOWLEDGMENT:
			return 'consignmentNote.show.sendRequestPort'
		case ConsignementNoteStatus.PENDING_ADDITIONAL_EXPENSES:
			return 'consignmentNote.show.pendingAdditionalExpenses'
		case ConsignementNoteStatus.IN_PROCESS:
			return 'consignmentNote.show.sendRequestPlant'
		case ConsignementNoteStatus.COMPLETED:
			return 'consignmentNote.show.completedFolio'
		case ConsignementNoteStatus.CANCELLED:
			return 'consignmentNote.show.cancelledFolio'
		case ConsignementNoteStatus.RESENT_TO_CARRIERT:
			return 'consignmentNote.show.resentdFolio'
		default:
			return 'consignmentNote.show.noOption'
	}
}
export function onMessageStatusDialog(status) {
	switch (status) {
		case ConsignementNoteStatus.SENT_TO_CARRIER:
			return 'consignmentNote.show.messageSentRequestCarrier'
		case ConsignementNoteStatus.PENDING_ACKNOWLEDGMENT:
			return 'consignmentNote.show.messageSendRequestPort'
		case ConsignementNoteStatus.PENDING_ADDITIONAL_EXPENSES:
			return 'consignmentNote.show.messagependingAdditionalExpenses'
		case ConsignementNoteStatus.IN_PROCESS:
			return 'consignmentNote.show.messageSendRequestPlant'
		case ConsignementNoteStatus.COMPLETED:
			return 'consignmentNote.show.messageCompletedFolio'
		case ConsignementNoteStatus.CANCELLED:
			return 'consignmentNote.show.messageCancelledFolio'
		case ConsignementNoteStatus.RESENT_TO_CARRIERT:
			return 'consignmentNote.show.messageResentCarriertFolio'
		default:
			return 'consignmentNote.show.noOption'
	}
}

export function getErrorInChangeStatus(response) {
	if (!IsNullOrEmpty(response.data) && typeof response.data === 'string') {
		if (response.data.includes('invalidNotExistDeliveries'))
			return 'consignmentNote.errors.invalidNotExistDeliveries'
		else if (response.data.includes('notExtistActionPlansGenerated'))
			return 'consignmentNote.errors.invalidNotExistMaterials'
		else if (response.data.includes('invalidStatusToChangeInProcess'))
			return 'consignmentNote.errors.invalidStatusToChangeInProcess'
		else if (response.data.includes('invalidXmlToChangeInProcess'))
			return 'consignmentNote.errors.invalidXmlToChangeInProcess'
		else if (response.data.includes('invalidStatusToCompleted'))
			return 'consignmentNote.errors.invalidStatusToCompleted'
		else if (response.data.includes('invalidStatusToCancelled'))
			return 'consignmentNote.errors.invalidStatusToCancelled'
		else if (response.data.includes('invalidNotExistAcusesInDeliveryV2'))
			return 'consignmentNote.errors.invalidNotExistAcusesInDeliveryV2'
		else if (response.data.includes('invalidNotExistAcusesInDelivery'))
			return 'consignmentNote.errors.invalidNotExistAcusesInDelivery'
		else if (response.data.includes('invalidDeliveryDate')) return 'consignmentNote.errors.invalidDeliveryDate'
		else return 'common.errorToSave'
	} else {
		return 'common.errorToSave'
	}
}
