import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import moment from 'moment'

/** Import component section */
import LoadingPage from '../../common/LoadingPage'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import DateTimePicker from '../../common/DateTimePicker/DatePicker';
import DataTable from '../../common/DataTable';

/** Material UI imports section */
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ConsignationIcon from '@material-ui/icons/RvHookupTwoTone';

import { getConsignmentsFromSAP, loadSupplier, donwlodConsignmentsFromSAP } from '../../../store/suppliers/SupplierActions'


/**
 * Consignments list 
 *
 * @class Consignments list
 * @extends {Component}
 */
class Consignments extends Component {

    /**
     * Create an instance of Consignments list
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            startDate: moment(),
            endDate: moment(),
            sortCriteria: {
                by: "status",
                ascending: false
            },
            isSearching: false
        }
    }

    /**
     * componentDidMount
     */
    componentDidMount(){
        let supplierToken = this.props.match.params.token;
        this.props.loadSupplier(supplierToken);
    }

    /**
     * On change range
     *
     * @param {*} startDate
     * @param {*} endDate
     * @memberof Stocks
     */
    onChangeRange(startDate, endDate) {
        this.setState({
            startDate,
            endDate,
        })
    }

    onChangeRangeStart(startDate){
        this.setState({
            startDate,
        })
    }

    onChangeRangeEnd(endDate){
        this.setState({
            endDate,
        })
    }

    /**
     * On change sort criteria
     *
     * @param {*} sortBy
     * @param {*} sortAscending
     * @memberof Index
     */
    onChangeSortCriteria(sortBy, sortAscending) {
        let sortCriteria = {
            by: sortBy,
            ascending: sortAscending
        }
        this.setState({
            sortCriteria
        })
    }

    /**
     * On search Consignments click
     *
     * @memberof Consignments
     */
    onSearchClick() {
        if(this.props.supplier){
            let supplierCode = this.props.supplier.code;
            let startDate = this.state.startDate.format("DD/MM/YYYY");
            let endDate = this.state.endDate.format("DD/MM/YYYY");
            this.props.getConsignments(supplierCode, startDate, endDate);
        }
    }

    /**
     * On donwload click
     */
    onDonwlodClick(){
        if (this.props.supplier) {
            let supplierCode = this.props.supplier.code;
            let startDate = this.state.startDate.format("DD/MM/YYYY");
            let endDate = this.state.endDate.format("DD/MM/YYYY");
            this.props.donwlodConsignments(supplierCode, startDate, endDate);
        }
    }

    /**
     * On render cell item custom
     * @param {*} dataSource 
     * @param {*} item 
     */
    onRenderCellItem(dataSource, item) {
        if (dataSource === "amount_total") {
            return `${item.amount_total} ${item.currency}`
        }
        if(dataSource === "is_invoice"){
            if(item.is_invoice){
                return (<Translate id="queries.consignments.invoice"/>)
            }
            else
                return (<Translate id="queries.consignments.slope"/>)
        }
    }

    /**
     * Get card content
     *
     * @returns
     * @memberof Stocks
     */
    getCardContent() {
        const { sortCriteria } =this.state;
        if (this.props.consignments) {
            return (
                <DataTable 
                    data = {this.props.consignments}
                    configuration = {TableConfiguration}
                    onRenderCellItem={this.onRenderCellItem}
                    mode={"clientSide"}
                    sortBy={sortCriteria.by}
                    sortAscending={sortCriteria.ascending}
                    onChangeSortCriteria={this.onChangeSortCriteria}
                    showFilters
                    widthFilters={200}
                />
            )
        } else if (this.props.isLoadingConsignments) {
            return (
                <LoadingPage />
            )
        }
        else {
            return (
                <div className="alert alert-info" role="alert">
                    <h6 className="alert-heading"><i className="fab fa-searchengin"></i><Translate id="queries.consignments.searchConsignmentsTitle"/></h6>
                    <p><Translate id="queries.consignments.searchConsignmentsMessage"/> </p>
                </div>               
            )
        }
    }

    /**
     * Render
     *
     * @returns
     * @memberof Consignments
     */
    render() {
        const { classes } = this.props;
        return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar color="primary" >
                            <ConsignationIcon />
                        </Avatar>
                    }
                    title={<span><Translate id= "menu.consults"/> | <Translate id= "menu.consignation"/></span>}
                    subheader={ <span>
                        {this.props.supplier ? this.props.supplier.full_name : ""}
                        <div className={classes.toolbar}>
                            {/* <DateTimePicker
                                onChangeRange={this.onChangeRange}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                            />  */}
                            <DateTimePicker
                                onChangeRange={this.onChangeRangeStart}
                                startDate={this.state.startDate}
                            />

                            <DateTimePicker
                                onChangeRange={this.onChangeRangeEnd}
                                startDate={this.state.endDate}
                            />
                            <Button variant="contained" color="default" disabled={this.props.isLoadingConsignments}  style={{marginLeft: "3px", fontSize:"smaller"}} onClick={this.onSearchClick}><Translate id= "common.search"/></Button>
                            <Button variant="contained" color="default" disabled={this.props.isDownloadQueries} style={{marginLeft: "3px", fontSize:"smaller"}} onClick={this.onDonwlodClick}> <Translate id="common.download" /></Button>
                        </div>
                    </span>
                }
                />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>
            </Card>
        )
    }
}


const TableConfiguration = {
    columns: [{
        header: <Translate id="queries.consignments.status"/>,
        dataSource: 'is_invoice',
        isSortable:true
    },{
        header: <Translate id="queries.consignments.material"/>,
        dataSource: 'material',
        isSortable:true
    },{
        header: <Translate id="queries.consignments.totalUnit"/>,
        dataSource: 'unit_total',
        isSortable:true
    },{
        header: <Translate id="queries.consignments.unitMeasure"/>,
        dataSource: 'unit',
        isSortable:true
    },{
        header: <Translate id="queries.consignments.totalAmount"/>,
        dataSource: 'amount_total'
    }]
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    return {
        supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
        isLoadingConsignments: state.suppliers.get('isLoadingConsignments'),
        consignments: state.suppliers.get('consignments') ? state.suppliers.get('consignments').toJS(): null,
        isDownloadQueries: state.suppliers.get('isDownloadQueries'),
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadSupplier:(token) =>{
            dispatch(loadSupplier(token))
        },
        getConsignments:(supplierCode, startDate, endDate) => {
            dispatch(getConsignmentsFromSAP(supplierCode, startDate, endDate))
        },
        donwlodConsignments:(supplierCode, startDate, endDate) => {
            dispatch(donwlodConsignmentsFromSAP(supplierCode, startDate, endDate))
        },
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    documentTypeSelect: {
        width: "150px",
        marginRight: "5px"
    }
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(Consignments))));
