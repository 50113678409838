import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

/** Material UI import section */
import { Switch, Typography, withStyles } from '@material-ui/core';
import { COLOR_STATUS_INFO } from '../../../store/helpers/StatusColorConstants';

const ServiceOrdersSourceSwitch = (props) => {
	const { checked, classes, onChange } = props;
	return (
		<div className={classes.root}>
			<Typography variant="subtitle2">
				<Translate id="accreditedWorkshops.serviceOrdersSource" />
			</Typography>
			<div className={classes.switchContainer}>
				<Typography variant="caption">
					<Translate id="accreditedWorkshops.crm" />
				</Typography>
				<PurpleSwitch checked={checked} onChange={onChange} />
				<Typography variant="caption">
					<Translate id="accreditedWorkshops.ps9" />
				</Typography>
			</div>
		</div>
	);
};

ServiceOrdersSourceSwitch.propTypes = {
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func
};

ServiceOrdersSourceSwitch.defaultProps = {
	checked: false,
	onChange: () => console.warn('[onChange] method from ButtonClearFilters is undefined')
};

const styles = (theme) => ({
	root: {
		textAlign: 'center',
		textTransform: 'uppercase',
	},
	switchContainer: {
		display: 'flex',
		height: 35,
		justifyContent: 'center',
		alignItems: 'center'
	},
	switch: {
		switchBase: {
			color: 'orange',
			'&$checked': {
				color: 'orange'
			},
			'&$checked + $track': {
				backgroundColor: 'orange'
			}
		},
		checked: {},
		track: {}
	}
});

export default withStyles(styles, { withTheme: true })(ServiceOrdersSourceSwitch);

const PurpleSwitch = withStyles({
	switchBase: {
		'&$checked + $track': {
			backgroundColor: 'orange'
		},
		'$track': {
			backgroundColor: COLOR_STATUS_INFO
		}
	},
	checked: {},
	track: {
		backgroundColor: COLOR_STATUS_INFO
	}
})(Switch);
