/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'

export const START_LOADING_NOTICE_CHANGES = 'START_LOADING_NOTICE_CHANGES'
export const SET_NOTICE_CHANGES_LIST = 'SET_NOTICE_CHANGES_LIST'
export const CHANGE_NOTICE_CHANGES_SORT_CRITERIA = 'CHANGE_NOTICE_CHANGES_SORT_CRITERIA'
export const CHANGE_NOTICE_CHANGES_QUERY = 'CHANGE_NOTICE_CHANGES_QUERY'
export const CHANGE_NOTICE_CHANGES_PAGINATION = 'CHANGE_NOTICE_CHANGES_PAGINATION'
export const SET_NOTICE_CHANGES_COUNT = 'SET_NOTICE_CHANGES_COUNT'
export const IS_LOADING_NOTICE_CHANGE = 'IS_LOADING_NOTICE_CHANGE'
export const GET_INFORMATION_NOTICE_CHANGE = 'GET_INFORMATION_NOTICE_CHANGE'
export const UPDATE_DETAIL_SCHEMATICS = 'UPDATE_DETAIL_SCHEMATICS'
export const IS_UPDATING_PLANE_NAME = 'IS_UPDATING_PLANE_NAME'
export const DELETE_DETAIL_SCHEMATIC = 'DELETE_DETAIL_SCHEMATIC'
export const IS_DELETE_DETAIL_SCHEMATIC = 'IS_DELETE_DETAIL_SCHEMATIC'
export const SET_NOTICE_ITEM_SELECTED = 'SET_NOTICE_ITEM_SELECTED'
export const SET_NOTICE_ITEMS_SELECTED = 'SET_NOTICE_ITEMS_SELECTED' //MASSIVE UPDATE
export const ADD_SUPPLIER_FROM_NOTICE_CHANGE = 'ADD_SUPPLIER_FROM_NOTICE_CHANGE'
export const REMOVE_SUPPLIER_FROM_NOTICE_CHANGE = 'REMOVE_SUPPLIER_FROM_NOTICE_CHANGE'
export const ADD_SAMPLE_REQUEST_SUPPLIER_FROM_NOTICE_CHANGE = 'ADD_SAMPLE_REQUEST_SUPPLIER_FROM_NOTICE_CHANGE'
export const REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_NOTICE_CHANGE = 'REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_NOTICE_CHANGE'
export const SEND_QUOTES_REQUEST_NOTICE_CHANGE = 'SEND_QUOTES_REQUEST_NOTICE_CHANGE'
export const SET_NOTICE_CHANGE = 'SET_NOTICE_CHANGE'
export const IS_SAVING_NOTICE_OF_CHANGE = 'IS_SAVING_NOTICE_OF_CHANGE'
export const FINALIZE_NOTICE_OF_CHANGE = 'FINALIZE_NOTICE_OF_CHANGE'
export const ADD_QUOTE_SUPPLIER_FROM_NOTICE_CHANGE = 'ADD_QUOTE_SUPPLIER_FROM_NOTICE_CHANGE'
export const REMOVE_QUOTE_SUPPLIER_FROM_NOTICE_CHANGE = 'REMOVE_QUOTE_SUPPLIER_FROM_NOTICE_CHANGE'
export const IS_LOADING_NOTIFICATION_NOTICE = 'IS_LOADING_NOTIFICATION_NOTICE'
export const GET_INFORMATION_NOTIFICATION_NOTICE = 'GET_INFORMATION_NOTIFICATION_NOTICE'
export const UPDATE_COMMENTS = 'UPDATE_COMMENTS'
export const ADD_FILE_NOTICE = 'ADD_FILE_NOTICE'
export const REMOVE_FILE_NOTICE = 'REMOVE_FILE_NOTICE'
export const UPDATE_NOTICE_DETAIL_ITEM_PROPERTY = 'UPDATE_NOTICE_DETAIL_ITEM_PROPERTY'
export const SET_REACTIVE_QUOTE_IN_NOTICE = 'SET_REACTIVE_QUOTE_IN_NOTICE'
export const SET_QUOTE_UPDATE_IN_NOTICE_OF_CHANGE = 'SET_QUOTE_UPDATE_IN_NOTICE_OF_CHANGE'
export const IS_LOADING_QUOTE_NOTICE_OF_CHANGE = 'IS_LOADING_QUOTE_NOTICE_OF_CHANGE'
/**
 * Action start loading notices
 * @param {*} isLoading
 */
function isLoadingNoticeChanges(isLoading) {
	return {
		type: START_LOADING_NOTICE_CHANGES,
		isLoading: isLoading,
	}
}

/**
 * Action to set notices list
 * @param {*} notices
 */
function setNoticesList(notices) {
	return {
		type: SET_NOTICE_CHANGES_LIST,
		notices: notices,
	}
}

/**
 * Action to saving of notice of change
 * @param {*} isSaving
 */
function isSavingNotoceOfChange(isSaving) {
	return {
		type: IS_SAVING_NOTICE_OF_CHANGE,
		isSaving: isSaving,
	}
}

/**
 * Action reducer to set the notice's counter
 *
 * @param {*} count
 * @returns
 */
function setNoticeChangeCount(count) {
	return {
		type: SET_NOTICE_CHANGES_COUNT,
		count,
	}
}

/**
 * Action to start load notice
 */
function startLoadNoticeChange(isLoading) {
	return {
		type: IS_LOADING_NOTICE_CHANGE,
		isLoadingNotice: isLoading,
	}
}

/**
 * Action to get informaiton from notice
 * @param {*} memo
 */
function getInformationNoticeChange(notice) {
	return {
		type: GET_INFORMATION_NOTICE_CHANGE,
		notice: notice,
	}
}

/**
 * Action to update plot
 */
function isUpdatingPlaneName(isUploading) {
	return {
		type: IS_UPDATING_PLANE_NAME,
		isUploading: isUploading,
	}
}

function updateDetailSchematic(detailPlane) {
	return {
		type: UPDATE_DETAIL_SCHEMATICS,
		detailPlane: detailPlane,
	}
}

/**
 * Action to remove plot
 * @param {*} deletedToken
 */
function deleteDetailSchemetic(deletedToken) {
	return {
		type: DELETE_DETAIL_SCHEMATIC,
		deletedToken: deletedToken,
	}
}

/**
 *isDeleteDetailSchematic
 */
export const isDeleteDetailSchematic = (isDeleting) => ({
	type: IS_DELETE_DETAIL_SCHEMATIC,
	isDeleting: isDeleting,
})

/**
 * Action to add supplier in quote list
 * @param {*} supplier
 */
function addSupplierInNotieChangeDetails(noticeItemToken, quote) {
	return {
		type: ADD_SUPPLIER_FROM_NOTICE_CHANGE,
		noticeItemToken,
		quote,
	}
}

/**
 * Action to remove a supplier from a notice change
 *
 * @param {*} noticeItemToken
 * @param {*} supplierToken
 * @returns
 */
function supplierRemovedFromItemDetails(noticeItemToken, supplierToken) {
	return {
		type: REMOVE_SUPPLIER_FROM_NOTICE_CHANGE,
		noticeItemToken,
		supplierToken,
	}
}

/**
 * Action to add sample request supplier from a notice change
 * @param {*} noticeItemToken
 * @param {*} supplierToken
 */
function addSampleRequestSupplierInNoticeChangeItem(noticeItemToken, supplierToken) {
	return {
		type: ADD_SAMPLE_REQUEST_SUPPLIER_FROM_NOTICE_CHANGE,
		noticeItemToken: noticeItemToken,
		supplierToken: supplierToken,
	}
}

/**
 * Action to remove sample request supplier from a notice change
 * @param {*} noticeItemToken
 * @param {*} supplierToken
 */
function removeSampleRequestSupplierInNoticeChangeItem(noticeItemToken, supplierToken) {
	return {
		type: REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_NOTICE_CHANGE,
		noticeItemToken: noticeItemToken,
		supplierToken: supplierToken,
	}
}

/**
 * Action to add quote supplier from a notice change
 * @param {*} noticeItemToken
 * @param {*} supplierToken
 */
function addQuoteSupplierInNoticeChangeItem(noticeItemToken, supplierToken) {
	return {
		type: ADD_QUOTE_SUPPLIER_FROM_NOTICE_CHANGE,
		noticeItemToken: noticeItemToken,
		supplierToken: supplierToken,
	}
}

/**
 * Action to remove sample request supplier from a notice change
 * @param {*} noticeItemToken
 * @param {*} supplierToken
 */
function removeQuoteSupplierInNoticeChangeItem(noticeItemToken, supplierToken) {
	return {
		type: REMOVE_QUOTE_SUPPLIER_FROM_NOTICE_CHANGE,
		noticeItemToken: noticeItemToken,
		supplierToken: supplierToken,
	}
}

/**
 * Action to start send notice change
 */
function sendNoticeChangeRequest(isSaving) {
	return {
		type: SEND_QUOTES_REQUEST_NOTICE_CHANGE,
		isSaving: isSaving,
	}
}

/**
 * Action to set notice of change
 * @param {*} notice of change
 */
function setNoticeChange(notice) {
	return {
		type: SET_NOTICE_CHANGE,
		notice: notice,
	}
}

/**
 * Action to finalize a notice of change
 * @param {*} memo
 */
function finalizeNoticeOfChange() {
	return {
		type: FINALIZE_NOTICE_OF_CHANGE,
	}
}

/**
 * Action to start load notification notice
 */
function startLoadNotificationNotice(isLoading) {
	return {
		type: IS_LOADING_NOTIFICATION_NOTICE,
		isLoadingNotification: isLoading,
	}
}

/**
 * Action to get informaiton from notice
 * @param {*} memo
 */
function getInformationNotificationNotice(notification) {
	return {
		type: GET_INFORMATION_NOTIFICATION_NOTICE,
		notification: notification,
	}
}

/**
 * Action to update comments of part number in details list
 *
 * @param {*} partNumberDetail
 * @returns
 */
function updateComments(partNumberDetail) {
	return {
		type: UPDATE_COMMENTS,
		partNumberDetail,
	}
}

/**
 * Add file
 *
 * @param {*} files
 * @returns
 */
function addFile(files, tokenPartNumber) {
	return {
		type: ADD_FILE_NOTICE,
		files,
		tokenPartNumber,
	}
}

/**
 * Remove file
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName, tokenPartNumber) {
	return {
		type: REMOVE_FILE_NOTICE,
		fileName,
		tokenPartNumber,
	}
}

/**
 * Remove file
 *
 * @param {*} fileName
 * @returns
 */
function updateDetailItemProperty(detailItem, propertyName) {
	return {
		type: UPDATE_NOTICE_DETAIL_ITEM_PROPERTY,
		detailItem,
		propertyName,
	}
}
/**
 * Remove file
 *
 * @param {*} fileName
 * @returns
 */
function updateQuoteInNoticeOfChange(quoteUpdate) {
	return {
		type: SET_QUOTE_UPDATE_IN_NOTICE_OF_CHANGE,
		quoteUpdate,
	}
}
function isLoadingUpdateQuote(isLoading) {
	return {
		type: IS_LOADING_QUOTE_NOTICE_OF_CHANGE,
		isLoading,
	}
}
/**
 * Get all notice changes by company from backend
 */
export function loadNoticeChangesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingNoticeChanges(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechanges/all`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setNoticesList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingNoticeChanges(false))
				console.error('Error getting the notice changes by company list', error.response)
			})
	}
}

/**
 * Get notice change's count
 *
 * @export
 * @returns
 */
export function getNoticeChangesCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().noticechanges.get('noticechangesCount')
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechanges/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setNoticeChangeCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the notice change's count", error.response)
			})
	}
}

/**
 * Action get notice information data from backend
 */
export function getNoticeChangeInformationDataFromBackend(folio) {
	return function (dispatch, getState) {
		dispatch(startLoadNoticeChange(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechanges`
		let data = {
			Folio: folio,
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CompanyCenter: selectedCompany.get('center'),
			CenterFin: selectedCompany.get('center_fin'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(getInformationNoticeChange(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to get information from notice change', error.response)
				dispatch(startLoadNoticeChange(false))
				return Promise.reject()
			})
	}
}

/**
 * Get and set the notice change in the estate from read from backend
 * @param {*} token
 */
export function loadNoticeChangeFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(startLoadNoticeChange(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechanges/noticechange/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setNoticeChange(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load notice of chanfe', error.response)
				dispatch(startLoadNoticeChange(false))
				return Promise.reject()
			})
	}
}

/**
 * Get the selected item from a notice change details
 * @param {*} state
 */
export const getSelectedNoticeItem = (state) => {
	let notice = state.noticechanges.get('noticechange')
	if (notice) {
		let selectedNoticeItem = notice.get('details').find((detail) => {
			return detail.get('is_selected') === true
		})
		if (selectedNoticeItem) {
			return selectedNoticeItem.toJS()
		} else {
			return null
		}
	} else {
		return null
	}
}

/**
 * Action to update detal part number plane
 * @param {*} detailToken
 * @param {*} planeName
 */
export function updateDetailPartnumberPlaneName(detailToken, planeName) {
	return (dispatch, getState) => {
		dispatch(isUpdatingPlaneName(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partNumbers/plots`
		let data = {
			Name: planeName,
			TokenPartNumber: detailToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateDetailSchematic(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upate plot', error.response)
				dispatch(isUpdatingPlaneName(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to delete plot from detail
 * @param {*} detailToken
 * @param {*} planeName
 */
export function deleteDetailPlaneName(detailToken, planeName) {
	return (dispatch, getState) => {
		dispatch(isDeleteDetailSchematic(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partNumbers/plots/remove`
		let data = {
			Name: planeName,
			TokenPartNumber: detailToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(deleteDetailSchemetic(detailToken))
				}
			})
			.catch((error) => {
				console.error('Error to remove plot in part number', error.response)
				dispatch(isDeleteDetailSchematic(false))
				return Promise.reject()
			})
	}
}

/**
 * Add notification from change notice of change
 * @param {*} memoPartNumber
 * @param {*} supplier
 */
export function addSupplierToChangeNotificationItem(memoPartNumber, supplier) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			ProjectPartNumberToken: memoPartNumber.token,
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			SupplierToken: supplier.token,
			SupplierName: supplier.name,
			SupplierCode: supplier.code,
			SupplierCountry: supplier.country,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechangenotification`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addSupplierInNotieChangeDetails(memoPartNumber.token, serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a notification for a notice change', error.response)
				return Promise.reject()
			})
	}
}

export function addSupplierToChangeNotificationMassiveItem(partNumberTokens, supplier) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,

			ProjectPartNumberTokens: partNumberTokens,

			SupplierToken: supplier.token,
			SupplierName: supplier.name,
			SupplierCode: supplier.code,
			SupplierCountry: supplier.country,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechangenotification/massive`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let responseData = serverResponse.data
					responseData.forEach((item) => {
						dispatch(addSupplierInNotieChangeDetails(item.part_number_token, item))
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a notification for a notice change', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove notification in notice if cange
 */
export function removeSupplierFromChangeNotificationItem(memoPartNumber, supplierToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let selectedCompany = getState().profile.get('selectedCompany')
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechangenotification/${selectedCompany.get(
			'token'
		)}/supplier/${supplierToken}/partNumber/${memoPartNumber.token}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(supplierRemovedFromItemDetails(memoPartNumber.token, supplierToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error removing a supplier from a notice og change', error.response)
				return Promise.reject()
			})
	}
}

export function removeSupplierFromChangeNotificationMassive(partNumberTokens, supplierToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user

		let selectedCompany = getState().profile.get('selectedCompany')
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechangenotification/removeNoticeNotification/massive`
		let data = {
			ProjectPartNumberTokens: partNumberTokens,
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(user, data)
		// /supplier//partNumber/${memoPartNumber.token}
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let responseData = serverResponse.data
					responseData.forEach((item) => {
						dispatch(supplierRemovedFromItemDetails(item.token, supplierToken))
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error removing a supplier from a notice og change', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Add supplier to a notice change and send a quote request
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function addSupplierToNoticeChangeItem(memoPartNumber, supplier) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			ProjectPartNumberToken: memoPartNumber.token,
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			SupplierToken: supplier.supplier_token,
			SupplierName: supplier.supplier_name,
			SupplierNumber: supplier.supplier_code,
			SupplierCountry: supplier.supplier_country,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addQuoteSupplierInNoticeChangeItem(memoPartNumber.token, supplier.supplier_token))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a quote for a notice change', error.response)
				return Promise.reject()
			})
	}
}

export function addSupplierToNoticeChangeItemMassive(partNumberTokens, supplier) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let itemData = []
		partNumberTokens.forEach((partNumberToken) => {
			itemData.push({
				ProjectPartNumberToken: partNumberToken,
				CompanyToken: selectedCompany.get('token'),
				CompanyIdentifier: selectedCompany.get('get_company_identifier'),
				CreatorUserName: user.profile.preferred_username,
				CreateBy: user.profile.name,
				SupplierToken: supplier.token,
				SupplierName: supplier.name,
				SupplierNumber: supplier.code,
				SupplierCountry: supplier.country,
			})
		})

		let data = {
			Items: itemData,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/addmultiplesquotes`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					partNumberTokens.forEach((partNumberToken) => {
						dispatch(addQuoteSupplierInNoticeChangeItem(partNumberToken, supplier.token))
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a quote for a notice change', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Remove a supplier from the notice of change requests
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplierToken
 * @returns
 */
export function removeSupplierFromNoticeChangeItem(memoPartNumber, supplierToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let selectedCompany = getState().profile.get('selectedCompany')
		let folio = getState().noticechanges.get('noticechange').get('folio')
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${selectedCompany.get(
			'token'
		)}/folio/${folio}/supplier/${supplierToken}/partNumber/${memoPartNumber.token}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeQuoteSupplierInNoticeChangeItem(memoPartNumber.token, supplierToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error removing a supplier from a notice og change', error.response)
				return Promise.reject()
			})
	}
}

export function removeSupplierFromNoticeChangeMassive(partNumberTokens, supplierToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let folio = getState().noticechanges.get('noticechange').get('folio')
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			ProjectPartNumberTokens: partNumberTokens,
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			Folio: folio,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(user, data)

		// let folio = getState().noticechanges.get('noticechange').get('folio');
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/deleteMultipleQuotes`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					partNumberTokens.forEach((partNumberToken) => {
						dispatch(removeQuoteSupplierInNoticeChangeItem(partNumberToken, supplierToken))
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error removing a multiple quotes', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Add sample request supplier to a notice of change
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function onAddSampleRequestSupplier(memoPartNumber, quote) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			ProjectPartNumberToken: memoPartNumber.token,
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			SupplierToken: quote.supplier_token,
			SupplierName: quote.supplier_name,
			SupplierNumber: quote.supplier_code,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addSampleRequestSupplierInNoticeChangeItem(memoPartNumber.token, quote.supplier_token))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a sample request for a notice change', error.response)
				return Promise.reject()
			})
	}
}
export function onAddSampleRequestSupplierMassive(partNumberTokens, supplier) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let itemData = []
		partNumberTokens.forEach((partNumberToken) => {
			itemData.push({
				ProjectPartNumberToken: partNumberToken,
				CompanyToken: selectedCompany.get('token'),
				CompanyIdentifier: selectedCompany.get('get_company_identifier'),
				CreatorUserName: user.profile.preferred_username,
				CreateBy: user.profile.name,
				SupplierToken: supplier.token,
				SupplierName: supplier.name,
				SupplierNumber: supplier.code,
				SupplierCountry: supplier.country,
			})
		})

		let data = {
			Items: itemData,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/addmultiplesquotes`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					partNumberTokens.forEach((partNumberToken) => {
						dispatch(addSampleRequestSupplierInNoticeChangeItem(partNumberToken, supplier.token))
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a sample request for a notice change', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Remove sample request supplier to a notice change
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function onRemoveSampleRequestSupplier(memoPartNumber, supplierToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/supplier/${supplierToken}/partNumber/${memoPartNumber.token}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeSampleRequestSupplierInNoticeChangeItem(memoPartNumber.token, supplierToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error remove a sample request for a notice change', error.response)
				return Promise.reject()
			})
	}
}

export function onRemoveSampleRequestSupplierMassive(partNumberTokens, supplierToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			ProjectPartNumberTokens: partNumberTokens,
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(user, data)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/deleteMultipleSampleRequests`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					partNumberTokens.forEach((partNumberToken) => {
						dispatch(removeSampleRequestSupplierInNoticeChangeItem(partNumberToken, supplierToken))
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error remove multiple sample request', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action from send quotes and sample request from notice of change
 */
export function sendQuotesRequestFromNoticeChange() {
	return (dispatch, getState) => {
		dispatch(sendNoticeChangeRequest(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let noticeChangeToken = getState().noticechanges.get('noticechange').get('token')
		let documentIdentifier = selectedCompany.get('document_identifier')
		let data = {}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechangenotification/${noticeChangeToken}/${documentIdentifier}/send`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(sendNoticeChangeRequest(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in send quotes and a sample request from notice of change', error.response)
				dispatch(sendNoticeChangeRequest(false))
				return Promise.reject()
			})
	}
}

/**
 * Update notice of change
 */
export function updateNoticeOfChangeFromBackend() {
	return (dispatch, getState) => {
		dispatch(isSavingNotoceOfChange(true))
		let selectedNoticeOfChange = getState().noticechanges.get('noticeInformation')
		let data = {
			Folio: selectedNoticeOfChange.get('folio'),
			CompanyToken: selectedNoticeOfChange.get('company_token'),
			CompanyIdentifier: selectedNoticeOfChange.get('company_identifier'),
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechanges`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setNoticeChange(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in supdate data notice of change', error.response)
				dispatch(isSavingNotoceOfChange(false))
				return Promise.reject()
			})
	}
}

/**
 * Finalize the proccess fron notice of change
 * @param {*} tokenNotice
 */
export function finalizeNoticeOfChangeFromBackend(tokenNotice) {
	return function (dispatch, getState) {
		dispatch(isSavingNotoceOfChange(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/projectdocument/closedproject/${tokenNotice}`

		return axios
			.put(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(finalizeNoticeOfChange())
				}
			})
			.catch((error) => {
				console.error('Error to finalize notice of change', error.response)
				dispatch(isSavingNotoceOfChange(false))
				return Promise.reject()
			})
	}
}

/**
 * Get and set the notice change in the estate from read from backend
 * @param {*} token
 */
export function loadNotificationNoticeFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(startLoadNotificationNotice(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechangenotification/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(getInformationNotificationNotice(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load notice of chanfe', error.response)
				dispatch(startLoadNotificationNotice(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to respond Notice Of Change By Supplier
 */
export function respondNoticeOfChangeBySupplier(token, effectiveDate, inventory, measurementUnit) {
	return (dispatch, getState) => {
		dispatch(isSavingNotoceOfChange(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Token: token,
			EffectiveDate: effectiveDate,
			Inventory: inventory,
			MeasurementUnit: measurementUnit,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechangenotification/responsebyclient`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingNotoceOfChange(false))
					dispatch(getInformationNotificationNotice(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingNotoceOfChange(false))
				console.error('Error in respond Notice Of Change By Supplier ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add comment on part number in list
 *
 * @export
 * @param {*} item
 * @param {*} comments
 * @returns
 */
export function onAddComment(item, comments, requieredPlotArt) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			Description: comments,
			PartNumberToken: item.token,
			RequieredPlotArt: requieredPlotArt,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/project`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateComments(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a sample request for a notice change', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to upload files
 *
 * @export
 * @param {*} files
 * @returns
 */
export function onAddFiles(files, tokenPartNumber) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumbers/upload/${tokenPartNumber}`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse.data, tokenPartNumber))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error to upload file(s) in material delivery', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files
 *
 * @export
 * @param {*} fileName
 * @returns
 */
export function onRemoveFiles(fileName, tokenPartNumber) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/remove/${tokenPartNumber}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFile(fileName, tokenPartNumber))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Update details item Stage Property
 *
 * @export
 * @param {*} partNumberToken
 * @param {*} stage
 * @returns
 */
export function updateDetailPartNumberStage(partNumberToken, stage) {
	return (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/project/noticechanges/${partNumberToken}`
		let data = {
			ChangeType: stage,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateDetailItemProperty(serverResponse.data, 'change_type'))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upate stage', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Update details item Stage Property
 *
 * @export
 * @param {*} partNumberToken
 * @param {*} stage
 * @returns
 */
export function toggleNoticeOfChangePartNumberActivation(partNumberToken) {
	return (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/project/noticechanges/${partNumberToken}/active`
		let data = {}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateDetailItemProperty(serverResponse.data, 'is_hidden'))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upate stage', error.response)
				return Promise.reject()
			})
	}
}

/**
 *Action to download report notice change by range date
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadNoticeChangeReportByRangeDate(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(startLoadNoticeChange(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/noticechanges/downloadByRangeDate`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(startLoadNoticeChange(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reportes_de_avisos_de_cambio.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(startLoadNoticeChange(false))
				console.error('Error downloading notice change report by month', error.response)
				return Promise.reject()
			})
	}
}
/**
 * Accept or Reject quite by supplier
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function acceptRejectQuoteFromNoticeChange(quoteToken, comments, statusQuote, statusQuoteNumber) {
	return (dispatch, getState) => {
		dispatch(isLoadingUpdateQuote(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			Status: statusQuote,
			StatusEnum: statusQuoteNumber,
			Comments: comments,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/allAcceptReject`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateQuoteInNoticeOfChange(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in rejected quote ', error.response)
				return Promise.reject()
			})
	}
}
