/** react imports section */
import React, { useEffect } from 'react'
import { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

/** Material UI imports section */
import { IconButton, Typography, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'

/**Common imports section */
import Toaster from '../../../common/Toaster'
import ThereIsNoInformation from '../../common/ThereIsNoInformation'

import CarrierInformationPanel from './CarrierInformationPanel'
import CarrierInformation from './CarrierInformation'

import { addCarrierInformation, editProposalInformation } from '../../../../store/transportTender/transportTenderAction'
import {
	getIsLoadingSavingSelector,
	getTransportTenderfilterSelector,
} from '../../../../store/transportTender/transportTenderSelector'
import { Translate } from 'react-localize-redux'
import { CarrierStatus } from '../../../../store/helpers/AppConstants'

const CarrierSupplierView = (props) => {
	const { classes, transportTenderdetail } = props
	const dispatch = useDispatch()
	const userProfile = useSelector((state) => state.oidc.user)

	const [openPanel, setOpenPanel] = useState(false)
	const [openEditPanel, setOpenEditPanel] = useState(false)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: '',
		toasterVariant: '',
	})
	const transportTenderinfo = useSelector((state) => getTransportTenderfilterSelector(state))

	const [information, setInformation] = useState({
		transportationAvailability: false,
		driverName: '',
		driverPhoneNumber: '',
		bearingPlates: '',
		comments: '',
		newAmount: 0,
		economicUnit: '',
		platesBox: '',
		economicalBox: '',
		league: '',
		carrierBiddingCode: '',
		existTrackLink: true,
	})
	const [phoneNumber, setPhoneNumber] = useState('')
	const [plates, setPlates] = useState('')
	const [platesBox, setPlatesBox] = useState('')
	const [isSave, setIsSave] = useState(false)
	const isTypeTransportTrailer = transportTenderdetail.IsTypeTransportTrailer
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : false)
	const isEditable =
		transportTenderdetail &&
		transportTenderinfo.carrierInformation &&
		(transportTenderinfo.carrierInformation.Status === CarrierStatus.DRAFT ||
			transportTenderinfo.carrierInformation.Status === CarrierStatus.EVALUATION) &&
		!isInternalUser()
	const isLoading = useSelector((state) => getIsLoadingSavingSelector(state))
	let userName = userProfile && userProfile.profile ? userProfile.profile.company_code : ''
	let allProposalFromSuppliers = transportTenderdetail.Proposals.filter((x) => x.CarrierBidding.Code === userName)
	let canAddCarrier =
		allProposalFromSuppliers.length === 0 &&
		transportTenderdetail &&
		transportTenderdetail.Status === 2 &&
		!transportTenderinfo.carrierInformation

	const onSubmit = () => {
		dispatch(addCarrierInformation(information, userProfile.profile.company_code, transportTenderdetail.Id))
			.then(() => {
				''
			})
			.catch((err) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'Error al guardar el reparto',
					toasterVariant: 'error',
				})
			})
			.finally(() => {
				setOpenPanel(false)
			})
	}
	const onEditProposal = () => {
		dispatch(editProposalInformation(information, userProfile.profile.company_code, transportTenderdetail.Id))
			.then(() => {
				''
			})
			.catch((err) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'Error al guardar la información del transportista',
					toasterVariant: 'error',
				})
			})
			.finally(() => {
				setOpenEditPanel(false)
			})
	}

	const convertirClavesAMinusculas = (objeto) => {
		var objetoConvertido = {}
		for (var clave in objeto) {
			if (objeto.hasOwnProperty(clave)) {
				var primeraLetraMinuscula = clave.charAt(0).toLowerCase() + clave.slice(1)
				objetoConvertido[primeraLetraMinuscula] = objeto[clave]
			}
		}
		return objetoConvertido
	}
	useEffect(() => {
		if (transportTenderinfo && transportTenderinfo.carrierInformation) {
			const informationEditPanel = convertirClavesAMinusculas(transportTenderinfo.carrierInformation)
			setInformation(informationEditPanel)
		}
	}, [transportTenderinfo])
	const onValidate = () => {
		if (information.driverName.length < 4) return false
		const driverPhoneNumber = information.driverPhoneNumber.replace(/\D/g, '')
		const isPhoneNumber = /^\d{10}$/.test(driverPhoneNumber)
		if (!isPhoneNumber) return false
		if (information.bearingPlates.length < 3) return false
		if (isTypeTransportTrailer) {
			if (information.economicUnit.length < 3) return false
			if (information.economicalBox.length < 3) return false
			if (information.platesBox.length < 3) return false
		}
		if (information.league.length < 5) return false
		return true
	}
	useEffect(() => {
		let isValid = onValidate()
		setIsSave(isValid)
	}, [information])
	return (
		<div className={classes.container}>
			<div className={classes.titleContainer}>
				<Typography gutterBottom variant='body2' component='p'>
					<strong>
						<Translate id={'carrierTenders.carrierInformation.fillTheInformationRequired'} />:
					</strong>
				</Typography>
				{canAddCarrier && !transportTenderdetail.HasExpired && (
					<div className={classes.addIconContainer}>
						<Typography variant='body2' display='block'>
							<Translate id={'carrierTenders.carrierInformation.fillInformation'} />:
						</Typography>{' '}
						{!(transportTenderinfo && transportTenderinfo.carrierInformation) && (
							<IconButton className={classes.addIcon}>
								<AddIcon onClick={() => setOpenPanel(true)} />
							</IconButton>
						)}
					</div>
				)}
			</div>
			{!transportTenderinfo.carrierInformation && <ThereIsNoInformation />}
			{transportTenderinfo.carrierInformation && (
				<CarrierInformation
					carrierInformation={transportTenderinfo.carrierInformation}
					isTypeTransportTrailer={isTypeTransportTrailer}
					isEditable={isEditable}
					// isEditable={isEditable && !transportTenderdetail.HasExpired}
					openEditProposalInformation={setOpenEditPanel}
				/>
			)}
			{openPanel && (
				<CarrierInformationPanel
					information={information}
					setInformation={setInformation}
					onClose={() => setOpenPanel(false)}
					onSubmit={onSubmit}
					transportTenderinfoDetail={transportTenderinfo.detail}
					phoneNumber={phoneNumber}
					setPhoneNumber={setPhoneNumber}
					plates={plates}
					setPlates={setPlates}
					platesBox={platesBox}
					setPlatesBox={setPlatesBox}
					isTypeTransportTrailer={isTypeTransportTrailer}
					isLoading={isLoading}
					isSave={isSave}
				/>
			)}
			{openEditPanel && transportTenderinfo && transportTenderinfo.carrierInformation && (
				<CarrierInformationPanel
					information={information}
					setInformation={setInformation}
					onClose={() => setOpenEditPanel(false)}
					onSubmit={onEditProposal}
					transportTenderinfoDetail={transportTenderinfo.detail}
					phoneNumber={phoneNumber}
					setPhoneNumber={setPhoneNumber}
					plates={plates}
					setPlates={setPlates}
					platesBox={platesBox}
					setPlatesBox={setPlatesBox}
					isTypeTransportTrailer={isTypeTransportTrailer}
					isLoading={isLoading}
					isSave={isSave}
				/>
			)}
			<Toaster
				message={toasterMessageConfig.toasterMessage}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</div>
	)
}

const styles = (theme) => ({
	container: {
		margin: '10px',
	},
	titleContainer: {
		display: 'grid',
		gridTemplateColumns: '5fr 1fr',
		gridGap: '10px',
	},
	addIcon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		margin: '0px 5px 0px 10px',
		width: '30px',
		height: '30px',
		display: 'flex',
		justifyContent: 'center',
	},
	addIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
})
export default withRouter(withStyles(styles, { withTheme: true })(CarrierSupplierView))
