import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import userManager from '../store/helpers/UserManager'
/** Language imports **/
import { Translate } from 'react-localize-redux'
import notautorizerImg from '../resources/images/not_autorizer.png'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { CardHeader, Typography } from '@material-ui/core'

/** Import section actions */

/**
 * Supplier list
 *
 * @class Suppliers list
 * @extends {Component}
 */
class Unauthorized extends Component {
	/**
	 * Create an instance of suppliers list
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoadingUser !== this.props.isLoadingUser && this.props.isLoadingUser === false) {
		}
	}

	/**
	 * Login request
	 *
	 */
	onLogoutRequest() {
		localStorage.removeItem('relativeUrl')
		userManager.signoutRedirect()
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof Supplier
	 */
	render() {
		return (
			<React.Fragment>
				<Card>
					<CardHeader
						title={
							<>
								<Typography variant='h5' align='center'>
									<Translate id={'common.notAuthorizer'} />
									<Button
										variant='contained'
										style={{ color: '#495057', backgroundColor: '#ccc', left: '10px' }}
										onClick={() => this.onLogoutRequest()}
									>
										<strong>
											{' '}
											<Translate id={'common.toReturn'} />
										</strong>
									</Button>
								</Typography>
								<Typography variant='h6' align='center' color='primary'>
									{'Si eres proveedor de PTM favor de ingresar a la siguiente liga '}
									<a style={{ color: '#e57373' }} href='https://sip.ptm.mx/v2.0/portal/'>
										{':'}
										https://sip.ptm.mx/v2.0/portal/{' '}
									</a>
								</Typography>
							</>
						}
					/>
					<CardContent>
						<img src={notautorizerImg} alt='Logo2' style={{ height: '85vh', marginLeft: '60vh' }} />
					</CardContent>
				</Card>
			</React.Fragment>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {}
}

const styles = (theme) => ({})

export default withRouter(
	withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Unauthorized))
)
