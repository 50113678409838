import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';

import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import ReworkIcon from '@material-ui/icons/SettingsBackupRestore';
import { Translate } from 'react-localize-redux';
import { createSacQuality } from '../../store/sacsQuality/SacsQualityActions';
import Toaster from '../common/Toaster';
import FormView from './components/FormView';

class Create extends Component {

    /**
     *Creates an instance of Create.
     * @param {*} props
     * @memberof Create
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            creationDate: moment().format('DD/MM/YYYY'),
        }
    }

    render() {
        return (
            <Card>
                <CardHeader
                    avatar={<ReworkIcon />}
                    title={<Typography variant="button" ><Translate id="sacsQuality.titleSac" /></Typography>}
                    subheader={<Typography variant="caption" color="textPrimary"><Translate id="common.createBy" /> {this.props.user.profile.name}
                        <br /><Translate id="common.creationDate" />: {this.state.creationDate}</Typography>}
                />
                <CardContent>
                    <FormView
                        {...this.props}
                        isSaving={this.props.isSaving}
                    />
                </CardContent>

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Card>
        );
    }
}


/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    return {
        user: user,
        isSaving: state.sacsQuality.get('isSaving'),
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        createSacQuality: (data) => {
            return dispatch(createSacQuality(data))
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create))));



