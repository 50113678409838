/**Import react section */
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import moment from 'moment'
import { Translate } from 'react-localize-redux'
/** Material UI import section */
import {
    TextField, Grid, Avatar
} from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import RequestIcon from '@material-ui/icons/Assistant';
/** Resources import section */
import AvatarContainer from '../AvatarContainer'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';
import { canBeAddAnswer } from '../../../../store/helpers/BiddingHelper';
import { COLOR_STATUS_WARNING } from '../../../../store/helpers/StatusColorConstants';

const StepLabelQuestion = props => {
    const { classes, responsible, userName,
        name, creationDate, question,
        canBeEdited, questionId, onAddAnswer, isSaving,
        bidding, isCurrentActiveDirectoryUser, preferred_username,
        isExternalUser
    } = props
    const [binddingModel, setBinddingModel] = useState({
        answer: "",
    });

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setBinddingModel({
            ...binddingModel,
            [name]: value
        });
    }

    /**Verify if can be sabed data*/
    const canBeSavedQuestion = () => {
        if (IsNullOrEmpty(binddingModel.answer)) return false
        return true;
    }

    const onSave = () => {
        if (props.onAddAnswer) {
            binddingModel.questionId = questionId
            binddingModel.biddingId = bidding.Id
            onAddAnswer(binddingModel)
                .then(() => {
                    setBinddingModel({
                        answer: ""
                    })
                })
        }
    }
    let isVisibleUserName = isCurrentActiveDirectoryUser ? true : (preferred_username ===userName) 
    let isVisibleUserNameExtenalUser = isExternalUser&& userName.includes('@') 
    let isEqualUserName = (preferred_username ===userName )
    return (
        <div>
            <div className={classes.flex}>
            {
                (isEqualUserName ? !isEqualUserName: !isVisibleUserNameExtenalUser)  ? <AvatarContainer
                        responsible={responsible}
                        isVisibleTooltip={isVisibleUserName}
                    /> :  <Avatar className={classes.avatarYellow}>
                        <RequestIcon/>
                    </Avatar>
            }
                <div>
                    {(isVisibleUserName ? isVisibleUserName: isVisibleUserNameExtenalUser) &&  <Typography color="primary" className={classes.marginLeft}>{`${userName} | ${name}`}</Typography>}
                    <Typography className={classes.marginLeft}>{question}</Typography>

                </div>
                <div style={{ flexGrow: 1 }} />
                <div className={classes.flex}>
                    <CalendarIcon
                        color="primary"
                    />
                    <Typography color="primary" className={classes.marginLeft}>{creationDate ? moment(creationDate).format('DD/MM/YYYY') : ""}</Typography>

                    <AccessTimeIcon
                        color="primary"
                        className={classes.marginLeft}
                    />
                    <Typography color="primary" className={classes.marginLeft}>{creationDate ? moment(creationDate).format('HH:mm') : ""}</Typography>

                </div>
            </div>
            {
                canBeEdited && <Grid container>
                    <Grid item xs={11}>
                        <TextField
                            fullWidth
                            label={<Translate id="biddingsModule.answer" />}
                            className={classes.textField}
                            name='answer'
                            onChange={onPropertyChange}
                            color="primary"
                            disabled={isSaving||!canBeAddAnswer(bidding)}
                            value={binddingModel ? binddingModel.answer : ""}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            onClick={onSave}
                            disabled={!canBeSavedQuestion() || isSaving || !canBeAddAnswer(bidding)}
                        >
                            <SendIcon color='primary' />
                        </IconButton>
                    </Grid>
                </Grid>

            }
        </div>

    )
}

const useStyle = ((theme) => ({
    containerBtn: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center"
    },
    btnSave: {
        maxHeight: "50px"
    },
    flex: {
        display: "flex"
    },
    marginLeft: {
        marginLeft: "10px"
    },
    avatarYellow:{
        backgroundColor: COLOR_STATUS_WARNING
    }
}))

StepLabelQuestion.propTypes = {
    isSaving: PropTypes.bool.isRequired
}

StepLabelQuestion.defaultProps = {
    isSaving: false
}
export default withRouter(withStyles(useStyle, { withTheme: true })(StepLabelQuestion))