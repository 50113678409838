
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Fab, Divider } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import AcceptIcon from '@material-ui/icons/ThumbUp';
import RejectIcon from '@material-ui/icons/ThumbDown';
import Edit from '@material-ui/icons/Edit';
import DevolutionIcon from '@material-ui/icons/ReplyAllOutlined';
import ReworkIcon from '@material-ui/icons/AutorenewOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SacsQualityIcon from '@material-ui/icons/Report';
import DeliveryOrdersIcon from '@material-ui/icons/Assignment';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

/** section import components */
import DataTable from '../../common/DataTable';
import OptionsMenu from '../../common/OptionsMenu';

/** section import actions */
import CorrectiveActionCommentsDialog from './CorrectiveActionCommentsDialog';
import { APPROVAL_ACEPTED, APPROVAL_REJECTED } from '../../../store/helpers/ResourcesHelper';
import { IsCurrentNotActiveDirectoryUser, IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper';
import { COLOR_STATUS_ACEPTED, COLOR_STATUS_REJECTED, COLOR_STATUS_LIGHT } from '../../../store/helpers/StatusColorConstants';
import { COMPANY_PTM } from '../../../store/helpers/ResourcesHelper';

/**
 * Component ot render Information partial quality report failure
 */
export default class InformationPartial extends Component {

    /**
     * Create an instance QualityFailureReport
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            itemToken: null,
            openModalAccept: false,
            openModalCancel: false,
            orderDelivery: {folio: "", token: "", message: ""},
        };
    }


    /**
     * Handle click to open options menu
     *
     * @param {*} itemToken
     * @memberof DetailsCard
     */
    openMenuOptions(itemToken) {
        this.setState({
            itemToken,
        });
    };

    /**
     * Handle click to close options menu
     *
     * @memberof DetailsCard
     */
    closeMenuOptions() {
        if (!this.state.openModalDecline && !this.state.openModalTooling
            && !this.state.openModalCubicaje && !this.state.openModalAddScales) {
            this.setState({
                itemToken: ''
            });
        }
    };

    /**
     * Handle open decline, tooling or cubicaje item modal
     *
     * @param {*} dialogId
     * @memberof DetailsCard
     */
    handleOpenItemDialog(dialogId, item, status) {
        this.setState({
            item: {
                ...item,
                status
            }
        },
            () => {
                this.props.handleOpenCloseItemDialog(dialogId)
            }
        );
    }

    /**
     * Render emergent action 
     * @param {*} dataSource 
     * @param {*} item 
     */
    onRenderEmergentAction(dataSource, item) {
        if (dataSource === "token") {
            return (<span>
                {this.props.qualityFailure.can_delete_emergent_action && IsCurrentActiveDirectoryUser(this.props.currentUser) &&
                    <IconButton edge="end" aria-label="Delete" onClick={() => { this.props.removeEmergentActionToQualityFailureReport(item.token) }} >
                        <Tooltip title={<Translate id="common.delete" />}>
                            <DeleteOutlinedIcon color="error" />
                        </Tooltip>
                    </IconButton>}
            </span>)
        }
    }

    onRenderOptionsMenuItemDocument(item, optionToShow) {
        var options = [];
        if (IsCurrentActiveDirectoryUser(this.props.currentUser) && this.props.qualityFailure.can_delete_emergent_action
            && (item.status !== APPROVAL_ACEPTED && item.status !== APPROVAL_REJECTED)) {
            if (optionToShow === 1)
                options.push({
                    itemClick: () => this.handleOpenItemDialog('showActionRequestCommentsDialog', item, APPROVAL_ACEPTED),
                    tooltipTranslation: <Translate id="common.accept" />,
                    menuItemIcon: <AcceptIcon color="secondary"/>
                })
            if (optionToShow === 2)
                options.push({
                    itemClick: () => this.handleOpenItemDialog('showActionRequestCommentsDialog', item, APPROVAL_REJECTED),
                    tooltipTranslation: <Translate id="common.reject" />,
                    menuItemIcon: <RejectIcon color='error' />
                })
        }
        if (IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.props.qualityFailure.can_delete_emergent_action
            && item.status !== APPROVAL_ACEPTED) {
            if (optionToShow === 1)
                options.push({
                    itemClick: () => this.props.handleOpenCorrectiveActionDialog('openModalAddCorrectiveAction', item),
                    tooltipTranslation: <Translate id="common.edit" />,
                    menuItemIcon: <Edit />
                })
        }
        if (options.length > 0) {
            return (
                <OptionsMenu
                    itemToken={item.token}
                    handleClickOptions={this.openMenuOptions}
                    open={this.state.itemToken === item.token}
                    handleClickAway={this.closeMenuOptions}
                    options={options}
                />
            )
        }
    }
    onRenderItemDocument(dataSource, item) {
        if (dataSource === "document") {
            let doc = `${process.env.REACT_APP_SIP_QUALITY_URL_FILES}Documents/FailureReportActionCorrectiveBySupplier/${encodeURIComponent(item.document)}`;            
            return (
                <Link 
                    component={'a'} 
                    target="_blank"
                    href={doc}>
                    {item.document}
                </Link>                
            )
        }
        if (dataSource === "options") {
            return (<React.Fragment>
                {this.onRenderOptionsMenuItemDocument(item, 1)}
                {this.onRenderOptionsMenuItemDocument(item, 2)}
            </React.Fragment>)
        }
    }

    /**
     * Onrender items deriver workflows
     */
    onRenderItemDeriverdWorkFlows(dataSource, item) {
        if (dataSource === "workflow_type") {
            if (item.workflow_type === 3) {
                return (<Typography color="primary" gutterBottom><ReworkIcon /> {item.folio}</Typography>)
            }
            if (item.workflow_type === 6) {
                return (<Typography color="primary" gutterBottom><DevolutionIcon /> {item.folio}</Typography>)
            }
            if (item.workflow_type === 4) {
                return (<Typography color="primary" gutterBottom><SacsQualityIcon /> {item.folio}</Typography>)
            }
        }
    }

    /**
     * Set Custom background color foreach detail row
     *
     * @memberof DetailsCard
     */
    setCustomColorRow() {
        this.props.qualityFailure.corrective_actions.map((item) => {
            var customColor;
            if (item.status === APPROVAL_ACEPTED)
                customColor = COLOR_STATUS_ACEPTED
            if (item.status === APPROVAL_REJECTED)
                customColor = COLOR_STATUS_REJECTED
            return item.custom_color = customColor;
        });
    }

        /**
     * Function to verify ext certificado calidat to show
     * @param {*} supplierToken 
     * @param {*} partNumber 
     * @param {*} lote 
     */
    onVerifExistCertificate(supplierToken, partNumber, lote){
        this.setState({orderDelivery: {folio: "", token: "", message: ""}})
        if(this.props.getDeliveryOrderByPartNumberCertificate){
            this.props.getDeliveryOrderByPartNumberCertificate(supplierToken,partNumber, lote)
            .then((result) => {
                if(result){
                    this.setState({orderDelivery: {folio: result.folio, token: result.token, message: ""}})
                }
                else{
                    this.setState({orderDelivery: {folio: "", token: "", message: "No existe certificado de calidad para este reporte de falla con este proveedor"}})
                }
            }).catch(() => {
                this.setState({orderDelivery: {folio: "", token: "", message: "No existe certificado de calidad para este reporte de falla con este proveedor"}})
            });
        }
    }

    /**
     * Render
     */
    render() {
        const { classes } = this.props;
        let qualityFailure = this.props.qualityFailure;
        this.setCustomColorRow();
        return (
            <Paper> <Divider />
                <List>
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="common.supplier" />
                                    </Typography>
                                    {": "}{qualityFailure.full_supplier}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="common.partNumber" />
                                    </Typography>
                                    {": "}{qualityFailure.full_part_description}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="common.preparedBy" />
                                    </Typography>
                                    {": "}{qualityFailure.user_name_report}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="qualityfailurereport.qualityInPlant" />
                                    </Typography>
                                    {": "}<Chip component="span" size="small" color="secondary" label={qualityFailure.quantity_of_material_in_invoice} /> {" | "}
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="qualityfailurereport.qualityReport" />
                                    </Typography>
                                    {": "}<Chip component="span" size="small" color="secondary" label={qualityFailure.quantity_of_material_affected} />
                                </React.Fragment>
                            } />
                    </ListItem>
                    {qualityFailure && qualityFailure.company_identifier === COMPANY_PTM &&
                        <ListItem divider>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                            {"Lote"}
                                        </Typography>
                                        {": "}{qualityFailure.lot_material} {" | "}
                                        <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                            {"Número de parte del cliente"}
                                        </Typography>
                                        {": "}{qualityFailure.customer_part_number}
                                    </React.Fragment>
                                } />
                        </ListItem>
                    }
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="qualityfailurereport.failure" />
                                    </Typography>
                                    {": "}{qualityFailure.failure_non_conformity && qualityFailure.failure_non_conformity.name}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary={<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                            <strong><Translate id="qualityfailurereport.affect" /></strong>
                        </Typography>}
                            secondary={
                                <React.Fragment>
                                    {qualityFailure.functionality_dimension && <span>{" — "}<Translate id="qualityfailurereport.functionalityDimension" /><br /></span>}
                                    {qualityFailure.functionality_specification && <span>{" — "}<Translate id="qualityfailurereport.functionalitySpecification" /><br /></span>}
                                    {qualityFailure.functionality_packaging && <span>{" — "}<Translate id="qualityfailurereport.functionalityPackaging" /><br /></span>}
                                    {qualityFailure.functionality_misidentified && <span>{" — "}<Translate id="qualityfailurereport.functionalityMisidentified" /><br /></span>}
                                    {qualityFailure.functionality && <span>{" — "}<Translate id="qualityfailurereport.functionality" /><br /></span>}
                                    {qualityFailure.functionality_assembly && <span>{" — "}<Translate id="qualityfailurereport.functionalityAssembly" /><br /></span>}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="qualityfailurereport.problemDescription" />
                                    </Typography>
                                    {": "}{qualityFailure.problem_description}
                                </React.Fragment>
                            } />
                    </ListItem>
                    {qualityFailure && qualityFailure.company_identifier === COMPANY_PTM &&
                     <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        {"Verificar si existe certificado de calidad: "}
                                    </Typography>
                                    <Fab
                                        variant="extended"
                                        size="small"
                                        component="a"
                                        target="_blank"
                                        style={{ margin: '0.5em 1%', color: '#fff', backgroundColor: COLOR_STATUS_LIGHT }}
                                        onClick={() => { this.onVerifExistCertificate(qualityFailure.supplier_token, qualityFailure.part_number, qualityFailure.lot_material) }}
                                        disabled ={this.props.isLoadingCertificate} 
                                        >
                                        <DoneIcon style={{  color: '#fff', backgroundColor: COLOR_STATUS_LIGHT }} />
                                        <Typography style={{color: '#fff', fontSize: "0.65rem" }} variant="caption"> {"Obtener certificado"}</Typography>
                                    </Fab>

                                    {this.state.orderDelivery.token && <Link color="primary"
                                        onClick={() => this.props.history.push(`/planning/deliveryorder/${this.state.orderDelivery.token}`)} to="#">
                                             {" "}  <DeliveryOrdersIcon /> {" Folio: "}{this.state.orderDelivery.folio}
                                    </Link>}
                                    {this.state.orderDelivery.message &&
                                       <span>{" "} { this.state.orderDelivery.message }</span>
                                    }
                                </React.Fragment>
                            }/>
                    </ListItem>
                    }
                </List>

                {/* List from emergent Actions  */}
                {qualityFailure.emergent_actions.length > 0 && <span><br />
                    <Typography gutterBottom variant="body2"><strong>{<Translate id="qualityfailurereport.emergentActions" />}</strong>  </Typography>
                    <DataTable
                        data={qualityFailure.emergent_actions}
                        configuration={TableEmergentActionsConfiguration}
                        onRenderCellItem={this.onRenderEmergentAction}
                    />
                </span>
                }

                {/* List from corrective Actions  */}
                {qualityFailure.corrective_actions.length > 0 && <span><br />
                    <Typography gutterBottom variant="body2"><strong>{<Translate id="qualityfailurereport.correctiveActionSupplier" />}</strong>  </Typography>
                    <DataTable
                        data={qualityFailure.corrective_actions}
                        configuration={TableConfigurationCorrectiveActions}
                        onRenderCellItem={this.onRenderItemDocument}
                    />
                </span>
                }

                {/* List from corrective Actions  */}
                {qualityFailure.derived_workflows.length > 0 && <span><br />
                    <Typography gutterBottom variant="body2"><strong>{<Translate id="qualityfailurereport.trackingReport" />}</strong>  </Typography>
                    <DataTable
                        data={qualityFailure.derived_workflows}
                        configuration={TableDerivedWorkflows}
                        onRenderCellItem={this.onRenderItemDeriverdWorkFlows}
                        onRowClick={(item) => {
                            if (item.workflow_type === 3) {
                                this.props.history.push(`/reworks/${item.token}`)
                            }
                            else if (item.workflow_type === 6) {
                                this.props.history.push(`/devolutions/${item.token}`)
                            }
                            else if (item.workflow_type === 4) {
                                this.props.history.push(`/sacsquality/${item.token}`)
                            }
                        }}
                    />
                </span>
                }

                <CorrectiveActionCommentsDialog
                    isSaving={this.props.isSaving}
                    open={this.props.open}
                    onClose={() => this.props.handleOpenCloseItemDialog('showActionRequestCommentsDialog')}
                    item={this.state.item}
                    saveComment={this.props.onAcceptCorrectiveAction}
                    classes={this.props.classes}
                />
            </Paper>
        );
    }
}

const TableEmergentActionsConfiguration = {
    columns: [{
        header: <Translate id="common.creationDate" />,
        dataSource: 'creation_date'
    }, {
        header: <Translate id="common.createBy" />,
        dataSource: 'create_by'
    }, {
        header: <Translate id="qualityfailurereport.descriptionEmergentAction" />,
        dataSource: 'description'
    }, {
        header: "",
        dataSource: 'token'
    }]
}

const TableConfigurationCorrectiveActions = {
    columns: [{
        header: <Translate id="common.creationDate" />,
        dataSource: 'creation_date'
    }, {
        header: <Translate id="common.createBy" />,
        dataSource: 'create_by'
    }, {
        header: <Translate id="qualityfailurereport.descriptionCorrectiveAction" />,
        dataSource: 'description'
    }, {
        header: <Translate id="common.document" />,
        dataSource: 'document'
    }, {
        header: <Translate id="common.comments" />,
        dataSource: 'quality_comments'
    }, {
        header: '',
        dataSource: 'options'
    }]
}

const TableDerivedWorkflows = {
    columns: [{
        header: <Translate id="common.folio" />,
        dataSource: 'workflow_type'
    }, {
        header: <Translate id="common.creationDate" />,
        dataSource: 'creation_date'
    }, {
        header: <Translate id="common.createBy" />,
        dataSource: 'create_by'
    }, {
        header: <Translate id="common.supplier" />,
        dataSource: 'full_supplier'
    }]
}

/**
 * qualityFailure
 */
InformationPartial.propTypes = {
    qualityFailure: PropTypes.object.isRequired
}