import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
/** Material UI imports section */
import { Chip, Typography, withStyles } from '@material-ui/core'
import { CheckCircleOutline, Warning } from '@material-ui/icons'
import moment from 'moment'
import { getManeuverTemplateByNumber, getManeuverTypeByNumber } from '../../../../store/helpers/TransportTenderHelper'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import RequierementClientDialog from './requieremetClientDialog'

const ViewDealsSupplier = (props) => {
	const { classes, transportTenderdetail } = props
	const [openWarningClient, setOpenWarningClient] = useState(false)
	const [repartSelect, setRepartSelected] = useState(null)
	// const destinations = transportTenderdetail.Destinations
	const isShowManeuver =
		transportTenderdetail &&
		transportTenderdetail.Destinations.filter((destinations) => destinations.isManeuvers).length > 0
	const isShowAppointment =
		transportTenderdetail &&
		transportTenderdetail.Destinations.filter(
			(destination) => destination.boardingAppointmentDate && destination.boardingAppointmentDate.length > 0
		).length > 0
	// const isShowManeuver = false
	// const isShowAppointment = false
	const onWarningClient = (client) => {
		setOpenWarningClient(true)
		setRepartSelected(client)
	}
	const isShowRequirement =
		transportTenderdetail &&
		transportTenderdetail.Destinations.some((deal) =>
			deal && deal.client && deal.client.CustomerRequirements
				? deal.client.CustomerRequirements.length > 0
				: false
		)

	console.log('transportTenderdetail', transportTenderdetail)
	return (
		<div className={classes.detalsContent}>
			<div className={classes.tableContent}>
				<Table className={classes.table} size='small' aria-label='a dense table'>
					<TableHead>
						<TableRow className={classes.headerTable}>
							{isShowRequirement && (
								<TableCell padding='none' className={classes.cellHeaderFixedSize}>
									<Typography
										className={classes.titleHeader}
										variant={'subtitle2'}
										gutterBottom
										component='p'
									>
										<Translate id={'carrierTenders.deals.requirementsCarriers.title'} />
									</Typography>
								</TableCell>
							)}

							<TableCell
								padding='none'
								style={{
									paddingLeft: 0,
									paddingRight: 0,
									width: 100,
									maxWidth: 150,
								}}
							>
								<Typography
									className={classes.titleHeader}
									variant={'subtitle2'}
									gutterBottom
									component='p'
								>
									<Translate id={'carrierTenders.deals.client'} />
								</Typography>
							</TableCell>
							<TableCell
								padding='none'
								style={{
									paddingLeft: 0,
									paddingRight: 0,
									width: 250,
									maxWidth: 250,
								}}
							>
								{' '}
								<Typography
									className={classes.titleHeader}
									variant={'subtitle2'}
									gutterBottom
									component='p'
								>
									<Translate id={'carrierTenders.deals.zone'} />
								</Typography>
							</TableCell>
							<TableCell
								padding='none'
								style={{
									paddingLeft: 0,
									paddingRight: 0,
									width: 150,
									maxWidth: 150,
								}}
							>
								<Typography
									className={classes.titleHeader}
									variant={'subtitle2'}
									gutterBottom
									component='p'
								>
									<Translate id={'carrierTenders.deals.quantityEquipment'} />
								</Typography>
							</TableCell>
							{isShowManeuver && (
								<TableCell padding='none' className={classes.cellHeader}>
									{' '}
									<Typography
										gutterBottom
										className={classes.titleHeader}
										variant={'subtitle2'}
										component='p'
									>
										<Translate id={'carrierTenders.deals.maneuver'} />
									</Typography>
								</TableCell>
							)}
							{isShowAppointment && (
								<TableCell padding='none' className={classes.cellHeader}>
									<Typography className={classes.titleHeader} variant={'subtitle2'}>
										<Translate id={'carrierTenders.deals.appointment'} />
									</Typography>
								</TableCell>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{transportTenderdetail &&
							transportTenderdetail.Destinations.map((deal, index) => {
								// return destinationGroup.destinations.map((deal) => {
								const haveClientRequirements =
									deal && deal.client && deal.client.CustomerRequirements
										? deal.client.CustomerRequirements.length > 0
										: false
								return (
									<TableRow key={index} className={classes.tableRow}>
										{haveClientRequirements && (
											<TableCell className={classes.cellHeaderFixedSize}>
												<Chip
													icon={<Warning className={classes.colorWarning} fontSize='small' />}
													onClick={() => onWarningClient(deal.client)}
													className={classes.chipWarningContent}
													label={
														<Typography className={classes.colorWarning} variant='caption'>
															<Translate
																id={'carrierTenders.warningRequirement.subTitle'}
															/>
														</Typography>
													}
												/>
											</TableCell>
										)}
										<TableCell padding='none' className={classes.cellHeader}>
											<Typography gutterBottom variant='body2' component='p'>
												{deal.client.Name}
											</Typography>
										</TableCell>
										<TableCell padding='none' className={classes.cellHeader}>
											<Typography gutterBottom variant='body2' component='p'>
												{deal.transportZone.Name} | {deal.transportZone.Direction}
											</Typography>
										</TableCell>
										<TableCell padding='none' className={classes.cellHeader} align='right'>
											<div style={{ display: 'flex' }}>
												<Typography>{deal.totalMaterial}</Typography>
											</div>
										</TableCell>
										{isShowManeuver && (
											<TableCell padding='none' className={classes.cellHeader}>
												<div className={classes.maneuver}>
													{deal.isManeuvers
														? getManeuverTemplateByNumber(
																deal.selectManeuverType,
																<Translate
																	id={getManeuverTypeByNumber(
																		deal.selectManeuverType
																	)}
																/>
														  )
														: '-'}
												</div>
											</TableCell>
										)}
										{isShowAppointment && (
											<TableCell padding='none' className={classes.cellHeader}>
												{deal.boardingAppointmentDate &&
												deal.boardingAppointmentDate.length > 0 ? (
													<div className={classes.appoimentContent}>
														<div className={classes.iconCheck}>
															<CheckCircleOutline />
														</div>
														<div className={classes.appoimentInfo}>
															<div className={classes.dateProps}>
																<Translate id={'carrierTenders.deals.folio'} />
																{`: ${
																	deal.boardingAppointmentFolio
																		? deal.boardingAppointmentFolio
																		: '-'
																}  `}
															</div>
															<div className={classes.dateProps}>
																{`| ${
																	deal.boardingAppointmentDate
																		? moment(deal.boardingAppointmentDate).format(
																				'DD/MM/YYYY'
																		  )
																		: '-'
																}`}
															</div>
															<div className={classes.dateProps}>
																<Translate
																	id={'carrierTenders.deals.appointmentTime'}
																/>
																{deal.boardingAppointmentDate
																	? moment(deal.boardingAppointmentDate).format(
																			' HH:mm A'
																	  )
																	: '-'}
															</div>
														</div>
													</div>
												) : (
													'-'
												)}
											</TableCell>
										)}
									</TableRow>
								)
								// })
							})}
					</TableBody>
				</Table>
			</div>
			{openWarningClient && (
				<RequierementClientDialog
					open={openWarningClient}
					onClose={() => setOpenWarningClient(false)}
					client={repartSelect}
				/>
			)}
		</div>
	)
}

const styles = (theme) => ({
	detalsContent: {
		padding: '8px',
	},
	tableContent: {
		padding: '8px',
		backgroundColor: '#F9F9F9',
		borderRadius: '8px',
	},
	tableRow: {
		backgroundColor: theme.palette.background.paper,
	},
	iconCheck: {
		paddingRight: '8px',
		color: theme.palette.colors.green,
	},
	dateProps: {
		paddingRight: '8px',
	},
	appoimentInfo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	appoimentContent: {
		display: 'flex',
		margin: '15px 15px 5px 15px',
		backgroundColor: '#EDF7ED',
		padding: '15px',
		borderRadius: '3px',
	},
	cellHeader: {
		paddingLeft: 0,
		paddingRight: 0,
		// border: 'none',
	},
	cellHeaderFixedSize: {
		paddingLeft: 0,
		paddingRight: 0,
		width: 150,
		maxWidth: 150,
	},
	headerTable: {
		backgroundColor: theme.palette.background.default,
		// border: 'none',
	},
	titleHeader: {
		fontWeight: 'bold',
	},
	checkbox: {
		display: 'flex',
	},
	maneuver: {
		// display: 'flex',
		// justifyContent: 'center',
		// alignItems: 'center',
	},
	iconWarning: {
		marginLeft: '8px',
		display: 'flex',
		justifyContent: 'center',
	},
	icon: {
		color: 'orange',
	},
	chipWarningContent: {
		backgroundColor: 'white',
		marginLeft: '8px',
		marginBottom: '2px',
		padding: '8px',
		border: 'solid 2px',
		borderColor: 'orange',
	},
	colorWarning: {
		color: 'orange',
	},
})
export default withRouter(withStyles(styles, { withTheme: true })(ViewDealsSupplier))
