
/** Helpers import section */
import axios from 'axios';
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';
export const START_LOADING_CATEGORIES = "START_LOADING_CATEGORIES";
export const IS_SAVING = "IS_SAVING";
/**Add actions section */
export const SET_BIDDING_CATEGORIES_LIST = "SET_BIDDING_CATEGORIES_LIST"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const DELETE_CATEGORY = "DELETE_CATEGORY"

/**
 * Action start loading common data
 * @param {*} isLoading 
 */
function isLoadingCommon(isLoading, identifier) {
    return {
        type: START_LOADING_CATEGORIES,
        isLoading: isLoading,
        identifier: identifier
    }
}

/**
 * Action start saving information
 * @param {*} isLoading 
 */
function isSavingCommon(isSaving, identifier) {
    return {
        type: IS_SAVING,
        isSaving: isSaving,
        identifier: identifier
    }
}


/**
 * Action to set BIDDINGS Categorieslist 
 * @param {*} BIDDINGS 
 */
function setBiddingsCategoriesList(categories) {
    return {
        type: SET_BIDDING_CATEGORIES_LIST,
        categories: categories
    }
}

/**
 * Action to set BIDDINGS Categorieslist 
 * @param {*} BIDDINGS 
 */
function setCategory(category) {
    return {
        type: ADD_CATEGORY,
        category: category
    }
}

function deleteCategory(id) {
    return {
        type: DELETE_CATEGORY,
        id: id
    }
}


export function loadAllBiddingsCategoriesFromBackEnd() {
    return function (dispatch, getState) {

        dispatch(isLoadingCommon(true, START_LOADING_CATEGORIES));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/biddings/categories`;
            return axios.get(endPoint, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setBiddingsCategoriesList(serverResponse.data))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingCommon(false, START_LOADING_CATEGORIES));
                    console.error("Error getting biddings categories", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isLoadingCommon(false, START_LOADING_CATEGORIES));
        }
    }
}

export function createCategoriesFromBackEnd(categoryModel) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, IS_SAVING));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/biddings/categories`;
            let command = {
                Name: categoryModel ? categoryModel.name : ""
            }
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(isSavingCommon(false, IS_SAVING));
                        dispatch(setCategory(serverResponse.data))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, IS_SAVING));
                    console.error("Error getting biddings categories", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isSavingCommon(false, IS_SAVING));
        }
    }
}

export function editCategoriesFromBackEnd(name, id) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, IS_SAVING));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/biddings/categories`;
            let command = {
                Name: name,
                Id: id
            }
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(isSavingCommon(false, IS_SAVING));
                        dispatch(setCategory(serverResponse.data))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, IS_SAVING));
                    console.error("Error getting biddings categories", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isSavingCommon(false, IS_SAVING));
        }
    }
}

export function deleteCategoryFromBackEnd(id) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, IS_SAVING));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/biddings/categories`;
            return axios.delete(endPoint, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(isSavingCommon(false, IS_SAVING));
                        dispatch(deleteCategory(id))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, IS_SAVING));
                    console.error("Error getting biddings categories", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isSavingCommon(false, IS_SAVING));
        }
    }
}