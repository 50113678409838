/** React import section */
import axios from 'axios';

/** Actions import section **/
import {
    getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserParameters,
    getAuthorizedRequestConfigurationUserFiles
} from '../helpers/SessionHelper';

/* Action types constant definitions */
export const CHANGE_SACS_QUALITY_SORT_CRITERIA = 'CHANGE_SACS_QUALITY_SORT_CRITERIA';
export const CHANGE_SACS_QUALITY_QUERY = 'CHANGE_SACS_QUALITY_QUERY';
export const CHANGE_SACS_QUALITY_PAGINATION = 'CHANGE_SACS_QUALITY_PAGINATION';

export const START_LOADING_SACS_QUALITY = "START_LOADING_SACS_QUALITY";
export const SET_SACS_QUALITY_LIST = "SET_SACS_QUALITY_LIST";
export const SET_SACS_QUALITY_COUNT = "SET_SACS_QUALITY_COUNT";
export const IS_SAVING = "IS_SAVING";
export const IS_LOADING = "IS_LOADING";
export const SET_SAC_QUALITY = "SET_SAC_QUALITY";
export const ADD_FILE_SACS_QUALITY = "ADD_FILE_SACS_QUALITY";
export const REMOVE_FILE_SACS_QUALITY = "REMOVE_FILE_SACS_QUALITY";
export const REMOVE_NON_CONFORMITY_CANCEL_TO_LIST = "REMOVE_NON_CONFORMITY_CANCEL_TO_LIST";
export const IS_LOADING_ALL_SACS_REPORTS = "IS_LOADING_ALL_SACS_REPORTS";

/* Transaction types constant definitions */


/**
 * Action start loading sacsQuality
 * @param {*} isLoading 
 */
function isLoadingSacsQuality(isLoading) {
    return {
        type: START_LOADING_SACS_QUALITY,
        isLoading
    }
}

/**
 * Action to set sacsQuality  list 
 * @param {*} sacsQuality 
 */
function setsacsQualityList(sacsQuality) {
    return {
        type: SET_SACS_QUALITY_LIST,
        sacsQuality
    }
}

/**
 * Action reducer to set sacsQuality counter
 *
 * @param {*} count
 * @returns
 */
function setSacsQualityCount(count) {
    return {
        type: SET_SACS_QUALITY_COUNT,
        count
    }
}

/**
 * Action to is sacQuality
 * @param {*} isSaving 
 */
function isSaving(isSaving) {
    return {
        type: IS_SAVING,
        isSaving
    }
}

/**
 * Action to is loaidng sacQuality
 * @param {*} isLoading 
 */
function isLoading(isLoading) {
    return {
        type: IS_LOADING,
        isLoading
    }
}

/**
 * Action to set sacQuality
 * @param {*} sacQuality 
 */
function setSacQuality(sacQuality) {
    return {
        type: SET_SAC_QUALITY,
        sacQuality
    }
}

/**
 * Add file to state
 *
 * @param {*} files
 * @returns
 */
function addFile(files) {
    return {
        type: ADD_FILE_SACS_QUALITY,
        files
    }
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName) {
    return {
        type: REMOVE_FILE_SACS_QUALITY,
        fileName
    }
}

/**
 * Action to remove non conformity cancel sac
 * @param {*} tokenNonConformity 
 */
function removeNonConformityCancelSac(tokenNonConformity) {
    return {
        type: REMOVE_NON_CONFORMITY_CANCEL_TO_LIST,
        tokenNonConformity
    }
}

/** Actions methods definitions **/


/**
 * Get all SacsQuality by company from backend 
 */
export function loadSacsQualityFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, isAllReports) {
    return function (dispatch, getState) {
        dispatch(isLoadingSacsQuality(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/all`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
            GetAllReports: isAllReports,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setsacsQualityList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingSacsQuality(false));
                console.error("Error getting sacsQuality by company list", error.response)
            })
    }
}


/**
 * Get sacQuality's count
 * @export
 * @returns
 */
export function getSacsQualityCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().sacsQuality ? getState().sacsQuality.get('sacsQualityCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSacsQualityCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the sacsQuality count", error.response)
            })
    }
}


/**
 * Get and set the SacQuality in the estate from read from backend
 * @param {*} token 
 */
export function loadSacQualityFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(isLoading(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isLoading(false))
                    dispatch(setSacQuality(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load SacQuality", error.response)
                dispatch(isLoading(false))
                return Promise.reject();
            })
    }
}


/**
 * Action to create a rework
 *
 * @export
 * @param {*} token
 * @returns
 */
export function createSacQuality(data) {
    return function (dispatch, getState) {
        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction`;

        var rework = {
            CompanyToken: selectedCompany.get('token'),
            CompanyIdentifier: selectedCompany.get("get_company_identifier"),
            DocumentIdentifier: selectedCompany.get('document_identifier'),
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,

            PartNumberCode: data.partNumber.code,
            PartNumberDescription: data.partNumber.description,
            PartMeasurementUnit: data.partNumber.measurementUnit,

            SupplierToken: data.supplierSelected.token,
            SupplierCode: data.supplierSelected.code,
            SupplierName: data.supplierSelected.name,

            ProblemDescription: data.problemDescription,

            QuantityOfMaterialInInvoice: data.inventoryQuantity,
            QuantityOfMaterialAffected: data.reportedQuantity,

            DevelopmentEngineerUserName: data.supplierDevelopmentEngineer.user_name,
            DevelopmentEngineerName: data.supplierDevelopmentEngineer.full_name,
        }

        return axios.post(endPoint, rework, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve(serverResponse.data);
                }
            })
            .catch((error) => {
                console.error("Error to load Rework", error.response)
                dispatch(isSaving(false))
                return Promise.reject();
            })
    }
}


/**
 * Action to update corrective action from failure report quality
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFilesEvidence(files) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let tokenQuality = getState().sacsQuality.get('sacQuality').get('token');
        let configuration = getAuthorizedRequestConfigurationUserFiles(user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/upload/${tokenQuality}`;
        let formData = new FormData();
        for (var index = 0; index <= files.length; index++) {
            formData.append('file', files[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addFile(serverResponse.data));
                    dispatch(isSaving(false));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to upload evidence file(s) from quality corrective actions", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} quoteToken
 * @param {*} fileName
 * @returns
 */
export function removeFilesEvidence(fileName) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let tokenQuality = getState().sacsQuality.get('sacQuality').get('token');
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/remove/${tokenQuality}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeFile(fileName))
                }
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to remove evidence file(s) from quality corrective actions", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to delete draft corectie action
 */
export function deleteDraftCorrectiveAction(token) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/workflow/${token}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to delete draft corrective action", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to send notification to suplier
 *
 * @export
 * @param {*} sacToken
 * @returns
 */
export function sendNotificationToSupplier(sacToken) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            CorrectiveActionToken: sacToken,
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name
        }

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/notification`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error sending sac notification to supplier", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Actio to create actions by suplier from sac quality
 *
 * @export
 * @param {*} preparedBy
 * @param {*} planningSelected
 * @param {*} developmentSelected
 * @param {*} comments
 * @returns
 */
export function createActionsBySupplierFromSacQuality(data) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let sacToken = getState().sacsQuality.get('sacQuality').get('token');
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let formData = new FormData();
        data.files.forEach((file) => {
            formData.append('file', file);
        })
        formData.set('CorrectiveActionToken', sacToken);
        formData.set('CreatorUserName', user.profile.preferred_username);
        formData.set('CreateBy', user.profile.name);
        formData.set('ProblemCause', data.root_cause);
        formData.set('Description', data.comments);

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/correctiveactionsupplier`;
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error adding a actions by supplier in corrective actions request", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Authorizer Request 
 *
 * @export
 * @param {*} requestToken
 * @param {*} commnet
 * @returns
 */
export function authorizerRequest(requestToken, commnet) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            RequestToken: requestToken,
            AuthorizeUserName: user.profile.preferred_username,
            AuthorizeBy: user.profile.name,
            Comment: commnet,
        }

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/aceptedcorrectiveactionreport`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSacQuality(serverResponse.data))
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in authorizer request ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Rejected Request 
 *
 * @export
 * @param {*} requestToken
 * @param {*} commnet
 * @returns
 */
export function rejectedRequest(requestToken, commnet) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            RequestToken: requestToken,
            AuthorizeUserName: user.profile.preferred_username,
            AuthorizeBy: user.profile.name,
            Comment: commnet,
        }

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/rejectcorrectiveactionreport`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSacQuality(serverResponse.data))
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in rejected request ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to add register warehouse entry from failure report quality
 * @param {*} description 
 */
export function registerWarehouseEntrySACFromBackend(folio) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let sacToken = getState().sacsQuality.get('sacQuality').get('token');

        let data = {
            CorrectiveActionToken: sacToken,
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            Folio: folio,
        }

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/registerwarehouseentry`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSacQuality(serverResponse.data))
                    dispatch(isSaving(false))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to register warehouse entry from sac quality", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to closedCorrectiveActionSAC
 * @param {*} token 
 */
export function closedCorrectiveActionSAC(token) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {}
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/${token}/closed`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSacQuality(serverResponse.data))
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in closed from failure report quality", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Remove a removeNonConformityFromCancel
 * @param {*} annotationToken 
 */
export function removeNonConformityFromCancel(token, tokenNonConformity) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/${token}/nonconformity/${tokenNonConformity}/remove`;
        return axios.put(endPoint, null, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeNonConformityCancelSac(tokenNonConformity))
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error removing a annotation from a rmi", error.response)
                return Promise.reject();
            })
    }
}

/**
 *Download file with all corrective action by company report 
 *
 * @export
 * @returns
 */
export function downloadCorrectiveActionReport() {
    return (dispatch, getState) => {
        dispatch(isLoading(true));
        let companyToken = getState().profile.get("selectedCompany").get('token');
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/correctiveaction/downloadreport/${companyToken}`;
        return axios.get(endPoint, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isLoading(false));
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reporte_de_SACS.xlsx";
                    a.click();
                }
            })
            .catch((error) => {
                dispatch(isLoading(false));
                console.error("Error downloading report of sacs", error.response)
                return Promise.reject();
            })
    }
}
