import { Button } from '@material-ui/core';
import { withStyles } from "@material-ui/core";

const GreenButton = withStyles({
    root: {
      backgroundColor: '#008000', // Set the color you want
      '&:hover': {
        backgroundColor: '#004d00', // Set the color you want when the button is hovered over
      },
      color: '#fff', // Set the color of the text
    },
  })(Button);

export default GreenButton