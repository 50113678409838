import React, { Component } from 'react'
import autoBind from 'auto-bind'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { ListItemIcon, Card } from '@material-ui/core'
import NotesIcon from '@material-ui/icons/NotesSharp'
import UserIcon from '@material-ui/icons/Person'
import FolioIcon from '@material-ui/icons/Filter9Outlined'

/** Import component section */
import HtmlEditor from '../../common/HtmlEditor'
import Evidence from '../../common/Evidence'
import { COMPANY_IDENTIFIER_IMBERA_MX } from '../../../store/helpers/ResourcesHelper'

/**
 * Show ethic code
 */
class DocumentsComponent extends Component {
	/**
	 *
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * renderItemList
	 * @param {*} title
	 * @param {*} value
	 * @param {*} icon
	 */
	renderItemList(title, value, icon) {
		return (
			<ListItem divider key={title}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText
					secondary={
						<>
							<Typography
								component='span'
								variant='body2'
								style={{ display: 'inline' }}
								color='textPrimary'
							>
								{title}
							</Typography>
							{': ' + value}
						</>
					}
				/>
			</ListItem>
		)
	}

	render() {
		const { prospect } = this.props
		return (
			<Card>
				<CardContent>
					<Grid>
						<List>
							{this.renderItemList('Folio', prospect.folio, <FolioIcon />)}
							{this.renderItemList('Enviado a', `${prospect.contact_name}`, <UserIcon />)}
							{this.renderItemList('Comentarios de la solicitud', prospect.notes, <NotesIcon />)}
							{prospect.comments_rejected &&
								this.renderItemList(
									'Motivo de rechazo',
									prospect.comments_rejected,
									<NotesIcon color='primary' />
								)}
							{prospect.attachmentsToSupplier && prospect.attachmentsToSupplier.length !== 0 && (
								<ListItem divider>
									<ListItemText
										secondary={
											<>
												<Typography component='span' variant='body2' color='textPrimary'>
													{'Archivos adicionales del proveedor '}
												</Typography>
												<Evidence
													isDisabled={true}
													baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
													removeFiles={this.props.removeFiles}
													uploadFiles={this.props.uploadFiles}
													files={prospect.attachmentsToSupplier}
												/>
											</>
										}
									/>
								</ListItem>
							)}
							{this.props.prospect.company_code === COMPANY_IDENTIFIER_IMBERA_MX && (
								<ListItem divider>
									<ListItemText
										secondary={
											<>
												<Typography component='span' variant='body2' color='textPrimary'>
													{'Archivos adicionales del proveedor '}
												</Typography>
												<Evidence
													baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
													removeFiles={this.props.removeFiles}
													uploadFiles={this.props.uploadFiles}
													files={this.props.uploadesFiles}
													disabledLink
												/>
											</>
										}
									/>
								</ListItem>
							)}
							<ListItem>
								<ListItemText
									secondary={
										<>
											<Typography component='span' variant='body2' color='textPrimary'>
												{'Comentarios del prospecto'}
											</Typography>
											<HtmlEditor
												id='observations'
												label={''}
												labelVariant={'body2'}
												isDisabled={!this.props.canBeEdit}
												initialValue={prospect.observations}
												onChange={(data) => this.props.updatePropertyComments(data.value)}
											/>
										</>
									}
								/>
							</ListItem>
							{/* <ListItem divider >
                                <ListItemText
                                    secondary={<>
                                        <Typography component="span" variant="body2" color="textPrimary">
                                            {"Agregar aviso de privacidad firmado"}
                                        </Typography>
                                        <Evidence
                                            isDisabled={!this.props.canBeEdit}
                                            baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
                                            removeFiles={this.props.removeFiles}
                                            uploadFiles={this.props.uploadFiles}
                                            files={prospect.attachments}
                                        />
                                    </>
                                    }
                                />
                            </ListItem> */}
						</List>
					</Grid>
				</CardContent>
			</Card>
		)
	}
}

export default DocumentsComponent
