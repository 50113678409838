import React, { Component } from 'react';
import autoBind from 'auto-bind'

/** Translations imports section */
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Avatar from '@material-ui/core/Avatar';
import SuppliersIcon from '@material-ui/icons/People';
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';

import SuppliersSelector from '../../common/SupplierSelector';


/**
 * Component to 
 */
class SuppliersCard extends Component {

    /**
   * Create an instance of Internal user
   * @param {*} props 
   */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { classes, contract } = this.props;

        return (
            <Card className={classes.card} style={{ height: '79vh' }}>
                <CardHeader
                    avatar={
                        <Avatar className={classes.userAvatar}>
                            <SuppliersIcon />
                        </Avatar>
                    }
                    title={<Translate id="constracts.selectedSupplier" />}
                    subheader={<Translate id="officialNotices.addSupplier" />}
                />
                <CardContent>
                    <Grid container spacing={24}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-error-radios"
                            name="quiz"
                            value={contract.isSupplier}
                            style={{marginLeft:'10px'}}
                            onChange={() => { this.props.updateProperty("isSupplier", !contract.isSupplier) }}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Proveedor" />
                            <FormControlLabel value={false} control={<Radio />} label="Otro" />
                        </RadioGroup>
                        {contract.isSupplier && <Grid item xs={12}>
                            <Typography variant="caption" gutterBottom><Translate id="common.supplier" /></Typography>
                            <SuppliersSelector
                                className={classes.textField}
                                onSupplierSelected={(supplier) => { this.props.onSupplierSelected(supplier) }}
                            />
                        </Grid>}
                        {!contract.isSupplier && <Grid item xs={12}>
                        <Typography variant="caption" gutterBottom><Translate id="constracts.clientCode" /></Typography>
                            <TextField fullWidth
                                id="outlined-multiline"
                                label={<Translate id="constracts.clientCode" />}
                                className={classes.textAreaField}
                                value={contract.clientCode}
                                onChange={(event) => { this.props.updateProperty("clientCode", event.target.value) }}
                                variant="outlined"
                                helperText= {contract.requestedBy.length <= 1 ? 'Introduzca al menos dos caracteres' : ''}
								error={contract.clientCode.length <= 1}
                            />
                        </Grid>}
                        {!contract.isSupplier && <Grid item xs={12}>
                        <Typography variant="caption" gutterBottom><Translate id="constracts.clientName" /></Typography>
                            <TextField fullWidth
                                id="outlined-multiline"
                                label={<Translate id="constracts.clientName" />}
                                className={classes.textAreaField}
                                value={contract.clientName}
                                onChange={(event) => { this.props.updateProperty("clientName", event.target.value) }}
                                variant="outlined"
                                helperText= {contract.requestedBy.length <= 1 ? 'Introduzca al menos dos caracteres' : ''}
								error={contract.clientName.length <= 1}
                            />
                        </Grid> }
                        {!contract.isSupplier && <RadioGroup
                            row
                            aria-labelledby="demo-error-radios"
                            name="quiz"
                            value={contract.isNewSupplier}
                            style={{marginLeft:'10px'}}
                            onChange={() => { this.props.updateProperty("isNewSupplier", !contract.isNewSupplier) }}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Es Nuevo proveedor" />
                            <FormControlLabel value={false} control={<Radio />} label="Es Cliente" />
                        </RadioGroup>}
                        <Grid item xs={12}>
                            <TextField fullWidth
                                id="outlined-multiline"
                                label={<Translate id="common.comments" />}
                                multiline
                                className={classes.textAreaField}
                                rows="3"
                                value={contract.notes}
                                onChange={(event) => { this.props.updateProperty("notes", event.target.value) }}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions className={classes.actions} disableActionSpacing>
                </CardActions>
            </Card>
        );
    }
}

export default SuppliersCard;