///Import react section
import { useSelector, useDispatch, } from 'react-redux'
import { converListToArray } from '../../../../store/helpers/ListHelper'
/**Import actions sections */
import {
    setToasterOptions
} from '../../../../store/indirectqualifications/IndirectqualificationsActions'
import {
    addActionPlant,
    deleteActionPlant,
    removeFileInActionPlan,
    addEvidenceInActionPlan,
    updateActionPlantSupplier,
    updateActionPlanFromResponsible
} from '../../../../store/questionnaires/QuestionnairesAction';

export const useQuestionnaireCustomHookShow = () => {
    const user = useSelector((state) => state.oidc.user)
    const questionnaire = useSelector((state) => state.questionnaires.get('questionnaire') ? state.questionnaires.get('questionnaire').toJS() : null)
    const isSavingQuestionnaire = useSelector((state) => state.questionnaires.get('isSavingQuestionnaire'))
    const userRolesByCompany = useSelector((state) => state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : [])
    /**Render hooks section */
    const dispatch = useDispatch()

    const onCloseToaster = () => {
        dispatch(setToasterOptions({
            showToaster: false,
            toasterMessage: "",
            toasterVariant: "",
        }))
    }

    const onAddActionPlant = (actionPlantToken, actionPlant) => {
        return dispatch(addActionPlant(actionPlantToken, actionPlant))
            .then(() => {})
            .catch((response) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                }))
            })
    }

    const onDeleteActionPlant = (actionPlantToken, actionPlant) => {
        return dispatch(deleteActionPlant(actionPlantToken, actionPlant))
            .then(() => {})
            .catch((response) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                }))
            })
    }

    const onRemoveFileInActionPlan = (questionnaireToken, actionPlantToken, fileName) => {
        return dispatch(removeFileInActionPlan(questionnaireToken, actionPlantToken, fileName))
            .then(() => {
                return Promise.resolve(true)
            })
            .catch((_) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                }))
                Promise.reject(false)
            })
    }

    const onAddEvidenceInActionPlan = (questionnaireToken, actionPlantToken, files) => {
        return dispatch(addEvidenceInActionPlan(questionnaireToken, actionPlantToken, files))
            .then(() => {
                return Promise.resolve(true)
            })
            .catch((_) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                }))
                Promise.reject(false)
            })
    }

    const onUpdateActionPlantSupplier = (questionnaireToken, actionPlant, isCurrentActiveDirectoryUser) => {
        return dispatch(updateActionPlantSupplier(questionnaireToken, actionPlant, isCurrentActiveDirectoryUser))
            .then(() => {
                return Promise.resolve(true)
            })
            .catch((_) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                }))
                Promise.reject(false)
            })
    }

    const onUpdateActionPlanFromResponsible = (questionnaireToken, actionPlant, isCurrentActiveDirectoryUser) => {
        return dispatch(updateActionPlanFromResponsible(questionnaireToken, actionPlant, isCurrentActiveDirectoryUser))
            .then(() => {
                return Promise.resolve(true)
            })
            .catch((_) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                }))
                Promise.reject(false)
            })
    }

    return {
        user,
        userRolesByCompany,
        isSavingQuestionnaire,
        questionnaire,
        onAddActionPlant,
        onDeleteActionPlant,
        onCloseToaster,
        onRemoveFileInActionPlan,
        onAddEvidenceInActionPlan,
        onUpdateActionPlantSupplier,
        onUpdateActionPlanFromResponsible
    }
}