import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate, getTranslate } from 'react-localize-redux'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import MemosIcon from '@material-ui/icons/FileCopyTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import SendIcon from '@material-ui/icons/Send'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import MemoItemDialog from './Components/MemoItemDialog'
import SendRequestDialog from './Components/SendRequestDialog'

import {
	getMemoInformationDataFromBackend,
	updateDetailPartnumberPlaneName,
	deleteDetailPlaneName,
	IS_LOADING_MEMO,
	getSelectedMemoItem,
	onAddSampleRequestSupplier,
	onRemoveSampleRequestSupplier,
	sendQuotesRequestFromMemo,
	addSupplierToMemoItem,
	removeSupplierFromMemoItem,
	SET_MEMO_ITEM_SELECTED,
	onAddComment,
	onAddFiles,
	onRemoveFiles,
	addSupplierToMemoMassiveItem,
	removeSupplierFromMemoMassive,
	onAddSampleRequestSupplierMassive,
	onRemoveSampleRequestSupplierMassive,
} from '../../store/memos/MemoActions'
import { downloadPlotArtFiles } from '../../store/partNumbers/PartNumberAction'

import AttachmentsDialog from '../common/AttachmentsDialog'
import MassiveMemoItemsDialog from './Components/MassiveMemoItemsDialog'
import InformationMemoCard from './Components/InformationMemoCard'
import AditionalMemoCard from './Components/AdditionalMemoCard'
import MemoDetails from './Components/MemoDetails'

import { COMPANY_IDENTIFIER_IMBERA_MX } from '../../store/helpers/ResourcesHelper'

/**
 * Component to create memo from SIT
 */
class Create extends Component {
	/**
	 * Create an instance ofMemos
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			folioMemo: '',
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			showDeletePlaneName: false,
			// Memo item options
			showMemoItemDialog: false,
			showMemoSendRequests: false,
			showAttachmentsDialog: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		this.props.startMemo()
	}

	/**
	 * Funcion on update folio to get memo
	 * @param {*} folio
	 */
	onUpdateFolioMemo(folio) {
		this.setState({
			folioMemo: folio,
		})
	}

	/**
	 * on key Down to get folio
	 * @param {*} event
	 */
	onKeyDown(event) {
		if (event.key === 'Enter') {
			this.getInformationMemo()
		}
	}

	/**
	 * Get action to get information memo
	 */
	getInformationMemo() {
		this.props
			.getMemoInformationData(this.state.folioMemo)
			.then(() => {
				this.setState({
					showAddWorker: false,
					showToView: true,
					showToaster: false,
				})
			})
			.catch((result) => {
				if (result.status === 400) {
					this.setState({
						showToaster: true,
						toasterMessage: result.data.Message,
						toasterVariant: 'error',
					})
				} else {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				}
			})
	}

	/**
	 * On memo item clicked open the dialog
	 *
	 * @param {*} item
	 * @memberof Create
	 */
	onMenuOptionItemClick(modalName, item, selectedTokens = []) {
		const { memo } = this.props
		let memoItemSelected
		if (item.token !== 'selection') {
			memoItemSelected = memo.details.find((memoItem) => {
				return memoItem.token === item.token
			})
			this.props.setMemoItemSelected(item.token)
			this.setState({
				memoItemSelected,
				selectedTokens,
				[modalName]: true,
			})
		} else {
			this.setState({
				selectedTokens,
				[modalName]: true,
			})
		}
	}

	/**
	 * onCloseModalItem
	 *
	 * @memberof Create
	 */
	onCloseModalItem(modalName) {
		this.setState({
			[modalName]: false,
			memoItemSelected: null,
		})
	}

	onDownloadPlotFile(lot, companyToken, kind, formatype) {
		return this.props
			.downloadPlotArtFiles(lot, companyToken, kind, formatype)
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorNotExtiFile' />,
					toasterVariant: 'warning',
				})
				return Promise.reject()
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		if (this.props.isLoadingMemo === true) {
			return <LoadingPage />
		} else if (this.props.memo !== null) {
			return (
				<Grid container spacing={24}>
					<Grid item xs={6}>
						<InformationMemoCard {...this.props} />
					</Grid>
					<Grid item xs={6}>
						<AditionalMemoCard {...this.props} />
					</Grid>
					<Grid item xs={12}>
						<MemoDetails
							onMenuOptionItemClick={this.onMenuOptionItemClick}
							{...this.props}
							downloadPlotFiles={this.onDownloadPlotFile}
						/>
					</Grid>
				</Grid>
			)
		}
	}

	/**
	 * On item plot selected
	 *
	 * @param {*} plotSelected
	 * @memberof Create
	 */
	onPlotSelected(item, plotSelected) {
		this.props
			.updateDetailPlane(item.token, plotSelected)
			.then(() => {})
			.catch(() => {
				console.log('onPlotSelected::failed')
			})
	}

	/**
	 * onSupplierSelected
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onSupplierSelected(item, supplier) {
		let existSupplierInList = item.quotes.find((quote) => {
			return quote.supplier_token === supplier.token
		})
		if (!existSupplierInList) {
			this.props
				.addSupplierToMemoItem(item, supplier)
				.then(() => {
					this.setState({
						showAddWorker: false,
						showToView: true,
						showToaster: false,
					})
				})
				.catch((result) => {
					if (result.status === 400) {
						this.setState({
							showToaster: true,
							toasterMessage: result.data.Message,
							toasterVariant: 'error',
						})
					} else {
						this.setState({
							showToaster: true,
							toasterMessage: <Translate id='common.errorToSave' />,
							toasterVariant: 'error',
						})
					}
				})
		}
	}

	/**
	 * On remove supplier
	 *
	 * @param {*} item
	 * @param {*} supplierToken
	 * @memberof Create
	 */
	onRemoveSupplier(item, supplierToken) {
		this.props.removeSupplierFromMemoItem(item, supplierToken)
	}

	/**
	 * On add sample request to supplier
	 */
	onAddSampleRequestSupplier(item, quote) {
		this.props.onAddSampleRequestSupplier(item, quote)
	}

	/**
	 * On remove sample request to supplier
	 * @param {*} item
	 * @param {*} supplierToken
	 */
	onRemoveSampleRequestSupplier(item, supplierToken) {
		this.props.onRemoveSampleRequestSupplier(item, supplierToken)
	}

	/**
	 * on Send requests on click memo
	 */
	onSendRequestClick() {
		this.setState({
			showMemoSendRequests: true,
		})
	}

	/**
	 * onCloseSendRequestDialog
	 *
	 * @memberof Create
	 */
	onCloseSendRequestDialog() {
		this.setState({
			showMemoSendRequests: false,
		})
	}

	/**
	 * Get action to send request memo
	 */
	sendQuotesRequestFromMemo() {
		this.props
			.sendQuotesRequestFromMemo()
			.then(() => {
				this.setState({
					showAddWorker: false,
					showToView: true,
					showToaster: false,
				})
				this.props.history.push(`/memos/${this.props.memo.token}`)
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	onMassiveSuppliersSelected(item, supplier) {
		this.props.addSupplierToMemoMassiveItem(this.state.selectedTokens, supplier)
	}

	onRemoveNoticeSupplierMassive(supplierToken) {
		return this.props.removeSupplierFromMemoMassive(this.state.selectedTokens, supplierToken)
	}

	onAddSampleRequestSupplierMassive(supplier) {
		return this.props.onAddSampleRequestSupplierMassive(this.state.selectedTokens, supplier)
	}

	onRemoveSampleRequestSupplierMassive(supplierToken) {
		return this.props.onRemoveSampleRequestSupplierMassive(this.state.selectedTokens, supplierToken)
	}
	/**
	 * Render component memo
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<MemosIcon />
						</Avatar>
					}
					action={
						<Tooltip title={<Translate id='memos.sendQuotes' />}>
							<IconButton
								color='primary'
								value
								onClick={() => {
									this.onSendRequestClick()
								}}
							>
								<SendIcon />
							</IconButton>
						</Tooltip>
					}
					title={
						<Typography variant='button'>
							<Translate id='memos.requestSampleQuote' />{' '}
						</Typography>
					}
				/>
				<CardContent>
					<Grid container spacing={24}>
						<List>
							<ListItem>
								<ListItemText
									primary={
										<React.Fragment>
											<TextField
												fullWidth
												autoFocus
												className={classes.textField}
												label={<Translate id='memos.folioMemo' />}
												onKeyDown={this.onKeyDown}
												onChange={(event) => {
													this.onUpdateFolioMemo(event.target.value)
												}}
											/>
											<br />
											<br />
										</React.Fragment>
									}
									secondary={
										<React.Fragment>{<Translate id='memos.messageToAddMemo' />}</React.Fragment>
									}
								/>
							</ListItem>
						</List>
					</Grid>
					{this.getCardContent()}
				</CardContent>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
				{this.props.selectedMemoItem && this.state.selectedTokens && this.state.selectedTokens.length <= 0 && (
					<MemoItemDialog
						item={this.props.selectedMemoItem}
						show={this.state.showMemoItemDialog}
						onClose={() => this.onCloseModalItem('showMemoItemDialog')}
						onPlotSelected={this.onPlotSelected}
						onSupplierSelected={this.onSupplierSelected}
						onRemoveSupplier={this.onRemoveSupplier}
						onAddSampleRequestSupplier={this.onAddSampleRequestSupplier}
						onRemoveSampleRequestSupplier={this.onRemoveSampleRequestSupplier}
						onAddComment={this.props.onAddComment}
						companyImbera={
							this.props.memo && this.props.memo.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX
						}
					/>
				)}
				{
					//Modal to update massive items
					this.state.selectedTokens && this.state.selectedTokens.length > 0 && (
						<MassiveMemoItemsDialog
							show={this.state.showMemoItemDialog}
							onClose={() => this.onCloseModalItem('showMemoItemDialog')}
							onSupplierMemoSelected={this.onMassiveSuppliersSelected}
							onRemoveNoticeSupplier={this.onRemoveNoticeSupplierMassive}
							onAddSampleRequestSupplier={this.onAddSampleRequestSupplierMassive}
							onRemoveSampleRequestSupplier={this.onRemoveSampleRequestSupplierMassive}
							companyImbera={
								this.props.memo && this.props.memo.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX
							}
						/>
					)
				}
				<AttachmentsDialog
					show={this.state.showAttachmentsDialog}
					item={this.props.selectedMemoItem ? this.props.selectedMemoItem : {}}
					uploadFiles={this.props.onAddFiles}
					removeFiles={this.props.onRemoveFiles}
					onClose={() => this.onCloseModalItem('showAttachmentsDialog')}
					dropzoneText={this.props.translate('dropzone.dropzoneBaseText')}
				/>
				{
					/// Obtener las solicitudes en borrador y si no existen bloquear el boton
					// mostrar cotizaciones en borrador
					this.state.showMemoSendRequests && (
						<SendRequestDialog
							show={this.state.showMemoSendRequests}
							onClose={this.onCloseSendRequestDialog}
							sendQuotesRequestFromMemo={this.sendQuotesRequestFromMemo}
							isSaving={this.props.isSaving}
						/>
					)
				}
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let memo = state.memos.get('memo')
	return {
		isLoadingMemo: state.memos.get('isLoadingMemo'),
		memo: memo ? memo.toJS() : null,
		selectedMemoItem: getSelectedMemoItem(state),
		specPlanos: state.partNumbers.get('specPlanos') ? state.partNumbers.get('specPlanos') : null,
		isUploading: state.memos.get('isUploading'),
		isSaving: state.memos.get('isSaving'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		getMemoInformationData: (folio) => {
			return dispatch(getMemoInformationDataFromBackend(folio))
		},
		startMemo: () => {
			dispatch({
				type: IS_LOADING_MEMO,
				isLoadingMemo: false,
			})
		},
		updateDetailPlane(detailToken, planeName) {
			return dispatch(updateDetailPartnumberPlaneName(detailToken, planeName))
		},
		deleteDetailPlaneName: (detailToken, planeName) => {
			return dispatch(deleteDetailPlaneName(detailToken, planeName))
		},
		addSupplierToMemoItem: (item, supplier) => {
			return dispatch(addSupplierToMemoItem(item, supplier))
		},
		removeSupplierFromMemoItem: (memoPartNumber, supplierToken) => {
			return dispatch(removeSupplierFromMemoItem(memoPartNumber, supplierToken))
		},
		setMemoItemSelected: (memoItemToken) => {
			dispatch({ type: SET_MEMO_ITEM_SELECTED, memoItemToken })
		},
		onAddSampleRequestSupplier: (memoPartNumber, quote) => {
			return dispatch(onAddSampleRequestSupplier(memoPartNumber, quote))
		},
		onRemoveSampleRequestSupplier: (memoPartNumber, supplierToken) => {
			return dispatch(onRemoveSampleRequestSupplier(memoPartNumber, supplierToken))
		},
		sendQuotesRequestFromMemo: () => {
			return dispatch(sendQuotesRequestFromMemo())
		},
		onAddComment: (item, comments, requieredPlotArt) => {
			return dispatch(onAddComment(item, comments, requieredPlotArt))
		},
		onAddFiles: (files, tokenPartNumber) => {
			return dispatch(onAddFiles(files, tokenPartNumber))
		},
		onRemoveFiles: (fileName, tokenPartNumber) => {
			return dispatch(onRemoveFiles(fileName, tokenPartNumber))
		},
		addSupplierToMemoMassiveItem: (partNumberTokens, supplier) => {
			return dispatch(addSupplierToMemoMassiveItem(partNumberTokens, supplier))
		},
		removeSupplierFromMemoMassive: (partNumberTokens, supplierToken) => {
			return dispatch(removeSupplierFromMemoMassive(partNumberTokens, supplierToken))
		},
		onAddSampleRequestSupplierMassive: (partNumberTokens, supplier) => {
			return dispatch(onAddSampleRequestSupplierMassive(partNumberTokens, supplier))
		},
		onRemoveSampleRequestSupplierMassive: (partNumberTokens, supplierToken) => {
			return dispatch(onRemoveSampleRequestSupplierMassive(partNumberTokens, supplierToken))
		},
		downloadPlotArtFiles: (lot, companyToken, kind, formatype) => {
			return dispatch(downloadPlotArtFiles(lot, companyToken, kind, formatype))
		},
	}
}

const styles = (theme) => ({
	toolbar: {
		display: 'flex',
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	button: {
		margin: theme.spacing.unit,
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	grow: {
		flexGrow: 1,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	supplierBadge: {
		padding: `0 ${theme.spacing.unit * 2}px`,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create)))
)
