import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/* Mui Material import section  */
import SyncUpIcon from '@material-ui/icons/AutorenewOutlined';
import {
    Avatar,
    Button,
    Grid,
    TextField,
    Typography,
    LinearProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Componentsimport section  */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader';
import { Translate } from 'react-localize-redux';


function SincronizeSApPanel(props) {
    const { open, onClose, classes, isSaving, onSave} = props;

    const [supplierNumber, setSupplierNumber] = useState();

    const onChangeNumber = (number) => {
        setSupplierNumber(number)
    }
    const isValid = ()=>{
        if(supplierNumber !== undefined)
            return true;
        return false;
    }
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
        setProgress((oldProgress) => {
            if (oldProgress === 100) {
            return 0;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
        });
        }, 500);

        return () => {
        clearInterval(timer);
        };
    }, []);

    return (
        <LateralPanel
            open={open}
            onClose={onClose}
        >
            <PanelHeader
                titlePanel={<Translate id="users.synchronizeSapSupplier" />}
                icon={<Avatar className={classes.avatar}><SyncUpIcon color='red' /></Avatar>}
                isIconMaterial={true} 
            />
            <div className={classes.container}>

                <Typography  variant="subtitle1" className={classes.subtitle}>
                    <Translate id="users.provideSupplier" />
                </Typography>

                <TextField
                    fullWidth
                    className={classes.textField}
                    type="number"
                    id="outlined-basic"
                    label={<Translate id="suppliers.supplierNumber" />}
                    variant="outlined"
                    onChange={(event) => onChangeNumber(event.target.value)}
                />
                {isSaving &&
                    <Typography  className={classes.message}>
                        <Translate id={'common.SynchronizingPleaseWait'}/>
                    </Typography>        
                }
            </div>
                
            {isSaving &&
                <div className={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={progress} />
                </div>
            }
            <div disaplay='flex' justifyConstent='right'>
                <Grid container>
                    <Grid item xs={8}>
                        <Button
                            variant="outlined"
                            className={classes.cancelButton}
                            onClick={() => onClose()}>
                                <Translate id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="outlined"
                            className={(isSaving || !isValid()) ? classes.lockButton : classes.syncButton}
                            onClick={() => onSave(supplierNumber)}
                            disabled={isSaving || !isValid()}
                            >
                                <Translate id="common.syncUp" />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </LateralPanel>
    )
}

const styles = theme => ({
    cancelButton: {
        margin: "10px",
        color: "red",
        marginLeft: "200px !important",
        marginBottom: "50px !important",
        borderColor: "red"
    },
    syncButton: {
        margin: "10px",
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        marginBottom: "50px !important"

    },
    lockButton: {
        margin: "10px",
        backgroundColor: theme.palette.grey[200],
        color: "white",
        marginBottom: "50px !important"
    },
    message: {
        width: '100%', 
        marginTop:'32px', 
        'text-align': 'center', 
    },
    textField: {
        marginTop: 30,
        marginBottom: 10
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    container: {
        maxHeight: `calc(100vh - ${185}px)`,
        minHeight: `calc(100vh - ${185}px)`,
        overflowY: "auto",
        padding: "10px",
    },

});

SincronizeSApPanel.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
}

SincronizeSApPanel.defaultProps = {
    open: false,
    onClose: () => console.warn('[onClose] is not defined!'),
    onSave: () => console.warn('[onSave] is not defined!'),
    isSaving: false,
}


export default (withStyles(styles, { withTheme: true })(SincronizeSApPanel))
