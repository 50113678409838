/**Import react section */
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'

/** Import store section */
import {
	addTransportTender,
	loadTypeShipmentCatalog,
	loadWineCellarCatalog,
	massiveTransportTenderAdd,
	updateTransportTender,
} from '../../../store/transportTender/transportTenderAction'
import { searchUsersInRole } from '../../../store/administration/InternalActions'
import * as selector from '../../../store/transportTender/transportTenderSelector'

/** Import component section */
import DialogComponent from '../common/DialogComponent'
import ManualCreateTransportZones from './ManualCreateTransportTender'
import LoadTypeSelector from '../common/LoadTypeSelector'
import MassiveTransportZones from './MassiveTransportZones'

/** Import Material-ui section */
import { withStyles } from '@material-ui/core'

/**Helper imports section */
import {
	getTypeShipOptions,
	getUserByRoleOptions,
	getWineCellarOptions,
} from '../../../store/helpers/TransportTenderHelper'
import { converListToArray } from '../../../store/helpers/ListHelper'
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import { TRANSPORT_GILDER } from '../../../store/helpers/RolesHelper'

const CreateTransportTender = (props) => {
	const { setOpenDialog, openDialog, setToasterMessageConfig, classes } = props
	//If the panel is for editing
	const { transportTenderdetail, isEdit } = props

	const dispatch = useDispatch()

	const [file, setfile] = useState([])
	const [isInvalidFile, setIsInvalidFile] = useState(true)

	const [changeSelect, setChangeSelect] = useState({ value: 0, label: 'Manual' })
	const [requestValues, setRequestValues] = useState({
		serviceDescription: '',
		shipmentId: '',
		dateServiceRequest: null,
		applicationDate: null,
		status: 1,
		programmer: null,
		wineCellar: null,
		startTime: '00:00',
		endTime: '00:00',
		typeShipments: null,
		showBaseRate: false,
		openRateTender: false,
		isFolioSit: false,
		tenderEndDate: moment(),
		tenderEndTime: '00:00',
	})

	const users = useSelector((state) =>
		state.internalUsers.get('usersByRole') ? converListToArray(state.internalUsers.get('usersByRole')) : []
	)

	const isLoadig = useSelector((state) => selector.getIsLoadingSavingSelector(state))
	/**Get catalog by state*/
	const wineCellar = useSelector((state) => selector.getWineCellarSelector(state))
	const typeShipment = useSelector((state) => selector.getTypeShipmentSelector(state))
	/**Get errors file By State */
	const errorTransportTenderFile = useSelector((state) => selector.getErrorTransportTenderFileSelector(state))

	const programmerOptions = users.length > 0 ? getUserByRoleOptions(users ? users[0].TransportGlider : []) : []
	const transportZoneOptions = wineCellar.length > 0 ? getWineCellarOptions(wineCellar) : []
	const typeShipmentsOptions = getTypeShipOptions(typeShipment)

	//Load Catalogs
	useEffect(() => {
		dispatch(searchUsersInRole(TRANSPORT_GILDER))
		let queryParams = {
			pageNumber: 1,
			rowsPerPage: 10000,
		}
		dispatch(loadWineCellarCatalog(queryParams))
		dispatch(loadTypeShipmentCatalog())
	}, [dispatch])

	const onSubmit = () => {
		/**Save editing */
		if (isEdit) {
			dispatch(updateTransportTender(transportTenderdetail.Id, requestValues))
				.catch((err) => {
					let message = 'carrierTenders.error.errorEditingTender'
					if (err) {
						message = err
					}
					setToasterMessageConfig({
						showToaster: true,
						toasterMessage: <Translate id={message} />,
						toasterVariant: 'error',
					})
				})
				.finally(() => {
					setOpenDialog(false)
				})
		} else {
			/**Save Tender */
			if (changeSelect && changeSelect.value === 0) {
				dispatch(addTransportTender(requestValues))
					.then((_) => {
						window.location.reload()
						setOpenDialog(false)
					})
					.catch((error) => {
						setToasterMessageConfig({
							showToaster: true,
							toasterMessage: <Translate id={error} />,
							toasterVariant: 'error',
						})
					})
				// .finally(() => {
				// 	setOpenDialog(false)
				// 	window.location.reload()
				// })
			} else if (changeSelect && changeSelect.value === 1) {
				/**Save messive Upload */
				dispatch(massiveTransportTenderAdd(file))
					.then((_) => {
						window.location.reload()
						setOpenDialog(false)
					})
					.catch((err) => {
						setToasterMessageConfig({
							showToaster: true,
							toasterMessage: <Translate id='carrierTenders.error.errorUploadingDocument' />,
							toasterVariant: 'error',
						})
					})
					.finally(() => {})
			}
		}
	}
	const onClose = () => {
		setOpenDialog(false)
		setRequestValues({
			serviceDescription: '',
			shipmentId: '',
			folioLetter: '',
			dateServiceRequest: null,
			applicationDate: null,
			status: 1,
			programmer: null,
			wineCellar: null,
			startTime: '00:00',
			endTime: '00:00',
			typeShipments: null,
			showBaseRate: false,
			openRateTender: false,
			tenderEndDate: moment(),
			tenderEndTime: '00:00',
		})
	}
	/**Verify if can be sabed data*/
	const canBeSaved = () => {
		if ((changeSelect && changeSelect.value === 0) || isEdit) {
			if (requestValues.shipmentId === '') return false
			if (!requestValues.isFolioSit && requestValues.folioLetter === '') return false
			if (requestValues.programmer === null) return false
			if (requestValues.typeShipments === null) return false
			if (requestValues.wineCellar === null) return false
			if (requestValues.endTime === null) return false
			if (requestValues.dateServiceRequest === null) return false
		} else if (changeSelect && changeSelect.value === 1) {
			if (errorTransportTenderFile.length > 0) return false
			if (file.length === 0) return false
			if (isInvalidFile === false) return false
		} else if (changeSelect === null) return false
		return true
	}

	return (
		<>
			<DialogComponent
				open={openDialog}
				onClose={() => onClose()}
				children={
					<div className={changeSelect == null && !isEdit ? classes.noChangeSelect : classes.changeSelect}>
						{!isEdit ? (
							<LoadTypeSelector setChange={setChangeSelect} classes={classes} value={changeSelect} />
						) : (
							''
						)}
						{(isEdit || (changeSelect && changeSelect.value === 0)) && (
							<ManualCreateTransportZones
								isEdit={isEdit}
								requestValues={requestValues}
								setRequestValues={setRequestValues}
								programmerOptions={programmerOptions}
								transportZoneOptions={transportZoneOptions}
								typeShipmentsOptions={typeShipmentsOptions}
								transportTenderdetail={transportTenderdetail}
							/>
						)}
						{changeSelect && changeSelect.value === 1 && (
							<MassiveTransportZones
								errorTransportTender={errorTransportTenderFile}
								isLoading={isLoadig}
								file={file}
								canBeSaved={canBeSaved()}
								setfile={setfile}
								setIsInvalidFile={setIsInvalidFile}
								isInvalidFile={isInvalidFile}
							/>
						)}
					</div>
				}
				canBeSaved={canBeSaved()}
				isSaving={isLoadig}
				onSave={onSubmit}
				title={
					changeSelect && changeSelect.value === 1 ? (
						'Cargar archivo'
					) : (
						<Translate id='carrierTenders.modaltitle' />
					)
				}
				view={SHOW_EDIT_VIEW}
			/>
		</>
	)
}
const styles = (theme) => ({
	titleSelect: {
		marginTop: '7px',
	},
	changeSelect: {},
	noChangeSelect: {
		width: '500px',
		height: '300px',
	},
})

CreateTransportTender.propTypes = {
	openDialog: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	setToasterMessageConfig: PropTypes.func.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
}

CreateTransportTender.defaultProps = {
	setOpenDialog: () => console.warn('[setOpenDialog] not defined!'),
	setToasterMessageConfig: () => console.warn('[setToasterMessageConfig] not defined!'),
	openDialog: false,
	isEdit: false,
}
export default withStyles(styles, { withTheme: true })(CreateTransportTender)
