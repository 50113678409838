import { isCategoryRawMaterials } from "../helpers/BiddingHelper";

export const PERCENTAGE_TOTAL = "100.0%";
/**
 * The method get item frfom service criteria
 * @param {*} bidding 
 */
export const getCriteriaService = (bidding) => {
    if (bidding === null || bidding === undefined)
        return null;
    if (bidding.Items.length === 0)
        return null;
    return bidding.Items[0];
}

export const getCriteriaGroup = (bidding) => {
    if (bidding === null || bidding === undefined)
        return [];
    if (bidding.Criterias.length === 0)
        return [];
    return getListCriteriasByGroup(bidding.Criterias, bidding)
}

/**
 * Selector from items included in the evaluation criteria list
 * @param {*} criterias 
 */
export const getListCriteriasByGroup = (criterias, bidding) => {
    let result = criterias.reduce((result, evaluation) => {
        let added = result.find((criteria) => criteria.Group === evaluation.Group)
        if (!added) {
            let itemsGroup = criterias.filter(criteria => criteria.Group === evaluation.Group);
            let sumPercetage = itemsGroup.reduce((sum, grup) => sum + grup.Weighing, 0);
            result.push({
                BiddingId: bidding.Id,
                Id: evaluation.Id,
                Group: evaluation.Group,
                Scope: evaluation.Scope,
                Weighing: sumPercetage,
                Items: [
                    {
                        MeasurementUnit: evaluation.MeasurementUnit,
                        Notes: evaluation.Notes,
                        Scope: evaluation.Scope,
                        Weighing: evaluation.Weighing,
                        Name: evaluation.Name,
                        IsAutomaticallyEvaluated: evaluation.IsAutomaticallyEvaluated,
                        Format: evaluation.Format,
                        UpperLimit: evaluation.UpperLimit,
                        CriteriaType: evaluation.CriteriaType,
                        LowerLimit: evaluation.LowerLimit,
                        Id: evaluation.Id,
                        Rule: evaluation.Rule,
                        Group: evaluation.Group,
                        format: evaluation.Format,
                        Response: evaluation.Response ? evaluation.Response:"",
                        Comments: evaluation.Comments ? evaluation.Comments:"",
                    }]
            })
        }
        else {
            let item = {
                MeasurementUnit: evaluation.MeasurementUnit,
                Notes: evaluation.Notes,
                Weighing: evaluation.Weighing,
                Name: evaluation.Name,
                IsAutomaticallyEvaluated: evaluation.IsAutomaticallyEvaluated,
                Format: evaluation.Format,
                UpperLimit: evaluation.UpperLimit,
                CriteriaType: evaluation.CriteriaType,
                LowerLimit: evaluation.LowerLimit,
                Id: evaluation.Id,
                Rule: evaluation.Rule,
                Group: evaluation.Group,
                Scope: evaluation.Scope,
                format: evaluation.Format,
                Response: evaluation.Response ? evaluation.Response:""
            }
            added.Items.push(item)
        }
        return result
    }, [])
    return result
}


/**
 * Selector from items included in the evaluation criteria list
 * @param {*} items 
 */
export const selectorGroupCriteria = (items) => {
    if (items === null)
        return [];
    let result = items.reduce((result, evaluation) => {
        let added = result.find((criteria) => criteria.Group === evaluation.Group)
        if (!added) {
            let itemsGroup = items.filter(criteria => criteria.Group === evaluation.Group);
            let sumPercetage = itemsGroup.reduce((sum, grup) => sum + grup.Weighing, 0);
            result.push({
                Id: evaluation.Id,
                Group: evaluation.Group,
                Weighing: sumPercetage
            })
        }
        return result
    }, [])
    return result
}

/**
 * This method get items groups list from criteria evaluation
 * @param {*} groupName 
 * @param {*} items 
 */
export const getEvaluationGroupList = (groupName, items) => {
    if (items === null)
        return [];
    let result = items.filter(criteria => criteria.Group === groupName);
    return result
}

/**
 * Function get total Percentage
 */
export const getTotalPercentage = (items) => {
    let total = 0;
    if (items.length !== 0) {
        let sumPercetage = items.reduce((sum, grup) => sum + grup.Weighing, 0);
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        })
        total = formatter.format(sumPercetage);
    }
    return total;
}


/**
 * Determine is percentage is cmpelted
 * @param {} items 
 */
export const getTotalCompleted = (items) => {
    let total = getTotalPercentage(items);
    if (total === PERCENTAGE_TOTAL)
        return true;
    else
        return false;
}

/**
 * Select itms generals
 * @param {*} items 
 */
export const getCriteriosGenerals = (items) => {
    if (items === null)
        return [];
    let result = items.filter(criteria => criteria.Group !== "Precio");
    return result
}

/***
 * Select items individuals
 */
export const getItemsIndividuals = (items, bidding) => {
    let result = []
    if (items === null)
        return [];
    items.forEach(item => {
        item.Criterias.forEach(criteria => {
            let exitGroup = result.find(x => x.ItemId === item.Id)
            if (exitGroup === undefined) {
                let supplierNew = {}
                if (isCategoryRawMaterials(bidding)) {
                        supplierNew.Name = `${item.Code} | ${item.Name}`
                        supplierNew.ItemId = item.Id
                        supplierNew.ItemProposalId = item.ItemProposalId
                        supplierNew.Status = item.Status
                        supplierNew.DivisaCode = item.DivisaCode ? item.DivisaCode : ""
                        supplierNew.IncotermCode = item.IncotermCode ? item.IncotermCode :""
                        supplierNew.PurchaseMeasurementUnit = item.PurchaseMeasurementUnit ? item.PurchaseMeasurementUnit : ""
                }
                else {
                    supplierNew.Name = item.Name
                    supplierNew.ItemId = item.Id
                }
                supplierNew[criteria.Name] = criteria.Response
                supplierNew[`${item.Id}*${criteria.Name}`] = criteria.Id
                supplierNew[`${criteria.Id}-${criteria.Name}`] = criteria.CriteriaType
                // supplierNew[`${criteria.Id}+${criteria.Name}`] = criteria.Format
                supplierNew[`${item.Id}+${criteria.Name}`] = criteria.Format
                supplierNew[`${item.Id}/${criteria.Name}`] = criteria.LowerLimit
                supplierNew[`${item.Id}_${criteria.Name}`] = criteria.UpperLimit

                result.push(supplierNew)
            }
            else {
                exitGroup[`${item.Id}*${criteria.Name}`] = criteria.Id
                exitGroup[`${criteria.Id}-${criteria.Name}`] = criteria.CriteriaType
                exitGroup[`${item.Id}+${criteria.Name}`] = criteria.Format
                exitGroup[`${item.Id}/${criteria.Name}`] = criteria.LowerLimit
                exitGroup[`${item.Id}_${criteria.Name}`] = criteria.UpperLimit
                exitGroup[criteria.Name] = criteria.Response
            }
        })
    });
    return result
}
