import React, { Component } from 'react'
import autoBind from 'auto-bind'
import Select from 'react-select'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'

/** Import component section */
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import { GetWorkCentersFersa, } from '../../../store/helpers/SelectOptions'

/**
 * Dialog to register warehouse user
 */
class SendDeliveryOrderToSapModal extends Component {
	/**
	 *Creates an instance of AddCommonItem.
	 * @param {*} props
	 * @memberof AddEmergentAction
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			centerSelected: null,
		}
	}

	/**
	 * On seleted prepare by
	 */
	onChangeCenter(option) {
		this.setState({ centerSelected: option })

	}

	/**
	 * onSave
	 */
	onSaveData() {
		if (this.props.onSendDeliveryOrderToSAP) {
			this.props.onSendDeliveryOrderToSAP(this.state.centerSelected)
			// this.props.onClose()
		}
	}

	/**
	 * Can be add item
	 */
	canBeSaved() {
		if (this.state.centerSelected === null) return false
		return true
	}

	/**
	 * Dialog information
	 */
	render() {
		const { classes } = this.props
		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.onClose}
				header={
					<Toolbar>
						<Typography variant='h6'>{this.props.title}</Typography>
					</Toolbar>
				}
				actions={
					<DialogActions
						view={SHOW_EDIT_VIEW}
						classes={classes}
						onCancel={this.props.onClose}
						onClose={this.props.onClose}
						onSaveItem={this.onSaveData}
						isSaving={this.props.isSaving}
						canBeSaved={this.canBeSaved()}
					/>
				}
			>
				<form>
					<Grid item xs={12}>
						<Typography variant='subtitle1' gutterBottom>
							{"Por favor selecciona el centro de trabajo de la lista que se muestra en el selector."}
						</Typography>
						<br />
						<Select
							label="Selecciona un centro"
							value={this.state.centerSelected}
							options={GetWorkCentersFersa()}
							onChange={this.onChangeCenter}
							styles={{
								input: (base) => ({ ...base, minWidth: 100, maxWidth: 70 }),
								menu: (base) => ({ ...base, zIndex: 10, maxHeight: 150 }),
								menuList: (base) => ({ ...base, maxHeight: 150, paddingTop: 0 }),
								dropdownIndicator: (base) => ({ ...base, padding: 0 }),
								menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
							}}
							menuPortalTarget={document.parentNode}
							menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
							menuPlacement={'auto'}
						/>
						<br />
						<br />
					</Grid>
				</form>
			</Dialog>
		)
	}
}

const dialogStyles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	toolBar: {
		backgroundColor: '#ea0b34',
	},
	button: {
		margin: theme.spacing.unit,
	},
	errorMessage: {
		color: theme.palette.common.white,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 500,
		marginTop: 10,
		marginBottom: 10,
	},
})

export default withRouter(withStyles(dialogStyles, { withTheme: true })(SendDeliveryOrderToSapModal))
