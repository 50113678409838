import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Close, Security } from '@material-ui/icons'
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import { Button, Card, IconButton, TextField, Typography } from '@material-ui/core'
import DatePicker from '../../common/DateTimePicker/DatePicker'
import moment from 'moment'
import Dropzone from '../../common/Dropzone'

const CreationSecurityCourses = ({
	classes,
	open,
	onClose,
	onSubmit,
	isLoading,
	courseInformation,
	setCourseInformation,
}) => {
	const canBeSaved = () => {
		const activeDate =
			courseInformation.ActiveDate.length === 0
				? ''
				: moment(courseInformation.ActiveDate).format('DD/MM/YYYY', true)
		const expirationDate =
			courseInformation.ExpirationDate === 0
				? ''
				: moment(courseInformation.ExpirationDate).format('DD/MM/YYYY', true)
		if (
			courseInformation &&
			courseInformation.CourseName.length > 0 &&
			courseInformation.MediaFile.length > 0 &&
			activeDate.length > 0 &&
			expirationDate.length > 0
		)
			return true
		return false
	}
	const onChangeTextField = (event) => {
		const { name, value } = event.target
		setCourseInformation({
			...courseInformation,
			[name]: value,
		})
	}
	const onChangeDate = (property, data) => {
		setCourseInformation({
			...courseInformation,
			[property]: data,
		})
	}
	const onChangeFile = (files) => {
		files.forEach((file) => {
			if (file.type.startsWith('video/')) {
				setCourseInformation({
					...courseInformation,
					MediaFile: [file],
				})
			}
		})
		return Promise.resolve('Ok')
	}
	const onRemoveFile = () =>
		setCourseInformation({
			...courseInformation,
			MediaFile: [],
		})

	return (
		<LateralPanel open={open} onClose={onClose}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id={'securityCourses.create.title'} />}
				onCloseButtonClick={onClose}
				icon={<Security color='secondary' />}
			/>
			<div className={classes.content}>
				<TextField
					id='standard-with-placeholder'
					label={'Nombre del curso'}
					onChange={onChangeTextField}
					name='CourseName'
					value={courseInformation.CourseName}
					inputProps={{
						style: {
							height: '10px',
						},
					}}
					size='small'
					margin='dense'
					fullWidth
					variant='outlined'
				/>
				<div className={classes.dateContent}>
					<div>
						<Typography variant='caption'>
							<Translate id={'securityCourses.index.table.activationDate'} />
						</Typography>
						<DatePicker
							opens={'left'}
							drops={'down'}
							onChangeRange={(event) => onChangeDate('ActiveDate', event)}
							startDate={
								courseInformation.ActiveDate ? moment(courseInformation.ActiveDate, 'DD/MM/YYYY') : null
							}
						/>
					</div>
					<div>
						<Typography variant='caption'>
							<Translate id={'securityCourses.index.table.expirationDate'} />
						</Typography>
						<DatePicker
							opens={'left'}
							drops={'down'}
							onChangeRange={(event) => onChangeDate('ExpirationDate', event)}
							startDate={
								courseInformation.ExpirationDate
									? moment(courseInformation.ExpirationDate, 'DD/MM/YYYY')
									: null
							}
						/>
					</div>
				</div>
				<TextField
					id='standard-with-placeholder'
					label={'Notas del curso'}
					onChange={onChangeTextField}
					name='CourseNotes'
					value={courseInformation.CourseNotes}
					size='small'
					margin='dense'
					fullWidth
					variant='outlined'
					multiline
					rows={4}
				/>
				{courseInformation.MediaFile.length > 0 ? (
					<VideoPreview classes={classes} file={courseInformation.MediaFile[0]} onRemove={onRemoveFile} />
				) : (
					<Dropzone
						dropzoneText={'Agrega video menor a 250MB '}
						acceptedFiles={['video/mp4']}
						maxFileSize={250 * 1024 * 1024} // 250 MB en bytes
						onChange={onChangeFile}
					/>
				)}
			</div>
			<Button
				data-tut='reactour-save'
				data-cy='btn-save'
				type='button'
				color='primary'
				variant='outlined'
				className={!canBeSaved() || isLoading ? classes.btnCreateDisabled : classes.btnCreate}
				onClick={onSubmit}
				disabled={!canBeSaved() || isLoading}
			>
				{<Translate id='securityCourses.create.save' />}
			</Button>
		</LateralPanel>
	)
}
const VideoPreview = ({ file, onRemove, classes }) => {
	const [videoUrl, setVideoUrl] = useState(null)

	React.useEffect(() => {
		if (file) {
			const videoObjectUrl = URL.createObjectURL(file)
			setVideoUrl(videoObjectUrl)
			return () => URL.revokeObjectURL(videoObjectUrl)
		}
	}, [file])
	return (
		<div>
			{file && (
				<Card className={classes.cardMedia}>
					<div className={classes.actionBtn}>
						<IconButton onClick={() => onRemove(file)}>
							<Close fontSize='small' />
						</IconButton>
					</div>
					{file.type.startsWith('video/') && (
						<video controls width='300' height='auto' style={{ borderRadius: '8px' }}>
							<source src={videoUrl} type={file.type} />
							Your browser does not support the video tag.
						</video>
					)}

					<div className={classes.cardContent}>
						<Typography variant='body2' className={classes.infoTitleCardMedia}>
							<Translate id={'securityCourses.create.videoLoader.name'} />: {file.name}
						</Typography>
						<div className={classes.infoCardMedia}>
							<Typography variant='caption'>
								<Translate id={'securityCourses.create.videoLoader.type'} />: {file.type}
							</Typography>
							<Typography variant='caption'>
								<Translate id={'securityCourses.create.videoLoader.size'} />:{' '}
								{(file.size / 1024 / 1024).toFixed(2)} MB
							</Typography>
						</div>
					</div>
				</Card>
			)}
		</div>
	)
}
const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	content: {
		margin: '8px',
	},
	dateContent: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	btnCreateDisabled: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `rgba(223, 226, 230, 1)`,
	},
	cardMedia: {
		backgroundColor: 'rgba(223, 226, 230, 1)',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		borderRadius: '8px',
	},
	actionBtn: {
		width: '453px',
		display: 'flex',
		justifyContent: 'flex-end',
		paddingTop: '5px',
	},
	cardContent: {
		padding: '8px',
	},
	infoCardMedia: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	infoTitleCardMedia: {
		color: theme.palette.primary.dark,
		fontWeight: 'bold',
	},
})
CreationSecurityCourses.propTypes = {
	open: propTypes.bool,
	isLoading: propTypes.bool,
	onClose: propTypes.func,
	onSubmit: propTypes.func,
}
CreationSecurityCourses.defaultProps = {
	open: false,
	isLoading: false,
	onClose: () => console.warn('No [onclose] callBack defined'),
	onSubmit: () => console.warn('No [onSubmit] callBack defined'),
}
export default withRouter(withStyles(styles, { withTheme: true })(CreationSecurityCourses))
