import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import moment from 'moment';

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import ReworkIcon from '@material-ui/icons/AutorenewOutlined'; 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DialogActions from '@material-ui/core/DialogActions';

/** Import component section */
import Toaster from '../../common/Toaster';
import Evidence from '../../common/Evidence';

import {loadGuaranteeReport, createNoteCreditBySupplier} from '../../../store/guaranteeReport/guaranteeReportAction';
import { showErrorToaster } from '../../../store/helpers/ToasterHelpers';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';

/**
 * Component to create AddNoteCredit from 
 */
class AddNoteCredit extends Component{

     /**
     * Creation AddNoteCredit an instance rework
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            attachments: [],
            creationDate: moment().format('DD/MM/YYYY'),
            noteCredit:{
                observation: "",
                folioNoteCredit: "",
            }
        }
    }

       /**
     * componentDidMount
     */
    componentDidMount(){
        let guaranteeToken = this.props.match.params.token;
        this.props.loadGuaranteeReport(guaranteeToken);
    }

    /**
     * Update the property of the note Credit
     *
     * @param {*} user
     * @param {*} value
     * @memberof View
     */
    updateProperty(property, value) {
        let noteCredit = {...this.state.noteCredit};
        noteCredit[property] = value;
        this.setState({
            noteCredit: noteCredit
        })
    }
      /**
     * Remove files
     */
    removeFiles(fileName) {
        let attachments = this.state.attachments.filter(file => {
            return file.name !== fileName
        })
        this.setState({
            attachments
        })
        return Promise.resolve('Ok')
    }

    /**
     * Add file
     * @param {*} attachments 
     */
    uploadFiles(files) {
        let filesList = this.state.attachments;
        if (files.length > 0) {
            for (var index = 0; index < files.length; index++) {
                filesList.push(files[index])
            }
        }
        this.setState({
            attachments: filesList
        })
        return Promise.resolve('Ok')
    }

     /**
     * Can be add item in quality Failure
     */
    canBeSaved(){
        let noteCredit = this.state.noteCredit;
        if(this.state.attachments.length === 0)
            return false;
        if(noteCredit.folioNoteCredit === "")
            return false;
        else
            return true;
    }

    /**
     * Get action to create guaranteeReport from rework
     */
    onCreateNoteCredit(){
        let guaranteeToken = this.props.match.params.token;
        this.props.createNoteCreditBySupplier(this.state.attachments, this.state.noteCredit)
        .then(() =>{
            this.setState({
                showToView:true,
                showToaster: false
            })
            this.props.history.push(`/guaranteereport/${guaranteeToken}`)
        }).catch((error) => {
            this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : "common.errorToAdd"));
        });
    }

        /**
    * Get the card content
    *
    * @memberof Show
    */
   getCardContent() {
        const { classes } = this.props;
        return(
            <Grid container spacing={24} style={{ paddingLeft: '80px', paddingRight: "100px", }}>
                <Grid item xs={10}>
                    <TextField required fullWidth
                        id="standard-read-only-input"
                        label={<Translate id="moduleGuarantee.folioNoteCredit"/>}
                        value={this.state.noteCredit.folioNoteCredit}
                        className={classes.tetxtFieldFull}
                        type="number"
                        margin="normal"
                        onChange={(event) => {this.updateProperty("folioNoteCredit", event.target.value)}}
                    /><br/><br/>
                    
                    <TextField fullWidth required
                        id="outlined-multiline-flexible"
                        label={<Translate id="moduleGuarantee.observations"/>}
                        multiline
                        className={classes.tetxtFieldFull} 
                        rows="3"
                        value={this.state.noteCredit.observation}
                        onChange={(event) => {this.updateProperty("observation", event.target.value)}}
                        margin="normal"
                        variant="outlined"
                    /><br/><br/>

                    <Typography variant='body2' component='p'><Translate id={'moduleGuarantee.addNoteFile'} /></Typography>
                    <Evidence
                        baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                        removeFiles={this.removeFiles}
                        uploadFiles={this.uploadFiles}
                        files={this.state.attachments}
                        acceptedFiles={['xml', 'pdf']}
                        disabledLink
                        text={ <Translate id="moduleGuarantee.addNoteFile" />}
                    />
                </Grid>

                <Grid container >
                    <DialogActions>
                        <Button 
                            data-cy="btn-save"
                            type="button" 
                            color="primary" 
                            onClick={this.onCreateNoteCredit}
                            disabled = {!this.canBeSaved() || this.props.isSaving}>
                            {<Translate id="common.save"/>}
                        </Button>
                        <Button onClick={() => {this.props.history.push(`/guaranteereport/${this.props.match.params.token}`)}} color="secondary">
                            {<Translate id="common.cancel"/>}
                        </Button>
                    </DialogActions>
                </Grid>  
            </Grid> 
        )
    }

    render(){
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <ReworkIcon />
                    </Avatar>
                }
                title={<Typography variant="button" ><Translate id="moduleGuarantee.creadtNote"/></Typography>}
                subheader={<Typography variant="caption" color="textPrimary"><Translate id="common.createBy"/>: {this.props.user.profile.name}
                 <br/><Translate id="common.creationDate"/>: {this.state.creationDate}</Typography>}
            />
            <CardContent>
                {this.getCardContent()}
            </CardContent>

            <Toaster
                message={<Translate id={this.state.toasterMessage} />}
                open={this.state.showToaster}
                variant={this.state.toasterVariant}
                onClose={() => { this.setState({ showToaster: false }) }}
            />

        </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    return {
        user: user,
        isSaving: state.guaranteeReport.get('isSaving'),
        guaranteeReport: state.guaranteeReport.get("guaranteeReport") ? state.guaranteeReport.get("guaranteeReport").toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadGuaranteeReport: (token) => {
            return dispatch(loadGuaranteeReport(token));
        },
        createNoteCreditBySupplier:(attachments, noteCredit) =>{
            return dispatch(createNoteCreditBySupplier(attachments, noteCredit))
        }
    }
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: "100px",
        paddingRight: "100px"
    },
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    },
    textField:{
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        maxWidth: 450,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(AddNoteCredit))));