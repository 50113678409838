/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import { Typography } from '@material-ui/core';
import { BACKGROUND_COLOR_GRAY_DARK } from '../../../store/helpers/StatusColorConstants';

import Table from '@material-ui/core/Table';
/**Import components section*/
import QuestionnaireTable from './QuestionnaireTable'
/**Import resources section*/

/**Import actions section */
const TopicComponent = (props) => {
    const { classes, topic, activeStep, sustainability } = props;
    const {updatePropertyQuestion, userRolesByCompany, userProfile} = props;

    return (
        <div>
            <div className={classes.paddingTopic}>
                <Typography style={{ color: topic.ColorTopic }}>{topic.Name}</Typography>
            </div>
            <Table className={classes.table} aria-label="customized table">
                {topic.Subtopics.map(subtopic => {
                    return (
                        <QuestionnaireTable
                            key={subtopic.Id}
                            subtopic={subtopic}
                            activeStep={activeStep}
                            sustainability={sustainability}
                            topicId = {topic.Id}
                            updatePropertyQuestion={updatePropertyQuestion}
                            userRolesByCompany={userRolesByCompany}
                            userProfile={userProfile}
                        />
                    )
                })}
            </Table>
        </div>
    )
};

const styles = (theme) => ({
    listHeader: {
        backgroundColor: BACKGROUND_COLOR_GRAY_DARK,
    },
    inputProps: {
        height: 6,
    },
    paddingTopic: {
        padding: "10px"
    }
});

TopicComponent.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
};

TopicComponent.defaultProps = {
    isLoading: false,
    topic: {
        Subtopics: [],
    }
};

export default withRouter(
    withStyles(styles, { withTheme: true })(TopicComponent)
);
