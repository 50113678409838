///Import react section
import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import autoBind from 'auto-bind';
import { withRouter } from "react-router-dom";
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import { connect } from 'react-redux';
import {cancelWorflowQualityFromBackend} from '../../store/workflows/workflowActions';
import CancelWorkflow from '../common/CancelWorkflow';

/**
 * Cancel cotainer
 */
class CancelContainer extends Component {
    constructor (props) {
        super(props);
        autoBind(this);
    }

      /**
     * Cancel a quality Failure from back end
     */
    onCancelWorkflowsDocument(cancelComment, razonForCancel){
        let token =this.props.match.params.token;
        this.props.cancelWorflowQualityFromBackend(token, cancelComment, razonForCancel)
            .then(() =>{
                this.props.history.push(`/warrantyReportsPtm/${token}`)
            }).catch(() =>{
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            })
    }

  render () {
    return (
        <CancelWorkflow
            {...this.props}
            workflow= {this.props.qualityFailure}
            onCancelWorkflowsDocument={this.onCancelWorkflowsDocument}
            isSaving={this.props.isCancel}
            link = {'warrantyReportsPtm'}
        />
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state) {
    return {
        isCancel: state.workflow.get('isCancel'),
    }
}

/**
 * Defines the actions injectes to the component
 *
 * @param {*} state
 * @returnss
 */
const mapDispatchToProps = dispatch => {
  return {
        cancelWorflowQualityFromBackend: (token, cancelComment, razonForCancel) => {
            return dispatch(cancelWorflowQualityFromBackend(token, cancelComment, razonForCancel));
        },
        
    }
}

export default withauthenticatedLayout((withRouter) (connect(mapStateToProps, mapDispatchToProps)(CancelContainer)));