import React, { Component } from 'react';
import { connect } from 'react-redux'
import autoBind from 'auto-bind';
import { Translate } from "react-localize-redux";
import Select from 'react-select'

import { searchByCustomerGuaranteeByQuery } from '../../../store/guaranteeReport/guaranteeReportAction';


/**
 * Prepared By selector container
 *
 * @class CustomerBySelector
 * @extends {Component}
 */
class CustomerBySelector extends Component {

    /**
     *Creates an instance of CustomerBySelector.
     * @param {*} props
     * @memberof CustomerBySelector
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            isLoading: true,
            customerGuarantee: []
        }
    }

    /**
   * componentDidMount
   */
    componentDidMount() {
        if (!this.props.isLoadingUser) {
            this.props.searchByCustomerGuaranteeByQuery()
                .then((customerGuarantee) => {
                    let users = customerGuarantee.map((userGuarantee) => {
                        return {
                            value: userGuarantee.token,
                            label: userGuarantee.name
                        }
                    })
                    this.setState({
                        customerGuarantee: users
                    })
                    return customerGuarantee.map((user) => {
                        return {
                            value: user.token,
                            label: user.name
                        }
                    })
                })
        }
    }

    /**
     * On select a CustomerBySelector 
     *
     * @param {*} option
     * @memberof CustomerBySelector
     */
    onSelectedcustomerGuaranteeClick(option) {
        if (this.state.customerGuarantee) {
            let selectedUser = this.state.customerGuarantee.find((user) => {
                return user.value === option.value
            })
            if (this.props.onCustomerGuaranteeSelected) {
                this.props.onCustomerGuaranteeSelected(selectedUser)
            }
        }
    }

    /**
     *  
     *
     * @returns
     * @memberof CustomerBySelector
     */
    render() {
        return (
            <Select
                placeholder={<Translate id="moduleGuarantee.reportGuarantee.searchBy" />}
                options={this.state.customerGuarantee}
                onChange={this.onSelectedcustomerGuaranteeClick}
                isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
                styles={{
                    menu: (base) => ({ ...base, zIndex: 10 }),
                    menuList: (base) => ({ ...base, paddingTop: 0 }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                }}
            />
        );
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        isLoadingUser: state.oidc.isLoadingUser,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        searchByCustomerGuaranteeByQuery: (query) => {
            return dispatch(searchByCustomerGuaranteeByQuery(query))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerBySelector);