import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
/** Material UI import section */
import { Chip } from '@material-ui/core';

const PaymentRequestStatus = (props) => {
	const { status } = props;
	const [color, setColor] = useState('gray');
	const [label, setLabel] = useState('');
	useEffect(() => {
		switch (status) {
			case '11':
				setLabel(<Translate id="accreditedWorkshops.sendedToSap" />);
				setColor('DarkOrange');
				break;
			case '11E':
				setLabel(<Translate id="accreditedWorkshops.sendedToSapFailure" />);
				setColor('DarkOrange');
				break;
			case '12':
				setLabel(<Translate id="accreditedWorkshops.docGenerated" />);
				setColor('CornflowerBlue');
				break;
			case '18':
				setLabel(<Translate id="accreditedWorkshops.paid" />);
				setColor('green');
				break;
			case '21':
				setLabel(<Translate id="accreditedWorkshops.compensated" />);
				setColor('green');
				break;
			case '51':
				setLabel(<Translate id="accreditedWorkshops.cancelled" />);
				setColor('red');
				break;
			case '22':
				setLabel(<Translate id="accreditedWorkshops.preliminatoryGen" />);
				setColor('CornflowerBlue');
				break;
			case '23':
				setLabel(<Translate id="accreditedWorkshops.preliminatoryCan" />);
				setColor('red');
				break;
			case '16':
				setLabel(<Translate id="accreditedWorkshops.sendError" />);
				break;

			default:
				break;
		}
	}, [status]);
	return <Chip label={label} variant="outlined" style={{ height: 18, borderColor: color, color: color }} />;
};

PaymentRequestStatus.propTypes = {
	status: PropTypes.oneOf(['11E', '11', '12', '15', '16', '18', '21', '22', '23', '51'])
};

PaymentRequestStatus.defaultProps = {
	status: '11'
};

export default PaymentRequestStatus;
