/** Helpers import section */
import axios from 'axios';
import {
    getAuthorizedRequestConfigurationUser,
    getAuthorizedRequestConfigurationUserParameters,
    getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper';
import moment from 'moment';
import { IsNullOrEmpty } from '../helpers/StringHelper';
import { IsCurrentNotActiveDirectoryUser } from '../helpers/SessionHelper';
import { backgroundColorTableStatus } from '../helpers/QualityAuditHelper'

export const IS_SAVING = "IS_SAVING";
export const IS_SAVINGTEMPLATE = "IS_SAVINGTEMPLATE";
export const ADD_AUDIT = "ADD_AUDIT";
export const START_LOADING_AUDIT_LIST = "START_LOADING_AUDIT_LIST";
export const SET_AUDIT_COUNT = "SET_AUDIT_COUNT";
export const SET_AUDIT_LIST = "SET_AUDIT_LIST";
export const SET_AUDIT = "SET_AUDIT";
export const UPDATE_AUDIT = "UPDATE_AUDIT"
export const UPLOAD_FILES_AUDIT = "UPLOAD_FILES_AUDIT";
export const UPLOAD_FILES_PLAN = "UPLOAD_FILES_PLAN";
export const UPLOAD_FILES_PLAN_EVIDENCE = "UPLOAD_FILES_PLAN_EVIDENCE";
export const REMOVE_FILES_AUDIT = "REMOVE_FILES_AUDIT";

/**
 * Action start loading common data
 * @param {*} isLoading 
 */
function isLoadingCommon(isLoading, identifier) {
    return {
        type: START_LOADING_AUDIT_LIST,
        isLoading: isLoading,
        identifier: identifier
    }
}

function updateAudit(property, updateValue) {
    return {
        type: UPDATE_AUDIT,
        property,
        updateValue
    }
}

/**
 * Action to set audit list 
 * @param {*} AUDIT 
 */
function setAuditList(auditList) {
    return {
        type: SET_AUDIT_LIST,
        auditList: auditList
    }
}

/**
 * Action to set count
 * @param {*} count 
 */
function setTotalCount(count) {
    return {
        type: SET_AUDIT_COUNT,
        count
    }
}

export function setAudit(audit) {
    return {
        type: SET_AUDIT,
        audit
    }
}

/**
 * Action start saving information
 * @param {*} isLoading 
 */
function isSavingCommon(isSaving, identifier) {
    return {
        type: IS_SAVING,
        isSaving: isSaving,
        identifier: identifier
    }
}

function addFile(files) {
    return {
        type: UPLOAD_FILES_AUDIT,
        files: files,
    }
}

function deleteFile(filesName, fileType) {
    return {
        type: REMOVE_FILES_AUDIT,
        filesName: filesName,
        fileType,
    }
}

function addFilePlan(files) {
    return {
        type: UPLOAD_FILES_PLAN,
        files: files,
    }
}

function addFilePlanEvidence(files) {
    return {
        type: UPLOAD_FILES_PLAN_EVIDENCE,
        files: files,
    }
}

/**
 * Action to add audit 
 * @param {*} itemModel 
 */
export function addAudit(itemModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_AUDIT));
        let selectedCompany = getState().profile.get("selectedCompany");
        let user = getState().oidc.user;
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/audit/create`;

            let command = {
                Comentarios: itemModel ? itemModel.comments : "",
                SupplierCode: itemModel ? itemModel.supplier.code : "",
                Rfc: itemModel ? itemModel.rfc : "",
                TypeAudit: itemModel ? itemModel.typeAudit.value : 0,
                AuditDate: !IsNullOrEmpty(itemModel.auditDate) ? moment(itemModel.auditDate).format('DD/MM/YYYY') : "",
                DocumentIdentifier: selectedCompany.get("document_identifier"),
                CompanyToken: selectedCompany.get("token"),
                SupplierToken: itemModel.supplier.token,
                CompanyIdentifier: selectedCompany.get("get_company_identifier"),
                SupplierName: itemModel.supplier.name,
                CreatorUserName: user.profile.preferred_username,
                CreateBy: user.profile.name,
            }
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(isSavingCommon(false, ADD_AUDIT));
                        return Promise.resolve(serverResponse.data.token)
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, ADD_AUDIT));
                    console.error("Error in add audit", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isSavingCommon(false, ADD_AUDIT));
        }
    }
}

/**
 * Action load Audits from company backend
 * @param {*} query 
 * @param {*} orderBy 
 * @param {*} sortAscending 
 * @param {*} page 
 * @param {*} rowsPerPage 
 */
export function loadAllAuditsFromBackEnd(queryParameters, translate, supplierCode) {
    return function (dispatch, getState) {
        dispatch(isLoadingCommon(true, START_LOADING_AUDIT_LIST));
        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/audit/getbycompany`;
            let command = {
                Page: (queryParameters && queryParameters.pageNumber) ? (parseInt(queryParameters.pageNumber) + 1) : 1,
                RowsPerPage: (queryParameters && queryParameters.rowsPerPage) ? queryParameters.rowsPerPage : 20,
                SortBy: ((queryParameters && queryParameters.sortBy) ? queryParameters.sortBy : "CreationDate"),
                SortAscending: ((queryParameters && queryParameters.sortDirection) && queryParameters.sortDirection === "asc" ? true : false),
                Query: (queryParameters && queryParameters.query) ? queryParameters.query : "",
                CompanyToken: selectedCompany.get("token"),
                SupplierCode: supplierCode !== null ? supplierCode : null,
            };
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        let mappedResponse = serverResponse.data.map(audit => {
                            audit.color_status_table = backgroundColorTableStatus(audit.actual_operation)
                            if (IsCurrentNotActiveDirectoryUser(user)) {
                                audit.auditDate = !IsNullOrEmpty(audit.auditDate) ? moment(audit.auditDate).format('DD/MM/YYYY') : ""
                                audit.typeAudit = audit.typeAudit === 1 ? "Inicial" : audit.typeAudit === 2 ? "Seguimiento" : ""
                            }
                            else {
                                audit.auditDate = !IsNullOrEmpty(audit.auditDate) ? moment(audit.auditDate).format('DD/MM/YYYY') : ""
                                audit.typeAudit = audit.typeAudit === 1 ? "Inicial" : audit.typeAudit === 2 ? "Seguimiento" : ""
                            }
                            return audit
                        })
                        dispatch(setAuditList(mappedResponse))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingCommon(false, SET_AUDIT_LIST));
                    console.error("Error getting audit list by company", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isLoadingCommon(false, SET_AUDIT_LIST));
        }
    }
}

export function getAuditCount() {
    return function (dispatch, getState) {
        let selectedCompany = getState().profile.get("selectedCompany");
        let companyToken = selectedCompany.get("token");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let supplierCode = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_code !== undefined) {
            supplierCode = getState().oidc.user.profile.company_code;
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/audit/${companyToken}/${supplierCode}`;
        return axios.post(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setTotalCount(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isLoadingCommon(false, SET_AUDIT_LIST));
                console.error("Error getting audit count by company", error.response)
                return Promise.reject(error.response)
            })
    }
}

/**
 * Action to load audit
 * @param {*} token 
 */
export function loadAuditFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(isLoadingCommon(true, SET_AUDIT));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/audit/getbyid/${token}`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setAudit(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isLoadingCommon(false, SET_AUDIT));
                console.error("Error getting audit", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addEvidenceSupplierAudit(token, files, type) {
    return (dispatch, getState) => {
        dispatch(isSavingCommon(true, UPDATE_AUDIT))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUserFiles(user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/audit/${token}/upload/${type}`;
        let formData = new FormData();
        for (var index = 0; index <= files.length; index++) {
            formData.append('file', files[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    if (type === 1)
                        dispatch(addFile(serverResponse.data));
                    else if (type === 2)
                        dispatch(addFilePlan(serverResponse.data));
                    else if (type === 3)
                        dispatch(addFilePlanEvidence(serverResponse.data));
                    dispatch(isSavingCommon(false, UPDATE_AUDIT));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_AUDIT))
                console.error("Error to upload evidence file(s) from audit", error.response)
                return Promise.reject();
            })
    }
}

export function removeFileInSupplierAudit(token, fileName, type) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/audit/${token}/remove/${type}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(deleteFile(fileName, type));
                    dispatch(isSavingCommon(false, UPDATE_AUDIT));
                    return Promise.resolve();
                }
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to remove file from audit", error.response)
                return Promise.reject();
            })
    }
}

export function updateAuditStatus(token, status) {
    return (dispatch, getState) => {
        dispatch(isSavingCommon(true, UPDATE_AUDIT))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/audit/${token}/updatestatus/${status}`;
        return axios.post(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setAudit(serverResponse.data))
                    dispatch(isSavingCommon(false, UPDATE_AUDIT));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_AUDIT))
                console.error("Error to send notications from audit", error.response)
                return Promise.reject();
            })
    }
}

export function updateAuditCommentsSupplierState(token, property, propertyData) {
    return (dispatch, getState) => {
        dispatch(isSavingCommon(true, UPDATE_AUDIT))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/audit/${token}/update`;
        let data = {
            [property]: propertyData.value
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateAudit(property, propertyData))
                    dispatch(setAudit(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_AUDIT))
                console.error("Error to update supplier status", error.response)
                return Promise.resolve()
            })
    }
}