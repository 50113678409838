import React, { Component } from 'react';
import autoBind from 'auto-bind';

import {
    Card, CardHeader, CardContent, FormControl,
    FormControlLabel, Checkbox, Grid, Tooltip, Typography, TextField
} from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { COLOR_STATUS_BLUE, COLOR_STATUS_SUCCESS } from '../../../../store/helpers/StatusColorConstants';
import PropTypes from 'prop-types';
import Evidence from '../../../common/Evidence';
import { onKeyDown } from '../../../../store/helpers/EventsHelper';

class QuestionCard extends Component {

    /**
  *Creates an instance of ActionsList.
  * @param {*} props
  * @memberof ActionsList
  */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            annexe: [],
        }
    }

    /**
     * Handle event upload files of devidence componet to add files to list
     *
     * @param {*} files
     * @returns
     * @memberof AddActionToListDialog
     */
    uploadFiles(questionToken, files) {
        let filesList = this.state.annexe.concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
            this.props.uploadFilesEvidenceInQuestion(filesList, questionToken)
        }
        this.setState({
            annexe: filesList
        })
        return Promise.resolve('Ok')
    }

    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
     * @memberof AddActionToListDialog
     */
    removeFiles(questionToken, fileName) {
        let newList = this.state.annexe.filter(files => {
            return files.name !== fileName
        })
        this.props.removeFilesEvidenceInQuestion(fileName, questionToken)
        this.setState({
            annexe: newList
        })
        return Promise.resolve('Ok')
    }

    renderTitleQuestion(question, showWeighting) {
        let aditional = ''

        return (<span>{question.name} {aditional}</span>)
    }

    render() {
        var { question, isTemplated, isUserAdmin, isEditMode, showWeighting, isDisabled } = this.props;
        const { removeQuestionInTemplated, editQuestionInTemplated, sendQuestionAnswer } = this.props;
        return (
            <Card>
                <CardHeader
                    title={this.renderTitleQuestion(question, showWeighting)}
                    titleTypographyProps={{ variant: "body1" }}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    action={
                        (isTemplated && isUserAdmin && isEditMode) &&
                        <React.Fragment>
                            <Tooltip title={<Translate id="common.delete" />}>
                                <DeleteOutlinedIcon color="error" style={{ cursor: "pointer", paddingTop: "5px" }}
                                    onClick={() => { removeQuestionInTemplated(question.token) }} />
                            </Tooltip>
                            <Tooltip title={<Translate id="common.edit" />}>
                                <EditIcon style={{ color: COLOR_STATUS_SUCCESS, paddingTop: "5px" }}
                                    onClick={() => { editQuestionInTemplated(question.token) }} />
                            </Tooltip>
                        </React.Fragment>
                    }
                />
                <CardContent style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 32, paddingRight: 32 }}>
                    <Grid container
                        spacing={isTemplated ? 16 : 0}
                        justify="space-between"
                    >
                        {question.question_type === 1 &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {(isTemplated) &&
                                    <Typography variant="body2" color="textSecondary">
                                        <Translate id="surveys.openAnswer" />
                                        {question.require_attachement && <span> {" y "} <Translate id="surveys.requireAttachment" /></span>}
                                    </Typography>
                                }
                                {(!isTemplated) &&
                                    <TextField
                                        id={question.id}
                                        label={<Typography variant="body1" color="textSecondary"><Translate id="sustainability.answers" /></Typography>}
                                        defaultValue={question ? question.commnets : ''}
                                        margin="normal"
                                        disabled={isDisabled}
                                        fullWidth
                                        onBlur={(event) => { sendQuestionAnswer(question.token, false, event.target.value) }}
                                        onKeyDown={onKeyDown}
                                    />
                                }
                                {(!this.props.isTemplated && question.require_attachement) &&
                                    <Evidence
                                        baseApi={process.env.REACT_APP_SIP_URL_FILES}
                                        removeFiles={(fileName) => this.removeFiles(question.token, fileName)}
                                        uploadFiles={(files) => this.uploadFiles(question.token, files)}
                                        files={this.state.annexe ? this.state.annexe : []}
                                        disabledLink={question}
                                    />
                                }
                            </Grid>}
                        {question.question_type === 2 &&
                            question.multiple_options.map((option, index) => {
                                return (<Grid key={option.token} item xs={12} sm={12} md={6} lg={6} xl={3}>
                                    {(isTemplated) &&
                                        <Typography variant="body2" color="textSecondary">
                                            <span style={{ color: COLOR_STATUS_BLUE, fontWeight: 'bold' }}>{index + 1}. </span>{`${option.OptionName}`}
                                        </Typography>
                                    }
                                    {(!isTemplated && question.question_type === 2) &&
                                        <FormControl margin="none" >
                                            <FormControlLabel
                                                control={<Checkbox
                                                    value={String(index + 1)}
                                                    checked={(question.response_supplier - 1) === index}
                                                    onChange={(event) => { sendQuestionAnswer(option.token, event.target, "") }}
                                                />}
                                                disabled={isDisabled}
                                                label={<Typography variant="body2" color="textSecondary">{option.OptionName}</Typography>}
                                            />
                                        </FormControl>}
                                </Grid>)
                            })}
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

QuestionCard.propTypes = {
    question: PropTypes.object.isRequired,
    isTemplated: PropTypes.bool,
    isUserAdmin: PropTypes.bool,
    isEditMode: PropTypes.bool,
    showWeighting: PropTypes.bool,
    isDisabled: PropTypes.bool,
    removeQuestionInTemplated: PropTypes.func,
    editQuestionInTemplated: PropTypes.func,
    sendQuestionAnswer: PropTypes.func
};

export default QuestionCard;