///Import react section
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/** Language imports **/
import { Translate } from 'react-localize-redux'
import moment from 'moment'

/** Material-UI imports section */
import { Typography, Grid, IconButton } from '@material-ui/core'
import HtmlEditor from '../../common/HtmlEditor'
import LateralPanelContainer from '../../common/LateralPanelComponents/LateralPanelContainer'
import CreateActionPlantPanel from './components/CreateActionPlantPanel'
import EditActionPanel from './components/EditActionPlant'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import FileIcon from '@material-ui/icons/AttachFile'

/** Import component section */
import DataTable from '../../common/DataTable'
import DatePicker from '../../common/DateTimePicker/DatePicker'

//Import store section
import { descriptionStatusAction, getColorText } from '../../../store/helpers/SustainabilityHelper'
import {
	COLOR_STATUS_DANGER,
	COLOR_STATUS_INFO,
	COLOR_STATUS_WARNING,
	BACKGROUND_COLOR_GRAY,
} from '../../../store/helpers/StatusColorConstants'
import { ActionPlantQuestionnaireStatus } from '../../../store/helpers/AppConstants'
import { useQuestionnaireCustomHookShow } from './customHooks/UseCustomHookShow'
/**
 * Component load list
 * @param {*} props
 */
const ActionsPlanTab = (props) => {
	const { classes, canEditCompany, canEditSupplier, translate, updateProperty } = props
	const { onAddActionPlant, questionnaire, onDeleteActionPlant, isSavingQuestionnaire } =
		useQuestionnaireCustomHookShow()
	const [identifierPanelLateral, setIdentifierPanelLateral] = useState('')
	const [isCloseLateralPanel, setIsCloseLateralPlanel] = useState(false)
	const [actionPlantSelected, setActionPlantSelected] = useState(null)

	const onOpenCloseLateralPanel = (actionPlan) => {
		setActionPlantSelected(actionPlan)
		setIdentifierPanelLateral('EditActionPanel')
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'status') {
			return (
				<Typography
					variant='caption'
					className={classes.statusText}
					style={{ color: getColorText(item.status) }}
				>
					<strong>{descriptionStatusAction(item.status, translate)}</strong>
				</Typography>
			)
		}
		if (dataSource === 'Options') {
			return (
				<IconButton
					size='small'
					onClick={() => {
						onOpenCloseLateralPanel(item)
					}}
				>
					<FileIcon
						style={{
							color:
								item.attachments && item.attachments.length === 0
									? COLOR_STATUS_INFO
									: COLOR_STATUS_WARNING,
						}}
					/>
				</IconButton>
			)
		}
		if (
			dataSource === 'ButtonDelete' &&
			item.status === ActionPlantQuestionnaireStatus.PENDIENT &&
			canEditCompany
		) {
			return (
				<IconButton
					disabled={isSavingQuestionnaire}
					onClick={() => onDeleteActionPlant(questionnaire ? questionnaire.token : '', item.token)}
				>
					<DeleteIcon />
				</IconButton>
			)
		}
	}

	const onCreateActionPlant = (actionPlant) => {
		return onAddActionPlant(questionnaire ? questionnaire.token : '', actionPlant)
	}
	/**Determinate that panel lateral is selected for open */
	const PanelLateralChildren = useCallback(
		{
			AddActionPlantPanel: (
				<CreateActionPlantPanel
					onAddActionPlant={onCreateActionPlant}
					onCloseButtonClick={() => setIsCloseLateralPlanel(true)}
				/>
			),
			EditActionPanel: (
				<EditActionPanel
					canEditSupplier={canEditSupplier}
					canEditCompany={canEditCompany}
					actionPlan={actionPlantSelected}
					onCloseButtonClick={() => setIsCloseLateralPlanel(true)}
					translate={translate}
					deliveryDateActionPlanFull={questionnaire ? questionnaire.delivery_date_action_plan : null}
				/>
			),
		},
		[identifierPanelLateral]
	)

	const onOpenLateralPanelCreateActionPlant = () => {
		setIdentifierPanelLateral('AddActionPlantPanel')
	}
	const onLateralPanelConfigChange = useCallback(() => {
		if (identifierPanelLateral) {
			onOpenAndCloseLateralPanel()
		}
	}, [identifierPanelLateral])

	/**Open lateral panel when identifier of panel change */
	useEffect(() => {
		onLateralPanelConfigChange()
	}, [onLateralPanelConfigChange])

	/**Open lateral panel when identifier of panel change */
	useEffect(() => {
		if (isCloseLateralPanel) {
			onOpenAndCloseLateralPanel()
			setIsCloseLateralPlanel(false)
		}
	}, [isCloseLateralPanel])

	const { LateralPanelComponent, onOpenAndCloseLateralPanel } = LateralPanelContainer({
		children: PanelLateralChildren[identifierPanelLateral],
		identifier: identifierPanelLateral,
		setIdentifierPanelLateral: setIdentifierPanelLateral,
	})

	const onChangeDelivery = (deliveryDate) => {
		let questionnaireToken = questionnaire ? questionnaire.token : ''
		props.updatePropertyDeliveryDate(questionnaireToken, 'delivery_date_action_plan', deliveryDate)
	}

	return (
		<div>
			<Grid item xs={12} container>
				<Grid item xs={8}>
					{/*Noting code*/}
				</Grid>
				<Grid item xs={4} xl={4}>
					<Typography variant='body2'>
						<strong>
							<Translate id='questionnaire.deliveryDateActionPlan' />:{' '}
						</strong>
						{!canEditCompany && (
							<Typography variant='body2' gutterBottom>
								{questionnaire.delivery_date_action_plan_full}
							</Typography>
						)}
						{canEditCompany && (
							<DatePicker
								onChangeRange={onChangeDelivery}
								startDate={moment(questionnaire.delivery_date_action_plan, 'DD/MM/YYYY')}
								minDate={moment()}
							/>
						)}
					</Typography>
				</Grid>
				<Grid item xs={11} container className={classes.colorGrey}>
					<Typography variant='overline' className={classes.titleText}>
						{'Plan de acciones para el proveedor'}
					</Typography>
				</Grid>
				<Grid item xs={1} container className={classes.btnCreate}>
					{canEditCompany && (
						<IconButton onClick={onOpenLateralPanelCreateActionPlant} disabled={isSavingQuestionnaire}>
							<AddIcon
								color='primary'
								className={classes.addIcon}
								onClick={() => onOpenLateralPanelCreateActionPlant()}
							/>
						</IconButton>
					)}
				</Grid>
			</Grid>
			<DataTable
				data={questionnaire.actions_plant}
				configuration={TableConfigurationSustainability}
				onRenderCellItem={onRenderCellItem}
			/>
			<br />
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<HtmlEditor
					id={'questionnaire.supplierStrengths'}
					label={'questionnaire.supplierStrengths'}
					labelVariant='caption'
					labelColor='textSecondary'
					isDisabled={!canEditCompany}
					initialValue={questionnaire.supplier_strengths}
					onChange={(data) => updateProperty('supplier_strengths', data)}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<HtmlEditor
					id={'questionnaire.improvementOpportunities'}
					label={'questionnaire.improvementOpportunities'}
					labelVariant='caption'
					labelColor='textSecondary'
					isDisabled={!canEditCompany}
					initialValue={questionnaire.improvement_opportunities}
					onChange={(data) => updateProperty('improvement_opportunities', data)}
				/>
			</Grid>
			<LateralPanelComponent />
		</div>
	)
}
ActionsPlanTab.propTypes = {
	sustainability: PropTypes.object.isRequired,
	actionPlans: PropTypes.array.isRequired,
}

ActionsPlanTab.defaultProps = {
	sustainability: {
		ActionPlans: [],
	},
	actionPlans: [],
}

const styles = (theme) => ({
	logo: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
	colorRed: {
		paddingLeft: '5px',
		backgroundColor: COLOR_STATUS_DANGER,
	},
	colorGrey: {
		paddingLeft: '5px',
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	titleText: {
		marginTop: '5px',
	},
	textField: {
		width: '230px',
	},
	inputProps: {
		height: 6,
		fontSize: 12,
	},
	statusText: {
		minWidth: '60px',
	},
	sectionText: {
		minWidth: '85px',
	},
	downloadButton: {
		display: 'flex',
	},
	pdfDownloadbutton: {
		marginTop: '20px',
		marginRight: '10px',
		flex: '2 1 0',
		marginLeft: 'auto',
		textAlign: 'right',
	},
	percentage: {
		flex: '1 1 0',
	},
	addIcon: {
		cursor: 'pointer',
	},
	btnCreate: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: '5px',
		paddingTop: '3px',
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
})

const TableConfigurationSustainability = {
	columns: [
		// {
		//   header: "",
		//   dataSource: "status_indicator",
		// },
		{
			header: <Translate id='common.status' />,
			dataSource: 'status',
		},
		{
			header: 'Nombre',
			dataSource: 'name',
		},
		{
			header: 'Respondido por',
			dataSource: 'answered_by',
		},
		{
			header: 'Fecha compromiso',
			dataSource: 'commitment_date',
		},
		{
			header: 'Fecha de aprobación',
			dataSource: 'approval_date',
		},
		{
			header: <Translate id='sustainability.uploadEvidence' />,
			dataSource: 'Options',
		},
		{
			header: '',
			dataSource: 'ButtonDelete',
		},
	],
}

export default withRouter(withStyles(styles, { withTheme: true })(ActionsPlanTab))
