/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import Typography from "@material-ui/core/Typography";
import {
  withStyles,
  ExpansionPanelSummary, ExpansionPanelDetails,
  ExpansionPanel
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/**Import components section*/
import HtmlEditor from "../../../common/HtmlEditor";
import { BACKGROUND_COLOR_GRAY_BIDDING } from '../../../../store/helpers/StatusColorConstants'

const ExpansionPanelBaseBidding = (props) => {
  const { classes } = props;
  /**Objects props section */
  const { base } = props

  let idenetifierPanel = base.Id;
  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ExpansionPanel expanded={(expanded === idenetifierPanel) || expanded === true} onChange={handleChange(idenetifierPanel)}>
      <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.textTitle}>{base.Name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <HtmlEditor
          id={base.Id}
          label=""
          labelVariant="subtitle1"
          isDisabled
          initialValue={base.Content}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
};

const styles = (theme) => ({
  expansionPanelSummary: {
    marginTop: "10px",
    backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING
  },
  baseName: {
    backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING
  },
  htmlEditor: {
    border: "1px solid #000"
  },
  textTitle:{
    fontWeight: "bold"
  }
});

ExpansionPanelBaseBidding.propTypes = {
  classes: PropTypes.object.isRequired
};

ExpansionPanelBaseBidding.defaultProps = {

};

export default withRouter(
  withStyles(styles, { withTheme: true })(ExpansionPanelBaseBidding)
);
