import React, { useState } from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

/** Material UI imports section */
import {
	withStyles,
	Card,
	CardHeader,
	Typography,
	CardContent,
	AppBar,
	Tabs,
	Tab,
	Fab,
	Grid,
	Tooltip,
	IconButton,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import MenuIcon from '@material-ui/icons/MenuOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import GetAppIcon from '@material-ui/icons/GetApp'

/** Custom components import section */
import LoadingPage from '../../common/LoadingPage'
import DialogOperation from '../../common/DialogOperation'
import TabPanelComponent from './TabPanelComponent'
import ConsignmentNoteAttachments from './ConsignmentNoteAttachments'
import AddCommonItem from '../../common/AddCommonItem'
import LinkNotes from './LinkNotes'

import {
	descriptionStatus,
	getColorText,
	canBeEditSupplier,
	canBeUpdatePlant,
	canBeCreateDevolution,
	onTitleStatusDialog,
	onMessageStatusDialog,
	TableConfiguration,
} from '../../../store/helpers/ConsigmentNoteHelper'
import { ConsignementNoteStatus, ConsingmentType } from '../../../store/helpers/AppConstants'
/**Import resources section*/
import yourlogohere from '../../../resources/images/imagotipo.png'
import DestinationsHeader from './DestinationsHeader'

/**Import actions section */
const DesktopView = (props) => {
	const {
		classes,
		isLoading,
		isSaving,
		isDonwloading,
		onUploadFiles = () => console.warn('onUploadFiles is not defined!'),
		onDownloadFiles = () => console.warn('onDownloadFiles is not defined!'),
		downloadingFiles = false,
		onRemoveFiles = () => console.warn('onRemoveFiles is not defined!'),
		onUploadAdditionalExpenseFiles = () => console.warn('onUploadAdditionalExpenseFiles is not defined!'),
		onDownloadAdditionalExpenseFiles = () => console.warn('onDownloadAdditionalExpenseFiles is not defined!'),
		downloadingAdditionalExpenseFiles = false,
		onRemoveAdditionalExpenseFiles = () => console.warn('onRemoveAdditionalExpenseFiles is not defined!'),
		onDownloadReport,
		onGenerateReport,
		onUploadAcknowledgmentReceipts = () => console.warn('onUploadAcknowledgmentReceipts is not defined!'),
		onRemoveAcknowledgmentReceipts = () => console.warn('onRemoveAcknowledgmentReceipts is not defined!'),
		onDownloadAcknowledgmentReceipts = () => console.warn('onDownloadAcknowledgmentReceipts is not defined!'),
		onGetgetAcknowledgmentFileByName = () => console.warn('onGetgetAcknowledgmentFileByName is not defined!'),

		onChangeStatusFromBackEnd = () => console.warn('onChangeStatusFromBackEnd is not defined!'),
		onCreateDevolutionFromBackEnd = () => console.warn('onCreateDevolutionFromBackEnd is not defined!'),
		downloadingAcknowledgmentReceipts = false,
		consignmentNote,
		deliveries,
		translate,
		userProfile,
		userRolesByCompany,
		onDeleteMaterialFromBackEnd,
		onDeleteDestinationFromBackEnd,
		deliveriesOptions,
		onUpdateDelivery,
		onUpdatePropertyNotes,
	} = props

	/** Defines local state */
	const [activeTab, setTabActive] = useState(0)
	const [codeTab, setTabCodeActive] = useState('')
	const [openDialogOperation, setDialogOperation] = useState(false)
	const [openDialogConfirm, setDialogConfirm] = useState({ open: false, status: null })
	const [openDialogDevolution, setDialogDevolution] = useState({ open: false, delivery: null, item: null })
	const [openDeleteMaterialDialog, setOpenDeleteMaterialDialog] = useState({
		open: false,
		delivery: null,
		item: null,
	})
	const [openDeleteDestinationDialog, setOpenDeleteDestinationDialog] = useState({ open: false, destination: null })
	//Selector section
	let canbeUpdateSupplier = canBeEditSupplier(consignmentNote, userRolesByCompany, userProfile)
	let canbeUpdatePlant = canBeUpdatePlant(consignmentNote, userRolesByCompany, userProfile)
	let canbeCreateDevolution = canBeCreateDevolution(consignmentNote, userRolesByCompany, userProfile)

	const onClickToActiveTab = (newValue, code) => {
		setTabActive(newValue)
		setTabCodeActive(code)
	}

	const onDialogOperation = () => {
		setDialogOperation(!openDialogOperation)
	}

	const onOpenConfirmDialog = (status) => {
		setDialogConfirm({ open: true, status: status })
	}

	const onClosedDialog = () => {
		setDialogConfirm({ open: false, status: null })
	}

	const onDialogCreateDevolution = (delivery, item) => {
		setDialogDevolution({ open: true, delivery: delivery, item: item })
	}

	const onDialogDeleteDestination = (destination) => {
		setOpenDeleteDestinationDialog({ open: !openDeleteDestinationDialog.open, destination: destination })
	}

	const onOpenCloseDeleteMaterialDialog = (delivery, item) => {
		setOpenDeleteMaterialDialog({ open: !openDeleteMaterialDialog.open, delivery: delivery, item: item })
	}

	const onCloseDialogDevolution = () => {
		setDialogDevolution({ open: false, delivery: null, item: null })
	}

	/***
	 * Function to change estatus
	 */
	const onChageStatus = () => {
		if (onChangeStatusFromBackEnd) {
			onChangeStatusFromBackEnd(openDialogConfirm.status).then(() => {
				onClosedDialog()
			})
		} else {
			console.log('No exist option to change')
		}
	}

	/**
	 * Function to in create devolution
	 */
	const onCreateDevolution = (confirmQuality) => {
		if (onCreateDevolutionFromBackEnd) {
			onCreateDevolutionFromBackEnd(
				openDialogDevolution.delivery,
				openDialogDevolution.item,
				confirmQuality
			).then(() => {
				onCloseDialogDevolution()
			})
		}
	}

	const onDeleteMaterial = () => {
		if (onDeleteMaterialFromBackEnd) {
			return onDeleteMaterialFromBackEnd(openDeleteMaterialDialog.delivery, openDeleteMaterialDialog.item).then(
				(_) => {
					onOpenCloseDeleteMaterialDialog(null, null)
				}
			)
		}
	}

	const onDeleteDestination = () => {
		if (onDeleteDestinationFromBackEnd) {
			return onDeleteDestinationFromBackEnd(openDeleteDestinationDialog.destination).then((_) => {
				onDialogDeleteDestination(null)
			})
		}
	}
	return (
		<Card>
			<HeaderComponent
				classes={classes}
				consignmentNote={consignmentNote}
				openDialog={onDialogOperation}
				openDialogOperation={openDialogOperation}
				translate={translate}
				getColorText={getColorText}
				descriptionStatus={descriptionStatus}
				canbeUpdateSupplier={canbeUpdateSupplier}
				canbeUpdatePlant={canbeUpdatePlant}
				consingmentType={ConsingmentType}
			/>
			<CardContent>
				{
					{
						true: <LoadingPage />,
						false: (
							<div>
								<Grid container direction='row' justifycontent='space-between' alignItems='center'>
									<Grid item xs={12} sm={6}>
										<Typography variant='subtitle2' display='block'>
											{
												<strong>
													{<Translate id='consignmentNote.show.folioTr' />}{' '}
													{consignmentNote && consignmentNote.transportCode}
												</strong>
											}
										</Typography>
										<br />
									</Grid>
									<Grid item xs={12} sm={6} className={classes.optionHeader}>
										<Tooltip title={<Translate id='consignmentNote.downloadReportPdf' />}>
											<IconButton
												color='primary'
												onClick={() => onGenerateReport()}
												disabled={isDonwloading}
											>
												<GetAppIcon />
											</IconButton>
										</Tooltip>
										<Tooltip title={<Translate id='consignmentNote.downloadReport' />}>
											<IconButton
												color='primary'
												onClick={() => onDownloadReport()}
												disabled={isDonwloading}
											>
												<DownloadIcon />
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<ConsignmentNoteAttachments
										title={<Translate id='consignmentNote.show.consignmentNote' />}
										attachments={consignmentNote ? consignmentNote.attachments : []}
										isUpdatingConsignmentNote={consignmentNote ? consignmentNote.isUpdating : false}
										onUploadFiles={onUploadFiles}
										onDownloadFiles={onDownloadFiles}
										downloadingFiles={downloadingFiles}
										onRemoveFiles={onRemoveFiles}
										canBeAdd={canbeUpdateSupplier}
										isConsignmentNote={true}
										isSaving={isSaving}
										onSeeGetAcknowledgmentFileByName={onGetgetAcknowledgmentFileByName}
									/>
								</Grid>
								<Grid item xs={12}>
									<ConsignmentNoteAttachments
										title={<Translate id='consignmentNote.show.additionalExpenses' />}
										attachments={
											consignmentNote ? consignmentNote.additionalExpenseAttachemnts : []
										}
										onUploadFiles={onUploadAdditionalExpenseFiles}
										onDownloadFiles={onDownloadAdditionalExpenseFiles}
										downloadingFiles={downloadingAdditionalExpenseFiles}
										onRemoveFiles={onRemoveAdditionalExpenseFiles}
										canBeAdd={canbeUpdateSupplier}
										isConsignmentNote={false}
										isSaving={isSaving}
										onSeeGetAcknowledgmentFileByName={onGetgetAcknowledgmentFileByName}
									/>
								</Grid>
								<Grid item xs={12}>
									<LinkNotes
										canBeAdd={canbeUpdateSupplier}
										consignmentNote={consignmentNote}
										onUpdatePropertyNotes={onUpdatePropertyNotes}
									/>
								</Grid>
								<Grid item xs={12}>
									<DestinationsHeader
										title={<Translate id='consignmentNote.show.destination' />}
										consignmentNote={consignmentNote}
										canBeAdd={
											canbeUpdatePlant && consignmentNote.status === ConsignementNoteStatus.DRAFT
										}
									/>
								</Grid>
								<div className={classes.tabsRoot}>
									<AppBar position='static' color='default'>
										<Tabs
											indicatorColor='primary'
											textColor='primary'
											value={activeTab}
											variant='scrollable'
											scrollButtons='auto'
										>
											{deliveries.map((delivery, index) => {
												return (
													<Tab
														key={delivery.code}
														id={index}
														onClick={(event) => onClickToActiveTab(index, delivery.code)}
														label={
															<span>
																<div className={classes.tabLabel}>
																	<div className={classes.tabLabelText}>
																		{delivery.code} | {delivery.name}
																	</div>
																	<div className={classes.tabLabelIcon}>
																		{canbeUpdatePlant &&
																			consignmentNote.status ===
																				ConsignementNoteStatus.DRAFT && (
																				<Tooltip title={'Eliminar destino'}>
																					<DeleteIcon
																						className={classes.deleteIcon}
																						disabled={isSaving}
																						onClick={() => {
																							onDialogDeleteDestination(
																								delivery
																							)
																						}}
																					/>
																				</Tooltip>
																			)}
																	</div>
																</div>
															</span>
														}
													/>
												)
											})}
										</Tabs>
									</AppBar>
								</div>

								<TabPanelComponent
									activeTab={activeTab}
									codeTab={codeTab}
									deliveries={consignmentNote ? consignmentNote.deliveries : []}
									consignmentNote={consignmentNote}
									onUploadAcknowledgmentReceipts={onUploadAcknowledgmentReceipts}
									onRemoveAcknowledgmentReceipts={onRemoveAcknowledgmentReceipts}
									onDownloadAcknowledgmentReceipts={onDownloadAcknowledgmentReceipts}
									onGetgetAcknowledgmentFileByName={onGetgetAcknowledgmentFileByName}
									downloadingAcknowledgmentReceipts={downloadingAcknowledgmentReceipts}
									canBeAdd={canbeUpdateSupplier}
									canbeUpdatePlant={canbeUpdatePlant}
									canBeCreateDevolution={canbeCreateDevolution}
									onDialogCreateDevolution={onDialogCreateDevolution}
									onOpenCloseDeleteMaterialDialog={onOpenCloseDeleteMaterialDialog}
									isSaving={isSaving}
									deliveriesOptions={deliveriesOptions}
									onUpdateDelivery={onUpdateDelivery}
								/>
							</div>
						),
					}[isLoading]
				}
			</CardContent>

			<ComponentChangeStatus
				onOpenChangeStatus={onOpenConfirmDialog}
				onDialogOperation={onDialogOperation}
				openDialogOperation={openDialogOperation}
				canbeUpdateSupplier={canbeUpdateSupplier}
				canbeUpdatePlant={canbeUpdatePlant}
				onTableConfiguration={TableConfiguration}
				status={consignmentNote ? consignmentNote.status : null}
			/>

			{/** Dialog to confim change status */}
			{openDialogConfirm.open && (
				<AddCommonItem
					title={<Translate id={onTitleStatusDialog(openDialogConfirm.status)} />}
					message={translate(onMessageStatusDialog(openDialogConfirm.status))}
					open={openDialogConfirm.open}
					onClose={onClosedDialog}
					onSaveItem={onChageStatus}
					isSaving={isSaving}
					saveButtonTranslate={'common.confirm'}
					isMessage={true}
				/>
			)}

			{/** Dialog to confim create devolution */}
			{openDialogDevolution.open && (
				<AddCommonItem
					title={<Translate id='consignmentNote.create.createDevolution' />}
					message={translate('consignmentNote.create.messageCreateDevolution')}
					open={openDialogDevolution.open}
					onClose={onCloseDialogDevolution}
					onSaveItem={onCreateDevolution}
					isSaving={isSaving}
					saveButtonTranslate={'common.confirm'}
					isInput={true}
					valueText={openDialogDevolution.item ? openDialogDevolution.item.quantity : ''}
					notExtistFolio={'Confirmar cantidad'}
				/>
			)}

			{/** Dialog to confim delete material */}
			{openDeleteMaterialDialog.open && (
				<AddCommonItem
					title={<Translate id='consignmentNote.createMaterial.deleteMaterial' />}
					message={<Translate id='consignmentNote.createMaterial.deleteMessage' />}
					open={openDeleteMaterialDialog.open}
					onClose={onOpenCloseDeleteMaterialDialog}
					onSaveItem={onDeleteMaterial}
					isSaving={isSaving}
					saveButtonTranslate={'common.delete'}
					isMessage={true}
				/>
			)}

			{/** Dialog to confim delete destinatoion */}
			{openDeleteDestinationDialog.open && (
				<AddCommonItem
					title={<Translate id='consignmentNote.createDestination.deleteDest' />}
					message={
						<div>
							<Translate id='consignmentNote.createDestination.deleteDestinationMessage' />
							<br />
							<div>
								<strong>{`Nombre: `}</strong>
								{`${openDeleteDestinationDialog.destination.code} - ${openDeleteDestinationDialog.destination.name}`}
							</div>
							<div>
								<strong>{`RFC: `}</strong>
								{`${openDeleteDestinationDialog.destination.recipient.rfc}`}
							</div>
						</div>
					}
					open={openDeleteDestinationDialog.open}
					onClose={onDialogDeleteDestination}
					onSaveItem={onDeleteDestination}
					isSaving={isSaving}
					saveButtonTranslate={'common.delete'}
					isMessage={true}
				/>
			)}
		</Card>
	)
}

const ComponentChangeStatus = (props) => {
	const {
		status,
		openDialogOperation,
		onDialogOperation,
		onOpenChangeStatus,
		canbeUpdateSupplier,
		canbeUpdatePlant,
		onTableConfiguration,
	} = props

	let options = onTableConfiguration(canbeUpdateSupplier, canbeUpdatePlant, status, onOpenChangeStatus)

	return (
		<>
			{openDialogOperation && (
				<DialogOperation open={openDialogOperation} onClose={onDialogOperation} options={options} />
			)}
		</>
	)
}
ComponentChangeStatus.propTypes = {
	onOpenChangeStatus: propTypes.func.isRequired,
}

/**
 * A tab panel component
 */
const HeaderComponent = ({
	consignmentNote,
	classes,
	openDialog,
	isDisabled,
	consingmentType,
	descriptionStatus,
	getColorText,
	translate,
	canbeUpdateSupplier,
	canbeUpdatePlant,
}) => {
	if (consignmentNote) {
		return (
			<CardHeader
				title={
					<Typography variant='h6'>
						<strong>
							{<Translate id='consignmentNote.show.title' />} | {consignmentNote.folio}
							{consignmentNote.source === consingmentType.DEVOLUTION ? ' | Devolución' : ''}
						</strong>
					</Typography>
				}
				subheader={
					<Typography variant='caption' display='block'>
						{
							<strong>
								{<Translate id='consignmentNote.show.sender' />}
								{consignmentNote.sender && consignmentNote.sender.name}
							</strong>
						}{' '}
						<br />
						{'RFC: '} {consignmentNote.sender && consignmentNote.sender.rfc} <br />
						{consignmentNote.sender &&
							`${consignmentNote.sender.street} ${consignmentNote.sender.suburb}, ${consignmentNote.sender.externalNumber}`}
						<br />
						{consignmentNote.sender &&
							`${consignmentNote.sender.town} ${consignmentNote.sender.stateCode} ${consignmentNote.sender.countryCode}, CP: ${consignmentNote.sender.zipCode}`}
					</Typography>
				}
				action={
					<span>
						<Typography variant='caption' className={classes.textHeader}>
							<strong
								className={classes.btnStatus}
								style={{ backgroundColor: getColorText(consignmentNote.status) }}
							>
								{descriptionStatus(consignmentNote.status, translate, consignmentNote.source)}
							</strong>
							<br />
							{moment(consignmentNote.creationDate).format('LLLL')}
							<br />
							<strong>{`${translate('consignmentNote.show.carrier')}: ${consignmentNote.carrier.code} | ${
								consignmentNote.carrier.name
							}`}</strong>
							<br />
						</Typography>
						<p className={classes.textHeader}>
							{(canbeUpdateSupplier || canbeUpdatePlant) && (
								<Fab
									variant='extended'
									size='small'
									color='primary'
									disabled={isDisabled}
									className={classes.margin}
									onClick={openDialog}
								>
									<MenuIcon className={classes.extendedIcon} />
									{<Translate id='common.AvailableOperations' />}
								</Fab>
							)}
						</p>
					</span>
				}
				avatar={<img src={yourlogohere} alt='logo' className={classes.logoImg} />}
			/>
		)
	} else {
		return ''
	}
}

HeaderComponent.propTypes = {
	consignmentNote: propTypes.object,
}

DesktopView.propTypes = {
	consignmentNote: propTypes.object,
	isLoading: propTypes.bool.isRequired,
	onDownloadFiles: propTypes.func.isRequired,
	downloadingFiles: propTypes.bool.isRequired,
	onRemoveFiles: propTypes.func.isRequired,
	onUploadAdditionalExpenseFiles: propTypes.func.isRequired,
	onDownloadAdditionalExpenseFiles: propTypes.func.isRequired,
	downloadingAdditionalExpenseFiles: propTypes.bool.isRequired,
	onRemoveAdditionalExpenseFiles: propTypes.func.isRequired,
	onRemoveAcknowledgmentReceipts: propTypes.func.isRequired,
	onDownloadAcknowledgmentReceipts: propTypes.func.isRequired,
	downloadingAcknowledgmentReceipts: propTypes.bool.isRequired,
}

const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
	},
	textHeader: {
		textAlign: 'end',
	},
	inline: {
		display: 'inline',
	},
	logoImg: {
		maxHeight: 85,
	},
	margin: {
		margin: theme.spacing.unit,
	},
	extendedIcon: {
		marginRight: theme.spacing.unit,
	},
	optionHeader: {
		textAlign: 'end',
	},
	btnStatus: {
		fontSize: '106%',
		whiteSpace: 'normal',
		color: 'white',
		padding: '5px',
	},
	tabsRoot: {
		maxWidth: `calc(100vw - ${330}px) !important`,
	},
	deleteIcon: {
		color: theme.palette.error.main,
		cursor: 'pointer',
	},
	tabLabel: {
		display: 'grid',
		gridTemplateColumns: 'auto 20px',
	},
	tabLabelText: {
		gridColumnStart: '1/2',
	},
	tabLabelIcon: {
		gridColumnStart: '3/4',
		display: 'flex',
		alignSelf: 'center',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(DesktopView))
