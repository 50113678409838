/**Import react section */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import propTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

/**Import MaterialUi section */
import { Grid, Button, TextField } from "@material-ui/core";
import { IsNullOrEmpty } from "../../../../store/helpers/StringHelper";
const CreateActionPlantPanel = (props) => {
  //Props section
  const { classes, onAddActionPlant, onCloseButtonClick } = props;
  const isSavingQuestionnaire = useSelector((state) =>
    state.questionnaires.get("isSavingQuestionnaire")
  );
  //store local this component
  const [actionPlant, setActionPlant] = useState(defaultActionPlant);
  const onPropertyChange = (event) => {
    const { name, value } = event.target;
    setActionPlant({ ...actionPlant, [name]: value });
  };

  const onCreateActionPlant = () => {
    if (props.onAddActionPlant) {
      return onAddActionPlant(actionPlant).then(() => {
        onCloseButtonClick();
      });
    }
  };

  /**Verify if can be sabed data*/
  const canBeSaved = () => {
    if (IsNullOrEmpty(actionPlant.name)) return false;
    return true;
  };

  return (
    <div>
      <div className={classes.containerInformation}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={"Nombre"}
              className={classes.textField}
              onChange={onPropertyChange}
              multiline
              name="name"
              margin="normal"
              variant="outlined"
              value={actionPlant ? actionPlant.name : ""}
              rows="2"
              disabled={isSavingQuestionnaire}
            />
          </Grid>
        </Grid>
      </div>

      {
        <Grid container>
          <Grid item xs={12} className={classes.btnSave}>
            <Button
              data-cy="btn-save"
              type="button"
              color="primary"
              variant="outlined"
              fullWidth
              onClick={onCreateActionPlant}
              className={classes.widthBtnSaved}
              disabled={!canBeSaved() || isSavingQuestionnaire}
            >
              {<Translate id="common.save" />}
            </Button>
          </Grid>
        </Grid>
      }
    </div>
  );
};

const defaultActionPlant = {
  name: "",
};
const styles = (theme) => ({
  containerInformation: {
    padding: "10px",
  },
  btnSave: {
    padding: "10px",
  },
});

CreateActionPlantPanel.propTypes = {
  /**
   * open and closedlateral panel for add an new user in customer
   */
  onCloseButtonClick: propTypes.func,
};

CreateActionPlantPanel.defaultProps = {
  onCloseButtonClick: () =>
    console.warn("Callback [onCloseButtonClick] no defined"),
};

export default withRouter(
  withStyles(styles, { withTheme: true })(CreateActionPlantPanel)
);
