import React, { Component } from 'react'
import autoBind from 'auto-bind'
import Dropzone from './Dropzone'
import { Translate } from 'react-localize-redux'
import Toaster from './Toaster'
import PropTypes from 'prop-types'
/**
 * Class component of evidence tab
 *
 * @class Evidence
 * @extends {Component}
 */
class Evidence extends Component {
	/**
	 *Creates an instance of Evidence.
	 * @param {*} props
	 * @memberof Evidence
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showDrop: true,
		}
	}

	/**
	 * React life cylce method
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Evidence
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.files !== this.props.files && this.props.files.length <= 0) {
			//This unmount and remount Dropzone component
			// to refresh his internal state
			this.setState(
				{
					showDrop: false,
				},
				() => {
					this.setState({ showDrop: true })
				}
			)
		}
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "saveCorrectly"
	 *
	 * @param {string} [idTranslation="common.saveCorrectly"]
	 * @memberof Show
	 */
	showSuccessToaster(idTranslation = 'common.saveCorrectly') {
		this.setState({
			showToaster: true,
			toasterVariant: 'success',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "errorToSave"
	 *
	 * @param {string} [idTranslation="common.errorToSave"]
	 * @memberof Show
	 */
	showErrorToaster(idTranslation = 'common.errorToSave') {
		this.setState({
			showToaster: true,
			toasterVariant: 'error',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * On change files of dropzone
	 *
	 * @param {*} files
	 * @returns
	 * @memberof Evidence
	 */
	onChangeFile(files) {
		return this.props
			.uploadFiles(files)
			.then(() => {
				if (this.props.successToasterDisplayed) {
					this.showSuccessToaster()
				}
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.showErrorToaster()
				return Promise.reject()
			})
	}

	/**
	 * On remove files of dropzone
	 *
	 * @param {*} fileName
	 * @returns
	 * @memberof Evidence
	 */
	onDeleteFile(fileName) {
		return this.props
			.removeFiles(fileName)
			.then(() => {
				if (this.props.successToasterDisplayed) {
					this.showSuccessToaster()
				}
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.showErrorToaster()
				return Promise.reject()
			})
	}

	render() {
		return (
			<React.Fragment>
				{this.state.showDrop && (
					<Dropzone
						files={this.props.files}
						onChange={(event) => this.onChangeFile(event)}
						onDeleteFile={this.onDeleteFile}
						baseApi={this.props.baseApi}
						acceptedFiles={this.props.acceptedFiles}
						isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
						disabledLink={this.props.disabledLink ? this.props.disabledLink : false}
						maxFiles={this.props.maxFiles ? this.props.maxFiles : 10}
						dropzoneText={this.props.text ? this.props.text : <Translate id='dropzone.dropzoneBaseText' />}
					/>
				)}
				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</React.Fragment>
		)
	}
}

Evidence.propTypes = {
	baseApi: PropTypes.string.isRequired,
	removeFiles: PropTypes.func.isRequired,
	uploadFiles: PropTypes.func.isRequired,
	files: PropTypes.array.isRequired,
	acceptedFiles: PropTypes.array,
	isDisabled: PropTypes.bool,
	disabledLink: PropTypes.bool,
	maxFiles: PropTypes.number,
	successToasterDisplayed: PropTypes.bool,
}

Evidence.defaultProps = {
	files: [],
	uploadFiles: () => Promise.reject('[uploadFiles] function not defined!'),
	successToasterDisplayed: true,
	baseApi: '',
}

export default Evidence
