import React from 'react'
import { Translate } from 'react-localize-redux'

/**
 * Set the service orders table configuration
 */
const serviceOrderTableConfiguration = [
	{
		header: '',
		dataSource: 'selectCheck',
		isMovable: false,
	},
	{
		header: '',
		dataSource: 'status_indicator',
		isMovable: false,
	},
	{
		header: <Translate id='accreditedWorkshops.serviceOrderId' />,
		dataSource: 'code',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='common.chain' />,
		dataSource: 'chainId',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='common.bussinesUnit' />,
		dataSource: 'branchOffice',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='accreditedWorkshops.workshopTechnician' />,
		dataSource: 'workshopTechnicianPs9Id',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='accreditedWorkshops.serviceType' />,
		dataSource: 'serviceType',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='common.corporate' />,
		dataSource: 'corporate',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='accreditedWorkshops.accreditedWorkshopsService' />,
		dataSource: 'serviceDescription',
		isSortable: true,
		filterEnabled: false,
	},
	{
		header: <Translate id='accreditedWorkshops.osDate' />,
		dataSource: 'creationDate',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='accreditedWorkshops.attentionDate' />,
		dataSource: 'attentionDate',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='quotes.closingDate' />,
		dataSource: 'closeDate',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='officialNotices.category' />,
		dataSource: 'category',
		isSortable: true,
		filterEnabled: true,
	},
	// {
	// 	header: <Translate id="accreditedWorkshops.warranty" />,
	// 	dataSource: 'warranty',
	// 	isSortable: true,
	// 	filterEnabled: false
	// },
	{
		header: <Translate id='accreditedWorkshops.serviceAmount' />,
		dataSource: 'tallerImporteServicio',
		isSortable: true,
		filterEnabled: false,
	},
	{
		header: <Translate id='accreditedWorkshops.expensesAmount' />,
		dataSource: 'tallerImporteGastos',
		isSortable: true,
		filterEnabled: false,
	},
]

export default serviceOrderTableConfiguration
