import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Chip from '@material-ui/core/Chip';
import { Tooltip } from '@material-ui/core';
import { Translate } from 'react-localize-redux';

function DataTablePaginationActions(props) {
    const { count, page, rowsPerPage, onChangePage, classes } = props;

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            {/* <Tooltip title={<Translate id="pagination.firstPage" />}> */}
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label={<Translate id="pagination.firstPage" />}
                >
                    <FirstPageIcon />
                </IconButton>
            {/* </Tooltip> */}
            {/* <Tooltip title={<Translate id="pagination.previousPage" />}> */}
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label={<Translate id="pagination.previousPage" />}
                >
                    <KeyboardArrowLeft />
                </IconButton>
            {/* </Tooltip> */}

            <Tooltip title={<Translate id="pagination.currentPage" />}>
                <Chip color="primary" size="small" label={page + 1} />
            </Tooltip>

            {/* <Tooltip title={<Translate id="pagination.nextPage" />}> */}
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label={<Translate id="pagination.nextPage" />}
                >
                    <KeyboardArrowRight />
                </IconButton>
            {/* </Tooltip> */}
            {/* <Tooltip title={<Translate id="pagination.lastPage" />}> */}
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label={<Translate id="pagination.lastPage" />}
                >
                    <LastPageIcon />
                </IconButton>
            {/* </Tooltip> */}
        </div>
    );
}

const style = theme => ({
    root: {
        //   flexShrink: 0,
        minWidth: 'fit-content',
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing.unit * 2
    },
});

export default withStyles(style, { withTheme: true })(DataTablePaginationActions)
