import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Avatar, CardHeader, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import { CreditCard, Send } from '@material-ui/icons'

import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import SelectDeveloper from './components/SelectDevelopment'
import Materiales from './components/Materiales'
import AddMaterialPanel from './components/AddMaterialPanel'
import AttachmentsDialog from '../common/AttachmentsDialog'
import LoadingPage from '../common/LoadingPage'

import {
	addItemInRequestPriceChange,
	initialRequestPriceChangeInDraft,
	onAddCommentRequest,
	onAddFiles,
	onRemoveFiles,
	onRemoveItemInRequestPriceChange,
	sendQuotesRequestFromRequestPriceChange,
	updateDetailPartnumberPlaneNameRequest,
} from '../../store/requestPriceChange/requestPriceChangeAction'
import * as selectors from '../../store/requestPriceChange/requestPriceChangeSelector'
import { getSupplierSelector } from '../../store/suppliers/SupplierSelectors'

import { Translate } from 'react-localize-redux'
import AddPlan from './components/addPlan'

const Create = () => {
	const dispatch = useDispatch()
	const history = useHistory()

	const [openAddMaterialPanel, setOpenAddMaterialPanel] = useState(false)
	const [developmentSelected, setDevelopmentSelect] = useState(null)
	const [developmentInMaterial, setDevelopmentInMaterial] = useState(null)
	const [materialDataPanel, setMaterialDataPanel] = useState(formData)
	const [openDialogAddFile, setOpenDialogAddFile] = useState(false)
	const [materialSelected, setMaterialSelected] = useState({})
	const [openAddPlan, setOpenAddPlan] = useState(false)
	const [comment, setComment] = useState('')

	const isLoading = useSelector((state) => selectors.getIsLoading(state))
	const requestPriceChangeDraft = useSelector((state) => selectors.getRequestPriceChangeDraft(state))
	const supplier = useSelector((state) => getSupplierSelector(state))
	const materials = requestPriceChangeDraft && requestPriceChangeDraft.details ? requestPriceChangeDraft.details : []

	const canBeSendQuote = () => {
		const isAttachmentsInAllMaterials =
			materials.filter((material) => material && material.attachments && material.attachments.length > 0)
				.length === materials.length
		const isPlanInAllMaterials =
			materials.filter((material) => material && material.filesName && material.plot_name.length > 0).length ===
			materials.length
		if (materials.length === 0) return false
		if (!developmentSelected) return false
		if (!isAttachmentsInAllMaterials && !isPlanInAllMaterials) return false
		return true
	}

	const onAddItemInRequestPriceChange = () =>
		dispatch(
			addItemInRequestPriceChange(requestPriceChangeDraft, materialDataPanel, supplier, developmentSelected)
		).then(() => {
			setMaterialDataPanel(formData)
			setOpenAddMaterialPanel(false)
		})
	const removeItemInRequestPriceChange = (itemToken) =>
		dispatch(onRemoveItemInRequestPriceChange(itemToken, supplier))

	const onMenuOptionItemClick = (item) => {
		setMaterialSelected(item)
		setOpenDialogAddFile(true)
	}
	const addFiles = (files, tokenPartNumber) =>
		dispatch(onAddFiles(files, tokenPartNumber)).then(() => setOpenDialogAddFile(false))
	const removeFiles = (files, tokenPartNumber) =>
		dispatch(onRemoveFiles(files, tokenPartNumber)).then(() => setOpenDialogAddFile(false))
	const onSendQuotesRequestFromRequestPriceChange = () =>
		dispatch(sendQuotesRequestFromRequestPriceChange()).then((response) => {
			if (response.data) {
				dispatch(initialRequestPriceChangeInDraft())
				const quoteToken = response.data
				history.push(`/quotes/${quoteToken}`)
				setDevelopmentInMaterial(null)
			}
		})
	const onAddPlanClick = (item) => {
		setOpenAddPlan(true)
		setMaterialSelected(item)
		setComment(item.comments)
	}
	const onPlotSelected = (item, plotSelectd) =>
		dispatch(updateDetailPartnumberPlaneNameRequest(item.token, plotSelectd)).then(() => {
			setOpenAddPlan(false)
			setMaterialSelected(null)
		})

	const onAddComment = (item, comments) => {
		if (item && comments)
			dispatch(onAddCommentRequest(item, comments, item.requiered_plot_art)).then(() => {
				setOpenAddPlan(false)
				setMaterialSelected(null)
			})
	}

	useEffect(() => {
		dispatch(initialRequestPriceChangeInDraft())
	}, [])
	useEffect(() => {
		if (materials && materials.length > 0) {
			const uniqueDevelopers = new Set(
				materials.map((material) =>
					JSON.stringify({
						name: material.assigned_development_name,
						user_email: material.email,
						user_name: material.developer_engineer_user_name,
					})
				)
			)
			const developersInMaterials = Array.from(uniqueDevelopers).map(JSON.parse)
			if (developersInMaterials.length === 1 && Object.keys(developersInMaterials[0]).length) {
				setDevelopmentSelect(developersInMaterials[0])
				setDevelopmentInMaterial(developersInMaterials[0])
			}
		}
	}, [materials, requestPriceChangeDraft])
	return (
		<Grid container spacing={24}>
			<Grid item xs={12}>
				<CardHeader
					avatar={
						<Avatar>
							<CreditCard />
						</Avatar>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.priceChangeRequest' />{' '}
						</Typography>
					}
					action={
						<Tooltip title={'Crear cotización'}>
							<IconButton
								disabled={!canBeSendQuote() || isLoading}
								color='primary'
								onClick={onSendQuotesRequestFromRequestPriceChange}
							>
								<Send />
							</IconButton>
						</Tooltip>
					}
				/>

				<SelectDeveloper
					onDevelopmentSelected={setDevelopmentSelect}
					developmentInMaterial={developmentInMaterial}
				/>

				{isLoading ? (
					<LoadingPage />
				) : (
					<Materiales
						tableConfiguration={TableConfiguration}
						setOpenAddMaterialPanel={setOpenAddMaterialPanel}
						developmentSelected={developmentSelected}
						materials={materials}
						removeItemInRequestPriceChange={removeItemInRequestPriceChange}
						onMenuOptionItemClick={onMenuOptionItemClick}
						onAddPlanClick={onAddPlanClick}
						companyToken={requestPriceChangeDraft ? requestPriceChangeDraft.company_token : ''}
						companyCode={requestPriceChangeDraft ? requestPriceChangeDraft.company_identifier : ''}
						kind={2}
					/>
				)}
				{openAddMaterialPanel && (
					<AddMaterialPanel
						open={openAddMaterialPanel}
						onClose={() => setOpenAddMaterialPanel(false)}
						isLoading={isLoading}
						developmentSelected={developmentSelected}
						materialDataPanel={materialDataPanel}
						setMaterialDataPanel={setMaterialDataPanel}
						onAddMaterial={onAddItemInRequestPriceChange}
					/>
				)}

				{materialSelected && (
					<AttachmentsDialog
						dropzoneText={'Seleccione un archivo o plano'}
						show={openDialogAddFile}
						item={materialSelected}
						uploadFiles={addFiles}
						removeFiles={removeFiles}
						onClose={() => setOpenDialogAddFile(false)}
					/>
				)}
				{materialSelected && openAddPlan && (
					<AddPlan
						show={openAddPlan}
						item={materialSelected}
						onClose={() => setOpenAddPlan(false)}
						onPlotSelected={onPlotSelected}
						onAddComment={onAddComment}
						comment={comment}
						setComment={setComment}
					/>
				)}
			</Grid>
		</Grid>
	)
}
const formData = {
	partNumber: '',
	volumnetMeaurement: '',
	volumen: '',
	observations: '',
}
const TableConfiguration = {
	columns: [
		{
			header: <Translate id='alternatingProveeduria.partNumber' />,
			dataSource: 'part_number',
		},
		{
			header: <Translate id='alternatingProveeduria.description' />,
			dataSource: 'part_description',
		},
		{
			header: <Translate id='alternatingProveeduria.plane' />,
			dataSource: 'plot_name',
		},
		{
			header: <Translate id='alternatingProveeduria.revision' />,
			dataSource: 'revise',
		},
		{
			header: <Translate id='alternatingProveeduria.volumen' />,
			dataSource: 'volume',
		},
		{
			header: <Translate id='alternatingProveeduria.observations' />,
			dataSource: 'comments',
		},
		{
			header: '',
			dataSource: 'options',
		},
	],
}

export default withauthenticatedLayout(withRouter(Create))
