/** Import ReactJs section */
import React, {Component} from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';

/** Import MaterialUI section */
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../common/LoadingPage';

import {Translate} from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import translations from '../../../resources/translations.json'

/** Import actions */
import { resetPasswordConfirmation } from '../../../store/suppliers/ExternalUserActions';

/** Import components */
import withAnonymousLayout from '../../layouts/withAnonymousLayout';

/** Import resources */
import '../../../resources/styles/resetpassword.css'


/**
 * Reset password 
 *
 * @class ResetPasswordConfirm
 * @extends {Component}
 */
class ResetPasswordConfirm extends Component {

    /**
     *Creates an instance of ResetPassword.
     * @param {*} props
     * @memberof ResetPasswordConfirm
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            isPasswordReset: false,
            isRequestExpired: false,
            messateToSendEmail: "",
        }
        let defaultLanguage = localStorage.getItem("language");
        if (!defaultLanguage) {
            defaultLanguage = 'es';
        }
        this.props.initialize({
            languages: [
                { name: "EspaÃ±ol", code: "es" },
                { name: "English", code: "en" },
                { name: "Portugues", code: "pt" },
            ],
            translation: translations,
            options: { 
                renderToStaticMarkup ,
                defaultLanguage: defaultLanguage
            }
        })
    }

    /**
     * componentDidMount
     *
     * @memberof ResetPasswordConfirm
     */
    componentDidMount() {
        this.props.resetPasswordConfirmation(this.props.match.params.request_token)
        .then((response) => {
            this.setState({
                isPasswordReset: true,
                messateToSendEmail: response.data,
            })    
        })
        .catch((error) => {
            this.setState({
                isRequestExpired: true,
            })
        })
    }

    /**
     * getMessage
     *
     * @returns
     * @memberof ResetPasswordConfirm
     */
    getMessage() {
        if (this.state.isRequestExpired) {
            return (
                <h6>{<Translate id="users.expired"/>}</h6>
            )
        } else if (this.state.isPasswordReset) {
            return (
                <React.Fragment>
                    <div className="alert alert-success" role="alert">
                        {<Translate id="users.passwordRestored"/>}
                        <strong>{this.state.messateToSendEmail}</strong>
                    </div>
                    <p>
                        {<Translate id="users.messagePasswordRestored"/>}
                        <strong>{this.state.messateToSendEmail}</strong>
                    </p>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="alert alert-success" role="alert">
                        {<Translate id="users.restoringPpassword"/>}
                    </div>
                    <Loader />
                </React.Fragment>
            )
        }
    }

    /**
     * Render
     *
     * @returns
     * @memberof ResetPassword
     */
    render() {
        return (
            <Paper className="container">
                {this.getMessage()}
            </Paper>
        );
    }
}

/**
 * Map state to props
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state){
    return {
    }
}
/**
 * Map dispatch to props
 */
const mapDispatchToProps = dispatch => {
    return {
        resetPasswordConfirmation:(requestToken) => {
            return dispatch(resetPasswordConfirmation(requestToken))
        }
    }
}

const styles = {
    iconPrimary: {
        color: "#9E9E9E"
    },
    iconHint: {
        color: "#FFAB00"
    },
    formControl: {
        marginBottom: "15px"
    }
}


export default withAnonymousLayout(withLocalize(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResetPasswordConfirm)))));
