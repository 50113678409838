import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'

import Select from 'react-select'

import { getEspectByPartNumber } from '../../store/partNumbers/PartNumberAction'

/**
 * Spect selector container
 *
 * @class SpectsSelected
 * @extends {SpectSelector}
 */
class SpectSelector extends Component {
	/**
	 *Creates an instance of PlotSelector.
	 * @param {*} props
	 * @memberof SpectsSelected
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			isLoading: true,
			options: [],
			errorLoading: false,
		}
	}

	/**
	 * componentWillMount
	 *
	 * @memberof SpectsSelected
	 */
	componentDidMount() {
		this.props
			.getEspectByPartNumber(this.props.specPlano, this.props.revision)
			.then((plots) => {
				if (plots) {
					let options = plots.map((plot) => {
						return {
							value: plot,
							label: plot,
						}
					})
					this.setState({
						options,
						isLoading: false,
					})
				}
			})
			.catch((error) => {
				console.error('Error getting the plots list from SAP', error)
				this.setState({
					errorLoading: true,
				})
			})
	}

	/**
	 * Get the placeholder content
	 *
	 * @returns
	 * @memberof PlotSelector
	 */
	getPlaceHolderContent() {
		if (this.state.errorLoading === true) {
			return <Translate id='plotSelector.errorLoading' />
		} else if (this.state.isLoading === true) {
			return <Translate id='common.loadingPleaseWait' />
		} else {
			return <Translate id='plotSelector.selecSpect' />
		}
	}

	/**
	 * Get the selected plot
	 *
	 * @memberof PlotSelector
	 */
	getSelectedPlot() {
		const { isLoading, options } = this.state
		if (isLoading === false && options.length > 0) {
			let selected = options.find((option) => {
				return option.value === this.props.selectedPlotName
			})
			return selected
		} else {
			return null
		}
	}

	/**
	 *
	 *
	 * @returns
	 * @memberof SpectSelector
	 */
	render() {
		return (
			<Select
				value={this.getSelectedPlot()}
				styles={this.state.errorLoading === true ? customStyles : {}}
				// isDisabled = {this.state.isLoading}
				placeholder={this.getPlaceHolderContent()}
				options={this.state.options}
				onChange={(option) => {
					this.props.onPlotSelected(option.value)
				}}
				isDisabled={this.props.isDisabled ? this.props.isDisabled || this.state.isLoading : false}
				menuPortalTarget={document.parentNode}
				menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
				menuPlacement={'auto'}
			/>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		getEspectByPartNumber: (specPlano, revision) => {
			return dispatch(getEspectByPartNumber(specPlano, revision))
		},
	}
}

const customStyles = {
	placeholder: (styles) => ({ ...styles, color: 'red' }),
	control: (styles) => ({ ...styles, borderColor: 'red' }),
	menu: (base) => ({ ...base, zIndex: 10 }),
	menuList: (base) => ({ ...base, paddingTop: 0 }),
	menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
}

export default connect(mapStateToProps, mapDispatchToProps)(SpectSelector)
