import { IsUserSupplierAdministrator } from "./SessionHelper"

export const ExportRequestStatus = {
	Undefined: 0,
	Draft: 1,
}


export const ExportRequestItemType = {
	Undefined: 0,
    NationalProvider:1, //proveedor transporte nacional
    MxProvider:2, // Agente aduanal mexicano
    ForeignTransportProvider:3, //proveedor transporte extranjero
    UsaProvider:4, //usa
}

export function canBeEditExportRequestInformation(exportRequestItem, userProfile)
{
    if(!exportRequestItem) return false
    if (!userProfile) return false
    if (userProfile.profile.company_code !== exportRequestItem.SupplierCode) return false
	let supplierAdmin = IsUserSupplierAdministrator(userProfile)
	return supplierAdmin 

}