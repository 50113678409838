import React, { Component } from 'react';
import {Translate} from "react-localize-redux";
import autoBind from 'auto-bind'

/** Material-UI imports section */
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

/**
 * Component tracking partial tap 
 */
export default class TrackingPartial extends Component{

    /**
     * TrackingPartial
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
    }

    /**
     * Method to get getQualityEvents
     */
    getQualityEvents(){
        let qualityEevents = this.props.sacQuality ? this.props.sacQuality.quality_events ? this.props.sacQuality.quality_events : [] : [];
        return (
            qualityEevents.map(event => {
                if (event.event_type === 1) {
                    let warehouseWntry = event.warehouse_entry;
                   return(<p key={event.token}><br />
                        <span class="text-success"> <i class="fa fa-truck"></i> | <Translate id="qualityfailurereport.entryWarehouse"/></span>
                            <br />
                            <span><Translate id="qualityfailurereport.folioEntry"/>: <strong>{warehouseWntry.sap_folio}</strong>  </span>         
                        <hr /></p>
                    )
                }
                return ("")

            })
        )
    }

    /**
     * Render tacking 
     */
    render(){
        let sacQuality = this.props.sacQuality;
        return(
            <div>
                <Paper> 
                    <Divider /><br/>
                    <Divider />
                    <br/>
                    <p> <strong>{sacQuality.get_successfull_warehouse_entries_counter} {" "}</strong><Translate id="qualityfailurereport.entryAdd"/> 
                    {" "}{sacQuality.warehouse_entries_required_to_close} <Translate id="qualityfailurereport.requered"/> </p>
                    <div className="progress">
                        <div className="progress-bar progress-bar-info" role="progressbar" 
                            aria-valuenow={sacQuality.get_successfull_warehouse_entries_counter} aria-valuemin="0"
                            aria-valuemax={sacQuality.warehouse_entries_required_to_close} style={{ minWidth: 40, width: sacQuality.get_progress_percentage+"%"  }} >        
                        </div>
                    </div><br/>
                    
                   {this.getQualityEvents()}

                </Paper>
            </div>
        )
    }
}

