import { createSelector } from 'reselect'
import { toSafeJS } from '../helpers/ImmutableHelper'

/**********************************************************************
 ********* Selectors for controls *************************************
 **********************************************************************/

const getScheduledVehicleControls = (state) => state.controlCO2.get('scheduledVehicleControls')
export const getScheduledVehicleControlsSelector = createSelector([getScheduledVehicleControls], (vehicles) =>
	!vehicles ? [] : toSafeJS(vehicles)
)

/**********************************************************************
 ********* Selectors for services *************************************
 **********************************************************************/
 const getServices = (state) => state.controlCO2.get('services')
 export const getServicesSelector = createSelector([getServices], (services) => (!services ? [] : toSafeJS(services)))

/**********************************************************************
 ********* Selectors for vehicles *************************************
 **********************************************************************/

const getVehicles = (state) => state.controlCO2.get('vehicles')
export const getVehiclesSelector = createSelector([getVehicles], (vehicles) => (!vehicles ? [] : toSafeJS(vehicles)))
