/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import moment from 'moment'
/**Import MaterialUi section */
import {
    Button, TextField, Grid, Typography
} from '@material-ui/core'
/**Import components section */
import DatePicker from '../../../common/DateTimePicker/DatePicker';
/**Import store section */
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'

const CHARACTER_LIMIT_PLATENUMBER = 20;
const CHARACTER_LIMIT_OPERATIVEUNIT = 100;
const CHARACTER_LIMIT_TRANSPORTTYPE = 100;
const CHARACTER_LIMIT_MATERIAL = 100;

const AddComponent = (props) => {
    const { onSaveButtonClick, classes, translate
    } = props
    const isSaving = useSelector((state) => state.warehouseWindows.get('isSaving'))

    const [requestModel, setRequestModel] = useState({
        plateNumber: "",
        deliveryDate: null,
        operativeUnit: "",
        transportType: "",
        material: "",
    });

    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (IsNullOrEmpty(requestModel.plateNumber)) return false
        if (requestModel.deliveryDate === null) return false
        if (IsNullOrEmpty(requestModel.operativeUnit)) return false
        if (IsNullOrEmpty(requestModel.transportType)) return false
        if (IsNullOrEmpty(requestModel.material)) return false
        return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(requestModel)
        }
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setRequestModel({
            ...requestModel,
            [name]: value
        });
    }

    const onChangeDate = (property, data) => {
        if ((property === 'deliveryDate') && (data !== null)) {
            setRequestModel({
                ...requestModel,
                [property]: data
            });
        }
    }

    return (
        <div>
            <div className={classes.containerInformation}>
                <Grid container >
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography color="primary">{<Translate id="warehouseWindows.create.deliveryDate" />}</Typography>
                        <div className={classes.marginTop}>
                            <DatePicker
                                onChangeRange={(event) => onChangeDate('deliveryDate', event)}
                                startDate={requestModel.deliveryDate ? moment(requestModel.deliveryDate, 'DD/MM/YYYY') : ''}
                                minDate={moment()}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" className={classes.marginLeft}>{<Translate id="warehouseWindows.create.material" />}</Typography>
                        <div className={classes.marginTop}>
                            <TextField
                                fullWidth
                                placeholder={translate("warehouseWindows.create.material")}
                                className={classes.textField}
                                name='material'
                                size="small"
                                onChange={onPropertyChange}
                                variant="outlined"
                                multiline
                                value={requestModel ? requestModel.material : ""}
                                InputProps={{
                                    inputProps: {
                                        maxLength: CHARACTER_LIMIT_MATERIAL,
                                    }
                                }}
                                helperText={`${requestModel ? requestModel.material.length : ""}/${CHARACTER_LIMIT_MATERIAL}`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary">{<Translate id="warehouseWindows.create.operativeUnit" />}</Typography>
                        <div className={classes.marginTop}>
                            <TextField
                                fullWidth
                                placeholder={translate("warehouseWindows.create.operativeUnit")}
                                className={classes.textField}
                                name='operativeUnit'
                                size="small"
                                onChange={onPropertyChange}
                                variant="outlined"
                                multiline
                                value={requestModel ? requestModel.operativeUnit : ""}
                                InputProps={{
                                    inputProps: {
                                        maxLength: CHARACTER_LIMIT_OPERATIVEUNIT,
                                    }
                                }}
                                helperText={`${requestModel ? requestModel.operativeUnit.length : ""}/${CHARACTER_LIMIT_OPERATIVEUNIT}`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary">{<Translate id="warehouseWindows.create.transportType" />}</Typography>
                        <div className={classes.marginTop}>
                            <TextField
                                fullWidth
                                placeholder={translate("warehouseWindows.create.transportType")}
                                className={classes.textField}
                                name='transportType'
                                size="small"
                                onChange={onPropertyChange}
                                variant="outlined"
                                multiline
                                value={requestModel ? requestModel.transportType : ""}
                                InputProps={{
                                    inputProps: {
                                        maxLength: CHARACTER_LIMIT_TRANSPORTTYPE,
                                    }
                                }}
                                helperText={`${requestModel ? requestModel.transportType.length : ""}/${CHARACTER_LIMIT_TRANSPORTTYPE}`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography color="primary">{<Translate id="warehouseWindows.create.plateNumber" />}</Typography>
                        <div className={classes.marginTop}>
                            <TextField
                                fullWidth
                                placeholder={translate("warehouseWindows.create.plateNumber")}
                                className={classes.textField}
                                name='plateNumber'
                                size="small"
                                onChange={onPropertyChange}
                                variant="outlined"
                                multiline
                                value={requestModel ? requestModel.plateNumber : ""}
                                InputProps={{
                                    inputProps: {
                                        maxLength: CHARACTER_LIMIT_PLATENUMBER,
                                    }
                                }}
                                helperText={`${requestModel ? requestModel.plateNumber.length : ""}/${CHARACTER_LIMIT_PLATENUMBER}`}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Grid container>
                <Grid item xs={12} className={classes.containerBtn}>
                    <Button
                        data-cy="btn-save"
                        type="button"
                        color="primary"
                        variant='outlined'
                        onClick={onSave}
                        className={classes.widthBtnSaved}
                        disabled={!canBeSaved() || isSaving}>
                        {<Translate id="common.save" />}
                    </Button>
                </Grid>
            </Grid>
        </div>

    )

}

const styles = theme => ({
    containerInformation: {
        maxHeight: `calc(100vh - ${165}px)`,
        minHeight: `calc(100vh - ${165}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    marginTop: {
        marginTop: "10px",
    },
    marginLeft: {
        marginLeft: "6px",
    },
    widthBtnSaved: {
        minWidth: "400px"
    }
});

AddComponent.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddComponent.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddComponent))