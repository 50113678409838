/** Helpers import section */
import axios from 'axios';
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserParameters,
	getAuthorizedRequestConfigurationUserFiles
} from '../helpers/SessionHelper';
import { underScoreToTitleCase } from '../helpers/StringHelper';
import { SET_SELETECT_COMPANY } from '../companies/CompanyActions';
import { GetSupplierTypeDescription } from '../helpers/ExpedientSupplier'

export const CHANGE_EXPEDIENT_SUPPLIERS_SORT_CRITERIA = 'CHANGE_EXPEDIENT_SUPPLIERS_SORT_CRITERIA';
export const CHANGE_EXPEDIENT_SUPPLIERS_SORT_QUERY = 'CHANGE_EXPEDIENT_SUPPLIERS_SORT_QUERY';
export const CHANGE_EXPEDIENT_SUPPLIERS_PAGINATION = 'CHANGE_EXPEDIENT_SUPPLIERS_PAGINATION';
export const IS_LOADING_EXPEDIENT_SUPPLIERS_INDEX = 'IS_LOADING_EXPEDIENT_SUPPLIERS_INDEX';
export const SET_EXPEDIENT_SUPPLIERS_LIST = 'SET_EXPEDIENT_SUPPLIERS_LIST';
export const SET_EXPEDIENT_SUPPLIERS_COUNT = 'SET_EXPEDIENT_SUPPLIERS_COUNT';
export const IS_SAVING_EXPEDIENT_SUPPLIER = 'IS_SAVING_EXPEDIENT_SUPPLIER';
export const IS_VALIDATE_EXPEDIENT_SUPPLIER = 'IS_VALIDATE_EXPEDIENT_SUPPLIER';

export const IS_LOADING_EXPEDIENT_SUPPLIER = 'IS_LOADING_EXPEDIENT_SUPPLIER';
export const SET_EXPEDIENT_SUPPLIER = 'SET_EXPEDIENT_SUPPLIER';
export const SET_SUPPLIER_EXPEDIENT = 'SET_SUPPLIER_EXPEDIENT';

export const UPLOAD_FILES_EXPEDIENT_SUPPLIER = 'UPLOAD_FILES_EXPEDIENT_SUPPLIER';
export const REMOVE_FILES_EXPEDIENT_SUPPLIER = 'REMOVE_FILES_EXPEDIENT_SUPPLIER';
export const SET_DOCUMENTS_CLASSIFICATIONS = 'SET_DOCUMENTS_CLASSIFICATIONS';
export const SET_SUPPLIERS_CLASSIFICATIONS = 'SET_SUPPLIERS_CLASSIFICATIONS';
export const IS_SAVING = 'IS_SAVING';
export const ADD_DOCUMENT_TYPE = 'ADD_DOCUMENT_TYPE';
export const ADD_DOCUMENT_IN_CLASSIFICATION = 'ADD_DOCUMENT_IN_CLASSIFICATION';
export const REMOVE_DOCUMENT_IN_CLASSIFICATION = 'REMOVE_DOCUMENT_IN_CLASSIFICATION';
export const REMOVE_EXPEDIENT_DOCUMENT = 'REMOVE_EXPEDIENT_DOCUMENT';
export const UPDATE_PROPERTY_EXPEDIENT_FILE = 'UPDATE_PROPERTY_EXPEDIENT_FILE';
export const UPDATE_FILE_IN_EXPEDIENT_SUPPLIER = 'UPDATE_FILE_IN_EXPEDIENT_SUPPLIER';
export const REMOVE_EXPEDIENT_CLASSIFICATION = 'REMOVE_EXPEDIENT_CLASSIFICATION';

export function removeExpedientClassification(token) {
	return {
		type: REMOVE_EXPEDIENT_CLASSIFICATION,
		token: token
	};
}

export function updatePropertyExpedientSupplier(token, propertyName, propertyValue) {
	return {
		type: UPDATE_FILE_IN_EXPEDIENT_SUPPLIER,
		token: token,
		propertyName: propertyName,
		propertyValue: propertyValue
	};
}

export function updatePropertyExpedientFileSupplier(supplierToken, fileToken, propertyName, propertyValue) {
	return {
		type: UPDATE_PROPERTY_EXPEDIENT_FILE,
		supplierToken: supplierToken,
		fileToken: fileToken,
		propertyName: propertyName,
		propertyValue: propertyValue

	};
}
export function removeExpedientDocumentAction(token) {
	return {
		type: REMOVE_EXPEDIENT_DOCUMENT,
		token: token
	};
}

export function deleteDocumentClassificaction(classificationToken, documentToken) {
	return {
		type: REMOVE_DOCUMENT_IN_CLASSIFICATION,
		classificationToken: classificationToken,
		documentToken: documentToken
	};
}

export function addDocumentClassificaction(classificationToken, documentToken, newDocuement) {
	return {
		type: ADD_DOCUMENT_IN_CLASSIFICATION,
		classificationToken: classificationToken,
		documentToken: documentToken,
		newDocuement: newDocuement
	};
}

export function addDocumentType(documentClassification) {
	return {
		type: ADD_DOCUMENT_TYPE,
		documentClassification: documentClassification
	};
}

/**
 * Action start saving information
 * @param {*} isLoading
 */
export function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
		identifier: identifier
	};
}

/**
 * Action to set suppliers list
 * @param {*} classifications
 */
function setSupplierClassificationsList(classifications) {
	return {
		type: SET_SUPPLIERS_CLASSIFICATIONS,
		classifications
	};
}

/**
 * Action to set suppliers list
 * @param {*} suppliers
 */
function setDocumentsClassificationsList(documents) {
	return {
		type: SET_DOCUMENTS_CLASSIFICATIONS,
		documents: documents
	};
}

function isLoadingList(isLoadingIndex) {
	return {
		type: IS_LOADING_EXPEDIENT_SUPPLIERS_INDEX,
		isLoadingIndex
	};
}

function setExpedientSuppliersList(expedientSuppliersList) {
	return {
		type: SET_EXPEDIENT_SUPPLIERS_LIST,
		expedientSuppliersList
	};
}

function setExpedientSuppliersCount(count) {
	return {
		type: SET_EXPEDIENT_SUPPLIERS_COUNT,
		count
	};
}

function isSavingExpedientSupplier(isSavingExpedientSupplier) {
	return {
		type: IS_SAVING_EXPEDIENT_SUPPLIER,
		isSavingExpedientSupplier: isSavingExpedientSupplier
	};
}

function isValidateExpedientSupplier(isSaving) {
	return {
		type: IS_VALIDATE_EXPEDIENT_SUPPLIER,
		isSaving: isSaving
	};
}

function isLoadingExpedientSupplier(isLoadingExpedient) {
	return {
		type: IS_LOADING_EXPEDIENT_SUPPLIER,
		isLoadingExpedient
	};
}

function setExpedientSupplier(expedientSupplier) {
	return {
		type: SET_EXPEDIENT_SUPPLIER,
		expedientSupplier
	};
}

function setSupplierExpedient(supplierExpedient) {
	return {
		type: SET_SUPPLIER_EXPEDIENT,
		supplierExpedient
	};
}

/**
 * Add file to state by supplier
 *
 * @param {*} files
 * @returns
 */
function updateFileExpedient(fileExpedient) {
	return {
		type: UPLOAD_FILES_EXPEDIENT_SUPPLIER,
		fileExpedient
	};
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFileBySupplierExpedient(fileName, tokenFile) {
	return {
		type: REMOVE_FILES_EXPEDIENT_SUPPLIER,
		fileName,
		tokenFile
	};
}

/**
 * Action to get a list of expedient suppliers by filters
 *
 * @export
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @returns
 */
export function loadExpedientSuppliersFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingList(true));
		let selectedCompany = getState().profile.get('selectedCompany');
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/all`;
			let data = {
				CompanyToken: selectedCompany.get("token"),
				SortBy: orderBy,
				SortAscending: sortAscending,
				Query: query,
				Page: `${page + 1}`,
				RowsPerPage: rowsPerPage,
			}
			return axios.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						let mappedResponse = serverResponse.data.map(item => {
							item.supplier_type_description = GetSupplierTypeDescription(item.supplier_type, item.supplier_classification)
							return item
						})
						dispatch(setExpedientSuppliersList(mappedResponse))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isLoadingList(false));
					console.error('Error getting the expedient suppliers list', error.response);
				});
		} else {
			dispatch(isLoadingList(false));
		}
	};
}

/**
 *Action to get expedient suppliers count
 *
 * @export
 * @returns
 */
export function getExpedientSuppliersCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().expedientSuppliers
			? getState().expedientSuppliers.get('expedientSuppliersCount')
			: null;
		if (actualCounter) {
			return;
		}
		let selectedCompany = getState().profile.get('selectedCompany');
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		if (selectedCompany) {
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/count`;
			let data = {
				CompanyToken: selectedCompany.get("token"),
			}
			return axios.post(endPoint, data, configuration)
				.then((serverResponse) => {
					dispatch(setExpedientSuppliersCount(serverResponse.data));
				})
				.catch((error) => {
					console.error('Error getting the expedient suppliers count', error.response);
				});
		}
	};
}

/**
 * Method to create expedient supplier
 * @param {*} expedientSupplier
 */
export function createExpedientSupplier(expedientSupplier, attachments, isCaptureManual) {
	return function (dispatch, getState) {
		dispatch(isSavingExpedientSupplier(true));

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers`;
		let companyToken = getState().profile.get('selectedCompany').get('token');
		let user = getState().oidc.user;

		let formData = new FormData();
		attachments.forEach((file) => {
			formData.append('file', file);
		});
		formData.set('CompanyToken', companyToken);
		formData.set('IsManual', isCaptureManual);
		formData.set('SupplierCode', expedientSupplier.supplier ? expedientSupplier.supplier.code : "");
		formData.set('Name', expedientSupplier.name);
		formData.set('CreatedBy', user.profile.name);
		formData.set('CreatedUserName', user.profile.preferred_username);
		formData.set('EmailCrator', typeof user.profile.email === 'string' ? user.profile.email : user.profile.email[0]);

		return axios
			.post(endPoint, formData, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingExpedientSupplier(false));
					return Promise.resolve(response.data);
				} else {
					dispatch(isSavingExpedientSupplier(false));
				}
			})
			.catch((error) => {
				console.error('Error create expedient supplier', error.response);
				dispatch(isSavingExpedientSupplier(false));
				return Promise.reject(error.response.data.Message);
			});
	};
}

/**
 * Method to create expedient supplier
 * @param {*} expedientSupplier
 */
export function validateExpedientSuppliersFile(attachments) {
	return function (dispatch, getState) {
		dispatch(isValidateExpedientSupplier(true));

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/validate`;

		let formData = new FormData();
		attachments.forEach((file) => {
			formData.append('file', file);
		});
		return axios
			.post(endPoint, formData, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(isValidateExpedientSupplier(false));
					return Promise.resolve(response.data);
				} else {
					dispatch(isValidateExpedientSupplier(false));
				}
			})
			.catch((error) => {
				console.error('Error validate expedient file with supplier', error.response);
				dispatch(isValidateExpedientSupplier(false));
				return Promise.reject(error.response.data.Message);
			});
	};
}

/**
 * Action set expedient supplier the estate from read from backend
 * @param {*} token
 */
export function loadExpedientSupplier(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingExpedientSupplier(true));
		let companyToken = getState().profile.get('selectedCompany').get('token');
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/expedient/${token}`;
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					if (serverResponse.data.Company.token === companyToken) {
						let response = serverResponse.data;
						let mappedResponse = response.expedient_update_for_suppliers.map(item => {
							item.supplier_type_description = GetSupplierTypeDescription(item.supplier_type, item.supplier_classification)
							return item
						})
						response.expedient_update_for_suppliers = mappedResponse;
						dispatch(setExpedientSupplier(response));
					} else {
						dispatch({
							type: SET_SELETECT_COMPANY,
							actualCompany: serverResponse.data.Company
						});
						window.location.reload();
					}
				}
			})
			.catch((error) => {
				console.error('Error to load expedient supplier', error.response);
				dispatch(isLoadingExpedientSupplier(false));
				return Promise.reject();
			});
	};
}

/**
 * Action to get a list of expedients by supplier by filters
 *
 * @export
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @returns
 */
export function loadExpedientsBySupplierFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingList(true));
		let selectedCompany = getState().profile.get('selectedCompany');
		let supplierCode = undefined;
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code;
		}
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/supplier/all`;

			let data = {
				CompanyToken: selectedCompany.get("token"),
				SupplierCode: supplierCode,
				SortBy: orderBy,
				SortAscending: sortAscending,
				Query: query,
				Page: `${page + 1}`,
				RowsPerPage: rowsPerPage,
			}
			return axios.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						let mappedResponse = serverResponse.data.map(item => {
							item.supplier_type_description = GetSupplierTypeDescription(item.supplier_type, item.supplier_classification)
							return item
						})
						dispatch(setExpedientSuppliersList(mappedResponse))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isLoadingList(false));
					console.error('Error getting the expedient suppliers list', error.response);
				});
		} else {
			dispatch(isLoadingList(false));
		}
	};
}

/**
 *Action to get expedients by supplier count
 *
 * @export
 * @returns
 */
export function getExpedientsBySupplierCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().expedientSuppliers
			? getState().expedientSuppliers.get('expedientSuppliersCount')
			: null;
		if (actualCounter) {
			return;
		}

		let selectedCompany = getState().profile.get('selectedCompany');
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let supplierCode = undefined;
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code)
			supplierCode = getState().oidc.user.profile.company_code;

		if (selectedCompany) {
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/supplier/count`;

			let data = {
				CompanyToken: selectedCompany.get("token"),
				SupplierCode: supplierCode,
			}

			return axios.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setExpedientSuppliersCount(serverResponse.data));
					}
				})
				.catch((error) => {
					console.error('Error getting the expedient suppliers count', error.response);
				});
		}
	};
}

/**
 * Action set expedient supplier the estate from read from backend
 * @param {*} token
 */
export function loadExpedientBySupplierToken(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingExpedientSupplier(true));

		let companyToken = getState().profile.get('selectedCompany').get('token');
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/expedienBytSupplier/${token}`;
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.data.Company.token === companyToken) {
					dispatch(setSupplierExpedient(serverResponse.data));
				} else {
					dispatch({
						type: SET_SELETECT_COMPANY,
						actualCompany: serverResponse.data.Company
					});
					window.location.reload();
				}
			})
			.catch((error) => {
				console.error('Error to load expedient by supplier', error.response);
				dispatch(isLoadingExpedientSupplier(false));
				return Promise.reject();
			});
	};
}

/**
 * Action to upload files to supplier from expedient file
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadFilesSupplierExpedient(fileToken, files) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/expedienBytSupplier/${fileToken}/upload`;
		let formData = new FormData();
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index]);
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateFileExpedient(serverResponse.data));
					return Promise.resolve();
				}
			})
			.catch((error) => {
				console.error('Error to upload sample files from supplier file(s)', error.response);
				return Promise.reject();
			});
	};
}

/**
 * Action to remove files to supplier from saplme
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeFilesShippingOrder(responseToken, fileName, folder, listItems) {
	return function (dispatch, getState) {
		let params = {
			name: fileName
		};
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/${responseToken}//remove/${folder}`;
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFileBySupplierExpedient(fileName, listItems));
				}
				return Promise.resolve();
			})
			.catch((error) => {
				console.error('Error to upload shipping order file(s)', error.response);
				return Promise.reject();
			});
	};
}

/**
 * Action to update property files
 * @param {*} fileToken
 * @param {*} property
 * @param {*} propertyData
 */
export function updatePropertyFileSupplier(fileToken, property, propertyData) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/expedienBytSupplier/${fileToken}`;
		let data = {
			[underScoreToTitleCase(property)]: propertyData
		};
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateFileExpedient(serverResponse.data));
				}
			})
			.catch((error) => {
				console.error('Error to update property', error.response);
				return Promise.reject();
			});
	};
}

/**
 * Update consent data processing police value
 * @param {*} token 
 * @param {*} consent 
 * @returns 
 */
export function updateConsentDataProcessingBySupplier(token, consent) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/expedienBytSupplier/update/${token}`;
		let data = {
			ConsentDataProcessing: consent !== null ? consent : false,
		};
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierExpedient(serverResponse.data));
				}
			})
			.catch((error) => {
				console.error('Error to update property', error.response);
				return Promise.reject();
			});
	};
}

/**
 * Action to send expedient by upplier
 * @param {*} expedientSupplierToken
 */
export function sendExpedientBySupplier(expedientSupplierToken, status) {
	return (dispatch, getState) => {
		dispatch(isSavingExpedientSupplier(true));

		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let selectedCompany = getState().profile.get('selectedCompany');
		let data = {
			ExpedientToken: expedientSupplierToken,
			ExpedientStatus: status,
			UpdateBy: user.profile.name,
			CompanyCode: selectedCompany.get('company_code'),
		};
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/updatestatusexpedient`;
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierExpedient(serverResponse.data));
					dispatch(isSavingExpedientSupplier(false));
					return Promise.resolve();
				}
			})
			.catch((error) => {
				dispatch(isSavingExpedientSupplier(false));
				console.error('Error in send expedient by supplier ', error.response);
				return Promise.reject(error.response.data.Message);
			});
	};
}

/**
 * Action to update status file
 * @param {*} token
 * @param {*} disposition
 */
export function updateStatusFileInPlant(token, status) {
	return (dispatch, getState) => {
		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let data = {
			FileExpedientStatus: status
		};
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/expedienBytSupplier/${token}`;
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateFileExpedient(serverResponse.data));
					return Promise.resolve();
				}
			})
			.catch((error) => {
				console.error('Error in update status file ', error.response);
				return Promise.reject();
			});
	};
}

/**
 * Action to update status expedient
 * @param {*} expedientSupplierToken
 * @param {*} status
 */
export function updateStatusExpedient(expedientSupplierToken, status, comments) {
	return (dispatch, getState) => {
		dispatch(isSavingExpedientSupplier(true));

		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let selectedCompany = getState().profile.get('selectedCompany');
		let data = {
			ExpedientToken: expedientSupplierToken,
			ExpedientStatus: status,
			UpdateBy: user.profile.name,
			Comments: comments,
			CompanyCode: selectedCompany.get('company_code'),
		};
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/updatestatusexpedient`;
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierExpedient(serverResponse.data));
					dispatch(isSavingExpedientSupplier(false));
					return Promise.resolve();
				}
			})
			.catch((error) => {
				dispatch(isSavingExpedientSupplier(false));
				console.error('Error in update expedient by supplier ', error.response);
				return Promise.reject(error.response.data.Message);
			});
	};
}
/**
 * Action to update status expedient
 * @param {*} expedientSupplierToken
 * @param {*} status
 */
export function deleteExpedient(expedientSupplierToken, status, comments) {
	return (dispatch, getState) => {
		dispatch(isSavingExpedientSupplier(true));

		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let selectedCompany = getState().profile.get('selectedCompany');
		let data = {
			ExpedientToken: expedientSupplierToken,
			ExpedientStatus: status,
			UpdateBy: user.profile.name,
			Comments: comments,
			CompanyCode: selectedCompany.get('company_code'),
		};
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/delete/expedient`;
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					// dispatch(setSupplierExpedient(serverResponse.data));
					dispatch(isSavingExpedientSupplier(false));
					return Promise.resolve();
				}
			})
			.catch((error) => {
				dispatch(isSavingExpedientSupplier(false));
				console.error('Error in update expedient by supplier ', error.response);
				return Promise.reject(error.response.data.Message);
			});
	};
}
/**
 * Action to send reminder from all supplier in process expedient
 * @param {*} expedientToken
 */
export function sendReminderToSuppliersToUpdateExpedient(expedientToken) {
	return (dispatch, getState) => {
		dispatch(isSavingExpedientSupplier(true));

		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let data = {};
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/expedient/${expedientToken}/sendremindersuppliers`;
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setExpedientSupplier(serverResponse.data));
					dispatch(isSavingExpedientSupplier(false));
					return Promise.resolve();
				}
			})
			.catch((error) => {
				dispatch(isSavingExpedientSupplier(false));
				console.error('Error in send reminder by all supplier', error.response);
				return Promise.reject(error.response.data.Message);
			});
	};
}

/**
 *Action to download report general of expedients by range date
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadExpedientsReportByRangeDate(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(isSavingExpedientSupplier(true));
		let companyToken = getState().profile.get('selectedCompany').get('token');
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate
		};
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/downloadReportGeenralByRangeDate`;
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingExpedientSupplier(false));
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					});
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = 'Reporte_general_expedientes.xlsx';
					a.click();
				}
			})
			.catch((error) => {
				dispatch(isSavingExpedientSupplier(false));
				console.error('Error downloading general expedients report by month', error.response);
				return Promise.reject();
			});
	};
}

/**
 *Action to download report file expedient supplier
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadFileClassificationReport() {
	return (dispatch, getState) => {
		dispatch(isSavingExpedientSupplier(true));
		let data = {};
		let selectedCompany = getState().profile.get('selectedCompany');
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/downloadClassificationReport/${selectedCompany.get('company_code')}`;
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingExpedientSupplier(false));
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					});
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = 'Reporte_de_clasificación_archivo.xlsx';
					a.click();
				}
			})
			.catch((error) => {
				dispatch(isSavingExpedientSupplier(false));
				console.error('Error downloading file classification report', error.response);
				return Promise.reject();
			});
	};
}

/**
 * Action to create document
 * @param {*}
 */
export function createDocumentType(expedientModel) {
	return (dispatch, getState) => {
		dispatch(isSavingExpedientSupplier(true));

		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let selectedCompany = getState().profile.get('selectedCompany');
		let data = {
			CompanyCode: selectedCompany.get('company_code'),
			Nomenclature: expedientModel ? expedientModel.nomenclature : '',
			Name: expedientModel ? expedientModel.documentName : '',
			Comments: expedientModel ? expedientModel.comments : '',
			FileType: expedientModel ? expedientModel.format.value : ''
		};
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/documents`;
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addDocumentType(serverResponse.data));
					dispatch(isSavingExpedientSupplier(false));
					return Promise.resolve();
				}
			})
			.catch((error) => {
				dispatch(isSavingExpedientSupplier(false));
				console.error('Error in send reminder by all supplier', error.response);
				return Promise.reject(error.response.data.Message);
			});
	};
}

/**
 * Action to create document
 * @param {*}
 */
export function updateDocumentType(expedientModel) {
	return (dispatch, getState) => {
		dispatch(isSavingExpedientSupplier(true));

		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let data = {
			Nomenclature: expedientModel ? expedientModel.nomenclature : '',
			Name: expedientModel ? expedientModel.documentName : '',
			Comments: expedientModel ? expedientModel.comments : '',
			FileType: expedientModel ? expedientModel.format.value : ''
		};
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/documents/${expedientModel.token}`;
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addDocumentType(serverResponse.data));
					dispatch(isSavingExpedientSupplier(false));
				}
				return Promise.resolve();
			})
			.catch((error) => {
				dispatch(isSavingExpedientSupplier(false));
				console.error('Error in update docuemnt type', error.response);
				return Promise.reject(error.response.data.Message);
			});
	};
}

export function loadAllDocumentsClasiffications() {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let selectedCompany = getState().profile.get('selectedCompany');
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/${selectedCompany.get(
			'company_code'
		)}/documents`;
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDocumentsClassificationsList(serverResponse.data));
				}
				return Promise.resolve(serverResponse.data);
			})
			.catch((error) => {
				console.error('Error getting the differenst documents classifications', error.response);
				return Promise.reject();
			});
	};
}

export function loadAllSupplierClasiffications() {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let selectedCompany = getState().profile.get('selectedCompany');
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/${selectedCompany.get(
			'company_code'
		)}/classifications`;
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierClassificationsList(serverResponse.data));
				}
				return Promise.resolve(serverResponse.data);
			})
			.catch((error) => {
				console.error('Error getting the differenst supplier classifications', error.response);
				return Promise.reject();
			});
	};
}

export function removeFileInAttachment(token, fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName
		};
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/documents/${token}/evidences`;
		dispatch(isSavingCommon(true));
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updatePropertyExpedientSupplier(token, 'attachments', serverResponse.data.attachments))
					dispatch(isSavingCommon(false));
					return Promise.resolve();
				}
				return Promise.resolve();
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));

				console.error('Error to upload order file(s) from supplier', error.response);
				return Promise.reject();
			});
	};
}

export function addEvidenceInAttachment(token, files) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true));
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/documents/${token}/evidences`;
		let formData = new FormData();
		files.forEach((file) => {
			formData.append('data', file);
		});
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false));
					dispatch(updatePropertyExpedientSupplier(token, 'attachments', serverResponse.data.attachments))
					return Promise.resolve(serverResponse.data.attachments);
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				console.error('Error add attachments in the action plan', error.response);
			});
	};
}

export function addDocumentInClassification(classificationToken, documentToken) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/classifications/${classificationToken}/documents`;
		dispatch(isSavingCommon(true));

		let command = {
			ClassificationToken: classificationToken,
			DocumentToken: documentToken
		};
		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false));

					dispatch(addDocumentClassificaction(classificationToken, documentToken, serverResponse.data));
				}
				return Promise.resolve(serverResponse.data);
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));

				console.error('Error in add document in classifications', error.response.data.Message);
				return Promise.reject(error.response.data.Message);
			});
	};
}

export function removeDocumentInClassification(classificationToken, documentToken) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/classifications/${classificationToken}/documents/${documentToken}`;

		dispatch(isSavingCommon(true));

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false));
					dispatch(deleteDocumentClassificaction(classificationToken, documentToken));
				}
				return Promise.resolve(serverResponse.data);
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				console.error('Error delete document classifications', error.response.data.Message);
				return Promise.reject(error.response.data.Message);
			});
	};
}

export function removeExpedientSupplier(expedientToken, supplier) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsupplier/${expedientToken}/${supplier.token}/removesuppliers`;
		dispatch(isSavingCommon(true));
		return axios.get(endPoint, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingCommon(false));
				}
				return Promise.resolve();
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				console.error("Error deleting supplier", error.response);
				return Promise.reject();
			})
	}
}

export function removeExpedientDocument(documentToken) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/documents/${documentToken}`;

		dispatch(isSavingCommon(true));
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false));
					dispatch(removeExpedientDocumentAction(documentToken));
				}
				return Promise.resolve(serverResponse.data);
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				console.error('Error delete document classifications', error.response.data.Message);
				return Promise.reject(error.response.data.Message);
			});
	};
}

export function addOrRemoveDocumentInExpedientSupplier(expedientToken, file) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/expedient/updateFilesupplier`;
		dispatch(isSavingCommon(true));

		let command = {
			ExpedientToken: expedientToken,
			FileToken: file ? file.token : '',
			IsRequired: file ? !file.is_requiered : false
		};
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false));
					dispatch(updatePropertyExpedientFileSupplier(expedientToken, command.FileToken, 'is_requiered', command.IsRequired));
				}
				return Promise.resolve(serverResponse.data);
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				console.error('Error in add document in supplier', error.response.data.Message);
				return Promise.reject(error.response.data.Message);
			});
	};
}

export function setStatusExpedientSupplier(expedient, status, comments) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true));
		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/status`;
		let selectedCompany = getState().profile.get('selectedCompany');
		let command = {
			ExpedientToken: expedient ? expedient.token : '',
			ExpedientStatus: status,
			Comments: comments,
			CompanyCode: selectedCompany.get('company_code'),
			UserName: user.profile.preferred_username,
		}
		return axios.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false));
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				console.error("Error update status folio", error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function downloadSuppliersClassificationsReport(classificationName) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true));
		let selectedCompany = getState().profile.get('selectedCompany');
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/downloadreport`;
		let command = {
			ClassificationName: classificationName,
			CompanyCode: selectedCompany.get('company_code'),
		}
		return axios.post(endPoint, command, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingCommon(false));
					var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = `Reporte_${classificationName}.xlsx`
					a.click();
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				console.error("Error in download suppliers classification ", error.response)
				return Promise.reject();
			})
	}
}

export function deleteAllClassification(token) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true));
		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let selectedCompany = getState().profile.get('selectedCompany');
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/${token}/delete/${selectedCompany.get('company_code')}`;
		return axios.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false));
					dispatch(removeExpedientClassification(token))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				console.error("Error delete all classification", error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function updateColumnInExpedientSupplier(expedientToken, nomenclatureName, value) {
	return function (dispatch, getState) {
		let companyToken = getState().profile.get('selectedCompany').get('token');
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/expedient/updateFileColumnSupplier`;
		dispatch(isSavingCommon(true));

		let command = {
			ExpedientToken: expedientToken ? expedientToken : "",
			NomenclatureName: nomenclatureName ? nomenclatureName : "",
			IsRequired: value ? value : false,
		};
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					if (serverResponse.data.Company.token === companyToken) {
						let response = serverResponse.data;
						let mappedResponse = response.expedient_update_for_suppliers.map(item => {
							item.supplier_type_description = GetSupplierTypeDescription(item.supplier_type, item.supplier_classification)
							return item
						})
						response.expedient_update_for_suppliers = mappedResponse;
						dispatch(setExpedientSupplier(response));
						dispatch(isSavingCommon(false));
					} else {
						dispatch({
							type: SET_SELETECT_COMPANY,
							actualCompany: serverResponse.data.Company
						});
						window.location.reload();
						dispatch(isSavingCommon(false));
					}
				}
				return Promise.resolve(serverResponse.data);
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				console.error('Error in add document in supplier', error.response.data.Message);
				return Promise.reject(error.response.data.Message);
			});
	};
}

export function forwardNotificationToProviderUser(token, userName) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true));
		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/${token}/notification/${userName}`;
		return axios.put(endPoint, {}, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false));
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function moveDocument(id, toIndex) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true));
		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/moveDocument`;
		let selectedCompany = getState().profile.get('selectedCompany');
		let command = {
			CompanyCode: selectedCompany.get('company_code'),
			DocToUpdateId: id,
			ToIndex: toIndex
		}
		return axios.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false));
					dispatch(setDocumentsClassificationsList(serverResponse.data));
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				console.error("Error update status folio", error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update status document
 * @param {*} expedientToken 
 * @param {*} documentToken 
 * @returns 
 */
export function saveDocument(expedientToken, documentToken) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true));
		let user = getState().oidc.user;
		let configuration = getAuthorizedRequestConfigurationUser(user);
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsupplier/senddocument`;
		let command = {
			ExpedientToken: expedientToken,
			DocumentToken: documentToken
		}
		return axios.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false));
					dispatch(setSupplierExpedient(serverResponse.data));
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingCommon(false));
				console.error("Error, enter valid token ", error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}