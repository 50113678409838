import React from 'react'
import propTypes from 'prop-types'

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core'

import { Translate } from 'react-localize-redux'
import PlotSelector from '../../common/PlotSelector'

const AddPlan = ({ show, item, onClose, onAddComment, onPlotSelected, setComment, comment }) => {
	return (
		<Dialog open={show}>
			<DialogTitle id='form-dialog-title'>
				<Translate id='deliveryOrderModule.materialsTable.plot' />: {item.part_number}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Typography>
							<Translate id='plotSelector.addPlots' />
						</Typography>
						<PlotSelector
							specPlano={item.plano_number}
							revision={item.revise}
							kind={2}
							selectedPlotName={item.plot_name}
							onPlotSelected={(plotSelectd) => {
								onPlotSelected(item, plotSelectd, 2)
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography>
							<Translate id='plotSelector.addComments' />
						</Typography>
						<TextField
							key={'comments'}
							fullWidth
							variant='outlined'
							multiline
							rowsMax='3'
							value={comment ? comment : ''}
							onChange={(event) => {
								setComment(event.target.value)
							}}
							onBlur={() => onAddComment(item, comment)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color='primary'>
					<Translate id='common.close' />
				</Button>
			</DialogActions>
		</Dialog>
	)
}
AddPlan.propTypes = {
	open: propTypes.bool,
	comment: propTypes.string,
	onClose: propTypes.func,
	onPlotSelected: propTypes.func,
	onAddComment: propTypes.func,
	setComment: propTypes.func,
}
AddPlan.defaultProps = {
	open: false,
	comment: '',
	onClose: () => console.warn('No [onClose] CallBack defined'),
	onPlotSelected: () => console.warn('No [onPlotSelected] CallBAck defined'),
	onAddComment: () => console.warn('No [onAddComment] CallBAck defined'),
	setComment: () => console.warn('No [setComment] CallBack defined'),
}
export default AddPlan
