import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import SendEmailIcon from '@material-ui/icons/Email'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import LoadingPage from '../../common/LoadingPage'
import Toaster from '../../common/Toaster'

import {
	loadQualityFailureReportFromBackEnd,
	registerDeviationFailureReport,
	getFolioDeviationDataFromBackend,
} from '../../../store/qualityFailureReport/QualityFailureReportAction'

/**
 * Container to send notification
 */
class RegisterDeviation extends Component {
	/**
	 * Create an instance of raw material
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			folioDeviation: '',
			statusDeviation: '-',
			notExtistFolio: '',
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let qualityFailureToken = this.props.match.params.token
		this.props.loadQualityFailureReport(qualityFailureToken)
	}

	/**
	 * Funcion on update folio to get deviation
	 * @param {*} folio
	 */
	onUpdateFolioDeviaiton(folioDeviation) {
		this.setState({
			folioDeviation: folioDeviation,
		})
	}

	/**
	 * Can be add folio deviation
	 */
	canBeSaved() {
		if (this.state.folioDeviation === '') return false
		if (this.state.statusDeviation === '-') return false
		return true
	}

	/**
	 * on key Down to get folio
	 * @param {*} event
	 */
	onKeyDown(event) {
		if (event.key === 'Enter') {
			this.registerDeviation()
		}
	}

	/**
	 * Functions to register deviation
	 */
	registerDeviation() {
		this.props
			.getFolioDeviationDataFromBackend(this.state.folioDeviation)
			.then((response) => {
				this.setState({
					statusDeviation: response.description_status,
					notExtistFolio: response.description,
				})
			})
			.catch(() => {
				this.setState({
					statusDeviation: '-',
					notExtistFolio: <Translate id='rawmaterialnonconformity.folioNotExist' />,
					showToaster: true,
					toasterMessage: <Translate id='common.errorToAdd' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Get action to send corrective action
	 */
	sendNotificationNonconformity() {
		this.props
			.registerDeviationFailureReport(this.state.folioDeviation)
			.then(() => {
				this.setState({
					showToView: true,
					showToaster: false,
				})
				let qualityFailureToken = this.props.match.params.token
				this.props.history.push(`/qualityfailurereport/${qualityFailureToken}`)
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { classes } = this.props
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.qualityFailure === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let qualityFailure = this.props.qualityFailure
			return (
				<Grid container>
					<Grid item xs={12} sm={6}>
						<div>
							<TextField
								autoFocus
								className={classes.textField}
								label={<Translate id='rawmaterialnonconformity.folioDeviation' />}
								placeholder='ex. DE-00118-19'
								onKeyDown={this.onKeyDown}
								onChange={(event) => {
									this.onUpdateFolioDeviaiton(event.target.value)
								}}
								helperText={
									<FormHelperText id='component-error-text'>
										<Translate id='rawmaterialnonconformity.messageFolioDeviation' />
									</FormHelperText>
								}
							/>
							<br />
						</div>

						<TextField
							disabled={true}
							id='standard-read-only-input'
							label={<Translate id='rawmaterialnonconformity.parNumberNonConform' />}
							value={qualityFailure.full_part_description}
							className={classes.textField}
							margin='normal'
							onChange={(event) => {
								this.onUpdateFolioDeviaiton('full_part_description', event.target.value)
							}}
						/>

						<TextField
							disabled={true}
							id='standard-read-only-input'
							label={<Translate id='rawmaterialnonconformity.quantityNonConform' />}
							value={qualityFailure.quantity_of_material_in_invoice}
							className={classes.textField}
							margin='normal'
							onChange={(event) => {
								this.onUpdateFolioDeviaiton('quantity_of_material_in_invoice', event.target.value)
							}}
						/>

						<TextField
							disabled={true}
							id='standard-read-only-input'
							label={<Translate id='rawmaterialnonconformity.statusFolio' />}
							value={this.state.statusDeviation}
							className={classes.textField}
							margin='normal'
							onChange={(event) => {
								this.onUpdateFolioDeviaiton('statusDeviation', event.target.value)
							}}
							helperText={
								<FormHelperText error id='component-error-text'>
									{this.state.notExtistFolio}
								</FormHelperText>
							}
						/>

						<div className={classes.textField}>
							<Button
								data-cy='btn-save'
								type='button'
								color='primary'
								onClick={this.sendNotificationNonconformity}
								disabled={!this.canBeSaved() || this.props.isSaving}
							>
								{<Translate id='common.save' />}
							</Button>
							<Button
								onClick={() => {
									this.props.history.push(
										`/rawmaterialnonconformity/${this.props.match.params.token}`
									)
								}}
								color='secondary'
							>
								{<Translate id='common.cancel' />}
							</Button>
						</div>
					</Grid>
				</Grid>
			)
		}
	}

	render() {
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<SendEmailIcon />
						</Avatar>
					}
					title={
						<Typography variant='button'>
							<Translate id='rawmaterialnonconformity.registerFolioDeviaiton' />
						</Typography>
					}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let qualityFailure = state.qualityFailureReport.get('qualityFailureReport')
	return {
		isLoading: state.qualityFailureReport.get('isLoading'),
		isSaving: state.qualityFailureReport.get('isSaving'),
		qualityFailure: qualityFailure ? qualityFailure.toJS() : null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadQualityFailureReport: (token) => {
			return dispatch(loadQualityFailureReportFromBackEnd(token))
		},
		getFolioDeviationDataFromBackend: (folio) => {
			return dispatch(getFolioDeviationDataFromBackend(folio))
		},
		registerDeviationFailureReport: (folio) => {
			return dispatch(registerDeviationFailureReport(folio))
		},
	}
}

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	actionsToolbar: {
		flexGrow: 1,
	},
	textField: {
		marginRight: theme.spacing.unit,
		width: 600,
		marginLeft: 200,
		marginTop: 20,
		marginBottom: 10,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RegisterDeviation)))
)
