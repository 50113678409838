import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import SendEmailIcon from '@material-ui/icons/Email'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import LoadingPage from '../../common/LoadingPage'
import Toaster from '../../common/Toaster'
import DataTable from '../../common/DataTable'

import { ROLE_SUPPLIER_QUALITYNGINEER } from '../../../store/helpers/RolesHelper'
import { searchUsersInRole } from '../../../store/administration/InternalActions'
import { loadSacQualityFromBackEnd, sendNotificationToSupplier } from '../../../store/sacsQuality/SacsQualityActions'

/**
 * Container to send notification
 */
class SendNotification extends Component {
	/**
	 * Create an instance of raw material
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			users: null,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let sacQualityToken = this.props.match.params.token
		this.props.loadSacQuality(sacQualityToken)
	}

	// /**
	//  * componentDidUpdate
	//  * @param {*} prevProps
	//  * @param {*} prevState
	//  * @memberof Index
	//  */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoading !== this.props.isLoading && this.props.sacQuality !== null) {
			/// Get information full from corrective
			let sacQuality = this.props.sacQuality
			/// Get User by role from supplier
			this.props
				.searchUsers(ROLE_SUPPLIER_QUALITYNGINEER, sacQuality.supplier_code ? sacQuality.supplier_code : null)
				.then((response) => {
					this.setState({ users: response })
				})
		}
	}

	/**
	 * Get action to send corrective action
	 */
	sendNotificationNonconformity() {
		let sacQualityToken = this.props.match.params.token
		this.props
			.sendNotificationToSupplier(sacQualityToken)
			.then(() => {
				this.setState({
					showToView: true,
					showToaster: false,
				})
				this.props.history.push(`/sacsquality/${sacQualityToken}`)
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { classes } = this.props
		if (this.props.isLoadingUsers === true || this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.sacQuality === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<Grid container>
					<Grid item xs={12}>
						<div className={classes.textField}>
							<Typography variant='subtitle1' gutterBottom>
								<strong>
									{' '}
									<Translate id='rawmaterialnonconformity.supplierDevelopmentEngineer' />:{' '}
								</strong>
								{this.props.sacQuality.development_engineer_non_conformity}
							</Typography>
						</div>

						<div className={classes.textField}>
							<DataTable
								data={this.state.users !== null ? this.state.users : []}
								configuration={TableConfiguration}
							/>
						</div>

						<div className={classes.textField}>
							<Button
								data-cy='btn-save'
								type='button'
								color='primary'
								onClick={this.sendNotificationNonconformity}
								disabled={this.props.isSaving}
							>
								{<Translate id='common.save' />}
							</Button>
							<Button
								onClick={() => {
									this.props.history.push(`/sacsquality/${this.props.match.params.token}`)
								}}
								color='secondary'
							>
								{<Translate id='common.cancel' />}
							</Button>
						</div>
					</Grid>
				</Grid>
			)
		}
	}

	render() {
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<SendEmailIcon />
						</Avatar>
					}
					title={
						<Typography variant='button'>
							<Translate id='sacsQuality.sacQualityNotification' />
						</Typography>
					}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='rawmaterialnonconformity.usersSupplierQuality' />,
			dataSource: 'name',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let sacQuality = state.sacsQuality.get('sacQuality')
	return {
		isLoading: state.sacsQuality.get('isLoading'),
		sacQuality: sacQuality ? sacQuality.toJS() : null,
		isSaving: state.sacsQuality.get('isSaving'),

		isLoadingUsers: state.suppliers.get('isLoadingUsers'),
		externalUsers: state.externalUsers.get('externalUsers')
			? state.externalUsers.get('externalUsers').toJS()
			: null,
		sortCriteria: state.suppliers.get('sortCriteria') ? state.suppliers.get('sortCriteria').toJS() : null,
		searchQuery: state.suppliers.get('searchQuery'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSacQuality: (token) => {
			return dispatch(loadSacQualityFromBackEnd(token))
		},
		sendNotificationToSupplier: (token) => {
			return dispatch(sendNotificationToSupplier(token))
		},
		searchUsers: (role, supplierCode) => {
			return dispatch(searchUsersInRole(role, supplierCode))
		},
	}
}

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	actionsToolbar: {
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 500,
		marginTop: 20,
		marginBottom: 10,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(SendNotification)))
)
