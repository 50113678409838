import React, { useState } from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

/** Import component section */
import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';

/**
 * DeleteCardConfirmation
*/
const DeleteCardConfirmation = (props) => {
    const { classes } = props;
    const { open, onClose, isDeleteSubTopic, isSaving,
        isDeleteTopic, isDeleteQuestion, onSaveItem, topicId,
        subTopicId, questionId,} = props

        const [itemModel] = useState({
            topicId: topicId,
            subTopicId: subTopicId,
            questionId: questionId})

    /** Function on save update **/
     const onSaveItemUpdate = () =>{
        if(props.onSaveItem)
        onSaveItem(itemModel);
    }

    return (
          <Dialog
            open = {open}
            onClose={onClose}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {isDeleteTopic && <Translate id="questionnaire.deleteTopic" />}
                            {isDeleteSubTopic && <Translate id="questionnaire.deleteSubTopic"/>}
                            {isDeleteQuestion && <Translate id="questionnaire.deleteQuestion"/>}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={"SHOW_EDIT_VIEW"}
                        classes={classes}
                        onCancel={onClose}
                        onClose={onClose}
                        onSaveItem={onSaveItemUpdate}
                        isSaving={isSaving}
                        canBeSaved={true}
                    />
                }>

                <SnackbarContent style={{backgroundColor:"white", color:"#212529"}}
                    message={
                        <>
                            {isDeleteTopic && <Translate id="questionnaire.messageRemoveSection" />}
                            {isDeleteSubTopic && <Translate id="questionnaire.messageRemoveSubTopic"/>}
                            {isDeleteQuestion && <Translate id="questionnaire.messageRemoveQuestion"/>}
                        </>
                    }
                />
            </Dialog>
    )
}

DeleteCardConfirmation.propTypes = {
    open: PropTypes.bool,
    isDeleteTopic: PropTypes.bool,
    isDeleteQuestion: PropTypes.bool,
    isDeleteSubTopic: PropTypes.bool,
};

DeleteCardConfirmation.defaultProps = {
    open: false,
    isDeleteTopic: false,
    isDeleteQuestion: false,
    isDeleteSubTopic: false,
};

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
})

export default withStyles(styles)(DeleteCardConfirmation);