import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import LoadingPage from '../../common/LoadingPage'
import SearchInput from '../../common/SearchInput/SearchInput'
import DataTable from '../../common/DataTable'
import EthicTemplate from '../ethicCode/EthicTemplate'
import Dialog from '../../common/Dialog'
import Toaster from '../../common/Toaster'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import AddIcon from '@material-ui/icons/AddTwoTone'
import Fab from '@material-ui/core/Fab'
import EthicCodeIcon from '@material-ui/icons/ListAlt'
import IndexViewIcon from '@material-ui/icons/ViewList'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import {
	IconButton,
	Tooltip,
	Grid,
	Button,
	TextField,
	FormControl,
	FormControlLabel,
	Checkbox,
	Divider,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Paper,
} from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import Select from 'react-select'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import { converListToArray } from '../../../store/helpers/ListHelper'
import { ROLE_COMPANYADMINISTRATOR, IsUserInRole } from '../../../store/helpers/RolesHelper'
import {
	loadEthicCodesFromBackEnd,
	CHANGE_ETHIC_CODES_SORT_CRITERIA,
	getTemplateByToken,
	addQuestionInTemplatedFromBackend,
	removeQuestionInTemplatedFromBackend,
	CHANGE_ETHIC_CODES_SORT_QUERY,
	getEthicCodesCount,
	CHANGE_ETHIC_CODES_PAGINATION,
} from '../../../store/ethicCode/ethicCodeAction'
import { GetQuestionType } from '../../../store/helpers/SelectOptions'
import {
	showErrorToaster, //showInfoToaster,
	showSuccessToaster,
} from '../../../store/helpers/ToasterHelpers'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants'

class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showIndex: true,
			openModalQuestion: false,
			...this.initialStateQuestionData(),
		}
	}

	initialStateQuestionData(initialData = null) {
		return {
			selectedQuestionToken: initialData ? initialData.token : null,
			name: initialData ? initialData.name : '',
			questionType: initialData ? initialData.question_type : '',
			requireAttachment: initialData ? initialData.require_attachement : '',
			percentage: initialData ? initialData.percentage : '',
			questionOptions: initialData ? initialData.multiple_options : [],
			correctOptionIndex: initialData
				? initialData.multiple_options.findIndex(
						(option) => option.token === initialData.token_option_correctly
				  )
				: '',
			commentPossibleResponse: initialData ? initialData.comment_possible_response : '',
		}
	}

	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadEthicCodes(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
			this.props.getEthicCodesCount()
			this.props.getTemplateByToken()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadEthicCodes()
			this.props.getEthicCodesCount()
			this.props.getTemplateByToken()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery
		) {
			this.props.loadEthicCodes(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'logo') {
			return (
				<Avatar data-cy='oficial-avatar'>
					<EthicCodeIcon />
				</Avatar>
			)
		}
		if (dataSource === 'is_accepted_by_supplier' && item.is_accepted_by_supplier) {
			return 'Si'
		} else if (dataSource === 'is_accepted_by_supplier' && item.is_accepted_by_supplier) {
			return 'No'
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Function to add new official notice
	 */
	onClickAddButton() {
		this.props.history.push(`/ethiccode/create`)
	}

	/* Switch view between index and calendar
	 *
	 * @memberof Index
	 */
	toggleView() {
		this.setState({ showIndex: !this.state.showIndex })
	}

	/**
	 * on click to add question in templated
	 */
	onClickAddEditQuestion(selectedQuestionToken = null) {
		var questionData = null
		if (!IsNullOrEmpty(selectedQuestionToken)) {
			questionData = this.props.templateEthicCode.questions.find(
				(question) => question.token === selectedQuestionToken
			)
		}
		this.setState({
			openModalQuestion: true,
			...this.initialStateQuestionData(questionData),
		})
	}

	/**
	 * Get notices counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getEthicCodesCounterMessage() {
		const { classes } = this.props
		if (this.props.ethicCodes) {
			let totalRecords = this.props.ethicCodes.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{totalRecords}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.ethicCodesCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingEthicCodes === true) {
			return <LoadingPage />
		} else if (this.props.ethicCodes === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.ethicCodes}
					configuration={TableConfiguration}
					onRenderCellItem={this.onRenderCellItem}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(ethicCode) => {
						this.props.history.push(`/ethiccode/${ethicCode.token}`)
					}}
					showFilters
					isIndex
				/>
			)
		}
	}

	/**
	 * Handle update properties to set data in local state
	 *
	 * @param {*} property
	 * @param {*} data
	 * @memberof
	 */
	updateProperty(property, data) {
		this.setState({
			[property]: data,
		})
	}

	updateListOptions() {
		var options = this.state.questionOptions
		if (options === undefined) options = []
		var numberOption = this.state.questionOptions.length + 1
		options = options.concat([
			{
				OptionName: this.state.OptionName,
				CorrectOption: this.state.CorrectOption,
				Number: numberOption,
				QuestionToken: this.state.selectedQuestionToken,
			},
		])

		this.setState({
			questionOptions: options,
			OptionName: null,
			CorrectOption: false,
		})
	}

	onChangeCorrectOption(indexCorrectOption) {
		let options = this.state.questionOptions.map((option, index) => {
			option.Number = index + 1
			if (index === indexCorrectOption) option.CorrectOption = true
			else option.CorrectOption = false
			return option
		})
		this.setState({
			questionOptions: options,
			correctOptionIndex: indexCorrectOption,
		})
	}

	/**
	 * On close and clear modal
	 */
	onCloseModalAddEditQuestion() {
		this.setState({
			openModalQuestion: false,
			selectedQuestionToken: null,
			name: '',
			questionType: '',
			numberResponse: '',
			percentage: '',
		})
	}

	/**
	 * On Create new question in templated
	 */
	onAddQuestionInTemplated() {
		if (this.state.questionType === 2) {
			this.props
				.addNewQuestionInTemplated(this.props.templateEthicCode.token, this.state)
				.then(() => {
					this.onCloseModalAddEditQuestion()
				})
				.catch((error) => {
					this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToAdd'))
				})
		} else {
			this.props
				.addNewQuestionInTemplated(this.props.templateEthicCode.token, this.state)
				.then(() => {
					this.onCloseModalAddEditQuestion()
				})
				.catch((error) => {
					this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToAdd'))
				})
		}
	}

	/**
	 * Function to remive question from template
	 * @param {*} selectedQuestionToken
	 */
	onRemoveQuestionInTemplate(selectedQuestionToken) {
		this.props
			.removeQuestionInTemplated(this.props.templateEthicCode.token, selectedQuestionToken)
			.then(() => {
				this.setState(showSuccessToaster('common.removeCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToAdd'))
			})
	}

	removeOptionOfQuestion(optionToken) {
		this.setState({
			questionOptions: this.state.questionOptions.filter((option) => option.token !== optionToken),
		})
	}

	onEditQuestionInTemplated(selectedQuestionToken) {
		this.props
			.onEditQuestionInTemplated(this.props.templateEthicCode.token, selectedQuestionToken)
			.then(() => {
				this.setState(showSuccessToaster('common.removeCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToAdd'))
			})
	}

	/**
	 * Render Text Area component
	 *
	 * @param {*} key
	 * @param {*} translationId
	 * @returns
	 * @memberof
	 */
	renderInput(translationId, key, lg = 12, options, type, isMultiple = false) {
		return (
			<Grid item xs={12} sm={12} md={12} lg={lg} xl={lg} className={this.props.classes.textField}>
				{(type === 'text' || type === 'number') && (
					<TextField
						autoFocus={key === 'name'}
						key={key}
						fullWidth
						type={type}
						label={<Translate id={translationId} />}
						value={this.state[key] ? this.state[key] : ''}
						onChange={(event) => {
							this.updateProperty(key, event.target.value)
						}}
						multiline={isMultiple}
						rowsMax={isMultiple ? 3 : 1}
						variant={isMultiple ? 'outlined' : 'standard'}
					/>
				)}
				{type === 'selected' && (
					<div>
						<Typography variant='caption' color={'textSecondary'}>
							<Translate id={translationId} />
						</Typography>
						<Select
							defaultValue={options[this.state[key] ? this.state[key] : 0]}
							className={this.props.classes.documentTypeSelect}
							options={options}
							onChange={(event) => this.updateProperty(key, event.value)}
							styles={{
								menu: (base) => ({ ...base, zIndex: 62 }),
								menuPortal: (base) => ({ ...base, zIndex: 9999 }),
							}}
							menuPortalTarget={document.parentNode}
							menuPlacement={'bottom'}
							menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
						/>
					</div>
				)}
				{type === 'checkbox' && (
					<FormControl margin='none'>
						<FormControlLabel
							control={
								<Checkbox
									value={key}
									checked={this.state[key]}
									onChange={(event) => {
										this.updateProperty(key, event.target.checked)
									}}
								/>
							}
							label={
								<Typography variant='body2' color='textSecondary'>
									<Translate id={translationId} />
								</Typography>
							}
						/>
					</FormControl>
				)}
			</Grid>
		)
	}

	/**
	 * Render Add Question
	 */
	renderAddQuestion() {
		const questionType = GetQuestionType()
		let xsButton = {
			maxWidth: 30,
			maxHeight: 30,
			paddingTop: 3,
			backgroundColor: BACKGROUND_COLOR_GRAY,
			color: 'red',
		}
		return (
			<div style={{ margin: 10, width: 500 }}>
				<Grid container spacing={24}>
					{this.renderInput('surveys.questionName', 'name', 12, null, 'text')}
					{this.renderInput('surveys.questionType', 'questionType', 6, questionType, 'selected')}
					{this.state.questionType === 1 && //OPEN ANSWER
						this.renderInput('surveys.requireAttachment', 'requireAttachment', 6, null, 'checkbox')}
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
						<Divider />
					</Grid>
					{this.state.questionType === 2 &&
						this.renderInput('surveys.optionName', 'OptionName', 10, null, 'text')}
					{this.state.questionType === 2 && (
						<Grid item xs={2} className={this.props.classes.textField}>
							<Fab
								title={'agregar'}
								size='small'
								color='primary'
								onClick={() => {
									this.updateListOptions()
								}}
								disabled={IsNullOrEmpty(this.state.OptionName)}
							>
								<AddIcon />
							</Fab>
						</Grid>
					)}
					{this.state.questionType === 2 && ( //MULTIPLE OPTIONS
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
							<Paper style={{ width: '100%' }}>
								<Typography variant='body1'>
									<Translate id='surveys.optionsList' />
								</Typography>
								<List dense disablePadding style={{ maxHeight: 150, overflowY: 'auto' }}>
									{this.state.questionOptions !== undefined &&
										this.state.questionOptions.map((option, index) => {
											option.CorrectOption = true
											return (
												<ListItem dense divider key={index}>
													<ListItemText>{option.OptionName}</ListItemText>
													<ListItemSecondaryAction>
														<IconButton
															id={option.token}
															style={xsButton}
															title={this.props.translate('common.delete')}
															onClick={(event) => {
																this.removeOptionOfQuestion(event.currentTarget.id)
															}}
														>
															<DeleteIcon color='error' />
														</IconButton>
													</ListItemSecondaryAction>
												</ListItem>
											)
										})}
									{(this.state.questionOptions === undefined ||
										this.state.questionOptions.length < 1) && (
										<ListItem dense divider alignItems='center'>
											<ListItemText>
												<Translate id='surveys.noOptionsAdded' />
											</ListItemText>
										</ListItem>
									)}
								</List>
							</Paper>
						</Grid>
					)}
				</Grid>
			</div>
		)
	}

	renderModalButtons() {
		return (
			<DialogActions>
				<Button
					disabled={!this.state.name || !this.state.questionType || this.props.isSavingEthicCode}
					onClick={() => this.onAddQuestionInTemplated()}
					color='primary'
				>
					<Translate id='common.save' />
				</Button>
				<Button onClick={() => this.onCloseModalAddEditQuestion()} color='secondary'>
					<Translate id='common.cancel' />
				</Button>
			</DialogActions>
		)
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof OfficialNotices
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<EthicCodeIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							{this.state.showIndex && <SearchInput onChangeSearchValue={this.onChangeSearchValue} />}
							{IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) &&
								this.state.showIndex && (
									<Fab
										size='small'
										color='primary'
										onClick={() => {
											this.onClickAddButton()
										}}
									>
										<AddIcon />
									</Fab>
								)}
							{!this.state.showIndex && (
								<Tooltip title={'Agregar nueva pregunta'}>
									<Fab
										size='small'
										color='secondary'
										onClick={() => {
											this.onClickAddEditQuestion()
										}}
									>
										<AddIcon />
									</Fab>
								</Tooltip>
							)}
							<Tooltip
								title={
									<Translate
										id={this.state.showIndex ? 'surveys.showTemplate' : 'surveys.showIndex'}
									/>
								}
							>
								<IconButton
									color='primary'
									value
									onClick={() => {
										this.toggleView()
									}}
								>
									{this.state.showIndex ? <IndexViewIcon /> : <EthicCodeIcon />}
								</IconButton>
							</Tooltip>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='surveys.surveysTitle' />
						</Typography>
					}
					subheader={this.state.showIndex && this.getEthicCodesCounterMessage()}
				/>

				<CardContent>
					{this.state.showIndex && this.getCardContent()}
					{!this.state.showIndex && (
						<EthicTemplate
							isLoadingEthicCode={this.props.isLoadingEthicCode}
							removeQuestionInTemplated={this.onRemoveQuestionInTemplate}
							editQuestionInTemplated={(selectedQuestionToken) =>
								this.onClickAddEditQuestion(selectedQuestionToken)
							}
							templateEthicCode={this.props.templateEthicCode}
							isUserAdmin={IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany)}
						/>
					)}
				</CardContent>

				{/** Dialog for add new question*/}
				<Dialog
					open={this.state.openModalQuestion}
					onClose={() => this.onCloseModalAddEditQuestion()}
					header={
						<Typography style={{ margin: 10 }}>
							<Translate id='surveys.addQuestion' />
						</Typography>
					}
					children={this.renderAddQuestion()}
					actions={this.renderModalButtons()}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'logo',
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='surveys.name' />,
			dataSource: 'name',
			isSortable: true,
		},
		{
			header: <Translate id='surveys.isAcceptRequired' />,
			dataSource: 'is_accepted_by_supplier',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		user: user,
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingEthicCodes: state.ethicCodes.get('isLoadingEthicCodes'),
		ethicCodes: state.ethicCodes.get('ethicCodes') ? state.ethicCodes.get('ethicCodes').toJS() : null,
		sortCriteria: state.ethicCodes.get('sortCriteria') ? state.ethicCodes.get('sortCriteria').toJS() : null,
		searchQuery: state.ethicCodes.get('searchQuery'),
		ethicCodesCount: state.ethicCodes.get('ethicCodesCount'),
		isLoadingEthicCode: state.ethicCodes.get('isLoadingEthicCodes'),
		templateEthicCode: state.ethicCodes.get('templateEthicCode')
			? state.ethicCodes.get('templateEthicCode').toJS()
			: null,
		isSavingEthicCode: state.ethicCodes.get('isSavingEthicCode'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadEthicCodes: (query, orderBy, sortAscending) => {
			dispatch(loadEthicCodesFromBackEnd(query, orderBy, sortAscending))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_ETHIC_CODES_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_ETHIC_CODES_SORT_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_ETHIC_CODES_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getEthicCodesCount: () => {
			dispatch(getEthicCodesCount())
		},
		getTemplateByToken: () => {
			dispatch(getTemplateByToken())
		},
		addNewQuestionInTemplated: (tokenTemplated, question) => {
			return dispatch(addQuestionInTemplatedFromBackend(tokenTemplated, question))
		},
		removeQuestionInTemplated: (tokenTemplated, selectedQuestionToken) => {
			return dispatch(removeQuestionInTemplatedFromBackend(tokenTemplated, selectedQuestionToken))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
