
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import {Translate} from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

/**
 * Component ot render Information partial devolution
 */
export default class InformationPartial extends Component{

    /**
     * Create an instance devolution
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
    }

    /**
     * Render
     */
    render() {
        const { classes} = this.props;
        let item = null;
        if(this.props.devolution.items.length !== 0){
            item = this.props.devolution.items[0];
        }
        return (
            <Paper>
                <List>
                    <ListItem>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="common.supplier"/>
                                </Typography>
                                {": "}{ this.props.devolution.full_supplier}
                            </React.Fragment>
                        } />
                    </ListItem>
                    <Divider />
                   {item && item.non_conformity &&  <ListItem>
                        <ListItemText
                         secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="devolutions.nonConformity"/>
                                </Typography>
                                {": "}{item && item.non_conformity}
                            </React.Fragment>
                        }/>
                    </ListItem>}
                    {item && item.non_conformity &&<Divider />}
                    <ListItem>
                        <ListItemText
                         secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="devolutions.receptionInspector"/>
                                </Typography>
                                {": "}{item && item.reception_inspector}
                            </React.Fragment>
                        }/>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                         secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="devolutions.planningEngineer"/>
                                </Typography>
                                {": "}{item && item.planning_engineer_name}
                            </React.Fragment>
                        }/>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                         secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="devolutions.supplierDevelopmentEngineer"/>
                                </Typography>
                                {": "}{item && item.development_engineer_name}
                            </React.Fragment>
                        }/>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                         secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="devolutions.carrierName"/>
                                </Typography>
                                {": "}{this.props.devolution.carrier_name}
                            </React.Fragment>
                        }/>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                         secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="devolutions.carrierUnitPlates"/>
                                </Typography>
                                {": "}{this.props.devolution.carrier_unit_plates}
                            </React.Fragment>
                        }/>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                         secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="devolutions.tokenDate"/>
                                </Typography>
                                {": "}{this.props.devolution.taken_by_supplier_date}
                            </React.Fragment>
                        }/>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                         secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="common.comments"/>
                                </Typography>
                                {": "}{item && item.comments}
                            </React.Fragment>
                        }/>
                    </ListItem>

                    <Divider />
                </List>
            </Paper>
        );
    }
}

/**
 * devolution
 */
InformationPartial.propTypes = {
    devolution: PropTypes.object.isRequired
}