import React from 'react'
import { Translate } from 'react-localize-redux'
/* Material UI import section */
import { makeStyles } from '@material-ui/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'

/** Custom components import section */
import DataTable from '../../../common/DataTable'

const FactoringAffiliationTable = (props) => {
	const classes = useStyles()
	//Functions props sections
	const { onRedirectToDetail, onOpenConfirmDeleteDialog, onOpenEditCatDialog, canBeEditInformation, onChangePage } =
		props
	//array props sections
	const { items = [] } = props.factoringAffiliationPaginated
	//int props sections
	const { pageNumber, pageSize, total, sort } = props.factoringAffiliationPaginated

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'Options' && canBeEditInformation) {
			return (
				<div>
					<IconButton
						size='small'
						onClick={() => onOpenConfirmDeleteDialog(item)}
						className={classes.iconButton}
					>
						<DeleteIcon />
					</IconButton>
					<IconButton size='small' onClick={() => onOpenEditCatDialog(item)}>
						<EditIcon />
					</IconButton>
				</div>
			)
		}
		if (dataSource === 'name') {
			return item.name
		}
	}

	return (
		<DataTable
			data={items}
			configuration={{ columns: tableConfiguration(canBeEditInformation) }}
			onRenderCellItem={onRenderCellItem}
			isIndex
			page={pageNumber}
			rowsPerPage={pageSize}
			totalRows={total}
			sortBy={sort.by}
			sortAscending={sort.direction}
			onChangePage={onChangePage}
			onRowClick={onRedirectToDetail}
		/>
	)
}

/**
 * Set the service orders table configuration
 */
const tableConfiguration = (canBeEditInformation) => {
	let defaultColumns = [
		{
			header: <Translate id='factoringAffiliation.table.formattedFolio' />,
			dataSource: 'formattedFolio',
			isMovable: false,
		},
		{
			header: <Translate id='factoringAffiliation.table.supplierCode' />,
			dataSource: 'supplierCode',
			isMovable: false,
		},
		{
			header: <Translate id='factoringAffiliation.table.supplierName' />,
			dataSource: 'supplierName',
			isMovable: false,
		},
		{
			header: <Translate id='factoringAffiliation.table.supplierRFC' />,
			dataSource: 'supplierRFC',
			isMovable: false,
		},
		{
			header: <Translate id='factoringAffiliation.table.phoneNumber' />,
			dataSource: 'supplierPhoneNumber',
			isMovable: false,
		},
		{
			header: <Translate id='factoringAffiliation.table.email' />,
			dataSource: 'supplierEmail',
			isMovable: false,
		},
		{
			header: <Translate id='factoringAffiliation.table.options' />,
			dataSource: 'Options',
			isMovable: false,
		},
	]
	return defaultColumns
}

const useStyles = makeStyles((theme) => ({
	flex: {
		display: 'flex',
	},
}))

export default FactoringAffiliationTable
