import React, { Component } from 'react';

/** Language imports **/
import {Translate} from "react-localize-redux";

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

/**
 * Component to add or edit worker
 */
export default class DialogEditWorker extends Component{

    render(){
        const { classes } = this.props;
        let worker = this.props.worker;
        return(
            <form className={classes.ediitForm}>
                    <TextField fullWidth autoFocus
                        className={classes.textField}
                        error={this.props.validateData("name")}
                        label={<Translate id="warehouseWorkers.name"/>}
                        defaultValue={worker.name}
                        onChange={(event) => {this.props.updateProperty("name", event.target.value)}}
                    /><br/><br/>

                    <FormControlLabel
                        control={
                        <Checkbox
                            onChange={(event) => {this.props.updateProperty('status', event.target.checked)}}     
                            color="primary"
                            value="status"
                            checked={worker.status}
                        />
                        }
                        label={<Translate id="warehouseWorkers.status"/>}
                    /><br/><br/>

            </form>  
        )
    }

}