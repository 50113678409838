
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';

/**
 * Component ot render Information partialraw material not conformiti
 */
export default class InformationPartial extends Component {

    /**
     * Create an instance guaranteeReport not conformity
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    renderListItem(content) {
        return (<ListItem divider>
            <ListItemText
                secondary={content}
            />
        </ListItem>)
    }

    renderDataLabel(translationId, data) {
        return (<>
            <Typography component="span" variant="body2" className={this.props.classes.inline} color="textPrimary">
                <Translate id={translationId} />
            </Typography>
            {": "}{(data && !IsNullOrEmpty(data)) ? data : ''}
        </>)
    }
    /**
     * Render
     */
    render() {
        const { classes } = this.props;
        let guaranteeReport = this.props.guaranteeReport;
        return (
            <Paper> <Divider />
                <List>
                    {this.renderListItem(this.renderDataLabel("common.supplier", guaranteeReport.full_supplier))}
                    {this.renderListItem(this.renderDataLabel("common.partNumber", guaranteeReport.full_part_description))}
                    {this.renderListItem(this.renderDataLabel("common.preparedBy", guaranteeReport.user_name_report))}
                    {this.renderListItem(<>
                        {this.renderDataLabel("moduleGuarantee.reportGuarantee.reportType", guaranteeReport.report_type)}
                        {" | "}
                        {(guaranteeReport.serial_number && !IsNullOrEmpty(guaranteeReport.serial_number)) && this.renderDataLabel("moduleGuarantee.serialNumber", guaranteeReport.serial_number)}
                    </>)}
                    {this.renderListItem(<>
                        {this.renderDataLabel("moduleGuarantee.reportGuarantee.amountReportedFault", <Badge color="secondary" className={classes.margin} badgeContent={guaranteeReport.quantity_of_material_in_invoice} max={9999} />)}
                        {" | "}
                        {this.renderDataLabel("moduleGuarantee.reportGuarantee.numberPiecesReceibed", <Badge color="secondary" className={classes.margin} badgeContent={guaranteeReport.quantity_of_material_affected} max={9999}></Badge>)}
                    </>)}
                    {this.renderListItem(<>
                        {this.renderDataLabel("moduleGuarantee.reportGuarantee.purchaseOrde", guaranteeReport.purchase_order_number)}{" | "}
                        {this.renderDataLabel("moduleGuarantee.reportGuarantee.docMaterial", guaranteeReport.document_material)}{" | "}
                        {this.renderDataLabel("moduleGuarantee.reportGuarantee.dateOrder", guaranteeReport.delivery_date_purchaseOrder)}
                    </>)}
                    {this.renderListItem(<>
                        {this.renderDataLabel("moduleGuarantee.reportGuarantee.unitPrice", guaranteeReport.unit_price)}
                        {" | "}
                        {this.renderDataLabel("moduleGuarantee.reportGuarantee.totalAmount", guaranteeReport.cost)}
                        {" | "}
                        {this.renderDataLabel("moduleGuarantee.reportGuarantee.arrivalDate", guaranteeReport.arrival_date_purchase)}
                    </>)}
                    {this.renderListItem(this.renderDataLabel("moduleGuarantee.reportGuarantee.problemDescription", guaranteeReport.problem_description))}
                    {this.renderListItem(this.renderDataLabel("moduleGuarantee.reportGuarantee.serviceOrderRepare", guaranteeReport.service_order_repare))}
                </List>

            </Paper>
        );
    }
}


/**
 * guaranteeReport
 */
InformationPartial.propTypes = {
    guaranteeReport: PropTypes.object.isRequired
}