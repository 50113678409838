import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

/** Custom components import section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader';
import SapMessages from './SapMessages';

/** Actions import section */
import {
	loadAttempErrors,
	loadPaymentRequestDetail
} from '../../../../store/accreditedWorkshops/accreditedWorkshopsActions';

/** Redux selectors imoprt section */
import { getPaymentRequestSelector } from '../../../../store/accreditedWorkshops/accreditedWorkshopsSelectors';

const PaymentRequestErrors = (props) => {
	const { token, onClose } = props;

	/** Local state */
	const [loading, setLoading] = useState(false);

	/** Connect with redux */
	const dispatch = useDispatch();
	const paymentRequest = useSelector((state) => getPaymentRequestSelector(state));

	useEffect(() => {
		setLoading(true);
		dispatch(loadPaymentRequestDetail(token)).finally(() => setLoading(false));
	}, [dispatch, token]);

	const handleAttempClick = (attemp) => {
		dispatch(loadAttempErrors(token, attemp.creationDate));
	};

	return (
		<LateralPanel>
			<PanelHeader
				isIconMaterial={true}
				//icon={AddIcon}
				titlePanel="Solicitud de pago"
				onCloseButtonClick={onClose}
			/>
			<SapMessages
				key="attemp-requests-list"
				loading={loading}
				attemps={paymentRequest ? paymentRequest.attemps : []}
				onAttempClick={handleAttempClick}
			/>
		</LateralPanel>
	);
};

PaymentRequestErrors.propTypes = {
	onClose: PropTypes.func.isRequired
};

PaymentRequestErrors.defaultProp = {
	onClose: () => console.log('[onClose] is not defined!')
};

export default PaymentRequestErrors;
