import React, { Component } from 'react'
import autoBind from 'auto-bind'
import propTypes from 'prop-types'

/** Material UI components imports*/
import { withStyles } from '@material-ui/core/styles'
import {
	Paper,
	Switch,
	ExpansionPanel,
	ExpansionPanelSummary,
	Typography,
	ExpansionPanelDetails,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Divider,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import modules from './Modules'

/** Redux imports */
import { Translate } from 'react-localize-redux'

class MenuConfiguration extends Component {
	/**
	 *Creates an instance of MenuConfiguration.
	 * @param {*} props
	 * @param {*} context
	 * @memberof MenuConfiguration
	 */
	constructor(props, context) {
		super(props, context)
		autoBind(this)
		this.state = {
			checked: false,
		}
	}

	/**
	 * method to render module section
	 *
	 * @param {*} moduleName
	 * @param {*} module
	 * @param {*} sections
	 * @returns
	 * @memberof MenuConfiguration
	 */
	renderModule(moduleName, module, sections) {
		return (
			<ExpansionPanel key={moduleName}>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography className={this.props.classes.headerClass}>
						<Translate id={'companies.modules.' + moduleName} />
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<div style={{ flexBasis: '100%' }}>
						<List>
							<Divider />
							{Object.keys(module).map((section) => {
								return (
									<ListItem key={section} divider>
										<ListItemText
											primary={<Translate id={'companies.functionModules.' + module[section]} />}
										/>
										<ListItemSecondaryAction>
											<Switch
												checked={sections[section]}
												onChange={(event) => this.props.toggleActiveMenu(event.target)} //event.target.checked,
												value={module[section]}
												inputProps={{ 'aria-label': 'primary checkbox' }}
											/>
										</ListItemSecondaryAction>
									</ListItem>
								)
							})}
						</List>
					</div>
				</ExpansionPanelDetails>
				<Divider />
			</ExpansionPanel>
		)
	}

	/**
	 * perform render component
	 *
	 * @returns
	 * @memberof MenuConfiguration
	 */
	render() {
		const { moduleEnabledStatus } = this.props

		return (
			<Paper>
				<ModuleComponent
					classes={this.props.classes}
					module={modules.qualityModule}
					enabledStatus={moduleEnabledStatus}
					toggleActiveMenu={this.props.toggleActiveMenu}
				/>
				<ModuleComponent
					classes={this.props.classes}
					module={modules.administrationModule}
					enabledStatus={moduleEnabledStatus}
					toggleActiveMenu={this.props.toggleActiveMenu}
				/>
				<ModuleComponent
					classes={this.props.classes}
					module={modules.suppliersDevelopmentModule}
					enabledStatus={moduleEnabledStatus}
					toggleActiveMenu={this.props.toggleActiveMenu}
				/>
				<ModuleComponent
					classes={this.props.classes}
					module={modules.importsModule}
					enabledStatus={moduleEnabledStatus}
					toggleActiveMenu={this.props.toggleActiveMenu}
				/>
				<ModuleComponent
					classes={this.props.classes}
					module={modules.consultationsModule}
					enabledStatus={moduleEnabledStatus}
					toggleActiveMenu={this.props.toggleActiveMenu}
				/>
				<ModuleComponent
					classes={this.props.classes}
					module={modules.planningModule}
					enabledStatus={moduleEnabledStatus}
					toggleActiveMenu={this.props.toggleActiveMenu}
				/>
				<ModuleComponent
					classes={this.props.classes}
					module={modules.accreditedWorkshopsModule}
					enabledStatus={moduleEnabledStatus}
					toggleActiveMenu={this.props.toggleActiveMenu}
				/>
				<ModuleComponent
					classes={this.props.classes}
					module={modules.sustainability}
					enabledStatus={moduleEnabledStatus}
					toggleActiveMenu={this.props.toggleActiveMenu}
				/>
				<ModuleComponent
					classes={this.props.classes}
					module={modules.consignmentNote}
					enabledStatus={moduleEnabledStatus}
					toggleActiveMenu={this.props.toggleActiveMenu}
				/>
				<ModuleComponent
					classes={this.props.classes}
					module={modules.supplierProspectus}
					enabledStatus={moduleEnabledStatus}
					toggleActiveMenu={this.props.toggleActiveMenu}
				/>
			</Paper>
		)
	}
}

const ModuleComponent = (props) => {
	const { module, enabledStatus, toggleActiveMenu } = props
	const { id, items } = module

	return (
		<ExpansionPanel key={id}>
			<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
				<Typography className={props.classes.headerClass}>
					<Translate id={'companies.modules.' + id} />
				</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
				<div style={{ flexBasis: '100%' }}>
					<List>
						<Divider />
						{items.map((moduleItem) => {
							return (
								<ListItem key={moduleItem} divider>
									<ListItemText
										primary={<Translate id={'companies.functionModules.' + moduleItem} />}
									/>
									<ListItemSecondaryAction>
										<Switch
											checked={enabledStatus[moduleItem]}
											onChange={(event) => toggleActiveMenu(event.target)} //event.target.checked,
											value={moduleItem}
											inputProps={{ 'aria-label': 'primary checkbox' }}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							)
						})}
					</List>
				</div>
			</ExpansionPanelDetails>
			<Divider />
		</ExpansionPanel>
	)
}
ModuleComponent.propTypes = {
	moduleItems: propTypes.object,
}
ModuleComponent.defaultProps = {
	moduleItems: {},
}

const viewStyles = (theme) => ({
	headerClass: {
		color: theme.palette.primary.main,
	},
})

export default withStyles(viewStyles, { withTheme: true })(MenuConfiguration)
