///Import react section
import React from 'react'
import { withRouter } from 'react-router-dom'
/** Material UI imports section */
import { withStyles, Card } from '@material-ui/core'
/** Import component section */
import LayoutHeaderWorkFlow from '../../../common/LayoutHeaderWorkFlow'
import Loader from '../../../common/LoadingPage'
import HeaderComponent from './HeaderComponent'
import FactoringTable from './FactoringTable'
import { Translate } from 'react-localize-redux'
const FactoringAffiliationDetail = (props) => {
	//Array props section
	const { userRolesByCompany } = props
	//Object props section
	const { selectedCompany, factoringAffiliation } = props
	//Functions
	const { onRedirectToCreateFactoringAfiliation, onRowClick, onUpdateSupplierFactoring } = props
	//bool props section
	const { isInternalUser, isLoading } = props
	if (isLoading) return <Loader />
	return (
		<Card>
			<LayoutHeaderWorkFlow
				workFlow={{
					folio: factoringAffiliation ? factoringAffiliation.FormattedFolio : '-',
					origin: '-',
					create_by: factoringAffiliation ? factoringAffiliation.createdBy : '-',
					full_supplier: factoringAffiliation ? factoringAffiliation.supplierName : '-',
					class_status: 'badge badge-success',
					actual_operation_description: 'Factoraje',
				}}
				company={selectedCompany}
				title={<Translate id='factoringAffiliation.title' />}
			/>
			<HeaderComponent
				factoringAffiliation={factoringAffiliation}
				onUpdateSupplierFactoring={onUpdateSupplierFactoring}
				userRolesByCompany={userRolesByCompany}
			/>
			<FactoringTable
				onRedirectToCreateFactoringAfiliation={onRedirectToCreateFactoringAfiliation}
				factoringAffiliation={factoringAffiliation}
				onRowClick={onRowClick}
				isInternalUser={isInternalUser}
				userRolesByCompany={userRolesByCompany}
			/>
		</Card>
	)
}

const styles = (theme) => ({})
export default withRouter(withStyles(styles, { withTheme: true })(FactoringAffiliationDetail))
