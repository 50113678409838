import { fromJS } from 'immutable';

import * as medicalChecksActions from './MedicalCheckActions';
/** Initial state */
export const medicalChecksInitialState = fromJS({
    isLoadingChecks: true,
    medicalchecks: [],
    sortCriteria: {
        by: "folio",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    medicalchecksCount: null,
    isSavingChecks: false,
    medicalcheck: null,
    isLoadingMedical: false,
})

export default function servicesProjectsReducer(state = medicalChecksInitialState, action) {
    switch (action.type) {
        case medicalChecksActions.IS_LOADING_MEDICAL_CHECKS: {
            return state.merge({
                isLoadingChecks: action.isLoadingChecks
            })
        }
        case medicalChecksActions.SET_MEDICAL_CHECKS_LIST: {
            let medicalchecksLists = fromJS(action.medicalchecks);
            return state.merge({
                medicalchecks: medicalchecksLists,
                isLoadingChecks: false
            })
        }
        case medicalChecksActions.CHANGE_MEDICAL_CHECKS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case medicalChecksActions.CHANGE_MEDICAL_CHECKS_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case medicalChecksActions.CHANGE_MEDICAL_CHECKS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case medicalChecksActions.SET_MEDICAL_CHECKS_COUNT: {
            return state.merge({
                medicalchecksCount: action.count
            })
        }
        case medicalChecksActions.IS_SAVING_MEDICAL_CHECK:{
            return state.merge({
                isSavingChecks: action.isSavingMedical
            })
        }
        case medicalChecksActions.IS_LOADING_MEDICAL_CHECK:{
            return state.merge({
                isLoadingMedical: action.isLoadingMedical
            })
        }
        case medicalChecksActions.SET_MEDICAL_CHECK:{
            return state.merge({
                isLoadingMedical: false,
                medicalcheck: fromJS(action.medicalCheck)
            })
        }
        default:
            return state;
    }
}