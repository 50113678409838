/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

/**Import MaterialUi section */
import { Button, Grid, Typography } from '@material-ui/core'
/**Import components section */
import DownloadIcon from '@material-ui/icons/CloudDownload'
/**Import store section */
import Evidence from '../../../common/Evidence'
import Formato_Cargar_Calificaciones_Servicios from '../../../../resources/files/Formato_Cargar_Calificaciones_Servicios.xlsx'
import Formato_Cargar_Calificaciones_Transportistas from '../../../../resources/files/Formato_Cargar_Calificaciones_Transportistas.xlsx'
import Formato_Cargar_Calificaciones_Transportistas_v2 from '../../../../resources/files/Formato_Cargar_Calificaciones_Transportistas_v2.xlsx'
import Formato_Cargar_Calificaciones_Transportistas_v3 from '../../../../resources/files/Formato_Cargar_Calificaciones_Transportistas_v3.xlsx'

import { IndirectQualificationType } from '../../../../store/helpers/AppConstants'
const AddComponent = (props) => {
	const { onSaveButtonClick, classes, indirectQualification } = props
	const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'))
	const [attachments, setAttachments] = useState([])

	/**Verify if can be sabed data*/
	const canBeSaved = () => {
		if (attachments.length === 0) return false
		return true
	}

	const onSave = () => {
		if (props.onSaveButtonClick) {
			onSaveButtonClick(attachments)
		}
	}

	/**
	 * Handle event remove files of evidence componet to remove files of list
	 *
	 * @param {*} fileName
	 * @returns
	 * @memberof ModalAddMasivaMaterials
	 */
	const removeFiles = (fileName) => {
		let newList = attachments.filter((files) => {
			return files.name !== fileName
		})
		setAttachments(newList)
		return Promise.resolve('Ok')
	}

	const uploadFiles = (files) => {
		let filesList = attachments.concat(files[0])
		if (files.length > 0) {
			for (var index = 1; index < files.length; index++) {
				filesList.push(files[index])
			}
		}
		setAttachments(filesList)
		return Promise.resolve('Ok')
	}

	const onGetTemplate = () => {
		switch (Number(indirectQualification.EvaluationType)) {
			case IndirectQualificationType.SERVICE:
				return Formato_Cargar_Calificaciones_Servicios
			case IndirectQualificationType.CARRIER:
				if (indirectQualification && indirectQualification.VersionRating === 1)
					return Formato_Cargar_Calificaciones_Transportistas
				else if (indirectQualification && indirectQualification.VersionRating === 2)
					return Formato_Cargar_Calificaciones_Transportistas_v2
				else return Formato_Cargar_Calificaciones_Transportistas_v3
			default:
				return false
		}
	}

	return (
		<div>
			<div className={classes.containerInformation}>
				<Grid container>
					{
						<Grid item xs={12}>
							<small className='text-muted'>
								<DownloadIcon
									fontSize='small'
									color='secondary'
									style={{ margin: '5px', marginBottom: '8px' }}
								/>
								{<a href={onGetTemplate()}>{'Descargar plantilla'}</a>}
							</small>
						</Grid>
					}

					<Grid item xs={12}>
						<Typography color='primary' className={classes.marginLeft}>
							Subir plantilla con información de proveedores
						</Typography>
						<div className={classes.marginTop}>
							<Evidence
								text={<Translate id='common.selectfiles' />}
								uploadFiles={uploadFiles}
								files={attachments}
								removeFiles={removeFiles}
								acceptedFiles={['XLSX']}
								maxFiles={1}
							/>
						</div>
					</Grid>
				</Grid>
			</div>

			<Grid container>
				<Grid item xs={12} className={classes.containerBtn}>
					<Button
						data-cy='btn-save'
						type='button'
						color='primary'
						variant='outlined'
						onClick={onSave}
						className={classes.widthBtnSaved}
						disabled={!canBeSaved() || isSaving}
					>
						{<Translate id='common.save' />}
					</Button>
				</Grid>
			</Grid>
		</div>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${165}px)`,
		minHeight: `calc(100vh - ${165}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputProps: {
		height: 6,
	},
	marginTop: {
		marginTop: '10px',
	},
	marginLeft: {
		marginLeft: '6px',
	},
	widthBtnSaved: {
		minWidth: '400px',
	},
})

AddComponent.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
}

AddComponent.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
}

export default withRouter(withStyles(styles, { withTheme: true })(AddComponent))
