/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
/**Import material UI Section*/

import {
    withStyles,
} from "@material-ui/core";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import DocumentsHeaderTable from './SuppliersHeadersTable'
import DocumentsContentTable from './SupplierDocumentsContentTable'

/**Import components section*/
import {
    onGetHeaderSuppliersDocumentsTable,
} from '../../../../store/helpers/ExpedientSupplier';

const SupplierDocumentsContainerTable = (props) => {
    /**String props section */
    /**Bools props section */
    /**Arrays props section */
    /**Functions props section */
    /**Objects props section */
    const { classes, expedientSupplier} = props
    let mappedHeadersDocuments = onGetHeaderSuppliersDocumentsTable(expedientSupplier ? expedientSupplier.expedient_update_for_suppliers: [])
    let canShow = props.canBeModule
    return (
        <div className={classes.root}>
            <Table
                className={classes.table}
                size="small">
                <DocumentsHeaderTable
                    mappedHeadersDocuments={mappedHeadersDocuments}
                    expedientToken={expedientSupplier.token}
                    canBeModule={canShow}
                    />
                <TableBody>
                    <DocumentsContentTable
                         expedientSupplier={expedientSupplier}
                         canBeModule={canShow}
                    />
                </TableBody>
            </Table>
        </div>
    )
}

const styles = (theme) => ({
    root: {
        maxHeight: `calc(100vh - ${225}px)`,
        minHeight: `calc(100vh - ${450}px)`,
        maxWidth: `calc(100vw - ${315}px)`,
        overflowX: "auto",
        overflowY: "auto",
    },
    table: {
        maxWidth: '100%',
    }
});

SupplierDocumentsContainerTable.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
}

SupplierDocumentsContainerTable.defaultProps = {
    isLoading: false,
}

export default withRouter(
    withStyles(styles, { withTheme: true })(SupplierDocumentsContainerTable)
);
