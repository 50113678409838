/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select'
import { useSelector } from 'react-redux'

/**Import MaterialUi section */
import {
    Button, TextField, Grid, Typography
} from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
/**Import components section */
import SuppliersSelector from '../../../common/SupplierSelector'

/**Import store section */
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { GetFolioType } from '../../../../store/helpers/SelectOptions'

const CHARACTER_LIMIT_NAME = 100;
const CHARACTER_LIMIT_DIVISA = 20;
const CHARACTER_LIMIT_COMMENTS = 255;

const AddComponent = (props) => {
    const { onSaveButtonClick, classes, translate
    } = props
    const isSaving = useSelector((state) => state.billings.get('isSaving'))

    const [billingModel, setBillingModel] = useState({
        reference: "",
        comments: "",
        amount: "",
        divisa: "",
        folioType: null,
        supplier: null,
    });

    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (IsNullOrEmpty(billingModel.reference)) return false
        if (IsNullOrEmpty(billingModel.comments)) return false
        if (IsNullOrEmpty(billingModel.amount)) return false
        if (IsNullOrEmpty(billingModel.divisa)) return false
        if (billingModel.folioType === null) return false
        if (billingModel.supplier === null) return false
        return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(billingModel)
        }
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setBillingModel({
            ...billingModel,
            [name]: value
        });
    }

    /**On change properties */
    const onChangeSelect = (name, option) => {
        setBillingModel({
            ...billingModel,
            [name]: option
        });
    }

    return (
        <div>
            <div className={classes.containerInformation}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography color="primary">{<Translate id="billings.create.selectedFolio" />}</Typography>
                        <div className={classes.marginTop}>
                            <Select
                                value={billingModel ? billingModel.folioType : ""}
                                className={classes.documentTypeSelect}
                                options={GetFolioType()}
                                onChange={(option) => onChangeSelect("folioType", option)}
                                placeholder={<Translate id="billings.create.selectedFolio" />}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                                    menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    control: base => ({
                                        ...base,
                                        height: 50,
                                        minHeight: 50,
                                    })
                                }}
                                menuPortalTarget={document.parentNode}
                                menuPosition="fixed"
                                menuPlacement={'auto'}
                            />

                        </div>

                    </Grid>
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography color="primary">{<Translate id="billings.create.reference" />}</Typography>
                        <div className={classes.marginTop}>
                            <TextField
                                fullWidth
                                placeholder={translate("billings.create.reference")}
                                className={classes.textField}
                                name='reference'
                                size="small"
                                onChange={onPropertyChange}
                                variant="outlined"
                                multiline
                                value={billingModel ? billingModel.reference : ""}
                                InputProps={{
                                    inputProps: {
                                        maxLength: CHARACTER_LIMIT_NAME,
                                    }
                                }}
                                helperText={`${billingModel ? billingModel.reference.length : ""}/${CHARACTER_LIMIT_NAME}`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary">{<Translate id="billings.create.selectedSupplier" />}</Typography>
                        <div className={classes.marginTop}>
                            <SuppliersSelector
                                onSupplierSelected={(supplier) => onChangeSelect("supplier", supplier)}
                                isDisabled={isSaving}
                            />
                        </div>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" className={classes.marginLeft}>{<Translate id="billings.create.comments" />}</Typography>
                        <div className={classes.marginTop}>
                            <TextField
                                fullWidth
                                placeholder={translate("billings.create.comments")}
                                className={classes.textField}
                                name='comments'
                                size="small"
                                onChange={onPropertyChange}
                                variant="outlined"
                                multiline
                                value={billingModel ? billingModel.comments : ""}
                                InputProps={{
                                    inputProps: {
                                        maxLength: CHARACTER_LIMIT_COMMENTS,
                                    }
                                }}
                                helperText={`${billingModel ? billingModel.comments.length : ""}/${CHARACTER_LIMIT_COMMENTS}`}
                            />
                        </div>

                    </Grid>
                    <Grid item xs={12} container
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                        <Grid item xs={6} >
                            <Typography color="primary" className={classes.marginLeft}>{<Translate id="billings.create.amount" />}</Typography>
                            <div className={classes.marginTop}>
                                <CurrencyTextField
                                    name='amount'
                                    variant="outlined"
                                    fullWidth
                                    value={billingModel ? billingModel.amount : ""}
                                    currencySymbol="$"
                                    outputFormat="string"
                                    onChange={onPropertyChange}
                                    disabled={isSaving}
                                />

                            </div>
                        </Grid>
                        <Grid item xs={5} className={classes.marginTopAdd}>
                            <Typography color="primary">{<Translate id="billings.create.divisa" />}</Typography>
                            <div className={classes.marginTop}>
                                <TextField
                                    fullWidth
                                    placeholder={translate("billings.create.divisa")}
                                    className={classes.textField}
                                    name='divisa'
                                    size="small"
                                    onChange={onPropertyChange}
                                    variant="outlined"
                                    multiline
                                    value={billingModel ? billingModel.divisa : ""}
                                    InputProps={{
                                        inputProps: {
                                            maxLength: CHARACTER_LIMIT_DIVISA,
                                        }
                                    }}
                                    helperText={`${billingModel ? billingModel.divisa.length : ""}/${CHARACTER_LIMIT_DIVISA}`}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <Grid container>
                <Grid item xs={12} className={classes.containerBtn}>
                    <Button
                        data-cy="btn-save"
                        type="button"
                        color="primary"
                        variant='outlined'
                        onClick={onSave}
                        className={classes.widthBtnSaved}
                        disabled={!canBeSaved() || isSaving}>
                        {<Translate id="common.save" />}
                    </Button>
                </Grid>
            </Grid>
        </div>

    )

}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${165}px)`,
        minHeight: `calc(100vh - ${165}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    marginTop: {
        marginTop: "10px",
    },
    marginTopAdd:{
        marginTop: "20px",
    },
    marginLeft: {
        marginLeft: "6px",
    },
    widthBtnSaved: {
        minWidth: "400px"
    }
});

AddComponent.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddComponent.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddComponent))