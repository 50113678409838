
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import moment from 'moment'

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import DatePicker from '../../common/DateTimePicker/DatePicker';

import { Sample_RequestedToSupplier } from '../../../store/helpers/DevelopmentHelper';
import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper';
import HtmlEditor from '../../common/HtmlEditor';


/**
 * Component to send date sample
 */
class SendDateSample extends Component {

    /**
     * Create an instance of sample request
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        let isDisabled = !(this.props.sampleRequest.actual_operation === Sample_RequestedToSupplier && IsCurrentNotActiveDirectoryUser(this.props.currentUser))
        if (this.props.sampleRequest) {
            return (
                <List divider dense>
                    <ListItem divider>
                        <ListItemText
                            primary={
                                <Typography variant="body2" gutterBottom>
                                    <strong><Translate id="samplesrequest.dateCompromisse" /></strong>
                                    {` ${this.props.sampleRequest.supplier_name}: `}
                                </Typography>
                            }
                            secondary={
                                <React.Fragment>
                                    {isDisabled &&
                                        <Typography variant="body2" gutterBottom>
                                            {this.props.sampleRequest.delivery_date_from_supplier}
                                        </Typography>
                                    }
                                    {!isDisabled &&
                                        <DatePicker
                                            onChangeRange={this.props.onChangeRange}
                                            startDate={this.props.dataDelivery}
                                            minDate={moment()}
                                        />
                                    }
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <ListItem divider>
                        <HtmlEditor
                            id="comments_from_supplier"
                            label={"samplesrequest.commentSample"}
                            labelVariant={"body2"}
                            isDisabled={isDisabled}
                            initialValue={this.props.sampleRequest.comments_from_supplier}
                            onChange={(data) => this.props.updatePropertyCommentSupplier(data.value)}
                        />
                    </ListItem>
                </List>
            )
        }
        else {
            return ("");
        }
    }
}


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    actionsToolbar: {
        flexGrow: 1,
    },
    logoCompany: {
        marginLeft: 80,
        maxHeight: 80
    },
    tableHead: {
        backgroundColor: "#fff",
        position: "sticky",
        zIndex: '30',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    },
    inline: {},
    paper: {},
    root: {},
    logoSupplier: {}
});


export default withRouter(withStyles(styles, { withTheme: true })(SendDateSample));