import { fromJS } from 'immutable';

import * as categoriesActions from './CategoriesActions'

/**
 * contraActions initial state
 */
export const biddingsInitialState = fromJS({
    isLoading: true,
    identifier: "",
    isSaving: false,
    categories: [],
})

export default function companiesReducer(state = biddingsInitialState, action) {
    switch (action.type) {
        case categoriesActions.START_LOADING_CATEGORIES: {
            return state.merge({
                isLoading: action.isLoading,
                identifier: action.identifier,
            })
        }
        case categoriesActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving,
                identifier: action.identifier,
            })
        }
        case categoriesActions.SET_BIDDING_CATEGORIES_LIST: {
            return state.merge({
                categories: fromJS(action.categories),
                isLoading: false,
                identifier: ""
            })
        }
        case categoriesActions.ADD_CATEGORY:{
            let data = fromJS(action.category);
            return state.updateIn(['categories'], allCategories => pushCategory(allCategories, fromJS(data))).set("isSaving", false)
        }
        case categoriesActions.DELETE_CATEGORY:{
            return state.updateIn(['categories'], allCategories => allCategories.filter(item => item.get('Id') !== action.id)).set('isSaving', false);
        }
        default:
            return state;
    }
}

const pushCategory = (allCategories, newCategory) => {
    let indexOfCategory = allCategories.findIndex(d => d.get('Id') === newCategory.get('Id'));
    if (indexOfCategory >= 0) {
        return allCategories.setIn([indexOfCategory], newCategory);
    }
    return allCategories.push(newCategory);
}