/** Import ReactJS */
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import { 
	Card, 
	Grid, 
	Divider, 
	Typography, 
	Tooltip,CardHeader, 
	Avatar, 
	CardContent, 
	List, 
	ListItem, 
	ListItemIcon, 
	ListItemText, 
	ListItemSecondaryAction
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'

//Import components section
import AcceptRejectDialog from '../../../common/AcceptRejectDialog'

/**Import resources section */
import {
	BACKGROUND_COLOR_GRAY,
	COLOR_STATUS_ALERT_INFO,
	COLOR_STATUS_DANGER,
	COLOR_SECONDARY_BLUE_LIGHT,
} from '../../../../store/helpers/StatusColorConstants'
import { FactoringStatus } from '../../../../store/helpers/AppConstants'

const VoBoSupplier = (props) => {
	const { factoring, classes, activeStep, isSaving } = props
	const { onUpdateFactoring } = props
	//bool props section
	const { isInternalUser } = props
	//Array props section

	const [configurationModal, setConfigurationModal] = useState({
		isOpen: false,
		isRejected: false,
	})

	const onAceptedVoBoBySupplier = () => {
		setConfigurationModal({
			...configurationModal,
			isOpen: !configurationModal.isOpen,
			isRejected: false,
		})
	}

	const onRejectdVoBoBySupplier = () => {
		setConfigurationModal({
			...configurationModal,
			isOpen: !configurationModal.isOpen,
			isRejected: true,
		})
	}

	const onAcceptOrRejetedFactoring = (comments, isAcepted) => {
		onUpdateFactoring(
			activeStep,
			factoring.Currencies,
			factoring ? factoring.paymentConditions : '-',
			factoring ? factoring.AnnualPurchase : '_',
			isAcepted,
			comments
		).then((_) => {
			setConfigurationModal({
				...configurationModal,
				isOpen: false,
				isRejected: false,
			})
		})
	}

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Card className={classes.card}>
						<CardHeader
							className={classes.cardHeader}
							avatar={
								<Avatar aria-label='Recipe' className={classes.avatar}>
									<AssignmentIcon />
								</Avatar>
							}
							title={<Translate id='factoringAffiliation.steps.data' />}
						/>
						<Divider />
						<CardContent>
							<Grid container direction='row' justify='center' alignItems='flex-start' spacing={8}>
								<Grid item xs={12}>
									<Typography>
										Por favor comprueba la información capturada por los administradores y
										selecciona la opción según sea el caso
									</Typography>
									<Divider />

									<List component='nav' className={classes.root}>
										<ListItem
											className={
												factoring.AceptedBySupplier ? classes.acceptedSupplier : null
											}
										>
											<ListItemIcon>
												<ThumbUpIcon color='primary' />
											</ListItemIcon>
											<ListItemText
												inset
												primary={
													<Typography className={classes.textTitle}>
														Al dar clic en esta opción esta aceptando la afiliación
														a factoraje.
														{/* <Translate id='factoringAffiliation.table.supplierCode' /> */}
													</Typography>
												}
												secondary={
													factoring &&
													factoring.Comments &&
													factoring.AceptedBySupplier
														? factoring.Comments
														: 'Sin comentarios'
												}
											/>
											{!isInternalUser &&
												factoring &&
												factoring.FactoringStatus ===
													FactoringStatus.VALIDATION_SUPPLIER && (
													<ListItemSecondaryAction>
														<Tooltip title={'Aceptar factoraje'}>
															<IconButton
																aria-label='Aceptar factoraje'
																onClick={onAceptedVoBoBySupplier}
																disabled={isSaving}
															>
																<ThumbUpIcon color='primary' />
															</IconButton>
														</Tooltip>
													</ListItemSecondaryAction>
												)}
										</ListItem>

										<Divider />
										{factoring &&
											factoring.FactoringStatus >= FactoringStatus.VALIDATION_SUPPLIER && (
												<ListItem
													className={
														factoring.Comments && !factoring.AceptedBySupplier
															? classes.rejectedSupplier
															: null
													}
												>
													<ListItemIcon>
														<ThumbDownIcon color='error' />
													</ListItemIcon>
													<ListItemText
														inset
														primary={
															<Typography className={classes.textTitle}>
																Al dar clic en esta opción esta rechazando la afiliación
																a factoraje.
																{/* <Translate id='factoringAffiliation.table.supplierCode' /> */}
															</Typography>
														}
														secondary={
															factoring &&
															factoring.Comments &&
															!factoring.AceptedBySupplier
																? factoring.Comments
																: 'Sin comentarios'
														}
													/>
													{!isInternalUser &&
														factoring &&
														factoring.FactoringStatus ===
															FactoringStatus.VALIDATION_SUPPLIER && (
															<ListItemSecondaryAction>
																<Tooltip title={'Rechazar factoraje'}>
																	<IconButton
																		aria-label='Rechara factoraje'
																		onClick={onRejectdVoBoBySupplier}
																		disabled={isSaving}
																	>
																		<ThumbDownIcon color='error' />
																	</IconButton>
																</Tooltip>
															</ListItemSecondaryAction>
														)}
												</ListItem>
											)}
										<Divider />
									</List>
									<Divider />
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>

			{configurationModal.isOpen && (
				<AcceptRejectDialog
					open={configurationModal.isOpen}
					additionalTitle={': la afiliación de factoraje'}
					onClickAccept={(comment) => onAcceptOrRejetedFactoring(comment, true)}
					isReject={configurationModal.isRejected}
					onClickReject={(comment) => onAcceptOrRejetedFactoring(comment, false)}
					onClickCloseCancel={() =>
						setConfigurationModal({
							...configurationModal,
							isOpen: false,
							isRejected: false,
						})
					}
					isSaving={isSaving}
				/>
			)}
		</>
	)
}

const styles = (theme) => ({
	card: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	cardHeader: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
	},
	rejectedSupplier: {
		backgroundColor: COLOR_STATUS_DANGER,
	},
	acceptedSupplier: {
		backgroundColor: COLOR_SECONDARY_BLUE_LIGHT,
	},
	textTitle: {
		paddingBottom: '10px',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(VoBoSupplier))
