/**Import react section */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles, Fab, Grid } from "@material-ui/core";
import {
  Card, CardContent
} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from '@material-ui/core/AppBar';
import DownloadIcon from '@material-ui/icons/CloudDownload';

/** Language imports **/
import { Translate } from "react-localize-redux";

/**Import components section*/
import TopicComponent from './TopicComponent'
import PercentageComponent from '../components/PercentageComponent'
import { SustainabilityStatus } from '../../../store/helpers/AppConstants'

/**Import resources section*/

/**Import actions section */
const Questionnaire = (props) => {
  const { classes, questionnaire, activeStep, sustainability,
    userRolesByCompany,  userProfile} = props;
  const {updatePropertyQuestion, downloadQuestionnarie} = props;
  const [tabSelectedIndex, setTabSelectedIndex] = useState(0)

  /**
   * onChangeTab
   * @param {*} indexTab 
   */
  const onChangeTab = (indexTab) => {
    setTabSelectedIndex(indexTab)
  }

  /**
  * Render or topic
  */
  const renderTopicsList = () => {
    let topicSelected = questionnaire.Topics[tabSelectedIndex];
    if (topicSelected !== null && topicSelected !== undefined) {
      return (<TopicComponent
        topic={topicSelected}
        activeStep={activeStep}
        sustainability={sustainability}
        updatePropertyQuestion={updatePropertyQuestion}
        userRolesByCompany={userRolesByCompany}
        userProfile={userProfile}
      />)
    }
  }

  return (
    <Card>
      <CardContent>
          {props.isAudit && questionnaire.RatingAuditor !== 0 && 
              <PercentageComponent
                  totalPercentage={questionnaire.RatingAuditor}
                  isApproved={sustainability.IsValidAprovedAudit}
                  title={<Translate id="sustainability.ratingAudit" />}
                />
          }
          {activeStep === 1 && sustainability.Status !== SustainabilityStatus.SEND_SUPPLIER && sustainability.Status !==SustainabilityStatus.DRAFT && 
            <Grid container justify="flex-end">
              <Fab variant="extended" color="secondary"  onClick={() => downloadQuestionnarie(sustainability.Token)} >
                <DownloadIcon/>{'\u00A0'}{<Translate id='sustainability.downloadQuestionnaire'/>}
              </Fab>
            </Grid>
          }
        <AppBar position="static" color="default" style={{ marginTop: "1rem" }}>
          <Tabs
            indicatorColor={"primary"}
            textColor="primary"
            value={tabSelectedIndex}
            scrollButtons="auto"
            className={classes.styleTab}
            variant="fullWidth"
          >
          {questionnaire.Topics.map((topic, index) => {
              return (
                <Tab
                  className={classes.tab}
                  style={{ color: topic.ColorTopic, minWidth: "50px" }}
                  key={topic.Id}
                  id={index}
                  label={<p><strong>{topic.ShortName}</strong></p>}
                  onClick={(event) => onChangeTab(index)}
                />
              )
            })}
          </Tabs>
        </AppBar>
        {renderTopicsList()}
      </CardContent>

    </Card>
  )
};

const styles = (theme) => ({
  tab: {
    minHeight: "auto",
  },
  tabRatinng:{
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
  }
});

Questionnaire.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  isLoading: PropTypes.bool.isRequired,
};

Questionnaire.defaultProps = {
  isLoading: false,
  questionnaire: {
    Topics: []
  }
};

export default withRouter(
  withStyles(styles, { withTheme: true })(Questionnaire)
);
