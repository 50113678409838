
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

/** Import component section */
import Dialog from '../../common/Dialog';
import { Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import ClockIcon from '@material-ui/icons/AccessTime';

/**
 * Dialog to view autorizations from reworks
 */
class DialogoQuality extends Component {

    /**
   *Creates an instance of DialogAutorizactions.
   * @param {*} props
   * @memberof DialogAutorizactions
   */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
    * Render list autorizers to show informations
    *
    * @returns
    * @memberof Show
    */
    renderAutorizers() {
        let authorizers = this.props.document ? this.props.document.authorizers ? this.props.document.authorizers : [] : [];
        return (
            authorizers.map(authorizer => {
                if (authorizer.status === 1) {
                    let possibleAuthorizes = authorizer.possible_authorizes.map(possible => {
                        return (<span key={possible.user_name}>
                            <Tooltip title={<Translate id='common.possibleAuthorizer' />}>
                                <PersonIcon color='primary' />
                            </Tooltip>{' ' + possible.full_name}<br />
                            {/* <i className="fa fa-user fa-2x"></i> {possible.full_name} |  */}
                        </span>)
                    })
                    return (<div key={authorizer.token} className="alert alert-secondary" role="alert">
                        {possibleAuthorizes}
                    </div>)
                }
                if (authorizer.status === 2) {
                    return (<div key={authorizer.token} className="alert alert-success" role="alert">
                        <Tooltip title={<Translate id='common.authorizationDate' />}>
                            <ClockIcon color='secondary' />
                        </Tooltip>{' ' + authorizer.authorization_date}
                        {/* <i className="far fa-clock fa-2x"></i> {authorizer.authorization_date} */}
                        <br />
                        <Tooltip title={<Translate id='common.authorizer' />}>
                            <PersonIcon color='secondary' />
                        </Tooltip>{' ' + authorizer.autorizer_by}
                        <br />
                        {/* <i className="fa fa-user fa-2x"></i> {authorizer.autorizer_by}   */}
                        <p>{authorizer.comment}</p>
                    </div>
                    )
                }
                if (authorizer.status === 3) {
                    return (<div key={authorizer.token} className="alert alert-danger" role="alert">
                        <Tooltip title={<Translate id='common.authorizationDate' />}>
                            <ClockIcon color='secondary' />
                        </Tooltip>{' ' + authorizer.authorization_date}
                        {/* <i className="far fa-clock fa-2x"></i> {authorizer.authorization_date} */}
                        <br />
                        <Tooltip title={<Translate id='common.authorizer' />}>
                            <PersonIcon color='secondary' />
                        </Tooltip>{' ' + authorizer.autorizer_by}
                        <br />
                        {/* <i className="fa fa-user fa-2x"></i> {authorizer.autorizer_by}   */}
                        <p>{authorizer.comment}</p>
                    </div>
                    )
                }
                else return ("")
            })

        )
    }
    /**
     * Dialog information
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id="reworks.viewAutorizersTitle"/>}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <React.Fragment>
                        <Toolbar>
                            <div className={classes.grow}></div>
                            {
                                this.props.isSaving === true ?
                                    <Button size="small" variant="outlined" color="secondary" className={classes.button} disabled>
                                        <Translate id="common.saving" />
                                    </Button> :
                                    <div>
                                        <Button size="small" variant="outlined" color="primary" className={classes.button} onClick={this.props.onClose}>
                                            <Translate id="common.close" />
                                        </Button>
                                    </div>
                            }

                        </Toolbar>
                        {
                            this.props.isSaving === true ?
                                <LinearProgress color="secondary" variant="query" />
                                : null
                        }
                    </React.Fragment>
                }>

                <div className="modal-body">
                    {this.renderAutorizers()}
                </div>

            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
    },
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(DialogoQuality));