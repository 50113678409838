import { fromJS } from 'immutable';

import * as indirectqualificationsActions from './IndirectqualificationsActions'

/**
 * contraActions initial state
 */
export const indirectQualificationsInitialState = fromJS({
    isLoading: false,
    identifier: "",
    isSaving: false,
    indirectsQualifications: [],
    indirectsQualificationsByPeriod: [],
    indirectQualification: null,
    supplier: null,
    indirectsQualificationsCount: 0,
    ratingsCount: 0,
    toasterConfiguration: {
        showToaster: false,
        toasterMessage: null,
        toasterVariant: "",
        name: "",
    },
    ratingsGraph:[],
    isLoadingGraph: false,
})

export default function companiesReducer(state = indirectQualificationsInitialState, action) {
    switch (action.type) {
        //#region REDUCER FOR INDIRECT QUALIFICATIONS
        case indirectqualificationsActions.START_LOADING_INDIRECT_QUALIFICATIONS: {
            return state.merge({
                isLoading: action.isLoading,
                identifier: action.identifier,
            })
        }
        case indirectqualificationsActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving,
                identifier: action.identifier,
            })
        }
        case indirectqualificationsActions.SET_INDIRECT_QUALIFICATIONS_LIST: {
            return state.merge({
                indirectsQualifications: fromJS(action.indirectsQualifications),
                isLoading: false,
                identifier: ""
            })
        }
        case indirectqualificationsActions.SET_INDIRECT_QUALIFICATIONS_BY_PERIOD_LIST: {
            return state.merge({
                indirectsQualificationsByPeriod: fromJS(action.data),
                isSaving: false,
                identifier: ""
            })
        }
        case indirectqualificationsActions.SET_INDIRECT_QUALIFICATIONS_COUNT: {
            return state.merge({
                indirectsQualificationsCount: action.count
            })
        }
        case indirectqualificationsActions.SET_RATING_BY_PERIOD_COUNT:{
            return state.merge({
                ratingsCount: action.count
            })
        }
        case indirectqualificationsActions.SET_ADD_INDIRECT_QUALIFICATION: {
            let data = fromJS(action.indirectQualification);
            return state.updateIn(['indirectsQualifications'], allIndirectQualification => pushIndirectQualification(allIndirectQualification, data)).set("isSaving", false)
        }
        case indirectqualificationsActions.SET_INDIRECT_QUALIFICATION: {
            return state.merge({
                isLoading: false,
                identifier: "",
                indirectQualification: action.indirectQualification
            })
        }
        case indirectqualificationsActions.UPDATE_PROPERTY_QUALIFICATION: {
            return state.setIn(['indirectQualification', action.propertyName], fromJS(action.propertyValue)).set('isSaving', false);
        }
        //#endregion
        //#region REDUCER FOR ITEMS
        case indirectqualificationsActions.UPDATE_PROPERTY_ITEMS: {
            return state.updateIn(['indirectQualification', 'Suppliers'], allSuppliers => updateItem(allSuppliers, fromJS(action.item), action.propertyName, action.propertyValue)).set('isSaving', false);

        }
        case indirectqualificationsActions.UPDATE_EVALUATOR_ITEM_PROPERTY: {
            return state.updateIn(['indirectQualification', 'Suppliers'], allSuppliers => updateItemEvaluator(allSuppliers, action.supplierId, action.evaluatorId, action.itemId, action.propertyName, action.propertyValue)).set('isSaving', false);

        }

        case indirectqualificationsActions.UPDATE_PROPERTY_EVALUATOR:{
            return state.updateIn(['indirectQualification', 'Suppliers'], allSuppliers => updatePropertyEvaluator(allSuppliers, action.supplierId, action.evaluatorId , action.propertyName, action.propertyValue)).set('isSaving', false);
        }
        //#endregion

        //#region REDUCER FOR SUPPLIERS
        case indirectqualificationsActions.UPDATE_PROPERTY_SUPPLIER: {
            return state.updateIn(['indirectQualification', "Suppliers"], allSuppliers => updatePropertySupplier(allSuppliers, fromJS(action.supplier), fromJS(action.propertyName), fromJS(action.propertyValue))).set('isSaving', false);
        }
        case indirectqualificationsActions.SET_SUPPLIER_ACTION_PLANT: {
            return state.merge({
                isLoading: false,
                identifier: "",
                supplier: fromJS(action.supplier)
            })
        }
        //#endregion

        case indirectqualificationsActions.UPDATE_PROPERTY_ACTION_PLANT: {
            return state.updateIn(['supplier', 'Actions'], allActions => updatePropertyActionPlant(allActions, action.actionPlantId, action.propertyName, action.propertyValue)).set('isSaving', false);

        }
        case indirectqualificationsActions.SET_TOASTER_OPTIONS: {
            return state.merge({
                toasterConfiguration: fromJS(action.configuration),
            })
        }
        case indirectqualificationsActions.LOAGIND_RATTINGS_GRAPH_SUPPLIER:{
            return state.merge({
                isLoadingGraph: action.isLoadingRating,
                ratingsGraph: fromJS([]),
            })
        }
        case indirectqualificationsActions.SET_RATTINGS_GRAPH_SUPPLIER: {
            return state.merge({
                isLoadingGraph: false,
                ratingsGraph: fromJS(action.data)
            })
        }
        default:
            return state;
    }
}

//#region Methods FOR INDIRECT QUALIFICATIONS
const pushIndirectQualification = (allIndirectQualifications, newIndirectQualification) => {
    let indexOfIndirectQualification = allIndirectQualifications.findIndex(d => d.get('Id') === newIndirectQualification.get('Id'));
    if (indexOfIndirectQualification >= 0) {
        return allIndirectQualifications.setIn([indexOfIndirectQualification], newIndirectQualification);
    }
    return allIndirectQualifications.unshift(newIndirectQualification);
}
//#endregion

//#region Methods FOR ITEMS
const updateItem = (allSuppliers, item, propertyName, propertyValue) => {
    let indexOfSupplier = allSuppliers.findIndex(d => d.get('Id') === item.get('SupplierId'));
    if (indexOfSupplier >= 0) {
        let newItemSupplierToUpdate = allSuppliers.get(indexOfSupplier)
        if (newItemSupplierToUpdate !== undefined) {
            let items = newItemSupplierToUpdate.get('Items')
            let indexOfItem = items.findIndex(d => d.get('Id') === item.get('Id'));
            if (indexOfItem >= 0) {
                return allSuppliers.setIn([indexOfSupplier, 'Items', indexOfItem, propertyName], propertyValue);
            }
        }
    }
    return allSuppliers
}

const updateItemEvaluator = (allSuppliers, supplierId, evaluatorId, itemId, propertyName, propertyValue) => {
    let indexOfSupplier = allSuppliers.findIndex(d => d.get('Id') === supplierId);
    if (indexOfSupplier >= 0) {
        let newItemSupplierToUpdate = allSuppliers.get(indexOfSupplier)
        if (newItemSupplierToUpdate !== undefined) {
            let evaluators = newItemSupplierToUpdate.get('Evaluators')
            let indexOfEvaluator = evaluators.findIndex(d => d.get('Id') === evaluatorId);
            if (indexOfEvaluator >= 0) {
                var evaluator = evaluators.get(indexOfEvaluator)
                if(evaluator !==undefined){
                    let items = evaluator.get('Items')
                    let indexOfItem = items.findIndex(d => d.get('Id') === itemId);
                    if (indexOfItem >= 0) {
                        return allSuppliers.setIn([indexOfSupplier, 'Evaluators',indexOfEvaluator, 'Items', indexOfItem, propertyName], propertyValue);
                    }
                }
            }
        }
    }
    return allSuppliers
}
//#endregion

//#region Methods FOR SUPPLIERS
const updatePropertySupplier = (allSuppliers, supplier, propertyName, propertyValue) => {
    let indexOfSupplier = allSuppliers.findIndex(d => d.get('Id') === supplier.get('Id'));
    if (indexOfSupplier >= 0) {
        return allSuppliers.setIn([indexOfSupplier, propertyName], propertyValue);
    }

}

const updatePropertyEvaluator = (allSuppliers, supplierId, evaluatorId, propertyName, propertyValue) => {
    let indexOfSupplier = allSuppliers.findIndex(d => d.get('Id') === supplierId);
    if (indexOfSupplier >= 0) {
        let supplier = allSuppliers.get(indexOfSupplier)
        if(supplier !==undefined){
            let evaluators = supplier.get('Evaluators')
            let indexOfEvaluators = evaluators.findIndex(d => d.get('Id') === evaluatorId);
            if (indexOfEvaluators >= 0) {
                return allSuppliers.setIn([indexOfSupplier, 'Evaluators', indexOfEvaluators, propertyName], propertyValue);
            }
        }
        return allSuppliers.setIn([indexOfSupplier, propertyName], propertyValue);
    }

}
  //#endregion

  //#region Methods FOR ActionPlant
const updatePropertyActionPlant = (allActionsPlants, actionPlantId, propertyName, propertyValue) => {
    let indexOfActionPlant = allActionsPlants.findIndex(d => d.get('Id') === actionPlantId);
    if (indexOfActionPlant >= 0) {
        return allActionsPlants.setIn([indexOfActionPlant, propertyName], propertyValue);
    }
    return allActionsPlants
}
//#endregion