import { Map, fromJS } from 'immutable';

import * as warehouseWorkersActions from './WarehouseWorkersActions';

/**
 * Companies initial state
 */
export const workersInitialState = fromJS({
    isLoadingWarehouseWorker: true,
    warehouseWorkers: null,
    isSavingWorker: false,
    usersQualityCount: null,
    sortCriteria: {
        by: "name",
        ascending: true
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
})

export default function companiesReducer(state = workersInitialState, action) {
    switch (action.type) {
        case warehouseWorkersActions.START_LOADING_WORKERS: {
            return state.merge({
                isLoadingWarehouseWorker: action.isLoading
            })
        }
        case warehouseWorkersActions.SET_WORKERS_LIST: {
            let workersLists = fromJS(action.workers);
            return state.merge({
                warehouseWorkers: workersLists,
                isLoadingWarehouseWorker: false
            })
        }
        case warehouseWorkersActions.IS_SAVING_WORKER: {
            return state.merge({
                isSavingWorker: action.isSaving
            })
        }
        case warehouseWorkersActions.ADD_WORKER_TO_LIST: {
            let worker = Map(action.worker);
            let workersList = state.get('warehouseWorkers').push(worker);
            return state.merge({
                warehouseWorkers: workersList,
                isSavingWorker: false
            });
        }
        case warehouseWorkersActions.UPDATE_WORKER: {
            let workersList = state.get('warehouseWorkers').map((workMap) => {
                if (workMap.get("token") === action.worker.token) {
                    return Map(action.worker);
                }
                return workMap;
            })
            return state.merge({
                warehouseWorkers: workersList,
                isSavingWorker: false,
            })
        }
        case warehouseWorkersActions.CHANGE_WORKERS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case warehouseWorkersActions.CHANGE_WORKERS_SORT_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case warehouseWorkersActions.CHANGE_WORKERS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case warehouseWorkersActions.SET_WORKERS_COUNT: {
            return state.merge({
                usersQualityCount: action.count
            })
        }
        default:
            return state;
    }
}