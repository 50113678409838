import { createSelector } from 'reselect';
import { toSafeJS } from '../helpers/ImmutableHelper';

/**********************************************************************
 ********* Selectors for service orders *******************************
 **********************************************************************/

export const getPaymentRequestStatus = (state) => state.accreditedWorkshops.get('paymentRequestCreationEnabled');

/**********************************************************************
 ********* Selectors for service orders *******************************
 **********************************************************************/

const getServiceOrder = (state) => state.accreditedWorkshops.get('service');

const getServiceOrderList = (state) => state.accreditedWorkshops.get('services');

const getSortCriteria = (state) => state.accreditedWorkshops.get('sortCriteria');

export const getServiceOrdersTotal = (state) => state.accreditedWorkshops.get('servicesCount');

export const getServiceOrdersAmount = (state) => state.accreditedWorkshops.get('servicesAmount');

export const getServiceOrdersPage = (state) => state.accreditedWorkshops.get('page');

export const getServiceOrdersPerPage = (state) => state.accreditedWorkshops.get('rowsPerPage');

export const getLoadingServiceOrdersFlag = (state) => state.accreditedWorkshops.get('isLoadingServices');

const getAllFilterOptions = (state, { filterType }) => state.accreditedWorkshops.getIn(['filterOptions', filterType]);

export const getServiceOrderSelector = createSelector([getServiceOrder], (serviceOrder) => toSafeJS(serviceOrder));

export const getSortCriteriaSelector = createSelector([getSortCriteria], (sortCriteria) => toSafeJS(sortCriteria));

export const getServiceOrderListSelector = createSelector([getServiceOrderList], (serviceOrderList) =>
	toSafeJS(serviceOrderList)
);

export const getAllFilterOptionsSelector = createSelector([getAllFilterOptions], (filterOptions) =>
	toSafeJS(filterOptions || [])
);

/**********************************************************************
 ********* Selectors for payment request ******************************
 **********************************************************************/

const getPaymentRequest = (state) => state.accreditedWorkshops.get('paymentRequest');

const getInvoice = (state) => state.accreditedWorkshops.get('invoice');

const getCreditNote = (state) => state.accreditedWorkshops.get('creditNote');

const getPaymentRequestErrors = (state) => state.accreditedWorkshops.get('paymentRequestErrors');

const getServiceOrderErrors = (state) => state.accreditedWorkshops.get('serviceOrderErrors');

const getServiceOrdersOnPaymentRequest = (state) => state.accreditedWorkshops.get('serviceOrderOnpaymentRequest');

const getPaymentRequestList = (state) => state.accreditedWorkshops.get('paymentRequests');

export const getLoadingPaymentRequestsFlag = (state) => state.accreditedWorkshops.get('isLoadingPaymentRequests');

export const getPaymentRequestsTotal = (state) => state.accreditedWorkshops.get('paymentRequestsCount');

export const getPaymentRequestErrorsSelector = createSelector([getPaymentRequestErrors], (paymentRequestErrors) =>
	toSafeJS(paymentRequestErrors)
);

export const getServiceOrderErrorsSelector = createSelector([getServiceOrderErrors], (serviceOrderErrors) =>
	toSafeJS(serviceOrderErrors)
);

export const getPaymentRequestSelector = createSelector([getPaymentRequest], (paymentRequest) =>
	toSafeJS(paymentRequest)
);

export const getInvoiceSelector = createSelector([getInvoice], (invoice) => toSafeJS(invoice));

export const getCreditNoteSelector = createSelector([getCreditNote], (creditNote) => toSafeJS(creditNote));

export const getServiceOrdersOnPaymentRequestSelector = createSelector(
	[getServiceOrdersOnPaymentRequest],
	(servicesOnPaymentRequest) => toSafeJS(servicesOnPaymentRequest)
);

export const getPaymentRequestListSelector = createSelector([getPaymentRequestList], (paymentRequestList) =>
	toSafeJS(paymentRequestList)
);

/**********************************************************************
 ********* Selectors for technicians **********************************
 **********************************************************************/

const getTechnicians = (state) => state.accreditedWorkshops.get('technicians');
export const getTechniciansSelector = createSelector([getTechnicians], (technicians) => toSafeJS(technicians));

/**************************************************************************
 ********* Selectors for setting ******************************************
 **************************************************************************/

const getSettings = (state) => state.accreditedWorkshops.get('settings');
export const getSettingsSelector = createSelector([getSettings], (settings) => toSafeJS(settings));

const getConfiguration = (state) => state.accreditedWorkshops.get('configuration');
export const getConfigurationSelector = createSelector([getConfiguration], (configuration) => toSafeJS(configuration));

const getServiceTypesOnConfiguration = (state) =>
	state.accreditedWorkshops.getIn(['configuration', 'configuration', 'ServiceTypes']);
export const getServiceTypesOnConfigurationSelector = createSelector([getServiceTypesOnConfiguration], (serviceTypes) =>
	!serviceTypes ? [] : toSafeJS(serviceTypes)
);

const getWorkshopCodesOnConfiguration = (state) =>
	state.accreditedWorkshops.getIn(['configuration', 'configuration', 'WorkshopCodes']);
export const getWorkshopCodesOnConfigurationSelector = createSelector(
	[getWorkshopCodesOnConfiguration],
	(workshopCodes) => (!workshopCodes ? [] : toSafeJS(workshopCodes))
);

const getAccreditedWorkshops = (state) => state.accreditedWorkshops.get('workshops');
export const getAccreditedWorkshopsSelector = createSelector([getAccreditedWorkshops], (workshops) =>
	!workshops ? [] : toSafeJS(workshops)
);

const getAllowedStatusOnConfiguration = (state) =>
	state.accreditedWorkshops.getIn(['configuration', 'configuration', 'OrderStatusInSections']);
export const getAllowedStatusForReleasedServicesSelector = createSelector(
	[getAllowedStatusOnConfiguration],
	(allowedStatus) =>
		!allowedStatus
			? []
			: toSafeJS(
					allowedStatus
						.filter((i) => i.get('SectionName') === 'ReleasedServiceOrders')
						.map((i) => i.get('ServiceOrderStatus'))
			  )
);
export const getAllowedStatusForNotReleasedServicesSelector = createSelector(
	[getAllowedStatusOnConfiguration],
	(allowedStatus) =>
		!allowedStatus
			? []
			: toSafeJS(
					allowedStatus
						.filter((i) => i.get('SectionName') === 'NotReleasedServiceOrders')
						.map((i) => i.get('ServiceOrderStatus'))
			  )
);
