///Import react section
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Translate } from 'react-localize-redux'
import DatePicker from '../../common/DateTimePicker/DatePicker'

/** Material-UI imports section */
import { TextField, Typography, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

/** Common imports section */
import HourPickers from '../common/HourPickers'
import SelectorComponent from '../common/SelectorComponent'
import { useEffect } from 'react'

const ManualCreateTransportZones = (props) => {
	const {
		requestValues,
		setRequestValues,
		classes,
		isEdit,
		programmerOptions,
		transportZoneOptions,
		typeShipmentsOptions,
		transportTenderdetail,
	} = props

	const onChangeinformation = (event) => {
		const { name, value } = event.target
		setRequestValues({
			...requestValues,
			[name]: value,
		})
	}
	const onChangeDate = (property, data) => {
		setRequestValues({
			...requestValues,
			[property]: data,
		})
	}

	const onchangeCheckbox = (name, event) => {
		if (name === 'isFolioSit') {
			setRequestValues({ ...requestValues, [name]: event.target.checked, folioLetter: '' })
		} else {
			setRequestValues({ ...requestValues, [name]: event.target.checked })
		}
	}

	//when the modal is editable
	useEffect(() => {
		if (transportTenderdetail) {
			setRequestValues({
				serviceDescription: transportTenderdetail.ServiceDescription,
				shipmentId: transportTenderdetail.ShipmentId,
				dateServiceRequest: transportTenderdetail.DateServiceRequest,
				applicationDate: transportTenderdetail.ApplicationDate,
				status: transportTenderdetail.Status,
				folioLetter: transportTenderdetail.FolioLetter,
				programmer: {
					...transportTenderdetail.Programmer,
					value: transportTenderdetail.Programmer ? transportTenderdetail.Programmer.userName : '',
					user_name: transportTenderdetail.Programmer ? transportTenderdetail.Programmer.userName : '',
					user_email: transportTenderdetail.Programmer ? transportTenderdetail.Programmer.email : '',
					label: `${transportTenderdetail.Programmer ? transportTenderdetail.Programmer.userName : ''} - ${
						transportTenderdetail.Programmer ? transportTenderdetail.Programmer.name : ''
					}`,
				},
				wineCellar: {
					...transportTenderdetail.WineCellar,
					value: transportTenderdetail.WineCellar.id,
					Id: transportTenderdetail.WineCellar.id,
					label: `${transportTenderdetail.WineCellar.name} | ${transportTenderdetail.WineCellar.direction}`,
				},
				startTime: transportTenderdetail.StartTime
					? moment(transportTenderdetail.StartTime).format('HH:mm')
					: '',
				endTime: transportTenderdetail.EndTime ? moment(transportTenderdetail.EndTime).format('HH:mm') : '',
				typeShipments: {
					value: transportTenderdetail.TypeShipmentId,
					label: `${transportTenderdetail.TypeShipmentName}`,
				},
				showBaseRate: transportTenderdetail.ShowBaseRate,
				openRateTender: transportTenderdetail.OpenRateTender,
				tenderEndDate: transportTenderdetail.TenderEndDate,
				tenderEndTime: transportTenderdetail.TenderEndTime
					? moment(transportTenderdetail.TenderEndTime).format('HH:mm')
					: '',
			})
		}
	}, [transportTenderdetail])

	return (
		<div className={classes.container}>
			<div className={classes.dateContainer}>
				<div>
					<Typography variant='subtitle1'>{<Translate id='carrierTenders.dateServiceRequest' />}</Typography>
					<div className={classes.datePickerContainer}>
						<div className={classes.timeContainer}>
							<div>
								<div className={classes.titleDate}>
									<Typography variant='subtitle1'>
										{<Translate id='carrierTenders.date' />}
									</Typography>
								</div>
								<div style={{ marginLeft: '-4px' }}>
									<DatePicker
										opens={'left'}
										drops={'down'}
										onChangeRange={(event) => onChangeDate('dateServiceRequest', event)}
										startDate={
											isEdit
												? requestValues.dateServiceRequest
													? moment(requestValues.dateServiceRequest)
													: ''
												: requestValues.dateServiceRequest
												? moment(requestValues.dateServiceRequest, 'DD/MM/YYYY')
												: ''
										}
										minDate={moment()}
									/>
								</div>
							</div>
							<div>
								<div className={classes.titleTime}>
									<Typography variant='subtitle1'>
										{<Translate id='carrierTenders.rangeFromHourto' />}:
									</Typography>
								</div>

								<HourPickers
									name='startTime'
									value={requestValues.startTime ? requestValues.startTime : null}
									onChange={onChangeinformation}
								/>
							</div>
							<div>
								<div className={classes.titleTime}>
									<Typography variant='subtitle1'>
										{<Translate id='carrierTenders.rangeFromHourto' />}:
									</Typography>
								</div>
								<HourPickers
									name='endTime'
									value={requestValues.endTime ? requestValues.endTime : null}
									onChange={onChangeinformation}
								/>
							</div>
						</div>
					</div>

					<div className={classes.hoursContainer}>
						<div className={classes.hour}>
							<div className={classes.shipment}>
								<Typography variant='subtitle1'>
									{<Translate id='carrierTenders.shipmentid' />}
								</Typography>
							</div>
							<TextField
								id='standard-with-placeholder'
								placeholder={'Id del embarque'}
								onChange={onChangeinformation}
								name='shipmentId'
								value={requestValues.shipmentId}
								className={classes.textField}
								inputProps={{
									style: {
										height: '10px',
									},
								}}
								size='small'
								margin='dense'
								fullWidth
								variant='outlined'
							/>
						</div>
						<div className={classes.hour}>
							<SelectorComponent
								title={<Translate id='carrierTenders.boardingType' />}
								placeholder={<Translate id='carrierTenders.boardingType' />}
								options={typeShipmentsOptions}
								value={requestValues.typeShipments}
								onChange={(option) => setRequestValues({ ...requestValues, typeShipments: option })}
							/>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div className={classes.baseRate}>
					<Typography variant='subtitle1' className={classes.rateTitle}>
						¿Es licitación con folio SIT y no es embarque compartido?
					</Typography>
					<Checkbox
						onChange={(event) => onchangeCheckbox('isFolioSit', event)}
						color='primary'
						value='showBaseRate'
						checked={requestValues.isFolioSit}
					/>
				</div>
				{!requestValues.isFolioSit && (
					<>
						<div className={classes.shipment}>
							<Typography variant='subtitle1'>
								<Translate id='carrierTenders.folioLetter' />
								Si es viaje compartido agrega el folio que se envía a SAP
							</Typography>
						</div>
						<TextField
							id='standard-with-placeholder'
							placeholder={'Folio letra'}
							onChange={onChangeinformation}
							name='folioLetter'
							value={requestValues.folioLetter}
							className={classes.textField}
							inputProps={{
								style: {
									height: '10px',
								},
							}}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
						/>
					</>
				)}
			</div>
			<div className={classes.hoursContainer}>
				<div className={classes.hour}>
					<SelectorComponent
						title={<Translate id='carrierTenders.shipmentPlanner' />}
						placeholder={<Translate id='carrierTenders.selectShipmentPlanner' />}
						options={programmerOptions}
						onChange={(option) => setRequestValues({ ...requestValues, programmer: option })}
						value={requestValues.programmer}
					/>
				</div>
				<div className={classes.hour}>
					<SelectorComponent
						title={<Translate id='carrierTenders.wineCellar' />}
						placeholder={<Translate id='carrierTenders.selectWineCellar' />}
						options={transportZoneOptions}
						onChange={(option) => setRequestValues({ ...requestValues, wineCellar: option })}
						value={requestValues.wineCellar}
					/>
				</div>
			</div>
			<div className={classes.rateContainer}>
				<div className={classes.divColor}></div>
				<div className={classes.baseRate}>
					<Typography variant='subtitle1' className={classes.rateTitle}>
						<Translate id='carrierTenders.showBaseRateCarrier' />:
					</Typography>
					<Checkbox
						onChange={(event) => onchangeCheckbox('showBaseRate', event)}
						color='primary'
						value='showBaseRate'
						checked={requestValues.showBaseRate}
					/>
				</div>
				<div className={classes.tariffTender}>
					<Typography variant='subtitle1' className={classes.rateTitle}>
						<Translate id='carrierTenders.openRateTender' />:
					</Typography>
					<Checkbox
						onChange={(event) => onchangeCheckbox('openRateTender', event)}
						color='primary'
						value='openRateTender'
						checked={requestValues.openRateTender}
					/>
				</div>
			</div>
			<div>
				<TextField
					id='standard-with-placeholder'
					label='Observaciones'
					placeholder={'Observaciones'}
					onChange={onChangeinformation}
					name='serviceDescription'
					value={requestValues.serviceDescription}
					margin='dense'
					variant='outlined'
					fullWidth
					multiline
					rows={2}
					rowsMax={4}
					helperText='Escriba las observaciones del servicio'
				/>
			</div>
			<div className={classes.hoursContainer}>
				<div className={classes.hour}>
					<div className={classes.titleTenderEndDate}>
						<Typography>{<Translate id='carrierTenders.tenderEndDate' />}</Typography>
					</div>
					<DatePicker
						opens={'left'}
						drops={'up'}
						onChangeRange={(event) => onChangeDate('tenderEndDate', event)}
						startDate={
							isEdit
								? requestValues.tenderEndDate
									? moment(requestValues.tenderEndDate)
									: ''
								: requestValues.tenderEndDate
								? moment(requestValues.tenderEndDate, 'DD/MM/YYYY')
								: ''
						}
						minDate={moment()}
					/>
				</div>
				<div className={classes.hour}>
					<div>
						<Typography>{<Translate id='carrierTenders.tenderEndTime' />}</Typography>
					</div>

					<HourPickers
						name='tenderEndTime'
						value={requestValues.tenderEndTime ? requestValues.tenderEndTime : null}
						onChange={onChangeinformation}
					/>
				</div>
			</div>
		</div>
	)
}

const styles = (theme) => ({
	container: {
		padding: '10px',
	},
	dateContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	dateTitle: {
		margin: '0px 5px 7px',
	},
	datePickerContainer: {
		display: 'flex',
		width: '500px',
		justifyContent: 'space-between',
	},
	titleRightDate: {
		margin: '0px 5px 15px',
	},
	rightContainer: {},
	documentTypeSelect: {
		marginTop: '10px',
	},
	hourTextField: {
		margin: '10px 0px 30px',
		height: '20px',
	},
	hoursContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '0px',
	},
	hour: {
		width: '235px',
	},

	selectType: {
		marginTop: '20px',
		width: '235px',
	},

	titleDate: {
		margin: '0px 0px 7px 2px',
	},
	titleTime: {
		marginBottom: '-3px',
	},
	timeContainer: {
		display: 'grid',
		gridTemplateColumns: '2fr 1fr 1fr',
		gridGap: '0px  10px',
	},
	rateContainer: {
		backgroundColor: '#F9F9F9',
		display: 'grid',
		gridTemplateColumns: '15px 230px 250px',
		alignItems: 'center',
		margin: '10px 0px -5px',
		gridGap: '0px',
		width: '100%',
	},
	divColor: {
		backgroundColor: theme.palette.secondary.main,
		height: '100%',
		borderRadius: '6px',
		margin: '3px 0px 3px',
	},
	rateTitle: {
		fontSize: '13px',
	},
	baseRate: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '5px',
	},
	shipment: {
		margin: '0px 0px -2px',
	},
	tariffTender: {
		display: 'flex',
		alignItems: 'center',
		margin: '3px 0px 3px 20px',
	},
	titleTenderEndDate: {
		marginBottom: '10px',
	},
})

ManualCreateTransportZones.propTypes = {
	onChangeDate: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	requestValues: PropTypes.object.isRequired,
}

ManualCreateTransportZones.defaultProps = {
	onChangeDate: () => console.warn('[onChangeDate] not defined!'),
	onChange: () => console.warn('[onChange] not defined!'),
	requestValues: {},
}

export default withStyles(styles, { withTheme: true })(ManualCreateTransportZones)
