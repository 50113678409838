import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

/** Language imports **/
import { Translate } from 'react-localize-redux';

/** Material-UI imports section */
import { Tab, Tabs, withStyles } from '@material-ui/core';

/** Custom components import section */
import ServiceOrdersIndex from './serviceOrders/ReleasedServices';
import NotReleasedServices from './serviceOrders/NotReleasedServices';
import LockedServiceOrders from './serviceOrders/LockedServiceOrders';
import PaymentRequestsIndex from './paymentRequests/Index';

/** Actions import section */
import { loadBusinessUnitConfiguration } from '../../store/accreditedWorkshops/accreditedWorkshopsActions';
import { getLoggedUserSelector } from '../../store/profile/ProfileSelectors';

/** Resouces import section */

const WorkshopIndex = (props) => {
	const { classes } = props;
	const { activeTab } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const loggedUser = useSelector((state) => getLoggedUserSelector(state));

	useEffect(() => {
		const loadConfiguration = async (rfc) => {
			dispatch(loadBusinessUnitConfiguration(rfc));
		};
		if (loggedUser && loggedUser.selectedCompany) {
			var { rfc } = loggedUser.selectedCompany;
			loadConfiguration(rfc);
		}
	}, [dispatch]);

	/**
	 * Handle the change of tab
	 */
	const handleChangeTab = (_, selectedTab) => {
		history.push(`/accreditedWorkshops/${selectedTab}`);
	};

	/**
	 * Render content for current selected tab
	 */
	const renderTabContent = () => {
		switch (activeTab) {
			case tabs.ReleasedServiceOrders:
				return <ServiceOrdersIndex />;
			case tabs.NotReleasedServices:
				return <NotReleasedServices />;
			case tabs.LockedServices:
				return <LockedServiceOrders />;
			case tabs.PaymentRequests:
				return <PaymentRequestsIndex />;

			default:
				return <ServiceOrdersIndex />;
		}
	};

	return (
		<>
			<Tabs
				value={activeTab}
				onChange={handleChangeTab}
				className={classes.root}
				aria-label="Tabs to show approved services and payment requests"
			>
				<Tab
					className={classes.tabItem}
					label={<Translate id="accreditedWorkshops.serviceOrders" />}
					{...a11yProps(tabs.ReleasedServiceOrders)}
				/>
				<Tab
					className={classes.tabItem}
					label={<Translate id="accreditedWorkshops.notReleasedServices" />}
					{...a11yProps(tabs.NotReleasedServices)}
				/>
				<Tab
					className={classes.tabItem}
					label={<Translate id="accreditedWorkshops.lockedServices" />}
					{...a11yProps(tabs.LockedServices)}
				/>
				<Tab
					className={classes.tabItem}
					label={<Translate id="accreditedWorkshops.paymentRequests" />}
					{...a11yProps(tabs.PaymentRequests)}
				/>
			</Tabs>
			{renderTabContent()}
		</>
	);
};

const tabs = {
	ReleasedServiceOrders: 'service-orders',
	NotReleasedServices: 'not-released-services',
	LockedServices: 'locked-services',
	PaymentRequests: 'payment-requests'
};

const a11yProps = (id) => {
	return {
		id: `tab-${id}`,
		value: `${id}`,
		'aria-controls': `simple-tabpanel-${id}`
	};
};

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.common.white,
		minHeight: '30px !important'
	},
	tabItem: {
		minHeight: '30px !important'
	}
});

export default withStyles(styles, { withTheme: true })(WorkshopIndex);
