import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import { Button, Dialog, DialogActions, DialogTitle} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const DialogConfirmOrders = (props) => {
	const { open, onClose, save, saving, } = props
	const classes = useStyles()

	return (
		<Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'} aria-labelledby='form-dialog-title'>
			<DialogTitle id='alert-dialog-title'>
				<Translate id='releasePlanningFersa.confirmOrder' />
			</DialogTitle>
			
			<DialogActions>
				<Button variant='outlined' className={classes.buttonSuccess} onClick={save} disabled={saving}>
					Confirmar
				</Button>
				<Button variant='outlined' className={classes.button} onClick={onClose} disabled={saving}>
					Cancelar
				</Button>
			</DialogActions>
		</Dialog>
	)
}

DialogConfirmOrders.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
}

DialogConfirmOrders.propTypes = {
	onClose: () => console.warn('onClose not defined!'),
}

const useStyles = makeStyles((theme) => ({
	dialogTitle: {
		display: 'flex',
		alignItems: 'center',
		'& > *': {
			marginLeft: 5, //theme.spacing(0.5)
		},
	},
	title: {
		flex: '1 1 0',
	},
	button: {
		color: 'red',
	},
	buttonSuccess: {
		// color: 'theme.palette.colors.green',
		color: 'green',
	},
}))

export default DialogConfirmOrders
