import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from 'react-localize-redux';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import DataTable from '../common/DataTable';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import SacsIcon from '@material-ui/icons/FileCopyTwoTone';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import TextField from '@material-ui/core/TextField';
import LoadingPage from '../common/LoadingPage';
import SuppliersSelector from '../common/SupplierSelector';
import Toaster from '../common/Toaster';

import {getRatingBySupplier} from '../../store/ratings/ratingActions';
import {createCorrectiveAction} from '../../store/correctiveActions/SacActions';
import {loadEvaluationSuppliersRatingByCompanyFromBackEnd} from '../../store/ratings/ratingActions';

/**
 * Component to create corrective action supplier
 */
class Create extends Component{

     /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            supplierSelected: null,
            comments: null,
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
        }
    }

    /**
     * onDeclineQuoteBySupplier
     *
     * @param {*} item
     * @param {*} supplier
     * @memberof Create
     */
    createProveduria() {
        this.setState({
            showCreatetemDialog: true,
        })
    }

    /**
     * On seletecd supplier
     */
    onSupplierSelected(supplier){
        this.setState({supplierSelected: supplier});
        if(!this.props.evaluationRating){
            this.props.loadEvaluationSuppliersRatingByCompany(this.props.selectedCompany.token);
        }

        this.props.getRatingBySupplier(supplier.code);
    }

    /**
     * Function to update comment
     */
    updatePropertyComment(value) {
        this.setState({
            comments: value
        })
    }


    /**
     * Can be add item in provedurie
     */
    canBeSend(){
        if(this.state.supplierSelected == null)
            return false;
        if(this.state.comments == null)
            return false;
        else
            return true;
    }


    /**
     * Get action to send corrective action 
     */
    sendCorretiveActionToSupplier(){
        if(!this.canBeSend())
        {
            this.setState({
                showToaster:true,
                toasterMessage: "Para poder crear la solicitud debe seleccinar un proveedor y debe tener calificaciones",
                toasterVariant: "error",
            })
        }
        else
        {
            this.props.createCorrectiveAction(this.state.supplierSelected, this.state.comments)
            .then((corrective) =>{
                this.setState({
                    showToView:true,
                    showToaster: false
                })
                this.props.history.push(`/correctiveactions/${corrective.token}`)
            }).catch(() =>{
                this.setState({
                    showToaster:true,
                    toasterMessage: <Translate id="common.errorToSave"/>,
                    toasterVariant: "error",
                })
            });
        }
    }

    /**
     * Get the card content
     *
     * @memberof Index
     */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if(this.props.rating !== null){
            return (
                <div>
                    <ProveduresDetails 
                        {...this.props}
                    />
                </div>
            )
        }  
    }

    render(){
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <SacsIcon />
                    </Avatar>
                }
                action={
                    <Tooltip title={"Crear SAC's"}>
                        <IconButton color="primary"value onClick={() => {this.sendCorretiveActionToSupplier()}} >
                        <SendIcon />
                        </IconButton>
                     </Tooltip>
                  }
                title={<Typography variant="button">{"Solicitud de acciones correctivas"} </Typography>}
                subheader={"Incumplimiento en la calificación del proveedor"}
            />
            <CardContent>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <InformationCard 
                            updatePropertyComment={this.updatePropertyComment}
                            {...this.props}
                            onSupplierSelected={this.onSupplierSelected}
                        />
                    </Grid>
                </Grid>

                <br/><br/><br/>
                {this.getCardContent()}
                
            </CardContent>

            <Toaster 
                message = {this.state.toasterMessage}
                open = {this.state.showToaster}
                variant = {this.state.toasterVariant}
                onClose = {() => {this.setState({ showToaster:false})}}
            /> 

        </Card>
        )
    }
}

class InformationCard extends Component {
    render() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={6}>
                    <List>
                        <ListItem>
                            <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="alternatingProveeduria.supplier"/></Typography>}
                            secondary={
                                <React.Fragment><br/>
                                     <SuppliersSelector
                                        onSupplierSelected = {(supplier) => {this.props.onSupplierSelected(supplier)}}
                                    />
                                </React.Fragment>
                            } />
                        </ListItem>
                    </List>
                </Grid>

                <Grid item xs={6}>
                    <List>
                        <ListItem>
                            <ListItemText primary={<Typography variant="subtitle1" gutterBottom>{"Comentarios"}</Typography>} 
                            secondary={
                                <React.Fragment>
                                  <TextField fullWidth
                                        id="outlined-multiline-flexible"
                                        multiline
                                        rowsMax="5"
                                        defaultValue={this.props.comments}
                                        onChange={(event) => { this.props.updatePropertyComment(event.target.value) }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </React.Fragment>
                            }/>
                        </ListItem>
                    </List>
                </Grid>
        </Grid>
        );
    }
}

/**
 * Component to proveduria
 */
class ProveduresDetails extends Component {
    /**
     * Create an instance of Internal user
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
    }

    /**
     * Render
     *
     * @returns
     * @memberof MemoDetails
     */
    render() {
        return (
            <Paper>
                <DataTable
                    data = {this.props.rating}
                    configuration={TableratingsTableConfiguration(this.props.evaluationRating, this.props.translate)}
                    onRenderCellItem = {this.onRenderCellItem}
                />
            </Paper>
        )
    }
}

function TableratingsTableConfiguration(evaluation, translate) {
    let columns = [
        {
            header: '',
            dataSource: 'status_indicator',
        }, {
            header: translate('rating.ratingsTable.month'),
            dataSource: 'rating_date',
        },
        {
            header:  `${translate && translate('rating.ratingsTable.deliveries')} ${evaluation ? `${evaluation.delivery_points}` : '25'}%`,
            dataSource: 'delivery_points',
        }, {
            header:  `${translate && translate('rating.ratingsTable.development')} ${evaluation ? `${evaluation.sourcing_points}` : '25'}%`,
            dataSource: 'development_points',
        }, {
            header:  `${translate && translate('rating.ratingsTable.quality')} ${evaluation ? `${evaluation.quality_points}` : '40'}%`,
            dataSource: 'quality_points',
        }, {
            header:  `${translate && translate('rating.ratingsTable.service')} ${evaluation ? `${evaluation.service_points}` : '10'}%`,
            dataSource: 'service_points',
        }, {
            header: <Translate id="rating.ratingsTable.finalScore" />,
            dataSource: 'final_score',
        }];
    return ({ columns });
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let selectedCompany = state.profile.get('selectedCompany')
    return {
        selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
        isLoading: state.ratings.get('isLoading'),
        rating: state.ratings.get('rating')? state.ratings.get("rating").toJS() : null,
        translate: getTranslate(state.localize),
        isLoadingEvaluation: state.ratings.get('isLoadingEvaluation'),
        evaluationRating: state.ratings.get('evaluationRating') ? state.ratings.get('evaluationRating').toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        getRatingBySupplier:(supplierCode) => {
           return dispatch(getRatingBySupplier(supplierCode))
        },
        createCorrectiveAction:(supplier, comment) => {
            return dispatch(createCorrectiveAction(supplier, comment))
        },
        loadEvaluationSuppliersRatingByCompany:(companyToken) =>{
            return dispatch(loadEvaluationSuppliersRatingByCompanyFromBackEnd(companyToken));
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(Create))));