import React, { Component } from 'react';
import autoBind from 'auto-bind'
import PropTypes from 'prop-types';

import { Translate } from 'react-localize-redux';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import TextField from '@material-ui/core/TextField';
import SuppliersSelector from '../../common/SupplierSelector';

/**
 * Component to create format
 */
class InformationCard extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            suppliers: [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.suppliers !== this.props.suppliers) {
            this.setState({
                suppliers: this.props.suppliers
            })
        }
    }

    getSuppliersList() {
        if (this.state.suppliers) {
            return (
                <List>
                    {this.state.suppliers.map((supplier) => {
                        return (<div key={supplier.name}>
                            <ListItem divider>
                                <ListItemText primary={<Typography variant="caption" gutterBottom>{supplier.name}</Typography>} />
                                <ListItemSecondaryAction>
                                    <IconButton id={supplier.token}
                                        onClick={(event) => { this.props.removeSupplier(event.currentTarget.id) }}
                                    >
                                        <DeleteIcon color='error' />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </div>
                        )
                    })}
                </List>
            )
        } else {
            return null;
        }
    }

    render() {
        const { sustainability, classes } = this.props;
        return (
            <Grid container spacing={24}>
                <Grid item xs={6}>
                    <List>
                        <ListItem>
                            <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="sustainability.selectdSuppliers"/></Typography>}
                            secondary={
                                <React.Fragment><br/>
                                     <SuppliersSelector
                                         className={classes.textField}
                                         onSupplierSelected={(supplier) => { this.props.updateSuppliersList(supplier) }}
                                    />
                                </React.Fragment>
                            } />
                        </ListItem>
                        {this.getSuppliersList()}
                            {this.state.suppliers.length === 0 &&
                                <div>
                                    <ListItem>
                                        <ListItemText primary={
                                            <Typography variant="subtitle2" gutterBottom>
                                                <Translate id="sustainability.messageNotxistSuppliers" />
                                            </Typography>
                                        } />
                                    </ListItem><Divider />
                                </div>
                            }
                    </List>
                </Grid>

                <Grid item xs={6}>
                    <List>
                        <ListItem>
                            <ListItemText primary={<Typography variant="subtitle1" gutterBottom>{<Translate id="sustainability.commentsToSupplier" />}</Typography>} 
                            secondary={
                                <React.Fragment>
                                  <TextField fullWidth
                                        id="outlined-multiline-flexible"
                                        multiline
                                        rows="4"
                                        value={sustainability.comments}
                                        onChange={(event) => { this.props.updatePropertyComment("comments", event.target.value) }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </React.Fragment>
                            }/>
                        </ListItem>
                    </List>
                </Grid>
        </Grid>
        );
    }
}
InformationCard.propTypes = {
    suppliers: PropTypes.array.isRequired,
    sustainability: PropTypes.object.isRequired
};

export default InformationCard;