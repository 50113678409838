import { fromJS } from 'immutable';

import * as manualsActions from './ManualPortalActions'

/**
 * Companies initial state
 */
export const manualsInitialState = fromJS({
    isLoadingManuals: true,
    manuals: null,
    isSavingManual: false,
    manualsCount: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    expedientSupplierCount: 0,
})

export default function companiesReducer(state = manualsInitialState, action) {
    switch (action.type) {
        case manualsActions.START_LOADING_MANUALS: {
            return state.merge({
                isLoadingManuals: action.isLoading
            })
        }
        case manualsActions.SET_MANUALS_LIST: {
            let manualsLists = fromJS(action.manuals);
            return state.merge({
                manuals: manualsLists,
                isLoadingManuals: false
            })
        }
        case manualsActions.CHANGE_MANUALS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case manualsActions.CHANGE_MANUALS_SORT_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case manualsActions.CHANGE_MANUALS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case manualsActions.SET_MANUALS_COUNT:{
            return state.merge({
                manualsCount: action.count
            })
        }
        case manualsActions.IS_SAVING_MANUAL: {
            return state.merge({
                isSavingManual: action.isSaving
            })
        }
        case manualsActions.ADD_MANUAL_TO_LIST:{
            let manualToAdd = fromJS(action.manual)
            return state.updateIn(["manuals"], (allManuals) => allManuals.push(manualToAdd))
            .set('isSavingManual', false);
        }
        case manualsActions.REMOVE_MANUAL_TO_LIST:{
            return state.updateIn(['manuals'], (allManuals) => {
                let index = allManuals.findIndex((item) => {
                    return item.get('token') === action.manualToken
                });
                return allManuals.delete(index).set('isSavingManual', false);
            })
        }
        default:
            return state;
    }
}