import React, { useState, useEffect, useRef, useCallback } from 'react'
import propTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Typography, IconButton, Button, Checkbox } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

/**
 * This component contains the datagrid BaseConfigurator
 */
const BaseConfigurator = (props) => {
	const [localConfiguration, setLocalConfiguration] = useState([])
	const { classes } = props

	useEffect(() => {
		let config = props.configuration.map((columnConfig) => {
			return { ...columnConfig }
		})
		setLocalConfiguration(config)
	}, [props.configuration])

	/**
	 * On change visible value for a column configuration
	 */
	const onChangeVisible = (title, value) => {
		let config = localConfiguration.map((columnConfig) => {
			if (columnConfig.title === title) return { ...columnConfig, visible: value }
			else return { ...columnConfig }
		})
		setLocalConfiguration(config)
	}

	/**
	 * Move an item from a location to another
	 * @param {*} from
	 * @param {*} to
	 */
	const onMoveItem = (from, to) => {
		let itemFrom = localConfiguration[from]
		let itemTo = localConfiguration[to]
		if (itemTo.isMovable === false) {
			console.warn(`Cannot move row to over ${itemTo.title}`)
			return
		}
		localConfiguration[from] = { ...itemTo }
		localConfiguration[to] = { ...itemFrom }
		setLocalConfiguration([...localConfiguration])
	}

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = localConfiguration[dragIndex]
            const newConfiguration = [...localConfiguration]
            // Remove the item from its original position
            newConfiguration.splice(dragIndex, 1)
            // Insert the item at the new position
            newConfiguration.splice(hoverIndex, 0, dragCard)
            setLocalConfiguration(newConfiguration)
        },
        [localConfiguration]
    )

	/**
	 * Render configuration
	 */
	return (
		<DndProvider backend={HTML5Backend}>
			<div className={classes.root}>
				<div />
				{/* Left so the save button is well placed */}
				<div className={classes.columItemList}>
					<Typography variant='h6' className={classes.configText}>
						{'Configuración'}
					</Typography>
					{localConfiguration.map((columnConfig, index) => {
						return (
							<ColumnItem
								{...columnConfig}
								index={index}
								onMoveCard={moveCard}
								count={localConfiguration.length}
								key={columnConfig.dataSource}
								onChangeVisible={onChangeVisible}
								onMoveItem={onMoveItem}
                                classes={classes}
							/>
						)
					})}
				</div>
				<div className={classes.acceptButtonDiv}>
					<Button
						className={classes.acceptButton}
						color={'primary'}
						variant='contained'
						onClick={() => props.onConfigurationChange(localConfiguration)}
						disableElevation={true}
					>
						<Typography variant='body1'>{props.saveButtonText}</Typography>
					</Button>
					<Button
						className={classes.resetIcon}
						variant='contained'
						onClick={props.onResetConfigurationTable}
						disableElevation={true}
					>
						<Typography variant='body1'>{'Resetear'}</Typography>
					</Button>
				</div>
			</div>
		</DndProvider>
	)
}
BaseConfigurator.propTypes = {
	/**
	 * The table configuration with visible and index properties
	 */
	configuration: propTypes.arrayOf(
		propTypes.shape({
			title: propTypes.string.isRequired,
			visible: propTypes.bool.isRequired,
		})
	).isRequired,
	/**
	 * Callback executed when the user save the configuration
	 */
	onConfigurationChange: propTypes.func.isRequired,
	/**
	 * The max height of the columns configuration container
	 */
	maxHeight: propTypes.string,
	/**
	 * The text in the save button
	 */
	saveButtonText: propTypes.string.isRequired,
}
BaseConfigurator.defaultProps = {
	configuration: [],
	onConfigurationChange: () => console.warn('No [onConfigurationChange] callback defined'),
	saveButtonText: 'Guardar',
}
// export default withStyles(styles)(BaseConfigurator)

/**
 * A title item
 */
const ColumnItem = (props) => {
	const { index, isMovable, count, title, visible } = props
	const { onMoveItem, onChangeVisible, onMoveCard, classes } = props
	/**
	 *  drag and drop implementation
	 */
	const ref = useRef(null)
	const [, drop] = useDrop({
		accept: 'title',
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			// Time to actually perform the action
			onMoveCard(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		},
	})
	const [{ isDragging }, drag] = useDrag({
		item: { type: 'title', id: index, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})
	const opacity = isDragging ? 0 : 1
	drag(drop(ref))

	/**
	 * Return title item
	 */

	return (
		<div className={classes.columnItem} ref={isMovable === true ? ref : null} style={{ opacity }}>
			<div className={classes.moveButtonContainer}>
				<IconButton
					disabled={index === 0 || isMovable !== true}
					size='small'
					onClick={() => onMoveItem(index, index - 1)}
				>
					<ArrowDropUpIcon fontSize='inherit' color='primary' />
				</IconButton>
				<IconButton
					disabled={index === count - 1 || isMovable !== true}
					size='small'
					onClick={() => onMoveItem(index, index + 1)}
				>
					<ArrowDropDownIcon fontSize='inherit' color='primary' />
				</IconButton>
			</div>
			<Typography variant='body1'>{title}</Typography>
			<div className={classes.visibilityContainer}>
				<Checkbox
					color='primary'
					disabled={isMovable !== true}
					checked={visible === true}
					onChange={(event) => onChangeVisible(title, event.target.checked)}
				/>
			</div>
		</div>
	)
}
ColumnItem.propTypes = {
	title: propTypes.oneOfType(propTypes.string, propTypes.node).isRequired,
	visible: propTypes.bool.isRequired,
	index: propTypes.number.isRequired,
	count: propTypes.number.isRequired,
	onChangeVisible: propTypes.func,
	isMovable: propTypes.bool,
	onMoveItem: propTypes.func,
}
ColumnItem.defaultProps = {
	title: '',
	visible: true,
	isMovable: true,

	index: 0,
}

const styles = (theme) => ({
	root: {
		display: 'grid',
		gridTemplateRows: 'auto 40px',
		width: '310px',
		height: '361px',
	},
	configText: {
		marginLeft: '52px',
		marginTop: '15px',
		marginBottom: '8.7px',
	},
	columItemList: {
		gridRow: '1 / 3',
		overflowY: 'auto',
		maxHeight: ({ maxHeight }) => `${maxHeight}`,
		backgroundColor: theme.palette.grey[50],
	},
	acceptButton: {
		display: 'flex',
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: theme.palette.primary.main,
		marginTop: '14px',
		marginBottom: '12px',
		gridRow: '3 / 4',
		boxShadow: 'none',
		width: '286.32px',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: theme.palette.common.white,
			border: `1.5px ${theme.palette.primary.main} solid`,
			color: theme.palette.primary.main,
		},
	},
	resetIcon: {
		display: 'flex',
		width: '286.32px',
		marginTop: '5px',
		marginBottom: '5px',
		marginLeft: 'auto',
		marginRight: 'auto',
		textTransform: 'none',
		backgroundColor: theme.palette.grey[100],
		color: theme.palette.primary.main,
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: theme.palette.grey[50],
			boxShadow: 'none',
			opacity: 1,
		},
	},
	acceptButtonDiv: {
		backgroundColor: theme.palette.grey[50],
		textTransform: 'none',
	},
	columnItem: {
		backgroundColor: theme.palette.common.white,
		padding: '3px',
		marginBottom: '3px',
		marginLeft: '10.7px',
		display: 'flex',
		alignItems: 'center',
		width: '284.6px',
	},
	moveButtonContainer: {
		marginRight: '10px',
		marginLeft: '20px',
	},
	visibilityContainer: {
		marginLeft: 'auto',
	},
})

export default withStyles(styles)(BaseConfigurator)