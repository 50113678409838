import React, { Component } from 'react'
import { connect } from 'react-redux'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/resource-timegrid'
import esLocale from '@fullcalendar/core/locales/es'
import ptLocale from '@fullcalendar/core/locales/pt'
import moment from 'moment'
import { BACKGROUND_COLOR_GRAY_DARK } from '../../../store/helpers/StatusColorConstants'

class CalendarComponent extends Component {
	getNextDayEvent(eventDay, dayOfWeek, allEvents) {
		let currentEventDay = eventDay
		for (let index = 0; index < 1; index++) {
			let initialStartDate = moment(currentEventDay.start).format('YYYY-MM-DD')
			let initialStartTime = moment(currentEventDay.start).format('HH:mm')
			let eventCreationDay = moment(initialStartDate).isoWeekday()
			let nextDayStart = moment()
			let nextDayEnd = moment()

			let initialEndDate = moment(currentEventDay.end).format('YYYY-MM-DD')
			let initialEndTime = moment(currentEventDay.end).format('HH:mm')

			// > Mayor que
			// < Menor que
			// if we haven't yet passed the day of the week that I need:
			// si aún no hemos pasado el día de la semana que necesito:
			if (eventCreationDay <= dayOfWeek) {
				// then just give me this week's instance of that day
				// entonces solo dame la instancia de ese día de esta semana
				nextDayStart = moment(initialStartDate).isoWeekday(dayOfWeek)
				nextDayEnd = moment(initialEndDate).isoWeekday(dayOfWeek)
			} else {
				// otherwise, give me *next week's* instance of that same day
				// de lo contrario, dame la * instancia de la próxima semana * de ese mismo día
				nextDayStart = moment(initialStartDate).add(1, 'weeks').isoWeekday(dayOfWeek)
				nextDayEnd = moment(initialEndDate).add(1, 'weeks').isoWeekday(dayOfWeek)
			}
			nextDayStart = moment(nextDayStart).format('YYYY-MM-DD') + 'T' + initialStartTime
			nextDayEnd = moment(nextDayEnd).format('YYYY-MM-DD') + 'T' + initialEndTime
			let rampNumber = currentEventDay.ramp
			if (rampNumber === 5) {
				rampNumber = 'Todas las rampas'
			}
			allEvents.push({
				title: `${currentEventDay.title}`,
				start: nextDayStart,
				end: nextDayEnd,
				id: currentEventDay.id,
				backgroundColor: currentEventDay.lock_by_supplier ? '#c5dfff' : '#85b1e8',
				...currentEventDay,
			})
			currentEventDay.start = nextDayStart
			currentEventDay.end = nextDayEnd
		}
		currentEventDay = null
		return allEvents
	}

	formatObjectEvents(events) {
		let allEvents = []
		events.forEach((event) => {
			allEvents.push({
				title: `${event.Title}`,
				start: event.StartDate,
				end: event.EndDate,
				id: event.Id,
				eventItem: event,
				backgroundColor:
					moment(event.end).diff(moment()) < 0
						? BACKGROUND_COLOR_GRAY_DARK
						: event.lock_by_supplier
						? '#a5eab5'
						: '#85b1e8',
			})
		})
		return allEvents
	}

	onSelectDateEvent(clickInfo, onOpenCalendarOption) {
		if (onOpenCalendarOption) {
			onOpenCalendarOption(clickInfo)
		}
	}

	render() {
		let locale = this.props.languages
			? this.props.languages.find((language) => {
					return language.active
			  })['code']
			: 'es'
		var allEvents = this.props.events ? this.formatObjectEvents(this.props.events) : []

		return (
			<FullCalendar
				defaultView='timeGridWeek'
				plugins={[dayGridPlugin, timeGridPlugin]}
				timeZone='UTC'
				locales={[esLocale, ptLocale]}
				locale={locale}
				header={{
					left: 'prev,next',
					center: 'title',
					right: 'timeGridDay,timeGridWeek,dayGridMonth',
				}}
				contentHeight='69vh'
				events={allEvents}
				eventClick={(clickInfo) => this.onSelectDateEvent(clickInfo, this.props.onOpenCalendarOption)}
			/>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		languages: state.localize.languages,
	}
}

export default connect(mapStateToProps, null)(CalendarComponent)
