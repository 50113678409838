///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import queryString from 'query-string'
//IMport Mui section
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import Avatar from '@material-ui/core/Avatar'
import Tabs from '@material-ui/core/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Fab from '@material-ui/core/Fab'
import CategoryIcon from '@material-ui/icons/Category'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import CategoriesLateralPanel from './components/CategoriesLateralPanel'
import CreatePhoneDirectoryPanel from './components/CreatePhoneDirectoryPanel'
import EditPhoneDirectoryPanel from './components/EditPhoneDirectoryPanel'
import PhoneCategoryTable from './components/PhoneCategoriesTable'
import EmptyView from './components/EmptyView'
import Toaster from '../common/Toaster'
import CategoryMaterialDescriptionDialog from './components/CategoryMaterialDescriptionDialog'
import ConfirmDeletePhoneDirectoryDialog from './components/ConfirmDeletePhoneDirectoryDialog'
//import action section
import {
	loadPhoneDiretoryByIdFromBackEnd,
	loadPhoneCategoryFromBackEnd,
	deletePhoneDirectoryFromBackEnd,
} from '../../store/phoneDirectories/PhoneDirectoryActions'
import { ROLE_PHONE_DIRECTORY_ADMINISTRATOR, IsUserInRole } from '../../store/helpers/RolesHelper'

import { converListToArray, toSafeObject } from '../../store/helpers/ListHelper'
import { Tab } from '@material-ui/core'

const IndexView = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	let location = useLocation()
	const queryParams = queryString.parse(location.search)
	let history = useHistory()
	let token = props.match.params.token
	let activeTab = props.match.params.activeTab
	activeTab = activeTab ? parseInt(activeTab) : 100

	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)

	const categories = useSelector((state) =>
		state.phoneDirectories.get('categories') ? converListToArray(state.phoneDirectories.get('categories')) : []
	)

	const isSaving = useSelector((state) => state.phoneDirectories.get('isSaving'))
	const phoneDirectoriesPaginated = useSelector((state) =>
		state.phoneDirectories.get('phoneDirectoriesPaginated')
			? toSafeObject(state.phoneDirectories.get('phoneDirectoriesPaginated'))
			: []
	)

	//Use state section
	const [openLateralPanelCategories, setOpenLateralPanelCategories] = useState(false)
	const [openLateralPanelCreatePhoneDirectory, setOpenLateralPanelCreatePhoneDirectory] = useState(false)
	const [categorySelected, setCategorySelected] = useState(null)
	const [categoryMaterialsDialog, setCategoryMaterialsDialog] = useState({ open: false, categoryMaterials: '' })
	const [confirmDeleteDialogConfig, setConfirmDeleteDialogConfig] = useState({ item: null, open: false })
	const [editPhoneCategoryDialogConfig, setEditPhoneCategoryDialogConfig] = useState({ item: null, open: false })
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	const onOpenEditCatDialog = (item) => {
		setEditPhoneCategoryDialogConfig({ item: item, open: !editPhoneCategoryDialogConfig.open })
	}

	const onOpenConfirmDeleteDialog = (item) => {
		setConfirmDeleteDialogConfig({ item: item, open: !confirmDeleteDialogConfig.open })
	}
	const onChangeTab = (event, value, category) => {
		props.history.push(`/phoneDirectory/${category.Id}/${value}`)
		setCategorySelected(category)
	}

	const onCleanView = () => {
		props.history.push(`/phoneDirectory`)
	}

	const onOpenCloseCategoriesLateralPanel = () => {
		setOpenLateralPanelCategories(!openLateralPanelCategories)
	}

	const onOpenCloseCreatePhoneDirectoryLateralPanel = () => {
		setOpenLateralPanelCreatePhoneDirectory(!openLateralPanelCreatePhoneDirectory)
	}

	const onDeletePhoneDirectory = () => {
		return dispatch(deletePhoneDirectoryFromBackEnd(confirmDeleteDialogConfig.item.phoneDirectoryId))
			.then(() => {
				onOpenConfirmDeleteDialog({ item: null, open: false })
			})
			.catch((error) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
	}

	const onChangeQuery = (searchQuery) => {
		// const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**Use effect section */
	useEffect(() => {
		dispatch(loadPhoneCategoryFromBackEnd())
	}, [dispatch])

	/**Use effect section */
	useEffect(() => {
		// const queryParams = queryString.parse(location.search)
		if (props.match.params.token) {
			dispatch(loadPhoneDiretoryByIdFromBackEnd(props.match.params.token, queryParams))
		}
	}, [dispatch, props.match.params.token, location.search])

	let totalPhoneDirectory = []
	const getCounterMessage = () => {
		if (totalPhoneDirectory) {
			let totalBiddings = phoneDirectoriesPaginated.items.length
			let biddingsCount = phoneDirectoriesPaginated.total
			return (
				<div>
					{<Translate id='common.showing' />}
					<span className={classes.showingCounter}>{totalBiddings}</span>&nbsp;
					{<Translate id='common.of' />}
					<span className={classes.showingCounter}>{biddingsCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	const onSetCategoryMaterialsDescription = (message) => {
		setCategoryMaterialsDialog({ open: !categoryMaterialsDialog.open, categoryMaterials: message })
	}

	const onRenderPhoneDirectoryTabs = () => {
		return (
			<AppBar position='static' color='default'>
				<Tabs variant='fullWidth' indicatorColor='primary' textColor='primary' value={activeTab}>
					{categories.map((category, index) => {
						return (
							<Tab
								label={category.Name}
								onClick={(event, value) => onChangeTab(event, index, category)}
								style={{ maxWidth: '100px' }}
								// style={{ width: classes.tabWidth }}
							/>
						)
					})}
				</Tabs>
			</AppBar>
		)
	}
	const onChangePage = (page, rowsPerPage) => {
		queryParams.pageNumber = page
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	let canBeEditInformation = IsUserInRole(ROLE_PHONE_DIRECTORY_ADMINISTRATOR, userRolesByCompany)
	const onGetCardContent = () => {
		if (token === undefined)
			return (
				<div>
					{onRenderPhoneDirectoryTabs()}
					<EmptyView />
				</div>
			)
		else {
			return (
				<div>
					{onRenderPhoneDirectoryTabs()}
					<PhoneCategoryTable
						phoneDirectoriesPaginated={phoneDirectoriesPaginated}
						categorySelected={categorySelected}
						onOpenCloseCreatePhoneDirectoryLateralPanel={onOpenCloseCreatePhoneDirectoryLateralPanel}
						onOpenConfirmDeleteDialog={onOpenConfirmDeleteDialog}
						onChangeQuery={onChangeQuery}
						onSetCategoryMaterialsDescription={onSetCategoryMaterialsDescription}
						onOpenEditCatDialog={onOpenEditCatDialog}
						canBeEditInformation={canBeEditInformation}
						queryParams={queryParams}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				</div>
			)
		}
	}

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<ContactPhoneIcon />
					</Avatar>
				}
				action={
					IsUserInRole(ROLE_PHONE_DIRECTORY_ADMINISTRATOR, userRolesByCompany) && (
						<div style={{ display: 'flex' }}>
							<div style={{ marginRight: '5px', marginTop: '8px' }}>
								<Typography>
									<Translate id='phoneDirectories.category' />
								</Typography>
							</div>
							<Fab
								size='small'
								color='primary'
								className={classes.categoryIcon}
								onClick={onOpenCloseCategoriesLateralPanel}
							>
								<CategoryIcon />
							</Fab>
						</div>
					)
				}
				title={<Typography variant='button'>{<Translate id='phoneDirectories.title' />}</Typography>}
				subheader={getCounterMessage()}
			/>
			<CardContent>{onGetCardContent()}</CardContent>

			{openLateralPanelCategories && (
				<CategoriesLateralPanel
					onCloseButtonClick={onOpenCloseCategoriesLateralPanel}
					onCleanView={onCleanView}
				/>
			)}

			{openLateralPanelCreatePhoneDirectory && (
				<CreatePhoneDirectoryPanel
					onCloseButtonClick={onOpenCloseCreatePhoneDirectoryLateralPanel}
					categorySelected={categorySelected}
					token={props.match.params.token}
				/>
			)}

			<CategoryMaterialDescriptionDialog
				open={categoryMaterialsDialog ? categoryMaterialsDialog.open : ''}
				activityMaterialsMessage={categoryMaterialsDialog ? categoryMaterialsDialog.categoryMaterials : ''}
				onClose={() => onSetCategoryMaterialsDescription('')}
				isSaving={isSaving}
			/>
			<ConfirmDeletePhoneDirectoryDialog
				open={confirmDeleteDialogConfig.open}
				onDeletePhoneDirectory={onDeletePhoneDirectory}
				onClose={() => onOpenConfirmDeleteDialog({ item: null, open: false })}
				isSaving={isSaving}
			/>
			{editPhoneCategoryDialogConfig && editPhoneCategoryDialogConfig.open && (
				<EditPhoneDirectoryPanel
					onCloseButtonClick={onOpenEditCatDialog}
					item={editPhoneCategoryDialogConfig.item}
					token={props.match.params.token}
				/>
			)}
			<Toaster
				message={
					<Translate id={toasterMessageConfig.toasterMessage} data={{ name: toasterMessageConfig.name }} />
				}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</Card>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	showingCounter: {
		marginLeft: '5px',
	},
	biddingContainer: {
		maxHeight: `calc(100vh - ${275}px)`,
		minHeight: `calc(100vh - ${275}px)`,
		overflowY: 'hidden',
	},
	categoryIcon: {
		marginLeft: '5px',
	},
	tabWidth: {
		maxWidth: '150px',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(IndexView)))
