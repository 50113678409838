import React from 'react'
import propTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import { IconButton, Tooltip, Typography } from '@material-ui/core'
import DataTable from '../../common/DataTable'
import { Add, AttachFile, DeleteOutlined, Settings } from '@material-ui/icons'

import { Translate } from 'react-localize-redux'
import OptionsMenu from '../../common/OptionsMenu'
import FilesLinks from '../../common/FilesLinks'

const Materiales = ({
	setOpenAddMaterialPanel,
	tableConfiguration,
	developmentSelected,
	classes,
	materials,
	isLoading,
	removeItemInRequestPriceChange,
	onMenuOptionItemClick,
	onAddPlanClick,
	companyToken,
	companyCode,
	kind,
}) => {
	const renderOptionsMenu = (item, optionToShow) => {
		var options = []
		if (optionToShow === 1)
			options.push({
				itemClick: () => onAddPlanClick(item),
				tooltipTranslation: <Translate id='common.addInformation' />,
				menuItemIcon: <Settings color={isLoading ? 'inherit' : 'action'} />,
				isLoading: isLoading,
			})
		if (optionToShow === 2)
			options.push({
				itemClick: () => removeItemInRequestPriceChange(item.token),
				tooltipTranslation: <Translate id='common.delete' />,
				menuItemIcon: <DeleteOutlined color={isLoading ? 'inherit' : 'action'} />,
				isLoading: isLoading,
			})
		if (optionToShow === 3)
			options.push({
				itemClick: () => onMenuOptionItemClick(item),
				tooltipTranslation: <Translate id='common.addAttachments' />,
				menuItemIcon: <AttachFile color={isLoading ? 'inherit' : 'secondary'} />,
				isLoading: isLoading,
			})
		return <OptionsMenu open={false} itemToken={item.token} options={options} xsSize />
	}
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'options') {
			return (
				<React.Fragment>
					{renderOptionsMenu(item, 1)}
					{renderOptionsMenu(item, 2)}
					{renderOptionsMenu(item, 3)}
				</React.Fragment>
			)
		}
		if (dataSource === 'plot_name') {
			return <FilesLinks item={item} hasPlot companyToken={companyToken} companyCode={companyCode} kind={kind} />
		}
	}
	return (
		<div>
			<div className={classes.header}>
				<Typography>
					<Translate id={'priceChangeRequest.create.materials.title'} />
				</Typography>
				{developmentSelected ? (
					<IconButton onClick={() => setOpenAddMaterialPanel(true)} className={classes.addBtn}>
						<Add fontSize='small' />
					</IconButton>
				) : (
					<Tooltip title={<Translate id={'priceChangeRequest.create.development.helpText'} />}>
						<IconButton>
							<Add fontSize='small' />
						</IconButton>
					</Tooltip>
				)}
			</div>
			<div>
				<DataTable data={materials} configuration={tableConfiguration} onRenderCellItem={onRenderCellItem} />
			</div>
		</div>
	)
}

Materiales.propTypes = {
	isLoading: propTypes.bool,
	materials: propTypes.array,
	tableConfiguration: propTypes.object,
	setOpenAddMaterialPanel: propTypes.func,
	removeItemInRequestPriceChange: propTypes.func,
	onAddPlanClick: propTypes.func,
}
Materiales.defaultProps = {
	isLoading: false,
	materials: [],
	tableConfiguration: {},
	setOpenAddMaterialPanel: () => console.warn('No [setOpenAddMaterialPanel] CallBack defined'),
	removeItemInRequestPriceChange: () => console.warn('No [removeItemInRequestPriceChange] CallBack defined'),
	onAddPlanClick: () => console.warn('No [onAddInformationClick] CallBack defined'),
}
const styles = (theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	addBtn: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
})
export default withStyles(styles, { withTheme: true })(Materiales)
