
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import PropTypes from 'prop-types';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import AcceptIcon from '@material-ui/icons/ThumbUp';
import RejectIcon from '@material-ui/icons/ThumbDown';
import CancelIcon from '@material-ui/icons/Cancel';

/** Import component section */
import Dialog from './Dialog';
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';

/**
 * Dialog to accept or reject 
 *
 * @class AcceptRejectDialog
 * @extends {Component}
 */
class AcceptRejectDialog extends Component {

    /**
     *Creates an instance of DialogActions.
     * @param {*} props
     * @memberof DialogActions
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            comment: ''
        }
    }

    /**
     * Handle to clean state then call OnClickCloseCancel function
     *
     * @memberof AcceptRejectDialog
     */
    handleOnCloseCancel() {
        this.setState({ comment: '' });
        this.props.onClickCloseCancel();
    }

    /**
     * Render button component
     *
     * @param {*} onClick
     * @param {*} color
     * @param {*} disabled
     * @param {*} idTranslation
     * @param {*} icon
     * @param {string} [variant="outlined"]
     * @returns
     * @memberof AcceptRejectDialog
     */
    renderButton(onClick, color, disabled, idTranslation, icon, variant = "outlined") {
        return (
            <Grid item key={idTranslation}>
                <Button
                    onClick={onClick}
                    color={color}
                    style={{
                        color: color === 'error' ? "#f44336" : '',
                        borderColor: color === 'error' ? "#f44336" : '',
                    }}
                    disabled={disabled}
                    variant={variant}
                    className={this.props.classes.button}
                >
                    {icon}<Translate id={idTranslation} />
                </Button>
            </Grid>
        )
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClickCloseCancel}
                header={
                    <Typography variant="h6">
                        <Translate id={this.props.isReject ? "common.reject" : "common.accept"} />{' ' + this.props.additionalTitle}
                    </Typography>
                }
                actions={
                    <React.Fragment>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justify="flex-end">
                                    {!this.props.isReject && this.renderButton(
                                        () => this.props.onClickAccept(this.state.comment),
                                        "secondary",
                                        this.props.isSaving,
                                        "common.accept",
                                        <AcceptIcon color="secondary" />
                                    )}
                                    {this.props.isReject && this.renderButton(
                                        () => this.props.onClickReject(this.state.comment),
                                        ((this.props.isSaving || IsNullOrEmpty(this.state.comment)) ? "default" : "error"),
                                        (this.props.isSaving || IsNullOrEmpty(this.state.comment)),
                                        "common.reject",
                                        <RejectIcon color={this.props.isSaving || IsNullOrEmpty(this.state.comment) ? 'default' : "error"} />
                                    )}
                                    {this.renderButton(() => this.handleOnCloseCancel(), "primary", this.props.isSaving, "common.cancel", <CancelIcon color="primary" />)}
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            this.props.isSaving === true ?
                                <LinearProgress color="secondary" variant="query" />
                                : null
                        }
                    </ React.Fragment>
                }>
                <form>
                    <TextField fullWidth
                        className={classes.textField}
                        multiline
                        rows="4"
                        rowsMax="8"
                        margin="normal"
                        variant="outlined"
                        value={this.state.comment}
                        label={<Translate id='common.comments' />}
                        onChange={(event) => { this.setState({ comment: event.target.value }) }}
                    />
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
});

AcceptRejectDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool,
    isReject: PropTypes.bool,
    additionalTitle: PropTypes.string,
    onClickAccept: PropTypes.func.isRequired,
    onClickReject: PropTypes.func.isRequired,
    onClickCloseCancel: PropTypes.func.isRequired
}

export default withStyles(dialogStyles, { withTheme: true })(AcceptRejectDialog);