import React, { Component } from 'react'
import moment from 'moment'
import 'moment/locale/es'
import uuidv4 from 'uuid/v4'

/** Language imports **/
import { Translate } from 'react-localize-redux'

//import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import PendingTasksIcon from '@material-ui/icons/AssignmentTwoTone'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import NoticesOfChangeIcon from '@material-ui/icons/SwapHorizTwoTone'
import QuotesIcon from '@material-ui/icons/AttachMoneyTwoTone'
import SamplesIcon from '@material-ui/icons/UnarchiveTwoTone'
import SACRequestIcon from '@material-ui/icons/BugReportTwoTone'

import RawMaterialNonConfIcon from '@material-ui/icons/BugReport'
import QualityFailureReportIcon from '@material-ui/icons/WarningOutlined'
import ReworkIcon from '@material-ui/icons/AutorenewOutlined'
import DevolutionIcon from '@material-ui/icons/ReplyAllOutlined'
import SacsQualityIcon from '@material-ui/icons/Report'
import WarrantyQualityIcon from '@material-ui/icons/VerticalSplit'

import QuestionnaireIcon from '@material-ui/icons/NaturePeople'
import GuaranteeReportIcon from '@material-ui/icons/FileCopyOutlined'

import PurchaseIcon from '@material-ui/icons/CalendarTodayOutlined'
import MaquilaIcon from '@material-ui/icons/Description'
import ShippingIcon from '@material-ui/icons/ShoppingCart'
import FreightIcon from '@material-ui/icons/LocalShipping'

import BiddingIcon from '@material-ui/icons/ViewCarousel'
import RateReviewTwoToneIcon from '@material-ui/icons/RateReviewTwoTone'
import ExpedientsIcon from '@material-ui/icons/FormatListNumbered'
import FindInPage from '@material-ui/icons/FindInPage'
import ProspectSupplierIncon from '@material-ui/icons/LocalShipping'
import { CreditCardOutlined } from '@material-ui/icons'

/**
 * Pending tasks
 *
 * @class PendingTasks
 * @extends {Component}
 */
class PendingTasks extends Component {
	/**
	 * get icon to show in tacks
	 */
	getDocumentIcon(workflow_type) {
		switch (workflow_type) {
			case 'QuoteSamples':
				return <QuotesIcon color='primary' />
			case 'SampleRequest':
				return <SamplesIcon color='primary' />
			case 'NoticeOfChange':
				return <NoticesOfChangeIcon color='primary' />
			case 'CorrectiveActionDevelopment':
				return <SACRequestIcon color='primary' />
			case 'RawMaterialNonConformity':
				return <RawMaterialNonConfIcon color='primary' />
			case 'FailureReport':
				return <QualityFailureReportIcon color='primary' />
			case 'Rework':
				return <ReworkIcon color='primary' />
			case 'CorrectiveAction':
				return <SacsQualityIcon color='primary' />
			case 'Devolution':
				return <DevolutionIcon color='primary' />
			case 'WarrantyReportsPtm':
				return <WarrantyQualityIcon color='primary' />
			case 'DeliverOrder':
				return <PurchaseIcon color='primary' />
			case 'ShippingOrder':
				return <ShippingIcon color='primary' />
			case 'Freight':
				return <FreightIcon color='primary' />
			case 'Sustainability':
				return <QuestionnaireIcon color='primary' />
			case 'Guarantee':
				return <GuaranteeReportIcon color='primary' />
			case 'QuestionnairePTM':
				return <QuestionnaireIcon color='primary' />
			case 'MaquilaOrder':
				return <MaquilaIcon color='primary' />
			case 'Bidding':
				return <BiddingIcon color='primary' />
			case 'RatingIndirect':
				return <RateReviewTwoToneIcon color='primary' />
			case 'ExpedientSupplier':
				return <ExpedientsIcon color='primary' />
			case 'ConsignmentNote':
				return <FindInPage color='primary' />
			case 'AuditQuality':
				return <FindInPage color='primary' />
			case 'Prospects':
				return <ProspectSupplierIncon color='primary' />
			case 'QualityCertificate':
				return <CreditCardOutlined color='primary' />
			default:
				return <PendingTasksIcon color='primary' />
		}
	}

	/**
	 * Get roles
	 *
	 * @returns
	 * @memberof CompaniesCard
	 */
	getTasksList() {
		const { classes } = this.props
		const { notifications, isLoadingNotifications } = this.props
		if (isLoadingNotifications === false && notifications !== null && notifications.length !== 0) {
			return (
				<List dense>
					{notifications.map((notification) => {
						moment.locale('es')
						moment().utc()
						let dateTime = moment(notification.workflow_date_time, 'DD-MM-YYYY hh:mm:ss am pm').from()
						return (
							<ListItem
								key={uuidv4()}
								dense
								button
								divider
								onClick={() => {
									this.props.history.push(
										`/${notification.workflow_url_document}/${notification.workflow_token}`
									)
								}}
							>
								<ListItemText
									primary={
										<React.Fragment>
											<Typography variant='overline' color='primary' gutterBottom>
												{this.getDocumentIcon(notification.workflow_type)}
												<strong> {notification.workflow_folio}</strong>
											</Typography>
										</React.Fragment>
									}
								/>
								<ListItemSecondaryAction>
									<span className='text-muted'> {dateTime}</span>
								</ListItemSecondaryAction>
							</ListItem>
						)
					})}
				</List>
			)
		} else if (isLoadingNotifications) {
			return (
				<Typography variant='overline' className={classes.noPendingTask}>
					<Translate id='common.loadingNotificationHome' />
				</Typography>
			)
		} else {
			return (
				<Typography variant='overline' className={classes.noPendingTask}>
					<Translate id='common.noPendingNotifications' />
				</Typography>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof PendingTasks
	 */
	render() {
		const { classes } = this.props
		return (
			<Card className={classes.card}>
				<CardHeader
					className={classes.title}
					avatar={<PendingTasksIcon color='primary' />}
					title={
						<span>
							{'Usted tiene '}
							{this.props.notifications && this.props.notifications.length}
							{' Tareas pendientes'}
						</span>
					}
				/>
				<CardContent style={{ height: '65vh', overflow: 'auto' }}>{this.getTasksList()}</CardContent>
				<CardActions className={classes.actions} disableActionSpacing></CardActions>
			</Card>
		)
	}
}

const styles = (theme) => ({
	card: {},
	title: {
		color: 'red',
	},
	noPendingTask: {
		color: theme.palette.primary.light,
	},
	avatar: {
		backgroundColor: theme.palette.primary.main,
	},
	inline: {
		display: 'inline',
	},
	following: {},
	cardContent: {},
	profileOptionTitle: {},
	padingOption: {},
	paper: {},
	listStyle: {},
	logo: {},
	linkStyle: {},
	tabs: {},
	userAvatar: {},
	userIcon: {},
	accountAvatar: {},
	avatarFileInput: {},
})

export default withStyles(styles, { withTheme: true })(PendingTasks)
