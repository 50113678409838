import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import Select from 'react-select'

import { DialogActions, Button, Grid, TextField, Typography, Tooltip, IconButton } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'

import SuppliersSelector from '../../common/SupplierSelector'
import { GetReportType } from '../../../store/helpers/SelectOptions'
import Toaster from '../../common/Toaster'

/**Import component section */
import Evidence from '../../common/Evidence'
import CustomerBySelector from './CustomerBySelector'
import FailureBySelector from './FailureBySelector'
import PartNumberSelector from '../../common/PartNumberSelector'
import DialogEvidenceHelper from './DialogEvidenceHelper'

/**
 * Class component of form to create sacQuality
 *
 * @class FormView
 * @extends {Component}
 */
class FormView extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			files: [],
			problemDescription: null,
			partNumberSelected: null,
			measurementUnit: null,
			amountReportedFault: null,
			producedBy: null,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			reportType: null,
			customerSelected: null,
			failureSelected: null,
			userNameReport: null,
			isActiveReportInPlant: false,
			serialNumber: null,
			serviceOrderRepare: null,
			openModalEvidenHelper: false,
		}
	}

	/**
	 * Update property in state
	 *
	 * @param {*} property
	 * @param {*} value
	 * @memberof FormView
	 */
	updateProperty(property, value) {
		this.setState({
			[property]: value,
		})
	}

	/**
	 * On seleted part number
	 */
	onSelected(propertyId, value) {
		this.setState({ [propertyId]: value })
	}

	/**
	 * On change report type
	 *
	 * @param {*} option
	 * @memberof ReportTypeSelect
	 */
	onChangeMeasurementUnit(option) {
		if (option.value === 'Garantía de planta') {
			this.setState({
				isActiveReportInPlant: true,
			})
		} else {
			this.setState({
				isActiveReportInPlant: false,
			})
		}
		this.setState({
			reportType: option.value,
		})
	}

	/**
	 * call to action to save data and create report guarantee
	 *
	 * @memberof FormView
	 */
	onSaveGuaranteeReport() {
		if (this.props.createGuaranteeReport)
			this.props
				.createGuaranteeReport(this.state)
				.then((response) => {
					this.props.history.push(`/guaranteereport/${response.token}`)
				})
				.catch(() => {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				})
	}

	/**
	 * On change files of evidence
	 *
	 * @param {*} event
	 * @memberof FormView
	 */
	onChangeFile(files) {
		this.setState({
			files,
		})
		return Promise.resolve('Ok')
	}

	/**
	 * On remove files of evidence
	 *
	 * @param {*} event
	 * @memberof FormView
	 */
	onDeleteFile(fileName) {
		let newFiles = this.state.files.filter((file) => {
			return file.name !== fileName
		})
		this.setState({ files: newFiles })
		return Promise.resolve('Ok')
	}

	/**
	 * Render textFiel Enabled to edit
	 *
	 * @param {*} textFieldId
	 * @param {*} translationTextFieldId
	 * @param {boolean} [isMultiple=false]
	 * @param {string} [type='text']
	 * @returns
	 * @memberof FormView
	 */
	renderTextField(textFieldId, translationTextFieldId, isMultiple = false, type = 'text', isDisabled = false) {
		return (
			<div>
				<TextField
					disabled={isDisabled}
					id={textFieldId}
					fullWidth
					margin='normal'
					value={this.state[textFieldId] ? this.state[textFieldId] : ''}
					label={<Translate id={translationTextFieldId} />}
					onChange={(event) => {
						this.updateProperty(textFieldId, event.target.value)
					}}
					className={isMultiple ? this.props.classes.textAreaField : this.props.classes.textField}
					type={type}
					// Only textAreak
					multiline={isMultiple}
					rows={isMultiple ? '4' : '1'}
					variant={isMultiple ? 'outlined' : 'standard'}
				/>
			</div>
		)
	}

	/**
	 * Method to validate required data is filled
	 *
	 * @returns
	 * @memberof FormView
	 */
	canBeSaved() {
		if (!this.state.userNameReport) return false
		if (!this.state.reportType) return false
		if (!this.state.problemDescription) return false
		if (
			this.state.isActiveReportInPlant &&
			(!this.state.serialNumber || (this.state.serialNumber && this.state.serialNumber.length < 9))
		)
			return false
		if (this.state.files.length < 1) return false
		if (
			!this.state.serviceOrderRepare ||
			(this.state.serviceOrderRepare &&
				(this.state.serviceOrderRepare.length < 9 || this.state.serviceOrderRepare.length > 10))
		)
			return false
		if (this.state.isActiveReportInPlant && this.state.serialNumber === this.state.serviceOrderRepare) return false
		if (!this.state.supplier) return false
		if (!this.state.partNumberSelected) return false
		if (!this.state.customerSelected) return false
		if (!this.state.failureSelected) return false
		if (!this.state.amountReportedFault) return false
		return true
	}

	/**
	 * Perforn render of component
	 *
	 * @returns
	 * @memberof FormView
	 */
	render() {
		const { classes } = this.props
		let measurements = GetReportType()
		return (
			<Grid container spacing={24} style={{ paddingLeft: '80px', paddingRight: '100px' }}>
				<Grid item xs={6}>
					{this.renderTextField('userNameReport', 'moduleGuarantee.reportGuarantee.producedBy', false)}
					<div className={classes.textField}>
						<Typography variant='subtitle1' gutterBottom>
							<Translate id='moduleGuarantee.reportGuarantee.reportType' />
						</Typography>
						<Select
							fullWidth
							defaultValue={measurements[0]}
							options={measurements}
							onChange={this.onChangeMeasurementUnit}
							styles={{
								menu: (base) => ({ ...base, zIndex: 10 }),
							}}
						/>
					</div>
					{this.state.isActiveReportInPlant &&
						this.renderTextField('serialNumber', 'moduleGuarantee.serialNumber', false)}
					{this.renderTextField(
						'problemDescription',
						'moduleGuarantee.reportGuarantee.problemDescription',
						true
					)}
					<Grid item xs={12}>
						<Typography>
							<Translate id='common.attachments' />
							<Tooltip title={<Translate id='moduleGuarantee.reportGuarantee.helpButton' />}>
								<IconButton
									color='primary'
									onClick={() => {
										this.setState({ openModalEvidenHelper: true })
									}}
								>
									<HelpOutline />
								</IconButton>
							</Tooltip>
						</Typography>
						<Evidence
							files={this.state.files}
							baseApi={process.env.REACT_APP_SIP_URL_FILES}
							removeFiles={(event) => this.onDeleteFile(event)}
							uploadFiles={(event) => this.onChangeFile(event)}
						/>
					</Grid>
					{this.renderTextField(
						'serviceOrderRepare',
						'moduleGuarantee.reportGuarantee.serviceOrderRepare',
						false
					)}
				</Grid>
				<Grid item xs={6}>
					<div className={classes.textField}>
						<Typography variant='subtitle1' gutterBottom>
							<Translate id='common.supplier' />
						</Typography>
						<SuppliersSelector
							className={classes.textField}
							onSupplierSelected={(supplier) => {
								this.onSelected('supplier', supplier)
							}}
						/>
					</div>
					<div className={classes.textField}>
						<Typography variant='subtitle1' gutterBottom>
							<Translate id='common.partNumber' />
						</Typography>
						<PartNumberSelector
							className={classes.textField}
							onPartNumberSelected={(partNumber) => {
								this.onSelected('partNumberSelected', partNumber)
							}}
						/>
					</div>
					<div className={classes.textField}>
						<TextField
							disabled={true}
							id='standard-read-only-input'
							label={<Translate id='common.descriptionPartNumber' />}
							value={this.state.partNumberSelected ? this.state.partNumberSelected.description : ''}
							margin='normal'
							onChange={(event) => {
								this.updateProperty('descriptionPartNumber', event.target.value)
							}}
							fullWidth
						/>
					</div>

					<div className={classes.textField}>
						<TextField
							disabled={true}
							id='standard-read-only-input'
							label={<Translate id='common.measurementUnit' />}
							value={this.state.partNumberSelected ? this.state.partNumberSelected.measurementUnit : ''}
							margin='normal'
							onChange={(event) => {
								this.updateProperty('measurementUnit', event.target.value)
							}}
							fullWidth
						/>
					</div>
					<div className={classes.textField}>
						<Typography variant='subtitle1' gutterBottom>
							<Translate id='moduleGuarantee.reportGuarantee.customer' />
						</Typography>
						<CustomerBySelector
							className={classes.textField}
							onCustomerGuaranteeSelected={(customerSelected) => {
								this.onSelected('customerSelected', customerSelected)
							}}
						/>
						<br />
					</div>
					<div className={classes.textField}>
						<Typography variant='subtitle1' gutterBottom>
							<Translate id='moduleGuarantee.reportGuarantee.failure' />
						</Typography>
						<FailureBySelector
							className={classes.textField}
							onFailureGuaranteeSelected={(failureSelected) => {
								this.onSelected('failureSelected', failureSelected)
							}}
						/>
						<br />
					</div>
					<div className={classes.textField}>
						<TextField
							fullWidth
							id='standard-number'
							label={<Translate id='moduleGuarantee.reportGuarantee.amountReportedFault' />}
							defaultValue={this.state.amountReportedFault}
							onChange={(event) => {
								this.updateProperty('amountReportedFault', event.target.value)
							}}
							type='number'
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
						/>
					</div>
				</Grid>
				<Grid item xs={12}>
					<DialogActions>
						<Button
							data-cy='btn-save'
							type='button'
							color='primary'
							onClick={() => this.onSaveGuaranteeReport()}
							disabled={!this.canBeSaved() || this.props.isSaving}
						>
							{<Translate id='common.save' />}
						</Button>
						<Button
							onClick={() => {
								this.props.history.push(`/guaranteereport`)
							}}
							color='secondary'
						>
							{<Translate id='common.cancel' />}
						</Button>
					</DialogActions>
				</Grid>
				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
				{this.state.openModalEvidenHelper && (
					<DialogEvidenceHelper
						open={this.state.openModalEvidenHelper}
						onClose={() => {
							this.setState({ openModalEvidenHelper: false })
						}}
					/>
				)}
			</Grid>
		)
	}
}

const viewStyles = (theme) => ({
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		marginBottom: 10,
	},
	textAreaField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	dense: {
		marginTop: 19,
	},
	menu: {
		width: 200,
	},
	footerForm: {
		display: 'flex',
		paddingRight: '60px',
	},
	formControl: { margin: theme.spacing.unit },
	toolbar: {},
	searchInput: {},
	button: {},
	actionsToolbar: {},
	showingUsersCounter: {},
	grow: {},
	supplierBadge: {},
})

export default withRouter(withStyles(viewStyles, { withTheme: true })(FormView))
