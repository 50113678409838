import * as rolesHelper from './RolesHelper'

export const Draft = 1
export const DeleteDraft = 2
export const UpdateDraft = 3
export const InProcess = 4
export const Closed = 20
export const Cancel = 21
export const Decline = 22
export const RawMaterialNonConformity_NotificationSent = 200
export const RawMaterial_ReturnToSupplier = 201
export const RawMaterialNonConformity_DeviationAuthorization = 204
export const RawMaterialNonConformity_ReworkRequest = 205
export const RawMaterialNonConformity_EditDraft = 209
export const RawMaterialNonConformity_DevolutionRequest = 212
export const FailureReport_NotificationSent = 300
export const FailureReport_MaterialReturnedToSupplier = 301
export const FailureReport_DeviationAuthorized = 302
export const FailureReport_RequestRework = 305
export const FailureReport_ClosedIsNotAttributableToTheSupplier = 309
export const WaitingForTheCompletionDateOfTheContainmentToClose = 310
export const FailureReport_AddEmergentAction = 311
export const FailureReport_AddCorrectiveActionBysupplier = 312
export const FailureReportQualirtEditDraft = 313
export const FailureReport_RegisterWarehouseEntry = 314
export const Rework_ReworkReturnToSupplier = 401
export const Rework_ReworkExecuted = 402
export const Rework_CloseToScrap = 406
export const SacQuality_SendNotification = 501
export const CorrectiveAction_ReponseFromSupplier = 502
export const CorrectiveAction_RegisterWarehouseEntry = 510
export const Devolution_WaitingByTheSupplierPickedUp = 601
export const Quote_RequestedToSupplier = 701
export const Quote_RespondBySupplier = 702
export const Quote_Send_RequoteToSupplier = 705
export const Quote_Declined_BySupplier = 707
export const Quote_SendReminderToSupplier = 708
export const Quote_AcceptedByEngineer = 709
export const Quote_RejectedByEngineer = 710
export const RMI_RespondedBySupplier = 802
export const RMI_Accepted = 803
export const RMI_Rejected = 804
export const RMI_ActivateToTheSupplierForModify = 805
export const RMI_SampleArrivedToClientFacilities = 808
export const RMI_InProcessOfCaptureByTheSupplier = 809
export const Sample_RequestedToSupplier = 901
export const Sample_RequestDeclined = 902
export const Sample_RequestWithoutRMIInWaitingToSampleArrive = 903
export const NoticeOfChangeRespondedBySupplier = 1001
export const CorrectiveActionDevelopment_NotificationSent = 1101
export const CorrectiveActionDevelopment_SendToDevelopmentEngineer = 1102
export const CorrectiveActionDevelopment_ResendCorrectiveAction = 1103
export const CorrectiveActionDevelopment_Close_And_Accepted = 1104
export const CorrectiveActionDevelopment_IsRequestForResponsible_ForModification = 1105
export const CorrectiveActionDevelopment_RequireAuthorizationDevelopmentAdministrator = 1106
export const ShippingOrder_SendShippingOrderToClient = 1403
export const ShippingOrder_ForwardTheSupplierToCorrectDocuments = 1404

export const Guarantee_SendGuaranteeToSupplier = 1800
export const GuaranteeReport_SendGuaranteeToReliabilityArea = 1801
export const Guarantee_AddReport_GuaranteeToNoteCredit_BySupplier = 1804
export const Guarantee_Accepted_And_Closed = 1806
export const Guarantee_Accept_And_Close = 1817
export const Guarantee_Forward_To_Supplier = 1818

export const WarrantyReportsPtm_SendNotification_To_Supplier = 1815

export const Questionnaire_SendToSuppliers = 1600
export const Questionnaire_ResponseBySupplier = 1601
export const Questionnaire_Evaluated = 1602
export const Questionnaire_Resend_To_Supplier = 1816
export const Questionnaire_Accept_And_Send_To_Supplier = 1819
export const Questionnire_Supplier_Adding_Actions = 1820
export const Questionnire_Resend_Actions_To_Supplier = 1821
export const Questionnaire_Generated_Actions_Plan = 3000

export const Freight_SendFreightToSupplier = 1822
export const Freight_SendFreigthToClient = 1823
export const Freight_ForwardTheSupplierToCorrectDocuments = 1824

export const Maquila_Orders_Send_Supplier = 1825
export const Maquila_Orders_Close = 1826
export const Maquila_Orders_Send_Notification = 1827
export const Maquila_Orders_Valid_Planning = 1828
export const Maquila_Orders_Rejected_Planning = 1829
export const Maquila_Orders_Send_Warehouse = 1830
export const Maquila_Orders_Valid_Supplier = 1831
export const Import_Requests_send_Information = 1832

export const Load_Alert_In_Process = 1834
export const Reject_Load_Alert = 1835
export const Accept_Load_Alert = 1836

export const ExpedientStatus_SendSupplier = 50
export const ExpedientStatus_AnsweredSupplier = 51
export const ExpedientStatus_AcceptedInPlant = 52
export const ExpedientStatus_ClosedInPlant = 53
export const ExpedientStatus_CancelInPlant = 54
export const ExpedientStatus_ResendToSupplier = 55

// Modulo de calidad enumerados 3000
// Modulo de Planeación enumerados 2000

/**
 * Determine if a string is null or empty
 *
 * @export
 * @param {*} string
 */
export function IsSupplierCanBeEdit(status, user) {
	return false
}

/**
 * Action to extending html helpers with available operations
 * @param {*} operation
 * @param {*} internalUser
 */
export function AvailableOperationLink(
	operation,
	internalUser,
	roles,
	isValidToCancel,
	isValidToDelete,
	isSupplierAdmin,
	isMessageCustomDelete,
) {
	let option = null
	switch (operation) {
		/* Generated */
		case Cancel:
			if (internalUser && isValidToCancel) {
				option = { value: Cancel, title: 'cancelFolio', message: 'messageCancel', option: Cancel }
			} else if (internalUser && isValidToCancel) {
				option = { value: Cancel, title: 'cancelFolio', message: 'messageCancel', option: Cancel }
			} else if (internalUser && isValidToCancel) {
				option = { value: Cancel, title: 'cancelFolio', message: 'messageCancel', option: Cancel }
			} else if (internalUser && isValidToCancel) {
				/** Module qulity */
				option = { value: Cancel, title: 'cancelFolio', message: 'messageCancel', option: Cancel }
			} else if (internalUser && isValidToCancel) {
				/** Module guarrantee */
				option = { value: Cancel, title: 'cancelFolio', message: 'messageCancel', option: Cancel }
			} else if (rolesHelper.canBeModuleShippingOrder(roles)) {
				/** Module shippingorder */
				option = { value: Cancel, title: 'cancelFolio', message: 'messageCancel', option: Cancel }
			} else if (internalUser && isValidToCancel) {
				/** Module sustantability questionnaire */
				option = { value: Cancel, title: 'cancelFolio', message: 'messageCancel', option: Cancel }
			}
			break
		case DeleteDraft: {
			if(isMessageCustomDelete){
				option = {
					value: DeleteDraft,
					title: isMessageCustomDelete,
					message: 'messageDeleteDraft',
					option: DeleteDraft,
				}
			}
			else if (internalUser && isValidToDelete) {
				option = {
					value: DeleteDraft,
					title: 'deleteDraft',
					message: 'messageDeleteDraft',
					option: DeleteDraft,
				}
			}
			break
		}
		/* Development */
		case Quote_RequestedToSupplier: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleDevelopmentSupplier(roles) || isSupplierAdmin)) {
				option = {
					value: Quote_RequestedToSupplier,
					title: 'quoteRequestedToSupplier',
					message: 'messageQupteRequestToSupplier',
					option: Quote_RequestedToSupplier,
				}
			}
			break
		}
		case Quote_Declined_BySupplier: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleDevelopmentSupplier(roles) || isSupplierAdmin)) {
				option = {
					value: Quote_Declined_BySupplier,
					title: 'quoteDeclinedBySupplier',
					message: 'messageQuoteDeclinedBySupplier',
					option: Quote_Declined_BySupplier,
				}
			}
			break
		}
		case Quote_AcceptedByEngineer: {
			if (internalUser && rolesHelper.canBeValidateModuleDevelopment(roles)) {
				option = {
					value: Quote_AcceptedByEngineer,
					title: 'quoteAcceptedByEngineer',
					message: 'messageQuoteAcceptedByEngineer',
					option: Quote_AcceptedByEngineer,
				}
			}
			break
		}
		case Quote_RejectedByEngineer: {
			if (internalUser && rolesHelper.canBeValidateModuleDevelopment(roles)) {
				option = {
					value: Quote_RejectedByEngineer,
					title: 'quoteRejectedByEngineer',
					message: 'messageQuoteRejectedByEngineer',
					option: Quote_RejectedByEngineer,
				}
			}
			break
		}
		case Quote_Send_RequoteToSupplier: {
			if (internalUser && rolesHelper.canBeValidateModuleDevelopment(roles)) {
				option = {
					value: Quote_Send_RequoteToSupplier,
					title: 'quoteSendRequoteToSupplier',
					message: 'messagequoteSendRequoteToSupplier',
					option: Quote_Send_RequoteToSupplier,
				}
			}
			break
		}
		case Quote_SendReminderToSupplier: {
			if (internalUser && rolesHelper.canBeValidateModuleDevelopment(roles)) {
				option = {
					value: Quote_SendReminderToSupplier,
					title: 'quoteSendReminderToSupplier',
					message: 'messageQuoteSendReminderToSupplier',
					option: Quote_SendReminderToSupplier,
				}
			}
			break
		}
		case Sample_RequestWithoutRMIInWaitingToSampleArrive: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleQualitySupplier(roles) || isSupplierAdmin)) {
				option = {
					value: Sample_RequestWithoutRMIInWaitingToSampleArrive,
					title: 'sampleRequestWithoutRMIInWaitingToSampleArrive',
					message: 'messagesampleRequestWithoutRMIInWaitingToSampleArrive',
					option: Sample_RequestWithoutRMIInWaitingToSampleArrive,
				}
			}
			break
		}
		case Sample_RequestDeclined: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleQualitySupplier(roles) || isSupplierAdmin)) {
				option = {
					value: Sample_RequestDeclined,
					title: 'sampleRequestDeclined',
					message: 'messagesampleRequestDeclined',
					option: Sample_RequestDeclined,
				}
			}
			break
		}
		case RMI_RespondedBySupplier: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleQualitySupplier(roles) || isSupplierAdmin)) {
				option = {
					value: RMI_RespondedBySupplier,
					title: 'rmiRespondedBySupplier',
					message: 'messageRmiRespondedBySupplier',
					option: RMI_RespondedBySupplier,
				}
			}
			break
		}
		case RMI_SampleArrivedToClientFacilities: {
			if (internalUser && rolesHelper.canBeAddArriveSampleRequest(roles)) {
				option = {
					value: RMI_RespondedBySupplier,
					title: 'rmiSampleArrivedToClientFacilities',
					message: 'messagermiSampleArrivedToClientFacilities',
					option: RMI_SampleArrivedToClientFacilities,
				}
			}
			break
		}
		case RMI_Accepted: {
			if (internalUser && rolesHelper.canBeCloseSampleRequest(roles)) {
				option = {
					value: RMI_Accepted,
					title: 'rmiAccepted',
					message: 'messagermiAccepted',
					option: RMI_Accepted,
				}
			}
			break
		}
		case RMI_Rejected: {
			if (internalUser && rolesHelper.canBeCloseSampleRequest(roles)) {
				option = {
					value: RMI_Rejected,
					title: 'rmiRejected',
					message: 'messagermiRejected',
					option: RMI_Rejected,
				}
			}
			break
		}
		case RMI_ActivateToTheSupplierForModify: {
			if (internalUser && rolesHelper.canBeCloseSampleRequest(roles)) {
				option = {
					value: RMI_ActivateToTheSupplierForModify,
					title: 'rmiActivateToTheSupplierForModify',
					message: 'messagermiActivateToTheSupplierForModify',
					option: RMI_ActivateToTheSupplierForModify,
				}
			}
			break
		}
		case NoticeOfChangeRespondedBySupplier: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleDevelopmentSupplier(roles) || isSupplierAdmin)) {
				option = {
					value: NoticeOfChangeRespondedBySupplier,
					title: 'noticeOfChangeRespondedBySupplier',
					message: 'messageNoticeOfChangeRespondedBySupplier',
					option: NoticeOfChangeRespondedBySupplier,
				}
			}
			break
		}
		case CorrectiveActionDevelopment_NotificationSent: {
			if (internalUser && rolesHelper.canBeValidateModuleDevelopment(roles)) {
				option = {
					value: CorrectiveActionDevelopment_NotificationSent,
					title: 'sacDeceloperNotificationSent',
					message: 'messageSacDeceloperNotificationSent',
					option: CorrectiveActionDevelopment_NotificationSent,
				}
			}
			break
		}
		case CorrectiveActionDevelopment_SendToDevelopmentEngineer: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleDevelopmentSupplier(roles) || isSupplierAdmin)) {
				option = {
					value: CorrectiveActionDevelopment_SendToDevelopmentEngineer,
					title: 'sacActionsSendToDevelopmentEngineer',
					message: 'messagesacActionsSendToDevelopmentEngineer',
					option: CorrectiveActionDevelopment_SendToDevelopmentEngineer,
				}
			}
			break
		}
		case CorrectiveActionDevelopment_ResendCorrectiveAction: {
			if (internalUser && rolesHelper.canBeValidateModuleDevelopment(roles)) {
				option = {
					value: CorrectiveActionDevelopment_ResendCorrectiveAction,
					title: 'sacActionsSendBackToSupplier',
					message: 'messageSacActionsSendBackToSupplier',
					option: CorrectiveActionDevelopment_ResendCorrectiveAction,
				}
			}
			break
		}
		case CorrectiveActionDevelopment_RequireAuthorizationDevelopmentAdministrator: {
			if (internalUser && rolesHelper.canBeValidateModuleDevelopment(roles)) {
				option = {
					value: CorrectiveActionDevelopment_RequireAuthorizationDevelopmentAdministrator,
					title: 'sacActionsRequireAuthorization',
					message: 'messageSacActionsRequireAuthorization',
					option: CorrectiveActionDevelopment_RequireAuthorizationDevelopmentAdministrator,
				}
			}
			break
		}
		case CorrectiveActionDevelopment_IsRequestForResponsible_ForModification: {
			if (internalUser && rolesHelper.canBeCloseSacDevelopment(roles)) {
				option = {
					value: CorrectiveActionDevelopment_IsRequestForResponsible_ForModification,
					title: 'sacActionsRequestResponsibleModification',
					message: 'messageSacActionsRequestResponsibleModification',
					option: CorrectiveActionDevelopment_IsRequestForResponsible_ForModification,
				}
			}
			break
		}
		case CorrectiveActionDevelopment_Close_And_Accepted: {
			if (internalUser && rolesHelper.canBeCloseSacDevelopment(roles)) {
				option = {
					value: CorrectiveActionDevelopment_Close_And_Accepted,
					title: 'sacActionsCloseAndAccept',
					message: 'messageSacActionsCloseAndAccept',
					option: CorrectiveActionDevelopment_Close_And_Accepted,
				}
			}
			break
		}
		/* Quality */
		case RawMaterialNonConformity_NotificationSent: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: RawMaterialNonConformity_NotificationSent,
					title: 'rawMaterialNonConformityNotificationSent',
					message: 'messagerawMaterialNonConformityNotificationSent',
					option: RawMaterialNonConformity_NotificationSent,
				}
			}
			break
		}
		case RawMaterialNonConformity_EditDraft: {
			if (internalUser && rolesHelper.canUserCreateReportModuleQuality(roles)) {
				option = {
					value: RawMaterialNonConformity_EditDraft,
					title: 'rawMaterialNonConformityEditDraft',
					message: 'messageRawMaterialNonConformityEditDraft',
					option: RawMaterialNonConformity_EditDraft,
				}
			}
			break
		}
		case RawMaterial_ReturnToSupplier: {
			if (internalUser && rolesHelper.canUserRetunrDevolutionFromMaterial(roles)) {
				option = {
					value: RawMaterial_ReturnToSupplier,
					title: 'rawMaterialReturnToSupplier',
					message: 'messageRawMaterialReturnToSupplier',
					option: RawMaterial_ReturnToSupplier,
				}
			}
			break
		}
		case RawMaterialNonConformity_DeviationAuthorization: {
			if (internalUser && rolesHelper.canUserCreateGeneratedUpdateForm(roles)) {
				option = {
					value: RawMaterialNonConformity_DeviationAuthorization,
					title: 'rawMaterialNonConforDeviation',
					message: 'messagerawMaterialNonConforDeviation',
					option: RawMaterialNonConformity_DeviationAuthorization,
				}
			}
			break
		}
		case RawMaterialNonConformity_ReworkRequest: {
			if (internalUser && rolesHelper.canUserCreateGeneratedUpdateForm(roles)) {
				option = {
					value: RawMaterialNonConformity_ReworkRequest,
					title: 'rawMaterialNonConformityRework',
					message: 'messageRawMaterialNonConformityRework',
					option: RawMaterialNonConformity_ReworkRequest,
				}
			}
			break
		}
		case RawMaterialNonConformity_DevolutionRequest: {
			if (internalUser && rolesHelper.canUserCreateGeneratedUpdateForm(roles)) {
				option = {
					value: RawMaterialNonConformity_DevolutionRequest,
					title: 'rawMaterialNonConformityDevolutionRequest',
					message: 'messageRawMaterialNonConformityDevolutionRequest',
					option: RawMaterialNonConformity_DevolutionRequest,
				}
			}
			break
		}
		case FailureReport_NotificationSent: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: FailureReport_NotificationSent,
					title: 'failureReportNotificationSent',
					message: 'messagefailureReportNotificationSent',
					option: FailureReport_NotificationSent,
				}
			}
			break
		}
		case FailureReport_ClosedIsNotAttributableToTheSupplier: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: FailureReport_ClosedIsNotAttributableToTheSupplier,
					title: 'closedIsNotAttributableToTheSupplier',
					message: 'messageClosedIsNotAttributableToTheSupplier',
					option: FailureReport_ClosedIsNotAttributableToTheSupplier,
				}
			}
			break
		}
		case FailureReport_MaterialReturnedToSupplier: {
			if (internalUser && rolesHelper.canUserRetunrDevolutionFromMaterial(roles)) {
				option = {
					value: FailureReport_MaterialReturnedToSupplier,
					title: 'rawMaterialReturnToSupplier',
					message: 'messageRawMaterialReturnToSupplier',
					option: FailureReport_MaterialReturnedToSupplier,
				}
			}
			break
		}
		case WaitingForTheCompletionDateOfTheContainmentToClose: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: WaitingForTheCompletionDateOfTheContainmentToClose,
					title: 'waitingForTheCompletionDateOfTheContainmentToClose',
					message: 'messagewaitingForTheCompletionDateOfTheContainmentToClose',
					option: WaitingForTheCompletionDateOfTheContainmentToClose,
				}
			}
			break
		}
		case FailureReport_AddEmergentAction: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: FailureReport_AddEmergentAction,
					title: 'failureReportAddEmergentAction',
					message: 'messagefailureReportAddEmergentAction',
					option: FailureReport_AddEmergentAction,
				}
			}
			break
		}
		case FailureReport_RequestRework: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: FailureReport_RequestRework,
					title: 'failureReportRequestRework',
					message: 'messageFailureReportRequestRework',
					option: FailureReport_RequestRework,
				}
			}
			break
		}
		case FailureReport_DeviationAuthorized: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: FailureReport_DeviationAuthorized,
					title: 'failureReportforDeviation',
					message: 'messageFailureReportDeviation',
					option: FailureReport_DeviationAuthorized,
				}
			}
			break
		}
		case FailureReport_AddCorrectiveActionBysupplier: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleQualitySupplier(roles) || isSupplierAdmin)) {
				/// External users supplier
				option = {
					value: FailureReport_AddCorrectiveActionBysupplier,
					title: 'addCorrectiveActionBysupplier',
					message: 'messageAddCorrectiveActionBysupplier',
					option: FailureReport_AddCorrectiveActionBysupplier,
				}
			}
			break
		}
		case FailureReportQualirtEditDraft: {
			if (internalUser && rolesHelper.canUserCreateReportModuleQuality(roles)) {
				option = {
					value: FailureReportQualirtEditDraft,
					title: 'failureReportQualirtEditDraft',
					message: 'messageFailureReportQualirtEditDraft',
					option: FailureReportQualirtEditDraft,
				}
			}
			break
		}
		case FailureReport_RegisterWarehouseEntry: {
			if (internalUser && rolesHelper.canUserCreateReportModuleQuality(roles)) {
				option = {
					value: FailureReport_RegisterWarehouseEntry,
					title: 'failureRegisterWarehouseEntry',
					message: 'messageFailureRegisterWarehouseEntry',
					option: FailureReport_RegisterWarehouseEntry,
				}
			}
			break
		}
		case Rework_ReworkExecuted: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleQualitySupplier(roles) || isSupplierAdmin)) {
				option = {
					value: Rework_ReworkExecuted,
					title: 'reworkReworkExecuted',
					message: 'messageReworkReworkExecuted',
					option: Rework_ReworkExecuted,
				}
			}
			break
		}
		case Rework_CloseToScrap: {
			if (internalUser && rolesHelper.canUserCreateDevolutionFromRework(roles)) {
				option = {
					value: Rework_CloseToScrap,
					title: 'reworkCloseToScrap',
					message: 'messageReworkCloseToScrap',
					option: Rework_CloseToScrap,
				}
			}
			break
		}
		case Rework_ReworkReturnToSupplier: {
			if (internalUser && rolesHelper.canUserCreateDevolutionFromRework(roles)) {
				option = {
					value: Rework_ReworkReturnToSupplier,
					title: 'reworkReworkReturnToSupplier',
					message: 'messageReworkReworkReturnToSupplier',
					option: Rework_ReworkReturnToSupplier,
				}
			}
			break
		}
		case Devolution_WaitingByTheSupplierPickedUp: {
			if (internalUser && rolesHelper.canUserCreateReportModuleQuality(roles)) {
				option = {
					value: Devolution_WaitingByTheSupplierPickedUp,
					title: 'devolutionWaitingByTheSupplierPickedUp',
					message: 'messageDevolutionWaitingByTheSupplierPickedUp',
					option: Devolution_WaitingByTheSupplierPickedUp,
				}
			}
			break
		}
		case CorrectiveAction_ReponseFromSupplier: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleQualitySupplier(roles) || isSupplierAdmin)) {
				/// External users supplier
				option = {
					value: CorrectiveAction_ReponseFromSupplier,
					title: 'correctiveActionReponseFromSupplier',
					message: 'messageCorrectiveActionReponseFromSupplier',
					option: CorrectiveAction_ReponseFromSupplier,
				}
			}
			break
		}
		case SacQuality_SendNotification: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: SacQuality_SendNotification,
					title: 'sacQualityNotificationSent',
					message: 'messageSacQualityNotificationSent',
					option: SacQuality_SendNotification,
				}
			}
			break
		}
		case CorrectiveAction_RegisterWarehouseEntry: {
			if (internalUser && rolesHelper.canUserCreateReportModuleQuality(roles)) {
				option = {
					value: CorrectiveAction_RegisterWarehouseEntry,
					title: 'correctiveActionRegisterWarehouseEntry',
					message: 'messageCorrectiveActionRegisterWarehouseEntry',
					option: CorrectiveAction_RegisterWarehouseEntry,
				}
			}
			break
		}
		case WarrantyReportsPtm_SendNotification_To_Supplier: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: WarrantyReportsPtm_SendNotification_To_Supplier,
					title: 'rawMaterialNonConformityNotificationSent',
					message: 'rawMaterialNonConformityNotificationSent',
					option: WarrantyReportsPtm_SendNotification_To_Supplier,
				}
			}
			break
		}
		case ShippingOrder_SendShippingOrderToClient: {
			if (!internalUser && (rolesHelper.canBeModuleShippingOrderSupplier(roles) || isSupplierAdmin)) {
				option = {
					value: ShippingOrder_SendShippingOrderToClient,
					title: 'shippingOrderSendShippingOrderToClient',
					message: 'messsgeShippingOrderSendShippingOrderToClient',
					option: ShippingOrder_SendShippingOrderToClient,
				}
			}
			break
		}
		case Import_Requests_send_Information: {
			if (!internalUser && (rolesHelper.canSeeModuleForeignTrade(roles) || isSupplierAdmin)) {
				option = {
					value: Import_Requests_send_Information,
					title: 'importRequestssendInformation',
					message: 'messageImportRequestssendInformation',
					option: Import_Requests_send_Information,
				}
			}
			break
		}
		case ShippingOrder_ForwardTheSupplierToCorrectDocuments: {
			if (internalUser && rolesHelper.canBeModuleShippingOrder(roles)) {
				option = {
					value: ShippingOrder_ForwardTheSupplierToCorrectDocuments,
					title: 'shippingOrderForwardTheSupplierToCorrectDocuments',
					message: 'messageShippingOrderForwardTheSupplierToCorrectDocuments',
					option: ShippingOrder_ForwardTheSupplierToCorrectDocuments,
				}
			}
			break
		}
		case UpdateDraft: {
			if (internalUser && rolesHelper.canUserCreateReportModuleQuality(roles)) {
				option = {
					value: UpdateDraft,
					title: 'failureReportQualirtEditDraft',
					message: 'messageFailureReportQualirtEditDraft',
					option: UpdateDraft,
				}
			}
			break
		}
		case GuaranteeReport_SendGuaranteeToReliabilityArea: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: GuaranteeReport_SendGuaranteeToReliabilityArea,
					title: 'guaranteeReportSendGuaranteeToReliabilityArea',
					message: 'messageGuaranteeReportSendGuaranteeToReliabilityArea',
					option: GuaranteeReport_SendGuaranteeToReliabilityArea,
				}
			}
			break
		}
		case Guarantee_SendGuaranteeToSupplier: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: Guarantee_SendGuaranteeToSupplier,
					title: 'guaranteeSendGuaranteeToSupplier',
					message: 'messageGuaranteeSendGuaranteeToSupplier',
					option: Guarantee_SendGuaranteeToSupplier,
				}
			}
			break
		}
		case Guarantee_AddReport_GuaranteeToNoteCredit_BySupplier: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleQualitySupplier(roles) || isSupplierAdmin)) {
				/// External users supplier
				option = {
					value: Guarantee_AddReport_GuaranteeToNoteCredit_BySupplier,
					title: 'guaranteeToNoteCreditBySupplier',
					message: 'messageGuaranteeToNoteCreditBySupplier',
					option: Guarantee_AddReport_GuaranteeToNoteCredit_BySupplier,
				}
			}
			break
		}
		case Guarantee_Accept_And_Close: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: Guarantee_Accept_And_Close,
					title: 'guaranteeAcceptAndClose',
					message: 'messageGuaranteeAcceptAndClose',
					option: Guarantee_Accept_And_Close,
				}
			}
			break
		}
		case Guarantee_Forward_To_Supplier: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = {
					value: Guarantee_Forward_To_Supplier,
					title: 'guaranteeForwardToSupplier',
					message: 'messageGuaranteeForwardToSupplier',
					option: Guarantee_Forward_To_Supplier,
				}
			}
			break
		}
		case Closed: {
			if (internalUser && rolesHelper.canUserValideModuleQuality(roles)) {
				option = { value: Closed, title: 'close', message: 'messageClose', option: Closed }
			}
			break
		}
		case Questionnaire_ResponseBySupplier: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleQualitySupplier(roles) || isSupplierAdmin)) {
				/// External users supplier
				option = {
					value: Questionnaire_ResponseBySupplier,
					title: 'questionnaireResponseBySupplier',
					message: 'messageQuestionnaireResponseBySupplier',
					option: Questionnaire_ResponseBySupplier,
				}
			}
			break
		}
		case Questionnaire_Resend_To_Supplier: {
			if (internalUser && rolesHelper.canBeModuleSustantabilityQuestionnaire(roles)) {
				option = {
					value: Questionnaire_Resend_To_Supplier,
					title: 'questionnaireResendToSupplier',
					message: 'messageQuestionnaireResendToSupplier',
					option: Questionnaire_Resend_To_Supplier,
				}
			}
			break
		}
		case Questionnaire_Generated_Actions_Plan: {
			if (internalUser && rolesHelper.canBeModuleSustantabilityQuestionnaire(roles)) {
				option = {
					value: Questionnaire_Generated_Actions_Plan,
					title: 'questionnaireGeneratedActionsPlan',
					message: 'messageQuestionnaireGeneratedActionsPlan',
					option: Questionnaire_Generated_Actions_Plan,
				}
			}
			break
		}
		case Questionnaire_Accept_And_Send_To_Supplier: {
			if (internalUser && rolesHelper.canBeModuleSustantabilityQuestionnaire(roles)) {
				option = {
					value: Questionnaire_Accept_And_Send_To_Supplier,
					title: 'questionnaireAcceptAndSendToSupplier',
					message: 'messageQuestionnaireAcceptAndSendToSupplier',
					option: Questionnaire_Accept_And_Send_To_Supplier,
				}
			}
			break
		}
		case Questionnire_Supplier_Adding_Actions: {
			if (!internalUser && (rolesHelper.canBeRespondeModuleQualitySupplier(roles) || isSupplierAdmin)) {
				/// External users supplier
				option = {
					value: Questionnire_Supplier_Adding_Actions,
					title: 'questionnireSupplierAddingActions',
					message: 'messageQuestionnireSupplierAddingActions',
					option: Questionnire_Supplier_Adding_Actions,
				}
			}
			break
		}
		case Questionnire_Resend_Actions_To_Supplier: {
			if (internalUser && rolesHelper.canBeModuleSustantabilityQuestionnaire(roles)) {
				option = {
					value: Questionnire_Resend_Actions_To_Supplier,
					title: 'questionnireResendActionsToSupplier',
					message: 'messageQuestionnireResendActionsToSupplier',
					option: Questionnire_Resend_Actions_To_Supplier,
				}
			}
			break
		}
		case Questionnaire_SendToSuppliers: {
			if (internalUser && rolesHelper.canBeModuleSustantabilityQuestionnaire(roles)) {
				option = {
					value: Questionnaire_SendToSuppliers,
					title: 'questionnaireToSuppliers',
					message: 'messageQuestionnaireToSupplier',
					option: Questionnaire_SendToSuppliers,
				}
			}
			break
		}
		case Questionnaire_Evaluated: {
			if (internalUser && rolesHelper.canBeModuleSustantabilityQuestionnaire(roles)) {
				option = {
					value: Questionnaire_Evaluated,
					title: 'questionnaireEvaluated',
					message: 'messageQuestionnaireEvaluated',
					option: Questionnaire_Evaluated,
				}
			}
			break
		}
		case ExpedientStatus_AnsweredSupplier: {
			if (!internalUser && (rolesHelper.canBeModuleExpedientSupplier(roles) || isSupplierAdmin)) {
				/// External users supplier
				option = {
					value: ExpedientStatus_AnsweredSupplier,
					title: 'expedientStatusAnsweredSupplier',
					message: 'messageExpedientStatusAnsweredSupplier',
					option: ExpedientStatus_AnsweredSupplier,
				}
			}
			break
		}
		case ExpedientStatus_ResendToSupplier: {
			if (internalUser && rolesHelper.canBeModuleExpeditByCompany(roles)) {
				option = {
					value: ExpedientStatus_ResendToSupplier,
					title: 'expedientStatusResendToSupplier',
					message: 'messageExpedientStatusResendToSupplier',
					option: ExpedientStatus_ResendToSupplier,
				}
			}
			break
		}
		case ExpedientStatus_ClosedInPlant: {
			if (internalUser && rolesHelper.canBeModuleExpeditByCompany(roles)) {
				option = {
					value: ExpedientStatus_ClosedInPlant,
					title: 'expedientStatusClosedInPlant',
					message: 'messageExpedientStatusClosedInPlant',
					option: ExpedientStatus_ClosedInPlant,
				}
			}
			break
		}
		case ExpedientStatus_CancelInPlant: {
			if (internalUser && rolesHelper.canBeModuleExpeditByCompany(roles)) {
				option = {
					value: ExpedientStatus_CancelInPlant,
					title: 'cancelFolio',
					message: 'messageCancel',
					option: ExpedientStatus_CancelInPlant,
				}
			}
			break
		}
		case Freight_SendFreightToSupplier: {
			option = {
				value: Freight_SendFreightToSupplier,
				title: 'freightSendFreightToClient',
				message: 'messsgeFreightOrderSendFreightToClient',
				option: Freight_SendFreightToSupplier,
			}
			break
		}
		case Freight_SendFreigthToClient: {
			if (!internalUser && (rolesHelper.canBeModuleShippingOrderSupplier(roles) || isSupplierAdmin)) {
				option = {
					value: Freight_SendFreigthToClient,
					title: 'freightSendFreightToClient',
					message: 'messsgeFreightOrderSendFreightToClient',
					option: Freight_SendFreigthToClient,
				}
			}
			break
		}
		case Freight_ForwardTheSupplierToCorrectDocuments: {
			if (internalUser && rolesHelper.canBeModuleShippingOrder(roles)) {
				option = {
					value: Freight_ForwardTheSupplierToCorrectDocuments,
					title: 'freightForwardTheSupplierToCorrectDocuments',
					message: 'messageFreightForwardTheSupplierToCorrectDocuments',
					option: Freight_ForwardTheSupplierToCorrectDocuments,
				}
			}
			break
		}
		case Maquila_Orders_Valid_Planning: {
			if (internalUser && rolesHelper.IsUserInRole(rolesHelper.ROLE_LOGISTICPLANNER, roles)) {
				option = {
					value: Maquila_Orders_Valid_Planning,
					title: 'maquilaOrdersValidMaquilaOrder',
					message: 'messageMaquilaOrdersValidMaquilaOrder',
					option: Maquila_Orders_Valid_Planning,
				}
			}
			break
		}
		case Maquila_Orders_Rejected_Planning: {
			if (internalUser && rolesHelper.IsUserInRole(rolesHelper.ROLE_LOGISTICPLANNER, roles)) {
				option = {
					value: Maquila_Orders_Rejected_Planning,
					title: 'maquilaOrdersRejectedMaquilaOrder',
					message: 'messageMaquilaOrdersRejectedMaquilaOrder',
					option: Maquila_Orders_Rejected_Planning,
				}
			}
			break
		}
		case Maquila_Orders_Send_Warehouse: {
			if (internalUser && rolesHelper.canUpdateMaquilaOrder(roles)) {
				option = {
					value: Maquila_Orders_Send_Warehouse,
					title: 'maquilaOrdersSendWarehouse',
					message: 'messageMaquilaOrdersSendWarehouse',
					option: Maquila_Orders_Send_Warehouse,
				}
			}
			break
		}
		case Maquila_Orders_Send_Supplier: {
			if (internalUser && rolesHelper.canBeOperationDeliveryWarehouse(roles)) {
				option = {
					value: Maquila_Orders_Send_Supplier,
					title: 'maquilaOrdersSendSupplier',
					message: 'messageMaquilaOrdersSendSupplier',
					option: Maquila_Orders_Send_Supplier,
				}
			}
			break
		}
		case Maquila_Orders_Valid_Supplier: {
			if (!internalUser && (rolesHelper.canBeModulePlaneationSupplier(roles) || isSupplierAdmin)) {
				/// External users supplier
				option = {
					value: Maquila_Orders_Valid_Supplier,
					title: 'maquilaOrdersValidSupplier',
					message: 'messageMaquilaOrdersSendNotification',
					option: Maquila_Orders_Valid_Supplier,
				}
			}
			break
		}
		case Maquila_Orders_Send_Notification: {
			if (!internalUser && (rolesHelper.canBeModulePlaneationSupplier(roles) || isSupplierAdmin)) {
				/// External users supplier
				option = {
					value: Maquila_Orders_Send_Notification,
					title: 'maquilaOrdersSendNotification',
					message: 'messageMaquilaOrdersSendNotification',
					option: Maquila_Orders_Send_Notification,
				}
			}
			break
		}
		case Maquila_Orders_Close: {
			if (
				internalUser &&
				(rolesHelper.canUpdateMaquilaOrder(roles) ||
					rolesHelper.IsUserInRole(rolesHelper.ROLE_MAQUILAORDER_ADMINISTRATION, roles))
			) {
				option = {
					value: Maquila_Orders_Close,
					title: 'maquilaOrdersClose',
					message: 'messageMaquilaOrdersClose',
					option: Maquila_Orders_Close,
				}
			}
			break
		}
		case Load_Alert_In_Process: {
			if (!internalUser)
				option = {
					value: Load_Alert_In_Process,
					title: 'loadAlertInProcess',
					message: 'messageLoadAlertInProcess',
					option: Load_Alert_In_Process,
				}
			break
		}
		case Reject_Load_Alert: {
			if (internalUser || rolesHelper.IsUserInRole(rolesHelper.ROLE_COMPANYADMINISTRATOR, roles)) {
				option = {
					value: Reject_Load_Alert,
					title: 'loadAlertReject',
					message: 'messageLoadAlertReject',
					option: Reject_Load_Alert,
				}
			}
			break
		}
		case Accept_Load_Alert: {
			if (internalUser || rolesHelper.IsUserInRole(rolesHelper.ROLE_COMPANYADMINISTRATOR, roles)) {
				option = {
					value: Accept_Load_Alert,
					title: 'loadAlertAccept',
					message: 'messageLoadAlertAccept',
					option: Accept_Load_Alert,
				}
			}
			break
		}
		default:
			break
	}
	return option
}
