///Import react section
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch, } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import ShowDesktopViewActionPlant from './components/actionPlant/ShowDesktopViewActionPlant'
import ShowEditActionPlant from './ShowEditActionPlant'

/**Import actions section */
import { loadActionPlantSupplierFromBackEnd } from '../../store/indirectqualifications/IndirectqualificationsActions'

const ShowActionPlant = (props) => {
    /**Render hooks section */
    const dispatch = useDispatch()

    /**Redux store information section*/
    const supplier = useSelector((state) => state.indirectQualifications.get('supplier') ? state.indirectQualifications.get('supplier').toJS() : null)
    const isLoading = useSelector((state) => state.indirectQualifications.get('isLoading'))
    const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'))
    const identifier = useSelector((state) => state.indirectQualifications.get('identifier'))

    const translate = useSelector((state) => getTranslate(state.localize))

    /**Store local this component */
    const [identifierPanelLateral, setIdentifierPanelLateral] = useState("")
    const [item, setItem] = useState(null)
    const [confirmPasswordConfigModal, setConfirmPasswordConfigModal] = useState({ opened: false, item: null, identifier: "" })

    const onConfirmPassword = (item, identifier) => {
        setConfirmPasswordConfigModal({ opened: !confirmPasswordConfigModal.opened, item: item, identifier: identifier });
    }

    /**Use effect section */
    useEffect(() => {
        dispatch(loadActionPlantSupplierFromBackEnd(props.match.params.token, props.match.params.supplierId))
    }, [dispatch, props.match.params.token, props.match.params.supplierId])

    const onLateralPanelConfig = (identifier, item) => {
        setIdentifierPanelLateral(identifier);
        setItem(item)
    }

    return (
        <>
            {
                <ShowDesktopViewActionPlant
                    /**objects */
                    translate={translate}
                    supplier={supplier}
                    /**String */
                    identifier={identifier}
                    /**Bools */
                    isLoading={isLoading}
                    isSaving={isSaving}
                    /**Arrays */
                    /**Functions */
                    onLateralPanelConfig={onLateralPanelConfig}
                    onConfirmPassword={onConfirmPassword}
                />


            }

            <ShowEditActionPlant
                identifierPanelLateral={identifierPanelLateral}
                setIdentifierPanelLateral={setIdentifierPanelLateral}
                onLateralPanelConfig={onLateralPanelConfig}
                item={item}
                onConfirmPassword={onConfirmPassword}
                confirmPasswordConfigModal={confirmPasswordConfigModal}
                ratingId={props.match.params.token}
            />
        </>
    )
}

const styles = ((theme) => ({

}))

export default withauthenticatedLayout(withRouter((withStyles(styles, { withTheme: true })(ShowActionPlant))))