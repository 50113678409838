import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";

/** Import imges */
import { Translate } from "react-localize-redux";
import logotypeFemsa from '../../resources/images/FNE_Logotipo.png'

import { Grid, Typography } from '@material-ui/core';
import { withLocalize } from "react-localize-redux";

/**
 * Component to get Footer Container
 */
class FooterContainer extends Component {

    /**
 *Creates an instance of FooterContainer.
 * @param {*} props
 * @memberof FooterContainer
 */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
     * Render footer container
     */
    render() {
        const { checkPrivacyNotice } = this.props;
        const privacyNoticePath= checkPrivacyNotice() ? checkPrivacyNotice() :"";
        return (<div className="footer-container">
            <footer>
                <Grid container spacing={0} justify="space-between">
                    <Grid item xs={12} md={6} lg={4}>
                        <Typography align="left" variant="caption">
                            &copy;  2019 Femsa Negocios Estratégicos &middot; <Translate id="common.allRightsReserved" />
                        </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} lg={4}>
                        <Typography align="center">
                            <small className="text-muted"><a href={privacyNoticePath} target="_blank" rel="noopener noreferrer"><Translate id="common.privacyNotice" /></a> </small>&middot;
                            <small className="text-muted"><a href={privacyNoticePath} target="_blank" rel="noopener noreferrer"><Translate id="common.termsOfUse" /></a> </small>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={3} lg={4}>
                        <Typography align="right">
                            <img src={logotypeFemsa} alt="femsa" />
                        </Typography>
                    </Grid>
                </Grid>
            </footer>
        </div>
        )
    }
}


/**
 * Map state to props
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state) {
    return {
        selectedCompany: state.profile.get("selectedCompany") ? state.profile.get("selectedCompany").toJS() : null,
        activeprivacyNotice: state.companies.get('activePrivacyNotice') ? state.companies.get('activePrivacyNotice').toJS() : null,
    }
}

/**
 * Map dispatch to props
 */
const mapDispatchToProps = dispatch => {
    return {
    }
}
export default withLocalize(withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterContainer)));