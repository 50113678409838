import { fromJS } from 'immutable'

import * as alternativeProveduresActions from './AlternativeProveduceAction'

/**
 * proveduresInitialState initial state
 */
export const proveduresInitialState = fromJS({
	isLoadingProvedures: true,
	alternativeProvedures: null,
	sortCriteria: {
		by: 'creation_date',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	proveduresCount: null,
	provedureDraft: null,
	isLoading: false,
	isSaving: false,
	provedure: null,
	isDownloadingDraft: false,
})

export default function proveduresReducer(state = proveduresInitialState, action) {
	switch (action.type) {
		case alternativeProveduresActions.START_LOADING_PROVEDURES: {
			return state.merge({
				isLoadingProvedures: action.isLoading,
			})
		}
		case alternativeProveduresActions.SET_PROVEDURES_LIST: {
			let proveduresLists = fromJS(action.provedures)
			return state.merge({
				alternativeProvedures: proveduresLists,
				isLoadingProvedures: false,
			})
		}
		case alternativeProveduresActions.CHANGE_PROVEDURES_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case alternativeProveduresActions.CHANGE_PROVEDURES_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case alternativeProveduresActions.CHANGE_PROVEDURES_QUERY: {
			return state.merge({
				searchQuery: action.query,
			})
		}
		case alternativeProveduresActions.SET_PROVEDURES_COUNT: {
			return state.merge({
				proveduresCount: action.count,
			})
		}
		case alternativeProveduresActions.IS_LOADING_PROVEDURE: {
			return state.merge({
				isLoading: action.isLoading,
				provedureDraft: null,
			})
		}
		case alternativeProveduresActions.IS_DOWNLOADING_DRAFT: {
			return state.merge({
				isDownloadingDraft: action.isDownloadingDraft,
			})
		}
		case alternativeProveduresActions.GET_PROVEDURE_IN_DRAFT: {
			return state.merge({
				isLoading: false,
				provedureDraft: fromJS(action.provedureDraft),
			})
		}
		case alternativeProveduresActions.SET_PROVEDURE_ITEM_SELECTED: {
			let items = state
				.get('provedureDraft')
				.get('details')
				.map((provedureItem) => {
					if (provedureItem.get('token') === action.itemToken) {
						return provedureItem.set('is_selected', true)
					} else {
						return provedureItem.set('is_selected', false)
					}
				})
			let provedureDraft = state.get('provedureDraft').set('details', items)
			return state.merge({
				provedureDraft,
			})
		}
		case alternativeProveduresActions.UPDATE_DETAIL_PLANE: {
			let items = state
				.get('provedureDraft')
				.get('details')
				.map((item) => {
					if (item.get('token') === action.detailPlane.token) {
						return item.set('plot_name', action.detailPlane.plot_name)
					} else {
						return item
					}
				})
			let provedureDraft = state.get('provedureDraft').set('details', items)
			return state.merge({
				provedureDraft,
			})
		}
		case alternativeProveduresActions.ADD_ITEM_FROM_PROVEDURE: {
			let item = fromJS(action.item)
			return state.updateIn(['provedureDraft', 'details'], (items) => items.push(item)).set('isSaving', false)
		}
		case alternativeProveduresActions.IS_SAVING_PROVEDURE: {
			return state.merge({
				isSaving: action.isSaving,
			})
		}
		case alternativeProveduresActions.SET_ALTERNATIVE_PROVEDURE: {
			return state.merge({
				isLoading: false,
				provedure: fromJS(action.provedure),
			})
		}
		case alternativeProveduresActions.REMOVE_DETAIL_ITEM_FROM_PROVEDURE: {
			return state.updateIn(['provedureDraft', 'details'], (items) => {
				let index = items.findIndex((item) => {
					return item.get('token') === action.itemToken
				})
				return items.delete(index).set('isSaving', false)
			})
		}
		case alternativeProveduresActions.FINALIZE_PROVEDURE: {
			let provedureUpdate = state.get('provedure')
			provedureUpdate = provedureUpdate.set('is_closed', true)
			return state.merge({
				isSaving: false,
				provedure: provedureUpdate,
			})
		}
		case alternativeProveduresActions.UPDATE_COMMENTS_ALTERNATIVE_PROVEDURE: {
			let items = state
				.get('provedureDraft')
				.get('details')
				.map((item) => {
					if (item.get('token') === action.partNumberDetail.token) {
						return item
							.set('comments', action.partNumberDetail.comments)
							.set('requiered_plot_art', action.partNumberDetail.requiered_plot_art)
					} else {
						return item
					}
				})
			let provedureDraft = state.get('provedureDraft').set('details', items)
			return state.merge({
				provedureDraft,
			})
		}
		case alternativeProveduresActions.ADD_FILE_ALTERNATIVE_PROVEDURE: {
			let detailsList = state.getIn(['provedureDraft', 'details']).toJS()
			let indexDetail = detailsList.findIndex((detail) => {
				return detail.token === action.tokenPartNumber
			})
			let oldList = state.getIn(['provedureDraft', 'details', indexDetail, 'attachments']).toJS()
			let filesToAdd = action.files.filter((newFile) => {
				return !oldList.find((uploadedFile) => {
					return uploadedFile.name === newFile.name
				})
			})
			let newList = [...oldList, ...filesToAdd]
			return state.setIn(['provedureDraft', 'details', indexDetail, 'attachments'], fromJS(newList))
		}
		case alternativeProveduresActions.REMOVE_FILE_ALTERNATIVE_PROVEDURE: {
			let detailsList = state.getIn(['provedureDraft', 'details']).toJS()
			let indexDetail = detailsList.findIndex((detail) => {
				return detail.token === action.tokenPartNumber
			})
			let oldList = state.getIn(['provedureDraft', 'details', indexDetail, 'attachments']).toJS()
			let newList = oldList.filter((file) => {
				return file.name !== action.fileName
			})
			return state.setIn(['provedureDraft', 'details', indexDetail, 'attachments'], fromJS(newList))
		}
		case alternativeProveduresActions.SET_REACTIVE_QUOTE_IN_ALTERNATIVE: {
			return state.updateIn(['provedure', 'suppliers'], (allSuppliers) => {
				let index = allSuppliers.findIndex((supplier) => {
					return supplier.get('supplier_token') === action.quote.supplier_token
				})
				let quoteToChange = allSuppliers.get(index)
				let updatedQuoteList = quoteToChange.get('quotes').map((item) => {
					if (item.get('token') === action.quote.token) {
						return item
							.set('status', action.quote.actual_operation_description)
							.set('actual_operation', action.quote.actual_operation)
					} else {
						return item
					}
				})
				let updatedDetail = quoteToChange.set('quotes', updatedQuoteList)
				return allSuppliers.setIn([index], updatedDetail)
			})
		}
		default:
			return state
	}
}
