import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
/**Import material UI Section*/
import {
    withStyles, Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

/**Import components section*/
import PopperListsComponent from '../PopperListsComponent'
import DataTable from '../../../common/DataTable';
import { canBeEditBiddingInStatusDraft, descriptionCriteriaType, isCategoryRawMaterials } from '../../../../store/helpers/BiddingHelper'
import { percengeFormat } from '../../../../store/helpers/AmountHelper'

/**Import resources section*/
import { IsCurrentActiveDirectoryUser } from '../../../../store/helpers/SessionHelper';
import { ScopeType, CriteriaType } from '../../../../store/helpers/AppConstants';

const CriteriaComponent = (props) => {
    /**String props section */
    // /**Bools props section */
    const { isSaving } = props
    // /**Arrays props section */
    const { userProfileLogged, userRolesByCompany, group } = props
    // /**Functions props section */
    const { onEditEvaluationCriteriaConfigPanel, onDeletedEvaluationFromBackEnd
    } = props

    /**Objects props section */
    const { bidding, } = props
    // const serviceCriteria = getCriteriaService(bidding);

    let canBeEditedInformation = canBeEditBiddingInStatusDraft(bidding, userRolesByCompany, userProfileLogged ? userProfileLogged.profile : null)
    let isUserActiveDirectory = IsCurrentActiveDirectoryUser(userProfileLogged);

    /**
     * onRowClick
     * @param {*} bidding 
     * @param {*} event 
     */
    const onRowClick = (bidding, event) => {
        let tagName = event.target.tagName
        if (tagName !== "svg" && tagName !== "A" && props.onEditEvaluationCriteriaConfigPanel) {
            onEditEvaluationCriteriaConfigPanel(bidding)
        }
    }


    /**
     * On render cell item custom
     * @param {*} dataSource
     * @param {*} item
     */
    const onRenderCellItem = (dataSource, item) => {
        let isCriteriaMoney = item.Scope ===ScopeType.ITEM && item.CriteriaType ===CriteriaType.MONEY && isCategoryRawMaterials(bidding)
        if (dataSource === "Scope") {
            return item.Scope ===ScopeType.GENERAL ? "Global": "Por item"
        }
        if (dataSource === "CriteriaType") {
            return descriptionCriteriaType(item.CriteriaType)
        }
        if (dataSource === "Weighing") {
            return percengeFormat(item.Weighing)
        }
        if (dataSource === "Name") {
            return <Typography style={{
                maxWidth: "200px",
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }}>{item.Name}</Typography>
        }
        if (dataSource === "IsAutomaticallyEvaluated") {
            return item.IsAutomaticallyEvaluated ? <Translate id="biddingsModule.services.automatic" /> : <Translate id="biddingsModule.services.manual" />
        }
        if (dataSource === "Options" && canBeEditedInformation && isUserActiveDirectory) {
            let buttonsForMoreCard = [
                {
                    title: <Translate id="common.delete" />,
                    icon: <DeleteIcon />,
                    onClick: (event) => { onDeletedEvaluationFromBackEnd(event, item.Id) },
                    isIconMaterial: true,
                    disabled: (isSaving || isCriteriaMoney|| (!canBeEditedInformation) )
                },

            ]
            return <PopperListsComponent
                title={<Translate id="common.options" />}
                listItems={buttonsForMoreCard}
            />
        }
    }

    const renderContainer = () => {
        return <DataTable
            data={group.Items}
            configuration={EvaluationCriteriaTableConfiguration(isUserActiveDirectory)}
            onRenderCellItem={onRenderCellItem}
            onRowClick={onRowClick}
        />
    }

    /**
     * Render componet 
     */
    return (renderContainer())
}

const EvaluationCriteriaTableConfiguration = (isUserActiveDirectory) => {
    let columns = [{
        header: <Translate id="biddingsModule.table.name" />,
        dataSource: 'Name',
    },{
        header:  <Translate id="biddingsModule.types"/>,
        dataSource: 'Scope',
    }, {
        header: <Translate id="biddingsModule.services.table.weighing" />,
        dataSource: 'Weighing'
    }]

    if (isUserActiveDirectory) {
        columns.push({
            header: <Translate id="biddingsModule.services.table.criteriaType" />,
            dataSource: 'CriteriaType',
        });
        columns.push({
            header: <Translate id="biddingsModule.services.table.evaluationType" />,
            dataSource: 'IsAutomaticallyEvaluated',
        }, {
            header: "",
            dataSource: 'Options'
        });
    }
    return ({ columns });
}

const styles = (theme) => ({
    tab: {
        minHeight: "auto",
    },
    textTab: {
        fontSize: "0.75rem !important"

    },
    buttonsRightContainer: {
        display: "flex",
        alignItems: 'center'
    },
    tabs: {
        display: "flex",
        justifyContent: 'space-between',
    },
    sectionsContainer: {
        marginTop: "20px",
        maxHeight: `calc(100vh - ${305}px)`,
        minHeight: `calc(100vh - ${305}px)`,
        overflowY: "auto",
        paddingBottom: "15px"
    },
    flexGrow: {
        flexGrow: 1
    },
    accordionSummary: {
        paddingRight: "0px !important",
        display: "flex",
        backgroundColor: "#f8f9fa"

    },
    accordionDetails: {
        backgroundColor: `${theme.palette.common.white} !important`
    },
    buttonsAcordionSummary: {
        display: "flex",
        justifyItems: "flex-end",
        marginRight: "20px"
    }

});

CriteriaComponent.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
}

CriteriaComponent.defaultProps = {
    isLoading: false,
}

export default withRouter(
    withStyles(styles, { withTheme: true })(CriteriaComponent)
);
