import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { Translate } from "react-localize-redux";
import PropTypes from 'prop-types'

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import { CardHeader } from '@material-ui/core';
import RejectedIcon from '@material-ui/icons/ThumbDown';

/**
 * Component ot render WorkflowCancel
 */
export default class WorkflowCancel extends Component{

    /**
     * Create an instance WorkflowCancel
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
    }


    /**
     * Render hystoryCard for renderCancel
     *
     * @param {*} folio
     * @param {*} status
     * @returns
     * @memberof Show
     */
    renderCancel(document) {
        return (
            <Grid container spacing={24} >
                <CardHeader
                    style={{ paddingTop: '0px' }}
                    title={
                        <Typography gutterBottom variant="body2" component="p">
                            <strong>
                            <RejectedIcon color='error' /> <Translate id="common.ReasonForCancellation" />:
                            </strong>
                            {' ' + document.reason_for_cancellation}
                        </Typography>
                    }
                    subheader={
                        <React.Fragment>
                            <Typography gutterBottom component="p">
                               {document.cancellation_comment}
                            </Typography>
                        </React.Fragment>
                    }
                    
                />
            </Grid>
        )
    }

    render(){
        return(this.renderCancel(this.props.document))
    }
}

/**
 * WorkflowCancel
 */
WorkflowCancel.propTypes = {
    document: PropTypes.object.isRequired
}