import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from 'react-localize-redux';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import SustainabilityIcon from '@material-ui/icons/NaturePeople';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Toaster from '../common/Toaster';
import InformationCard from './components/InformationCard';

import { showSuccessToaster, showErrorToaster, showInfoToaster } from '../../store/helpers/ToasterHelpers';
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';
import {createSustainabilityQuestionnaire} from '../../store/sustainability/SustainabilityActions';

class Create extends Component {

    constructor(props) {
      super(props);
      autoBind(this);
      this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            sustainability: {
                suppliers: [],
                comments: '',
            }
        }
    };
    

    /**
     * Function to update comment
     */
    updatePropertyComment(property, value) {
        let sustainabilityUpdate = { ...this.state.sustainability };
        sustainabilityUpdate[property] = value;
        this.setState({
            sustainability: sustainabilityUpdate
        })
    }

    updateSuppliersList(value) {
        let sustainabilityUpdate = { ...this.state.sustainability };
        if (value !== null && value !== undefined) {
            if (!sustainabilityUpdate['suppliers'].find(supplier => { return supplier.token === value.token })) {
                    let supplier = { name: value.full_name, token: value.token, SupplierName: value.name, SupplierToken: value.token, SupplierCode: value.code };
                    sustainabilityUpdate['suppliers'] = sustainabilityUpdate['suppliers'].concat(supplier);
                    this.setState({
                        sustainability: sustainabilityUpdate
                    })
            } else {
                this.setState(showInfoToaster("common.repeatedSelection"));
            }
        }
    }

    removeSupplierFromList(supplierToken) {
        let sustainabilityUpdate = { ...this.state.sustainability };
        let newSuppliersList = sustainabilityUpdate['suppliers'].filter(supplier => {
            return supplier.token !== supplierToken
        })
        sustainabilityUpdate['suppliers'] = newSuppliersList;
        this.setState({
            sustainability: sustainabilityUpdate
        })
    }

      /**
     * Can be add item 
     */
    canBeSend(){
        let sustainability = this.state.sustainability;
        if (sustainability.suppliers.length <= 0)
            return false;
        if(sustainability.comments === "")
            return false;
        else
            return true;
    }

    /**
     * Function to send Questionnair
     */
    sendSustainabilityQuestionnairToSuppliers() {
        this.props.createSustainabilityQuestionnaire(this.state.sustainability)
            .then(() => {
                this.setState(showSuccessToaster("common.addCorrectly"));
                this.props.history.push(`/sustainability`);
            }).catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : "common.errorToAdd"));
            });
    }

    render(){
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <SustainabilityIcon />
                    </Avatar>
                }
                action={
                    <span>
                        <Tooltip title={<Translate id="sustainability.sendQuestionnaire"/>}>
                            <IconButton disabled={!this.canBeSend() || this.props.isSavingQuestionnaire}
                             color="primary"value onClick={() => {this.sendSustainabilityQuestionnairToSuppliers()}} >
                                <SendIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={<Translate id="common.cancel"/>}>
                        <IconButton  color="primary"value onClick={() => {this.props.history.push(`/sustainability`);}} >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                  </span>
                  }
                title={<Typography variant="button">{<Translate id="sustainability.sustainabilityQuestionnaire"/>} </Typography>}
                subheader={<Translate id="sustainability.messageToCreate"/>}
            />
            <CardContent>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <InformationCard 
                            updatePropertyComment={this.updatePropertyComment}
                            sustainability={this.state.sustainability}
                            onSupplierSelected={this.onSupplierSelected}
                            updateSuppliersList={this.updateSuppliersList}
                            suppliers={this.state.sustainability.suppliers}
                            removeSupplier={this.removeSupplierFromList}
                            {...this.props}
                        />
                    </Grid>
                </Grid>
            </CardContent>

            <Toaster
                message={<Translate id={this.state.toasterMessage} />}
                open={this.state.showToaster}
                variant={this.state.toasterVariant}
                onClose={() => { this.setState({ showToaster: false }) }}
            />

        </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        isSavingQuestionnaire: state.sustainability.get("isSavingQuestionnaire"),
        questionnaire: state.sustainability.get('questionnaire') ? state.sustainability.get('questionnaire').toJS() : null,
        translate: getTranslate(state.localize)
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        createSustainabilityQuestionnaire:(sustainability) => {
            return dispatch(createSustainabilityQuestionnaire(sustainability))
        },
    }
}

const styles = theme => ({
    
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(Create))));