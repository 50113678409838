import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { withStyles } from "@material-ui/core/styles";
import { Translate } from 'react-localize-redux';

/** Material UI imports section */
import AddIcon from '@material-ui/icons/AddTwoTone';
import AlertIcon from '@material-ui/icons/Error';
import {Fab,CardHeader,Typography,
    Grid, Tooltip, CardContent, Card} from "@material-ui/core";

/** Component imports section */

import QualityCertificate from './QualityCertificate';

/** Actions and helpers imports section */

class CertificatesTable extends Component {

    /**
     *Creates an instance of MaterialsTable.
     * @param {*} props
     * @memberof MaterialsTable
     */
    constructor(props) {
        super(props);
        autoBind(this);
    };

    render() {
        return (
            <Card>
                <CardHeader
                    title={this.props.title}
                    action={
                        this.props.canBeEditSupplier && <React.Fragment>
                            <Tooltip title={<Translate id="deliveryOrderModule.addCertificate" />}>
                                <Fab size="small" color="primary"
                                    style={{ marginLeft: '1em' }}
                                    onClick={() => { this.props.onAddClick("openAddCertificateModal", null) }}>
                                    <AddIcon />
                                </Fab>
                            </Tooltip>
                        </React.Fragment>
                    }
                />
                <CardContent>
                    <Grid container spacing={0} style={{ marginBottom: '3em' }}>
                        <Grid item xs={12}>
                            {this.props.deliveryOrder.certificates.map(certificate => {
                                return (
                                    <QualityCertificate
                                        key={certificate.token}
                                        certificate={certificate}
                                        onRemoveQualityCertificate={this.props.onRemoveQualityCertificate}
                                        onAddItem={this.props.onAddItemInCertificateInDeliveryOrder}
                                        onRemoveItem={this.props.onRemoveItemInCertificateInDeliveryOrder}
                                        onUpdateItem={this.props.onUpdateItemInCertificateInDeliveryOrder}
                                        canBeEditSupplier={this.props.canBeEditSupplier}
                                        canbeDeleteCetificate={true}
                                    />
                                    )
                                })
                            }

                            { this.props.deliveryOrder.certificates.length === 0 && 
                                <Typography variant="body2" align="center">
                                        <AlertIcon color="primary" />
                                    <strong><Translate id={"deliveryOrderModule.notExistCetificate"} /></strong>
                                </Typography>
                            }
                        </Grid>
                        
                    </Grid>
                </CardContent>
            </Card >
        );
    }
}


const styles = theme => ({
    customBadge: {
        color: "white",
        top: '50%',
        right: '-10%',
    }
});

export default withStyles(styles)(CertificatesTable);