import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER, COLOR_STATUS_WARNING, COLOR_STATUS_INFO, COLOR_STATUS_LIGHT } from './../../store/helpers/StatusColorConstants';

/**
 * Class to render tracking component
 *
 * @class TrackingStatus
 * @extends {Component}
 */
class TrackingStatus extends Component {

    /**
     * Determine constant color to use in background
     *
     * @param {*} color
     * @returns
     * @memberof TrackingStatus
     */
    determineColor(color) {
        switch (color.toLowerCase()) {
            case 'success':
                return COLOR_STATUS_SUCCESS
            case 'danger':
                return COLOR_STATUS_DANGER
            case 'warning':
                return COLOR_STATUS_WARNING
            case 'info':
                return COLOR_STATUS_INFO
            default:
                return COLOR_STATUS_LIGHT
        }
    }

    render() {
        return (
            <Grid container justify="center" alignItems="center" spacing={12}>
                {this.props.tracking && this.props.tracking.map(status => {
                    return (
                        <Grid item xs={2}
                            alignItems='center'
                            style={{
                                backgroundColor: this.determineColor(status.color),
                                color: '#fff',
                                width: '35px',
                                height: '25px',
                                textAlign: "center"
                            }}
                        >
                            {status.number}
                        </Grid>

                    )
                })
                }
                {!this.props.tracking && "Sin información de seguimiento"}
            </Grid>
        );
    }
}

export default TrackingStatus;