import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SearchAllIcon from '@material-ui/icons/ListAlt';

/** Language imports **/
import { withLocalize, getTranslate, Translate } from "react-localize-redux";
import SearchFilters from './SearchFilters';

/**
 * Search input
 *
 * @class SearchInput
 * @extends {Component}
 */
class SearchInputOptions extends Component {


    /**
     *Creates an instance of SearchInput.
     * @param {*} props
     * @memberof SearchInput
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            searchQuery: this.props.value ? this.props.value : ""
        }
    }

    /**
     * On key press
     *
     * @param {*} key
     * @memberof SearchInput
     */
    onKeyPress(event) {
        if (event.key === "Enter") {
            this.props.onChangeSearchValue(this.state.searchQuery)
        }
    }

    /**
     *
     *
     * @param {*} event
     * @memberof SearchInput
     */
    onChange(event) {
        this.setState({
            searchQuery: event.target.value
        })
    }

    /**
     * On search all reports with all status
     */
    onSearchReports() {
        if (this.props.onSearchAllReports) {
            this.props.onSearchAllReports();
        }
    }

    /**
     *
     *
     * @returns
     * @memberof SearchInput
     */
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        className={classes.input}
                        placeholder={this.props.translate("common.search")}
                        onKeyPress={this.onKeyPress}
                        onChange={this.onChange}
                        value={this.state.searchQuery}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                    />

                    {this.props.isSearchAllReports && <Tooltip title={<Translate id="rawmaterialnonconformity.getallReportsMaterial" />}>
                        <IconButton className={classes.iconButton} aria-label="search"
                            onClick={this.onSearchReports}>
                            <SearchAllIcon />
                        </IconButton>
                    </Tooltip>}

                    <Divider className={classes.divider} orientation="vertical" />
                    {this.props.isSearchByFilters &&
                        <SearchFilters
                            options={this.props.options}
                            onSearch={this.props.onSearchByFilters}
                        />
                    }

                </Paper>

            </div>
        );
    }
}

const styles = theme => ({
    search: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 300,
        backgroundColor: fade(theme.palette.primary.main, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.25),
        },
        height: "38px"
    },
    input: {
        marginLeft: theme.spacing.unit * 1,
        flex: 1,
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    searchIcon: {
        width: theme.spacing.unit * 4,
        color: theme.palette.text.hint,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 4,
        transition: theme.transitions.create('width'),
        width: '100%',
        fontSize: "14px",
        [theme.breakpoints.up('sm')]: {
            width: 100,
            '&:focus': {
                width: 100,
            },
        },
    },
    iconButton: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        transition: theme.transitions.create('width'),
    },
    divider: {
        height: 28,
        margin: 4,
    },
});

const mapStateToProps = state => ({ translate: getTranslate(state.localize) });
export default withLocalize(withStyles(styles)(connect(mapStateToProps)(SearchInputOptions)))