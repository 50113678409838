import React, { useState, useEffect, useRef, useCallback } from 'react'
import propTypes from 'prop-types'
/** Material UI import section */
import { Typography, IconButton, Button, Checkbox, withStyles } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
// import { DndProvider,useDrag, useDrop } from 'react-dnd'
// import update from 'immutability-helper'
// import { HTML5Backend } from 'react-dnd-html5-backend'

const styles = (theme) => ({
	root: {
		display: 'grid',
		gridTemplateRows: 'auto 40px',
		zIndex: 500,
	},
	columItemList: {
		gridRow: '1 / 2',
		overflowY: 'auto',
		maxHeight: '300px',
	},
	acceptButton: {
		//marginLeft: 'auto',
		//marginRight: 'auto',
		backgroundColor: theme.palette.primary.main,
		marginTop: '10px',
		gridRow: '2 / 3',
	},
	columnItem: {
		border: '1px solid #f0f0f0',
		padding: '5px 10px',
		marginBottom: '5px',
		display: 'flex',
		alignItems: 'center',
	},
	moveButtonContainer: {
		marginRight: '10px',
	},
	visibilityContainer: {
		marginLeft: 'auto',
	},
})

/**
 * This component contains the datagrid BaseConfigurator
 */
const BaseConfigurator = (props) => {
	const { configuration, classes } = props
	const [localConfiguration, setLocalConfiguration] = useState([])
	// const classes = useStyles({ maxHeight: props.maxHeight })

	useEffect(() => {
		const config = configuration
			.map((columnConfig) => {
				return { ...columnConfig }
			})
		setLocalConfiguration(config)
	}, [configuration])

	/**
	 * On change visible value for a column configuration
	 */
	const onChangeVisible = (header, value) => {
		let config = localConfiguration.map((columnConfig) => {
			if (columnConfig.header === header) return { ...columnConfig, visible: value }
			else return { ...columnConfig }
		})
		setLocalConfiguration(config)
	}

	/**
	 * Move an item from a location to another
	 * @param {*} from
	 * @param {*} to
	 */
	const onMoveItem = (from, to) => {
		let itemFrom = localConfiguration[from]
		let itemTo = localConfiguration[to]
		if (itemTo.isMovable === false) {
			console.warn(`Cannot move row to over ${itemTo.header}`)
			return
		}
		localConfiguration[from] = { ...itemTo }
		localConfiguration[to] = { ...itemFrom }
		setLocalConfiguration([...localConfiguration])
	}

	const moveCard = useCallback(
		(dragIndex, hoverIndex) => {
			// const dragCard = localConfiguration[dragIndex]
			//   setLocalConfiguration(
			// 	update(localConfiguration, {
			// 	  $splice: [
			// 		[dragIndex, 1],
			// 		[hoverIndex, 0, dragCard],
			// 	  ],
			// 	}),
			//   )
		},
		[localConfiguration]
	)

	/**
	 * Render configuration
	 */
	return (
		// <DndProvider backend={HTML5Backend}>
		<div className={classes.root}>
			<div className={classes.columItemList}>
				{localConfiguration.map((columnConfig, index) => {
					return (
						<ColumnItem
							{...columnConfig}
							classes={classes}
							index={index}
							onMoveCard={moveCard}
							count={localConfiguration.length}
							key={columnConfig.dataSource}
							onChangeVisible={onChangeVisible}
							onMoveItem={onMoveItem}
						/>
					)
				})}
			</div>
			<div className={classes.acceptButton}>
				<Button
					size='small'
					fullWidth
					color={'secondary'}
					variant='contained'
					onClick={() => props.onConfigurationChange(localConfiguration)}
				>
					{props.saveButtonText}
				</Button>
			</div>
		</div>
		// </DndProvider>
	)
}
BaseConfigurator.propTypes = {
	/**
	 * The table configuration with visible and index properties
	 */
	configuration: propTypes.arrayOf(
		propTypes.shape({
			header: propTypes.oneOfType([propTypes.node, propTypes.string]).isRequired,
			visible: propTypes.bool.isRequired,
		})
	).isRequired,
	/**
	 * Callback executed when the user save the configuration
	 */
	onConfigurationChange: propTypes.func.isRequired,
	/**
	 * The max height of the columns configuration container
	 */
	maxHeight: propTypes.string,
	/**
	 * The text in the save button
	 */
	saveButtonText: propTypes.string.isRequired,
}
BaseConfigurator.defaultProps = {
	configuration: [],
	onConfigurationChange: () => console.warn('No [onConfigurationChange] callback defined'),
	saveButtonText: 'Guardar configuración',
}
export default withStyles(styles, { withTheme: true })(BaseConfigurator)

/**
 * A header item
 */
const ColumnItem = (props) => {
	const { index, isMovable, count, header, visible, classes } = props
	// const { onMoveItem, onChangeVisible, onMoveCard } = props
	const { onMoveItem, onChangeVisible } = props
	// const classes = useStyles()
	/**
	 *  drag and drop implementation
	 */
	const ref = useRef(null)
	// const [, drop] = useDrop({
	// 	accept: 'header',
	// 	hover(item, monitor) {
	// 	  if (!ref.current) {
	// 		return
	// 	  }
	// 	  const dragIndex = item.index
	// 	  const hoverIndex = index

	// 	  // Don't replace items with themselves
	// 	  if (dragIndex === hoverIndex) {
	// 		return
	// 	  }
	// 	  // Determine rectangle on screen
	// 	  const hoverBoundingRect = ref.current?.getBoundingClientRect()
	// 	  // Get vertical middle
	// 	  const hoverMiddleY =
	// 		(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
	// 	  // Determine mouse position
	// 	  const clientOffset = monitor.getClientOffset()
	// 	  // Get pixels to the top
	// 	  const hoverClientY = clientOffset.y - hoverBoundingRect.top
	// 	  // Only perform the move when the mouse has crossed half of the items height
	// 	  // When dragging downwards, only move when the cursor is below 50%
	// 	  // When dragging upwards, only move when the cursor is above 50%
	// 	  // Dragging downwards
	// 	  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
	// 		return
	// 	  }
	// 	  // Dragging upwards
	// 	  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
	// 		return
	// 	  }
	// 	  // Time to actually perform the action
	// 	  onMoveCard(dragIndex, hoverIndex)
	// 	  // Note: we're mutating the monitor item here!
	// 	  // Generally it's better to avoid mutations,
	// 	  // but it's good here for the sake of performance
	// 	  // to avoid expensive index searches.
	// 	  item.index = hoverIndex
	// 	},
	//   })
	//   const [{ isDragging }, drag] = useDrag({
	// 	item: { type: 'header', id:index, index },
	// 	collect: (monitor) => ({
	// 	  isDragging: monitor.isDragging(),
	// 	}),
	//   })
	//   const opacity = isDragging ? 0 : 1
	//   drag(drop(ref))

	/**
	 * Return header item
	 */

	return (
		// <div className={classes.columnItem} ref={isMovable === true?ref:null} style={{...opacity}}>
		<div className={classes.columnItem} ref={isMovable === true ? ref : null}>
			<div className={classes.moveButtonContainer}>
				<IconButton
					disabled={index === 0 || isMovable !== true}
					size='small'
					onClick={() => onMoveItem(index, index - 1)}
				>
					<ArrowDropUpIcon fontSize='inherit' />
				</IconButton>
				<IconButton
					disabled={index === count - 1 || isMovable !== true}
					size='small'
					onClick={() => onMoveItem(index, index + 1)}
				>
					<ArrowDropDownIcon fontSize='inherit' />
				</IconButton>
			</div>
			<Typography variant='caption'>{header || ' - '}</Typography>
			<div className={classes.visibilityContainer}>
				<Checkbox
					size='small'
					color='secondary'
					disabled={isMovable !== true}
					checked={visible === true}
					onChange={(event) => onChangeVisible(header, event.target.checked)}
				/>
			</div>
		</div>
	)
}

ColumnItem.propTypes = {
	header: propTypes.string.isRequired,
	visible: propTypes.bool.isRequired,
	index: propTypes.number.isRequired,
	count: propTypes.number.isRequired,
	onChangeVisible: propTypes.func,
	isMovable: propTypes.bool,
	onMoveItem: propTypes.func,
}

ColumnItem.defaultProps = {
	header: '',
	visible: true,
	isMovable: true,

	index: 0,
}
