import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import {Typography, Toolbar, TextField, Grid} from "@material-ui/core";

/** Import component section */
import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';

/**
 * Class compoenent to add a AddCertificateModal
 *
 * @class AddCertificateModal
 * @extends {Component}
 */
class AddCertificateModal extends Component {

    /**
     *Creates an instance of DialogActions.
     * @param {*} props
     * @memberof DialogActions
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = this.initialState();
    }

    /**
     * Return initial state
     *
     * @returns
     * @memberof AddCertificateModal
     */
    initialState(keepAdding = false, isClose = false) {
        return ({
            part_number_code: this.props.certificate ? (isClose ? null : this.props.certificate.part_number_code) : null,
            purchase_order: this.props.certificate ? (isClose ? null : this.props.certificate.purchase_order) : null,
            transport_line: this.props.certificate ? (isClose ? null : this.props.certificate.transport_line) : null,
            load_conditions: this.props.certificate ? (isClose ? null : this.props.certificate.load_conditions) : null,
            name_who_certifies: this.props.certificate ? (isClose ? null : this.props.certificate.name_who_certifies) : null,
            name_vobo_transport: this.props.certificate ? (isClose ? null : this.props.certificate.name_vobo_transport) : null,
            number_register_quality: this.props.certificate ? (isClose ? null : this.props.certificate.number_register_quality) : null,
            keepAdding
        })
    }


    /**
     * Handle state checked option
     *
     * @memberof AddCertificateModal
     */
    handleCheck() {
        this.setState({
            keepAdding: !this.state.keepAdding
        })
    }

    /**
     * Function to trigger blur event
     *
     * @param {*} event
     * @memberof AddCertificateModal
     */
    onKeyDown(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    updateProperty(property, data) {
        this.setState({
            [property]: data,
        });
    }


    /**
     * method to call action and add item
     *
     * @memberof AddCertificateModal
     */
    onAddItem() {
        this.props.addICertificate(this.state)
            .then((response) => {
                if (response === 'Ok' && !this.state.keepAdding) {
                    this.handleCloseCancel()
                }
                else {
                    this.setState(this.initialState(true));
                }
            })
    }

    /**
     * Handle  Close or cancel event
     *
     * @memberof AddCertificateModal
     */
    handleCloseCancel() {
        this.setState(this.initialState(false, true),
            () => this.props.onClose());
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof AddCertificateModal
     */
    isValidToSave() {
        if (!IsNullOrEmpty(this.state.part_number_code)
            && !IsNullOrEmpty(this.state.purchase_order)
            && !IsNullOrEmpty(this.state.transport_line)
            && !IsNullOrEmpty(this.state.name_who_certifies)
            && !IsNullOrEmpty(this.state.name_vobo_transport)
        )
            return true;
        return false
    }

    /**
     * Method to render text field 
     *
     * @param {*} textFieldId
     * @param {*} textFieldTranslateId
     * @param {boolean} [isRequired=true]
     * @returns
     * @memberof AddCertificateModal
     */
    renderTextField(textFieldId, textFieldTranslateId, isRequired = true, type = "text", showHelper = false) {
        return (
            <TextField
                id={textFieldId}
                label={<Translate id={textFieldTranslateId} />}
                value={this.state[textFieldId] ? this.state[textFieldId] : ''}
                margin="dense"
                type={type}
                style={{width:"250px"}} 
                required={isRequired}
                onBlur={(event) => this.updateProperty(textFieldId, event.target.value)}
                onChange={(event) => this.updateProperty(textFieldId, event.target.value)}
                onKeyDown={this.onKeyDown}
            />
        )
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id={this.props.title} />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <Grid container spacing={0}>
                        {!this.isValidToSave() &&
                            <Grid item xs={12} className={this.props.classes.checkBox}>
                                <Typography variant="caption" color='error'>
                                    {<Translate id="common.requiredFields" />}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <DialogActions
                                view={SHOW_EDIT_VIEW}
                                classes={classes}
                                onCancel={() => this.handleCloseCancel()}
                                onClose={() => this.handleCloseCancel()}
                                onSaveItem={this.onAddItem}
                                isSaving={this.props.isSaving}
                                canBeSaved={this.isValidToSave()}
                            />
                        </Grid>
                    </Grid>
                }>

                <Grid container spacing={2} className={classes.modal} justify='space-between'>
                    <Grid item xs={6}>
                        {this.renderTextField('part_number_code', 'deliveryOrderModule.materialsTable.code', true, "number", true)}
                        {this.renderTextField('transport_line', 'deliveryOrderModule.transportLine', true, "text", false)}
                        {this.renderTextField('name_who_certifies', 'deliveryOrderModule.namewhocertifies', true, "text", false)}
                        {this.renderTextField('number_register_quality', 'deliveryOrderModule.numberRegisterQuality', true, "text", false)}
                    </Grid>
                    <Grid item xs={6} >
                        {this.renderTextField('purchase_order', 'deliveryOrderModule.materialsTable.purchaseOrder', true, "number", true)}
                        {this.renderTextField('load_conditions', 'deliveryOrderModule.loadConditions', false)}
                        {this.renderTextField('name_vobo_transport', 'deliveryOrderModule.namevoboTransport', true, "text", false)}
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
}

const styles = theme => ({
    modal: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxWidth: 600
    },
    textField: {
        marginBottom: theme.spacing.unit * 2
    },
    checkBox: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxHeight: '25px'
    }
});

export default withRouter(withStyles(styles, { withTheme: true })(AddCertificateModal));