
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import moment from 'moment';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import { FormControlLabel, Checkbox, Grid, Paper } from '@material-ui/core';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import Select from 'react-select'
import DatePicker from '../../common/DateTimePicker/DatePicker';

import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
import { GetBooleanOptions, GetActionTypes } from '../../../store/helpers/SelectOptions';
import Evidence from './../../common/Evidence';

/**
 * Dialog to AddActionToListDialog
 */
class AddActionToListDialog extends Component {

    /**
     *Creates an instance of AddActionToListDialog.
     * @param {*} props
     * @memberof AddActionToListDialog
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            user_name: this.props.user ? this.props.user.profile.preferred_username : '',
            description: '',
            comments: '',
            action_type: 0,
            end_date_time: moment(),
            is_resolved: 0,
            annexe: [],
            checked: false,
            shouldRenderEvidence: true
        }
    }

    /**
     * Set local state to initial values
     *
     * @param {boolean} [checkedValue=false]
     * @memberof AddActionToListDialog
     */
    setInitialState(checkedValue = false) {
        this.setState({
            user_name: this.props.user ? this.props.user.profile.preferred_username : '',
            description: '',
            comments: '',
            action_type: 0,
            end_date_time: moment(),
            is_resolved: 0,
            annexe: [],
            checked: checkedValue,
            shouldRenderEvidence: false
        }, () => {
            this.setState({
                shouldRenderEvidence: true
            })
        })
    }

    /**
     * React lyfe-cycle
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof AddActionToListDialog
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.item && this.props.item !== prevProps.item) {
            let annexe = [];
            if (this.props.item.attachments)
                for (var index = 0; index < this.props.item.attachments.length; index++) {
                    annexe.push(this.props.item.attachments[index]);
                }
            this.setState({
                description: this.props.item.description,
                comments: this.props.item.comments,
                action_type: this.props.item.action_type,
                end_date_time: moment(this.props.item.end_date_time, 'DD/MM/YYYY'),
                is_resolved: this.props.item.is_resolved,
                annexe,
            })
        }
    }


    /**
     * Validate if form has full required data to save
     *
     * @returns
     * @memberof AddActionToListDialog
     */
    canBeSaved() {
        if (IsNullOrEmpty(this.status["description"]))
            return false
        if (IsNullOrEmpty(this.status["comments"]))
            return false
        if (IsNullOrEmpty(this.status["action_type"]))
            return false
        if (IsNullOrEmpty(this.status["end_date_time"]))
            return false
        if (!this.status["is_resolved"] > 0)
            return false
        if (this.status["annexe"].length <= 0)
            return false
        return true
    }

    /**
     * Handle update properties to set data in local state
     *
     * @param {*} property
     * @param {*} data
     * @memberof AddActionToListDialog
     */
    updateProperty(property, data) {
        this.setState({
            [property]: data,
        }
        )
    }

    /**
     * Handle state checked option
     *
     * @memberof AddActionToListDialog
     */
    handleCheck() {
        this.setState({
            checked: !this.state.checked
        })
    }

    /**
     * Handle onsave event of modal to send data to backend and save action
     *
     * @memberof AddActionToListDialog
     */
    handleOnSave() {
        if (!this.props.item)
            this.props.onAddActions(this.props.isContainment, this.state)
                .then(response => {
                    if (this.state.checked === true) {
                        this.setInitialState(true);
                    } else {
                        this.setInitialState();
                        this.props.onClose();
                    }
                })
                .catch(error => {
                })
        else {
            this.props.onEditActions(this.props.isContainment, this.props.item.token, this.state)
                .then(response => {
                    if (this.state.checked === true) {
                        this.setInitialState(true);
                    } else {
                        this.setInitialState();
                        this.props.onClose();
                    }
                })
                .catch(error => {
                })
        }
    }

    /**
     * Handle event upload files of devidence componet to add files to list
     *
     * @param {*} files
     * @returns
     * @memberof AddActionToListDialog
     */
    uploadFiles(files) {
        let filesList = this.state.annexe.concat(files[0]);
        // let filesList = this.state.annexe;
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
        }

        this.setState({
            annexe: filesList
        })
        return Promise.resolve('Ok')
    }

    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
     * @memberof AddActionToListDialog
     */
    removeFiles(fileName) {
        let newList = this.state.annexe.filter(files => {
            return files.name !== fileName
        })
        this.setState({
            annexe: newList
        })
        return Promise.resolve('Ok')
    }

    /**
     * handle event on close or on cancel to clean state of modal
     *
     * @memberof AddActionToListDialog
     */
    handleOnCloseCancel() {
        this.setInitialState();
        if (this.props.onClose)
            this.props.onClose();
    }

    /**
     * Render Text Area component
     *
     * @param {*} key
     * @param {*} translationId
     * @returns
     * @memberof AddActionToListDialog
     */
    renderTextArea(key, translationId, xs = 12) {
        return (
            <Grid item xs={xs} className={this.props.classes.textField}>
                <TextField autoFocus={key === 'description'}
                    key={key}
                    fullWidth
                    label={<Translate id={translationId} />}
                    value={this.state[key] ? this.state[key] : ''}
                    onChange={(event) => { this.updateProperty(key, event.target.value) }}
                    multiline
                    rowsMax="4"
                />
            </Grid>
        )
    }

    /**
     * Render Select component
     *
     * @param {*} key
     * @param {*} translationId
     * @param {*} options
     * @returns
     * @memberof AddActionToListDialog
     */
    renderSelect(key, translationId, options, xs = 12) {
        return (
            <Grid item xs={xs} className={this.props.classes.textField}>
                <Typography variant="caption" color={'textSecondary'}><Translate id={translationId} /></Typography>
                <Select
                    value={options[this.state[key] ? this.state[key] : 0]}
                    className={this.props.classes.documentTypeSelect}
                    options={options}
                    onChange={(event) => this.updateProperty(key, event.value)}
                />
            </Grid>
        )
    }

    /**
     * Render Datetime component
     *
     * @param {*} key
     * @param {*} translationId
     * @returns
     * @memberof AddActionToListDialog
     */
    renderDateTime(key, translationId, xs = 12) {
        return (
            <Grid item xs={xs} className={this.props.classes.textField}>
                <Typography variant="caption" color={'textSecondary'}><Translate id={translationId} /></Typography>
                <DatePicker
                    onChangeRange={(event) => { this.updateProperty(key, event) }}
                    startDate={this.state[key] ? this.state[key] : ''}
                />
            </Grid>
        )
    }

    /**
     * Render evidence component
     *
     * @param {*} key
     * @param {*} translationId
     * @returns
     * @memberof AddActionToListDialog
     */
    renderEvidence(key, translationId, xs = 12) {
        return (
            <Grid item xs={xs} className={this.props.classes.checkBox}>
                <Typography variant="subtitle2" color={'textSecondary'}>
                    <Translate id={translationId} />
                </Typography>
                <Evidence
                    baseApi={process.env.REACT_APP_SIP_URL_FILES}
                    removeFiles={this.removeFiles}
                    uploadFiles={this.uploadFiles}
                    files={this.state[key] ? this.state[key] : []}
                    disabledLink={!this.props.item}
                />
            </Grid>
        )
    }

    /**
     * Dialog to update information
     */
    render() {
        const { classes } = this.props;
        const booleanOptions = GetBooleanOptions();
        const actionType = GetActionTypes();
        return (
            <Paper style={{ overflowX: 'hidden' }}>
                <Dialog
                    style={{ overflowX: 'hidden' }}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    header={
                        <Toolbar>
                            <Typography variant="h6">
                                {<Translate id="sac.actions.addAction" />}
                            </Typography>
                        </Toolbar>
                    }

                    actions={
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => this.handleOnCloseCancel()}
                            onClose={this.props.onClose}
                            onSaveItem={() => this.handleOnSave()}
                            isSaving={this.props.isSaving}
                            canBeSaved={() => this.canBeSaved()}
                        />
                    }>
                    <Grid container style={{ maxWidth: 500 }} justify='space-between'>
                        {this.renderTextArea("description", ("sac.actions." + (this.props.isContainment ? 'containmentActions' : 'nonRecurrenceActions')))}
                        {this.renderTextArea("comments", "sac.actions.comments")}
                        {this.renderDateTime("end_date_time", "sac.actions.endDate")}
                        {this.renderSelect("action_type", "sac.actions.actionType", actionType, 5)}
                        {this.renderSelect("is_resolved", "sac.actions.solved", booleanOptions, 5)}
                        {this.renderEvidence("annexe", "sac.actions.attachments")}
                        {!this.props.item &&
                            <Grid item xs={12} className={this.props.classes.checkBox}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.checked}
                                            onChange={() => this.handleCheck()}
                                            value="checked"
                                            color="primary"
                                        />
                                    }
                                    label={<Translate id="sac.actions.keepAddingActions" />}
                                />
                            </Grid>}
                    </Grid>
                </Dialog>
            </Paper>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
    },
    checkBox: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    }
});

export default withStyles(dialogStyles, { withTheme: true })(AddActionToListDialog);