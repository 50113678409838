import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Translate, withLocalize } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { decodeJson, decodeNumericArray, decodeArray } from 'use-query-params'

/* Material UI import section */
import { Avatar, Card, CardContent, CardHeader, Typography, withStyles } from '@material-ui/core'
import AccreditedWorkShopsIcon from '@material-ui/icons/HowToReg'

/** Custom components import section */
import DataTable from '../../common/DataTable'
import LoadingPage from '../../common/LoadingPage'
import ContextFilterMenu from '../../common/contextFilters/ContextFilterMenu'
import AdvancedFiltersModal from '../../common/contextFilters/AdvancedFiltersModal'
import ServiceOrderDetail from './components/ServiceOrderDetail'
import PaymentRequestAddingPanel from './components/PaymentRequestAddingPanel'
import DialogQuickFilter from './components/DialogQuickFilter'
import ServiceOrdersToolbar from './components/ServiceOrdersToolbar'
import Toaster from '../../common/Toaster'
import { useConfigurator } from '../../common/DataGrid/UseConfigurator'

/** Actions import section */
import {
	loadAccreditedWorkshopsServicesFromBackEnd,
	validateXML,
	makePaymentRequest,
	setPaymentRequestErrors,
	setInvoice,
	setPaymentRequest,
	downloadServiceOrdersReport,
	loadAccreditedWorkshopsServicesTotalFromBackEnd,
	loadAccreditedWorkshopsServicesTotalAmountFromBackEnd,
	setServicesList,
	loadAccreditedWorkshopsServicesTokens,
	validatePaymentRequest,
} from '../../../store/accreditedWorkshops/accreditedWorkshopsActions.js'

/** Redux selectors import section */
import {
	getAllowedStatusForReleasedServicesSelector,
	getConfigurationSelector,
	getCreditNoteSelector,
	getInvoiceSelector,
	getPaymentRequestErrorsSelector,
	// getPaymentRequestSelector,
	getServiceOrderErrorsSelector,
	getServiceOrderListSelector,
	getServiceOrdersAmount,
	getServiceOrdersTotal,
	getServiceTypesOnConfigurationSelector,
} from '../../../store/accreditedWorkshops/accreditedWorkshopsSelectors'
import { getSupplierSelector } from '../../../store/suppliers/SupplierSelectors'
import { getLoggedUserSelector } from '../../../store/profile/ProfileSelectors'

/** Custom hooks import section */
import useSorting from '../hooks/SortingHook'

/** Resources import section */
import serviceOrderTableConfiguration from './serviceHelpers/TableConfiguration'
import { buildFilterConfig, FilterCriteria } from './serviceHelpers/FilterConfiguration'
import { decodeDelimitedPipeArray } from '../../common/contextFilters/CustomArrayParam'
import { renderCustomCellItem } from './serviceHelpers/TableHelpers'
import { buildSelectedServiceOrderMetrics } from './serviceHelpers/ServiceOrdersHelper'

const tableId = 'workshops-service-orders-table-config'

const ReleasedServiceOrders = (props) => {
	/** Desctructuring properties */
	const { history, location, translate, classes } = props

	/** Defines local state */
	const [loading, setLoading] = useState(true)
	const [loadingTotalAmount, setLoadingTotalAmount] = useState(false)
	const [processingPaymentRequest, setProcessingPaymentRequest] = useState(false)
	const [showCircularProgress, setShowCircularProgress] = useState(false)
	const [selectAllChecked, setSelectAllChecked] = useState(false)
	const [selectedServiceOrders, setSelectedServiceOrders] = useState([])
	const [invoiceFiles, setInvoiceFiles] = useState([])
	const [creditNoteFiles, setCreditNoteFiles] = useState([])
	const [fileValidation, setFileValidation] = useState({ displayed: false, isValid: false })
	const [serviceTableConfiguration, setServiceTableConfiguration] = useConfigurator(
		tableId,
		serviceOrderTableConfiguration
	)
	const [increasedTableConfiguration, setIncreasedTableConfiguration] = useState(serviceTableConfiguration)
	const [configuratorAnchorEl, setConfigurationAnchorEl] = useState(null)
	const [rowsPerPage, setRowsPerPage] = useState(20)
	const [page, setPage] = useState(0)
	const [serviceOrderDetailPanelOptions, setServiceOrderDetailPanelOptions] = useState({
		opened: false,
		onCloseButtonClick: null,
		token: '',
	})
	const [paymentRequestPanelOptions, setPaymentRequestPanelOptions] = useState({ opened: false })
	const [filters, setFilters] = useState([])
	const [filterSettings, setFilterSettings] = useState({
		open: false,
		anchorEl: null,
		type: '',
		filterIdentificator: '',
	})
	const [advancedFilterModalConfig, setAdvancedFilterModalConfig] = useState({
		open: false,
		queryStringKey: '',
		filterId: '',
		criteria: 0,
	})
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		message: '',
		variant: '',
	})
	const [openQuickFilterDialog, setOpenQuickFilterDialog] = useState(false)

	const selectedServicesData = buildSelectedServiceOrderMetrics(selectedServiceOrders)

	/** Connect with redux */
	const dispatch = useDispatch()
	const profile = useSelector((state) => getLoggedUserSelector(state))
	const supplier = useSelector((state) => getSupplierSelector(state))
	const serviceOrdersTotal = useSelector((state) => getServiceOrdersTotal(state))
	const serviceOrders = useSelector((state) => getServiceOrderListSelector(state))
	// const paymentRequest = useSelector((state) => getPaymentRequestSelector(state))
	const invoice = useSelector((state) => getInvoiceSelector(state))
	const creditNote = useSelector((state) => getCreditNoteSelector(state))
	const paymentRequestErrors = useSelector((state) => getPaymentRequestErrorsSelector(state))
	const serviceOrdersErrors = useSelector((state) => getServiceOrderErrorsSelector(state))
	const totalAmount = useSelector((state) => getServiceOrdersAmount(state))
	const serviceTypes = useSelector((state) => getServiceTypesOnConfigurationSelector(state))
	const settings = useSelector((state) => getConfigurationSelector(state))
	const allowedServiceOrderStatus = useSelector((state) => getAllowedStatusForReleasedServicesSelector(state))

	const [sortingBy, sortingDirection, dataSource, orderAsc, handleSortingCriteriaChange] = useSorting()

	/** Set initial filters */
	useEffect(() => {
		if (!!supplier) {
			dispatch(setInvoice(null))
			dispatch(setPaymentRequest(null))
		}
	}, [supplier, dispatch])

	useEffect(() => {
		if (!!supplier && !!location && serviceTypes.length > 0 && allowedServiceOrderStatus.length > 0) {
			const queryParams = queryString.parse(location.search)
			const searchFilters = []

			Object.entries(queryParams).forEach(([name, value]) => {
				if (name.startsWith('Advanced')) {
					let newName = name.replace('Advanced', '')
					switch (newName) {
						case 'ServiceCost': {
							const decodedFilter = decodeJson(value)
							const decodedValues = decodeNumericArray(decodedFilter.values)
							searchFilters.push({
								PropertyIdentificator: newName,
								RuleValue: decodedValues,
								FilterCategorization: decodedFilter.criteria,
							})
							break
						}
						default: {
							const decodedFilter = decodeJson(value)
							const decodedValues = decodeArray(decodedFilter.values)
							searchFilters.push({
								PropertyIdentificator: newName,
								RuleValue: decodedValues,
								FilterCategorization: decodedFilter.criteria,
							})
							break
						}
					}
				} else if (!name.includes('order')) {
					switch (name) {
						case 'pageNumber': {
							break
						}
						case 'TallerImporteServicio':
						case 'TallerImporteGastos': {
							const decodedValues = decodeDelimitedPipeArray(value)
							searchFilters.push({
								PropertyIdentificator: name,
								RuleValue: decodedValues,
								FilterCategorization: FilterCriteria.NUMERIC_EQUAL,
							})
							break
						}
						default: {
							const decodedValues = decodeDelimitedPipeArray(value)
							searchFilters.push({
								PropertyIdentificator: name,
								RuleValue: decodedValues.map((val) => (val === '(VACIO)' ? ' ' : val)),
								FilterCategorization: name.includes('Date')
									? FilterCriteria.BETWEEN_DATES
									: FilterCriteria.EQUAL,
							})
							break
						}
					}
				}
			})
			searchFilters.push({
				PropertyIdentificator: 'ServiceTypeId',
				RuleValue: serviceTypes.map((st) => st.Type),
			})
			setFilters([
				...searchFilters,
				{
					PropertyIdentificator: 'WorkshopTechnicianSapId',
					RuleValue: [supplier.code],
				},
				{
					PropertyIdentificator: 'OrdenStatus',
					RuleValue: allowedServiceOrderStatus,
				},
				{
					PropertyIdentificator: 'WorkshopStatus',
					RuleValue: ['100'],
				},
			])
		}
	}, [supplier, serviceTypes, location, allowedServiceOrderStatus])

	useEffect(() => {
		const shouldDisplayChecks = () => {
			const technicianFilter = filters.find((f) => f.PropertyIdentificator === 'WorkshopTechnicianPs9Id')
			if (!technicianFilter || technicianFilter.RuleValue.length !== 1) return false
			const chainFilter = filters.find((f) => f.PropertyIdentificator === 'ChainId')
			if (!chainFilter || chainFilter.RuleValue.length !== 1) return false
			const branchOfficeFilter = filters.find((f) => f.PropertyIdentificator === 'BranchOfficeId')
			if (!branchOfficeFilter || branchOfficeFilter.RuleValue.length !== 1) return false
			const serviceTypeFilter = filters.find((f) => f.PropertyIdentificator === 'ServiceTypeId')
			let validService = false
			if (!serviceTypeFilter || serviceTypeFilter.RuleValue.length === 0) return false
			else if (
				!validService &&
				serviceTypeFilter.RuleValue.length === 2 &&
				((serviceTypeFilter.RuleValue.includes('1001') && serviceTypeFilter.RuleValue.includes('1002')) ||
					(serviceTypeFilter.RuleValue.includes('1003') && serviceTypeFilter.RuleValue.includes('1004')))
			) {
				validService = true
			} else if (
				!validService &&
				serviceTypeFilter.RuleValue.length === 3 &&
				serviceTypeFilter.RuleValue.includes('1001') &&
				serviceTypeFilter.RuleValue.includes('1002') &&
				serviceTypeFilter.RuleValue.includes('1005')
			) {
				validService = true
			} else if (!validService && serviceTypeFilter.RuleValue.length === 1) validService = true
			return true && validService
		}

		const isFilterApplied = (queryParams, filterId) => {
			const filterInQueryString = queryParams[filterId]
			return !!filterInQueryString
		}

		const updateColumnsConfig = () => {
			let newColumns = [...serviceTableConfiguration]
			const filterIds = {
				ChainId: 'branchOffice',
				BranchOfficeId: 'workshopTechnicianPs9Id',
				WorkshopTechnicianPs9Id: 'serviceType',
			}
			const queryParams = queryString.parse(location.search)
			Object.entries(filterIds).forEach(([filterKey, columnKey]) => {
				const filterApplied = isFilterApplied(queryParams, filterKey)
				const indexOfColumn = serviceTableConfiguration.findIndex((f) => f.dataSource === columnKey)
				if (indexOfColumn > 0) {
					newColumns[indexOfColumn] = {
						...serviceTableConfiguration[indexOfColumn],
						filterEnabled: filterApplied,
					}
				}
			})
			return newColumns
		}

		if (shouldDisplayChecks()) {
			const columns = updateColumnsConfig()
			setIncreasedTableConfiguration([
				...columns,
				{
					header: <Translate id='common.releaseDate' />,
					dataSource: 'releaseDate',
				},
			])
		} else {
			setSelectedServiceOrders([])
			const columns = updateColumnsConfig()
			setIncreasedTableConfiguration([
				...columns.filter((f) => f.dataSource !== 'selectCheck'),
				{
					header: <Translate id='accreditedWorkshops.reason' />,
					dataSource: 'lockReason',
				},
				{
					header: <Translate id='common.releaseDate' />,
					dataSource: 'releaseDate',
				},
			])
		}
	}, [serviceTableConfiguration, filters, location])

	/** Load service orders */
	useEffect(() => {
		if (filters.length > 0) {
			setLoading(true)
			dispatch(
				loadAccreditedWorkshopsServicesFromBackEnd(filters, page, rowsPerPage, sortingBy, sortingDirection)
			).finally(() => setLoading(false))
			dispatch(loadAccreditedWorkshopsServicesTotalFromBackEnd(filters))
			setLoadingTotalAmount(true)
			dispatch(loadAccreditedWorkshopsServicesTotalAmountFromBackEnd(filters)).finally(() =>
				setLoadingTotalAmount(false)
			)
		} else {
			dispatch(setServicesList([]))
			setLoading(false)
		}
	}, [dispatch, filters, sortingBy, sortingDirection, page, rowsPerPage])

	/**
	 * Handle the service orders selection and unpdate local state.
	 * @param {Array} selectedTokens
	 */
	const handleSelectingRowChange = (itemToken, checked) => {
		if (checked) {
			const serviceOrder = serviceOrders.find((so) => so.token === itemToken)
			if (serviceOrder) {
				setSelectedServiceOrders((serviceOrders) => [
					...serviceOrders,
					{
						token: serviceOrder.token,
						code: serviceOrder.code,
						tallerImporteGastos: serviceOrder.tallerImporteGastos,
						tallerImporteServicio: serviceOrder.tallerImporteServicio,
					},
				])
			}
		} else {
			setSelectedServiceOrders((serviceOrders) => serviceOrders.filter((so) => so.token !== itemToken))
		}
	}

	const handlePageChange = (newPage, newRowsPerPage) => {
		setRowsPerPage((prevRowsPerPage) => {
			const pageToSet = prevRowsPerPage !== newRowsPerPage ? 0 : newPage
			setPage(pageToSet)
			return newRowsPerPage
		})
	}

	const openContextFilterMenu = (event, dataSource) => {
		let filterConfig = buildFilterConfig(dataSource)
		const rect = event.currentTarget.getBoundingClientRect()
		setFilterSettings({
			...filterConfig,
			open: true,
			anchorEl: event.currentTarget,
			x: rect.x,
			y: rect.y + rect.height,
		})
	}

	const closeContextFilterMenu = () => {
		setFilterSettings({
			open: false,
			anchorEl: null,
			type: '',
			name: '',
			dataSource: '',
			filterIdentificator: '',
		})
	}

	const openAvancedFilterModal = (queryStringKey, criteria) => {
		setAdvancedFilterModalConfig({
			open: true,
			queryStringKey: queryStringKey,
			criteria: criteria,
		})
		closeContextFilterMenu()
	}

	const closeAvancedFilterModal = () => {
		setAdvancedFilterModalConfig({ open: false, criteria: 0 })
	}

	/**
	 * Update state in order to show the lateral panel with service order detail.
	 * @param {Object} item
	 * @param {Object} event
	 */
	const openServiceOrderDetail = (item, event) => {
		const { tagName } = event.target
		if (tagName !== 'INPUT') {
			const { token } = item
			setServiceOrderDetailPanelOptions({ opened: true, onCloseButtonClick: closeServiceOrderDetail, token })
		}
	}

	/**
	 * Update state in order to close the lateral panel with service order detail
	 */
	const closeServiceOrderDetail = () => {
		setServiceOrderDetailPanelOptions({ opened: false, token: '' })
	}

	/**
	 * Update state in order to show the lateral panel to create a new payment request.
	 */
	const openPaymentRequestAddingPanel = () => {
		setPaymentRequestPanelOptions({ opened: true })
	}

	/**
	 * Update state in order to close the lateral panel to create a new payment request
	 */
	const closePaymentRequestAddingPanel = () => {
		setPaymentRequestPanelOptions({ opened: false })
	}

	/**
	 * Handle event upload files of devidence componet to add files to list
	 *
	 * @param {Array} files
	 */
	const validatePaymentRequestData = (files, serviceOrderCodes) => {
		console.log('🚀 ~ file: ReleasedServices.js ~ line 431 ~ validatePaymentRequestData ~ files', files)
		setProcessingPaymentRequest(true)
		dispatch(setInvoice(null))
		dispatch(setPaymentRequestErrors([], []))
		const xmlFilesObj = {
			invoice: files.find((file) => file.type === 'text/xml'),
		}
		setInvoiceFiles(files)
		if (!serviceOrderCodes || serviceOrderCodes.length === 0) {
			serviceOrderCodes = selectedServiceOrders.map((so) => so.code)
		}
		const chainFilter = filters.find((filter) => filter.PropertyIdentificator === 'ChainId')
		const chainCodes = chainFilter ? chainFilter.RuleValue : []
		dispatch(validatePaymentRequest(xmlFilesObj, chainCodes, serviceOrderCodes, supplier.code))
			.then((response) => {
				if (response) {
					dispatch(validateXML(xmlFilesObj))
						.then((response) => {
							setFileValidation({ displayed: true, isValid: response !== undefined })
						})
						.catch(() => {
							//Error to validate
						})
				} else {
					setToasterOptions({
						open: true,
						message: translate('accreditedWorkshops.error.messageErrorsFound'),
						variant: 'error',
					})
				}
			})
			.catch((error) => {
				//Error to validate
			})
			.finally(() => setProcessingPaymentRequest(false))
	}

	/**
	 * Handle event upload files of devidence componet to add files to list
	 *
	 * @param {Array} files
	 */
	const uploadCreditNoteFiles = (files) => {
		const invoiceXmlFile = invoiceFiles.find((f) => f.type === 'text/xml')
		const creditNoteXmlFile = files.find((f) => f.type === 'text/xml')
		const xmlFilesObj = {
			invoice: invoiceXmlFile,
			creditNote: creditNoteXmlFile,
		}
		setCreditNoteFiles(files)
		const serviceOrderCodes = selectedServiceOrders.map((so) => so.code)
		const chainFilter = filters.find((filter) => filter.PropertyIdentificator === 'ChainId')
		const chainCodes = chainFilter ? chainFilter.RuleValue : []

		setProcessingPaymentRequest(true)
		dispatch(validatePaymentRequest(xmlFilesObj, chainCodes, serviceOrderCodes, supplier.code))
			.then((response) => {
				if (response) {
					dispatch(validateXML(xmlFilesObj))
						.then((response) => {
							setFileValidation({ displayed: true, isValid: response !== undefined })
						})
						.catch(() => {
							//Error to validate
						})
				} else {
					setToasterOptions({
						open: true,
						message: translate('accreditedWorkshops.error.messageErrorsFound'),
						variant: 'error',
					})
				}
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: 'No se pudo realizar la validación',
					variant: 'error',
				})
			})
			.finally(() => setProcessingPaymentRequest(false))

		return Promise.resolve('Ok')
	}

	const onSelectAllChange = (checked) => {
		setSelectAllChecked(checked)
		if (checked) {
			dispatch(loadAccreditedWorkshopsServicesTokens(filters)).then((serviceOrders) => {
				setSelectedServiceOrders(serviceOrders)
			})
		} else {
			setSelectedServiceOrders([])
		}
	}

	const cleanData = () => {
		setInvoiceFiles([])
		setCreditNoteFiles([])
		setFileValidation({ displayed: false, isValid: false })
		dispatch(setInvoice(null))
		dispatch(setPaymentRequestErrors([], []))
		return Promise.resolve('Ok')
	}

	const createPaymentRequest = (serviceOrderCodes) => {
		if (!serviceOrderCodes || serviceOrderCodes.length === 0) {
			serviceOrderCodes = selectedServiceOrders.map((so) => so.code)
		}
		setProcessingPaymentRequest(true)
		const chainFilter = filters.find((filter) => filter.PropertyIdentificator === 'ChainId')
		const chainCodes = chainFilter ? chainFilter.RuleValue : []
		console.log('🚀 ~ file: ReleasedServices.js ~ line 442 ~ validatePaymentRequestData', {
			chainFilter,
			chainCodes,
		})
		dispatch(
			makePaymentRequest(
				invoiceFiles,
				creditNoteFiles,
				chainCodes,
				serviceOrderCodes,
				supplier.code,
				profile.selectedCompany.partnership_code
			)
		)
			.then((response) => {
				if (response) {
					setToasterOptions({
						open: true,
						message: 'Las solicitud de pago ha sido creada.',
						variant: 'success',
					})
					const { token } = response
					closePaymentRequestAddingPanel()
					const url = `/accreditedWorkshops/paymentRequest/${token}`
					setTimeout(() => {
						history.push(url)
					}, 2000)
				} else {
					closePaymentRequestAddingPanel()
					setToasterOptions({
						open: true,
						message: translate(
							response.data.Message === '409 Conflict when saving file'
								? 'accreditedWorkshops.error.messageErrorSaveFile'
								: 'accreditedWorkshops.error.messageErrorsFound'
						),
						variant: 'error',
					})
					setProcessingPaymentRequest(false)
				}
			})
			.catch((error) => {
				//Error to validate
				setToasterOptions({
					open: true,
					message: translate(
						error.data.Message === '409 Conflict when saving file'
							? 'accreditedWorkshops.error.messageErrorSaveFile'
							: 'accreditedWorkshops.error.messageErrorsFound'
					),
					variant: 'error',
				})
				setProcessingPaymentRequest(false)
			})
	}

	/**
	 * Get counter
	 *
	 * @returns
	 * @memberof Index
	 */
	const getCounterMessage = () => {
		if (serviceOrders) {
			let quantityOfServices = serviceOrders.length
			return (
				<div>
					{translate('common.showing')}&nbsp;
					<span>{quantityOfServices}</span>&nbsp;
					{translate('common.of')}&nbsp;
					<span className={classes.totalCounter}>{serviceOrdersTotal}</span>
					&nbsp;
					{translate('common.records')}
				</div>
			)
		}
		return <span></span>
	}

	/*
	 * DOWNLOAD SERVICES REPORT
	 */
	const downloandServiceOrdersReportIndex = () => {
		setShowCircularProgress(true)
		dispatch(downloadServiceOrdersReport(filters)).finally(() => setShowCircularProgress(false))
	}

	const openTableColumnsConfigurator = (event) => {
		setConfigurationAnchorEl(configuratorAnchorEl ? null : event.currentTarget)
	}

	/**
	 * The user change the service table configuration
	 */
	const onTableConfigChange = (configuration) => {
		setServiceTableConfiguration(configuration)
		setConfigurationAnchorEl(null)
	}

	return (
		<>
			<Card>
				<CardHeader
					className={classes.cardHeader}
					avatar={
						<Avatar>
							<AccreditedWorkShopsIcon />
						</Avatar>
					}
					title={
						<Typography variant='overline'>
							<Translate id='accreditedWorkshops.accreditedWorkshopsService' />{' '}
						</Typography>
					}
					action={
						<ServiceOrdersToolbar
							columnSettingsButton={{
								displayed: true,
								onClick: openTableColumnsConfigurator,
								tableSettings: serviceTableConfiguration,
								onTableSettingsChange: onTableConfigChange,
							}}
							allServiceMetrics={{
								loading: loadingTotalAmount,
								numberOfServiceOrders: serviceOrdersTotal,
								totalAmount: totalAmount,
							}}
							selectedServiceMetrics={{
								...selectedServicesData,
							}}
							downloadingButton={{
								downloading: showCircularProgress,
								onClick: downloandServiceOrdersReportIndex,
							}}
							creatingPaymentRequestButton={{
								disabled: false,
								locked:
									settings.configuration && !settings.configuration.PaymentRequestCreationStatusFull,
								onClick: openPaymentRequestAddingPanel,
							}}
							setOpenQuickFilterDialog={setOpenQuickFilterDialog}
						/>
					}
					subheader={getCounterMessage}
				/>
				<CardContent className={classes.cardContent}>
					{
						{
							true: <LoadingPage />,
							false: (
								<DataTable
									isIndex
									rowHeight={32}
									data={serviceOrders}
									configuration={{ columns: increasedTableConfiguration }}
									sortBy={dataSource}
									sortAscending={orderAsc}
									onChangeSortCriteria={handleSortingCriteriaChange}
									onRowClick={openServiceOrderDetail}
									onRenderCellItem={renderCustomCellItem}
									totalRows={serviceOrdersTotal}
									page={page}
									rowsPerPage={rowsPerPage}
									onChangePage={handlePageChange}
									//onSelectedCheck={handleServiceOrdersSelection}
									onSelectRowChange={handleSelectingRowChange}
									onFilterClick={openContextFilterMenu}
									onSelectAllChange={onSelectAllChange}
									selectedTokens={selectedServiceOrders.map((so) => so.token)}
									selectAllChecked={selectAllChecked}
								/>
							),
						}[loading]
					}
				</CardContent>
			</Card>
			{filterSettings.open && (
				<ContextFilterMenu
					{...filterSettings}
					filters={filters}
					onClose={closeContextFilterMenu}
					onAvancedFilterClick={openAvancedFilterModal}
				/>
			)}
			{advancedFilterModalConfig.open && (
				<AdvancedFiltersModal {...advancedFilterModalConfig} onClose={closeAvancedFilterModal} />
			)}
			<DialogQuickFilter
				openDialog={openQuickFilterDialog}
				defaultServiceTypes={serviceTypes}
				filters={filters}
				buildFilterConfig={buildFilterConfig}
				setOpenDialog={setOpenQuickFilterDialog}
			/>
			{serviceOrderDetailPanelOptions.opened && (
				<ServiceOrderDetail classes={classes} {...serviceOrderDetailPanelOptions} />
			)}
			{paymentRequestPanelOptions.opened && (
				<PaymentRequestAddingPanel
					onClose={closePaymentRequestAddingPanel}
					// onServiceOrdersSelected={selectServiceOrders}
					onButtonCreateClick={createPaymentRequest}
					invoiceFiles={invoiceFiles}
					onUploadInvoiceFiles={validatePaymentRequestData}
					onRemoveInvoiceFiles={cleanData}
					creditNoteFiles={creditNoteFiles}
					onUploadCreidtNoteFiles={uploadCreditNoteFiles}
					onRemoveCreidtNoteFiles={cleanData}
					fileValidation={fileValidation}
					paymentRequestErrors={paymentRequestErrors}
					serviceOrdersErrors={serviceOrdersErrors}
					invoice={invoice}
					creditNote={creditNote}
					amount={selectedServicesData.totalAmount}
					quantityOfServices={selectedServicesData.numberOfServiceOrders}
					saving={processingPaymentRequest}
					disabled={!fileValidation.isValid}
				/>
			)}
			<Toaster
				{...toasterOptions}
				onClose={() => {
					setToasterOptions({
						open: false,
						message: '',
						variant: '',
					})
				}}
			/>
		</>
	)
}

const styles = (theme) => ({
	cardHeader: {
		padding: '8px 16px 0px 4px',
	},
	cardContent: {
		padding: '4px 4px',
		maxWidth: 'calc(100vw - 300px)',
	},
	instructionsDiv: {
		padding: theme.spacing.unit,
		maxWidth: '20vw',
	},
})

export default withRouter(withLocalize(withStyles(styles, { withTheme: true })(ReleasedServiceOrders)))
