import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import autoBind from 'auto-bind'

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader, Divider } from '@material-ui/core';
import { Translate } from "react-localize-redux";

import QuestionCard from './components/QuestionCard';
import LoadingPage from '../../common/LoadingPage';


class EthicTemplate extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showIndex: true,
        };
    }

    /**
     * Render 
     */
    render() {
        var { templateEthicCode, isUserAdmin, isLoadingEthicCode } = this.props;
        return (
            <div>
                {isLoadingEthicCode === true ?
                    <LoadingPage />
                    :
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Grid item>
                                <Divider />
                                <Card>
                                    <CardHeader
                                        title={<Translate id="surveys.surveyTemplate" />}
                                    />
                                    <CardContent>
                                        {templateEthicCode && templateEthicCode.questions.length > 0 && templateEthicCode.questions.map(question => {
                                            return (<QuestionCard
                                                key={question.token}
                                                showWeighting
                                                question={question}
                                                isUserAdmin={isUserAdmin}
                                                isTemplated={templateEthicCode.is_templated}
                                                isEditMode
                                                removeQuestionInTemplated={this.props.removeQuestionInTemplated}
                                                editQuestionInTemplated={this.props.editQuestionInTemplated}
                                            />
                                            )
                                        })}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
}

const styles = theme => ({
    logo: {
        backgroundColor: theme.palette.secondary.main,
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    showingCounter: {
        color: theme.palette.secondary.main
    }
});

export default withRouter(withStyles(styles, { withTheme: true })(connect(null, null)(EthicTemplate)));