
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import autoBind from 'auto-bind'

/** Material-UI imports section */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";

/** Import section component */
import Toaster from '../../common/Toaster';
import DialogActions from '@material-ui/core/DialogActions';
import SuppliersSelector from '../../common/SupplierSelector';
import PartNumberSelector from '../../common/PartNumberSelector';
import FailureNonConformitySelector from '../../common/FailureNonConformitySelector';
import PreparedBySelector from '../../common/PreparedBySelector';

import {COMPANY_PTM} from '../../../store/helpers/ResourcesHelper';

/**
 * Component Form Raw material non conformity
 */
class FormView extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            preparedBySelected: null,
            supplierSelected: null,
            partNumberSelected: null,
            failureSelected: null,
            rawMaterial: {
                problemDescription: "",
                invoicedAmount: "",
                quantityInspected: "",
                specificationCorreclty: "",
                lotMaterial: "",
                customerPartNumber: "",
                deliveryOrderFolio: "",
                deliveryOrderToken: "",
            }
        }
    }

    /**
    * On seleted prepare by
    */
    onPrepareBySelected(prepareBy) {
        this.setState({ preparedBySelected: prepareBy });
    }

    /**
     * On seletecd supplier
     */
    onSupplierSelected(supplier) {
        this.setState({ supplierSelected: supplier });
    }

    /**
     * On seleted part number
     */
    onPartNumberSelected(partNumber) {
        this.setState({ partNumberSelected: partNumber });
    }

    /**
     * On seleted failure
     */
    onFailureSelected(failure) {
        this.setState({ failureSelected: failure });
    }

    /**
    * Update the property of the raw mateiral
    *
    * @param {*} user
    * @param {*} value
    * @memberof View
    */
    updateProperty(property, value) {
        let creatRawMaterial = { ...this.state.rawMaterial };
        creatRawMaterial[property] = value;
        this.setState({
            rawMaterial: creatRawMaterial
        })
    }

    /**
     * Can be add item in raw material
     */
    canBeSaved() {
        let rawMaterial = this.state.rawMaterial;
        if (this.state.preparedBySelected === null) return false;
        if (this.state.supplierSelected === null) return false;
        if (this.state.partNumberSelected === null)return false;
        if (this.state.failureSelected === null) return false;
        if (rawMaterial.problemDescription === "" || rawMaterial.invoicedAmount === "" ||
            rawMaterial.quantityInspected === "" || rawMaterial.specificationCorreclty === "")
            return false;
        return true;
    }

    /**
    * Has valid quantity
    */
    hasInvalidValueQantity() {
        /// Validar que la cantidad inventario no puede ser mayor a la cantiad reportada
        if (this.state.rawMaterial.invoicedAmount !== "" && this.state.rawMaterial.quantityInspected !== "") {
            let invoicedAmount = Number(this.state.rawMaterial.invoicedAmount);
            let quantityInspected = Number(this.state.rawMaterial.quantityInspected);
            if (invoicedAmount < quantityInspected) {
                return true;
            }
            else
                return false;
        }
        else
            return false;
    }


    /**
     * Metho to can be created non conformity
     */
    canBeCreateNonconformity() {
        /// Validar que la cantidad inspeccionarda no puede ser mayo a la factudda
        if (this.hasInvalidValueQantity()) {
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="rawmaterialnonconformity.messageQualityInvalid" />,
                toasterVariant: "error",
            })
        }
        else if (this.props.canBeCreate) {
            this.props.canBeCreate(this.state.preparedBySelected, this.state.supplierSelected, this.state.partNumberSelected, this.state.failureSelected, this.state.rawMaterial)
        }
    }

    /**
     * Render show 
     */
    render() {
        const { classes, selectedCompany} = this.props;
        return (
            <div className={classes.container} noValidate autoComplete="off">
                <Grid container spacing={24} autoComplete="off">
                    <Grid item xs={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom><Translate id="common.preparedBy"/> *</Typography>
                                <PreparedBySelector
                                    className={classes.textField}
                                    onUsersQualitySelected={(supplier) => { this.onPrepareBySelected(supplier) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom><Translate id="common.supplier"/> *</Typography>
                                <SuppliersSelector
                                    className={classes.textField}
                                    onSupplierSelected={(supplier) => { this.onSupplierSelected(supplier) }}
                                />
                            </Grid>
                           {selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && <Grid item xs={12}>
                                <TextField
                                    id="standard-read-only-input"
                                    label={"Lote *"}
                                    value={this.state.rawMaterial.lotMaterial}
                                    type="text"
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("lotMaterial", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>}
                            {selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && <Grid item xs={12}>
                                <TextField
                                    id="standard-read-only-input"
                                    label={"Número de parte del cliente"}
                                    value={this.state.rawMaterial.customerPartNumber}
                                    type="text"
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("customerPartNumber", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>}
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label={<span><Translate id="rawmaterialnonconformity.descriptionBrach" /> *</span>}
                                    multiline
                                    rows="5"
                                    value={this.state.rawMaterial.problemDescription}
                                    onChange={(event) => { this.updateProperty("problemDescription", event.target.value) }}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label={<span><Translate id="rawmaterialnonconformity.specificationCorreclty" /> *</span>}
                                    multiline
                                    rows="5"
                                    value={this.state.rawMaterial.specificationCorreclty}
                                    onChange={(event) => { this.updateProperty("specificationCorreclty", event.target.value) }}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom><Translate id="common.partNumber"/> *</Typography>
                                <PartNumberSelector
                                    className={classes.textField}
                                    onPartNumberSelected={(partNumber) => { this.onPartNumberSelected(partNumber) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={true}
                                    id="standard-read-only-input"
                                    label={<span><Translate id="common.descriptionPartNumber" /> *</span>}
                                    value={this.state.partNumberSelected ? this.state.partNumberSelected.description : ""}
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("description", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={true}
                                    id="standard-read-only-input"
                                    label={<Translate id="common.measurementUnit" />}
                                    value={this.state.partNumberSelected ? this.state.partNumberSelected.measurementUnit : ""}
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("measurementUnit", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom><Translate id="rawmaterialnonconformity.failure"/> *</Typography>
                                <FailureNonConformitySelector
                                    className={classes.textField}
                                    onNonConformitySelected={(failure) => { this.onFailureSelected(failure) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-read-only-input"
                                    error={this.hasInvalidValueQantity()}
                                    label={<span><Translate id="rawmaterialnonconformity.invoicedAmount" /> *</span>}
                                    value={this.state.rawMaterial.invoicedAmount}
                                    type="number"
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("invoicedAmount", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-read-only-input"
                                    error={this.hasInvalidValueQantity()}
                                    label={<span><Translate id="rawmaterialnonconformity.quantityInspected" /> *</span>}
                                    value={this.state.rawMaterial.quantityInspected}
                                    type="number"
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("quantityInspected", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <DialogActions>
                                <Button
                                    data-cy="btn-save"
                                    type="button"
                                    color="primary"
                                    onClick={this.canBeCreateNonconformity}
                                    disabled={!this.canBeSaved() || this.props.isSaving}>
                                    {<Translate id="common.save" />}
                                </Button>
                                <Button onClick={() => { this.props.history.push(`/rawmaterialnonconformity`) }} color="secondary">
                                    {<Translate id="common.cancel" />}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Grid>

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </div>
        )
    }
}

const viewStyles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: "100px",
        paddingRight: "150px",
    },
    tetxtFieldFull: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        // width: 900,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        //   width: 440,
        width: '100%',
    },
    textAreaField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        // width: 440,
        height: 80,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    footerForm: {
        display: "flex",
        paddingRight: "60px",
    },
    formControl: { margin: theme.spacing.unit },
    toolbar: {},
    searchInput: {},
    button: {},
    actionsToolbar: {},
    showingUsersCounter: {},
    grow: {},
    supplierBadge: {}
});

export default withRouter(withStyles(viewStyles, { withTheme: true })(FormView));