import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import SustainabilityIcon from '@material-ui/icons/NaturePeople'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'

import {
	CHANGE_SUSTAINABILITY_SORT_CRITERIA,
	CHANGE_SUSTAINABILITY_QUERY,
	CHANGE_SUSTAINABILITY_PAGINATION,
	loadSustainabilityQuestionnairesFromBackEnd,
	getSustainabilityQuestionnairesCount,
} from '../../store/sustainability/SustainabilityActions'

import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'

class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadSustainabilityQuestionnaires(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.props.isAllReports
			)
			this.props.getSustainabilityQuestionnairesCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadSustainabilityQuestionnaires()
			this.props.getSustainabilityQuestionnairesCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadSustainabilityQuestionnaires(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingQuestionnaires === true) {
			return <LoadingPage />
		} else if (this.props.questionnaires === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.questionnaires}
					configuration={TableConfiguration(IsCurrentActiveDirectoryUser(this.props.user))}
					showFilters
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(sustainability) => {
						this.props.history.push(`/sustainability/${sustainability.token}`)
					}}
					isIndex
					totalRows={this.props.questionnairesCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get moemos counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getSacsCounterMessage() {
		const { classes } = this.props
		if (this.props.questionnaires) {
			let totalQuestionnaires = this.props.questionnaires.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalQuestionnaires}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span className={classes.totalCounter}>{this.props.questionnairesCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof Sacs
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<SustainabilityIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>
							{IsCurrentActiveDirectoryUser(this.props.user) && (
								<Fab
									size='small'
									color='primary'
									onClick={() => {
										this.props.history.push(`/sustainability/create`)
									}}
								>
									<AddtIcon />
								</Fab>
							)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='sustainability.sustainabilityQuestionnaire' />{' '}
						</Typography>
					}
					subheader={this.getSacsCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>
			</Card>
		)
	}
}

function TableConfiguration(isUserInternal) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='common.createBy' />,
			dataSource: 'create_by',
			isSortable: true,
		},
	]

	if (isUserInternal) {
		columns.push({
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		})
	}

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingQuestionnaires: state.sustainability.get('isLoadingQuestionnaires'),
		questionnaires: state.sustainability.get('questionnaires')
			? state.sustainability.get('questionnaires').toJS()
			: null,
		sortCriteria: state.sustainability.get('sortCriteria') ? state.sustainability.get('sortCriteria').toJS() : null,
		searchQuery: state.sustainability.get('searchQuery'),
		page: state.sustainability.get('page'),
		rowsPerPage: state.sustainability.get('rowsPerPage'),
		questionnairesCount: state.sustainability.get('questionnairesCount'),
		isSaving: state.ratings.get('isSavingQuestionnaire'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_SUSTAINABILITY_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_SUSTAINABILITY_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_SUSTAINABILITY_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		loadSustainabilityQuestionnaires: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadSustainabilityQuestionnairesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		getSustainabilityQuestionnairesCount: () => {
			dispatch(getSustainabilityQuestionnairesCount())
		},
	}
}

const styles = (theme) => ({
	logo: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
