import React, {Component} from 'react';
import autoBind from 'auto-bind'
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import '../../resources/styles/ReactCrop.css';

/**
 * CropForm component
 *
 * @class CropForm
 * @extends {Component}
 */
class CropForm extends Component {

    /**
     *Creates an instance of CropForm.
     * @param {*} props
     * @memberof CropForm
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            crop : null,   
        }
    }

    /**
     * On change crop
     *
     * @param {*} crop
     * @param {*} pixelCrop
     * @memberof CropForm
     */
    onChangeCrop(crop) {
        this.setState({
            crop : crop,
        })
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            "newFile.jpeg"
          );
          this.setState({ croppedImageUrl });
        }
      }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
              if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error("Canvas is empty");
                return;
              }
              blob.name = fileName;
              window.URL.revokeObjectURL(this.fileUrl);
              this.fileUrl = window.URL.createObjectURL(blob);
              resolve(this.fileUrl);
            }, "image/jpeg");
          });
        }
    
        
    /**
     * On image loaded event for setting initia crop and initial pixel crop values
     * 
     * @param {any} image 
     * @memberof CropImageModal
     */
    onImageLoaded(image) {
        let initialCrop = makeAspectCrop({
            x: 0,
            y: 0,                
            aspect: 1,
            width: 400
        }, image.naturalWidth / image.naturalHeight);
        this.setState({
            crop: initialCrop,
        });
    }

    /**
     * Render
     *
     * @returns
     * @memberof CropAvatarForm
     */
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <ReactCrop
                    src={this.props.fileBase64}
                    crop= {this.state.crop }
                    onChange = {this.onChangeCrop}
                    onImageLoaded = {this.onImageLoaded}
                    onComplete={this.onCropComplete}
                >
                </ReactCrop>
            </div>
        );
    }
}
CropForm.propTypes = {
    fileBase64: propTypes.string.isRequired
}

const viewStyles = theme => ({
    root: {
        minWidth: "300px",
        minHeight: "300px"
    }
});

export default withStyles(viewStyles)(CropForm);