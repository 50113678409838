import { fromJS, Map } from 'immutable';

import * as deliveryOrdersAction from './DeliveryOrdersAction';

/** Initial state */
export const deliveryOrdersInitialState = fromJS({
    isLoadingDeliveryOrders: false,
    deliveryOrders: [],
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    deliveryOrdersCount: null,
    //---Single delivery order---
    isLoadingDeliveryOrder: false,
    isSavingDeliveryOrder: false,
    deliveryOrderDraft: null,
    deliveryOrder: null,
    isLoadingPartNumberCodes: false,
    partNumberCodes: [],
    isLoadingPurchaseOrders: false,
    purchaseOrders: [],
    isLoadingPositions: false,
    positions: [],
    isSavingMaterial: false,
    isDonwloadingReport:false,
    //---------------------------
    //---FullCalendarDeliveries--
    deliveryOrdersEvents: [],
    isLoadingDeliveryOrdersEvents: false,
    //---------------------------
    isLoadingCertificate: false,
})

export default function deliveryOrderReducer(state = deliveryOrdersInitialState, action) {
    switch (action.type) {
        case deliveryOrdersAction.IS_LOADING_DELIVERY_ORDERS: {
            return state.merge({
                isLoadingDeliveryOrders: action.isLoadingDeliveryOrders
            })
        }
        case deliveryOrdersAction.SET_DELIVERY_ORDERS_LIST: {
            let deliveryOrdersLists = fromJS(action.deliveryOrders);
            return state.merge({
                deliveryOrders: deliveryOrdersLists,
                isLoadingDeliveryOrders: false
            })
        }
        case deliveryOrdersAction.IS_LOADING_DELIVERY_ORDER: {
            return state.merge({
                isLoadingDeliveryOrder: action.isLoadingDeliveryOrder,
                [action.deliveryOrderType]:
                    action.deliveryOrderType === "deliveryOrderDraft" ?
                        null : state.deliveryOrder
            })
        }
        case deliveryOrdersAction.IS_SAVING_DELIVERY_ORDER: {
            return state.merge({
                isSavingDeliveryOrder: action.isSavingDeliveryOrder,
            })
        }
        case deliveryOrdersAction.SET_DELIVERY_ORDER: {
            return state.merge({
                isLoadingDeliveryOrder: false,
                isSavingDeliveryOrder: false,
                [action.deliveryOrderType]: fromJS(action.deliveryOrder)
            })
        }
        case deliveryOrdersAction.CHANGE_DELIVERY_ORDERS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case deliveryOrdersAction.CHANGE_DELIVERY_ORDERS_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case deliveryOrdersAction.CHANGE_DELIVERY_ORDERS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case deliveryOrdersAction.SET_DELIVERY_ORDERS_COUNT: {
            return state.merge({
                deliveryOrdersCount: action.count
            })
        }
        case deliveryOrdersAction.IS_LOADING_PART_NUMBER_CODES: {
            return state.merge({
                isLoadingPartNumberCodes: action.isLoadingPartNumberCodes,
            })
        }
        case deliveryOrdersAction.SET_PART_NUMBER_CODES: {
            return state.merge({
                isLoadingPartNumberCodes: false,
                partNumberCodes: fromJS(action.partNumberCodes)
            })
        }
        case deliveryOrdersAction.IS_LOADING_SELECTOR_VALUES: {
            let isLoadingSelector = "isLoading" + action.selectorName
            return state.merge({
                [isLoadingSelector]: action.isLoadingSelectorValues,
            })
        }
        case deliveryOrdersAction.SET_SELECTOR_VALUES: {
            let isLoadingSelector = "isLoading" + action.selectorName
            return state.merge({
                [isLoadingSelector]: false,
                [action.propertyName]: fromJS(action.selectorValues)
            })
        }
        case deliveryOrdersAction.IS_SAVING_MATERIAL: {
            return state.merge({
                isSavingMaterial: action.isSavingMaterial,
            })
        }
        case deliveryOrdersAction.IS_DOWNLOADING_REPORT_ORDERS: {
            return state.merge({
                isDonwloadingReport: action.isDonwloading,
            })
        }
        case deliveryOrdersAction.ADD_MATERIAL: {
            let foundMaterial = state.get('deliveryOrderDraft').get('materials').find(item => {
                return item.get('token') === action.material.token
            })
            if (foundMaterial)
                return state.set('isSavingMaterial', false);
            let material = Map(action.material);
            let newList = state.get('deliveryOrderDraft').get('materials').push(material);
            return state.setIn(['deliveryOrderDraft', 'materials'], fromJS(newList))
                .set('isSavingMaterial', false)
        }
        case deliveryOrdersAction.REMOVE_MATERIAL: {
            let oldList = state.getIn(['deliveryOrderDraft', 'materials']).toJS();
            let newList = oldList.filter(item => {
                return item.token !== action.itemToken
            })
            return state.setIn(['deliveryOrderDraft', 'materials'], fromJS(newList))
                .set('isSavingMaterial', false);
        }
        case deliveryOrdersAction.UPDATE_MATERIAL: {
            return state.updateIn([action.deliveryOrderType, 'materials'], (allMaterials) => {
                let index = allMaterials.findIndex((item) => {
                    return item.get('token') === action.material.token
                });
                return allMaterials.setIn([index], fromJS(action.material));
            }).set('isSavingMaterial', false);
        }
        case deliveryOrdersAction.ADD_FILE_INDRAFT_DELIVERY_ORDER: {
            let oldList = state.getIn(['deliveryOrderDraft', 'attachments']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['deliveryOrderDraft', 'attachments'], fromJS(newList));
        }
        case deliveryOrdersAction.REMOVE_INDRAFT_FILE_DELIVERY_ORDER: {
            let oldList = state.getIn(['deliveryOrderDraft', 'attachments']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['deliveryOrderDraft', 'attachments'], fromJS(newList));
        }
        case deliveryOrdersAction.ADD_FILE_DELIVERY_ORDER: {
            let oldList = state.getIn(['deliveryOrder', 'attachments']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['deliveryOrder', 'attachments'], fromJS(newList));
        }
        case deliveryOrdersAction.REMOVE_FILE_DELIVERY_ORDER: {
            let oldList = state.getIn(['deliveryOrder', 'attachments']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['deliveryOrder', 'attachments'], fromJS(newList));
        }
        case deliveryOrdersAction.SET_DELIVERY_ORDERS_EVENTS: {
            let deliveryOrdersEvents = fromJS(action.deliveryOrdersEvents);
            return state.merge({
                deliveryOrdersEvents,
                isLoadingDeliveryOrdersEvents: false
            })
        }
        case deliveryOrdersAction.UPDATE_DELIVERY_IN_DRAFT: {
            let carbonFootprint = action.carbonFootprint;
            let orderTypeTranpsort = carbonFootprint.type_transport;
            let destinationName = carbonFootprint.destination_name;
            let destinationToken = carbonFootprint.destination_token;
            let destionationKilometres = carbonFootprint.destionation_kilometres;
            let freightName = carbonFootprint.freight_name;

            return state.setIn(['deliveryOrderDraft', 'type_transport'], orderTypeTranpsort)
                .setIn(['deliveryOrderDraft', 'destination_name'], destinationName)
                .setIn(['deliveryOrderDraft', 'destination_token'], destinationToken)
                .setIn(['deliveryOrderDraft', 'destionation_kilometres'], destionationKilometres)
                .setIn(['deliveryOrderDraft', 'freight_name'], freightName);
        }
        case deliveryOrdersAction.ADD_INDRAFT_CERTIFICATE_DELIVERY_ORDER: {
            let foundCertificate = state.get('deliveryOrderDraft').get('certificates').find(certificate => {
                return certificate.get('token') === action.certificate.token
            })
            if (foundCertificate){
                return state.updateIn(["deliveryOrderDraft", 'certificates'], (allCertificates) => {
                    let index = allCertificates.findIndex((certificate) => {
                        return certificate.get('token') === action.certificate.token
                    });
                    return allCertificates.setIn([index], fromJS(action.certificate));
                }).set('isSavingDeliveryOrder', false);
            }
            let certificateAdd = fromJS(action.certificate);
            let newList = state.get('deliveryOrderDraft').get('certificates').push(certificateAdd);
            return state.setIn(['deliveryOrderDraft', 'certificates'], fromJS(newList))
                .set('isSavingDeliveryOrder', false)
        }
        case deliveryOrdersAction.UPDATE_INDRAFT_CERTIFICATE_DELIVERY_ORDER:{
            return state.updateIn(["deliveryOrderDraft", 'certificates'], (allCertificates) => {
                let index = allCertificates.findIndex((certificate) => {
                    return certificate.get('token') === action.certificate.token
                });
                return allCertificates.setIn([index], fromJS(action.certificate));
            }).set('isSavingDeliveryOrder', false);
        }
        case deliveryOrdersAction.REMOVE_INDRAFT_CERTIFICATE_DELIVERY_ORDER:{
            let oldList = state.getIn(['deliveryOrderDraft', 'certificates']).toJS();
            let newList = oldList.filter(certificate => {
                return certificate.token !== action.certificateToken
            })
            return state.setIn(['deliveryOrderDraft', 'certificates'], fromJS(newList));
        }
        case deliveryOrdersAction.IS_LOADING_CERTIFICATED: {
            return state.merge({
                isLoadingCertificate: action.isLoadingCertificate
            })
        }
        case deliveryOrdersAction.UPDATE_INVIEW_CERTIFICATE_DELIVERY_ORDER:{
            let canbePrint = action.certificate.can_be_print_order_pdf;
            return state.updateIn(["deliveryOrder", 'certificates'], (allCertificates) => {
                let index = allCertificates.findIndex((certificate) => {
                    return certificate.get('token') === action.certificate.token
                });
                return allCertificates.setIn([index], fromJS(action.certificate));
            }).set('isSavingDeliveryOrder', false)
            .setIn(["deliveryOrder", 'can_be_print_order_pdf'], canbePrint);
        }
        case deliveryOrdersAction.UPDATE_DELIVERY_ARRIVE_TIME:{
            let arrivalTime = action.arrivalTime;
            return state.setIn(["deliveryOrder", "arrival_time"],arrivalTime)
        }
        default:
            return state;
    }
}