/**
 * Define name resources constants
 */
export const ENVIROMENT_TYPE_PRODUCTION = 'https://sip.efemsa.com/v2.0/portal'

export const ACTIVE_DIRECTORY_USER = 'ActiveDirectoryUser'
export const NON_ACTIVE_DIRECTORY_USER = 'NonActiveDirectoryUser'

/** Resoruces string  */

export const SHOW_VIEW = 'SHOW_VIEW'
export const SHOW_EDIT_VIEW = 'SHOW_EDIT_VIEW'

/** Resources get companies to change theme in portal */
export const COMPANY_THEME_ACTIVE = 'themeActive'
export const COMPANY_ACTIVE = 'company'
export const COMPANY_IMBERA = 'IMB'
export const COMPANY_PTM = 'PTM'
export const COMPANY_REPARE = 'CEDIS'
export const COMPANY_METALICOS = 'METALICOS'
export const COMPANY_IMBERA_WARRANTY = 'GARAN'
export const COMPANY_FERSA = 'FERSA'
export const COMPANY_COMITOR = 'COMITOR'
export const COMPANY_TEQMA = 'TEQMA'
export const COMPANY_PACTO = 'PACTO'
export const COMPANY_FABATSA = 'FABATSA'
export const COMPANY_IMBERA_COLOMBIA = 'COL'
export const COMPANY_IMBERA_BRASIL = 'BRA'
export const COMPANY_MAQUINAS_EOS = 'EOS'

export const COMPANY_IDENTIFIER_IMBERA_MX = 'ImberaMX'
export const COMPANY_IDENTIFIER_IMBERA_BRASIL = 'ImberaBrasil'
export const COMPANY_IDENTIFIER_IMBERA_REPARE = 'ImberaService'
export const COMPANY_IDENTIFIER_IMBERA_COLOMBIA = 'ImberaColombia'
export const COMPANY_IDENTIFIER_IMBERA_WARRANTY = 'ImberaWarranty'

export const COMPANY_IDENTIFIER_TEQMA = 'Teqma'
export const COMPANY_IDENTIFIER_PACTO = 'Pacto'
export const COMPANY_IDENTIFIER_FERSA = 'Fersa'
export const COMPANY_IDENTIFIER_FABATSA = 'Fabatsa'
export const COMPANY_IDENTIFIER__METALLIC = 'Metallic'
export const COMPANY_IDENTIFIER__EOS = 'Eos'
export const COMPANY_IDENTIFIER_PTMT = 'PTMT'

export const IS_SUPPLIER_NATIONAL_MX = 'MX'
export const IS_SUPPLIER_MATIONAL_MXP = 'MXP'

/** Status from detail items */
export const APPROVAL_ACEPTED = 'ACCEPTED'
export const APPROVAL_REJECTED = 'REJECTED'
export const APPROVAL_DECLINED = 'DECLINED'
export const APPROVAL_REQUOTE = 'REQUOTE'
export const APPROVAL_UPDATED = 'UPDATED'
export const APPROVAL_PROCESS = 'INPROCESS'
export const APPROVAL_IMPROVE = 'IMPROVE'

export const YEAR_MONTHS = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
]

/** OFFICIAL NOTICE CONST */
export const OFFICIAL_NOTICE_VIDEO = 'video'
export const OFFICIAL_NOTICE_COMMINIQUE = 'comminique'
export const OFFICIAL_NOTICE_IMAGE = 'image'

/** Status from expedient document */
export const DOCUMENT_REQUEST = 1
export const DOCUMENT_ADDED = 2
export const DOCUMENT_ACEPTED = 3
export const DOCUMENT_REJECTED = 4
export const DOCUMENT_WAITING = 5
// DocumentAlreadyValid Esta opcion es para agregar en el portal y validar que ya lo tiene solo para no pedir el doc al proveedor
export const DOCUENT_DOESNOTAPPLY = 6

/** status medical check */
export const MEDICAL_CHECK_VALIDATED = 3
export const MEDICAL_CHECK_REJECYED = 4

/** Documents Contractor */
export const DOCUMENT_CONTRACTOR = 1
export const DOCUMENT_ENVIROMENTAL = 2

/** status TRAINING*/
export const TRAINING_VALIDATED = 4
export const TRAINING_REJECYED = 5
