
/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
/** Material UI components */
import {
    Popper,
    ClickAwayListener,
    Typography,
    Paper,
    Tooltip,
    Divider,
    InputBase,
    Fab,
} from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
/**Import components section */
import ListResponsible from './ListResponsibles'
/**Import resources section */
import {
    COLOR_STATUS_SUCCESS, 
    BACKGROUND_COLOR_GRAY
} from '../../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';

const ResponsiblePopper = (props) => {
    const {
        classes, onAddResponsible, 
        responsiblesAdded, onRemoveResponsible, 
        userProfile
    }= props
    const [anchorEl, setAnchorEl] = useState(null)
    const [allUsersFilters, setAllUsersFilters] = useState([])
    const [isSearch, setIsSearch] = useState(false)
    const translate = useSelector((state) => getTranslate(state.localize))
    /**Use effect section */
    useEffect(() => {
        if(!isSearch){
            setAllUsersFilters(props.users)
        }
    }, [props.users])

    /**Use effect section */
    useEffect(() => {
        setIsSearch(false)
    }, [])
    
    const onMoreIconClick = (event)=>{
        event.stopPropagation();
        setAnchorEl(event.currentTarget)
    }
    const onQueryChange = event => {
        let usersAvancedFilters = props.users
        if(!IsNullOrEmpty(event.target.value)){
            setIsSearch(true)
            let fixedQuery = event.target.value
            usersAvancedFilters =usersAvancedFilters.filter((x) => {
                return (
                    x.user_name.trim().toLowerCase().startsWith(fixedQuery) ||
                    x.name.trim().toLowerCase().includes(fixedQuery)
                )
            })
            setAllUsersFilters(usersAvancedFilters)
        }
        else   {
            setIsSearch(false)
            setAllUsersFilters(props.users)
        } 
    }

    const onClickAwayListener=()=>{
        setAnchorEl(null)
        setIsSearch(false)
        setAllUsersFilters(props.users)
    }

    return (
        <div>
            <Tooltip
                title={<Translate id="biddingsModule.create.addResponsibles" />}
            >
             <Fab 
                    size="small" 
                    color="primary"
                    aria-label="add" 
                    disableFocusRipple
                    className={classes.addResponsibleFab} 
                    onClick={onMoreIconClick}
                >
                    <PersonAddIcon/>
                </Fab>
            </Tooltip>

            <Popper disablePortal anchorEl={anchorEl} open={anchorEl !== null} className={classes.popper}>
                <ClickAwayListener onClickAway={onClickAwayListener}>
                    <Paper className={classes.papper} elevation={10}>
                    <strong><Typography color="primary" align="center" variant="subtitle1"><Translate id="biddingsModule.create.addResponsibles" /></Typography></strong>
                        <Divider />
                        <div 
                            component="form" 
                            className={classes.searchBoxContainer}
                        >
                            <InputBase
                                className={classes.input}
                                placeholder={translate("biddingsModule.searchResponsible")}
                                inputProps={{ 'aria-label': 'search google maps' }}
                                onChange={onQueryChange}
                            />
                        </div>
                        <Divider />
                        <ListResponsible
                            users={allUsersFilters}
                            responsiblesAdded={responsiblesAdded}
                            onAddResponsible={onAddResponsible}
                            onRemoveResponsible={onRemoveResponsible}
                            userProfile={userProfile}
                        
                        />
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div >
    )
}

let useStyle = ((theme) => ({
    addResponsibleFab: {
        backgroundColor: COLOR_STATUS_SUCCESS,
        color: 'white',
        width:"35px !important",
        height:"35px !important",
        "&.MuiFab-sizeSmall": {
            width:"35px !important",
            height:"35px !important"
        },
        "&.Mui-disabled": {
            backgroundColor: COLOR_STATUS_SUCCESS,
            color: 'white',
            opacity: .75,
        },
        "&:hover": {
            backgroundColor: COLOR_STATUS_SUCCESS
        }
    },
    root: {
        padding: "10px",
        width: "250px",
        marginTop: "13px",
        backgroundColor: "white",
    },
    popper: {
        zIndex: 9999
    },
    searchBoxContainer: {
        width: "100%",
    },
    input: {
        marginTop: "2px",
        width: "100%",
        textAlign: "center",
        padding: '6px 18px',
        border: "1px solid #545CD"
    },
    papper:{
        zIndex: 9999,
        width: "350px",
        padding:"10px",
        backgroundColor:BACKGROUND_COLOR_GRAY
    }
}))

ResponsiblePopper.propTypes = {
    /**
     * This array determinate options render in the list for users responsibles
     */
    users: propTypes.array.isRequired
}
ResponsiblePopper.defaultProps = {
    users: [],
}

export default (withStyles(useStyle, { withTheme: true }) (ResponsiblePopper))