import { fromJS } from 'immutable';

import * as contactSupplier from './ContactSupplierAction';

/**
 *  Initial state
 */
export const contactSupplierInitialState = fromJS({
    isLoadingContactSuppliers: true,
    contactSuppliers: null,
    contactSupplierData: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    contactSupplierCount: 0,
    isLoadingcontactSupplier: false,
    contactSupplier: null,
    isSavingContactSupplier: false,
    contactsListUpdate: null,
})

export default function moduleContactSupplierReducer(state = contactSupplierInitialState, action) {
    switch (action.type) {
        case contactSupplier.IS_LOADING_CONTACT_SUPPLIERS_INDEX: {
            return state.merge({
                isLoadingContactSuppliers: action.isLoadingIndex
            })
        }
        case contactSupplier.SET_CONTACT_SUPPLIERS_LIST: {
            return state.merge({
                contactSuppliers: fromJS(action.contactSuppliersList),
                isLoadingContactSuppliers: false
            })
        }
        case contactSupplier.SET_CONTACT_SUPPLIERS_COUNT: {
            return state.merge({
                contactSupplierCount: action.contactSuppliersCount
            })
        }
        case contactSupplier.CHANGE_CONTACT_SUPPLIERS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case contactSupplier.CHANGE_CONTACT_SUPPLIERS_SORT_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case contactSupplier.CHANGE_CONTACT_SUPPLIERS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case contactSupplier.IS_LOADING_CONTACT_SUPPLIER: {
            return state.merge({
                isLoadingcontactSupplier: action.isLoadingContactSupplier
            })
        }
        case contactSupplier.SET_CONTACT_SUPPLIER: {
            return state.merge({
                isLoadingcontactSupplier: false,
                contactSupplier: fromJS(action.contactSupplier)
            })
        }
        case contactSupplier.IS_SAVING_CONTACT_SUPPLIER:{
            return state.merge({
                isSavingContactSupplier: action.isSaving
            })
        }
        case contactSupplier.SET_CONTACTS_LIST_TO_UPDATE:{
            return state.merge({
                isLoadingContactSuppliers: false,
                contactsListUpdate: fromJS(action.contactsList)
            })
        }
        case contactSupplier.REMOVE_CONTACT_UPDATE_SUPPLIER: {
            return state.updateIn(['contactsListUpdate'], (allContacts) => {
                let index = allContacts.findIndex((contact) => {
                    return contact.get('token') === action.contactToken
                });
                return allContacts.delete(index).set('isSavingContactSupplier', false);
            })
        }
        default:
            return state;
    }

}