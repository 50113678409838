import { fromJS } from 'immutable'

import moment from 'moment'
import * as loadActions from './LoadAlertAction'

export const loadAlertInitialState = fromJS({
	isLoading: false,
	loadAlerts: null,
	trackings: [],
	loadAlert: null,
	sortCriteria: {
		by: 'CreationDate',
		ascending: false,
	},
	page: 0,
	rowsPerPage: 50,
	searchQuery: '',
	loadCount: null,
	trackingCount: 0,
	isSaving: false,
	identifier: '',
	startDate: moment().subtract(20, 'days'),
	endDate: moment(),
})

export default function loadReducer(state = loadAlertInitialState, action) {
	switch (action.type) {
		case loadActions.SET_LOAD_ALERT_LIST: {
			let loadAlertsList = fromJS(action.loadAlerts)
			return state.merge({
				loadAlerts: loadAlertsList,
				isLoading: false,
			})
		}
		case loadActions.SET_TRACKING_LIST: {
			let trackingsList = fromJS(action.trackings)
			return state.merge({
				trackings: trackingsList,
				isLoading: false,
			})
		}
		case loadActions.SET_LOAD_ALERT: {
			let loadAlertList = fromJS(action.loadAlert)
			return state.merge({
				loadAlert: loadAlertList,
				isLoading: false,
			})
		}
		case loadActions.IS_SAVING: {
			return state.merge({
				isSaving: action.isSaving,
				identifier: action.identifier,
			})
		}
		case loadActions.SET_LOAD_ALERT_COUNT: {
			return state.merge({
				loadCount: action.count,
			})
		}
		case loadActions.SET_TRACKING_COUNT: {
			return state.merge({
				trackingCount: action.count,
			})
		}
		case loadActions.START_LOADING_LOAD_ALERTS: {
			return state.merge({
				isLoading: action.isLoading,
			})
		}
		case loadActions.CHANGE_LOAD_ALERT_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
			})
		}
		case loadActions.CHANGE_LOAD_ALERT_START_DATE: {
			console.log("🚀 ~ loadReducer ~ action.startDate:", action.startDate)
			return state.merge({
				startDate: action.startDate,
				page: 0,
			})
		}
		case loadActions.CHANGE_LOAD_ALERT_END_DATE: {
			return state.merge({
				endDate: action.endDate,
				page: 0,
			})
		}
		case loadActions.CHANGE_LOAD_ALERTS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case loadActions.ADD_FILES_LOAD_ALERT: {
			let oldList = state.getIn(['loadAlert', action.listItems]).toJS()
			let filesToAdda = action.files.filter((newFileSupl) => {
				return !oldList.find((uploadedFile) => {
					return uploadedFile.name === newFileSupl.name
				})
			})
			let newList = [...oldList, ...filesToAdda]
			return state.setIn(['loadAlert', action.listItems], fromJS(newList))
		}
		default:
			return state
	}
}
