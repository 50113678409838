/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles, Divider} from "@material-ui/core";
import { Typography} from "@material-ui/core";
import { Translate } from "react-localize-redux";

/**Import components section*/
import Certificate from '../../../resources/images/certificate.png'
import WorkerMan from '../../../resources/images/WorkerMan.png'
import WorkerWoman from '../../../resources/images/WorkerWoman.png'

/**Import resources section*/
import { COLOR_STATUS_DANGER, COLOR_STATUS_SUCCESS,
  BACKGROUND_COLOR_GRAY_BIDDING, COLOR_STATUS_BLUE } from '../../../store/helpers/StatusColorConstants';
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";

/**Import actions section */
const PanelRight = (props) => {
  const { classes, totalRatingSupplier, totalWorkDetail,
    isSummary, isApproved} = props;

  return (
    <div className={classes.root}>
      <div className={isSummary ? classes.ratingCardSummary : classes.ratingCard}>
        <CircularProgressbarWithChildren 
          value={totalRatingSupplier}
          styles={buildStyles({
          pathColor: isApproved ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER,
          })}>                  
          <Typography style={{fontSize: '24px'}}>{`${totalRatingSupplier}%`}</Typography>
        </CircularProgressbarWithChildren>
        <div className={classes.title}>
          <Typography style={{color: isApproved ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER, textAlign: 'center'}}>
            <strong>{isApproved ? <Translate id='sustainability.isApproved'/> : <Translate id='sustainability.notApproved'/>}</strong> 
          </Typography>
        </div>
      
        <div className={classes.information}>
          <Typography variant='caption' color='primary'>
            {<Translate id='sustainability.totalWorkers'/>}
          </Typography>
          <Typography variant='caption'>{
            totalWorkDetail.TotalAdministrativeWoman+
            totalWorkDetail.TotalAdministrativeMen+
            totalWorkDetail.TotalOperationalWoman+
            totalWorkDetail.TotalOperationalMen}
          </Typography>
          
          <Divider className={classes.dividerLineBlue} />
          <img src={WorkerWoman} alt="workerWoman" />
          <div><Typography variant='caption' color='primary'>{<Translate id='sustainability.workers.administrative'/>}</Typography></div>
          <div><Typography variant='caption'>{totalWorkDetail.TotalAdministrativeWoman}</Typography></div>
          <div><Typography variant='caption' color='primary'>{<Translate id='sustainability.workers.operational'/>}</Typography></div>
          <div><Typography variant='caption'>{totalWorkDetail.TotalOperationalWoman}</Typography></div>

          <Divider className={classes.dividerLineBlue} />
          <img src={WorkerMan} alt="workerMan" />
          <div><Typography variant='caption' color='primary'>{<Translate id='sustainability.workers.administrative'/>}</Typography></div>
          <div><Typography variant='caption'>{totalWorkDetail.TotalAdministrativeMen}</Typography></div>
          <div><Typography variant='caption' color='primary'>{<Translate id='sustainability.workers.operational'/>}</Typography></div>
          <div><Typography variant='caption'>{totalWorkDetail.TotalOperationalMen}</Typography></div>

        </div>

        <div className={classes.centerPlace}>
          <img src={Certificate} alt="certificate" className={classes.certificateImg}/>
        </div>
      </div> 
    </div>
  )
};

const styles = (theme) => ({
    root:{
        display: "flex",
        width: "100%",
    },
    ratingCard:{
      backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
      padding: "15px",
      height: "640px",
      borderRadius: '15px',
      width: "120px",
    },
    title:{
      marginTop: '15px',
    },
    information: {
      textAlign:'center',
      marginTop: "15px",
      '& > *': {
        marginRight: '5px'
    }},
    dividerLineBlue: {
      marginTop: "15px",
      marginBottom: "15px",
      backgroundColor: COLOR_STATUS_BLUE,
      height: '2px'
    },
  certificateImg: {
    width: "90%",
    marginTop: "20px",
    paddingTop: "0px"
  },
  centerPlace: {
    textAlign: 'center',
  },
  /* SUMMARY STYLES */
  ratingCardSummary:{
    backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
    padding: "15px",
    marginTop: '3px',
    height: "650px",
    borderRadius: '15px',
    width: "120px",
  },
  centerPlaceSummary: {
    flex: '4 1 0',
    height: '240px',
    textAlign: 'center',
  },
  certificateImgSummary: {
    width: "35%",
    marginTop: "10px",
  },
  downloadIcon: {
    marginRight: '6px', 
    marginBottom: '4px'
  },
  certificateSummary: {
    textAlign: 'center',
    color: 'gold',
    fontWeight: 'bold',
    fontSize: '12px',
    marginTop: '3px',
    marginBottom: '3px',
  },
});

PanelRight.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  isLoading: PropTypes.bool.isRequired,
  isSummary: PropTypes.bool,
  totalRatingSupplier: PropTypes.number, 
  totalWorkDetail: PropTypes.object,
  canBeActiveResult: PropTypes.bool,
  isApproved: PropTypes.bool,
  sustainability: PropTypes.object,
};

PanelRight.defaultProps = {
  isLoading: false,
  isSummary: false,
  totalRatingSupplier: 0,
  totalWorkDetail: {},
  canBeActiveResult: false,
  isApproved: false,
  sustainability: {},
};

export default withRouter(
  withStyles(styles, { withTheme: true })(PanelRight)
);
