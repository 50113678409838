import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import moment from 'moment'

/** Import component section */
import '../../resources/styles/autosuggest.css'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import Toaster from '../common/Toaster';
import SuppliersCard from './components/SuppliersCard';
import UserCard from './components/UserCard';

/** Translations imports section */
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

/** Import section actions */
import { searchBySuppliersByQury } from '../../store/suppliers/SupplierActions';
import { createOfficeNoticeData } from '../../store/administration/OfficialNoticeActions';


/**
 * Container to Create user
 */
class Create extends Component {

    /**
   * Creates an instance of View.
   * @param {*} props
   * @memberof View
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            notice: {
                attachments: [],
                activationDate: "",
                expirationDate: "",
                suppliers: [],
                notice_type: "",
                notes: "",
                acceptedBySupplier: false,
                optionAll: true,
                optionNationals: false,
                optionForeigns: false,
                option: "optionAll",
                optionByTemplate: false,
                templates: [],
            }
        }
    }

    /**
     * Function to validate which check is enable
     *
     * @param {*} noticeUpdate
     * @param {*} property
     * @param {*} value
     * @returns
     * @memberof Create
     */
    validateChecks(noticeUpdate, property, value) {
        if (property === 'optionNationals' && value) {
            noticeUpdate['optionAll'] = false;
            noticeUpdate['optionForeigns'] = false;
        } else if (property === 'optionForeigns' && value) {
            noticeUpdate['optionAll'] = false;
            noticeUpdate['optionNationals'] = false;
        } else if (property === 'optionAll' && value) {
            noticeUpdate['optionNationals'] = false;
            noticeUpdate['optionForeigns'] = false;
        }
        noticeUpdate[property] = value;
        if (!noticeUpdate['optionNationals']
            && !noticeUpdate['optionForeigns'])
            noticeUpdate['optionAll'] = true;

        return noticeUpdate
    }
    /**
    * Update the properties in notice in state
    *
    * @param {*} user
    * @param {*} value
    * @memberof View
    */
    updateProperty(property, value) {
        let noticeUpdate = { ...this.state.notice };
        if (property === 'optionNationals'
            || property === 'optionForeigns'
            || property === 'optionAll')
            noticeUpdate = this.validateChecks(noticeUpdate, property, value)
        else
            noticeUpdate[property] = value;
        this.setState({
            notice: noticeUpdate
        })
    }

    /**
     * Update the suppliers list
     *
     * @param {*} user
     * @param {*} value
     * @memberof View
     */
    updateSuppliersList(value) {
        let noticeUpdate = { ...this.state.notice };
        if (value !== null && value !== undefined) {
            if (!noticeUpdate['suppliers'].find(supplier => { return supplier.token === value.token })) {
                if (value.full_name) {
                    let supplier = { name: value.full_name, token: value.token };
                    noticeUpdate['suppliers'] = noticeUpdate['suppliers'].concat(supplier);
                    this.setState({
                        notice: noticeUpdate
                    })
                }
                else {
                    let supplier = { name: value.name, token: value.token };
                    noticeUpdate['suppliers'] = noticeUpdate['suppliers'].concat(supplier);
                    this.setState({
                        notice: noticeUpdate
                    })
                }
            } else {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.repeatedSelection" />,
                    toasterVariant: "info"
                })
            }
        }
    }

    /**
     * Update the suppliers list
     *
     * @param {*} user
     * @param {*} value
     * @memberof View
     */
    removeSupplierFromList(supplierToken) {
        let noticeUpdate = { ...this.state.notice };
        let newSuppliersList = noticeUpdate['suppliers'].filter(supplier => {
            return supplier.token !== supplierToken
        })
        noticeUpdate['suppliers'] = newSuppliersList;
        this.setState({
            notice: noticeUpdate
        })
    }

    /**
     * Remove files
     */
    removeFiles(fileName) {
        let attachments = this.state.notice.attachments.filter(file => {
            return file.name !== fileName
        })
        this.setState({
            notice: {
                ...this.state.notice,
                attachments
            }
        })
        return Promise.resolve('Ok')
    }

    /**
     * Add file
     * @param {*} attachments 
     */
    uploadFiles(files) {
        let filesList = this.state.notice.attachments;
        if (files.length > 0) {
            for (var index = 0; index < files.length; index++) {
                filesList.push(files[index])
            }
        }
        this.setState({
            notice: {
                ...this.state.notice,
                attachments: filesList
            }
        })
        return Promise.resolve('Ok')
    }

           /**
     * Remove files
     */
    removeTemplate(fileName) {
        let templates = this.state.notice.templates.filter(file => {
            return file.name !== fileName
        })
        this.setState({
            notice: {
                ...this.state.notice,
                templates
            }
        })
        return Promise.resolve('Ok')
    }

    /**
     * Add file
     * @param {*} tempaltes 
     */
    uploadTemplate(files) {
        let filesList = this.state.notice.templates;
        if (files.length > 0) {
            for (var index = 0; index < files.length; index++) {
                filesList.push(files[index])
            }
        }
        this.setState({
            notice: {
                ...this.state.notice,
                templates: filesList
            }
        })
        return Promise.resolve('Ok')
    }

    /**
     * Action to can be  information
     */
    canBeSaved() {
        if (this.props.isSaving)
            return false;
        else {
            let notice = this.state.notice;
            if (notice.activationDate.length === 0)
                return false;
            if (notice.expirationDate.length === 0)
                return false;
            if (moment(notice.expirationDate).isBefore(notice.activationDate))
                return false;
            if(notice.optionByTemplate){
                if (notice.templates.length <= 0)
                    return false;
            }
            else{
                if (notice.attachments.length <= 0)
                    return false;
                if (notice.suppliers.length <= 0)
                    return false;
            }
        }
        return true;
    }

    /**
     * Function to create worker
     * @param {*} worker 
     */
    onCreateNoticeOffice() {
        this.props.createOfficeNoticeData(this.state.notice)
            .then((officalNoticeToken) => {
                this.setState({
                    showAddWorker: false,
                    showToView: true,
                    showToaster: true,
                    toasterMessage: <Translate id="common.addCorrectly" />,
                    toasterVariant: "success"
                })
                this.props.history.push(`/officialnotices/${officalNoticeToken}`);
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToAdd" />,
                    toasterVariant: "error",
                })
            });
    }


    /**
     * Render show 
     */
    render() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={6}>
                    <UserCard
                        updateProperty={this.updateProperty}
                        notice={this.state.notice}
                        onCreateNoticeOffice={this.onCreateNoticeOffice}
                        canBeSaved={this.canBeSaved()}
                        uploadFiles={this.uploadFiles}
                        removeFiles={this.removeFiles}
                        dropzoneText={this.props.translate('dropzone.dropzoneBaseText')}
                        dropzoneVideo={this.props.translate('dropzone.dropzoneVideoFiles')}
                        {...this.props}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SuppliersCard
                        notice={this.state.notice}
                        updateProperty={this.updateProperty}
                        updateSuppliersList={this.updateSuppliersList}
                        suppliers={this.state.notice.suppliers}
                        removeSupplier={this.removeSupplierFromList}
                        dropzoneText={this.props.translate('dropzone.dropzoneBaseText')}
                        uploadTemplate={this.uploadTemplate}
                        removeTemplate={this.removeTemplate}
                        {...this.props}
                    />
                </Grid>

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Grid>
        )

    }
}


/**
 * Map state to props mapStateToProps
 * @param {*} state 
 */
function mapStateToProps(state) {
    let selectedCompanyIdentifier = state.profile.get('selectedCompany').get('get_company_identifier')

    return {
        selectedCompanyIdentifier: selectedCompanyIdentifier ? selectedCompanyIdentifier : null,
        isLoadingUser: state.oidc.isLoadingUser,
        isLoadingSuppliers: state.suppliers.get("isLoadingSuppliers"),
        isSaving: state.officialNotices.get('isSaving'),
        categories: state.officialNotices.get('categories') ? state.officialNotices.get('categories').toJS() : null,
        translate: getTranslate(state.localize)
    }
}

/**
 * mapDispatchToProps
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        searchBySuppliersByQury: (query) => {
            dispatch(searchBySuppliersByQury(query));
        },
        createOfficeNoticeData: (notice) => {
            return dispatch(createOfficeNoticeData(notice));
        },

    }
}

const viewStyles = theme => ({
    userAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 240,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    formatCheck: {
        display: 'flex',
        marginLeft: theme.spacing.unit,
        width: 460,
    },
});

export default withauthenticatedLayout(withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create))));