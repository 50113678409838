/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/**Import MaterialUi section */
import {
    ListItemAvatar, List, Avatar, ListItemText,
    ListItem, IconButton, ListItemSecondaryAction, Divider, ListSubheader
} from '@material-ui/core'
import FileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
/**Import components section */
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';

const ListAttachments = (props) => {

    /**props section */
    const { isSaving, attachments, classes, onDelete } = props

    return (
        <List subheader={<ListSubheader><Translate id="common.savedFiles" /></ListSubheader>} className={classes.root}>
            {
                attachments.map(((atachment, index) => {
                    return (
                        <div key={index}>
                            <ListItem >
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar} size="small">
                                        <FileIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={atachment.Name}
                                // secondary={secondary ? 'Secondary text' : null}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" disabled={isSaving} onClick={() => onDelete(atachment)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </div>
                    )
                }))
            }
        </List>
    )
}

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    }


});

ListAttachments.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onValidateTemplate: propTypes.func
}

ListAttachments.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onValidateTemplate: () =>
        console.warn('Callback [onValidateTemplate] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(ListAttachments))