import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'auto-bind'
import moment from 'moment'
import {
	List,
	ListItem,
	ListItemText,
	TextField,
	ListSubheader,
	Grid,
	withStyles,
	Typography,
	Tooltip,
	IconButton,
} from '@material-ui/core'
import { BACKGROUND_COLOR_GRAY_DARK, COLOR_STATUS_INFO } from '../../../store/helpers/StatusColorConstants'
import { Translate } from 'react-localize-redux'
import NoIcon from '@material-ui/icons/Clear'
import YesIcon from '@material-ui/icons/Check'
import { FormControlLabel, Checkbox, FormGroup } from '@material-ui/core'

import { onKeyDown } from '../../../store/helpers/EventsHelper'
import Evidence from '../../common/Evidence'

/**
 * Class component to show question section
 *
 * @class QuestionsList
 * @extends {PureComponent}
 */
class QuestionsList extends PureComponent {
	/**
	 *Creates an instance of QuestionsList.
	 * @param {*} props
	 * @memberof QuestionsList
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {}
	}

	/**
	 * Method to render buttop option
	 *
	 * @param {*} title
	 * @param {*} optionValue
	 * @param {*} key
	 * @param {*} item
	 * @param {*} listName
	 * @param {*} isDisabled
	 * @returns
	 * @memberof QuestionsList
	 */
	renderOptionButton(sectionToken, title, optionValue, key, item, isDisabled) {
		let xsButton = {
			maxWidth: 25,
			maxHeight: 25,
			cursor: isDisabled ? 'context-menu' : 'pointer',
			backgroundColor: item[key] === optionValue ? COLOR_STATUS_INFO : BACKGROUND_COLOR_GRAY_DARK,
		}
		let fontColor = item[key] === optionValue ? '#fff' : '#000'

		return (
			<Grid item>
				<Tooltip title={this.props.translate(title)}>
					<IconButton
						aria-label='More'
						value={optionValue}
						style={xsButton}
						onClick={(event) =>
							!isDisabled && this.props.updateProperty(sectionToken, key, event.currentTarget.value, item)
						}
					>
						{optionValue === '1' && (
							<YesIcon style={{ paddingTop: '2px', width: '.7em', height: '.7em', color: fontColor }} />
						)}
						{optionValue === '2' && (
							<NoIcon style={{ paddingTop: '2px', width: '.7em', height: '.7em', color: fontColor }} />
						)}
						{optionValue === '3' && (
							<Typography style={{ paddingTop: '2px', fontSize: '.5em', color: fontColor }}>
								{'N/A'}
							</Typography>
						)}
					</IconButton>
				</Tooltip>
			</Grid>
		)
	}

	/**
	 * Method to render section of options
	 * @param {*} sectionToken
	 * @param {*} key
	 * @param {*} item
	 * @param {*} isDisabled
	 * @param {*} size
	 */
	renderOptionsButtons(sectionToken, key, item, isDisabled, size, justification) {
		return (
			<Grid item xs={12} sm={12} md={size} lg={size} xl={size} justify={justification}>
				{!isDisabled && (
					<Grid container spacing={0} justify='space-evenly'>
						{this.renderOptionButton(sectionToken, 'sustainability.options.1', '1', key, item, isDisabled)}
						{this.renderOptionButton(sectionToken, 'sustainability.options.2', '2', key, item, isDisabled)}
						{item.is_question_with_na &&
							this.renderOptionButton(
								sectionToken,
								'sustainability.options.3',
								'3',
								key,
								item,
								isDisabled
							)}
					</Grid>
				)}
				{isDisabled && (
					<Typography align='center' variant='caption'>
						{(item[key] === null || item[key] === 0) && <Translate id='common.withoutData' />}
						{item[key] &&
							item[key] !== null &&
							item[key] !== 0 &&
							this.renderOptionButton(
								sectionToken,
								`sustainability.options.${item[key]}`,
								item[key],
								key,
								item,
								true
							)}
					</Typography>
				)}
			</Grid>
		)
	}

	/**
	 * Method to render Text Field input
	 * @param {*} sectionToken
	 * @param {*} key
	 * @param {*} item
	 * @param {*} isDisabled
	 */
	renderTextArea(sectionToken, key, item, isDisabled, size = 6, align = 'center') {
		return (
			<Grid item xs={12} sm={12} md={size} lg={size} xl={size}>
				{!isDisabled && (
					<TextField
						autoFocus={key === 'name'}
						key={key}
						fullWidth
						defaultValue={item[key] ? item[key] : ''}
						onBlur={(event) => {
							this.props.updateProperty(sectionToken, key, event.target.value, item)
						}}
						onKeyDown={(event) => onKeyDown(event)}
						margin='dense'
						disabled={isDisabled}
						inputProps={{
							style: { fontSize: 12 },
						}}
						multiline
					/>
				)}
				{isDisabled && (
					<Typography align={align} variant='caption'>
						{item[key] ? item[key] : ''}
					</Typography>
				)}
			</Grid>
		)
	}

	/**
	 * Method to render Title section
	 *
	 * @param {*} translationId
	 * @param {*} size
	 * @returns
	 * @memberof QuestionsList
	 */
	renderTitleSection(translationId, size) {
		return (
			<Grid item xs={12} sm={12} md={size} lg={size} xl={size}>
				<Typography align='center' variant='overline'>
					<Translate id={translationId} />
				</Typography>
			</Grid>
		)
	}

	/**
	 * Handle event upload files of devidence componet to add files to list
	 *
	 * @param {*} files
	 * @returns
	 * @memberof AddActionToListDialog
	 */
	uploadFiles(sectionToken, questionToken, files) {
		this.props.uploadFilesInQuestion(sectionToken, questionToken, files)
		return Promise.resolve('Ok')
	}

	/**
	 * Handle event remove files of evidence componet to remove files of list
	 *
	 * @param {*} fileName
	 * @returns
	 * @memberof AddActionToListDialog
	 */
	removeFiles(sectionToken, questionToken, fileName) {
		this.props.removeFilesInQuestion(sectionToken, questionToken, fileName)
		return Promise.resolve('Ok')
	}

	/**
	 * Main method to render QuestionsList
	 *
	 * @returns
	 * @memberof QuestionsList
	 */
	render() {
		const {
			canEditSupplier,
			canEditCommentsInPlant,
			canEditCommentsInAudit,
			sectionQuestionnaire,
			updateOptionMuptiple,
		} = this.props
		let sizeSupplierResponse = this.props.isDisabledQuestionMandatory ? 3 : 3
		return (
			<List
				key={sectionQuestionnaire.short_name}
				dense
				subheader={
					<ListSubheader
						sticky='true'
						color='default'
						style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}
					>
						<Grid container spacing={0}>
							<Grid item xs={5} sm={12} md={6} lg={4} xl={5}>
								<Typography variant='button'>{sectionQuestionnaire.description}</Typography>
							</Grid>
							<Grid item xs={7} sm={12} md={6} lg={8} xl={7}>
								<Grid container spacing={0} justify='flex-end' alignItems='center'>
									{!sectionQuestionnaire.is_section_multiple &&
										this.renderTitleSection('sustainability.supplierAnswer', 2)}
									{this.renderTitleSection('common.observationsSuppliers', 2)}
									{!sectionQuestionnaire.is_section_multiple &&
										this.renderTitleSection('common.auditAnswer', 2)}
									{this.renderTitleSection('common.observationsAudit', 2)}
									{this.renderTitleSection('common.observationsPlant', 2)}
								</Grid>
							</Grid>
						</Grid>
					</ListSubheader>
				}
			>
				{/** Section with question option selected (si / no / na) */}
				{!sectionQuestionnaire.is_section_multiple &&
					sectionQuestionnaire.section_questions.map((question, index) => {
						return (
							<ListItem key={question.token} dense divider>
								<Grid container spacing={0}>
									<Grid item xs={4} sm={12} md={6} lg={5} xl={6}>
										<ListItemText>
											<strong>
												{index + 1}
												{'. '}
											</strong>
											{question.name}
											{question.can_be_add_attachemts && (
												<Evidence
													isDisabled={!canEditSupplier}
													text={this.props.dropzoneText}
													baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
													removeFiles={(fileName) =>
														this.removeFiles(
															sectionQuestionnaire.token,
															question.token,
															fileName
														)
													}
													uploadFiles={(files) =>
														this.uploadFiles(
															sectionQuestionnaire.token,
															question.token,
															files
														)
													}
													files={question.attachments}
												/>
											)}
											{question.attachments.length !== 0 && question.file_date && this.props.isActiveInternalUser && (
												<Typography color='primary'>
													{'Fecha en que el proveedor subio su documento: '}
													{moment(question.file_date).format('DD/MM/YYYY')}
												</Typography>
											)}
										</ListItemText>
									</Grid>
									<Grid item xs={8} sm={12} md={6} lg={7} xl={6}>
										<Grid container spacing={16} justify='center'>
											{this.renderOptionsButtons(
												sectionQuestionnaire.token,
												'response_supplier',
												question,
												!canEditSupplier,
												sizeSupplierResponse
											)}
											{this.renderTextArea(
												sectionQuestionnaire.token,
												'commnets',
												question,
												!canEditSupplier,
												2,
												'center'
											)}
											{this.renderOptionsButtons(
												sectionQuestionnaire.token,
												'response_audit',
												question,
												!canEditCommentsInAudit,
												sizeSupplierResponse
											)}
											{this.renderTextArea(
												sectionQuestionnaire.token,
												'comments_audit',
												question,
												!canEditCommentsInAudit,
												2,
												'center'
											)}
											{this.renderTextArea(
												sectionQuestionnaire.token,
												'comments_in_plant',
												question,
												!canEditCommentsInPlant,
												2,
												'center'
											)}
										</Grid>
									</Grid>
								</Grid>
							</ListItem>
						)
					})}

				{/** Section with question option multiples on open */}
				{sectionQuestionnaire.is_section_multiple &&
					sectionQuestionnaire.section_questions.map((question, index) => {
						return (
							<ListItem key={question.token} dense divider>
								<Grid container spacing={0}>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<ListItemText>
											<strong>
												{index + 1}
												{'. '}
											</strong>
											{question.name}
											<div>
												{question.question_type === 2 && (
													<FormGroup row>
														{question.multiple_options.map((option, index) => {
															return (
																<FormControlLabel
																	key={option.token}
																	control={
																		<Checkbox
																			checked={option.is_selected}
																			onChange={(event) => {
																				updateOptionMuptiple(
																					sectionQuestionnaire.token,
																					question.token,
																					option.token,
																					'is_selected',
																					event.target.checked
																				)
																			}}
																		/>
																	}
																	disabled={!canEditSupplier}
																	label={
																		<Typography
																			variant='body2'
																			color='textSecondary'
																		>
																			{option.option_mame}
																		</Typography>
																	}
																/>
															)
														})}
													</FormGroup>
												)}
												{question.question_type === 1 &&
													this.renderTextArea(
														sectionQuestionnaire.token,
														'commnets',
														question,
														!canEditSupplier,
														10,
														'left'
													)}
												{question.question_type === 1 &&
													this.renderTextArea(
														sectionQuestionnaire.token,
														'comments_audit',
														question,
														!canEditCommentsInAudit,
														10,
														'left'
													)}
												{question.question_type === 1 &&
													this.renderTextArea(
														sectionQuestionnaire.token,
														'comments_in_plant',
														question,
														!canEditCommentsInPlant,
														10,
														'left'
													)}
											</div>
										</ListItemText>
									</Grid>
									{question.question_type !== 1 && (
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<br />
											<Grid container spacing={16} justify='flex-end' alignItems='flex-end'>
												{this.renderTextArea(
													sectionQuestionnaire.token,
													'commnets',
													question,
													!canEditSupplier,
													4
												)}
												{this.renderTextArea(
													sectionQuestionnaire.token,
													'comments_audit',
													question,
													!canEditCommentsInAudit,
													4
												)}
												{this.renderTextArea(
													sectionQuestionnaire.token,
													'comments_in_plant',
													question,
													!canEditCommentsInPlant,
													4
												)}
											</Grid>
										</Grid>
									)}
								</Grid>
							</ListItem>
						)
					})}
			</List>
		)
	}
}

QuestionsList.propTypes = {
	sectionQuestionnaire: PropTypes.object.isRequired,
	canEditSupplier: PropTypes.bool,
	translate: PropTypes.func,
}

const styles = (theme) => ({
	textField: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit,
	},
})

export default withStyles(styles, { withTheme: true })(QuestionsList)
