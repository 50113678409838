import { fromJS } from 'immutable'

import * as controlCo2Actions from './controlCO2Action'

export const controlCO2Initialstate = fromJS({
	isLoanding: false,
	requestsCount: 0,
	isSaving: false,
	request: null,
	service: null,
	vehicles: [],
	services: [],
	requests: [],
	scheduledVehicleControls: [],
	toasterConfiguration: {
        showToaster: false,
        toasterMessage: null,
        toasterVariant: "",
        name: "",
    },
})

export default function controlCO2Reducer(state = controlCO2Initialstate, action) {
	switch (action.type) {
		case controlCo2Actions.IS_LOANDING_CONTROLCO2: {
			return state.merge({
				isLoanding: action.isLoanding,
			})
		}
		case controlCo2Actions.SET_REQUESTS_COUNT: {
			return state.merge({
				requestsCount: action.count,
			})
		}
		case controlCo2Actions.REMOVE_CONTROL: {
			return state.updateIn(['requests'], (requests) =>
				requests.filter((v) => v.get('token') !== action.controlRequestToken)
			)
		}
		case controlCo2Actions.SET_REQUESTS_LIST: {
			return state.merge({
				requests: fromJS(action.requests),
				isLoading: false,
				identifier: '',
			})
		}
		case controlCo2Actions.EDIT_CONTROLCO2_REQUEST: {
			const requestsIndex = state.getIn(['requests']).findIndex((v) => v.get('token') === action.request.token)
			return requestsIndex !== -1 ? state.setIn(['requests', requestsIndex], fromJS(action.request)) : state
		}
		case controlCo2Actions.SET_SERVICE: {
			return state.merge({
				service: fromJS(action.service),
				isLoading: false,
				identifier: '',
			})
		}
		case controlCo2Actions.IS_SAVING_COTROL: {
			return state.merge({
				isSaving: action.isSaving,
			})
		}
		case controlCo2Actions.SET_VEHICLES: {
			return state.set('vehicles', fromJS(action.vehicles))
		}
		case controlCo2Actions.ADD_VEHICLE: {
			return state.updateIn(['vehicles'], (vehicles) => vehicles.push(fromJS(action.vehicle)))
		}
		case controlCo2Actions.EDIT_VEHICLE: {
			const vehicleIndex = state.getIn(['vehicles']).findIndex((v) => v.get('token') === action.vehicle.token)
			return vehicleIndex !== -1 ? state.setIn(['vehicles', vehicleIndex], fromJS(action.vehicle)) : state
		}
		case controlCo2Actions.REMOVE_VEHICLE: {
			return state.updateIn(['vehicles'], (vehicles) =>
				vehicles.filter((v) => v.get('token') !== action.vehicleToken)
			)
		}
		case controlCo2Actions.SET_SCHEDULED_VEHICLE_CONTROLS: {
			return state.set('scheduledVehicleControls', fromJS(action.controls))
		}
		case controlCo2Actions.SET_SERVICES: {
			return state.set('services', fromJS(action.services))
		}
		case controlCo2Actions.REMOVE_SERVICE: {
			return state.updateIn(['services'], (services) =>
				services.filter((v) => v.get('token') !== action.serviceToken)
			)
		}
		case controlCo2Actions.SET_TOASTER_OPTIONS: {
            return state.merge({
                toasterConfiguration: fromJS(action.configuration),
            })
        }
		default:
			return state
	}
}
