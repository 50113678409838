/**Import react section */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import { withStyles, Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**Import components section*/
import LoadingPage from '../../../common/LoadingPage';
/**Import resources section*/
import BiddingBaseLeftPanel from './BiddingBaseLeftPanel'
import EmptyInformation from '../EmptyInformation'
import CreateBaseComponent from './CreateBaseComponent'
const ShowDesktopView = (props) => {
  const { classes, bidding } = props;
  /**String props section */
  const { mode } = props
  /**Bools props section */
  const { isLoading, isSaving, isEdited } = props
  /**Arrays props section */
  const { translate, setIdEdited } = props
  /**Functions props section */
  const { onButtonCreateClick, onAddBiddingBaseFromBackEnd,
    onEditBaseItemClick, onEditBiddingBaseFromBackEnd, 
    onDeletedBiddingSectionFromBackEnd, onUpdatePositionSection
  
  } = props
  const { editedBiddingBase } = props
  const [isOpenDrawer, setIsOpenDrawerOpen] = useState(true);

  const handleDrawerOpenClose = () => {
    setIsOpenDrawerOpen(!isOpenDrawer);
  };

  /**
  * Get the card content
  *
  * @memberof Index
  */
  const getCardContent = () => {
    if (isLoading === true) {
      return (
        <LoadingPage />
      )
    } else {
      return (
        <div className={classes.root}>

          <BiddingBaseLeftPanel
            isOpenDrawer={isOpenDrawer}
            handleDrawerOpenClose={handleDrawerOpenClose}
            translate={translate}
            bidding={bidding}
            onButtonCreateClick={onButtonCreateClick}
            onEditBaseItemClick={onEditBaseItemClick}
            onDeletedBiddingSectionFromBackEnd={onDeletedBiddingSectionFromBackEnd}
            isSaving={isSaving}
            editedBiddingBase={editedBiddingBase}
            onUpdatePositionSection={onUpdatePositionSection}
          />

          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card className={classes.cardRight}>

                {mode === 'MODE_EMPTY' &&

                  <div className={classes.containerEmptyMessage}>
                    <EmptyInformation
                      title={translate('biddingsModule.basesCreate.emptyInformation')}
                    />
                  </div>
                }
                {mode === 'MODE_CREATE' &&
                  <CreateBaseComponent
                    translate={translate}
                    onSaveButtonClick={onAddBiddingBaseFromBackEnd}
                    isSaving={isSaving}
                    bidding={bidding}
                    mode={mode}
                  />
                }
                {mode === 'MODE_EDIT' &&
                  <CreateBaseComponent
                    translate={translate}
                    onSaveButtonClick={onEditBiddingBaseFromBackEnd}
                    editedBiddingBase={editedBiddingBase}
                    isSaving={isSaving}
                    isEdited={isEdited}
                    setIdEdited={setIdEdited}
                    bidding={bidding}
                    mode={mode}
                  />
                }
              </Card>

            </Grid>
          </Grid>
        </div>
      )
    }
  }

  return getCardContent()
};

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  panelRight: {
    marginLeft: "10px",
    marginRight: "10px",
    display: "flex",
  },
  cardRight: {
    marginLeft: "10px",
    maxHeight: `calc(100vh - ${185}px)`,
    minHeight: `calc(100vh - ${185}px)`,
    overflow: "Hidden",
  },
  containerEmptyMessage: {
    marginTop: "100px"
  }
});

ShowDesktopView.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  isLoading: PropTypes.bool.isRequired,
};

ShowDesktopView.defaultProps = {
  isLoading: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(ShowDesktopView)
);
