import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'
import Select from 'react-select/async'

import { searchUsersByQuery } from '../../store/administration/InternalActions'
import Toaster from './Toaster'

/**
 * User selector container
 *
 * @class UserSelector
 * @extends {Component}
 */
class UserSelector extends Component {
	/**
	 *Creates an instance of UserSelector.
	 * @param {*} props
	 * @memberof UserSelector
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			isSearching: false,
			usersList: [],
			selectedUser: '',
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		this.setState({ isSearching: true })
		this.props.searchUsers('').then(() => {
			this.setState({ isSearching: false })
		})
	}

	/**
	 * React life-cycle
	 * select default option if prop.value is found in list
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof UserSelector
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.props.users !== prevProps.users) {
			let selectedUser = this.props.users.find((user) => {
				return user.user_name === this.props.value
			})
			if (selectedUser) {
				selectedUser = { label: selectedUser.full_name, value: selectedUser.user_name }
				this.setState({ selectedUser })
			}
		}
	}

	/**
	 * handler to search options in suppliers list
	 *
	 * @param {*} query
	 * @returns
	 * @memberof UserSelector
	 */
	search(query) {
		this.setState({ isSearching: true })
		let filteredList = this.props.users.filter((user) => {
			return user.full_name.toLowerCase().includes(query.toLowerCase())
		})
		return Promise.resolve(filteredList)
	}

	/**
	 * Get the user matching with a query
	 *
	 * @param {*} query
	 * @returns
	 * @memberof UserSelector
	 */
	getUsers(query) {
		return this.search(query).then((usersList) => {
			this.setState({
				usersList,
				isSearching: false,
			})
			return usersList.map((user) => {
				return {
					value: user.user_name,
					label: user.full_name,
				}
			})
		})
	}

	/**
	 * Method to determine if input value has >=3 characters
	 * then isSearching is true and filter results
	 *
	 * @param {*} value
	 * @returns
	 * @memberof UserSelector
	 */
	onInputChange(value) {
		let isSearching = value.length >= 3
		this.setState({ isSearching })
		return value
	}

	/**
	 * On selec a user
	 *
	 * @param {*} option
	 * @memberof UserSelector
	 */
	onSelectedUserClick(option) {
		if (this.state.usersList) {
			let selectedUser = this.state.usersList.find((user) => {
				return user.user_name === option.value
			})
			if (selectedUser && this.props.onUserSelected) {
				this.setState({ selectedUser: { label: selectedUser.full_name, value: selectedUser.user_name } })
				this.props.onUserSelected(selectedUser)
			} else {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			}
		}
	}

	/**
	 * Method to determine place holder message
	 *
	 * @returns
	 * @memberof UserSelector
	 */
	getPlaceholder() {
		let id = 'users.searchUser'
		if (this.state.isSearching || this.props.isLoadingUsers) id = 'common.loadingPleaseWait'

		return <Translate id={id} />
	}

	/**
	 *
	 *
	 * @returns
	 * @memberof UserSelector
	 */
	render() {
		return (
			<React.Fragment>
				<Select
					placeholder={this.getPlaceholder()}
					value={this.state.selectedUser}
					loadOptions={this.state.isSearching ? this.getUsers : ''}
					cacheOptions
					onChange={this.onSelectedUserClick}
					onInputChange={this.onInputChange}
					isDisabled={
						this.props.isLoadingUsers || this.props.isDisabled
							? this.props.isLoadingUsers || this.props.isDisabled
							: false
					}
					isLoading={this.props.isLoadingUsers || this.state.isSearching}
					styles={{
						menu: (base) => ({ ...base, zIndex: 31 }),
					}}
				/>
				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</React.Fragment>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		isLoadingUsers: state.internalUsers.get('isLoadingUsers'),
		users: state.internalUsers.get('users') ? state.internalUsers.get('users').toJS() : null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		searchUsers: (query) => {
			return dispatch(searchUsersByQuery(query))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelector)
