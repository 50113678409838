/**Import react section */
import React from 'react'
import { Translate } from 'react-localize-redux'
import { COLOR_STATUS_BLUE, COLOR_STATUS_WARNING, COLOR_STATUS_SUCCESS } from './StatusColorConstants'
import { sparePartPriceChangeRequestStatus } from './AppConstants'

export const statusColorIndicator = (status) => {
	switch (Number(status)) {
		case sparePartPriceChangeRequestStatus.DRAFT:
			return COLOR_STATUS_BLUE
		case sparePartPriceChangeRequestStatus.IN_PROCESS:
			return COLOR_STATUS_WARNING
		case sparePartPriceChangeRequestStatus.IS_ACCEPTED:
		case sparePartPriceChangeRequestStatus.CLOSED:
		case sparePartPriceChangeRequestStatus.UPDATE_SAP:
			return COLOR_STATUS_SUCCESS
		case sparePartPriceChangeRequestStatus.CANCELLED:
		case sparePartPriceChangeRequestStatus.IS_REJECTED:
		case sparePartPriceChangeRequestStatus.ERROR_SAP:
			return COLOR_STATUS_SUCCESS
		default:
			return ''
	}
}

export const backgroundColorTableStatus = (status) => {
	switch (Number(status)) {
		case sparePartPriceChangeRequestStatus.IN_PROCESS:
			return 'warning'
		case sparePartPriceChangeRequestStatus.IS_ACCEPTED:
		case sparePartPriceChangeRequestStatus.CLOSED:
		case sparePartPriceChangeRequestStatus.UPDATE_SAP:
			return 'success'
		case sparePartPriceChangeRequestStatus.CANCELLED:
		case sparePartPriceChangeRequestStatus.IS_REJECTED:
		case sparePartPriceChangeRequestStatus.ERROR_SAP:
			return 'danger'
		default:
			return ''
	}
}

export const descriptionStatus = (status) => {
	switch (Number(status)) {
		case sparePartPriceChangeRequestStatus.DRAFT:
			return <Translate id='sparePartPriceChange.status.draft' />
		case sparePartPriceChangeRequestStatus.IN_PROCESS:
			return <Translate id='sparePartPriceChange.status.inProcess' />
		case sparePartPriceChangeRequestStatus.CLOSED:
			return <Translate id='sparePartPriceChange.status.closed' />
		case sparePartPriceChangeRequestStatus.CANCELLED:
			return <Translate id='sparePartPriceChange.status.cancel' />
		case sparePartPriceChangeRequestStatus.IS_ACCEPTED:
			return <Translate id='sparePartPriceChange.status.accepted' />
		case sparePartPriceChangeRequestStatus.IS_REJECTED:
			return <Translate id='sparePartPriceChange.status.rejected' />
		case sparePartPriceChangeRequestStatus.UPDATE_SAP:
			return <Translate id='sparePartPriceChange.status.updateSAP' />
		case sparePartPriceChangeRequestStatus.ERROR_SAP:
			return <Translate id='sparePartPriceChange.status.errorSAP' />
		default:
			return '-'
	}
}

export const isActiveAvailableOperations = (status, isInternalUser) => {
	if (status === sparePartPriceChangeRequestStatus.DRAFT && !isInternalUser) return true
	if (status === sparePartPriceChangeRequestStatus.IN_PROCESS && isInternalUser) return true
	else return false
}

export const existListItems = (requestPriceChange) => {
	if (requestPriceChange === null) return false
	return requestPriceChange.materials.length !== 0
}

export function TableConfigurationOperations(canbeUpdate, status, onOpenChangeStatus, onDeleteRequest, existListItems) {
	let columns = []
	if (!canbeUpdate && status === sparePartPriceChangeRequestStatus.DRAFT) {
		if (!existListItems) {
			columns.push({
				value: sparePartPriceChangeRequestStatus.PRICES_SAP,
				title: <Translate id='sparePartPriceChange.show.proceSAP' />,
				onClick: () => onOpenChangeStatus(sparePartPriceChangeRequestStatus.PRICES_SAP),
				isIconMaterial: true,
				description: <Translate id='sparePartPriceChange.show.proceSAPMessage' />,
			})
		}
		columns.push({
			value: sparePartPriceChangeRequestStatus.IN_PROCESS,
			title: <Translate id='sparePartPriceChange.show.sendRequest' />,
			onClick: () => onOpenChangeStatus(sparePartPriceChangeRequestStatus.IN_PROCESS),
			isIconMaterial: true,
			description: <Translate id='sparePartPriceChange.show.sendRequestMessage' />,
		})
		columns.push({
			value: sparePartPriceChangeRequestStatus.DELETED,
			title: <Translate id='sparePartPriceChange.show.deleteRequest' />,
			onClick: onDeleteRequest,
			isIconMaterial: true,
			description: <Translate id='sparePartPriceChange.show.deleteRequestMessage' />,
		})
	}
	if (canbeUpdate && status === sparePartPriceChangeRequestStatus.IN_PROCESS) {
		columns.push({
			value: sparePartPriceChangeRequestStatus.SEND_SAP,
			title: <Translate id='sparePartPriceChange.show.sendSAP' />,
			onClick: () => onOpenChangeStatus(sparePartPriceChangeRequestStatus.SEND_SAP),
			isIconMaterial: true,
			description: <Translate id='sparePartPriceChange.show.sendSAPMessage' />,
		})
		columns.push({
			value: sparePartPriceChangeRequestStatus.CLOSED,
			title: <Translate id='sparePartPriceChange.show.closeRequest' />,
			onClick: () => onOpenChangeStatus(sparePartPriceChangeRequestStatus.CLOSED),
			isIconMaterial: true,
			description: <Translate id='sparePartPriceChange.show.closeRequestMessage' />,
		})
		columns.push({
			value: sparePartPriceChangeRequestStatus.CANCELLED,
			title: <Translate id='sparePartPriceChange.show.cancelFolio' />,
			onClick: () => onOpenChangeStatus(sparePartPriceChangeRequestStatus.CANCELLED),
			isIconMaterial: true,
			description: <Translate id='sparePartPriceChange.show.cancelFolioMessage' />,
		})
	}
	return columns
}

export function onTitleStatusDialog(status) {
	switch (status) {
		case sparePartPriceChangeRequestStatus.PRICES_SAP:
			return 'sparePartPriceChange.show.proceSAP'
		case sparePartPriceChangeRequestStatus.IN_PROCESS:
			return 'sparePartPriceChange.show.sendRequest'
		case sparePartPriceChangeRequestStatus.DELETED:
			return 'sparePartPriceChange.show.deleteRequest'
		case sparePartPriceChangeRequestStatus.SEND_SAP:
			return 'sparePartPriceChange.show.sendSAP'
		case sparePartPriceChangeRequestStatus.CLOSED:
			return 'sparePartPriceChange.show.closeRequest'
		case sparePartPriceChangeRequestStatus.CANCELLED:
			return 'sparePartPriceChange.show.cancelFolio'
		default:
			return 'consignmentNote.show.noOption'
	}
}

export function onMessageStatusDialog(status) {
	switch (status) {
		case sparePartPriceChangeRequestStatus.PRICES_SAP:
			return 'sparePartPriceChange.show.proceSAPMessage'
		case sparePartPriceChangeRequestStatus.IN_PROCESS:
			return 'sparePartPriceChange.show.sendRequestMessage'
		case sparePartPriceChangeRequestStatus.DELETED:
			return 'sparePartPriceChange.show.show.deleteRequestMessage'
		case sparePartPriceChangeRequestStatus.SEND_SAP:
			return 'sparePartPriceChange.show.sendSAPMessage'
		case sparePartPriceChangeRequestStatus.CLOSED:
			return 'sparePartPriceChange.show.closeRequestMessage'
		case sparePartPriceChangeRequestStatus.CANCELLED:
			return 'sparePartPriceChange.show.cancelFolioMessage'
		default:
			return 'consignmentNote.show.noOption'
	}
}
