import { fromJS } from 'immutable';

import * as quoteActions from './QuotesActions';

/**
 * quotesInitialState initial state
 */
export const quotesInitialState = fromJS({
    isLoadingQuotes: true,
    quotes: null,
    isLoadingQuote: false,
    quote: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    quotesCount: null,
    isSaving: false,
})

export default function quotesReducer(state = quotesInitialState, action) {
    switch (action.type) {
        case quoteActions.START_LOADING_QUOTES: {
            return state.merge({
                isLoadingQuotes: action.isLoading
            })
        }
        case quoteActions.SET_QUOTES_LIST: {
            let quotesLists = fromJS(action.quotes);
            return state.merge({
                quotes: quotesLists,
                isLoadingQuotes: false
            })
        }
        case quoteActions.CHANGE_QUOTES_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case quoteActions.CHANGE_QUOTES_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case quoteActions.CHANGE_QUOTES_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case quoteActions.IS_LOADING_QUOTE: {
            return state.merge({
                isLoadingQuote: action.isLoadingQuote,
                quote: null,
            })
        }
        case quoteActions.SET_QUOTE: {
            return state.merge({
                isSaving: false,
                isLoadingQuote: false,
                quote: fromJS(action.quote)
            })
        }
        case quoteActions.SET_QUOTES_COUNT: {
            return state.merge({
                quotesCount: action.count
            })
        }
        case quoteActions.IS_SAVING_QUOTE: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case quoteActions.UPDATE_QUOTE_DETAIL_ITEM: {
            return state.updateIn(['quote', 'details'], (allDetails) => {
                let index = allDetails.findIndex((item) => {
                    return item.get('token') === action.detail.token
                });
                return allDetails.setIn([index], fromJS(action.detail)).set('isSaving', false);
            },
            );
        }
        case quoteActions.ADD_FILE_QUOTES: {
            let oldList = state.getIn(['quote', 'attachments']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['quote', 'attachments'], fromJS(newList));
        }
        case quoteActions.REMOVE_FILE_QUOTES: {
            let oldList = state.getIn(['quote', 'attachments']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['quote', 'attachments'], fromJS(newList));
        }
        default:
            return state;
    }
}