//import react section
import React from 'react'
import { Translate } from 'react-localize-redux'
//import material ui section
import { withStyles, Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
//import component common section
import { RELEASE_PLAN_COLOR_YELLOW } from '../../../../store/helpers/StatusColorConstants'

const DialogTitle = withStyles((theme) => ({
	root: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit * 2,
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing.unit,
		top: theme.spacing.unit,
		color: theme.palette.grey[500],
	},
}))((props) => {
	const { children, classes, onClose } = props
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
			<Typography variant='h6'>{children}</Typography>
			{onClose ? (
				<IconButton aria-label='Close' className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	)
})

const DialogContent = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing.unit * 2,
	},
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit,
	},
}))(MuiDialogActions)

const styleWarning = {
	border: `2px solid ${RELEASE_PLAN_COLOR_YELLOW}`,
	padding: '10px',
}

class DialogWarningQualificationMessage extends React.Component {
	state = {
		open: false,
	}

	handleClickOpen = () => {
		this.setState({
			open: true,
		})
	}

	handleClose = () => {
		this.setState({ open: false })
	}

	render() {
		return (
			<div>
				<Tooltip title={<Translate id='indirectqualifications.warnings.dataEvaluated' />}>
					<InfoIcon size='small' onClick={this.handleClickOpen} />
				</Tooltip>
				<Dialog onClose={this.handleClose} aria-labelledby='customized-dialog-title' open={this.state.open}>
					{this.props.columnItem && (
						<DialogTitle id='customized-dialog-title' onClose={this.handleClose}>
							{this.props.columnItem.title}
						</DialogTitle>
					)}
					<DialogContent>
						{this.props.columnItem && (
							<Typography gutterBottom>
								<strong>
									<Translate id='indirectqualifications.warnings.infoCalification' />
								</strong>
							</Typography>
						)}
						{this.props.columnItem && (
							<div style={styleWarning}>
								<Typography gutterBottom>{this.props.columnItem.warningDescription}</Typography>
							</div>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color='primary'>
							<Translate id='indirectqualifications.warnings.closed' />
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}

export default DialogWarningQualificationMessage
