import React from 'react'
import { Translate } from 'react-localize-redux'
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
/* Material UI import section */
import { Button, TextField, Typography, withStyles } from '@material-ui/core'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'

const CreateTypeShipmentPanel = (props) => {
	const { onClose, classes, setInformation, information, onSave, isLoading } = props
	const onChange = (event) => {
		const { name, value } = event.target
		setInformation({
			...information,
			[name]: value,
		})
	}
	const canBeSaved = () => {
		if (information.name === '') return false
		return true
	}
	return (
		<LateralPanel onClose={onClose}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id='carrierTenders.deals.addCarriersTitle' />}
				onCloseButtonClick={onClose}
				icon={<BubbleChartIcon color='secondary' />}
			/>
			<div className={classes.bodyContainer}>
				<div>
					<Typography variant='subtitle2'>
						{<Translate id='carrierTenders.configuration.carrierTypes' />}
					</Typography>
				</div>
				<TextField
					id='standard-with-placeholder'
					placeholder={'Tipo de embarque'}
					onChange={onChange}
					name='name'
					value={information.salesManager}
					inputProps={{
						style: {
							height: '10px',
						},
					}}
					size='small'
					margin='dense'
					fullWidth
					variant='outlined'
				/>
			</div>

			<Button
				data-tut='reactour-save'
				data-cy='btn-save'
				type='button'
				color='primary'
				variant='outlined'
				className={classes.btnCreate}
				onClick={onSave}
				disabled={!canBeSaved() || isLoading}
			>
				{<Translate id='carrierTenders.deals.addShipment' />}
			</Button>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	bodyContainer: {
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
	},

	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
})

export default withStyles(styles, { withTheme: true })(CreateTypeShipmentPanel)
