import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

/** Material-UI imports section */
import { Translate, getTranslate } from 'react-localize-redux'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import { IconButton } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { ListItemIcon, ListItemAvatar, Avatar, Tooltip, Collapse, ListItemSecondaryAction } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import EthicCodeIcon from '@material-ui/icons/ListAlt'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline'
import SuppliersIcon from '@material-ui/icons/Group'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import PersonIcon from '@material-ui/icons/Person'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'

import DownloadIcon from '@material-ui/icons/CloudDownload'
import CloundDownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import LinkMaterial from '@material-ui/core/Link'
import EditUser from '@material-ui/icons/BorderColor'
import CalendarIcon from '@material-ui/icons/CalendarToday'
/** Import component section */
import LoadingPage from '../../common/LoadingPage'
import QuestionCard from './components/QuestionCard'
import DialogEditDates from './components/DialogEditDates'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import SuppliersSelector from '../../common/SupplierSelector'

import { converListToArray } from '../../../store/helpers/ListHelper'
import { ROLE_COMPANYADMINISTRATOR, IsUserInRole } from '../../../store/helpers/RolesHelper'
import {
	loadEthicCode,
	downloadSuppliersCodeEthicsReport,
	updateSurveyTitle,
	updateEthicData,
	removeSupplierInListExcludesFromEthicCode,
	addSupplierInListExcludesFromEthicCode,
} from '../../../store/ethicCode/ethicCodeAction'
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import Toaster from '../../common/Toaster'

/**
 * Show ethic code
 */
class Show extends Component {
	/**
	 *
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openSuppliersList: true,
			openSuppliersExclude: false,
			openQuestionnaires: false,
			openSuppliers: false,
			editTitle: true,
			surveyTitle: '',
			isOpenEditEthicCodeDatesDialog: false,
			ethicCode: {
				token: '',
				activationDate: '',
				expirationDate: '',
				officialToken: '',
			},
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let ethicCodeToken = this.props.match.params.token
		this.props.loadEthicCode(ethicCodeToken)
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoadingEthicCode !== this.props.isLoadingEthicCode && this.props.ethicCode !== null) {
			let ethicCode = {
				token: this.props.ethicCode.token,
				activationDate: this.props.ethicCode.activation_date,
				expirationDate: this.props.ethicCode.expiration_date,
			}
			this.setState({
				ethicCode: ethicCode,
			})
		}
	}

	/**
	 * Handle state to show or hide suppliers list
	 *
	 * @memberof Show
	 */
	handleExpandSuppliersList() {
		this.setState({
			openSuppliersList: !this.state.openSuppliersList,
		})
	}

	onEditOpenEditEthicCodeDatesDialog() {
		this.setState({ isOpenEditEthicCodeDatesDialog: !this.state.isOpenEditEthicCodeDatesDialog })
	}

	handleExpandQuestionnairesList() {
		this.setState({
			openQuestionnaires: !this.state.openQuestionnaires,
		})
	}

	handleExpandSuppliersListQuestion() {
		this.setState({
			openSuppliers: !this.state.openSuppliers,
		})
	}

	handleEditSurveyTitle() {
		this.setState({
			editTitle: !this.state.editTitle,
			surveyTitle: '',
		})
	}

	handleSaveNewSurveyTitle() {
		let surveyToken = this.props.match.params.token
		this.props.updateSurveyTitle(surveyToken, this.state.surveyTitle)
		this.setState({
			editTitle: !this.state.editTitle,
		})
	}

	handleTitleEditTextFieldChange(e) {
		this.setState({
			surveyTitle: e.target.value,
		})
	}

	/**
	 * Function on update ethic code information
	 */
	onUpdateEthicCode() {
		let ethicCodeData = { ...this.state.ethicCode }
		if (typeof this.state.ethicCode.activationDate === 'string') {
			ethicCodeData['activationDate'] = moment(this.state.ethicCode.activationDate, 'DD/MM/YYYY')
		}
		if (typeof this.state.ethicCode.expirationDate === 'string') {
			ethicCodeData['expirationDate'] = moment(this.state.ethicCode.expirationDate, 'DD/MM/YYYY')
		}
		this.setState(
			{
				ethicCode: ethicCodeData,
			},
			() => {
				this.props
					.updateEthicData(this.state.ethicCode)
					.then(() => {
						this.setState({
							isOpenEditEthicCodeDatesDialog: false,
							showToaster: true,
							toasterMessage: <Translate id='common.saveCorrectly' />,
							toasterVariant: 'success',
						})
					})
					.catch(() => {
						this.setState({
							showToaster: true,
							toasterMessage: <Translate id='common.errorToSave' />,
							toasterVariant: 'error',
						})
					})
			}
		)
	}

	/**
	 * render Collapsable section of suppliers list
	 *
	 * @param {*} idTranslation
	 * @param {*} value
	 * @param {*} icon
	 * @returns
	 * @memberof Show
	 */
	renderSuppliersList(idTranslation, value, icon) {
		return (
			<React.Fragment>
				<Collapse in={this.state.openSuppliersList} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{value.map((supplier) => {
							return (
								<React.Fragment key={supplier.token}>
									<ListItem
										key={supplier.share_with_supplier}
										component='li'
										className={this.props.classes.nested}
									>
										<ListItemIcon style={{ marginLeft: '3rem', marginRight: '0rem' }}>
											<Tooltip title={<Translate id={idTranslation} />}>{icon}</Tooltip>
										</ListItemIcon>
										<ListItemText primary={supplier.share_with_supplier} />
									</ListItem>
									<Divider />
								</React.Fragment>
							)
						})}
					</List>
				</Collapse>
				<Divider />
			</React.Fragment>
		)
	}

	/**
	 * Render item in list of official notice detail
	 *
	 * @param {*} idTranslation
	 * @param {*} value
	 * @param {*} icon
	 * @param {*} isLink
	 * @param {*} isButton
	 * @returns
	 * @memberof Show
	 */
	renderItemList(idTranslation, value, icon, isButton, isBoolean) {
		return (
			<React.Fragment>
				<ListItem
					key={idTranslation}
					button={isButton}
					onClick={() => {
						if (isButton) this.handleExpandSuppliersList()
					}}
				>
					<ListItemIcon>
						<Tooltip title={<Translate id={idTranslation} />}>{icon}</Tooltip>
					</ListItemIcon>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									component='span'
									variant='body2'
									className={this.props.classes.inline}
									color='textPrimary'
								>
									<Translate id={idTranslation} />
								</Typography>
								{!isButton ? (!isBoolean ? ' ' + value : '') : ''}
							</React.Fragment>
						}
					/>
					{isButton && (this.state.openSuppliersList ? <ExpandLess /> : <ExpandMore />)}
				</ListItem>
				<Divider />
			</React.Fragment>
		)
	}

	/**
	 * Function to render documents
	 * @param {*} documents
	 */
	renderDocuments(attachments) {
		return (
			<Collapse in={true} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					{attachments.map((document) => {
						return (
							<ListItem divider key={document.name} component='li' className={this.props.classes.nested}>
								<LinkMaterial
									style={{ marginLeft: '3rem', marginRight: '0rem' }}
									component={'a'}
									target='_blank'
									href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${document.relativePath}`}
								>
									{'  '}
									{document.name}
								</LinkMaterial>
							</ListItem>
						)
					})}
				</List>
			</Collapse>
		)
	}

	renderTemplateExpander() {
		return (
			<React.Fragment>
				<ListItem
					key={<Translate id='surveys.questionSendSuppliers' />}
					button={true}
					onClick={() => {
						this.handleExpandQuestionnairesList()
					}}
				>
					<ListItemIcon>
						<Tooltip title={<Translate id='surveys.questionSendSuppliers' />}>{<EthicCodeIcon />}</Tooltip>
					</ListItemIcon>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									component='span'
									variant='body2'
									className={this.props.classes.inline}
									color='textPrimary'
								>
									<Translate id='surveys.questionSendSuppliers' />
								</Typography>
							</React.Fragment>
						}
					/>
					{this.state.openQuestionnaires ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Divider />
			</React.Fragment>
		)
	}

	renderTemaple(template) {
		return (
			<React.Fragment>
				<Collapse in={this.state.openQuestionnaires} timeout='auto' unmountOnExit>
					<Card>
						<CardContent>
							{template.questions.map((question) => {
								return (
									<QuestionCard
										key={question.token}
										question={question}
										isTemplated={template.is_templated}
									/>
								)
							})}
						</CardContent>
					</Card>
				</Collapse>
			</React.Fragment>
		)
	}

	handleExpandSuppliersListExclude() {
		this.setState({
			openSuppliersExclude: !this.state.openSuppliersExclude,
		})
	}

	/**
	 * renderSuppliersExpander
	 */
	renderSuppliersExcludeExpander() {
		return (
			<React.Fragment>
				<ListItem
					key={<Translate id='contactsupplier.supplierToExluded' />}
					button={true}
					onClick={() => {
						this.handleExpandSuppliersListExclude()
					}}
				>
					<ListItemIcon>
						<Tooltip title={<Translate id='contactsupplier.supplierToExluded' />}>
							{<SuppliersIcon />}
						</Tooltip>
					</ListItemIcon>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									component='span'
									variant='body2'
									className={this.props.classes.inline}
									color='textPrimary'
								>
									<Translate id='contactsupplier.supplierToExluded' />
								</Typography>
							</React.Fragment>
						}
					/>
					{this.state.openSuppliers ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Divider />
			</React.Fragment>
		)
	}

	renderSuppliersExpander() {
		return (
			<React.Fragment>
				<ListItem
					key={<Translate id='surveys.listSupplierEvidence' />}
					button={true}
					onClick={() => {
						this.handleExpandSuppliersListQuestion()
					}}
				>
					<ListItemIcon>
						<Tooltip title={<Translate id='surveys.listSupplierEvidence' />}>{<SuppliersIcon />}</Tooltip>
					</ListItemIcon>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									component='span'
									variant='body2'
									className={this.props.classes.inline}
									color='textPrimary'
								>
									<Translate id='surveys.listSupplierEvidence' />
								</Typography>
							</React.Fragment>
						}
					/>
					{this.state.openSuppliers ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Divider />
			</React.Fragment>
		)
	}

	/**
	 * Function to add supplier in list excludes
	 */
	addSupplierInListExcludesInList(supplier) {
		if (supplier) {
			this.props.addSupplierInListExcludes(this.props.match.params.token, supplier.code)
		}
	}

	/**
	 *  Function to remove supplier in list excludes
	 * @param {*} supplierCode
	 */
	removeSupplierInListExcludesInList(supplierCode) {
		if (supplierCode) {
			this.props.removeSupplierInListExcludes(this.props.match.params.token, supplierCode)
		}
	}

	renderSuppliers(suppliers, icon) {
		return (
			<Collapse in={this.state.openSuppliers} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					{suppliers.map((document) => {
						return (
							<React.Fragment key={document.name}>
								<ListItem key={document.name} component='li' className={this.props.classes.nested}>
									<LinkMaterial
										style={{ marginLeft: '3rem', marginRight: '0rem' }}
										component={'a'}
										target='_blank'
										href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${document.relativePath}`}
									>
										{'  '}
										{document.name}
									</LinkMaterial>
								</ListItem>
							</React.Fragment>
						)
					})}
				</List>
			</Collapse>
		)
	}

	/**
	 * rederListExcludeSuppliers
	 * @param {*} excludeSuppliers
	 */
	rederListExcludeSuppliers(excludeSuppliers) {
		console.log('excludeSuppliers', excludeSuppliers)
		return (
			<Collapse in={this.state.openSuppliersExclude} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					{IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
						<ListItem divider key='name' component='li' className={this.props.classes.nested}>
							<Grid item xs={10} style={{ marginLeft: '3rem' }}>
								<SuppliersSelector
									className={this.props.classes.textField}
									onSupplierSelected={(supplier) => {
										this.addSupplierInListExcludesInList(supplier)
									}}
								/>
							</Grid>
							{/* <ListItemSecondaryAction>
								<Tooltip title={<Translate id={'officialNotices.downloadTemplate'} />}>
									<Fab
										size='small'
										color='secondary'
										onClick={() => {
											this.onClickOptionExclideSuppliers()
										}}
									>
										<DownloadIcon />
									</Fab>
								</Tooltip>
							</ListItemSecondaryAction> */}
						</ListItem>
					)}
					{excludeSuppliers.size && (
						<ListItem component='li' className={this.props.classes.nested}>
							{<Translate id='contactsupplier.withoutSuppliersExclide' />}
						</ListItem>
					)}
					{excludeSuppliers.map((supplier) => {
						return (
							<ListItem divider key={supplier.supplier_code}>
								<ListItemIcon style={{ marginLeft: '3rem', marginRight: '0rem' }}>
									<SuppliersIcon style={{ marginTop: '-5px' }} />
								</ListItemIcon>
								<ListItemText
									primary={
										<Typography variant='caption' gutterBottom>
											{supplier.supplier_name}
										</Typography>
									}
								/>
								{IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
									<ListItemSecondaryAction>
										<Tooltip title={<Translate id='common.delete' />}>
											<IconButton
												id={supplier.supplier_code}
												disabled={this.props.isLoadingEthicCode}
												onClick={(event) => {
													this.removeSupplierInListExcludesInList(event.currentTarget.id)
												}}
											>
												<DeleteOutlinedIcon color='error' />
											</IconButton>
										</Tooltip>
									</ListItemSecondaryAction>
								)}
							</ListItem>
						)
					})}
				</List>
			</Collapse>
		)
	}

	/**
	 * Update the properties in ethicCode in state
	 *
	 * @param {*} user
	 * @param {*} value
	 * @memberof View
	 */
	updateProperty(property, value) {
		let ethicCodeUpdate = { ...this.state.ethicCode }
		ethicCodeUpdate[property] = value
		this.setState({
			ethicCode: ethicCodeUpdate,
		})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Show
	 */
	getCardContent() {
		if (this.props.isLoadingEthicCode === true) {
			return <LoadingPage />
		} else if (this.props.ethicCode !== null) {
			const { classes, ethicCode } = this.props
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Paper>
							<List>
								<ListItem key='name'>
									<ListItemAvatar>
										<Avatar>
											<EthicCodeIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={
											this.state.editTitle ? (
												<React.Fragment>
													<Typography
														variant='h5'
														className={classes.inline}
														color='textPrimary'
													>
														{ethicCode.name}
														<Tooltip title={'Editar titulo'}>
															<IconButton
																onClick={() => {
																	this.handleEditSurveyTitle()
																}}
															>
																<EditIcon style={{ fontSize: 20 }} />
															</IconButton>
														</Tooltip>
													</Typography>
												</React.Fragment>
											) : (
												<React.Fragment>
													<TextField
														value={this.state.surveyTitle}
														onChange={this.handleTitleEditTextFieldChange}
														style={{ width: '30rem', fontSize: 40 }}
													/>
													<Tooltip title={'Guardar'}>
														<IconButton
															onClick={() => {
																this.handleSaveNewSurveyTitle()
															}}
														>
															<SaveIcon
																disablePadding
																style={{
																	fontSize: 20,
																	marginLeft: '0rem',
																	marginRight: '0rem',
																}}
																color='secondary'
															/>
														</IconButton>
													</Tooltip>
													<Tooltip title={'Cancelar'}>
														<IconButton
															onClick={() => {
																this.handleEditSurveyTitle()
															}}
														>
															<CancelIcon
																disablePadding
																style={{
																	fontSize: 20,
																	marginLeft: '0rem',
																	marginRight: '0rem',
																}}
																color='error'
															/>
														</IconButton>
													</Tooltip>
												</React.Fragment>
											)
										}
									/>
									{IsCurrentActiveDirectoryUser(this.props.user) &&
										IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
											<ListItemIcon>
												<Tooltip title={<Translate id='common.edit' />}>
													<EditUser
														color='secondary'
														onClick={this.onEditOpenEditEthicCodeDatesDialog}
													/>
												</Tooltip>
											</ListItemIcon>
										)}
								</ListItem>

								{this.renderItemList(
									'officialNotices.activationDate',
									ethicCode.activation_date_long,
									<CalendarIcon />
								)}
								{this.renderItemList(
									'officialNotices.expirationDate',
									ethicCode.expiration_date_long,
									<CalendarIcon />
								)}
								{ethicCode.is_accepted_by_supplier &&
									this.renderItemList(
										'surveys.isAcceptRequired',
										ethicCode.is_accepted_by_supplier,
										<CheckCircleIcon />,
										false,
										true
									)}
								{this.renderItemList(
									'surveys.filesInCode',
									ethicCode.attachments,
									<CloundDownloadIcon />,
									false,
									true
								)}
								{this.renderDocuments(ethicCode.attachments)}
								{this.renderItemList(
									'officialNotices.suppliers',
									ethicCode.share_with_suppliers,
									<SuppliersIcon />,
									true,
									true
								)}
								{this.renderSuppliersList(
									'common.supplier',
									ethicCode.share_with_suppliers,
									<PersonIcon color='secondary' />
								)}
								{ethicCode.is_accepted_by_supplier &&
									IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
										<ListItem divider key='token'>
											<Link
												color='primary'
												onClick={() =>
													this.props.downloadSuppliersCodeEthicsReport(
														this.props.ethicCode.token
													)
												}
												to='#'
											>
												<DownloadIcon /> {<Translate id='surveys.messageToDownload' />}
											</Link>
										</ListItem>
									)}
								{this.renderSuppliersExpander()}
								<Collapse in={this.state.openSuppliers} timeout='auto' unmountOnExit>
									<List component='div' disablePadding>
										{ethicCode.suppliers_list.size && (
											<ListItem component='li' className={this.props.classes.nested}>
												{<Translate id='surveys.withoutSuppliersEvidences' />}
											</ListItem>
										)}
										{ethicCode.suppliers_list.map((supplier) => {
											return (
												<React.Fragment key={supplier.supplier_code}>
													<ListItem
														key={supplier.supplier_code}
														component='li'
														className={this.props.classes.nested}
													>
														{<Translate id='common.supplier' />} {': '}
														{supplier.supplier_code} | {supplier.supplier_name}
													</ListItem>
													{this.renderSuppliers(supplier.attachments)}
													<Divider />
												</React.Fragment>
											)
										})}
									</List>
								</Collapse>

								{this.renderTemplateExpander()}
								{this.renderTemaple(ethicCode.template)}
								{IsCurrentActiveDirectoryUser(this.props.user) && this.renderSuppliersExcludeExpander()}

								{IsCurrentActiveDirectoryUser(this.props.user) &&
									this.rederListExcludeSuppliers(ethicCode.exclude_suppliers)}
							</List>
						</Paper>
					</Grid>
				</Grid>
			)
		}
	}

	render() {
		return (
			<Card>
				<CardContent>{this.getCardContent()}</CardContent>
				{/** Dialog to DialogEditDates */}
				{this.state.isOpenEditEthicCodeDatesDialog && (
					<DialogEditDates
						open={this.state.isOpenEditEthicCodeDatesDialog}
						notice={this.state.ethicCode}
						onClose={() => {
							this.setState({ isOpenEditEthicCodeDatesDialog: false })
						}}
						updateProperty={this.updateProperty}
						onSaveItem={this.onUpdateEthicCode}
						isSaving={this.props.isLoadingEthicCode}
					/>
				)}

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingEthicCode: state.ethicCodes.get('isLoadingEthicCode'),
		ethicCode: state.ethicCodes.get('ethicCode') ? state.ethicCodes.get('ethicCode').toJS() : null,
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadEthicCode: (ethicCodeToken) => {
			return dispatch(loadEthicCode(ethicCodeToken))
		},
		downloadSuppliersCodeEthicsReport: (ethicCodeToken) => {
			return dispatch(downloadSuppliersCodeEthicsReport(ethicCodeToken))
		},
		updateSurveyTitle: (surveyToken, surveyName) => {
			return dispatch(updateSurveyTitle(surveyToken, surveyName))
		},
		updateEthicData: (ethicData) => {
			return dispatch(updateEthicData(ethicData))
		},
		addSupplierInListExcludes: (ethicCodeToken, supplierCode) => {
			return dispatch(addSupplierInListExcludesFromEthicCode(ethicCodeToken, supplierCode))
		},
		removeSupplierInListExcludes: (ethicCodeToken, supplierCode) => {
			return dispatch(removeSupplierInListExcludesFromEthicCode(ethicCodeToken, supplierCode))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
