import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { searchBySuppliersByQury } from '../../store/suppliers/SupplierActions'
import { Translate } from 'react-localize-redux'

import Select from 'react-select/async'

/**
 * Plot selector container
 *
 * @class SupplierSelector
 * @extends {Component}
 */
class SupplierShortSelector extends Component {
	/**
	 *Creates an instance of SupplierSelector.
	 * @param {*} props
	 * @memberof SupplierSelector
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			isSearching: false,
			suppliers: [],
			selectedSupplier: '',
		}
	}

	/**
	 * React life-cycle
	 * select default option if prop.value is found in list
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof SupplierSelector
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.props.value) {
			if (this.state.suppliers.length !== prevState.suppliers.length || this.props.value !== prevProps.value) {
				let selectedSupplier = this.state.suppliers.find((supplier) => {
					return supplier.token === this.props.value
				})
				if (selectedSupplier) {
					selectedSupplier = {
						label: selectedSupplier.full_name,
						value: selectedSupplier.token,
						name: selectedSupplier.name,
						code: selectedSupplier.code,
					}
					this.setState({ selectedSupplier })
				}
			}
		}
	}

	/**
	 * Get the supplier matching with a query
	 *
	 * @param {*} query
	 * @returns
	 * @memberof SupplierSelector
	 */
	getSuppliers(query) {
		return this.props.searchBySupplier(query).then((suppliers) => {
			this.setState({
				suppliers: suppliers,
			})
			return suppliers.map((supplier) => {
				return {
					value: supplier.token,
					label: supplier.full_name,
					name: supplier.name,
					code: supplier.code,
				}
			})
		})
	}

	/**
	 * Method to determine if input value has >=3 characters
	 * then isSearching is true and filter results
	 *
	 * @param {*} value
	 * @returns
	 * @memberof SuppliersCompaniesSelector
	 */
	onInputChange(value) {
		let isSearching = value.length >= 3
		this.setState({ isSearching })
		return value
	}

	/**
	 * On selec a supplier
	 *
	 * @param {*} option
	 * @memberof SupplierSelector
	 */
	onSelectedSupplierClick(option) {
		let supplier = { token: option.value, code: option.code, name: option.name }
		if (this.props.onSupplierSelected) {
			this.props.onSupplierSelected(supplier)
		}
	}

	/**
	 * Method to determine place holder message
	 *
	 * @returns
	 * @memberof UserSelector
	 */
	getPlaceholder() {
		let id = 'suppliers.searchSuppliers'
		if (this.props.value) {
			if (this.state.isSearching || this.props.isLoadingUsers) id = 'common.loadingPleaseWait'
		}

		return <Translate id={id} />
	}

	/**
	 *
	 *
	 * @returns
	 * @memberof SupplierSelector
	 */
	render() {
		return (
			<Select
				placeholder={this.getPlaceholder()}
				value={this.props.value && this.state.selectedSupplier}
				loadOptions={this.state.isSearching && this.getSuppliers}
				cacheOptions
				onChange={this.onSelectedSupplierClick}
				onInputChange={this.onInputChange}
				isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
				styles={{
					menu: (base) => ({ ...base, zIndex: 60 }),
					menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER ANY ELEMENT
				}}
				menuPortalTarget={document.body}
			/>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		searchBySupplier: (query) => {
			return dispatch(searchBySuppliersByQury(query))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierShortSelector)
