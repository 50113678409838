import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { Translate } from 'react-localize-redux';

/** Material UI import section */
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';

/** Resources import section */
import { applyCurrencyFormat } from '../../../store/helpers/StringHelper';

/**
 * Assigned Footer component
 */
const GeneralInformation = (props) => {
	/** Destructuring properties */
	const { service } = props;

	const renderGeneralInformation = (translationId, value) => {
		return (
			<ListItem divider>
				<ListItemText
					style={{ display: 'flex', margin: 0 }}
					primary={
						<Typography component="span" color="textSecondary" variant="button">
							<Translate id={translationId} />
						</Typography>
					}
					secondary={
						<Typography
							color="textSecondary"
							variant="button"
							style={{ flex: '1 1 0', textAlign: 'right', paddingLeft: 5 }}
						>
							{value}
						</Typography>
					}
				/>
			</ListItem>
		);
	};

	return (
		<List style={{ overflowY: 'auto', maxHeight: '73vh' }}>
			{renderGeneralInformation('accreditedWorkshops.serviceOrderDetail.serviceOrder', service.code)}
			{renderGeneralInformation(
				'accreditedWorkshops.serviceOrderDetail.creationDate',
				`${service.creationDate && moment(service.creationDate).format('MM/DD/YYYY')}`
			)}
			{renderGeneralInformation(
				'accreditedWorkshops.serviceOrderDetail.closeDate',
				`${service.attentionDate && moment(service.attentionDate).format('MM/DD/YYYY')}`
			)}
			{renderGeneralInformation(
				'accreditedWorkshops.serviceOrderDetail.attentionDate',
				`${service.attentionDate && moment(service.attentionDate).format('MM/DD/YYYY')}`
			)}
			{renderGeneralInformation('accreditedWorkshops.serviceOrderDetail.chain', service.ChainName)}
			{renderGeneralInformation('accreditedWorkshops.serviceOrderDetail.corporate', service.CorporateName)}
			{renderGeneralInformation('accreditedWorkshops.serviceOrderDetail.distributor', service.DistributorName)}
			{renderGeneralInformation('accreditedWorkshops.serviceOrderDetail.branchOffice', service.BranchOfficeName)}
			{renderGeneralInformation('accreditedWorkshops.serviceOrderDetail.serie', service.Serie)}
			{renderGeneralInformation('accreditedWorkshops.serviceOrderDetail.active', service.Active)}
			{renderGeneralInformation('accreditedWorkshops.serviceOrderDetail.model', service.Model)}
			{renderGeneralInformation('accreditedWorkshops.serviceOrderDetail.product', service.Product)}
			{renderGeneralInformation('accreditedWorkshops.serviceOrderDetail.warranty', service.ServiceWarranty)}
			{renderGeneralInformation(
				'accreditedWorkshops.serviceOrderDetail.serviceAmount',
				applyCurrencyFormat(service.workshopServiceAmount)
			)}
			{renderGeneralInformation(
				'accreditedWorkshops.serviceOrderDetail.expenseAmount',
				applyCurrencyFormat(service.workshopSpendingAmount)
			)}
			{renderGeneralInformation('accreditedWorkshops.serviceOrderDetail.comments', service.comments)}
		</List>
	);
};

GeneralInformation.propTypes = {
	service: propTypes.object.isRequired
};

export default GeneralInformation;
