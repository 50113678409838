import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import { Translate } from 'react-localize-redux'
import BusinessIcon from '@material-ui/icons/BusinessTwoTone'
import CurrencyIcon from '@material-ui/icons/AttachMoneyTwoTone'
import PlaceIcon from '@material-ui/icons/PlaceTwoTone'
import RfcIcon from '@material-ui/icons/LineStyle'
import IncotermIcon from '@material-ui/icons/FlightLandTwoTone'
import PurchaseGroupIcon from '@material-ui/icons/SupervisorAccountTwoTone'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import { FindInPage } from '@material-ui/icons'
import { GetResearchColorOptions } from '../../../store/helpers/SelectOptions'
import {
	COLOR_STATUS_DANGER,
	COLOR_STATUS_GRAY,
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_WARNING,
} from '../../../store/helpers/StatusColorConstants'
import { COMPANY_PTM } from '../../../store/helpers/ResourcesHelper'

/**
 * Component to show general information supplier
 */
class GeneralInformation extends Component {
	/**
	 * Render
	 *
	 * @returns
	 * @memberof GeneralInformation
	 */
	render() {
		const { classes } = this.props
		let supplier = this.props.supplier
		let partnership = this.props.partnership
		let researchOptions = GetResearchColorOptions()
		let contact = this.props.salesContactList

		const getReserchColor = (option) => {
			let color
			switch (option ? option.value : 0) {
				case 1:
					color = COLOR_STATUS_SUCCESS
					break
				case 2:
					color = COLOR_STATUS_DANGER
					break
				case 3:
					color = COLOR_STATUS_WARNING
					break
				default:
					color = COLOR_STATUS_GRAY
					break
			}
			return color
		}
		return (
			<div className={classes.root}>
				<Grid container spacing={24}>
					<Grid item xs={contact ? 4 : 6}>
						<List className={classes.root}>
							<ListItem alignItems='flex-start'>
								<BusinessIcon />
								<ListItemText
									primary={<Translate id='suppliers.supplierNumber' />}
									secondary={<strong>{supplier.full_name}</strong>}
								/>
							</ListItem>
							<ListItem alignItems='flex-start'>
								<RfcIcon />
								<ListItemText
									primary={<Translate id='suppliers.tradeName' />}
									secondary={
										<React.Fragment>
											{supplier.tradeName}
											<br />
											<Typography component='span' className={classes.inline} color='textPrimary'>
												{<Translate id='suppliers.rfc' />}
												{': '}
											</Typography>
											{supplier.rfc}
										</React.Fragment>
									}
								/>
							</ListItem>
							<ListItem alignItems='flex-start'>
								<PlaceIcon />
								<ListItemText
									primary={<Translate id='suppliers.address' />}
									secondary={
										<React.Fragment>
											{supplier.address} <br />
											{supplier.full_address} <br />
											<Typography component='span' className={classes.inline} color='textPrimary'>
												{<Translate id='suppliers.postalCode' />}
												{': '}
											</Typography>
											{supplier.postalCode}
										</React.Fragment>
									}
								/>
							</ListItem>
							{partnership &&
								this.props.selectedCompany &&
								this.props.selectedCompany.company_code === COMPANY_PTM && (
									<ListItem alignItems='flex-start'>
										<FindInPage
											style={{ color: getReserchColor(researchOptions[partnership.research]) }}
										/>
										<ListItemText
											primary={<Translate id='suppliers.research' />}
											secondary={
												<React.Fragment>
													{partnership.research === 0
														? '-'
														: researchOptions[partnership.research]['label']}
												</React.Fragment>
											}
										/>
									</ListItem>
								)}
						</List>
					</Grid>

					<Grid item xs={contact ? 4 : 6}>
						{partnership && (
							<List className={classes.root}>
								<ListItem alignItems='flex-start'>
									<ListItemText
										primary={
											<React.Fragment>
												<Typography variant='h5'>
													{<Translate id='suppliers.dataComercial' />}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<ListItem alignItems='flex-start'>
									<CurrencyIcon />
									<ListItemText
										primary={<Translate id='suppliers.currency' />}
										secondary={<React.Fragment>{partnership.order_currency}</React.Fragment>}
									/>
								</ListItem>
								<ListItem alignItems='flex-start'>
									<IncotermIcon />
									<ListItemText
										primary={<Translate id='suppliers.incoterm' />}
										secondary={
											<React.Fragment>
												{partnership.incoterm}
												<br />

												<Typography
													component='span'
													className={classes.inline}
													color='textPrimary'
												>
													{<Translate id='suppliers.paymentCondition' />}
													{': '}
												</Typography>
												{partnership.condition_credit_full}
											</React.Fragment>
										}
									/>
								</ListItem>
								<ListItem alignItems='flex-start'>
									<PurchaseGroupIcon />
									<ListItemText
										primary={<Translate id='suppliers.buyingGroup' />}
										secondary={<React.Fragment>{partnership.buying_group}</React.Fragment>}
									/>
								</ListItem>
							</List>
						)}
						{!partnership && (
							<Typography component='span' className={classes.inline} color='textPrimary'>
								{<Translate id='common.noInfomrmationSupplier' />}
							</Typography>
						)}
						{!partnership &&
							this.props.onSynchronizeSupplierAutomaticallyByCode &&
							this.props.isVisibleBtnSynchronizeSupplier && (
								<div className={classes.synchronizeSupplier}>
									<Button
										variant='contained'
										color='primary'
										className={classes.btnSynchronize}
										disabled={this.props.isSaving}
										onClick={this.props.onSynchronizeSupplierAutomaticallyByCode}
									>
										Sincronizar proveedor con SAP
									</Button>
								</div>
							)}
					</Grid>
					{contact && (
						<Grid item xs={4}>
							<List className={classes.root}>
								<ListItem alignItems='flex-start'>
									<ListItemText
										primary={
											<React.Fragment>
												<Typography variant='h6'>
													{<Translate id='suppliers.saleaAndCollection' />}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<ListItem alignItems='flex-start'>
									<AccountBoxIcon />
									<ListItemText
										primary={<Translate id='officialNotices.name' />}
										secondary={
											<React.Fragment>
												<Typography
													component='span'
													className={classes.inline}
													color='textPrimary'
												>
													{contact[1]}
												</Typography>
												<Typography
													component='span'
													color='textPrimary'
													className={classes.contact}
												>
													{contact[4]}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<ListItem alignItems='flex-start'>
									<ContactMailIcon />
									<ListItemText
										primary={<Translate id='common.email' />}
										secondary={
											<React.Fragment>
												<Typography
													component='span'
													className={classes.inline}
													color='textPrimary'
												>
													{contact[0]}
												</Typography>
												<Typography
													component='span'
													color='textPrimary'
													className={classes.contact}
												>
													{contact[3]}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<ListItem alignItems='flex-start'>
									<ContactPhoneIcon />
									<ListItemText
										primary={<Translate id='contactsupplier.phone' />}
										secondary={
											<React.Fragment>
												<Typography
													component='span'
													className={classes.inline}
													color='textPrimary'
												>
													{contact[2]}
												</Typography>
												<Typography
													component='span'
													color='textPrimary'
													className={classes.contact}
												>
													{contact[5]}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
							</List>
						</Grid>
					)}
				</Grid>
				<Divider />
			</div>
		)
	}
}

const viewStyles = (theme) => ({
	logoCompany: {
		backgroundColor: theme.palette.secondary.main,
		marginLeft: 80,
		margin: 10,
		width: 60,
		height: 60,
	},
	edit: {
		marginRight: 30,
		backgroundColor: theme.palette.text.hint,
	},
	root: {
		flexGrow: 1,
	},
	inline: {
		display: 'inline',
	},
	imagenSize: {
		margin: 50,
		width: 1090,
		height: 420,
		marginLeft: 80,
	},
	synchronizeSupplier: {
		display: 'flex',
		justifyContent: 'center',
	},
	btnSynchronize: {
		marginTop: '10px',
	},
	contact: {
		color: '#808B96',
	},
})

export default withRouter(withStyles(viewStyles, { withTheme: true })(GeneralInformation))
