import { fromJS } from 'immutable'

import * as directoryActions from './PhoneDirectoryActions'

/**
 * contraActions initial state
 */
export const phoneDirectoryInitialState = fromJS({
	isLoading: true,
	identifier: '',
	categories: [],
	phoneDirectoriesPaginated: {
		items: [],
		pageNumber: 0,
		pageSize: 20,
		total: 0,
		sort: {
			by: 'Name',
			direction: 'desc',
		},
	},
})

export default function companiesReducer(state = phoneDirectoryInitialState, action) {
	switch (action.type) {
		case directoryActions.START_LOADING_PHONE_CATEGORY: {
			return state.merge({
				isLoading: action.isLoading,
				identifier: action.identifier,
			})
		}
		case directoryActions.IS_SAVING: {
			return state.merge({
				isSaving: action.isSaving,
				identifier: action.identifier,
			})
		}
		case directoryActions.SET_PHONE_DIRECTORY: {
			return state.merge({
				phoneDirectoriesPaginated: fromJS(action.phoneDirectoriesPaginated),
				isSaving: false,
				identifier: '',
			})
		}
		case directoryActions.ADD_CATEGORY: {
			let data = fromJS(action.category)
			return state
				.updateIn(['categories'], (allCategories) => pushCategory(allCategories, fromJS(data)))
				.set('isSaving', false)
		}

		case directoryActions.DELETE_CATEGORY: {
			return state
				.updateIn(['categories'], (allCategories) =>
					allCategories.filter((item) => item.get('Id') !== action.id)
				)
				.set('isSaving', false)
		}

		case directoryActions.DELETE_PHONE_DIRECTORY: {
			return state
				.updateIn(['phoneDirectoriesPaginated', 'items'], (allItems) =>
					allItems.filter((item) => item.get('phoneDirectoryId') !== action.id)
				)
				.set('isSaving', false)
		}

		case directoryActions.SET_CATEGORIES_LIST: {
			return state.merge({
				categories: fromJS(action.categories),
				isLoading: false,
				identifier: '',
			})
		}
		default:
			return state
	}
}

const pushCategory = (allCategories, newCategory) => {
	let indexOfCategory = allCategories.findIndex((d) => d.get('Id') === newCategory.get('Id'))
	if (indexOfCategory >= 0) {
		return allCategories.setIn([indexOfCategory], newCategory)
	}
	return allCategories.push(newCategory)
}
