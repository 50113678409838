import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import moment from 'moment'

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';

/** Import resources */
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import DatePicker from '../../common/DateTimePicker/DatePicker';

/**
 * Component o DialogEditDates
 */
class DialogEditDates extends Component {

    /**
    * Create an instance ofMemos
    * @param {*} props 
    */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
     * Can be save
     */
    canbeSave() {
        if (this.notice.activationDate.length !== 0 && this.notice.expirationDate.length !== "")
            return true;
        else
            return false;
    }

    /**
     *  Dialog to revister arrive date
     */
    render() {
        const { classes, notice } = this.props;
        let minExpirationDate = notice.activationDate ? moment(notice.activationDate, 'DD/MM/YYYY').add(1, 'days') : moment();
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            {<Translate id="officialNotices.updateDate" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={this.props.onSaveItem}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.canbeSave}
                    />
                }>

                <form className={classes.textField}>
                    <Typography>{<Translate id="officialNotices.activationDate" />}</Typography>
                    <DatePicker
                        onChangeRange={(event) => { this.props.updateProperty('activationDate', event) }}
                        startDate={moment(notice.activationDate, 'DD/MM/YYYY')}
                        minDate={moment()}
                    />
                    <br />
                    <Typography>{<Translate id="officialNotices.expirationDate" />}</Typography>
                    <DatePicker
                        onChangeRange={(event) => { this.props.updateProperty('expirationDate', event) }}
                        startDate={moment(notice.expirationDate, 'DD/MM/YYYY')}
                        minDate={minExpirationDate}
                    />
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(DialogEditDates));