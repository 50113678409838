import { fromJS } from 'immutable';

import * as warehouseRawMaterialAction from './WarehouseRawMaterialAction';

/**
 * warehouseRawMaterialAction initial state
 */
export const warehouseRawMaterialInitialState = fromJS({
    isLoadingWarehouseRawMaterials: true,
    warehouseRawMaterialsList: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    warehouseRawMaterialsCount: null,
    isSaving: false,
    isLoading: false,
    warehouseRawMaterial: null,
})

export default function warehouseRawMaterialReducer(state = warehouseRawMaterialInitialState, action) {
    switch (action.type) {
        case warehouseRawMaterialAction.START_LOADING_WAREHOUSE_RAW_MATERIALS: {
            return state.merge({
                isLoadingWarehouseRawMaterials: action.isLoading
            })
        }
        case warehouseRawMaterialAction.SET_WAREHOUSE_RAW_MATERIALS_LIST: {
            let warehouseRawMaterials = fromJS(action.warehouseRawMaterials);
            return state.merge({
                warehouseRawMaterialsList: warehouseRawMaterials,
                isLoadingWarehouseRawMaterials: false,
                isLoading: false,
                isSaving:false,
            })
        }
        case warehouseRawMaterialAction.CHANGE_WAREHOUSE_RAW_MATERIALS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case warehouseRawMaterialAction.CHANGE_WAREHOUSE_RAW_MATERIALS_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case warehouseRawMaterialAction.CHANGE_WAREHOUSE_RAW_MATERIALS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case warehouseRawMaterialAction.SET_WAREHOUSE_RAW_MATERIALS_COUNT: {
            return state.merge({
                warehouseRawMaterialsCount: action.count
            })
        }
        case warehouseRawMaterialAction.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case warehouseRawMaterialAction.IS_LOADING: {
            return state.merge({
                isLoading: action.isLoading
            })
        }
        case warehouseRawMaterialAction.SET_WAREHOUSE_RAW_MATERIAL: {
            return state.merge({
                isLoading: false,
                isSaving: false,
                warehouseRawMaterial: fromJS(action.warehouseRawMaterial)
            })
        }
        default:
            return state;
    }
}