import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'

/** Material UI components imports*/
import { withStyles } from '@material-ui/core/styles'

import { Typography, IconButton, Tooltip, Grid, Fab } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import DeleteIcon from '@material-ui/icons/Delete'
import { Translate } from 'react-localize-redux'
import SendSupplierIcon from '@material-ui/icons/Send'

import LoadingPage from '../../../common/LoadingPage'
import DataTable from '../../../common/DataTable'
import Toaster from '../../../common/Toaster'

/** Import section actions */
//import { loadUsersSupplierFromBackEnd } from '../../../../store/suppliers/ExternalUserActions';
import {
	loadNotificationsByRequest,
	removeNotificationsUserProvider,
	addNotificationInUserSpplier,
} from '../../../../store/notifications/NotificationAction'
import { mappedSuppliersExternalOptions } from '../../../../store/helpers/SelectOptions'
import { forwardNotificationToProviderUser } from '../../../../store/expedientSupplier/ExpedientSupplierAction'
import { canBeModuleExpeditByCompany } from '../../../../store/helpers/RolesHelper'
import {
	URL_BASE_EXPEDIENT_SUPPLIER,
	REQUEST_EXPEDIENT_SUPPLIER,
	MODULE_SUPPLIERS,
} from '../../../../store/helpers/NotificationHelper'
import { COLOR_STATUS_DANGER } from '../../../../store/helpers/StatusColorConstants'
import { showWarningToaster } from '../../../../store/helpers/ToasterHelpers'
import { converListToArray } from '../../../../store/helpers/ListHelper'
import { ExpedientSupplierStatus } from '../../../../store/helpers/AppConstants'

/**
 * Evaluation supplier rating
 */
class NotificationsTab extends Component {
	/**
	 *Creates an instance of MenuConfiguration.
	 * @param {*} props
	 * @param {*} context
	 * @memberof MenuConfiguration
	 */
	constructor(props, context) {
		super(props, context)
		autoBind(this)
		this.state = {
			userEnabled: true,
			supplier: null,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
		}
	}

	componentDidMount() {
		let expedientSupplierToken = this.props.match.params.token
		//let supplierToken = this.props.supplier.token;
		//this.props.loadExternalUsers(
		//	this.props.searchQuery,
		//	this.props.sortCriteria.by,
		//	this.props.sortCriteria.ascending,
		//	this.state.userEnabled,
		//	supplierToken
		//);
		this.props.loadNotificationsByRequest(expedientSupplierToken)
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (
			dataSource === 'options1' &&
			canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
			((this.props.supplierExpedient &&
				this.props.supplierExpedient.status === ExpedientSupplierStatus.SEND_SUPPLIERS) ||
				(this.props.supplierExpedient &&
					this.props.supplierExpedient.status === ExpedientSupplierStatus.RESEND_TO_SUPPLIER))
		) {
			return (
				<div style={{ minWidth: '100px !important' }}>
					<Tooltip title={<Translate id='expedientSupplier.removeNotification' />}>
						<IconButton
							component='span'
							disabled={this.props.isRemovingNotification}
							onClick={() => this.onRemoveNotificationsUserProvider(item.RequestId, item.CreatedBy)}
						>
							<DeleteIcon style={{ color: COLOR_STATUS_DANGER }} />
						</IconButton>
					</Tooltip>
					<Tooltip title={<Translate id='expedientSupplier.resendNotification' />}>
						<IconButton
							component='span'
							style={{ marginLeft: '1px' }}
							disabled={this.props.isSavingExpedient}
							onClick={() =>
								this.onForwardNotificationToProviderUser(this.props.match.params.token, item.CreatedBy)
							}
						>
							<EmailIcon color='secondary' />
						</IconButton>
					</Tooltip>
				</div>
			)
		}
	}

	onSupplierChange(option) {
		this.setState({
			supplier: option,
		})
	}

	onForwardNotificationToProviderUser(token, userName) {
		return this.props
			.forwardNotificationToProviderUser(token, userName)
			.then((_) => {
				return Promise.resolve(true)
			})
			.catch((_error) => {
				this.setState(showWarningToaster(_error))
				return Promise.reject(false)
			})
	}

	onRemoveNotificationsUserProvider(requestToken, userName) {
		return this.props.removeNotificationsUserProvider(requestToken, userName)
	}

	onAddNotificationInUserSpplier(token, folio, supplier, modulePortal, urlBase, requestType) {
		return this.onForwardNotificationToProviderUser(token, supplier.value).then((isSuccess) => {
			if (isSuccess) {
				this.props
					.addNotificationInUserSpplier(token, folio, supplier, modulePortal, urlBase, requestType)
					.then((_) => {
						this.setState({
							supplier: null,
						})
					})
					.catch((_error) => {
						this.setState(showWarningToaster(_error))
					})
			}
		})
	}

	/**
	 * perform render component
	 *
	 * @returns
	 * @memberof
	 */
	render() {
		const { notificationsRequest, classes, supplierExpedient, usersByRole } = this.props
		let externalUserOptions = usersByRole.length !== 0 ? mappedSuppliersExternalOptions(usersByRole) : []
		if (this.props.isLoadingRequest === true) {
			return <LoadingPage />
		} else if (notificationsRequest === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<div>
					{canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
						((this.props.supplierExpedient &&
							this.props.supplierExpedient.status === ExpedientSupplierStatus.SEND_SUPPLIERS) ||
							(this.props.supplierExpedient &&
								this.props.supplierExpedient.status ===
									ExpedientSupplierStatus.RESEND_TO_SUPPLIER)) && (
							<Grid container spacing={24} style={{ marginTop: '10px' }}>
								<Grid item xs={8}>
									{/**NOTING CODE */}
									<Typography color='primary' style={{ marginTop: '5px' }}>
										<Translate id='expedientSupplier.notificationsSendSuppliers' />
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
										<div style={{ width: '350px' }}>
											<Select
												placeholder={<Translate id='expedientSupplier.sendNotification' />}
												className={classes.documentTypeSelect}
												options={externalUserOptions}
												onChange={this.onSupplierChange}
												styles={{
													// menu: (base) => ({ ...base, zIndex: 10 })
													menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
													menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
													menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
												}}
												menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
												menuPlacement={'auto'}
												value={this.state.supplier}
											/>
										</div>
										<div>
											<Tooltip title={<Translate id='expedientSupplier.sendNotification' />}>
												<Fab
													component='span'
													size='small'
													color='primary'
													style={{ marginLeft: '4px' }}
													disabled={
														this.state.supplier === null ||
														this.props.isRemovingNotification
													}
													onClick={() =>
														this.onAddNotificationInUserSpplier(
															this.props.match.params.token,
															supplierExpedient ? supplierExpedient.folio : '',
															this.state.supplier,
															MODULE_SUPPLIERS,
															URL_BASE_EXPEDIENT_SUPPLIER,
															REQUEST_EXPEDIENT_SUPPLIER
														)
													}
												>
													<SendSupplierIcon />
												</Fab>
											</Tooltip>
										</div>
									</div>
								</Grid>
							</Grid>
						)}
					<DataTable
						data={notificationsRequest}
						configuration={TableConfiguration}
						onRenderCellItem={this.onRenderCellItem}
					/>

					<Toaster
						message={<Translate id={this.state.toasterMessage} />}
						open={this.state.showToaster}
						variant={this.state.toasterVariant}
						onClose={() => {
							this.setState({ showToaster: false })
						}}
					/>
				</div>
			)
		}
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='common.folio' />,
			dataSource: 'Folio',
		},
		{
			header: <Translate id='common.user' />,
			dataSource: 'CreatedBy',
		},
		{
			header: <Translate id='common.notifiedTo' />,
			dataSource: 'Name',
		},
		{
			header: <Translate id='common.email' />,
			dataSource: 'Email',
		},
		{
			header: <Translate id='common.sendDate' />,
			dataSource: 'CreationDateTime',
		},
		{
			header: <Translate id='common.options' />,
			dataSource: 'options1',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		//isLoadingUser: state.oidc.isLoadingUser,
		//isLoadingUsers: state.externalUsers.get('isLoadingUsers'),
		//externalUsers: state.externalUsers.get('externalUsers') ? state.externalUsers.get('externalUsers').toJS() : null,
		isSaving: state.externalUsers.get('isSavingUser'),
		isLoadingRequest: state.notifications.get('isLoadingRequest'),
		notificationsRequest: state.notifications.get('notificationsRequest')
			? state.notifications.get('notificationsRequest').toJS()
			: null,
		//sortCriteria: state.externalUsers.get('sortCriteria') ? state.externalUsers.get('sortCriteria').toJS() : null,
		//searchQuery: state.externalUsers.get('searchQuery'),
		isRemovingNotification: state.notifications.get('isRemovingNotification'),
		isSavingExpedient: state.expedientSuppliers.get('isSaving'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		//loadExternalUsers: (query, orderBy, sortAscending, userEnabled, supplierToken) => {
		//	dispatch(loadUsersSupplierFromBackEnd(query, orderBy, sortAscending, userEnabled, supplierToken));
		//},
		loadNotificationsByRequest: (requestToken) => {
			dispatch(loadNotificationsByRequest(requestToken))
		},
		removeNotificationsUserProvider: (requestToken, userName) => {
			dispatch(removeNotificationsUserProvider(requestToken, userName))
		},
		forwardNotificationToProviderUser: (token, userName) => {
			return dispatch(forwardNotificationToProviderUser(token, userName))
		},
		addNotificationInUserSpplier: (token, folio, supplier, modulePortal, urlBase, requestType) => {
			return dispatch(addNotificationInUserSpplier(token, folio, supplier, modulePortal, urlBase, requestType))
		},
	}
}

const viewStyles = (theme) => ({
	headerClass: {
		color: theme.palette.primary.main,
	},
	grow: {
		flexGrow: 1,
	},
	secondaryHeading: {
		color: theme.palette.text.secondary,
	},
	displayFlex: {
		display: 'flex',
	},
	justifyContent: {
		justifyContent: 'flex-end',
	},
})

export default withRouter(
	withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(NotificationsTab))
)
