/**Import react section */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import uuidv4 from 'uuid/v4'
/**Import material UI Section*/

import {
    withStyles, Checkbox
} from "@material-ui/core";

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { BACKGROUND_COLOR_GRAY} from '../../../../store/helpers/StatusColorConstants'

import { useExpedientSupplierCustomHookShow } from '../../customHooks/UseCustomHookShow';

const SuppliersHeadersTable = (props) => {
    /**Arrays props section */
    const { mappedHeadersDocuments } = props
    /**Functions props section */
    const {onAddOrRemoveDocumentColumnInExpedientSupplier, isSaving} = useExpedientSupplierCustomHookShow()
    /**Objects props section */
    const { classes } = props
    /**Strings props section */
    const { expedientToken } = props

    const [nomenclatureIdentifier, setNomenclatureIdentifier] = useState([])

    const onAddNomenclatureIdentifier = (nomenclatureName) => {
        let newListIdentifierCodes = [...nomenclatureIdentifier]
        newListIdentifierCodes.push(nomenclatureName)
        setNomenclatureIdentifier(newListIdentifierCodes)
    }

    const onRemoveNomenclatureIdentifier = (nomenclatureName) => {
        let newListIdentifierCodes = nomenclatureIdentifier.filter(nomenclature => nomenclature !== nomenclatureName)
        setNomenclatureIdentifier(newListIdentifierCodes)
    }

    const onExistNomenclatureCode = (nomeclatureName) => nomenclatureIdentifier.includes(nomeclatureName)

    const onChangeCheck = (event, nomenclatureName) => {
        let isChecked = event.target.checked
        isChecked ? onAddNomenclatureIdentifier(nomenclatureName) : onRemoveNomenclatureIdentifier(nomenclatureName)
        onAddOrRemoveDocumentColumnInExpedientSupplier(nomenclatureName, event.target.checked, expedientToken)
    }

    return (

        <TableHead key={uuidv4()}>
            <TableRow>
                {
                    mappedHeadersDocuments.map(column => {
                        return (<TableCell
                            padding={column.title ? "" : "none"}
                            style={{ backgroundColor: column.backgroundColor ? column.backgroundColor : BACKGROUND_COLOR_GRAY,
                                position: column.title === 'Proveedor' ? "sticky" : column.title === '' ? "sticky" : '',
                                left: column.title === 'Proveedor' ? '80px' : column.title === '' ? 0 : '', 
                                zIndex: column.title === 'Proveedor' ? "40" : column.title === '' ? "40" : '', }}
                            className={classes.tableCellHeader}
                            key={uuidv4()}
                            align="center" >
                            {column.title}
                            {(column.nomenclatureName && props.canBeModule === true) && <Checkbox 
                                disabled={isSaving}
                                checked={onExistNomenclatureCode(column.nomenclatureName)}
                                onChange={(event)=>onChangeCheck(event, column.nomenclatureName)} 
                            />}
                        </TableCell>)
                    })
                }
            </TableRow>
        </TableHead>
    )
}

const styles = (theme) => ({
    tableCellHeader: {
        height: "15px",
        position: 'sticky',
        top: 0,
        zIndex: '30'
    },
    colorTextWhite: {
        color: "white"
    },
});

SuppliersHeadersTable.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
    indirectQualifications: PropTypes.object.isRequired
}

SuppliersHeadersTable.defaultProps = {
    isLoading: false,
    indirectQualifications: []
}

export default withRouter(
    withStyles(styles, { withTheme: true })(SuppliersHeadersTable)
);
