import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Tab from '@material-ui/core/Tab'

import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import { Link } from 'react-router-dom'
import NoticesOfChangeIcon from '@material-ui/icons/SwapHorizTwoTone'
import RefreshIcon from '@material-ui/icons/Refresh'
import PowerSettingIcon from '@material-ui/icons/PowerSettingsNew'

import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import SuppliersCard from './components/SuppliersCard'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

import {
	loadNoticeChangeFromBackEnd,
	updateNoticeOfChangeFromBackend,
	finalizeNoticeOfChangeFromBackend,
} from '../../store/noticeChanges/NoticeChangeAction'
import AddCommonItem from '../common/AddCommonItem'
import { AppBar, Tabs } from '@material-ui/core'
import CostSummary from './components/CostSummary'
import { canBeValidateModuleDevelopment } from '../../store/helpers/RolesHelper'
import { converListToArray } from '../../store/helpers/ListHelper'

/**
 * Container show notice
 */
class Show extends Component {
	/**
	 * Create an instance notice
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openUpdateOfficialNotice: false,
			openFinalizeNoticeOfChange: false,
			activeTab: 0,
		}
	}

	/**
	 * On finalize notice of change
	 */
	onFinalizeNoticeOfChange() {
		this.setState({
			openFinalizeNoticeOfChange: true,
		})
	}

	/**
	 * On update notice of change
	 */
	OnUpdateNoticeOfChange() {
		this.setState({
			openUpdateOfficialNotice: true,
		})
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let noticeToken = this.props.match.params.token
		this.props.loadNoticeChange(noticeToken)
	}

	/**
	 * Update information of notice of change
	 */
	onUpdateNoticeOfChangeFronBackend() {
		this.props
			.updateNoticeOfChangeFromBackend()
			.then(() => {
				this.setState({
					openUpdateOfficialNotice: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Finalize un project from notice of change
	 */
	onFinalizeNoticeOfChangeFronBackend() {
		let noticeToken = this.props.match.params.token
		this.props
			.finalizeNoticeOfChangeFromBackend(noticeToken)
			.then(() => {
				this.setState({
					openFinalizeNoticeOfChange: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Method to change the active tab
	 */
	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 * Get the card content
	 *
	 * @memberof Show
	 */
	getCardContent() {
		if (this.props.isLoadingNoticeChange === true) {
			return <LoadingPage />
		} else if (this.props.noticeChange !== null) {
			const { classes } = this.props
			return (
				<Grid container spacing={24}>
					<Grid item xs={6}>
						<InformationMemoCard {...this.props} />
					</Grid>
					<Grid item xs={6}>
						<InformationProgressCard {...this.props} />
					</Grid>
					<Grid item xs={12}>
						<AppBar position='static' color='default'>
							<Tabs
								indicatorColor='secondary'
								textColor='secondary'
								className={classes.styleTab}
								value={this.state.activeTab}
								onChange={this.onClickToActiveTab}
							>
								<Tab label={<Translate id='common.supplier' />} />
								<Tab label={<Translate id='common.costSummary' />} />
							</Tabs>
						</AppBar>
						{this.state.activeTab === 0 && (
							<Grid item xs={12}>
								<Divider />
								<SuppliersCard {...this.props} />
							</Grid>
						)}
						{this.state.activeTab === 1 && (
							<Grid item xs={12}>
								<Divider />
								<CostSummary {...this.props} />
							</Grid>
						)}
					</Grid>
				</Grid>
			)
		}
	}

	render() {
		let folioNotice = this.props.noticeChange ? this.props.noticeChange.folio : ''
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<NoticesOfChangeIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							{this.props.noticeChange &&
								(!this.props.noticeChange.is_closed || !this.props.noticeChange.status_is_closed) &&
								canBeValidateModuleDevelopment(this.props.userRolesByCompany) && (
									<span>
										<Tooltip title={<Translate id='noticeChanges.updateNoticeChange' />}>
											<IconButton
												color='primary'
												data-cy='btn-edit'
												className={classes.edit}
												onClick={this.OnUpdateNoticeOfChange}
											>
												<RefreshIcon />
											</IconButton>
										</Tooltip>

										<Tooltip title={<Translate id='noticeChanges.finalizeNoticeChange' />}>
											<IconButton
												color='primary'
												data-cy='btn-edit'
												className={classes.edit}
												onClick={this.onFinalizeNoticeOfChange}
											>
												<PowerSettingIcon />
											</IconButton>
										</Tooltip>
									</span>
								)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='noticeChanges.noticeInformation' />: <strong>{folioNotice}</strong>
						</Typography>
					}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				{this.state.openUpdateOfficialNotice && (
					<AddCommonItem
						title={<Translate id='noticeChanges.updateNoticeChange' />}
						message={<Translate id='noticeChanges.updateDataNoticeChange' />}
						open={this.state.openUpdateOfficialNotice}
						onClose={() => {
							this.setState({ openUpdateOfficialNotice: false })
						}}
						onSaveItem={this.onUpdateNoticeOfChangeFronBackend}
						isSaving={this.props.isSaving}
						isMessage={true}
					/>
				)}

				{this.state.openFinalizeNoticeOfChange && (
					<AddCommonItem
						title={<Translate id='noticeChanges.finalizeNoticeChange' />}
						message={<Translate id='noticeChanges.finalizeDataNoticeChange' />}
						open={this.state.openFinalizeNoticeOfChange}
						onClose={() => {
							this.setState({ openFinalizeNoticeOfChange: false })
						}}
						onSaveItem={this.onFinalizeNoticeOfChangeFronBackend}
						isSaving={this.props.isSaving}
						isMessage={true}
					/>
				)}

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

/**
 * Information MemoCard
 *
 * @class InformationMemoCard
 * @extends {Component}
 */
class InformationMemoCard extends Component {
	render() {
		return (
			<Paper>
				<List>
					<Divider />
					<ListItem>
						<ListItemText
							primary={
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='memos.project' />
								</Typography>
							}
							secondary={<React.Fragment>{this.props.noticeChange.project_name}</React.Fragment>}
						/>
					</ListItem>
					<Divider />
					<ListItem>
						<ListItemText
							primary={
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='memos.stage' />
								</Typography>
							}
							secondary={<React.Fragment>{this.props.noticeChange.stage}</React.Fragment>}
						/>
					</ListItem>
					<Divider />
				</List>
			</Paper>
		)
	}
}

/**
 * Information MemoCard
 *
 * @class InformationMemoCard
 * @extends {Component}
 */
class InformationProgressCard extends Component {
	render() {
		return (
			<Paper>
				<List>
					<Divider />
					<ListItem>
						<ListItemText
							primary={
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='common.folio' />
								</Typography>
							}
							secondary={<React.Fragment>{this.props.noticeChange.folio}</React.Fragment>}
						/>
					</ListItem>
					<Divider />
					<ListItem>
						<ListItemText
							primary={
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='common.status' />
								</Typography>
							}
							secondary={<React.Fragment>{this.props.noticeChange.status_description}</React.Fragment>}
						/>
					</ListItem>
					<Divider />
					<ListItem>
						<ListItemText
							primary={
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='memos.relationFolio' />
								</Typography>
							}
							secondary={
								<React.Fragment>
									{
										<Link
											to={`/memos/${this.props.noticeChange.related_project_document_base_token}`}
										>
											{this.props.noticeChange.related_project_document_base}
										</Link>
									}
								</React.Fragment>
							}
						/>
					</ListItem>
				</List>
			</Paper>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let noticeChange = state.noticechanges.get('noticeInformation')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		isLoadingNoticeChange: state.noticechanges.get('isLoadingNoticeChange'),
		isSaving: state.noticechanges.get('isSaving'),
		noticeChange: noticeChange ? noticeChange.toJS() : null,
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadNoticeChange: (token) => {
			return dispatch(loadNoticeChangeFromBackEnd(token))
		},
		updateNoticeOfChangeFromBackend: () => {
			return dispatch(updateNoticeOfChangeFromBackend())
		},
		finalizeNoticeOfChangeFromBackend: (token) => {
			return dispatch(finalizeNoticeOfChangeFromBackend(token))
		},
	}
}

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	actionsToolbar: {
		display: 'flex',
	},
	styleTab: {
		backgroundColor: '#fff',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
