import React, { Component } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'
import { Translate, getTranslate } from 'react-localize-redux'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import { connect } from 'react-redux'

const undoRedo = 'undo redo'
const textStyle = 'bold italic underline strikethrough'
const textAlign = 'alignleft aligncenter alignright alignjustify'
const textHierarchy = 'bullist numlist outdent indent'
const fontFormat = 'fontsizeselect forecolor backcolor removeformat'

/**
 * Class compoenent to render HTML Editor
 *
 * @class HtmlEditor
 * @extends {Component}
 */
class HtmlEditor extends Component {
	constructor(props) {
		super(props)
		this.state = {
			refresh: false,
		}
	}

	/**
	 * React lyfe-cycle method
	 * if prop isDisbled was change force to remount component to prevent unexpected behavior
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof HtmlEditor
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisabled !== this.props.isDisabled) {
			this.setState({ refresh: !this.state.refresh }, () => {
				this.setState({ refresh: !this.state.refresh })
			})
		}
	}

	/**
	 *Handle event to call onChange function if onBlur event has fired
	 *
	 * @memberof HtmlEditor
	 */
	handleEditorChange = (e) => {
		if (this.props.onChange) this.props.onChange({ id: e.target.id, value: e.target.getContent() })
		// if (this.props.onEditorChange)
		//     this.props.onEditorChange(e);
	}

	onHandleEditorChange = (content, editor) => {
		if (this.props.onEditorChange) this.props.onEditorChange(content)
	}

	render() {
		return (
			<Grid container spacing={0}>
				{this.props.label && (
					<Grid item xs={12}>
						<Typography
							variant={this.props.labelVariant && this.props.labelVariant}
							color={this.props.labelColor && this.props.labelColor}
						>
							{this.props.label && <Translate id={this.props.label} />}
						</Typography>
					</Grid>
				)}
				{!this.state.refresh && (
					<Grid item xs={12}>
						<Editor
							id={this.props.id}
							apiKey={`${process.env.TINY_API_KEY}`}
							init={{
								height: this.props.height ? this.props.height : 250,
								plugins: [
									'autoresize noneditable autolink spellchecker',
									'insertdatetime media table paste code help wordcount',
								],
								menubar: false, //To hide Menu bar
								statusbar: false, //To hide bottom bar where show status and Label: "Powered by TinyMCE"
								browser_spellcheck: true, //To enable spell checker integrated with de browser
								toolbar_drawer: 'floating', //to group toolbar options if space is smaller than required to show all options
								toolbar:
									!this.props.isDisabled &&
									`${undoRedo} | ${textStyle} | ${textAlign} | ${textHierarchy} | ${fontFormat} | table`,
							}}
							onBlur={this.handleEditorChange}
							onEditorChange={this.onHandleEditorChange}
							value={this.props.initialValue}
							disabled={this.props.isDisabled}
							inline={this.props.isDisabled} //If is disabled must be render InLine
							initialValue={
								IsNullOrEmpty(this.props.initialValue)
									? this.props.isDisabled
										? `<caption>-${this.props.translate('common.withoutData')}-</caption>`
										: ''
									: this.props.initialValue
							}
						/>
					</Grid>
				)}
			</Grid>
		)
	}
}

HtmlEditor.propTypes = {
	id: PropTypes.string.isRequired, //Id requiered to determine property to update in state
	label: PropTypes.string, //id translation of Label to show over Editor
	labelVariant: PropTypes.string, //variant typography of label to show over Editor
	labelColor: PropTypes.string, //color text typography of label to show over Editor
	isDisabled: PropTypes.bool.isRequired, //Determine if component must be disabled and can not be editable
	initialValue: PropTypes.string, //Determine initial value in Editor
	onChange: PropTypes.func, //Function required to handle change of value of editor, only is required if Editor is to edit, no need function if is only to show data
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		translate: getTranslate(state.localize),
	}
}

export default connect(mapStateToProps, null)(HtmlEditor)
