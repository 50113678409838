import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate, getTranslate } from 'react-localize-redux'
import { Link } from 'react-router-dom'
import LinkMaterial from '@material-ui/core/Link'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import { CardHeader, Fab, Tooltip } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Dialog from '../common/Dialog'
import DialogActions from '../common/DialogActions'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import HistoryCard from '../common/HistoryCard'
import FloatingButton from '../common/FloatingButton'
import DialogAvailableOperations from '../common/DialogAvailableOperations'
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow'
import DataTable from '../common/DataTable'
import DetailsCard from './components/DetailsCard'
import WorkflowCancel from '../common/WorkflowCancel'
import HtmlEditor from '../common/HtmlEditor'
import QuotesIcon from '@material-ui/icons/AttachMoneyTwoTone'
import ModalUpdateQuoteMasivaBySupplier from './components/ModalUpdateQuoteMasivaBySupplier'
import RefreshIcon from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'

/** Import actions and helpers section */
import { loadSupplier, loadPartnership } from '../../store/suppliers/SupplierActions'
import {
	loadQuoteFromBackEnd,
	declineQuoteBySupplier,
	acceptedOrRejectedDetailItemEnginner,
	sendRequoteToSupplier,
	acceptRejectRequoteDetailItemsEnginner,
	sendQuoteToDevelopmentEngineer,
	quoteAcceptedByEngineer,
	quoteRejectedByEngineer,
	updateQuoteDetailItem,
	declineQuoteItem,
	updateQuoteProperty,
	uploadQuoteFiles,
	removeQuoteFiles,
	updateQuoteToolingDetailItem,
	updateQuoteCubicajeDetailItem,
	addQuoteScalesDetailItem,
	deleteQuoteScalesDetailItem,
	activateQuoteToDevelopmentEngineer,
	generatedNewPDFQuote,
	downloadDetailsQuoteFromSupplierToResponse,
	downloadQuoteWithDetailsFromDevelopment,
	responseQuoteMaterialListBySupplier,
	updateIncotermInQuote,
} from '../../store/quotes/QuotesActions'
import { downloadPlotArtFiles } from '../../store/partNumbers/PartNumberAction'

/** Resources import section */
import { loadCompany } from '../../store/companies/CompanyActions'
import { SHOW_EDIT_VIEW, APPROVAL_REQUOTE, APPROVAL_DECLINED } from '../../store/helpers/ResourcesHelper'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import { converListToArray } from '../../store/helpers/ListHelper'
import {
	WORKFLOW_REMINDER,
	QUOTE_RESPONSE,
	QUOTE_REQUOTE_DETAILS_ITEMS,
	WORKFLOW_CANCELLATION,
	WORKFLOWREACTIVE,
} from '../../store/helpers/WorkFlowDocumentsType'
import {
	CaBeCancelQuote,
	IsUserInRole,
	ROLE_COMPANYADMINISTRATOR,
	ROLE_LOGISTICMANAGER,
	canBeValidateModuleDevelopment,
} from '../../store/helpers/RolesHelper'
import { GetMeasurementUnits, GetTypeQuote } from '../../store/helpers/SelectOptions'
import { IsCurrentNotActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import { COMPANY_IDENTIFIER_IMBERA_MX, COMPANY_PTM } from '../../store/helpers/ResourcesHelper'

/**
 * Container show quotes
 */
class Show extends Component {
	/**
	 * Create an instance ofMemos
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAvailableOperationsDialog: false,
			openDeclineRequest: false,
			commentsDecline: '',
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			acceptedEnginnerDialog: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let quoteToken = this.props.match.params.token
		this.props.loadQuote(quoteToken)
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoadingQuote !== this.props.isLoadingQuote && this.props.quote !== null) {
			/// Get information full from quote
			let quote = this.props.quote
			this.props.loadCompany(quote.company_token)
			this.props.loadPartnership(quote.supplier_code, quote.company_token)

			this.props
				.loadSupplier(quote.supplier_token)
				.then(() => {
					if (
						quote.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
						(quote.currency_type === '' || quote.incoterm === '')
					) {
						this.props.updateIncotermInQuote(this.props.quote.token)
					}
				})
				.catch(() => {})
		}
	}

	/**
	 * onSendQuoteRequestedToSupplier
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onSendQuoteRequestedToSupplier() {
		if (this.hasValidDetailsData())
			this.props
				.sendQuoteRequestedToSupplier(
					this.props.quote.token,
					this.props.partnership,
					this.props.quote.incoterm,
					this.props.quote.currency_type
				)
				.then(() => {
					this.showSuccessToaster()
					this.setState({
						openAvailableOperationsDialog: false,
					})
				})
				.catch(() => {
					this.showErrorToaster()
				})
		else this.showErrorToaster('quotes.invalidDetailsData')
	}

	/**
	 * Function to generated new pdf
	 */
	onGeneratedNewPDFQuote() {
		this.props.generatedNewPDFQuote(
			this.props.quote.token,
			this.props.partnership,
			this.props.quote.incoterm,
			this.props.quote.currency_type
		)
	}

	/**
	 * onDeclineQuoteBySupplier
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onDeclineQuoteBySupplier() {
		this.setState({
			openAvailableOperationsDialog: false,
			openDeclineRequest: true,
		})
	}

	/**
	 * Function to add comment to decline
	 * @param {*} commentsDecline
	 */
	updateDeclineProperty(commentsDecline) {
		this.setState({
			commentsDecline: commentsDecline,
		})
	}

	/**
	 * onSaveDeclineQuote
	 */
	onSaveDeclineQuote() {
		if (this.state.commentsDecline) {
			this.props
				.declineQuoteBySupplier(this.props.quote.token, this.state.commentsDecline)
				.then(() => {
					this.showSuccessToaster()
					this.setState({
						openDeclineRequest: false,
					})
				})
				.catch(() => {
					this.showErrorToaster()
				})
		}
	}

	/**
	 * Action on accpeted quote by engineer
	 */
	onQuoteAcceptedByEngineer() {
		this.props
			.quoteAcceptedByEngineer(this.props.quote.token)
			.then(() => {
				this.showSuccessToaster()
				this.setState({
					openAvailableOperationsDialog: false,
				})
			})
			.catch(() => {
				this.showErrorToaster()
			})
	}

	/**
	 * Action on rejecteed quote by engineer
	 */
	onQuoteRejectedByEngineer() {
		this.props
			.quoteRejectedByEngineer(this.props.quote.token)
			.then(() => {
				this.showSuccessToaster()
				this.setState({
					openAvailableOperationsDialog: false,
				})
			})
			.catch(() => {
				this.showErrorToaster()
			})
	}

	/**
	 * Action on onSendRequoteToSupplier
	 */
	onSendRequoteToSupplier() {
		this.props
			.sendRequoteToSupplier(this.props.quote.token)
			.then(() => {
				this.showSuccessToaster()
				this.setState({
					openAvailableOperationsDialog: false,
				})
			})
			.catch(() => {
				this.showErrorToaster()
			})
	}

	/**
	 * Cancel a quote
	 */
	onCancelDocument() {
		this.props.history.push(`/quotes/cancel/${this.props.quote.token}`)
	}

	/**
	 * Redirects in view of reminders
	 */
	onReminderToSupplier() {
		this.props.history.push(`/quotes/reminder/${this.props.quote.token}`)
	}

	/**
	 * Method to validate if details list has full required information
	 *
	 * @returns
	 * @memberof Show
	 */
	hasValidDetailsData() {
		let invalidItem = this.props.quote.details.find((detail) => {
			if (detail.status !== APPROVAL_DECLINED) {
				return (
					IsNullOrEmpty(detail.cost_type) ||
					(detail.cost_type !== '4' &&
						(IsNullOrEmpty(String(detail.unitary_cost)) || detail.unitary_cost <= 0)) ||
					(detail.cost_type !== '4' &&
						(IsNullOrEmpty(String(detail.measurement_unit)) || detail.measurement_unit <= 0))
				)
			}
			return null
		})
		return !invalidItem
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "saveCorrectly"
	 *
	 * @param {string} [idTranslation="common.saveCorrectly"]
	 * @memberof Show
	 */
	showSuccessToaster(idTranslation = 'common.saveCorrectly') {
		this.setState({
			showToaster: true,
			toasterVariant: 'success',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 *
	 * @param {string} [idTranslation="common.downloadCorrectly"]
	 * @member Show
	 */
	showDownloadSuccessToaster(idTranslation = 'common.downloadCorrectly') {
		this.setState({
			showToaster: true,
			toasterVariant: 'success',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "errorToSave"
	 *
	 * @param {string} [idTranslation="common.errorToSave"]
	 * @memberof show
	 */
	showErrorToaster(idTranslation = 'common.errorToSave') {
		this.setState({
			showToaster: true,
			toasterVariant: 'error',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 *
	 * @param {string} [idTranslation="common.errorToDownload"]
	 * @member show
	 */
	showDownloadErrorToaster(idTranslation = 'common.errorToDownloadFile') {
		this.setState({
			showToaster: true,
			toasterVariant: 'error',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Action on active quote to development
	 */
	onActivateQuoteToDevelopmentEngineer() {
		this.props
			.activateQuoteToDevelopmentEngineer(this.props.quote.token)
			.then(() => {
				this.showSuccessToaster()
			})
			.catch(() => {
				this.showErrorToaster()
			})
	}

	/**
	 * On action to open modal masiva
	 */
	onClickOptionMasivaMaterials() {
		this.setState({ openAddMisiva: true })
	}

	onMaterialsMassiva(attachments) {
		return this.props
			.responseQuoteMaterialListBySupplier(this.props.quote.token, attachments)
			.then(() => {
				this.showSuccessToaster()
				this.setState({ openAddMisiva: false })
				return Promise.resolve('Ok')
			})
			.catch((response) => {
				this.showErrorToaster()
				return Promise.reject()
			})
	}

	onDownloadPlotFile(lot, companyToken, kind, formatype) {
		return this.props
			.downloadPlotArtFiles(lot, companyToken, kind, formatype)
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorNotExtiFile' />,
					toasterVariant: 'warning',
				})
				return Promise.reject()
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Show
	 */
	renderCardContent() {
		if (this.props.isLoadingQuote === true) {
			return <LoadingPage />
		} else if (this.props.quote !== null) {
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<List>
							<Divider />
							<ListItem>
								<ListItemText
									secondary={
										<Typography component='span' variant='h5' color='textSecondary'>
											<strong>
												{' '}
												<Translate id='quotes.responsible' />:
												{this.props.quote.developer_engineer}
											</strong>
										</Typography>
									}
								/>
								<ListItemSecondaryAction>
									{this.props.quote.is_closed && this.props.quote.closing_date && (
										<strong>
											<Translate id='quotes.closingDate' />:{this.props.quote.closing_date}
										</strong>
									)}
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem>
								{this.props.quote.developer_comments && (
									<ListItemText
										primary={
											<HtmlEditor
												id='developer_comments'
												label='quotes.developerComments'
												labelVariant='subtitle1'
												isDisabled
												initialValue={this.props.quote.developer_comments}
											/>
										}
									/>
								)}
								{!this.props.quote.developer_comments && (
									<ListItemText
										primary={
											<Grid container style={{ maxHeight: 90, overflowY: 'auto' }}>
												{this.props.quote.details.map((detail) => {
													if (!IsNullOrEmpty(detail.comment_detail)) {
														return (
															<Grid item xs={12}>
																<Typography variant='body2' component='p'>
																	<strong>
																		{`${
																			!IsNullOrEmpty(detail.part_number)
																				? detail.part_number
																				: ''
																		}: `}
																	</strong>
																	{detail.comment_detail}
																</Typography>
															</Grid>
														)
													} else return ''
												})}
											</Grid>
										}
									/>
								)}
								{canBeValidateModuleDevelopment(this.props.userRolesByCompany) && (
									<ListItemSecondaryAction>
										<Tooltip title={<Translate id={'quotes.dowloadDetail'} />}>
											<Fab
												size='small'
												color='secondary'
												onClick={() => {
													this.props.downloadQuoteWithDetailsFromDevelopment(
														this.props.match.params.token
													)
												}}
											>
												<DownloadIcon />
											</Fab>
										</Tooltip>

										{this.props.quote.is_closed &&
											(IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) ||
												IsUserInRole(ROLE_LOGISTICMANAGER, this.props.userRolesByCompany)) &&
											this.props.quote.actual_operation !== 21 && (
												<Tooltip title={<Translate id='quotes.activateQuote' />}>
													<IconButton
														color='primary'
														data-cy='btn-active'
														disabled={this.props.isSaving}
														onClick={this.onActivateQuoteToDevelopmentEngineer}
													>
														<RefreshIcon />
													</IconButton>
												</Tooltip>
											)}
									</ListItemSecondaryAction>
								)}

								{IsCurrentNotActiveDirectoryUser(this.props.currentUser) &&
									this.props.quote.update_quote_by_supplier && (
										<ListItemSecondaryAction>
											{' '}
											<Tooltip title={<Translate id={'quotes.messageToDowloadDetails'} />}>
												<Fab
													size='small'
													color='secondary'
													onClick={() => {
														this.onClickOptionMasivaMaterials()
													}}
												>
													<DownloadIcon />
												</Fab>
											</Tooltip>
										</ListItemSecondaryAction>
									)}
							</ListItem>
						</List>
					</Grid>
					<Grid item xs={12}>
						<DetailsCard
							showSuccessToaster={this.showSuccessToaster}
							showErrorToaster={this.showErrorToaster}
							downloadPlotFiles={this.onDownloadPlotFile}
							{...this.props}
						/>
					</Grid>
					{canBeValidateModuleDevelopment(this.props.userRolesByCompany) &&
						this.props.quote.can_generated_new_pdf && (
							<Grid item xs={12}>
								<Fab
									variant='extended'
									size='small'
									color='primary'
									onClick={this.onGeneratedNewPDFQuote}
									disabled={this.props.isSaving}
								>
									<QuotesIcon />
									<Typography style={{ color: '#fff' }} variant='caption'>
										{this.props.isSaving ? (
											<Translate id='common.downloadingReport' />
										) : (
											<Translate id='common.generatingPdf' />
										)}
									</Typography>
								</Fab>
							</Grid>
						)}
				</Grid>
			)
		}
	}

	/**
	 * Action to set state to open of available operations dialog
	 * @memberof
	 */
	setOpenDialog = (openAvailable) => {
		this.setState({
			openAvailableOperationsDialog: openAvailable,
		})
	}

	/**
	 * Method to validate available options to show on DialogAvailableOperations
	 *
	 * @param {*} availableOptions
	 * @returns
	 * @memberof Show
	 */
	validateAvailableOptions(availableOptions) {
		let hasRequote =
			this.props.quote &&
			this.props.quote.details.find((detail) => {
				return detail.status === APPROVAL_REQUOTE
			})
		if (hasRequote) {
			availableOptions = availableOptions.filter((option) => {
				return option !== 709
			})
		} else {
			availableOptions = availableOptions.filter((option) => {
				return option !== 705
			})
		}
		return availableOptions
	}

	/**
	 * Render quote donload
	 * @param {*} document
	 */
	renderResponseQuote(document) {
		return (
			<div>
				{this.props.quote.company_identifier === COMPANY_PTM && (
					<LinkMaterial
						component={'a'}
						target='_blank'
						href={`${process.env.REACT_APP_SIP_URL_FILES}/Documents/Quotes/${document.token}.pdf`}
					>
						<DownloadIcon style={{ marginTop: '-5px' }} /> {<Translate id='quotes.quoteDownload' />}
					</LinkMaterial>
				)}

				{this.props.quote.company_identifier !== COMPANY_PTM && (
					<Link onClick={() => this.onGeneratedNewPDFQuote()} to='#'>
						<DownloadIcon style={{ marginTop: '-5px' }} />{' '}
						{this.props.isSaving ? (
							<Translate id='common.downloadingReport' />
						) : (
							<Translate id='quotes.quoteDownload' />
						)}
					</Link>
				)}
			</div>
		)
	}

	renderRequote(document) {
		return (
			<DataTable
				data={document.requote_items.length > 0 && document.requote_items}
				configuration={TableRequoteDetails()}
				onRenderCellItem={this.onRenderRequoteCellItem}
			/>
		)
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderRequoteCellItem(dataSource, item) {
		let optionsCostType = GetTypeQuote(this.props.quote.company_identifier)
		let optionsMeasurementUnit = GetMeasurementUnits()
		if (dataSource === 'cost_type') {
			if (item.cost_type !== null && item.cost_type !== '') {
				return `${optionsCostType[item.cost_type].label}`
			}
		}
		if (dataSource === 'volume') {
			if (item.volumneUnit !== null && item.volumneUnit !== '') {
				return (
					<span>
						{' '}
						{item.volume} {item.volumneUnit !== 0 && optionsMeasurementUnit[item.volumneUnit].label}{' '}
					</span>
				)
			}
		}
		if (dataSource === 'measurement_unit') {
			if (item.measurement_unit !== null && item.measurement_unit !== '') {
				return `${optionsMeasurementUnit[item.measurement_unit].label}`
			}
		}
	}

	renderReactiveQuote(comment) {
		return (
			<Grid container spacing={24}>
				<CardHeader
					style={{ paddingTop: '0px' }}
					title={
						<Typography gutterBottom variant='body2' component='p'>
							<strong>
								<Translate id='quotes.messageReactiveQuote' />
							</strong>
						</Typography>
					}
					subheader={
						<Typography gutterBottom variant='body2' component='p'>
							{comment}
						</Typography>
					}
				/>
			</Grid>
		)
	}

	/**
	 * Render hystory section for all documents
	 *
	 * @returns
	 * @memberof Show
	 */
	renderHistory() {
		let documents = this.props.quote ? (this.props.quote.documents ? this.props.quote.documents : []) : []
		return documents.map((document) => {
			let titleId
			let aditionalData

			if (document.document_type === WORKFLOW_REMINDER) {
				titleId = 'common.reminderToSupplier'
				aditionalData = this.renderAditionalDataReminder(document)
			}
			if (document.document_type === QUOTE_RESPONSE) {
				titleId = 'quotes.quoteSupplierResponse'
				aditionalData = this.renderResponseQuote(document)
			}
			if (document.document_type === QUOTE_REQUOTE_DETAILS_ITEMS) {
				titleId = 'quotes.requestToRequote'
				aditionalData = this.renderRequote(document)
			}
			if (document.document_type === WORKFLOW_CANCELLATION) {
				titleId = 'common.folioCancel'
				aditionalData = <WorkflowCancel document={document} />
			}
			if (document.document_type === WORKFLOWREACTIVE) {
				titleId = 'quotes.rectiveQuote'
				aditionalData = document.comments && this.renderReactiveQuote(document.comments)
			}
			return (
				<HistoryCard
					key={document.token}
					title={titleId}
					userCreator={document.create_by}
					creationDate={document.creation_date_long}
					aditionalData={aditionalData}
				/>
			)
		})
	}

	/**
	 * Render hystoryCard for reminder
	 *
	 * @param {*} folio
	 * @param {*} status
	 * @returns
	 * @memberof Show
	 */
	renderAditionalDataReminder(document) {
		return (
			<Grid container spacing={24}>
				<Grid item xs={12}>
					<Typography gutterBottom variant='body2' component='p'>
						<strong>
							<Translate id='common.mensajes' />:
						</strong>
						<br />
					</Typography>
					<Typography gutterBottom variant='body2' component='p'>
						{' ' + document.reminder_decription}
						<br />
						<br />
						<strong>
							<Translate id='common.sendTo' />:
						</strong>
						<DataTable
							data={document.users_to_send_reminder}
							configuration={{
								columns: [
									{
										header: <Translate id='users.name' />,
										dataSource: 'name',
									},
								],
							}}
						/>
					</Typography>
				</Grid>
			</Grid>
		)
	}

	render() {
		const { classes } = this.props
		let availableOptions = this.props.quote ? this.props.quote.get_available_operations : []
		availableOptions = this.validateAvailableOptions(availableOptions)
		return (
			<Card>
				{availableOptions.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

				<LayoutHeaderWorkFlow
					{...this.props}
					workFlow={this.props.quote}
					isQuote={true}
					title={<Translate id='quotes.quote' />}
				/>
				<CardContent>{this.renderCardContent()}</CardContent>

				{this.renderHistory()}

				<DialogAvailableOperations
					{...this.props}
					options={availableOptions}
					isValidToCancel={CaBeCancelQuote(this.props.userRolesByCompany)}
					openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
					setOpenDialog={this.setOpenDialog}
					onDeclineQuoteBySupplier={this.onDeclineQuoteBySupplier}
					sendQuoteRequestedToSupplier={this.onSendQuoteRequestedToSupplier}
					onQuoteAcceptedByEngineer={this.onQuoteAcceptedByEngineer}
					onQuoteRejectedByEngineer={this.onQuoteRejectedByEngineer}
					onSendRequoteToSupplier={this.onSendRequoteToSupplier}
					onCancelDocument={this.onCancelDocument}
					onReminderToSupplier={this.onReminderToSupplier}
				/>

				<ModalUpdateQuoteMasivaBySupplier
					open={this.state.openAddMisiva}
					dropzoneText={this.props.translate('dropzone.dropzoneBaseText')}
					onClose={() => {
						this.setState({ openAddMisiva: false })
					}}
					onMaterialsMassiva={this.onMaterialsMassiva}
					onDownloadDetailsQuote={() => {
						this.props.downloadDetailsQuoteFromSupplierToResponse(this.props.match.params.token)
					}}
					isSaving={this.props.isSaving}
				/>

				{/** Dialog to update information */}
				<Dialog
					open={this.state.openDeclineRequest}
					onClose={() => {
						this.setState({ openDeclineRequest: false })
					}}
					header={
						<Toolbar>
							<Typography variant='h6'>
								{<Translate id='statusDescriptions.quoteDeclinedBySupplier' />}
							</Typography>
						</Toolbar>
					}
					actions={
						<DialogActions
							view={SHOW_EDIT_VIEW}
							classes={classes}
							onCancel={() => {
								this.setState({ openDeclineRequest: false })
							}}
							onClose={() => {
								this.setState({ openDeclineRequest: false })
							}}
							onSaveItem={this.onSaveDeclineQuote}
							isSaving={this.props.isSaving}
							canBeSaved={this.state.commentsDecline !== ''}
						/>
					}
				>
					<form>
						<TextField
							fullWidth
							label={<Translate id='samplesrequest.motiveDecline' />}
							className={classes.textField}
							multiline
							rowsMax='8'
							value={this.state.commentsDecline}
							onChange={(event) => {
								this.updateDeclineProperty(event.target.value)
							}}
							onKeyDown={this.onKeyDown}
							margin='normal'
							variant='outlined'
						/>
					</form>
				</Dialog>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableRequoteDetails() {
	let columns = [
		{
			header: <Translate id='quotes.codeSupplier' />,
			dataSource: 'supplier_code',
		},
		{
			header: <Translate id='quotes.partNumberImbera' />,
			dataSource: 'part_number',
		},
		{
			header: <Translate id='quotes.plane' />,
			dataSource: 'plane',
		},
		{
			header: <Translate id='quotes.typeQuote' />,
			dataSource: 'cost_type',
		},
		{
			header: <Translate id='quotes.estimatedVolume' />,
			dataSource: 'volume',
		},
		{
			header: <Translate id='quotes.unitPrice' />,
			dataSource: 'unitary_cost',
		},
		{
			header: <Translate id='quotes.measuremetUnit' />,
			dataSource: 'measurement_unit',
		},
		{
			header: <Translate id='quotes.requote' />,
			dataSource: 'accepted_or_reject_comment',
		},
		{
			header: <Translate id='quotes.totalPrice' />,
			dataSource: 'total_cost',
		},
	]
	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let quote = state.quotes.get('quote')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		currentUser: user,
		isLoadingQuote: state.quotes.get('isLoadingQuote'),
		quote: quote ? quote.toJS() : null,
		company: state.companies.get('company') ? state.companies.get('company').toJS() : null,
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		partnership: state.suppliers.get('partnership') ? state.suppliers.get('partnership').toJS() : null,
		isSaving: state.quotes.get('isSaving'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadQuote: (token) => {
			return dispatch(loadQuoteFromBackEnd(token))
		},
		loadCompany: (token) => {
			return dispatch(loadCompany(token))
		},
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		loadPartnership: (supplierCode, companyToken) => {
			return dispatch(loadPartnership(supplierCode, companyToken))
		},
		updateIncotermInQuote: (quoteToken) => {
			return dispatch(updateIncotermInQuote(quoteToken))
		},
		updateDetailItemQuote: (quoteToken, updateDetail) => {
			return dispatch(updateQuoteDetailItem(quoteToken, updateDetail))
		},
		sendQuoteRequestedToSupplier: (quoteToken, supplier, incoterm, currency_type) => {
			return dispatch(sendQuoteToDevelopmentEngineer(quoteToken, supplier, incoterm, currency_type))
		},
		declineQuoteBySupplier: (quoteToken, comment) => {
			return dispatch(declineQuoteBySupplier(quoteToken, comment))
		},
		acceptedOrRejectedDetailItemEnginner(itemToken, commnet, status) {
			dispatch(acceptedOrRejectedDetailItemEnginner(itemToken, commnet, status))
		},
		acceptRejectRequoteDetailItemsEnginner(itemTokens, commnet, status) {
			dispatch(acceptRejectRequoteDetailItemsEnginner(itemTokens, commnet, status))
		},
		quoteAcceptedByEngineer: (quoteToken) => {
			return dispatch(quoteAcceptedByEngineer(quoteToken))
		},
		quoteRejectedByEngineer: (quoteToken) => {
			return dispatch(quoteRejectedByEngineer(quoteToken))
		},
		sendRequoteToSupplier: (quoteToken) => {
			return dispatch(sendRequoteToSupplier(quoteToken))
		},
		declineQuoteItem: (quoteItemToken, comment) => {
			return dispatch(declineQuoteItem(quoteItemToken, comment))
		},
		updateQuoteProperty: (quoteToken, property, propertyData) => {
			return dispatch(updateQuoteProperty(quoteToken, property, propertyData))
		},
		uploadFiles: (quoteToken, files) => {
			return dispatch(uploadQuoteFiles(quoteToken, files))
		},
		removeFiles: (quoteToken, fileName) => {
			return dispatch(removeQuoteFiles(quoteToken, fileName))
		},
		updateQuoteToolingDetailItem: (updateDetail) => {
			return dispatch(updateQuoteToolingDetailItem(updateDetail))
		},
		updateQuoteCubicajeDetailItem: (updateDetail) => {
			return dispatch(updateQuoteCubicajeDetailItem(updateDetail))
		},
		addQuoteScalesDetailItem: (scaleData, itemToken) => {
			return dispatch(addQuoteScalesDetailItem(scaleData, itemToken))
		},
		deleteQuoteScalesDetailItem: (scaleData, itemToken) => {
			return dispatch(deleteQuoteScalesDetailItem(scaleData, itemToken))
		},
		activateQuoteToDevelopmentEngineer: (token) => {
			return dispatch(activateQuoteToDevelopmentEngineer(token))
		},
		generatedNewPDFQuote: (quoteToken, supplier, incoterm, currency_type) => {
			return dispatch(generatedNewPDFQuote(quoteToken, supplier, incoterm, currency_type))
		},
		downloadDetailsQuoteFromSupplierToResponse: (quoteToken) => {
			return dispatch(downloadDetailsQuoteFromSupplierToResponse(quoteToken))
		},
		downloadQuoteWithDetailsFromDevelopment: (quoteToken) => {
			return dispatch(downloadQuoteWithDetailsFromDevelopment(quoteToken))
		},
		responseQuoteMaterialListBySupplier: (quoteToken, attachments) => {
			return dispatch(responseQuoteMaterialListBySupplier(quoteToken, attachments))
		},
		downloadPlotArtFiles: (lot, companyToken, kind, formatype) => {
			return dispatch(downloadPlotArtFiles(lot, companyToken, kind, formatype))
		},
	}
}

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	actionsToolbar: {
		flexGrow: 1,
	},
	logoCompany: {
		maxHeight: 100,
	},
	logoSupplier: {
		marginLeft: 80,
		maxHeight: 80,
	},
	tableHead: {
		backgroundColor: '#fff',
		position: 'sticky',
		zIndex: '30',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 500,
		marginTop: 10,
		marginBottom: 10,
	},
	tableContainer: {
		maxWidth: 200,
	},
	parnertPadding: {
		padding: 15,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
