import React from 'react'
import { Translate } from 'react-localize-redux'
//Import react section
import { TextField, Typography } from '@material-ui/core'
import { Error } from '@material-ui/icons'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const CreateManualDelivery = (props) => {
	const { classes, onChange, information, onChangeCheck } = props
	console.log('information=>>>>>>>>>>>>>>>>>', information)
	const isValidValue =
		information && information.deliveryNumber && information.deliveryNumber.length >= 6 ? true : false
	return (
		<div>
			<FormGroup row>
				<FormControlLabel
					control={<Checkbox checked={information.noDeliveryNumber} name='noDeliveryNumber' />}
					label='No aplica entrega'
					onChange={onChangeCheck}
				/>
			</FormGroup>
			{!information.noDeliveryNumber && (
				<>
					<div className={classes.title}>
						<Typography>
							<Translate id={'carrierTenders.deals.deliveryPanel.title'} />{' '}
						</Typography>
					</div>
					<TextField
						id='standard-with-placeholder'
						placeholder='Entrega'
						onChange={onChange}
						value={information.deliveryNumber}
						name='deliveryNumber'
						style={classes.textField}
						inputProps={{
							style: {
								height: '10px',
							},
						}}
						size='small'
						margin='dense'
						fullWidth
						variant='outlined'
						type='number'
						error={!isValidValue}
						helperText={
							isValidValue ? null : (
								<div className={classes.errorContent}>
									<Error color='red' />
									<Typography className={classes.errorMessage}>
										<Translate id={'carrierTenders.deals.deliveryPanel.errorMessage'} />
									</Typography>
								</div>
							)
						}
					/>
				</>
			)}
		</div>
	)
}

export default CreateManualDelivery
