import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

/** Material UI imports section */
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Fab from '@material-ui/core/Fab'
import CardContent from '@material-ui/core/CardContent'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'

/** Material UI icons imports section */
import AddIcon from '@material-ui/icons/AddTwoTone'
import EditIcon from '@material-ui/icons/Edit'
import PrintIcon from '@material-ui/icons/Print'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import AlertOutlineIcon from '@material-ui/icons/ErrorOutline'
import AlertIcon from '@material-ui/icons/Error'
import AcceptIcon from '@material-ui/icons/ThumbUp'
import Button from '@material-ui/core/Button'
import RawMaterialNonConfIcon from '@material-ui/icons/BugReport'

/** Component imports section */
import DataTable from '../../../common/DataTable'
import OptionsMenu from '../../../common/OptionsMenu'
import FilesLinks from './../../../common/FilesLinks'
import OptionsDeliveryOrder from './OptionsDeliveryOrder'
import Select from 'react-select'

/** Actions and helpers imports section */
import {
	COLOR_STATUS_SUCCESS,
	BACKGROUND_COLOR_GRAY,
	COLOR_STATUS_ACEPTED,
} from './../../../../store/helpers/StatusColorConstants'
import { GetWarehouses, getSelectedValue, GetRamps } from '../../../../store/helpers/SelectOptions'
import { canBeOperationDeliveryWarehouse, canBeModulePlaneationSupplier } from '../../../../store/helpers/RolesHelper'
import { IsUserSupplierAdministrator } from '../../../../store/helpers/SessionHelper'

class MaterialsTable extends Component {
	/**
	 *Creates an instance of MaterialsTable.
	 * @param {*} props
	 * @memberof MaterialsTable
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			itemToken: '',
			isOnEdit: false,
			item: this.initialItemState(),
		}
	}

	/**
	 * Return initial data values to item
	 *
	 * @returns
	 * @memberof MaterialsTable
	 */
	initialItemState() {
		return {
			token: null,
			part_number_code: null,
			purchase_order: null,
			position: null,
			revision_confirmation: null,
			quantity_of_material: null,
			lot: null,
			expected_quantity_today: null,
			revision: null,
		}
	}

	/**
	 * React life-cycle
	 *
	 * @param {*} nextProps
	 * @param {*} nextState
	 * @returns
	 * @memberof MaterialsTable
	 */
	shouldComponentUpdate(nextProps, nextState) {
		//Prevent rerender compoenent if item token in state does not
		if (this.state.item.token === nextState.item.token && this.state.isOnEdit) return false
		return true
	}

	/**
	 * Handle state to set token of item to show options menu list
	 *
	 * @param {*} itemToken
	 * @memberof ProveduresDetails
	 */
	handleClickOptionsMenu(itemToken) {
		this.setState({
			itemToken,
			item: this.initialItemState(),
			isOnEdit: false,
		})
	}

	/**
	 * Handle state to show or hide menu options list
	 *
	 * @memberof ProveduresDetails
	 */
	handleCloseOptionsMenu() {
		if (!this.state.showMemoItemDialog || !this.state.showAttachmentsDialog) {
			this.setState((state) => ({
				itemToken: '',
			}))
		}
	}

	/**
	 * Set Custom background color foreach detail row
	 *
	 * @memberof DetailsCard
	 */
	setCustomColorRow() {
		this.props.data.map((item) => {
			var customColor
			if (item.has_been_accepted_in_warehouse === true) customColor = COLOR_STATUS_ACEPTED

			return (item.custom_color = customColor)
		})
	}

	/**
	 * Handle update properties to set data in local state
	 *
	 * @param {*} property
	 * @param {*} data
	 * @memberof AddMaterialModal
	 */
	updatePropertyInState(property, data) {
		this.setState({
			item: {
				...this.state.item,
				[property]: data,
			},
		})
	}

	/**
	 * Function to trigger blur event
	 *
	 * @param {*} event
	 * @memberof AddMaterialModal
	 */
	onKeyDown(event) {
		if (event.key === 'Enter') document.activeElement.blur()
	}

	/**
	 * Handle on blur event of text field
	 *
	 * @param {*} textFieldId
	 * @memberof MaterialsTable
	 */
	handleBlurEditTextField(textFieldId) {
		if (textFieldId !== 'sap_folio')
			this.props.onUpdateProperty(this.state.item, textFieldId).then(() => {
				this.setState({ item: this.initialItemState, isOnEdit: false })
			})
		else
			this.props.onUpdateSapFolio(this.state.item, textFieldId).then(() => {
				this.setState({ item: this.initialItemState, isOnEdit: false })
			})
	}

	/**
	 * On change warehouse select option set in state and then call action to set in backend
	 *
	 * @param {*} option
	 * @memberof MaterialsTable
	 */
	onChangeWarehouse(option) {
		this.setState(
			{
				item: {
					...this.state.item,
					cell_name: option.value,
				},
			},
			() => {
				this.props.onUpdateProperty(this.state.item, 'cell_name').then(() => {
					this.setState({ item: this.initialItemState, isOnEdit: false })
				})
			}
		)
	}

	onChangeRamp(option) {
		this.setState(
			{
				item: {
					...this.state.item,
					ramp: option.value,
				},
			},
			() => {
				this.props.onUpdateProperty(this.state.item, 'ramp').then(() => {
					this.setState({ item: this.initialItemState, isOnEdit: false })
				})
			}
		)
	}

	/**
	 * Method to render text field
	 *
	 * @param {*} textFieldId
	 * @param {*} textFieldTranslateId
	 * @param {boolean} [isRequired=true]
	 * @returns
	 * @memberof AddMaterialModal
	 */
	renderTextField(textFieldId, type = 'text', showHelper = false) {
		let helperValue = ''
		let helperText = ''
		if (showHelper) {
			helperValue =
				this.state.item[
					textFieldId === 'revision_confirmation' ? 'revision' : 'missing_quantity_in_purchase_order'
				]
			helperText = (
				<Translate
					id={
						textFieldId === 'revision_confirmation'
							? 'deliveryOrderModule.requestedRevision'
							: 'deliveryOrderModule.error.quantityCantExceedsPurchaseOrder'
					}
				/>
			)
		}
		return (
			<TextField
				id={textFieldId}
				defaultValue={this.state.item[textFieldId] ? this.state.item[textFieldId] : ''}
				margin='dense'
				type={type}
				fullWidth
				onBlur={(event) => this.handleBlurEditTextField(textFieldId)}
				onChange={(event) => this.updatePropertyInState(textFieldId, event.target.value)}
				onKeyDown={this.onKeyDown}
				style={{ minWidth: 65, maxWidth: 65 }}
				helperText={
					this.state.item &&
					showHelper && (
						<React.Fragment>
							{helperText}
							{`: ${helperValue}`}
						</React.Fragment>
					)
				}
			/>
		)
	}

	/**
	 * Method to render OptionsMenu
	 *
	 * @param {*} item
	 * @returns
	 * @memberof ProveduresDetails
	 */
	renderOptionsMenu(item) {
		var options = []
		let { canAcceptEditWarehouseman } = this.props

		if (this.props.isDraft) {
			options.push({
				itemClick: () => this.props.onMenuOptionItemClick('openAddMaterialModal', item),
				tooltipTranslation: <Translate id='common.edit' />,
				menuItemIcon: <EditIcon color='secondary' />,
			})
			options.push({
				itemClick: () => this.props.onMenuOptionItemClick('openRemoveMaterialModal', item),
				tooltipTranslation: <Translate id='common.delete' />,
				menuItemIcon: <DeleteIcon color='error' />,
			})
		}
		if (canAcceptEditWarehouseman) {
			options.push({
				itemClick: () => this.props.onMenuOptionItemClick('openEditQuantityModal', item),
				tooltipTranslation: <Translate id='common.edit' />,
				menuItemIcon: <EditIcon color='secondary' />,
			})
			if (!item.has_been_accepted_in_warehouse) {
				options.push({
					itemClick: () => this.props.onAcceptMaterial(item.token),
					tooltipTranslation: <Translate id='common.accept' />,
					menuItemIcon: <AcceptIcon color='secondary' />,
				})
			}
		}
		return (
			<OptionsMenu
				itemToken={item.token}
				handleClickOptions={this.handleClickOptionsMenu}
				open={this.state.itemToken === item.token}
				handleClickAway={this.handleCloseOptionsMenu}
				options={options}
				xsSize
			/>
		)
	}

	/**
	 * renderOptionsMenuQuality
	 * @param {*} item
	 */
	renderOptionsMenuQuality(item) {
		var options = []
		let { canGenerateQuality } = this.props
		if (canGenerateQuality) {
			options.push({
				itemClick: () =>
					this.props.history.push(
						`/rawmaterialnonconformity/createfromDelivery/${this.props.deliveryOrderToken}/${item.token}`
					),
				tooltipTranslation: <Translate id='rawmaterialnonconformity.createRawMaterialFromDevelivery' />,
				menuItemIcon: <RawMaterialNonConfIcon color='secondary' />,
			})
		}
		return (
			<OptionsMenu
				itemToken={item.token}
				handleClickOptions={this.handleClickOptionsMenu}
				open={this.state.itemToken === item.token}
				handleClickAway={this.handleCloseOptionsMenu}
				options={options}
				xsSize
			/>
		)
	}

	/**
	 * Render issues list in tooltip title
	 *
	 * @param {*} issues
	 * @returns
	 * @memberof MaterialsTable
	 */
	renderIssuesTooltipList(issues) {
		return (
			<List dense>
				{issues.map((issue) => {
					return (
						<ListItem key={issue.token} divider dense>
							<ListItemText
								primary={
									<Typography style={{ color: 'white' }}>
										{<Translate id={issue.message} />}
									</Typography>
								}
							/>
						</ListItem>
					)
				})}
			</List>
		)
	}

	/**
	 * Render Input or label in datattable to edit or read data
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @param {*} inputType
	 * @param {*} showHelper
	 * @returns
	 * @memberof MaterialsTable
	 */
	renderInputLabelToEdit(dataSource, item, inputType, showHelper) {
		let isValidUserInRole = canBeOperationDeliveryWarehouse(this.props.roles)
		let isSupplierAdmin = IsUserSupplierAdministrator(this.props.user)
		let isValidSupplierInRole = canBeModulePlaneationSupplier(this.props.roles)
		if (
			((this.props.showOptionsByWarehouse && isValidUserInRole) || isValidSupplierInRole || isSupplierAdmin) &&
			this.state.item &&
			this.state.item.token === item.token
		)
			return this.renderTextField(dataSource, inputType, showHelper)
		else {
			if ((this.props.showOptionsByWarehouse && isValidUserInRole) || isValidSupplierInRole || isSupplierAdmin)
				return (
					<Tooltip title={<Translate id='common.clickToEdit' />} placement='top'>
						<Button
							variant='outlined'
							size='small'
							style={{ minWidth: 65, maxWidth: 65, maxHeight: 40, fontSize: 'xx-small' }}
							onClick={() => this.setState({ item, isOnEdit: true })}
						>
							{item[dataSource] ? item[dataSource] : <Translate id='common.noData' />}
						</Button>
					</Tooltip>
				)
			else {
				return item[dataSource] ? item[dataSource] : <Translate id='common.noData' />
			}
		}
	}

	/**
	 * Method to render version or quantity Cell in table
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof MaterialsTable
	 */
	renderCellRevisionOrQuantity(dataSource, item) {
		if (this.props.isDraft) {
			let issues = item['issues'].filter((issue) => {
				if (dataSource === 'revision_confirmation') return issue.issue_type === 4 || issue.issue_type === 5
				return issue.issue_type === 1 || issue.issue_type === 2 || issue.issue_type === 6
			})
			if (issues.length > 0) {
				return (
					<Grid container spacing={0}>
						<Grid item xs={10}>
							<Tooltip title={this.renderIssuesTooltipList(issues)} placement='bottom'>
								<Badge
									badgeContent={
										dataSource === 'revision_confirmation' ? (
											<AlertOutlineIcon color='error' />
										) : (
											<AlertIcon color='error' />
										)
									}
									classes={{ badge: this.props.classes.customBadge }}
								>
									{this.renderInputLabelToEdit(dataSource, item, 'number', true, issues)}
								</Badge>
							</Tooltip>
						</Grid>
					</Grid>
				)
			} else return this.renderInputLabelToEdit(dataSource, item, 'number', true)
		} else return item[dataSource]
	}

	/**
	 * Rnder warehouse select
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof MaterialsTable
	 */
	renderWarehouseSelect(dataSource, item) {
		let warehouseOptions = GetWarehouses()
		let isValidUserInRole = canBeOperationDeliveryWarehouse(this.props.roles)
		if (isValidUserInRole && this.state.item && this.state.item.token === item.token)
			return (
				<Select
					value={getSelectedValue(warehouseOptions, item[dataSource])}
					options={warehouseOptions}
					onChange={this.onChangeWarehouse}
					styles={{
						input: (base) => ({ ...base, minWidth: 60, maxWidth: 60 }),
						menu: (base) => ({ ...base, zIndex: 10, maxHeight: 150 }),
						menuList: (base) => ({ ...base, maxHeight: 150, paddingTop: 0 }),
						dropdownIndicator: (base) => ({ ...base, padding: 0 }),
					}}
				/>
			)
		else {
			if (isValidUserInRole)
				return (
					<Tooltip title={<Translate id='common.clickToEdit' />}>
						<Button
							variant='outlined'
							size='small'
							style={{ minWidth: 65, maxWidth: 65, maxHeight: 40, fontSize: 'xx-small' }}
							onClick={() => this.setState({ item, isOnEdit: true })}
						>
							{item[dataSource] ? (
								item[dataSource]
							) : (
								<Translate id='deliveryOrderModule.noWarehouseSelected' />
							)}
						</Button>
					</Tooltip>
				)
			else {
				return item[dataSource] ? item[dataSource] : <Translate id='deliveryOrderModule.noWarehouseSelected' />
			}
		}
	}

	renderRampSelect(dataSource, item) {
		let rampsOptions = GetRamps()
		rampsOptions = this.props.isRampThree ? rampsOptions.filter((x) => x.value === 'Rampa 3') : rampsOptions
		let isValidUserInRole = canBeOperationDeliveryWarehouse(this.props.roles)
		if (isValidUserInRole && this.state.item && this.state.item.token === item.token)
			return (
				<Select
					value={getSelectedValue(rampsOptions, item[dataSource])}
					options={rampsOptions}
					onChange={this.onChangeRamp}
					styles={{
						input: (base) => ({ ...base, minWidth: 60, maxWidth: 60 }),
						menu: (base) => ({ ...base, zIndex: 10, maxHeight: 150 }),
						menuList: (base) => ({ ...base, maxHeight: 150, paddingTop: 0 }),
						dropdownIndicator: (base) => ({ ...base, padding: 0 }),
					}}
				/>
			)
		else {
			if (isValidUserInRole)
				return (
					<Tooltip title={<Translate id='common.clickToEdit' />}>
						<Button
							variant='outlined'
							size='small'
							style={{ minWidth: 65, maxWidth: 65, maxHeight: 40, fontSize: 'xx-small' }}
							onClick={() => this.setState({ item, isOnEdit: true })}
						>
							{item[dataSource] ? (
								item[dataSource]
							) : (
								<Translate id='deliveryOrderModule.noRampSelected' />
							)}
						</Button>
					</Tooltip>
				)
			else {
				return item[dataSource] ? item[dataSource] : <Translate id='deliveryOrderModule.noRampSelected' />
			}
		}
	}

	/**
	 * Method to render custom cell in DataTable
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof MaterialsTable
	 */
	onRenderCellItem(dataSource, item) {
		let kind = 1
		if (dataSource === 'price') {
			let tooltipTitle = `${item['price_MXN']} MXN`
			return (
				<Tooltip title={tooltipTitle}>
					<React.Fragment>{`${item[dataSource]} ${item['currency']}`}</React.Fragment>
				</Tooltip>
			)
		}
		if (dataSource === 'plot_name') {
			return <FilesLinks item={item} hasPlot companyToken={this.props.companyToken} kind={kind} />
		}
		if (dataSource === 'purchase_order_total') {
			return item[dataSource] + item['currency']
		}
		if (dataSource === 'options') {
			return this.renderOptionsMenu(item)
		}
		if (dataSource === 'optionsquality') {
			return this.renderOptionsMenuQuality(item)
		}
		if (dataSource === 'revision_confirmation' || dataSource === 'quantity_of_material') {
			return this.renderCellRevisionOrQuantity(dataSource, item)
		}
		if (dataSource === 'lot') {
			if (this.props.showOptionsByWarehouse && canBeOperationDeliveryWarehouse(this.props.roles)) {
				return this.renderInputLabelToEdit(dataSource, item, 'text', false)
			} else {
				return item.lot
			}
		}
		if (dataSource === 'cell_name') {
			return this.renderWarehouseSelect(dataSource, item)
		}
		if (dataSource === 'ramp') {
			if (this.props.showOptionsByWarehouse) {
				return this.renderRampSelect(dataSource, item)
			} else {
				return item.ramp
			}
		}
		if (dataSource === 'sap_folio') {
			if (this.props.showOptionsByWarehouse && canBeOperationDeliveryWarehouse(this.props.roles)) {
				return this.renderInputLabelToEdit(dataSource, item, 'text', false)
			} else {
				return item.sap_folio
			}
		}
	}

	/**
	 * Function to donwload PDF file for a material returned or missing document
	 */
	downloadPDForMaterialReturnedOrMissing() {
		this.props.downloadPDForMaterialReturnedOrMissing(this.props.deliveryOrderToken)
	}

	render() {
		let isDisabledToClose = this.props.data && this.props.data.length <= 0
		this.setCustomColorRow()
		return (
			<Card>
				<CardHeader
					title={this.props.title}
					action={
						!this.props.isDisabled && (
							<React.Fragment>
								<Tooltip title={<Translate id='deliveryOrderModule.closeAndSendDeliveryOrder' />}>
									<Fab
										variant='extended'
										size='small'
										disabled={isDisabledToClose}
										style={{
											backgroundColor: isDisabledToClose
												? BACKGROUND_COLOR_GRAY
												: COLOR_STATUS_SUCCESS,
											color: '#fff',
										}}
										aria-label='send'
										onClick={() => this.props.onCloseAndPrint()}
									>
										<PrintIcon /> <Translate id='deliveryOrderModule.closeAndSendDeliveryOrder' />
									</Fab>
								</Tooltip>

								<Tooltip title={<Translate id='deliveryOrderModule.addMaterial' />}>
									<Fab
										size='small'
										color='primary'
										style={{ marginLeft: '1em' }}
										onClick={() => {
											this.props.onAddClick('openAddMaterialModal', null)
										}}
									>
										<AddIcon />
									</Fab>
								</Tooltip>
							</React.Fragment>
						)
					}
				/>
				<CardContent>
					{this.props.data && (
						<Grid container spacing={0} style={{ marginBottom: '3em' }}>
							<Grid item xs={12}>
								<DataTable
									data={this.props.data}
									configuration={TableConfiguration(
										this.props.hasBeenSentToSAP,
										this.props.showOptionsByWarehouse,
										this.props.canGenerateQuality
									)}
									onRenderCellItem={this.onRenderCellItem}
								/>
							</Grid>
							{!this.props.isDraft && (
								<OptionsDeliveryOrder
									deliveryOrderToken={this.props.deliveryOrderToken}
									isDraft={this.props.isDraft}
									canBeQualityValidate={this.props.canBeQualityValidate}
									canSupplierCancel={this.props.canSupplierCancel}
									isSaving={this.props.isSaving}
									roles={this.props.roles}
									user={this.props.user}
									totalOrderItems={this.props.totalOrderItems}
									onCancelDeliveryOpen={this.props.onCancelDeliveryOpen}
									canAcceptEditWarehouseman={this.props.canAcceptEditWarehouseman}
									canBeCancelByWarehouse={this.props.canBeCancelByWarehouse}
									showOptionsByWarehouse={this.props.showOptionsByWarehouse}
									canBeSendSAP={this.props.canBeSendSAP}
									onSendDeliveryOrderToSAP={this.props.onSendDeliveryOrderToSAP}
									isCloseOrder={this.props.isCloseOrder}
									isCanceled={this.props.isCanceled}
									onCancelDeliveryInSAPOpen={this.props.onCancelDeliveryInSAPOpen}
									hasMaterialReturnedMissing={this.props.hasMaterialReturnedMissing}
									downloadPDForMaterialReturnedOrMissing={this.downloadPDForMaterialReturnedOrMissing}
									canBePrintOrderPdf={this.props.canBePrintOrderPdf}
									isRampThree={this.props.isRampThree && this.props.isAddDeliveryTime}
								/>
							)}
						</Grid>
					)}
				</CardContent>
			</Card>
		)
	}
}

function TableConfiguration(hasBeenSentToSAP, showOptionsWarehouse, canGenerateQuality) {
	let columns = [
		{
			header: <Translate id='deliveryOrderModule.materialsTable.purchaseOrder' />,
			dataSource: 'purchase_order',
		},
		{
			header: <Translate id='deliveryOrderModule.materialsTable.code' />,
			dataSource: 'part_number_code',
		},
		{
			header: <Translate id='deliveryOrderModule.materialsTable.description' />,
			dataSource: 'part_number_description',
		},
		{
			header: <Translate id='deliveryOrderModule.materialsTable.version' />,
			dataSource: 'revision_confirmation',
		},
		{
			header: <Translate id='deliveryOrderModule.materialsTable.position' />,
			dataSource: 'position',
		},
		{
			header: <Translate id='deliveryOrderModule.materialsTable.plot' />,
			dataSource: 'plot_name',
		},
		{
			header: <Translate id='deliveryOrderModule.materialsTable.quantity' />,
			dataSource: 'quantity_of_material',
		},
		{
			header: <Translate id='deliveryOrderModule.materialsTable.measurementUnit' />,
			dataSource: 'part_number_um',
		},
		{
			header: <Translate id='deliveryOrderModule.materialsTable.lot' />,
			dataSource: 'lot',
		},
		{
			header: <Translate id='deliveryOrderModule.materialsTable.invoice' />,
			dataSource: 'invoice_or_remission',
		},
	]

	if (showOptionsWarehouse) {
		columns.push({
			header: <Translate id='deliveryOrderModule.materialsTable.warehouse' />,
			dataSource: 'cell_name',
		})
	}
	columns.push({
		header: <Translate id='deliveryOrderModule.materialsTable.ramp' />,
		dataSource: 'ramp',
	})
	columns.push({
		header: <Translate id='deliveryOrderModule.materialsTable.unitPrice' />,
		dataSource: 'price',
	})
	columns.push({
		header: <Translate id='deliveryOrderModule.materialsTable.totalPrice' />,
		dataSource: 'purchase_order_total',
	})
	columns.push({
		header: '',
		dataSource: 'options',
	})

	if (hasBeenSentToSAP) {
		columns.push({
			header: <Translate id='deliveryOrderModule.materialsTable.numberSap' />,
			dataSource: 'sap_folio',
		})
	}
	if (canGenerateQuality) {
		columns.push({
			header: '',
			dataSource: 'optionsquality',
		})
	}
	return { columns }
}

const styles = (theme) => ({
	customBadge: {
		color: 'white',
		top: '50%',
		right: '-10%',
	},
})

export default withStyles(styles)(MaterialsTable)
