///Import react section
import React, {useState} from 'react';
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/** Language imports **/
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import {Typography, Grid,TextField,IconButton, Fab } from "@material-ui/core";
import FileIcon from '@material-ui/icons/AttachFile';
import DownloadIcon from '@material-ui/icons/CloudDownload';

/** Import component section */
import {getActionsByCriteriaList, getActionsByOpportunityList,
    getAnswerQuestion} from '../../../store/sustainability2/SustainabilityResults'
import {canBeEditSupplier, canBeResponsible} from '../../../store/helpers/SustainabilityHelper'

/** Import component section */
import DataTable from '../../common/DataTable';
import { descriptionStatusAction} from '../../../store/helpers/SustainabilityHelper';
import { COLOR_STATUS_DANGER, COLOR_STATUS_SUCCESS, 
    COLOR_STATUS_WARNING, COLOR_STATUS_INFO} from '../../../store/helpers/StatusColorConstants';
import {SustainabilityStatus} from '../../../store/helpers/AppConstants'
import PanelAction from './PanelAction'
import PercentageComponent from '../components/PercentageComponent'

/** Import PDF section */
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from "../PDFs/PdfActionPlan/PdfDocument";

/**
 * Component load list
 * @param {*} props 
 */
const ActionsPlanContent = (props) => {
    const {classes, actionPlans, sustainability, translate, userProfile} = props
    const {updatePropertyActionPlan, userRolesByCompany, isSaving} = props
    const actionsCriterias = getActionsByCriteriaList(actionPlans)
    const actionsOppornunity = getActionsByOpportunityList(actionPlans)
    const [openLateralPanelAction, setOpenLateralPanelAction] = useState({ opened: false, actionPlan: null })
    let canBeEditResponsible = (canBeResponsible(sustainability, userRolesByCompany, userProfile));
    let canUpdateSupplier = (canBeEditSupplier(sustainability, userRolesByCompany, userProfile) && 
    (sustainability.Status === SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN || sustainability.Status === SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER 
        || sustainability.Status === SustainabilityStatus.REEVALUATE_QUETIOONAIRE));

    const onOpenCloseLateralPanel = (actionPlan) => {
        setOpenLateralPanelAction({ opened: !openLateralPanelAction.opened, actionPlan: actionPlan });
    }

    /**
     * On render cell item custom
     * @param {*} dataSource 
     * @param {*} item 
     */
    const onRenderCellItem = (dataSource, item) => {
        if (dataSource === "Status") {
            return <Typography variant="caption" className={classes.statusText}>{descriptionStatusAction(item.Status, translate)}</Typography>
        }
        if (dataSource === "AnswerSupplier") {
            return <Typography variant="caption" className={classes.ellipsisText}>{getAnswerQuestion(item.AnswerSupplier)}</Typography>
        }
        if (dataSource === "Section") {
            return <Typography variant="caption" className={classes.sectionText}>{item.Section}</Typography>
        }
        if(dataSource === "Description"){
            return <div className={classes.textField}>
                <TextField
                    fullWidth
                    defaultValue={item.Description}
                    disabled={!(canBeEditResponsible && sustainability.Status === SustainabilityStatus.INITIAL_ACTIONS_PLAN)}
                    multiline
                    rowsMax={3}
                    onBlur={(event) => updatePropertyActionPlan("Description", event.target.value, item.Id, "ActionPlans")}
                    InputProps={{ classes: { input: classes.inputProps } }}
                />
            </div>
        }
        if(dataSource === "Recommendation"){
            return <div className={classes.textField}>
                <Typography variant="caption" > {item.Recommendation}</Typography>
            </div>
        }
        if (dataSource === "Options") {
            if(sustainability.Status !== SustainabilityStatus.INITIAL_ACTIONS_PLAN){
                return(<IconButton size="small" onClick={() => { onOpenCloseLateralPanel(item) }}>
                    <FileIcon  style={{color: item.Attachments.length === 0 ? COLOR_STATUS_INFO: COLOR_STATUS_WARNING}} />
                </IconButton>)
            }
            else{
                return("")
            }
        }
    }

    return (
        <div>
            
            {sustainability.TotalRatingActionPlan !== 0 &&
            <div className={classes.downloadButton}>
                <div className={classes.percentage}>
                <PercentageComponent
                    totalPercentage={sustainability.TotalRatingActionPlan}
                    isApproved={sustainability.IsAprovedActionPlan}
                    title={<Translate id='sustainability.percentageFidding'/>}
                 />
                </div>
                {sustainability.IsClose &&
                <div className={classes.pdfDownloadbutton}>
                    <PDFDownloadLink
                    document={<PdfDocument
                        actionsCriterias={actionsCriterias}
                        actionsOppornunity={actionsOppornunity}
                        sustainability={sustainability}/>} 
                        fileName={`Resultados Plan de Acciones.pdf`}>
                        {({ blob, url, loading, error }) => (<Fab variant="extended" size="small" color="secondary">
                            <DownloadIcon/>{<Translate id='sustainability.downloadResultReport'/>}
                        </Fab>)}
                    </PDFDownloadLink>
                </div>
                }
            </div>
            }
            <Grid item xs={12} container className={classes.colorRed}>
                <Typography variant="overline" className={classes.titleText}>{<Translate id="sustainability.actionsCritical" />}</Typography>
            </Grid>
            <DataTable
                data={actionsCriterias}
                configuration={TableConfigurationSustainability}
                onRenderCellItem={onRenderCellItem}
            />
            <br/>
            <Grid item xs={12} container className={classes.colorGrey}>
                <Typography variant="overline" className={classes.titleText}>{<Translate id="sustainability.actionOpportunity" />}</Typography>
            </Grid>
            <DataTable
                data={actionsOppornunity}
                configuration={TableConfigurationSustainability}
                onRenderCellItem={onRenderCellItem}
            />

            {
                openLateralPanelAction.opened &&
                <PanelAction
                    actionPlan={openLateralPanelAction.actionPlan}
                    onCloseButtonClick={onOpenCloseLateralPanel}
                    translate={translate}
                    canUpdateSupplier={canUpdateSupplier}
                    isSaving={isSaving}
                    canBeEditResponsible = {(canBeEditResponsible && (sustainability.Status === SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER
                        || sustainability.Status === SustainabilityStatus.REEVALUATE_QUETIOONAIRE))}
                    title={<Translate id="sustainability.actions.actions" />}
                    listActions={"ActionPlans"}
                />
            }

        </div>
    )
}
ActionsPlanContent.propTypes = {
    sustainability: PropTypes.object.isRequired,
    actionPlans: PropTypes.array.isRequired,
  };
  
  ActionsPlanContent.defaultProps = {
    sustainability: {
        ActionPlans:[],
    },
    actionPlans:[],
  };


const styles = ((theme) => ({
    logo: {
        backgroundColor: theme.palette.secondary.main,
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    showingCounter: {
        color: theme.palette.secondary.main
    },
    colorRed:{
        paddingLeft: "5px",
        backgroundColor: COLOR_STATUS_DANGER,
    },
    colorGrey:{
        paddingLeft: "5px",
        backgroundColor: COLOR_STATUS_SUCCESS,
    },
    titleText:{
        color: "white",
    },
    textField: {
        width: "230px",
    },
    inputProps: {
        height: 6,
        fontSize: 12
    },
    statusText:{
        minWidth: "60px",
    },
    sectionText:{
        minWidth: "85px",
    },
    downloadButton:{
        display: 'flex',
    },
    pdfDownloadbutton: {
        marginTop: '20px',
        marginRight: '10px',
        flex: '2 1 0',
        marginLeft: 'auto',
        textAlign: 'right',
    },
    percentage: {
        flex: '1 1 0',
    },
}))

const TableConfigurationSustainability = {
    columns: [
        {
            header: '',
            dataSource: 'status_indicator',
        },
        {
            header: <Translate id="common.status"/>,
            dataSource: 'Status',
        }, {
            header: <Translate id="sustainability.section"/>,
            dataSource: 'Section',
        },{
            header: <Translate id="sustainability.questionName"/>,
            dataSource: 'QuestionName',
        },{
            header: <Translate id="sustainability.answers"/>,
            dataSource: 'AnswerSupplier',
        }, {
            header: <Translate id="sustainability.tips"/>,
            dataSource: 'Recommendation',
        }, {
            header: <Translate id="sustainability.recommendations"/>,
            dataSource: 'Description',
        },
        {
            header: <Translate id="sustainability.uploadEvidence"/>,
            dataSource: 'Options',
        }
    ]
}

export default withRouter(
    withStyles(styles, { withTheme: true })(ActionsPlanContent)
);
