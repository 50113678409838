import React from "react";
import { Translate } from "react-localize-redux";
import propTypes from "prop-types";

import {
  Typography,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  withStyles,
  IconButton,
} from "@material-ui/core";
import AddServiceIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";

/**
 * Service type list
 * @param {*} props
 * @returns
 */
const ServiceTypeList = (props) => {
  const {
    classes,
    onAddServiceTypeClick,
    onRemoveServiceTypeClick,
    serviceTypes,
  } = props;

  return (
    <div>
      <div className={classes.alertInfo}>
        <InfoIcon size="small" />{" "}
        <Translate id="accreditedWorkshops.serviceTypes.serviceTypesInfo" />
      </div>
      <div className={classes.header}>
        <Typography variant="subtitle1" color="primary" fontWeight={500}>
          <Translate id="accreditedWorkshops.setting.serviceTypes" />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={onAddServiceTypeClick}
        >
          <AddServiceIcon className={classes.leftIcon} />
          <Translate id="common.add" />
        </Button>
      </div>
      <Divider />
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontWeight={500}
                >
                  <Translate id="accreditedWorkshops.serviceTypes.setid" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontWeight={500}
                >
                  <Translate id="accreditedWorkshops.serviceTypes.code" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontWeight={500}
                >
                  <Translate id="accreditedWorkshops.serviceTypes.description" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontWeight={500}
                ></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceTypes.map((type) => {
              return (
                <TableRow key={type.Type}>
                  <TableCell>
                    <Typography variant="body2">{type.SetId}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{type.Type}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{type.Description}</Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => onRemoveServiceTypeClick(type.Type)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

ServiceTypeList.propTypes = {
  serviceTypes: propTypes.arrayOf(
    propTypes.shape({
      SetId: propTypes.string.isRequired,
    })
  ).isRequired,
  onAddServiceTypeClick: propTypes.func.isRequired,
  onRemoveServiceTypeClick: propTypes.func.isRequired,
};
ServiceTypeList.defaultProps = {
  serviceTypes: [],
  onAddServiceTypeClick: () => {},
  onRemoveServiceTypeClick: () => {},
};

const styles = (theme) => ({
  header: {
    padding: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  alertInfo: {
    margin: "10px 0px",
    padding: "10px 7px 10px 40px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "10px center",
    fontSize: "13px",
    textAlign: "left",
    width: "auto",
    color: "#00529B",
    backgroundColor: "#BDE5F8",
  },
});

export default withStyles(styles, { withTheme: true })(ServiceTypeList);
