/** Import ReactJS */
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import { Card, Grid, Divider, Typography, Tooltip, IconButton } from '@material-ui/core'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import AssignmentIcon from '@material-ui/icons/Assignment'
import MoneyIcon from '@material-ui/icons/Money'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import BeenhereIcon from '@material-ui/icons/Beenhere'
//Import components section
import ConfirmPasswordDialog from '../../../common/ConfirmPasswordDialog'

/**Import resources section */
import { BACKGROUND_COLOR_GRAY, COLOR_STATUS_ALERT_INFO } from '../../../../store/helpers/StatusColorConstants'
import { FactoringStatus } from '../../../../store/helpers/AppConstants'
import { IsUserInRole, ROLE_FACTORING_AFFILIATION_ADMINISTRATOR } from '../../../../store/helpers/RolesHelper'

const PaymentConfiguration = (props) => {
	const { classes, factoring, activeStep } = props
	//bool props section
	const { isInternalUser, isSaving } = props
	//Array props section
	const { userRolesByCompany } = props
	const { onUpdateFactoring } = props
	const [confirmPasswordConfigModal, setConfirmPasswordConfigModal] = useState({
		opened: false,
		item: null,
	})

	const onConfirmPassword = (item) => {
		setConfirmPasswordConfigModal({
			opened: !confirmPasswordConfigModal.opened,
			item: item,
		})
	}

	let isUserAdministrator = IsUserInRole(ROLE_FACTORING_AFFILIATION_ADMINISTRATOR, userRolesByCompany)

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Card className={classes.card}>
						<CardHeader
							className={classes.cardHeader}
							avatar={
								<Avatar aria-label='Recipe' className={classes.avatar}>
									<AssignmentIcon />
								</Avatar>
							}
							title={'Configuración de pagos en SAP'}
						/>
						<Divider />
						<CardContent>
							<Grid container direction='row' justify='center' alignItems='flex-start' spacing={8}>
								<Grid item xs={12}>
									<List component='nav' className={classes.root}>
										<ListItem
											className={factoring.AceptedBySupplier ? classes.acceptedSupplier : null}
										>
											<ListItemIcon>
												<MoneyIcon color='primary' />
											</ListItemIcon>
											<ListItemText
												inset
												primary={
													<Typography className={classes.textTitle}>
														Terminar la configuración de pagos SAP.
													</Typography>
												}
											/>
											{isInternalUser &&
												factoring &&
												factoring.FactoringStatus ===
													FactoringStatus.PAYMENT_SETUP_COMPLETATION &&
												isUserAdministrator && (
													<ListItemSecondaryAction>
														<Tooltip title={'Confirmar configuración de pagos'}>
															<IconButton onClick={onConfirmPassword} disabled={isSaving}>
																<BeenhereIcon color='primary' />
															</IconButton>
														</Tooltip>
													</ListItemSecondaryAction>
												)}
										</ListItem>

										<Divider />
									</List>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>

			{confirmPasswordConfigModal && confirmPasswordConfigModal.opened && (
				<ConfirmPasswordDialog
					open={confirmPasswordConfigModal.opened}
					title={<Translate id='common.confirmOperation' />}
					message1={
						'Por favor confirma que haz terminado de configurar los métodos de pago del proveedor en SAP, esta acción ya no se puede revertir.'
					}
					message2={<Translate id='common.enterPassword' />}
					onClickButtonConfirm={(comment) =>
						onUpdateFactoring(
							activeStep,
							factoring.Currencies,
							factoring ? factoring.PaymentConditions : '-',
							factoring ? factoring.AnnualPurchase : '_',
							false,
							factoring ? factoring.Comments : '_'
						).then((_) => {
							onConfirmPassword(null)
						})
					}
					onClose={onConfirmPassword}
					isSaving={isSaving}
					isVisibleComments={false}
					commentRequired={false}
				/>
			)}
		</>
	)
}

const styles = (theme) => ({
	card: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	cardHeader: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
	},
	cardContainer: {
		marginTop: '10px',
		padding: '10px',
		borderWidth: '5px',
		// borderStyle: 'solid',
		borderLeftColor: 'black',
	},
	padding: {
		padding: '10px',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(PaymentConfiguration))
