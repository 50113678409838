/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import { IsNullOrEmpty } from '../helpers/StringHelper'

export const START_LOADING_OFFICIAL_NOTICES = 'START_LOADING_OFFICIAL_NOTICES'
export const SET_OFFICIAL_NOTICES_LIST = 'SET_OFFICIAL_NOTICES_LIST'
export const CHANGE_OFFICIAL_NOTICE_SORT_CRITERIA = 'CHANGE_OFFICIAL_NOTICE_SORT_CRITERIA'
export const CHANGE_OFFICIAL_NOTICE_QUERY = 'CHANGE_OFFICIAL_NOTICE_QUERY'
export const CHANGE_OFFICIAL_NOTICE_PAGINATION = 'CHANGE_OFFICIAL_NOTICE_PAGINATION'
export const SET_OFFICES_NOTICES_COUNT = 'SET_OFFICES_NOTICES_COUNT'
export const IS_SAVING_OFFICE_NOTICE = 'IS_SAVING_OFFICE_NOTICE'
export const IS_LOADING_OFFICIAL_NOTICE = 'IS_LOADING_OFFICIAL_NOTICE'
export const SET_OFFICIAL_NOTICE = 'SET_OFFICIAL_NOTICE'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_OFFICIAL_NOTICE_TO_ACCEPTED = 'SET_OFFICIAL_NOTICE_TO_ACCEPTED'
export const IS_SAVING_ADD_ACCEPTED_NOTICE = 'IS_SAVING_ADD_ACCEPTED_NOTICE'
export const REMOVE_OFFICIAL_NOTICE_ACCEPTED_SUPPLIER = 'REMOVE_OFFICIAL_NOTICE_ACCEPTED_SUPPLIER'
export const IS_LOADING_NOTICES_INITIAL = 'IS_LOADING_NOTICES_INITIAL'
export const SET_NOTICES_INITIAL_VIEW = 'SET_NOTICES_INITIAL_VIEW'
export const ADD_SUPPLIER_TO_EXCLUDE_IN_NOTICE = 'ADD_SUPPLIER_TO_EXCLUDE_IN_NOTICE'
export const REMOVE_SUPPLIER_TO_EXCLUDE_IN_NOTICE = 'REMOVE_SUPPLIER_TO_EXCLUDE_IN_NOTICE'
export const SET_OFFICIAL_NOTICE_COUNT = 'SET_OFFICIAL_NOTICE_COUNT'

/**
 * Action start loading official notice
 * @param {*} isLoading
 */
function isLoadinNotices(isLoading) {
	return {
		type: START_LOADING_OFFICIAL_NOTICES,
		isLoading: isLoading,
	}
}

/**
 * Action to set notices list
 * @param {*} users
 */
function setNoticesList(notices) {
	return {
		type: SET_OFFICIAL_NOTICES_LIST,
		notices: notices,
	}
}

/**
 * Action to is saving data
 * @param {*} isSaving
 */
function isSavingNotice(isSaving) {
	return {
		type: IS_SAVING_OFFICE_NOTICE,
		isSaving: isSaving,
	}
}

/**
 * Action reducer to set the notices's counter
 *
 * @param {*} count
 * @returns
 */
function setOfficeNoticesCount(count) {
	return {
		type: SET_OFFICES_NOTICES_COUNT,
		count,
	}
}

/**
 * Action to start load official notice
 */
function startLoadOfficialNotice(isLoading) {
	return {
		type: IS_LOADING_OFFICIAL_NOTICE,
		isLoading: isLoading,
	}
}
/**
 * Action to set notice count
 * @param {*} officialNoticeCount
 */
function setOfficialNoticeCount(officialNoticeCount) {
	return {
		type: SET_OFFICIAL_NOTICE_COUNT,
		officialNoticeCount: officialNoticeCount,
	}
}

/**
 * Action to set official notice
 * @param {*} officialNotice
 */
function setOfficialNotice(officialNotice) {
	return {
		type: SET_OFFICIAL_NOTICE,
		officialNotice: officialNotice,
	}
}

/**
 * set categories list from response
 *
 * @param {*} categories
 * @returns
 */
function setCategories(categories) {
	return {
		type: SET_CATEGORIES,
		categories,
	}
}

/**
 * Action to set notices list
 * @param {*} users
 */
function setNoticesListToAccepted(notices) {
	return {
		type: SET_OFFICIAL_NOTICE_TO_ACCEPTED,
		notices: notices,
	}
}

/**
 * Action to is saving notice acepted
 * @param {*} isSaving
 */
function isSavingNoticeAccpeted(isSaving) {
	return {
		type: IS_SAVING_ADD_ACCEPTED_NOTICE,
		isSaving: isSaving,
	}
}

/**
 * Action to remove officl notice acepted by supplier
 * @param {*} tokenNotice
 * @param {*} documentName
 */
function removeOfficeNoticeAceptedInList(tokenNotice, documentName) {
	return {
		type: REMOVE_OFFICIAL_NOTICE_ACCEPTED_SUPPLIER,
		tokenNotice: tokenNotice,
		documentName: documentName,
	}
}

/**
 * Action to is Loading notice inicial
 * @param {*} isLoading
 */
function isLoadingInitilNotices(isLoading) {
	return {
		type: IS_LOADING_NOTICES_INITIAL,
		isLoading: isLoading,
	}
}

/**
 * Action to set notices initial view list
 * @param {*} users
 */
function setNoticesInitialView(notices) {
	return {
		type: SET_NOTICES_INITIAL_VIEW,
		notices: notices,
	}
}

/**
 * Add supplier to list exclide
 * @param {*} supplierCode
 */
function addSupplierToExclude(supplier) {
	return {
		type: ADD_SUPPLIER_TO_EXCLUDE_IN_NOTICE,
		supplier,
	}
}

/**
 * Remove to list explide
 * @param {*} supplierCode
 */
function removeSupplierToExclude(supplierCode) {
	return {
		type: REMOVE_SUPPLIER_TO_EXCLUDE_IN_NOTICE,
		supplierCode,
	}
}

/**
 * Get all oficial notices from backend
 */
export function loadOfficialNoticesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadinNotices(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/all`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setNoticesList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadinNotices(false))
				console.error('Error getting the notices list', error.response)
			})
	}
}

/**
 * Get office notices count
 */
export function getOfficeNoticeCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().officialNotices.get('noticesCount')
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setOfficeNoticesCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the offices notices's count", error.response)
			})
	}
}

/**
 * Action to create company
 * @param {*} company
 */
export function createOfficeNoticeData(notice) {
	return function (dispatch, getState) {
		dispatch(isSavingNotice(true))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice`
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let formData = new FormData()
		notice.attachments.forEach((file) => {
			formData.append('file', file)
		})

		notice.templates.forEach((file) => {
			formData.append('fileToUpload', file)
		})

		formData.set('CompanyToken', companyToken)
		formData.set('ActivationDate', notice.activationDate.format('DD/MM/YYYY'))
		formData.set('ExpirationDate', notice.expirationDate.format('DD/MM/YYYY'))
		formData.set('OfficialNoticeType', notice.notice_type.value)
		formData.set('AcceptedBySupplier', notice.acceptedBySupplier)
		formData.set('Notes', notice.notes)
		formData.set('Nationals', notice.optionNationals)
		formData.set('Foreigns', notice.optionForeigns)
		formData.set('ByTemplate', notice.optionByTemplate)

		let suppliersList = ''
		notice.suppliers.forEach((supplier) => {
			if (!IsNullOrEmpty(suppliersList)) suppliersList += '>'
			suppliersList += supplier.name
		})
		formData.set('Suppliers', suppliersList)

		return axios
			.post(endPoint, formData, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingNotice(false))
					return Promise.resolve(response.data)
				} else {
					dispatch(isSavingNotice(false))
				}
			})
			.catch((error) => {
				console.error('Error create oficial notice', error.response)
				dispatch(isSavingNotice(false))
				return Promise.reject()
			})
	}
}

/**
 * Get and set the offical notice in the estate from read from backend
 * @param {*} token
 */
export function loadOfficialNotice(token) {
	return function (dispatch, getState) {
		dispatch(startLoadOfficialNotice(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setOfficialNotice(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load official notice', error.response)
				dispatch(startLoadOfficialNotice(false))
				return Promise.reject()
			})
	}
}

/**
 * Get office notices count
 */
export function getCategories() {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/officialnoticecategory`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let options = [{ value: '', label: 'Seleccionar una opción' }]
					serverResponse.data.forEach((option) => {
						options.push({ value: option.token, label: option.name })
					})
					dispatch(setCategories(options))
				}
			})
			.catch((error) => {
				console.error("Error getting the offices notices's categories", error.response)
			})
	}
}

/**
 * Action to load official notice for accepted
 */
export function loadOfficialNoticeForAccepted() {
	return function (dispatch, getState) {
		dispatch(isLoadinNotices(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierCode = getState().oidc.user.profile.company_code
		}

		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/activetoaccepted`
			let data = {
				CompanyToken: selectedCompany.get('token'),
				SupplierCode: supplierCode,
			}
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setNoticesListToAccepted(serverResponse.data))
					}
				})
				.catch((error) => {
					dispatch(isLoadinNotices(false))
					console.error("Error getting the offices notices's accpeted", error.response)
				})
		} else {
			return Promise.reject()
		}
	}
}

/**
 *
 * @param {*} noticeToken
 * @param {*} documentName
 */
export function addOfficeNoticeAcceptedBySupplier(noticeToken, documentName, videoDuration, videoProgress) {
	return function (dispatch, getState) {
		dispatch(isSavingNoticeAccpeted(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplier = getState().suppliers.get('supplier')
		let supplierCode = undefined
		let supplierName = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		if (supplier) {
			supplierName = supplier.get('name')
		}

		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/acceptedsupplier`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
			SupplierName: supplierName,
			NoticeToken: noticeToken,
			AcceptedBy: user.profile.name,
			DocumentName: documentName,
			VideoDuration: videoDuration,
			VideoProgress: videoProgress,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeOfficeNoticeAceptedInList(noticeToken, documentName))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingNoticeAccpeted(false))
				console.error('Error adding a accepted notice by supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to load Notices Actives To Show In Initial View
 */
export function loadOfficialNoticeInitialView() {
	return function (dispatch, getState) {
		let officialNotices = getState().officialNotices ? getState().officialNotices.get('noticesInitialView') : null
		if (officialNotices) {
			return
		}

		dispatch(isLoadingInitilNotices(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let supplierCode = undefined
			if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
				supplierCode = getState().oidc.user.profile.company_code
			}
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/initialview`
			let data = {
				CompanyToken: selectedCompany.get('token'),
				SupplierCode: supplierCode,
			}
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setNoticesInitialView(serverResponse.data))
					}
				})
				.catch((error) => {
					dispatch(isLoadingInitilNotices(false))
					console.error("Error getting the offices notices's initial view", error.response)
				})
		} else {
			return Promise.reject()
		}
	}
}

/**
 * Method to removing official notice
 * @param {*} noticeToken
 */
export function removeOfficalNotice(noticeToken) {
	return function (dispatch, getState) {
		dispatch(isSavingNotice(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/${noticeToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingNotice(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingNotice(false))
				console.error('Error in remove offical notice', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to create company
 * @param {*} company
 */
export function updateOfficeNoticeData(notice) {
	return function (dispatch, getState) {
		dispatch(isSavingNotice(true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice`

		let formData = new FormData()
		formData.set('OfficialNoticeToken', notice.token)
		formData.set('ActivationDate', notice.activationDate.format('DD/MM/YYYY'))
		formData.set('ExpirationDate', notice.expirationDate.format('DD/MM/YYYY'))

		return axios
			.put(endPoint, formData, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setOfficialNotice(response.data))
					dispatch(isSavingNotice(false))
				} else {
					dispatch(isSavingNotice(false))
				}
			})
			.catch((error) => {
				console.error('Error update oficial notice', error.response)
				dispatch(isSavingNotice(false))
				return Promise.reject()
			})
	}
}

/**
 * Method to removing official notice
 * @param {*} noticeToken
 */
export function downloadSuppliersExcelReport(noticeToken) {
	return function (dispatch, getState) {
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/${noticeToken}/report`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Proveedores_del_comunicado.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				console.error('Error in remove offical notice', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add supplier in list exclude from offical notice
 * @param {*} noticeToken
 * @param {*} supplierCode
 */
export function addSupplierInListExcludesFromOfficialNotice(noticeToken, supplierCode) {
	return function (dispatch, getState) {
		dispatch(isSavingNotice(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/excludesuppliers`
		let data = {
			OfficialNoticeToken: noticeToken,
			SupplierCode: supplierCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					if (serverResponse.data !== '') {
						dispatch(addSupplierToExclude(serverResponse.data))
					}
					dispatch(isSavingNotice(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingNotice(false))
				console.error('Error in adding supplier exclude in official notice', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove supplier in list exclude from offical notice
 * @param {*} noticeToken
 * @param {*} supplierCode
 */
export function removeSupplierInListExcludesFromOfficialNotice(noticeToken, supplierCode) {
	return function (dispatch, getState) {
		dispatch(isSavingNotice(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/${noticeToken}/excludesuppliers/${supplierCode}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeSupplierToExclude(supplierCode))
					dispatch(isSavingNotice(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingNotice(false))
				console.error('Error in remove supplier exclude in official notice', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to exclude list suppliers
 * @param {*} noticeToken
 */
export function importSupplierToExclude(noticeToken, attachments) {
	return function (dispatch, getState) {
		dispatch(isSavingNotice(true))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/${noticeToken}/importsuppliertoexclude`

		let formData = new FormData()
		attachments.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.put(endPoint, formData, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setOfficialNotice(response.data))
					dispatch(isSavingNotice(false))
					return Promise.resolve(response.data)
				} else {
					dispatch(isSavingNotice(false))
				}
			})
			.catch((error) => {
				console.error('Error import list supplier to exclude', error.response)
				dispatch(isSavingNotice(false))
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function getTotalNotifications() {
	return function (dispatch, getState) {
		let count = getState().officialNotices.get('totalNotifications')
		if (count) {
			return
		}
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		// Aplica para imbera
		if (selectedCompany && selectedCompany.get('company_code') === 'IMB' && supplierCode !== undefined) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/getnoticetotals`
			let data = {
				CompanyToken: selectedCompany.get('token'),
				SupplierCode: supplierCode,
			}
			return axios
				.post(endPoint, data, configuration)
				.then((response) => {
					if (response.status === 200) {
						dispatch(setOfficialNoticeCount(response.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					console.error('Error getting elements to count', error.response)
					return Promise.reject(error.response.data.Message)
				})
		}
	}
}

export function addNoticeRelease(officialToken) {
	return function (dispatch, getState) {
		let selectedCompany = getState().profile.get('selectedCompany')
		let count = getState().officialNotices.get('totalNotifications')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/addnoticerelease`
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		// Aplica para imbera y usurios externos
		if (selectedCompany && selectedCompany.get('company_code') === 'IMB' && supplierCode !== undefined) {
			let data = {
				OfficialToken: officialToken,
				CompanyCode: selectedCompany.get('company_code'),
				SupplierCode: supplierCode,
				VisualizedUser: getState().oidc.user.profile.name,
			}
			return axios
				.post(endPoint, data, configuration)
				.then((response) => {
					if (response.status === 200) {
						if (response.data === true) dispatch(setOfficialNoticeCount(count - 1))
					}
				})
				.catch((error) => {
					console.error('Error getting elements to count', error)
					return Promise.reject(error.response.data.Message)
				})
		}
	}
}
