///Import react section
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'

/** Material-UI imports section */
import Paper from '@material-ui/core/Paper'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import { useDispatch, useSelector } from 'react-redux'
import ReleaseClockIcon from '@material-ui/icons/Restore'
import { Card, CardHeader, Avatar, Typography, CardContent, Tooltip, Fab } from '@material-ui/core'

/** Import component */
import SearchInput from '../../common/SearchInput/SearchInput'
import DataTable from '../../common/DataTable'
import LoadingPage from '../../common/LoadingPage'
import {
	CHANGE_RELEASE_CALENDARS_QUERY,
	CHANGE_RELEASE_CALENDARS_SORT_CRITERIA,
	loadReleaseCanledarsLockFromBackEnd,
} from '../../../store/plannigFersa/ReleaseCalendarAction'

/** Language imports **/

const Index = (props) => {
	const { classes } = props
	const dispatch = useDispatch()

	const releaseCalendarLocks = useSelector((state) =>
		state.releaseCalendarFersaLock.get('releaseCalendarLocks')
			? state.releaseCalendarFersaLock.get('releaseCalendarLocks').toJS()
			: null
	)
	const isLoadingReleaseCalendars = useSelector((state) =>
		state.releaseCalendarFersaLock.get('isLoadingReleaseCalendars')
	)
	const sortCriteria = useSelector((state) =>
		state.releaseCalendarFersaLock.get('sortCriteria')
			? state.releaseCalendarFersaLock.get('sortCriteria').toJS()
			: null
	)
	const searchQuery = useSelector((state) => state.releaseCalendarFersaLock.get('searchQuery'))

	useEffect(() => {
		dispatch(loadReleaseCanledarsLockFromBackEnd(searchQuery, sortCriteria.by, sortCriteria.ascending))
	}, [dispatch, searchQuery])

	const onChangeSortCriteria = (sortBy, sortAscending) => {
		dispatch({
			type: CHANGE_RELEASE_CALENDARS_SORT_CRITERIA,
			sortCriteria: {
				by: sortBy,
				ascending: sortAscending,
			},
		})
	}

	const onRowClick = (calendar, event) => {
		let tagName = event.target.tagName
		if (tagName !== 'INPUT') {
			props.history.push(`/releasecalendarFersa/${calendar.id}`)
		}
	}

	const getCardContent = () => {
		if (isLoadingReleaseCalendars === true) {
			return <LoadingPage />
		} else if (releaseCalendarLocks === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<Paper style={{ marginBottom: '4rem' }}>
					<DataTable
						data={releaseCalendarLocks.items ? releaseCalendarLocks.items : []}
						configuration={TableConfiguration(true)}
						sortBy={sortCriteria.by}
						sortAscending={sortCriteria.ascending}
						onChangeSortCriteria={onChangeSortCriteria}
						onRowClick={onRowClick}
						showFilters
						isIndex
					/>
				</Paper>
			)
		}
	}

	const onChangeSearchValue = (searchQuery) => {
		dispatch({
			type: CHANGE_RELEASE_CALENDARS_QUERY,
			query: searchQuery,
		})
	}

	return (
		<Card>
			<div className={classes.marginButton}></div>
			<CardHeader
				avatar={
					<Avatar>
						<ReleaseClockIcon />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						<SearchInput
							className={classes.searchInput}
							onChangeSearchValue={onChangeSearchValue}
							value={props.searchQuery}
						/>

						<Tooltip title={<Translate id='releaseCalendarLock.addReleaseCalendar' />}>
							<Fab
								size='small'
								color='primary'
								onClick={() => {
									props.history.push(`/releasecalendarFersa/create`)
								}}
							>
								<AddtIcon />
							</Fab>
						</Tooltip>
					</div>
				}
				title={
					<Typography variant='button'>
						<Translate id='menu.releaseCalendarLock' />{' '}
					</Typography>
				}
			/>
			<CardContent>{getCardContent()}</CardContent>
		</Card>
	)
}

function TableConfiguration(showCheckSelect) {
	let columns = [
		{
			header: <Translate id='releaseCalendarLock.starHour' />,
			dataSource: 'time_hour_lock_start',
			isSortable: true,
		},
		{
			header: <Translate id='releaseCalendarLock.endHour' />,
			dataSource: 'time_hour_lock_end',
		},
		{
			header: <Translate id='releaseCalendarLock.typeLock' />,
			dataSource: 'lock_type_description',
			isSortable: true,
		},
		{
			header: <Translate id='releaseCalendarLock.startDate' />,
			dataSource: 'date_lock_start',
		},
		{
			header: <Translate id='releaseCalendarLock.endDate' />,
			dataSource: 'date_lock_end',
		},
		{
			header: <Translate id='releaseCalendarLock.messageLock' />,
			dataSource: 'message_lock',
			isSortable: true,
		},
		{
			header: <Translate id='releaseCalendarLock.numberRamp' />,
			dataSource: 'number_of_ramps',
			isSortable: true,
		},
	]

	return { columns }
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	divRenderInput: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Index)))
