export const buildSelectedServiceOrderMetrics = (selectedServiceOrders) => {
	if (!Array.isArray(selectedServiceOrders)) {
		return {
			displayed: false,
			numberOfServiceOrders: 0,
			totalAmount: 0
		};
	}
	const totalAmount = calculateTotalAmountForWorkshops(selectedServiceOrders);
	return {
		displayed: selectedServiceOrders.length > 0,
		numberOfServiceOrders: selectedServiceOrders.length,
		totalAmount
	};
};

// const filterServiceOrderByCodes = (serviceOrders, selectedServiceOrderCodes) =>
// 	serviceOrders.filter((so) => selectedServiceOrderCodes.includes(so.code));

const calculateTotalAmountForWorkshops = (serviceOrders) =>
	serviceOrders.reduce((total, so) => total + so.tallerImporteGastos + so.tallerImporteServicio, 0);
