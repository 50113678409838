import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { NumberParam, useQueryParam } from 'use-query-params'
import { DelimitedPipeArrayParam } from './CustomArrayParam'

/** Material UI import section */
import {
	Button,
	Checkbox,
	Paper,
	Popover,
	Typography,
	List,
	ListItem,
	ListItemText,
	LinearProgress,
} from '@material-ui/core'

/** Custom componets import section */
import SearchBox from '../SearchBox'
import ContextFilterSubMenu from './ContextFilterSubMenu'

/** Helpers import section */
import {
	getAdvancedFilterDescription,
	FilterCriteria,
	getAdvancedFilterOptions,
} from '../../accreditedWorkshops/serviceOrders/serviceHelpers/FilterConfiguration'

/** Actions import seciton */
import { loadFilterOptions } from '../../../store/accreditedWorkshops/accreditedWorkshopsActions'

/** Selectors import section */
import { getAllFilterOptionsSelector } from '../../../store/accreditedWorkshops/accreditedWorkshopsSelectors'

/** Helpers import section */
import ContextFilterMenuTypes from './ContextFilterMenuTypes'
import { IsNullOrEmpty as isNullOrEmpty } from '../../../store/helpers/StringHelper'

/** Resources import section */
import useStyles from './ContextFilterMenuStyles'

/**
 * Component to render contex menu showing available filters
 * for given header column table.
 *
 * @param {object} props
 * @returns
 */
const ContextFilterMenu = (props) => {
	const {
		/** Destructuring properties */
		anchorEl,
		type,
		name,
		url,
		entityName,
		queryStringKey,
		filterId,
		filters,
		customOptions,
		x,
		y,
		/** Destructuring callbacks */
		onClose,
		onAvancedFilterClick,
		onApply,
		mapOption,
	} = props

	/** Defines local state */
	// const [anchor, setAnchor] = useState(null);
	const [open, setOpen] = useState(false)
	const [options, setOptions] = useState([])
	const [loading, setLoading] = useState(true)
	const [optionsSectionDisplayed, setOptionsSectionDisplayed] = useState(true)
	const [selectedOptions, setSelectedOptions] = useState([])
	const [advancedFilterOptions, setAdvancedFilterOptions] = useState({
		open: false,
		anchor: null,
		items: [],
	})
	const [query, setQuery] = useState('')

	/** Connect with redux */
	const dispatch = useDispatch()
	const allFilterOptions = useSelector((state) => getAllFilterOptionsSelector(state, { filterType: queryStringKey }))

	/** Get query string values */
	const [pageNumber, setPageNumber] = useQueryParam('pageNumber', NumberParam)
	const [filter, setFilter] = useQueryParam(queryStringKey, DelimitedPipeArrayParam)
	const [advancedFilter, setAdvancedFilter] = useQueryParam(`Advanced${queryStringKey}`, DelimitedPipeArrayParam)

	const classes = useStyles()

	/**
	 * Set the element used as anchor to displayed this component.
	 */
	useEffect(() => {
		setOpen(!!anchorEl)
		// setAnchor(anchorEl);
	}, [anchorEl])

	/**
	 * Retrieve all filter options
	 */
	useEffect(() => {
		if (!isNullOrEmpty(queryStringKey)) {
			dispatch(loadFilterOptions(url, entityName, queryStringKey, filters)).finally(() => setLoading(false))
		} else {
			setLoading(false)
		}
	}, [dispatch, url, entityName, queryStringKey, filters])

	/**
	 * Update local state in order to show current selected filter values.
	 */
	useEffect(() => {
		if (!!filter) {
			let filteredOptions = allFilterOptions.filter((opt) => filter.includes(opt.code))
			setSelectedOptions(filteredOptions)
		}
	}, [type, filter, allFilterOptions])

	/**
	 * Determines if search box should be dispalyed.
	 */
	useEffect(() => {
		let searchBoxStatus = Object.values(ContextFilterMenuTypes).includes(type)
		setOptionsSectionDisplayed(searchBoxStatus)
	}, [type])

	/**
	 * Update visible filter options in order to seach box has changed its value.
	 */
	useEffect(() => {
		let filteredOptions
		if (customOptions.length > 0) {
			filteredOptions = customOptions
		} else if (type === ContextFilterMenuTypes.Date) {
			filteredOptions = allFilterOptions.reduce(
				(unique, opt) =>
					unique.some((item) => item.key === opt.label)
						? unique
						: [
								...unique,
								{
									label: opt.label,
									key: opt.key,
								},
						  ],
				[]
			)
		} else if (!!mapOption) {
			filteredOptions = allFilterOptions.map(mapOption)
		} else {
			filteredOptions = allFilterOptions
		}
		if (!!query) {
			if (customOptions.length > 0) {
				filteredOptions = customOptions.filter(
					(opt) =>
						opt.key.toLowerCase().includes(query.toLowerCase()) ||
						opt.label.toLowerCase().includes(query.toLowerCase())
				)
			} else {
				filteredOptions = allFilterOptions.filter(
					(opt) =>
						opt.key.toLowerCase().includes(query.toLowerCase()) ||
						opt.label.toLowerCase().includes(query.toLowerCase())
				)
			}
		}

		if (customOptions.length > 0) {
			filteredOptions = filteredOptions.map(({ key, label }) => {
				const customOption = customOptions.find((opt) => opt.key === key)
				if (!customOption) {
					return {
						key,
						label,
						code: key,
						description: key === label ? label : `${key} - ${label}`,
					}
				} else {
					return customOption
				}
			})
		} else {
			filteredOptions = filteredOptions.map(({ key, label }) => ({
				key,
				label,
				code: key,
				description: key === label ? label : `${key} - ${label}`,
			}))
		}
		setOptions(filteredOptions)
	}, [type, query, allFilterOptions, customOptions, mapOption])

	/** Close menu popper filter */
	const closeContextMenu = () => {
		setOpen(false)
		//setAnchor(null);
		onClose()
	}

	/** On change option check */
	const toggleSelectedOption = (event) => {
		// console.log(`toggleSelectedOption -> event`, event);
		const { checked } = event.target
		const optCode = event.target.getAttribute('data-code')
		const option = options.find((opt) => opt.code === optCode)
		if (!option) {
			return
		}

		if (queryStringKey.includes('Date')) {
			if (checked) {
				setSelectedOptions([option])
			} else {
				setSelectedOptions([])
			}
		} else {
			if (checked) {
				setSelectedOptions([...selectedOptions, option])
			} else {
				setSelectedOptions(selectedOptions.filter((opt) => opt.code !== option.code))
			}
		}
	}

	/**
	 * Check if the array already includes an option, if not add it
	 * @param {*} option
	 */
	const isOptionSelected = (optCode) => {
		return selectedOptions.some((opt) => opt.code === optCode)
	}

	/** Clean filter */
	const cleanFilter = () => {
		setFilter(undefined, 'pushIn')
		if (!!advancedFilter) {
			setAdvancedFilter(undefined, 'pushIn')
		}
		closeContextMenu()
	}

	/** Apply filter */
	const applyFilters = () => {
		let filterValues = []
		if (type === ContextFilterMenuTypes.Date) {
			if (customOptions.length > 0) {
				if (customOptions.some((opt) => opt.key === selectedOptions[0].key)) {
					const customValues = customOptions.find((opt) => opt.key === selectedOptions[0].key).filterValues
					let rangeValues = []
					if (customValues) {
						if (customValues.startDate) {
							rangeValues.push(customValues.startDate.startOf('day').format('YYYY-MM-DD'))
						}
						if (customValues.endDate) {
							rangeValues.push(customValues.endDate.add(1, 'd').startOf('day').format('YYYY-MM-DD'))
						}
					}
					filterValues = rangeValues || []
				}
			} else {
				filterValues = [`${selectedOptions[0].code}-01-01`, `${selectedOptions[0].code}-12-31`]
			}
		} else {
			filterValues = selectedOptions.map((opt) => opt.code)
		}
		setFilter(filterValues, 'pushIn')
		if (!!pageNumber) {
			setPageNumber(1, 'pushIn')
		}
		closeContextMenu()
		onApply({ queryStringKey, filterId })
	}

	const openAdvancedOptionsMenu = (event) => {
		let items = getAdvancedFilterOptions(type)
		setAdvancedFilterOptions({
			open: true,
			anchorEl: event.currentTarget,
			items: items,
		})
	}

	const handleAdvancedOptionClick = (event) => {
		const criteria = event.currentTarget.getAttribute('data-criteria')
		onAvancedFilterClick(queryStringKey, criteria)
	}

	return (
		<Popover
			open={open}
			anchorReference='anchorPosition'
			anchorPosition={{ top: y, left: x }}
			//anchorEl={anchor}
			onClose={closeContextMenu}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			{/* <ClickAwayListener onClickAway={closeContextMenu}> */}
			<Paper elevation={2} className={classes.papperPopper}>
				<List dense className={classes.mainList}>
					<ListItem divider button onClick={cleanFilter}>
						<ListItemText primary={!name ? 'Borra filtro' : `Borrar filtro de ${name.toLowerCase()}`} />
					</ListItem>
					{
						{
							true: (
								<ListItem
									divider
									button
									data-criteria={FilterCriteria.BETWEEN_DATES}
									onClick={handleAdvancedOptionClick}
								>
									<ListItemText primary={getAdvancedFilterDescription(type)} />
								</ListItem>
							),
							false: (
								<ListItem divider button onClick={openAdvancedOptionsMenu}>
									<ListItemText primary={getAdvancedFilterDescription(type)} />
								</ListItem>
							),
						}[type === 'DATE']
					}
				</List>
				{advancedFilterOptions.open && (
					<ContextFilterSubMenu
						id={'advanced-options'}
						onItemClick={handleAdvancedOptionClick}
						{...advancedFilterOptions}
					/>
				)}
				{
					{
						true: <LinearProgress color='secondary' />,
						false: optionsSectionDisplayed && (
							<>
								<SearchBox
									autoFocus
									autoSearch
									placeholder='Buscar'
									onChange={(value) => setQuery(value)}
								/>
								<div className={classes.filterItemsContainer}>
									{options.map((opt) => {
										return (
											<div key={opt.code} className={classes.filterItem}>
												<Checkbox
													size='small'
													style={{ zIndex: 200 }}
													inputProps={{
														'data-code': opt.code,
													}}
													onChange={toggleSelectedOption}
													checked={isOptionSelected(opt.code)}
												/>
												<Typography variant='caption'>{opt.description}</Typography>
											</div>
										)
									})}
								</div>
								<div className={classes.footer}>
									<Button size='small' variant='outlined' onClick={applyFilters}>
										Aplicar
									</Button>
								</div>
							</>
						),
					}[loading]
				}
			</Paper>
			{/* </ClickAwayListener> */}
		</Popover>
	)
}

ContextFilterMenu.propTypes = {
	type: PropTypes.oneOf(Object.values(ContextFilterMenuTypes)).isRequired,
	url: PropTypes.string.isRequired,
	entityName: PropTypes.string.isRequired,
	filterId: PropTypes.string.isRequired,
	queryStringKey: PropTypes.string.isRequired,
	anchorEl: PropTypes.object.isRequired,
	onClose: PropTypes.func,
	onApply: PropTypes.func,
	onAvancedFilterClick: PropTypes.func,
	customOptions: PropTypes.array,
	mapOption: PropTypes.func,
}

ContextFilterMenu.defaultProps = {
	onClose: () => console.warn('[onClose] not defined!'),
	onApply: () => console.warn('[onApply] not defined!'),
	onAvancedFilterClick: () => console.warn('[onAvancedFilterClick] not defined!'),
	customOptions: [],
}

export default ContextFilterMenu
