import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'

const ItemInfo = ({ label = '', text = '', translationId }) => {
	if (translationId) {
		return <Typography variant='body2'><Translate id={translationId} />{`: ${text || '-'}`}</Typography>
	}
	return <Typography variant='body2'>{label}{`: ${text}`}</Typography>
}

ItemInfo.propTypes = {
	label: PropTypes.node,
	translationId: PropTypes.string,
	text: PropTypes.string,
}

export default ItemInfo
