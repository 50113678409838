
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import { Translate } from "react-localize-redux";

/** Import component section */
import DialogActions from '../../common/DialogActions';
import Dialog from '../../common/Dialog';
import {SHOW_EDIT_VIEW} from '../../../store/helpers/ResourcesHelper';

/**
 * Dialog to add EditMailAdministrator item in workflow
 */
class EditMailAdministrator extends Component {

    /**
   *Creates an instance of EditMailAdministrator.
   * @param {*} props
   * @memberof AddEmergentAction
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            userEdit: this.props.userEdit,
        }
    }

    /**
   * componentDidUpdate
   *
   * @param {*} prevProps
   * @param {*} prevState
   * @memberof Index
   */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userEdit.email !== this.props.userEdit.email || prevProps.userEdit.full_name !== this.props.userEdit.full_name) {
            this.setState({
                userEdit: this.props.userEdit
            })
        }
    }

    /**
     * Function to add email or user name
     * @param {*} email 
     */
    updateProperty(property, value) {
        let updatedUser = {...this.state.userEdit};
        updatedUser[property] = value;
        this.setState({
            userEdit: updatedUser
        })
    }

    /**
     * onSave
     */
    onSaveItem() {
        if (this.state.userEdit) {
            if(this.props.onSaveItem)
                this.props.onSaveItem(this.state.userEdit);
        }
    }

    /**
     * canBeSaved
     */
    canBeSaved(){
        if(this.state.userEdit === undefined)
            return false;
        if (this.state.userEdit.full_name.length === 0 || this.state.userEdit.full_name === "")
            return false;
        if(this.state.userEdit.email.length === 0 || this.state.userEdit.email === "")
            return false;
        if(this.state.userEdit.email !== ""){
            let regexEmail = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regexEmail.test(this.state.userEdit.email)){
                return false;
            }
            else{
                return true;
            }
        }
        return true;
    }

    /**
     * Dialog information
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
            open = {this.props.open}
            onClose={this.props.onClose}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {this.props.title}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={this.onSaveItem}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.canBeSaved()}
                    />
                }>

                <form>
                    <TextField fullWidth required
                        error = {!this.canBeSaved()}
                        id="standard-read-only-input"
                        className={classes.textField}
                        label={this.props.message}
                        defaultValue={this.state.userEdit.email}
                        margin="normal"
                        onChange={(event) => { this.updateProperty("email", event.target.value)}}
                    />
                     <TextField fullWidth required
                        error = {!this.canBeSaved()}
                        id="standard-read-only-input"
                        className={classes.textField}
                        label={<Translate id="suppliers.nameRequered"/>}
                        defaultValue={this.state.userEdit.full_name}
                        margin="normal"
                        onChange={(event) => { this.updateProperty("full_name", event.target.value) }}
                    />
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 450,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(EditMailAdministrator));