import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LayoutHeaderWorkFlow from './../common/LayoutHeaderWorkFlow'
import MaterialsTable from './components/MaterialsTable'
import LoadingPage from '../common/LoadingPage'

import RegisterDeliveryButton from '../planning/deliveryOrder/components/RegisterDeliveryButton'

/** actions inport section */
import { loadPartnership, loadSupplier, loadSupplierCode } from '../../store/suppliers/SupplierActions'
import { Translate } from 'react-localize-redux'
import { converListToArray } from '../../store/helpers/ListHelper'
import {
	// canBeModulePlaneationSupplier, 
	canBeOperationDeliveryWarehouse
} from '../../store/helpers/RolesHelper'
import { useEffect } from 'react'
import {
	acceptMaterialItemByWarehouseman,
	cancelDeliveryOrderToClient,
	dowloandOrderOperation,
	downloadDeliveryMaterialReturnedOrMissingPdf,
	loadDeliveryOrderFromBackEnd,
	removeFilesQualityCertificates,
	sendDeliveryOrderToSAP,
	updateMaterialFromDeliveryOrder,
	UpdateReceivedAmount,
	uploadFilesQualityCertificates,
	downloadUploadFiles
} from '../../store/plannigFersa/DeliveryOrderAction'
import { useState } from 'react'
import {
	getIsLoadingDeliveryOrdenItems,
	getLoadingDeliveryOrderShowView,
} from '../../store/plannigFersa/ReleasePlanningSelector'
import HistoryPartial from './components/HistoryPartial'
import FilesComponent from './components/FilesComponent'

import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers'
import RegisterWarehouseUser from './components/RegisterWarehouseUser'
import AddCommonItem from '../common/AddCommonItem'
import RatingSupplierRatingPartial from './components/RatingSupplierRatingPartial'
import Evidence from '../common/Evidence'
// import { IsUserSupplierAdministrator } from '../../store/helpers/SessionHelper'
import InformationPartial from './components/InformationPartial'
import { newCompanyDataFersa } from '../../store/helpers/Company'
import SendDeliveryOrderToSapModal from './components/SendDeliveryOrderToSapModal'
import { Typography } from '@material-ui/core'
const Show = (props) => {
	const { classes } = props
	const [selectdTab, setSelectedTab] = useState(0)
	const [state, setEstate] = useState({
		activeTab: 0,
		//Toaster state data
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		openCancelDialog: false,
		openRegisterWarehouse: false,
		openEditQuantityModal: false,
		userWarehouse: null,
		openCancelInSap: false,
		material: null
	})

	const [openCloseSendOrderToSAPDialog, setCloseSendOrderToSAPDialog] = useState(false)
	const dispatch = useDispatch()
	const user = useSelector((state) => state.oidc.user)
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const supplier = useSelector((state) =>
		state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null
	)
	const company = useSelector((state) =>
		state.profile.get('selectedCompany') ? state.profile.get('selectedCompany').toJS() : null
	)
	const deliveryOrder = useSelector((state) =>
		state.deliveryOrders.get('deliveryOrder') ? state.deliveryOrders.get('deliveryOrder').toJS() : null
	)
	let releasePlan = deliveryOrder ? deliveryOrder.releasePlan : ''

	const IsLoadingdeliveryOrder = useSelector((state) => getIsLoadingDeliveryOrdenItems(state))
	const isLoadingShowView = useSelector((state) => getLoadingDeliveryOrderShowView(state))
	const isLoadingSavingMaterial = useSelector((state) => state.deliveryOrder.get('IsSavingLoadingItem'))
	const isDonwloadingReport = useSelector((state) => state.deliveryOrders.get('isDonwloadingReport'))

	useEffect(() => {
		if (user.profile.company_token) {
			const id = props.match.params.token
			dispatch(loadDeliveryOrderFromBackEnd(id))
			dispatch(loadSupplier(user.profile.company_token)).then((supplierValue) => {
				dispatch(loadPartnership(supplierValue.code, company.token))
			})
		} else {
			const id = props.match.params.token

			dispatch(loadDeliveryOrderFromBackEnd(id)).then((delivery) => {
				dispatch(loadSupplierCode(delivery.supplierCode))
			})
		}
	}, [dispatch, props.match.params.token])

	/**
	 * On render view to register delivery arrival
	 */
	const onRegisterArrival = () => {
		props.history.push(`/deliveryOrderFersa/registerarrival`)
	}
	const handleChange = (event, newValue) => {
		setSelectedTab(newValue)
	}

	/**
	 * Method to handle state and open any modal
	 *
	 * @param {*} modalName
	 * @param {*} [item=null]
	 * @memberof Show
	 */
	const onOpenModal = (modalName, item = null) => {
		setEstate((prev) => ({ ...prev, [modalName]: true }))
	}
	/* *
	 * onCancelFolio
	 */
	const onCancelDeliveryOpen = () => {
		setEstate((prev) => ({ ...prev, openCancelDialog: true }))
	}
	const onCloseModal = (modalName) => {
		setEstate((prev) => ({ ...prev, [modalName]: false }))
	}

	const onUpdateProperty = (item, property) => {
		if (property === 'receivedAmount') {
			return dispatch(UpdateReceivedAmount(item.id, item[property]))
		} else {
			return dispatch(updateMaterialFromDeliveryOrder(item, property, true))
			.then(_=>{
				const id = props.match.params.token
				dispatch(loadDeliveryOrderFromBackEnd(id, false))
			})
		}
	}
	const downloadPDF = (item, property) => {
		return dispatch(dowloandOrderOperation(props.match.params.token))
	}
	const downloadPDForMaterialReturnedOrMissing = () => {
		return dispatch(downloadDeliveryMaterialReturnedOrMissingPdf(props.match.params.token))
	}
	/**
	 * Method to call Accept material action
	 *
	 * @param {*} itemToken
	 * @memberof Show
	 */
	const onAcceptMaterial = (itemToken, item) => {
		onOpenModal('openRegisterWarehouse')
		setEstate((prev) => ({ ...prev, itemToken: itemToken, material: item }))
	}
	const acceptMaterial = (userWarehouse) => {
		return dispatch(acceptMaterialItemByWarehouseman(state.itemToken, userWarehouse))
			.then(() => {
				onCloseModal('openRegisterWarehouse')
			})
			.catch((error) => {
				const errorValue = showErrorToaster(error)
				setEstate((prev) => ({ ...prev, errorValue }))
				return Promise.reject()
			})
	}

	const cancelDeliveryOrder = (common) => {
		let deliveryOrderid = props.match.params.token

		return dispatch(cancelDeliveryOrderToClient(deliveryOrderid, common))
			.then(() => {
				const showSuccess = showSuccessToaster()
				setEstate((prev) => ({ ...prev, openCancelDialog: false }))
				setEstate(showSuccess)
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				setEstate(showErrorToaster(error))
				return Promise.reject()
			})
	}

	const onOpenDialodSendDeliveryOrderToSAP = () => {
		setCloseSendOrderToSAPDialog(!openCloseSendOrderToSAPDialog)
	}

	const onSendDeliveryOrderToSAP = (centerSelected) => {
		let deliveryOrderToken = props.match.params.token
		return dispatch(sendDeliveryOrderToSAP(deliveryOrderToken, centerSelected))
			.then(() => {
				const id = props.match.params.token
				dispatch(loadDeliveryOrderFromBackEnd(id, false))
				onOpenDialodSendDeliveryOrderToSAP()
				// this.setState(showSuccessToaster());
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				const id = props.match.params.token
				dispatch(loadDeliveryOrderFromBackEnd(id, false))
				onOpenDialodSendDeliveryOrderToSAP()
				// this.setState(showErrorToaster(error));
				// const id = props.match.params.token
				// dispatch(loadDeliveryOrderFromBackEnd(id, false))
				return Promise.reject()
			})
	}

	const uploadFilesDeliveryOrden = (file, folderName) => {
		const id = props.match.params.token
		return dispatch(uploadFilesQualityCertificates(file, id, folderName))
	}

	const removeFiles = (file, folderName) => {
		const id = props.match.params.token
		return dispatch(removeFilesQualityCertificates(file, id, folderName))
	}


	const onDownloadUploadFile = (fileName, folderName, fileExtension) => {
		const id = props.match.params.token
		return dispatch(downloadUploadFiles(id, folderName, fileName, fileExtension))

	}


	const getCardContent = () => {
		console.log("deliveryOrder", deliveryOrder)
		if (deliveryOrder !== null) {
			// let isUserFromWarehouseRole = canBeOperationDeliveryWarehouse(userRolesByCompany)
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Divider />
						<AppBar position='static' color='default'>
							<Tabs
								indicatorColor='secondary'
								textColor='secondary'
								className={classes.styleTab}
								value={selectdTab}
								onChange={handleChange}
							>
								<Tab label={<Translate id='common.information' />} />
								<Tab label={<Translate id='deliveryOrderModule.history' />} />
								<Tab label={<Translate id='deliveryOrderModule.qualityCertificates' />} />
								<Tab label={<Translate id='deliveryOrderModule.bills' />} />
								{deliveryOrder.ratings && deliveryOrder.ratings.length > 0 && (
									<Tab
										label={<Translate id='deliveryOrderModule.ratingOrder.ratingSupplierRating' />}
									/>
								)}

								{/* {deliveryOrder.ratings.length > 0 && <Tab label={<Translate id="deliveryOrderModule.ratingOrder.ratingSupplierRating" />} />} */}
							</Tabs>
						</AppBar>
						{selectdTab === 0 && <InformationPartial classes={classes} deliveryOrder={deliveryOrder} />}
						{selectdTab === 1 && <HistoryPartial classes={classes} deliveryOrder={deliveryOrder} />}
						{selectdTab === 2 && (
							<>
								<Grid container>
									<Grid item xs={12}>
										<Typography style={{ marginTop: "15px" }}>Archivos de los certificados de calidad</Typography>
										{/* {(canBeModulePlaneationSupplier(userRolesByCompany) ||
											IsUserSupplierAdministrator(user)) && ( */}
										{false && < Evidence
											// isDisabled={isUserFromWarehouseRole}
											isDisabled={true}
											baseApi={process.env.REACT_APP_SIP_PLANNIG_FERSA}
											removeFiles={(file) => removeFiles(file, "qualityCertificateFiles")}
											uploadFiles={(file) => uploadFilesDeliveryOrden(file, "qualityCertificateFiles")}
											files={deliveryOrder.qualityCertificateFiles}
											successToasterDisplayed={true}
										/>}
										{/* ) */}
										{/* } */}
										<br />
										<FilesComponent
											files={deliveryOrder.qualityCertificateFiles}
											// onDownloadFile={(event) => onDownloadUploadFile(event.target.id, )}
											// onDeleteFile={removeFiles}
											folderName={"qualityCertificateFiles"}
											onDownloadFile={onDownloadUploadFile}
											isSaving={isDonwloadingReport}

										/>
									</Grid>

								</Grid>

								{/* <Grid>
									{canBeOperationDeliveryWarehouse(userRolesByCompany) && (
										<Evidence
											isDisabled={true}
											baseApi={process.env.REACT_APP_SIP_PLANNIG_FERSA}
											files={deliveryOrder.attachments}
										/>
									)}
								</Grid> */}
							</>
						)}
						{selectdTab === 3 && (
							<>

								<Grid container>
									<Grid item xs={12}>
										<Typography style={{ marginTop: "15px" }}>Archivos de las facturas</Typography>
										{/* {
									(canBeModulePlaneationSupplier(userRolesByCompany) ||
										IsUserSupplierAdministrator(user)) && ( */}
										{false && <Evidence
											isDisabled={true}
											// isDisabled={isUserFromWarehouseRole}
											baseApi={process.env.REACT_APP_SIP_PLANNIG_FERSA}
											removeFiles={(file) => removeFiles(file, "billsFiles")}
											uploadFiles={(file) => uploadFilesDeliveryOrden(file, "billsFiles")}
											files={deliveryOrder.billsFiles}
											successToasterDisplayed={true}
										/>}
										{/* ) */}
										{/* } */}

										<br />
										<FilesComponent
											files={deliveryOrder.billsFiles}
											// onDownloadFile={(event) => onDownloadUploadFile(event.target.id, )}
											// onDeleteFile={removeFiles}
											folderName={"billsFiles"}
											onDownloadFile={onDownloadUploadFile}
											isSaving={isDonwloadingReport}

										/>
									</Grid>
								</Grid>

							</>
						)}

						{selectdTab === 4 && (
							<RatingSupplierRatingPartial
								ratings={deliveryOrder.ratings ? deliveryOrder.ratings : []}
								canEvaluarOrder={true}
							/>
						)}
						{/** list materials in order  */}
						<MaterialsTable
							data={deliveryOrder.materials ? deliveryOrder.materials : []}
							hasBeenSentToSAP={deliveryOrder.exist_folios_to_sap}
							totalOrderItems={deliveryOrder ? deliveryOrder.total_order_items : 0}
							companyToken={company.token}
							deliveryOrderToken={props.match.params.token}
							canSupplierCancel={deliveryOrder.can_supplier_cancel}
							canBeQualityValidate={deliveryOrder.can_be_quality}
							canAcceptEditWarehouseman={
								deliveryOrder.exist_register_warehouse_user &&
								canBeOperationDeliveryWarehouse(userRolesByCompany)
							}
							canBeCancelByWarehouse={
								deliveryOrder.can_be_cancel_by_warehouse &&
								canBeOperationDeliveryWarehouse(userRolesByCompany)
							}
							isDisabled
							roles={userRolesByCompany}
							user={user}
							isSaving={isLoadingSavingMaterial}
							onCancelDeliveryOpen={onCancelDeliveryOpen}
							onAcceptMaterial={onAcceptMaterial}
							showOptionsByWarehouse={deliveryOrder.show_options_by_warehouse}
							canBeSendSAP={deliveryOrder.can_user_send_to_sap}
							onSendDeliveryOrderToSAP={onOpenDialodSendDeliveryOrderToSAP}
							isCloseOrder={deliveryOrder.isClosed}
							isCanceled={deliveryOrder.is_canceled}
							onCancelDeliveryInSAPOpen={() => ''}
							onUpdateProperty={onUpdateProperty}
							hasMaterialReturnedMissing={deliveryOrder.has_material_returned_or_missing}
							onMenuOptionItemClick={onOpenModal}
							onUpdateSapFolio={() => ''}
							downloadPDForMaterialReturnedOrMissing={downloadPDForMaterialReturnedOrMissing}
							canBePrintOrderPdf={props.deliveryOrder && this.props.deliveryOrder.can_be_print_order_pdf}
							history={props.history}
							downloadPDF={downloadPDF}
							isVisibleErrorSap={!deliveryOrder.isClosed ? true:false}
						/>

						<SendDeliveryOrderToSapModal
							title={<Translate id='deliveryOrderModule.sendToSapTitle' />}
							open={openCloseSendOrderToSAPDialog}
							onClose={onOpenDialodSendDeliveryOrderToSAP}
							onSendDeliveryOrderToSAP={onSendDeliveryOrderToSAP}
							isSaving={isLoadingSavingMaterial}
						/>
					</Grid>
				</Grid>
			)
		}
	}
	if (IsLoadingdeliveryOrder === true || isLoadingShowView === true) {
		return <LoadingPage />
	} else {
		let isValidToRegiserOrder = deliveryOrder ? deliveryOrder.is_valid_to_register_order : false
		return (
			<Card>
				{canBeOperationDeliveryWarehouse(userRolesByCompany) && isValidToRegiserOrder && (
					<span>
						<RegisterDeliveryButton classes={classes} open={onRegisterArrival} />
						<br />
						<br />
						<br />
					</span>
				)}

				{supplier && (
					<LayoutHeaderWorkFlow
						{...props}
						company={newCompanyDataFersa(company, releasePlan)}
						supplier={supplier}
						workFlow={deliveryOrder}
						title={<Translate id='deliveryOrderModule.deliveryOrder' />}
					/>
				)}
				<CardContent className={classes.cardcontent}>{getCardContent()}</CardContent>
				{/** Dialog to cancel order by client */}
				<RegisterWarehouseUser
					title={<Translate id='deliveryOrderModule.selectYourName' />}
					message={<Translate id='deliveryOrderModule.messageToAddWarehouse' />}
					open={state.openRegisterWarehouse}
					onClose={() => onCloseModal('openRegisterWarehouse')}
					onRegisterWarehouseUser={acceptMaterial}
					isSaving={props.isSavingDeliveryOrder}
					material = {state.material ? state.material: null}
				/>
				{/** Dialog to cancel order by client or internal user */}
				<AddCommonItem
					title={<Translate id='deliveryOrderModule.confirmCancel' />}
					message={<Translate id='deliveryOrderModule.reazonCancel' />}
					open={state.openCancelDialog}
					onClose={() => onCloseModal('openCancelDialog')}
					onSaveItem={cancelDeliveryOrder}
					isSaving={false}
					isTextArea={true}
				/>
			</Card>
		)
	}
}
/**
 * Get the card content
 *
 * @memberof Show
 */

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	inline: {
		display: 'inline',
	},
	logoCompany: {
		maxHeight: 100,
	},
	logoSupplier: {
		marginLeft: 80,
		maxHeight: 80,
	},
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
	stickyDiv: {
		position: 'fixed',
		top: 88,
		zIndex: '300',
	},
	margin: {
		margin: theme.spacing.unit,
	},
	extendedIcon: {
		marginRight: theme.spacing.unit,
	},
	cardcontent: {
		padding: 5,
		"&:last-child": {
			paddingLeft: 5,
			paddingRight: 5,
		}
	  }
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
