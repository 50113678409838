import { createSelector } from 'reselect'
import { toSafeJS } from '../helpers/ImmutableHelper'

const getIndexState = (state) => state.certifications

/**
 * Get Transport tenders list selector
 */
export const getCertificationsSelector = createSelector([getIndexState], (indexState) => {
	const certifications = indexState.get('certificationsPaginated')
	return certifications ? toSafeJS(certifications) : []
})
export const getIsLoadingSaving = createSelector([getIndexState], (indexState) => {
	const isSaving = indexState.get('isLoading')
	return isSaving
})
export const getIsLoadingDownload = createSelector([getIndexState], (indexState) => {
	const isSaving = indexState.get('isDownloadLoader')
	return isSaving
})
