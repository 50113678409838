import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { getTranslate, Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import LayoutHeaderWorkFlow from './../../common/LayoutHeaderWorkFlow'
import MaterialsTable from './components/MaterialsTable'
import Evidence from '../../common/Evidence'
import LoadingPage from '../../common/LoadingPage'
import InformationPartial from './components/InformationPartial'
import HistoryPartial from './components/HistoryPartial'
import RegisterDeliveryButton from './components/RegisterDeliveryButton'
import RegisterWarehouseUser from './components/RegisterWarehouseUser'
import RatingSupplierRatingPartial from './components/RatingSupplierRatingPartial'
import QualityCertificate from './components/QualityCertificate'

/** actions inport section */
import { loadSupplier } from './../../../store/suppliers/SupplierActions'
import {
	loadDeliveryOrderFromBackEnd,
	cancelDeliveryOrderToClient,
	uploadFilesQualityCertificates,
	removeFilesQualityCertificates,
	acceptMaterialItemByWarehouseman,
	sendDeliveryOrderToSAP,
	cancelDeliveryOrderInSAP,
	updateMaterialProperty,
	updateQuantityMaterial,
	updateSapNumberMaterial,
	downloadDeliveryMaterialReturnedOrMissingPdf,
	downloadCertificateQuaityExcel,
	addItemInCertificateInDeliveryOrder,
	updateItemInCertificateInDeliveryOrder,
	removeItemInCertificateInDeliveryOrder,
	updateDeliveryTimeProperty,
} from '../../../store/planning/DeliveryOrdersAction'
import { converListToArray } from '../../../store/helpers/ListHelper'

import Toaster from '../../common/Toaster'
import { showSuccessToaster, showErrorToaster, showWarningToaster } from '../../../store/helpers/ToasterHelpers'
import AddCommonItem from '../../common/AddCommonItem'
import {
	canBeOperationDeliveryWarehouse,
	canBeModulePlaneationSupplier,
	canValidateDeliveryOrderQuality,
} from '../../../store/helpers/RolesHelper'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { IsUserSupplierAdministrator } from '../../../store/helpers/SessionHelper'
import EditMaterialQuantityModal from './components/EditMaterialQuantityModal'

/**
 * Implent component to show delivery order
 */
class Show extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			activeTab: 0,
			//Toaster state data
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			openCancelDialog: false,
			openRegisterWarehouse: false,
			openEditQuantityModal: false,
			userWarehouse: null,
			openCancelInSap: false,
			arrivaltime: '',
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let deliveryOrderToken = this.props.match.params.token
		this.props.getDeliveryOrder(deliveryOrderToken)
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.isLoadingDeliveryOrder !== this.props.isLoadingDeliveryOrder &&
			this.props.deliveryOrder !== null
		) {
			/// Get information full from delivery Order
			let deliveryOrder = this.props.deliveryOrder
			this.props.loadSupplier(deliveryOrder.supplier_token)
			/// Verify if exist user register warehouse in delivery order
			if (
				deliveryOrder.exist_register_warehouse_user &&
				canBeOperationDeliveryWarehouse(this.props.userRolesByCompany)
			) {
				this.setState({
					openRegisterWarehouse: true,
					arrivaltime: deliveryOrder.arrival_time
				})
			}
		}
	}

	/**
	 * Method to change the active tab
	 */
	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 * onCancelFolio
	 */
	onCancelDeliveryOpen() {
		this.setState({
			openCancelDialog: true,
		})
	}

	/**
	 * Function to on cancel order
	 * @param {*} common
	 */
	cancelDeliveryOrderToClient(common) {
		let deliveryOrderToken = this.props.match.params.token
		return this.props
			.cancelDeliveryOrderToClient(deliveryOrderToken, common)
			.then(() => {
				this.setState({
					openCancelDialog: false,
				})
				this.setState(showSuccessToaster())
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showErrorToaster(error))
				return Promise.reject()
			})
	}

	/**
	 * Action to set state to open of available operations dialog
	 * @memberof
	 */
	setOpenDialog = (openAvailable) => {
		this.setState({
			openAvailableOperationsDialog: openAvailable,
		})
	}

	/**
	 * On render view to register delivery arrival
	 */
	onRegisterArrival() {
		this.props.history.push(`/deliveryorder/registerarrival`)
	}

	/**
	 * Function to register warehouse user in order
	 */
	onRegisterWarehouseUser(userWarehouse) {
		this.setState({
			openRegisterWarehouse: false,
			userWarehouse: userWarehouse,
		})
	}

	/**
	 * Method to handle state and open any modal
	 *
	 * @param {*} modalName
	 * @param {*} [item=null]
	 * @memberof Show
	 */
	onOpenModal(modalName, item = null) {
		this.setState({
			item,
			[modalName]: true,
		})
	}

	onCloseModal(modalName) {
		this.setState({
			[modalName]: false,
		})
	}

	/**
	 * Method to call Accept material action
	 *
	 * @param {*} itemToken
	 * @memberof Show
	 */
	onAcceptMaterial(itemToken) {
		if (IsNullOrEmpty(this.state.userWarehouse)) {
			this.setState(showErrorToaster('deliveryOrderModule.error.mustSelectWorkerBefore'))
			this.onOpenModal('openRegisterWarehouse')
		}
		if (!IsNullOrEmpty(this.state.userWarehouse))
			return this.props
				.acceptMaterial(itemToken, this.state.userWarehouse)
				.then(() => {
					this.setState(showSuccessToaster())
				})
				.catch((error) => {
					this.setState(showErrorToaster(error))
					return Promise.reject()
				})
	}

	/**
	 * Function to on send order from sap
	 * @param {*} common
	 */
	onSendDeliveryOrderToSAP() {
		let deliveryOrderToken = this.props.match.params.token
		return this.props
			.sendDeliveryOrderToSAP(deliveryOrderToken)
			.then(() => {
				this.setState(showSuccessToaster())
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showErrorToaster(error))
				return Promise.reject()
			})
	}

	/**
	 * onCancelDeliveryInSAPOpen
	 */
	onCancelDeliveryInSAPOpen() {
		this.setState({
			openCancelInSap: true,
		})
	}

	/**
	 * Function to on send order from sap
	 * @param {*} common
	 */
	onCancelDeliveryOrderToSAP(reasonCancellation) {
		let deliveryOrderToken = this.props.match.params.token
		return this.props
			.cancelDeliveryOrderInSAP(deliveryOrderToken, reasonCancellation)
			.then(() => {
				this.onCloseModal('openCancelInSap')
				this.setState(showSuccessToaster())
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showErrorToaster(error))
				return Promise.reject()
			})
	}

	/**
	 * Update any material property in the data source
	 *
	 *
	 * @param {*} item
	 * @param {*} property
	 * @returns
	 * @memberof Show
	 */
	onUpdateProperty(item, property) {
		return this.props
			.updatePropertyInItem(item.token, property, item[property])
			.then(() => {
				this.setState(showSuccessToaster())
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showErrorToaster(error))
				return Promise.reject()
			})
	}

	/**
	 * Method to call action and update quantity of material item
	 *
	 * @param {*} item
	 * @param {*} property
	 * @returns
	 * @memberof Show
	 */
	onEditMaterialItemQuantity(item, property) {
		return this.props
			.updateQuantityMaterialItem(item.token, item[property])
			.then(() => {
				this.setState(showSuccessToaster())
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showErrorToaster(error))
				return Promise.reject()
			})
	}

	onUpdateSapFolio(item, property) {
		return this.props
			.updateSapNumberMaterialItem(item.token, item[property])
			.then(() => {
				this.setState(showSuccessToaster())
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showErrorToaster(error))
				return Promise.reject()
			})
	}

	/**
	 * onAddItemInCertificateInDeliveryOrder
	 * @param {*} certificateToken
	 */
	onAddItemInCertificateInDeliveryOrder(certificateToken) {
		return this.props
			.addItemInCertificateInDeliveryOrder(certificateToken)
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showWarningToaster(error))
				return Promise.reject()
			})
	}

	/**
	 * onUpdateItemInCertificateInDeliveryOrder
	 * @param {*} certificateToken
	 * @param {*} itemToken
	 * @param {*} property
	 * @param {*} valueProperty
	 */
	onUpdateItemInCertificateInDeliveryOrder(certificateToken, itemToken, property, valueProperty) {
		return this.props
			.updateItemInCertificateInDeliveryOrder(certificateToken, itemToken, property, valueProperty)
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				console.log('error', error)
				return Promise.reject()
			})
	}

	/**
	 * onRemoveItemInCertificateInDeliveryOrder
	 * @param {*} certificateToken
	 * @param {*} itemToken
	 */
	onRemoveItemInCertificateInDeliveryOrder(certificateToken, itemToken) {
		return this.props
			.removeItemInCertificateInDeliveryOrder(certificateToken, itemToken)
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				return Promise.reject()
			})
	}

	setArrivalTime(event) {
		this.setState({
			arrivaltime: event.currentTarget.value,
		})
	}
	
	updateArrivalTime() {
		this.props.updateDeliveryTimeProperty(this.props.match.params.token, this.state.arrivaltime)
	}

	/**
	 * Get the card content
	 *
	 * @memberof Show
	 */
	getCardContent() {
		if (this.props.isLoadingDeliveryOrder === true) {
			return <LoadingPage />
		} else if (this.props.deliveryOrder !== null) {
			const { classes } = this.props
			let canbeEditCertificateSupplier =
				this.props.deliveryOrder.can_supplier_cancel &&
				(canBeModulePlaneationSupplier(this.props.userRolesByCompany) ||
					IsUserSupplierAdministrator(this.props.currentUser))
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Divider />
						<AppBar position='static' color='default'>
							<Tabs
								indicatorColor='secondary'
								textColor='secondary'
								className={classes.styleTab}
								value={this.state.activeTab}
								onChange={this.onClickToActiveTab}
							>
								<Tab label={<Translate id='common.information' />} />
								<Tab label={<Translate id='deliveryOrderModule.history' />} />
								<Tab label={<Translate id='deliveryOrderModule.qualityCertificates' />} />
								{this.props.deliveryOrder.ratings.length > 0 && (
									<Tab
										label={<Translate id='deliveryOrderModule.ratingOrder.ratingSupplierRating' />}
									/>
								)}
							</Tabs>
						</AppBar>
						{this.state.activeTab === 0 && (
							<InformationPartial
								classes={classes}
								deliveryOrder={this.props.deliveryOrder}
								updateArrivalTime={this.updateArrivalTime}
								setArrivalTime={this.setArrivalTime}
								arrivaltime={this.state.arrivaltime}
								user={this.props.currentUser}
							/>
						)}
						{this.state.activeTab === 1 && (
							<HistoryPartial classes={classes} deliveryOrder={this.props.deliveryOrder} />
						)}
						{this.state.activeTab === 2 && (
							<Grid>
								{this.props.deliveryOrder.attachments.length !== 0 && (
									<Evidence
										isDisabled={true}
										baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
										removeFiles={this.props.removeFiles}
										uploadFiles={this.props.uploadFiles}
										files={this.props.deliveryOrder.attachments}
									/>
								)}

								<br />
								{this.props.deliveryOrder.certificates.map((certificate) => {
									return (
										<QualityCertificate
											key={certificate.token}
											certificate={certificate}
											canBeEditSupplier={canbeEditCertificateSupplier}
											deliveryOrderToken={this.props.deliveryOrder.token}
											downloadCertificateQuaity={this.props.downloadCertificateQuaityExcel}
											canBePrintOrderPdf={
												this.props.deliveryOrder &&
												this.props.deliveryOrder.can_be_print_order_pdf
											}
											canbeDeleteCetificate={false}
											onAddItem={this.onAddItemInCertificateInDeliveryOrder}
											onUpdateItem={this.onUpdateItemInCertificateInDeliveryOrder}
											onRemoveItem={this.onRemoveItemInCertificateInDeliveryOrder}
											isSaving={this.props.isSavingDeliveryOrder}
										/>
									)
								})}
							</Grid>
						)}
						{this.state.activeTab === 3 && (
							<RatingSupplierRatingPartial
								ratings={this.props.deliveryOrder.ratings}
								canEvaluarOrder={this.props.deliveryOrder.can_evaluate_service_supplier}
							/>
						)}

						{/** list materials in order  */}
						<MaterialsTable
							data={this.props.deliveryOrder && this.props.deliveryOrder.materials}
							hasBeenSentToSAP={this.props.deliveryOrder.exist_folios_to_sap}
							totalOrderItems={this.props.deliveryOrder ? this.props.deliveryOrder.total_order_items : 0}
							companyToken={this.props.deliveryOrder && this.props.deliveryOrder.company_token}
							deliveryOrderToken={this.props.match.params.token}
							canSupplierCancel={this.props.deliveryOrder.can_supplier_cancel}
							canBeQualityValidate={this.props.deliveryOrder.can_be_quality}
							canAcceptEditWarehouseman={
								this.props.deliveryOrder.exist_register_warehouse_user &&
								canBeOperationDeliveryWarehouse(this.props.userRolesByCompany)
							}
							canBeCancelByWarehouse={
								this.props.deliveryOrder.can_be_cancel_by_warehouse &&
								canBeOperationDeliveryWarehouse(this.props.userRolesByCompany)
							}
							isDisabled
							roles={this.props.userRolesByCompany}
							user={this.props.currentUser}
							isSaving={this.props.isSavingDeliveryOrder}
							onCancelDeliveryOpen={this.onCancelDeliveryOpen}
							onAcceptMaterial={this.onAcceptMaterial}
							showOptionsByWarehouse={this.props.deliveryOrder.show_options_by_warehouse}
							canBeSendSAP={this.props.deliveryOrder.can_user_send_to_sap}
							onSendDeliveryOrderToSAP={this.onSendDeliveryOrderToSAP}
							isCloseOrder={this.props.deliveryOrder.is_closed}
							isCanceled={this.props.deliveryOrder.is_canceled}
							onCancelDeliveryInSAPOpen={this.onCancelDeliveryInSAPOpen}
							onUpdateProperty={this.onUpdateProperty}
							hasMaterialReturnedMissing={this.props.deliveryOrder.has_material_returned_or_missing}
							onMenuOptionItemClick={this.onOpenModal}
							onUpdateSapFolio={this.onUpdateSapFolio}
							downloadPDForMaterialReturnedOrMissing={
								this.props.downloadDeliveryMaterialReturnedOrMissingPdf
							}
							canBePrintOrderPdf={
								this.props.deliveryOrder && this.props.deliveryOrder.can_be_print_order_pdf
							}
							canGenerateQuality={
								this.props.deliveryOrder.can_generate_quality &&
								canValidateDeliveryOrderQuality(this.props.userRolesByCompany)
							}
							history={this.props.history}
							isRampThree={this.props.deliveryOrder && this.props.deliveryOrder.ramp_number === 3}
							isAddDeliveryTime={this.props.deliveryOrder.arrival_time.length <= 2}
						/>

						{!this.props.deliveryOrder.can_be_print_order_pdf && (
							<Grid container spacing={12}>
								<Typography color='primary' variant='overline'>
									<strong>
										<Translate id={'deliveryOrderModule.error.invalidNotExistItemsInCertificate'} />
									</strong>
									<br />
									<small style={{ color: '#6c757d' }}>
										<strong>
											{
												<Translate
													id={'deliveryOrderModule.error.informationRequeredCertificateItem'}
												/>
											}
										</strong>
									</small>
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
			)
		}
	}

	render() {
		const { classes } = this.props
		let isValidToRegiserOrder = this.props.deliveryOrder
			? this.props.deliveryOrder.is_valid_to_register_order
			: false
		return (
			<Card>
				{canBeOperationDeliveryWarehouse(this.props.userRolesByCompany) && isValidToRegiserOrder && (
					<span>
						<RegisterDeliveryButton classes={classes} open={this.onRegisterArrival} />
						<br />
						<br />
						<br />
					</span>
				)}

				{this.props.supplier && (
					<LayoutHeaderWorkFlow
						{...this.props}
						workFlow={this.props.deliveryOrder}
						title={<Translate id='deliveryOrderModule.deliveryOrder' />}
					/>
				)}
				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to cancel order by client or internal user */}
				<AddCommonItem
					title={<Translate id='deliveryOrderModule.confirmCancel' />}
					message={<Translate id='deliveryOrderModule.reazonCancel' />}
					open={this.state.openCancelDialog}
					onClose={() => {
						this.onCloseModal('openCancelDialog')
					}}
					onSaveItem={this.cancelDeliveryOrderToClient}
					isSaving={this.props.isSavingDeliveryOrder}
					isTextArea={true}
				/>

				{/** Dialog to cancel order by client */}
				<RegisterWarehouseUser
					title={<Translate id='deliveryOrderModule.selectYourName' />}
					message={<Translate id='deliveryOrderModule.messageToAddWarehouse' />}
					open={this.state.openRegisterWarehouse}
					onClose={() => {
						this.onCloseModal('openRegisterWarehouse')
					}}
					onRegisterWarehouseUser={this.onRegisterWarehouseUser}
					isSaving={this.props.isSavingDeliveryOrder}
				/>

				{/** Dialog to cancel order in SAP */}
				<AddCommonItem
					title={<Translate id='deliveryOrderModule.canceOrderToSAP' />}
					message={<Translate id='deliveryOrderModule.reasonFromCancelInSAP' />}
					open={this.state.openCancelInSap}
					onClose={() => {
						this.onCloseModal('openCancelInSap')
					}}
					onSaveItem={this.onCancelDeliveryOrderToSAP}
					isSaving={this.props.isSavingDeliveryOrder}
					isTextArea={true}
				/>

				{/** Modal to edit Quantity of a material item in material table */}
				<EditMaterialQuantityModal
					title='deliveryOrderModule.changeMaterialItemQuantity'
					open={this.state.openEditQuantityModal}
					onClose={() => {
						this.onCloseModal('openEditQuantityModal')
					}}
					onSaveItem={this.onEditMaterialItemQuantity}
					isSaving={this.props.isSavingDeliveryOrder}
					item={this.state.item}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		company: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		currentUser: user,
		isLoadingSupplier: state.suppliers.get('isLoadingSupplier'),
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		isLoadingDeliveryOrder: state.deliveryOrders.get('isLoadingDeliveryOrder'),
		isSavingDeliveryOrder: state.deliveryOrders.get('isSavingDeliveryOrder'),
		deliveryOrder: state.deliveryOrders.get('deliveryOrder')
			? state.deliveryOrders.get('deliveryOrder').toJS()
			: null,
		isSavingMaterial: state.deliveryOrders.get('isSavingMaterial'),
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		getDeliveryOrder: (deliveryOrderToken) => {
			return dispatch(loadDeliveryOrderFromBackEnd(deliveryOrderToken))
		},
		uploadFiles: (files) => {
			return dispatch(uploadFilesQualityCertificates(files))
		},
		removeFiles: (fileName) => {
			return dispatch(removeFilesQualityCertificates(fileName))
		},
		cancelDeliveryOrderToClient: (token, razonForCancel) => {
			return dispatch(cancelDeliveryOrderToClient(token, razonForCancel))
		},
		acceptMaterial: (itemToken, workerName) => {
			return dispatch(acceptMaterialItemByWarehouseman(itemToken, workerName))
		},
		sendDeliveryOrderToSAP: (token) => {
			return dispatch(sendDeliveryOrderToSAP(token))
		},
		cancelDeliveryOrderInSAP: (token, reasonCancellation) => {
			return dispatch(cancelDeliveryOrderInSAP(token, reasonCancellation))
		},
		updatePropertyInItem: (materialItemToken, property, data) => {
			return dispatch(updateMaterialProperty(materialItemToken, property, data))
		},
		updateQuantityMaterialItem: (materialItemToken, data) => {
			return dispatch(updateQuantityMaterial(materialItemToken, data))
		},
		updateSapNumberMaterialItem: (materialItemToken, data) => {
			return dispatch(updateSapNumberMaterial(materialItemToken, data))
		},
		downloadDeliveryMaterialReturnedOrMissingPdf: (token) => {
			return dispatch(downloadDeliveryMaterialReturnedOrMissingPdf(token))
		},
		downloadCertificateQuaityExcel: (token, certificateToken) => {
			return dispatch(downloadCertificateQuaityExcel(token, certificateToken))
		},
		addItemInCertificateInDeliveryOrder: (certificateToken) => {
			return dispatch(addItemInCertificateInDeliveryOrder(certificateToken, false))
		},
		updateItemInCertificateInDeliveryOrder: (certificateToken, itemToken, property, data) => {
			return dispatch(updateItemInCertificateInDeliveryOrder(certificateToken, itemToken, property, data, false))
		},
		removeItemInCertificateInDeliveryOrder: (certificateToken, itemToken) => {
			return dispatch(removeItemInCertificateInDeliveryOrder(certificateToken, itemToken, false))
		},
		updateDeliveryTimeProperty: (token, value) => {
			return dispatch(updateDeliveryTimeProperty(token, value))
		},
	}
}

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	inline: {
		display: 'inline',
	},
	logoCompany: {
		maxHeight: 100,
	},
	logoSupplier: {
		marginLeft: 80,
		maxHeight: 80,
	},
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
	stickyDiv: {
		position: 'fixed',
		top: 88,
		zIndex: '300',
	},
	margin: {
		margin: theme.spacing.unit,
	},
	extendedIcon: {
		marginRight: theme.spacing.unit,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
