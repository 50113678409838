/**
 * backgroundColorSelect
 * @param {*} status
 */
export const backgroundColorSelect = (status) => {
	switch (Number(status)) {
		case 1:
		case 6:
			return '#F7B924'
		case 2:
			return '#30B1FC'
		case 3:
			return '#3AC47D'
		case 4:
		case 5:
			return '#D92550'
		case 7:
			return 'rgba(134, 237, 205, 1)'
		default:
			return null
	}
}
