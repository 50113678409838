/** Import ReactJs section */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { renderToStaticMarkup } from 'react-dom/server'

/** Import MaterialUI section */
import { withStyles } from '@material-ui/core/styles'

import ProspectSupplierIncon from '@material-ui/icons/LocalShipping'
import SearchIcon from '@material-ui/icons/Search'
import { Translate, getTranslate } from 'react-localize-redux'
import { TextField, InputAdornment, Typography, Grid, Button, Card, CardHeader, CardContent } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Avatar from '@material-ui/core/Avatar'

import { withLocalize } from 'react-localize-redux'
import translations from '../../resources/translations.json'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import { showErrorToaster } from '../../store/helpers/ToasterHelpers'

/** Import actions */
import {
	loadProspect,
	uploadDocumentsProspect,
	updatePropertyProspect,
	updateCommentsData,
	sendRequestByProspect,
	uploadFilesEvidence,
	removeFilesEvidence,
} from '../../store/prospects/ProspectAction'

/** Import components */
import ApplicationBar from '../layouts/AnonymousApplicationBar'
import DocumentsComponent from './Components/DocumentsComponent'
import DocumentsProspect from './Components/DocumentsProspect'
import Toaster from '../common/Toaster'
import { checkPrivacyNoticePDF } from '../../store/helpers/NoticeOfPrivacy'
import { backgroundColorSelect } from '../../store/helpers/ProspectHelper.js'
import { COMPANY_IDENTIFIER_IMBERA_MX } from '../../store/helpers/ResourcesHelper'
import { getActivePrivacyNotice } from '../../store/companies/CompanyActions'

/**
 * RegisterProspect
 *
 * @class RegisterProspect
 * @extends {Component}
 */
class RegisterProspect extends Component {
	/**
	 *Creates an instance of RegisterProspect.
	 * @param {*} props
	 * @memberof RegisterProspect
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			currentFolio: '',
			statusDocument: 1,
			activeTab: 0,
			isValid: true,
			canBeHeader: false,
			extraFiles: [],
		}

		let defaultLanguage = localStorage.getItem('language')
		if (!defaultLanguage) {
			defaultLanguage = 'es'
		}
		this.props.initialize({
			languages: [
				{ name: 'EspaÃ±ol', code: 'es' },
				{ name: 'English', code: 'en' },
				{ name: 'Portugues', code: 'pt' },
			],
			translation: translations,
			options: {
				renderToStaticMarkup,
				defaultLanguage: defaultLanguage,
			},
		})
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let prospectToken = this.props.match.params.token
		this.props.loadProspect(prospectToken)
	}

	/**
	 * Update the value of query on local state
	 *
	 * @param {*} event
	 * @memberof searchDemandsByQuery
	 */
	onChangeFolio(event) {
		this.setState({ currentFolio: event.target.value })
	}

	/**
	 * Function to register folio from delivery order
	 * @param {*} event
	 */
	onClickRegisterFolio(event) {
		if (event.key === 'Enter') {
			let currentFolio = this.state.currentFolio
			if (currentFolio.trim() === this.props.prospect.code && this.props.prospect.can_be_edit_prospect) {
				this.setState({ statusDocument: 2, isValid: true, canBeHeader: true })
				this.props.getActivePrivacyNotice(this.props.prospect.company_id, localStorage)
			} else {
				this.setState({ isValid: false, canBeHeader: false })
			}
		}
	}

	/**
	 * Method to change the active tab
	 */
	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 * Function to update updatePropertyInstrumentsr
	 */
	updatePropertyComments(value) {
		this.props.updateProperty(this.props.match.params.token, value)
	}

	/**
	 * Send document by provider
	 */
	onSendDocumentByProspect() {
		let prospectToken = this.props.match.params.token
		this.props
			.sendRequestByProspect(prospectToken)
			.then(() => {
				this.setState({ statusDocument: 3, canBeHeader: false })
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * Add file
	 * @param {*} attachments
	 */
	uploadFiles(files) {
		let filesList = this.state.extraFiles
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.push(files[index])
			}
		}
		this.setState({
			extraFiles: filesList,
		})
		this.props.uploadFiles(files)

		return Promise.resolve('Ok')
	}

	/**
	 * Remove files
	 */
	removeFiles(fileName) {
		let filesList = this.state.extraFiles.filter((file) => {
			return file.name !== fileName
		})
		this.setState({
			extraFiles: filesList,
		})

		this.props.removeFiles(fileName)
		return Promise.resolve('Ok')
	}
	/**
	 * On render container information
	 */
	renderContainer() {
		let statusActive = this.state.statusDocument
		if (this.props.isLoading === true) {
			return <div>{'Cargando información '}</div>
		} else {
			let prospect = this.props.prospect

			let privacityActive = checkPrivacyNoticePDF(
				prospect.company_code,
				localStorage,
				this.props.activeprivacyNotice
			)
			switch (statusActive) {
				case 1:
					return (
						<div>
							<br />
							<Typography variant='subtitle1' style={{ color: '#990033' }} align='center'>
								<Translate id='prospects.viewInformationEnterTheCode' />
							</Typography>
							<div className='div-register'>
								<TextField
									autoFocus
									color='primary'
									className='search-folio'
									onKeyPress={this.onClickRegisterFolio}
									onChange={this.onChangeFolio}
									InputProps={{
										endAdornment: (
											<InputAdornment position='start'>
												<SearchIcon color='primary' />
											</InputAdornment>
										),
										disableUnderline: false,
									}}
								/>
								{!this.state.isValid && (
									<Typography variant='subtitle1' style={{ color: '#990033' }} align='center'>
										<Translate id='prospects.invalidCode' />
									</Typography>
								)}
							</div>
						</div>
					)
				case 2:
					return (
						<Grid item xs={12}>
							<AppBar position='static' color='default'>
								<Tabs
									indicatorColor='secondary'
									textColor='primary'
									value={this.state.activeTab}
									onChange={this.onClickToActiveTab}
								>
									<Tab
										label={
											<p>
												<i className='fa fa-exclamation-triangle'></i>
												<Translate id='common.privacyNotice' />
											</p>
										}
									/>
									<Tab
										label={
											<p>
												<i className='fa fa-file-image'></i>
												<Translate id='expedientSupplier.documentsRequest' />
											</p>
										}
									/>
								</Tabs>
							</AppBar>
							{this.state.activeTab === 0 && prospect && (
								<div
									className='embed-responsive embed-responsive-16by9'
									style={{ overflow: 'hidden', height: '100%', width: '100%' }}
								>
									<iframe
										title='official_notice'
										className='embed-responsive-item'
										src={privacityActive}
									></iframe>

									<a href={privacityActive} target='_blank' rel='noopener noreferrer'>
										<Translate id='common.privacyNoticePTM' />
									</a>
								</div>
							)}
							{this.state.activeTab === 1 && prospect && (
								<DocumentsComponent
									prospect={prospect}
									canBeEdit={this.props.prospect.can_be_edit_prospect}
									updatePropertyComments={this.updatePropertyComments}
									uploadesFiles={this.state.extraFiles}
									uploadFiles={this.uploadFiles}
									removeFiles={this.removeFiles}
								/>
							)}
							{this.state.activeTab === 1 && prospect && (
								<DocumentsProspect
									prospect={prospect}
									isDisabledObsPlant={prospect.status === 4 ? true : false}
									is_active_prospect={this.props.prospect.can_be_edit_prospect}
									isDisableOptions={false}
									uploadFiles={this.props.uploadFiles}
									removeFiles={this.props.removeFiles}
									translate={this.props.translate}
									uploadDocumentsProspect={this.props.uploadDocumentsProspect}
									updatePropertyProspect={this.props.updatePropertyProspect}
								/>
							)}
						</Grid>
					)
				case 3:
					return (
						<Grid item xs={12}>
							<Typography variant='h6' align='center'>
								<span class='text-success'>
									{<Translate id='biddingsModule.externalUsers.successData' />}
								</span>
							</Typography>
						</Grid>
					)
				default:
					return null
			}
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof
	 */
	render() {
		return (
			<div className='authenticated-container'>
				<div id='application-bar'>
					<ApplicationBar openLogin={true} {...this.props} />
				</div>
				<main className='registerContainer'>
					<Card>
						<CardHeader
							avatar={
								<>
									{this.state.canBeHeader && (
										<Avatar
											style={{
												backgroundColor: backgroundColorSelect(
													this.props.prospect && this.props.prospect.status
												),
											}}
										>
											<ProspectSupplierIncon />
										</Avatar>
									)}
								</>
							}
							action={
								<div>
									{this.props.prospect &&
										this.state.canBeHeader &&
										(this.props.prospect.status === 1 ||
											this.props.prospect.status === 4 ||
											this.props.prospect.status === 6) &&
										this.props.prospect.can_be_edit_prospect && (
											<Button
												variant='contained'
												color='primary'
												size='small'
												disabled={this.props.isSaving}
												onClick={() => {
													this.onSendDocumentByProspect()
												}}
											>
												<Translate id='memos.sendRequest' />
											</Button>
										)}
								</div>
							}
							title={
								this.state.canBeHeader && (
									<strong>
										<Typography variant='button'>
											<Translate id='prospects.aRequestFor' />
											{this.props.prospect && this.props.prospect.company_name}
										</Typography>
									</strong>
								)
							}
							subheader={
								this.state.canBeHeader && (
									<>
										<Translate id='prospects.requestTracking' />
										{this.props.prospect && this.props.prospect.creation_date} <br />
										<Translate id='prospects.submittedBy' />
										{this.props.prospect && this.props.prospect.creator_name} <br />
										<Translate id='prospects.status' />
										{this.props.prospect && this.props.prospect.description_status} <br />
										{
											<strong>
												<Translate id='prospects.readPrivacyNotice' />
											</strong>
										}
										<br />
										{this.props.prospect &&
											this.props.prospect.company_code === COMPANY_IDENTIFIER_IMBERA_MX && (
												<div
													className='alert alert-light'
													role='alert'
													style={{ borderColor: '#FFC107' }}
												>
													<h6 className='alert-heading'>
														<Translate
															id={'expedientSupplier.titleAlertConsentDataProcessing'}
														/>
													</h6>
													<Translate id='prospects.messageAlecrtConsentDataProcessingFirst' />
													<br />
													<Translate id='expedientSupplier.messageAlecrtConsentDataProcessingSecond' />
												</div>
											)}
									</>
								)
							}
						/>

						<CardContent>{this.renderContainer()}</CardContent>

						<Toaster
							message={<Translate id={this.state.toasterMessage} />}
							open={this.state.showToaster}
							variant={this.state.toasterVariant}
							onClose={() => {
								this.setState({ showToaster: false })
							}}
						/>
					</Card>
					{/* <FooterContainer /> */}
				</main>
			</div>
		)
	}
}

/**
 * Map state to props
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state) {
	return {
		isLoading: state.prospects.get('isLoadingProspect'),
		translate: getTranslate(state.localize),
		prospect: state.prospects.get('prospect') ? state.prospects.get('prospect').toJS() : null,
		isSaving: state.prospects.get('isSavingPropect'),
		activeprivacyNotice: state.companies.get('activePrivacyNotice')
			? state.companies.get('activePrivacyNotice').toJS()
			: null,
	}
}
/**
 * Map dispatch to props
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadProspect: (token) => {
			return dispatch(loadProspect(token))
		},
		updateProperty: (token, observations) => {
			return dispatch(updateCommentsData(token, observations))
		},
		sendRequestByProspect: (token) => {
			return dispatch(sendRequestByProspect(token))
		},
		uploadDocumentsProspect: (fileToken, files) => {
			return dispatch(uploadDocumentsProspect(fileToken, files))
		},
		updatePropertyProspect: (fileToken, key, value) => {
			return dispatch(updatePropertyProspect(fileToken, key, value))
		},
		uploadFiles: (files) => {
			return dispatch(uploadFilesEvidence(files))
		},
		removeFiles: (fileName) => {
			return dispatch(removeFilesEvidence(fileName))
		},
		getActivePrivacyNotice: (companyToken, localStorage) => {
			return dispatch(getActivePrivacyNotice(companyToken, localStorage))
		},
	}
}

const styles = (theme) => ({})

export default withLocalize(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegisterProspect)))
)
