import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/** Material UI imports section */
import { Card, CardContent, CircularProgress, Typography, withStyles } from '@material-ui/core';

/** Helpers imort section */
import { applyCurrencyFormat } from '../../../../store/helpers/StringHelper';

const MetricsCard = (props) => {
	const { title, loading, quantityOfServices, amount, classes } = props;

	return (
		<Card className={classes.root}>
			<CardContent className={classes.content}>
				<div className={classes.title}>
					<Typography variant="overline" color="primary">
						{title}
					</Typography>
				</div>
				<div className={classes.metrics}>
					{
						{
							true: (
								<Fragment>
									<Typography variant="caption">Servicios:</Typography>
									<CircularProgress size="small" color="secondary" style={{ width: 20, height: 20 }} />
									<Typography variant="caption">Monto:</Typography>
									<CircularProgress size="small" color="secondary" style={{ width: 20, height: 20 }} />
								</Fragment>
							),
							false: (
								<Fragment>
									<Typography variant="caption">Servicios: {quantityOfServices}</Typography>
									<Typography variant="caption">Monto: {applyCurrencyFormat(amount)}</Typography>
								</Fragment>
							)
						}[loading]
					}
				</div>
			</CardContent>
		</Card>
	);
};

MetricsCard.propTypes = {
	title: PropTypes.string,
	loading: PropTypes.bool.isRequired,
	quantityOfServices: PropTypes.number.isRequired,
	amount: PropTypes.number.isRequired
};

MetricsCard.defaultProps = {
	title: 'Servicios',
	loading: false,
	quantityOfServices: 0,
	amount: 0
};

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.primary.white,
		borderColor: theme.palette.primary.light,
		borderStyle: 'solid',
		borderWidth: 2,
		width: 220,
		padding: 0
	},
	content: {
		padding: '0px 8px 2px 8px !important',
	},
	title: {
		textAlign: 'center',
		height: 25
	},
	metrics: {
		display: 'flex',
		justifyContent: 'space-around'
	},
	loading: {
		display: 'flex'
	}
});

export default withStyles(styles, { withTheme: true })(MetricsCard);
