import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

import UserInRoleSelector from '../../common/UserInRoleSelector'
import { ROLE_SUPPLIERDEVELOPMENTENGINEER } from '../../../store/helpers/RolesHelper'

import { Translate } from 'react-localize-redux'

const SelectDevelopment = ({ onDevelopmentSelected, developmentInMaterial,classes}) => {
	if (developmentInMaterial) {
		return (
			<div className={classes.titleDevelopment}>
				<Typography variant='body2'>
					<strong>
						<Translate id={'priceChangeRequest.create.development.title'} />
					</strong>	
				</Typography>
				<Typography variant='body2'>{`: ${developmentInMaterial.user_name} | ${developmentInMaterial.name}`}</Typography>
			</div>
		)
	}
	return (
		<div style={{ paddingBottom: '16px' }}>
			<Typography variant='caption'>
				<Translate id={'priceChangeRequest.create.development.title'} />
			</Typography>
			<UserInRoleSelector
				role={ROLE_SUPPLIERDEVELOPMENTENGINEER}
				onUserSelected={(development) => {
					onDevelopmentSelected(development)
				}}
			/>
		</div>
	)
}
SelectDevelopment.propTypes = {
	developersList: propTypes.array,
	developerSelected: propTypes.any,
	onSelectDeveloper: propTypes.func,
}
SelectDevelopment.defaultProps = {
	developersList: [],
	developerSelected: null,
	onSelectDeveloper: () => console.warn('No [onSelectDeveloper] CallBack defined'),
}
const styles = (theme) => ({
	titleDevelopment:{
		display: 'flex',
	}
})
export default withRouter(withStyles(styles, { withTheme: true })(SelectDevelopment))
