import { fromJS } from 'immutable'

import * as rawMaterialActions from './RawMaterialActions'

/**
 * RawMaterialReducer initial state
 */
export const rawMaterialInitialState = fromJS({
	isLoadingRawMaterials: true,
	rawMaterialNonConformities: null,
	sortCriteria: {
		by: 'creation_date',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	rawMaterialCount: null,
	isSaving: false,
	isLoading: false,
	rawMaterial: null,
	isAllRawMaaterialsReports: false,
})

export default function rawMaterialReducer(state = rawMaterialInitialState, action) {
	switch (action.type) {
		case rawMaterialActions.START_LOADING_RAW_MATERIALS: {
			return state.merge({
				isLoadingRawMaterials: action.isLoading,
			})
		}
		case rawMaterialActions.SET_RAW_MATERIALS_LIST: {
			let rawMaterialsLists = fromJS(action.rawmaterials)
			return state.merge({
				rawMaterialNonConformities: rawMaterialsLists,
				isLoadingRawMaterials: false,
				isLoading: false,
				isSaving: false,
			})
		}
		case rawMaterialActions.CHANGE_RAW_MATERIALS_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case rawMaterialActions.CHANGE_RAW_MATERIALS_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case rawMaterialActions.CHANGE_RAW_MATERIALS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case rawMaterialActions.IS_LOADING_ALL_RAW_MATERIALS: {
			return state.merge({
				isAllRawMaaterialsReports: action.isAllReports,
			})
		}
		case rawMaterialActions.SET_RAW_MATERIALS_COUNT: {
			return state.merge({
				rawMaterialCount: action.count,
			})
		}
		case rawMaterialActions.IS_SAVING: {
			return state.merge({
				isSaving: action.isSaving,
			})
		}
		case rawMaterialActions.IS_LOADING: {
			return state.merge({
				isLoading: action.isLoading,
			})
		}
		case rawMaterialActions.SET_RAW_MATERIAL_NON_CONFORMITY: {
			return state.merge({
				isLoading: false,
				isSaving: false,
				rawMaterial: fromJS(action.rawMaterial),
			})
		}
		case rawMaterialActions.ADD_FILE_RAW_MATERIAL_NON_CONFORMITY: {
			let oldList = state.getIn(['rawMaterial', 'attachments']).toJS()
			let filesToAdd = action.files.filter((newFile) => {
				return !oldList.find((uploadedFile) => {
					return uploadedFile.name === newFile.name
				})
			})
			let newList = [...oldList, ...filesToAdd]
			return state.setIn(['rawMaterial', 'attachments'], fromJS(newList))
		}
		case rawMaterialActions.REMOVE_FILE_RAW_MATERIAL_NON_CONFORMITY: {
			let oldList = state.getIn(['rawMaterial', 'attachments']).toJS()
			let newList = oldList.filter((file) => {
				return file.name !== action.fileName
			})
			return state.setIn(['rawMaterial', 'attachments'], fromJS(newList))
		}
		case rawMaterialActions.ADD_EMERGENT_ACTION_TO_RAW_MATERIAL: {
			let emergentAction = fromJS(action.emergentAction)
			return state
				.updateIn(['rawMaterial', 'emergent_actions'], (actions) => actions.push(emergentAction))
				.set('isSaving', false)
		}
		case rawMaterialActions.REMOVE_EMERGENT_ACTION_TO_RAW_MATERIAL: {
			return state.updateIn(['rawMaterial', 'emergent_actions'], (allActions) => {
				let index = allActions.findIndex((item) => {
					return item.get('token') === action.actionToken
				})
				return allActions.delete(index).set('isSaving', false)
			})
		}
		case rawMaterialActions.ADD_CORRECTIVE_ACTION_RAWMATERIAL_TO_LIST: {
			let correctiveAction = fromJS(action.correctiveAction)
			return state
				.updateIn(['rawMaterial', 'corrective_actions'], (actions) => actions.push(correctiveAction))
				.set('isSaving', false)
		}
		case rawMaterialActions.UPDATE_CORRECTIVE_ACTION_RAWMATERIAL_TO_LIST: {
			let correctiveAction = action.correctiveAction
			return state
				.updateIn(['rawMaterial', 'corrective_actions'], (actions) => {
					let index = actions.findIndex((item) => {
						return item.get('token') === correctiveAction.token
					})
					return actions.setIn([index], fromJS(action.correctiveAction))
				})
				.set('isSaving', false)
		}
		case rawMaterialActions.SET_RAW_MATERIAL_QUALITY: {
			return state.merge({
				isLoading: false,
				isSaving: false,
				rawMaterial: fromJS(action.rawMaterial),
			})
		}
		default:
			return state
	}
}
