import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/**Import material UI Section*/
import { Button, FormControlLabel, Typography, withStyles, InputAdornment } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'

/** Custom components import section */
import AutoSaveTextField from '../../../common/AutoSaveTextField'

/** Redux selectors import section */
import StatusSwitch from '../../components/StatusSwitch'
import {
	INVOICING_ERROR,
	REPARE_RFC,
	IVA_PERCENTAGE,
	RESICO_PERCENTAGE,
	MAX_NUMBER_OF_SERVICES_IN_PAYMENT_REQUEST,
	PAYMENT_REQUESTS_CREATION_DAYS,
} from '../../../../store/helpers/AccreditedWorkshopsHelper'

const GeneralSettings = (props) => {
	const {
		classes,
		paymentRequestCreation,
		paymentRequestCreationDays,
		paymentRequestRfc,
		paymentRequestTaxDifference,
		paymentRequestIva,
		paymentRequestResico,
		paymentRequestMaxNumberOfServicesInPaymentRequest,
	} = props

	const ivaPercentage = paymentRequestIva.value && parseFloat(paymentRequestIva.value).toFixed(2)
	const resicoPercentage = paymentRequestResico.value && parseFloat(paymentRequestResico.value).toFixed(2)
	return (
		<div>
			<div className={classes.alertInfo}>
				<InfoIcon size='small' /> <Translate id='accreditedWorkshops.setting.optionCreationInfo' />
			</div>
			<div className={classes.textGroup}>
				<div className={classes.text}>
					<Typography>
						<Translate id='accreditedWorkshops.setting.optionCreationStatus' />{' '}
						{paymentRequestCreation.enabled ? 'Habilitada' : 'Deshabilitada'}
					</Typography>
				</div>
				<div className={classes.inputGroup}>
					<FormControlLabel
						className={classes.switchGroup}
						control={
							<StatusSwitch
								checked={paymentRequestCreation.enabled}
								onChange={paymentRequestCreation.onChange}
								color='primary'
							/>
						}
						label={paymentRequestCreation.enabled ? 'Deshabilitar' : 'Habilitar'}
					/>
				</div>
			</div>
			<div className={classes.textGroup}>
				<div className={classes.text}>
					<Typography>
						<Translate id='accreditedWorkshops.setting.optionCreationDays' />{' '}
						{paymentRequestCreationDays.days} Dias antes de fin de mes
					</Typography>
				</div>
				<div className={classes.inputGroup}>
					<AutoSaveTextField
						id={PAYMENT_REQUESTS_CREATION_DAYS}
						type='number'
						value={paymentRequestCreationDays.days}
						onChange={paymentRequestCreationDays.onChange}
					/>
				</div>
			</div>
			<div className={classes.alertInfo}>
				<InfoIcon /> <Translate id='accreditedWorkshops.setting.optionRfcInfo' />
			</div>
			<div className={classes.textGroup}>
				<div className={classes.text}>
					<Typography>
						<Translate id='accreditedWorkshops.setting.optionRfcData' /> {paymentRequestRfc.value}
					</Typography>
				</div>
				<div className={classes.inputGroup}>
					<AutoSaveTextField
						id={REPARE_RFC}
						value={paymentRequestRfc.value}
						onChange={paymentRequestRfc.onChange}
						disabled
					/>
				</div>
			</div>
			<div className={classes.alertInfo}>
				<InfoIcon /> <Translate id='accreditedWorkshops.setting.optionTaxDifferenceInfo' />
			</div>
			<div className={classes.textGroup}>
				<div className={classes.text}>
					<Typography>
						<Translate id='accreditedWorkshops.setting.optionTaxDifferenceData' /> ${' '}
						{paymentRequestTaxDifference.value}
					</Typography>
				</div>
				<div className={classes.inputGroup}>
					<AutoSaveTextField
						id={INVOICING_ERROR}
						value={paymentRequestTaxDifference.value}
						onChange={paymentRequestTaxDifference.onChange}
						InputProps={{
							startAdornment: <InputAdornment position='start'>$</InputAdornment>,
						}}
					/>
				</div>
			</div>
			<div className={classes.alertInfo}>
				<InfoIcon /> <Translate id='accreditedWorkshops.setting.maxNumberOfServicesInPaymentRequestInfo' />
			</div>
			<div className={classes.textGroup}>
				<div className={classes.text}>
					<Typography>
						<Translate id='accreditedWorkshops.setting.optionMaxNumberOfServicesInPaymentRequestData' />
						{paymentRequestMaxNumberOfServicesInPaymentRequest.value}
					</Typography>
				</div>
				<div className={classes.inputGroup}>
					<AutoSaveTextField
						id={MAX_NUMBER_OF_SERVICES_IN_PAYMENT_REQUEST}
						value={paymentRequestMaxNumberOfServicesInPaymentRequest.value}
						onChange={paymentRequestMaxNumberOfServicesInPaymentRequest.onChange}
					/>
				</div>
			</div>
			<div className={classes.alertInfo}>
				<InfoIcon /> <Translate id='accreditedWorkshops.setting.ivaPercentagesInfo' />
			</div>
			<div className={classes.textGroup}>
				<div className={classes.text}>
					<Typography>
						<Translate id='accreditedWorkshops.setting.ivaPercentagesData' />
						{ivaPercentage} %
					</Typography>
				</div>
				<div className={classes.inputGroup}>
					<AutoSaveTextField
						id={IVA_PERCENTAGE}
						value={ivaPercentage}
						onChange={paymentRequestIva.onChange}
						InputProps={{
							endAdornment: <InputAdornment position='end'>%</InputAdornment>,
						}}
					/>
				</div>
				<div>
					<Button variant='contained' color='primary' onClick={paymentRequestIva.onEditButtonClick}>
						<EditIcon className={classes.leftIcon} />
						<Translate id='common.edit' />
					</Button>
				</div>
			</div>
			<div className={classes.alertInfo}>
				<InfoIcon /> <Translate id='accreditedWorkshops.setting.resicoPercentagesInfo' />
			</div>
			<div className={classes.textGroup}>
				<div className={classes.text}>
					<Typography>
						<Translate id='accreditedWorkshops.setting.resicoPercentagesData' />
						{resicoPercentage} %
					</Typography>
				</div>
				<div className={classes.inputGroup}>
					<AutoSaveTextField
						id={RESICO_PERCENTAGE}
						type='number'
						value={resicoPercentage}
						onChange={paymentRequestResico.onChange}
						InputProps={{
							endAdornment: <InputAdornment position='end'>%</InputAdornment>,
						}}
					/>
				</div>
				<div>
					<Button variant='contained' color='primary' onClick={paymentRequestResico.onEditButtonClick}>
						<EditIcon className={classes.leftIcon} />
						<Translate id='common.edit' />
					</Button>
				</div>
			</div>{' '}
		</div>
	)
}

GeneralSettings.propTypes = {
	paymentRequestCreation: PropTypes.shape({
		enabled: PropTypes.bool.isRequired,
		onChange: PropTypes.func.isRequired,
	}),
	paymentRequestCreationDays: PropTypes.shape({
		days: PropTypes.number.isRequired,
		onChange: PropTypes.func.isRequired,
	}),
	paymentRequestRfc: PropTypes.shape({
		value: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
	}),
	paymentRequestTaxDifference: PropTypes.shape({
		value: PropTypes.number.isRequired,
		onChange: PropTypes.func.isRequired,
	}),
	paymentRequestIva: PropTypes.shape({
		value: PropTypes.number.isRequired,
		onChange: PropTypes.func.isRequired,
		onEditButtonClick: PropTypes.func.isRequired,
	}),
	paymentRequestResico: PropTypes.shape({
		value: PropTypes.number.isRequired,
		onChange: PropTypes.func.isRequired,
		onEditButtonClick: PropTypes.func.isRequired,
	}),
}

GeneralSettings.defaultProps = {
	paymentRequestCreation: {
		enabled: false,
		onChange: () => console.warn('[paymentRequestCreation.onChange] not defined!'),
	},
	paymentRequestCreationDays: {
		days: 0,
		onChange: () => console.warn('[paymentRequestCreationDays.onChange] not defined!'),
	},
	paymentRequestRfc: {
		value: '',
		onChange: () => console.warn('[paymentRequestRfc.onChange] not defined!'),
	},
	paymentRequestTaxDifference: {
		value: 0,
		onChange: () => console.warn('[paymentRequestTaxDifference.onChange] not defined!'),
	},
	paymentRequestIva: {
		value: 0,
		onChange: () => console.warn('[paymentRequestIva.onChange] not defined!'),
		onEditButtonClick: () => console.warn('[paymentRequestIva.onEditButtonClick] not defined!'),
	},
	paymentRequestResico: {
		value: 0,
		onChange: () => console.warn('[paymentRequestResico.onChange] not defined!'),
		onEditButtonClick: () => console.warn('[paymentRequestResico.onEditButtonClick] not defined!'),
	},
}

const styles = () => ({
	alertInfo: {
		margin: '10px 0px',
		padding: '10px 7px 10px 40px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '10px center',
		fontSize: '13px',
		textAlign: 'left',
		width: 'auto',
		color: '#00529B',
		backgroundColor: '#BDE5F8',
	},
	textGroup: {
		padding: 8,
		display: 'flex',
		justifyContent: 'space-between',
	},
	switchGroup: {
		marginBottom: '0px',
		height: '38px',
	},
	inputGroup: {
		width: '30%',
		textAlign: 'center',
	},
	text: {
		marginTop: '.5%',
		marginBottom: '.5%',
		marginRight: '5%',
		width: '70%',
	},
	options: {
		marginTop: '6%',
	},
})

export default withStyles(styles, { withTheme: true })(GeneralSettings)
