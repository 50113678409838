import React, { Component } from 'react';
import autoBind from 'auto-bind'

/** Translations imports section */
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import EmployeesIcon from '@material-ui/icons/People';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import EmployeesSelector from './EmployeesSelector';
import { Grid, ListItemAvatar, Divider } from '@material-ui/core';

import yourlogohere from '../../../resources/images/yourlogohere.png';

/**
 * Component to employees in office
 */
class EmployeesInListCard extends Component {

    /**
   * Create an instance of Internal user
   * @param {*} props 
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            employees: this.props.employees ? this.props.employees : [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.employees !== this.props.employees) {
            this.setState({
                employees: this.props.employees
            })
        }
    }

    /**
     * Get the employees list
     *
     * @returns
     * @memberof EmployeesInListCard
     */
    getEmployeesInList() {
        if (this.state.employees) {
            return (
                <List>
                    {this.state.employees.map((employee) => {
                        let urlImageProfile = `${process.env.REACT_APP_SIP_URL_LOGOS_ADMINISTRATION}/${employee.image_employed}`;
                        return (<div key={employee.name}>
                            <ListItem divider>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={employee.name}
                                        src={employee.image_employed ? urlImageProfile : yourlogohere}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="caption" gutterBottom>{employee.name}</Typography>} />
                               {this.props.isActive && <ListItemSecondaryAction>
                                    <IconButton id={employee.token}
                                        onClick={(event) => { this.props.removeEmployee(event.currentTarget.id) }}
                                    >
                                        <DeleteIcon color='error' disabled={this.props.isSaving} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                               }
                            </ListItem>
                        </div>
                        )
                    })}
                </List>
            )
        } else {
            return null;
        }
    }



    render() {
        const { classes } = this.props;

        return (
            <Card style={{ height: '136vh' }} >
                <CardHeader
                    avatar={
                        <Avatar className={classes.userAvatar}>
                            <EmployeesIcon />
                        </Avatar>
                    }
                    title={<Translate id={this.props.title} />}
                />
                <Divider/>
                <CardContent>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {this.props.isActive &&
                                <EmployeesSelector
                                    className={classes.textField}
                                    onEmployeeSelected={(employee) => { this.props.updateEmployeesList(employee) }}
                                />}
                            <List style={{   maxHeight: `calc(100vh - ${55}px)`,
                                        minHeight: `calc(100vh - ${55}px)`,
                                        overflowY: "auto",}}>
                                {this.getEmployeesInList()}
                                {this.state.employees.length === 0 &&
                                    <div>
                                        <ListItem selected divider>
                                            <ListItemText primary={
                                                <Typography variant="subtitle2" component="h5" align="center">
                                                    <Translate id="contractorsModule.noEmployeesInPayment" />
                                                </Typography>
                                            } />
                                        </ListItem>
                                    </div>
                                }
                            </List>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

export default EmployeesInListCard;