/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
/**Import actions sections */
/** Material UI import section */
import { DialogContent, Typography, withStyles } from '@material-ui/core'

/** Custom components import section */
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import Toaster from '../../common/Toaster'
import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER } from '../../../store/helpers/StatusColorConstants'
/** Resources import section */
/**
 * @param {*} props
 * @returns
 */
const ConfirmDeletePhoneDirectoryDialog = (props) => {
	const { classes } = props

	const { isSaving, onDeletePhoneDirectory } = props
	const [toasterOptions, setToasterOptions] = useState({ open: false, variant: '', message: null })

	return (
		<>
			<Dialog
				open={props.open}
				onClose={props.onClose}
				isSaving={isSaving}
				maxWidth={'500px'}
				header={
					<Typography color='primary' align='center' variant='h6'>
						<Translate id='phoneDirectories.create.deletedPhoneDirectoryTitle' />
					</Typography>
				}
				actions={
					<DialogActions
						view={SHOW_EDIT_VIEW}
						classes={classes}
						onCancel={props.onClose}
						onClose={props.onClose}
						onSaveItem={onDeletePhoneDirectory}
						isSaving={props.isSaving}
						canBeSaved={true}
					/>
				}
			>
				<DialogContent>{<Translate id='phoneDirectories.create.deletedPhoneDirectoryMessage' />}</DialogContent>
			</Dialog>

			{/** Toaster */}
			{toasterOptions.open && (
				<Toaster toasterOptions={toasterOptions} onClose={() => setToasterOptions({ open: false })} />
			)}
		</>
	)
}
const styles = (theme) => ({
	btnSaved: {
		backgroundColor: COLOR_STATUS_SUCCESS,
		color: 'white',
		'&:hover': {
			backgroundColor: COLOR_STATUS_SUCCESS,
			color: 'white',
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_STATUS_SUCCESS,
			color: 'white',
			opacity: 0.5,
		},
	},
	btnCancel: {
		marginRight: '10px',
		backgroundColor: COLOR_STATUS_DANGER,
		color: 'white',
		'&:hover': {
			backgroundColor: COLOR_STATUS_DANGER,
			color: 'white',
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_STATUS_DANGER,
			color: 'white',
			opacity: 0.5,
		},
	},
	btnConfirm: {
		backgroundColor: COLOR_STATUS_SUCCESS,
		color: 'white',
		'&:hover': {
			backgroundColor: COLOR_STATUS_SUCCESS,
			color: 'white',
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_STATUS_SUCCESS,
			color: 'white',
			opacity: 0.5,
		},
	},
	btnWithIcon: {
		backgroundColor: `white" !important`,
	},
})

ConfirmDeletePhoneDirectoryDialog.propTypes = {
	/**Confirm the operation to be performed*/
	onClickButtonConfirm: propTypes.func,
	/**Determinate if saving an operation */
	isSaving: propTypes.bool,
	/**Determinate if is visible the textbox commnets */
	isVisibleComments: propTypes.bool,
	/**Determinate if the commnets are required*/
	commentRequired: propTypes.bool,
	/**Determinate if dialog password is open or close */
	open: propTypes.bool.isRequired,
	/**Determinate if close the dialog  */
	onClose: propTypes.func.isRequired,
	/**See mensaje of operation alert*/
	alertMesage: propTypes.string,
	/**See mensaje of operation*/
	message2: propTypes.oneOfType([propTypes.string, propTypes.node]),
}

ConfirmDeletePhoneDirectoryDialog.defaultProps = {
	isSaving: false,
	isVisibleComments: false,
	commentRequired: false,
	alertMesage: '',
	message2: '',
	onClickButtonConfirm: () => console.warn('Callback [onClickButtonConfirm] no defined'),
}

export default withRouter(withStyles(styles, { withTheme: true })(ConfirmDeletePhoneDirectoryDialog))
