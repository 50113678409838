
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import autoBind from 'auto-bind'

/** Material-UI imports section */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";

/** Import section component */
import Toaster from '../../common/Toaster';
import DialogActions from '@material-ui/core/DialogActions';
import PreparedBySelector from '../../common/PreparedBySelector';
import UserSelector from '../../common/UserSelector';

/**
 * Component Form Rwrokw
 */
class DevolutionForm extends Component {

    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            preparedBySelected: null,
            comments: "",
            planningSelected: null,
            developmentSelected: null,
        }
    }

     /**
     * On seleted prepare by
     */
    onPrepareBySelected(prepareBy){
        this.setState({preparedBySelected: prepareBy});
    }

    /**
     * On seletecd planning Engineer
     */
    onPlanningSelected(planning){
        this.setState({planningSelected: planning});
    }

    /**
     * On seletecd supplier Development Engineer
     */
    onDevelopmentSelected(development){
        this.setState({developmentSelected: development});
    }

     /**
     * Update the property of the devolution
     *
     * @param {*} user
     * @param {*} value
     * @memberof View
     */
    updateProperty(comments) {
        this.setState({
            comments: comments
        })
    }

    /**
     * Can be add item in raw material
     */
    canBeSaved(){
        if(this.state.preparedBySelected === null)
            return false;
        if(this.state.planningSelected === null)
            return false;
        if(this.state.developmentSelected === null)
            return false;
        else
            return true;
    }

    /**
     * Metho to can be created devolution
     */
    canBeCreateDevolution(){
        if(this.props.canBeCreate){
            this.props.canBeCreate(this.state.preparedBySelected, this.state.planningSelected, this.state.developmentSelected, this.state.comments)
        }   
    }

    /**
     * Render show 
     */
    render(){
        const { classes } = this.props;
        let rework = this.props.rework;
        return(
            <Grid container spacing={24} style={{ paddingLeft: '80px', paddingRight: "100px", }}>
                <Grid item xs={6}>

                    <div className={classes.textField} ><br/>
                        <Typography variant="body2" gutterBottom><Translate id="common.partNumber"/>
                        {": "}{rework.full_part_description}
                        </Typography>
                    </div>

                    <div className={classes.textField} ><br/>
                        <Typography variant="body2" gutterBottom><Translate id="rawmaterialnonconformity.planningEngineer"/> *</Typography>
                        <UserSelector
                            className={classes.textField}
                            onUserSelected = {(planning) => {this.onPlanningSelected(planning)}}
                            />
                    </div>

                    <div className={classes.textField} ><br/>
                        <Typography variant="body2" gutterBottom><Translate id="rawmaterialnonconformity.supplierDevelopmentEngineer"/> *</Typography>
                        <UserSelector
                            className={classes.textField}
                            onUserSelected = {(development) => {this.onDevelopmentSelected(development)}}
                        />
                    </div>

                </Grid>

                <Grid item xs={6}>

                    <div className={classes.textField} ><br/>
                        <Typography variant="body2" gutterBottom><Translate id="common.supplier"/>
                        {": "}{rework.full_supplier}
                        </Typography>
                    </div>

                    <div className={classes.textField} ><br/>
                        <Typography variant="body2" gutterBottom><Translate id="common.preparedBy"/> *</Typography>
                        <PreparedBySelector
                            className={classes.textField}
                            onUsersQualitySelected = {(prepareBy) => {this.onPrepareBySelected(prepareBy)}}
                        /><br/>
                    </div>

                    <TextField fullWidth
                        id="outlined-multiline-flexible"
                        label={<Translate id="common.comments"/>}
                        multiline
                        className={classes.textField} 
                        rows="4"
                        value={this.state.comments}
                        onChange={(event) => {this.updateProperty(event.target.value)}}
                        margin="normal"
                        variant="outlined"
                    /><br/><br/>
                </Grid>
                
                <Grid container >
                    <DialogActions>
                        <Button 
                            type="button" 
                            color="primary" 
                            onClick={this.canBeCreateDevolution}
                            disabled = {!this.canBeSaved() || this.props.isSaving}>
                            {<Translate id="common.save"/>}
                        </Button>
                        <Button onClick={() => {this.props.history.push(`/reworks/${rework.token}`)}} color="secondary">
                            {<Translate id="common.cancel"/>}
                        </Button>
                    </DialogActions>
                </Grid> 

                <Toaster 
                    message = {this.state.toasterMessage}
                    open = {this.state.showToaster}
                    variant = {this.state.toasterVariant}
                    onClose = {() => {this.setState({ showToaster:false})}}
                /> 

            </Grid>
        )
    }
}

const viewStyles = theme => ({
    tetxtFieldFull:{
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: "150px"
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 450,
    },
    textAreaField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 440,
        height: 80,
      },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    footerForm:{
        display: "flex",
        paddingRight: "60px",
    },
    formControl:{margin: theme.spacing.unit},
    toolbar:{},
    searchInput:{ },
    button:{ },
    actionsToolbar:{ },
    showingUsersCounter:{ },
    grow:{},
    supplierBadge:{}
});

export default withRouter(withStyles(viewStyles, { withTheme:true })(DevolutionForm));