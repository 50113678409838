import { getAuthorizedRequestConfigurationUser } from "../helpers/SessionHelper"
import axios from 'axios'

export const START_LOADING_RELEASE_CALENDARS = "START_LOADING_RELEASE_CALENDARS";
export const SET_RELEASE_CALENDARS_LIST = "SET_RELEASE_CALENDARS_LIST";
export const CHANGE_RELEASE_CALENDARS_SORT_CRITERIA = "CHANGE_RELEASE_CALENDARS_SORT_CRITERIA";
export const CHANGE_RELEASE_CALENDARS_QUERY = "CHANGE_RELEASE_CALENDARS_QUERY";
export const IS_LOADING = "IS_LOADING";
export const IS_SAVING = "IS_SAVING";
export const SET_RELEASE_CALENDAR_LOCK = "SET_RELEASE_CALENDAR_LOCK";
/**
 * Action start loading release calendars list 
 * @param {*} isLoading 
 */
 function isLoadingReleaseCalendars(isLoading) {
    return {
        type: START_LOADING_RELEASE_CALENDARS,
        isLoading: isLoading
    }
}

/**
 * Action to set release calendars list 
 * @param {*} calendarsLocks 
 */
 function setReleaseCalendarsList(calendarsLocks) {
    return {
        type: SET_RELEASE_CALENDARS_LIST,
        calendarsLocks: calendarsLocks
    }
}

/**
 * Action to is loaidng  release calendar lock
 * @param {*} isLoading 
 */
 function isLoading(isLoading) {
    return {
        type: IS_LOADING,
        isLoading: isLoading
    }
}

/**
 * Action to set  release calendar lock
 * @param {*} rawMaterial 
 */
 function setReleaseCalenarLock(calendarLock) {
    return {
        type: SET_RELEASE_CALENDAR_LOCK,
        calendarLock: calendarLock
    }
}

/**
 * Action to is saving release calendar lock
 * @param {*} isSaving 
 */
 function isSaving(isSaving) {
    return {
        type: IS_SAVING,
        isSaving: isSaving
    }
}

export function loadReleaseCanledarsLockFromBackEnd(query, orderBy, sortAscending) {
    return function (dispatch, getState) {
       dispatch(isLoadingReleaseCalendars(true));
        
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}ReleaseCalendarLock/search`;
        let data = {
			CompanyCode: selectedCompany.get("company_code"),
			Sort: {
				By: orderBy,
				Direction: sortAscending ? 'asc' : 'desc',
			},
			SortAscending: sortAscending,
			Query:query,
		}
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setReleaseCalendarsList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingReleaseCalendars(false));
                console.error("Error getting the release calendars lock by company list", error.response)
            })
    }
}

export function loadReleaseCalendarLockFromBackEnd(id) {
    return function (dispatch, getState) {
        dispatch(isLoading(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}ReleaseCalendarLock/${id}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setReleaseCalenarLock(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load release calendar lock", error.response)
                dispatch(isLoading(false))
                return Promise.reject();
            })
    }
}

export function removeReleaseCalendarLock(calendarLockId) {
    return (dispatch, getState) => {
        dispatch(isSaving(true));

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}ReleaseCalendarLock/${calendarLockId}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                }
                else{
                    dispatch(isSaving(false));
                    return Promise.reject(serverResponse);
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error removing a release calendar lock", error.response)
                return Promise.reject(error.response);
            })
    }
}

export function createReleaseCalendarLockFromBackEnd(calendarLock) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let data = {
            //CompanyIdentifier: selectedCompany.get("get_company_identifier"),
            CompanyCode: selectedCompany.get('company_code'),
            Message: calendarLock ? calendarLock.message : "",
            NumberRamps: calendarLock ? calendarLock.number_ramps : "",
            IsAllDays: calendarLock ? calendarLock.all_days : "",
            ByDate: calendarLock ? calendarLock.by_date : "",
            ByPeriod: calendarLock ? calendarLock.by_period : "",
            IsMonday: calendarLock ? calendarLock.is_monday : "",
            IsTuesday: calendarLock ? calendarLock.is_tuesday : "",
            IsWednesday: calendarLock ? calendarLock.is_wednesday : "",
            IsThursday: calendarLock ? calendarLock.is_thursday : "",
            IsFriday: calendarLock ? calendarLock.is_friday : "",
            IsSaturday: calendarLock ? calendarLock.is_saturday : "",
            TimeHourLockStart: calendarLock ? calendarLock.time_hour_lock_start ? calendarLock.time_hour_lock_start.format("DD/MM/YYYY") : "" : "",
            TimeHourLockEnd: calendarLock ? calendarLock.time_hour_lock_end ? calendarLock.time_hour_lock_end.format("DD/MM/YYYY") : "": "",
            TimeHourStart: calendarLock ? calendarLock.time_hour_start : "",
            TimeHourEnd: calendarLock ? calendarLock.time_hour_end : "",
            CreatedBy: user.profile.name,
        }
        let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}ReleaseCalendarLock/create`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error adding a release calendar lock from company", error.response)
                return Promise.reject(error.response);
            })
    }
}


