/** React imports section */
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'

/** Material UI imports section */
import { Button, withStyles, Typography, Divider, Checkbox } from '@material-ui/core'
import ProspectSupplierIncon from '@material-ui/icons/LocalShipping'

/** common imports section */
import Toaster from '../../../common/Toaster'
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'

import { addCarries } from '../../../../store/transportTender/transportTenderAction'
import { getIsLoadingSavingSelector } from '../../../../store/transportTender/transportTenderSelector'

const AddCarrierPanel = (props) => {
	const { classes, onClose, transportTenderId, carrierBidding, catalogCarries } = props

	const dispatch = useDispatch()
	const isloading = useSelector((state) => getIsLoadingSavingSelector(state))

	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: '',
		toasterVariant: '',
	})
	const [codes, setCodes] = useState([])

	useEffect(() => {
		if (carrierBidding) setCodes(carrierBidding.map((x) => x.Code))
	}, [carrierBidding])

	useEffect(() => {
		setCodes(carrierBidding)
	}, [carrierBidding])

	const onSubmit = () => {
		dispatch(addCarries(codes, transportTenderId))
			.then(() => {
				''
			})
			.catch((err) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'Error al guardar el reparto',
					toasterVariant: 'error',
				})
			})
			.finally(() => {
				onClose()
			})
	}
	const canBeSaved = () => {}

	const addCode = (carrier, event) => {
		if (event.target.checked) setCodes((prevArreglo) => [...prevArreglo, carrier])
		else {
			let array = codes.filter((x) => x.Code !== carrier.Code)
			setCodes(array)
		}
	}
	return (
		<LateralPanel onClose={onClose} classes={classes}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id='carrierTenders.carriers.carriersTitle' />}
				onCloseButtonClick={onClose}
				icon={<ProspectSupplierIncon color='secondary' />}
			/>
			<div className={classes.bodyContainer}>
				<Typography gutterBottom variant='body2' component='p'>
					<Translate id='carrierTenders.carriers.selectCarrier' />
				</Typography>
				{catalogCarries.map((x) => {
					return (
						<div key={x.code}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Checkbox
									onChange={(event) => addCode(x, event)}
									color='primary'
									checked={codes.find((y) => y.Code === x.Code) ? true : false}
									value={x.Code}
								/>
								<Typography gutterBottom variant='body2' component='p'>
									{x.Code} | {x.Name}
								</Typography>
							</div>
							<Divider />
						</div>
					)
				})}
			</div>
			<Button
				data-tut='reactour-save'
				data-cy='btn-save'
				type='button'
				color='primary'
				variant='outlined'
				className={classes.btnCreate}
				onClick={onSubmit}
				disabled={canBeSaved() || isloading}
			>
				{<Translate id='carrierTenders.carriers.addCarrier' />}
			</Button>
			<Toaster
				message={<Translate id={'carrierTenders.error.errorAddingRequest'} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	bodyContainer: {
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
	},
	titleSelect: {
		marginTop: '7px',
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
})
export default withStyles(styles, { withTheme: true })(AddCarrierPanel)
