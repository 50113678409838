import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import moment from 'moment'
import { Link } from 'react-router-dom';

/** Import component section */
import LoadingPage from '../../common/LoadingPage'
import Select from 'react-select'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import DateTimePicker from '../../common/DateTimePicker/DatePicker'
import DataTable from '../../common/DataTable';
import Dialog from '../../common/Dialog';
import { COMPANY_PTM, COMPANY_IMBERA, COMPANY_METALICOS, COMPANY_IMBERA_WARRANTY } from '../../../store/helpers/ResourcesHelper';
import DonwloadReportDialog from '../../common/DonwloadReportDialog';
/** Material UI imports section */
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PurchaseOrdersIcon from '@material-ui/icons/ShoppingCartTwoTone';

/** Actions and helpers imports section */
import { getPurchaseOrdersFromSAP, getSpecificationPlaneByPurchaseOrder,
    donwlodPurchaseOrdersFromSAP, getPurchaseOrdersFromSAPByPeriod } from '../../../store/suppliers/SupplierActions'
import { showPlotsFromPartNumber } from '../../../store/helpers/ShowPlotToPartNumber';
import { loadSupplier } from '../../../store/suppliers/SupplierActions';

/**
 * Purchase Orders list 
 *
 * @class Purchase Orders list
 * @extends {Component}
 */
class PurchaseOrders extends Component {

    /**
     * Create an instance of Purchase Orders list
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            startDate: moment(),
            endDate: moment(),
            sortCriteria: {
                by: "status",
                ascending: false
            },
            isSearching: false,
            showspecPlano: false,
            specPlanos: null,
            itemSelected: null,
            dateType: "A",            
            showPeriod:true,
            period: 0,
            showDateRangeDialog: false
        }        
    }

    /**
  * componentDidMount
  */
    componentDidMount() {
        let supplierToken = this.props.match.params.token;
        this.props.loadSupplier(supplierToken);
        // Is PTM,  Buscar ordenes de compra cerradas por fechas
        if(this.props.selectedCompany.get_company_identifier === COMPANY_PTM){
            this.setState({dateType: "C"})
        }
    }

    /**
     * On change range
     *
     * @param {*} startDate
     * @param {*} endDate
     * @memberof PurchaseOrders
     */
    onChangeRanges(startDate, endDate) {
        this.setState({
            startDate,
            endDate,
        })
    }

    onChangeRangeStart(startDate){
        this.setState({
            startDate
        })
    }

    onChangeRangeEnd(endDate){
        this.setState({
            endDate
        })
    }
    
    /**
     * On change sort criteria
     *
     * @param {*} sortBy
     * @param {*} sortAscending
     * @memberof Index
     */
    onChangeSortCriteria(sortBy, sortAscending) {
        let sortCriteria = {
            by: sortBy,
            ascending: sortAscending
        }
        this.setState({
            sortCriteria
        })
    }

    /**
     * Action to change invoice type
     * @param {*} typeInvoice 
     */
    onChangeDateType(option) {     
        this.setState({
            dateType: option.value,
            showPeriod: option.value === "C" ? false: true,
            startDate:moment(),
            endDate:moment()
        });       
    }

    onFirstPageClick() {
        let lapse = 0;
        this.setState({ period: lapse });
        this.onSearchClick();
    }

    onPreviousPageClick() {
        let lapse = this.state.period + 1;
        this.setState({ period: lapse });
        this.onSearchClick();
    }

    onNextPageClick() {
        let lapse = this.state.period - 1;
        this.setState({ period: lapse });
        this.onSearchClick();
    }

    /**
     * On search PurchaseOrders click
     *
     * @memberof PurchaseOrders
     */
    onSearchClick() { 
        if (this.props.supplier) {
            if(this.state.dateType==="A") /* Buscar ordenes de compra abiertas por bloques de tiempo */
            {
                let supplierCode = this.props.supplier.code;                
                this.props.getPurchaseOrdersByPeriod(supplierCode, this.state.dateType, this.state.period);
            }
            else /* Buscar ordenes de compra cerradas por fechas */
            {
                let supplierCode = this.props.supplier.code;
                let startDate = this.state.startDate.format("DD/MM/YYYY");
                let endDate = this.state.endDate.format("DD/MM/YYYY");
                this.props.getPurchaseOrders(supplierCode, startDate, endDate, this.state.dateType);
            }            
        }
    }

    getPurchaseOrderByDateRange(startDate, endDate){
        if(this.props.supplier){
            let supplierCode = this.props.supplier.code;
            this.setState({
                startDate: startDate,
                endDate: endDate
            })
            return this.props.getPurchaseOrders(supplierCode, startDate, endDate, this.state.dateType, this.props.selectedCompany.company_code)
                .then((_)=>{
                    this.handleOpenCloseModal()
                })
        }
    }

    getPurchaseOrderByPeriodEightMonths(){
        if(this.state.dateType==="A"){
            let supplierCode = this.props.supplier.code;
            this.setState({
                startDate: moment().subtract(8, 'months').format("DD/MM/YYYY"),
                endDate:moment().format("DD/MM/YYYY")
            })
            this.props.getPurchaseOrders(supplierCode, moment().subtract(8, 'months').format("DD/MM/YYYY"),moment().format("DD/MM/YYYY"), this.state.dateType, this.props.selectedCompany.company_code)
        }
    }


     /**
     * On donwload click
     */
    onDonwlodClick(){
        if (this.props.supplier) {
            let supplierCode = this.props.supplier.code;
            let startDate = this.state.startDate.format("DD/MM/YYYY");
            let endDate = this.state.endDate.format("DD/MM/YYYY");
            this.props.donwlodPurchaseOrders(supplierCode, startDate, endDate, this.state.dateType);
        }
    }

    onDonwlodImberaBtnClicClick(){
        if (this.props.supplier) {
            let supplierCode = this.props.supplier.code;
            let startDate = this.state.startDate;
            let endDate = this.state.endDate;
            this.props.donwlodPurchaseOrders(supplierCode, startDate, endDate, this.state.dateType);
        }
    }

    /**
     * Dialog to get specification plane 
     * @param {*} item 
     */
    onViewSpecificationPlane(item) {
        this.props.getSpecificationPlaneByPurchaseOrder(item.specification_plane, item.revision);
        this.setState({
            showspecPlano: true,
            itemSelected: item
        })
    }

    /**
   * On render cell item custom
   * @param {*} dataSource 
   * @param {*} item 
   */
    onRenderCellItem(dataSource, item) {
        if (dataSource === "amount") {
            return `${item.amount} ${item.currency}`
        }
        // if (dataSource === "status") {
        //     if (item.status) {
        //         return (<Translate id="queries.invoices.paid"/>)
        //     }
        //     else
        //         return (<Translate id="queries.invoices.isPaid"/>)
        // }
        // if (dataSource === "method_payment") {
        //     if (item.method_payment === "Transfer") {
        //         return (<Translate id="queries.invoices.transfer"/>)
        //     }
        //     else
        //         return (<Translate id="queries.invoices.factoring"/>)
        // }
        if (dataSource === "specification_plane") {
            return (<Link onClick={() => { this.onViewSpecificationPlane(item) }}>{item.specification_plane}</Link>)
        }
    }

    /**
     * On Render aditional row data
     *
     * @param {*} dataSource
     * @param {*} item
     * @returns
     * @memberof PurchaseOrders
     */
    onRenderCellAditionalRowItem(dataSource, item) {
        if (dataSource === 'purchase_orders_items' && item[dataSource]) {
            return (
                <React.Fragment>
                    <DataTable
                        data={item[dataSource]}
                        configuration={TableConfigurationPurchaseOrder}
                    />
                </React.Fragment>
            )
        }
    }


    /**
     * Function from open spec planos
     */
    getSppectList() {
        if (this.props.specPlanos) {
            return (
                <List>
                    {this.props.specPlanos.map((spect) => {
                        return (
                            <ListItem key={spect}>
                                <ListItemText
                                    secondary={
                                        <React.Fragment>
                                            {<Link onClick={() => showPlotsFromPartNumber(spect, this.props.selectedCompany.token)} to="#">{spect} </Link>}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        )
                    })}
                </List>
            )
        }
        else {
            return (<LoadingPage />)
        }
    }

    /**
     * Get card content
     *
     * @returns
     * @memberof PurchaseOrders
     */
    getCardContent() {
        const { sortCriteria } = this.state;
        if (this.props.purchaseOrders) {
            return (
                <DataTable
                    data={this.props.purchaseOrders}
                    configuration={TableConfiguration}
                    onRenderCellItem={this.onRenderCellItem}
                    mode={"clientSide"}
                    sortBy={sortCriteria.by}
                    sortAscending={sortCriteria.ascending}
                    onChangeSortCriteria={this.onChangeSortCriteria}
                    showFilters
                    collapsableChild
                    aditionalRows={AditionalRows}
                    onRenderCellAditionalRowItem={this.onRenderCellAditionalRowItem}
                    widthFilters={120}
                />
            )
        } else if (this.props.isLoadingPurchaseOrders) {
            return (
                <LoadingPage />
            )
        }
        else {
            if (this.props.selectedCompany.get_company_identifier === COMPANY_PTM) {
                return(
                    <div className="alert alert-info" role="alert">
                        <h6 className="alert-heading"><i className="fab fa-searchengin"></i><Translate id="queries.purchaseOrders.searchPurchaseOrdersTitle"/></h6>
                        <p><Translate id="queries.purchaseOrders.searchPurchaseOrdersMessagePTM"/> </p>
                    </div>
                )                
            }
            else
            {
                return (
                    <div className="alert alert-info" role="alert">
                        <h6 className="alert-heading"><i className="fab fa-searchengin"></i><Translate id="queries.purchaseOrders.searchPurchaseOrdersTitle"/></h6>
                        <p><Translate id="queries.purchaseOrders.searchPurchaseOrdersMessage"/> </p>
                    </div>
                )
            }
        }
    }

    handleOpenCloseModal() {
        this.setState({
            showDateRangeDialog: !this.state.showDateRangeDialog
        })
    }



    /**
     * Get buttons content
     *
     * @returns
     * @memberof PurchaseOrders
     */
    getButtonsContent() {
        const { classes } = this.props;   
        if (this.props.selectedCompany.company_code === COMPANY_PTM) {     
            return(
                <div className={classes.toolbar}>                            
                   <DocumentTypeSelect 
                        {...this.props}
                        dateType={this.state.dateType}
                        onChangeDateType={this.onChangeDateType}                                
                    />                                        
                    <DateTimePicker
                        onChangeRange={this.onChangeRangeStart}
                        startDate={this.state.startDate}
                    />                    
                    <DateTimePicker
                        onChangeRange={this.onChangeRangeEnd}
                        startDate={this.state.endDate}
                    />                    
                    <Button variant="contained" color="default" disabled={this.props.isLoadingPurchaseOrders} style={{marginLeft: "3px", fontSize:"smaller"}} onClick={this.onSearchClick}><Translate id= "common.search"/></Button>
                    <Button variant="contained" color="default" disabled={this.props.isDownloadQueries} style={{marginLeft: "3px", fontSize:"smaller"}} onClick={this.onDonwlodClick}> <Translate id="common.download" /></Button> 
                </div>
            )
        }
        else if(this.props.selectedCompany.company_code === COMPANY_IMBERA || this.props.selectedCompany.company_code === COMPANY_METALICOS
            || this.props.selectedCompany.company_code === COMPANY_IMBERA_WARRANTY){
            return(
                <div className={classes.toolbar} >
                    <DocumentTypeSelect 
                        {...this.props}
                        dateType={this.state.dateType}
                        onChangeDateType={this.onChangeDateType}                                
                    />
                    {this.state.showPeriod &&
                        <Button 
                            variant="contained" 
                            color="default" 
                            disabled={this.props.isLoadingPurchaseOrders} 
                            style={{marginLeft: "3px", fontSize:"smaller"}}                                    
                            onClick={this.getPurchaseOrderByPeriodEightMonths}                                    
                            ><Translate id= "DateTimePicker.eightMonths"/></Button>
                    }              
                    {!this.state.showPeriod &&
                        <DateTimePicker
                            onChangeRange={this.onChangeRangeStart}
                            startDate={this.state.startDate}
                        />
                    }
                    {!this.state.showPeriod &&
                        <DateTimePicker
                            onChangeRange={this.onChangeRangeEnd}
                            startDate={this.state.endDate}
                        />
                    }
                    {!this.state.showPeriod &&
                        <Button variant="contained" color="default" disabled={this.props.isLoadingPurchaseOrders} style={{marginLeft: "3px", fontSize:"smaller"}} onClick={this.onSearchClick}><Translate id= "common.search"/></Button>
                    }

                    {this.state.showPeriod &&
                        <Button 
                            variant="contained" 
                            color="default" 
                            disabled={this.props.isLoadingPurchaseOrders} 
                            style={{marginLeft: "3px", fontSize:"smaller"}}                                    
                            onClick={this.handleOpenCloseModal}                                    
                            >Rango de fechas</Button>
                    }
                    <DonwloadReportDialog 
                        title={"Obtener ordenes de compra por Rango de fechas"}
                        open={this.state.showDateRangeDialog}
                        showMessageDownloadReport={false}
                        onClose={this.handleOpenCloseModal}
                        isSaving={this.props.isLoadingPurchaseOrders}
                        titleBtnConfirm ="common.getPurchaseOrders"
                        onDonwloadItems={this.getPurchaseOrderByDateRange}
                    />
                    <Button variant="contained" color="default" disabled={this.props.isDownloadQueries} style={{marginLeft: "3px", fontSize:"smaller"}} onClick={this.onDonwlodImberaBtnClicClick}> <Translate id="common.download" /></Button>
                </div>
            )
        }
        else{
            return(
                <div className={classes.toolbar} >
                    <DocumentTypeSelect 
                        {...this.props}
                        dateType={this.state.dateType}
                        onChangeDateType={this.onChangeDateType}                                
                    />
                    {this.state.showPeriod &&
                        <Button 
                            variant="contained" 
                            color="default" 
                            disabled={this.props.isLoadingPurchaseOrders} 
                            style={{marginLeft: "3px", fontSize:"smaller"}}                                    
                            onClick={this.onFirstPageClick}                                    
                            ><Translate id= "DateTimePicker.CurrentPeriod"/></Button>
                    }
                    {this.state.showPeriod &&
                        <Button 
                            variant="contained" 
                            color="default" 
                            disabled={this.props.isLoadingPurchaseOrders} 
                            style={{marginLeft: "3px", fontSize:"smaller"}}                                    
                            onClick={this.onPreviousPageClick}                                    
                            ><Translate id= "DateTimePicker.PreviousPeriod"/></Button>
                    }
                    {this.state.showPeriod && this.state.period > 1 &&
                        <Button 
                            variant="contained" 
                            color="default" 
                            disabled={this.props.isLoadingPurchaseOrders} 
                            style={{marginLeft: "3px", fontSize:"smaller"}}                                    
                            onClick={this.onNextPageClick}                                    
                            ><Translate id= "DateTimePicker.NextPeriod"/></Button>
                    }                    
                    {!this.state.showPeriod &&
                        <DateTimePicker
                            onChangeRange={this.onChangeRangeStart}
                            startDate={this.state.startDate}
                        />
                    }
                    {!this.state.showPeriod &&
                        <DateTimePicker
                            onChangeRange={this.onChangeRangeEnd}
                            startDate={this.state.endDate}
                        />
                    }
                    {!this.state.showPeriod &&
                        <Button variant="contained" color="default" disabled={this.props.isLoadingPurchaseOrders} style={{marginLeft: "3px", fontSize:"smaller"}} onClick={this.onSearchClick}><Translate id= "common.search"/></Button>
                    }
                    <Button variant="contained" color="default" disabled={this.props.isDownloadQueries} style={{marginLeft: "3px", fontSize:"smaller"}} onClick={this.onDonwlodClick}> <Translate id="common.download" /></Button>
                </div>)
        }
    }

    /**
     * Render
     *
     * @returns
     * @memberof Supplier
     */
    render() {
        const { classes } = this.props;    
        return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar color="primary" >
                            <PurchaseOrdersIcon />
                        </Avatar>
                    }
                    title={<span><Translate id= "menu.consults"/> | <Translate id= "menu.purchaseOrder"/></span>}
                    subheader={ <span>
                        {this.props.supplier ? this.props.supplier.full_name : ""}
                        {this.getButtonsContent()}                                              
                    </span>
                }
                />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {/** Dialog to update information */}
                <Dialog
                    open={this.state.showspecPlano}
                    onClose={() => { this.setState({ showspecPlano: false }) }}
                    header={
                        <Toolbar>
                            <DialogTitle id="form-dialog-title">{<Translate id="queries.purchaseOrders.specifications"/>}
                                {this.state.itemSelected && ": " + this.state.itemSelected.specification_plane + " "}
                                {<Translate id="queries.purchaseOrders.revision"/>}
                                {this.state.itemSelected && ":  " + this.state.itemSelected.revision}</DialogTitle>
                        </Toolbar>
                    }
                >

                    {this.getSppectList()}

                    <Toolbar>
                        <div className={classes.grow}></div>
                        <Button variant="outlined" color="primary" className={classes.button}
                            data-cy="btn-close"
                            onClick={() => { this.setState({ showspecPlano: false }) }}>
                            <Translate id="common.close" />
                        </Button>
                    </Toolbar>

                </Dialog>

            </Card>
        )
    }
}


/**
 * Get the document selection
 *
 * @class DocumentTypeSelect
 * @extends {Component}
 */
class DocumentTypeSelect extends Component {


    /**
     * Get the select options
     *
     * @returns
     * @memberof DocumentTypeSelect
     */
    getOptions(type) {
        if(type===1){
            return [
                { value: "A", label: 'Abiertas' },
                { value: "C", label: 'Cerradas' }
            ]
        }
        else
        {
            return [
                { value: "B", label: 'Creación' },
                { value: "C", label: 'Contabilización' }
            ]
        }
    }

    /**
     * Render
     *
     * @returns
     * @memberof DocumentTypeSelect
     */
    render() {
        const { classes } = this.props;
        let type = 0;
        if (this.props.selectedCompany.get_company_identifier !== COMPANY_PTM) { type = 1; }
        let options = this.getOptions(type);
        return (
            <Select
                value={options[this.props.dateType]}
                className={classes.documentTypeSelect}
                options={options}
                onChange={this.props.onChangeDateType}
                styles={{
                    menu: (base) => ({ ...base, zIndex: 100 })
                }}
            />
        );
    }
}

const TableConfiguration = {
    columns: [{
        header: '',
        dataSource: 'status_indicator',
    }, {
        header: '',
        dataSource: 'expand'
    }, {
        header: <Translate id="queries.purchaseOrders.status" />,
        dataSource: 'status',
        isSortable: true
    }, {
        header: <Translate id="queries.purchaseOrders.center" />,
        dataSource: 'center_company',
        isSortable: true
    }, {
        header: <Translate id="queries.purchaseOrders.purchaseOrder" />,
        dataSource: 'order',
        isSortable: true
    }, {
        header: <Translate id="queries.purchaseOrders.line" />,
        dataSource: 'line',
        isSortable: true
    }, {
        header: <Translate id="queries.purchaseOrders.part" />,
        dataSource: 'item',
        isSortable: true
    }, {
        header: <Translate id="queries.purchaseOrders.description" />,
        dataSource: 'description'
    }, {
        header: <Translate id="queries.purchaseOrders.amountOrdered" />,
        dataSource: 'orden_items'
    }, {
        header: <Translate id="queries.purchaseOrders.receivedAmount" />,
        dataSource: 'reception_items',
        isSortable: true
    }, {
        header: <Translate id="queries.purchaseOrders.pendingQuantity" />,
        dataSource: 'balance'
    }, {
        header: <Translate id="queries.purchaseOrders.unitMeasure" />,
        dataSource: 'unit_measure'
    }, {
        header: <Translate id="queries.purchaseOrders.deadline" />,
        dataSource: 'delivery_date'
    },
    {
        header: <Translate id="queries.purchaseOrders.especPlane" />,
        dataSource: 'specification_plane'
    }, {
        header: <Translate id="queries.purchaseOrders.especPackange" />,
        dataSource: 'specification_empac'
    }, {
        header: <Translate id="queries.purchaseOrders.revision" />,
        dataSource: 'revision'
    }, {
        header: <Translate id="queries.purchaseOrders.subassembly" />,
        dataSource: 'subassembly'
    }]
}

/** Configuration to show additional rows */
const AditionalRows = {
    rows: [{
        dataSource: 'purchase_orders_items',
        type: 'dataList'
    }]
}

/** Table configuration to render Datatable of aditional row */
const TableConfigurationPurchaseOrder = {
    columns: [
        {
            header: <Translate id="queries.purchaseOrders.line" />,
            dataSource: 'l_item'
        },
        {
            header: <Translate id="queries.purchaseOrders.numberEntry" />,
            dataSource: 'reference_sap'
        },
        {
            header: <Translate id="queries.purchaseOrders.invoiceNumber" />,
            dataSource: 'invoice_number'
        },
        {
            header: <Translate id="queries.purchaseOrders.description" />,
            dataSource: 'description'
        },
        {
            header: <Translate id="queries.purchaseOrders.cost" />,
            dataSource: 'cost'
        },
        {
            header: <Translate id="queries.purchaseOrders.currency" />,
            dataSource: 'currency'
        },

        {
            header: <Translate id="queries.purchaseOrders.receivedAmount" />,
            dataSource: 'received_products'
        },
        {
            header: <Translate id="queries.stocks.receptionDate" />,
            dataSource: 'receipt_date'
        }
    ]
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let selectedCompany = state.profile.get('selectedCompany')
    return {
        selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
        supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
        isLoadingPurchaseOrders: state.suppliers.get('isLoadingPurchaseOrders'),
        purchaseOrders: state.suppliers.get('purchaseOrders') ? state.suppliers.get('purchaseOrders').toJS() : null,
        specPlanos: state.suppliers.get("specPlanos") ? state.suppliers.get('specPlanos') : null,
        isDownloadQueries: state.suppliers.get('isDownloadQueries')        
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadSupplier: (token) => {
            dispatch(loadSupplier(token))
        },
        getPurchaseOrders: (supplierCode, startDate, endDate, dateType,companyCode) => {
            return dispatch(getPurchaseOrdersFromSAP(supplierCode, startDate, endDate, dateType, companyCode))
        },
        getPurchaseOrdersByPeriod: (supplierCode, dateType, period) => {
            dispatch(getPurchaseOrdersFromSAPByPeriod(supplierCode, dateType, period))
        },
        getSpecificationPlaneByPurchaseOrder: (specPlano, revision) => {
            dispatch(getSpecificationPlaneByPurchaseOrder(specPlano, revision))
        },
        donwlodPurchaseOrders:(supplierCode, startDate, endDate, dateType) =>{
            dispatch(donwlodPurchaseOrdersFromSAP(supplierCode, startDate, endDate, dateType))
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex",
        width: "700px",
        margin: theme.spacing.unit,
    },
    documentTypeSelect: {
        width: "180px",
        marginRight: "5px"
    },
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrders))));
