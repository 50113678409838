import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import Select from 'react-select'

/** Material-UI imports section */
import { Grid, Tooltip } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import Avatar from '@material-ui/core/Avatar'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'

/** Import component section */
import DataTable from '../../common/DataTable'

/**
 * Tab to UserTabs
 */
class UserTabs extends Component {
	/**
	 *Creates an instance of AddContractModal.
	 * @param {*} props
	 * @memberof AddContractModal
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	onRenderCellItem(dataSource, item) {
		if (dataSource === 'avatar') {
			return (
				<Avatar data-cy='avatar'>
					<PersonIcon color='primary' />
				</Avatar>
			)
		}
		if (dataSource === 'token') {
			if (!item.is_contract_main) {
				return (
					<Tooltip title={<Translate id='common.delete' />}>
						<DeleteOutlinedIcon
							onClick={() => {
								this.props.onRemoveUser(item)
							}}
							style={{ zIndex: '990', cursor: 'pointer' }}
							color='error'
						/>
					</Tooltip>
				)
			} else {
				return '-'
			}
		}
	}

	/**
	 * Dialog to update information
	 */
	render() {
		const { contract_users, users, onAddUserInContract, canBeModuleContractsSupplier } = this.props
		return (
			<Grid>
				{canBeModuleContractsSupplier(this.props.userRolesByCompany) && (
					<Grid item xs={12}>
						<br />
						<Select
							placeholder={'Agregar usuarios para visualizar contrato'}
							options={users}
							onChange={onAddUserInContract}
							styles={{
								menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
								menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
								menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
							}}
							menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
							menuPlacement={'auto'}
						/>
					</Grid>
				)}
				<DataTable
					data={contract_users}
					configuration={TableUsers(canBeModuleContractsSupplier(this.props.userRolesByCompany))}
					onRenderCellItem={this.onRenderCellItem}
				/>
			</Grid>
		)
	}
}

UserTabs.defaultProps = {}

function TableUsers(isDisabled) {
	let columns = [
		{
			header: '',
			dataSource: 'avatar',
		},
		{
			header: <Translate id='common.userName' />,
			dataSource: 'user_name',
		},
		{
			header: <Translate id='common.nameUser' />,
			dataSource: 'name',
		},
	]
	if (isDisabled) {
		columns.push({
			header: '',
			dataSource: 'token',
		})
	}
	return { columns }
}

const dialogStyles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
})

export default withStyles(dialogStyles, { withTheme: true })(UserTabs)
