import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Translate, withLocalize } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { decodeJson, decodeNumericArray, decodeArray } from 'use-query-params'

/* Material UI import section */
import { Avatar, Card, CardContent, CardHeader, Fab, Typography, withStyles } from '@material-ui/core'
import AccreditedWorkShopsIcon from '@material-ui/icons/HowToReg'
import LockIcon from '@material-ui/icons/Lock'

/** Custom components import section */
import DataTable from '../../common/DataTable'
import LoadingPage from '../../common/LoadingPage'
import ContextFilterMenu from '../../common/contextFilters/ContextFilterMenu'
import AdvancedFiltersModal from '../../common/contextFilters/AdvancedFiltersModal'
import ServiceOrderDetail from '../serviceOrders/components/ServiceOrderDetail'
import ButtonDownload from '../components/ButtonDownload'
import DialogLockConfirmation from './components/DialogConfirmation'
import MetricsCard from '../serviceOrders/components/MetricsCard'
import ButtonTableColumnSettings from '../components/ButtonColumnsTableSettings'
import ColumnsConfigurator from '../../common/DataGrid/ColumnsConfigurator'
import ServiceOrdersSourceSwitch from '../components/ServiceOrdersSourceSwitch'
import Spacer from '../../common/Spacer'

/** Actions imoprt section */
import {
	loadAccreditedWorkshopsServicesFromBackEnd,
	downloadServiceOrdersReport,
	loadAccreditedWorkshopsServicesTotalFromBackEnd,
	lockServiceOrdersForPaymentRequest,
	loadAccreditedWorkshopsServicesTotalAmountFromBackEnd,
	setServicesList,
	cancelHttpRequest,
	loadAccreditedWorkshopsServicesInPs9FromBackEnd,
	downloadServiceOrdersInPs9Report,
} from '../../../store/accreditedWorkshops/accreditedWorkshopsActions.js'

/** Redux selectors import section */
import {
	getAllowedStatusForNotReleasedServicesSelector,
	getServiceOrderListSelector,
	getServiceOrdersAmount,
	getServiceOrdersTotal,
	getServiceTypesOnConfigurationSelector,
	getWorkshopCodesOnConfigurationSelector,
} from '../../../store/accreditedWorkshops/accreditedWorkshopsSelectors'

/** Custom hooks import section */
import { useConfigurator } from '../../common/DataGrid/UseConfigurator'
import useClearFiltersButton from '../hooks/ClearFiltersButtonHook'
import useSorting from '../hooks/SortingHook'

/** Resources import section */
import serviceOrderTableConfiguration from '../serviceOrders/serviceHelpers/TableConfiguration'
import { buildFilterConfig, FilterCriteria } from '../serviceOrders/serviceHelpers/FilterConfiguration'
import { decodeDelimitedPipeArray } from '../../common/contextFilters/CustomArrayParam'
import { renderCustomCellItem } from '../serviceOrders/serviceHelpers/TableHelpers'

const tableId = 'not-released-service-orders-table-config'

const Source = {
	CRM: 'CRM',
	PS9: 'PS9',
}

const NotReleasedServiceOrders = (props) => {
	/** Desctructuring properties */
	const { location, history, translate, classes } = props

	/** Defines local state */
	const [serviceOrdersSource, setServiceOrdersSource] = useState(Source.CRM)
	const [loading, setLoading] = useState(true)
	const [loadingTotalAmount, setLoadingTotalAmount] = useState(false)
	const [showCircularProgress, setShowCircularProgress] = useState(false)
	const [selectedServiceOrders, setSelectedServiceOrders] = useState([])
	const [rowsPerPage, setRowsPerPage] = useState(20)
	const [page, setPage] = useState(0)
	const [serviceTableConfiguration, setServiceTableConfiguration] = useConfigurator(
		tableId,
		serviceOrderTableConfiguration
	)
	const [increasedTableConfiguration, setIncreasedTableConfiguration] = useState(serviceTableConfiguration)
	const [configuratorAnchorEl, setConfigurationAnchorEl] = useState(null)
	const [serviceOrderDetailPanelOptions, setServiceOrderDetailPanelOptions] = useState({
		opened: false,
		onCloseButtonClick: null,
		token: '',
	})
	const [filters, setFilters] = useState([])
	const [filterSettings, setFilterSettings] = useState({
		open: false,
		anchorEl: null,
		type: '',
		filterIdentificator: '',
	})
	const [advancedFilterModalConfig, setAdvancedFilterModalConfig] = useState({
		open: false,
		queryStringKey: '',
		filterId: '',
		criteria: 0,
	})
	const [lockConfirmationModalOptions, setLockConfirmationModalOptions] = useState({ opened: false })
	const servicesComeFromCRM = serviceOrdersSource === Source.CRM
	/** Connect with redux */
	const dispatch = useDispatch()
	const serviceOrdersTotal = useSelector((state) => getServiceOrdersTotal(state))
	const serviceOrders = useSelector((state) => getServiceOrderListSelector(state))
	const totalAmount = useSelector((state) => getServiceOrdersAmount(state))
	const serviceTypes = useSelector((state) => getServiceTypesOnConfigurationSelector(state))
	const workshopCodes = useSelector((state) => getWorkshopCodesOnConfigurationSelector(state))
	const allowedServiceOrderStatus = useSelector((state) => getAllowedStatusForNotReleasedServicesSelector(state))

	const [sortingBy, sortingDirection, dataSource, orderAsc, handleSortingCriteriaChange] = useSorting()
	const [ClearFiltersButton] = useClearFiltersButton()

	useEffect(() => {
		if (!!location && serviceTypes.length > 0 && allowedServiceOrderStatus.length > 0 && workshopCodes.length > 0) {
			const queryParams = queryString.parse(location.search)
			const searchFilters = []

			Object.entries(queryParams).forEach(([name, value]) => {
				if (name.startsWith('Advanced')) {
					let newName = name.replace('Advanced', '')
					switch (newName) {
						case 'ServiceCost': {
							const decodedFilter = decodeJson(value)
							const decodedValues = decodeNumericArray(decodedFilter.values)
							searchFilters.push({
								PropertyIdentificator: newName,
								RuleValue: decodedValues,
								FilterCategorization: decodedFilter.criteria,
							})
							break
						}
						default: {
							const decodedFilter = decodeJson(value)
							const decodedValues = decodeArray(decodedFilter.values)
							searchFilters.push({
								PropertyIdentificator: newName,
								RuleValue: decodedValues,
								FilterCategorization: decodedFilter.criteria,
							})
							break
						}
					}
				} else if (!name.includes('order')) {
					switch (name) {
						case 'pageNumber': {
							break
						}
						case 'TallerImporteServicio':
						case 'TallerImporteGastos': {
							const decodedValues = decodeDelimitedPipeArray(value)
							searchFilters.push({
								PropertyIdentificator: name,
								RuleValue: decodedValues,
								FilterCategorization: FilterCriteria.NUMERIC_EQUAL,
							})
							break
						}
						default: {
							const decodedValues = decodeDelimitedPipeArray(value)
							searchFilters.push({
								PropertyIdentificator: name,
								RuleValue: decodedValues.map((val) => (val === '(VACIO)' ? ' ' : val)),
								FilterCategorization: name.includes('Date')
									? FilterCriteria.BETWEEN_DATES
									: FilterCriteria.EQUAL,
							})
							break
						}
					}
				}
			})
			searchFilters.push({
				PropertyIdentificator: 'ServiceTypeId',
				RuleValue: serviceTypes.map((st) => st.Type),
			})
			setFilters([
				...searchFilters,
				{
					PropertyIdentificator: 'WorkshopTechnicianSapId',
					RuleValue: workshopCodes,
				},
				{
					PropertyIdentificator: 'OrdenStatus',
					RuleValue: allowedServiceOrderStatus, //['10']
				},
				{
					PropertyIdentificator: 'WorkshopStatus',
					RuleValue: ['100'],
				},
			])
		}
	}, [location, serviceTypes, workshopCodes])

	/** Load service orders */
	useEffect(() => {
		if (serviceOrdersSource === Source.CRM) {
			if (filters.length > 0) {
				setLoading(true)
				dispatch(
					loadAccreditedWorkshopsServicesFromBackEnd(filters, page, rowsPerPage, sortingBy, sortingDirection)
				).finally(() => setLoading(false))
				dispatch(loadAccreditedWorkshopsServicesTotalFromBackEnd(filters))
				setLoadingTotalAmount(true)
				dispatch(loadAccreditedWorkshopsServicesTotalAmountFromBackEnd(filters)).finally(() =>
					setLoadingTotalAmount(false)
				)
			} else {
				dispatch(setServicesList([]))
				setLoading(false)
			}
		} else if (serviceOrdersSource === Source.PS9) {
			setLoading(true)
			const serviceTypeIds = serviceTypes.map(({ Type }) => Type)
			dispatch(loadAccreditedWorkshopsServicesInPs9FromBackEnd(workshopCodes, serviceTypeIds)).finally(() =>
				setLoading(false)
			)
		}

		return () => {
			cancelHttpRequest('serviceOrdersRequest')
			cancelHttpRequest('serviceOrdersTotalRequest')
			cancelHttpRequest('serviceOrdersTotalAmountRequest')
		}
	}, [dispatch, serviceOrdersSource, workshopCodes, filters, sortingBy, sortingDirection, page, rowsPerPage])

	useEffect(() => {
		if (servicesComeFromCRM) {
			setIncreasedTableConfiguration(serviceTableConfiguration)
		} else {
			setIncreasedTableConfiguration([
				...serviceTableConfiguration
					.filter((c) => c.dataSource !== 'selectCheck')
					.map((c) => ({ ...c, isSortable: false, filterEnabled: false })),
				{
					header: <Translate id='common.status' />,
					dataSource: 'statusDescription',
					isSortable: false,
					filterEnabled: false,
				},
			])
		}
	}, [servicesComeFromCRM, serviceTableConfiguration])

	/**
	 * Handle the service orders selection and unpdate local state.
	 * @param {Array} selectedTokens
	 */
	const handleServiceOrdersSelection = (selectedTokens) => {
		const selectedSerciceOrderCodes = serviceOrders.reduce(
			(serviceCodes, serviceOrder) =>
				selectedTokens.includes(serviceOrder.token) ? [...serviceCodes, serviceOrder.code] : serviceCodes,
			[]
		)
		setSelectedServiceOrders(selectedSerciceOrderCodes)
	}

	const handlePageChange = (newPage, newRowsPerPage) => {
		setRowsPerPage((prevRowsPerPage) => {
			const pageToSet = prevRowsPerPage !== newRowsPerPage ? 0 : newPage
			setPage(pageToSet)
			return newRowsPerPage
		})
	}

	/*
	 * Download service order report
	 */
	const downloadServiceOrdersReportIndex = () => {
		setShowCircularProgress(true)
		if (servicesComeFromCRM) {
			dispatch(downloadServiceOrdersReport(filters)).finally(() => setShowCircularProgress(false))
		} else {
			const serviceTypeIds = serviceTypes.map(({ Type }) => Type)
			dispatch(downloadServiceOrdersInPs9Report(workshopCodes, serviceTypeIds)).finally(() =>
				setShowCircularProgress(false)
			)
		}
	}

	/*
	 * Lock service orders
	 */
	const lockServiceOrders = (comments) => {
		setLockConfirmationModalOptions({ opened: true, executingAction: false })
		dispatch(lockServiceOrdersForPaymentRequest(selectedServiceOrders, comments))
			.then(() => {
				closeLockConfirmationModal()
				setTimeout(() => {
					history.go(0)
				}, 2000)
			})
			.catch((error) => {
				console.log(`🚀 ~ file: ReleasedServiceOrders.js ~ line 225 ~ dispatch ~ error`, error)
			})
	}

	const openContextFilterMenu = (event, dataSource) => {
		let filterConfig = buildFilterConfig(dataSource)
		const rect = event.currentTarget.getBoundingClientRect()
		setFilterSettings({
			...filterConfig,
			open: true,
			anchorEl: event.currentTarget,
			x: rect.x,
			y: rect.y + rect.height,
		})
	}

	const closeContextFilterMenu = () => {
		setFilterSettings({
			open: false,
			anchorEl: null,
			type: '',
			name: '',
			dataSource: '',
			filterIdentificator: '',
		})
	}

	const openAvancedFilterModal = (queryStringKey, criteria) => {
		setAdvancedFilterModalConfig({
			open: true,
			queryStringKey: queryStringKey,
			criteria: criteria,
		})
		closeContextFilterMenu()
	}

	const closeAvancedFilterModal = () => {
		setAdvancedFilterModalConfig({ open: false, criteria: 0 })
	}

	/**
	 * Update state in order to show the confirmation modal to lock service orders for payament request.
	 */
	const openLockConfirmationModal = () => {
		setLockConfirmationModalOptions({ opened: true })
	}

	/**
	 * Update state in order to hide the confirmation modal to lock service orders for payament request.
	 */
	const closeLockConfirmationModal = () => {
		setLockConfirmationModalOptions({ opened: false })
	}

	/**
	 * Update state in order to show the lateral panel with service order detail.
	 * @param {Object} item
	 * @param {Object} event
	 */
	const openServiceOrderDetail = (item, event) => {
		const { tagName } = event.target
		if (tagName !== 'INPUT') {
			const { token } = item
			setServiceOrderDetailPanelOptions({ opened: true, onCloseButtonClick: closeServiceOrderDetail, token })
		}
	}

	/**
	 * Update state in order to close the lateral panel with service order detail
	 */
	const closeServiceOrderDetail = () => {
		setServiceOrderDetailPanelOptions({ opened: false, token: '' })
	}

	const openTableColumnsConfigurator = (event) => {
		setConfigurationAnchorEl(configuratorAnchorEl ? null : event.currentTarget)
	}

	/**
	 * The user change the service table configuration
	 */
	const onConfigurationChange = (configuration) => {
		setServiceTableConfiguration(configuration)
		setConfigurationAnchorEl(null)
	}

	/**
	 * Get counter
	 *
	 * @returns
	 * @memberof ServiceOrders
	 */
	const getCounterMessage = () => {
		if (serviceOrders) {
			let quantityOfServices = serviceOrders.length
			return (
				<div>
					{translate('common.showing')}&nbsp;
					<span>{quantityOfServices}</span>&nbsp;
					{translate('common.of')}&nbsp;
					<span className={classes.totalCounter}>{serviceOrdersTotal}</span>
					&nbsp;
					{translate('common.records')}
				</div>
			)
		}
		return <span></span>
	}

	return (
		<>
			<Card>
				<CardHeader
					className={classes.cardHeader}
					avatar={
						<Avatar>
							<AccreditedWorkShopsIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							{servicesComeFromCRM && (
								<>
									<MetricsCard
										loading={loadingTotalAmount}
										quantityOfServices={serviceOrdersTotal}
										amount={totalAmount}
									/>
									<Spacer />
									<ClearFiltersButton />
									<Fab
										title={translate('accreditedWorkshops.lockServiceOrders')}
										color='primary'
										onClick={openLockConfirmationModal}
										disabled={selectedServiceOrders.length === 0}
										style={{ height: 15, width: 35 }}
									>
										<LockIcon />
									</Fab>
									<Spacer />
								</>
							)}
							<ButtonDownload
								onClick={downloadServiceOrdersReportIndex}
								showCircularProgress={showCircularProgress}
							/>
							<Spacer />
							<Spacer />
							<ButtonTableColumnSettings onShowConfiguration={openTableColumnsConfigurator} />
							<ColumnsConfigurator
								open={configuratorAnchorEl ? true : false}
								anchorEl={configuratorAnchorEl}
								configuration={serviceTableConfiguration}
								onConfigurationChange={onConfigurationChange}
								onClickAway={() => setConfigurationAnchorEl(null)}
							/>
							<Spacer />
							<ServiceOrdersSourceSwitch
								checked={!servicesComeFromCRM}
								onChange={() => setServiceOrdersSource(servicesComeFromCRM ? Source.PS9 : Source.CRM)}
							/>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='accreditedWorkshops.accreditedWorkshopsService' />
						</Typography>
					}
					subheader={getCounterMessage}
				/>
				<CardContent className={classes.cardContent}>
					{
						{
							true: <LoadingPage />,
							false: (
								<DataTable
									data={serviceOrders}
									configuration={{ columns: increasedTableConfiguration }}
									sortBy={dataSource}
									sortAscending={orderAsc}
									onChangeSortCriteria={handleSortingCriteriaChange}
									onRowClick={servicesComeFromCRM ? openServiceOrderDetail : undefined}
									isIndex
									rowHeight={32}
									onRenderCellItem={renderCustomCellItem}
									totalRows={servicesComeFromCRM ? serviceOrdersTotal : undefined}
									page={page}
									rowsPerPage={rowsPerPage}
									onChangePage={handlePageChange}
									onSelectedCheck={handleServiceOrdersSelection}
									onFilterClick={openContextFilterMenu}
								/>
							),
						}[loading]
					}
				</CardContent>
			</Card>
			{filterSettings.open && (
				<ContextFilterMenu
					{...filterSettings}
					filters={filters}
					onClose={closeContextFilterMenu}
					onAvancedFilterClick={openAvancedFilterModal}
				/>
			)}
			{advancedFilterModalConfig.open && (
				<AdvancedFiltersModal {...advancedFilterModalConfig} onClose={closeAvancedFilterModal} />
			)}
			{serviceOrderDetailPanelOptions.opened && (
				<ServiceOrderDetail classes={classes} {...serviceOrderDetailPanelOptions} />
			)}
			{lockConfirmationModalOptions.opened && (
				<DialogLockConfirmation
					{...lockConfirmationModalOptions}
					commentsRequired
					title={<Translate id='accreditedWorkshops.lockServiceOrders' />}
					message={<Translate id='accreditedWorkshops.lockServiceOrdersConfirmationMessage' />}
					onClose={closeLockConfirmationModal}
					onConfirm={lockServiceOrders}
				/>
			)}
		</>
	)
}

const styles = (theme) => ({
	cardHeader: {
		padding: '8px 16px 0px 4px',
	},
	actionsToolbar: {
		display: 'flex',
		alignItems: 'center',
	},
	cardContent: {
		padding: '4px 4px',
		maxWidth: 'calc(100vw - 300px)',
	},
	instructionsDiv: {
		padding: theme.spacing.unit,
		maxWidth: '20vw',
	},
})

export default withRouter(withLocalize(withStyles(styles, { withTheme: true })(NotReleasedServiceOrders)))
