import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import {
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core'

/** Import component section */
import Dialog from './../../../common/Dialog'
import DialogActions from './../../../common/DialogActions'
import Evidence from './../../../common/Evidence'
import EmployeeSelector from '../../components/EmployeeSelector'

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper'
import DatePicker from '../../../common/DateTimePicker/DatePicker'
import moment from 'moment'
/**
 * Class compoenent to add an employee
 *
 * @class AddMedicalCheckDialog
 * @extends {Component}
 */
class AddMedicalCheckDialog extends Component {
	/**
	 *Creates an instance of DialogActions.
	 * @param {*} props
	 * @memberof DialogActions
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			selectedEmployee: null,
			medicalCheckFiles: [],
			dopingFiles: [],
			isInformedConsentSigned: true, // se Agregara un informe de consentimiento cambiar valor a false si se requiere
			startDateMedicalCheck: '',
			endDateMedicalCheck: '',
			startDateDoping: '',
			endDateDoping: '',
		}
	}

	/**
	 * Return initial state
	 *
	 * @returns
	 * @memberof AddServiceProjectDialog
	 */
	initialState() {
		return {
			medicalCheckFiles: [],
			dopingFiles: [],
			selectedEmployee: null,
			isInformedConsentSigned: true,
		}
	}

	/**
	 * On seletecd emploeed
	 */
	onEmployeedSelected(emploeed) {
		this.setState({ selectedEmployee: emploeed })
	}

	/**
	 * Handle update properties to set data in local state
	 *
	 * @param {*} property
	 * @param {*} data
	 * @memberof AddMedicalCheckDialog
	 */
	updateProperty(property, data) {
		this.setState({
			[property]: data,
		})
		if (property === 'startDateMedicalCheck'){
			this.setState({
				endDateMedicalCheck: moment(data).add(1,'years')
			})
		}
		if (property === 'startDateDoping'){
			this.setState({
				endDateDoping: moment(data).add(1,'years')
			})
		}
	}

	/**
	 * Handle event upload files of devidence componet to add files to list
	 *
	 * @param {*} files
	 * @returns
	 * @memberof AddMedicalCheckDialog
	 */
	uploadMedicalCheckFiles(files) {
		let filesList = this.state.medicalCheckFiles.concat(files[0])
		if (files.length > 0) {
			for (var index = 1; index < files.length; index++) {
				filesList.push(files[index])
			}
		}

		this.setState({
			medicalCheckFiles: filesList,
		})
		return Promise.resolve('Ok')
	}

	uploadDopingFiles(files) {
		let filesList = this.state.dopingFiles.concat(files[0])
		if (files.length > 0) {
			for (var index = 1; index < files.length; index++) {
				filesList.push(files[index])
			}
		}

		this.setState({
			dopingFiles: filesList,
		})
		return Promise.resolve('Ok')
	}

	/**
	 * Handle event remove files of evidence componet to remove files of list
	 *
	 * @param {*} fileName
	 * @returns
	 * @memberof AddMedicalCheckDialog
	 */
	removeMedicalCheckFiles(fileName) {
		let newList = this.state.medicalCheckFiles.filter((files) => {
			return files.name !== fileName
		})
		this.setState({
			medicalCheckFiles: newList,
		})
		return Promise.resolve('Ok')
	}

	removeDopingFiles(fileName) {
		let newList = this.state.dopingFiles.filter((files) => {
			return files.name !== fileName
		})
		this.setState({
			dopingFiles: newList,
		})
		return Promise.resolve('Ok')
	}

	/**
	 * method to call action and add payment
	 *
	 * @memberof AddMedicalCheckDialog
	 */
	onAddMedicalCheck() {
		if (this.props.addMedicalCheck) {
			this.props.addMedicalCheck(this.state).then((response) => {
				if (response === 'Ok') this.handleCloseCancel()
			})
		}
	}

	/**
	 * Handle  Close or cancel event
	 *
	 * @memberof AddMedicalCheckDialog
	 */
	handleCloseCancel() {
		this.setState(this.initialState(), () => this.props.onClose())
	}

	/**
	 * Handle accpeting informed consent by user
	 */
	onAcceptInformedConsent() {
		this.setState({ isInformedConsentSigned: true })
	}

	/**
	 * Method to validate if required properties has data
	 *
	 * @returns
	 * @memberof AddMedicalCheckDialog
	 */
	isValidToSave() {
		if (this.state.selectedEmployee === null) return false
		if (this.state.medicalCheckFiles && this.state.medicalCheckFiles.length === 0) return false
		if (this.state.startDateMedicalCheck.length === 0) return false
		if (this.state.endDateMedicalCheck.length === 0) return false
		// if (this.state.startDateDoping.length === 0) return false
		// if (this.state.endDateDoping.length === 0) return false
		// if (this.state.dopingFiles && this.state.dopingFiles.length === 0) return false
		return true
	}

	renderInformedConsentForm() {
		const { classes } = this.props
		return (
			<Grid container className={classes.modal} justify='space-between'>
				<Grid item xs={12}>
					Verifique que su información es real
				</Grid>
			</Grid>
		)
	}

	renderMedicalCheckForm() {
		const { classes } = this.props
		return (
			<Grid container className={classes.modal} justify='space-between'>
				<Grid item xs={12}>
					<EmployeeSelector
						className={classes.textField}
						onEmployeedSelected={(employeed) => {
							this.onEmployeedSelected(employeed)
						}}
					/>
					<br />
				</Grid>
				<Grid item xs={12}>
					<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
						<Translate id='contractorsModule.medicalchecks.activationDate' />
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<DatePicker
						onChangeRange={(event) => {
							this.updateProperty('startDateMedicalCheck', event)
						}}
						startDate={this.state.startDateMedicalCheck}
					/>
				</Grid>
				<Grid item xs={6}>
				<TextField
					value={this.state.endDateMedicalCheck === '' ? '' : moment(this.state.endDateMedicalCheck).calendar()}
					name='EndDate'
					style={{marginLeft: '15px'}}
				/>
				</Grid>

				<Grid item xs={12}>
					<Typography variant='subtitle2' color={'textSecondary'}>
						{<Translate id='contractorsModule.medicalchecks.addfileCheck' />} PDF *
					</Typography>
					<Evidence
						baseApi={process.env.REACT_APP_SIP_URL_FILES}
						removeFiles={(fileName) => this.removeMedicalCheckFiles(fileName)}
						uploadFiles={(files) => this.uploadMedicalCheckFiles(files)}
						files={this.state.medicalCheckFiles}
						acceptedFiles={['pdf']}
						disabledLink
						maxFiles={1}
						text={this.props.dropzoneBaseText}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
						<Translate id='contractorsModule.medicalchecks.expirationDate' />
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<DatePicker
						onChangeRange={(event) => {
							this.updateProperty('startDateDoping', event)
						}}
						startDate={this.state.startDateDoping}
					/>
				</Grid>
				<Grid item xs={6}>
					
					<TextField
					value={this.state.endDateDoping === '' ? '' : moment(this.state.endDateDoping).calendar()}
					name='EndDate'
					style={{marginLeft: '15px'}}
				/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant='subtitle2' color={'textSecondary'}>
						{<Translate id='contractorsModule.medicalchecks.addDopingFile' />} PDF *
					</Typography>
					<Evidence
						baseApi={process.env.REACT_APP_SIP_URL_FILES}
						removeFiles={(fileName) => this.removeDopingFiles(fileName)}
						uploadFiles={(files) => this.uploadDopingFiles(files)}
						files={this.state.dopingFiles}
						acceptedFiles={['pdf']}
						disabledLink
						maxFiles={1}
						text={this.props.dropzoneBaseText}
					/>
				</Grid>
			</Grid>
		)
	}

	renderInformedConsentFormActions() {
		const { classes } = this.props
		return (
			<div>
				{!this.isValidToSave() && (
					<Typography variant='caption' color='error'>
						{<Translate id='common.requiredFields' />}
					</Typography>
				)}
				<DialogActions
					view={SHOW_EDIT_VIEW}
					classes={classes}
					onCancel={() => this.handleCloseCancel()}
					onClose={() => this.handleCloseCancel()}
					onSaveItem={this.onAddMedicalCheck}
					isSaving={this.props.isSaving}
					canBeSaved={this.isValidToSave() || this.props.isSaving}
				/>
			</div>
		)
	}

	renderMedicalCheckFormActions() {
		const { classes } = this.props
		return (
			<div>
				<DialogActions
					view={SHOW_EDIT_VIEW}
					classes={classes}
					onCancel={() => this.handleCloseCancel()}
					onClose={() => this.handleCloseCancel()}
					onSaveItem={this.onAcceptInformedConsent}
					isSaving={this.props.isSaving}
					canBeSaved={true}
					saveButtonTranslate={'common.accept'}
				/>
			</div>
		)
	}

	render() {
		return (
			<Dialog
				open={this.props.open}
				onClose={() => this.handleCloseCancel()}
				header={
					<Toolbar>
						<Typography variant='h6'>
							{<Translate id='contractorsModule.medicalchecks.addEmployeed' />}
						</Typography>
					</Toolbar>
				}
				actions={
					this.state.isInformedConsentSigned
						? this.renderInformedConsentFormActions()
						: this.renderMedicalCheckFormActions()
				}
			>
				{this.state.isInformedConsentSigned ? this.renderMedicalCheckForm() : this.renderInformedConsentForm()}
			</Dialog>
		)
	}
}

const styles = (theme) => ({
	modal: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
		width: 520,
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(AddMedicalCheckDialog))
