import React, {Component} from 'react';
import autoBind from 'auto-bind'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/** Material UI */
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/BusinessTwoTone';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

/** Language imports **/
import {Translate} from "react-localize-redux";

/** Import component */
import Dialog from '../../common/Dialog';
import Toaster from '../../common/Toaster';
import CropForm from '../../common/CropForm';

import { uploadAvatarUserExternals } from '../../../store/suppliers/ExternalUserActions';

/**
 * UploadAvatar view 
 *
 * @class View
 * @extends {Component}
 */
class UploadAvatar extends Component {

    /**
     * Creates an instance of View.
     * @param {*} props
     * @memberof View
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            isLoading: false,
            errorLoading: false,
            errorMessage: "",
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            croppedLogoFileBase64: this.props.fileBase64,
        }
    }

    /**
     * onCropLogoImage
     *
     * @param {*} fileBase64
     * @memberof View
     */
    onCropAvatarImage(fileBase64) {
        this.setState({
            croppedLogoFileBase64: fileBase64
        })
    }

    /**
     * On upload user avatar
     *
     * @memberof View
     */
    onUploadCompanyLogo() {
        let userToken = this.props.match.params.token;
        this.props.uploadAvatarUserExternals(userToken, this.state.croppedLogoFileBase64)
        .then(() => {
            this.props.onClose()
        })
        .catch(() => {
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="common.errorToSave"/>,
                toasterVariant: "error",
            })      
        })
    }

    handleOnUploadImage(){
        this.props.onUploadImage(this.state.croppedLogoFileBase64)
        .then(() => {
            this.props.onClose()
        })
        .catch(() => {
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="common.errorToSave"/>,
                toasterVariant: "error",
            })      
        })
    }

    /**
     * Get the toolbar header
     *
     * @memberof Password
     */
    getHeader() {
        const { classes } = this.props;
        return (
            <Toolbar>
                <React.Fragment>
                    <Avatar className={classes.toolBarAvatar}>
                        <BusinessIcon />
                    </Avatar>
                    <Typography variant="h6">
                        <Translate id="companies.addLogo"/>
                    </Typography>                
                </React.Fragment>
            </Toolbar>  
        )
    }

    /**
     *
     * Get view to show
     * @memberof View
     */
    getViewToShow() {
        return (
            <CropForm 
                fileBase64 = {this.props.fileBase64}
                onCropAvatarImage = {this.onCropAvatarImage}
        /> )
    }

        /**
     * Get the action buttons for the form
     *
     * @returns
     * @memberof Password
     */
    getActionButtons() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Toolbar className={this.props.errorThrowMessage !== null ? classes.errorToolBar: null}>
                    {
                        this.props.errorThrowMessage ? 
                        <Typography color="inherit" className={classes.errorMessage}>
                                {this.props.errorThrowMessage}
                            </Typography>
                        : null
                    }
                    <div className={classes.grow}></div>
                    {
                        this.state.isSaving === true ? 
                            <Button  color="secondary" disabled>
                                <Translate id="common.saving"/>
                            </Button>
                            :
                            <Button color="secondary" 
                                onClick={this.props.onUploadImage?this.handleOnUploadImage:this.onUploadCompanyLogo}
                            >
                            <Translate id="common.save"/> 
                            </Button>
                    }
                    <Button color="primary" onClick={this.props.onClose}>
                        <Translate id="common.cancel"/>
                    </Button>
                </Toolbar>
                {
                    this.state.isLoading === true ?
                    <LinearProgress color="secondary" variant="query" />
                    :  null
                }     

                <Toaster 
                    message = {this.state.toasterMessage}
                    open = {this.state.showToaster}
                    variant = {this.state.toasterVariant}
                    onClose = {() => {this.setState({ showToaster:false})}}
                /> 

            </React.Fragment>
        )
    }

    /**
     * Render
     *
     * @returns
     * @memberof View
     */
    render() {
            let viewToShow = this.getViewToShow();
            return (
                <Dialog
                    open= {this.props.open}
                    onClose={() => {
                        this.props.onClose()
                    }}
                    header={
                        this.getHeader()                        
                    }
                    actions= {
                        this.getActionButtons()
                    }
                >
                    {viewToShow}
                </Dialog>       
            )
    }
}


/**
 * Map state to props
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state){
    return {
        errorThrowMessage: state.companies.get('errorThrowMessage'),
    }
}

/**
 * Map dispatch to props
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        uploadAvatarUserExternals: (id, fileBase64) => {
            return dispatch(uploadAvatarUserExternals(id, fileBase64))
        }
    }
}

const viewStyles = theme => ({
    toolBarAvatar: {
        marginRight : "10px",
        backgroundColor: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    }
});
export default withRouter(withStyles(viewStyles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(UploadAvatar)));