import { fromJS } from 'immutable'
import moment from 'moment'
import {
	FREIGHT_RETENTION_PERCENTAGE,
	INVOICING_ERROR,
	REPARE_RFC,
	IVA_PERCENTAGE,
	SERVICE_RETENTION_PERCENTAGE,
	MAX_NUMBER_OF_SERVICES_IN_PAYMENT_REQUEST,
	PAYMENT_REQUESTS_CREATION_DAYS,
	RESICO_PERCENTAGE,
} from '../helpers/AccreditedWorkshopsHelper'

import * as accreditedWorkshopsActions from './accreditedWorkshopsActions'

/**
 *  Initial state
 */
export const accreditedWorkshopsInitialState = fromJS({
	workshopStatus: false,
	// Settings
	settings: {
		paymentRequestCreationEnabled: false,
		allowedDifferenceForInvoceAmount: 0,
		repareCurrentRfc: '',
		serviceRetentionPercent: 0,
		freightRetentionPercent: 0,
		serviceRetention: {
			percent: 0,
			workshopCodes: [],
		},
		freightRetention: {
			percent: 0,
			workshopCodes: [],
		},
	},

	// Configuration
	configuration: {
		isLoading: false,
		configuration: null,
	},

	//Pagination and search
	sortCriteria: {
		by: 'attentionDate',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	filterOptions: {},
	isLoadingFilterOptions: false,
	//Index services orders
	isLoadingServices: true,
	services: [],
	servicesCount: 0,
	servicesAmount: 0,
	//Index payment requests
	isLoadingPaymentRequests: true,
	paymentRequests: [],
	paymentRequestsCount: 0,
	//Index technicians
	isLoadingTechnicians: true,
	technicians: [],
	//Single service
	isLoadingService: false,
	isSavingService: false,
	service: null,
	paymentRequestErrors: [],
	serviceOrderErrors: [],
	//Single payment request
	isLoadingPaymentRequestDetail: false,
	paymentRequestDetail: null,
	invoice: null,
	creditNote: null,
	//Single technician
	isLoadingTechnicianDetail: false,
	isSavingTechnicianDetail: false,
	technicianDetail: null,
	// Workshops
	workshops: [],
})

export default function accreditedWorkshopsReducer(state = accreditedWorkshopsInitialState, action) {
	switch (action.type) {
		case accreditedWorkshopsActions.SET_ACCREDITED_WORKSHOP_STATUS: {
			return state.set('workshopStatus', action.status)
		}
		case accreditedWorkshopsActions.ADD_SERVICE_TYPE: {
			return state.updateIn(['configuration', 'configuration', 'ServiceTypes'], (allServices) =>
				allServices.push(fromJS(action.serviceType))
			)
		}
		case accreditedWorkshopsActions.REMOVE_SERVICE_TYPE: {
			return state.updateIn(['configuration', 'configuration', 'ServiceTypes'], (allServices) =>
				allServices.filter((service) => service.get('Type') !== action.serviceType)
			)
		}
		case accreditedWorkshopsActions.SET_BUSINESS_CONFIGURATION: {
			return state
				.setIn(['configuration', 'isLoading'], action.isLoading)
				.setIn(['configuration', 'configuration'], fromJS(action.configuration))
		}
		case accreditedWorkshopsActions.SET_PAYMENT_REQUEST_SETTINGS: {
			const { settings } = action
			const serviceRetentionPercent = settings.find((s) => s.code === SERVICE_RETENTION_PERCENTAGE).value
			const freightRetentionPercent = settings.find((s) => s.code === FREIGHT_RETENTION_PERCENTAGE).value
			const serviceRetentionWorkshops = settings.find(
				(s) => s.code === SERVICE_RETENTION_PERCENTAGE
			).workshopCodes
			const freightRetentionWorkshops = settings.find(
				(s) => s.code === FREIGHT_RETENTION_PERCENTAGE
			).workshopCodes
			const allowedDifferenceForInvoceAmount = settings.find((s) => s.code === INVOICING_ERROR).value
			const repareCurrentRfc = settings.find((s) => s.code === REPARE_RFC).value
			return state
				.setIn(['settings', 'serviceRetentionPercent'], Number(serviceRetentionPercent))
				.setIn(['settings', 'serviceRetention', 'percent'], Number(serviceRetentionPercent))
				.setIn(['settings', 'serviceRetention', 'workshopCodes'], fromJS(serviceRetentionWorkshops))
				.setIn(['settings', 'freightRetentionPercent'], Number(freightRetentionPercent))
				.setIn(['settings', 'freightRetention', 'percent'], Number(freightRetentionPercent))
				.setIn(['settings', 'freightRetention', 'workshopCodes'], fromJS(freightRetentionWorkshops))
				.setIn(['settings', 'allowedDifferenceForInvoceAmount'], Number(allowedDifferenceForInvoceAmount))
				.setIn(['settings', 'repareCurrentRfc'], fromJS(repareCurrentRfc))
		}
		case accreditedWorkshopsActions.SET_PAYMENT_REQUEST_CREATION_STATUS: {
			return state.setIn(['configuration', 'configuration', 'PaymentRequestCreationStatus'], action.value)
		}
		case accreditedWorkshopsActions.SET_SETTING_BY_CODE_VALUE: {
			const { retentionType, retentionValue } = action

			switch (retentionType) {
				case SERVICE_RETENTION_PERCENTAGE:
					return state.setIn(
						['configuration', 'configuration', 'ServiceRetentionPercentage'],
						Number(retentionValue)
					)
				case INVOICING_ERROR:
					return state.setIn(
						['configuration', 'configuration', 'InvoiceAmountVariationAllowed'],
						Number(retentionValue)
					)
				case REPARE_RFC:
					return state.setIn(['settings', 'repareCurrentRfc'], retentionValue)
				case PAYMENT_REQUESTS_CREATION_DAYS:
					return state.setIn(['configuration', 'configuration', 'PaymentRequestCreationDays'], retentionValue)
				case MAX_NUMBER_OF_SERVICES_IN_PAYMENT_REQUEST:
					return state.setIn(
						['configuration', 'configuration', 'MaxNumberOfServicesInPaymentRequest'],
						Number(retentionValue)
					)
				case IVA_PERCENTAGE:
					return state.setIn(['configuration', 'configuration', 'IvaPercentage'], Number(retentionValue))
				case RESICO_PERCENTAGE:
					return state.setIn(['configuration', 'configuration', 'ResicoPercentage'], Number(retentionValue))
				default:
					return state.setIn(
						['configuration', 'configuration', 'FreightRetentionPercentage'],
						Number(retentionValue)
					)
			}
		}
		case accreditedWorkshopsActions.ADD_WORKSHOP_ON_RETENTION: {
			const { retentionType, workshopCode } = action
			if (retentionType === SERVICE_RETENTION_PERCENTAGE)
				return state.updateIn(['configuration', 'configuration', 'WorkshopRetentions'], (allCodes) =>
					allCodes.push(fromJS(workshopCode))
				)
			return state.updateIn(['configuration', 'configuration', 'WorkshopRetentions'], (allCodes) =>
				allCodes.push(workshopCode)
			)
		}
		case accreditedWorkshopsActions.REMOVE_WORKSHOP_FROM_RETENTION: {
			const { retentionType, workshop } = action
			return state.updateIn(['configuration', 'configuration', 'WorkshopRetentions'], (allCodes) =>
				allCodes.filter((ws) => {
					let sameCode = ws.get('Code') === workshop.Code
					let retType = ws.get('RetentionType') === retentionType
					return !(sameCode && retType)
				})
			)
		}
		case accreditedWorkshopsActions.ADD_WORKSHOP_IVA: {
			const { rfc, workshopCode, percentage } = action
			const workshopToAdd = fromJS({ BusinessUnitRFC: rfc, WorkshopCode: workshopCode, Percentage: percentage })
			return state.updateIn(['configuration', 'configuration', 'WorkshopsWithIva'], (allCodes) =>
				allCodes.push(workshopToAdd)
			)
		}
		case accreditedWorkshopsActions.REMOVE_WORKSHOP_IVA: {
			const { workshopCode } = action
			return state.updateIn(['configuration', 'configuration', 'WorkshopsWithIva'], (allWorkshops) =>
				allWorkshops.filter((ws) => ws.get('WorkshopCode') !== workshopCode)
			)
		}
		case accreditedWorkshopsActions.ADD_WORKSHOP_RESICO: {
			const { rfc, workshopCode, percentage } = action
			const workshopToAdd = fromJS({ BusinessUnitRFC: rfc, WorkshopCode: workshopCode, Percentage: percentage })
			return state.updateIn(['configuration', 'configuration', 'WorkshopsWithResico'], (allCodes) =>
				allCodes.push(workshopToAdd)
			)
		}
		case accreditedWorkshopsActions.REMOVE_WORKSHOP_RESICO: {
			const { workshopCode } = action
			return state.updateIn(['configuration', 'configuration', 'WorkshopsWithResico'], (allWorkshops) =>
				allWorkshops.filter((ws) => ws.get('WorkshopCode') !== workshopCode)
			)
		}
		case accreditedWorkshopsActions.SET_ACCREDITED_WORKSHOPS_SERVICES_COUNT: {
			return state.set('servicesCount', action.count.total)
		}
		case accreditedWorkshopsActions.SET_ACCREDITED_WORKSHOPS_SERVICES_AMOUNT: {
			return state.set('servicesAmount', action.amount)
		}
		case accreditedWorkshopsActions.SET_ACCREDITED_WORKSHOPS_SERVICES_LIST: {
			let servicesLists = fromJS(action.services)
			return state.merge({
				services: servicesLists,
				isLoadingServices: false,
			})
		}
		case accreditedWorkshopsActions.SET_SERVICE_ORDERS_LOCK_REASONS: {
			const { data } = action
			return state.update('services', (allServices) =>
				allServices.map((service) => {
					const item = data.find((i) => i.serviceOrderToken === service.get('token'))
					return item && item.comments
						? service.set('lockReason', item.comments)
						: service.set('lockReason', '-')
				})
			)
		}
		case accreditedWorkshopsActions.CHANGE_ACCREDITED_WORKSHOPS_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case accreditedWorkshopsActions.CHANGE_ACCREDITED_WORKSHOPS_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case accreditedWorkshopsActions.CHANGE_ACCREDITED_WORKSHOPS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case accreditedWorkshopsActions.SET_FILTER_OPTIONS: {
			let options = fromJS(action.options)
			return state.setIn(['filterOptions', action.filterType], options).set('isLoadingFilterOptions', false)
		}
		case accreditedWorkshopsActions.IS_SAVING_SERVICE: {
			return state.merge({
				isSavingService: action.isSavingService,
			})
		}
		case accreditedWorkshopsActions.IS_LOADING_SERVICE: {
			return state.merge({
				isLoadingService: action.isLoadingService,
			})
		}
		case accreditedWorkshopsActions.SET_ACCREDITED_WORKSHOPS_SERVICE: {
			let service = fromJS(action.service)
			return state.merge({
				service,
				isLoadingService: false,
			})
		}
		case accreditedWorkshopsActions.SET_APPROVED_SERVICE_ITEM_SELECTED: {
			let items = state
				.get('accreditedServices')
				.get('services')
				.map((serviceItem) => {
					if (serviceItem.get('token') === action.serviceItemToken) {
						return serviceItem.set('is_selected', true)
					} else {
						return serviceItem.set('is_selected', false)
					}
				})
			let accreditedServices = state.get('accreditedServices').set('services', items)
			return state.merge({
				accreditedServices,
			})
		}
		case accreditedWorkshopsActions.IS_LOADING_PAYMENT_REQUESTS: {
			return state.set('isLoadingPaymentRequests', action.isLoadingPaymentRequests)
		}
		case accreditedWorkshopsActions.SET_PAYMENT_REQUESTS_LIST: {
			const { requests, total } = action
			const paymentRequests = fromJS(requests)
			return state.merge({
				paymentRequests,
				paymentRequestsCount: total,
				isLoadingPaymentRequests: false,
			})
		}
		case accreditedWorkshopsActions.SET_ALL_PAYMENT_REQUESTS_ERRORS: {
			const { paymentRequestErrors } = action
			return state.update('paymentRequests', (allPaymentRequests) =>
				allPaymentRequests.map((paymentRequest) => {
					const errorMessages = paymentRequestErrors
						.filter((e) => e.paymentRequestId === paymentRequest.get('id'))
						.map((e) => e.errorMessage)
					return paymentRequest.set('lastError', errorMessages.join(' | '))
				})
			)
		}
		case accreditedWorkshopsActions.IS_LOADING_PAYMENT_REQUEST_DETAIL: {
			return state.set('isLoadingService', action.isLoadingPaymentRequests)
		}
		case accreditedWorkshopsActions.SET_PAYMENT_REQUEST_DETAIL: {
			let paymentRequest = fromJS(action.paymentRequest)
			return state.merge({
				paymentRequest,
				isLoadingPaymentRequestDetail: false,
			})
		}
		case accreditedWorkshopsActions.SET_ATTEMP_ERRORS: {
			let indexOfAttemp = state
				.getIn(['paymentRequest', 'attemps'])
				.findIndex((a) => a.get('creationDate') === action.attempDateStr)
			if (indexOfAttemp > -1) {
				return state.setIn(['paymentRequest', 'attemps', indexOfAttemp, 'errors'], action.errors)
			}
			return state
		}
		case accreditedWorkshopsActions.SET_INVOICE: {
			const invoice = fromJS(action.invoice)
			const creditNote = fromJS(action.creditNote)
			return state.set('invoice', invoice).set('creditNote', creditNote)
		}
		case accreditedWorkshopsActions.SET_PAYMENT_REQUEST_AS_SENDING: {
			const indexOfPaymentRequest = state
				.getIn(['paymentRequests'])
				.findIndex((r) => r.get('token') === action.paymentRequestToken)
			if (indexOfPaymentRequest > -1) {
				return state.setIn(['paymentRequests', indexOfPaymentRequest, 'sending'], action.status)
			}
			return state
		}
		case accreditedWorkshopsActions.SET_PAYMENT_REQUESTS_ERRORS: {
			let paymentRequestErrors = fromJS(action.paymentRequestErrors)
			let serviceOrderErrors = fromJS(action.serviceOrderErrors)
			return state.merge({
				paymentRequestErrors,
				serviceOrderErrors,
				isLoadingPaymentRequestDetail: false,
			})
		}
		case accreditedWorkshopsActions.IS_LOADING_TECHNICIANS: {
			return state.set('isLoadingTechnicians', action.value)
		}
		case accreditedWorkshopsActions.SET_TECHNICIAN_LIST: {
			let technicians = fromJS(action.technicians)
			return state.merge({
				technicians,
				isLoadingTechnicians: false,
			})
		}
		case accreditedWorkshopsActions.IS_LOADING_TECHNICIAN_DETAIL: {
			return state.set('isLoadingTechnicianDetail', action.value)
		}
		case accreditedWorkshopsActions.IS_SAVING_TECHNICIAN_DETAIL: {
			return state.set('isSavingTechnicianDetail', action.value)
		}
		case accreditedWorkshopsActions.SET_TECHNICIAN_DETAIL: {
			let technicianDetail = fromJS(action.technicianDetail)
			return state.merge({
				technicianDetail,
				isLoadingTechnicianDetail: false,
			})
		}
		case accreditedWorkshopsActions.ADD_TECHNICIAN_ON_LIST: {
			let technicianDetail = fromJS(action.technicianDetail)
			return state
				.update('technicians', (technicianList) => technicianList.push(technicianDetail))
				.set('isSavingTechnicianDetail', false)
		}
		case accreditedWorkshopsActions.UPDATE_TECHNICIAN_ON_LIST: {
			let technicianDetail = fromJS(action.technicianDetail)
			return state
				.update('technicians', (technicianList) => {
					const indexOfTechnician = technicianList.findIndex(
						(technician) => technician.get('token') === technicianDetail.get('token')
					)
					return indexOfTechnician !== -1
						? technicianList.setIn([indexOfTechnician], technicianDetail)
						: technicianList
				})
				.set('isSavingTechnicianDetail', false)
		}
		case accreditedWorkshopsActions.SET_RELEASE_DATE_IN_SERVICE_ORDERS: {
			let { data } = action
			return state.update('services', (servceOrders) =>
				servceOrders.map((service) => {
					const item = data.find((i) => i.serviceOrderToken === service.get('token'))
					return item && item.creationDate ? service.set('releaseDate', moment(item.creationDate)) : service
				})
			)
		}
		case accreditedWorkshopsActions.SET_WORKSHOP_CODES: {
			const workshopCodes = fromJS(action.workshopCodes)
			return state.setIn(['configuration', 'configuration', 'WorkshopCodes'], workshopCodes)
		}
		case accreditedWorkshopsActions.SET_ACCREDITED_WORKSHOPS: {
			const workshops = fromJS(action.workshops)
			return state.set('workshops', workshops)
		}
		case accreditedWorkshopsActions.SET_TECHNICIANS_IN_ACCREDITED_WORKSHOPS: {
			return state.update('workshops', (workshops) =>
				workshops.map((workshop) => {
					const technicians = action.data.filter((i) => i.label === workshop.get('sapCode')).map((i) => i.key)
					if (technicians.length > 0) {
						return workshop.set('technicians', fromJS(technicians))
					}
					return workshop
				})
			)
		}
		case accreditedWorkshopsActions.ADD_STATUS_ITEM_TO_SECTION: {
			const { data } = action
			return state.updateIn(['configuration', 'configuration', 'OrderStatusInSections'], (allItems) => {
				if (
					allItems.some(
						(i) =>
							i.get('ServiceOrderStatus') === data.ServiceOrderStatus &&
							i.get('SectionName') === data.SectionName
					)
				) {
					return allItems
				}
				return allItems.push(fromJS(data))
			})
		}
		case accreditedWorkshopsActions.REMOVE_STATUS_ITEM_FROM_SECTION: {
			const { sectionName, status } = action
			return state.updateIn(['configuration', 'configuration', 'OrderStatusInSections'], (allItems) =>
				allItems.filter((i) => {
					let sameCode = i.get('ServiceOrderStatus') === status
					let sameSection = i.get('SectionName') === sectionName
					return !(sameCode && sameSection)
				})
			)
		}
		case accreditedWorkshopsActions.ADD_WORKSHOP: {
			const { workshop } = action
			return state.update('workshops', (workshops) => workshops.splice(0, 0, fromJS(workshop)))
		}
		default:
			return state
	}
}
