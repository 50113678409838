import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { components } from 'react-select';
import chroma from 'chroma-js';
import moment from 'moment';

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Grid, Divider } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import AlertIcon from '@material-ui/icons/ErrorOutline';
import TimeIcon from '@material-ui/icons/Schedule';

/** Components imports section */
import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';
import DatePicker from '../../../common/DateTimePicker/DatePicker';
import Select from 'react-select'

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper';
import { BACKGROUND_COLOR_CALENDAR_BUTTON } from '../../../../store/helpers/StatusColorConstants';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';
import { GetArrivalHours, GetRampsBySupplier, GetArrivalHoursRamp3, GetArrivalHoursRamp4 } from '../../../../store/helpers/SelectOptions';

class CloseAndPrintModal extends Component {

    /**
     *Creates an instance of DialogActions.
     * @param {*} props
     * @memberof DialogActions
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = this.initialState();
    }

    /**
     * Return initial state
     *
     * @returns
     * @memberof AddMaterialModal
     */
    initialState() {
        return ({
            estimated_date: null,
            estimated_time: null,
            ramp_number: "",
            unavailableHours: null
        })
    }

    /**
     * Handle update properties to set data in local state
     *
     * @param {*} property
     * @param {*} data
     * @memberof AddMaterialModal
     */
    updateProperty(property, data) {
        this.setState({
            [property]: data,
        }, () => {
            if (property === "ramp_number")
                this.setState({
                    estimated_date: null,
                    estimated_time: null,
                    unavailableHours: null
                })
            if (property === "estimated_date")
                this.setState({
                    estimated_time: null,
                    unavailableHours: null
                })
        });

    }

    /**
     * Handle  Close or cancel event
     *
     * @memberof AddMaterialModal
     */
    handleCloseCancel() {
        this.setState(this.initialState(),
            () => this.props.onClose());
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof AddMaterialModal
     */
    isValidToSave() {
        if (!IsNullOrEmpty(this.state.ramp_number)
            && !IsNullOrEmpty(this.state.estimated_date))
            return true;
        if (!IsNullOrEmpty(this.state.ramp_number)
            && !IsNullOrEmpty(this.state.estimated_date)
            && !IsNullOrEmpty(this.state.estimated_time))
            return true;
        return false
    }

    /**
     * Method to call function to close delivery order and close modal
     *
     * @memberof CloseAndPrintModal
     */
    handleClosePrint() {
        this.props.onCloseAndPrint(this.state)
            .then((response) => {
                this.handleCloseCancel();
            })
    }

    handleCheckAvailability() {
        this.props.onCheckAvailability(this.state)
            .then((response) => {
                this.setState({
                    unavailableHours: response
                })
            })
    }

    /**
     * Render datetime component
     *
     * @param {*} key
     * @param {*} translationId
     * @param {number} [xs=12]
     * @returns
     * @memberof AddModal
     */
    renderDateTime(key, translationId, xs = 12) {
        return (
            <Grid item xs={xs} className={this.props.classes.textField}>
                <Typography variant="caption" color={'textSecondary'}>
                    <Translate id={translationId} />*
                </Typography>
                <DatePicker
                    onChangeRange={(event) => { this.updateProperty(key, event) }}
                    startDate={this.state[key] ? this.state[key] : null}
                    minDate={moment()}
                />
            </Grid>
        )
    }

    /**
     * Render select time component
     *
     * @param {*} key
     * @param {*} translationId
     * @param {number} [xs=12]
     * @returns
     * @memberof CloseAndPrintModal
     */
    renderSelect(key, translationId, options, xs = 12, customDropdown = false) {
        let selectedValue = options !== null ? options[this.state[key] ? this.state[key] : ''] : ''
        return (
            <Grid item xs={xs} className={this.props.classes.textField}>
                <Typography variant="caption" color={'textSecondary'}>
                    <Translate id={translationId} />*
                </Typography>
                <Select
                    value={selectedValue}
                    options={options}
                    onChange={(event) => this.updateProperty(key, event.value)}
                    styles={colouredOptions}
                    components={customDropdown ? { DropdownIndicator, Option } : {}}
                    menuPortalTarget={document.parentNode}
                    menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
                    menuPlacement={'auto'}
                />
                {key === "ramp_number" && this.state[key] === 3 &&
                    <Typography variant="caption" color="textSecondary">
                        <AlertIcon color="inherit" /><strong><Translate id={"deliveryOrderModule.rampUseMessage"} /></strong>
                    </Typography>}
                {key === "ramp_number" && this.state[key] === 4 &&
                    <Typography variant="caption" color="textSecondary">
                        <AlertIcon color="inherit" /><strong><Translate id={"deliveryOrderModule.fourthRampUseMessage"} /></strong>
                    </Typography>}
            </Grid>
        )
    }

    render() {
        const { classes } = this.props;
        let arrivalHours;
        if (this.state["ramp_number"] === 3)
            arrivalHours = GetArrivalHoursRamp3(this.state.unavailableHours !== null ? this.state.unavailableHours : []);
        else if (this.state["ramp_number"] === 4)
            arrivalHours = GetArrivalHoursRamp4(this.state.unavailableHours !== null ? this.state.unavailableHours : []);
        else
            arrivalHours = GetArrivalHours(this.state.unavailableHours !== null ? this.state.unavailableHours : []);
        let numberRamps = GetRampsBySupplier(this.props.partnership);
        let hasAvailableSchedules = this.state.unavailableHours !== null && arrivalHours.filter(hour => { return !hour.hasOwnProperty('isDisabled') }).length > 0;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id={this.props.title} />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <Grid container spacing={0} className={this.props.classes.modalFooter}>
                        {!this.isValidToSave() &&
                            <Grid item xs={12} className={this.props.classes.checkBox}>
                                <Typography variant="caption" color='error'>
                                    {<Translate id="common.requiredFields" />}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <DialogActions
                                view={SHOW_EDIT_VIEW}
                                classes={classes}
                                onCancel={() => this.handleCloseCancel()}
                                onClose={() => this.handleCloseCancel()}
                                onSaveItem={hasAvailableSchedules ? this.handleClosePrint : this.handleCheckAvailability}
                                isSaving={this.props.isSaving}
                                saveButtonTranslate={hasAvailableSchedules ? "deliveryOrderModule.closeAndSendDeliveryOrder" : "deliveryOrderModule.checkAvailability"}
                                canBeSaved={this.isValidToSave()}
                            />
                        </Grid>
                    </Grid>
                }>
                <Grid container spacing={24} className={this.props.classes.modal} justify="space-between">
                    <Grid item xs={12} >
                        <Typography variant="body1">
                            <AlertIcon color="primary" style={{ marginRight: '.3em' }} />
                            <Translate id={!hasAvailableSchedules ? "deliveryOrderModule.checkAvailabilityMessage" : "deliveryOrderModule.closeMessage"} />
                        </Typography>
                        <Divider />
                    </Grid>
                    {this.renderSelect("ramp_number", "deliveryOrderModule.rampNumber", numberRamps, 5)}
                    {this.renderDateTime("estimated_date", "deliveryOrderModule.estimatedArrivalDate", 5)}
                    {hasAvailableSchedules && this.renderSelect("estimated_time", "deliveryOrderModule.estimatedArrivalTime", arrivalHours, 5, true)}
                </Grid>
            </Dialog>
        );
    }
}

/** Custom styles to set background color of options in select of hours */
const colouredOptions = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color ? data.color : "white");
        return {
            ...styles,
            backgroundColor:
                isFocused
                    ? data.color
                        ? color.alpha(0.5).css()
                        : "#eaeaea"
                    : data.color,
            color: chroma.contrast(color, 'white') > 2
                ? 'white'
                : 'black',
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
        };
    },
    indicatorSeparator: () => null,
    menu: (base) => ({ ...base, zIndex: 62, maxHeight: 150 }),
    menuList: (base) => ({ ...base, maxHeight: 150, paddingTop: 0 }),
    // menu: (base) => ({ ...base, zIndex: 1000, height: 150 }),
    // menuList: (base) => ({ ...base, height: 150, paddingTop: 0 }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
    indicatorContainer: (base) => ({ ...base, maxWidth: 28, padding: 0 }),
    dropdownIndicator: styles => ({ ...styles, backgroundColor: BACKGROUND_COLOR_CALENDAR_BUTTON, color: 'white' })
};

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <TimeIcon style={{ color: 'white' }} />
        </components.DropdownIndicator >
    );
};

const Option = props => {
    const { data } = props;
    return (
        <components.Option {...props}>
            {data.isDisabled &&
                <Tooltip title={<Translate id={data.messageLock} />} placement="right">
                    <Typography align="center">
                        {data.label}
                    </Typography>
                </Tooltip>}
            {!data.isDisabled &&
                <Typography align="center">
                    {data.label}
                </Typography>
            }
        </components.Option>
    );
};

const styles = theme => ({
    modal: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
        maxWidth: "fit-content"
    },
    modalFooter: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    textField: {
        // marginBottom: theme.spacing.unit * 2
    },
    checkBox: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    }
});

export default withRouter(withStyles(styles, { withTheme: true })(CloseAndPrintModal));