import { fromJS } from 'immutable'

import * as questionnairesActions from './QuestionnairesAction'

/**
 *  Initial state questionnaires
 */
export const questionnairesInitialState = fromJS({
	sortCriteria: {
		by: 'creation_date',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	isLoadingQuestionnaires: true,
	questionnaires: null,
	questionnairesCount: 0,
	isLoadingQuestionnaire: true,
	isSavingQuestionnaire: false,
	questionnaire: null,
	isLoadingTemplates: false,
	templates: null,
	isSavingTemplate: false,
	schudeleDatesHistorys: [],
})

export default function questionnaireReducer(state = questionnairesInitialState, action) {
	switch (action.type) {
		case questionnairesActions.IS_LOADING_QUESTIONNAIRES_INDEX: {
			return state.merge({
				isLoadingQuestionnaires: action.isLoadingIndex,
			})
		}
		case questionnairesActions.SET_SCHUDELE_DATE_HISTORYS: {
			let schudeleDatesLists = fromJS(action.data)
			return state.merge({
				schudeleDatesHistorys: schudeleDatesLists,
				isLoadingQuestionnaires: false,
			})
		}
		case questionnairesActions.SET_QUESTIONNAIRES_LIST: {
			let questionnairesLists = fromJS(action.questionnaires)
			return state.merge({
				questionnaires: questionnairesLists,
				isLoadingQuestionnaires: false,
			})
		}
		case questionnairesActions.SET_QUESTIONNAIRES_COUNT: {
			return state.merge({
				questionnairesCount: action.count,
			})
		}
		case questionnairesActions.CHANGE_QUESTIONNAIRES_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case questionnairesActions.CHANGE_QUESTIONNAIRES_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case questionnairesActions.CHANGE_QUESTIONNAIRES_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case questionnairesActions.IS_SAVING_QUESTIONNAIRE: {
			return state.merge({
				isSavingQuestionnaire: action.isSavingQuestionnaire,
			})
		}
		case questionnairesActions.IS_LOADING_QUESTIONNAIRE: {
			return state.merge({
				isLoadingQuestionnaire: action.isLoadingQuestionnaire,
			})
		}
		case questionnairesActions.SET_QUESTIONNAIRE: {
			let questionnaire = fromJS(action.questionnaire)
			return state.merge({
				questionnaire,
				isLoadingQuestionnaire: false,
			})
		}
		case questionnairesActions.UPDATE_SECTION_QUESTION_IN_LIST: {
			return state.updateIn(['questionnaire', 'sections'], (allSections) => {
				let index = allSections.findIndex((section) => {
					return section.get('token') === action.sectionToken
				})
				let questionToChange = allSections.get(index)
				let updatedQuestionList = questionToChange.get('section_questions').map((item) => {
					if (item.get('token') === action.updatedQuestion.token) {
						return fromJS(action.updatedQuestion)
					} else {
						return item
					}
				})
				let updatedQuestion = questionToChange.set('section_questions', updatedQuestionList)
				return allSections.setIn([index], updatedQuestion)
			})
		}
		case questionnairesActions.UPDATE_OPTION_MULTIPLE_IN_QUESTION_LIST: {
			return state.updateIn(['questionnaire', 'sections'], (allSections) => {
				let index = allSections.findIndex((section) => {
					return section.get('token') === action.sectionToken
				})
				let questionToChange = allSections.get(index)
				let updatedQuestionList = questionToChange.get('section_questions').map((item) => {
					if (item.get('token') === action.question.token) {
						return fromJS(action.question)
					} else {
						return item
					}
				})
				let updatedQuestion = questionToChange.set('section_questions', updatedQuestionList)
				return allSections.setIn([index], updatedQuestion)
			})
		}
		case questionnairesActions.IS_LOADING_TEMPLATES: {
			return state.merge({
				isLoadingTemplates: action.isLoading,
			})
		}
		case questionnairesActions.SET_TEMPLATES_LIST: {
			let templatesLists = fromJS(action.templates)
			return state.merge({
				templates: templatesLists,
				isLoadingTemplates: false,
			})
		}
		case questionnairesActions.IS_SAVING_SECTION_TEMPALTE: {
			return state.merge({
				isSavingTemplate: action.isSavingTemplate,
			})
		}
		case questionnairesActions.ADD_SECTION_IN_TEMPLATE: {
			let sectionTemplateAdd = fromJS(action.sectionTemplate)
			let totalPercentage = action.sectionTemplate.total_porcentaje_questionnaire
			let stateUpdate = state.updateIn(['questionnaire', 'sections'], (actions) =>
				actions.push(sectionTemplateAdd)
			)
			return stateUpdate
				.setIn(['questionnaire', 'total_porcentaje'], totalPercentage)
				.set('isSavingTemplate', false)
		}
		case questionnairesActions.UPDATE_TOTAL_AUDIT: {
			let totalAudit = action.question.total_audit_rating
			return state.setIn(['questionnaire', 'rating_audit_total'], totalAudit)
		}
		case questionnairesActions.UPDATE_SECTION_IN_TEMPLATE: {
			let stateUpdate = state.updateIn(['questionnaire', 'sections'], (allSections) => {
				let index = allSections.findIndex((item) => {
					return item.get('token') === action.section.token
				})

				return allSections.setIn([index], fromJS(action.section))
			})
			let totalPercentage = stateUpdate
				.getIn(['questionnaire', 'sections'])
				.reduce((sum, section) => sum + section.get('percentage_section'), 0)
			return stateUpdate
				.setIn(['questionnaire', 'total_porcentaje'], totalPercentage)
				.set('isSavingTemplate', false)
		}
		case questionnairesActions.REMOVE_SECTION_IN_TEMPLATE: {
			let updatedState = state.updateIn(['questionnaire', 'sections'], (allSections) => {
				let index = allSections.findIndex((item) => {
					return item.get('token') === action.sectionToken
				})
				return allSections.delete(index)
			})
			let totalPercentage = updatedState
				.getIn(['questionnaire', 'sections'])
				.reduce((sum, section) => sum + section.get('percentage_section'), 0)
			return updatedState
				.setIn(['questionnaire', 'total_porcentaje'], totalPercentage)
				.set('isSavingTemplate', false)
		}
		case questionnairesActions.ADD_ACTION_PLANT: {
			let actionPlantToAdd = fromJS(action.actionPlant)
			return state.updateIn(['questionnaire', 'actions_plant'], (actions) =>
				addActionPlant(actions, actionPlantToAdd)
			)
		}
		case questionnairesActions.REMOVE_ACTION_PLANT: {
			return state.updateIn(['questionnaire', 'actions_plant'], (actions) =>
				actions.filter((x) => x.get('token') !== action.token)
			)
		}
		default:
			return state
	}
}

const addActionPlant = (allActionPlant, actionPlant) => {
	let indexOfActionPlant = allActionPlant.findIndex((d) => d.get('token') === actionPlant.get('token'))
	if (indexOfActionPlant >= 0) {
		return allActionPlant.setIn([indexOfActionPlant], actionPlant)
	}
	return allActionPlant.push(actionPlant)
}
