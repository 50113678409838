import React, { Component } from 'react';
import {Translate} from "react-localize-redux";
import autoBind from 'auto-bind'

/** Material-UI imports section */
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

/**
 * Component tracking partial tap 
 */
export default class TrackingPartial extends Component{

    /**
     * TrackingPartial
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
    }

    /**
     * Method to get getQualityEvents
     */
    getQualityEvents(){
        let qualityEevents = this.props.qualityFailure ? this.props.qualityFailure.quality_events ? this.props.qualityFailure.quality_events : [] : [];
        return (
            qualityEevents.map(event => {
                if (event.event_type === 1) {
                    let warehouseWntry = event.warehouse_entry;
                   return(<p key={event.token}><br />
                        <span class="text-success"> <i class="fa fa-truck"></i> | <Translate id="qualityfailurereport.entryWarehouse"/></span>
                            <br /><span><Translate id="qualityfailurereport.folioEntry"/>: <strong>{warehouseWntry.sap_folio}</strong>  </span>         
                            <hr />
                        </p>
                    )
                }
                return ("")

            })
        )
    }

    /**
     * Render tacking 
     */
    render(){
        let qualityFailure = this.props.qualityFailure;
        let containmentAction = qualityFailure.containment_action;
        return(
            <div>
                <Paper> 
                    <Divider /><br/>
                    <h4 id="dealing-with-specificity"><Translate id="qualityfailurereport.actionContainer"/></h4><br/>
                    {containmentAction && <div className="alert alert-secondary" role="alert">
                        <p><Translate id="qualityfailurereport.messageToMark"/> <code className="highlighter-rouge"> {containmentAction.utilize} {containmentAction.color} </code>
                            <Translate id="qualityfailurereport.locationIn"/> <code className="highlighter-rouge"> {containmentAction.location} </code>
                            <Translate id="qualityfailurereport.markIn"/>
                            <code className="highlighter-rouge"> {containmentAction.identify_by}  </code>  <Translate id="qualityfailurereport.legendMark"/> {containmentAction.legend}
                        </p>
                    </div>}<br/>

                    <Divider />
                    <br/>
                    <p> <strong>{qualityFailure.get_successfull_warehouse_entries_counter} {" "}</strong><Translate id="qualityfailurereport.entryAdd"/> 
                    {" "}{qualityFailure.warehouse_entries_required_to_close} <Translate id="qualityfailurereport.requered"/> </p>
                    <div className="progress">
                        <div className="progress-bar progress-bar-info" role="progressbar" 
                            aria-valuenow={qualityFailure.get_successfull_warehouse_entries_counter} aria-valuemin="0"
                            aria-valuemax={qualityFailure.warehouse_entries_required_to_close} style={{ minWidth: 40, width: qualityFailure.get_progress_percentage+"%"  }} >        
                        </div>
                    </div><br/>
                    
                   {this.getQualityEvents()}

                </Paper>
            </div>
        )
    }
}

