import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import CatalogHeader from './components/CatalogHeader'
import BallotIcon from '@material-ui/icons/Ballot'
import { Translate } from 'react-localize-redux'
import * as selector from '../../../store/transportTender/transportTenderSelector'
import * as action from '../../../store/transportTender/transportTenderAction'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../../common/DataTable'

import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import queryString from 'query-string'
import LoadingPage from '../../common/LoadingPage'
import { isTransportTenderAdministrator } from '../../../store/helpers/TransportTenderHelper'
import { getLoggedUserSelector } from '../../../store/profile/ProfileSelectors'
import DialogCatalogUploadMassive from './components/DialogCatalogUploadMassive'
import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

const RequirementsCarriers = () => {
	let history = useHistory()
	let location = useLocation()
	const dispatch = useDispatch()
	const queryParams = queryString.parse(location.search)

	const isLoadig = useSelector((state) => selector.getIsLoadingSavingSelector(state))
	const transportTenderinfo = useSelector((state) => selector.getTransportTenderfilterSelector(state))
	const loggetUser = useSelector((state) => getLoggedUserSelector(state))
	const errorRequirementsCarrierFile = useSelector((state) => selector.getErrorRequirementsCarrierFile(state))

	const [openDialog, setOpenDialog] = useState(false)
	const [file, setfile] = useState([])
	const userRoles = loggetUser && loggetUser.grantedRoles ? loggetUser.grantedRoles : []
	const isTransportTenderAdminitsrator = isTransportTenderAdministrator(userRoles)

	const onDownloadTemplateFilled = () => {
		return dispatch(action.downloadRequirementsCarrierTemplate())
	}
	const canBeSaved = () => {
		if (errorRequirementsCarrierFile.length > 0) return false
		if (file.length === 0) return false
		return true
	}
	const onSave = () => {
		dispatch(action.uploadRequirementsCarrierTemplate(file))
			.then(() => {
				setOpenDialog(false)
				dispatch(action.loadRequirementsCarrierCatalog(queryParams))
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}
	const onChangeQuery = (query) => {
		if (query) queryParams.query = query
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onChangePage = (page, rowsPerPage) => {
		queryParams.pageNumber = page + 1
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onDeleteRequiriment = (requiriment) =>
		dispatch(action.deleteRequirementsCarrier(requiriment.id)).then(() =>
			dispatch(action.loadRequirementsCarrierCatalog(queryParams))
		)
	useEffect(() => {
		dispatch(action.loadRequirementsCarrierCatalog(queryParams))
	}, [dispatch, location.search])

	useEffect(() => {
		if (file.length > 0) {
			dispatch(action.validateRequirementsCarrierTemplate(file))
		}
	}, [file])

	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'options')
			return (
				<div>
					<IconButton onClick={() => onDeleteRequiriment(item)}>
						<Delete fontSize='small' color='primary' />
					</IconButton>
				</div>
			)
	}
	return (
		<div>
			<CatalogHeader
				setOpenDialog={setOpenDialog}
				icon={<BallotIcon />}
				isInternalUse={true}
				title={<Translate id={'carrierTenders.configuration.requirementsCarriers'} />}
				isAdministrator={isTransportTenderAdminitsrator}
				showSearch
				queryParams={queryParams}
				onChangeSearchValue={onChangeQuery}
			/>
			{isLoadig ? (
				<LoadingPage />
			) : (
				<DataTable
					data={transportTenderinfo.requirementsCarriersPagination.items}
					configuration={TableConfiguration()}
					onRenderCellItem={onRenderCellItem}
					showFilters
					isIndex
					page={transportTenderinfo.requirementsCarriersPagination.pageNumber}
					rowsPerPage={transportTenderinfo.requirementsCarriersPagination.pageSize}
					totalRows={transportTenderinfo.requirementsCarriersPagination.total}
					onChangePage={onChangePage}
				/>
			)}
			<DialogCatalogUploadMassive
				title={'Importación masiva de requisitos para clientes'}
				typeLoadTranslate={'massiveRequirementsCarriers'}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				file={file}
				setfile={setfile}
				onDownloadTemplateFilled={onDownloadTemplateFilled}
				isLoading={isLoadig}
				canBeSaved={canBeSaved()}
				errorList={errorRequirementsCarrierFile}
				onSave={onSave}
			/>
		</div>
	)
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: <Translate id={'carrierTenders.deals.requirementsCarriers.table.client'} />,
			dataSource: 'clientName',
		},
		{
			header: <Translate id={'carrierTenders.deals.requirementsCarriers.table.requirementDescription'} />,
			dataSource: 'requirementDescription',
		},
		{
			header: <Translate id={'carrierTenders.deals.requirementsCarriers.table.options'} />,
			dataSource: 'options',
		},
	]
	return { columns }
}
const styles = (theme) => ({
	addIcon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		borderRadius: '16px',
		margin: '0px 5px 0px 10px',
	},
	titleTabs: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	containerTitle: {
		margin: '15px',
	},
})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(RequirementsCarriers)))
