///Import react section
import { useEffect } from 'react';
import { useSelector, useDispatch, } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { getTranslate } from "react-localize-redux";

/**Import actions sections */
import {
    setToasterOptions
} from '../../../store/indirectqualifications/IndirectqualificationsActions'
import {
    loadAllRequestsFromBackEnd,
    addRequest,
    getRequestCount,
    changeStatus,
} from '../../../store/warehouseWindows/WarehouseWindowsActions'
/**Import helpers sections */
import { converListToArray } from '../../../store/helpers/ListHelper'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
import { ADD_DUPLICATED_BIDDING_NAME_MESSAGE } from '../../../store/helpers/MessagesError'

export const useRequestsCustomHookIndex = () => {
    /**use selector information */
    const requests = useSelector((state) => state.warehouseWindows.get('requests') ? converListToArray(state.warehouseWindows.get('requests')) : [])
    const translate = useSelector((state) => getTranslate(state.localize))

    /**Render hooks section */
    const dispatch = useDispatch()
    let location = useLocation()

    /**Use effect section */
    useEffect(() => {
        const queryParams = queryString.parse(location.search)
        dispatch(loadAllRequestsFromBackEnd(queryParams, translate))
        dispatch(getRequestCount())
    }, [dispatch, location.search])

    const onAddRequest = (model) => {
        const queryParams = queryString.parse(location.search)
        return dispatch(addRequest(model, translate))
            .then(() => {
                dispatch(loadAllRequestsFromBackEnd(queryParams, translate))
                dispatch(getRequestCount())
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                }))
            })
            .catch((response) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: (response && !IsNullOrEmpty(response.data) && typeof response.data === 'string' && response.data.includes("Maximum folios created")) ? "biddingsModule.errorMessages.addBiddingError" : "common.errorToSave",
                    toasterVariant: "error",
                    name: model.name
                }))
            })
    }

    const onChangeStatus = (item, newStatus) => {
        return dispatch(changeStatus(item, newStatus, translate))
            .then(() => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                }))
            })
            .catch((response) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: (response && !IsNullOrEmpty(response.data) && typeof response.data === 'string' && response.data.includes(ADD_DUPLICATED_BIDDING_NAME_MESSAGE)) ? "biddingsModule.errorMessages.addBiddingError" : "common.errorToSave",
                    toasterVariant: "error",
                    name: item.CreatorName
                }))
            })
    }

    return { onAddRequest, onChangeStatus, requests }
}