/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';

export const START_LOADING_WORKERS = "START_LOADING_WORKERS";
export const SET_WORKERS_LIST = "SET_WORKERS_LIST";
export const IS_SAVING_WORKER = "IS_SAVING_WORKER";
export const UPDATE_WORKER = "UPDATE_WORKER";
export const ADD_WORKER_TO_LIST = "ADD_WORKER_TO_LIST";
export const CHANGE_WORKERS_SORT_CRITERIA = "CHANGE_WORKERS_SORT_CRITERIA"
export const CHANGE_WORKERS_SORT_QUERY = "CHANGE_WORKERS_SORT_QUERY"
export const CHANGE_WORKERS_PAGINATION = "CHANGE_WORKERS_PAGINATION"
export const SET_WORKERS_COUNT = "SET_WORKERS_COUNT";

/**
 * Action start loading workers
 * @param {*} isLoading 
 */
function isLoading(isLoading) {
    return {
        type: START_LOADING_WORKERS,
        isLoading: isLoading
    }
}

/**
 * Action to set workers list 
 * @param {*} comapnies 
 */
function setWarehousesWorkerList(workers) {
    return {
        type: SET_WORKERS_LIST,
        workers: workers
    }
}

/**
 * Action to is saving worker data
 * @param {*} isSaving 
 */
function isSavingWorker(isSaving) {
    return {
        type: IS_SAVING_WORKER,
        isSaving: isSaving
    }
}

/**
 * Action from update worker
 * @param {*} worker 
 */
function updateWorker(worker) {
    return {
        type: UPDATE_WORKER,
        worker: worker
    }
}

/**
 * Action from add worker to list
 */
function addWorkerToList(worker) {
    return {
        type: ADD_WORKER_TO_LIST,
        worker: worker
    }
}

/**
 * Action reducer to set the workers counter
 *
 * @param {*} count
 * @returns
 */
function setWorkersCount(count) {
    return {
        type: SET_WORKERS_COUNT,
        count
    }
}

/**
 * Get all Warehouse Workers and from backend by company
 */
export function loadWarehouseWorkersFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {

        let warehouseWorkers = getState().warehouseWorkers.get("warehouseWorkers");
        if (warehouseWorkers && warehouseWorkers.size > 0) {
            return Promise.resolve();
        }

        dispatch(isLoading(true));
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/workers`;

        let data = {
            CompanyToken: selectedCompany.get("token"),
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setWarehousesWorkerList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoading(false));
                console.error("Error getting the workers list", error.response)
            })
    }
}

/**
 * Action to create Worker 
 * @param {*} company 
 */
export function createWorkerData(worker) {
    return function (dispatch, getState) {

        let selectedCompany = getState().profile.get("selectedCompany");
        dispatch(isSavingWorker(true));
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/${selectedCompany.get("token")}/workers`;
        let data = {
            Name: worker.name,
            Status: worker.status,
        }
        return axios.post(endPoint, data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(addWorkerToList(response.data))
                }
                else {
                    dispatch(isSavingWorker(false));
                }
            })
            .catch((error) => {
                console.error("Error create worker", error.response);
                dispatch(isSavingWorker(false));
                return Promise.reject();
            })
    }
}

/**
 * Action to update worker data
 * @param {*} provider 
 */
export function updateWorkerData(worker) {
    return function (dispatch, getState) {
        let selectedCompany = getState().profile.get("selectedCompany");
        dispatch(isSavingWorker(true));
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/${selectedCompany.get('token')}/workers/${worker.token}`;
        let data = {
            Name: worker.name,
            Status: worker.status,
        }
        return axios.put(endPoint, data)
            .then((response) => {
                dispatch(updateWorker(response.data))
            })
            .catch((error) => {
                console.error("Error update worker", error.response);
                dispatch(isSavingWorker(false));
                return Promise.reject();
            })
    }
}

/**
 * Action to serch Users warehouse by Query 
 * @param {*} query 
 */
export function searchByUsersWarehouseByQuery(){
    return function(dispatch, getState){

        dispatch(isLoading(true));
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/companies/workers`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
        }

        return axios.post(endPoint, data, configuration)
        .then((response) => {
            if (response.status === 200) {
                dispatch(isLoading(false));
                return Promise.resolve(response.data);
            }
        })
        .catch((error) => {
            dispatch(isLoading(true));
            console.error("error ", error);
        });        
    }
}

/**
 * Action load users quality from backend
 */
export function loadUsersQualityFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {

        dispatch(isLoading(true));
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/workers/all`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setWarehousesWorkerList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoading(false));
                console.error("Error getting the workers users  list", error.response)
            })
    }
}

/**
 * Get UsersQuality's count
 * DONE
 * @export
 * @returns
 */
export function getUsersQualityCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().warehouseWorkers ? getState().warehouseWorkers.get('usersQualityCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/worker/count`;
        let data ={
            CompanyToken: selectedCompany.get("token")
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setWorkersCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the workers users count", error.response)
            })
    }
}

/**
 * Action to add user
 *
 * @export
 * @param {*} name
 * @returns
 */
export function addUserData(name) {
    return function (dispatch, getState) {
        dispatch(isSavingWorker(true));
        let selectedCompanyToken = getState().profile.get("selectedCompany").get('token');
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/worker/`;
        let data = {
            Name: name,
            CompanyToken: selectedCompanyToken,
        }
        return axios.post(endPoint, data)
            .then((response) => {
                dispatch(addWorkerToList(response.data))
            })
            .catch((error) => {
                console.error("Error to add user", error.response);
                dispatch(isSavingWorker(false));
                return Promise.reject();
            })
    }
}


/**
 * Action to serch Users Qualit by Query 
 * @param {*} query 
 */
export function searchByUsersQualityByQuery(){
    return function(dispatch, getState){

        dispatch(isLoading(true));
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/workers/all`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            GetAllReports: true,
        }
        return axios.post(endPoint, data, configuration)
        .then((response) => {
            if (response.status === 200) {
                dispatch(isLoading(false));
                return Promise.resolve(response.data);
            }
        })
        .catch((error) => {
            dispatch(isLoading(true));
            console.error(error);
        });        
    }
}

/**
 * Action to update non conformity failure
 *
 * @export
 * @param {*} user
 * @returns
 */
export function updateUserQualityData(user) {

    return function (dispatch, getState) {

        dispatch(isSavingWorker(true));
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/worker`;
        let data = {
            Token: user.token,
            Name: user.name,
            Status: user.status,
        }
        return axios.put(endPoint, data)
            .then((response) => {
                dispatch(updateWorker(response.data))
                dispatch(isSavingWorker(false));
            })
            .catch((error) => {
                console.error("Error to update user common quality", error.response);
                dispatch(isSavingWorker(false));
                return Promise.reject();
            })
    }
}
