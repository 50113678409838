//import from react
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from "react-localize-redux";

//Imports from material
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton, Button, Typography } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { COLOR_STATUS_DANGER, COLOR_STATUS_GRAY } from '../../../../store/helpers/StatusColorConstants';
import { useExpedientSupplierCustomHookShow } from '../../customHooks/UseCustomHookShow';

const SupplierDeleteButton = (props) => {

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [disable, setdisable] = useState(false)
    const [color, setcolor] = useState(COLOR_STATUS_DANGER)
    const { classes, expedientSupplier, supplier, onRemoveExpedientSupplier } = props
    const { onLoadExpedientSupplier } = useExpedientSupplierCustomHookShow()

    const handleOpen = () => {
        setOpenDeleteDialog(true)
    }
    const handleClose = () => {
        setOpenDeleteDialog(false)
    }

    const buttonProces = () => {
        setdisable(true)
        setcolor(COLOR_STATUS_GRAY)
        onRemoveExpedientSupplier(expedientSupplier.token, supplier).then(
            (response) => {
                if (response === true) {
                    handleClose();
                    onLoadExpedientSupplier(expedientSupplier.token)
                }
                else {
                    setdisable(false)
                    setcolor(COLOR_STATUS_GRAY)
                }
            }
        );
    }
    return (
        <div>
            <IconButton className={classes.removeIcon}
                onClick={() => handleOpen()}>
                <DeleteIcon />
            </IconButton>
            <Dialog
                open={openDeleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" >
                    <Typography color="primary" variant='h6'>
                        {<Translate id={'expedientSupplier.removeSupplier'} />}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Translate id={'expedientSupplier.confirmSupplierRemove'} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary"><Translate id={'common.cancel'} /></Button>
                    <Button disabled={disable} onClick={buttonProces} style={{ color: color }} autoFocus>
                        <Translate id={'common.delete'} />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};

SupplierDeleteButton.propTypes = {
    expedientSupplier: PropTypes.object.isRequired,
    supplier: PropTypes.object.isRequired,
    onRemoveExpedientSupplier: PropTypes.func,
}
SupplierDeleteButton.defaultProps = {
    expedientSupplier: {},
    supplier: {},
    onRemoveExpedientSupplier: () => console.warn("onRemoveExpedientSupplier not defined"),
}
export default SupplierDeleteButton;