import React, {Component} from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';

/**
 * Dialog component
 *
 * @export
 * @class Dialog
 * @extends {Component}
 */
class DialogView extends Component {

    /**
     * Render
     *
     * @returns
     * @memberof Dialog
     */
    render() {
        const { classes } = this.props;
        let open = this.props.open ? this.props.open : false;
        return (
            <Dialog
                open = {open}
                onClose = {this.props.onClose}
            >
                <div className={classes.dialogRoot}>
                    <div className={classes.dialogHeader}>
                        {this.props.header}
                    </div>
                    <div className={classes.dialogContent}>
                        {this.props.children}
                    </div>
                    <div className={classes.dialogActions}>
                        {this.props.actions}
                    </div>
                </div>
            </Dialog>
        );
    }
}

DialogView.propTypes = {
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    header: propTypes.element.isRequired,
    children: propTypes.element,
    actions: propTypes.element
};

DialogView.defaultProps = {
    open: false,
}


/**
 * Styles definition
 */
const dialogStyles = {
    dialogRoot: {
        display: 'flex',
        gridTemplateRows: 'auto 1fr auto',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    dialogHeader: {
        padding: "5px",
        gridRow: "1 / 2",
        borderBottom: "1px solid #dadce0"
    },
    dialogContent: {
        padding: "10px",
        gridRow: "2 / 3",
        //overflowY: "auto"
    },
    dialogActions: {
        padding: "5px",
        borderTop: "1px solid #dadce0",
        gridRow: "3 / 4"
    }
}
export default withStyles(dialogStyles,{withTheme:true})(DialogView);