/** Helpers import section */
import axios from 'axios';
import {getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserParameters,
    getAuthorizedRequestConfigurationUserFiles } from '../helpers/SessionHelper';

export const START_LOADING_DEVOLUTIONS = "START_LOADING_DEVOLUTIONS";
export const SET_DEVOLUTIONS_LIST = "SET_DEVOLUTIONS_LIST";
export const CHANGE_DEVOLUTION_SORT_CRITERIA = "CHANGE_DEVOLUTION_SORT_CRITERIA";
export const CHANGE_DEVOLUTIONS_QUERY = "CHANGE_DEVOLUTIONS_QUERY";
export const CHANGE_DEVOLUTIONS_PAGINATION = "CHANGE_DEVOLUTIONS_PAGINATION";
export const SET_DEVOLUTIONS_COUNT = "SET_DEVOLUTIONS_COUNT";
export const IS_SAVING = "IS_SAVING";
export const IS_LOADING = "IS_LOADING";
export const SET_DEVOLUTION = "SET_DEVOLUTION";
export const START_INVOICES_LIST_BY_CODE = "START_INVOICES_LIST_BY_CODE";
export const SET_INVOICES_LIST_BY_CODE = "SET_INVOICES_LIST_BY_CODE";
export const DELETE_INVOICE_IN_ITEM_DEVOLUTION = "DELETE_INVOICE_IN_ITEM_DEVOLUTION";
export const DELETE_ITEM_DEVOLUTION = "DELETE_ITEM_DEVOLUTION";
export const IS_LOADING_ALL_DEVOLUTIONS_REPORTS = "IS_LOADING_ALL_DEVOLUTIONS_REPORTS";
export const ADD_FILE_DEVOLUTION = "ADD_FILE_DEVOLUTION";
export const REMOVE_FILE_DEVOLUTION = "REMOVE_FILE_DEVOLUTION";

/**
 * Add file to state
 *
 * @param {*} files
 * @returns
 */
 function addFileInDevolution(files) {
    return {
        type: ADD_FILE_DEVOLUTION,
        files
    }
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFileFromDevolution(fileName) {
    return {
        type: REMOVE_FILE_DEVOLUTION,
        fileName
    }
}

/**
 * Action start loading devolutions
 * @param {*} isLoading 
 */
function isLoadingDevolutions(isLoading){
    return {
        type: START_LOADING_DEVOLUTIONS,
        isLoading:isLoading
    }
}

/**
 * Action to set devolutions  list 
 * @param {*} devolutions 
 */
function setDevolutionsList(devolutions){
    return {
        type:SET_DEVOLUTIONS_LIST,
        devolutions:devolutions
    }
}

/**
 * Action reducer to set the devolutions counter
 *
 * @param {*} count
 * @returns
 */
function setDevolutionsCount(count) {
    return {
        type: SET_DEVOLUTIONS_COUNT,
        count
    }
}

/**
 * Action to is saving devolution
 * @param {*} isSaving 
 */
function isSaving(isSaving){
    return {
        type: IS_SAVING,
        isSaving:isSaving
    }
}

/**
 * Action to is loading devolution
 * @param {*} isLoading 
 */
function isLoading(isLoading){
    return {
        type: IS_LOADING,
        isLoading:isLoading
    }
}

/**
 * Action to set devolution
 * @param {*} devolution 
 */
function setDevolution(devolution){
    return {
        type: SET_DEVOLUTION,
        devolution: devolution
    }
}

/**
 * Action start loading invoices
 * @param {*} isLoading 
 */
function isLoadingInvoices(isLoading){
    return {
        type: START_INVOICES_LIST_BY_CODE,
        isLoadingInvoices:isLoading
    }
}

/**
 * Action to set invoices list 
 * @param {*} invoices 
 */
function setInvoicesList(invoices){
    return {
        type:SET_INVOICES_LIST_BY_CODE,
        invoices:invoices
    }
}

/**
 * Action to delete invoice from item devolution
 * @param {*} invoiceToken 
 */
function deleteInvoiceInItemDevolution(itemToken, invoiceToken){
    return{
        type: DELETE_INVOICE_IN_ITEM_DEVOLUTION,
        itemToken: itemToken,
        invoiceToken: invoiceToken
    }
}

/**
 * Action to delete item devolution 
 * @param {*} itemToken 
 */
function deleteItemDevolution(itemToken){
    return{
        type:DELETE_ITEM_DEVOLUTION,
        itemToken: itemToken
    }
}

/**
 * Get all Devolutions by company from backend 
 * DONE
 */
export function loadDevolutionsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, isAllReports){
    return function(dispatch, getState){
        dispatch(isLoadingDevolutions(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if(getState().oidc.user.profile && getState().oidc.user.profile.company_token){
            supplierToken =  getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/all`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
            GetAllReports: isAllReports,
        }
        return axios.post(endPoint, data, configuration)
        .then((serverResponse) => {
            if (serverResponse.status === 200) {
                dispatch(setDevolutionsList(serverResponse.data))
            }
        })
        .catch((error) => {
            dispatch(isLoadingDevolutions(false));
            console.error("Error getting the devolutions by company list", error.response)
        }) 
    }
}


/**
 * Get devolution's count
 * DONE
 * @export
 * @returns
 */
export function getDevolutiosCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().devolutions? getState().devolutions.get('devolutionsCount'): null;
        if (actualCounter){
            return;
        }          
        
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if(getState().oidc.user.profile && getState().oidc.user.profile.company_token){
            supplierToken =  getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
        }
        return axios.post(endPoint, data,  configuration)
        .then((serverResponse) => {
            if (serverResponse.status === 200) {
                dispatch(setDevolutionsCount(serverResponse.data))
            }
        })
        .catch((error) => {
            console.error("Error getting the devolution count", error.response)
        }) 
    }
}


/**
 * Get and set the devolution in the estate from read from backend
 * DONE
 * @param {*} token 
 */
export function loadDevolutionFromBackEnd(token){
    return function(dispatch, getState){
        dispatch(isLoading(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/devolution/${token}`;

        return axios.get(endPoint, configuration)
        .then((serverResponse) => {
            if (serverResponse.status === 200) {
                dispatch(setDevolution(serverResponse.data))
            }
        })
        .catch((error) => {
            console.error("Error to load devolution", error.response)
            dispatch(isLoading(false))
            return Promise.reject();
        })
    }
}

/**
 * Action to Mark Devolution Taken For Supplier from backend
 * @param {*} description 
 */
export function markDevolutionTakenForSupplierFromBackend(carrierName, carrierUnitPlates) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let devolutionToken = getState().devolutions.get('devolution').get('token');

        let data = {
            DevolutionToken: devolutionToken,
            carrierName: carrierName,
            CarrierUnitPlates: carrierUnitPlates,
        }

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/markdevolutiontaken`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setDevolution(serverResponse.data))
                    dispatch(isSaving(false))
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to mark devolution taken for supplier", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action get invoices by part number ans supplier data from backend 
 */
export function getAllInvoicesByPartNumberDevolutionFromSAP(parNumberCode){
    return function(dispatch, getState){
        dispatch(isLoadingInvoices(true))

        let selectedCompany = getState().profile.get("selectedCompany");
        let supplierConde = getState().devolutions.get('devolution').get('supplier_code');
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let data = {
            CompanyCenter: selectedCompany.get('center'),
            CompanyCenterFin: selectedCompany.get('center_fin'),
            PartNumberCode: parNumberCode,
            SupplierCode: supplierConde,
        }
       
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/partnumbers/supplier`;
        return axios.post(endPoint, data, configuration)
        .then((serverResponse) => {
            if (serverResponse.status === 200) {
                dispatch(setInvoicesList(serverResponse.data))
                return Promise.resolve(serverResponse.data);
            }
        })
        .catch((error) => {
            console.error("Error to get information from invoices", error.response)
            dispatch(isLoadingInvoices(false))
            return Promise.reject();
        })
    }
}

/**
 * Action to create a devolution
 *
 * @export
 * @param {*} token
 * @returns
 */
export function createDevolution(data) {
    return function (dispatch, getState) {
        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/cretemanual`;

        var devolution = {
            CompanyToken: selectedCompany.get('token'),
            CompanyIdentifier: selectedCompany.get("get_company_identifier"),
            DocumentIdentifier: selectedCompany.get('document_identifier'),
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            SupplierToken: data.supplier.token,
            SupplierCode: data.supplier.code,
            SupplierName: data.supplier.name,
            Comments: data.comments,
            ReceptionInspector: data.preparedBy.label,
            DevelopmentEngineerEmail: data.supplierDevelopmentEngineer.user_email,
            DevelopmentEngineerName: data.supplierDevelopmentEngineer.full_name,
            PlanningEngineerEmail: data.planningEngineer.user_email,
            PlanningEngineerName: data.planningEngineer.full_name,
        }

        return axios.post(endPoint, devolution, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve(serverResponse.data);
                }
            })
            .catch((error) => {
                console.error("Error to load Rework", error.response)
                dispatch(isSaving(false))
                return Promise.reject();
            })
    }
}

/**
 * Action update description in devolution item data from backend 
 */
export function updateDescriptionDevolutionItem(partNumber, description){
    return function(dispatch, getState){

        let devolutionToken = getState().devolutions.get('devolution').get('token');
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let data = {
            DevolutionToken: devolutionToken,
            PartNumberCode: partNumber ? partNumber.code : "",
            PartNumberDescription:partNumber ? partNumber.description : "",
            PartNumberUM: partNumber ? partNumber.measurementUnit : "",
            Description: description
        }

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/items/updatedescription`;
        return axios.put(endPoint, data, configuration)
            .then(() => {
            })
            .catch((error) => {
                console.error("Error to get information from invoices", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action update non conformity in devolution item data from backend 
 */
export function updateNonconformityToDevolutionItem(partNumber, failure){
    return function(dispatch, getState){

        let devolutionToken = getState().devolutions.get('devolution').get('token');
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let data = {
            DevolutionToken: devolutionToken,
            PartNumberCode: partNumber ? partNumber.code : "",
            PartNumberDescription:partNumber ? partNumber.description : "",
            PartNumberUM: partNumber ? partNumber.measurementUnit : "",
            NonconformityToken: failure ? failure.value : "",
        }
       
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/items/nonconformity`;
        return axios.put(endPoint, data, configuration)
            .then(() => {
            })
            .catch((error) => {
                console.error("Error to get information from invoices", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to add invoice in devolution item data from backend 
 */
export function addInvoiceDevolutionItemFromBackend(partNumber, invoice){
    return function(dispatch, getState){

        let devolutionToken = getState().devolutions.get('devolution').get('token');
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let data = {
            DevolutionToken: devolutionToken,
            PartNumberCode: partNumber ? partNumber.code : "",
            InvoicePurchaseOrderLine:invoice ? invoice.purchase_order_line : "",
            InvoicePurchaseOrder: invoice ? invoice.purchase_order : "",
            InvoiceItemFolio: invoice ? invoice.folio : "",
            InvoiceReference: invoice ? invoice.supplier_reference : "",
            InvoiceQuality: invoice ? invoice.quantity_can_be_returned : "",
            InvoiceCreationDate: invoice ? invoice.creation_date : "",
            InvoiceCurrency: invoice ? invoice.currency : "",
            QualityReturn: invoice ? invoice.quantity_to_return : "",
            CompanyCenter: invoice ? invoice.company_center : "",
            Amount : invoice ? invoice.amount : "",

        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/items/invoices`;
        return axios.post(endPoint, data, configuration)
            .then(() => {
            })
            .catch((error) => {
                console.error("Error to get information from invoices", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to Update Status To Devolution Waiting By The Supplier Picke dUp from backend
 * @param {*} description 
 */
export function updateStatusToDevolutionWaitingByTheSupplierPickedUp() {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let devolutionToken = getState().devolutions.get('devolution').get('token');

        let data = {}
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/${devolutionToken}/statuswaiting`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setDevolution(serverResponse.data))
                    dispatch(isSaving(false))
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to update status waiting by the supplier picked up devolution", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to remove Invoice from Devolution item from backend
 * @param {*} invoiceToken 
 */
export function deleteInvoiceItemDevolution(itemToken, invoiceToken) {
    return (dispatch, getState)=>{
        
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/items/invoices/${invoiceToken}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(deleteInvoiceInItemDevolution(itemToken, invoiceToken))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error removing a invoice from item devolution", error.response)
                return Promise.reject();
        })
    }
}

/**
 * Action to remove devolution item in Devolution from backend
 * @param {*} tokenItem 
 */
export function deleteDevolutionItem(tokenItem) {
    return (dispatch, getState)=>{
        
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let devolutionToken = getState().devolutions.get('devolution').get('token');

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/${devolutionToken}/items/${tokenItem}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(deleteItemDevolution(tokenItem))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error removing a devolution item from devolution", error.response)
                return Promise.reject();
        })
    }
}


/**
 * Action to delete draft devolution
 * @param {*} token 
 */
export function deleteDraftDevolution(token) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/workflow/${token}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to delete draft devolution", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to Reactive devolution
 *
 * @export
 * @param {*} token
 * @returns
 */
export function onReactiveDevolutionFromBackend(token) {
    return function (dispatch, getState) {
        dispatch(isSaving(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/${token}/reactive`;

        return axios.post(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setDevolution(serverResponse.data))
                    dispatch(isSaving(false))
                }
            })
            .catch((error) => {
                console.error("Error to reactiveDevolution", error.response)
                dispatch(isSaving(false))
                return Promise.reject();
            })
    }
}

/**
 *Download report of devolutions
 *
 * @export
 * @returns
 */
 export function downloadDevolutionsReport(startDate, endDate) {
    return (dispatch, getState) => {
        dispatch(isLoading(true));
        let companyToken = getState().profile.get("selectedCompany").get('token');
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/downloadreport`;
        let command = {
            StartDate: startDate,
            EndDate: endDate,
            CompanyToken: companyToken
        }
        return axios.post(endPoint,command, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isLoading(false));
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reporte_de_devoluciones.xlsx";
                    a.click();
                }
                return Promise.resolve()
            })
            .catch((error) => {
                dispatch(isLoading(false));
                console.error("Error downloading report of dovolutions", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to update files in the devolution
 *
 * @export
 * @param {*} data
 * @returns
 */
 export function uploadFilesEvidence(files, devolutionToken) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUserFiles(user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/${devolutionToken}/upload`;
        let formData = new FormData();
        for (var index = 0; index <= files.length; index++) {
            formData.append('file', files[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addFileInDevolution(serverResponse.data));
                    dispatch(isSaving(false));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to upload evidence file(s) from devolutions", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} quoteToken
 * @param {*} fileName
 * @returns
 */
 export function removeFilesEvidence(fileName,devolutionToken) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions/${devolutionToken}/removeFile`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeFileFromDevolution(fileName))
                }
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to remove evidence file(s) from devolutions", error.response)
                return Promise.reject();
            })
    }
}
