/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import {
    withStyles,
    Grid,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

/**Import components section*/
import { BACKGROUND_COLOR_GRAY_BIDDING } from '../../../../store/helpers/StatusColorConstants'
import EmptyInformation from '../EmptyInformation'
import ListSuppliers from './ListSuppliers'
import { BiddingSuppliers } from '../../../../store/helpers/AppConstants';

const AddSuppliersComponent = (props) => {
    const { classes, translate, bidding } = props;
    /**String props section */
    // const { } = props
    /**Bools props section */
    const { isSaving } = props
    /**Arrays props section */
    const { userRolesByCompany } = props
    /**Functions props section */
    const { onDeletedSupplierBiddingFromBackEnd, onSendEmailConfigPanel, onCancelSupplierBiddingFromBackEnd, onUpdateContactEmailFromBackEnd, } = props
    /**Objects props section */
    const { userProfile } = props

    return (
        <>
            <Grid item xs={12} className={classes.containerSuppliers}>
                {
                    (bidding && bidding.Suppliers.length) !== 0
                        ? <>
                            <Typography color='primary' variant='subtitle1'>{translate('biddingsModule.show.supplierInternal')}</Typography>

                            <ListSuppliers
                                suppliers={bidding ? bidding.Suppliers.filter(s => s.Type === BiddingSuppliers.INTERNAL) : []}
                                onDeletedSupplierBiddingFromBackEnd={onDeletedSupplierBiddingFromBackEnd}
                                onCancelSupplierBiddingFromBackEnd={onCancelSupplierBiddingFromBackEnd}
                                isSaving={isSaving}
                                userRolesByCompany={userRolesByCompany}
                                bidding={bidding}
                                userProfile={userProfile}
                                translate={translate}
                                onSendEmailConfigPanel={onSendEmailConfigPanel}
                            />

                            <Typography color='primary' variant='subtitle1'>{translate('biddingsModule.show.supplierExternal')}</Typography>

                            <ListSuppliers
                                suppliers={bidding ? bidding.Suppliers.filter(s => s.Type === BiddingSuppliers.EXTERNAL) : []}
                                onDeletedSupplierBiddingFromBackEnd={onDeletedSupplierBiddingFromBackEnd}
                                onCancelSupplierBiddingFromBackEnd={onCancelSupplierBiddingFromBackEnd}
                                onUpdateContactEmailFromBackEnd={onUpdateContactEmailFromBackEnd}
                                isSaving={isSaving}
                                userRolesByCompany={userRolesByCompany}
                                bidding={bidding}
                                userProfile={userProfile}
                                translate={translate}
                                onSendEmailConfigPanel={onSendEmailConfigPanel}
                            />

                        </> :
                        <EmptyInformation
                            title={translate('biddingsModule.create.configSupplier')}
                        />
                }

            </Grid>
        </>
    )
};

const styles = (theme) => ({
    specifications: {
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
    },
    avatar: {
        backgroundColor: theme.palette.primary.main
    }
});

AddSuppliersComponent.propTypes = {
    bidding: PropTypes.object
};

AddSuppliersComponent.defaultProps = {
    bidding: null
};

export default withRouter(
    withStyles(styles, { withTheme: true })(AddSuppliersComponent)
);
