//import react section
import React, { useState } from 'react'
import propTypes from 'prop-types'

// Import material-ui section
import { Button } from '@material-ui/core'
import Configurator from './PopperConfigurator'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import { withStyles } from '@material-ui/core/styles'

const ColumnsConfigurator = (props) => {
	//Array props section
	const { headerTableConfiguration } = props
	//Function props section
	const { onConfigurationChange } = props
	const {title, classes} = props

	const [configurationAnchorEl, setConfigurationAnchorEl] = useState(null)

	const onShowConfiguration = (event) => {
		setConfigurationAnchorEl(
			configurationAnchorEl ? null : event.currentTarget
		)
	}

	const onConfigurationChangeClose = (configuration) => {
		if (onConfigurationChange) {
			onConfigurationChange(configuration)
			setConfigurationAnchorEl(null)
		}
	}

	const onResetConfigurationTable = () =>{
		if(props.onResetConfigurationTable){
			props.onResetConfigurationTable()
			setConfigurationAnchorEl(null)

		}
	}

	return (
		<>
			<Button id='columnsConfigurator' className={classes.button} onClick={onShowConfiguration}>
				{<SettingsOutlinedIcon className={classes.icon}/>} {title}
			</Button>
			<Configurator
				open={configurationAnchorEl ? true : false}
				anchorEl={configurationAnchorEl}
				configuration={headerTableConfiguration}
				onConfigurationChange={onConfigurationChangeClose}
				onClickAway={() => setConfigurationAnchorEl(null)}
				maxHeight='300px'
				onResetConfigurationTable={onResetConfigurationTable}
			/>
		</>
	)
}


ColumnsConfigurator.propTypes = {
	headerTableConfiguration: propTypes.arrayOf(
		propTypes.shape({
			dataSource: propTypes.string.isRequired,
			sortable: propTypes.bool,
			isMovable: propTypes.bool.isRequired,
			title: propTypes.oneOfType([propTypes.string, propTypes.node]),
		})
	),
	onConfigurationChange: propTypes.func.isRequired,
}

ColumnsConfigurator.defaultProps = {
	headerTableConfiguration: [
		{
			dataSource: 'Ejemplo',
			sortable: false,
			isMovable: false,
			title: 'Ejemplo',
		},
	],
	onConfigurationChange: () =>
		console.warn('Callback [onConfigurationChange] no defined'),
}

const styles = (theme) => ({
	icon: {
		marginRight: '19.4px',
	},
	button: {
		textTransform: 'none',
		color: theme.palette.primary.main,
	}
})

export default withStyles(styles)(ColumnsConfigurator)
