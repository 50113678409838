/**Import react section */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
/**Import MaterialUi section */
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
/**Import components section */

/**Import store section */
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';
import { GetFileTypeOptions } from '../../../../store/helpers/SelectOptions';

/**custom hooks */
import { FileType } from '../../../../store/helpers/AppConstants';

const EditExpedientSupplier = (props) => {
	const CHARACTER_LIMIT_DOCUMENT_NAME = 100;
	const CHARACTER_LIMIT_NOMENCLATURE = 100;
	const CHARACTER_COMMENTS = 255;

	const { onSaveButtonClick, classes } = props;
	const isSaving = useSelector((state) => state.expedientSuppliers.get('isSavingExpedientSupplier'));
	const [expedienteSupplierModel, setExpedientSupplierModel] = useState({
		nomenclature: '',
		documentName: '',
		comments: '',
		format: null
	});

	useEffect(() => {
		if (props.item) {
			let fileTypeOption = null;
			if (props.item.FileType === 'PDF') fileTypeOption = GetFileTypeOptions().find((x) => x.value === FileType.PDF);
			if (props.item.FileType === 'EXCEL')fileTypeOption = GetFileTypeOptions().find((x) => x.value === FileType.EXCEL);
			if (props.item.FileType === 'ZIP') fileTypeOption = GetFileTypeOptions().find((x) => x.value === FileType.ZIP);
			if (props.item.FileType === 'WORD') fileTypeOption = GetFileTypeOptions().find((x) => x.value === FileType.WORD);
			if (props.item.FileType === 'IMAGEN') fileTypeOption = GetFileTypeOptions().find((x) => x.value === FileType.IMAGEN);
			if (props.item.FileType === 'PDF_O_IMAGEN') fileTypeOption = GetFileTypeOptions().find((x) => x.value === FileType.PDF_O_IMAGEN);

			let newItem = {
				nomenclature: props.item.Nomenclature,
				documentName: props.item.Name,
				comments: props.item.Comments,
				format: fileTypeOption,
				token: props.item.token
			};
			setExpedientSupplierModel(newItem);
		}
	}, [props.item]);

	const onSave = () => {
		if (props.onSaveButtonClick) {
			onSaveButtonClick(expedienteSupplierModel);
		}
	};

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target;
		setExpedientSupplierModel({
			...expedienteSupplierModel,
			[name]: value
		});
	};

	const onSelectedChange = (name, option) => {
		setExpedientSupplierModel({
			...expedienteSupplierModel,
			[name]: option
		});
	};

	/**Verify if can be sabed data*/
	const canBeSaved = () => {
		if (IsNullOrEmpty(expedienteSupplierModel.nomenclature)) return false;
		if (IsNullOrEmpty(expedienteSupplierModel.documentName)) return false;
		if (expedienteSupplierModel.format === null) return false;
		return true;
	};

	return (
		<div>
			<div className={classes.containerInformation}>
				<Grid container>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label={'Nomenclatura'}
							className={classes.textField}
							onChange={onPropertyChange}
							name="nomenclature"
							margin="normal"
							variant="outlined"
							value={expedienteSupplierModel ? expedienteSupplierModel.nomenclature : 0}
							inputProps={{ maxLength: CHARACTER_LIMIT_NOMENCLATURE }}
							helperText={
								<Typography align="right">
									{`${
										expedienteSupplierModel ? expedienteSupplierModel.nomenclature.length : 0
									}/${CHARACTER_LIMIT_NOMENCLATURE}`}
								</Typography>
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label={'Nombre de documento'}
							className={classes.textField}
							onChange={onPropertyChange}
							name="documentName"
							margin="normal"
							variant="outlined"
							value={expedienteSupplierModel ? expedienteSupplierModel.documentName : 0}
							inputProps={{ maxLength: CHARACTER_LIMIT_DOCUMENT_NAME }}
							helperText={
								<Typography align="right">
									{`${
										expedienteSupplierModel ? expedienteSupplierModel.documentName.length : 0
									}/${CHARACTER_LIMIT_DOCUMENT_NAME}`}
								</Typography>
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<Select
							placeholder={'Selecciona el formato'}
							options={GetFileTypeOptions()}
							onChange={(item) => onSelectedChange('format', item)}
							value={expedienteSupplierModel ? expedienteSupplierModel.format : null}
							styles={{
								menu: (base) => ({ ...base, zIndex: 10 }),
								menuList: (base) => ({ ...base, paddingTop: 0 }),
								menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
								control: (base) => ({
									...base,
									height: 50,
									minHeight: 50
								})
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							multiline
							label={'Comentarios'}
							className={classes.textField}
							onChange={onPropertyChange}
							name="comments"
							margin="normal"
							variant="outlined"
							rows={3}
							rowsMax={3}
							value={expedienteSupplierModel ? expedienteSupplierModel.comments : 0}
							helperText={
								<Typography align="right">
									{`${expedienteSupplierModel ? expedienteSupplierModel.comments.length : 0}/${CHARACTER_COMMENTS}`}
								</Typography>
							}
						/>
					</Grid>
				</Grid>
			</div>

			<Grid container>
				<Grid item xs={12} className={classes.containerBtn}>
					<Button
						data-cy="btn-save"
						type="button"
						color="primary"
						variant="outlined"
						onClick={onSave}
						className={classes.widthBtnSaved}
						disabled={!canBeSaved() || isSaving}
					>
						{<Translate id="common.save" />}
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${165}px)`,
		minHeight: `calc(100vh - ${165}px)`,
		overflowY: 'auto',
		padding: '10px'
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center'
	},
	inputProps: {
		height: 6
	},
	marginTop: {
		marginTop: '10px'
	},
	marginLeft: {
		marginLeft: '6px'
	},
	widthBtnSaved: {
		minWidth: '400px'
	}
});

EditExpedientSupplier.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired
};

EditExpedientSupplier.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false
};

export default withRouter(withStyles(styles, { withTheme: true })(EditExpedientSupplier));
