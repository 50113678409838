/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import {
    Typography,
    withStyles,
    Grid
} from "@material-ui/core";
/**Import components section*/
import { BACKGROUND_COLOR_GRAY_BIDDING } from '../../../store/helpers/StatusColorConstants'

const EmptyInformation = (props) => {
    const { classes } = props;
    /**String props section */
    const { title } = props

    return (
        <Grid container>
            <Grid item xs={12} className={classes.backgroundColor}>
                <Typography variant='subtitle1' align='center'>{title}</Typography>

            </Grid>
        </Grid>
    )
};

const styles = (theme) => ({
    imagContainer: {
        display: "flex",
        justifyContent: 'center'
    },
    backgroundColor:{
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
    }
});

EmptyInformation.propTypes = {
    title: PropTypes.string.isRequired
};

EmptyInformation.defaultProps = {
    title: ""
};

export default withRouter(
    withStyles(styles, { withTheme: true })(EmptyInformation)
);
