import axios from 'axios'
import { internalCompanyData, supplierData } from '../helpers/BasePdfData'
import {onGetBlobType} from '../helpers/BlobTypeHelper'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
export const SET_DELIVERYORDERS = 'SET_DELIVERYORDERS'
export const IS_LOADING_DELIVERY_ORDERS = 'IS_LOADING_DELIVERY_ORDERS'
export const IS_LOADING_DELIVERY_ORDER_ITEMS = 'IS_LOADING_DELIVERY_ORDER_ITEMS'
export const IS_LOADING_ADD_DELIVERY_ORDER_ITEMS = 'IS_LOADING_ADD_DELIVERY_ORDER_ITEMS'
export const SET_SELECTOR_VALUES = 'SET_SELECTOR_VALUES'
export const IS_LOADING_DELIVERY_ORDER = 'IS_LOADING_DELIVERY_ORDER'
export const SET_DELIVERY_ORDER = 'SET_DELIVERY_ORDER'
export const ADD_MATERIAL = 'ADD_MATERIAL'
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL'
export const REMOVE_MATERIAL = 'REMOVE_MATERIAL'
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY'
export const IS_SAVING_DELIVERY_ORDER = 'IS_SAVING_DELIVERY_ORDER'
export const IS_LOADING_DELIVERY_ORDER_SHOW_VIEW = 'IS_LOADING_DELIVERY_ORDER_SHOW_VIEW'

export const CHANGE_DELIVERY_ORDERS_SORT_CRITERIA = 'CHANGE_DELIVERY_ORDERS_SORT_CRITERIA'
export const CHANGE_DELIVERY_ORDERS_QUERY = 'CHANGE_DELIVERY_ORDERS_QUERY'
export const CHANGE_DELIVERY_ORDERS_PAGINATION = 'CHANGE_DELIVERY_ORDERS_PAGINATION'
export const SET_DELIVERY_ORDERS_COUNT = 'SET_DELIVERY_ORDERS_COUNT'
export const ADD_DELIVERY_ORDER = 'ADD_DELIVERY_ORDER'
export const SET_DELIVERY_ORDERS_EVENTS = 'SET_DELIVERY_ORDERS_EVENTS'
export const IS_SAVING_MATERIAL = 'IS_SAVING_MATERIAL'
export const ADD_FILE_IN_DELIVERY_ORDER = 'ADD_FILE_IN_DELIVERY_ORDER'
export const IS_DOWNLOADING_REPORT_ORDERS = 'IS_DOWNLOADING_REPORT_ORDERS'
export const REMOVE_INDRAFT_FILE_DELIVERY_ORDER = 'REMOVE_INDRAFT_FILE_DELIVERY_ORDER'
export const REMOVE_FILE_IN_DELIVERY_ORDER = 'REMOVE_FILE_IN_DELIVERY_ORDER'

function isLoadingDeliveryOrden(isLoading) {
	return {
		type: IS_LOADING_DELIVERY_ORDERS,
		isLoading: isLoading,
	}
}

function isLoadingDeliveryOrdenShowView(isLoading) {
	return {
		type: IS_LOADING_DELIVERY_ORDER_SHOW_VIEW,
		isLoading: isLoading,
	}
}

function setDeliveryOrden(deliveryOrders) {
	return {
		type: SET_DELIVERYORDERS,
		deliveryOrders: deliveryOrders,
	}
}

function setSelectorValues(selectorValues) {
	return {
		type: SET_SELECTOR_VALUES,
		selectorValues,
	}
}

function isLoadingDeliveryOrderItems(isLoading) {
	return {
		type: IS_LOADING_DELIVERY_ORDER_ITEMS,
		IsLoading: isLoading,
	}
}
function isSavingAddMaterial(isLoading) {
	return {
		type: IS_LOADING_ADD_DELIVERY_ORDER_ITEMS,
		IsSavingLoadingItem: isLoading,
	}
}
function setDeliveryOrderData(deliveryOrder, deliveryOrderType = 'deliveryOrder') {
	return {
		type: SET_DELIVERY_ORDER,
		deliveryOrder,
		deliveryOrderType,
	}
}

function addMaterialToList(material) {
	return {
		type: ADD_MATERIAL,
		material,
	}
}

function updateMaterialFromList(material, deliveryOrderType = 'deliveryOrder') {
	return {
		type: UPDATE_MATERIAL,
		deliveryOrderType,
		material,
	}
}

function removeMaterialFromList(id) {
	return {
		type: REMOVE_MATERIAL,
		id,
	}
}

function setDeliveryOrdersEvents(deliveryOrdersEvents) {
	return {
		type: SET_DELIVERY_ORDERS_EVENTS,
		deliveryOrdersEvents,
	}
}

/**
 * Set flag to determine if is saving specific deliveryOrder data
 *
 * @param {*} isSavingDeliveryOrder
 * @returns
 */
function isSavingDeliveryOrder(isSavingDeliveryOrder) {
	return {
		type: IS_SAVING_DELIVERY_ORDER,
		isSavingDeliveryOrder,
	}
}

/**
 * Set flag to determine if is saving material in deliveryOrder
 *
 * @param {*} isSavingDeliveryOrder
 * @returns
 */
//  function isSavingMaterial(isSavingMaterial) {
//     return {
//         type: IS_SAVING_MATERIAL,
//         isSavingMaterial
//     }
// }
function addFile(files, folderName, isFromViewCreate) {
	return {
		type: ADD_FILE_IN_DELIVERY_ORDER,
		files: files,
		folderName: folderName,
		isFromViewCreate: isFromViewCreate
	}
}

function isDonwloadingReport(isDonwloading) {
	return {
		type: IS_DOWNLOADING_REPORT_ORDERS,
		isDonwloading,
	}
}

function removeFile(fileName, folderName, isFromViewCreate) {
	return {
		type: REMOVE_FILE_IN_DELIVERY_ORDER,
		fileName,
		folderName,
		isFromViewCreate
	}
}
export function getActiveDeliveryOrderFromBackEnd(supplier, partnership) {
	return function (dispatch, getState) {
		if (supplier && partnership) {
			dispatch(isLoadingDeliveryOrderItems(true))

			let user = getState().oidc.user
			let configuration = getAuthorizedRequestConfigurationUser(user)
			let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/getactive`

			let dataObject = {
				CompanyIdentifier: 'FERSA',
				CreatorUserName: user.profile.preferred_username,
				CreatedBy: user.profile.name,
				CreatorEmail: typeof user.profile.email === 'string' ? user.profile.email : user.profile.email[0],
				SupplierToken: supplier ? supplier.token : '',
				SupplierName: supplier ? supplier.name : '',
				SupplierCode: supplier ? supplier.code : '',
				MaterialDeliveryTime: partnership ? partnership.off_set_delivery_days : 5,
				DaysRequiredForDeliveryMaterial: partnership ? partnership.days_required_for_delivery_material : 0,
				AddHoursForDeliveryMaterial: partnership ? partnership.time_hours_required_for_delivery_material : '30',
				Incoterm: partnership ? partnership.incoterm : 'DDP',
			}

			return axios
				.post(endPoint, dataObject, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setDeliveryOrderData(serverResponse.data, 'deliveryOrderDraft'))
						dispatch(isLoadingDeliveryOrderItems(false))
					}
				})
				.catch((error) => {
					console.error('Error to load deliveryOrder', error.response)
					dispatch(isLoadingDeliveryOrderItems(false))
					return Promise.reject(error.response) //Return Message to show toaster error
				})
			// }
		}
	}
}

export function addMaterialToDeliveryOrder(material, supplier) {
	return (dispatch, getState) => {
		if (supplier) {
			dispatch(isSavingAddMaterial(true))
			let user = getState().oidc.user
			let configuration = getAuthorizedRequestConfigurationUser(user)
			let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/items`
			let selectedCompany = getState().profile.get('selectedCompany')

			let command = {
				CompanyToken: selectedCompany.get('token'),
				supplierCode: supplier.code,
				Code: material.part_number_code,
				PurchaseOrder: material.purchase_order,
				Position: parseInt(material.position),
				RevisionConfirmation: material.status,
				Quantity: parseFloat(material.quantity_of_material),
				InvoiceRemission: material.invoice_or_remission,
				Lote: material.lot,
				CellToken: '34',
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(addMaterialToList(serverResponse.data))
						return Promise.resolve(serverResponse.data)
					}
				})
				.catch((error) => {
					dispatch(isSavingAddMaterial(false))
					return Promise.reject(error.response)
				})
		}
	}
}

export function loadDeliveryOrders(query, orderBy, sortAscending, page, rowsPerPage, companyCode) {
	return function (dispatch, getState) {
		dispatch(isLoadingDeliveryOrden(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let data = {
			SupplierCode: companyCode,
			CompanyCode: 'FERSA',
			Sort: {
				By: 'Folio',
				Direction: sortAscending ? 'asc' : 'desc',
			},
			SortAscending: sortAscending,
			Query: query,
			PageSize: rowsPerPage,
			PageNumber: page + 1,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/search`
		if (selectedCompany) {
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setDeliveryOrden(serverResponse.data))
						dispatch(isLoadingDeliveryOrden(false))
					}
				})
				.catch((error) => {
					console.error('Error to load release plan', error.response)
					dispatch(isLoadingDeliveryOrden(false))
					return Promise.reject()
				})
		}
	}
}
export function loadRelease() {
	return function (dispatch, getState) {
		//dispatch(isLoadingReleasePlanning(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/loadRelease/${user.profile.company_code}`
		if (selectedCompany) {
			return axios
				.get(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setSelectorValues(serverResponse.data))
						// dispatch(isLoadingReleasePlanning(false))
					}
				})
				.catch((error) => {
					console.error('Error to load release plan', error.response)
					//dispatch(isLoadingReleasePlanning(false))
					return Promise.reject()
				})
		}
	}
}

export function loadReleaseMaterialsConfirms() {
	return function (dispatch, getState) {
		//dispatch(isLoadingReleasePlanning(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/loadRelease/${user.profile.company_code}/materials`
		if (selectedCompany) {
			return axios
				.get(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setSelectorValues(serverResponse.data))
						// dispatch(isLoadingReleasePlanning(false))
					}
				})
				.catch((error) => {
					console.error('Error to load release plan', error.response)
					//dispatch(isLoadingReleasePlanning(false))
					return Promise.reject()
				})
		}
	}
}


export function updateMaterialFromDeliveryOrder(material, materialItemToken, internaUser = false) {
	return function (dispatch, getState) {
		dispatch(isSavingAddMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/updateitem`
		let data = {
			Id: materialItemToken,
			PurchaseOrder: material.purchase_order,
			Position: material.position,
			Revision: material.revision_confirmation ? material.revision_confirmation: material.status,
			Quantity: material.quantity_of_material,
			InvoiceRemission: material.invoice_or_remission,
			Lote: material.lot,
			code: material.part_number_code,
			WarehouseCode: material.warehouse_code,
            FolioEntrieSap: material.folio_entrie_sap,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					if (internaUser) {
						dispatch(updateMaterialFromList(serverResponse.data))
						dispatch(isSavingAddMaterial(false))
					} else {
						dispatch(updateMaterialFromList(serverResponse.data, 'deliveryOrderDraft'))
					}
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingAddMaterial(false))
				console.error('Error to update material', error.response)
				return Promise.reject()
			})
	}
}

export function removeMaterialFromDeliveryOrder(materialItemToken) {
	return (dispatch, getState) => {
		dispatch(isSavingAddMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/items/${materialItemToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeMaterialFromList(materialItemToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingAddMaterial(false))
				console.error('Error adding a deliveryOrder', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function closeAndPrintDeliveryOrderOperation(arrivalData, deliveryOrderToken) {
	return function (dispatch, getState) {
		dispatch(isSavingDeliveryOrder(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let supplierInformation = getState().suppliers.get('supplier').toJS()
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let partnershipInformation = getState().suppliers.get('partnership').toJS()
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/verifyavailabletimes/tosend`
		let data = {
			DeliveryOrderId: deliveryOrderToken,
			EstimatedArrivalDateTime: `${arrivalData.estimated_date.format('DD/MM/YYYY')} ${arrivalData.estimated_time
				}`,
			SendName: user.profile.name,
			SendEmail: typeof user.profile.email === 'string' ? user.profile.email : user.profile.email[0],
			SendUserName: user.profile.preferred_username,
			RampNumber: String(arrivalData.ramp_number),
			Supplier: supplierData(supplierInformation),
			Company: internalCompanyData(companyInformation),
			MaterialDeliveryTime: partnershipInformation ? partnershipInformation.off_set_delivery_days : 5,
			DaysRequiredForDeliveryMaterial: partnershipInformation
				? partnershipInformation.days_required_for_delivery_material
				: 0,
			AddHoursForDeliveryMaterial: partnershipInformation
				? partnershipInformation.time_hours_required_for_delivery_material
				: '1',
			Incoterm: partnershipInformation ? partnershipInformation.incoterm : 'DDP',
			IsRequieredCarbonFootprint: partnershipInformation
				? partnershipInformation.is_supplier_to_add_carbon_foot_print
				: false,
			IsRequieredCerificateByTemplate: partnershipInformation
				? partnershipInformation.is_requiered_cerificate_by_template
				: false,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/verifyavailabletimes/sendToClient`
					return axios
						.post(endPoint, data, configuration)
						.then((response) => {
							if (response.status === 200) {
								let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/pdf`
								return axios
									.post(endPoint, data, {
										responseType: 'arraybuffer',
										headers: configuration.headers,
									})
									.then((response) => {
										if (response.status === 200) {
											dispatch(isSavingDeliveryOrder(false))
											return Promise.resolve('Ok')
										}
									})
									.catch((error) => {
										console.error('Error to close deliveryOrder', error.response)
										dispatch(isSavingDeliveryOrder(false))
										return Promise.reject(error.response.data.Message ? error.response.data.Message: error.response.data) //IMPORTANT!! Return full error response
									})
							}
						})
						.catch((error) => {
							console.error('Error to close deliveryOrder', error.response)
							dispatch(isSavingDeliveryOrder(false))
							return Promise.reject(error.response.data.Message ? error.response.data.Message: error.response.data) //IMPORTANT!! Return full error response
						})
				}
			})
			.catch((error) => {
				dispatch(isSavingDeliveryOrder(false))
				return Promise.reject(error.response.data.Message ? error.response.data.Message: error.response.data) //Return Message to show toaster error
			})
	}
}

export function dowloandOrderOperation(deliveryOrderToken) {
	return function (dispatch, getState) {
		dispatch(isSavingAddMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let supplierInformation = getState().suppliers.get('supplier').toJS()
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/pdf`
		let data = {
			DeliveryOrderId: deliveryOrderToken,
			Supplier: supplierData(supplierInformation),
			Company: internalCompanyData(companyInformation),
		}
		return axios
			.post(endPoint, data, {
				responseType: 'arraybuffer',
				headers: configuration.headers,
			})
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var file = new Blob([serverResponse.data], { type: 'application/pdf' })
					var fileURL = URL.createObjectURL(file)
					window.open(fileURL)
					dispatch(isSavingAddMaterial(false))
					return Promise.resolve(fileURL)
				}
			})
			.catch((error) => {
				dispatch(isSavingAddMaterial(false))
				return Promise.reject(error.response.data.Message) //Return Message to show toaster error
			})
	}
}

export function downloadDeliveryMaterialReturnedOrMissingPdf(id) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		dispatch(isSavingAddMaterial(true))
		let supplierInformation = getState().suppliers.get('supplier').toJS()
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let data = {
			Supplier: supplierData(supplierInformation),
			Company: internalCompanyData(companyInformation),
			DeliveryOrderId: id,
		}
		
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/materialReturnedOrMissingPdf`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var file = new Blob([serverResponse.data], { type: 'application/pdf' })
					var fileURL = URL.createObjectURL(file)
					window.open(fileURL)
					dispatch(isSavingAddMaterial(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingAddMaterial(false))

				console.error('Error in download Material Returned Or Missing in delivery order', error.response)
				return Promise.reject()
			})
	}
}
export function checkScheduleAvailability(arrivalData, deliveryOrderToken) {
	return function (dispatch, getState) {
		//dispatch(isSavingDeliveryOrder(true));
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/unavailableHours`
		let data = {
			EstimatedArrivalDateTime: `${arrivalData.estimated_date.format('DD/MM/YYYY')}`,
			CompanyCode: 'FERSA',
			//CompanyCode: companyInformation['token'],

			RampNumber: String(arrivalData.ramp_number),
			DeliveryOrderId: deliveryOrderToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					//	dispatch(isSavingDeliveryOrder(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to verify available times delivery order', error.response)
				//dispatch(isSavingDeliveryOrder(false))
				return Promise.reject(error.response.data.Message) //Return Message to show toaster error
			})
	}
}

export function loadDeliveryOrderFromBackEnd(id, isLoading = true) {
	return function (dispatch, getState) {
		if(isLoading){
			dispatch(isLoadingDeliveryOrdenShowView(true))
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/deliveryOrdenId/${id}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDeliveryOrderData(serverResponse.data))
					dispatch(isLoadingDeliveryOrdenShowView(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to load deliveryOrder', error.response)
				dispatch(isLoadingDeliveryOrdenShowView(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to register the folio from delivery arrval
 * @param {*} folio
 */
export function registerArrivalDeliveryOrder(folio) {
	return function (dispatch, getState) {
		dispatch(isSavingAddMaterial(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user

		let data = {
			RegisterByUserName: user.profile.preferred_username,
			RegisterByName: user.profile.name,
			Folio: folio,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/registerarrival`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingAddMaterial(false))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSavingAddMaterial(false))
				return Promise.reject(error.response.data)
			})
	}
}

export function UpdateReceivedAmount(materialId, value) {
	return function (dispatch, getState) {
		dispatch(isSavingAddMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/UpdateReceivedAmount`
		let data = {
			Id: materialId,
			ReceivedAmount: parseFloat(value),
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDeliveryOrderData(serverResponse.data))
					dispatch(isSavingAddMaterial(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingAddMaterial(false))
				console.error('Error to update material', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function acceptMaterialItemByWarehouseman(itemToken, workerName) {
	return function (dispatch, getState) {
		dispatch(isSavingAddMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			Id: itemToken,
			Name: user.profile.name,
			UserName: user.profile.preferred_username,
			WorkerName: workerName,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/acceptitemwarehouseman`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingAddMaterial(false))
					dispatch(setDeliveryOrderData(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isSavingAddMaterial(false))
				console.error('Error to accept material item', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function cancelDeliveryOrderToClient(id, razonForCancel) {
	return function (dispatch, getState) {
		dispatch(isSavingAddMaterial(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user

		let data = {
			CancelUserName: user.profile.preferred_username,
			CancelBy: user.profile.name,
			DeliveryOrderId: id,
			ReasonCancellation: razonForCancel,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/cancelbyclient`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingAddMaterial(false))
					dispatch(setDeliveryOrderData(serverResponse.data))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingAddMaterial(false))
				console.error('Error to cancel a delivery order', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function loadDeliveryOrdersEventsFromBackEnd(supplierCode) {
	return function (dispatch, getState) {
		dispatch(isSavingAddMaterial(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/calendarlocks`
		let data = {
			companyCode: selectedCompany.get('company_code'),
			SupplierCode: supplierCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDeliveryOrdersEvents(serverResponse.data.items))
					dispatch(isSavingAddMaterial(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingAddMaterial(false))
				console.error('Error getting the delivery orders by company list', error.response)
			})
	}
}

/**
 * Action Send the delivery order to SAP from user warehouse
 * @param {*} folio
 */
export function sendDeliveryOrderToSAP(token, centerSelected) {
	return function (dispatch, getState) {
		dispatch(isSavingAddMaterial(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user

		// let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			DeliveryOrderId: token,
			CreatorUserName: user.profile.preferred_username,
			CreatedBy: user.profile.name,
			CompanyCenter: centerSelected.value,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/senddeliveryordertosap`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					console.log("serverResponse.data", serverResponse.data)
					// dispatch(setDeliveryOrderData(serverResponse.data))
					console.log("setDeliveryOrderData.data")

					dispatch(isSavingAddMaterial(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingAddMaterial(false))
				console.error('Error to send delivery order to sap' + error)
				return Promise.reject(error)
			})
	}
}

export function uploadFilesQualityCertificates(files, id, folderName, isFromViewCreate = false) {
	return (dispatch, getState) => {
		// dispatch(isSavingAddMaterial(true))
		dispatch(isDonwloadingReport(false))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/${id}/files/${folderName}`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse ? serverResponse.data[0] : {}, folderName, isFromViewCreate))
					// dispatch(isSavingAddMaterial(false))
					dispatch(isDonwloadingReport(false))

					return Promise.resolve()
				}
			})
			.catch((error) => {
				// dispatch(isSavingAddMaterial(false))
				dispatch(isDonwloadingReport(false))

				console.error('Error to upload evidence file(s) from delivery order', error.response)
				return Promise.reject()
			})
	}
}

export function downloadOrdersReportByRangeDate(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(isDonwloadingReport(true))
		let companyCode = getState().profile.get('selectedCompany').get('company_code')
		let data = {
			CompanyCode: companyCode,
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/downloadreport/byrangedate`
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer', headers: configuration.headers  })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_general_ordenes.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading report of delivery orders', error.response)
				return Promise.reject()
			})
	}
}

export function removeFilesQualityCertificates(fileName, id, folderName, isFromViewCreate = true) {
	return function (dispatch, getState) {
		let user = getState().oidc.user
		dispatch(isDonwloadingReport(true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/${id}/remove/${fileName}/${folderName}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDonwloadingReport(false))
					dispatch(removeFile(fileName, folderName, isFromViewCreate))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error to remove evidence file(s) from delivery order', error.response)
				return Promise.reject()
			})
	}
}

/**
 *Download report of quotes in process by supplier
 *
 * @export
 * @returns
 */
export function downloadUploadFiles(id, folderName, fileName, fileExtension) {
	return (dispatch, getState) => {
		dispatch(isDonwloadingReport(true))
		let endPoint = `${process.env.REACT_APP_SIP_PLANNIG_FERSA}DeliveryOrder/${id}/files/${fileName}/${folderName}/${fileExtension}`
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		return axios.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([response.data], {
						type: onGetBlobType(fileExtension),
					})
					let url = window.URL.createObjectURL(blob);
					window.open(url)
					// let a = document.createElement('a');
					// a.href = url;
					// a.download = "Reporte_cotizaciones_proveedor.xlsx";
					// a.click();
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))

				console.error("Error downloading upload file", error.response)
				return Promise.reject();
			})
	}
}