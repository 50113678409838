//Import react section
import React, { Component } from 'react'
import moment from 'moment'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
//import material ui section
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

/**
 * CalendarEventDialog
 */
class CalendarEventDialog extends Component {
	/**
	 *Creates an instance of CalendarEventDialog.
	 * @param {*} props
	 * @memberof SendRequestDialog
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	onRowClick(id) {
		this.props.history.push(`/transportTenderShow/${id}`)
	}

	render() {
		const { classes, item } = this.props
		return (
			<Dialog open={this.props.open}>
				<DialogTitle id='form-dialog-title'>{'Evento en calendario seleccionado'}</DialogTitle>
				<DialogContent>
					<div className={classes.dialogRoot}>
						<div className={classes.title}>
							<Typography variant='caption' gutterBottom>
								{item && item.event && item.event.title}
							</Typography>
						</div>
						<Typography variant='caption' gutterBottom>
							<strong>Hora inicio: </strong>
							{moment.utc(item && item.event && item.event.start).format('DD/MM/YYYY HH:mm:ss')}
						</Typography>
						<Typography variant='caption' gutterBottom>
							<strong>Hora Fin: </strong>
							{moment.utc(item && item.event && item.event.end).format('DD/MM/YYYY HH:mm:ss')}
						</Typography>
						<Button
							fullWidth
							variant='contained'
							color='primary'
							onClick={() => {
								this.onRowClick(item.event.id)
							}}
							disabled={this.props.isSaving}
						>
							Ver licitación
						</Button>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.onOpenCalendarOption} color='primary'>
						<Translate id='common.close' />
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = (theme) => ({
	dialogRoot: {
		minHeight: '100px',
	},
	sampleRequired: {
		color: theme.palette.primary.light,
	},
	deleteButton: {
		color: 'red',
	},
	title: {
		padding: '10px',
		border: '1px solid rgba(67, 119, 196, 1)',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(CalendarEventDialog))
