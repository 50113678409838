
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import Select from 'react-select'
import moment from 'moment'

/** Material-UI imports section */
import { Grid, Paper, Typography, Toolbar,
    TextField } from '@material-ui/core';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import DatePicker from '../../common/DateTimePicker/DatePicker';
import { GetTrackingContractType } from '../../../store/helpers/SelectOptions';

import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import Evidence from '../../common/Evidence';

/**
 * Dialog to AddContractModal
 */
class AddContractModal extends Component {

    /**
     *Creates an instance of AddContractModal.
     * @param {*} props
     * @memberof AddContractModal
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            contract: {
                attachments: [],
                activationDate: "",
                expirationDate: "",
                name: "",
                notes: "",
                contractType: 0,
            }
        }
    }

    /**
     * Action to can be  information
     */
    canBeSaved() {
        if (this.props.isSaving)
            return false;
        else {
            let contract = this.state.contract;
            if (contract.activationDate.length === 0)
                return false;
            if (contract.expirationDate.length === 0)
                return false;
            if (moment(contract.expirationDate).isBefore(contract.activationDate))
                return false;
            if (contract.attachments.length <= 0)
                return false;
        }
        return true;
    }

    /**
    * Update the properties in state
    *
    * @param {*} user
    * @param {*} value
    * @memberof View
    */
    updateProperty(property, value) {
        let contractpdate = { ...this.state.contract };
        contractpdate[property] = value;
        this.setState({
            contract: contractpdate
        })
        if(property === 'contractType' && (value === 2 || value === 3))
        {
            contractpdate['activationDate'] = moment();
            this.setState({
                contract: contractpdate
            })
        }
        if(property === 'contractType' && value === 2 )
        {
            contractpdate['expirationDate'] =  moment();
            this.setState({
                contract: contractpdate
            })
        }
    }

    /**
     * Remove files
     */
    removeFiles(fileName) {
        let attachments = this.state.contract.attachments.filter(file => {
            return file.name !== fileName
        })
        this.setState({
            contract: {
                ...this.state.contract,
                attachments
            }
        })
        return Promise.resolve('Ok')
    }

    /**
     * Add file
     * @param {*} attachments 
     */
    uploadFiles(files) {
        let filesList = this.state.contract.attachments;
        if (files.length > 0) {
            for (var index = 0; index < files.length; index++) {
                filesList.push(files[index])
            }
        }
        this.setState({
            contract: {
                ...this.state.contract,
                attachments: filesList
            }
        })
        return Promise.resolve('Ok')
    }

    /**
     * handle event on close or on cancel to clean state of modal
     *
     * @memberof AddActionToListDialog
     */
    handleOnCloseCancel() {
        this.setState({
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            contract: {
                attachments: [],
                activationDate: "",
                expirationDate: "",
                supplierSelected: null,
                name: "",
                notes: "",
                contractType: 0,
            }
        })
        if (this.props.onClose)
            this.props.onClose();
    }

     /**
     * Function to create
     * @param {*} worker 
     */
    onAddNewContract() {
        this.props.createNewContract(this.state.contract)
            .then(() => {
                this.setState({
                    contract: {
                        attachments: [],
                        activationDate: "",
                        expirationDate: "",
                        supplierSelected: null,
                        name: "",
                        notes: "",
                        contractType: 0,
                    }
                })
            }).catch(() => {
            });
    }

    /**
     * Dialog to update information
     */
    render() {
        const { classes } = this.props;
        let open = this.props.open ? this.props.open : false;
        let options = GetTrackingContractType()
        return (
            <Paper style={{ overflowX: 'hidden' }}>
                <Dialog
                    style={{ overflowX: 'hidden' }}
                    open={open}
                    onClose={this.props.onClose}
                    header={
                        <Toolbar>
                            <Typography variant="h6">
                                {<Translate id="constracts.addDocument" />}
                            </Typography>
                        </Toolbar>
                    }

                    actions={
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => this.handleOnCloseCancel()}
                            onClose={this.props.onClose}
                            onSaveItem={this.onAddNewContract}
                            isSaving={this.props.isSaving}
                            canBeSaved={this.canBeSaved()}
                        />
                    }>
                    <Grid container style={{ maxWidth: 500}} justify='space-between'>
                        <Grid item xs={5}>
                            <Typography variant="caption" color={'textSecondary'} style={{ paddingBottom: "1rem"}}>{<Translate id="constracts.contractType" />}</Typography>
                            <Select 
                                value={options[this.state.contract.contractType]}
                                options={options}
                                onChange={(event) => { this.updateProperty("contractType", event.value) }}
                                menuPosition="fixed" 
                                menuPlacement={"auto"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" color={'textSecondary'}>{<Translate id="constracts.nameDocument" />}</Typography>
                            <TextField 
                                fullWidth
                                label={""}
                                value={this.state.contract.name}
                                onChange={(event) => { this.updateProperty("name", event.target.value) }}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{marginLeft:"6px"}} variant="caption" color={'textSecondary'}><Translate id="officialNotices.activationDate" /></Typography>
                            <DatePicker
                                onChangeRange={(event) => { this.updateProperty('activationDate', event) }}
                                startDate={this.state.contract.activationDate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{marginLeft:"6px"}} variant="caption" color={'textSecondary'}><Translate id="officialNotices.expirationDate" /></Typography>
                            <DatePicker
                                onChangeRange={(event) => { this.updateProperty('expirationDate', event) }}
                                startDate={this.state.contract.expirationDate}
                                minDate={this.state.contract.activationDate ? moment(this.state.contract.activationDate, 'DD/MM/YYYY').add(1, 'days') : moment()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{marginLeft:"6px"}} variant="caption" color={'textSecondary'}>{<Translate id="common.comments" />}</Typography>
                            <TextField fullWidth
                                id="outlined-multiline"
                                label={""}
                                value={this.state.contract.notes}
                                onChange={(event) => { this.updateProperty("notes", event.target.value) }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br/>
                            <Evidence
                                baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                                removeFiles={this.removeFiles}
                                uploadFiles={this.uploadFiles}
                                files={this.state.contract.attachments}
                                acceptedFiles={['pdf']}
                                disabledLink
                                text={this.props.dropzoneText}
                            />
                        </Grid>
                    </Grid>
                </Dialog>
            </Paper>
        )
    }
}

AddContractModal.defaultProps = {
    open: false,
}


const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
    },
});

export default withStyles(dialogStyles, { withTheme: true })(AddContractModal);