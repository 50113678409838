import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import {
    withStyles, Typography, Card, CardHeader, Avatar
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import {
    COLOR_STATUS_WARNING,
    COLOR_STATUS_DANGER,
    COLOR_STATUS_ALERT_INFO
} from '../../store/helpers/StatusColorConstants'

const WarningComponent = (props) => {
    /**String props section */
    const {title, message, warningError} = props
    // /**Bools props section */
    const { classes } = props

    const renderContainer = () => {
        return <Card
            elevation={4}
            className={warningError ? classes.cardError : classes.card }
        >
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        <WarningIcon className={classes.warninIcon} />
                    </Avatar>
                }
                title={title}
                subheader={<Typography align='justify'>{message}</Typography>}
            />
        </Card>
    }

    /**
     * Render componet 
     */
    return renderContainer()
}

const styles = (theme) => ({
    tab: {
        minHeight: "auto",
    },
    warninIcon: {
        color: COLOR_STATUS_DANGER
    },
    avatar: {
        backgroundColor: COLOR_STATUS_WARNING
    },
    card: {
        backgroundColor: COLOR_STATUS_ALERT_INFO,
        marginTop:"5px"

    },
    cardError:{
        backgroundColor: COLOR_STATUS_DANGER,
        marginTop:"5px"
    }

});

WarningComponent.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
}

WarningComponent.defaultProps = {
    isLoading: false,
}

export default withRouter(
    withStyles(styles, { withTheme: true })(WarningComponent)
);
