import React from 'react';
import propTypes from 'prop-types';
//import numeral from 'numeral';

/** Material UI import section */
import { List, ListItem, ListItemText, Typography, ListItemSecondaryAction } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

/** Resources import section */
// import { applyCurrencyFormat } from '../../../store/helpers/StringHelper';

/**
 * Materials Panel component
 */
const MaterialsInformation = (props) => {
	const { usedMaterials, removedMaterials } = props;

	const classes = useStyles();

	const renderUsedMaterial = (material) => {
		return (
			<ListItem divider key={material.Code} className={classes.usedMaterialRow}>
				<Typography key="material-description" variant="button" className={classes.usedMaterialName}>
					{material.Description}
				</Typography>
				<Typography
					key="material-warranty"
					align="right"
					color="textSecondary"
					variant="button"
					className={classes.usedMaterialWarranty}
				>
					{material.WarrantyType}
				</Typography>
				<Typography
					key="material-quantity"
					align="right"
					color="textSecondary"
					variant="button"
					className={classes.usedMaterialDetail}
				>
					{material.Quantity} {material.Measurement}
				</Typography>
				{/* <Typography
					key="material-unit-cost"
					align="right"
					color="textSecondary"
					variant="button"
					className={classes.usedMaterialDetail}
				>
					{applyCurrencyFormat(material.Price)}
				</Typography> */}
			</ListItem>
		);
	};

	const renderRemovedMaterials = (material) => {
		return (
			<ListItem divider className={classes.usedMaterialRow}>
				<ListItemText
					secondary={
						<React.Fragment>
							<Typography component="span" variant="caption" className={classes.inline}>
								{material.Code} {material.Description}
								<br />
							</Typography>
							<Typography component="span" variant="caption" className={classes.inline}>
								<strong>
									{'FALLA: '}
									{material.FailureDescription}
									<br />
								</strong>
							</Typography>
							<Typography component="span" variant="caption" className={classes.inline}>
								<strong>
									{'CAUSA: '}
									{material.FailureReasonDescription}
								</strong>
							</Typography>
						</React.Fragment>
					}
				/>
				<ListItemSecondaryAction>
					<Typography style={{ margin: '5px' }} color="textSecondary" variant="button" className={classes.inline}>
						{material.Quantity} {material.Measurement}
					</Typography>
				</ListItemSecondaryAction>
			</ListItem>
		);
	};

	return (
		<List style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '73vh' }}>
			<ListItem divider key="used" className={classes.outlinedRoot}>
				<ListItemText
					secondary={
						<React.Fragment>
							<Typography
								//style={{ color: COLOR_PRIMARY_NORMAL }}
								component="span"
								variant="button"
								className={classes.inline}
							>
								<strong>{'Usados'}</strong>
							</Typography>
						</React.Fragment>
					}
				/>
			</ListItem>
			{usedMaterials.map((material) => renderUsedMaterial(material))}

			<ListItem
				key="removed"
				style={{
					//backgroundColor: COLOR_PRIMARY_LIGHT,
					//color: COLOR_PRIMARY_PRESSEND
				}}
			>
				<ListItemText
					secondary={
						<React.Fragment>
							<Typography
								//style={{ color: COLOR_PRIMARY_NORMAL }}
								component="span"
								variant="button"
								className={classes.inline}
							>
								<strong>{'Eliminados'}</strong>
							</Typography>
						</React.Fragment>
					}
				/>
			</ListItem>
			{removedMaterials.map((material) => (
				<div key={material.code}>{renderRemovedMaterials(material)}</div>
			))}
		</List>
	);
};

MaterialsInformation.propTypes = {
	usedMaterials: propTypes.array.isRequired,
	removedMaterials: propTypes.array.isRequired
};

MaterialsInformation.defaultProps = {
	usedMaterials: [],
	removedMaterials: []
};

const useStyles = makeStyles(() => ({
	usedMaterialRow: {
		margin: '2px 5px',
		//borderLeft: `3px solid ${COLOR_ALTERNATE_NORMAL}`,
		display: 'flex'
	},
	usedMaterialName: {
		flex: '6 1 0'
	},
	usedMaterialWarranty: {
		flex: '1 1 0'
	},
	usedMaterialDetail: {
		flex: '2 1 0'
	}
}));

export default MaterialsInformation;
