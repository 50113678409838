
import React from 'react'
export function StepsForAddCriteriaHelper(value) {
    let steps = [
        {
            selector: '[data-tut="reactour-criteria"]',
            content: ({ goto }) => (
                <div>
                    Selecciona el tipo de criterio.<br />
                    Item (para que la evaluación sea por cada material o servicio).<br />
                    General (para que la evaluación sea para todos los materiales o servicios).
                </div>)

            // observe: '[data-tour="observable-parent"]'
        },
        {
            selector: '[data-tut="reactour-name-criteria"]',
            content: ({ goto }) => (
                <div>
                    Agrega el nombre que identificará tu criterio.<br />
                    Ejemplo: <strong>Precio</strong>
                </div>),
        },
        {
            selector: '[data-tut="reactour-weighing"]',
            content: ({ goto }) => (
                <div>
                    Agrega peso que tendrá el criterio.<br />
                    Ejemplo: <strong>50</strong>
                </div>),
        },
        {
            selector: '[data-tut="reactour-grouper"]',
            content: ({ goto }) => (
                <div>
                    Agrega o selecciona la categorización del criterio.<br />
                    Si deseas crear uno nuevo escribe en el selector y presiona enter.<br />
                    Ejemplo: <strong>Precio</strong>
                </div>),
        },
        {
            selector: '[data-tut="reactour-criteriaType"]',
            content: ({ goto }) => (
                <div>
                    Agrega o selecciona el tipo de criterio.<br />
                    Esté determinará el tipo de respuesta del poveedor <br />
                    Ejemplo: <strong>Número</strong>
                </div>),
        },
        {
            selector: '[data-tut="reactour-measurementUnit"]',
            content: ({ goto }) => (
                <div>
                    Selecciona una unidad de medida
                </div>),
        },
        {
            selector: '[data-tut="reactour-format"]',
            content: ({ goto }) => (
                <div>
                    Selecciona el formato (Representada en número, texto, decimal) de la respuesta del criterio.
                </div>),
        },
        {
            selector: '[data-tut="reactour-rule1"]',
            content: ({ goto }) => (
                <div>
                    Selecciona la regla de calificación
                </div>),
        },
        {
            selector: '[data-tut="reactour-minimumRange"]',
            content: ({ goto }) => (
                <div>
                    Selecciona el rango minímo de la respuesta. <br />
                    <strong>Nota:</strong> el valor no puede ser mayor al valor máximo
                </div>),
        },
        {
            selector: '[data-tut="reactour-maximumRange"]',
            content: ({ goto }) => (
                <div>
                    Selecciona el rango máximo de la respuesta. <br />
                    <strong>Nota:</strong> el valor no puede ser menor al valor minímo
                </div>),
        },
        {
            selector: '[data-tut="reactour-comments"]',
            content: ({ goto }) => (
                <div>
                    Agrega una descripción detallada del criterio.
                </div>),
        },
        {
            selector: '[data-tut="reactour-save"]',
            content: ({ goto }) => (
                <div>
                    Guarda tu criterio configurado
                </div>),
        },
    ]
    return steps
}
