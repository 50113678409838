/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Select from 'react-select'

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import {
    Card, Chip, Typography, CardContent, Grid,
    TextField, Divider
} from "@material-ui/core";
import { Translate } from "react-localize-redux";

/**Import components section*/

/**Import resources section*/
import { COLOR_STATUS_BLUE } from '../../../store/helpers/StatusColorConstants';
import { GetBusinessTurnType, GetBusinessType, getSelectedValue } from '../../../store/helpers/SelectOptions';
import {canBeEditSupplier} from '../../../store/helpers/SustainabilityHelper'
import {getTotalWorkDetail} from '../../../store/sustainability2/SustainabilityResults'
import {SustainabilityStatus} from '../../../store/helpers/AppConstants'
import GeneralInformationMen from '../../../resources/images/GeneralInformationMen.png'
import GeneralInformationWoman from '../../../resources/images/GeneralInformationWoman.png'

/**Import actions section */
const GeneralInformation = (props) => {
    const { classes, sustainability, totalWorkerDetail, userRolesByCompany, userProfile} = props;
    const {updateProperty, updatePropertyDetail} = props
    let optionsGetBusinessTurnType = GetBusinessTurnType();
    let optionsBusinessType = GetBusinessType();
    let canBeEdit = (canBeEditSupplier(sustainability, userRolesByCompany, userProfile) && sustainability.Status === SustainabilityStatus.SEND_SUPPLIER);
    let totalWorkDetail = getTotalWorkDetail(sustainability, totalWorkerDetail);

    /**
     * Render text field
     * @param {} value 
     * @param {*} title 
     */
    const renderTextField = (key, value, detailId, isDisable) => {
        return (
            <Grid item xs>
                <TextField
                    size="small"
                    fullWidth
                    className={classes.textField}
                    name={key}
                    type='number'
                    margin="dense"
                    variant="outlined"
                    defaultValue={value}
                    disabled={!isDisable}
                    InputProps={{ classes: { input: classes.inputProps } }}
                    onBlur={(event) => updatePropertyDetail(key, event.target.value, detailId)}
                />
            </Grid>
        )
    }

    const renderSelect = (key, translationId, options) => {
        let selectedValue = getSelectedValue(options, sustainability[key]);
        return (
            <Grid item xs>
                <Typography color="primary">{translationId}</Typography>
                <Select
                    value={selectedValue === undefined ? "0" : selectedValue}
                    options={options}
                    onChange={(event) => updateProperty(key, event.value)}
                    styles={{
                        menu: (base) => ({ ...base, zIndex: 62 }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 })
                    }}
                    menuPortalTarget={document.parentNode}
                    menuPlacement={'bottom'}
                    isDisabled={!canBeEdit}
                />
            </Grid>
        )
    }

    return (
        <Card>
            <CardContent>
                <Grid item xs={12} container spacing={24}>
                    {renderSelect("BusinessTurn", <Translate id='sustainability.businessActivity'/>,  optionsGetBusinessTurnType)}
                    {renderSelect("BusinessType", <Translate id='sustainability.companyType'/>, optionsBusinessType)}
                    <Grid item xs>
                        <Typography color="primary">{<Translate id='sustainability.salesDescription'/>}</Typography>
                        <TextField
                            className={classes.textFile}
                            fullWidth
                            name='name'
                            margin="dense"
                            variant="outlined"
                            defaultValue={sustainability.BusinessDescription}
                            disabled={!canBeEdit}
                            InputProps={{ classes: { input: classes.inputTextProps } }}
                            onBlur={(event) => updateProperty("BusinessDescription", event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Divider className={classes.dividerLine} />
                <div className={classes.contentHeader} >
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10} container spacing={24}>
                            <Grid item xs={6} ><Typography color="primary"><Chip style={{marginLeft:"20px"}} className={classes.contentTittle} component="span" label={<Translate id="sustainability.workers.women"/>} disabled /></Typography> </Grid>
                            <Grid item xs={6}><Typography color="primary"><Chip className={classes.contentTittle} component="span" label={<Translate id="sustainability.workers.men"/>} disabled /></Typography></Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container>
                        <Grid item xs><Typography color="primary">{<Translate id='sustainability.workers.employees'/>}</Typography></Grid>
                        <Grid item xs style={{textAlign: 'center'}}><Typography color="primary">{<Translate id='sustainability.workers.administrative'/>}</Typography></Grid>
                        <Grid item xs style={{textAlign: 'center'}}><Typography color="primary">{<Translate id='sustainability.workers.operational'/>}</Typography></Grid>
                        <Grid item xs style={{textAlign: 'center'}}><Typography color="primary">{<Translate id='sustainability.workers.administrative'/>}</Typography></Grid>
                        <Grid item xs style={{textAlign: 'center'}}><Typography color="primary">{<Translate id='sustainability.workers.operational'/>}</Typography></Grid>
                    </Grid>

                    {sustainability.WorkerDetails.map((workerDetail) => {
                        return (<Grid key={workerDetail.Id} item xs={12} className={classes.workDetail}  container>
                                <Grid item xs><br /><Typography >{<Translate id={workerDetail.Name}/>}</Typography></Grid>
                                {renderTextField("AdministrativeWoman", workerDetail.AdministrativeWoman, workerDetail.Id, canBeEdit)}
                                {renderTextField("OperationalWoman", workerDetail.OperationalWoman,  workerDetail.Id, canBeEdit)}
                                {renderTextField("AdministrariveMen", workerDetail.AdministrariveMen,  workerDetail.Id, canBeEdit)}
                                {renderTextField("OperationalMen", workerDetail.OperationalMen,  workerDetail.Id, canBeEdit)}
                            </Grid>
                            )
                        })
                    }
                </div>

                <Divider className={classes.dividerLineBlue} />
                
                <Grid item xs={12} container>
                    <Grid container item xs={12}>
                        <Grid item xs={8}><Typography color="primary">{<Translate id='sustainability.total'/>}</Typography></Grid>
                        <Grid item xs={2} style={{textAlign: 'center'}}><Typography color="primary">{<Translate id='sustainability.workers.administrative'/>}</Typography></Grid>
                        <Grid item xs={2} style={{textAlign: 'center'}}><Typography color="primary">{<Translate id='sustainability.workers.operational'/>}</Typography></Grid>
                    </Grid>
                    <Grid container item xs={12} justify="center" alignItems="center">
                        <Grid item xs={8} className={classes.icon}><img src={GeneralInformationWoman} alt="generalInformationWoman"/></Grid>
                            <Chip className={classes.totalChip} label={totalWorkDetail.TotalAdministrativeWoman} />
                            <Chip className={classes.totalChip}  label={totalWorkDetail.TotalOperationalWoman} />
                    </Grid>
                    <Grid container item xs={12}  justify="center" alignItems="center">
                        <Grid item xs={8} className={classes.icon}><img src={GeneralInformationMen} alt="generalInformationMen"/></Grid>
                            <Chip className={classes.totalChip} label={totalWorkDetail.TotalAdministrativeMen} />
                            <Chip className={classes.totalChip}  label={totalWorkDetail.TotalOperationalMen} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    {sustainability.Description && <Typography variant="subtitle2">
                        <br/>
                        {<strong style={{color:COLOR_STATUS_BLUE}}>{<Translate id='sustainability.commentsForSupplier'/>}</strong>}: {sustainability.Description}
                    </Typography>}
                </Grid>
            </CardContent>
        </Card>
    )
};

const styles = (theme) => ({
    contentHeader: {
        direction: "row",
        justify: "space-between",
        alignItems: "center",
        padding: 3,
    },
    contentTittle: {
        width: '100%',
    },
    textField: {
        padding: 3,
    },
    textFile:{
        top:"-8px",
    },
    inputProps: {
        height: 6,
    },
    inputTextProps: {
        height: 6,
        fontSize: 12
    },
    dividerLine: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    workDetail: {
        '& > *': {
            margin: '5px',
    }},
    dividerLineBlue: {
        marginTop: "10px",
        marginBottom: "10px",
        backgroundColor: COLOR_STATUS_BLUE,
        height: '2px'
    },
    icon: {
        textAlign: 'right', 
        marginRight: '5px', 
        marginTop: '10px',
    },
    totalChip:{
        width: "15% !important",
        margin: "5px",
        display: "flex !important",
        justifyContent: "center !important",
    },
});

GeneralInformation.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    sustainability: PropTypes.object.isRequired,
};

GeneralInformation.defaultProps = {
    sustainability: {
        WorkerDetails:[],
    }
};

export default withRouter(
    withStyles(styles, { withTheme: true })(GeneralInformation)
);
