/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import uuidv4 from 'uuid/v4'
/**Import material UI Section*/

import {
    Typography,
    withStyles,
} from "@material-ui/core";

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { Translate } from "react-localize-redux";

/**Import components section*/
import {
    onGetHeaderTableIndirectQualificationItemTypeType,
    onGetHeaderTableIndirectQualificationSectionType
} from '../../../../store/helpers/IndirectQualificationsHelpers';
import { BACKGROUND_COLOR_GRAY,
    COLOR_STATUS_SUCCESS, COLOR_STATUS_WARNING, COLOR_STATUS_DANGER} from '../../../../store/helpers/StatusColorConstants'
import { IsCurrentNotActiveDirectoryUser } from '../../../../store/helpers/SessionHelper'
import { useIndirectQualificationCustomHookShow } from '../../customHooks/UseCustomHookShow'
import { Evaluationclassification } from '../../../../store/helpers/AppConstants'
import {GetTotalRatingSupplier}  from '../../../../store/helpers/IndirectQualificationsHelpers' 

const QualificationHeaderTable = (props) => {
    const { user } = useIndirectQualificationCustomHookShow()

    /**String props section */
    // const { } = props
    /**Bools props section */
    /**Arrays props section */
    const { indirectQualification, translate } = props
    /**Functions props section */
    //const {} = props
    /**Objects props section */
    const { classes } = props
    let isUserNotActiveDirectory = IsCurrentNotActiveDirectoryUser(user)
    let totalRating = GetTotalRatingSupplier(indirectQualification)

    const GetColorEvaluation = (description) =>{
        switch(description)
        {
            case Evaluationclassification.RELIABILITY_SUPPLIER:
            case Evaluationclassification.RELIABILITY_WHITH_OPPORTUNITY:
                return COLOR_STATUS_SUCCESS;
            case Evaluationclassification.REGURAR_WHITH_OPPORTUNITY:
                return COLOR_STATUS_WARNING;
            case Evaluationclassification.UNREALIABLE_SUPPLIER:
                return COLOR_STATUS_DANGER;
            default:
                return "";
        }
    }

    const GetDescriptionEvaluation = (description) =>{
        switch(description)
        {
            case Evaluationclassification.RELIABILITY_SUPPLIER:
                return <Translate id="indirectqualifications.clasification.reliableSupplier" />;
            case Evaluationclassification.RELIABILITY_WHITH_OPPORTUNITY:
                return <Translate id="indirectqualifications.clasification.reliableWithOpportunity" />;
            case  Evaluationclassification.REGURAR_WHITH_OPPORTUNITY:
                return <Translate id="indirectqualifications.clasification.regularWithOpportunity" />;
            case Evaluationclassification.UNREALIABLE_SUPPLIER:
                return <Translate id="indirectqualifications.clasification.unreliableSupplier" />;
            default:
                return <Translate id="indirectqualifications.clasification.withoutEvaluating" />;
        }
    }


    return (

        <TableHead key={uuidv4()}>
            <TableRow>
                <TableCell
                    colSpan={isUserNotActiveDirectory ? 3 : 6}
                    className={classes.tableCellHeader}
                    key={uuidv4()}
                    align="left"
                >
                    <Typography variant="subtitle2" color="primary">{indirectQualification ? indirectQualification.Name : ""}</Typography>
                    {indirectQualification && indirectQualification.Comments && <Typography variant="subtitle2">{`Comentarios: ${indirectQualification ? indirectQualification.Comments : ""}`}</Typography>}
                </TableCell>
                {
                    isUserNotActiveDirectory &&
                    <TableCell
                        colSpan={3}
                        className={classes.tableCellHeader}
                        key={uuidv4()}
                        align="center"
                        style={{backgroundColor: GetColorEvaluation(totalRating.DescriptionEvaluation)}}
                    >
                        <Typography variant="subtitle2" className={classes.colorTextWhite}>
                            <Translate id="indirectqualifications.show.ratingFinal" />: {totalRating.TotalRating} % {GetDescriptionEvaluation(totalRating.DescriptionEvaluation, translate)}
                        </Typography>

                    </TableCell>
                }
                {
                    onGetHeaderTableIndirectQualificationSectionType(indirectQualification).map(column => {
                        return (<TableCell
                            colSpan={column.columnSpam}
                            className={classes.tableCellHeader}
                            style={{ backgroundColor: column.backgroundColor }}
                            key={uuidv4()}
                            align="center" >
                            {column.title}
                        </TableCell>)
                    })
                }
            </TableRow>
            <TableRow>
                {
                    onGetHeaderTableIndirectQualificationItemTypeType(indirectQualification, isUserNotActiveDirectory).map(column => {
                        return (<TableCell
                            padding={column.title ? "" : "none"}
                            style={{ backgroundColor: column.backgroundColor ? column.backgroundColor : BACKGROUND_COLOR_GRAY }}
                            className={classes.tableCellHeader}
                            key={uuidv4()}
                            align="center" >
                            <strong>{column.title}</strong>
                        </TableCell>)
                    })
                }
            </TableRow>
        </TableHead>
    )
}

const styles = (theme) => ({
    tableCellHeader: {
        height: "15px",
        position: 'sticky',
        top: 0,
        zIndex: '30'
    },
    colorTextWhite: {
        color: "white"
    },
});

QualificationHeaderTable.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
    indirectQualifications: PropTypes.object.isRequired
}

QualificationHeaderTable.defaultProps = {
    isLoading: false,
    indirectQualifications: []
}

export default withRouter(
    withStyles(styles, { withTheme: true })(QualificationHeaderTable)
);
