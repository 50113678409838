import React from 'react'
import moment from 'moment'
import { useState } from 'react'
import Select from 'react-select'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'

/** Material UI icons imports section */
import AlertIcon from '@material-ui/icons/ErrorOutline'

/** Import component */
import Toaster from '../../common/Toaster'
import DatePicker from '../../common/DateTimePicker/DatePicker'
import { GetRampsFersaFromRelease, GetTimeToLockCalendar } from '../../../store/helpers/SelectOptions'

const FormView = (props) => {
	const [stateValue, setStateValue] = useState({
		sshowToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		calendarLock: {
			message: '',
			number_ramps: '',
			all_days: false,
			by_date: false,
			by_period: false,
			is_monday: false,
			is_tuesday: false,
			is_wednesday: false,
			is_thursday: false,
			is_friday: false,
			is_saturday: false,
			time_hour_lock_start: moment(),
			time_hour_lock_end: moment(),
			time_hour_start: '',
			time_hour_end: '',
		},
	})

	const renderFormControlCheckBox = (property, translationId) => {
		return (
			<FormControlLabel
				control={
					<Checkbox
						checked={stateValue.calendarLock[property]}
						onChange={(event) => {
							updateProperty(property, event.target.checked)
						}}
					/>
				}
				label={<Translate id={translationId} />}
			/>
		)
	}

	const updateProperty = (property, value) => {
		let creatcalendarLock = { ...stateValue.calendarLock }
		creatcalendarLock[property] = value
		setStateValue((prev) => ({
			...prev,
			calendarLock: creatcalendarLock,
		}))
	}

	const renderDaysWeekSection = () => {
		return (
			<React.Fragment>
				<FormGroup row>
					{renderFormControlCheckBox('is_monday', 'releaseCalendarLock.isMonday')}
					{renderFormControlCheckBox('is_tuesday', 'releaseCalendarLock.isTuesday')}
					{renderFormControlCheckBox('is_wednesday', 'releaseCalendarLock.isWednesday')}
				</FormGroup>
				<FormGroup row>
					{renderFormControlCheckBox('is_thursday', 'releaseCalendarLock.isThursday')}
					{renderFormControlCheckBox('is_friday', 'releaseCalendarLock.isFriday')}
					{renderFormControlCheckBox('is_saturday', 'releaseCalendarLock.isSaturday')}
				</FormGroup>
			</React.Fragment>
		)
	}

	const renderByPeriodSection = () => {
		return (
			<Grid item xs={12}>
				<Grid container spacing={24}>
					{renderDatePicker('time_hour_lock_start', 'releaseCalendarLock.startDate')}
					{renderDatePicker('time_hour_lock_end', 'releaseCalendarLock.endDate')}
				</Grid>
				<Grid item xs={12}>
					<br />
					<Typography variant='caption' color={'textSecondary'}>
						<strong>
							{
								'Seleccione el periodo de rango de fechas que requiere bloquear todos los dias al mismo horario en el periodo seleccionado.'
							}
						</strong>
					</Typography>
				</Grid>
			</Grid>
		)
	}

	const onChangeDate = (date, property) => {
		let creatcalendarLock = { ...stateValue.calendarLock }
		creatcalendarLock[property] = date
		setStateValue((prev) => ({
			...prev,
			calendarLock: creatcalendarLock,
		}))
	}
	const renderDatePicker = (property, translationId) => {
		return (
			<Grid item xs={6}>
				<Typography variant='caption' color={'textSecondary'}>
					<Translate id={translationId} />
				</Typography>
				<DatePicker
					onChangeRange={(date) => onChangeDate(date, property)}
					startDate={stateValue.calendarLock[property] ? stateValue.calendarLock[property] : ''}
					minDate={moment()}
				/>
			</Grid>
		)
	}

	const renderDatesSection = () => {
		return (
			<Grid item xs={12}>
				<Grid container spacing={24}>
					{renderDatePicker('time_hour_lock_start', 'releaseCalendarLock.startDate')}
					{renderDatePicker('time_hour_lock_end', 'releaseCalendarLock.endDate')}
				</Grid>
				<Grid item xs={12}>
					<br />
					<Typography variant='caption' color={'textSecondary'}>
						<strong>
							{
								'Seleccione la fecha para bloquear el calendario completo, en el rango de fecha seleccionado desde la fecha y hora inicio hasta la  fecha y hora fin.'
							}
						</strong>
					</Typography>
				</Grid>
			</Grid>
		)
	}
    
	const renderRampAndHourSection = () => {
		let rampsByType = GetRampsFersaFromRelease()
		let timeOptions = GetTimeToLockCalendar()
		return (
			<Grid item xs={6}>
				<Grid container spacing={24}>
					{renderSelect('number_ramps', 'releaseCalendarLock.rampsNumber', rampsByType, 12)}
					{renderSelect('time_hour_start', 'releaseCalendarLock.starHour', timeOptions, 6)}
					{renderSelect('time_hour_end', 'releaseCalendarLock.endHour', timeOptions, 6)}
				</Grid>
			</Grid>
		)
	}

	const renderSelect = (property, translationId, options, xs) => {
		return (
			<Grid item xs={xs}>
				<FormLabel component='legend'>
					<Translate id={translationId} />
				</FormLabel>
				<Select
					defaultValue={options[0]}
					options={options}
					fullWidth
					onChange={(event) => {
						updatePropertySelected(event.value, property)
					}}
					styles={{ menu: (base) => ({ ...base, zIndex: 10 }) }}
				/>
			</Grid>
		)
	}

	const updatePropertySelected = (option, property) => {
		let creatcalendarLock = { ...stateValue.calendarLock }
		creatcalendarLock[property] = option
		setStateValue((prev) => ({
			...prev,
			calendarLock: creatcalendarLock,
		}))
	}

	const renderCommentSection = () => {
		return (
			<Grid item xs={12}>
				<TextField
					fullWidth
					required
					id='message'
					label={<Translate id='releaseCalendarLock.lockRequiered' />}
					multiline
					rows='4'
					value={stateValue.calendarLock.message}
					onChange={(event) => {
						updateProperty('message', event.target.value)
					}}
					margin='normal'
					variant='outlined'
				/>
			</Grid>
		)
	}

	const canBeCreateReleaseCalendarLock = () => {
		if (props.canBeCreate) {
			props.canBeCreate(stateValue.calendarLock)
		}
	}

	const canBeSaved = () => {
		let calendarLock = stateValue.calendarLock
		if (
			calendarLock.message !== '' &&
			calendarLock.number_ramps !== '' &&
			calendarLock.time_hour_start !== '' &&
			calendarLock.time_hour_end !== '' &&
			((calendarLock.all_days && hasCheckedDay()) ||
				(calendarLock.by_date &&
					calendarLock.time_hour_lock_start !== '' &&
					calendarLock.time_hour_lock_end !== '') ||
				(calendarLock.by_period &&
					calendarLock.time_hour_lock_start !== '' &&
					calendarLock.time_hour_lock_end !== ''))
		)
			return true
		return false
	}

	const hasCheckedDay = () => {
		if (
			stateValue.calendarLock.is_monday ||
			stateValue.calendarLock.is_tuesday ||
			stateValue.calendarLock.is_wednesday ||
			stateValue.calendarLock.is_thursday ||
			stateValue.calendarLock.is_friday ||
			stateValue.calendarLock.is_saturday
		)
			return true
		return false
	}
	const handleCancel = () => {
		setStateValue((prev) => ({
			...prev,
			calendarLock: {
				message: '',
				number_ramps: '',
				all_days: false,
				by_date: false,
				by_period: false,
				is_monday: false,
				is_tuesday: false,
				is_wednesday: false,
				is_thursday: false,
				is_friday: false,
				is_saturday: false,
				time_hour_lock_start: moment(),
				time_hour_lock_end: moment(),
				time_hour_start: '',
				time_hour_end: '',
			},
		}))
		props.history.push(`/releasecalendarFersa`)
	}

	return (
		<Card>
			<CardHeader
				avatar={<AlertIcon color='primary' />}
				title={
					<Typography variant='subtitle2' color={'textSecondary'}>
						<Translate id='releaseCalendarLock.messageToAddCalendar' />
					</Typography>
				}
			/>
			<CardContent>
				<Grid container spacing={24} justify='center'>
					<Grid item xs={6}>
						<FormControl>
							<FormGroup row>
								{renderFormControlCheckBox('all_days', 'releaseCalendarLock.allDays')}
								{renderFormControlCheckBox('by_period', 'releaseCalendarLock.rereatByPreriod')}
							</FormGroup>
							{/** DaysOfWeek checks section*/}
							{stateValue.calendarLock.all_days && <FormGroup>{renderDaysWeekSection()}</FormGroup>}
							{/** Dates datepickers section*/}
							{stateValue.calendarLock.by_date && <FormGroup>{renderDatesSection()}</FormGroup>}
							{/** Dates by_period section*/}
							{stateValue.calendarLock.by_period && <FormGroup>{renderByPeriodSection()}</FormGroup>}
						</FormControl>
					</Grid>
					{/** RAMP AND HOUR SECTION */}
					{renderRampAndHourSection()}
					{/** COMMENT AND BUTTON SAVE SECTION */}
					{renderCommentSection()}
				</Grid>
			</CardContent>
			<CardActions>
				<Grid container spacing={24} justify='flex-end' style={{ margin: '0px 1rem' }}>
					<Grid item xs={1}>
						<Button
							color='primary'
							onClick={canBeCreateReleaseCalendarLock}
							disabled={!canBeSaved() || props.isSaving}
						>
							<Translate id='common.save' />
						</Button>
					</Grid>
				</Grid>
				<Grid item xs={1}>
					<Button onClick={handleCancel} disabled={props.isSaving}>
						<Typography variant='button' color='error'>
							<Translate id='common.cancel' />
						</Typography>
					</Button>
				</Grid>
			</CardActions>
			<Toaster
				message={stateValue.toasterMessage}
				open={stateValue.showToaster}
				variant={stateValue.toasterVariant}
				onClose={() => {
					setStateValue((prev) => ({
						...prev,
						showToaster: false,
					}))
				}}
			/>
		</Card>
	)
}

export default FormView
