import React from 'react'
import Select from 'react-select'
import { Translate } from 'react-localize-redux'
import { Typography } from '@material-ui/core'
import { getChargingWayOptions } from '../../../store/helpers/TransportTenderHelper'
const LoadTypeSelector = (props) => {
	const { setChange, classes, value, disabled } = props
	return (
		<div>
			<Typography variant='subtitle2'>{<Translate id='carrierTenders.chargingWay' />}</Typography>

			<Select
				placeholder={'Selecciona una forma de carga'}
				options={getChargingWayOptions()}
				onChange={(option) => setChange(option)}
				className={classes.titleSelect}
				styles={{
					menu: (base) => ({ ...base, zIndex: 10 }),
					//menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
					menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
					menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
				}}
				menuPlacement={'auto'}
				isLoading={false}
				value={value}
				isDisabled={disabled}
			/>
		</div>
	)
}

export default LoadTypeSelector
