import React, { useEffect, useCallback, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import queryString from 'query-string'

/** Import component section */
import { Avatar, Card, CardContent, CardHeader, Fab, Tooltip, Typography } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import AddIcon from '@material-ui/icons/AddTwoTone'

import SearchInput from '../common/SearchInput/SearchInput'
import AddCommonItem from '../common/AddCommonItem'
import { Translate, getTranslate } from 'react-localize-redux'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import { useRequestsCustomHookIndex } from './customHooks/useCustomHooksIndex'
import AddControlModal from './components/AddControlModal'
import LateralPanelContainer from '../common/LateralPanelComponents/LateralPanelContainer'
import ViewRequestComponent from './components/ViewRequest'
import ViewServiceComponent from './components/ViewService'
import DonwloadReportDialog from '../common/DonwloadReportDialog'
import { downloadHistorialControlCO2Excel, deleteRequest, loadVehicles } from '../../store/controlCO2/controlCO2Action'
import moment from 'moment'

const ControlCO2 = (props) => {
	const { classes, isBlocked, isAdminUser, disabledBlock } = props

	let location = useLocation()
	let history = useHistory()
	const dispatch = useDispatch()

	const queryParams = queryString.parse(location.search)
	const [openAddModal, setOpenAddModal] = useState(false)
	const translate = useSelector((state) => getTranslate(state.localize))
	const requestsCount = useSelector((state) => state.controlCO2.get('requestsCount'))
	const { requests, service, onAddRequest, onUpdateRequest } = useRequestsCustomHookIndex()
	const [identifierPanelLateral, setIdentifierPanelLateral] = useState('')
	const [modify, setModify] = useState(false)
	const [selectedItem, setSelectedItem] = useState({ destinationPlace: '' })
	const [opneModal, setOpenModal] = useState(false)
	const [newService, setNewService] = useState({
		lastVerificationDate: moment().format('YYYY-MM-DD'),
		lastDateOfService: moment().format('YYYY-MM-DD'),
		lastHoldingDate: moment().format('YYYY-MM-DD'),
		vehicleToken: '',
		originOfTransportation: '',
		TypeOfTransportation: '',
		FireExtinguisherInspection: moment().format('YYYY-MM-DD'),
		LastVerificationDateAttachments: [],
		lastDateOfServiceAttachments: [],
		lastHoldingDateAttachments: [],
		otherAttachments: [],
	})
	const [deletingRequestPanelConfig, setDeletingRequestPanelConfig] = useState({
		opened: false,
		deleting: false,
		requestToken: null,
	})

	const isLoading = useSelector((state) => state.controlCO2.get('isLoanding'))
	const isSaving = useSelector((state) => state.controlCO2.get('isSaving'))
	const modifyService = () => {
		setModify(!modify)
	}
	const getCounterMessage = () => {
		if (requests) {
			let totalRequests = requests.length
			return (
				<div>
					{<Translate id='common.showing' />}&nbsp;
					<span className={classes.showingCounter}>{totalRequests}</span>&nbsp;
					{<Translate id='common.of' />}&nbsp;
					<span className={classes.showingCounter}>{requestsCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	const removefilesLastVerificationDate = (fileName) => {
		let newControl = newService
		let newAttachments = newService.LastVerificationDateAttachments.filter((file) => {
			return file.name !== fileName
		})
		newControl.LastVerificationDateAttachments = newAttachments
		setNewService(newControl)
		return Promise.resolve('Ok')
	}
	const uploadFilesLastVerificationDate = (files) => {
		let filesList = newService
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.LastVerificationDateAttachments.push(files[index])
			}
		}
		setNewService(filesList)

		return Promise.resolve('Ok')
	}
	const removeFilesLastHoldingDate = (fileName) => {
		let newControl = newService
		let newAttachments = newService.lastHoldingDateAttachments.filter((file) => {
			return file.name !== fileName
		})
		newControl.lastHoldingDateAttachments = newAttachments
		setNewService(newControl)
		return Promise.resolve('Ok')
	}
	const uploadFilesLastHoldingDate = (files) => {
		let filesList = newService
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.lastHoldingDateAttachments.push(files[index])
			}
		}
		setNewService(filesList)

		return Promise.resolve('Ok')
	}
	const removeFilesLastDateOfServiceAttachments = (fileName) => {
		let newControl = newService
		let newAttachments = newService.lastDateOfServiceAttachments.filter((file) => {
			return file.name !== fileName
		})
		newControl.lastDateOfServiceAttachments = newAttachments
		setNewService(newControl)
		return Promise.resolve('Ok')
	}
	const uploadFilesLastDateOfServiceAttachments = (files) => {
		let filesList = newService
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.lastDateOfServiceAttachments.push(files[index])
			}
		}
		setNewService(filesList)
		return Promise.resolve('Ok')
	}
	const removeFilesOtherAttachments = (fileName) => {
		let newControl = newService
		let newAttachments = newService.otherAttachments.filter((file) => {
			return file.name !== fileName
		})
		newControl.otherAttachments = newAttachments
		setNewService(newControl)
		return Promise.resolve('Ok')
	}
	const uploadFilesOtherAttachments = (files) => {
		let filesList = newService
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.otherAttachments.push(files[index])
			}
		}
		setNewService(filesList)
		return Promise.resolve('Ok')
	}

	const OnUpdateProperty = (p, value) => {
		let updateService = newService
		updateService[p] = value
		setNewService(updateService)
	}

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	const handleDeleteRequestConfirmation = () => {
		setDeletingRequestPanelConfig((prevStatus) => ({ ...prevStatus, deleting: true }))
		dispatch(deleteRequest(deletingRequestPanelConfig.requestToken)).finally(() => {
			setDeletingRequestPanelConfig({
				opened: false,
				deleting: false,
				requestToken: null,
			})
		})
	}

	/** Load services and vehicles data */
	useEffect(() => {
		dispatch(loadVehicles())
	}, [dispatch])

	/**
	 * Show the confimation modal to add new vehicle.
	 */
	const openRequestDeleteConfirmationModal = (event) => {
		event.preventDefault()
		event.stopPropagation()
		const { id } = event.currentTarget
		console.log('🚀 ~ openServiceDeleteConfirmationModal ~ id:', id)
		setDeletingRequestPanelConfig((prevStatus) => ({ ...prevStatus, opened: true, requestToken: id }))
	}

	const onRenderCellItem = (dataSource, item) => {
		const [gasoline, oil, water, tires] = item.arrivalLevelsCheck.split('|')
		switch (dataSource) {
			case 'vehicleEconomicNumber':
				return `${item.vehicleEconomicNumber || '-'} | ${item.vehicleLicensePlate || '-'}`
			case 'gasoline':
				return gasoline
			case 'oil':
				return oil
			case 'water':
				return water
			case 'tires':
				return tires
			case 'options':
			return (
				<Tooltip title={<Translate id='controlCO2.deleteRequest' />}>
					<DeleteOutlinedIcon
						id={item.token}
						onClick={openRequestDeleteConfirmationModal}
						color='error'
					/>
				</Tooltip>
			)
			default:
				return null
		}
	}

	const onRowClick = (item, event) => {
		onLateralPanelConfig('IndexViewControl')
		setSelectedItem(item)
	}

	const onLateralPanelConfig = (identifier) => {
		setIdentifierPanelLateral(identifier)
		onOpenAndCloseLateralPanel()
	}

	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	const PanelLateralChildren = useCallback(
		{
			IndexViewService: (
				<ViewServiceComponent
					translate={translate}
					item={service}
					onCloseButtonClick={() => {
						setSelectedItem(null)
						setModify(false)
					}}
					modify={modify}
					setModify={modifyService}
					newService={newService}
					uploadFilesLastVerificationDate={uploadFilesLastVerificationDate}
					removefilesLastVerificationDate={removefilesLastVerificationDate}
					uploadFilesLastHoldingDate={uploadFilesLastHoldingDate}
					removeFilesLastHoldingDate={removeFilesLastHoldingDate}
					uploadFilesLastDateOfServiceAttachments={uploadFilesLastDateOfServiceAttachments}
					removeFilesLastDateOfServiceAttachments={removeFilesLastDateOfServiceAttachments}
					uploadFilesOtherAttachments={uploadFilesOtherAttachments}
					removeFilesOtherAttachments={removeFilesOtherAttachments}
					OnUpdateProperty={OnUpdateProperty}
					onCloseModal={() => onOpenAndCloseLateralPanel()}
				/>
			),
			IndexViewControl: (
				<ViewRequestComponent
					translate={translate}
					item={selectedItem}
					onCloseButtonClick={() => {
						setSelectedItem(null)
					}}
					service={service}
					onCloseServiceButtonClick={() => {
						setSelectedItem(null)
						setModify(false)
					}}
					modify={modify}
					setModify={modifyService}
					newService={newService}
					uploadFilesLastVerificationDate={uploadFilesLastVerificationDate}
					removefilesLastVerificationDate={removefilesLastVerificationDate}
					uploadFilesLastHoldingDate={uploadFilesLastHoldingDate}
					removeFilesLastHoldingDate={removeFilesLastHoldingDate}
					uploadFilesLastDateOfServiceAttachments={uploadFilesLastDateOfServiceAttachments}
					removeFilesLastDateOfServiceAttachments={removeFilesLastDateOfServiceAttachments}
					uploadFilesOtherAttachments={uploadFilesOtherAttachments}
					removeFilesOtherAttachments={removeFilesOtherAttachments}
					OnUpdateProperty={OnUpdateProperty}
					onCloseModal={() => onOpenAndCloseLateralPanel()}
					canBeModify={false}
					isSaving={isSaving}
					onUpdateRequest={onUpdateRequest}
					isAdminUser={isAdminUser}
					isBlocked={isBlocked}
					disabledBlock={disabledBlock}
				/>
			),
		},
		[identifierPanelLateral, selectedItem]
	)

	const handleCloseModalReport = () => {
		setOpenModal(!opneModal)
	}

	const handleDownloadReportByDateRange = (startDate, endDate) => {
		return dispatch(downloadHistorialControlCO2Excel(startDate, endDate))
	}

	const onDownloadControlReport = (startDate, endDate) => {
		handleDownloadReportByDateRange(startDate, endDate)
		handleCloseModalReport()
	}

	const { LateralPanelComponent, onOpenAndCloseLateralPanel } = LateralPanelContainer({
		children: PanelLateralChildren[identifierPanelLateral],
		identifier: identifierPanelLateral,
	})

	const getCardContent = () => {
		if (isLoading === true) {
			return <LoadingPage />
		} else if (requests === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<>
					<DataTable
						data={requests}
						configuration={TableConfiguration(isAdminUser)}
						onRenderCellItem={onRenderCellItem}
						onChangeSortCriteria={onChangeSortCriteria}
						onRowClick={onRowClick}
						isIndex
						showFilters
						totalRows={requestsCount}
						sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'FormatedFolio'}
						sortAscending={
							queryParams && queryParams.sortDirection
								? queryParams.sortDirection === 'desc'
									? false
									: true
								: false
						}
						page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
						rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				</>
			)
		}
	}

	return (
		<Card>
			<CardHeader
				avatar={<Avatar>Co2</Avatar>}
				action={
					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '5px' }}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={onChangeSearchValue}
								value={!!queryParams && queryParams.query ? queryParams.query : ''}
							/>
						</div>
						<Tooltip title={<Translate id='controlCO2.add' />}>
								<Fab
									className={classes.iconButon}
									size='small'
									color='primary'
									onClick={() => {
										setOpenAddModal(!openAddModal)
									}}
								>
									<AddIcon />
								</Fab>
							</Tooltip>
						<Tooltip title={<Translate id='controlCO2.report' />}>
							<Fab
								size='small'
								color={'primary'}
								className={classes.btnDownloadReport}
								onClick={handleCloseModalReport}
								isSaving={isLoading}
							>
								<DownloadIcon />
							</Fab>
						</Tooltip>
					</div>
				}
				title={<Typography variant='button'>{<Translate id='controlCO2.title' />}</Typography>}
				subheader={getCounterMessage()}
			/>

			<CardContent>{getCardContent()}</CardContent>

			<LateralPanelComponent />
			<DonwloadReportDialog
				title={<Translate id='controlCO2.titleModal' />}
				open={opneModal}
				onClose={handleCloseModalReport}
				onDonwloadItems={onDownloadControlReport}
			/>
			<AddCommonItem
					title={<Translate id='controlCO2.deleteRequest' />}
					message={<Translate id='controlCO2.deleteRequestMessageConfirmation' />}
					open={deletingRequestPanelConfig.opened}
					onClose={() => {
						setDeletingRequestPanelConfig({ opened: false, serviceToken: null })
					}}
					onSaveItem={handleDeleteRequestConfirmation}
					isSaving={deletingRequestPanelConfig.deleting}
					isMessage={true}
			/>
			<AddControlModal
				open={openAddModal}
				onClose={() => {
					setOpenAddModal(false)
				}}
				onAddRequest={onAddRequest}
				isBlocked={isBlocked}
			/>
		</Card>
	)
}

function TableConfiguration(isAdminUser) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='controlCO2.creationDate' />,
			dataSource: 'creationDate',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.user' />,
			dataSource: 'user',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.vehicle' />,
			dataSource: 'vehicleEconomicNumber',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.destinationPlace' />,
			dataSource: 'destinationPlace',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.km' />,
			dataSource: 'kmTotal',
		},
		{
			header: <Translate id='controlCO2.carbonResult' />,
			dataSource: 'carbonResult',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.gasoline' />,
			dataSource: 'gasoline',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.oil' />,
			dataSource: 'oil',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.water' />,
			dataSource: 'water',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.tires' />,
			dataSource: 'tires',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.cleaningCheck' />,
			dataSource: 'cleaningCheck',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.observations' />,
			dataSource: 'observations',
			isSortable: true,
		},
	]

	if (isAdminUser) {
		columns.push({
			dataSource: 'options',
		})
	}

	return { columns }
}

const styles = (theme) => ({
	searchInput: {
		marginTop: '5px',
	},
	iconButon: {
		marginLeft: '5px',
	},
	btnDownloadReport: {
		marginLeft: '5px',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(ControlCO2))
