import React from 'react'
import PropTypes from 'prop-types'

/** Material UI import section */
import { Typography, List, ListItemText, ListItem, withStyles } from '@material-ui/core'

/** Custom component import section */
import HtmlEditor from '../../common/HtmlEditor'

/** Resources import section */
import { COLOR_CART_PORT } from '../../../store/helpers/StatusColorConstants'

const NotesValidate = (props) => {
	const {
		onUpdatePropertyNotes = () => console.warn('onUpdatePropertyNotes is not defined!'),
		classes,
		canBeAdd,
		qualityCertificate,
	} = props

	return (
		<List>
			<ListItem divider style={{ backgroundColor: COLOR_CART_PORT }}>
				<ListItemText
					secondary={
						<Typography component='span' variant='body2' className={classes.inline} color='textPrimary'>
							<strong>{'Comentarios para el proveedor'}</strong>
						</Typography>
					}
				/>
			</ListItem>
			<ListItem style={{ width: 1100 }}>
				<HtmlEditor
					id='notes'
					labelVariant={'body2'}
					isDisabled={!canBeAdd}
					initialValue={qualityCertificate ? qualityCertificate.NotesPlant : ''}
					onChange={(data) => onUpdatePropertyNotes(data.value)}
				/>
			</ListItem>
		</List>
	)
}

NotesValidate.propTypes = {
	qualityCertificate: PropTypes.object,
	onUpdatePropertyNotes: PropTypes.func,
}

const styles = (theme) => ({
	optionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: 80,
	},
	marginIcon: {
		marginLeft: '5px',
	},
})

export default withStyles(styles, { withTheme: true })(NotesValidate)
