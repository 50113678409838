import React from 'react'
import { Translate } from 'react-localize-redux'

export const vehiclesTableConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='controlCO2.vehicles.economicNumber' />,
			dataSource: 'economicNumber',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.vehicles.licensePlate' />,
			dataSource: 'licensePlate',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.vehicles.brandName' />,
			dataSource: 'brandName',
			isSortable: true,
		},
		{
			header: <Translate id='controlCO2.vehicles.modelName' />,
			dataSource: 'modelName',
		},
		{
			header: null,
			dataSource: 'options',
		},
	],
}
