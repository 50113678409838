/** Helpers import section */
import axios from 'axios'
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper'

export const IS_SAVING = 'IS_SAVING'
export const START_LOADING_PHONE_CATEGORY = 'START_LOADING_PHONE_CATEGORY'
export const SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const DELETE_PHONE_DIRECTORY = 'DELETE_PHONE_DIRECTORY'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const SET_PHONE_DIRECTORY = 'SET_PHONE_DIRECTORY'
/**
 * Action start loading common data
 * @param {*} isLoading
 */
function isLoadingCommon(isLoading, identifier) {
	return {
		type: START_LOADING_PHONE_CATEGORY,
		isLoading: isLoading,
		identifier: identifier,
	}
}

/**
 * Action start saving information
 * @param {*} isLoading
 */
function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
		identifier: identifier,
	}
}

export function setCategories(categories) {
	return {
		type: SET_CATEGORIES_LIST,
		categories: categories,
	}
}

function deleteCategory(id) {
	return {
		type: DELETE_CATEGORY,
		id: id,
	}
}

function setCategory(category) {
	return {
		type: ADD_CATEGORY,
		category: category,
	}
}

function setPhoneDiretory(phoneDirectoriesPaginated) {
	return {
		type: SET_PHONE_DIRECTORY,
		phoneDirectoriesPaginated: phoneDirectoriesPaginated,
	}
}

function deletePhoneDirectory(id) {
	return {
		type: DELETE_PHONE_DIRECTORY,
		id: id,
	}
}

export function loadPhoneCategoryFromBackEnd() {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, START_LOADING_PHONE_CATEGORY))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/PhoneDirectoryCategories`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCategories(serverResponse.data))
					dispatch(isLoadingCommon(false, START_LOADING_PHONE_CATEGORY))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, START_LOADING_PHONE_CATEGORY))
				console.error('Error getting phone categories', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function editCategoriesFromBackEnd(name, id) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let user = getState().oidc.user
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/PhoneDirectoryCategories`
			let command = {
				CategoryName: name,
				Id: id,
				RequestedBy: user.profile.preferred_username,
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, IS_SAVING))
						// dispatch(setCategory(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(true, IS_SAVING))
					console.error('Error update category', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, IS_SAVING))
		}
	}
}

export function createCategoriesFromBackEnd(categoryModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let user = getState().oidc.user

			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/PhoneDirectoryCategories`
			let command = {
				CategoryName: categoryModel ? categoryModel.name : '',
				RequestedBy: user.profile.preferred_username,
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, IS_SAVING))
						dispatch(setCategory(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, IS_SAVING))
					console.error('Error getting biddings categories', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, IS_SAVING))
		}
	}
}

export function deleteCategoryFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/PhoneDirectoryCategories/${id}`
			return axios
				.delete(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, IS_SAVING))
						dispatch(deleteCategory(id))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, IS_SAVING))
					console.error('Error getting biddings categories', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, IS_SAVING))
		}
	}
}

export function loadPhoneDiretoryByIdFromBackEnd(id, queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, START_LOADING_PHONE_CATEGORY))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/PhoneDirectories?CategoryId=${id}||equal`

		if (queryParams && queryParams.query) endPoint = endPoint + `&query=${queryParams.query}`
		if (queryParams && queryParams.pageNumber) endPoint = endPoint + `&pageNumber=${queryParams.pageNumber - 1}`
		if (queryParams && queryParams.rowsPerPage) endPoint = endPoint + `&pageSize=${queryParams.rowsPerPage}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.pageNumber = serverResponse.data.pageNumber - 1
					dispatch(setPhoneDiretory(serverResponse.data))
					dispatch(isLoadingCommon(false, START_LOADING_PHONE_CATEGORY))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, START_LOADING_PHONE_CATEGORY))
				console.error('Error getting phone categories', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function createPhoneDirectoryFromBackEnd(data, id) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let user = getState().oidc.user

			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/PhoneDirectories`
			let command = {
				RequestedBy: user.profile.preferred_username,
				UserName: data.UserName ? data.UserName : 'CREATED_NOT_USERNAME',
				Name: data ? data.Name : '',
				Email: data ? data.Email : '',
				PhoneNumber: data ? data.PhoneNumber : '',
				Position: data ? data.Position : '',
				DirectBoss: data ? data.DirectBoss : '',
				Id: id,
				ActivityMaterials: data ? data.ActivityMaterials : '',
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						// dispatch(loadPhoneDiretoryByIdFromBackEnd(phoneCategoryId))
						dispatch(isSavingCommon(false, IS_SAVING))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, IS_SAVING))
					console.error('Error to create phone directory', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, IS_SAVING))
		}
	}
}

export function editPhoneDirectoryFromBackEnd(data, id, phoneCategoryId) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let user = getState().oidc.user

			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/PhoneDirectories`
			let command = {
				RequestedBy: user.profile.preferred_username,
				UserName: data.UserName ? data.UserName : 'CREATED_NOT_USERNAME',
				Name: data ? data.Name : '',
				Email: data ? data.Email : '',
				PhoneNumber: data ? data.PhoneNumber : '',
				Position: data ? data.Position : '',
				DirectBoss: data ? data.DirectBoss : '',
				Id: id,
				PhoneDirectoryId: phoneCategoryId,
				ActivityMaterials: data ? data.ActivityMaterials : '',
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(loadPhoneDiretoryByIdFromBackEnd(id))
						dispatch(isSavingCommon(false, IS_SAVING))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, IS_SAVING))
					console.error('Error to edit phone directory', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, IS_SAVING))
		}
	}
}

export function deletePhoneDirectoryFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/PhoneDirectories/${id}`
			return axios
				.delete(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(deletePhoneDirectory(id))
						dispatch(isSavingCommon(false, IS_SAVING))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, IS_SAVING))
					console.error('Error delete phone directory', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, IS_SAVING))
		}
	}
}
