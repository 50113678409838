/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';

export const START_LOADING_WAREHOUSE_RAW_MATERIALS = "START_LOADING_WAREHOUSE_RAW_MATERIALS";
export const SET_WAREHOUSE_RAW_MATERIALS_LIST = "SET_WAREHOUSE_RAW_MATERIALS_LIST";
export const CHANGE_WAREHOUSE_RAW_MATERIALS_SORT_CRITERIA = "CHANGE_WAREHOUSE_RAW_MATERIALS_SORT_CRITERIA";
export const CHANGE_WAREHOUSE_RAW_MATERIALS_QUERY = "CHANGE_WAREHOUSE_RAW_MATERIALS_QUERY";
export const CHANGE_WAREHOUSE_RAW_MATERIALS_PAGINATION = "CHANGE_WAREHOUSE_RAW_MATERIALS_PAGINATION";
export const SET_WAREHOUSE_RAW_MATERIALS_COUNT = "SET_WAREHOUSE_RAW_MATERIALS_COUNT";
export const IS_SAVING = "IS_SAVING";
export const IS_LOADING = "IS_LOADING";
export const SET_WAREHOUSE_RAW_MATERIAL = "SET_WAREHOUSE_RAW_MATERIAL";

/**
 * Action start loading WarehouseRawMaterials
 * @param {*} isLoading 
 */
function isLoadingWarehouseRawMaterials(isLoading) {
    return {
        type: START_LOADING_WAREHOUSE_RAW_MATERIALS,
        isLoading: isLoading
    }
}

/**
 * Action to set WarehouseRawMaterial list 
 * @param {*} warehouseRawMaterials 
 */
function setWarehouseRawMaterialsList(warehouseRawMaterials) {
    return {
        type: SET_WAREHOUSE_RAW_MATERIALS_LIST,
        warehouseRawMaterials: warehouseRawMaterials
    }
}

/**
 * Action reducer to set the WarehouseRawMaterial counter
 *
 * @param {*} count
 * @returns
 */
function setWarehouseRawMaterialsCount(count) {
    return {
        type: SET_WAREHOUSE_RAW_MATERIALS_COUNT,
        count
    }
}

/**
 * Action to is saving warehouseRawMaterials
 * @param {*} isSaving 
 */
function isSaving(isSaving) {
    return {
        type: IS_SAVING,
        isSaving: isSaving
    }
}

/**
 * Action to is loading warehouseRawMaterials
 * @param {*} isLoading 
 */
function isLoading(isLoading) {
    return {
        type: IS_LOADING,
        isLoading: isLoading
    }
}

/**
 * Action to set  warehouseRawMaterial 
 * @param {*} warehouseRawMaterial 
 */
function setWarehouseRawMaterial(warehouseRawMaterial) {
    return {
        type: SET_WAREHOUSE_RAW_MATERIAL,
        warehouseRawMaterial: warehouseRawMaterial
    }
}

/**
 * Get all warehouseRawMaterial by company from backend 
 */
export function loadWarehouseRawMaterialsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {
        dispatch(isLoadingWarehouseRawMaterials(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rawmaterial/rawmaterails`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setWarehouseRawMaterialsList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingWarehouseRawMaterials(false));
                console.error("Error getting the warehouseRawMaterial by company list", error.response)
            })
    }
}


/**
 * Get WarehouseRawMaterial's count
 * @export
 * @returns
 */
export function getWarehouseRawMaterialsCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().warehouseRawMaterials ? getState().warrantyReports.get('warehouseRawMaterialsCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rawmaterial/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setWarehouseRawMaterialsCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the WarehouseRawMaterial count", error.response)
            })
    }
}

/**
 * Action to create WarehouseRawMaterial from backend
 */
export function createWarehouseRawMaterialFromBackEnd(supplier, rawMaterial) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let data = {
            CompanyToken: selectedCompany.get('token'),
            DocumentIdentifier: selectedCompany.get('document_identifier'),
            CompanyIdentifier: selectedCompany.get("get_company_identifier"),
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            SupplierToken: supplier.token,
            SupplierName: supplier.name,
            SupplierCode: supplier.code,
            DeliveryTime: rawMaterial.deliveryTime.format("DD/MM/YYYY"),
            Incidence: rawMaterial.incidence,
            PurchaseOrder: rawMaterial.purchase_order,
            QualityCertificate: rawMaterial.quality_certificate,
            InvoiceOrRemision: rawMaterial.invoice_or_remision,
            CorrectQuantity: rawMaterial.correct_quantity,
            Comments: rawMaterial.comments,
            PurchaseOrderNumber: rawMaterial.purchase_order_number
        }

        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rawmaterial`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error adding a Warehouse Raw Material from company", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Get and set the WarehouseRawMaterial in the estate from read from backend
 * DONE
 * @param {*} token 
 */
export function loadWarehouseRawMaterialFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(isLoading(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rawmaterial/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setWarehouseRawMaterial(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load  Warehouse Raw Material ", error.response)
                dispatch(isLoading(false))
                return Promise.reject();
            })
    }
}

/**
 *Action to download report raw materials by range date
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadRawMaterialsReportByRangeDate(startDate, endDate) {
    return (dispatch, getState) => {
        dispatch(isLoading(true));
        let companyToken = getState().profile.get("selectedCompany").get('token');
        let data = {
            CompanyToken: companyToken,
            StartDate: startDate,
            EndDate: endDate
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/rawmaterial/downloadRawMaterialByRangeDate`;
        return axios.post(endPoint, data, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isLoading(false));
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reportes_de_entrega_material.xlsx";
                    a.click();
                }
            })
            .catch((error) => {
                dispatch(isLoading(false));
                console.error("Error downloading raw materials report by month", error.response)
                return Promise.reject();
            })
    }
}

