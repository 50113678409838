import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/**Import MaterialUi section */
import { Grid, Button, Avatar, Typography, TextField } from '@material-ui/core'
import BiddingIcon from '@material-ui/icons/ViewCarousel'

/** section import components */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants'

/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper.js'

const PanelQualityCetificate = (props) => {
	/**props section */
	const { onCloseButtonClick, classes, onSaveButtonClick, isSaving } = props

	const [panelInformation, setPanelInformation] = useState({
		invoice: '',
		comments: '',
	})

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setPanelInformation({
			...panelInformation,
			[name]: value,
		})
	}

	const canBeSaved = () => {
		if (IsNullOrEmpty(panelInformation.invoice)) return false
		if (IsNullOrEmpty(panelInformation.comments)) return false
		else return true
	}

	const onSave = () => {
		if (props.onSaveButtonClick) {
			onSaveButtonClick(panelInformation)
		}
	}

	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={<Translate id='qualityCertificate.addCertificate' />}
				icon={
					<Avatar className={classes.avatar}>
						<BiddingIcon />
					</Avatar>
				}
				onCloseButtonClick={onCloseButtonClick}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid container>
						<Typography color='primary'>
							<Translate id='qualityCertificate.factorNumber' />
						</Typography>
						<Grid item xs={12} style={{ marginTop: '1rem' }}>
							<TextField
								fullWidth
								label={'Factura'}
								className={classes.textField}
								value={panelInformation.invoice}
								onChange={onPropertyChange}
								name='invoice'
								margin='normal'
								variant='outlined'
							/>
						</Grid>
						<Grid item xs={12}>
							<br />
							<Typography color='primary'>
								{<Translate id='qualityCertificate.commentsToSupplier' />}
							</Typography>
							<TextField
								fullWidth
								label={<Translate id='common.comments' />}
								className={classes.textField}
								value={panelInformation.comments}
								onChange={onPropertyChange}
								multiline
								name='comments'
								margin='normal'
								variant='outlined'
								rows='4'
							/>
						</Grid>
					</Grid>
				</div>
				<Grid container>
					<Grid item xs={12} className={classes.containerBtn}>
						<Button
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='outlined'
							className={classes.saveButton}
							disabled={!canBeSaved() || isSaving}
							onClick={onSave}
						>
							{<Translate id='common.save' />}
						</Button>
					</Grid>
				</Grid>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	expansionPanelSummary: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputProps: {
		height: 6,
	},
	saveButton: {
		bottom: 0,
	},
})

PanelQualityCetificate.propTypes = {
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onSaveItem: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
	/**
	 * Validate if template is correctly
	 */
	onCloseButtonClick: propTypes.func,
}

PanelQualityCetificate.defaultProps = {
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	onSaveItem: () => console.warn('Callback [onSaveItem] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelQualityCetificate))
