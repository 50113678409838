import { fromJS } from 'immutable';

import * as maquilaActions from './MaquilaOrderActions'

/**
 * maquila action initial state
 */
export const maquilaInitialState = fromJS({
    isLoadingMaquilas: true,
    maquilas: null,
    maquilasCount: null,
    sortCriteria: {
        by: "folio",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    isLoadingMaquila: false,
    maquilaOrder: null,
    isDonwloading: false,
})

export default function companiesReducer(state = maquilaInitialState, action) {
    switch (action.type) {
        case maquilaActions.START_LOADING_MAQUILAS: {
            return state.merge({
                isLoadingMaquilas: action.isLoading
            })
        }
        case maquilaActions.SET_MAQUILAS_LIST: {
            return state.merge({
                maquilas: fromJS(action.maquilas),
                isLoadingMaquilas: false
            })
        }
        case maquilaActions.CHANGE_MAQUILAS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case maquilaActions.CHANGE_MAQUILAS_SORT_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case maquilaActions.CHANGE_MAQUILAS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case maquilaActions.SET_MAQUILAS_COUNT:{
            return state.merge({
                maquilasCount: action.count
            })
        }
        case maquilaActions.IS_LOADING_MAQUILA_ORDER:{
            return state.merge({
                isLoadingMaquila: action.isLoadingMaquila
            })  
        }
        case maquilaActions.SET_MAQUILA_ORDER:{
            return state.merge({
                isLoadingMaquila: false,
                isSavingMaquila:false,
                maquilaOrder: fromJS(action.maquilaOrder)
            })
        }
        case maquilaActions.IS_SAVING_MAQUILA:{
            return state.merge({
                isSavingMaquila: action.isSavingMaquila,
            })
        }
        case maquilaActions.UPDATE_MAQUILA_ORDER:{
            return state.merge({
                isSavingMaquila: false,
                maquilaOrder: fromJS(action.maquilaOrder)
            })
        }
        case maquilaActions.ADD_MATERIAL_MACHINED_IN_MAQUILA: {
            let material = fromJS(action.material)
            return state.updateIn(['maquilaOrder', "material_machineds"], (actions) => actions.push(material))
                .set('isSavingMaquila', false);
        }
        case maquilaActions.UPDATE_MATERIAL_MACHINED_IN_MAQUILA: {
            return state.updateIn(['maquilaOrder', "material_machineds"], (actions) => {
                let index = actions.findIndex((item) => {
                    return item.get('token') === action.material.token
                });
                return actions.setIn([index], fromJS(action.material));
            }
            ).set('isSavingMaquila', false);
        }
        case maquilaActions.REMOVE_MATERIAL_MACHINED_IN_MAQUILA: {
            return state.updateIn(['maquilaOrder', 'material_machineds'], (materials) => {
                let index = materials.findIndex((item) => {
                    return item.get('token') === action.actionToken
                });
                return materials.delete(index).set('isSavingMaquila', false);
            })
        }
        case maquilaActions.ADD_FILES_MAQUILA_ORDER:{
            let oldList = state.getIn(['maquilaOrder', action.listItems]).toJS();
            let filesToAdda = action.files.filter(newFileSupl => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFileSupl.name
                })
            })
            let newList = [...oldList, ...(filesToAdda)];
            return state.setIn(['maquilaOrder', action.listItems], fromJS(newList));
        }
        case maquilaActions.REMOVE_FILES_MAQUILA_ORDER:{
            let oldList = state.getIn(['maquilaOrder', action.listItems]).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['maquilaOrder', action.listItems], fromJS(newList));
        }
        case maquilaActions.UPDATE_DETAIL_MACHINED_IN_MAQUILA: {
            return state.updateIn(['maquilaOrder', "detail_machineds"], (details) => {
                let index = details.findIndex((item) => {
                    return item.get('token') === action.material.token
                });
                return details.setIn([index], fromJS(action.material));
            }
            ).set('isSavingMaquila', false);
        }
        case maquilaActions.ADD_MOLD_IN_MAQUILA:{
            let molde = fromJS(action.molde)
            return state.updateIn(['maquilaOrder', "maquila_molds"], (moldes) => moldes.push(molde))
                .set('isSavingMaquila', false);
        }
        case maquilaActions.UPDATE_MOLD_IN_MAQUILA:{
            return state.updateIn(['maquilaOrder', "maquila_molds"], (moldes) => {
                let index = moldes.findIndex((item) => {
                    return item.get('token') === action.molde.token
                });
                return moldes.setIn([index], fromJS(action.molde));
            }
            ).set('isSavingMaquila', false);
        }
        case maquilaActions.REMOVE_MOLD_IN_MAQUILA: {
            return state.updateIn(['maquilaOrder', 'maquila_molds'], (moldes) => {
                let index = moldes.findIndex((item) => {
                    return item.get('token') === action.actionToken
                });
                return moldes.delete(index).set('isSavingMaquila', false);
            })
        }
        case maquilaActions.IS_DOWNLOADING_REPORT_MAQUILA: {
            return state.merge({
                isDonwloading: action.isDonwloading
            })
        }
        case maquilaActions.UPDATE_FILE_MATERIAL_MACHINED_PARTIAL_ITEM:{
            return state.updateIn(['maquilaOrder', 'material_machineds'], (allMaterial) => UpdateFileMaterialMachined(allMaterial, action.materialToken, action.itemToken, fromJS(action.newMaterialPartialItem)))
        }
        case maquilaActions.ADD_TOOLING_IN_MAQUILA:{
            let tooling = fromJS(action.tooling)
            return state.updateIn(['maquilaOrder', "toolings"], (toolings) => toolings.push(tooling))
                .set('isSavingMaquila', false);
        }
        case maquilaActions.UPDATE_TOOLING_IN_MAQUILA:{
            return state.updateIn(['maquilaOrder', "toolings"], (toolings) => {
                let index = toolings.findIndex((item) => {
                    return item.get('token') === action.tooling.token
                });
                return toolings.setIn([index], fromJS(action.tooling));
            }
            ).set('isSavingMaquila', false);
        }
        case maquilaActions.REMOVE_TOOLING_IN_MAQUILA: {
            return state.updateIn(['maquilaOrder', 'toolings'], (toolings) => {
                let index = toolings.findIndex((item) => {
                    return item.get('token') === action.actionToken
                });
                return toolings.delete(index).set('isSavingMaquila', false);
            })
        }
        default:
            return state;
    }
}

const UpdateFileMaterialMachined = (allMaterials, materialToken, itemToken, newMaterialPartialItem) => {
    let indexOfMaterialMachined = allMaterials.findIndex(d => d.get('token') === materialToken);
    if (indexOfMaterialMachined >= 0) {
        let newItemUpdated = allMaterials.get(indexOfMaterialMachined)
        if (newItemUpdated !== undefined) {
            let evaluationCriterias = newItemUpdated.get('items')
            let indexOfItem = evaluationCriterias.findIndex(d => d.get('token') === itemToken);
            if (indexOfItem >= 0) {
                return allMaterials.setIn([indexOfMaterialMachined, 'items', indexOfItem], newMaterialPartialItem);
            }
        }
    }
    return allMaterials
}