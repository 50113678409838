/**Import react section */
import React, { useEffect, useState } from 'react'
import { useDispatch, } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/**Import MaterialUi section */
import {
    Grid, Typography, Avatar, Button
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import WarningComponent from '../../../common/WarningComponent'
import CommentsComponent from '../CommentsComponent'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';
import {
    canBeResendEmail
} from '../../../../store/helpers/BiddingHelper';
import { BiddingSuppliers } from '../../../../store/helpers/AppConstants'
import { getAllEmailsToSuppliers } from '../../../../store/biddings/BiddingsActions'
const AddSuppliersLateralPanel = (props) => {

    /**Render hooks section */
    const dispatch = useDispatch()

    /**props section */
    const { onCloseButtonClick, bidding, userRolesByCompany,
        isSaving, classes, onSaveButtonClick, supplier, translate
    } = props
    const [suppliersEmails, setSuppliersEmails] = useState([])

    /**Use effect section */
    useEffect(() => {
        if (props.bidding && supplier)
            dispatch(getAllEmailsToSuppliers(supplier.Code))
                .then((emails) => {
                    setSuppliersEmails(emails)
                })
    }, [])

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(supplier.Code)
        }
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<Translate id="biddingsModule.authorizerContacts" />}
                subTitlePanel={supplier ? supplier.Name : ""}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography >
                                {`${translate("biddingsModule.peopleAccessToBidding")} ${supplier ? supplier.Name : ""}. ${translate("biddingsModule.emailNotification")}`}
                            </Typography>
                        </Grid><br />
                        <Grid item xs={12}>
                            {
                                (suppliersEmails && suppliersEmails.length !== 0) ? suppliersEmails.map(user => {
                                    return (
                                        <Grid item xs={12} style={{ marginTop: "5px" }}>
                                            <Typography variant="subtitle2" color="primary">
                                                {user.Name}
                                            </Typography>
                                            <Typography variant="subtitle2" color="primary">
                                                {user.Email}
                                            </Typography>
                                        </Grid>
                                    )
                                }) :
                                    supplier && supplier.Type === BiddingSuppliers.INTERNAL ?
                                        <WarningComponent
                                            title={translate("biddingsModule.warning")}
                                            message={translate("biddingsModule.noEmailsToSupplier")}

                                        /> : <CommentsComponent
                                            title={translate("biddingsModule.information")}
                                            comments={`${translate('biddingsModule.supplierSelectedExternal')} ${supplier ? supplier.ContactEmail : ""} ${translate('biddingsModule.supplierAlta')}`}
                                        />
                            }
                        </Grid>

                    </Grid>

                </div>
               { 
               supplier && supplier.Status===0 && <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            disabled={isSaving ||
                                // suppliersEmails.length === 0 ||
                                (!canBeResendEmail(bidding, userRolesByCompany))}>
                            {<Translate id="common.sendEmail" />}
                        </Button>
                    </Grid>
                </Grid>
                }
            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "20px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    }


});

AddSuppliersLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddSuppliersLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddSuppliersLateralPanel))