import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import {
    withStyles
} from '@material-ui/core';

/**Import components section*/
import ServicesTabs from './ServicesTabs'
import MaterialTable from './MaterialTable'
import EmptyInformation from '../EmptyInformation'

/**Import helpers section */
import { isCategoryRawMaterials, isCategoryService } from '../../../../store/helpers/BiddingHelper'
const ServicesOrMaterialContainer = (props) => {
    /**String props section */
    const { classes } = props;
    // /**Bools props section */
    const { isSaving } = props
    // /**Arrays props section */
    const { userProfile, userProfileLogged, userRolesByCompany, translate } = props
    // /**Functions props section */
    const { onEditEvaluationCriteriaConfigPanel, onDeletedEvaluationFromBackEnd,
        onAddResponseSupplier, onCreateEvaluationCriteriaConfigPanel, onDeleteServiceOrMaterial
    } = props

    /**Objects props section */
    const { bidding, } = props


    /**
     * Render componet 
     */
    return (
        <div className={classes.sectionsContainer}>
            {
                bidding && bidding.Items.length !== 0 && isCategoryService(bidding) && bidding.Items.map((itemProposal, index) => {
                    return (
                        <div key={index}>
                            <ServicesTabs
                                translate={translate}
                                bidding={bidding}
                                userRolesByCompany={userRolesByCompany}
                                onCreateEvaluationCriteriaConfigPanel={onCreateEvaluationCriteriaConfigPanel}
                                onDeletedEvaluationFromBackEnd={onDeletedEvaluationFromBackEnd}
                                onDeleteServiceOrMaterial={onDeleteServiceOrMaterial}
                                isSaving={isSaving}
                                userProfileLogged={userProfileLogged}
                                onEditEvaluationCriteriaConfigPanel={onEditEvaluationCriteriaConfigPanel}
                                onAddResponseSupplier={onAddResponseSupplier}
                                userProfile={userProfile}
                                itemProposal={itemProposal}
                                index={index}
                            />

                        </div>
                    )
                })

            }
            {
                bidding && bidding.Items.length !== 0 && isCategoryRawMaterials(bidding) &&
                <MaterialTable
                    /**translate */
                    translate={translate}
                    /**objects */
                    bidding={bidding}
                    userRolesByCompany={userRolesByCompany}
                    userProfileLogged={userProfileLogged}
                    userProfile={userProfile}
                    /**bools */
                    isSaving={isSaving}
                    /**Functions */
                    onCreateEvaluationCriteriaConfigPanel={onCreateEvaluationCriteriaConfigPanel}
                    onDeletedEvaluationFromBackEnd={onDeletedEvaluationFromBackEnd}
                    onDeleteServiceOrMaterial={onDeleteServiceOrMaterial}
                    onEditEvaluationCriteriaConfigPanel={onEditEvaluationCriteriaConfigPanel}
                    onAddResponseSupplier={onAddResponseSupplier}
                    items={bidding.Items}
                />


            }
            {
                bidding && bidding.Items.length === 0 && <EmptyInformation
                    title={translate("biddingsModule.notExistInformationInThisApart")}
                />
            }
        </div>
    )
}

const styles = (theme) => ({
    tab: {
        minHeight: "auto",
    },
    textTab: {
        fontSize: "0.75rem !important"

    },
    buttonsRightContainer: {
        display: "flex",
        alignItems: 'center'
    },
    tabs: {
        display: "flex",
        justifyContent: 'space-between',
    },
    sectionsContainer: {
        marginTop: "20px",
        maxHeight: `calc(100vh - ${305}px)`,
        minHeight: `calc(100vh - ${305}px)`,
        overflowY: "auto",
        paddingBottom: "15px"
    },
    flexGrow: {
        flexGrow: 1
    },
    accordionSummary: {
        paddingRight: "0px !important",
        display: "flex",
        backgroundColor: "#f8f9fa"

    },
    accordionDetails: {
        backgroundColor: `${theme.palette.common.white} !important`
    },
    buttonsAcordionSummary: {
        display: "flex",
        justifyItems: "flex-end",
        marginRight: "20px"
    }

});

ServicesOrMaterialContainer.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
}

ServicesOrMaterialContainer.defaultProps = {
    isLoading: false,
}

export default withRouter(
    withStyles(styles, { withTheme: true })(ServicesOrMaterialContainer)
);
