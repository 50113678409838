
import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'auto-bind';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import QuotesIcon from '@material-ui/icons/AttachMoneyTwoTone';
import SamplesIcon from '@material-ui/icons/UnarchiveTwoTone';
import ReactiveIcon from '@material-ui/icons/FindReplace';

import AddCommonItem from '../../common/AddCommonItem';
import DataTable from '../../common/DataTable';
import Toaster from '../../common/Toaster';
import LoadingPage from '../../common/LoadingPage';

/** Import component section */
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper';
import {caBeActiveQuote} from '../../../store/helpers/RolesHelper';
import { converListToArray } from '../../../store/helpers/ListHelper'
import {COLOR_STATUS_SUCCESS} from '../../../store/helpers/StatusColorConstants';
import {Quote_Declined_BySupplier} from '../../../store/helpers/DevelopmentHelper';
import { showSuccessToaster, showErrorToaster } from '../../../store/helpers/ToasterHelpers';

import {reactiveWorkflowDocument} from '../../../store/workflows/workflowActions';
import {SET_REACTIVE_QUOTE_IN_ALTERNATIVE } from '../../../store/alternativeProvedure/AlternativeProveduceAction';

/**
 * Container show notice
 */
class SuppliersCard extends Component {

    /**
     * Create an instance notice
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openActiveQuote: false,
            quoteToActive: "",
        }
    }

    /**
     * Action to open reactive sample request
     */
    onOpenActiveQuote(tokenQuote) {
        this.setState({
            openActiveQuote: true,
            quoteToActive: tokenQuote
        })
    }

    /**
     * Action to reactive quote from supplier
     * @param {*} commnents 
     */
    reactiveQuoteFromSupplier(commnents) {
        this.props.reactiveQuoteFromSupplier(this.state.quoteToActive, commnents)
            .then((response) => {
                this.props.changeQuoteActive(response);
                this.setState(showSuccessToaster("common.saveCorrectly"));
                this.setState({
                    quoteToActive: "",
                    openActiveQuote: false,
                })
            }).catch((error) => {
                this.setState(showErrorToaster("common.errorToSave"));
            });
    }

    /**
    * On render cell item custom
    * @param {*} dataSource 
    * @param {*} item 
    */
   onRenderCellItem(dataSource, item) {
    if (dataSource === "folio") {
        if (item.type_request === "QuoteSamples") {
            return (<div><QuotesIcon color="primary" /> {item.folio}</div>)
        }
        if (item.type_request === "SampleRequest") {
            return (<div><SamplesIcon color="secondary" /> {item.folio}</div>)
        }
    }
    if (dataSource === "type_request") {
        if (item.type_request === "QuoteSamples")
            return (<Translate id="quotes.quote" />)
        else if (item.type_request === "SampleRequest")
            return (<Translate id="samplesrequest.samplesrequest" />)
    }
    if(dataSource === "option"){
        if(item.type_request === "QuoteSamples" && item.actual_operation === Quote_Declined_BySupplier && caBeActiveQuote(this.props.userRolesByCompany)){
            return(<Tooltip title={<Translate id="quotes.rectiveQuote" />} >
                <IconButton style={{ color:COLOR_STATUS_SUCCESS}} 
                    data-cy="btn-active"
                    onClick={() => { this.onOpenActiveQuote(item.token) }} >
                       <ReactiveIcon />
                </IconButton>
            </Tooltip>)
        }
        else
            return("");
    }
}

    /**
     * Get a filtered list of suppliers 
     *
     * @returns
     * @memberof SuppliersCard
     */
    getFilteredSuppliersList(data) {
        return data.suppliers.filter(supplier => {
            return supplier.supplier_token === this.props.user.profile.company_token
        })
    }


    /**
     * On render row click 
     * @param {*} item 
     * @param {*} event 
     */
    onRowClick(item, event){
        if (event.target.tagName !== "svg") {
            if (item.type_request === "QuoteSamples") {
                this.props.history.push(`/quotes/${item.token}`)
            }
            else if (item.type_request === "SampleRequest") {
                this.props.history.push(`/samplerequests/${item.token}`)
            }
        }
    }

    /**
     * Get the companies list
     *
     * @returns
     * @memberof CompaniesCard
     */
    getSuppliersList() {
        const { provedure } = this.props;
        if (provedure) {
            if (IsCurrentActiveDirectoryUser(this.props.user)) {
                return (this.props.provedure.suppliers.map((supplier) => {
                    return (
                        <DataTable
                            key={supplier.supplier_token}
                            title={<Typography variant="subtitle2" gutterBottom><strong>{supplier.supplier_code} | {supplier.supplier_name.toUpperCase()}</strong></Typography>}
                            data={supplier.quotes}
                            configuration={TableQuotesConfiguration}
                            onRenderCellItem={this.onRenderCellItem}
                            onRowClick={this.onRowClick}
                        />
                    )
                }))
            }
            else {
                return (this.getFilteredSuppliersList(this.props.provedure).map((supplier) => {
                    return (
                        <DataTable
                            title={<Typography variant="subtitle2" gutterBottom><strong>{supplier.supplier_code} | {supplier.supplier_name.toUpperCase()}</strong></Typography>}
                            data={supplier.quotes}
                            configuration={TableQuotesConfiguration}
                            onRenderCellItem={this.onRenderCellItem}
                            onRowClick={this.onRowClick}
                        />
                    )
                }))
            }
        } else {
            return <LoadingPage />;
        }
    }

    render() {
        return (
            <Paper>
                {this.getSuppliersList()}

                {/** Dialog to reactive quote decline by supplier */}
                < AddCommonItem
                    title={<Translate id="quotes.rectiveQuote" />}
                    message={<Translate id="quotes.messageReactiveQuote" />}
                    open={this.state.openActiveQuote}
                    onClose={() => { this.setState({ openActiveQuote: false }) }}
                    onSaveItem={this.reactiveQuoteFromSupplier}
                    isSaving={this.props.isActive}
                    isTextArea={true}
                />

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Paper>
        )
    }
}

const TableQuotesConfiguration = {
    columns: [{
        header: <Translate id="common.folio" />,
        dataSource: 'folio'
    }, {
        header: <Translate id="memos.documentType" />,
        dataSource: 'type_request',
    }, {
        header: <Translate id="common.status" />,
        dataSource: 'status'
    },{
        header: "",
        dataSource: 'option'
    }]
}


/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        user: user,
        isActive: state.workflow.get('isActive'),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        reactiveQuoteFromSupplier:(quoteToken, commnents) =>{
            return dispatch(reactiveWorkflowDocument(quoteToken, commnents));
        },
        changeQuoteActive: (quote) => {
            dispatch({
                type: SET_REACTIVE_QUOTE_IN_ALTERNATIVE,
                quote: quote
            })
        },
    }
}

export default ((connect(mapStateToProps, mapDispatchToProps)(SuppliersCard)));