import React from 'react';
import { Translate } from 'react-localize-redux';

/** Material UI import section */
import { Card, CardContent, Typography, withStyles } from '@material-ui/core';

const InstructionsCard = ({classes: {root, content, text}}) => {
	return (
		<Card className={root}>
			<CardContent className={content}>
				<Typography variant="caption" className={text}>
					<Translate id="accreditedWorkshops.creatingPaymentRequestInstructions" />
				</Typography>
			</CardContent>
		</Card>
	);
};

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.primary.light,
        maxWidth: 425,
		maxHeight: 50,
		overflowY: 'auto'
	},
	content: {
		display: 'flex',
		justifyContent: 'space-around',
		padding: '5px 5px 5px 5px !important',
		color: theme.palette.colors.white,
	},
	text: {
		fontSize: '0.65rem'
	}
});

export default withStyles(styles, { withTheme: true })(InstructionsCard);
