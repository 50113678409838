

import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import {Translate} from "react-localize-redux";

/** Material-UI imports section */

import DataTable from '../../common/DataTable';

/**
 * Component render devolution items
 */
export default class DevolutionItems extends Component{

    /**
     * Create an instance devolution items
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
    }

    /**
     * Render
     */
    render() {
        return(
            <DataTable 
                data = {this.props.devolution.items}
                configuration = {TableConfiguration}
            />
        )
    }

}
/**
 * devolution
 */
DevolutionItems.propTypes = {
    devolution: PropTypes.object.isRequired
}

const TableConfiguration = {
    columns: [{
        header: <Translate id="common.partNumber"/>,
        dataSource: 'full_part_description'
    },{
        header: "Descripción del problema",
        dataSource: 'problem_description'
    },{
        header: <Translate id="devolutions.qualityMaterial"/>,
        dataSource: 'quantity_of_material'
    },{
        header: <Translate id="devolutions.nonConformity"/>,
        dataSource: 'non_conformity'
    },{
        header: <Translate id="devolutions.invoiceNumber"/>,
        dataSource: 'invoice_number'
    }]
}