import { fromJS } from 'immutable';

import * as reworkActions from './ReworkAction';

/**
 * Rework reducer initial state
 */
export const reworkInitialState = fromJS({
    isLoadingReworks: true,
    reworks: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    reworksCount: null,
    isSaving: false,
    isLoading: false,
    rework: null,
    isAllReworksReports: false,
})

export default function reworkReducer(state = reworkInitialState, action) {
    switch (action.type) {
        case reworkActions.START_LOADING_REWORKS: {
            return state.merge({
                isLoadingReworks: action.isLoading
            })
        }
        case reworkActions.SET_REWORKS_LIST: {
            let reworksLists = fromJS(action.reworks);
            return state.merge({
                reworks: reworksLists,
                isLoadingReworks: false,
                isSaving:false,
            })
        }
        case reworkActions.CHANGE_REWORKS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case reworkActions.CHANGE_REWORKS_QUERY: {
            return state.merge({
                searchQuery: action.queryquery,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case reworkActions.CHANGE_REWORKS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case reworkActions.IS_LOADING_ALL_REWORKS_REPORTS: {
            return state.merge({
                isAllReworksReports: action.isAllReports
            })
        }
        case reworkActions.SET_REWORKS_COUNT: {
            return state.merge({
                reworksCount: action.count
            })
        }
        case reworkActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case reworkActions.IS_LOADING: {
            return state.merge({
                isLoading: action.isLoading
            })
        }
        case reworkActions.SET_REWORK:{
            return state.merge({
                isLoading: false,
                isSaving: false,
                rework: fromJS(action.rework)
            })
        }
        default:
            return state;
    }
}