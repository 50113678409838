import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import Dialog from '../common/Dialog'
import DialogActions from '../common/DialogActions'
import Toaster from '../common/Toaster'
import DialogEditWorker from './DialogEditWorker'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Fab from '@material-ui/core/Fab'
import Toolbar from '@material-ui/core/Toolbar'
import AddCompanytIcon from '@material-ui/icons/AddTwoTone'
import WarehouseIcon from '@material-ui/icons/DeveloperBoard'
import DataTable from '../common/DataTable'

/** Language imports **/
import { Translate } from 'react-localize-redux'

/** Import section actions */
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import { SHOW_EDIT_VIEW } from '../../store/helpers/ResourcesHelper'
import {
	loadWarehouseWorkersFromBackEnd,
	createWorkerData,
	updateWorkerData,
	CHANGE_WORKERS_SORT_CRITERIA,
} from '../../store/administration/WarehouseWorkersActions'
import { Tooltip } from '@material-ui/core'

/**
 * Internal user view
 *
 * @class Internal user
 * @extends {Component}
 */
class WarehouseStaffIndex extends Component {
	/**
	 * Create an instance of Internal user
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			worker: {
				name: '',
				status: false,
			},
			showAddWorker: false,
			showToEdit: false,
			editWorker: null,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadWarehouseWorkers()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadWarehouseWorkers()
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		if (dataSource === 'status') {
			return (
				<Tooltip
					title={
						item.status ? (
							<Translate id='warehouseWorkers.status' />
						) : (
							<Translate id='warehouseWorkers.inactive' />
						)
					}
				>
					<Avatar data-cy='user-avatar' className={item.status ? classes.avatar : ''}>
						<PersonIcon />
					</Avatar>
				</Tooltip>
			)
		}
	}

	/**
	 * Method to set the panel to add worker
	 */
	setShowAddWorkerState(open) {
		this.setState({
			showAddWorker: open,
			worker: {
				name: '',
				status: false,
			},
		})
	}

	/**
	 * hasInvalidValue
	 * @param {*} key
	 */
	hasInvalidValue(key) {
		return this.validateData().some((error) => error.key === key)
	}

	/**
	 * Execute data validation
	 */
	validateData() {
		const { worker } = this.state
		let validations = []
		if (worker && IsNullOrEmpty(worker.name)) {
			validations.push({
				key: 'name',
			})
		}
		return validations
	}

	/**
	 * Action to can be  information
	 */
	canBeSaved() {
		let validations = []
		validations = this.validateData()
		if (this.props.isSavingWorker) return false
		else return validations.length === 0
	}

	/**
	 *method add properties
	 *
	 * @param {*} userId
	 * @memberof Show
	 */
	updateProperty(property, value) {
		let updatedworker = { ...this.state.worker }
		updatedworker[property] = value
		this.setState({
			worker: updatedworker,
		})
	}

	/**
	 * Function to create worker
	 * @param {*} worker
	 */
	onCreateWorker() {
		this.props
			.createWorkerData(this.state.worker)
			.then(() => {
				this.setState({
					showAddWorker: false,
					showToView: true,
					showToaster: true,
					toasterMessage: <Translate id='common.addCorrectly' />,
					toasterVariant: 'success',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToAdd' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Action to remove user in editWorker
	 */
	setViewEditWorkerMode(editWorker) {
		this.setState({
			showToEdit: true,
			editWorker: editWorker,
		})
	}

	/**
	 * Action to update worker
	 * @param {*} property
	 * @param {*} value
	 */
	updatePropertyWorker(property, value) {
		let updatedworker = { ...this.state.editWorker }
		updatedworker[property] = value
		this.setState({
			editWorker: updatedworker,
		})
	}

	/**
	 * Action on save worker
	 */
	onSaveWorker() {
		this.props
			.updateWorkerData(this.state.editWorker)
			.then(() => {
				this.setState({
					showToEdit: false,
					showToView: true,
					showToaster: true,
					toasterMessage: <Translate id='common.saveCorrectly' />,
					toasterVariant: 'success',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingWarehouseWorker === true) {
			return <LoadingPage />
		} else if (this.props.warehouseWorkers === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.warehouseWorkers}
					configuration={TableConfiguration}
					onRenderCellItem={this.onRenderCellItem}
					mode={'clientSide'}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(worker) => {
						this.setViewEditWorkerMode(worker)
					}}
				/>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof warehouseStaff
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<WarehouseIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<Fab
								size='small'
								color='primary'
								onClick={() => {
									this.setShowAddWorkerState(true)
								}}
							>
								<AddCompanytIcon />
							</Fab>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='warehouseWorkers.warehouseWorkers' />{' '}
						</Typography>
					}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to add information */}
				<Dialog
					open={this.state.showAddWorker}
					onClose={() => {
						this.setState({ showAddWorker: false })
					}}
					header={
						<Toolbar>
							<Typography variant='h6'>{<Translate id='warehouseWorkers.addworker' />}</Typography>
						</Toolbar>
					}
					actions={
						<DialogActions
							view={SHOW_EDIT_VIEW}
							onCancel={() => {
								this.setState({ showAddWorker: false })
							}}
							onClose={() => {
								this.setState({ showAddWorker: false })
							}}
							onSaveItem={() => this.onCreateWorker()}
							isSaving={this.state.isSavingWorker}
							canBeSaved={this.canBeSaved()}
						/>
					}
				>
					<DialogEditWorker
						classes={classes}
						worker={this.state.worker}
						updateProperty={this.updateProperty}
						validateData={this.validateData}
					/>
				</Dialog>

				{/** Dialog to update information */}
				<Dialog
					open={this.state.showToEdit}
					onClose={() => {
						this.setState({ showToEdit: false })
					}}
					header={
						<Toolbar>
							<Typography variant='h6'>{<Translate id='warehouseWorkers.updateWorker' />}</Typography>
						</Toolbar>
					}
					actions={
						<DialogActions
							view={SHOW_EDIT_VIEW}
							classes={classes}
							onCancel={() => {
								this.setState({ showToEdit: false })
							}}
							onClose={() => {
								this.setState({ showToEdit: false })
							}}
							onSaveItem={this.onSaveWorker}
							isSaving={this.props.isSavingWorker}
							canBeSaved={true}
						/>
					}
				>
					{this.state.editWorker && (
						<DialogEditWorker
							classes={classes}
							worker={this.state.editWorker}
							updateProperty={this.updatePropertyWorker}
							validateData={this.validateData}
						/>
					)}
				</Dialog>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='warehouseWorkers.warehouseWorkers' />,
			dataSource: 'name',
			isSortable: true,
		},
		{
			header: <Translate id='warehouseWorkers.status' />,
			dataSource: 'status',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingWarehouseWorker: state.warehouseWorkers.get('isLoadingWarehouseWorker'),
		warehouseWorkers: state.warehouseWorkers.get('warehouseWorkers')
			? state.warehouseWorkers.get('warehouseWorkers').toJS()
			: null,
		isSavingWorker: state.warehouseWorkers.get('isSavingWorker'),
		sortCriteria: state.warehouseWorkers.get('sortCriteria')
			? state.warehouseWorkers.get('sortCriteria').toJS()
			: null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadWarehouseWorkers: () => {
			dispatch(loadWarehouseWorkersFromBackEnd())
		},
		createWorkerData: (worker) => {
			return dispatch(createWorkerData(worker))
		},
		updateWorkerData: (worker) => {
			return dispatch(updateWorkerData(worker))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_WORKERS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(
		withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(WarehouseStaffIndex))
	)
)
