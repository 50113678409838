import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import { Button, LinearProgress, TextField, Typography, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'

/** Custom components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import Evidence from '../../../common/Evidence'
import ItemInfo from './ItemInfo'

/** Resources import section */
import {
	applyCurrencyFormat,
	applyPercentageFormat,
	IsNullOrEmpty,
	removeLeadingZeros,
	splitLines,
} from '../../../../store/helpers/StringHelper'

const PaymentRequestAddingPanel = (props) => {
	/** Destructuring properties */
	const {
		amount = 0,
		quantityOfServices,
		invoiceFiles,
		creditNoteFiles,
		fileValidation,
		invoice,
		creditNote,
		paymentRequestErrors,
		serviceOrdersErrors,
		saving,
		disabled,
		classes,
		onClose,
		onButtonCreateClick,
		onUploadInvoiceFiles,
		onRemoveInvoiceFiles,
		onUploadCreidtNoteFiles,
		onRemoveCreidtNoteFiles,
	} = props

	/** Local state */
	const [serviceOrderCodes, setServiceOrderCodes] = useState([])

	const creditNoteSectionEnabled = paymentRequestErrors.includes('InvalidTotalAmount')
	// const bulkInputShown = quantityOfServices === 0
	const bulkInputShown = false
	const quantityOfServicesInPaymentRequest = quantityOfServices === 0 ? serviceOrderCodes.length : quantityOfServices

	/**
	 * Handle the change of service orders value
	 * @param {*} event
	 */
	const onServiceOrdersChange = (event) => {
		const { value } = event.target
		const values = splitLines(value).filter((v) => !IsNullOrEmpty(v))
		const uniqValues = [...new Set(values.map((v) => v.trim()))]
		const trimmedValues = removeLeadingZeros(uniqValues)
		setServiceOrderCodes(trimmedValues)
	}

	const handleButtonCreateClick = () => {
		onButtonCreateClick(serviceOrderCodes)
	}

	const handleUploadInvoiceFiles = (files) => {
		if (files.length === 2) {
			onUploadInvoiceFiles(files, serviceOrderCodes)
		}

		return Promise.resolve('Ok')
	}

	const handleUploadCreditNoteFiles = (files) => {
		if (files.length === 2) {
			onUploadCreidtNoteFiles(files)
		}

		return Promise.resolve('Ok')
	}

	return (
		<LateralPanel onClose={onClose} classes={classes}>
			<PanelHeader
				isIconMaterial={true}
				titlePanel={<Translate id={'accreditedWorkshops.makePaymentRequest'} />}
				onCloseButtonClick={onClose}
				icon={AddIcon}
			/>
			<div className={classes.bodyContainer}>
				{bulkInputShown && (
					<TextField
						fullWidth
						variant='outlined'
						label={<Translate id={'accreditedWorkshops.typeSrviceOrdeCodes'} />}
						multiline
						rowsMax={5}
						onChange={onServiceOrdersChange}
						className={classes.textField}
						InputLabelProps={{
							classes: {
								root: classes.cssLabel,
								focused: classes.cssFocused,
							},
						}}
						InputProps={{
							classes: {
								root: classes.cssOutlinedInput,
								focused: classes.cssFocused,
								notchedOutline: classes.notchedOutline,
							},
							inputMode: 'text',
						}}
					/>
				)}
				<div className={classes.instructionsDiv}>
					<Typography variant='body2'>
						<Translate id={'accreditedWorkshops.instructionsToAddInvoice'} />
					</Typography>
					<Evidence
						text={'Cargar archivos pdf y xml'}
						uploadFiles={handleUploadInvoiceFiles}
						removeFiles={onRemoveInvoiceFiles}
						files={invoiceFiles}
						acceptedFiles={['pdf', 'xml']}
						isDisabled={false}
						maxFiles={2}
						disabledLink
						successToasterDisplayed={false}
					/>
				</div>
				{fileValidation.displayed && (
					<div className={fileValidation.isValid ? classes.valid : classes.invalid}>
						<Typography>
							<Translate
								id={`accreditedWorkshops.${fileValidation.isValid ? 'xmlIsValid' : 'xmlIsInvalid'}`}
							/>
						</Typography>
					</div>
				)}
				{invoice && (
					<>
						<div className={classes.infoContainer}>
							<Typography variant='overline'>
								<Translate id='accreditedWorkshops.selectedServiceOrders' />
							</Typography>
							<div className={classes.infoGral}>
								<ItemInfo
									translationId='accreditedWorkshops.quantity'
									text={quantityOfServicesInPaymentRequest}
								/>
								<ItemInfo
									translationId='accreditedWorkshops.amount'
									text={applyCurrencyFormat(amount)}
								/>
							</div>
						</div>
						<div className={classes.infoContainer}>
							<Typography variant='overline'>
								<Translate id='accreditedWorkshops.invoice.invoice' />
							</Typography>
							<div className={classes.infoGral}>
								<div>
									<ItemInfo translationId='accreditedWorkshops.invoice.serie' text={invoice.serie} />
									<ItemInfo translationId='accreditedWorkshops.invoice.folio' text={invoice.folio} />
									<ItemInfo
										translationId='accreditedWorkshops.invoice.rfc'
										text={invoice.recipientRFC}
									/>
								</div>
								<div>
									<ItemInfo
										label={<Translate id='accreditedWorkshops.invoice.iva' />}
										text={applyPercentageFormat(invoice.ivaPercentage)}
									/>
									<ItemInfo
										label={<Translate id='accreditedWorkshops.invoice.subTotal' />}
										text={applyCurrencyFormat(invoice.subTotal)}
									/>
									<ItemInfo
										label={<Translate id='accreditedWorkshops.invoice.total' />}
										text={applyCurrencyFormat(invoice.total)}
									/>
								</div>
							</div>
							{invoice &&
								invoice.retentions.map((item, index) => (
									<div key={index}>
										<Typography variant='body2'>
											<Translate id='accreditedWorkshops.retention' />
											{': '}
											{applyCurrencyFormat(item.amount)}
											{' - '}
											<Translate id='accreditedWorkshops.percentage' />
											{': '}
											{item.percentage * 100}%
										</Typography>
									</div>
								))}
						</div>
						{creditNote && (
							<>
								<div className={classes.infoContainer}>
									<Typography variant='overline'>
										<Translate id='accreditedWorkshops.invoice.creditNote' />
									</Typography>
									<div className={classes.infoGral}>
										<div>
											<ItemInfo
												label={<Translate id='accreditedWorkshops.invoice.folio' />}
												text={`${creditNote.serie}-${creditNote.folio}`}
											/>
											<ItemInfo
												label={<Translate id='accreditedWorkshops.invoice.rfc' />}
												text={creditNote.recipientRFC}
											/>
										</div>
										<div>
											<ItemInfo
												label={<Translate id='accreditedWorkshops.invoice.subTotal' />}
												text={applyCurrencyFormat(creditNote.subTotal)}
											/>
											<ItemInfo
												label={<Translate id='accreditedWorkshops.invoice.total' />}
												text={applyCurrencyFormat(creditNote.total)}
											/>
										</div>
									</div>
								</div>
							</>
						)}
					</>
				)}
				{(paymentRequestErrors.length > 0 || serviceOrdersErrors.length > 0) && (
					<div className={classes.errorsContainer}>
						<Typography variant='overline'>
							<Translate id='accreditedWorkshops.errors' />
						</Typography>
						{paymentRequestErrors.map((error) => (
							<Typography key={error}>
								<Translate id={`accreditedWorkshops.error.message${error}`} />
							</Typography>
						))}
						{serviceOrdersErrors.map((error) => (
							<Typography key={`${error.serviceOrderCode}-${error.errorCode}`}>
								{`» ${error.serviceOrderCode}:`}{' '}
								<Translate id={`accreditedWorkshops.error.message${error.errorCode}`} />
							</Typography>
						))}
					</div>
				)}
				{creditNoteSectionEnabled && (
					<div className={classes.instructionsDiv}>
						<Typography>
							<Translate id='accreditedWorkshops.creditNoteAddingMessage' />
						</Typography>
						<Evidence
							text={'Cargar archivos pdf y xml'}
							uploadFiles={handleUploadCreditNoteFiles}
							removeFiles={onRemoveCreidtNoteFiles}
							files={creditNoteFiles}
							acceptedFiles={['pdf', 'xml']}
							maxFiles={2}
							disabledLink
							successToasterDisplayed={false}
						/>
					</div>
				)}
			</div>
			{saving && <LinearProgress />}
			<Button
				className={classes.btnCreate}
				type='submit'
				variant='contained'
				disabled={saving || !invoiceFiles || disabled}
				onClick={handleButtonCreateClick}
				fullWidth
			>
				<Translate id={'accreditedWorkshops.create'} />
			</Button>
		</LateralPanel>
	)
}

PaymentRequestAddingPanel.propTypes = {
	amount: PropTypes.number.isRequired,
	quantityOfServices: PropTypes.number.isRequired,
	onClose: PropTypes.func.isRequired,
	onButtonCreateClick: PropTypes.func.isRequired,
	onUploadInvoiceFiles: PropTypes.func.isRequired,
	onRemoveInvoiceFiles: PropTypes.func.isRequired,
	onUploadCreidtNoteFiles: PropTypes.func.isRequired,
	onRemoveCreidtNoteFiles: PropTypes.func.isRequired,
	invoiceFiles: PropTypes.array,
	creaditNoteFiles: PropTypes.array,
	fileValidation: PropTypes.shape({
		displayed: PropTypes.bool.isRequired,
		isValid: PropTypes.bool.isRequired,
	}),
	invoice: PropTypes.oneOf([
		null,
		PropTypes.shape({
			subTotal: PropTypes.number.isRequired,
			total: PropTypes.number.isRequired,
			serie: PropTypes.string.isRequired,
		}),
	]),
	creaditNote: PropTypes.oneOf([
		null,
		PropTypes.shape({
			subTotal: PropTypes.number.isRequired,
			total: PropTypes.number.isRequired,
			serie: PropTypes.string.isRequired,
		}),
	]),
	saving: PropTypes.bool,
	disabled: PropTypes.bool,
}

PaymentRequestAddingPanel.defaultProps = {
	amount: 0,
	quantityOfServices: 0,
	onClose: () => console.warn('[onClose] function not defined!'),
	onButtonCreateClick: () => console.warn('[onButtonCreateClick] function not defined!'),
	onUploadInvoiceFiles: () => {
		console.warn('[onUploadInvoiceFiles] function not defined!')
		return Promise.reject()
	},
	onRemoveInvoiceFiles: () => {
		console.warn('[onRemoveInvoiceFiles] function not defined!')
		return Promise.reject()
	},
	onUploadCreidtNoteFiles: () => {
		console.warn('[onUploadCreidtNoteFiles] function not defined!')
		return Promise.reject()
	},
	onRemoveCreidtNoteFiles: () => {
		console.warn('[onRemoveCreidtNoteFiles] function not defined!')
		return Promise.reject()
	},
	fileValidation: {
		displayed: false,
		isValid: false,
	},
	invoice: null,
	creditNote: null,
	saving: false,
	disabled: true,
}

const styles = (theme) => ({
	bodyContainer: {
		paddingLeft: theme.spacing.unit * 2,
		paddingRight: theme.spacing.unit * 2,
		overflowX: 'auto',
	},
	valid: {
		backgroundColor: theme.palette.colors.green,
		textAlign: 'center',
	},
	invalid: {
		backgroundColor: theme.palette.secondary.light,
		textAlign: 'center',
	},
	panelHeader: {
		paddingLeft: theme.spacing.unit * 2,
		paddingRight: theme.spacing.unit * 2,
		paddingTop: theme.spacing.unit,
		paddingBottom: theme.spacing.unit,
		backgroundColor: theme.palette.primary.main,
	},
	infoContainer: {
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: 10,
		marginTop: theme.spacing.unit,
		paddingLeft: theme.spacing.unit * 3,
		paddingRight: theme.spacing.unit * 3,
	},
	infoGral: {
		display: 'flex',
		flex: '4 1 0',
		justifyContent: 'space-between',
	},
	errorsContainer: {
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: 10,
		marginTop: theme.spacing.unit,
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
	},
	instructionsDiv: {
		padding: theme.spacing.unit,
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	textField: {
		marginBottom: 10,
	},
	cssLabel: {
		margin: 0,
		fontSize: 13,
	},
	cssOutlinedInput: {
		margin: 0,
		fontSize: 13,
	},
	cssFocused: {
		margin: 0,
		fontSize: 13,
	},
	notchedOutline: {
		borderWidth: '1px',
		margin: 0,
		fontSize: 13,
	},
})

export default withStyles(styles, { withTheme: true })(PaymentRequestAddingPanel)
