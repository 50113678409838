
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/** Material-UI imports section */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography } from '@material-ui/core';

/** Components section imports */
import UserSelector from '../common/UserSelector';

/** Language imports **/
import { Translate } from "react-localize-redux";

/** Import section actions */
import { GetPartnerships, GetCentersToCompany, GetAllDivisionToCompany } from '../../store/helpers/SelectOptions';

/**
 * Component Form comapny
 */
class FormView extends Component {


    /* Determine if a key is inclued in the errors list the Company
    *
    * @param {*} key
    * @returns
    */
    hasInvalidValue(key) {
        if (!this.props.errors) {
            return false;
        }
        return this.props.errors.some(error => error.key === key);
    }

    /**
     * Render show 
     */
    render() {
        const { classes } = this.props;
        let company = this.props.company;
        return (
            <form noValidate autoComplete="off">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <TextField fullWidth
                            error={this.hasInvalidValue("name")}
                            disabled={this.props.showToView}
                            id="standard-uncontrolled"
                            label={<Translate id="companies.name" />}
                            value={company.name}
                            className={classes.tetxtFieldFull}
                            onChange={(event) => { this.props.updateProperty("name", event.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth
                            error={this.hasInvalidValue("commercial_name")}
                            disabled={this.props.showToView}
                            id="standard-read-only-input"
                            label={<Translate id="companies.comercialname" />}
                            value={company.commercial_name}
                            onChange={(event) => { this.props.updateProperty("commercial_name", event.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth
                            error={this.hasInvalidValue("rfc")}
                            disabled={this.props.showToView}
                            id="standard-read-only-input"
                            label={<Translate id="companies.rfc" />}
                            value={company.rfc}
                            onChange={(event) => { this.props.updateProperty("rfc", event.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth
                            error={this.hasInvalidValue("address")}
                            disabled={this.props.showToView}
                            id="standard-uncontrolled"
                            label={<Translate id="companies.address" />}
                            value={company.address}
                            className={classes.tetxtFieldFull}
                            onChange={(event) => { this.props.updateProperty("address", event.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField fullWidth
                            disabled={this.props.showToView}
                            id="standard-read-only-input"
                            label={<Translate id="companies.zipCode" />}
                            value={company.zip_code}
                            onChange={(event) => { this.props.updateProperty("zip_code", event.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField fullWidth
                            error={this.hasInvalidValue("partnership_code")}
                            disabled={this.props.showToView}
                            id="standard-select"
                            select
                            label={<Translate id="companies.partnershipCode" />}
                            value={company.partnership_code}
                            onChange={(event) => { this.props.updateProperty('partnership_code', event.target.value) }}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                        >
                            {GetPartnerships().map(partner => (
                                <option key={partner.value} value={partner.value}>
                                    {partner.value}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField fullWidth
                            error={this.hasInvalidValue("center")}
                            disabled={this.props.showToView}
                            id="standard-select"
                            select
                            label={<Translate id="companies.centerInitial" />}
                            value={company.center}
                            onChange={(event) => { this.props.updateProperty('center', event.target.value) }}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                        >
                            {GetCentersToCompany().map(center => (
                                <option key={center.value} value={center.value}>
                                    {center.value}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField fullWidth
                            error={this.hasInvalidValue("center_fin")}
                            disabled={this.props.showToView}
                            id="standard-select"
                            select
                            label={<Translate id="companies.centerEnd" />}
                            value={company.center_fin}
                            onChange={(event) => { this.props.updateProperty('center_fin', event.target.value) }}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                        >
                            {GetCentersToCompany().map(center => (
                                <option key={center.value} value={center.value}>
                                    {center.value}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth
                            error={this.hasInvalidValue("division")}
                            disabled={this.props.showToView}
                            id="standard-select"
                            select
                            label={<Translate id="companies.division" />}
                            value={company.division}
                            onChange={(event) => { this.props.updateProperty('division', event.target.value) }}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                        >
                            {GetAllDivisionToCompany().map(division => (
                                <option key={division.value} value={division.value}>
                                    {division.value}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="caption" gutterBottom color={'textSecondary'}><Translate id="companies.administratorUser"/></Typography>
                        <UserSelector
                            isDisabled={this.props.showToView}
                            value={company.administrator_token}
                            onUserSelected={(engineer) => { this.props.updateProperty('administrator_token', engineer.user_name) }}
                        />
                    </Grid>

                    {this.props.showToView &&
                        <Grid item xs={6}>
                            <TextField fullWidth
                                error={this.hasInvalidValue("document_identifier")}
                                disabled
                                id="standard-read-only-input"
                                label={<Translate id="companies.documentIdentifier" />}
                                value={company.document_identifier}
                                onChange={(event) => { this.props.updateProperty("document_identifier", event.target.value) }}
                            />
                            <br /><br /><br />
                        </Grid>
                    }
                    {this.props.createCompany &&
                        <Grid item xs={6}>
                            <TextField fullWidth
                                error={this.hasInvalidValue("document_identifier")}
                                disabled={this.props.showToView}
                                id="standard-read-only-input"
                                label={<Translate id="companies.documentIdentifier" />}
                                value={company.document_identifier}
                                onChange={(event) => { this.props.updateProperty("document_identifier", event.target.value) }}
                            />
                            <br /><br /><br />
                        </Grid>
                    }
                    {!this.props.showToView && <Grid item xs={12} >
                        <DialogActions>
                            <Button
                                data-cy="btn-save"
                                type="button"
                                color="primary"
                                onClick={this.props.onSave}
                                disabled={!this.props.canBeSaved}>
                                {<Translate id="common.save" />}
                            </Button>
                            <Button onClick={() => this.props.setViewInEditMode(true)} color="secondary">
                                {<Translate id="common.cancel" />}
                            </Button>
                        </DialogActions>
                    </Grid>}
                </Grid>
            </form>
        )
    }
}

const viewStyles = theme => ({
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    footerForm: {
        display: "flex",
        paddingRight: "60px",
    },
});

export default withRouter(withStyles(viewStyles, { withTheme: true })(FormView));