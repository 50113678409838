import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';

// import { DateRange as ReactCalendar } from 'react-date-range';

/** Material UI import section */
import { InputAdornment, TextField } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRangeTwoTone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//import { DateRangePicker as ReactDateRagePicker } from 'react-date-range'
// import 'react-date-range/dist/styles.css' // main style file
// import 'react-date-range/dist/theme/default.css' // theme css file
// import * as locales from 'react-date-range/dist/locale';

/**
 * Date time picker component
 */
const DateRangePicker = (props) => {
	/**
	 * onChangeRange callback
	 */
	const onChangeRange = (_, picker) => {
		const startDate = picker.startDate.startOf('day');
		const endDate = picker.endDate.endOf('day');
		// const endDate = picker.startDate.startOf('day');
		// const startDate = picker.endDate.endOf('day');
		props.onChangeRange({ startDate: startDate, endDate: endDate });
	};

	return (
		<div>
			<DatetimeRangePicker
				style={{ marginLeft: '5px' }}
				showDropdowns
				startDate={props.startDate}
				endDate={props.endDate}
				onEvent={onChangeRange}
				//minDate={this.props.minDate}
				//maxDate={this.props.maxDate}
			>
				<TextField
					fullWidth
					variant={props.variant}
					size="small"
					value={`${props.startDate.format('DD/MM/YYYY')}  -  ${props.endDate.format('DD/MM/YYYY')}`}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<DateRangeIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="start">
								<ExpandMoreIcon />
							</InputAdornment>
						)
						//className: classes.text
					}}
				/>
			</DatetimeRangePicker>

			{/* <ReactCalendar
					locale={locales['es']}
                    ranges={getSelectionRange()}
                    onChange={onChangeRange}
                    months={2}
                    direction='horizontal'
                    showDateDisplay={false}
                    staticRanges={getStaticRanges()}
                /> */}
		</div>
	);
};
DateRangePicker.propTypes = {
	/**
	 * The initial selected range date
	 */
	startDate: propTypes.instanceOf(moment).isRequired,
	/**
	 * The final selected range date
	 */
	endDate: propTypes.instanceOf(moment).isRequired,
	/**
	 * Callback executed when the range has changed
	 */
	onChangeRange: propTypes.func.isRequired,
	/**
	 * The text field variant value
	 */
	variant: propTypes.oneOf(['filled', 'outlined']),
	/**
	 * The predefined range shown in the lateral panel
	 */
	predefinedRanges: propTypes.arrayOf(
		propTypes.shape({
			label: propTypes.string.isRequired,
			startDate: propTypes.instanceOf(moment).isRequired,
			endDate: propTypes.instanceOf(moment).isRequired
		})
	),
	/**
	 * The color to use in the text and icons
	 */
	textColor: propTypes.string
};
DateRangePicker.defaultProps = {
	startDate: moment(),
	endDate: moment(),
	onChangeRange: () => console.warn('No [onChangeRange] callback defined'),
	variant: 'outlined',
	predefinedRanges: undefined,
	textColor: 'rgba(0, 0, 0, 0.87)'
};
export default DateRangePicker;

// const useStyles = makeStyles((theme) => ({
// 	text: {
// 		// color: ({ textColor }) => `${textColor}`
// 	}
// }))
