import axios from 'axios'
import moment from 'moment'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'
//Import resources sections
import {
	backgroundColorTableStatus,
	descriptionStatus,
	backgroundColorTableSupplierStatus,
	descriptionSupplierStatus,
	descriptionSupplierType,
	GetDescriptionEvaluation,
	getColorTableGraph,
	GetColorEvaluation,
	descriptionIndirectEvaluationTypeTranslate,
} from '../helpers/IndirectQualificationsHelpers'

export const START_LOADING_INDIRECT_QUALIFICATIONS = 'START_LOADING_INDIRECT_QUALIFICATIONS'
export const SET_INDIRECT_QUALIFICATIONS_LIST = 'SET_INDIRECT_QUALIFICATIONS_LIST'
export const SET_INDIRECT_QUALIFICATIONS_BY_PERIOD_LIST = 'SET_INDIRECT_QUALIFICATIONS_BY_PERIOD_LIST'
export const SET_INDIRECT_QUALIFICATIONS_COUNT = 'SET_INDIRECT_QUALIFICATIONS_COUNT'
export const SET_RATING_BY_PERIOD_COUNT = 'SET_RATING_BY_PERIOD_COUNT'
export const IS_SAVING = 'IS_SAVING'
export const SET_TOASTER_OPTIONS = 'SET_TOASTER_OPTIONS'

/**Actions to add information */
export const SET_ADD_INDIRECT_QUALIFICATION = 'SET_ADD_INDIRECT_QUALIFICATION'
export const SET_INDIRECT_QUALIFICATION = 'SET_INDIRECT_QUALIFICATION'
export const SET_SUPPLIER_ACTION_PLANT = 'SET_SUPPLIER_ACTION_PLANT'
export const SET_SUPPLIER_IMPORT = 'SET_SUPPLIER_IMPORT'
/**Update information */
export const UPDATE_PROPERTY_QUALIFICATION = 'UPDATE_PROPERTY_QUALIFICATION'
export const UPDATE_PROPERTY_ITEMS = 'UPDATE_PROPERTY_ITEMS'
export const UPDATE_PROPERTY_ACTION_PLANT = 'UPDATE_PROPERTY_ACTION_PLANT'
export const UPDATE_PROPERTY_SUPPLIER = 'UPDATE_PROPERTY_SUPPLIER'
export const UPDATE_PROPERTY_EVALUATOR = 'UPDATE_PROPERTY_EVALUATOR'

export const GENERATE_ACTIONS_PLAN = 'GENERATE_ACTIONS_PLAN'
export const UPDATE_EVALUATOR_ITEM_PROPERTY = 'UPDATE_EVALUATOR_ITEM_PROPERTY'

export const LOAGIND_RATTINGS_GRAPH_SUPPLIER = 'LOAGIND_RATTINGS_GRAPH_SUPPLIER'
export const SET_RATTINGS_GRAPH_SUPPLIER = 'SET_RATTINGS_GRAPH_SUPPLIER'

/**
 * Action to set indirects Qualifications count
 * @param {*} count
 */
function setRatingsByPeriodCount(count) {
	return {
		type: SET_RATING_BY_PERIOD_COUNT,
		count,
	}
}

export function updatePropertyEvaluatorItem(supplierId, evaluatorId, itemId, propertyName, propertyValue) {
	return {
		type: UPDATE_EVALUATOR_ITEM_PROPERTY,
		supplierId,
		evaluatorId,
		itemId,
		propertyName,
		propertyValue,
	}
}

export function updatePropertyEvaluator(supplierId, evaluatorId, propertyName, propertyValue) {
	return {
		type: UPDATE_PROPERTY_EVALUATOR,
		supplierId: supplierId,
		evaluatorId: evaluatorId,
		propertyName: propertyName,
		propertyValue: propertyValue,
	}
}

/**
 * Action to set indirect list by period
 * @param {*} indirectsQualifications
 */
function setIndirectQualificationsByPeriodList(data) {
	return {
		type: SET_INDIRECT_QUALIFICATIONS_BY_PERIOD_LIST,
		data: data,
	}
}

export function updatePropertyActionPlant(actionPlantId, propertyName, propertyValue) {
	return {
		type: UPDATE_PROPERTY_ACTION_PLANT,
		actionPlantId: actionPlantId,
		propertyName: propertyName,
		propertyValue: propertyValue,
	}
}
/**
 * Action to set supplier whit actions plants
 * @param {*} count
 */
function setSupplierActionPlant(supplier) {
	return {
		type: SET_SUPPLIER_ACTION_PLANT,
		supplier,
	}
}

export function updatePropertyItem(item, propertyName, propertyValue) {
	return {
		type: UPDATE_PROPERTY_ITEMS,
		item: item,
		propertyName: propertyName,
		propertyValue: propertyValue,
	}
}

export function updatePropertySupplier(supplier, propertyName, propertyValue) {
	return {
		type: UPDATE_PROPERTY_SUPPLIER,
		supplier: supplier,
		propertyName: propertyName,
		propertyValue: propertyValue,
	}
}

export function updatePropertyQualification(propertyName, propertyValue) {
	return {
		type: UPDATE_PROPERTY_QUALIFICATION,
		propertyName: propertyName,
		propertyValue: propertyValue,
	}
}

export function setSuppliersImport(suppliers) {
	return {
		type: SET_SUPPLIER_IMPORT,
		suppliers: suppliers,
	}
}

export function setToasterOptions(configuration) {
	return {
		type: SET_TOASTER_OPTIONS,
		configuration: configuration,
	}
}

/**
 * Action start loading common data
 * @param {*} isLoading
 */
function isLoadingCommon(isLoading, identifier) {
	return {
		type: START_LOADING_INDIRECT_QUALIFICATIONS,
		isLoading: isLoading,
		identifier: identifier,
	}
}

/**
 * Action to set indirect list
 * @param {*} indirectsQualifications
 */
function setIndirectQualificationsList(indirectsQualifications) {
	return {
		type: SET_INDIRECT_QUALIFICATIONS_LIST,
		indirectsQualifications: indirectsQualifications,
	}
}

/**
 * Action to set indirects Qualifications count
 * @param {*} count
 */
function setIndirectsQualificationsCount(count) {
	return {
		type: SET_INDIRECT_QUALIFICATIONS_COUNT,
		count,
	}
}

/**
 * Action to set indirects Qualifications
 * @param {*} count
 */
function setAddIndirectsQualifications(indirectQualification) {
	return {
		type: SET_ADD_INDIRECT_QUALIFICATION,
		indirectQualification,
	}
}

/**
 * Action to set indirects Qualifications geting by id
 * @param {*} count
 */
function setIndirectsQualifications(indirectQualification) {
	return {
		type: SET_INDIRECT_QUALIFICATION,
		indirectQualification,
	}
}

/**
 * Action start saving information
 * @param {*} isLoading
 */
export function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
		identifier: identifier,
	}
}

//#region ACTION FOR INDIRECT QUALIFICATIONS

/**
 * Action load indirect qualifications from company backend
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @param {*} page
 * @param {*} rowsPerPage
 */
export function loadAllIndirectQualificationsFromBackEnd(queryParameters, translate) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, START_LOADING_INDIRECT_QUALIFICATIONS))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/ratings`
			let command = {
				PageNumber:
					queryParameters && queryParameters.pageNumber ? parseInt(queryParameters.pageNumber) + 1 : 1,
				PageSize: queryParameters && queryParameters.rowsPerPage ? queryParameters.rowsPerPage : 20,
				SortingBy: queryParameters && queryParameters.sortBy ? queryParameters.sortBy : 'CreationDate',
				SortingOrder: queryParameters && queryParameters.sortDirection ? queryParameters.sortDirection : 'desc',
				Query: queryParameters.query ? queryParameters.query : '',
				CompanyCode: selectedCompany ? selectedCompany.document_identifier : '',
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						let mappedResponse = serverResponse.data.Results.map((item) => {
							item.color_status_table = backgroundColorTableStatus(item.Status)
							item.Status = descriptionStatus(item.Status, translate)
							item.EvaluationTypeDescription = descriptionIndirectEvaluationTypeTranslate(
								item.EvaluationType,
								translate
							)
							item.EvaluationDateDescription = moment(item.EvaluationDate).format('MMMM YYYY')
							return item
						})
						dispatch(setIndirectsQualificationsCount(serverResponse.data.TotalItems))
						dispatch(setIndirectQualificationsList(mappedResponse))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isLoadingCommon(false, START_LOADING_INDIRECT_QUALIFICATIONS))
					console.error('Error getting indirect qualifications', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isLoadingCommon(false, SET_INDIRECT_QUALIFICATIONS_LIST))
		}
	}
}

export function downloadReportRatings(startDate, endDate) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_ADD_INDIRECT_QUALIFICATION))
		const endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/ratings/reportByPeriod`
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		let command = {
			StartDate: startDate,
			EndDate: endDate,
			CompanyCode: selectedCompany ? selectedCompany.document_identifier : '',
		}
		return axios
			.post(endPoint, command, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, SET_ADD_INDIRECT_QUALIFICATION))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte-Calificaciones.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_ADD_INDIRECT_QUALIFICATION))
				console.error('Error al to download report ratings', error)
				return Promise.reject()
			})
	}
}

export function downloadReportRatingsDetail(id, evaluationType) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_ADD_INDIRECT_QUALIFICATION))
		const endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/rating/ratingReportDetail/${id}/${evaluationType}`
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, SET_ADD_INDIRECT_QUALIFICATION))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte-Calificaciones.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_ADD_INDIRECT_QUALIFICATION))
				console.error('Error al to download report ratings', error)
				return Promise.reject()
			})
	}
}

export function addIndirectQualification(qualificationModel, translate) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_ADD_INDIRECT_QUALIFICATION))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/rating`
		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		let command = {
			Name: qualificationModel ? qualificationModel.name : '',
			ClosingDate: qualificationModel ? qualificationModel.closingDate : null,
			StartDate: qualificationModel ? qualificationModel.startDate : null,
			ShippingDate: qualificationModel ? qualificationModel.shippingDate : null,
			EvaluationType:
				qualificationModel && qualificationModel.evaluationType
					? qualificationModel.evaluationType.value
					: null,
			EvaluationYear: qualificationModel && qualificationModel.year ? qualificationModel.year.value : null,
			EvaluationMonth: qualificationModel && qualificationModel.month ? qualificationModel.month.value : null,
			CreatedBy: user ? user.profile.username : '',
			CreateName: user ? user.profile.name : '',
			CompanyCode: selectedCompany ? selectedCompany.document_identifier : '',
		}
		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.color_status_table = backgroundColorTableStatus(serverResponse.data.Status)
					serverResponse.data.Status = descriptionStatus(serverResponse.data.Status, translate)
					serverResponse.data.EvaluationTypeDescription = descriptionIndirectEvaluationTypeTranslate(
						serverResponse.data.EvaluationType,
						translate
					)
					serverResponse.data.EvaluationDateDescription = moment(serverResponse.data.EvaluationDate).format(
						'MMMM YYYY'
					)
					dispatch(setAddIndirectsQualifications(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_ADD_INDIRECT_QUALIFICATION))
				console.error('Error update bidding base', error.response)
				return Promise.reject(error.response.data)
			})
	}
}

export function loadIndirectQualificationFromBackEnd(id, isLoading = true) {
	return function (dispatch, getState) {
		if (isLoading) {
			dispatch(isLoadingCommon(true, SET_INDIRECT_QUALIFICATION))
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/rating/${id}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SET_INDIRECT_QUALIFICATION))
					dispatch(setIndirectsQualifications(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SET_INDIRECT_QUALIFICATION))
				console.error('Error getting indirect qualification', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function importIndirectQualificationTemplateFile(xmlFile, id) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_SUPPLIER_IMPORT))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/rating/${id}/suppliers/template`
		let formData = new FormData()
		xmlFile.forEach((file) => {
			formData.append('data', file)
		})
		return axios
			.put(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, SET_SUPPLIER_IMPORT))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_SUPPLIER_IMPORT))
				console.error('Error import suppliers ', error.response)
				return Promise.reject(error.response.data)
			})
	}
}

export function setStatusRating(id, status, comments) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_PROPERTY_QUALIFICATION))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/rating/${id}/status`
		let command = {
			Id: id,
			Status: status,
			Comments: comments,
		}
		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_PROPERTY_QUALIFICATION))
					dispatch(setIndirectsQualifications(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_PROPERTY_QUALIFICATION))
				console.error('Error update status folio', error.response)
				return Promise.reject(error.response.data)
			})
	}
}

export function addCommentsToSupplier(evaluator) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_PROPERTY_QUALIFICATION))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/comments`
		let command = {
			SupplierId: evaluator ? evaluator.SupplierId : '',
			RatingId: evaluator ? evaluator.IndirectQualificationId : '',
			Comments: evaluator ? evaluator.Comments : '',
		}
		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_PROPERTY_QUALIFICATION))
					dispatch(
						updatePropertyEvaluator(evaluator.SupplierId, evaluator.Id, 'Comments', evaluator.Comments)
					)
					// dispatch(updatePropertySupplier(serverResponse.data, 'Items', serverResponse.data.Items))
					// dispatch(loadIndirectQualificationFromBackEnd(evaluator ? evaluator.IndirectQualificationId : ''))

					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_PROPERTY_QUALIFICATION))
				console.error('Error update update comments', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function addIndirectQualificationToSupplier(item) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_PROPERTY_QUALIFICATION))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/scope`
		let command = {
			Scope: item ? item.Scope : '',
			RatingId: item ? item.IndirectQualificationId : '',
			SupplierId: item ? item.SupplierId : '',
			ItemId: item ? item.Id : '',
			EvaluatorId: item.EvaluatorId,
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_PROPERTY_QUALIFICATION))
					dispatch(
						updatePropertyEvaluatorItem(
							item.SupplierId,
							item.EvaluatorId,
							item.Id,
							'Scope',
							serverResponse.data.Scope
						)
					)
					dispatch(
						updatePropertyEvaluator(
							item.SupplierId,
							item.EvaluatorId,
							'TotalItemsEvaluated',
							serverResponse.data.TotalItemsEvaluated
						)
					)
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_PROPERTY_QUALIFICATION))
				console.error('Error update cancel folio', error.response)
				return Promise.reject(error.response.data)
			})
	}
}

export function addItemComments(itemObject) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_PROPERTY_QUALIFICATION))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/item/comments`
		let command = {
			Comments: itemObject ? itemObject.item.Comments : '',
			RatingId: itemObject ? itemObject.evaluator.IndirectQualificationId : '',
			SupplierId: itemObject ? itemObject.evaluator.SupplierId : '',
			ItemId: itemObject ? itemObject.item.Id : '',
			EvaluatorId: itemObject.item.EvaluatorId,
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_PROPERTY_QUALIFICATION))
					dispatch(
						updatePropertyEvaluatorItem(
							itemObject.evaluator.SupplierId,
							itemObject.item.EvaluatorId,
							itemObject.item.Id,
							'Comments',
							serverResponse.data.Comments
						)
					)
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_PROPERTY_QUALIFICATION))
				console.error('Error update cancel folio', error.response)
				return Promise.reject(error.response.data)
			})
	}
}

export function generateActionPlantForSupplier(supplier) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, GENERATE_ACTIONS_PLAN))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/actionPlant`
		let command = {
			RatingId: supplier ? supplier.IndirectQualificationId : '',
			SupplierId: supplier ? supplier.Id : '',
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, GENERATE_ACTIONS_PLAN))
					dispatch(updatePropertySupplier(supplier, 'Status', serverResponse.data.Status))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, GENERATE_ACTIONS_PLAN))
				console.error('Error generate action plan', error.response)
				return Promise.reject(error.response)
			})
	}
}

//#endregion

//#region ACTION FOR SUPPLIER ACTION PLANTS
export function loadActionPlantSupplierFromBackEnd(id, supplierId) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_SUPPLIER_ACTION_PLANT))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/${id}/actionPlant/${supplierId}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SET_SUPPLIER_ACTION_PLANT))
					dispatch(setSupplierActionPlant(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SET_SUPPLIER_ACTION_PLANT))
				console.error('Error getting supplier id', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Add evidence in action plan
 * @param {*} actionPlanId
 * @param {*} files
 * @returns
 */
export function addEvidenceInActionPlan(ratingId, supplierId, actionPlantId, files, listActions) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_SUPPLIER_ACTION_PLANT))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/${ratingId}/${supplierId}/actionPlans/${actionPlantId}/evidences`
		let formData = new FormData()
		files.forEach((file) => {
			formData.append('data', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updatePropertyActionPlant(actionPlantId, 'Attachments', serverResponse.data.Attachments))
					dispatch(isSavingCommon(false, SET_SUPPLIER_ACTION_PLANT))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_SUPPLIER_ACTION_PLANT))
				console.error('Error add attachments in the action plan', error.response)
			})
	}
}

/**
 * Action to remove files to supplier from saplme
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeFileInActionPlan(ratingId, supplierId, actionPlantId, fileName, listActions) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/${ratingId}/${supplierId}/actionPlans/${actionPlantId}/evidences`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updatePropertyActionPlant(actionPlantId, 'Attachments', serverResponse.data.Attachments))
					dispatch(isSavingCommon(false, SET_SUPPLIER_ACTION_PLANT))
					return Promise.resolve()
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to upload order file(s) from supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Download files
 * @param {*} id
 * @param {*} fileName
 * @param {*} extension
 * @returns
 */
export const downloadFilesActionPlant = (id, fileName, extension) => {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let url = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/evidences/${id}/${fileName}/${extension}`
		return axios
			.get(url, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `${fileName}`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error donwload file', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to update action plan in sustainability from auditor
 * @param {*} id
 * @param {*} property
 * @param {*} propertyData
 */
export function updateActionPlanFromResponsible(ratingId, supplierId, actionPlan, checAproved, listActions) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_SUPPLIER_ACTION_PLANT))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/${ratingId}/${supplierId}/actionPlans/${actionPlan.Id}`
		let data = {
			ComplianceDate: actionPlan.ComplianceDate,
			ApprovalDate: actionPlan.ApprovalDate,
			CommentsPlant: actionPlan.CommentsPlant,
			CheckAproved: checAproved,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(
						updatePropertyActionPlant(actionPlan.Id, 'ComplianceDate', serverResponse.data.ComplianceDate)
					)
					dispatch(updatePropertyActionPlant(actionPlan.Id, 'ApprovalDate', serverResponse.data.ApprovalDate))
					dispatch(
						updatePropertyActionPlant(actionPlan.Id, 'CommentsPlant', serverResponse.data.CommentsPlant)
					)
					dispatch(updatePropertyActionPlant(actionPlan.Id, 'CheckAproved', serverResponse.data.CheckAproved))
					dispatch(updatePropertyActionPlant(actionPlan.Id, 'Status', serverResponse.data.Status))
					dispatch(isSavingCommon(false, SET_SUPPLIER_ACTION_PLANT))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_SUPPLIER_ACTION_PLANT))
				console.error('Error to update action plan', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update action plan in supplier
 * @param {*} id
 * @param {*} property
 * @param {*} propertyValue
 */
export function updateActionPlanFromBackend(ratingId, supplierId, actionPlanId, property, propertyValue, listActions) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_SUPPLIER_ACTION_PLANT))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/${ratingId}/${supplierId}/actionPlans/${actionPlanId}`
		let data = {
			[property]: propertyValue,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.SupplierId = supplierId
					dispatch(updatePropertyActionPlant(actionPlanId, property, serverResponse.data[property]))
					dispatch(updatePropertyActionPlant(actionPlanId, 'Status', serverResponse.data.Status))

					dispatch(isSavingCommon(false, SET_SUPPLIER_ACTION_PLANT))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_SUPPLIER_ACTION_PLANT))
				console.error('Error to update action plan', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update action plan in rating from supplier
 * @param {*} id
 * @param {*} property
 * @param {*} propertyData
 */
export function updateActionPlanFromSupplier(ratingId, supplierId, actionPlan, listActions) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_SUPPLIER_ACTION_PLANT))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/${ratingId}/${supplierId}/actionPlans/${actionPlan.Id}`
		let data = {
			CommentsSupplier: actionPlan.CommentsSupplier,
			CommitmentDate: actionPlan.CommitmentDate,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.SupplierId = supplierId
					dispatch(
						updatePropertyActionPlant(
							actionPlan.Id,
							'CommentsSupplier',
							serverResponse.data.CommentsSupplier
						)
					)
					dispatch(
						updatePropertyActionPlant(actionPlan.Id, 'CommitmentDate', serverResponse.data.CommitmentDate)
					)
					dispatch(updatePropertyActionPlant(actionPlan.Id, 'Status', serverResponse.data.Status))
					dispatch(isSavingCommon(false, SET_SUPPLIER_ACTION_PLANT))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_SUPPLIER_ACTION_PLANT))
				console.error('Error to update action plan', error.response)
				return Promise.reject()
			})
	}
}

export function setStatusSupplier(ratingId, supplierId, status, comments) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_PROPERTY_QUALIFICATION))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/supplier/status`
		let command = {
			RatingId: ratingId,
			SupplierId: supplierId,
			Status: status,
			Comments: comments,
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_PROPERTY_QUALIFICATION))
					dispatch(setSupplierActionPlant(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_PROPERTY_QUALIFICATION))
				console.error('Error update status folio', error.response)
				return Promise.reject(error.response.data)
			})
	}
}

export function ratingsByPeriod(startDate, endDate, translate, queryParameters) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, GENERATE_ACTIONS_PLAN))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/ratings/period`
		let command = {
			PageNumber: queryParameters && queryParameters.pageNumber ? parseInt(queryParameters.pageNumber) + 1 : 1,
			PageSize: queryParameters && queryParameters.rowsPerPage ? queryParameters.rowsPerPage : 20,
			SortingBy: queryParameters && queryParameters.sortBy ? queryParameters.sortBy : 'CreationDate',
			SortingOrder: queryParameters && queryParameters.sortDirection ? queryParameters.sortDirection : 'desc',
			Query: queryParameters && queryParameters.query ? queryParameters.query : '',
			StartDate: startDate,
			EndDate: endDate,
			CompanyCode: selectedCompany ? selectedCompany.document_identifier : '',
		}
		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, GENERATE_ACTIONS_PLAN))
					let mappedResponse = serverResponse.data.Results.map((item) => {
						item.TotalRating = `${item.TotalRating}`
						item.color_status_table = backgroundColorTableSupplierStatus(item.Status)
						item.StatusDescription = descriptionSupplierStatus(item.Status, translate)
						item.SupplierTypeDescription = descriptionSupplierType(item.SupplierType, translate)
						item.DescriptionEvaluationType = GetDescriptionEvaluation(item.DescriptionEvaluation, translate)
						item.EvaluationDateDescription = moment(item.EvaluationDate).format('MMMM YYYY')
						return item
					})
					dispatch(setIndirectQualificationsByPeriodList(mappedResponse))
					dispatch(setRatingsByPeriodCount(serverResponse.data.TotalItems))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, GENERATE_ACTIONS_PLAN))
				console.error('Error getting rating by period', error.response)
				return Promise.reject(error.response)
			})
	}
}

//#endregion

function isLoadingGraph(isLoading) {
	return {
		type: LOAGIND_RATTINGS_GRAPH_SUPPLIER,
		isLoadingRating: isLoading,
	}
}

function setRatingGraphSupplier(ratings) {
	return {
		type: SET_RATTINGS_GRAPH_SUPPLIER,
		data: ratings,
	}
}

/**
 * Get and set the failure report quality in the estate from read from backend
 * @param {*} token
 */
export function loadRatingsIndirectsBySupplierFromBackEnd(supplierCode) {
	return function (dispatch, getState) {
		dispatch(isLoadingGraph(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		if (selectedCompany) {
			let endPoint = `${process.env.REACT_APP_SIP_INDIRECT_QUALIFICATIONS}/ratings/${selectedCompany.get(
				'document_identifier'
			)}/${supplierCode}`
			return axios
				.get(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isLoadingGraph(false))
						let mappedResponse = serverResponse.data.map((item) => {
							item.color_final_score = GetColorEvaluation(item.description)
							item.color_status_table = getColorTableGraph(item.description)
							item.rating_date = moment(item.evaluation_date).format('MMMM YYYY')
							item.rating_year = moment(item.evaluation_date).format('YYYY')
							return item
						})
						dispatch(setRatingGraphSupplier(mappedResponse))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isLoadingGraph(false))
					console.error('Error getting ratings by supplier', error.response)
					return Promise.reject()
				})
		} else {
			dispatch(isLoadingGraph(false))
			console.error('Error getting ratings by supplier')
			return Promise.reject()
		}
	}
}
