import { createSelector } from 'reselect'
import { toSafeJS } from '../helpers/ImmutableHelper'

const getPaymentRequest = (state) => state.releasePlanning.get('releasePlanning')
const getItems = (state) => state.releasePlanning.get('materialsRelease')
const getDeliveryOrders = (state) => state.deliveryOrder.get('deliveryOrders')
const getIslodigDeliveryOrders = (state) => state.deliveryOrder.get('isLoadingDeliveryOrderItems')
const getIslodigAddDelivery = (state) => state.deliveryOrder.get('isLoadingAddDeliveryOrderItems')
const getLoadingDeliveryShowView = (state) => state.deliveryOrder.get('isLoadingDeliveryShowView')



export const getreleasePlanning = createSelector([getPaymentRequest], (release) => (release ? toSafeJS(release) : []))
export const getMaterialsRelease = createSelector([getItems], (items) => (items ? toSafeJS(items) : []))
export const getDeliveryOrder= createSelector([getDeliveryOrders], (delivery) => (delivery ? toSafeJS(delivery) : []))
export const getIsLoadingDeliveryOrdenItems= createSelector([getIslodigDeliveryOrders], (delivery) => (delivery))
export const getisLoadingAddDeliveryOrderItems= createSelector([getIslodigAddDelivery], (delivery) => (delivery))
export const getLoadingDeliveryOrderShowView= createSelector([getLoadingDeliveryShowView], (isLoading) => (isLoading))
