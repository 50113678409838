import React from 'react'
import { Chip } from '@material-ui/core'
import { FactoringStatus } from './AppConstants'
import { Translate } from 'react-localize-redux'
export const descriptionStatus = (status) => {
	switch (status) {
		case FactoringStatus.DRAFT:
			return <Chip label={<Translate id='factoringAffiliation.status.draft' />} />
		case FactoringStatus.IN_PROCESS:
			return <Chip color='secondary' label={<Translate id='factoringAffiliation.status.inProcess' />} />
		case FactoringStatus.VALIDATION_SUPPLIER:
			return (
				<Chip
					style={{ backgroundColor: 'rgba(121, 169, 216, 1)' }}
					label={<Translate id='factoringAffiliation.status.validationSupplier' />}
				/>
			)
		case FactoringStatus.REJECTED_BY_SUPPLIER:
			return (
				<Chip
					style={{ backgroundColor: '#d32f2f' }}
					label={<Translate id='factoringAffiliation.status.rejectedBySupplier' />}
				/>
			)
		case FactoringStatus.PAYMENT_SETUP_COMPLETATION:
			return (
				<Chip
					style={{ backgroundColor: 'yellow' }}
					label={<Translate id='factoringAffiliation.status.paymentConfiguration' />}
				/>
			)
		case FactoringStatus.SIGNATURES_WITH_BANK:
			return (
				<Chip
					style={{ backgroundColor: '#B79EFF' }}
					label={<Translate id='factoringAffiliation.status.signaturesWithBank' />}
				/>
			)
		case FactoringStatus.CLOSED:
			return (
				<Chip
					style={{ backgroundColor: 'rgba(121, 216, 126, 1)' }}
					label={<Translate id='factoringAffiliation.status.closed' />}
				/>
			)
		default:
			return <Chip label={<Translate id='factoringAffiliation.status.draft' />} />
	}
}
