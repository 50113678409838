import React from 'react'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

/** Import component section */
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'

const DialogComponent = (props) => {
	const { title, onClose, view, classes, open, isSaving, canBeSaved, children, onSave, savingTranslate } = props
	return (
		<Dialog
			style={{ overflowX: 'hidden' }}
			open={open}
			onClose={onClose}
			header={
				<Toolbar>
					<Typography variant='h6'>{title}</Typography>
				</Toolbar>
			}
			actions={
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					{!canBeSaved && (
						<Typography variant='body1' color='primary'>
							{<Translate id='common.requiredFields' />}
						</Typography>
					)}

					<DialogActions
						view={view}
						classes={classes}
						onCancel={onClose}
						onClose={onClose}
						onSaveItem={onSave}
						isSaving={isSaving}
						canBeSaved={canBeSaved}
						saveButtonTranslate={savingTranslate ? savingTranslate : 'carrierTenders.saveRequest'}
					/>
				</div>
			}
			children={children}
		></Dialog>
	)
}

export default DialogComponent
