import { fromJS } from 'immutable';

import * as ratingActions from './ratingActions';

/**
 * ratingActions initial state
 */
export const ratingInitialState = fromJS({
    isSaving: false,
    isLoading: false,
    isSending: false,
    rating: null,
    ratingToPublish: null,
    isLoadingEvaluation: false,
    evaluationRating: null,
    ratings: null,
    ratingsPtm: null,
    ratingsFersa:[]
})

export default function ratingReducer(state = ratingInitialState, action) {
    switch (action.type) {
        case ratingActions.IS_SAVING_RATING: {
            return state.merge({
                isSaving: action.isSaving,
                ratingsPtm: null,
            })
        }
        case ratingActions.IS_LOADING: {
            return state.merge({
                isLoading: action.isLoading
            })
        }
        case ratingActions.IS_SENDING_RATING:{
            return state.merge({
                isSending: action.isSending
            })
        }
        case ratingActions.GET_RATING_SUPPLIER: {
            return state.merge({
                isLoading: false,
                rating: fromJS(action.rating)
            })
        }
        case ratingActions.SET_RATING_TO_PUBLISH: {
            return state.merge({
                isLoadingRating: false,
                isSaving: false,
                ratingToPublish: fromJS(action.ratingToPublish)
            })
        }
        case ratingActions.IS_LOADING_RATING:{
            return state.merge({
                isLoadingRating: action.isLoadingRating,
                ratings: null,
            })
        }
        case ratingActions.SET_RATINGS_BY_SUPPLIER: {
            return state.merge({
                isLoadingRating: false,
                isSaving: false,
                ratings: fromJS(action.ratings)
            })
        }
        case ratingActions.START_GET_EVALUATION_SUPPLIERS_PERCENTAGE: {
            return state.merge({
                isLoadingEvaluation: action.isLoading
            })
        }
        case ratingActions.SET_EVALUATION_SUPPLIERS_PERCENTAGE: {
            return state.merge({
                isLoadingEvaluation: false,
                evaluationRating: fromJS(action.evaluation)
            })
        }
        case ratingActions.SET_LIST_RATINGS_PTM: {
            return state.merge({
                isLoadingRating: false,
                isSaving: false,
                ratingsPtm: fromJS(action.ratings)
            })
        }
        case ratingActions.SET_LIST_RATINGS_FERSA:{
            return state.merge({
                isLoadingRating: false,
                isSaving: false,
                ratingsFersa: fromJS(action.ratings)
            })
        }
        default:
            return state;
    }
}
