import { fromJS } from 'immutable'

import * as trainingActions from './TrainingActions'

export const trainingInitialState = fromJS({
	trainings: [],
	training: null,
	trainingCount: 0,
	isLoanding: false,
	isSavingTraining: false,
	isLoadingTraining: false,
	sortCriteria: {
		by: 'name',
		ascending: false,
	},
	page: 0,
	roesPerPage: 20,
	searchQuery: '',
})

export default function trainingsReducer(state = trainingInitialState, action) {
	switch (action.type) {
		case trainingActions.IS_LOADING_TRAININGS: {
			return state.merge({
				isLoanding: action.isLoadingTrainings,
			})
		}
		case trainingActions.SET_TRAINING_LIST: {
			return state.merge({
				trainings: fromJS(action.trainingsList),
				isLoanding: false,
			})
		}
		case trainingActions.IS_LOADING_TRAINING: {
			return state.merge({
				isLoadingTraining: action.isLoadingTraining,
			})
		}
		case trainingActions.SET_TRAINING: {
			return state.merge({
				training: fromJS(action.training),
				isLoanding: false,
			})
		}
		case trainingActions.SET_TRAINING_COUNT: {
			return state.merge({
				trainingCount: action.trainingCount,
				isLoanding: false,
			})
		}
		case trainingActions.IS_SAVING_TRAINING: {
			return state.merge({
				isSavingTraining: action.isSavingTraining,
			})
		}
		default:
			return state
	}
}
