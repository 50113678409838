import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'

/** Translations imports section */
/** Material-UI imports section */
import Avatar from '@material-ui/core/Avatar'
import SuppliersIcon from '@material-ui/icons/NoteSharp'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { Grid, TextField } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'

/**
 * Component to
 */
class FormNotesCard extends Component {
	/**
	 * Create an instance of Internal user
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	render() {
		const { classes, prospect, emailsValue } = this.props
		return (
			<Card className={classes.card} style={{ height: '79vh' }}>
				<CardHeader
					avatar={
						<Avatar className={classes.userAvatar}>
							<SuppliersIcon />
						</Avatar>
					}
					title={<Translate id='prospects.commentsForProspectus' />}
				/>
				<CardContent>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<Typography variant='caption' color={'textSecondary'}>
								<Translate id='prospects.contactEmail' />
							</Typography>
							{emailsValue.list.length > 0 &&
								emailsValue.list.map((file) => {
									return (
										<Chip
											className={classes.userAvatar}
											key={file}
											label={file}
											onDelete={() => {
												this.props.deleteEmails(file)
											}}
										/>
									)
								})}

							<TextField
								fullWidth
								id='filled-margin-dense'
								label={''}
								type='email'
								value={emailsValue.value}
								onChange={this.props.updateEmail}
								onKeyPress={this.props.updateEmail}
								margin='dense'
								variant='outlined'
								helperText={<Translate id='prospects.contactEmailNote' />}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption' color={'textSecondary'}>
								<Translate id='prospects.addComments' />
							</Typography>
							<TextField
								fullWidth
								id='outlined-multiline'
								label={''}
								multiline
								className={classes.textAreaField}
								rows='5'
								value={prospect.notes}
								onChange={(event) => {
									this.props.updateProperty('notes', event.target.value)
								}}
								variant='outlined'
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className={classes.actions} disableActionSpacing></CardActions>
			</Card>
		)
	}
}

export default FormNotesCard
