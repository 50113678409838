import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'

import { Avatar, Card, CardContent, CardHeader, Chip, Grid, IconButton, Typography, Tooltip } from '@material-ui/core'
import { School } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import AceptedIcon from '@material-ui/icons/ThumbUp'
import RejectedIcon from '@material-ui/icons/ThumbDown'

import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import EditTrainingDialog from './components/EditTrainingDialog'
import AddCommonItem from '../../common/AddCommonItem'

import { editEmployeeTraining, getTraining, validateTrainingInPlant } from '../../../store/contractors/TrainingActions'
import { getTrainigSelector } from '../../../store/contractors/TrainingSelector'
import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import { canCloseRegistersAccessControl } from '../../../store/helpers/RolesHelper'
import {
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_WARNING,
	COLOR_STATUS_TRAINING,
} from '../../../store/helpers/StatusColorConstants'
import { TRAINING_VALIDATED, TRAINING_REJECYED } from '../../../store/helpers/ResourcesHelper'
import { converListToArray } from '../../../store/helpers/ListHelper'
import { getIsSavingTraining } from '../../../store/contractors/TrainingSelector'

const TrainingShow = () => {
	const dispatch = useDispatch()
	const params = useParams()
	const userProfileLogged = useSelector((state) => state.oidc.user)
	const [openEditTrainingModal, onOpenEditTrainingModal] = useState(false)
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const isSavingTraining = useSelector((state) => getIsSavingTraining(state))
	const [openAceptedTraining, onOpenAcceptedTraining] = useState(false)
	const [openRejectedTraining, onOpenRejectedTtaining] = useState(false)

	const OnOpenModal = () => {
		onOpenEditTrainingModal(!openEditTrainingModal)
	}
	useEffect(() => {
		dispatch(getTraining(params.token))
	}, [dispatch])
	const training = useSelector((state) => getTrainigSelector(state))

	const editTraining = (startDate, endDate, name) => {
		dispatch(editEmployeeTraining(startDate, endDate, name, training.token))
	}

	const determineStatus = (status) => {
		let statusColor
		switch (status) {
			case 4:
				statusColor = COLOR_STATUS_SUCCESS
				break
			case 5:
				statusColor = COLOR_STATUS_DANGER
				break
			default:
				statusColor = COLOR_STATUS_WARNING
				break
		}
		return statusColor
	}

	const onAcceptedTraining = () => {
		return dispatch(validateTrainingInPlant(training.token, TRAINING_VALIDATED, '')).then(() => {
			onOpenAcceptedTraining(!openAceptedTraining)
		})
	}

	const onRejectedTraining = (comments) => {
		return dispatch(validateTrainingInPlant(training.token, TRAINING_REJECYED, comments)).then(() => {
			onOpenRejectedTtaining(!openRejectedTraining)
		})
	}

	const urlBasetraining = training ? training.training_url : ''
	const urlBase = `${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${urlBasetraining}`

	return (
		<Card style={{ height: '136vh' }}>
			<CardHeader
				avatar={
					<Avatar>
						<School />
					</Avatar>
				}
				title={
					<Typography variant='h6'>
						<Translate id='contractorsModule.medicalchecks.trainingsTitle' />
						{' de: '}
						{training && training.employed_name}{' '}
					</Typography>
				}
				action={
					<span>
						{IsCurrentNotActiveDirectoryUser(userProfileLogged) &&
							training &&
							(training.status === 1 || training.status === 0) && (
								<Tooltip title={<Translate id='common.edit' />}>
									<IconButton color='primary' onClick={() => OnOpenModal()}>
										<EditIcon />
									</IconButton>
								</Tooltip>
							)}
						{canCloseRegistersAccessControl(userRolesByCompany) &&
							training &&
							(training.status === 1 || training.status === 0) && (
								<span>
									<IconButton
										color='secondary'
										onClick={() => onOpenAcceptedTraining(!openAceptedTraining)}
									>
										<Tooltip title={<Translate id='common.accept' />}>
											<AceptedIcon style={{ color: COLOR_STATUS_SUCCESS }} />
										</Tooltip>
									</IconButton>
									<IconButton
										color='secondary'
										onClick={() => onOpenRejectedTtaining(!openRejectedTraining)}
									>
										<Tooltip title={<Translate id='common.reject' />}>
											<RejectedIcon style={{ color: COLOR_STATUS_DANGER }} />
										</Tooltip>
									</IconButton>
								</span>
							)}
					</span>
				}
				subheader={
					training ? (
						<Grid container spacing={12}>
							<Grid item xs={12}>
								{'Nombre: '}:{` ${training.name}`}
							</Grid>
							<Grid item xs={12}>
								{<Translate id='contractorsModule.validIn' />}:
								{` ${training.start_date} - ${training.end_date}`}
							</Grid>
							<Grid item xs={12}>
								{<Translate id='contractorsModule.medicalchecks.contractName' />}:
								{` ${training.supplier_name}`}
							</Grid>
							{training.status !== 1 && training.status !== 0 && (
								<Grid item xs={12}>
									<Typography variant='subtitle2'>
										<Translate id='contractorsModule.validatedBy' />:
										{` ${training.validate_by} - ${training.validation_date}`}
									</Typography>
									<Typography variant='subtitle2'>{training.validator_comments}</Typography>
								</Grid>
							)}
							{training.get_active === 2 ? (
								<Chip style={{ backgroundColor: COLOR_STATUS_WARNING }} label='Próximo a vencer' />
							) : training.get_active === 3 ? (
								<Chip style={{ backgroundColor: COLOR_STATUS_DANGER }} label='Vencido' />
							) : (
								<Chip style={{ backgroundColor: COLOR_STATUS_TRAINING }} label='Activo' />
							)}
							<Typography
								variant='body2'
								style={{ color: determineStatus(training.status), marginLeft: '10px' }}
							>
								{training.status_description}
							</Typography>
						</Grid>
					) : (
						''
					)
				}
			/>
			<CardContent style={{ height: '83%' }}>
				<div
					className='embed-responsive embed-responsive-16by9'
					style={{ overflow: 'hidden', height: '100%', width: '100%' }}
				>
					{/** this component not works in development 'cause x-frame-options is enabled in backend
					 * please comment this tag instead
					 */}
					<iframe id='training' title='Capacitación' className='embed-responsive-item' src={`${urlBase}`} />
				</div>
			</CardContent>
			{training && (
				<EditTrainingDialog
					open={openEditTrainingModal}
					onClose={OnOpenModal}
					editTraining={editTraining}
					training={training}
				/>
			)}

			<AddCommonItem
				title={'Aceptar capacitación en planta'}
				message={'Se valida la capacitación en planta del empleado y se notifica al contratista.'}
				open={openAceptedTraining}
				onClose={() => onOpenAcceptedTraining(!openAceptedTraining)}
				onSaveItem={onAcceptedTraining}
				isSaving={isSavingTraining}
				isMessage={true}
			/>

			<AddCommonItem
				title={'Rechazar capacitación en planta'}
				message={'Motivo por el cual se rechaza en planta.'}
				open={openRejectedTraining}
				onClose={() => onOpenRejectedTtaining(!openRejectedTraining)}
				onSaveItem={onRejectedTraining}
				isSaving={isSavingTraining}
				isTextArea={true}
			/>
		</Card>
	)
}
const styles = (theme) => ({})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(TrainingShow)))
