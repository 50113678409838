import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import TrazabilityIcon from '@material-ui/icons/CallSplitTwoTone';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import LoadingPage from '../common/LoadingPage';
import Toaster from '../common/Toaster';
import Memos from './components/Memos';
import NoticeChange from './components/NoticeChange';
import AlternativeProvedurias from './components/AlternativeProvedurias';
import RMIDocuments from './components/RMIDocuments';

import { getPartNumberInformationDataFromBackend } from '../../store/partNumbers/PartNumberAction';


/**
 * Component to create memo from SIT
 */
class PartNumberHistory extends Component {

    /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            partNumber: "",
            activeTab: 0,
        }
    }

    /**
     * Funcion on update folio to get partNumber
     * @param {*} folio 
     */
    onUpdatePartNumber(partNumber) {
        this.setState({
            partNumber: partNumber
        })
    }

    /**
     * on key Down to get folio
     * @param {*} event 
     */
    onKeyDown(event) {
        if (event.key === "Enter") {
            this.getPartNumber();
        }
    }


    /**
     * Get action to get information memo 
     */
    getPartNumber() {
        this.props.getPartNumberHistory(this.state.partNumber)
            .then(() => {
                this.setState({
                    showAddWorker: false,
                    showToView: true,
                    showToaster: false
                })
            }
            ).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToAdd" />,
                    toasterVariant: "error",
                })
            });
    }


    /**
    * Method to change the active tab
    */
    onClickToActiveTab = (event, value) => {
        this.setState({ activeTab: value });
    };


    /**
     * Get the card content
     *
     * @memberof Index
     */
    getCardContent() {
        if (this.props.isLoadingPartNumber === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.partNumber !== null) {
            let partNumber = this.props.partNumber;
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}><Typography variant="button">{partNumber.part_description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <AppBar position="static" color="default">
                            <Tabs variant="fullWidth" indicatorColor="secondary" textColor="secondary"
                                value={this.state.activeTab}
                                onChange={this.onClickToActiveTab}>
                                <Tab label={<Translate id="menu.memos" />} />
                                <Tab label={<Translate id="menu.noticeChange" />} />
                                <Tab label={<Translate id="menu.alternatingProveeduria" />} />
                                <Tab label={<Translate id="samplesrequest.reportSample" />} />
                            </Tabs>
                        </AppBar>
                        {this.state.activeTab === 0 && <Memos memos={partNumber.memos} {...this.props} />}
                        {this.state.activeTab === 1 && <NoticeChange noticeChanges={partNumber.notices} {...this.props} />}
                        {this.state.activeTab === 2 && <AlternativeProvedurias proveduries={partNumber.proveeduries}  {...this.props} />}
                        {this.state.activeTab === 3 && <RMIDocuments rmis={partNumber.rmis}  {...this.props} />}
                    </Grid>
                </Grid>
            )
        }
    }

    /**
     * Render component memo
     */
    render() {
        const { classes } = this.props;
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <TrazabilityIcon />
                        </Avatar>
                    }
                    title={<Typography variant="button">{<Translate id="alternatingProveeduria.trazability" />} </Typography>}
                />
                <CardContent>
                    <Grid container spacing={24}>
                        <List>
                            <ListItem>
                                <ListItemText primary={
                                    <React.Fragment>
                                        <TextField fullWidth autoFocus
                                            className={classes.textField}
                                            label={<Translate id="alternatingProveeduria.partNumber" />}
                                            onKeyDown={this.onKeyDown}
                                            onChange={(event) => { this.onUpdatePartNumber(event.target.value) }}
                                        /><br /><br />
                                    </React.Fragment>
                                }
                                    secondary={
                                        <React.Fragment>
                                            {<Translate id="alternatingProveeduria.partNumberHistory" />}
                                        </React.Fragment>
                                    } />
                            </ListItem>
                        </List>
                    </Grid>
                    {this.getCardContent()}
                </CardContent>

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        )
    }
}


/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let partNumber = state.partNumbers.get("partNumber");
    return {
        isLoadingPartNumber: state.partNumbers.get('isLoadingPartNumber'),
        partNumber: partNumber ? partNumber.toJS() : null,

    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        getPartNumberHistory: (partNumber) => {
            return dispatch(getPartNumberInformationDataFromBackend(partNumber));
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(PartNumberHistory))));