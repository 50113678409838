/**Import react section */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import uuidv4 from "uuid/v4";
import { Translate } from "react-localize-redux";
/**Import material UI Section*/
import {
  withStyles,
  Typography,
  Checkbox,
  Tooltip,
  IconButton,
  TableBody,
} from "@material-ui/core";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
/**Import components section */
/**import helper section */
import {
  RELEASE_PLAN_COLOR_YELLOW,
  COLOR_STATUS_GRAY,
  COLOR_STATUS_DANGER,
  BACKGROUND_COLOR_GRAY_DARK,
} from "../../../../store/helpers/StatusColorConstants";
import { REMOVE_EXPEDIENT_DOCUMENT } from "../../../../store/expedientSupplier/ExpedientSupplierAction";
import { onMappedDocumentsItems } from "../../../../store/helpers/ExpedientSupplier";
import { useExpedientSupplierCustomHookIndex } from "../../customHooks/UseCustomHookIndex";
import { BTN_DELETE } from "../../../../store/helpers/ExpedientSupplier";
const DocumentsContentTable = (props) => {
  const {
    onAddDocumentInClassification,
    onRemoveDocumentInClassification,
    isSaving,
  } = useExpedientSupplierCustomHookIndex();
  /**String props section */
  const { identifierPanelLateral } = props;
  /**Bools props section */
  //const { isSaving } = props
  /**Arrays props section */
  //const { } = props
  /**Functions props section */
  const {
    documents,
    supplierClassifications,
    mappedHeadersDocuments,
    onLateralPanelConfig,
    onConfirmPassword,
  } = props;
  const { classes } = props;
  const [identifierRow, setIdentifierRow] = useState("");

  const includeDocumentsReferences = (documentToken) => {
    let existClassification = supplierClassifications.some((x) =>
      x.Documents.some((d) => d.token === documentToken)
    );
    if (existClassification) {
      return true;
    } else {
      return false;
    }
  };

  const onGetDocument = (document, headerTitle) => {
    let existClassification = supplierClassifications.find(
      (x) => x.token === headerTitle.token
    );
    if (existClassification) {
      let existDocument = existClassification.Documents.find(
        (d) => d.token === document.token
      );
      if (existDocument !== undefined) {
        return existDocument;
      }
      return null;
    } else {
      return null;
    }
  };

  const onRowClick = (event, document) => {
    if (props.onLateralPanelConfig) {
      setIdentifierRow(document.token);
      onLateralPanelConfig("EditExpedientSupplier", document);
    }
  };

  const onDragEnd = (item) => {
    if (props.handleMoveDocumentToIndex) {
      props.handleMoveDocumentToIndex(
        item.draggableId,
        item ? item.destination.index : 1
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <TableBody style={{ padding: 0 }}>
        <TableRow style={{ padding: 0 }}>
          <TableCell
            colSpan={mappedHeadersDocuments.length}
            style={{ padding: 0 }}
          >
            <Droppable droppableId="characters">
              {(provided) => (
                <span
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ padding: 0 }}
                >
                  {onMappedDocumentsItems(documents).map((document, index) => {
                    let isVisibleBackgroundColorRow =
                      document.token === identifierRow &&
                      identifierPanelLateral;
                    let includesDocuments = document.attachments.length !== 0;
                    return (
                      <Draggable
                        key={document.token}
                        draggableId={document.token}
                        index={document.Index}
                      >
                        {(provided, snapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <TableRow
                              className={classes.cursorPointer}
                              style={{
                                backgroundColor: isVisibleBackgroundColorRow
                                  ? BACKGROUND_COLOR_GRAY_DARK
                                  : null,
                              }}
                            >
                              <TableCell
                                align="justify"
                                className={classes.number}
                              >
                                <Typography
                                  variant="caption"
                                  className={classes.index}
                                >
                                  {document.Index}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="justify"
                                className={classes.nomenclature}
                              >
                                <Typography variant="caption">
                                  {document.Nomenclature}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.cellName}
                              >
                                <Tooltip title={document.Name}>
                                  <Typography
                                    variant="caption"
                                    className={classes.texts}
                                  >
                                    {document.Name}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.cellNotes}
                              >
                                <Tooltip title={document.Comments}>
                                  <Typography
                                    variant="caption"
                                    className={classes.notes}
                                  >
                                    {document.Comments}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                              <TableCell
                                align="justify"
                                className={classes.cellFormat}
                              >
                                <Typography variant="caption">
                                  {document.FileType}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="justify"
                                className={classes.cellFiles}
                              >
                                <Tooltip
                                  title={
                                    includesDocuments ? (
                                      <Translate id="expedientSupplier.seeFiles" />
                                    ) : (
                                      <Translate id="expedientSupplier.addFiles" />
                                    )
                                  }
                                >
                                  <IconButton
                                    onClick={() =>
                                      onLateralPanelConfig(
                                        "IndexAddAttachments",
                                        document
                                      )
                                    }
                                  >
                                    <AttachFileIcon
                                      className={
                                        includesDocuments
                                          ? classes.containsFiles
                                          : classes.notcontainsFiles
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              {mappedHeadersDocuments.map(
                                (headerTitle, index) => {
                                  if (
                                    index > 5 &&
                                    headerTitle.title !== BTN_DELETE
                                  ) {
                                    return (
                                      <TableCell
                                        key={uuidv4()}
                                        align="center"
                                        className={classes.aditionalCell}
                                      >
                                        {onGetDocument(
                                          document,
                                          headerTitle
                                        ) ? (
                                          <Checkbox
                                            disabled={isSaving}
                                            checked={true}
                                            onClick={() =>
                                              onRemoveDocumentInClassification(
                                                headerTitle.token,
                                                onGetDocument(
                                                  document,
                                                  headerTitle
                                                ).token
                                              )
                                            }
                                          />
                                        ) : (
                                          <Checkbox
                                            disabled={isSaving}
                                            checked={false}
                                            onClick={() =>
                                              onAddDocumentInClassification(
                                                headerTitle.token,
                                                document.token
                                              )
                                            }
                                          />
                                        )}
                                      </TableCell>
                                    );
                                  } else {
                                    return null;
                                  }
                                }
                              )}
                              <TableCell
                                align="justify"
                                className={classes.aditionalCell}
                              >
                                <div style={{ display: "flex" }}>
                                  <Tooltip
                                    title={<Translate id="common.delete" />}
                                  >
                                    <div>
                                      <IconButton
                                        className={classes.removeIcon}
                                        disabled={
                                          isSaving ||
                                          includeDocumentsReferences(
                                            document.token
                                          )
                                        }
                                        onClick={() =>
                                          onConfirmPassword(
                                            document,
                                            REMOVE_EXPEDIENT_DOCUMENT
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    title={<Translate id="common.edit" />}
                                  >
                                    <IconButton
                                      onClick={(event) =>
                                        onRowClick(event, document)
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                            {/* </ListItem> */}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </span>
              )}
            </Droppable>
          </TableCell>
        </TableRow>
      </TableBody>
    </DragDropContext>
  );
};

const styles = (theme) => ({
  containsFiles: {
    color: RELEASE_PLAN_COLOR_YELLOW,
  },
  notcontainsFiles: {
    color: COLOR_STATUS_GRAY,
  },
  removeIcon: {
    color: COLOR_STATUS_DANGER,
    "&.Mui-disabled": {
      color: COLOR_STATUS_GRAY,
      opacity: 0.75,
    },
  },
  cursorPointer: {
    cursor: "pointer",
  },
  number: {
    maxWidth: "40px",
    position: "sticky",
    left: 0,
    zIndex: "30",
    backgroundColor: "white"
  },
  texts: {
    maxWidth: "190px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  notes: {
    maxWidth: "90px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  nomenclature: {
    maxWidth: "150px",
    minWidth: "150px",
    position: "sticky",
    left: '80px',
    zIndex: "30",
    backgroundColor: "white"
  },
  index: {
    width: "30px",
    maxWidth: "30px",
  },
  cellName: {
    minWidth: "200px",
    maxWidth: "200px",
  },
  cellNotes: {
    minWidth: "200px",
    maxWidth: "200px",
  },
  cellFormat: {
    minWidth: "60px",
    maxWidth: "60px",
  },
  cellFiles: {
    minWidth: "60px",
    maxWidth: "60px",
  },
  aditionalCell: {
    minWidth: "120px",
    maxWidth: "120px",
  },
});

DocumentsContentTable.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  isLoading: PropTypes.bool.isRequired,
};

DocumentsContentTable.defaultProps = {
  isLoading: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(DocumentsContentTable)
);
