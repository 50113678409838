/**Import react section */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'

/**Import MaterialUi section */
import {
    Grid, Button, Avatar, Typography, TextField, ListSubheader,
    ListItemSecondaryAction
} from '@material-ui/core'
import FileIcon from '@material-ui/icons/AttachFile';
import {
    ListItemAvatar, List, ListItemText,
    ListItem, IconButton
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/**Import components section */
import DatePicker from '../../../common/DateTimePicker/DatePicker';
import Evidence from '../../../common/Evidence'

/**Import store section */
import { converListToArray } from '../../../../store/helpers/ListHelper'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { canBeEditSupplier, canBeResponsible } from '../../../../store/helpers/IndirectQualificationsHelpers'
import { IndirectQualificationsSupplierStatus, ActionPlantStatus } from '../../../../store/helpers/AppConstants'
import { COLOR_STATUS_WARNING } from '../../../../store/helpers/StatusColorConstants'

/**custom hooks */
import { useIndirectQualificationCustomHookShow } from '../../customHooks/UseCustomHookShow'


const PanelActionPanel = (props) => {
    const { classes, translate, actionPlan, onCloseButtonClick, listActions
    } = props
    const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'))
    const userRolesByCompany = useSelector((state) => state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : [])
    let ratingId = props.match.params.token;
    let supplierId = props.match.params.supplierId;


    const [attachments, setAttachments] = useState(actionPlan.Attachments ? actionPlan.Attachments : [])
    const [itemModel, setItemModel] = useState(actionPlan);

    const {
        user, supplier,
        onDownloadFilesActionPlant,
        onRemoveFileInActionPlan,
        onUpdateActionPlanFromSupplier,
        onUpdateActionPlanFromResponsible,
        onAddEvidenceInActionPlan
    } = useIndirectQualificationCustomHookShow()

    let canUpdateSupplier = (canBeEditSupplier(supplier, userRolesByCompany, user))
    let canBeEditResponsible = (canBeResponsible(supplier, userRolesByCompany, user))
    let rating = (supplier ? (supplier.Rating ? supplier.Rating : null) : null)
    let isUserCreator = (rating && (user && (user.profile.username === rating.CreatedBy)))
    const canUpdateSupplierAction = canUpdateSupplier && (supplier.Status === IndirectQualificationsSupplierStatus.SEND_ACTION_PLANT_TO_SUPPLIER || supplier.Status === IndirectQualificationsSupplierStatus.ACTION_PLANT_RESPONSE ) && actionPlan.Status !== ActionPlantStatus.AUTHORIZER
    const canBeUpdateActtachmentsSupplierAction = canUpdateSupplier && (supplier.Status === IndirectQualificationsSupplierStatus.SEND_ACTION_PLANT_TO_SUPPLIER || supplier.Status === IndirectQualificationsSupplierStatus.ACTION_PLANT_RESPONSE ) && actionPlan.Status !== ActionPlantStatus.AUTHORIZER
    const canBeUpdateResponsible = (canBeEditResponsible && isUserCreator && actionPlan && (actionPlan.Status === ActionPlantStatus.OPEN || actionPlan.Status === ActionPlantStatus.PROCESS) && supplier.Status === IndirectQualificationsSupplierStatus.ACTION_PLANT_RESPONSE)
    /**Render hooks section */
    const [checAproved, setCheckAprove] = useState(actionPlan && !IsNullOrEmpty(actionPlan.ApprovalDate))

    /**
       * On upload file in maquila order in list name
       * @param {*} files 
       * @param {*} maquilaToken 
       * @param {*} listName 
       */
    const uploadFiles = (files) => {
        let filesList = attachments.concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
            return onAddEvidenceInActionPlan(ratingId, supplierId, itemModel.Id, files, listActions)
                .then((result) => {
                    setAttachments(filesList)
                    return Promise.resolve('Ok');

                }).catch(() => {
                    return Promise.reject();
                });
        }
        return Promise.resolve('Ok');
    }

    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
    */
    const removeFiles = (fileName) => {
        let newList = attachments.filter(files => {
            return files.name !== fileName
        })
        return onRemoveFileInActionPlan(ratingId, supplierId, itemModel.Id, fileName, listActions)
            .then(() => {
                setAttachments(newList)
                return Promise.resolve('Ok');
            }).catch(() => {
                return Promise.reject();
            });
    }

    /**On change properties */
    const onChangeDate = (property, data) => {
        if (property === "CommitmentDate" || property === "ComplianceDate" || property === "ApprovalDate")
            data = data ? moment(data).format("DD/MM/YYYY") : ""
        setItemModel({
            ...itemModel,
            [property]: data
        });
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setItemModel({
            ...itemModel,
            [name]: value
        });
    }

    /**
    * Update action plan
    * @param {*} property 
    * @param {*} value 
    * @param {*} actionPlanId 
    * @returns 
    */
    const onSaveItemUpdate = () => {
        if (canUpdateSupplierAction) {
            return onUpdateActionPlanFromSupplier(ratingId, supplierId, itemModel, listActions)
                .then(() => {
                    onCloseButtonClick()
                })
        }
        else {
            return onUpdateActionPlanFromResponsible(ratingId, supplierId, itemModel, checAproved, listActions)
                .then(() => {
                    onCloseButtonClick()
                })
        }
    }

    const getfileDownload = (fileName, extension) => {
        return onDownloadFilesActionPlant(itemModel.Id, fileName, extension)
    }

    const handleChange = (event) => {
        setCheckAprove(event.target.checked);
    };

    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (canUpdateSupplier) {
            if (IsNullOrEmpty(itemModel.CommentsSupplier))
                return false;
            if (IsNullOrEmpty(itemModel.CommitmentDate) || itemModel.CommitmentDate.length === 0)
                return false;
            else
                return true;
        }
        else if (canBeEditResponsible) {
            if (IsNullOrEmpty(itemModel.ComplianceDate) || itemModel.ComplianceDate.length === 0)
                return false;
            else
                return true;
        }
        else
            return false;
    }

    return (
        <div>
            <div className={classes.containerInformation}>
                <Grid container >
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={translate('common.commentsActionPlant')}
                            className={classes.textField}
                            onChange={onPropertyChange}
                            multiline
                            name='CommentsSupplier'
                            margin="normal"
                            variant="outlined"
                            value={itemModel ? itemModel.CommentsSupplier : ''}
                            rows="2"
                            disabled={isSaving || !canUpdateSupplierAction}
                        />
                    </Grid>
                    {
                        canUpdateSupplierAction && <Grid item xs={12}>
                            <Typography color='primary'>{translate('common.commitmentDateCloseDiscovery')}</Typography>
                            <div className={classes.marginTop}>
                                <DatePicker
                                    onChangeRange={(event) => onChangeDate('CommitmentDate', event)}
                                    startDate={itemModel.CommitmentDate ? moment(itemModel.CommitmentDate, 'DD/MM/YYYY') : ''}
                                    minDate={moment()}
                                />
                            </div>
                        </Grid>
                    }
                    {!canUpdateSupplierAction && <Grid item xs={12}>
                        <List className={classes.root}>
                            <ListItem divider>
                                <ListItemText style={{ marginLeft: "-14px" }} secondary={translate('common.commitmentDateCloseDiscovery')} />
                                <ListItemSecondaryAction>
                                    {itemModel.CommitmentDate && itemModel.CommitmentDate}
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Grid>}
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography color='primary' className={classes.textTitle}>{translate('common.evidencesActionPlant')}</Typography>
                        {
                            canBeUpdateActtachmentsSupplierAction &&
                            <div className={classes.alertPanel}>
                                <div className={classes.alert}>
                                    <Typography variant='caption' align='justify' className={classes.marginTop}>
                                        <InfoIcon style={{ color: COLOR_STATUS_WARNING }} />
                                        {translate('common.uploadFilesActionPlant')}
                                    </Typography>
                                </div>
                            </div>
                        }
                        {canBeUpdateActtachmentsSupplierAction && <div className={classes.marginTop}>
                            <Evidence
                                text={translate('common.selectfiles')}
                                uploadFiles={uploadFiles}
                                isDisabled={!canBeUpdateActtachmentsSupplierAction}
                                maxFiles={1}
                                files={[]}
                            />

                        </div>
                        }
                        {attachments.length === 0 && <Typography className={classes.textTitle}><Translate id="common.noFiles" /></Typography>}
                        <List>
                            {
                                attachments.map(((evidence, index) => {
                                    return (
                                        <ListItem key={index} divider>
                                            <ListItemAvatar>
                                                <Avatar className={classes.avatar} size="small">
                                                    <FileIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={<Typography color="primary" className={classes.fileName}>{evidence.name}</Typography>}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => getfileDownload(evidence.name, evidence.ext)}
                                            />
                                            {canBeUpdateActtachmentsSupplierAction && <ListItemSecondaryAction>
                                                <IconButton edge="end" onClick={() => removeFiles(evidence.name)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>}
                                        </ListItem>
                                    )
                                }))
                            }
                        </List>
                    </Grid>
                    {!canBeUpdateResponsible &&
                        <Grid item xs={12}>
                            <List className={classes.root}
                                subheader={
                                    <ListSubheader style={{ marginLeft: "-12px", marginTop:"20px" }} color='primary' component="div" id="nested-list-subheader">
                                        {"Información adicional del responsable"}
                                    </ListSubheader>
                                }>
                                <ListItem divider className={classes.marginTop}>
                                    <ListItemText style={{ marginLeft: "-12px" }} secondary={translate('common.acceptActionPlant')} />
                                    <div className={classes.marginTop}>
                                        <ListItemSecondaryAction>
                                            {itemModel.ComplianceDate && itemModel.ComplianceDate}
                                        </ListItemSecondaryAction>
                                    </div>
                                </ListItem>
                                <ListItem divider className={classes.marginTop}>
                                    <ListItemText style={{ marginLeft: "-12px" }} secondary="Fecha de aceptación (Cierre de hallazgos):" />
                                    <ListItemSecondaryAction>
                                        {itemModel.ApprovalDate && itemModel.ApprovalDate}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>}
                    {canBeUpdateResponsible && <Grid item xs={12} className={classes.marginTop}>
                        <Typography className={classes.textTitle}>{translate('common.acceptActionPlant')}</Typography>
                        <div className={classes.marginTop}>
                            <DatePicker
                                onChangeRange={(event) => onChangeDate('ComplianceDate', event)}
                                startDate={itemModel.ComplianceDate ? moment(itemModel.ComplianceDate, 'DD/MM/YYYY') : ''}
                                minDate={moment()}
                                opens={"left"}
                                drops={"up"}
                            />
                        </div>
                        <div className={classes.marginTop}>
                            <FormControlLabel
                                style={{ marginLeft: "-1px" }}
                                control={<Switch checked={checAproved}
                                    onChange={handleChange}
                                    name="checkedApproved"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />}
                                label="Aceptación de evidencias (Cierre de hallazgos)"
                                labelPlacement="start"
                                disabled={!IsNullOrEmpty(actionPlan.ApprovalDate)}
                            />

                        </div>
                    </Grid>}
                    {!canUpdateSupplierAction && <Grid item xs={12} >
                        <TextField
                            fullWidth
                            label={translate('common.commentsInPlant')}
                            className={classes.textField}
                            onChange={onPropertyChange}
                            multiline
                            name='CommentsPlant'
                            margin="normal"
                            variant="outlined"
                            value={itemModel ? itemModel.CommentsPlant : ''}
                            rows="2"
                            disabled={isSaving || !canBeUpdateResponsible}
                        />
                    </Grid>}
                </Grid>
            </div>

            {
                (canUpdateSupplierAction || canBeUpdateResponsible) && <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSaveItemUpdate}
                            className={classes.widthBtnSaved}
                            disabled={!canBeSaved() || isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>
            }
        </div >

    )

}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${150}px)`,
        minHeight: `calc(100vh - ${150}px)`,
        overflowY: "auto",
        overflowX: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    marginTop: {
        marginTop: "10px",
    },
    marginLeft: {
        marginLeft: "6px",
    },
    widthBtnSaved: {
        minWidth: "400px"
    },
    fileName: {
        maxWidth: "340px",
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }
});

PanelActionPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

PanelActionPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelActionPanel))