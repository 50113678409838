/**Import react section */
import React, { useState } from "react";
import { useSelector } from "react-redux";

import propTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { withRouter } from "react-router-dom";
/**Import MaterialUi section */
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/CloudDownload";
/**Import components section */
import Evidence from "../../../common/Evidence";
import SuppliersSelector from "../../../common/SupplierSelector";
/**Import store resources section */
import { IsNullOrEmpty } from "../../../../store/helpers/StringHelper";
import downloadTemplate from "../../../../resources/files/Formato_Cargar_Proveedores.xlsx";
/**Import actions section */
import { useExpedientSupplierCustomHookIndex } from "../../customHooks/UseCustomHookIndex";

const CreateExpedient = (props) => {
  const { onCreateExpedientSupplier, onValidateFileExpedientSupplier } =
    useExpedientSupplierCustomHookIndex();

  const { classes } = props;
  const isSaving = useSelector((state) =>
    state.expedientSuppliers.get("isSavingExpedientSupplier")
  );
  const isValidateExpedient = useSelector((state) =>
    state.expedientSuppliers.get("isValidateExpedient")
  );
  const [expedienteSupplierModel, setExpedientSupplierModel] = useState({
    name: "",
    importType: "TEMPLATE",
    supplier: null,
  });

  const [attachments, setAttachments] = useState([]);
  const [messageErrors, setMessageErrors] = useState(null);

  /**On change properties */
  const onPropertyChange = (event) => {
    let { name, value } = event.target;
    setExpedientSupplierModel({
      ...expedienteSupplierModel,
      [name]: value,
    });
  };

  const onSelectedChange = (name, option) => {
    setExpedientSupplierModel({
      ...expedienteSupplierModel,
      [name]: option,
    });
  };

  /**Verify if can be sabed data*/
  const canBeSaved = () => {
    if (
      expedienteSupplierModel &&
      expedienteSupplierModel.importType === "TEMPLATE"
    ) {
      if (IsNullOrEmpty(expedienteSupplierModel.name)) return false;
      if (attachments.length === 0) return false;
      if (attachments.length > 1) return false;
    } else {
      if (IsNullOrEmpty(expedienteSupplierModel.name)) return false;
      if (expedienteSupplierModel.supplier === null) return false;
    }
    return true;
  };

  /**
   * Remove files
   */
  const removeFiles = (fileName) => {
    let attachmentsFiltered = attachments.filter((file) => {
      return file.name !== fileName;
    });
    setAttachments(attachmentsFiltered);
    setMessageErrors(null);
    return Promise.resolve("Ok");
  };

  /**
   * Add file
   * @param {*} attachments
   */
  const uploadFiles = (files) => {
    let filesList = [...attachments];
    if (files.length > 0) {
      for (var index = 0; index < files.length; index++) {
        filesList.push(files[index]);
      }
    }
    setAttachments(filesList);
    onValidateFileExpedientSupplier(filesList).then((data) => {
      setMessageErrors(data);
      console.log("panel lateral data", data);
    });
    return Promise.resolve("Ok");
  };

  /**On change properties */
  const onChangeRadioButton = (event, value) => {
    let { name } = event.target;
    setExpedientSupplierModel({
      ...expedienteSupplierModel,
      [name]: value,
    });
  };

  let isCaptureManual =
    expedienteSupplierModel && expedienteSupplierModel.importType === "MANUAL";
  return (
    <div>
      <div className={classes.containerInformation}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="Gender"
                name="importType"
                className={classes.group}
                value={
                  expedienteSupplierModel
                    ? expedienteSupplierModel.importType
                    : ""
                }
                onChange={(event) =>
                  onChangeRadioButton(
                    event,
                    isCaptureManual ? "TEMPLATE" : "MANUAL"
                  )
                }
              >
                <FormControlLabel
                  onClick={(event) =>
                    onChangeRadioButton(
                      event,
                      isCaptureManual ? "TEMPLATE" : "MANUAL"
                    )
                  }
                  value="MANUAL"
                  control={<Radio />}
                  label={
                    isCaptureManual
                      ? "Importar proveedores por plantilla"
                      : "Agregar proveedor manual"
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {expedienteSupplierModel &&
            expedienteSupplierModel.importType === "TEMPLATE" && (
              <>
                <Grid item xs={12}>
                  <small className="text-muted">
                    <DownloadIcon
                      fontSize="small"
                      color="secondary"
                      style={{ marginBottom: "8px" }}
                    />
                    <a href={downloadTemplate}>
                      <Translate id="expedientSupplier.downloadTemplatedExpedient" />
                    </a>
                  </small>
                </Grid>
                <Grid item xs={12} className={classes.marginTop}>
                  <Typography variant="subtitle2" color={"textSecondary"}>
                    <Translate id={"expedientSupplier.uploadSupplierList"} />
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.marginTop}>
                  <Evidence
                    text={<Translate id="dropzone.dropzoneBase" />}
                    baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
                    removeFiles={removeFiles}
                    uploadFiles={uploadFiles}
                    files={attachments}
                    disabledLink
                  />
                </Grid>
                <Grid item xs={12} className={classes.marginTop}>
                  <TextField
                    fullWidth
                    label={<Translate id="expedientSupplier.nameExpedient" />}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    name="name"
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={3}
                    rowsMax={3}
                    disabled={isSaving}
                    value={
                      expedienteSupplierModel
                        ? expedienteSupplierModel.name
                        : ""
                    }
                  />
                </Grid>
                {messageErrors && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="primary">
                      <Translate
                        id={"expedientSupplier.parser.informationFount"}
                      />
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.marginTop}
                    >
                      <Translate
                        id={"expedientSupplier.parser.totalSuppliers"}
                      />{" "}
                      {messageErrors.Total}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="error"
                      className={classes.marginTop}
                    >
                      <Translate
                        id={"expedientSupplier.parser.totalSuppliersError"}
                      />{" "}
                      {messageErrors.TotalErrors}
                    </Typography>
                    {messageErrors.MessageError.length !== 0 && (
                      <Typography
                        variant="subtitle2"
                        className={classes.marginTop}
                      >
                        <Translate id={"expedientSupplier.parser.listError"} />
                      </Typography>
                    )}
                    {messageErrors.MessageError.map((x) => {
                      return (
                        <>
                          <Divider />
                          <Typography
                            variant="subtitle2"
                            color="error"
                            className={classes.marginTop}
                          >
                            <Translate
                              id={x.Message}
                              data={{ code: x.Value }}
                            />
                          </Typography>
                          <Divider />
                        </>
                      );
                    })}
                  </Grid>
                )}
              </>
            )}

          {expedienteSupplierModel &&
            expedienteSupplierModel.importType === "MANUAL" && (
              <>
                <Grid item xs={12}>
                  <Typography color="primary">
                    Selecciona el proveedor
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.marginTop}>
                  <SuppliersSelector
                    onSupplierSelected={(supplier) =>
                      onSelectedChange("supplier", supplier)
                    }
                    isDisabled={isSaving}
                  />
                </Grid>
                <Grid item xs={12} className={classes.marginTop}>
                  <TextField
                    fullWidth
                    label={<Translate id="expedientSupplier.nameExpedient" />}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    name="name"
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={3}
                    rowsMax={3}
                    value={
                      expedienteSupplierModel ? expedienteSupplierModel.name : 0
                    }
                  />
                </Grid>
              </>
            )}

          {isValidateExpedient && (
            <Grid item xs={12}>
			  <LinearProgress color="secondary" />
              <Typography color="primary" align="center">Validando información</Typography>
            </Grid>
          )}
        </Grid>
      </div>

      <Grid container>
        <Grid item xs={12} className={classes.containerBtn}>
          <Button
            data-cy="btn-save"
            type="button"
            color="primary"
            variant="outlined"
            onClick={() =>
              onCreateExpedientSupplier(
                expedienteSupplierModel,
                attachments,
                isCaptureManual
              )
            }
            className={classes.widthBtnSaved}
            disabled={!canBeSaved() || isSaving}
          >
            {<Translate id="common.save" />}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = (theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  containerInformation: {
    maxHeight: `calc(100vh - ${165}px)`,
    minHeight: `calc(100vh - ${165}px)`,
    overflowY: "auto",
    padding: "10px",
  },
  containerBtn: {
    display: "flex",
    justifyContent: "center",
  },
  inputProps: {
    height: 6,
  },
  marginTop: {
    marginTop: "10px",
  },
  marginLeft: {
    marginLeft: "6px",
  },
  widthBtnSaved: {
    minWidth: "400px",
  },
});

CreateExpedient.propTypes = {
  /**
   * callback executed add new user in the list
   */
  onSaveButtonClick: propTypes.func,
  /**
   * open and closedlateral panel for add an new user in customer
   */
  onCloseButtonClick: propTypes.func,
  /**
   * Determinate if is saving data
   */
  isSaving: propTypes.bool.isRequired,
};

CreateExpedient.defaultProps = {
  onSaveButtonClick: () =>
    console.warn("Callback [onSaveButtonClick] no defined"),
  onCloseButtonClick: () =>
    console.warn("Callback [onCloseButtonClick] no defined"),
  isSaving: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(CreateExpedient)
);
