/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux'
/**Import Material UI section */
import {
	Typography, 
} from '@material-ui/core';

const StepOneValidateData = (props) => {
	const { 
		classes, 
	} = props

	return (
		<div>
			<br />
			<div className={classes.padding}>
				<Typography variant="subtitle1" style={{ color: "#990033" }} align="center">
					<Translate id="biddingsModule.notExistBidding" />
				</Typography>
			</div>
		</div>

	)
}


StepOneValidateData.propTypes = {
	isValid: propTypes.bool.isRequired
}
StepOneValidateData.defaultProps = {
	isValid: false
}

/**
 * Styles definition
 */
const styles = theme => ({
	button: {
		margin: theme.spacing.unit,
	},
	flex: {
		display: "flex",
		justifyContent: "flex-end"
	},
	padding: {
		paddingLeft: "100px",
		paddingRight: "100px"
	}


});

export default withStyles(styles, { withTheme: true })(StepOneValidateData);
