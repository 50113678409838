/**Import react section */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Translate } from "react-localize-redux";
/**Import material UI Section*/
import { withStyles, Typography, Checkbox } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Tooltip } from "@material-ui/core";
/**Import components section */
import SupplierDeleteButton from './SupplierDeleteButton';
/**import helper section */
import {
	RELEASE_PLAN_COLOR_YELLOW,
	COLOR_STATUS_GRAY,
	COLOR_STATUS_DANGER
} from '../../../../store/helpers/StatusColorConstants';
import { onMappedDocumentsItems } from '../../../../store/helpers/ExpedientSupplier';
import { useExpedientSupplierCustomHookShow } from '../../customHooks/UseCustomHookShow';

const SupplierDocumentsContentTable = (props) => {
	/**String props section */
	/**Bools props section */
	/**Arrays props section */
	/**Functions props section */
	const { expedientSupplier, classes } = props;
	const { onAddOrRemoveDocumentInExpedientSupplier, onRemoveExpedientSupplier, isSaving } = useExpedientSupplierCustomHookShow()
	let canShow = props.canBeModule
	return (
		<>
			{onMappedDocumentsItems(expedientSupplier ? expedientSupplier.expedient_update_for_suppliers : []).map((supplier, index) => {
				return (
					<Fragment>
						<TableRow>
							<TableCell align="justify" className={classes.number}>
								<Typography variant="caption">{`${index + 1}`}</Typography>
							</TableCell>
							<TableCell align="left" className={classes.texts}>
								<Typography variant="caption">{`${supplier.full_supplier}`}</Typography>
							</TableCell>

								
								<TableCell align="justify">
								<Typography variant="caption">{`${supplier.supplier_classification}`}</Typography>
							</TableCell>
							{canShow === true && (
								<TableCell align="justify" className={classes.aditionalCell}>
								<div style={{ display: "flex" }}>
									<Tooltip title={<Translate id="common.delete" />}>
										<div>
											<SupplierDeleteButton
											expedientSupplier={expedientSupplier}
											supplier={supplier}
											onRemoveExpedientSupplier={onRemoveExpedientSupplier}
											classes={classes} />
										</div>
									</Tooltip>
								</div>
							</TableCell>
							)}
							{supplier.file_update_suppliers.map((file) => {
								return (
									<TableCell align="center">
										{canShow === true && ( 
											<Checkbox disabled={isSaving} onClick={() => onAddOrRemoveDocumentInExpedientSupplier(supplier.token, file)} checked={file.is_requiered} />
										)}
									</TableCell>
								);
							})} 
						</TableRow>
					</Fragment>
				);
			})}
		</>
	);
};

const styles = (theme) => ({
	containsFiles: {
		color: RELEASE_PLAN_COLOR_YELLOW
	},
	notcontainsFiles: {
		color: COLOR_STATUS_GRAY
	},
	number: {
		maxWidth: '40px',
		position: "sticky",
		left: 0,
		zIndex: "30",
		backgroundColor: "white"
	},
	texts: {
		maxWidth: '200px',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		position: "sticky",
		left: '80px',
		zIndex: "30",
		backgroundColor: "white"
	},
	removeIcon: {
		color: COLOR_STATUS_DANGER,
		'&.Mui-disabled': {
			color: COLOR_STATUS_GRAY,
			opacity: 0.75
		}
	}
});

SupplierDocumentsContentTable.propTypes = {
	/**
	 * Determinate if is loading information from back end
	 * and see view skeleton
	 */
	isLoading: PropTypes.bool.isRequired
};

SupplierDocumentsContentTable.defaultProps = {
	isLoading: false
};

export default withRouter(withStyles(styles, { withTheme: true })(SupplierDocumentsContentTable));
