/** react imports section */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

/** Material-UI imports section */
import { Card, CardContent } from '@material-ui/core'

/** components imports section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import LayoutHeaderGuidelineDetail from './components/LayoutHeaderDetail'
import GuidelineDetailContent from './components/GuidelineDetailContent'
import LoadingPage from '../../common/LoadingPage'

/** actions imports section */
import * as selector from '../../../store/specifications/specifications.Selector'
import { getGuidelinedetail } from '../../../store/specifications/specifications.Action'

const GuidelineDetail = (props) => {
	const dispatch = useDispatch()

	const isLoading = useSelector((state) => selector.getLoadingSelector(state))
	const data = useSelector((state) => selector.getGuidelineDataSelector(state))

	useEffect(() => {
		dispatch(getGuidelinedetail(props.match.params.token))
	}, [dispatch, props.match.params.token])

	if (isLoading === true) {
		return <LoadingPage />
	}

	return (
		<Card>
			<LayoutHeaderGuidelineDetail {...data} />
			<CardContent>
				<GuidelineDetailContent
					isLoading={isLoading}
					controlChanges={data.control_changes}
					activities={data.activity_sequences}
					authrizations={data.authorizations}
				/>
			</CardContent>
		</Card>
	)
}

export default withauthenticatedLayout(withRouter(GuidelineDetail))
