/**Import react section */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import uuidv4 from "uuid/v4";
import { Translate } from 'react-localize-redux'
/**Import material UI Section*/
import { withStyles, Typography, IconButton, Tooltip } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ContactsIcon from "@material-ui/icons/GroupAdd";
/**Import actions section */
import {
  getBiddingResultTotalPercentage,
  onGetHeaderResultGeneralTableV2,
  WINNER_RESULT_BUTTONS,
  RESULT_TOTAL,
  SUPPLIERS_FINAL_COMMENTS,
  getTotalWinners,
  canBeEditBiddingInStatusResolved,
  RESULT_TOTAL_WINNER_IN_ITEMS,
} from "../../../../store/helpers/BiddingHelper";
/**import helper section */
import { percengeFormatResultTable } from "../../../../store/helpers/AmountHelper";
import IsWinnerIcon from "../../../../resources/images/winners/isWinner.png";
import {
  COLOR_STATUS_SUCCESS,
  COLOR_STATUS_BLUE,
  COLOR_STATUS_DANGER,
} from "../../../../store/helpers/StatusColorConstants";
import ItemProposalResponseByProvider from "./ItemProposalResponseByProvider";

const ResultTableContentV2 = (props) => {
  /**String props section */
  const { title, supplierCodeIdentifier } = props;
  /**Bools props section */
  const { isSaving, isListItems, isExternalUser } = props;
  /**Arrays props section */
  const { items, userRolesByCompany, translate } = props;
  /**Functions props section */
  const {
    onConfirmPassword,
    onAddWinnerConfigPanel,
    onAddWinnerInItemFromBackend,
    onAddCommentsSupplierConfigPanel,
  } = props;
  /**Objects props section */
  const { classes, bidding, userProfile } = props;

  const [identifierIsSaving, setIdentifierIsSaving] = useState("");
  const [identifierItemIdIsSaving, setIdentifierItemIdIsSaving] = useState("");

  let totalSpamRow = onGetHeaderResultGeneralTableV2(
    bidding.CriteriasResponses[1].criterias[0].ItemResponses[0],
    bidding.Suppliers,
    bidding,
    supplierCodeIdentifier,
    !isExternalUser
  ).length;

  return (
    <>
      {title && (
        <TableRow key={uuidv4()} style={{ backgroundColor: "#88A9DA" }}>
          <TableCell
            colSpan={totalSpamRow}
            component="th"
            scope="row"
            align="center"
          >
            {
              <Typography variant="subtitle2" className={classes.titleCell}>
                {title}
              </Typography>
            }
          </TableCell>
        </TableRow>
      )}

      {items.map((row) => {
        let isVisibleCellData =
          row.group !== WINNER_RESULT_BUTTONS &&
          row.group !== RESULT_TOTAL &&
          row.group !== SUPPLIERS_FINAL_COMMENTS &&
          row.group !== RESULT_TOTAL_WINNER_IN_ITEMS;
        let headerStyleWinnerInItems =
          row.group === RESULT_TOTAL_WINNER_IN_ITEMS
            ? { backgroundColor: "#88A9DA" }
            : null;
        return (
          <TableRow key={uuidv4()}>
            {row.isGroup === true && (
              <TableCell
                rowSpan={row.totalItems}
                component="th"
                scope="row"
                style={headerStyleWinnerInItems}
              >
                <div className={classes.flex}>
                  <strong>
                    {row.group !== WINNER_RESULT_BUTTONS &&
                    row.group !== RESULT_TOTAL_WINNER_IN_ITEMS
                      ? row.group
                      : null}
                  </strong>
                  {isVisibleCellData && (
                    <>
                      <div className={classes.flexGrow} />
                      <Typography
                        color="primary"
                        className={classes.percentage}
                      >
                        {getBiddingResultTotalPercentage(items, row.group)}
                      </Typography>
                    </>
                  )}
                </div>
              </TableCell>
            )}
            {row.isCatergoryMaterial &&
            isListItems &&
            row.group !== RESULT_TOTAL &&
            row.group !== SUPPLIERS_FINAL_COMMENTS &&
            row.group !== RESULT_TOTAL_WINNER_IN_ITEMS ? (
              <TableCell align="center" className={classes.iconsWinner}>
                <div className={classes.flex}>
                  {canBeEditBiddingInStatusResolved(
                    bidding,
                    userRolesByCompany,
                    userProfile
                  ) && (
                    <Tooltip
                      onClick={() => onAddWinnerConfigPanel(row)}
                      title={<Translate id="biddingsModule.addWinners" />}
                    >
                      <IconButton className={classes.marginLeftIcon}>
                        <ContactsIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <div className={classes.winnerIconTotal}>
                    <img
                      alt="WinnerIcon"
                      className={classes.winnerSize}
                      src={IsWinnerIcon}
                    />
                  </div>
                  <div className={classes.winnerIconTotal}>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      style={{ marginLeft: "10px" }}
                    >
                      {`${getTotalWinners(row)} `}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      style={{ marginLeft: "5px" }}
                    >
                      {<Translate id="biddingsModule.winners" />}
                    </Typography>
                  </div>
                </div>
              </TableCell>
            ) : (
              row.isCatergoryMaterial && (
                <TableCell
                  key={uuidv4()}
                  align="center"
                  className={classes.iconsWinner}
                  style={headerStyleWinnerInItems}
                ></TableCell>
              )
            )}
            <TableCell
              key={uuidv4()}
              style={headerStyleWinnerInItems}
              align="justify"
              className={classes.criteriaDescription}
            >
              {isVisibleCellData ? row.nombreCriteria : null}
            </TableCell>
            <TableCell key={uuidv4()} style={headerStyleWinnerInItems}>
              {isVisibleCellData
                ? percengeFormatResultTable(row.weighing)
                : null}
            </TableCell>
            <TableCell
              key={uuidv4()}
              style={headerStyleWinnerInItems}
              align="center"
            >
              {isVisibleCellData ? row.quantity : null}
            </TableCell>
            <TableCell key={uuidv4()} style={headerStyleWinnerInItems}>
              {isVisibleCellData ? row.measurementUnit : null}
            </TableCell>
            <ItemProposalResponseByProvider
              translate={translate}
              isSaving={isSaving}
              row={row}
              bidding={bidding}
              headerStyleWinnerInItems={headerStyleWinnerInItems}
              onConfirmPassword={onConfirmPassword}
              onAddWinnerInItemFromBackend={onAddWinnerInItemFromBackend}
              userRolesByCompany={userRolesByCompany}
              userProfile={userProfile}
              isVisibleCellData={isVisibleCellData}
              isListItems={isListItems}
              onAddCommentsSupplierConfigPanel={
                onAddCommentsSupplierConfigPanel
              }
              isExternalUser={isExternalUser}
              identifierIsSaving={identifierIsSaving}
              setIdentifierIsSaving={setIdentifierIsSaving}
              identifierItemIdIsSaving={identifierItemIdIsSaving}
              setIdentifierItemIdIsSaving={setIdentifierItemIdIsSaving}
            />
          </TableRow>
        );
      })}
    </>
  );
};

const styles = (theme) => ({
  flex: {
    display: "flex",
  },
  wrapper: {
    margin: "3px",
    position: "relative",
  },
  percentage: {
    fontWeight: "bold",
    marginLeft: "5px",
  },
  containerServices: {
    maxHeight: `calc(100vh - ${390}px)`,
    minHeight: `calc(100vh - ${390}px)`,
    overflowY: "auto",
  },
  flexGrow: {
    flexGrow: 1,
  },
  inputProps: {
    //height: 8,
    fontSize: 12,
  },
  accordionSummary: {
    paddingRight: "0px !important",
    display: "flex",
    backgroundColor: "#f8f9fa",
  },
  accordionDetails: {
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  buttonsAcordionSummary: {
    display: "flex",
    justifyItems: "flex-end",
    marginRight: "20px",
  },
  btnAcceptWinner: {
    color: COLOR_STATUS_SUCCESS, //"white",
    borderColor: COLOR_STATUS_SUCCESS,
    marginRight: "5px",
  },
  noWrapText: {
    maxWidth: "100px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  btnAcceptNotWinner: {
    color: COLOR_STATUS_BLUE, //"white",
    borderColor: COLOR_STATUS_BLUE,
  },
  marginRightText: {
    marginRight: "10px",
    marginTop: "7px",
  },
  btnWinnerInExpanded: {
    color: COLOR_STATUS_SUCCESS, //"white",
    borderColor: COLOR_STATUS_SUCCESS,
    marginRight: "20px",
  },
  finishedComment: {
    color: COLOR_STATUS_SUCCESS, //"white",
  },
  winnerSize: {
    width: "20px",
    height: "auto",
  },
  iconsWinner: {
    maxWidth: "600px",
  },
  marginLeftIcon: {
    marginleft: "5px",
  },
  fabProgress: {
    color: COLOR_STATUS_SUCCESS,
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  fabWinner: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  titleCell: {
    color: "white",
  },
  winnerIconTotal: {
    display: "flex",
    alignItems: "center",
  },
  declinedMaterial: {
    color: COLOR_STATUS_DANGER,
  },
  criteriaDescription: {
    minWidth: "300px",
  },
});

ResultTableContentV2.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  isLoading: PropTypes.bool.isRequired,
};

ResultTableContentV2.defaultProps = {
  isLoading: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(ResultTableContentV2)
);
