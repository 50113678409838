import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'

/** Import component section */
import DataTable from '../../common/DataTable'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'

import Badge from '@material-ui/core/Badge'

import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import SettingsIcon from '@material-ui/icons/Settings'
import AttachIcon from '@material-ui/icons/AttachFile'

import LoadingPage from '../../common/LoadingPage'
import OptionsMenu from '../../common/OptionsMenu'
import FilesLinks from '../../common/FilesLinks'

class MemoDetails extends Component {
	/**
	 * Create an instance of Internal user
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showspecPlano: false,
			planeToChange: null,
			revisionToChange: null,
			selectedPlaneValue: null,
			selectedDetailPlaneToken: null,
			showDeletePlaneName: false,
			itemToken: '',
			selectedTokens: [],
		}
	}

	onDeleteDetailPlane(item) {
		this.setState({
			showDeletePlaneName: true,
			revisionToChange: item.revise,
			planeToChange: item.plot_name,
			selectedDetailPlaneToken: item.token,
		})
	}

	/**
	 * Handle state to set token of item to show options menu list
	 *
	 * @param {*} itemToken
	 * @memberof MemoDetails
	 */
	handleClickOptionsMenu(itemToken) {
		this.setState({
			itemToken,
		})
	}

	/**
	 * Handle state to show or hide menu options list
	 *
	 * @memberof MemoDetails
	 */
	handleCloseOptionsMenu() {
		if (!this.state.showMemoItemDialog || !this.state.showAttachmentsDialog) {
			this.setState((state) => ({
				itemToken: '',
			}))
		}
	}

	/**
	 * Method to render OptionsMenu
	 *
	 * @param {*} item
	 * @returns
	 * @memberof ActionsList
	 */
	renderOptionsMenu(item, isMassive) {
		var options = []
		options.push({
			itemClick: () => this.props.onMenuOptionItemClick('showMemoItemDialog', item, this.state.selectedTokens),
			tooltipTranslation: <Translate id='common.addInformation' />,
			menuItemIcon: <SettingsIcon color='secondary' />,
		})
		!isMassive &&
			options.push({
				itemClick: () => this.props.onMenuOptionItemClick('showAttachmentsDialog', item),
				tooltipTranslation: <Translate id='common.addAttachments' />,
				menuItemIcon: <AttachIcon color='secondary' />,
			})

		return (
			<OptionsMenu
				itemToken={item.token}
				handleClickOptions={this.handleClickOptionsMenu}
				open={this.state.itemToken === item.token}
				handleClickAway={this.handleCloseOptionsMenu}
				options={options}
				xsSize
			/>
		)
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		let kind = 1
		let formatype = 'Memos'

		if (dataSource === 'material') {
			return (
				<div>
					<List>
						<ListItem>
							<ListItemText
								primary={
									<React.Fragment>
										<Typography variant='caption'>
											{item.part_number}
											{' | '}
											{item.part_description}
											<br />
											{<Translate id='common.schema' />}
											{': '}
											{item.plano_number}
											{' | '}
											{<Translate id='officialNotices.revision' />}
											{': '}
											{item.review}
										</Typography>
									</React.Fragment>
								}
							/>
						</ListItem>
					</List>
				</div>
			)
		} else if (dataSource === 'plot_name') {
			return (
				<FilesLinks
					item={item}
					hasPlot
					companyToken={this.props.memo.company_token}
					companyCode={this.props.memo.company_identifier}
					kind={kind}
					formatype={formatype}
					downloadFile={this.props.downloadPlotFiles}
				/>
			)
		} else if (dataSource === 'options') {
			return this.state.selectedTokens.length <= 0 ? this.renderOptionsMenu(item) : ''
		} else if (dataSource === 'suppliers') {
			if (item.quotes && item.quotes.length) {
				if (item.quotes.length === 1) {
					return (
						<Typography noWrap variant='overline' gutterBottom>
							{
								<React.Fragment>
									{item.quotes[0].supplier_code} | {item.quotes[0].supplier_name}{' '}
								</React.Fragment>
							}
						</Typography>
					)
				} else {
					return (
						<Badge
							color='primary'
							badgeContent={`+${item.quotes.length - 1}`}
							className={classes.supplierBadge}
						>
							<Typography noWrap variant='overline' gutterBottom>
								{
									<React.Fragment>
										{item.quotes[0].supplier_code} | {item.quotes[0].supplier_name}{' '}
									</React.Fragment>
								}
							</Typography>
						</Badge>
					)
				}
			} else {
				return <span>{<Translate id='memos.withoutSuppliers' />}</span>
			}
		}
	}

	/**
	 * Handle change plane
	 */
	handleChange = (event) => {
		this.setState({ selectedPlaneValue: event.target.value })
	}

	onSelectedChecks(selectedTokens) {
		this.setState({ selectedTokens })
	}

	/**
	 * Function from open spec planos
	 */
	getSppectList() {
		const { classes } = this.props
		if (this.props.specPlanos) {
			return (
				<FormControl component='fieldset' className={classes.formControl}>
					<FormLabel component='legend'></FormLabel>
					<RadioGroup
						aria-label='Gender'
						name='gender1'
						className={classes.group}
						value={this.state.value}
						onChange={this.handleChange}
					>
						{this.props.specPlanos.map((spect) => (
							<FormControlLabel value={spect} control={<Radio />} label={spect} />
						))}
					</RadioGroup>
				</FormControl>
			)
		} else {
			return <LoadingPage />
		}
	}

	onDeleteConfirm() {
		this.props
			.deleteDetailPlaneName(this.state.selectedDetailPlaneToken, this.state.planeToChange)
			.then(() => {
				this.setState({
					showDeletePlaneName: false,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof MemoDetails
	 */
	render() {
		let showSelectChecks = true //TODO: add validation to show or hide checks
		return (
			<Paper style={{ marginBottom: '4rem' }}>
				<Grid container spacing={0} justify='flex-end' direction='row'>
					{showSelectChecks && this.state.selectedTokens.length > 0 && (
						<Grid container spacing={0} justify='flex-end' direction='row'>
							{this.renderOptionsMenu({ token: 'selection' }, true)}
						</Grid>
					)}
				</Grid>
				<DataTable
					title={<Translate id='memos.memos' />}
					data={this.props.memo.details}
					configuration={TableConfiguration(showSelectChecks)}
					onRenderCellItem={this.onRenderCellItem}
					onSelectedCheck={(selectedTokens) => {
						this.onSelectedChecks(selectedTokens)
					}}
					selectedTokens={this.state.selectedTokens}
				/>
			</Paper>
		)
	}
}

function TableConfiguration(showCheckSelect) {
	let columns = [
		{
			header: <Translate id='memos.material' />,
			dataSource: 'material',
		},
		{
			header: <Translate id='memos.assignedTo' />,
			dataSource: 'assigned_development_name',
		},
		{
			header: <Translate id='memos.requiredSamples' />,
			dataSource: 'sample_number',
		},
		{
			header: <Translate id='memos.plotNumber' />,
			dataSource: 'plot_name',
		},
		{
			header: <Translate id='common.comments' />,
			dataSource: 'comments',
		},
		{
			header: <Translate id='menu.suppliers' />,
			dataSource: 'suppliers',
		},
		{
			header: '',
			dataSource: 'options',
		},
	]
	if (showCheckSelect)
		columns.push({
			header: '',
			dataSource: 'selectCheck',
		})
	return { columns }
}
export default MemoDetails
