///Import react section
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import uuidv4 from 'uuid/v4'

/** Import section actions */
import { getColor } from '../../store/helpers/PlanningColorConstants'
import {
	BACKGROUND_COLOR_GRAY_DARK,
	COLOR_UNITS,
	COLOR_STATUS_YELLOW_LIGHT,
	BACKGROUND_COLOR_CALENDAR_BUTTON,
	COLOR_STATUS_GRAY,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_SUCCESS,
	COLOR_SUSTAINABILITY_ROSE,
	RELEASE_PLAN_COLOR_YELLOW,
	RELEASE_PLAN_COLOR_BLUE_GRAY,
} from '../../store/helpers/StatusColorConstants'

/** Material-UI imports section */
import { Button, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { Translate } from 'react-localize-redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import NoReleaseIcon from '@material-ui/icons/EventBusy'
import { Chip } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

/** Import component section */
import PanelDetailItem from './components/PanelDetailItem'

const ShowRelease = (props) => {
	const { items, release, classes, userRolesByCompany, partnership, setToaster, isOpenLateralMenu } = props
	const [itemPanelOptions, setItemPanelOptions] = useState({ opened: false })
	const [valuesItem, setIValues] = useState()

	const openSectionPanel = (item) => {
		setItemPanelOptions({ opened: true })
		setIValues(item)
	}

	const closeSectionPanel = () => {
		setItemPanelOptions({ opened: false })
	}

	if (items.length === 0) {
		return (
			<div container spacing={24} justify='center'>
				<Card>
					<CardHeader
						avatar={
							<Avatar>
								<NoReleaseIcon color='error' />
							</Avatar>
						}
						title={
							<Typography gutterBottom variant='h5' component='h2' align='justify'>
								<Translate id='releasePlan.notExistRelease' />
							</Typography>
						}
					/>
					<CardContent>
						<Typography variant='body2' color='textSecondary' component='p' align='justify'>
							<Translate id='releasePlan.notExistReleaseMessage' />
						</Typography>
					</CardContent>
				</Card>
			</div>
		)
	} else {
		let configuration = TableReleaseTableConfiguration(release.day)
		return (
			<div className={isOpenLateralMenu ? classes.container : classes.containerClosedLateralMenu}>
				<div className={classes.flexChip}>
					<div className={classes.flexDiv}>
						<FiberManualRecordIcon
							fontSize='small'
							className={classes.separationCircle}
							style={{ color: `${COLOR_STATUS_GRAY}` }}
						/>
						<Typography variant='caption' className={classes.separationTitle}>
							<Translate
								id='releasePlanningFersa.deliveredUnconfirmed'
								style={{ color: `${COLOR_STATUS_GRAY}` }}
							/>
						</Typography>
					</div>
					<div className={classes.flexDiv}>
						<FiberManualRecordIcon
							fontSize='small'
							className={classes.separationCircle}
							style={{ color: `${COLOR_UNITS}` }}
						/>
						<Typography
							variant='caption'
							className={classes.separationTitle}
							style={{ color: `${COLOR_UNITS}` }}
						>
							<Translate id='releasePlanningFersa.confirmed' />
						</Typography>
					</div>
					<div className={classes.flexDiv}>
						<FiberManualRecordIcon
							fontSize='small'
							className={classes.separationCircle}
							style={{ color: `${COLOR_STATUS_YELLOW_LIGHT}` }}
						/>
						<Typography
							variant='caption'
							className={classes.separationTitle}
							style={{ color: `${COLOR_STATUS_YELLOW_LIGHT}` }}
						>
							<Translate id='releasePlanningFersa.process' />
						</Typography>
					</div>
					<div className={classes.flexDiv}>
						<FiberManualRecordIcon
							fontSize='small'
							className={classes.separationCircle}
							style={{ color: `${BACKGROUND_COLOR_CALENDAR_BUTTON}` }}
						/>
						<Typography
							variant='caption'
							className={classes.separationTitle}
							style={{ color: `${BACKGROUND_COLOR_CALENDAR_BUTTON}` }}
						>
							<Translate id='releasePlanningFersa.Aggregate' />
						</Typography>
					</div>
					<div className={classes.flexDiv}>
						<FiberManualRecordIcon
							fontSize='small'
							className={classes.separationCircle}
							style={{ color: `${COLOR_SUSTAINABILITY_ROSE}` }}
						/>
						<Typography
							variant='caption'
							className={classes.separationTitle}
							style={{ color: `${COLOR_SUSTAINABILITY_ROSE}` }}
						>
							<Translate id='releasePlanningFersa.fosrtCare' />
						</Typography>
					</div>
					{release.months.length > 0 &&
						release.months.map((month) => {
							return (
								<div className={classes.separation} key={uuidv4()}>
									<Chip label={month.name} style={{ backgroundColor: getColor(month.month) }} />
								</div>
							)
						})}
				</div>
				<div>
					<Paper>
						<Table>
							{renderTableHead(configuration)}
							<TableBody>
								{items.length > 0 &&
									items.map((item) => {
										return (
											<TableRow hover key={uuidv4()}>
												{configuration.columns.map((column) => {
													let cellContent
													let maxPaddingCell = 2
													cellContent = item[column.dataSource]

													let rendersCell = ''
													if (column.dataSource === 'confirm') {
														rendersCell = (
															<TableCell
																key={uuidv4()}
																align={column.align ? column.align : 'center'}
																style={{
																	wordBreak: 'break-word',
																	paddingLeft: maxPaddingCell,
																	paddingRight: maxPaddingCell,
																	minWidth: column.minWidth,
																}}
															>
																<Button
																	size='small'
																	variant='outlined'
																	color='secondary'
																	onClick={() => openSectionPanel(item)}
																>
																	{item.status === 0 ? (
																		<Typography
																			variant='caption'
																			className={classes.button}
																		>
																			Pendiente{' '}
																		</Typography>
																	) : (
																		<Typography
																			variant='caption'
																			className={classes.buttonConfirm}
																		>
																			Confirmardo
																		</Typography>
																	)}
																</Button>
															</TableCell>
														)
													} else if (column.dataSource === 'quantities') {
														rendersCell = renderQuantityByDay(
															item,
															column.columnDayIndex,
															column.minWidth
														)
													} else {
														rendersCell = (
															<TableCell
																key={uuidv4()}
																align={column.align ? column.align : 'center'}
																style={{
																	wordBreak: 'break-word',
																	paddingLeft: maxPaddingCell,
																	paddingRight: maxPaddingCell,
																	minWidth: column.minWidth,
																}}
															>
																{cellContent}
															</TableCell>
														)
													}
													return rendersCell
												})}
											</TableRow>
										)
									})}
							</TableBody>
						</Table>
					</Paper>
				</div>
				{itemPanelOptions.opened && (
					<PanelDetailItem
						setToaster={setToaster}
						onClose={closeSectionPanel}
						item={valuesItem}
						configuration={configuration}
						release={release}
						userRolesByCompany={userRolesByCompany}
						partnership={partnership}
					/>
				)}
			</div>
		)
	}
}

const renderQuantityByDay = (item, index, columnMinWidth) => {
	const quantity = item.quantities[index]
	return (
		<TableCell
			key={uuidv4()}
			align={'center'}
			style={{
				wordBreak: 'keep-all',
				minWidth: columnMinWidth,
				paddingLeft: 2,
				paddingRight: 2,
				borderRight: `${BACKGROUND_COLOR_GRAY_DARK} solid 1px`,
			}}
		>
			{quantity.quantityValue === 0
				? ''
				: getQuantityToShow(
						quantity,
						item.quality_with_folio_the_order_active,
						quantity.statusOrder,
						item.status
				  )}
		</TableCell>
	)
}

const getQuantityToShow = (quantity, quantityWithFolioOrderActive, statusOrder, status) => {
	if (quantity.adjustedQuantity === '' && quantityWithFolioOrderActive.length === 0) {
		return renderDataWithTooltip(
			quantity.quantityValue,
			'releasePlan.error.quantityInTransit',
			RELEASE_PLAN_COLOR_YELLOW,
			quantityWithFolioOrderActive
		)
	} else if (quantity.quantityValue !== quantity.adjustedQuantity) {
		if (quantityWithFolioOrderActive.length !== 0) {
			return renderDataWithTooltip(
				quantity.adjustedQuantity,
				'releasePlan.error.foliosWithOrder',
				RELEASE_PLAN_COLOR_YELLOW,
				quantityWithFolioOrderActive
			)
		} else {
			if (quantity.IsAddedByUser) {
				return (
					quantity.adjustedQuantity, 'releasePlan.error.quantityAddedByPlanner', RELEASE_PLAN_COLOR_BLUE_GRAY
				)
			} else {
				if (statusOrder === 1 || status === 1) {
					return renderDataWithStatus(quantity.adjustedQuantity, COLOR_STATUS_SUCCESS)
				} else {
					return quantity.adjustedQuantity
				}
			}
		}
	} else {
		if (quantity.IsAddedByUser) {
			return renderDataWithTooltip(
				quantity.adjustedQuantity,
				'releasePlan.error.quantityAddedByPlanner',
				RELEASE_PLAN_COLOR_BLUE_GRAY
			)
		} else {
			return quantity.adjustedQuantity
		}
	}
}

const renderDataWithStatus = (value, color) => {
	return <Typography style={{ color }}>{value}</Typography>
}
const renderDataWithTooltip = (value, tooltipId, color, dataTooltip = '') => {
	return (
		<Tooltip
			title={
				<React.Fragment>
					<Translate id={tooltipId} />
					{dataTooltip}
				</React.Fragment>
			}
		>
			<Typography style={{ color }}>{value}</Typography>
		</Tooltip>
	)
}

const TableReleaseTableConfiguration = (days) => {
	let columns = [
		{
			header: 'Solicitud de pedido',
			dataSource: 'solpedNumber',
			minWidth: 60,
		},
		{
			header: 'Código',
			dataSource: 'material',
			minWidth: 60,
		},
		{
			header: 'Descripción',
			dataSource: 'materialDescription',
			minWidth: 220,
			align: 'left',
		},
		{
			header: 'UM',
			dataSource: 'unitMeasure',
			minWidth: 40,
		},
		{
			header: 'Orden de compra',
			dataSource: 'orderBuy',
			minWidth: 80,
		},
		{
			header: 'Posición',
			dataSource: 'position',
			minWidth: 40,
		},
		{
			header: 'Total',
			dataSource: 'total_quantity_material',
			minWidth: 40,
		},
		{
			header: 'Confirmar',
			dataSource: 'confirm',
			minWidth: 40,
		},
	]

	if (days) {
		days.forEach((day, index) => {
			columns.push({
				header: day.day,
				columnDayIndex: index,
				dataSource: 'quantities',
				month: day.month,
				minWidth: 40,
			})
		})
	}

	return { columns }
}

const renderTableHead = (configuration) => {
	return (
		<TableHead key={uuidv4()}>
			<TableRow key={uuidv4()}>
				{configuration.columns.map((column) => {
					let maxPaddingCell = 2
					return (
						<TableCell
							key={uuidv4()}
							align={column.align ? column.align : 'center'}
							style={{
								paddingLeft: maxPaddingCell,
								paddingRight: maxPaddingCell,
								minWidth: column.minWidth,
								backgroundColor: getColor(column.month),
								position: 'sticky',
								top: 0,
								zIndex: '30',
							}}
						>
							{column.header}
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)
}

ShowRelease.propTypes = {
	items: propTypes.array.isRequired,
	release: propTypes.object.isRequired,
}

ShowRelease.defaultProps = {
	items: [],
	release: {
		day: [],
		months: [],
	},
}

const styles = (theme) => ({
	flexChip: {
		display: 'flex',
		justifyContent: 'flex-end !important',
		marginRight: '20px',
	},
	// container: {
	// 	//maxHeight: '490px',
	// 	overflowY: 'auto',
	// 	marginLeft: '15px',
	// 	marginRight: '15px',
	// 	minHeight: `calc(100vh - ${230}px)`,
	// 	maxHeight: `calc(100vh - ${230}px)`,
	// },
	container: {
		//maxHeight: '490px',
		maxWidth: `calc(95vw - ${260}px)`,
		overflowY: 'auto',
		overflowX: 'auto',
		// marginLeft: '10px',
		// marginRight: '10px',
		minHeight: `calc(100vh - ${300}px)`,
		maxHeight: `calc(100vh - ${300}px)`,
	},
	containerClosedLateralMenu: {
		//maxHeight: '490px',
		maxWidth: `calc(95vw - ${90}px)`,
		overflowY: 'auto',
		overflowX: 'auto',
		// marginLeft: '10px',
		// marginRight: '10px',
		minHeight: `calc(100vh - ${300}px)`,
		maxHeight: `calc(100vh - ${300}px)`,
	},
	separation: {
		margin: '5px',
	},
	flexDiv: {
		display: 'flex',
	},
	separationTitle: {
		marginTop: '15px',
		marginRight: '10px',
	},
	separationCircle: {
		marginTop: '15px',
	},
	button: {
		color: `${COLOR_STATUS_DANGER}`,
	},
	buttonConfirm: {
		color: `${COLOR_STATUS_SUCCESS}`,
	},
	txtConfirm: {
		color: `${COLOR_STATUS_SUCCESS}`,
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(ShowRelease))
