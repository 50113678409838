import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Toaster from '../../common/Toaster'
import { Typography } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import WarningIcon from '@material-ui/icons/Warning'

/** Section resources and action */
import {
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
	BACKGROUND_COLOR_GRAY,
	COLOR_STATUS_WARNING,
} from '../../../store/helpers/StatusColorConstants'
import Avatar from '@material-ui/core/Avatar'
import SocialSecurityIcon from '@material-ui/icons/VerifiedUser'
import {
	loadSocialInsurancePaymentFromBackEnd,
	addEmployeeInPayment,
	removeEmployeeInPayment,
	updateDataSocialInsurancePayment,
} from '../../../store/contractors/SocialInsurancePaymentsAction'
import EmployeesInListCard from '../components/EmployeesInListCard'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import EditSocialInsurancePayment from './components/EditSocialInsurancePayment'
import { COMPANY_IMBERA } from '../../../store/helpers/ResourcesHelper'
import { showErrorToaster, showSuccessToaster, showWarningToaster } from '../../../store/helpers/ToasterHelpers'

class Show extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			activeTab: 0,
			socialInsurancePayment: {
				employees: [],
			},
			editPaymentDialog: false,
			editPayment: null,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let socialInsurancePaymentToken = this.props.match.params.token
		this.props.loadSocialInsurancePayment(socialInsurancePaymentToken)
	}

	/**
	 * Method to change the active tab
	 */
	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 * Update the employees list adding item
	 *
	 * @param {*} selectedEmployee
	 * @memberof Show
	 */
	updateEmployeesList(selectedEmployee) {
		let employeesInPayment = this.props.socialInsurancePayment.contractor_employeds
		if (
			selectedEmployee !== null &&
			selectedEmployee !== undefined &&
			!IsNullOrEmpty(selectedEmployee.image_employed)
		) {
			if (
				!employeesInPayment.find((employee) => {
					return employee.token === selectedEmployee.token
				})
			) {
				let paymentToken = this.props.match.params.token
				this.props
					.addEmployeeInPayment(selectedEmployee.token, paymentToken, this.props.selectedCompany.token)
					.then(() => {
						this.setState(showSuccessToaster())
						return Promise.resolve('Ok')
					})
					.catch((response) => {
						if (!IsNullOrEmpty(response.data) && response.data.Message) {
							this.setState(showWarningToaster(response.data.Message))
						} else {
							this.setState(showErrorToaster())
							return Promise.reject()
						}
					})
			} else {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.repeatedSelection' />,
					toasterVariant: 'info',
				})
			}
		} else {
			this.setState({
				showToaster: true,
				toasterMessage: <Translate id='contractorsModule.employeeWithoutPhoto' />,
				toasterVariant: 'info',
			})
		} 
	}

	/**
	 * Method to call action and remove employee from list
	 *
	 * @param {*} employeeToken
	 * @memberof Show
	 */
	removeEmployeeFromList(employeeToken) {
		let paymentToken = this.props.match.params.token
		this.props
			.removeEmployeeInPayment(employeeToken, paymentToken)
			.then(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.saveCorrectly' />,
					toasterVariant: 'success',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Open modal for edit employeed
	 */
	openEditPayment() {
		let payment = this.props.socialInsurancePayment
		this.setState({
			editPaymentDialog: true,
			editPayment: {
				token: payment.token,
				description: payment.payment_description,
			},
		})
	}

	/**
	 * Aciton to on cancel edit paymnet
	 */
	onCloseEditPayment() {
		this.setState({
			editPaymentDialog: false,
			editPayment: null,
		})
	}

	/**
	 * call to action to update data payment
	 *
	 * @memberof FormView
	 */
	updatePayment(editItem) {
		if (editItem !== null) {
			this.props
				.updateDataSocialInsurancePayment(editItem)
				.then(() => {
					this.setState({
						editPaymentDialog: false,
						editPayment: null,
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: <Translate id='common.saveCorrectly' />,
					})
				})
				.catch((result) => {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				})
		}
	}

	/**
	 * function to determine status description and color to show chip in header
	 *
	 * @param {*} status
	 * @returns
	 * @memberof Show
	 */
	determineStatus(status) {
		let statusDescription
		let statusColor
		switch (status) {
			case 1: //AddedPayment
				statusDescription = 'contractorsModule.status.addedPayment'
				statusColor = COLOR_STATUS_WARNING
				break
			case 2: //IsValidated
				statusDescription = 'contractorsModule.status.isValidated'
				statusColor = COLOR_STATUS_SUCCESS
				break
			case 3: //IsRejected
				statusDescription = 'contractorsModule.status.isRejected'
				statusColor = COLOR_STATUS_DANGER
				break
			case 4: //modifiedBySupplier
				statusDescription = 'contractorsModule.status.modifiedBySupplier'
				statusColor = COLOR_STATUS_WARNING
				break
			default:
				statusDescription = 'Undefined'
				statusColor = BACKGROUND_COLOR_GRAY
				break
		}
		return { label: <Translate id={statusDescription} />, color: statusColor }
	}

	renderPaymentData() {
		let { classes, socialInsurancePayment } = this.props
		let status = this.determineStatus(socialInsurancePayment.status)
		let relativeFilesPath = socialInsurancePayment.base_path_files
		let urlBase = `${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${relativeFilesPath}`
		return (
			<Card style={{ height: '136vh' }}>
				<CardHeader
					avatar={
						<Avatar>
							<SocialSecurityIcon />
						</Avatar>
					}
					action={
						<span>
							{' '}
							{socialInsurancePayment && socialInsurancePayment.is_active_payment && (
								<IconButton
									onClick={() => {
										this.openEditPayment()
									}}
								>
									<EditIcon />
								</IconButton>
							)}
						</span>
					}
					title={<Typography variant='h6'>{socialInsurancePayment.payment_description}</Typography>}
					subheader={
						<Grid container spacing={8}>
							<Grid item xs={12}>
								<Translate id='contractorsModule.paymentDateSUA' />:
								{` ${socialInsurancePayment.date_start} - ${socialInsurancePayment.date_end}`}
							</Grid>
							<Grid item xs={12}>
								{socialInsurancePayment.status !== 1 && (
									<React.Fragment>
										<Typography variant='subtitle2'>
											<Translate id='contractorsModule.validatedBy' />:
											{` ${socialInsurancePayment.validate_by} - ${socialInsurancePayment.validation_date}`}
										</Typography>
										<Typography variant='subtitle2'>
											{socialInsurancePayment.motive_rejected}
										</Typography>
									</React.Fragment>
								)}
								<Chip style={{ color: '#fff', backgroundColor: status.color }} label={status.label} />
								{socialInsurancePayment.status === 3 && (
									<React.Fragment>
										<div
											className='alert'
											style={{
												marginTop: '10px',
												color: '#fff',
												backgroundColor: COLOR_STATUS_DANGER,
											}}
										>
											<h6>
												<WarningIcon />{' '}
												<Translate id='contractorsModule.uploadtCorrectDocumentation' />
											</h6>
											<p>
												<Translate id='contractorsModule.updateCorrectInformationDocumentation' />
											</p>
										</div>
									</React.Fragment>
								)}
								{this.props.socialInsurancePayment.contractor_employeds.length === 0 && (
									<div
										className='alert'
										style={{
											marginTop: '10px',
											color: '#fff',
											backgroundColor: COLOR_STATUS_DANGER,
										}}
									>
										<h6 className='alert-heading'>
											{' '}
											<WarningIcon /> <Translate id='contractorsModule.titlePaymentNote' />
										</h6>
										<p>
											<Translate id='contractorsModule.notePaymets' />
										</p>
									</div>
								)}
							</Grid>
						</Grid>
					}
				/>
				<CardContent style={{ height: '83%' }}>
					<Divider />
					<AppBar position='static' color='default'>
						<Tabs
							indicatorColor='secondary'
							textColor='secondary'
							className={classes.styleTab}
							value={this.state.activeTab}
							onChange={this.onClickToActiveTab}
							variant='fullWidth'
							style={{ marginBottom: '.3rem' }}
						>
							<Tab label={<Translate id='contractorsModule.fileSUA' />} />
							<Tab label={<Translate id='contractorsModule.depositReceipt' />} />
						</Tabs>
					</AppBar>
					{this.state.activeTab === 0 && (
						<div
							className='embed-responsive embed-responsive-16by9'
							style={{ overflow: 'hidden', height: '100%', width: '100%' }}
						>
							<iframe
								title='fileSUA'
								className='embed-responsive-item'
								src={`${urlBase}/${socialInsurancePayment.file_sua}`}
							/>
						</div>
					)}
					{this.state.activeTab === 1 && (
						<div
							className='embed-responsive embed-responsive-16by9'
							style={{ overflow: 'hidden', height: '100%', width: '100%' }}
						>
							<iframe
								title='depositReceipt'
								className='embed-responsive-item'
								src={`${urlBase}/${socialInsurancePayment.file_proof_payment}`}
							/>
						</div>
					)}
				</CardContent>

				{this.state.editPaymentDialog && (
					<EditSocialInsurancePayment
						payment={this.state.editPayment}
						open={this.state.editPaymentDialog}
						isSaving={this.props.isSavingSocialInsurancePayment}
						updatePayment={this.updatePayment}
						onClose={this.onCloseEditPayment}
					/>
				)}
			</Card>
		)
	}

	render() {
		return (
			<Grid container spacing={24}>
				<Grid item xs={8}>
					{this.props.socialInsurancePayment && this.renderPaymentData()}
				</Grid>
				<Grid item xs={4}>
					{this.props.socialInsurancePayment && (
						<EmployeesInListCard
							employees={this.props.socialInsurancePayment.contractor_employeds}
							title={'contractorsModule.employeesInPayment'}
							updateEmployeesList={this.updateEmployeesList}
							removeEmployee={this.removeEmployeeFromList}
							isSaving={this.props.isSavingSocialInsurancePayment}
							{...this.props}
							isActive={
								(this.props.socialInsurancePayment &&
									this.props.socialInsurancePayment.is_active_payment) ||
								this.props.selectedCompany.company_code === COMPANY_IMBERA
							}
						/>
					)}
				</Grid>
				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Grid>
		)
	}
}

const styles = (theme) => ({
	logoCompany: {
		maxHeight: 100,
	},
	styleTab: {
		backgroundColor: '#fff',
	},
})

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingSocialInsurancePayment: state.contractorSocialInsurancePayments.get('isLoadingSocialInsurancePayment'),
		isSavingSocialInsurancePayment: state.contractorSocialInsurancePayments.get('isSavingSocialInsurancePayment'),
		socialInsurancePayment: state.contractorSocialInsurancePayments.get('socialInsurancePayment')
			? state.contractorSocialInsurancePayments.get('socialInsurancePayment').toJS()
			: null,
		employeesInPayment: state.contractorSocialInsurancePayments.get('employeesInPayment')
			? state.contractorSocialInsurancePayments.get('employeesInPayment').toJS()
			: null,
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSocialInsurancePayment: (token) => {
			dispatch(loadSocialInsurancePaymentFromBackEnd(token))
		},
		addEmployeeInPayment: (employeeToken, paymentToken, selectedCompanyToken) => {
			return dispatch(addEmployeeInPayment(employeeToken, paymentToken, selectedCompanyToken))
		},
		removeEmployeeInPayment: (employeeToken, paymentToken) => {
			return dispatch(removeEmployeeInPayment(employeeToken, paymentToken))
		},
		updateDataSocialInsurancePayment: (socialInsurancePayment) => {
			return dispatch(updateDataSocialInsurancePayment(socialInsurancePayment))
		},
	}
}

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
