import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import moment from 'moment'

/** Import component section */
import LoadingPage from '../../common/LoadingPage'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import DatePicker from '../../common/DateTimePicker/DatePicker'
import DataTable from '../../common/DataTable'

/** Material UI imports section */
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import WarehouseEntriesIcon from '@material-ui/icons/LocalShippingTwoTone'

/** actions and helpers imports section */
import { getStocksFromSAP, loadSupplier, donwlodStocksFromSAP } from '../../../store/suppliers/SupplierActions'

/**
 * Stocks list
 *
 * @class Stocks list
 * @extends {Component}
 */
class Stocks extends Component {
	/**
	 * Create an instance of suppliers list
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			startDate: moment(),
			endDate: moment(),
			sortCriteria: {
				by: 'status',
				ascending: false,
			},
			isSearching: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let supplierToken = this.props.match.params.token
		this.props.loadSupplier(supplierToken)
	}

	/**
	 * On change range
	 *
	 * @param {*} startDate
	 * @param {*} endDate
	 * @memberof Stocks
	 */
	onChangeRange(startDate, endDate) {
		this.setState({
			startDate,
			endDate,
		})
	}

	onChangeRangeStart(startDate) {
		this.setState({
			startDate,
		})
	}

	onChangeRangeEnd(endDate) {
		this.setState({
			endDate,
		})
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		let sortCriteria = {
			by: sortBy,
			ascending: sortAscending,
		}
		this.setState({
			sortCriteria,
		})
	}

	/**
	 * On search stocks click
	 *
	 * @memberof Stocks
	 */
	onSearchClick() {
		if (this.props.supplier) {
			let supplierCode = this.props.supplier.code
			let startDate = this.state.startDate.format('DD/MM/YYYY')
			let endDate = this.state.endDate.format('DD/MM/YYYY')
			this.props.getStocks(supplierCode, startDate, endDate)
		}
	}

	/**
	 * On donwload click
	 */
	onDonwlodClick() {
		if (this.props.supplier) {
			let supplierCode = this.props.supplier.code
			let startDate = this.state.startDate.format('DD/MM/YYYY')
			let endDate = this.state.endDate.format('DD/MM/YYYY')
			this.props.donwlodStocks(supplierCode, startDate, endDate)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'cost') {
			return `${item.cost} ${item.currency}`
		}
	}

	/**
	 * Get card content
	 *
	 * @returns
	 * @memberof Stocks
	 */
	getCardContent() {
		const { sortCriteria } = this.state
		if (this.props.stocks) {
			return (
				<DataTable
					data={this.props.stocks}
					configuration={TableConfiguration}
					onRenderCellItem={this.onRenderCellItem}
					mode={'clientSide'}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					showFilters
					widthFilters={150}
				/>
			)
		} else if (this.props.isLoadingStocks) {
			return <LoadingPage />
		} else {
			return (
				<div className='alert alert-info' role='alert'>
					<h6 className='alert-heading'>
						<i className='fab fa-searchengin'></i>
						<Translate id='queries.stocks.searchStocksTitle' />
					</h6>
					<p>
						<Translate id='queries.stocks.searchStocksMessage' />{' '}
					</p>
				</div>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof Supplier
	 */
	render() {
		const { classes } = this.props
		console.log('this.props.stocks', this.props.stocks)
		return (
			<Card className={classes.card}>
				<CardHeader
					avatar={
						<Avatar color='primary'>
							<WarehouseEntriesIcon />
						</Avatar>
					}
					title={
						<span>
							<Translate id='menu.consults' /> | <Translate id='queries.stocks.stocks' />
						</span>
					}
					subheader={
						<span>
							{this.props.supplier ? this.props.supplier.full_name : ''}
							<div className={classes.toolbar}>
								{/* <DateTimePicker
                                onChangeRange={this.onChangeRange}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                            />  */}
								<DatePicker onChangeRange={this.onChangeRangeStart} startDate={this.state.startDate} />

								<DatePicker onChangeRange={this.onChangeRangeEnd} startDate={this.state.endDate} />
								<Button
									variant='contained'
									color='default'
									disabled={this.props.isLoadingStocks}
									style={{ marginLeft: '3px', fontSize: 'smaller' }}
									onClick={this.onSearchClick}
								>
									<Translate id='common.search' />
								</Button>
								<Button
									variant='contained'
									color='default'
									disabled={this.props.isDownloadQueries}
									style={{ marginLeft: '3px', fontSize: 'smaller' }}
									onClick={this.onDonwlodClick}
								>
									{' '}
									<Translate id='common.download' />
								</Button>
							</div>
						</span>
					}
				/>
				<CardContent>{this.getCardContent()}</CardContent>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='queries.stocks.center' />,
			dataSource: 'center_company',
			isSortable: true,
		},
		{
			header: <Translate id='queries.stocks.purchaseOrder' />,
			dataSource: 'order',
			isSortable: true,
		},
		{
			header: <Translate id='queries.stocks.line' />,
			dataSource: 'line',
			isSortable: true,
		},
		{
			header: <Translate id='queries.stocks.item' />,
			dataSource: 'item',
			isSortable: true,
		},
		{
			header: <Translate id='queries.stocks.description' />,
			dataSource: 'description',
			isSortable: true,
		},
		{
			header: <Translate id='queries.stocks.invoiceNumber' />,
			dataSource: 'invoice_number',
			isSortable: true,
		},
		{
			header: <Translate id='queries.stocks.numberEntry' />,
			dataSource: 'reference_sap',
		},
		{
			header: <Translate id='queries.stocks.referenceDocument' />,
			dataSource: 'reference_document',
		},
		{
			header: <Translate id='queries.stocks.unitPrice' />,
			dataSource: 'cost',
		},
		{
			header: <Translate id='queries.stocks.receivedAmount' />,
			dataSource: 'reception_items',
		},
		{
			header: <Translate id='queries.stocks.unitMeasure' />,
			dataSource: 'unit_measure',
		},
		{
			header: <Translate id='queries.stocks.receptionDate' />,
			dataSource: 'receipt_date',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		isLoadingStocks: state.suppliers.get('isLoadingStocks'),
		stocks: state.suppliers.get('stocks') ? state.suppliers.get('stocks').toJS() : null,
		isDownloadQueries: state.suppliers.get('isDownloadQueries'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSupplier: (token) => {
			dispatch(loadSupplier(token))
		},
		getStocks: (supplierCode, startDate, endDate) => {
			dispatch(getStocksFromSAP(supplierCode, startDate, endDate))
		},
		donwlodStocks: (supplierCode, startDate, endDate) => {
			dispatch(donwlodStocksFromSAP(supplierCode, startDate, endDate))
		},
	}
}

const styles = (theme) => ({
	toolbar: {
		display: 'flex',
	},
	documentTypeSelect: {
		width: '150px',
		marginRight: '5px',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Stocks)))
)
