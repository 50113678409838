import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
/** Material UI impoerts sections */
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import { Typography, CardHeader, CardContent, IconButton, Avatar, Grid } from '@material-ui/core'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import BarChart from '../common/BarChart'
import DataTable from '../common/DataTable'

/** Actions and helpers imports section */
import LoadingPage from '../common/LoadingPage'
import { loadSupplier } from '../../store/suppliers/SupplierActions'
import { loadRatingsIndirectsBySupplierFromBackEnd } from '../../store/indirectqualifications/IndirectqualificationsActions'
import { genereteStringGuid } from '../../store/helpers/StringHelper'
import moment from 'moment'
import { YEAR_MONTHS } from '../../store/helpers/ResourcesHelper'

class RatingsView extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			ratingsChart: [],
			average: 0,
			filteredRatings: [],
			showModalDownloadReport: false,
			initialDate: {
				selectedMonth: moment().month(),
				selectedYear: moment().year(),
			},
			finalDate: {
				selectedMonth: moment().month(),
				selectedYear: moment().year(),
			},
		}
	}

	componentWillMount() {
		this.setState({ ratingsChart: [] })
	}

	convertRatingChartData() {
		let dataSet = []
		let labelsSections = YEAR_MONTHS
		let years = [...new Set(this.props.ratings.map((rating) => rating.rating_year))]
		years.forEach((year) => {
			let ratingsValues = []
			let ratingsColors = []
			labelsSections.forEach((month, index) => {
				this.props.ratings.forEach((rating) => {
					if (year === rating.rating_year) {
						let monthIndex = moment().month(rating.rating_date).format('M')
						if (Number(monthIndex) === index + 1) {
							ratingsValues[index] = rating.final_score
							ratingsColors[index] = rating.color_final_score
						}
					}
				})
			})
			dataSet.push({
				data: ratingsValues,
				labelGroup: year,
				labelColor: '#ff0000',
				backgroundColors: ratingsColors,
				hiddenLegend: year !== moment().format('YYYY'),
			})
		})
		return dataSet
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			let supplierToken = this.props.match.params.token
			this.props.loadSupplier(supplierToken).then(() => {
				if (this.props.supplier)
					this.props.loadRatings(this.props.supplier.code).then(() => {
						this.setState(
							{
								ratingsChart: this.convertRatingChartData(),
							},
							() => {
								let availableYears = [
									...new Set(this.props.ratings.map((rating) => Number(rating.rating_year))),
								]
								let maxYear = Math.max(...availableYears)
								let selectedYears = [String(maxYear)]
								this.filterRatings(selectedYears)
							}
						)
					})
			})
		}
	}

	onRenderRatingsCellItem(dataSource, item) {
		if (dataSource === 'supplier_name') {
			return `${item['supplier_number']} | ${item[dataSource]}`
		}
		if (dataSource === 'final_score') {
			return <Typography style={{ color: item.color_final_score }}>{item[dataSource]} % </Typography>
		}
	}

	calculateAverage() {
		if (this.state.filteredRatings.length > 0) {
			let scores = this.state.filteredRatings.map((x) => x.final_score)
			let avg = scores.reduce((a, b) => a + b, 0) / scores.length
			this.setState({ average: avg.toFixed(2) })
		}
	}

	filterRatings(selectedYears) {
		this.setState(
			{
				filteredRatings: this.props.ratings.filter(
					(x) => selectedYears.find((year) => year === x.rating_year) !== undefined
				),
			},
			() => this.calculateAverage()
		)
	}

	handlerLegendClick(legendItems) {
		let selectedYears = legendItems.filter((x) => !x.hidden).map((x) => x.text)
		this.filterRatings(selectedYears)
	}

	render() {
		const { classes } = this.props
		if (this.props.isLoadingSupplier === true || this.props.isLoadingRating === true) {
			return <LoadingPage />
		}
		if (this.props.supplier === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let supplier = this.props.supplier
			let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${supplier.logo}`
			let logoUrl = urlImage + '?token=' + genereteStringGuid()

			let logoAvatar = <IconButton className={classes.avatar}>P </IconButton>
			if (supplier.logo) {
				logoAvatar = <Avatar alt='Remy Sharp' src={logoUrl} className={classes.avatar} />
			}
			return (
				<Paper className={this.props.classes.root}>
					<CardHeader avatar={logoAvatar} title={supplier.full_name} subheader={supplier.rfc} />
					<CardContent>
						{this.state.ratingsChart.length > 0 && (
							<BarChart dataSets={this.state.ratingsChart} handlerLegendClick={this.handlerLegendClick} />
						)}
						<Card className={this.props.classes.paper}>
							<DataTable
								alignCellContent='center'
								data={this.state.filteredRatings}
								configuration={TableratingsTableConfiguration(this.props.translate)}
								onRenderCellItem={this.onRenderRatingsCellItem}
								collapsableChild
							/>
							<Grid container justify='flex-end' alignItems='flex-end'>
								<Grid item xs={2}>
									<strong>
										<Translate id='rating.average' />:
									</strong>
								</Grid>
								<Grid item xs={1}>
									<Typography align='center'>{this.state.average}</Typography>
								</Grid>
							</Grid>
						</Card>
					</CardContent>
				</Paper>
			)
		}
	}
}

function TableratingsTableConfiguration() {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='rating.ratingsTable.month' />,
			dataSource: 'rating_date',
		},
		{
			header: <Translate id='rating.ratingsTable.service' />,
			dataSource: 'service',
		},
		{
			header: <Translate id='indirectqualifications.sectionType.product' />,
			dataSource: 'product',
		},
		{
			header: <Translate id='indirectqualifications.sectionType.extra' />,
			dataSource: 'extra',
		},
		{
			header: <Translate id='indirectqualifications.sectionType.flexibility' />,
			dataSource: 'flexibility',
		},
		{
			header: <Translate id='indirectqualifications.sectionType.availability' />,
			dataSource: 'availability',
		},
		{
			header: <Translate id='indirectqualifications.sectionType.comunication' />,
			dataSource: 'effectiveCommunication',
		},
		{
			header: <Translate id='indirectqualifications.sectionType.processes' />,
			dataSource: 'complianceProcesses',
		},
		{
			header: <Translate id='indirectqualifications.sectionType.incidencts' />,
			dataSource: 'incidents',
		},
		{
			header: <Translate id='indirectqualifications.sectionType.units' />,
			dataSource: 'units',
		},
		{
			header: 'Puntos requeridos',
			dataSource: 'total_points',
		},
		{
			header: <Translate id='rating.ratingsTable.finalScore' />,
			dataSource: 'final_score',
		},
	]
	return { columns }
}

const styles = (theme) => ({
	paper: {
		margin: `${theme.spacing.unit}px auto`,
		padding: theme.spacing.unit * 2,
	},
	root: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: `0 ${theme.spacing.unit * 3}px`,
	},
})

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingSupplier: state.suppliers.get('isLoadingSupplier'),
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		isLoadingRating: state.indirectQualifications.get('isLoadingGraph'),
		ratings: state.indirectQualifications.get('ratingsGraph')
			? state.indirectQualifications.get('ratingsGraph').toJS()
			: null,
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		loadRatings: (supplierCode) => {
			return dispatch(loadRatingsIndirectsBySupplierFromBackEnd(supplierCode))
		},
	}
}

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RatingsView)))
)
