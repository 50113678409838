import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate, getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import SendIcon from '@material-ui/icons/Send'
import WaitingIcon from '@material-ui/icons/RadioButtonUnchecked'
import ReprobateIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import ApprovedIcon from '@material-ui/icons/SentimentVerySatisfied'
import RegularApprovedIcon from '@material-ui/icons/SentimentSatisfied'
import LinkMaterial from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import CloundDownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import CloseIcon from '@material-ui/icons/Close'
import InfoIcon from '@material-ui/icons/Info'
import Paper from '@material-ui/core/Paper'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import yourlogohere from '../../../resources/images/yourlogohere.png'
import femsalogohere from '../../../resources/images/femsa_logo.png'

/** Import action section */
import {
	BACKGROUND_COLOR_GRAY,
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_WARNING,
	COLOR_STATUS_ALERT_INFO,
} from './../../../store/helpers/StatusColorConstants'
import { CardHeader, Fab, Divider, Tab, Tabs, AppBar, Avatar, Typography } from '@material-ui/core'
import {
	saveResponseInQuestionnaireFromSupplier,
	sendQuestionnarieCompletedFromSupplier,
	uploadFilesEvidenceInQuestion,
	removeFilesEvidenceInQuestion,
} from '../../../store/ethicCode/ethicCodeAction'
import LoadingPage from '../../common/LoadingPage'
import QuestionCard from './components/QuestionCard'
import { showSuccessToaster, showErrorToaster } from '../../../store/helpers/ToasterHelpers'
import Toaster from '../../common/Toaster'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'

import { loadAdministratorSupplier } from '../../../store/suppliers/ExternalUserActions'

class Questionnaires extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			activeTab: 0,
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.isLoadingQuestionnaries !== this.props.isLoadingQuestionnaries &&
			this.props.ethicQuestionnaires !== null
		) {
			/// Get information full from adminsitrator supplier
			let adminUserName = `${this.props.currentUser.profile.company_code}-admin`
			this.props.loadAdministratorSupplier(adminUserName)
		}
	}

	changeSelectTab(index) {
		this.setState({ activeTab: Number(index) })
	}

	sendQuestionAnswer(questionToken, indexOption, commnets) {
		this.props.sendQuestionAnswer(
			this.props.ethicQuestionnaires[this.state.activeTab].token,
			questionToken,
			indexOption.value,
			commnets
		)
	}

	sendQuestionnaire() {
		this.props
			.sendQuestionnaire(this.props.ethicQuestionnaires[this.state.activeTab].token)
			.then((response) => {
				this.setState(showSuccessToaster('surveys.sentSuccess'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : showErrorToaster()))
			})
	}

	isCompletedQuestionaries() {
		let isCompleted = this.props.ethicQuestionnaires.find((cuestionarie) => {
			return cuestionarie.code_ethic_status === 1
		})
		return isCompleted
	}

	onCloseOptionCuestionaries() {
		return this.props.history.push('/')
	}

	getStatusDescription(statusId) {
		switch (statusId) {
			case 2:
				return 'statusDescriptions.approved'
			case 3:
				return 'statusDescriptions.reprobate'
			case 4:
				return 'statusDescriptions.regular'
			default:
				return 'statusDescriptions.inWaiting'
		}
	}
	getStatusIcon(statusId, xs = true) {
		let xsButton = xs && { maxWidth: 20, maxHeight: 20, marginTop: -10 }

		switch (Number(statusId)) {
			case 2:
				return <ApprovedIcon style={{ ...xsButton, color: COLOR_STATUS_SUCCESS }} />
			case 3:
				return <ReprobateIcon style={{ ...xsButton, color: COLOR_STATUS_DANGER }} />
			case 4:
				return <RegularApprovedIcon style={{ ...xsButton, color: COLOR_STATUS_WARNING }} />
			default:
				return <WaitingIcon color='inherit' style={{ ...xsButton }} />
		}
	}

	renderDocuments(attachments) {
		return (
			<React.Fragment>
				<List component='div' disablePadding>
					<ListItem className={this.props.classes.nested}>
						<Typography
							component='span'
							variant='body2'
							className={this.props.classes.inline}
							color='textPrimary'
						>
							<Translate id={'surveys.filesInCode'} />
						</Typography>
					</ListItem>
					{attachments.map((document) => {
						return (
							<React.Fragment key={document.name}>
								<ListItem key={document.name} component='li' className={this.props.classes.nested}>
									<LinkMaterial
										component={'a'}
										target='_blank'
										href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${document.relativePath}`}
									>
										<CloundDownloadIcon />
										{'  '}
										{document.name}
									</LinkMaterial>
								</ListItem>
							</React.Fragment>
						)
					})}
				</List>
			</React.Fragment>
		)
	}

	renderQuestionaireCard() {
		var { ethicQuestionnaires, classes, isLoadingQuestionnaries, administratorSupplier } = this.props
		var currentQuestionnaire
		var isFullyAnswered = false
		if (!isLoadingQuestionnaries) {
			currentQuestionnaire = ethicQuestionnaires[this.state.activeTab]
			isFullyAnswered =
				currentQuestionnaire &&
				!currentQuestionnaire.questions.find(
					(question) => question.response_supplier < 1 && question.question_type === 2
				)
		}
		if (ethicQuestionnaires.length < 1) return this.props.history.push('/')

		return (
			<Card>
				<CardHeader
					title={currentQuestionnaire.name_code_ethic}
					titleTypographyProps={{ variant: 'h6' }}
					action={
						<React.Fragment>
							{/* Is admin can be send information */}
							{administratorSupplier &&
								administratorSupplier.id === this.props.currentUser.profile.sub && (
									<Fab
										title={this.props.translate('surveys.sendQuestionnaire')}
										color='primary'
										size='small'
										disabled={
											!isFullyAnswered ||
											this.props.isSavingEthicCode ||
											currentQuestionnaire.is_closed
										}
										onClick={() => {
											this.sendQuestionnaire()
										}}
										style={{ margin: '.4em' }}
									>
										<SendIcon />
									</Fab>
								)}
							{/* } */}

							{!this.isCompletedQuestionaries() && (
								<Fab
									title={this.props.translate('surveys.closeQuestionaries')}
									color='secondary'
									size='small'
									disabled={this.isCompletedQuestionaries()}
									onClick={() => {
										this.onCloseOptionCuestionaries()
									}}
									style={{ margin: '.4em' }}
								>
									<CloseIcon />
								</Fab>
							)}
						</React.Fragment>
					}
				/>
				<CardContent style={{ height: '60vh', overflowY: 'auto' }} className={classes.withoutPaddingTopBottom}>
					{currentQuestionnaire.attachments.length !== 0 && (
						<Grid item xs={12}>
							{this.renderDocuments(currentQuestionnaire.attachments)}
							<br />
						</Grid>
					)}
					{currentQuestionnaire.questions.map((question) => {
						return (
							<QuestionCard
								question={question}
								sendQuestionAnswer={this.sendQuestionAnswer}
								isDisabled={currentQuestionnaire.is_closed}
								removeFilesEvidenceInQuestion={this.props.removeFilesEvidenceInQuestion}
								uploadFilesEvidenceInQuestion={this.props.uploadFilesEvidenceInQuestion}
								currentQuestionnaireToken={currentQuestionnaire.token}
							/>
						)
					})}{' '}
					<br />
					<br />
				</CardContent>
			</Card>
		)
	}

	render() {
		var { ethicQuestionnaires, isLoadingQuestionnaries, classes, company, administratorSupplier } = this.props
		let companyLogo = company ? company.logo : ''
		let urlImageCompany = `${process.env.REACT_APP_SIP_URL_LOGOS_ADMINISTRATION}/${companyLogo}`
		return (
			<Paper>
				<Card className={classes.cardInfo} role='alert'>
					<CardHeader
						avatar={
							<Avatar aria-label='Important' className={classes.avatar}>
								<InfoIcon />
							</Avatar>
						}
						title={<Translate id='surveys.acceptedTitle' />}
						subheader={
							administratorSupplier && (
								<React.Fragment>
									<Grid item>
										<strong>
											<Translate id='officialNotices.administrator' />
											{': '}
										</strong>
										{administratorSupplier.name}
									</Grid>
									<Grid item>
										<strong>
											<Translate id='officialNotices.email' />
											{': '}
										</strong>
										{administratorSupplier.email}
									</Grid>
								</React.Fragment>
							)
						}
					/>
				</Card>
				<Card>
					<CardHeader
						avatar={
							<img
								src={companyLogo ? urlImageCompany : yourlogohere}
								alt='Logo'
								className={classes.logoCompany}
							/>
						}
						title={
							<Typography variant='h5' align='center'>
								{this.props.translate('surveys.titleQuestionnaires')}
							</Typography>
						}
						action={<img src={femsalogohere} alt='Logo2' className={classes.logoSupplier} />}
						className={classes.withoutPaddingTopBottom}
					/>
					<CardContent
						className={classes.withoutPaddingTopBottom}
						style={{ height: '75vh', marginTop: '10vh' }}
					>
						{isLoadingQuestionnaries ? (
							<LoadingPage />
						) : (
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<AppBar position='static' color='default'>
										<Tabs
											indicatorColor='primary'
											textColor='primary'
											value={this.state.activeTab}
											onChange={this.onClickToActiveTab}
											variant='scrollable'
											scrollButtons='auto'
											className={classes.tabs}
										>
											{ethicQuestionnaires.map((questionnaire, index) => {
												return (
													<Tab
														id={index}
														label={<p>{questionnaire.name_code_ethic}</p>}
														onClick={(event) =>
															this.changeSelectTab(event.currentTarget.id)
														}
														className={classes.tabs}
													/>
												)
											})}
										</Tabs>
									</AppBar>
								</Grid>
								<Grid item xs={12}>
									<Divider />
									{this.renderQuestionaireCard()}
								</Grid>
							</Grid>
						)}
					</CardContent>
					<Toaster
						message={<Translate id={this.state.toasterMessage} />}
						open={this.state.showToaster}
						variant={this.state.toasterVariant}
						onClose={() => {
							this.setState({ showToaster: false })
						}}
					/>
				</Card>
			</Paper>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let ethicQuestionnaires = state.ethicCodes.get('ethicCodesListHome')
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		currentUser: user,
		translate: getTranslate(state.localize),
		ethicQuestionnaires: ethicQuestionnaires ? ethicQuestionnaires.toJS() : null,
		isLoadingQuestionnaries: state.ethicCodes.get('isLoadingHome'),
		isSavingEthicCode: state.ethicCodes.get('isSavingEthicCode'),
		company: selectedCompany ? selectedCompany.toJS() : null,
		administratorSupplier: state.externalUsers.get('administratorSupplier')
			? state.externalUsers.get('administratorSupplier').toJS()
			: null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		sendQuestionAnswer: (questionnaireToken, questionToken, indexOption, commnets) => {
			return dispatch(
				saveResponseInQuestionnaireFromSupplier(questionnaireToken, questionToken, indexOption, commnets)
			)
		},
		sendQuestionnaire: (questionnaireToken) => {
			return dispatch(sendQuestionnarieCompletedFromSupplier(questionnaireToken))
		},
		loadAdministratorSupplier: (userId) => {
			return dispatch(loadAdministratorSupplier(userId))
		},
		uploadFilesEvidenceInQuestion: (files, questionToken) => {
			return dispatch(uploadFilesEvidenceInQuestion(files, questionToken))
		},
		removeFilesEvidenceInQuestion: (fileName, questionToken) => {
			return dispatch(removeFilesEvidenceInQuestion(fileName, questionToken))
		},
	}
}

const styles = (theme) => ({
	logoCompany: {
		maxHeight: 70,
	},
	logoSupplier: {
		marginTop: 15,
		maxHeight: 45,
	},
	tabs: {
		maxHeight: 25,
		minHeight: 20,
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	withoutPaddingTopBottom: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	cardInfo: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Questionnaires)))
)
