

/**
 * Determine if a string is null or empty
 *
 * @export
 * @param {*} string
 */
export function IsNullOrEmpty(value) {
    if (!value) {
        return true;
    }
    let trimmed = String(value).trim();
    if (trimmed.length === 0) {
        return true;
    }
    if (trimmed === 'null')
        return true;
    return false;
}


/**
 * Determine if a selector is null or empty
 * @param {*} value 
 */
export function IsSelectorNullOrEmpty(value) {
    if (!value) {
        return true;
    }
    if (value.length === 0) {
        return true
    }
    return false;
}

/**
 * Create new string guid token 
 * 
 * @export
 * @returns 
 */
export function genereteStringGuid() {
    function random() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return random() + random() + "-" + random() + "-" + random() + "-" + random() + "-" + random() + random() + random();
}

/**
 * capitalize first leter of a string
 *
 * @export
 * @param {*} value
 * @returns
 */
export function capitalizeFirstLetter(value) {
    var capitalLetter = value.charAt(0).toUpperCase();
    return capitalLetter + value.substring(1);
}

/**
 * convert understrike case to title case
 * Optionally a char or string can be pass to separate the input string
 *
 * @export
 * @param {*} value
 * @returns
 */
export function underScoreToTitleCase(value, separator = '') {
    var splitStr = value.toLowerCase().split('_');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(separator);
}

export function convertToTitleCase(value, separator = '_') {
    var splitStr = value.toLowerCase().split(separator);
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(separator);
}

export function changeSeparatorCharacter(value, currentSeparator = ' ', newSeperator = '_') {
    var splitStr = value.toLowerCase().split(currentSeparator);
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(newSeperator);
}

export const applyCurrencyFormat = (amount) => {
    const number = amount || 0;
    const result = roundNumber(number, 2);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
    return `${formatter.format(result)}`;
}

export const applyPercentageFormat = (percentage) => {
    const number = percentage || 0;
    const result = roundNumber(number, 2) * 100;
    return `${result} %`;
}

export const roundNumber = (value, decimals = 0) => {
	return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

export const capitalize = (str) => {
    if (typeof str !== 'string') {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Method to splite lines from string to array taking each line as a new element.
 * 
 * @param {string} str 
 */
export const splitLines = (str) => IsNullOrEmpty(str) ? [] : str.split(/\r?\n/);

/**
 * Method to take input array an it returns a new array with only unique values.
 * 
 * @param {values} arrayValues Array of values
 */
export const removeRepeatingValues = (arrayValues) => !arrayValues ? [] : [...new Set(arrayValues)];

/**
 * Method to take input array, it remove leading zeros from each values on input array
 * and it returns a new array.
 * 
 * @param {values} arrayValues Array of values
 */
export const removeLeadingZeros = (arrayValues) => !arrayValues ? [] : arrayValues.map((val) => val.replace(/^0+/, ''));


