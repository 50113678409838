///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Translate, getTranslate } from 'react-localize-redux'
import queryString from 'query-string'
/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AddIcon from '@material-ui/icons/AddTwoTone'
import CategoryIcon from '@material-ui/icons/Category'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import BiddingIcon from '@material-ui/icons/ViewCarousel'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import DuplicatedIcon from '@material-ui/icons/CollectionsBookmark'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import Grid from '@material-ui/core/Grid'
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import SearchInput from '../common/SearchInput/SearchInput'
import OptionsMenu from '../common/OptionsMenu'
import AddLateralPanel from './components/AddBiddingLateralPanel'
import PopperListsComponent from './components/PopperListsComponent'
import AvatarContainer from './components/AvatarContainer'
import CategoriesLateralPanel from './components/categories/CategoriesLateralPanel'
import AddBiddingStartNewRound from './components/AddBiddingStartNewRound'
/**import actions section */
import {
	loadAllBiddingsFromBackEnd,
	addBidding,
	downloadReportBiddings,
	ADD_BIDDING,
	removeBidding,
	startNewRoundBidding,
	setBidding,
} from '../../store/biddings/BiddingsActions'
import { loadAllBiddingsCategoriesFromBackEnd } from '../../store/categories/CategoriesActions'
import { searchUsersInRole, loadInternalUsersFromBackEnd } from '../../store/administration/InternalActions'
/**import helper section */
import { converListToArray } from '../../store/helpers/ListHelper'
import { canBeModuleBidding } from '../../store/helpers/RolesHelper'
import { ROLE_BIDDING_RESPONSIBLE, IsUserInRole } from '../../store/helpers/RolesHelper'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import { ADD_DUPLICATED_BIDDING_NAME_MESSAGE } from '../../store/helpers/MessagesError'
import {
	getResponsiblesFromBidding,
	canBeEditBiddingInStatusDraftIndex,
	getStatusIndex,
} from '../../store/helpers/BiddingHelper'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import { BiddingStatus } from '../../store/helpers/AppConstants'
const IndexView = (props) => {
	const { classes } = props
	/**Render hooks section */
	// const classes = styles()
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()
	const queryParams = queryString.parse(location.search)

	/**Redux store information section*/
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const biddings = useSelector((state) =>
		state.biddings.get('biddings') ? converListToArray(state.biddings.get('biddings')) : []
	)
	const isLoadingBiddings = useSelector((state) => state.biddings.get('isLoading'))
	const isSaving = useSelector((state) => state.biddings.get('isSaving'))
	const identifier = useSelector((state) => state.biddings.get('identifier'))
	const categories = useSelector((state) =>
		state.categories.get('categories') ? converListToArray(state.categories.get('categories')) : []
	)
	const usersByRole = useSelector((state) =>
		state.internalUsers.get('usersByRole') ? converListToArray(state.internalUsers.get('usersByRole')) : []
	)
	const internalUsers = useSelector((state) =>
		state.internalUsers.get('internalUsers') ? converListToArray(state.internalUsers.get('internalUsers')) : []
	)
	const userProfile = useSelector((state) =>
		state.oidc.user ? state.oidc.user.profile : { name: '', email: '', user_name: '' }
	)
	const biddingsCount = useSelector((state) => state.biddings.get('biddingsCount'))
	const isDownloadingReport = useSelector((state) => state.biddings.get('isDownloading'))
	const translate = useSelector((state) => getTranslate(state.localize))
	const userProfileLogged = useSelector((state) => state.oidc.user)
	/**Store local this component */
	const [openLateralPanelCreateBidding, setOpenLateralPanelCreateBidding] = useState({ opened: false })
	const [openLateralPanelDuplicatedBidding, setOpenLateralPanelDuplicatedBidding] = useState({
		opened: false,
		bidding: null,
	})
	const [openLateralPanelCategories, setOpenLateralPanelCategories] = useState(false)

	const [showMenu, setShowMenu] = useState(true)
	const [itemToken, setItemToken] = useState('')
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	let isVisibleCategories = false
	/**Use effect section */
	useEffect(() => {
		dispatch(setBidding(null))
		dispatch(loadInternalUsersFromBackEnd())
		dispatch(loadAllBiddingsCategoriesFromBackEnd())
		dispatch(searchUsersInRole(ROLE_BIDDING_RESPONSIBLE))
	}, [dispatch])

	/**Use effect section */
	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(loadAllBiddingsFromBackEnd(queryParams, translate))
	}, [dispatch, location.search])

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onOpenCloseCategoriesLateralPanel = () => {
		setOpenLateralPanelCategories(!openLateralPanelCategories)
	}

	const onOpenCloseLateralPanel = () => {
		setOpenLateralPanelCreateBidding({ opened: !openLateralPanelCreateBidding.opened })
	}

	const onDuplicatedBiddingOpenCloseLateralPanel = (event, bidding) => {
		if (event) event.stopPropagation()
		setOpenLateralPanelDuplicatedBidding({ opened: !openLateralPanelDuplicatedBidding.opened, bidding: bidding })
	}

	const getCounterMessage = () => {
		if (biddings) {
			let totalBiddings = biddings.length
			return (
				<div>
					{<Translate id='common.showing' />}
					<span className={classes.showingCounter}>{totalBiddings}</span>&nbsp;
					{<Translate id='common.of' />}
					<span className={classes.showingCounter}>{biddingsCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	const onDownloadReport = () => {
		dispatch(downloadReportBiddings())
			.then(() => {})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToDownload',
					toasterVariant: 'error',
				})
			})
	}

	const onAddBidding = (biddingModel) => {
		return dispatch(addBidding(biddingModel, translate))
			.then(() => {
				onOpenCloseLateralPanel()
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage:
						!IsNullOrEmpty(response.data) &&
						typeof response.data === 'string' &&
						response.data.includes(ADD_DUPLICATED_BIDDING_NAME_MESSAGE)
							? 'biddingsModule.errorMessages.addBiddingError'
							: 'common.errorToSave',
					toasterVariant: 'error',
					name: biddingModel.name,
				})
			})
	}

	const onRemoveBidding = (event, id) => {
		event.stopPropagation()
		return dispatch(removeBidding(id))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.removeCorrectly',
				})
			})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onStartNewRoundBidding = (biddingModel) => {
		biddingModel.Id = openLateralPanelDuplicatedBidding ? openLateralPanelDuplicatedBidding.bidding.Id : ''
		return dispatch(startNewRoundBidding(biddingModel, translate))
			.then(() => {
				onDuplicatedBiddingOpenCloseLateralPanel()
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const handleCloseOptionsMenu = () => {
		setShowMenu(false)
		setItemToken('')
	}

	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof Index
	 */
	const handleClickOptionsMenu = (token) => {
		setItemToken(token)
	}

	const renderAction = () => {
		var options = []
		options.push({
			itemClick: () => onDownloadReport(),
			tooltipTranslation: <Translate id='common.downloadReport' />,
			menuItemIcon: <DownloadIcon color='secondary' />,
			isLoading: isDownloadingReport,
		})
		if (showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={handleClickOptionsMenu}
					open={itemToken === 'item.token'}
					handleClickAway={handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * onRowClick
	 * @param {*} calendar
	 * @param {*} event
	 */
	const onRowClick = (bidding, event) => {
		let tagName = event.target.tagName
		if (tagName !== 'svg' && tagName !== 'A') {
			props.history.push(`/biddings/${bidding.Id}`)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	const getCardContent = () => {
		if (isLoadingBiddings === true) {
			return <LoadingPage />
		} else if (biddings === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<>
					<DataTable
						data={biddings}
						configuration={TableConfiguration(canBeModuleBidding(userRolesByCompany))}
						onRenderCellItem={onRenderCellItem}
						onChangeSortCriteria={onChangeSortCriteria}
						onRowClick={onRowClick}
						isIndex
						showFilters
						totalRows={biddingsCount}
						sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'FormatedFolio'}
						sortAscending={
							queryParams && queryParams.sortDirection
								? queryParams.sortDirection === 'desc'
									? false
									: true
								: false
						}
						page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
						rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				</>
			)
		}
	}
	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'Name') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item.Name}
				</Typography>
			)
		}
		if (dataSource === 'CreatedBy') {
			const creator = internalUsers.find((x) => x.user_name === item.CreatedBy)
			return creator ? creator.name : ''
		}
		if (dataSource === 'Responsibles') {
			let allResponsibles = getResponsiblesFromBidding(usersByRole, item.Responsibles, userProfile)
			return (
				<Grid item xs={12}>
					{item && item.Responsibles && (
						<Grid container style={{ marginLeft: '-17px' }}>
							{allResponsibles.map((user) => {
								return (
									<Grid item xs={1} key={user.user_name} style={{ marginLeft: '9px' }}>
										<AvatarContainer
											responsible={user}
											userProfile={userProfile}
											canBeRemoveResponsible={false}
										/>
									</Grid>
								)
							})}
						</Grid>
					)}
				</Grid>
			)
		}
		if (dataSource === 'options' && IsCurrentActiveDirectoryUser(userProfileLogged)) {
			let status = getStatusIndex(item.Status, translate)
			let buttonsForMoreCard = []
			if (item.CanBeStartNewRound) {
				buttonsForMoreCard.push({
					title: <Translate id='biddingsModule.duplicated' />,
					icon: <DuplicatedIcon />,
					onClick: (event) => {
						onDuplicatedBiddingOpenCloseLateralPanel(event, item)
					},
					isIconMaterial: true,
					disabled: isSaving,
				})
			}
			if (status === BiddingStatus.DRAFT) {
				buttonsForMoreCard.push({
					title: <Translate id='common.delete' />,
					onClick: (event) => {
						onRemoveBidding(event, item.Id)
					},
					icon: <DeleteIcon />,
					isIconMaterial: true,
					disabled:
						!canBeEditBiddingInStatusDraftIndex(item, userRolesByCompany, userProfile, status) || isSaving,
				})
			}
			return <PopperListsComponent title={<Translate id='common.options' />} listItems={buttonsForMoreCard} />
		}
	}
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<BiddingIcon />
					</Avatar>
				}
				action={
					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '5px' }}>
							<SearchInput
								onChangeSearchValue={onChangeSearchValue}
								value={!!queryParams && queryParams.query ? queryParams.query : ''}
							/>
						</div>

						{IsUserInRole(ROLE_BIDDING_RESPONSIBLE, userRolesByCompany) && (
							<Fab
								size='small'
								color='primary'
								onClick={() => {
									onOpenCloseLateralPanel()
								}}
							>
								<AddIcon />
							</Fab>
						)}
						{isVisibleCategories && IsUserInRole(ROLE_BIDDING_RESPONSIBLE, userRolesByCompany) && (
							<Fab
								size='small'
								color='primary'
								className={classes.categoryIcon}
								onClick={() => {
									onOpenCloseCategoriesLateralPanel()
								}}
							>
								<CategoryIcon />
							</Fab>
						)}

						{IsUserInRole(ROLE_BIDDING_RESPONSIBLE, userRolesByCompany) && renderAction()}
					</div>
				}
				title={<Typography variant='button'>{<Translate id='biddingsModule.title' />}</Typography>}
				subheader={getCounterMessage()}
			/>

			{openLateralPanelCreateBidding.opened && (
				<AddLateralPanel
					onCloseButtonClick={onOpenCloseLateralPanel}
					categories={categories}
					onSaveButtonClick={onAddBidding}
					internalUsers={usersByRole}
					isSaving={identifier === ADD_BIDDING && isSaving}
					userProfile={userProfile}
				/>
			)}
			{openLateralPanelDuplicatedBidding.opened && (
				<AddBiddingStartNewRound
					onCloseButtonClick={onDuplicatedBiddingOpenCloseLateralPanel}
					onSaveButtonClick={onStartNewRoundBidding}
					isSaving={isSaving}
				/>
			)}
			{isVisibleCategories && openLateralPanelCategories && (
				<CategoriesLateralPanel
					categories={categories}
					onCloseButtonClick={onOpenCloseCategoriesLateralPanel}
				/>
			)}
			<CardContent>{getCardContent()}</CardContent>

			<Toaster
				message={
					<Translate id={toasterMessageConfig.toasterMessage} data={{ name: toasterMessageConfig.name }} />
				}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</Card>
	)
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='biddingsModule.table.status' />,
			dataSource: 'Status',
		},
		{
			header: <Translate id='biddingsModule.table.folio' />,
			dataSource: 'Folio',
			isSortable: true,
		},
		{
			header: <Translate id='biddingsModule.table.creationDate' />,
			dataSource: 'CreationDate',
			isSortable: true,
		},
		{
			header: <Translate id='biddingsModule.table.name' />,
			dataSource: 'Name',
			isSortable: true,
		},
		{
			header: <Translate id='biddingsModule.table.closingDate' />,
			dataSource: 'PlannedClosingDate',
			isSortable: true,
		},
		{
			header: <Translate id='biddingsModule.table.publishDate' />,
			dataSource: 'PlannedPublicationResultsDate',
			isSortable: true,
		},
		{
			header: <Translate id='biddingsModule.create.category' />,
			dataSource: 'CategoryName',
		},
		{
			header: <Translate id='biddingsModule.create.responsibles' />,
			dataSource: 'Responsibles',
			isArrayStringFilter: true,
		},
		{
			header: '',
			dataSource: 'options',
		},
	]

	return { columns }
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	showingCounter: {
		marginLeft: '5px',
	},
	biddingContainer: {
		maxHeight: `calc(100vh - ${275}px)`,
		minHeight: `calc(100vh - ${275}px)`,
		overflowY: 'hidden',
		backgroundColor: 'red',
	},
	categoryIcon: {
		marginLeft: '5px',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(IndexView)))
