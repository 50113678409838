
/** Helpers import section */
import axios from 'axios';
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';
import {
    descriptionFolioType
} from '../helpers/BillingHelper';

export const SET_BILLINGS_COUNT = "SET_BILLINGS_COUNT";
export const START_LOADING_BILLINGS = "START_LOADING_BILLINGS";
export const IS_SAVING = "IS_SAVING";
/**Add actions section */
export const SET_BILLINGS_LIST = "SET_BILLINGS_LIST";
export const SET_BILLING = "SET_BILLING"
export const ADD_BILLING = "ADD_BILLING"
export const ADD_BILLING_ITEM = "ADD_BILLING_ITEM"

/**Update actions section */

/**Delete actions section */


function setAddItem(item) {
    return {
        type: ADD_BILLING_ITEM,
        item
    }
}

/**
 * Action start loading common data
 * @param {*} isLoading 
 */
function isLoadingCommon(isLoading, identifier) {
    return {
        type: START_LOADING_BILLINGS,
        isLoading: isLoading,
        identifier: identifier
    }
}

/**
 * Action start saving information
 * @param {*} isLoading 
 */
function isSavingCommon(isSaving, identifier) {
    return {
        type: IS_SAVING,
        isSaving: isSaving,
        identifier: identifier
    }
}

/**
 * Action to set BILLINGS list 
 * @param {*} BILLINGS 
 */
function setBillingsList(billings) {
    return {
        type: SET_BILLINGS_LIST,
        billings: billings
    }
}

/**
 * Action to set billings count
 * @param {*} count 
 */
function setBillingsCount(count) {
    return {
        type: SET_BILLINGS_COUNT,
        count
    }
}

function setAddBilling(billing) {
    return {
        type: ADD_BILLING,
        billing
    }
}

export function setBilling(billing) {
    return {
        type: SET_BILLING,
        billing
    }
}

//#region ACTION FOR BILLINGS


/**
 * Action load billing from company backend
 * @param {*} query 
 * @param {*} orderBy 
 * @param {*} sortAscending 
 * @param {*} page 
 * @param {*} rowsPerPage 
 */
export function loadAllBillingsFromBackEnd(queryParameters, translate) {
    return function (dispatch, getState) {

        dispatch(isLoadingCommon(true, START_LOADING_BILLINGS));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let supplierToken = undefined;
            if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
                supplierToken = getState().oidc.user.profile.company_code;
            }
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/billings/filters`
            
            let sortAscending = ((queryParameters && queryParameters.sortDirection) ? queryParameters.sortDirection : 'desc')
            if (!sortAscending) sortAscending = false
            let data = {
                CompanyToken: selectedCompany.get("token"),
                SupplierToken: supplierToken,
                SortBy:  ((queryParameters && queryParameters.sortBy) ? queryParameters.sortBy : "CreationDate"),
                SortAscending: sortAscending,
                Query: queryParameters.query ? queryParameters.query : "",
                Page: (queryParameters && queryParameters.pageNumber) ? (parseInt(queryParameters.pageNumber) + 1) : 1,
                RowsPerPage: (queryParameters && queryParameters.rowsPerPage) ? queryParameters.rowsPerPage : 20,
            }
            return axios.post(endPoint, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        let mappedResponse = serverResponse.data.map(billing => {
                            billing.folio_type_description = descriptionFolioType(billing.folio_type, translate)
                            return billing
                        })
                        dispatch(setBillingsList(mappedResponse))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingCommon(false, SET_BILLINGS_LIST));
                    console.error("Error getting billings list by company", error.response)
                    return Promise.reject(error.response)
                })

        }
        else {
            dispatch(isLoadingCommon(false, SET_BILLINGS_LIST));
        }
    }
}


export function addBilling(billingModel, translate) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, ADD_BILLING));
        let selectedCompany = getState().profile.get("selectedCompany");
        let user = getState().oidc.user;
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/billing`;

            let command = {
                Reference: billingModel ? billingModel.reference : "",
                Comments: billingModel ? billingModel.comments : "",
                Amount: billingModel ? billingModel.amount : 0,
                DivisaCode: billingModel ? billingModel.divisa : "",
                FolioType: billingModel.folioType ? billingModel.folioType.value : "",
                CompanyToken: selectedCompany.get('token'),
                CompanyIdentifier: selectedCompany.get('get_company_identifier'),
                DocumentIdentifier: selectedCompany.get('document_identifier'),
                CreatorUserName: user.profile.preferred_username,
                CreateBy: user.profile.name,
                CreateEmail: user.profile.email,
                SupplierToken: billingModel.supplier ? billingModel.supplier.token:"",
                SupplierCode: billingModel.supplier ? billingModel.supplier.code:"",
                SupplierName: billingModel.supplier ? billingModel.supplier.name:"",
            }
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        serverResponse.data.folio_type_description = descriptionFolioType(serverResponse.data.folio_type, translate)
                        dispatch(setAddBilling(serverResponse.data))
                        return Promise.resolve(true)
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, ADD_BILLING));
                    console.error("Error in add billing", error.response)
                    return Promise.reject(false)
                })
        }
        else {
            dispatch(isSavingCommon(false, ADD_BILLING));
        }
    }
}

/**
 *Action to get supplier billings count 
 *
 * @export
 * @returns
 */
 export function getBillingCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().billings.get('billingsCount');
        if (actualCounter) {
            return;
        }
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/billings/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setBillingsCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the billing's count", error.response)
            })
    }
}


export function loadBillingFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(isLoadingCommon(true, SET_BILLING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/billing/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isLoadingCommon(false, SET_BILLING));
                    dispatch(setBilling(serverResponse.data))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isLoadingCommon(false, SET_BILLING));
                console.error("Error getting billing", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addItemBilling(token, itemModel) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, ADD_BILLING_ITEM));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/billing/item`;

            let command = {
                Token: token,
                Description: itemModel ? itemModel.name : "",
                Amount: itemModel ? itemModel.price : "",
                MeasurementUnit: itemModel ? itemModel.measurementUnit : "",
            }
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setAddItem(serverResponse.data))
                        return Promise.resolve(true)
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, ADD_BILLING_ITEM));
                    console.error("Error in add item in billing", error.response)
                    return Promise.reject(false)
                })
        }
        else {
            dispatch(isSavingCommon(false, ADD_BILLING_ITEM));
        }
    }
}



//#endregion