import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'

import { Group } from '@material-ui/icons'

import CatalogHeader from './components/CatalogHeader'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'

import { isTransportTenderAdministrator } from '../../../store/helpers/TransportTenderHelper'
import { getLoggedUserSelector } from '../../../store/profile/ProfileSelectors'
import * as selector from '../../../store/transportTender/transportTenderSelector'
import * as action from '../../../store/transportTender/transportTenderAction'

import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import LoadingPage from '../../common/LoadingPage'
import DataTable from '../../common/DataTable'
import DialogCatalogUploadMassive from './components/DialogCatalogUploadMassive'
import { Typography } from '@material-ui/core'
import moment from 'moment'

const Clients = (props) => {
	const { classes } = props
	let history = useHistory()
	let location = useLocation()
	const dispatch = useDispatch()

	const [openDialog, setOpenDialog] = useState(false)
	const [file, setfile] = useState([])

	const queryParams = queryString.parse(location.search)
	const loggetUser = useSelector((state) => getLoggedUserSelector(state))
	const isLoadig = useSelector((state) => selector.getIsLoadingSavingSelector(state))
	const transportTenderinfo = useSelector((state) => selector.getTransportTenderfilterSelector(state))
	const errorTariffSAPFile = useSelector((state) => selector.getErrorClientsFile(state))

	const userRoles = loggetUser && loggetUser.grantedRoles ? loggetUser.grantedRoles : []
	const isTransportTenderAdminitsrator = isTransportTenderAdministrator(userRoles)

	const onChangeQuery = (query) => {
		if (query) queryParams.query = query
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onChangePage = (page, rowsPerPage) => {
		queryParams.pageNumber = page + 1
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}
	useEffect(() => {
		dispatch(action.loadClientsPaginatedCatalog(queryParams))
	}, [dispatch, location.search])

	useEffect(() => {
		if (file.length > 0) {
			dispatch(action.validateClientTemplate(file))
		}
	}, [file])

	const onDownloadTemplateFilled = () => {
		return dispatch(action.downloadFilledClientTemplate())
	}
	const canBeSaved = () => {
		if (errorTariffSAPFile.length > 0) return false
		if (file.length === 0) return false
		return true
	}
	const onSave = () => {
		dispatch(action.uploadClientsTemplate(file))
			.then(() => {
				setOpenDialog(false)
				dispatch(action.loadClientsPaginatedCatalog(queryParams))
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}
	return (
		<div className={classes.containerTitle}>
			<CatalogHeader
				setOpenDialog={setOpenDialog}
				icon={<Group />}
				isInternalUse={true}
				title={<Translate id={'carrierTenders.configuration.clientsTitle'} />}
				isAdministrator={isTransportTenderAdminitsrator}
				showSearch
				queryParams={queryParams}
				onChangeSearchValue={onChangeQuery}
			/>
			{isLoadig ? (
				<LoadingPage />
			) : (
				<DataTable
					data={transportTenderinfo.clientsPagination.items}
					configuration={TableConfiguration()}
					onRenderCellItem={onRenderCellItem}
					showFilters
					isIndex
					page={transportTenderinfo.clientsPagination.pageNumber}
					rowsPerPage={transportTenderinfo.clientsPagination.pageSize}
					totalRows={transportTenderinfo.clientsPagination.total}
					onChangePage={onChangePage}
				/>
			)}
			<DialogCatalogUploadMassive
				title={'Importación masiva de tipos de clientes'}
				typeLoadTranslate={'massiveClients'}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				file={file}
				setfile={setfile}
				onDownloadTemplateFilled={onDownloadTemplateFilled}
				isLoading={isLoadig}
				canBeSaved={canBeSaved()}
				errorList={errorTariffSAPFile}
				onSave={onSave}
			/>
		</div>
	)
}
const onRenderCellItem = (dataSource, item) => {
	if (dataSource === 'creationDate')
		return <Typography>{moment(item.creationDate).format('DD/MM/YYYY HH:mm A')}</Typography>
}
function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: <Translate id={'carrierTenders.clientCatalog.table.code'} />,
			dataSource: 'Code',
		},
		{
			header: <Translate id={'carrierTenders.clientCatalog.table.name'} />,
			dataSource: 'Name',
		},
		{
			header: 'RFC',
			dataSource: 'RFC',
		},
		{
			header: <Translate id={'carrierTenders.clientCatalog.table.creationDate'} />,
			dataSource: 'creationDate',
		},
	]
	return { columns }
}
const styles = (theme) => ({
	containerTitle: {
		margin: '15px',
	},
})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Clients)))
