import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { Translate } from "react-localize-redux";
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select';

class ShowPanelToAddFiles extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            documentSelected: null
        }
    }
    canBeSaved() {
        if (this.state.documentSelected === null)
            return false
        return true
    }

    //Send document not required
    onChange(optionSelect) {
        this.setState({
            documentSelected: optionSelect
        })
    }

    GetBooleanOptions() {
        const types = (this.props.documentsNotRequired);
        const documentsList = [];
        types.map((document) =>
            documentsList.push({ value: document.token, label: `${document.nomenclature_name} | ${document.description}` }));
        return documentsList;
    }
    
    render() {
        const { classes } = this.props
        return (
            <Dialog open={this.props.show}>
                <DialogTitle id="form-dialog-title">{"Seleccione el tipo de archivo que desea subir"}</DialogTitle>
                <DialogContent>
                    <Select
                        onChange={(event) => { this.onChange(event) }}
                        options={this.GetBooleanOptions()}
                        fullWidth
                        styles={{
                            menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                            menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                        }}
                        menuPortalTarget={document.parentNode}
                        menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
                        menuPlacement={'auto'}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.props.addFileOnExpedient(this.state.documentSelected) }}
                        disabled={this.props.isSaving || !this.canBeSaved()}
                        className={classes.buttonStyle}
                        color="green">
                        {/* {this.state.show && onClick = {() => this.show}} */}
                        <Translate id="common.addDocument" />
                    </Button>
                    <Button onClick={this.props.onClose} color="primary">
                        <Translate id="common.close" />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = theme => ({
    buttonStyle: {
        color: theme.palette.primary.main,


    }

});
export default (withStyles(styles, { withTheme: true })(ShowPanelToAddFiles));