import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER, COLOR_STATUS_WARNING } from '../helpers/StatusColorConstants'

export const getColorStatusContract = (status) => {
	switch (Number(status)) {
		case 0:
			return null
		case 1:
			return COLOR_STATUS_SUCCESS
		case 2:
			return COLOR_STATUS_WARNING
		case 3:
			return COLOR_STATUS_DANGER
		default:
			return null
	}
}

export const getDescriptionStatusContract = (status, time = '') => {
	switch (Number(status)) {
		case 0:
			return 'Borrador'
		case 1:
			return 'Activo'
		case 2:
			return 'Proximo a vencer ' + time
		case 3:
			return 'Vencido'
		default:
			return ''
	}
}
