import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'auto-bind'
import {
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Tooltip,
	Grid,
	withStyles,
	Typography,
	ListItemSecondaryAction,
	IconButton,
} from '@material-ui/core'

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/ControlPoint'
import { Translate } from 'react-localize-redux'

import {
	BACKGROUND_COLOR_GRAY_DARK,
	COLOR_STATUS_BLUE,
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
} from '../../../../store/helpers/StatusColorConstants'

/**
 * Class component to show question section
 *
 * @class QuestionsList
 * @extends {PureComponent}
 */
class QuestionsList extends PureComponent {
	/**
	 *Creates an instance of QuestionsList.
	 * @param {*} props
	 * @memberof QuestionsList
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {}
	}

	/**
	 * Method to render Title section
	 *
	 * @param {*} translationId
	 * @param {*} size
	 * @returns
	 * @memberof QuestionsList
	 */
	renderTitleSection(translationId, size) {
		return (
			<Grid item xs={12} sm={12} md={size} lg={size} xl={size}>
				<Typography align='center' variant='button'>
					<Translate id={translationId} />
				</Typography>
			</Grid>
		)
	}

	/**
	 * Main method to render QuestionsList
	 *
	 * @returns
	 * @memberof QuestionsList
	 */
	render() {
		const {
			sectionQuestionnaire,
			onClickAddEditQuestion,
			onRemoveQuestionInSection,
			isSavingTemplate,
			onClickRemoveSection,
			onClickAddEditSection,
		} = this.props
		let isInvalidPercentageSection = sectionQuestionnaire.maximum_points > sectionQuestionnaire.percentage_section
		return (
			<List
				key={sectionQuestionnaire.short_name}
				dense
				subheader={
					<ListSubheader
						sticky='true'
						color='default'
						style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}
					>
						<Grid container spacing={0}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<ListItemText>
									<Typography variant='subtitle2' style={{ margin: '10px', width: '60%' }}>
										{' '}
										{sectionQuestionnaire.description}
									</Typography>
								</ListItemText>
								<ListItemSecondaryAction>
									{!sectionQuestionnaire.is_section_multiple && (
										<span>
											<span
												style={{ color: isInvalidPercentageSection ? COLOR_STATUS_DANGER : '' }}
											>
												<strong>
													{' '}
													{sectionQuestionnaire.percentage_section} %{' '}
													{isInvalidPercentageSection && (
														<Translate id='surveys.invalidPercentage' />
													)}{' '}
												</strong>
											</span>
										</span>
									)}
									{sectionQuestionnaire.is_section_multiple && (
										<span>{<Translate id='questionnaire.sectionWithoutPercentage' />}</span>
									)}
									<Tooltip title={<Translate id='common.add' />}>
										<IconButton
											aria-label='edit'
											size='small'
											style={{ padding: '5px' }}
											onClick={() =>
												onClickAddEditQuestion(
													sectionQuestionnaire.token,
													sectionQuestionnaire.is_section_multiple
												)
											}
											disabled={isSavingTemplate}
										>
											<AddIcon style={{ color: COLOR_STATUS_BLUE }} />
										</IconButton>
									</Tooltip>
									<Tooltip title={<Translate id='common.delete' />}>
										<IconButton
											aria-label='edit'
											size='small'
											style={{ padding: '5px' }}
											onClick={() => onClickRemoveSection(sectionQuestionnaire.token)}
											disabled={isSavingTemplate}
										>
											<DeleteOutlinedIcon color='error' />
										</IconButton>
									</Tooltip>
									<Tooltip title={<Translate id='common.edit' />}>
										<IconButton
											aria-label='edit'
											size='small'
											style={{ padding: '5px' }}
											onClick={() => onClickAddEditSection(sectionQuestionnaire.token)}
											disabled={isSavingTemplate}
										>
											<EditIcon style={{ color: COLOR_STATUS_SUCCESS }} />
										</IconButton>
									</Tooltip>
								</ListItemSecondaryAction>
							</Grid>
						</Grid>
					</ListSubheader>
				}
			>
				{/** Section with question option selected (si / no / na) */}
				{sectionQuestionnaire.section_questions.map((question, index) => {
					return (
						<ListItem key={question.token} dense divider>
							<Grid container spacing={0}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<ListItemText>
										<strong>
											{index + 1}
											{'. '}
										</strong>
										{question.name} <br />
									</ListItemText>
									<ListItemSecondaryAction>
										{!sectionQuestionnaire.is_section_multiple && (
											<span
												style={{
													color: COLOR_STATUS_BLUE,
													fontWeight: 'bold',
													marginRight: '20px',
												}}
											>
												{' '}
												{`${question.percentage} %`}
											</span>
										)}
										<Tooltip title={<Translate id='common.delete' />}>
											<IconButton
												aria-label='delete'
												size='small'
												style={{ padding: '5px' }}
												onClick={() =>
													onRemoveQuestionInSection(
														sectionQuestionnaire.token,
														question.token
													)
												}
												disabled={isSavingTemplate}
											>
												<DeleteOutlinedIcon color='error' />
											</IconButton>
										</Tooltip>
										<Tooltip title={<Translate id='common.edit' />}>
											<IconButton
												aria-label='edit'
												size='small'
												style={{ padding: '5px' }}
												onClick={() =>
													onClickAddEditQuestion(
														sectionQuestionnaire.token,
														sectionQuestionnaire.is_section_multiple,
														question.token
													)
												}
												disabled={isSavingTemplate}
											>
												<EditIcon style={{ color: COLOR_STATUS_SUCCESS }} />
											</IconButton>
										</Tooltip>
									</ListItemSecondaryAction>
								</Grid>
							</Grid>
						</ListItem>
					)
				})}

				{!sectionQuestionnaire.is_section_multiple && (
					<ListItem key={sectionQuestionnaire.token} dense divider>
						<Grid container spacing={0}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<ListItemText>
									<strong>{<Translate id='questionnaire.maximumPoints' />} </strong>
								</ListItemText>
								<ListItemSecondaryAction>
									<span style={{ color: COLOR_STATUS_BLUE, fontWeight: 'bold', marginRight: '80px' }}>
										<strong> {`${sectionQuestionnaire.maximum_points} %`}</strong>
									</span>
								</ListItemSecondaryAction>
							</Grid>
						</Grid>
					</ListItem>
				)}
			</List>
		)
	}
}

QuestionsList.propTypes = {
	sectionQuestionnaire: PropTypes.object.isRequired,
	isSavingTemplate: PropTypes.bool.isRequired,
	translate: PropTypes.func,
	onClickAddEditQuestion: PropTypes.func.isRequired,
	onRemoveQuestionInSection: PropTypes.func.isRequired,
	onClickRemoveSection: PropTypes.func.isRequired,
	onClickAddEditSection: PropTypes.func.isRequired,
}

const styles = (theme) => ({
	textField: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit,
	},
})

export default withStyles(styles, { withTheme: true })(QuestionsList)
