/**Import react section */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select'

/**Import MaterialUi section */
import {
    Button, TextField, Grid, Typography
} from '@material-ui/core'
/**Import components section */
import DatePicker from '../../../common/DateTimePicker/DatePicker';
/**Import store section */
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { GetIndirectQualificationEvaluationType, GetMonths, GetYears } from '../../../../store/helpers/SelectOptions'

const CHARACTER_LIMIT_NAME = 100;

const AddComponent = (props) => {
    const { onSaveButtonClick, classes, translate
    } = props

    const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'))


    const [indirectQualificationModel, setIndirectQualificationModel] = useState({
        name: "",
        closingDate: moment(),
        startDate: moment(),
        evaluationType: null,
        shippingDate: null,
        month: null,
        year: null
    });

    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (IsNullOrEmpty(indirectQualificationModel.name)) return false
        if (indirectQualificationModel.evaluationType === null) return false
        if (indirectQualificationModel.shipphingDate === null) return false
        if (indirectQualificationModel.month === null) return false
        if (indirectQualificationModel.year === null) return false
        return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(indirectQualificationModel)
        }
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setIndirectQualificationModel({
            ...indirectQualificationModel,
            [name]: value
        });
    }

    /**On change properties */
    const onChangeSelect = (name, option) => {
        setIndirectQualificationModel({
            ...indirectQualificationModel,
            [name]: option
        });
    }

    const onChangeDate = (property, data) => {
        setIndirectQualificationModel({
            ...indirectQualificationModel,
            [property]: data
        });
    }

    return (
        <div>
            <div className={classes.containerInformation}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography color="primary">{<Translate id="indirectqualifications.create.selectedTypeEvaluation" />}</Typography>
                        <div className={classes.marginTop}>
                            <Select
                                value={indirectQualificationModel ? indirectQualificationModel.evaluationType : ""}
                                className={classes.documentTypeSelect}
                                options={GetIndirectQualificationEvaluationType()}
                                onChange={(option) => onChangeSelect("evaluationType", option)}
                                placeholder={<Translate id="indirectqualifications.create.selectedTypeEvaluation" />}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                                    menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    control: base => ({
                                        ...base,
                                        height: 50,
                                        minHeight: 50,
                                    })
                                }}
                                menuPortalTarget={document.parentNode}
                                menuPosition="fixed"
                                menuPlacement={'auto'}
                            />

                        </div>

                    </Grid>
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography color="primary">{<Translate id="indirectqualifications.create.name" />}</Typography>
                        <div className={classes.marginTop}>
                            <TextField
                                fullWidth
                                placeholder={translate("indirectqualifications.create.name")}
                                //label={<Translate id="indirectqualifications.create.name" />}
                                className={classes.textField}
                                name='name'
                                size="small"
                                onChange={onPropertyChange}
                                variant="outlined"
                                multiline
                                value={indirectQualificationModel ? indirectQualificationModel.name : ""}
                                InputProps={{
                                    inputProps: {
                                        maxLength: CHARACTER_LIMIT_NAME,
                                    }
                                }}
                                helperText={`${indirectQualificationModel ? indirectQualificationModel.name.length : ""}/${CHARACTER_LIMIT_NAME}`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography color="primary" className={classes.marginLeft}>{<Translate id="indirectqualifications.create.period" />}</Typography>

                        <Grid container spacing={8} className={classes.marginTop}>
                            <Grid item xs={6} className={classes.marginTop} >
                                <Typography color="primary" className={classes.marginLeft}>{<Translate id="indirectqualifications.create.month" />}</Typography>
                                <div className={classes.marginTop}>
                                    <Select
                                        value={indirectQualificationModel ? indirectQualificationModel.month : ""}
                                        className={classes.documentTypeSelect}
                                        options={GetMonths()}
                                        onChange={(option) => onChangeSelect("month", option)}
                                        placeholder={<Translate id="indirectqualifications.create.month" />}
                                        styles={{
                                            menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                                            menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            control: base => ({
                                                ...base,
                                                height: 50,
                                                minHeight: 50,
                                            })
                                        }}
                                        menuPortalTarget={document.parentNode}
                                        menuPosition="fixed"
                                        menuPlacement={'auto'}
                                    />

                                </div>
                            </Grid>
                            <Grid item xs={6} className={classes.marginTop}>
                                <Typography color="primary" className={classes.marginLeft}>{<Translate id="indirectqualifications.create.year" />}</Typography>
                                <div className={classes.marginTop}>
                                    <Select
                                        value={indirectQualificationModel ? indirectQualificationModel.year : ""}
                                        className={classes.documentTypeSelect}
                                        options={GetYears()}
                                        onChange={(option) => onChangeSelect("year", option)}
                                        placeholder={<Translate id="indirectqualifications.create.year" />}
                                        styles={{
                                            menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                                            menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            control: base => ({
                                                ...base,
                                                height: 50,
                                                minHeight: 50,
                                            })
                                        }}
                                        menuPortalTarget={document.parentNode}
                                        menuPosition="fixed"
                                        menuPlacement={'auto'}
                                    />
                                </div>
                            </Grid>

                        </Grid>

                    </Grid>

                    <Grid item xs={12} className={classes.marginTop}>
                        <br />
                        <Typography color="primary" className={classes.marginLeft}>{<Translate id="indirectqualifications.create.shipphingDate" />}</Typography>
                        <Grid item xs={12} >
                            <DatePicker
                                opens={"left"}
                                drops={"up"}
                                minDate={moment()}
                                onChangeRange={(event) => onChangeDate('shippingDate', event)}
                                startDate={indirectQualificationModel.shippingDate ? moment(indirectQualificationModel.shippingDate, 'DD/MM/YYYY') : ''}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <Grid container>
                <Grid item xs={12} className={classes.containerBtn}>
                    <Button
                        data-cy="btn-save"
                        type="button"
                        color="primary"
                        variant='outlined'
                        onClick={onSave}
                        className={classes.widthBtnSaved}
                        disabled={!canBeSaved() || isSaving}>
                        {<Translate id="common.save" />}
                    </Button>
                </Grid>
            </Grid>
        </div>

    )

}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${165}px)`,
        minHeight: `calc(100vh - ${165}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    marginTop: {
        marginTop: "10px",
    },
    marginLeft: {
        marginLeft: "6px",
    },
    widthBtnSaved: {
        minWidth: "400px"
    }
});

AddComponent.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddComponent.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddComponent))