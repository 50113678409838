import React, { Component } from 'react'
import autoBind from 'auto-bind'
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker'
import '../../../resources/styles/daterangepickerTime.css'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import { withLocalize, getTranslate } from 'react-localize-redux'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import { Button } from '@material-ui/core'

/**
 * Render
 *
 * @class DateTimePicker
 * @extends {Component}
 */
class DatePicker extends Component {
	/**
	 *Creates an instance of DatePicker.
	 * @param {*} props
	 * @memberof DatePicker
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 *
	 * Get the label contained in the date range picker content
	 *
	 * @returns
	 *
	 * @memberOf FiltersContainers
	 */
	getLabelRange() {
		if (this.props.startDate) {
			let start = this.props.startDate.format('DD/MM/YYYY')
			return start
		} else {
			return ''
		}
	}

	/**
	 * On change range
	 *
	 * @param {*} event
	 * @param {*} picker
	 * @memberof DateTimePicker
	 */
	onChangeRange(event, picker) {
		this.props.onChangeRange(picker.startDate)
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof DatePicker
	 */
	render() {
		return (
			<React.Fragment>
				<DatetimeRangePicker
					opens={this.props.opens}
					drops={this.props.drops}
					style={{ marginLeft: '5px' }}
					singleDatePicker
					showDropdowns
					startDate={this.props.startDate}
					onEvent={this.onChangeRange}
					minDate={this.props.minDate}
					maxDate={this.props.maxDate}
				>
					<div className='input-group'>
						<input
							type='text'
							disabled={this.props.disabled}
							className='form-control'
							value={this.getLabelRange()}
							readOnly
						/>
						{!this.props.notshowCalendar && (
							<span className='input-group-btn'>
								<Button variant='contained' color='primary' style={{ height: '100%' }}>
									<CalendarIcon style={{ color: '#fff' }} />
								</Button>
							</span>
						)}
					</div>
				</DatetimeRangePicker>
			</React.Fragment>
		)
	}
}

const styles = (theme) => ({})
const mapStateToProps = (state) => ({ translate: getTranslate(state.localize) })
export default withLocalize(withStyles(styles)(connect(mapStateToProps)(DatePicker)))
