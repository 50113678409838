import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import SearchInput from '../../common/SearchInput/SearchInput'

/** Material UI imports section */
import { withStyles } from '@material-ui/core/styles'
import { Card, CardHeader, Avatar, Fab, Typography, CardContent } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'
import EmployeesIcon from '@material-ui/icons/People'
/** Actions and helpers imports section */
import LoadingPage from '../../common/LoadingPage'
import DataTable from '../../common/DataTable'
import UsersLockIcon from '@material-ui/icons/PermIdentityTwoTone'
import Tooltip from '@material-ui/core/Tooltip'

import {
	CHANGE_EMPLOYEES_SORT_CRITERIA,
	CHANGE_EMPLOYEES_QUERY,
	getEmployeesCount,
	addEmployee,
	CHANGE_EMPLOYEES_PAGINATION,
} from '../../../store/contractors/EmployeesAction'
import AddEmployeeDialog from './AddEmployeeDialog'

import { loadEmployeesFromBackEnd } from './../../../store/contractors/EmployeesAction'
import Toaster from '../../common/Toaster'
import CardsIcon from '@material-ui/icons/AssignmentInd'
import OptionsMenu from '../../common/OptionsMenu'
import { loadSupplier } from '../../../store/suppliers/SupplierActions'

import {
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_WARNING,
} from '../../../store/helpers/StatusColorConstants'

/**
 * Class container to show employees index
 *
 * @class Index
 * @extends {Component}
 */
class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAddEmployeeModal: false,
			itemToken: null,
			showMenu: true,
			userEnabled: true,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadEmployees(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.state.userEnabled
			)
			this.props.getEmployeesCount(this.state.userEnabled)
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (prevProps.isLoadingEmployees !== this.props.isLoadingEmployees && this.props.supplier === null) {
			this.props.loadSupplier(this.props.user.profile.company_token)
		}
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadEmployees()
			this.props.getEmployeesCount(this.state.userEnabled)
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadEmployees(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.state.userEnabled
			)
		}
	}

	/**
	 * Switch view between user locks
	 *
	 * @memberof toggleViewUserLock
	 */
	toggleViewUserLock() {
		this.setState({ userEnabled: !this.state.userEnabled })
		this.props.loadEmployees(
			this.props.searchQuery,
			this.props.sortCriteria.by,
			this.props.sortCriteria.ascending,
			this.props.page,
			this.props.rowsPerPage,
			!this.state.userEnabled
		)
		this.props.getEmployeesCount(!this.state.userEnabled)
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "saveCorrectly"
	 *
	 * @param {string} [idTranslation="common.saveCorrectly"]
	 * @memberof Show
	 */
	showSuccessToaster(idTranslation = 'common.saveCorrectly') {
		this.setState({
			showToaster: true,
			toasterVariant: 'success',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "errorToSave"
	 *
	 * @param {string} [idTranslation="common.errorToSave"]
	 * @memberof Show
	 */
	showErrorToaster(idTranslation = 'common.errorToSave') {
		this.setState({
			showToaster: true,
			toasterVariant: 'error',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	handleShowAddEmployeeDialog() {
		this.setState({
			openAddEmployeeModal: !this.state.openAddEmployeeModal,
		})
	}

	handleOnAddEmployee(employee) {
		this.props
			.onAddEmployee(employee, this.props.supplier ? this.props.supplier.full_name : '')
			.then(() => {
				this.handleShowAddEmployeeDialog()
				this.showSuccessToaster()
			})
			.catch((result) => {
				if (result.status === 400) {
					this.setState({
						showToaster: true,
						toasterMessage: result.data.Message,
						toasterVariant: 'error',
					})
				} else {
					this.showErrorToaster()
				}
			})
	}

	handleClickToShowEmployeesCards() {
		this.props.history.push(`/employees/cards/`)
	}

	/**
	 * Handle on close optons menu to set void item token in state
	 *
	 * @memberof index
	 */
	handleCloseOptionsMenu() {
		this.setState(
			{
				itemToken: '',
				showMenu: false,
			},
			() => {
				this.setState({ showMenu: true })
			}
		)
	}

	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof InvestigationLines
	 */
	handleClickOptionsMenu(token) {
		this.setState({
			itemToken: token,
		})
	}

	/**
	 * Method to custom render in datalist
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof Index
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'is_active') {
			if (item.is_active) return <Translate id='contractorsModule.available' />
			else if (!item.is_active) return <Translate id='contractorsModule.disabled' />
		}
		if (dataSource === 'access_granted') {
			return (
				<Typography style={{ color: item.access_granted ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER }}>
					{
						<Translate
							id={
								item.access_granted
									? 'contractorsModule.accessGranted'
									: 'contractorsModule.accessDenied'
							}
						/>
					}
				</Typography>
			)
		}
		if (dataSource === 'message_access_granted') {
			if (item.access_granted) {
				return (
					<div style={{ width: '320px' }}>
						{item.detail_messages.map((message) => {
							return (
								<span>
									{message}
									<br />
								</span>
							)
						})}
						<span style={{ color: COLOR_STATUS_WARNING }}>{item.waring_incidence}</span>
					</div>
				)
			}
			if (!item.access_granted) {
				return (
					<div style={{ width: '320px' }}>
						{item.messages.map((message) => {
							return (
								<span>
									{message}
									<br />
								</span>
							)
						})}
						<span style={{ color: COLOR_STATUS_WARNING }}>{item.waring_incidence}</span>
					</div>
				)
			}
		}
	}

	/**
	 * On Render aditional row data
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof PurchaseOrders
	 */
	onRenderCellAditionalRowItem(dataSource, item) {
		if (dataSource === 'services_actives' && item[dataSource]) {
			return (
				<React.Fragment>
					<DataTable data={item[dataSource]} configuration={TableServices} />
				</React.Fragment>
			)
		}
	}

	onRequestClick(employee, event) {
		let tagName = event.target.tagName
		if (employee && tagName !== 'svg' && tagName !== 'BUTTON' && tagName !== 'path') {
			this.props.history.push(`/employees/${employee.token}`)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingEmployees === true) {
			return <LoadingPage />
		} else if (this.props.employees === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.employees}
					configuration={TableConfiguration}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(event, employee) => {
						this.onRequestClick(event, employee)
					}}
					showFilters
					isIndex
					totalRows={this.props.employeesCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
					collapsableChild
					aditionalRows={AditionalRows}
					onRenderCellAditionalRowItem={this.onRenderCellAditionalRowItem}
				/>
			)
		}
	}

	/**
	 * Get material deliverie's counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		if (this.props.employees) {
			let totalEmployees = this.props.employees.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalEmployees}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.employeesCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 *Render action button in card quality failure report
	 *
	 * @memberof Index
	 */
	renderAction() {
		var options = []
		options.push({
			itemClick: () => this.handleClickToShowEmployeesCards(),
			tooltipTranslation: <Translate id='contractorsModule.showAccessCards' />,
			menuItemIcon: <CardsIcon color='secondary' />,
			isLoading: this.props.isLoading,
		})
		if (this.state.showMenu) {
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === 'item.token'}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
				/>
			)
		}
	}

	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<EmployeesIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>

							<Fab
								size='small'
								color='primary'
								className={classes.categoryIcon}
								onClick={() => {
									this.setState({ openAddEmployeeModal: true })
								}}
							>
								<AddIcon />
							</Fab>

							<Tooltip
								title={
									<Translate
										id={
											this.state.userEnabled
												? 'contractorsModule.employeesLocked'
												: 'contractorsModule.employeeActive'
										}
									/>
								}
							>
								<Fab
									className={classes.categoryIcon}
									size='small'
									onClick={() => {
										this.toggleViewUserLock()
									}}
								>
									{this.state.userEnabled ? <UsersLockIcon /> : <EmployeesIcon color='secondary' />}
								</Fab>
							</Tooltip>

							{this.renderAction()}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.employees' />
						</Typography>
					}
					subheader={this.getCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>
				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
				<AddEmployeeDialog
					open={this.state.openAddEmployeeModal}
					isSaving={this.props.isSavingEmployee}
					addEmployee={this.handleOnAddEmployee}
					onClose={this.handleShowAddEmployeeDialog}
					selectedCompany={this.props.selectedCompany}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'expand',
		},
		{
			header: <Translate id='users.name' />,
			dataSource: 'name',
		},
		{
			header: <Translate id='contractorsModule.socialSecurityNumber' />,
			dataSource: 'insurance_number',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'is_active',
		},
		{
			header: 'Acceso',
			dataSource: 'access_granted',
		},
		{
			header: 'Mensajes',
			dataSource: 'message_access_granted',
		},
	],
}

/** Configuration to show additional rows */
const AditionalRows = {
	rows: [
		{
			dataSource: 'services_actives',
			type: 'dataList',
		},
	],
}

const TableServices = {
	columns: [
		{
			header: 'Servicio',
			dataSource: 'Description',
		},
		{
			header: 'Orden de compra',
			dataSource: 'PurchaseOrder',
		},
		{
			header: 'Fecha inicio',
			dataSource: 'StartDate',
		},
		{
			header: 'Feca fin',
			dataSource: 'EndDate',
		},
		{
			header: 'Responsable',
			dataSource: 'Responsible',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingEmployees: state.contractorEmployees.get('isLoadingEmployees'),
		isSavingEmployee: state.contractorEmployees.get('isSavingEmployee'),
		employees: state.contractorEmployees.get('employees')
			? state.contractorEmployees.get('employees').toJS()
			: null,
		employeesCount: state.contractorEmployees.get('employeesCount'),
		sortCriteria: state.contractorEmployees.get('sortCriteria')
			? state.contractorEmployees.get('sortCriteria').toJS()
			: null,
		searchQuery: state.contractorEmployees.get('searchQuery'),
		translate: getTranslate(state.localize),
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		page: state.contractorEmployees.get('page'),
		rowsPerPage: state.contractorEmployees.get('rowsPerPage'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_EMPLOYEES_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_EMPLOYEES_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_EMPLOYEES_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		loadEmployees: (query, orderBy, sortAscending, page, rowsPerPage, isActive) => {
			dispatch(loadEmployeesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, isActive))
		},
		getEmployeesCount: (isActive) => {
			dispatch(getEmployeesCount(isActive))
		},
		onAddEmployee: (employee, supplierName) => {
			return dispatch(addEmployee(employee, supplierName))
		},
	}
}

const styles = (theme) => ({
	avatar: {},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	categoryIcon: {
		marginLeft: '5px',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
