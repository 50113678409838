/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'
import { changeSeparatorCharacter } from '../helpers/StringHelper'

export const START_LOADING_PROVEDURES = 'START_LOADING_PROVEDURES'
export const SET_PROVEDURES_LIST = 'SET_PROVEDURES_LIST'
export const CHANGE_PROVEDURES_SORT_CRITERIA = 'CHANGE_PROVEDURES_SORT_CRITERIA'
export const CHANGE_PROVEDURES_QUERY = 'CHANGE_PROVEDURES_QUERY'
export const CHANGE_PROVEDURES_PAGINATION = 'CHANGE_PROVEDURES_PAGINATION'
export const SET_PROVEDURES_COUNT = 'SET_PROVEDURES_COUNT'
export const IS_LOADING_PROVEDURE = 'IS_LOADING_PROVEDURE'
export const GET_PROVEDURE_IN_DRAFT = 'GET_PROVEDURE_IN_DRAFT'
export const SET_PROVEDURE_ITEM_SELECTED = 'SET_PROVEDURE_ITEM_SELECTED'
export const UPDATE_DETAIL_PLANE = 'UPDATE_DETAIL_PLANE'
export const ADD_ITEM_FROM_PROVEDURE = 'ADD_ITEM_FROM_PROVEDURE'
export const IS_SAVING_PROVEDURE = 'IS_SAVING_PROVEDURE'
export const SET_ALTERNATIVE_PROVEDURE = 'SET_ALTERNATIVE_PROVEDURE'
export const REMOVE_DETAIL_ITEM_FROM_PROVEDURE = 'REMOVE_DETAIL_ITEM_FROM_PROVEDURE'
export const FINALIZE_PROVEDURE = 'FINALIZE_PROVEDURE'
export const UPDATE_COMMENTS_ALTERNATIVE_PROVEDURE = 'UPDATE_COMMENTS_ALTERNATIVE_PROVEDURE'
export const ADD_FILE_ALTERNATIVE_PROVEDURE = 'ADD_FILE_ALTERNATIVE_PROVEDURE'
export const REMOVE_FILE_ALTERNATIVE_PROVEDURE = 'REMOVE_FILE_ALTERNATIVE_PROVEDURE'
export const SET_REACTIVE_QUOTE_IN_ALTERNATIVE = 'SET_REACTIVE_QUOTE_IN_ALTERNATIVE'
export const IS_DOWNLOADING_DRAFT = 'IS_DOWNLOADING_DRAFT'
/**
 * Action start loading provedures
 * @param {*} isLoading
 */
function isLoadingProvedures(isLoading) {
	return {
		type: START_LOADING_PROVEDURES,
		isLoading: isLoading,
	}
}

/**
 * Action to set provedures list
 * @param {*} provedures
 */
function setProveduresList(provedures) {
	return {
		type: SET_PROVEDURES_LIST,
		provedures: provedures,
	}
}

/**
 * Action reducer to set the provedurie's counter
 *
 * @param {*} count
 * @returns
 */
function setProveduriesCount(count) {
	return {
		type: SET_PROVEDURES_COUNT,
		count: count,
	}
}

/**
 * Action start loading provedures
 * @param {*} isLoading
 */
function isLoadingProvedure(isLoading) {
	return {
		type: IS_LOADING_PROVEDURE,
		isLoading: isLoading,
	}
}
function isDownloadingDraft(isDownloadingDraft) {
	return {
		type: IS_DOWNLOADING_DRAFT,
		isDownloadingDraft,
	}
}

/**
 * Action load alternative proveduria in draft
 * @param {*} provedureDraft
 */
function loadAlternativeProveduriaInDraft(provedureDraft) {
	return {
		type: GET_PROVEDURE_IN_DRAFT,
		provedureDraft: provedureDraft,
	}
}

/**
 * Action to upate plot
 * @param {*} detailPlane
 */
function updateDetailSchematic(detailPlane) {
	return {
		type: UPDATE_DETAIL_PLANE,
		detailPlane: detailPlane,
	}
}

/**
 * Action start saving provedures
 * @param {*} isSaving
 */
function isSavingProvedureDraft(isSaving) {
	return {
		type: IS_SAVING_PROVEDURE,
		isSaving: isSaving,
	}
}

/**
 * Action to get informaiton from provedure
 * @param {*} memo
 */
function setAlternativeProvedure(provedure) {
	return {
		type: SET_ALTERNATIVE_PROVEDURE,
		provedure: provedure,
	}
}

/**
 * Action finalize a provedure
 */
function finalizeProvedure() {
	return {
		type: FINALIZE_PROVEDURE,
	}
}

/**
 * Action to update comments of part number in details list
 *
 * @param {*} partNumberDetail
 * @returns
 */
function updateComments(partNumberDetail) {
	return {
		type: UPDATE_COMMENTS_ALTERNATIVE_PROVEDURE,
		partNumberDetail,
	}
}

/**
 * Add file
 *
 * @param {*} files
 * @returns
 */
function addFile(files, tokenPartNumber) {
	return {
		type: ADD_FILE_ALTERNATIVE_PROVEDURE,
		files,
		tokenPartNumber,
	}
}

/**
 * Remove file
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName, tokenPartNumber) {
	return {
		type: REMOVE_FILE_ALTERNATIVE_PROVEDURE,
		fileName,
		tokenPartNumber,
	}
}

/**
 * Get all alternative provedures by company from backend
 */
export function loadProveduresFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingProvedures(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure/proveduries`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProveduresList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingProvedures(false))
				console.error('Error getting the alternative provedures by company list', error.response)
			})
	}
}

/**
 * Get proveduries's count
 *
 * @export
 * @returns
 */
export function getProveduresCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().alternativeProvedures.get('proveduresCount')
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setProveduriesCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the provedure's count", error.response)
			})
	}
}

/**
 * Action to initial alternative provedure
 */
export function initialAlternativeProvedure() {
	return (dispatch, getState) => {
		dispatch(isLoadingProvedure(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure/initial`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(loadAlternativeProveduriaInDraft(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingProvedure(false))
				console.error('Error adding a alternative provedure', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add item in alternative proveduria
 * @param {*} item
 */
export function addItemInAlternativeProveduria(provedure, item, supplierSelected) {
	return (dispatch, getState) => {
		dispatch(isSavingProvedureDraft(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			CompanyToken: provedure.company_token,
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CenterInitial: selectedCompany.get('center'),
			CenterFin: selectedCompany.get('center_fin'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			CreateEmail: typeof user.profile.email === 'string' ? user.profile.email : user.profile.email[0],

			SupplierToken: supplierSelected ? supplierSelected.token : provedure.supplier_token,
			SupplierName: supplierSelected ? supplierSelected.name : provedure.supplier_name,
			SupplierNumber: supplierSelected ? supplierSelected.code : provedure.supplier_code,
			SupplierCountry: supplierSelected ? supplierSelected.country : provedure.country,

			RequieredQuote: item.quote,
			RequieredSample: item.sample,
			RequieredPtmRmi: item.rmi,
			PartNumber: item.partNumber,
			SampleNumber: item.sampleNumber,
			Volumen: item.volumen,
			VolumenUnit: item.volumnetMeaurement,
			Commnets: item.observations,
			PartDescription: item.partDescription,
			Revise: item.revise,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingProvedureDraft(false))
					dispatch(loadAlternativeProveduriaInDraft(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingProvedureDraft(false))
				console.error('Error adding a quote for a memo', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Get the selected item from a provedure item details
 * @param {*} state
 */
export const getSelectedProvedureItem = (state) => {
	let provedure = state.alternativeProvedures.get('provedureDraft')
	if (provedure) {
		let selectedProveureItem = provedure.get('details').find((detail) => {
			return detail.get('is_selected') === true
		})
		if (selectedProveureItem) {
			return selectedProveureItem.toJS()
		} else {
			return null
		}
	} else {
		return null
	}
}

/**
 * Action to update detal part number plane
 * @param {*} detailToken
 * @param {*} planeName
 */
export function updateDetailPartnumberPlaneName(detailToken, planeName) {
	return (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partNumbers/plots`
		let data = {
			Name: planeName,
			TokenPartNumber: detailToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateDetailSchematic(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upate plot', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action from send quotes and sample request from alternative provedurie
 */
export function sendQuotesRequestFromAlternative() {
	return (dispatch, getState) => {
		dispatch(isSavingProvedureDraft(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')

		let token = getState().alternativeProvedures.get('provedureDraft').get('token')
		let documentIdentifier = selectedCompany.get('document_identifier')
		let data = {}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure/${token}/${documentIdentifier}/send`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingProvedureDraft(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in send quotes and a sample request from alternative provedurie', error.response)
				dispatch(isSavingProvedureDraft(false))
				return Promise.reject()
			})
	}
}

/**
 * Get and set the alternative provedurie in the estate from read from backend
 * @param {*} token
 */
export function loadAlternativeProvedureeFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingProvedure(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternative/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setAlternativeProvedure(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load lternative provedure', error.response)
				dispatch(isLoadingProvedure(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to update alternative provedurie properties
 * @param {*} proveedurie
 */
export function updateAlternativeProvedureProperty(token, deadlineDelivery, typeProvedurie) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure/${token}`
		let data = {
			DeadlineDelivery: deadlineDelivery,
			AlternatingProveeduriaType: typeProvedurie,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(loadAlternativeProveduriaInDraft(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to saving provedurie', error.response)
				return Promise.reject()
			})
	}
}

/**
 * ction to remive item in proveduria
 */
export function removeItemInAlternativeProveduria(itemToken, supplierSelected) {
	return (dispatch, getState) => {
		dispatch(isSavingProvedureDraft(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let tokenProveduria = getState().alternativeProvedures.get('provedureDraft').get('token')
		let supplierToken = supplierSelected
			? supplierSelected.token
			: getState().alternativeProvedures.get('provedureDraft').get('supplier_token')
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure/${tokenProveduria}/details/${itemToken}/supplier/${supplierToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingProvedureDraft(false))
					dispatch(loadAlternativeProveduriaInDraft(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingProvedureDraft(false))
				console.error('Error removing a item from a provedureDraft', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Finalize the proccess from alternative proveduria
 */
export function finalizeProvedureFromBackend(tokenProvedure) {
	return function (dispatch, getState) {
		dispatch(isSavingProvedureDraft(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/projectdocument/closedproject/${tokenProvedure}`

		return axios
			.put(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(finalizeProvedure())
				}
			})
			.catch((error) => {
				console.error('Error finalize provedure', error.response)
				dispatch(isSavingProvedureDraft(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to add comment
 *
 * @export
 * @param {*} item
 * @param {*} comments
 * @returns
 */
export function onAddComment(item, comments, requieredPlotArt) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			Description: comments,
			PartNumberToken: item.token,
			RequieredPlotArt: requieredPlotArt,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/project`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateComments(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding comments', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to upload files
 *
 * @export
 * @param {*} files
 * @returns
 */
export function onAddFiles(files, tokenPartNumber) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumbers/upload/${tokenPartNumber}`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse.data, tokenPartNumber))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error to upload file(s) in material delivery', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files
 *
 * @export
 * @param {*} fileName
 * @returns
 */
export function onRemoveFiles(fileName, tokenPartNumber) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/remove/${tokenPartNumber}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFile(fileName, tokenPartNumber))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add containment action to sac
 *
 * @export
 * @param {*} sacToken
 * @param {*} actionData
 * @returns
 */
export function importMaterialListOfAlternativeProveduria(provedure, attachments) {
	return (dispatch, getState) => {
		dispatch(isSavingProvedureDraft(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)

		let formData = new FormData()
		formData.set('Token', provedure.token)
		formData.set('CenterInitial', selectedCompany.get('center'))
		formData.set('CenterFin', selectedCompany.get('center_fin'))

		formData.set('SupplierToken', provedure.supplier_token)
		formData.set('SupplierName', provedure.supplier_name)
		formData.set('SupplierNumber', provedure.supplier_code)
		formData.set('SupplierCountry', provedure.country)

		for (var index = 0; index < attachments.length; index++) {
			formData.append('file', attachments[index])
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure/detailsimport`
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingProvedureDraft(false))
					dispatch(loadAlternativeProveduriaInDraft(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding containment action to sac', error.response)
				dispatch(isSavingProvedureDraft(false))
				return Promise.reject()
			})
	}
}

/**
 *Action to download report alternative proveduria by range date
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadAlternativeProveduriaReportByRangeDate(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(isLoadingProvedure(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure/downloadByRangeDate`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isLoadingProvedure(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_provedurias.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isLoadingProvedure(false))
				console.error('Error downloading alternative proveduria report by month', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action from resend alternative proveduria from new supplier
 * @param {*} tokenProvedure
 * @param {*} supplier
 */
export function resendAlternativeProveduriaFromBackend(tokenProvedure, supplier) {
	return function (dispatch, getState) {
		dispatch(isSavingProvedureDraft(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure/resendnewsupplier`
		let data = {
			ProveduriaToken: tokenProvedure,
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			SupplierToken: supplier.token,
			SupplierName: supplier.name,
			SupplierNumber: supplier.code,
			SupplierCountry: supplier ? supplier.country : '',
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			CreateEmail: typeof user.profile.email === 'string' ? user.profile.email : user.profile.email[0],
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingProvedureDraft(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error resend provedure from nw supplier', error.response)
				dispatch(isSavingProvedureDraft(false))
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action from remove all items in draft in proveduria
 */
export function removeAllDetailsInProveduriaDraft() {
	return (dispatch, getState) => {
		dispatch(isSavingProvedureDraft(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let token = getState().alternativeProvedures.get('provedureDraft').get('token')

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure/${token}/remove`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingProvedureDraft(false))
					dispatch(loadAlternativeProveduriaInDraft(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in remove all details in draft alternative provedurie', error.response)
				dispatch(isSavingProvedureDraft(false))
				return Promise.reject()
			})
	}
}

export function downloadAlternativeProvedureDraft(provedureDraft) {
	return (dispatch, getState) => {
		dispatch(isDownloadingDraft(true))
		let supplierPA = changeSeparatorCharacter(provedureDraft.supplier_name.replace(',', '').split('|')[1].trim())
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/alternativeprovedure/detailsexport/draft/${provedureDraft.token}`

		return axios
			.post(endPoint, null, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDownloadingDraft(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Borrador_PA_${supplierPA}.xlsx`
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDownloadingDraft(false))
				console.error('Error downloading alternative provedure draft', error.response)
				return Promise.reject()
			})
	}
}
