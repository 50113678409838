/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'

export const CHANGE_CONTRACTS_SORT_CRITERIA = 'CHANGE_CONTRACTS_SORT_CRITERIA'
export const CHANGE_CONTRACTS_SORT_QUERY = 'CHANGE_CONTRACTS_SORT_QUERY'
export const CHANGE_CONTRACTS_PAGINATION = 'CHANGE_CONTRACTS_PAGINATION'

export const START_LOADING_CONTRACTS = 'START_LOADING_CONTRACTS'
export const SET_CONTRACTS_LIST = 'SET_CONTRACTS_LIST'
export const SET_CONTRACTS_COUNT = 'SET_CONTRACTS_COUNT'
export const IS_SAVING_CONTRACT = 'IS_SAVING_CONTRACT'
export const ADD_CONTRACT_TO_LIST = 'ADD_CONTRACT_TO_LIST'
export const REMOVE_CONTRACT_TO_LIST = 'REMOVE_CONTRACT_TO_LIST'

export const IS_LOADING_SUPPLIER_CONTRACT = 'IS_LOADING_SUPPLIER_CONTRACT'
export const SET_SUPPLIER_CONTRACT = 'SET_SUPPLIER_CONTRACT'
export const IS_DOWNLOAD_REPORT_CONTRACT = 'IS_DOWNLOAD_REPORT_CONTRACT'

/**
 * Action start loading contracts
 * @param {*} isLoading
 */
function isLoadingContracts(isLoading) {
	return {
		type: START_LOADING_CONTRACTS,
		isLoading: isLoading,
	}
}

/**
 * Action to set contracts list
 * @param {*} contracts
 */
function setContractsList(contracts) {
	return {
		type: SET_CONTRACTS_LIST,
		contracts: contracts,
	}
}

/**
 * Action to set contracts count
 * @param {*} count
 */
function setContractsCount(count) {
	return {
		type: SET_CONTRACTS_COUNT,
		count,
	}
}

/**
 * Action to start load contract
 */
function isSavingContract(isSaving) {
	return {
		type: IS_SAVING_CONTRACT,
		isSaving: isSaving,
	}
}

/**
 * remove contract in list
 * @param {*} contractToken
 */
function removeContractToList(contractToken) {
	return {
		type: REMOVE_CONTRACT_TO_LIST,
		contractToken,
	}
}

/**
 * Action to start load supplier contractor
 */
function startLoadSupplierContractor(isLoading) {
	return {
		type: IS_LOADING_SUPPLIER_CONTRACT,
		isLoading: isLoading,
	}
}

/**
 * Action to set supplier contractor
 * @param {*} officialNotice
 */
function setSupplierContractor(supplierContractor) {
	return {
		type: SET_SUPPLIER_CONTRACT,
		supplierContractor: supplierContractor,
	}
}

function isDownloading(isDownloading) {
	return {
		type: IS_DOWNLOAD_REPORT_CONTRACT,
		isDownloading,
	}
}

/**
 * Action load contracts from company backend
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @param {*} page
 * @param {*} rowsPerPage
 */
export function loadContractsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, documentType) {
	return function (dispatch, getState) {
		dispatch(isLoadingContracts(true))
		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract/documentType`
			let data = {
				CompanyToken: selectedCompany.get('token'),
				DocumentType: documentType,
				SortBy: orderBy,
				SortAscending: sortAscending,
				Query: query,
				Page: `${page + 1}`,
				RowsPerPage: rowsPerPage,
				CreatedBy: user.profile.name,
				UserName: user.profile.username,
				UserId: user.profile.sub,
			}
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setContractsList(serverResponse.data))
					}
				})
				.catch((error) => {
					dispatch(isLoadingContracts(false))
					console.error('Error getting contracts list by company', error.response)
				})
		} else {
			dispatch(isLoadingContracts(false))
		}
	}
}
/**
 * Action to get supplier contracts count
 */
export function getContractsCount(documentType) {
	return function (dispatch, getState) {
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			DocumentType: documentType,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setContractsCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error getting the supplier contracts count', error.response)
			})
	}
}

/**
 * Action to create company
 * @param {*} company
 */
export function createContractData(contract) {
	return function (dispatch, getState) {
		dispatch(isSavingContract(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract`
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let formData = new FormData()
		contract.attachments.forEach((file) => {
			formData.append('file', file)
		})
		formData.set('CompanyToken', companyToken)
		formData.set('SupplierCode', contract && contract.supplierSelected ? contract.supplierSelected.code : '')
		formData.set('Name', contract.name)
		formData.set('StartDate', contract.activationDate.format('DD/MM/YYYY'))
		formData.set('EndDate', contract.expirationDate.format('DD/MM/YYYY'))
		formData.set('Notes', contract.notes)
		formData.set('CreatorName', user.profile.name)
		formData.set('RequestedBy', contract.requestedBy)
		formData.set('TypeDeal', contract.typeDeal)
		formData.set('Currency', contract.typeOfCurrency)
		formData.set('Legal', contract.legal)
		formData.set('Language', contract.language)
		formData.set('DaysToExpire', contract.daysToExpire)
		formData.set('Repse', contract.repse)
		formData.set('ContrapartEmployees', contract.contrapartEmployees)
		formData.set('CounterpartEmployerRegistration', contract.counterpartEmployerRegistration)
		formData.set('CounterpartRFC', contract.counterpartRFC)
		formData.set('PersonnelServiceAvailable', contract.personnelServiceAvailable)
		formData.set('IsFilial', contract.isFilial)
		formData.set('PersonnelEntersPlaceToPerformServices', contract.personnelEntersPlaceToPerformServices)
		formData.set('DocumentType', contract.documentType)
		formData.set('DescriptionOfServices', contract.descriptionOfServices)
		formData.set('Department', contract.department)
		formData.set('Consideration', contract.consideration)
		formData.set('WayToPay', contract.wayToPay)
		formData.set('SpecialConditions', contract.specialConditions)
		formData.set('ContractMotive', contract.contractMotive)
		formData.set('signature', contract.signature)
		formData.set('clientName', contract.clientName)
		formData.set('clientCode', contract.clientCode)
		formData.set('isSupplier', contract.isSupplier)
		formData.set('isNewSupplier', contract.isNewSupplier)

		return axios
			.post(endPoint, formData, configuration)
			.then(() => {
				dispatch(isSavingContract(false))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error supplier contract', error.response)
				dispatch(isSavingContract(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to create company
 * @param {*} company
 */
export function requestContractData(contract) {
	return function (dispatch, getState) {
		dispatch(isSavingContract(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/requestcontract`
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let formData = new FormData()
		contract.attachments.forEach((file) => {
			formData.append('file', file)
		})
		formData.set('CompanyToken', companyToken)
		formData.set('SupplierCode', contract ? contract.supplierSelected.code : '')
		formData.set('Name', contract.name)
		formData.set('StartDate', contract.activationDate.format('DD/MM/YYYY'))
		formData.set('EndDate', contract.expirationDate.format('DD/MM/YYYY'))
		formData.set('Notes', contract.notes)
		formData.set('CreatorName', user.profile.name)
		formData.set('DocumentType', contract.documentType)
		formData.set('RequestedBy', contract.requestedBy)

		return axios
			.post(endPoint, formData, configuration)
			.then(() => {
				dispatch(isSavingContract(false))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error supplier contract', error.response)
				dispatch(isSavingContract(false))
				return Promise.reject()
			})
	}
}

export function updateSupplierContract(contract, token) {
	return function (dispatch, getState) {
		dispatch(isSavingContract(true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/updatesuppliercontract`
		let formData = new FormData()

		formData.set('Token', token)
		formData.set('RequestedBy', contract.requestedBy)
		formData.set('TypeDeal', contract.typeDeal)
		formData.set('Currency', contract.typeOfCurrency)
		formData.set('Legal', contract.legal)
		formData.set('Language', contract.language)
		formData.set('DaysToExpire', contract.daysToExpire)
		formData.set('Repse', contract.repse)
		formData.set('ContrapartEmployees', contract.contrapartEmployees)
		formData.set('CounterpartEmployerRegistration', contract.counterpartEmployerRegistration)
		formData.set('CounterpartRFC', contract.counterpartRFC)
		formData.set('PersonnelServiceAvailable', contract.personnelServiceAvailable)
		formData.set('IsFilial', contract.isFilial)
		formData.set('PersonnelEntersPlaceToPerformServices', contract.personnelEntersPlaceToPerformServices)
		formData.set('DescriptionOfServices', contract.descriptionOfServices)
		formData.set('Department', contract.department)
		formData.set('Consideration', contract.consideration)
		formData.set('WayToPay', contract.wayToPay)
		formData.set('SpecialConditions', contract.specialConditions)
		formData.set('ContractMotive', contract.contractMotive)

		return axios
			.post(endPoint, formData, configuration)
			.then(() => {
				dispatch(isSavingContract(false))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error supplier contract', error.response)
				dispatch(isSavingContract(false))
				return Promise.reject()
			})
	}
}

export function editSupplierContract(contract, token) {
	return function (dispatch, getState) {
		dispatch(isSavingContract(true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/editsuppliercontract`
		let formData = new FormData()

		formData.set('Token', token)
		formData.set('RequestedBy', contract.requestedBy)
		formData.set('TypeDeal', contract.typeDeal)
		formData.set('Currency', contract.typeOfCurrency)
		formData.set('Legal', contract.legal)
		formData.set('Language', contract.language)
		formData.set('DaysToExpire', contract.daysToExpire)
		formData.set('Repse', contract.repse)
		formData.set('ContrapartEmployees', contract.contrapartEmployees)
		formData.set('CounterpartEmployerRegistration', contract.counterpartEmployerRegistration)
		formData.set('CounterpartRFC', contract.counterpartRFC)
		formData.set('PersonnelServiceAvailable', contract.personnelServiceAvailable)
		formData.set('IsFilial', contract.isFilial)
		formData.set('PersonnelEntersPlaceToPerformServices', contract.personnelEntersPlaceToPerformServices)
		formData.set('DescriptionOfServices', contract.descriptionOfServices)
		formData.set('Department', contract.department)
		formData.set('Consideration', contract.consideration)
		formData.set('WayToPay', contract.wayToPay)
		formData.set('SpecialConditions', contract.specialConditions)
		formData.set('ContractMotive', contract.contractMotive)

		return axios
			.post(endPoint, formData, configuration)
			.then((response) => {
				dispatch(setSupplierContractor(response.data))
				dispatch(isSavingContract(false))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error supplier contract', error.response)
				dispatch(isSavingContract(false))
				return Promise.reject()
			})
	}
}

/**
 * Remove supplier contract
 * @param {*} contractToken
 */
export function removeSupplierContract(contractToken) {
	return function (dispatch, getState) {
		dispatch(isSavingContract(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract/${contractToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingContract(false))
					dispatch(removeContractToList(contractToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingContract(false))
				console.error('Error in remove contract token', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Get and set the supplier contractor in the estate from read from backend
 * @param {*} token
 */
export function loadSupplierContractor(token) {
	return function (dispatch, getState) {
		dispatch(startLoadSupplierContractor(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract/history/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierContractor(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load official notice', error.response)
				dispatch(startLoadSupplierContractor(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to create company
 * @param {*} company
 */
export function addSupplierContractDetail(contract, contractToken) {
	return function (dispatch, getState) {
		dispatch(isSavingContract(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract/detail`
		let formData = new FormData()
		contract.attachments.forEach((file) => {
			formData.append('file', file)
		})
		console.log("contract", contract)
		formData.set('ContractToken', contractToken)
		formData.set('Name', contract.name)
		formData.set('StartDate', contract.activationDate.format('DD/MM/YYYY'))
		formData.set('EndDate', contract.expirationDate.format('DD/MM/YYYY'))
		formData.set('Notes', contract.notes)
		formData.set('ContractType', contract.contractType)
		formData.set('CreatorName', user.profile.name)

		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierContractor(serverResponse.data))
				}
				dispatch(isSavingContract(false))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error add new supplier contract', error.response)
				dispatch(isSavingContract(false))
				return Promise.reject()
			})
	}
}

export function editContractDetail(contractToken, contract) {
	return function (dispatch, getState) {
		console.log("CONTRACT", contract)
		dispatch(isSavingContract(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract/detail`
		let formData = new FormData()
		contract.attachments.forEach((file) => {
			formData.append('file', file)
		})
		formData.set('ContractToken', contractToken ? contractToken : '')
		formData.set('DetailToken', contract.token ? contract.token : '')
		formData.set('Name', contract.name ? contract.name : '')
		formData.set(
			'StartDate',
			contract.update_start_date ? contract.start_date.format('DD/MM/YYYY') : contract.start_date
		)
		formData.set('EndDate', contract.update_end_date ? contract.end_date.format('DD/MM/YYYY') : contract.end_date)
		formData.set('Notes', contract.notes ? contract.notes : '')
		formData.set('CreatorName', user.profile.name ? user.profile.name : '')
		formData.set('IsContractMain', contract.is_contract_main ? contract.is_contract_main : false)
		formData.set('ContractType', contract.contractType ? contract.contractType : 0)

		return axios
			.put(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierContractor(serverResponse.data))
				}
				dispatch(isSavingContract(false))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error add new supplier contract', error.response)
				dispatch(isSavingContract(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to download reporte supplier contracts
 */
export function downloadReportSupplierContractors(documentType) {
	return (dispatch, getState) => {
		dispatch(isDownloading(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract/downloadreport/${companyToken}/${documentType}`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDownloading(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_contratos_general.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDownloading(false))
				console.error('Error downloading report contracts', error.response)
				return Promise.reject()
			})
	}
}

export function downloadReportBySupplier(token) {
	return (dispatch, getState) => {
		dispatch(isDownloading(true))
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract/downloadreport/${token}/supplier`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDownloading(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_por contrato.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDownloading(false))
				console.error('Error downloading report contracts', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Remove supplier contract
 * @param {*} contractToken
 */
export function removeContractDetail(contractToken, detailToken) {
	return function (dispatch, getState) {
		dispatch(isSavingContract(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract/${contractToken}/detail/${detailToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingContract(false))
					dispatch(setSupplierContractor(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingContract(false))
				console.error('Error in remove contract token', error.response)
				return Promise.reject()
			})
	}
}

export function addContractUser(contractToken, userName, name) {
	return function (dispatch, getState) {
		dispatch(isSavingContract(true))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract/users`
		let formData = new FormData()
		formData.set('ContractToken', contractToken)
		formData.set('UserName', userName)
		formData.set('Name', name)

		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierContractor(serverResponse.data))
				}
				dispatch(isSavingContract(false))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error add new user contract', error.response)
				dispatch(isSavingContract(false))
				return Promise.reject()
			})
	}
}

export function removeContractUser(contractToken, userName) {
	return function (dispatch, getState) {
		dispatch(isSavingContract(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliercontract/${contractToken}/users/${userName}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierContractor(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingContract(false))
				console.error('Error in remove user contract token', error.response)
				return Promise.reject()
			})
	}
}
