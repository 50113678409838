import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

/** Material UI import section */
import { Tabs, Tab, withStyles } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'

/** Custom components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import RetentionPercentageTab from './RetentionPercentageTab'

/** Actions import section */
import {
	updateSettingValue,
	addWorkshopToRetention,
	removeWorkshopFromRetention,
	loadAccreditedWorkshops,
} from '../../../../store/accreditedWorkshops/accreditedWorkshopsActions'
import {
	getAccreditedWorkshopsSelector,
	getSettingsSelector,
} from '../../../../store/accreditedWorkshops/accreditedWorkshopsSelectors'
import {
	FREIGHT_RETENTION_PERCENTAGE,
	SERVICE_RETENTION_PERCENTAGE,
} from '../../../../store/helpers/AccreditedWorkshopsHelper'
//import { mockTechnicians } from '../../../../store/accreditedWorkshops/mockData';

const RetentionPercentagesPanel = (props) => {
	/** Destructuring properties */
	const { classes, onClose, configuration, rfc } = props

	/** Defines local state */
	const [activeTab, setActiveTab] = useState(tabs.ServiceRetention)
	const [loadingWorkshops, setLoadingWorkshops] = useState(true)

	/** Connect with redux */
	const dispatch = useDispatch()
	const settings = useSelector((state) => getSettingsSelector(state))
	const allWorkshops = useSelector((state) => getAccreditedWorkshopsSelector(state))

	useEffect(() => {
		// setLoadingWorkshops(true);
		dispatch(loadAccreditedWorkshops()).finally(() => {
			setLoadingWorkshops(false)
		})
	}, [dispatch])

	const suppliers = allWorkshops
		.map(({ sapCode, name }) => ({ value: sapCode, label: `${sapCode} - ${name}` }))
		.reduce((acc, supplier) => {
			if (acc.some((i) => i.value === supplier.value)) return acc
			acc.push(supplier)
			return acc
		}, [])

	/**
	 * Handle the change of tab
	 */
	const chandleTabChange = (event, newValue) => {
		setActiveTab(newValue)
	}

	const updateRetentionValue = (event) => {
		const { value } = event.target
		const type = activeTab === tabs.ServiceRetention ? SERVICE_RETENTION_PERCENTAGE : FREIGHT_RETENTION_PERCENTAGE
		dispatch(updateSettingValue(rfc, value / 100, type))
	}

	const addSupplierToRetention = (supplier) => {
		const type = activeTab === tabs.ServiceRetention ? SERVICE_RETENTION_PERCENTAGE : FREIGHT_RETENTION_PERCENTAGE
		dispatch(addWorkshopToRetention(rfc, supplier.value, type))
	}

	const removeSupplierFromRetention = (supplier) => {
		const type = activeTab === tabs.ServiceRetention ? SERVICE_RETENTION_PERCENTAGE : FREIGHT_RETENTION_PERCENTAGE
		dispatch(removeWorkshopFromRetention(rfc, supplier, type))
	}

	/**
	 * Render content for current selected tab
	 */
	const renderTabContent = () => {
		switch (activeTab) {
			case tabs.ServiceRetention:
				return (
					<RetentionPercentageTab
						retentionType={SERVICE_RETENTION_PERCENTAGE}
						value={configuration.ServiceRetentionPercentage * 100}
						loadingSuppliers={loadingWorkshops}
						suppliers={suppliers}
						suppliersInRetention={configuration.WorkshopRetentions.filter(
							(x) => x.RetentionType === SERVICE_RETENTION_PERCENTAGE
						)}
						handleRetentionValueChange={updateRetentionValue}
						onSupplierChange={addSupplierToRetention}
						onRemoveWorkshopButtonClick={removeSupplierFromRetention}
					/>
				)
			case tabs.FreightRetention:
				return (
					<RetentionPercentageTab
						retentionType={FREIGHT_RETENTION_PERCENTAGE}
						value={configuration.FreightRetentionPercentage * 100}
						loadingSuppliers={loadingWorkshops}
						suppliers={suppliers}
						suppliersInRetention={configuration.WorkshopRetentions.filter(
							(x) => x.RetentionType === FREIGHT_RETENTION_PERCENTAGE
						)}
						handleRetentionValueChange={updateRetentionValue}
						onSupplierChange={addSupplierToRetention}
						onRemoveWorkshopButtonClick={removeSupplierFromRetention}
					/>
				)
			default:
				return (
					<RetentionPercentageTab
						retentionType={SERVICE_RETENTION_PERCENTAGE}
						value={settings.serviceRetentionPercent * 100}
						loadingSuppliers={loadingWorkshops}
						suppliers={suppliers}
						suppliersInRetention={configuration.WorkshopRetentions.filter(
							(x) => x.RetentionType === SERVICE_RETENTION_PERCENTAGE
						)}
						handleRetentionValueChange={updateRetentionValue}
						onSupplierChange={addSupplierToRetention}
						onRemoveWorkshopButtonClick={removeSupplierFromRetention}
					/>
				)
		}
	}

	return (
		<LateralPanel onClose={onClose} classes={classes}>
			<PanelHeader
				isIconMaterial
				titlePanel={'Retenciones'}
				onCloseButtonClick={onClose}
				icon={<SettingsIcon color='primary' />}
			/>
			<div className={classes.bodyContainer}>
				<Tabs value={activeTab} onChange={chandleTabChange} aria-label='Tabs to show services or configuration'>
					<Tab
						label={<Translate id='accreditedWorkshops.services' />}
						{...a11yProps(tabs.ServiceRetention)}
					/>
					<Tab label={<Translate id='accreditedWorkshops.freight' />} {...a11yProps(tabs.FreightRetention)} />
				</Tabs>
				<div className={classes.tabContainer}>{renderTabContent()}</div>
			</div>
		</LateralPanel>
	)
}

RetentionPercentagesPanel.propTypes = {
	onClose: PropTypes.func.isRequired,
}

const tabs = {
	ServiceRetention: 'service',
	FreightRetention: 'freight',
}

const a11yProps = (id) => {
	return {
		id: `tab-${id}`,
		value: `${id}`,
		'aria-controls': `simple-tabpanel-${id}`,
	}
}

const styles = (theme) => ({
	bodyContainer: {
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
	},
	tabContainer: {
		padding: 10,
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
})

export default withStyles(styles, { withTheme: true })(RetentionPercentagesPanel)
