///Import react section
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import LinkMaterial from '@material-ui/core/Link'
import ContractIcon from '@material-ui/icons/ImportContacts'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import { Translate, getTranslate } from 'react-localize-redux'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import SearchInput from '../common/SearchInput/SearchInput'
import OptionsMenu from '../common/OptionsMenu'

/** Action and helpers */
import {
	loadContractsFromBackEnd,
	getContractsCount,
	createContractData,
	CHANGE_CONTRACTS_SORT_CRITERIA,
	CHANGE_CONTRACTS_SORT_QUERY,
	CHANGE_CONTRACTS_PAGINATION,
	downloadReportSupplierContractors,
} from '../../store/supplierconstracts/SupplierConstractAction'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canBeModuleContractsSupplier, canBeModuleContractsView } from '../../store/helpers/RolesHelper'
import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers'
import { DOCUMENT_CONTRACTOR } from '../../store/helpers/ResourcesHelper'
import { getColorStatusContract, getDescriptionStatusContract } from '../../store/helpers/DocumentsHelper'

/**
 * Index view
 */
class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			openModalContract: false,
			contract: {
				attachments: [],
				name: '',
			},
			showMenu: true,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadContracts(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				DOCUMENT_CONTRACTOR
			)
			this.props.getContractsCount(DOCUMENT_CONTRACTOR)
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadContracts()
			this.props.getContractsCount(DOCUMENT_CONTRACTOR)
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadContracts(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				DOCUMENT_CONTRACTOR
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Get contracts portal counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		const { classes } = this.props
		if (this.props.contracts) {
			let totalContracts = this.props.contracts.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{totalContracts}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.contractsCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'status') {
			return (
				<Tooltip title={getDescriptionStatusContract(item.status, item.end_date)}>
					<Avatar data-cy='avatar' style={{ backgroundColor: getColorStatusContract(item.status) }}>
						<ContractIcon />
					</Avatar>
				</Tooltip>
			)
		}
		if (dataSource === 'repse') {
			return item.repse ? 'SI' : 'NO'
		}
		if (dataSource === 'type_deal') {
			let optionsTypeDeal = [
				{ value: 1, label: 'Arrendamiento' },
				{ value: 2, label: 'Aviso de Terminación' },
				{ value: 3, label: 'Formato' },
				{ value: 4, label: 'Comodato' },
				{ value: 5, label: 'Compraventa' },
				{ value: 6, label: 'Convenio de Confidencialidad' },
				{ value: 7, label: 'Convenio Modificatorio' },
				{ value: 8, label: 'Prestación de Servicios' },
				{ value: 9, label: 'Maquila' },
				{ value: 10, label: 'Suministro' },
				{ value: 11, label: 'Otro' },
			]
			return item.type_deal === 0 ? '' : <Typography>{optionsTypeDeal[item.type_deal - 1].label}</Typography>
		}
		if (dataSource === 'name') {
			return (
				<>
					{item.name && (
						<LinkMaterial
							key={item.token}
							component={'a'}
							target='_blank'
							color='primary'
							href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/Documents/SupplierContract/${item.token}/${item.file_name}.pdf`}
						>
							<DownloadIcon style={{ marginTop: '-7px' }} /> {item.name}
						</LinkMaterial>
					)}
				</>
			)
		}
		if (dataSource === 'supplier_name') {
			if (!item.isSupplier) {
				return `${item.clientCode} | ${item.clientName}`
			}
		}
	}

	/**
	 * onRowClick
	 * @param {*} calendar
	 * @param {*} event
	 */
	onRowClick(contractor, event) {
		let tagName = event.target.tagName
		if (
			tagName !== 'svg' &&
			tagName !== 'A' &&
			(canBeModuleContractsSupplier(this.props.userRolesByCompany) ||
				canBeModuleContractsView(this.props.userRolesByCompany))
		) {
			this.props.history.push(`/contracts/${contractor.token}`)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingContracts === true) {
			return <LoadingPage />
		} else if (this.props.contracts === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.contracts}
					configuration={TableConfiguration(canBeModuleContractsSupplier(this.props.userRolesByCompany))}
					onRenderCellItem={this.onRenderCellItem}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={this.onRowClick}
					isIndex
					totalRows={this.props.contractsCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Function to add new contracts
	 */
	onClickAddButton() {
		this.props.history.push(`/contracts/create`)
	}
	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof Index
	 */
	handleClickOptionsMenu(token) {
		this.setState({
			itemToken: token,
		})
	}

	/**
	 * Handle on close optons menu to set void item token in state
	 *
	 * @memberof Index
	 */
	handleCloseOptionsMenu() {
		this.setState(
			{
				itemToken: '',
				showMenu: false,
			},
			() => {
				this.setState({ showMenu: true })
			}
		)
	}

	/**
	 *Render action button in card download
	 *
	 * @memberof Index
	 */
	renderAction() {
		var options = []
		options.push({
			itemClick: () => this.onDownloadReport(),
			tooltipTranslation: <Translate id='common.downloadReport' />,
			menuItemIcon: <DownloadIcon color='secondary' />,
			isLoading: this.props.isDownloadingReport,
		})
		if (this.state.showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === 'item.token'}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	/**
	 *Download report of from contacts
	 *
	 * @memberof Index
	 */
	onDownloadReport() {
		this.props
			.downloadReportSupplierContractors(DOCUMENT_CONTRACTOR)
			.then(() => {
				showSuccessToaster('common.downloadCorrectly')
			})
			.catch(() => {
				showErrorToaster('common.errorToDownload')
			})
	}

	/**
	 * Create instance
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<ContractIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							{canBeModuleContractsSupplier(this.props.userRolesByCompany) && (
								<SearchInput onChangeSearchValue={this.onChangeSearchValue} />
							)}
							{canBeModuleContractsSupplier(this.props.userRolesByCompany) && (
								<Fab
									size='small'
									color='primary'
									onClick={() => {
										this.onClickAddButton()
									}}
								>
									<AddtIcon />
								</Fab>
							)}
							{canBeModuleContractsSupplier(this.props.userRolesByCompany) && this.renderAction()}
						</div>
					}
					title={<Typography variant='button'>{<Translate id='constracts.constracts' />}</Typography>}
					subheader={this.getCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: <Translate id='officialNotices.status' />,
			dataSource: 'status',
		},
		{
			header: 'Folio',
			dataSource: 'folio',
		},
		{
			header: <Translate id='constracts.activationDate' />,
			dataSource: 'start_date',
		},
		{
			header: <Translate id='constracts.expirationDate' />,
			dataSource: 'end_date',
		},
		{
			header: <Translate id='constracts.counterpart' />,
			dataSource: 'supplier_name',
		},
		{
			header: <Translate id='constracts.typeDeal' />,
			dataSource: 'type_deal',
		},
		{
			header: <Translate id='constracts.repse' />,
			dataSource: 'repse',
		},
		{
			header: <Translate id='constracts.title' />,
			dataSource: 'name',
		},
	]

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingContracts: state.contracts.get('isLoadingContracts'),
		contracts: state.contracts.get('contracts') ? state.contracts.get('contracts').toJS() : null,
		isSavingContract: state.contracts.get('isSavingContract'),
		isDownloadingReport: state.contracts.get('isDownloadingReport'),
		sortCriteria: state.contracts.get('sortCriteria') ? state.contracts.get('sortCriteria').toJS() : null,
		searchQuery: state.contracts.get('searchQuery'),
		page: state.contracts.get('page'),
		rowsPerPage: state.contracts.get('rowsPerPage'),
		contractsCount: state.contracts.get('contractsCount'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadContracts: (query, orderBy, sortAscending, page, rowsPerPage, documentType) => {
			dispatch(loadContractsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, documentType))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_CONTRACTS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_CONTRACTS_SORT_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_CONTRACTS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getContractsCount: (documentType) => {
			return dispatch(getContractsCount(documentType))
		},
		createContractData: (contract) => {
			return dispatch(createContractData(contract))
		},
		downloadReportSupplierContractors: (documentType) => {
			return dispatch(downloadReportSupplierContractors(documentType))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
