import React from 'react';
import PropTypes from 'prop-types';

/**Import components section*/
import { Text, View, StyleSheet } from '@react-pdf/renderer';

/** Language imports **/
import { Translate } from "react-localize-redux";
import Root from '../../../../store/Root';

/**Import components section*/

/**Import resources section*/
import { COLOR_STATUS_DANGER, COLOR_STATUS_SUCCESS } from '../../../../store/helpers/StatusColorConstants';
import { getQuestionsCritical, getAnswerQuestion} from '../../../../store/sustainability2/SustainabilityResults'

const RecommendationsPdf = props => {
    const { topics } = props    
    return (
        <Root>
            <View style={styles.root}>
            {topics.map((topic) => {
                let questios = getQuestionsCritical(topic.Subtopics);
                if(questios.length !== 0){
                    return (
                        <View key={topic.Id} >
                              <View>
                                  <View style={[styles.contentList, {backgroundColor:topic.ColorTopic}]}>
                                    <View style={styles.listHeader}><Text>{topic.Name}</Text></View>
                                    <View style={styles.listResponse}><Text><Translate id="sustainability.Answer"/></Text></View>
                                    <View style={styles.listHeader}><Text><Translate id="sustainability.recommendations"/></Text></View>
                                  </View>
                              </View>
                              <View>
                                  {questios.map((question) => {
                                      return (
                                          <View key={question.Id} style={styles.contentTableRow}>
                                              <View style={styles.contentTable}>
                                                  <Text>{question.Name}</Text>
                                              </View>
                                              <View style={styles.contentTableAnswer}>
                                                    <Text style={question.PercentageSupplier === 0 ? styles.colorRed : styles.colorGrey}> 
                                                        {getAnswerQuestion(question.AnswerSupplier)}
                                                    </Text>
                                              </View>
                                              <View style={styles.contentTable}>
                                                <Text>{question.Recommendation}</Text>
                                              </View>
                                          </View>
                                      )
                                  })}
                              </View>
                        </View>                                           
                    )
                }
                else{
                    return null
                }
            })}
        </View>
        </Root>
    );
};

// Create styles
const styles = StyleSheet.create({
    root:{
        width: '100%',
        marginTop: "20px",
        fontSize: 8
    },
    contentList: {
        height: "25px",
        display: 'flex',
        flexDirection: 'row',
    },
    listHeader: {
        color: "white",
        width:"45%",
        textAlign: 'center',
        marginTop: '2px'
    },
    listResponse: {
        color: "white",
        width:"10%",
        textAlign: 'center',
        marginTop: '2px'
    },
    contentTableRow: {
        height: "auto",
        display: 'flex',
        flexDirection: 'row',
    },
    contentTable: {
        color: "black",
        width:"45%",
        marginBottom: 6,
    },
    contentTableAnswer: {
        textAlign: 'center',
        width:"10%"
    },
    colorRed:{
        color: COLOR_STATUS_DANGER,
    },
    colorGrey:{
        color: COLOR_STATUS_SUCCESS,
    }
  });

RecommendationsPdf.propTypes = {
    topics:PropTypes.array
};
  
RecommendationsPdf.defaultProps = {
    topics: [],
};

export default RecommendationsPdf;