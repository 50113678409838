import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'

/** Material-UI imports section */
import { Translate } from "react-localize-redux";
import {Typography, TextField} from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';

/** Import resources */
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import DatePicker from '../../common/DateTimePicker/DatePicker';

/**
 * Component o DialogProgramAudit
 */
class DialogProgramAudit extends Component {
    /**
    * Create an instance ofMemos
    * @param {*} props 
    */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            ScheduledAuditDate: "",
            Comments: "",
        }
        
    }

    /**
     * Can be save
     */
    canbeSave() {
        if (this.state.ScheduledAuditDate === null)
            return true;
        if (this.state.ScheduledAuditDate.length !== 0)
            return true;
        else
            return false;
    }

    /**
     * Function to add description
     * @param {*} description 
     */
     updateProperty(property, value) {
        this.setState({
            [property]: value
        })
    }

    /**
     * onSave
     */
    onSaveItem() {
        if(this.props.onSaveItem)
            this.props.onSaveItem(this.state);
    }

    /**
     *  Dialog to revister arrive date
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            {this.props.isMessage ? 
                             <Translate id='sustainability.auditReChudeleDate'/> :
                             <Translate id='sustainability.auditDate'/>}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={this.onSaveItem}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.canbeSave()}
                    />
                }>

                <form className={classes.textField}>
                    <Typography variant="caption" style={{marginLeft:"5px"}} >{<Translate id='sustainability.dateAudit'/>}</Typography>
                        <DatePicker
                            onChangeRange={(event)=> {this.updateProperty('ScheduledAuditDate', event)}}
                            startDate={this.state.ScheduledAuditDate ? moment(this.state.ScheduledAuditDate , 'DD/MM/YYYY') : ''}
                            minDate={moment()}
                        />
                    <br />
                    <TextField fullWidth
                        label={<Translate id='common.comments'/>}
                        className={classes.textField}
                        multiline
                        rows="3"
                        defaultValue={this.state.Comments}
                        onChange={(event) => { this.updateProperty("Comments", event.target.value) }}
                        margin="normal"
                        variant="outlined"
                    />
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 350,
        marginTop: 10,
        marginBottom: 10
    }
});


export default withRouter(withStyles(dialogStyles, { withTheme: true })(DialogProgramAudit));