/** React imports section */
import React from 'react'
import moment from 'moment'
import { useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import propTypes from 'prop-types'

/** Material UI imports section */
import { Typography, Checkbox, TextField } from '@material-ui/core'

/** Store imports section */
import {
	getClientsOptions,
	getManeuverTypeByNumber,
	getTransportZoneOptions,
} from '../../../../store/helpers/TransportTenderHelper'

/** Store imports section */
import SelectorComponent from '../../common/SelectorComponent'
import HourPickers from '../../common/HourPickers'
import DatePicker from '../../../common/DateTimePicker/DatePicker'
import { ManeuverTypeEnum } from '../../../../store/helpers/AppConstants'

const CreateManualDeals = (props) => {
	const { classes, clientsCatalog, transportZone, information, setInformation, deal } = props
	const onchange = (event) => {
		setInformation({ ...information, requiresManeuvering: event.target.checked })
	}
	const onChangeCheckBox = (event) => {
		const { name, checked } = event.target
		if (name === 'isFolioSit') {
			setInformation({
				...information,
				[name]: checked,
				folioLetter: '',
			})
		} else {
			setInformation({
				...information,
				[name]: checked,
			})
		}
	}
	const onChangeTextField = (event) => {
		const { name, value } = event.target
		setInformation({
			...information,
			[name]: value,
		})
	}
	const onChangeDate = (property, data) => {
		setInformation({
			...information,
			[property]: data,
		})
	}
	useEffect(() => {
		if (deal) {
			setInformation({
				client: {
					...deal.Client,
					value: deal.Client.Name,
					label: `${deal.Client.Name}`,
				},
				zone: {
					...deal.TransportZone,
					value: deal.TransportZone.Id,
					label: `${deal.TransportZone.Name} | ${deal.TransportZone.KM} KM`,
				},
				requiresManeuvering: deal.IsManeuvers,
				street: deal.Street,
				externalNumber: deal.ExternalNumber,
				internalnumber: deal.Internalnumber,
				suburb: deal.Suburb,
				countryCode: deal.CountryCode,
				town: deal.Town,
				state: deal.State,
				country: deal.Country,
				salesManager: deal.SalesManager,
				estimatedDeliveryDate: deal.EstimatedDeliveryDate ? moment(deal.EstimatedDeliveryDate) : null,
				estimatedTimeDelivery: deal.EstimatedTimeDelivery
					? moment(deal.EstimatedTimeDelivery).format('HH:mm')
					: null,
				requiresBoardingAppointment:
					deal.BoardingAppointmentDate  ? true : false,
				boardingAppointmentDate: deal.BoardingAppointmentDate ? moment(deal.BoardingAppointmentDate) : null,
				boardingAppointmentTime: deal.BoardingAppointmentDate
					? moment(deal.BoardingAppointmentDate).format('HH:mm')
					: null,
				boardingAppointmentFolio: deal.BoardingAppointmentFolio,
				folioLetter: deal.FolioLetter,
				SelectManeuverType:
					getManeuverTypeByNumber(deal.SelectManeuverType).length > 0
						? {
								value: deal.SelectManeuverType,
								label: <Translate id={getManeuverTypeByNumber(deal.SelectManeuverType)} />,
						  }
						: null,
				maneuverSupplierCode: deal.ManeuverSupplierCode,
				maneuverSupplierName: deal.ManeuverSupplierName,
			})
		}
	}, [deal])
	const clientsOptions = getClientsOptions(clientsCatalog)
	const transportZoneOptions = getTransportZoneOptions(transportZone)
	return (
		<div style={{ marginTop: '-10px' }}>
			<div className={classes.createManualStyles}>
				<Typography variant='subtitle2'>{<Translate id='carrierTenders.deals.addDestinations' />}</Typography>
			</div>
			<div className={classes.baseRate}>
				<Checkbox
					name='isFolioSit'
					checked={information.isFolioSit}
					onChange={(event) => onChangeCheckBox(event)}
					value={'1'}
				/>
				<Typography variant='subtitle1' className={classes.rateTitle}>
					¿Es licitación con folio SIT?
				</Typography>
				{/* <Checkbox
					onChange={(event) => onchangeCheckbox('isFolioSit', event)}
					color='primary'
					value='isFolioSit'
					checked={information.isFolioSit}
				/> */}
			</div>
			{!information.isFolioSit && (
				<>
					<div className={classes.titleSelect}>
						<Typography variant='subtitle2'>Folio letra</Typography>
					</div>
					<TextField
						id='standard-with-placeholder'
						placeholder='Folio letra'
						onChange={onChangeTextField}
						name='folioLetter'
						value={information.folioLetter}
						style={classes.textField}
						inputProps={{
							style: {
								height: '10px',
							},
						}}
						size='small'
						margin='dense'
						fullWidth
						variant='outlined'
					/>
				</>
			)}
			<SelectorComponent
				title={<Translate id='carrierTenders.deals.client' />}
				placeholder={<Translate id='carrierTenders.deals.selectClient' />}
				value={information.client}
				options={clientsOptions}
				onChange={(option) => setInformation({ ...information, client: option })}
			/>
			<SelectorComponent
				title={<Translate id='carrierTenders.deals.zone' />}
				placeholder={<Translate id='carrierTenders.deals.selectZone' />}
				options={transportZoneOptions}
				onChange={(option) => setInformation({ ...information, zone: option })}
				value={information.zone}
			/>
			<div className={classes.titleSelect}>
				<Typography variant='subtitle2'>{<Translate id='carrierTenders.deals.salesManager' />}</Typography>
			</div>
			<TextField
				id='standard-with-placeholder'
				placeholder='Responsable comercial'
				onChange={onChangeTextField}
				name='salesManager'
				value={information.salesManager}
				style={classes.textField}
				inputProps={{
					style: {
						height: '10px',
					},
				}}
				size='small'
				margin='dense'
				fullWidth
				variant='outlined'
			/>
			{/* <div className={classes.datecontainer}>
				<div className={classes.datePicker}>
					<div style={{ marginBottom: '10px' }}>
						<Typography variant='subtitle2'>
							{<Translate id='carrierTenders.deals.deliveryDate' />}
						</Typography>
					</div>
					<DatePicker
						opens={'left'}
						drops={'down'}
						onChangeRange={(event) => onChangeDate('estimatedDeliveryDate', event)}
						startDate={
							information.estimatedDeliveryDate
								? moment(information.estimatedDeliveryDate, 'DD/MM/YYYY')
								: null
						}
					/>
				</div>
				<div>
					<div className={classes.titleSelect}>
						<Typography variant='subtitle2'>
							{<Translate id='carrierTenders.deals.stimatedTimeDelivery' />}
						</Typography>
					</div>
					<HourPickers
						name='estimatedTimeDelivery'
						value={information.estimatedTimeDelivery ? information.estimatedTimeDelivery : ''}
						onChange={onChangeTextField}
					/>
				</div>
			</div> */}
			<div className={classes.titleSelect}>
				<Typography>{'Dirección'}</Typography>
			</div>
			<div className={classes.addressContainer}>
				<div className={classes.gridColumn1}>
					<div>
						<Typography variant='subtitle2'>{<Translate id='carrierTenders.deals.street' />}</Typography>
					</div>
					<TextField
						id='standard-with-placeholder'
						placeholder='Calle'
						onChange={onChangeTextField}
						value={information.street}
						name='street'
						style={classes.textField}
						inputProps={{
							style: {
								height: '10px',
							},
						}}
						size='small'
						margin='dense'
						fullWidth
						variant='outlined'
					/>
				</div>
				<div className={classes.gridColumn3}>
					<div>
						<Typography variant='subtitle2'>
							{<Translate id='carrierTenders.deals.externalNumber' />}
						</Typography>
					</div>
					<TextField
						id='standard-with-placeholder'
						placeholder='Num exterior'
						onChange={onChangeTextField}
						name='externalNumber'
						value={information.externalNumber}
						style={classes.textField}
						inputProps={{
							style: {
								height: '10px',
							},
						}}
						size='small'
						margin='dense'
						fullWidth
						variant='outlined'
					/>
				</div>
				<div className={classes.gridColumn1}>
					<div>
						<Typography variant='subtitle2'>
							{<Translate id='carrierTenders.deals.internalNumber' />}
						</Typography>
					</div>
					<TextField
						id='standard-with-placeholder'
						placeholder='Num interior'
						onChange={onChangeTextField}
						name='internalnumber'
						value={information.internalnumber}
						style={classes.textField}
						inputProps={{
							style: {
								height: '10px',
							},
						}}
						size='small'
						margin='dense'
						fullWidth
						variant='outlined'
					/>
				</div>
				<div className={classes.gridColumn3}>
					<div>
						<Typography variant='subtitle2'>{<Translate id='carrierTenders.deals.suburb' />}</Typography>
					</div>
					<TextField
						id='standard-with-placeholder'
						placeholder='Colonia'
						onChange={onChangeTextField}
						name='suburb'
						value={information.suburb}
						style={classes.textField}
						inputProps={{
							style: {
								height: '10px',
							},
						}}
						size='small'
						margin='dense'
						fullWidth
						variant='outlined'
					/>
				</div>
				<div className={classes.gridColumn1}>
					<div>
						<Typography variant='subtitle2'>{<Translate id='carrierTenders.deals.zipCode' />}</Typography>
					</div>
					<TextField
						id='standard-with-placeholder'
						placeholder='Código postal'
						onChange={onChangeTextField}
						name='countryCode'
						value={information.countryCode}
						style={classes.textField}
						inputProps={{
							style: {
								height: '10px',
							},
						}}
						size='small'
						margin='dense'
						fullWidth
						variant='outlined'
					/>
				</div>
				<div className={classes.gridColumn3}>
					<div>
						<Typography variant='subtitle2'>{<Translate id='carrierTenders.deals.town' />}</Typography>
					</div>
					<TextField
						id='standard-with-placeholder'
						placeholder='Municipio'
						onChange={onChangeTextField}
						name='town'
						value={information.town}
						style={classes.textField}
						inputProps={{
							style: {
								height: '10px',
							},
						}}
						size='small'
						margin='dense'
						fullWidth
						variant='outlined'
					/>
				</div>
				<div className={classes.gridColumn1}>
					<div>
						<Typography variant='subtitle2'>{<Translate id='carrierTenders.deals.stateCode' />}</Typography>
					</div>
					<TextField
						id='standard-with-placeholder'
						placeholder='Estado'
						onChange={onChangeTextField}
						name='state'
						value={information.state}
						style={classes.textField}
						inputProps={{
							style: {
								height: '10px',
							},
						}}
						size='small'
						margin='dense'
						fullWidth
						variant='outlined'
					/>
				</div>
				<div className={classes.gridColumn3}>
					<div>
						<Typography variant='subtitle2'>
							{<Translate id='carrierTenders.deals.countryCode' />}
						</Typography>
					</div>
					<TextField
						id='standard-with-placeholder'
						placeholder='País'
						onChange={onChangeTextField}
						name='country'
						value={information.country}
						style={classes.textField}
						inputProps={{
							style: {
								height: '10px',
							},
						}}
						size='small'
						margin='dense'
						fullWidth
						variant='outlined'
					/>
				</div>
			</div>
			<div className={classes.manoeuvringContainer}>
				<div className={classes.divColor}></div>
				<div className={classes.manoeuvringTitle}>
					<Typography variant='subtitle2'>
						{<Translate id='carrierTenders.deals.requiresManeuvering' />}
					</Typography>

					<Checkbox
						checked={information.requiresManeuvering}
						onChange={(event) => onchange(event)}
						value={'1'}
						disabled
					/>
				</div>
			</div>
			{information.requiresManeuvering === true && (
				<div>
					<SelectorComponent
						title={<Translate id='carrierTenders.deals.maneuvered.title' />}
						placeholder={<Translate id='carrierTenders.deals.maneuvered.select' />}
						value={information.SelectManeuverType}
						options={maneuverTypes}
						onChange={(option) => setInformation({ ...information, SelectManeuverType: option })}
						isDisabled={true}
					/>
					{information.maneuverSupplierCode && (
						<>
							<Typography>{'Proveedor de maniobra'}</Typography>
							<Typography>{`${
								information && information.maneuverSupplierCode ? information.maneuverSupplierCode : '-'
							}-${
								information && information.maneuverSupplierName ? information.maneuverSupplierName : '-'
							}`}</Typography>
						</>
					)}
				</div>
			)}
			<div className={classes.manoeuvringContainer}>
				<div className={classes.divColor}></div>
				<div className={classes.manoeuvringTitle}>
					<Typography variant='subtitle2'>
						{<Translate id='carrierTenders.deals.requiresBoardingAppointment' />}
					</Typography>

					<Checkbox
						name='requiresBoardingAppointment'
						checked={information.requiresBoardingAppointment}
						onChange={(event) => onChangeCheckBox(event)}
						value={'1'}
					/>
				</div>
			</div>
			{information.requiresBoardingAppointment === true && (
				<div>
					<div className={classes.createManualStyles}>
						<Typography variant='subtitle2'>
							{<Translate id='carrierTenders.deals.titleBoardingAppointment' />}
						</Typography>
					</div>
					<div className={classes.datePicker}>
						<div style={{ marginBottom: '10px' }}>
							<Typography variant='subtitle2'>
								{<Translate id='carrierTenders.deals.selectAppointmentDate' />}
							</Typography>
						</div>
						<DatePicker
							opens={'left'}
							drops={'up'}
							onChangeRange={(event) => onChangeDate('boardingAppointmentDate', event)}
							startDate={
								information.boardingAppointmentDate
									? moment(information.boardingAppointmentDate, 'DD/MM/YYYY')
									: null
							}
							minDate={moment()}
						/>
					</div>
					<div>
						<div className={classes.titleSelect}>
							<Typography variant='subtitle2'>
								{<Translate id='carrierTenders.deals.selectAppointmentTime' />}
							</Typography>
						</div>
						<HourPickers
							name='boardingAppointmentTime'
							value={information.boardingAppointmentTime ? information.boardingAppointmentTime : ''}
							onChange={onChangeTextField}
						/>
					</div>
					<div>
						<div className={classes.titleSelect}>
							<Typography variant='subtitle2'>
								{<Translate id='carrierTenders.deals.appointmentFolio' />}
							</Typography>
						</div>
						<TextField
							id='standard-with-placeholder'
							placeholder='Escribe el Folio de cita'
							onChange={onChangeTextField}
							name='boardingAppointmentFolio'
							value={information.boardingAppointmentFolio}
							style={classes.textField}
							inputProps={{
								style: {
									height: '10px',
								},
							}}
							size='small'
							margin='dense'
							fullWidth
							variant='outlined'
						/>
					</div>
				</div>
			)}
		</div>
	)
}
const maneuverTypes = [
	{
		value: ManeuverTypeEnum.PayForImbera,
		label: <Translate id={getManeuverTypeByNumber(ManeuverTypeEnum.PayForImbera)} />,
	},
	{
		value: ManeuverTypeEnum.ContratedForTransport,
		label: <Translate id={getManeuverTypeByNumber(ManeuverTypeEnum.ContratedForTransport)} />,
	},
	{
		value: ManeuverTypeEnum.PaidCarrierToCustomer,
		label: <Translate id={getManeuverTypeByNumber(ManeuverTypeEnum.PaidCarrierToCustomer)} />,
	},
]
CreateManualDeals.propTypes = {
	clientsCatalog: propTypes.array.isRequired,
	transportZone: propTypes.array.isRequired,
	setInformation: propTypes.func.isRequired,
}

CreateManualDeals.defaultProps = {
	clientsCatalog: [],
	transportZone: [],
	setInformation: () => console.warn('[setInformation] not defined'),
}
export default CreateManualDeals
