import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonClearFilters from '../components/ButtonClearFilters';

export default function useClearFiltersButton() {
	const history = useHistory();
	const { search } = history.location;
	const disabled = !search || search.length === 0;

	const cleanAllFilters = () => {
		const { pathname } = history.location;
		history.push(pathname);
	};

	const Button = useCallback(
		() => <ButtonClearFilters disabled={disabled} onClick={cleanAllFilters} />,
		[disabled, cleanAllFilters]
	);

	return [Button];
}
