import React, { Component } from 'react';
import { connect } from 'react-redux'
import autoBind from 'auto-bind';
import { Translate } from "react-localize-redux";
import Select from 'react-select'

import { searchByFailureGuaranteeByQuery } from '../../../store/guaranteeReport/guaranteeReportAction';


/**
 * Prepared By selector container
 *
 * @class FailureBySelector
 * @extends {Component}
 */
class FailureBySelector extends Component {

    /**
     *Creates an instance of FailureBySelector.
     * @param {*} props
     * @memberof FailureBySelector
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            isLoading: true,
            failureGuarantee: []
        }
    }

    /**
   * componentDidMount
   */
    componentDidMount() {
        if (!this.props.isLoadingUser) {
            this.props.searchByFailureGuaranteeByQuery()
                .then((failureGuarantee) => {
                    let users = failureGuarantee.map((userFailure) => {
                        return {
                            value: userFailure.token,
                            label: userFailure.name
                        }
                    })
                    this.setState({
                        failureGuarantee: users
                    })
                    return failureGuarantee.map((failure) => {
                        return {
                            value: failure.token,
                            label: failure.name
                        }
                    })
                })
        }
    }

    /**
     * On select a FailureBySelector 
     *
     * @param {*} option
     * @memberof FailureBySelector
     */
    onSelectedFailureGuaranteeClick(option) {
        if (this.state.failureGuarantee) {
            let selectedUser = this.state.failureGuarantee.find((failure) => {
                return failure.value === option.value
            })
            if (this.props.onFailureGuaranteeSelected) {
                this.props.onFailureGuaranteeSelected(selectedUser)
            }
        }
    }

    /**
     *  
     *
     * @returns
     * @memberof FailureBySelector
     */
    render() {
        return (
            <Select
                placeholder={<Translate id="moduleGuarantee.reportGuarantee.searchBy" />}
                options={this.state.failureGuarantee}
                onChange={this.onSelectedFailureGuaranteeClick}
                isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
                styles={{
                    menu: (base) => ({ ...base, zIndex: 10 }),
                    menuList: (base) => ({ ...base, paddingTop: 0 }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                }}
            />
        );
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        isLoadingUser: state.oidc.isLoadingUser,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        searchByFailureGuaranteeByQuery: (query) => {
            return dispatch(searchByFailureGuaranteeByQuery(query))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FailureBySelector);