/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserParameters,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'

export const START_LOADING_MEMOS = 'START_LOADING_MEMOS'
export const SET_MEMOS_LIST = 'SET_MEMOS_LIST'
export const IS_LOADING_MEMO = 'IS_LOADING_MEMO'
export const SET_MEMO = 'SET_MEMO'
export const CHANGE_MEMOS_SORT_CRITERIA = 'CHANGE_MEMOS_SORT_CRITERIA'
export const CHANGE_MEMOS_QUERY = 'CHANGE_MEMOS_QUERY'
export const CHANGE_MEMOS_PAGINATION = 'CHANGE_MEMOS_PAGINATION'
export const SET_MEMOS_COUNT = 'SET_MEMOS_COUNT'
export const GET_INFORMATION_MEMO = 'GET_INFORMATION_MEMO'
export const UPDATE_DETAIL_SCHEMATIC = 'UPDATE_DETAIL_SCHEMATIC'
export const IS_UPDATING_PLANE_NAME = 'IS_UPDATING_PLANE_NAME'
export const DELETE_DETAIL_SCHEMATIC = 'DELETE_DETAIL_SCHEMATIC'
export const IS_DELETE_DETAIL_SCHEMATIC = 'IS_DELETE_DETAIL_SCHEMATIC'
export const ADD_SUPPLIER_FROM_MEMO = 'ADD_SUPPLIER_FROM_MEMO'
export const REMOVE_SUPPLIER_FROM_MEMO = 'REMOVE_SUPPLIER_FROM_MEMO'
export const SET_MEMO_ITEM_SELECTED = 'SET_MEMO_ITEM_SELECTED'
export const ADD_SAMPLE_REQUEST_SUPPLIER_FROM_MEMO = 'ADD_SAMPLE_REQUEST_SUPPLIER_FROM_MEMO'
export const REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_MEMO = 'REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_MEMO'
export const SEND_QUOTES_REQUEST_MEMO = 'SEND_QUOTES_REQUEST_MEMO'
export const IS_SAVING_MEMO = 'IS_SAVING_MEMO'
export const FINALIZE_MEMO = 'FINALIZE_MEMO'
export const MARK_QUOTE_DETAIL_ITEM_AS_BEST_OPTION = 'MARK_QUOTE_DETAIL_ITEM_AS_BEST_OPTION'
export const UPDATE_COMMENTS_MEMO = 'UPDATE_COMMENTS_MEMO'
export const ADD_FILE_MEMO = 'ADD_FILE_MEMO'
export const REMOVE_FILE_MEMO = 'REMOVE_FILE_MEMO'
export const SET_REACTIVE_QUOTE_IN_MEMO = 'SET_REACTIVE_QUOTE_IN_MEMO'

/**
 * Action start loading memos
 * @param {*} isLoading
 */
function isLoadingMemos(isLoading) {
	return {
		type: START_LOADING_MEMOS,
		isLoading: isLoading,
	}
}
export const updateDetailSchematic = (detailPlane) => ({
	type: UPDATE_DETAIL_SCHEMATIC,
	detailPlane: detailPlane,
})

export const isUpdatingPlaneName = (isUploading) => ({
	type: IS_UPDATING_PLANE_NAME,
	isUploading: isUploading,
})
export const deleteDetailSchemetic = (deletedToken) => ({
	type: DELETE_DETAIL_SCHEMATIC,
	deletedToken: deletedToken,
})

export const isDeleteDetailSchematic = (isDeleting) => ({
	type: IS_DELETE_DETAIL_SCHEMATIC,
	isDeleting: isDeleting,
})

/**
 * Action saving data of memos
 * @param {*} isSaving
 */
function isSavingMemo(isSaving) {
	return {
		type: IS_SAVING_MEMO,
		isSaving: isSaving,
	}
}

/**
 * Action to set memos list
 * @param {*} memos
 */
function setMemosList(memos) {
	return {
		type: SET_MEMOS_LIST,
		memos: memos,
	}
}

/**
 * Action to start load memo
 */
function startLoadMemo(isLoading) {
	return {
		type: IS_LOADING_MEMO,
		isLoadingMemo: isLoading,
	}
}

/**
 * Action to set memo
 * @param {*} memo
 */
function setMemo(memo) {
	return {
		type: SET_MEMO,
		memo: memo,
	}
}

/**
 * Action reducer to set the memo's counter
 *
 * @param {*} count
 * @returns
 */
function setMemosCount(count) {
	return {
		type: SET_MEMOS_COUNT,
		count,
	}
}

/**
 * Action to get informaiton from memo
 * @param {*} memo
 */
function gtInformationMemo(memo) {
	return {
		type: GET_INFORMATION_MEMO,
		memo: memo,
	}
}

/**
 * Action to remove a supplier from a memo
 *
 * @param {*} memoItemToken
 * @param {*} supplierToken
 * @returns
 */
function supplierRemovedFromItem(memoItemToken, supplierToken) {
	return {
		type: REMOVE_SUPPLIER_FROM_MEMO,
		memoItemToken,
		supplierToken,
	}
}

/**
 * Action to add supplier in quote list
 * @param {*} supplier
 */
function addSupplierInMemoDetails(memoItemToken, quote) {
	return {
		type: ADD_SUPPLIER_FROM_MEMO,
		memoItemToken: memoItemToken,
		quote: quote,
	}
}

/**
 * Action to add sample request supplier from a memo
 * @param {*} memoItemToken
 * @param {*} supplierToken
 */
function addSampleRequestSupplierInMemoItem(memoItemToken, supplierToken) {
	return {
		type: ADD_SAMPLE_REQUEST_SUPPLIER_FROM_MEMO,
		memoItemToken: memoItemToken,
		supplierToken: supplierToken,
	}
}

/**
 * Action to remove sample request supplier from a memo
 * @param {*} memoItemToken
 * @param {*} supplierToken
 */
function removeSampleRequestSupplierInMemoItem(memoItemToken, supplierToken) {
	return {
		type: REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_MEMO,
		memoItemToken: memoItemToken,
		supplierToken: supplierToken,
	}
}

/**
 * Action to start send memo
 */
function sendMemoRequest(isSaving) {
	return {
		type: SEND_QUOTES_REQUEST_MEMO,
		isSaving: isSaving,
	}
}

/**
 * Action to finalize a  memo
 * @param {*} memo
 */
function finalizeMemo(memo) {
	return {
		type: FINALIZE_MEMO,
		memo: memo,
	}
}

/**
 * Action mark a quote detail item as best option
 */
function markQuoteDetailItemAsBestOption(detail, quoteItemToken) {
	return {
		type: MARK_QUOTE_DETAIL_ITEM_AS_BEST_OPTION,
		detail: detail,
		quoteItemToken: quoteItemToken,
	}
}

/**
 * Action to update comments of part number in details list
 *
 * @param {*} partNumberDetail
 * @returns
 */
function updateComments(partNumberDetail) {
	return {
		type: UPDATE_COMMENTS_MEMO,
		partNumberDetail,
	}
}

/**
 * Add file
 *
 * @param {*} files
 * @returns
 */
function addFile(files, tokenPartNumber) {
	return {
		type: ADD_FILE_MEMO,
		files,
		tokenPartNumber,
	}
}

/**
 * Remove file
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName, tokenPartNumber) {
	return {
		type: REMOVE_FILE_MEMO,
		fileName,
		tokenPartNumber,
	}
}

/**
 * Get all memos by company from backend
 */
export function loadMemosFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingMemos(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/memos/all`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMemosList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingMemos(false))
				console.error('Error getting the memos by company list', error.response)
			})
	}
}

/**
 * Get memo's count
 *
 * @export
 * @returns
 */
export function getMemosCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().memos.get('memosCount')
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/memos/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMemosCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the memos's count", error.response)
			})
	}
}

/**
 * Get and set the memo in the estate from read from backend
 * @param {*} token
 */
export function loadMemoFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(startLoadMemo(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/memos/memo/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMemo(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load memo', error.response)
				dispatch(startLoadMemo(false))
				return Promise.reject()
			})
	}
}

/**
 * Get the selected item from a memo details
 * @param {*} state
 */
export const getSelectedMemoItem = (state) => {
	let memo = state.memos.get('memo')
	if (memo) {
		let selectedMemoItem = memo.get('details').find((detail) => {
			return detail.get('is_selected') === true
		})
		if (selectedMemoItem) {
			return selectedMemoItem.toJS()
		} else {
			return null
		}
	} else {
		return null
	}
}

/**
 *  Action update plane from memo item
 * @param {*} detailToken
 * @param {*} planeName
 */
export function updateDetailPartnumberPlaneName(detailToken, planeName) {
	return (dispatch, getState) => {
		dispatch(isUpdatingPlaneName(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partNumbers/plots`
		let data = {
			Name: planeName,
			TokenPartNumber: detailToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateDetailSchematic(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to get information from memo', error.response)
				dispatch(isUpdatingPlaneName(false))
				return Promise.reject()
			})
	}
}

/**
 * Action delete plant in item memo
 * @param {*} detailToken
 * @param {*} planeName
 */
export function deleteDetailPlaneName(detailToken, planeName) {
	return (dispatch, getState) => {
		dispatch(isDeleteDetailSchematic(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partNumbers/plots/remove`
		let data = {
			Name: planeName,
			TokenPartNumber: detailToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(deleteDetailSchemetic(detailToken))
				}
			})
			.catch((error) => {
				console.error('Error to get information from memo', error.response)
				dispatch(isDeleteDetailSchematic(false))
				return Promise.reject()
			})
	}
}

/**
 * Action get memo information data from backend
 */
export function getMemoInformationDataFromBackend(folio) {
	return function (dispatch, getState) {
		dispatch(startLoadMemo(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/memos`
		let data = {
			Folio: folio,
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CompanyCenter: selectedCompany.get('center'),
			CenterFin: selectedCompany.get('center_fin'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(gtInformationMemo(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to get information from memo', error.response)
				dispatch(startLoadMemo(false))
				return Promise.reject(error.response)
			})
	}
}

/**
 * Add supplier to a memo and send a quote request
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function addSupplierToMemoItem(memoPartNumber, supplier) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let data = {
			ProjectPartNumberToken: memoPartNumber.token,
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			SupplierToken: supplier.token,
			SupplierName: supplier.name,
			SupplierNumber: supplier.code,
			SupplierCountry: supplier.country,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addSupplierInMemoDetails(memoPartNumber.token, serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a quote for a memo', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Remove a supplier from the memo requests
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplierToken
 * @returns
 */
export function removeSupplierFromMemoItem(memoPartNumber, supplierToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let selectedCompany = getState().profile.get('selectedCompany')
		let folio = getState().memos.get('memo').get('folio')
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${selectedCompany.get(
			'token'
		)}/folio/${folio}/supplier/${supplierToken}/partNumber/${memoPartNumber.token}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(supplierRemovedFromItem(memoPartNumber.token, supplierToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error removing a supplier from a memo', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Add sample request supplier to a memo
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function onAddSampleRequestSupplier(memoPartNumber, quote) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			ProjectPartNumberToken: memoPartNumber.token,
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			SupplierToken: quote.supplier_token,
			SupplierName: quote.supplier_name,
			SupplierNumber: quote.supplier_code,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addSampleRequestSupplierInMemoItem(memoPartNumber.token, quote.supplier_token))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a sample request for a memo', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Remove sample request supplier to a memo
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function onRemoveSampleRequestSupplier(memoPartNumber, supplierToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/supplier/${supplierToken}/partNumber/${memoPartNumber.token}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeSampleRequestSupplierInMemoItem(memoPartNumber.token, supplierToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error remove a sample request for a memo', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action from send quotes and sample request from memo
 */
export function sendQuotesRequestFromMemo() {
	return (dispatch, getState) => {
		dispatch(sendMemoRequest(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let folio = getState().memos.get('memo').get('folio')
		let companyToken = getState().memos.get('memo').get('company_token')
		let documentIdentifier = selectedCompany.get('document_identifier')
		let data = {}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${folio}/company/${companyToken}/${documentIdentifier}/send`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(sendMemoRequest(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in send quotes and a sample request for a memo', error.response)
				dispatch(sendMemoRequest(false))
				return Promise.reject()
			})
	}
}

/**
 * Finalize the proccess of an memo
 * @param {*} tokenMemo
 */
export function finalizeMemoFromBackend(tokenMemo) {
	return function (dispatch, getState) {
		dispatch(isSavingMemo(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/projectdocument/closedproject/${tokenMemo}`

		return axios
			.put(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(finalizeMemo())
				}
			})
			.catch((error) => {
				console.error('Error finalize memo', error.response)
				dispatch(isSavingMemo(false))
				return Promise.reject(error.response)
			})
	}
}

/**
 * Action to update data memo from backend
 */
export function updateDataMemoFromBackend() {
	return function (dispatch, getState) {
		dispatch(isSavingMemo(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let folio = getState().memos.get('memoInformation').get('folio')
		let companyToken = getState().memos.get('memoInformation').get('company_token')
		let companyIdentifier = getState().memos.get('memoInformation').get('company_identifier')
		let data = {
			Folio: folio,
			CompanyToken: companyToken,
			CompanyIdentifier: companyIdentifier,
			CompanyCenter: selectedCompany.get('center'),
			CenterFin: selectedCompany.get('center_fin'),
		}

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/memos`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMemo(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error update data of an memo', error.response)
				dispatch(isSavingMemo(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to mark quote detail item as best option
 * @param {*} projectToken
 * @param {*} quoteItemToken
 */
export function markQuoteDetailItemAsBestOptionFromBackend(projectToken, quoteItemToken) {
	return function (dispatch, getState) {
		dispatch(isSavingMemo(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${projectToken}/${quoteItemToken}`
		return axios
			.post(endPoint, null, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(markQuoteDetailItemAsBestOption(response.data, quoteItemToken))
					dispatch(isSavingMemo(false))
				} else {
					dispatch(isSavingMemo(false))
				}
			})
			.catch((error) => {
				console.error('Error update user', error.response)
				dispatch(isSavingMemo(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to add comment
 *
 * @export
 * @param {*} item
 * @param {*} comments
 * @returns
 */
export function onAddComment(item, comments, requieredPlotArt) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			Description: comments,
			PartNumberToken: item.token,
			RequieredPlotArt: requieredPlotArt,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/project`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateComments(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding comments', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to upload files
 *
 * @export
 * @param {*} files
 * @returns
 */
export function onAddFiles(files, tokenPartNumber) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumbers/upload/${tokenPartNumber}`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse.data, tokenPartNumber))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error to upload file(s) in material delivery', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files
 *
 * @export
 * @param {*} fileName
 * @returns
 */
export function onRemoveFiles(fileName, tokenPartNumber) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/remove/${tokenPartNumber}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFile(fileName, tokenPartNumber))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to Download Memos by month report
 *
 * @export
 * @param {*} month
 * @returns
 */
export function downloadReportByDateRange(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(startLoadMemo(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/memos/downloadByRangeDate`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(startLoadMemo(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_memos.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(startLoadMemo(false))
				console.error('Error in download memos report by month', error.response)
				return Promise.reject()
			})
	}
}

export function addSupplierToMemoMassiveItem(partNumberTokens, supplier) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let itemData = []
		partNumberTokens.forEach((partNumberToken) => {
			itemData.push({
				ProjectPartNumberToken: partNumberToken,
				CompanyToken: selectedCompany.get('token'),
				CompanyIdentifier: selectedCompany.get('get_company_identifier'),
				CreatorUserName: user.profile.preferred_username,
				CreateBy: user.profile.name,
				SupplierToken: supplier.token,
				SupplierName: supplier.name,
				SupplierNumber: supplier.code,
				SupplierCountry: supplier.country,
			})
		})

		let data = {
			Items: itemData,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/addmultiplesquotes`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let responseData = serverResponse.data
					responseData.forEach((response) => {
						dispatch(addSupplierInMemoDetails(response.part_number_token, response))
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a quote to memo', error.response)
				return Promise.reject()
			})
	}
}

export function removeSupplierFromMemoMassive(partNumberTokens, supplierToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let folio = getState().memos.get('memo').get('folio')
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			ProjectPartNumberTokens: partNumberTokens,
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			Folio: folio,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(user, data)

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/deleteMultipleQuotes`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					partNumberTokens.forEach((partNumberToken) => {
						dispatch(supplierRemovedFromItem(partNumberToken, supplierToken))
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error removing a multiple quotes', error.response)
				return Promise.reject()
			})
	}
}

export function onAddSampleRequestSupplierMassive(partNumberTokens, supplier) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let itemData = []
		partNumberTokens.forEach((partNumberToken) => {
			itemData.push({
				ProjectPartNumberToken: partNumberToken,
				CompanyToken: selectedCompany.get('token'),
				CompanyIdentifier: selectedCompany.get('get_company_identifier'),
				CreatorUserName: user.profile.preferred_username,
				CreateBy: user.profile.name,
				SupplierToken: supplier.token,
				SupplierName: supplier.name,
				SupplierNumber: supplier.code,
				SupplierCountry: supplier.country,
			})
		})

		let data = {
			Items: itemData,
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/addmultiplesquotes`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					partNumberTokens.forEach((partNumberToken) => {
						dispatch(addSampleRequestSupplierInMemoItem(partNumberToken, supplier.token))
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a sample request for a notice change', error.response)
				return Promise.reject()
			})
	}
}

export function onRemoveSampleRequestSupplierMassive(partNumberTokens, supplierToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			ProjectPartNumberTokens: partNumberTokens,
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(user, data)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/deleteMultipleSampleRequests`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					partNumberTokens.forEach((partNumberToken) => {
						dispatch(removeSampleRequestSupplierInMemoItem(partNumberToken, supplierToken))
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error remove multiple sample request', error.response)
				return Promise.reject()
			})
	}
}
