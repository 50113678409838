import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'

import { Avatar, Card, CardContent, CardHeader, Fab, Typography } from '@material-ui/core'
import { Add, CreditCard } from '@material-ui/icons'
import SearchInput from '../common/SearchInput/SearchInput'

import DataTable from '../common/DataTable'
import Toaster from '../common/Toaster'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper.js'
import * as selectors from '../../store/sparePartPriceChange/sparePartPriceChangeSelector'
import {
	GetAllSparePartPriceChangeRequests,
	addRequest,
	getRequestsCount,
	ADD_REQUEST,
} from '../../store/sparePartPriceChange/sparePartPriceChangeAction'
import CreateRequestPanel from './components/CreateRequestPanel'
import LoadingPage from '../common/LoadingPage'

const IndexList = (props) => {
	const { classes } = props
	let location = useLocation()
	const history = useHistory()
	const dispatch = useDispatch()
	const queryParams = queryString.parse(location.search)
	const isLoadingList = useSelector((state) => state.sparePartPriceChange.get('isLoadingList'))
	const isSaving = useSelector((state) => state.sparePartPriceChange.get('isSaving'))
	const identifier = useSelector((state) => state.sparePartPriceChange.get('identifier'))
	const sparePartsPriceChangeRequests = useSelector((state) => selectors.getRequestsList(state))
	const pagination = useSelector((state) => selectors.getPagination(state))
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})
	/** Get user Information*/
	const userProfile = useSelector((state) => state.oidc.user)
	/** Get the information if you are an internal user*/
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')

	const [openLateralPanel, setOpenLateralPanel] = useState(false)

	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(GetAllSparePartPriceChangeRequests(queryParams))
	}, [dispatch, location.search])

	useEffect(() => {
		dispatch(getRequestsCount())
	}, [dispatch])

	/**
	 * Function to open side panel
	 */
	const onOpenLateralPanel = () => {
		setOpenLateralPanel(!openLateralPanel)
	}

	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * Function to add folio
	 * @param {*} itemModel
	 */
	const onAddRequest = (itemModel) => {
		return dispatch(addRequest(itemModel))
			.then((requestToken) => {
				onOpenLateralPanel()
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
				if (!IsNullOrEmpty(requestToken)) {
					history.push(`/sparePartPriceChange/show/${requestToken}`)
				}
			})
			.catch((response) => {
				console.log('response', response)
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToAdd',
					toasterVariant: 'error',
				})
			})
	}

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<CreditCard color='secondary' />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						<SearchInput
							onChangeSearchValue={onChangeSearchValue}
							value={!!queryParams && queryParams.query ? queryParams.query : ''}
						/>
						{!isInternalUser() && (
							<Fab
								size='small'
								color='primary'
								onClick={() => {
									onOpenLateralPanel()
								}}
							>
								<Add />
							</Fab>
						)}
					</div>
				}
				title={
					<Typography variant='button'>
						<Translate id='menu.priceChangeRequestPTM' />{' '}
					</Typography>
				}
				subheader={
					<div>
						<Translate id='common.showing' />
						&nbsp;
						<span>{pagination.total ? pagination.total : 0}</span>&nbsp;
						<Translate id='common.of' />
						&nbsp;
						<span>{pagination.total ? pagination.total : 0}</span>&nbsp;
						<Translate id='common.records' />
						&nbsp;
					</div>
				}
			/>
			<CardContent>
				{isLoadingList ? (
					<LoadingPage />
				) : (
					<DataTable
						showFilters
						isIndex
						data={sparePartsPriceChangeRequests}
						configuration={TableConfiguration(isInternalUser())}
						onRowClick={(request) => {
							history.push(`/sparePartPriceChange/show/${request.token}`)
						}}
						onChangeSortCriteria={onChangeSortCriteria}
						totalRows={pagination.total}
						sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'Folio'}
						sortAscending={
							queryParams && queryParams.sortDirection
								? queryParams.sortDirection === 'desc'
									? false
									: true
								: false
						}
						page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
						rowsPerPage={queryParams && queryParams.rowsPerPage ? parseInt(queryParams.rowsPerPage) : 20}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				)}
			</CardContent>
			{openLateralPanel && (
				<CreateRequestPanel
					onCloseButtonClick={onOpenLateralPanel}
					onSaveButtonClick={onAddRequest}
					isSaving={identifier === ADD_REQUEST && isSaving}
				/>
			)}
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</Card>
	)
}
function TableConfiguration(isUserInternal) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'status_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creationDate',
			isSortable: true,
		},
		{
			header: <Translate id='common.createdBy' />,
			dataSource: 'createdBy',
		},
	]
	if (isUserInternal) {
		columns.push({
			header: <Translate id='priceChangeRequest.index.table.supplier' />,
			dataSource: 'supplierNumber',
			isSortable: true,
		})
		columns.push({
			header: <Translate id='priceChangeRequest.index.table.supplier' />,
			dataSource: 'supplierName',
			isSortable: true,
		})
	}
	return { columns }
}
const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
	},
})
export default withStyles(styles, { withTheme: true })(IndexList)
