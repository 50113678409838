
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

/** Import component section */
import Select from 'react-select'
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import { GetTypeInvestmentTooling, GetMeasurementUnits, GetTimeUnit } from '../../../store/helpers/SelectOptions';

/**
 * Dialog to add tooling data to quote item
 */
class ToolingQuoteDialog extends Component {

    /**
     *Creates an instance of DialogActions.
    * @param {*} props
    * @memberof DialogActions
    */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            tooling: {}
        }
    }

    /**
     * React life cycle 
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof ToolingQuoteDialog
     */
    componentDidUpdate(prevProps, prevState) {
        //Se verifica si esta establecido un token de item, entonces se establece la informacion de tooling de ese item
        if (prevProps.quoteItemToken !== this.props.quoteItemToken && Object.keys(prevState.tooling).length === 0) {
            let index = this.props.detailsList.findIndex((item) => {
                return item.token === this.props.quoteItemToken
            });
            if (index >= 0) {
                this.setState({
                    tooling: this.props.detailsList[index].tooling
                })
            }
        }
    }

    /**
     * Function to update tooling property of text field in state
     *
     * @param {*} data
     * @memberof ToolingQuoteDialog
     */
    updateProperty(data) {
        this.setState({
            tooling: {
                ...this.state.tooling,
                [data.id]: data.value,
            }
        })
    }

    /**
     * Function to update tooling property of select in state
     *
     * @param {*} data
     * @param {*} property
     * @memberof ToolingQuoteDialog
     */
    updateSelectProperty(data, property) {
        this.setState({
            tooling: {
                ...this.state.tooling,
                [property]: data.value
            }
        })
    }

    /**
     * On save tooling quote, call saveToolingItem action from props
     *
     * @memberof ToolingQuoteDialog
     */
    onSaveToolingQuote() {
        if (this.state.tooling) {
            this.props.saveToolingItem(this.props.quoteItemToken, this.state.tooling)
        }
    }

    /**
     * Handle  Close or cancel event
     *
     * @memberof ToolingQuoteDialog
     */
    handleCloseCancel() {
        this.setState({ tooling: {} }, () => this.props.onClose());
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof ToolingQuoteDialog
     */
    isValidToSave() {
        if (this.state.tooling === null || this.state.tooling === undefined || Object.keys(this.state.tooling).length <= 0)
            return false
        if (this.state.tooling.inversion_amortization_type === 6 && (!this.state.tooling.others || this.state.tooling.others === null || this.state.tooling.others === ''))
            return false
        if (this.state.tooling.volume && this.state.tooling.volume !== null && this.state.tooling.volume !== '' &&
            (!this.state.tooling.volume_measurement_unit || this.state.tooling.volume_measurement_unit === null || this.state.tooling.volume_measurement_unit === ''))
            return false
        if ((this.state.tooling.inversion_amortization_type && this.state.tooling.inversion_amortization_type !== null && this.state.tooling.inversion_amortization_type !== '')
            && (this.state.tooling.description && this.state.tooling.description !== null && this.state.tooling.description !== '')
            && (this.state.tooling.total_cost && this.state.tooling.total_cost !== null && this.state.tooling.total_cost !== '')
            && (this.state.tooling.manufacturing_time && this.state.tooling.manufacturing_time !== null && this.state.tooling.manufacturing_time !== '')
            && (this.state.tooling.manufacturing_time_measurement_unit && this.state.tooling.manufacturing_time_measurement_unit !== null && this.state.tooling.manufacturing_time_measurement_unit !== '')) {
            return true;
        }

        return false
    }

    /**
     * Render a text field and a input inside a div like 1 field
     *
     * @param {*} textFieldId
     * @param {*} textFieldTranslateId
     * @param {*} selectId
     * @param {*} selectOptions
     * @returns
     * @memberof DetailsCard
     */
    renderTextFieldAndSelect(textFieldId, textFieldTranslateId, requiredTextField, selectId, selectOptions, requiredSelect) {
        return (
            <div>
                <div style={{ display: 'inline-flex', width: '45%' }}>
                    <TextField
                        id={textFieldId}
                        label={<Translate id={textFieldTranslateId}/>}
                        defaultValue={this.state.tooling[textFieldId] ? this.state.tooling[textFieldId] : ''}
                        margin="normal"
                        type="number"
                        fullWidth
                        required={requiredTextField ? requiredTextField : false}
                        onBlur={(event) => this.updateProperty(event.target)}
                        onKeyDown={this.onKeyDown}
                    />
                </div>
                <div style={{ display: 'inline-block', width: '55%', paddingLeft: '5px' }}>
                    <Select
                        id={selectId}
                        defaultValue={this.state.tooling ? selectOptions[this.state.tooling[selectId]] : ''}
                        options={selectOptions}
                        required={requiredSelect ? requiredSelect : false}
                        onChange={(option) => this.updateSelectProperty(option, selectId)}
                    />
                </div>
            </div>
        )
    }

    /**
     * Method to render TextField by params
     *
     * @param {*} textFieldId
     * @param {*} textFieldTranslateId
     * @param {*} required
     * @param {*} type
     * @param {*} multiple
     * @param {*} variant
     * @returns
     * @memberof ToolingQuoteDialog
     */
    renderOnlyTextField(textFieldId, textFieldTranslateId, required, type, multiple, variant) {
        return (
            <TextField
                id={textFieldId}
                label={<Translate id={textFieldTranslateId}/>}
                defaultValue={this.state.tooling ? this.state.tooling[textFieldId] : ''}
                margin="normal"
                type={type ? type : "number"}
                fullWidth
                required={required ? required : false}
                onBlur={(event) => this.updateProperty(event.target)}
                onKeyDown={this.onKeyDown}
                multiline={multiple ? multiple : false}
                rowsMax={multiple ? 5 : 1}
                variant={variant}
            />
        )
    }

    /**
     * Dialog to update information
     */
    render() {
        const { classes } = this.props;
        let optionsInvestment = GetTypeInvestmentTooling();
        let optionsMeasurementUnit = GetMeasurementUnits();
        let optionsTime = GetTimeUnit();
        let showExtra = this.state.tooling['inversion_amortization_type'] === 1
            || this.state.tooling['inversion_amortization_type'] === 3
            || this.state.tooling['inversion_amortization_type'] === 6;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id="quotes.tooling" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <div>
                        {!this.isValidToSave() &&
                            <Typography variant="caption" color='error'>
                                {<Translate id="common.requiredFields" />}
                            </Typography>
                        }
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => this.handleCloseCancel()}
                            onClose={() => this.handleCloseCancel()}
                            onSaveItem={this.onSaveToolingQuote}
                            isSaving={this.props.isSaving}
                            canBeSaved={this.isValidToSave()}
                        />
                    </div>
                }>

                <form >
                    <Select
                        id={'inversion_amortization_type'}
                        fullWidth
                        required
                        options={optionsInvestment}
                        defaultValue={this.state.tooling ? optionsInvestment[this.state.tooling['inversion_amortization_type'] ? this.state.tooling['inversion_amortization_type'] : ''] : ''}
                        onChange={(option) => this.updateSelectProperty(option, 'inversion_amortization_type')}
                    />
                    {this.state.tooling['inversion_amortization_type'] === 6 &&
                        this.renderOnlyTextField('others', 'quotes.otherInvestment', true, 'text')
                    }
                    {this.renderOnlyTextField('description', 'quotes.description', true, 'text', true)}
                    {this.renderOnlyTextField('total_cost', 'quotes.totalCost', true)}
                    {showExtra && this.renderTextFieldAndSelect('volume', "quotes.volume", false, 'volume_measurement_unit', optionsMeasurementUnit, false)}
                    {this.renderTextFieldAndSelect('manufacturing_time', "quotes.manufacturingTime", true, 'manufacturing_time_measurement_unit', optionsTime, true)}
                    {showExtra && this.renderOnlyTextField('piece_cost', 'quotes.pieceCost')}
                </form>
            </Dialog>
        )
    }
}

export default withRouter(ToolingQuoteDialog);