import { fromJS } from 'immutable'

import * as supplierActions from './SupplierActions'

/**
 * Suppliers initial state
 */
export const suppliersInitialState = fromJS({
	isLoadingSuppliers: false,
	suppliers: null,
	isLoadingSupplier: false,
	supplier: null,
	isLoadingInvoices: false,
	invoices: null,
	isLoadingStocks: false,
	stocks: null,
	isLoadingInventories: true,
	inventories: null,
	isLoadingConsignments: false,
	consignments: null,
	isLoadingPurchaseOrders: false,
	isLoadingReportSupplier: false,
	purchaseOrders: null,
	sortCriteria: {
		by: 'user_name',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	suppliersCount: null,
	advanceOptionsSupplier: null,
	isSaving: false,
	specPlanos: null,
	partnership: null,
	bancks: null,
	isLoadingBanck: false,
	followedSuppliers: null,
	isLoadingInventoryMaquilas: false,
	inventoryMaquilas: null,
	isDownloadQueries: false,
	salesContactList: null,
	isLoadingContact: false,
	supplierCarriers: [],
	isLoadingSupplierCarriers: false,
	isLoadingSapSupplier: false,
})

export default function suppliersReducer(state = suppliersInitialState, action) {
	switch (action.type) {
		case supplierActions.STAR_LOADING_SUPPLIERS: {
			return state.merge({
				isLoadingSuppliers: action.isLoading,
				salesContactList: null,
			})
		}
		case supplierActions.SET_SUPPLIERS_LIST: {
			let suppliersLists = fromJS(action.suppliers)
			return state.merge({
				suppliers: suppliersLists,
				isLoadingSuppliers: false,
			})
		}
		case supplierActions.SET_SUPPLIER_TYPES: {
			let suppliersLists = fromJS(action.suppliers)
			return state.merge({
				suppliersTypes: suppliersLists,
				isLoadingSuppliers: false,
			})
		}
		case supplierActions.CHANGE_SUPPLIERS_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case supplierActions.CHANGE_SUPPLIERS_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case supplierActions.CHANGE_SUPPLIERS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case supplierActions.SET_SUPPLIERS_COUNT: {
			return state.merge({
				suppliersCount: action.count,
			})
		}
		case supplierActions.IS_LOADING_SUPPLIER: {
			return state.merge({
				isLoadingSupplier: action.isLoadingSupplier,
				supplier: null,
			})
		}
		case supplierActions.SET_SUPPLIER: {
			return state.merge({
				isLoadingSupplier: false,
				supplier: fromJS(action.supplier),
			})
		}
		case supplierActions.SET_SUPPLIER_BY_QUERY: {
			let suppliersLists = fromJS(action.suppliers)
			return state.merge({
				suppliers: suppliersLists,
				isLoadingSuppliers: false,
			})
		}
		case supplierActions.STAR_LOADING_INVOICES: {
			return state.merge({
				invoices: null,
				isLoadingInvoices: action.isLoading,
			})
		}
		case supplierActions.SET_INVOICES_LIST: {
			let invoicesLists = fromJS(action.invoices)
			return state.merge({
				invoices: invoicesLists,
				isLoadingInvoices: false,
			})
		}
		case supplierActions.START_LOADING_STOCKS: {
			return state.merge({
				invoices: null,
				isLoadingStocks: action.isLoading,
			})
		}
		case supplierActions.SET_STOCKS_LIST: {
			let stocksLists = fromJS(action.stocks)
			return state.merge({
				stocks: stocksLists,
				isLoadingStocks: false,
			})
		}
		case supplierActions.START_LOADING_INVENTORIES: {
			return state.merge({
				inventories: null,
				isLoadingInventories: action.isLoading,
			})
		}
		case supplierActions.SET_INVENTORIES_LIST: {
			let inventoriesLists = fromJS(action.inventories)
			return state.merge({
				inventories: inventoriesLists,
				isLoadingInventories: false,
			})
		}
		case supplierActions.START_LOADING_INVENTORY_MAQUILAS: {
			return state.merge({
				inventoryMaquilas: null,
				isLoadingInventoryMaquilas: action.isLoading,
			})
		}
		case supplierActions.SET_INVENTORY_MAQUILAS: {
			let inventoriesLists = fromJS(action.inventoriesMaquilas)
			return state.merge({
				inventoryMaquilas: inventoriesLists,
				isLoadingInventoryMaquilas: false,
			})
		}
		case supplierActions.START_LOADING_CONSIGNMENTS: {
			return state.merge({
				consignments: null,
				isLoadingConsignments: action.isLoading,
			})
		}
		case supplierActions.SET_CONSIGNMENTS_LIST: {
			let consignmentsLists = fromJS(action.consignments)
			return state.merge({
				consignments: consignmentsLists,
				isLoadingConsignments: false,
			})
		}
		case supplierActions.START_LOADING_PURCHASE_ORDERS: {
			return state.merge({
				purchaseOrders: null,
				isLoadingPurchaseOrders: action.isLoading,
			})
		}
		case supplierActions.SET_PURCHASE_ORDERS_LIST: {
			let purchaseOrdersLists = fromJS(action.purchaseOrders)
			return state.merge({
				purchaseOrders: purchaseOrdersLists,
				isLoadingPurchaseOrders: false,
			})
		}
		case supplierActions.IS_DOWNLOAD_QUERIES: {
			return state.merge({
				isDownloadQueries: action.isDonwload,
			})
		}
		case supplierActions.SET_ADVANCE_OPTIONS_SUPPLIER: {
			return state.merge({
				isLoadingSupplier: false,
				isSaving: false,
				advanceOptionsSupplier: fromJS(action.supplier),
			})
		}
		case supplierActions.IS_SAVING: {
			return state.merge({
				isSaving: action.isSaving,
			})
		}
		case supplierActions.SET_PARTNERSHIP_SUPPLIER: {
			return state.merge({
				isLoadingSupplier: false,
				isSaving: false,
				partnership: fromJS(action.partnership),
			})
		}
		case supplierActions.START_BANCKS_LIST_SUPPLIER: {
			return state.merge({
				isLoadingBanck: action.isLoadingBanck,
			})
		}
		case supplierActions.SET_BANCKS_LIST_SUPPLIER: {
			return state.merge({
				isLoadingBanck: false,
				bancks: fromJS(action.bancks),
			})
		}
		case supplierActions.SET_SPECIFICATIONS: {
			return state.merge({
				specPlanos: action.specPlanos,
				isSaving: false,
			})
		}
		case supplierActions.SET_ENABLE_SUPPLIER: {
			let optionSupplier = state.get('advanceOptionsSupplier')
			optionSupplier = optionSupplier.set('locked', action.enabled)
			return state.merge({
				isLoadingUser: false,
				isSaving: false,
				advanceOptionsSupplier: optionSupplier,
			})
		}
		case supplierActions.SET_FOLLOWED_SUPPLIERS: {
			return state.merge({
				followedSuppliers: fromJS(action.followedSuppliers),
			})
		}
		case supplierActions.ADD_FOLLOWED_SUPPLIER_TO_USER: {
			let supplier = fromJS(action.item)
			let suppliersList = state.get('followedSuppliers').push(supplier)
			return state.merge({
				followedSuppliers: suppliersList,
			})
		}
		case supplierActions.IS_DOWNLOADING_REPORT_EMPLOYEES: {
			return state.merge({
				isLoadingReportSupplier: action.isLoading,
			})
		}
		case supplierActions.REMOVE_FOLLOWED_SUPPLIER_TO_USER: {
			let actualSuppliers = state.get('followedSuppliers')
			if (actualSuppliers) {
				let updatedSuppliers = actualSuppliers.filter((supplierFollowed) => {
					return !(supplierFollowed.get('supplier_token') === action.supplierToken)
				})
				return state.merge({
					followedSuppliers: updatedSuppliers,
				})
			} else {
				return state
			}
		}
		case supplierActions.SET_SALES_CONTACT: {
			return state.merge({
				salesContactList: action.contactList,
			})
		}
		case supplierActions.IS_LOADING_CONTACT: {
			return state.merge({
				isLoadingContact: action.isLoading,
			})
		}
		case supplierActions.SET_SUPPLIERS_CARRIERS: {
			return state.merge({
				supplierCarriers: action.suppliers,
			})
		}
		case supplierActions.IS_LOADING_SUPPLIERS_CARRIERS: {
			return state.merge({
				isLoadingSupplierCarriers: action.isloadingCarries,
			})
		}
		case supplierActions.IS_LOADING_SAP_SUPPLIER: {
			return state.merge({
				isLoadingSapSupplier: action.isLoadingSapSupplier,
			})
		}
		default:
			return state
	}
}
