import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import { TextField, Toolbar, Typography } from '@material-ui/core'

import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'

import { Translate } from 'react-localize-redux'

const DialogCommentReject = (props) => {
	const { openRejectRequest, classes, isSaving, commentsReject, quoteSelected } = props
	const { onClose, onSaveStatusQuote, updateRejectProperty, onKeyDown } = props
	return (
		<Dialog
			open={openRejectRequest}
			onClose={onClose}
			header={
				<Toolbar>
					<Typography variant='h6'>
						<Translate id='statusDescriptions.quoteDeclinedBySupplier' />
						{` ${quoteSelected.folio}`}
					</Typography>
				</Toolbar>
			}
			actions={
				<DialogActions
					view={SHOW_EDIT_VIEW}
					classes={classes}
					onCancel={onClose}
					onClose={onClose}
					onSaveItem={onSaveStatusQuote}
					isSaving={isSaving}
					canBeSaved={commentsReject !== ''}
				/>
			}
		>
			<form>
				<TextField
					fullWidth
					label={<Translate id='samplesrequest.motiveDecline' />}
					className={classes.textField}
					multiline
					rowsMax='8'
					value={commentsReject}
					onChange={(event) => updateRejectProperty(event.target.value)}
					onKeyDown={onKeyDown}
					margin='normal'
					variant='outlined'
				/>
			</form>
		</Dialog>
	)
}
DialogCommentReject.propTypes = {
	openRejectRequest: propTypes.bool,
	isSaving: propTypes.bool,
	commentsReject: propTypes.string,
	quoteSelected: propTypes.string,
	onClose: propTypes.func,
	onSaveStatusQuote: propTypes.func,
	updateRejectProperty: propTypes.func,
	onKeyDown: propTypes.func,
}
DialogCommentReject.defaultProps = {
	openRejectRequest: false,
	isSaving: false,
	commentsReject: '',
	quoteSelected: '',
	onClose: () => console.warn('No [onClose] Callback defined'),
	onSaveStatusQuote: () => console.warn('No [onSaveStatusQuote] CallBack defined'),
	updateRejectProperty: () => console.warn('No [updateRejectProperty] CallBack defined'),
	onKeyDown: () => console.warn('No [onKeyDown] CallBack defined'),
}
const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	actionsToolbar: {
		flexGrow: 1,
	},
	logoCompany: {
		maxHeight: 100,
	},
	logoSupplier: {
		marginLeft: 80,
		maxHeight: 80,
	},
	tableHead: {
		backgroundColor: '#fff',
		position: 'sticky',
		zIndex: '30',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 500,
		marginTop: 10,
		marginBottom: 10,
	},
	tableContainer: {
		maxWidth: 200,
	},
	parnertPadding: {
		padding: 15,
	},
})
export default withRouter(withStyles(styles, { withTheme: true })(DialogCommentReject))
