import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import moment from 'moment'
import Select from 'react-select'

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import DatePicker from '../../common/DateTimePicker/DatePicker';
import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper';
import {GetMeasurementUnits} from '../../../store/helpers/SelectOptions';


/**
 * Component to send date SendEffectiveDate
 */
class SendEffectiveDate extends Component{

    /**
     * Create an instance of SendEffectiveDate
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render(){
        const { classes } = this.props;
        let measurements = GetMeasurementUnits();
        if(this.props.notificationNotice && this.props.notificationNotice.response_document === null && this.props.notificationNotice.can_update_supplier && (IsCurrentNotActiveDirectoryUser(this.props.currentUser))){
            return(
                <List>
                    <ListItem>
                        <ListItemText primary={<Typography variant="body2" gutterBottom><Translate id="noticeChanges.efectiveDate"/></Typography>} 
                            secondary={
                                <React.Fragment>
                                    <DatePicker
                                        onChangeRange={this.props.onChangeRange}
                                        startDate={this.props.effectiveDate}
                                        minDate={moment()}
                                    />
                                    <Translate id="noticeChanges.efectiveDateMessage"/>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                     <ListItem>
                        <ListItemText secondary={
                            <React.Fragment>
                                <TextField
                                    className={classes.textField}
                                    label={<Translate id="noticeChanges.inventory"/>}
                                    defaultValue={this.props.inventory}
                                    type="number"
                                    onChange={(event) => {this.props.updateProperty( event.target.value)}}
                                />
                            </React.Fragment>
                            }
                        />
                        <ListItemSecondaryAction>
                            <React.Fragment>
                                <div className={classes.marginSelect}>
                                    <Typography variant="body2" gutterBottom> {<Translate id="noticeChanges.inventoryMessage"/>}</Typography>
                                    <Select 
                                        value={measurements[this.props.measurementUnit]}
                                        options={measurements}
                                        onChange={this.props.onChangeMeasurementUnit}
                                    />
                                </div>
                            </React.Fragment>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <br/><br/><br/><br/>
                    <br/><br/><br/><br/>
                </List>
            )
        }
        else if(this.props.notificationNotice && this.props.notificationNotice.response_document){
            let responseDocument = this.props.notificationNotice.response_document;
            return(
                <List>
                    <ListItem>
                        <ListItemText primary={<Typography variant="body2" gutterBottom>
                            <strong>{<Translate id="noticeChanges.efectiveDate"/>}: </strong>
                            {responseDocument.effective_date_time}</Typography>} />
                    </ListItem><Divider />
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography variant="body2" gutterBottom><strong> {<Translate id="noticeChanges.inventoryMessage"/>}: </strong></Typography>
                                    {responseDocument.measurement_unit !== 0 && responseDocument.measurement_unit !== "" &&
                                        <span>
                                            {responseDocument.inventory}{" "}{measurements[responseDocument.measurement_unit].label}
                                        </span>}
                                </React.Fragment>
                            } 
                            />
                    </ListItem><Divider />
                </List>
            )
        }
        else{
            return("");
        }
    }
} 


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    actionsToolbar: {
        flexGrow: 1,
    },
    logoCompany: {
        marginLeft: 100,
        maxHeight: 80
    },
    tableHead: {
        backgroundColor: "#fff",
        position: "sticky",
        zIndex: '30',
    },
    marginSelect:{
        marginRight: theme.spacing.unit * 3, 
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
      },
      inline:{},
      paper:{ },
      root:{ },
      logoSupplier:{ }
});


export default withRouter(withStyles(styles, { withTheme: true })(SendEffectiveDate));