import React, { Component } from 'react';
import { Translate, getTranslate } from 'react-localize-redux';
import autoBind from 'auto-bind'
import uuidv4 from 'uuid/v4'
import moment from 'moment';
import { connect } from 'react-redux'

/** Components imports section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import { withRouter } from "react-router-dom";
import Toaster from '../common/Toaster';
import DataTable from '../common/DataTable';
import Evidence from './../common/Evidence';

/** Material UI imports section */
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
/** Material UI Icons imports section */
import RatesIcon from '@material-ui/icons/BarChartTwoTone';
import ImportIcon from '@material-ui/icons/ImportExport';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import { COMPANY_IDENTIFIER_IMBERA_REPARE } from '../../store/helpers/ResourcesHelper';

import {
    loadRatingToPushishFromBackEnd, uploadSuppliersFile,
    uploadRatingDeliveryFiles, uploadRatingStopInLineQualityFiles,
    uploadRatingPpmsQualityFiles, generateRatingDevelopmentSip,
    generateRatings
} from '../../store/ratings/ratingActions';
import {loadEvaluationSuppliersRatingByCompanyFromBackEnd} from '../../store/ratings/ratingActions';

import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER } from './../../store/helpers/StatusColorConstants';
import { Tooltip } from '@material-ui/core';

/**
 * Class container to show Generate ratings view
 *
 * @class GenerateRatings
 * @extends {Component}
 */
class GenerateRatings extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            suppliersFile: [],
            deliveriesFile: [],
            stopLinesFile: [],
            qualityPpmsFile: [],
            fileBase64: "",
        }
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        if (!this.props.isLoadingUser) {
            this.props.loadRatingToPushish();
            this.props.loadEvaluationSuppliersRatingByCompany(this.props.selectedCompany.token);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : "";
        if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
            this.props.loadRatingToPushish();
            this.props.loadEvaluationSuppliersRatingByCompany(this.props.selectedCompany.token);
        }
    }

    handleUploadFiles(files, listName) {
        if (listName === 'suppliersFile' && this.props.uploadSuppliersFile)
            return this.props.uploadSuppliersFile(files)
                .then(() => {
                    return Promise.resolve('Ok')
                })
                .catch(() => {
                    this.showToasterError(listName)
                    return Promise.reject()
                })
        if (listName === 'deliveriesFile' && this.props.uploadDeliveriesFile)
            return this.props.uploadDeliveriesFile(files)
                .then(() => {
                    return Promise.resolve('Ok')
                })
                .catch(() => {
                    this.showToasterError(listName)
                    return Promise.reject()
                })
        if (listName === 'stopLinesFile' && this.props.uploadStopLineFile)
            return this.props.uploadStopLineFile(files)
                .then(() => {
                    return Promise.resolve('Ok')
                })
                .catch(() => {
                    this.showToasterError(listName)
                    return Promise.reject()
                })
        if (listName === 'qualityPpmsFile' && this.props.uploadPpmsFile)
            return this.props.uploadPpmsFile(files)
                .then(() => {
                    return Promise.resolve('Ok')
                })
                .catch(() => {
                    this.showToasterError(listName)
                    return Promise.reject()
                })
        return Promise.reject()
    }

    /**
     * Action to publish rating
     */
    onGenerateRatings() {
        this.props.generateRatings()
        .then(() => {
            this.setState({
                showToView: true,
                showToaster: true,
                toasterVariant: "success",
                toasterMessage: <Translate id="common.saveCorrectly" />,
            })
        }).catch(() => {
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="common.errorToSave" />,
                toasterVariant: "error",
            })
        });
    }

    /**
     * Function to show toaster error with custom message for each uploaded file
     *
     * @param {*} listName
     * @memberof GenerateRatings
     */
    showToasterError(listName) {
        let errorMessage = this.props.translate(errorsToUploadDictionary[listName])
            + ' ' + this.props.translate('rating.baseErrorRatingsUpload');
        this.setState({
            showToaster: true,
            toasterVariant: "error",
            toasterMessage: errorMessage,
        })
    }

    onRenderRatingsCellItem(dataSource, item) {
        if (dataSource === 'supplier_name') {
            return (`${item['supplier_number']} | ${item[dataSource]}`)
        }
        if (dataSource === 'final_score') {
            return (<Typography style={{ color: item.color_final_score }}>{item[dataSource]}</Typography>)
        }
    }
    /**
     * Render ListItem for each section required to generate ratings
     *
     * @param {*} idTitleTranslate
     * @param {*} status
     * @param {*} idDropMessageTranslate
     * @param {*} filesId
     * @param {boolean} [isSuppliersEvaluation=false]
     * @param {boolean} [isDrop=true]
     * @returns
     * @memberof GenerateRatings
     */
    renderListSection(idTitleTranslate, status, idDropMessageTranslate, filesId, isSuppliersEvaluation = false, isDrop = true) {
        return (
            <ListItem key={uuidv4()} divider>
                <Grid container spacing={24}>
                    <Grid item xs={3}>
                        <ListItemText
                            primary={<Typography component="p" style={{ width: 'inherit !important' }}><Translate id={idTitleTranslate} /></Typography>}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ListItemText
                            primary={<Typography component="p" style={{ width: 'inherit !important', color: status ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER }}>
                                <Translate id={status ?
                                    (isSuppliersEvaluation ? "rating.evaluatingSuppliers" : "rating.generated")
                                    : 'rating.withoutEvaluate'
                                } />
                            </Typography>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {status && <ListItemText
                            style={{ textAlign: "center" }}
                            primary={
                                <Tooltip title={<Translate id="common.doneProcess" />}>
                                    <CheckCircleIcon style={{ color: COLOR_STATUS_SUCCESS }} />
                                </Tooltip>}
                        />}
                        {(isDrop && !status) &&
                            <ListItemText
                                primary={<Typography component="p" style={{ width: 'inherit !important' }}><Translate id={idDropMessageTranslate} /></Typography>}
                                secondary={
                                    <Evidence
                                        baseApi={process.env.REACT_APP_SIP_URL_FILES}
                                        removeFiles={() => { }}//The prop is Required by the component but this case is not functional
                                        uploadFiles={(event) => this.handleUploadFiles(event, filesId)}
                                        files={this.state[filesId]}
                                        acceptedFiles={['excel']}
                                        maxFiles={1}
                                        disabledLink
                                        isDisabled={status}
                                    />
                                }
                            />}
                        {(!isDrop && !status) &&
                            <ListItemText
                                primary={
                                    <Button
                                        fullWidth variant="contained"
                                        color="primary" disabled={status || this.props.isSaving}
                                        onClick={() => { this.props.generateDevelopmentSip() }}>
                                        <ImportIcon className={'classes.rightIcon'} /><Translate id='rating.buttonAreasToEvaluate' />
                                    </Button>}
                                secondary={
                                    <Typography variant="caption" style={{ width: 'inherit !important' }}><Translate id={idDropMessageTranslate} /></Typography>
                                }
                            />}
                    </Grid>
                </Grid>
            </ListItem>
        )
    }

    renderListSections() {
        let { ratingToPublish } = this.props;
        let month = moment().subtract(1, 'months').format("MMMM");
        let year = moment().subtract(1, 'months').format("YYYY")
        let isSuppliersLoaded = ratingToPublish && Object.keys(ratingToPublish).length > 0;
        return (
            <List >
                <Divider />
                <ListItem key={'generateKey'} divider>
                    <Grid container spacing={24}>
                        <Grid item xs={6}>
                            <ListItemText
                                primary={<Typography component="p" style={{ width: 'inherit !important' }}><Translate id="rating.monthAndYear" /></Typography>}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ListItemText
                                primary={
                                    <Typography component="span" variant="body2" color="textPrimary" style={{ textAlign: isSuppliersLoaded && ratingToPublish.is_rating_to_publish ? "center" : "left" }}>
                                        <Translate id="rating.monthToGenerate" /><strong>{` ${month} `}</strong>
                                        <Translate id="rating.yearToGenerate" /><strong>{` ${year}.`}</strong>
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </ListItem>

                {this.renderListSection("rating.suppliersToEvaluate", isSuppliersLoaded, "rating.importSuppliersToEvaluate", "suppliersFile", true)}

                {isSuppliersLoaded && this.renderListSection("rating.deliveries", ratingToPublish.is_delivery_publich, "rating.importDeliveries", "deliveriesFile")}
                {isSuppliersLoaded && this.renderListSection("rating.stopLines", ratingToPublish.is_quality_online_publish, "rating.importStopLines", "stopLinesFile")}
                {isSuppliersLoaded && this.renderListSection("rating.qualityPpms", ratingToPublish.is_quality_ppms_publish, "rating.importQualityPpms", "qualityPpmsFile")}
                {isSuppliersLoaded && this.renderListSection("rating.areasToEvaluate", ratingToPublish.is_development_publish, "rating.captionAreasToEvaluate", "", false, false)}
            </List>
        )
    }

    render() {
        let { ratingToPublish } = this.props;
        let alreadyPublished = ratingToPublish && ratingToPublish.is_rating_to_publish;
        let isDisabled = ratingToPublish
            && ratingToPublish.is_delivery_publich
            && ratingToPublish.is_quality_online_publish
            && ratingToPublish.is_quality_ppms_publish
            && ratingToPublish.is_development_publish
        let evaluationRating = this.props.evaluationRating;
        let tableConfiguration = TableratingsTableConfiguration(evaluationRating, this.props.translate);
        if(this.props.selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE)
        {
            tableConfiguration = TableratingsRepareConfiguration(this.props.translate); 
        }
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <RatesIcon />
                        </Avatar>
                    }
                    title={<Typography variant="button">{<Translate id="rating.generateRating" />} </Typography>}
                />
                <CardContent>
                    <Grid container spacing={24} >
                        <Grid item xs={12} className="alert alert-info" role="alert" style={{ marginLeft: '.7rem', marginRight: '.7rem' }}>
                            {!alreadyPublished && <React.Fragment>
                                <h6 className="alert-heading">
                                    <InfoIcon />
                                    <Translate id="rating.messatoToGenerateRatings" />
                                    <Typography component="p"><strong><Translate id="rating.areasToGenerateRatings" /></strong></Typography>
                                </h6>
                            </React.Fragment>}
                            {alreadyPublished && <React.Fragment>
                                <h6 className="alert-heading">
                                    <InfoIcon />
                                    <Translate id="rating.generatedRatingsCurrentMonth" />
                                </h6>
                            </React.Fragment>}
                        </Grid>
                        {!alreadyPublished &&<Grid item xs={12} style={{ marginLeft: '.7rem', marginRight: '.7rem' }}>
                        <Grid container justify="flex-end" alignItems="center">
                                <Grid item xs={3}>
                                    <Button variant="contained" color="primary" fullWidth
                                        disabled={!isDisabled}
                                        onClick={() => { this.onGenerateRatings() }}>
                                        <ImportIcon
                                        /><Translate id='rating.publishRatings' />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>}
                        <Grid item xs={12}>
                            {this.renderListSections()}
                            <Typography variant="caption" component="p"><Translate id="rating.captionTimeToGenerate" /></Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '.5rem' }}>
                            <Divider />
                            <DataTable
                                title={<Translate id="companies.functionModules.SupplierQualification" />}
                                data={this.props.ratingToPublish && this.props.ratingToPublish.ratings ? this.props.ratingToPublish.ratings : []}
                                configuration={tableConfiguration}
                                onRenderCellItem={this.onRenderRatingsCellItem}
                            />
                        </Grid>
                    </Grid>

                </CardContent>
                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Card>
        );
    }
}

const errorsToUploadDictionary = {
    suppliersFile: 'rating.errorImportingSuppliers',
    deliveriesFile: 'rating.errorImportingDeliveryRatings',
    stopLinesFile: 'rating.errorImportingStopLineRatings',
    qualityPpmsFile: 'rating.errorImportingPpmsRatings',
}


function TableratingsTableConfiguration(evaluation, translate) {
    let columns = [
        {
            header: '',
            dataSource: 'status_indicator',
        }, {
            header: <Translate id="common.supplier" />,
            dataSource: 'supplier_name',
        },
        {
            header:  `${translate && translate('rating.ratingsTable.deliveries')} ${evaluation ? `${evaluation.delivery_points}` : '25'}%`,
            dataSource: 'delivery_points',
        }, {
            header:  `${translate && translate('rating.ratingsTable.development')} ${evaluation ? `${evaluation.sourcing_points}` : '25'}%`,
            dataSource: 'development_points',
        }, {
            header:  `${translate && translate('rating.ratingsTable.quality')} ${evaluation ? `${evaluation.quality_points}` : '40'}%`,
            dataSource: 'quality_points',
        }, {
            header:  `${translate && translate('rating.ratingsTable.service')} ${evaluation ? `${evaluation.service_points}` : '10'}%`,
            dataSource: 'service_points',
        }, {
            header: <Translate id="rating.ratingsTable.finalScore" />,
            dataSource: 'final_score',
        }];
    return ({ columns });
}

function TableratingsRepareConfiguration(translate) {
    let columns = [
        {
            header: '',
            dataSource: 'status_indicator',
        }, {
            header: <Translate id="common.supplier" />,
            dataSource: 'supplier_name',
        },
        {
            header:  `${translate && translate('rating.ratingsTable.deliveries')} ${'45'}%`,
            dataSource: 'delivery_points',
        }, {
            header:  `${translate && translate('rating.ratingsTable.quality')} ${'45'}%`,
            dataSource: 'quality_points',
        }, {
            header:  `${translate && translate('rating.ratingsTable.service')} ${'10'}%`,
            dataSource: 'service_points',
        }, {
            header: <Translate id="rating.ratingsTable.finalScore" />,
            dataSource: 'final_score',
        }];
    return ({ columns });
}

// {
//     header: <Translate id="rating.ratingsTable.warrantyPenalty" />,
//     dataSource: 'guarantee_points',
// },


/**
 *  Defines the properties injecteded from the store to view container
* @param {*} state
            */
function mapStateToProps(state) {
    let selectedCompany = state.profile.get('selectedCompany')
    return {
        isLoadingUser: state.oidc.isLoadingUser,
        selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
        isLoadingRating: state.ratings.get("isLoadingRating"),
        isSaving: state.ratings.get("isSaving"),
        ratingToPublish: state.ratings.get("ratingToPublish") ? state.ratings.get("ratingToPublish").toJS() : null,
        translate: getTranslate(state.localize),
        evaluationRating: state.ratings.get('evaluationRating') ? state.ratings.get('evaluationRating').toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component
* @param {*} dispatch
        */
const mapDispatchToProps = dispatch => {
    return {
        loadRatingToPushish: () => {
            return (dispatch(loadRatingToPushishFromBackEnd()))
        },
        uploadSuppliersFile: (files) => {
            return (dispatch(uploadSuppliersFile(files)))
        },
        uploadDeliveriesFile: (files) => {
            return (dispatch(uploadRatingDeliveryFiles(files)))
        },
        uploadStopLineFile: (files) => {
            return (dispatch(uploadRatingStopInLineQualityFiles(files)))
        },
        uploadPpmsFile: (files) => {
            return (dispatch(uploadRatingPpmsQualityFiles(files)))
        },
        generateDevelopmentSip: () => {
            return (dispatch(generateRatingDevelopmentSip()))
        },
        generateRatings: () => {
            return (dispatch(generateRatings()))
        },
        loadEvaluationSuppliersRatingByCompany:(companyToken) =>{
            return dispatch(loadEvaluationSuppliersRatingByCompanyFromBackEnd(companyToken));
        }

    }
}

export default withauthenticatedLayout(withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateRatings)));