///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import PurchaseIcon from '@material-ui/icons/CalendarTodayOutlined'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { useDispatch, useSelector } from 'react-redux'
import { loadReleasePlanFrom, loadReleasePlanFromBackEnd } from '../../store/plannigFersa/ReleasePlanningAction'
import { getMaterialsRelease, getreleasePlanning } from '../../store/plannigFersa/ReleasePlanningSelector'
import { Translate } from 'react-localize-redux'
//import LoadingPage from '../common/LoadingPage'
import { AppBar, Tab, Tabs } from '@material-ui/core'
import ShowRelease from './ShowRelease'
import moment from 'moment'
import LoadingPage from '../common/LoadingPage'
import { converListToArray } from '../../store/helpers/ListHelper'
import { loadPartnership, loadSupplierCode } from '../../store/suppliers/SupplierActions'

import EditIcon from '@material-ui/icons/Edit'
import AddItemInRelease from './components/AddItemInRelease'
import RemoveItemInRelease from './components/RemoveItemInRelease'
import DeleteIcon from '@material-ui/icons/Delete'
import { canUpdateAddMaterialsInRelease } from '../../store/helpers/RolesHelper'
import Toaster from '../common/Toaster'

/** Language imports **/

const Release = (props) => {
	const dispatch = useDispatch()
	const [selectdTab, setSelectedTab] = useState(0)
	const [refresh, setRefresh] = useState(1)
	const [toaster, setToaster] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
	})

	const isLoading = useSelector((state) => state.releasePlanning.get('isLoadingReleasePlanning'))
	const isOpenLateralMenu = useSelector((state) => state.profile.get('isOpenLateralMenu'))
	const release = useSelector((state) => getreleasePlanning(state))
	const items = useSelector((state) => getMaterialsRelease(state))
	const handleChange = (event, newValue) => {
		setSelectedTab(newValue)
	}
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const companyToken = useSelector((state) =>
		state.profile.get('selectedCompany') ? state.profile.get('selectedCompany').toJS() : null
	)
	const partnership = useSelector((state) =>
		state.suppliers.get('partnership') ? state.suppliers.get('partnership').toJS() : null
	)
	useEffect(() => {
		dispatch(loadSupplierCode(props.match.params.supplierCode))
		dispatch(loadReleasePlanFrom(props.match.params.supplierCode))
		dispatch(loadPartnership(props.match.params.supplierCode, companyToken.token))
	}, [dispatch])

	const onUpdateReleasePlan = () => {
		dispatch(loadReleasePlanFromBackEnd(props.match.params.supplierCode))
		setSelectedTab(0)
	}
	const onUpdateRemoveItemReleasePlan = () => {
		dispatch(loadReleasePlanFromBackEnd(props.match.params.supplierCode))
	}

	return (
		<Card>
			<CardHeader
				title={
					<Typography variant='button'>
						<Translate id='releasePlan.releasePlan' />: {release ? release.suppliercode : ''} |{' '}
						{release ? release.supplierName : ''}
					</Typography>
				}
				subheader={
					<span>
						{<Translate id='releasePlan.publicationDate' />}:{' '}
						{release.publicationDate ? moment(release.publicationDate).format('YYYY/MM/DD  HH:mm') : ''}
					</span>
				}
				avatar={
					<Avatar>
						<PurchaseIcon />
					</Avatar>
				}
			/>
			{isLoading && <LoadingPage />}
			{isLoading === false && (
				<CardContent>
					{' '}
					<div>
						{canUpdateAddMaterialsInRelease(userRolesByCompany) && (
							<>
								<AppBar position='static' color='default'>
									<Tabs
										indicatorColor='secondary'
										textColor='secondary'
										value={selectdTab}
										onChange={handleChange}
									>
										<Tab
											label={
												<p>
													<PurchaseIcon /> <Translate id='releasePlan.releasePlan' />
												</p>
											}
										/>
										<Tab
											label={
												<p>
													<EditIcon /> <Translate id='releasePlan.addMaterial' />
												</p>
											}
										/>
										<Tab
											label={
												<p>
													<DeleteIcon /> <Translate id='releasePlan.removeMaterial' />{' '}
												</p>
											}
										/>
									</Tabs>
								</AppBar>
							</>
						)}

						{selectdTab === 0 && (
							<ShowRelease
								setToaster={setToaster}
								items={items}
								release={release}
								setRefresh={setRefresh}
								refresh={refresh}
								userRolesByCompany={userRolesByCompany}
								partnership={partnership}
								isOpenLateralMenu={isOpenLateralMenu}
							/>
						)}
						{selectdTab === 1 && <AddItemInRelease updateRelease={onUpdateReleasePlan} />}
						{selectdTab === 2 && <RemoveItemInRelease updateRelease={onUpdateRemoveItemReleasePlan}/>}
					</div>
					<Toaster
						message={toaster.toasterMessage}
						open={toaster.showToaster}
						variant={toaster.toasterVariant}
						onClose={() => {
							setToaster({
								showToaster: false,
								toasterMessage: '',
								toasterVariant: ''
							});
						}}
					/>
				</CardContent>
			)}
		</Card>
	)
}

const styles = (theme) => ({
	logo: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	marginIcon: {
		marginLeft: '5px',
	},
	marginIconCalendar: {
		marginLeft: '5px',
		//backgroundColor: COLOR_SUSTAINABILITY_PLUM,
	},
	marginIconFile: {
		marginLeft: '5px',
		//backgroundColor: COLOR_SUSTAINABILITY_ROSE,
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
	styleTab: {
		backgroundColor: '#fff',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Release)))
