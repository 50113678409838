import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import RawMaterialNonConfIcon from '@material-ui/icons/BugReport';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Toaster from '../common/Toaster';
import FormViewEdit from './components/FormViewEdit';
import LoadingPage from '../common/LoadingPage';

import {loadRawMaterialNonConformityFromBackEnd, editRawMaterialNonConformityFromBackEnd} from '../../store/rawMaterialNonConformity/RawMaterialActions';

/**
 * Component to EditReport raw material supplier
 */
class EditReport extends Component{

     /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
        }
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        let rawMaterialToken = this.props.match.params.token;
        this.props.loadRawMaterialNonConformity(rawMaterialToken);
    }

    /**
     * Get action to send corrective action 
     */
    saveEditedQualityFailureReport(preparedBy, supplier, partNumber, failure, rawMaterial){
        this.props.editRawMaterialNonConformityFromBackEnd(preparedBy, supplier, partNumber, failure, rawMaterial)
        .then(() =>{
            this.setState({
                showToView:true,
                showToaster: false,
            })
            let rawMaterialToken = this.props.match.params.token;
            this.props.history.push(`/rawmaterialnonconformity/${rawMaterialToken}`)
        }).catch(() =>{
            this.setState({
                showToaster:true,
                toasterMessage: <Translate id="common.errorToSave"/>,
                toasterVariant: "error",
            })
        });
    }

      /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.rawMaterial !== null) {
            return (
                <FormViewEdit  
                    {...this.props}
                    isSaving = {this.props.isSaving}
                    rawMaterial={this.props.rawMaterial}
                    canBeSaveToEdit={this.saveEditedQualityFailureReport}
                />
            )
        }
    }

    render(){
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <RawMaterialNonConfIcon />
                    </Avatar>
                }
                title={<Typography variant="button" ><Translate id="rawmaterialnonconformity.informationRawMaterial"/></Typography>}
                subheader={<Typography variant="caption" color="textPrimary"><Translate id="common.createBy"/>: {this.props.rawMaterial && this.props.rawMaterial.create_by}
                 <br/><Translate id="common.creationDate"/>: {this.props.rawMaterial && this.props.rawMaterial.creation_date}</Typography>}
            />
            <CardContent>
                {this.getCardContent()}
            </CardContent>

            <Toaster 
                message = {this.state.toasterMessage}
                open = {this.state.showToaster}
                variant = {this.state.toasterVariant}
                onClose = {() => {this.setState({ showToaster:false})}}
            /> 

        </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    let rawMaterial = state.rawMaterialNonConformity.get("rawMaterial");
    let selectedCompany = state.profile.get('selectedCompany');
    return {
        user: user,
        isSaving: state.rawMaterialNonConformity.get('isSaving'),
        isLoading: state.rawMaterialNonConformity.get('isLoading'),
        rawMaterial: rawMaterial ? rawMaterial.toJS() : null,
        selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadRawMaterialNonConformity: (token) => {
            return dispatch(loadRawMaterialNonConformityFromBackEnd(token));
        },
        editRawMaterialNonConformityFromBackEnd: (preparedBy, supplier, partNumber, failure, rawMaterial) =>{
            return dispatch(editRawMaterialNonConformityFromBackEnd(preparedBy, supplier, partNumber, failure, rawMaterial))
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(EditReport))));