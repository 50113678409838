export const SERVICE_RETENTION_PERCENTAGE = 'ServiceRetentionPercentage';
export const FREIGHT_RETENTION_PERCENTAGE = 'FreightRetentionPercentage';
export const IVA_PERCENTAGE = 'IvaPercentage';
export const RESICO_PERCENTAGE = 'ResicoPercentage';
export const INVOICING_ERROR = 'InvoiceAmountVariationAllowed';
export const REPARE_RFC = 'REPARE_RFC';
export const PAYMENT_REQUESTS_CREATION = 'PaymentRequestCreationStatus';
export const PAYMENT_REQUESTS_CREATION_DAYS = 'PaymentRequestCreationDays';
export const REALEASED_SERVICES_SECTION = 'ReleasedServiceOrders';
export const NOT_REALEASED_SERVICES_SECTION = 'NotReleasedServiceOrders';
export const MAX_NUMBER_OF_SERVICES_IN_PAYMENT_REQUEST = 'MaxNumberOfServicesInPaymentRequest';
