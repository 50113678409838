import axios from 'axios'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import moment from 'moment'

export const IS_SAVING = 'IS_SAVING'
export const SET_CERTIFICATIONS = 'SET_CERTIFICATIONS'
export const SET_CERTIFICATION = 'SET_CERTIFICATION'
export const IS_LOADING = 'IS_LOADING'

/**
 * Action start saving information
 * @param {*} isLoading
 */
function isSavingCommon(isSaving) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
	}
}
function setCertificationsPaginated(cert) {
	return {
		type: SET_CERTIFICATIONS,
		cert: cert,
	}
}
function setCertification(cert) {
	return {
		type: SET_CERTIFICATION,
		cert: cert,
	}
}
function isLoadingName(nameIsloading, valueIsloading) {
	return {
		type: IS_LOADING,
		nameIsloading,
		valueIsloading,
	}
}
export function createCertification(information) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))

		const state = getState()
		const { user } = state.oidc
		const selectedCompany = state.profile.get('selectedCompany')
		const supplier = state.suppliers.get('supplier')
		const configuration = getAuthorizedRequestConfigurationUserFiles(user)

		let userName = user.profile.username || user.profile.preferred_username

		const formData = new FormData()
		formData.append('RequestBy', userName)
		formData.append('CertificationName', information.CertificationName)
		formData.append('Notes', information.Notes)
		formData.append('StartDateCertification', moment(information.StartDateCertification).format('DD/MM/YYYY'))
		formData.append('EndDateCertification', moment(information.EndDateCertification).format('DD/MM/YYYY'))
		formData.append('SupplierNumber', user.profile.company_code)
		formData.append('SupplierName', supplier.get('name'))
		formData.append('CompanyCode', selectedCompany.get('get_company_identifier'))

		information.Document.forEach((file) => {
			formData.append('data', file, file.name)
		})

		const endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Certification/create`

		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				dispatch(isSavingCommon(false))
				if (serverResponse.status === 200) {
					dispatch(setCertification(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error adding certification', error.response)
				return Promise.reject(error.message)
			})
	}
}

export function loadCertificationsPaginated(queryParams) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Certification?`
		if (queryParams && queryParams.query) endPoint = endPoint + `query=${queryParams.query}`
		if (queryParams && queryParams.pageNumber) endPoint = endPoint + `&pageNumber=${queryParams.pageNumber - 1}`
		if (queryParams && queryParams.rowsPerPage) endPoint = endPoint + `&pageSize=${queryParams.rowsPerPage}`
		if (queryParams && queryParams.sortBy)
			endPoint =
				endPoint +
				`&sortBy=${
					queryParams.sortBy && queryParams.sortBy.length > 0 ? queryParams.sortBy : 'CertificationName'
				}`
		if (queryParams && queryParams.sortDirection)
			endPoint =
				endPoint +
				`&sortDirection=${
					queryParams.sortDirection && queryParams.sortDirection.length > 0
						? queryParams.sortDirection
						: 'desc'
				}`
		let supplierNumber = getState().oidc.user.profile.company_code
		let companyCode = selectedCompany.get('get_company_identifier')
		if (queryParams.supplierCode) endPoint = endPoint + `&SupplierNumber=${queryParams.supplierCode}||equal`
		else if (supplierNumber) endPoint = endPoint + `&SupplierNumber=${supplierNumber}||equal`
		if (companyCode) endPoint = endPoint + `&CompanyCode=${companyCode}||equal`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCertificationsPaginated(serverResponse.data))
					dispatch(isSavingCommon(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error getting security courses paginated', error.response)
				return Promise.reject(error.response)
			})
	}
}
export function deleteCertification(id, fileName, folderName) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Certification/delete/${id}/${encodeURIComponent(
			fileName
		)}/${folderName}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error delete security course', error.response)
				return Promise.reject(error.response)
			})
	}
}
export function getCertificationPDF(id, fileName, folderName) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		configuration.responseType = 'arraybuffer'
		const endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Certification/download/${id}/${encodeURIComponent(
			fileName
		)}/${folderName}`
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var file = new Blob([serverResponse.data], { type: serverResponse.headers['content-type'] })
					var fileURL = URL.createObjectURL(file)
					window.open(fileURL)
				}
			})
			.catch((error) => {
				console.error('Error downloading document', error.response)
				return Promise.reject()
			})
	}
}

export function getDownlaodReportCertification(queryParams) {
	return function (dispatch, getState) {
		console.log('EntraDownlaod')
		dispatch(isLoadingName('isDownloadLoader', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Certification/download/report?`
		console.log({ endPoint })
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierNumber = getState().oidc.user.profile.company_code
		let companyCode = selectedCompany.get('get_company_identifier')
		if (companyCode) endPoint = endPoint + `CompanyCode=${companyCode}||equal`
		if (queryParams.supplierCode) endPoint = endPoint + `&SupplierNumber=${queryParams.supplierCode}||equal`
		else if (supplierNumber) endPoint = endPoint + `&SupplierNumber=${supplierNumber}||equal`
		if (queryParams.filters && queryParams.filters.StartDate && queryParams.filters.EndDate) {
			const startDate = moment(queryParams.filters.StartDate).format('MM-DD-YYYY')
			const endDate = moment(queryParams.filters.EndDate).format('MM-DD-YYYY')
			endPoint = endPoint + `&CreationDate=${startDate}|${endDate}||between`
		}
		console.log({ endPoint })
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_certificaciones.xlsx'
					a.click()
				}
				dispatch(isLoadingName('isDownloadLoader', false))
			})
			.catch((error) => {
				console.log({ error })
				dispatch(isLoadingName('isDownloadLoader', false))
			})
	}
}
