/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import {
    withStyles,
    Grid
} from "@material-ui/core";
/**Import components section*/
import EmptyInformation from '../EmptyInformation'
import ExpansionPanelBaseBidding from './ExpansionPanelBaseBidding'
import { BACKGROUND_COLOR_GRAY_BIDDING } from '../../../../store/helpers/StatusColorConstants'

const BasesContainer = (props) => {
    const { classes, translate, bidding } = props;

    return (
        <>
            <Grid container className={classes.containerModule}>
                <Grid item xs={12}>
                    {
                        (bidding && bidding.Sections && bidding.Sections.length !== 0) ? bidding.Sections.map(base => {
                            return (
                                <div key={base.Id}>
                                    <ExpansionPanelBaseBidding
                                        base={base}
                                        translate={translate}

                                    />

                                </div>
                            )
                        }) : <EmptyInformation
                                title={translate('biddingsModule.create.configBidding')}
                            />
                    }
                </Grid>
            </Grid>

        </>
    )
};

const styles = (theme) => ({
    grow: {
        flexGrow: 1
    },
    flex: {
        display: 'flex'
    },
    titleHeader: {
        marginLeft: '20px',
        marginTop: '10px'
    },
    specifications: {
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
    },
    containerModule: {
    }
});

BasesContainer.propTypes = {
    bidding: PropTypes.object
};

BasesContainer.defaultProps = {
    bidding: null
};

export default withRouter(
    withStyles(styles, { withTheme: true })(BasesContainer)
);
