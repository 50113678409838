import { fromJS } from 'immutable';

import * as releasePlanAction from './ReleasePlanAction';

/**
 * releasePlan InitialState initial state
 */
export const releasePlanInitialState = fromJS({
    isLoadingReleasePlan: true,
    releasePlan: null,
    isLoadingMaterials: false,
    materialsRelease: null,
    isSaving: false,
    isUpdatingReleasePlan: false,
})

export default function releasePlanReducer(state = releasePlanInitialState, action) {
    switch (action.type) {
        case releasePlanAction.START_LOADING_RELEASE_PLAN: {
            return state.merge({
                isLoadingReleasePlan: action.isLoading
            })
        }
        case releasePlanAction.SET_RELEASE_PLAN: {
            return state.merge({
                isLoadingReleasePlan: false,
                releasePlan: fromJS(action.releasePlan)
            })
        }
        case releasePlanAction.START_LOADING_MATERIALS: {
            return state.merge({
                isLoadingMaterials: action.isLoading
            })
        }
        case releasePlanAction.SET_MATERIALS_RELEASE: {
            return state.merge({
                isLoadingMaterials: false,
                materialsRelease: fromJS(action.materials)
            })
        }
        case releasePlanAction.IS_SAVING_MATERIAL:{
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case releasePlanAction.IS_UPDATING_RELEASE_PLAN:{
            return state.merge({
                isUpdatingReleasePlan: action.isUpdating
            })
        }
        default:
            return state;
    }
}