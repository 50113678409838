
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import autoBind from 'auto-bind'

/** Material-UI imports section */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";

/** Import section component */
import Toaster from '../../common/Toaster';
import DialogActions from '@material-ui/core/DialogActions';
import FailureNonConformitySelector from '../../common/FailureNonConformitySelector';
import PreparedBySelector from '../../common/PreparedBySelector';

/**
 * Component Form Raw material non conformity from Delivery order
 */
class FormCreateFromDelivery extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            preparedBySelected: null,
            supplierSelected: {
                token: this.props.deliveryOrder.supplier_token,
                name: this.props.deliveryOrder.supplier_name,
                code: this.props.deliveryOrder.supplier_code,
                fullSupplier: this.props.deliveryOrder.full_supplier,
            },
            partNumberSelected: {
                code: this.props.itemFromRawMaterial.part_number_code,
                description: this.props.itemFromRawMaterial.part_number_description,
                measurementUnit: this.props.itemFromRawMaterial.part_number_um,
            },
            failureSelected: null,
            rawMaterial: {
                problemDescription: "",
                invoicedAmount: this.props.itemFromRawMaterial.quantity_of_material,
                quantityInspected: "",
                specificationCorreclty: "",
                lotMaterial: this.props.itemFromRawMaterial.lot,
                customerPartNumber: "",
                deliveryOrderFolio: this.props.deliveryOrder.folio,
                deliveryOrderToken: this.props.deliveryOrder.token,
            },
        }
    }

    /**
    * On seleted prepare by
    */
    onPrepareBySelected(prepareBy) {
        this.setState({ preparedBySelected: prepareBy });
    }

    /**
     * On seleted failure
     */
    onFailureSelected(failure) {
        this.setState({ failureSelected: failure });
    }

    /**
    * Update the property of the raw mateiral
    *
    * @param {*} user
    * @param {*} value
    * @memberof View
    */
    updateProperty(property, value) {
        let creatRawMaterial = { ...this.state.rawMaterial };
        creatRawMaterial[property] = value;
        this.setState({
            rawMaterial: creatRawMaterial
        })
    }

    /**
     * Can be add item in raw material
     */
    canBeSaved() {
        let rawMaterial = this.state.rawMaterial;
        if (this.state.preparedBySelected === null)
            return false;
        if (this.state.supplierSelected === null)
            return false;
        if (this.state.partNumberSelected === null)
            return false;
        if (this.state.failureSelected === null)
            return false;
        if (rawMaterial.problemDescription === "" || rawMaterial.invoicedAmount === "" ||
            rawMaterial.quantityInspected === "" || rawMaterial.specificationCorreclty === "")
            return false;
        if (this.hasInvalidValueQantity())
            return false;
        else
            return true;
    }

    /**
    * Has valid quantity
    */
    hasInvalidValueQantity() {
        /// Validar que la cantidad inventario no puede ser mayor a la cantiad reportada
        if (this.state.rawMaterial.invoicedAmount !== "" && this.state.rawMaterial.quantityInspected !== "") {
            let invoicedAmount = Number(this.state.rawMaterial.invoicedAmount);
            let quantityInspected = Number(this.state.rawMaterial.quantityInspected);
            if (invoicedAmount < quantityInspected) {
                return true;
            }
            else
                return false;
        }
        else
            return false;
    }


    /**
     * Metho to can be created non conformity
     */
    canBeCreateNonConformityFromDeliveryOrder() {
        /// Validar que la cantdad inspeccionarda no puede ser mayo a la factudda
        if (this.hasInvalidValueQantity()) {
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="rawmaterialnonconformity.messageQualityInvalid" />,
                toasterVariant: "error",
            })
        }
        else if (this.props.createdFromDeliveryOrder) {
            this.props.createdFromDeliveryOrder(this.state.preparedBySelected, this.state.supplierSelected, this.state.partNumberSelected, 
                this.state.failureSelected, this.state.rawMaterial)
        }
    }

    /**
     * Render show 
     */
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container} noValidate autoComplete="off">
                <Grid container spacing={24} autoComplete="off">
                    <Grid item xs={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom><Translate id="common.preparedBy"/></Typography>
                                <PreparedBySelector
                                    prepareBy={this.state.preparedBySelected}
                                    className={classes.textField}
                                    onUsersQualitySelected={(supplier) => { this.onPrepareBySelected(supplier) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={true}
                                    label={<Translate id="common.supplier"/>}
                                    id="standard-read-only-input"
                                    value={this.state.supplierSelected ? this.state.supplierSelected.fullSupplier : ""}
                                    onChange={(event) => { this.updateProperty("supplierSelected", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-read-only-input"
                                    label={"Lote"}
                                    value={this.state.rawMaterial.lotMaterial}
                                    type="text"
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("lotMaterial", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-read-only-input"
                                    label={"Número de parte del cliente"}
                                    value={this.state.rawMaterial.customerPartNumber}
                                    type="text"
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("customerPartNumber", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label={<Translate id="rawmaterialnonconformity.descriptionBrach" />}
                                    multiline
                                    rows="3"
                                    value={this.state.rawMaterial.problemDescription}
                                    onChange={(event) => { this.updateProperty("problemDescription", event.target.value) }}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label={<Translate id="rawmaterialnonconformity.specificationCorreclty" />}
                                    multiline
                                    rows="3"
                                    value={this.state.rawMaterial.specificationCorreclty}
                                    onChange={(event) => { this.updateProperty("specificationCorreclty", event.target.value) }}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom><Translate id="common.partNumber"/></Typography>
                                 <TextField
                                    disabled={true}
                                    id="standard-read-only-input"
                                    value={this.state.partNumberSelected ? this.state.partNumberSelected.code : ""}
                                    onChange={(event) => { this.updateProperty("code", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={true}
                                    id="standard-read-only-input"
                                    label={<Translate id="common.descriptionPartNumber" />}
                                    value={this.state.partNumberSelected ? this.state.partNumberSelected.description : ""}
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("description", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={true}
                                    id="standard-read-only-input"
                                    label={<Translate id="common.measurementUnit" />}
                                    value={this.state.partNumberSelected ? this.state.partNumberSelected.measurementUnit : ""}
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("measurementUnit", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom><Translate id="rawmaterialnonconformity.failure"/></Typography>
                                <FailureNonConformitySelector
                                    failure={this.state.failureSelected}
                                    className={classes.textField}
                                    onNonConformitySelected={(failure) => { this.onFailureSelected(failure) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-read-only-input"
                                    error={this.hasInvalidValueQantity()}
                                    label={<Translate id="rawmaterialnonconformity.invoicedAmount" />}
                                    value={this.state.rawMaterial.invoicedAmount}
                                    type="number"
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("invoicedAmount", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-read-only-input"
                                    error={this.hasInvalidValueQantity()}
                                    label={<Translate id="rawmaterialnonconformity.quantityInspected" />}
                                    value={this.state.rawMaterial.quantityInspected}
                                    type="number"
                                    margin="normal"
                                    onChange={(event) => { this.updateProperty("quantityInspected", event.target.value) }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <DialogActions>
                                <Button
                                    data-cy="btn-save"
                                    type="button"
                                    color="primary"
                                    onClick={this.canBeCreateNonConformityFromDeliveryOrder}
                                    disabled={!this.canBeSaved() || this.props.isSaving}>
                                    {<Translate id="common.save" />}
                                </Button>
                                <Button onClick={() => { this.props.history.push(`/planning/deliveryorder/${this.props.match.params.token}`) }} color="secondary">
                                    {<Translate id="common.cancel" />}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Grid>

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </div>
        )
    }
}

const viewStyles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: "100px",
        paddingRight: "150px",
    },
    tetxtFieldFull: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },
    textAreaField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        height: 80,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    footerForm: {
        display: "flex",
        paddingRight: "60px",
    },
    formControl: { margin: theme.spacing.unit },
    toolbar: {},
    searchInput: {},
    button: {},
    actionsToolbar: {},
    showingUsersCounter: {},
    grow: {},
    supplierBadge: {}
});

export default withRouter(withStyles(viewStyles, { withTheme: true })(FormCreateFromDelivery));