/** Import ReactJs section */
import React, {Component} from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';

/** Import MaterialUI section */
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import UserNameIcon from '@material-ui/icons/HowToRegTwoTone';
import Toaster from '../../common/Toaster'
import {Translate, getTranslate} from "react-localize-redux";

import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import translations from '../../../resources/translations.json'

/** Import actions */
import {resetPassword} from '../../../store/suppliers/ExternalUserActions';

/** Import components */
import withAnonymousLayout from '../../layouts/withAnonymousLayout';
import '../../../resources/styles/resetpassword.css';

import imageImbera from '../../../resources/images/header-imbera.png';
import imagePtm from '../../../resources/images/header-ptm.png';
import imageAlpunto from '../../../resources/images/header_alpunto.png';
import imageRepare from '../../../resources/images/header-repare.png';
import imageMetalicos from '../../../resources/images/header-metalicos.png';



/**
 * Reset password
 *
 * @class ResetPassword
 * @extends {Component}
 */
class ResetPasswordRequest extends Component {

    /**
     *Creates an instance of ResetPassword.
     * @param {*} props
     * @memberof ResetPassword
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            userName: "",
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            requestSent: false,
            messateToSendEmail: "",
        }

        let defaultLanguage = localStorage.getItem("language");
        if (!defaultLanguage) {
            defaultLanguage = 'es';
        }
        this.props.initialize({
            languages: [
                { name: "EspaÃ±ol", code: "es" },
                { name: "English", code: "en" },
                { name: "Portugues", code: "pt" },
            ],
            translation: translations,
            options: { 
                renderToStaticMarkup ,
                defaultLanguage: defaultLanguage
            }
        })
    }

    /**
     * Reset password
     *
     * @memberof ResetPassword
     */
    resetPassword(event) {
        event.preventDefault();
        this.props.resetPassword(this.state.userName)
        .then((response) => {
            let message =`${this.props.translate("users.messageSendEmail")} - ${response.data}`;
            this.setState({
                showToaster: true,
                requestSent:true,
                messateToSendEmail: response.data,
                toasterVariant: "success",
                toasterMessage: message,
            })    
        })
        .catch((error) => {
            if (error.status === 400) {
                let message =`${this.props.translate("users.userToValid")} - ${error.data.Message}`;
                this.setState({
                    showToaster: true,
                    toasterVariant: "warning",
                    toasterMessage:  message,
                })
            } else {
                this.setState({
                    showToaster: true,
                    toasterVariant: "error",
                    toasterMessage: <Translate id="common.error"/>,
                })
            }
        })
    }

    /**
     * Update user name 
     *
     * @param {*} event
     * @memberof ResetPassword
     */
    updateUserName(event) {
        this.setState({
            userName:event.target.value
        })
    }


    /**
     * Get message
     *
     * @returns
     * @memberof ResetPasswordRequest
     */
    getMessage() {
        if (this.state.requestSent) {
            return (
                <React.Fragment>
                    <p><Translate id="users.messageReseteSucess"/> <strong>{this.state.messateToSendEmail}</strong></p>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                     <div>
                        <h6><Translate id="users.messageResete"/> </h6>
                        <br />
                    </div>
                    <form className="restore-password-form" onSubmit={this.resetPassword}>
                        <FormControl fullWidth={true} >
                            <InputLabel><Translate id="users.nameUser"/></InputLabel>
                            <Input autoFocus
                                id="userName"
                                value={this.state.userName}
                                onChange={this.updateUserName}
                                placeholder={"Nombre del usuario"}
                                startAdornment={
                                <InputAdornment position="start">
                                    <UserNameIcon />
                                </InputAdornment>
                            }
                            />
                        </FormControl>
                        <br />
                        <br />
                        <br />
                        <Button variant="outlined" color="secondary" disabled={this.props.isSavingUser} fullWidth={true} type="submit">
                            <Translate id="users.restorePassword"/>
                        </Button><br /><br />
                    </form><br />
                </React.Fragment>
            )
        }
    }

    /**
     * Render
     *
     * @returns
     * @memberof ResetPassword
     */
    render() {
        return (
            <Paper className="container">
                <h1 color="primary"><Translate id="users.restorePassword"/></h1>
               
                {this.getMessage()}

                <div className="login-footer-logos">
                    <img className="imagenLogin" src={imageImbera} alt="Remy Sharp" />
                    <img className="imagenLogin" src={imagePtm} alt="Remy Sharp" />
                    <img className="imagenLogin" src={imageAlpunto} alt="Remy Sharp" />
                    <img className="imagenLogin" src={imageRepare} alt="Remy Sharp" />
                    <img className="imagenLogin" src={imageMetalicos} alt="Remy Sharp" />
                </div>

                <Toaster 
                    message = {this.state.toasterMessage}
                    open = {this.state.showToaster}
                    variant = {this.state.toasterVariant}
                    onClose = {() => {this.setState({ showToaster:false})}}
                />
            </Paper>
        );
    }
}

/**
 * Map state to props
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state){
    return {
        isSavingUser: state.externalUsers.get("isSavingUser"),
        translate: getTranslate(state.localize),
    }
}
/**
 * Map dispatch to props
 */
const mapDispatchToProps = dispatch => {
    return {
        resetPassword:(userName) => {
            return dispatch(resetPassword(userName))
        }
    }
}

const styles = theme => ({
    containerPaper:{
        maxWidth: 800,
        margin: "50px auto 0px auto",
        padding: 50,
        backgroundColor: "#990033",
        color:"#ffffff",
        fontFamily: 'Arsenal',
    },
    iconPrimary: {
        color: "#9E9E9E"
    },
    iconHint: {
        color: "#FFAB00"
    },
});


export default withAnonymousLayout(withLocalize(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResetPasswordRequest)))));