import React from 'react'
import { CarrierStatus, ManeuverTypeEnum, TransportTendeStatus } from './AppConstants'
import {
	COLOR_STATUS_DANGER,
	COLOR_STATUS_GRAY,
	COLOR_STATUS_PROCESS,
	COLOR_STATUS_WARNING,
	COLOR_SUSTAINABILITY_PLUM,
} from './StatusColorConstants'
import { Chip } from '@material-ui/core'
import { CheckCircle, Done } from '@material-ui/icons'
import { ROLE_TRANSPORT_TENDER_ADMINISTRATOR } from './RolesHelper'
export const getColorStatusText = (status) => {
	switch (Number(status)) {
		case TransportTendeStatus.DRAFT:
			return COLOR_STATUS_GRAY
		case TransportTendeStatus.SENT_TO_CARRIER:
			return COLOR_STATUS_WARNING
		case TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER:
			return COLOR_SUSTAINABILITY_PLUM
		case TransportTendeStatus.IN_EVALUATION:
			return COLOR_STATUS_WARNING
		case TransportTendeStatus.CANCEL:
			return COLOR_STATUS_DANGER
		default:
			return ''
	}
}
export const getColorStatusCarierText = (status) => {
	switch (Number(status)) {
		case CarrierStatus.DRAFT:
			return COLOR_STATUS_GRAY
		case CarrierStatus.EVALUATION:
			return COLOR_STATUS_PROCESS
		case CarrierStatus.DECLINE:
			return COLOR_STATUS_DANGER
		case CarrierStatus.EXPIRED:
			return COLOR_STATUS_WARNING
		case CarrierStatus.CANCEL:
			return COLOR_STATUS_DANGER
		default:
			return ''
	}
}
export const descriptionTransportTEnderStatus = (status) => {
	switch (Number(status)) {
		case TransportTendeStatus.DRAFT:
			return 'Borrador'
		case TransportTendeStatus.SENT_TO_CARRIER:
			return 'Enviado a transportista'
		case TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER:
			return 'Bitácora enviada a proveedor'
		case TransportTendeStatus.IN_EVALUATION:
			return 'En evaluación'
		case TransportTendeStatus.CANCEL:
			return 'Cancelada'
		default:
			return ''
	}
}

export const descriptionCarrierStatus = (status, isWinner, transportTenderStatus) => {
	switch (Number(status)) {
		case CarrierStatus.DRAFT:
			return 'carrierTenders.statusCarrier.draft'
		case CarrierStatus.EVALUATION:
			if (transportTenderStatus === TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER) {
				if (isWinner) return 'carrierTenders.statusCarrier.isWinner'
				else return 'carrierTenders.statusCarrier.noWinner'
			} else return 'carrierTenders.statusCarrier.inEvaluation'
		case CarrierStatus.DECLINE:
			return 'carrierTenders.statusCarrier.rejected'
		case CarrierStatus.EXPIRED:
			return 'carrierTenders.statusCarrier.expired'
		case CarrierStatus.CANCEL:
			return 'carrierTenders.statusCarrier.cancel'
		default:
			return ''
	}
}

export const mappedUserByRoleTransportProgrammerOption = (option) => ({
	...option,
	value: option.id,
	label: `${option.user_name} - ${option.name}`,
})

export const getUserByRoleOptions = (data) => {
	return data.map((option) => mappedUserByRoleTransportProgrammerOption(option))
}
export const mappedTransportZoneOption = (option) => ({
	...option,
	value: option.Id,
	label: `${option.Code} - ${option.Name} | ${option.KM} KM`,
})

export const getTransportZoneOptions = (data) => {
	return data.map((option) => mappedTransportZoneOption(option))
}
export const mappedTypeShipOption = (option) => ({
	...option,
	value: option.Id,
	label: `${option.Name}`,
})

export const getTypeShipOptions = (data) => {
	return data.map((option) => mappedTypeShipOption(option))
}
export const mappedWineCellarOption = (option) => ({
	...option,
	value: option.id,
	label: `${option.name} | ${option.direction} `,
})

export const getWineCellarOptions = (data) => {
	return data.map((option) => mappedWineCellarOption(option))
}
export const mappedTransportTypeOption = (option) => ({
	...option,
	value: option.Id,
	label: `${option.Type} | ${option.Code} | ${option.Description} `,
})

export const getTransportTypeOptions = (data) => {
	console.log('DATA', data)
	return data.map((option) => mappedTransportTypeOption(option))
}
export const mappedClientsOption = (option) => ({
	...option,
	value: option.Name,
	label: `${option.Name}`,
})

export const getClientsOptions = (data) => {
	return data.map((option) => mappedClientsOption(option))
}

export const getTypeShipmentsOptions = () => {
	let options = []
	options.push({ value: 0, label: 'Nacional' })
	options.push({ value: 1, label: 'Internacional' })
	return options
}
export const getChargingWayOptions = () => {
	let options = []
	options.push({ value: 0, label: 'Manual' })
	options.push({ value: 1, label: 'Masiva' })
	return options
}
export const optionsTransportTender = (
	isInternalUser,
	onSubmit,
	onChangeStatusCarrier,
	status,
	canBeSendLogBookToCarrier,
	onSendSecondRoundCarrier,
	hasExpired
) => {
	let columns = []
	if (isInternalUser) {
		if (status === TransportTendeStatus.DRAFT)
			columns.push({
				value: 1,
				title: 'Enviar licitación a los transportistas',
				onClick: () => onSubmit(TransportTendeStatus.SENT_TO_CARRIER),
				isIconMaterial: true,
				description:
					'Se enviará una invitación para participar en la licitación a los transportistas configurados',
			})
		if (status === TransportTendeStatus.SENT_TO_CARRIER)
			columns.push({
				value: 1,
				title: 'Enviar licitación a evaluación',
				onClick: () => onSubmit(TransportTendeStatus.IN_EVALUATION),
				isIconMaterial: true,
				description:
					'Se terminara el proceso de captura de información por parte de los proveedores y el planeador calificará las propuestas enviadas hasta el momento.',
			})
		if (canBeSendLogBookToCarrier) {
			columns.push({
				value: 1,
				title: 'Enviar bitácora a transportista ganador',
				onClick: () => onSubmit(TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER),
				isIconMaterial: true,
				description: 'Se enviará la bitácora (pdf) al transportista ganador',
			})
		}
		if (
			status === TransportTendeStatus.IN_EVALUATION ||
			status === TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER
		)
			columns.push({
				value: 1,
				title: 'Enviar licitación a segunda ronda',
				onClick: () => onSendSecondRoundCarrier(TransportTendeStatus.SENT_TO_CARRIER),
				isIconMaterial: true,
				description:
					'Se enviará esta licitación a segunda ronda, asegúrate de haber actualizado la información. Se enviará la licitación a los transportistas',
			})
		if (status !== TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER)
			columns.push({
				value: 1,
				title: 'Cancelar licitación',
				onClick: () => onSubmit(TransportTendeStatus.CANCEL),
				isIconMaterial: true,
				description: 'Se cancelará la licitación de transportistas',
			})
	} else {
		if (!hasExpired) {
			columns.push({
				value: 1,
				title: 'Confirmar participación en la licitación',
				onClick: () => onChangeStatusCarrier(2),
				isIconMaterial: true,
				description: 'Al confirmar tu participación estarás aceptando la disponibilidad de transporte.',
			})
		}
	}
	return columns
}

export const getSuppliersTypesOptionsSelect = (option) => ({
	...option,
	value: option.code,
	label: `${option.code} |${option.supplier_name}`,
})
export const getManeuverTypeByNumber = (number) => {
	switch (number) {
		case ManeuverTypeEnum.PayForImbera:
			return 'carrierTenders.deals.maneuvered.payForImbera'
		case ManeuverTypeEnum.ContratedForTransport:
			return 'carrierTenders.deals.maneuvered.contratedForTransport'
		case ManeuverTypeEnum.PaidCarrierToCustomer:
			return 'carrierTenders.deals.maneuvered.paidCarrierToCustomer'
		default:
			return ''
	}
}
export const getManeuverTemplateByNumber = (number, title) => {
	switch (number) {
		case ManeuverTypeEnum.PayForImbera:
			return (
				<Chip
					style={{ width: 220 }}
					size='small'
					variant={'outlined'}
					icon={<CheckCircle fontSize='small' />}
					label={title}
				/>
			)
		case ManeuverTypeEnum.ContratedForTransport:
			return <Chip style={{ width: 220 }} variant={'outlined'} icon={<Done fontSize='small' />} label={title} />
		case ManeuverTypeEnum.PaidCarrierToCustomer:
			return <Chip style={{ width: 220 }} variant={'outlined'} icon={<Done fontSize='small' />} label={title} />
		default:
			return ''
	}
}
/**Get options for divisas select*/
export const getSupplierOptions = (data) => {
	return data.map((option) => getSuppliersTypesOptionsSelect(option))
}
export const isTransportTenderAdministrator = (roles) => {
	return roles.some((role) => role.name === ROLE_TRANSPORT_TENDER_ADMINISTRATOR)
}
