
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LoadingPage from '../common/LoadingPage';
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow';
import InformationPartial from './InformationPartial';

/** Import action section */
import { loadSupplier } from '../../store/suppliers/SupplierActions';
import {loadWarehouseRawMaterialFromBackEnd} from '../../store/warehouseRawMaterial/WarehouseRawMaterialAction';

import { converListToArray } from '../../store/helpers/ListHelper'

/** Language imports **/
import { Translate } from "react-localize-redux";

/**
 * Container show loadWarehouseRawMaterial
 */
class Show extends Component {

    /**
     * Create an instance loadWarehouseRawMaterial
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openDeleteDraft: false,
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let warehouseRawMaterialsToken = this.props.match.params.token;
        this.props.loadWarehouseRawMaterial(warehouseRawMaterialsToken);
    }

    /**
     * componentDidUpdate
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof Index
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.warehouseRawMaterial !== null) {
            /// Get information full from warehouseRawMaterial
            let warehouseRawMaterial = this.props.warehouseRawMaterial;
            this.props.loadSupplier(warehouseRawMaterial.supplier_token);
        }
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.warehouseRawMaterial !== null) {
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Divider />
                        <InformationPartial  {...this.props} />
                    </Grid>
                </Grid>
            )
        }
    }

    render() {
        return (
            <Card>
                <LayoutHeaderWorkFlow {...this.props}
                    workFlow={this.props.warehouseRawMaterial}
                    title={<Translate id="warehouseRawMaterial.reportMaterial"/>}
                />

                <CardContent>
                    {this.getCardContent()}
                </CardContent>

            </Card>
        )
    }

}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let warehouseRawMaterial = state.warehouseRawMaterials.get("warehouseRawMaterial");
    let selectedCompany = state.profile.get("selectedCompany");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        currentUser: user,
        isLoading: state.warehouseRawMaterials.get('isLoading'),
        warehouseRawMaterial: warehouseRawMaterial ? warehouseRawMaterial.toJS() : null,
        isSaving: state.warehouseRawMaterials.get('isSaving'),
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        company: selectedCompany ? selectedCompany.toJS() : null,
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadWarehouseRawMaterial: (token) => {
            return dispatch(loadWarehouseRawMaterialFromBackEnd(token));
        },
        loadSupplier: (token) => {
            return (dispatch(loadSupplier(token)))
        }
    }
}

const styles = theme => ({
    actionsToolbar: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    margin: {
        margin: theme.spacing.unit * 2,
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));