
import React, { Component } from 'react';

/** Material-UI imports section */
import {Translate} from "react-localize-redux";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";

import DataTable from '../../common/DataTable';

/**
 * Component to show list RMIS data
 */
export default class RMIDocuments extends Component{

          /**
     * Get the card content
     *
     * @memberof Index
     */
    getCardContent() {
        if(this.props.rmis === null){
            return(
                <div className="loader-container">            
                    <Typography variant="subtitle1" gutterBottom> <Translate id="common.notInformation"/></Typography>
                </div>
            )
        } else {
            return (
                <DataTable
                    data = {this.props.rmis}
                    configuration = {TableConfiguration}
                    onRowClick = {(sample) => {
                        this.props.history.push(`/samplerequests/${sample.token}`)
                    }}
                />
            )
        }
    }

    render(){
        return(
            <Card>
                <CardContent>
                    {this.getCardContent()}                
                </CardContent>
            </Card>
        )
    }
} 

const TableConfiguration = {
    columns: [{
        header: <Translate id="common.folio"/>,
        dataSource: 'folio'
    },{
        header: "Validado por",
        dataSource: 'inspected_by_engineer'
    },{
        header: <Translate id="samplesrequest.validateBy"/>,
        dataSource: 'supplier'
    },{
        header: <Translate id="common.status"/>,
        dataSource: 'status'
    }]
}