import React, { useEffect, useState } from 'react'

/** Material UI import section */
import { TextField } from '@material-ui/core'

const AutoSaveTextField = (props) => {
	const { value, onChange, ...other } = props

	const [timer, setTimer] = useState(null)
	const [localValue, setLocalValue] = useState('')

	useEffect(() => {
		setLocalValue(value)
	}, [value])

	const handleChange = (event) => {
		const { target } = event
		const newValue = target.value
		setLocalValue(newValue)
		clearTimeout(timer)
		const newTimeoutId = setTimeout(() => {
			// Runs 1 second (1000 ms) after the last change
			onChange({ target })
		}, 1000)
		setTimer(newTimeoutId)
	}

	return <TextField value={localValue} {...other} onChange={handleChange} />
}

AutoSaveTextField.propTypes = {}

export default AutoSaveTextField
