
import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import autoBind from 'auto-bind'
import Select from 'react-select'
import moment from 'moment';

/** Material-UI imports section */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

/** Material UI icons imports section */
import AlertIcon from '@material-ui/icons/ErrorOutline';

/** Import section component */
import Toaster from '../../common/Toaster';
import DatePicker from '../../common/DateTimePicker/DatePicker';

import { GetRampsFromRelease, GetTimeToLockCalendar } from '../../../store/helpers/SelectOptions';


/**
 * Component Form Release calendar lock
 */
class FormView extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            calendarLock: this.initialCalendarLockData()
        }
    }

    initialCalendarLockData() {
        return ({
            message: '',
            number_ramps: '',
            all_days: false,
            by_date: false,
            by_period: false,
            is_monday: false,
            is_tuesday: false,
            is_wednesday: false,
            is_thursday: false,
            is_friday: false,
            is_saturday: false,
            time_hour_lock_start: moment(),
            time_hour_lock_end: moment(),
            time_hour_start: '',
            time_hour_end: ''
        })
    }

    /**
    * Update the property of the calendar Lock
    *
    * @param {*} user
    * @param {*} value
    * @memberof View
    */
    updateProperty(property, value) {
        let creatcalendarLock = { ...this.state.calendarLock };
        creatcalendarLock[property] = value;
        this.setState({
            calendarLock: creatcalendarLock
        }, () => {
            if (property === "by_date") {
                this.setState({
                    calendarLock: {
                        ...this.state.calendarLock,
                        all_days: false,
                        by_period:false,
                        is_monday: false,
                        is_tuesday: false,
                        is_wednesday: false,
                        is_thursday: false,
                        is_friday: false,
                        is_saturday: false,
                    }
                })
            }
            if (property === "all_days") {
                this.setState({
                    calendarLock: {
                        ...this.state.calendarLock,
                        by_date: false,
                        by_period:false,
                        time_hour_lock_start: moment(),
                        time_hour_lock_end: moment(),
                    }
                })
            }
            if (property === "by_period") {
                this.setState({
                    calendarLock: {
                        ...this.state.calendarLock,
                        by_date: false,
                        all_days: false,
                        time_hour_lock_start: moment(),
                        time_hour_lock_end: moment(),
                        is_monday: false,
                        is_tuesday: false,
                        is_wednesday: false,
                        is_thursday: false,
                        is_friday: false,
                        is_saturday: false,
                    }
                })
            }
        })
    }

    handleCancel() {
        this.setState({
            calendarLock: this.initialCalendarLockData()
        }, () => { this.props.history.push(`/releasecalendar`) })
    }

    /**
    * Update selected options
    * @param {*} option 
    * @param {*} property 
    */
    updatePropertySelected(option, property) {
        let creatcalendarLock = { ...this.state.calendarLock };
        creatcalendarLock[property] = option;
        this.setState({
            calendarLock: creatcalendarLock
        })
    }

    onChangeDate(date, property) {
        this.setState({
            calendarLock: {
                ...this.state.calendarLock,
                [property]: date
            }
        })
    }

    hasCheckedDay() {
        if (this.state.calendarLock.is_monday
            || this.state.calendarLock.is_tuesday
            || this.state.calendarLock.is_wednesday
            || this.state.calendarLock.is_thursday
            || this.state.calendarLock.is_friday
            || this.state.calendarLock.is_saturday
        )
            return true;
        return false;
    }

    /**
     * Can be add item in release calendar lock
     */
    canBeSaved() {
        let calendarLock = this.state.calendarLock;
        if (calendarLock.message !== ""
            && calendarLock.number_ramps !== ""
            && calendarLock.time_hour_start !== ""
            && calendarLock.time_hour_end !== ""
            && ((calendarLock.all_days && this.hasCheckedDay())
                || (calendarLock.by_date && (calendarLock.time_hour_lock_start !== "" && calendarLock.time_hour_lock_end !== ""))
                || (calendarLock.by_period && (calendarLock.time_hour_lock_start !== "" && calendarLock.time_hour_lock_end !== ""))
            )
        )
            return true;
        return false;
    }

    /**
     * Metho to can be created release calendar lock
     */
    canBeCreateReleaseCalendarLock() {
        if (this.props.canBeCreate) {
            this.props.canBeCreate(this.state.calendarLock)
        }
    }

    renderFormControlCheckBox(property, translationId) {
        return (
            <FormControlLabel
                control={
                    <Checkbox checked={this.state.calendarLock[property]}
                        onChange={(event) => { this.updateProperty(property, event.target.checked) }}
                    />
                }
                label={<Translate id={translationId} />}
            />
        )
    }

    renderDaysWeekSection() {
        return (
            <React.Fragment>
                <FormGroup row>
                    {this.renderFormControlCheckBox("is_monday", "releaseCalendarLock.isMonday")}
                    {this.renderFormControlCheckBox("is_tuesday", "releaseCalendarLock.isTuesday")}
                    {this.renderFormControlCheckBox("is_wednesday", "releaseCalendarLock.isWednesday")}
                </FormGroup>
                <FormGroup row>
                    {this.renderFormControlCheckBox("is_thursday", "releaseCalendarLock.isThursday")}
                    {this.renderFormControlCheckBox("is_friday", "releaseCalendarLock.isFriday")}
                    {this.renderFormControlCheckBox("is_saturday", "releaseCalendarLock.isSaturday")}
                </FormGroup>
            </React.Fragment>
        )
    }

    renderDatePicker(property, translationId) {
        return (
            <Grid item xs={6}>
                <Typography variant="caption" color={'textSecondary'}>
                    <Translate id={translationId} />
                </Typography>
                <DatePicker
                    onChangeRange={(date) => this.onChangeDate(date, property)}
                    startDate={this.state.calendarLock[property] ? this.state.calendarLock[property] : ''}
                    minDate={moment()}
                />
            </Grid>
        )
    }
    renderDatesSection() {
        return (
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    {this.renderDatePicker("time_hour_lock_start", "releaseCalendarLock.startDate")}
                    {this.renderDatePicker("time_hour_lock_end", "releaseCalendarLock.endDate")}
                </Grid>
                <Grid item xs={12}>
                    <br/>
                    <Typography variant="caption" color={'textSecondary'}>
                        <strong>
                            {"Seleccione la fecha para bloquear el calendario completo, en el rango de fecha seleccionado desde la fecha y hora inicio hasta la  fecha y hora fin."}
                        </strong>
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    renderByPeriodSection() {
        return (
            <Grid item xs={12}>
                <Grid container spacing={24}>
                    {this.renderDatePicker("time_hour_lock_start", "releaseCalendarLock.startDate")}
                    {this.renderDatePicker("time_hour_lock_end", "releaseCalendarLock.endDate")}
                </Grid>
                <Grid item xs={12}>
                    <br/>
                    <Typography variant="caption" color={'textSecondary'}>
                        <strong>
                            {"Seleccione el periodo de rango de fechas que requiere bloquear todos los dias al mismo horario en el periodo seleccionado."}
                        </strong>
                    </Typography>
                </Grid>
            </Grid>
        )
    }

     renderSelect(property, translationId, options, xs) {
        return (
            <Grid item xs={xs}>
                <FormLabel component="legend">
                    <Translate id={translationId} />
                </FormLabel>
                <Select
                    defaultValue={options[0]}
                    options={options}
                    fullWidth
                    onChange={(event) => { this.updatePropertySelected(event.value, property) }}
                    styles={{ menu: (base) => ({ ...base, zIndex: 10 }) }}
                />
            </Grid>
        )
    }

    renderRampAndHourSection() {
        let rampsByType = GetRampsFromRelease();
        let timeOptions = GetTimeToLockCalendar();
        return (
            <Grid item xs={6}>
                <Grid container spacing={24}>
                    {this.renderSelect("number_ramps", "releaseCalendarLock.rampsNumber", rampsByType, 12)}
                    {this.renderSelect("time_hour_start", "releaseCalendarLock.starHour", timeOptions, 6)}
                    {this.renderSelect("time_hour_end", "releaseCalendarLock.endHour", timeOptions, 6)}
                </Grid>
            </Grid>
        )
    }

    renderCommentSection() {
        return (
            <Grid item xs={12}>
                <TextField fullWidth required
                    id="message"
                    label={<Translate id="releaseCalendarLock.lockRequiered" />}
                    multiline
                    rows="4"
                    value={this.state.calendarLock.message}
                    onChange={(event) => { this.updateProperty("message", event.target.value) }}
                    margin="normal"
                    variant="outlined"
                />
            </Grid>
        )
    }
    /**
     * Render show
     */
    render() {
        return (
            <Card>
                <CardHeader
                    avatar={<AlertIcon color="primary" />}
                    title={
                        <Typography variant="subtitle2" color={'textSecondary'}>
                            <Translate id="releaseCalendarLock.messageToAddCalendar" />
                        </Typography>
                    }
                />
                <CardContent>
                    <Grid container spacing={24} justify="center">
                        <Grid item xs={6}>
                            <FormControl>
                                <FormGroup row>
                                    {this.renderFormControlCheckBox("all_days", "releaseCalendarLock.allDays")}
                                    {this.renderFormControlCheckBox("by_date", "releaseCalendarLock.lockByDate")}
                                    {this.renderFormControlCheckBox("by_period", "releaseCalendarLock.rereatByPreriod")}
                                </FormGroup>
                                {/** DaysOfWeek checks section*/}
                                {this.state.calendarLock.all_days && <FormGroup>
                                    {this.renderDaysWeekSection()}
                                </FormGroup>}
                                {/** Dates datepickers section*/}
                                {this.state.calendarLock.by_date && <FormGroup>
                                    {this.renderDatesSection()}
                                </FormGroup>}
                                {/** Dates by_period section*/}
                                {this.state.calendarLock.by_period && <FormGroup>
                                    {this.renderByPeriodSection()}
                                </FormGroup>}
                            </FormControl>
                        </Grid>
                        {/** RAMP AND HOUR SECTION */}
                        {this.renderRampAndHourSection()}
                        {/** COMMENT AND BUTTON SAVE SECTION */}
                        {this.renderCommentSection()}
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container spacing={24} justify="flex-end" style={{ margin: '0px 1rem' }}>
                        <Grid item xs={1}>
                            <Button
                                color="primary"
                                onClick={this.canBeCreateReleaseCalendarLock}
                                disabled={!this.canBeSaved() || this.props.isSaving}
                            >
                                <Translate id="common.save" />
                            </Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                onClick={this.handleCancel}
                                disabled={this.props.isSaving}
                            >
                                <Typography variant="button" color="error">
                                    <Translate id="common.cancel" />
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Card >
        )
    }
}

export default (FormView);