import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { RViewer, RViewerTrigger } from 'react-viewerjs'

import { Typography, Toolbar } from '@material-ui/core'

import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'
import { HelpOutline } from '@material-ui/icons'

import visual1 from '../../../resources/images/guaranteeReport/visual-aid-page-1.jpg'
import visual2 from '../../../resources/images/guaranteeReport/visual-aid-page-2.jpg'
import visual3 from '../../../resources/images/guaranteeReport/visual-aid-page-3.jpg'
import { Translate } from 'react-localize-redux'

const DialogEvidenceHelper = (props) => {
	const { open, onClose, classes } = props
	let urls = [visual1, visual2, visual3]
	return (
		<Dialog
			open={open}
			onClose={onClose}
			header={
				<Toolbar>
					<HelpOutline color='primary' className={classes.titleIcon} />
					<Typography variant='h6'>
						<Translate id='moduleGuarantee.reportGuarantee.aidHeader' />
					</Typography>
				</Toolbar>
			}
			actions={<DialogActions onClose={onClose} classes={classes} />}
		>
			<Typography variant='subtitle2'>
				<Translate id='moduleGuarantee.reportGuarantee.aidBody1' />
			</Typography>
			<ol>
				<li>
					<Typography variant='subtitle2'>
						<Translate id='moduleGuarantee.reportGuarantee.aidBody2' />
					</Typography>
				</li>
				<li>
					<Typography variant='subtitle2'>
						<Translate id='moduleGuarantee.reportGuarantee.aidBody3' />
					</Typography>
				</li>
				<li>
					<Typography variant='subtitle2'>
						<Translate id='moduleGuarantee.reportGuarantee.aidBody4' />
					</Typography>
					<ol>
						<li>
							<Typography variant='subtitle2'>
								<Translate id='moduleGuarantee.reportGuarantee.aidSubBody1' />
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle2'>
								<Translate id='moduleGuarantee.reportGuarantee.aidSubBody2' />
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle2'>
								<Translate id='moduleGuarantee.reportGuarantee.aidSubBody3' />
							</Typography>
						</li>
					</ol>
				</li>
			</ol>
			<Typography className={classes.aidText}>
				<Translate id='moduleGuarantee.reportGuarantee.textInfo' />
			</Typography>
			<RViewer imageUrls={urls}>
				<div className={classes.imgContainer}>
					{urls.map((url, index) => {
						return (
							<RViewerTrigger index={index}>
								<button>
									<img
										srcSet={`${url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
										src={`${url}?w=164&h=164&fit=crop&auto=format`}
										alt={'visual aid'}
										loading='lazy'
										width={'150px'}
										border='0px'
									/>
								</button>
							</RViewerTrigger>
						)
					})}
				</div>
			</RViewer>
		</Dialog>
	)
}

DialogEvidenceHelper.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
}
DialogEvidenceHelper.defaultProps = {
	open: false,
	onClose: () => console.warn('[onClose] is not defined!'),
}

const styles = (theme) => ({
	titleIcon: {
		marginRight: '5px',
	},
	image: {
		width: '30vw',
		margin: 'auto',
		marginTop: '5px',
		marginBottom: '5px',
	},
	imgContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	aidText: {
		fontSize: '12px',
		color: theme.palette.error.main,
		marginTop: '5px',
		marginBottom: '5px',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(DialogEvidenceHelper))
