
/** Helpers import section */
import axios from 'axios';
import moment from 'moment'
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';
import { BiddingMaterialOrServices, CategoryType } from '../helpers/AppConstants';
import {
    backgroundColorTableStatus, backgroundColorTableStatusProposal,
    descriptionStatus, descriptionProposalItem, getFiltersBidding,
    getPercentageDecimal, getExchangeRates, onMappedResulItemstTable,
    getCommentsSuppliersForFinishedBidding
} from '../helpers/BiddingHelper';
import { IsCurrentNotActiveDirectoryUser } from '../helpers/SessionHelper';
import { IsNullOrEmpty } from '../helpers/StringHelper';

export const SET_BIDDINGS_COUNT = "SET_BIDDINGS_COUNT";
export const IS_DOWNLOAD_REPORT_BIDDINGS = "IS_DOWNLOAD_REPORT_BIDDINGS"

export const START_LOADING_BIDDINGS = "START_LOADING_BIDDINGS";
export const IS_SAVING = "IS_SAVING";
/**Add actions section */
export const SET_BIDDINGS_LIST = "SET_BIDDINGS_LIST";
export const SET_DIVISAS_LIST = "SET_DIVISAS_LIST";
export const SET_BIDDING = "SET_BIDDING"
export const ADD_BIDDING = "ADD_BIDDING"
export const ADD_BIDDING_BASE = "ADD_BIDDING_BASE"
export const ADD_SUPPLIER = "ADD_SUPPLIER"
export const ADD_SERVICE_OR_MATERIAL = "ADD_SERVICE_OR_MATERIAL"
export const ADD_EVALUATION_CRITERIA = "ADD_EVALUATION_CRITERIA"
export const ADD_QUESTION = 'ADD_QUESTION'
export const SET_MEASUMENT_UNITS_BIDDINGS = "SET_MEASUMENT_UNITS_BIDDINGS"
export const ADD_ANSWER = "ADD_ANSWER"
export const ADD_EXCHANGE_RATES = "ADD_EXCHANGE_RATES"
export const SET_INCOTERMS = "SET_INCOTERMS"
export const ADD_WINNER_SUPPLIER = "ADD_WINNER_SUPPLIER"
export const ADD_WINNER_IN_ITEM_SUPPLIER = "ADD_WINNER_IN_ITEM_SUPPLIER"
export const PUBLISH_RESULTS_BIDDING = "PUBLISH_RESULTS_BIDDING"
/**Update actions section */
export const UPDATE_BIDDING = "UPDATE_BIDDING"
export const UPDATE_PUBLISH_FLAG_ENABLED = "UPDATE_PUBLISH_FLAG_ENABLED"
export const UPDATE_CALCULATION_FLAG_ENABLED = "UPDATE_CALCULATION_FLAG_ENABLED"
export const UPDATE_PROPERTY_BIDDING = "UPDATE_PROPERTY_BIDDING"
export const UPDATE_PROPERTY_EVALUATION_CRITERIA = "UPDATE_PROPERTY_EVALUATION_CRITERIA"
export const UPDATE_PROPERTY_CRITERIA = "UPDATE_PROPERTY_CRITERIA"
export const UPDATE_PROPERTY_ITEM = "UPDATE_PROPERTY_ITEM"
export const UPDATE_IS_SENT_PROPOSAL_SUPPLIER = "UPDATE_IS_SENT_PROPOSAL_SUPPLIER"
export const UPDATE_PROPERTY_EVALUATED_WEIGHING = "UPDATE_PROPERTY_EVALUATED_WEIGHING"
export const UPDATE_PROPERTY_EVALUATED_WEIGHING_SCOPE_GLOBAL = "UPDATE_PROPERTY_EVALUATED_WEIGHING_SCOPE_GLOBAL"
export const UPDATE_STEP_EXTERNL_USER = "UPDATE_STEP_EXTERNL_USER"
export const UPDATE_SECTION_INDEX = "UPDATE_SECTION_INDEX"
export const UPDATE_CRITERIA_GROUP = "UPDATE_CRITERIA_GROUP"
export const UPDATE_PUBLISH_RESULTS_FLAG_ENABLED = "UPDATE_PUBLISH_RESULTS_FLAG_ENABLED"
export const UPDATE_FINAL_COMMENTS_IN_SUPPLIER = "UPDATE_FINAL_COMMENTS_IN_SUPPLIER"
export const UPDATE_DIVISA = "UPDATE_DIVISA"
/**Delete actions section */
export const DELETE_BIDDING_SECTION = "DELETE_BIDDING_SECTION"
export const DELETE_SUPPLIER = "DELETE_SUPPLIER"
export const DELETE_BIDDING = "DELETE_BIDDING"
export const DELETE_EVALUATION_CRITERIA = "DELETE_EVALUATION_CRITERIA"
export const DELETE_SERVICE_OR_MATERIAL = "DELETE_SERVICE_OR_MATERIAL"


export function setUpdateDivisa(divisa) {
    return {
        type: UPDATE_DIVISA,
        divisa: divisa,
    }
}

export function setIncoterms(incoterms) {
    return {
        type: SET_INCOTERMS,
        incoterms: incoterms,
    }
}

export function setFinalCommentsSupplier(supplierCode, comments) {
    return {
        type: UPDATE_FINAL_COMMENTS_IN_SUPPLIER,
        comments: comments,
        supplierCode: supplierCode
    }
}


export function setWinnerInItem(supplier, propertyName, propertyValue) {
    return {
        type: ADD_WINNER_IN_ITEM_SUPPLIER,
        group: supplier.group,
        criteriaId: supplier.criteriaId,
        itemResponseId: supplier.itemId,
        supplierCode: supplier.supplierCode,
        propertyName: propertyName,
        propertyValue: propertyValue,

    }
}

export function setExchangeRates(exchangeRates) {
    return {
        type: ADD_EXCHANGE_RATES,
        exchangeRates: exchangeRates,
    }
}

export function setDivisas(divisas) {
    return {
        type: SET_DIVISAS_LIST,
        divisas: divisas
    }
}

export function setUpdateCriteriaGroup(currentCriteriaGroup, newCriteriaGroup) {
    return {
        type: UPDATE_CRITERIA_GROUP,
        currentCriteriaGroup,
        newCriteriaGroup
    }
}

export function setAnswer(id, answer) {
    return {
        type: ADD_ANSWER,
        id: id,
        answer: answer
    }
}

export function setQuestion(question) {
    return {
        type: ADD_QUESTION,
        question: question
    }
}
export function updateStepSupplierExternal(step) {
    return {
        type: UPDATE_STEP_EXTERNL_USER,
        step: step
    }
}
/**
 * Action start loading common data
 * @param {*} isLoading 
 */
function isLoadingCommon(isLoading, identifier) {
    return {
        type: START_LOADING_BIDDINGS,
        isLoading: isLoading,
        identifier: identifier
    }
}

/**
 * Action start saving information
 * @param {*} isLoading 
 */
function isSavingCommon(isSaving, identifier) {
    return {
        type: IS_SAVING,
        isSaving: isSaving,
        identifier: identifier
    }
}

/**
 * Action to set BIDDINGS list 
 * @param {*} BIDDINGS 
 */
function setBiddingsList(biddings) {
    return {
        type: SET_BIDDINGS_LIST,
        biddings: biddings
    }
}

/**
 * Action to set biddings count
 * @param {*} count 
 */
function setBiddingsCount(count) {
    return {
        type: SET_BIDDINGS_COUNT,
        count
    }
}

function isDownloading(isDownloading) {
    return {
        type: IS_DOWNLOAD_REPORT_BIDDINGS,
        isDownloading
    }
}

function setAddBidding(bidding) {
    return {
        type: ADD_BIDDING,
        bidding
    }
}

export function setBidding(bidding) {
    return {
        type: SET_BIDDING,
        bidding
    }
}

/**
 * Action to update bidding in redux store
 * @param {*} BIDDINGS 
 */
function updateBidding(bidding) {
    return {
        type: UPDATE_BIDDING,
        bidding: bidding
    }
}

/**
 * Action to update bidding in redux store
 * @param {*} BIDDINGS 
 */
function addBiddingBase(bidding) {
    return {
        type: ADD_BIDDING_BASE,
        bidding: bidding
    }
}

/**
 * Action to delete bidding in redux store
 * @param {*} BIDDINGS 
 */
function deleteBiddingBase(id) {
    return {
        type: DELETE_BIDDING_SECTION,
        id: id
    }
}

/**
 * Action to delete bidding in redux store
 * @param {*} BIDDINGS 
 */
function deleteBidding(id) {
    return {
        type: DELETE_BIDDING,
        id: id
    }
}

function setAddSupplier(supplier) {
    return {
        type: ADD_SUPPLIER,
        supplier
    }
}

/**
 * Action to delete supplier 
 * @param {*} BIDDINGS 
 */
function deleteSupplier(id) {
    return {
        type: DELETE_SUPPLIER,
        id: id
    }
}

function setAddService(item) {
    return {
        type: ADD_SERVICE_OR_MATERIAL,
        item: item
    }
}

function setAddEvaluationCriteria(evaluationCriteria) {
    return {
        type: ADD_EVALUATION_CRITERIA,
        evaluationCriteria: evaluationCriteria
    }
}

function updatePropertyCriteria(criteriaId, propertyName, propertyValue) {
    return {
        type: UPDATE_PROPERTY_CRITERIA,
        criteriaId: criteriaId,
        propertyName: propertyName,
        propertyValue: propertyValue
    }
}
function updatePropertyEvaluationCriteria(itemId, evaluationId, propertyName, propertyValue) {
    return {
        type: UPDATE_PROPERTY_EVALUATION_CRITERIA,
        itemId: itemId,
        evaluationId: evaluationId,
        propertyName: propertyName,
        propertyValue: propertyValue
    }
}

function updatePropertyEvaluationCriteriaService(itemPoposalId, supplierCode, evaluationId, propertyName, propertyValue) {
    return {
        type: UPDATE_PROPERTY_EVALUATED_WEIGHING,
        itemPoposalId: itemPoposalId,
        supplierCode: supplierCode,
        evaluationId: evaluationId,
        propertyName: propertyName,
        propertyValue: propertyValue
    }
}

function updatePropertyEvaluationCriteriaServiceScopeGlobal(supplierCode, evaluationId, propertyName, propertyValue) {
    return {
        type: UPDATE_PROPERTY_EVALUATED_WEIGHING_SCOPE_GLOBAL,
        supplierCode: supplierCode,
        evaluationId: evaluationId,
        propertyName: propertyName,
        propertyValue: propertyValue
    }
}


export function updatePropertyItem(itemId, propertyName, propertyValue) {
    return {
        type: UPDATE_PROPERTY_ITEM,
        itemId: itemId,
        propertyName: propertyName,
        propertyValue: propertyValue
    }
}

/**
 * Action to delete bidding evaluation criteria in redux store
 * @param {*} BIDDINGS 
 */
function deleteEvaluation(id) {
    return {
        type: DELETE_EVALUATION_CRITERIA,
        id: id,
    }
}

function deleteServiceOrMaterial(id) {
    return {
        type: DELETE_SERVICE_OR_MATERIAL,
        id: id,
    }
}

function updateFlagPublishIsEnabled(isEnabled) {
    return {
        type: UPDATE_PUBLISH_FLAG_ENABLED,
        isEnabled: isEnabled
    }
}

function updateFlagPublishResultsIsEnabled(isEnabled) {
    return {
        type: UPDATE_PUBLISH_RESULTS_FLAG_ENABLED,
        isEnabled: isEnabled
    }
}

function updateFlagCalculationIsEnabled(isEnabled) {
    return {
        type: UPDATE_CALCULATION_FLAG_ENABLED,
        isEnabled: isEnabled
    }
}

function updatePropertyBidding(propertyName, propertyValue) {
    return {
        type: UPDATE_PROPERTY_BIDDING,
        propertyName: propertyName,
        propertyValue: propertyValue,
    }
}

function setMeasurementUnits(units) {
    return {
        type: SET_MEASUMENT_UNITS_BIDDINGS,
        units: units
    }
}

function updateIsSentProposalEnabled(isEnabled) {
    return {
        type: UPDATE_IS_SENT_PROPOSAL_SUPPLIER,
        isEnabled: isEnabled
    }
}

function updateSectionIndex(id, index) {
    return {
        type: UPDATE_SECTION_INDEX,
        id: id,
        index: index

    }
}


/**
 * Action load BIDDINGS from company backend
 * @param {*} query 
 * @param {*} orderBy 
 * @param {*} sortAscending 
 * @param {*} page 
 * @param {*} rowsPerPage 
 */
export function loadAllBiddingsFromBackEnd(queryParameters, translate) {
    return function (dispatch, getState) {

        dispatch(isLoadingCommon(true, START_LOADING_BIDDINGS));
        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/biddings`;
            let command = {
                PageNumber: (queryParameters && queryParameters.pageNumber) ? (parseInt(queryParameters.pageNumber) + 1) : 1,
                PageSize: (queryParameters && queryParameters.rowsPerPage) ? queryParameters.rowsPerPage : 20,
                SortingBy: ((queryParameters && queryParameters.sortBy) ? queryParameters.sortBy : "CreationDate"),
                SortingOrder: ((queryParameters && queryParameters.sortDirection) ? queryParameters.sortDirection : 'desc'),
                Filters: getFiltersBidding(queryParameters.query),
            };
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        let mappedResponse = serverResponse.data.Results.map(bidding => {
                            if (IsCurrentNotActiveDirectoryUser(user)) {
                                bidding.color_status_table = backgroundColorTableStatusProposal(bidding.ProposalStatus)
                                bidding.Status = descriptionProposalItem(bidding.ProposalStatus, translate)
                                bidding.PlannedClosingDate = !IsNullOrEmpty(bidding.PlannedClosingDate) ? moment(bidding.PlannedClosingDate).format('DD/MM/YYYY') : ""
                                bidding.PlannedPublicationResultsDate = bidding.PlannedOpeningDate
                                bidding.Folio = bidding.FormatedFolio
                            }
                            else {
                                bidding.color_status_table = backgroundColorTableStatus(bidding.Status)
                                bidding.Status = descriptionStatus(bidding.Status, translate)
                                bidding.PlannedClosingDate = !IsNullOrEmpty(bidding.PlannedClosingDate) ? moment(bidding.PlannedClosingDate).format('DD/MM/YYYY') : ""
                                bidding.PlannedPublicationResultsDate = bidding.PlannedOpeningDate
                                bidding.Folio = bidding.FormatedFolio

                            }
                            return bidding
                        })
                        dispatch(setBiddingsCount(serverResponse.data.TotalItems))
                        dispatch(setBiddingsList(mappedResponse))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingCommon(false, SET_BIDDINGS_LIST));
                    console.error("Error getting contracts list by company", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isLoadingCommon(false, SET_BIDDINGS_LIST));
        }
    }
}

export function downloadReportBiddings() {
    return function (dispatch, getState) {
        dispatch(isDownloading(true));
        const url = `${process.env.REACT_APP_SIP_BIDDING}/biddings/report`;
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        return axios.get(url, { responseType: 'arraybuffer', headers: configuration.headers })
            .then(serverResponse => {
                if (serverResponse.status === 200) {
                    dispatch(isDownloading(false));
                    var blob = new Blob([serverResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reporte-licitaciones.xlsx";
                    a.click();
                    return Promise.resolve('Ok');
                }
            })
            .catch(error => {
                dispatch(isDownloading(false));
                console.error('Error al to download report bidding', error);
                return Promise.reject();
            });
    }
}

export function addBidding(biddingModel, translate) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, ADD_BIDDING));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding`;

            let endDate = (biddingModel && biddingModel.closingDate) ? moment(biddingModel.closingDate).format("YYYY/MM/DD") : ""
            let endDateAndTime = endDate ? moment(endDate + " " + biddingModel.closingDateTimer, 'YYYY-MM-DD HH:mm') : ""
            let command = {
                Name: biddingModel ? biddingModel.name : "",
                Description: biddingModel ? biddingModel.description : "",
                PlannedClosingDate: endDateAndTime ? endDateAndTime.format('YYYY-MM-DD HH:mm') : null,
                plannedPublicationDate: biddingModel ? biddingModel.publishDate : null,
                CategoryId: biddingModel.category ? biddingModel.category.value : null,
                Responsibles: biddingModel ? biddingModel.responsibles.map(x => x.user_name) : null,
                InitializeDefaultSections: biddingModel ? biddingModel.initializeDefaultSections : true
            }
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        serverResponse.data.color_status_table = backgroundColorTableStatus(serverResponse.data.Status)
                        serverResponse.data.Status = descriptionStatus(serverResponse.data.Status, translate)
                        serverResponse.data.PlannedClosingDate = !IsNullOrEmpty(serverResponse.data.PlannedClosingDate) ? moment(serverResponse.data.PlannedClosingDate).format('DD/MM/YYYY') : ""
                        serverResponse.data.PlannedPublicationResultsDate = serverResponse.data.PlannedOpeningDate
                        serverResponse.data.Folio = serverResponse.data.FormatedFolio
                        dispatch(setAddBidding(serverResponse.data))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, ADD_BIDDING));
                    console.error("Error in add bidding", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isSavingCommon(false, ADD_BIDDING));
        }
    }
}

export function loadBiddingFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(isLoadingCommon(true, SET_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isLoadingCommon(false, SET_BIDDING));
                    dispatch(setBidding(serverResponse.data))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isLoadingCommon(false, SET_BIDDING));
                console.error("Error getting bidding", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function updateBiddingFromBackEnd(biddingModel, biddingId) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingId}`;
        const endDate = (biddingModel && biddingModel.closingDate) ? moment(biddingModel.closingDate, "DD/MM/YYYY") : null
        const fortmatedDate = endDate ? moment(endDate).format("DD/MM/YYYY") : ""
        let endDateAndTime = fortmatedDate ? moment(fortmatedDate + " " + biddingModel.closingDateTimer, 'DD/MM/YYYY HH:mm:ss') : ""
        let command = {
            Name: biddingModel ? biddingModel.name : "",
            Description: biddingModel ? biddingModel.description : "",
            PlannedClosingDate: endDateAndTime ? moment(endDateAndTime).format("DD/MM/YYYY HH:mm:ss") : null,
            PlannedPublicationResultsDate: biddingModel.publishDate ? moment(biddingModel.publishDate).format("DD/MM/YYYY HH:mm:ss") : null,
            CategoryId: biddingModel.category ? biddingModel.category.value : null,
            Responsibles: biddingModel ? biddingModel.responsibles.map(x => x.user_name) : null,
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateBidding(serverResponse.data))
                    dispatch(setBidding(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_BIDDING));
                console.error("Error update bidding", error.response)
                return Promise.reject(error.response)
            })
    }
}


export function addBiddingBaseFromBackEnd(biddingBaseModel, biddingId) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingId}/section`;
        let command = {
            BiddingId: biddingId,
            Name: biddingBaseModel ? biddingBaseModel.Name : "",
            Content: biddingBaseModel ? biddingBaseModel.Content : "",
        }
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addBiddingBase(serverResponse.data))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_BIDDING));
                console.error("Error add bidding section", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function editBiddingBaseFromBackEnd(biddingBaseModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/section/${biddingBaseModel.Id}`;
        let command = {
            Name: biddingBaseModel ? biddingBaseModel.Name : "",
            Content: biddingBaseModel ? biddingBaseModel.Content : "",
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addBiddingBase(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_BIDDING));
                console.error("Error update bidding base", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function deletedBiddingBaseFromBackEnd(biddingBaseModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, DELETE_BIDDING_SECTION));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/section/${biddingBaseModel.Id}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(deleteBiddingBase(biddingBaseModel.Id))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, DELETE_BIDDING_SECTION));
                console.error("Error deleted bidding base", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addSupplierInBiddingFromBackEnd(supplierModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${supplierModel.Id}/supplier`;
        let command = {
            BiddingId: supplierModel ? supplierModel.Id : "",
            Code: supplierModel ? supplierModel.code : "",
            Name: supplierModel ? supplierModel.name : "",
            SupplierType: 0
        }
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setAddSupplier(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_BIDDING));
                console.error("Error update bidding base", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function deletedSupplierBiddingFromBackEnd(supplierModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, DELETE_SUPPLIER));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/supplier/${supplierModel.Id}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(deleteSupplier(supplierModel.Id))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, DELETE_SUPPLIER));
                console.error("Error deleted bidding supplier", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addExternalSupplierInBiddingFromBackEnd(supplierModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${supplierModel.Id}/supplier`;
        let command = {
            BiddingId: supplierModel ? supplierModel.Id : "",
            Name: supplierModel ? supplierModel.name : "",
            ContactName: supplierModel ? supplierModel.contactName : "",
            ContactEmail: supplierModel ? supplierModel.email : "",
            Code: "",
            SupplierType: 1
        }
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setAddSupplier(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_BIDDING));
                console.error("Error update bidding base", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function removeBidding(biddingId) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, DELETE_BIDDING));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingId}`;

            return axios.delete(endPoint, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(deleteBidding(biddingId))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, DELETE_BIDDING));
                    console.error("Error in delete bidding", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isSavingCommon(false, DELETE_BIDDING));
        }
    }
}

export function startNewRoundBidding(biddingModel, translate) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, DELETE_BIDDING));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingModel.Id}/round`;
            let endDate = (biddingModel && biddingModel.closingDate) ? moment(biddingModel.closingDate).format("YYYY/MM/DD") : ""
            let endDateAndTime = endDate ? moment(endDate + " " + biddingModel.closingDateTimer, 'YYYY-MM-DD HH:mm') : ""
            let command = {
                PlannedClosingDate: endDateAndTime ? endDateAndTime.format('YYYY-MM-DD HH:mm') : null,
                PlannedPublicationDate: biddingModel ? biddingModel.publishDate : null,
            }
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        serverResponse.data.color_status_table = backgroundColorTableStatus(serverResponse.data.Status)
                        serverResponse.data.Status = descriptionStatus(serverResponse.data.Status, translate)
                        serverResponse.data.PlannedClosingDate = !IsNullOrEmpty(serverResponse.data.PlannedClosingDate) ? moment(serverResponse.data.PlannedClosingDate).format('DD/MM/YYYY') : ""
                        serverResponse.data.PlannedPublicationResultsDate = serverResponse.data.PlannedOpeningDate
                        serverResponse.data.Folio = serverResponse.data.FormatedFolio
                        dispatch(setAddBidding(serverResponse.data))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, DELETE_BIDDING));
                    console.error("Error in duplicate bidding", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isSavingCommon(false, DELETE_BIDDING));
        }
    }
}

export function addServiceFromBackEnd(biddingModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_SERVICE_OR_MATERIAL));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingModel.Id}/items`;
        let command = {};
        if (biddingModel.type === CategoryType.MATERIAL) {
            command.ItemType = BiddingMaterialOrServices.MATERIAL
            command.Code = biddingModel.partNumber ? biddingModel.partNumber.code : ""
            command.Name = biddingModel.partNumber ? biddingModel.partNumber.description : ""
            command.MeasurementUnit = biddingModel.partNumber ? biddingModel.partNumber.measurementUnit : ""
            command.Notes = biddingModel ? biddingModel.comments : ""
            command.Quantity = biddingModel ? biddingModel.quantity : 0
            command.PurchasePeriod = biddingModel ? biddingModel.purchasePeriod : ""
            command.BaseUnit = biddingModel ? biddingModel.baseUnit : 0
            command.BaseMeasurement = biddingModel ? biddingModel.baseMeasurement : ""
        }
        if (biddingModel.type === CategoryType.SERVICE) {
            command.ItemType = BiddingMaterialOrServices.SERVICE
            command.Name = biddingModel ? biddingModel.name : ""
            command.Notes = biddingModel ? biddingModel.comments : ""
        }
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setAddService(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_SERVICE_OR_MATERIAL));
                console.error("Error in create service or material", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addEvaluationCriteriaService(biddingModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_EVALUATION_CRITERIA));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingModel.Id}/evaluationcriterias`;
        let command = {
            BiddingId: biddingModel.Id,
            Name: biddingModel ? biddingModel.name : "",
            Notes: biddingModel ? biddingModel.comments : "",
            Weighing: biddingModel ? getPercentageDecimal(biddingModel.weighing) : "",
            Scope: biddingModel ? (biddingModel.criteria ? biddingModel.criteria.value : "") : "",
            CriteriaType: biddingModel ? biddingModel.criteriaType.value : "",
            EvaluationRule: biddingModel.evaluationRule ? biddingModel.evaluationRule.value : 0,
            CriteriaGroup: biddingModel.grouper ? biddingModel.grouper.label : "",
            LowerLimit: biddingModel.minimumRange ? biddingModel.minimumRange : null,
            UpperLimit: biddingModel.maximumRange ? biddingModel.maximumRange : null,
            Format: biddingModel.numberFormat ? biddingModel.numberFormat.value : "",
            MeasurementUnit: biddingModel.measurementUnit ? biddingModel.measurementUnit.label : "",
        }
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setAddEvaluationCriteria(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                console.error("Error in create bidding evaluation criteria", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function updateEvaluationCriteria(biddingModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_EVALUATION_CRITERIA));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/evaluationcriterias/${biddingModel.Id}`;
        let command = {
            BiddingId: biddingModel.Id,
            Name: biddingModel ? biddingModel.name : "",
            Notes: biddingModel ? biddingModel.comments : "",
            Weighing: biddingModel ? getPercentageDecimal(biddingModel.weighing) : "",
            Scope: biddingModel ? (biddingModel.criteria ? biddingModel.criteria.value : "") : "",
            CriteriaType: biddingModel ? biddingModel.criteriaType.value : "",
            EvaluationRule: biddingModel.evaluationRule ? biddingModel.evaluationRule.value : 0,
            CriteriaGroup: biddingModel.grouper ? biddingModel.grouper.label : "",
            LowerLimit: biddingModel.minimumRange ? biddingModel.minimumRange : null,
            UpperLimit: biddingModel.maximumRange ? biddingModel.maximumRange : null,
            Format: biddingModel.numberFormat ? biddingModel.numberFormat.value : "",
            MeasurementUnit: biddingModel.measurementUnit ? biddingModel.measurementUnit.label : "",
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setAddEvaluationCriteria(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                console.error("Error in create bidding evaluation criteria", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function updateEvaluationCriteriaGroup(biddingModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_EVALUATION_CRITERIA));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingModel.Id}/group`;
        let command = {
            BiddingId: biddingModel.BiddingId,
            ItemId: biddingModel.Id,
            CurrentCriteriaGroup: biddingModel.Grouper ? biddingModel.Grouper.label : "",
            NewCriteriaGroup: biddingModel.NewGrouper ? biddingModel.NewGrouper.label : "",
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setUpdateCriteriaGroup(command.CurrentCriteriaGroup, command.NewCriteriaGroup))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                console.error("Error in edit evaluation criteria group", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function removeEvaluationCriteria(criteriaId) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, DELETE_EVALUATION_CRITERIA));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/evaluationcriteria/${criteriaId}`;

            return axios.delete(endPoint, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(deleteEvaluation(criteriaId))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, DELETE_EVALUATION_CRITERIA));
                    console.error("Error in delete bidding evaluation criteria", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isSavingCommon(false, DELETE_EVALUATION_CRITERIA));
        }
    }
}

export function addResponseSupplier(serviceCriteriaId, itemId, suppliersResponse, isGeneralResponse, comments) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_EVALUATION_CRITERIA));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/proposal/item/evaluationcriteria/${itemId}`;
        let command = {
            ItemId: serviceCriteriaId,
            EvaluationCriteriaId: itemId,
            SuppliersResponse: suppliersResponse,
            Comments: comments
        }
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    if (isGeneralResponse) {
                        dispatch(updatePropertyCriteria(serverResponse.data.Id, "Response", serverResponse.data.Response))
                        dispatch(updatePropertyCriteria(serverResponse.data.Id, "Comments", comments))
                    }
                    else {
                        dispatch(updatePropertyEvaluationCriteria(serviceCriteriaId, serverResponse.data.Id, "Response", serverResponse.data.Response))
                    }
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                console.error("Error in create bidding evaluation criteria", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addMaterialResponsebySupplier(materialModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_EVALUATION_CRITERIA));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/proposal/material/${materialModel.Id}`;
        let command = {
            PurchaseQuantity: materialModel.purchaseQuantity,
            PurchaseMeasurementUnit: materialModel.purchaseMeasurementUnit,
            PurchasePrice: materialModel.purchasePrice,
            PurchaseDivisaCode: materialModel.purchaseDivisa ? materialModel.purchaseDivisa.value : "",
            MaterialPrice: materialModel.materialPrice,
            MaterialDivisaCode: materialModel.materialDivisa ? materialModel.materialDivisa.value : "",
            IncotermCode: materialModel.incoterm ? materialModel.incoterm.value : "",
            Destination: materialModel.destination,
            PurchaseMinimumQuantity: materialModel.purchaseMinimumQuantity
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updatePropertyEvaluationCriteria(materialModel.ItemId, materialModel.criteriaId, "Response", materialModel.materialPrice))
                    dispatch(updatePropertyItem(materialModel.ItemId, "PurchasePrice", serverResponse.data.PurchasePrice))
                    dispatch(updatePropertyItem(materialModel.ItemId, "IncotermCode", serverResponse.data.IncotermCode))
                    dispatch(updatePropertyItem(materialModel.ItemId, "PurchaseQuantity", serverResponse.data.PurchaseQuantity))
                    dispatch(updatePropertyItem(materialModel.ItemId, "Destination", serverResponse.data.Destination))
                    dispatch(updatePropertyItem(materialModel.ItemId, "DivisaCode", serverResponse.data.DivisaCode))
                    dispatch(updatePropertyItem(materialModel.ItemId, "PurchaseDivisaCode", serverResponse.data.PurchaseDivisaCode))
                    dispatch(updatePropertyItem(materialModel.ItemId, "PurchaseMeasurementUnit", serverResponse.data.PurchaseMeasurementUnit))
                    dispatch(updatePropertyItem(materialModel.ItemId, "PurchaseMinimumQuantity", serverResponse.data.PurchaseMinimumQuantity))
                    dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                console.error("Error in add response by supplier", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function removeServiceOrMaterial(itemId) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, DELETE_SERVICE_OR_MATERIAL));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/item/${itemId}`;

            return axios.delete(endPoint, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(deleteServiceOrMaterial(itemId))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, DELETE_SERVICE_OR_MATERIAL));
                    console.error("Error in delete service or material", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isSavingCommon(false, DELETE_SERVICE_OR_MATERIAL));
        }
    }
}

export function changeStatusBiddingFromBackEnd(status, biddingId, exchangeRates = []) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_PUBLISH_FLAG_ENABLED));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingId}/status`;
        let command = {
            Status: status,
            ExchangeRates: exchangeRates.length !== 0 ? getExchangeRates(exchangeRates) : null
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, UPDATE_PUBLISH_FLAG_ENABLED));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_PUBLISH_FLAG_ENABLED));
                console.error("Error update status bidding", error.response)
                return Promise.reject(error.response)
            })
    }
}

// Update external supplier contact email
export function updateContactEmailFromBackEnd(itemModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/supplier/updateContact`
        let command = {
            ContactEmail: itemModel.updateContact,
            BiddingId: itemModel.biddingId,
            Code: itemModel.supplierCode,
            SupplierType: itemModel.supplierType
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateBidding(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_BIDDING));
                console.error("Error update contact Email", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function changeStatusSupplierFromBackEnd(status, biddingId, comments = "", supplierId) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/proposal/${biddingId}/status`
        let command = {
            Status: status,
            Comment: comments,
            SupplierId: supplierId,
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updatePropertyBidding("ProposalStatus", serverResponse.data.Status))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_BIDDING));
                console.error("Error update estatus proposal", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function isPublicationEnabled(biddingId) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_PUBLISH_FLAG_ENABLED));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingId}/publication/enabled`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateFlagPublishIsEnabled(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_PUBLISH_FLAG_ENABLED));
                console.error("Error update bidding", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function isPublicationResultsEnabled(biddingId) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_PUBLISH_FLAG_ENABLED));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingId}/results/enabled`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateFlagPublishResultsIsEnabled(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_PUBLISH_FLAG_ENABLED));
                console.error("Error update bidding", error.response)
                return Promise.reject(error.response)
            })
    }
}


export function isCalculateEnabled(biddingId) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_CALCULATION_FLAG_ENABLED));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingId}/evaluation/enabled`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, UPDATE_CALCULATION_FLAG_ENABLED));
                    dispatch(updateFlagCalculationIsEnabled(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_CALCULATION_FLAG_ENABLED));
                console.error("Error update bidding", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function updateSectionFromBackEnd(section) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_SECTION_INDEX));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/section/index`;
        let command = {
            SectionId: section.draggableId ? section.draggableId : "",
            ToIndex: section ? section.destination.index : 0,
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateSectionIndex(section.draggableId ? section.draggableId : "", section ? section.destination.index : 0))
                    dispatch(isSavingCommon(false, UPDATE_SECTION_INDEX));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_SECTION_INDEX));
                console.error("Error update position bidding section", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function loadMeasurementUnitsFromBackEnd() {
    return function (dispatch, getState) {
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/evaluationcriterias/measurementunits`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setMeasurementUnits(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error load measurement units", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function isSentProposalEnabled(biddingId) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_IS_SENT_PROPOSAL_SUPPLIER));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingId}/proposal/enabled`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateIsSentProposalEnabled(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_IS_SENT_PROPOSAL_SUPPLIER));
                console.error("Error in get sent proporal enabled", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function setMaterialStatusBySupplierInternal(item, status) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_EVALUATION_CRITERIA));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/proposal/materials/${item ? item.ItemProposalId : ""}/status`;
        let command = {
            Status: status
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updatePropertyItem(item.ItemId, "Status", status))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                console.error("Error in create bidding evaluation criteria", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function setMaterialStatusBySupplierExternal(item, status) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_EVALUATION_CRITERIA));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/external/proposal/materials/${item ? item.ItemProposalId : ""}/status`;
        let command = {
            Status: status
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updatePropertyItem(item.ItemId, "Status", status))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                console.error("Error in create bidding evaluation criteria", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addEvaluationResponsibleFromBackend(supplier, responsibleResponse, isGeneralResponse) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_EVALUATION_CRITERIA));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/proposal/${supplier.supplierCode}/evaluationcriteria/${supplier.evaluationId}/weighing`;
        let command = {
            EvaluationCriteriaId: supplier.evaluationId,
            Value: responsibleResponse ? getPercentageDecimal(responsibleResponse) : ""
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    if (isGeneralResponse) {
                        dispatch(updatePropertyEvaluationCriteriaServiceScopeGlobal(supplier.supplierCode, supplier.evaluationId, "evaluatedWeighing", getPercentageDecimal(responsibleResponse)))

                    } else {
                        dispatch(updatePropertyEvaluationCriteriaService(supplier.itemPoposalId, supplier.supplierCode, supplier.evaluationId, "EvaluatedWeighing", responsibleResponse))
                    }
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                console.error("Error in create bidding evaluation criteria", error.response)
                return Promise.reject(error.response)
            })
    }
}


export function onAddQuestionFromBackEnd(questionModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${questionModel.Id}/question`;
        let command = {
            BiddingId: questionModel.Id,
            Name: questionModel.question
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setQuestion(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error("Error in create question", error.response)
                return Promise.reject(error.response)
            })
    }
}


export function onAddAnswerFromBackEnd(questionModel) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${questionModel.biddingId}/answer`;
        let command = {
            QuestionId: questionModel.questionId,
            Name: questionModel.answer
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setAnswer(questionModel.questionId, serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error("Error in create question", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addWinnerFromBackend(id, supplier) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_WINNER_SUPPLIER));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${id}/winner/${supplier.supplierCode}/`;
        let command = {
            BiddingInd: id,
        }
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                console.error("Error in create bidding evaluation criteria", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addWinnerInItemFromBackend(supplier, isWinner) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_WINNER_IN_ITEM_SUPPLIER));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/items/${supplier ? supplier.itemId : ""}/winner/${supplier.supplierCode}/${isWinner}`;
        let command = {
        }
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_WINNER_IN_ITEM_SUPPLIER));
                    dispatch(setWinnerInItem(supplier, "IsSelectedWinner", isWinner))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_WINNER_IN_ITEM_SUPPLIER));
                console.error("Error in create bidding evaluation criteria", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function importMaterialsFile(xmlFile, id) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_WINNER_SUPPLIER));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${id}/materials`;
        let formData = new FormData();
        xmlFile.forEach((file) => {
            formData.append('data', file);
        });
        return axios
            .post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                console.error('Error add attachments in the bidding', error.response);
                return Promise.reject()
            });
    };
}

export function importMaterialsPricesBysupplierFile(xmlFile, id) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_WINNER_SUPPLIER));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${id}/criterias/template`;
        let formData = new FormData();
        xmlFile.forEach((file) => {
            formData.append('data', file);
        });
        return axios
            .post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                console.error('Error responses by supplier ', error.response);
                return Promise.reject()
            });
    };
}

export function deleteAtachmentSupplier(atachment, id) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_WINNER_SUPPLIER));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/proposal/supplier/attachmet`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                console.error("Error in delete supplier", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addDivisaInBiddingResultFromBackEnd(id, divisa, supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${id}/divisa/${divisa.Code}/result`;
        let command = {
            BiddingId: id,
            DivisaCode: divisa ? divisa.Code : "",
            RequestedBy: supplierCode
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_QUESTION));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error("Error in create question", error.response)
                return Promise.reject(error.response)
            })
    }
}


export function addDivisaInBiddingResultSupplierExternalFromBackEnd(id, divisa, supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/external/bidding/${id}/suppliers/${supplierCode}/divisa/${divisa.value}`;
        let command = {}
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_QUESTION));
                    dispatch(setUpdateDivisa({
                        Code: divisa.value,
                        Name: divisa.label
                    }))

                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error("Error in create question", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function getExchangeRatesInBiddingFromBackEnd(id) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_EXCHANGE_RATES));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${id}/exchangerates`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_EXCHANGE_RATES));
                    dispatch(setExchangeRates(serverResponse.data, id))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_EXCHANGE_RATES));
                console.error("Error get exchange rates", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addIncotermInProposalFromBackEnd(id, incotermModel, supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${id}/incoterm/`;
        let command = {
            BiddingId: id,
            IncotermCode: incotermModel ? incotermModel.incoterm.value : "",
            Destination: incotermModel ? incotermModel.destination : "",
            RequestedBy: supplierCode
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_QUESTION));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error("Error in create question", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function downloadMaterialsReport(id) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        const url = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${id}/criterias/template`;
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        return axios.get(url, { responseType: 'arraybuffer', headers: configuration.headers })
            .then(serverResponse => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_QUESTION));
                    var blob = new Blob([serverResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "ReporteMaterials.xlsx";
                    a.click();
                    return Promise.resolve('Ok');
                }
            })
            .catch(error => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error('Error al descargar reporte de materiales ', error);
                return Promise.reject();
            });
    }
}

export function downloadResultBiddingReport(resultsGeneral, resultsItem, headersTable, bidding) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        const url = `${process.env.REACT_APP_SIP_BIDDING}/bidding/report/results`;
        let command = {
            SupplierResultsGenerals: onMappedResulItemstTable(resultsGeneral),
            SupplierResultsItems: onMappedResulItemstTable(resultsItem),
            Columns: headersTable.map(x => x.title),
            Name: bidding ? bidding.Name : "",
            Folio: bidding ? bidding.FormatedFolio : "",
        }
        return axios.post(url, command, { responseType: 'arraybuffer' })
            .then(serverResponse => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_QUESTION));
                    var blob = new Blob([serverResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "ReporteResultados.xlsx";
                    a.click();
                    return Promise.resolve('Ok');
                }
            })
            .catch(error => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error('Error al descargar reporte de materiales ', error);
                return Promise.reject();
            });
    }
}

export function resendEmailToSupplier(biddingId, supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingId}/supplier/${supplierCode}/email`
        let command = {}
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, UPDATE_BIDDING));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_BIDDING));
                console.error("Error to send email to supplier", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function getAllEmailsToSuppliers(supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/suppliers/${supplierCode}/users`
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, UPDATE_BIDDING));
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_BIDDING));
                console.error("Error to send email to supplier", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function publishResultsFromBackEnd(status, biddingId, suppliers = []) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, PUBLISH_RESULTS_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingId}/status`;
        let command = {
            Status: status,
            MessageToSuppliers: suppliers.length !== 0 ? getCommentsSuppliersForFinishedBidding(suppliers) : null
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, PUBLISH_RESULTS_BIDDING));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, PUBLISH_RESULTS_BIDDING));
                console.error("Error update status bidding", error.response)
                return Promise.reject(error.response)
            })
    }
}


export function addFinishedCommentsInSupplier(biddingId, supplier) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_PUBLISH_FLAG_ENABLED));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${biddingId}/suppliers/${supplier ? supplier.supplierCode : ""}/finalmessage`;
        let command = {
            Comment: supplier ? supplier.Comment : ""
        }
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setFinalCommentsSupplier(supplier.supplierCode, supplier ? supplier.Comment : ""))
                    dispatch(isSavingCommon(false, UPDATE_PUBLISH_FLAG_ENABLED));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_PUBLISH_FLAG_ENABLED));
                console.error("Error update status bidding", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function downloadAdditionalCostTemplate(id, supplier) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        const url = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${id}/suppliers/${supplier ? supplier.supplierCode : ""}/additionalcost/template`;
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        return axios.get(url, { responseType: 'arraybuffer', headers: configuration.headers })
            .then(serverResponse => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_QUESTION));
                    var blob = new Blob([serverResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "ReporteMaterials.xlsx";
                    a.click();
                    return Promise.resolve('Ok');
                }
            })
            .catch(error => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error('Error al descargar reporte de materiales ', error);
                return Promise.reject();
            });
    }
}

export function importAdditionalCostFile(xmlFile, id, supplier) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_WINNER_SUPPLIER));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${id}/suppliers/${supplier ? supplier.supplierCode : ""}/additionalcost/template`;
        let formData = new FormData();
        xmlFile.forEach((file) => {
            formData.append('data', file);
        });
        return axios
            .post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                console.error('Error add attachments in the bidding', error.response);
                return Promise.reject()
            });
    };
}

/**
 * DIVISAS SECTION
 * GETTING INFORMATION FROM DIVISAS
 */
export function loadDivisas() {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, SET_DIVISAS_LIST));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/divisas`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, SET_DIVISAS_LIST));
                    dispatch(setDivisas(serverResponse.data))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, SET_DIVISAS_LIST));
                console.error("Error getting bidding", error.response)
                return Promise.reject(false)
            })
    }
}

/**
 * INCOTERMS SECTION
 * GETTING INFORMATION FROM INCOTERMS
 */
export function loadIncoterms() {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, SET_INCOTERMS));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/incoterms`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, SET_INCOTERMS));
                    dispatch(setIncoterms(serverResponse.data))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, SET_INCOTERMS));
                console.error("Error getting bidding", error.response)
                return Promise.reject(false)
            })
    }
}


//#region ACTION FOR SUPPLIERS EXTERNALS
export function loadBiddingBySupplierExternalCode(code) {
    return function (dispatch, getState) {
        dispatch(isLoadingCommon(true, SET_BIDDING));
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/external/bidding/${code}`;
        return axios.get(endPoint)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isLoadingCommon(false, SET_BIDDING));
                    dispatch(setBidding(serverResponse.data))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isLoadingCommon(false, SET_BIDDING));
                console.error("Error getting bidding", error.response)
                return Promise.reject(false)
            })
    }
}

export function addResponseSupplierExternal(serviceCriteriaId, itemId, suppliersResponse, isGeneralResponse, supplierCode, comments) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_EVALUATION_CRITERIA));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/external/proposal/${supplierCode}/item/evaluationcriteria/${itemId}`;
        let command = {
            ItemId: serviceCriteriaId,
            EvaluationCriteriaId: itemId,
            SuppliersResponse: suppliersResponse,
            Comments: comments
        }
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    if (isGeneralResponse) {
                        dispatch(updatePropertyCriteria(serverResponse.data.Id, "Response", serverResponse.data.Response))
                        dispatch(updatePropertyCriteria(serverResponse.data.Id, "Comments", comments))
                    }
                    else {
                        dispatch(updatePropertyEvaluationCriteria(serviceCriteriaId, serverResponse.data.Id, "Response", serverResponse.data.Response))
                    }
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                console.error("Error in create bidding evaluation criteria", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function isSentProposalExternalEnabled(biddingId, supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_IS_SENT_PROPOSAL_SUPPLIER));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/external/bidding/${biddingId}/proposal/enabled/${supplierCode}`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateIsSentProposalEnabled(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_IS_SENT_PROPOSAL_SUPPLIER));
                console.error("Error in get sent proporal enabled", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addTermsAndConditionsFromBackend(supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_WINNER_SUPPLIER));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/external/bidding/${supplierCode}/terms`;
        let command = {
            SupplierCode: supplierCode,
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                console.error("Error in add termns and conditions in bidding", error.response)
                return Promise.reject(error.response)
            })
    }
}
export function onAddQuestionSupplierExternalFromBackEnd(questionModel, supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/external/bidding/${questionModel.Id}/question`;
        let command = {
            BiddingId: questionModel.Id,
            Name: questionModel.question,
            SupplierCode: supplierCode
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setQuestion(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error("Error in create question", error.response)
                return Promise.reject(error.response)
            })
    }
}
export function onAddAnswerSupplierExternalFromBackEnd(questionModel, supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/external/bidding/${questionModel.biddingId}/answer`;
        let command = {
            QuestionId: questionModel.questionId,
            Name: questionModel.answer,
            SupplierCode: supplierCode
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setAnswer(questionModel.questionId, serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error("Error in create question", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function changeStatusSupplierExternalFromBackEnd(status, biddingId, comments = "", supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_BIDDING));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/external/proposal/${biddingId}/status`
        let command = {
            Status: status,
            Comment: comments,
            SupplierCode: supplierCode
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updatePropertyBidding("ProposalStatus", serverResponse.data.Status))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_BIDDING));
                console.error("Error update estatus proposal", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function downloadMaterialsReportSupplierExternal(supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        const url = `${process.env.REACT_APP_SIP_BIDDING}/external/bidding/${supplierCode}/criterias/template`
        return axios.get(url, { responseType: 'arraybuffer' })
            .then(serverResponse => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_QUESTION));
                    var blob = new Blob([serverResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "ReporteMaterials.xlsx";
                    a.click();
                    return Promise.resolve('Ok');
                }
            })
            .catch(error => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error('Error al descargar reporte de materiales ', error);
                return Promise.reject();
            });
    }
}

export function importMaterialsPricesBySupplierExternalFile(xmlFile, supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_WINNER_SUPPLIER));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/external/bidding/${supplierCode}/criterias/template`;
        let formData = new FormData();
        xmlFile.forEach((file) => {
            formData.append('data', file);
        });
        return axios
            .post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_WINNER_SUPPLIER));
                console.error('Error import materials ', error.response);
                return Promise.reject()
            });
    };
}

export function addDivisaInBiddingFromBackEnd(id, divisa, supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_QUESTION));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/bidding/${id}/divisa/${divisa.value}`;
        let command = {
            BiddingId: id,
            DivisaCode: divisa ? divisa.value : "",
            RequestedBy: supplierCode
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setUpdateDivisa({
                        Code: divisa.value,
                        Name: divisa.label
                    }))
                    dispatch(isSavingCommon(false, ADD_QUESTION));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_QUESTION));
                console.error("Error in add divisa", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function addMaterialResponseBySupplierExternal(materialModel, supplierCode) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_EVALUATION_CRITERIA));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_BIDDING}/external/proposal/${supplierCode}/material/${materialModel.Id}`;
        let command = {
            PurchaseQuantity: materialModel.purchaseQuantity,
            PurchaseMeasurementUnit: materialModel.purchaseMeasurementUnit,
            PurchasePrice: materialModel.purchasePrice,
            PurchaseDivisaCode: materialModel.purchaseDivisa ? materialModel.purchaseDivisa.value : "",
            MaterialPrice: materialModel.materialPrice,
            MaterialDivisaCode: materialModel.materialDivisa ? materialModel.materialDivisa.value : "",
            IncotermCode: materialModel.incoterm ? materialModel.incoterm.value : "",
            Destination: materialModel.destination,
            PurchaseMinimumQuantity: materialModel.purchaseMinimumQuantity
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updatePropertyEvaluationCriteria(materialModel.ItemId, materialModel.criteriaId, "Response", materialModel.materialPrice))
                    dispatch(updatePropertyItem(materialModel.ItemId, "PurchasePrice", serverResponse.data.PurchasePrice))
                    dispatch(updatePropertyItem(materialModel.ItemId, "IncotermCode", serverResponse.data.IncotermCode))
                    dispatch(updatePropertyItem(materialModel.ItemId, "PurchaseQuantity", serverResponse.data.PurchaseQuantity))
                    dispatch(updatePropertyItem(materialModel.ItemId, "Destination", serverResponse.data.Destination))
                    dispatch(updatePropertyItem(materialModel.ItemId, "DivisaCode", serverResponse.data.DivisaCode))
                    dispatch(updatePropertyItem(materialModel.ItemId, "PurchaseDivisaCode", serverResponse.data.PurchaseDivisaCode))
                    dispatch(updatePropertyItem(materialModel.ItemId, "PurchaseMeasurementUnit", serverResponse.data.PurchaseMeasurementUnit))
                    dispatch(updatePropertyItem(materialModel.ItemId, "PurchaseMinimumQuantity", serverResponse.data.PurchaseMinimumQuantity))
                    dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_EVALUATION_CRITERIA));
                console.error("Error in add response by supplier", error.response)
                return Promise.reject(error.response)
            })
    }
}

//#endregion