import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { converListToArray } from '../../store/helpers/ListHelper'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import { Translate } from 'react-localize-redux'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'

import UploadAvatar from './UploadAvatar'

/** Import section actions */
import { loadUser } from '../../store/administration/InternalActions'
import { genereteStringGuid } from '../../store/helpers/StringHelper'
import ProfileUserCard from './components/user/ProfileUserCard'
import ProfileRolesCard from './components/user/ProfileRolesCard'

/**
 * Container to Profile user
 */
class Profile extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.uploadLogoFileRef = React.createRef()
		this.state = {
			showChangeAvatarDialog: false,
			avatarFileBase64: null,
			croppedFileBase64: null,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let userToken = this.props.match.params.token
		this.props.loadUser(userToken)
	}

	/**
	 * Upload the log file
	 *
	 * @memberof UserProfileHeader
	 */
	uploadLogoFile(file) {
		let self = this
		let fileUpload = file.target.files[0]
		/// Determine if the image to add not is undefined or null
		if (fileUpload !== undefined && fileUpload !== null) {
			var reader = new FileReader()
			reader.onload = function () {
				self.onSelecteFile(reader.result)
			}
			reader.onerror = function (error) {
				console.log('Error: ', error)
			}
			reader.readAsDataURL(fileUpload)
			self.uploadLogoFileRef.current.value = ''
		}
	}

	/**
	 * On select avatar user
	 *
	 * @param {*} fileBase64
	 * @memberof Profile
	 */
	onSelecteFile(fileBase64) {
		this.setState({
			avatarFileBase64: fileBase64,
			croppedFileBase64: fileBase64,
			showChangeAvatarDialog: true,
		})
	}

	/**
	 * Render Profile
	 */
	render() {
		const { internalUser, isLoadingUser, classes } = this.props
		if (isLoadingUser === true) {
			return <LoadingPage />
		}
		if (internalUser === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${internalUser.avatar_url}`
			let logoUrl = urlImage + '?token=' + genereteStringGuid()
			let logoAvatar = (
				<IconButton
					className={classes.avatar}
					onClick={() => {
						this.uploadLogoFileRef.current.click()
					}}
				>
					A
				</IconButton>
			)
			if (internalUser.avatar_url) {
				logoAvatar = (
					<Avatar
						alt='Remy Sharp'
						src={logoUrl}
						className={classes.avatar}
						onClick={() => {
							this.uploadLogoFileRef.current.click()
						}}
					/>
				)
			}

			return (
				<Grid container spacing={24}>
					<Grid item xs={6}>
						<Paper>
							<Card className={classes.card}>
								<CardHeader
									avatar={logoAvatar}
									title={internalUser.full_name}
									subheader={internalUser.user_name}
								/>
								<ProfileUserCard {...this.props} />
							</Card>
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<ProfileRolesCard {...this.props} {...this.state} />
					</Grid>

					<input
						className={classes.logoFileInput}
						type='file'
						accept='image/*'
						ref={this.uploadLogoFileRef}
						multiple={false}
						onChange={this.uploadLogoFile}
					/>

					{this.state.showChangeAvatarDialog ? (
						<UploadAvatar
							open={this.state.showChangeAvatarDialog}
							fileBase64={this.state.avatarFileBase64}
							onClose={() => {
								this.setState({ showChangeAvatarDialog: false })
							}}
						/>
					) : null}
				</Grid>
			)
		}
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		internalUser: state.internalUsers.get('internalUser') ? state.internalUsers.get('internalUser').toJS() : null,
		isLoadingUser: state.internalUsers.get('isLoadingUser'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadUser: (token) => {
			return dispatch(loadUser(token))
		},
	}
}

const viewStyles = (theme) => ({
	waitMessage: {
		marginTop: '15px',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: '100px',
		paddingRight: '150px',
	},
	userAvatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	rolesAvatar: {
		backgroundColor: theme.palette.primary.main,
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		marginLeft: 10,
		margin: 10,
		width: 60,
		height: 60,
	},
	logoFileInput: {
		display: 'none',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Profile)))
)
