/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, } from 'react-redux'
import moment from 'moment'

/**Import MaterialUi section */
import {Grid, Button, Avatar, Typography,TextField, ListSubheader,
    ListItemSecondaryAction } from '@material-ui/core'
import FileIcon from '@material-ui/icons/AttachFile';
import {
    ListItemAvatar, List, ListItemText,
    ListItem, IconButton
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import DatePicker from '../../common/DateTimePicker/DatePicker';
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';
import Evidence from '../../common/Evidence'
import Toaster from '../../common/Toaster';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import {SustainabilityActionStatus} from '../../../store/helpers/AppConstants'

import {
    updateActionPlanFromSupplier,
    addEvidenceInActionPlan,
    removeFileInActionPlan,
    updateActionPlanFromAuditor,
    downloadFiles,
} from '../../../store/sustainability2/SustainabilityActions'

const PanelAction = (props) => {
    /**props section */
    const {onCloseButtonClick,
        isSaving, classes,
        actionPlan,
        canUpdateSupplier,
        translate,
        canBeEditResponsible,
        listActions,
    } = props

    const [attachments, setAttachments] = useState(actionPlan.Attachments ? actionPlan.Attachments : [])
    const [itemModel, setItemModel] = useState(actionPlan);
    const canBeUpdateAudit = (canBeEditResponsible &&  (actionPlan.Status === SustainabilityActionStatus.OPEN || actionPlan.Status === SustainabilityActionStatus.PROCESS))
    const canUpdateSupplierAction =  (canUpdateSupplier &&  (actionPlan.Status === SustainabilityActionStatus.PENDIENT ||
        actionPlan.Status === SustainabilityActionStatus.OPEN || actionPlan.Status === SustainabilityActionStatus.PROCESS || actionPlan.Status === SustainabilityActionStatus.ACCEPTED))
    /**Render hooks section */
    const dispatch = useDispatch()

    const [checAproved, setCheckAprove] = useState(false);

    /**Store local this component */
    const [toasterMessageConfig, setToasterMessageConfig] = useState({
        showToaster: false,
        toasterMessage: null,
        toasterVariant: "",
        name: ""
    })

    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if(canUpdateSupplier){
            if (IsNullOrEmpty(itemModel.CommnetsSupplier))
                return false;
            if(IsNullOrEmpty(itemModel.CommitmentDate) || itemModel.CommitmentDate.length === 0)
                return false;
            else
                return true;
        }
        else if(canBeEditResponsible){
            if(IsNullOrEmpty(itemModel.ComplianceDate) || itemModel.ComplianceDate.length === 0)
                return false;
            else
                return true;
        }
        else
            return false;
    }

     /**
     * On upload file in maquila order in list name
     * @param {*} files 
     * @param {*} maquilaToken 
     * @param {*} listName 
     */
    const uploadFiles = (files) => {
        let sustainabilityId = props.match.params.token;
        let filesList = attachments.concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
        return dispatch(addEvidenceInActionPlan(sustainabilityId, itemModel.Id, files, listActions))
            .then(() => {
                setAttachments(filesList)
                return Promise.resolve('Ok');

            }).catch(() => {
                return Promise.reject();
            });
        }
        return Promise.resolve('Ok');
    }
    
    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
    */
    const removeFiles = (fileName) => {
        let sustainabilityId = props.match.params.token;
        let newList = attachments.filter(files => {
            return files.name !== fileName
        })
        return dispatch(removeFileInActionPlan(sustainabilityId, itemModel.Id, fileName, listActions))
            .then(() => {
                setAttachments(newList)
                return Promise.resolve('Ok');
            }).catch(() => {
                return Promise.reject();
            });
    }

    /**On change properties */
    const onChangeDate = (property, data) => {
        if(property === "CommitmentDate" || property === "ComplianceDate" || property === "ApprovalDate")
            data = data ? moment(data).format("DD/MM/YYYY") : ""
        setItemModel({
            ...itemModel,
            [property]: data
        });
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setItemModel({
            ...itemModel,
            [name]: value
        });
    }

      /**
     * Update action plan
     * @param {*} property 
     * @param {*} value 
     * @param {*} actionPlanId 
     * @returns 
     */
    const onSaveItemUpdate = () =>{
        let sustainabilityId = props.match.params.token;
        if(canUpdateSupplierAction){
            return dispatch(updateActionPlanFromSupplier(sustainabilityId, itemModel, listActions))
                .then(() => {
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterVariant: "success",
                        toasterMessage: "common.saveCorrectly",
                    })
                    onCloseButtonClick()
                })
                .catch(() => {
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterMessage: "common.errorToSave",
                        toasterVariant: "error",
                    })
                })
        }
        else{
            return dispatch(updateActionPlanFromAuditor(sustainabilityId, itemModel, listActions, checAproved))
                .then(() => {
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterVariant: "success",
                        toasterMessage: "common.saveCorrectly",
                    })
                    onCloseButtonClick()
                })
                .catch(() => {
                    setToasterMessageConfig({
                        showToaster: true,
                        toasterMessage: "common.errorToSave",
                        toasterVariant: "error",
                    })
                })
        }
    }

    const getfileDownload =(fileName, extension) =>{
        return dispatch(downloadFiles(itemModel.Id, fileName, extension))
        .then(() => {
            return Promise.resolve('Ok');
        }).catch(() => {
            return Promise.reject();
        });
    }

    const handleChange = (event) => {
        setCheckAprove(event.target.checked);
    };

    return (
        <>
            <LateralPanel
                onClose={onCloseButtonClick}
            >
                <PanelHeader
                    titlePanel={props.title}
                    onCloseButtonClick={onCloseButtonClick}
                    icon={<Avatar className={classes.avatar}><FileIcon /></Avatar>}
                    isIconMaterial={true}
                />
                <div className={classes.containerInformation}>
                    <Grid container >
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={translate('common.commentsActionPlant')}
                                className={classes.textField}
                                onChange={onPropertyChange}
                                multiline
                                name='CommnetsSupplier'
                                margin="normal"
                                variant="outlined"
                                value={itemModel ? itemModel.CommnetsSupplier : ''}
                                rows="2"
                                disabled={isSaving || !canUpdateSupplierAction}
                            />
                        </Grid>
                        {canUpdateSupplierAction && actionPlan.Status !== SustainabilityActionStatus.ACCEPTED && <Grid item xs={12}>
                            <Typography color='primary'>{translate('common.commitmentDateCloseDiscovery')}</Typography>
                            <DatePicker
                                onChangeRange={(event) => onChangeDate('CommitmentDate', event)}
                                startDate={itemModel.CommitmentDate ? moment(itemModel.CommitmentDate, 'DD/MM/YYYY') : ''}
                                minDate={moment()}
                            />
                        </Grid>}
                        {!canUpdateSupplierAction && <Grid item xs={12}>
                            <List className={classes.root}>
                                 <ListItem divider>
                                    <ListItemText secondary={translate('common.commitmentDateCloseDiscovery')} />
                                    <ListItemSecondaryAction>
                                        {itemModel.CommitmentDate && itemModel.CommitmentDate}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>}
                        <Grid item xs={12}>
                            <br/>
                            <Typography color='primary' className={classes.textTitle}>{translate('common.evidencesActionPlant')}</Typography>
                            {canUpdateSupplierAction && 
                             <div className={classes.alertPanel}>
                                <div className={classes.alert}>
                                    <Typography variant='caption'>
                                    <InfoIcon/>
                                    {translate('common.uploadFilesActionPlant')}
                                    </Typography>
                                </div>
                            </div>
                            }
                            {canUpdateSupplierAction && <Evidence
                                text={translate('common.selectfiles')}
                                uploadFiles={uploadFiles}
                                isDisabled={!canUpdateSupplierAction}
                                maxFiles={1}
                            />}
                            {attachments.length === 0 &&  <Typography className={classes.textTitle}><Translate id="common.noFiles" /></Typography>}
                             <List>
                                {
                                    attachments.map(((evidence, index) => {
                                        return (
                                            <ListItem key={index} divider>
                                                <ListItemAvatar>
                                                    <Avatar className={classes.avatar} size="small">
                                                        <FileIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={<Typography color="primary">{evidence.name}</Typography>}
                                                    style={{cursor: "pointer"}}  
                                                    onClick={()=>getfileDownload(evidence.name, evidence.ext)} 
                                                />
                                                {canUpdateSupplierAction && <ListItemSecondaryAction>
                                                    <IconButton edge="end" onClick={() => removeFiles(evidence.name)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>}
                                            </ListItem>
                                        )
                                    }))
                                }
                            </List>
                        </Grid>
                        {!canBeUpdateAudit && (actionPlan.Status !== SustainabilityActionStatus.PENDIENT) && 
                        <Grid item xs={12}>
                            <List className={classes.root}
                                subheader={
                                    <ListSubheader color='primary' component="div" id="nested-list-subheader">
                                        {"Información adicional"}
                                    </ListSubheader>
                                }>
                                <ListItem divider>
                                    <ListItemText secondary={translate('common.acceptActionPlant')}/>
                                    <ListItemSecondaryAction>
                                        {itemModel.ComplianceDate && itemModel.ComplianceDate}
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText secondary="Fecha de aceptación (Cierre de hallazgos):" />
                                    <ListItemSecondaryAction>
                                        {itemModel.ApprovalDate && itemModel.ApprovalDate}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>}
                        {canBeUpdateAudit && <Grid item xs={12}>
                            <br/>
                            <Typography className={classes.textTitle}>{translate('common.acceptActionPlant')}</Typography>
                            <DatePicker
                                onChangeRange={(event) => onChangeDate('ComplianceDate', event)}
                                startDate={itemModel.ComplianceDate ? moment(itemModel.ComplianceDate, 'DD/MM/YYYY') : ''}
                                minDate={moment()}
                                opens={"left"}
                                drops={"up"}
                            />
                            <br/>
                            <FormControlLabel
                                control={<Switch  checked={checAproved} 
                                    onChange={handleChange}
                                    name="checkedApproved"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }} 
                                />}
                                label={translate('common.acceptActionPlant')}
                                labelPlacement="start"
                            />
                        </Grid>}
                        {!canUpdateSupplierAction && <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={translate('common.commentsInPlant')}
                                className={classes.textField}
                                onChange={onPropertyChange}
                                multiline
                                name='CommnetsPlant'
                                margin="normal"
                                variant="outlined"
                                value={itemModel ? itemModel.CommnetsPlant : ''}
                                rows="2"
                                disabled={isSaving || !canBeUpdateAudit}
                            />
                        </Grid>}
                    </Grid>
                </div>
                {(canUpdateSupplierAction || canBeUpdateAudit) && <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSaveItemUpdate}
                            disabled={!canBeSaved() || isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>}

            </LateralPanel >

            <Toaster
                message={<Translate id={toasterMessageConfig.toasterMessage} />}
                open={toasterMessageConfig.showToaster}
                variant={toasterMessageConfig.toasterVariant}
                onClose={() => {
                    setToasterMessageConfig({
                        showToaster: false,
                        toasterMessage: "",
                        toasterVariant: "",
                    })
                }}
            />

        </>

    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    textTitle:{
        paddingLeft: "6px",
    },

});

PanelAction.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onDeleteAttachment: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onSaveItem: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onCloseButtonClick: propTypes.func,
    /**All attachments saved */
    uploadFilesFromSupplier: propTypes.func
}

PanelAction.defaultProps = {
    onDeleteAttachment: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveItem: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    uploadFilesFromSupplier: () =>
        console.warn('Callback [onValidateTemplate] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelAction))