import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/**Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core'

/** Import section actions */
import {
	CHANGE_DELIVERY_QUALIFICATIONS_PAGINATION,
	CHANGE_DELIVERY_QUALIFICATIONS_QUERY,
	CHANGE_DELIVERY_QUALIFICATIONS_SORT_CRITERIA,
	loadDeliveryQualifications,
} from '../../store/deliveryQualifications/deliveryQualificationsActions'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import { converListToArray } from '../../store/helpers/ListHelper'
import SearchInput from '../common/SearchInput/SearchInput'
import { FormatListNumbered } from '@material-ui/icons'

class Index extends Component {
	/**
	 * Create an instance for delivery qualifications for Imbera MX.
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	componentDidMount() {
		this.props.loadDeliveryQualifications(
			this.props.searchQuery,
			this.props.sortCriteria.by,
			this.props.sortCriteria.ascending,
			this.props.page,
			this.props.rowsPerPage
		)
	}
	componentDidUpdate(prevProps) {
		if (
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadDeliveryQualifications(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	getCounterMessage() {
		if (this.props.deliveryQualifications) {
			let totalDeliveryQualifications = this.props.deliveryQualifications.total_items
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{this.props.deliveryQualifications.items_by_page}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{totalDeliveryQualifications}</span>&nbsp;
					{this.props.translate('common.records')}&nbsp;
				</div>
			)
		}
	}

	onRenderCellItem(dataSource, item) {
		switch (dataSource) {
			case 'purchase_order': {
				return <span>{item.breach_details[0] != null ? item.breach_details[0].purchase_order : ''}</span>
			}
			case 'delivery_time': {
				return <span>{item.breach_details[0] != null ? item.breach_details[0].delivery_date : ''}</span>
			}
			case 'full_supplier': {
				return (
					<span>{`${item.breach_details[0] != null ? item.breach_details[0].supplier_code : ''} | ${
						item.breach_details[0] != null ? item.breach_details[0].supplier_name : ''
					}`}</span>
				)
			}
			case 'status': {
				return <span>{item != null ? item.status_translated : ''}</span>
			}
			default:
				return <span>{item[dataSource]}</span>
		}
	}

	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery, this.props.rowsPerPage)
	}

	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.deliveryQualifications === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.deliveryQualifications.items}
					configuration={TableConfiguration(IsCurrentActiveDirectoryUser(this.props.user))}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(deliveryQualification) => {
						this.props.history.push(`/deliveryQualifications/${deliveryQualification.token}`)
					}}
					isIndex
					totalRows={this.props.deliveryQualifications.total_items}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
					onRenderCellItem={this.onRenderCellItem}
				/>
			)
		}
	}

	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={<FormatListNumbered />}
					title={
						<Typography variant='button'>
							{<Translate id='deliveryQualifications.titleDeliveryQualifications' />}{' '}
						</Typography>
					}
					action={
						<div className={classes.actionsToolbar}>
							{IsCurrentActiveDirectoryUser(this.props.user) && (
								<SearchInput
									className={classes.searchInput}
									onChangeSearchValue={this.onChangeSearchValue}
									value={this.props.searchQuery}
								/>
							)}
						</div>
					}
					subheader={this.getCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>
			</Card>
		)
	}
}

function TableConfiguration(isUserInternal) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'foliated_date',
			isSortable: true,
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'status',
			isSortable: true,
		},

		{
			header: <Translate id='warehouseRawMaterial.purchaseOrder' />,
			dataSource: 'purchase_order',
		},
		{
			header: <Translate id='warehouseRawMaterial.dateMaterial' />,
			dataSource: 'delivery_time',
		},
		{
			header: <Translate id='deliveryQualifications.RequestBy' />,
			dataSource: 'created_by',
		},
	]
	if (isUserInternal) {
		columns.push({
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		})
	}
	return { columns }
}

function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		deliveryQualifications: state.deliveryQualifications.get('deliveryQualifications')
			? state.deliveryQualifications.get('deliveryQualifications').toJS()
			: null,
		deliveryQualificationsCount: state.deliveryQualifications.get('deliveryQualificationsCount'),
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isSaving: state.deliveryQualifications.get('isSaving'),
		isLoading: state.deliveryQualifications.get('isLoading'),

		sortCriteria: state.deliveryQualifications.get('sortCriteria')
			? state.deliveryQualifications.get('sortCriteria').toJS()
			: null,
		searchQuery: state.deliveryQualifications.get('searchQuery'),
		page: state.deliveryQualifications.get('page'),
		rowsPerPage: state.deliveryQualifications.get('rowsPerPage'),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		loadDeliveryQualifications: (query, sortBy, sortAscending, page, rowsPerPage) => {
			return dispatch(loadDeliveryQualifications(query, sortBy, sortAscending, page, rowsPerPage))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_DELIVERY_QUALIFICATIONS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_DELIVERY_QUALIFICATIONS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		changeSearchQuery: (query, rowsPerPage) => {
			dispatch({
				type: CHANGE_DELIVERY_QUALIFICATIONS_QUERY,
				query: query,
				rowsPerPage,
			})
		},
	}
}

const styles = (theme) => ({
	avatar: {},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
