import React from 'react'
import { Translate } from 'react-localize-redux'
/** Material-UI imports section */
import { Divider, CardContent, CardHeader, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/AddTwoTone'
import WarehouseIcon from '@material-ui/icons/DeveloperBoard'
import DeleteIcon from '@material-ui/icons/DeleteForever'

/** imports section */
import ThereIsNoInformation from '../../common/ThereIsNoInformation'
import MaterialsTable from './MaterialsTable'
import PopperListsComponent from '../../../biddings/components/PopperListsComponent'
import { TransportTendeStatus } from '../../../../store/helpers/AppConstants'

const DeliveryContent = (props) => {
	const {
		deliveries,
		setOpenMaterialPanel,
		classes,
		deal,
		setOpenDeliveryPanel,
		onDelete,
		onDeleteDelivery,
		transportTenderdetail,
	} = props
	const popperLists = (delivery) => {
		let options = []
		options.push({
			title: <Translate id={'carrierTenders.edit'} />,
			onClick: () =>
				setOpenDeliveryPanel({
					open: true,
					dealId: deal.Id,
					isEdit: true,
					delivery: delivery,
				}),
			icon: <EditIcon className={classes.deleteIcon} />,
			isIconMaterial: true,
			disabled: false,
		})
		options.push({
			title: <Translate id='common.delete' />,
			onClick: () => onDeleteDelivery(delivery),
			icon: <DeleteIcon className={classes.editcon} />,
			isIconMaterial: true,
			disabled: false,
		})
		return options
	}
	return (
		<CardContent>
			{deliveries && deliveries.length > 0 ? (
				deliveries.map((delivery) => {
					return (
						<CardHeader
							key={delivery.Id}
							title={
								<div className={classes.deliveryHeader}>
									<div className={classes.warehouseIcon}>
										<WarehouseIcon />
									</div>

									<div>
										<div className={classes.spaceBetween}>
											<Typography gutterBottom variant='caption' component='p'>
												<strong>
													<Translate id={'carrierTenders.deals.delivery'} />:
												</strong>{' '}
												{delivery.DeliveryNumber}
											</Typography>
											{(transportTenderdetail.Status === TransportTendeStatus.DRAFT ||
												transportTenderdetail.Status ===
													TransportTendeStatus.SENT_TO_CARRIER) && (
												<div>
													<PopperListsComponent
														title={<Translate id='common.options' />}
														listItems={popperLists(delivery)}
													/>
												</div>
											)}
										</div>

										<Divider className={classes.divider} />
										<div className={classes.spaceBetween}>
											<Typography gutterBottom variant='body2' component='p'>
												<strong>
													{' '}
													<Translate id={'carrierTenders.deals.billMaterials'} />{' '}
												</strong>{' '}
											</Typography>
											{(transportTenderdetail.Status === TransportTendeStatus.DRAFT ||
												transportTenderdetail.Status ===
													TransportTendeStatus.SENT_TO_CARRIER) && (
												<div className={classes.flexEnd}>
													<Typography gutterBottom variant='caption' component='p'>
														<Translate id={'carrierTenders.deals.materials'} />
													</Typography>
													<AddIcon
														onClick={() =>
															setOpenMaterialPanel({
																open: true,
																dealId: deal.Id,
																deliveryId: delivery.Id,
															})
														}
														className={classes.addIcon}
													/>
												</div>
											)}
										</div>
										<MaterialsTable
											data={delivery.Materials}
											deleteMaterial={(id) => onDelete(id)}
											transportTenderdetail={transportTenderdetail}
										/>
									</div>
								</div>
							}
						/>
					)
				})
			) : (
				<ThereIsNoInformation />
			)}
		</CardContent>
	)
}

export default DeliveryContent
