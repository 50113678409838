/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
/** Material-UI imports section */
import { withStyles, Grid, Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'

const DownloadAcknowledgmentFiles = (props) => {
	/**props section */
	const { classes, file } = props

	return (
		<Dialog maxWidth='lg' open={props.open} fullWidth onClose={props.onClose}>
			<Grid container>
				<Grid item xs={12} className={classes.root}>
					<object
						id='pass3'
						aria-label='this object pdf'
						data={file}
						type='application/pdf'
						width='100%'
						height='100%'
					></object>
				</Grid>
				<Grid item xs={12}>
					<DialogActions>
						<Button variant='outlined' color='primary' onClick={props.onClose}>
							<Translate id='common.close' />
						</Button>
					</DialogActions>
				</Grid>
			</Grid>
		</Dialog>
	)
}

const styles = (theme) => ({
	root: {
		width: '100%',
		maxHeight: `calc(100vh - ${265}px)`,
		minHeight: `calc(100vh - ${265}px)`,
	},
})
DownloadAcknowledgmentFiles.propTypes = {
	open: propTypes.bool.isRequired,
}

DownloadAcknowledgmentFiles.defaultProps = {
	open: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(DownloadAcknowledgmentFiles))
