import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
import FormCreateCard from './Components/FormCreateCard'
import FormNotesCard from './Components/FormNotesCard'

/** Translations imports section */
import { Translate, getTranslate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
/** Material-UI imports section */
import Grid from '@material-ui/core/Grid'

/** Import section actions */
import { createProspectData } from '../../store/prospects/ProspectAction'
import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers'
import { loadAllSupplierClasiffications } from '../../store/expedientSupplier/ExpedientSupplierAction'
import { converListToArray } from '../../store/helpers/ListHelper'
import {
	COMPANY_IDENTIFIER_PTMT,
	COMPANY_IDENTIFIER__METALLIC,
	COMPANY_IMBERA,
} from '../../store/helpers/ResourcesHelper'

/**
 * Container to Create prospect
 */
class Create extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			prospect: {
				attachments: [],
				prospectName: '',
				contactName: [],
				prospectType: '',
				contactEmail: '',
				notes: '',
				centerType: '',
				noticeOfPrivacyType: '',
			},
			email: {
				list: [],
				value: '',
			},
		}
	}

	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadAllSupplierClasiffications()
		}
	}
	/**
	 * Update the properties in state
	 *
	 * @param {*} user
	 * @param {*} value
	 * @memberof View
	 */
	updateProperty(property, value) {
		let prospectpdate = { ...this.state.prospect }
		prospectpdate[property] = value
		this.setState({
			prospect: prospectpdate,
		})
	}

	onChangePrivacyType() {
		let prospectpdate = { ...this.state.prospect }
		let value = prospectpdate.noticeOfPrivacyType['value']
		if (value === '1') return COMPANY_IDENTIFIER__METALLIC
		else if (value === '2') return COMPANY_IDENTIFIER_PTMT
		else if (value === '3') return COMPANY_IMBERA
		else return COMPANY_IMBERA
	}

	updateEmail(event) {
		const { key } = event
		let emails = { ...this.state.email }
		let prospectpdate = { ...this.state.prospect }
		emails['value'] = event.target.value
		prospectpdate['contactEmail'] = event.target.value

		if (key === 'Enter') {
			emails['list'].push(event.target.value)
			prospectpdate['contactEmail'] = emails['list']
			emails['value'] = ''
		}
		this.setState({
			email: emails,
			prospect: prospectpdate,
		})
	}

	deleteEmails(event) {
		let emails = { ...this.state.email }
		let prospectpdate = { ...this.state.prospect }
		emails['list'] = this.state.email.list.filter((item) => item !== event)
		prospectpdate['contactEmail'] = emails['list']
		this.setState({
			email: emails,
		})
	}
	/**
	 * Remove files
	 */
	removeFiles(fileName) {
		let attachments = this.state.prospect.attachments.filter((file) => {
			return file.name !== fileName
		})
		this.setState({
			prospect: {
				...this.state.prospect,
				attachments,
			},
		})
		return Promise.resolve('Ok')
	}

	/**
	 * Add file
	 * @param {*} attachments
	 */
	uploadFiles(files) {
		let filesList = this.state.prospect.attachments
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.push(files[index])
			}
		}
		this.setState({
			prospect: {
				...this.state.prospect,
				attachments: filesList,
			},
		})
		return Promise.resolve('Ok')
	}

	/**
	 * Action to can be  information
	 */
	canBeSaved() {
		if (this.props.isSaving) return false
		else {
			let prospect = this.state.prospect
			if (prospect.prospectName.length === 0) return false
			if (prospect.contactName.length === 0) return false
			if (prospect.prospectType.length === 0) return false
			if (prospect.contactEmail.length === 0) return false
			if (prospect.notes.length === 0) return false
		}
		return true
	}

	/**
	 * Function to create
	 * @param {*} worker
	 */
	onCreateProspectData() {
		this.props
			.createProspectData(this.state.prospect)
			.then(() => {
				this.setState(showSuccessToaster('common.addCorrectly'))
				this.props.history.push(`/prospects`)
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToAdd'))
			})
	}

	/**
	 * Render show
	 */
	render() {
		return (
			<Grid container spacing={24}>
				<Grid item xs={6}>
					<FormCreateCard
						typesProspects={this.props.supplierClassifications}
						updateProperty={this.updateProperty}
						onChangePrivacyType={this.onChangePrivacyType}
						prospect={this.state.prospect}
						onCreateContractData={this.onCreateProspectData}
						canBeSaved={this.canBeSaved()}
						uploadFiles={this.uploadFiles}
						removeFiles={this.removeFiles}
						dropzoneText={this.props.translate('dropzone.dropzoneBase')}
						{...this.props}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormNotesCard
						deleteEmails={this.deleteEmails}
						updateEmail={this.updateEmail}
						emails={this.state.prospect}
						prospect={this.state.prospect}
						emailsValue={this.state.email}
						updateProperty={this.updateProperty}
						{...this.props}
					/>
				</Grid>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Grid>
		)
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompanyIdentifier = state.profile.get('selectedCompany').get('get_company_identifier')
	let companyIdentifier = state.profile.get('selectedCompany')
	let supplierClassifications = state.expedientSuppliers.get('supplierClassifications')
	return {
		selectedCompanyIdentifier: selectedCompanyIdentifier ? selectedCompanyIdentifier : null,
		companyIdentifier: companyIdentifier ? companyIdentifier : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isSaving: state.prospects.get('isSavingPropect'),
		supplierClassifications: supplierClassifications ? converListToArray(supplierClassifications) : [],
		translate: getTranslate(state.localize),
		activeprivacyNotice: state.companies.get('activePrivacyNotice')
			? state.companies.get('activePrivacyNotice').toJS()
			: null,
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		createProspectData: (prospect) => {
			return dispatch(createProspectData(prospect))
		},

		loadAllSupplierClasiffications: () => {
			return dispatch(loadAllSupplierClasiffications())
		},
	}
}

const viewStyles = (theme) => ({
	userAvatar: {
		backgroundColor: theme.palette.secondary.main,
		color: 'white',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 240,
	},
	toolBar: {
		backgroundColor: 'red',
	},
	button: {
		margin: theme.spacing.unit,
	},
	errorMessage: {
		color: theme.palette.common.white,
	},
	formatCheck: {
		display: 'flex',
		marginLeft: theme.spacing.unit,
		width: 460,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create)))
)
