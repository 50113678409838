import React from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

/** Import component section */
import Dialog from '../../common/Dialog'
import Select from 'react-select'

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useState } from 'react'
import DialogActions from '../../common/DialogActions'
import { getSelectedValue } from '../../../store/helpers/SelectOptions'
const AddMaterialModal = (props) => {

	const {
		onClose,
		open,
		title,
		classes,
		item,
		codes,
		purchaseOrders,
		positions,
		setCode,
		setOrderBy,
		setPositionValue,
		addItem,
		value,
		onUpdateMaterial,
		supplier,
		isSaving,
	} = props
	const [keepAdding, setKeepAdding] = useState(false)
	const [values, setValues] = useState({
		part_number_code: '',
		purchase_order: '',
		position: '',
		status: '',
		quantity_of_material: '',
		lot: '',
		revision: '',
		invoice_or_remission: '',
	})

	//const handleCheck = () => {}
	const renderSelect = (key, translationId, options, id) => {
		options = formatSelectValues(options)
		return (
			<Grid item xs={12} className={classes.textField}>
				<Typography variant='caption' color={'textSecondary'}>
					<Translate id={translationId} />*
				</Typography>
				<Select
					value={getSelectedValue(options, String(value ? value[id] : ''))}
					options={options}
					onChange={(event) => updateProperty(key, event.value)}
					menuPortalTarget={document.parentElement}
				/>
			</Grid>
		)
	}

	const formatSelectValues = (optionsList) => {
		return optionsList.map((item) => {
			return { value: String(item), label: String(item) }
		})
	}
	const onKeyDown = (event) => {
		if (event.key === 'Enter') document.activeElement.blur()
	}

	const renderTextField = (
		textFieldId,
		textFieldTranslateId,
		isRequired = true,
		type = 'text',
		showHelper = false,
		idTexfield
	) => {
		let helperValue = ''
		let helperText = ''
		if (showHelper) {
			helperValue =
				textFieldId === 'status'
					? item.revision != null
						? item.revision
						: ''
					: item.total_quantity_material
						? item.totalQuantityConfirm
						: ''
			helperText = (
				<Translate
					id={
						textFieldId === 'status'
							? 'deliveryOrderModule.requestedRevision'
							: 'deliveryOrderModule.error.quantityCantExceedsPurchaseOrder'
					}
				/>
			)
		}

		return (
			<TextField
				id={textFieldId}
				label={<Translate id={textFieldTranslateId} />}
				defaultValue={value ? (value[idTexfield] ? value[idTexfield] : '') : ''}
				margin='dense'
				type={type}
				fullWidth
				required={isRequired}
				onBlur={(event) => updateProperty(textFieldId, event.target.value)}
				onChange={(event) => updateProperty(textFieldId, event.target.value)}
				onKeyDown={onKeyDown}
				helperText={
					item &&
					showHelper && (
						<React.Fragment>
							{helperText}
							{`: ${helperValue}`}
						</React.Fragment>
					)
				}
			/>
		)
	}

	const updateProperty = (property, data) => {
		setValues((prev) => ({ ...prev, [property]: data }))
		if (property === 'part_number_code') {
			setCode(data)
			setOrderBy('')
		} else if (property === 'purchase_order') {
			setOrderBy(data)
		} else if (property === 'position') {
			setPositionValue(data)
		}
	}
	const isValidToSave = () => {
		if (value === null) {
			if (
				!IsNullOrEmpty(values.part_number_code) &&
				!IsNullOrEmpty(values.purchase_order) &&
				!IsNullOrEmpty(values.position) &&
				!IsNullOrEmpty(values.status) &&
				!IsNullOrEmpty(values.quantity_of_material) &&
				Number(values.quantity_of_material) > 0
			)
				return true
		} else {
			return true
		}

		return false
	}
	const onAddItem = () => {
		if (value !== null) {
			const update = {
				part_number_code: values.part_number_code === '' ? value.code : values,
				purchase_order: values.purchase_order === '' ? value.purchase_order : values.purchase_order,
				position: values.position === '' ? value.position : values.position,
				status: values.status === '' ? value.revision_confirmation : values.status,
				quantity_of_material:
					values.quantity_of_material === '' ? value.quantity_of_material : values.quantity_of_material,
				lot: values.lot === '' ? value.lote : values.lot,
				revision: values.revision === '' ? value.invoice_or_remission : values.revision,
				invoice_or_remission:
					values.invoice_or_remission === '' ? value.invoice_or_remission : values.invoice_or_remission,
			}
			onUpdateMaterial(update, value.id)
			setKeepAdding(true)
		} else {
			addItem(values, supplier)
			setKeepAdding(true)
		}
		setValues({
			part_number_code: '',
			purchase_order: '',
			position: '',
			status: '',
			quantity_of_material: '',
			lot: '',
			revision: '',
			invoice_or_remission: '',
		})
	}
	return (
		<>
			{item && (
				<Dialog
					open={open}
					onClose={onClose}
					header={
						<Toolbar>
							<Typography variant='h6'>{<Translate id={title} />}</Typography>
						</Toolbar>
					}
					actions={
						<Grid container spacing={0}>
							{/* validar que los campos son requeridos  */}
							{isValidToSave() && (
								<Grid item xs={12} className={classes.checkBox}>
									<Typography variant='caption' color='error'>
										{<Translate id='common.requiredFields' />}
									</Typography>
								</Grid>
							)}

							{!item && (
								<Grid item xs={12} className={classes.checkBox}>
									<FormControlLabel
										control={
											<Checkbox
												checked={keepAdding}
												onChange={() => this.handleCheck()}
												value='checked'
												color='primary'
											/>
										}
										label={<Translate id='deliveryOrderModule.keepAddingMaterial' />}
									/>
								</Grid>
							)}
							<Grid item xs={12}>
								<DialogActions
									view={SHOW_EDIT_VIEW}
									classes={classes}
									onCancel={onClose}
									onClose={onClose}
									onSaveItem={onAddItem}
									isSaving={isSaving}
									canBeSaved={isValidToSave()}
								/>
							</Grid>
						</Grid>
					}
				>
					<Grid container className={classes.modal} justify='space-between'>
						{renderSelect('part_number_code', 'deliveryOrderModule.materialsTable.code', codes, 'code')}
						{renderSelect(
							'purchase_order',
							'deliveryOrderModule.materialsTable.purchaseOrder',
							purchaseOrders,
							'purchase_order'
						)}
						{renderSelect('position', 'deliveryOrderModule.materialsTable.position', positions, 'position')}
						<div className={classes.content}>
							<div>
								{renderTextField(
									'status',
									'deliveryOrderModule.materialsTable.version',
									true,
									'number',
									true,
									'revision_confirmation'
								)}
							</div>
							<div className={classes.margin}>
								{renderTextField(
									'quantity_of_material',
									'deliveryOrderModule.materialsTable.quantity',
									true,
									'number',
									true,
									'quantity_of_material'
								)}
							</div>
						</div>
						<div className={classes.content}>
							<div>
								{renderTextField(
									'invoice_or_remission',
									'deliveryOrderModule.materialsTable.invoice',
									false,
									'text',
									false,
									'invoice_or_remission'
								)}
							</div>
							{false && <div className={classes.margin}>
								{renderTextField(
									'lot',
									'deliveryOrderModule.materialsTable.lot',
									false,
									'text',
									false,
									'lot'
								)}
							</div>}
						</div>
					</Grid>
				</Dialog>
			)}
		</>
	)
}

const styles = (theme) => ({
	modal: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
		maxWidth: 500,
	},
	textField: {
		marginBottom: theme.spacing.unit * 2,
	},
	checkBox: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
		maxHeight: '25px',
	},
	content: {
		display: 'flex',
		justifyContent: 'space-between !importan',
	},
	margin: {
		marginLeft: theme.spacing.unit * 8,
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(AddMaterialModal))
