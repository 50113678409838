/**Import react section */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

/**Import material UI Section*/
import { withStyles } from '@material-ui/core'
import { Card, CardHeader, Typography, CardContent, Avatar, Fab } from '@material-ui/core'
import { Translate, getTranslate } from 'react-localize-redux'
import SustainabilityIcon from '@material-ui/icons/NaturePeople'
import MenuIcon from '@material-ui/icons/MenuOutlined'
import InfoIcon from '@material-ui/icons/Info'

import DialogOperation from './DialogOperation'

/**Import components section*/
import LoadingPage from '../../common/LoadingPage'
import StepperHeader from './StepperHeader'
import { getColorStatusQuestionnaire, descriptionStatus } from '../../../store/helpers/SustainabilityHelper'
import AddCommonItem from '../../common/AddCommonItem'
import {
	canBeEditSupplier,
	canBeResponsible,
	AvailableOperationsConfiguration,
	onTitleStatusDialog,
	onMessageStatusDialog,
} from '../../../store/helpers/SustainabilityHelper'
import { SustainabilityStatus } from '../../../store/helpers/AppConstants'
import DialogProgramAudit from './DialogProgramAudit'

/**Import resources section*/

/**Import actions section */
const DesktopView = (props) => {
	const { classes } = props
	/**String props section */
	/**Bools props section */
	const { isLoading, isSaving } = props
	/**Arrays props section */
	const { sustainability, totalWorkerDetail, userProfile, userRolesByCompany } = props
	/**Functions props section */
	const {
		updateProperty,
		updatePropertyDetail,
		updatePropertyQuestion,
		changeStatusFromBackEnd,
		updatePropertyActionPlan,
		programAuditFromBackEnd,
		downloadQuestionnarie,
		duplicateQuestionnarie,
	} = props
	const translate = useSelector((state) => getTranslate(state.localize))
	const [openDialogConfirm, setDialogConfirm] = useState({
		open: false,
		status: null,
		comments: '',
	})
	let canSendSupplier = canBeEditSupplier(sustainability, userRolesByCompany, userProfile)
	let canChangeResponsibleStatus = canBeResponsible(sustainability, userRolesByCompany, userProfile)
	const [openProgramAudit, setProgramAudit] = useState({
		open: false,
		status: null,
	})

	const [openDialogDuplicate, setDialogDuplicate] = useState({
		open: false,
		comments: '',
	})

	const [onDialogOperation, setDialogOperation] = useState(false)

	const onOpenConfirmDialog = (status) => {
		setDialogConfirm({ open: true, status: status })
	}

	const onClosedDialog = () => {
		setDialogConfirm({
			open: false,
			status: null,
			sustainabilityId: null,
			comments: '',
		})
	}

	const closeDialogOperation = () => {
		setDialogOperation(!onDialogOperation)
	}

	/**
	 * Function to change estatus from responsbles
	 * @param {*} comments
	 */
	const onChangeStatus = (comments) => {
		if (changeStatusFromBackEnd) {
			changeStatusFromBackEnd(openDialogConfirm.status, comments).then(() => {
				onClosedDialog()
			})
		} else {
			console.log('No exti opcion to change')
		}
	}

	/** Open dialog diplicate */
	const onOpenDialogDuplicate = () => {
		setDialogDuplicate({ open: true })
	}

	/** Close dialog duplicate */
	const onClosedDuplicate = () => {
		setDialogDuplicate({
			open: false,
			sustainabilityId: null,
			comments: '',
		})
	}

	/**
	 * function to call action duplicateQuestionnarie
	 * @param {*} comments
	 */
	const onDuplicateQuestionnarie = (comments) => {
		if (duplicateQuestionnarie) {
			duplicateQuestionnarie(comments).then(() => {
				onClosedDuplicate()
			})
		} else {
			console.log('No exti opcion to change')
		}
	}

	const onOpenProgramAudit = (status) => {
		setProgramAudit({ open: true, status: status })
	}

	const onClosedProgramAudit = () => {
		setProgramAudit({
			open: false,
			status: null,
			sustainabilityId: null,
			comments: '',
		})
	}

	/**
	 * Function to prigram audit from responsbles
	 * @param {*} model
	 */
	const onProgramAudit = (model) => {
		if (changeStatusFromBackEnd) {
			programAuditFromBackEnd(openProgramAudit.status, model).then(() => {
				onClosedProgramAudit()
			})
		} else {
			console.log('No exti opcion to change')
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	const getCardContent = () => {
		if (isLoading === true) {
			return <LoadingPage />
		} else if (sustainability === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<div>
					<StepperHeader
						userProfile={userProfile}
						userRolesByCompany={userRolesByCompany}
						sustainability={sustainability}
						updateProperty={updateProperty}
						updatePropertyDetail={updatePropertyDetail}
						updatePropertyQuestion={updatePropertyQuestion}
						translate={translate}
						updatePropertyActionPlan={updatePropertyActionPlan}
						isSaving={isSaving}
						totalWorkerDetail={totalWorkerDetail}
						canBeActiveResult={canChangeResponsibleStatus}
						downloadQuestionnarie={downloadQuestionnarie}
					/>
				</div>
			)
		}
	}

	return (
		<Card>
			<CardHeader
				title={
					<Typography variant='button'>
						{<Translate id='sustainability.sustainabilityQuestionnaire' />} |{' '}
						{sustainability && sustainability.FormatedFolio}
					</Typography>
				}
				subheader={
					<span>
						{sustainability && sustainability.FullSupplier} <br />
						<strong>{descriptionStatus(sustainability && sustainability.Status, translate)}</strong>
						{sustainability && sustainability.ScheduledAuditDate && (
							<span>
								<br />
								{'Fecha de auditoria:'} {sustainability.ScheduledAuditDate}
							</span>
						)}
					</span>
				}
				avatar={
					<Avatar
						style={{
							backgroundColor: getColorStatusQuestionnaire(sustainability && sustainability.Status),
						}}
					>
						<SustainabilityIcon />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						{sustainability && (
							<div className={classes.stickyDiv}>
								{canChangeResponsibleStatus &&
									sustainability.Status === SustainabilityStatus.AUDIT_IN_PROCESS && (
										<div className={classes.alertPanel}>
											<div className={classes.alert}>
												<Typography variant='caption' color='primary'>
													<InfoIcon />
													<Translate id='sustainability.auditCellphoneInProcessUser' />
													{sustainability.AuditName}
													<br />
													<Translate id='sustainability.auditCellphoneInProcessMessage' />
												</Typography>
											</div>
										</div>
									)}
								<ComponentChangeStatus
									onOpenChangeStatus={onOpenConfirmDialog}
									onOpenDialogDuplicate={onOpenDialogDuplicate}
									onOpenProgramAudit={onOpenProgramAudit}
									closeDialogOperation={closeDialogOperation}
									openDialogOperation={onDialogOperation}
									canbeUpdateResponsibleStatus={canChangeResponsibleStatus}
									canbeUpdateSupplier={canSendSupplier}
									status={sustainability ? sustainability.Status : null}
									configuration={AvailableOperationsConfiguration}
									classes={classes}
									canBeSendCloseFindings={sustainability.CanBeSendCloseFindings}
									canBeSendActionPlan={sustainability.CanBeSendActionPlan}
									canBeGeneratedActionsPlan={sustainability.CanBeGeneratedActionsPlan}
									canPublishResult={sustainability.CanPublishResult}
								/>
							</div>
						)}
					</div>
				}
			/>
			<CardContent>{getCardContent()}</CardContent>

			{/** Dialog to confim change status */}
			{openDialogConfirm.open && (
				<AddCommonItem
					title={<Translate id={onTitleStatusDialog(openDialogConfirm.status)} />}
					message={translate(onMessageStatusDialog(openDialogConfirm.status))}
					open={openDialogConfirm.open}
					onClose={onClosedDialog}
					onSaveItem={onChangeStatus}
					isSaving={isSaving}
					saveButtonTranslate={'common.confirm'}
					isMessage={openDialogConfirm.status !== SustainabilityStatus.CANCELLED ? true : false}
					isTextArea={openDialogConfirm.status === SustainabilityStatus.CANCELLED ? true : false}
				/>
			)}

			{/** Dialog to confirm duplicate questionnaire */}
			{openDialogDuplicate.open && (
				<AddCommonItem
					title={<Translate id='sustainability.status.duplicateFoli' />}
					message={<Translate id='sustainability.messageDuplicateFoli' />}
					open={openDialogDuplicate.open}
					onClose={onClosedDuplicate}
					onSaveItem={onDuplicateQuestionnarie}
					isSaving={isSaving}
					saveButtonTranslate={'common.confirm'}
					isMessage={false}
					isTextArea={true}
				/>
			)}

			{openProgramAudit.open && (
				<DialogProgramAudit
					open={openProgramAudit.open}
					onClose={onClosedProgramAudit}
					onSaveItem={onProgramAudit}
					isSaving={isSaving}
					saveButtonTranslate={'common.confirm'}
					isMessage={sustainability.Status === SustainabilityStatus.AUDIT_IN_PROCESS ? true : false}
				/>
			)}
		</Card>
	)
}

const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
	},
	stickyDiv: {
		top: 88,
		zIndex: '300',
	},
	margin: {
		margin: theme.spacing.unit,
	},
	extendedIcon: {
		marginRight: theme.spacing.unit,
	},
	alert: {
		color: '#0D3C61',
		backgroundColor: '#ccf2ff',
		borderRadius: '.5em',
		margin: '10px 0px',
		padding: '12px',
	},
	alertPanel: {
		margin: '20px',
		minWidth: '400px',
		maxWidth: '600px',
		textAlign: 'center',
	},
})

DesktopView.propTypes = {
	/**
	 * Determinate if is loading information from back end
	 * and see view skeleton
	 */
	isLoading: PropTypes.bool.isRequired,
}

DesktopView.defaultProps = {
	isLoading: false,
	sustainability: {},
}
////Se mandan llamar los otros componenete
///Revisar componentes de las preguntas para modificar
export default withRouter(withStyles(styles, { withTheme: true })(DesktopView))

/**
 * A component change status
 */
const ComponentChangeStatus = (props) => {
	const {
		onOpenChangeStatus,
		onOpenDialogDuplicate,
		onOpenProgramAudit,
		closeDialogOperation,
		openDialogOperation,
		canbeUpdateResponsibleStatus,
		canbeUpdateSupplier,
		status,
		configuration,
		classes,
		canBeSendCloseFindings,
		canBeSendActionPlan,
		canBeGeneratedActionsPlan,
		canPublishResult,
	} = props

	let options = configuration(
		canbeUpdateResponsibleStatus,
		canbeUpdateSupplier,
		status,
		onOpenChangeStatus,
		canBeSendCloseFindings,
		canBeSendActionPlan,
		canBeGeneratedActionsPlan,
		canPublishResult,
		onOpenDialogDuplicate,
		onOpenProgramAudit
	)

	return (
		<>
			{options.length > 1 && (
				<Fab
					variant='extended'
					size='small'
					color='primary'
					className={classes.margin}
					onClick={closeDialogOperation}
				>
					<MenuIcon className={classes.extendedIcon} />
					{<Translate id='common.AvailableOperations' />}
				</Fab>
			)}
			{options.length === 1 && (
				<Fab
					variant='extended'
					size='small'
					color='primary'
					className={classes.margin}
					onClick={() => onOpenChangeStatus(options[0].value)}
				>
					<MenuIcon className={classes.extendedIcon} />
					{<Translate id={onTitleStatusDialog(options[0].value)} />}
				</Fab>
			)}
			{openDialogOperation && (
				<DialogOperation open={openDialogOperation} onClose={closeDialogOperation} options={options} />
			)}
		</>
	)
}

ComponentChangeStatus.propTypes = {
	canbeUpdateResponsibleStatus: PropTypes.bool.isRequired,
	canbeUpdateSupplier: PropTypes.bool.isRequired,
	onOpenChangeStatus: PropTypes.func.isRequired,
	onOpenDialogDuplicate: PropTypes.func.isRequired,
	onOpenProgramAudit: PropTypes.func.isRequired,
}
