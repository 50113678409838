///Import react section
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import AddCommonItem from '../common/AddCommonItem'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Tooltip } from '@material-ui/core'

/** Material-UI imports Icons */
import FailureGuarrantee from '@material-ui/icons/ThumbDown'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'
import DialogAddGuarantee from '../common/DialogAddGuarantee'
import Toaster from '../common/Toaster'
import DialogEditFailureGuarantee from './components/DialogEditFailureGuarantee'

import {
	addFailureGuaranteeData,
	loadFailureGuaranteeFromBackEnd,
	getFailureGuaranteeCount,
	CHANGE_FAILURE_GUARANTEE_SORT_CRITERIA,
	CHANGE_FAILURE_GUARANTEE_SORT_QUERY,
	updateFailureGuaranteeData,
	onRemoveFailureGuaranteeFromBackEnd,
} from '../../store/failureGuarantee/failureGuaranteeAction'

/**
 * Customer guarantee view
 */
class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showAddCustomerGuaranteeDialog: false,
			openModalEditFailureGuarantee: false,
			editItem: null,
			openRemoveItem: null,
		}
		base = this.props.translate('moduleGuarantee.failureGuarantee.failure')
		spanish = this.props.translate('common.spanish')
		english = this.props.translate('common.english')
		portuguese = this.props.translate('common.portugues')
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadFailureGuaranteeFromBackEnd(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
			this.props.getFailureGuaranteeCount()
		}
	}

	/**
	 * Open modal for edit failure guarantee
	 */
	handleOpenFailureCustomerGuarantee(item) {
		this.setState({
			openModalEditFailureGuarantee: true,
			editItem: item,
		})
	}

	/**
	 *method add properties
	 *
	 * @param {*} userId
	 * @memberof Show
	 */
	updateProperty(property, value) {
		let updatedguarantee = { ...this.state.editItem }
		updatedguarantee[property] = value
		this.setState({
			editItem: updatedguarantee,
		})
	}

	/**
	 * onRemoveItem
	 */
	onRemoveItem(itemId) {
		this.setState({
			openRemoveItem: true,
			itemId: itemId,
		})
	}

	/**
	 *Get failure  guarantee counter
	 *
	 * @returns
	 * @memberof Index
	 */
	geCounterMessage() {
		if (this.props.failureGuarantee) {
			let totalFailureGuarantees = this.props.failureGuarantee.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalFailureGuarantees}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.failureGuaranteeCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'edit') {
			return (
				<Tooltip color='secondary' title={<Translate id='common.edit' />}>
					<EditIcon
						color='secondary'
						onClick={() => {
							this.handleOpenFailureCustomerGuarantee(item)
						}}
					/>
				</Tooltip>
			)
		} else if (dataSource === 'delete') {
			return (
				<Tooltip color='secondary' title={<Translate id='common.delete' />}>
					<DeleteIcon
						color='secondary'
						onClick={() => {
							this.onRemoveItem(item.token)
						}}
					/>
				</Tooltip>
			)
		}
	}

	/**
	 *componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadFailureGuaranteeFromBackEnd()
			this.props.getFailureGuaranteeCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery
		) {
			this.props.loadFailureGuaranteeFromBackEnd(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
		}
	}

	/**
	 * Handle state of any Dialog to show or close
	 *
	 * @param {*} dialogId
	 * @memberof customerGuarantee
	 */
	handleOpenCloseAddCustomerGuaranteeDialog(dialogId) {
		this.setState({ [dialogId]: !this.state[dialogId] })
	}

	/**
	 * Handle On save, call action and show toaster if done or not
	 *
	 * @param {*} name
	 * @returns
	 * @memberof NonConformity
	 */
	handleOnSaveAddFailureGuarantee(name) {
		return this.props
			.addFailureGuaranteeData(name)
			.then(() => {
				this.setState({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
				this.handleOpenCloseAddCustomerGuaranteeDialog('showAddCustomerGuaranteeDialog')
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
				return Promise.reject()
			})
	}

	/**
	 * call to action to update data guarantee customer
	 *
	 * @memberof FormView
	 */
	updateFailureGuarantee() {
		if (this.state.editItem !== null) {
			this.props
				.updateFailureGuaranteeData(this.state.editItem)
				.then((response) => {
					this.setState({
						openModalEditFailureGuarantee: false,
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: <Translate id='common.saveCorrectly' />,
					})
				})
				.catch(() => {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				})
		}
	}

	/**
	 * Function on remove warehouses
	 */
	onRemoveFailureGuarantee() {
		this.props
			.onRemoveFailureGuaranteeFromBackEnd(this.state.itemId)
			.then(() => {
				this.setState({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
					openRemoveItem: false,
					itemId: '',
				})
			})
			.catch((result) => {
				if (result.status === 400) {
					this.setState({
						showToaster: true,
						toasterMessage: result.data.Message,
						toasterVariant: 'error',
					})
				} else {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				}
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.failureGuarantee === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.failureGuarantee}
					configuration={TableConfiguration(spanish, english, portuguese)}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
				/>
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Create instance customer guarantee
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<FailureGuarrantee />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>
							<Fab
								size='small'
								color='primary'
								onClick={() =>
									this.handleOpenCloseAddCustomerGuaranteeDialog('showAddCustomerGuaranteeDialog')
								}
							>
								<AddtIcon />
							</Fab>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.failureGuarantee' />{' '}
						</Typography>
					}
					subheader={this.geCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to add non conformity*/}
				<DialogAddGuarantee
					classes={this.props.classes}
					onSave={this.handleOnSaveAddFailureGuarantee}
					onCloseCancel={this.handleOpenCloseAddCustomerGuaranteeDialog}
					showDialog={this.state.showAddCustomerGuaranteeDialog}
					title={<Translate id='moduleGuarantee.failureGuarantee.addFailureGuarantee' />}
					isSaving={this.props.isSaving}
				/>

				<DialogEditFailureGuarantee
					open={this.state.openModalEditFailureGuarantee}
					title={<Translate id='moduleGuarantee.failureGuarantee.updateGuaranteeFailure' />}
					failureGuarantee={this.state.editItem}
					updateFailureGuaranteeData={this.updateFailureGuarantee}
					updateProperty={this.updateProperty}
					onClose={() => {
						this.setState({ openModalEditFailureGuarantee: false, editItem: null })
					}}
					onCancel={() => {
						this.setState({ openModalEditFailureGuarantee: false, editItem: null })
					}}
					isSaving={this.props.isSaving}
				/>

				{/** Dialog to delete a failure guarantee */}
				<AddCommonItem
					title={<Translate id='moduleGuarantee.failureGuarantee.titleDeleteFailure' />}
					message={<Translate id='moduleGuarantee.failureGuarantee.descriptionFailure' />}
					open={this.state.openRemoveItem}
					onClose={() => {
						this.setState({ openRemoveItem: false })
					}}
					onSaveItem={this.onRemoveFailureGuarantee}
					isSaving={this.props.isSaving}
					isMessage={true}
				/>
				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		failureGuarantee: state.failureGuarantee.get('failureGuaranteeData')
			? state.failureGuarantee.get('failureGuaranteeData').toJS()
			: null,
		sortCriteria: state.failureGuarantee.get('sortCriteria')
			? state.failureGuarantee.get('sortCriteria').toJS()
			: null,
		searchQuery: state.failureGuarantee.get('searchQuery'),
		failureGuaranteeCount: state.failureGuarantee.get('failureGuaranteeCount'),
		translate: getTranslate(state.localize),
		isSaving: state.failureGuarantee.get('isSavingFailureGuarantee'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		addFailureGuaranteeData: (name) => {
			return dispatch(addFailureGuaranteeData(name))
		},
		loadFailureGuaranteeFromBackEnd: (query, orderBy, sortAscending) => {
			return dispatch(loadFailureGuaranteeFromBackEnd(query, orderBy, sortAscending))
		},
		getFailureGuaranteeCount: () => {
			dispatch(getFailureGuaranteeCount())
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_FAILURE_GUARANTEE_SORT_QUERY,
				query: query,
			})
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_FAILURE_GUARANTEE_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		updateFailureGuaranteeData: (data) => {
			return dispatch(updateFailureGuaranteeData(data))
		},
		onRemoveFailureGuaranteeFromBackEnd: (token) => {
			return dispatch(onRemoveFailureGuaranteeFromBackEnd(token))
		},
	}
}

let base
let spanish
let english
let portuguese
function TableConfiguration(spanish, english, portuguese) {
	return {
		columns: [
			{
				header: base + ' (' + spanish + ')',
				dataSource: 'name',
				isSortable: true,
			},
			{
				header: base + ' (' + english + ')',
				dataSource: 'name_english',
			},
			{
				header: base + ' (' + portuguese + ')',
				dataSource: 'name_portuguese',
			},
			{
				header: <Translate id='common.edit' />,
				dataSource: 'edit',
			},
			{
				header: <Translate id='common.delete' />,
				dataSource: 'delete',
			},
		],
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
