import axios from 'axios'
/** Helper imports section */
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper'
import { backgroundColorGuidelineStatus, descriptionGuidelineStatus } from '../helpers/SpecificationsHelper'

export const LOADING_SPECIFICATIONS = 'LOADING_SPECIFICATIONS'
export const SET_GUIDELINE_LIST = 'SET_GUIDELINE_LIST'
export const CHANGE_GUIDELINE_PAGINATION = 'CHANGE_GUIDELINE_PAGINATION'
export const CHANGE_PACKAGE_PAGINATION = 'CHANGE_PACKAGE_PAGINATION'
export const SET_GUIDELINE_INFO = 'SET_GUIDELINE_INFO'
export const SET_PACKAGE_LIST = 'SET_PACKAGE_LIST'
export const SET_PACKAGE_INFO = 'SET_PACKAGE_INFO'

function isloadingSpecifications(isLoading) {
	return {
		type: LOADING_SPECIFICATIONS,
		isLoading: isLoading,
	}
}

function setGuideLinesList(guidelines, totalItems) {
	return {
		type: SET_GUIDELINE_LIST,
		guidelines: guidelines,
		totalGuidelines: totalItems,
	}
}

function setPackagesList(packages, totalItems) {
	return {
		type: SET_PACKAGE_LIST,
		packages: packages,
		totalPackages: totalItems,
	}
}

function setGuidelinePagination(page, pageSize) {
	return {
		type: CHANGE_GUIDELINE_PAGINATION,
		page: page,
		pageSize: pageSize,
	}
}

function setPackagePagination(page, pageSize) {
	return {
		type: CHANGE_PACKAGE_PAGINATION,
		page: page,
		pageSize: pageSize,
	}
}

function setGuidelineInformation(data) {
	return {
		type: SET_GUIDELINE_INFO,
		guidelineData: data,
	}
}

function setPackageInformation(data) {
	return {
		type: SET_PACKAGE_INFO,
		packageData: data,
	}
}

export function loadGuidelines(query, pageNumber, pageSize) {
	return function (dispatch, getState) {
		dispatch(isloadingSpecifications(true))
		let data = {
			Query: query,
			Page: pageNumber + 1,
			RowsPerPage: pageSize,
		}
		let config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let url = `${process.env.REACT_APP_SIP_SUPPLIERS}/guidelines/query`

		return axios
			.post(url, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let mappedResponse = serverResponse.data.Data.items.map((item) => {
						item.color_status_table = backgroundColorGuidelineStatus(item.status)
						item.status_translate = descriptionGuidelineStatus(item.status)
						return item
					})
					dispatch(setGuideLinesList(mappedResponse, serverResponse.data.Data.total_items))
					dispatch(
						setGuidelinePagination(
							serverResponse.data.Data.current_page,
							serverResponse.data.Data.items_by_page
						)
					)
					dispatch(isloadingSpecifications(false))
				}
			})
			.catch((error) => {
				console.error('Error getting general guidelines from back', error.response)
				dispatch(isloadingSpecifications(false))
				return Promise.reject()
			})
	}
}

export function getGuidelinedetail(token) {
	return function (dispatch, getState) {
		dispatch(isloadingSpecifications(true))
		let config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let url = `${process.env.REACT_APP_SIP_SUPPLIERS}/guidelines/${token}`

		return axios
			.get(url, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setGuidelineInformation(serverResponse.data.Data))
					dispatch(isloadingSpecifications(false))
				}
			})
			.catch((error) => {
				console.error('Error getting guideline detail from back', error.response)
				dispatch(isloadingSpecifications(false))
				return Promise.reject()
			})
	}
}

export function createNewPackagingSpecificationDocument(documentName, user) {
	return function (dispatch, getState) {
		dispatch(isloadingSpecifications(true))
		let body = {
			BusinessUnitId: 4,
			UserName: user.userName,
			Email: user.email,
			Name: user.name,
			DocumentType: 15,
			DocumentName: documentName,
			SupplierCode: user.supplierCode,
		}
		let config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let url = `${process.env.REACT_APP_SIP_SUPPLIERS}/specifications/package`
		return axios
			.post(url, body, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isloadingSpecifications(false))
				}
			})
			.catch((error) => {
				console.error('Error to create document in SIT', error.response)
				dispatch(isloadingSpecifications(false))
				return Promise.reject()
			})
	}
}

export function loadPackageSpecifications(query, pageNumber, pageSize, supplierCode) {
	return function (dispatch, getState) {
		dispatch(isloadingSpecifications(true))
		let data = {
			Query: query,
			PageNumber: pageNumber + 1,
			PageSize: pageSize,
			SupplierCode: supplierCode,
			DocumentType: 15,
		}
		let config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let url = `${process.env.REACT_APP_SIP_SUPPLIERS}/specifications/package/query`

		return axios
			.post(url, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let mappedResponse = serverResponse.data.Data.items.map((item) => {
						item.color_status_table = backgroundColorGuidelineStatus(item.status)
						item.status_translate = descriptionGuidelineStatus(item.status)
						return item
					})
					dispatch(setPackagesList(mappedResponse, serverResponse.data.Data.total_items))
					dispatch(
						setPackagePagination(
							serverResponse.data.Data.current_page,
							serverResponse.data.Data.items_by_page
						)
					)
					dispatch(isloadingSpecifications(false))
				}
			})
			.catch((error) => {
				console.error('Error getting general guidelines from back', error.response)
				dispatch(isloadingSpecifications(false))
				return Promise.reject()
			})
	}
}

export function getPackageDetail(token) {
	return function (dispatch, getState) {
		dispatch(isloadingSpecifications(true))
		let config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let url = `${process.env.REACT_APP_SIP_SUPPLIERS}/guidelines/${token}`

		return axios
			.get(url, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setPackageInformation(serverResponse.data.Data))
					dispatch(isloadingSpecifications(false))
				}
			})
			.catch((error) => {
				console.error('Error getting package specification detail from back', error.response)
				dispatch(isloadingSpecifications(false))
				return Promise.reject()
			})
	}
}

export function addItemtoList(token, numberList) {
	return function (dispatch, getState) {
		dispatch(isloadingSpecifications(true))
		let config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let url = `${process.env.REACT_APP_SIP_SUPPLIERS}/specifications/package/add`
		let body = {
			Token: token,
			Numberlist: numberList,
		}
		return axios
			.post(url, body, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isloadingSpecifications(false))
				}
			})
			.catch((error) => {
				console.error('Error adding package specification item from back', error.response)
				dispatch(isloadingSpecifications(false))
				return Promise.reject()
			})
	}
}

export function deleteItemtoList(token, numberList) {
	return function (dispatch, getState) {
		dispatch(isloadingSpecifications(true))
		let config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let url = `${process.env.REACT_APP_SIP_SUPPLIERS}/specifications/package/${token}/${numberList}`
		return axios
			.delete(url, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isloadingSpecifications(false))
				}
			})
			.catch((error) => {
				console.error('Error deleting package specification item from back', error.response)
				dispatch(isloadingSpecifications(false))
				return Promise.reject()
			})
	}
}

export function updateItemtoList(token, numberList, value) {
	return function (dispatch, getState) {
		dispatch(isloadingSpecifications(true))
		let config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let url = `${process.env.REACT_APP_SIP_SUPPLIERS}/specifications/package/updatedescription`
		let body = {
			Token: token,
			NumberList: numberList,
			Description: value,
		}
		return axios
			.put(url, body, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isloadingSpecifications(false))
				}
			})
			.catch((error) => {
				console.error('Error updating package specification item from back', error.response)
				dispatch(isloadingSpecifications(false))
				return Promise.reject()
			})
	}
}

export function updateVisualAidtoList(token, numberList, value) {
	return function (dispatch, getState) {
		dispatch(isloadingSpecifications(true))
		let config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let url = `${process.env.REACT_APP_SIP_SUPPLIERS}/specifications/package/updatevisualaid`
		let body = new FormData()
		if (value.length > 0) {
			value.forEach((file) => {
				body.append('file', file)
			})
		} else body.set('VisualAid', '')
		body.set('Token', token)
		body.set('NumberList', numberList)
		return axios
			.put(url, body, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isloadingSpecifications(false))
				}
			})
			.catch((error) => {
				console.error('Error updating package specification item from back', error.response)
				dispatch(isloadingSpecifications(false))
				return Promise.reject()
			})
	}
}

export function submitAuthorize(token) {
	return function (dispatch, getState) {
		dispatch(isloadingSpecifications(true))
		let config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let url = `${process.env.REACT_APP_SIP_SUPPLIERS}/specifications/package/submitauthorize/${token}`
		return axios
			.post(url, '', config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isloadingSpecifications(false))
				}
			})
			.catch((error) => {
				console.error('Error to submit authorize package specification from back', error.response)
				dispatch(isloadingSpecifications(false))
				return Promise.reject()
			})
	}
}
