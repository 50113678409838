import { ACTIVE_DIRECTORY_USER, NON_ACTIVE_DIRECTORY_USER } from './ResourcesHelper'

/**
 * Get the header for an authorized request
 * @param {*} user
 */
export function getAuthorizedRequestConfigurationUser(user) {
	if (user) {
		let configuration = {
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${user.access_token}`,
			},
		}
		return configuration
	}
}

/**
 * Get the header for an authorized request
 * @param {*} user
 */
export function getAuthorizedRequestConfigurationUserFiles(user) {
	if (user) {
		let configuration = {
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${user.access_token}`,
				'Content-Type': 'multipart/form-data',
			},
		}
		return configuration
	}
}

/**
 * Get the header for an authorized request
 * @param {*} user
 */
export function getAuthorizedRequestConfigurationUserFilesAndData(user) {
	if (user) {
		let configuration = {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
				'Content-Type': 'x-www-form-urlencoded',
			},
		}
		return configuration
	}
}

/**
 * Get the header for an authorized request
 * and add parameters like object
 * this is used to send data to delete request
 * @export
 * @param {*} user
 * @param {*} params
 * @returns
 */
export function getAuthorizedRequestConfigurationUserParameters(user, data) {
	if (user) {
		let configuration = {
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${user.access_token}`,
			},
			data,
		}
		return configuration
	}
}

/**
 * Method to deternine if the current is active directory
 * @param {*} oidcUser
 */
export function IsCurrentActiveDirectoryUser(oidcUser) {
	if (oidcUser === undefined || oidcUser === null) return false
	if (oidcUser.profile.user_type === ACTIVE_DIRECTORY_USER) return true
	else return false
}

/**
 * Method to deternine if the current is not active directory
 * @param {*} oidcUser
 */
export function IsCurrentNotActiveDirectoryUser(oidcUser) {
	if (oidcUser === undefined || oidcUser === null) return false

	if (oidcUser.profile.user_type === NON_ACTIVE_DIRECTORY_USER) return true
	else return false
}

/**
 * Action to determine if the user supplier is administrator
 * @param {*} oidcUser
 */
export function IsUserSupplierAdministrator(oidcUser) {
	if (oidcUser === undefined || oidcUser === null) return false

	if (oidcUser.profile.user_type === ACTIVE_DIRECTORY_USER) return false

	let userAdmin = oidcUser.profile.company_code + '-admin'
	if (oidcUser.profile.preferred_username === userAdmin) return true
	else return false
}

/**
 * Is UserSupplierAdminExternal
 * @param {*} oidcUser
 */
export function IsUserSupplierAdminExternal(profileUser) {
	if (profileUser === undefined || profileUser === null) return false

	if (profileUser.user_type === ACTIVE_DIRECTORY_USER) return false

	let userAdmin = profileUser.company_code + '-admin'
	if (profileUser.username === userAdmin) return true
	else return false
}
