///Import react section
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch, } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import ShowDesktopViewInternalUser from './components/ShowDesktopViewInternalUser'
import ShowEdit from './ShowEdit'

/**Import actions section */
import { loadBillingFromBackEnd } from '../../store/billings/BillingsActions'
import { loadSupplierCode } from '../../store/suppliers/SupplierActions';
const Show = (props) => {
    /**Render hooks section */
    const dispatch = useDispatch()

    /**Redux store information section*/
    const isLoading = useSelector((state) => state.billings.get('isLoading'))
    const isSaving = useSelector((state) => state.billings.get('isSaving'))
    const identifier = useSelector((state) => state.billings.get('identifier'))
    const selectedCompany = useSelector((state) => state.profile.get('selectedCompany') ? state.profile.get('selectedCompany').toJS() : null)
    const supplier = useSelector((state) => state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null)
    const translate = useSelector((state) => getTranslate(state.localize))

    /**Store local this component */
    const [identifierPanelLateral, setIdentifierPanelLateral] = useState("")
    const [item, setItem] = useState(null)

    const [confirmPasswordConfigModal, setConfirmPasswordConfigModal] = useState({ opened: false, item: null, identifier: "" })

    const onConfirmPassword = (item, identifier) => {
        setConfirmPasswordConfigModal({ opened: !confirmPasswordConfigModal.opened, item: item, identifier: identifier });
    }


    /**Use effect section */
    useEffect(() => {
        dispatch(loadBillingFromBackEnd(props.match.params.token))
            .then(response => {
                dispatch(loadSupplierCode(response.supplier_code))
            })
    }, [dispatch, props.match.params.token])

    const onLateralPanelConfig = (identifier, item) => {
        setIdentifierPanelLateral(identifier);
        setItem(item)
    }

    return (
        <>
            {
                <ShowDesktopViewInternalUser
                    /**objects */
                    translate={translate}
                    selectedCompany={selectedCompany}
                    supplier={supplier}
                    /**String */
                    identifier={identifier}
                    /**Bools */
                    isLoading={isLoading}
                    isSaving={isSaving}
                    /**Arrays */

                    /**Functions */
                    onLateralPanelConfig={onLateralPanelConfig}
                    onConfirmPassword={onConfirmPassword}

                />
            }


            <ShowEdit
                identifierPanelLateral={identifierPanelLateral}
                setIdentifierPanelLateral={setIdentifierPanelLateral}
                onLateralPanelConfig={onLateralPanelConfig}
                item={item}
                onConfirmPassword={onConfirmPassword}
                confirmPasswordConfigModal={confirmPasswordConfigModal}

            />

        </>
    )
}

const styles = ((theme) => ({

}))

export default withauthenticatedLayout(withRouter((withStyles(styles, { withTheme: true })(Show))))