import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

/** Material-UI imports section */
import { Translate, getTranslate } from 'react-localize-redux'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import {
	Typography,
	AppBar,
	CardContent,
	Grid,
	List,
	ListItemIcon,
	Tooltip,
	TextField,
	Card,
	Divider,
	ListItem,
	ListItemText,
	Tabs,
	Tab,
} from '@material-ui/core'
import ShowPanelToAddFiles from './components/show/ShowPanelToAddFiles'
import LinkMaterial from '@material-ui/core/Link'
import ExpedientsIcon from '@material-ui/icons/FormatListNumbered'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import PersonIcon from '@material-ui/icons/Person'
import FileIcon from '@material-ui/icons/FileCopy'
import SuppliersIcon from '@material-ui/icons/LibraryBooks'
import CommentsIcon from '@material-ui/icons/FormatListBulleted'
import AcceptIcon from '@material-ui/icons/ThumbUp'
import RejectIcon from '@material-ui/icons/ThumbDown'
import RequestIcon from '@material-ui/icons/ExploreOff'
import WaitingIcon from '@material-ui/icons/Explore'
import DoesNotApplyIcon from '@material-ui/icons/FileCopy'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import AddIcon from '@material-ui/icons/Add'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'

/** Import component section */
import LoadingPage from '../common/LoadingPage'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import { converListToArray } from '../../store/helpers/ListHelper'
import DataTable from '../common/DataTable'
import Evidence from '../common/Evidence'
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow'
import FloatingButton from '../common/FloatingButton'
import Toaster from '../common/Toaster'
import DialogAvailableOperations from '../common/DialogAvailableOperations'
import OptionsMenu from '../common/OptionsMenu'
import AddCommonItem from '../common/AddCommonItem'
import NotificationsTab from './components/notifications/NotificationsTab'

import {
	loadExpedientBySupplierToken,
	uploadFilesSupplierExpedient,
	updatePropertyFileSupplier,
	sendExpedientBySupplier,
	updateStatusFileInPlant,
	updateStatusExpedient,
	saveDocument,
	updateConsentDataProcessingBySupplier,
	deleteExpedient,
} from '../../store/expedientSupplier/ExpedientSupplierAction'
import { loadSupplierCode } from '../../store/suppliers/SupplierActions'
import { searchUsersInRole } from '../../store/administration/InternalActions'

import { onKeyDown } from '../../store/helpers/EventsHelper'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import { showSuccessToaster, showErrorToaster } from '../../store/helpers/ToasterHelpers'
import { COLOR_ALTERNATE, BACKGROUND_COLOR_CALENDAR_BUTTON } from '../../store/helpers/StatusColorConstants'
import { canBeModuleExpedientSupplier, canBeModuleExpeditByCompany, canBeModuleExpedientViewer} from '../../store/helpers/RolesHelper'
import {
	DOCUMENT_WAITING,
	DOCUMENT_REQUEST,
	DOCUMENT_ADDED,
	DOCUMENT_REJECTED,
	DOCUMENT_ACEPTED,
	DOCUENT_DOESNOTAPPLY,
	COMPANY_IMBERA,
} from '../../store/helpers/ResourcesHelper'
import {
	ExpedientStatus_AnsweredSupplier,
	ExpedientStatus_ResendToSupplier,
	ExpedientStatus_CancelInPlant,
	ExpedientStatus_ClosedInPlant,
	DeleteDraft,
} from '../../store/helpers/DevelopmentHelper'
import { IsUserSupplierAdministrator } from '../../store/helpers/SessionHelper'
import { ROLE_SUPPLIER_FILE_MANAGER } from '../../store/helpers/RolesHelper'
import TemplatesExpedient from './TemplatesExpedient'
import {
	GetSupplierTypeDescription,
	GetClassifcationType,
	GetColorStatus,
	GettipeFile,
} from '../../store/helpers/ExpedientSupplier'
/**
 * Show ethic code
 */
class ShowExpedientBySupplier extends Component {
	/**
	 *
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAvailableOperationsDialog: false,
			acceptedQualityDialog: false,
			openResentExpedient: false,
			openCanceExpedient: false,
			showSupplierFiles: false,
			itemToken: '',
			activeTab: 0,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let expedientSupplierToken = this.props.match.params.token
		this.props.loadExpedientBySupplierToken(expedientSupplierToken).then((supplierExpedient) => {
			if (this.props.supplierExpedient) {
				this.props.searchUsersInRole(ROLE_SUPPLIER_FILE_MANAGER, this.props.supplierExpedient.supplier_code)
			}
		})
	}

	/**
	 * update consent check
	 * @param {*} value
	 */
	onUpdateConsent(value) {
		let supplierToken = this.props.match.params.token
		this.props.updateConsentDataProcessingBySupplier(supplierToken, value)
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.isLoadingExpedientSupplier !== this.props.isLoadingExpedientSupplier &&
			this.props.supplierExpedient !== null &&
			this.props.supplier === null
		) {
			/// Get information full from supplier Expedient
			let supplierExpedient = this.props.supplierExpedient
			this.props.loadSupplierCode(supplierExpedient.supplier_code)
		}
	}

	/**
	 * Method to change the active tab
	 */
	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	onSendRequestClick() {
		this.setState({
			showSupplierFiles: true,
		})
	}

	onClickSaveFiles(addDocument) {
		this.props
			.saveDocument(this.props.match.params.token, addDocument.value)
			.then(() => {
				this.setState({ showSupplierFiles: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	onCloseSendRequestDialog() {
		this.setState({
			showSupplierFiles: false,
		})
	}
	/**
	 * Action to set state to open of available operations dialog
	 * @memberof
	 */
	setOpenDialog = (openAvailable) => {
		this.setState({
			openAvailableOperationsDialog: openAvailable,
		})
	}

	/**
	 * Render item in list of expedient by supplier
	 *
	 * @param {*} idTranslation
	 * @param {*} value
	 * @param {*} icon
	 * @param {*} isLink
	 * @returns
	 * @memberof Show
	 */
	renderItemList(idTranslation, value, icon, isTreatment) {
		return (
			<React.Fragment>
				<ListItem key={idTranslation} divider>
					<ListItemIcon>
						<Tooltip title={<Translate id={idTranslation} />}>{icon}</Tooltip>
					</ListItemIcon>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									component='span'
									variant='body2'
									className={this.props.classes.inline}
									color='textPrimary'
								>
									<Translate id={idTranslation} />
								</Typography>
								{!isTreatment && (
									<span>
										{': '}
										{value}
									</span>
								)}
								{isTreatment && (
									<React.Fragment>
										{': '}
										{GetClassifcationType(value)}{' '}
									</React.Fragment>
								)}
							</React.Fragment>
						}
					/>
				</ListItem>
			</React.Fragment>
		)
	}

	/**
	 * Handle event upload files of devidence componet to add files to list
	 *
	 * @param {*} files
	 * @returns
	 * @memberof AddActionToListDialog
	 */
	uploadFiles(fileToken, files) {
		this.props.uploadFilesSupplierExpedient(fileToken, files)
		return Promise.resolve('Ok')
	}

	/**
	 * Remove files
	 */
	removeFiles(fileName) {
		return Promise.resolve('Ok')
	}

	/**
	 * onRenderCellItem
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		let expedientToken = this.props.match.params.token
		let pathTemplate = this.props.supplierExpedient && this.props.supplierExpedient.path_template
		let isCompanyRepare = this.props.supplierExpedient && this.props.supplierExpedient.is_company_repare
		let isCompanyPtm = this.props.supplierExpedient && this.props.supplierExpedient.is_company_ptm
		let isSupplierForeign = this.props.supplierExpedient && this.props.supplierExpedient.is_supplier_foreign
		let canbeSupplier =
			this.props.supplierExpedient.can_be_update_supplier &&
			(canBeModuleExpedientSupplier(this.props.userRolesByCompany) ||
				IsUserSupplierAdministrator(this.props.currentUser))
		let canUpdatePlant =
			this.props.supplierExpedient.can_be_update_in_plant &&
			canBeModuleExpeditByCompany(this.props.userRolesByCompany)
		let versionExpedient = this.props.supplierExpedient.version
		if (dataSource === 'logo') {
			return <SuppliersIcon style={{ color: item.color_status }} />
		}
		if (dataSource === 'description') {
			return (
				<Typography style={{ maxWidth: '300px' }} variant='caption'>
					{item.description}
					<br />
					{item.notes && (
						<Typography variant='caption' style={{ color: BACKGROUND_COLOR_CALENDAR_BUTTON }}>
							<strong>
								<Translate id='expedientSupplier.notes' />:
							</strong>{' '}
							{item.notes}
						</Typography>
					)}
					{versionExpedient === 1 && !canBeModuleExpedientViewer(this.props.userRolesByCompany) && (
						<TemplatesExpedient
							item={item}
							pathTemplate={pathTemplate}
							isCompanyRepare={isCompanyRepare}
							isCompanyPtm={isCompanyPtm}
							isSupplierForeign={isSupplierForeign}
						/>
					)}
					{versionExpedient === 2 && (
						<span>
							{item.attachments.map((file) => {
								return (
									<span key={file.name}>
										<LinkMaterial
											target='_blank'
											href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${file.relativePath}`}
										>
											{file.name}
										</LinkMaterial>
										<br />
									</span>
								)
							})}
						</span>
					)}
				</Typography>
			)
		}
		if (dataSource === 'file_expedient_status') {
			return GetColorStatus(item.file_expedient_status, item.color_status)
		}
		if (dataSource === 'document_name') {
			return (
				<React.Fragment>
					{canbeSupplier && item.can_be_add_file && (
						<div style={{ width: '280px' }}>
							{' '}
							<Evidence
								text={this.props.translate('dropzone.dropzoneBase')}
								baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
								removeFiles={(fileName) => this.removeFiles(item.token, fileName)}
								uploadFiles={(files) => this.uploadFiles(item.token, files)}
								files={[]}
								acceptedFiles={GettipeFile(item.file_type)}
								disabledLink
							/>
						</div>
					)}
					{!canBeModuleExpedientViewer(this.props.userRolesByCompany) ? (

						<LinkMaterial
						component={'a'}
						target='_blank'
						href={`${process.env.REACT_APP_SIP_URL_LOGOS_ADMINISTRATION}/Documents/ExpedientSupplier/${expedientToken}/${item.document_name}`}
						>
						{' '}
						{item.document_name}
					</LinkMaterial>
					):(<LinkMaterial
						>
						{' '}
						{item.document_name}
					</LinkMaterial>)}
				</React.Fragment>
			)
		}
		if (dataSource === 'observations_supplier') {
			return this.renderTextArea('observations_supplier', item, !canbeSupplier)
		}
		if (dataSource === 'observations_in_plant') {
			return this.renderTextArea('observations_in_plant', item, !canUpdatePlant)
		}
		if (dataSource === 'options') {
			return this.renderOptionsMenu(item)
		}
	}

	/**
	 * renderTextArea
	 * @param {*} key
	 * @param {*} item
	 * @param {*} isDisabled
	 */
	renderTextArea(key, item, isDisabled) {
		return (
			<React.Fragment>
				{!isDisabled && (
					<TextField
						fullWidth
						key={key}
						defaultValue={item[key] ? item[key] : ''}
						onBlur={(event) => {
							this.props.updatePropertyFileSupplier(item.token, key, event.target.value)
						}}
						onKeyDown={(event) => onKeyDown(event)}
						margin='normal'
						disabled={isDisabled}
						inputProps={{
							style: { fontSize: 12 },
						}}
					/>
				)}
				{isDisabled && (
					<Typography style={{ width: '120px' }} align='center' variant='caption'>
						{item[key] ? item[key] : ''}
					</Typography>
				)}
			</React.Fragment>
		)
	}
	/**
	 * Handle state to set token of item to show options menu list
	 *
	 * @param {*} itemToken
	 * @memberof ShowExpedientBySupplier
	 */
	handleClickOptionsMenu(itemToken) {
		this.setState({
			itemToken,
		})
	}

	/**
	 * Handle state to show or hide menu options list
	 *
	 * @memberof ShowExpedientBySupplier
	 */
	handleCloseOptionsMenu() {
		if (!this.state.openModalAuthorization) {
			this.setState((state) => ({
				itemToken: '',
			}))
		}
	}

	/**
	 * Change status docunet in on update status file
	 * @param {*} token
	 * @param {*} status
	 */
	onUpdateStatusFile(token, status) {
		this.props.updateStatusFileInPlant(token, status).then(() => {
			this.handleCloseOptionsMenu()
		})
	}

	/**
	 * Method to render OptionsMenu
	 *
	 * @param {*} item
	 * @returns
	 * @memberof AnnotationsRMI
	 */
	renderOptionsMenu(item) {
		var options = []
		if (
			!this.props.supplierExpedient.is_closed &&
			canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
			item.file_expedient_status === DOCUMENT_REQUEST
		) {
			/// File change status to DOCUMENT_WAITING
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUMENT_WAITING),
				tooltipTranslation: <Translate id='expedientSupplier.documentWaiting' />,
				menuItemIcon: <WaitingIcon color='primary' />,
			})
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUENT_DOESNOTAPPLY),
				tooltipTranslation: <Translate id='expedientSupplier.documentDoesNotApply' />,
				menuItemIcon: <DoesNotApplyIcon style={{ color: COLOR_ALTERNATE }} />,
			})
		}
		if (
			!this.props.supplierExpedient.is_closed &&
			canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
			item.file_expedient_status === DOCUMENT_WAITING
		) {
			///File change status to DOCUMENT_REQUEST
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUMENT_REQUEST),
				tooltipTranslation: <Translate id='expedientSupplier.documentRequest' />,
				menuItemIcon: <RequestIcon color='secondary' />,
			})
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUENT_DOESNOTAPPLY),
				tooltipTranslation: <Translate id='expedientSupplier.documentDoesNotApply' />,
				menuItemIcon: <DoesNotApplyIcon style={{ color: COLOR_ALTERNATE }} />,
			})
		}
		if (
			this.props.supplierExpedient.can_be_update_in_plant &&
			canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
			(item.file_expedient_status === DOCUMENT_ADDED ||
				item.file_expedient_status === DOCUMENT_REJECTED ||
				item.file_expedient_status === DOCUMENT_ACEPTED)
		) {
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUMENT_ACEPTED),
				tooltipTranslation: <Translate id='common.accept' />,
				menuItemIcon: <AcceptIcon color='secondary' className={this.props.classes.icon} />,
			})
			options.push({
				itemClick: () => this.onUpdateStatusFile(item.token, DOCUMENT_REJECTED),
				tooltipTranslation: <Translate id='common.reject' />,
				menuItemIcon: <RejectIcon color='error' className={this.props.classes.icon} />,
			})
		}
		return (
			<OptionsMenu
				itemToken={item.token}
				handleClickOptions={this.handleClickOptionsMenu}
				open={this.state.itemToken === item.token}
				handleClickAway={this.handleCloseOptionsMenu}
				options={options}
				xsSize
			/>
		)
	}

	/**
	 * onSendDocumentsBySupplier
	 */
	onSendDocumentsBySupplier() {
		this.props
			.sendExpedientBySupplier(this.props.match.params.token, ExpedientStatus_AnsweredSupplier)
			.then(() => {
				this.setState({ openAvailableOperationsDialog: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * onClickCloseReport
	 *
	 * @param {*} item
	 * @memberof Create
	 */
	onClickResendExpedientToSupplier() {
		this.setState({
			openResentExpedient: true,
			openAvailableOperationsDialog: false,
		})
	}

	/**
	 * onResendExpedientToSupplier
	 * @param {*} comments
	 */
	onResendExpedientToSupplier(comments) {
		this.props
			.updateStatusExpedient(this.props.match.params.token, ExpedientStatus_ResendToSupplier, comments)
			.then(() => {
				this.setState({ openResentExpedient: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * onClickCancelExpedient
	 */
	onClickCancelExpedient() {
		this.setState({
			openCanceExpedient: true,
			openAvailableOperationsDialog: false,
		})
	}

	/**
	 * oncancel expedient
	 * @param {*} comments
	 */
	onCancelExpedientByClient(comments) {
		this.props
			.updateStatusExpedient(this.props.match.params.token, ExpedientStatus_CancelInPlant, comments)
			.then(() => {
				this.setState({ openCanceExpedient: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * oncancel expedient
	 * @param {*} comments
	 */
	onDeleteExpedientByClient(comments) {
		this.props
			.deleteExpedient(this.props.match.params.token, DeleteDraft, comments)
			.then(() => {
				this.setState({ openCanceExpedient: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
				this.props.history.push(`/expedientsupplier`)
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * On close expedient by client
	 * @param {*} comments
	 */
	onCloseExpedientBySupplier() {
		this.props
			.updateStatusExpedient(this.props.match.params.token, ExpedientStatus_ClosedInPlant)
			.then(() => {
				this.setState({ openAvailableOperationsDialog: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * Function to donwload expedient documentations by supplier in ZIP
	 */
	downloadExpedientSupplierInZIP() {
		let expedientToken = this.props.match.params.token
		let urlPlot = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientsuppliers/expedienBySupplier/${expedientToken}/downloadZip`
		window.open(urlPlot, '_blank')
	}

	/**
	 * Get the card content
	 *
	 * @memberof Show
	 */
	getCardContent() {
		if (this.props.isLoadingExpedientSupplier === true) {
			return <LoadingPage />
		} else if (this.props.supplierExpedient !== null) {
			const { supplierExpedient, supplier, company } = this.props
			let availableOptions = this.props.supplierExpedient
				? this.props.supplierExpedient.get_available_operations
				: []
			let isDisabledObsPlant = supplierExpedient.status !== 50
			let allSuppliersInNotifications = this.props.usersByRole.find((role) => {
				return Object.keys(role)[0] === ROLE_SUPPLIER_FILE_MANAGER
			})
			let allSuppliersNotifications = allSuppliersInNotifications
				? allSuppliersInNotifications[ROLE_SUPPLIER_FILE_MANAGER]
				: []

			return (
				<Grid>
					{availableOptions && availableOptions.length !== 0 && !canBeModuleExpedientViewer(this.props.userRolesByCompany) && <FloatingButton open={this.setOpenDialog} />}
					<LayoutHeaderWorkFlow
						title={<Translate id='expedientSupplier.expedientTitle' />}
						workFlow={supplierExpedient}
						supplier={supplier}
						company={company}
					/>
					<List>
						<Divider />
						{this.renderItemList(
							'expedientSupplier.supplierType',
							GetSupplierTypeDescription(
								supplierExpedient.supplier_type,
								supplierExpedient.supplier_classification
							),
							<FileIcon />
						)}
						{supplierExpedient.classification_type !== 0 &&
							!supplierExpedient.is_company_ptm &&
							this.renderItemList(
								'expedientSupplier.treatment',
								supplierExpedient.classification_type,
								<ExpedientsIcon />,
								true
							)}
						{supplierExpedient.update_date &&
							this.renderItemList(
								'expedientSupplier.dateResponseSupplier',
								supplierExpedient.update_date,
								<CalendarIcon />
							)}
						{company.company_code === COMPANY_IMBERA &&
							supplierExpedient.is_enable_security_police &&
							supplierExpedient.status !== 49 &&
							!canBeModuleExpedientViewer(this.props.userRolesByCompany) && (
								<React.Fragment>
									<ListItem key={'PrivacyPolicyCheck'} divider>
										<FormControlLabel
											control={
												<Checkbox
													onChange={(event) => {
														this.onUpdateConsent(event.target.checked)
													}}
													color='primary'
													value='status'
													checked={supplierExpedient.consent_data_processing}
													disabled={
														canBeModuleExpeditByCompany(this.props.userRolesByCompany) ||
														supplierExpedient.status !== 50
													}
												/>
											}
											label={<Translate id='expedientSupplier.consentDataProcessing' />}
										/>
									</ListItem>
								</React.Fragment>
							)}
						{supplierExpedient.update_by &&
							this.renderItemList(
								'expedientSupplier.respondeBy',
								supplierExpedient.update_by,
								<PersonIcon />
							)}
						{supplierExpedient.closed_date &&
							this.renderItemList(
								'expedientSupplier.dateClose',
								supplierExpedient.closed_date,
								<CalendarIcon />
							)}
						{supplierExpedient.comments &&
							this.renderItemList(
								'expedientSupplier.messageCommnet',
								supplierExpedient.comments,
								<CommentsIcon />
							)}
						{company.company_code === COMPANY_IMBERA &&
							supplierExpedient.is_enable_security_police &&
							!supplierExpedient.consent_data_processing &&
							!canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
							supplierExpedient.status === 50 && (
								<div className='alert alert-light' role='alert' style={{ borderColor: '#FFC107' }}>
									<h6 className='alert-heading'>
										<ReportProblemOutlinedIcon style={{ color: '#FFC107' }} />
										&nbsp;&nbsp;
										<Translate id={'expedientSupplier.titleAlertConsentDataProcessing'} />
									</h6>
									<Translate id='expedientSupplier.messageAlecrtConsentDataProcessingFirst' />
									<br />
									<br />
									<Translate id='expedientSupplier.messageAlecrtConsentDataProcessingSecond' />
								</div>
							)}
						{supplierExpedient.is_closed && !canBeModuleExpedientViewer(this.props.userRolesByCompany) && (
							<ListItem key={'downloadExpedientZip'} divider>
								<ListItemText
									secondary={
										<Link
											color='primary'
											onClick={() => this.downloadExpedientSupplierInZIP()}
											to='#'
										>
											<DownloadIcon style={{ margin: '5px' }} />
											<Translate id='expedientSupplier.downloadExpedientZip' />
										</Link>
									}
								/>
							</ListItem>
						)}
						{/* status 50 send to supplier */}
						{!this.props.supplierExpedient.is_closed &&
							canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
							supplierExpedient.status === 50 && (
								<ListItem>
									<ListItemSecondaryAction>
										<Button
											variant='contained'
											color='primary'
											style={{ marginTop: '30px' }}
											onClick={() => this.onSendRequestClick()}
										>
											<AddIcon style={{ margin: '0px' }} />
										</Button>
									</ListItemSecondaryAction>
								</ListItem>
							)}
					</List>
					<br />
					<Grid>
						<AppBar position='static' color='default'>
							<Tabs
								indicatorColor='primary'
								textColor='primary'
								value={this.state.activeTab}
								onChange={this.onClickToActiveTab}
							>
								<Tab label={<Translate id='expedientSupplier.documents' />} />
								{!canBeModuleExpedientViewer(this.props.userRolesByCompany) &&(
									<Tab label={<Translate id='expedientSupplier.notifications' />} />
								)}
							</Tabs>
						</AppBar>
						{this.state.activeTab === 0 && (
							<div>
								{((company.company_code === COMPANY_IMBERA &&
									((!canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
										supplierExpedient.consent_data_processing &&
										supplierExpedient.is_enable_security_police) ||
										(!canBeModuleExpeditByCompany(this.props.userRolesByCompany) &&
											!supplierExpedient.is_enable_security_police) ||
										canBeModuleExpeditByCompany(this.props.userRolesByCompany) || 
										canBeModuleExpedientViewer(this.props.userRolesByCompany))) ||
									company.company_code !== COMPANY_IMBERA) && (
									<DataTable
										data={supplierExpedient.file_update_suppliers}
										configuration={TableFilesConfiguration(
											canBeModuleExpeditByCompany(this.props.userRolesByCompany),
											isDisabledObsPlant
										)}
										onRenderCellItem={this.onRenderCellItem}
									/>
								)}
							</div>
						)}
						{this.state.activeTab === 1 && (
							<div>
								<NotificationsTab
									supplier={supplier}
									supplierExpedient={supplierExpedient}
									usersByRole={allSuppliersNotifications}
								/>
							</div>
						)}
					</Grid>
				</Grid>
			)
		}
	}

	render() {
		return (
			<Card>
				<CardContent>{this.getCardContent()}</CardContent>

				<DialogAvailableOperations
					{...this.props}
					options={this.props.supplierExpedient ? this.props.supplierExpedient.get_available_operations : []}
					openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
					setOpenDialog={this.setOpenDialog}
					onClickToSendDocumentsBySupplier={this.onSendDocumentsBySupplier}
					isSaving={this.props.isSaving}
					onClickExpedientStatusResendToSupplier={this.onClickResendExpedientToSupplier}
					onClickCancelExpedient={this.onClickCancelExpedient}
					onCloseExpedientBySupplier={this.onCloseExpedientBySupplier}
					onDeleteDraft={this.onDeleteExpedientByClient}
					isMessageCustomDelete={'deleteExpedient'}
				/>
				{/** Dialog to resent report */}
				<AddCommonItem
					title={<Translate id='statusDescriptions.expedientStatusResendToSupplier' />}
					message={<Translate id='expedientSupplier.messageResentExpedientSupplier' />}
					open={this.state.openResentExpedient}
					onClose={() => {
						this.setState({ openResentExpedient: false })
					}}
					onSaveItem={this.onResendExpedientToSupplier}
					isSaving={this.props.isSaving}
					isTextArea={true}
				/>

				{/** Dialog to cancel report */}
				<AddCommonItem
					title={<Translate id='statusDescriptions.cancelFolio' />}
					message={<Translate id='expedientSupplier.messageCancelFolio' />}
					open={this.state.openCanceExpedient}
					onClose={() => {
						this.setState({ openCanceExpedient: false })
					}}
					onSaveItem={this.onCancelExpedientByClient}
					isSaving={this.props.isSaving}
					isTextArea={true}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>

				<ShowPanelToAddFiles
					show={this.state.showSupplierFiles}
					onCloseModal={this.onCloseSendRequestDialog}
					addFileOnExpedient={this.onClickSaveFiles}
					onClose={this.onCloseSendRequestDialog}
					isSaving={this.props.isSavingAddDocuments}
					documentsNotRequired={
						this.props.supplierExpedient ? this.props.supplierExpedient.documents_not_required : []
					}
				/>
			</Card>
		)
	}
}

function TableFilesConfiguration(showInPlant, isDisabledObsPlant) {
	let columns = [
		{
			header: '',
			dataSource: 'logo',
		},
		{
			header: <Translate id='expedientSupplier.documents' />,
			dataSource: 'description',
			padding: 1,
			maxWidth: '20vw',
		},
		{
			header: <Translate id='expedientSupplier.formatType' />,
			dataSource: 'file_type',
		},
		{
			header: <Translate id='expedientSupplier.attachDocument' />,
			dataSource: 'document_name',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'file_expedient_status',
		},
		{
			header: <Translate id='expedientSupplier.observationsSupplier' />,
			dataSource: 'observations_supplier',
		},
	]

	if (isDisabledObsPlant)
		columns.push({
			header: <Translate id='expedientSupplier.observationsInPlant' />,
			dataSource: 'observations_in_plant',
		})

	///Validation to show or hide this column
	if (showInPlant)
		columns.push({
			header: '',
			dataSource: 'options',
		})

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		currentUser: user,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingExpedientSupplier: state.expedientSuppliers.get('isLoadingExpedientSupplier'),
		supplierExpedient: state.expedientSuppliers.get('supplierExpedient')
			? state.expedientSuppliers.get('supplierExpedient').toJS()
			: null,
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize),
		company: selectedCompany ? selectedCompany.toJS() : null,
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		isSaving: state.expedientSuppliers.get('isSavingExpedientSupplier'),
		isSavingAddDocuments: state.expedientSuppliers.get('isSaving'),
		usersByRole: state.internalUsers.get('usersByRole')
			? converListToArray(state.internalUsers.get('usersByRole'))
			: [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadExpedientBySupplierToken: (expedientSupplierToken) => {
			return dispatch(loadExpedientBySupplierToken(expedientSupplierToken))
		},
		uploadFilesSupplierExpedient: (fileToken, files) => {
			return dispatch(uploadFilesSupplierExpedient(fileToken, files))
		},
		updatePropertyFileSupplier: (fileToken, key, value) => {
			return dispatch(updatePropertyFileSupplier(fileToken, key, value))
		},
		sendExpedientBySupplier: (token, status) => {
			return dispatch(sendExpedientBySupplier(token, status))
		},
		loadSupplierCode: (supplierCode) => {
			dispatch(loadSupplierCode(supplierCode))
		},
		updateStatusFileInPlant: (token, status) => {
			return dispatch(updateStatusFileInPlant(token, status))
		},
		updateStatusExpedient: (token, status, comments) => {
			return dispatch(updateStatusExpedient(token, status, comments))
		},
		deleteExpedient: (token, status, comments) => {
			return dispatch(deleteExpedient(token,status,comments))
		},
		searchUsersInRole: (roleNames, supplierCode) => {
			return dispatch(searchUsersInRole(roleNames, supplierCode))
		},
		saveDocument: (expedientToken, documentToken) => {
			return dispatch(saveDocument(expedientToken, documentToken))
		},
		updateConsentDataProcessingBySupplier: (supplierToken, consent) => {
			return dispatch(updateConsentDataProcessingBySupplier(supplierToken, consent))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		color: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	inline: {
		display: 'inline',
	},
	styleTab: {
		backgroundColor: '#fff',
	},
	grow: {
		flexGrow: 1,
	},
})

export default withauthenticatedLayout(
	withRouter(
		withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ShowExpedientBySupplier))
	)
)
