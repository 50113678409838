import React, { useState } from 'react';
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/**Import MaterialUi section */
import { Grid, Button, Avatar, Typography } from '@material-ui/core'
import AddNoticetIcon from '@material-ui/icons/AddTwoTone';
import DownloadIcon from '@material-ui/icons/CloudDownload';

/** section import components */
import Evidence from '../../common/Evidence'
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';

/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader';

import downloadTemplateRepare from '../../../resources/files/Formato_Actualizar_documentos_repare.xlsx';

const PanelMoldsImport = props => {
    /**props section */
    const {
        onCloseButtonClick,
        classes,
        onSaveItem
    } = props

    const [attachments, setAttachments] = useState([])
    
    /**
     * Function on update file
     */
    const onSaveItemUpdate = () => {
        if (props.onSaveItem) {
            onSaveItem()
        }
    }

    /**
    * On upload file in maquila order in list name
    * @param {*} files 
    * @param {*} maquilaToken 
    * @param {*} listName 
    */
    const uploadFiles = (files) => {
        let filesList = attachments.concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
            setAttachments(filesList)
        }
        return Promise.resolve('Ok');
    }

    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
    */
    const removeFiles = (fileName) => {
        let newList = attachments.filter(files => {
            return files.name !== fileName
        })
        setAttachments(newList)
           return Promise.resolve('Ok');
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}>
            <PanelHeader
                titlePanel={<Translate id="maquilaOrders.MoldsListImport" />}
                icon={<Avatar className={classes.avatar}><AddNoticetIcon /></Avatar>}
                onCloseButtonClick={onCloseButtonClick}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container>
                    <Typography color='primary'><Translate id="maquilaOrders.donwloadTemplateAndImportMolds"/></Typography>
                        <Grid item xs={12}>
                            <br/>
                            <Evidence
                                text='subir archivo a importar' 
                                files={attachments}
                                uploadFiles={uploadFiles}
                                removeFiles={removeFiles}
                                baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
                            />
                            <br/>
                            <Typography color='primary'><Translate id="maquilaOrders.donwloadTemplate"/></Typography>
                            <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <small className="text-muted"> 
                                <DownloadIcon fontSize="small" color="secondary" style={{ margin: '5px', marginBottom:'8px' }} /> 
                                <a href={downloadTemplateRepare}><Translate id="expedientSupplier.downloadTemplatedExpedient"/></a>
                            </small>
                        </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Grid container>
                    <Grid item xs={12}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            fullWidth
                            variant='outlined'
                            className={classes.saveButton}
                            onClick={onSaveItemUpdate}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </LateralPanel >
    );
};

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    saveButton: {
        bottom: 0
    }
});

PanelMoldsImport.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onDeleteAttachment: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onSaveItem: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onCloseButtonClick: propTypes.func,
    /**All attachments saved */
    /**
     * Validate if template is correctly
     */
    uploadFilesFromSupplier: propTypes.func
};

PanelMoldsImport.defaultProps = {
    onDeleteAttachment: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveItem: () =>
        console.warn('Callback [onSaveItem] no defined'),
    uploadFilesFromSupplier: () =>
        console.warn('Callback [onValidateTemplate] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelMoldsImport))