/**Import react section */
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { Translate } from 'react-localize-redux';
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';


/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import DoneAll from '@material-ui/icons/DoneAll';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import AddCommonItem from '../../common/AddCommonItem';

import DataTable from '../../common/DataTable';
import LoadingPage from '../../common/LoadingPage';
import { GetTypeQuote, GetMeasurementUnits } from '../../../store/helpers/SelectOptions';
import { COLOR_STATUS_ACEPTED } from '../../../store/helpers/StatusColorConstants';
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper';

import { markQuoteDetailItemAsBestOptionFromBackend } from '../../../store/memos/MemoActions';
import { canBeValidateModuleDevelopment } from '../../../store/helpers/RolesHelper';

class CostSummary extends Component {
    /**
     * Create an intance of memos
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openMarkItemAsBest: false,
            tokenDetail: "",
        }
    }

    /**
     * Mark Quote Detail Item As BestOption
     */
    onMarkQuoteDetailItemAsBestOptionFromBackend() {
        let memoToken = this.props.match.params.token;
        this.props.markQuoteDetailItemAsBestOptionFromBackend(memoToken, this.state.tokenDetail)
            .then(() => {
                this.setState({
                    openMarkItemAsBest: false
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            })

    }

    /**
    * On render cell item custom
    * @param {*} dataSource 
    * @param {*} item 
    */
    onRenderCellItem(dataSource, item) {
        const { classes } = this.props;
        let optionsCostType = GetTypeQuote();
        let optionsUnits = GetMeasurementUnits();
        if (dataSource === "supplier_name") {
            return (`${item.supplier_code} | ${item.supplier_name}`)
        }
        if (dataSource === "token") {
            let isValidtToMarkCost = canBeValidateModuleDevelopment(this.props.userRolesByCompany);
            if (item.can_user_mark_as_best_option === true && isValidtToMarkCost) {
                return (
                    <Tooltip title={<Translate id="memos.markCost" />} >
                        <IconButton color="primary"
                            data-cy="btn-edit"
                            className={classes.edit}
                            onClick={() => { this.onMarkItemAsBestOption(item.token) }} >
                            <DoneAll />
                        </IconButton>
                    </Tooltip>
                )
            }
            else {
                return ("-")
            }
        }
        if (dataSource === "total_cost") {
            return (`$${item.total_cost}`)
        }
        if (dataSource === "measurement_unit") {
            if (item.measurement_unit !== null && item.measurement_unit !== 0) {
                return (`${optionsUnits[item.measurement_unit].label}`)
            }
            else {
                return ("Sin unidad")
            }
        }
        if (dataSource === "cost_type") {
            if (item.cost_type !== null && item.cost_type !== "") {
                return (`${optionsCostType[item.cost_type].label}`)
            }
            else {
                return ("")
            }
        }
    }

    /**
     * Set Custom background color foreach action
     *
     * @memberof ActionsList
     */
    setCustomColorRow() {
        (this.props.memo && this.props.memo.details) &&
            this.props.memo.details.map((item) => {
                var customColor;
                item.quote_details.map((supplier) => {
                    if (supplier.is_best_option === true) {
                        customColor = COLOR_STATUS_ACEPTED;
                    }
                    else {
                        customColor = "";
                    }
                    return supplier.custom_color = customColor;
                });
                return customColor;
            });
    }

    /**
     * on mark item as best option
     */
    onMarkItemAsBestOption(tokenDetail) {
        this.setState({
            openMarkItemAsBest: true,
            tokenDetail: tokenDetail,
        })
    }

    /**
     * Method to filter details by supplier
     *
     * @returns
     * @memberof CostSummary
     */
    getFilteredDetails(data) {
        return data.details.filter(detail => {
            let detailQuote = detail.quote_details.filter(quoteDetail => {
                return quoteDetail.supplier_code === this.props.user.profile.company_code;
            })
            detail.quote_details = detailQuote;
            if (detail.quote_details.length > 0)
                return detail;
            else return '';
        })
    }

    /**
     * get cost summary
     *
     * @returns
     * @memberof CostSummary
     */
    getCostSummaryList() {
        const { memo } = this.props;
        this.setCustomColorRow();
        if (memo) {
            if (IsCurrentActiveDirectoryUser(this.props.user)) {
                return (this.props.memo.details.map((detail) => {
                    return (
                        <DataTable
                            key={detail.token}
                            title={<Typography variant="subtitle2" gutterBottom><strong>{detail.full_part_number.toUpperCase()}</strong></Typography>}
                            data={detail.quote_details}
                            configuration={TableCostSumaryConfiguration}
                            onRenderCellItem={this.onRenderCellItem}
                        />
                    )
                })
                )
            }
            else {
                return (this.getFilteredDetails(this.props.memo).map((detail) => {
                    return (
                        <DataTable
                            key={detail.token}
                            title={<Typography variant="subtitle2" gutterBottom><strong>{detail.full_part_number.toUpperCase()}</strong></Typography>}
                            data={detail.quote_details}
                            configuration={TableCostSumaryConfiguration}
                            onRenderCellItem={this.onRenderCellItem}
                        />
                    )
                }))
            }
        } else {
            return <LoadingPage />
        }

    }

    render() {
        return (
            <Paper>
                {this.getCostSummaryList()}

                <AddCommonItem
                    title={<Translate id="memos.markCost" />}
                    message={<Translate id="memos.markCostMessage" />}
                    open={this.state.openMarkItemAsBest}
                    onClose={() => { this.setState({ openMarkItemAsBest: false }) }}
                    onSaveItem={this.onMarkQuoteDetailItemAsBestOptionFromBackend}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                />

            </Paper>
        );
    }
}

const TableCostSumaryConfiguration = {
    columns: [{
        header: <Translate id="common.supplier" />,
        dataSource: 'supplier_name'
    }, {
        header: <Translate id="common.cost" />,
        dataSource: 'total_cost'
    }, {
        header: <Translate id="common.unitMeasure" />,
        dataSource: 'measurement_unit'
    }, {
        header: <Translate id="common.quoteType" />,
        dataSource: 'cost_type'
    }, {
        header: <Translate id="common.currency" />,
        dataSource: 'current'
    }, {
        header: "",
        dataSource: 'token'
    }]
}
/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let memo = state.memos.get("memoInformation");
    return {
        isLoadingMemo: state.memos.get('isLoadingMemo'),
        memo: memo ? memo.toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        markQuoteDetailItemAsBestOptionFromBackend: (token, tokenDetail) => {
            return dispatch(markQuoteDetailItemAsBestOptionFromBackend(token, tokenDetail));
        }
    }
}

export default ((connect(mapStateToProps, mapDispatchToProps)(CostSummary)));