import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Autosuggest from 'react-autosuggest'
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from './layouts/withauthenticatedLayout'

/** Material-UI imports section */
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import BusinessIcon from '@material-ui/icons/BusinessTwoTone'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
/** Language imports **/
import '../resources/styles/autosuggest.css'
import '../resources/styles/dropzone.css'
import { searchBySuppliersByQury } from '../store/suppliers/SupplierActions'

import NoticeCarousel from './home/NoticeCarousel'
import PendingTasks from './home/PendingTasks'
import moment from 'moment'

/**
 * MainInternalUserView view
 *
 * @class MainInternalUserView
 * @extends {Component}
 */
class MainInternalUserView extends Component {
	/**
	 * Create an instance of Internal user
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			value: '',
			tokenSupplier: '',
			suggestions: [],
			activeTab: 0,
		}
	}

	/**
	 * Update component state for suggestions on add authorizer to document.
	 * @param users
	 */
	componentWillReceiveProps(suppliers = this.props.suppliers) {
		if (suppliers.suppliers !== null) {
			let suggestions = suppliers.suppliers
			this.setState({ suggestions: suggestions })
		}
	}

	/**
	 * Funtion on change supplier to serch
	 */
	onChangeSerchSupplier = (event, { newValue }) => {
		this.setState({
			value: newValue,
		})
	}

	/**
	 * This method looks for the coicidences of an account when giving Enter
	 *
	 * @param {*} event
	 * @memberof Index
	 */
	onEnterViewProfile(event) {
		if (event.key === 'Enter') {
			if (this.state.tokenSupplier) {
				this.props.history.push(`/suppliers/${this.state.tokenSupplier}`)
			}
		}
	}

	/**
	 * Method to change the active tab
	 */
	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	// Autosuggest will call this function every time you need to update suggestions.
	// You already implemented this logic above, so just use it.
	onSuggestionsFetchRequested = ({ value }) => {
		if (value.trim().length > 2) {
			this.props.searchBySuppliersByQury(value)
		}
	}

	// Autosuggest will call this function every time you need to clear suggestions.
	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
			tokenSupplier: '',
		})
	}

	/**
	 *  When suggestion is clicked, Autosuggest needs to populate the input
	 * based on the clicked suggestion. Teach Autosuggest how to calculate the
	 * input value for every given suggestion.
	 * @param {*} suggestion
	 */
	getSuggestionValue(suggestion) {
		this.setState({
			tokenSupplier: suggestion.token,
		})
		return suggestion.full_name
	}

	/**
	 * Option in render sugestion
	 * @param {*} suggestion
	 */
	onRenderSuggestions(suggestion) {
		const { classes } = this.props
		return (
			<div>
				<List className={classes.listStyle} dense>
					<ListItem
						onClick={(supplier) => {
							this.props.history.push(`/suppliers/${suggestion.token}`)
						}}
					>
						<Avatar className={classes.logo}>
							<BusinessIcon />
						</Avatar>
						<ListItemText primary={suggestion.full_name} />
					</ListItem>
				</List>
			</div>
		)
	}

	/**
	 * function to render favorites
	 *
	 * @returns
	 * @memberof MainInternalUserView
	 */
	onRenderFavorites() {
		const { classes } = this.props
		return (
			<div>
				<List className={classes.listStyle} dense>
					{this.props.suppliers &&
						this.props.suppliers.map((supplier) => {
							return (
								<ListItem
									divider
									button
									onClick={(supplier) => {
										this.props.history.push(`/suppliers/${supplier.token}`)
									}}
								>
									<Avatar className={classes.logo}>
										<BusinessIcon />
									</Avatar>
									<ListItemText primary={supplier.full_name} />
								</ListItem>
							)
						})}
					{!this.props.suppliers && (
						<ListItem>
							<ListItemText primary={'Sin Favoritos'} />
						</ListItem>
					)}
				</List>
			</div>
		)
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof AnonymousView
	 */
	render() {
		const { value, suggestions } = this.state
		const { classes } = this.props

		// Autosuggest will pass through all these props to the input.
		const inputProps = {
			placeholder: 'Buscar proveedor',
			value,
			onChange: this.onChangeSerchSupplier,
			onKeyDown: this.onEnterViewProfile,
		}

		let locale = this.props.languages
			? this.props.languages.find((language) => {
					return language.active
			  })['code']
			: 'es'
		moment.locale(locale)

		return (
			<Grid container spacing={24}>
				<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
					<Paper className={classes.paper}>
						<Autosuggest
							suggestions={suggestions}
							onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
							onSuggestionsClearRequested={this.onSuggestionsClearRequested}
							getSuggestionValue={this.getSuggestionValue}
							renderSuggestion={this.onRenderSuggestions}
							inputProps={inputProps}
						/>
						<Grid container spacing={24}>
							<Grid item xs={12}>
								{/* <Tabs
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    className={classes.tabs}
                                    value={this.state.activeTab}
                                    onChange={this.onClickToActiveTab}>
                                    <Tab
                                        color="primary"
                                        icon={<Tooltip title={<Translate id="common.notifications" />}><NotificationsIcon /></Tooltip>}
                                        className={classes.tabs}
                                    />
                                    <Tab
                                        icon={<Tooltip title={<Translate id="common.favorites" />}><FavoritesIcon /></Tooltip>}
                                        className={classes.tabs}
                                    />
                                </Tabs>
                                {this.state.activeTab === 0 &&
                                    <PendingTasks {...this.props} />
                                }
                                {this.state.activeTab === 1 &&
                                    this.props.suppliers && this.onRenderFavorites()
                                } */}
								<PendingTasks {...this.props} />
							</Grid>
						</Grid>
						{/* <PendingTasks {...this.props} /> */}
					</Paper>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
					<Paper className={classes.paper}>
						<NoticeCarousel {...this.props} />
					</Paper>
				</Grid>
			</Grid>
		)
	}
}

function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '' } }
	return {
		user,
		isLoadingSuppliers: state.suppliers.get('isLoadingSuppliers'),
		suppliers: state.suppliers.get('suppliers') ? state.suppliers.get('suppliers').toJS() : null,
		noticesInitialView: state.officialNotices.get('noticesInitialView')
			? state.officialNotices.get('noticesInitialView').toJS()
			: null,
		isLoadingNotifications: state.notifications.get('isLoadingNotifications'),
		notifications: state.notifications.get('notifications')
			? state.notifications.get('notifications').toJS()
			: null,
		languages: state.localize.languages,
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		searchBySuppliersByQury: (query) => {
			dispatch(searchBySuppliersByQury(query))
		},
	}
}

const viewStyles = (theme) => ({
	card: {
		margin: '5px',
	},
	cardContent: {
		backgroundColor: 'white',
	},
	profileOptionTitle: {
		fontWeight: 'lighter',
	},
	padingOption: {
		paddingLeft: 30,
	},
	paper: {
		height: '85vh',
		overflow: 'hidden',
	},
	listStyle: {
		top: -10,
		height: 30,
		margin: 5,
	},
	logo: {
		width: 30,
		height: 30,
	},
	linkStyle: {
		textDecoration: 'none',
		color: theme.palette.text.secondary,
	},
	tabs: {
		maxHeight: 25,
		minHeight: 20,
	},
})

export default withauthenticatedLayout(
	withRouter(
		withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(MainInternalUserView))
	)
)
