export const RAW_MATERIAL_NON_CONFORMITY_NOTIFICATION = 'RAWMATERIALNONCONFORMITYNOTIFICATION'
export const DEVIATION_AUTHORIZATION = 'DEVIATIONAUTHORIZATION'
export const RAWMATERIAL_CORRECTIVEACTIONS_IMPROVEMENTREQUEST = 'RAWMATERIALCORRECTIVEACTIONSIMPROVEMENTREQUEST'
/** FAILURE REPORT */
export const FAILURE_REPORT_DOCUMENT_NOTIFICATION = 'FAILUREREPORTQUALITYNOTIFICATION'
export const FAILURE_REPORT_CORRECTIVE_ACTIONS_IMPROVEMENT_REQUEST = 'FAILUREREPORTCORRECTIVEACTIONSIMPROVEMENTREQUEST'

/** Non conformity */
export const NON_CONFORMITY_DOCUMENT_NOTIFICATION = 'RAWMATERIALNONCONFORMITYNOTIFICATION'
export const NON_CONFORMITY_DOCUMENT_DEVOLUTION_RAW_MATERIA = 'NONCONFORMITYDOCUMENTDEVOLUTIONRAWMATERIA'
export const NON_CONFORMITY_DOCUMENT_REQUEST_REWORK = 'NONCONFORMITYDOCUMENT_REQUEST_REWORK'

export const WARRANTY_REPORT_PTM_NOTIFICATION = 'WARRANTY_REPORT_PTM_NOTIFICATION'

/** rework */
export const NONCONFORMITYDOCUMENT_REQUEST_REWORK = 'NONCONFORMITYDOCUMENT_REQUEST_REWORK'
export const REWORKREPORT = 'REWORKREPORT'

/** SAC Quality */
export const CORRECTIVE_ACTION_NOTIFICATION = 'CORRECTIVEACTIONNOTIFICATION'
export const CORRECTIVE_ACTION_RESPONSE = 'CORRECTIVEACTIONRESPONSE'

/** Workflows document type */
export const WORKFLOW_CANCELLATION = 'WORKFLOW_CANCELLATION'
export const WORKFLOW_REMINDER = 'WORKFLOW_REMINDER'

/* RMI document type*/
export const RMI_DOCUMENT = 'RMIDOCUMENT'
export const RMI_RESPONSE = 'RMIRESPONSE'
export const RMI_RECEPTION = 'RMIRECEPTION'
export const RMI_REQUEST = 'RMIREQUEST'
export const RMI_REQUEST_MODITY = 'RMIRQUESTMODIFY'
export const WORKFLOWREACTIVE = 'WORKFLOWREACTIVE'
export const SAMPLEREQUESTDECLINATION = 'SAMPLEREQUESTDECLINATION'

/** SAC Development */
export const CORRECTIVE_ACTION_DEVELOPMENT_INVESTIGATION_LINES = 'CORRECTIVEACTIONDEVELOPMET_LINEINVESTIGATION'
export const CORRECTIVE_ACTION_DEVELOPMENT_CONTAINMENT_ACTIONS = 'CORRECNTIVEACTIONDEVELOPMET_CONTAINMENTACTION'
export const CORRECTIVE_ACTION_DEVELOPMENT_NON_RECURRING_ACTIONS = 'CORRECNTIVEACTIONDEVELOPMET_NONRECURRING_ACTION'
export const CORRECTIVE_ACTION_DEVELOPMENT_REQUEST_MODIFY = 'CORRECNTIVEACTIONDEVELOPMET_REQUEST_MODIFY'
export const CORRECTIVE_ACTION_DEVELOPMENT_REQUEST_MODIFY_BY_RESPONSIBLE =
	'CORRECNTIVEACTIONDEVELOPMET_REQUEST_MOIFY_BY_RESPONSIBLE'

/** Quotes */
export const QUOTE_RESPONSE = 'QUOTERESPONSE'
export const QUOTE_REQUOTE_DETAILS_ITEMS = 'QUOTEREQUOTEDETAILSITEMS'

/** SHIPPING ORDER */
export const SHIPPINGORDER_FORWARDTHESUPPLIER = 'SHIPPINGORDER_FORWARDTHESUPPLIER'

/** FREIGHT */
export const FREIGHT_FORWARDTHESUPPLIER = 'FREIGHT_FORWARDTHESUPPLIER'

/** DELIVERY ORDER */
export const DELIVERYORDER_ARRIVAL = 'DELIVERYORDER_ARRIVAL'
export const DELIVERYORDER_QUALITYVERIFICATION = 'DELIVERYORDER_QUALITYVERIFICATION'
export const DELIVERYORDER_WAREHOUSEVERIFICATION = 'DELIVERYORDER_WAREHOUSEVERIFICATION'
export const DELIVERYORDER_CLOSED = 'DELIVERYORDER_CLOSED'

/* Sustainability Questionnaire documents type*/
export const SUSTAINABILITY_QUESTIONAIRE_NOTIFICAITON = 'SUSTAINABILITYQUESTIONAIRENOTIFICAITON'
export const SUSTAINABILITY_QUESTIONAIRE_RESPONSE = 'SUSTAINABILITYQUESTIONAIRERESPONSE'
export const SUSTAINABILITY_QUESTIONNAIRE_RESEND_TO_SUPPLIER = 'SUSTAINABILITYQUESTIONNAIRERESENDTOSUPPLIER'
export const SUSTAINABILITY_QUESTIONAIRE_CLOSE = 'SUSTAINABILITYQUESTIONAIRECLOSE'
export const QUESTIONNAIRE_ACCEPTED_AND_SEND_SUPPLIER = 'QUESTIONNAIREACCEPTEDANDSENDSUPPLIER'
export const QUESTIONNAIRE_SUPPLIER_ADDING_ACTIONS = 'QUESTIONNAIRESUPPLIERADDINGACTIONS'
export const QUESTIONNAIRE_RESEND_ACTIONS_FROM_SUPPLIER = 'QUESTIONNAIRERESENDACTIONSFROMSUPPLIER'

/** GUARRANTEE REPORT */
export const GUARANTEEDOCUMENT_NOTIFICATION = 'GUARANTEEDOCUMENT_NOTIFICATION'
export const GUARANTEE_NOTE_CREDITREPORT = 'GUARANTEE_NOTE_CREDITREPORT'
export const GUARANTEE_FORWARD_TO_SUPPLIER = 'GUARANTEE_FORWARD_TO_SUPPLIER'
