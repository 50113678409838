import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import NoticesIcon from '@material-ui/icons/ChatTwoTone'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import AddNoticetIcon from '@material-ui/icons/AddTwoTone'
import Fab from '@material-ui/core/Fab'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadOfficialNoticesFromBackEnd,
	CHANGE_OFFICIAL_NOTICE_SORT_CRITERIA,
	CHANGE_OFFICIAL_NOTICE_QUERY,
	CHANGE_OFFICIAL_NOTICE_PAGINATION,
	getOfficeNoticeCount,
} from '../../store/administration/OfficialNoticeActions'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canCloseRegistersAccessControl } from '../../store/helpers/RolesHelper'
import { Tooltip } from '@material-ui/core'
import DataTable from '../common/DataTable'

/**
 * Official notices view
 *
 * @class Official notices
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance of Official notices
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadOfficialNotices(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getOfficeNoticeCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadOfficialNotices()
			this.props.getOfficeNoticeCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadOfficialNotices(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		if (dataSource === 'is_active') {
			return (
				<Tooltip title={<Translate id={item.is_active ? 'common.active' : 'common.inactive'} />}>
					<Avatar data-cy='oficial-avatar' className={item.is_active ? classes.avatar : ''}>
						<NoticesIcon />
					</Avatar>
				</Tooltip>
			)
		}
		if (dataSource === 'official_notice_type') {
			if (item.official_notice_type === 1) {
				return <Translate id='officialNotices.notice' />
			} else if (item.official_notice_type === 2) {
				return <Translate id='officialNotices.comminique' />
			} else {
				return <Translate id='officialNotices.video' />
			}
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Function to add new official notice
	 */
	onClickAddButton() {
		this.props.history.push(`/officialNotices/create`)
	}

	/**
	 * Get notices counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getOfficeNoticesCounterMessage() {
		const { classes } = this.props
		if (this.props.officialNotices) {
			let totalNotices = this.props.officialNotices.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{totalNotices}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.noticesCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingNotices === true) {
			return <LoadingPage />
		} else if (this.props.officialNotices === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.officialNotices}
					configuration={TableConfiguration}
					onRenderCellItem={this.onRenderCellItem}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(notice) => {
						this.props.history.push(`/officialnotices/${notice.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.noticesCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof OfficialNotices
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<NoticesIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput onChangeSearchValue={this.onChangeSearchValue} />
							{canCloseRegistersAccessControl(this.props.userRolesByCompany) && (
								<Fab
									size='small'
									color='primary'
									onClick={() => {
										this.onClickAddButton()
									}}
								>
									<AddNoticetIcon />
								</Fab>
							)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='officialNotices.officialNotices' />{' '}
						</Typography>
					}
					subheader={this.getOfficeNoticesCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='officialNotices.status' />,
			dataSource: 'is_active',
			isSortable: true,
		},
		{
			header: <Translate id='officialNotices.officialNoticeType' />,
			dataSource: 'official_notice_type',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='officialNotices.activationDate' />,
			dataSource: 'activation_date',
			isSortable: true,
		},
		{
			header: <Translate id='officialNotices.expirationDate' />,
			dataSource: 'expiration_date',
			isSortable: true,
		},
		{
			header: <Translate id='officialNotices.notes' />,
			dataSource: 'notes',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingNotices: state.officialNotices.get('isLoadingNotices'),
		officialNotices: state.officialNotices.get('officialNotices')
			? state.officialNotices.get('officialNotices').toJS()
			: null,
		sortCriteria: state.officialNotices.get('sortCriteria')
			? state.officialNotices.get('sortCriteria').toJS()
			: null,
		searchQuery: state.officialNotices.get('searchQuery'),
		noticesCount: state.officialNotices.get('noticesCount'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize),
		page: state.officialNotices.get('page'),
		rowsPerPage: state.officialNotices.get('rowsPerPage'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadOfficialNotices: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadOfficialNoticesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_OFFICIAL_NOTICE_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_OFFICIAL_NOTICE_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_OFFICIAL_NOTICE_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getOfficeNoticeCount: () => {
			dispatch(getOfficeNoticeCount())
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
