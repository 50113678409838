import { fromJS } from 'immutable';

import * as notificationsActions from './NotificationAction';

/**
 * notificationsActions Actions initial state
 */
export const notificationsInitialState = fromJS({
    isLoadingNotifications: true,
    notifications: null,
    isRemovingNotification: false,
    notificationsUserSelected: [],
    isLoadingUserSelectedNotifications: false,
    isLoadingRequest: false,
    notificationsRequest: null,
    isLoadingSourcing: false,
    notificationsSourcing: null,
})

export default function materialDeliveryReducer(state = notificationsInitialState, action) {
    switch (action.type) {
        case notificationsActions.IS_LOADING_NOTIFICATIONS: {
            return state.merge({
                isLoadingNotifications: action.isLoadingNotifications,
                notifications: null,
            })
        }
        case notificationsActions.SET_NOTIFICATIONS: {
            let notificationsLists = fromJS(action.notifications);
            return state.merge({
                notifications: notificationsLists,
                isLoadingNotifications: false
            })
        }
        case notificationsActions.IS_LOADING_USER_NOTIFICATIONS: {
            return state.merge({
                isLoadingUserSelectedNotifications: action.isLoadingNotifications,
                notificationsUserSelected: [],
            })
        }
        case notificationsActions.SET_NOTIFICATIONS_USER: {
            let notificationsLists = fromJS(action.notifications);
            return state.merge({
                isLoadingUserSelectedNotifications: false,
                notificationsUserSelected: notificationsLists,
            })
        }
        case notificationsActions.IS_RENOVE_NOTIFICATION: {
            return state.merge({
                isRemovingNotification: action.isRemoving,
            })
        }
        case notificationsActions.REMOVE_NOTIFICATION: {
            return state.updateIn(['notificationsUserSelected'], (allNotifcations) => {
                let index = allNotifcations.findIndex((item) => {
                    if ((item.get('workflow_token') === action.workflowToken) !== null) {
                        return item.get('workflow_token') === action.workflowToken;
                    }
                    else return null;

                });
                /// Exist notification remove
                if (index !== -1 && index !== null) {
                    return allNotifcations.delete(index);
                }
                /// Not exist return notification
                else {
                    return allNotifcations;
                }
            })
        }
        case notificationsActions.IS_LOADING_NOTIFICATION_REQUEST: {
            return state.merge({
                isLoadingRequest: action.isLoadingNotifications,
                notificationsRequest: null,
            })
        }
        case notificationsActions.SET_NOTIFICATIONS_REQUEST: {
            let notificationsLists = fromJS(action.notificationsRequest);
            return state.merge({
                notificationsRequest: notificationsLists,
                isLoadingRequest: false
            })
        }
        case notificationsActions.ADD_NOTIFICATION_REQUEST: {
            return state.updateIn(['notificationsRequest'], allNotification => addNotification(allNotification, fromJS(action.notification))).set('isRemovingNotification', false);
        }
        case notificationsActions.REMOVE_NOTIFICATION_REQUEST: {
            return state.updateIn(['notificationsRequest'], allNotification => allNotification.filter(d => d.get('CreatedBy') !== action.createdBy)).set('isRemovingNotification', false);
        }
        case notificationsActions.IS_LOADING_NOTIFICATIONS_OUTSORCING: {
            return state.merge({
                isLoadingSourcing: action.isLoadingSourcing,
                notificationsSourcing: null,
            })
        }
        case notificationsActions.SET_NOTIFICATIONS_OUTSORCING: {
            let notificationsLists = fromJS(action.notificationsSourcing);
            return state.merge({
                notificationsSourcing: notificationsLists,
                isLoadingSourcing: false
            })
        }
        default:
            return state;
    }
}

const addNotification = (allCriterias, notification) => {
    let indexOfItem = allCriterias.findIndex(d => d.get('RequestId') === notification.get('RequestId') && d.get('CreatedBy') === notification.get('CreatedBy'));
    if (indexOfItem >= 0) {
        return allCriterias.setIn([indexOfItem], notification);
    }
    return allCriterias.push(notification)
}
