import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';

/** Translations imports section */
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import Avatar from '@material-ui/core/Avatar';
import SuppliersIcon from '@material-ui/icons/People';
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Grid } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import Toaster from '../common/Toaster';
import Evidence from '../common/Evidence';
import downloadTemplateImbera from '../../resources/files/Formato_Actualizar_documentos_imbera.xlsx'
import downloadTemplateRepare from '../../resources/files/Formato_Actualizar_documentos_repare.xlsx'
import downloadTemplatePtm from '../../resources/files/Formato_Actualizar_documentos_ptm.xlsx'

/** Import section actions */
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';
import { showSuccessToaster, showErrorToaster } from '../../store/helpers/ToasterHelpers';
import {createExpedientSupplier} from '../../store/expedientSupplier/ExpedientSupplierAction';
import { COMPANY_IDENTIFIER_IMBERA_REPARE, COMPANY_PTM} from '../../store/helpers/ResourcesHelper';

/**
 * Component to create new expedient 
 */
class Create extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            expedientSupplier: {
                attachments: [],
                name: "",
            }
        }
    }

    updateProperty(property, value) {
        let expedientSupplierUpdate = { ...this.state.expedientSupplier };
        expedientSupplierUpdate[property] = value;
        this.setState({
            expedientSupplier: expedientSupplierUpdate
        })
    }

    canBeSaved() {
        let expedientSupplier = this.state.expedientSupplier;
        if (expedientSupplier.name.length === 0)
            return false;
        if (expedientSupplier.attachments.length <= 0)
            return false;
        else
            return true;
    }

    /**
     * Remove files
     */
    removeFiles(fileName) {
        let attachments = this.state.expedientSupplier.attachments.filter(file => {
            return file.name !== fileName
        })
        this.setState({
            expedientSupplier: {
                ...this.state.expedientSupplier,
                attachments
            }
        })
        return Promise.resolve('Ok')
    }

    /**
     * Add file
     * @param {*} attachments 
     */
    uploadFiles(files) {
        let filesList = this.state.expedientSupplier.attachments;
        if (files.length > 0) {
            for (var index = 0; index < files.length; index++) {
                filesList.push(files[index])
            }
        }
        this.setState({
            expedientSupplier: {
                ...this.state.expedientSupplier,
                attachments: filesList
            }
        })
        return Promise.resolve('Ok')
    }

    /**
     * Function to create expedient supplier
     */
    onCreateExpedientSupplier() {
        this.props.createExpedientSupplier(this.state.expedientSupplier)
            .then((expedientToken) => {
                this.setState(showSuccessToaster("common.addCorrectly"));
                this.props.history.push(`/expedientsupplier/${expedientToken}`);
            }).catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : "common.errorToAdd"));
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <Card className={classes.card} style={{ height: '79vh', width: "1050px", marginLeft: "100px" }}>
                <CardHeader
                    avatar={
                        <Avatar className={classes.userAvatar}>
                            <SuppliersIcon />
                        </Avatar>
                    }
                    title={<Translate id="expedientSupplier.expedientTitle" />}
                    subheader={<Translate id="expedientSupplier.addExpedient" />}
                />
                <CardContent style={{ height: '58vh', overflowY: "auto" }}>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Divider/>
                            <br/>
                            <TextField fullWidth
                                id="name"
                                label={<Translate id="expedientSupplier.addDescription" />}
                                className={classes.textAreaField}
                                value={this.state.expedientSupplier.name}
                                onChange={(event) => { this.updateProperty("name", event.target.value) }}
                            />
                            <br/><br/><br/>
                            <Typography variant="subtitle2" color={'textSecondary'}>
                                <Translate id={"expedientSupplier.uploadSupplierList"} />
                            </Typography>
                            <Evidence
                                text={this.props.translate('dropzone.dropzoneBaseText')}
                                baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
                                removeFiles={this.removeFiles}
                                uploadFiles={this.uploadFiles}
                                files={this.state.expedientSupplier.attachments}
                                disabledLink
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <small className="text-muted"> 
                                <DownloadIcon fontSize="small" color="secondary" style={{ margin: '5px', marginBottom:'8px' }} /> 
                                {this.props.selectedCompany && this.props.selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE && 
                                <a href={downloadTemplateRepare}><Translate id="expedientSupplier.downloadTemplatedExpedient"/></a>}
                                {this.props.selectedCompany && this.props.selectedCompany.get_company_identifier === COMPANY_PTM && 
                                <a href={downloadTemplatePtm}><Translate id="expedientSupplier.downloadTemplatedExpedient"/></a>}
                                {this.props.selectedCompany && (this.props.selectedCompany.get_company_identifier !== COMPANY_IDENTIFIER_IMBERA_REPARE &&
                                this.props.selectedCompany.get_company_identifier !== COMPANY_PTM) && 
                                <a href={downloadTemplateImbera}><Translate id="expedientSupplier.downloadTemplatedExpedient"/></a>}
                            </small>
                        </Grid>
                    </Grid>
                </CardContent>

                <CardActions className={classes.actions} >
                    <Grid item xs={12}>
                        <Divider/>
                        <Toolbar>
                            <Button variant="outlined" color="secondary" className={classes.button}
                                onClick={this.onCreateExpedientSupplier}
                                disabled={!this.canBeSaved() || this.props.isSaving}
                            >
                                <Translate id={this.props.isSaving ? "common.saving" : "common.save"} />
                            </Button>
                            <Button variant="outlined" color="primary" className={classes.button}
                                data-cy="btn-close"
                                onClick={() => { this.props.history.push(`/expedientsupplier`) }} >
                                <Translate id="common.cancel" />
                            </Button>
                        </Toolbar>
                    </Grid>
                </CardActions>

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        )

    }
}

function mapStateToProps(state) {
    let selectedCompany = state.profile.get('selectedCompany')
    return {
        isSaving: state.expedientSuppliers.get('isSavingExpedientSupplier'),
        translate: getTranslate(state.localize),
        selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createExpedientSupplier: (expedientSupplier) => {
            return dispatch(createExpedientSupplier(expedientSupplier));
        },

    }
}

const viewStyles = theme => ({
    userAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 240,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    formatCheck: {
        display: 'flex',
        marginLeft: theme.spacing.unit,
        width: 460,
    },
});

export default withauthenticatedLayout(withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create))));