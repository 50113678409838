/**Import react section */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

/**Import material UI Section*/
import { withStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { Tooltip, AppBar, Grid } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Divider from '@material-ui/core/Divider'

/**Import components section*/
import LoadingPage from '../../common/LoadingPage'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

/**Import resources section*/
import {
	BACKGROUND_COLOR_GRAY_BIDDING,
	COLOR_STATUS_WARNING,
	COLOR_STATUS_DANGER,
	COLOR_QUESTIONNAIRE_CHERRY,
	BACKGROUND_COLOR_CALENDAR_BUTTON,
} from '../../../store/helpers/StatusColorConstants'

/**Import actions section */
const DesktopView = (props) => {
	const {
		isLoading,
		template,
		openCardSubtopic,
		openCardQuestion,
		openDeleteTopic,
		openDeleteSubTopic,
		openDeleteQuestion,
		openEditTemplated,
		openEditSubTopic,
		openEditQuestion,
		onDragEndQuestion,
	} = props
	const { classes } = props

	const [value, setValue] = useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	/* Render card content */
	const getCardContent = () => {
		if (isLoading === true) {
			return <LoadingPage />
		} else if (template === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<div>
					<AppBar position='static' color='default'>
						<Tabs
							variant='scrollable'
							scrollButtons='auto'
							aria-label='scrollable auto'
							value={value}
							onChange={handleChange}
						>
							{template.Topics.map((topic, index) => {
								return (
									<Tab
										style={{ color: topic.ColorTopic, marginTop: '5px' }}
										key={topic.Id}
										id={index}
										label={
											<p>
												<strong>{topic.ShortName}</strong>
											</p>
										}
									/>
								)
							})}
						</Tabs>
					</AppBar>
					{renderQuestionsList()}
				</div>
			)
		}
	}

	const renderQuestionsList = () => {
		let sectionSelected = template.Topics[value]
		if (sectionSelected !== undefined) {
			return <TabPanel key={sectionSelected.Id} topic={sectionSelected} />
		} else {
			return 'No hay seccion seleccioanda '
		}
	}

	/* Render subtopic name */
	function TabPanel(props) {
		const { topic } = props
		return (
			<>
				<div style={{ color: topic.ColorTopic, minWidth: '50px', maxWidth: '100%', display: 'flex' }}>
					<strong className={classes.textTittle}>
						{topic.Name} {<span style={{ color: topic.ColorTopic }}>{topic.Percentage} % </span>}
					</strong>
					<Tooltip style={{ marginLeft: 'auto' }} title={<Translate id='questionnaire.addSubTopic' />}>
						<IconButton size='small' onClick={() => openCardSubtopic(topic.Id)}>
							<AddtIcon className={classes.addButton} />
						</IconButton>
					</Tooltip>
					<Tooltip style={{ marginLeft: '0' }} title={<Translate id='questionnaire.editTopic' />}>
						<IconButton size='small' onClick={() => openEditTemplated(topic.Id, topic)}>
							<EditIcon className={classes.editButton} />
						</IconButton>
					</Tooltip>
					<Tooltip style={{ marginLeft: '0' }} title={<Translate id='questionnaire.deleteTopic' />}>
						<IconButton size='small' onClick={() => openDeleteTopic(topic.Id)}>
							<DeleteIcon className={classes.deleteButton} />
						</IconButton>
					</Tooltip>
				</div>

				{topic.Subtopics.map((subTopic, index) => {
					return (
						<div key={subTopic.Id}>
							<div className={classes.subtopicPanel} key={subTopic.Id} id={index}>
								<Typography variant='subtitle2' style={{ marginLeft: '10px', marginTop: '5px' }}>
									<strong>
										{subTopic.Name}{' '}
										{
											<span style={{ color: BACKGROUND_COLOR_CALENDAR_BUTTON }}>
												{subTopic.Percentage} %{' '}
											</span>
										}{' '}
									</strong>
								</Typography>
								<Tooltip
									style={{ marginLeft: 'auto' }}
									title={<Translate id='questionnaire.addQuestion' />}
								>
									<IconButton size='small' onClick={() => openCardQuestion(topic.Id, subTopic.Id)}>
										<AddtIcon className={classes.addButton} />
									</IconButton>
								</Tooltip>
								<Tooltip
									style={{ marginLeft: '0' }}
									title={<Translate id='questionnaire.editSubTopic' />}
								>
									<IconButton
										size='small'
										onClick={() => openEditSubTopic(topic.Id, subTopic.Id, subTopic)}
									>
										<EditIcon className={classes.editButton} />
									</IconButton>
								</Tooltip>
								<Tooltip
									style={{ marginLeft: '0' }}
									title={<Translate id='questionnaire.deleteSubTopic' />}
								>
									<IconButton size='small' onClick={() => openDeleteSubTopic(topic.Id, subTopic.Id)}>
										<DeleteIcon className={classes.deleteButton} />
									</IconButton>
								</Tooltip>
							</div>

							<Grid item xs={12}>
								<DragDropContext onDragEnd={(event) => onDragEndQuestion(event, subTopic.Id)}>
									<Droppable droppableId='characters'>
										{(provided) => (
											<div
												className='characters'
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												<List
													component='nav'
													aria-labelledby='nested-list-subheader'
													className={classes.root}
												>
													{subTopic.Questions.map((question, index) => {
														return (
															<Draggable
																key={question.Id}
																draggableId={question.Id}
																index={index}
															>
																{(provided) => (
																	<div
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		ref={provided.innerRef}
																	>
																		<Questions
																			id={index}
																			key={question.Id}
																			count={index}
																			question={question}
																			subTopicId={subTopic.Id}
																			topicId={topic.Id}
																		/>
																	</div>
																)}
															</Draggable>
														)
													})}
													{provided.placeholder}
												</List>
											</div>
										)}
									</Droppable>
								</DragDropContext>
							</Grid>
						</div>
					)
				})}
			</>
		)
	}

	/* Render questions */
	function Questions(props) {
		const { question, subTopicId, topicId, count } = props
		return (
			<>
				<>
					<List key={question.Id}>
						<ListItemText
							primary={
								<Typography variant='subtitle2'>
									{count + 1}. {question.Name}{' '}
									{
										<span style={{ color: BACKGROUND_COLOR_CALENDAR_BUTTON }}>
											{question.QuestionType === 0 && <span> {question.Percentage} % </span>}{' '}
										</span>
									}{' '}
								</Typography>
							}
							secondary={
								<>
									{question.Recommendation && (
										<Typography variant='caption'>
											{
												<span style={{ color: COLOR_QUESTIONNAIRE_CHERRY }}>
													{'Recomendación'}
												</span>
											}
											: {question.Recommendation}{' '}
										</Typography>
									)}
									{question.Warning && (
										<Typography variant='caption'>
											{<span style={{ color: COLOR_STATUS_WARNING }}>{'Warning'}</span>}:{' '}
											{question.Warning}{' '}
										</Typography>
									)}
									{(question.RequiredDocument || question.ClassificationType === 1) && (
										<Typography variant='caption'>
											<span style={{ color: COLOR_STATUS_DANGER }}>
												<strong> * </strong>
											</span>
											{
												<span style={{ color: COLOR_QUESTIONNAIRE_CHERRY }}>
													{<Translate id={'sustainability.requiredDocuments'} />}
												</span>
											}
										</Typography>
									)}
								</>
							}
							className={classes.listItems}
						/>
						<ListItemSecondaryAction>
							<Tooltip title={<Translate id='questionnaire.editQuestion' />}>
								<IconButton
									size='small'
									onClick={() => openEditQuestion(topicId, subTopicId, question.Id, question)}
								>
									<EditIcon className={classes.editButton} />
								</IconButton>
							</Tooltip>
							<Tooltip title={<Translate id='questionnaire.deleteQuestion' />}>
								<IconButton
									size='small'
									onClick={() => openDeleteQuestion(topicId, subTopicId, question.Id)}
								>
									<DeleteIcon className={classes.deleteButton} />
								</IconButton>
							</Tooltip>
						</ListItemSecondaryAction>
					</List>
					<Divider />
				</>
			</>
		)
	}

	return getCardContent()
}

const styles = (theme) => ({
	subtopicPanel: {
		minWidth: '50px',
		maxWidth: '100%',
		height: '35px',
		backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
		display: 'flex',
	},
	listItems: {
		marginRight: '10%',
	},
	textTittle: {
		marginTop: '10px',
		marginLeft: '10px',
	},
	addButton: {
		color: 'rgb(67 119 196)',
	},
	editButton: {
		color: COLOR_STATUS_WARNING,
	},
	deleteButton: {
		color: COLOR_STATUS_DANGER,
	},
})

DesktopView.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	openCardSubtopic: PropTypes.func,
	openCardQuestion: PropTypes.func,
	openDeleteTopic: PropTypes.func,
	openDeleteSubTopic: PropTypes.func,
	openDeleteQuestion: PropTypes.func,
}

DesktopView.defaultProps = {
	isLoading: false,
	openCardSubtopic: () => console.warn('Callback [openCardSubtopic] no defined'),
	openCardQuestion: () => console.warn('Callback [openCardQuestion] no defined'),
	openDeleteTopic: () => console.warn('Callback [openDeleteTopic] no defined'),
	openDeleteSubTopic: () => console.warn('Callback [openDeleteSubTopic] no defined'),
	openDeleteQuestion: () => console.warn('Callback [openDeleteQuestion] no defined'),
	template: {},
}

export default withRouter(withStyles(styles, { withTheme: true })(DesktopView))
