import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { withStyles } from '@material-ui/core';
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';
import { Translate } from 'react-localize-redux';

class CardImageLink extends PureComponent {
    render() {
        const { classes } = this.props;
        let hasItems = this.props.data && this.props.data.length > 0;
        let baseRedirectionPath = this.props.baseRedirectionPath;
        return (
            <Grid container spacing={24}>
                {hasItems && this.props.data.map(item => {
                    let title = "";
                    let isActive = false;
                    if(this.props.isPayment){
                        title = `${item.date_start}-${item.date_end}  ${item.status_description}`;
                        isActive = item.is_active_payment || item.status === 2;
                    }
                    if(this.props.isService){
                        title = `${item.start_date}-${item.end_date}  ${item.status}`;
                        isActive = item.is_active_service;
                    }
                    return (
                        <Grid item xs={2}><br/><br/>
                            <Card elevation={3} className={isActive? classes.active : classes.inactive }>
                                <CardActionArea id={title} onClick={this.props.existPath ? ()=>this.props.history.push(`${baseRedirectionPath}/${item.token}`): ""}>
                                    <CardMedia
                                        className={classes.media}
                                        image={this.props.cardImage}
                                        title={title}
                                    />
                                    <CardContent>
                                        <Typography variant="caption" color="textSecondary" component="p">
                                            {title}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )
                })}
                {!hasItems &&
                    <Grid item xs={12}
                        justify="center"
                        alignItems="center"
                        style={{ backgroundColor: BACKGROUND_COLOR_GRAY }}
                    >
                        <Translate id="common.notInformation" />
                    </Grid>
                }
            </Grid>
        );
    }
}

const styles = {
    card: {
        border: `${BACKGROUND_COLOR_GRAY} solid 2px`
    },
    active: {
        border: "#28A745 solid 2px",
    },
    inactive: {
        border: "#DC3545 solid 2px",
    },
    media: {
        height: 150,
        paddingTop: '56.25%',
    },
};

CardImageLink.propTypes = {

};

export default withStyles(styles)(CardImageLink);