
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import {Translate} from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';

/**
 * Component ot render Information partialraw material not conformiti
 */
export default class InformationPartial extends Component{

    /**
     * Create an instance RawMaterial not conformity
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
    }

    /**
     * Render
     */
    render() {
        const { classes} = this.props;
        return (
            <Paper>
                <List>
                    <ListItem divider>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="common.supplier"/>
                                </Typography>
                                {": "}{ this.props.warehouseRawMaterial.full_supplier}
                            </React.Fragment>
                        } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="common.preparedBy" />
                                    </Typography>
                                    {": "}{this.props.warehouseRawMaterial.create_by}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        {<Translate id="warehouseRawMaterial.dateMaterial"/>}
                                    </Typography>
                                    {": "}{this.props.warehouseRawMaterial.delivery_time}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        {<Translate id="warehouseRawMaterial.purchaseOrder"/>}
                                    </Typography>
                                    {": "}{this.props.warehouseRawMaterial.purchase_order_number}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        {<Translate id="warehouseRawMaterial.totalIncidence"/>}
                                    </Typography>
                                    {": "}<Badge color="secondary" className={classes.margin} badgeContent={this.props.warehouseRawMaterial.total_incidence} max={99} children={""} ></Badge>
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary={<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                            <strong><Translate id="warehouseRawMaterial.informationMissing"/></strong>
                        </Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.warehouseRawMaterial.purchase_order && <span>{" — "}{"Orden de compra"}<br /></span>}
                                    {this.props.warehouseRawMaterial.quality_certificate && <span>{" — "}{"Certificado de calidad"}<br /></span>}
                                    {this.props.warehouseRawMaterial.invoice_or_remision && <span>{" — "}{"Factura / Remisión"}<br /></span>}
                                    {this.props.warehouseRawMaterial.correct_quantity && <span>{" — "}{"Cantidad incompleta"}<br /></span>}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                         secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    {<Translate id="warehouseRawMaterial.commnetentry"/>}
                                </Typography>
                                {": "}{this.props.warehouseRawMaterial.comments}
                            </React.Fragment>
                        }/>
                    </ListItem>

                    <Divider />
                </List>

            </Paper>
        );
    }
}

/**
 * warehouseRawMateria
 */
InformationPartial.propTypes = {
    warehouseRawMaterial: PropTypes.object.isRequired
}