import React, { useState } from 'react';
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, } from 'react-redux'

/**Import MaterialUi section */
import { Grid, Avatar, Typography } from '@material-ui/core'
import FileIcon from '@material-ui/icons/AttachFile';
import {
    ListItemAvatar, List, ListItemText,
    ListItem, IconButton, ListItemSecondaryAction
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';

/** section import components */
import Evidence from '../../common/Evidence'
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';

/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader';

import { updateSupplierEvidenceFromSupplier, 
    removeSupplierEvidence,downloadFiles} from '../../../store/sustainability2/SustainabilityActions'


const PanelFiles = props => {
    /**props section */
    const {
        onCloseButtonClick,
        classes, topicId, 
        subtopicId, 
        question,
        canBeEdit,
    } = props

    const [attachments, setAttachments] = useState(question.Attachments ? question.Attachments : [])

    /**Render hooks section */
    const dispatch = useDispatch()

    /**
    * On upload supplier evidence
    */
    const uploadFiles = (files) => {
        let sustainabilityId = props.match.params.token;
        let filesList = attachments.concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
            return dispatch(updateSupplierEvidenceFromSupplier(sustainabilityId, topicId, subtopicId, question.Id, files))
            .then(() => {
                onCloseButtonClick()
                setAttachments(filesList)
                return Promise.resolve('Ok');

            }).catch(() => {
                return Promise.reject();
            });
        }
        return Promise.resolve('Ok');
    }

    /**
     * Handle event remove files of evidence componet to remove files of list
     * @param {*} fileName
     * @returns
    */
    const removeFiles = (fileName) => {
        let sustainabilityId = props.match.params.token;
        let newList = attachments.filter(files => {
            return files.name !== fileName
        })
        return dispatch(removeSupplierEvidence(sustainabilityId, topicId, subtopicId, question.Id, fileName))
            .then(() => {
                setAttachments(newList)
                return Promise.resolve('Ok');
            }).catch(() => {
                return Promise.reject();
            });
    }

    const getfileDownload =(fileName, extension) =>{
        return dispatch(downloadFiles(question.Id, fileName, extension))
        .then(() => {
            return Promise.resolve('Ok');
        }).catch(() => {
            return Promise.reject();
        });
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}>
            <PanelHeader
                titlePanel={<Translate id="sustainability.supplierEvidence" />}
                icon={<Avatar className={classes.avatar}><FileIcon /></Avatar>}
                onCloseButtonClick={onCloseButtonClick}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container>
                    <Typography color='primary'><Translate id="sustainability.uploadEvidence"/></Typography>
                        <Grid item xs={12}>
                            {canBeEdit && 
                             <div className={classes.alertPanel}>
                                <div className={classes.alert}>
                                    <Typography variant='caption'>
                                    <InfoIcon/>
                                    <Translate id="common.uploadFilesActionPlant"/>
                                    </Typography>
                                </div>
                            </div>
                            }
                            {canBeEdit && <Evidence
                                text={<Translate id="sustainability.uploadEvidence"/>}
                                uploadFiles={uploadFiles}
                                isDisabled={!canBeEdit}
                                maxFiles={1}
                                ///files={attachments}
                                //removeFiles={removeFiles}s
                                //baseApi={process.env.REACT_APP_SIP_SUSTAINABILITY_URL_FILES}
                            />}
                            {question.Attachments.length === 0 && <Translate id="common.noFiles" />}
                            <List>
                                {
                                    attachments.map(((evidence, index) => {
                                        return (
                                            <ListItem key={index} divider>
                                                <ListItemAvatar>
                                                    <Avatar className={classes.avatar} size="small">
                                                        <FileIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={<Typography color="primary">{evidence.name}</Typography>}
                                                    style={{cursor: "pointer"}}  
                                                    onClick={()=>getfileDownload(evidence.name, evidence.ext)} 
                                                />
                                                {canBeEdit && <ListItemSecondaryAction>
                                                    <IconButton edge="end" onClick={() => removeFiles(evidence.name)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>}
                                            </ListItem>
                                        )
                                    }))
                                }
                            </List>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </LateralPanel >
    );
};

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    saveButton: {
        bottom: 0
    },
});

PanelFiles.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onDeleteAttachment: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onSaveItem: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onCloseButtonClick: propTypes.func,
    /**All attachments saved */
    /**
     * Validate if template is correctly
     */
    uploadFilesFromSupplier: propTypes.func
};

PanelFiles.defaultProps = {
    onDeleteAttachment: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveFile: () =>
        console.warn('Callback [onSaveFile] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelFiles))