import React from "react";
import { Translate } from 'react-localize-redux';

import {ClassificationType, QuestionStatusType} from '../helpers/AppConstants'

import { getColorStatusAction} from '../helpers/SustainabilityHelper';

/**
 * Function to get total work
 * @param {*} sustainability 
 */
export const getTotalWorkDetail = (sustainability, totalWorkerDetail) => {
    if(totalWorkerDetail)
        return totalWorkerDetail;
        
    if (sustainability === null || sustainability === undefined)
        return null;
    if (sustainability.WorkerDetails.length === 0)
        return null;
    return sustainability.WorkerDetails[0];
}

/**
 * Selection list question critical
 * @param {*} items 
 */
export const getQuestionsCritical = (subtopics) => {
    let questions = [];

    if (subtopics === null)
        return questions;

    subtopics.forEach((subTopic) => {
        subTopic.Questions.forEach((quetion) => {
            if(quetion.ClassificationType === ClassificationType.CRITICAL)
                questions.push(quetion)
        })
    })
    return questions
}

/**
 * Selection question oportunities
 * @param {*} subtopics 
 */
export const getQuestionsOportunity = (subtopics) => {
    let questions = [];

    if (subtopics === null)
        return questions;

    subtopics.forEach((subTopic) => {
        subTopic.Questions.forEach((quetion) => {
            if(quetion.ClassificationType === ClassificationType.OPPORTUNITYAREA)
                questions.push(quetion)
        })
    })
    return questions
}


/**
 * Function to get answer upplier
 * @param {*} answerSupplier 
 */
export const getAnswerQuestion = (answerQuestion) =>{
    if(answerQuestion === "1")
       return <Translate id={"sustainability.yes"} />;
   else if(answerQuestion === "2")
       return <Translate id={"sustainability.not"} />;
   else if(answerQuestion === "3")
       return <Translate id={"sustainability.na"} />
   else
       return "-"
}

/**
 * Function to get status question
 * @param {*} status 
 * @returns 
 */
export const getStatusQuestion = (status) =>{
    if(status === QuestionStatusType.COMPLIES)
        return(<Translate id={"sustainability.fulfilled"} />)
    else if(status === QuestionStatusType.FAILS)
        return(<Translate id={"sustainability.notCumply"} />)
    else    
        return("-");
}

/**
 * Selection list by classification
 * @param {*} items 
 */
export const getActionsByCriteriaList = (actionPlans) => {
    if (actionPlans === null)
        return [];
    let result = actionPlans.filter(action => action.ClassificationType === ClassificationType.CRITICAL);
    let mappedResponse = result.map(action => {
        action.color_status_table = getColorStatusAction(action.Status)
        return action
    })
    return mappedResponse
}

/**
 * This method get filter OpportunityArea
 * @param {*} groupName 
 * @param {*} items 
 */
export const getActionsByOpportunityList = (actionPlans) => {
    if (actionPlans === null)
        return [];
    let result = actionPlans.filter(action => action.ClassificationType === ClassificationType.OPPORTUNITYAREA);
    let mappedResponse = result.map(action => {
        action.color_status_table = getColorStatusAction(action.Status)
        return action
    })
    return mappedResponse
}