import { fromJS } from 'immutable';

import * as devolutionActions from './DevolutionActions';

/**
 * devolutionInitialState initial state
 */
export const devolutionInitialState = fromJS({
    isLoadingDevolutions: true,
    devolutions: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    devolutionsCount: null,
    isSaving: false,
    isLoading: false,
    devolution: null,
    isLoadingInvoices: false,
    invoices:false,
    isAllDevolutionsReports: false,
})

export default function devolutionReducer(state = devolutionInitialState, action) {
    switch (action.type) {
        case devolutionActions.START_LOADING_DEVOLUTIONS: {
            return state.merge({
                isLoadingDevolutions: action.isLoading
            })
        }
        case devolutionActions.SET_DEVOLUTIONS_LIST: {
            let devolutionsLists = fromJS(action.devolutions);
            return state.merge({
                devolutions: devolutionsLists,
                isLoadingDevolutions: false,
                isSaving:false,
            })
        }
        case devolutionActions.CHANGE_DEVOLUTION_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case devolutionActions.CHANGE_DEVOLUTIONS_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case devolutionActions.CHANGE_DEVOLUTIONS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case devolutionActions.IS_LOADING_ALL_DEVOLUTIONS_REPORTS: {
            return state.merge({
                isAllDevolutionsReports: action.isAllReports
            })
        }
        case devolutionActions.SET_DEVOLUTIONS_COUNT: {
            return state.merge({
                devolutionsCount: action.count
            })
        }
        case devolutionActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case devolutionActions.IS_LOADING: {
            return state.merge({
                isLoading: action.isLoading
            })
        }
        case devolutionActions.SET_DEVOLUTION:{
            return state.merge({
                isLoading: false,
                isSaving:false,
                devolution: fromJS(action.devolution)
            })
        }
        case devolutionActions.START_INVOICES_LIST_BY_CODE:{
            return state.merge({
                isLoadingInvoices: action.isLoadingInvoices
            }) 
        }
        case devolutionActions.SET_INVOICES_LIST_BY_CODE: {
            let invoicesLists = fromJS(action.invoices);
            return state.merge({
                invoices: invoicesLists,
                isLoadingInvoices: false
            })
        }
        case devolutionActions.DELETE_INVOICE_IN_ITEM_DEVOLUTION: {
            return state.updateIn(['devolution', 'items'], (allItems) => {
                let index = allItems.findIndex((detail) => {
                    return detail.get('token') === action.itemToken
                });
                let detailToChange = allItems.get(index);
                let invoiceIndex =  detailToChange.get('invoices').findIndex((invoiceMap) => {
                    return invoiceMap.get('token') === action.invoiceToken
                })
                let updatedInvoicesList = detailToChange.get('invoices').delete(invoiceIndex);
                let updatedInvoices = detailToChange.set('invoices', updatedInvoicesList);
                return allItems.setIn([index], updatedInvoices);
            });
        }
        case devolutionActions.DELETE_ITEM_DEVOLUTION:{
            return state.updateIn(['devolution', 'items'], (allItems) => {
                let index = allItems.findIndex((item) => {
                    return item.get('token') === action.itemToken
                });
                return allItems.delete(index);
            })
        }
        case devolutionActions.ADD_FILE_DEVOLUTION: {
            let oldList = state.getIn(['devolution', 'attachments']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['devolution', 'attachments'], fromJS(newList));
        }
        case devolutionActions.REMOVE_FILE_DEVOLUTION: {
            let oldList = state.getIn(['devolution', 'attachments']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['devolution', 'attachments'], fromJS(newList));
        }
        default:
            return state;
    }
}