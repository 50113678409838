///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'
//Import components section
import FactoringAffiliationDetail from './components/detail/DesktopView'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
//Import store section
import { converListToArray, toSafeObject } from '../../store/helpers/ListHelper'

import {
	loadFactoringAffiliationFromBackEnd,
	createFactoringHistoryBackEnd,
	updateSupplierInFactoringAffiliationBackEnd,
} from '../../store/factoringAffiliations/FactoringAffiliationActions'
import Toaster from '../common/Toaster'

const Show = (props) => {
	/**Render hooks section */
	const dispatch = useDispatch()

	const selectedCompany = useSelector((state) =>
		state.profile.get('selectedCompany') ? toSafeObject(state.profile.get('selectedCompany')) : null
	)
	const factoringAffiliation = useSelector((state) =>
		state.factoringAffiliation.get('factoringAffiliationDetail')
			? toSafeObject(state.factoringAffiliation.get('factoringAffiliationDetail'))
			: null
	)
	const isLoading = useSelector((state) =>
		state.factoringAffiliation.get('isLoading') ? state.factoringAffiliation.get('isLoading') : false
	)
	/**Redux store information section*/
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)

	/** Get user Information*/
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})
	const userProfile = useSelector((state) => state.oidc.user)
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')
	/**Use effect section */
	useEffect(() => {
		dispatch(loadFactoringAffiliationFromBackEnd(props.match.params.token))
	}, [dispatch, props.match.params.token])

	const onRedirectToCreateFactoringAfiliation = () => {
		return dispatch(createFactoringHistoryBackEnd(props.match.params.token)).then((result) => {
			props.history.push(`/factoringaffiliation/${props.match.params.token}/show/${result.Id}`)
		})
	}

	const onUpdateSupplierFactoring = (email, phoneNumber) => {
		return dispatch(updateSupplierInFactoringAffiliationBackEnd(props.match.params.token, email, phoneNumber))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * onRowClick
	 * @param {*} calendar
	 */
	const onRowClick = (itemRow) => {
		props.history.push(`/factoringaffiliation/${props.match.params.token}/show/${itemRow.Id}`)
	}
	return (
		<>
			<FactoringAffiliationDetail
				//Array props section
				userRolesByCompany={userRolesByCompany}
				//object props section
				selectedCompany={selectedCompany}
				factoringAffiliation={factoringAffiliation}
				//Functions props
				onRedirectToCreateFactoringAfiliation={onRedirectToCreateFactoringAfiliation}
				onRowClick={onRowClick}
				onUpdateSupplierFactoring={onUpdateSupplierFactoring}
				//bool props section
				isInternalUser={isInternalUser()}
				isLoading={isLoading}
			/>
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</>
	)
}

const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
