import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

/** Material UI imports section */
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

/** Material UI Icons imports section */
import QualityAssuranceIcon from '@material-ui/icons/ThumbUpAltTwoTone'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import CancelIcon from '@material-ui/icons/Block'
import PrintIcon from '@material-ui/icons/Print'

/** Actions nd helpers imports section */
import {
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_BLUE,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_LIGHT,
	COLOR_STATUS_GRAY,
} from './../../../../store/helpers/StatusColorConstants'
import { IsUserSupplierAdministrator } from '../../../../store/helpers/SessionHelper'
import { canBeModulePlaneationSupplier, canBeOperationDeliveryWarehouse } from '../../../../store/helpers/RolesHelper'

class OptionsDeliveryOrder extends Component {
	/**
	 *Creates an instance of MaterialsTable.
	 * @param {*} props
	 * @memberof MaterialsTable
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/// Render generated option order
	renderOptionDeliveryOrder() {
		let canbeSupplier =
			this.props.canSupplierCancel &&
			(canBeModulePlaneationSupplier(this.props.roles) || IsUserSupplierAdministrator(this.props.user))
		let canBeCanceFromSap =
			this.props.isCloseOrder && !this.props.isCanceled && canBeOperationDeliveryWarehouse(this.props.roles)
		let canbeCalcenInTransit =
			this.props.canBeCancelByWarehouse &&
			!this.props.isCanceled &&
			canBeOperationDeliveryWarehouse(this.props.roles)

		return (
			<Grid container spacing={0}>
				<Grid item xs={10}>
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<Fab
								disabled={!this.props.canBePrintOrderPdf}
								variant='extended'
								component='a'
								target='_blank'
								size='small'
								style={{
									margin: '0.5em 1%',
									width: '98%',
									backgroundColor: this.props.canBePrintOrderPdf
										? COLOR_STATUS_BLUE
										: COLOR_STATUS_GRAY,
									opacity: this.props.isSaving ? '.60' : '',
								}}
								href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}Documents/DeliveryOrders/${this.props.deliveryOrderToken}.pdf`}
							>
								<DownloadIcon style={{ margin: '0.3em', color: '#fff' }} />
								<Typography
									style={{ color: this.props.isSaving ? '' : '#fff', fontSize: '0.65rem' }}
									variant='caption'
								>
									{this.props.canBePrintOrderPdf ? (
										<Translate id='deliveryOrderModule.downloadDeliveryOrder' />
									) : (
										'No es posible descargar, debe completar el certificado'
									)}
								</Typography>
							</Fab>
						</Grid>
						{canBeCanceFromSap && (
							<Grid item xs={3}>
								<Fab
									variant='extended'
									component='a'
									target='_blank'
									size='small'
									style={{
										margin: '0.5em 1%',
										width: '98%',
										backgroundColor: COLOR_STATUS_DANGER,
										opacity: this.props.isSaving ? '.60' : '',
									}}
									disabled={this.props.isSaving}
									onClick={this.props.onCancelDeliveryInSAPOpen}
								>
									<CancelIcon style={{ margin: '0.3em', color: '#fff' }} />
									<Typography
										style={{ color: this.props.isSaving ? '' : '#fff', fontSize: '0.65rem' }}
										variant='caption'
									>
										{' '}
										<Translate id='deliveryOrderModule.canceOrderToSAP' />
									</Typography>
								</Fab>
							</Grid>
						)}
						{canbeSupplier && (
							<Grid item xs={3}>
								<Fab
									variant='extended'
									component='a'
									target='_blank'
									size='small'
									style={{
										margin: '0.5em 1%',
										width: '98%',
										backgroundColor: COLOR_STATUS_DANGER,
										opacity: this.props.isSaving ? '.60' : '',
									}}
									disabled={this.props.isSaving}
									onClick={this.props.onCancelDeliveryOpen}
								>
									<CancelIcon style={{ margin: '0.3em', color: '#fff' }} />
									<Typography
										style={{ color: this.props.isSaving ? '' : '#fff', fontSize: '0.65rem' }}
										variant='caption'
									>
										{' '}
										<Translate id='deliveryOrderModule.cancelPurchaseOrder' />
									</Typography>
								</Fab>
							</Grid>
						)}
						{canbeCalcenInTransit && (
							<Grid item xs={3}>
								<Fab
									variant='extended'
									component='a'
									target='_blank'
									size='small'
									style={{
										margin: '0.5em 1%',
										width: '98%',
										backgroundColor: COLOR_STATUS_DANGER,
										opacity: this.props.isSaving ? '.60' : '',
									}}
									disabled={this.props.isSaving}
									onClick={this.props.onCancelDeliveryOpen}
								>
									<CancelIcon style={{ margin: '0.3em', color: '#fff' }} />
									<Typography
										style={{ color: this.props.isSaving ? '' : '#fff', fontSize: '0.65rem' }}
										variant='caption'
									>
										<Translate id='deliveryOrderModule.cancelPurchaseOrder' />
									</Typography>
								</Fab>
							</Grid>
						)}
						{this.props.hasMaterialReturnedMissing && (
							<Grid item xs={3}>
								<Fab
									variant='extended'
									component='a'
									target='_blank'
									size='small'
									style={{
										margin: '0.5em 1%',
										width: '98%',
										backgroundColor: COLOR_STATUS_LIGHT,
										opacity: this.props.isSaving ? '.60' : '',
									}}
									disabled={this.props.isSaving}
									onClick={this.props.downloadPDForMaterialReturnedOrMissing}
								>
									<PrintIcon style={{ margin: '0.3em', color: '#fff' }} />
									<Typography
										style={{ color: this.props.isSaving ? '' : '#fff', fontSize: '0.65rem' }}
										variant='caption'
									>
										<Translate id='deliveryOrderModule.buttonViewReturnMaterial' />
									</Typography>
								</Fab>
							</Grid>
						)}
					</Grid>
					<Divider />
				</Grid>
				<Grid item xs={2}>
					<Typography align='right' style={{ margin: '.95em' }}>
						<Translate id='common.total' />:{` ${this.props.totalOrderItems}`}
					</Typography>
					<Divider />
				</Grid>
			</Grid>
		)
	}

	renderByWarehouseUser() {
		let canBeSendSAP = this.props.canBeSendSAP && canBeOperationDeliveryWarehouse(this.props.roles)
		let showAction = canBeSendSAP || this.props.canAcceptEditWarehouseman
		return (
			<Grid container spacing={0}>
				<Grid item xs={10}>
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<Fab
								variant='extended'
								component='a'
								target='_blank'
								size='small'
								style={{
									margin: '0.5em 1%',
									width: '98%',
									backgroundColor: COLOR_STATUS_BLUE,
									opacity: this.props.isSaving ? '.60' : '',
								}}
								href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}Documents/DeliveryOrders/${this.props.deliveryOrderToken}.pdf`}
							>
								<DownloadIcon style={{ margin: '0.3em', color: '#fff' }} />
								<Typography
									style={{ color: this.props.isSaving ? '' : '#fff', fontSize: '0.65rem' }}
									variant='caption'
								>
									{' '}
									<Translate id='deliveryOrderModule.downloadDeliveryOrder' />
								</Typography>
							</Fab>
						</Grid>
						{showAction && (
							<Grid item xs={3}>
								<Fab
									variant='extended'
									component='a'
									target='_blank'
									size='small'
									style={{
										margin: '0.5em 1%',
										width: '98%',
										backgroundColor: COLOR_STATUS_SUCCESS,
										opacity: !canBeSendSAP || this.props.isSaving ? '.60' : '',
									}}
									disabled={!canBeSendSAP || this.props.isSaving || this.props.isRampThree}
									onClick={this.props.onSendDeliveryOrderToSAP}
								>
									<QualityAssuranceIcon style={{ margin: '0.3em', color: '#fff' }} />
									<Typography
										style={{
											color: !canBeSendSAP || this.props.isSaving ? '' : '#fff',
											fontSize: '0.65rem',
										}}
										variant='caption'
									>
										{' '}
										<Translate id='deliveryOrderModule.migrateToAP' />
									</Typography>
								</Fab>
							</Grid>
						)}
						{(showAction || this.props.canBeCancelByWarehouse) && (
							<Grid item xs={3}>
								<Fab
									variant='extended'
									component='a'
									target='_blank'
									size='small'
									style={{
										margin: '0.5em 1%',
										width: '98%',
										backgroundColor: COLOR_STATUS_DANGER,
										opacity: this.props.isSaving ? '.60' : '',
									}}
									disabled={this.props.isSaving}
									onClick={this.props.onCancelDeliveryOpen}
								>
									<CancelIcon style={{ margin: '0.3em', color: '#fff' }} />
									<Typography
										style={{ color: this.props.isSaving ? '' : '#fff', fontSize: '0.65rem' }}
										variant='caption'
									>
										<Translate id='deliveryOrderModule.cancelPurchaseOrder' />
									</Typography>
								</Fab>
							</Grid>
						)}
						{this.props.hasMaterialReturnedMissing && (
							<Grid item xs={3}>
								<Fab
									variant='extended'
									component='a'
									target='_blank'
									size='small'
									style={{
										margin: '0.5em 1%',
										width: '98%',
										backgroundColor: COLOR_STATUS_LIGHT,
										opacity: this.props.isSaving ? '.60' : '',
									}}
									disabled={this.props.isSaving}
									onClick={this.props.downloadPDForMaterialReturnedOrMissing}
								>
									<PrintIcon style={{ margin: '0.3em', color: '#fff' }} />
									<Typography
										style={{ color: this.props.isSaving ? '' : '#fff', fontSize: '0.65rem' }}
										variant='caption'
									>
										<Translate id='deliveryOrderModule.buttonViewReturnMaterial' />
									</Typography>
								</Fab>
							</Grid>
						)}
					</Grid>
					<Divider />
				</Grid>
				<Grid item xs={2}>
					<Typography align='right' style={{ margin: '.95em' }}>
						<Translate id='common.total' />:{` ${this.props.totalOrderItems}`}
					</Typography>
					<Divider />
				</Grid>
			</Grid>
		)
	}

	render() {
		let canBeWharehouse = this.props.showOptionsByWarehouse && canBeOperationDeliveryWarehouse(this.props.roles)
		if (canBeWharehouse) {
			return this.renderByWarehouseUser()
		} else {
			return this.renderOptionDeliveryOrder()
		}
	}
}

const styles = (theme) => ({
	customBadge: {
		color: 'white',
		top: '50%',
		right: '-100%',
	},
})

export default withStyles(styles)(OptionsDeliveryOrder)
