import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import LoadingPage from '../../common/LoadingPage'
import DataTable from '../../common/DataTable'
import Toaster from '../../common/Toaster'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import UsersIcon from '@material-ui/icons/PermIdentityTwoTone'
import EditUser from '@material-ui/icons/BorderColor'
import Button from '@material-ui/core/Button'

/** Language imports **/
import { Translate } from 'react-localize-redux'
import EditMailAdministrator from './EditMailAdministrator'
import AddCommonItem from '../../common/AddCommonItem'

/** Import section actions */
import {
	loadUsersSupplierFromBackEnd,
	createUserData,
	updateEmailUsersSupplier,
	setUserNameLoggedInSupplier,
	CHANGE_EXTERNAL_USERS_SORT_CRITERIA,
	CHANGE_EXTERNAL_USERS_QUERY,
	activeUserSupplierExpiredToSingPortal,
	activeAllUserSupplierExpiredToSingPortal,
} from '../../../store/suppliers/ExternalUserActions'

import { converListToArray } from '../../../store/helpers/ListHelper'
import {
	canBeLonginSupplier,
	IsUserInRole,
	ROLE_COMPANYADMINISTRATOR,
	ROLE_SUPPLIERDEVELOPMENT,
} from '../../../store/helpers/RolesHelper'
import { COMPANY_PTM } from '../../../store/helpers/ResourcesHelper'
import { Lock } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import moment from 'moment'

/**
 * UsersSupplier view
 *
 * @class ExternalUserIndex
 * @extends {Component}
 */
class UsersSupplier extends Component {
	/**
	 * Create an instance of ExternalUserIndex
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openDialogEditUser: false,
			userEdit: null,
			openDialogChangeUser: false,
			userNameLogger: '',
			accessToken: '',
			userEnabled: true,
			openDialogActive: false,
			openDialogActiveAll: false,
			userIdActive: '',
			allUsersIdActive: [],
			userNameActive: '',
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			let supplierToken = this.props.match.params.token
			this.props.loadExterbalUsers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.state.userEnabled,
				supplierToken
			)
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let supplierToken = this.props.match.params.token
		if (prevProps.isLoadingUser !== this.props.isLoadingUser && this.props.isLoadingUser === false) {
			this.props.loadExterbalUsers()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery
		) {
			this.props.loadExterbalUsers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.state.userEnabled,
				supplierToken
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * onEditEmailUser
	 */
	onEditEmailUser(userEdit) {
		this.setState({
			openDialogEditUser: true,
			userEdit: userEdit,
		})
	}

	/**
	 * Function on update email adminstrator
	 */
	onEditMailUserAdministrator(user) {
		this.props
			.updateEmailUsersSupplier(user)
			.then(() => {
				this.setState({
					openDialogEditUser: false,
					userEdit: null,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * onChangeUserLogger
	 */
	onChangeUserLogger(userNameLogger) {
		this.setState({
			openDialogChangeUser: true,
			userNameLogger: userNameLogger,
			accessToken: '',
		})
	}

	/**
	 * setUserNameLoggedInSupplier
	 * @param {*} userId
	 */
	setUserLogger() {
		this.props
			.setUserNameLoggedInSupplier(this.state.userNameLogger)
			.then((response) => {
				this.setState({
					accessToken: response,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * On get userId to active
	 * @param {*} userIdActive
	 */
	onActiveUserLock(userIdActive, name) {
		this.setState({
			openDialogActive: true,
			userIdActive: userIdActive,
			userNameActive: name,
		})
	}

	/**
	 * On get users Id to active
	 * @param {*} allUsersIdActive
	 */
	onActiveAllUsersLock(allUsersIdActive) {
		this.setState({
			openDialogActiveAll: true,
			allUsersIdActive: allUsersIdActive,
		})
	}

	/**
	 * set active user
	 */
	setAllUserActived() {
		this.props
			.activeAllUserSupplierExpiredToSingPortal(this.state.allUsersIdActive)
			.then(() => {
				this.setState({
					openDialogActive: false,
					allUsersIdActive: [],
					showToaster: true,
					toasterMessage: <Translate id='common.activatedAllUserCorrectly' />,
					toasterVariant: 'success',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * set active user
	 */
	setUserActived() {
		this.props
			.activeUserSupplierExpiredToSingPortal(this.state.userIdActive)
			.then(() => {
				this.setState({
					openDialogActive: false,
					userIdActive: '',
					showToaster: true,
					toasterMessage: <Translate id='common.activatedUserCorrectly' />,
					toasterVariant: 'success',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * On print all user names
	 */
	onPrintAllUserNames(users) {
		if (users.length > 0) {
			return (
				<ul>
					{users.map((users) => (
						<li>
							<Typography>{users.full_name}</Typography>
						</li>
					))}
				</ul>
			)
		}
		return null
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		if (dataSource === 'enabled' && canBeLonginSupplier(this.props.userRolesByCompany)) {
			return (
				<Button
					size='small'
					variant='outlined'
					color='primary'
					disabled={this.props.isSaving}
					className={classes.bottnText}
					onClick={() => {
						this.onChangeUserLogger(item.user_name)
					}}
				>
					<Translate id='common.singUserExternal' />
				</Button>
			)
		}
		if (dataSource === 'full_name') {
			if (IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany)) {
				return (
					<React.Fragment key={item.id}>
						<span>
							<EditUser
								onClick={() => {
									this.onEditEmailUser(item)
								}}
								style={{ zIndex: '990', cursor: 'pointer' }}
								color='secondary'
							/>{' '}
							{item.full_name}{' '}
						</span>
					</React.Fragment>
				)
			} else {
				return item.full_name
			}
		}
		if (dataSource === 'email') {
			if (IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany)) {
				return (
					<React.Fragment key={item.id}>
						<span>
							<EditUser
								onClick={() => {
									this.onEditEmailUser(item)
								}}
								style={{ zIndex: '990', cursor: 'pointer' }}
								color='secondary'
							/>{' '}
							{item.email}{' '}
						</span>
					</React.Fragment>
				)
			} else {
				return item.email
			}
		}
		if (
			dataSource === 'user_type' &&
			(IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) ||
				IsUserInRole(ROLE_SUPPLIERDEVELOPMENT, this.props.userRolesByCompany))
		) {
			return (
				<Button
					size='small'
					variant='outlined'
					style={{ color: 'rgb(18 156 113)' }}
					disabled={this.props.isSaving}
					className={classes.bottnText}
					onClick={() => {
						this.onActiveUserLock(item.id, item.full_name)
					}}
				>
					<Translate id='users.activeUserExpirated' />
				</Button>
			)
		}
		if (dataSource === 'actions') {
			let isActive =
				moment(item.last_authentication_date).diff(moment(), 'days') > -30 ||
				item.last_authentication_date === null
			return (
				<div style={{ display: 'flex' }}>
					<Tooltip
						title={
							item.last_authentication_date === null
								? 'No ha ingresado'
								: isActive
								? 'Activo'
								: 'Inactivo'
						}
					>
						<Lock
							style={
								item.last_authentication_date === null
									? { color: '#49C5FA' }
									: isActive
									? { color: 'rgba(121, 216, 126, 1)' }
									: { color: 'rgba(218, 127, 122, 1)' }
							}
						/>
					</Tooltip>
					<Typography
						variant='caption'
						style={
							isActive
								? { color: 'rgba(121, 216, 126, 1)', marginLeft: '15px', marginTop: '3px' }
								: { color: 'rgba(218, 127, 122, 1)', marginLeft: '15px', marginTop: '3px' }
						}
					>
						{item.last_authentication_date ? moment(item.last_authentication_date).calendar() : null}
					</Typography>
				</div>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		let isDisabled = canBeLonginSupplier(this.props.userRolesByCompany)
		let isDisabledAdminToActive =
			this.props.selectedCompany &&
			(IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) ||
				IsUserInRole(ROLE_SUPPLIERDEVELOPMENT, this.props.userRolesByCompany))
		if (this.props.isLoadingUsers === true) {
			return <LoadingPage />
		} else if (this.props.externalUsers === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.externalUsers}
					configuration={TableConfiguration(isDisabled, isDisabledAdminToActive)}
					onRenderCellItem={this.onRenderCellItem}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
				/>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof ExternalUserIndex
	 */
	render() {
		const { classes } = this.props
		let message = <Translate id='common.messageSingToUserExternal' />
		if (this.state.accessToken) {
			message = (
				<span>
					{<Translate id='suppliers.messageAccess' />} <br />
					<br />
					<strong>{<Translate id='suppliers.loginWith' />}: </strong> {this.state.userNameLogger}
					<br />
					<strong>{<Translate id='suppliers.temporaryAccess' />}: </strong> {this.state.accessToken}
					<br />
				</span>
			)
		}
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<UsersIcon />
						</Avatar>
					}
					action={
						IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) &&
						this.props.selectedCompany.company_code === COMPANY_PTM && (
							<div className={classes.actionsToolbar}>
								<Button
									variant='outlined'
									style={{ color: 'rgb(18 156 113)' }}
									disabled={this.props.isSaving}
									className={classes.bottnText}
									onClick={() => {
										this.onActiveAllUsersLock(this.props.externalUsers)
									}}
								>
									<Translate id='users.activeAllUsersExpirated' />
								</Button>
							</div>
						)
					}
					title={
						<Typography variant='button'>
							<Translate id='users.users' />{' '}
						</Typography>
					}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to Edit mail supplier */}
				{this.state.userEdit && (
					<EditMailAdministrator
						title={<Translate id='suppliers.updatEmailAdmin' />}
						message={<Translate id='suppliers.emailRequiered' />}
						open={this.state.openDialogEditUser}
						onClose={() => {
							this.setState({ openDialogEditUser: false })
						}}
						onSaveItem={this.onEditMailUserAdministrator}
						isSaving={this.props.isSaving}
						userEdit={this.state.userEdit}
					/>
				)}

				{/** Dialog to messageSingToUserExternal */}
				{this.state.openDialogChangeUser && (
					<AddCommonItem
						title={<Translate id='common.singUserExternal' />}
						message={message}
						open={this.state.openDialogChangeUser}
						onClose={() => {
							this.setState({ openDialogChangeUser: false })
						}}
						onSaveItem={this.setUserLogger}
						isSaving={this.props.isSaving}
						isMessage={true}
						saveButtonTranslate={'common.generated'}
					/>
				)}

				{/** Dialog to active user block by expiration days ingresed in portal */}
				{this.state.openDialogActive && (
					<AddCommonItem
						title={<Translate id='users.activeUserExpirated' />}
						message={
							<div>
								<Translate id='common.messageActivated' />
								{this.state.userNameActive}
								<Translate id='common.messageActivated2' />
							</div>
						}
						open={this.state.openDialogActive}
						onClose={() => {
							this.setState({ openDialogActive: false })
						}}
						onSaveItem={this.setUserActived}
						isSaving={this.props.isSaving}
						isMessage={true}
						saveButtonTranslate={'common.activated'}
					/>
				)}

				{/** Dialog to active all users blocked by expiration days ingresed in portal */}
				{this.state.openDialogActiveAll && (
					<AddCommonItem
						title={<Translate id='users.activeAllUsersExpirated' />}
						message={
							<div>
								<Translate id='common.messageAllActivated' />
								{this.onPrintAllUserNames(this.props.externalUsers)}
								<Translate id='common.messageAllActivated2' />
							</div>
						}
						open={this.state.openDialogActiveAll}
						onClose={() => {
							this.setState({ openDialogActiveAll: false })
						}}
						onSaveItem={this.setAllUserActived}
						isSaving={this.props.isSaving}
						isMessage={true}
						saveButtonTranslate={'common.activated'}
					/>
				)}

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableConfiguration(isDisabled, isDisabledAdminToActive) {
	let columns = [
		{
			header: <Translate id='users.nameUser' />,
			dataSource: 'user_name',
		},
		{
			header: <Translate id='users.name' />,
			dataSource: 'full_name',
		},
		{
			header: <Translate id='users.email' />,
			dataSource: 'email',
		},
	]

	if (isDisabled) {
		columns.push({
			header: <Translate id='users.userAccess' />,
			dataSource: 'enabled',
		})
	}

	if (isDisabledAdminToActive) {
		columns.push({
			header: <Translate id='users.activeUserExpirated' />,
			dataSource: 'user_type',
		})
	}
	columns.push({
		header: '',
		dataSource: 'actions',
	})

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingUsers: state.externalUsers.get('isLoadingUsers'),
		externalUsers: state.externalUsers.get('externalUsers')
			? state.externalUsers.get('externalUsers').toJS()
			: null,
		sortCriteria: state.externalUsers.get('sortCriteria') ? state.externalUsers.get('sortCriteria').toJS() : null,
		searchQuery: state.externalUsers.get('searchQuery'),
		usersCount: state.externalUsers.get('externalUsersCount'),
		isSaving: state.externalUsers.get('isSavingUser'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		createUserData: (createUser) => {
			return dispatch(createUserData(createUser))
		},
		loadExterbalUsers: (query, orderBy, sortAscending, userEnabled, supplierToken) => {
			dispatch(loadUsersSupplierFromBackEnd(query, orderBy, sortAscending, userEnabled, supplierToken))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_EXTERNAL_USERS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_EXTERNAL_USERS_QUERY,
				query: query,
			})
		},
		updateEmailUsersSupplier: (user) => {
			return dispatch(updateEmailUsersSupplier(user))
		},
		setUserNameLoggedInSupplier: (user_name) => {
			return dispatch(setUserNameLoggedInSupplier(user_name))
		},
		activeUserSupplierExpiredToSingPortal: (userId) => {
			return dispatch(activeUserSupplierExpiredToSingPortal(userId))
		},
		activeAllUserSupplierExpiredToSingPortal: (allUsersId) => {
			return dispatch(activeAllUserSupplierExpiredToSingPortal(allUsersId))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	bottnText: {
		fontSize: 11,
		border: '1px solid rgba(191, 12, 61, 0.5)',
	},
})

export default withRouter(
	withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(UsersSupplier))
)
