import axios from 'axios'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'
import { underScoreToTitleCase } from '../helpers/StringHelper'
import { descriptionStatus, backgroundColorTableStatus } from '../helpers/SparePartPriceChangeHelper'
import { COLOR_STATUS_ACEPTED, COLOR_STATUS_REJECTED } from '../helpers/StatusColorConstants'
import { sparePartPriceChangeRequestStatus } from '../helpers/AppConstants'
import { supplierData, internalCompanyData } from '../helpers/BasePdfData'

export const IS_SAVING = 'IS_SAVING'
export const ADD_REQUEST = 'ADD_REQUEST'
export const IS_LOADING_LIST_PRICES = 'IS_LOADING_LIST_PRICES'
export const GET_ALL_REQUESTS = 'GET_ALL_REQUESTS'
export const SET_REQUESTS_COUNT = 'SET_REQUESTS_COUNT'
export const CHANGE_REQUESTS_PAGINATION = 'HANGE_REQUESTS_PAGINATION'
export const CHANGE_REQUEST_SORT_CRITERIA = 'CHANGE_REQUEST_SORT_CRITERIA'
export const CHANGE_REQUEST_QUERY = 'CHANGE_REQUEST_QUERY'
export const IS_LOADING_PRICE = 'IS_LOADING_PRICE'
export const SET_SPARE_PART_PRICE = 'SET_SPARE_PART_PRICE'
export const ADD_FILE_SPARE_PART_PRICE = 'ADD_FILE_SPARE_PART_PRICE'
export const REMOVE_FILE_SPARE_PART_PRICE = 'REMOVE_FILE_SPARE_PART_PRICE'
export const IS_LOADING_PRICE_SAP = 'IS_LOADING_PRICE_SAP'
export const SET_PRICES_SAP_LIST = 'SET_PRICES_SAP_LIST'
export const IS_DOWNLOAD_PRICE_SAP = 'IS_DOWNLOAD_PRICE_SAP'

function isLoadingListPrices(isLoadingList) {
	return {
		type: IS_LOADING_LIST_PRICES,
		isLoadingList: isLoadingList,
	}
}

/**
 * Action start saving information
 * @param {*} isLoading
 */
function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
		identifier: identifier,
	}
}

/**
 * Action reducer to set the requests counter
 *
 * @param {*} count
 * @returns
 */
function setRequestsCount(count) {
	return {
		type: SET_REQUESTS_COUNT,
		count: count,
	}
}
/**
 * Action load all requests for internal user
 * @param {*} sparePartPriceChangeRequests
 */
function loadAllRequests(sparePartPriceChangeRequests) {
	return {
		type: GET_ALL_REQUESTS,
		sparePartPriceChangeRequests: sparePartPriceChangeRequests,
	}
}

/**
 * Action start loading price change
 * @param {*} isLoading
 */
function isLoadingPrice(isLoading) {
	return {
		type: IS_LOADING_PRICE,
		isLoadingList: isLoading,
	}
}

function getSparePartPrice(request) {
	return {
		type: SET_SPARE_PART_PRICE,
		request: request,
	}
}

function addFile(files) {
	return {
		type: ADD_FILE_SPARE_PART_PRICE,
		files,
	}
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName) {
	return {
		type: REMOVE_FILE_SPARE_PART_PRICE,
		fileName,
	}
}

function isLoadingPriceSAP(isLoadingSAP) {
	return {
		type: IS_LOADING_PRICE_SAP,
		isLoadingSAP,
	}
}

function setPricesSAPList(prices) {
	return {
		type: SET_PRICES_SAP_LIST,
		prices: prices,
	}
}

function isDownlodingPrices(isLoading) {
	return {
		type: IS_DOWNLOAD_PRICE_SAP,
		isDownloadPrices: isLoading,
	}
}

/** Action to get all spare part price change requests */
export function GetAllSparePartPriceChangeRequests(queryParameters) {
	return function (dispatch, getState) {
		dispatch(isLoadingListPrices(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let supplierToken = undefined
		if (getState().oidc.user && getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechange/requests`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			Page: queryParameters && queryParameters.pageNumber ? parseInt(queryParameters.pageNumber) + 1 : 1,
			RowsPerPage: queryParameters && queryParameters.rowsPerPage ? queryParameters.rowsPerPage : 20,
			SortBy: queryParameters && queryParameters.sortBy ? queryParameters.sortBy : 'Folio',
			SortAscending:
				queryParameters && queryParameters.sortDirection
					? queryParameters.sortDirection === 'asc'
						? false
						: true
					: false,
			Query: queryParameters && queryParameters.query ? queryParameters.query : '',
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let mappedRequests = serverResponse.data.map((request) => {
						request.color_status_table = backgroundColorTableStatus(request.status)
						request.status_description = descriptionStatus(request.status)
						return request
					})
					dispatch(loadAllRequests(mappedRequests))
					dispatch(isLoadingListPrices(false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingListPrices(false))
				console.error('Error getting the spare part price change requests', error.response)
			})
	}
}

/**
 * Action to add spare part price change request
 * @param {*} item
 */
export function addRequest(notes) {
	return (dispatch, getState) => {
		dispatch(isSavingCommon(true, ADD_REQUEST))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplier = getState().suppliers.get('supplier')
		let supplierToken = getState().oidc.user.profile.company_token
		let data = {
			CompanyToken: selectedCompany.get('token'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			SupplierToken: supplierToken,
			SupplierNumber: getState().oidc.user.profile.company_code,
			SupplierName: supplier.get('name'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			CreateEmail: user.profile.email,
			Notes: notes.comments,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechange`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, ADD_REQUEST))
					return Promise.resolve(serverResponse.data.token)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, ADD_REQUEST))
				console.error('Error adding a spare part price change request', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function deleteRequest(token) {
	return (dispatch, getState) => {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechange/${token}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to load request price change', error.response)
				dispatch(isSavingCommon(false))
				return Promise.reject()
			})
	}
}

/**
 * Get proveduries's count
 *
 * @export
 * @returns
 */
export function getRequestsCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().sparePartPriceChange.get('sparePartPriceChangeCount')
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierToken = undefined
		if (getState().oidc.user && getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechange/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setRequestsCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the provedure's count", error.response)
			})
	}
}

export function loadRequestSparePartPriceChangeFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingPrice(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechange/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.materials.map((item) => {
						item.color_status_table = backgroundColorTableStatus(item.status)
						if (
							item.status === sparePartPriceChangeRequestStatus.IS_ACCEPTED ||
							item.status === sparePartPriceChangeRequestStatus.UPDATE_SAP
						)
							item.custom_color = COLOR_STATUS_ACEPTED
						if (
							item.status === sparePartPriceChangeRequestStatus.IS_REJECTED ||
							item.status === sparePartPriceChangeRequestStatus.ERROR_SAP
						)
							item.custom_color = COLOR_STATUS_REJECTED
						return item
					})
					dispatch(getSparePartPrice(serverResponse.data))
					dispatch(isLoadingPrice(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to load request price change', error.response)
				dispatch(isLoadingPrice(false))
				return Promise.reject()
			})
	}
}

export function changeStatusFromRequest(token, status) {
	return (dispatch, getState) => {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechange/${token}/status/${status}`
		return axios
			.put(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.materials.map((item) => {
						item.color_status_table = backgroundColorTableStatus(item.status)
						if (
							item.status === sparePartPriceChangeRequestStatus.IS_ACCEPTED ||
							item.status === sparePartPriceChangeRequestStatus.UPDATE_SAP
						)
							item.custom_color = COLOR_STATUS_ACEPTED
						if (
							item.status === sparePartPriceChangeRequestStatus.IS_REJECTED ||
							item.status === sparePartPriceChangeRequestStatus.ERROR_SAP
						)
							item.custom_color = COLOR_STATUS_REJECTED
						return item
					})
					dispatch(getSparePartPrice(serverResponse.data))
					dispatch(isSavingCommon(false))
				}
			})
			.catch((error) => {
				console.error('Error to load request price change', error.response)
				dispatch(isSavingCommon(false))
				return Promise.reject(error.response)
			})
	}
}

export function updateMaterialToChangePrice(itemToken, requestToken, materialModel) {
	return (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechange/materials`
		let data = {
			MaterialToken: itemToken ? itemToken : '',
			RequestToken: requestToken ? requestToken : '',
			ToChange: materialModel.change_price ? materialModel.change_price : false,
			NewUmb: materialModel.newUmb ? materialModel.newUmb : '',
			NewPrice: materialModel.newPrice ? materialModel.newPrice : 0.0,
			NewCurrency: materialModel.newCurrency ? materialModel.newCurrency : '',
			Comments: materialModel.comments ? materialModel.comments : '',
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(getSparePartPrice(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load request price change', error.response)
				return Promise.reject()
			})
	}
}

export function acceptRejectMaterialInList(requestToken, itemToken, status, comments) {
	return (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechange/${requestToken}/accepted`
		let data = {
			MaterialToken: itemToken ? itemToken : '',
			RequestToken: requestToken ? requestToken : '',
			Status: status,
			Comments: comments,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.materials.map((item) => {
						item.color_status_table = backgroundColorTableStatus(item.status)
						if (
							item.status === sparePartPriceChangeRequestStatus.IS_ACCEPTED ||
							item.status === sparePartPriceChangeRequestStatus.UPDATE_SAP
						)
							item.custom_color = COLOR_STATUS_ACEPTED
						if (
							item.status === sparePartPriceChangeRequestStatus.IS_REJECTED ||
							item.status === sparePartPriceChangeRequestStatus.ERROR_SAP
						)
							item.custom_color = COLOR_STATUS_REJECTED
						return item
					})
					dispatch(getSparePartPrice(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load request price change', error.response)
				return Promise.reject()
			})
	}
}

export function updateSparePartPriceChange(requestToken, property, propertyData) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechange/${requestToken}/save`
		let data = {
			[underScoreToTitleCase(property)]: propertyData,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.materials.map((item) => {
						item.color_status_table = backgroundColorTableStatus(item.status)
						if (
							item.status === sparePartPriceChangeRequestStatus.IS_ACCEPTED ||
							item.status === sparePartPriceChangeRequestStatus.UPDATE_SAP
						)
							item.custom_color = COLOR_STATUS_ACEPTED
						if (
							item.status === sparePartPriceChangeRequestStatus.IS_REJECTED ||
							item.status === sparePartPriceChangeRequestStatus.ERROR_SAP
						)
							item.custom_color = COLOR_STATUS_REJECTED
						return item
					})
					dispatch(getSparePartPrice(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load sparepartpricechange', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to upload files to sparepartpricechange
 * @param {*} requestToken
 * @param {*} files
 * @returns
 */
export function uploadSparePartPriceFiles(requestToken, files) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechange/${requestToken}/files`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload sparepartpricechange file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files to sparepartpricechange
 * @param {*} quoteToken
 * @param {*} fileName
 * @returns
 */
export function removeSparePartPriceFiles(requestToken, fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechanges/${requestToken}/files`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFile(fileName))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to upload sparepartpricechange file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to create pdf
 * @param {*} requestToken
 * @param {*} supplier
 * @returns
 */
export function generatedPDFRequest(requestToken, partnership, supplier) {
	return (dispatch, getState) => {
		dispatch(isSavingCommon(true))

		let user = getState().oidc.user
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let data = {
			Supplier: supplierData(supplier),
			Company: internalCompanyData(companyInformation),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Incoterm: partnership ? partnership.incoterm : '',
			Currency: partnership ? partnership.order_currency : '',
			ConditionCredit: partnership ? partnership.condition_credit_full : '',
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechanges/${requestToken}/pdf`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				dispatch(isSavingCommon(false))
				if (serverResponse.status === 200) {
					var file = new Blob([serverResponse.data], { type: 'application/pdf' })
					var fileURL = URL.createObjectURL(file)
					window.open(fileURL)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error in create PDF to sparepartpricechange ', error.response)
				return Promise.reject()
			})
	}
}

export function getPricesFromSAP(supplierCode) {
	return function (dispatch, getState) {
		dispatch(isLoadingPriceSAP(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechange/${supplierCode}/prices`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setPricesSAPList(serverResponse.data))
					dispatch(isLoadingPriceSAP(false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingPriceSAP(false))
				console.error('Error getting prices sap by supplier list', error.response)
			})
	}
}

/**
 * Action to donwload prices by supplier from SAP from backend
 */
export function donwlodPricesFromSAP() {
	return function (dispatch, getState) {
		dispatch(isDownlodingPrices(true))
		let prices = getState().sparePartPriceChange.get('prices')
			? getState().sparePartPriceChange.get('prices').toJS()
			: null
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sparepartpricechanges/donwlod`
		let data = {
			Materials: prices,
		}
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDownlodingPrices(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Precios_por_proveedor.xlsx`
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDownlodingPrices(false))
				console.error('Error donwload prices by supplier list', error.response)
			})
	}
}
