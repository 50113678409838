import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import DataTable from '../common/DataTable'
import OptionsMenu from '../common/OptionsMenu'
import DonwloadReportDialog from '../common/DonwloadReportDialog'
import Toaster from '../common/Toaster'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import SamplesIcon from '@material-ui/icons/UnarchiveTwoTone'
import ArchiveDownloadIcon from '@material-ui/icons/Archive'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'

/** Import section actions */
import {
	loadSampleRequestsFromBackEnd,
	getSampleRequestsCount,
	downloadReportByDateRange,
	CHANGE_SAMPLE_REQUESTS_SORT_CRITERIA,
	CHANGE_SAMPLE_REQUESTS_QUERY,
	CHANGE_SAMPLE_REQUESTS_PAGINATION,
} from '../../store/samplerequests/SampleRequestAction'
import { showSuccessToaster, showErrorToaster } from '../../store/helpers/ToasterHelpers'

/**
 * Sample request view
 *
 * @class sample
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance of sample request
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			itemToken: null,
			showMenu: true,
			openModalReport: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadSampleRequests(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
			this.props.getSampleRequestCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadSampleRequests()
			this.props.getSampleRequestCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadSampleRequests(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof Index
	 */
	handleClickOptionsMenu(token) {
		this.setState({
			itemToken: token,
		})
	}

	/**
	 * Handle on close optons menu to set void item token in state
	 *
	 * @memberof Index
	 */
	handleCloseOptionsMenu() {
		if (!this.state.openModalReport) {
			this.setState(
				{
					itemToken: '',
					showMenu: false,
				},
				() => {
					this.setState({ showMenu: true })
				}
			)
		}
	}

	/**
	 * Open modal for set report by mont
	 *
	 * @memberof Index
	 */
	handleOpenModal() {
		this.setState({
			openModalReport: true,
		})
	}

	/**
	 * Close modal to report by month
	 *
	 * @memberof Index
	 */
	handleCloseModal() {
		this.setState(
			{
				openModalReport: false,
			},
			() => this.handleCloseOptionsMenu()
		)
		this.setState({ startDate: '', endDate: '' })
	}

	/**
	 *Download report of sample request by month
	 *
	 * @memberof Index
	 */
	onDownloadReportByRangeDate(startDate, endDate) {
		this.props
			.downloadReportByDateRange(startDate, endDate)
			.then(() => {
				this.setState(showSuccessToaster('common.addCorrectly'))
				this.handleCloseModal()
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	/**
	 *Render action button in card sample's report
	 *
	 * @memberof Index
	 */
	renderAction() {
		var options = []
		options.push({
			itemClick: () => this.handleOpenModal(),
			tooltipTranslation: <Translate id='samplesrequest.downloadReportByMonth' />,
			menuItemIcon: <ArchiveDownloadIcon color='secondary' />,
			isLoading: this.props.isLoadingSample,
		})
		if (this.state.showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === 'item.token'}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingSampleRequest === true) {
			return <LoadingPage />
		} else if (this.props.samplerequests === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.samplerequests}
					configuration={TableConfigurations(IsCurrentActiveDirectoryUser(this.props.user))}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(sample) => {
						this.props.history.push(`/samplerequests/${sample.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.samplesCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get samples counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getSamplesCounterMessage() {
		if (this.props.samplerequests) {
			let totalSamples = this.props.samplerequests.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalSamples}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.samplesCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof Samples
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<SamplesIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>

							{IsCurrentActiveDirectoryUser(this.props.user) && this.renderAction()}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.sample' />{' '}
						</Typography>
					}
					subheader={this.getSamplesCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to download report */}
				<DonwloadReportDialog
					title={<Translate id='samplesrequest.downloadReportByMonth' />}
					open={this.state.openModalReport}
					onClose={this.handleCloseModal}
					isSaving={this.props.isLoadingSample}
					onDonwloadItems={this.onDownloadReportByRangeDate}
				/>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableConfigurations(isUserInternal) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='quotes.origin' />,
			dataSource: 'origin',
			isSortable: true,
		},
		{
			header: <Translate id='memos.developmentEngineer' />,
			dataSource: 'developer_engineer',
			isSortable: true,
		},
	]

	if (isUserInternal) {
		columns.push({
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		})
	}

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingSampleRequest: state.samplerequests.get('isLoadingSampleRequest'),
		samplerequests: state.samplerequests.get('samplerequests')
			? state.samplerequests.get('samplerequests').toJS()
			: null,
		sortCriteria: state.samplerequests.get('sortCriteria') ? state.samplerequests.get('sortCriteria').toJS() : null,
		searchQuery: state.samplerequests.get('searchQuery'),
		page: state.samplerequests.get('page'),
		rowsPerPage: state.samplerequests.get('rowsPerPage'),
		samplesCount: state.samplerequests.get('samplesCount'),
		translate: getTranslate(state.localize),
		isLoadingSample: state.samplerequests.get('isLoadingSample'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSampleRequests: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadSampleRequestsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_SAMPLE_REQUESTS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_SAMPLE_REQUESTS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_SAMPLE_REQUESTS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getSampleRequestCount: () => {
			dispatch(getSampleRequestsCount())
		},
		downloadReportByDateRange: (startDate, endDate) => {
			return dispatch(downloadReportByDateRange(startDate, endDate))
		},
	}
}

const styles = (theme) => ({
	avatar: {},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
