import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { toSafeJS } from "../../store/helpers/ImmutableHelper";

export const ModuleRoute = ({children, ...rest}) => {

  const selectedCompany = useSelector((state) =>
    toSafeJS(state.profile.get("selectedCompany"))
  );

  if (selectedCompany && !selectedCompany.company_modules[rest.moduleName] === true) {
    return  <Redirect to={{ pathname: "/" }} />
  }
  else {
    return <Route {...rest} />
  }
  
};

export default ModuleRoute;
