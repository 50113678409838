
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { ListItemIcon,CardHeader, ListItemText, ListItem, List, Fab} from '@material-ui/core';
import LinkMaterial from '@material-ui/core/Link';
import CalendarActiveIcon from '@material-ui/icons/EventAvailableOutlined';
import FolioIcon from '@material-ui/icons/Filter9Outlined';
import SupplierIcon from '@material-ui/icons/ShoppingCartTwoTone';
import DocumentsIcon from '@material-ui/icons/DoneAll';
import Avatar from '@material-ui/core/Avatar';
import AddtIcon from '@material-ui/icons/AddTwoTone';
import Tooltip from "@material-ui/core/Tooltip";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LoadingPage from '../common/LoadingPage';
import Toaster from '../common/Toaster';
import DataTable from '../common/DataTable';
import AddContractModal from './Components/AddContractModal'
import AddCommonItem from '../common/AddCommonItem';

/** Import action section */
import { canBeModuleDocumentsEnvSupplier } from '../../store/helpers/RolesHelper';
import { converListToArray } from '../../store/helpers/ListHelper'
import { loadSupplierContractor, addSupplierContractDetail,
    removeContractDetail} from '../../store/supplierconstracts/SupplierConstractAction';
import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers';
import {getColorStatusContract, getDescriptionStatusContract} from '../../store/helpers/DocumentsHelper'

/**
 * Container show Official notice
 */
class ShowDocument extends Component {

    /**
     * Create an instance Official notice
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openAddContract: false,
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let contractorToken = this.props.match.params.token;
        this.props.loadSupplierContractor(contractorToken);
    }


    /**
     * Render item in list of contracte detail
     *
     * @param {*} idTranslation
     * @param {*} value
     * @param {*} icon
     * @param {*} isLink
     * @param {*} isButton
     * @returns
     * @memberof Show
     */
    renderItemList(idTranslation, value, icon) {
        return ( <ListItem divider  key={idTranslation} >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText 
                    secondary={<>
                            <Typography component="span" variant="body2" className={this.props.classes.inline} color="textPrimary">
                                <Translate id={idTranslation} />
                            </Typography>
                            { (": " + value)}
                        </>
                    }
                />
            </ListItem>
        )
    }

    /**
     * onRenderCellItem
     * @param {*} dataSource 
     * @param {*} item 
     */
    onRenderCellItem(dataSource, item) {
        if (dataSource === "status") {
            return (
                <Tooltip title={getDescriptionStatusContract(item.status)}>
                    <Avatar data-cy='avatar' style={{backgroundColor:getColorStatusContract(item.status)}} >
                        <DocumentsIcon />
                    </Avatar>
                </Tooltip>)
        }
        if (dataSource === "name") {
            return (<LinkMaterial key={item.token} component={'a'} target="_blank" color="primary" 
                    href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/Documents/SupplierContract/${this.props.supplierContractor.token}/${item.token}.pdf`}>
                    <DownloadIcon style={{marginTop:"-7px"}} />
                    {" "}{item.name}
                </LinkMaterial>
            )
        }
        if(dataSource === "notes"){
            return (<div style={{width:"280px"}}>
                {item.notes}
            </div>)
        }
        if(dataSource === "token"){
            if(!item.is_contract_main){
                return(
                    <Tooltip title={<Translate id="common.delete" />}>
                        <DeleteOutlinedIcon onClick={() => {this.onRemoveItem(item.token)}} 
                        style={{zIndex: '990', cursor: "pointer" }} color="error" />
                </Tooltip>
                )
            }
            else{
                return ("-")
            }
        }
    }

        /**
     * onRemoveItem
     */
    onRemoveItem(itemId) {
        this.setState({
            openRemoveItem: true,
            itemId: itemId,
        })
    }

    /**
     * onRemoveContract
     */
    onRemoveContract() {
        this.props.removeContractDetail(this.props.supplierContractor.token, this.state.itemId)
        .then(() => {
            this.setState({
                openRemoveItem: false,
                itemId: "",
            })
        }).catch(() => {
            this.setState(showErrorToaster("common.errorToAdd"));
            return Promise.reject();
        });
    }

    /**
     * openAddContract
     * @param {*} openAddContract 
     */
   handleShowAddDialog(openAddContract) {
        this.setState({
            openAddContract,
        })
    }

       /**
     * Handle event to add action, call redux action to save data
     * @param {*} data 
     */
    onCreateNewContract(data) {
        return this.props.addSupplierContractDetail(data, this.props.supplierContractor.token)
            .then(() => {
                this.setState(showSuccessToaster("common.addCorrectly"));
                this.setState({openAddContract: false,})
                return Promise.resolve('Ok');
            })
            .catch(error => {
                this.setState(showErrorToaster("common.errorToAdd"));
                return Promise.reject();
            })
    }


    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.supplierContractor !== null) {
            const {supplierContractor } = this.props;
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Paper>
                            <List>
                                {this.renderItemList("common.folio", supplierContractor.folio, <FolioIcon />)}
                                {this.renderItemList("common.supplier", supplierContractor.supplier_name, <SupplierIcon />)}
                                {this.renderItemList("common.creationDate", supplierContractor.creation_date, <CalendarActiveIcon />)}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {<Translate id="constracts.histoyDocuments" />}
                        </Typography>
                        <DataTable
                            data={supplierContractor.contracts_detail}
                            configuration={TableConfiguration(canBeModuleDocumentsEnvSupplier(this.props.userRolesByCompany))}
                            onRenderCellItem={this.onRenderCellItem}
                        />
                    </Grid>
                </Grid>
            )
        }
    }

    render() {
        const { classes, supplierContractor } = this.props;
        return (
            <Card>
                 <CardHeader
                    avatar={
                        <Avatar style={{backgroundColor:getColorStatusContract(supplierContractor && supplierContractor.status)}}>
                            <DocumentsIcon />
                        </Avatar>
                    }
                    action={
                        <div className={classes.actionsToolbar}>
                             {canBeModuleDocumentsEnvSupplier(this.props.userRolesByCompany) && <Tooltip title={<Translate id="constracts.addDocument" />}>
                             <Fab size="small" color="primary"
                                onClick={() => { this.handleShowAddDialog(true) }}>
                                <AddtIcon />
                            </Fab></Tooltip>}
                        </div>
                    }
                    title={<Typography variant="button">{<Translate id="constracts.documentTitle" />}</Typography>}
                    subheader={supplierContractor && supplierContractor.supplier_name}
                />

                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {/** Dialog to add other contactor*/}
                <AddContractModal
                    text={this.props.translate('dropzone.dropzoneBaseText')}
                    open={this.state.openAddContract}
                    onClose={() => { this.handleShowAddDialog(false) }}
                    createNewContract={this.onCreateNewContract}
                    isSaving={this.props.isSaving}
                    user={this.props.currentUser}
                />

                {/** Dialog to remove */}
                < AddCommonItem
                    title={<Translate id="constracts.deleteDocument" />}
                    message={<Translate id="constracts.messsageDeletedocument" />}
                    open={this.state.openRemoveItem}
                    onClose={() => { this.setState({ openRemoveItem: false }) }}
                    onSaveItem={this.onRemoveContract}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                    saveButtonTranslate={"common.delete"}
                />

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        )
    }

}

function TableConfiguration(isDisabled) {
    let columns = [
        {
            header: <Translate id="officialNotices.status" />,
            dataSource: 'status',
        },{
            header: <Translate id="constracts.documentTitle" />,
            dataSource: 'name'
        },{
            header: <Translate id="officialNotices.activationDate" />,
            dataSource: 'start_date'
        },{
            header: <Translate id="officialNotices.expirationDate" />,
            dataSource: 'end_date'
        },{
            header: <Translate id="common.createBy" />,
            dataSource: 'creator_name'
        },{
            header: <Translate id="common.comments" />,
            dataSource: 'notes'
        }]
        if (isDisabled) {
            columns.push({
                header: "",
                dataSource: 'token'
            });  
        }
    return ({ columns });
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let supplierContractor = state.contracts.get("supplierContractor");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        currentUser: user,
        isLoading: state.contracts.get('isLoadingContractor'),
        supplierContractor: supplierContractor ? supplierContractor.toJS() : null,
        isSaving: state.contracts.get('isSavingContract'),
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadSupplierContractor: (token) => {
            return dispatch(loadSupplierContractor(token));
        },
        addSupplierContractDetail:(contract, contractToken) =>{
            return dispatch(addSupplierContractDetail(contract, contractToken));
        },
        removeContractDetail:(contractToken, detailToken) => {
            return dispatch(removeContractDetail(contractToken, detailToken));
        }
    }
}

const styles = theme => ({
    actionsToolbar: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    margin: {
        margin: theme.spacing.unit * 2,
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ShowDocument))));