///Import react section
import React from 'react';
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/** Material-UI imports section */
import {Typography, CardContent} from "@material-ui/core";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";

/** Import component section */
import { COLOR_STATUS_DANGER, COLOR_STATUS_SUCCESS,
     BACKGROUND_COLOR_GRAY_BIDDING} from '../../../store/helpers/StatusColorConstants';

/**
 * Component load list
 * @param {*} props 
 */
const PercentageComponent = (props) => {
    const {classes, totalPercentage, title, isApproved, isSummary} = props

    return (
        <div className={isSummary ? classes.rootSummary : classes.root}>
            <div className= {isSummary ? classes.cardContentSummary : classes.cardContent}>
                <CardContent className={isSummary ? classes.contentSummary :classes.content}>
                    <div className={classes.display}>
                    { isSummary ? <CircularProgressbarWithChildren className={classes.percentSummary}
                        value={totalPercentage}
                        styles={buildStyles({
                        pathColor: isApproved ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER,
                        })}>                  
                        <Typography style={{fontSize: '18px', marginTop: '10px'}}>{`${totalPercentage}%`}</Typography>
                        </CircularProgressbarWithChildren>

                        : <Typography variant="h5" className={ classes.percent}>{totalPercentage}%</Typography> }
                        
                        <div className={classes.line} style={{backgroundColor: isApproved ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER }}></div>
                        <div className={isSummary ? classes.titleSummary : classes.title}>
                            <Typography variant="caption" style={{color: isApproved ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER, }}>
                                {title}
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </div>
        </div>
    )
}
PercentageComponent.propTypes = {
    totalPercentage: PropTypes.number.isRequired,
    isSummary: PropTypes.bool
};
  
PercentageComponent.defaultProps = {
    isSummary: false,
};

const styles = ((theme) => ({
    root:{
        display: 'flex',
        height: "90px",
    },
    cardContent:{
        display: 'flex',
        padding: 4,
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
        marginBottom: "10px",
        paddingRight: "10px",
        borderRadius: '15px',
    },
    content:{
        flex: 'auto',
        marginLeft: 8,
        textAlign: 'center',
        borderRadius: '15px',
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
    },
    percent: {
        width: "90px",
    },
    display:{
        display: 'flex',
    },
    line: {
        width: '5px',
        height: 'auto',
        margin: 2
    },
    title: {
        flex: '3 1 0',
        marginTop: '10px',
        marginBottom: '10px',
        fontSize: "10"
    },
    /*Style Card for summary */
    rootSummary: {
        display: 'flex',
        justifyContent: "center",
        height: "120px",
    },
    cardContentSummary:{
        display: 'flex',
        padding: 4,
        marginBottom: "15px",
        height: '120px',
        width: '100%',
    },
    contentSummary:{
        flex: 'auto',
        textAlign: 'center',
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
        borderRadius: '15px',
    },
    percentSummary: {
        width: '80px',
        marginRight: '10px',
    },
    titleSummary: {
        marginTop: '40px',
        marginLeft: '4px',
        fontSize: "10"
    },
}))


export default withRouter(
    withStyles(styles, { withTheme: true })(PercentageComponent)
);
