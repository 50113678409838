import React from 'react'
import DataTable from '../../../common/DataTable'
import { Typography, withStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import { Translate } from 'react-localize-redux'
import { TransportTendeStatus } from '../../../../store/helpers/AppConstants'
const MaterialsTable = (props) => {
	const { classes, data, deleteMaterial, transportTenderdetail } = props

	const configTable = {
		columns: TableConfiguration().columns.map((item) => {
			let itemRender = { ...item }
			if (itemRender.header) itemRender.header = <Translate id={itemRender.header} />
			return itemRender
		}),
	}

	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'Code') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item.Code ? item.Code : '-'}
				</Typography>
			)
		}
		if (dataSource === 'Quantity') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item.Quantity ? item.Quantity : '-'}
				</Typography>
			)
		}
		if (
			dataSource === 'options' &&
			transportTenderdetail &&
			(transportTenderdetail.Status === TransportTendeStatus.DRAFT ||
				transportTenderdetail.Status === TransportTendeStatus.SENT_TO_CARRIER)
		) {
			return (
				<div>
					<DeleteIcon className={classes.deleteColor} onClick={() => deleteMaterial(item.Id)} />
				</div>
			)
		}
	}
	return (
		<div>
			<DataTable data={data} configuration={configTable} onRenderCellItem={onRenderCellItem} />
		</div>
	)
}
function TableConfiguration() {
	let columns = [
		{
			header: 'carrierTenders.deals.material.tableMaterial.header.code',
			dataSource: 'Code',
		},
		{
			header: 'carrierTenders.deals.material.tableMaterial.header.quantity',
			dataSource: 'Quantity',
		},
		{
			header: 'carrierTenders.deals.material.tableMaterial.header.description',
			dataSource: 'Description',
		},
		{
			header: 'carrierTenders.deals.material.tableMaterial.header.options',
			dataSource: 'options',
			isSortable: true,
		},
	]

	return { columns }
}
const styles = (theme) => ({
	deleteColor: {
		color: '#757575',
	},
})
export default withStyles(styles, { withTheme: true })(MaterialsTable)
