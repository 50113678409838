import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { Toolbar, Typography, TextField } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import autoBind from 'auto-bind'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';

class DialogAddUser extends Component {
    /**
     * Create an instance of Dialog user
     * @param {*} props
     * @memberof DialogAddUser
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            name: "",
        }
    }


    /**
     * Function to update property of text field in state
     *
     * @param {*} data
     * @memberof DialogAddUser
     */
    updateProperty(data) {
        this.setState({
            [data.id]: data.value,
        })
    }

    /**
     * Function to trigger blur event
     * 
     * @param {*} event
     * @memberof DetailsCard
     */
    onKeyDown(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    /**
     * Validate if Save button is enable 
     * to enable, required properties must has data
     *
     * @returns
     * @memberof DialogAddUser
     */
    canBeSaved() {
        if (!IsNullOrEmpty(this.state.name))
            return true;
        return false;
    }

    /**
     * Handle close or cancel to clean local estate and call prop function after update
     *
     * @memberof DialogAddUser
     */
    handleCloseCancel() {
        this.setState({ name: '' }, () => this.props.onCloseCancel('showAddUserDialog'))
    }

    /**
     * Handle OnSave, if response is 'Ok' then clean state
     *
     * @memberof DialogAddUser
     */
    handleSave() {
        this.props.onSave(this.state.name).then(value => {
            if (value === 'Ok')
                this.setState({ name: '' })
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.showDialog}
                onClose={() => { this.handleCloseCancel() }}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            {<Translate id="qualityCatalogs.addUser" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <div>
                        {!this.canBeSaved() &&
                            <Typography variant="caption" color='error'>
                                {<Translate id="common.requiredFields" />}
                            </Typography>
                        }
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => { this.handleCloseCancel() }}
                            onClose={() => { this.handleCloseCancel() }}
                            onSaveItem={() => this.handleSave()}
                            isSaving={this.props.isSaving}
                            canBeSaved={this.canBeSaved()}
                        />
                    </div>
                } >
                <form className={classes.ediitForm}>
                    <TextField id="name" fullWidth autoFocus required
                        className={classes.textField}
                        label={<Translate id="qualityCatalogs.name" />}
                        value={this.state.name ? this.state.name : ''}
                        onBlur={(event) => this.updateProperty(event.target)}
                        onChange={(event) => this.updateProperty(event.target)}
                        onKeyDown={(event) => this.onKeyDown(event)}
                    />
                </form>
            </Dialog>
        );
    }
}

DialogAddUser.propTypes = {
    showDialog: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onCloseCancel: PropTypes.func.isRequired
};

export default DialogAddUser;