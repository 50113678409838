import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from 'react-localize-redux';

/** Material-UI imports section */
import Grid from '@material-ui/core/Grid';

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import Toaster from '../../common/Toaster';
import InformationCreate from './InformationCreate';
import SuppliersCard from './SuppliersCard';

import { showSuccessToaster, showErrorToaster, showInfoToaster } from '../../../store/helpers/ToasterHelpers';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
import { createQuestionnaire } from '../../../store/questionnaires/QuestionnairesAction';

class Create extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            questionnaire: {
                suppliers: [],
                comments: '',
                questionnairetype: '',
                scheduledDate: null
            }
        }
    };


    /**
     * Function to update comment
     */
    updatePropertyComment(property, value) {
        let questionnaireUpdate = { ...this.state.questionnaire };
        questionnaireUpdate[property] = value;
        this.setState({
            questionnaire: questionnaireUpdate
        })
    }

    updateSuppliersList(value) {
        let questionnaireUpdate = { ...this.state.questionnaire };
        if (value !== null && value !== undefined) {
            if (!questionnaireUpdate['suppliers'].find(supplier => { return supplier.token === value.token })) {
                let supplier = { name: value.full_name, token: value.token, SupplierName: value.name, SupplierToken: value.token, SupplierCode: value.code };
                questionnaireUpdate['suppliers'] = questionnaireUpdate['suppliers'].concat(supplier);
                this.setState({
                    questionnaire: questionnaireUpdate
                })
            } else {
                this.setState(showInfoToaster("common.repeatedSelection"));
            }
        }
    }

    removeSupplierFromList(supplierToken) {
        let questionnaireUpdate = { ...this.state.questionnaire };
        let newSuppliersList = questionnaireUpdate['suppliers'].filter(supplier => {
            return supplier.token !== supplierToken
        })
        questionnaireUpdate['suppliers'] = newSuppliersList;
        this.setState({
            questionnaire: questionnaireUpdate
        })
    }

    /**
   * Can be add item 
   */
    canBeSaved() {
        let questionnaire = this.state.questionnaire;
        if (questionnaire.suppliers.length <= 0)
            return false;
        if (questionnaire.questionnairetype === "")
            return false;
        if (questionnaire.scheduledDate === null)
            return false
        else
            return true;
    }

    /**
     * Function to send Questionnair
     */
    sendQuestionnairToSuppliers() {
        this.props.createQuestionnaire(this.state.questionnaire)
            .then(() => {
                this.setState(showSuccessToaster("common.addCorrectly"));
                this.props.history.push(`/questionnaire`);
            }).catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : "common.errorToAdd"));
            });
    }

    render() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={6}>
                    <InformationCreate
                        updateProperty={this.updatePropertyComment}
                        questionnaire={this.state.questionnaire}
                        classes={this.props.classes}
                        isSaving={this.props.isSavingQuestionnaire}
                        onCreate={this.sendQuestionnairToSuppliers}
                        canBeSaved={this.canBeSaved()}
                        {...this.props}
                    />
                </Grid>

                <Grid item xs={6}>
                    <SuppliersCard
                        updateSuppliersList={this.updateSuppliersList}
                        suppliers={this.state.questionnaire.suppliers}
                        removeSupplier={this.removeSupplierFromList}
                        classes={this.props.classes}
                    />
                </Grid>

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Grid>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        isSavingQuestionnaire: state.questionnaires.get("isSavingQuestionnaire"),
        translate: getTranslate(state.localize)
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        createQuestionnaire: (questionnaire) => {
            return dispatch(createQuestionnaire(questionnaire))
        },
    }
}

const styles = theme => ({
    userAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 240,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create))));