/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

/** Language imports **/
import { Translate } from "react-localize-redux";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TextField } from "@material-ui/core";
/**Import components section*/

/**Import resources section*/
import { COLOR_STATUS_DANGER, COLOR_STATUS_SUCCESS } from '../../../store/helpers/StatusColorConstants';
import { getQuestionsCritical, getAnswerQuestion } from '../../../store/sustainability2/SustainabilityResults'

/**Import actions section */
const Recommendations = (props) => {
    const { classes, topics, canBeActiveResult } = props;
    const { updatePropertyQuestion } = props;

    /**
     * Render list topics
     */
    return (
        <div className={classes.root}>
            {topics.map((topic) => {
                let questios = getQuestionsCritical(topic.Subtopics);
                if (questios.length !== 0) {
                    return (
                        <Table key={topic.Id} >
                            <TableHead>
                                <TableRow style={{ backgroundColor: topic.ColorTopic }} className={classes.contentList}>
                                    <TableCell className={classes.listHeader}>{topic.Name}</TableCell>
                                    {<TableCell className={classes.listResponse}>{<Translate id="sustainability.Answer" />}</TableCell>}
                                    {<TableCell className={classes.listHeader}>{<Translate id="sustainability.recommendations" />}</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {questios.map((question) => {
                                    return (
                                        <TableRow key={question.Id}>
                                            <TableCell>
                                                {<Typography variant="caption">{question.Name}</Typography>}
                                            </TableCell>
                                            <TableCell>
                                                {<Typography variant="caption" className={question.PercentageSupplier === 0 ? classes.colorRed : classes.colorGrey}>
                                                    {getAnswerQuestion(question.AnswerSupplier)}
                                                </Typography>}
                                            </TableCell>
                                            <TableCell>
                                                {(question.AnswerSupplier !== '1' || question.CommnetsSupplier === null) && canBeActiveResult && <TextField
                                                    className={classes.textField}
                                                    fullWidth
                                                    defaultValue={question.Recommendation}
                                                    disabled={!canBeActiveResult}
                                                    multiline
                                                    rowsMax={3}
                                                    onBlur={(event) => updatePropertyQuestion("Recommendation", event.target.value, question.Id, question.SubTopicId, topic.Id)}
                                                    InputProps={{ classes: { input: classes.inputProps } }}
                                                />}
                                                {(question.AnswerSupplier !== '1' || question.CommnetsSupplier === null) && !canBeActiveResult && <Typography variant="caption" > {question.Recommendation}</Typography>}
                                                {question.AnswerSupplier === '1' && question.CommnetsSupplier !== null && canBeActiveResult && <TextField
                                                    className={classes.textField}
                                                    fullWidth
                                                    defaultValue={question.CommnetsSupplier}
                                                    disabled={!canBeActiveResult}
                                                    multiline
                                                    rowsMax={3}
                                                    onBlur={(event) => updatePropertyQuestion("CommnetsSupplier", event.target.value, question.Id, question.SubTopicId, topic.Id)}
                                                    InputProps={{ classes: { input: classes.inputProps } }}
                                                />}
                                                {question.AnswerSupplier === '1' && question.CommnetsSupplier !== null && !canBeActiveResult && <Typography variant="caption" > {question.CommnetsSupplier}</Typography>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    )
                }
                else {
                    return ("")
                }
            })}
        </div>
    )
};

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: "20px"
    },
    contentList: {
        height: "25px",
    },
    listHeader: {
        color: "white",
        width: "45%"
    },
    listResponse: {
        color: "white",
        width: "10%"
    },
    colorRed: {
        color: COLOR_STATUS_DANGER,
    },
    colorGrey: {
        color: COLOR_STATUS_SUCCESS,
    },
    inputProps: {
        height: 6,
        fontSize: 12
    },
});

Recommendations.propTypes = {
    /**
     * Determinate if is loading information
     */
    topics: PropTypes.array,
};

Recommendations.defaultProps = {
    topics: []
};

export default withRouter(
    withStyles(styles, { withTheme: true })(Recommendations)
);
