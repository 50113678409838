import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

/** Material-UI imports section */
import { Translate, getTranslate } from 'react-localize-redux';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemIcon, ListItemAvatar, Avatar, Tooltip, ListItemSecondaryAction } from '@material-ui/core';
import ExpedientsIcon from '@material-ui/icons/FormatListNumbered';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import PersonIcon from '@material-ui/icons/Person';
import FileIcon from '@material-ui/icons/FileCopy';
import CommentIcon from '@material-ui/icons/Comment';
import SuppliersIcon from '@material-ui/icons/LibraryBooks';
import EmailIcon from '@material-ui/icons/ContactMail';

/** Import component section */
import LoadingPage from '../common/LoadingPage';
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import { converListToArray } from '../../store/helpers/ListHelper';
import DataTable from '../common/DataTable';
import AddCommonItem from '../common/AddCommonItem';
import Toaster from '../common/Toaster';
import ShowEdit from './ShowEdit';
import FloatingButton from '../indirectqualifications/components/FloatingButton';
import SupplierDocumentsContainerTable from './components/show/SupplierDocumentsContainerTable';
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';
import { showSuccessToaster, showErrorToaster } from '../../store/helpers/ToasterHelpers';
import {
	loadExpedientSupplier,
	sendReminderToSuppliersToUpdateExpedient
} from '../../store/expedientSupplier/ExpedientSupplierAction';
import { ExpedientSupplierStatus } from '../../store/helpers/AppConstants';
import { canBeModuleExpedientViewer } from '../../store/helpers/RolesHelper'
import {GetSupplierTypeDescription, GetClassifcationType} from '../../store/helpers/ExpedientSupplier'

/**
 * Show ethic code
 */
class Show extends Component {
	/**
	 *
	 * @param {*} props
	 */
	constructor(props) {
		super(props);
		autoBind(this);
		this.state = {
			openSendReminder: false,
			openAvailableOperationsDialog: false,
			confirmPasswordConfigModal: {
				opened: false,
				item: null
			}
		};
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let expedientSupplierToken = this.props.match.params.token;
		this.props.loadExpedientSupplier(expedientSupplierToken);
	}

	/**
	 * Action to set state to open of available operations dialog
	 * @memberof
	 */
	onOpenAndClose = (openAvailable) => {
		this.setState({
			openAvailableOperationsDialog: openAvailable
		});
	};

	onConfirmPassword = (item, identifier) => {
		this.setState({
			confirmPasswordConfigModal: {
				opened: !this.state.confirmPasswordConfigModal.opened,
				item: item,
				identifier: identifier
			}
		});
	};

	/**
	 * Render item in list of official notice detail
	 *
	 * @param {*} idTranslation
	 * @param {*} value
	 * @param {*} icon
	 * @param {*} isLink
	 * @returns
	 * @memberof Show
	 */
	renderItemList(idTranslation, value, icon, isBoolean) {
		return (
			<React.Fragment>
				<ListItem key={idTranslation} divider>
					<ListItemIcon>
						<Tooltip title={<Translate id={idTranslation} />}>{icon}</Tooltip>
					</ListItemIcon>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography component="span" variant="body2" className={this.props.classes.inline} color="textPrimary">
									<Translate id={idTranslation} />
								</Typography>
								{!isBoolean ? ': ' + value : ''}
							</React.Fragment>
						}
					/>
				</ListItem>
			</React.Fragment>
		);
	}

	onRenderCellItem(dataSource, item) {
		if (dataSource === 'full_supplier') {
			return (
                <span>
                      <Link href="#" onClick={() => {this.props.history.push(`/expedientsbysupplier/${item.token}`)}} to={"#"}>
                           <Typography color="primary" gutterBottom><SuppliersIcon style={{ color: item.color_status}} /> {item.full_supplier}</Typography> 
                        </Link>
                </span>
			);
		}
		if (dataSource === 'supplier_type_description') {
            return(GetSupplierTypeDescription(item.supplier_type, item.supplier_classification))
		}
		if (dataSource === 'classification_type') {
            return(GetClassifcationType(item.classification_type))
		}
	}

	/**
	 * onClickCloseReport
	 *
	 * @param {*} item
	 * @memberof Create
	 */
	onClickSendReminderToSuppliers() {
		this.setState({
			openSendReminder: true
		});
	}

	/**
	 * onResendExpedientToSupplier
	 * @param {*} comments
	 */
	onSendReminderSupplier() {
		this.props
			.sendReminderToSuppliersToUpdateExpedient(this.props.match.params.token)
			.then(() => {
				this.setState({ openSendReminder: false });
				this.setState(showSuccessToaster('common.reminderSendCorrectly'));
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'));
			});
	}

	/**
	 * Get the card content
	 *
	 * @memberof Show
	 */
	getCardContent(availableOperations) {
		if (this.props.isLoadingExpedientSupplier === true) {
			return <LoadingPage />;
		} else if (this.props.expedientSupplier !== null) {
			const { classes, expedientSupplier } = this.props;
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Paper>
							<List>
								<ListItem divider>
									<ListItemAvatar>
										<Avatar data-cy="avatar" className={!expedientSupplier.is_closed ? classes.avatar : ''}>
											<ExpedientsIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={
											<Typography variant="h6" className={classes.inline} color="textPrimary">
												{expedientSupplier.formated_folio} | <Translate id="expedientSupplier.expedientTitle" />
											</Typography>
										}
									/>
									{availableOperations.length !== 0  && !canBeModuleExpedientViewer(this.props.userRolesByCompany) && (
										<ListItemSecondaryAction>
											{<FloatingButton onOpenAndCloseDialog={() => this.onOpenAndClose(true)} />}
										</ListItemSecondaryAction>
									)}
								</ListItem>
								{this.renderItemList('common.status', expedientSupplier.status, <ExpedientsIcon />)}
								{this.renderItemList('common.creationDate', expedientSupplier.creation_date, <CalendarIcon />)}
								{this.renderItemList('common.createBy', expedientSupplier.created_by, <PersonIcon />)}
								{this.renderItemList('expedientSupplier.nameExpedient', expedientSupplier.name, <FileIcon />)}
								{expedientSupplier && expedientSupplier.comments && this.renderItemList('common.comments', expedientSupplier.comments, <CommentIcon />)}
								{!expedientSupplier.is_closed && expedientSupplier.actual_status !== ExpedientSupplierStatus.DRAFT && !canBeModuleExpedientViewer(this.props.userRolesByCompany) &&(
									<ListItem key={'downloadExpedientZip'} divider>
										<ListItemText
											secondary={
												<Link color="primary" onClick={this.onClickSendReminderToSuppliers} to="#">
													<EmailIcon style={{ margin: '5px' }} />
													{<Translate id="expedientSupplier.sendMassMailToSuppliers" />}
												</Link>
											}
										/>
									</ListItem>
								)}
							</List>
							<br />
							{expedientSupplier.actual_status !== ExpedientSupplierStatus.DRAFT && (
								<Grid container>
									<Typography gutterBottom variant="body2">
										<strong>{<Translate id="expedientSupplier.suppliersInExpedients" />}</strong>{' '}
									</Typography>
									<br />
									<DataTable
										data={expedientSupplier.expedient_update_for_suppliers}
										configuration={TableSuppliersConfiguration}
										onRenderCellItem={this.onRenderCellItem}
										onRowClick={(expedient) => {
											this.props.history.push(`/expedientsbysupplier/${expedient.token}`);
										}}
                                        showFilters
									/>
								</Grid>
							)}
							<br />
							{expedientSupplier.actual_status === ExpedientSupplierStatus.DRAFT  && (
								<Grid container>
									<Grid item xs={12}>
										<Typography gutterBottom variant="body2">
											<strong>{<Translate id="expedientSupplier.expedientDocument" />}</strong>{' '}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<SupplierDocumentsContainerTable expedientSupplier={expedientSupplier} canBeModule={!canBeModuleExpedientViewer(this.props.userRolesByCompany)}/>
									</Grid>
								</Grid>
							)}
						</Paper>
					</Grid>
				</Grid>
			);
		}
	}

	render() {
		const { expedientSupplier } = this.props;
		let availableOperations = [];
		if (expedientSupplier && expedientSupplier.actual_status === ExpedientSupplierStatus.DRAFT) {
			availableOperations.push({
				title: <Translate id="expedientSupplier.operationsAvailable.messageSentToSupplierTitle" />,
				onClick: () => this.onConfirmPassword(expedientSupplier, ExpedientSupplierStatus.SEND_SUPPLIERS),
				isIconMaterial: true,
				description: <Translate id="expedientSupplier.operationsAvailable.messageSentToSupplierMessage" />
			});
			availableOperations.push({
				title: <Translate id="expedientSupplier.operationsAvailable.messageCancelFolioTitle" />,
				onClick: () => this.onConfirmPassword(expedientSupplier, ExpedientSupplierStatus.CANCEL_IN_PLANT),
				isIconMaterial: true,
				description: <Translate id="expedientSupplier.operationsAvailable.messageCancelFolio" />
			});
		}

		return (
			<Card>
				<CardContent>{this.getCardContent(availableOperations)}</CardContent>

				<AddCommonItem
					title={<Translate id="expedientSupplier.sendMassMailToSuppliers" />}
					message={<Translate id="expedientSupplier.messageSendMassMailToSuppliers" />}
					open={this.state.openSendReminder}
					onClose={() => {
						this.setState({ openSendReminder: false });
					}}
					onSaveItem={this.onSendReminderSupplier}
					isSaving={this.props.isSaving}
					isMessage={true}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false });
					}}
				/>

				<ShowEdit
					openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
					onOpenAndClose={this.onOpenAndClose}
					onConfirmPassword={this.onConfirmPassword}
					confirmPasswordConfigModal={this.state.confirmPasswordConfigModal}
					availableOperations={availableOperations}
				/>
			</Card>
		);
	}
}

const TableSuppliersConfiguration = {
	columns: [
		{
			header: <Translate id="common.supplier" />,
			dataSource: 'full_supplier'
		},
		{
			header: <Translate id="expedientSupplier.dateRequest" />,
			dataSource: 'creation_date'
		},
		{
			header: <Translate id="expedientSupplier.supplierType" />,
			dataSource: 'supplier_type_description'
		},
		{
			header: <Translate id="expedientSupplier.documentsRequest" />,
			dataSource: 'count_total_files'
		},
		{
			header: <Translate id="expedientSupplier.missingDocuments" />,
			dataSource: 'count_files_request'
		},
		{
			header: <Translate id="expedientSupplier.documentsAdding" />,
			dataSource: 'count_files_adding'
		},
		{
			header: <Translate id="expedientSupplier.documentsAccepted" />,
			dataSource: 'count_files_accepted'
		},
		{
			header: <Translate id="expedientSupplier.pendingByAcepted" />,
			dataSource: 'count_files_pending_accepted'
		},
		{
			header: <Translate id="expedientSupplier.dateStatus" />,
			dataSource: 'closed_date'
		},
		{
			header: <Translate id="common.status" />,
			dataSource: 'actual_operation_description'
		}
	]
};

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } };
	let userRolesByCompany = state.profile.get('userRolesByCompany');
	return {
		user: user,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingExpedientSupplier: state.expedientSuppliers.get('isLoadingExpedientSupplier'),
		expedientSupplier: state.expedientSuppliers.get('expedientSupplier')
			? state.expedientSuppliers.get('expedientSupplier').toJS()
			: null,
		isSaving: state.expedientSuppliers.get('isSavingExpedientSupplier'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize)
	};
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadExpedientSupplier: (expedientSupplierToken) => {
			return dispatch(loadExpedientSupplier(expedientSupplierToken));
		},
		sendReminderToSuppliersToUpdateExpedient: (expedientToken) => {
			return dispatch(sendReminderToSuppliersToUpdateExpedient(expedientToken));
		}
	};
};

const styles = (theme) => ({
	avatar: {
		color: theme.palette.secondary.main
	},
	actionsToolbar: {
		display: 'flex'
	},
	inline: {
		display: 'inline'
	}
});

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
);
