/**Import react section */
import React from "react";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";

/** Import component section */
import LoadingPage from '../../common/LoadingPage';
import FloatingButton from '../../common/FloatingButton';

import LayoutHeaderWorkFlow from '../../common/LayoutHeaderWorkFlow';
import BillingTable from './items/BillingTable';
import DialogOperationContainer from '../../common/DialogOperations/DialogOperationContainer';
/**import helpers section */
import { BillingStatus } from '../../../store/helpers/AppConstants'
/**Custom hooks index */

import { useBillingCustomHookShow } from '../customHooks/UseCustomHookShow'

const ShowDesktopViewInternalUser = (props) => {
    const { billing, user } = useBillingCustomHookShow()

    /**String props section */
    //const { } = props
    /**Bools props section */
    const { isLoading } = props
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onLateralPanelConfig, onConfirmPassword } = props
    /**Objects props section */
    const { translate, classes, selectedCompany, supplier } = props
    /**Render hooks section */


    /**Redux store information section*/
    let isUserCreator = (billing && (user && (user.profile.username === billing.creator_user_name)))
    let availableOperationsInStatusDraft = []
    if (billing && isUserCreator && billing.actual_operation === BillingStatus.DRAFT) {
        availableOperationsInStatusDraft.push({
            title: <Translate id="billings.operationsAvailable.messageSendToSupplierTitle" />,
            onClick: () => onConfirmPassword(billing, BillingStatus.SEND_TO_SUPPLIERS),
            isIconMaterial: true,
            description: <Translate id="billings.operationsAvailable.messageSentToSupplier" />
        })
        availableOperationsInStatusDraft.push({
            title: <Translate id="sustainability.status.cancelFolio" />,
            onClick: () => onConfirmPassword(billing, BillingStatus.CANCEL),
            isIconMaterial: true,
            description: <Translate id="indirectqualifications.operationsAvailable.messageCancelQualification" />
        })

    }

    const onGetOperationsAvailable = () => {
        switch (billing ? billing.actual_operation : 0) {
            case BillingStatus.DRAFT:
                return availableOperationsInStatusDraft
            default:
                return []
        }
    }



    const { DialogOperations, onOpenAndClose, } = DialogOperationContainer({
        options: onGetOperationsAvailable()
    })

    /**
    * Get the card content
    *
    * @memberof Index
    */
    const getCardContent = () => {
        if (isLoading === true) {
            return (
                <LoadingPage />
            )
        } else {
            return (
                <>
                    <Card>
                        {
                            <FloatingButton
                                open={onOpenAndClose}
                            />
                        }
                        <LayoutHeaderWorkFlow
                            workFlow={billing}
                            company={selectedCompany}
                            supplier={supplier}
                            title={<span><Translate id="billings.title" /> {billing ? billing.reference : ""}</span>}

                        />
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Card className={classes.papper}>
                                    <BillingTable
                                        onLateralPanelConfig={onLateralPanelConfig}
                                        translate={translate}
                                    />
                                </Card>
                            </Grid>
                        </Grid>

                    </Card>
                    <DialogOperations />
                </>
            )
        }
    }
    return getCardContent()
}

const styles = (theme) => ({
    papper: {
        marginTop: "10px"
    },
    root: {
        maxHeight: `calc(100vh - ${265}px)`,
        minHeight: `calc(100vh - ${265}px)`,
        overflowY: "auto",
        padding: "10px",
        overflowX: "auto"
    },

});

export default withRouter(
    withStyles(styles, { withTheme: true })(ShowDesktopViewInternalUser)
);
