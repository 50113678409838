/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserParameters,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'

export const START_LOADING_RAW_MATERIALS = 'START_LOADING_RAW_MATERIALS'
export const SET_RAW_MATERIALS_LIST = 'SET_RAW_MATERIALS_LIST'
export const CHANGE_RAW_MATERIALS_SORT_CRITERIA = 'CHANGE_RAW_MATERIALS_SORT_CRITERIA'
export const CHANGE_RAW_MATERIALS_QUERY = 'CHANGE_RAW_MATERIALS_QUERY'
export const CHANGE_RAW_MATERIALS_PAGINATION = 'CHANGE_RAW_MATERIALS_PAGINATION'
export const SET_RAW_MATERIALS_COUNT = 'SET_RAW_MATERIALS_COUNT'
export const IS_SAVING = 'IS_SAVING'
export const IS_LOADING = 'IS_LOADING'
export const SET_RAW_MATERIAL_NON_CONFORMITY = 'SET_RAW_MATERIAL_NON_CONFORMITY'
export const ADD_FILE_RAW_MATERIAL_NON_CONFORMITY = 'ADD_FILE_RAW_MATERIAL_NON_CONFORMITY'
export const REMOVE_FILE_RAW_MATERIAL_NON_CONFORMITY = 'REMOVE_FILE_RAW_MATERIAL_NON_CONFORMITY'
export const IS_LOADING_ALL_RAW_MATERIALS = 'IS_LOADING_ALL_RAW_MATERIALS'
export const ADD_EMERGENT_ACTION_TO_RAW_MATERIAL = 'ADD_EMERGENT_ACTION_TO_RAW_MATERIAL'
export const REMOVE_EMERGENT_ACTION_TO_RAW_MATERIAL = 'REMOVE_EMERGENT_ACTION_TO_RAW_MATERIAL'
export const ADD_CORRECTIVE_ACTION_RAWMATERIAL_TO_LIST = 'ADD_CORRECTIVE_ACTION_RAWMATERIAL_TO_LIST'
export const UPDATE_CORRECTIVE_ACTION_RAWMATERIAL_TO_LIST = 'UPDATE_CORRECTIVE_ACTION_RAWMATERIAL_TO_LIST'
export const SET_RAW_MATERIAL_QUALITY = 'SET_RAW_MATERIAL_QUALITY'

/**
 * Action start loading raw maerial non conformities
 * @param {*} isLoading
 */
function isLoadingRawMaterials(isLoading) {
	return {
		type: START_LOADING_RAW_MATERIALS,
		isLoading: isLoading,
	}
}

/**
 * Action to set raw maerial non conformities  list
 * @param {*} rawmaterials
 */
function setRawMaterialNonConformitiesList(rawmaterials) {
	return {
		type: SET_RAW_MATERIALS_LIST,
		rawmaterials: rawmaterials,
	}
}

/**
 * Action reducer to set the raw maerial non conformities counter
 *
 * @param {*} count
 * @returns
 */
function setRawMaterialNonConformitiesCount(count) {
	return {
		type: SET_RAW_MATERIALS_COUNT,
		count,
	}
}

/**
 * Action to is saving raw material non conformity
 * @param {*} isSaving
 */
function isSaving(isSaving) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
	}
}

/**
 * Action to is loaidng raw material non conformity
 * @param {*} isLoading
 */
function isLoading(isLoading) {
	return {
		type: IS_LOADING,
		isLoading: isLoading,
	}
}

/**
 * Action to set raw mateiral not conformity
 * @param {*} rawMaterial
 */
function setRawMaterialNonConformity(rawMaterial) {
	return {
		type: SET_RAW_MATERIAL_NON_CONFORMITY,
		rawMaterial: rawMaterial,
	}
}

/**
 * Add file to state
 *
 * @param {*} files
 * @returns
 */
function addFile(files) {
	return {
		type: ADD_FILE_RAW_MATERIAL_NON_CONFORMITY,
		files,
	}
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName) {
	return {
		type: REMOVE_FILE_RAW_MATERIAL_NON_CONFORMITY,
		fileName,
	}
}

/**
 * Action to add emergent action
 * @param {*} action
 */
function addEmergentAction(action) {
	return {
		type: ADD_EMERGENT_ACTION_TO_RAW_MATERIAL,
		emergentAction: action,
	}
}

/**
 * Action to remove emergent action
 * @param {*} annotationToken
 */
function removeEmergentAction(actionToken) {
	return {
		type: REMOVE_EMERGENT_ACTION_TO_RAW_MATERIAL,
		actionToken: actionToken,
	}
}

/**
 * Action to add corrective action
 * @param {*} action
 */
function addCorrectiveAction(correctiveAction) {
	return {
		type: ADD_CORRECTIVE_ACTION_RAWMATERIAL_TO_LIST,
		correctiveAction,
	}
}

/**
 * Action to update corrective action
 * @param {*} action
 */
function updateCorrectiveAction(correctiveAction) {
	return {
		type: UPDATE_CORRECTIVE_ACTION_RAWMATERIAL_TO_LIST,
		correctiveAction,
	}
}

function setRawMaterialReportsQuality(rawMaterial) {
	return {
		type: SET_RAW_MATERIAL_QUALITY,
		rawMaterial: rawMaterial,
	}
}

/**
 * Get all RawMaterialNonConformities by company from backend
 * DONE
 */
export function loadRawMaterialNonConformitiesFromBackEnd(
	query,
	orderBy,
	sortAscending,
	page,
	rowsPerPage,
	isAllReports
) {
	return function (dispatch, getState) {
		dispatch(isLoadingRawMaterials(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/all`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
			GetAllReports: isAllReports,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setRawMaterialNonConformitiesList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingRawMaterials(false))
				console.error('Error getting the raw material nonconformity by company list', error.response)
			})
	}
}

/**
 * Get raw material nonconformity's count
 * DONE
 * @export
 * @returns
 */
export function getRawMaterialNonConformitiesCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().rawMaterials ? getState().rawMaterials.get('rawMaterialCount') : null
		if (actualCounter) {
			return Promise.resolve()
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setRawMaterialNonConformitiesCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error getting the raw material non conformities count', error.response)
			})
	}
}

/**
 * Action to create raw material non conformity from backend
 */
export function createRawMaterialNonConformityFromBackEnd(preparedBy, supplier, partNumber, failure, rawMaterial) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let data = {
			CompanyToken: selectedCompany.get('token'),
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			SupplierToken: supplier.token,
			SupplierName: supplier.name,
			SupplierCode: supplier.code,

			PreparedBy: preparedBy ? preparedBy.label : '',
			PartNumberCode: partNumber ? partNumber.code : '',
			Description: partNumber ? partNumber.description : '',
			MeasurementUnit: partNumber ? partNumber.measurementUnit : '',
			NonconformityToken: failure ? failure.value : '',

			QuantityOfMaterialInInvoice: rawMaterial.invoicedAmount,
			QuantityOfMaterialAffected: rawMaterial.quantityInspected,
			ProblemDescription: rawMaterial.problemDescription,
			CorrectMaterialCondition: rawMaterial.specificationCorreclty,
			LotMaterial: rawMaterial.lotMaterial,
			CustomerPartNumber: rawMaterial.customerPartNumber,
			DeliveryOrderFolio: rawMaterial.deliveryOrderFolio,
			DeliveryOrderToken: rawMaterial.deliveryOrderToken,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error adding a raw material non conformity from company', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to edit raw material non conformity from backend
 */
export function editRawMaterialNonConformityFromBackEnd(preparedBy, supplier, partNumber, failure, rawMaterial) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let data = {
			RawMaterialToken: rawMaterial.tokenRawMaterial,
			SupplierToken: supplier.token,
			SupplierName: supplier.name,
			SupplierCode: supplier.code,

			PreparedBy: preparedBy ? preparedBy.label : '',
			PartNumberCode: partNumber ? partNumber.code : '',
			Description: partNumber ? partNumber.description : '',
			MeasurementUnit: partNumber ? partNumber.measurementUnit : '',
			NonconformityToken: failure ? failure.value : '',

			QuantityOfMaterialInInvoice: rawMaterial.invoicedAmount,
			QuantityOfMaterialAffected: rawMaterial.quantityInspected,
			ProblemDescription: rawMaterial.problemDescription,
			CorrectMaterialCondition: rawMaterial.specificationCorreclty,
			LotMaterial: rawMaterial.lotMaterial,
			CustomerPartNumber: rawMaterial.customerPartNumber,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error edit a raw material non conformity from company', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Get and set the RawMaterialNonConformity in the estate from read from backend
 * DONE
 * @param {*} token
 */
export function loadRawMaterialNonConformityFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoading(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/nonconformity/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setRawMaterialNonConformity(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load Raw Material NonConformity', error.response)
				dispatch(isLoading(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to send notification from non conformity
 * @param {*} planningSelected
 * @param {*} developmentSelected
 * @param {*} externalUsers
 */
export function sendNotificationNonconformity(planningSelected, developmentSelected, usersInternal) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let tokenRawMaterial = getState().rawMaterialNonConformity.get('rawMaterial').get('token')

		let data = {
			WorkflowToken: tokenRawMaterial,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			PlanningEmail: planningSelected.user_email,
			PlanningName: planningSelected.full_name ? planningSelected.full_name : planningSelected.name,
			Developmentemail: developmentSelected.user_email,
			DevelopmentName: developmentSelected.full_name ? developmentSelected.full_name : developmentSelected.name,
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			UsersInternal: usersInternal,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/sendnotification`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to send notications from raw matarial', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action get memo information data from backend
 */
export function getFolioDeviationDataFromBackend(folio) {
	return function (dispatch, getState) {
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/deviation/${folio}/${selectedCompany.get(
			'get_company_identifier'
		)}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to get folio deviation', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to send notification from non conformity
 * @param {*} planningSelected
 * @param {*} developmentSelected
 * @param {*} externalUsers
 */
export function registerDeviationNonconformity(folio) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let tokenRawMaterial = getState().rawMaterialNonConformity.get('rawMaterial').get('token')
		let selectedCompany = getState().profile.get('selectedCompany')

		let data = {
			RawMaterialToken: tokenRawMaterial,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Folio: folio,
			CompanyIdentifier: selectedCompany.get('get_company_identifier'),
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/registerdeviation`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in register deviation from raw material non conformity', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to create devolution
 * @param {*} preparedBy
 * @param {*} failure
 * @param {*} devolution
 */
export function createDevolutionFromBackEnd(preparedBy, failure, devolution) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let tokenRawMaterial = getState().rawMaterialNonConformity.get('rawMaterial').get('token')
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let data = {
			WorkFlowToken: tokenRawMaterial,
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,

			PreparedBy: preparedBy ? preparedBy.label : '',
			NonconformityToken: failure ? failure.value : '',

			QuantityOfMaterial: devolution.invoicedAmount,
			InvoiceNumber: devolution.invoiceNumber,
			Comments: devolution.comments,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/devolutions`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error adding a devolution from raw material', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to create new rework
 * @param {*} sorterBySelected
 * @param {*} dateMaterial
 * @param {*} description
 */
export function createReworkFromBackEnd(sorterBySelected, dateMaterial, description) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let selectedCompany = getState().profile.get('selectedCompany')
		let tokenRawMaterial = getState().rawMaterialNonConformity.get('rawMaterial').get('token')
		let quality = getState().rawMaterialNonConformity.get('rawMaterial').get('quantity_of_material_in_invoice')
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let data = {
			WorkFlowToken: tokenRawMaterial,
			DocumentIdentifier: selectedCompany.get('document_identifier'),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			SorterToken: sorterBySelected ? sorterBySelected.token : '',
			SorterName: sorterBySelected ? sorterBySelected.name : '',
			SorterCode: sorterBySelected ? sorterBySelected.code : '',
			DateReworkMaterial: dateMaterial,
			Description: description,
			QuantityOfMaterial: quality,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/reworks/nonconformityworkflow`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error adding a rework from raw material', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update corrective action from failure report quality
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFilesEvidence(files) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let tokenQuality = getState().rawMaterialNonConformity.get('rawMaterial').get('token')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/${tokenQuality}/upload`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error(
					'Error to upload evidence file(s) from raw material non conformity report',
					error.response
				)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} quoteToken
 * @param {*} fileName
 * @returns
 */
export function removeFilesEvidence(fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let token = getState().rawMaterialNonConformity.get('rawMaterial').get('token')
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/${token}/remove`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFile(fileName))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error(
					'Error to remove evidence file(s) from raw material non conformity report',
					error.response
				)
				return Promise.reject()
			})
	}
}

/**
 * Action to delete draft raw material
 * @param {*} token
 */
export function deleteDraftRawMaterial(token) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/workflow/${token}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to delete draft raw material non conformity', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update status deviation
 * @param {*} tokenDeviation
 */
export function updateStatusDeviation(tokenDeviation) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/deviation/${tokenDeviation}`
		return axios
			.put(endPoint, null, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setRawMaterialNonConformity(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to delete draft raw material non conformity', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Finalizes the return of material
 */
export function notifyDevolutionMaterialEngineer(token) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/${token}/notify`
		return axios
			.post(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setRawMaterialNonConformity(serverResponse.data))
					dispatch(isSaving(false))
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to notify to enginner to devolution of material', error.response)
				return Promise.reject()
			})
	}
}

/**
 *Download report of faults of material
 *
 * @export
 * @returns
 */
export function downloadRawMaterialReport() {
	return (dispatch, getState) => {
		dispatch(isLoading(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/downloadreport/${companyToken}`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isLoading(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_Incumplimientos.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isLoading(false))
				console.error('Error downloading report of raw material non conformities', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to close workflow manually a workflows from quality
 *
 * @export
 * @param {*} token
 * @returns
 */
export function closedRawMaterialManuallyFromBackend(token) {
	return function (dispatch, getState) {
		dispatch(isSaving(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let data = {}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/workflow/${token}/qualityclose`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					return Promise.resolve(serverResponse)
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to close a workflows document', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add emergent action from failure report quality
 * @param {*} description
 */
export function addEmergentActionToRawMaterial(description) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let token = getState().rawMaterialNonConformity.get('rawMaterial').get('token')

		let data = {
			FailureReportToken: token,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Description: description,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/emergentaction`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addEmergentAction(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to add emegent action from raw material', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove emergent action from ra wmateiral
 * @param {*} annotationToken
 */
export function removeEmergentActionToRawMaterial(actionToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/emergentaction/${actionToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeEmergentAction(actionToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error removing emegent action from raw material', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add corrective action from failure report quality
 *
 * @export
 * @param {*} data
 * @returns
 */
export function addCorrectiveActionsRawMaterial(correctiveActionData) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let tokenQuality = getState().rawMaterialNonConformity.get('rawMaterial').get('token')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/correctiveactionsupplier`
		let formData = new FormData()
		correctiveActionData.attachments.forEach((file) => {
			formData.append('file', file)
		})
		formData.set('RawMaterialToken', tokenQuality)
		formData.set('CreatorUserName', user.profile.preferred_username)
		formData.set('CreateBy', user.profile.name)
		formData.set('Description', correctiveActionData.description)
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addCorrectiveAction(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to add corrective action from raw material quality', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to accept corrective action from raw material
 *
 * @export
 * @param {*} data
 * @returns
 */
export function acceptCorrectiveActions(item) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/correctiveactionsupplier`
		let data = {
			ActionToken: item.token,
			InspectedUserName: user.profile.preferred_username,
			InspectedBy: user.profile.name,
			Status: item.status,
			Comment: item.quality_comments,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateCorrectiveAction(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to accept or reject corrective action from raw material', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update corrective action from raw material
 *
 * @export
 * @param {*} data
 * @returns
 */
export function updateDataCorrectiveAction(correctiveActionData) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/correctiveactionsupplier/update`
		let formData = new FormData()
		correctiveActionData.attachments.forEach((file) => {
			formData.append('file', file)
		})
		formData.set('CorrectiveActionToken', correctiveActionData.token)
		formData.set('CreatorUserName', user.profile.preferred_username)
		formData.set('CreateBy', user.profile.name)
		formData.set('Description', correctiveActionData.description)
		return axios
			.put(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateCorrectiveAction(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to update corrective action from raw material', error.response)
				return Promise.reject()
			})
	}
}

export function registerWarehouseEntryFromBackend(folio) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let tokenQuality = getState().rawMaterialNonConformity.get('rawMaterial').get('token')

		let data = {
			FailureReportToken: tokenQuality,
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Folio: folio,
		}

		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/registerwarehouseentry`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setRawMaterialReportsQuality(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to register warehouse entry from raw material', error.response)
				return Promise.reject()
			})
	}
}

export function udateStatusToWaitingForCompleteTheContainment(token) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {}
		let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/rawmaterials/${token}/updatetatus`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setRawMaterialReportsQuality(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in update status from raw material', error.response)
				return Promise.reject()
			})
	}
}
