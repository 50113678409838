
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import autoBind from 'auto-bind'
import PropTypes from 'prop-types'

/** Material-UI imports section */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

/** Import section component */
import Toaster from '../../common/Toaster';
import DialogActions from '@material-ui/core/DialogActions';

/**
 * Component Form Release calendar lock
 */
class InformationPartial extends Component {

    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            calendarLock:{
                token: this.props.calendarLock.token,
                message: this.props.calendarLock.message_lock,
                number_ramps: this.props.calendarLock.number_of_ramps,
                all_days: this.props.calendarLock.all_days,
                is_monday: this.props.calendarLock.is_monday,
                is_tuesday: this.props.calendarLock.is_tuesday,
                is_wednesday: this.props.calendarLock.is_wednesday,
                is_thursday: this.props.calendarLock.is_thursday,
                is_friday: this.props.calendarLock.is_friday,
                is_saturday: this.props.calendarLock.is_saturday,
                date_lock_start:this.props.calendarLock.date_lock_start,
                date_lock_end: this.props.calendarLock.date_lock_end,
                time_hour_lock_start:this.props.calendarLock.time_hour_lock_start,
                time_hour_lock_end: this.props.calendarLock.time_hour_lock_end,
                calendar_lock_type: this.props.calendarLock.calendar_lock_type,
            }
        }
    }

    /**
     * Render show 
     */
    render(){
        const { classes } = this.props;
            return(
                <div className={classes.container} noValidate autoComplete="off">
                     <Grid item xs={6}>
                        <TextField
                            disabled
                            id="standard-read-only-input"
                            className={classes.formText} 
                            label={<Translate id="releaseCalendarLock.rampsNumber"/>}
                            value={this.state.calendarLock.number_ramps}
                            margin="normal"
                            onChange={(event) => {this.updateProperty("number_ramps", event.target.value)}}
                        />
                        {this.state.calendarLock.calendar_lock_type === 0 && <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                {this.state.calendarLock.all_days && <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox  checked={this.state.calendarLock.all_days}
                                        disabled
                                        />}
                                        label={"Bloqueado todos los días"}/>
                                    </FormGroup>}
                                {!this.state.calendarLock.all_days && <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox  checked={true}
                                        disabled
                                        />}
                                        label={"Bloqueado por fechas"}/>
                                    </FormGroup>}
                            </FormGroup>
                        </FormControl>}
                        {this.state.calendarLock.calendar_lock_type !== 0 && <>
                            <FormControl component="fieldset" className={classes.formControl}>
                                {this.state.calendarLock.calendar_lock_type === 1 && <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox  checked={this.state.calendarLock.calendar_lock_type === 1}
                                        disabled
                                        />}
                                        label={"Bloqueado todos los días"}/>
                                    </FormGroup>}
                                {this.state.calendarLock.calendar_lock_type === 2 && <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox  checked={this.state.calendarLock.calendar_lock_type === 2}
                                        disabled
                                        />}
                                        label={"Bloqueado por fechas"}/>
                                    </FormGroup>}
                                {this.state.calendarLock.calendar_lock_type === 3 && <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox  checked={this.state.calendarLock.calendar_lock_type === 3}
                                        disabled
                                        />}
                                        label={"Bloqueado por periodo"}/>
                                    </FormGroup>}
                            </FormControl>
                        </>}

                        <br/>
                        <TextField
                            disabled
                            id="standard-read-only-input"
                            className={classes.formText} 
                            label={<Translate id="releaseCalendarLock.startDate"/>}
                            value={this.state.calendarLock.date_lock_start}
                            margin="normal"
                            onChange={(event) => {this.updateProperty("date_lock_start", event.target.value)}}
                        />
                         <TextField
                            disabled
                            id="standard-read-only-input"
                            className={classes.formText} 
                            label={<Translate id="releaseCalendarLock.endDate"/>}
                            value={this.state.calendarLock.date_lock_end}
                            margin="normal"
                            onChange={(event) => {this.updateProperty("date_lock_end", event.target.value)}}
                        />
                        <TextField
                            disabled
                            id="standard-read-only-input"
                            className={classes.formText} 
                            label={<Translate id="releaseCalendarLock.starHour"/>}
                            value={this.state.calendarLock.time_hour_lock_start}
                            margin="normal"
                            onChange={(event) => {this.updateProperty("time_hour_lock_start", event.target.value)}}
                        />
                        <TextField
                            disabled
                            id="standard-read-only-input"
                            className={classes.formText} 
                            label={<Translate id="releaseCalendarLock.endHour"/>}
                            value={this.state.calendarLock.time_hour_lock_end}
                            margin="normal"
                            onChange={(event) => {this.updateProperty("time_hour_lock_end", event.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.formatCheck}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                <FormControlLabel
                                    disabled
                                    value="status"
                                    control={<Checkbox value="status"  checked={this.state.calendarLock.is_monday}
                                    onChange={(event) => {this.updateProperty('is_monday', event.target.checked)}} />}
                                    label={<Translate id="releaseCalendarLock.isMonday"/>}
                                />
                                <FormControlLabel
                                    disabled
                                    control={<Checkbox  checked={this.state.calendarLock.is_tuesday}
                                    onChange={(event) => {this.updateProperty('is_tuesday', event.target.checked)}}  />}
                                    label={<Translate id="releaseCalendarLock.isTuesday"/>}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.calendarLock.is_wednesday}
                                    disabled
                                     onChange={(event) => {this.updateProperty('is_wednesday', event.target.checked)}}  />}
                                    label={<Translate id="releaseCalendarLock.isWednesday"/>}
                                />
                                </FormGroup>
                            </FormControl>
                            <FormControl required component="fieldset" className={classes.formControl} >
                                <FormGroup>
                                <FormControlLabel
                                    disabled
                                    control={<Checkbox checked={this.state.calendarLock.is_thursday}
                                    onChange={(event) => {this.updateProperty('is_thursday', event.target.checked)}} />}
                                    label={<Translate id="releaseCalendarLock.isThursday"/>}
                                />
                                <FormControlLabel
                                    disabled
                                    control={<Checkbox checked={this.state.calendarLock.is_friday}
                                    onChange={(event) => {this.updateProperty('is_friday', event.target.checked)}}    />}
                                    label={<Translate id="releaseCalendarLock.isFriday"/>}
                                />
                                <FormControlLabel
                                    disabled
                                    control={
                                    <Checkbox checked={this.state.calendarLock.is_saturday}
                                     onChange={(event) => {this.updateProperty('is_saturday', event.target.checked)}}  />}
                                    label={<Translate id="releaseCalendarLock.isSaturday"/>}
                                />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <br/>
                        <TextField fullWidth required
                            id="outlined-multiline-flexible"
                            label={<Translate id="releaseCalendarLock.lockRequiered"/>}
                            multiline
                            disabled
                            className={classes.tetxtFieldFull} 
                            rows="3"
                            value={this.state.calendarLock.message}
                            onChange={(event) => {this.updateProperty("message", event.target.value)}}
                            margin="normal"
                            variant="outlined"
                        /><br/><br/>
                    </Grid>
                    <Grid container >
                        <DialogActions>
                            <Button onClick={() => {this.props.history.push(`/releasecalendar`)}} color="secondary">
                                {<Translate id="common.toReturn"/>}
                            </Button>
                        </DialogActions>
                    </Grid> 

                    <Toaster 
                        message = {this.state.toasterMessage}
                        open = {this.state.showToaster}
                        variant = {this.state.toasterVariant}
                        onClose = {() => {this.setState({ showToaster:false})}}
                    /> 

                </div>
            )
    }
}

/**
 * InformationPartial
 */
InformationPartial.propTypes = {
    calendarLock: PropTypes.object.isRequired
}

const viewStyles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: "100px",
    },
    tetxtFieldFull:{
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        maxWidth: 900,
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      maxWidth: 450,
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    footerForm:{
        display: "flex",
        paddingRight: "60px",
    },
    formatCheck: {
        display: 'flex',
         marginLeft: theme.spacing.unit,
        width: 460,
    },
    formText:{
        marginLeft: theme.spacing.unit,
        maxWidth: 340,
    },
    formControl:{margin: theme.spacing.unit},
    toolbar:{},
    searchInput:{ },
    button:{ },
    actionsToolbar:{ },
    showingUsersCounter:{ },
    grow:{},

});

export default withRouter(withStyles(viewStyles, { withTheme:true })(InformationPartial));