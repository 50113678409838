///Import react section
import React, { useEffect } from 'react';
import { getTranslate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

/** Language imports **/
import { Translate } from "react-localize-redux";
import queryString from 'query-string'

/** Material-UI imports section */
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import SustainabilityIcon from '@material-ui/icons/NaturePeople';
import ListIcon from '@material-ui/icons/List';
import {Typography, Fab, Tooltip} from "@material-ui/core";

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/resource-timegrid';
import list from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import ptLocale from '@fullcalendar/core/locales/pt';
import moment from 'moment';
import { converListToArray } from '../../../store/helpers/ListHelper'
import '@fullcalendar/list/main.css';
import '@fullcalendar/timegrid/main.css';

import { loadAllAuditCalendar } from '../../../store/sustainability2/SustainabilityActions'
import {COLOR_SUSTAINABILITY_PLUM} from '../../../store/helpers/StatusColorConstants';

/**
 * Component load calenar
 * @param {*} props 
 */
const Show = (props) => {
    const translate = useSelector((state) => getTranslate(state.localize))
    let location = useLocation()
    const dispatch = useDispatch()
    const { classes } = props;

    let locale = props.languages ? props.languages.find(language => { return language.active })["code"] : "es";

    const auditCalendar = useSelector((state) => state.sustainabilityV2.get('auditCalendar') ? converListToArray(state.sustainabilityV2.get('auditCalendar')) : [])
    /**Use effect section */
    useEffect(() => {
        const queryParams = queryString.parse(location.search)
        dispatch(loadAllAuditCalendar(queryParams))
    }, [dispatch])

    /**
     * Function to show sustainabilityv2
     */
     const onOpenSustainability = ( event) => {
        props.history.push(`/sustainabilityv2`)
    }

    const formatObjectEvents = (events) => {
        let allEvents = [];
        events.forEach(event => {
            let AuditDate = moment(event.ScheduledAuditDate,"DD/MM/YYYY").format('YYYY-MM-DD');
            allEvents.push({
                title: `${translate('sustainability.auditScheduledFor')} ${event.FullSupplier }`,
                date: AuditDate,
                backgroundColor: '#a5eab5',
                color: '#a5eab5',
                description: event.Description,
            },{
                title: `${translate('sustainability.auditScheduledFor')} ${event.FullSupplier }`,
                date: AuditDate,
                backgroundColor: '#a5eab5',
                color: '#a5eab5',
                rendering: 'background',
            })
        });
        return allEvents;
    }

    const allEvents = auditCalendar ? formatObjectEvents(auditCalendar) : [];
    return (
        <Card>
            <CardHeader
                title={<Typography variant="button">{<Translate id="sustainability.AuditCalendar"/>}</Typography>}
                avatar={
                    <Avatar>
                        <SustainabilityIcon />
                    </Avatar>
                }
                action={
                    <Tooltip title={<Translate id="sustainability.returnView"/>}>
                        <Fab className={classes.marginIconReturn} size="small" color="primary" onClick={() => { onOpenSustainability() }}>
                            <ListIcon />
                        </Fab>
                    </Tooltip>
                }
            />
            <CardContent className={classes.calendar}>
            <FullCalendar                
                defaultView="listMonth"
                locales={[esLocale, ptLocale]}
                locale={locale}
                timeZone="UTC"
                plugins={[dayGridPlugin, timeGridPlugin, list]}
                header= {{
                    left: 'prev,next',
                    center: 'title',
                    right: 'timeGridWeek,dayGridMonth,listMonth'
                }}
                events= { allEvents }
                views= {{
                    listMonth: {
                        titleFormat: { month: 'long', year: 'numeric', day: 'numeric' },
                        noEventsMessage: translate("sustainability.notExistAudit")
                    }
                }}
                height = "parent"
                contentHeight = "auto"
                aspectRatio = "default"
          />
        </CardContent>
        </Card>        
    )
}

const styles = ((theme) => ({
    calendar: {
        minHeight: '400px',
        height: '100%',
        with: '100%'
    },
    marginIconReturn:{
        marginLeft: "5px",
        backgroundColor: COLOR_SUSTAINABILITY_PLUM
    },
}))



export default withauthenticatedLayout(withRouter((withStyles(styles, { withTheme: true })(Show))))