import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

/** Material UI impot section */
import { Button, DialogActions, TextField, Typography } from '@material-ui/core';

/** Custom components import section */
import Dialog from '../../../common/Dialog';

/**
 * Presentional component to render the confimation dialog to lock service orders for payment request.
 * @param {object} props
 */
const DialogLockConfrimation = (props) => {
	/** Destructuring properties */
	const { opened, commentsRequired, executingAction, title, message, onClose, onConfirm } = props;

	/** Defeines local state */
	const [comments, setComments] = useState('');
	const [acceptButtonDisabled, setAcceptButtonDisabled] = useState(true);

	/** Update the initial state for accepted button */
	useEffect(() => {
		if (!commentsRequired) {
			setAcceptButtonDisabled(false);
		}
	}, [commentsRequired]);

	/**
	 * Handle the click confrim button
	 */
	const handleConfirmClick = () => {
		if (commentsRequired) {
			onConfirm(comments);
		} else {
			onConfirm();
		}
	};

	/**
	 * Handle the change event of comments
	 * @param {object} event
	 */
	const onCommentsChange = (event) => {
		const { value } = event.target;
		setComments(value);
		const acceptButtonEnabled = commentsRequired ? value && value.length > 10 : true;
		setAcceptButtonDisabled(!acceptButtonEnabled);
	};

	return (
		<Dialog
			open={opened}
			onClose={onClose}
			header={<Typography style={{ margin: 10 }}>{title}</Typography>}
			actions={
				<DialogActions>
					<Button onClick={handleConfirmClick} color="primary" disabled={acceptButtonDisabled || executingAction}>
						<Translate id="common.accept" />
					</Button>
					<Button onClick={onClose} color="secondary">
						<Translate id="common.cancel" />
					</Button>
				</DialogActions>
			}
		>
			<Typography style={{ margin: 10 }}>{message}</Typography>
			{commentsRequired && (
				<TextField
					autoFocus
					fullWidth
					multiline
					required
					label="Comentarios"
					type="text"
					size="large"
					margin="dense"
					onChange={onCommentsChange}
				/>
			)}
		</Dialog>
	);
};

DialogLockConfrimation.propTypes = {
	opened: PropTypes.bool.isRequired,
	commentsRequired: PropTypes.bool,
	executingAction: PropTypes.bool,
	title: PropTypes.element,
	message: PropTypes.element,
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired
};

DialogLockConfrimation.defaultProps = {
	commentsRequired: false,
	executingAction: false,
	title: '',
	message: '',
	onClose: () => console.warn('[onClose] not defined'),
	onConfirm: () => console.warn('[onConfirm] not defined')
};

export default DialogLockConfrimation;
