/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';
import { IsNullOrEmpty } from '../helpers/StringHelper';

export const START_LOADING_USERS = "START_LOADING_USERS";
export const SET_USERS_LIST = "SET_USERS_LIST";
export const IS_LOADING_USER = "IS_LOADING_USER";
export const SET_USER = "SET_USER";
export const CHANGE_INTERAL_USER_SORT_CRITERIA = "CHANGE_INTERAL_USER_SORT_CRITERIA";
export const CHANGE_INTERAL_USER_QUERY = "CHANGE_INTERAL_USER_QUERY";
export const SET_INTERNAL_USERS_COUNT = "SET_INTERNAL_USERS_COUNT";
export const SET_INTERNAL_USERS_ROLES = "SET_INTERNAL_USERS_ROLES";
export const IS_LOADING_INTERNAL_USERS_ROLES = "IS_LOADING_INTERNAL_USERS_ROLES";
export const ADD_USER_TO_ROLE = "ADD_USER_TO_ROLE";
export const REMOVE_USER_FROM_ROLE = "REMOVE_USER_FROM_ROLE";
export const SET_INTERNAL_USERS_BY_QUERY = "SET_INTERNAL_USERS_BY_QUERY";
export const IS_SAVING = "IS_SAVING";
export const SET_ENABLE_USER = "SET_ENABLE_USER";
export const SET_USERS_IN_ROLE = "SET_USERS_IN_ROLE";

/**
 * Action start loading users
 * @param {*} isLoading 
 */
function isLoadingUsers(isLoading) {
    return {
        type: START_LOADING_USERS,
        isLoading: isLoading
    }
}

/**
 * Action to set users list 
 * @param {*} users 
 */
function setUsersList(users) {
    return {
        type: SET_USERS_LIST,
        users: users
    }
}

/**
 * Action to start load user
 */
function startLoadUser(isLoading) {
    return {
        type: IS_LOADING_USER,
        isLoadingUser: isLoading
    }
}

/**
 * Action to set user
 * @param {*} user 
 */
function setUser(user) {
    return {
        type: SET_USER,
        user: user
    }
}

/**
 * Action reducer to set the internal user's counter
 *
 * @param {*} count
 * @returns
 */
function setInternalUsersCount(count) {
    return {
        type: SET_INTERNAL_USERS_COUNT,
        count
    }
}

/**
 * Set internal user roles
 *
 * @param {*} userId
 * @param {*} roles
 * @returns
 */
function setInternalUserRoles(userId, roles) {
    return {
        type: SET_INTERNAL_USERS_ROLES,
        userId,
        roles
    }
}

/**
 * Is loading internal user's roles
 *
 * @param {*} isLoading
 * @returns
 */
function isLoadingInternalUserRoles(isLoading) {
    return {
        type: IS_LOADING_INTERNAL_USERS_ROLES,
        isLoading
    }
}

/**
 * Action to set users by query 
 * @param {*} users 
 */
function setUsersByQuery(users) {
    return {
        type: SET_INTERNAL_USERS_BY_QUERY,
        users: users
    }
}

/**
 * Action to set users list in role
 *
 * @param {*} users
 * @param {*} role
 * @returns
 */
function setUsersByRole(users, role){
    return{
        type: SET_USERS_IN_ROLE,
        role,
        users
    }
}

/**
 * Action to is saving user
 */
function isSavingUser(isSaving) {
    return {
        type: IS_SAVING,
        isSaving: isSaving
    }
}

/**
 * Action to set enabled user
 * @param {*} enabled 
 */
function setEnabledUser(enabled) {
    return {
        type: SET_ENABLE_USER,
        enabled: enabled
    }
}

/**
 * Get all users by company from backend 
 */
export function loadInternalUsersFromBackEnd(query, orderBy, sortAscending) {
    return function (dispatch, getState) {
        dispatch(isLoadingUsers(true));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/company/users/internal`;
        let data = {
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setUsersList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingUsers(false));
                console.error("Error getting the users by company list", error.response)
            })
    }
}


/**
 * Get internal user's count
 *
 * @export
 * @returns
 */
export function getInternalUsersCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().internalUsers.get('internalUsersCount');
        if (actualCounter) {
            return;
        }

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/company/users/internal/count`;
        let data ={}
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setInternalUsersCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the internal user's count", error.response)
            })
    }
}

/**
 * Get and set the user in the estate from read from backend
 * @param {*} token 
 */
export function loadUser(token) {
    return function (dispatch, getState) {
        dispatch(startLoadUser(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user/${token}`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setUser(serverResponse.data))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                console.error("Error to load user", error.response)
                dispatch(startLoadUser(false))
                return Promise.reject();
            })
    }
}


/**
 * Load user's role
 *
 * @export
 * @param {*} userId
 * @returns
 */
export function loadUserRoles(userId) {
    return function (dispatch, getState) {
        dispatch(isLoadingInternalUserRoles(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${userId}/roles`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setInternalUserRoles(userId, serverResponse.data))
                    dispatch(isLoadingInternalUserRoles(false));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error loading user's roles", error.response)
                dispatch(isLoadingInternalUserRoles(false));
                return Promise.reject();
            })
    }
}


/**
 * Toggle the assignation of a user in a role
 *
 * @export
 * @param {*} assigned
 * @param {*} company
 * @param {*} role
 */
export function toggleRoleAssignent(internalUser, assigned, company, role) {
    return function (dispatch, getState) {
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${internalUser.id}/roles/${role.id}`;
        let endPointAdd = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/role`;
        if (assigned === true) {
            let data = {
                UserId: internalUser.id,
                RoleId: role.id,
                UserName: internalUser.user_name,
                Name: internalUser.full_name,
                UserEmail: internalUser.email,
            }
            return axios.post(endPointAdd, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch({
                            type: ADD_USER_TO_ROLE,
                            companyToken: company.token,
                            roleId: role.id
                        })
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    console.error("Error adding a user into a role", error.response)
                    dispatch(isLoadingInternalUserRoles(false));
                    return Promise.reject();
                })

        } else {
            return axios.delete(endPoint, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch({
                            type: REMOVE_USER_FROM_ROLE,
                            companyToken: company.token,
                            roleId: role.id
                        })
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    console.error("Error removing the user from a role", error.response)
                    dispatch(isLoadingInternalUserRoles(false));
                    return Promise.reject();
                })
        }
    }
}

/**
 * Action to serch users by Query 
 * @param {*} query 
 */
export function searchUsersByQuery(query) {
    return function (dispatch, getState) {
        dispatch(isLoadingUsers(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/users/internalbyquery`;
        let data ={
            Query: query,
        }
        return axios.post(endPoint, data, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(setUsersByQuery(response.data));
                    return Promise.resolve(response.data);
                }
            })
            .catch((error) => {
                dispatch(isLoadingUsers(false))
                console.error(error);
            });
    }
}

/**
 * Action to serch users by role 
 * @param {*} query 
 */
export function searchUsersInRole(role, supplierCode = null) {
    return function (dispatch, getState) {
        dispatch(isLoadingUsers(true))
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let selectedCompanyToken = getState().profile.get("selectedCompany").get('token');
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/roles/internalusers/role`;
        let data ={
            CompanyToken: selectedCompanyToken,
            RoleName: role,
            SupplierCode: supplierCode
        }
        if (IsNullOrEmpty(supplierCode)) {
            return axios.post(endPoint, data, configuration)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(setUsersByRole(response.data, role));
                        return Promise.resolve(response.data);
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingUsers(false))
                    console.error(error);
                });
        }
        else {
            endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/roles/externalUsers/supplier`;
            return axios.post(endPoint, data, configuration)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(setUsersByRole(response.data, role));
                        return Promise.resolve(response.data);
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingUsers(false))
                    console.error(error);
                });
        }
    }
}

/**
 * Action to set lockout enable user
 * @param {*} userId 
 * @param {*} enabled 
 */
export function setLockoutEnabledUser(userId, enabled, comments) {
    return function (dispatch, getState) {
        dispatch(isSavingUser(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user/${userId}/lockoutenabled`;
        let data = {
            LockoutEnabled: enabled,
            Comments: comments,
        }

        return axios.put(endPoint, data, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(setEnabledUser(enabled))
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingUser(false))
                console.error(error);
            });
    }
}


/**
 * Upload avatar user
 *
 * @export
 * @param {*} userId
 * @param {*} fileBase64
 * @returns
 */
export function uploadAvatarUser(userId, fileBase64) {
    return function (dispatch, getState) {

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user/${userId}/avatar`;
        let data = { fileBase64 }

        return axios.put(endPoint, data, configuration)
            .then((response) => {
                dispatch(setUser(response.data))
                return Promise.resolve();
            })
            .catch((err) => {
                console.error("Error uploading user avatar", err.response)
                return Promise.reject();
            })
    }
}

/**
 * Toggle the assignation of a user in a role
 *
 * @export
 * @param {*} assigned
 * @param {*} company
 * @param {*} role
 */
export function addRolesInAllCompaniesGrandAccess(userId) {
    return function (dispatch, getState) {
        dispatch(isLoadingInternalUserRoles(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${userId}/roles/addrolesincompanies`;
        let data = {}

        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setInternalUserRoles(userId, serverResponse.data))
                    dispatch(isLoadingInternalUserRoles(false));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error adding a user into a role", error.response)
                dispatch(isLoadingInternalUserRoles(false));
                return Promise.reject();
            })
    }
}