import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ReactiveIcon from '@material-ui/icons/FindReplace'
import QuotesIcon from '@material-ui/icons/AttachMoneyTwoTone'
import SamplesIcon from '@material-ui/icons/UnarchiveTwoTone'
import NotificationIcon from '@material-ui/icons/Transform'

import AddCommonItem from '../../common/AddCommonItem'
import DataTable from '../../common/DataTable'
import Toaster from '../../common/Toaster'
import LoadingPage from '../../common/LoadingPage'

/** Import component section */
import { reactiveWorkflowDocument } from '../../../store/workflows/workflowActions'
import {
	SET_REACTIVE_QUOTE_IN_NOTICE,
	acceptRejectQuoteFromNoticeChange,
} from '../../../store/noticeChanges/NoticeChangeAction'
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import { caBeActiveQuote, canBeValidateModuleDevelopment } from '../../../store/helpers/RolesHelper'
import { converListToArray } from '../../../store/helpers/ListHelper'
import { COLOR_STATUS_ACEPTED, COLOR_STATUS_DANGER, COLOR_STATUS_REJECTED, COLOR_STATUS_SUCCESS } from '../../../store/helpers/StatusColorConstants'
import {
	Quote_AcceptedByEngineer,
	Quote_Declined_BySupplier,
	Quote_RejectedByEngineer,
	Quote_RespondBySupplier,
} from '../../../store/helpers/DevelopmentHelper'
import { showSuccessToaster, showErrorToaster } from '../../../store/helpers/ToasterHelpers'
import { ThumbDown, ThumbUp } from '@material-ui/icons'
import DialogCommentReject from './DialogCommentReject'
import { Paper } from '@material-ui/core'
import { APPROVAL_ACEPTED, APPROVAL_REJECTED } from '../../../store/helpers/ResourcesHelper'

/**
 * Container show notice
 */
class SuppliersCard extends Component {
	/**
	 * Create an instance notice
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openActiveQuote: false,
			quoteToActive: '',
			openRejectRequest: false,
			commentsReject: '',
			quoteSelected: '',
		}
	}

	/**
	 * Action to open reactive sample request
	 */
	onOpenActiveQuote(tokenQuote) {
		this.setState({
			openActiveQuote: true,
			quoteToActive: tokenQuote,
		})
	}

	/**
	 * accept quote
	 */
	onAcceptedQuotesClick = (event, item) => {
		event.stopPropagation()
		this.props
			.onAcceptRejectQuote(item.token, this.state.commentsReject, APPROVAL_ACEPTED, Quote_AcceptedByEngineer)
			.then(() => {
				this.setState({ openRejectRequest: false })
			})
	}
	/**
	 * onOpenRejectDialog
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onOpenRejectDialog(event, item) {
		event.stopPropagation()
		this.setState({
			openRejectRequest: true,
			quoteSelected: item,
		})
	}
	/**
	 * Function to add comment to decline
	 * @param {*} commentsReject
	 */
	updateRejectProperty(commentsReject) {
		this.setState({
			commentsReject: commentsReject,
		})
	}
	/**
	 * Function to trigger blur event and save data of input
	 *
	 * @param {*} event
	 * @memberof DetailsCard
	 */
	onKeyDown(event) {
		if (event.key === 'Enter') document.activeElement.blur()
	}
	onSaveStatusRejectQuote() {
		this.props
			.onAcceptRejectQuote(
				this.state.quoteSelected.token,
				this.state.commentsReject,
				APPROVAL_REJECTED,
				Quote_RejectedByEngineer
			)
			.then(() => {
				this.setState({ openRejectRequest: false })
			})
	}
	/**
	 * Action to reactive quote from supplier
	 * @param {*} commnents
	 */
	reactiveQuoteFromSupplier(commnents) {
		this.props
			.reactiveQuoteFromSupplier(this.state.quoteToActive, commnents)
			.then((response) => {
				this.props.changeQuoteActive(response)
				this.setState(showSuccessToaster('common.saveCorrectly'))
				this.setState({
					quoteToActive: '',
					openActiveQuote: false,
				})
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToSave'))
			})
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'folio') {
			if (item.type_request === 'NoticeOfChange') {
				return (
					<div>
						<NotificationIcon color='secondary' /> {item.folio}
					</div>
				)
			}
			if (item.type_request === 'QuoteSamples') {
				return (
					<div>
						<QuotesIcon color='primary' /> {item.folio}
					</div>
				)
			}
			if (item.type_request === 'SampleRequest') {
				return (
					<div>
						<SamplesIcon color='secondary' /> {item.folio}
					</div>
				)
			}
		}
		if (dataSource === 'type_request') {
			if (item.type_request === 'QuoteSamples') return <Translate id='quotes.quote' />
			else if (item.type_request === 'SampleRequest') return <Translate id='samplesrequest.samplesrequest' />
			else if (item.type_request === 'NoticeOfChange') return <Translate id='noticeChanges.noticeOfChange' />
		}
		if (dataSource === 'option') {
			if (
				item.type_request === 'QuoteSamples' &&
				item.actual_operation === Quote_RespondBySupplier &&
				canBeValidateModuleDevelopment(this.props.userRolesByCompany)
			) {
				return (
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						{this.props.isLoadingUpdateQuote ? (
							<IconButton>
								<ThumbUp />
							</IconButton>
						) : (
							<Tooltip title={<Translate id='quotes.textHelpAcceptQuote' />}>
								<IconButton
									disabled={this.props.isLoadingUpdateQuote}
									onClick={(event) => this.onAcceptedQuotesClick(event, item)}
									style={{ color: COLOR_STATUS_SUCCESS }}
								>
									<ThumbUp />
								</IconButton>
							</Tooltip>
						)}
						{this.props.isLoadingUpdateQuote ? (
							<IconButton>
								<ThumbDown />
							</IconButton>
						) : (
							<Tooltip title={<Translate id='quotes.textHelpRejectQuote' />}>
								<IconButton
									disabled={this.props.isLoadingUpdateQuote}
									onClick={(event) => this.onOpenRejectDialog(event, item)}
									style={{ color: COLOR_STATUS_DANGER }}
								>
									<ThumbDown />
								</IconButton>
							</Tooltip>
						)}
					</div>
				)
			}
			if (
				item.type_request === 'QuoteSamples' &&
				item.actual_operation === Quote_Declined_BySupplier &&
				caBeActiveQuote(this.props.userRolesByCompany)
			) {
				return (
					<Tooltip title={<Translate id='quotes.rectiveQuote' />}>
						<IconButton
							style={{ color: COLOR_STATUS_SUCCESS }}
							data-cy='btn-active'
							onClick={() => {
								this.onOpenActiveQuote(item.token)
							}}
						>
							<ReactiveIcon />
						</IconButton>
					</Tooltip>
				)
			} else return ''
		}
	}

	/**
	 * Get a filtered list of suppliers
	 *
	 * @returns
	 * @memberof SuppliersCard
	 */
	getFilteredSuppliersList(data) {
		return data.suppliers.filter((supplier) => {
			return supplier.supplier_token === this.props.user.profile.company_token
		})
	}

	/**
	 * On render row click
	 * @param {*} item
	 * @param {*} event
	 */
	onRowClick(item, event) {
		if (event.target.tagName !== 'svg') {
			if (item.type_request === 'QuoteSamples') {
				this.props.history.push(`/quotes/${item.token}`)
			} else if (item.type_request === 'SampleRequest') {
				this.props.history.push(`/samplerequests/${item.token}`)
			} else if (item.type_request === 'NoticeOfChange') {
				this.props.history.push(`/noticechanges/notification/${item.token}`)
			}
		}
	}
	setCustomColorRow(quotes) {
		quotes.map((item) => {
			var customColor
			if (item.actual_operation === Quote_AcceptedByEngineer) customColor = COLOR_STATUS_ACEPTED
			if (item.actual_operation === Quote_RejectedByEngineer) customColor = COLOR_STATUS_REJECTED
			return (item.custom_color = customColor)
		})
		return quotes
	}
	/**
	 * Get the companies list
	 *
	 * @returns
	 * @memberof CompaniesCard
	 */
	getSuppliersList() {
		const { noticeChange } = this.props
		if (noticeChange) {
			if (IsCurrentActiveDirectoryUser(this.props.user)) {
				return this.props.noticeChange.suppliers.map((supplier) => {
					let quotes = this.setCustomColorRow(supplier.quotes)
					return (
						<div key={supplier.supplier_token}>
							<DataTable
								title={
									<Typography variant='subtitle2' gutterBottom>
										<strong>
											{supplier.supplier_code} | {supplier.supplier_name.toUpperCase()}
										</strong>
									</Typography>
								}
								data={quotes}
								configuration={TableQuotesConfiguration}
								onRenderCellItem={this.onRenderCellItem}
								onRowClick={this.onRowClick}
							/>
							<br />
						</div>
					)
				})
			} else {
				return this.getFilteredSuppliersList(this.props.noticeChange).map((supplier) => {
					let quotes = this.setCustomColorRow(supplier.quotes)
					return (
						<div key={supplier.supplier_token}>
							<DataTable
								title={
									<Typography variant='subtitle2' gutterBottom>
										<strong>
											{supplier.supplier_code} | {supplier.supplier_name.toUpperCase()}
										</strong>
									</Typography>
								}
								data={quotes}
								configuration={TableQuotesConfiguration}
								onRenderCellItem={this.onRenderCellItem}
								onRowClick={this.onRowClick}
							/>
							<br />
						</div>
					)
				})
			}
		} else {
			return <LoadingPage />
		}
	}

	render() {
		return (
			<Paper>
				{this.getSuppliersList()}

				{/** Dialog to reactive quote decline by supplier */}
				<AddCommonItem
					title={<Translate id='quotes.rectiveQuote' />}
					message={<Translate id='quotes.messageReactiveQuote' />}
					open={this.state.openActiveQuote}
					onClose={() => {
						this.setState({ openActiveQuote: false })
					}}
					onSaveItem={this.reactiveQuoteFromSupplier}
					isSaving={this.props.isActive}
					isTextArea={true}
				/>
				<DialogCommentReject
					openRejectRequest={this.state.openRejectRequest}
					quoteSelected={this.state.quoteSelected}
					onClose={() => {
						this.setState({ openRejectRequest: false })
					}}
					updateRejectProperty={this.updateRejectProperty}
					commentsReject={this.state.commentsReject}
					onKeyDown={this.onKeyDown}
					onSaveStatusQuote={this.onSaveStatusRejectQuote}
					isSaving={this.props.isLoadingUpdateQuote}
				/>
				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Paper>
		)
	}
}

const TableQuotesConfiguration = {
	columns: [
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
		},
		{
			header: <Translate id='memos.documentType' />,
			dataSource: 'type_request',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'status',
		},
		{
			header: '',
			dataSource: 'option',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		isActive: state.workflow.get('isActive'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isLoadingUpdateQuote: state.noticechanges.get('isLoadingUpdateQuote'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		reactiveQuoteFromSupplier: (quoteToken, commnents) => {
			return dispatch(reactiveWorkflowDocument(quoteToken, commnents))
		},
		changeQuoteActive: (quote) => {
			dispatch({
				type: SET_REACTIVE_QUOTE_IN_NOTICE,
				quote: quote,
			})
		},
		onAcceptRejectQuote: (quoteToken, comments, statusQuote, statusQuoteNumber) => {
			return dispatch(acceptRejectQuoteFromNoticeChange(quoteToken, comments, statusQuote, statusQuoteNumber))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersCard)
