/** Helpers import section */
import axios from 'axios';
import moment from 'moment'
import {
    getAuthorizedRequestConfigurationUser,
    getAuthorizedRequestConfigurationUserParameters
} from '../helpers/SessionHelper';
import {
    descriptionStatus, backgroundColorTableStatus,
    getFiltersFolios
} from '../helpers/SustainabilityHelper'
export const START_LOADING_SUSTAINABILITY_LIST = "START_LOADING_SUSTAINABILITY_LIST";
export const SET_SUSTAINABILITY_COUNT = "SET_SUSTAINABILITY_COUNT";

export const SET_SUSTAINABILITY_LIST = "SET_SUSTAINABILITY_LIST";
export const IS_SAVING = "IS_SAVING";
export const IS_SAVINGTEMPLATE = "IS_SAVINGTEMPLATE";
export const ADD_SUSTAINABILITY = "ADD_SUSTAINABILITY";
export const SET_SUSTAINABILITY = "SET_SUSTAINABILITY"
export const UPDATE_SUSTAINABILITY = "UPDATE_SUSTAINABILITY"
export const UPDATE_WORKER_DETAIL = "UPDATE_WORKER_DETAIL"
export const SET_QUESTIONNAIRE = "SET_QUESTIONNAIRE"
export const UPDATE_QUESTION_IN_SUSTAINABILITY = "UPDATE_QUESTION_IN_SUSTAINABILITY"
export const UPDATE_ACTION_PLAN = "UPDATE_ACTION_PLAN"
export const SET_AUDIT_CALENDAR_LIST = "SET_AUDIT_CALENDAR_LIST"

export const ADD_TOPIC_IN_SUSTAINABILITY = "ADD_TOPIC_IN_SUSTAINABILITY"
export const ADD_SUBTOPIC_IN_SUSTAINABILITY = "ADD_SUBTOPIC_IN_SUSTAINABILITY"
export const ADD_QUESTION_IN_SUSTAINABILITY = "ADD_QUESTION_IN_SUSTAINABILITY"
export const DELETE_TOPIC_IN_SUSTAINABILITY = "DELETE_TOPIC_IN_SUSTAINABILITY"
export const DELETE_SUBTOPIC_IN_SUSTAINABILITY = "DELETE_SUBTOPIC_IN_SUSTAINABILITY"
export const DELETE_QUESTION_IN_SUSTAINABILITY = "DELETE_QUESTION_IN_SUSTAINABILITY"
export const UPDATE_TOPIC_IN_SUSTAINABILITY = "UPDATE_TOPIC_IN_SUSTAINABILITY"
export const UPDATE_SUBTOPIC_IN_SUSTAINABILITY = "UPDATE_SUBTOPIC_IN_SUSTAINABILITY"
export const UPDATE_QUESTIONNAIRE = "UPDATE_QUESTIONNAIRE"

export const UPDATE_SUPPLIERS_FILES_EVIDENCE = "UPDATE_SUPPLIERS_FILES_EVIDENCE"
/**
 * Action start loading common data
 * @param {*} isLoading 
 */
function isLoadingCommon(isLoading, identifier) {
    return {
        type: START_LOADING_SUSTAINABILITY_LIST,
        isLoading: isLoading,
        identifier: identifier
    }
}

/**
 * Action to set SUSTAINABILITY list 
 * @param {*} SUSTAINABILITY 
 */
function setSustainabilityList(sustainabilityList) {
    return {
        type: SET_SUSTAINABILITY_LIST,
        sustainabilityList: sustainabilityList
    }
}

/**
 * Action to set count
 * @param {*} count 
 */
function setTotalCount(count) {
    return {
        type: SET_SUSTAINABILITY_COUNT,
        count
    }
}

export function setSustainability(sustainability) {
    return {
        type: SET_SUSTAINABILITY,
        sustainability
    }
}

export function setTemplateQuestionnaire(templateQuestionnaire) {
    return {
        type: SET_QUESTIONNAIRE,
        templateQuestionnaire
    }
}

/**
 * Action start saving information
 * @param {*} isLoading 
 */
function isSavingCommon(isSaving, identifier) {
    return {
        type: IS_SAVING,
        isSaving: isSaving,
        identifier: identifier
    }
}

function updateSustainability(property, updateValue) {
    return {
        type: UPDATE_SUSTAINABILITY,
        property,
        updateValue
    }
}

function updateWorkerDetail(wordDetail) {
    return {
        type: UPDATE_WORKER_DETAIL,
        wordDetail: wordDetail,
    }
}

function updateQuestionInSustainability(topicId, subTopicId, question) {
    return {
        type: UPDATE_QUESTION_IN_SUSTAINABILITY,
        topicId,
        subTopicId,
        question
    }
}

function updateActionPlan(actionPlan, listActions) {
    return {
        type: UPDATE_ACTION_PLAN,
        actionPlan,
        listActions,
    }
}


function updateQuestionnaire(property, updateValue) {
    return {
        type: UPDATE_QUESTIONNAIRE,
        property,
        updateValue
    }
}


/**
 * Action load sustainability from company backend
 * @param {*} query 
 * @param {*} orderBy 
 * @param {*} sortAscending 
 * @param {*} page 
 * @param {*} rowsPerPage 
 */
export function loadAllSustainabilityFromBackEnd(queryParameters, translate) {
    return function (dispatch, getState) {

        dispatch(isLoadingCommon(true, START_LOADING_SUSTAINABILITY_LIST));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainabilities`;
            let command = {
                CompanyCode: selectedCompany.get("document_identifier"),
                PageNumber: (queryParameters && queryParameters.pageNumber) ? (parseInt(queryParameters.pageNumber) + 1) : 1,
                PageSize: (queryParameters && queryParameters.rowsPerPage) ? queryParameters.rowsPerPage : 20,
                SortingBy: ((queryParameters && queryParameters.sortBy) ? queryParameters.sortBy : "CreationDate"),
                SortingOrder: ((queryParameters && queryParameters.sortDirection) ? queryParameters.sortDirection : 'desc'),
                Query: (queryParameters && queryParameters.query) ? queryParameters.query : '',
                Filters: getFiltersFolios(queryParameters.query),
            };
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        let mappedResponse = serverResponse.data.Results.map(sustainability => {
                            sustainability.color_status_table = backgroundColorTableStatus(sustainability.Status)
                            sustainability.Status = descriptionStatus(sustainability.Status, translate)
                            return sustainability
                        })
                        dispatch(setTotalCount(serverResponse.data.TotalItems))
                        dispatch(setSustainabilityList(mappedResponse))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingCommon(false, START_LOADING_SUSTAINABILITY_LIST));
                    console.error("Error getting sustainability list by company", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isLoadingCommon(false, START_LOADING_SUSTAINABILITY_LIST));
        }
    }
}

/**
 * Action to add Sustainability 
 * @param {*} itemModel 
 */
export function addSustainability(itemModel) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, ADD_SUSTAINABILITY));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability`;

            let command = {
                Description: itemModel ? itemModel.comments : "",
                SupplierCode: itemModel ? itemModel.supplier.code : "",
                SupplierName: itemModel ? itemModel.supplier.name : "",
                CompanyCode: selectedCompany.get("document_identifier"),
            }
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(isSavingCommon(false, ADD_SUSTAINABILITY));
                        return Promise.resolve(serverResponse.data.Id)
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, ADD_SUSTAINABILITY));
                    console.error("Error in add sustainability", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isSavingCommon(false, ADD_SUSTAINABILITY));
        }
    }
}

/**
 * Action to load SUSTAINABILITY
 * @param {*} token 
 */
export function loadSustainabilityFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(isLoadingCommon(true, SET_SUSTAINABILITY));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${token}`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSustainability(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isLoadingCommon(false, SET_SUSTAINABILITY));
                console.error("Error getting sustainability", error.response)
                return Promise.reject(error.response)
            })
    }
}

export function loadTemplateQuestionnaire() {
    return function (dispatch, getState) {
        dispatch(isLoadingCommon(true, SET_QUESTIONNAIRE));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let selectedCompany = getState().profile.get("selectedCompany");
        let companyCode = selectedCompany.get("document_identifier")
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/questionnaire/templated/${companyCode}`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setTemplateQuestionnaire(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isLoadingCommon(false, SET_QUESTIONNAIRE));
                console.error("Error getting template", error.response)
                return Promise.reject(error.response)
            })
    }
}

/**
 * Action to update sustainability
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function updateSustainabilityFromBackend(id, property, propertyData) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_SUSTAINABILITY))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${id}`;
        let data = {
            [property]: propertyData
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateSustainability(property, propertyData));
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY))
                console.error("Error to update", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to update sustainability
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function updateWorkerDetailFromBackend(id, detailId, property, propertyData) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_SUSTAINABILITY))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${id}/workerDetail/${detailId}`;
        let data = {
            [property]: propertyData
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateWorkerDetail(serverResponse.data));
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY))
                console.error("Error to update", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to update question in sustainability
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function updateQuestionFromBackend(id, topicId, subTopicId, questionId, property, propertyData) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_SUSTAINABILITY))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${id}/questions/${questionId}`;
        let data = {
            [property]: propertyData
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateQuestionInSustainability(topicId, subTopicId, serverResponse.data));
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY))
                console.error("Error to update", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to change status
 * @param {*} status 
 * @param {*} sustainabilityId 
 * @param {*} comments 
 */
export function changeStatusFromBackEnd(status, sustainabilityId, comments = "") {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_SUSTAINABILITY));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${sustainabilityId}/status`
        let command = {
            Status: status,
            Comments: comments,
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    if (status !== 17) {
                        dispatch(setSustainability(serverResponse.data))
                        dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY));
                        return Promise.resolve()
                    }
                    dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY));
                console.error("Error update estatus sustainability", error.response)
                return Promise.reject(error.response)
            })
    }
}

/**
 * Action to update action plan in sustainability
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function updateActionPlanFromBackend(id, actionPlanId, property, propertyData, listActions) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_SUSTAINABILITY))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${id}/actionPlans/${actionPlanId}`;
        let data = {
            [property]: propertyData
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateActionPlan(serverResponse.data, listActions));
                    dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY));
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY))
                console.error("Error to update action plan", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to update action plan in sustainability from supplier
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function updateActionPlanFromSupplier(id, actionPlan, listActions) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_SUSTAINABILITY))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${id}/actionPlans/${actionPlan.Id}`;
        let data = {
            CommnetsSupplier: actionPlan.CommnetsSupplier,
            CommitmentDate: actionPlan.CommitmentDate
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateActionPlan(serverResponse.data, listActions));
                    dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY));
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY))
                console.error("Error to update action plan", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to update action plan in sustainability from auditor
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function updateActionPlanFromAuditor(id, actionPlan, listActions, checAproved) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_SUSTAINABILITY))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${id}/actionPlans/${actionPlan.Id}`;
        let data = {
            ComplianceDate: actionPlan.ComplianceDate,
            ApprovalDate: actionPlan.ApprovalDate,
            CommnetsPlant: actionPlan.CommnetsPlant,
            CheckAproved: checAproved,
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateActionPlan(serverResponse.data, listActions));
                    dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY));
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY))
                console.error("Error to update action plan", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Add evidence in action plan
 * @param {*} actionPlanId 
 * @param {*} files 
 * @returns 
 */
export function addEvidenceInActionPlan(id, actionPlanId, files, listActions) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_SUSTAINABILITY));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${id}/actionPlans/${actionPlanId}/evidences`;
        let formData = new FormData();
        files.forEach((file) => {
            formData.append('data', file);
        });
        return axios
            .post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateActionPlan(serverResponse.data, listActions));
                    dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY));
                console.error('Error add attachments in the action plan', error.response);
            });
    };
}

/**
 * Action to remove files to supplier from saplme
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeFileInActionPlan(id, actionPlanId, fileName, listActions) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${id}/actionPlans/${actionPlanId}/evidences`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateActionPlan(serverResponse.data, listActions));
                    dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY));
                    return Promise.resolve();
                }
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to upload order file(s) from supplier", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to change status
 * @param {*} status 
 * @param {*} sustainabilityId 
 * @param {*} comments 
 */
export function programAuditFromBackEnd(status, sustainabilityId, model) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_SUSTAINABILITY));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${sustainabilityId}/status`
        let command = {
            Status: status,
            ScheduledAuditDate: model.ScheduledAuditDate ? moment(model.ScheduledAuditDate).format('DD/MM/YYYY') : "",
            Comments: model.Comments,
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSustainability(serverResponse.data))
                    dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY));
                console.error("Error update estatus sustainability", error.response)
                return Promise.reject(error.response)
            })
    }
}

/**
 * Action to set AUDIT list 
 */
function setTotalAudit(auditCalendar) {
    return {
        type: SET_AUDIT_CALENDAR_LIST,
        auditCalendar: auditCalendar
    }
}

/**
 * Action to update evidence files in question in sustainability
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function updateSupplierEvidenceFromSupplier(id, topicId, subTopicId, questionId, files) {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, UPDATE_SUSTAINABILITY))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${id}/questions/${questionId}/evidences`;
        let formData = new FormData();
        files.forEach((file) => {
            formData.append('data', file);
        });
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateQuestionInSustainability(topicId, subTopicId, serverResponse.data));
                    return Promise.resolve();
                }
                return Promise.resolve();
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, UPDATE_SUSTAINABILITY))
                console.error("Error to update", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to remove evidence files in question in sustainability
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeSupplierEvidence(id, topicId, subTopicId, questionId, fileName) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${id}/questions/${questionId}/evidences`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateQuestionInSustainability(topicId, subTopicId, serverResponse.data));
                    return Promise.resolve();
                }
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to upload order file(s) from supplier", error.response)
                return Promise.reject();
            })
    }
}/**
 * Action load audit calendar
 */
export function loadAllAuditCalendar(queryParameters) {
    return function (dispatch, getState) {
        dispatch(isLoadingCommon(true, START_LOADING_SUSTAINABILITY_LIST));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/audits/programs`;
            let command = {
                CompanyCode: selectedCompany.get("document_identifier"),
            };
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setTotalAudit(serverResponse.data))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingCommon(false, START_LOADING_SUSTAINABILITY_LIST));
                    console.error("Error getting sustainability list by company", error.response)
                    return Promise.reject(error.response)
                })
        }
        else {
            dispatch(isLoadingCommon(false, START_LOADING_SUSTAINABILITY_LIST));
        }
    }
}
/**
 * Action start saving information
 * @param {*} isLoading 
 */
function isSavingTemplate(isSavingTemplate, identifier) {
    return {
        type: IS_SAVINGTEMPLATE,
        isSavingTemplate: isSavingTemplate,
        identifier: identifier
    }
}

function addTopicInList(molde) {
    return {
        type: ADD_TOPIC_IN_SUSTAINABILITY,
        molde
    }
}

/**
 * Action to add a new Topic 
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function addTopicFromBackend(questionnaireId, model) {
    return (dispatch, getState) => {
        dispatch(isSavingTemplate(true, ADD_TOPIC_IN_SUSTAINABILITY));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let selectedCompany = getState().profile.get("selectedCompany");
        let companyCode = selectedCompany.get("document_identifier")
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/questionnaire/topics`;
        let data = {
            QuestionnaireId: questionnaireId,
            Name: model.name,
            ShortName: model.shortName,
            Color: model.color ? model.color.value : '',
            CompanyCode: companyCode
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addTopicInList(serverResponse.data));
                    dispatch(isSavingTemplate(false, ADD_TOPIC_IN_SUSTAINABILITY));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingTemplate(false, ADD_TOPIC_IN_SUSTAINABILITY))
                console.error("Error to add new topic in sustainability", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to add a new subtopic
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function addSubTopicFromBackend(questionnaireId, topicId, model) {
    return (dispatch, getState) => {
        dispatch(isSavingTemplate(true, ADD_SUBTOPIC_IN_SUSTAINABILITY));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/questionnaire/subtopics`;
        let data = {
            QuestionnaireId: questionnaireId,
            TopicId: topicId,
            Name: model.name,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateTopicInList(serverResponse.data));
                    let porcentage = serverResponse.data;
                    dispatch(updateQuestionnaire("TotalPercentage", porcentage.TotalPercentage));
                    dispatch(isSavingTemplate(false, ADD_SUBTOPIC_IN_SUSTAINABILITY));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingTemplate(false, ADD_SUBTOPIC_IN_SUSTAINABILITY))
                console.error("Error to add subTopic in sustainability", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to add a new question
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function addQuestionFromBackend(questionnaireId, topicId, subtopicId, model) {
    return (dispatch, getState) => {
        dispatch(isSavingTemplate(true, ADD_QUESTION_IN_SUSTAINABILITY));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/questionnaire/questions`;
        let data = {
            QuestionnaireId: questionnaireId,
            Name: model.questionName,
            TopicId: topicId,
            SubtopicId: subtopicId,
            Percentage: model.percentage,
            QuestionType: model.questionType ? model.questionType.value : "",
            ClassificationType: model.questionType ? model.classificationType.value : "",
            Recommendation: model.recomendationQuestion,
            Warning: model.warning,
            CorrectAnswer: model.answer ? model.answer.value : '',
            RequiredDocument: model.requiredDocument.value === "1" ? true : false,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateTopicInList(serverResponse.data));
                    let porcentage = serverResponse.data;
                    dispatch(updateQuestionnaire("TotalPercentage", porcentage.TotalPercentage));
                    dispatch(isSavingTemplate(false, ADD_QUESTION_IN_SUSTAINABILITY));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingTemplate(false, ADD_QUESTION_IN_SUSTAINABILITY))
                console.error("Error to add question in sustainability", error.response)
                return Promise.reject();
            })
    }
}

function deleteTopicInList(topicId) {
    return {
        type: DELETE_TOPIC_IN_SUSTAINABILITY,
        topicId: topicId
    }
}

/**
 * Action to delete a Topic 
 * @param {*} questionnaireId 
 * @param {*} topicId 
 */
export function deleteTopicFromBackend(questionnaireId, itemMolde) {
    return (dispatch, getState) => {
        dispatch(isSavingTemplate(true, DELETE_TOPIC_IN_SUSTAINABILITY));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let selectedCompany = getState().profile.get("selectedCompany");
        let companyCode = selectedCompany.get("document_identifier")
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/questionnaire/${questionnaireId}/topics/${itemMolde.topicId}/${companyCode}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(deleteTopicInList(itemMolde.topicId));
                    let porcentage = serverResponse.data;
                    dispatch(updateQuestionnaire("TotalPercentage", porcentage.TotalPercentage));
                    dispatch(isSavingTemplate(false, DELETE_TOPIC_IN_SUSTAINABILITY));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingTemplate(false, DELETE_TOPIC_IN_SUSTAINABILITY))
                console.error("Error to delete topic in sustainability", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to delete a Topic 
 * @param {*} questionnaireId 
 * @param {*} topicId 
 */
export function deleteSubTopicFromBackend(questionnaireId, model) {
    return (dispatch, getState) => {
        dispatch(isSavingTemplate(true, DELETE_SUBTOPIC_IN_SUSTAINABILITY));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let selectedCompany = getState().profile.get("selectedCompany");
        let companyCode = selectedCompany.get("document_identifier")
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/questionnaire/${questionnaireId}/subtopics/${model.subTopicId}/${companyCode}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateTopicInList(serverResponse.data));
                    let porcentage = serverResponse.data;
                    dispatch(updateQuestionnaire("TotalPercentage", porcentage.TotalPercentage));
                    dispatch(isSavingTemplate(false, DELETE_SUBTOPIC_IN_SUSTAINABILITY));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingTemplate(false, DELETE_SUBTOPIC_IN_SUSTAINABILITY))
                console.error("Error to delete topic in sustainability", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to delete a Topic 
 * @param {*} questionnaireId 
 * @param {*} topicId 
 */
export function deleteQuestionFromBackend(questionnaireId, model) {
    return (dispatch, getState) => {
        dispatch(isSavingTemplate(true, DELETE_QUESTION_IN_SUSTAINABILITY));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let selectedCompany = getState().profile.get("selectedCompany");
        let companyCode = selectedCompany.get("document_identifier")
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/questionnaire/${questionnaireId}/questions/${model.questionId}/${companyCode}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateTopicInList(serverResponse.data));
                    let porcentage = serverResponse.data;
                    dispatch(updateQuestionnaire("TotalPercentage", porcentage.TotalPercentage));
                    dispatch(isSavingTemplate(false, DELETE_QUESTION_IN_SUSTAINABILITY));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingTemplate(false, DELETE_QUESTION_IN_SUSTAINABILITY))
                console.error("Error to delete topic in sustainability", error.response)
                return Promise.reject();
            })
    }
}

/**
 * 
 * @param {Update tipic} model 
 * @returns 
 */
function updateTopicInList(model) {
    return {
        type: UPDATE_TOPIC_IN_SUSTAINABILITY,
        model
    }
}

/**
 * Action to update question in sustainability
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function updateTopicFromBack(questionnaireId, model) {
    return function (dispatch, getState) {
        dispatch(isSavingTemplate(true, UPDATE_TOPIC_IN_SUSTAINABILITY));
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let selectedCompany = getState().profile.get("selectedCompany");
        let companyCode = selectedCompany.get("document_identifier")
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/questionnaire/${questionnaireId}/topics/${model.topicId}/${companyCode}`;
        let data = {
            Name: model.name,
            ShortName: model.shortName,
            ColorTopic: model.color ? model.color.value : ''
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateTopicInList(serverResponse.data));
                    let porcentage = serverResponse.data;
                    dispatch(updateQuestionnaire("TotalPercentage", porcentage.TotalPercentage));
                    dispatch(isSavingTemplate(false, UPDATE_TOPIC_IN_SUSTAINABILITY))
                }
            })
            .catch((error) => {
                dispatch(isSavingTemplate(false, UPDATE_TOPIC_IN_SUSTAINABILITY))
                console.error("Error to update", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to update question in sustainability
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function updateSubTopicFromBack(questionnaireId, model) {
    return function (dispatch, getState) {
        dispatch(isSavingTemplate(true, UPDATE_SUBTOPIC_IN_SUSTAINABILITY))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let selectedCompany = getState().profile.get("selectedCompany");
        let companyCode = selectedCompany.get("document_identifier")
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/questionnaire/${questionnaireId}/subtopics/${model.subTopicId}/${companyCode}`;
        let data = {
            Name: model.name,
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateTopicInList(serverResponse.data));
                    let porcentage = serverResponse.data;
                    dispatch(updateQuestionnaire("TotalPercentage", porcentage.TotalPercentage));
                    dispatch(isSavingTemplate(false, UPDATE_SUBTOPIC_IN_SUSTAINABILITY))
                }
            })
            .catch((error) => {
                dispatch(isSavingTemplate(false, UPDATE_SUBTOPIC_IN_SUSTAINABILITY))
                console.error("Error to update", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to update question in sustainability
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function updateQuestionFromBack(questionnaireId, model) {
    return function (dispatch, getState) {
        dispatch(isSavingTemplate(true, UPDATE_SUBTOPIC_IN_SUSTAINABILITY))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let selectedCompany = getState().profile.get("selectedCompany");
        let companyCode = selectedCompany.get("document_identifier")
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/questionnaire/${questionnaireId}/questions/${model.questionId}/${companyCode}`;
        let data = {
            Name: model.questionName,
            Percentage: model.percentage,
            QuestionType: model.questionType ? model.questionType.value : "",
            ClassificationType: model.questionType ? model.classificationType.value : "",
            Recommendation: model.recomendationQuestion,
            Warning: model.warning,
            CorrectAnswer: model.answer ? model.answer.value : '',
            RequiredDocument: model.requiredDocument.value === "1" ? true : false,
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateTopicInList(serverResponse.data));
                    let porcentage = serverResponse.data;
                    dispatch(updateQuestionnaire("TotalPercentage", porcentage.TotalPercentage));
                    dispatch(isSavingTemplate(false, UPDATE_SUBTOPIC_IN_SUSTAINABILITY))
                }
            })
            .catch((error) => {
                dispatch(isSavingTemplate(false, UPDATE_SUBTOPIC_IN_SUSTAINABILITY))
                console.error("Error to update", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to update index in question
 * @param {*} id 
 * @param {*} property 
 * @param {*} propertyData 
 */
export function setQuestionIndexFromBack(question, subtopicId) {
    return function (dispatch, getState) {
        dispatch(isSavingTemplate(true, UPDATE_SUBTOPIC_IN_SUSTAINABILITY))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/questionnaire/questions/index`;
        let data = {
            SubtopicId: subtopicId,
            QuestionId: question.draggableId ? question.draggableId : "",
            ToIndex: question ? question.destination.index : 0,
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateTopicInList(serverResponse.data));
                    dispatch(isSavingTemplate(false, UPDATE_SUBTOPIC_IN_SUSTAINABILITY))
                }
            })
            .catch((error) => {
                dispatch(isSavingTemplate(false, UPDATE_SUBTOPIC_IN_SUSTAINABILITY))
                console.error("Error to moving index question", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Download files
 * @param {*} id 
 * @param {*} fileName 
 * @param {*} extension 
 * @returns 
 */
export const downloadFiles = (id, fileName, extension) => {
    return function (dispatch, getState) {
        let url = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/evidences/${id}/${fileName}/${extension}`
        return axios.get(url, { responseType: 'arraybuffer' })
            .then(serverResponse => {
                if (serverResponse.status === 200) {
                    var blob = new Blob([serverResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${fileName}`;
                    a.click();
                    return Promise.resolve('Ok');
                }
            })
            .catch((error) => {
                console.error("Error donwload file", error)
                return Promise.reject();
            });
    }
}

export const downloadQuestionnaries = (token) => {
    return function (dispatch, getState) {
        let url = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/${token}/downloadReport/`
        return axios.get(url, { responseType: 'arraybuffer' })
            .then(serverResponse => {
                if (serverResponse.status === 200) {
                    var blob = new Blob([serverResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `ReporteCustionario`;
                    a.click();
                    return Promise.resolve('Ok');
                }
            })
            .catch((error) => {
                console.error("Error to download file", error)
                return Promise.reject();
            })
    }
}

/**
 * Action to duplicte questionnarie
 * @param {*} sustainabilityId 
 * @param {*} comments 
 * @returns 
 */
export function duplicateQuestionnarie(sustainabilityId, comments = "") {
    return function (dispatch, getState) {
        dispatch(isSavingCommon(true, ADD_SUSTAINABILITY));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/sustainability/duplicate`
        let command = {
            SustentabilityId: sustainabilityId,
            Comments: comments,
        }
        return axios.post(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingCommon(false, ADD_SUSTAINABILITY));
                    return Promise.resolve(serverResponse.data.Id)
                }
            })
            .catch((error) => {
                dispatch(isSavingCommon(false, ADD_SUSTAINABILITY));
                console.error("Error duplicate questionnarie", error.response)
                return Promise.reject(error.response)
            })
    }
}