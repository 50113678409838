import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import autoBind from 'auto-bind';

/** Components imports section */
import OptionsMenu from './../../common/OptionsMenu';

/** Material UI imports section */
import {
    Card, CardHeader, Typography,
    CardContent, Grid, TextField,
    Paper, List, ListItem,
    ListItemText, Divider, Tooltip
} from '@material-ui/core';
import AcceptedIcon from '@material-ui/icons/ThumbUp';
import RejectedIcon from '@material-ui/icons/ThumbDown';

/** Actions and helpers immports section */
import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper';
import AcceptRejectDialog from '../../common/AcceptRejectDialog';

class InvestigationLines extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            itemToken: null,
            showMenu: true,
            investigationLines: this.props.investigationLines ? this.props.investigationLines : {},
            openModalAuthorization: false
        }
    }

    /**
     * React life-cycle methos
     * update state if props has changes
     * 
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof InvestigationLines
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.investigationLines && (prevProps.investigationLines.status !== this.props.investigationLines.status)) {
            this.setState({
                investigationLines: this.props.investigationLines
            })
        }
    }


    /**
     * Handle click of options menu component to set item Token in state nd open menu
     *
     * @param {*} token
     * @memberof InvestigationLines
     */
    handleClickOptionsMenu(token) {
        this.setState({
            itemToken: token
        })
    };

    /**
     * Handle on close optons menu to set void item token in state 
     *
     * @memberof InvestigationLines
     */
    handleCloseOptionsMenu() {
        if (!this.state.openModalAuthorization) {
            this.setState({
                itemToken: '',
                showMenu: false
            }, () => {
                this.setState({ showMenu: true })
            });
        }
    };

    /**
     * Handle dialog status to show or hide 
     *
     * @param {*} dialogId
     * @memberof InvestigationLines
     */
    handleOpenItemDialog(dialogId, documentType = '', isReject = false) {
        this.setState({
            [dialogId]: !this.state[dialogId],
            documentType,
            isReject
        }, () => {
            if (!this.state[dialogId])
                this.handleCloseOptionsMenu()
        });
    }

    /**
     * Handle on accept or reject 
     *
     * @param {*} comment
     * @memberof InvestigationLines
     */
    onAcceptReject(comment) {
        this.props.acceptReject(comment, this.props.sacToken, !this.state.isReject)
            .then(() => {
                this.props.showSuccessToaster();
                this.handleOpenItemDialog('openModalAuthorization');
            })
            .catch(() => {
                this.props.showErrorToaster();
            })
    }

    /**
     * Render Action button in card header of investigation lines
     *
     * @returns
     * @memberof InvestigationLines
     */
    renderAction() {
        let isExternalUser = IsCurrentNotActiveDirectoryUser(this.props.user);
        var options = [];
        options.push({
            itemClick: () => this.handleOpenItemDialog('openModalAuthorization', this.props.investigationLines.document_type),
            tooltipTranslation: <Translate id="common.accept" />,
            menuItemIcon: <AcceptedIcon color='secondary' />
        });
        options.push({
            itemClick: () => this.handleOpenItemDialog('openModalAuthorization', this.props.investigationLines.document_type, true),
            tooltipTranslation: <Translate id="common.reject" />,
            menuItemIcon: <RejectedIcon color='error' />
        });

        if (this.props.investigationLines && this.props.investigationLines.status === 1)
            return (<Tooltip title={<Translate id="common.accepted" />}>
                <AcceptedIcon color='secondary' />
            </Tooltip>)
        else if (this.props.investigationLines && this.props.investigationLines.status === 2)
            return (<Tooltip title={<Translate id="common.rejected" />}>
                <RejectedIcon color='error' />
            </Tooltip>)
        else if (!isExternalUser && this.props.canBeUpdateInternal && this.state.showMenu)
            return (
                <OptionsMenu
                    itemToken={'item.token'}
                    handleClickOptions={this.handleClickOptionsMenu}
                    open={this.state.itemToken === 'item.token'}
                    handleClickAway={this.handleCloseOptionsMenu}
                    options={options}
                />
            )
    }

    /**
     * Render title section
     *
     * @returns
     * @memberof InvestigationLines
     */
    renderCardHeader() {
        return (
            <CardHeader
                title={<Typography variant="button"><Translate id="sac.fiveWhy.problemCause" /></Typography>}
                subheader={<Typography variant="subtitle2"><Translate id="sac.fiveWhy.investigationLines" /> </Typography>}
                action={this.renderAction()
                }
            />
        )
    }

    /**
     * Render grid items with 5 Why´s data
     *
     * @returns
     * @memberof InvestigationLines
     */
    renderFiveWhyReadOnly() {
        let whyArray = [];
        for (let why = 1; why < 6; why++) {
            whyArray.push(
                <ListItem key={'line_investigation_why' + why} divider>
                    <ListItemText
                        primary={<Typography variant="caption" style={{ width: 'inherit !important' }}>{why}. <Translate id="sac.fiveWhy.why"/> </Typography>}
                        secondary={
                            <Typography component="span" variant="body2" color="textPrimary">
                                {this.state.investigationLines ?
                                    (this.state.investigationLines['line_investigation_why' + why] ?
                                        this.state.investigationLines['line_investigation_why' + why] : '--'
                                    ) : '--'}
                            </Typography>
                        }
                    />
                </ListItem>
            )
        }
        return (<Grid item xs={12}><Paper><List>{whyArray}</List></Paper></Grid>)
    }

    /**
     * Render grid items with text field to set 5 Why´s data
     *
     * @returns
     * @memberof InvestigationLines
     */
    renderFiveWhyTable() {
        let whyArray = [];
        for (let why = 1; why < 6; why++) {
            whyArray.push(
                <Grid item xs={12}>
                    <TextField
                        id={'line_investigation_why' + why}
                        label={<Typography variant="caption" style={{ width: 'inherit !important' }}>{why}. <Translate id="sac.fiveWhy.why" /></Typography>}
                        fullWidth
                        variant='outlined'
                        multiline
                        rows={1}
                        rowsMax={4}
                        defaultValue={this.state.investigationLines ? this.state.investigationLines['line_investigation_why' + why] ? this.state.investigationLines['line_investigation_why' + why] : '' : ''}
                        onBlur={(event) => this.props.updatePropertyInvestigationLines(this.props.sacToken, event.target)}
                        onChange={(event) => this.props.updateProperty(event.target, 'investigationLines')}
                    // onKeyDown={this.props.onKeyDown}
                    />
                </Grid>
            )
        }
        return whyArray
    }

    /**
     * render root cause data
     *
     * @returns
     * @memberof InvestigationLines
     */
    renderRootCauseAndCommentsReadOnly() {
        return (
            <Paper>
                <List>
                    <ListItem key={'cause_identified'}>
                        <ListItemText
                            primary={<Typography variant="caption" style={{ width: 'inherit !important' }}><Translate id="sac.fiveWhy.rootCause"/> </Typography>}
                            secondary={
                                <Typography component="span" variant="body2" color="textPrimary">
                                    {this.state.investigationLines ?
                                        (this.state.investigationLines['cause_identified'] ?
                                            this.state.investigationLines['cause_identified'] : '--'
                                        ) : '--'}
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider />
                    {this.state.investigationLines.fiveWhys_comments &&
                        <ListItem key={'fiveWhys_comments'}>
                            <ListItemText
                                primary={<Typography variant="caption" style={{ width: 'inherit !important' }}><Translate id="sac.inspectorComments"/> </Typography>}
                                secondary={
                                    <Typography component="span" variant="body2" color="textPrimary">
                                        {this.state.investigationLines ?
                                            (this.state.investigationLines['fiveWhys_comments'] ?
                                                this.state.investigationLines['fiveWhys_comments'] : '--'
                                            ) : '--'}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    }
                    <Divider />
                </List>
            </Paper>
        )
    }

    /**
     * Render root cuse text field
     *
     * @returns
     * @memberof InvestigationLines
     */
    renderRootCause() {
        return (
            <React.Fragment>
                <TextField
                    id={'cause_identified'}
                    label={<Translate id="sac.fiveWhy.rootCause"/>}
                    fullWidth
                    variant='outlined'
                    multiline
                    rows={10}
                    rowsMax={20}
                    defaultValue={this.state.investigationLines ? this.state.investigationLines['cause_identified'] ? this.state.investigationLines['cause_identified'] : '' : ''}
                    onChange={(event) => this.props.updateProperty(event.target, 'investigationLines')}
                    onBlur={(event) => this.props.updatePropertyInvestigationLines(this.props.sacToken, event.target)}
                />
                {this.state.investigationLines.fiveWhys_comments &&
                    <Paper>
                        <List>
                            <ListItem key={'fiveWhys_comments'}>
                                <ListItemText
                                    primary={<Typography variant="caption" style={{ width: 'inherit !important' }}><Translate id="sac.inspectorComments"/> </Typography>}
                                    secondary={
                                        <Typography component="span" variant="body2" color="textPrimary">
                                            {this.state.investigationLines ?
                                                (this.state.investigationLines['fiveWhys_comments'] ?
                                                    this.state.investigationLines['fiveWhys_comments'] : '--'
                                                ) : '--'}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <Divider />
                        </List>
                    </Paper>
                }
            </React.Fragment>)
    }

    /**
     * Perform the render of component
     *
     * @returns
     * @memberof InvestigationLines
     */
    render() {
        let isExternalUser = IsCurrentNotActiveDirectoryUser(this.props.user);
        return (
            <Card>
                {this.renderCardHeader()}
                <CardContent>
                    <Grid container spacing={24}>
                        <Grid item xs={6}>
                            <Grid container spacing={24}>
                                {(!isExternalUser || !this.props.canBeUpdateSupplier) && this.renderFiveWhyReadOnly()}
                                {(isExternalUser && this.props.canBeUpdateSupplier) && this.renderFiveWhyTable()}
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            {(!isExternalUser || !this.props.canBeUpdateSupplier) && this.renderRootCauseAndCommentsReadOnly()}
                            {(isExternalUser && this.props.canBeUpdateSupplier) && this.renderRootCause()}
                        </Grid>
                    </Grid>
                </CardContent>
                <AcceptRejectDialog
                    open={this.state.openModalAuthorization}
                    isReject={this.state.isReject}
                    additionalTitle={': ' + this.props.translate("sac.fiveWhy.investigationLines")}
                    onClickAccept={this.onAcceptReject}
                    onClickReject={this.onAcceptReject}
                    onClickCloseCancel={() => this.handleOpenItemDialog('openModalAuthorization')}
                    isSaving={this.props.isSaving}
                />
            </Card >
        );
    }
}

export default InvestigationLines;