
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';

import MaterialIcon from '@material-ui/icons/FileCopyTwoTone';
import QuotesIcon from '@material-ui/icons/AttachMoneyTwoTone';
import SamplesIcon from '@material-ui/icons/UnarchiveTwoTone';
import RefreshIcon from '@material-ui/icons/Refresh';
import PowerSettingIcon from '@material-ui/icons/PowerSettingsNew';

import LoadingPage from '../common/LoadingPage';
import DataTable from '../common/DataTable';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import AddCommonItem from '../common/AddCommonItem';
import { loadMaterialDeliveryFromBackEnd, updateDataMaterialDeliveryFromBackend, finalizeMaterialDeliveryFromBackend } from '../../store/materialDelivery/MaterialDeliveryAction';
import { AppBar, Tabs } from '@material-ui/core';
import CostSummary from '../materialDelivery/components/CostSummary';
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper';
import {canBeValidateModuleDevelopment} from '../../store/helpers/RolesHelper';
import { converListToArray } from '../../store/helpers/ListHelper'

/**
 * Container show material delivery
 */
class Show extends Component {

    /**
     * Create an instance material delivery
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openUpdateMaterialDelivery: false,
            openFinalizeMaterialDelivery: false,
            activeTab: 0,

        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let materialToken = this.props.match.params.token;
        this.props.loadMaterialDelivery(materialToken);
    }

    /**
     * On update material delivery
     */
    onUpdateMaterialDelivery() {
        this.setState({
            openUpdateMaterialDelivery: true,
        })
    }

    /**
     * On finalize material delivery
     */
    onFinalizeMaterialDelivery() {
        this.setState({
            openFinalizeMaterialDelivery: true,
        })
    }

    /**
     * Update information of material delivery
     */
    onUpdateMaterialDeliveryFromBackend() {
        this.props.updateDataMaterialDeliveryFromBackend()
            .then(() => {
                this.setState({
                    openUpdateMaterialDelivery: false
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            })
    }

    /**
     * Finalize un project of an material delivery
     */
    onFinalizeMaterialDeliveryFromBackend() {
        let materialToken = this.props.match.params.token;
        this.props.finalizeMaterialDeliveryFromBackend(materialToken)
            .then(() => {
                this.setState({
                    openFinalizeMaterialDelivery: false
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            })
    }

    /**
     * Method to change the active tab
     */
    onClickToActiveTab = (event, value) => {
        this.setState({ activeTab: value });
    };

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoadingMaterialDelivery === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.materialDelivery !== null) {
            const { classes } = this.props;
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <InformationMaterialDeliveryCard {...this.props} />
                        <AppBar position="static" color="default">
                            <Tabs indicatorColor="secondary" textColor="secondary"
                                className={classes.styleTab}
                                value={this.state.activeTab}
                                onChange={this.onClickToActiveTab}>
                                <Tab label={<Translate id="common.supplier"/>} />
                                <Tab label={<Translate id="common.costSummary"/>} />
                            </Tabs>
                        </AppBar>
                        {this.state.activeTab === 0 &&
                            <Grid item xs={12} >
                                <Divider />
                                <SuppliersCard {...this.props} />
                            </Grid>
                        }
                        {this.state.activeTab === 1 &&
                            <Grid item xs={12}>
                                <Divider />
                                <CostSummary {...this.props} />
                            </Grid>}
                    </Grid>
                </Grid>
            )
        }
    }

    render() {
        let folioNotice = this.props.materialDelivery ? this.props.materialDelivery.folio : "";
        const { classes } = this.props;
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <MaterialIcon />
                        </Avatar>
                    }
                    action={
                        <div className={classes.actionsToolbar}>{
                            this.props.materialDelivery && !this.props.materialDelivery.is_closed && !this.props.materialDelivery.status_is_closed &&
                            canBeValidateModuleDevelopment(this.props.userRolesByCompany) &&
                            <span>
                                <Tooltip title={<Translate id="materialDelivery.updateMaterial"/>} ><IconButton color="primary"
                                    data-cy="btn-edit"
                                    className={classes.edit}
                                    onClick={this.onUpdateMaterialDelivery}>
                                    <RefreshIcon />
                                </IconButton>
                                </Tooltip>

                                <Tooltip title={<Translate id="materialDelivery.finalizeMaterial"/>} ><IconButton color="primary"
                                    data-cy="btn-edit"
                                    className={classes.edit}
                                    onClick={this.onFinalizeMaterialDelivery}>
                                    <PowerSettingIcon />
                                </IconButton>
                                </Tooltip>
                            </span>
                        }
                        </div>
                    }
                    title={<Typography variant="button"><Translate id="noticeChanges.noticeInformation"/>: <strong>{folioNotice}</strong></Typography>}
                />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {this.state.openUpdateMaterialDelivery &&
                    <AddCommonItem
                        title={<Translate id="materialDelivery.finalizeMaterialTitle"/>}
                        message={<Translate id="materialDelivery.finalizeDataMaterial"/>}
                        open={this.state.openUpdateMaterialDelivery}
                        onClose={() => { this.setState({ openUpdateMaterialDelivery: false }) }}
                        onSaveItem={this.onUpdateMaterialDeliveryFromBackend}
                        isSaving={this.props.isSaving}
                        isMessage={true}
                    />}

                {this.state.openFinalizeMaterialDelivery &&
                    <AddCommonItem
                        title={<Translate id="materialDelivery.finalizeMaterialTitle"/>}
                        message={<Translate id="materialDelivery.finalizeDataMaterial"/>}
                        open={this.state.openFinalizeMaterialDelivery}
                        onClose={() => { this.setState({ openFinalizeMaterialDelivery: false }) }}
                        onSaveItem={this.onFinalizeMaterialDeliveryFromBackend}
                        isSaving={this.props.isSaving}
                        isMessage={true}
                    />}
            </Card>
        )
    }
}

/**
 * Information InformationMaterialDeliveryCard
 *
 * @class InformationMaterialDeliveryCard
 * @extends {Component}
 */
class InformationMaterialDeliveryCard extends Component {
    render() {
        return (
            <Paper>
                <List>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="materialDelivery.folioMaterial"/>:</Typography>} />
                        <ListItemSecondaryAction>
                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>{this.props.materialDelivery.folio}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="memos.project"/>:</Typography>} />
                        <ListItemSecondaryAction>
                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>{this.props.materialDelivery.project_name}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom>{"Volumen "}:</Typography>} />
                        <ListItemSecondaryAction>
                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>{this.props.materialDelivery.project_volume}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="memos.stage"/>:</Typography>} />
                        <ListItemSecondaryAction>
                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>{this.props.materialDelivery.stage}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="materialDelivery.deliveryDate"/>:</Typography>} />
                        <ListItemSecondaryAction>
                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>{this.props.materialDelivery.mem_com_date}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="common.comments"/>:</Typography>} />
                        <ListItemSecondaryAction>
                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>{this.props.materialDelivery.developer_comments}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="common.status"/>:</Typography>} />
                        <ListItemSecondaryAction>
                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>{this.props.materialDelivery.status_description}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>
            </Paper>
        );
    }
}


/**
 * Information suppliers
 */
class SuppliersCard extends Component {

    /**
    * On render cell item custom
    * @param {*} dataSource 
    * @param {*} item 
    */
    onRenderCellItem(dataSource, item) {
        if (dataSource === "folio") {
            if (item.type_request === "QuoteSamples") {
                return (<div><QuotesIcon color="primary" /> {item.folio}</div>)
            }
            if (item.type_request === "SampleRequest") {
                return (<div><SamplesIcon color="secondary" /> {item.folio}</div>)
            }
        }
        if (dataSource === "type_request") {
            if (item.type_request === "QuoteSamples")
                return (<Translate id="quotes.quote" />)
            else if (item.type_request === "SampleRequest")
                return (<Translate id="samplesrequest.samplesrequest" />)
        }
    }

    /**
     * Get a filtered list of suppliers 
     *
     * @returns
     * @memberof SuppliersCard
     */
    getFilteredSuppliersList(data) {
        return data.suppliers.filter(supplier => {
            return supplier.supplier_token === this.props.user.profile.company_token
        })
    }

    /**
    * Get the companies list
    *
    * @returns
    * @memberof CompaniesCard
    */
    getSuppliersList() {
        const { materialDelivery } = this.props;
        if (materialDelivery) {
            if (IsCurrentActiveDirectoryUser(this.props.user)) {
                return (this.props.materialDelivery.suppliers.map((supplier) => {
                    return (
                        <DataTable
                            title={<Typography variant="subtitle2" gutterBottom><strong>{supplier.supplier_code} | {supplier.supplier_name.toUpperCase()}</strong></Typography>}
                            data={supplier.quotes}
                            configuration={TableQuotesConfiguration}
                            onRenderCellItem={this.onRenderCellItem}
                            onRowClick={(item) => {
                                if (item.type_request === "QuoteSamples") {
                                    this.props.history.push(`/quotes/${item.token}`)
                                }
                                else if (item.type_request === "SampleRequest") {
                                    this.props.history.push(`/samplerequests/${item.token}`)
                                }
                            }}
                        />
                    )
                }))
            }
            else {
                return (this.getFilteredSuppliersList(this.props.materialDelivery).map((supplier) => {
                    return (
                        <DataTable
                            title={<Typography variant="subtitle2" gutterBottom><strong>{supplier.supplier_code} | {supplier.supplier_name.toUpperCase()}</strong></Typography>}
                            data={supplier.quotes}
                            configuration={TableQuotesConfiguration}
                            onRenderCellItem={this.onRenderCellItem}
                            onRowClick={(item) => {
                                if (item.type_request === "QuoteSamples") {
                                    this.props.history.push(`/quotes/${item.token}`)
                                }
                                else if (item.type_request === "SampleRequest") {
                                    this.props.history.push(`/samplerequests/${item.token}`)
                                }
                            }}
                        />
                    )
                }))
            }
        } else {
            return <LoadingPage />;
        }
    }

    render() {
        return (
            <Paper>
                {this.getSuppliersList()}
            </Paper>
        )
    }
}

const TableQuotesConfiguration = {
    columns: [{
        header: <Translate id="common.folio" />,
        dataSource: 'folio'
    }, {
        header: <Translate id="memos.documentType" />,
        dataSource: 'type_request',
    }, {
        header: <Translate id="common.status" />,
        dataSource: 'status'
    }]
}


/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let materialDelivery = state.materialDelivery.get("materialDeliveryInformation");
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        user: user,
        isLoadingMaterialDelivery: state.materialDelivery.get('isLoadingMaterialDelivery'),
        materialDelivery: materialDelivery ? materialDelivery.toJS() : null,
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadMaterialDelivery: (token) => {
            return dispatch(loadMaterialDeliveryFromBackEnd(token));
        },
        updateDataMaterialDeliveryFromBackend: () => {
            return dispatch(updateDataMaterialDeliveryFromBackend());
        },
        finalizeMaterialDeliveryFromBackend: (token) => {
            return dispatch(finalizeMaterialDeliveryFromBackend(token));
        },
    }
}

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    actionsToolbar: {
        display: "flex"
    },
    styleTab: {
        backgroundColor: "#fff",
    },
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));