import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useHistory, useParams } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import { Tab, Tabs } from '@material-ui/core'

/** Components */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import IndexList from './IndexList'
import IndexSAP from './IndexSAP'

const Index = (props) => {
	const { classes } = props
	const history = useHistory()
	const { activeTab } = useParams()

	/** Get user Information*/
	const userProfile = useSelector((state) => state.oidc.user)
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')

	const handleChange = (event, newValue) => {
		history.push(`/sparePartPriceChange/${newValue}`)
	}

	const renderTabContent = () => {
		switch (activeTab) {
			case tabs.SpareParts:
				return <IndexList />
			case tabs.Prices:
				return <IndexSAP />
			default:
				return <IndexList />
		}
	}

	return (
		<>
			{isInternalUser() && (
				<>
					<Tabs value={activeTab} onChange={handleChange} className={classes.root} aria-label='Tabs'>
						<Tab className={classes.tabItem} label={'REFACCIONES'} {...a11yProps(tabs.SpareParts)} />
						<Tab className={classes.tabItem} label={'PRECIOS'} {...a11yProps(tabs.Prices)} />
					</Tabs>
					{renderTabContent()}
				</>
			)}
			{!isInternalUser() && <IndexList />}
		</>
	)
}

const tabs = {
	SpareParts: 'spare-parts',
	Prices: 'prices',
}

const a11yProps = (id) => {
	return {
		id: `tab-${id}`,
		value: `${id}`,
		'aria-controls': `simple-tabpanel-${id}`,
	}
}

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.common.white,
		minHeight: '30px !important',
	},
	// tabItem: {
	// 	minHeight: '30px !important',
	// },
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Index)))
