import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
import Avatar from '@material-ui/core/Avatar'
import TemplatesIcon from '@material-ui/icons/LibraryBooks'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import { Tooltip, Typography, Card, CardContent, CardHeader, Fab } from '@material-ui/core'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import DataTable from '../../common/DataTable'
import LoadingPage from '../../common/LoadingPage'
import Toaster from '../../common/Toaster'
import CardCreateTemplated from './components/CardCreateTemplated'

import { loadTemplatesFromBackEnd, createTemplate } from '../../../store/questionnaires/QuestionnairesAction'
import { converListToArray } from '../../../store/helpers/ListHelper'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { showInfoToaster } from '../../../store/helpers/ToasterHelpers'
import { canBeModuleSustantabilityQuestionnaire } from '../../../store/helpers/RolesHelper'

/**
 * Component to show templated
 */
class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openModalAddTemplate: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadTemplates()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadTemplates()
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		if (this.props.isLoadingTemplates === true) {
			return <LoadingPage />
		} else if (this.props.templates === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.templates}
					configuration={TableConfiguration()}
					showFilters
					isIndex
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(template) => {
						this.props.history.push(`/templates/${template.token}`)
					}}
				/>
			)
		}
	}

	/**
	 * Get moemos counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getSacsCounterMessage() {
		if (this.props.templates) {
			let totalQuestionnaires = this.props.templates.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalQuestionnaires}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Open add templated
	 */
	onClickAddTemplated() {
		this.setState({
			openModalAddTemplate: true,
		})
	}

	/**
	 * Function to send Questionnair
	 */
	createTeplated(template) {
		return this.props
			.createTemplate(template.name, template.questionnaireType)
			.then((responseToken) => {
				this.props.history.push(`/templates/${responseToken}`)
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showInfoToaster(!IsNullOrEmpty(error) ? error : 'common.errorToAdd'))
				return Promise.reject()
			})
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof Sacs
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<TemplatesIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							{canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany) && (
								<Tooltip title={<Translate id='questionnaire.addTemplated' />}>
									<Fab
										size='small'
										color='primary'
										onClick={() => {
											this.onClickAddTemplated()
										}}
									>
										<AddtIcon />
									</Fab>
								</Tooltip>
							)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='questionnaire.templates' />{' '}
						</Typography>
					}
					subheader={this.getSacsCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				<CardCreateTemplated
					open={this.state.openModalAddTemplate}
					addItem={this.createTeplated}
					onClose={() => {
						this.setState({ openModalAddTemplate: false })
					}}
					isSaving={this.props.isSavingTemplate}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableConfiguration() {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='questionnaire.questionnaireType' />,
			dataSource: 'description_questionnaire_type',
		},
		{
			header: <Translate id='questionnaire.nameQuestionnaire' />,
			dataSource: 'name',
		},
		{
			header: <Translate id='common.createBy' />,
			dataSource: 'create_by',
		},
	]

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingTemplates: state.questionnaires.get('isLoadingTemplates'),
		templates: state.questionnaires.get('templates') ? state.questionnaires.get('templates').toJS() : null,
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isSavingTemplate: state.questionnaires.get('isSavingTemplate'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadTemplates: () => {
			dispatch(loadTemplatesFromBackEnd())
		},
		createTemplate: (name, questionnaireType) => {
			return dispatch(createTemplate(name, questionnaireType))
		},
	}
}

const styles = (theme) => ({
	logo: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
