
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import { Card, Divider, CardContent, Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LoadingPage from '../common/LoadingPage';
import FloatingButton from '../common/FloatingButton';
import DialogAvailableOperations from '../common/DialogAvailableOperations';
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow';
import Toaster from '../common/Toaster';
import GeneralInformation from './components/GeneralInformation';
import MaterialsMaquilads from './components/MaterialsMaquilads';
import MaterialsSend from './components/MaterialsSend'
import AddCommonItem from '../common/AddCommonItem';

import { loadSupplierCode } from '../../store/suppliers/SupplierActions';
import {
    loadMaquilaOrderFromBackEnd, addMaterialMachined,
    updateMaterialMachinedFromBack, removeMaterialMachinedBackend,
    updateMaquilaOrderFromBack, sendMaquialOrderToSupplier,
    cancelMaquilaOrderFromBackend, uploadFilesMaquilaOrder,
    removeFilesMaquilaOrder, updateMaterialsFromSupplier,
    uploadFilesInMaterialFromSupplier, removeFilesMaterialFromSupplier,
    updateCommentsMaquilaOrder, closeMaquilaOrderFromBackend,
    uploadFilesDetailMachinedsInMaterial, removeFilesDetailMachinedsInMaterial,
    sendNotificationFromBackend, updatProductMachineds,
    addMoldInMaquila, updateMoldeMaquila, deleteMoldInMaquila,
    downloadMaquilaOrderExcel,
    addUserIInNotificationMaquila,
    addToolingInMaquila,
    deleteToolingInMaquila,
    uploadFilesToolings,
    deleteFilesTooling,
    changeStatusMaquilaOrderFromBackend
} from '../../store/maquilaOrders/MaquilaOrderActions';
import {
    searchUsersInRole
} from '../../store/administration/InternalActions'

import { converListToArray } from '../../store/helpers/ListHelper'
import { showSuccessToaster, showErrorToaster } from '../../store/helpers/ToasterHelpers';
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper';
import {
    Maquila_Orders_Valid_Planning, Maquila_Orders_Rejected_Planning,
    Maquila_Orders_Send_Warehouse, Maquila_Orders_Valid_Supplier, Cancel
} from '../../store/helpers/DevelopmentHelper'
import { ROLE_SUPPLIER_LOGISTICPLANNER } from '../../store/helpers/RolesHelper'

/**
 * Container show shippingOrderToken
 */
class Show extends Component {

    /**
     * Create an instance maquilaOrder
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            activeTab: 0,
            openAvailableOperationsDialog: false,
            openChangeStatusOrder: false,
            statusAction: 0,
            userSupplier: [],
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let maquilaOrderToken = this.props.match.params.token;
        this.props.loadMaquilaOrder(maquilaOrderToken);
    }

    /**
     * componentDidUpdate
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof Index
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.maquilaOrder !== null) {
            /// Get information full from maquilaOrder
            let maquilaOrder = this.props.maquilaOrder;
            this.props.loadSupplierCode(maquilaOrder.supplier_code);
            this.props.searchUsersInRole(ROLE_SUPPLIER_LOGISTICPLANNER, this.props.maquilaOrder.supplier_code)
                .then((users) => {
                    this.setState({ userSupplier: users });
                })
        }
    }

    /**
     * Method to change the active tab
     */
    onClickToActiveTab = (event, value) => {
        this.setState({ activeTab: value });
    };

    /**
     * Action to set state to open of available operations dialog
     * @memberof 
     */
    setOpenDialog = (openAvailable) => {
        this.setState({
            openAvailableOperationsDialog: openAvailable,
        });
    }

    /**
     * onAddMaterialInList
     * @param {*} 
     */
    onAddMaterialInList(material, productMachined) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.addMaterialMachined(maquilaOrderToken, material, productMachined)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    onUpdateMaterialItem(itemUpdate) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.updateMaterialMachined(maquilaOrderToken, itemUpdate)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    onRemoveMaterialMachined(itemToken) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.removeMaterialMachined(maquilaOrderToken, itemToken)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch(() => {
                return Promise.reject();
            })
    }

    onUpdateMaquilaOrder(order) {
        let maquilaOrderToken = this.props.match.params.token;
        let requieredDate = this.props.maquilaOrder.required_plant_date;
        if (requieredDate !== null && order.required_plant_date != null) {
            if (requieredDate !== order.required_plant_date)
                requieredDate = order.required_plant_date.format("DD/MM/YYYY");
        }
        return this.props.updateMaquilaOrder(maquilaOrderToken, requieredDate, order.required_quality, order.required_molde, order.required_tooling)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
     * onSendMaquilaOrderFromSupplier
     *
     * @param {*} item
     * @param {*} supplier
     * @memberof Create
     */
    onSendMaquilaOrderFromSupplier() {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.sendMaquialOrderToSupplier(maquilaOrderToken)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                })
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
    * onCancelOrderMaquila
    *
    * @param {*} item
    * @param {*} supplier
    * @memberof Create
    */
    onCancelOrderMaquila(comments) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.cancelMaquilaOrder(maquilaOrderToken, comments)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                    openChangeStatusOrder: false,
                    statusAction: '',
                })
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
     * Function update item from supplier
     */
    onSaveItemUpdateSupplier(item) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.updateMaterialsFromSupplier(maquilaOrderToken, item)
            .then(() => {
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    /**
* Function to update property Questionnaire
* @param {*} sectionToken 
* @param {*} property 
* @param {*} data 
*/
    updateCommentsMaquilaOrder(property, data) {
        this.props.updatePropertyMaquilaOrder(this.props.match.params.token, property, data)
    }

    /**
    * onCancelOrderMaquila
    *
    * @param {*} item
    * @param {*} supplier
    * @memberof Create
    */
    onCloseMaquilaOrder() {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.closeMaquilaOrder(maquilaOrderToken)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                })
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
  * onCancelOrderMaquila
  *
  * @param {*} item
  * @param {*} supplier
  * @memberof Create
  */
    onSendNotificationAPlant() {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.sendNotificationFromBackend(maquilaOrderToken)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                })
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
  * Function update item from supplier
  */
    onUpdatProductMachineds(item) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.updatProductMachineds(maquilaOrderToken, item)
            .then(() => {
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }


    /**
     * addMoldInMaquila
     * @param {*} 
     */
    onAddMoldInMaquila(itemModel) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.addMoldInMaquila(maquilaOrderToken, itemModel)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    /**
   * Function update molde from supplier
   */
    onSaveMoldeFromSupplier(itemToken, property, dataValue) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.updateMoldeMaquila(maquilaOrderToken, itemToken, property, dataValue)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    /**
     * deleteMoldInMaquila
     * @param {*} 
     */
    onDeleteMoldInMaquila(molde) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.deleteMoldInMaquila(maquilaOrderToken, molde)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    onAddToolingInMaquila(toolingToken) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.addToolingInMaquila(maquilaOrderToken, toolingToken)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    onDeleteToolingInMaquila(tooling) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.deleteToolingInMaquila(maquilaOrderToken, tooling)
            .then(() => {
                return Promise.resolve('Ok')
            })
            .catch(() => {
                this.setState(showErrorToaster());
                return Promise.reject();
            })
    }

    setOpenValidOrder = () => {
        this.setState({
            openAvailableOperationsDialog: false,
            openChangeStatusOrder: !this.state.openChangeStatusOrder,
            statusAction: Maquila_Orders_Valid_Planning
        });
    }

    setOpenRejectedOrder = () => {
        this.setState({
            openAvailableOperationsDialog: false,
            openChangeStatusOrder: !this.state.openChangeStatusOrder,
            statusAction: Maquila_Orders_Rejected_Planning
        });
    }

    setOpenSenWarehouseOrder = () => {
        this.setState({
            openAvailableOperationsDialog: false,
            openChangeStatusOrder: !this.state.openChangeStatusOrder,
            statusAction: Maquila_Orders_Send_Warehouse
        });
    }

    setOpenValirSupplierOrder = () => {
        this.setState({
            openAvailableOperationsDialog: false,
            openChangeStatusOrder: !this.state.openChangeStatusOrder,
            statusAction: Maquila_Orders_Valid_Supplier
        });
    }

    setOpenCancelOrderMaquila = () => {
        this.setState({
            openAvailableOperationsDialog: false,
            openChangeStatusOrder: !this.state.openChangeStatusOrder,
            statusAction: Cancel
        });
    }

    getTitleStatus = () => {
        if (this.state.statusAction === Maquila_Orders_Valid_Planning) {
            return "Validar orden de maquila"
        }
        else if (this.state.statusAction === Maquila_Orders_Rejected_Planning) {
            return "Denegar orden de maquila"
        }
        else if (this.state.statusAction === Maquila_Orders_Send_Warehouse) {
            return "Enviar orden de maquila a almacén"
        }
        else if (this.state.statusAction === Maquila_Orders_Valid_Supplier) {
            return "Enviar orden de maquila a planta"
        }
        else if (this.state.statusAction === Cancel) {
            return "Cancelar orden de maquila"
        }
        else {
            return "No tiene estatus seleccionado"
        }
    }

    getMessageStatus = () => {
        if (this.state.statusAction === Maquila_Orders_Valid_Planning) {
            return "Agregar comentario sobre la validación de la orden"
        }
        else if (this.state.statusAction === Maquila_Orders_Rejected_Planning) {
            return "Agregar el motivo por el cual esta orden de maquila es denegada"
        }
        else if (this.state.statusAction === Maquila_Orders_Send_Warehouse) {
            return "Agregar comentario de la orden de maquila para almacén"
        }
        else if (this.state.statusAction === Maquila_Orders_Valid_Supplier) {
            return "Agregar comentario para planta de la orden de maquila"
        }
        else if (this.state.statusAction === Cancel) {
            return "Se cancela la orden de maquila y se puedes obtener nuevamente la información de SAP"
        }
        else {
            return "No tiene estatus seleccionado"
        }
    }

    onChangeStatus(comments) {
        let maquilaOrderToken = this.props.match.params.token;
        if (this.state.statusAction === Cancel) {
            return this.onCancelOrderMaquila(comments)
        }
        else {
            return this.props.changeStatusMaquilaOrder(maquilaOrderToken, comments, this.state.statusAction)
                .then(() => {
                    this.setState({
                        openChangeStatusOrder: false,
                        statusAction: '',
                    })
                    this.setState(showSuccessToaster());
                    return Promise.resolve('Ok')
                })
                .catch((error) => {
                    this.setState(showErrorToaster(error));
                    return Promise.reject();
                })
        }
    }

    onAddNotificationInUserSpplier(userSupplier) {
        let maquilaOrderToken = this.props.match.params.token;
        return this.props.addUserIInNotificationMaquila(maquilaOrderToken, userSupplier)
            .then(() => {
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.maquilaOrder !== null) {
            const { classes } = this.props;
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Divider />
                        <AppBar position="static" color="default">
                            <Tabs indicatorColor="secondary" textColor="secondary"
                                className={classes.styleTab}
                                value={this.state.activeTab}
                                onChange={this.onClickToActiveTab}>
                                <Tab label={<Translate id="common.information" />} />
                                <Tab label={<Translate id="maquilaOrders.sendMaterials" />} />
                            </Tabs>
                        </AppBar>
                        <GeneralInformation
                            maquilaOrder={this.props.maquilaOrder}
                            classes={this.props.classes}
                            isSavingMaquila={this.props.isSavingMaquila}
                            onUpdateMaquilaOrder={this.onUpdateMaquilaOrder}
                            userRolesByCompany={this.props.userRolesByCompany}
                        />
                        {this.state.activeTab === 0 && <div>
                            <MaterialsMaquilads
                                maquilaOrder={this.props.maquilaOrder}
                                classes={this.props.classes}
                                onAddMaterialInList={this.onAddMaterialInList}
                                isSavingMaquila={this.props.isSavingMaquila}
                                onUpdateMaterialItem={this.onUpdateMaterialItem}
                                onRemoveMaterialMachined={this.onRemoveMaterialMachined}
                                userRolesByCompany={this.props.userRolesByCompany}
                                dropzoneText={this.props.translate('common.selectfiles')}
                                updateMaterialsFromSupplier={this.onSaveItemUpdateSupplier}
                                uploadFilesFromSupplier={this.props.uploadFilesInMaterialFromSupplier}
                                removeFilesFromSupplier={this.props.removeFilesMaterialFromSupplier}
                                updateCommentsMaquilaOrder={this.updateCommentsMaquilaOrder}
                                uploadFilesDetailMachineds={this.props.uploadFilesDetailMachinedsInMaterial}
                                removeFilesDetailMachineds={this.props.removeFilesDetailMachinedsInMaterial}
                                currentUser={this.props.currentUser}
                                updatProductMachineds={this.onUpdatProductMachineds}
                                onAddMoldInMaquila={this.onAddMoldInMaquila}
                                onSaveMoldeFromSupplier={this.onSaveMoldeFromSupplier}
                                onDeleteMoldInMaquila={this.onDeleteMoldInMaquila}
                                downloadMaquilaOrderExcel={this.props.downloadMaquilaOrderExcel}
                                isDonwloading={this.props.isDonwloading}
                                uploadFilesMaquilaOrder={this.props.uploadFilesMaquilaOrder}
                                removeFilesMaquilaOrder={this.props.removeFilesMaquilaOrder}
                                allUsersSupplier={this.state.userSupplier}
                                onAddNotificationInUserSpplier={this.onAddNotificationInUserSpplier}
                                onAddToolingInMaquila={this.onAddToolingInMaquila}
                                onDeleteToolingInMaquila={this.onDeleteToolingInMaquila}
                                uploadFilesToolings={this.props.uploadFilesToolings}
                                deleteFilesTooling={this.props.deleteFilesTooling}
                            />
                        </div>}
                        {this.state.activeTab === 1 && <div>
                            <MaterialsSend
                                maquilaOrder={this.props.maquilaOrder}
                                classes={this.props.classes}
                                isSavingMaquila={this.props.isSavingMaquila}
                                isUserActiveDirectory={IsCurrentActiveDirectoryUser(this.props.currentUser)}
                                userRolesByCompany={this.props.userRolesByCompany}
                                dropzoneText={this.props.translate('common.selectfiles')}
                                uploadFilesMaquilaOrder={this.props.uploadFilesMaquilaOrder}
                                removeFilesMaquilaOrder={this.props.removeFilesMaquilaOrder}
                                updateMaterialsFromSupplier={this.onSaveItemUpdateSupplier}
                                uploadFilesFromSupplier={this.props.uploadFilesInMaterialFromSupplier}
                                removeFilesFromSupplier={this.props.removeFilesMaterialFromSupplier}
                                currentUser={this.props.currentUser}
                                uploadFilesDetailMachineds={this.props.uploadFilesDetailMachinedsInMaterial}
                                removeFilesDetailMachineds={this.props.removeFilesDetailMachinedsInMaterial}
                                updatProductMachineds={this.onUpdatProductMachineds}
                                updateCommentsMaquilaOrder={this.updateCommentsMaquilaOrder}
                            />
                        </div>}
                    </Grid>
                </Grid>
            )
        }
    }


    render() {
        let optonsAvalinable = this.props.maquilaOrder ? this.props.maquilaOrder.get_available_operations : [];
        return (
            <Card>
                {optonsAvalinable.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

                <LayoutHeaderWorkFlow {...this.props}
                    workFlow={this.props.maquilaOrder}
                    title={<Translate id="maquilaOrders.maquilaOrder" />}
                />

                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                <DialogAvailableOperations
                    {...this.props}
                    isSaving={this.props.isSavingMaquila}
                    options={optonsAvalinable}
                    openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
                    setOpenDialog={this.setOpenDialog}
                    onSendMaquilaOrderFromSupplier={this.onSendMaquilaOrderFromSupplier}
                    onCancelDocument={this.setOpenCancelOrderMaquila}
                    onCloseMaquilaOrder={this.onCloseMaquilaOrder}
                    onSendNotificationAPlant={this.onSendNotificationAPlant}
                    onValidOrderMaquilaForPlanning={this.setOpenValidOrder}
                    onRejectedOrderMaquilaForPlanning={this.setOpenRejectedOrder}
                    onSentOpenSenWarehouseOrder={this.setOpenSenWarehouseOrder}
                    onSentOpenValidSsupplierOrder={this.setOpenValirSupplierOrder}
                />

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

                {/** Dialog to acepted request */}
                < AddCommonItem
                    title={this.getTitleStatus()}
                    message={this.getMessageStatus()}
                    open={this.state.openChangeStatusOrder}
                    onClose={() => { this.setState({ openChangeStatusOrder: false }) }}
                    onSaveItem={this.onChangeStatus}
                    isSaving={this.props.isSaving}
                    isTextArea={true}
                />

            </Card>
        )
    }

}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let maquilaOrder = state.maquilaOrders.get("maquilaOrder");
    let selectedCompany = state.profile.get("selectedCompany");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        currentUser: user,
        isLoading: state.maquilaOrders.get('isLoadingMaquila'),
        maquilaOrder: maquilaOrder ? maquilaOrder.toJS() : null,
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        company: selectedCompany ? selectedCompany.toJS() : null,
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
        isSavingMaquila: state.maquilaOrders.get('isSavingMaquila'),
        isDonwloading: state.maquilaOrders.get('isDonwloading'),
        usersByRole: state.internalUsers.get('usersByRole') ? converListToArray(state.internalUsers.get('usersByRole')) : []
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadMaquilaOrder: (token) => {
            return dispatch(loadMaquilaOrderFromBackEnd(token));
        },
        loadSupplierCode: (token) => {
            return (dispatch(loadSupplierCode(token)))
        },
        addMaterialMachined: (token, material, productMachined) => {
            return (dispatch(addMaterialMachined(token, material, productMachined)))
        },
        updateMaterialMachined: (tokenMaquila, itemUpdate) => {
            return (dispatch(updateMaterialMachinedFromBack(tokenMaquila, itemUpdate)))
        },
        removeMaterialMachined: (tokenMaquila, token) => {
            return (dispatch(removeMaterialMachinedBackend(tokenMaquila, token)))
        },
        updateMaquilaOrder: (maquilaOrderToken, requieredDate, required_quality, required_molde, required_tooling) => {
            return (dispatch(updateMaquilaOrderFromBack(maquilaOrderToken, requieredDate, required_quality, required_molde, required_tooling)))
        },
        sendMaquialOrderToSupplier: (maquilaOrderToken) => {
            return dispatch(sendMaquialOrderToSupplier(maquilaOrderToken))
        },
        cancelMaquilaOrder: (maquilaOrderToken, comments) => {
            return dispatch(cancelMaquilaOrderFromBackend(maquilaOrderToken, comments))
        },
        uploadFilesMaquilaOrder: (maquilaToken, files, listName) => {
            return dispatch(uploadFilesMaquilaOrder(maquilaToken, files, listName));
        },
        removeFilesMaquilaOrder: (maquilaToken, fileName, listName) => {
            return dispatch(removeFilesMaquilaOrder(maquilaToken, fileName, listName))
        },
        updateMaterialsFromSupplier: (maquilaToken, itemUpdate) => {
            return dispatch(updateMaterialsFromSupplier(maquilaToken, itemUpdate));
        },
        uploadFilesInMaterialFromSupplier: (maquilaToken, itemToken, files) => {
            return dispatch(uploadFilesInMaterialFromSupplier(maquilaToken, itemToken, files));
        },
        removeFilesMaterialFromSupplier: (maquilaToken, itemToken, fileName) => {
            return dispatch(removeFilesMaterialFromSupplier(maquilaToken, itemToken, fileName))
        },
        updatePropertyMaquilaOrder: (maquilaToken, property, propertyData) => {
            return (dispatch(updateCommentsMaquilaOrder(maquilaToken, property, propertyData)))
        },
        closeMaquilaOrder: (maquilaToken) => {
            return dispatch(closeMaquilaOrderFromBackend(maquilaToken))
        },
        uploadFilesDetailMachinedsInMaterial: (maquilaToken, itemToken, files) => {
            return dispatch(uploadFilesDetailMachinedsInMaterial(maquilaToken, itemToken, files));
        },
        removeFilesDetailMachinedsInMaterial: (maquilaToken, itemToken, fileName) => {
            return dispatch(removeFilesDetailMachinedsInMaterial(maquilaToken, itemToken, fileName))
        },
        sendNotificationFromBackend: (maquilaToken) => {
            return dispatch(sendNotificationFromBackend(maquilaToken))
        },
        updatProductMachineds: (maquilaToken, itemUpdate) => {
            return dispatch(updatProductMachineds(maquilaToken, itemUpdate));
        },
        addMoldInMaquila: (maquilaToken, itemModel) => {
            return dispatch(addMoldInMaquila(maquilaToken, itemModel))
        },
        updateMoldeMaquila: (maquilaToken, itemToken, property, propertyData) => {
            return dispatch(updateMoldeMaquila(maquilaToken, itemToken, property, propertyData))
        },
        deleteMoldInMaquila: (maquilaToken, itemToken) => {
            return dispatch(deleteMoldInMaquila(maquilaToken, itemToken))
        },
        downloadMaquilaOrderExcel: (maquilaToken) => {
            return dispatch(downloadMaquilaOrderExcel(maquilaToken));
        },
        changeStatusMaquilaOrder: (maquilaOrderToken, comments, status) => {
            return dispatch(changeStatusMaquilaOrderFromBackend(maquilaOrderToken, comments, status))
        },
        searchUsersInRole: (roleNames, supplierCode) => {
            return dispatch(searchUsersInRole(roleNames, supplierCode))
        },
        addUserIInNotificationMaquila: (maquilaToken, usersupplier) => {
            return dispatch(addUserIInNotificationMaquila(maquilaToken, usersupplier))
        },
        addToolingInMaquila: (maquilaToken, tooling) => {
            return dispatch(addToolingInMaquila(maquilaToken, tooling))
        },
        deleteToolingInMaquila: (maquilaToken, toolingToken) => {
            return dispatch(deleteToolingInMaquila(maquilaToken, toolingToken))
        },
        uploadFilesToolings: (maquilaToken, itemToken, files) => {
            return dispatch(uploadFilesToolings(maquilaToken, itemToken, files));
        },
        deleteFilesTooling: (maquilaToken, itemToken, fileName) => {
            return dispatch(deleteFilesTooling(maquilaToken, itemToken, fileName))
        },
    }
}

const styles = theme => ({
    actionsToolbar: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    margin: {
        margin: theme.spacing.unit * 2,
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));