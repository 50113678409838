import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import Select from 'react-select'

/** Material UI import section*/
import {
	InputAdornment,
	TextField,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
	withStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteForever'

/** Resources import section */
import { SERVICE_RETENTION_PERCENTAGE } from '../../../../store/helpers/AccreditedWorkshopsHelper'

const RetentionPercentageTab = (props) => {
	const {
		retentionType,
		value,
		loadingSuppliers,
		suppliers,
		suppliersInRetention,
		classes,
		handleRetentionValueChange,
		onSupplierChange,
		onRemoveWorkshopButtonClick,
	} = props

	const getRetentionLabel = () => {
		return retentionType === SERVICE_RETENTION_PERCENTAGE ? (
			<Translate id='accreditedWorkshops.setting.serviceRetention' />
		) : (
			<Translate id='accreditedWorkshops.setting.freightRetention' />
		)
	}

	const getSupplierLabel = (code) => {
		const supplier = suppliers.find((s) => s.value === code)
		return supplier ? supplier.label : `${code} - Desconocido`
	}

	const percentage = value ? value.toFixed(1) : 0

	return (
		<div>
			<div className={classes.retentionValue}>
				<Typography variant='body2'>{getRetentionLabel()}:</Typography>
				<TextField
					value={percentage}
					name='value'
					type='number'
					variant='outlined'
					onChange={handleRetentionValueChange}
					InputProps={{
						classes: { input: classes.textField },
						endAdornment: <InputAdornment position='end'>%</InputAdornment>,
					}}
				/>
			</div>
			<div className={classes.suppliersContainer}>
				<Typography variant='body2'>
					<Translate id='accreditedWorkshops.setting.assignedSuppliers' />
				</Typography>
				<Select
					className={classes.documentTypeSelect}
					options={suppliers}
					onChange={onSupplierChange}
					styles={{
						// menu: (base) => ({ ...base, zIndex: 10 })
						menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
						menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
						menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
					}}
					menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
					menuPlacement={'auto'}
					isLoading={loadingSuppliers}
				/>
				<div>
					{suppliersInRetention.map((supplier) => (
						<ListItem key={supplier.Id} ContainerComponent='div' className={classes.activeItem}>
							<ListItemText
								primary={
									<Typography variant='subtitle2' noWrap>
										{getSupplierLabel(supplier.Code)}
									</Typography>
								}
							/>
							<ListItemSecondaryAction className={classes.itemListIcon}>
								<ListItemIcon
									data-code={supplier}
									style={{ cursor: 'pointer' }}
									onClick={() => onRemoveWorkshopButtonClick(supplier)}
								>
									<DeleteIcon />
								</ListItemIcon>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</div>
			</div>
		</div>
	)
}

RetentionPercentageTab.propTypes = {
	retentionType: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	suppliersInRetention: PropTypes.arrayOf(PropTypes.string).isRequired,
	loadingSuppliers: PropTypes.bool.isRequired,
	suppliers: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	).isRequired,
	handleRetentionValueChange: PropTypes.func.isRequired,
	onSupplierChange: PropTypes.func.isRequired,
}

RetentionPercentageTab.defaultProps = {
	retentionType: SERVICE_RETENTION_PERCENTAGE,
	value: 0,
	suppliersInRetention: [],
	loadingSuppliers: false,
	suppliers: [],
	handleRetentionValueChange: () => console.warn('[handleRetentionValueChange] not defined!'),
	onSupplierChange: () => console.warn('[onSupplierChange] not defined!'),
}

const styles = (theme) => ({
	retentionValue: {
		padding: '10px 0',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	suppliersContainer: {
		'& > *': {
			marginTop: 16,
		},
	},
	textField: {
		height: 20,
		width: 100,
		padding: 8,
	},
	activeItem: {
		margin: '4px 0',
		padding: '8px 15px',
		backgroundColor: '#F6F6F6',
		borderRadius: '4px',
		color: theme.palette.common.white,
	},
})

export default withStyles(styles, { withTheme: true })(RetentionPercentageTab)
