import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'

/** Material UI import section*/
import { Divider } from '@material-ui/core'

/** Custom components import section*/
import GeneralSettings from './components/GeneralSettings'
import RetentionPercentages from './components/RetentionPercentages'
import RetentionPercentagesPanel from './components/RetentionPercentagesPanel'
import ServiceTypePanel from './components/ServiceTypePanel'
import ServiceTypeList from './components/ServiceTypeList'
import ServiceStatusInSection from './components/ServiceStatusInSection'
import ServiceStatusInSectionPanel from './components/ServiceStatusInSectionPanel'
import IvaPercentagesPanel from './components/IvaPercentagesPanel'
import ResicoPercentagesPanel from './components/ResicoPercentagesPanel'

/** Redux selectors import section */
import { useDispatch } from 'react-redux'
import {
	togglePaymentRequestCreationStatus,
	updateSettingValue,
	getServiceTypeList,
	addServiceType,
	removeServiceType,
	loadWorkshopCodes,
	updateWorkshopIvaPercentage,
	updateWorkshopResicoPercentage,
} from '../../../store/accreditedWorkshops/accreditedWorkshopsActions'

/** Resources import section */
import {
	FREIGHT_RETENTION_PERCENTAGE,
	SERVICE_RETENTION_PERCENTAGE,
} from '../../../store/helpers/AccreditedWorkshopsHelper'

const Configuration = (props) => {
	const { configuration, rfc } = props
	/** Defines local state */
	const [retentionPercentagesPanelOptions, setRetentionPercentagesPanelOptions] = useState({ opened: false })
	const [ivaPercentagesPanelOptions, setIvaPercentagesPanelOptions] = useState({ opened: false })
	const [resicoPercentagesPanelOptions, setResicoPercentagesPanelOptions] = useState({ opened: false })
	const [statusInSectionPanelOptions, setStatusInSectionPanelOptions] = useState({ opened: false })
	const [serviceTypePanelOptions, setServiceTypePanelOptions] = useState({ opened: false })
	const [serviceTypes, setServiceTypes] = useState([])
	/** Connect with redux */
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getServiceTypeList()).then((serviceTypes) => {
			setServiceTypes(serviceTypes)
		})
		dispatch(loadWorkshopCodes())
	}, [])

	const toggleChecked = (event) => {
		const { checked } = event.target
		dispatch(togglePaymentRequestCreationStatus(rfc, checked)).catch((error) => {
			console.error('Error updating the payment request creation updated', error)
		})
	}

	const changeConfigurationSetting = (event) => {
		const { value, id } = event.target
		dispatch(updateSettingValue(rfc, value, id))
	}

	const changeIvaPecentage = (event) => {
		const { value, id } = event.target
		dispatch(updateWorkshopIvaPercentage(rfc, value / 100, id))
	}

	const changeResicoPecentage = (event) => {
		const { value, id } = event.target
		dispatch(updateWorkshopResicoPercentage(rfc, value / 100, id))
	}

	const openRetentionPercentagesPanel = () => {
		setRetentionPercentagesPanelOptions({ opened: true })
	}

	const closeRetentionPercentagesPanel = () => {
		setRetentionPercentagesPanelOptions({ opened: false })
	}

	const openIvaPercentagesPanel = () => {
		setIvaPercentagesPanelOptions({ opened: true })
	}

	const closeIvaPercentagesPanel = () => {
		setIvaPercentagesPanelOptions({ opened: false })
	}

	const openResicoPercentagesPanel = () => {
		setResicoPercentagesPanelOptions({ opened: true })
	}

	const closeResicoPercentagesPanel = () => {
		setResicoPercentagesPanelOptions({ opened: false })
	}

	const openStatusInSectionPanel = () => {
		setStatusInSectionPanelOptions({ opened: true })
	}

	const closeStatusInSectionPanel = () => {
		setStatusInSectionPanelOptions({ opened: false })
	}

	const openServiceTypePanel = () => {
		setServiceTypePanelOptions({ opened: true })
	}

	const closeServiceTypePanel = () => {
		setServiceTypePanelOptions({ opened: false })
	}

	const onServiceTypeChange = (serviceType) => {
		dispatch(addServiceType(rfc, serviceType.value))
	}

	const onRemoveServiceTypeClick = (serviceType) => {
		dispatch(removeServiceType(rfc, serviceType))
	}

	return (
		<>
			<GeneralSettings
				paymentRequestCreation={{
					enabled: configuration.PaymentRequestCreationStatus,
					onChange: toggleChecked,
				}}
				paymentRequestCreationDays={{
					days: configuration.PaymentRequestCreationDays,
					onChange: changeConfigurationSetting,
				}}
				paymentRequestRfc={{
					value: configuration.RFC,
					onChange: changeConfigurationSetting,
				}}
				paymentRequestTaxDifference={{
					value: configuration.InvoiceAmountVariationAllowed,
					onChange: changeConfigurationSetting,
				}}
				paymentRequestMaxNumberOfServicesInPaymentRequest={{
					value: configuration.MaxNumberOfServicesInPaymentRequest,
					onChange: changeConfigurationSetting,
				}}
				paymentRequestIva={{
					value: configuration.IvaPercentage * 100,
					onChange: changeIvaPecentage,
					onEditButtonClick: openIvaPercentagesPanel,
				}}
				paymentRequestResico={{
					value: configuration.ResicoPercentage * 100,
					onChange: changeResicoPecentage,
					onEditButtonClick: openResicoPercentagesPanel,
				}}
			/>
			<Divider />
			<div style={{ height: 24 }} />
			<RetentionPercentages
				serviceRetention={{
					code: SERVICE_RETENTION_PERCENTAGE,
					value: configuration.ServiceRetentionPercentage * 100,
					numberOfSuppliers: configuration.WorkshopRetentions.filter(
						(ws) => ws.RetentionType === SERVICE_RETENTION_PERCENTAGE
					).length,
				}}
				freightRetention={{
					code: FREIGHT_RETENTION_PERCENTAGE,
					value: configuration.FreightRetentionPercentage * 100,
					numberOfSuppliers: configuration.WorkshopRetentions.filter(
						(ws) => ws.RetentionType === FREIGHT_RETENTION_PERCENTAGE
					).length,
				}}
				onEditButtonClick={openRetentionPercentagesPanel}
			/>
			<Divider />
			<div style={{ height: 24 }} />
			<ServiceTypeList
				onAddServiceTypeClick={openServiceTypePanel}
				onRemoveServiceTypeClick={onRemoveServiceTypeClick}
				serviceTypes={configuration.ServiceTypes}
			/>
			<Divider />
			<div style={{ height: 24 }} />
			<ServiceStatusInSection
				onEditButtonClick={openStatusInSectionPanel}
				serviceStatusInSection={configuration.OrderStatusInSections}
			/>
			{retentionPercentagesPanelOptions.opened && (
				<RetentionPercentagesPanel
					onClose={closeRetentionPercentagesPanel}
					configuration={configuration}
					rfc={rfc}
				/>
			)}
			{ivaPercentagesPanelOptions.opened && (
				<IvaPercentagesPanel onClose={closeIvaPercentagesPanel} configuration={configuration} rfc={rfc} />
			)}
			{resicoPercentagesPanelOptions.opened && (
				<ResicoPercentagesPanel onClose={closeResicoPercentagesPanel} configuration={configuration} rfc={rfc} />
			)}
			{serviceTypePanelOptions.opened && (
				<ServiceTypePanel
					onClose={closeServiceTypePanel}
					configuration={configuration}
					serviceTypes={serviceTypes}
					onServiceTypeChange={onServiceTypeChange}
				/>
			)}
			{statusInSectionPanelOptions.opened && (
				<ServiceStatusInSectionPanel
					onClose={closeStatusInSectionPanel}
					configuration={configuration}
					rfc={rfc}
				/>
			)}
		</>
	)
}

Configuration.propTypes = {
	configuration: propTypes.shape({
		RFC: propTypes.string.isRequired,
		PaymentRequestCreationStatus: propTypes.bool.isRequired,
		InvoiceAmountVariationAllowed: propTypes.number.isRequired,
		ServiceRetentionPercentage: propTypes.number.isRequired,
		FreightRetentionPercentage: propTypes.number.isRequired,
		WorkshopRetentions: propTypes.array.isRequired,
	}).isRequired,
}

Configuration.defaultProps = {
	configuration: {
		RFC: '',
		PaymentRequestCreationStatus: false,
		InvoiceAmountVariationAllowed: 0,
		ServiceRetentionPercentage: 0,
		FreightRetentionPercentage: 0,
		WorkshopRetentions: [],
	},
}

export default Configuration
