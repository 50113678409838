///Import react section
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch, } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import ShowDesktopViewInternalUser from './components/internalUser/ShowDesktopViewInternalUser'
import ShowEdit from './ShowEdit'

/**Import actions section */
import { loadIndirectQualificationFromBackEnd } from '../../store/indirectqualifications/IndirectqualificationsActions'

const Show = (props) => {
    /**Render hooks section */
    const dispatch = useDispatch()

    /**Redux store information section*/
    const indirectQualification = useSelector((state) => state.indirectQualifications.get('indirectQualification') ? state.indirectQualifications.get('indirectQualification').toJS() : null)
    const isLoading = useSelector((state) => state.indirectQualifications.get('isLoading'))
    const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'))
    const identifier = useSelector((state) => state.indirectQualifications.get('identifier'))
    const isOpenLateralMenu = useSelector((state) => state.profile.get('isOpenLateralMenu'))

    const translate = useSelector((state) => getTranslate(state.localize))

    /**Store local this component */
    const [identifierPanelLateral, setIdentifierPanelLateral] = useState("")
    const [item, setItem] = useState(null)
    const [confirmPasswordConfigModal, setConfirmPasswordConfigModal] = useState({ opened: false, item: null, identifier: "" })

    const onConfirmPassword = (item, identifier) => {
        setConfirmPasswordConfigModal({ opened: !confirmPasswordConfigModal.opened, item: item, identifier: identifier });
    }

    /**Use effect section */
    useEffect(() => {
        dispatch(loadIndirectQualificationFromBackEnd(props.match.params.token))
    }, [dispatch, props.match.params.token])

    const onLateralPanelConfig = (identifier, item) => {
        setIdentifierPanelLateral(identifier);
        setItem(item)
    }

    const onRedirectToActionPlant = (item) => {
        props.history.push(`/indirectqualifications/actionsPlant/${props.match.params.token}/${item.Id}`)
    }

    return (
        <>
            {
                <ShowDesktopViewInternalUser
                    /**objects */
                    indirectQualification={indirectQualification}
                    translate={translate}
                    /**String */
                    identifier={identifier}
                    /**Bools */
                    isLoading={isLoading}
                    isSaving={isSaving}
                    isOpenLateralMenu={isOpenLateralMenu}
                    /**Arrays */
                    /**Functions */
                    onLateralPanelConfig={onLateralPanelConfig}
                    onConfirmPassword={onConfirmPassword}
                    onRedirectToActionPlant={onRedirectToActionPlant}
                />
            }


            <ShowEdit
                identifierPanelLateral={identifierPanelLateral}
                setIdentifierPanelLateral={setIdentifierPanelLateral}
                onLateralPanelConfig={onLateralPanelConfig}
                item={item}
                onConfirmPassword={onConfirmPassword}
                confirmPasswordConfigModal={confirmPasswordConfigModal}
                history={props.history}
            />

        </>
    )
}

const styles = ((theme) => ({

}))

export default withauthenticatedLayout(withRouter((withStyles(styles, { withTheme: true })(Show))))