/**Import react section */
import React from 'react'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/**Import MaterialUi section */
import { Grid, Typography, TextField, Button } from '@material-ui/core'

const AddInformationPartialComponent = (props) => {
	//Bool props section
	const {
		isMaquilaDraft,
		isSaving,
		// differenceErrorMessageIsVisible,
		canBeUpdateSupplier,
		canBeEditWarehouse,
	} = props
	//object props section
	const { classes, itemModel } = props
	//Funtion props section
	const { onPropertyChange, onAddMaterialMachinedPartialItem, canBeAddedPartiality } = props

	let supplirCreateParciality = canBeUpdateSupplier && false
	return (
		<>
			{!isMaquilaDraft && (canBeEditWarehouse || supplirCreateParciality) && (
				<Grid item xs={12}>
					<br />
					<Typography color='primary'>{<Translate id='maquilaOrders.aditionalInformation' />}</Typography>
					<br />
					<div>
						{canBeEditWarehouse && (
							<>
								<TextField
									size='small'
									fullWidth
									label={<Translate id='maquilaOrders.quantitySendConfinrSupplier' />}
									className={classes.textField}
									name='quantity_send'
									type='number'
									margin='dense'
									variant='outlined'
									step={0.5}
									onChange={onPropertyChange}
									disabled={isSaving}
									value={itemModel.quantity_send}
									InputProps={{ classes: { input: classes.inputProps } }}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<br />
								<TextField
									size='small'
									fullWidth
									label={<Translate id={'maquilaOrders.shippingTicket'} />}
									className={classes.textField}
									onChange={onPropertyChange}
									name='shipping_ticket'
									type='number'
									margin='dense'
									variant='outlined'
									value={itemModel ? itemModel.shipping_ticket : ''}
									disabled={isSaving}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</>
						)}
						{supplirCreateParciality && (
							<>
								<TextField
									size='small'
									fullWidth
									label={<Translate id='maquilaOrders.quantityReceived' />}
									className={classes.textField}
									name='quantity_received'
									type='number'
									margin='dense'
									variant='outlined'
									step={0.5}
									onChange={onPropertyChange}
									disabled={isSaving}
									value={itemModel.quantity_received}
									InputProps={{ classes: { input: classes.inputProps } }}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<br />
								<TextField
									size='small'
									fullWidth
									label={<Translate id={'maquilaOrders.ticketReceivedMaterial'} />}
									className={classes.textField}
									onChange={onPropertyChange}
									name='entrance_weighing_ticket'
									type='number'
									margin='dense'
									variant='outlined'
									value={itemModel ? itemModel.entrance_weighing_ticket : ''}
									disabled={isSaving}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</>
						)}
						{
							<div className={classes.buttonParcialitiesAddContainer}>
								<Button
									data-cy='btn-save'
									type='button'
									color='primary'
									variant='outlined'
									onClick={onAddMaterialMachinedPartialItem}
									disabled={canBeAddedPartiality() || isSaving}
								>
									Agregar parcialidad
								</Button>
							</div>
						}
					</div>
				</Grid>
			)}
		</>
	)
}

const styles = (theme) => ({
	buttonParcialitiesAddContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(AddInformationPartialComponent))
