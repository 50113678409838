import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { COLOR_STATUS_DANGER } from '../../../store/helpers/StatusColorConstants'
import { COMPANY_IDENTIFIER_IMBERA_MX } from '../../../store/helpers/ResourcesHelper'
import FilesLinks from '../../common/FilesLinks'

/**
 * Container sample request
 */
export default class SampleRequested extends Component {
	/**
	 * Create an instance of sample request
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	render() {
		const { classes, sampleRequest } = this.props
		let kind = 1
		let formatype = this.props.sampleRequest.formatype
		if (this.props.sampleRequest.alternating_proveeduria_type === '2') {
			kind = 2
		}
		if (sampleRequest) {
			return (
				<List>
					<ListItem>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='samplesrequest.folioMemo' />
									</Typography>
									{': '}
									{sampleRequest.origin}
								</React.Fragment>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='samplesrequest.partNumber' />
									</Typography>
									{': '}
									{sampleRequest.part_number}
								</React.Fragment>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='samplesrequest.stage' />
									</Typography>
									{': '}
									{sampleRequest.stage}
								</React.Fragment>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='samplesrequest.sampleRequest' />
									</Typography>
									{sampleRequest.number_of_samples_requested}
									{sampleRequest.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
										<Typography
											component='span'
											variant='body2'
											className={classes.block}
											style={{ color: COLOR_STATUS_DANGER }}
										>
											{sampleRequest.comment_engineer_process}
										</Typography>
									)}
								</React.Fragment>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='samplesrequest.developmentEngineer' />
									</Typography>
									{': '}
									{sampleRequest.developer_engineer}
								</React.Fragment>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='samplesrequest.planeAndAditionalFiles' />
									</Typography>
									{': '}
									<FilesLinks
										item={sampleRequest}
										hasPlot
										customAttachmentsProperty={'attachments_part_number'}
										companyToken={sampleRequest.company_token}
										companyCode={sampleRequest.company_identifier}
										kind={kind}
										formatype={formatype}
										downloadFile={this.props.downloadPlotFiles}
									/>
								</React.Fragment>
							}
						/>
					</ListItem>
					{sampleRequest.deadline_delivery && (
						<ListItem divider>
							<ListItemText
								secondary={
									<React.Fragment>
										<Typography
											component='span'
											variant='body2'
											className={classes.inline}
											color='textPrimary'
										>
											<Translate id='samplesrequest.requieredDateSample' />
										</Typography>
										{': '}
										{sampleRequest.deadline_delivery}
									</React.Fragment>
								}
							/>
						</ListItem>
					)}
					{sampleRequest.comments && (
						<ListItem divider>
							<ListItemText
								secondary={
									<React.Fragment>
										<Typography
											component='span'
											variant='body2'
											className={classes.inline}
											color='textPrimary'
										>
											<Translate id='samplesrequest.commentsByDevelopment' />
										</Typography>
										{': '}
										{sampleRequest.comments}
									</React.Fragment>
								}
							/>
						</ListItem>
					)}
				</List>
			)
		} else {
			return ''
		}
	}
}
