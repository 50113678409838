/**Import react section */
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import RateReviewTwoToneIcon from '@material-ui/icons/RateReviewTwoTone';

/**Import material UI Section*/

import { withStyles, Grid, Button, Typography, IconButton } from '@material-ui/core';
/**Import components section */
import DatePicker from '../../../common/DateTimePicker/DatePicker';
import DataTable from '../../../common/DataTable';
/**Import actions section */
import { ratingsByPeriod } from '../../../../store/indirectqualifications/IndirectqualificationsActions';
import { GetColorEvaluation } from '../../../../store/helpers/IndirectQualificationsHelpers';
const RatingSupplierTable = (props) => {
	const dispatch = useDispatch();

	/**String props section */
	// const { } = props
	/**Bools props section */
	const { isSaving } = props;
	/**Arrays props section */
	const { indirectsQualificationsByPeriod } = props;
	const { queryParams, totalRows, onChangePage, onChangeSortCriteria } = props;
	/**Functions props section */
	//const {  } = props
	/**Objects props section */
	const { translate, classes, dateConfigurationModel, setDateConfigurationModelModel, redirectToRatingDetail } = props;
    let location = useLocation()

    /**Use effect section */
    useEffect(() => {
        if(dateConfigurationModel && dateConfigurationModel.startDate !==null && dateConfigurationModel.closingDate !==null && props.queryParams){
            dispatch(ratingsByPeriod(dateConfigurationModel.startDate, dateConfigurationModel.closingDate, translate, queryParams));
        }
    }, [location.search])

	/**Verify if can be sabed data*/
	const canBeSaved = () => {
		if (dateConfigurationModel.closingDate === null) return false;
		if (dateConfigurationModel.startDate === null) return false;
		return true;
	};
	const onChangeDate = (property, data) => {
		if (property === 'startDate') {
			setDateConfigurationModelModel({
				...dateConfigurationModel,
				[property]: data,
				closingDate: null
			});
		} else {
			setDateConfigurationModelModel({
				...dateConfigurationModel,
				[property]: data
			});
		}
	};

	const onSave = () => {
		dispatch(ratingsByPeriod(dateConfigurationModel.startDate, dateConfigurationModel.closingDate, translate, queryParams));
	};

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'FormatedFolio') {
			return <Typography className={classes.statusSupplier} variant="caption">{`${item.FormatedFolio}`}</Typography>;
		}
		if (dataSource === 'EvaluationDateDescription') {
			return (
				<Typography className={classes.statusSupplier} variant="caption">
					{item.EvaluationDateDescription}
				</Typography>
			);
		}
		if (dataSource === 'StatusDescription') {
			return (
				<Typography style={{ color: item.color_status_table }} className={classes.statusSupplier} variant="caption">
					{item.StatusDescription}
				</Typography>
			);
		}
		if (dataSource === 'Code') {
			return (
				<Typography className={classes.nameSupplier} variant="caption">{`${item.Code} | ${item.Name}`}</Typography>
			);
		}
		if (dataSource === 'TotalRating') {
			return (
				<Typography variant="caption" style={{ color: GetColorEvaluation(item.DescriptionEvaluation) }}>
					{item.TotalRating}
				</Typography>
			);
		}
		if (dataSource === 'DescriptionEvaluationType') {
			return (
				<Typography variant="caption" style={{ color: GetColorEvaluation(item.DescriptionEvaluation) }}>
					{item.DescriptionEvaluationType}
				</Typography>
			);
		}
		if (dataSource === 'RatingName' || dataSource === 'Concepto') {
			return (
				<Typography
					style={{
						maxWidth: '80px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden'
					}}
					className={classes.nameSupplier}
					variant="caption"
				>{`${item.RatingName}`}</Typography>
			);
		}
		if (dataSource === 'Option') {
			return (
				<IconButton>
					{' '}
					<RateReviewTwoToneIcon color="primary" onClick={() => redirectToRatingDetail(item.RatingId)} />{' '}
				</IconButton>
			);
		}
	};

	return (
		<Grid container spacing={8}>
			<Grid item xs={4}>
				<Typography variant="subtitle2" color="primary" className={classes.titleText}>
					Fecha de inicio de periodo
				</Typography>
				<DatePicker
					opens={'left'}
					onChangeRange={(event) => onChangeDate('startDate', event)}
					startDate={dateConfigurationModel.startDate ? moment(dateConfigurationModel.startDate) : ''}
				/>
			</Grid>
			<Grid item xs={4}>
				<Typography variant="subtitle2" color="primary" className={classes.titleText}>
					Fecha fin de periodo
				</Typography>
				<DatePicker
					opens={'left'}
					onChangeRange={(event) => onChangeDate('closingDate', event)}
					startDate={dateConfigurationModel.closingDate ? moment(dateConfigurationModel.closingDate, 'DD/MM/YYYY') : ''}
				/>
			</Grid>
			<Grid item xs={4}>
				<Button
					data-cy="btn-save"
					type="button"
					color="primary"
					variant="outlined"
					onClick={onSave}
					className={classes.btnSearch}
					disabled={!canBeSaved() || isSaving}
				>
					{isSaving ? <Translate id="common.searching" /> : <Translate id="common.search" />}
				</Button>
			</Grid>
			<Grid item xs={12}>
				<DataTable
					data={indirectsQualificationsByPeriod}
					configuration={TableConfiguration()}
					onRenderCellItem={onRenderCellItem}
                    onChangeSortCriteria={onChangeSortCriteria}
					isIndex
					showFilters
					totalRows={totalRows}
					sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'FormatedFolio'}
					sortAscending={
						queryParams && queryParams.sortDirection ? (queryParams.sortDirection === 'desc' ? false : true) : false
					}
					page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
					rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
					onChangePage={(newPage, newRowsPerPage) => {
						onChangePage(newPage, newRowsPerPage);
					}}
				/>
			</Grid>
		</Grid>
	);
};

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: 'Folio',
			dataSource: 'FormatedFolio'
		},
		{
			header: <Translate id="indirectqualifications.table.status" />,
			dataSource: 'StatusDescription'
		},
		{
			header: 'Proveedor',
			dataSource: 'Code'
		},
		{
			header: 'Tipo',
			dataSource: 'SupplierTypeDescription'
		},
		{
			header: 'Mes',
			dataSource: 'EvaluationDateDescription'
		},
		{
			header: 'Calificación',
			dataSource: 'TotalRating'
		},
		{
			header: 'Clasificación',
			dataSource: 'DescriptionEvaluationType'
		},
		{
			header: 'Nombre',
			dataSource: 'RatingName'
		},
		{
			header: '',
			dataSource: 'Option'
		}
	];

	return { columns };
}

const styles = (theme) => ({
	root: {
		maxHeight: `calc(100vh - ${335}px)`,
		maxWidth: `calc(100vw - ${315}px)`,
		overflowX: 'auto',
		overflowY: 'auto'
	},
	table: {
		maxWidth: '100%',
		minHeight: 90,
		maxHeight: '100%'
	},
	nameSupplier: {
		width: '200px'
	},
	statusSupplier: {
		width: '100px'
	},
	titleText: {
		marginLeft: '5px'
	},
	btnSearch: {
		marginTop: '22px'
	}
});

RatingSupplierTable.propTypes = {
	/**
	 * Determinate if is loading information from back end
	 * and see view skeleton
	 */
	isSaving: PropTypes.bool.isRequired
};

RatingSupplierTable.defaultProps = {
	isSaving: false
};

export default withRouter(withStyles(styles, { withTheme: true })(RatingSupplierTable));
