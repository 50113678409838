/**Import react section */
import React from 'react'
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
/**Import section components */
import StepLabelQuestion from './StepLabelQuestion'
/**Import resources section */
import {
    COLOR_STATUS_BLUE,
} from '../../../../store/helpers/StatusColorConstants'
import { Typography } from '@material-ui/core';
/** Resources import section */

import { getInternalUserName } from '../../../../store/helpers/BiddingHelper';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';


const StepperQuestion = props => {
    const { classes, questions, isSaving, onAddAnswer, usersByRole, bidding, isCurrentActiveDirectoryUser, isExternalUser, preferred_username} = props
    const IconComponent = (props) => {
        return (
            <Paper elevation={3} className={classes.papper}>
                <Badge badgeContent={questions[props.icon - 1].Answers.length} color="error">
                    <LibraryBooksIcon
                        color="primary"
                    />

                </Badge>
            </Paper>
        );
    }
    return (
        <Stepper orientation="vertical" style={{ backgroundColor: "#f8f9fa" }}>
            {questions.map((question, index) => {
                let responsible = {
                    avatar_url: null,
                    name: IsNullOrEmpty(question.CreatorName) ? getInternalUserName(usersByRole, question.CreatedBy) : question.CreatorName,
                    user_name: question.CreatedBy
                }
                return (
                    <Step key={question.Id} active={true}>
                        <StepLabel
                            StepIconComponent={IconComponent}
                        >
                            <StepLabelQuestion
                                questionId={question.Id}
                                responsible={responsible}
                                name={IsNullOrEmpty(question.CreatorName) ? getInternalUserName(usersByRole, question.CreatedBy) : question.CreatorName}
                                userName={question.CreatedBy}
                                creationDate={question.CreationDate}
                                question={question.Name}
                                canBeEdited={true}
                                onAddAnswer={onAddAnswer}
                                isSaving={isSaving}
                                bidding={bidding}
                                isCurrentActiveDirectoryUser={isCurrentActiveDirectoryUser}
                                preferred_username={preferred_username}
                                isExternalUser={isExternalUser}
                            />
                        </StepLabel>
                        <StepContent className={classes.stepContent}>
                            <Typography color="primary"><Translate id="biddingsModule.questions.answers" /></Typography>
                            {
                                question.Answers.map(answers => {
                                    let responsible = {
                                        avatar_url: null,
                                        name: (IsNullOrEmpty(answers.CreatorName) && !isExternalUser) ? getInternalUserName(usersByRole, answers.CreatedBy) : !isExternalUser ?answers.CreatorName:"A",
                                        user_name: answers.CreatedBy
                                    }
                                    return (
                                        <div className={classes.marginTop} key={answers.Id} >
                                            <StepLabelQuestion
                                                responsible={responsible}
                                                name={(IsNullOrEmpty(answers.CreatorName) && !isExternalUser) ? getInternalUserName(usersByRole, answers.CreatedBy) : !isExternalUser ?answers.CreatorName:"A"}
                                                userName={answers.CreatedBy}
                                                creationDate={answers.CreationDate}
                                                question={answers.Name}
                                                canBeEdited={false}
                                                isCurrentActiveDirectoryUser={isCurrentActiveDirectoryUser}
                                                bidding={bidding}
                                                preferred_username={preferred_username}
                                                isExternalUser={isExternalUser}
                                            />
                                            <Divider />
                                        </div>
                                    )
                                })
                            }

                        </StepContent>
                    </Step>
                )
            })}
        </Stepper>
    )
}

const useStyle = ((theme) => ({
    containerBtn: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center"
    },
    btnSave: {
        maxHeight: "50px"
    },
    papper: {
        borderLeft: `5px solid ${COLOR_STATUS_BLUE}`,
        height: "70px",
        width: "50px"
    },
    marginTop: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    stepContent: {
        paddingLeft: "40px"
    }
}))

StepperQuestion.propTypes = {
    isSaving: PropTypes.bool.isRequired
}

StepperQuestion.defaultProps = {
    isSaving: false
}
export default withRouter(withStyles(useStyle, { withTheme: true })(StepperQuestion))