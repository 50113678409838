import React from 'react'
import propTypes from 'prop-types'
import Dialog from '../../common/Dialog'
import { Toolbar, Typography, withStyles } from '@material-ui/core'
import { LocalShipping, Map, Receipt } from '@material-ui/icons'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import { Translate } from 'react-localize-redux'

const DialogInfoTarif = (props) => {
	const { open, onClose, classes, transportType, maxKm, total, maxTransportZoneDirection } = props
	return (
		<Dialog
			style={{ overflowX: 'hidden' }}
			open={open}
			onClose={onClose}
			header={
				<Toolbar className={classes.title}>
					<Typography variant='subtitle2'>
						<b>
							<Translate id={'carrierTenders.detail.dialogInfoTarif.title'} />
						</b>
					</Typography>
				</Toolbar>
			}
			actions={
				<div className={classes.total}>
					<Typography className={classes.text}>
						<Translate id={'carrierTenders.detail.dialogInfoTarif.total'} />:
						{total
							? total.toLocaleString('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
							: '$0 MXN'}
					</Typography>
				</div>
			}
		>
			<div className={classes.informationContent}>
				<div className={classes.calculateContent}>
					<div className={classes.calculate}>
						<LocalShipping className={classes.icon} />
						<Typography className={classes.text}>+</Typography>
						<Map className={classes.icon} />
						<Typography className={classes.text}>=</Typography>
						<Receipt className={classes.icon} />
					</div>
				</div>
				<div>
					<div className={classes.transportTypeContent}>
						<Typography className={classes.transportType}>
							<Translate id={'carrierTenders.detail.dialogInfoTarif.transportType'} />:
						</Typography>
						<Typography variant='caption' className={classes.text}>
							{transportType}
						</Typography>
					</div>
					<Typography className={classes.text}>
						<Translate id={'carrierTenders.detail.dialogInfoTarif.greaterDistance'} />:
					</Typography>
					<div className={classes.textContent}>
						<Typography variant='caption' className={classes.maxDistance}>
							{maxTransportZoneDirection}
						</Typography>
						<Typography variant='caption' className={classes.text}>
							{maxKm} KM
						</Typography>
					</div>
				</div>
			</div>
		</Dialog>
	)
}
DialogInfoTarif.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
	transportType: propTypes.string,
	maxKm: propTypes.any,
	total: propTypes.any,
}
DialogInfoTarif.defaultProps = {
	open: false,
	onClose: () => console.warn('No [onClose] Call back defined'),
	transportType: '--',
	maxKm: 0,
	total: 0,
}
const styles = (theme) => ({
	title: {
		display: 'flex',
		justifyContent: 'center',
	},
	total: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: '.5rem',
	},
	informationContent: {
		minWidth: '296px',
		padding: '.5rem',
	},
	calculateContent: {
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: '8px',
	},
	calculate: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '160px',
		color: 'red !important',
	},
	icon: {
		color: theme.palette.primary.main,
	},
	maxDistance: {
		color: theme.palette.text.secondary,
		paddingRight: '8px',
	},
	text: {
		color: theme.palette.text.secondary,
	},
	textContent: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	transportTypeContent: {
		display: 'flex',
	},
	transportType: {
		color: theme.palette.text.secondary,
		paddingRight: '2px',
	},
})
export default withRouter(withStyles(styles, { withTheme: true })(DialogInfoTarif))
