import { fromJS, Map } from 'immutable';

import * as samplesActions from './SampleRequestAction';

/**
 * samplesInitialState initial state
 */
export const samplesInitialState = fromJS({
    isLoadingSampleRequest: true,
    samplerequests: null,
    isLoadingSample:false,
    sampleRequest: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    samplesCount: null,
    isSaving: false,
})

export default function samplesReducer(state = samplesInitialState, action){
    switch(action.type){
        case samplesActions.START_LOADING_SAMPLE_REQUESTS:{
            return state.merge({
                isLoadingSampleRequest: action.isLoading
            })
        }
        case samplesActions.SET_SAMPLE_REQUESTS_LIST:{
            let samplesLists = fromJS(action.samples);
            return state.merge({
                samplerequests: samplesLists,
                isLoadingSampleRequest: false
            })
        }
        case samplesActions.CHANGE_SAMPLE_REQUESTS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case samplesActions.CHANGE_SAMPLE_REQUESTS_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case samplesActions.CHANGE_SAMPLE_REQUESTS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case samplesActions.IS_LOADING_SAMPLE_REQUEST:{
            return state.merge({
                isLoadingSample: action.isLoadingSample,
                sampleRequest: null,
            })
        }
        case samplesActions.SET_SAMPLE_REQUEST:{
            return state.merge({
                isSaving: false,
                isLoadingSample: false,
                sampleRequest: fromJS(action.sample)
            })
        }
        case samplesActions.SET_SAMPLE_REQUESTS_COUNT: {
            return state.merge({
                samplesCount: action.count
            })
        }
        case samplesActions.IS_SAVING_SAMPLE_REQUEST:{
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case samplesActions.ADD_ANNOTATION_LIST_TO_RMI:{
            let annotation = fromJS(action.annotation)
            return state.updateIn(['sampleRequest', 'rmi_document', "annotations"], (annotations) => annotations.push(annotation))
            .set('isSaving', false);
        }
        case samplesActions.REMOVE_ANNOTATION_LIST_TO_RMI:{
            return state.updateIn(['sampleRequest', 'rmi_document', 'annotations'], (allAnnotations) => {
                let index = allAnnotations.findIndex((item) => {
                    return item.get('token') === action.annotationToken
                });
                return allAnnotations.delete(index).set('isSaving', false);
            })
        }
        case samplesActions.UPDATE_ANNOTATION_LIST_TO_RMI:{
            return state.updateIn(['sampleRequest', 'rmi_document', 'annotations'], (allAnnotations) => {
                let index = allAnnotations.findIndex((item) => {
                    return item.get('token') === action.annotation.token
                });
                 return allAnnotations.setIn([index], Map(action.annotation)).set('isSaving', false);
            });
        }
        case samplesActions.ADD_FILES_SAMPLE_REQUEST_COMPANY: {
            let oldList = state.getIn(['sampleRequest', 'rmi_document', 'attachments_company']).toJS();
            let filesToAdda = action.files.filter(newFileSupl => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFileSupl.name
                })
            })
            let newList = [...oldList, ...(filesToAdda)];
            return state.setIn(['sampleRequest', 'rmi_document', 'attachments_company'], fromJS(newList));
        }
        case samplesActions.REMOVE_FILES_SAMPLE_REQUEST_COMPANY: {
            let oldList = state.getIn(['sampleRequest', 'rmi_document', 'attachments_company']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['sampleRequest', 'rmi_document', 'attachments_company'], fromJS(newList));
        }
        case samplesActions.ADD_FILES_SAMPLE_REQUEST_SUPPLIER: {
            let oldList = state.getIn(['sampleRequest', 'attachments_suppliers']).toJS();
            let filesToAdda = action.files.filter(newFileSupl => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFileSupl.name
                })
            })
            let newList = [...oldList, ...(filesToAdda)];
            return state.setIn(['sampleRequest', 'attachments_suppliers'], fromJS(newList));
        }
        case samplesActions.REMOVE_FILE_SAMPLE_REQUEST_SUPPLIER: {
            let oldList = state.getIn(['sampleRequest', 'attachments_suppliers']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['sampleRequest', 'attachments_suppliers'], fromJS(newList));
        }
        default:
            return state;
    }
}