import axios from 'axios'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'
import {
	backgroundColorTableStatusQualityCertificate,
	descriptionStatusQualityCertificate,
} from '../helpers/SustainabilityHelper'

export const START_LOADING_QUALITY_LIST = 'START_LOADING_QUALITY_LIST'
export const SET_QUALITY_COUNT = 'SET_QUALITY_COUNT'
export const SET_QUALITY_LIST = 'SET_QUALITY_LIST'
export const START_ADD_QUALIRT_CERTIFICATE = 'START_ADD_QUALIRT_CERTIFICATE'
export const ADD_QUALITY_CERTIFICATE = 'ADD_QUALITY_CERTIFICATE'
export const SET_QUALITY_CERTIFICATE = 'SET_QUALITY_CERTIFICATE'
export const UPDATE_QUALITY_CERTIFICATE = 'UPDATE_QUALITY_CERTIFICATE'
export const IS_SAVING_QUALITY_CERTIFICATE = 'IS_SAVING_QUALITY_CERTIFICATE'
export const IS_DOWNLOADING_CERTIFICATE = 'IS_DOWNLOADING_CERTIFICATE'

/**
 * Action start loading common data
 * @param {*} isLoading
 */
function isLoadingCommon(isLoading, identifier) {
	return {
		type: START_LOADING_QUALITY_LIST,
		isLoading: isLoading,
		identifier: identifier,
	}
}

function setTotalCount(count) {
	return {
		type: SET_QUALITY_COUNT,
		count,
	}
}

function qualityList(qualityList) {
	return {
		type: SET_QUALITY_LIST,
		qualityList: qualityList,
	}
}

export function setQualityCertificate(qualityCertificate) {
	return {
		type: SET_QUALITY_CERTIFICATE,
		qualityCertificate,
	}
}

function isSavingQuality(isSaving) {
	return {
		type: IS_SAVING_QUALITY_CERTIFICATE,
		isSaving: isSaving,
	}
}

function isAddQualitySertificate(isSaving) {
	return {
		type: START_ADD_QUALIRT_CERTIFICATE,
		isSaving: isSaving,
	}
}

export function addQualityCertificateToList(qualityCertificate) {
	return {
		type: ADD_QUALITY_CERTIFICATE,
		qualityCertificate,
	}
}

function isDonwloadingReport(isDonwloading) {
	return {
		type: IS_DOWNLOADING_CERTIFICATE,
		isDonwloading,
	}
}

export function loadAllQualityCertificationsFromBackEnd(queryParameters, translate) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, START_LOADING_QUALITY_LIST))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualityCertificates`
			let command = {
				CompanyCode: selectedCompany.get('document_identifier'),
				PageNumber:
					queryParameters && queryParameters.pageNumber ? parseInt(queryParameters.pageNumber) + 1 : 1,
				PageSize: queryParameters && queryParameters.rowsPerPage ? queryParameters.rowsPerPage : 20,
				SortingBy: queryParameters && queryParameters.sortBy ? queryParameters.sortBy : 'CreationDate',
				SortingOrder: queryParameters && queryParameters.sortDirection ? queryParameters.sortDirection : 'desc',
				Query: queryParameters && queryParameters.query ? queryParameters.query : '',
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						let mappedResponse = serverResponse.data.Results.map((sustainability) => {
							sustainability.color_status_table = backgroundColorTableStatusQualityCertificate(
								sustainability.Status
							)
							sustainability.Status = descriptionStatusQualityCertificate(
								sustainability.Status,
								translate
							)
							return sustainability
						})
						dispatch(setTotalCount(serverResponse.data.TotalItems))
						dispatch(qualityList(mappedResponse))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isLoadingCommon(false, START_LOADING_QUALITY_LIST))
					console.error('Error getting sustainability list by company', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isLoadingCommon(false, START_LOADING_QUALITY_LIST))
		}
	}
}

export function addQualityCertificate(itemModel) {
	return function (dispatch, getState) {
		dispatch(isAddQualitySertificate(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplier = getState().suppliers.get('supplier') ? getState().suppliers.get('supplier').toJS() : null
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate`

			let command = {
				Comments: itemModel ? itemModel.comments : '',
				SupplierCode: supplier ? supplier.code : '',
				SupplierName: supplier ? supplier.name : '',
				CompanyCode: selectedCompany.get('document_identifier'),
				Invoice: itemModel ? itemModel.invoice : '',
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(addQualityCertificateToList(serverResponse.data))
						dispatch(isLoadingCommon(false))
						return Promise.resolve(serverResponse.data.Id)
					}
				})
				.catch((error) => {
					dispatch(isAddQualitySertificate(false))
					console.error('Error in add quality certificate', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isAddQualitySertificate(false))
		}
	}
}

export function loadQualityCertificateFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_QUALITY_CERTIFICATE))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQualityCertificate(serverResponse.data))
					dispatch(isLoadingCommon(false, SET_QUALITY_CERTIFICATE))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SET_QUALITY_CERTIFICATE))
				console.error('Error getting quality certificate', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function changeStatusQualityCertificate(id, status) {
	return function (dispatch, getState) {
		dispatch(isSavingQuality(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate/${id}/status`
		let command = {
			Id: id,
			Status: status,
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQualityCertificate(serverResponse.data))
					dispatch(isSavingQuality(false))
					return Promise.resolve(serverResponse.data.Id)
				}
			})
			.catch((error) => {
				dispatch(isSavingQuality(false))
				console.error('Error in add quality certificate', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function uploadFilesQualityCertificate(id, files, folder) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate/${id}/files/${folder}`
		let formData = new FormData()
		for (var index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQualityCertificate(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload sample files from supplier file(s)', error.response)
				return Promise.reject()
			})
	}
}

export function removeFilesQualityCertificate(id, fileName, folder) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate/${id}/files/${folder}/${fileName}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQualityCertificate(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to upload file(s)', error.response)
				return Promise.reject()
			})
	}
}

export function addPurchaseOrderDetail(id, itemModel) {
	return function (dispatch, getState) {
		dispatch(isSavingQuality(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate/${id}/details`

			let command = {
				PurchaseOrder: itemModel ? itemModel.purchaseOrder : '',
				ParNumber: itemModel ? (itemModel.partNumberSelected ? itemModel.partNumberSelected.code : '') : '',
				PartNumberDescription: itemModel
					? itemModel.partNumberSelected
						? itemModel.partNumberSelected.description
						: ''
					: '',
				Quality: itemModel ? itemModel.quality : '',
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setQualityCertificate(serverResponse.data))
						dispatch(isSavingQuality(false))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingQuality(false))
					console.error('Error in add detail quality certificate', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingQuality(false))
		}
	}
}

export function removePurchaseOrderDetail(id, itemId) {
	return function (dispatch, getState) {
		dispatch(isSavingQuality(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate/${id}/details/${itemId}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQualityCertificate(serverResponse.data))
					dispatch(isSavingQuality(false))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to delete item in certificate', error.response)
				dispatch(isSavingQuality(false))
				return Promise.reject()
			})
	}
}

export function validateQualityCertificate(id, itemId, status) {
	return function (dispatch, getState) {
		dispatch(isSavingQuality(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate/${id}/details/validate`
		let command = {
			Id: id,
			ItemId: itemId,
			Status: status,
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQualityCertificate(serverResponse.data))
					dispatch(isSavingQuality(false))
					return Promise.resolve(serverResponse.data.Id)
				}
			})
			.catch((error) => {
				dispatch(isSavingQuality(false))
				console.error('Error in validate purchase certificate', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function updateQualityCertificate(id, notes) {
	return function (dispatch, getState) {
		dispatch(isSavingQuality(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate/${id}/update`
		let command = {
			Notes: notes,
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQualityCertificate(serverResponse.data))
					dispatch(isSavingQuality(false))
					return Promise.resolve(serverResponse.data.Id)
				}
			})
			.catch((error) => {
				dispatch(isSavingQuality(false))
				console.error('Error in validate purchase certificate', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function downloadReportCertificate(supplier, modelDate) {
	return function (dispatch, getState) {
		dispatch(isDonwloadingReport(true))
		let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate/download/report`
		let selectedCompany = getState().profile.get('selectedCompany')
		let command = {
			BusinessUnitCode: selectedCompany.get('company_code'),
			SupplierCode: supplier ? supplier.code : '',
			EndDate: modelDate.endDate,
			StartDate: modelDate.startDate,
		}
		return axios
			.post(endPoint, command, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_certificados.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading report  downloadReportCertificate', error.response)
				return Promise.reject()
			})
	}
}

export const downloadFileCertificate = (id, item) => {
	return function (dispatch, getState) {
		let url = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate/download/file`
		let command = {
			Id: id,
			FileName: item.Name,
			FolderPatch: item.FolderPatch,
			Extension: item.ExtFile,
			RelativePath: item.RelativePath,
		}
		return axios
			.post(url, command, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `${item.Name}`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error donwload file', error)
				return Promise.reject()
			})
	}
}

export function importPurchaseOrdersFromExcel(id, attachments) {
	return function (dispatch, getState) {
		dispatch(isSavingQuality(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUSTAINABILITY}/qualitycertificate/${id}/import/template`
		let formData = new FormData()
		attachments.forEach((file) => {
			formData.append('data', file)
		})
		return axios
			.put(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQualityCertificate(serverResponse.data))
					dispatch(isSavingQuality(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingQuality(false))
				console.error('Error import purchase order in certificate ', error.response)
				return Promise.reject(error.response.data)
			})
	}
}
