
/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';

export const START_LOADING_DESTINATIONS = "START_LOADING_DESTINATIONS";
export const START_LOADING_DESTINATIONS_LIST = "START_LOADING_DESTINATIONS_LIST";
export const SET_DESTINATIONS_LIST = "SET_DESTINATIONS_LIST";
export const CHANGE_DESTINATIONS_SORT_CRITERIA = "CHANGE_DESTINATIONS_SORT_CRITERIA";
export const CHANGE_DESTINATIONS_QUERY = "CHANGE_DESTINATIONS_QUERY";
export const CHANGE_DESTINATIONS_PAGINATION = "CHANGE_DESTINATIONS_PAGINATION";
export const SET_DESTINATIONS_COUNT = "SET_DESTINATIONS_COUNT";
export const IS_SAVING_DESTINATION = "IS_SAVING_DESTINATION";
export const ADD_DESTINATION_TO_LIST = "ADD_DESTINATION_TO_LIST";
export const REMOVE_DESTINATION_TO_LIST = "REMOVE_DESTINATION_TO_LIST";
export const UPDATE_DESTINATION_IN_LIST = "UPDATE_DESTINATION_IN_LIST";

/**
 * Action start loading 
 * @param {*} isLoading 
 */
function isLoading(isLoading) {
    return {
        type: START_LOADING_DESTINATIONS,
        isLoading
    }
}

/**
 * Action start loading destinations
 * @param {*} isLoading 
 */
function isLoadingDestinations(isLoadingTransportDestinations) {
    return {
        type: START_LOADING_DESTINATIONS_LIST,
        isLoadingTransportDestinations
    }
}

/**
 * Action to set destinations list 
 * @param {*} shippingOrders 
 */
function setTransportDestinationsList(destinations) {
    return {
        type: SET_DESTINATIONS_LIST,
        destinations: destinations
    }
}

/**
 * Action reducer to set the destinatios counter
 *
 * @param {*} count
 * @returns
 */
function setDestinationsCount(count) {
    return {
        type: SET_DESTINATIONS_COUNT,
        count
    }
}

/**
 * Action to start save destination
 */
function isSavingDestination(isSaving) {
    return {
        type: IS_SAVING_DESTINATION,
        isSaving: isSaving
    }
}

/**
 * add destination in list
 * @param {*} destination 
 */
function addDestinationToList(destination) {
    return {
        type: ADD_DESTINATION_TO_LIST,
        destination
    }
}

/**
 * remove destination in list
 * @param {*} destinationToken 
 */
function removeDestinationToList(destinationToken){
    return {
        type: REMOVE_DESTINATION_TO_LIST,
        destinationToken
    }
}

/**
 * Action from update destionation
 * @param {*} worker 
 */
function updateDestination(destination) {
    return {
        type: UPDATE_DESTINATION_IN_LIST,
        destination
    }
}

/**
 * Action to serch Transport destination by Query 
 * @param {*} query 
 */
export function searchTransportDestinationByQuery(){
    return function(dispatch, getState){

        dispatch(isLoading(true));
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/destinations/search`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
        }
        return axios.post(endPoint, data, configuration)
        .then((response) => {
            if (response.status === 200) {
                dispatch(isLoading(false));
                return Promise.resolve(response.data);
            }
        })
        .catch((error) => {
            dispatch(isLoading(false));
            console.error(error);
        });        
    }
}


/**
 * Get all transport destinations by company from backend 
 */
export function loadDestinationsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {
        dispatch(isLoadingDestinations(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/destinations/all`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setTransportDestinationsList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingDestinations(false));
                console.error("Error getting the tranports destinations by company list", error.response)
            })
    }
}

/**
 * Get destination's count
 *
 * @export
 * @returns
 */
export function getDestinationsCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().transportdestinations.get('destinationsCount');
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
   
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/destinations/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setDestinationsCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the destinatins's count", error.response)
            })
    }
}

/**
 * Action new destiantion
 * @param {*} company 
 */
export function addTransportDestination(destination) {
    return function (dispatch, getState) {
        dispatch(isSavingDestination(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/destinations/create`;
        let companyToken = getState().profile.get("selectedCompany").get('token');
      
        let data = {
            CompanyToken : companyToken,
            Destination: destination.destination,
            Kilometres: destination.kilometres
        }

        return axios.post(endPoint, data, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(addDestinationToList(response.data));
                    return Promise.resolve();
                }
                else {
                    dispatch(isSavingDestination(false));
                }
            })
            .catch((error) => {
                console.error("Error create new destination", error.response);
                dispatch(isSavingDestination(false));
                return Promise.reject(error.response.data.Message);
            })
    }
}

/**
 * Remove destination token
 * @param {*} destinationToken 
 */
export function removeTransportDestination(destinationToken) {
    return function (dispatch, getState) {

        dispatch(isSavingDestination(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/destinations/${destinationToken}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingDestination(false));
                    dispatch(removeDestinationToList(destinationToken));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSavingDestination(false));
                console.error("Error in remove destiantion tranport", error.response)
                return Promise.reject(error.response.data.Message);
            })
    }
}

/**
 * Action to update destination data
 * @param {*} destination 
 */
export function updateDestinationData(destination) {
    return function (dispatch, getState) {
        dispatch(isSavingDestination(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/destinations`;
        let data = {
            TransportToken: destination.token,
            Destination: destination.destination,
            Kilometres: destination.kilometres,
        }
        return axios.put(endPoint, data, configuration)
            .then((response) => {
                dispatch(updateDestination(response.data))
            })
            .catch((error) => {
                dispatch(isSavingDestination(false));
                console.error("Error in update destiantion tranport", error.response)
                return Promise.reject(error.response.data.Message);
            })
    }
}

