import React, { Component } from 'react';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

/**
 * InformationNoticeCard 
 *
 * @class InformationNoticeCard
 * @extends {Component}
 */
class InformationNoticeCard extends Component {
    render() {
        return (
            <Paper>
                <List>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="noticeChanges.noticeType" /></Typography>}
                            secondary={this.props.noticechange.notice_type} />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="noticeChanges.developerComments" /></Typography>}
                            secondary={this.props.noticechange.observations} />
                    </ListItem>
                </List>
            </Paper>
        );
    }
}
export default InformationNoticeCard;