/** React imports section */
import React from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
/** Component imports section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
/** Material-UI imports section */
import { Avatar, Button, Grid, TextField, Typography, withStyles } from '@material-ui/core'
import { Description } from '@material-ui/icons'
/** Helper imports section */
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'

const PackageCreatePanel = (props) => {
	const { onClose, isSaving, onSave, documentName, onPropertyChange, classes } = props

	const canBeSaved = () => {
		if (IsNullOrEmpty(documentName)) return false
		return true
	}

	return (
		<LateralPanel onClose={onClose}>
			<PanelHeader
				titlePanel={<Translate id='specificationsSection.package.addSpecificationLabel' />}
				icon={
					<Avatar className={classes.avatar}>
						<Description />
					</Avatar>
				}
				onCloseButtonClick={onClose}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid container>
						<Typography color='primary'>
							<Translate id='specificationsSection.package.specificationName' />
						</Typography>
						<Grid item xs={12}>
							<TextField
								fullWidth
								className={classes.textField}
								name='documentName'
								margin='normal'
								variant='outlined'
								value={documentName || ''}
								onChange={onPropertyChange}
							/>
						</Grid>
					</Grid>
				</div>
				<Grid container>
					<Grid item xs={12} className={classes.containerBtn}>
						<Button
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='outlined'
							className={classes.saveButton}
							disabled={!canBeSaved() || isSaving}
							onClick={onSave}
						>
							<Translate id='common.save' />
						</Button>
						<Button
							data-cy='btn-cancel'
							type='button'
							color='secondary'
							variant='outlained'
							className={classes.cancelButton}
							onClick={onClose}
						>
							<Translate id='common.cancel' />
						</Button>
					</Grid>
				</Grid>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	saveButton: {
		bottom: 0,
		margin: 2,
	},
	cancelButton: {
		color: theme.palette.error.main,
		bottom: 0,
		margin: 2,
	},
})

PackageCreatePanel.propTypes = {
	onClose: propTypes.func,
	isSaving: propTypes.bool,
	onSave: propTypes.func,
	documentName: propTypes.string,
	onPropertyChange: propTypes.func,
}

PackageCreatePanel.defaultProps = {
	onClose: () => console.warn('[onClose] is not defined!'),
	isSaving: false,
	onSave: () => console.warn('[onSave] is not defined!'),
	documentName: '',
	onPropertyChange: () => console.warn('[onPropertyChange] is not defined!'),
}

export default withStyles(styles, { withTheme: true })(PackageCreatePanel)
