import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Chip, CircularProgress, ClickAwayListener, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import AttachIcon from '@material-ui/icons/AttachFile'
import CloseIcon from '@material-ui/icons/Cancel'
import { DropzoneArea } from 'material-ui-dropzone'
import { Translate } from 'react-localize-redux'
import '../../resources/styles/dropzone.css'
/** Dictionay with lists of extensions to accept by dropzone */
const dictionaryExtensions = {
	Images: ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'], //Images default extensions
	Video: [
		'.webm',
		'.mkv',
		'.flv',
		'.ogg',
		'.ogv',
		'.gif',
		'.avi',
		'.mov',
		'.qt',
		'.wmv',
		'.mp4',
		'.m4p',
		'.3gp',
		'.3g2',
		'.m4v',
		'.mpeg',
	], //Video default extensions
	OfficeDocuments: [
		//Contains most common Microsoft office extensions
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //Word default extensions
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //Excel default extensions
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint default extensions
		'application/vnd.openxmlformats-officedocument.presentationml.slideshow', // PowerPoint default extensions
	],
	Word: [
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //Word default extensions
	],
	Excel: [
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //Excel default extensions
	],
	PowerPoint: [
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint default extensions
		'application/vnd.openxmlformats-officedocument.presentationml.slideshow', // PowerPoint default extensions
	],
	Pdf: ['application/pdf'], //PDF default extensions
	OtherFiles: ['.eml', '.msg', '*smg', '.zip', '.rar'], /// msg
	XML: ['.xml'],
	CSV: ['.csv'],
}

/** Default file extensions to accept on dropzone,
 *  All extensions in dictionary are accepted by default*/
const defaultFileExtensions = dictionaryExtensions.Images.concat(dictionaryExtensions.OfficeDocuments)
	.concat(dictionaryExtensions.Pdf)
	.concat(dictionaryExtensions.Video)
	.concat(dictionaryExtensions.OtherFiles)
/** Default maximum quantity of files to accept */
const defaultMaxFiles = 10
/** Default maximum size (bytes) of file to accept */
const defaultMaxSizeFile = 150000000 //150MB by default
/** Default message text to show in drop zone*/
const defaultDropzoneText = 'Arrastra y suelta una imagen o archivo aquí o haz clic'
/** Default value to show preview of files beloww the container*/
const defaultShowPreviewBelow = false
/** Default value to show preview of files inside the container*/
const defaultShowPreviewInside = false
/** Default value to show alerts*/
const defaultShowAlerts = false
/** Default value to show File names in preview under the image/file to upload*/
const defaultShowFileNames = true
/** Default Class name to custom component style*/
const defaultClass = 'files-container' //A class named like this should be created to custom style
/** Default Class name to custom text styles in component */
const defaultTextClass = 'dropzone-text' //A class named like this should be created to custom style

/**
 * Class to create Dropzone base component
 *
 * @class Dropzone
 * @extends {Component}
 */
class Dropzone extends Component {
	/**
	 *Creates an instance of Dropzone.
	 * @param {*} props
	 * @memberof Dropzone
	 */
	constructor(props) {
		super(props)
		this.state = {
			uploadedFiles: this.props.files ? this.props.files : [],
			wasClicked: '',
			isLoading: false,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.files !== this.props.files) {
			this.setState({
				uploadedFiles: this.props.files,
			})
		}
	}

	/**
	 * Handle change event
	 *
	 * @param {*} files
	 * @memberof Dropzone
	 */
	handleChange(filesToAdd) {
		let newFiles = []
		for (let index = 0; index < filesToAdd.length; index++) {
			let notFound = !this.state.uploadedFiles.find((uploadedFile) => {
				return uploadedFile.name === filesToAdd[index].name
			})
			if (notFound) {
				newFiles.push(filesToAdd[index])
			}
		}
		if (this.props.onChange) {
			this.props.onChange(newFiles).then((value) => {
				if (value === 'Ok') {
					this.setState({
						uploadedFiles: this.state.uploadedFiles,
					})
				}
			})
		}
	}

	/**
	 * handle state to change isLoading flag
	 *
	 * @memberof Dropzone
	 */
	turnLoadingFlag() {
		this.setState({
			isLoading: !this.state.isLoading,
		})
	}

	/**
	 * Handle click on delete icon of chip to remove file
	 *
	 * @param {*} fileName
	 * @memberof Dropzone
	 */
	handleDelete(event) {
		this.turnLoadingFlag()
		let fileName = event.currentTarget.id
		event.preventDefault()
		if (this.props.onDeleteFile) {
			this.props
				.onDeleteFile(fileName)
				.then((value) => {
					if (value === 'Ok') {
						this.setState({
							uploadedFiles: this.props.files,
							isLoading: false,
						})
					}
				})
				.catch((error) => {
					this.turnLoadingFlag()
				})
		} else {
			this.turnLoadingFlag()
		}
	}

	/**
	 * Function to determine valid extensions accepted by dropzone
	 *
	 * @param {*} extensionsList
	 * @returns
	 * @memberof Dropzone
	 */
	determineExtensions(extensionsList) {
		let extensions = []
		extensionsList.forEach((extension) => {
			let key = Object.keys(dictionaryExtensions).find((key) => {
				return key.toLowerCase() === extension.toLowerCase()
			})
			extensions = extensions.concat(dictionaryExtensions[key])
		})
		return extensions
	}

	/**
	 * Handle click on chip to download file
	 *
	 * @param {*} file
	 * @memberof Dropzone
	 */
	handleClickChip(event) {
		this.setState({
			wasClicked: event.currentTarget.id,
		})
		if (this.props.onClickFile) this.props.onClickFile(event.currentTarget.id)
	}

	render() {
		let hasFiles = this.state.uploadedFiles && this.state.uploadedFiles.length > 0
		let isClickable = this.props.disabledLink ? !this.props.disabledLink : true // by default is clickable
		return (
			<div
				style={{
					margin: '5px',
					height: '95px !important',
					width: '100%',
				}}
			>
				{!this.props.isDisabled && (
					<DropzoneArea
						acceptedFiles={
							this.props.acceptedFiles && this.props.acceptedFiles.length > 0
								? this.determineExtensions(this.props.acceptedFiles)
								: defaultFileExtensions
						}
						filesLimit={this.props.maxFiles ? this.props.maxFiles : defaultMaxFiles}
						maxFileSize={this.props.maxSizeFile ? this.props.maxSizeFile : defaultMaxSizeFile}
						dropzoneText={this.props.dropzoneText ? this.props.dropzoneText : defaultDropzoneText}
						showPreviews={
							this.props.showPreviewBelow ? this.props.showPreviewBelow : defaultShowPreviewBelow
						}
						showPreviewsInDropzone={
							this.props.showPreviewInside ? this.props.showPreviewInside : defaultShowPreviewInside
						}
						showAlerts={this.props.showAlerts ? this.props.showAlerts : defaultShowAlerts}
						dropzoneClass={this.props.customClass ? this.props.customClass : defaultClass} //Parameter to customize style of container
						dropzoneParagraphClass={
							this.props.customTextClass ? this.props.customTextClass : defaultTextClass
						} //parameter to customize text style inside container
						onChange={this.handleChange.bind(this)}
						showFileNamesInPreview={
							this.props.showFileNames ? this.props.showFileNames : defaultShowFileNames
						}
					/>
				)}
				<div style={{ margin: '5px' }}>
					{this.props.isDisabled && <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />}
					{hasFiles &&
						this.state.uploadedFiles.map((file) => {
							return (
								<ClickAwayListener
									key={file.name}
									onClickAway={() => this.setState({ wasClicked: '' })}
								>
									<Chip
										icon={<AttachIcon />}
										label={
											<div
												style={{
													maxWidth: '100px',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
											>
												<Typography noWrap title={file.name}>
													{file.name}
												</Typography>
											</div>
										}
										clickable={isClickable && !this.state.isLoading}
										component={isClickable && !this.state.isLoading ? 'a' : 'div'}
										target='_blank'
										href={!this.state.isLoading ? `${this.props.baseApi}/${file.relativePath}` : ''}
										id={file.name}
										key={file.name}
										onClick={
											isClickable && !this.state.isLoading
												? (event) => {
														this.handleClickChip(event)
												  }
												: ''
										}
										color={this.state.wasClicked === file.name ? 'secondary' : 'primary'}
										onDelete={
											!this.props.isDisabled
												? this.state.isLoading
													? () => {}
													: (event) => {
															this.handleDelete(event)
													  }
												: ''
										}
										deleteIcon={
											!this.props.isDisabled ? (
												this.state.isLoading ? (
													<CircularProgress
														color='textDisabled'
														disableShrink
														size={16}
														thickness={7}
													/>
												) : (
													<CloseIcon id={file.name} />
												)
											) : (
												''
											)
										}
										style={{
											margin: '1px',
											maxWidth: '450px',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									></Chip>
								</ClickAwayListener>
							)
						})}
					{this.props.isDisabled && !hasFiles && <Translate id='common.noFiles' />}
					{this.props.isDisabled && <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />}
				</div>
			</div>
		)
	}
}

Dropzone.propTypes = {
	files: PropTypes.array.isRequired,
	onDeleteFile: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	baseApi: PropTypes.string.isRequired,
	isDisabled: PropTypes.bool,
	onClickFile: PropTypes.func,
	acceptedFiles: PropTypes.array,
	maxFiles: PropTypes.number,
	maxSizeFile: PropTypes.number,
	dropzoneText: PropTypes.string,
	showPreviewBelow: PropTypes.bool,
	showPreviewInside: PropTypes.bool,
	showAlerts: PropTypes.bool,
	customClass: PropTypes.string,
	customTextClass: PropTypes.string,
	showFileNames: PropTypes.bool,
}

export default Dropzone
