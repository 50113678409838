///Import react section
import React, { useCallback, useEffect } from 'react'
import { Translate, getTranslate } from 'react-localize-redux'
import { useSelector } from 'react-redux'
/** Import component section */
import Toaster from '../common/Toaster'
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog'

/**import helpers section */
import LateralPanelContainer from '../common/LateralPanelComponents/LateralPanelContainer'
import ImportFileProvidersComponent from './components/internalUser/ImportFileProvidersComponent'
import SupplierCommentsLateralComponent from './components/suppliers/SupplierCommentsLateralComponent'
import PanelActionPanel from './components/actionPlant/PanelActionPanel'
/**Custom hooks index */
import { useIndirectQualificationCustomHookShow } from './customHooks/UseCustomHookShow'
import { IndirectQualificationsStatus } from '../../store/helpers/AppConstants'
import { GENERATE_ACTIONS_PLAN } from '../../store/indirectqualifications/IndirectqualificationsActions'
import SupplierRatingCommentsLateralPanelComponent from './components/suppliers/SupplierRatingCommentsLateralPanelComponent'
const Show = (props) => {
	/**String props section */
	const { identifierPanelLateral } = props
	/**Bools props section */
	//const { } = props
	/**Arrays props section */
	// const { } = props
	/**Functions props section */
	const { onConfirmPassword } = props

	/**Objects props section */
	const { setIdentifierPanelLateral, item, confirmPasswordConfigModal } = props
	/**Render hooks section */
	/**Redux store information section*/
	const toasterMessageConfig = useSelector((state) => state.indirectQualifications.get('toasterConfiguration').toJS())
	const {
		onCloseToaster,
		onImportIndirectQualificationTemplateFile,
		onLoadIndirectQualificationFromBackEnd,
		onSetStatusInRating,
		onAddCommentsToSupplier,
		onGenerateActionPlantForSupplier,
		onAddItemComments,
	} = useIndirectQualificationCustomHookShow()

	const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'))
	const translate = useSelector((state) => getTranslate(state.localize))
	const indirectQualification = useSelector((state) =>
		state.indirectQualifications.get('indirectQualification')
			? state.indirectQualifications.get('indirectQualification').toJS()
			: null
	)

	/**
	 * Import file suppliers
	 */
	const onImportFileTemplate = (file) => {
		onImportIndirectQualificationTemplateFile(file, indirectQualification.Id).then((response) => {
			if (response === true) {
				onCloseLateralPanel()
				onLoadIndirectQualificationFromBackEnd(indirectQualification.Id)
			}
		})
	}

	const onAddSupplierComments = (supplier) => {
		onAddCommentsToSupplier(supplier).then((response) => {
			if (response === true) {
				onCloseLateralPanel()
			}
		})
	}

	const onAddItemCommentsForSupplier = (supplier) => {
		onAddItemComments(supplier).then((response) => {
			if (response === true) {
				onCloseLateralPanel()
			}
		})
	}

	/**Determinate that panel lateral is selected for open */
	const PanelLateralChildren = useCallback(
		{
			IndexCreateIndirectQualification: (
				<ImportFileProvidersComponent
					onSaveButtonClick={onImportFileTemplate}
					translate={translate}
					indirectQualification={indirectQualification}
				/>
			),
			ShowAddCommentsSupplierIndirectQualification: (
				<SupplierCommentsLateralComponent
					isSaving={isSaving}
					translate={translate}
					onSaveButtonClick={onAddSupplierComments}
					supplier={item}
				/>
			),
			ShowEditActionPlant: (
				<PanelActionPanel
					translate={translate}
					//onSaveButtonClick={onAddSupplierComments}
					//supplier={item}
				/>
			),
			SupplierRatingCommentsLateralPanelComponent: (
				<SupplierRatingCommentsLateralPanelComponent
					isSaving={isSaving}
					translate={translate}
					onSaveButtonClick={onAddItemCommentsForSupplier}
					item={item}
				/>
			),
		},
		[identifierPanelLateral]
	)

	const { LateralPanelComponent, onOpenAndCloseLateralPanel, onCloseLateralPanel } = LateralPanelContainer({
		children: PanelLateralChildren[identifierPanelLateral],
		identifier: identifierPanelLateral,
		setIdentifierPanelLateral: setIdentifierPanelLateral,
	})

	const onLateralPanelConfigChange = useCallback(() => {
		if (identifierPanelLateral) {
			onOpenAndCloseLateralPanel()
		}
	}, [identifierPanelLateral])

	/**Open lateral panel when identifier of panel change */
	useEffect(() => {
		onLateralPanelConfigChange()
	}, [onLateralPanelConfigChange])

	const onCancelFolio = (confirmationModel) => {
		onSetStatusInRating(
			indirectQualification.Id,
			IndirectQualificationsStatus.CANCEL,
			confirmationModel.comments
		).then((response) => {
			if (response === true) {
				onConfirmPassword()
			}
		})
	}

	const onSendToEvaluate = () => {
		onSetStatusInRating(indirectQualification.Id, IndirectQualificationsStatus.SEND_TO_EVALUATE).then(
			(response) => {
				if (response === true) {
					onConfirmPassword()
				}
			}
		)
	}

	const onGenerateRating = () => {
		onSetStatusInRating(indirectQualification.Id, IndirectQualificationsStatus.GENERATE_RATING).then((response) => {
			if (response === true) {
				onConfirmPassword()
			}
		})
	}

	const onPublishRatings = () => {
		onSetStatusInRating(indirectQualification.Id, IndirectQualificationsStatus.PUBLISH_RATINGS).then((response) => {
			if (response === true) {
				onConfirmPassword()
			}
		})
	}

	const generateActionPlantForSupplier = () => {
		onGenerateActionPlantForSupplier(confirmPasswordConfigModal ? confirmPasswordConfigModal.item : null).then(
			(response) => {
				if (response === true) {
					onConfirmPassword()
				}
			}
		)
	}

	const onRemoveFolio = (confirmationModel) => {
		onSetStatusInRating(
			indirectQualification.Id,
			IndirectQualificationsStatus.REMOVE,
			confirmationModel.comments
		).then((response) => {
			if (response === true) {
				onConfirmPassword()
				props.history.push(`/indirectqualifications`)
			}
		})
	}

	const getMessageByIdentifier = () => {
		switch (confirmPasswordConfigModal.identifier) {
			case IndirectQualificationsStatus.CANCEL:
				return 'indirectqualifications.operationsAvailable.messageCancelQualification'
			case IndirectQualificationsStatus.SEND_TO_EVALUATE:
				return 'indirectqualifications.operationsAvailable.messageSentQualification'
			case IndirectQualificationsStatus.GENERATE_RATING:
				return 'indirectqualifications.operationsAvailable.messageGenerateRatingsMessage'
			case IndirectQualificationsStatus.PUBLISH_RATINGS:
				return 'indirectqualifications.operationsAvailable.messagePublishMessage'
			case GENERATE_ACTIONS_PLAN:
				return 'indirectqualifications.operationsAvailable.messageActionPlan'
			case IndirectQualificationsStatus.REMOVE:
				return 'indirectqualifications.operationsAvailable.messageRemoveRatingResponse'
			default:
				return ''
		}
	}

	const getCallBackToInvokeByIndentifier = () => {
		switch (confirmPasswordConfigModal.identifier) {
			case IndirectQualificationsStatus.CANCEL:
				return onCancelFolio
			case IndirectQualificationsStatus.SEND_TO_EVALUATE:
				return onSendToEvaluate
			case IndirectQualificationsStatus.GENERATE_RATING:
				return onGenerateRating
			case IndirectQualificationsStatus.PUBLISH_RATINGS:
				return onPublishRatings
			case GENERATE_ACTIONS_PLAN:
				return generateActionPlantForSupplier
			case IndirectQualificationsStatus.REMOVE:
				return onRemoveFolio
			default:
				return ''
		}
	}

	const getIsRequiredComments = () => {
		switch (confirmPasswordConfigModal.identifier) {
			case IndirectQualificationsStatus.CANCEL:
				return true
			default:
				return false
		}
	}

	return (
		<>
			{confirmPasswordConfigModal && confirmPasswordConfigModal.opened && (
				<ConfirmPasswordDialog
					open={confirmPasswordConfigModal.opened}
					title={<Translate id='common.confirmOperation' />}
					message1={<Translate id={getMessageByIdentifier()} />}
					message2={<Translate id='common.enterPassword' />}
					onClickButtonConfirm={getCallBackToInvokeByIndentifier()}
					onClose={onConfirmPassword}
					isSaving={isSaving}
					isVisibleComments={getIsRequiredComments()}
					commentRequired={getIsRequiredComments()}
				/>
			)}
			<LateralPanelComponent />
			<Toaster
				message={
					<Translate
						id={toasterMessageConfig.toasterMessage}
						data={{ name: toasterMessageConfig ? toasterMessageConfig.name : '' }}
					/>
				}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => onCloseToaster()}
			/>
		</>
	)
}

export default Show
