/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
/**Import material UI Section*/

import {
    withStyles,
} from "@material-ui/core";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import QualificationHeaderTable from './QualificationHeaderTable'
import QualificationContentTable from './QualificationContentTable'

const QualificationContainerTable = (props) => {
    /**String props section */
    // const { } = props
    /**Bools props section */
    //const { isSaving, isVisibleComments } = props
    /**Arrays props section */
    //const { userRolesByCompany } = props
    /**Functions props section */
    const { onLateralPanelConfig, onConfirmPassword, onRedirectToActionPlant } = props
    /**Objects props section */
    const { classes, indirectQualification, translate } = props
    return (
        <div className={classes.root}>
            <Table
                className={classes.table}
                size="small">
                <QualificationHeaderTable
                    indirectQualification={indirectQualification}
                    translate={translate}
                />
                <TableBody>
                    <QualificationContentTable
                        indirectQualification={indirectQualification}
                        onLateralPanelConfig={onLateralPanelConfig}
                        onConfirmPassword={onConfirmPassword}
                        onRedirectToActionPlant={onRedirectToActionPlant}
                        translate={translate}
                    />
                </TableBody>
            </Table>
        </div>
    )
}

const styles = (theme) => ({
    root: {
        //maxHeight: `calc(100vh - ${335}px)`,
        maxWidth: `calc(100vw - ${315}px)`,
        overflowX: "auto",
        overflowY: "auto",
    },
    table: {
        maxWidth: '100%',
        //minHeight: 90,
        //maxHeight: '100%'
    }
});

QualificationContainerTable.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
}

QualificationContainerTable.defaultProps = {
    isLoading: false,
}

export default withRouter(
    withStyles(styles, { withTheme: true })(QualificationContainerTable)
);
