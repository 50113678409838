import { fromJS, Map } from 'immutable';

import * as destiantionAction from './TransportDestinationAction';

/**
 * initial state
 */
export const destiantionInitialState = fromJS({
    isLoadingDestinations: true,
    destinations: null,
    destinationsCount: null,
    sortCriteria: {
        by: "destination",
        ascending: true
    },
    page: 0,
    rowsPerPage: 20,
    searchQuery: "",
    isSavingDestination: false,
})

export default function destiantionReducer(state = destiantionInitialState, action) {
    switch (action.type) {
        case destiantionAction.START_LOADING_DESTINATIONS: {
            return state.merge({
                isLoadingDestinations: action.isLoading
            })
        }
        case destiantionAction.START_LOADING_DESTINATIONS_LIST: {
            return state.merge({
                isLoadingDestinations: action.isLoadingTransportDestinations
            })
        }
        case destiantionAction.SET_DESTINATIONS_LIST: {
            let destinationsList = fromJS(action.destinations);
            return state.merge({
                destinations: destinationsList,
                isLoadingDestinations: false
            })
        }
        case destiantionAction.CHANGE_DESTINATIONS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case destiantionAction.CHANGE_DESTINATIONS_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case destiantionAction.CHANGE_DESTINATIONS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case destiantionAction.SET_DESTINATIONS_COUNT: {
            return state.merge({
                destinationsCount: action.count
            })
        }
        case destiantionAction.IS_SAVING_DESTINATION: {
            return state.merge({
                isSavingDestination: action.isSaving
            })
        }
        case destiantionAction.ADD_DESTINATION_TO_LIST:{
            let destinationToAdd = fromJS(action.destination)
            return state.updateIn(["destinations"], (allDestinations) => allDestinations.push(destinationToAdd))
            .set('isSavingDestination', false);
        }
        case destiantionAction.REMOVE_DESTINATION_TO_LIST:{
            return state.updateIn(['destinations'], (allDestinations) => {
                let index = allDestinations.findIndex((item) => {
                    return item.get('token') === action.destinationToken
                });
                return allDestinations.delete(index).set('isSavingDestination', false);
            })
        }
        case destiantionAction.UPDATE_DESTINATION_IN_LIST: {
            let destinationsList = state.get('destinations').map((itemMap) => {
                if (itemMap.get("token") === action.destination.token) {
                    return Map(action.destination);
                }
                return itemMap;
            })
            return state.merge({
                destinations: destinationsList,
                isSavingDestination: false,
            })
        }
        default:
            return state;
    }
}
