import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import MemosIcon from '@material-ui/icons/FileCopyTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import { AppBar, Tabs } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import PowerSettingIcon from '@material-ui/icons/PowerSettingsNew'

/** Redux import section */
import { loadMemoFromBackEnd, finalizeMemoFromBackend, updateDataMemoFromBackend } from '../../store/memos/MemoActions'
import {
	cancelWorflowFromBackend,
	reactiveWorkflowDocument,
	sendReminderToSupplier,
} from '../../store/workflows/workflowActions'
import {
	acceptRMISampleRequestByClient,
	registerSampleArrive,
	rejectRMISampleRequestByClient,
	rmiActivateToTheSupplierForModify,
} from '../../store/samplerequests/SampleRequestAction'

/** Custom components import section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import AddCommonItem from '../common/AddCommonItem'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import CostSummary from '../memos/Components/CostSummary'
import SuppliersCard from './Components/SuppliersCard'
import FloatingButton from '../common/FloatingButton'
import DialogAvailableOperations from '../common/DialogAvailableOperations'
import ArriveRegisterDate from '../samplerequests/components/ArriveRegisterDate'
import CancelWorkflowDialog from '../common/CancelWorkflowDialog'

/** Resources import section */
import { canSampleRequestBeCanceled, canBeValidateModuleDevelopment } from '../../store/helpers/RolesHelper'
import { converListToArray } from '../../store/helpers/ListHelper'

/**
 * Container show memo
 */
class Show extends Component {
	/**
	 * Create an instance ofMemos
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openUpdateMemo: false,
			openFinalizeMemo: false,
			activeTab: 0,
			availableOperationsDialogOpened: false,
			arriveRegisterDialogOpened: false,
			confirmSupplierReminderDialogOpened: false,
			confirmationRmiAcceptedDialogOpened: false,
			confirmationRmiRejectedDialogOpened: false,
			editRmiDialogOpened: false,
			cancelSampleRequestDialogOpened: false,
			selectedSampleRequestTokens: [],
			isSaving: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		this.loadMemoDetail()
	}

	loadMemoDetail() {
		let memoToken = this.props.match.params.token
		this.props.loadMemo(memoToken)
	}

	/**
	 * Open dialog to send supplier remider
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onReminderToSupplierClicked() {
		this.setState({
			availableOperationsDialogOpened: false,
			confirmSupplierReminderDialogOpened: true,
		})
	}

	/**
	 * Handles event triggered to send supplier reminder
	 */
	onSendSupplierReminder(comment) {
		const selectedSampleRequests = getSelectedSampleRequests(
			this.props.memo,
			this.state.selectedSampleRequestTokens
		)
		const failedSampleRequestFolios = []

		const sendReminders = async (sampleRequests) => {
			for (const sampleRequest of sampleRequests) {
				try {
					await this.props.sendReminderToSupplier(sampleRequest.token, comment)
				} catch {
					failedSampleRequestFolios.push(sampleRequest.folio)
				}
			}
		}
		this.setState({ isSaving: true })
		sendReminders(selectedSampleRequests).finally(() => {
			if (failedSampleRequestFolios.length === 0) {
				this.setState({
					confirmSupplierReminderDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			} else {
				this.setState({
					confirmSupplierReminderDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'error',
					toasterMessage: (
						<div>
							<div>
								<Translate id='common.errorToSave' /> <br />
								<Translate id='common.folio' />:<br />
							</div>
							<div>
								<ul>
									{selectedSampleRequests.map(({ folio }) => (
										<li key={folio}>{folio}</li>
									))}
								</ul>
							</div>
						</div>
					),
				})
			}
			this.loadMemoDetail()
		})
	}

	/**
	 * Open dialog to confirm rmi accepting
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onArriveRegisterDateClicked() {
		this.setState({
			availableOperationsDialogOpened: false,
			arriveRegisterDialogOpened: true,
		})
	}

	/**
	 * Handles event triggerd to register arrival date by client
	 * @param {*} arrivalDate
	 */
	onRegisterArrivalDateByClient(arrivalDate) {
		const selectedSampleRequests = getSelectedSampleRequests(
			this.props.memo,
			this.state.selectedSampleRequestTokens
		)
		const failedSampleRequestFolios = []

		const registerArrivals = async (sampleRequests) => {
			for (const sampleRequest of sampleRequests) {
				try {
					await this.props.registerSampleArrival(
						sampleRequest.token,
						arrivalDate,
						sampleRequest.number_of_samples_requested
					)
				} catch {
					failedSampleRequestFolios.push(sampleRequest.folio)
				}
			}
		}
		this.setState({ isSaving: true })
		registerArrivals(selectedSampleRequests).finally(() => {
			if (failedSampleRequestFolios.length === 0) {
				this.setState({
					arriveRegisterDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			} else {
				this.setState({
					arriveRegisterDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'error',
					toasterMessage: (
						<div>
							<div>
								<Translate id='common.errorToSave' /> <br />
								<Translate id='common.folio' />:<br />
							</div>
							<div>
								<ul>
									{selectedSampleRequests.map(({ folio }) => (
										<li key={folio}>{folio}</li>
									))}
								</ul>
							</div>
						</div>
					),
				})
			}
			this.loadMemoDetail()
		})
	}

	/**
	 * Open dialog to confirm rmi accepting
	 */
	onConfirmRmiAcceptedClicked() {
		this.setState({
			availableOperationsDialogOpened: false,
			confirmationRmiAcceptedDialogOpened: true,
		})
	}

	/**
	 * Handles event triggered to accept RMI sample request by client
	 */
	acceptRmiSampleRequestByClient() {
		const selectedSampleRequests = getSelectedSampleRequests(
			this.props.memo,
			this.state.selectedSampleRequestTokens
		)
		const failedSampleRequestFolios = []

		const acceptRmiSampleRequests = async (sampleRequests) => {
			for (const sampleRequest of sampleRequests) {
				try {
					await this.props.acceptRmiSampleRequestByClient(sampleRequest.token)
				} catch (err) {
					failedSampleRequestFolios.push(sampleRequest.folio)
				}
			}
		}
		this.setState({ isSaving: true })
		acceptRmiSampleRequests(selectedSampleRequests).finally(() => {
			if (failedSampleRequestFolios.length === 0) {
				this.setState({
					confirmationRmiAcceptedDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			} else {
				this.setState({
					confirmationRmiAcceptedDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'error',
					toasterMessage: (
						<div>
							<div>
								<Translate id='common.errorToSave' /> <br />
								<Translate id='common.folio' />:<br />
							</div>
							<div>
								<ul>
									{selectedSampleRequests.map(({ folio }) => (
										<li key={folio}>{folio}</li>
									))}
								</ul>
							</div>
						</div>
					),
				})
			}
			this.loadMemoDetail()
		})
	}

	/**
	 * Open dialog to confimrm rmi rejecteing
	 */
	onConfirmRMIRejectedClicked() {
		this.setState({
			availableOperationsDialogOpened: false,
			confirmationRmiRejectedDialogOpened: true,
		})
	}

	/**
	 * Handles event triggered to reject RMI sample request by client
	 */
	rejectRmiSampleRequestByClient() {
		const selectedSampleRequests = getSelectedSampleRequests(
			this.props.memo,
			this.state.selectedSampleRequestTokens
		)
		const failedSampleRequestFolios = []

		const rejectRmiSampleRequests = async (sampleRequests) => {
			for (const sampleRequest of sampleRequests) {
				try {
					await this.props.rejectRmiSampleRequestByClient(sampleRequest.token)
				} catch (err) {
					failedSampleRequestFolios.push(sampleRequest.folio)
				}
			}
		}
		this.setState({ isSaving: true })
		rejectRmiSampleRequests(selectedSampleRequests).finally(() => {
			if (failedSampleRequestFolios.length === 0) {
				this.setState({
					confirmationRmiRejectedDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			} else {
				this.setState({
					confirmationRmiRejectedDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'error',
					toasterMessage: (
						<div>
							<div>
								<Translate id='common.errorToSave' /> <br />
								<Translate id='common.folio' />:<br />
							</div>
							<div>
								<ul>
									{selectedSampleRequests.map(({ folio }) => (
										<li key={folio}>{folio}</li>
									))}
								</ul>
							</div>
						</div>
					),
				})
			}
			this.loadMemoDetail()
		})
	}

	/**
	 * Open dialog to confirm rmi activation
	 *
	 * @param {*} item
	 * @memberof
	 */
	onActivateRmiToTheSupplierForModifyClicked() {
		this.setState({
			availableOperationsDialogOpened: false,
			editRmiDialogOpened: true,
		})
	}

	/**
	 * Handles event triggered to edit RMI sample request in order to allow modifing by supplier
	 * @param {*} commnents
	 */
	onActivateRmiToTheSupplierForModify(comments) {
		const selectedSampleRequests = getSelectedSampleRequests(
			this.props.memo,
			this.state.selectedSampleRequestTokens
		)
		const failedSampleRequestFolios = []

		const activateRmis = async (sampleRequests) => {
			for (const sampleRequest of sampleRequests) {
				try {
					await this.props.activateRmiForTheSupplierEdition(sampleRequest.token, comments)
				} catch {
					failedSampleRequestFolios.push(sampleRequest.folio)
				}
			}
		}
		this.setState({ isSaving: true })
		activateRmis(selectedSampleRequests).finally(() => {
			if (failedSampleRequestFolios.length === 0) {
				this.setState({
					editRmiDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			} else {
				this.setState({
					editRmiDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'error',
					toasterMessage: (
						<div>
							<div>
								<Translate id='common.errorToSave' /> <br />
								<Translate id='common.folio' />:<br />
							</div>
							<div>
								<ul>
									{selectedSampleRequests.map(({ folio }) => (
										<li key={folio}>{folio}</li>
									))}
								</ul>
							</div>
						</div>
					),
				})
			}
			this.loadMemoDetail()
		})
	}

	/**
	 * Open dialog to cancel sample request
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onCancelSampleRequestClicked() {
		this.setState({
			availableOperationsDialogOpened: false,
			cancelSampleRequestDialogOpened: true,
		})
	}

	/**
	 * Handles event triggered to cancel sample request
	 */
	onCancelSampleRequest(comments, cancellationReason) {
		const selectedSampleRequests = getSelectedSampleRequests(
			this.props.memo,
			this.state.selectedSampleRequestTokens
		)
		const failedSampleRequestFolios = []

		const sendReminders = async (sampleRequests) => {
			for (const sampleRequest of sampleRequests) {
				try {
					await this.props.cancelSampleRequest(sampleRequest.token, comments, cancellationReason)
				} catch {
					failedSampleRequestFolios.push(sampleRequest.folio)
				}
			}
		}
		this.setState({ isSaving: true })
		sendReminders(selectedSampleRequests).finally(() => {
			if (failedSampleRequestFolios.length === 0) {
				this.setState({
					cancelSampleRequestDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			} else {
				this.setState({
					cancelSampleRequestDialogOpened: false,
					isSaving: false,
					showToaster: true,
					toasterVariant: 'error',
					toasterMessage: (
						<div>
							<div>
								<Translate id='common.errorToSave' /> <br />
								<Translate id='common.folio' />:<br />
							</div>
							<div>
								<ul>
									{selectedSampleRequests.map(({ folio }) => (
										<li key={folio}>{folio}</li>
									))}
								</ul>
							</div>
						</div>
					),
				})
			}
			this.loadMemoDetail()
		})
	}

	/**
	 * Action to update state in order to open of available operations dialog
	 * @memberof
	 */
	setOpenDialog = (opened) => {
		this.setState({
			availableOperationsDialogOpened: opened,
		})
	}

	/**
	 * On update memo
	 */
	onUpdateMemo() {
		this.setState({
			openUpdateMemo: true,
		})
	}

	/**
	 * On finalize memo
	 */
	onFinalizeMemo() {
		this.setState({
			openFinalizeMemo: true,
		})
	}

	/**
	 * Method to change the active tab
	 */
	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 * Finalize un project of an memos
	 */
	onFinalizeMemoFromBackend() {
		let memoToken = this.props.match.params.token
		this.props
			.finalizeMemoFromBackend(memoToken)
			.then(() => {
				this.setState({
					openFinalizeMemo: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			})
			.catch((result) => {
				if (result.status === 400) {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id={result.data.Message} />,
						toasterVariant: 'error',
					})
				} else {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				}
			})
	}

	/**
	 * Update information of memo
	 */
	onUpdateMemoFronBackend() {
		this.props
			.updateDataMemoFromBackend()
			.then(() => {
				this.setState({
					openUpdateMemo: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Handle change of selected sample requests
	 * @param {array} selectedSampleRequestTokens
	 */
	onSelectedSampleRequestChange(selectedSampleRequestTokens) {
		this.setState({ selectedSampleRequestTokens })
	}

	/**
	 * Get the card content
	 *
	 * @memberof Show
	 */
	renderCardContent() {
		if (this.props.isLoadingMemo === true) {
			return <LoadingPage />
		} else if (this.props.memo !== null) {
			const { classes } = this.props
			return (
				<Grid container spacing={24}>
					<Grid item xs={6}>
						<InformationMemoCard {...this.props} />
					</Grid>
					<Grid item xs={6}>
						<InformationProgressCard {...this.props} />
					</Grid>
					<Grid item xs={12}>
						<AppBar position='static' color='default'>
							<Tabs
								indicatorColor='secondary'
								textColor='secondary'
								className={classes.styleTab}
								value={this.state.activeTab}
								onChange={this.onClickToActiveTab}
							>
								<Tab label={<Translate id='common.supplier' />} />
								<Tab label={<Translate id='common.costSummary' />} />
							</Tabs>
						</AppBar>
						{this.state.activeTab === 0 && (
							<Grid item xs={12}>
								<Divider />
								<SuppliersCard
									{...this.props}
									onSelectedSampleRequestChange={this.onSelectedSampleRequestChange}
								/>
							</Grid>
						)}
						{this.state.activeTab === 1 && (
							<Grid item xs={12}>
								<Divider />
								<CostSummary {...this.props} />
							</Grid>
						)}
					</Grid>
				</Grid>
			)
		}
	}

	render() {
		const { classes, memo } = this.props
		const folioMemo = memo ? memo.folio : ''
		const availableOperations = getAvailableOperationsForSelectedSampleRequests(
			memo,
			this.state.selectedSampleRequestTokens
		)

		return (
			<Card>
				{availableOperations.length !== 0 && <FloatingButton open={this.setOpenDialog} />}
				<DialogAvailableOperations
					currentUser={this.props.user}
					userRolesByCompany={this.props.userRolesByCompany}
					options={availableOperations}
					openAvailableOperationsDialog={this.state.availableOperationsDialogOpened}
					setOpenDialog={this.setOpenDialog}
					isValidToCancel={canSampleRequestBeCanceled(this.props.userRolesByCompany)}
					onReminderToSupplier={this.onReminderToSupplierClicked}
					onArriveRegisterDateDialog={this.onArriveRegisterDateClicked}
					onAcceptRMISampleRequestByClient={this.onConfirmRmiAcceptedClicked}
					onRejectRMISampleRequestByClient={this.onConfirmRMIRejectedClicked}
					onRmiActivateToTheSupplierForModify={this.onActivateRmiToTheSupplierForModifyClicked}
					onCancelDocument={this.onCancelSampleRequestClicked}
				/>
				<CardHeader
					avatar={
						<Avatar>
							<MemosIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							{this.props.memo &&
								(!this.props.memo.is_closed || !this.props.memo.status_is_closed) &&
								canBeValidateModuleDevelopment(this.props.userRolesByCompany) && (
									<span>
										<Tooltip title={<Translate id='memos.updateMemos' />}>
											<IconButton
												color='primary'
												data-cy='btn-edit'
												className={classes.edit}
												onClick={this.onUpdateMemo}
											>
												<RefreshIcon />
											</IconButton>
										</Tooltip>

										<Tooltip title={<Translate id='memos.finalizarMemos' />}>
											<IconButton
												color='primary'
												data-cy='btn-edit'
												className={classes.edit}
												onClick={this.onFinalizeMemo}
											>
												<PowerSettingIcon />
											</IconButton>
										</Tooltip>
									</span>
								)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='memos.memo' />: <strong>{folioMemo}</strong>
						</Typography>
					}
				/>

				<CardContent>{this.renderCardContent()}</CardContent>

				{this.state.openUpdateMemo && (
					<AddCommonItem
						title={<Translate id='memos.updateMemos' />}
						message={<Translate id='memos.updateDataMemo' />}
						open={this.state.openUpdateMemo}
						onClose={() => {
							this.setState({ openUpdateMemo: false })
						}}
						onSaveItem={this.onUpdateMemoFronBackend}
						isSaving={this.props.isSaving}
						isMessage={true}
					/>
				)}

				{this.state.openFinalizeMemo && (
					<AddCommonItem
						title={<Translate id='memos.finalizarMemos' />}
						message={<Translate id='memos.finalizarDataMemos' />}
						open={this.state.openFinalizeMemo}
						onClose={() => {
							this.setState({ openFinalizeMemo: false })
						}}
						onSaveItem={this.onFinalizeMemoFromBackend}
						isSaving={this.props.isSaving}
						isMessage={true}
					/>
				)}

				<ArriveRegisterDate
					open={this.state.arriveRegisterDialogOpened}
					numberOfSampleRequestsDisplayed={false}
					onClose={() => {
						this.setState({ arriveRegisterDialogOpened: false })
					}}
					onRegisterDateArrriveByClient={this.onRegisterArrivalDateByClient}
					isSaving={this.state.isSaving}
				/>

				{/** Dialog to send supplier reminder */}
				<AddCommonItem
					title={<Translate id='common.sendReminderToSupplierTitle' />}
					message={<Translate id='common.messageToSupplier' />}
					open={this.state.confirmSupplierReminderDialogOpened}
					onClose={() => {
						this.setState({ confirmSupplierReminderDialogOpened: false })
					}}
					onSaveItem={this.onSendSupplierReminder}
					isSaving={this.state.isSaving}
					isTextArea={true}
					saveButtonTranslate={'common.sendBotton'}
				/>

				{/** Dialog to confirm accepted RMI  */}
				<AddCommonItem
					title={<Translate id='statusDescriptions.rmiAccepted' />}
					message={
						<strong style={{ color: 'green' }}>
							<Translate id='samplesrequest.confirmRmiAccepted' />
						</strong>
					}
					open={this.state.confirmationRmiAcceptedDialogOpened}
					onClose={() => {
						this.setState({ confirmationRmiAcceptedDialogOpened: false })
					}}
					onSaveItem={this.acceptRmiSampleRequestByClient}
					isSaving={this.state.isSaving}
					isMessage={true}
				/>

				{/** Dialog to confirm rejected RMI  */}
				<AddCommonItem
					title={<Translate id='statusDescriptions.rmiRejected' />}
					message={
						<strong style={{ color: 'red' }}>
							<Translate id='samplesrequest.confirmRMIRejected' />
						</strong>
					}
					open={this.state.confirmationRmiRejectedDialogOpened}
					onClose={() => {
						this.setState({ confirmationRmiRejectedDialogOpened: false })
					}}
					onSaveItem={this.rejectRmiSampleRequestByClient}
					isSaving={this.props.isSaving}
					isMessage={true}
				/>

				{/** Dialog to resent rmi to supplier */}
				<AddCommonItem
					title={<Translate id='samplesrequest.sendRMIToSupplierForModify' />}
					message={<Translate id='samplesrequest.commentForModifyTheRMI' />}
					open={this.state.editRmiDialogOpened}
					onClose={() => {
						this.setState({ editRmiDialogOpened: false })
					}}
					onSaveItem={this.onActivateRmiToTheSupplierForModify}
					isSaving={this.state.isSaving}
					isTextArea={true}
				/>

				{/** Dialog to cancel questionnaire */}
				<CancelWorkflowDialog
					open={this.state.cancelSampleRequestDialogOpened}
					onClose={() => {
						this.setState({ cancelSampleRequestDialogOpened: false })
					}}
					onSaveItem={this.onCancelSampleRequest}
					isSaving={this.props.isCancel}
				/>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

/**
 * Information MemoCard
 *
 * @class InformationMemoCard
 * @extends {Component}
 */
class InformationMemoCard extends Component {
	render() {
		return (
			<Paper>
				<List>
					<Divider />
					<ListItem>
						<ListItemText
							primary={
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='memos.project' />
								</Typography>
							}
							secondary={<React.Fragment>{this.props.memo.project_name}</React.Fragment>}
						/>
					</ListItem>
					<Divider />
					<ListItem>
						<ListItemText
							primary={
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='memos.stage' />
								</Typography>
							}
							secondary={<React.Fragment>{this.props.memo.stage}</React.Fragment>}
						/>
					</ListItem>
					<Divider />
					<ListItem>
						<ListItemText
							primary={
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='memos.dateRequiredEngineering' />
								</Typography>
							}
							secondary={<React.Fragment>{this.props.memo.date_required_by_engineer}</React.Fragment>}
						/>
					</ListItem>
				</List>
			</Paper>
		)
	}
}

/**
 * Information MemoCard
 *
 * @class InformationMemoCard
 * @extends {Component}
 */
class InformationProgressCard extends Component {
	render() {
		return (
			<Paper>
				<List>
					<Divider />
					<ListItem>
						<ListItemText
							primary={
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='common.folio' />
								</Typography>
							}
							secondary={<React.Fragment>{this.props.memo.folio}</React.Fragment>}
						/>
					</ListItem>
					<Divider />
					<ListItem>
						<ListItemText
							primary={
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='common.status' />
								</Typography>
							}
							secondary={<React.Fragment>{this.props.memo.status_description}</React.Fragment>}
						/>
					</ListItem>
					<Divider />
					<ListItem>
						<ListItemText
							primary={
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='memos.relationFolio' />
								</Typography>
							}
							secondary={
								<React.Fragment>
									{
										<Link
											to={`/noticechanges/${this.props.memo.related_project_document_base_token}`}
										>
											{this.props.memo.related_project_document_base}
										</Link>
									}
								</React.Fragment>
							}
						/>
					</ListItem>
				</List>
			</Paper>
		)
	}
}

function getAvailableOperationsForSelectedSampleRequests(memo, selectedSampleRequestTokens) {
	if (!selectedSampleRequestTokens || selectedSampleRequestTokens.length === 0) {
		return []
	}
	if (memo) {
		let availableOperations = []
		const selectedSupplier = memo.suppliers.find((s) =>
			s.quotes.some((q) => selectedSampleRequestTokens.includes(q.token))
		)
		if (selectedSupplier) {
			selectedSupplier.quotes.forEach((item) => {
				if (item.type_request === 'SampleRequest' && selectedSampleRequestTokens.includes(item.token)) {
					availableOperations =
						availableOperations.length === 0
							? item.available_operations
							: availableOperations.filter((x) => item.available_operations.includes(x))
				}
			})
		}
		/** 
		 * Code 21 corresponds to cancel option, 
		 * this options must not be enabled in order to prevent mistake on canceling 
		 * due to this operation can't be undo.
		 */
		availableOperations = availableOperations.filter(code => code !== 21)
		return availableOperations
	}
	return []
}

function getSelectedSampleRequests(memo, selectedSampleRequestTokens) {
	if (!selectedSampleRequestTokens || selectedSampleRequestTokens.length === 0) {
		return []
	}
	if (memo) {
		let selectedSampleRequests = []
		const selectedSupplier = memo.suppliers.find((s) =>
			s.quotes.some((q) => selectedSampleRequestTokens.includes(q.token))
		)
		if (selectedSupplier) {
			selectedSampleRequests = selectedSupplier.quotes.filter(
				(item) => item.type_request === 'SampleRequest' && selectedSampleRequestTokens.includes(item.token)
			)
		}
		return selectedSampleRequests
	}
	return []
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let memo = state.memos.get('memoInformation')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		isLoadingMemo: state.memos.get('isLoadingMemo'),
		isSaving: state.memos.get('isSaving'),
		isActive: state.workflow.get('isActive'),
		memo: memo ? memo.toJS() : null,
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadMemo: (token) => {
			return dispatch(loadMemoFromBackEnd(token))
		},
		finalizeMemoFromBackend: (token) => {
			return dispatch(finalizeMemoFromBackend(token))
		},
		updateDataMemoFromBackend: () => {
			return dispatch(updateDataMemoFromBackend())
		},
		reactiveQuoteFromSupplier: (quoteToken, commnents) => {
			return dispatch(reactiveWorkflowDocument(quoteToken, commnents))
		},
		registerSampleArrival(token, arriveDate, numberSamples) {
			return dispatch(registerSampleArrive(token, arriveDate, numberSamples))
		},
		activateRmiForTheSupplierEdition: (token, commnents) => {
			return dispatch(rmiActivateToTheSupplierForModify(token, commnents))
		},
		acceptRmiSampleRequestByClient: (token) => {
			return dispatch(acceptRMISampleRequestByClient(token))
		},
		rejectRmiSampleRequestByClient: (token) => {
			return dispatch(rejectRMISampleRequestByClient(token))
		},
		sendReminderToSupplier: (token, comment) => {
			return dispatch(sendReminderToSupplier(token, comment))
		},
		cancelSampleRequest: (token, comment, cancellationReason) => {
			return dispatch(cancelWorflowFromBackend(token, comment, cancellationReason))
		},
	}
}

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	actionsToolbar: {
		display: 'flex',
	},
	styleTab: {
		backgroundColor: '#fff',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
