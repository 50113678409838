
// import Argentina from '../../resources/images/countries/iconArgentina.png'
// import Colombia from '../../resources/images/countries/iconColombia.png'
// import Ecuador from '../../resources/images/countries/iconEcuador.png'
// import EstadosUnidos from '../../resources/images/countries/iconUSA.png'
// import Mexico from '../../resources/images/countries/iconMexico.png'
import DivisaIcon from '../../resources/images/countries/divisaIcon.png'
// import Peru from '../../resources/images/countries/iconPeru.png'
// import Venezuela from '../../resources/images/countries/iconVenezuela.png'
// import Brasil from '../../resources/images/countries/iconBrazil.png'
// import Chile from '../../resources/images/countries/iconChile.png'
// import Filipinas from '../../resources/images/countries/iconFilipinas.png'
// import Guatemala from '../../resources/images/countries/iconGuatemala.png'
// import Nicaragua from '../../resources/images/countries/iconNicaragua.png'
// import Panama from '../../resources/images/countries/iconPanama.png'
// import Portugal from '../../resources/images/countries/iconPortugal.png'
// import CostaRica from '../../resources/images/countries/iconCostaRica.png'
/**
 * Get the logos by country
 */
export const getLogosByCountry = code => {
    switch (code) {
        // case 'MXN': {
        //     return Mexico 
        // }
        // case 'USD': {
        //     return  EstadosUnidos
        // }
        // case 'COLOMBIA': {
        //     return  Colombia
        // }
        // case 'ARGENTINA': {
        //     return Argentina 
        // }
        // case 'ECUADOR': {
        //     return Ecuador
        // }
        // case 'PERÚ': {
        //     return Peru
        // }
        // case 'VENEZUELA': {
        //     return Venezuela
        // }
        // case 'BRASIL': {
        //     return Brasil
        // }
        // case 'CHILE': {
        //     return Chile
        // }
        // case 'FILIPINAS': {
        //     return Filipinas
        // }
        // case 'GUATEMALA': {
        //     return Guatemala
        // }
        // case 'NICARAGUA': {
        //     return Nicaragua
        // }
        // case 'PANAMA': {
        //     return Panama
        // }
        // case 'PORTUGAL': {
        //     return Portugal
        // }
        // case 'COSTA RICA': {
        //     return CostaRica
        // }
        default:
            return DivisaIcon
    }
}
