/** React imports section */
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

/** Material UI imports section */
import ProspectSupplierIncon from '@material-ui/icons/LocalShipping'
import { Typography, withStyles, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Cancel'

/** Common imports section */
import Toaster from '../../common/Toaster'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import { getTransportTypeOptions } from '../../../store/helpers/TransportTenderHelper'
import SelectorComponent from '../common/SelectorComponent'

/** Store imports section */
import { addTransportType } from '../../../store/transportTender/transportTenderAction'
import { getIsLoadingSavingSelector } from '../../../store/transportTender/transportTenderSelector'

const TransportTypePanel = (props) => {
	const { onClose, classes, transportType, transportTenderdetail } = props

	const dispatch = useDispatch()
	let location = useLocation()
	const transportTypeOptions = transportType.length > 0 ? getTransportTypeOptions(transportType) : []
	const islodigSaving = useSelector((state) => getIsLoadingSavingSelector(state))

	const [transportTypeValue, setTransportType] = useState(null)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: '',
		toasterVariant: '',
	})
	useEffect(() => {
		if (transportTenderdetail.TransportTypeCode)
			setTransportType(transportTypeOptions.find((x) => x.Code === transportTenderdetail.TransportTypeCode))
	}, [dispatch])

	const onSubmit = () => {
		dispatch(
			addTransportType({
				transportTypeId: transportTypeValue.Id,
				transportTenderId: location.pathname.replace('/transportTenderShow/', ''),
			})
		)
			.catch((err) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: <Translate id='carrierTenders.error.errorSavingTransportType' />,
					toasterVariant: 'error',
				})
			})
			.finally(() => {
				onClose()
			})
	}
	const canBeSaved = () => {
		if (transportTypeValue === null) return false
		return true
	}

	return (
		<LateralPanel onClose={onClose}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id='carrierTenders.detail.typeTransport' />}
				onCloseButtonClick={onClose}
				icon={<ProspectSupplierIncon color='secondary' />}
			/>
			<div className={classes.bodyContainer}>
				<Typography variant='subtitle2' noWrap className={classes.typographyContainer}>
					{<Translate id='carrierTenders.detail.typeTransport' />}
				</Typography>

				<SelectorComponent
					title={'Selecciona un tipo de transporte'}
					placeholder={'Selecciona un tipo de transporte'}
					options={transportTypeOptions}
					onChange={(option) => setTransportType(option)}
					value={transportTypeValue}
				/>
				{transportTypeValue && (
					<div className={classes.typeTranportContainer}>
						<div className={classes.displayFlex}>
							<ProspectSupplierIncon className={classes.prospectSupplierInconStyles} />
							<Typography variant='subtitle2' noWrap className={classes.title} color={'#BDBDBD'}>
								{transportTypeValue.Code} | {transportTypeValue.Description}
							</Typography>
						</div>
						<div>
							<CloseIcon
								color='primary'
								className={classes.closeIcon}
								onClick={() => setTransportType(null)}
							/>
						</div>
					</div>
				)}
			</div>
			<Button
				data-tut='reactour-save'
				data-cy='btn-save'
				type='button'
				color='primary'
				variant='outlined'
				className={classes.btnCreate}
				onClick={onSubmit}
				disabled={!canBeSaved() || islodigSaving}
			>
				{<Translate id='carrierTenders.detail.addTransportTypes' />}
			</Button>
			<Toaster
				message={<Translate id={'carrierTenders.error.errorAddingRequest'} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</LateralPanel>
	)
}
const styles = (theme) => ({
	bodyContainer: {
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
	},
	tabContainer: {
		padding: 10,
	},

	typographyContainer: {
		margin: '0px 0px 5px',
	},
	title: {
		marginLeft: '10px',
	},

	typeTranportContainer: {
		height: ' 56px',
		border: '1px solid #BDBDBD',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '10px',
	},
	displayFlex: {
		display: 'flex',
	},
	prospectSupplierInconStyles: {
		marginLeft: '10px',
		color: '#BDBDBD',
	},
	closeIcon: {
		margin: '-20px 10px',
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
})

export default withStyles(styles, { withTheme: true })(TransportTypePanel)
