/**Import react section */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
/**Import material UI Section*/

import { withStyles, Typography, Button } from '@material-ui/core';

import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import { BiddingStatus, ScopeType } from '../../../../store/helpers/AppConstants';
import { percengeFormat } from '../../../../store/helpers/AmountHelper';
import CommentsComponent from '../CommentsComponent';
import {
	COLOR_STATUS_SUCCESS
} from '../../../../store/helpers/StatusColorConstants';

/**Import components section*/
import {
	getResultInform,
	getBiddingResultTotalPercentage,
	onGetHeaderResultGeneralTable,
	onMappedDataResultsGeneralsTable,
	ADDITIONAL_COSTS,
	isCategoryRawMaterials
} from '../../../../store/helpers/BiddingHelper';
import AddicionalCostTable from './AddicionalCostTable';
const ResultTableGeneral = (props) => {
	/**Bools props section */
	const { isOpenLateralMenu, translate } = props;
	/**Functions props section */
	const { onAddWeighingConfigPanel, onUploadAdditionalCostsConfigPanel } = props;
	/**Objects props section */
	const { classes, bidding } = props;

	let results = onMappedDataResultsGeneralsTable(bidding, ScopeType.GENERAL);

	return (
		<div style={{ maxWidth: isOpenLateralMenu ? `calc(100vw - ${315}px)`:`calc(100vw - ${155}px)`, overflowX: 'auto' }}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						{onGetHeaderResultGeneralTable(results.length !== 0 ? results[0] : [], bidding).map((column) => {
							return (
								<TableCell key={uuidv4()} align="center">
									<strong>{column.title}</strong>
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>

				<TableBody>
					{results && results.length !== 0 ? (
						results.map((row) => (
							<TableRow key={uuidv4()}>
								{row.isGroup === true && (
									<TableCell rowSpan={row.totalItems} component="th" scope="row">
										<div className={classes.flex}>
											<strong>{row.group}</strong>
											<div className={classes.flexGrow} />
											{row.group !== ADDITIONAL_COSTS && (
												<Typography color="primary" className={classes.percentage}>
													{getBiddingResultTotalPercentage(results, row.group)}
												</Typography>
											)}
										</div>
									</TableCell>
								)}
								<TableCell align="left">
									{row.group !== ADDITIONAL_COSTS && (
										<div className={classes.flex}>
											<div>{`${row.nombreCriteria} `}</div>
											<div className={classes.flexGrow} />
											<Typography color="primary" className={classes.percentage}>
												{`(${percengeFormat(row.weighing)})`}
											</Typography>
										</div>
									)}
								</TableCell>
								{row.suppliers.map((supplier) => (
									<TableCell key={uuidv4()} align="justify">
										{row.group !== ADDITIONAL_COSTS && (
											<Typography className={classes.noWrapText} variant="caption" color="primary">
												{getResultInform(supplier)}
												{supplier.unit}
											</Typography>
										)}
										{row.group !== ADDITIONAL_COSTS && (
											<Typography variant="caption" align="justify">
												{`${translate("biddingsModule.assingnedPonderation")} ${
													supplier.evaluatedWeighing ? percengeFormat(supplier.evaluatedWeighing) : supplier.evaluatedWeighing
												}`}
											</Typography>
										)}

										{bidding && bidding.Status === BiddingStatus.CLOSED && row.group !== ADDITIONAL_COSTS ? 
											 (
												<Button
													size="small"
													type="button"
													variant="outlined"
													className={
														(supplier.evaluatedWeighing || supplier.evaluatedWeighing ===0)
															? classes.haveEvaluatedWeighing
															: classes.notHaveEvaluatedWeighing
													}
													onClick={() => onAddWeighingConfigPanel(supplier)}
												>
													{translate("biddingsModule.responded")}
												</Button>
											)
										 : row.group !== ADDITIONAL_COSTS ? (
											<Typography className={classes.noWrapText} variant="caption">
												{percengeFormat(supplier.evaluated)}
											</Typography>
										) : (
											<Button
												size="small"
												type="button"
												variant="outlined"
												className={supplier.hasAdditionalCosts ? classes.btnAddHaveAdditionsCost: classes.btnAddAdditionsCost}
												onClick={() => onUploadAdditionalCostsConfigPanel(supplier)}
											>
												{translate("biddingsModule.otherCosts")}
											</Button>
										)}
									</TableCell>
								))}
							</TableRow>
						))
					) : results && results.length === 0 && isCategoryRawMaterials(bidding) ? (
						<AddicionalCostTable 
                            bidding={bidding} 
                            onUploadAdditionalCostsConfigPanel={onUploadAdditionalCostsConfigPanel}
                        />
					) : (
						<CommentsComponent
							title={translate("biddingsModule.information")}
							comments={translate("biddingsModule.emptyResults")}
						/>
					)}
				</TableBody>
			</Table>
		</div>
	);
};

const styles = (theme) => ({
	flex: {
		display: 'flex'
	},
	percentage: {
		fontWeight: 'bold',
		marginLeft: '5px'
	},
	flexGrow: {
		flexGrow: 1
	},
	inputProps: {
		//height: 8,
		fontSize: 12
	},
	haveEvaluatedWeighing: {
		color: COLOR_STATUS_SUCCESS,
		minWidth: '130px'
	},
	notHaveEvaluatedWeighing: {
		minWidth: '130px'
	},
	noWrapText: {
		maxWidth: '100px',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden'
	},
	btnAddAdditionsCost: {
		marginRight: '20px',
		minWidth: '130px'
	},
	btnAddHaveAdditionsCost:{
		color: COLOR_STATUS_SUCCESS, //"white",
		borderColor: COLOR_STATUS_SUCCESS,
		marginRight: '20px',
		minWidth: '130px'
	}
});

ResultTableGeneral.propTypes = {
	/**
	 * Determinate if is loading information from back end
	 * and see view skeleton
	 */
	isLoading: PropTypes.bool.isRequired
};

ResultTableGeneral.defaultProps = {
	isLoading: false
};

export default withRouter(withStyles(styles, { withTheme: true })(ResultTableGeneral));
