import React, { Component } from 'react';
import autoBind from 'auto-bind';

/** Translations imports section */
import { Translate } from "react-localize-redux";
import Select from 'react-select'

/** Material-UI imports section */
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import EthicCodeIcon from '@material-ui/icons/ListAlt';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";

import DeleteIcon from '@material-ui/icons/DeleteOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { GetContactsType } from '../../../store/helpers/SelectOptions';
import SuppliersSelector from '../../common/SupplierSelector';

class BaseInformation extends Component {

    /**
     *Creates an instance of BaseInfoEthicCode.
     * @param {*} props
     * @param {*} context
     * @memberof BaseInfoEthicCode
     */
    constructor(props, context) {
        super(props, context);
        autoBind(this);
        this.state = {
            excludeSuppliers: [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.excludeSuppliers !== this.props.excludeSuppliers) {
            this.setState({
                excludeSuppliers: this.props.excludeSuppliers
            })
        }
    }

    getSupplierInList() {
        if (this.state.excludeSuppliers) {
            return (
                <List>
                    {this.state.excludeSuppliers.map((supplier) => {
                        return (<div key={supplier.name}>
                            <ListItem divider>
                                <ListItemText primary={<Typography variant="caption" gutterBottom>{supplier.name}</Typography>} />
                                <ListItemSecondaryAction>
                                    <IconButton id={supplier.token}
                                        onClick={(event) => { this.props.removeSupplierExclude(event.currentTarget.id) }}
                                    >
                                        <DeleteIcon color='error' />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </div>
                        )
                    })}
                </List>
            )
        } else {
            return null;
        }
    }

    render() {
        const { contactSupplier, classes } = this.props;
        const contactsType = GetContactsType();
        return (
            <Card className={classes.card} style={{ height: '79vh' }} >
                <CardHeader
                    avatar={
                        <Avatar className={classes.userAvatar}>
                            <EthicCodeIcon />
                        </Avatar>
                    }
                    title={<Translate id="contactsupplier.addContactSupplier" />}
                />
                <CardContent style={{ height: '60vh', overflowY: "auto" }}>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="caption" color={'textSecondary'}><Translate id="contactsupplier.typeContact" /></Typography>
                            <Select
                                value={contactsType ? contactsType['contact_type'] : ''}
                                className={classes.documentTypeSelect}
                                options={contactsType}
                                onChange={(event) => this.props.updateProperty("contact_type", event)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth
                                id="name"
                                label={<Translate id="contactsupplier.observationsAddcontact" />}
                                className={classes.textAreaField}
                                value={contactSupplier.name}
                                onChange={(event) => { this.props.updateProperty("name", event.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => { this.props.updateProperty('acceptedBySupplier', event.target.checked) }}
                                        color="primary"
                                        value="status"
                                    />
                                }
                                label={<Translate id="surveys.setToAcceptBySupplier" />}
                            />
                        </Grid>
                        {contactSupplier.contact_type && contactSupplier.contact_type.value === 2 && <Grid item xs={12}>
                            <Typography variant="subtitle2" color={'textSecondary'}>
                                <Translate id={"contactsupplier.supplierToExluded"} />
                            </Typography>
                            <SuppliersSelector
                                className={classes.textField}
                                onSupplierSelected={(supplier) => { this.props.updateExcludeSuppliersList(supplier) }}
                            />
                            <List>
                                {this.getSupplierInList()}
                                {this.state.excludeSuppliers.length === 0 &&
                                    <ListItem divider>
                                        <ListItemText primary={
                                            <Typography variant="subtitle2" gutterBottom>
                                                <Translate id="contactsupplier.noSuppliersToSendContactSupplier" />
                                            </Typography>
                                        } />
                                    </ListItem>
                                }
                            </List>
                        </Grid>}
                    </Grid>
                </CardContent>

                <CardActions className={classes.actions} >
                    <Grid item xs={12}>
                        <Toolbar>
                            <Button variant="outlined" color="secondary" className={classes.button}
                                onClick={this.props.onCreate}
                                disabled={!this.props.canBeSaved || this.props.isSaving}
                            >
                                <Translate id={this.props.isSaving ? "common.saving" : "common.save"} />
                            </Button>
                            <Button variant="outlined" color="primary" className={classes.button}
                                data-cy="btn-close"
                                onClick={() => { this.props.history.push(`/contactsupplier`) }} >
                                <Translate id="common.cancel" />
                            </Button>
                        </Toolbar>
                    </Grid>
                </CardActions>
            </Card>
        );
    }
}

export default BaseInformation;