import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/** Material UI import section */
import { Typography } from '@material-ui/core';

/** Resources import section */
import { applyCurrencyFormat } from '../../../store/helpers/StringHelper';

/**
 * Component to show a cell with text
 */
export const DataGridTextCell = memo((props) => {
	const { text, variant } = props;
	return (
		<Typography variant={variant} style={{ whiteSpace: 'nowrap', overflow: 'hidden', display: 'block' }}>
			{text}
		</Typography>
	);
});

DataGridTextCell.propTypes = {
	text: PropTypes.string.isRequired,
	variant: PropTypes.string.isRequired
};

DataGridTextCell.defaultProps = {
	text: '-',
	variant: 'overline'
};

/**
 * Component to show a cell with currency format
 */
export const DataGridCurrencyCell = memo((props) => {
	const { amount } = props;
	return (
		<Typography
			variant="overline"
			style={{ whiteSpace: 'nowrap', overflow: 'hidden', display: 'block', textAlign: 'right' }}
		>
			{applyCurrencyFormat(amount)}
		</Typography>
	);
});

DataGridCurrencyCell.propTypes = {
	amount: PropTypes.number.isRequired
};

DataGridCurrencyCell.defaultProps = {
	amount: 0
};

/**
 * Component to show a cell with date format
 */
export const DataGridDateCell = memo((props) => {
	const { date, format } = props;
	return (
		<Typography
			variant="overline"
			style={{ whiteSpace: 'nowrap', overflow: 'hidden', display: 'block', minWidth: 100 }}
		>
			{!date || !date.format ? '' : date.format(format)}
		</Typography>
	);
});

DataGridDateCell.propTypes = {
	date: PropTypes.oneOfType([null, PropTypes.instanceOf(moment)]).isRequired,
	format: PropTypes.string
};

DataGridDateCell.defaultProps = {
	date: null,
	format: 'DD/MM/YYYY'
};
