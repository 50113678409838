/**Import react section */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
/**Import material UI Section*/
import {
	withStyles,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
	Tooltip,
	IconButton,
	Grid
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/NoteAdd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
/**Import components section*/
import DataTable from '../../../common/DataTable';
import WarningComponent from '../../../common/WarningComponent';
/**import selector section */
import { descriptionCriteriaType, getSupplierResponseWithFormat } from '../../../../store/helpers/BiddingHelper';
import { percengeFormat } from '../../../../store/helpers/AmountHelper';
import { ScopeType } from '../../../../store/helpers/AppConstants';

/**import selector section */
import { getCriteriaGroup } from '../../../../store/biddings/BindingsSelectors';

const EvaluationsCriteriaResponse = (props) => {
	/**String props section */
	const { classes } = props;
	// /**Bools props section */
	// /**Arrays props section */
	// /**Functions props section */
	const { onAddResponseGralSupplierConfigPanel } = props;

	/**Objects props section */
	const { bidding } = props;
	const evaluationCriteria = getCriteriaGroup(bidding);

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'Scope') {
			return <div className={classes.divWidth}>{item.Scope === ScopeType.GENERAL ? <Translate id="biddingsModule.global" /> : <Translate id="biddingsModule.byItem" />}</div>;
		}
		if (dataSource === 'CriteriaType') {
			return <div className={classes.divWidth}>{descriptionCriteriaType(item.CriteriaType)}</div>;
		}
		if (dataSource === 'Weighing') {
			return <div className={classes.divWidth}>{percengeFormat(item.Weighing)}</div>;
		}
		if (dataSource === 'Name') {
			return (
				<div className={classes.nameWidth}>
					<Typography className={classes.noWrapText}>{item.Name}</Typography>
				</div>
			);
		}
		if (dataSource === 'Notes') {
			return (
				<Tooltip title={item.Notes}>
					<Typography className={classes.noWrapText}>{item.Notes}</Typography>
				</Tooltip>
			);
		}
		if (dataSource === 'Result') {
			return <div className={classes.maxWidth}>{getSupplierResponseWithFormat(item)}</div>;
		}
		if (dataSource === 'Icon') {
			return (
				<Tooltip title={ <Translate id="biddingsModule.addSupplierAnswer" />}>
					<IconButton color="primary" aria-label="More" onClick={() => onAddResponseGralSupplierConfigPanel(item)}>
						<AddIcon />
					</IconButton>
				</Tooltip>
			);
		}
	};

	/**
	 * Render componet
	 */
	return (
		<div className={classes.sectionsContainer}>
			{evaluationCriteria.length !== 0 ? (
				evaluationCriteria.map((group) => {
					return (
						<>
							<ExpansionPanel key={group.Id}>
								<ExpansionPanelSummary
									className={classes.accordionSummary}
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel3bh-content"
									id="panel3bh-header"
								>
									<Typography color="primary">
										{group.Group}:{percengeFormat(group.Weighing)}
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails style={{ display: 'flex' }}>
									<Grid container>
										<Grid item xs={12}>
											<DataTable
												data={group.Items}
												configuration={EvaluationCriteriaTableConfiguration()}
												onRenderCellItem={onRenderCellItem}
											/>
										</Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</>
					);
				})
			) : (
				<WarningComponent title={<Translate id="biddingsModule.information" />} message={<Translate id="biddingsModule.notExistCriteriaGlobals" />} />
			)}
		</div>
	);
};

const EvaluationCriteriaTableConfiguration = () => {
	let columns = [
		{
			header: <Translate id="biddingsModule.table.name" />,
			dataSource: 'Name'
		},
		{
			header: <Translate id="biddingsModule.type" />,
			dataSource: 'Scope'
		},
		{
			header: <Translate id="biddingsModule.services.table.weighing" />,
			dataSource: 'Weighing'
		},
		{
			header: <Translate id="biddingsModule.notes" />,
			dataSource: 'Notes'
		},
		{
			header: <Translate id="biddingsModule.response" />,
			dataSource: 'Result'
		},
		{
			header: '',
			dataSource: 'Icon'
		}
	];
	return { columns };
};

const styles = (theme) => ({
	divWidth: {
		width: '50px'
	},
	nameWidth: {
		width: '200px'
	},
	tab: {
		minHeight: 'auto'
	},
	textTab: {
		fontSize: '0.75rem !important'
	},
	sectionsContainer: {
		marginTop: '4px'
	},
	flexGrow: {
		flexGrow: 1
	},
	accordionSummary: {
		paddingRight: '0px !important',
		display: 'flex',
		backgroundColor: '#f8f9fa'
	},
	accordionDetails: {
		backgroundColor: `${theme.palette.common.white} !important`
	},
	buttonsAcordionSummary: {
		display: 'flex',
		justifyItems: 'flex-end',
		marginRight: '20px'
	},
	noWrapText: {
		maxWidth: '300px',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden'
	}
});

EvaluationsCriteriaResponse.propTypes = {
	/**
	 * Determinate if is loading information from back end
	 * and see view skeleton
	 */
	isLoading: PropTypes.bool.isRequired
};

EvaluationsCriteriaResponse.defaultProps = {
	isLoading: false
};

export default withRouter(withStyles(styles, { withTheme: true })(EvaluationsCriteriaResponse));
