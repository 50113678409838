/** React imports section */
import React from 'react'
import propTypes from 'prop-types'
import moment from 'moment'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import { Typography, CardHeader, Divider, Card, CardContent, Button, withStyles } from '@material-ui/core'
/** Helper imports section */
import { colorFolioGuidelineStatus, descriptionGuidelineStatus } from '../../../../store/helpers/SpecificationsHelper'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { specificationStatus } from '../../../../store/helpers/SpecificationsHelper'
/** Resource imports section */
import imbLogo from '../../../../resources/images/imagotipo.png'

const PackageLayouteHeader = (props) => {
	const {
		folio,
		creation_date,
		created_by,
		document_status,
		release_date,
		name,
		submitAuthorize,
		classes,
		isInternalUser,
		totalActivities,
	} = props

	return (
		<Card>
			<CardHeader
				avatar={<img src={imbLogo} alt='logo' className={classes.logoImg} />}
				title={
					<Typography gutterBottom variant='h6'>
						<strong>
							{<Translate id='specificationsSection.package.title' />} | {folio}
						</strong>
					</Typography>
				}
				subheader={
					<p>
						{<Translate id='specificationsSection.guidelines.creationDate' />}:{' '}
						{moment(creation_date, 'DD/MM/YYYY HH:mm:ss').format('LLLL')}
						<br />
						{<Translate id='specificationsSection.guidelines.createdBy' />}: {created_by}
						<br />
						{<Translate id='specificationsSection.package.specificationName' />}: {name}
					</p>
				}
				action={
					<Typography variant='caption' className={classes.textHeader}>
						<strong
							className={classes.btnStatus}
							style={{ backgroundColor: colorFolioGuidelineStatus(document_status) }}
						>
							{descriptionGuidelineStatus(document_status)}
						</strong>
						<br />
						{<Translate id='specificationsSection.guidelines.releaseDate' />}:{' '}
						{!IsNullOrEmpty(release_date) && moment(release_date, 'DD/MM/YYYY HH:mm:ss').format('LLLL')}
						<br />
						{document_status === specificationStatus.draft && !isInternalUser && totalActivities > 0 && (
							<Button
								type='button'
								color='primary'
								variant='contained'
								className={classes.saveButton}
								onClick={submitAuthorize}
							>
								<Translate id='specificationsSection.package.submitAuthirize' />
							</Button>
						)}
					</Typography>
				}
			/>
			<CardContent className={classes.withoutPaddingTopBottom}>
				<Divider />
			</CardContent>
		</Card>
	)
}

PackageLayouteHeader.propTypes = {
	folio: propTypes.string,
	creation_date: propTypes.string,
	created_by: propTypes.string,
	document_status: propTypes.number,
	release_date: propTypes.string,
	name: propTypes.string,
	submitAuthorize: propTypes.func,
	isInternalUser: propTypes.bool,
	totalActivities: propTypes.number,
}

PackageLayouteHeader.defaultProps = {
	folio: '',
	creation_date: moment().format('DD/MM/YYYY HH:mm:ss').toString(),
	created_by: '',
	document_status: 0,
	release_date: '',
	name: '',
	submitAuthorize: () => console.warn('[submitAuthorize] is not defined!'),
	isInternalUser: false,
	totalActivities: 0,
}

const styles = (theme) => ({
	withoutPaddingTopBottom: {
		paddingTop: '0 !important',
		paddingBottom: '0 !important',
	},
	logoImg: {
		maxHeight: 85,
	},
	textHeader: {
		textAlign: 'end',
	},
	btnStatus: {
		fontSize: '106%',
		whiteSpace: 'normal',
		color: 'white',
		padding: '5px',
	},
	saveButton: {
		bottom: 0,
		margin: 2,
		borderRadius: '15px',
		'&:hover': {
			backgroundColor: 'white',
			color: theme.palette.primary.main,
			border: '1px solid',
		},
	},
})
export default withStyles(styles, { withTheme: true })(PackageLayouteHeader)
