import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import LoadingPage from '../../common/LoadingPage'
import Toaster from '../../common/Toaster'
import FormatViewUser from './FormatViewUser'
import UploadAvatar from './UploadAvatar'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import IconKey from '@material-ui/icons/VpnKey'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import Toolbar from '@material-ui/core/Toolbar'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import { Translate } from 'react-localize-redux'
import Paper from '@material-ui/core/Paper'
import ChangePassword from '../../suppliers/users/ChangePassword'
import { Grid } from '@material-ui/core'

/** Import section actions */
import { IsNullOrEmpty, genereteStringGuid } from '../../../store/helpers/StringHelper'
import {
	loadUser,
	updateUserData,
	updateUserExternalPasswordDataFromBackend,
} from '../../../store/suppliers/ExternalUserActions'
/**
 * Container to show user
 */
class ExternalUserShow extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.uploadLogoFileRef = React.createRef()
		this.state = {
			showToView: true,
			editUser: null,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			showChangeAvatarDialog: false,
			avatarFileBase64: null,
			croppedFileBase64: null,
			editPassword: false,
			editedPasswordUser: {
				actualPassword: '',
				newPassword: '',
				checkPassword: '',
			},
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let userToken = this.props.match.params.token
		this.props.loadUser(userToken)
	}

	/**
	 * Set view to edit information user
	 */
	setViewInEditMode(activeShow) {
		this.setState({
			showToView: activeShow,
			editUser: { ...this.props.externalUser },
		})
	}

	/**
	 * Update the property of user
	 *
	 * @param {*} user
	 * @param {*} value
	 * @memberof View
	 */
	updateProperty(property, value) {
		let updatedUser = { ...this.state.editUser }
		updatedUser[property] = value
		this.setState({
			editUser: updatedUser,
		})
	}

	/**
	 * On edit Pasword
	 */
	openEditPassword() {
		this.setState({
			editPassword: true,
		})
	}

	/**
	 * Update the property of the user's password
	 * @param {*} property
	 * @param {*} value
	 */
	updateUserPasswordProperty(property, value) {
		let updatePasswordUser = { ...this.state.editedPasswordUser }
		updatePasswordUser[property] = value.replace(/\s/g, '')
		this.setState({
			editedPasswordUser: updatePasswordUser,
		})
	}

	/**
	 * Executevalidations user data validation
	 */
	validateData() {
		const { editUser } = this.state
		let validations = []
		if (editUser) {
			if (IsNullOrEmpty(editUser.full_name)) {
				validations.push({
					key: 'full_name',
				})
			}
			if (IsNullOrEmpty(editUser.email)) {
				validations.push({
					key: 'email',
				})
			}
			let regexEmail =
				/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			if (!regexEmail.test(editUser.email)) {
				validations.push({
					key: 'email',
				})
			}
		}
		return validations
	}

	/**
	 * Action to can be update information serie
	 */
	canBeSaved() {
		let validations = []
		validations = this.validateData()
		if (this.props.isSavingUser) return false
		else return validations.length === 0
	}

	/**
	 *Determine if the save action can be executed
	 *
	 * @returns
	 * @memberof ExternalUserShow
	 */
	canBeSavedPassword() {
		if (this.props.isSavingPassword) return false
		let passwordValidations = []
		passwordValidations = this.validateUserPasswordData()
		return passwordValidations.length === 0
	}

	/**
	 *Change user external password
	 *
	 * @memberof ExternalUserShow
	 */
	onUpdatePasswordUserExternal() {
		let userToken = this.props.match.params.token
		this.props
			.updateUserExternalPasswordDataFromBackend(this.state.editedPasswordUser, userToken)
			.then(() => {
				this.setState({
					editPassword: false,
					showToaster: true,
					toasterMessage: <Translate id='users.userPasswordAddedCorrectly' />,
					toasterVariant: 'success',
					editedPasswordUser: {
						actualPassword: '',
						newPassword: '',
						checkPassword: '',
					},
				})
			})
			.catch(() => {
				this.setState({
					editPassword: true,
					showToaster: true,
					toasterMessage: <Translate id='users.errorToAddUserPassword' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Execute User´s external password data validation
	 */
	validateUserPasswordData() {
		const { editedPasswordUser } = this.state
		let passwordValidations = []
		if (editedPasswordUser) {
			if (IsNullOrEmpty(editedPasswordUser.actualPassword)) {
				passwordValidations.push({
					key: 'actualPassword',
				})
			}

			let regexNewPassword =
				/^(((?=.*[a-z])(?=.*[A-Z]))((?=.*[a-z])(?=.*[0-9]))((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/
			if (!regexNewPassword.test(editedPasswordUser.newPassword)) {
				passwordValidations.push({
					key: 'newPassword',
				})
			}

			if (IsNullOrEmpty(editedPasswordUser.checkPassword)) {
				passwordValidations.push({
					key: 'checkPassword',
				})
			}

			if (editedPasswordUser.newPassword !== editedPasswordUser.checkPassword) {
				passwordValidations.push({
					key: 'checkPassword',
				})
			}

			if (editedPasswordUser.actualPassword === editedPasswordUser.newPassword) {
				passwordValidations.push({
					key: 'newPassword',
				})
			}
		}
		return passwordValidations
	}

	/**
	 * Action to save user
	 */
	onSaveUser() {
		this.props
			.updateUserData(this.state.editUser)
			.then(() => {
				this.setState({
					showToView: true,
					showToaster: true,
					toasterMessage: <Translate id='common.saveCorrectly' />,
					toasterVariant: 'success',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Upload the log file
	 *
	 * @memberof UserProfileHeader
	 */
	uploadLogoFile(file) {
		let self = this
		let fileUpload = file.target.files[0]
		/// Determine if the image to add not is undefined or null
		if (fileUpload !== undefined && fileUpload !== null) {
			var reader = new FileReader()
			reader.onload = function () {
				self.onSelecteFile(reader.result)
			}
			reader.onerror = function (error) {
				console.log('Error: ', error)
			}
			reader.readAsDataURL(fileUpload)
			self.uploadLogoFileRef.current.value = ''
		}
	}

	/**
	 * On select avatar user
	 *
	 * @param {*} fileBase64
	 * @memberof Profile
	 */
	onSelecteFile(fileBase64) {
		this.setState({
			avatarFileBase64: fileBase64,
			croppedFileBase64: fileBase64,
			showChangeAvatarDialog: true,
		})
	}

	/**
	 * Render show
	 */
	render() {
		const { classes } = this.props
		if (this.props.isLoadingUser === true) {
			return <LoadingPage />
		}
		if (this.props.externalUser === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let externalUser = this.props.externalUser
			let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${externalUser.avatar_url}`
			let logoUrl = urlImage + '?token=' + genereteStringGuid()
			return (
				<Paper>
					<Toolbar>
						{externalUser.avatar_url && (
							<Grid item xs={2}>
								<Avatar
									alt='Remy Sharp'
									src={logoUrl}
									className={classes.avatar}
									onClick={() => {
										this.uploadLogoFileRef.current.click()
									}}
								/>
								<input
									className={classes.logoFileInput}
									type='file'
									accept='image/*'
									ref={this.uploadLogoFileRef}
									multiple={false}
									onChange={this.uploadLogoFile}
								/>
							</Grid>
						)}

						{!externalUser.avatar_url && (
							<Grid item xs={1}>
								<IconButton
									alt='Remy Sharp'
									className={classes.avatar}
									onClick={() => {
										this.uploadLogoFileRef.current.click()
									}}
								>
									A
								</IconButton>
								<input
									className={classes.logoFileInput}
									type='file'
									accept='image/*'
									ref={this.uploadLogoFileRef}
									multiple={false}
									onChange={this.uploadLogoFile}
								/>
							</Grid>
						)}

						<Typography variant='h5'>{<Translate id='users.informationUser' />}</Typography>

						<div className={classes.grow}></div>

						<Tooltip title='Editar'>
							<IconButton
								data-cy='btn-edit'
								className={classes.edit}
								onClick={() => this.setViewInEditMode(false)}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>

						<Tooltip title='Editar contraseña'>
							<IconButton
								data-cy='btn-edit'
								className={classes.edit}
								onClick={() => this.openEditPassword(false)}
							>
								<IconKey />
							</IconButton>
						</Tooltip>
					</Toolbar>

					{this.state.showToView && (
						<FormatViewUser
							user={externalUser}
							setViewInEditMode={this.setViewInEditMode}
							showToView={this.state.showToView}
							editUser={true}
							errors={this.validateData()}
						/>
					)}

					{!this.state.showToView && (
						<FormatViewUser
							user={this.state.editUser}
							setViewInEditMode={this.setViewInEditMode}
							showToView={this.state.showToView}
							editUser={true}
							updateProperty={this.updateProperty}
							errors={this.validateData()}
							canBeSaved={this.canBeSaved()}
							onSave={this.onSaveUser}
						/>
					)}

					<input
						className={classes.logoFileInput}
						type='file'
						accept='image/*'
						ref={this.uploadLogoFileRef}
						multiple={false}
						onChange={this.uploadLogoFile}
					/>

					{this.state.showChangeAvatarDialog ? (
						<UploadAvatar
							open={this.state.showChangeAvatarDialog}
							fileBase64={this.state.avatarFileBase64}
							onClose={() => {
								this.setState({ showChangeAvatarDialog: false })
							}}
						/>
					) : null}

					<Toaster
						message={this.state.toasterMessage}
						open={this.state.showToaster}
						variant={this.state.toasterVariant}
						onClose={() => {
							this.setState({ showToaster: false })
						}}
					/>
					<ChangePassword
						title={<Translate id='users.changePassword' />}
						externalUser={this.state.editedPasswordUser}
						updateUserPasswordProperty={this.updateUserPasswordProperty}
						open={this.state.editPassword}
						onClose={() => {
							this.setState({
								editPassword: false,
								editedPasswordUser: { actualPassword: '', newPassword: '', checkPassword: '' },
							})
						}}
						error={this.validateUserPasswordData()}
						canBeSaved={this.canBeSavedPassword()}
						onUpdatePasswordUserExternal={this.onUpdatePasswordUserExternal}
						isSavingPassword={this.props.isSavingPassword}
					/>
				</Paper>
			)
		}
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		externalUser: state.externalUsers.get('externalUser') ? state.externalUsers.get('externalUser').toJS() : null,
		isLoadingUser: state.externalUsers.get('isLoadingUser'),
		isSavingUser: state.externalUsers.get('isSavingUser'),
		isSavingPassword: state.externalUsers.get('isSavingPassword'),
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadUser: (token) => {
			return dispatch(loadUser(token))
		},
		updateUserData: (user) => {
			return dispatch(updateUserData(user))
		},
		updateUserExternalPasswordDataFromBackend: (editPasswordUser, userToken) => {
			return dispatch(updateUserExternalPasswordDataFromBackend(editPasswordUser, userToken))
		},
	}
}

const viewStyles = (theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: '80px',
		paddingRight: '80px',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 440,
		marginTop: 10,
		marginBottom: 10,
	},
	dense: {
		marginTop: 19,
	},
	menu: {
		width: 200,
	},
	footerForm: {
		display: 'flex',
		paddingRight: '40px',
	},
	logoFileInput: {
		display: 'none',
	},
	grow: {
		flexGrow: 1,
	},
	toolBar: {
		backgroundColor: 'red',
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		marginLeft: 10,
		margin: 10,
		width: 80,
		height: 80,
	},
	edit: {
		margin: 10,
		backgroundColor: theme.palette.text.hint,
	},
})

export default withauthenticatedLayout(
	withRouter(
		withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ExternalUserShow))
	)
)
