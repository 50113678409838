/**Import react section */
import React from "react";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { Typography, Grid, TextField, IconButton, withStyles } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import FileIcon from '@material-ui/icons/AttachFile';

/** Import component section */
import DataTable from '../../../common/DataTable';
import { BACKGROUND_COLOR_GRAY, COLOR_STATUS_DANGER, COLOR_STATUS_WARNING, COLOR_STATUS_INFO } from '../../../../store/helpers/StatusColorConstants';
import { descriptionSectionType, descriptionItemType, actionPlantStatus } from '../../../../store/helpers/IndirectQualificationsHelpers';
import { IndirectQualificationsSupplierStatus } from '../../../../store/helpers/AppConstants'
/**import helpers section */
/**Custom hooks index */
import { useIndirectQualificationCustomHookShow } from '../../customHooks/UseCustomHookShow'
const ActionPlantTable = (props) => {
    let ratingId = props.match.params.token;
    let supplierId = props.match.params.supplierId;

    /**String props section */
    //const { } = props
    /**Bools props section */
    /**Arrays props section */
    const { items } = props
    /**Functions props section */
    const { onLateralPanelConfig } = props
    /**Objects props section */
    const { classes, title, supplier } = props
    /**Render hooks section */
    /**Redux store information section*/
    const { user, onUpdateActionPlanFromBackend } = useIndirectQualificationCustomHookShow()

    let rating = (supplier ? (supplier.Rating ? supplier.Rating : null) : null)
    let isUserCreator = (rating && (user && (user.profile.username === rating.CreatedBy)))

    /**
     * On render cell item custom
     * @param {*} dataSource 
     * @param {*} item 
     */
    const onRenderCellItem = (dataSource, item) => {
        if (dataSource === "Status") {
            return <Typography className={classes.descriptionName}>{actionPlantStatus(item.Status)}</Typography>
        }
        if (dataSource === "Name") {
            return <Typography className={classes.descriptionName}>{item.Item.Name}</Typography>
        }
        if (dataSource === "SectionType") {
            return <Typography className={classes.descriptionName}>{descriptionSectionType(item.Item.SectionType)}</Typography>
        }
        if (dataSource === "ItemType") {
            return <Typography className={classes.descriptionName}>{descriptionItemType(item.Item.ItemType)}</Typography>
        }
        if (dataSource === "Scope") {
            return <Typography className={classes.descriptionName} align="center">{item.Item.Scope}</Typography>
        }
        if (dataSource === "Recommendations") {
            return <div className={classes.textField}>
                <TextField
                    fullWidth
                    defaultValue={item.Recommendations}
                    disabled={!(isUserCreator && supplier.Status === IndirectQualificationsSupplierStatus.GENERATE_ACTION_PLANS)}
                    multiline
                    rowsMax={3}
                    onBlur={(event) => onUpdateActionPlanFromBackend(ratingId, supplierId, item.Id, "Recommendations", event.target.value, item.Id, "ActionPlans")}
                    InputProps={{ classes: { input: classes.inputProps } }}
                />
            </div>
        }
        if (dataSource === "Options") {
            if (supplier && supplier.Status !== IndirectQualificationsSupplierStatus.DRAFT) {
                return (<IconButton size="small" onClick={() => { onLateralPanelConfig("ShowEditActionPlant", item) }}>
                   <FileIcon  style={{color: item.Attachments.length === 0 ? COLOR_STATUS_INFO: COLOR_STATUS_WARNING}} />
                </IconButton>)
            }
            else {
                return ("")
            }
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} className={props.backgroundColor}>
                <Typography className={classes.itemTitle}>{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    data={items ? items : []}
                    configuration={TableConfiguration(supplier && supplier.Status !== IndirectQualificationsSupplierStatus.DRAFT)}
                    onRenderCellItem={onRenderCellItem}
                />
            </Grid>
        </Grid>
    )
}

function TableConfiguration(isDisaled) {
    let columns = [
        {
            header: '',
            dataSource: 'status_indicator',
        },
        {
            header: <Translate id="indirectqualifications.actionPlans.status" />,
            dataSource: 'Status',
        },
        {
            header: <Translate id="indirectqualifications.actionPlans.name" />,
            dataSource: 'Name',
        },
        {
            header: <Translate id="indirectqualifications.actionPlans.sectionType" />,
            dataSource: 'SectionType',
        },
        {
            header: <Translate id="indirectqualifications.actionPlans.itemType" />,
            dataSource: 'ItemType',
        },
        {
            header: <Translate id="indirectqualifications.actionPlans.scope" />,
            dataSource: 'Scope',
        },
        {
            header: <Translate id="indirectqualifications.actionPlans.recomendations" />,
            dataSource: 'Recommendations',
        },


    ]
    if (isDisaled) {
        columns.push({
            header: <Translate id="sustainability.uploadEvidence" />,
            dataSource: 'Options',
        })
    }
    return ({ columns });
}

const styles = (theme) => ({
    papper: {
        marginTop: "10px"
    },
    root: {
        maxHeight: `calc(100vh - ${265}px)`,
        minHeight: `calc(100vh - ${265}px)`,
        overflowY: "auto",
        padding: "10px",
        overflowX: "auto"
    },
    critical: {
        backgroundColor: COLOR_STATUS_DANGER
    },
    btnAdd: {
        backgroundColor: BACKGROUND_COLOR_GRAY,
        display: "flex",
        justifyContent: "flex-end"
    },
    itemTitle: {
        marginTop: "3px",
        marginLeft: "15px",
        //fontWeight:"bold",
        color: "white"
    },
    descriptionName: {
        maxWidth: "200px",
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    textField: {
        width: "230px",
    },
    inputProps:{
        height: 6,
        fontSize: 12
    }

});

export default withRouter(
    withStyles(styles, { withTheme: true })(ActionPlantTable)
);
