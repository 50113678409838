///Import react section
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch, } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import ShowDesktopViewExternalUser from './components/ShowDesktopViewExternalUser'
import ShowDesktopViewInternalUser from './components/ShowDesktopViewInternalUser'
import ShowEdit from './ShowEdit'
import Toaster from '../common/Toaster';

import {
    loadBiddingFromBackEnd,
    deletedSupplierBiddingFromBackEnd,
    removeEvaluationCriteria,
    removeServiceOrMaterial,
    changeStatusBiddingFromBackEnd,
    changeStatusSupplierFromBackEnd,
    isPublicationEnabled,
    addResponseSupplier,
    loadMeasurementUnitsFromBackEnd,
    isSentProposalEnabled,
    addEvaluationResponsibleFromBackend,
    onAddQuestionFromBackEnd,
    onAddAnswerFromBackEnd,
    loadDivisas,
    addDivisaInBiddingFromBackEnd,
    addDivisaInBiddingResultFromBackEnd,
    getExchangeRatesInBiddingFromBackEnd,
    loadIncoterms,
    addIncotermInProposalFromBackEnd,
    downloadMaterialsReport,
    downloadResultBiddingReport,
    isCalculateEnabled,
    UPDATE_PUBLISH_FLAG_ENABLED,
    addWinnerInItemFromBackend,
    isPublicationResultsEnabled,
    setMaterialStatusBySupplierInternal,
    updateContactEmailFromBackEnd
} from '../../store/biddings/BiddingsActions'
/**Import actions section */
import { converListToArray } from '../../store/helpers/ListHelper'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper';
import { canRefreshScreenToPublishBidding } from '../../store/helpers/BiddingHelper';
import { BiddingStatus } from '../../store/helpers/AppConstants'
const Show = (props) => {
    /**Render hooks section */
    const dispatch = useDispatch()

    /**Redux store information section*/
    const bidding = useSelector((state) => state.biddings.get('bidding') ? state.biddings.get('bidding').toJS() : null)
    const isLoading = useSelector((state) => state.biddings.get('isLoading'))
    const identifier = useSelector((state) => state.biddings.get('identifier'))
    const isSaving = useSelector((state) => state.biddings.get('isSaving'))
    const canBePublicationEnabled = useSelector((state) => state.biddings.get('isPublicationEnabled'))
    const canBePublicationResultsEnabled = useSelector((state) => state.biddings.get('isPublicationResultsEnabled'))
    const userProfile = useSelector((state) => state.oidc.user ? state.oidc.user.profile : { name: "", email: "", user_name: "", user_type: "" })
    const userProfileLogged = useSelector((state) => state.oidc.user)
    const usersByRole = useSelector((state) => state.internalUsers.get('usersByRole') ? converListToArray(state.internalUsers.get('usersByRole')) : [])
    const divisas = useSelector((state) => state.biddings.get('divisas') ? converListToArray(state.biddings.get('divisas')) : [])
    const incoterms = useSelector((state) => state.biddings.get('incoterms') ? converListToArray(state.biddings.get('incoterms')) : [])
    const userRolesByCompany = useSelector((state) => state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : [])
    const canBeSentProposalEnabled = useSelector((state) => state.biddings.get('isSentProposalEnabled'))
    const canBeCalculateEnabled = useSelector((state) => state.biddings.get('isCalculateEnabled'))
    const isOpenLateralMenu = useSelector((state) => state.profile.get('isOpenLateralMenu'))
    const translate = useSelector((state) => getTranslate(state.localize))

    /**Store local this component */
    const [editBiddingConfigPanel, setEditBiddingConfigPanel] = useState({ opened: false, bidding: null })
    const [editSupplierConfigPanel, setEditSuppliersConfigPanel] = useState({ opened: false })
    const [createServicesConfigPanel, setCreateServicesConfigPanel] = useState({ opened: false })
    const [createEvaluationCriteriaConfigPanel, setCreateEvaluationCriteriaConfigPanel] = useState({ opened: false, evaluationCriteria: null })
    const [editEvaluationCriteriaConfigPanel, setEditEvaluationCriteriaConfigPanel] = useState({ opened: false, evaluationCriteria: null })
    const [uploadAttachmentConfigPanel, setUploadAttachmentConfigPanel] = useState({ opened: false, item: null })
    const [uploadMaterialsPricesConfigPanel, setUploadMaterialsPricesAttachmentConfigPanel] = useState({ opened: false })
    const [editEvaluationCriteriaGroupConfigPanel, setEditEvaluationCriteriaGroupConfigPanel] = useState({ opened: false, evaluationCriteria: null })
    const [confirmPasswordConfigModal, setConfirmPasswordConfigModal] = useState({ opened: false, item: null, identifier: "" })
    const [addWeighingConfigPanel, setAddWeighingConfigPanel] = useState({ opened: false, item: null })
    const [addExchangeRatesConfigPanel, setAddExchangeRatesConfigPanel] = useState({ opened: false })
    const [addResponseItemSupplierConfigPanel, setAddResponseItemConfigPanel] = useState({ opened: false, item: null })
    const [addResponseGralSupplierConfigPanel, setAddResponseGralConfigPanel] = useState({ opened: false, item: null })
    const [addCommentsSupplierConfigPanel, setAddCommentsSupplierConfigPanel] = useState({ opened: false })
    const [addWinnerConfigPanel, setAddWinnerConfigPanel] = useState({ opened: false, item: null })
    const [uploadAdditionalCostsConfigPanel, setUploadAdditionalCostsConfigPanel] = useState({ opened: false, supplier: null })
    const [sendEmailConfigPanel, setSendEmailConfigPanel] = useState({ opened: false, supplier: null })

    const [toasterMessageConfig, setToasterMessageConfig] = useState({
        showToaster: false,
        toasterMessage: null,
        toasterVariant: "",
        name: ""
    })
    /**Use effect section */
    useEffect(() => {
        dispatch(loadMeasurementUnitsFromBackEnd())
        dispatch(loadDivisas())
        dispatch(loadIncoterms())
        dispatch(loadBiddingFromBackEnd(props.match.params.token))
            .then((bidding) => {
                if (bidding && bidding.Status === BiddingStatus.DRAFT) {
                    dispatch(isPublicationEnabled(props.match.params.token))
                }
                dispatch(isSentProposalEnabled(props.match.params.token))
                dispatch(getExchangeRatesInBiddingFromBackEnd(props.match.params.token))
                if (bidding && bidding.Status === BiddingStatus.CLOSED) {
                    dispatch(isCalculateEnabled(props.match.params.token))
                }
                if (bidding && bidding.Status === BiddingStatus.RESOLVED) {
                    dispatch(isPublicationResultsEnabled(props.match.params.token))
                }
            })
    }, [dispatch, props.match.params.token])

    const onUploadAdditionalCostsConfigPanel = (supplier) => {
        setUploadAdditionalCostsConfigPanel({ opened: !uploadAdditionalCostsConfigPanel.opened, supplier: supplier });
    }

    const onSendEmailConfigPanel = (supplier) => {
        setSendEmailConfigPanel({ opened: !sendEmailConfigPanel.opened, supplier: supplier });
    }

    const onAddCommentsSupplierConfigPanel = (supplier) => {
        setAddCommentsSupplierConfigPanel({ opened: !addCommentsSupplierConfigPanel.opened, supplier: supplier });
    }

    const onAddResponseItemSupplierConfigPanel = (item) => {
        setAddResponseItemConfigPanel({ opened: !addResponseItemSupplierConfigPanel.opened, item: item });
    }
    const onAddResponseGralSupplierConfigPanel = (item) => {
        setAddResponseGralConfigPanel({ opened: !addResponseGralSupplierConfigPanel.opened, item: item });
    }
    const onOpenCloseExchangeRatesLateralPanel = () => {
        if (bidding.ExchangeRates && bidding.ExchangeRates.length === 0) {
            onConfirmPassword([], UPDATE_PUBLISH_FLAG_ENABLED)
        } else {
            setAddExchangeRatesConfigPanel({ opened: !addExchangeRatesConfigPanel.opened });
        }
    }

    const onConfirmPassword = (item, identifier) => {
        setConfirmPasswordConfigModal({ opened: !confirmPasswordConfigModal.opened, item: item, identifier: identifier });
    }

    const onOpenCloseEditLateralPanel = () => {
        setEditBiddingConfigPanel({ opened: !editBiddingConfigPanel.opened });
    }

    const onOpenCloseEditSuppliersLateralPanel = () => {
        setEditSuppliersConfigPanel({ opened: !editSupplierConfigPanel.opened });
    }

    const onOpenCloseCreateServicesLateralPanel = () => {
        setCreateServicesConfigPanel({ opened: !createServicesConfigPanel.opened });
    }

    const onAddWeighingConfigPanel = (item) => {
        setAddWeighingConfigPanel({ opened: !addWeighingConfigPanel.opened, item: item });
    }

    const onCreateEvaluationCriteriaConfigPanel = (event, evaluationCriteria) => {
        if (event)
            event.stopPropagation();
        setCreateEvaluationCriteriaConfigPanel({ opened: !createEvaluationCriteriaConfigPanel.opened, evaluationCriteria: evaluationCriteria });
    }

    const onEditEvaluationCriteriaConfigPanel = (evaluationCriteria) => {
        setEditEvaluationCriteriaConfigPanel({ opened: !editEvaluationCriteriaConfigPanel.opened, evaluationCriteria: evaluationCriteria });
    }
    const onEditEvaluationCriteriaGroupConfigPanel = (event, evaluationCriteria) => {
        if (event)
            event.stopPropagation()
        setEditEvaluationCriteriaGroupConfigPanel({ opened: !editEvaluationCriteriaGroupConfigPanel.opened, evaluationCriteria: evaluationCriteria });
    }

    const onOpenCloseUploadAttachmentsLateralPanel = () => {
        setUploadAttachmentConfigPanel({ opened: !uploadAttachmentConfigPanel.opened });
    }

    const onUploadMaterialsPricesConfigPanelLateralPanel = () => {
        setUploadMaterialsPricesAttachmentConfigPanel({ opened: !uploadMaterialsPricesConfigPanel.opened });
    }

    const onAddWinnerConfigPanel = (item) => {
        setAddWinnerConfigPanel({ opened: !addWinnerConfigPanel.opened, item: item });
    }

    const onDeletedSupplierBiddingFromBackEnd = (supplier,) => {
        return dispatch(deletedSupplierBiddingFromBackEnd(supplier))
            .then(() => {
                if (canRefreshScreenToPublishBidding(bidding)) {
                    dispatch(isPublicationEnabled(bidding ? bidding.Id : ""))
                }
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.removeCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onDeletedEvaluationFromBackEnd = (event, criteriaId) => {
        if (event)
            event.stopPropagation();
        return dispatch(removeEvaluationCriteria(criteriaId))
            .then(() => {
                if (canRefreshScreenToPublishBidding(bidding)) {
                    dispatch(isPublicationEnabled(bidding ? bidding.Id : ""))
                }
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.removeCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }


    const onDeleteServiceOrMaterial = (event, itemId) => {
        if (event)
            event.stopPropagation();
        return dispatch(removeServiceOrMaterial(itemId))
            .then(() => {
                if (canRefreshScreenToPublishBidding(bidding)) {
                    dispatch(isPublicationEnabled(bidding ? bidding.Id : ""))
                }
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.removeCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onChangeStatusBiddingFromBackEnd = (status, biddingId) => {
        return dispatch(changeStatusBiddingFromBackEnd(status, biddingId))
            .then(() => {
                return dispatch(loadBiddingFromBackEnd(props.match.params.token))
                    .then((_) => {
                        dispatch(getExchangeRatesInBiddingFromBackEnd(props.match.params.token))
                        setToasterMessageConfig({
                            showToaster: true,
                            toasterVariant: "success",
                            toasterMessage: "common.saveCorrectly",
                        })
                    })

            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onUpdateContactEmailFromBackEnd = (itemModel) => {
        return dispatch(updateContactEmailFromBackEnd(itemModel))
            .then(() => {
                return dispatch(loadBiddingFromBackEnd(props.match.params.token))
                    .then((_) => {
                        dispatch(getExchangeRatesInBiddingFromBackEnd(props.match.params.token))
                        setToasterMessageConfig({
                            showToaster: true,
                            toasterVariant: "success",
                            toasterMessage: "common.saveCorrectly",
                        })
                    })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onChangeStatusSupplierFromBackEnd = (status, biddingId, comments, supplierId = "") => {
        return dispatch(changeStatusSupplierFromBackEnd(status, biddingId, comments, supplierId))
            .then(() => {
                dispatch(loadBiddingFromBackEnd(props.match.params.token))
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.saveCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onAddResponseSupplier = (itemId, id, suppliersResponse, isGeneralResponse, comments) => {
        return dispatch(addResponseSupplier(itemId, id, suppliersResponse, isGeneralResponse, comments))
            .then(() => {
                dispatch(isSentProposalEnabled(props.match.params.token))
                if (isGeneralResponse) {
                    onAddResponseGralSupplierConfigPanel()
                }
            })
            .catch(() => {
            })
    }

    const onAddEvaluationResponsible = (supplier, responsibleResponse, isGeneralResponse) => {
        return dispatch(addEvaluationResponsibleFromBackend(supplier, responsibleResponse, isGeneralResponse))
            .then(() => {
                dispatch(isCalculateEnabled(props.match.params.token))
                if (isGeneralResponse) {
                    onAddWeighingConfigPanel()
                }
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onAddQuestion = (questionModel) => {
        questionModel.Id = props.match.params.token
        return dispatch(onAddQuestionFromBackEnd(questionModel))
            .then(() => { })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onAddAnswer = (questionModel) => {
        return dispatch(onAddAnswerFromBackEnd(questionModel))
            .then(() => { })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onAddDivisaInBiddingFromBackEnd = (divisa) => {
        return dispatch(addDivisaInBiddingFromBackEnd(props.match.params.token, divisa))
            .then(() => {
                dispatch(isSentProposalEnabled(props.match.params.token))
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onAddDivisaInBiddingsResultsFromBackEnd = (divisa) => {
        return dispatch(addDivisaInBiddingResultFromBackEnd(props.match.params.token, divisa))
            .then(() => { })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onAddIncotermFromBackEnd = (incotermModel) => {
        return dispatch(addIncotermInProposalFromBackEnd(props.match.params.token, incotermModel))
            .then(() => { })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onDownloadMaterialsReport = () => {
        return dispatch(downloadMaterialsReport(props.match.params.token))
            .then(() => { })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onDownloadBiddingResultsReport = (resultsGeneral, resultsItem, headersTable) => {
        return dispatch(downloadResultBiddingReport(resultsGeneral, resultsItem, headersTable, bidding))
            .then(() => { })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.error",
                    toasterVariant: "error",
                })
            })
    }

    const onAddWinnerInItemFromBackend = (item, isWinner) => {
        return dispatch(addWinnerInItemFromBackend(item, isWinner))
            .then(() => {
                dispatch(isPublicationResultsEnabled(props.match.params.token))
                setToasterMessageConfig({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                })
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    const onSetMaterialStatusBySupplierInternal = (item, status) => {
        return dispatch(setMaterialStatusBySupplierInternal(item, status))
            .then(() => {
            })
            .catch(() => {
                setToasterMessageConfig({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                })
            })
    }

    return (
        <>
            {
                IsCurrentActiveDirectoryUser(userProfileLogged) ?
                    <ShowDesktopViewInternalUser
                        identifier={identifier}
                        /**Objects */
                        bidding={bidding}
                        userProfile={userProfile}
                        userProfileLogged={userProfileLogged}
                        /**Bools */
                        isLoading={isLoading}
                        isSaving={isSaving}
                        canBePublicationEnabled={canBePublicationEnabled}
                        canBeSentProposalEnabled={canBeSentProposalEnabled}
                        canBePublicationResultsEnabled={canBePublicationResultsEnabled}
                        isOpenLateralMenu={isOpenLateralMenu}
                        /**Arrays */
                        userRolesByCompany={userRolesByCompany}
                        internalUsers={usersByRole}
                        divisas={divisas}
                        incoterms={incoterms}
                        /**Functions */
                        onOpenCloseEditLateralPanel={onOpenCloseEditLateralPanel}
                        translate={translate}
                        onOpenCloseEditSuppliersLateralPanel={onOpenCloseEditSuppliersLateralPanel}
                        onDeletedSupplierBiddingFromBackEnd={onDeletedSupplierBiddingFromBackEnd}
                        onCancelSupplierBiddingFromBackEnd={onChangeStatusSupplierFromBackEnd}
                        onUpdateContactEmailFromBackEnd={onUpdateContactEmailFromBackEnd}
                        onOpenCloseCreateServicesLateralPanel={onOpenCloseCreateServicesLateralPanel}
                        onCreateEvaluationCriteriaConfigPanel={onCreateEvaluationCriteriaConfigPanel}
                        onDeletedEvaluationFromBackEnd={onDeletedEvaluationFromBackEnd}
                        onDeleteServiceOrMaterial={onDeleteServiceOrMaterial}
                        onEditEvaluationCriteriaConfigPanel={onEditEvaluationCriteriaConfigPanel}
                        onAddResponseSupplier={onAddResponseSupplier}
                        onChangeStatusSupplierFromBackEnd={onChangeStatusSupplierFromBackEnd}
                        addEvaluationResponsibler={onAddEvaluationResponsible}
                        onEditEvaluationCriteriaGroupConfigPanel={onEditEvaluationCriteriaGroupConfigPanel}
                        onAddQuestion={onAddQuestion}
                        onAddAnswer={onAddAnswer}
                        onConfirmPassword={onConfirmPassword}
                        onAddWeighingConfigPanel={onAddWeighingConfigPanel}
                        onOpenCloseUploadAttachmentsLateralPanel={onOpenCloseUploadAttachmentsLateralPanel}
                        onOpenCloseExchangeRatesLateralPanel={onOpenCloseExchangeRatesLateralPanel}
                        onAddDivisaInBiddingsResultsFromBackEnd={onAddDivisaInBiddingsResultsFromBackEnd}
                        onDownloadBiddingResultsReport={onDownloadBiddingResultsReport}
                        canBeCalculateEnabled={canBeCalculateEnabled}
                        onAddWinnerConfigPanel={onAddWinnerConfigPanel}
                        onAddWinnerInItemFromBackend={onAddWinnerInItemFromBackend}
                        onAddCommentsSupplierConfigPanel={onAddCommentsSupplierConfigPanel}
                        onUploadAdditionalCostsConfigPanel={onUploadAdditionalCostsConfigPanel}
                        onSendEmailConfigPanel={onSendEmailConfigPanel}
                    /> :
                    <ShowDesktopViewExternalUser
                        identifier={identifier}
                        /**Objects */
                        bidding={bidding}
                        userProfile={userProfile}
                        userProfileLogged={userProfileLogged}
                        /**Bools */
                        isLoading={isLoading}
                        isSaving={isSaving}
                        canBePublicationEnabled={canBePublicationEnabled}
                        canBeSentProposalEnabled={canBeSentProposalEnabled}
                        isOpenLateralMenu={isOpenLateralMenu}
                        /**Arrays */
                        userRolesByCompany={userRolesByCompany}
                        internalUsers={usersByRole}
                        divisas={divisas}
                        /**Functions */
                        onOpenCloseEditLateralPanel={onOpenCloseEditLateralPanel}
                        translate={translate}
                        onCreateEvaluationCriteriaConfigPanel={onCreateEvaluationCriteriaConfigPanel}
                        onDeletedEvaluationFromBackEnd={onDeletedEvaluationFromBackEnd}
                        onDeleteServiceOrMaterial={onDeleteServiceOrMaterial}
                        onChangeStatusBiddingFromBackEnd={onChangeStatusBiddingFromBackEnd}
                        onEditEvaluationCriteriaConfigPanel={onEditEvaluationCriteriaConfigPanel}
                        onAddResponseSupplier={onAddResponseSupplier}
                        onChangeStatusSupplierFromBackEnd={onChangeStatusSupplierFromBackEnd}
                        onAddQuestion={onAddQuestion}
                        onAddAnswer={onAddAnswer}
                        isExternalUser={false}
                        onDivisaInBiddingFromBackEnd={onAddDivisaInBiddingFromBackEnd}
                        incoterms={incoterms}
                        onAddIncotermFromBackEnd={onAddIncotermFromBackEnd}
                        onDownloadMaterialsReport={onDownloadMaterialsReport}
                        onUploadMaterialsPricesConfigPanelLateralPanel={onUploadMaterialsPricesConfigPanelLateralPanel}
                        onAddResponseItemSupplierConfigPanel={onAddResponseItemSupplierConfigPanel}
                        onAddResponseGralSupplierConfigPanel={onAddResponseGralSupplierConfigPanel}
                        onAddCommentsSupplierConfigPanel={onAddCommentsSupplierConfigPanel}
                        onDownloadBiddingResultsReport={onDownloadBiddingResultsReport}
                        onSetMaterialStatusBySupplierInternal={onSetMaterialStatusBySupplierInternal}

                    />
            }

            <ShowEdit
                onOpenCloseEditLateralPanel={onOpenCloseEditLateralPanel}
                editBiddingConfigPanel={editBiddingConfigPanel}
                bidding={bidding}
                editSupplierConfigPanel={editSupplierConfigPanel}
                onOpenCloseEditSuppliersLateralPanel={onOpenCloseEditSuppliersLateralPanel}
                translate={translate}
                createServicesConfigPanel={createServicesConfigPanel}
                onOpenCloseCreateServicesLateralPanel={onOpenCloseCreateServicesLateralPanel}
                createEvaluationCriteriaConfigPanel={createEvaluationCriteriaConfigPanel}
                onCreateEvaluationCriteriaConfigPanel={onCreateEvaluationCriteriaConfigPanel}
                editEvaluationCriteriaConfigPanel={editEvaluationCriteriaConfigPanel}
                onEditEvaluationCriteriaConfigPanel={onEditEvaluationCriteriaConfigPanel}
                uploadAttachmentConfigPanel={uploadAttachmentConfigPanel}
                onOpenCloseUploadAttachmentsLateralPanel={onOpenCloseUploadAttachmentsLateralPanel}
                onEditEvaluationCriteriaGroupConfigPanel={onEditEvaluationCriteriaGroupConfigPanel}
                editEvaluationCriteriaGroupConfigPanel={editEvaluationCriteriaGroupConfigPanel}
                onConfirmPassword={onConfirmPassword}
                confirmPasswordConfigModal={confirmPasswordConfigModal}
                onAddWeighingConfigPanel={onAddWeighingConfigPanel}
                addWeighingConfigPanel={addWeighingConfigPanel}
                addEvaluationResponsible={onAddEvaluationResponsible}
                onOpenCloseExchangeRatesLateralPanel={onOpenCloseExchangeRatesLateralPanel}
                addExchangeRatesConfigPanel={addExchangeRatesConfigPanel}
                onUploadMaterialsPricesConfigPanelLateralPanel={onUploadMaterialsPricesConfigPanelLateralPanel}
                uploadMaterialsPricesConfigPanel={uploadMaterialsPricesConfigPanel}
                isExternalUser={false}
                addResponseItemSupplierConfigPanel={addResponseItemSupplierConfigPanel}
                onAddResponseItemSupplierConfigPanel={onAddResponseItemSupplierConfigPanel}
                onAddResponseSupplier={onAddResponseSupplier}
                onAddResponseGralSupplierConfigPanel={onAddResponseGralSupplierConfigPanel}
                addResponseGralSupplierConfigPanel={addResponseGralSupplierConfigPanel}
                addWinnerConfigPanel={addWinnerConfigPanel}
                onAddWinnerConfigPanel={onAddWinnerConfigPanel}
                addCommentsSupplierConfigPanel={addCommentsSupplierConfigPanel}
                onAddCommentsSupplierConfigPanel={onAddCommentsSupplierConfigPanel}
                onUploadAdditionalCostsConfigPanel={onUploadAdditionalCostsConfigPanel}
                uploadAdditionalCostsConfigPanel={uploadAdditionalCostsConfigPanel}
                sendEmailConfigPanel={sendEmailConfigPanel}
                onSendEmailConfigPanel={onSendEmailConfigPanel}
            />


            <Toaster
                message={<Translate id={toasterMessageConfig.toasterMessage} />}
                open={toasterMessageConfig.showToaster}
                variant={toasterMessageConfig.toasterVariant}
                onClose={() => {
                    setToasterMessageConfig({
                        showToaster: false,
                        toasterMessage: "",
                        toasterVariant: "",
                    })
                }}
            />

        </>
    )
}

const styles = ((theme) => ({

}))

export default withauthenticatedLayout(withRouter((withStyles(styles, { withTheme: true })(Show))))