/** Helpers import section */
import axios from 'axios';

import {
    getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserParameters,
    getAuthorizedRequestConfigurationUserFiles
} from '../helpers/SessionHelper';

export const START_LOADING_FREIGHTS = "START_LOADING_FREIGHTS";
export const SET_FREIGHTS_LIST = "SET_FREIGHTS_LIST";
export const CHANGE_FREIGHTS_SORT_CRITERIA = "CHANGE_FREIGHTS_SORT_CRITERIA";
export const CHANGE_FREIGHTS_QUERY = "CHANGE_FREIGHTS_QUERY";
export const CHANGE_FREIGHT_PAGINATION = "CHANGE_FREIGHT_PAGINATION";
export const SET_FREIGHTS_COUNT = "SET_FREIGHTS_COUNT";
export const IS_LOADING_FREIGHT = "IS_LOADING_FREIGHT";
export const SET_FREIGHT = "SET_FREIGHT";
export const ADD_FILES_FREIGHT_SUPPLIER = "ADD_FILES_FREIGHT_SUPPLIER";
export const REMOVE_FILES_FREIGHT_SUPPLIER = "REMOVE_FILES_FREIGHT_SUPPLIER";
export const IS_SAVING_FREIGHT = "IS_SAVING_FREIGHT";
export const UPDATE_DETAIL_FROM_FREIGHT = "UPDATE_DETAIL_FROM_FREIGHT";


/**
 * Action start loading freights
 * @param {*} isLoading 
 */
function isLoadingFreights(isLoading) {
    return {
        type: START_LOADING_FREIGHTS,
        isLoading: isLoading
    }
}

/**
 * Action to set freights list 
 * @param {*} freights
 */
function setFreightsList(freights) {    
    return {
        type: SET_FREIGHTS_LIST,
        freights: freights
    }
}

/**
 * Action to start load freight
 */
function startLoadFreight(isLoading) {
    return {
        type: IS_LOADING_FREIGHT,
        isLoadingFreight: isLoading
    }
}

/**
 * Action to set freight
 * @param {*} freight 
 */
function setFreight(freight) {    
    return {
        type: SET_FREIGHT,
        freight: freight
    }
}

/**
 * Action reducer to set the freights counter
 *
 * @param {*} count
 * @returns
 */
function setFreightsCount(count) {
    return {
        type: SET_FREIGHTS_COUNT,
        count
    }
}

/**
 * Add file to state by supplier
 *
 * @param {*} files
 * @returns
 */
function addFileBySupplierFreight(files, listItems) {
    return {
        type: ADD_FILES_FREIGHT_SUPPLIER,
        files,
        listItems
    }
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFileBySupplierFreight(fileName, listItems) {
    return {
        type: REMOVE_FILES_FREIGHT_SUPPLIER,
        fileName,
        listItems
    }
}

/**
 * Action start loading samples
 * @param {*} isLoading 
 */
function isSaving(isSaving) {
    return {
        type: IS_SAVING_FREIGHT,
        isSaving: isSaving
    }
}

/**
 * Get all shipping order's by company from backend 
 */
export function loadFreightsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {
        dispatch(isLoadingFreights(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_code;
        }
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/freight/filters`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {                    
                    dispatch(setFreightsList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingFreights(false));
                console.error("Error getting the Freight by company list", error.response)
            })
    }
}


/**
 * Get freights count
 *
 * @export
 * @returns
 */
export function getFreightsCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().freights.get('FreightsCount');
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/freight/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setFreightsCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the Freight's count", error.response)
            })
    }
}


/**
 * Get and set the freight in the estate from read from backend
 * @param {*} token 
 */
export function loadFreightFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(startLoadFreight(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/freight/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {                    
                    dispatch(setFreight(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load Freight", error.response)
                dispatch(startLoadFreight(false))
                return Promise.reject();
            })
    }
}

/**
 * Action to upload files to supplier from sample
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadFilesFreight(responseToken, files,  folder, listItems) {
    return function (dispatch, getState) {
        let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/freight/${responseToken}/${folder}`;
        let formData = new FormData();
        for (var index = 0; index < files.length; index++) {            
            formData.append('file', files[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addFileBySupplierFreight(serverResponse.data, listItems))
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                console.error("Error to upload sample files from supplier file(s)", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to remove files to supplier from saplme
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeFilesFreight(responseToken, fileName, folder, listItems) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/freight/${responseToken}/remove/${folder}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeFileBySupplierFreight(fileName, listItems))
                }
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to upload freight file(s)", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Method to update rmi document information basic
 */
export function updateFreightResponse(responseToken, observtions) {
    return function (dispatch, getState) {

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/freight/response`;
        let data = {
            Token: responseToken,
            Observations: observtions,
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                }
            })
            .catch((error) => {
                console.error("Error to load freight", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Method to update rmi document information basic
 */
export function updateFreight(token, dateTimeEta) {
    return function (dispatch, getState) {

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/freight`;
        let data = {
            Token: token,
            DateTimeEta: dateTimeEta,
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setFreight(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load freight", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to send Send freight To Client
 */
export function sendSendFreightToClient(token) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            NameUserCreator: user.profile.name,
        }
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/freight/${token}/sendtoclient`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setFreight(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in send freight to client ", error.response)
                return Promise.reject(error.response.data.Message);
            })
    }
}

/**
 * Action to cancel a shipping order document
 *
 * @export
 * @param {*} token
 * @returns
 */
export function cancelFreightOrder(token, razonForCancel) {
    return function (dispatch, getState) {
        dispatch(isSaving(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let user = getState().oidc.user;

        let data = {
            CancelUserName: user.profile.preferred_username,
            CancelBy: user.profile.name,
            ShippingToken: token,
            ReasonCancellation: razonForCancel
        }
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/freight/cancelshipping`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    dispatch(setFreight(serverResponse.data))
                    return Promise.resolve('Ok');
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to cancel a freight", error.response)
                return Promise.reject(error.response.data.Message);
            })
    }
}

/**
 * Action to forward shipping order to supplier to correct documents
 *
 * @export
 * @param {*} token
 * @returns
 */
export function forwardTheSupplierToCorrectDocuments(token, observations) {
    return function (dispatch, getState) {
        dispatch(isSaving(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let user = getState().oidc.user;

        let data = {
            CreatorUserName: user.profile.preferred_username,
            CreatedBy: user.profile.name,
            ShippingToken: token,
            Observations: observations
        }
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/freight/forwardthesuppliertocorrectdocuments`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    dispatch(setFreight(serverResponse.data))
                    return Promise.resolve('Ok');
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to forward freight to supplier to correct documents", error.response)
                return Promise.reject(error.response.data.Message);
            })
    }
}

/**
 *Action to download report sac's development by range date
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadFreightsReportByRangeDate(startDate, endDate) {
    return (dispatch, getState) => {
        dispatch(startLoadFreight(true));
        let companyToken = getState().profile.get("selectedCompany").get('token');
        let data = {
            CompanyToken: companyToken,
            StartDate: startDate,
            EndDate: endDate
        }
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/freight/downloadByRangeDate`;
        return axios.post(endPoint, data, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(startLoadFreight(false));
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reportes_de_importaciones.xlsx";
                    a.click();
                }
            })
            .catch((error) => {
                dispatch(startLoadFreight(false));
                console.error("Error downloading freight report by month", error.response)
                return Promise.reject();
            })
    }
}
