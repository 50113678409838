import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

/** Material-UI imports section */
import { Tab, Tabs, withStyles } from '@material-ui/core';

/** Actions import section */
import {
	loadBusinessUnitConfiguration,
	loadWorkshopCodes
} from '../../store/accreditedWorkshops/accreditedWorkshopsActions';

/** Redux selectors import section */
import { getConfigurationSelector } from '../../store/accreditedWorkshops/accreditedWorkshopsSelectors';
import { getLoggedUserSelector } from '../../store/profile/ProfileSelectors';

/** Custom components import section */
import ReleasedServiceOrders from './administrator/ReleasedServiceOrders';
import NotReleasedServiceOrders from './administrator/NotReleasedServiceOrders';
import LockedServiceOrders from './administrator/LockedServiceOrders';
import Configuration from './administrator/Configuration';
import PaymentRequestsIndex from './paymentRequests/Index';
import WorkshopsIndex from './workshops/Index';
import LoadingPage from '../common/LoadingPage';

const AdminitratorIndex = (props) => {
	const { classes } = props;
	const { activeTab } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const configuration = useSelector((state) => getConfigurationSelector(state));
	const loggedUser = useSelector((state) => getLoggedUserSelector(state));

	/**
	 * Load business configuration
	 */
	useEffect(() => {
		const loadConfiguration = async (rfc) => {
			dispatch(loadBusinessUnitConfiguration(rfc));
		};
		if (loggedUser && loggedUser.selectedCompany) {
			var rfc = loggedUser.selectedCompany.rfc;
			loadConfiguration(rfc);
		}
		dispatch(loadWorkshopCodes());
	}, [dispatch, loggedUser.selectedCompany.rfc]);

	/**
	 * Handle the change of tab
	 */
	const handleChange = (event, newValue) => {
		history.push(`/accreditedWorkshops/${newValue}`);
	};

	const configurationVariable = configuration.configuration ? configuration.configuration : undefined;

	/**
	 * Render content for current selected tab
	 */
	const renderTabContent = () => {
		switch (activeTab) {
			case tabs.ReleasedServiceOrders:
				return <ReleasedServiceOrders />;
			case tabs.NotReleasedServices:
				return <NotReleasedServiceOrders />;
			case tabs.LockedServices:
				return <LockedServiceOrders />;
			case tabs.PaymentRequests:
				return <PaymentRequestsIndex />;
			case tabs.Workshops:
				return <WorkshopsIndex />;
			case tabs.Settings:
				return <Configuration rfc={loggedUser.selectedCompany.rfc} configuration={configurationVariable} />;
			default:
				return <Configuration rfc={loggedUser.selectedCompany.rfc} configuration={configurationVariable} />;
		}
	};

	// if (!configuration.configuration && activeTab !== "settings") {
	//   handleChange(null, "settings");
	// }

	const isBusinessUnitConfigured = () => {
		if (configuration.configuration) {
			return configuration.configuration.ServiceTypes.length > 0;
		} else return false;
	};

	if (configuration.isLoading) {
		return <LoadingPage />;
	}

	return (
		<>
			<Tabs
				value={activeTab}
				onChange={handleChange}
				className={classes.root}
				aria-label="Tabs to show services or configuration"
			>
				<Tab
					className={classes.tabItem}
					label={<Translate id="accreditedWorkshops.serviceOrders" />}
					disabled={!isBusinessUnitConfigured()}
					{...a11yProps(tabs.ReleasedServiceOrders)}
				/>
				<Tab
					className={classes.tabItem}
					label={<Translate id="accreditedWorkshops.notReleasedServices" />}
					disabled={!isBusinessUnitConfigured()}
					{...a11yProps(tabs.NotReleasedServices)}
				/>
				<Tab
					className={classes.tabItem}
					label={<Translate id="accreditedWorkshops.lockedServices" />}
					disabled={!isBusinessUnitConfigured()}
					{...a11yProps(tabs.LockedServices)}
				/>
				<Tab
					className={classes.tabItem}
					label={<Translate id="accreditedWorkshops.paymentRequests" />}
					disabled={!isBusinessUnitConfigured()}
					{...a11yProps(tabs.PaymentRequests)}
				/>
				<Tab
					className={classes.tabItem}
					label={<Translate id="accreditedWorkshops.workshops" />}
					{...a11yProps(tabs.Workshops)}
				/>
				<Tab
					className={classes.tabItem}
					label={<Translate id="accreditedWorkshops.settings" />}
					{...a11yProps(tabs.Settings)}
				/>
			</Tabs>
			{renderTabContent()}
		</>
	);
};

const tabs = {
	ReleasedServiceOrders: 'service-orders',
	NotReleasedServices: 'not-released-services',
	LockedServices: 'locked-services',
	PaymentRequests: 'payment-requests',
	Workshops: 'workshops',
	Settings: 'settings'
};

const a11yProps = (id) => {
	return {
		id: `tab-${id}`,
		value: `${id}`,
		'aria-controls': `simple-tabpanel-${id}`
	};
};

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.common.white,
		minHeight: '30px !important'
	},
	tabItem: {
		minHeight: '30px !important'
	}
});

export default withStyles(styles, { withTheme: true })(AdminitratorIndex);
