
/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserFiles } from '../helpers/SessionHelper';

export const CHANGE_MANUALS_SORT_CRITERIA = "CHANGE_MANUALS_SORT_CRITERIA";
export const CHANGE_MANUALS_SORT_QUERY = "CHANGE_MANUALS_SORT_QUERY";
export const CHANGE_MANUALS_PAGINATION = "CHANGE_MANUALS_PAGINATION";

export const START_LOADING_MANUALS = "START_LOADING_MANUALS";
export const SET_MANUALS_LIST = "SET_MANUALS_LIST";
export const SET_MANUALS_COUNT = "SET_MANUALS_COUNT";
export const IS_SAVING_MANUAL = "IS_SAVING_MANUAL";
export const ADD_MANUAL_TO_LIST = "ADD_MANUAL_TO_LIST";
export const REMOVE_MANUAL_TO_LIST = "REMOVE_MANUAL_TO_LIST";

/**
 * Action start loading manuals
 * @param {*} isLoading 
 */
function isLoadingManuals(isLoading) {
    return {
        type: START_LOADING_MANUALS,
        isLoading: isLoading
    }
}

/**
 * Action to set manuals list 
 * @param {*} manuals 
 */
function setManualsList(manuals) {
    return {
        type: SET_MANUALS_LIST,
        manuals: manuals
    }
}

/**
 * Action to set manuals count
 * @param {*} count 
 */
function setManualsCount(count) {
    return {
        type: SET_MANUALS_COUNT,
        count
    }
}

/**
 * Action to start load manual
 */
function isSavingManual(isSaving) {
    return {
        type: IS_SAVING_MANUAL,
        isSaving: isSaving
    }
}

/**
 * add manual in list
 * @param {*} manual 
 */
function addManualToList(manual) {
    return {
        type: ADD_MANUAL_TO_LIST,
        manual
    }
}

/**
 * remove manual in list
 * @param {*} manualToken 
 */
function removeManualToList(manualToken){
    return {
        type: REMOVE_MANUAL_TO_LIST,
        manualToken
    }
}

/**
 * Action load manuals from company backend
 * @param {*} query 
 * @param {*} orderBy 
 * @param {*} sortAscending 
 * @param {*} page 
 * @param {*} rowsPerPage 
 */
export function loadManualsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {

        dispatch(isLoadingManuals(true));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/manualsportals`;
            let data = {
                CompanyToken: selectedCompany.get("token"),
                SortBy: orderBy,
                SortAscending: sortAscending,
                Query: query,
                Page: `${page+1}`,
                RowsPerPage: rowsPerPage,
            }
            return axios.post(endPoint, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setManualsList(serverResponse.data))
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingManuals(false));
                    console.error("Error getting manuals list by company", error.response)
                })
        }
        else {
            dispatch(isLoadingManuals(false));
        }
    }
}
/**
 * Action to get manuals count 
 */
export function getManualsCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().manualsportal ? getState().manualsportal.get('manualsCount') : null;
        if (actualCounter) {
            return;
        }
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/manualsportal/count`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setManualsCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the manuals count", error.response)
            })
    }
}


/**
 * Action to create company 
 * @param {*} company 
 */
export function createManualData(manual) {
    return function (dispatch, getState) {
        dispatch(isSavingManual(true));

        let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/manualsportal`;
        let companyToken = getState().profile.get("selectedCompany").get('token');
        let formData = new FormData();
        manual.annexe.forEach((file) => {
            formData.append('file', file);
        })
        formData.set('CompanyToken', companyToken);
        formData.set('Name', manual.name);

        return axios.post(endPoint, formData, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(addManualToList(response.data));
                    return Promise.resolve();
                }
                else {
                    dispatch(isSavingManual(false));
                }
            })
            .catch((error) => {
                console.error("Error create notice portal", error.response);
                dispatch(isSavingManual(false));
                return Promise.reject();
            })
    }
}

/**
 * Remove manual portal 
 * @param {*} manualToken 
 */
export function removeManualPortal(manualToken) {
    return function (dispatch, getState) {

        dispatch(isSavingManual(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/manualsportal/${manualToken}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingManual(false));
                    dispatch(removeManualToList(manualToken));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingManual(false));
                console.error("Error in remove manual token", error.response)
                return Promise.reject();
            })
    }
}

