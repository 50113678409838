///Import react section
import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import autoBind from 'auto-bind';
import { withRouter } from "react-router-dom";
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import {loadQualityFailureReportFromBackEnd} from '../../store/qualityFailureReport/QualityFailureReportAction';
import { connect } from 'react-redux';
import {cancelWorflowQualityFromBackend} from '../../store/workflows/workflowActions';
import CancelWorkflow from '../common/CancelWorkflow';

class CancelContainer extends Component {
  constructor (props) {
    super(props);
    autoBind(this);
    this.state = {

    }
  }

    /**
    * componentDidMount
    */
   componentDidMount() {
    let qualityFailureToken = this.props.match.params.token;
    this.props.loadQualityFailureReport(qualityFailureToken);
}

      /**
     * Cancel a quality Failure from back end
     */
    onCancelWorkflowsDocument(cancelComment, razonForCancel){
      let token =this.props.match.params.token;
      this.props.cancelWorflowQualityFromBackend(token, cancelComment, razonForCancel)
      .then(() =>{
        this.props.history.push(`/qualityfailurereport/${this.props.qualityFailure.token}`)
      }).catch(() =>{
          this.setState({
              showToaster: true,
              toasterMessage: <Translate id="common.errorToSave" />,
              toasterVariant: "error",
          })
      })
  }

  render () {
    return (
      <CancelWorkflow
        {...this.props}
          workflow= {this.props.qualityFailure}
          onCancelWorkflowsDocument={this.onCancelWorkflowsDocument}
          isSaving={this.props.isCancel}
          link = {'qualityfailurereport'}

      />
    )
  }
}

/**
 *  Defines the properties injecteded from the store to view container
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state) {
    let qualityFailure = state.qualityFailureReport.get("qualityFailureReport");
   return {
      isCancel: state.workflow.get('isCancel'),
      qualityFailure: qualityFailure ? qualityFailure.toJS() : null,
  }
}

/**
 * Defines the actions injectes to the component
 *
 * @param {*} state
 * @returns
 */
const mapDispatchToProps = dispatch => {
  return {
    loadQualityFailureReport: (token) => {
        return dispatch(loadQualityFailureReportFromBackEnd(token));
    },
    cancelWorflowQualityFromBackend: (token, cancelComment, razonForCancel) => {
        return dispatch(cancelWorflowQualityFromBackend(token, cancelComment, razonForCancel));
    },
    
  }
}

export default withauthenticatedLayout((withRouter) (connect(mapStateToProps, mapDispatchToProps)(CancelContainer)));