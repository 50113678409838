import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import { getTranslate, Translate } from 'react-localize-redux'
import LoadingPage from '../../common/LoadingPage'
import DataTable from '../../common/DataTable'
import RegisterDeliveryButton from './components/RegisterDeliveryButton'
import Toaster from '../../common/Toaster'
import DeliveriesCalendar from './components/DeliveriesCalendar'
import SearchInputOptions from '../../common/SearchInput/SearchInputOptions'
import OptionsMenu from '../../common/OptionsMenu'
import ArchiveDownloadIcon from '@material-ui/icons/Archive'
import DonwloadReportDialog from '../../common/DonwloadReportDialog'

/** Material UI imports section */
import {
	Card,
	CardHeader,
	Avatar,
	Typography,
	CardContent,
	IconButton,
	Tooltip,
	Fab,
	Select,
	MenuItem,
} from '@material-ui/core'

/** Material UI Icons imports section */
import DeliveryOrdersIcon from '@material-ui/icons/Assignment'
import IndexViewIcon from '@material-ui/icons/ViewList'
import SchedulerViewIcon from '@material-ui/icons/CalendarToday'
import AddIcon from '@material-ui/icons/AddTwoTone'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Actions and helpers imports section */
import {
	loadDeliveryOrdersFromBackEnd,
	CHANGE_DELIVERY_ORDERS_SORT_CRITERIA,
	CHANGE_DELIVERY_ORDERS_QUERY,
	getDeliveryOrdersCount,
	downloadDeliveryOrderReport,
	loadDeliveryOrdersEventsFromBackEnd,
	downloadOrdersReportByRangeDate,
	CHANGE_DELIVERY_ORDERS_PAGINATION,
	downloadOrdersReportByDeliveryDate,
} from '../../../store/planning/DeliveryOrdersAction'

import { IsUserSupplierAdministrator } from '../../../store/helpers/SessionHelper'
import { converListToArray } from '../../../store/helpers/ListHelper'
import { canBeModulePlaneationSupplier, canBeOperationDeliveryWarehouse } from '../../../store/helpers/RolesHelper'
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import { showErrorToaster, showSuccessToaster } from '../../../store/helpers/ToasterHelpers'
import { GetRampsOptions } from '../../../store/helpers/SelectOptions'
import TrackingStatus from '../../common/TrackingStatus'
import { GetApp } from '@material-ui/icons'

/**
 * Class to show index of Delivery orders
 *
 * @class Index
 * @extends {Component}
 */
class Index extends Component {
	/**
	 *Creates an instance of Index.
	 * @param {*} props
	 * @memberof Index
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showIndex: true,
			isDownloadingReport: false,
			additionalFilters: null,
			itemToken: null,
			showMenu: true,
			openModalReportByTime: false,
			openModalByMonthReport: false,
			openModalByDeliveryDate: false,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			ramp: 0,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadDeliveryOrders(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getDeliveryOrdersCount()
			this.props.loadDeliveryOrdersEvents()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadDeliveryOrders()
			this.props.getDeliveryOrdersCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadDeliveryOrders(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.state.additionalFilters
			)
		}
	}

	/**
	 * Method to custom render in datalist
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof Index
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'tracking') {
			console.log('ITEM', item)
			return <TrackingStatus tracking={item.tracings} />
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Switch view between index and calendar
	 *
	 * @memberof Index
	 */
	toggleView() {
		this.setState({ showIndex: !this.state.showIndex })
	}

	/**
	 * On render view to register delivery arrival
	 */
	onRegisterArrival() {
		this.props.history.push(`/deliveryorder/registerarrival`)
	}

	onSearchByFilters(additionalFilters) {
		this.setState(
			{
				additionalFilters,
			},
			() => {
				this.props.changePage(0, this.props.rowsPerPage)
			}
		)
		return this.props
			.loadDeliveryOrders(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				additionalFilters
			)
			.then((response) => {
				return Promise.resolve('Ok')
			})
	}

	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof Index
	 */
	handleClickOptionsMenu(token) {
		this.setState({
			itemToken: token,
		})
	}

	/**
	 * Handle on close optons menu to set void item token in state
	 *
	 * @memberof Index
	 */
	handleCloseOptionsMenu() {
		if (!this.state.openModalByMonthReport || !this.openModalReportByTime) {
			this.setState(
				{
					itemToken: '',
					showMenu: false,
				},
				() => {
					this.setState({ showMenu: true })
				}
			)
		}
	}

	/**
	 * Open modal for set report by mont
	 *
	 * @memberof Index
	 */
	handleOpenModal() {
		this.setState({
			openModalByMonthReport: true,
		})
	}

	handleOpenModalByDeliveryDate() {
		this.setState({
			openModalByDeliveryDate: true,
		})
	}

	/**
	 * Close modal to report by month
	 *
	 * @memberof Index
	 */
	handleCloseModal() {
		this.setState(
			{
				openModalByMonthReport: false,
			},
			() => this.handleCloseOptionsMenu()
		)
		this.setState({ startDate: '', endDate: '' })
	}

	handleCloseModalByDeliveryDate() {
		this.setState(
			{
				openModalByDeliveryDate: false,
			},
			() => this.handleCloseOptionsMenu()
		)
		this.setState({ startDate: '', endDate: '' })
	}

	handleOpenModalByTime() {
		this.setState({
			openModalReportByTime: true,
		})
	}

	handleCloseModalByTime() {
		this.setState(
			{
				openModalReportByTime: false,
			},
			() => this.handleCloseOptionsMenu()
		)
		this.setState({ startDate: '', endDate: '' })
	}

	/**
	 *Download report of from contacts
	 *
	 * @memberof Index
	 */
	handleDownloadReportByDateRange(startDate, endDate) {
		this.props
			.downloadOrdersReportByRangeDate(startDate, endDate)
			.then(() => {
				this.handleCloseModal()
				showSuccessToaster('common.downloadCorrectly')
			})
			.catch((error) => {
				showErrorToaster('common.errorToDownload')
			})
	}

	handleDownloadReportByDeliveryDate(startDate, endDate) {
		this.props
			.downloadOrdersReportByDeliveryDate(startDate, endDate)
			.then(() => {
				this.handleCloseModalByDeliveryDate()
				showSuccessToaster('common.downloadCorrectly')
			})
			.catch((error) => {
				showErrorToaster('common.errorToDownload')
			})
	}

	/**
	 * Function to donwload report.
	 */
	onDownloadReport(startDate, endDate) {
		this.setState({ isDownloadingReport: true })
		this.props
			.downloadReport(startDate, endDate)
			.then(() => {
				this.setState({ isDownloadingReport: false })
				this.handleCloseModalByTime()
				showSuccessToaster('common.downloadCorrectly')
			})
			.catch(() => {
				this.setState({ isDownloadingReport: false })
				showErrorToaster('common.errorToDownload')
			})
	}

	/**
	 *Render action button in card download expedient report
	 *
	 * @memberof Index
	 */
	renderAction() {
		var options = []
		options.push({
			itemClick: () => this.handleOpenModalByTime(),
			tooltipTranslation: <Translate id='deliveryOrderModule.donwloadReportTimeEntry' />,
			menuItemIcon: <DownloadIcon color='secondary' />,
			isLoading: this.state.isDownloadingReport,
		})
		options.push({
			itemClick: () => this.handleOpenModal(),
			tooltipTranslation: <Translate id='deliveryOrderModule.donwloadReportOrders' />,
			menuItemIcon: <ArchiveDownloadIcon color='secondary' />,
			isLoading: this.props.isDonwloadingReport,
		})
		options.push({
			itemClick: () => this.handleOpenModalByDeliveryDate(),
			tooltipTranslation: <Translate id='deliveryOrderModule.donwloadReportOrdersByDeliveryDate' />,
			menuItemIcon: <GetApp color='secondary' />,
			isLoading: this.props.isDonwloadingReport,
		})
		if (this.state.showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === 'item.token'}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	changeRamp(value) {
		this.setState({ ramp: value })
	}

	/**
	 * Get delivery orders counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getDeliveryOrdersCounterMessage() {
		if (this.props.deliveryOrders) {
			let totalDeliveryOrders = this.props.deliveryOrders.length
			return (
				<diV>
					<div>
						{this.props.translate('common.showing')}&nbsp;
						<span>{totalDeliveryOrders}</span>&nbsp;
						{this.props.translate('common.of')}&nbsp;
						<span>{this.props.deliveryOrdersCount}</span>&nbsp;
						{this.props.translate('common.records')}
					</div>
					<div>
						{
							'Seguimiento: 1 Borrador, 2 En Transito, 3 En Almacen, 4 En espera de registro SAP, 5 Finalizado'
						}
						&nbsp;
					</div>
				</diV>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingDeliveryOrders === true) {
			return <LoadingPage />
		} else if (this.props.deliveryOrders === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let tableConfiguration = TableInternalConfiguration
			if (!IsCurrentActiveDirectoryUser(this.props.user)) {
				tableConfiguration = TableExtenalInternalConfiguration
			}
			return (
				<DataTable
					data={this.props.deliveryOrders}
					configuration={tableConfiguration}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(deliveryOrder) => {
						this.props.history.push(`/planning/deliveryorder/${deliveryOrder.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.deliveryOrdersCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	render() {
		const { classes } = this.props
		let optionsRamp = GetRampsOptions()
		let events =
			this.state.ramp !== 0
				? this.props.deliveryOrdersEvents.filter((x) => parseInt(x.ramp) === parseInt(this.state.ramp))
				: this.props.deliveryOrdersEvents
		return (
			<Card>
				{canBeOperationDeliveryWarehouse(this.props.userRolesByCompany) && (
					<span>
						<RegisterDeliveryButton classes={classes} open={this.onRegisterArrival} />
						<br />
					</span>
				)}

				<CardHeader
					avatar={
						<Avatar>
							<DeliveryOrdersIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							{this.state.showIndex && (
								<React.Fragment>
									<SearchInputOptions
										className={classes.searchInput}
										onChangeSearchValue={this.onChangeSearchValue}
										value={this.props.searchQuery}
										isSearchByFilters
										onSearchByFilters={(filters) => this.onSearchByFilters(filters)}
										options={AdditionalFilters}
									/>
									{(canBeModulePlaneationSupplier(this.props.userRolesByCompany) ||
										IsUserSupplierAdministrator(this.props.user)) && (
										<Tooltip title={<Translate id={'deliveryOrderModule.activeDeliveryOrder'} />}>
											<Fab
												size='small'
												color='primary'
												onClick={() => {
													this.props.history.push(`/planning/deliveryorder/create`)
												}}
											>
												<AddIcon />
											</Fab>
										</Tooltip>
									)}
								</React.Fragment>
							)}
							{!this.state.showIndex && (
								<Select
									fullWidth
									value={this.state.ramp}
									de
									// className={classes.documentTypeSelect}
									onChange={(event) => {
										this.changeRamp(event.target.value)
									}}
								>
									{optionsRamp.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</Select>
							)}
							<Tooltip
								title={
									<Translate id={this.state.showIndex ? 'common.showCalendar' : 'common.showIndex'} />
								}
							>
								<IconButton
									color='primary'
									value
									onClick={() => {
										this.toggleView()
									}}
								>
									{this.state.showIndex ? <SchedulerViewIcon /> : <IndexViewIcon />}
								</IconButton>
							</Tooltip>

							{IsCurrentActiveDirectoryUser(this.props.user) && this.renderAction()}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.deliveryOrder' />{' '}
						</Typography>
					}
					subheader={this.getDeliveryOrdersCounterMessage()}
				/>
				<CardContent>
					{this.state.showIndex && this.getCardContent()}
					{!this.state.showIndex && <DeliveriesCalendar events={events} />}
				</CardContent>

				{/** Dialog to download report */}
				<DonwloadReportDialog
					title={<Translate id='deliveryOrderModule.donwloadReportOrders' />}
					open={this.state.openModalByMonthReport}
					onClose={this.handleCloseModal}
					isSaving={this.props.isDonwloadingReport}
					onDonwloadItems={this.handleDownloadReportByDateRange}
					saveButtonTranslate={'common.downloadReport'}
				/>

				{/** Dialog to download report */}
				<DonwloadReportDialog
					title={<Translate id='deliveryOrderModule.donwloadReportOrdersByDeliveryDate' />}
					open={this.state.openModalByDeliveryDate}
					onClose={this.handleCloseModalByDeliveryDate}
					isSaving={this.props.isDonwloadingReport}
					onDonwloadItems={this.handleDownloadReportByDeliveryDate}
					saveButtonTranslate={'common.downloadReport'}
				/>

				{/** Dialog to download report  by time */}
				<DonwloadReportDialog
					title={<Translate id='deliveryOrderModule.donwloadReportTimeEntry' />}
					open={this.state.openModalReportByTime}
					onClose={this.handleCloseModalByTime}
					isSaving={this.props.isDonwloadingReport}
					onDonwloadItems={this.onDownloadReport}
					saveButtonTranslate={'common.downloadReport'}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const AdditionalFilters = [
	{
		type: 'datetime',
		properties: [
			{
				propertyName: 'start_print_date',
				translation: 'deliveryOrderModule.searchFilters.startPrintingDate',
				dependentPropertyName: 'end_print_date',
			},
			{
				propertyName: 'end_print_date',
				translation: 'deliveryOrderModule.searchFilters.endPrintingDate',
				minDateProperty: 'start_print_date',
			},
		],
	},
	{
		type: 'datetime',
		properties: [
			{
				propertyName: 'start_estimated_arrival_date',
				translation: 'deliveryOrderModule.searchFilters.startEstimatedDeliveryDate',
				dependentPropertyName: 'end_estimated_arrival_date',
			},
			{
				propertyName: 'end_estimated_arrival_date',
				translation: 'deliveryOrderModule.searchFilters.endEstimatedDeliveryDate',
				minDateProperty: 'start_estimated_arrival_date',
			},
		],
	},
]

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		user: user,
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingSuppliers: state.suppliers.get('isLoadingSuppliers'),
		suppliers: state.suppliers.get('suppliers') ? state.suppliers.get('suppliers').toJS() : null,
		isLoadingDeliveryOrders: state.deliveryOrders.get('isLoadingDeliveryOrders'),
		deliveryOrders: state.deliveryOrders.get('deliveryOrders')
			? state.deliveryOrders.get('deliveryOrders').toJS()
			: null,
		deliveryOrdersCount: state.deliveryOrders.get('deliveryOrdersCount'),
		sortCriteria: state.deliveryOrders.get('sortCriteria') ? state.deliveryOrders.get('sortCriteria').toJS() : null,
		searchQuery: state.deliveryOrders.get('searchQuery'),
		page: state.deliveryOrders.get('page'),
		rowsPerPage: state.deliveryOrders.get('rowsPerPage'),
		translate: getTranslate(state.localize),
		deliveryOrdersEvents: state.deliveryOrders.get('deliveryOrdersEvents')
			? state.deliveryOrders.get('deliveryOrdersEvents').toJS()
			: [],
		isDonwloadingReport: state.deliveryOrders.get('isDonwloadingReport'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadDeliveryOrders: (query, orderBy, sortAscending, page, rowsPerPage, filters = null) => {
			return dispatch(loadDeliveryOrdersFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, filters))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_DELIVERY_ORDERS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_DELIVERY_ORDERS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_DELIVERY_ORDERS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getDeliveryOrdersCount: () => {
			dispatch(getDeliveryOrdersCount())
		},
		downloadReport: (startDate, endDate) => {
			return dispatch(downloadDeliveryOrderReport(startDate, endDate))
		},
		loadDeliveryOrdersEvents: () => {
			dispatch(loadDeliveryOrdersEventsFromBackEnd())
		},
		downloadOrdersReportByRangeDate: (startDate, endDate) => {
			return dispatch(downloadOrdersReportByRangeDate(startDate, endDate))
		},
		downloadOrdersReportByDeliveryDate: (startDate, endDate) => {
			return dispatch(downloadOrdersReportByDeliveryDate(startDate, endDate))
		},
	}
}

const styles = (theme) => ({
	logo: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
})

const TableInternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'tracking',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.state' />,
			dataSource: 'actual_operation_description',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.folio' />,
			dataSource: 'folio',
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printingDate' />,
			dataSource: 'print_date',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printingTime' />,
			dataSource: 'print_time',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.estimatedDeliveryDate' />,
			dataSource: 'estimated_arrival_date',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.estimatedDeliveryTime' />,
			dataSource: 'estimated_arrival_time',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printedBy' />,
			dataSource: 'print_by',
		},
	],
}

const TableExtenalInternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'tracking',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.state' />,
			dataSource: 'actual_operation_description',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.folio' />,
			dataSource: 'folio',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printingDate' />,
			dataSource: 'print_date',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printingTime' />,
			dataSource: 'print_time',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.estimatedDeliveryDate' />,
			dataSource: 'estimated_arrival_date',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.estimatedDeliveryTime' />,
			dataSource: 'estimated_arrival_time',
		},
		{
			header: <Translate id='deliveryOrderModule.deliveryOrderIndex.printedBy' />,
			dataSource: 'print_by',
		},
	],
}

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
