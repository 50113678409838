import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'

/**import actions section */
import AddMaterialModal from './AddMaterialModal'
import CloseAndPrintModal from './CloseAndPrintModal'
import {
	checkScheduleAvailability,
	closeAndPrintDeliveryOrderOperation,
	getActiveDeliveryOrderFromBackEnd,
	// loadRelease,
	loadReleaseMaterialsConfirms,
	removeMaterialFromDeliveryOrder,
	updateMaterialFromDeliveryOrder,
	removeFilesQualityCertificates,
	uploadFilesQualityCertificates,
	downloadUploadFiles
} from '../../../store/plannigFersa/DeliveryOrderAction'
import { IsUserSupplierAdministrator } from '../../../store/helpers/SessionHelper'
import { loadPartnership, loadSupplier } from '../../../store/suppliers/SupplierActions'
import { addMaterialToDeliveryOrder } from '../../../store/plannigFersa/DeliveryOrderAction'
//Import components section
import MaterialsTable from '../components/MaterialsTable'
import LayoutHeaderWorkFlow from '../../common/LayoutHeaderWorkFlow'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import Evidence from '../../common/Evidence'
import DeleteConfirmationModal from '../../planning/deliveryOrder/components/DeleteConfirmationModal'
//import { checkScheduleAvailability } from '../../../store/planning/DeliveryOrdersAction'
import FilesComponent from './FilesComponent'
/** Import component section */
import LoadingPage from '../../common/LoadingPage'
import { converListToArray } from '../../../store/helpers/ListHelper'
import { showErrorToaster, showWarningToaster } from '../../../store/helpers/ToasterHelpers'
import { getIsLoadingDeliveryOrdenItems } from '../../../store/plannigFersa/ReleasePlanningSelector'
import { canBeModulePlaneationSupplier, canBeOperationDeliveryWarehouse } from '../../../store/helpers/RolesHelper'
import { newCompanyDataFersa } from '../../../store/helpers/Company'
/** Material UI imports section */
import { withStyles } from '@material-ui/core/styles'
import AlertIcon from '@material-ui/icons/ErrorOutline'
import NoReleaseIcon from '@material-ui/icons/EventBusy'
import { AppBar, Card, CardContent, Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import Toaster from '../../common/Toaster'
const Create = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	const [selectdTab, setSelectedTab] = useState(0)
	const [code, setCode] = useState(false)
	const [orderBy, setOrderBy] = useState(false)
	const [positionValue, setPositionValue] = useState(false)
	const [isSaving, SetIsSaving] = useState(true)
	const [isLoadingReleaseUseEffect, setIsLoadingReleaseUseEffect] = useState(true)

	useEffect(() => {
		if (user.profile.company_token) {
			dispatch(loadSupplier(user.profile.company_token))
				.then((supplierValue) => {
					dispatch(loadPartnership(supplierValue.code, company.token))
						.then((partner) => {
							dispatch(getActiveDeliveryOrderFromBackEnd(supplierValue, partner))
								.then(_ => {
									setIsLoadingReleaseUseEffect(false)
								})
								.catch(_ => {
									setIsLoadingReleaseUseEffect(false)
								})
						})
						.catch(_ => {
							setIsLoadingReleaseUseEffect(false)
						})
				})
				.catch(_ => {
					setIsLoadingReleaseUseEffect(false)
				})
		}

	}, [dispatch])

	useEffect(() => {
		dispatch(loadReleaseMaterialsConfirms()).finally(() => SetIsSaving(false))
	}, [dispatch])

	const user = useSelector((state) => state.oidc.user)
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const supplier = useSelector((state) =>
		state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null
	)
	const isLoadingSupplier = useSelector((state) => state.suppliers.get('isLoadingSupplier'))

	const deliveryOrder = useSelector((state) =>
		state.deliveryOrder.get('deliveryOrderDraft') ? state.deliveryOrder.get('deliveryOrderDraft').toJS() : null
	)
	const materials = useSelector((state) =>
		state.deliveryOrder.get('deliveryOrderDraft')
			? state.deliveryOrder.getIn(['deliveryOrderDraft', 'materials']).toJS()
			: null
	)
	const company = useSelector((state) =>
		state.profile.get('selectedCompany') ? state.profile.get('selectedCompany').toJS() : null
	)
	const itemsRelease = useSelector((state) =>
		state.deliveryOrder.get('items') ? state.deliveryOrder.get('items').toJS() : []
	)
	const partnership = useSelector((state) =>
		state.suppliers.get('partnership') ? state.suppliers.get('partnership').toJS() : null
	)
	const IsLoadingdeliveryOrder = useSelector((state) => getIsLoadingDeliveryOrdenItems(state))
	const isLoadingSavingMaterial = useSelector((state) => state.deliveryOrder.get('IsSavingLoadingItem'))
	const isSavingDeliveryOrder = useSelector((state) => state.deliveryOrder.get('isSavingDeliveryOrder'))
	const isDonwloadingReport = useSelector((state) => state.deliveryOrders.get('isDonwloadingReport'))

	let dataCompany = deliveryOrder ? deliveryOrder.relesase : ''

	const uploadFilesDeliveryOrden = (file, folderName) => {
		const id = deliveryOrder.delivery.id
		return dispatch(uploadFilesQualityCertificates(file, id, folderName, true))
	}

	const removeFiles = (file, folderName) => {
		const id = deliveryOrder.delivery.id
		return dispatch(removeFilesQualityCertificates(file, id, folderName, true))
	}

	const onDownloadUploadFile = (fileName, folderName, fileExtension) => {
		const id = deliveryOrder.delivery.id
		return dispatch(downloadUploadFiles(id, folderName, fileName, fileExtension))

	}
	const hasIssuesInMaterial = (item) => item.issues.length > 0;
	const hasErrorInMaterials = () => {
		if (IsNullOrEmpty(materials))
			return true
		return materials.some(hasIssuesInMaterial)

	}
	// useEffect(() => {
	// 	dispatch(loadRelease()).finally(() => SetIsSaving(false))
	// }, [dispatch])

	const [state, setEstate] = useState({
		activeTab: 0,
		openAddMaterialModal: false,
		openRemoveMaterialModal: false,
		//Toaster state data
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		additionalToasterData: null,
	})

	const onFilterItemRElease = (items) => {
		return items
	}
	let hasFilesAdded = (deliveryOrder && deliveryOrder.delivery && deliveryOrder.delivery.billsFiles && deliveryOrder.delivery.qualityCertificateFiles && (deliveryOrder.delivery.billsFiles.length === 0 || deliveryOrder.delivery.qualityCertificateFiles.length === 0))
	let isDisabledBtnClosedAndPrint = (hasFilesAdded || hasErrorInMaterials())

	const filter = onFilterItemRElease(itemsRelease)
	const codes = filter ? [...new Set(filter.map((item) => item.material))] : []
	const filterorderBuy = filter ? (code === false ? filter : filter.filter((x) => x.material === code)) : []
	const orderBuy = filterorderBuy ? [...new Set(filterorderBuy.map((item) => item.orderBuy))] : []
	const filterposition = filter ? (orderBy === false ? filter : filter.filter((x) => x.orderBuy === orderBy && x.material === code)) : []
	const position = filterposition ? [...new Set(filterposition.map((item) => item.position))] : []
	let isUserFromWarehouseRole = canBeOperationDeliveryWarehouse(userRolesByCompany)

	const getItem = () => {
		if (code && orderBy && positionValue) {
			return filter.filter(
				(x) => x.solpedNumber === code && x.orderBuy === orderBy && x.position === parseInt(positionValue)
			)
		}
		return []
	}

	const handleChange = (event, newValue) => {
		setSelectedTab(newValue)
	}

	const onCloseModal = (modalName) => {
		setEstate((prev) => ({ ...prev, item: null, [modalName]: false }))
	}

	const onAddMaterial = (material, valueSuplier) => {
		//SetIsSaving(true)
		dispatch(addMaterialToDeliveryOrder(material, valueSuplier)).finally(() => onCloseModal('openAddMaterialModal'))
	}
	const onUpdateMaterial = (material, id) => {
		dispatch(updateMaterialFromDeliveryOrder(material, id)).finally(() => onCloseModal('openAddMaterialModal'))
	}
	const onOpenModal = (modalName, itemValue) => {
		setEstate((prev) => ({ ...prev, itemValue, [modalName]: true }))
	}

	const onUpdateProperty = (material, id) => {
		return dispatch(updateMaterialFromDeliveryOrder(material, id))
	}

	const removeMaterial = () => {
		dispatch(removeMaterialFromDeliveryOrder(state.itemValue.id)).finally(() =>
			onCloseModal('openRemoveMaterialModal')
		)
	}

	const closeAndPrintDeliveryOrder = (arrivalData) => {
		let deliveryOrderToken = deliveryOrder.delivery.id

		return dispatch(closeAndPrintDeliveryOrderOperation(arrivalData, deliveryOrderToken))
			.then(() => {
				props.history.push(`/planning/deliveryOrderFersa/${deliveryOrderToken}`)
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				if (error) {
					let errors = error.split('|')
					if (errors.length > 1) {
						setEstate(showWarningToaster(errors[0]))
					} else setEstate(showErrorToaster(error))
					return Promise.reject()
				} else {
					setEstate(showErrorToaster())
					return Promise.reject()
				}
			})
	}

	const checkAvailability = (arrivalData) => {
		let deliveryOrderToken = deliveryOrder.delivery.id
		return dispatch(checkScheduleAvailability(arrivalData, deliveryOrderToken))
			.then((response) => {
				return Promise.resolve(response)
			})
			.catch((error) => {
				setEstate(showErrorToaster(error))
				return Promise.reject()
			})
	}

	if (IsLoadingdeliveryOrder || isLoadingSupplier || isSaving || isLoadingReleaseUseEffect) {
		return (
			<Card>
				<LoadingPage />
			</Card>
		)
	} else
		return (
			<Card>
				{deliveryOrder != null && (
					<>
						<LayoutHeaderWorkFlow
							{...props}
							company={newCompanyDataFersa(company, dataCompany)}
							supplier={supplier}
							workFlow={deliveryOrder ? deliveryOrder.delivery : []}
							title={<Translate id='deliveryOrderModule.deliveryOrder' />}
						/>
						<CardContent>
							<Divider />
							<AppBar position='static' color='default'>
								<Tabs
									indicatorColor='secondary'
									textColor='secondary'
									className={classes.styleTab}
									value={selectdTab}
									onChange={handleChange}
								>
									<Tab label={<Translate id='common.information' />} />
									<Tab label={<Translate id='deliveryOrderModule.qualityCertificates' />} />
									<Tab label={<Translate id='deliveryOrderModule.bills' />} />
									{/* {deliveryOrder && <Tab label={<Translate id='deliveryOrderModule.qualityCertificates' />} />} */}
									{/* {partnership.is_supplier_to_add_carbon_foot_print && 
                                                    <Tab label={<Translate id="deliveryOrderModule.carbonFootprint.titleTransport" />} />} */}
								</Tabs>
							</AppBar>
							{selectdTab === 0 && deliveryOrder && (
								<MaterialsTable
									title={
										<>
											<Typography variant='caption'>
												<AlertIcon color='primary' />
												{<Translate id='deliveryOrderModule.materialsNote' />}
											</Typography>
											{hasFilesAdded && <Typography variant='caption'>
												<AlertIcon color='primary' />
												<Translate id='deliveryOrderModule.emptyFiles' />
											</Typography>}

											{hasErrorInMaterials() && <Typography variant='caption'>
												<AlertIcon color='primary' />
												<Translate id='deliveryOrderModule.errorsInDeliveryItems' />
											</Typography>}
										</>
									}
									isDraft
									onAddClick={onOpenModal}
									onMenuOptionItemClick={onOpenModal}
									onCloseAndPrint={() => 'openCloseAndPrintModal'}
									data={materials ? materials : []}
									companyToken={company.token}
									totalOrderItems={deliveryOrder ? deliveryOrder.total_order_items : 0}
									onUpdateProperty={onUpdateProperty}
									roles={userRolesByCompany}
									user={user}
									isDisabledBtnClosedAndPrint={isDisabledBtnClosedAndPrint}
								/>
							)}
							{selectdTab === 1 && (
								<>
									<Grid container>
										<Grid item xs={12}>
											<Typography style={{ marginTop: "15px" }}>Por favor agrega los archivos de los certificados de calidad</Typography>
											{(canBeModulePlaneationSupplier(userRolesByCompany) ||
												IsUserSupplierAdministrator(user)) && (
													<>

														<Evidence
															isDisabled={isUserFromWarehouseRole}
															// baseApi={process.env.REACT_APP_SIP_PLANNIG_FERSA}
															// removeFiles={(file) => removeFiles(file, "qualityCertificateFiles")}
															uploadFiles={(file) => uploadFilesDeliveryOrden(file, "qualityCertificateFiles")}
															// files={deliveryOrder?.delivery?.qualityCertificateFiles}
															// files={(deliveryOrder && deliveryOrder.delivery && deliveryOrder.delivery.qualityCertificateFiles) ? deliveryOrder.delivery.qualityCertificateFiles : []}
															files={[]}
															successToasterDisplayed={true}
															acceptedFiles={['pdf', 'excel']}
														/>
														<FilesComponent
															files={(deliveryOrder && deliveryOrder.delivery && deliveryOrder.delivery.qualityCertificateFiles) ? deliveryOrder.delivery.qualityCertificateFiles : []}
															// onDownloadFile={(event) => onDownloadUploadFile(event.target.id, )}
															onDeleteFile={removeFiles}
															folderName={"qualityCertificateFiles"}
															onDownloadFile={onDownloadUploadFile}
															isSaving={isDonwloadingReport || isUserFromWarehouseRole}

														/>
													</>
												)}
											<br />

										</Grid>

									</Grid>
								</>
							)}
							{selectdTab === 2 && (
								<Grid container>
									<Grid item xs={12}>
										<Typography style={{ marginTop: "15px" }}>Por favor agrega los archivos de las facturas</Typography>
										{(canBeModulePlaneationSupplier(userRolesByCompany) ||
											IsUserSupplierAdministrator(user)) && (
												<>
													<Evidence
														isDisabled={isUserFromWarehouseRole}
														// baseApi={process.env.REACT_APP_SIP_PLANNIG_FERSA}
														// removeFiles={(file) => removeFiles(file, "billsFiles")}
														uploadFiles={(file) => uploadFilesDeliveryOrden(file, "billsFiles")}
														// files={(deliveryOrder && deliveryOrder.delivery && deliveryOrder.delivery.billsFiles) ? deliveryOrder.delivery.billsFiles : []}
														files={[]}
														successToasterDisplayed={true}
														acceptedFiles={['pdf', 'excel']}

													// onClickFile={(event) => onDownloadUploadFile(event.target.id, "billsFiles")}
													// isClickable = {true}
													/>
													<FilesComponent
														files={(deliveryOrder && deliveryOrder.delivery && deliveryOrder.delivery.billsFiles) ? deliveryOrder.delivery.billsFiles : []}
														// onDownloadFile={(event) => onDownloadUploadFile(event.target.id, )}
														onDeleteFile={removeFiles}
														folderName={"billsFiles"}
														onDownloadFile={onDownloadUploadFile}
														isSaving={isDonwloadingReport || isUserFromWarehouseRole}

													/>
												</>
											)}

										<br />
									</Grid>
								</Grid>
							)}
						</CardContent>

						<AddMaterialModal
							open={state.openAddMaterialModal}
							title={state.itemValue ? 'deliveryOrderModule.updateMaterial' : 'deliveryOrderModule.addMaterial'}
							addItem={onAddMaterial}
							onUpdateMaterial={onUpdateMaterial}
							onClose={() => onCloseModal('openAddMaterialModal')}
							codes={codes}
							purchaseOrders={orderBuy}
							positions={position}
							// getPurchaseOrders={props.getPurchaseOrders}
							// getPositions={props.getPositions}
							isSaving={isLoadingSavingMaterial}
							item={getItem().length === 1 ? getItem()[0] : []}
							setCode={setCode}
							setOrderBy={setOrderBy}
							setPositionValue={setPositionValue}
							// itemsRelease={itemsRelease}
							value={state.itemValue}
							supplier={supplier}
						/>

						<DeleteConfirmationModal
							open={state.openRemoveMaterialModal}
							title='deliveryOrderModule.removeMaterial'
							onRemoveMaterial={removeMaterial}
							onClose={() => onCloseModal('openRemoveMaterialModal')}
							isSaving={isLoadingSavingMaterial}
							item={state.itemValue}
						/>
						<CloseAndPrintModal
							open={state.openCloseAndPrintModal}
							title='deliveryOrderModule.closeConfirmationMessage'
							onCloseAndPrint={closeAndPrintDeliveryOrder}
							onCheckAvailability={checkAvailability}
							onClose={() => onCloseModal('openCloseAndPrintModal')}
							isSaving={isSavingDeliveryOrder}
							partnership={partnership}
						/>

						<Toaster
							open={state.showToaster}
							message={state.toasterMessage? <Translate id={state.toasterMessage}/>:""}
							variant={state.toasterVariant}
							onClose={() => {
								setEstate({
									open: false,
									message: '',
									variant: '',
									showToaster: false,
									toasterMessage: null,
									toasterVariant: '',
								})
							}}
						/>
					</>
				)}
				{(deliveryOrder === null
					// || itemsRelease.length === 0
				) && (
						<React.Fragment>
							<CardContent>
								<Grid container spacing={24} justify='center'>
									<Grid item xs={12}>
										<Typography align='center'>
											<NoReleaseIcon color='error' />
											<Translate id='deliveryOrderModule.error.doesNotExistReleasePlan' />
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</React.Fragment>
					)}
			</Card>
		)
}

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	inline: {
		display: 'inline',
	},
	logoCompany: {
		maxHeight: 100,
	},
	logoSupplier: {
		marginLeft: 80,
		maxHeight: 80,
	},
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Create)))
