import React, { useState } from 'react';
import PropTypes from 'prop-types';

/** Custom components import section */
import DataTable from '../../common/DataTable';
import ServiceOrderDetail from '../serviceOrders/components/ServiceOrderDetail';

/** Resources import section */
import serviceOrderTableConfiguration from '../serviceOrders/serviceHelpers/TableConfiguration';
import { renderCustomCellItem } from '../serviceOrders/serviceHelpers/TableHelpers';

/**
 * Presentional component to show the service orders associated to some payment reqeuest.
 *
 * @param {object} props
 */
const PaymentRequestServices = (props) => {
	const { serviceOrders } = props;

	/** Defines local state */
	const [serviceOrderDetailPanelOptions, setServiceOrderDetailPanelOptions] = useState({
		opened: false,
		onCloseButtonClick: null,
		token: ''
	});

	/**
	 * Update state in order to show the lateral panel with service order detail.
	 * @param {Object} item
	 * @param {Object} event
	 */
	const openServiceOrderDetail = (item, event) => {
		const { tagName } = event.target;
		if (tagName !== 'INPUT') {
			const { token } = item;
			setServiceOrderDetailPanelOptions({ opened: true, onCloseButtonClick: closeServiceOrderDetail, token });
		}
	};

	/**
	 * Update state in order to close the lateral panel with service order detail
	 */
	const closeServiceOrderDetail = () => {
		setServiceOrderDetailPanelOptions({ opened: false, token: '' });
	};

	return (
		<div>
			<DataTable
				configuration={tableConfiguration}
				data={serviceOrders}
				onRenderCellItem={renderCustomCellItem}
				onRowClick={openServiceOrderDetail}
			/>
			{serviceOrderDetailPanelOptions.opened && <ServiceOrderDetail {...serviceOrderDetailPanelOptions} />}
		</div>
	);
};

/**
 * Set the service orders table configuration
 */
const tableConfiguration = {
	columns: [
		...serviceOrderTableConfiguration
			.filter((f) => f.dataSource !== 'selectCheck')
			.map((item) => ({ ...item, filterEnabled: false }))
	]
};

PaymentRequestServices.propTypes = {
	serviceOrders: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

PaymentRequestServices.defaultProps = {
	serviceOrders: []
};

export default PaymentRequestServices;
