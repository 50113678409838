
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import {Translate} from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

/** section import components */
import DataTable from '../../../common/DataTable';

/**
 * Component ot render Information partialraw material not conformiti
 */
export default class RatingSupplierRatingPartial extends Component{

    /**
     * Create an instance RatingSupplierRatingPartial
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
    }

        /**
     * Onrender items deriver workflows
     */
    onRenderCellItem(dataSource, item) {
        if (dataSource === "delivery_order_rate") {
            return(<span>{item.delivery_order_rate} % </span>)
        }
        if(dataSource === "date_arrive_rate"){
            return(<span>{item.date_arrive_rate} % </span>)
        }
        if(dataSource === "quantity_rate"){
            return(<span>{item.quantity_rate} % </span>)
        }
        if(dataSource === "total_rating"){
            return(<span>{item.total_rating} % </span>)
        }
    }

    /**
     * Render
     */
    render() {
        return (
            <Paper> 
                <Divider />
                 {/* List from rating delivery order  */}
                 {this.props.ratings.length > 0 && <span><br />
                    <Typography gutterBottom variant="body2"><strong>{<Translate id="deliveryOrderModule.ratingOrder.ratingSupplierRating" />}</strong>  </Typography>
                    <DataTable
                        data={this.props.ratings}
                        configuration={TableRating}
                        onRenderCellItem={this.onRenderCellItem}
                    />
                </span>
                }

               {!this.props.canEvaluarOrder &&
                <List>
                        <ListItem>
                            <ListItemText
                            secondary={
                                <React.Fragment>
                                    {<Translate id="deliveryOrderModule.ratingOrder.ratingMessageCanEvaluateService"/>}
                                </React.Fragment>
                            } />
                        </ListItem>
                        <Divider />
                    </List>
                }

            </Paper>
        );
    }
}

const TableRating = {
    columns: [{
        header: '',
        dataSource: 'status_indicator',
    }, {
        header: <Translate id="deliveryOrderModule.ratingOrder.deliveryOrderRate" />,
        dataSource: 'delivery_order_rate'
    },{
        header: <Translate id="deliveryOrderModule.ratingOrder.dateArriveRate" />,
        dataSource: 'date_arrive_rate'
    }, {
        header: <Translate id="deliveryOrderModule.ratingOrder.quantityRate" />,
        dataSource: 'quantity_rate'
    }, {
        header: <Translate id="deliveryOrderModule.ratingOrder.totalRating" />,
        dataSource: 'total_rating'
    }]
}

/**
 * ratings deliver order
 */
RatingSupplierRatingPartial.propTypes = {
    ratings: PropTypes.array.isRequired,
    canEvaluarOrder:PropTypes.bool.isRequired
}