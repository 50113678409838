import React, { Component } from 'react';
import autoBind from 'auto-bind'
import PropTypes from 'prop-types'

/** Import imges */
import logoImbera from '../../resources/images/header-imbera.png'
import logoPTM from '../../resources/images/header-ptm.png'
import logoRepare from '../../resources/images/header-repare.png'
import logoAlpunto from '../../resources/images/header_alpunto.png'
import logoMetalic from '../../resources/images/header-metalicos.png'
import logoEOS from '../../resources/images/header-EOS.png'

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import {
    COMPANY_PTM, COMPANY_IDENTIFIER_IMBERA_REPARE, COMPANY_IDENTIFIER_TEQMA,
    COMPANY_IDENTIFIER_PACTO, COMPANY_IDENTIFIER_FERSA,
    COMPANY_IDENTIFIER_FABATSA, COMPANY_IDENTIFIER__METALLIC, COMPANY_MAQUINAS_EOS
} from '../../store/helpers/ResourcesHelper';

/**
 * Component to get header logos by company
 */
export default class HeaderLogosCompany extends Component {

    /**
 *Creates an instance of HeaderLogosCompany.
 * @param {*} props
 * @memberof HeaderLogosCompany
 */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
     * Method to get the header logo by company
     */
    getLogoByCompany() {
        const { companyIdentifier } = this.props;
        if (companyIdentifier) {
            switch (companyIdentifier) {
                case COMPANY_PTM:
                    return (<img className="logoheader-sip" src={logoPTM} alt="logoheader" />)
                case COMPANY_IDENTIFIER_IMBERA_REPARE:
                    return (<img className="logoheader-sip" src={logoRepare} alt="logoheader" />)
                case COMPANY_IDENTIFIER__METALLIC:
                    return (<img className="logoheader-sip" src={logoMetalic} alt="logoheader" />)
                case COMPANY_IDENTIFIER_TEQMA:
                case COMPANY_IDENTIFIER_PACTO:
                case COMPANY_IDENTIFIER_FERSA:
                case COMPANY_IDENTIFIER_FABATSA:
                    return (<img className="logoheader-sip" src={logoAlpunto} alt="logoheader" />)
                case COMPANY_MAQUINAS_EOS:
                    return (<img className="logoheader-sip" src={logoEOS} alt="logoheader" />)
                default:
                    return (<img className="logoheader-sip" src={logoImbera} alt="logoheader" />)
            }
        }
        else {
            return (<img className="logoheader-sip" src={logoImbera} alt="logoheader" />)
        }
    }

    /**
     * Render heade logo
     */
    render() {
        return (<div style={{ display: 'contents' }}>
            {this.getLogoByCompany()}
            <IconButton onClick={this.props.onClose} style={{ position: "absolute" }}>
                <ChevronLeftIcon />
            </IconButton>
        </div>
        )
    }
}

/**
 * FooterContainer
 */
HeaderLogosCompany.propTypes = {
    companyIdentifier: PropTypes.string
}
