import React from 'react'
import ListDocumentsComponentAdd from '../../common/ListDocumentsComponentAdd'

const GeneralFiles = ({ dataDetail }) => {
	return (
		<>
			<ListDocumentsComponentAdd title='common.packingList' documents={dataDetail.packingListAttachments} />

			<ListDocumentsComponentAdd
				title='common.certificateOfOrigin'
				documents={dataDetail.certificateOfOriginAttachments}
			/>
		</>
	)
}

export default GeneralFiles
