import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'

/** Materil */
import { Avatar, Card, CardContent, CardHeader, Typography, Grid, Fab, Tooltip } from '@material-ui/core'
import { CreditCard } from '@material-ui/icons'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Components */
import DataTable from '../common/DataTable'
import SupplierSelector from '../common/SupplierSelector'
import * as selectors from '../../store/sparePartPriceChange/sparePartPriceChangeSelector'

import { getPricesFromSAP, donwlodPricesFromSAP } from '../../store/sparePartPriceChange/sparePartPriceChangeAction'
import LoadingPage from '../common/LoadingPage'

const IndexSAP = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	const isLoadingSAP = useSelector((state) => state.sparePartPriceChange.get('isLoadingSAP'))
	const isDownloadPrices = useSelector((state) => state.sparePartPriceChange.get('isDownloadPrices'))
	const sparePartsPrices = useSelector((state) => selectors.getPricesList(state))

	const onSelectedChange = (option) => {
		if (option) {
			dispatch(getPricesFromSAP(option.code))
		}
	}

	const onDownloadReport = () => {
		dispatch(donwlodPricesFromSAP())
	}

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<CreditCard color='primary' />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						<Tooltip title='Descargar Reporte'>
							<Fab
								style={{ marginTop: '1rem' }}
								size='small'
								color='primary'
								className={classes.margin}
								onClick={onDownloadReport}
								disabled={isDownloadPrices}
							>
								<DownloadIcon />
							</Fab>
						</Tooltip>

						<Grid item xs={12} style={{ marginTop: '1rem', width: '300px' }}>
							<SupplierSelector onSupplierSelected={(supplier) => onSelectedChange(supplier)} />
						</Grid>
					</div>
				}
				title={
					<Typography variant='button'>
						<Translate id='menu.priceChangeRequestPTM' />{' '}
					</Typography>
				}
				subheader={
					<div>
						<Translate id='common.showing' />
						&nbsp;
					</div>
				}
			/>
			<CardContent>
				{isLoadingSAP ? (
					<LoadingPage />
				) : (
					<DataTable data={sparePartsPrices} configuration={TableConfiguration()} />
				)}
			</CardContent>
		</Card>
	)
}
function TableConfiguration() {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: 'Material',
			dataSource: 'code',
		},
		{
			header: 'Descripción Material',
			dataSource: 'description',
		},
		{
			header: 'UMB',
			dataSource: 'umb',
		},
		{
			header: 'Precio',
			dataSource: 'price',
		},
		{
			header: 'Moneda',
			dataSource: 'currency',
		},
		{
			header: 'Comentarios',
			dataSource: 'comments',
		},
	]

	return { columns }
}

const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
	},
	margin: {
		margin: theme.spacing.unit,
	},
})
export default withStyles(styles, { withTheme: true })(IndexSAP)
