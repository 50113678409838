/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { CardContent, withStyles } from "@material-ui/core";
import { withLocalize } from 'react-localize-redux';
import { Card,StepIcon , StepConnector} from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';

/**Import components section*/
import GeneralInformation from './GeneralInformation';
import Questionnaire from '../questionnaire/Questionnaire';
import ContainerResult from '../results/ContainerResult'
import ActionsPlanContent from '../actionsPlan/ActionsPlanContent'
import FindingsContent from '../findings/FindingsContent'
import Summary from '../components/Summary'

/**Import resources section*/

import {SustainabilityStatus} from '../../../store/helpers/AppConstants'
import {getTotalWorkDetail} from '../../../store/sustainability2/SustainabilityResults'
import {canBeResponsible} from '../../../store/helpers/SustainabilityHelper'

/**Import actions section */
const StepperHeader = (props) => {
  const {
    userProfile,
    userRolesByCompany,
    sustainability,
    updateProperty,
    updatePropertyDetail,
    updatePropertyQuestion,
    translate,
    updatePropertyActionPlan,
    isSaving,
    totalWorkerDetail,
    canBeActiveResult,
    downloadQuestionnarie,
  } = props;

  let canBeEditResults = (canBeResponsible(sustainability, userRolesByCompany, userProfile) && sustainability.Status === SustainabilityStatus.RESPONSE_SUPPLIER);

  const classes = styles();
  const [activeStep, setActiveStep] = React.useState(sustainability.ActiveStep);
  const [completed] = React.useState(new Set());
  const [skipped] = React.useState(new Set());


  let totalWorkDetail = getTotalWorkDetail(sustainability, totalWorkerDetail);

  /**
   * Function to get steps
   */
  const getSteps = () => {
    let steps = []
    steps = [translate('sustainability.steps.generalInformation'), 
    translate('sustainability.steps.questionnaire'),
    translate('sustainability.steps.results'),
    translate('sustainability.steps.actionPlant'),
    translate('sustainability.steps.audit'),
    translate('sustainability.steps.clousureFindings'),
    translate('sustainability.steps.summary')];
    return steps; 
  }
  
  const handleStep = (step) => () => {
    setActiveStep(step);
  };


  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const isStepComplete = (step) => {
    return completed.has(step);
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <GeneralInformation 
            userProfile ={userProfile}
            userRolesByCompany={userRolesByCompany}
            sustainability={sustainability}
            updateProperty={updateProperty}
            updatePropertyDetail={updatePropertyDetail}
            totalWorkerDetail={totalWorkerDetail}
          />;
      case 1:
        return <Questionnaire
            activeStep={activeStep}
            questionnaire={sustainability.Questionnaire}
            sustainability={sustainability}
            updatePropertyQuestion={updatePropertyQuestion}
            userRolesByCompany={userRolesByCompany}
            userProfile={userProfile}
            downloadQuestionnarie={downloadQuestionnarie}

        />;
      case 2:
        return <ContainerResult 
            questionnaire={sustainability.Questionnaire}
            totalWorkDetail={totalWorkDetail}
            updatePropertyQuestion={updatePropertyQuestion}
            canBeEditResults={canBeEditResults}
            sustainability={sustainability}
        />;
      case 3:
        return <ActionsPlanContent 
          actionPlans={sustainability.ActionPlans}
          sustainability={sustainability}
          translate={translate}
          updatePropertyActionPlan={updatePropertyActionPlan}
          userRolesByCompany={userRolesByCompany}
          userProfile={userProfile}
          isSaving={isSaving}
        />;
      case 4:
        return <Questionnaire
          activeStep={activeStep}
          questionnaire={sustainability.Questionnaire}
          sustainability={sustainability}
          updatePropertyQuestion={updatePropertyQuestion}
          userRolesByCompany={userRolesByCompany}
          userProfile={userProfile}
          isAudit={true}
        />;
      case 5:
        return <FindingsContent 
          closeFindings={sustainability.CloseFindings}
          sustainability={sustainability}
          translate={translate}
          updatePropertyActionPlan={updatePropertyActionPlan}
          userRolesByCompany={userRolesByCompany}
          userProfile={userProfile}
          isSaving={isSaving}
        />;
        case 6:
          return <Summary 
            sustainability={sustainability}
            updateProperty={updateProperty}
            questionnaire={sustainability.Questionnaire}
            totalWorkDetail={totalWorkDetail}
            isAudit={true}
            canBeActiveResult={canBeActiveResult}
          />;
      default:
        return 'Paso desconocido';
    }
  }

  /**
   * Action to get active steper
   * @returns 
   */
  const getSteperActived =() =>{
    switch(sustainability.Status)
    {
      case SustainabilityStatus.SEND_SUPPLIER:
        return <> <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorLibConnector />} >
              {getSteps().map((label, index) => {
                  const stepProps = {};
                if (isStepSkipped(index)) { stepProps.completed = false}
                return (
                  <Step key={label} >
                    {(index === 0 || index === 1) ? <StepButton 
                      onClick={handleStep(index)}
                      completed={isStepComplete(index)}>
                      {label} </StepButton> :
                    <StepLabel StepIconComponent={IconComponent}>{label}</StepLabel>
                    }</Step>);
              })}
          </Stepper> 
        </>
        case  SustainabilityStatus.RESPONSE_SUPPLIER: 
            if(canBeEditResults)
              return<> <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorLibConnector />} >
                  {getSteps().map((label, index) => {
                      const stepProps = {};
                    if (isStepSkipped(index)) { stepProps.completed = false}
                    return (
                      <Step key={label} >
                        {(index === 0 || index === 1 || index === 2) ? <StepButton 
                          onClick={handleStep(index)}
                          completed={isStepComplete(index)}>
                          {label} </StepButton> :
                        <StepLabel StepIconComponent={IconComponent}>{label}</StepLabel>
                        }</Step>);
                  })}
              </Stepper> 
            </>
            else
              return <> <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorLibConnector />} >
              {getSteps().map((label, index) => {
                  const stepProps = {};
                if (isStepSkipped(index)) { stepProps.completed = false}
                return (
                  <Step key={label} >
                    {(index === 0 || index === 1) ? <StepButton 
                      onClick={handleStep(index)}
                      completed={isStepComplete(index)}>
                      {label} </StepButton> :
                    <StepLabel StepIconComponent={IconComponent}>{label}</StepLabel>
                    }</Step>);
              })}
          </Stepper> 
        </>
      case  SustainabilityStatus.PUBLISH_RESULTS: 
          return <> <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorLibConnector />} >
                {getSteps().map((label, index) => {
                    const stepProps = {};
                  if (isStepSkipped(index)) { stepProps.completed = false}
                  return (
                    <Step key={label} >
                      {(index === 0 || index === 1 || index === 2) ? <StepButton 
                        onClick={handleStep(index)}
                        completed={isStepComplete(index)}>
                        {label} </StepButton> :
                      <StepLabel StepIconComponent={IconComponent}>{label}</StepLabel>
                      }</Step>);
                })}
            </Stepper> 
          </>
          case  SustainabilityStatus.INITIAL_ACTIONS_PLAN:
          case  SustainabilityStatus.REEVALUATE_QUETIOONAIRE:
          case SustainabilityStatus.IN_PROCEES_ACTIONS_PLAN:
          case  SustainabilityStatus.RESPONSE_ACTIONS_SUPPLIER:
              return <> <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorLibConnector />} >
                    {getSteps().map((label, index) => {
                        const stepProps = {};
                      if (isStepSkipped(index)) { stepProps.completed = false}
                      return (
                        <Step key={label} >
                          {(index === 0 || index === 1 || index === 2 || index === 3) ? <StepButton 
                            onClick={handleStep(index)}
                            completed={isStepComplete(index)}>
                            {label} </StepButton> :
                          <StepLabel StepIconComponent={IconComponent}>{label}</StepLabel>
                          }</Step>);
                    })}
                </Stepper> 
              </>
          case SustainabilityStatus.PROGRAM_AUDIT :
          case SustainabilityStatus.AUDIT_IN_PROCESS :
          case SustainabilityStatus.AUDIT_RESPONSE :
            return <> <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorLibConnector />} >
                  {getSteps().map((label, index) => {
                      const stepProps = {};
                    if (isStepSkipped(index)) { stepProps.completed = false}
                    return (
                      <Step key={label} >
                        {(index === 0 || index === 1 || index === 2 || index === 3 || index === 4) ? <StepButton 
                          onClick={handleStep(index)}
                          completed={isStepComplete(index)}>
                          {label} </StepButton> :
                        <StepLabel StepIconComponent={IconComponent}>{label}</StepLabel>
                        }</Step>);
                  })}
              </Stepper> 
            </>
            case SustainabilityStatus.GENERATED_FINDINGS :
            case SustainabilityStatus.IN_PROCESS_FINFINGS :
            case SustainabilityStatus.FIDDINGS_RESPONSE :
            case SustainabilityStatus.REEVALUATE_AUDIT :
                return <> <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorLibConnector />} >
                      {getSteps().map((label, index) => {
                          const stepProps = {};
                        if (isStepSkipped(index)) { stepProps.completed = false}
                        return (
                          <Step key={label} >
                            {(index === 0 || index === 1 || index === 2 || index === 3 || index === 4 || index === 5) ? <StepButton 
                              onClick={handleStep(index)}
                              completed={isStepComplete(index)}>
                              {label} </StepButton> :
                            <StepLabel StepIconComponent={IconComponent}>{label}</StepLabel>
                            }</Step>);
                      })}
                  </Stepper> 
                </>
      default:
        return  <Stepper alternativeLabel nonLinear activeStep={activeStep}
                connector={<ColorLibConnector />}>
                {getSteps().map((label, index) => {
                    const stepProps = {};
                if (isStepSkipped(index)) {stepProps.completed = false; }
                return (
                  <Step key={label}>
                    <StepButton
                      onClick={handleStep(index)}
                      completed={isStepComplete(index)}>
                      {label}
                    </StepButton>
                  </Step>
                );
            })}
       </Stepper>
    }
  }

  return (
    <Card>
      <div className={classes.root}>
        {getSteperActived()}
        <CardContent>
          {getStepContent(activeStep)}
        </CardContent>
      </div>
    </Card>
  )
};

const IconComponent = withStyles((theme) => ({
  root: {
      color: "#EBEEF7",
      '&$active': {
        backgroundColor: "#6369D7",
          color: "#6369D7",

      },
      '&$completed': {
        backgroundColor: "#6369D7",
          color: "#6369D7"
      }
  },
  active: {},
  completed: {}
}))(StepIcon);


const ColorLibConnector = withStyles((theme) => ({
  alternativeLabel: {
      top: 15
  },
  active: {
      '& $line': {
          backgroundColor: "#6369D7",
          color:  "#6369D7"
      }
  },
  completed: {
      '& $line': {
          backgroundColor: "#464A97",
          color:  "#6369D7"
      }
  },
  line: {
      backgroundColor: "#EBEEF7",
      color:  "#6369D7"
  }
}))(StepConnector);

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: 0,
    
  },
  backButton: {
    marginRight: 0,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: 0,
    marginBottom: 0,
  }
});

StepperHeader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

StepperHeader.defaultProps = {
  isLoading: false,
};

export default withRouter(withLocalize(withStyles(styles, { withTheme: true })(StepperHeader)));