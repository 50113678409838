import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/**Import MaterialUi section */
import { Grid, Button, Avatar, Typography, TextField, Switch, FormGroup } from '@material-ui/core'
import BiddingIcon from '@material-ui/icons/ViewCarousel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** section import components */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper.js'

/**Import components section */
import Formato_Cargar_Ordenes from '../../../resources/files/Formato_Cargar_Ordenes.xlsx'
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import PartNumberSelector from '../../common/PartNumberSelector'
import Evidence from '../../common/Evidence'

const PanelAddDetails = (props) => {
	/**props section */
	const { onCloseButtonClick, classes, onSaveButtonClick, isSaving, onImportPurchaseOrders } = props

	const [selectedValue, setSelectedValue] = React.useState(false)
	const [attachments, setAttachments] = useState([])
	const [addPurchaseOrder, setAddPurchaseOrder] = useState({
		purchaseOrder: '',
		quality: '',
		partNumberSelected: '',
	})

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setAddPurchaseOrder({
			...addPurchaseOrder,
			[name]: value,
		})
	}

	const onPartNumberSelected = (partNumber) => {
		setAddPurchaseOrder({ ...addPurchaseOrder, partNumberSelected: partNumber })
	}

	const canBeSaved = () => {
		if (selectedValue) {
			if (attachments.length === 0) return false
			else return true
		} else {
			if (IsNullOrEmpty(addPurchaseOrder.purchaseOrder)) return false
			if (IsNullOrEmpty(addPurchaseOrder.partNumberSelected)) return false
			else return true
		}
	}

	const onSave = () => {
		if (selectedValue) {
			if (props.onImportPurchaseOrders) {
				onImportPurchaseOrders(attachments)
			}
		} else {
			if (props.onSaveButtonClick) {
				onSaveButtonClick(addPurchaseOrder)
			}
		}
	}

	const handleChange = (event) => {
		setSelectedValue(event.target.checked)
	}

	const uploadFiles = (files) => {
		let filesList = attachments.concat(files[0])
		if (files.length > 0) {
			for (var index = 1; index < files.length; index++) {
				filesList.push(files[index])
			}
		}
		setAttachments(filesList)
		return Promise.resolve('Ok')
	}

	const removeFiles = (fileName) => {
		let newList = attachments.filter((files) => {
			return files.name !== fileName
		})
		setAttachments(newList)
		return Promise.resolve('Ok')
	}

	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={'Agregar orden de compra'}
				icon={
					<Avatar className={classes.avatar}>
						<BiddingIcon />
					</Avatar>
				}
				onCloseButtonClick={onCloseButtonClick}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid container>
						<Grid item xs={12}>
							<Typography color='primary'>{'Selecciona la opción para agregar'}</Typography>
							<FormGroup>
								<FormControlLabel
									control={<Switch checked={selectedValue} onChange={handleChange} />}
									label='Importar desde un excel'
								/>
							</FormGroup>
						</Grid>
						{selectedValue && (
							<Grid container>
								<Grid item xs={12}>
									<small className='text-muted'>
										<DownloadIcon
											fontSize='small'
											color='primary'
											style={{ margin: '5px', marginBottom: '8px' }}
										/>
										{
											<a href={Formato_Cargar_Ordenes}>
												{'Descargar plantilla para importar información'}
											</a>
										}
									</small>
								</Grid>
								<Grid item xs={12}>
									<Evidence
										files={attachments}
										uploadFiles={uploadFiles}
										removeFiles={removeFiles}
										isDisabled={false}
										acceptedFiles={['Excel']}
										maxFiles={1}
										disabledLink
										successToasterDisplayed={false}
									/>
								</Grid>
							</Grid>
						)}

						{!selectedValue && (
							<Grid container>
								<Grid item xs={12}>
									<Typography color='primary'>{'Orden de compra'}</Typography>
									<TextField
										fullWidth
										label={'Orden de compra'}
										className={classes.textField}
										defaultValue={addPurchaseOrder.purchaseOrder}
										onChange={onPropertyChange}
										name='purchaseOrder'
										margin='normal'
										variant='outlined'
									/>
								</Grid>
								<Grid item xs={12}>
									<br />
									<Typography color='primary'>
										<Translate id='common.partNumber' /> *
									</Typography>
									<PartNumberSelector
										className={classes.textField}
										onPartNumberSelected={(partNumber) => {
											onPartNumberSelected(partNumber)
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<br />
									<Typography color='primary'>{'Descripción número de parte'}</Typography>
									<TextField
										className={classes.textField}
										disabled={true}
										id='standard-read-only-input'
										value={
											addPurchaseOrder.partNumberSelected
												? addPurchaseOrder.partNumberSelected.description
												: ''
										}
										margin='normal'
										variant='outlined'
										name='partNumberDescription'
										onChange={onPropertyChange}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<br />
									<Typography color='primary'>{'Cantidad'}</Typography>
									<TextField
										fullWidth
										className={classes.textField}
										defaultValue={addPurchaseOrder.quality}
										onChange={onPropertyChange}
										type={'number'}
										name='quality'
										margin='normal'
										variant='outlined'
									/>
								</Grid>
							</Grid>
						)}
					</Grid>
				</div>
				<Grid container>
					<Grid item xs={12} className={classes.containerBtn}>
						<Button
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='outlined'
							className={classes.saveButton}
							disabled={!canBeSaved() || isSaving}
							onClick={onSave}
						>
							{<Translate id='common.save' />}
						</Button>
					</Grid>
				</Grid>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	expansionPanelSummary: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputProps: {
		height: 6,
	},
	saveButton: {
		bottom: 0,
	},
})

PanelAddDetails.propTypes = {
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onSaveItem: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
	/**
	 * Validate if template is correctly
	 */
	onCloseButtonClick: propTypes.func,
}

PanelAddDetails.defaultProps = {
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	onSaveItem: () => console.warn('Callback [onSaveItem] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelAddDetails))
