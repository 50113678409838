///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'
import queryString from 'query-string'

/** Material-UI imports section */
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import FindInPage from '@material-ui/icons/FindInPage'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Fab from '@material-ui/core/Fab'
import { Typography, Tooltip } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import ErrorIcon from '@material-ui/icons/Error'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import { COLOR_SUSTAINABILITY_PLUM, COLOR_SUSTAINABILITY_ROSE } from '../../store/helpers/StatusColorConstants'
import SearchInput from '../common/SearchInput/SearchInput'
import PanelAddAudit from './components/PanelAddAudit'
import DataTable from '../common/DataTable'
import Toaster from '../common/Toaster'
import LoadingPage from '../common/LoadingPage'
import {
	addAudit,
	ADD_AUDIT,
	loadAllAuditsFromBackEnd,
	getAuditCount,
} from '../../store/qualityaudit/QualityAuditActions'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper.js'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canUserCreateReportModuleQuality } from '../../store/helpers/RolesHelper'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import {
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_WARNING,
	COLOR_STATUS_INFO,
} from './../../store/helpers/StatusColorConstants'

/**
 * Component load list
 * @param {*} props
 */
const Index = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()
	const queryParams = queryString.parse(location.search)

	const isLoadingList = useSelector((state) => state.qualityAudit.get('isLoading'))
	const isSaving = useSelector((state) => state.qualityAudit.get('isSaving'))
	const identifier = useSelector((state) => state.qualityAudit.get('identifier'))
	const auditCount = useSelector((state) => state.qualityAudit.get('auditCount'))
	const translate = useSelector((state) => getTranslate(state.localize))
	const auditList = useSelector((state) =>
		state.qualityAudit.get('auditList') ? converListToArray(state.qualityAudit.get('auditList')) : []
	)
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const user = useSelector((state) =>
		state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	)
	const [openLateralPanel, setOpenLateralPanel] = useState(false)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})
	/**Use effect section */

	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(
			loadAllAuditsFromBackEnd(
				queryParams,
				translate,
				user.profile.company_code !== undefined ? user.profile.company_code : null
			)
		)
		dispatch(getAuditCount())
	}, [dispatch, location.search])

	/**
	 * Function to open side panel
	 */
	const onOpenLateralPanel = () => {
		setOpenLateralPanel(!openLateralPanel)
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onRowClick = (audit, event) => {
		let tagName = event.target.tagName
		if (tagName !== 'svg' && tagName !== 'A') {
			props.history.push(`/qualityaudit/${audit.token}`)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	const getCardContent = () => {
		if (isLoadingList === true) {
			return <LoadingPage />
		} else if (auditList === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={auditList}
					configuration={TableConfigurationAudit}
					onRenderCellItem={onRenderCellItem}
					onRowClick={onRowClick}
					onChangeSortCriteria={onChangeSortCriteria}
					isIndex
					showFilters
					totalRows={auditCount}
					sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'FormatedFolio'}
					sortAscending={
						queryParams && queryParams.sortDirection
							? queryParams.sortDirection === 'desc'
								? false
								: true
							: false
					}
					page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
					rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
					onChangePage={(newPage, newRowsPerPage) => {
						onChangePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'full_supplier') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item.full_supplier}
				</Typography>
			)
		}
		if (dataSource === 'create_by') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item.create_by}
				</Typography>
			)
		}
		if (dataSource === 'supplierStatus') {
			switch (item.supplierStatus) {
				case 1:
					return (
						<Tooltip title={<Translate id='common.accepted' />}>
							<CheckCircleIcon
								className={classes.statusPlan}
								style={{
									color: COLOR_STATUS_SUCCESS,
								}}
							/>
						</Tooltip>
					)
				case 2:
					return (
						<Tooltip title={<Translate id='qualityAudit.conditionedState' />}>
							<ErrorIcon
								className={classes.statusPlan}
								style={{
									color: COLOR_STATUS_WARNING,
								}}
							/>
						</Tooltip>
					)
				case 3:
					return (
						<Tooltip title={<Translate id='common.rejected' />}>
							<CancelIcon
								className={classes.statusPlan}
								style={{
									color: COLOR_STATUS_DANGER,
								}}
							/>
						</Tooltip>
					)
				default:
					return (
						<Tooltip title={<Translate id='qualityAudit.awaitingEvaluation' />}>
							<AccessTimeIcon
								className={classes.statusPlan}
								style={{
									color: COLOR_STATUS_INFO,
								}}
							/>
						</Tooltip>
					)
			}
		}
	}

	/**
	 * Function to add folio
	 * @param {*} itemModel
	 */
	const onAddAudit = (itemModel) => {
		return dispatch(addAudit(itemModel))
			.then((requestToken) => {
				onOpenLateralPanel()
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
				if (!IsNullOrEmpty(requestToken)) {
					props.history.push(`/qualityaudit/${requestToken}`)
				}
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: getErrorInChangeStatus(response),
					toasterVariant: 'error',
				})
			})
	}

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * Method to show error in change status
	 * @param {*} error
	 */
	const getErrorInChangeStatus = (response) => {
		if (!IsNullOrEmpty(response.data) && typeof response.data === 'string') {
			if (response.data.includes('The Total Percentage')) return 'sustainability.error.templatedInvalid'
			else return 'common.errorToSave'
		} else {
			return 'common.errorToSave'
		}
	}

	/**
	 * Get folios
	 */
	const getCounterMessage = () => {
		if (auditList) {
			let auditTotal = auditList.length
			return (
				<div>
					{<Translate id='common.showing' />}&nbsp;
					<span className={classes.totalCounter}>{auditTotal}</span>&nbsp;
					{<Translate id='common.of' />}&nbsp;
					<span className={classes.totalCounter}>{auditCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	return (
		<Card>
			<CardHeader
				title={<Typography variant='button'>{<Translate id='menu.audit' />}</Typography>}
				avatar={
					<Avatar>
						<FindInPage />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						<SearchInput
							onChangeSearchValue={onChangeSearchValue}
							value={!!queryParams && queryParams.query ? queryParams.query : ''}
						/>

						{IsCurrentActiveDirectoryUser(user) && canUserCreateReportModuleQuality(userRolesByCompany) && (
							<Tooltip title={<Translate id='qualityAudit.createNewAudit' />}>
								<Fab
									className={classes.marginIcon}
									size='small'
									color='primary'
									onClick={() => {
										onOpenLateralPanel()
									}}
								>
									<AddtIcon />
								</Fab>
							</Tooltip>
						)}
					</div>
				}
				subheader={getCounterMessage()}
			/>
			<CardContent>{getCardContent()}</CardContent>

			{openLateralPanel && (
				<PanelAddAudit
					onCloseButtonClick={onOpenLateralPanel}
					onSaveButtonClick={onAddAudit}
					isSaving={identifier === ADD_AUDIT && isSaving}
				/>
			)}
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</Card>
	)
}

const styles = (theme) => ({
	logo: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	marginIcon: {
		marginLeft: '5px',
	},
	marginIconCalendar: {
		marginLeft: '5px',
		backgroundColor: COLOR_SUSTAINABILITY_PLUM,
	},
	marginIconFile: {
		marginLeft: '5px',
		backgroundColor: COLOR_SUSTAINABILITY_ROSE,
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
	statusPlan: {
		position: 'relative',
		justifyContent: 'center',
		top: '50%',
		left: '50%',
		width: '40px',
		height: '40px',
		transform: 'translate(-50%)',
	},
})

const TableConfigurationAudit = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		},
		{
			header: 'RFC',
			dataSource: 'rfc',
			isSortable: true,
		},
		{
			header: <Translate id='qualityAudit.typeAudit' />,
			dataSource: 'typeAudit',
		},
		{
			header: <Translate id='qualityAudit.auditDate' />,
			dataSource: 'auditDate',
		},
		{
			header: <Translate id='qualityAudit.creationDate' />,
			dataSource: 'creation_date',
		},
		{
			header: <Translate id='qualityAudit.supplierStatus' />,
			dataSource: 'supplierStatus',
		},
		{
			header: <Translate id='qualityAudit.applicant' />,
			dataSource: 'create_by',
		},
	],
}

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Index)))
