import React, { useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'

/**Import components materialUI */
import { withStyles } from '@material-ui/core/styles'

/**Import MaterialUi section */
import { List, ListItem, TextField, Grid, Typography, Paper, Toolbar } from '@material-ui/core'
import Evidence from '../../common/Evidence'
import { saveServiceCO2 } from '../../../store/controlCO2/controlCO2Action'

/**Import components section */
import VehicleSelect from './VehicleSelect'
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'

/**Import store section */
import { loadVehicles } from '../../../store/controlCO2/controlCO2Action'
import { getVehiclesSelector } from '../../../store/controlCO2/controlCO2Selectors'

/**import helper section */
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'

const buildVehicleOption = (vehicle) => ({ value: vehicle.token, label: `${vehicle.economicNumber}` })

const AddServiceModal = (props) => {
    const {
		classes,
		newService,
		OnUpdateProperty,
		removefilesLastVerificationDate,
		uploadFilesLastVerificationDate,
		uploadFilesLastHoldingDate,
		removeFilesLastHoldingDate,
		uploadFilesLastDateOfServiceAttachments,
		removeFilesLastDateOfServiceAttachments,
		removeFilesOtherAttachments,
		uploadFilesOtherAttachments,
		onCloseModal,
        open,
	} = props
	
const dispatch = useDispatch()

/** Connect with redux */
const isSaving = useSelector((state) => state.controlCO2.get('isSaving'))
const allVehicles = useSelector((state) => getVehiclesSelector(state))
const vehicleOptions = allVehicles.map((vehicle) => buildVehicleOption(vehicle))

/** Load vehicles data */
useEffect(() => {
    dispatch(loadVehicles())
}, [dispatch])

const saveService = () => {
    return dispatch(saveServiceCO2(newService))
        .then(() => {
            onCloseModal()
        })
        .catch((response) => {
            console.log('Error', response)
        })
}


return (
    <Paper style={{ overflowX: 'hidden' }}>
			<Dialog
				style={{ overflowX: 'hidden' }}
				open={open}
				onClose={onCloseModal}
				header={
					<Toolbar>
						<Typography variant='h6'>{<Translate id='controlCO2.addServiceCO2' />}</Typography>
					</Toolbar>
				}
				actions={
					<DialogActions
						view={SHOW_EDIT_VIEW}
						onCancel={onCloseModal}
						onClose={onCloseModal}
						onSaveItem={() => saveService()}
						isSaving={isSaving}
                        canBeSaved={true}
					/>
				}
		>
        <div className={classes.containerInformation}>
            <List sx={{ width: '100%' }}>
                <ListItem>
                        <TextField
                            label='Ultima Fecha de verificación:	'
                            fullWidth
                            defaultValue={newService.lastVerificationDate}
                            type='date'
                            onChange={(event) => OnUpdateProperty('lastVerificationDate', event.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <Grid container style={{ width: 425 }} justify='space-between'>
                            <Grid item xs={12}>
                                <Evidence
                                    baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                                    removeFiles={removefilesLastVerificationDate}
                                    uploadFiles={uploadFilesLastVerificationDate}
                                    files={newService.LastVerificationDateAttachments}
                                    text='Arrastra y suelta una imagen'
                                    acceptedFiles={['images']}
                                    disabledLink
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <TextField
                            label='Ultima Fecha de servicio:	'
                            fullWidth
                            defaultValue={newService.lastDateOfService}
                            type='date'
                            onChange={(event) => OnUpdateProperty('lastDateOfService', event.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <Grid container style={{ width: 425 }} justify='space-between'>
                            <Grid item xs={12}>
                                <Evidence
                                    baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                                    removeFiles={removeFilesLastDateOfServiceAttachments}
                                    uploadFiles={uploadFilesLastDateOfServiceAttachments}
                                    files={newService.lastDateOfServiceAttachments}
                                    text='Arrastra y suelta una imagen'
                                    acceptedFiles={['images']}
                                    disabledLink
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <TextField
                            label='Ultima Fecha tenencia: '
                            fullWidth
                            defaultValue={newService.lastHoldingDate}
                            type='date'
                            onChange={(event) => OnUpdateProperty('lastHoldingDate', event.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <Grid container style={{ width: 425 }} justify='space-between'>
                            <Grid item xs={12}>
                                <Evidence
                                    baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                                    removeFiles={removeFilesLastHoldingDate}
                                    uploadFiles={uploadFilesLastHoldingDate}
                                    text='Arrastra y suelta una imagen'
                                    files={newService.lastHoldingDateAttachments}
                                    acceptedFiles={['images']}
                                    disabledLink
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Typography>Archivos adjuntos</Typography>
                    </ListItem>
                    <ListItem>
                        <Grid container style={{ width: 425 }} justify='space-between'>
                                <Grid item xs={12}>
                                    <Evidence
                                        baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                                        removeFiles={removeFilesOtherAttachments}
                                        uploadFiles={uploadFilesOtherAttachments}
                                        text='Arrastra y suelta un archivo'
                                        files={newService.otherAttachments}
                                        acceptedFiles={['images', 'officeDocuments', 'pdf', 'otherFiles']}
                                        disabledLink
                                    />
                                </Grid>
                            </Grid>
                    </ListItem>
                    <ListItem>
                        <Typography style={{ marginLeft: '6px' }} variant='caption' color={'textSecondary'}>
                            {<Translate id='controlCO2.vehicle' />}
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Grid container style={{ width: 425 }} justify='space-between'>
                            <Grid item xs={12}>
                                <VehicleSelect
                                    options={vehicleOptions}
                                    onOptionChange={(selectedOption) => {
                                        OnUpdateProperty('vehicleToken', selectedOption.value)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <TextField
                            label='Origen del Transporte: '
                            fullWidth
                            onChange={(event) => OnUpdateProperty('originOfTransportation', event.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            label='Tipo de Transporte:'
                            fullWidth
                            onChange={(event) => OnUpdateProperty('TypeOfTransportation', event.target.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            label='Revisión de extintor:'
                            fullWidth
                            defaultValue={newService.FireExtinguisherInspection}
                            type='date'
                            onChange={(event) => OnUpdateProperty('FireExtinguisherInspection', event.target.value)}
                        />
                    </ListItem>
                </List>
            </div>
            </Dialog>
	</Paper>
)
}
const dialogStyles = (theme) => ({
    textField: {
        '&.MuiInputBase-root': {
            padding: '10px 14px',
        },
    },
})

export default withStyles(dialogStyles, { withTheme: true })(AddServiceModal)