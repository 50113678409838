import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { Translate, getTranslate } from 'react-localize-redux'
import Button from '@material-ui/core/Button'

/** Material-UI imports section */
import {
	Paper,
	Grid,
	CardContent,
	Typography,
	Tooltip,
	Card,
	ListItemIcon,
	CardHeader,
	ListItemText,
	ListItem,
	List,
	Fab,
	TextField,
	Select,
	MenuItem,
} from '@material-ui/core'

import CalendarActiveIcon from '@material-ui/icons/EventAvailableOutlined'
import ContractIcon from '@material-ui/icons/ImportContacts'
import Avatar from '@material-ui/core/Avatar'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import AddContractModal from './Components/AddContractModal'
import EditContractModal from './Components/EditContractModal'
import AddCommonItem from '../common/AddCommonItem'
import UserTabs from './Components/UsersTab'
import ContractsTabs from './Components/ContractsTab'

/** Import action section */
import { canBeModuleContractsSupplier } from '../../store/helpers/RolesHelper'
import { converListToArray } from '../../store/helpers/ListHelper'
import {
	loadSupplierContractor,
	addSupplierContractDetail,
	editContractDetail,
	removeContractDetail,
	removeSupplierContract,
	updateSupplierContract,
	editSupplierContract,
	addContractUser,
	removeContractUser,
	downloadReportBySupplier,
} from '../../store/supplierconstracts/SupplierConstractAction'
import { searchUsersInRole } from '../../store/administration/InternalActions'
import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers'
import { getColorStatusContract } from '../../store/helpers/DocumentsHelper'
import {
	AccountBalance,
	Assignment,
	AssignmentInd,
	CalendarToday,
	ConfirmationNumberSharp,
	Edit,
	FolderSpecial,
	Gavel,
	Group,
	GroupWork,
	LocalShipping,
	MonetizationOn,
	NewReleases,
	Note,
	Person,
	Receipt,
	SystemUpdate,
	TranslateOutlined,
	VerticalSplit,
} from '@material-ui/icons'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import {
	GetTypeDealContract,
	GetCurrencyTypeContract,
	GetLegaContract,
	GetLanguageContract,
	GetOptionResolved,
} from '../../store/helpers/SelectOptions'
import { ROLE_CONTRACTS_VIEWER_SUPPLIERS } from '../../store/helpers/RolesHelper'

/**
 * Container show Official notice
 */
class Show extends Component {
	/**
	 * Create an instance Official notice
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAddContract: false,
			openEditContract: false,
			userToAdd: null,
			activeTab: 0,
			users: [],
			itemToEdit: null,
			contract: {
				requestedBy: '',
				typeDeal: 0,
				typeOfCurrency: 0,
				legal: '',
				language: '',
				daysToExpire: 0,
				repse: false,
				contrapartEmployees: 0,
				counterpartEmployerRegistration: '',
				counterpartRFC: '',
				personnelServiceAvailable: false,
				isFilial: false,
				personnelEntersPlaceToPerformServices: false,
				descriptionOfServices: '',
				department: '',
				consideration: '',
				wayToPay: '',
				specialConditions: '',
				contractMotive: '',
				signature: '',
			},
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let contractorToken = this.props.match.params.token
		this.props.loadSupplierContractor(contractorToken)
		this.props.searchUsersInRole(ROLE_CONTRACTS_VIEWER_SUPPLIERS).then((users) => {
			let usersToAdd = users.map((user) => {
				return {
					value: user.user_name,
					label: user.name,
				}
			})
			this.setState({
				users: usersToAdd,
			})
		})
	}

	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 * Render item in list of contracte detail
	 *
	 * @param {*} idTranslation
	 * @param {*} value
	 * @param {*} icon
	 * @param {*} isLink
	 * @param {*} isButton
	 * @returns
	 * @memberof Show
	 */
	renderItemList(idTranslation, value, icon, key = '') {
		return (
			<ListItem divider key={idTranslation + key}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText
					secondary={
						<div style={{ display: 'flex' }}>
							<Typography
								component='span'
								variant='body2'
								className={this.props.classes.inline}
								color='textPrimary'
							>
								<Translate id={idTranslation} />
								:&nbsp;
							</Typography>
							<Typography component='span' className={this.props.classes.inlineValue}>
								{value ? value : ''}
							</Typography>
						</div>
					}
				/>
			</ListItem>
		)
	}

	renderUpdateItemList(idTranslation, element = '', icon) {
		return (
			<ListItem divider key={idTranslation}>
				<ListItemIcon>{icon}</ListItemIcon>
				<Typography component='span' variant='body2' className={this.props.classes.inline} color='textPrimary'>
					<Translate id={idTranslation} />
				</Typography>
				{': '}
				<TextField
					fullWidth
					style={{ left: '5px' }}
					id='filled-margin-dense'
					value={
						this.state.contract[idTranslation.split('.')[1]]
							? this.state.contract[idTranslation.split('.')[1]]
							: element
					}
					onChange={(event) => {
						this.updateProperty(idTranslation.split('.')[1], event.target.value)
					}}
					helperText={
						idTranslation === 'constracts.descriptionOfServices' ||
						idTranslation === 'constracts.specialConditions'
							? 'Introduzca cada uno separadas por "|"'
							: ''
					}
					size='small'
					defaultValue={element}
				/>
			</ListItem>
		)
	}

	renderUpdateItemListSelect(idTranslation, element = '', icon, options) {
		return (
			<ListItem divider key={idTranslation}>
				<ListItemIcon>{icon}</ListItemIcon>
				<Typography component='span' variant='body2' className={this.props.classes.inline} color='textPrimary'>
					<Translate id={idTranslation} />
				</Typography>
				{': '}
				<Select
					fullWidth
					style={{ left: '5px' }}
					value={
						this.state.contract[idTranslation.split('.')[1]]
							? this.state.contract[idTranslation.split('.')[1]]
							: element
					}
					onChange={(event) => {
						this.updateProperty(idTranslation.split('.')[1], event.target.value)
					}}
				>
					{options.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</ListItem>
		)
	}

	/**
	 * onRemoveItem
	 */
	onRemoveItem(itemId) {
		this.setState({
			openRemoveItem: true,
			itemId: itemId,
		})
	}

	onEditItem(item) {
		this.setState({
			openEditContract: true,
			itemToEdit: item,
		})
	}

	/**
	 * onRemoveContract
	 */
	onRemoveContract() {
		this.props
			.removeContractDetail(this.props.supplierContractor.token, this.state.itemId)
			.then(() => {
				this.setState({
					openRemoveItem: false,
					itemId: '',
				})
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToRemove'))
				return Promise.reject()
			})
	}

	onEditContract(item) {
		return this.props
			.editContractDetail(this.props.supplierContractor.token, item)
			.then(() => {
				this.setState({
					openEditContract: false,
					itemToEdit: null,
				})
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToRemove'))
				return Promise.reject()
			})
	}

	/**
	 * onDeleteFolio
	 */
	onDeleteFolio() {
		this.props
			.removeSupplierContract(this.props.supplierContractor.token)
			.then(() => {
				this.setState({
					openDeleteFolio: false,
				})
				this.props.history.push(`/contracts`)
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToRemove'))
				return Promise.reject()
			})
	}

	onUpdateFolio() {
		this.props
			.updateSupplierContract(this.state.contract, this.props.supplierContractor.token)
			.then(() => {
				this.setState({
					openUpdateFolio: false,
				})
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToAdd'))
				return Promise.reject()
			})
	}

	onEditFolio() {
		this.props
			.editSupplierContract(this.state.contract, this.props.supplierContractor.token)
			.then(() => {
				this.setState({
					openEditFolio: false,
				})
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToAdd'))
				return Promise.reject()
			})
	}

	onAddUserInContract(user) {
		let contractToken = this.props.supplierContractor.token
		return this.props
			.addContractUser(contractToken, user.value, user.label)
			.then(() => {
				this.setState(showSuccessToaster())
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToAdd'))
				return Promise.reject()
			})
	}

	onRemoveUser(removeUser) {
		this.props
			.removeContractUser(this.props.supplierContractor.token, removeUser.user_name)
			.then(() => {
				this.setState(showSuccessToaster())
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToRemove'))
				return Promise.reject()
			})
	}

	/**
	 * openAddContract
	 * @param {*} openAddContract
	 */
	handleShowAddDialog(openAddContract) {
		this.setState({
			openAddContract,
		})
	}

	handleShowEditItem(openEditContract) {
		this.setState({
			openEditContract,
			itemToEdit: null,
		})
	}

	/**
	 * openDeleteFolio
	 * @param {*} openDeleteFolio
	 */
	handleShowDeleteFolioDialog(openDeleteFolio) {
		this.setState({
			openDeleteFolio,
		})
	}

	handleShowUpdateDialog(openUpdateFolio) {
		this.setState({
			openUpdateFolio,
		})
	}
	handleShowEditDialog(openEditFolio) {
		this.setState({
			openEditFolio,
		})
		let contract = this.state.contract
		contract.requestedBy = this.props.supplierContractor.requested_by
		contract.consideration = this.props.supplierContractor.consideration
		contract.contractMotive = this.props.supplierContractor.contract_motive
		contract.contrapartEmployees = this.props.supplierContractor.contrapart_employees
		contract.counterpartEmployerRegistration = this.props.supplierContractor.counterpart_employees_registration
		contract.counterpartRFC = this.props.supplierContractor.counterpart_RFC
		contract.department = this.props.supplierContractor.department
		contract.descriptionOfServices = this.props.supplierContractor.description_of_services
		contract.isFilial = this.props.supplierContractor.is_filial
		contract.language = this.props.supplierContractor.language
		contract.legal = this.props.supplierContractor.legal
		contract.personnelEntersPlaceToPerformServices =
			this.props.supplierContractor.personnel_enters_place_to_perform_services
		contract.personnelServiceAvailable = this.props.supplierContractor.personnel_service_available
		contract.repse = this.props.supplierContractor.repse
		contract.specialConditions = this.props.supplierContractor.Special_conditions
		contract.typeDeal = this.props.supplierContractor.type_deal
		contract.typeOfCurrency = this.props.supplierContractor.currency
		contract.wayToPay = this.props.supplierContractor.way_to_pay
		contract.signature = this.props.supplierContractor.signature
		this.setState({})
	}

	/**
	 * Handle event to add action, call redux action to save data
	 * @param {*} data
	 */
	onCreateNewContract(data) {
		console.log("DATA", this.props.supplierContractor)
		return this.props
			.addSupplierContractDetail(data, this.props.supplierContractor.token)
			.then(() => {
				this.setState(showSuccessToaster('common.addCorrectly'))
				this.setState({ openAddContract: false })
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToAdd'))
				return Promise.reject()
			})
	}

	onDownloadReportContract() {
		let contractToken = this.props.supplierContractor.token
		this.props
			.downloadReportBySupplier(contractToken)
			.then(() => {
				showSuccessToaster('common.downloadCorrectly')
			})
			.catch(() => {
				showErrorToaster('common.errorToDownload')
			})
	}

	updateProperty(property, element) {
		let contractpdate = { ...this.state.contract }
		contractpdate[property] = element
		this.setState({
			contract: contractpdate,
		})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Show
	 */
	getCardContent() {
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.supplierContractor !== null) {
			const { supplierContractor } = this.props
			let optionsTypeDeal = GetTypeDealContract()
			let optionsTypeOfCurrency = GetCurrencyTypeContract()
			let legalOptions = GetLegaContract()
			let languageOptions = GetLanguageContract()
			let eneableOptions = GetOptionResolved()
			return (
				<Grid container spacing={24}>
					<Grid item xs={6}>
						<Paper>
							<List>
								{this.renderItemList(
									'common.folio',
									supplierContractor.folio,
									<ConfirmationNumberSharp />
								)}
								{supplierContractor.isSupplier
									? this.renderItemList(
											'constracts.counterpart',
											supplierContractor.supplier_name,
											<LocalShipping />
									  )
									: this.renderItemList(
											'constracts.counterpart',
											`${supplierContractor.clientCode} | ${supplierContractor.clientName}`,
											<LocalShipping />
									  )}
								{this.renderItemList(
									'common.creationDate',
									supplierContractor.creation_date,
									<CalendarActiveIcon />
								)}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.requestedBy',
												supplierContractor.requested_by,
												<Person />
										  )
										: this.renderUpdateItemList(
												'constracts.requestedBy',
												supplierContractor.requested_by,
												<Person />
										  )
									: this.renderUpdateItemList(
											'constracts.requestedBy',
											supplierContractor.requested_by,
											<Person />
									  )}
								{this.renderItemList(
									'constracts.signature',
									supplierContractor.signature,
									<VerticalSplit />
								)}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.typeDeal',
												supplierContractor.type_deal
													? optionsTypeDeal[supplierContractor.type_deal - 1].label
													: '',
												<Note />
										  )
										: this.renderUpdateItemListSelect(
												'constracts.typeDeal',
												supplierContractor.type_deal,
												<Note />,
												optionsTypeDeal
										  )
									: this.renderUpdateItemListSelect(
											'constracts.typeDeal',
											this.state.typeDeal,
											<Note />,
											optionsTypeDeal
									  )}
								{this.renderItemList(
									'constracts.daysToExpire',
									moment(supplierContractor.end_date, 'DD/MM/YYYY').diff(moment(), 'days') + ' Dias',
									<CalendarToday />
								)}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.language',
												supplierContractor.language,
												<TranslateOutlined />
										  )
										: this.renderUpdateItemListSelect(
												'constracts.language',
												supplierContractor.language,
												<TranslateOutlined />,
												languageOptions
										  )
									: this.renderUpdateItemListSelect(
											'constracts.language',
											'',
											<TranslateOutlined />,
											languageOptions
									  )}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.contrapartEmployees',
												supplierContractor.contrapart_employees,
												<ConfirmationNumberSharp />
										  )
										: this.renderUpdateItemList(
												'constracts.contrapartEmployees',
												supplierContractor.contrapart_employees,
												<ConfirmationNumberSharp />
										  )
									: this.renderUpdateItemList(
											'constracts.contrapartEmployees',
											'',
											<ConfirmationNumberSharp />
									  )}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? supplierContractor.Special_conditions &&
										  supplierContractor.Special_conditions.split('|').map((element) => {
												if (element.length > 0)
													return this.renderItemList(
														'constracts.specialConditions',
														element,
														<NewReleases />,
														element
													)
												else return null
										  })
										: this.renderUpdateItemList(
												'constracts.specialConditions',
												supplierContractor.Special_conditions,
												<NewReleases />
										  )
									: this.renderUpdateItemList('constracts.specialConditions', '', <NewReleases />)}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.counterpartRFC',
												supplierContractor.counterpart_RFC,
												<ConfirmationNumberSharp />
										  )
										: this.renderUpdateItemList(
												'constracts.counterpartRFC',
												supplierContractor.counterpart_RFC,
												<ConfirmationNumberSharp />
										  )
									: this.renderUpdateItemList(
											'constracts.counterpartRFC',
											'',
											<ConfirmationNumberSharp />
									  )}
								{this.state.openEditFolio && (
									<>
										<Button
											variant='outlined'
											color='secondary'
											data-cy='btn-save'
											style={{ left: '10px', top: '10px' }}
											onClick={this.onEditFolio}
										>
											<Translate id='common.save' />
										</Button>
										<Button
											variant='outlined'
											color='primary'
											data-cy='btn-close'
											style={{ left: '20px', top: '10px' }}
											onClick={() => {
												this.setState({ openEditFolio: false })
											}}
										>
											<Translate id='common.cancel' />
										</Button>
									</>
								)}
							</List>
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper>
							<List>
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.isFilial',
												supplierContractor.is_filial ? 'SI' : 'NO',
												<GroupWork />
										  )
										: this.renderUpdateItemListSelect(
												'constracts.isFilial',
												supplierContractor.is_filial,
												<GroupWork />,
												eneableOptions
										  )
									: this.renderUpdateItemListSelect(
											'constracts.isFilial',
											'',
											<GroupWork />,
											eneableOptions
									  )}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.personnelEntersPlaceToPerformServices',
												supplierContractor.personnel_enters_place_to_perform_services
													? 'SI'
													: 'NO',
												<Group />
										  )
										: this.renderUpdateItemListSelect(
												'constracts.personnelEntersPlaceToPerformServices',
												supplierContractor.personnel_enters_place_to_perform_services,
												<GroupWork />,
												eneableOptions
										  )
									: this.renderUpdateItemListSelect(
											'constracts.personnelEntersPlaceToPerformServices',
											'',
											<Group />,
											eneableOptions
									  )}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.contractMotive',
												supplierContractor.contract_motive,
												<Note />
										  )
										: this.renderUpdateItemList(
												'constracts.contractMotive',
												supplierContractor.contract_motive,
												<Note />
										  )
									: this.renderUpdateItemList('constracts.contractMotive', '', <Note />)}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.wayToPay',
												supplierContractor.way_to_pay,
												<AccountBalance />
										  )
										: this.renderUpdateItemList(
												'constracts.wayToPay',
												supplierContractor.way_to_pay,
												<AccountBalance />
										  )
									: this.renderUpdateItemList('constracts.wayToPay', '', <AccountBalance />)}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.typeOfCurrency',
												optionsTypeOfCurrency[supplierContractor.currency].label,
												<MonetizationOn />
										  )
										: this.renderUpdateItemListSelect(
												'constracts.typeOfCurrency',
												supplierContractor.currency,
												<MonetizationOn />,
												optionsTypeOfCurrency
										  )
									: this.renderUpdateItemListSelect(
											'constracts.typeOfCurrency',
											'',
											<MonetizationOn />,
											optionsTypeOfCurrency
									  )}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList('constracts.legal', supplierContractor.legal, <Gavel />)
										: this.renderUpdateItemListSelect(
												'constracts.legal',
												supplierContractor.legal,
												<Gavel />,
												legalOptions
										  )
									: this.renderUpdateItemListSelect('constracts.legal', '', <Gavel />, legalOptions)}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.repse',
												supplierContractor.repse ? 'SI' : 'NO',
												<Receipt />
										  )
										: this.renderUpdateItemListSelect(
												'constracts.repse',
												supplierContractor.repse,
												<Receipt />,
												eneableOptions
										  )
									: this.renderUpdateItemListSelect(
											'constracts.repse',
											'',
											<Receipt />,
											eneableOptions
									  )}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.counterpartEmployerRegistration',
												supplierContractor.counterpart_employees_registration,
												<Assignment />
										  )
										: this.renderUpdateItemList(
												'constracts.counterpartEmployerRegistration',
												supplierContractor.counterpart_employees_registration,
												<Assignment />
										  )
									: this.renderUpdateItemList(
											'constracts.counterpartEmployerRegistration',
											'',
											<Assignment />
									  )}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? this.renderItemList(
												'constracts.personnelServiceAvailables',
												supplierContractor.personnel_service_available ? 'SI' : 'NO',
												<AssignmentInd />
										  )
										: this.renderUpdateItemListSelect(
												'constracts.personnelServiceAvailables',
												supplierContractor.personnel_service_available,
												<AssignmentInd />,
												eneableOptions
										  )
									: this.renderUpdateItemListSelect(
											'constracts.personnelServiceAvailables',
											'',
											<AssignmentInd />,
											eneableOptions
									  )}
								{supplierContractor.status !== 0
									? !this.state.openEditFolio
										? supplierContractor.description_of_services &&
										  supplierContractor.description_of_services.split(',').map((element) => {
												if (element.length > 0)
													return this.renderItemList(
														'constracts.descriptionOfServices',
														element,
														<FolderSpecial />,
														element
													)
												else return null
										  })
										: this.renderUpdateItemList(
												'constracts.descriptionOfServices',
												supplierContractor.description_of_services,
												<FolderSpecial />
										  )
									: this.renderUpdateItemList(
											'constracts.descriptionOfServices',
											'',
											<FolderSpecial />
									  )}
							</List>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<AppBar position='static' color='default'>
							<Tabs
								indicatorColor='secondary'
								textColor='secondary'
								value={this.state.activeTab}
								onChange={this.onClickToActiveTab}
							>
								<Tab label={<p>{<Translate id='constracts.historyContract' />} </p>} />
								<Tab label={<Translate id='constracts.historyUsers' />} />
							</Tabs>
						</AppBar>
						{this.state.activeTab === 0 && (
							<ContractsTabs
								supplierContractor={supplierContractor}
								onEditItem={this.onEditItem}
								onRemoveItem={this.onRemoveItem}
								userRolesByCompany={this.props.userRolesByCompany}
								canBeModuleContractsSupplier={canBeModuleContractsSupplier}
							/>
						)}
						{this.state.activeTab === 1 && (
							<UserTabs
								users={this.state.users}
								onAddUserInContract={this.onAddUserInContract}
								onRemoveUser={this.onRemoveUser}
								userRolesByCompany={this.props.userRolesByCompany}
								canBeModuleContractsSupplier={canBeModuleContractsSupplier}
								contract_users={supplierContractor.contract_users}
							/>
						)}
					</Grid>
				</Grid>
			)
		}
	}

	render() {
		const { classes, supplierContractor } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar
							style={{
								backgroundColor: getColorStatusContract(
									supplierContractor && supplierContractor.status
								),
							}}
						>
							<ContractIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<Toolbar>
								<Grid style={{ paddingRight: '10px' }}>
									<Tooltip title={<Translate id='common.downloadReport' />}>
										<Fab
											size='small'
											color='primary'
											onClick={() => {
												this.onDownloadReportContract()
											}}
										>
											<DownloadIcon />
										</Fab>
									</Tooltip>
								</Grid>
								{canBeModuleContractsSupplier(this.props.userRolesByCompany) && (
									<Grid style={{ paddingRight: '10px' }}>
										<Tooltip title={<Translate id='constracts.edit' />}>
											<Fab
												size='small'
												color='primary'
												onClick={() => {
													this.handleShowEditDialog(true)
												}}
											>
												<Edit />
											</Fab>
										</Tooltip>
									</Grid>
								)}
								{canBeModuleContractsSupplier(this.props.userRolesByCompany) &&
									supplierContractor &&
									supplierContractor.status === 0 && (
										<Grid style={{ paddingRight: '10px' }}>
											<Tooltip title={<Translate id='constracts.update' />}>
												<Fab
													size='small'
													color='primary'
													onClick={() => {
														this.handleShowUpdateDialog(true)
													}}
												>
													<SystemUpdate />
												</Fab>
											</Tooltip>
										</Grid>
									)}
								{canBeModuleContractsSupplier(this.props.userRolesByCompany) && (
									<Grid style={{ paddingRight: '10px' }}>
										<Tooltip title={<Translate id='constracts.deleteFolio' />}>
											<Fab
												size='small'
												color='primary'
												onClick={() => {
													this.handleShowDeleteFolioDialog(true)
												}}
											>
												<DeleteOutlinedIcon />
											</Fab>
										</Tooltip>
									</Grid>
								)}
								{canBeModuleContractsSupplier(this.props.userRolesByCompany) && (
									<Tooltip title={<Translate id='constracts.addContract' />}>
										<Fab
											size='small'
											color='primary'
											onClick={() => {
												this.handleShowAddDialog(true)
											}}
										>
											<AddtIcon />
										</Fab>
									</Tooltip>
								)}
							</Toolbar>
						</div>
					}
					title={<Typography variant='button'>{<Translate id='constracts.title' />}</Typography>}
					subheader={supplierContractor && supplierContractor.supplier_name}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to add other contactor*/}
				<AddContractModal
					text={this.props.translate('dropzone.dropzoneBaseText')}
					open={this.state.openAddContract}
					onClose={() => {
						this.handleShowAddDialog(false)
					}}
					createNewContract={this.onCreateNewContract}
					isSaving={this.props.isSaving}
					user={this.props.currentUser}
				/>

				{this.state.openEditContract && (
					<EditContractModal
						text={this.props.translate('dropzone.dropzoneBaseText')}
						open={this.state.openEditContract}
						onClose={() => {
							this.handleShowEditItem(false)
						}}
						editContract={this.onEditContract}
						isSaving={this.props.isSaving}
						user={this.props.currentUser}
						item={this.state.itemToEdit}
					/>
				)}

				{/** Dialog to remove */}
				<AddCommonItem
					title={<Translate id='constracts.deleteContracct' />}
					message={<Translate id='constracts.messageToDeleteContr' />}
					open={this.state.openRemoveItem}
					onClose={() => {
						this.setState({ openRemoveItem: false })
					}}
					onSaveItem={this.onRemoveContract}
					isSaving={this.props.isSaving}
					isMessage={true}
					saveButtonTranslate={'common.delete'}
				/>

				{/** Dialog to delete folio */}
				<AddCommonItem
					title={<Translate id='constracts.deleteFolio' />}
					message={<Translate id='constracts.messageToDeleteFolio' />}
					open={this.state.openDeleteFolio}
					onClose={() => {
						this.setState({ openDeleteFolio: false })
					}}
					onSaveItem={this.onDeleteFolio}
					isSaving={this.props.isSaving}
					isMessage={true}
					saveButtonTranslate={'common.delete'}
				/>

				{/** Dialog to Update folio */}
				<AddCommonItem
					title={<Translate id='constracts.updateFolio' />}
					message={<Translate id='constracts.messageToUpdateFolio' />}
					open={this.state.openUpdateFolio}
					onClose={() => {
						this.setState({ openUpdateFolio: false })
					}}
					onSaveItem={this.onUpdateFolio}
					isSaving={this.props.isSaving}
					isMessage={true}
					saveButtonTranslate={'constracts.update'}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let supplierContractor = state.contracts.get('supplierContractor')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		currentUser: user,
		isLoading: state.contracts.get('isLoadingContractor'),
		supplierContractor: supplierContractor ? supplierContractor.toJS() : null,
		isSaving: state.contracts.get('isSavingContract'),
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSupplierContractor: (token) => {
			return dispatch(loadSupplierContractor(token))
		},
		addSupplierContractDetail: (contract, contractToken) => {
			return dispatch(addSupplierContractDetail(contract, contractToken))
		},
		removeContractDetail: (contractToken, detailToken) => {
			return dispatch(removeContractDetail(contractToken, detailToken))
		},
		editContractDetail: (contractToken, detail) => {
			return dispatch(editContractDetail(contractToken, detail))
		},
		removeSupplierContract: (contractToken) => {
			return dispatch(removeSupplierContract(contractToken))
		},
		updateSupplierContract: (contract, token) => {
			return dispatch(updateSupplierContract(contract, token))
		},
		editSupplierContract: (contract, token) => {
			return dispatch(editSupplierContract(contract, token))
		},
		addContractUser: (contractToken, userName, name) => {
			return dispatch(addContractUser(contractToken, userName, name))
		},
		removeContractUser: (contractToken, userName) => {
			return dispatch(removeContractUser(contractToken, userName))
		},
		searchUsersInRole: (roleNames) => {
			return dispatch(searchUsersInRole(roleNames))
		},
		downloadReportBySupplier: (token) => {
			return dispatch(downloadReportBySupplier(token))
		},
	}
}

const styles = (theme) => ({
	actionsToolbar: {
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
	inline: {
		display: 'inline',
		whiteSpace: 'nowrap',
	},
	inlineValue: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		color: 'gray',
		width: '500px',
	},
	logoCompany: {
		maxHeight: 100,
	},
	logoSupplier: {
		marginLeft: 80,
		maxHeight: 80,
	},
	styleTab: {
		backgroundColor: '#fff',
	},
	margin: {
		margin: theme.spacing.unit * 2,
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
