import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import LinkMaterial from '@material-ui/core/Link'
import { showPlotsFromPartNumber } from '../../store/helpers/ShowPlotToPartNumber'
import { Grid, Tooltip } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import AttachIcon from '@material-ui/icons/AttachFile'

import { COMPANY_IDENTIFIER_IMBERA_MX } from '../../store/helpers/ResourcesHelper'

/**
 * Class to show PartNumbersFiles pure component
 * return grid with list of attached files
 *
 * @class FilesLinks
 * @extends {PureComponent}
 */
class FilesLinks extends PureComponent {
	render() {
		let { item, kind, formatype, companyCode } = this.props
		let plot = this.props.customPlotProperty ? this.props.customPlotProperty : item.plot_name || item.plane
		let files = this.props.item.filesName
		let attachments = this.props.customAttachmentsProperty ? this.props.customAttachmentsProperty : 'attachments'
		return (
			<React.Fragment>
				{this.props.hasPlot && plot && (
					<Grid item xs={12}>
						<Tooltip title={<Translate id='common.downloadPlotTooltip' />} placement='left'>
							<Link
								onClick={() => showPlotsFromPartNumber(plot, this.props.companyToken, kind, formatype)}
								to='#'
							>
								<AttachIcon style={{ fontSize: '18px' }} />
								{this.props.strikeThrough && (
									<span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
										{'' + plot}
									</span>
								)}
								{!this.props.strikeThrough && '' + plot}
							</Link>
						</Tooltip>
					</Grid>
				)}
				{files &&
					files.length > 0 &&
					companyCode === COMPANY_IDENTIFIER_IMBERA_MX &&
					item.requiered_plot_art &&
					files.map((fileName) => {
						return (
							<Grid item xs={12}>
								<Tooltip title={<Translate id='common.downloadPlotTooltip' />} placement='left'>
									<Link
										onClick={() =>
											this.props.downloadFile(fileName, this.props.companyToken, kind, formatype)
										}
										to='#'
									>
										<AttachIcon style={{ fontSize: '18px' }} />
										{this.props.strikeThrough && (
											<span
												style={{
													textDecorationLine: 'line-through',
													textDecorationStyle: 'solid',
												}}
											>
												{'' + fileName}
											</span>
										)}
										{!this.props.strikeThrough && '' + fileName}
									</Link>
								</Tooltip>
							</Grid>
						)
					})}
				{item[attachments] &&
					item[attachments].length > 0 &&
					item[attachments].map((file) => {
						return (
							<Grid item xs={12} key={file.relativePath}>
								<Tooltip title={<Translate id='common.downloadFilesTooltip' />} placement='left'>
									<LinkMaterial
										target='_blank'
										href={`${process.env.REACT_APP_SIP_URL_FILES}/${file.relativePath}`}
									>
										<AttachIcon style={{ fontSize: '18px' }} />
										{this.props.strikeThrough && (
											<text
												style={{
													textDecorationLine: 'line-through',
													textDecorationStyle: 'solid',
												}}
											>
												{'' + file.name}
											</text>
										)}
										{!this.props.strikeThrough && '' + file.name}
									</LinkMaterial>
								</Tooltip>
							</Grid>
						)
					})}
			</React.Fragment>
		)
	}
}

FilesLinks.propTypes = {
	item: PropTypes.object.isRequired,
	companyToken: PropTypes.string,
	hasPlot: PropTypes.bool,
	customPlotProperty: PropTypes.string, // optional determine property name in object to get plot name
	customAttachmentsProperty: PropTypes.string, //optional detemine property in object to get attachemnts list
}

export default FilesLinks
