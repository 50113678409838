///Import react section
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
/**Import MaterialUi section */
import { Grid, Avatar, TextField, Typography, LinearProgress, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import SupplierSelector from '../../../common/SupplierSelector'

/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'

/**Import actionsSection */
import { addAlerts } from '../../../../store/loadAlerts/LoadAlertAction'
import { loadAdministratorSupplier } from '../../../../store/suppliers/ExternalUserActions'
const AddLoadAlertPanel = (props) => {
	/**Functions props section */
	const { onCloseButtonClick, onRedirectToDetail, isInternalUser } = props
	/**Objects props section */
	const { classes, administratorSupplier } = props
	//bool props csection
	const dispatch = useDispatch()
	const isSaving = useSelector((state) => state.loadAlert.get('isSaving'))

	const [alertModel, setAlertModel] = useState({
		SupplierName: '',
		SupplierCode: '',
		SupplierEmail: '',
		ShippingOrder: '',
		ReferenceNumber: '',
	})

	// /**Use effect section */
	useEffect(() => {
		if (administratorSupplier) {
			setAlertModel({
				...alertModel,
				SupplierEmail: administratorSupplier ? administratorSupplier.email : '',
			})
		}
	}, [dispatch, administratorSupplier])

	const onAddAlert = () => {
		return dispatch(addAlerts(alertModel, isInternalUser))
			.then((result) => {
				onRedirectToDetail(result.token)
			})
			.catch((response) => {})
	}

	/**Verify if can be sabed data*/
	const canBeSaved = () => {
		if (isInternalUser) {
			if (IsNullOrEmpty(alertModel.SupplierCode)) return false
			if (IsNullOrEmpty(alertModel.SupplierName)) return false
			if (IsNullOrEmpty(alertModel.SupplierEmail)) return false
			if (IsNullOrEmpty(alertModel.ShippingOrder)) return false
			if (IsNullOrEmpty(alertModel.ReferenceNumber)) return false
			return true
		} else {
			if (IsNullOrEmpty(alertModel.ShippingOrder)) return false
			if (IsNullOrEmpty(alertModel.ReferenceNumber)) return false
			return true
		}
	}

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setAlertModel({
			...alertModel,
			[name]: value,
		})
	}

	const onSelectedChange = (option) => {
		console.log('option', option)
		setAlertModel({
			...alertModel,
			SupplierCode: option ? option.code : '',
			SupplierName: option ? option.name : '',
		})

		return dispatch(loadAdministratorSupplier(`${option ? option.code : ''}-admin`))
	}
	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={<span>{'Crear alerta de carga'}</span>}
				onCloseButtonClick={onCloseButtonClick}
				icon={
					<Avatar className={classes.avatar}>
						<AddIcon />
					</Avatar>
				}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid container>
						{isInternalUser && (
							<>
								<Grid container spacing={1}>
									<Grid item xs={12} style={{ marginTop: '1rem' }}>
										<Typography>
											<Translate id='factoringAffiliation.table.supplierCode' />
										</Typography>
										<SupplierSelector
											onSupplierSelected={(supplier) => onSelectedChange(supplier)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<div className={classes.createTextField}>
										<Typography>
											<Translate id='factoringAffiliation.table.supplierName' />
										</Typography>
										<TextField
											value={alertModel ? alertModel.SupplierName : ''}
											fullWidth
											margin='normal'
											variant='outlined'
											name='SupplierName'
											onChange={onPropertyChange}
											disabled={true}
										/>
									</div>
								</Grid>
								<Grid item xs={12}>
									<div className={classes.createTextField}>
										<Typography>Correo del proveedor</Typography>
										<TextField
											value={alertModel ? alertModel.SupplierEmail : ''}
											fullWidth
											margin='normal'
											variant='outlined'
											name='SupplierEmail'
											onChange={onPropertyChange}
											disabled={true}
										/>
									</div>
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							<div className={classes.createTextField}>
								<Typography>Número de referencia internacional</Typography>
								<TextField
									value={alertModel ? alertModel.ReferenceNumber : ''}
									fullWidth
									margin='normal'
									variant='outlined'
									name='ReferenceNumber'
									onChange={onPropertyChange}
									disabled={isSaving}
								/>
							</div>
						</Grid>

						<Grid item xs={12}>
							<div className={classes.createTextField}>
								<Typography>Orden de compra</Typography>
								<TextField
									value={alertModel ? alertModel.ShippingOrder : ''}
									fullWidth
									margin='normal'
									variant='outlined'
									name='ShippingOrder'
									onChange={onPropertyChange}
									disabled={isSaving}
								/>
							</div>
						</Grid>
					</Grid>
				</div>
				{isSaving && <LinearProgress />}
				<Button
					className={classes.btnSubmit}
					type='submit'
					variant='contained'
					disabled={isSaving || !canBeSaved()}
					fullWidth
					onClick={onAddAlert}
				>
					Crear alerta de carga
				</Button>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	expansionPanelSummary: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		paddingLeft: '20px',
		paddingTop: '10px',
		paddingBotton: '20px',
		paddingRight: '20px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	btnSubmit: {
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${BACKGROUND_COLOR_GRAY} !important`,
			opacity: 0.5,
		},
	},
})

AddLoadAlertPanel.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
}

AddLoadAlertPanel.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(AddLoadAlertPanel))
