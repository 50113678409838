import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import autoBind from 'auto-bind';
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';


/** Import component section */

import Reminder from '../common/Reminder';
import {sendReminderToSupplier} from '../../store/workflows/workflowActions'
import {loadQuoteFromBackEnd} from '../../store/quotes/QuotesActions';
/**
 * Container reminder to supplier of quotes
 */
class ReminderContainer extends Component {

    /**
     * Create instance of quotes
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {

        }
    }

    /**
    * componentDidMount
    */
   componentDidMount() {
    let quoteToken = this.props.match.params.token;
    this.props.loadQuote(quoteToken);
}

    /**
     * Send reminder to supplier of quotes
     * @param {*} comment 
     * @param {*} members 
     */
    onSendReminderToSupplier(comment){
        this.props.sendReminderToSupplier(this.props.match.params.token, comment)
        .then(() =>{
            let token =this.props.match.params.token;
            this.props.history.push(`/quotes/${token}`)
        }).catch(() =>{
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="common.errorToSave" />,
                toasterVariant: "error",
            })
        })
    }

    /**
     * Render to reminder
     */
    render() {
    return (
        <Reminder 
            {...this.props}
            workflow= {this.props.quote}
            onSendReminderToSupplier={this.onSendReminderToSupplier}
        />

    );
  }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let quote = state.quotes.get("quote");
        return {
        quote: quote ? quote.toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        sendReminderToSupplier: (token, comment) => {
            return dispatch(sendReminderToSupplier(token, comment));
        },
        loadQuote: (token) => {
            return dispatch(loadQuoteFromBackEnd(token));
        },

    }
}

export default withauthenticatedLayout((withRouter) (connect(mapStateToProps, mapDispatchToProps)(ReminderContainer)));