/**Import react section */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import autoBind from 'auto-bind';


/** Material-UI imports section */
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography, Tooltip, CardHeader, List, ListItem, TextField, CardActions, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutline';
import ReportGuaranteIcon from '@material-ui/icons/FileCopyOutlined';
import ForwardIcon from '@material-ui/icons/Forward';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from '@material-ui/core';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow';
import FloatingButton from '../common/FloatingButton';
import Evidence from '../common/Evidence';
import CardContent from '@material-ui/core/CardContent';
import InformationPartial from './components/InformationPartial';
import { converListToArray } from '../../store/helpers/ListHelper'
import DialogAvailableOperations from '../common/DialogAvailableOperations';
import DialogAnalysisReport from './components/DialogAnalisysReport';
import AddCommonItem from '../common/AddCommonItem';
import WorkflowCancel from '../common/WorkflowCancel';
import HistoryCard from '../common/HistoryCard';
import DataTable from '../common/DataTable';
import Toaster from '../common/Toaster';
import Dialog from '../common/Dialog';


/**Import actions sections */
import {
    loadGuaranteeReport, createAnalysisReport, uploadFilesEvidence,
    removeFilesEvidence, deleteDraftGuarrantee, updateGuaranteeStatus
} from '../../store/guaranteeReport/guaranteeReportAction';
import { loadSupplier, loadSupplierCode } from '../../store/suppliers/SupplierActions';
import LoadingPage from '../common/LoadingPage';
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper';
import { canUserValideModuleQuality } from '../../store/helpers/RolesHelper';
import { GUARANTEEDOCUMENT_NOTIFICATION, WORKFLOW_CANCELLATION, GUARANTEE_NOTE_CREDITREPORT, GUARANTEE_FORWARD_TO_SUPPLIER } from '../../store/helpers/WorkFlowDocumentsType';
import { Guarantee_Accept_And_Close, Guarantee_Forward_To_Supplier } from '../../store/helpers/DevelopmentHelper';
import { showErrorToaster } from '../../store/helpers/ToasterHelpers';
import { COLOR_STATUS_DANGER } from '../../store/helpers/StatusColorConstants';
import { COLOR_STATUS_GRAY } from '../../store/helpers/StatusColorConstants';


class Show extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            files: [],
            activeTab: 0,
            openAvailableOperationsDialog: false,
            openModalAnalysisReport: false,
            openDeleteDraft: false,
            openModalForwardComment: false,
            commentForward: "",
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let guaranteeReportToken = this.props.match.params.token;
        this.props.loadGuaranteeReport(guaranteeReportToken);
    }

    // /**
    //  * componentDidUpdate
    //  *
    //  * @param {*} prevProps
    //  * @param {*} prevState
    //  * @memberof Index
    //  */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.guaranteeReport !== null) {
            /// Get information full from guaranteReport
            let guaranteeReport = this.props.guaranteeReport;
            (guaranteeReport && guaranteeReport.is_create_from_sap) ? this.props.loadSupplierCode(guaranteeReport.supplier_code): this.props.loadSupplier(guaranteeReport.supplier_token);
            
        }
    }

    /**
     *Open modal for the  report analysis report
     *
     * @memberof Show
     */
    onClickToSendGuaranteeToReliabilityArea() {
        this.setState({
            openModalAnalysisReport: true,
            openAvailableOperationsDialog: false
        })
    }

    /**
     *Open view add report analysis add
     *
     * @memberof Show
     */
    onClickToSendGuaranteeToSupplier() {
        this.props.history.push(`/guaranteereport/sendnotification/${this.props.match.params.token}`)
    }


    /**
   * onDeleteDraft
   *
   * @param {*} item
   * @param {*} supplier
   * @memberof Create
   */
    onDeleteDraft() {
        this.setState({
            openAvailableOperationsDialog: false,
            openDeleteDraft: true,
        })
    }

    /**
   * onDeleteGuarrantee
   */
    onDeleteGuarrantee() {
        let guaranteeReportToken = this.props.match.params.token;
        this.props.deleteDraftGuarrantee(guaranteeReportToken)
            .then(() => {
                this.setState({
                    openDeleteDraft: false,
                })
                this.props.history.push(`/guaranteereport`)
            }).catch(() => {
                this.setState(showErrorToaster())
            });
    }

    /**
 * Function to edit guarrantee report
 */
    onClickEditGuarranteeReportInDraft() {
        let guarranteeToken = this.props.match.params.token;
        this.props.history.push(`/guaranteereport/edit/${guarranteeToken}`)
    }

    //Cancel report
    onCancelDocument() {
        this.props.history.push(`/guaranteereport/cancel/${this.props.match.params.token}`)
    }

    onAddCreditNote() {
        this.props.history.push(`/guaranteereport/notecredit/${this.props.match.params.token}`)
    }

    onClickToAcceptAndClose() {
        let guaranteeToken = this.props.match.params.token;
        this.props.updateGuaranteeStatus(guaranteeToken, Guarantee_Accept_And_Close)
            .then(() => {
                this.setOpenDialog(false);
            })
            .catch(() => {
                this.setState(showErrorToaster())
            })
    }

    onClickToForwardToSupplier() {
        this.showModalForward()
    }

    showModalForward() {
        this.setState({ openModalForwardComment: !this.state.openModalForwardComment })
    }

    onCloseCancelForwardModal() {
        this.showModalForward();
        this.setOpenDialog(false);
        this.setState({ commentForward: "" })
    }
    saveComment(comment) {
        this.setState({ commentForward: comment })
    }

    forwardToSupplier() {

        let guaranteeToken = this.props.match.params.token;
        this.props.updateGuaranteeStatus(guaranteeToken, Guarantee_Forward_To_Supplier, this.state.commentForward)
            .then(() => {
                this.onCloseCancelForwardModal()
            })
            .catch(() => {
                this.setState(showErrorToaster("common.requiredComment"))
            })
    }

    /**
  * Render hystory for rework report
  * @param {*} document 
  */
    renderNoteCreditData(document) {
        const { classes } = this.props;
        let guarranteeToken = this.props.match.params.token;
        return (
            <List>
                <ListItem>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="moduleGuarantee.folioNoteCredit" />
                                </Typography>
                                {": "}  {document.document_note_credit}
                            </React.Fragment>
                        } />
                </ListItem>
                <Divider />
                <ListItem divider>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    {"PDF"}
                                </Typography>
                                {": "}
                                <Link component={'a'} target="_blank"
                                    href={`${process.env.REACT_APP_SIP_QUALITY_URL_FILES}/Documents/GuaranteeRequestNoteCredit/${guarranteeToken}/${document.invoice}`}>
                                    {document.invoice}
                                </Link><br />
                            </React.Fragment>
                        } />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    {"XML"}
                                </Typography>
                                {": "}
                                <Link component={'a'} target="_blank"
                                    href={`${process.env.REACT_APP_SIP_QUALITY_URL_FILES}/Documents/GuaranteeRequestNoteCredit/${guarranteeToken}/${document.document_note_credit_xml}`}>
                                    {document.document_note_credit_xml}
                                </Link><br />
                            </React.Fragment>
                        } />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="moduleGuarantee.observations" />
                                </Typography>
                                {": "} {document.observations}
                            </React.Fragment>
                        } />
                </ListItem>
            </List>
        )
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.guaranteeReport !== null) {
            const { classes } = this.props;
            return (

                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Divider />
                        <AppBar position="static" color="default">
                            <Tabs indicatorColor="secondary" textColor="secondary"
                                className={classes.styleTab}
                                value={this.state.activeTab}
                                onChange={this.onClickToActiveTab}>
                                <Tab label={<p><ReportGuaranteIcon />{<Translate id="common.information" />} </p>} />
                                <Tab label={<Translate id="common.evidence" />} />
                            </Tabs>
                        </AppBar>
                        {this.state.activeTab === 0 && <InformationPartial  {...this.props} />}
                        {this.state.activeTab === 1 &&
                            <Evidence
                                isDisabled={this.props.guaranteeReport.is_closed || !IsCurrentActiveDirectoryUser(this.props.currentUser)}
                                baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                                removeFiles={this.props.removeFiles}
                                uploadFiles={this.props.uploadFiles}
                                files={this.props.guaranteeReport.attachments}
                            />
                        }
                    </Grid>
                </Grid>
            )
        }
    }

    /**
     * Method to change the active tab
     */
    onClickToActiveTab = (event, value) => {
        this.setState({ activeTab: value });
    };

    /**
     * Action to set state to open of available operations dialog
     * @memberof 
     */
    setOpenDialog = (openAvailable) => {
        this.setState({
            openAvailableOperationsDialog: openAvailable,
        });
    }

    renderAditionalData(developmenEngineerName, qualityUsersList) {
        return (
            <Grid container spacing={24}>
                {developmenEngineerName && <CardHeader
                    style={{ paddingTop: '0px' }}
                    title={
                        <Typography gutterBottom variant="body2" component="p">
                            <strong>
                                <Translate id="common.sentTo" />:
                            </strong>
                        </Typography>
                    }
                    subheader={<Typography gutterBottom variant="body2" component="p">
                        <Tooltip title={<Translate id='common.developmentEnginner' />}>
                            <PersonIcon color='secondary' />
                        </Tooltip>{' ' + developmenEngineerName}
                    </Typography>
                    }
                />}
                <Grid item xs={12}>
                    <DataTable
                        title={
                            <Typography gutterBottom variant="body2" component="p">
                                <strong>
                                    <Translate id="rawmaterialnonconformity.qualityUsersSupplier" />:
                                </strong>
                            </Typography>
                        }
                        data={qualityUsersList}
                        configuration={{
                            columns: [{
                                header: <Translate id="officialNotices.name" />,
                                dataSource: 'full_name',
                            }]
                        }}
                    />
                </Grid>
            </Grid>
        )
    }

    /**
     * Render hystoryCard for render dorward to supplier data
     *
     * @param {*} folio
     * @param {*} status
     * @returns
     * @memberof Show
     */
    renderForwardToSupplier(document) {
        return (
            <Grid container spacing={24} >
                <CardHeader
                    style={{ paddingTop: '0px' }}
                    title={
                        <Typography gutterBottom variant="body2" component="p">
                            <strong>
                            <ForwardIcon color='error' /> <Translate id="common.forwardReason" />:
                            </strong>
                            {' ' + document.forward_reason}
                        </Typography>
                    }
                />
                <Grid item xs={12}>
                    <DataTable
                        title={
                            <Typography gutterBottom variant="body2" component="p">
                                <strong>
                                    <Translate id="rawmaterialnonconformity.qualityUsersSupplier" />:
                                </strong>
                            </Typography>
                        }
                        data={document.users_quality}
                        configuration={{
                            columns: [{
                                header: <Translate id="officialNotices.name" />,
                                dataSource: 'full_name',
                            }]
                        }}
                    />
                </Grid>
            </Grid>
        )
    }


    /**
    * Render hystory section for all documents
    *
    * @returns
    * @memberof Show
    */
    renderHistory() {
        let documents = this.props.guaranteeReport ? this.props.guaranteeReport.documents ? this.props.guaranteeReport.documents : [] : []
        return (
            documents.map(document => {
                let titleId;
                let aditionalData;
                if (document.document_type === GUARANTEEDOCUMENT_NOTIFICATION) {
                    titleId = 'menu.rawMaterialsNotification';
                    aditionalData = this.renderAditionalData(document.development_engineer_name, document.users_quality);
                }
                if (document.document_type === GUARANTEE_NOTE_CREDITREPORT) {
                    titleId = 'moduleGuarantee.noteCredit';
                    aditionalData = this.renderNoteCreditData(document);
                }
                if (document.document_type === WORKFLOW_CANCELLATION) {
                    titleId = 'common.folioCancel';
                    aditionalData = <WorkflowCancel document={document} />;
                }
                if (document.document_type === GUARANTEE_FORWARD_TO_SUPPLIER) {
                    titleId = 'common.requestForwardedSupplier';
                    aditionalData = this.renderForwardToSupplier(document);
                }
                return (
                    <HistoryCard
                        key={document.token}
                        title={titleId}
                        userCreator={document.create_by}
                        creationDate={document.creation_date_long}
                        aditionalData={aditionalData}
                    />
                )

            })
        )
    }


    render() {
        let availableOperations = this.props.guaranteeReport ? this.props.guaranteeReport.get_available_operations : [];
        return (
            <Card>
                {availableOperations.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

                <LayoutHeaderWorkFlow {...this.props}
                    workFlow={this.props.guaranteeReport}
                    title={<Translate id="moduleGuarantee.reportGuarantee.titleReportGuarantee" />}
                />

                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {this.renderHistory()}

                <DialogAvailableOperations
                    {...this.props}
                    isValidToCancel={canUserValideModuleQuality(this.props.userRolesByCompany)}
                    options={availableOperations}
                    openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
                    setOpenDialog={this.setOpenDialog}
                    onClickToSendGuaranteeToReliabilityArea={this.onClickToSendGuaranteeToReliabilityArea}
                    onClickToSendGaranteeToSupplier={this.onClickToSendGuaranteeToSupplier}
                    onDeleteDraft={this.onDeleteDraft}
                    onClickEditGuarranteeReportInDraft={this.onClickEditGuarranteeReportInDraft}
                    onCancelDocument={this.onCancelDocument}
                    onAddCreditNote={this.onAddCreditNote}
                    onClickToAcceptAndClose={this.onClickToAcceptAndClose}
                    onClickToForwardToSupplier={this.onClickToForwardToSupplier}
                />

                <DialogAnalysisReport
                    {...this.props}
                    open={this.state.openModalAnalysisReport}
                    title={<Translate id="moduleGuarantee.reportGuarantee.titleAnalysisReport" />}
                    onClose={() => { this.setState({ openModalAnalysisReport: false }) }}
                    isSaving={this.props.isSaving}
                />

                {/** Dialog to delete draft */}
                < AddCommonItem
                    title={<Translate id="moduleGuarantee.removeGuarrantee" />}
                    message={<Translate id="moduleGuarantee.messageToRemove" />}
                    open={this.state.openDeleteDraft}
                    onClose={() => { this.setState({ openDeleteDraft: false }) }}
                    onSaveItem={this.onDeleteGuarrantee}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                />
                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

                <Dialog
                    open={this.state.openModalForwardComment}
                    onClose={this.showModalForward}
                    header={<Typography ><Translate id="common.comments" /></Typography>}
                    children={<TextField variant="outlined" onChange={(event) => this.saveComment(event.target.value)} rows={3} rowsMax={5} multiline fullWidth />}
                    actions={<CardActions>
                        <Button size="small" color="primary" onClick={this.forwardToSupplier} disabled={this.state.commentForward.length < 10 || this.props.isSaving}>
                            <Translate id="statusDescriptions.guaranteeForwardToSupplier" />
                        </Button>
                        <Button size="small" style={{ color: this.props.isSaving ? COLOR_STATUS_GRAY : COLOR_STATUS_DANGER }} onClick={this.onCloseCancelForwardModal} disabled={this.props.isSaving}>
                            <Translate id="common.cancel" />
                        </Button>
                    </CardActions>}
                />

            </Card>
        )
    };
}



/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let guaranteeReport = state.guaranteeReport.get("guaranteeReport");
    let selectedCompany = state.profile.get("selectedCompany");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        guaranteeReport: guaranteeReport ? guaranteeReport.toJS() : null,
        company: selectedCompany ? selectedCompany.toJS() : null,
        currentUser: user,
        isLoading: state.guaranteeReport.get('isLoadingGuarantee'),
        isSaving: state.guaranteeReport.get('isSaving'),
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}


/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadGuaranteeReport: (token) => {
            return dispatch(loadGuaranteeReport(token));
        },
        loadSupplier: (token) => {
            return (dispatch(loadSupplier(token)))
        },
        createAnalysisReport: (data) => {
            return dispatch(createAnalysisReport(data))
        },
        uploadFiles: (files) => {
            return dispatch(uploadFilesEvidence(files));
        },
        removeFiles: (fileName) => {
            return dispatch(removeFilesEvidence(fileName));
        },
        deleteDraftGuarrantee: (token) => {
            return dispatch(deleteDraftGuarrantee(token))
        },
        updateGuaranteeStatus: (token, status, comment = "") => {
            return dispatch(updateGuaranteeStatus(token, status, comment))
        },
        loadSupplierCode:(code)=>{
            return dispatch(loadSupplierCode(code))
        }
    }
}

const styles = theme => ({
    actionsToolbar: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    margin: {
        margin: theme.spacing.unit * 2,
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));
