import React from 'react'
/* Material UI import section */

import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'

const EmptyView = () => {
	const classes = useStyles()
	return (
		<div className={classes.content}>
			<Grid container className={classes.grid}>
				<Grid item xs={12}>
					<Typography align='center'>
						<ImportContactsIcon color='primary' />
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography align='center'>No existen directories</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography align='center'>Selecciona una categoría para mostrar información</Typography>
				</Grid>
			</Grid>
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	content: {
		maxHeight: `calc(100vh - ${265}px)`,
		minHeight: `calc(100vh - ${265}px)`,
		position: 'relative',
	},
	grid: {
		position: 'absolute',
		top: '30%',
		bottom: '50%',
	},
}))

export default EmptyView
