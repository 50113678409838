import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'

/** Import component */
import SearchInput from '../../common/SearchInput/SearchInput'
import DataTable from '../../common/DataTable'
import Toaster from '../../common/Toaster'
import LoadingPage from '../../common/LoadingPage'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import ReleaseClockIcon from '@material-ui/icons/Restore'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import SettingsIcon from '@material-ui/icons/Settings'

import OptionsMenu from '../../common/OptionsMenu'
import AddCommonItem from '../../common/AddCommonItem'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

import {
	loadReleaseCanledarsLockFromBackEnd,
	CHANGE_RELEASE_CALENDARS_SORT_CRITERIA,
	CHANGE_RELEASE_CALENDARS_QUERY,
	getReleaseCalendarLockCount,
	removeReleaseCalendarsMassive,
	CHANGE_RELEASE_CALENDARS_PAGINATION,
} from '../../../store/planning/ReleaseCalendarAction'

/**
 * component Index from release calendar lock
 */
class Index extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			itemToken: '',
			selectedTokens: [],
			showDeleteItemDialog: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadReleaseCanledarsLock(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getReleaseCalendarLockCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadReleaseCanledarsLock()
			this.props.getReleaseCalendarLockCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage ||
			prevProps.searchQuery !== this.props.searchQuery
		) {
			this.props.loadReleaseCanledarsLock(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof NonConformity
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof NonConformity
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * onRenderCellItem
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'number_of_ramps') {
			if (item.number_of_ramps === 5) return 'Todas las rampas'
			else return item.number_of_ramps
		}
	}

	/**
	 * an Seelct all items onSelectedChecks
	 * @param {*} selectedTokens
	 */
	onSelectedChecks(selectedTokens) {
		this.setState({ selectedTokens })
	}

	/**
	 * Handle state to set token of item to show options menu list
	 *
	 * @param {*} itemToken
	 * @memberof calendarLocks
	 */
	handleClickOptionsMenu(itemToken) {
		this.setState({
			itemToken,
		})
	}

	/**
	 * Handle state to show or hide menu options list
	 *
	 * @memberof calendarLocks
	 */
	handleCloseOptionsMenu() {
		if (!this.state.showDeleteItemDialog) {
			this.setState((state) => ({
				itemToken: '',
			}))
		}
	}

	/**
	 * Method to render OptionsMenu
	 *
	 * @param {*} item
	 * @returns
	 * @memberof ActionsList
	 */
	renderOptionsMenu(item) {
		var options = []
		options.push({
			itemClick: () => this.onMenuOptionItemClick(item, this.state.selectedTokens),
			tooltipTranslation: <Translate id='releaseCalendarLock.removeTitle' />,
			menuItemIcon: <SettingsIcon color='secondary' />,
		})
		return (
			<OptionsMenu
				itemToken={item.token}
				handleClickOptions={this.handleClickOptionsMenu}
				open={this.state.itemToken === item.token}
				handleClickAway={this.handleCloseOptionsMenu}
				options={options}
			/>
		)
	}

	/**
	 * onMenuOptionItemClick
	 * @param {*} item
	 * @param {*} selectedTokens
	 */
	onMenuOptionItemClick(item, selectedTokens = []) {
		this.setState({
			selectedTokens,
			showDeleteItemDialog: true,
			itemToken: '',
		})
	}

	/**
	 * Function to remove block calendar
	 */
	onRemoveCalendarsLock() {
		this.props
			.removeReleaseCalendarsLockMassive(this.state.selectedTokens)
			.then(() => {
				this.setState({
					selectedTokens: [],
					showDeleteItemDialog: false,
					itemToken: '',
				})
				this.props.history.push(`/releasecalendar`)
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * onRowClick
	 * @param {*} calendar
	 * @param {*} event
	 */
	onRowClick(calendar, event) {
		let tagName = event.target.tagName
		if (tagName !== 'INPUT') {
			this.props.history.push(`/releasecalendar/${calendar.token}`)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingReleaseCalendars === true) {
			return <LoadingPage />
		} else if (this.props.releaseCalendarLocks === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<Paper style={{ marginBottom: '4rem' }}>
					<Grid container spacing={0} justify='flex-end' direction='row'>
						{this.state.selectedTokens.length > 0 && (
							<Grid container spacing={0} justify='flex-end' direction='row'>
								{this.renderOptionsMenu({ token: 'selection' })}
							</Grid>
						)}
					</Grid>
					<DataTable
						data={this.props.releaseCalendarLocks}
						configuration={TableConfiguration(true)}
						sortBy={sortCriteria.by}
						sortAscending={sortCriteria.ascending}
						onChangeSortCriteria={this.onChangeSortCriteria}
						onRowClick={this.onRowClick}
						showFilters
						isIndex
						onRenderCellItem={this.onRenderCellItem}
						onSelectedCheck={(selectedTokens) => {
							this.onSelectedChecks(selectedTokens)
						}}
						selectedTokens={this.state.selectedTokens}
						totalRows={this.props.releaseCalendarLocksCount}
						rowsPerPage={this.props.releaseCalendarLocks.length}
						page={this.props.page}
						onChangePage={(newPage, newRowsPerPage) => {
							this.props.changePage(newPage, newRowsPerPage)
						}}
					/>
				</Paper>
			)
		}
	}

	/**
	 * Get release calendar lock counter
	 *
	 * @returns
	 * @memberof Index
	 */
	geCounterMessage() {
		if (this.props.releaseCalendarLocks) {
			let totalreleaseCalendarLocks = this.props.releaseCalendarLocks.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalreleaseCalendarLocks}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.releaseCalendarLocksCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Render list role
	 *
	 * @returns
	 * @memberof Show
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<ReleaseClockIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>

							<Tooltip title={<Translate id='releaseCalendarLock.addReleaseCalendar' />}>
								<Fab
									size='small'
									color='primary'
									onClick={() => {
										this.props.history.push(`/releasecalendar/create`)
									}}
								>
									<AddtIcon />
								</Fab>
							</Tooltip>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.releaseCalendarLock' />{' '}
						</Typography>
					}
					subheader={this.geCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to remove lists calendar lock */}
				<AddCommonItem
					title={<Translate id='releaseCalendarLock.removeTitle' />}
					message={<Translate id='releaseCalendarLock.removeMesageMassive' />}
					open={this.state.showDeleteItemDialog}
					onClose={() => {
						this.setState({ showDeleteItemDialog: false, selectedTokens: [] })
					}}
					onSaveItem={this.onRemoveCalendarsLock}
					isSaving={this.props.isSaving}
					isMessage={true}
					saveButtonTranslate={'common.delete'}
				/>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableConfiguration(showCheckSelect) {
	let columns = [
		{
			header: <Translate id='releaseCalendarLock.starHour' />,
			dataSource: 'time_hour_lock_start',
			isSortable: true,
		},
		{
			header: <Translate id='releaseCalendarLock.endHour' />,
			dataSource: 'time_hour_lock_end',
		},
		{
			header: <Translate id='releaseCalendarLock.typeLock' />,
			dataSource: 'lock_type_description',
			isSortable: true,
		},
		{
			header: <Translate id='releaseCalendarLock.startDate' />,
			dataSource: 'date_lock_start',
		},
		{
			header: <Translate id='releaseCalendarLock.endDate' />,
			dataSource: 'date_lock_end',
		},
		{
			header: <Translate id='releaseCalendarLock.messageLock' />,
			dataSource: 'message_lock',
			isSortable: true,
		},
		{
			header: <Translate id='releaseCalendarLock.numberRamp' />,
			dataSource: 'number_of_ramps',
			isSortable: true,
		},
	]
	if (showCheckSelect)
		columns.push({
			header: '',
			dataSource: 'selectCheck',
		})
	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingReleaseCalendars: state.releaseCalendarLock.get('isLoadingReleaseCalendars'),
		releaseCalendarLocks: state.releaseCalendarLock.get('releaseCalendarLocks')
			? state.releaseCalendarLock.get('releaseCalendarLocks').toJS()
			: null,
		sortCriteria: state.releaseCalendarLock.get('sortCriteria')
			? state.releaseCalendarLock.get('sortCriteria').toJS()
			: null,
		searchQuery: state.releaseCalendarLock.get('searchQuery'),
		rowsPerPage: state.releaseCalendarLock.get('rowsPerPage'),
		page: state.releaseCalendarLock.get('page'),
		releaseCalendarLocksCount: state.releaseCalendarLock.get('releaseCalendarLocksCount'),
		isSaving: state.releaseCalendarLock.get('isSaving'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadReleaseCanledarsLock: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadReleaseCanledarsLockFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		getReleaseCalendarLockCount: () => {
			dispatch(getReleaseCalendarLockCount())
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_RELEASE_CALENDARS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_RELEASE_CALENDARS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_RELEASE_CALENDARS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		removeReleaseCalendarsLockMassive: (selectedTokens) => {
			return dispatch(removeReleaseCalendarsMassive(selectedTokens))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
