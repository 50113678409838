import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { Typography, Tooltip, IconButton, Button } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import AceptedIcon from '@material-ui/icons/ThumbUp'
import RejectedIcon from '@material-ui/icons/ThumbDown'

/** Section resources and action */
import {
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_DANGER,
	BACKGROUND_COLOR_GRAY,
	COLOR_STATUS_WARNING,
} from '../../../store/helpers/StatusColorConstants'
import { MEDICAL_CHECK_VALIDATED, MEDICAL_CHECK_REJECYED } from '../../../store/helpers/ResourcesHelper'
import {
	loadMedicalCheckFromBackEnd,
	validateMedicalCheckInPlant,
} from '../../../store/contractors/MedicalCheckActions'
import { canValidateMedicalCheck } from '../../../store/helpers/RolesHelper'
import { converListToArray } from '../../../store/helpers/ListHelper'
import AddCommonItem from '../../common/AddCommonItem'
import Toaster from '../../common/Toaster'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { showSuccessToaster, showErrorToaster } from '../../../store/helpers/ToasterHelpers'

const Attachment = {
	MEDICAL_CHECK: 'MEDICAL_CHECK',
	DOPING: 'DOPING',
}

class Show extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAcceptedDialog: false,
			openRejectedDialog: false,
			attachmentShown: Attachment.MEDICAL_CHECK,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let token = this.props.match.params.token
		this.props.loadMedicalCheck(token)
	}

	/**
	 * function to determine status description and color to show chip in header
	 *
	 * @param {*} status
	 * @returns
	 * @memberof Show
	 */
	determineStatus(status) {
		let statusDescription
		let statusColor
		switch (status) {
			case 1: //addiedCheck
				statusDescription = 'contractorsModule.status.addiedCheck'
				statusColor = COLOR_STATUS_WARNING
				break
			case 2: //closedCheck
				statusDescription = 'contractorsModule.status.closedCheck'
				statusColor = COLOR_STATUS_SUCCESS
				break
			case 3: //validateCheck
				statusDescription = 'contractorsModule.status.validateCheck'
				statusColor = COLOR_STATUS_SUCCESS
				break
			case 4: //rejectedCheck
				statusDescription = 'contractorsModule.status.rejectedCheck'
				statusColor = COLOR_STATUS_DANGER
				break
			default:
				statusDescription = 'contractorsModule.status.closedCheck'
				statusColor = BACKGROUND_COLOR_GRAY
				break
		}
		return { label: <Translate id={statusDescription} />, color: statusColor }
	}

	/**
	 * Handle state to show or hide modal
	 *
	 * @memberof Show
	 */
	handleOpenCloseModal(nameModal) {
		this.setState({
			[nameModal]: !this.state[nameModal],
		})
	}

	/**
	 * Function to accepted
	 * @param {*} comments
	 */
	onAcceptedMedicalCheck() {
		this.props
			.validateMedicalCheckInPlant(this.props.match.params.token, MEDICAL_CHECK_VALIDATED, '')
			.then(() => {
				this.handleOpenCloseModal('openAcceptedDialog')
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * Function to accepted
	 * @param {*} comments
	 */
	onRejectedMedicalCheck(comments) {
		this.props
			.validateMedicalCheckInPlant(this.props.match.params.token, MEDICAL_CHECK_REJECYED, comments)
			.then(() => {
				this.handleOpenCloseModal('openRejectedDialog')
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	onAttachmentChange(value) {
		this.setState({ attachmentShown: value })
	}

	renderMedicalCheck() {
		const { classes, medicalcheck } = this.props
		const status = this.determineStatus(medicalcheck.status)
		let relativeFilesPath = medicalcheck.medical_check_url
		let relativeDopingPath = medicalcheck.doping_url
		const urlBase =
			this.state.attachmentShown === Attachment.MEDICAL_CHECK
				? `${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${relativeFilesPath}`
				: `${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${relativeDopingPath}`

		//EMPLOYEE PROFILE PHOTO
		let employeePhoto = medicalcheck.employeed ? medicalcheck.employeed.image_employed : ''
		let urlImageProfile = `${process.env.REACT_APP_SIP_URL_LOGOS_ADMINISTRATION}/${employeePhoto}`

		return (
			<Card style={{ height: '136vh' }}>
				<CardHeader
					avatar={
						<Avatar alt={medicalcheck.employed_name} src={urlImageProfile} className={classes.avatar} />
					}
					action={
						canValidateMedicalCheck(this.props.userRolesByCompany) &&
						medicalcheck.status === 1 && (
							<span>
								<IconButton
									color='secondary'
									onClick={() => this.handleOpenCloseModal('openAcceptedDialog')}
								>
									<Tooltip title={<Translate id='common.accept' />}>
										<AceptedIcon style={{ color: COLOR_STATUS_SUCCESS }} />
									</Tooltip>
								</IconButton>
								<IconButton
									color='secondary'
									onClick={() => this.handleOpenCloseModal('openRejectedDialog')}
								>
									<Tooltip title={<Translate id='common.reject' />}>
										<RejectedIcon style={{ color: COLOR_STATUS_DANGER }} />
									</Tooltip>
								</IconButton>
							</span>
						)
					}
					title={
						<Typography variant='h6'>
							{' '}
							{medicalcheck.folio} - {medicalcheck.employed_name}{' '}
						</Typography>
					}
					subheader={
						<Grid container spacing={12}>
							<Grid item xs={12}>
								{<Translate id='contractorsModule.medicalchecks.dateMedicalCheck' />}:
								{` ${medicalcheck.start_date} - ${medicalcheck.end_date}`}
							</Grid>
							<Grid item xs={12}>
								{<Translate id='contractorsModule.medicalchecks.contractName' />}:
								{` ${medicalcheck.supplier_name}`}
							</Grid>
							<Grid item xs={12}>
								{medicalcheck.status !== 1 && (
									<React.Fragment>
										<Typography variant='subtitle2'>
											<Translate id='contractorsModule.validatedBy' />:
											{` ${medicalcheck.validate_by} - ${medicalcheck.validation_date}`}
										</Typography>
										<Typography variant='subtitle2'>{medicalcheck.validator_comments}</Typography>
									</React.Fragment>
								)}
								<Chip style={{ color: '#fff', backgroundColor: status.color }} label={status.label} />
							</Grid>
						</Grid>
					}
				/>
				<CardContent style={{ height: '83%' }}>
					<div className={classes.attachmentsHeader}>
						<Button
							variant='outlined'
							onClick={() => this.onAttachmentChange(Attachment.MEDICAL_CHECK)}
							className={this.state.attachmentShown === Attachment.MEDICAL_CHECK && classes.enabledChip}
						>
							<Translate id='contractorsModule.medicalchecks.medicalCheck' />
						</Button>
						{medicalcheck.doping_url && (
							<Button
								variant='outlined'
								onClick={() => this.onAttachmentChange(Attachment.DOPING)}
								className={this.state.attachmentShown === Attachment.DOPING && classes.enabledChip}
							>
								<Translate id='contractorsModule.medicalchecks.doping' />
							</Button>
						)}
					</div>
					<div
						className='embed-responsive embed-responsive-16by9'
						style={{ overflow: 'hidden', height: '100%', width: '100%' }}
					>
						{/** this component not works in development 'cause x-frame-options is enabled in backend
						 * please comment this tag instead */}
						<iframe
							id='Medicalcheck'
							title='Chequeo medico'
							className='embed-responsive-item'
							src={`${urlBase}`}
							// src={`${urlBase}/${medicalcheck.file_name}`}
						/>
					</div>
				</CardContent>
			</Card>
		)
	}

	render() {
		return (
			<Grid container spacing={24}>
				<Grid item xs={12}>
					{this.props.medicalcheck && this.renderMedicalCheck()}

					<AddCommonItem
						title={<Translate id='contractorsModule.medicalchecks.acceptedDocument' />}
						message={<Translate id='contractorsModule.medicalchecks.acceptedDocumentMessage' />}
						open={this.state.openAcceptedDialog}
						onClose={() => this.handleOpenCloseModal('openAcceptedDialog')}
						onSaveItem={this.onAcceptedMedicalCheck}
						isSaving={this.props.isSavingChecks}
						isMessage={true}
					/>

					<AddCommonItem
						title={<Translate id='contractorsModule.medicalchecks.rejectedDocument' />}
						message={<Translate id='contractorsModule.medicalchecks.rejectedDocumentMessage' />}
						open={this.state.openRejectedDialog}
						onClose={() => this.handleOpenCloseModal('openRejectedDialog')}
						onSaveItem={this.onRejectedMedicalCheck}
						isSaving={this.props.isSavingChecks}
						isTextArea={true}
					/>

					<Toaster
						message={<Translate id={this.state.toasterMessage} />}
						open={this.state.showToaster}
						variant={this.state.toasterVariant}
						onClose={() => {
							this.setState({ showToaster: false })
						}}
					/>
				</Grid>
			</Grid>
		)
	}
}

const styles = (theme) => ({
	logoCompany: {
		maxHeight: 100,
	},
	styleTab: {
		backgroundColor: '#fff',
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		margin: 10,
		width: 90,
		height: 90,
	},
	attachmentsHeader: {
		display: 'flex',
		justifyContent: 'space-around',
		paddingBottom: 8,
	},
	enabledChip: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.common.white,
		'&:hover, &.Mui-focusVisible': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
		'&.Mui-active': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
	},
})

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingMedical: state.contractorMedicalcheck.get('isLoadingMedical'),
		isSavingChecks: state.contractorMedicalcheck.get('isSavingChecks'),
		medicalcheck: state.contractorMedicalcheck.get('medicalcheck')
			? state.contractorMedicalcheck.get('medicalcheck').toJS()
			: null,
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadMedicalCheck: (token) => {
			dispatch(loadMedicalCheckFromBackEnd(token))
		},
		validateMedicalCheckInPlant: (token, status, comments) => {
			return dispatch(validateMedicalCheckInPlant(token, status, comments))
		},
	}
}

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
