/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import { withStyles, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
//Import compinents section
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'

import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import DatePicker from '../../common/DateTimePicker/DatePicker'

const DownloadPendingFolio = (props) => {
	/**props section */
	const { classes } = props

	const [dateConfigurationModel, setDateConfigurationModelModel] = React.useState({
		endDate: null,
		startDate: null,
	})

	const canBeSavedDates = () => {
		if (dateConfigurationModel.endDate === null) return false
		if (dateConfigurationModel.startDate === null) return false
		return true
	}

	const onSaveButtonClick = () => {

		if (props.onDonwloadItems) {
			props.onDonwloadItems(dateConfigurationModel)
		}
	}

	const onChangeDate = (property, data) => {
		if (property === 'startDate') {
			setDateConfigurationModelModel({
				...dateConfigurationModel,
				[property]: data,
				endDate: null,
			})
		} else {
			setDateConfigurationModelModel({
				...dateConfigurationModel,
				[property]: data,
			})
		}
	}

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			header={
				<Toolbar>
					<Typography variant='h6'><Translate id='common.downloadPendingFolioReport' /></Typography>
				</Toolbar>
			}
			actions={
				<DialogActions
					view={SHOW_EDIT_VIEW}
					classes={classes}
					onCancel={props.onClose}
					onClose={props.onClose}
					onSaveItem={onSaveButtonClick}
					isSaving={props.isSaving}
					canBeSaved={canBeSavedDates()}
				/>
			}
		>
			<Grid container>
				
				<Grid item xs={6}>
					<Typography variant='subtitle2' color='primary' className={classes.titleText}>
						Fecha de inicio de periodo
					</Typography>
					<DatePicker
						opens={'left'}
						onChangeRange={(event) => onChangeDate('startDate', event)}
						startDate={dateConfigurationModel.startDate ? moment(dateConfigurationModel.startDate) : ''}
					/>
				</Grid>

				<Grid item xs={6}>
					<Typography variant='subtitle2' color='primary' className={classes.titleText}>
						Fecha fin de periodo
					</Typography>
					<DatePicker
						opens={'left'}
						onChangeRange={(event) => onChangeDate('endDate', event)}
						startDate={
							dateConfigurationModel.endDate ? moment(dateConfigurationModel.endDate, 'DD/MM/YYYY') : ''
						}
					/>
				</Grid>
			</Grid>
		</Dialog>
	)
}

const styles = (theme) => ({})

DownloadPendingFolio.propTypes = {
	open: propTypes.bool.isRequired,
}

DownloadPendingFolio.defaultProps = {
	open: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(DownloadPendingFolio))
