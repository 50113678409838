/** react imports section */
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core'
/** Store imports section */
import { loadCarriesCatalog } from '../../../../store/transportTender/transportTenderAction'
import { getTransportTenderfilterSelector } from '../../../../store/transportTender/transportTenderSelector'

import CarrierTable from './CarrierTable'
import AddCarrierPanel from './AddCarrierPanel'

const Carrier = (props) => {
	const { transportTenderId } = props
	const dispatch = useDispatch()

	const [openPanel, setOpenPanel] = useState(false)
	const transportTenderinfo = useSelector((state) => getTransportTenderfilterSelector(state))

	useEffect(() => {
		dispatch(loadCarriesCatalog())
	}, [dispatch])
	return (
		<div>
			<CarrierTable
				setOpenPanel={setOpenPanel}
				carrierBidding={transportTenderinfo.detail.CarrierBidding}
				transportTenderdetail={transportTenderinfo.detail}
			/>
			{openPanel && (
				<AddCarrierPanel
					onClose={() => setOpenPanel(false)}
					transportTenderId={transportTenderId}
					carrierBidding={transportTenderinfo.detail.CarrierBidding}
					catalogCarries={transportTenderinfo.carriers.filter(
						(x) => x.TypeShipmentId === transportTenderinfo.detail.TypeShipmentId
					)}
				/>
			)}
		</div>
	)
}
const styles = (theme) => ({})
export default withStyles(styles, { withTheme: true })(Carrier)
