/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'
/**Import MaterialUi section */
import {
    Grid, Typography, Avatar, 
    Button, FormHelperText, Tooltip
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import HelpIcon from '@material-ui/icons/Help';
/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import DatePicker from '../../common/DateTimePicker/DatePicker';
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import TimePickers from '../../common/DateTimePicker/TimePickers'

const AddLateralPanel = (props) => {
    /**props section */
    const { onSaveButtonClick, onCloseButtonClick,
        isSaving, classes
    } = props

    const [binddingModel, setBinddingModel] = useState({
        name: "",
        description: "",
        closingDate: null,
        publishDate: null,
        responsibles: [],
        category: null,
        closingDateTimer: "18:00"
    });
    const [isVisibleHelperPublishDate, setIsVisibleHelperPublishDate] = useState(false);
    const [isVisibleHelperClosingDate, setIsVisibleHelperClosingDate] = useState(false);
    const [isValidDates, setIsValidDate] = useState(true);

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(binddingModel)
        }
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setBinddingModel({
            ...binddingModel,
            [name]: value
        });
    }


    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (binddingModel.closingDate === null) return false
        if (binddingModel.publishDate === null) return false
        if (!isValidDates) return false
        return true;
    }

    const onChangeDate = (property, data) => {
        if ((binddingModel.closingDate && property === 'publishDate') && (moment(data, 'DD/MM/YYYY') < moment(binddingModel.closingDate, 'DD/MM/YYYY'))) {
            setIsVisibleHelperPublishDate(true)
            setIsValidDate(false)
        }
        else if ((binddingModel.publishDate && property === 'closingDate') && (moment(data, 'DD/MM/YYYY') > moment(binddingModel.publishDate, 'DD/MM/YYYY'))) {
            setIsVisibleHelperClosingDate(true)
            setIsValidDate(false)
        }
        else {
            setIsVisibleHelperPublishDate(false)
            setIsVisibleHelperClosingDate(false)
            setIsValidDate(true)
            setBinddingModel({
                ...binddingModel,
                [property]: data
            });
        }
    }

    let minPublishDate = binddingModel.closingDate ? moment(binddingModel.closingDate, 'DD/MM/YYYY').add(1, 'days') : moment();
    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<Translate id="biddingsModule.newRound" />}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >
                         <Grid item xs={12}>
                            <Typography variant="subtitle1" color="primary"><Translate id="biddingsModule.setNewDates" /></Typography>
                        </Grid><br/><br/>
                        <Grid item xs={11}>
                            <Typography >{<Translate id="biddingsModule.create.closingDate" />}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title={<Translate id="biddingsModule.create.titleClosingDate" />}>
                                <HelpIcon />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                onChangeRange={(event) => onChangeDate('closingDate', event)}
                                startDate={binddingModel.closingDate ? moment(binddingModel.closingDate, 'DD/MM/YYYY') : ''}
                                minDate={moment()}
                            />
                            {
                                isVisibleHelperClosingDate && <FormHelperText>
                                    <Translate id="biddingsModule.create.messageClosinDateError" />
                                </FormHelperText>
                            }
                            <br />
                        </Grid><br />
                        <Grid item xs={12}>
                            <TimePickers
                                label={<Translate id="biddingsModule.create.closingDateTime" />}
                                name='closingDateTimer'
                                value={binddingModel.closingDateTimer ? binddingModel.closingDateTimer : null}
                                onChange={onPropertyChange}
                                disabled={IsNullOrEmpty(binddingModel.closingDate)}
                            />
                            <br />
                        </Grid><br />
                        <Grid item xs={11}>
                            <Typography >{<Translate id="biddingsModule.create.publishDate" />}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title={<Translate id="biddingsModule.create.titlePublishDate" />}>
                                <HelpIcon />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>

                            <DatePicker
                                onChangeRange={(event) => onChangeDate('publishDate', event)}
                                startDate={binddingModel.publishDate ? moment(binddingModel.publishDate, 'DD/MM/YYYY') : ''}
                                disabled={binddingModel.closingDate === null}
                                minDate={minPublishDate}
                            />
                            {
                                isVisibleHelperPublishDate && <FormHelperText>
                                    <Translate id="biddingsModule.create.messagePublishDateError" />
                                </FormHelperText>
                            }
                            <br />
                        </Grid><br />
                    </Grid>

                </div>
                <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            disabled={!canBeSaved() || isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    }
});

AddLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onValidateTemplate: propTypes.func
}

AddLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onValidateTemplate: () =>
        console.warn('Callback [onValidateTemplate] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddLateralPanel))