import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import 'react-virtualized/styles.css'
/**Import material UI Section*/
import {
    withStyles, Typography, List, ListItem, ListItemText
} from '@material-ui/core';
import { Translate } from "react-localize-redux";

/**Import components section*/
import DivisaConfiguration from './DivisaConfiguration'
import EvaluationsCriteriaResponse from './EvaluationsCriteriaResponse'
import TableVirtualizeMaterials from './TableVirtualizeMaterials'
import TableVirtualizeServices from './TableVirtualizeServices'
/**Import helperts section */
import {
    getCriteriaService,
    getItemsIndividuals,
} from '../../../../store/biddings/BindingsSelectors'
import { percengeFormat } from '../../../../store/helpers/AmountHelper'
import {
    supplierCanEditBidding,
    isCategoryRawMaterials,
    isCategoryService
} from '../../../../store/helpers/BiddingHelper'

const SupplierResponseContainer = (props) => {
    /**String props section */
    const { classes, translate } = props;
    // /**Bools props section */
    const { isSaving, isExternalUser } = props
    // /**Arrays props section */
    const { userProfile, userRolesByCompany, divisas, incoterms } = props
    // /**Functions props section */
    const { onAddResponseSupplier, onDivisaInBiddingFromBackEnd, onAddIncotermFromBackEnd,
        onAddResponseItemSupplierConfigPanel, onAddResponseGralSupplierConfigPanel,
        onSetMaterialStatusBySupplierInternal
    } = props

    /**Objects props section */
    const { bidding, } = props
    const evaluationCriteria = getCriteriaService(bidding);
    const individuals = getItemsIndividuals(bidding ? bidding.Items : null, bidding);
    const [identifierLoading, setIdentifierLoading] = useState("")

    /**
 * Get headers indvisuals items
 * @param {*} items 
 */
    const getHeaderIndiviualItemsTable = (items) => {
        let isCategoryMaterials = isCategoryRawMaterials(bidding)
        let columns = []
        columns.push(
            {
                label: <Translate id={'biddingsModule.name'} />,
                dataKey: 'Name',
                // flexGrow: 1.0,
                width: isCategoryRawMaterials(bidding) ? (isExternalUser ? 400 : 500) : 300
            }
        )
        if (items !== null) {
            items.forEach(item => {
                columns.push({
                    label: `${item.Name} - ${percengeFormat(item.Weighing)}`,
                    dataKey: item.Name,
                    width: isCategoryRawMaterials(bidding) ? null : 200
                });
            });
        }
        if (isCategoryMaterials) {
            columns.push({
                label: <Translate id={'biddingsModule.measurementUnit'} />,
                dataKey: 'PurchaseMeasurementUnit',
                width: (isCategoryRawMaterials(bidding) && isExternalUser) ? 100 : null
            })
            columns.push({
                label: <Translate id={'biddingsModule.currency'} />,
                dataKey: 'DivisaCode',
                width: (isCategoryRawMaterials(bidding) && isExternalUser) ? 100 : null
            })
            columns.push({
                label: <Translate id={'biddingsModule.incoterm'} />,
                dataKey: 'IncotermCode',
                width: (isCategoryRawMaterials(bidding) && isExternalUser) ? 100 : null
            })
            columns.push({
                label: "",
                dataKey: 'Icon',
                // numeric: true,
                width: 150
            })
        }

        return columns;
    }

    /**
     * Render componet 
     */
    return (
        <div className={classes.sectionsContainer}>
            {
                isCategoryService(bidding) && <DivisaConfiguration
                    divisas={divisas}
                    onDivisaInBiddingFromBackEnd={onDivisaInBiddingFromBackEnd}
                    isSaving={isSaving}
                    incoterms={incoterms}
                    onAddIncotermFromBackEnd={onAddIncotermFromBackEnd}
                    bidding={bidding}
                    userProfile={userProfile}
                    userRolesByCompany={userRolesByCompany}
                    isExternalUser={isExternalUser}
                    translate={translate}

                />
            }
            <div style={{ flexBasis: '100%' }}>
                <Typography className={classes.paddingColor} color="primary" variant="subtitle1">
                    {<Translate id={'biddingsModule.evaluationsCriteria'} />}
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={
                                <Typography style={{ color: 'green' }}>
                                    {' '}
                                    <Translate id={'biddingsModule.services.totalPercetnage'} />
                                    {': '}
                                    100%
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
                <EvaluationsCriteriaResponse
                    bidding={bidding}
                    onAddResponseGralSupplierConfigPanel={onAddResponseGralSupplierConfigPanel}
                />
            </div>
            <br />

            <div style={{ flexBasis: '100%' }}>
                <Typography className={classes.paddingColor} color="primary" variant="subtitle1">
                    <Translate id={'biddingsModule.listItems'} />
                </Typography>

                {
                    isCategoryRawMaterials(bidding) &&
                    <TableVirtualizeMaterials
                        items={individuals}
                        columns={getHeaderIndiviualItemsTable(evaluationCriteria ? evaluationCriteria.Criterias : null)}
                        onAddResponseItemSupplierConfigPanel={onAddResponseItemSupplierConfigPanel}
                        isSaving={isSaving}
                        onAddResponseSupplier={onAddResponseSupplier}
                        setIdentifierLoading={setIdentifierLoading}
                        identifierLoading={identifierLoading}
                        canBeEditedInformation={supplierCanEditBidding(bidding, userProfile, userRolesByCompany, isExternalUser)}
                        onSetMaterialStatusBySupplierInternal={onSetMaterialStatusBySupplierInternal}
                        translate={translate}
                    />
                }

                {
                    isCategoryService(bidding) &&
                    <TableVirtualizeServices
                        items={individuals}
                        columns={getHeaderIndiviualItemsTable(evaluationCriteria ? evaluationCriteria.Criterias : null)}
                        onAddResponseItemSupplierConfigPanel={onAddResponseItemSupplierConfigPanel}
                        isSaving={isSaving}
                        onAddResponseSupplier={onAddResponseSupplier}
                        setIdentifierLoading={setIdentifierLoading}
                        identifierLoading={identifierLoading}
                        canBeEditedInformation={supplierCanEditBidding(bidding, userProfile, userRolesByCompany, isExternalUser)}
                        translate={translate}
                    />
                }
            </div>
        </div>
    )
}

const styles = (theme) => ({
    tab: {
        minHeight: "auto",
    },
    textTab: {
        fontSize: "0.75rem !important"

    },
    sectionsContainer: {
        marginTop: "20px",
        maxHeight: `calc(100vh - ${305}px)`,
        minHeight: `calc(100vh - ${305}px)`,
        overflow: "auto",
        paddingBottom: "15px"
    },
    flexGrow: {
        flexGrow: 1
    },
    paddingColor: {
        paddingRight: "0px !important",
        display: "flex",
        backgroundColor: "#f8f9fa"

    },
    maxWidth: {
        maxWidth: "200px"
    },
    maxWidthItem: {
        maxWidth: "200px",
    },
    noWrapText: {
        maxWidth: "200px",
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }

});

SupplierResponseContainer.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
}

SupplierResponseContainer.defaultProps = {
    isLoading: false,
}

export default withRouter(
    withStyles(styles, { withTheme: true })(SupplierResponseContainer)
);
