import { fromJS } from 'immutable';

import * as shippingActions from './ShippingOrderAction';

/**
 * shippingsInitialState initial state
 */
export const shippingsInitialState = fromJS({
    isLoadingShippings: true,
    shippingOrders: null,
    loadAlerts: null,
    loadAlert: null,
    isLoadingShipping: false,
    shippingOrder: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    page: 0,
    rowsPerPage: 20,
    searchQuery: "",
    shippingOrdersCount: null,
    isSaving: false,
})

export default function shippingsReducer(state = shippingsInitialState, action) {
    switch (action.type) {
        case shippingActions.START_LOADING_SHIPPING_ORDERS: {
            return state.merge({
                isLoadingShippings: action.isLoading
            })
        }
        case shippingActions.SET_SHIPPING_ORDERS_LIST: {
            let shippingsLists = fromJS(action.shippingOrders);
            return state.merge({
                shippingOrders: shippingsLists,
                isLoadingShippings: false
            })
        }
        case shippingActions.CHANGE_SHIPPING_ORDERS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case shippingActions.CHANGE_SHIPPING_ORDERS_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case shippingActions.CHANGE_SHIPPING_ORDERS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case shippingActions.IS_LOADING_SHIPPING_ORDER: {
            return state.merge({
                isLoadingShipping: action.isLoadingShipping,
                shippingOrder: null,
            })
        }
        case shippingActions.SET_SHIPPING_ORDER: {
            return state.merge({
                isSaving: false,
                isLoadingShipping: false,
                shippingOrder: fromJS(action.shippingOrder)
            })
        }
        case shippingActions.IS_SAVING_SHIPPING_ORDER: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case shippingActions.SET_SHIPPING_ORDERS_COUNT: {
            return state.merge({
                shippingOrdersCount: action.count
            })
        }
        case shippingActions.UPDATE_DETAIL_FROM_SHIPPING_ORDER: {
            return state.updateIn(['shippingOrder', 'details'], (allDetails) => {
                let index = allDetails.findIndex((item) => {
                    return item.get('token') === action.detail.token
                });
                return allDetails.setIn([index], fromJS(action.detail)).set('isSaving', false);
            });
        }
        case shippingActions.ADD_FILES_SHIPPING_ORDER_SUPPLIER: {
            let oldList = state.getIn(['shippingOrder', action.listItems]).toJS();
            let filesToAdda = action.files.filter(newFileSupl => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFileSupl.name
                })
            })
            let newList = [...oldList, ...(filesToAdda)];
            return state.setIn(['shippingOrder', action.listItems], fromJS(newList));
        }
        case shippingActions.REMOVE_FILES_SHIPPING_ORDER_SUPPLIER: {
            let oldList = state.getIn(['shippingOrder', action.listItems]).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['shippingOrder', action.listItems], fromJS(newList));
        }
        default:
            return state;
    }
}