import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import DataTable from '../common/DataTable'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import ExpedientsIcon from '@material-ui/icons/FormatListNumbered'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadExpedientsBySupplierFromBackEnd,
	CHANGE_EXPEDIENT_SUPPLIERS_SORT_CRITERIA,
	CHANGE_EXPEDIENT_SUPPLIERS_PAGINATION,
	CHANGE_EXPEDIENT_SUPPLIERS_SORT_QUERY,
	getExpedientsBySupplierCount,
} from '../../store/expedientSupplier/ExpedientSupplierAction'
import { converListToArray } from '../../store/helpers/ListHelper'
import { GetSupplierTypeDescription } from '../../store/helpers/ExpedientSupplier'

/**
 * Expedient supplier view
 *
 * @class Expedient supplier
 * @extends {Component}
 */
class ExpedientsSupplier extends Component {
	/**
	 * Create an instance of Expedient suppliers
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadExpedientsBySupplier(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getExpedientsBySupplierCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadExpedientsBySupplier()
			this.props.getExpedientsBySupplierCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadExpedientsBySupplier(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	onRenderCellItem(dataSource, item) {
		if (dataSource === 'supplier_type') {
			return GetSupplierTypeDescription(item.supplier_type, item.supplier_classification)
		}
		if (dataSource === 'treatment') {
			return (
				<React.Fragment>
					{item.is_manufacturer && (
						<Typography variant='caption'>
							<Translate id='expedientSupplier.treatmentDescription.isManufacturer' />
							<br />
						</Typography>
					)}
					{item.is_distributor_marketer && (
						<Typography variant='caption'>
							<Translate id='expedientSupplier.treatmentDescription.isDistributorMarketer' />
							<br />
						</Typography>
					)}
					{item.is_maquilador && (
						<Typography variant='caption'>
							<Translate id='expedientSupplier.treatmentDescription.isMaquilador' />
							<br />
						</Typography>
					)}
					{item.is_spare_parts && (
						<Typography variant='caption'>
							<Translate id='expedientSupplier.treatmentDescription.isSpareParts' />
							<br />
						</Typography>
					)}
					{item.is_service && (
						<Typography variant='caption'>
							<Translate id='expedientSupplier.treatmentDescription.isService' />
							<br />
						</Typography>
					)}
					{item.is_administration && (
						<Typography variant='caption'>
							<Translate id='expedientSupplier.treatmentDescription.isAdministration' />
							<br />
						</Typography>
					)}
					{item.is_sales_machinery_equipment && (
						<Typography variant='caption'>
							<Translate id='expedientSupplier.treatmentDescription.isSalesMachineryEquipment' />
							<br />
						</Typography>
					)}
					{item.is_workshops_customer && (
						<Typography variant='caption'>
							<Translate id='expedientSupplier.treatmentDescription.isWorkshopsCustomer' />
						</Typography>
					)}
				</React.Fragment>
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}
	/**
	 * Get expedient Suppliers counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getExpedientSupplierCounterMessage() {
		const { classes } = this.props
		if (this.props.expedientSuppliers) {
			let totalexpedientSuppliers = this.props.expedientSuppliers.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{totalexpedientSuppliers}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.expedientSupplierCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingExpedientSuppliers === true) {
			return <LoadingPage />
		} else if (this.props.expedientSuppliers === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.expedientSuppliers}
					configuration={TableConfiguration}
					onRenderCellItem={this.onRenderCellItem}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(expedient) => {
						this.props.history.push(`/expedientsbysupplier/${expedient.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.expedientSupplierCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof ExpedientSupplier
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<ExpedientsIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput onChangeSearchValue={this.onChangeSearchValue} />
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='expedientSupplier.expedientsTitle' />{' '}
						</Typography>
					}
					subheader={this.getExpedientSupplierCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
		},
		{
			header: <Translate id='expedientSupplier.dateRequest' />,
			dataSource: 'creation_date',
		},
		{
			header: <Translate id='expedientSupplier.sendBy' />,
			dataSource: 'create_by',
		},
		{
			header: <Translate id='expedientSupplier.supplierType' />,
			dataSource: 'supplier_type_description',
		},
		{
			header: <Translate id='expedientSupplier.documentsRequest' />,
			dataSource: 'count_total_files',
		},
		{
			header: <Translate id='expedientSupplier.documentsAccepted' />,
			dataSource: 'count_files_accepted',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingExpedientSuppliers: state.expedientSuppliers.get('isLoadingExpedientSuppliers'),
		expedientSuppliers: state.expedientSuppliers.get('expedientSuppliers')
			? state.expedientSuppliers.get('expedientSuppliers').toJS()
			: null,
		sortCriteria: state.expedientSuppliers.get('sortCriteria')
			? state.expedientSuppliers.get('sortCriteria').toJS()
			: null,
		searchQuery: state.expedientSuppliers.get('searchQuery'),
		page: state.expedientSuppliers.get('page'),
		rowsPerPage: state.expedientSuppliers.get('rowsPerPage'),
		expedientSupplierCount: state.expedientSuppliers.get('expedientSupplierCount'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadExpedientsBySupplier: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadExpedientsBySupplierFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_EXPEDIENT_SUPPLIERS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_EXPEDIENT_SUPPLIERS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_EXPEDIENT_SUPPLIERS_SORT_QUERY,
				query: query,
			})
		},
		getExpedientsBySupplierCount: () => {
			return dispatch(getExpedientsBySupplierCount())
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
})

export default withauthenticatedLayout(
	withRouter(
		withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ExpedientsSupplier))
	)
)
