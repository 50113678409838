/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserParameters,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import { convertToTitleCase } from './../helpers/StringHelper'

/** Employees index constants */
export const IS_LOADING_EMPLOYEES = 'IS_LOADING_EMPLOYEES'
export const SET_EMPLOYEES_LIST = 'SET_EMPLOYEES_LIST'
export const CHANGE_EMPLOYEES_SORT_CRITERIA = 'CHANGE_EMPLOYEES_SORT_CRITERIA'
export const CHANGE_EMPLOYEES_QUERY = 'CHANGE_EMPLOYEES_QUERY'
export const CHANGE_EMPLOYEES_PAGINATION = 'CHANGE_EMPLOYEES_PAGINATION'
export const SET_EMPLOYEES_COUNT = 'SET_EMPLOYEES_COUNT'
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'

/** Employee detail Constants */
export const IS_LOADING_EMPLOYEE = 'IS_LOADING_EMPLOYEE'
export const SET_EMPLOYEE = 'SET_EMPLOYEE'
export const IS_SAVING_EMPLOYEE = 'IS_SAVING_EMPLOYEE'
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const IS_CLOSED_REGISTERS_ACCESS_CONTROL = 'IS_CLOSED_REGISTERS_ACCESS_CONTROL'
export const ADD_FILE_EMPLOYEE = 'ADD_FILE_EMPLOYEE'
export const REMOVE_FILE_EMPLOYEE = 'REMOVE_FILE_EMPLOYEE'

/**
 * Set flag to determine if is loading employees list
 *
 * @param {*} isLoadingEmployees
 * @returns
 */
function isLoadingEmployees(isLoadingEmployees) {
	return {
		type: IS_LOADING_EMPLOYEES,
		isLoadingEmployees,
	}
}

/**
 * Set employees list in store
 *
 * @param {*} employees
 * @returns
 */
function setEmployeesList(employees) {
	return {
		type: SET_EMPLOYEES_LIST,
		employees,
	}
}

/**
 * Set employees list count in store
 *
 * @param {*} count
 * @returns
 */
function setEmployeesCount(count) {
	return {
		type: SET_EMPLOYEES_COUNT,
		count,
	}
}

/**
 * Add an employee to list
 *
 * @param {*} employee
 * @returns
 */
function createEmployee(employee) {
	return {
		type: ADD_EMPLOYEE,
		employee,
	}
}

/**
 * Set flag to determine if is loading specific employee data
 *
 * @param {*} isLoadingEmployee
 * @returns
 */
function isLoadingEmployee(isLoadingEmployee) {
	return {
		type: IS_LOADING_EMPLOYEE,
		isLoadingEmployee,
	}
}

/**
 * Set flag to determine if is saving specific employee data
 *
 * @param {*} isSavingEmployee
 * @returns
 */
function isSavingEmployee(isSavingEmployee) {
	return {
		type: IS_SAVING_EMPLOYEE,
		isSavingEmployee,
	}
}

/**
 * Set employee data in store
 *
 * @param {*} employee
 * @returns
 */
function setEmployee(employee) {
	return {
		type: SET_EMPLOYEE,
		employee,
	}
}

/**
 * Action uploading close register from access control portal
 * @param {*} isClosed
 */
function isCloseAccessControlPortal(isClosed) {
	return {
		type: IS_CLOSED_REGISTERS_ACCESS_CONTROL,
		isCloseRergisters: isClosed,
	}
}

function addFile(files) {
	return {
		type: ADD_FILE_EMPLOYEE,
		files,
	}
}

function removeFile(fileName) {
	return {
		type: REMOVE_FILE_EMPLOYEE,
		fileName,
	}
}

/**
 * Get employees list from backend
 *
 * @export
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @returns
 */
export function loadEmployeesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, isActive) {
	return function (dispatch, getState) {
		dispatch(isLoadingEmployees(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contractoremployed/all`
		let data = {
			CompanyCode: selectedCompany.get('company_code'),
			SupplierCode: supplierCode,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
			allEmployees: false,
			IsActive: isActive,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setEmployeesList(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingEmployees(false))
				console.error('Error getting the employees by company list', error.response)
			})
	}
}

/**
 * Action loaAlldEmployeesFromBackEnd
 */
export function loaAlldEmployeesFromBackEnd(allEmployees = false) {
	return function (dispatch, getState) {
		dispatch(isLoadingEmployees(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}

		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contractoremployed/search/${supplierCode}/all/${allEmployees}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				dispatch(isLoadingEmployees(false))
				if (serverResponse.status === 200) {
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingEmployees(false))
				console.error('Error getting the employees by company list', error.response)
			})
	}
}

/**
 * Get Employees list count
 *
 * @export
 * @returns
 */
export function getEmployeesCount(isActive) {
	return function (dispatch, getState) {
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contractoremployed/count`
		let data = {
			SupplierCode: supplierCode,
			IsActive: isActive,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setEmployeesCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the employee's count", error.response)
			})
	}
}

/**
 * Add new employee to list
 *
 * @export
 * @param {*} employee
 * @returns
 */
export function addEmployee(employee, supplierName) {
	return (dispatch, getState) => {
		dispatch(isSavingEmployee(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let supplierCode = undefined
		if (user.profile && user.profile.company_code) {
			supplierCode = user.profile.company_code
		}
		let data = {
			SupplierCode: supplierCode,
			SupplierName: supplierName,
			Name: convertToTitleCase(employee.employee_full_name, ' '),
			InsuranceNumber: employee.social_security_number,
			RFC: employee.rfc_number,
			CURP: employee.curp_Number,
			ShortName: employee.short_Name,
			FirstSurname: employee.first_Surname,
			SecondSurname: employee.second_Surname,
			CompanyId: selectedCompany.get('id'),
			Position: employee.position,
			Department: employee.department,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contractoremployed`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(createEmployee(serverResponse.data))
					dispatch(isSavingEmployee(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingEmployee(false))
				console.error('Error adding a employee', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Upload employee photo
 *
 * @export
 * @param {*} userId
 * @param {*} fileBase64
 * @returns
 */
export function uploadEmployeePhoto(employeeToken, photoBase64) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let endPoint = `${
			process.env.REACT_APP_SIP_SUPPLIERS
		}/contractoremployed/avatar/${employeeToken}/${selectedCompany.get('id')}`
		let data = {
			fileBase64: photoBase64,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((response) => {
				dispatch(setEmployee(response.data))
				return Promise.resolve()
			})
			.catch((err) => {
				console.error('Error uploading user avatar', err.response)
				return Promise.reject()
			})
	}
}

/**
 * Get an specific Employee data from backend
 *
 * @export
 * @param {*} token
 * @returns
 */
export function loadEmployeeFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingEmployee(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contractoremployed/${token}/${selectedCompany.get('id')}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setEmployee(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load employee', error.response)
				dispatch(isLoadingEmployee(false))
				return Promise.reject()
			})
	}
}

/**
 * Update data of an Employee
 *
 * @export
 * @param {*} employee
 * @returns
 */
export function updateDataEmployee(employee) {
	return function (dispatch, getState) {
		dispatch(isSavingEmployee(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let data = {
			EmployedToken: employee.token,
			Name: convertToTitleCase(employee.employee_full_name, ' '),
			InsuranceNumber: employee.social_security_number,
			RFC: employee.rfc_number,
			CURP: employee.curp_Number,
			ShortName: employee.short_Name,
			FirstSurname: employee.first_Surname,
			SecondSurname: employee.second_Surname,
			IsActive: employee.is_active,
			CompanyId: selectedCompany.get('id'),
			Position: employee.position,
			Department: employee.department,
		}

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contractoremployed`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingEmployee(true))
					dispatch(setEmployee(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error update data of an employee', error.response)
				dispatch(isSavingEmployee(false))
				return Promise.reject(error.response)
			})
	}
}

export function downloadEmployeesCardsPDF(supplierCode, employeesTokens) {
	return (dispatch, getState) => {
		dispatch(isLoadingEmployee(true))
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contractoremployed/employeecards/pdf`
		let data = {
			SupplierCode: supplierCode,
			UsersTokens: employeesTokens,
		}
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var file = new Blob([serverResponse.data], { type: 'application/pdf' })
					var fileURL = URL.createObjectURL(file)
					window.open(fileURL)
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isLoadingEmployee(false))
				console.error('Error in download access cards', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to close registers obsolets from protal access contractor
 * @param {*} supplierCode
 */
export function closeRegistersObsoletesFromBack() {
	return function (dispatch, getState) {
		dispatch(isCloseAccessControlPortal(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contractoraccess/closeregistersobsoletes`

		return axios
			.post(endPoint, null, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isCloseAccessControlPortal(false))
				}
			})
			.catch((error) => {
				console.error('Error to close registers obsolets', error.response)
				dispatch(isCloseAccessControlPortal(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to uploadFiles
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFiles(files) {
	return (dispatch, getState) => {
		dispatch(isSavingEmployee(true))

		let user = getState().oidc.user
		let tokenEmployee = getState().contractorEmployees.get('employee').get('token')
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contractoremployed/${tokenEmployee}/upload`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse.data))
					dispatch(isSavingEmployee(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingEmployee(false))
				console.error('Error to upload evidence file(s) from employee', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files
 *
 * @export
 * @param {*} fileName
 * @returns
 */
export function removeFiles(fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let tokenEmployee = getState().contractorEmployees.get('employee').get('token')
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contractoremployed/${tokenEmployee}/remove`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFile(fileName))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) employee', error.response)
				return Promise.reject()
			})
	}
}
