/** React imports section */
import React from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'

/** Material UI imports section */
import { Divider, Typography } from '@material-ui/core'
import { useState } from 'react'

/** Common imports section */
import DialogOperation from '../../common/DialogOperation'

/** Store imports section */
import {
	ChangeTenderStatus,
	changeCarrierInformationStatus,
	downloadCarrierLogPDF,
	sendSecondRoundCarrier,
	downloadConsignmentNoteInformation,
} from '../../../store/transportTender/transportTenderAction'

import TransportTenderInformation from './TransportTenderInformation'
import HeaderDetail from './HeaderDetail'
import CreateTransportTender from '../createTransportTender/CreateTransportTender'
import { optionsTransportTender } from '../../../store/helpers/TransportTenderHelper'
import DialogComponent from '../common/DialogComponent'
import { TransportTendeStatus } from '../../../store/helpers/AppConstants'
import DialogInfoTarif from '../common/DialogInfoTarif'

const Template = (props) => {
	const {
		transportTenderdetail,
		selectedCompany,
		transportType,
		transportTenderId,
		isInternalUser,
		setToasterMessageConfig,
		transportTenderinfo,
		islodigSaving,
		typeShipmentsOptions,
		userProfile,
		carrierInformation,
		isLoadingDownlaod,
		setOpenWineCellarDateDialog,
		isTransportWinner,
	} = props
	const dispatch = useDispatch()

	const [openDialog, setOpenDialog] = useState(false)
	const [openEditDialog, setOpenEditDialog] = useState(false)
	const [openDialogConfirm, setOpenDialogConfirm] = useState({ open: false, title: '', description: '' })
	const [openCalculateTarif, setOpenCalculateTarif] = useState(false)

	const canBeSubmitTender = () => {
		if (transportTenderdetail.TransportTypeCode === '') return false
		if (transportTenderdetail.Destinations.length === 0) return false
		return true
	}
	const canBeSentLongToWinning = () => {
		if (transportTenderdetail.Proposals.filter((x) => !x.Winning).length > 0) return false
		return true
	}

	const onSubmitTender = (status) => {
		if (
			TransportTendeStatus.DRAFT === transportTenderdetail.Status &&
			!canBeSubmitTender() &&
			TransportTendeStatus.CANCEL !== status
		)
			setOpenDialogConfirm({
				open: true,
				title: <Translate id='carrierTenders.onSubmit.sendTenderCarriers' />,
				description: <Translate id='carrierTenders.errorOnSubmit.errorSendTenderCarriers' />,
			})
		else if (
			TransportTendeStatus.SENT_TO_CARRIER === transportTenderdetail.Status &&
			!canBeSentLongToWinning() &&
			TransportTendeStatus.CANCEL !== status &&
			TransportTendeStatus.IN_EVALUATION !== status
		)
			setOpenDialogConfirm({
				open: true,
				title: <Translate id='carrierTenders.onSubmit.wendLogWinningCarrier' />,
				description: <Translate id='carrierTenders.errorOnSubmit.errorWendLogWinningCarrier' />,
			})
		else {
			return dispatch(ChangeTenderStatus(status, transportTenderId))
				.catch((err) => {
					setToasterMessageConfig({
						showToaster: true,
						toasterMessage: err ? (
							<Translate id={err} />
						) : (
							<Translate id='carrierTenders.error.errorSubmittingBid' />
						),
						toasterVariant: 'error',
					})
				})
				.finally(() => {
					setOpenDialog(false)
				})
		}
	}
	const onChangeStatusCarrier = (status) => {
		dispatch(changeCarrierInformationStatus(status, userProfile.profile.company_code, transportTenderdetail.Id))
			.catch((err) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: <Translate id='carrierTenders.error.errorSubmittingBid' />,
					toasterVariant: 'error',
				})
			})
			.finally(() => {
				setOpenDialog(false)
			})
	}
	const onDonwloadCarrierLog = () => {
		dispatch(downloadCarrierLogPDF(transportTenderdetail.Id)).catch((err) => {
			setToasterMessageConfig({
				showToaster: true,
				toasterMessage: <Translate id='carrierTenders.error.errorDownloadLog' />,
				toasterVariant: 'error',
			})
		})
	}

	const onDownloadConsignmentNoteInformation = () => {
		dispatch(downloadConsignmentNoteInformation(transportTenderdetail.Id)).catch((err) => {
			setToasterMessageConfig({
				showToaster: true,
				toasterMessage: <Translate id='carrierTenders.error.errorDownloadCartaPorte' />,
				toasterVariant: 'error',
			})
		})
	}

	const onSendSecondRoundCarrier = (status) => {
		dispatch(sendSecondRoundCarrier(transportTenderdetail.Id))
			.catch((err) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: <Translate id={err} />,
					toasterVariant: 'error',
				})
			})
			.finally(() => {
				setOpenDialog(false)
			})
	}
	return (
		<div>
			<HeaderDetail
				transportTenderdetail={transportTenderdetail}
				selectedCompany={selectedCompany}
				setOpenDialog={setOpenDialog}
				isInternalUser={isInternalUser}
				carrierInformation={carrierInformation}
				onDonwloadCarrierLog={onDonwloadCarrierLog}
				onDownloadConsignmentNoteInformation={onDownloadConsignmentNoteInformation}
				isLoadingDownlaod={isLoadingDownlaod}
				isTransportWinner={isTransportWinner}
				isLoading={islodigSaving}
			/>
			<Divider />
			<TransportTenderInformation
				transportTenderdetail={transportTenderdetail}
				transportType={transportType}
				isInternalUser={isInternalUser}
				setOpenEditDialog={setOpenEditDialog}
				islodigSaving={islodigSaving}
				typeShipmentsOptions={typeShipmentsOptions}
				setOpenWineCellarDateDialog={setOpenWineCellarDateDialog}
				onOpenCalculateTarif={setOpenCalculateTarif}
			/>
			<DialogOperation
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				options={optionsTransportTender(
					isInternalUser,
					onSubmitTender,
					onChangeStatusCarrier,
					transportTenderdetail.Status,
					transportTenderdetail.CanBeSendLogBookToCarrier,
					onSendSecondRoundCarrier,
					transportTenderdetail.HasExpired
				)}
			/>
			<CreateTransportTender
				setOpenDialog={setOpenEditDialog}
				openDialog={openEditDialog}
				transportTenderinfo={transportTenderinfo}
				transportTenderdetail={transportTenderdetail}
				isEdit={true}
				setToasterMessageConfig={setToasterMessageConfig}
			/>
			<DialogComponent
				open={openDialogConfirm.open}
				onClose={() => setOpenDialogConfirm({ open: false, title: '', description: '' })}
				children={
					<div>
						<Typography variant='body1'>{openDialogConfirm.description}</Typography>
					</div>
				}
				canBeSaved={true}
				title={openDialogConfirm.title}
			/>
			<DialogInfoTarif
				open={openCalculateTarif}
				onClose={() => setOpenCalculateTarif(false)}
				transportType={transportTenderdetail.TransportTypeDescription}
				maxKm={
					transportTenderdetail &&
					transportTenderdetail.MaxTransportZoneDirection &&
					transportTenderdetail.MaxTransportZoneDirection.KM
				}
				total={transportTenderdetail.FeeAmount}
				maxTransportZoneDirection={
					transportTenderdetail &&
					transportTenderdetail.MaxTransportZoneDirection &&
					transportTenderdetail.MaxTransportZoneDirection.Direction &&
					transportTenderdetail.MaxTransportZoneDirection.Direction
				}
			/>
		</div>
	)
}

export default Template
