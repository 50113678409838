/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Translate, getTranslate } from 'react-localize-redux'
import { useSelector } from 'react-redux'

import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'

/**Import MaterialUi section */
import {
    Grid, Typography, Avatar, TextField, Button, FormHelperText, Tooltip
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import HelpIcon from '@material-ui/icons/Help';
/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import DatePicker from '../../common/DateTimePicker/DatePicker';
import ResponsiblesContainer from './ResponsiblePopper'
import AvatarContainer from './AvatarContainer'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { ROLE_BIDDING_RESPONSIBLE } from '../../../store/helpers/RolesHelper'
import TimePickers from '../../common/DateTimePicker/TimePickers'
import { getResponsiblesFromBidding } from '../../../store/helpers/BiddingHelper'
const AddLateralPanel = (props) => {
    const translate = useSelector((state) => getTranslate(state.localize))

    const CHARACTER_LIMIT_NAME = 100;
    const CHARACTER_LIMIT_DESCRIPTION = 250;

    /**props section */
    const { onSaveButtonClick, onCloseButtonClick,
        isSaving, classes, internalUsers,
        userProfile
    } = props
    let users = internalUsers.find(role => {
        return Object.keys(role)[0] === ROLE_BIDDING_RESPONSIBLE
    })

    const [binddingModel, setBinddingModel] = useState({
        name: "",
        description: "",
        closingDate: null,
        publishDate: null,
        responsibles: [],
        category: null,
        closingDateTimer: "18:00"
    });
    const [isVisibleHelperPublishDate, setIsVisibleHelperPublishDate] = useState(false);
    const [isVisibleHelperClosingDate, setIsVisibleHelperClosingDate] = useState(false);
    const [isValidDates, setIsValidDate] = useState(true);

    /**Use effect section */
    useEffect(() => {
        if (props.bidding && (binddingModel.category === null || binddingModel.responsibles.length === 0) && userProfile) {
            let allResponsibles = (getResponsiblesFromBidding(internalUsers, props.bidding.Responsibles, userProfile))
            setBinddingModel({
                ...binddingModel,
                name: props.bidding.Name,
                description: props.bidding.Description,
                category: {
                    label: props.bidding.CategoryName,
                    value: props.bidding.CategotyId
                },
                responsibles: allResponsibles,
                closingDate: !IsNullOrEmpty(props.bidding.PlannedClosingDate) ? moment(props.bidding.PlannedClosingDate).format('DD/MM/YYYY') : "",
                publishDate: !IsNullOrEmpty(props.bidding.PlannedOpeningDate) ? moment(props.bidding.PlannedOpeningDate, 'DD/MM/YYYY') : "",
                closingDateTimer: !IsNullOrEmpty(props.bidding.PlannedClosingDate) ? moment(props.bidding.PlannedClosingDate).format('HH:mm') : "18:00"
            })
        }
    }, [])

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(binddingModel)
        }
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setBinddingModel({
            ...binddingModel,
            [name]: value
        });
    }


    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (IsNullOrEmpty(binddingModel.name)) return false
        if (binddingModel.category === null) return false
        if (binddingModel.responsibles.length === 0) return false
        if (!isValidDates) return false
        return true;
    }

    const onChangeDate = (property, data) => {
        if ((binddingModel.closingDate && property === 'publishDate') && (moment(data, 'DD/MM/YYYY') < moment(binddingModel.closingDate, 'DD/MM/YYYY'))) {
            setIsVisibleHelperPublishDate(true)
            setIsValidDate(false)
        }
        else if ((binddingModel.publishDate && property === 'closingDate') && (moment(data, 'DD/MM/YYYY') > moment(binddingModel.publishDate, 'DD/MM/YYYY'))) {
            setIsVisibleHelperClosingDate(true)
            setIsValidDate(false)
        }
        else {
            setIsVisibleHelperPublishDate(false)
            setIsVisibleHelperClosingDate(false)
            setIsValidDate(true)
            setBinddingModel({
                ...binddingModel,
                [property]: data
            });
        }
    }

    const onAddResponsible = (user) => {
        setBinddingModel({
            ...binddingModel,
            "responsibles": binddingModel.responsibles.concat(user)
        });
    }

    const onRemoveResponsible = (user) => {
        let newListUserNames = binddingModel.responsibles.filter(u => {
            return u.user_name !== user.user_name
        })
        setBinddingModel({
            ...binddingModel,
            "responsibles": newListUserNames
        });
    }
    let minPublishDate = binddingModel.closingDate ? moment(binddingModel.closingDate, 'DD/MM/YYYY').add(1, 'days') : moment();

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={`${translate("biddingsModule.table.folio")} | ${props.bidding ? props.bidding.FormatedFolio : ""}`}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >
                        <Grid item xs={12} style={{ display: "flex" }}>
                            <Typography variant='subtitle1' color='primary' className={classes.margingTopText}>{translate("biddingsModule.create.category")}</Typography>
                            <Typography variant='subtitle1' className={classes.margingTopText}>{": "}{binddingModel ? binddingModel.category && binddingModel.category.label : ""}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={<Translate id="biddingsModule.create.projectName" />}
                                className={classes.textField}
                                name='name'
                                onChange={onPropertyChange}
                                margin="normal"
                                variant="outlined"
                                multiline
                                value={binddingModel ? binddingModel.name : ""}
                                inputProps={{ maxLength: CHARACTER_LIMIT_NAME }}
                                helperText={`${binddingModel ? binddingModel.name.length : ""}/${CHARACTER_LIMIT_NAME}`}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={<Translate id="biddingsModule.create.projectDescription" />}
                                className={classes.textField}
                                onChange={onPropertyChange}
                                multiline
                                name='description'
                                margin="normal"
                                variant="outlined"
                                value={binddingModel ? binddingModel.description : 0}
                                inputProps={{ maxLength: CHARACTER_LIMIT_DESCRIPTION }}
                                helperText={`${binddingModel ? binddingModel.description.length : 0}/${CHARACTER_LIMIT_DESCRIPTION}`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Typography >{<Translate id="biddingsModule.create.closingDate" />}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title={<Translate id="biddingsModule.create.titleClosingDate" />}>
                                        <HelpIcon />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker
                                        opens={"left"}
                                        drops={"up"}
                                        onChangeRange={(event) => onChangeDate('closingDate', event)}
                                        startDate={binddingModel.closingDate ? moment(binddingModel.closingDate, 'DD/MM/YYYY') : ''}
                                        minDate={moment()}
                                    />
                                    {
                                        isVisibleHelperClosingDate && <FormHelperText>
                                            <Translate id="biddingsModule.create.messageClosinDateError" />
                                        </FormHelperText>
                                    }

                                    <br />

                                </Grid><br />
                                <Grid item xs={12}>
                                    <TimePickers
                                        label={<Translate id="biddingsModule.create.closingDateTime" />}
                                        name='closingDateTimer'
                                        value={binddingModel.closingDateTimer ? binddingModel.closingDateTimer : null}
                                        onChange={onPropertyChange}
                                        disabled={IsNullOrEmpty(binddingModel.closingDate)}
                                    />
                                    <br />
                                </Grid><br />
                                <Grid item xs={11}>
                                    <Typography >{<Translate id="biddingsModule.create.publishDate" />}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title={<Translate id="biddingsModule.create.titlePublishDate" />}>
                                        <HelpIcon />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>

                                    <DatePicker
                                        opens={"left"}
                                        drops={"up"}
                                        onChangeRange={(event) => onChangeDate('publishDate', event)}
                                        startDate={binddingModel.publishDate ? moment(binddingModel.publishDate, 'DD/MM/YYYY') : ''}
                                        disabled={binddingModel.closingDate === null}
                                        minDate={minPublishDate}
                                    />
                                    {
                                        isVisibleHelperPublishDate && <FormHelperText>
                                            <Translate id="biddingsModule.create.messagePublishDateError" />
                                        </FormHelperText>
                                    }

                                    <br />

                                </Grid><br />
                                <Grid item xs={12}>
                                    <Typography >{<Translate id="biddingsModule.create.responsibles" />}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <ResponsiblesContainer
                                        onAddResponsible={onAddResponsible}
                                        responsiblesAdded={binddingModel.responsibles ? binddingModel.responsibles : []}
                                        onRemoveResponsible={onRemoveResponsible}
                                        users={users ? users[ROLE_BIDDING_RESPONSIBLE] : []}
                                        userProfile={userProfile}
                                    />
                                </Grid>
                                <Grid item xs={10} >
                                    <Grid container style={{ marginLeft: "-30px" }}>
                                        {
                                            binddingModel.responsibles.map(user => {
                                                return (
                                                    <Grid item xs={1} key={user.user_name} style={{ marginLeft: "9px" }}>
                                                        <AvatarContainer
                                                            responsible={user}
                                                            onRemoveResponsible={onRemoveResponsible}
                                                            userProfile={userProfile}
                                                            canBeRemoveResponsible={true}
                                                        />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>


                    </Grid>

                </div>
                <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            disabled={!canBeSaved() || isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    }


});

AddLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onValidateTemplate: propTypes.func
}

AddLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onValidateTemplate: () =>
        console.warn('Callback [onValidateTemplate] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddLateralPanel))