import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'

import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import { DoneAll, WarningRounded } from '@material-ui/icons'
import { Button, TextField, Typography } from '@material-ui/core'
import DatePicker from '../../common/DateTimePicker/DatePicker'
import Dropzone from '../../common/Dropzone'

import moment from 'moment'

const CreateCertificationsPanel = ({
	classes,
	open,
	onClose,
	onSubmit,
	isLoading,
	certificationInformation,
	setCertificationInformation,
}) => {
	const canBeSaved = () => {
		if (validationDates()) return false
		if (certificationInformation.CertificationName.length === 0) return false
		if (certificationInformation.StartDateCertification.length === 0) return false
		if (certificationInformation.EndDateCertification.length === 0) return false
		if (certificationInformation.Document === null || certificationInformation.Document.length === 0) return false
		return true
	}
	const validationDates = () => {
		const startDate = certificationInformation.StartDateCertification.valueOf()
		const endDate = certificationInformation.EndDateCertification.valueOf()
		if (startDate <= endDate) return false
		if (
			certificationInformation.StartDateCertification.length !== 0 &&
			certificationInformation.EndDateCertification.length !== 0
		)
			return true
		return false
	}
	const onChangeTextField = (event) => {
		const { name, value } = event.target
		setCertificationInformation({
			...certificationInformation,
			[name]: value,
		})
	}
	const onChangeDate = (property, data) => {
		setCertificationInformation({
			...certificationInformation,
			[property]: data,
		})
	}
	const onChangeFile = (files) => {
        const file = files[files.length - 1]
		setCertificationInformation({
			...certificationInformation,
			Document: [file],
		})
		return Promise.resolve(true)
	}
	const onRemoveFile = (file) => {
		setCertificationInformation({
			...certificationInformation,
			Document: [],
		})
		return Promise.resolve('Ok')
	}
	return (
		<LateralPanel open={open} onClose={onClose}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id={'certifications.lateralPanel.title'} />}
				onCloseButtonClick={onClose}
				icon={<DoneAll color='secondary' />}
			/>
			<div className={classes.content}>
				<TextField
					id='standard-with-placeholder'
					label={'Nombre de la certificación'}
					onChange={onChangeTextField}
					name='CertificationName'
					value={certificationInformation.CertificationName}
					inputProps={{
						style: {
							height: '10px',
						},
					}}
					size='small'
					margin='dense'
					fullWidth
					variant='outlined'
				/>
				<div className={classes.dateContent}>
					<div>
						<Typography variant='caption'>
							<Translate id={'certifications.lateralPanel.startDate'} />
						</Typography>
						<DatePicker
							opens={'left'}
							drops={'down'}
							onChangeRange={(event) => onChangeDate('StartDateCertification', event)}
							startDate={
								certificationInformation.StartDateCertification
									? moment(certificationInformation.StartDateCertification, 'DD/MM/YYYY')
									: null
							}
						/>
					</div>
					<div>
						<Typography variant='caption'>
							<Translate id={'certifications.lateralPanel.endDate'} />
						</Typography>
						<DatePicker
							opens={'left'}
							drops={'down'}
							onChangeRange={(event) => onChangeDate('EndDateCertification', event)}
							startDate={
								certificationInformation.EndDateCertification
									? moment(certificationInformation.EndDateCertification, 'DD/MM/YYYY')
									: null
							}
						/>
					</div>
				</div>
				{validationDates() && (
					<div className={classes.helpText}>
						<WarningRounded />
						<Typography style={{ color: 'white', marginLeft: '2px' }}>
							<Translate id='certifications.lateralPanel.errorDates' />
						</Typography>
					</div>
				)}
				<TextField
					id='standard-with-placeholder'
					label={'Notas de certificación'}
					onChange={onChangeTextField}
					name='Notes'
					value={certificationInformation.Notes}
					size='small'
					margin='dense'
					fullWidth
					variant='outlined'
					multiline
					rows={4}
				/>
				{certificationInformation && (
					<Dropzone
						files={certificationInformation.Document}
						onChange={onChangeFile}
						onDeleteFile={onRemoveFile}
						acceptedFiles={['pdf']}
						dropzoneText={'Agregar archivos con formato PDF'}
					/>
				)}
			</div>
			<Button
				data-tut='reactour-save'
				data-cy='btn-save'
				type='button'
				color='primary'
				variant='outlined'
				className={!canBeSaved() || isLoading ? classes.btnCreateDisabled : classes.btnCreate}
				onClick={onSubmit}
				disabled={!canBeSaved() || isLoading}
			>
				{<Translate id='certifications.lateralPanel.save' />}
			</Button>
		</LateralPanel>
	)
}
const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	content: {
		margin: '8px',
	},
	dateContent: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	btnCreateDisabled: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `rgba(223, 226, 230, 1)`,
	},
	cardMedia: {
		backgroundColor: 'rgba(223, 226, 230, 1)',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		borderRadius: '8px',
	},
	actionBtn: {
		width: '453px',
		display: 'flex',
		justifyContent: 'flex-end',
		paddingTop: '5px',
	},
	cardContent: {
		padding: '8px',
	},
	infoCardMedia: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	infoTitleCardMedia: {
		color: theme.palette.primary.dark,
		fontWeight: 'bold',
	},
	helpText: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.common.white,
		padding: '8px',
		borderRadius: '8px',
		margin: '3px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
})
CreateCertificationsPanel.propTypes = {
	open: propTypes.bool,
	isLoading: propTypes.bool,
	onClose: propTypes.func,
	onSubmit: propTypes.func,
	setcertificationInformation: propTypes.func,
}
CreateCertificationsPanel.defaultProps = {
	open: false,
	isLoading: false,
	onClose: () => console.warn('No [onclose] callBack defined'),
	onSubmit: () => console.warn('No [onSubmit] callBack defined'),
	setCertificationInformation: () => console.warn('No [setCertificationInformation] Callback defined'),
}
export default withRouter(withStyles(styles, { withTheme: true })(CreateCertificationsPanel))
