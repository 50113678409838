
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import PowerSettingIcon from '@material-ui/icons/PowerSettingsNew';
import AddCommonItem from '../common/AddCommonItem';
import Tab from '@material-ui/core/Tab';
import SendIcon from '@material-ui/icons/Send';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AlternativeSupplierIcon from '@material-ui/icons/SwapCallsTwoTone';
import { AppBar, Tabs, Fab } from '@material-ui/core';

import LoadingPage from '../common/LoadingPage';
import Toaster from '../common/Toaster';
import CostSummary from './components/CostSummary';
import ResendProvedureDialog from './components/ResendProvedureDialog';
import SuppliersCard from './components/SuppliersCard';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import {canBeValidateModuleDevelopment} from '../../store/helpers/RolesHelper';
import { converListToArray } from '../../store/helpers/ListHelper'
import { showSuccessToaster, showErrorToaster } from '../../store/helpers/ToasterHelpers';

import { loadAlternativeProvedureeFromBackEnd, finalizeProvedureFromBackend,
    resendAlternativeProveduriaFromBackend} from '../../store/alternativeProvedure/AlternativeProveduceAction';

/**
 * Container show notice
 */
class Show extends Component {

    /**
     * Create an instance notice
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openFinalizeAlternating: false,
            activeTab: 0,
            openResendPA: false,
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let provedureToken = this.props.match.params.token;
        this.props.loadAlternativeProvedure(provedureToken);
    }

    /**
     * On finalize alternating
     */
    onFinalizeAlternating() {
        this.setState({
            openFinalizeAlternating: true,
        })
    }

    /**
     * Method to change the active tab
     */
    onClickToActiveTab = (event, value) => {
        this.setState({ activeTab: value });
    };

    /**
     * Finalize un project of an provedure
     */
    onFinalizeProvedureFromBackend() {
        let provedureToken = this.props.match.params.token;
        this.props.finalizeProvedureFromBackend(provedureToken)
            .then(() => {
                this.setState({openFinalizeAlternating: false})
                this.setState(showSuccessToaster("common.saveCorrectly"));
            }).catch((error) => {
                this.setState(showErrorToaster("common.errorToAdd"));
            });
    }

    /**
     * onOpenResendPAClick
     */
    onOpenResendPAClick() {
        this.setState({
            openResendPA: true
        })
    }

    /**
     * Action to resend alternative proveduia from supplier
     * @param {*} supplier 
     */
    resendAlternativeProveduriaFromSupplier(supplier){
        let provedureToken = this.props.match.params.token;
        this.props.resendAlternativeProveduria(provedureToken, supplier)
            .then((responsetoken) => {
                this.setState({openResendPA: false})
                this.setState(showSuccessToaster("common.addCorrectly"));
                this.props.loadAlternativeProvedure(responsetoken);
                this.props.history.push(`/alternativeprovedure/${responsetoken}`);
            }).catch((error) => {
                console.log("error", error)
                this.setState(showErrorToaster("common.errorToAdd"));
            });
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.provedure !== null) {
            const { classes } = this.props;
            return (
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        <InformationMemoCard
                            {...this.props} />
                    </Grid>
                    <Grid item xs={6}>
                        <InformationProgressCard
                            {...this.props} />
                    </Grid>
                    <Grid item xs={12}>
                        <AppBar position="static" color="default">
                            <Tabs indicatorColor="secondary" textColor="secondary"
                                className={classes.styleTab}
                                value={this.state.activeTab}
                                onChange={this.onClickToActiveTab}>
                                <Tab label={<Translate id="common.supplier"/>} />
                                <Tab label={<Translate id="common.costSummary"/>} />
                            </Tabs>
                        </AppBar>
                        {this.state.activeTab === 0 &&
                            <Grid item xs={12} >
                                <Divider />
                                <SuppliersCard {...this.props} />
                            </Grid>
                        }
                        {this.state.activeTab === 1 &&
                            <Grid item xs={12}>
                                <Divider />
                                <CostSummary {...this.props} />
                            </Grid>}
                    </Grid>
                </Grid>
            )
        }
    }

    render() {
        let folioProvedure = this.props.provedure ? this.props.provedure.folio : "";
        const { classes } = this.props;
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <AlternativeSupplierIcon />
                        </Avatar>
                    }
                    action={
                        <div className={classes.actionsToolbar}>{
                            <span>
                                {this.props.provedure && (!this.props.provedure.is_closed || !this.props.provedure.status_is_closed) && 
                                    canBeValidateModuleDevelopment(this.props.userRolesByCompany) &&
                                <Tooltip title={<Translate id="alternatingProveeduria.finalizeAlternatingTitle"/>} ><IconButton color="primary"
                                    data-cy="btn-edit"
                                    className={classes.edit}
                                    onClick={this.onFinalizeAlternating}>
                                    <PowerSettingIcon />
                                </IconButton>
                                </Tooltip>}

                                {this.props.provedure && canBeValidateModuleDevelopment(this.props.userRolesByCompany) &&
                                <Tooltip title={<Translate id={"alternatingProveeduria.resendAlternativeProveduria"} />}>
                                    <Fab
                                        size="small"
                                        color="primary"
                                        onClick={() => { this.onOpenResendPAClick() }}>
                                        <SendIcon />
                                    </Fab>
                                </Tooltip>}

                            </span>  
                        }
                        </div>
                    }
                    title={<Typography variant="button"><Translate id="alternatingProveeduria.provedureInformation"/>: <strong>{folioProvedure}</strong></Typography>}
                />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {this.state.openFinalizeAlternating &&
                    <AddCommonItem
                        title={<Translate id="alternatingProveeduria.finalizeAlternatingTitle"/>}
                        message={<Translate id="alternatingProveeduria.finalizeDataAlternating"/>}
                        open={this.state.openFinalizeAlternating}
                        onClose={() => { this.setState({ openFinalizeAlternating: false }) }}
                        onSaveItem={this.onFinalizeProvedureFromBackend}
                        isSaving={this.props.isSaving}
                        isMessage={true}
                    />}

                    <ResendProvedureDialog
                        show={this.state.openResendPA}
                        onClose={() => { this.setState({ openResendPA: false }) }}
                        isSaving={this.props.isSaving}
                        onResendProveduriaFromSupplier={this.resendAlternativeProveduriaFromSupplier}
                    />

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        )
    }

}

/**
 * Information MemoCard
 *
 * @class InformationMemoCard
 * @extends {Component}
 */
class InformationMemoCard extends Component {
    render() {
        return (
            <Paper>
                <List>
                    <ListItem divider>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="alternatingProveeduria.folio"/></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.provedure.folio}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="memos.stage"/></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.provedure.stage}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="common.creationDate"/></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.provedure.creation_date_long}
                                </React.Fragment>
                            } />
                    </ListItem>
                </List>
            </Paper>
        );
    }
}

/**
 * Information MemoCard
 *
 * @class InformationMemoCard
 * @extends {Component}
 */
class InformationProgressCard extends Component {
    render() {
        return (
            <Paper>
                <List>
                    <ListItem divider>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="common.status"/></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.provedure.status_description}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="alternatingProveeduria.deliveryDateSample"/></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.provedure.deadline_delivery}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="alternatingProveeduria.commentDeveloper"/></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.provedure.comment}
                                </React.Fragment>
                            } />
                    </ListItem>
                </List>
            </Paper>
        );
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let provedure = state.alternativeProvedures.get("provedure");
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        user: user,
        isLoading: state.alternativeProvedures.get('isLoading'),
        isSaving: state.alternativeProvedures.get('isSaving'),
        provedure: provedure ? provedure.toJS() : null,
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadAlternativeProvedure: (token) => {
            return dispatch(loadAlternativeProvedureeFromBackEnd(token));
        },
        finalizeProvedureFromBackend: (token) => {
            return dispatch(finalizeProvedureFromBackend(token));
        },
        resendAlternativeProveduria:(token, supplier) =>{
            return dispatch(resendAlternativeProveduriaFromBackend(token, supplier))
        }
    }
}

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    actionsToolbar: {
        display: "flex"
    },
    styleTab: {
        backgroundColor: "#fff",
    },
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));