/** Import react section */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Avatar, CardHeader, Typography } from '@material-ui/core'
import { Poll } from '@material-ui/icons'
import SearchInput from '../../../common/SearchInput/SearchInput'

const GuidelinesHeader = (props) => {
	const { onChangeSearchValue, queryParams } = props
	const classes = styles()
	return (
		<CardHeader
			avatar={
				<Avatar>
					<Poll />
				</Avatar>
			}
			action={
				<div style={classes.constainer}>
					<div style={classes.marginSearch}>
						<SearchInput
							onChangeSearchValue={onChangeSearchValue}
							value={!queryParams && queryParams.query ? queryParams.query : ''}
						/>
					</div>
				</div>
			}
			title={
				<Typography variant='button'>
					<Translate id='specificationsSection.guidelines.tileMenu' />
				</Typography>
			}
		/>
	)
}
const styles = (theme) => ({
	constainer: {
		display: 'flex',
	},
	marginSearch: {
		marginRight: '5px',
	},
})
GuidelinesHeader.propTypes = {
	onChangeSearchValue: PropTypes.func.isRequired,
	queryParams: PropTypes.shape({
		query: PropTypes.string,
	}),
}
GuidelinesHeader.defaultProps = {
	onChangeSearchValue: () => console.warn('[onChangeSearchValue] not defined!'),
}

export default withRouter(withStyles(styles, { withTheme: true })(GuidelinesHeader))
