import { IsNullOrEmpty } from './StringHelper'


export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}


/**
 * Get an absolute url address
 *
 * @export
 * @param {*} endPoint
 * @returns
 */
export function getAbsoluteUrlAddresss(endPoint) {
    let baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
    if (IsNullOrEmpty(endPoint)) {
        return process.env.REACT_APP_ROOT === '/' ? `${baseUrl}` : `${baseUrl}${process.env.REACT_APP_ROOT}/${endPoint}`

    } else {
        return process.env.REACT_APP_ROOT === '/' ? `${baseUrl}/${endPoint}` : `${baseUrl}${process.env.REACT_APP_ROOT}/${endPoint}`
    }
}


/**
 * Get relative url address
 *
 * @export
 * @param {*} endPoint
 * @returns
 */
export function getRelativeUrlAddresss(endPoint) {
    if (IsNullOrEmpty(endPoint)) {
        return process.env.REACT_APP_ROOT;
    } else {
        return process.env.REACT_APP_ROOT === '/' ? `/${endPoint}` : `${process.env.REACT_APP_ROOT}/${endPoint}`
    }
}