/** React imports section */
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'

/** Material-UI imports section */
import { Button, withStyles } from '@material-ui/core'
import PlaceIcon from '@material-ui/icons/PlaceTwoTone'

/** Common imports section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import LoadTypeSelector from '../../common/LoadTypeSelector'

/** Store imports section */
import { UpdateDelivery, addDelivery } from '../../../../store/transportTender/transportTenderAction'
import CreateManualDelivery from './CreateManualDelivery'
import { getIsLoadingSavingSelector } from '../../../../store/transportTender/transportTenderSelector'
const DeliveriesPanel = (props) => {
	const { classes, onClose, openPanel, isEdit, delivery, setToasterMessageConfig, transportTenderdetail } = props
	const isLoading = useSelector((state) => getIsLoadingSavingSelector(state))
	const dispatch = useDispatch()

	const [information, setInformation] = useState({
		deliveryNumber: '',
		noDeliveryNumber: false,
	})

	const [changeSelect, setChangeSelect] = useState({ value: 0, label: 'Manual' })
	const onSubmit = () => {
		if (isEdit) {
			dispatch(UpdateDelivery(information, delivery.Id, transportTenderdetail.Id))
				.then(() => {
					''
				})
				.catch((err) => {
					setToasterMessageConfig({
						showToaster: true,
						toasterMessage: 'Error al guardar la información',
						toasterVariant: 'error',
					})
				})
				.finally(() => {
					onClose()
				})
		} else {
			dispatch(addDelivery(information, openPanel.dealId, transportTenderdetail.Id))
				.then(() => {
					''
				})
				.catch((err) => {
					setToasterMessageConfig({
						showToaster: true,
						toasterMessage: 'Error al guardar la información',
						toasterVariant: 'error',
					})
				})
				.finally(() => {
					onClose()
				})
		}
	}

	const canBeSaved = () => {
		if (information && information.noDeliveryNumber) {
			return true
		} else {
			if (information.deliveryNumber === null) return false
			if (information.deliveryNumber.length < 6) return false
			return true
		}
	}

	const onChangeinformation = (event) => {
		const { name, value } = event.target
		setInformation({
			...information,
			[name]: value,
		})
	}

	const onChangeCheck = (event) => {
		const { name, checked } = event.target
		setInformation({
			...information,
			[name]: checked,
		})
	}

	useEffect(() => {
		if (delivery) {
			setInformation({
				deliveryNumber: delivery.DeliveryNumber,
			})
		}
	}, [delivery])
	return (
		<LateralPanel onClose={onClose}>
			<PanelHeader
				isIconMaterial
				titlePanel={'Entregas'}
				onCloseButtonClick={onClose}
				icon={<PlaceIcon color='secondary' />}
			/>
			<div className={classes.bodyContainer}>
				{!isEdit && (
					<LoadTypeSelector
						setChange={setChangeSelect}
						classes={classes}
						value={changeSelect}
						disabled={true}
					/>
				)}

				{((changeSelect && changeSelect.value === 0) || isEdit) && (
					<>
						<CreateManualDelivery
							classes={classes}
							information={information}
							onChange={onChangeinformation}
							onChangeCheck={onChangeCheck}
						/>
					</>
				)}
			</div>
			<Button
				data-tut='reactour-save'
				data-cy='btn-save'
				type='button'
				color='primary'
				variant='outlined'
				className={classes.btnCreate}
				onClick={onSubmit}
				disabled={!canBeSaved() || isLoading}
			>
				{delivery ? (
					<Translate id='carrierTenders.deals.editDelivery' />
				) : (
					<Translate id='carrierTenders.deals.addDelivery' />
				)}
			</Button>
		</LateralPanel>
	)
}
const styles = (theme) => ({
	bodyContainer: {
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
	},

	displayFlex: {
		display: 'flex',
	},

	closeIcon: {
		margin: '-20px 10px',
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	title: {
		marginTop: '7px',
	},
	datecontainer: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridGap: '0px  20px',
	},
	datePicker: {
		marginTop: '10px',
	},
	errorContent: {
		display: 'flex',
	},
	errorMessage: {
		color: 'red',
		paddingLeft: '8px',
	},
})
export default withStyles(styles, { withTheme: true })(DeliveriesPanel)
