/** React imports section */
import React from 'react'
import moment from 'moment'
import propTypes from 'prop-types'
import queryString from 'query-string'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
/** Materila-UI imports section */
import { withStyles, Typography } from '@material-ui/core'
/** Component imports section */
import DataTable from '../../../common/DataTable'
import LoadingPage from '../../../common/LoadingPage'

const PackageTable = (props) => {
	const { isLoading, totalItems, pageNumber, rowsPerPage, data } = props
	let location = useLocation()
	let history = useHistory()

	const onRowClick = (rowInfo, event) => {
		props.history.push(`/specifications/package/${rowInfo.token}`)
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}
	if (isLoading === true) {
		return <LoadingPage />
	}

	return (
		<DataTable
			data={data}
			configuration={TableConfiguration()}
			onRenderCellItem={onRenderCellItem}
			onChangePage={(newPage, newRowsPerPage) => {
				onChangePage(newPage, newRowsPerPage)
			}}
			onRowClick={onRowClick}
			isIndex
			totalRows={totalItems}
			page={pageNumber}
			rowsPerPage={rowsPerPage}
		/>
	)
}

const onRenderCellItem = (dataSource, item) => {
	if (dataSource === 'creation_date') {
		return (
			<Typography
				style={{
					maxWidth: '200px',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
				}}
			>
				{item && item.creation_date
					? moment(item.creation_date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')
					: ''}
			</Typography>
		)
	}
	if (dataSource === 'release_date') {
		return (
			<Typography
				style={{
					maxWidth: '200px',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
				}}
			>
				{item && item.release_date ? moment(item.release_date, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''}
			</Typography>
		)
	}
}

function TableConfiguration() {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='specificationsSection.guidelines.folio' />,
			dataSource: 'folio',
		},
		{
			header: <Translate id='specificationsSection.guidelines.statusTitle' />,
			dataSource: 'status_translate',
		},
		{
			header: <Translate id='specificationsSection.guidelines.creationDate' />,
			dataSource: 'creation_date',
		},
		{
			header: <Translate id='specificationsSection.guidelines.documentName' />,
			dataSource: 'name',
		},
		{
			header: <Translate id='specificationsSection.guidelines.createdBy' />,
			dataSource: 'created_by',
		},
		{
			header: <Translate id='specificationsSection.guidelines.releaseDate' />,
			dataSource: 'release_date',
		},
		{
			header: <Translate id='specificationsSection.guidelines.revision' />,
			dataSource: 'revision',
		},
	]

	return { columns }
}

PackageTable.propTypes = {
	isLoading: propTypes.bool,
	totalItems: propTypes.number,
	pageNumber: propTypes.number,
	rowsPerPage: propTypes.number,
	data: propTypes.array,
}

PackageTable.defaultProps = {
	isLoading: false,
	totalItems: 0,
	pageNumber: 0,
	rowsPerPage: 20,
	data: [],
}

const styles = (theme) => ({})

export default withRouter(withStyles(styles, { withTheme: true })(PackageTable))
