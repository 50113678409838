

/**
 * Convert a immutable list into an array oj objects
 *
 * @export
 * @param {*} list
 * @returns
 */
export function converListToArray(list) {
    let result = []
    if (list) {
        list.map((item) => {
            return result.push(item.toJS())
        })
    } 
    return result
}

/**
 * Returns a objet from a immutable map
 */
 export const toSafeObject = (mapObject) => {
    if (mapObject === null || mapObject === undefined)
        return null;
    return mapObject.toJS();
}
