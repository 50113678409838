/** Helpers import section */
import axios from 'axios';

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserParameters
} from '../helpers/SessionHelper';
export const IS_LOADING_NOTIFICATIONS = 'IS_LOADING_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const IS_RENOVE_NOTIFICATION = 'IS_RENOVE_NOTIFICATION';
export const IS_LOADING_USER_NOTIFICATIONS = 'IS_LOADING_USER_NOTIFICATIONS';
export const SET_NOTIFICATIONS_USER = 'SET_NOTIFICATIONS_USER';
export const IS_LOADING_NOTIFICATION_REQUEST = 'IS_LOADING_NOTIFICATION_REQUEST';
export const SET_NOTIFICATIONS_REQUEST = 'SET_NOTIFICATIONS_REQUEST';
export const ADD_NOTIFICATION_REQUEST = 'ADD_NOTIFICATION_REQUEST';
export const REMOVE_NOTIFICATION_REQUEST = 'REMOVE_NOTIFICATION_REQUEST';
export const IS_LOADING_NOTIFICATIONS_OUTSORCING = 'IS_LOADING_NOTIFICATIONS_OUTSORCING';
export const SET_NOTIFICATIONS_OUTSORCING = 'SET_NOTIFICATIONS_OUTSORCING';


function removeNotificationRequest(requestId, createdBy) {
	return {
		type: REMOVE_NOTIFICATION_REQUEST,
		requestId,
		createdBy
	};
}

function addNotification(notification) {
	return {
		type: ADD_NOTIFICATION_REQUEST,
		notification: notification
	};
}

/**
 * Action to notify about loading the user notifications
 *
 * @returns
 */
function isLoadingUserNotifications(isLoading) {
	return {
		type: IS_LOADING_NOTIFICATIONS,
		isLoadingNotifications: isLoading
	};
}

/**
 * Action to set logged user notifications
 * @param {*} roles
 */
function setNotifications(notifications) {
	return {
		type: SET_NOTIFICATIONS,
		notifications: notifications
	};
}

/**
 * Action to remove notification from user
 * @param {*} folio
 */
export function removeNotificationFromUser(workflowToken) {
	return {
		type: REMOVE_NOTIFICATION,
		workflowToken
	};
}

function isRemovingNotificaion(isRemoving) {
	return {
		type: IS_RENOVE_NOTIFICATION,
		isRemoving
	};
}

function isLoadingUserSelectedNotifications(isLoading) {
	return {
		type: IS_LOADING_USER_NOTIFICATIONS,
		isLoadingNotifications: isLoading
	};
}

function setNotificationsUser(notifications) {
	return {
		type: SET_NOTIFICATIONS_USER,
		notifications: notifications
	};
}

/**
 * Action to notify about loading the notifications by request
 *
 * @returns
 */
function isLoadingNotificationsRequest(isLoading) {
	return {
		type: IS_LOADING_NOTIFICATION_REQUEST,
		isLoadingNotifications: isLoading
	};
}

/**
 * Action to set notifications by request
 * @param {*} roles
 */
function setNotificationsByRequest(notificationsRequest) {
	return {
		type: SET_NOTIFICATIONS_REQUEST,
		notificationsRequest: notificationsRequest
	};
}

/**
 * Load user's role
 *
 * @export
 * @param {*} userId
 * @returns
 */
export function loadNotificationsByUser() {
	return function (dispatch, getState) {
		/// TODO: se debe de actualizar las notificaciones cuando esten en la vista de inicio
		dispatch(isLoadingUserNotifications(true));
		let loggedUser = getState().oidc.user;
		let selectedCompany = getState().profile.get('selectedCompany');
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		if (loggedUser && selectedCompany) {
			let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/notifications/user`;
			let data = {
				CompanyToken: selectedCompany.get("token"),
				UserName: loggedUser.profile.preferred_username,
			}
			return axios.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setNotifications(serverResponse.data));
						return Promise.resolve();
					}
				})
				.catch((error) => {
					dispatch(isLoadingUserNotifications(false));
					console.error("Error loading user's notifications", error.response);
					return Promise.reject();
				});
		}
	};
}

/**
 * Load user's role
 *
 * @export
 * @param {*} userId
 * @returns
 */
export function loadNotificationsByUserSelected(userName) {
	return function (dispatch, getState) {
		dispatch(isLoadingUserSelectedNotifications(true));
		let selectedCompany = getState().profile.get('selectedCompany');
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		if (userName && selectedCompany) {
			let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/notifications/user`;
			let data = {
				CompanyToken: selectedCompany.get("token"),
				UserName: userName,
			}
			return axios.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setNotificationsUser(serverResponse.data));
						return Promise.resolve();
					}
				})
				.catch((error) => {
					dispatch(isLoadingUserSelectedNotifications(false));
					console.error("Error loading user's notifications", error.response);
					return Promise.reject();
				});
		}
	};
}

/**
 * Function to remove notification in planning with workflow
 */
export function removeNotificationsPlanning(token, workflowtype) {
	return (dispatch, getState) => {
		dispatch(isRemovingNotificaion(true));
		let user = getState().oidc.user;
		let data = {
			token: token
		};
		let configuration = getAuthorizedRequestConfigurationUserParameters(user, data);

		var getworkflowtype = getDocumentType(workflowtype);
		if (getworkflowtype === "planning_production") {
			let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/notifications/workflow/${token}`;
			return axios
				.delete(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(removeNotificationFromUser(token));
						dispatch(isRemovingNotificaion(false));
						return Promise.resolve();
					}
				})
				.catch((error) => {
					dispatch(isRemovingNotificaion(false));
					console.error('Error removing a notification in planning', error.response);
					return Promise.reject();
				});
		}
		else {
			let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/notifications/workflow/${token}`;
			return axios
				.delete(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(removeNotificationFromUser(token));
						dispatch(isRemovingNotificaion(false));
						return Promise.resolve();
					}
				})
				.catch((error) => {
					dispatch(isRemovingNotificaion(false));
					console.error('Error removing a notification in planning', error.response);
					return Promise.reject();
				});
		}
	};
}

export function getDocumentType(workflowType) {
	switch (workflowType) {
		case "QuoteSamples":
		case "SampleRequest":
		case "NoticeOfChange":
		case "CorrectiveActionDevelopment":
			return "development";
		case "RawMaterialNonConformity":
		case "FailureReport":
		case "Rework":
		case "CorrectiveAction":
		case "Devolution":
		case "WarrantyReportsPtm":
		case "Sustainability":
		case "Guarantee":
		case "QuestionnairePTM":
		case "AuditQuality":
			return "quality";
		case "DeliverOrder":
		case "ShippingOrder":
		case "Freight":
		case "MaquilaOrder":
			return "planning_production"
		default:
			return "no module";
	}
}

/**
 * Load notifications by request
 *
 * @export
 * @param {*} userId
 * @returns
 */
export const loadNotificationsByRequest = (requestToken) => {
	return async (dispatch, getState) => {
		try {
			const loadNotificationsFromBackend = async (requestToken) => {
				dispatch(isLoadingNotificationsRequest(true));
				// Get the authorized configuration
				let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
				// Get the notifications
				let endPoint = `${process.env.REACT_APP_SIP_NOTIFICATIONS}/notifications/${requestToken}/request`;
				let serverResponse = await axios.get(endPoint, configuration);
				await getFullUsersInformation(serverResponse.data, null);
				dispatch(setNotificationsByRequest(serverResponse.data));
			};
			await loadNotificationsFromBackend(requestToken);
		} catch (ex) {
			console.error('Errog getting the notifications list', ex);
		}
	};
};

/**
 * Get the full user information for a list of users
 */
export const getFullUsersInformation = async (usersList, configuration) => {
	try {
		let url = `${process.env.REACT_APP_IDENTITY_SERVER}/api/users`;
		const content = usersList.map((user) => user.CreatedBy);
		let useIdentityData = await axios.post(url, content, configuration);
		return usersList.map((user) => {
			let usesData = useIdentityData.data.find((x) => x.user_name === user.CreatedBy);
			user.Name = usesData ? usesData.full_name : '';
			user.Email = usesData ? usesData.email : '';
			return user;
		});
	} catch (ex) {
		console.error('Errog getting the users list', ex);
	}
};

/**
 * Function to remove notification in notification proyect
 */
export function removeNotificationsUserProvider(token, userName) {
	return (dispatch, getState) => {
		dispatch(isRemovingNotificaion(true));
		let user = getState().oidc.user;
		let data = {
			token: token
		};
		let configuration = getAuthorizedRequestConfigurationUserParameters(user, data);

		let endPoint = `${process.env.REACT_APP_SIP_NOTIFICATIONS}/notifications/${token}/${userName}`;
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeNotificationRequest(token, userName))
					dispatch(isRemovingNotificaion(false));
					return Promise.resolve();
				}
			})
			.catch((error) => {
				dispatch(isRemovingNotificaion(false));
				console.error('Error removing a notification in planning', error.response);
				return Promise.reject();
			});
	};
}

/**
 * Function to add notification
 */
export function addNotificationInUserSpplier(token, folio, supplier, modulePortal, urlBase, requestType) {
	return (dispatch, getState) => {
		dispatch(isRemovingNotificaion(true));
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		let selectedCompany = getState().profile.get('selectedCompany');
		let command = {
			CompanyCode: selectedCompany.get('company_code'),
			RequestId: token,
			RequestFolio: folio,
			ModulePortal: modulePortal,
			UrlBase: urlBase,
			RequestType: requestType,
			UserName: supplier.value
		};
		let endPoint = `${process.env.REACT_APP_SIP_NOTIFICATIONS}/notification`;
		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isRemovingNotificaion(false));
					serverResponse.data.Name = supplier.name
					serverResponse.data.Email = supplier.email
					dispatch(addNotification(serverResponse.data))
					return Promise.resolve();
				}
				return Promise.resolve();
			})
			.catch((error) => {
				dispatch(isRemovingNotificaion(false));
				console.error('Error in add notification in the supplier', error.response);
				return Promise.reject(error.response.data.detail);
			});
	};
}

function isLoadingUserNotificationsOutSoricng(isLoading) {
	return {
		type: IS_LOADING_NOTIFICATIONS_OUTSORCING,
		isLoadingSourcing: isLoading
	};
}

function setNotificationsSorcing(notifications) {
	return {
		type: SET_NOTIFICATIONS_OUTSORCING,
		notificationsSourcing: notifications
	};
}

export function loadNotificationsOutsorcing(supplierNumber) {
	return function (dispatch, getState) {

		let actualNotification = getState().notifications.get('notificationsSourcing');
		if (actualNotification) {
			return;
		}

		dispatch(isLoadingUserNotificationsOutSoricng(true));
		let selectedCompany = getState().profile.get('selectedCompany');
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
		if (selectedCompany) {
			let endPoint = `${process.env.REACT_APP_SIP_OUTSORCING}/suppliers/${supplierNumber}/status`;
			return axios.get(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setNotificationsSorcing(serverResponse.data));
						return Promise.resolve();
					}
				})
				.catch((error) => {
					dispatch(isLoadingUserNotificationsOutSoricng(false));
					console.error("Error loading user's notifications soorcing", error.response);
					return Promise.reject();
				});
		}
	};
}