/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'
import { supplierData, internalCompanyData } from '../helpers/BasePdfData'
import { underScoreToTitleCase } from '../helpers/StringHelper'

export const START_LOADING_QUOTES = 'START_LOADING_QUOTES'
export const SET_QUOTES_LIST = 'SET_QUOTES_LIST'
export const CHANGE_QUOTES_SORT_CRITERIA = 'CHANGE_QUOTES_SORT_CRITERIA'
export const CHANGE_QUOTES_QUERY = 'CHANGE_QUOTES_QUERY'
export const CHANGE_QUOTES_PAGINATION = 'CHANGE_QUOTES_PAGINATION'
export const SET_QUOTES_COUNT = 'SET_QUOTES_COUNT'
export const IS_LOADING_QUOTE = 'IS_LOADING_QUOTE'
export const SET_QUOTE = 'SET_QUOTE'
export const IS_SAVING_QUOTE = 'IS_SAVING_QUOTE'
export const UPDATE_QUOTE_DETAIL_ITEM = 'UPDATE_QUOTE_DETAIL_ITEM'
export const ADD_FILE_QUOTES = 'ADD_FILE_QUOTES'
export const REMOVE_FILE_QUOTES = 'REMOVE_FILE_QUOTES'

/**
 * Action start loading quotes
 * @param {*} isLoading
 */
function isLoadingQuotes(isLoading) {
	return {
		type: START_LOADING_QUOTES,
		isLoading: isLoading,
	}
}

/**
 * Action to set quotes list
 * @param {*} quotes
 */
function setQuotesList(quotes) {
	return {
		type: SET_QUOTES_LIST,
		quotes: quotes,
	}
}

/**
 * Action to start load quote
 */
function startLoadQuote(isLoading) {
	return {
		type: IS_LOADING_QUOTE,
		isLoadingQuote: isLoading,
	}
}

/**
 * Action to set quote
 * @param {*} quote
 */
function setQuote(quote) {
	return {
		type: SET_QUOTE,
		quote: quote,
	}
}

/**
 * Action reducer to set the quotes's counter
 *
 * @param {*} count
 * @returns
 */
function setQuotesCount(count) {
	return {
		type: SET_QUOTES_COUNT,
		count,
	}
}

/**
 * Action start loading quotes
 * @param {*} isLoading
 */
function isSaving(isSaving) {
	return {
		type: IS_SAVING_QUOTE,
		isSaving: isSaving,
	}
}

/**
 * Update quote detail item
 *
 * @param {*} detail
 * @returns
 */
function updateQuoteDetail(detail) {
	return {
		type: UPDATE_QUOTE_DETAIL_ITEM,
		detail,
	}
}

/**
 * Add file to state
 *
 * @param {*} files
 * @returns
 */
function addFile(files) {
	return {
		type: ADD_FILE_QUOTES,
		files,
	}
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName) {
	return {
		type: REMOVE_FILE_QUOTES,
		fileName,
	}
}

/**
 * Get all quotes by company from backend
 */
export function loadQuotesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingQuotes(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/all`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuotesList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingQuotes(false))
				console.error('Error getting the quotes by company list', error.response)
			})
	}
}

/**
 * Get quote's count
 *
 * @export
 * @returns
 */
export function getQuotesCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().quotes.get('quotesCount')
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierToken: supplierToken,
		}
		return axios
			.post(endPoint, data, configuration)

			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuotesCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the quote's count", error.response)
			})
	}
}

/**
 * Get and set the quote in the estate from read from backend
 * @param {*} token
 */
export function loadQuoteFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(startLoadQuote(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/quote/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuote(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load quote', error.response)
				dispatch(startLoadQuote(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to updateQuote properties
 *
 * @export
 * @param {*} quote
 * @returns
 */
export function updateQuoteProperty(quoteToken, property, propertyData) {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/save`
		let data = {
			[underScoreToTitleCase(property)]: propertyData,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuote(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to load quote', error.response)
				return Promise.reject()
			})
	}
}

/***
 * Action to update quote detail item
 */
export function updateQuoteDetailItem(quoteToken, detail) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/detail/${detail.token}`
		let data = {
			SupplierCode: detail.supplier_code,
			CostType: detail.cost_type,
			UnitaryCost: detail.unitary_cost,
			MeasurementUnit: detail.measurement_unit,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateQuoteDetail(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load quote', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Decline quite by supplier
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function declineQuoteBySupplier(quoteToken, commnents) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let supplierInformation = getState().suppliers.get('supplier').toJS()
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let data = {
			Supplier: supplierData(supplierInformation),
			Company: internalCompanyData(companyInformation),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Commnents: commnents,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/decline`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuote(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in decline quote ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to decline a quote item detail
 *
 * @export
 * @param {*} quoteItemToken
 * @param {*} comments
 * @returns
 */
export function declineQuoteItem(quoteItemToken, comments) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Commnents: comments,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/declined/detail/${quoteItemToken}`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateQuoteDetail(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in decline item quote ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * quoteToken
 * @param {*} quoteToken
 */
export function updateIncotermInQuote(quoteToken) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let partnership = getState().suppliers.get('partnership')
			? getState().suppliers.get('partnership').toJS()
			: null
		let data = {
			Incoterm: partnership ? partnership.incoterm : '',
			Currency: partnership ? partnership.order_currency : '',
			ConditionCredit: partnership ? partnership.condition_credit_full : '',
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/updateIncoterm`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuote(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in send quote by supplier quote ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Send quote to development engineer
 *
 * @export
 * @param {*} quoteToken
 * @param {*} supplier
 * @returns
 */
export function sendQuoteToDevelopmentEngineer(quoteToken, supplier, incoterm, currency_type) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let supplierInformation = getState().suppliers.get('supplier').toJS()
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let data = {
			Supplier: supplierData(supplierInformation),
			Company: internalCompanyData(companyInformation),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Incoterm: incoterm ? incoterm : supplier ? supplier.incoterm : '',
			Currency: currency_type ? currency_type : supplier ? supplier.order_currency : '',
			ConditionCredit: supplier ? supplier.condition_credit_full : '',
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/sendengineer`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuote(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in send quote by supplier quote ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Decline quite by supplier
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function acceptedOrRejectedDetailItemEnginner(itemToken, comment, status) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Status: status,
			Commnents: comment,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/detail/${itemToken}/updatestatus`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateQuoteDetail(serverResponse.data))
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in accepted, rejected or requote item quote ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Decline quite by supplier
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function acceptRejectRequoteDetailItemsEnginner(itemTokens, comment, status) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Status: status,
			Commnents: comment,
			SelectedTokens: itemTokens,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/detail/selectedTokens/updatestatus`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.forEach((item) => {
						dispatch(updateQuoteDetail(item))
					})
					dispatch(isSaving(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in accepted, rejected or requote items in quote ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to accepted quote by development engineer
 *
 * @export
 * @param {*} quoteToken
 * @returns
 */
export function quoteAcceptedByEngineer(quoteToken) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let supplierInformation = getState().suppliers.get('supplier').toJS()
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let data = {
			Supplier: supplierData(supplierInformation),
			Company: internalCompanyData(companyInformation),
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/accepted`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuote(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in accepted by enginner quote ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action rejected quote to development engineer
 *
 * @export
 * @param {*} quoteToken
 * @returns
 */
export function quoteRejectedByEngineer(quoteToken) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)

		let supplierInformation = getState().suppliers.get('supplier').toJS()
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let data = {
			Supplier: supplierData(supplierInformation),
			Company: internalCompanyData(companyInformation),
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/rejected`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuote(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in send quote by supplier quote ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Decline quite by supplier
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function sendRequoteToSupplier(token) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${token}/sendrequote`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuote(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in re-quote on quote ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to upload files to quote
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadQuoteFiles(quoteToken, files) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/upload/${quoteToken}`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addFile(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload quote file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files to quote
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeQuoteFiles(quoteToken, fileName) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/remove/${quoteToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeFile(fileName))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to upload quote file(s)', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update tooling data of an quote item
 *
 * @export
 * @param {*} detail
 * @returns
 */
export function updateQuoteToolingDetailItem(detail) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/tooling/${detail.token}`
		let data = {
			Description: detail.tooling.description,
			InversionAmortizationType: detail.tooling.inversion_amortization_type,
			Others: detail.tooling.others,
			ManufacturingTime: detail.tooling.manufacturing_time,
			ManufacturingTimeMeasurementUnit: detail.tooling.manufacturing_time_measurement_unit,
			PieceCost: detail.tooling.piece_cost,
			TotalCost: detail.tooling.total_cost,
			Volume: detail.tooling.volume,
			VolumeMeasurementUnit: detail.tooling.volume_measurement_unit,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateQuoteDetail(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load quote', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update cubicaje data of an quote item
 *
 * @export
 * @param {*} detail
 * @returns
 */
export function updateQuoteCubicajeDetailItem(detail) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/detailitems/cubicaje/${detail.token}`
		let data = {
			ContainerType: detail.cubicaje.container_type,
			QuantityPerContainer: detail.cubicaje.quantity_per_container,
			NumberPieces: detail.cubicaje.number_pieces,
			Width: detail.cubicaje.width,
			Length: detail.cubicaje.length,
			Heigth: detail.cubicaje.heigth,
			Weight: detail.cubicaje.weight,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateQuoteDetail(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load quote', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add scales data of an quote item
 *
 * @export
 * @param {*} detail
 * @returns
 */
export function addQuoteScalesDetailItem(detail) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/scale/${detail.token}`
		let scale = detail.scales[detail.scales.length - 1]

		let data = {
			UpperLimit: scale.upper_limit,
			LowerLimit: scale.lower_limit,
			Cost: scale.cost,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateQuoteDetail(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load quote', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to delete scales data of an quote item
 *
 * @export
 * @param {*} scaleToken
 * @returns
 */
export function deleteQuoteScalesDetailItem(scaleToken) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/scale/${scaleToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateQuoteDetail(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load quote', error.response)
				return Promise.reject()
			})
	}
}

/**
 *Download report of quotes in process by supplier
 *
 * @export
 * @returns
 */
export function downloadQuotesInProcessSupplierReport() {
	return (dispatch, getState) => {
		dispatch(startLoadQuote(true))

		let companyToken = getState().profile.get('selectedCompany').get('token')
		let supplierToken = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/downloadreport/${companyToken}/inprocesssupplier/${supplierToken}`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(startLoadQuote(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_cotizaciones_proveedor.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(startLoadQuote(false))
				console.error('Error downloading report of quotes in process by supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to Download quotes by month report
 *
 * @export
 * @param {*} month
 * @returns
 */
export function downloadReportByDateRangeReport(startDate, endDate, supplier) {
	return (dispatch, getState) => {
		dispatch(startLoadQuote(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let data = {
			CompanyToken: companyToken,
			StartDate: startDate,
			EndDate: endDate,
			SupplierCode: supplier ? supplier : '',
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/downloadReportByRangeDate`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(startLoadQuote(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_tiempos_de_respuesta_QT.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(startLoadQuote(false))
				console.error('Error in download quotes report by month', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to active quote by adminsitrator from company
 *
 * @export
 * @param {*} token
 * @returns
 */
export function activateQuoteToDevelopmentEngineer(token) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${token}/activequote`
		return axios
			.put(endPoint, null, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setQuote(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in activate on quote ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Send quote to development engineer
 *
 * @export
 * @param {*} quoteToken
 * @param {*} supplier
 * @returns
 */
export function generatedNewPDFQuote(quoteToken, supplier, incoterm, currency_type) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let supplierInformation = getState().suppliers.get('supplier').toJS()
		let companyInformation = getState().profile.get('selectedCompany').toJS()
		let data = {
			Supplier: supplierData(supplierInformation),
			Company: internalCompanyData(companyInformation),
			CreatorUserName: user.profile.preferred_username,
			CreateBy: user.profile.name,
			Incoterm: incoterm ? incoterm : supplier ? supplier.incoterm : '',
			Currency: currency_type ? currency_type : supplier ? supplier.order_currency : '',
			ConditionCredit: supplier ? supplier.condition_credit_full : '',
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/pdf`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				dispatch(isSaving(false))
				if (serverResponse.status === 200) {
					var file = new Blob([serverResponse.data], { type: 'application/pdf' })
					var fileURL = URL.createObjectURL(file)
					window.open(fileURL)
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error in send quote by supplier quote ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to donwload templated from quote to response supplier in excel
 * @param {*} quoteToken
 */
export function downloadDetailsQuoteFromSupplierToResponse(quoteToken) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/downloadByResponseSupplier`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSaving(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Detalle_de_la_cotizacion.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error downloading report of quotes in process by supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to import tempplate from excel by supplier
 * @param {*} provedure
 * @param {*} attachments
 */
export function responseQuoteMaterialListBySupplier(quoteToken, attachments) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)

		let formData = new FormData()
		formData.set('Token', quoteToken)
		for (var index = 0; index < attachments.length; index++) {
			formData.append('file', attachments[index])
		}

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/detailsReponseSupplier`
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSaving(false))
					dispatch(setQuote(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error response quote to excel from supplier', error.response)
				dispatch(isSaving(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to donwload details quote in excel to development
 * @param {*} quoteToken
 */
export function downloadQuoteWithDetailsFromDevelopment(quoteToken) {
	return (dispatch, getState) => {
		dispatch(isSaving(true))

		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${quoteToken}/downloadexcelfromdevelopment`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSaving(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Detalle_de_la_cotizacion.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error downloading report of quotes from development', error.response)
				return Promise.reject()
			})
	}
}
