import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

/** Resources import section */
import { applyCurrencyFormat } from '../../../store/helpers/StringHelper'
import PaymentRequestStatus from '../paymentRequests/components/PaymentRequestStatus'

/**
 * Styles to apply on component
 */
const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	label: {
		padding: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	canceledContent: {
		display: 'flex',
		justifyContent: 'space-around',
	},
}))

/**
 * Presentional component to show the payment request general information.
 *
 *  * @param {object} props
 */
const PaymentRequestDetail = (props) => {
	const {
		folio,
		status,
		creationDate,
		serviceOrdersNumber,
		totalAmount,
		documentNumber,
		invoiceFolio,
		creditNoteFolio,
		paymentDate,
		canceledAt,
		canceledBy,
	} = props
	const classes = useStyles()

	return (
		<>
			<div className={classes.root}>
				<div className={classes.label}>
					<Typography variant='overline'>
						<Translate id='accreditedWorkshops.paymentRequestDetail.folio' />
					</Typography>
					<Typography>{folio}</Typography>
				</div>
				<div className={classes.label}>
					<Typography variant='overline'>
						<Translate id='accreditedWorkshops.invoiceFolio' />
					</Typography>
					<Typography>{invoiceFolio}</Typography>
				</div>
				<div className={classes.label}>
					<Typography variant='overline'>
						<Translate id='accreditedWorkshops.creditNoteFolio' />
					</Typography>
					<Typography>{creditNoteFolio}</Typography>
				</div>
				<div className={classes.label}>
					<Typography variant='overline'>
						<Translate id='accreditedWorkshops.paymentRequestDetail.document' />
					</Typography>
					<Typography>{documentNumber}</Typography>
				</div>
				<div className={classes.label}>
					<Typography variant='overline'>
						<Translate id='accreditedWorkshops.paymentRequestDetail.status' />
					</Typography>
					<div>
						<PaymentRequestStatus status={status} />
					</div>
				</div>

				<div className={classes.label}>
					<Typography variant='overline'>
						<Translate id='accreditedWorkshops.paymentRequestDetail.creationDate' />
					</Typography>
					<Typography>{creationDate && creationDate.format('DD/MM/YYYY')}</Typography>
				</div>
				<div className={classes.label}>
					<Typography variant='overline'>
						<Translate id='accreditedWorkshops.paymentRequestDetail.statusDate' />
					</Typography>
					<Typography>{paymentDate && paymentDate.format('DD/MM/YYYY')}</Typography>
				</div>
				<div className={classes.label}>
					<Typography variant='overline'>
						<Translate id='accreditedWorkshops.paymentRequestDetail.serviceOrdersNumber' />
					</Typography>
					<Typography>{serviceOrdersNumber}</Typography>
				</div>
				<div className={classes.label}>
					<Typography variant='overline'>
						<Translate id='accreditedWorkshops.paymentRequestDetail.totalAmount' />
					</Typography>
					<Typography>{applyCurrencyFormat(totalAmount)}</Typography>
				</div>
			</div>
			{status === '51' && (
				<div className={classes.canceledContent}>
					<div className={classes.label}>
						<Typography variant='overline'>
							<Translate id='accreditedWorkshops.paymentRequestDetail.cancelatedBy' />
						</Typography>
						<Typography>{canceledBy}</Typography>
					</div>
					<div className={classes.label}>
						<Typography variant='overline'>
							<Translate id='accreditedWorkshops.paymentRequestDetail.cancelatedAt' />
						</Typography>
						<Typography>{canceledAt && canceledAt.format('DD/MM/YYYY HH:mm:ss')}</Typography>
					</div>
				</div>
			)}
		</>
	)
}

PaymentRequestDetail.propTypes = {
	folio: PropTypes.string.isRequired,
	status: PropTypes.string.isRequired,
	creationDate: PropTypes.instanceOf(moment).isRequired,
	paymentDate: PropTypes.instanceOf(moment).isRequired,
	serviceOrdersNumber: PropTypes.number.isRequired,
	totalAmount: PropTypes.number.isRequired,
}

PaymentRequestDetail.defaultProps = {
	folio: '',
	status: '',
	creationDate: moment(),
	paymentDate: moment(),
	serviceOrdersNumber: 0,
	totalAmount: 0,
}

export default PaymentRequestDetail
