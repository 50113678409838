/** React imports section */
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-localize-redux'

/** Components imports section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import { addCarrier, addTypeShipment } from '../../../store/transportTender/transportTenderAction'

/** Store imports section */
import { getTransportTenderfilterSelector } from '../../../store/transportTender/transportTenderSelector'
import { getSuppliersAreCarriers } from '../../../store/suppliers/SupplierActions'
import * as action from '../../../store/transportTender/transportTenderAction'
import { getAllSupplierSelector } from '../../../store/suppliers/SupplierSelectors'
import { getSupplierOptions } from '../../../store/helpers/TransportTenderHelper'
import { getLoggedUserSelector } from '../../../store/profile/ProfileSelectors'
import { isTransportTenderAdministrator } from '../../../store/helpers/TransportTenderHelper'

/** Common imports section */
import Toaster from '../../common/Toaster'
import TypeShipmentContent from './components/TypeShipmentContent'

const TypeShipment = (props) => {
	const dispatch = useDispatch()

	const transportTenderinfo = useSelector((state) => getTransportTenderfilterSelector(state))
	const supplierContract = useSelector((state) => getAllSupplierSelector(state))
	const optionsSupplie = getSupplierOptions(
		supplierContract.supplierCarriers ? supplierContract.supplierCarriers : []
	)
	const loggetUser = useSelector((state) => getLoggedUserSelector(state))
	const userRoles = loggetUser && loggetUser.grantedRoles ? loggetUser.grantedRoles : []
	const isTransportTenderAdminitsrator = isTransportTenderAdministrator(userRoles)

	const [openCollapese, setOpenCollapese] = useState({ open: false, Id: null })
	const [information, setInformation] = useState({ name: '' })
	const [openTypeShipmentPanel, setOpenTypeShipmentPanel] = useState(false)
	const [openAssignSupplier, setOpenOpenAssignSupplier] = useState({ open: false, Id: null })
	const [supplier, setSupplier] = useState(null)

	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: '',
		toasterVariant: '',
	})
	const onSave = (id) => {
		dispatch(addTypeShipment(information.name))
			.catch((err) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'Error al guardar un tipo de transporte',
					toasterVariant: 'error',
				})
			})
			.finally(() => {
				setOpenTypeShipmentPanel(false)
				setSupplier(null)
			})
	}
	const onAddCarrier = (id) => {
		dispatch(addCarrier(supplier, openAssignSupplier.Id))
			.then(() => {})
			.catch((err) => {
				if (err === 'alreadyRegisteredSupplierAnother')
					setToasterMessageConfig({
						showToaster: true,
						toasterMessage: <Translate id={'carrierTenders.error.alreadyRegisteredSupplierAnother'} />,
						toasterVariant: 'error',
					})
				else
					setToasterMessageConfig({
						showToaster: true,
						toasterMessage: 'Error al guardar un tipo de transporte',
						toasterVariant: 'error',
					})
			})
			.finally(() => {
				setOpenOpenAssignSupplier({ open: false, Id: null })
				setSupplier(null)
			})
	}
	const onDeleteGroupCarrier = (carrierGroup) => {
		const typeShipmentId = carrierGroup && carrierGroup.Id ? carrierGroup.Id : ''
		dispatch(action.deleteTypeShipment(typeShipmentId))
	}
	const onDeleteCarrier = (id) =>
		dispatch(action.deleteCarrier(id)).then(() => dispatch(action.loadTypeShipmentCatalog()))
	useEffect(() => {
		dispatch(getSuppliersAreCarriers())
		dispatch(action.loadTypeShipmentCatalog())
	}, [dispatch])
	return (
		<div>
			<TypeShipmentContent
				typeShipment={transportTenderinfo.typeShipment}
				openCollapese={openCollapese}
				setOpenCollapese={setOpenCollapese}
				openAssignSupplier={openAssignSupplier}
				setOpenOpenAssignSupplier={setOpenOpenAssignSupplier}
				information={information}
				setInformation={setInformation}
				onAddCarrier={onAddCarrier}
				optionsSupplie={optionsSupplie}
				supplier={supplier}
				setSupplier={setSupplier}
				onSave={onSave}
				openTypeShipmentPanel={openTypeShipmentPanel}
				setOpenTypeShipmentPanel={setOpenTypeShipmentPanel}
				isLoading={transportTenderinfo.isLoadingCatalog}
				isTransportTenderAdministrator={isTransportTenderAdminitsrator}
				onDelete={onDeleteGroupCarrier}
				onDeleteCarrier={onDeleteCarrier}
			/>
			<Toaster
				message={toasterMessageConfig.toasterMessage}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</div>
	)
}

export default withauthenticatedLayout(withRouter(TypeShipment))
