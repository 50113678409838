import React from 'react';
import uuidv4 from 'uuid/v4';
import { withStyles } from '@material-ui/core/styles';
/** Material UI import section */
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    Typography,
    ListItemAvatar,
    Avatar
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done'

/** Resources import section */
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
import { COLOR_ALTERNATE } from '../../../store/helpers/StatusColorConstants';

const ListResponsible = props => {
    const {classes, userProfile} = props

    const onAddResponsible = (user) => {
        if (props.onAddResponsible) {
            props.onAddResponsible(user)
        }
    }

    const onRemoveResponsible = (user) => {
        if(props.onRemoveResponsible){
            props.onRemoveResponsible(user)
        }
    }

    const onExistResponsible = (user) => {
        if (props.responsiblesAdded.find(u=>u.user_name ===user.user_name)) {
            return true;
        }
        return false;
    }

    const getSimpleList = () => {
        return (
            <List dense>
                {
                    props.users && props.users.map(user => {
                        let existUserAdded = onExistResponsible(user);
                        let isDisabled = (userProfile && (userProfile.username ===user.user_name))
                        return (
                            <ListItem
                                button
                                key={user.id}
                                className={classes.disabledItem}
                                disabled={isDisabled}
                                onClick={() => { !existUserAdded ? onAddResponsible(user) : onRemoveResponsible(user) }}
                            >
                                <ListItemAvatar>
                                    <UserAvatar
                                        name={user.name}
                                        avatarUrl={user.avatar_url}
                                        classes={classes}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2" noWrap style={{maxWidth:"155px"}}>
                                            {user.name}
                                        </Typography>
                                    }
                                    />
                                {
                                    existUserAdded &&
                                    <ListItemSecondaryAction className={classes.itemListIcon}>
                                        <ListItemIcon
                                            id={user.user_name}
                                        >
                                            <DoneIcon />
                                        </ListItemIcon>
                                    </ListItemSecondaryAction>
                                }
                            </ListItem>
                        )
                    })
                }
            </List>
        );
    }
    return (
        <div style={{
            maxHeight: `calc(100vh - ${400}px)`, overflowY: 'auto', overflowX:'hidden'
        }}>
            {getSimpleList()}

        </div>
    );
}

const UserAvatar = props => {
    const { name, avatarUrl, classes } = props;
    const firstLetterName = name.charAt(0).toUpperCase();

    const getAvatarUrl = () => {
        if (!IsNullOrEmpty(avatarUrl)) {
            let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${avatarUrl}`;
            let logoUrl = urlImage + "?token=" + uuidv4();
            return logoUrl;
        }
        else
            return null
    }

    return (
        <Avatar
            alt="Avatar"
            src={getAvatarUrl()}
            className={classes.avatar}
        >
            <span styles={{ fontSize: 40 }}>{IsNullOrEmpty(avatarUrl) ? firstLetterName.toUpperCase() : null}</span>
        </Avatar>
    );
}

const useCardStyle = ((theme) => ({
    root: {
        width: '250px',
        marginLeft: '10px'
    },
    searchBoxContainer: {
        width: "100%"
    },
    listItemText: {
        color: COLOR_ALTERNATE
    },
    disabledItem: {
        marginTop: 1,
        padding: '1px 0px 1px 5px',
        borderRadius: "4px",
        cursor: 'pointer'
    },
    itemListIcon: {
        // right: "-30px",
        cursor: 'pointer'
    },
    avatar: {
        width: "25px",
        height: "25px",
    },
    rootAvatar: {
        marginTop: "10px"
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    subtitle: {
        backgroundColor: '#D0DEED'
    },
    logoFileInput: {
        display: "none",
    },
}))


export default (withStyles(useCardStyle, { withTheme: true }) (ListResponsible))