import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

/** Import component section */
import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper';

/**
 * Class component to edit quantity of a material item in delivery order
 *
 * @class EditMaterialQuantityModal
 * @extends {Component}
 */
class EditMaterialQuantityModal extends Component {

    /**
     *Creates an instance of EditMaterialQuantityModal.
     * @param {*} props
     * @memberof EditMaterialQuantityModal
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = this.initialState();
    }

    /**
     * Return initial state
     *
     * @returns
     * @memberof EditMaterialQuantityModal
     */
    initialState(isClose = false) {
        return ({
            token: this.props.item ? (isClose ? null : this.props.item.token) : null,
            quantity_of_material: this.props.item ? (isClose ? null : this.props.item.quantity_of_material) : null,
            expected_quantity_today: this.props.item ? (isClose ? null : this.props.item.expected_quantity_today) : null,
        })
    }

    /**
     * React lyfe-cycle method
     * If item prop was updated, state data should be update 
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof EditMaterialQuantityModal
     */
    componentDidUpdate(prevProps, prevState) {
        if ((this.props.item !== prevProps.item)
            || (this.props.open !== prevProps.open && this.props.open === true)) {
            this.setState(this.initialState());
        }
    }

    /**
     * Handle update properties to set data in local state
     *
     * @param {*} property
     * @param {*} data
     * @memberof EditMaterialQuantityModal
     */
    updateProperty(property, data) {
        this.setState({
            [property]: data,
        });
    }

    /**
     * Function to trigger blur event
     *
     * @param {*} event
     * @memberof EditMaterialQuantityModal
     */
    onKeyDown(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    /**
     * Handle  Close or cancel event
     *
     * @memberof EditMaterialQuantityModal
     */
    handleCloseCancel() {
        this.setState(this.initialState(true),
            () => this.props.onClose());
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof EditMaterialQuantityModal
     */
    isValidToSave() {

        if (!IsNullOrEmpty(this.state.quantity_of_material)
            && Number(this.state.quantity_of_material) >= 0
            && Number(this.state.quantity_of_material) <= Number(this.props.item && this.props.item.quantity_of_material))
            return true;
        return false
    }

    /**
     * Handle event onSaveItem to call function in props
     *
     * @memberof EditMaterialQuantityModal
     */
    handleOnSaveItem() {
        this.props.onSaveItem(this.state, "quantity_of_material")
            .then((response) => {
                this.handleCloseCancel()
            })
    }

    /**
     * Method to render text field 
     *
     * @param {*} textFieldId
     * @param {*} textFieldTranslateId
     * @param {boolean} [isRequired=true]
     * @returns
     * @memberof EditMaterialQuantityModal
     */
    renderTextField(textFieldId, textFieldTranslateId) {
        let helperValue = this.props.item && this.props.item[textFieldId];
        let helperText = <Translate id={"deliveryOrderModule.editQuantityMaterialItemMessage"} />

        return (
            <Grid item xs={12}>
                <TextField
                    id={textFieldId}
                    label={<Translate id={textFieldTranslateId} />}
                    value={this.state[textFieldId] ? this.state[textFieldId] : ''}
                    className={this.props.classes.textField}
                    type={"number"}
                    fullWidth
                    required
                    error={Number(this.state[textFieldId]) < 0 || Number(this.state[textFieldId]) > Number(this.props.item && this.props.item[textFieldId])}
                    onBlur={(event) => this.updateProperty(textFieldId, event.target.value)}
                    onChange={(event) => this.updateProperty(textFieldId, event.target.value)}
                    onKeyDown={this.onKeyDown}
                    helperText={
                        this.props.item &&
                        <React.Fragment>
                            {helperText}{`: ${helperValue}`}
                        </React.Fragment>
                    }
                />
            </Grid>
        )
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id={this.props.title} />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <Grid container spacing={0}>
                        {!this.isValidToSave() &&
                            <Grid item xs={12} className={this.props.classes.checkBox}>
                                <Typography variant="caption" color='error'>
                                    {<Translate id="common.requiredFields" />}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <DialogActions
                                view={SHOW_EDIT_VIEW}
                                classes={classes}
                                onCancel={() => this.handleCloseCancel()}
                                onClose={() => this.handleCloseCancel()}
                                onSaveItem={this.handleOnSaveItem}
                                isSaving={this.props.isSaving}
                                canBeSaved={this.isValidToSave()}
                            />
                        </Grid>
                    </Grid>
                }>
                <Grid container className={classes.modal} justify='center'>
                    {this.renderTextField('quantity_of_material', 'deliveryOrderModule.materialsTable.quantity')}
                </Grid>
            </Dialog>
        )
    }
}

const styles = theme => ({
    modal: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxWidth: 400
    },
    textField: {
        minWidth: 400,
    },
    checkBox: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxHeight: '25px'
    }
});

export default withRouter(withStyles(styles, { withTheme: true })(EditMaterialQuantityModal));