import { fromJS } from 'immutable';
import * as internalActions from './InternalActions';
import { converListToArray } from '../helpers/ListHelper'

/**
 * Users initial state
 */
export const usersInitialState = fromJS({
    isLoadingUsers: true,
    internalUsers: null,
    isLoadingUser: false,
    internalUser: null,
    sortCriteria: {
        by: "user_name",
        ascending: false
    },
    searchQuery: "",
    internalUsersCount: null,
    internalUserRoles: null,
    usersByRole: [],
    isLoadingInternalUserRoles: false,
    users: null,
    isSaving: false,
})

export default function usersReducer(state = usersInitialState, action) {
    switch (action.type) {
        case internalActions.START_LOADING_USERS: {
            return state.merge({
                isLoadingUsers: action.isLoading
            })
        }
        case internalActions.SET_USERS_LIST: {
            let usersLists = fromJS(action.users);
            return state.merge({
                internalUsers: usersLists,
                isLoadingUsers: false
            })
        }
        case internalActions.IS_LOADING_USER: {
            return state.merge({
                isLoadingUser: action.isLoadingUser,
                internalUser: null,
            })
        }
        case internalActions.SET_USER: {
            return state.merge({
                isLoadingUser: false,
                isSaving: false,
                internalUser: fromJS(action.user)
            })
        }
        case internalActions.CHANGE_INTERAL_USER_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case internalActions.CHANGE_INTERAL_USER_QUERY: {
            return state.merge({
                searchQuery: action.query
            })
        }
        case internalActions.SET_INTERNAL_USERS_COUNT: {
            return state.merge({
                internalUsersCount: action.count
            })
        }
        case internalActions.SET_INTERNAL_USERS_ROLES: {
            return state.merge({
                internalUserRoles: action.roles
            })
        }
        case internalActions.IS_LOADING_INTERNAL_USERS_ROLES: {
            return state.merge({
                isLoadingInternalUserRoles: action.isLoading
            })
        }
        case internalActions.ADD_USER_TO_ROLE: {
            let actualRoles = state.get('internalUserRoles')
            if (actualRoles) {
                let updatedRoles = converListToArray(actualRoles);
                updatedRoles.push({ company_token: action.companyToken, id: action.roleId })
                return state.merge({
                    internalUserRoles: fromJS(updatedRoles)
                });
            } else {
                return state;
            }
        }
        case internalActions.REMOVE_USER_FROM_ROLE: {
            let actualRoles = state.get('internalUserRoles')
            if (actualRoles) {
                let updatedRoles = actualRoles.filter((roleAssigned) => {
                    return !(roleAssigned.get('company_token') === action.companyToken && roleAssigned.get('id') === action.roleId)
                })
                return state.merge({
                    internalUserRoles: updatedRoles
                });
            } else {
                return state;
            }
        }
        case internalActions.SET_INTERNAL_USERS_BY_QUERY: {
            let usersLists = fromJS(action.users);
            return state.merge({
                users: usersLists,
                isLoadingUsers: false
            })
        }
        case internalActions.SET_USERS_IN_ROLE: {
            //Set data in variables
            let usersLists = (action.users);
            let role = (action.role);
            // Create a object list with role like Key
            let usersByRoleToAdd = [{ [role]: usersLists }]
            // Get current list of roles lists
            let actualUsersByRole = state.get('usersByRole').toJS();
            //Validate if role is current added in list
            let hasRoleList = actualUsersByRole.find(roleInList => {
                return Object.keys(roleInList)[0] === role
            })
            //If role is not already in list then then It will be added  
            let newUsersByRole=[...actualUsersByRole];
            if(!hasRoleList)
                newUsersByRole = [...newUsersByRole, ...usersByRoleToAdd];

            return state.set('usersByRole', fromJS(newUsersByRole)).set('isLoadingUsers', false)
        }
        case internalActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case internalActions.SET_ENABLE_USER: {
            let internalUser = state.get('internalUser');
            internalUser = internalUser.set('enabled', action.enabled);
            return state.merge({
                isLoadingUser: false,
                isSaving: false,
                internalUser: internalUser
            })
        }
        default:
            return state;
    }
}