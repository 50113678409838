/**Import react section */
import React from "react";
import uuidv4 from "uuid/v4";
import { Translate } from 'react-localize-redux'
/**Import material UI Section*/
import {
  withStyles,
  Typography,
  IconButton,
  Tooltip,
  Fab,
  CircularProgress,
} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import CommentIcon from "@material-ui/icons/Comment";
/**Import actions section */
import {
  WINNER_RESULT_BUTTONS,
  RESULT_TOTAL,
  SUPPLIERS_FINAL_COMMENTS,
  getResultInform,
  canBeEditBiddingInStatusResolved,
  RESULT_TOTAL_WINNER_IN_ITEMS,
  IS_DECLINED_MATERIAL_BY_SUPPLIER,
} from "../../../../store/helpers/BiddingHelper";
import { ADD_WINNER_SUPPLIER } from "../../../../store/biddings/BiddingsActions";
/**import helper section */
import { percengeFormatResultTable } from "../../../../store/helpers/AmountHelper";
import IsWinnerIcon from "../../../../resources/images/winners/isWinner.png";
import NoWinnerIcon from "../../../../resources/images/winners/noWinner.png";
import {
  BACKGROUND_COLOR_GRAY_BIDDING,
  COLOR_STATUS_SUCCESS,
  COLOR_STATUS_BLUE,
  COLOR_STATUS_DANGER,
} from "../../../../store/helpers/StatusColorConstants";
import { IsNullOrEmpty } from "../../../../store/helpers/StringHelper";
import { ItemProposalStatus } from "../../../../store/helpers/AppConstants";
import { formatterDolarValue } from "../../../../store/helpers/AmountHelper";

const ItemProposalResponseByProvider = (props) => {
  const { classes, translate } = props;
  const { isSaving, isVisibleCellData, isListItems, isExternalUser } = props;
  const { row, userRolesByCompany } = props;
  const { bidding, headerStyleWinnerInItems, userProfile } = props;
  const {
    onConfirmPassword,
    onAddWinnerInItemFromBackend,
    onAddCommentsSupplierConfigPanel,
    setIdentifierItemIdIsSaving,
    identifierItemIdIsSaving,
    identifierIsSaving,
    setIdentifierIsSaving
  } = props;

  const onSetWinner = (event, supplier) => {
    event.stopPropagation();
    if (props.onConfirmPassword) {
      setIdentifierIsSaving(supplier.supplierCode);
      setIdentifierItemIdIsSaving(supplier.itemId);
      onConfirmPassword(supplier, ADD_WINNER_SUPPLIER);
    }
  };

  const onAddWinnerInItem = (event, supplier, isWinner) => {
    event.stopPropagation();
    if (props.onAddWinnerInItemFromBackend) {
      setIdentifierIsSaving(supplier.supplierCode);
      setIdentifierItemIdIsSaving(supplier.itemId);
      onAddWinnerInItemFromBackend(supplier, isWinner);
    }
  };
  let suppliersNotDeclined = !isExternalUser
    ? bidding.Suppliers.filter((d) => d.Status === ItemProposalStatus.COMPLETED)
    : bidding.Suppliers;
  return (
    <>
      {suppliersNotDeclined.map((supplierNotDeclined, index) => {
        let supplier = row.suppliers.find(
          (x) => x.supplierCode === supplierNotDeclined.Code
        );
        if (supplier.group === IS_DECLINED_MATERIAL_BY_SUPPLIER) {
          return (
            <>
              <TableCell
                key={uuidv4()}
                align="center"
                style={headerStyleWinnerInItems}
              >
                <Typography className={classes.declinedMaterial}>
                  <Translate id="biddingsModule.declinedMaterial" />
                </Typography>
              </TableCell>
              <TableCell
                key={uuidv4()}
                align="center"
                style={{ backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING }}
              >
                {percengeFormatResultTable(0)}
              </TableCell>
            </>
          );
        } else {
          return (
            <>
              <TableCell
                key={uuidv4()}
                align="center"
                style={headerStyleWinnerInItems}
              >
                {row.group === WINNER_RESULT_BUTTONS &&
                !supplier.isCatergoryMaterial &&
                canBeEditBiddingInStatusResolved(
                  bidding,
                  userRolesByCompany,
                  userProfile
                ) ? (
                  <>
                    <Tooltip
                      title={
                        supplier.isSelectedWinner
                          ? <Translate id="biddingsModule.winner" />
                          : <Translate id="biddingsModule.setAsWinner" />
                      }
                    >
                      <Fab
                        className={classes.fabWinner}
                        size="small"
                        disabled={
                          isSaving &&
                          supplier.supplierCode === identifierIsSaving &&
                          supplier.itemId === identifierItemIdIsSaving
                        }
                        onClick={(event) =>
                          canBeEditBiddingInStatusResolved(
                            bidding,
                            userRolesByCompany,
                            userProfile
                          )
                            ? onSetWinner(event, supplier)
                            : null
                        }
                      >
                        <img
                          alt="WinnerIcon"
                          className={classes.winnerSize}
                          src={
                            supplier.isSelectedWinner
                              ? IsWinnerIcon
                              : NoWinnerIcon
                          }
                        />
                      </Fab>
                    </Tooltip>
                    {isSaving &&
                      supplier.supplierCode === identifierIsSaving &&
                      supplier.itemId === identifierItemIdIsSaving && (
                        <CircularProgress
                          size={52}
                          className={classes.fabProgress}
                        />
                      )}
                  </>
                ) : isVisibleCellData ? (
                  <>
                    <div
                      style={
                        isListItems
                          ? { display: "flex", alignItems: "center" }
                          : null
                      }
                    >
                      {isListItems && supplier.isCatergoryMaterial && (
                        <div className={classes.wrapper}>
                          <Tooltip
                            title={
                              canBeEditBiddingInStatusResolved(
                                bidding,
                                userRolesByCompany,
                                userProfile
                              )
                                ? supplier.isSelectedWinner
                                  ? <Translate id="biddingsModule.deletedWinner" />
                                  : <Translate id="biddingsModule.setAsWinner" />
                                : supplier.isSelectedWinner
                                ? <Translate id="biddingsModule.winner" />
                                : <Translate id="biddingsModule.noSelectedAsWinner" />
                            }
                          >
                            <Fab
                              className={classes.fabWinner}
                              size="small"
                              disabled={
                                isSaving &&
                                supplier.supplierCode === identifierIsSaving &&
                                supplier.itemId === identifierItemIdIsSaving
                              }
                              onClick={(event) =>
                                canBeEditBiddingInStatusResolved(
                                  bidding,
                                  userRolesByCompany,
                                  userProfile
                                )
                                  ? onAddWinnerInItem(
                                      event,
                                      supplier,
                                      !supplier.isSelectedWinner
                                    )
                                  : null
                              }
                            >
                              <img
                                alt="WinnerIcon"
                                className={classes.winnerSize}
                                src={
                                  supplier.isSelectedWinner
                                    ? IsWinnerIcon
                                    : NoWinnerIcon
                                }
                              />
                            </Fab>
                          </Tooltip>
                          {isSaving &&
                            supplier.supplierCode === identifierIsSaving &&
                            supplier.itemId === identifierItemIdIsSaving && (
                              <CircularProgress
                                size={52}
                                className={classes.fabProgress}
                              />
                            )}
                        </div>
                      )}
                      <>
                        <Typography
                          className={classes.noWrapText}
                          variant="caption"
                        >
                          {getResultInform(supplier)}
                        </Typography>
                      </>
                    </div>
                      {supplier.responseMXN && (
                        <Typography align="left" color="primary" variant="subti">
                          {`${translate("biddingsModule.responseInMXN")} ${formatterDolarValue(supplier.responseMXN)}`}
                        </Typography>
                      )}
                  </>
                ) : row.group === SUPPLIERS_FINAL_COMMENTS ? (
                  <IconButton
                    className={
                      IsNullOrEmpty(supplier.finalComment)
                        ? null
                        : classes.finishedComment
                    }
                    onClick={() => onAddCommentsSupplierConfigPanel(supplier)}
                  >
                    <CommentIcon />
                  </IconButton>
                ) : null}
              </TableCell>
              {!isExternalUser && (
                <TableCell
                  key={uuidv4()}
                  align="right"
                  style={
                    row.group !== WINNER_RESULT_BUTTONS &&
                    row.group !== SUPPLIERS_FINAL_COMMENTS
                      ? row.group === RESULT_TOTAL
                        ? { backgroundColor: "#0B72D7", color: "white" }
                        : row.group === RESULT_TOTAL_WINNER_IN_ITEMS
                        ? { backgroundColor: "#88A9DA", color: "white" }
                        : {
                            backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
                          }
                      : {}
                  }
                >
                  {row.group !== WINNER_RESULT_BUTTONS &&
                  row.group !== SUPPLIERS_FINAL_COMMENTS
                    ? percengeFormatResultTable(supplier.evaluatedWeighingTotal)
                    : null}
                </TableCell>
              )}
            </>
          );
        }
      })}
    </>
  );
};

const useCardStyle = (theme) => ({
  flex: {
    display: "flex",
  },
  wrapper: {
    margin: "3px",
    position: "relative",
  },
  percentage: {
    fontWeight: "bold",
    marginLeft: "5px",
  },
  containerServices: {
    maxHeight: `calc(100vh - ${390}px)`,
    minHeight: `calc(100vh - ${390}px)`,
    overflowY: "auto",
  },
  flexGrow: {
    flexGrow: 1,
  },
  inputProps: {
    //height: 8,
    fontSize: 12,
  },
  accordionSummary: {
    paddingRight: "0px !important",
    display: "flex",
    backgroundColor: "#f8f9fa",
  },
  accordionDetails: {
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  buttonsAcordionSummary: {
    display: "flex",
    justifyItems: "flex-end",
    marginRight: "20px",
  },
  btnAcceptWinner: {
    color: COLOR_STATUS_SUCCESS, //"white",
    borderColor: COLOR_STATUS_SUCCESS,
    marginRight: "5px",
  },
  noWrapText: {
    maxWidth: "100px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  btnAcceptNotWinner: {
    color: COLOR_STATUS_BLUE, //"white",
    borderColor: COLOR_STATUS_BLUE,
  },
  marginRightText: {
    marginRight: "10px",
    marginTop: "7px",
  },
  btnWinnerInExpanded: {
    color: COLOR_STATUS_SUCCESS, //"white",
    borderColor: COLOR_STATUS_SUCCESS,
    marginRight: "20px",
  },
  finishedComment: {
    color: COLOR_STATUS_SUCCESS, //"white",
  },
  winnerSize: {
    width: "20px",
    height: "auto",
  },
  iconsWinner: {
    maxWidth: "600px",
  },
  marginLeftIcon: {
    marginleft: "5px",
  },
  fabProgress: {
    color: COLOR_STATUS_SUCCESS,
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  fabWinner: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  titleCell: {
    color: "white",
  },
  winnerIconTotal: {
    display: "flex",
    alignItems: "center",
  },
  declinedMaterial: {
    color: COLOR_STATUS_DANGER,
  },
  criteriaDescription: {
    minWidth: "300px",
  },
});

export default withStyles(useCardStyle, { withTheme: true })(
  ItemProposalResponseByProvider
);
