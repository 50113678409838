import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'

/**Mui imports section */
import { Tooltip, Checkbox, TextField, Grid, InputAdornment, Typography } from '@material-ui/core'
import AcceptIcon from '@material-ui/icons/ThumbUp'
import RejectIcon from '@material-ui/icons/ThumbDown'

/**Component import section */
import DataTable from '../common/DataTable'
import OptionsMenu from '../common/OptionsMenu'
import HtmlEditor from '../common/HtmlEditor'
import Dropzone from '../common/Dropzone'
import SelectorComponent from '../transportTender/common/SelectorComponent'

import { GetCurrencyPTM, GetMeasurementPTM, getSelectedValue } from '../../store/helpers/SelectOptions'
import { COLOR_STATUS_SUCCESS } from '../../store/helpers/StatusColorConstants'
import { sparePartPriceChangeRequestStatus } from '../../store/helpers/AppConstants'
import { descriptionStatus } from '../../store/helpers/SparePartPriceChangeHelper'

const SparePartPriceDetail = (props) => {
	const {
		isInternalUser,
		requestPriceChange,
		onUpdateMaterialToPriceChange,
		onAcceptRejectAction,
		onUpdateSparePartPriceChange,
		onUploadFile,
		onDeleteFile,
		userProfile,
	} = props
	const [itemToAccepted, setItemToken] = useState({ itemToken: '', status: '', comments: '' })

	const isVisibledComments = () => {
		return requestPriceChange !== null && requestPriceChange.status !== sparePartPriceChangeRequestStatus.DRAFT
	}

	const canBeResponsible = () => {
		if (
			requestPriceChange !== null &&
			requestPriceChange.status === sparePartPriceChangeRequestStatus.IN_PROCESS &&
			isInternalUser
		)
			return true
		else return false
	}

	const canBeEdited = () => {
		if (
			requestPriceChange !== null &&
			requestPriceChange.status === sparePartPriceChangeRequestStatus.DRAFT &&
			!isInternalUser
		)
			return true
		else return false
	}

	const canBeEditedNotes = () => {
		if (
			requestPriceChange !== null &&
			(requestPriceChange.status === sparePartPriceChangeRequestStatus.DRAFT ||
				requestPriceChange.status === sparePartPriceChangeRequestStatus.IN_PROCESS) &&
			!isInternalUser
		)
			return true
		else return false
	}

	const onUpdateProperty = (value, propertyName, item) => {
		item[propertyName] = value
		onUpdateMaterialToPriceChange(item.token, item)
	}

	const onSelectMaterial = (event, token, item) => {
		item.change_price = event.target.checked
		onUpdateMaterialToPriceChange(token, item)
	}

	const setCurrencySelected = (option, item) => {
		item.newCurrency = option.value
		onUpdateMaterialToPriceChange(item.token, item)
	}

	const setUnitSelected = (option, item) => {
		item.newUmb = option.value
		onUpdateMaterialToPriceChange(item.token, item)
	}

	const onRenderCellItem = (dataSource, item) => {
		let optionsMeasurementUnit = GetMeasurementPTM()
		let optionsCurrency = GetCurrencyPTM()
		if (dataSource === 'check' && canBeEdited()) {
			return (
				<Tooltip title={'Crear devolución'}>
					<Checkbox
						checked={item.change_price}
						onChange={(event) => onSelectMaterial(event, item.token, item)}
					/>
				</Tooltip>
			)
		}
		if (dataSource === 'status') {
			return <Typography variant='caption'>{descriptionStatus(item.status)}</Typography>
		}
		if (dataSource === 'description') {
			return <Typography variant='caption'>{item.description}</Typography>
		}
		if (dataSource === 'price') {
			return <>${item.price}</>
		}
		if (dataSource === 'newUmb') {
			if (canBeEdited() && item.change_price === true) {
				return (
					<SelectorComponent
						options={optionsMeasurementUnit}
						fonUpdateProperty
						onChange={(option) => setUnitSelected(option, item)}
						value={getSelectedValue(optionsMeasurementUnit, item.newUmb)}
					/>
				)
			} else {
				return (
					<>
						{item.newUmb
							? optionsMeasurementUnit[item.newUmb]
								? optionsMeasurementUnit[item.newUmb].label
								: '-'
							: '-'}
					</>
				)
			}
		}
		if (dataSource === 'newPrice') {
			if (canBeEdited() && item.change_price === true) {
				return (
					<TextField
						defaultValue={item.newPrice}
						InputProps={{
							startAdornment: <InputAdornment position='start'>$</InputAdornment>,
							style: { width: '70px', fontSize: '12px' },
						}}
						onBlur={(event) => onUpdateProperty(event.target.value, 'newPrice', item)}
					/>
				)
			} else {
				return <>${item.newPriceParcer}</>
			}
		}
		if (dataSource === 'newCurrency') {
			if (canBeEdited() && item.change_price === true) {
				return (
					<SelectorComponent
						options={optionsCurrency}
						onChange={(option) => setCurrencySelected(option, item)}
						value={getSelectedValue(optionsCurrency, item.newCurrency)}
					/>
				)
			} else {
				return (
					<div>
						{item.newCurrency
							? optionsCurrency[item.newCurrency]
								? optionsCurrency[item.newCurrency].label
								: '-'
							: '-'}
					</div>
				)
			}
		}
		if (dataSource === 'comments') {
			if (
				canBeResponsible() &&
				item.change_price === true &&
				item.status !== sparePartPriceChangeRequestStatus.UPDATE_SAP &&
				item.status !== sparePartPriceChangeRequestStatus.ERROR_SAP
			) {
				return (
					<TextField
						defaultValue={item.comments}
						onBlur={(event) => onUpdateProperty(event.target.value, 'comments', item)}
						inputProps={{
							style: { fontSize: 12 },
						}}
						margin='dense'
						multiline
					/>
				)
			} else {
				return (
					<Typography variant='caption' style={{ width: '130px' }}>
						{item.comments} <br /> - {item.message_sap}
					</Typography>
				)
			}
		}
		if (dataSource === 'options') {
			return (
				<>
					{renderOptionsMenu(item, 1)}
					{renderOptionsMenu(item, 2)}
				</>
			)
		}
	}

	const handleClickOptionsMenu = (itemToken, status, comments) => {
		setItemToken({ itemToken: itemToken, status: status, comments: comments })
		onAcceptRejectAction(itemToken, status, comments)
	}

	const handleCloseOptionsMenu = () => {
		setItemToken({ itemToken: '', status: '', comments: '' })
	}

	const renderOptionsMenu = (item, optionToShow) => {
		var options = []
		if (canBeResponsible()) {
			if (
				optionToShow === 1 &&
				item.status !== sparePartPriceChangeRequestStatus.UPDATE_SAP &&
				item.status !== sparePartPriceChangeRequestStatus.ERROR_SAP
			) {
				options.push({
					itemClick: () => {
						handleClickOptionsMenu(item.token, 3, 'IsAccepted') //IsAccepted = 3
					},
					tooltipTranslation: <Translate id='common.accept' />,
					menuItemIcon: <AcceptIcon style={{ color: COLOR_STATUS_SUCCESS }} />,
				})
			}
			if (
				optionToShow === 2 &&
				item.status !== sparePartPriceChangeRequestStatus.UPDATE_SAP &&
				item.status !== sparePartPriceChangeRequestStatus.ERROR_SAP
			)
				options.push({
					itemClick: () => {
						handleClickOptionsMenu(item.token, 4, 'IsRejected') //IsRejected = 4
					},
					tooltipTranslation: <Translate id='common.reject' />,
					menuItemIcon: <RejectIcon color='error' />,
				})
		}
		return (
			<OptionsMenu
				itemToken={item.token}
				handleClickOptions={handleClickOptionsMenu}
				open={itemToAccepted.itemToken === item.token}
				handleClickAway={handleCloseOptionsMenu}
				options={options}
				xsSize
			/>
		)
	}

	return (
		<Grid item xs={12}>
			<DataTable
				data={requestPriceChange.materials}
				configuration={TableConfiguration(isVisibledComments())}
				onRenderCellItem={onRenderCellItem}
			/>
			<Grid item xs={12} style={{ height: '100px', marginTop: '30px', marginBottom: '10px' }}>
				{requestPriceChange && isInternalUser && (
					<>
						<Typography color='textSecondary'>
							{<Translate id='sparePartPriceChange.show.notesSap' />}
						</Typography>
						<TextField
							fullWidth
							label={'Folio'}
							defaultValue={requestPriceChange ? requestPriceChange.comments : ''}
							onBlur={(event) => onUpdateSparePartPriceChange('Comments', event.target.value)}
							name='comments'
							margin='normal'
							variant='outlined'
							disabled={!canBeResponsible()}
						/>
					</>
				)}
			</Grid>
			<Grid item xs={12} style={{ height: '250px', marginTop: '30px', marginBottom: '10px' }}>
				{requestPriceChange && userProfile && (
					<HtmlEditor
						id={'notes-price'}
						label={'sparePartPriceChange.show.notes'}
						labelVariant='subtitle1'
						labelColor='textSecondary'
						height={200}
						isDisabled={!canBeEditedNotes()}
						initialValue={requestPriceChange ? requestPriceChange.notes : ''}
						onChange={(data) => onUpdateSparePartPriceChange('Notes', data.value)}
					/>
				)}
			</Grid>
			<Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px' }}>
				<Typography color='textSecondary'>{'Archivos para enviar a planta'}</Typography>
				<Dropzone
					files={requestPriceChange.attachments}
					onChange={onUploadFile}
					onDeleteFile={onDeleteFile}
					isDisabled={!canBeEdited()}
					baseApi={process.env.REACT_APP_SIP_URL_FILES}
				/>
			</Grid>
		</Grid>
	)
}

function TableConfiguration(isVisibled) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'check',
		},
		{
			header: 'status',
			dataSource: 'status',
		},
		{
			header: 'Material',
			dataSource: 'code',
		},
		{
			header: 'Descripción Material',
			dataSource: 'description',
		},
		{
			header: 'UMB',
			dataSource: 'umb',
		},
		{
			header: 'Precio',
			dataSource: 'price',
		},
		{
			header: 'Moneda',
			dataSource: 'currency',
		},
		{
			header: 'Nuevo precio',
			dataSource: 'newPrice',
		},
		{
			header: 'Nueva UMB',
			dataSource: 'newUmb',
		},
		{
			header: 'Nueva moneda',
			dataSource: 'newCurrency',
		},
	]
	if (isVisibled) {
		columns.push({
			header: 'Comentarios',
			dataSource: 'comments',
		})
	}
	columns.push({
		header: '',
		dataSource: 'options',
	})

	return { columns }
}

export default SparePartPriceDetail
