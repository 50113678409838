import { fromJS } from 'immutable';

import * as warrantyReportPtmActions from './warrantyReportPtmAction';

/**
 * warrantyReportPtmReducer initial state
 */
export const warrantyReportInitialState = fromJS({
    isLoadingWarrantyReports: true,
    warrantyReportsList: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    warrantyReportsCount: null,
    isSaving: false,
    isLoading: false,
    warrantyReport: null,
})

export default function warrantyReportReducer(state = warrantyReportInitialState, action) {
    switch (action.type) {
        case warrantyReportPtmActions.START_LOADING_WARRANTY_REPORTS: {
            return state.merge({
                isLoadingRawMaterials: action.isLoading
            })
        }
        case warrantyReportPtmActions.SET_WARRANTY_REPORTS_LIST: {
            let warrantyReports = fromJS(action.warrantyReports);
            return state.merge({
                warrantyReportsList: warrantyReports,
                isLoadingWarrantyReports: false,
                isLoading: false,
                isSaving:false,
            })
        }
        case warrantyReportPtmActions.CHANGE_WARRANTY_REPORTS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case warrantyReportPtmActions.CHANGE_WARRANTY_REPORTS_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case warrantyReportPtmActions.CHANGE_WARRANTY_REPORTS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case warrantyReportPtmActions.SET_WARRANTY_REPORTS_COUNT: {
            return state.merge({
                warrantyReportsCount: action.count
            })
        }
        case warrantyReportPtmActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case warrantyReportPtmActions.IS_LOADING: {
            return state.merge({
                isLoading: action.isLoading
            })
        }
        case warrantyReportPtmActions.SET_WARRANTY_REPORT: {
            return state.merge({
                isLoading: false,
                isSaving: false,
                warrantyReport: fromJS(action.warrantyReport)
            })
        }
        case warrantyReportPtmActions.ADD_FILE_WARRANTY_REPORT_PTM: {
            let oldList = state.getIn(['warrantyReport', 'attachments']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['warrantyReport', 'attachments'], fromJS(newList));
        }
        case warrantyReportPtmActions.REMOVE_FILE_WARRANTY_REPORT_PTM: {
            let oldList = state.getIn(['warrantyReport', 'attachments']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['warrantyReport', 'attachments'], fromJS(newList));
        }
        default:
            return state;
    }
}