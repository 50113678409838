
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {showPlotsFromPartNumber} from '../../../store/helpers/ShowPlotToPartNumber';

/**
 * Container NotificationPartial
 */
export default class NotificationPartial extends Component {

     /**
     * Create an instance of NotificationPartial
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
     * Render
     */
    render() {
        const { classes} = this.props;
        if(this.props.notificationNotice != null){
            let notificationNotice = this.props.notificationNotice;
            return (
                <List>
                     <ListItem>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2"  className={classes.inline} color="textPrimary">
                                    <Translate id="noticeChanges.folioNotification"/>
                                </Typography>
                                {": "}{notificationNotice.project_name}
                            </React.Fragment>
                        } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2"  className={classes.inline} color="textPrimary">
                                    <Translate id="samplesrequest.partNumber"/>
                                </Typography>
                                {": "}{notificationNotice.part_number_full}
                            </React.Fragment>
                        } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2"  className={classes.inline} color="textPrimary">
                                    {"Revision"}
                                </Typography>
                                {": "}{notificationNotice.part_number_revision}
                            </React.Fragment>
                        } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2"  className={classes.inline} color="textPrimary">
                                    <Translate id="noticeChanges.changeRequest"/>
                                </Typography>
                                {": "}{notificationNotice.changes_to_make}
                            </React.Fragment>
                        } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2"  className={classes.inline} color="textPrimary">
                                    <Translate id="samplesrequest.developmentEngineer"/>
                                </Typography>
                                {": "}{notificationNotice.developer_engineer_name}
                            </React.Fragment>
                        } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2"  className={classes.inline} color="textPrimary">
                                    <Translate id="noticeChanges.comments"/>
                                </Typography>
                                {": "}{notificationNotice.developer_comments}
                            </React.Fragment>
                        } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2"  className={classes.inline} color="textPrimary">
                                    <Translate id="noticeChanges.plotNumber"/>
                                </Typography>
                                {": "}<Link onClick={() => showPlotsFromPartNumber(notificationNotice.plane, notificationNotice.company_token)} to="#">{notificationNotice.plane} </Link>
                            </React.Fragment>
                        } />
                    </ListItem>
                    <Divider />
                </List>
            );
        }
        else{
            return(""); 
        }
    }
}
/**
 * NotificationPartial
 */
NotificationPartial.propTypes = {
    notificationNotice: PropTypes.object.isRequired
}