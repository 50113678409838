import React from 'react'
import { Translate } from 'react-localize-redux'

/**
 * Set the payment requests table configuration
 */
export const paymentRequestTableConfiguration = [
	{
		header: <Translate id='common.folio' />,
		dataSource: 'folio',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='common.supplier' />,
		dataSource: 'workshopCode',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='common.creationDate' />,
		dataSource: 'creationDate',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='accreditedWorkshops.amount' />,
		dataSource: 'totalAmount',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='accreditedWorkshops.servicesNumber' />,
		dataSource: 'serviceOrdersNumber',
		isSortable: false,
		filterEnabled: true,
	},
	{
		header: <Translate id='accreditedWorkshops.invoiceFolio' />,
		dataSource: 'invoiceFolio',
		isSortable: false,
		filterEnabled: true,
	},
	{
		header: <Translate id='accreditedWorkshops.creditNoteFolio' />,
		dataSource: 'creditNoteFolio',
		isSortable: false,
		filterEnabled: true,
	},
	{
		header: <Translate id='accreditedWorkshops.paymentRequestDetail.document' />,
		dataSource: 'documentNumber',
		isSortable: false,
		filterEnabled: true,
	},
	{
		header: <Translate id='accreditedWorkshops.statusDate' />,
		dataSource: 'payDate',
		isSortable: true,
		filterEnabled: true,
	},
	{
		header: <Translate id='common.status' />,
		dataSource: 'status',
		isSortable: false,
		filterEnabled: true,
	},
	{
		header: <Translate id='accreditedWorkshops.lastError' />,
		dataSource: 'lastError',
		isSortable: false,
		filterEnabled: false,
	},
	{
		header: '',
		dataSource: 'cancelButton',
		isSortable: false,
	},
	{
		header: '',
		dataSource: 'actions',
		isSortable: false,
	},
]
