export const COLOR_STATUS_ACEPTED = '#DFF0D8'
export const COLOR_STATUS_REJECTED = '#F2DEDE'
export const COLOR_STATUS_DECLINED = '#F9F4B66F'
export const COLOR_STATUS_REQUOTE = '#428AFF6F'

/** Bootstrap colors */
export const COLOR_STATUS_WARNING = '#FFC107'
export const COLOR_STATUS_DANGER = '#DC3545'
export const COLOR_STATUS_SUCCESS = '#28A745'
export const COLOR_STATUS_INFO = '#428AFF6F'
export const COLOR_STATUS_ALERT_INFO = '#D1ECF1'
export const COLOR_STATUS_LIGHT = '#17A2B8'
export const COLOR_STATUS_BLUE = '#428bca'
export const COLOR_STATUS_GRAY = '#6c757d'
export const COLOR_STATUS_PROCESS = '#428aff'
export const COLOR_SECONDARY_BLUE_LIGHT = '#83ECCB'
export const COLOR_STATUS_TRAINING = '#9dfc62'

/** Auxiliar colors constants */
export const BACKGROUND_COLOR_GRAY = '#fafafa'
export const BACKGROUND_COLOR_GRAY_DARK = '#e0e0e0'
export const BACKGROUND_COLOR_CALENDAR_BUTTON = '#1e72cc'
export const BACKGROUND_COLOR_GRAY_BIDDING = '#F6F6F6'

/** Status constants */
export const COLOR_STATUS_DRAFT = 'secondary'
export const COLOR_STATUS_INPROCESS = 'warning'
export const COLOR_STATUS_COMPLETED = 'success'
export const COLOR_STATUS_CONFLICT = 'danger'

/** Release plan colors */
export const RELEASE_PLAN_COLOR_YELLOW = '#FFC107'
export const RELEASE_PLAN_COLOR_BLUE_GRAY = '#4b4bd1'
export const COLOR_ALTERNATE = '#7d586e'
export const COLOR_CERTIFICATE_BLUE = '#428aff1c'

export const COLOR_QUESTIONNAIRE_CHERRY = '#941D3B'
export const COLOR_SUSTAINABILITY_PLUM = '#B79EFF'
export const COLOR_SUSTAINABILITY_ROSE = '#E59A9A'

export const COLOR_STATUS_YELLOW_LIGHT = '#e4b72e'

/**Indirect qualification */
export const COLOR_PRODUCT = '#8CCBBB'
export const COLOR_SUBSECTION_PRODUCT = '#B5EBDE'

export const COLOR_SERVICE = '#97F0F0'
export const COLOR_SUBSECTION_SERVICE = '#CBF5F5'

export const COLOR_EXTRA = '#CDF0E5'
export const COLOR_SUBSECTION_EXTRA = '#D2EAE2'

export const COLOR_FLEXIBILITY = '#BCE6C2'
export const COLOR_SUBSECTION_FLEXIBILITY = '#CBF0D1'

export const COLOR_AVAILABILITY = '#CBF0C5'
export const COLOR_SUBSECTION_AVAILABILITY = '#DDF8D9'

export const COLOR_EFFECTIVE_COMUNICATION = '#C8D8BB'
export const COLOR_SUBSECTION_EFFECTIVE_COMUNICATION = '#D2E3C3'

export const COLOR_COMPLIANCE_PROCESSES = '#E5F0C5'
export const COLOR_SUBSECTION_COMPLIANCE_PROCESSES = '#F4FCDD'

export const COLOR_INCIDENCTS = '#C4C4A3'
export const COLOR_SUBSECTION_INCIDENCTS = '#EDEDCF'

export const COLOR_UNITS = '#9FC4A3'
export const COLOR_SUBSECTION_UNITS = '#B5D9B9'

export const COLOR_CART_PORT = 'rgb(228 239 241)'
