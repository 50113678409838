import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import moment from 'moment'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import WarrantyQualityIcon from '@material-ui/icons/FormatAlignJustify'; 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import SuppliersSelector from '../common/SupplierSelector';
import DatePicker from '../common/DateTimePicker/DatePicker';

import {createWarehouseRawMaterialFromBackEnd} from '../../store/warehouseRawMaterial/WarehouseRawMaterialAction';

/**
 * Component to create corrective action supplier
 */
class Create extends Component{

     /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            creationDate: moment().format('DD/MM/YYYY'),
            supplierSelected: null,
            rawMaterial:{
                deliveryTime: moment(),
                incidence: 0,
                purchase_order: "",
                quality_certificate: "",
                invoice_or_remision: "",
                correct_quantity: "",
                comments: "",
                purchase_order_number: ""
            }
        }
    }

    /**
     * On seletecd supplier
     */
    onSupplierSelected(supplier) {
        this.setState({ supplierSelected: supplier });
    }

      /**
    * Update the property of the raw mateiral
    *
    * @param {*} user
    * @param {*} value
    * @memberof View
    */
   updateProperty(property, value) {
        let creatRawMaterial = { ...this.state.rawMaterial };
        creatRawMaterial[property] = value;
        this.setState({
            rawMaterial: creatRawMaterial
        })
    }

    /**
     * onChangeRange
     * @param {*} deliveryTime 
     */
    onChangeRange(deliveryTime){
        let creatRawMaterial = { ...this.state.rawMaterial };
        creatRawMaterial['deliveryTime'] = deliveryTime;
        this.setState({
            rawMaterial: creatRawMaterial
        })
    }

     /**
     * Can be add item in raw material
     */
    canBeSaved() {
        let rawMaterial = this.state.rawMaterial;
        if (this.state.supplierSelected === null) return false;
        if(!rawMaterial.deliveryTime) return false;
        if (rawMaterial.purchase_order_number === "")
            return false;
        return true;
    }

    /**
     * Get action to create createWarehouseRawMaterial
     */
    createWarehouseRawMaterial(){
        this.props.createWarehouseRawMaterial(this.state.supplierSelected, this.state.rawMaterial)
        .then((rawMaterial) =>{
            this.setState({
                showToView:true,
                showToaster: false
            })
            this.props.history.push(`/warehouseRawMaterials/${rawMaterial.token}`)
        }).catch(() =>{
            this.setState({
                showToaster:true,
                toasterMessage: <Translate id="common.errorToSave"/>,
                toasterVariant: "error",
            })
        });
    }

    render(){
        const { classes} = this.props;
        return(
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <WarrantyQualityIcon />
                        </Avatar>
                    }
                    title={<Typography variant="button" >{<Translate id="warehouseRawMaterial.reportMaterial"/>}</Typography>}
                    subheader={<Typography variant="caption" color="textPrimary"><strong><Translate id="common.createBy"/></strong>: {this.props.user.profile.name}
                    <br/><strong>{<Translate id="common.creationDate"/>}</strong>: {this.state.creationDate}</Typography>}
                />
                <CardContent>
                    <Grid container spacing={24} autoComplete="off">
                        <Grid item xs={6}>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom><Translate id="common.supplier"/> *</Typography>
                                    <SuppliersSelector
                                        className={classes.textField}
                                        onSupplierSelected={(supplier) => { this.onSupplierSelected(supplier) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="standard-read-only-input"
                                        label={<Translate id="warehouseRawMaterial.purchaseOrder"/>}
                                        value={this.state.rawMaterial.purchase_order_number}
                                        type="text"
                                        margin="normal"
                                        onChange={(event) => { this.updateProperty("purchase_order_number", event.target.value) }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={<span>{<Translate id="warehouseRawMaterial.commnetentry"/>} </span>}
                                        multiline
                                        rows="5"
                                        value={this.state.rawMaterial.comments}
                                        onChange={(event) => { this.updateProperty("comments", event.target.value) }}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>{<Translate id="warehouseRawMaterial.dateMaterial"/>} *</Typography>
                                    <DatePicker
                                        onChangeRange={this.onChangeRange}
                                        startDate={this.state.rawMaterial.deliveryTime}
                                        maxDate={moment()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormLabel component="legend">{<Translate id="warehouseRawMaterial.selectDocuments"/>} </FormLabel>
                                        <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox  checked={this.state.rawMaterial.purchase_order}
                                            onChange={(event) => {this.updateProperty('purchase_order', event.target.checked)}} />}
                                            label={<Translate id="warehouseRawMaterial.purchaseOrder"/>}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox  checked={this.state.rawMaterial.quality_certificate}
                                            onChange={(event) => {this.updateProperty('quality_certificate', event.target.checked)}}  />}
                                            label={<Translate id="warehouseRawMaterial.qualityCertificate"/>}
                                        />
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={this.state.rawMaterial.invoice_or_remision}
                                            onChange={(event) => {this.updateProperty('invoice_or_remision', event.target.checked)}}  />}
                                            label={<Translate id="warehouseRawMaterial.invoce"/>}
                                        />
                                         <FormControlLabel
                                            control={
                                            <Checkbox checked={this.state.rawMaterial.correct_quantity}
                                            onChange={(event) => {this.updateProperty('correct_quantity', event.target.checked)}}  />}
                                            label={<Translate id="warehouseRawMaterial.qualityMaterial"/>}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <DialogActions>
                                <Button
                                    data-cy="btn-save"
                                    type="button"
                                    color="primary"
                                    onClick={this.createWarehouseRawMaterial}
                                    disabled={!this.canBeSaved() || this.props.isSaving}>
                                    {<Translate id="common.save" />}
                                </Button>
                                <Button onClick={() => { this.props.history.push(`/warehouseRawMaterials`) }} color="secondary">
                                    {<Translate id="common.cancel" />}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    let selectedCompany = state.profile.get('selectedCompany');
    return {
        user: user,
        isSaving: state.warehouseRawMaterials.get('isSaving'),
        selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        createWarehouseRawMaterial:(supplier, rawMaterial) =>{
            return dispatch(createWarehouseRawMaterialFromBackEnd(supplier, rawMaterial))
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(Create))));