import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import OptionsMenu from '../common/OptionsMenu'
import DonwloadReportDialog from '../common/DonwloadReportDialog'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import ContactsIcon from '@material-ui/icons/SupervisedUserCircle'
import ArchiveDownloadIcon from '@material-ui/icons/Archive'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import AddNoticetIcon from '@material-ui/icons/AddTwoTone'
import Fab from '@material-ui/core/Fab'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadContactSuppliersFromBackEnd,
	CHANGE_CONTACT_SUPPLIERS_SORT_CRITERIA,
	CHANGE_CONTACT_SUPPLIERS_SORT_QUERY,
	getContactSuppliersCount,
	downloadGeneralReportByRangeDate,
} from '../../store/contactsupplier/ContactSupplierAction'
import { converListToArray } from '../../store/helpers/ListHelper'
import { IsUserInRole, ROLE_COMPANYADMINISTRATOR } from '../../store/helpers/RolesHelper'
import { Tooltip } from '@material-ui/core'
import DataTable from '../common/DataTable'
import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers'
import Toaster from '../common/Toaster'

/**
 * contact supplier view
 *
 * @class Contact supplier
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance of Contact suppliers
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			itemToken: null,
			showMenu: true,
			openModalByMonthReport: false,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadContactSuppliers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
			this.props.getContactSuppliersCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadContactSuppliers()
			this.props.getContactSuppliersCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery
		) {
			this.props.loadContactSuppliers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'logo') {
			return (
				<Avatar data-cy='option-avatar'>
					<ContactsIcon color='secondary' />
				</Avatar>
			)
		}
		if (dataSource === 'contact_type') {
			if (item.contact_type === 1) {
				return <Translate id='contactsupplier.contactTypeTemplate' />
			} else {
				return <Translate id='contactsupplier.contactTypeExclude' />
			}
		}
		if (dataSource === 'is_accepted_by_supplier' && item.is_accepted_by_supplier) {
			return 'Si'
		} else if (dataSource === 'is_accepted_by_supplier' && item.is_accepted_by_supplier) {
			return 'No'
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Function to add new contact supplier
	 */
	onClickAddButton() {
		this.props.history.push(`/contactsupplier/create`)
	}

	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof Index
	 */
	handleClickOptionsMenu(token) {
		this.setState({
			itemToken: token,
		})
	}

	/**
	 * Handle on close optons menu to set void item token in state
	 *
	 * @memberof Index
	 */
	handleCloseOptionsMenu() {
		if (!this.state.openModalByMonthReport) {
			this.setState(
				{
					itemToken: '',
					showMenu: false,
				},
				() => {
					this.setState({ showMenu: true })
				}
			)
		}
	}

	/**
	 * Open modal for set report by mont
	 *
	 * @memberof Index
	 */
	handleOpenModal() {
		this.setState({
			openModalByMonthReport: true,
		})
	}

	/**
	 * Close modal to report by month
	 *
	 * @memberof Index
	 */
	handleCloseModal() {
		this.setState(
			{
				openModalByMonthReport: false,
			},
			() => this.handleCloseOptionsMenu()
		)
		this.setState({ startDate: '', endDate: '' })
	}

	/**
	 *Download report of from contacts
	 *
	 * @memberof Index
	 */
	handleDownloadReportByDateRange(startDate, endDate) {
		this.props
			.downloadReportByDateRange(startDate, endDate)
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
				this.handleCloseModal()
			})
			.catch((error) => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	/**
	 *Render action button in card update contact report
	 *
	 * @memberof Index
	 */
	renderAction() {
		var options = []
		options.push({
			itemClick: () => this.handleOpenModal(),
			tooltipTranslation: <Translate id='contactsupplier.downloadReportByMonth' />,
			menuItemIcon: <ArchiveDownloadIcon color='secondary' />,
			isLoading: this.props.isLoadingcontactSupplier,
		})
		if (this.state.showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === 'item.token'}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	/**
	 * Get contactSuppliers counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getContactSupplierCounterMessage() {
		const { classes } = this.props
		if (this.props.contactSuppliers) {
			let totalcontactSuppliers = this.props.contactSuppliers.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{totalcontactSuppliers}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.contactSupplierCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingContactSuppliers === true) {
			return <LoadingPage />
		} else if (this.props.contactSuppliers === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.contactSuppliers}
					configuration={TableConfiguration}
					onRenderCellItem={this.onRenderCellItem}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(contact) => {
						this.props.history.push(`/contactsupplier/${contact.token}`)
					}}
					showFilters
					isIndex
				/>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof ConctactSupplier
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<ContactsIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput onChangeSearchValue={this.onChangeSearchValue} />
							{IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
								<Tooltip title={<Translate id={'contactsupplier.addContact'} />}>
									<Fab
										size='small'
										color='primary'
										onClick={() => {
											this.onClickAddButton()
										}}
									>
										<AddNoticetIcon />
									</Fab>
								</Tooltip>
							)}

							{IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) &&
								this.renderAction()}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='contactsupplier.contactsupplierTitle' />{' '}
						</Typography>
					}
					subheader={this.getContactSupplierCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to download report */}
				<DonwloadReportDialog
					title={<Translate id='contactsupplier.downloadReportByMonth' />}
					open={this.state.openModalByMonthReport}
					onClose={this.handleCloseModal}
					isSaving={this.props.isLoadingcontactSupplier}
					onDonwloadItems={this.handleDownloadReportByDateRange}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'logo',
		},
		{
			header: <Translate id='contactsupplier.typeContact' />,
			dataSource: 'contact_type',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='contactsupplier.name' />,
			dataSource: 'name',
			isSortable: true,
		},
		{
			header: <Translate id='contactsupplier.isAcceptRequired' />,
			dataSource: 'is_accepted_by_supplier',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingContactSuppliers: state.contactSuppliers.get('isLoadingContactSuppliers'),
		contactSuppliers: state.contactSuppliers.get('contactSuppliers')
			? state.contactSuppliers.get('contactSuppliers').toJS()
			: null,
		sortCriteria: state.contactSuppliers.get('sortCriteria')
			? state.contactSuppliers.get('sortCriteria').toJS()
			: null,
		searchQuery: state.contactSuppliers.get('searchQuery'),
		contactSupplierCount: state.contactSuppliers.get('contactSupplierCount'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		translate: getTranslate(state.localize),
		isLoadingcontactSupplier: state.contactSuppliers.get('isSavingContactSupplier'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadContactSuppliers: (query, orderBy, sortAscending) => {
			dispatch(loadContactSuppliersFromBackEnd(query, orderBy, sortAscending))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_CONTACT_SUPPLIERS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_CONTACT_SUPPLIERS_SORT_QUERY,
				query: query,
			})
		},
		getContactSuppliersCount: () => {
			dispatch(getContactSuppliersCount())
		},
		downloadReportByDateRange: (startDate, endDate) => {
			return dispatch(downloadGeneralReportByRangeDate(startDate, endDate))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
