/**Import react section */
import React from "react";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import RateReviewTwoToneIcon from '@material-ui/icons/RateReviewTwoTone';

const EmptyIndirectQuaifications = (props) => {
    /**String props section */
    const { classes } = props
    return (
        <Grid container spacing={8}>
            <Grid item xs={12} className={classes.centerContainer}>
                <RateReviewTwoToneIcon color="primary" className={classes.iconAvatar}/>
            </Grid>
            <Grid item xs={12}>
                <Typography color="primary" align="center">No existen proveedores a calificar</Typography>
                <Typography color="primary" align="center">Agregue el documento con la información necesaria</Typography>
            </Grid>
        </Grid>
    )
}

const styles = (theme) => ({
    centerContainer:{
        display:"flex",
        justifyContent:"center"
    },
    iconAvatar:{
        width:"600px",
        height:"100px",
        opacity:0.5
    }

});

export default withRouter(
    withStyles(styles, { withTheme: true })(EmptyIndirectQuaifications)
);
