///Import react section
import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import autoBind from 'auto-bind';
import { withRouter } from "react-router-dom";
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import {loadDevolutionFromBackEnd} from '../../store/devolutions/DevolutionActions'
import { connect } from 'react-redux';
import {cancelWorflowQualityFromBackend} from '../../store/workflows/workflowActions';
import CancelWorkflow from '../common/CancelWorkflow';

class CancelContainer extends Component {
  constructor (props) {
    super(props);
    autoBind(this);
    this.state = {

    }
  }

    /**
    * componentDidMount
    */
  componentDidMount() {
        let devolutionToken = this.props.match.params.token;
        this.props.loadDevolution(devolutionToken);
  }

      /**
     * Cancel a quality Failure from back end
     */
    onCancelWorkflowsDocument(cancelComment, razonForCancel){
      let token =this.props.match.params.token;
      this.props.cancelWorflowQualityFromBackend(token, cancelComment, razonForCancel)
      .then(() =>{
        this.props.history.push(`/devolutions/${token}`)
      }).catch(() =>{
          this.setState({
              showToaster: true,
              toasterMessage: <Translate id="common.errorToSave" />,
              toasterVariant: "error",
          })
      })
  }

  render () {
    return (
      <CancelWorkflow
        {...this.props}
          workflow= {this.props.devolution}
          onCancelWorkflowsDocument={this.onCancelWorkflowsDocument}
          isSaving={this.props.isCancel}
          link = {'devolutions'}

      />
    )
  }
}

/**
 *  Defines the properties injecteded from the store to view container
 *
 * @param {*} state
 * @returns
 */
function mapStateToProps(state) {
    let devolution = state.devolutions.get("devolution");
   return {
      isCancel: state.workflow.get('isCancel'),
      devolution: devolution ? devolution.toJS() : null,
  }
}

/**
 * Defines the actions injectes to the component
 *
 * @param {*} state
 * @returns
 */
const mapDispatchToProps = dispatch => {
  return {
    loadDevolution: (token) => {
        return dispatch(loadDevolutionFromBackEnd(token));
    },
    cancelWorflowQualityFromBackend: (token, cancelComment, razonForCancel) => {
      return dispatch(cancelWorflowQualityFromBackend(token, cancelComment, razonForCancel));
  },
    
  }
}

export default withauthenticatedLayout((withRouter) (connect(mapStateToProps, mapDispatchToProps)(CancelContainer)));