import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';
import Select from 'react-select';

import { DialogActions, Button, Grid, TextField, Typography } from '@material-ui/core';

import SuppliersSelector from '../../common/SupplierSelector';
import { GetReportType, getSelectedValue } from '../../../store/helpers/SelectOptions';
import Toaster from '../../common/Toaster';

/**Import component section */
import CustomerBySelector from './CustomerBySelector';
import FailureBySelector from './FailureBySelector';
import PartNumberSelector from '../../common/PartNumberSelector';

/**
 * Class component of form to create sacQuality
 *
 * @class FormView
 * @extends {Component}
 */
class FormViewEdit extends Component {
    constructor(props) {
        super(props)
        autoBind(this);
        this.state = {
            isActiveReportInPlant: this.props.guaranteeReport.report_type === "Garantía de planta" ? true : false,
            supplierSelected: {
                token: this.props.guaranteeReport.supplier_token,
                name: this.props.guaranteeReport.supplier_name,
                code: this.props.guaranteeReport.supplier_code,
                label: this.props.guaranteeReport.supplier_name,
                value: this.props.guaranteeReport.supplier_code,
            },
            partNumberSelected: {
                code: this.props.guaranteeReport.part_number,
                description: this.props.guaranteeReport.part_description,
                measurementUnit: this.props.guaranteeReport.measurement_unit,
                label: this.props.guaranteeReport.part_description,
                value: this.props.guaranteeReport.part_number,
            },
            failureSelected: this.props.guaranteeReport.failure_guarantee ? {
                value: this.props.guaranteeReport.failure_guarantee.token,
                label: this.props.guaranteeReport.failure_guarantee.name
            }: null,
            customerSelected:  this.props.guaranteeReport.customer_guarantee ?{
                value: this.props.guaranteeReport.customer_guarantee.token,
                label: this.props.guaranteeReport.customer_guarantee.name
            } : null,
            guarrantee: {
                tokenGuarantee: this.props.guaranteeReport.token,
                userNameReport: this.props.guaranteeReport.user_name_report,
                reportType: this.props.guaranteeReport.report_type,
                serialNumber: this.props.guaranteeReport.serial_number,
                problemDescription: this.props.guaranteeReport.problem_description,
                amountReportedFault: this.props.guaranteeReport.quantity_of_material_in_invoice,
                numberPiecesReceibed: this.props.guaranteeReport.quantity_of_material_affected,
                serviceOrderRepare: this.props.guaranteeReport.service_order_repare ? this.props.guaranteeReport.service_order_repare : ''
            }
        };
    };

    /**
     * On seletecd supplier
     */
    onSupplierSelected(supplier) {
        let newSupplier = {...supplier}
        newSupplier.label = supplier.name
        newSupplier.value = supplier.code
        this.setState({ supplierSelected: newSupplier });
    }

    /**
     * On seleted part number
     */
    onPartNumberSelected(partNumber) {
        let newPartNumber = {...partNumber}
        newPartNumber.label = partNumber.part_number_full
        newPartNumber.value = partNumber.code
        this.setState({ partNumberSelected: newPartNumber });
    }

    /**
     * On seleted customer
     */
    onCustomerSelected(customer) {
        this.setState({ customerSelected: customer });
    }

    /**
   * On seleted failure
   */
    onFailureSelected(failure) {
        this.setState({ failureSelected: failure });
    }


    /**
    * Update the property of the report creatReport Guarrantee
    *
    * @param {*} user
    * @param {*} value
    * @memberof View
    */
    updateProperty(property, value) {
        let creatReportGuarrantee = { ...this.state.guarrantee };
        creatReportGuarrantee[property] = value;
        this.setState({
            guarrantee: creatReportGuarrantee
        })
    }

    /**
     * On change report type
     *
     * @param {*} option
     * @memberof ReportTypeSelect
     */
    onChangeMeasurementUnit(option) {
        let creatReportGuarrantee = { ...this.state.guarrantee };
        creatReportGuarrantee["reportType"] = option.value;
        if (option.value === "Garantía de planta") {
            this.setState({
                isActiveReportInPlant: true
            })
        }
        else {
            this.setState({
                isActiveReportInPlant: false
            })
        }
        this.setState({
            guarrantee: creatReportGuarrantee
        })
    }

    /**
     * call to action to save data and create report guarantee
     *
     * @memberof FormView
     */
    onSaveGuaranteeReport() {
        if (this.props.canBeSaveToEdit)
            this.props.canBeSaveToEdit(this.state.supplierSelected, this.state.partNumberSelected,
                this.state.customerSelected, this.state.failureSelected, this.state.guarrantee);
    }

    /**
     * Render textFiel Enabled to edit
     *
     * @param {*} textFieldId
     * @param {*} translationTextFieldId
     * @param {boolean} [isMultiple=false]
     * @param {string} [type='text']
     * @returns
     * @memberof FormView
     */
    renderTextField(textFieldId, translationTextFieldId, isMultiple = false, type = 'text', isDisabled = false) {
        return (
            <div >
                <TextField
                    disabled={isDisabled}
                    id={textFieldId}
                    fullWidth
                    margin="normal"
                    value={this.state.guarrantee[textFieldId] ? this.state.guarrantee[textFieldId] : ''}
                    label={<Translate id={translationTextFieldId} />}
                    onChange={(event) => { this.updateProperty(textFieldId, event.target.value) }}
                    className={isMultiple ? this.props.classes.textAreaField : this.props.classes.textField}
                    type={type}
                    // Only textAreak
                    multiline={isMultiple}
                    rows={isMultiple ? "4" : "1"}
                    variant={isMultiple ? "outlined" : 'standard'}
                />
            </div>
        )
    }

    /**
     * Method to validate required data is filled
     *
     * @returns
     * @memberof FormView
     */
    canBeSaved() {
        let guarrantee = this.state.guarrantee;
        if (!guarrantee.problemDescription) return false;
        if (!guarrantee.amountReportedFault) return false;
        if (!guarrantee.userNameReport) return false;
        if (!this.state.supplierSelected) return false;
        if (!this.state.partNumberSelected) return false;
        if (!this.state.customerSelected) return false;
        if (!this.state.failureSelected) return false;
        return true;
    }

    /**
     * Perforn render of component
     *
     * @returns
     * @memberof FormView
     */
    render() {
        const { classes } = this.props;
        let measurements = GetReportType();
        return (
            <Grid container spacing={24} style={{ paddingLeft: '80px', paddingRight: "100px", }}>
                <Grid item xs={6} >
                    {this.renderTextField('userNameReport', 'moduleGuarantee.reportGuarantee.producedBy', false)}
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="moduleGuarantee.reportGuarantee.reportType" /></Typography>
                        <Select
                            fullWidth
                            value={getSelectedValue(measurements, this.state.guarrantee.reportType)}
                            options={measurements}
                            onChange={this.onChangeMeasurementUnit}
                            styles={{
                                menu: (base) => ({ ...base, zIndex: 10 })
                            }}
                        />
                    </div>
                    {this.state.isActiveReportInPlant && <div> {this.renderTextField('serialNumber', 'moduleGuarantee.serialNumber', false)}</div>}
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="common.supplier" /></Typography>
                        <SuppliersSelector
                            className={classes.textField}
                            onSupplierSelected={(supplier) => { this.onSupplierSelected(supplier) }}
                            value={this.state.supplierSelected}
                        />
                        {this.state.supplierSelected &&<Typography variant="subtitle1" gutterBottom>{this.state.supplierSelected.label}</Typography>}

                    </div>
                    {this.renderTextField('problemDescription', 'moduleGuarantee.reportGuarantee.problemDescription', true)}
                    {this.renderTextField('serviceOrderRepare', 'moduleGuarantee.reportGuarantee.serviceOrderRepare', false)}
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="common.partNumber" /></Typography>
                        {(this.props.guaranteeReport && this.props.guaranteeReport.is_create_from_sap)  ?
                        <Typography variant="subtitle2">{`${this.props.guaranteeReport.part_number}`}</Typography> :
                        <PartNumberSelector
                            className={classes.textField}
                            onPartNumberSelected={(partNumber) => { this.onPartNumberSelected(partNumber) }}
                            value={this.state.partNumberSelected}
                        />}
                    </div>
                    <div className={classes.textField} >
                        <TextField
                            disabled={true}
                            id="standard-read-only-input"
                            label={<Translate id="common.descriptionPartNumber" />}
                            value={this.state.partNumberSelected ? this.state.partNumberSelected.description : ""}
                            margin="normal"
                            onChange={(event) => { this.updateProperty("descriptionPartNumber", event.target.value) }}
                            fullWidth
                        />
                    </div>

                    <div className={classes.textField} >
                        <TextField
                            disabled={true}
                            id="standard-read-only-input"
                            label={<Translate id="common.measurementUnit" />}
                            value={this.state.partNumberSelected ? this.state.partNumberSelected.measurementUnit : ""}
                            margin="normal"
                            onChange={(event) => { this.updateProperty("measurementUnit", event.target.value) }}
                            fullWidth
                        />
                    </div>
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="moduleGuarantee.reportGuarantee.customer" /></Typography>
                        <CustomerBySelector
                            className={classes.textField}
                            onCustomerGuaranteeSelected={(customerSelected) => { this.onCustomerSelected(customerSelected) }}
                        />
                    </div>
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="moduleGuarantee.reportGuarantee.failure" /></Typography>
                        <FailureBySelector
                            className={classes.textField}
                            onFailureGuaranteeSelected={(failureSelected) => { this.onFailureSelected(failureSelected) }}
                        />
                    </div>
                    <div className={classes.textField} >
                        <TextField fullWidth
                            id="standard-number"
                            label={<Translate id="moduleGuarantee.reportGuarantee.amountReportedFault" />}
                            defaultValue={this.state.guarrantee.amountReportedFault}
                            onChange={(event) => { this.updateProperty("amountReportedFault", event.target.value) }}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                        />
                    </div>
                    <div className={classes.textField} >
                        <TextField fullWidth
                            id="standard-number"
                            label={<Translate id="moduleGuarantee.reportGuarantee.numberPiecesReceibed" />}
                            defaultValue={this.state.guarrantee.numberPiecesReceibed}
                            onChange={(event) => { this.updateProperty("numberPiecesReceibed", event.target.value) }}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                        />
                    </div>

                </Grid>
                <Grid item xs={12} >
                    <DialogActions>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            onClick={() => this.onSaveGuaranteeReport()}
                            disabled={!this.canBeSaved() || this.props.isSaving}
                        >
                            {<Translate id="common.save" />}
                        </Button>
                        <Button onClick={() => { this.props.history.push(`/guaranteereport/${this.props.match.params.token}`) }} color="secondary">
                            {<Translate id="common.cancel" />}
                        </Button>
                    </DialogActions>
                </Grid>
                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Grid>
        )
    }
}


const viewStyles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginBottom: 10,
    },
    textAreaField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    footerForm: {
        display: "flex",
        paddingRight: "60px",
    },
    formControl: { margin: theme.spacing.unit },
    toolbar: {},
    searchInput: {},
    button: {},
    actionsToolbar: {},
    showingUsersCounter: {},
    grow: {},
    supplierBadge: {}
});

export default withRouter(withStyles(viewStyles, { withTheme: true })(FormViewEdit));