import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import yourlogohere from '../../../resources/images/yourlogohere.png'
import DataTable from '../../common/DataTable'
import OptionsMenu from '../../common/OptionsMenu'
import EmployeesInListCard from '../components/EmployeesInListCard'
import Toaster from '../../common/Toaster'
import Service from './components/Service'
import AddModal from './components/AddModal'
import AddServiceProjectDialog from './components/AddServiceProjectDialog'
import Evidence from '../../common/Evidence'

/** Material UI imports section */
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import AttachIcon from '@material-ui/icons/AttachFile'
import FullStarIcon from '@material-ui/icons/Star'
import {
	Typography,
	Tooltip,
	Link,
	AppBar,
	Tabs,
	Tab,
	Divider,
	Grid,
	CardContent,
	Card,
	CardHeader,
} from '@material-ui/core'

/** Actions and helpers imports section */
import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER } from '../../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { EMPLOYEES_TYPE, CONTACTS_TYPE, MATERIALS_TYPE } from '../../../store/helpers/ServicesListTypes'
import {
	showSuccessToaster,
	showErrorToaster,
	showInfoToaster,
	showWarningToaster,
} from './../../../store/helpers/ToasterHelpers'
import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import {
	loadServiceProjectFromBackEnd,
	addEmployeeInServiceProject,
	removeItemInServiceProject,
	addMaterialInServiceProject,
	addContactInServiceProject,
	editServiceProject,
	removeContactInServiceProject,
	downloadMaterialsListReportPdf,
	addDocumentOnServiceProject,
	removeDocumentFiscalInServiceProject,
	loadPurchaseOrder,
} from '../../../store/contractors/ServicesProjectsAction'
import { COMPANY_IMBERA, COMPANY_FERSA } from '../../../store/helpers/ResourcesHelper'
import { changeTypeServiceToIdTranslate } from '../../../store/helpers/ServicesListTypes'

/**
 * Component show service
 */
class Show extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			activeTab: 0,
			serviceProject: {
				employees: [],
			},
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let serviceProjectToken = this.props.match.params.token
		this.props.loadServiceProject(serviceProjectToken)
	}

	/**
	 * Method to change the active tab
	 */
	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	/**
	 * Handle state to show or hide modal
	 *
	 * @memberof Show
	 */
	handleOpenCloseModal(nameModal) {
		this.setState({
			[nameModal]: !this.state[nameModal],
		})
	}

	/**
	 * Update the employees list adding item
	 *
	 * @param {*} selectedEmployee
	 * @memberof Show
	 */
	updateEmployeesList(selectedEmployee) {
		let employeesInList = this.props.serviceProject.employees
		if (
			selectedEmployee !== null &&
			selectedEmployee !== undefined &&
			!IsNullOrEmpty(selectedEmployee.image_employed)
		) {
			if (
				!employeesInList.find((employee) => {
					return employee.token === selectedEmployee.token
				})
			) {
				let serviceProjectToken = this.props.match.params.token
				this.props
					.addEmployeeInServiceProject(selectedEmployee.token, serviceProjectToken)
					.then(() => {
						this.setState(showSuccessToaster())
						return Promise.resolve('Ok')
					})
					.catch((response) => {
						if (!IsNullOrEmpty(response.data) && response.data.Message) {
							this.setState(showWarningToaster(response.data.Message))
						} else {
							this.setState(showErrorToaster())
							return Promise.reject()
						}
					})
			} else {
				this.setState(showInfoToaster('common.repeatedSelection'))
			}
		} else {
			this.setState(showInfoToaster('contractorsModule.employeeWithoutPhoto'))
		}
	}

	/**
	 * Handle to call action and save contact
	 *
	 * @param {*} contact
	 * @returns
	 * @memberof Show
	 */
	handleOnAddContact(contact) {
		let serviceProjectToken = this.props.match.params.token
		return this.props
			.onAddContact(contact, serviceProjectToken)
			.then(() => {
				this.setState(showSuccessToaster())
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showWarningToaster(!IsNullOrEmpty(error) ? error : 'common.errorToAdd'))
				return Promise.reject()
			})
	}

	/**
	 * Handle to call action and save material
	 *
	 * @param {*} material
	 * @returns
	 * @memberof Show
	 */
	handleOnAddMaterial(material) {
		let serviceProjectToken = this.props.match.params.token
		return this.props
			.onAddMaterial(material, serviceProjectToken)
			.then(() => {
				this.setState(showSuccessToaster())
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState(showErrorToaster())
				return Promise.reject()
			})
	}

	/**
	 * Method to call action and remove  from list
	 *
	 * @param {*} tokenItem
	 * @memberof Show
	 */
	removeItemFromList(tokenItem, type) {
		let serviceProjectToken = this.props.match.params.token
		if (type === 'contactsType') {
			this.props
				.removeContact(tokenItem, serviceProjectToken)
				.then(() => {
					this.setState(showSuccessToaster())
				})
				.catch(() => {
					this.setState(showErrorToaster())
				})
		} else {
			this.props
				.removeItemInServiceProject(tokenItem, serviceProjectToken, type)
				.then(() => {
					this.setState(showSuccessToaster())
				})
				.catch(() => {
					this.setState(showErrorToaster())
				})
		}
	}

	/**
	 *  Handle event to call action and edit service/project
	 *
	 * @param {*} serviceProject
	 * @returns
	 * @memberof Show
	 */
	handleOnEditServiceProject(serviceProject) {
		let serviceToken = this.props.match.params.token
		return this.props
			.onEditServiceProject(serviceProject, serviceToken)
			.then(() => {
				this.setState(showSuccessToaster())
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState(showErrorToaster())
				return Promise.reject()
			})
	}

	handleDownloadMaterialsListReport() {
		let serviceToken = this.props.match.params.token
		this.props
			.downloadMaterialsListReport(serviceToken)
			.then(() => {
				this.setState(showSuccessToaster('common.downloadCorrectly'))
			})
			.catch(() => {
				this.setState(showErrorToaster('common.errorToDownload'))
			})
	}

	/**
	 * Function to add fiscal document in service from ptm
	 * @param {*} files
	 */
	onAddDocumentFiscalInServiceProject(files) {
		return this.props
			.addDocumenInServiceProject(this.props.serviceProject.token, 'tax_certificate', files)
			.then(() => {
				return Promise.resolve()
			})
			.catch((error) => {
				return Promise.reject()
			})
	}

	/**
	 * Function to add fiscal document in service from ptm
	 * @param {*} files
	 */
	onAddDocumentPurchaseOrderInServiceProject(files) {
		return this.props
			.addDocumenInServiceProject(this.props.serviceProject.token, 'purchase_order', files)
			.then(() => {
				return Promise.resolve()
			})
			.catch((error) => {
				return Promise.reject()
			})
	}

	onGetOrders(data) {
		return this.props.loadPurchaseOrder(this.props.supplier.code, data.purchase_order)
	}

	/**
	 * Method to render OptionsMenu
	 *
	 * @param {*} item
	 * @returns
	 * @memberof Show
	 */
	renderOptionsMenu(item, type) {
		var options = []
		if (this.props.serviceProject.ca_be_edit_by_supplier && IsCurrentNotActiveDirectoryUser(this.props.user)) {
			options.push({
				itemClick: () => {
					this.removeItemFromList(item.token, type)
				},
				tooltipTranslation: <Translate id='common.delete' />,
				menuItemIcon: <DeleteOutlinedIcon color='secondary' />,
			})
		}
		return (
			<OptionsMenu
				itemToken={item.token}
				handleClickOptions={this.handleClickOptionsMenu}
				open={this.state.itemToken === item.token}
				handleClickAway={this.handleCloseOptionsMenu}
				options={options}
				xsSize
			/>
		)
	}

	/**
	 * Method to render attachments cell in table
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof Show
	 */
	renderAttachOnItem(dataSource, item) {
		return (
			item[dataSource] &&
			item[dataSource].map((file) => {
				return (
					<Grid item xs={12} key={file.name}>
						<Tooltip title={<Translate id='common.downloadFilesTooltip' />} placement='top'>
							<Link
								target='_blank'
								href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${file.relativePath}`}
							>
								<AttachIcon style={{ fontSize: '18px' }} color='primary' />
								{'' + file.name}
							</Link>
						</Tooltip>
					</Grid>
				)
			})
		)
	}

	onRenderCellItem(dataSource, item, type) {
		if (dataSource === 'options') {
			return this.renderOptionsMenu(item, type)
		}
		if (dataSource === 'attachments') return this.renderAttachOnItem(dataSource, item)
		if (dataSource === 'filesSecurity') return this.renderAttachOnItem(dataSource, item)
	}

	onRenderCellItemRatings(dataSource, item) {
		if (dataSource === 'number_stars') {
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						{[1, 2, 3, 4, 5].map((star) => {
							if (star <= item[dataSource]) return <FullStarIcon style={{ color: '#FFD700' }} />
							else return <FullStarIcon style={{ color: '#ABABAB' }} />
						})}
					</Grid>
				</Grid>
			)
		}
	}

	renderPaymentData() {
		const { classes, serviceProject } = this.props
		const supplierLogo = this.props.supplier ? this.props.supplier.logo : ''
		const urlImageSupplier = `${process.env.REACT_APP_IDENTITY_SERVER}/${supplierLogo}`
		const urlBase = `${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${serviceProject.base_path_files}`
		const typeText = changeTypeServiceToIdTranslate(serviceProject.service_project_type)
		return (
			<Card>
				<CardHeader
					avatar={
						<Tooltip title={serviceProject.supplier_name}>
							<Avatar
								alt='company logo'
								src={supplierLogo ? urlImageSupplier : yourlogohere}
								className={classes.avatar}
							></Avatar>
						</Tooltip>
					}
					action={
						serviceProject.ca_be_edit_by_supplier &&
						IsCurrentNotActiveDirectoryUser(this.props.user) && (
							<Tooltip title={<Translate id='common.edit' />}>
								<IconButton
									color='secondary'
									onClick={() => this.handleOpenCloseModal('openEditServiceProjectModal')}
								>
									<EditIcon />
								</IconButton>
							</Tooltip>
						)
					}
					title={
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Grid container spacing={0} justify='flex-start' alignItems='center'>
									<Grid item xs={12}>
										<Typography component='span' variant='h6'>
											<Translate id='common.description' />:{` ${serviceProject.description}`}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='subtitle1'>
											{(this.props.selectedCompany.company_code === COMPANY_IMBERA ||
												this.props.selectedCompany.company_code === COMPANY_FERSA) && (
												<>
													<Translate id='contractorsModule.types' />
													:&nbsp;
													<Translate id={`${typeText}`} />
													&nbsp;&nbsp;
												</>
											)}
											<Translate id='contractorsModule.purchaseOrder' />:
											{` ${serviceProject.purchase_order}`}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					}
					subheader={
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Translate id='contractorsModule.serviceProjectDate' />:
								{` ${serviceProject.start_date} - ${serviceProject.end_date}`}
							</Grid>
							<Grid item xs={12}>
								<Translate id='contractorsModule.serviceProjectAreas' />:
								{` ${serviceProject.service_areas}`}
							</Grid>
							<Grid item xs={12}>
								<Translate id='contractorsModule.requireWeekendsAccess' />:
								<Translate id={serviceProject.enter_weekends === true ? 'common.yes' : 'common.no'} />
							</Grid>
							<Grid item xs={12}>
								<Chip
									style={{
										color: '#fff',
										backgroundColor: serviceProject.is_active_service
											? COLOR_STATUS_SUCCESS
											: COLOR_STATUS_DANGER,
									}}
									label={
										<Translate
											id={serviceProject.is_active_service ? 'common.active' : 'common.inactive'}
										/>
									}
								/>
								{serviceProject.contacts && serviceProject.contacts.length === 0 && (
									<Typography
										component='span'
										variant='body2'
										style={{ color: 'rgba(148, 29, 59, 1)' }}
									>
										{<Translate id='contractorsModule.withoutContact' />}
									</Typography>
								)}
								{serviceProject.is_company_ptm &&
									serviceProject.materials &&
									serviceProject.materials.length === 0 && (
										<Typography
											component='span'
											variant='body2'
											style={{ color: 'rgba(148, 29, 59, 1)' }}
										>
											{<Translate id='contractorsModule.withoutMaterial' />}
										</Typography>
									)}
								{serviceProject.is_company_ptm && !serviceProject.document_constancy_fiscal && (
									<Typography
										component='span'
										variant='body2'
										style={{ color: 'rgba(148, 29, 59, 1)' }}
									>
										{<Translate id='contractorsModule.messageAddDocumnetFiscal' />}
									</Typography>
								)}
								{serviceProject.is_company_ptm && !serviceProject.document_purchase_order && (
									<Typography
										component='span'
										variant='body2'
										style={{ color: 'rgba(148, 29, 59, 1)' }}
									>
										{<Translate id='contractorsModule.messageDocumnetPurchaseOrderRequired' />}
									</Typography>
								)}
							</Grid>
						</Grid>
					}
				/>
				<CardContent style={{ height: '83%' }}>
					<Divider />
					<AppBar position='static' color='default'>
						<Tabs
							indicatorColor='secondary'
							textColor='secondary'
							className={classes.styleTab}
							value={this.state.activeTab}
							onChange={this.onClickToActiveTab}
							variant='fullWidth'
							style={{ marginBottom: '.3rem' }}
						>
							<Tab label={<Translate id='contractorsModule.servicesProjectsTabs.assignedStaff' />} />
							<Tab
								label={
									<>
										<Translate id='contractorsModule.servicesProjectsTabs.contacts' />{' '}
										{serviceProject.company && serviceProject.company.document_identifier}
									</>
								}
							/>
							<Tab label={<Translate id='contractorsModule.servicesProjectsTabs.materials' />} />
							<Tab label={<Translate id='contractorsModule.servicesProjectsTabs.serviceRate' />} />
							{serviceProject.is_company_ptm && (
								<Tab
									label={<Translate id='contractorsModule.servicesProjectsTabs.constanceFiscal' />}
								/>
							)}
							{serviceProject.is_company_ptm && (
								<Tab label={<Translate id='contractorsModule.servicesProjectsTabs.purchaseOrder' />} />
							)}
						</Tabs>
					</AppBar>
					{this.state.activeTab === 0 && (
						<EmployeesInListCard
							employees={serviceProject.employees}
							title={'contractorsModule.employeesInServiceProject'}
							updateEmployeesList={this.updateEmployeesList}
							removeEmployee={(employeeToken) => this.removeItemFromList(employeeToken, EMPLOYEES_TYPE)}
							isSaving={this.props.isSavingServiceProject}
							{...this.props}
							isActive={
								(serviceProject.ca_be_edit_by_supplier &&
									IsCurrentNotActiveDirectoryUser(this.props.user)) ||
								this.props.selectedCompany.company_code === COMPANY_IMBERA ||
								this.props.selectedCompany.company_code === COMPANY_FERSA
							}
						/>
					)}
					{this.state.activeTab === 1 && (
						<Service
							onAddClick={() => this.handleOpenCloseModal('contactsModal')}
							data={serviceProject.contacts ? serviceProject.contacts : []}
							configuration={ContactsTableConfiguration(serviceProject.ca_be_edit_by_supplier)}
							onRenderCellItem={(dataSource, item) =>
								this.onRenderCellItem(dataSource, item, CONTACTS_TYPE)
							}
							isActive={
								serviceProject.ca_be_edit_by_supplier &&
								IsCurrentNotActiveDirectoryUser(this.props.user)
							}
						/>
					)}
					{this.state.activeTab === 2 && (
						<Service
							onAddClick={() => this.handleOpenCloseModal('materialsModal')}
							data={serviceProject.materials ? serviceProject.materials : []}
							configuration={MaterialsTableConfiguration(
								serviceProject.ca_be_edit_by_supplier,
								serviceProject.is_company_ptm
							)}
							onRenderCellItem={(dataSource, item) =>
								this.onRenderCellItem(dataSource, item, MATERIALS_TYPE)
							}
							isActive={
								serviceProject.ca_be_edit_by_supplier &&
								IsCurrentNotActiveDirectoryUser(this.props.user)
							}
							onDownloadMaterialsListReport={this.handleDownloadMaterialsListReport}
						/>
					)}
					{this.state.activeTab === 3 && (
						<DataTable
							data={serviceProject.ratings ? serviceProject.ratings : []}
							configuration={RatingsTableConfiguration}
							onRenderCellItem={this.onRenderCellItemRatings}
						/>
					)}
					{this.state.activeTab === 4 && (
						<>
							{serviceProject.document_constancy_fiscal && (
								<div
									className='embed-responsive embed-responsive-16by9'
									style={{ overflow: 'hidden', height: '100%', width: '100%' }}
								>
									<iframe
										title='addConstanceFiscal'
										className='embed-responsive-item'
										src={`${urlBase}/${serviceProject.document_constancy_fiscal}`}
									/>
								</div>
							)}

							{!serviceProject.document_constancy_fiscal && (
								<Typography component='span' variant='body1'>
									{<Translate id='contractorsModule.notExistConstanceFiscal' />}
								</Typography>
							)}

							{serviceProject.ca_be_edit_by_supplier &&
								IsCurrentNotActiveDirectoryUser(this.props.user) && (
									<Evidence
										text={this.props.translate('contractorsModule.addConstanceFiscal')}
										baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
										removeFiles={this.props.removeDocumentFiscalInServiceProject}
										uploadFiles={this.onAddDocumentFiscalInServiceProject}
										files={[]}
										acceptedFiles={['pdf']}
										disabledLink
										maxFiles={1}
									/>
								)}
						</>
					)}
					{this.state.activeTab === 5 && (
						<>
							{serviceProject.document_purchase_order && (
								<div
									className='embed-responsive embed-responsive-16by9'
									style={{ overflow: 'hidden', height: '100%', width: '100%' }}
								>
									<iframe
										title='addPurchaseOrderFile'
										className='embed-responsive-item'
										src={`${urlBase}/${serviceProject.document_purchase_order}`}
									/>
								</div>
							)}

							{!serviceProject.document_purchase_order && (
								<Typography component='span' variant='body1'>
									{<Translate id='contractorsModule.notExistPurchaseOrder' />}
								</Typography>
							)}

							{serviceProject.ca_be_edit_by_supplier &&
								IsCurrentNotActiveDirectoryUser(this.props.user) && (
									<Evidence
										text={this.props.translate('contractorsModule.addPurchaseOrderFile')}
										baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
										removeFiles={this.props.removeDocumentFiscalInServiceProject}
										uploadFiles={this.onAddDocumentPurchaseOrderInServiceProject}
										files={[]}
										acceptedFiles={['pdf']}
										disabledLink
										maxFiles={1}
									/>
								)}
						</>
					)}
				</CardContent>
			</Card>
		)
	}

	render() {
		const { isLoadingPurchaseOrders, purchaseOrder } = this.props
		return (
			<Grid container spacing={24}>
				<Grid item xs={12}>
					{this.props.serviceProject && this.renderPaymentData()}
				</Grid>
				<AddModal
					open={this.state['contactsModal']}
					type={CONTACTS_TYPE}
					title='contractorsModule.addContact'
					isSaving={this.props.isSavingServiceProject}
					addItem={this.handleOnAddContact}
					onClose={() => this.handleOpenCloseModal('contactsModal')}
				/>
				<AddModal
					open={this.state['materialsModal']}
					type={MATERIALS_TYPE}
					title='contractorsModule.addMaterial'
					dropzoneText={this.props.translate('dropzone.dropzoneBase')}
					isSaving={this.props.isSavingServiceProject}
					addItem={this.handleOnAddMaterial}
					onClose={() => this.handleOpenCloseModal('materialsModal')}
					canBeFileSecurity={this.props.serviceProject && this.props.serviceProject.is_company_ptm}
				/>
				{this.state.openEditServiceProjectModal && (
					<AddServiceProjectDialog
						open={this.state.openEditServiceProjectModal}
						isSaving={this.props.isSavingServiceProject}
						editServiceProject={this.handleOnEditServiceProject}
						onClose={() => this.handleOpenCloseModal('openEditServiceProjectModal')}
						onGetOrders={this.onGetOrders}
						data={this.props.serviceProject}
						selectedCompany={this.props.selectedCompany}
						purchaseOrder={purchaseOrder}
						isLoadingPurchaseOrders={isLoadingPurchaseOrders}
					/>
				)}
				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Grid>
		)
	}
}

function ContactsTableConfiguration(isActive) {
	let columns = [
		{
			header: <Translate id='contractorsModule.contacts.name' />,
			dataSource: 'name',
		},
		{
			header: <Translate id='contractorsModule.contacts.email' />,
			dataSource: 'email',
		},
		{
			header: <Translate id='contractorsModule.contacts.phone' />,
			dataSource: 'phone_number',
		},
		{
			header: <Translate id='contractorsModule.contacts.extension' />,
			dataSource: 'phone_extension',
		},
	]
	if (isActive)
		columns.push({
			header: '',
			dataSource: 'options',
		})
	return { columns }
}

function MaterialsTableConfiguration(isActive, isPtm) {
	let columns = [
		{
			header: <Translate id='contractorsModule.materials.name' />,
			dataSource: 'name',
		},
		{
			header: <Translate id='contractorsModule.materials.description' />,
			dataSource: 'description',
		},
		{
			header: <Translate id='contractorsModule.materials.attachments' />,
			dataSource: 'attachments',
		},
	]

	if (isPtm) {
		columns.push({
			header: <Translate id='contractorsModule.materials.fileSecurity' />,
			dataSource: 'filesSecurity',
		})
	}

	if (isActive) {
		columns.push({
			header: '',
			dataSource: 'options',
		})
	}

	return { columns }
}

const RatingsTableConfiguration = {
	columns: [
		{
			header: <Translate id='contractorsModule.ratings.ratedBy' />,
			dataSource: 'rated_by',
		},
		{
			header: <Translate id='contractorsModule.ratings.ratingDate' />,
			dataSource: 'rated_date',
		},
		{
			header: <Translate id='contractorsModule.ratings.comments' />,
			dataSource: 'comments',
		},
		{
			header: <Translate id='contractorsModule.ratings.rate' />,
			dataSource: 'number_stars',
		},
	],
}

const styles = (theme) => ({
	logoCompany: {
		maxHeight: 100,
	},
	styleTab: {
		backgroundColor: '#fff',
	},
	media: {
		height: 0,
		paddingTop: '56.25%',
	},
	avatar: {
		margin: 10,
		width: 100,
		height: 100,
	},
})

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	return {
		user: user,
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingServiceProject: state.contractorServicesProjects.get('isLoadingServiceProject'),
		isSavingServiceProject: state.contractorServicesProjects.get('isSavingServiceProject'),
		serviceProject: state.contractorServicesProjects.get('serviceProject')
			? state.contractorServicesProjects.get('serviceProject').toJS()
			: null,
		employeesInList: state.contractorServicesProjects.get('employeesInList')
			? state.contractorServicesProjects.get('employeesInList').toJS()
			: null,
		translate: getTranslate(state.localize),
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		isLoadingPurchaseOrders: state.contractorServicesProjects.get('isLoadingPurchaseOrders'),
		purchaseOrder: state.contractorServicesProjects.get('purchaseOrders')
			? state.contractorServicesProjects.get('purchaseOrders').toJS()
			: null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadServiceProject: (token) => {
			return dispatch(loadServiceProjectFromBackEnd(token))
		},
		addEmployeeInServiceProject: (employeeToken, serviceProjectToken) => {
			return dispatch(addEmployeeInServiceProject(employeeToken, serviceProjectToken))
		},
		removeItemInServiceProject: (tokenItem, serviceToken, type) => {
			return dispatch(removeItemInServiceProject(tokenItem, serviceToken, type))
		},
		onAddContact: (contact, serviceToken) => {
			return dispatch(addContactInServiceProject(contact, serviceToken))
		},
		removeContact: (tokenItem, serviceToken) => {
			return dispatch(removeContactInServiceProject(tokenItem, serviceToken))
		},
		onAddMaterial: (material, serviceToken) => {
			return dispatch(addMaterialInServiceProject(material, serviceToken))
		},
		onEditServiceProject: (serviceProject, serviceToken) => {
			return dispatch(editServiceProject(serviceProject, serviceToken))
		},
		downloadMaterialsListReport: (serviceToken) => {
			return dispatch(downloadMaterialsListReportPdf(serviceToken))
		},
		addDocumenInServiceProject: (serviceToken, documentType, files) => {
			return dispatch(addDocumentOnServiceProject(serviceToken, documentType, files))
		},
		removeDocumentFiscalInServiceProject: (fileName) => {
			return dispatch(removeDocumentFiscalInServiceProject(fileName))
		},
		loadPurchaseOrder: (supplierCode, purchaseOrder) => {
			return dispatch(loadPurchaseOrder(supplierCode, purchaseOrder))
		},
	}
}

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
