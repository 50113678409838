import { createSelector } from 'reselect'
import { toSafeJS } from '../helpers/ImmutableHelper'

const getIndexState = (state) => state.securityCourses

/**
 * Get Transport tenders list selector
 */
export const getSecurityCoursesSelector = createSelector([getIndexState], (indexState) => {
	const securityCourses = indexState.get('securityCoursesPaginated')
	return securityCourses ? toSafeJS(securityCourses) : []
})
export const getIsLoadingSaving = createSelector([getIndexState], (indexState) => {
	const isSaving = indexState.get('isLoading')
	return isSaving
})
export const getIsLoadingVideo = createSelector([getIndexState], (indexState) => {
	const isLoadingVideo = indexState.get('isLoadingVideo')
	return isLoadingVideo
})
export const getDetailSecurityCourseSelector = createSelector([getIndexState], (indexState) => {
	const securityCourse = indexState.get('detail')
	return securityCourse ? toSafeJS(securityCourse) : null
})
