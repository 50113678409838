///Import react section
import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch, } from 'react-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/** Language imports **/
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Avatar from '@material-ui/core/Avatar';
import SustainabilityIcon from '@material-ui/icons/NaturePeople';
import ListIcon from '@material-ui/icons/List';
import AddtIcon from '@material-ui/icons/AddTwoTone';
import WarningIcon from '@material-ui/icons/Warning';
import { Tooltip, Card, CardContent, CardHeader, Fab, Typography} from '@material-ui/core';

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import DesktopView from './DesktopView';
import Toaster from '../../common/Toaster';
import CardCreateTemplate from './components/CardCreateTemplate';
import CardCreateSubTopic from './components/CardCreateSubTopic';
import CardCreateQuestion from './components/CardCreateQuestion';
import DeleteCardConfirmation from './components/DeleteCardConfirmation';
import {COLOR_SUSTAINABILITY_ROSE, COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER} from '../../../store/helpers/StatusColorConstants';

/** Import actions */  
import {loadTemplateQuestionnaire, addTopicFromBackend, addSubTopicFromBackend,
    addQuestionFromBackend, deleteTopicFromBackend, deleteSubTopicFromBackend,
    deleteQuestionFromBackend, updateTopicFromBack, updateSubTopicFromBack,
    updateQuestionFromBack, setQuestionIndexFromBack} from '../../../store/sustainability2/SustainabilityActions';
  

/**
 * Component load list
 * @param {*} props 
 */
const Template = (props) => {
    const { classes, } = props

    const [openModalTemplate, setOpenModalTemplate] = useState({topicId: 0, subTopicId: 0, openModal: false, edit: false, delete: false, itemSelected: {}})
    const [openModalSubTopic, setOpenModalSubTopic] = useState({topicId: 0, subTopicId: 0, openModal: false, edit: false, delete: false, itemSelected: {}})
    const [openModalQuestion, setOpenModalQuestion] = useState({topicId: 0, subTopicId: 0, questionId: 0, openModal: false, edit: false, delete: false, itemSelected: {}})

    /**Render hooks section */ 
    const dispatch = useDispatch()

    /**Redux store information section*/
    const isLoading = useSelector((state) => state.sustainabilityV2.get('isLoading'))
    const template = useSelector((state) => state.sustainabilityV2.get('templateQuestionnaire') ? state.sustainabilityV2.get('templateQuestionnaire').toJS() : null)

    const isSavingTemplate = useSelector((state) => state.sustainabilityV2.get('isSavingTemplate'))

    /**Store local this component */
    const [toasterMessageConfig, setToasterMessageConfig] = useState({
        showToaster: false,
        toasterMessage: null,
        toasterVariant: "",
        name: ""
    })

    /**Use effect section */
    useEffect(() => {
        dispatch(loadTemplateQuestionnaire())
    }, [dispatch])

    /**
     * Function to show sustainabilityv2
     */
     const onOpenSustainability = ( event) => {
        props.history.push(`/sustainabilityv2`)
    }

    /*******************************************************************/
    /******************************* ADD *******************************/
    /*******************************************************************/

    /** Function to open cart to add a new topic **/
     const onClickAddTemplated = () => {
         setOpenModalTemplate({
            openModal: !openModalTemplate.openModal,
            edit: false,
            topicId: 0,
            itemSelected: {}});
    }

    /** Function to add a new topic **/
    const onAddTopic = (itemMolde) => {
       return dispatch(addTopicFromBackend(template.Id, itemMolde))
        .then(() => {
            onClickAddTemplated()
        })
        .catch((error) => {
            setToasterMessageConfig({
                showToaster: true,
                toasterMessage: "common.errorToSave",
                toasterVariant: "error",
            })
        });
    }

    /** Function to open cart to add subtopic **/
     const onClickAddSubTopic = (topicId) => {
        setOpenModalSubTopic({...openModalSubTopic,
            openModal: !openModalSubTopic.openModal,
            topicId: topicId});
    }

    /** Function on add subtopic **/
    const onAddsubTopic = (itemMolde) => {
        return dispatch(addSubTopicFromBackend(template.Id, openModalSubTopic.topicId, itemMolde))
        .then(() => {
            onClickAddSubTopic()
        })
        .catch((error) => {
            setToasterMessageConfig({
                showToaster: true,
                toasterMessage: "common.errorToSave",
                toasterVariant: "error",
            })
        });
    }

    /** Function to open add card question **/
     const onClickAddQuestion = (topicId, subTopicId) => {
        setOpenModalQuestion({...openModalQuestion,
            openModal: !openModalQuestion.openModal,
            topicId: topicId,
            subTopicId: subTopicId});
    }

    /** Function on add Question **/
    const onAddQuestion = (itemMolde) => {
        return dispatch(addQuestionFromBackend(template.Id, openModalQuestion.topicId, openModalQuestion.subTopicId, itemMolde))
        .then(() => {
            onClickAddQuestion()
        })
        .catch((error) => {
            setToasterMessageConfig({
                showToaster: true,
                toasterMessage: "common.errorToSave",
                toasterVariant: "error",
            })
        });
    }

    /**********************************************************************/
    /******************************* DELETE *******************************/
    /**********************************************************************/

    /** Function to open car to delete topic **/
     const openDeleteTemplated = (topicId) => {
        setOpenModalTemplate({...openModalTemplate,
            delete: !openModalTemplate.delete,
            topicId: topicId});
    }

    /** Function to delete topic **/
     const onDeleteTopic = (itemMolde) => {
        return dispatch(deleteTopicFromBackend(template.Id, itemMolde))
         .then(() => {
             setOpenModalTemplate({
                openModal: false,
                edit: false,
                delete:false,
                topicId: 0,
                itemSelected: {}});
         })
         .catch((error) => {
            setToasterMessageConfig({
                showToaster: true,
                toasterMessage: "common.errorToSave",
                toasterVariant: "error",
            })
         });
     }


    /** Function to open card to delete subtopic **/
     const openDeleteSubTopic = (topicId, subTopicId) => {
        setOpenModalSubTopic({...openModalSubTopic,
            delete: !openModalSubTopic.delete,
            topicId: topicId,
            subTopicId: subTopicId});
    }

    /** Function on delete subtopic **/
    const onDeleteSubTopic = (itemMolde) => {
        return dispatch(deleteSubTopicFromBackend(template.Id, itemMolde))
        .then(() => {
            openDeleteSubTopic()
        })
        .catch((error) => {
            setToasterMessageConfig({
                showToaster: true,
                toasterMessage: "common.errorToSave",
                toasterVariant: "error",
            })
        });
    }

    /** Function to delete question **/
     const openDeleteQuestion = (topicId, subTopicId, questionId) => {
        setOpenModalQuestion({...openModalQuestion,
            delete: !openModalQuestion.delete,
            topicId: topicId,
            subTopicId: subTopicId,
            questionId: questionId});
    }

    /** Function delete Question **/
     const onDeleteQuestion = (itemMolde) => {
        console.log(itemMolde)
        return dispatch(deleteQuestionFromBackend(template.Id, itemMolde))
        .then(() => {
            openDeleteQuestion()
        })
        .catch((error) => {
            setToasterMessageConfig({
                showToaster: true,
                toasterMessage: "common.errorToSave",
                toasterVariant: "error",
            })
        });
    }

    /**********************************************************************/
    /******************************** EDIT ********************************/
    /**********************************************************************/

    /** Function to open edit topic **/
     const openEditTemplated = (topicId, itemSelected) => {
       setOpenModalTemplate({...openModalTemplate,
            openModal: !openModalTemplate.openModal,
            edit: !openModalTemplate.edit,
            topicId: topicId,
            itemSelected: itemSelected});
   }
    /** Function to edit topic **/
    const onEditTopic = (itemMolde) => {
       return dispatch(updateTopicFromBack(template.Id, itemMolde))
        .then(() => {
            onClickAddTemplated()
        })
        .catch((error) => {
            setToasterMessageConfig({
                showToaster: true,
                toasterMessage: "common.errorToSave",
                toasterVariant: "error",
            })
        });
    }

    /** Function to open edit subtopic **/
     const openEditSubTopic = (topicId, subTopicId, itemSelected) => {
        setOpenModalSubTopic({...openModalSubTopic,
            openModal: !openModalSubTopic.openModal,
            edit: !openModalSubTopic.edit,
            topicId: topicId,
            subTopicId: subTopicId,
            itemSelected: itemSelected});
    }

    /** Function on Edit subtopic **/
     const onEditSubTopic = (itemMolde) => {
        return dispatch(updateSubTopicFromBack(template.Id, itemMolde))
        .then(() => {
            openEditSubTopic()
        })
        .catch((error) => {
            setToasterMessageConfig({
                showToaster: true,
                toasterMessage: "common.errorToSave",
                toasterVariant: "error",
            })
        });
    }

    /** Function to edit question **/
     const openEditQuestion = (topicId, subTopicId, questionId, itemSelected) => {
        setOpenModalQuestion({...openModalQuestion,
            openModal: !openModalQuestion.openModal,
            edit: !openModalQuestion.edit,
            topicId: topicId,
            subTopicId: subTopicId,
            questionId: questionId,
            itemSelected: itemSelected});
    }

    /** Function on edit Question **/
     const onEditQuestion = (itemMolde) => {
       return dispatch(updateQuestionFromBack(template.Id, itemMolde))
       .then(() => {
            openEditQuestion()
       })
       .catch((error) => {
        setToasterMessageConfig({
            showToaster: true,
            toasterMessage: "common.errorToSave",
            toasterVariant: "error",
        })
       });
   }

   const isCompletePercentage = (totalPercentage) =>{
        if(totalPercentage !== 100)
            return false;
        else
            return true;
   }

   const onDragEndQuestion = (question, subtopicId) => {
        return dispatch(setQuestionIndexFromBack(question, subtopicId))
        .catch(() => {
            setToasterMessageConfig({
                showToaster: true,
                toasterMessage: "common.errorToSave",
                toasterVariant: "error",
            })
        });
    }

    return (
        <Card>
            <CardHeader
                title={<Translate id="sustainability.sustainabilityTemplate"/>}
                avatar={
                    <Avatar>
                        <SustainabilityIcon />
                    </Avatar>
                }
                subheader={
                    <Typography variant="caption" style={{color: isCompletePercentage(template && template.TotalPercentage) ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER }}>
                        {"Porcentaje total: "}   <strong>{template && template.TotalPercentage} % </strong>
                    </Typography>}
                action={
                    <div className={classes.actionsToolbar}>
                        <Tooltip title={<Translate id="questionnaire.addTopic"/>}>
                            <Fab size="small" color="primary" onClick={() => onClickAddTemplated()}>
                                <AddtIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title={<Translate id="sustainability.returnView"/>}>
                            <Fab className={classes.marginIconReturn} size="small" color="primary" onClick={() => { onOpenSustainability() }}>
                                <ListIcon />
                            </Fab>
                        </Tooltip>
                    </div>
                }
            />
            <CardContent  style={{ height: '65vh', overflowY: 'auto', maxWidth:"calc(100vw - 300px)" }}>
                {template && !isCompletePercentage(template && template.TotalPercentage) &&        
                <div className={classes.warningAlert}>
                    <WarningIcon/>
                    <Typography variant="caption">
                        <Translate id="sustainability.alertMessageSustainabilityTemplate"/>
                    </Typography>
                </div>}
                <DesktopView 
                    isLoading={isLoading}
                    template={template}
                    openCardSubtopic={onClickAddSubTopic}
                    openCardQuestion={onClickAddQuestion}
                    openDeleteTopic={openDeleteTemplated}
                    openDeleteSubTopic={openDeleteSubTopic}
                    openDeleteQuestion={openDeleteQuestion}
                    openEditTemplated={openEditTemplated}
                    openEditSubTopic={openEditSubTopic}
                    openEditQuestion={openEditQuestion}
                    onDragEndQuestion={onDragEndQuestion}
                />
 
            </CardContent>

            <Toaster
                message={<Translate id={toasterMessageConfig.toasterMessage} />}
                open={toasterMessageConfig.showToaster}
                variant={toasterMessageConfig.toasterVariant}
                onClose={() => {
                    setToasterMessageConfig({
                        showToaster: false,
                        toasterMessage: "",
                        toasterVariant: "",
                    })
                }}
            />

            {/***********************************************************************/}
            {/********************************* ADD  ********************************/}
            {/********************************* EDIT ********************************/}
            {/***********************************************************************/}

            {openModalTemplate.openModal && 
                <CardCreateTemplate
                    open={openModalTemplate.openModal}
                    itemSelected={openModalTemplate.itemSelected}
                    isEdit={openModalTemplate.edit}
                    onSaveItem={onAddTopic}
                    onClose={onClickAddTemplated}
                    onCloseEdit={openEditTemplated}
                    isSaving={isSavingTemplate}
                    onSaveItemEdit={onEditTopic}
                    topicId={openModalTemplate.topicId}
                />
            }

            {openModalSubTopic.openModal && 
                <CardCreateSubTopic
                    open={openModalSubTopic.openModal}
                    itemSelected={openModalSubTopic.itemSelected}
                    isEdit={openModalSubTopic.edit}
                    onCloseEdit={openEditSubTopic}
                    onSaveItemEdit={onEditSubTopic}
                    onSaveItem={onAddsubTopic}
                    onClose={onClickAddSubTopic}
                    isSaving={isSavingTemplate}
                    topicId={openModalSubTopic.topicId}
                    subTopicId={openModalSubTopic.subTopicId}
                />
            }

            {openModalQuestion.openModal && 
                <CardCreateQuestion
                    open={openModalQuestion.openModal}
                    onSaveItem={onAddQuestion}
                    onClose={onClickAddQuestion}
                    isSaving={isSavingTemplate}
                    itemSelected={openModalQuestion.itemSelected}
                    isEdit={openModalQuestion.edit}
                    onCloseEdit={openEditQuestion} 
                    onSaveItemEdit={onEditQuestion}
                    topicId={openModalQuestion.topicId}
                    subTopicId={openModalQuestion.subTopicId}
                    questionId={openModalQuestion.questionId}
                />
            }
            
            {/**********************************************************************/}
            {/******************************* DELETE *******************************/}
            {/**********************************************************************/}

            {/* OPEN DELETE TOPIC CONFIRMATION */}
            {openModalTemplate.delete && 
                <DeleteCardConfirmation
                    open={openModalTemplate.delete}
                    isDeleteTopic={openModalTemplate.delete}
                    onSaveItem={onDeleteTopic}
                    onClose={openDeleteTemplated}
                    isSaving={isSavingTemplate}
                    topicId={openModalTemplate.topicId}
                />
            }

            {/* OPEN DELETE SUBTOPIC CONFIRMATION */}
            {openModalSubTopic.delete && 
                <DeleteCardConfirmation
                    open={openModalSubTopic.delete}
                    isDeleteSubTopic={openModalSubTopic.delete}
                    onSaveItem={onDeleteSubTopic}
                    onClose={openDeleteSubTopic}
                    isSaving={isSavingTemplate}
                    topicId={openModalSubTopic.topicId}
                    subTopicId={openModalSubTopic.subTopicId}
                />
            }

            {/* OPEN DELETE QUESTION CONFIRMATION */}
            {openModalQuestion.delete && 
                <DeleteCardConfirmation
                    open={openModalQuestion.delete}
                    isDeleteQuestion={openModalQuestion.delete}
                    onSaveItem={onDeleteQuestion}
                    onClose={openDeleteQuestion}
                    isSaving={isSavingTemplate}
                    topicId={openModalQuestion.topicId}
                    subTopicId={openModalQuestion.subTopicId}
                    questionId={openModalQuestion.questionId}
                />
            }
        </Card>
    )
}


const styles = ((theme) => ({
    actionsToolbar: {
        '& > *': {
            marginRight: '5px',
        },
    },
    marginIconReturn:{
        marginLeft: "5px",
        backgroundColor: COLOR_SUSTAINABILITY_ROSE
    },
    warningAlert: {
        margin: '10px 0',
        padding: '10px',
        borderRadius: '3px',
        display: 'flex',
        backgroundColor: '#fcf8e3',
        borderColor: '#faebcc',
        color: '#8a6d3b',
    }
}))


export default withauthenticatedLayout(withRouter((withStyles(styles, { withTheme: true })(Template))))