import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import moment from 'moment'

/** Material-UI imports section */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LoadingPage from '../common/LoadingPage';
import Toaster from '../common/Toaster';

/** Import actions section */
import FloatingButton from '../common/FloatingButton';
import DialogAvailableOperations from '../common/DialogAvailableOperations';
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow';
import HistoryCard from '../common/HistoryCard';
import NotificationPartial from './components/NotificationPartial';
import SendEffectiveDate from './components/SendEffectiveDate';
import WorkflowCancel from '../common/WorkflowCancel';

import { WORKFLOW_CANCELLATION } from '../../store/helpers/WorkFlowDocumentsType';
import {loadNotificationNoticeFromBackEnd, respondNoticeOfChangeBySupplier} from '../../store/noticeChanges/NoticeChangeAction';
import { loadSupplier, loadAdvanceOptionsSupplier } from '../../store/suppliers/SupplierActions';
import { converListToArray } from '../../store/helpers/ListHelper'
import { CaBeCancelQuote } from '../../store/helpers/RolesHelper';

/**
 * Container show View Notification
 */
class ViewNotification extends Component {

    /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openAvailableOperationsDialog: false,
            openDeclineRequest: false,
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            inventory: 0,
            measurementUnit: 0,
            effectiveDate: moment(),
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let quoteToken = this.props.match.params.token;
        this.props.loadNotificationNotice(quoteToken);
    }

    
    /**
     * On change date effectiveDate
     * @param {*} effectiveDate 
     */
    onChangeRange(effectiveDate) {
        this.setState({
            effectiveDate,
        })
    }

      /**
     * On change document type
     *
     * @param {*} option
     * @memberof DocumentTypeSelect
     */
    onChangeMeasurementUnit(option) {
        this.setState({
            measurementUnit: option.value
        })
    }

      /**
     * Update property item
     * @param {*} property 
     * @param {*} value 
     */
    updateProperty(value) {
        this.setState({
            inventory: value
        })
    }

    /**
   * componentDidUpdate
   *
   * @param {*} prevProps
   * @param {*} prevState
   * @memberof Index
   */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoadingNotification !== this.props.isLoadingNotification) && this.props.notificationNotice !== null) {
            /// Get information full from sampleRequest
            let notificationNotice = this.props.notificationNotice;
            this.props.loadSupplier(notificationNotice.supplier_token);
            this.props.loadAdvanceOptionsSupplier(notificationNotice.supplier_code);
        }
    }


      /**
     * onSendSampleRequestToClient
     *
     * @param {*} item
     * @param {*} supplier
     * @memberof Create
     */
    onRespondNoticeOfChangeBySupplier() {
        let effectiveDate = this.state.effectiveDate.format("DD/MM/YYYY");
        this.props.respondNoticeOfChangeBySupplier(this.props.notificationNotice.token, effectiveDate, this.state.inventory, this.state.measurementUnit)
        .then(() =>{
            this.setState({
                openAvailableOperationsDialog:false,
                showToView:true,
                showToaster: true,
                toasterVariant: "success",
                toasterMessage: <Translate id="common.saveCorrectly"/>,
            })
        }).catch(() =>{
            this.setState({
                showToaster:true,
                toasterMessage: <Translate id="common.errorToSave"/>,
                toasterVariant: "error",
            })
        });
    }

    /**
     * Cancel a quote
     */
    onCancelDocument() {
        this.props.history.push(`/noticechanges/cancel/${this.props.notificationNotice.token}`)
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoadingNotification === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.notificationNotice !== null) {
            return (
                <Grid container spacing={24}>
                     <Grid item xs={12}>
                        <NotificationPartial {...this.props} />
                    </Grid>
                </Grid>
            )
        }
    }

    /**
     * Action to set state to open of available operations dialog
     * @memberof 
     */
    setOpenDialog = (openAvailable) => {
        this.setState({
            openAvailableOperationsDialog: openAvailable,
        });

    }

    /**
     * Render send rrquest
     */
    renderSendRequest(){
        if(this.props.notificationNotice){
            let createdBy= '';
            let crationDate = '';
            if(this.props.notificationNotice.response_document){
                createdBy = this.props.notificationNotice.response_document.create_by;
                crationDate = this.props.notificationNotice.response_document.creation_date_long;
            }
            return(
                <HistoryCard
                    key={this.props.notificationNotice.token}
                    title={'noticeChanges.efectiveDate'}
                    userCreator={createdBy}
                    creationDate={crationDate}
                    aditionalData={
                         <SendEffectiveDate {...this.props} 
                            inventory ={this.state.inventory}
                            measurementUnit = {this.state.inventoty}
                            effectiveDate = {this.state.effectiveDate}
                            onChangeRange = {this.onChangeRange}
                            updateProperty = {this.updateProperty}
                            onChangeMeasurementUnit ={this.onChangeMeasurementUnit}
                        />
                    }
                />
            )
        }
        else return("");
    }

        /**
     * Render hystory section for all documents
     *
     * @returns
     * @memberof Show
     */
    renderHistory() {
        let documents = this.props.notificationNotice ? this.props.notificationNotice.documents ? this.props.notificationNotice.documents : [] : []
        return (
            documents.map(document => {
                let titleId;
                let aditionalData;
                if (document.document_type === WORKFLOW_CANCELLATION) {
                    titleId = 'common.folioCancel';
                    aditionalData = <WorkflowCancel document={document} />;
                }
                return (
                    <HistoryCard
                        key={document.token}
                        title={titleId}
                        userCreator={document.create_by}
                        creationDate={document.creation_date_long}
                        aditionalData={aditionalData}
                    />
                )

            })
        )
    }

    /**
     * Render sample request
     */
    render() {
        let optonsAvalinable = this.props.notificationNotice ? this.props.notificationNotice.get_available_operations : [];
        return (
            <Card>
                {optonsAvalinable.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

                <LayoutHeaderWorkFlow {...this.props}
                    workFlow={this.props.notificationNotice}
                    title={<Translate id="noticeChanges.noticeOfChange"/>}
                />
                <Divider />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {this.renderSendRequest()}

                {this.renderHistory()}

                 <DialogAvailableOperations
                    {...this.props}
                    options={optonsAvalinable} 
                    isValidToCancel={CaBeCancelQuote(this.props.userRolesByCompany)}
                    openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
                    setOpenDialog={this.setOpenDialog}
                    onRespondNoticeOfChangeBySupplier ={this.onRespondNoticeOfChangeBySupplier}
                    onCancelDocument={this.onCancelDocument}
                />

                <Toaster 
                    message = {this.state.toasterMessage}
                    open = {this.state.showToaster}
                    variant = {this.state.toasterVariant}
                    onClose = {() => {this.setState({ showToaster:false})}}
                /> 

            </Card>
        )
    }
}


/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let selectedCompany = state.profile.get("selectedCompany");
    let notificationNotice = state.noticechanges.get("notificationNotice");
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        isLoadingUser: state.oidc.isLoadingUser,
        currentUser: user,
        isLoadingNotification: state.noticechanges.get('isLoadingNotification'),
        notificationNotice: notificationNotice ? notificationNotice.toJS() : null,
        isSaving: state.noticechanges.get("isSaving"),
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        company: selectedCompany ? selectedCompany.toJS() : null,
        advanceOptionsSupplier: state.suppliers.get('advanceOptionsSupplier') ? state.suppliers.get('advanceOptionsSupplier').toJS() : null,
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadNotificationNotice: (token) => {
            return dispatch(loadNotificationNoticeFromBackEnd(token));
        },
        loadSupplier: (token) => {
            return (dispatch(loadSupplier(token)))
        },
        loadAdvanceOptionsSupplier:(supplierCode) => {
            return dispatch(loadAdvanceOptionsSupplier(supplierCode));
        },
        respondNoticeOfChangeBySupplier:(token, effectiveDate, inventory, measurementUnit) => {
            return dispatch(respondNoticeOfChangeBySupplier(token, effectiveDate, inventory, measurementUnit))
        }
    }
}

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    actionsToolbar: {
        flexGrow: 1,
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    tableHead: {
        backgroundColor: "#fff",
        position: "sticky",
        zIndex: '30',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    },
    paper: {
        margin: `${theme.spacing.unit}px auto`,
        padding: theme.spacing.unit * 2,
      },
      root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: `0 ${theme.spacing.unit * 3}px`,
      },
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ViewNotification))));