import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Material-UI imports section */
import { Typography, Tooltip, Avatar, Card, CardHeader, CardContent, Fab } from '@material-ui/core'
import DestinationIcon from '@material-ui/icons/AirportShuttle'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/Edit'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import DataTable from '../../common/DataTable'
import LoadingPage from '../../common/LoadingPage'
import SearchInput from '../../common/SearchInput/SearchInput'
import AddCommonItem from '../../common/AddCommonItem'
import AddDestinationDialog from './AddDestinationDialog'
import Toaster from '../../common/Toaster'

/** Import section actions */
import {
	loadDestinationsFromBackEnd,
	getDestinationsCount,
	CHANGE_DESTINATIONS_SORT_CRITERIA,
	CHANGE_DESTINATIONS_QUERY,
	CHANGE_DESTINATIONS_PAGINATION,
	addTransportDestination,
	removeTransportDestination,
	updateDestinationData,
} from '../../../store/planning/TransportDestinationAction'
import { showWarningToaster, showSuccessToaster } from '../../../store/helpers/ToasterHelpers'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'

/**
 * Transport destinations view
 *
 * @class destinations
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance of Transport destinations
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadDestinations(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getDestinationsCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadDestinations()
			this.props.getDestinationsCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadDestinations(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		if (dataSource === 'is_active') {
			return (
				<div style={{ width: '35px' }}>
					<DestinationIcon color='secondary' />
				</div>
			)
		}
		if (dataSource === 'destination') {
			return <div style={{ width: '280px' }}>{item.destination}</div>
		}
		if (dataSource === 'kilometres') {
			return (
				<>
					{item.kilometresValue} {' km '}
				</>
			)
		}
		if (dataSource === 'token') {
			return (
				<>
					<Tooltip title={<Translate id='deliveryOrderModule.carbonFootprint.editDestination' />}>
						<EditIcon
							onClick={() => {
								this.onEditItem(item)
							}}
							className={classes.iconOption}
							color='primary'
						/>
					</Tooltip>
					<Tooltip title={<Translate id='deliveryOrderModule.carbonFootprint.removeDestination' />}>
						<DeleteOutlinedIcon
							onClick={() => {
								this.onRemoveItem(item.token)
							}}
							className={classes.iconOption}
							color='error'
						/>
					</Tooltip>
				</>
			)
		}
	}

	/**
	 * onRemoveItem
	 */
	onRemoveItem(itemId) {
		this.setState({
			openRemoveItem: true,
			itemId: itemId,
		})
	}

	/**
	 * OnEdit item
	 * @param {*} item
	 */
	onEditItem(item) {
		this.setState({
			openEditItem: true,
			itemEdit: item,
			itemEditId: item.token,
		})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingDestinations === true) {
			return <LoadingPage />
		} else if (this.props.destinations === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.destinations}
					configuration={TableConfiguration()}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					showFilters
					isIndex
					totalRows={this.props.destinationsCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get destinations counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		if (this.props.destinations) {
			let totaldestinations = this.props.destinations.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totaldestinations}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.destinationsCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Handle state of any Dialog to show or close
	 *
	 * @param {*} dialogId
	 * @memberof Destination Add or update
	 */
	handleOpenDialog(dialogId) {
		this.setState({ [dialogId]: !this.state[dialogId] })
	}

	/**
	 * Action to add new destination
	 * @param {*} destination
	 */
	onAddDestination(destination) {
		return this.props
			.addTransportDestination(destination)
			.then(() => {
				this.setState(showSuccessToaster('common.addCorrectly'))
				this.handleOpenDialog('showAddDestinationDialog')
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showWarningToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
				return Promise.reject()
			})
	}

	/**
	 * onRemoveDestination
	 */
	onRemoveDestination() {
		this.props
			.removeTransportDestination(this.state.itemId)
			.then(() => {
				this.setState({
					openRemoveItem: false,
					itemId: '',
				})
			})
			.catch((error) => {
				this.setState(showWarningToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
				return Promise.reject()
			})
	}

	/**
	 * Action to save information
	 * @param {*} destination
	 */
	onSaveEditDestination(destination) {
		return this.props
			.updateDestinationData(destination)
			.then(() => {
				this.setState(showSuccessToaster('common.saveCorrectly'))
				this.setState({
					openEditItem: false,
					itemEdit: null,
					itemEditId: '',
				})
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showWarningToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
				return Promise.reject()
			})
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof destinations
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<DestinationIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>

							<Tooltip title={<Translate id='deliveryOrderModule.carbonFootprint.addDestination' />}>
								<Fab
									size='small'
									color='primary'
									onClick={() => this.handleOpenDialog('showAddDestinationDialog')}
								>
									<AddtIcon />
								</Fab>
							</Tooltip>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='deliveryOrderModule.carbonFootprint.transportDestination' />{' '}
						</Typography>
					}
					subheader={this.getCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to add destination */}
				<AddDestinationDialog
					classes={this.props.classes}
					itemEdit={null}
					isSaving={this.props.isSavingDestination}
					onSaveItem={this.onAddDestination}
					onClose={() => this.handleOpenDialog('showAddDestinationDialog')}
					open={this.state.showAddDestinationDialog}
				/>

				{/** Dialog to remove destination */}
				<AddCommonItem
					title={<Translate id='deliveryOrderModule.carbonFootprint.removeDestination' />}
					message={<Translate id='deliveryOrderModule.carbonFootprint.messageToRemoveDestination' />}
					open={this.state.openRemoveItem}
					onClose={() => {
						this.setState({ openRemoveItem: false })
					}}
					onSaveItem={this.onRemoveDestination}
					isSaving={this.props.isSavingDestination}
					isMessage={true}
				/>

				{/** Dialog to edit destination */}
				<AddDestinationDialog
					classes={this.props.classes}
					itemEdit={this.state.itemEdit ? this.state.itemEdit : null}
					isSaving={this.props.isSavingDestination}
					onSaveItem={this.onSaveEditDestination}
					open={this.state.openEditItem}
					onClose={() => {
						this.setState({ openEditItem: false })
					}}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableConfiguration() {
	let columns = [
		{
			header: '',
			dataSource: 'is_active',
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='deliveryOrderModule.carbonFootprint.destination' />,
			dataSource: 'destination',
			isSortable: true,
		},
		{
			header: <Translate id='deliveryOrderModule.carbonFootprint.kilometres' />,
			dataSource: 'kilometres',
		},
		{
			header: '',
			dataSource: 'token',
		},
	]

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	return {
		user: user,
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingDestinations: state.transportdestinations.get('isLoadingDestinations'),
		destinations: state.transportdestinations.get('destinations')
			? state.transportdestinations.get('destinations').toJS()
			: null,
		sortCriteria: state.transportdestinations.get('sortCriteria')
			? state.transportdestinations.get('sortCriteria').toJS()
			: null,
		searchQuery: state.transportdestinations.get('searchQuery'),
		page: state.transportdestinations.get('page'),
		rowsPerPage: state.transportdestinations.get('rowsPerPage'),
		destinationsCount: state.transportdestinations.get('destinationsCount'),
		isSavingDestination: state.transportdestinations.get('isSavingDestination'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadDestinations: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadDestinationsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_DESTINATIONS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_DESTINATIONS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_DESTINATIONS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getDestinationsCount: () => {
			dispatch(getDestinationsCount())
		},
		addTransportDestination: (destination) => {
			return dispatch(addTransportDestination(destination))
		},
		removeTransportDestination: (destinationToken) => {
			return dispatch(removeTransportDestination(destinationToken))
		},
		updateDestinationData: (destination) => {
			return dispatch(updateDestinationData(destination))
		},
	}
}

const styles = () => ({
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	iconOption: {
		marginRight: '25px',
		zIndex: '990',
		cursor: 'pointer',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
