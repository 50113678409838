import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';
import { DialogActions, Button, Grid, TextField, Typography } from '@material-ui/core';
import SuppliersSelector from '../../common/SupplierSelector';
import PreparedBySelector from '../../common/PreparedBySelector';
import DateTimePicker from '../../common/DateTimePicker/DatePicker'
import moment from 'moment'
import { GetAreas, GetCecos } from '../../../store/helpers/SelectOptions';
import Select from 'react-select'
import PartNumberSelector from '../../common/PartNumberSelector';
import Toaster from '../../common/Toaster';

/**
 * Class component of form to create rework
 *
 * @class FormView
 * @extends {Component}
 */
class FormView extends Component {
    constructor(props) {
        super(props)
        autoBind(this);
        this.state = {
            startDate: moment(),
            preparedBySelected: null,
            supplierSelected: null,
            partNumber: null,
            area: "",
            activity_description_to_be_performed: "",
            delivery_date: "",
            quantity_of_material: "",
            sorter: null,
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
        };
    };

    /**
     * Update property in state
     *
     * @param {*} property
     * @param {*} value
     * @memberof FormView
     */
    updateProperty(property, value) {
        this.setState({
            [property]: value
        })
    }

    /**
     * On seleted part number
     */
    onSelected(propertyId, value) {
        this.setState({ [propertyId]: value });
    }

    /**
    * On change date arriveDate
    * @param {*} arriveDate 
    */
    onChangeRange(deliveryDate) {
        this.setState({
            delivery_date: deliveryDate,
        })
    }

    /**
     * call to action to save data and create rework
     *
     * @memberof FormView
     */
    onSaveRework() {
        if (this.props.createRework)
            this.props.createRework(this.state)
                .then(response => {
                    this.props.history.push(`/reworks/${response.token}`)
                }).catch(() => {
                    this.setState({
                        showToaster: true,
                        toasterMessage: <Translate id="common.errorToSave" />,
                        toasterVariant: "error",
                    })
                });
    }
    
    /**
     * Render Textfield disabled to edit
     *
     * @param {*} textFieldId
     * @param {*} translationTextFieldId
     * @param {boolean} [isMultiple=false]
     * @returns
     * @memberof FormView
     */
    renderTextFieldDisabled(textFieldId, translationTextFieldId, isMultiple = false) {
        let listIds = textFieldId.split('.');
        let value = '';
        if (listIds.length > 0) {
            value = this.state[listIds[0]] ? this.state[listIds[0]][listIds[1]] : '';
        }
        else {
            value = this.state[textFieldId];
        }
        return (
            <div >
                <TextField
                    id={textFieldId}
                    fullWidth
                    margin="normal"
                    value={value}
                    label={<span><Translate id={translationTextFieldId} /> *</span>}
                    onChange={(event) => { this.updateProperty(textFieldId, event.target.value) }}
                    className={isMultiple ? this.props.classes.textAreaField : this.props.classes.textField}
                    //Only textArea
                    multiline={isMultiple}
                    rows={isMultiple ? "4" : "1"}
                    variant={isMultiple ? "outlined" : 'standard'}
                    disabled
                />
            </div>
        )
    }

    /**
     * Render textFiel Enabled to edit
     *
     * @param {*} textFieldId
     * @param {*} translationTextFieldId
     * @param {boolean} [isMultiple=false]
     * @param {string} [type='text']
     * @returns
     * @memberof FormView
     */
    renderTextField(textFieldId, translationTextFieldId, isMultiple = false, type = 'text') {
        return (
            <div >
                <TextField
                    id={textFieldId}
                    fullWidth
                    margin="normal"
                    value={this.state[textFieldId] ? this.state[textFieldId] : ''}
                    label={<span><Translate id={translationTextFieldId} /> *</span>}
                    onChange={(event) => { this.updateProperty(textFieldId, event.target.value) }}
                    className={isMultiple ? this.props.classes.textAreaField : this.props.classes.textField}
                    type={type}
                    //Only textArea
                    multiline={isMultiple}
                    rows={isMultiple ? "4" : "1"}
                    variant={isMultiple ? "outlined" : 'standard'}
                />
            </div>
        )
    }

    /**
     * Method to validate required data is filled
     *
     * @returns
     * @memberof FormView
     */
    canBeSaved() {
        if(this.state.preparedBySelected === null) return false;
        if(this.state.supplierSelected === null) return false;
        if(this.state.partNumber === null) return false;
        if(this.state.area === null) return false;
        if(this.state.activity_description_to_be_performed === "") return false;
        if(this.state.quantity_of_material === "") return false;
        if(!this.state.delivery_date) return false;
         if(this.state.sorter === null) return false;
        return true;
    }

    render() {
        const { classes } = this.props;
        let optionsAreas = GetAreas();
        let optionsCecos = GetCecos();
        return (
            <Grid container spacing={24} style={{ paddingLeft: '80px', paddingRight: "100px", }}>
                <Grid item xs={6} >
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="common.preparedBy"/> *</Typography>

                        <PreparedBySelector
                            className={classes.textField}
                            onUsersQualitySelected={(preparedBy) => { this.onSelected('preparedBySelected', preparedBy) }}
                        />
                        <br />
                    </div>

                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="common.supplier"/> *</Typography>

                        <SuppliersSelector
                            className={classes.textField}
                            onSupplierSelected={(supplier) => { this.onSelected('supplierSelected', supplier) }}
                        />
                        <br />
                    </div>
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="common.area"/> *</Typography>
                        <Select
                            defaultValue={optionsAreas['area']}
                            options={optionsAreas}
                            fullWidth
                            onChange={(event) => this.updateProperty("area", event.value)}
                            styles={{
                                menu: (base) => ({ ...base, zIndex: 10 })
                            }}
                        />
                        <br />

                    </div>
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="common.ceco"/> *</Typography>
                        <Select
                            defaultValue={optionsCecos['cost_center']}
                            options={optionsCecos}
                            fullWidth
                            onChange={(event) => this.updateProperty("cost_center", event.value)}
                            styles={{
                                menu: (base) => ({ ...base, zIndex: 10 })
                            }}
                        />
                        <br />
                    </div>
                    {this.renderTextField('activity_description_to_be_performed', 'reworks.descriptionRework', true)}
                    <br />
                    <br />
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.textField} >
                        <Typography variant="subtitle1" gutterBottom><Translate id="common.partNumber"/> *</Typography>
                        <PartNumberSelector
                            className={classes.textField}
                            onPartNumberSelected={(partNumber) => { this.onSelected('partNumber', partNumber) }}
                        />
                    </div>
                    {this.renderTextFieldDisabled('partNumber.description', 'reworks.materialDescription')}
                    {this.renderTextFieldDisabled('partNumber.measurementUnit', 'reworks.measurementUnit')}
                    {this.renderTextField('quantity_of_material', 'reworks.quantityMaterialRework', false, 'number')}
                    <div className={classes.textField} ><br />
                        <Typography variant="subtitle1" gutterBottom><Translate id="reworks.dateRework"/> *</Typography>
                        <DateTimePicker
                            onChangeRange={this.onChangeRange}
                            minDate={moment()}
                            startDate={this.state.delivery_date}
                        />
                    </div>
                    <div className={classes.textField} ><br />
                        <Typography variant="subtitle1" gutterBottom><Translate id="reworks.sorter"/> *</Typography>
                        <SuppliersSelector
                            className={classes.textField}
                            onSupplierSelected={(sorter) => { this.onSelected('sorter', sorter) }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} >
                    <DialogActions>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            onClick={() => this.onSaveRework()}
                            disabled={!this.canBeSaved() || this.props.isSaving}
                        >
                            {<Translate id="common.save" />}
                        </Button>
                        <Button onClick={() => { this.props.history.push(`/reworks`) }} color="secondary">
                            {<Translate id="common.cancel" />}
                        </Button>
                    </DialogActions>
                </Grid>
                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Grid>
        )
    }
}


const viewStyles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textAreaField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    footerForm: {
        display: "flex",
        paddingRight: "60px",
    },
    formControl: { margin: theme.spacing.unit },
    toolbar: {},
    searchInput: {},
    button: {},
    actionsToolbar: {},
    showingUsersCounter: {},
    grow: {},
    supplierBadge: {}
});

export default withRouter(withStyles(viewStyles, { withTheme: true })(FormView));