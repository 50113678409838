
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import {Translate} from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

/**
 * Component ot render Information partialraw material not conformiti
 */
export default class InformationPartial extends Component{

    /**
     * Create an instance RawMaterial not conformity
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
    }

    /**
     * Render
     */
    render() {
        const { classes} = this.props;
        return (
            <Paper> <Divider />
                <List>
                    <ListItem>
                        <ListItemText
                           secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="common.supplier"/>
                                </Typography>
                                {": "}{ this.props.warrantyReport.full_supplier}
                            </React.Fragment>
                        } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <Translate id="common.preparedBy" />
                                    </Typography>
                                    {": "}{this.props.warrantyReport.create_by}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                         secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                    <Translate id="warrantyReportsPtm.motiveWarranty" />
                                </Typography>
                                {": "}{this.props.warrantyReport.description}
                            </React.Fragment>
                        }/>
                    </ListItem>

                    <Divider />
                </List>

            </Paper>
        );
    }
}

/**
 * warrantyReport
 */
InformationPartial.propTypes = {
    warrantyReport: PropTypes.object.isRequired
}