/**Import react section */
import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
/**Import MaterialUi section */
import FileIcon from '@material-ui/icons/AttachFile';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import {  Grid, Typography, ListItemSecondaryAction, Avatar } from '@material-ui/core';
import { ListItemAvatar, List, ListItemText, ListItem, IconButton } from '@material-ui/core';
import LinkMaterial from '@material-ui/core/Link';
/**Import components section */
import Evidence from '../../../common/Evidence';

/**Import store section */

/**custom hooks */
import { useExpedientSupplierCustomHookIndex } from '../../customHooks/UseCustomHookIndex';

const AddAttachment = (props) => {
	const { classes, translate, item } = props;
	const [attachments, setAttachments] = useState(item.attachments ? item.attachments : []);

	const { onRemoveFileInAttachment, onAddEvidenceInAttachment, isSaving } =
		useExpedientSupplierCustomHookIndex();

	/**
	 * On upload file in maquila order in list name
	 * @param {*} files
	 * @param {*} maquilaToken
	 * @param {*} listName
	 */
	const uploadFiles = (files) => {
		let filesList = attachments.concat(files[0]);
		if (files.length > 0) {
			for (var index = 1; index < files.length; index++) {
				filesList.push(files[index]);
			}
			return onAddEvidenceInAttachment(item.token, files)
				.then((newListAttachments) => {
					setAttachments(newListAttachments);
					return Promise.resolve('Ok');
				})
				.catch(() => {
					return Promise.reject();
				});
		}
		return Promise.resolve('Ok');
	};

	/**
	 * Handle event remove files of evidence componet to remove files of list
	 *
	 * @param {*} fileName
	 * @returns
	 */
	const removeFiles = (fileName) => {
		let newList = attachments.filter((files) => {
			return files.name !== fileName;
		});
		return onRemoveFileInAttachment(item.token, fileName)
			.then(() => {
				setAttachments(newList);
				return Promise.resolve('Ok');
			})
			.catch(() => {
				return Promise.reject();
			});
	};

	const onGetAccepFiles = () => {
		switch (item.FileType) {
			case 'PDF':
				return ['.pdf'];
			case 'EXCEL':
				return ['.xlsx'];
			case 'ZIP':
				return ['.zip'];
			case 'WORK':
				return ['.docx'];
			case 'IMAGEN':
				return ['images'];
			case 'PDF_O_IMAGEN':
				return ['.pdf', 'images'];
			default:
				return [];
		}
	};

	return (
		<div>
			<div className={classes.containerInformation}>
				<Grid container>
					<Grid item xs={12}>
						<Typography color="primary">Agrega los archivos</Typography>
						<div className={classes.marginTop}>
							<Evidence
								text={translate('common.selectfiles')}
								uploadFiles={uploadFiles}
								isDisabled={false}
								maxFiles={1}
								files={[]}
								acceptedFiles={onGetAccepFiles()}
							/>
						</div>
						{attachments.length === 0 && (
							<Typography className={classes.textTitle}>
								<Translate id="common.noFiles" />
							</Typography>
						)}
						<List>
							{attachments.map((evidence, index) => {
								return (
									<ListItem key={index} divider>
										<ListItemAvatar>
											<Avatar className={classes.avatar} size="small">
												<FileIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={
												<Typography color="primary" className={classes.fileName}>
													  <LinkMaterial  target="_blank"
													  href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${evidence.relativePath}`}
													  >
														{evidence.name}
													  </LinkMaterial>
												</Typography>
											}
											style={{ cursor: 'pointer' }}
										/>
										{
											<ListItemSecondaryAction>
												<IconButton edge="end" onClick={() => removeFiles(evidence.name)} disabled={isSaving}>
													<DeleteIcon />
												</IconButton>
											</ListItemSecondaryAction>
										}
									</ListItem>
								);
							})}
						</List>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${165}px)`,
		minHeight: `calc(100vh - ${165}px)`,
		overflowY: 'auto',
		padding: '10px'
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center'
	},
	inputProps: {
		height: 6
	},
	marginTop: {
		marginTop: '10px'
	},
	marginLeft: {
		marginLeft: '6px'
	},
	widthBtnSaved: {
		minWidth: '400px'
	}
});

AddAttachment.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired
};

AddAttachment.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false
};

export default withRouter(withStyles(styles, { withTheme: true })(AddAttachment));
