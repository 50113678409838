import { fromJS } from 'immutable';

import * as biddingsActions from './BiddingsActions'

/**
 * contraActions initial state
 */
export const biddingsInitialState = fromJS({
    isLoading: true,
    identifier: "",
    isSaving: false,
    biddings: [],
    bidding: null,
    biddingsCount: 0,
    isDownloadingReport: false,
    categories: [],
    isPublicationEnabled: false,
    measurementunits: [],
    isSentProposalEnabled: false,
    isCalculateEnabled: false,
    isPublicationResultsEnabled:false,
    step: 1,
    divisas: [],
    incoterms: []
})

export default function companiesReducer(state = biddingsInitialState, action) {
    switch (action.type) {
        case biddingsActions.START_LOADING_BIDDINGS: {
            return state.merge({
                isLoading: action.isLoading,
                identifier: action.identifier,
            })
        }
        case biddingsActions.UPDATE_PUBLISH_FLAG_ENABLED: {
            return state.merge({
                isPublicationEnabled: action.isEnabled,
                isSaving: false
            })
        }
        case biddingsActions.UPDATE_PUBLISH_RESULTS_FLAG_ENABLED:{
            return state.merge({
                isPublicationResultsEnabled: action.isEnabled,
                isSaving: false
            })
        }
        case biddingsActions.UPDATE_CALCULATION_FLAG_ENABLED: {
            return state.merge({
                isCalculateEnabled: action.isEnabled,
                isSaving: false
            })
        }
        case biddingsActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving,
                identifier: action.identifier,
            })
        }
        case biddingsActions.IS_DOWNLOAD_REPORT_BIDDINGS: {
            return state.merge({
                isDownloading: action.isDownloading,
            })
        }
        case biddingsActions.SET_BIDDINGS_LIST: {
            return state.merge({
                biddings: fromJS(action.biddings),
                isLoading: false,
                identifier: ""
            })
        }
        case biddingsActions.ADD_BIDDING: {
            let data = fromJS(action.bidding);
            return state.updateIn(['biddings'], allBiddings => pushBidding(allBiddings, data)).set("isSaving", false)
        }
        case biddingsActions.SET_BIDDING: {
            return state.merge({
                isLoading: false,
                identifier: "",
                bidding: action.bidding
            })
        }
        case biddingsActions.UPDATE_BIDDING: {
            return state.update('biddings', allBiddings => updateBidding(allBiddings, fromJS(action.bidding))).set('isSaving', false);
        }
        case biddingsActions.SET_BIDDINGS_COUNT: {
            return state.merge({
                biddingsCount: action.count
            })
        }
        case biddingsActions.ADD_BIDDING_BASE: {
            return state.updateIn(['bidding', 'Sections'], allBiddings => addBiddingBase(allBiddings, fromJS(action.bidding))).set('isSaving', false);
        }
        case biddingsActions.DELETE_BIDDING_SECTION: {
            return state.updateIn(['bidding', 'Sections'], allSections => allSections.filter(section => section.get('Id') !== action.id)).set('isSaving', false);
        }
        case biddingsActions.ADD_SUPPLIER: {
            return state.updateIn(['bidding', 'Suppliers'], allSuppliers => addSupplier(allSuppliers, fromJS(action.supplier))).set('isSaving', false);
        }
        case biddingsActions.UPDATE_FINAL_COMMENTS_IN_SUPPLIER:{
            return state.updateIn(['bidding', 'Suppliers'], allSuppliers => updateSupplier(allSuppliers, action.supplierCode, fromJS(action.comments))).set('isSaving', false);
        }
        case biddingsActions.DELETE_SUPPLIER: {
            return state.updateIn(['bidding', 'Suppliers'], allSuppliers => allSuppliers.filter(supplier => supplier.get('Id') !== action.id)).set('isSaving', false);
        }
        case biddingsActions.DELETE_BIDDING: {
            return state.updateIn(['biddings'], allBiddings => allBiddings.filter(bidding => bidding.get('Id') !== action.id)).set('isSaving', false);
        }
        case biddingsActions.ADD_SERVICE_OR_MATERIAL: {
            return state.updateIn(['bidding', 'Items'], allItems => addItem(allItems, fromJS(action.item))).set('isSaving', false);
        }
        case biddingsActions.ADD_EVALUATION_CRITERIA: {
            return state.updateIn(['bidding', 'Criterias'], allCriterias => updateCriteria(allCriterias, fromJS(action.evaluationCriteria))).set('isSaving', false);
        }
        case biddingsActions.DELETE_EVALUATION_CRITERIA: {
            return state.updateIn(['bidding', 'Criterias'], allCriterias => allCriterias.filter(item => item.get('Id') !== action.id)).set('isSaving', false);
        }
        case biddingsActions.DELETE_SERVICE_OR_MATERIAL: {
            return state.updateIn(['bidding', 'Items'], allItems => allItems.filter(item => item.get('Id') !== action.id)).set('isSaving', false);
        }
        case biddingsActions.UPDATE_PROPERTY_BIDDING: {
            return state.setIn(['bidding', action.propertyName], action.propertyValue).set('isSaving', false);
        }
        case biddingsActions.UPDATE_DIVISA:{
            return state.setIn(['bidding', 'Divisa'], fromJS(action.divisa)).set('isSaving', false)
        }
        case biddingsActions.UPDATE_PROPERTY_EVALUATION_CRITERIA: {
            return state.updateIn(['bidding', 'Items'], allItems => updateItemEvaluationCriterialProperty(allItems, action.itemId, action.evaluationId, action.propertyName, action.propertyValue)).set('isSaving', false);
        }
        case biddingsActions.UPDATE_PROPERTY_CRITERIA: {
            return state.updateIn(['bidding', 'Criterias'], allItems => updateCriterialProperty(allItems, action.criteriaId, action.propertyName, action.propertyValue)).set('isSaving', false);
        }
        case biddingsActions.UPDATE_PROPERTY_ITEM: {
            return state.updateIn(['bidding', 'Items'], allItems => updateItemProperty(allItems, action.itemId, action.propertyName, action.propertyValue)).set('isSaving', false);
        }
        case biddingsActions.SET_MEASUMENT_UNITS_BIDDINGS: {
            return state.merge({
                measurementunits: fromJS(action.units)
            })
        }
        case biddingsActions.UPDATE_IS_SENT_PROPOSAL_SUPPLIER: {
            return state.merge({
                isSentProposalEnabled: action.isEnabled,
                isSaving: false
            })
        }
        case biddingsActions.UPDATE_PROPERTY_EVALUATED_WEIGHING: {
            return state.updateIn(['bidding', 'ItemsResponses'], allItems => updateItemEvaluationCriterialPropertyService(allItems, action.itemPoposalId,
                action.supplierCode, action.evaluationId, action.propertyName, action.propertyValue)).set('isSaving', false);
        }
        case biddingsActions.UPDATE_PROPERTY_EVALUATED_WEIGHING_SCOPE_GLOBAL: {
            return state.updateIn(['bidding', 'CriteriaResponses'], allItems => updateItemEvaluationCriterialPropertyServiceScopeGlobal(allItems,
                action.supplierCode, action.evaluationId, action.propertyName, action.propertyValue)).set('isSaving', false);
        }
        case biddingsActions.UPDATE_STEP_EXTERNL_USER: {
            return state.merge({
                step: action.step,
            })
        }
        case biddingsActions.ADD_QUESTION: {
            return state.updateIn(['bidding', 'Questions'], allQuestions => addQuestion(allQuestions, fromJS(action.question))).set('isSaving', false);
        }
        case biddingsActions.ADD_ANSWER: {
            return state.updateIn(['bidding', 'Questions'], allQuestions => addAnswer(allQuestions, action.id, fromJS(action.answer))).set('isSaving', false);
        }
        case biddingsActions.UPDATE_SECTION_INDEX: {
            return state.updateIn(['bidding', 'Sections'], allSections => updateIndex(allSections, action.id, action.index)).set('isSaving', false);
        }
        case biddingsActions.UPDATE_CRITERIA_GROUP: {
            return state.updateIn(['bidding', 'Criterias'], allCriterias => updateCriterialGroup(allCriterias, action.currentCriteriaGroup, action.newCriteriaGroup)).set('isSaving', false);
        }
        case biddingsActions.ADD_EXCHANGE_RATES: {
            return state.setIn(['bidding', "ExchangeRates"], fromJS(action.exchangeRates)).set('isSaving', false);
        }
        case biddingsActions.ADD_WINNER_IN_ITEM_SUPPLIER: {
            return state.updateIn(['bidding', 'CriteriasResponses'], allItems => updateCriteriaResponseProperty(
                allItems, action.group, action.criteriaId, action.itemResponseId, action.supplierCode, action.propertyName, action.propertyValue)).set('isSaving', false);
        }
        /**Divisas reducer section */
        case biddingsActions.SET_DIVISAS_LIST: {
            return state.merge({
                divisas: fromJS(action.divisas),
                isSaving: false,
                identifier: ""
            })
        }
        /**Incoterms reducer section */
        case biddingsActions.SET_INCOTERMS: {
            return state.merge({
                incoterms: fromJS(action.incoterms),
                isSaving: false,
                identifier: ""
            })
        }
        default:
            return state;
    }
}

const pushBidding = (allBiddings, newBidding) => {
    let indexOfBidding = allBiddings.findIndex(d => d.get('Id') === newBidding.get('Id'));
    if (indexOfBidding >= 0) {
        return allBiddings.setIn([indexOfBidding], newBidding);
    }
    return allBiddings.unshift(newBidding);
}

const updateBidding = (allBiddings, bidding) => {
    let indexOfBidding = allBiddings.findIndex(d => d.get('Id') === bidding.get('Id'));
    if (indexOfBidding >= 0) {
        return allBiddings.setIn([indexOfBidding], bidding);
    }
    return allBiddings
}

const addSupplier = (allSuppliers, supplier) => {
    let indexOfBidding = allSuppliers.findIndex(d => d.get('Id') === supplier.get('Id'));
    if (indexOfBidding >= 0) {
        return allSuppliers.setIn([indexOfBidding], supplier);
    }
    return allSuppliers.push(supplier)
}

const updateSupplier = (allSuppliers, supplierCode, comments) => {
    let indexOfBidding = allSuppliers.findIndex(d => d.get('Code') === supplierCode);
    if (indexOfBidding >= 0) {
        return allSuppliers.setIn([indexOfBidding, 'FinalComment'], comments);
    }
    return allSuppliers
}


const addItem = (allItems, item) => {
    let indexOfItem = allItems.findIndex(d => d.get('Id') === item.get('Id'));
    if (indexOfItem >= 0) {
        return allItems.setIn([indexOfItem], item);
    }
    return allItems.push(item)
}

const updateItemProperty = (allItems, itemId, propertyName, propertyValue) => {
    let indexOfItem = allItems.findIndex(d => d.get('Id') === itemId);
    if (indexOfItem >= 0) {
        return allItems.setIn([indexOfItem, propertyName], propertyValue);
    }
    return allItems
}

const updateItemEvaluationCriterialProperty = (allItems, itemId, evaluationId, propertyName, propertyValue) => {
    let indexOfItem = allItems.findIndex(d => d.get('Id') === itemId);
    if (indexOfItem >= 0) {
        let newItemUpdated = allItems.get(indexOfItem)
        if (newItemUpdated !== undefined) {
            let evaluationCriterias = newItemUpdated.get('Criterias')
            let indexOfEvaluationCriteria = evaluationCriterias.findIndex(d => d.get('Id') === evaluationId);
            if (indexOfEvaluationCriteria >= 0) {
                return allItems.setIn([indexOfItem, 'Criterias', indexOfEvaluationCriteria, propertyName], propertyValue);
            }
        }
    }
    return allItems
}

const updateItemEvaluationCriterialPropertyService = (allItems, itemPoposalId, supplierCode, evaluationId, propertyName, propertyValue) => {
    let indexOfItem = allItems.findIndex(d => d.get('Id') === itemPoposalId);
    if (indexOfItem >= 0) {
        let newItemUpdated = allItems.get(indexOfItem)
        if (newItemUpdated !== undefined) {
            let suppliersProposal = newItemUpdated.get('SuppliersProposal')
            let indexOfSupplier = suppliersProposal.findIndex(d => d.get('supplierCode') === supplierCode);
            if (indexOfSupplier >= 0) {
                let supplierUpdate = suppliersProposal.get(indexOfSupplier)
                if (supplierUpdate !== undefined) {
                    let allEvaluationsCriterias = supplierUpdate.get('criterias')
                    let indexOfEvaluationCriteria = allEvaluationsCriterias.findIndex(d => d.get('Id') === evaluationId);
                    if (indexOfEvaluationCriteria >= 0) {
                        return allItems.setIn([indexOfItem, 'SuppliersProposal', indexOfSupplier, 'criterias', indexOfEvaluationCriteria, propertyName], propertyValue);
                    }
                }
            }
        }
    }
    return allItems
}

const updateItemEvaluationCriterialPropertyServiceScopeGlobal = (allItems, supplierCode, evaluationId, propertyName, propertyValue) => {
    let indexOfItem = allItems.findIndex(d => d.get('Id') === evaluationId);
    if (indexOfItem >= 0) {
        let newItemUpdated = allItems.get(indexOfItem)
        if (newItemUpdated !== undefined) {
            let suppliersProposal = newItemUpdated.get('Responses')
            let indexOfSupplier = suppliersProposal.findIndex(d => d.get('supplierCode') === supplierCode);
            if (indexOfSupplier >= 0) {
                let supplierUpdate = suppliersProposal.get(indexOfSupplier)
                if (supplierUpdate !== undefined) {
                    return allItems.setIn([indexOfItem, 'Responses', indexOfSupplier, propertyName], propertyValue);
                }
            }
        }
    }
    return allItems
}

const updateCriteriaResponseProperty = (allItems, group, criteriaId, itemResponseId, supplierCode, propertyName, propertyValue) => {
    let indexOfGroup = allItems.findIndex(d => d.get('group') === group);
    if (indexOfGroup >= 0) {
        let newGroupUpdate = allItems.get(indexOfGroup)
        if (newGroupUpdate !== undefined) {
            let criteriasResponses = newGroupUpdate.get('criterias')
            let indexOfCriteria = criteriasResponses.findIndex(d => d.get('Id') === criteriaId);
            if (indexOfCriteria >= 0) {
                let criteria = criteriasResponses.get(indexOfCriteria)
                if (criteria !== undefined) {
                    let itemsResponses = criteria.get('ItemResponses')
                    let indexOfItemResponse = itemsResponses.findIndex(d => d.get('Id') === itemResponseId);
                    if (indexOfItemResponse >= 0) {
                        let itemsResponse = itemsResponses.get(indexOfItemResponse)
                        if (itemsResponse !== undefined) {
                            let supplierProposals = itemsResponse.get('SupplierProposal')
                            let indexOfSupplier = supplierProposals.findIndex(d => d.get('SupplierCode') === supplierCode);
                            if (indexOfSupplier >= 0) {
                                let supplier = supplierProposals.get(indexOfSupplier)
                                if (supplier !== undefined) {
                                    return allItems.setIn([indexOfGroup,'criterias', indexOfCriteria, 'ItemResponses', indexOfItemResponse, 'SupplierProposal', indexOfSupplier, propertyName],propertyValue)
                                }
                            }
                        }
                    }

                }
            }
        }
    }
    return allItems
}
/**
 * Section for function bidding questions and answers 
 */
const addQuestion = (allQuestion, newQuestion) => {
    let indexOfQuestion = allQuestion.findIndex(d => d.get('Id') === newQuestion.get('Id'));
    if (indexOfQuestion >= 0) {
        return allQuestion
    }
    return allQuestion.unshift(newQuestion);
}

const addAnswer = (allQuestion, id, newAnswer) => {
    let indexOfQuestion = allQuestion.findIndex(d => d.get('Id') === id);
    if (indexOfQuestion >= 0) {
        let question = allQuestion.get(indexOfQuestion);
        let updatedActionsList = question.get('Answers').unshift(newAnswer);
        return allQuestion.setIn([indexOfQuestion, 'Answers'], updatedActionsList);

    }
    return allQuestion.push(newAnswer);
}

/**
 * Section for function bidding sections 
 */
const updateIndex = (allSections, id, index) => {
    let orderSection = []
    allSections.toJS().forEach((section) => {
        if (section.Id === id) {
            section.Index = index
            orderSection.push(section)
        }
        else if (section.Index >= index) {
            section.Index = section.Index + 1
            orderSection.push(section)
        }
        else
            orderSection.push(section)
    });
    return fromJS(orderSection)
}

const addBiddingBase = (allBiddingsBases, biddingBase) => {
    let indexOfBidding = allBiddingsBases.findIndex(d => d.get('Id') === biddingBase.get('Id'));
    if (indexOfBidding >= 0) {
        return allBiddingsBases.setIn([indexOfBidding], biddingBase);
    }
    return allBiddingsBases.push(biddingBase)
}


/**
 * Section for function Criterias bidding 
 */
const updateCriterialGroup = (allCriterias, currentCriteriaGroup, newCriteriaGroup) => {
    let updateGroups = []
    allCriterias.toJS().forEach((criteria) => {
        if (criteria.Group === currentCriteriaGroup) {
            criteria.Group = newCriteriaGroup
            updateGroups.push(criteria)
        }
        else
            updateGroups.push(criteria)
    });
    return fromJS(updateGroups)
}

const updateCriterialProperty = (allCriterias, criteriaId, propertyName, propertyValue) => {
    let indexOfItem = allCriterias.findIndex(d => d.get('Id') === criteriaId);
    if (indexOfItem >= 0) {
        return allCriterias.setIn([indexOfItem, propertyName], propertyValue);
    }
    return allCriterias
}

const updateCriteria = (allCriterias, evaluationCriteria) => {
    let indexOfItem = allCriterias.findIndex(d => d.get('Id') === evaluationCriteria.get('Id'));
    if (indexOfItem >= 0) {
        return allCriterias.setIn([indexOfItem], evaluationCriteria);
    }
    return allCriterias.push(evaluationCriteria)
}