/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';

export const START_LOADING_RELEASE_PLAN = "START_LOADING_RELEASE_PLAN";
export const SET_RELEASE_PLAN = "SET_RELEASE_PLAN";
export const START_LOADING_MATERIALS = "START_LOADING_MATERIALS";
export const SET_MATERIALS_RELEASE = "SET_MATERIALS_RELEASE";
export const IS_SAVING_MATERIAL = "IS_SAVING_MATERIAL";
export const IS_UPDATING_RELEASE_PLAN = "IS_UPDATING_RELEASE_PLAN";

/**
 * Action start loading release plan supplier
 * @param {*} isLoading 
 */
function isLoadingReleasePlan(isLoading) {
    return {
        type: START_LOADING_RELEASE_PLAN,
        isLoading: isLoading
    }
}

/**
 * Action to set release plan
 * @param {*} releasePlan 
 */
function setRelesePlan(releasePlan) {
    return {
        type: SET_RELEASE_PLAN,
        releasePlan: releasePlan
    }
}

/**
 * Action to loading materials
 * @param {*} isLoading 
 */
function isLoadingMaterials(isLoading){
    return {
        type: START_LOADING_MATERIALS,
        isLoading
    }
}

/**
 * Action to set list materials from release
 * @param {*} materials 
 */
function setMaterialsRelease(materials){
    return{
        type:SET_MATERIALS_RELEASE,
        materials
    }
}

/**
 * Action is saving material
 * @param {*} isLoading 
 */
function isSavingMaterial(isSaving) {
    return {
        type: IS_SAVING_MATERIAL,
        isSaving: isSaving
    }
}

/**
 * Action start updating all release plan from supplier manually
 * @param {*} isLoading 
 */
function isUploadingReleasePlanMnually(isLoading) {
    return {
        type: IS_UPDATING_RELEASE_PLAN,
        isUpdating: isLoading
    }
}

/**
 * Get and set the release plna supplier in the estate from read from backend
 * @param {*} token 
 */
export function loadReleasePlanFromBackEnd(supplierConde) {
    return function (dispatch, getState) {
        dispatch(isLoadingReleasePlan(true))

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/releaseplan/${selectedCompany.get('token')}/${supplierConde}`;
        if(selectedCompany){
            return axios.get(endPoint, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setRelesePlan(serverResponse.data))
                        dispatch(isLoadingReleasePlan(false))
                    }
                    dispatch(isLoadingReleasePlan(false))
                })
                .catch((error) => {
                    console.error("Error to load release plan", error.response)
                    dispatch(isLoadingReleasePlan(false))
                    return Promise.reject();
                })
            }
        }
}

/**
 * Action to set list materials from release from remove 
 * @param {*} supplierCode 
 */
export function loadMaterialsReleaseFromRemove(supplierCode){
    return function (dispatch, getState) {
        dispatch(isLoadingMaterials(true))

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/releaseplan/release/${selectedCompany.get('token')}/${supplierCode}`;
        if(selectedCompany){
            return axios.get(endPoint, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setMaterialsRelease(serverResponse.data))
                        dispatch(isLoadingMaterials(false))
                    }
                })
                .catch((error) => {
                    console.error("Error to load release plan", error.response)
                    dispatch(isLoadingMaterials(false))
                    return Promise.reject();
                })
            }
        }
}

/**
 * Action to add material from release 
 * @param {*} material 
 * @param {*} supplierName 
 */
export function addMaterialToReleasePlan(material) {
    return (dispatch, getState) => {

        dispatch(isSavingMaterial(true));
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let selectedCompany = getState().profile.get("selectedCompany");
        let supplier = getState().suppliers.get("supplier");

        let data = {
            CompanyToken: selectedCompany.get('token'),
            CompanyIdentifier: selectedCompany.get('get_company_identifier'),
            CompanyCenter: selectedCompany.get('center'),
            SupplierCode:  supplier ? supplier.get('code') : '',
            SupplierName: supplier ? supplier.get('name'): '',
            PartCode: material.part_number_code,
            PurchaseOrder: material.purchase_order,
            Position: material.position,
        }

        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/releaseplan/items`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                dispatch(isSavingMaterial(false))
            })
            .catch((error) => {
                dispatch(isSavingMaterial(false))
                console.error("Error adding a material in reelase", error.response)
                return Promise.reject(error.response);
            })
    }
}

/**
 * Remove a material to release plan from backend
 * @param {*} annotationToken 
 */
export function removeMaterialFromReleasePlan(materialToken) {
    return (dispatch, getState) => {
        dispatch(isSavingMaterial(true));

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/releaseplan/items/${materialToken}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSavingMaterial(false))
                }
                else{
                    dispatch(isSavingMaterial(false));
                    return Promise.reject(serverResponse);

                }
            })
            .catch((error) => {
                dispatch(isSavingMaterial(false))
                console.error("Error removing a material from release", error.response)
                return Promise.reject(error.response);
            })
    }
}

/**
 * Action to update all release plan from supplier manually
 * @param {*} supplierCode 
 */
export function updateReleasePlanManuallyFromBack(){
    return function (dispatch, getState) {
        dispatch(isUploadingReleasePlanMnually(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/releaseplan/updatereleasesptm/synchronizemanually`;

        return axios.post(endPoint, null, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isUploadingReleasePlanMnually(false))
                }
            })
            .catch((error) => {
                console.error("Error to updating release plan manully", error.response)
                dispatch(isUploadingReleasePlanMnually(false))
                return Promise.reject();
            })
        }
        
}

