import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';

/** Material UI imoprt section */
import { Avatar, Card, CardContent, CardHeader, Typography } from '@material-ui/core';

/** Custom compoents import section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import PaymentRequestDetail from '../components/PaymentRequestDetail';
import PaymentRequestServices from '../components/PaymentRequestServices';
import ButtonDownload from '../components/ButtonDownload';
import LoadingPage from '../../common/LoadingPage';

/** Actions import section */
import {
	loadPaymentRequestDetail,
	loadServiceOrdersInPaymentRequest,
	downloandPaymentRequestsViewReport
} from '../../../store/accreditedWorkshops/accreditedWorkshopsActions';

/** Redux's selectors import section */
import {
	getPaymentRequestSelector,
	getServiceOrderListSelector
} from '../../../store/accreditedWorkshops/accreditedWorkshopsSelectors';

/**
 * Component to display the paymet request detail.
 */
const View = (props) => {
	/** Desctructuring properties */
	const { classes } = props;
	const { token } = props.match.params;

	/** Defines local state */
	const [showCircularProgress, setShowCircularProgress] = useState(false);
	const [loadingServiceOrders, setLoadingServiceOrders] = useState(false);

	/** Connect with redux */
	const dispatch = useDispatch();
	const paymentRequest = useSelector((state) => getPaymentRequestSelector(state));
	const serviceOrders = useSelector((state) => getServiceOrderListSelector(state));

	/**
	 * Load the payment request detail and the associated services to it.
	 */
	useEffect(() => {
		if (token) {
			dispatch(loadPaymentRequestDetail(token));
			setLoadingServiceOrders(true);
			dispatch(loadServiceOrdersInPaymentRequest(token, 0, 20)).finally(() => setLoadingServiceOrders(false));
		}
		return () => {
			// cleanup
		};
	}, [token, dispatch]);

	/*
	 * Download report with service orders into payment request
	 */
	const downloandViewReport = () => {
		setShowCircularProgress(true);
		dispatch(downloandPaymentRequestsViewReport(token)).finally(() => setShowCircularProgress(false));
	};

	return (
		<div>
			<Card>
				<CardHeader
					avatar={<Avatar>SP</Avatar>}
					title={
						<Typography>
							<Translate id="accreditedWorkshops.paymentRequestDetail.title" />
						</Typography>
					}
					action={
						<div className={classes.actionsToolbar}>
							<ButtonDownload onClick={downloandViewReport} showCircularProgress={showCircularProgress} />
						</div>
					}
				/>
				<CardContent>
					<PaymentRequestDetail {...paymentRequest} />
					{
						{
							true: <LoadingPage />,
							false: <PaymentRequestServices serviceOrders={serviceOrders} />
						}[loadingServiceOrders]
					}
				</CardContent>
			</Card>
		</div>
	);
};

const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
		alignItems: 'center'
	}
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(View)));
