/**
 * function to show plot to part number
 * Get information from SIP - SAP
 * @param {*} namePlot
 */
export function showPlotsFromPartNumber(namePlot, companyToken, kind, formatype) {
	if (kind === undefined) {
		kind = 1
	}
	if (formatype === undefined) {
		formatype = '0'
	}
	let urlPlot = `${process.env.REACT_APP_SIP_SUPPLIERS}/partnumbers/${namePlot}/${companyToken}/${kind}/${formatype}`
	window.open(urlPlot, '_blank', 'titlebar=no,width=800,height=800,location=no,resizable=yes')
}

/**
 * Function to check if a URL exists
 * @param {string} url - The URL to check.
 * @returns {Promise<boolean>} - Promise that resolves to true if the URL exists, false otherwise.
 */
export async function doesUrlExist(url) {
    try {
        const response = await fetch(url, { 
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/pdf'
            }
        });

		console.log('response', response)
		console.log('response Ok response', response.ok)
		console.log('response status', response.status)

        if (!response.ok) {
            console.error(`Error checking URL: ${url}`, {
                status: response.status,
                statusText: response.statusText,
                headers: [...response.headers.entries()],
            });
        }

        return response.ok;
    } catch (error) {
        console.error('Error checking URL:', error);
        return false;
    }
}
