import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import SnackbarContent from '@material-ui/core/SnackbarContent'

/** Import component section */
import Dialog from './Dialog'
import DialogActions from './DialogActions'
import { SHOW_EDIT_VIEW } from '../../store/helpers/ResourcesHelper'

/**
 * Dialog to add common item in workflow
 */
class AddCommonItem extends Component {
	/**
	 *Creates an instance of AddCommonItem.
	 * @param {*} props
	 * @memberof AddEmergentAction
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			description: this.props.valueText ? this.props.valueText : '',
		}
	}

	/**
	 * Function to add description
	 * @param {*} description
	 */
	updatetemProperty(description) {
		this.setState({
			description: description,
		})
	}

	/**
	 * onSave
	 */
	onSaveItem() {
		if (this.props.isMessage) {
			if (this.props.onSaveItem) this.props.onSaveItem(this.state.description)
		} else if (this.state.description) {
			if (this.props.onSaveItem) this.props.onSaveItem(this.state.description)
			this.setState({ description: '' })
		}
	}

	/**
	 * canBeSaved
	 */
	canBeSaved() {
		if (this.props.isMessage) {
			return true
		} else {
			return this.state.description !== ''
		}
	}

	/**
	 * Dialog information
	 */
	render() {
		const { classes } = this.props
		const open = this.props.open ? this.props.open : false
		return (
			<Dialog
				open={open}
				onClose={this.props.onClose}
				header={
					<Toolbar>
						<Typography variant='h6'>{this.props.title}</Typography>
					</Toolbar>
				}
				actions={
					<DialogActions
						view={SHOW_EDIT_VIEW}
						classes={classes}
						onCancel={this.props.onClose}
						onClose={this.props.onClose}
						onSaveItem={this.onSaveItem}
						isSaving={this.props.isSaving}
						canBeSaved={this.canBeSaved()}
						saveButtonTranslate={this.props.saveButtonTranslate}
						colorButton={this.props.colorButton}
					/>
				}
			>
				<form>
					{this.props.isTextArea && (
						<TextField
							fullWidth
							label={this.props.message}
							className={classes.textField}
							multiline
							rows='4'
							defaultValue={this.state.description}
							onChange={(event) => {
								this.updatetemProperty(event.target.value)
							}}
							margin='normal'
							variant='outlined'
						/>
					)}
					{this.props.isInput && (
						<TextField
							fullWidth
							required
							id='standard-read-only-input'
							label={this.props.message}
							defaultValue={this.state.description}
							margin='normal'
							onChange={(event) => {
								this.updatetemProperty(event.target.value)
							}}
							helperText={
								<FormHelperText error id='component-error-text'>
									{this.props.notExtistFolio}
								</FormHelperText>
							}
						/>
					)}
					{this.props.isMessage && (
						<SnackbarContent
							style={{ backgroundColor: 'white', color: '#212529' }}
							message={this.props.message}
						/>
					)}
				</form>
			</Dialog>
		)
	}
}

AddCommonItem.propTypes = {
	saveButtonTranslate: PropTypes.string
}

AddCommonItem.defaultProps = {
	open: false,
}

const dialogStyles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	toolBar: {
		backgroundColor: '#ea0b34',
	},
	button: {
		margin: theme.spacing.unit,
	},
	errorMessage: {
		color: theme.palette.common.white,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 500,
		marginTop: 10,
		marginBottom: 10,
	},
})

export default withRouter(withStyles(dialogStyles, { withTheme: true })(AddCommonItem))
