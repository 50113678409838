import React from 'react';
/**Import material ui icons */
import AddActionPlantIcon from '@material-ui/icons/Add';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddExpedientIcon from '@material-ui/icons/Folder';
import BillingItem from '@material-ui/icons/LocalAtm';
import RateReviewTwoToneIcon from '@material-ui/icons/RateReviewTwoTone';
import SettingsSupplierCassificationIcon from '@material-ui/icons/Settings';
import WarehouseWindowsIcon from '@material-ui/icons/Store';

import { Avatar } from '@material-ui/core';
import { Attachment, Notifications } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import { COLOR_QUESTIONNAIRE_CHERRY, COLOR_SECONDARY_BLUE_LIGHT } from '../../store/helpers/StatusColorConstants';

export const PanelLateralTitle = {
	IndexCreateIndirectQualification: 'indirectqualifications.create.title',
	ShowAddCommentsSupplierIndirectQualification: 'indirectqualifications.show.addComments',
	/**Billings */
	IndexCreateBilling: 'billings.create.title',
	ShowAddItemBilling: 'billings.create.titleItems',
	ShowAddBillingFile: 'billings.create.addFileBilling',
	ShowEditActionPlant: 'indirectqualifications.create.actionPlant',
	IndexAddAttachments: 'common.files',
	EditExpedientSupplier: 'expedientSupplier.classificationDocument',
	CreateExpedient: 'expedientSupplier.expedientTitle',
	IndexDeleteClassification: 'expedientSupplier.deleteClassification',
	AddActionPlantPanel: 'questionnaire.createActionPlant',
	EditActionPanel: 'questionnaire.editActionPlant',
	IndexCreateRequest: 'warehouseWindows.create.title',
	IndexViewRequest: 'warehouseWindows.view.title',
	IndexViewControl: 'controlCO2.title',
	IndexViewService: 'controlCO2.serviceTitle',
	SupplierRatingCommentsLateralPanelComponent: 'indirectqualifications.show.addComments',
    IndexViewEdit: "importRequests.title",
    IndexSendDocuments: "importRequests.aditionaltitle",
	AddLoadAlert: "loadAlerts.title",
	AddMaterial: "loadAlerts.addMaterial",
	AddInspectionDocument: "loadAlerts.addInspectionDocument",
	AddInvoiceDocuments: "loadAlerts.addDocument",
	AddAnnexesDocumentes: "loadAlerts.addDocument",
	HistoryMessages: "loadAlerts.HistoryMessages",
	AddPreviousDocument: "loadAlerts.addDocument"
	
}

export const PanelLateralSubTitle = {
	IndexCreateIndirectQualification: 'indirectqualifications.create.subTitle',
	IndexCreateBilling: 'billings.create.subTitle',
	IndexCreateRequest: 'warehouseWindows.create.subTitle',
	IndexViewRequest: 'warehouseWindows.view.subtitle',
	IndexViewControl: 'controlCO2.subtitle',
	IndexViewService: 'controlCO2.serviceSubtitle',
}

export const PanelLateralIcon = {
	IndexCreateIndirectQualification: (
		<Avatar style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }}>
			<RateReviewTwoToneIcon />
		</Avatar>
	),
	ShowAddCommentsSupplierIndirectQualification: (
		<Avatar style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }}>
			<RateReviewTwoToneIcon />
		</Avatar>
	),
	IndexCreateBilling: (
		<Avatar style={{ backgroundColor: COLOR_QUESTIONNAIRE_CHERRY, color: 'white' }}>
			<BillingItem />
		</Avatar>
	),
	ShowAddItemBilling: (
		<Avatar style={{ backgroundColor: COLOR_QUESTIONNAIRE_CHERRY, color: 'white' }}>
			<BillingItem />
		</Avatar>
	),
	ShowAddBillingFile: (
		<Avatar style={{ backgroundColor: COLOR_QUESTIONNAIRE_CHERRY, color: 'white' }}>
			<AttachFileIcon />
		</Avatar>
	),
	ShowEditActionPlant: (
		<Avatar style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }}>
			<RateReviewTwoToneIcon />
		</Avatar>
	),
	IndexAddAttachments: (
		<Avatar style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }}>
			<AttachFileIcon />
		</Avatar>
	),
	EditExpedientSupplier: (
		<Avatar style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }}>
			<AddExpedientIcon />
		</Avatar>
	),
	CreateExpedient: (
		<Avatar style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }}>
			<AssignmentIndIcon />
		</Avatar>
	),
	IndexDeleteClassification: (
		<Avatar style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }}>
			<SettingsSupplierCassificationIcon />
		</Avatar>
	),
	AddActionPlantPanel: (
		<Avatar style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }}>
			<AddActionPlantIcon />
		</Avatar>
	),
	EditActionPanel: (
		<Avatar style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }}>
			<AttachFileIcon />
		</Avatar>
	),
	IndexCreateRequest: (
		<Avatar style={{ backgroundColor: COLOR_QUESTIONNAIRE_CHERRY, color: 'white' }}>
			<WarehouseWindowsIcon />
		</Avatar>
	),
	IndexViewRequest: (
		<Avatar style={{ backgroundColor: COLOR_QUESTIONNAIRE_CHERRY, color: 'white' }}>
			<WarehouseWindowsIcon />
		</Avatar>
	),
	IndexViewControl: <Avatar style={{ backgroundColor: COLOR_QUESTIONNAIRE_CHERRY, color: 'white' }}>CO2</Avatar>,
	IndexViewService: <Avatar style={{ backgroundColor: COLOR_QUESTIONNAIRE_CHERRY, color: 'white' }}>CO2</Avatar>,
	SupplierRatingCommentsLateralPanelComponent: (
		<Avatar style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }}>
			<RateReviewTwoToneIcon />
		</Avatar>
	),
    IndexViewEdit:<Avatar style={{backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color:"white" }}><Add/></Avatar>,
    IndexSendDocuments:<Avatar style={{backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color:"white" }}><Attachment/></Avatar>,
    AddLoadAlert:<Avatar style={{backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color:"white" }}><Notifications/></Avatar>,
    AddMaterial:<Avatar style={{backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color:"white" }}><Notifications/></Avatar>,
    AddInspectionDocument:<Avatar style={{backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color:"white" }}><Notifications/></Avatar>,
    AddInvoiceDocuments:<Avatar style={{backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color:"white" }}><Notifications/></Avatar>,
    AddAnnexesDocumentes:<Avatar style={{backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color:"white" }}><Notifications/></Avatar>,
    AddPreviousDocument:<Avatar style={{backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color:"white" }}><Notifications/></Avatar>,
    HistoryMessages:<Avatar style={{backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color:"white" }}><Notifications/></Avatar>,
}

export const IsIconMaterial = {
    IndexCreateIndirectQualification: true,
    ShowAddCommentsSupplierIndirectQualification: true,
    IndexCreateBilling: true,
    ShowAddItemBilling: true,
    ShowAddBillingFile: true,
    ShowEditActionPlant:true,
    IndexAddAttachments:true,
    EditExpedientSupplier:true,
    CreateExpedient: true,
    IndexDeleteClassification:true,
    AddActionPlantPanel:true,
    EditActionPanel:true,
    IndexCreateRequest: true,
    IndexViewRequest: true,
    IndexViewControl: true,
    IndexViewService: true,
    IndexViewEdit: true,
    IndexSendDocuments: true,
	SupplierRatingCommentsLateralPanelComponent: true,
	AddLoadAlert: true,
	AddMaterial: true,
	AddInspectionDocument: true,
	AddInvoiceDocuments: true,
	AddAnnexesDocumentes: true,
	AddPreviousDocument: true,
	HistoryMessages: true
}
