/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import { Translate } from 'react-localize-redux'
/**Import material UI Section*/
import {
    Grid, withStyles, TextField, Typography
} from '@material-ui/core'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
/**Import components section*/
import PartNumberSelector from '../../../common/PartNumberSelector';
import { IS_MATERIAL_FROM_SAP, IS_MATERIAL_FROM_GENERAL } from '../../../../store/helpers/BiddingHelper'
const CreateMaterialComponent = (props) => {
    const CHARACTER_LIMIT_DESCRIPTION = 250;
    /**String props section */
    // const { } = props
    /**Bools props section */
    // const { } = props
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onPropertyChange, onSelectedPartNumber, onRadioChange, onPropertyPartNumberChange } = props
    /**Objects props section */
    const { biddingModel, classes } = props
    return (
        <><br /><br />
            <Grid container data-tut="reactour-criteria">
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend"><Translate id="biddingsModule.selectOptionMaterial" /></FormLabel>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <RadioGroup aria-label="gender" name="isMaterialSAP" value={biddingModel ? biddingModel.isMaterialSAP : ""} onChange={onRadioChange}>
                        <FormControlLabel value={IS_MATERIAL_FROM_SAP} control={<Radio />} label={<Translate id="biddingsModule.sAP" />} />
                    </RadioGroup>
                </Grid>
                <Grid item xs={6}>
                    <RadioGroup aria-label="gender" name="isMaterialSAP" value={biddingModel ? biddingModel.isMaterialSAP : ""} onChange={onRadioChange}>
                        <FormControlLabel value={IS_MATERIAL_FROM_GENERAL} control={<Radio />} label={<Translate id="biddingsModule.general" />} />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom><Translate id="plotSelector.searchPartNumbers" /></Typography>
                <PartNumberSelector
                    className={classes.textField}
                    onPartNumberSelected={(partNumber) => { onSelectedPartNumber('partNumber', partNumber) }}
                    isDisabled={biddingModel && biddingModel.isMaterialSAP === IS_MATERIAL_FROM_GENERAL}
                />

            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth
                    disabled={biddingModel && biddingModel.isMaterialSAP === IS_MATERIAL_FROM_SAP}
                    id="standard-read-only-input-code"
                    label={<Translate id="common.partNumber" />}
                    value={biddingModel.partNumber ? biddingModel.partNumber.code : ""}
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    name='code'
                    onChange={onPropertyPartNumberChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth
                    disabled={biddingModel && biddingModel.isMaterialSAP === IS_MATERIAL_FROM_SAP}
                    id="standard-read-only-input-measurementUnit"
                    label={<Translate id="common.measurementUnit" />}
                    value={biddingModel.partNumber ? biddingModel.partNumber.measurementUnit : ""}
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    name='measurementUnit'
                    onChange={onPropertyPartNumberChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth
                    disabled={biddingModel && biddingModel.isMaterialSAP === IS_MATERIAL_FROM_SAP}
                    id="standard-read-only-input-description"
                    label={<Translate id="common.descriptionPartNumber" />}
                    value={biddingModel.partNumber ? biddingModel.partNumber.description : ""}
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    name='description'
                    onChange={onPropertyPartNumberChange}
                />
            </Grid>
            <Grid item xs={12} className={classes.titleDisplay}>
                <TextField
                    fullWidth
                    label={<Translate id="biddingsModule.quantity" />}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    type='number'
                    step={0.5}
                    name='quantity'
                    margin="dense"
                    variant="outlined"
                    value={biddingModel ? biddingModel.quantity : 0}
                />
            </Grid>
            <Grid item xs={12} className={classes.titleDisplay}>
                <TextField
                    fullWidth
                    label={<Translate id="biddingsModule.period" />}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    name='purchasePeriod'
                    margin="dense"
                    variant="outlined"
                    value={biddingModel ? biddingModel.purchasePeriod : ""}
                />
            </Grid>
            <Grid item xs={12} className={classes.titleDisplay}>
                <TextField
                    fullWidth
                    label={<Translate id="biddingsModule.baseUnit" />}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    type='number'
                    step={0.5}
                    name='baseUnit'
                    margin="dense"
                    variant="outlined"
                    value={biddingModel ? biddingModel.baseUnit : 0}
                />
            </Grid>
            <TextField fullWidth
                    id="standard-read-only-input-code"
                    label={<Translate id="biddingsModule.measurentUnitBase" />}
                    value={biddingModel.baseMeasurement ? biddingModel.baseMeasurement : ""}
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    name='baseMeasurement'
                    onChange={onPropertyChange}
                />
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={<Translate id="common.comments" />}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    multiline
                    name='comments'
                    margin="dense"
                    variant="outlined"
                    value={biddingModel ? biddingModel.comments : 0}
                    inputProps={{ maxLength: CHARACTER_LIMIT_DESCRIPTION }}
                    helperText={`${biddingModel ? biddingModel.comments.length : 0}/${CHARACTER_LIMIT_DESCRIPTION}`}
                />

            </Grid>
        </>
    )
}

const styles = theme => ({

});
CreateMaterialComponent.propTypes = {
    /**
    * Change property values textfiel
    */
    onPropertyChange: PropTypes.func.isRequired,
}

CreateMaterialComponent.defaultProps = {
    onPropertyChange: () =>
        console.warn('Callback [onPropertyChange] no defined'),
}

export default withRouter(withStyles(styles, { withTheme: true })(CreateMaterialComponent))