import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import moment from 'moment'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import RawMaterialNonConfIcon from '@material-ui/icons/BugReport';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Toaster from '../common/Toaster';
import FormCreateFromDelivery from './components/FormCreateFromDelivery';
import LoadingPage from '../common/LoadingPage';

import {loadDeliveryOrderFromBackEnd} from '../../store/planning/DeliveryOrdersAction';
import {createRawMaterialNonConformityFromBackEnd} from '../../store/rawMaterialNonConformity/RawMaterialActions';
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';
import { showErrorToaster } from '../../store/helpers/ToasterHelpers';

/**
 * Component to EditReport raw material supplier
 */
class CreateRawMaterialFromDelivery extends Component{

     /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            creationDate: moment().format('DD/MM/YYYY'),
            itemFromRawMaterial: null,
        }
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        let deliveryOrderToken = this.props.match.params.token;
        this.props.getDeliveryOrder(deliveryOrderToken);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoadingDeliveryOrder !== this.props.isLoadingDeliveryOrder) && this.props.deliveryOrder !== null) {
            /// Get item to generated raw material
            let itemToken = this.props.match.params.itemToken;
            var itemRam = this.props.deliveryOrder.materials.find(material => material.token === itemToken)
            if(itemRam !== null){
                this.setState({
                    itemFromRawMaterial: itemRam,
                })
            }
        }
    }

    /**
     * on creta raw material no conformitie
     */
    createRawMaterial(preparedBy, supplier, partNumber, failure, rawMaterial){
        this.props.createRawMaterialNonConformity(preparedBy, supplier, partNumber, failure, rawMaterial)
        .then((rawMaterial) =>{
            this.setState({
                showToView:true,
                showToaster: false
            })
            this.props.history.push(`/rawmaterialnonconformity/${rawMaterial.token}`)
        }).catch((error) =>{
            this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : "common.errorToSave"));
        });
    }

      /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.state.itemFromRawMaterial !== null) {
            return (
                <FormCreateFromDelivery  
                    isSaving = {this.props.isSaving}
                    deliveryOrder={this.props.deliveryOrder}
                    itemFromRawMaterial={this.state.itemFromRawMaterial}
                    createdFromDeliveryOrder={this.createRawMaterial}
                />
            )
        }
    }

    render(){
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <RawMaterialNonConfIcon />
                    </Avatar>
                }
                title={<Typography variant="button" ><Translate id="rawmaterialnonconformity.informationRawMaterial"/></Typography>}
                subheader={<Typography variant="caption" color="textPrimary"><Translate id="common.createBy"/> {this.props.user.profile.name}
                 <br/><Translate id="common.creationDate"/>: {this.state.creationDate}</Typography>}
            />
            <CardContent>
                {this.getCardContent()}
            </CardContent>

            <Toaster 
                message = {this.state.toasterMessage}
                open = {this.state.showToaster}
                variant = {this.state.toasterVariant}
                onClose = {() => {this.setState({ showToaster:false})}}
            /> 
        </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    return {
        user: user,
        isSaving: state.rawMaterialNonConformity.get('isSaving'),
        isLoadingDeliveryOrder: state.deliveryOrders.get('isLoadingDeliveryOrder'),
        deliveryOrder: state.deliveryOrders.get('deliveryOrder') ? state.deliveryOrders.get('deliveryOrder').toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        getDeliveryOrder: (deliveryOrderToken) => {
            return (dispatch(loadDeliveryOrderFromBackEnd(deliveryOrderToken)))
        },
        createRawMaterialNonConformity:(preparedBy, supplier, partNumber, failure, rawMaterial) =>{
            return dispatch(createRawMaterialNonConformityFromBackEnd(preparedBy, supplier, partNumber, failure, rawMaterial))
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(CreateRawMaterialFromDelivery))));