/**Import react section */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
/**Import MaterialUi section */
import { Grid, Avatar, TextField, Typography, Button, LinearProgress } from '@material-ui/core'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import Toaster from '../../common/Toaster'
import HtmlEditor from '../../common/HtmlEditor'

/**Import store section */
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'

/**Import actionsSection */
import { editPhoneDirectoryFromBackEnd } from '../../../store/phoneDirectories/PhoneDirectoryActions'
import { showErrorToaster, showInfoToaster } from '../../../store/helpers/ToasterHelpers'

const EditPhoneDirectoryPanel = (props) => {
	/**props section */
	const { onCloseButtonClick, classes, token } = props

	const dispatch = useDispatch()
	const isSaving = useSelector((state) => state.phoneDirectories.get('isSaving'))

	const [categoryModel, setCategoryModel] = useState({
		UserName: '',
		Name: '',
		Email: '',
		PhoneNumber: '',
		Position: '',
		DirectBoss: '',
		ActivityMaterials: '',
		PhoneDirectoryId: '',
	})
	const [toasterState, setToasterState] = useState({ showToaster: false })

	/**Use effect section */
	useEffect(() => {
		if (props.item) {
			setCategoryModel({
				UserName: props.item ? props.item.userName : '',
				Name: props.item ? props.item.name : '',
				Email: props.item ? props.item.email : '',
				PhoneNumber: props.item ? props.item.phoneNumber : '',
				Position: props.item ? props.item.position : '',
				DirectBoss: props.item ? props.item.directBoss : '',
				ActivityMaterials: props.item ? props.item.activityMaterials : '',
				PhoneDirectoryId: props.item ? props.item.phoneDirectoryId : '',
			})
		}
	}, [props.item])

	const onEditPhoneDirectoryFromBackEnd = () => {
		return dispatch(editPhoneDirectoryFromBackEnd(categoryModel, token, categoryModel.PhoneDirectoryId))
			.then(() => {
				setToasterState(showInfoToaster())
				onCloseButtonClick()
			})
			.catch((response) => {
				setToasterState(showErrorToaster())
			})
	}

	/**Verify if can be sabed data*/
	const canBeSaved = () => {
		if (IsNullOrEmpty(categoryModel.UserName)) return false
		if (IsNullOrEmpty(categoryModel.Name)) return false
		if (IsNullOrEmpty(categoryModel.Email)) return false
		if (IsNullOrEmpty(categoryModel.PhoneNumber)) return false
		if (IsNullOrEmpty(categoryModel.Position)) return false
		if (IsNullOrEmpty(categoryModel.DirectBoss)) return false
		if (IsNullOrEmpty(categoryModel.ActivityMaterials)) return false
		return true
	}

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setCategoryModel({
			...categoryModel,
			[name]: value,
		})
	}

	const onTextFieldTextPropertyChange = (value) => {
		setCategoryModel({
			...categoryModel,
			ActivityMaterials: value,
		})
	}

	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={<span>{<Translate id='phoneDirectories.create.editContact' />}</span>}
				onCloseButtonClick={onCloseButtonClick}
				icon={
					<Avatar className={classes.avatar}>
						<ImportContactsIcon />
					</Avatar>
				}
				isIconMaterial={true}
			/>
			<>
				<div className={classes.content}>
					<TextField
						fullWidth
						label={<Translate id='phoneDirectories.create.userName' />}
						name='UserName'
						onChange={onPropertyChange}
						className={classes.textField}
						value={categoryModel ? categoryModel.UserName : ''}
						variant='outlined'
						margin='dense'
					/>
					<TextField
						fullWidth
						label={<Translate id='phoneDirectories.create.name' />}
						name='Name'
						onChange={onPropertyChange}
						className={classes.textField}
						value={categoryModel ? categoryModel.Name : ''}
						variant='outlined'
						margin='dense'
					/>

					<TextField
						fullWidth
						label={<Translate id='phoneDirectories.create.email' />}
						name='Email'
						onChange={onPropertyChange}
						className={classes.textField}
						value={categoryModel ? categoryModel.Email : ''}
						variant='outlined'
						margin='dense'
					/>
					<TextField
						fullWidth
						label={<Translate id='phoneDirectories.create.phoneNumber' />}
						name='PhoneNumber'
						onChange={onPropertyChange}
						className={classes.textField}
						value={categoryModel ? categoryModel.PhoneNumber : ''}
						variant='outlined'
						margin='dense'
					/>
					<TextField
						fullWidth
						label={<Translate id='phoneDirectories.create.position' />}
						name='Position'
						onChange={onPropertyChange}
						className={classes.textField}
						value={categoryModel ? categoryModel.Position : ''}
						variant='outlined'
						margin='dense'
					/>

					<TextField
						fullWidth
						label={<Translate id='phoneDirectories.create.directBoss' />}
						name='DirectBoss'
						onChange={onPropertyChange}
						className={classes.textField}
						value={categoryModel ? categoryModel.DirectBoss : ''}
						variant='outlined'
						margin='dense'
					/>
					<Grid container>
						<Grid item xs={12}>
							<Typography>
								<Translate id='phoneDirectories.create.categoryMaterials' />
							</Typography>
							<HtmlEditor
								onEditorChange={onTextFieldTextPropertyChange}
								initialValue={categoryModel ? categoryModel.ActivityMaterials : ''}
							/>
						</Grid>
					</Grid>
				</div>
				{isSaving && <LinearProgress />}
				<Button
					className={classes.btnSubmit}
					type='submit'
					variant='contained'
					disabled={isSaving || !canBeSaved()}
					fullWidth
					onClick={onEditPhoneDirectoryFromBackEnd}
				>
					<Translate id={'common.edit'} />
				</Button>
				<Toaster
					message={<Translate id={toasterState.toasterMessage} />}
					open={toasterState.showToaster}
					variant={toasterState.toasterVariant}
					onClose={() => setToasterState({ showToaster: false })}
				/>
			</>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	content: {
		padding: '20px',
		maxHeight: `calc(100vh - ${180}px)`,
		minHeight: `calc(100vh - ${180}px)`,
		overflowY: 'auto',
	},
	textField: {
		marginBottom: theme.spacing.unit,
	},
	btnSubmit: {
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
})

EditPhoneDirectoryPanel.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
}

EditPhoneDirectoryPanel.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(EditPhoneDirectoryPanel))
