/**Import react section */
import React from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'

/** Import Material-ui section */
import BugReportIcon from '@material-ui/icons/BugReport'
import { Typography, withStyles, Button } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

/** Import commmon section */

import Dropzone from '../../common/Dropzone'
import { massiveTransportTender, setErrorsTranportTender } from '../../../store/transportTender/transportTenderAction'
import downloadTemplate from '../../../resources/files/Plantilla_de_ licitacion_transportes.xlsx'

const MassiveTransportZones = (props) => {
	const { classes, errorTransportTender, isLoading, file, setfile, canBeSaved } = props
	const { setIsInvalidFile, isInvalidFile } = props
	const dispatch = useDispatch()

	const onChangeFile = (files) => {
		setfile(files)
		return dispatch(massiveTransportTender(files))
			.then(() => {
				setIsInvalidFile(true)
				return Promise.resolve('Ok')
			})
			.catch(() => {
				setIsInvalidFile(false)
				return Promise.reject()
			})
	}
	const onDeleteFile = (fileName) => {
		let newFiles = file.filter((file) => {
			return file.name !== fileName
		})
		setfile(newFiles)
		setIsInvalidFile(true)
		dispatch(setErrorsTranportTender([]))
		return Promise.resolve('Ok')
	}
	return (
		<div className={classes.root}>
			{!isInvalidFile && <Typography color='error'>El archivo cargado contiene errores</Typography>}
			{errorTransportTender.length === 0 && (
				<div>
					<Typography variant='subtitle2' className={classes.title}>
						<Translate id={`carrierTenders.file.downloadUpload`} />
					</Typography>
					<a href={downloadTemplate}>
						<Button
							data-tut='reactour-save'
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='outlined'
							className={classes.btnCreate}
							disabled={canBeSaved || isLoading}
						>
							<CloudDownloadIcon />
							<Translate id={`carrierTenders.file.downloadTemplate`} />
						</Button>
					</a>
				</div>
			)}
			<Typography variant='body2' className={classes.title}>
				<Translate id={`carrierTenders.file.uploadTemplateDocument`} />
			</Typography>
			<Dropzone
				files={file}
				onChange={(event) => onChangeFile(event)}
				onDeleteFile={onDeleteFile}
				baseApi={''}
				acceptedFiles={['XLSX']}
				isDisabled={isLoading}
				disabledLink={false}
				maxFiles={1}
				dropzoneText={<Translate id='dropzone.dropzoneBaseText' />}
			/>
			{errorTransportTender.length > 0 && (
				<div>
					<Typography variant='caption' className={classes.errorTitle}>
						{<BugReportIcon className={classes.errorIcon} />}{' '}
						<Translate id={`carrierTenders.file.informationErrors`} />
					</Typography>
					<div
						style={{
							overflowY: 'scroll',
							maxHeight: '195px',
						}}
					>
						{errorTransportTender.map((error) => {
							return (
								<div styles={{ display: 'flex' }}>
									<Typography variant='caption' className={classes.titleError}>
										Fila {error.excelRow} -
										{error.message.includes('empty') ? (
											<div styles={{ display: 'flex' }}>
												{' '}
												El campo{' '}
												{
													<Translate
														id={`carrierTenders.errorFiles.massiveTranportTender.${error.message}`}
													/>
												}{' '}
												esta vacio
											</div>
										) : (
											<Translate
												id={`carrierTenders.errorFiles.massiveTranportTender.${error.message}`}
											/>
										)}
									</Typography>
								</div>
							)
						})}
					</div>
				</div>
			)}
		</div>
	)
}

const styles = (theme) => ({
	btnCreate: {
		marginTop: '10px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	root: {
		width: '500px',
	},
	title: {
		marginTop: '10px',
		display: 'flex',
	},
	titleError: {
		marginTop: '3px',
		display: 'flex',
	},
	errorTitle: {
		color: `${theme.palette.error.main} !important`,
	},
	errorIcon: {
		color: `${theme.palette.error.main} !important`,
	},
})

MassiveTransportZones.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	canBeSaved: PropTypes.func.isRequired,
	setfile: PropTypes.func.isRequired,
	errorTransportTender: PropTypes.object,
	file: PropTypes.object,
}

MassiveTransportZones.defaultProps = {
	canBeSaved: () => console.warn('[canBeSaved] not defined!'),
	setfile: () => console.warn('[setfile] not defined!'),
	isLoading: false,
	errorTransportTender: [],
	file: [],
}

export default withStyles(styles, { withTheme: true })(MassiveTransportZones)
