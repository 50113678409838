/**Import react section */
import React, { useState } from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";

/** Language imports **/
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

/** Import component section */
import Dialog from "../../../common/Dialog";
import DialogActions from "../../../common/DialogActions";

/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW } from "../../../../store/helpers/ResourcesHelper";
import { IsNullOrEmpty } from "../../../../store/helpers/StringHelper";

const CardUpdateContact = (props) => {
    /**props section */
    const {
        open,
        onClose,
        isSaving,
        onSaveItem,
        classes,
        isEdit,
        ContactEmail,
        biddingId,
        supplierId,
        supplierType
    } = props;

    const [itemModel, setItemModel] = useState(
        open ? {
            updateContact: ContactEmail,
            biddingId: biddingId,
            supplierCode: supplierId,
            supplierType: supplierType
        }
            : {
                updateContact: "",
                biddingId: "",
                supplierCode: "",
                supplierType: 0
            }
    );

    const [errors, setErrors] = useState({});

    const onSaveItemUpdate = () => {
        if (props.onSaveItem) onSaveItem(itemModel);
    };
    /**
      * Method to validate if required properties has data
      *
      * @returns
      * @memberof AddModal
      */
    const isValidToSave = () => {
        if (IsNullOrEmpty(itemModel.updateContact)) return false;
        if (Object.entries(errors).length !== 0) return false
        else return true;
    };

    /**
   * Handle update properties to set data in local state
   *
   * @param {*} property
   * @param {*} data
   * @memberof AddModal
   */
    const updateProperty = (event) => {
        let { name, value } = event.target;
        const errors = validate(event.target);
        setErrors(errors);
        setItemModel({
            ...itemModel,
            [name]: value,
        });
    };

    const validate = data => {
        const errors = {};
        if (data.name === "updateContact" && data.value.length > 0) {
            let regexQuantity = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regexQuantity.test(data.value)) errors.updateContact = "Correo electrónico inválido";
        }
        return errors;
    };

    return (
        <Dialog
            open={open}
            onClose={isEdit ? onClose : onClose}
            header={
                <Toolbar>
                    <Typography variant="h6">
                        {<Translate id="biddingsModule.supplierUpdateContact" />}
                    </Typography>
                </Toolbar>
            }
            actions={
                <div>
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={isEdit ? onClose : onClose}
                        onClose={isEdit ? onClose : onClose}
                        onSaveItem={isEdit ? onSaveItemUpdate : onClose}
                        isSaving={isSaving}
                        canBeSaved={isValidToSave() || isSaving}
                    />
                </div>
            }
        >
            <Grid container className={classes.modal} justify="space-between">
                <TextField
                    name="updateContact"
                    error={Object.entries(errors).length !== 0}
                    label={<Translate id="contactsupplier.newContact" />}
                    value={itemModel.updateContact}
                    size="small"
                    margin="dense"
                    type={"text"}
                    fullWidth
                    required={true}
                    onChange={updateProperty}
                    variant={"outlined"}
                    helperText={(errors.updateContact) ? errors.updateContact : ""}
                />
                <Typography className={classes.subtitle1}>
                    {<Translate id="biddingsModule.emailChangeNotification" />}
                </Typography>
            </Grid>
        </Dialog>
    );

};

const styles = (theme) => ({
    modal: {
        marginRight: theme.spacing.unit * 2,
        width: 500,
    },
    subtitle1: {
        fontSize: 12,
        color: '#f48fb1',
    },
});

CardUpdateContact.propTypes = {
    onClose: propTypes.func,
    isSaving: propTypes.bool.isRequired,
    isEdit: propTypes.bool,
    onSaveItem: propTypes.func,
    ContactEmail: propTypes.string,
    biddingId: propTypes.string,
    supplierId: propTypes.string,
    supplierType: propTypes.number
};

CardUpdateContact.defaultProps = {
    onClose: () => console.warn("Callback [onClose] no defined"),
    isSaving: false,
    isEdit: false,
    onSaveItem: () => console.warm("Callback [onSaveItem] no defined"),
    ContactEmail: "",
    biddingId: "",
    supplierId: "",
    supplierType: 0
};

export default withRouter(
    withStyles(styles, { withTheme: true })(CardUpdateContact)
);