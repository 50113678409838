import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import moment from 'moment'

/** Import component section */
import '../../resources/styles/autosuggest.css'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import Toaster from '../common/Toaster';
import FormHeaderCard from './Components/FormHeaderCard';
import SuppliersCard from './Components/SuppliersCard'

/** Translations imports section */
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

/** Import section actions */
import { searchBySuppliersByQury } from '../../store/suppliers/SupplierActions';
import { createContractData } from '../../store/supplierconstracts/SupplierConstractAction';
import {DOCUMENT_ENVIROMENTAL} from '../../store/helpers/ResourcesHelper'

/**
 * Container to Create contract
 */
class CreateDocument extends Component {

    /**
   * Creates an instance of View.
   * @param {*} props
   * @memberof View
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            contract: {
                attachments: [],
                activationDate: "",
                expirationDate: "",
                supplierSelected: null,
                name: "",
                notes: "",
                documentType: DOCUMENT_ENVIROMENTAL,
            }
        }
    }

    /**
    * Update the properties in state
    *
    * @param {*} user
    * @param {*} value
    * @memberof View
    */
    updateProperty(property, value) {
        let contractpdate = { ...this.state.contract };
        contractpdate[property] = value;
        this.setState({
            contract: contractpdate
        })
    }

      /**
     * On seletecd supplier
     */
    onSupplierSelected(supplier) {
        let contractpdate = { ...this.state.contract };
        contractpdate.supplierSelected = supplier;
        this.setState({
            contract: contractpdate
        })
    }

    /**
     * Remove files
     */
    removeFiles(fileName) {
        let attachments = this.state.contract.attachments.filter(file => {
            return file.name !== fileName
        })
        this.setState({
            contract: {
                ...this.state.contract,
                attachments
            }
        })
        return Promise.resolve('Ok')
    }

    /**
     * Add file
     * @param {*} attachments 
     */
    uploadFiles(files) {
        let filesList = this.state.contract.attachments;
        if (files.length > 0) {
            for (var index = 0; index < files.length; index++) {
                filesList.push(files[index])
            }
        }
        this.setState({
            contract: {
                ...this.state.contract,
                attachments: filesList
            }
        })
        return Promise.resolve('Ok')
    }


    /**
     * Action to can be  information
     */
    canBeSaved() {
        if (this.props.isSaving)
            return false;
        else {
            let contract = this.state.contract;
            if (contract.activationDate.length === 0)
                return false;
            if (contract.expirationDate.length === 0)
                return false;
            if (moment(contract.expirationDate).isBefore(contract.activationDate))
                return false;
            if (contract.attachments.length <= 0)
                return false;
            if (contract.supplierSelected === null)
                return false;
        }
        return true;
    }

    /**
     * Function to create
     * @param {*} worker 
     */
    onCreateContractData() {
        this.props.createContractData(this.state.contract)
            .then(() => {
                this.setState({
                    showAddWorker: false,
                    showToView: true,
                    showToaster: true,
                    toasterMessage: <Translate id="common.addCorrectly" />,
                    toasterVariant: "success"
                })
                this.props.history.push(`/documents`);
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToAdd" />,
                    toasterVariant: "error",
                })
            });
    }


    /**
     * Render show 
     */
    render() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={6}>
                    <FormHeaderCard
                        updateProperty={this.updateProperty}
                        contract={this.state.contract}
                        onCreateContractData={this.onCreateContractData}
                        canBeSaved={this.canBeSaved()}
                        uploadFiles={this.uploadFiles}
                        removeFiles={this.removeFiles}
                        dropzoneText={this.props.translate('dropzone.dropzoneBase')}
                        request = {true}
                        requestDocument = {true}
                        {...this.props}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SuppliersCard
                        contract={this.state.contract}
                        updateProperty={this.updateProperty}
                        onSupplierSelected={this.onSupplierSelected}
                        {...this.props}
                    />
                </Grid>

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Grid>
        )

    }
}


/**
 * Map state to props mapStateToProps
 * @param {*} state 
 */
function mapStateToProps(state) {
    let selectedCompanyIdentifier = state.profile.get('selectedCompany').get('get_company_identifier')

    return {
        selectedCompanyIdentifier: selectedCompanyIdentifier ? selectedCompanyIdentifier : null,
        isLoadingUser: state.oidc.isLoadingUser,
        isLoadingSuppliers: state.suppliers.get("isLoadingSuppliers"),
        isSaving: state.contracts.get('isSavingContract'),
        translate: getTranslate(state.localize)
    }
}

/**
 * mapDispatchToProps
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        searchBySuppliersByQury: (query) => {
            dispatch(searchBySuppliersByQury(query));
        },
        createContractData: (contract) => {
            return dispatch(createContractData(contract));
        },

    }
}

const viewStyles = theme => ({
    userAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 240,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    formatCheck: {
        display: 'flex',
        marginLeft: theme.spacing.unit,
        width: 460,
    },
});

export default withauthenticatedLayout(withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(CreateDocument))));