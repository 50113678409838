/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux'
/** Material UI components */
import {
    Popper,
    ClickAwayListener,
    Typography,
    Paper,
    Tooltip,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from '@material-ui/core/'
import MoreVertIcon from '@material-ui/icons/MoreVert';
/**Import resources section */

const PopperListsComponent = (props) => {
    const { listItems, title, classes } = props
    const [anchorEl, setAnchorEl] = useState(null)

    const onMoreIconClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget)
    }
    return (
        <div>
            <Tooltip
                title={title}
            >
                <MoreVertIcon
                    onClick={onMoreIconClick}
                    className={classes.moreIcon}
                />
            </Tooltip>
            <Popper anchorEl={anchorEl} open={anchorEl !== null} className={classes.zIndex}>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Paper elevation={2} >
                        <div className={classes.titleContainer}>
                            <Typography variant='subtitle2' className={classes.title} align='center'>{title}</Typography>
                        </div>
                        <List dense className={classes.paper}>

                            {
                                (listItems && listItems.length !==0) ? listItems.map((item, index) => {
                                    return (< ListItem
                                        button
                                        onClick={item.onClick}
                                        key={index}
                                        disabled={item.disabled}
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            {
                                                item.isIconMaterial ? item.icon :
                                                    <img src={item.icon} alt='edit' width="20px" height="auto" />
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItem>)
                                }): <Typography> <Translate id="biddingsModule.noOptions" /></Typography>
                            }
                        </List>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div >
    )
}

const useCardStyle = ((theme) => ({
    paper:{
        paddingBottom: "10px",
        paddingRight:"10px",
        paddingLeft:"10px",
    },
    popper: {
        gridColumn: "2/3",
        padding: "30px",
    },
    moreIcon: {
        marginTop: ({ marginTop }) => marginTop ? marginTop : "0px",
        marginRight: ({ marginRight }) => marginRight ? marginRight : "0px",
        cursor: "pointer",
    },
    listItemIcon: {
        "&.MuiListItemIcon-root": {
            maxWidth: "30px !important",
            minWidth: "30px !important"
        }
    },
    titleContainer: {
        backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    title: {
        color: `${theme.palette.common.white} !important`,
    },
    zIndex: {
        zIndex: 999
    }
}))


PopperListsComponent.propTypes = {
    /**
     * This property fron css determinate the position of icon more
     */
    marginTop: propTypes.string,
    /**
     * This property fron css determinate the position of icon more
     */
    marginRight: propTypes.string,
    /**
     * This property fron css determinate the title popper card
     */
    title: propTypes.oneOfType([
        propTypes.string,
        propTypes.node,
    ]),
    /**
     * This array determinate options render in the list item
     */
    listItems: propTypes.array.isRequired
}
PopperListsComponent.defaultProps = {
    listItems: [],
    marginTop: "",
    marginRight: "",
    title: "",
}

export default (withStyles(useCardStyle, { withTheme: true })(PopperListsComponent))