///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { Translate } from 'react-localize-redux'
/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
/** Import component section */
import DesktopView from './components/create/DesktopView'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
//Import store section
import {
	loadFactoringHistoryFromBackEnd,
	updateFactoringBackEnd,
	addFactoringsFiles,
	deleteFactoringsFiles,
	downloadFactoringFile,
	synchronizeFactoringSap,
	notificateSupplierFromFactoring,
} from '../../store/factoringAffiliations/FactoringAffiliationActions'
import { converListToArray, toSafeObject } from '../../store/helpers/ListHelper'
import Toaster from '../common/Toaster'

const Create = (props) => {
	/**Render hooks section */
	const dispatch = useDispatch()
	/**Render hooks section */
	const translate = useSelector((state) => getTranslate(state.localize))

	const factoring = useSelector((state) =>
		state.factoringAffiliation.get('factoring') ? toSafeObject(state.factoringAffiliation.get('factoring')) : null
	)
	/**Redux store information section*/
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)

	/** Get user Information*/
	const userProfile = useSelector((state) => state.oidc.user)
	/** Get user Information*/
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})
	const isSaving = useSelector((state) =>
		state.factoringAffiliation.get('isSaving') ? state.factoringAffiliation.get('isSaving') : false
	)
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')

	const [isSendingNotifiaction, setIsSendingNotifiaction] = useState(false);

	const onUpdateFactoring = (
		step,
		currencies,
		paymentConditions,
		annualPurchase,
		aceptedBySupplier,
		commentRejectedSupplier
	) => {
		return dispatch(
			updateFactoringBackEnd(
				props.match.params.id,
				step,
				currencies,
				paymentConditions,
				annualPurchase,
				aceptedBySupplier,
				commentRejectedSupplier
			)
		)
			.then((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
				return Promise.resolve()
			})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
				return Promise.reject()
			})
	}

	const onAddAttachmets = (attachments, folderName) => {
		return dispatch(addFactoringsFiles(props.match.params.id, attachments, folderName))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onDeleteFactoringsFiles = (fileName, folderName) => {
		return dispatch(deleteFactoringsFiles(props.match.params.id, fileName, folderName))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onDownloadFactoringFile = (fileName, folderName) => {
		return dispatch(downloadFactoringFile(props.match.params.id, fileName, folderName))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}
	const onSynchronizeSapFactoring =(factoringId, covenantId)=>{
		return dispatch(synchronizeFactoringSap(factoringId, covenantId))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onSendNotificationToSupplier = (factoringId) =>{
		setIsSendingNotifiaction(true);
		dispatch(notificateSupplierFromFactoring(factoringId))
		.then(() => {
			setToasterMessageConfig({
				showToaster: true,
				toasterVariant: 'success',
				toasterMessage: 'common.saveCorrectly',
			})
			setIsSendingNotifiaction(false)
		})
		.catch(() => {
			setToasterMessageConfig({
				showToaster: true,
				toasterMessage: 'common.error',
				toasterVariant: 'error',
			})
			setIsSendingNotifiaction(false)
		})
	}

	/**Use effect section */
	useEffect(() => {
		dispatch(loadFactoringHistoryFromBackEnd(props.match.params.id))
	}, [dispatch, props.match.params.id])

	return (
		<>
			<DesktopView
				translate={translate}
				factoring={factoring}
				//Array props section
				userRolesByCompany={userRolesByCompany}
				//Function props section
				onUpdateFactoring={onUpdateFactoring}
				onAddAttachmets={onAddAttachmets}
				onDeleteFactoringsFiles={onDeleteFactoringsFiles}
				onDownloadFactoringFile={onDownloadFactoringFile}
				SynchronizeSapFactoring = {onSynchronizeSapFactoring}
				onSendNotificationToSupplier = {onSendNotificationToSupplier}
				//bool props
				isInternalUser={isInternalUser()}
				isSaving={isSaving}
				isSavingNotification={isSendingNotifiaction}
			/>
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</>
	)
}

const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Create)))
