import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { Toolbar, Typography, TextField } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import autoBind from 'auto-bind'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';

class DialogEditNonConformity extends Component {
    /**
     * Create an instance of Dialog
     * @param {*} props
     * @memberof DialogEditNonConformity
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            token: this.props.nonConformity.token,
            name: this.props.nonConformity.name,
            name_english:this.props.nonConformity.name_english,
            name_portuguese: this.props.nonConformity.name_portuguese,
        }
    }

    /**
     * Function to update property of text field in state
     *
     * @param {*} data
     * @memberof DialogEditNonConformity
     */
    updateProperty(data) {
        this.setState({
            [data.id]: data.value,
        })
    }

    /**
     * Function to trigger blur event
     * 
     * @param {*} event
     * @memberof DetailsCard
     */
    onKeyDown(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    /**
     * Validate if Save button is enable 
     * to enable, required properties must has data
     *
     * @returns
     * @memberof DialogEditNonConformity
     */
    canBeSaved() {
        if (!IsNullOrEmpty(this.state.name))
            return true;
        return false;
    }

    /**
     * Handle close or cancel to clean local estate and call prop function after update
     *
     * @memberof DialogEditNonConformity
     */
    handleCloseCancel() {
        this.setState({
            name: "",
            name_english: "",
            name_portuguese: "",
        }, () => this.props.onCloseCancel('openModalEditNonConformity'))
    }

    /**
     * Handle OnSave, if response is 'Ok' then clean state
     *
     * @memberof DialogEditNonConformity
     */
    onSaveNonConformity() {
        if(this.props.onSaveNonConformity)
            this.props.onSaveNonConformity(this.state);
    }

    /**
     * On save non conformity
     * @param {*} id 
     * @param {*} translateId 
     */
    renderTextField(id, translateId) {
        return (
            <TextField id={id} fullWidth
                required={id === 'name'}
                autoFocus={id === 'name'}
                className={this.props.classes.textField}
                label={<Translate id={translateId} />}
                value={this.state[id] ? this.state[id] : ''}
                onBlur={(event) => this.updateProperty(event.target)}
                onChange={(event) => this.updateProperty(event.target)}
                onKeyDown={(event) => this.onKeyDown(event)}
            />
        )
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.showDialog}
                onClose={() => { this.handleCloseCancel() }}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            <Translate id="qualityCatalogs.nonConformityEdit" />
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <div>
                        {!this.canBeSaved() &&
                            <Typography variant="caption" color='error'>
                                {<Translate id="common.requiredFields" />}
                            </Typography>
                        }
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => { this.handleCloseCancel() }}
                            onClose={() => { this.handleCloseCancel() }}
                            onSaveItem={() => this.onSaveNonConformity()}
                            isSaving={this.props.isSaving}
                            canBeSaved={this.canBeSaved()}
                        />
                    </div>
                } >
                <form className={classes.ediitForm}>
                    {this.renderTextField("name", "qualityCatalogs.nonConformityName")}
                    {this.renderTextField("name_english", "qualityCatalogs.nonConformityNameEnglish")}
                    {this.renderTextField("name_portuguese", "qualityCatalogs.nonConformityNamePortuguese")}
                </form>
            </Dialog>
        );
    }
}

DialogEditNonConformity.propTypes = {
    showDialog: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onSaveNonConformity: PropTypes.func.isRequired,
    onCloseCancel: PropTypes.func.isRequired,
    nonConformity: PropTypes.object.isRequired
};

export default DialogEditNonConformity;