export const newCompanyDataFersa = (company, relesase) => {
	let newCompany = {
		logo: company ? company.logo : '',
		name: relesase ? relesase.companyName : '',
		address: relesase ? relesase.companyAddress + ',' + relesase.companyCity + ',' + relesase.companyRegion : '',
		zip_code: relesase ? relesase.companyPostalCode : '',
		rfc: company ? company.rfc : '',
	}
	return newCompany
}
