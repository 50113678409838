import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import LoadingPage from '../../common/LoadingPage'
import ToolbarDialog from '../../common/ToolbarDialog'
import Toaster from '../../common/Toaster'
import FormatViewUser from './FormatViewUser'
import SearchInput from '../../common/SearchInput/SearchInput'
import DataTable from '../../common/DataTable'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import LockIcon from '@material-ui/icons/LockTwoTone'
import UnLockIcon from '@material-ui/icons/LockOpen'
import Tooltip from '@material-ui/core/Tooltip'
import AddUserIcon from '@material-ui/icons/AddTwoTone'
import UsersLockIcon from '@material-ui/icons/PermIdentityTwoTone'
import UsersIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import { IconButton } from '@material-ui/core'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'
import AddCommonItem from '../../common/AddCommonItem'

/** Import section actions */
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import {
	loadUsersSupplierFromBackEnd,
	createUserData,
	getExternalUserCount,
	setLockoutEnabledExternal,
	CHANGE_EXTERNAL_USERS_SORT_CRITERIA,
	CHANGE_EXTERNAL_USERS_QUERY,
} from '../../../store/suppliers/ExternalUserActions'
import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER } from '../../../store/helpers/StatusColorConstants'

/**
 * ExternalUserIndex view
 *
 * @class ExternalUserIndex
 * @extends {Component}
 */
class ExternalUserIndex extends Component {
	/**
	 * Create an instance of ExternalUserIndex
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showUsers: true,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			openEnableUser: false,
			openUnlockedUser: false,
			userEnabled: true,
			userLockId: '',
			createUser: {
				number_supplier: '',
				user_name: '',
				full_name: '',
				email: '',
			},
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadExterbalUsers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.state.userEnabled
			)
			this.props.getUsersCount()

			let createUser = { ...this.state.createUser }
			createUser['number_supplier'] = this.props.currentUser.profile.company_code + '-'
			this.setState({
				createUser: createUser,
			})
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoadingUser !== this.props.isLoadingUser && this.props.isLoadingUser === false) {
			this.props.loadExterbalUsers()
			this.props.getUsersCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery
		) {
			this.props.loadExterbalUsers(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.state.userEnabled
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Method to created user
	 */
	setShowToCreateUser(open) {
		this.setState({
			showUsers: open,
			showToaster: false,
			createUser: {
				number_supplier: this.props.currentUser.profile.company_code + '-',
				user_name: '',
				full_name: '',
				email: '',
			},
		})
	}

	/**
	 * Update the property of the user
	 *
	 * @param {*} user
	 * @param {*} value
	 * @memberof View
	 */
	updateProperty(property, value) {
		let createUser = { ...this.state.createUser }
		createUser[property] = value
		this.setState({
			createUser: createUser,
		})
	}

	/**
	 * Executevalidations user data validation
	 */
	validateData() {
		const { createUser } = this.state
		let validations = []
		if (createUser) {
			if (IsNullOrEmpty(createUser.user_name)) {
				validations.push({
					key: 'user_name',
				})
			} else if (createUser.user_name.length < 5) {
				validations.push({
					key: 'user_name',
				})
			}
			if (IsNullOrEmpty(createUser.full_name)) {
				validations.push({
					key: 'full_name',
				})
			}
			if (IsNullOrEmpty(createUser.email)) {
				validations.push({
					key: 'email',
				})
			}
			let regexEmail =
				/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			if (!regexEmail.test(createUser.email)) {
				validations.push({
					key: 'email',
				})
			}
		}
		return validations
	}

	/**
	 * Action to can be update information serie
	 */
	canBeSaved() {
		let validations = []
		validations = this.validateData()
		if (this.props.isSavingUser) return false
		else return validations.length === 0
	}

	/**
	 * Action to save company
	 */
	onCreateUser() {
		this.props
			.createUserData(this.state.createUser)
			.then(() => {
				this.setState({
					showUsers: true,
					createUser: {
						number_supplier: this.props.currentUser.profile.company_code + '-',
						user_name: '',
						full_name: '',
						email: '',
					},
					showToaster: true,
					toasterMessage: <Translate id='common.addCorrectly' />,
					toasterVariant: 'success',
				})
			})
			.catch((response) => {
				if (response.status === 400) {
					this.setState({
						showToaster: true,
						toasterMessage: response.data.Message,
						toasterVariant: 'error',
					})
				} else {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToAdd' />,
						toasterVariant: 'error',
					})
				}
			})
	}

	/**
	 * onEnableUser
	 */
	onEnableUser(userLockId) {
		this.setState({
			openEnableUser: true,
			userLockId: userLockId,
		})
	}

	/**
	 * Function on block user
	 */
	onBlockUser(comments) {
		this.props
			.setLockoutEnabledExternal(this.state.userLockId, false, comments)
			.then(() => {
				this.setState({
					openEnableUser: false,
					userLockId: '',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * onUnLockedUser
	 */
	onUnLockedUser(userLockId) {
		this.setState({
			openUnlockedUser: true,
			userLockId: userLockId,
		})
	}

	/**
	 * Function on unloked user
	 * @param {*} comments
	 */
	onUnlockedUser(comments) {
		this.props
			.setLockoutEnabledExternal(this.state.userLockId, true, comments)
			.then(() => {
				this.setState({
					openUnlockedUser: false,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		if (dataSource === 'user_name') {
			return (
				<React.Fragment key={item.id}>
					<span
						style={{ zIndex: '990', cursor: 'pointer' }}
						onClick={() => {
							this.props.history.push(`/externalusers/profile/${item.id}`)
						}}
					>
						{item.user_name}
					</span>
				</React.Fragment>
			)
		}
		if (dataSource === 'full_name') {
			return (
				<React.Fragment key={item.id}>
					<span
						style={{ zIndex: '990', cursor: 'pointer' }}
						onClick={() => {
							this.props.history.push(`/externalusers/profile/${item.id}`)
						}}
					>
						{item.full_name}
					</span>
				</React.Fragment>
			)
		}
		if (dataSource === 'email') {
			return (
				<React.Fragment key={item.id}>
					<span
						style={{ zIndex: '990', cursor: 'pointer' }}
						onClick={() => {
							this.props.history.push(`/externalusers/profile/${item.id}`)
						}}
					>
						{item.email}
					</span>
				</React.Fragment>
			)
		}
		if (dataSource === 'avatar_url') {
			if (item.avatar_url) {
				let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${item.avatar}`
				return (
					<Tooltip title={<Translate id={item.enabled ? 'users.enabled' : 'users.disabled'} />}>
						<Avatar
							src={urlImage}
							className={item.enabled ? classes.avatar : ''}
							data-cy='user-avatar'
							style={{ backgroundColor: item.enabled ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER }}
						/>
					</Tooltip>
				)
			} else {
				return (
					<Tooltip title={<Translate id={item.enabled ? 'users.enabled' : 'users.disabled'} />}>
						<Avatar
							data-cy='user-avatar'
							className={item.enabled ? classes.avatar : ''}
							style={{ backgroundColor: item.enabled ? COLOR_STATUS_SUCCESS : COLOR_STATUS_DANGER }}
						>
							<PersonIcon />
						</Avatar>
					</Tooltip>
				)
			}
		}
		if (dataSource === 'enabled') {
			if (item.enabled && item.id !== this.props.currentUser.profile.sub) {
				return (
					<Tooltip title={<Translate id='users.lock' />}>
						<LockIcon
							onClick={() => {
								this.onEnableUser(item.id)
							}}
							style={{ zIndex: '990', cursor: 'pointer' }}
							color='primary'
							className={classes.icon}
						/>
					</Tooltip>
				)
			} else if (!item.enabled && item.id !== this.props.currentUser.profile.sub) {
				return (
					<Tooltip title={<Translate id='users.unlockUser' />}>
						<UnLockIcon
							onClick={() => {
								this.onUnLockedUser(item.id)
							}}
							style={{ zIndex: '990', cursor: 'pointer' }}
							color='secondary'
							className={classes.icon}
						/>
					</Tooltip>
				)
			} else return '-'
		}
	}

	/**
	 * Switch view between user locks
	 *
	 * @memberof toggleViewUserLock
	 */
	toggleViewUserLock() {
		this.setState({ userEnabled: !this.state.userEnabled })
		this.props.loadExterbalUsers(
			this.props.searchQuery,
			this.props.sortCriteria.by,
			this.props.sortCriteria.ascending,
			!this.state.userEnabled
		)
	}

	/**
	 * Get users counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		const { classes } = this.props
		if (this.props.externalUsers) {
			let totalUsers = this.props.externalUsers.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span className={classes.showingCounter}>{totalUsers}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.usersCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingUsers === true) {
			return <LoadingPage />
		} else if (this.props.externalUsers === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.externalUsers}
					configuration={TableConfiguration}
					onRenderCellItem={this.onRenderCellItem}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					showFilters
				/>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof ExternalUserIndex
	 */
	render() {
		const { classes } = this.props
		if (this.state.showUsers) {
			return (
				<Card>
					<CardHeader
						avatar={
							<Avatar>
								<UsersIcon />
							</Avatar>
						}
						action={
							<div className={classes.actionsToolbar}>
								<SearchInput
									className={classes.searchInput}
									onChangeSearchValue={this.onChangeSearchValue}
									value={this.props.searchQuery}
								/>
								<Fab
									size='small'
									color='primary'
									onClick={() => {
										this.setShowToCreateUser(false)
									}}
								>
									<AddUserIcon />
								</Fab>

								<Tooltip
									title={
										<Translate
											id={this.state.userEnabled ? 'users.usersBlock' : 'users.usersUnlock'}
										/>
									}
								>
									<IconButton
										onClick={() => {
											this.toggleViewUserLock()
										}}
									>
										{this.state.userEnabled ? <UsersLockIcon /> : <UsersIcon color='primary' />}
									</IconButton>
								</Tooltip>
							</div>
						}
						title={
							<Typography variant='button'>
								<Translate id='users.users' />{' '}
							</Typography>
						}
						subheader={this.getCounterMessage()}
					/>
					<CardContent>{this.getCardContent()}</CardContent>

					{/** Dialog to Block user */}
					<AddCommonItem
						title={<Translate id='users.blockUser' />}
						message={<Translate id='users.messageToBlock' />}
						open={this.state.openEnableUser}
						onClose={() => {
							this.setState({ openEnableUser: false })
						}}
						onSaveItem={this.onBlockUser}
						isSaving={this.props.isSaving}
						isTextArea={true}
					/>

					{/** Dialog to Unlocked user */}
					<AddCommonItem
						title={<Translate id='users.unlockUser' />}
						message={<Translate id='users.messageTounlockUser' />}
						open={this.state.openUnlockedUser}
						onClose={() => {
							this.setState({ openUnlockedUser: false })
						}}
						onSaveItem={this.onUnlockedUser}
						isSaving={this.props.isSaving}
						isMessage={true}
					/>
				</Card>
			)
		} else {
			return (
				<Paper>
					{!this.state.showUsers && (
						<div>
							<ToolbarDialog
								isUser={true}
								title={<Translate id='users.informationUser' />}
								canBeEdit={false}
							/>

							<FormatViewUser
								user={this.state.createUser}
								setViewInEditMode={this.setShowToCreateUser}
								showToView={this.state.showUsers}
								createUser={true}
								updateProperty={this.updateProperty}
								errors={this.validateData()}
								canBeSaved={this.canBeSaved()}
								onSave={this.onCreateUser}
							/>
						</div>
					)}

					<Toaster
						message={this.state.toasterMessage}
						open={this.state.showToaster}
						variant={this.state.toasterVariant}
						onClose={() => {
							this.setState({ showToaster: false })
						}}
					/>
				</Paper>
			)
		}
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='users.nameUser' />,
			dataSource: 'user_name',
		},
		{
			header: <Translate id='users.name' />,
			dataSource: 'full_name',
			isSortable: true,
		},
		{
			header: <Translate id='users.email' />,
			dataSource: 'email',
		},
		{
			header: '',
			dataSource: 'avatar_url',
		},
		{
			header: '',
			dataSource: 'enabled',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	return {
		currentUser: user,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingUsers: state.externalUsers.get('isLoadingUsers'),
		externalUsers: state.externalUsers.get('externalUsers')
			? state.externalUsers.get('externalUsers').toJS()
			: null,
		isSavingUser: state.externalUsers.get('isSavingUser'),
		sortCriteria: state.externalUsers.get('sortCriteria') ? state.externalUsers.get('sortCriteria').toJS() : null,
		searchQuery: state.externalUsers.get('searchQuery'),
		usersCount: state.externalUsers.get('externalUsersCount'),
		isSaving: state.externalUsers.get('isSavingUser'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		createUserData: (createUser) => {
			return dispatch(createUserData(createUser))
		},
		loadExterbalUsers: (query, orderBy, sortAscending, userEnabled) => {
			dispatch(loadUsersSupplierFromBackEnd(query, orderBy, sortAscending, userEnabled))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_EXTERNAL_USERS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_EXTERNAL_USERS_QUERY,
				query: query,
			})
		},
		getUsersCount: () => {
			dispatch(getExternalUserCount())
		},
		setLockoutEnabledExternal: (userId, enabled, comments) => {
			return dispatch(setLockoutEnabledExternal(userId, enabled, comments, true))
		},
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ExternalUserIndex)))
)
