import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import SettingsIcon from '@material-ui/icons/Settings'
import AttachIcon from '@material-ui/icons/AttachFile'
import Button from '@material-ui/core/Button'

import OptionsMenu from '../../common/OptionsMenu'
import FilesLinks from '../../common/FilesLinks'
import DataTable from '../../common/DataTable'

/** Import actions */
import { GetChangeNoticeType } from '../../../store/helpers/SelectOptions'
import { COMPANY_IDENTIFIER_IMBERA_REPARE, COMPANY_IDENTIFIER_IMBERA_MX } from '../../../store/helpers/ResourcesHelper'
import { COLOR_STATUS_REJECTED } from '../../../store/helpers/StatusColorConstants'

class NoticeChangeDetails extends Component {
	/**
	 * Create an instance of Internal user
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showspecPlano: false,
			planeToChange: null,
			revisionToChange: null,
			selectedPlaneValue: null,
			selectedDetailPlaneToken: null,
			showDeletePlaneName: false,
			selectedTokens: [],
		}
	}

	onDeleteDetailPlane(item) {
		this.setState({
			showDeletePlaneName: true,
			revisionToChange: item.revise,
			planeToChange: item.plot_name,
			selectedDetailPlaneToken: item.token,
		})
	}

	/**
	 * On change change notice type
	 *
	 * @param {*} option
	 * @memberof NoticeChangeDetails
	 */
	onChangeNoticeType(option, partNumberToken) {
		this.setState({
			changeNoticeType: option.value,
		})
		this.props.updateStage(partNumberToken, option.value)
	}

	/**
	 * Handle state to set token of item to show options menu list
	 *
	 * @param {*} itemToken
	 * @memberof NoticeChangeDetails
	 */
	handleClickOptionsMenu(itemToken) {
		this.setState({
			itemToken,
		})
	}

	/**
	 * Handle state to show or hide menu options list
	 *
	 * @memberof NoticeChangeDetails
	 */
	handleCloseOptionsMenu() {
		if (!this.state.showNoticeItemDialog || !this.state.showAttachmentsDialog) {
			this.setState((state) => ({
				itemToken: '',
			}))
		}
	}

	/**
	 * Method to render OptionsMenu
	 *
	 * @param {*} item
	 * @returns
	 * @memberof NoticeChangeDetails
	 */
	renderOptionsMenu(item, isMassive) {
		var options = []
		options.push({
			itemClick: () => this.props.onMenuOptionItemClick('showNoticeItemDialog', item, this.state.selectedTokens),
			tooltipTranslation: <Translate id='common.addInformation' />,
			menuItemIcon: <SettingsIcon color='secondary' />,
		})
		!isMassive &&
			options.push({
				itemClick: () => this.props.onMenuOptionItemClick('showAttachmentsDialog', item),
				tooltipTranslation: <Translate id='common.addAttachments' />,
				menuItemIcon: <AttachIcon color='secondary' />,
			})

		return (
			<OptionsMenu
				itemToken={item.token}
				handleClickOptions={this.handleClickOptionsMenu}
				open={this.state.itemToken === item.token}
				handleClickAway={this.handleCloseOptionsMenu}
				options={options}
				xsSize
			/>
		)
	}

	/**
	 * Action to is actiove or deactive item
	 * @param {*} itemToken
	 */
	onToggleNoticeOfChangePartNumberActivation(itemToken) {
		this.props.toggleNoticeOfChangePartNumberActivation(itemToken)
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		let options = GetChangeNoticeType()
		let noticeCreate = this.props.noticechange
		let kind = 1

		if (dataSource === 'material') {
			return (
				<List>
					<ListItem>
						<ListItemText
							primary={
								<React.Fragment>
									<Typography variant='caption'>
										{item.part_number}
										{' | '}
										{item.part_description}
										<br />
										{<Translate id='common.schema' />}
										{': '}
										{item.plano_number}
										{' | '}
										{<Translate id='officialNotices.revision' />}
										{': '}
										{item.review} <br />
										{<Translate id='noticeChanges.changes_to_make' />}
										{': '}
										{item.changes_to_make}
									</Typography>
								</React.Fragment>
							}
						/>
					</ListItem>
				</List>
			)
		}
		if (dataSource === 'change_type') {
			if (
				noticeCreate.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
				noticeCreate.company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE
			) {
				if (item.is_hidden) {
					return (
						<Button
							variant='contained'
							onClick={() => this.onToggleNoticeOfChangePartNumberActivation(item.token)}
							style={{ fontSize: '.8em' }}
							color='primary'
							size='small'
							className={classes.button}
							disabled={this.props.isSaving}
						>
							{<Translate id='noticeChanges.activated' />}
						</Button>
					)
				} else if (noticeCreate.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX) {
					return (
						<React.Fragment>
							<Select
								value={options[String(item.change_type)]}
								className={classes.onChangeNoticeType}
								options={options}
								onChange={(event) => this.onChangeNoticeType(event, item.token)}
								styles={{
									container: (base) => ({ ...base, minWidth: 100 }),
									menu: (base) => ({ ...base, zIndex: 10 }),
								}}
								menuPortalTarget={document.body}
							/>
							<Button
								variant='contained'
								onClick={() => this.onToggleNoticeOfChangePartNumberActivation(item.token)}
								style={{ fontSize: '.8em' }}
								color='secondary'
								size='small'
								className={classes.button}
								disabled={this.props.isSaving}
							>
								{<Translate id='noticeChanges.deactivate' />}
							</Button>
						</React.Fragment>
					)
				}
			}
			if (noticeCreate.company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE) {
				let descriptionType = ''
				if (item.change_type !== '' && item.change_type !== 0) {
					if (options[String(item.change_type)] !== undefined)
						descriptionType = options[String(item.change_type)].label
				}
				return (
					<React.Fragment>
						{descriptionType}
						<br />
						<Button
							variant='contained'
							onClick={() => this.onToggleNoticeOfChangePartNumberActivation(item.token)}
							style={{ fontSize: '.8em', marginLeft: '-2px' }}
							color='secondary'
							size='small'
							className={classes.button}
							disabled={this.props.isSaving}
						>
							{<Translate id='noticeChanges.deactivate' />}
						</Button>
					</React.Fragment>
				)
			} else {
				return (
					<Select
						value={options[String(item.change_type)]}
						className={classes.onChangeNoticeType}
						options={options}
						onChange={(event) => this.onChangeNoticeType(event, item.token)}
						styles={{
							container: (base) => ({ ...base, minWidth: 100 }),
							menu: (base) => ({ ...base, zIndex: 10 }),
						}}
						menuPortalTarget={document.body}
					/>
				)
			}
		}
		if (dataSource === 'plot_name') {
			return (
				<FilesLinks
					item={item}
					hasPlot
					companyToken={this.props.noticechange.company_token}
					companyCode={this.props.noticechange.company_identifier}
					kind={kind}
					downloadFile={this.props.downloadPlotFiles}
				/>
			)
		}
		if (dataSource === 'options') {
			return this.state.selectedTokens.length <= 0 ? this.renderOptionsMenu(item) : ''
		} else if (dataSource === 'suppliers') {
			if (item.quotes && item.quotes.length) {
				if (item.quotes.length === 1) {
					return (
						<Typography noWrap variant='overline' gutterBottom>
							{
								<React.Fragment>
									{item.quotes[0].supplier_code} | {item.quotes[0].supplier_name}{' '}
								</React.Fragment>
							}
						</Typography>
					)
				} else {
					return (
						<Badge
							color='primary'
							badgeContent={`+${item.quotes.length - 1}`}
							className={classes.supplierBadge}
						>
							<Typography noWrap variant='overline' gutterBottom>
								{
									<React.Fragment>
										{item.quotes[0].supplier_code} | {item.quotes[0].supplier_name}{' '}
									</React.Fragment>
								}
							</Typography>
						</Badge>
					)
				}
			} else {
				return (
					<span>
						<Translate id='common.noSupplier' />
					</span>
				)
			}
		}
	}

	/**
	 * Handle change plane
	 */
	handleChange = (event) => {
		this.setState({ selectedPlaneValue: event.target.value })
	}

	onSelectedChecks(selectedTokens) {
		this.setState({ selectedTokens })
	}

	/**
	 * Set Custom background color foreach action
	 * @memberof ActionsList
	 *
	 */
	setCustomColorRow() {
		this.props.noticechange.details &&
			this.props.noticechange.details.map((item) => {
				var customColor
				if (item.is_hidden) customColor = COLOR_STATUS_REJECTED
				return (item.custom_color = customColor)
			})
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof NoticeChangeDetails
	 */
	render() {
		let noticeCreate = this.props.noticechange
		let showSelectChecks = true

		if (
			noticeCreate &&
			(noticeCreate.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
				noticeCreate.company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE)
		) {
			this.setCustomColorRow()
		}
		return (
			<Paper style={{ marginBottom: '5rem' }}>
				<Grid container spacing={0} justify='flex-end'>
					{showSelectChecks && this.state.selectedTokens.length > 0 && (
						<Grid item xs={0.5}>
							{this.renderOptionsMenu({ token: 'selection' }, true)}
						</Grid>
					)}
				</Grid>
				<DataTable
					title={<Translate id='noticeChanges.materials' />}
					data={this.props.noticechange.details}
					configuration={TableConfiguration(showSelectChecks)}
					onRenderCellItem={this.onRenderCellItem}
					onSelectedCheck={(selectedTokens) => {
						this.onSelectedChecks(selectedTokens)
					}}
					selectedTokens={this.state.selectedTokens}
				/>
			</Paper>
		)
	}
}

function TableConfiguration(showCheckSelect) {
	let columns = [
		{
			header: <Translate id='noticeChanges.material' />,
			dataSource: 'material',
		},
		{
			header: <Translate id='noticeChanges.stage' />,
			dataSource: 'change_type',
		},
		{
			header: <Translate id='noticeChanges.assignedTo' />,
			dataSource: 'assigned_development_name',
		},
		{
			header: <Translate id='noticeChanges.plotNumber' />,
			dataSource: 'plot_name',
		},
		{
			header: <Translate id='common.comments' />,
			dataSource: 'comments',
		},
		{
			header: <Translate id='menu.suppliers' />,
			dataSource: 'suppliers',
		},
		{
			header: '',
			dataSource: 'options',
		},
	]
	if (showCheckSelect)
		columns.push({
			header: '',
			dataSource: 'selectCheck',
		})
	return { columns }
}

export default NoticeChangeDetails
