/** components imports section */
import React from 'react'
import { Translate } from 'react-localize-redux'
/** constants imports section */
import {
	COLOR_STATUS_DANGER,
	COLOR_STATUS_GRAY,
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_WARNING,
} from './StatusColorConstants'

export const descriptionGuidelineStatus = (status) => {
	switch (status) {
		case 0:
			return <Translate id='specificationsSection.guidelines.status.draft' />
		case 1:
			return <Translate id='specificationsSection.guidelines.status.inAuthorization' />
		case 2:
			return <Translate id='specificationsSection.guidelines.status.authorized' />
		case 3:
			return <Translate id='specificationsSection.guidelines.status.inProgress' />
		default:
			return ''
	}
}

export const specificationStatus = {
	draft: 0,
	inAuthorization: 1,
	authorized: 2,
	inProgress: 3,
}

export const backgroundColorGuidelineStatus = (status) => {
	switch (status) {
		case 0:
			return 'secondary'
		case 1:
			return 'warning'
		case 2:
			return 'success'
		case 3:
			return 'warning'
		default:
			return ''
	}
}

export const colorFolioGuidelineStatus = (status) => {
	switch (status) {
		case 0:
			return COLOR_STATUS_GRAY
		case 1:
			return COLOR_STATUS_WARNING
		case 2:
			return COLOR_STATUS_SUCCESS
		case 3:
			return COLOR_STATUS_WARNING
		default:
			return null
	}
}

export const descriptionDocumentStatus = (status) => {
	switch (status) {
		case 1:
			return <Translate id='specificationsSection.guidelines.status.inProgress' />
		case 2:
			return <Translate id='specificationsSection.guidelines.status.authorized' />
		case 3:
			return <Translate id='specificationsSection.guidelines.status.refused' />
		default:
			return ''
	}
}

export const backgroundColorDocumentStatus = (status) => {
	switch (status) {
		case 1:
			return COLOR_STATUS_WARNING
		case 2:
			return COLOR_STATUS_SUCCESS
		case 3:
			return COLOR_STATUS_DANGER
		default:
			return COLOR_STATUS_GRAY
	}
}
