/** Import ReactJS */
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import { Card,
	Grid,
	Divider,
	Typography,
	Tooltip,
	CardHeader,
	CardContent,
	Avatar,
	IconButton,
	ListItemSecondaryAction,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemIcon
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import SendIcon from '@material-ui/icons/Send'

//import components section
import Evidence from '../../../common/Evidence'
import ConfirmPasswordDialog from '../../../common/ConfirmPasswordDialog'

/**Import resources section */
import {
	BACKGROUND_COLOR_GRAY,
	COLOR_STATUS_ALERT_INFO,
	COLOR_CART_PORT,
} from '../../../../store/helpers/StatusColorConstants'
import { FactoringStatus, FilesFolderName } from '../../../../store/helpers/AppConstants'
import { IsUserInRole, ROLE_FACTORING_AFFILIATION_ADMINISTRATOR } from '../../../../store/helpers/RolesHelper'

const Files = (props) => {
	const { classes } = props
	const { factoring, activeStep } = props
	//bool props section
	const { isSaving, isInternalUser, canBeEditInformation } = props
	//function props section
	const { onAddAttachmets, onDeleteFactoringsFiles, onDownloadFactoringFile,onUpdateFactoring } = props

	//Array props section
	const { userRolesByCompany } = props
	let isUserAdministrator = IsUserInRole(ROLE_FACTORING_AFFILIATION_ADMINISTRATOR, userRolesByCompany)

	const [confirmPasswordConfigModal, setConfirmPasswordConfigModal] = useState({
		opened: false,
		item: null,
	})

	const onConfirmPassword = (item) => {
		setConfirmPasswordConfigModal({
			opened: !confirmPasswordConfigModal.opened,
			item: item,
		})
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<Card className={classes.card}>
					<CardHeader
						className={classes.cardHeader}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={
							'A continuación se muestran todos los archivos que se han agregado para este proceso de factoraje'
						}
					/>
					<Divider />
					<CardContent>
						<Grid container>
							{isInternalUser && canBeEditInformation && isUserAdministrator && (
								<Grid item xs={12}>
									<Evidence
										files={[]}
										maxFiles={1}
										uploadFiles={(files) =>
											onAddAttachmets(files, FilesFolderName.FactoringAffiliationFiles)
										}
									/>
								</Grid>
							)}
							<Grid item xs={12}>
								<List>
									{factoring &&
										factoring.attachments.map((file) => {
											return (
												<>
													<ListItem className={classes.fileItem}>
														<ListItemAvatar>
															<Avatar>
																<AttachFileIcon />
															</Avatar>
														</ListItemAvatar>
														<ListItemText primary={file.name} />
														<ListItemSecondaryAction>
															<IconButton
																aria-label='download'
																disabled={isSaving}
																onClick={() =>
																	onDownloadFactoringFile(
																		file.name,
																		FilesFolderName.FactoringAffiliationFiles
																	)
																}
															>
																<CloudDownloadIcon />
															</IconButton>
															{isInternalUser &&
																canBeEditInformation &&
																isUserAdministrator && (
																	<IconButton
																		aria-label='Delete'
																		disabled={isSaving}
																		onClick={() =>
																			onDeleteFactoringsFiles(
																				file.name,
																				FilesFolderName.FactoringAffiliationFiles
																			)
																		}
																	>
																		<DeleteIcon />
																	</IconButton>
																)}
														</ListItemSecondaryAction>
													</ListItem>
													<Divider />
												</>
											)
										})}
									{factoring && factoring.attachments.length === 0 && (
										<Typography>No existen archivos cargados hasta el momento</Typography>
									)}
								</List>
							</Grid>
						</Grid>
					</CardContent>
					<Divider />
					{isInternalUser &&	factoring &&
					factoring.FactoringStatus === FactoringStatus.IN_PROCESS && (
					<List component='nav'>
						<ListItem>
							<ListItemIcon>
								<SendIcon color='primary' />
							</ListItemIcon>
							<ListItemText
								inset
								primary={
									<Typography className={classes.textTitle}>
										Enviar información capturada al proveedor
									</Typography>
								}
							/>
							{isUserAdministrator && (
								<ListItemSecondaryAction>
									<Tooltip title={'Enviar a proveedor'}>
										<IconButton
											onClick={onConfirmPassword}
											disabled={isSaving}
										>
											<SendIcon color='primary' />
										</IconButton>
									</Tooltip>
								</ListItemSecondaryAction>
							)}
						</ListItem>
					</List>
				)}
				<Divider />
				</Card>
			</Grid>

			{confirmPasswordConfigModal && confirmPasswordConfigModal.opened && (
				<ConfirmPasswordDialog
					open={confirmPasswordConfigModal.opened}
					title={<Translate id='common.confirmOperation' />}
					message1={'Se compartirá la información cargada con el proveedor seleccionado'}
					message2={<Translate id='common.enterPassword' />}
					onClickButtonConfirm={(comment) =>
						onUpdateFactoring(
							activeStep,
							factoring.Currencies,
							factoring ? factoring.PaymentConditions : '-',
							factoring ? factoring.AnnualPurchase : '_',
							false,
							factoring ? factoring.Comments : '_'
						).then((_) => {
							onConfirmPassword(null)
						})
					}
					onClose={onConfirmPassword}
					isSaving={isSaving}
					isVisibleComments={false}
					commentRequired={false}
				/>
			)}
		</Grid>
	)
}

const styles = (theme) => ({
	card: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	cardHeader: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
	},
	fileItem: {
		backgroundColor: COLOR_CART_PORT,
		marginTop: '10px',
	},
	textTitle: {
		paddingBottom: '10px',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(Files))
