/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
/** Material-UI imports section */
import { withStyles, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
//Import compinents section
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'

import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import DatePicker from '../../common/DateTimePicker/DatePicker'
import { DownloadType } from '../../../store/helpers/AppConstants'

const DownloadAcknowledgmentFiles = (props) => {
	/**props section */
	const { classes } = props

	const [selectedValue, setSelectedValue] = React.useState('All')
	const [supplierCodes, setSupplierCodes] = React.useState('')
	const [dateConfigurationModel, setDateConfigurationModelModel] = React.useState({
		endDate: null,
		startDate: null,
	})
	const canBeSaved = () => {
		if (IsNullOrEmpty(supplierCodes)) return false
		return true
	}

	const canBeSavedDates = () => {
		if (dateConfigurationModel.endDate === null) return false
		if (dateConfigurationModel.startDate === null) return false
		return true
	}

	const handleChange = (event) => {
		setSupplierCodes('')
		setSelectedValue(event.target.value)
	}
	const splitLines = (str) => (IsNullOrEmpty(str) ? [] : str.split(/\r?\n/))
	const filterCodesEmpty = (arrayCodes) => arrayCodes.filter((d) => !IsNullOrEmpty(d))

	const onPropertyChange = (event) => {
		setSupplierCodes(event.target.value)
	}
	const onSaveButtonClick = () => {
		let allCodes = filterCodesEmpty(splitLines(supplierCodes))
		if (props.onDownloadAllAcknowledgmentZipFile) {
			props.onDownloadAllAcknowledgmentZipFile(allCodes, dateConfigurationModel, selectedValue)
		}
	}

	const onChangeDate = (property, data) => {
		if (property === 'startDate') {
			setDateConfigurationModelModel({
				...dateConfigurationModel,
				[property]: data,
				endDate: null,
			})
		} else {
			setDateConfigurationModelModel({
				...dateConfigurationModel,
				[property]: data,
			})
		}
	}

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			header={
				<Toolbar>
					<Typography variant='h6'>Descagar archivos de acuses de recibo</Typography>
				</Toolbar>
			}
			actions={
				<DialogActions
					view={SHOW_EDIT_VIEW}
					classes={classes}
					onCancel={props.onClose}
					onClose={props.onClose}
					onSaveItem={onSaveButtonClick}
					isSaving={props.isSaving}
					canBeSaved={
						 selectedValue !== DownloadType.ALL
							? canBeSaved() && canBeSavedDates()
							: canBeSavedDates()
					}
				/>
			}
		>
			<Grid container>
				<Grid item xs={12}>
					<FormControl component='fieldset'>
						<FormLabel component='legend'>Selecciona la opción deseada</FormLabel>
						<RadioGroup row aria-label='position' name='position' defaultValue='All'>
							<FormControlLabel
								value={DownloadType.ALL}
								checked={selectedValue === DownloadType.ALL}
								control={<Radio color='primary' />}
								label='Todo'
								labelPlacement='All'
								onChange={handleChange}
							/>
							<FormControlLabel
								value={DownloadType.BY_SUPPLIER}
								checked={selectedValue === DownloadType.BY_SUPPLIER}
								control={<Radio color='primary' />}
								label='Por proveedor'
								labelPlacement={DownloadType.BY_SUPPLIER}
								onChange={handleChange}
							/>
							<FormControlLabel
								value={DownloadType.BY_DESTINATION_CLIENT}
								checked={selectedValue === DownloadType.BY_DESTINATION_CLIENT}
								control={<Radio color='primary' />}
								label='Por código destinatarios'
								labelPlacement={DownloadType.BY_DESTINATION_CLIENT}
								onChange={handleChange}
							/>
							<FormControlLabel
								value={DownloadType.BY_RFC}
								checked={selectedValue === DownloadType.BY_RFC}
								control={<Radio color='primary' />}
								label='Por RFC cliente'
								labelPlacement={DownloadType.BY_RFC}
								onChange={handleChange}
							/>
						</RadioGroup>
					</FormControl>
				</Grid>
				{selectedValue === DownloadType.BY_SUPPLIER && (
					<Grid item xs={12}>
						<TextField
							fullWidth
							label={'Escribe el código de los proveedores'}
							name='name'
							onChange={onPropertyChange}
							margin='normal'
							variant='outlined'
							rows={3}
							rowsMax={4}
							multiline
							helperText='Ejemplo: 123,21232,3245,5323'
							// value={binddingModel ? binddingModel.name : ''}
						/>
					</Grid>
				)}

				{selectedValue === DownloadType.BY_RFC && (
					<Grid item xs={12}>
						<TextField
							fullWidth
							label={'Escribe los RFC de los clientes'}
							name='name'
							onChange={onPropertyChange}
							margin='normal'
							variant='outlined'
							rows={3}
							rowsMax={4}
							multiline
							helperText='Ejemplo: VECJ880326, VECJ880327, VECJ880328'
							// value={binddingModel ? binddingModel.name : ''}
						/>
					</Grid>
				)}

				{selectedValue === DownloadType.BY_DESTINATION_CLIENT && (
					<Grid item xs={12}>
						<TextField
							fullWidth
							label={'Escribe el código de los destinatarios'}
							name='name'
							onChange={onPropertyChange}
							margin='normal'
							variant='outlined'
							rows={3}
							rowsMax={4}
							multiline
							helperText='Ejemplo: 123,21232,3245,5323'
							// value={binddingModel ? binddingModel.name : ''}
						/>
					</Grid>
				)}

				<Grid item xs={6}>
					<Typography variant='subtitle2' color='primary' className={classes.titleText}>
						Fecha de inicio de periodo
					</Typography>
					<DatePicker
						opens={'left'}
						onChangeRange={(event) => onChangeDate('startDate', event)}
						startDate={dateConfigurationModel.startDate ? moment(dateConfigurationModel.startDate) : ''}
					/>
				</Grid>

				<Grid item xs={6}>
					<Typography variant='subtitle2' color='primary' className={classes.titleText}>
						Fecha fin de periodo
					</Typography>
					<DatePicker
						opens={'left'}
						onChangeRange={(event) => onChangeDate('endDate', event)}
						startDate={
							dateConfigurationModel.endDate ? moment(dateConfigurationModel.endDate, 'DD/MM/YYYY') : ''
						}
					/>
				</Grid>
			</Grid>
		</Dialog>
	)
}

const styles = (theme) => ({})

DownloadAcknowledgmentFiles.propTypes = {
	open: propTypes.bool.isRequired,
}

DownloadAcknowledgmentFiles.defaultProps = {
	open: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(DownloadAcknowledgmentFiles))
