import { fromJS, Map } from 'immutable';

import * as insurancePaymentsActions from './SocialInsurancePaymentsAction';

/** Initial state */
export const socialInsurancePaymentsInitialState = fromJS({
    isLoadingSocialInsurancePayments: true,
    socialInsurancePayments: [],
    isLoadingSocialInsurancePayment: false,
    socialInsurancePayment: null,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    socialInsurancePaymentsCount: null,
    isSavingSocialInsurancePayment: false,
})

export default function socialInsurancePaymentsReducer(state = socialInsurancePaymentsInitialState, action) {
    switch (action.type) {
        case insurancePaymentsActions.IS_LOADING_SOCIAL_INSURANCE_PAYMENTS: {
            return state.merge({
                isLoadingSocialInsurancePayments: action.isLoadingSocialInsurancePayments
            })
        }
        case insurancePaymentsActions.SET_SOCIAL_INSURANCE_PAYMENTS_LIST: {
            let socialInsurancePaymentsLists = fromJS(action.socialInsurancePayments);
            return state.merge({
                socialInsurancePayments: socialInsurancePaymentsLists,
                isLoadingSocialInsurancePayments: false
            })
        }
        case insurancePaymentsActions.IS_LOADING_SOCIAL_INSURANCE_PAYMENT: {
            return state.merge({
                isLoadingSocialInsurancePayment: action.isLoadingSocialInsurancePayment,
            })
        }
        case insurancePaymentsActions.SET_SOCIAL_INSURANCE_PAYMENT: {
            return state.merge({
                isLoadingSocialInsurancePayment: false,
                isSavingSocialInsurancePayment: false,
                socialInsurancePayment: fromJS(action.socialInsurancePayment)
            })
        }
        case insurancePaymentsActions.CHANGE_SOCIAL_INSURANCE_PAYMENTS_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case insurancePaymentsActions.CHANGE_SOCIAL_INSURANCE_PAYMENTS_QUERY: {
            return state.merge({
                searchQuery: action.query
            })
        }
        case insurancePaymentsActions.CHANGE_SOCIAL_INSURANCE_PAYMENTS_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case insurancePaymentsActions.SET_SOCIAL_INSURANCE_PAYMENTS_COUNT: {
            return state.merge({
                socialInsurancePaymentsCount: action.count
            })
        }
        case insurancePaymentsActions.IS_SAVING_SOCIAL_INSURANCE_PAYMENT:{
            return state.merge({
                isSavingSocialInsurancePayment: action.isSavingSocialInsurancePayment
            })
        }
        case insurancePaymentsActions.ADD_SOCIAL_INSURANCE_PAYMENT: {
            let socialInsurancePayment = Map(action.socialInsurancePayment);
            let socialInsurancePaymentsList = state.get('socialInsurancePayments').push(socialInsurancePayment);
            return state.merge({
                socialInsurancePayments: socialInsurancePaymentsList,
                isSavingSocialInsurancePayment: false
            });
        }
        case insurancePaymentsActions.UPDATE_SOCIAL_INSURANCE_PAYMENT: {
            return state.merge({
                isSavingSocialInsurancePayment: action.isSavingSocialInsurancePayment
            })
        }
        case insurancePaymentsActions.ADD_EMPLOYEE_IN_PAYMENT: {
            let employee = Map(action.employee);
            let newList = state.get('socialInsurancePayment').get('contractor_employeds').push(employee);
            return state.setIn(['socialInsurancePayment', 'contractor_employeds'], fromJS(newList))
            .set('isSavingSocialInsurancePayment', false);
        }
        case insurancePaymentsActions.REMOVE_EMPLOYEE_IN_PAYMENT: {
            let oldList = state.getIn(['socialInsurancePayment', 'contractor_employeds']).toJS();
            let newList = oldList.filter(employee => {
                return employee.token !== action.employeeToken
            })
            return state.setIn(['socialInsurancePayment', 'contractor_employeds'], fromJS(newList))
            .set('isSavingSocialInsurancePayment', false);
        }
        default:
            return state;
    }
}