
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';

/**
 * Dialog to accepted, rejected or decline quote
 */
class DeclineQuoteDialog extends Component {

    /**
   *Creates an instance of DialogActions.
   * @param {*} props
   * @memberof DialogActions
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openDeclineRequest: false,
            commentsDecline: "",
        }
    }

    /**
     * Function to add comment to decline
     * @param {*} commentsDecline 
     */
    updateDeclineItemProperty(commentsDecline) {
        this.setState({
            commentsDecline: commentsDecline
        })
    }

    /**
     * onSaveDeclineQuote
     */
    onSaveDeclineQuote() {
        if (this.state.commentsDecline) {
            this.props.declineQuoteItem(this.props.quoteItemToken, this.state.commentsDecline)
                .then(() => {
                    this.setState({
                        openDeclineRequest: false,
                        showToView: true,
                        showToaster: true,
                        toasterVariant: "success",
                        toasterMessage: <Translate id="common.saveCorrectly" />,
                    },
                    ()=>{
                        this.props.onClose();
                }
                    )
                }).catch(() => {
                    this.setState({
                        showToaster: true,
                        toasterMessage: <Translate id="common.errorToSave" />,
                        toasterVariant: "error",
                    })
                });
        }
    }

    handleCloseCancel() {
        this.setState({ commentsDecline: null }, () => this.props.onClose());
    }
    /**
     * Dialog to update information
     */
    render() {

        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id="quotes.declineQuote" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={() => this.handleCloseCancel()}
                        onClose={() => this.handleCloseCancel()}
                        onSaveItem={this.onSaveDeclineQuote}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.state.commentsDecline !== ""}
                    />
                }>

                <form>
                    <TextField fullWidth
                        label={<Translate id="samplesrequest.motiveDecline"/>}
                        className={classes.textField}
                        multiline
                        rowsMax="8"
                        defaultValue={this.state.commentsDecline}
                        onChange={(event) => { this.updateDeclineItemProperty(event.target.value) }}
                        onKeyDown={this.onKeyDown}
                        margin="normal"
                        variant="outlined"
                    />
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(DeclineQuoteDialog));