import React from 'react'
/* Material UI import section */

import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import { Translate } from 'react-localize-redux'

const EmptyView = () => {
	const classes = useStyles()
	return (
		<div className={classes.content}>
			<Grid container >
				<Grid item xs={12}>
					<Typography align='center'>
						<ImportContactsIcon color='primary' />
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography align='center'>
                        <Translate id="common.emptyView" />
                    </Typography>
				</Grid>
				
			</Grid>
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	content: {
		maxHeight: `100%`,
		minHeight: `250px`,
		position: 'relative',
	},
	
}))

export default EmptyView
