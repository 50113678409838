import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

import {
	Grid,
	TextField,
	Switch,
	DialogTitle,
	DialogContent,
	DialogActions,
	Dialog,
	Button,
	Typography,
} from '@material-ui/core'

import PlotSelector from '../../common/PlotSelector'
import SpectSelector from '../../common/SpectSelector'

/**
 * ProvedureItemDialog item lateral options panels
 *
 * @class ProvedureItemDialog
 * @extends {Component}
 */
class ProvedureItemDialog extends Component {
	/**
	 *Creates an instance of NoticeItemDialog.
	 * @param {*} props
	 * @memberof ProvedureItemDialog
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			comments: this.props.item ? this.props.item.comments : null,
			requiered_plot_art: this.props.item ? this.props.item.requiered_plot_art : null,
		}
	}

	/**
	 * React life-cycle method
	 * Update status if props has change
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof ProvedureItemDialog
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.props.item !== prevProps.item) {
			this.setState({
				comments: this.props.item.comments,
				requiered_plot_art: this.props.item.requiered_plot_art,
			})
		}
	}

	/**
	 * Update property in state
	 *
	 * @param {*} property
	 * @param {*} value
	 * @memberof MemoItemDialog
	 */
	updateProperty(property, value) {
		this.setState({
			[property]: value,
		})
		if (property === 'requiered_plot_art') {
			if (this.props.onAddComment) this.props.onAddComment(this.props.item, this.state.comments, value)
		}
	}

	/**
	 * on Add comment to item
	 *
	 * @param {*} item
	 * @memberof MemoItemDialog
	 */
	onAddComment(item) {
		if (this.props.onAddComment) this.props.onAddComment(item, this.state.comments, this.state.requiered_plot_art)
	}

	/**
	 *
	 *
	 * @returns
	 * @memberof ProvedureItemDialog
	 */
	render() {
		const { item, classes, companyImbera } = this.props
		return (
			<Dialog open={this.props.show}>
				<DialogTitle id='form-dialog-title'>
					<Translate id='deliveryOrderModule.materialsTable.plot' />: {item.part_number} Rev. {item.revise}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<Typography>
								<Translate id='plotSelector.addPlots' />
							</Typography>
							<PlotSelector
								specPlano={item.plano_number}
								revision={item.revise}
								kind={this.props.kind}
								selectedPlotName={item.plot_name}
								onPlotSelected={(plotSelectd) => {
									this.props.onPlotSelected(this.props.item, plotSelectd, this.props.kind)
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography>
								<Translate id='plotSelector.addSpects' />
							</Typography>
							<SpectSelector
								specPlano={item.plano_number}
								revision={item.revise}
								selectedPlotName={item.plot_name}
								onPlotSelected={(plotSelectd) => {
									this.props.onPlotSelected(this.props.item, plotSelectd)
								}}
							/>
						</Grid>
						{companyImbera && (
							<Grid item xs={12} className={classes.itemsDetail}>
								<Typography color='primary'>
									<Translate id='plotSelector.addPlotArt' />
								</Typography>
								<Switch
									checked={this.state.requiered_plot_art}
									onChange={(event) => {
										this.updateProperty('requiered_plot_art', event.target.checked)
									}}
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<Typography>
								<Translate id='plotSelector.addComments' />
							</Typography>
							<TextField
								key={'comments'}
								fullWidth
								variant='outlined'
								multiline
								rowsMax='3'
								value={this.state['comments'] ? this.state['comments'] : ''}
								onChange={(event) => {
									this.updateProperty('comments', event.target.value)
								}}
								onBlur={() => this.onAddComment(this.props.item)}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.onClose} color='primary'>
						<Translate id='common.close' />
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = (theme) => ({
	dialogRoot: {
		minHeight: '400px',
	},
	sampleRequired: {
		color: theme.palette.primary.light,
	},
	deleteButton: {
		color: 'red',
	},
	itemsDetail: {
		display: 'flex',
		alignItems: 'center',
	},
})

export default withStyles(styles)(ProvedureItemDialog)
