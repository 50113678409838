/**Import react section */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import { withStyles, TextField, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**Import components section*/
import LoadingPage from '../../../common/LoadingPage';
import HtmlEditor from "../../../common/HtmlEditor";
import WarningComponent from "../../../common/WarningComponent";
/**Import resources section*/
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'


const CreateBaseComponent = (props) => {
    /**Bools props section */
    const { isLoading, isSaving, classes, isEdited, setIdEdited, bidding } = props
    /**Arrays props section */
    const { translate } = props
    /**Functions props section */
    const { onSaveButtonClick } = props
    const [baseModel, setBaseModel] = useState({
        Name: "",
        Content: "",
        Id: ""
    });

    /**Use effect section */
    useEffect(() => {
        if (props.editedBiddingBase) {
            setIdEdited(false)
            setBaseModel({
                Name: props.editedBiddingBase.Name,
                Content: props.editedBiddingBase.Content,
                Id: props.editedBiddingBase.Id,

            })
        }
    }, [props.editedBiddingBase])

    const onChange = (value) => {
        setBaseModel({
            ...baseModel,
            "Content": value
        })
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setBaseModel({
            ...baseModel,
            [name]: value
        });
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(baseModel)
        }
    }

    /**On change properties */
    const existBaseSomeName = () => {
        if (bidding === undefined || bidding === null)
            return false
        if (bidding.Sections.length === 0)
            return false
        if (props.mode === 'MODE_EDIT') {
            if(((props.editedBiddingBase && props.editedBiddingBase.Name) && (props.editedBiddingBase.Name !==baseModel.Name))){
                let existSection = bidding.Sections.find(x => x.Name === baseModel.Name)
                if (existSection === undefined)
                    return false
                return true
            }
            return false

        }
        else {
            let existSection = bidding.Sections.find(x => x.Name === baseModel.Name)
            if (existSection === undefined)
                return false
            return true
        }
    }


    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (IsNullOrEmpty(baseModel.Name)) return false
        if (IsNullOrEmpty(baseModel.Content)) return false
        return true;
    }

    /**
    * Get the card content
    *
    * @memberof Index
    */
    const getCardContent = () => {
        if (isLoading === true) {
            return (
                <LoadingPage />
            )
        } else {
            return (
                <Grid container spacing={24}>
                    <Grid item xs={10} style={{ padding: "30px" }}>
                        <TextField
                            fullWidth
                            label={translate('biddingsModule.basesCreate.name')}
                            name='Name'
                            // margin="normal"
                            variant="outlined"
                            size="small"
                            value={baseModel ? baseModel.Name : ""}
                            onChange={onPropertyChange}
                        />

                    </Grid>
                    <Grid item xs={2} style={{ display: "flex", alignSelf: "center" }}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            className={classes.button}
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            disabled={!canBeSaved() || existBaseSomeName() || isSaving}>
                            {translate("common.save")}
                        </Button>
                    </Grid>
                    {
                        existBaseSomeName() &&
                        <Grid item xs={12}
                        style={{ padding: "30px" }}
                        >
                            <WarningComponent
                                title={translate("biddingsModule.warning")}
                                message={translate("biddingsModule.alreadySection")}
                            />
                        </Grid>
                    }

                    { !isEdited && <Grid item xs={12} style={{
                        paddingTop: "0px",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        maxHeight: `calc(100vh - ${300}px)`,
                        minHeight: `calc(100vh - ${300}px)`,
                        overflowY: "auto",
                    }}>

                        <HtmlEditor
                            id={baseModel.Id ? baseModel.Id : "id-modeCreate"}
                            labelVariant="subtitle1"
                            onEditorChange={onChange}
                            initialValue={baseModel ? baseModel.Content : ""}
                            isDisabled={false}
                        />

                    </Grid>}
                </Grid>
            )
        }
    }

    return getCardContent()
};

const styles = (theme) => ({
    root: {
        display: "flex",
    },
    padding: {
        padding: "30px"
    },
    button: {
        maxHeight: "40px"
    }
});

CreateBaseComponent.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
};

CreateBaseComponent.defaultProps = {
    isLoading: false,
};

export default withRouter(
    withStyles(styles, { withTheme: true })(CreateBaseComponent)
);
