import React from 'react';
import { Translate } from 'react-localize-redux';
import moment from 'moment';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

/** Material UI import section */

/** Custom component import section */
import DataTable from '../../common/DataTable';

const ConsignmentsTable = ({ consignmentNotes = [], consignmentNoteCount }) => {
	const location = useLocation();
	const history = useHistory();
	const queryParams = queryString.parse(location.search);

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search);
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page;
		}
		queryParams.rowsPerPage = rowsPerPage;
		history.push({ search: queryString.stringify(queryParams) });
	};

	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search);
		queryParams.sortBy = sortBy;
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc';
		history.push({ search: queryString.stringify(queryParams) });
	};

	const onRowClick = (consignmentNote, event) => {
		let tagName = event.target.tagName;
		if (tagName !== 'svg' && tagName !== 'A') {
			history.push(`/consignmentNote/${consignmentNote.id}`);
		}
	};

	const onRenderCellItem = (dataSource, item) => {
		switch (dataSource) {
			case 'creationDate': {
				return moment(item.creationDate).format('DD/MM/YYYY');
			}
			default: {
				return null;
			}
		}
	};

	return (
		<DataTable
			data={consignmentNotes}
			configuration={tableConfiguration}
			onRowClick={onRowClick}
			onChangeSortCriteria={onChangeSortCriteria}
			isIndex
			showFilters
			totalRows={consignmentNoteCount}
			sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'folio'}
			sortAscending={
				queryParams && queryParams.sortDirection ? (queryParams.sortDirection === 'desc' ? false : true) : false
			}
			page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
			rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
			onChangePage={(newPage, newRowsPerPage) => {
				onChangePage(newPage, newRowsPerPage);
			}}
			onRenderCellItem={onRenderCellItem}
		/>
	);
};

const tableConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator'
		},
		{
			header: <Translate id="common.status" />,
			dataSource: 'status'
		},
		{
			header: <Translate id="common.folio" />,
			dataSource: 'folio'
		},
		{
			header: <Translate id="common.creationDate" />,
			dataSource: 'creationDate'
		},
		{
			header: <Translate id="consignmentNote.table.folioTransport" />,
			dataSource: 'transportCode'
		},
		{
			header: <Translate id="consignmentNote.table.transports" />,
			dataSource: 'carrierName'
		},
		{
			header: <Translate id="consignmentNote.table.sender" />,
			dataSource: 'senderName'
		}
	]
};

export default ConsignmentsTable;
