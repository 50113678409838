/**Import react section */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ImportMaterialIcon from '@material-ui/icons/Category';

/**Import components section*/
import LoadingPage from '../../common/LoadingPage';
import TabPanel from '../../common/TabPanel'
import BasesContainer from './sections/BasesContainer'
// import MaterialsContainer from './MaterialsContainer'
import ShowDesktopViewHeader from './ShowDesktopViewHeader'
import ShowDesktopViewRightPanel from './ShowDesktopViewRightPanel'
import AddCommonItem from '../../common/AddCommonItem';
import QuestionContainer from './questionsAndAnswers/QuestionContainer'
import CommentsComponent from './CommentsComponent'

/**Import resources section*/
import { ActionsStatus, BiddingStatus, ScopeType, ItemProposalStatus } from '../../../store/helpers/AppConstants';
import {
  isVisibleTabQuestionsAndAnswers, isCategoryRawMaterials,
  supplierCanEditBidding, isVisibleTabResults, onGetHeaderReportTableV2,
  onMappedDataResultsGeneralsTableV2,
  onMappedDataResultsItemTableV2, getSupplierByUserLogged
} from '../../../store/helpers/BiddingHelper';
import SupplierResponseContainer from './suppliers/SupplierResponseContainer'
import ResultsTableGeneralV2 from './results/ResultsTableGeneralV2'
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper';
/**Import actions section */
const ShowDesktopView = (props) => {
  const { classes, bidding } = props;
  /**String props section */
  const { identifier, supplierExternalCode } = props
  /**Bools props section */
  const { isLoading, isSaving, canBePublicationEnabled, canBeSentProposalEnabled, isExternalUser, isOpenLateralMenu } = props
  /**Arrays props section */
  const { internalUsers, userRolesByCompany, divisas, incoterms } = props
  /**Functions props section */
  const { onOpenCloseEditLateralPanel, translate,
    onCreateEvaluationCriteriaConfigPanel,
    onDeletedEvaluationFromBackEnd, onDeleteServiceOrMaterial, onChangeStatusBiddingFromBackEnd,
    onEditEvaluationCriteriaConfigPanel, onAddResponseSupplier, onChangeStatusSupplierFromBackEnd,
    onAddQuestion, onAddAnswer, onDivisaInBiddingFromBackEnd, onAddIncotermFromBackEnd,
    onDownloadMaterialsReport, onUploadMaterialsPricesConfigPanelLateralPanel,
    onAddResponseItemSupplierConfigPanel, onAddResponseGralSupplierConfigPanel,
    onAddCommentsSupplierConfigPanel, onDownloadBiddingResultsReport, onSetMaterialStatusBySupplierInternal
  } = props
  /**Objects props section */
  const { userProfile, userProfileLogged } = props
  const [tabSelectedIndex, setTabSelectedIndex] = useState(0)
  const [openDialogConfirm, setDialogConfirmInternal] = useState({ open: false, status: null, bindingId: null, actionType: null })
  let supplierData = getSupplierByUserLogged(bidding, userProfile, isExternalUser, supplierExternalCode)

  const onChangeTab = (indexTab) => {
    setTabSelectedIndex(indexTab)
  }

  const onOpenConfirmDialog = (status, bindingId, actionType) => {
    setDialogConfirmInternal({ open: true, status: status, bindingId: bindingId, actionType: actionType })
  }

  const onClosedDialog = () => {
    setDialogConfirmInternal({ open: false, status: null, bindingId: null, actionType: null })
  }

  const onDownloaderReportResult = () => {
    let supplierCodeIdentifier = (supplierExternalCode ? supplierExternalCode: (userProfile ? userProfile.preferred_username:""))
    let criteriasResponses = (bidding.CriteriasResponses.length >= 1 ? (bidding.CriteriasResponses[0].criterias.length >= 1 ? bidding.CriteriasResponses[0].criterias[0] : []) : [])
    let headersTable = onGetHeaderReportTableV2(criteriasResponses, bidding.Suppliers, supplierCodeIdentifier, false)


    let globalsResults = onMappedDataResultsGeneralsTableV2(bidding, ScopeType.GENERAL)
    let itemsResult = onMappedDataResultsItemTableV2(bidding, ScopeType.ITEM, bidding.Status, supplierCodeIdentifier)

    if (props.onDownloadBiddingResultsReport) {
      onDownloadBiddingResultsReport(globalsResults, itemsResult, headersTable)
    }
  }


  /**
 * Function to change estatus from responsbles
 * @param {*} comments 
 */
  const onChangeStatusResponsble = (comments) => {
    switch (openDialogConfirm.actionType) {
      case ActionsStatus.ACTION_PUBLISH:
      case ActionsStatus.ACTION_CLOSED:
      case ActionsStatus.ACTION_CALCULATE:
        if (onChangeStatusBiddingFromBackEnd) {
          onChangeStatusBiddingFromBackEnd(openDialogConfirm.status, openDialogConfirm.bindingId)
            .then(() => {
              if(props.onLoadBiddingDetail){
                props.onLoadBiddingDetail()
              }
              onClosedDialog()
            })
        }
        break;
      case ActionsStatus.ACTION_SEND_PROPOSAL:
        if (onChangeStatusSupplierFromBackEnd) {
          onChangeStatusSupplierFromBackEnd(openDialogConfirm.status, openDialogConfirm.bindingId, '')
            .then(() => {
              if(props.onLoadBiddingDetail){
                props.onLoadBiddingDetail()
              }
              onClosedDialog()
            })
        }
        break;
      case ActionsStatus.ACTION_DECLINED_PROPOSAL:
        if (onChangeStatusSupplierFromBackEnd) {
          onChangeStatusSupplierFromBackEnd(openDialogConfirm.status, openDialogConfirm.bindingId, comments)
            .then(() => {
              if(props.onLoadBiddingDetail){
                props.onLoadBiddingDetail()
              }
              onClosedDialog()
            })
        }
        break;
      default:
        console.log("No exist option to change bidding")
        break;
    }
  }


  const getTitleModal = () => {
    switch (openDialogConfirm.actionType) {
      case ActionsStatus.ACTION_PUBLISH:
        return ("biddingsModule.publish");
      case ActionsStatus.ACTION_CLOSED:
        return ("biddingsModule.closeBidding")
      case ActionsStatus.ACTION_CALCULATE:
        return ("biddingsModule.calculateBidding")
      case ActionsStatus.ACTION_DECLINED_PROPOSAL:
        return ("biddingsModule.declineSupplier");
      case ActionsStatus.ACTION_SEND_PROPOSAL:
        return ("biddingsModule.sendBySupplier");
      default:
        return ("common.confirm");
    }
  }

  const getMessageDialog = () => {
    switch (openDialogConfirm.actionType) {
      case ActionsStatus.ACTION_PUBLISH:
        return ("biddingsModule.publishMessage");
      case ActionsStatus.ACTION_CLOSED:
        return ("biddingsModule.closeBiddingMessage")
      case ActionsStatus.ACTION_CALCULATE:
        return ("biddingsModule.calculateBiddingMessage")
      case ActionsStatus.ACTION_DECLINED_PROPOSAL:
        return ("biddingsModule.declineSupplierMessage");
      case ActionsStatus.ACTION_SEND_PROPOSAL:
        return ("biddingsModule.sendBySupplierMessage");
      default:
        return ("common.confirm");
    }
  }

  /**
* Get the card content
*
* @memberof Index
*/
  const getCardContent = () => {
    if (isLoading === true) {
      return (
        <LoadingPage />
      )
    } else {
      return (
        <Grid container spacing={8}>
          <ShowDesktopViewHeader
            bidding={bidding}
            identifier={identifier}
            isSaving={isSaving}
            canBePublicationEnabled={canBePublicationEnabled}
            userRolesByCompany={userRolesByCompany}
            translate={translate}
            userProfileLogged={userProfileLogged}
            userProfile={userProfile}
            canBeSentProposalEnabled={canBeSentProposalEnabled}
            onOpenConfirmDialog={onOpenConfirmDialog}
            isExternalUser={isExternalUser}
          />
          <Grid item xs={tabSelectedIndex === 0 ? 8 : 12}>
            <Card className={classes.leftPanel}>
              <div className={classes.tabs}>
                <div className={tabSelectedIndex === 0 ? classes.containerTabs : null}>

                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="disabled tabs example"
                    onChange={(event, index) => onChangeTab(index)}
                    value={tabSelectedIndex}
                  >
                    <Tab style={{ minWidth: "50px" }} className={classes.tab} label={translate("biddingsModule.apart")} />
                    {<Tab style={{ minWidth: "50px" }} className={classes.tab} label={isCategoryRawMaterials(bidding) ? translate("biddingsModule.listItems") : translate("biddingsModule.listServices")} />}
                    {isVisibleTabQuestionsAndAnswers(bidding) && <Tab style={{ minWidth: "50px" }} className={classes.tab} label={translate("biddingsModule.questions.title")} />}
                    {isVisibleTabResults(bidding) && <Tab style={{ minWidth: "50px" }} className={classes.tab} label={translate("biddingsModule.results")} />}

                  </Tabs>
                </div>
                <div className={classes.buttonsRightContainer} >
                  {
                    tabSelectedIndex === 1 &&
                    isCategoryRawMaterials(bidding) &&
                    supplierCanEditBidding(bidding, userProfile, userRolesByCompany, isExternalUser) &&
                    <Tooltip title={translate("biddingsModule.importPricesAndResponses")}>
                      <IconButton
                        color="primary"
                        aria-label="More"
                        onClick={() => onUploadMaterialsPricesConfigPanelLateralPanel()}
                      >
                        <ImportMaterialIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  {
                    tabSelectedIndex === 1 &&
                    isCategoryRawMaterials(bidding) &&
                    <Tooltip title={translate("biddingsModule.downloadMaterials")}>
                      <IconButton
                        color="primary"
                        aria-label="More"
                        disabled={isSaving}
                        onClick={() => onDownloadMaterialsReport()}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  {
                    (tabSelectedIndex === 3 && bidding && (bidding.Status === BiddingStatus.FINISHED))
                    && bidding && bidding.ProposalStatus === ItemProposalStatus.COMPLETED && <Tooltip title={translate("biddingsModule.downloadReportResult")}>
                      <IconButton
                        color="primary"
                        aria-label="More"
                        onClick={onDownloaderReportResult}
                        disabled={isSaving}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  }

                </div>

              </div>
              <TabPanel value={tabSelectedIndex} index={0}>
                <div className={classes.sectionsContainer}>
                  <BasesContainer
                    translate={translate}
                    bidding={bidding}
                  />
                </div>

              </TabPanel>

              <TabPanel value={tabSelectedIndex} index={1}>
                {
                  <SupplierResponseContainer
                    translate={translate}
                    bidding={bidding}
                    userRolesByCompany={userRolesByCompany}
                    onCreateEvaluationCriteriaConfigPanel={onCreateEvaluationCriteriaConfigPanel}
                    onDeletedEvaluationFromBackEnd={onDeletedEvaluationFromBackEnd}
                    onDeleteServiceOrMaterial={onDeleteServiceOrMaterial}
                    isSaving={isSaving}
                    userProfileLogged={userProfileLogged}
                    onEditEvaluationCriteriaConfigPanel={onEditEvaluationCriteriaConfigPanel}
                    onAddResponseSupplier={onAddResponseSupplier}
                    userProfile={userProfile}
                    isExternalUser={isExternalUser}
                    divisas={divisas}
                    onDivisaInBiddingFromBackEnd={onDivisaInBiddingFromBackEnd}
                    incoterms={incoterms}
                    onAddIncotermFromBackEnd={onAddIncotermFromBackEnd}
                    onAddResponseItemSupplierConfigPanel={onAddResponseItemSupplierConfigPanel}
                    onAddResponseGralSupplierConfigPanel={onAddResponseGralSupplierConfigPanel}
                    onSetMaterialStatusBySupplierInternal={onSetMaterialStatusBySupplierInternal}
                  />
                }
              </TabPanel>
              <TabPanel value={tabSelectedIndex} index={2}>
                <QuestionContainer
                  onAddQuestion={onAddQuestion}
                  onAddAnswer={onAddAnswer}
                  isSaving={isSaving}
                  bidding={bidding}
                  isExternalUser={isExternalUser}
                  userProfile={userProfile}
                  userRolesByCompany={userRolesByCompany}
                  usersByRole={isExternalUser ? [] : internalUsers}
                  preferred_username={isExternalUser ? supplierExternalCode: (userProfile ? userProfile.preferred_username:"")}
                  isCurrentActiveDirectoryUser = {isExternalUser ? false: IsCurrentActiveDirectoryUser(userProfileLogged)}
                />
              </TabPanel>

              {
                (bidding && (bidding.Status === BiddingStatus.FINISHED)) && <TabPanel value={tabSelectedIndex} index={3}>
                  <Grid item xs={12}>
                    {
                      (bidding.CriteriasResponses && bidding.CriteriasResponses.length !== 0) ?
                        <ResultsTableGeneralV2
                          bidding={bidding}
                          isSaving={isSaving}
                          userRolesByCompany={userRolesByCompany}
                          userProfile={userProfile}
                          onAddCommentsSupplierConfigPanel={onAddCommentsSupplierConfigPanel}
                          isVisibleComments
                          supplierExternalCode={supplierExternalCode}
                          isCurrentActiveDirectoryUser = {isExternalUser ? false: IsCurrentActiveDirectoryUser(userProfileLogged)}
                          isExternalUser={true}
                          isOpenLateralMenu={isOpenLateralMenu}
                          translate={translate}
                        /> :
                        <CommentsComponent
                          title={translate("common.comments")}
                          comments={ (supplierData && supplierData.FinalComment) ? supplierData.FinalComment : translate("biddingsModule.notSelectedAsWinner")}
                          isVisibleHtmlEditor={(supplierData && supplierData.FinalComment) ? true: false}
                        />
                    }

                  </Grid>
                </TabPanel>
              }

            </Card>
          </Grid>
          {
            tabSelectedIndex === 0 &&
            <ShowDesktopViewRightPanel
              bidding={bidding}
              internalUsers={internalUsers}
              userRolesByCompany={userRolesByCompany}
              onOpenCloseEditLateralPanel={onOpenCloseEditLateralPanel}
              translate={translate}
              userProfile={userProfile}
              tabSelectedIndex={tabSelectedIndex}
              isExternalUser={isExternalUser}

            />
          }

          {/** Dialog to confim change status */}
          <AddCommonItem
            title={translate(getTitleModal())}
            message={translate(getMessageDialog())}
            open={openDialogConfirm.open}
            onClose={onClosedDialog}
            onSaveItem={onChangeStatusResponsble}
            isSaving={isSaving}
            saveButtonTranslate={"common.confirm"}
            isMessage={openDialogConfirm.actionType === ActionsStatus.ACTION_DECLINED_PROPOSAL ? false : true}
            isTextArea={openDialogConfirm.actionType === ActionsStatus.ACTION_DECLINED_PROPOSAL ? true : false}
          />

        </Grid>
      )
    }
  }
  return getCardContent()
};

const styles = (theme) => ({
  leftPanel: {
    maxHeight: `calc(100vh - ${245}px)`,
    minHeight: `calc(100vh - ${245}px)`,
    overflowY: "hidden",
    padding: "10px"
  },
  sectionsContainer: {
    maxHeight: `calc(100vh - ${305}px)`,
    minHeight: `calc(100vh - ${305}px)`,
    overflowY: "auto",
    paddingBottom: "15px"
  },
  grow: {
    flexGrow: 1
  },
  flex: {
    display: 'flex'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main
  },
  buttonsRightContainer: {
    display: "flex",
    alignItems: 'center'
  },
  tabs: {
    display: "flex",
    justifyContent: 'space-between',
  },
  containerTabs: {
    maxWidth: `calc(100vw - ${715}px)`,
  }
});

ShowDesktopView.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  isLoading: PropTypes.bool.isRequired,
};

ShowDesktopView.defaultProps = {
  isLoading: false,
  canBeSentProposalEnabled: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(ShowDesktopView)
);
