/**Import react section */
import React from 'react'
import { withRouter } from 'react-router-dom'

/**Import material UI Section*/
import { withStyles } from '@material-ui/core'
/** Import component section */
import StepperHeader from './StepperHeader'
const DesktopView = (props) => {
	//Array props section
	const { userRolesByCompany } = props
	//Objects props section
	const { translate, factoring } = props
	//Functins props
	const { onUpdateFactoring, onAddAttachmets, onDeleteFactoringsFiles, onDownloadFactoringFile, SynchronizeSapFactoring, onSendNotificationToSupplier } = props
	//bool props section
	const { isInternalUser, isSaving, isSavingNotification } = props
	return (
		<StepperHeader
			translate={translate}
			factoring={factoring}
			//Functions props section
			onUpdateFactoring={onUpdateFactoring}
			onAddAttachmets={onAddAttachmets}
			onDeleteFactoringsFiles={onDeleteFactoringsFiles}
			onDownloadFactoringFile={onDownloadFactoringFile}
			SynchronizeSapFactoring={SynchronizeSapFactoring}
			onSendNotificationToSupplier ={onSendNotificationToSupplier}
			//bool props section
			isInternalUser={isInternalUser}
			isSaving={isSaving}
			isSavingNotification={isSavingNotification}
			//Array
			userRolesByCompany={userRolesByCompany}
		/>
	)
}

const styles = (theme) => ({})

export default withRouter(withStyles(styles, { withTheme: true })(DesktopView))
