
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';

/**
 * Dialog to add action emergented
 */
class AddEmergentAction extends Component {

    /**
   *Creates an instance of AddEmergentAction.
   * @param {*} props
   * @memberof AddEmergentAction
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            description: "",
        }
    }

    /**
     * Function to add description
     * @param {*} description 
     */
    updatetemProperty(description) {
        this.setState({
            description: description
        })
    }

    /**
     * onSave
     */
    onSaveItem() {
        if (this.state.description) {
            if(this.props.onSaveItem)
                this.props.onSaveItem(this.state.description);
            this.setState({description: ""})
        }
    }

    /**
     * Dialog information
     */
    render() {
        const { classes } = this.props;
        return (
            <Dialog
            open = {this.props.open}
            onClose={this.props.onClose}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {this.props.title}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={this.onSaveItem}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.state.description !== ""}
                    />
                }>

                <form>
                    <TextField fullWidth
                        label={this.props.message}
                        className={classes.textField}
                        multiline
                        rows="4"
                        defaultValue={this.state.description}
                        onChange={(event) => { this.updatetemProperty(event.target.value) }}
                        margin="normal"
                        variant="outlined"
                    />
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(AddEmergentAction));