/** Helpers import section */
import axios from 'axios';

import {
    getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserParameters,
    getAuthorizedRequestConfigurationUserFiles
} from '../helpers/SessionHelper';

export const IS_SAVING = "IS_SAVING";
export const START_LOADING_CUSTOMER_GUARANTEE = "START_LOADING_CUSTOMER_GUARANTEE";
export const START_LOADING_FAILURE_GUARANTEE = "START_LOADING_FAILURE_GUARANTEE";
export const SET_GUARANTEE_REPORT_TO_LIST = "SET_GUARANTEE_REPORT_TO_LIST";
export const IS_LOADING = "IS_LOADING";
export const SET_GUARANTEE_REPORT_COUNT = "SET_GUARANTEE_REPORT_COUNT";
export const CHANGE_GUARANTEE_REPORT_SORT_QUERY = "CHANGE_GUARANTEE_REPORT_SORT_QUERY";
export const CHANGE_GUARANTEE_REPORT_SORT_CRITERIA = "CHANGE_GUARANTEE_REPORT_SORT_CRITERIA";
export const CHANGE_GUARANTEE_PAGINATION = "CHANGE_GUARANTEE_PAGINATION";
export const SET_GUARANTEE_REPORT = "SET_GUARANTEE_REPORT";
export const ADD_FILE_GUARRANTEE_REPORT = "ADD_FILE_GUARRANTEE_REPORT";
export const REMOVE_FILE_GUARRANTEE_REPORT = "REMOVE_FILE_GUARRANTEE_REPORT";

/**
 * Action to is saving report
 * @param {*} isSaving
 */
function isSaving(isSaving) {
    return {
        type: IS_SAVING,
        isSaving: isSaving
    }
}

/**
 * Action start loading customer guarantee
 * @param {*} isLoading
 */
function isLoadingCustomer(isLoadingCustomer) {
    return {
        type: START_LOADING_CUSTOMER_GUARANTEE,
        isLoadingCustomer: isLoadingCustomer
    }
}

/**
 * Action start loading customer guarantee
 * @param {*} isLoading
 */
function isLoadingFailure(isLoadingFailure) {
    return {
        type: START_LOADING_FAILURE_GUARANTEE,
        isLoadingFailure: isLoadingFailure
    }
}

/**
 * Action start loading guarantee report
 * @param {*} isLoading
 */
function isLoading(isLoading) {
    return {
        type: IS_LOADING,
        isLoading
    }
}

/**
 *Action add guarantee report to list
 *
 * @param {*} customerGuaranteeData
 * @returns
 */
function setGuaranteeReportToList(guaranteeReportList) {
    return {
        type: SET_GUARANTEE_REPORT_TO_LIST,
        guaranteeReportList: guaranteeReportList
    }
}

/**
 * Action to set guarantee report count
 */
function setGuaranteeReportCount(count) {
    return {
        type: SET_GUARANTEE_REPORT_COUNT,
        count
    }
}

/**
 * Action to set guarantee report
 * @param {*} guaranteeReport 
 */
function setGuaranteeReport(guaranteeReport) {
    return {
        type: SET_GUARANTEE_REPORT,
        guaranteeReport: guaranteeReport
    }
}

/**
 * Add file to state
 *
 * @param {*} files
 * @returns
 */
function addFile(files) {
    return {
        type: ADD_FILE_GUARRANTEE_REPORT,
        files
    }
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName) {
    return {
        type: REMOVE_FILE_GUARRANTEE_REPORT,
        fileName
    }
}

/**
 * Action to serch customer guarantee by Query
 * @param {*} query
 */
export function searchByCustomerGuaranteeByQuery() {
    return function (dispatch, getState) {

        dispatch(isLoadingCustomer(true));
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/customerguarantee/filters`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            Page: 1,
            RowsPerPage: 50,
        }
        return axios.post(endPoint, data, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isLoadingCustomer(false));
                    return Promise.resolve(response.data);
                }
            })
            .catch((error) => {
                dispatch(isLoadingCustomer(true));
                console.error(error);
            });
    }
}


/**
 * Action to serch failure guarantee by Query
 * @param {*} query
 */
export function searchByFailureGuaranteeByQuery() {
    return function (dispatch, getState) {

        dispatch(isLoadingFailure(true));
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/failureguarantee/filters`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            Page: 1,
            RowsPerPage: 50,
        }
        return axios.post(endPoint, data, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isLoadingFailure(false));
                    return Promise.resolve(response.data);
                }
            })
            .catch((error) => {
                dispatch(isLoadingFailure(true));
                console.error(error);
            });
    }
}

/**
 * Action to get a list of  guarantee report by filters
 */
export function loadGuaranteeReportFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {

        dispatch(isLoading(true));
        let selectedCompany = getState().profile.get("selectedCompany");

        if (selectedCompany) {
            let supplierToken = undefined;
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
                supplierToken = getState().oidc.user.profile.company_token;
            }

            let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantee/all`;
            let data = {
                CompanyToken: selectedCompany.get("token"),
                SupplierToken: supplierToken,
                SortBy: orderBy,
                SortAscending: sortAscending,
                Query: query,
                Page: `${page+1}`,
                RowsPerPage: rowsPerPage,
            }
            return axios.post(endPoint, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(isLoading(false));
                        dispatch(setGuaranteeReportToList(serverResponse.data))

                    }
                })
                .catch((error) => {
                    dispatch(isLoading(false));
                    console.error("Error getting the guarantee report list", error.response)
                })
        }
        else {
            dispatch(isLoading(false));

        }
    }
}

/**
 *Action to get guarantee report count
 *
 * @export
 * @returns
 */
export function getGuaranteeReportCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().guaranteeReport ? getState().guaranteeReport.get('guaranteeReportCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        if (selectedCompany) {
            let supplierToken = undefined;
            if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
                supplierToken = getState().oidc.user.profile.company_token;
            }
            let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantees/count`;
            let data ={
                CompanyToken: selectedCompany.get("token"),
                SupplierToken: supplierToken,
            }
            return axios.post(endPoint, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setGuaranteeReportCount(serverResponse.data))
                    }
                })
                .catch((error) => {
                    console.error("Error getting the guarantee report count", error.response)
                })
        }
    }
}

/**
 * Action to create a guarantee report
 *
 * @export
 * @param {*} token
 * @returns
 */
export function createGuaranteeReport(data) {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantee`;
        let formData = new FormData();
        data.files.forEach((file) => {
            formData.append('files', file);
        })

        formData.set('CompanyToken', selectedCompany.get('token'));
        formData.set('CompanyIdentifier', selectedCompany.get("get_company_identifier"));
        formData.set('CreateBy', user.profile.name);
        formData.set('SupplierToken', data.supplier.token);
        formData.set('SupplierCode', data.supplier.code);
        formData.set('SupplierName', data.supplier.name);
        formData.set('CreatorUserName', user.profile.preferred_username);
        formData.set('DocumentIdentifier', selectedCompany.get('document_identifier'));
        formData.set('ProblemDescription', data.problemDescription);
        formData.set('PartDescription', data.partNumberSelected.description ? data.partNumberSelected.description : "");
        formData.set('PartMeasurementUnit', data.partNumberSelected.measurementUnit ? data.partNumberSelected.measurementUnit : "");
        formData.set('FailureAmountReported', data.amountReportedFault);
        formData.set('ReportType', data.reportType);
        formData.set('UserNameReport', data.userNameReport);
        formData.set('CustomerToken', data.customerSelected ? data.customerSelected.value : "");
        formData.set('PartNumberCode', data.partNumberSelected.code ? data.partNumberSelected.code : "");
        formData.set('FailureToken', data.failureSelected ? data.failureSelected.value : "");
        formData.set('SerialNumber', data.serialNumber);
        formData.set('ServiceOrderRepare', data.serviceOrderRepare ? data.serviceOrderRepare : "");
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve(serverResponse.data);
                }
            })
            .catch((error) => {
                console.error("Error to create guaranteereport", error.response)
                dispatch(isSaving(false))
                return Promise.reject();
            })
    }
}

/**
 * Action to edit guarrantee from backend
 */
export function editGuarranteeeReportFromBackEnd(supplier, partNumber, customerSelected, failureSelected, guarrantee) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let data = {
            GuaranteeToken: guarrantee.tokenGuarantee,
            SupplierToken: supplier.token,
            SupplierName: supplier.name,
            SupplierCode: supplier.code,

            ReportType: guarrantee.reportType,
            UserNameReport: guarrantee.userNameReport,
            CustomerToken: customerSelected ? customerSelected.value : "",
            FailureAmountReported: guarrantee.amountReportedFault,
            SerialNumber: guarrantee.serialNumber,
            FailureToken: failureSelected ? failureSelected.value : "",
            ProblemDescription: guarrantee.problemDescription,
            NumberPiecesReceibed: guarrantee.numberPiecesReceibed,
            PartNumberCode: partNumber ? partNumber.code : "",
            PartDescription: partNumber ? partNumber.description : "",
            PartMeasurementUnit: partNumber ? partNumber.measurementUnit : "",
            ServiceOrderRepare: (guarrantee.serviceOrderRepare ? guarrantee.serviceOrderRepare : "")   
        }

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantees/update`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error edit a guarrantee report by company", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Get and set the guarantee report in the state from read from backend
 * DONE
 * @param {*} token 
 */
export function loadGuaranteeReport(token) {
    return function (dispatch, getState) {
        dispatch(isLoading(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantees/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setGuaranteeReport(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load Guarantee report", error.response)
                dispatch(isLoading(false))
                return Promise.reject();
            })
    }
}

/**
 * Acton to create analysys report
 */
export function createAnalysisReport(data) {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let guaranteeReport = getState().guaranteeReport.get("guaranteeReport");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantees/analisisreport`;
        let formData = new FormData();
        data.files.forEach((file) => {
            formData.append('files', file);
        })
        formData.set('GuaranteeToken', guaranteeReport.get('token'));
        formData.set('CompanyToken', selectedCompany.get('token'));
        formData.set('CreateBy', user.profile.name);
        formData.set('CreatorUserName', user.profile.preferred_username);
        formData.set('OrigenFailure', data.failureSelected);
        formData.set('Document', data.file);
        formData.set('Observation', data.comments);
        formData.set('Name', data.folioAnalysis);

        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve(serverResponse.data);
                }
            })
            .catch((error) => {
                console.error("Error to create analysis report", error.response)
                dispatch(isSaving(false))
                return Promise.reject();
            })
    }
}

/**
 * Action to send notification from guarantee report
 * @param {*} planningSelected 
 * @param {*} developmentSelected 
 * @param {*} externalUsers 
 */
export function sendNotificationGuaranteeReport(developmentSelected) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let guaranteeReportToken = getState().guaranteeReport.get("guaranteeReport").get('token');

        let data = {
            GuaranteeToken: guaranteeReportToken,
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            DevelopmentEngineerUserName: developmentSelected.user_name,
            DevelopmentEngineerName: developmentSelected.full_name ? developmentSelected.full_name : developmentSelected.name,
            DevelopmentEngineerEmail: developmentSelected.user_email,
        }

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantees/sendnotification`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to send notications from guarantee reports", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to update guarantees
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFilesEvidence(files) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let guaranteeReportToken = getState().guaranteeReport.get("guaranteeReport").get('token');
        let configuration = getAuthorizedRequestConfigurationUserFiles(user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantees/${guaranteeReportToken}/upload`;
        let formData = new FormData();
        for (var index = 0; index <= files.length; index++) {
            formData.append('file', files[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addFile(serverResponse.data));
                    dispatch(isSaving(false));
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to upload evidence file(s) from guarantees report", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} quoteToken
 * @param {*} fileName
 * @returns
 */
export function removeFilesEvidence(fileName) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let guaranteeReportToken = getState().guaranteeReport.get("guaranteeReport").get('token');
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantees/${guaranteeReportToken}/remove`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeFile(fileName))
                }
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to remove evidence file(s) from guaranteeReport report", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to delete draft guarantee
 * @param {*} token 
 */
export function deleteDraftGuarrantee(token) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/workflow/${token}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to delete draft guaranty", error.response)
                return Promise.reject();
            })
    }
}

/**
 *Action to download report guarantee by month
 *
 * @export
 * @param {*} month
 * @returns
 */
export function downloadGuarranteeReportByMonth(startDate, endDate, sucursalUser) {
    return (dispatch, getState) => {
        dispatch(isSaving(true));
        let companyToken = getState().profile.get("selectedCompany").get('token');
        let data = {
            CompanyToken: companyToken,
            StartDate: startDate,
            EndDate: endDate,
            CreatorUserName: sucursalUser,
        }
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantees/downloadRangefilters`;
        return axios.post(endPoint, data, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isSaving(false));
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reporte_de_Garantías.xlsx";
                    a.click();
                }
            })
            .catch((error) => {
                dispatch(isSaving(false));
                console.error("Error downloading guarrantee report by month", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to created rework re
 */
export function createNoteCreditBySupplier(attachments, noteCredt) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let guaranteeReportToken = getState().guaranteeReport.get("guaranteeReport").get('token');
        let selectedCompany = getState().profile.get("selectedCompany");
        let formData = new FormData();
        attachments.forEach((file) => {
            formData.append('file', file);
        })
        formData.set('GuaranteeToken', guaranteeReportToken);
        formData.set('CreatorUserName', user.profile.preferred_username);
        formData.set('CreateBy', user.profile.name);
        formData.set('Observation', noteCredt.observation);
        formData.set('Invoice', noteCredt.folioNoteCredit);
        formData.set('PartnershipCode', selectedCompany.get("partnership_code"));
        formData.set('Division', selectedCompany.get("division"));
        formData.set('CenterCompany', selectedCompany.get("center"));

        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantees/notecreditbysupplier`;
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error add rework by sorter in request rework ", error.response)
                return Promise.reject(error.response.data.Message);
            })
    }
}

export function updateGuaranteeStatus(guaranteeToken, status, comment) {
    return (dispatch, getState) => {
        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/guarantee/updatestatus`;
        let command = {
            GuaranteeToken: guaranteeToken,
            Status: status,
            Comment: comment
        }
        return axios.put(endPoint, command, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setGuaranteeReport(serverResponse.data))
                    return Promise.resolve();//TODO: UPDATE IN STORE
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to upadate to Guarantee status ", error.response)
                return Promise.reject(error.response.data.Message);
            })
    }
}