import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Translate } from "react-localize-redux";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LinearProgress, Grid } from '@material-ui/core';


/**
 * Memo send request items from memo
 */
class SendRequestDialog extends Component {

    /**
     *Creates an instance of MemoItemDialog.
     * @param {*} props
     * @memberof MemoItemDialog
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            isSending: false
        }
    }

    /**
     * On send request to suppliers
     */
    onSendRequesSupplierMemoClick() {
        if (this.props.sendQuotesRequestFromMemo) {
            this.props.sendQuotesRequestFromMemo()
        }
    }

    render() {
        const { classes } = this.props
        return (
            <Dialog open={this.props.show} style={{ overflow: "hidden" }}>
                <DialogTitle id="form-dialog-title">{<Translate id="memos.sendQuotes" />}</DialogTitle>
                <DialogContent>
                    <div className={classes.dialogRoot}>
                        <Typography variant="body" gutterBottom>
                            <Translate id="memos.sendMemorequest" />
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Grid container
                        justify="flex-end"
                        alignItems="center">
                        {this.props.isSaving &&
                            <Grid item xs={12}>
                                <Typography variant="caption" gutterBottom>
                                    <Translate id="memos.waitSendRequestMessage" />
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={3}>
                            <Button onClick={() => { this.onSendRequesSupplierMemoClick() }} disabled={this.props.isSaving} color="secondary">
                                <Translate id="common.send" />
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button onClick={this.props.onClose} color="primary" disabled={this.props.isSaving}>
                                <Translate id="common.close" />
                            </Button>
                        </Grid>
                        {this.props.isSaving === true ?
                            <Grid item xs={12}>
                                <LinearProgress color="secondary" />
                            </Grid>
                            : null
                        }
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = theme => ({
    dialogRoot: {
        minHeight: "100px"
    },
    sampleRequired: {
        color: theme.palette.primary.light
    },
    deleteButton: {
        color: "red"
    }
})

export default withStyles(styles)(SendRequestDialog);
