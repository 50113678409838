import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQueryParam, JsonParam, NumberParam } from 'use-query-params';

/** Material UI import section */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Fab, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Clear';

/** Custom components import section */
//import { TextBoxFieldRequired } from '../TextBox';
// import DateRangePicker from '../DateRangePicker';
import DateRangePicker from '../DateTimePicker/DateRangePicker';

/** Helpers import section */
import {
	splitLines,
	IsNullOrEmpty,
	removeRepeatingValues,
	removeLeadingZeros
} from '../../../store/helpers/StringHelper';
import { FilterCriteria } from '../../accreditedWorkshops/serviceOrders/serviceHelpers/FilterConfiguration';

const AdvancedFiltersModal = (props) => {
	const { open, queryStringKey, criteria } = props;
	const { onClose } = props;

	/** Define local state */
	const [query, setQuery] = useState('');
	const [dateRange, setDateRange] = useState({ startDate: moment(), endDate: moment() });
	const [lowerLimit, setLowerLimit] = useState(0);
	const [upperLimit, setUppererLimit] = useState(0);
	const [queryDisplayed, setQueryDisplayed] = useState(false);
	const [dateRangeDisplayed, setDateRangeDisplayed] = useState(false);
	const [lowerLimitDisplayed, setLowerLimitDisplayed] = useState(false);
	const [upperLimitDisplayed, setUppererLimitDisplayed] = useState(false);

	const [pageNumber, setPageNumber] = useQueryParam('pageNumber', NumberParam);
	const [advancedFilter, setAdvancedFilter] = useQueryParam(`Advanced${queryStringKey}`, JsonParam);

	const classes = useStyles();

	useEffect(() => {
		if (queryStringKey.includes('Date')) {
			setDateRangeDisplayed(true);
		} else {
			let criteriaNum = Number(criteria);
			let lowerLimitStatus =
				criteriaNum === FilterCriteria.BETWEEN ||
				criteriaNum === FilterCriteria.GREATER_OR_EQUAL_THAN ||
				criteriaNum === FilterCriteria.GREATER_THAN;
			setLowerLimitDisplayed(lowerLimitStatus);
			let upperLimitStatus =
				criteriaNum === FilterCriteria.BETWEEN ||
				criteriaNum === FilterCriteria.LESS_OR_EQUAL_THAN ||
				criteriaNum === FilterCriteria.LESS_THAN;
			setUppererLimitDisplayed(upperLimitStatus);
			let queryStatus = criteriaNum === FilterCriteria.NOT_EQUAL || criteriaNum === FilterCriteria.CONTAINS_OR_EQUALS;
			setQueryDisplayed(queryStatus);
		}
	}, [queryStringKey, criteria]);

	useEffect(() => {
		if (!!advancedFilter) {
			switch (Number(advancedFilter.criteria)) {
				case FilterCriteria.BETWEEN_DATES: {
					const [startDate, endDate] = advancedFilter.values;
					setDateRange(moment(startDate), moment(endDate));
					break;
				}
				default: {
					break;
				}
			}
		}
	}, [advancedFilter]);

	const handleRangeChange = (dateRange) => {
		setDateRange(dateRange);
	};

	const applyAdvancedFilters = () => {
		let criteriaNum = Number(criteria);
		if (criteriaNum === FilterCriteria.BETWEEN) {
			setAdvancedFilter({ values: [lowerLimit, upperLimit], criteria: criteria }, 'pushIn');
		} else if (criteriaNum === FilterCriteria.GREATER_OR_EQUAL_THAN || criteriaNum === FilterCriteria.GREATER_THAN) {
			setAdvancedFilter({ values: [lowerLimit], criteria: criteria }, 'pushIn');
		} else if (criteriaNum === FilterCriteria.LESS_OR_EQUAL_THAN || criteriaNum === FilterCriteria.LESS_THAN) {
			setAdvancedFilter({ values: [upperLimit], criteria: criteria }, 'pushIn');
		} else if (criteriaNum === FilterCriteria.NOT_EQUAL || criteriaNum === FilterCriteria.CONTAINS_OR_EQUALS) {
			let values = splitLines(query).filter((v) => !IsNullOrEmpty(v));
			let uniqValues = removeRepeatingValues(values);
			let trimmedValues = removeLeadingZeros(uniqValues);
			setAdvancedFilter({ values: trimmedValues, criteria: criteria }, 'pushIn');
		} else if (queryStringKey.includes('Date')) {
			let rangeValues = [dateRange.startDate.startOf('day').format('YYYY-MM-DD'), dateRange.endDate.add(1, 'd').startOf('day').format('YYYY-MM-DD')];
			setAdvancedFilter(
				{
					values: rangeValues,
					criteria: criteria
				},
				'pushIn'
			);
		}
		if (!!pageNumber) {
			setPageNumber(1, 'pushIn');
		}
		onClose();
	};

	/** Clean filter */
	const cleanFilter = () => {
		setAdvancedFilter(undefined, 'pushIn');
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={!dateRangeDisplayed}
			maxWidth={dateRangeDisplayed ? null : 'sm'}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title" disableTypography className={classes.dialogTitle}>
				<Typography gutterBottom color="secondary" variant="subtitle1" className={classes.title}>
					<strong>FILTRO PERSONALIZADO</strong>
				</Typography>
				<Fab size="small" className={classes.root} onClick={onClose}>
					<DeleteIcon />
				</Fab>
			</DialogTitle>
			<DialogContent>
				{lowerLimitDisplayed && (
					<>
						<DialogContentText>
							Es mayor {Number(criteria) === FilterCriteria.GREATER_OR_EQUAL_THAN ? ' o igual' : ''} que
						</DialogContentText>
						<TextField
							autoFocus
							fullWidth
							label="Valor"
							type="number"
							size="small"
							margin="dense"
							value={lowerLimit}
							onChange={(event) => setLowerLimit(event.target.value)}
						/>
						{/* <TextBoxFieldRequired
							active
							required
							autoFocus
							fullWidth
							margin="dense"
							value={lowerLimit}
							label="Valor"
							type="number"
							onChange={(event) => setLowerLimit(event.target.value)}
						/> */}
					</>
				)}
				{upperLimitDisplayed && (
					<>
						<DialogContentText>
							Es menor {Number(criteria) === FilterCriteria.LESS_OR_EQUAL_THAN ? ' o igual ' : ''} que
						</DialogContentText>
						<TextField
							autoFocus
							fullWidth
							label="Valor"
							type="number"
							size="small"
							margin="dense"
							value={upperLimit}
							onChange={(event) => setUppererLimit(event.target.value)}
						/>
						{/* <TextBoxFieldRequired
							active
							required
							fullWidth
							margin="dense"
							value={upperLimit}
							label="Valor"
							type="number"
							onChange={(event) => setUppererLimit(event.target.value)}
						/> */}
					</>
				)}
				{queryDisplayed && (
					<>
						<DialogContentText>
							{Number(criteria) === FilterCriteria.CONTAINS_OR_EQUALS ? 'Contiene' : 'No contiene'}
						</DialogContentText>
						<TextField
							autoFocus
							fullWidth
							multiline
							label="Valor"
							type="number"
							size="small"
							margin="dense"
							value={query}
							onChange={(event) => setQuery(event.target.value)}
						/>
						{/* <TextBoxFieldRequired
							active
							required
							autoFocus
							fullWidth
							multiline
							margin="dense"
							value={query}
							label="Texto"
							type="text"
							onChange={(event) => setQuery(event.target.value)}
						/> */}
					</>
				)}
				{dateRangeDisplayed && (
					<DateRangePicker
						startDate={dateRange.startDate}
						endDate={dateRange.endDate}
						onChangeRange={handleRangeChange}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={cleanFilter}>Limpiar</Button>
				<Button onClick={applyAdvancedFilters}>Aplicar</Button>
			</DialogActions>
		</Dialog>
	);
};

AdvancedFiltersModal.propTypes = {
	open: PropTypes.bool.isRequired,
	queryStringKey: PropTypes.string.isRequired,
	criteria: PropTypes.string.isRequired,
	onClose: PropTypes.func
};

AdvancedFiltersModal.propTypes = {
	onClose: () => console.warn('onClose not defined!')
};

const useStyles = makeStyles((theme) => ({
	dialogTitle: {
		display: 'flex',
		alignItems: 'center',
		'& > *': {
			marginLeft: 5 //theme.spacing(0.5)
		}
	},
	title: {
		flex: '1 1 0'
	}
}));

export default AdvancedFiltersModal;
