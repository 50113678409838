import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import ActionsIcon from '@material-ui/icons/PlaylistAddCheck'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import ScaleIcon from '@material-ui/icons/LinearScale'

/** Material UI Icons imports section */
import RejectIcon from '@material-ui/icons/ThumbDown'
import AcceptIcon from '@material-ui/icons/ThumbUp'
import RequoteIcon from '@material-ui/icons/Replay'
import BuildIcon from '@material-ui/icons/Build'
import BoxIcon from '@material-ui/icons/Inbox'
import MessageIcon from '@material-ui/icons/Comment'

/** Import component section */
import LoadingPage from '../../common/LoadingPage'
import Dropzone from '../../common/Dropzone'
import Select from 'react-select'
import AcceptedRejectedDialog from '../components/AcceptedRejectedDialog'
import DeclineQuoteDialog from './DeclineQuoteDialog'
import OptionsMenu from '../../common/OptionsMenu'
import ToolingQuoteDialog from './ToolingQuoteDialog'
import CubicajeQuoteDialog from './CubicajeQuoteDialog'
import ScaleQuoteDialog from './ScaleQuoteDialog'
import Toaster from '../../common/Toaster'
import DataTable from './../../common/DataTable'
import FilesLinks from '../../common/FilesLinks'
import WarehouseSelector from '../../common/WarehouseSelector'
import HtmlEditor from '../../common/HtmlEditor'

/** Actions and helpers imports section */
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { canBeValidateModuleDevelopment } from '../../../store/helpers/RolesHelper'
import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import {
	GetMeasurementUnits,
	GetTypeQuote,
	GetTimeUnit,
	GetContainerType,
	GetCurrencyType,
	GetIncotermsQuotes,
} from '../../../store/helpers/SelectOptions'
import {
	COMPANY_IDENTIFIER_IMBERA_BRASIL,
	COMPANY_IDENTIFIER_IMBERA_MX,
	COMPANY_IDENTIFIER_IMBERA_REPARE,
	COMPANY_PTM,
	APPROVAL_ACEPTED,
	APPROVAL_REJECTED,
	APPROVAL_REQUOTE,
	APPROVAL_DECLINED,
} from '../../../store/helpers/ResourcesHelper'
import {
	COLOR_STATUS_ACEPTED,
	COLOR_STATUS_REQUOTE,
	COLOR_STATUS_DECLINED,
	COLOR_STATUS_REJECTED,
} from '../../../store/helpers/StatusColorConstants'

/**
 * Information details
 */
class DetailsCard extends Component {
	/**
	 * Create an instance ofMemos
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			comments_supplier: this.props.quote.comments_supplier,
			quote: this.props.quote,
			acceptedEnginnerDialog: false,
			itemToken: null,
			scaleItemToken: null,
			commentEnginner: '',
			openModalDecline: false,
			openModalTooling: false,
			openModalCubicaje: false,
			openModalAddScales: false,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			selectedTokens: [],
		}
	}

	/**
	 * React life-cycle
	 *  Update state if props has changes
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof DetailsCard
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.quote !== this.props.quote) {
			this.setState({
				quote: this.props.quote,
			})
		}
	}

	/**
	 * Function to trigger blur event and save data of input
	 *
	 * @param {*} event
	 * @memberof DetailsCard
	 */
	onKeyDown(event) {
		if (event.key === 'Enter') document.activeElement.blur()
	}

	/**
	 * Function to save changes of data
	 *
	 * @param {*} data
	 * @param {*} token
	 * @memberof DetailsCard
	 */
	saveItem(data, token) {
		var detailUpdate = this.state.quote.details.filter((x) => x.token === token)
		if (detailUpdate.length !== 0) {
			detailUpdate[0][data.id] = data.value
			this.props.updateDetailItemQuote(this.props.quote.token, detailUpdate[0])
		}
	}

	/**
	 * On onChangeCostType
	 *
	 * @param {*} option
	 * @memberof DocumentTypeSelect
	 */
	onChangeCostType(option, id, token) {
		var detailUpdate = this.state.quote.details.filter((x) => x.token === token)
		if (detailUpdate.length !== 0) {
			detailUpdate[0][id] = option
			this.props.updateDetailItemQuote(this.props.quote.token, detailUpdate[0])
		}
	}

	/**
	 * On change change notice type
	 *
	 * @param {*} option
	 * @memberof DocumentTypeSelect
	 */
	onChangeMeasurement(option, id, token) {
		var detailUpdate = this.state.quote.details.filter((x) => x.token === token)
		if (detailUpdate.length !== 0) {
			detailUpdate[0][id] = option
			this.props.updateDetailItemQuote(this.props.quote.token, detailUpdate[0])
		}
	}

	/**
	 * Handle click to open options menu
	 *
	 * @param {*} itemToken
	 * @memberof DetailsCard
	 */
	handleClick(itemToken) {
		this.setState({
			itemToken,
		})
	}

	/**
	 * Handle click to close options menu
	 *
	 * @memberof DetailsCard
	 */
	handleClose() {
		if (
			!this.state.openModalDecline &&
			!this.state.openModalTooling &&
			!this.state.openModalCubicaje &&
			!this.state.openModalAddScales
		) {
			this.setState((state) => ({
				itemToken: '',
				scaleItemToken: '',
			}))
		}
	}

	/**
	 * Handle open decline, tooling or cubicaje item modal
	 *
	 * @param {*} dialogId
	 * @memberof DetailsCard
	 */
	handleOpenItemDialog(dialogId) {
		this.setState({
			[dialogId]: !this.state[dialogId],
		})
	}

	/**
	 * Handle to close decline, tooling or cubicaje item modal and close options menu
	 *
	 * @param {*} dialogId
	 * @memberof DetailsCard
	 */
	handleOnCloseItemDialog(dialogId) {
		this.setState({ [dialogId]: false }, () => this.handleClose())
	}

	/**
	 * Handle click to Add scales
	 *
	 * @param {*} itemToken
	 * @memberof DetailsCard
	 */
	handleClickAddScales(scaleItemToken) {
		this.setState({
			scaleItemToken,
			openModalAddScales: !this.state.openModalAddScales,
		})
	}

	/**
	 * Method to render options menu
	 *
	 * @param {*} item
	 * @returns
	 * @memberof DetailsCard
	 */
	renderOptionsMenu(item) {
		var options = []
		if (item.token !== 'selection') {
			let isActive =
				IsCurrentNotActiveDirectoryUser(this.props.currentUser) &&
				this.state.quote.update_quote_by_supplier &&
				item.status !== APPROVAL_ACEPTED
			if (isActive) {
				//Tooling Option
				options.push({
					itemClick: () => this.handleOpenItemDialog('openModalTooling'),
					tooltipTranslation: <Translate id='quotes.toolingQuote' />,
					menuItemIcon: <BuildIcon />,
				})
				//Cubicaje Option
				options.push({
					itemClick: () => this.handleOpenItemDialog('openModalCubicaje'),
					tooltipTranslation: <Translate id='quotes.cubicajeQuote' />,
					menuItemIcon: <BoxIcon />,
				})
				//Decline Option
				options.push({
					itemClick: () => this.handleOpenItemDialog('openModalDecline'),
					tooltipTranslation: <Translate id='quotes.declineQuote' />,
					menuItemIcon: <RejectIcon />,
				})
			}
		}

		if (
			canBeValidateModuleDevelopment(this.props.userRolesByCompany) &&
			this.state.quote.update_quote_by_company &&
			item.status !== APPROVAL_DECLINED
		) {
			//Modal to accept, reject or requote for development enginner
			options.push({
				itemClick: () => this.onClickShowDialogEnginner(item.token),
				tooltipTranslation: <Translate id='quotes.actionsByDevelopmentEngineer' />,
				menuItemIcon: <ActionsIcon />,
			})
		}
		return (
			<OptionsMenu
				itemToken={item.token}
				handleClickOptions={this.handleClick}
				open={this.state.itemToken === item.token}
				handleClickAway={this.handleClose}
				options={options}
				xsSize
			/>
		)
	}

	/**
	 * Method to render text field component
	 *
	 * @param {*} dataSource
	 * @param {*} translationId
	 * @param {*} isActive
	 * @returns
	 * @memberof DetailsCard
	 */
	renderTextField(dataSource, translationId, isActive) {
		if (isActive) {
			return (
				<TextField
					id={dataSource}
					label={<Translate id={translationId} />}
					defaultValue={this.state.quote[dataSource]}
					margin='none'
					disabled={!isActive}
					type='text'
					onBlur={(event) => this.updateQuoteProperty(event.target)}
					onKeyDown={this.onKeyDown}
				/>
			)
		} else {
			return (
				<Typography color={'textPrimary'}>
					<Translate id={translationId} />
					{': '}
					{this.state.quote[dataSource]}
				</Typography>
			)
		}
	}

	renderTextFieldNumber(dataSource, translationId, isActive) {
		if (isActive) {
			return (
				<TextField
					id={dataSource}
					label={<Translate id={translationId} />}
					defaultValue={this.state.quote[dataSource]}
					margin='none'
					disabled={!isActive}
					type='number'
					onBlur={(event) => this.updateQuoteProperty(event.target)}
					onKeyDown={this.onKeyDown}
				/>
			)
		} else {
			return (
				<Typography color={'textPrimary'}>
					<Translate id={translationId} />
					{': '}
					{this.state.quote[dataSource]}
				</Typography>
			)
		}
	}

	renderStrikeThroughText(text) {
		return <span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>{text}</span>
	}

	/**
	 * Validate Cost unt with 4 decemals
	 * @param {*} dataSource
	 * @param {*} valueItem
	 */
	hasInvalidCostUnit(dataSource, valueItem) {
		if (this.state.quote && this.state.quote.company_identifier === COMPANY_PTM) return false
		if (dataSource !== 'unitary_cost') return false
		else {
			var regexDecinals = /^([0-9]+\.?[0-9]{0,4})$/
			if (regexDecinals.test(valueItem)) return false
			else return true
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		let isActive =
			IsCurrentNotActiveDirectoryUser(this.props.currentUser) &&
			this.state.quote.update_quote_by_supplier &&
			item.status !== APPROVAL_ACEPTED
		let optionsMeasurementUnit = GetMeasurementUnits()
		let kind = 1
		let formatype = 1
		if (this.props.quote.alternating_proveeduria_type === 2) {
			kind = 2
			formatype = 2
		}
		if (dataSource === 'supplier_code' || (dataSource === 'unitary_cost' && item.cost_type !== '4')) {
			if (isActive)
				return (
					<TextField
						id={dataSource}
						defaultValue={item[dataSource]}
						margin='normal'
						disabled={!isActive}
						type={dataSource === 'unitary_cost' ? 'number' : 'text'}
						onBlur={(event) => this.saveItem(event.target, item.token)}
						onKeyDown={this.onKeyDown}
						error={this.hasInvalidCostUnit(dataSource, item[dataSource])}
						helperText={
							this.hasInvalidCostUnit(dataSource, item[dataSource]) && (
								<Translate id='quotes.validateCostUnit' />
							)
						}
					/>
				)
			if (!isActive) {
				if (item.status !== APPROVAL_DECLINED) return item[dataSource]
				else return this.renderStrikeThroughText(item[dataSource])
			}
		}

		if (dataSource === 'plane') {
			return (
				<FilesLinks
					item={item}
					hasPlot
					companyToken={this.props.quote.company_token}
					companyCode={this.props.quote.company_identifier}
					strikeThrough={item.status === APPROVAL_DECLINED}
					kind={kind}
					formatype={formatype}
					downloadFile={this.props.downloadPlotFiles}
				/>
			)
		}

		if (dataSource === 'cost_type') {
			let optionsCostType = GetTypeQuote(this.props.quote.company_identifier)
			return (
				<div style={{ minWidth: '120px' }}>
					{isActive && (
						<Select
							defaultValue={optionsCostType[item.cost_type]}
							options={optionsCostType}
							isDisabled={!isActive}
							fullWidth
							onChange={(event) => this.onChangeCostType(event.value, 'cost_type', item.token)}
							styles={{
								menu: (base) => ({ ...base, zIndex: 10 }),
							}}
							menuPortalTarget={document.body}
						/>
					)}
					{!isActive &&
						item.status !== APPROVAL_DECLINED &&
						optionsCostType[item.cost_type] &&
						optionsCostType[item.cost_type].label}
					{!isActive && item.status === APPROVAL_DECLINED && this.renderStrikeThroughText(item[dataSource])}
				</div>
			)
		}
		if (dataSource === 'volume') {
			if (item.status !== APPROVAL_DECLINED)
				return (
					<span>
						{item.volume} {item.volumneUnit !== 0 && optionsMeasurementUnit[item.volumneUnit].label}
					</span>
				)
			else
				return (
					<span>
						{this.renderStrikeThroughText(item.volume)}
						{item.volumneUnit !== 0 &&
							this.renderStrikeThroughText(optionsMeasurementUnit[item.volumneUnit].label)}
					</span>
				)
		}
		if (dataSource === 'unitary_cost' && item.cost_type === '4') {
			return (
				<IconButton color='primary' onClick={() => this.handleClickAddScales(item.token)}>
					<Tooltip title={<Translate id={'quotes.addScales'} />}>
						<ScaleIcon />
					</Tooltip>
				</IconButton>
			)
		}

		if (dataSource === 'measurement_unit') {
			return (
				<div style={{ minWidth: '120px' }}>
					{isActive && (
						<Select
							defaultValue={optionsMeasurementUnit[item.measurement_unit]}
							options={optionsMeasurementUnit}
							isDisabled={!isActive}
							fullWidth
							onChange={(event) => this.onChangeMeasurement(event.value, 'measurement_unit', item.token)}
							styles={{
								menu: (base) => ({ ...base, zIndex: 10 }),
							}}
							menuPortalTarget={document.body}
						/>
					)}
					{!isActive &&
						item.status !== APPROVAL_DECLINED &&
						item.measurement_unit > 0 &&
						optionsMeasurementUnit[item.measurement_unit] &&
						optionsMeasurementUnit[item.measurement_unit].label}
					{!isActive &&
						item.status === APPROVAL_DECLINED &&
						item.measurement_unit > 0 &&
						optionsMeasurementUnit[item.measurement_unit] &&
						this.renderStrikeThroughText(optionsMeasurementUnit[item.measurement_unit].label)}
				</div>
			)
		}

		if (dataSource === 'options') {
			return this.renderOptionsMenu(item)
		}
		if (item.status === APPROVAL_DECLINED) return this.renderStrikeThroughText(item[dataSource])
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderScalesCellItem(dataSource, item) {
		if (dataSource === 'scale') {
			return `${item.lower_limit} - ${item.upper_limit}`
		}
	}

	renderAdditionalItem(title, content, icon) {
		return (
			<Tooltip title={<Translate id={title} />} placement='left'>
				<Grid container spacing={0} style={{ cursor: 'pointer' }}>
					<Grid item xs={1} style={{ maxWidth: 30 }}>
						{icon}
					</Grid>
					<Grid item xs={11}>
						{content}
					</Grid>
				</Grid>
			</Tooltip>
		)
	}

	/**
	 * On Render aditional row data
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof DetailsCard
	 */
	onRenderCellAditionalRowItem(dataSource, item) {
		let xsIcon = { maxWidth: 30, maxHeight: 30, paddingTop: 3 }
		if (dataSource === 'comment_detail' && item[dataSource] && !IsNullOrEmpty(item[dataSource])) {
			let content = (
				<React.Fragment>
					<Typography component='p'>{item[dataSource]}</Typography>
				</React.Fragment>
			)
			let icon = <MessageIcon style={xsIcon} color='secondary' />
			return this.renderAdditionalItem('quotes.developerComments', content, icon)
		}
		if (dataSource === 'tooling' && item[dataSource] && !IsNullOrEmpty(item[dataSource].description)) {
			let content = (
				<React.Fragment>
					<Typography component='p'>{item[dataSource].tooling_full_description}</Typography>
				</React.Fragment>
			)
			let icon = <BuildIcon style={xsIcon} color='secondary' />
			return this.renderAdditionalItem('quotes.toolingTooltip', content, icon)
		}
		if (dataSource === 'scales' && item[dataSource] && item[dataSource].length > 0) {
			let content = (
				<React.Fragment>
					<DataTable
						data={item[dataSource]}
						configuration={TableScalesConfiguration(false)}
						onRenderCellItem={this.onRenderScalesCellItem}
					/>
				</React.Fragment>
			)
			let icon = <ScaleIcon style={xsIcon} color='secondary' />
			return this.renderAdditionalItem('quotes.scalesTooltip', content, icon)
		}
		if (dataSource === 'cubicaje' && item[dataSource] && !IsNullOrEmpty(item[dataSource].container_type)) {
			let optionsContainerType = GetContainerType()
			let content = (
				<React.Fragment>
					<Typography component='p'>
						<Translate id={'quotes.containerType'} />
						{': '}
						{optionsContainerType[item[dataSource].container_type].label}
					</Typography>
					<Typography component='p'>
						<Translate id={'quotes.quantityPerContainer'} />
						{': '}
						{item[dataSource].quantity_per_container}
					</Typography>
					<Typography component='p'>
						<Translate id={'quotes.numberPieces'} />
						{': '}
						{item[dataSource].number_pieces}
					</Typography>
					<Typography component='p'>
						<Translate id={'quotes.width'} />
						{': '}
						{item[dataSource].width}
					</Typography>
					<Typography component='p'>
						<Translate id={'quotes.length'} />
						{': '}
						{item[dataSource].length}
					</Typography>
					<Typography component='p'>
						<Translate id={'quotes.heigth'} />
						{': '}
						{item[dataSource].heigth}
					</Typography>
					<Typography component='p'>
						<Translate id={'quotes.weight'} />
						{': '}
						{item[dataSource].weight}
					</Typography>
				</React.Fragment>
			)
			let icon = <BoxIcon style={xsIcon} color='secondary' />
			return this.renderAdditionalItem('quotes.cubicajeTooltip', content, icon)
		}
		if (dataSource === 'accepted_or_reject_date_time') {
			if (item['accepted_or_reject_comment'] && !IsNullOrEmpty(item['accepted_or_reject_comment'])) {
				let statusTranslation =
					item['status'] === APPROVAL_ACEPTED
						? 'quotes.approval'
						: item['status'] === APPROVAL_REJECTED
						? 'quotes.reject'
						: 'quotes.requote'
				let validatedByTranslation =
					item['status'] === APPROVAL_ACEPTED
						? 'quotes.acceptedBy'
						: item['status'] === APPROVAL_REJECTED
						? 'quotes.rejectedBy'
						: 'quotes.requotedBy'
				let content = (
					<React.Fragment>
						<Typography component='p'>
							<Translate id={'common.date'} />
							{': '}
							{item['accepted_or_reject_date_time']}
						</Typography>
						<Typography component='p'>
							<Translate id={validatedByTranslation} />
							{': '}
							{item['accepted_or_rejected_by_name']}
						</Typography>
						<Typography component='p'>
							<Translate id={'common.comment'} />
							{': '}
							{item['accepted_or_reject_comment']}
						</Typography>
					</React.Fragment>
				)
				let icon =
					item['status'] === APPROVAL_ACEPTED ? (
						<AcceptIcon style={xsIcon} color='secondary' />
					) : item['status'] === APPROVAL_REJECTED ? (
						<RejectIcon style={xsIcon} color='error' />
					) : (
						<RequoteIcon style={xsIcon} color='secondary' />
					)

				return this.renderAdditionalItem(statusTranslation, content, icon)
			} else return 'skip'
		}
		if (dataSource === 'declined_datetime') {
			if (item['declined_comment'] && !IsNullOrEmpty(item['declined_comment'])) {
				let content = (
					<React.Fragment>
						<Typography component='p'>
							<Translate id={'common.date'} />
							{': '}
							{item['declined_datetime']}
						</Typography>
						<Typography component='p'>
							<Translate id={'quotes.declinedBy'} />
							{': '}
							{item['declined_by_name']}
						</Typography>
						<Typography component='p'>
							<Translate id={'common.comment'} />
							{': '}
							{item['declined_comment']}
						</Typography>
					</React.Fragment>
				)
				let icon = <RejectIcon style={xsIcon} color='error' />
				return this.renderAdditionalItem('quotes.decline', content, icon)
			} else return 'skip'
		}
	}

	/**
	 * Method to render grid item
	 *
	 * @param {*} content
	 * @param {number} [xs=10]
	 * @returns
	 * @memberof DetailsCard
	 */
	renderGridProperty(content, xs = 10) {
		return (
			<Grid item xs={xs}>
				<Paper className={this.props.classes.paper}>{content}</Paper>
			</Grid>
		)
	}

	/**
	 * Render a text field and a select inside a div like 1 field
	 *
	 * @param {*} textFieldId
	 * @param {*} textFieldTranslateId
	 * @param {*} selectId
	 * @param {*} selectOptions
	 * @returns
	 * @memberof DetailsCard
	 */
	renderTextFieldAndSelect(textFieldId, textFieldTranslateId, selectId, selectOptions) {
		let isActive =
			IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.state.quote.update_quote_by_supplier
		return (
			<div>
				{isActive && (
					<React.Fragment>
						<div style={{ display: 'inline-flex', width: '45%' }}>
							<TextField
								id={textFieldId}
								label={<Translate id={textFieldTranslateId} />}
								defaultValue={this.state.quote[textFieldId]}
								margin='normal'
								disabled={!isActive}
								type='number'
								fullWidth
								onBlur={(event) => this.updateQuoteProperty(event.target)}
								onKeyDown={this.onKeyDown}
							/>
						</div>
						<div style={{ display: 'inline-block', width: '55%', paddingLeft: '5px' }}>
							<Select
								id={selectId}
								defaultValue={selectOptions[this.state.quote[selectId]]}
								options={selectOptions}
								isDisabled={!isActive}
								onChange={(option) => this.updateQuoteSelectProperty(option, selectId)}
								styles={{
									menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
									menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
									menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
								}}
							/>
						</div>
					</React.Fragment>
				)}
				{!isActive && (
					<Typography color={'textPrimary'}>
						<Translate id={textFieldTranslateId} />
						{': '}
						{this.state.quote[textFieldId]} {selectOptions[this.state.quote[selectId]].label}
					</Typography>
				)}
			</div>
		)
	}

	renderCurrencySelect(textFieldTranslateId, selectId, selectOptions) {
		let isActive =
			IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.state.quote.update_quote_by_supplier
		return (
			<div>
				{isActive && (
					<div style={{ display: 'inline-block', width: '90%', paddingLeft: '5px' }}>
						<Typography variant={'caption'} color={'textSecondary'}>
							<Translate id={textFieldTranslateId} />
						</Typography>
						<Select
							id={selectId}
							defaultValue={selectOptions[this.state.quote[selectId]]}
							options={selectOptions}
							isDisabled={!isActive}
							onChange={(option) => this.updateQuoteSelectProperty(option, selectId)}
							styles={{
								menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
								menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
								menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
							}}
						/>
					</div>
				)}
				{!isActive && (
					<Typography color={'textPrimary'}>
						<Translate id={textFieldTranslateId} />
						{': '}
						{selectOptions[this.state.quote[selectId]].label}
					</Typography>
				)}
			</div>
		)
	}

	/**
	 * Method to render partnership data section
	 *
	 * @returns
	 * @memberof DetailsCard
	 */
	getPartnershipData() {
		const { partnership, quote, classes } = this.props
		let optionsMeasurement = GetMeasurementUnits()
		let optionsTime = GetTimeUnit()
		let optionsCurrency = GetCurrencyType()
		let optionsIcoterms = GetIncotermsQuotes()
		let isActive =
			IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.state.quote.update_quote_by_supplier
		return (
			<div className={classes.parnertPadding}>
				<Typography variant='subtitle1' gutterBottom>
					<Translate id='quotes.project' />: {quote.project_name}
				</Typography>
				<Divider component='li' />
				<Grid container spacing={24}>
					<Grid item xs={5}>
						<Grid container spacing={24}>
							{(quote.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
								quote.company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE) && (
								<Grid item xs={6}>
									{quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_BRASIL &&
										this.renderGridProperty(
											<div>
												<br />
												<Translate id='quotes.tax' />: {quote.tax} %
											</div>,
											6
										)}
									{this.renderGridProperty(
										<div>
											<br />
											<Translate id='quotes.creditTerms' />:{' '}
											{partnership && partnership.condition_credit_full}
										</div>
									)}
								</Grid>
							)}
							{(quote.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
								quote.company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE) && (
								<Grid item xs={6}>
									{isActive &&
										this.renderGridProperty(
											<div>
												<br />
												{this.renderCurrencySelect(
													'quotes.currency',
													'currency',
													optionsCurrency
												)}
											</div>
										)}
									{isActive &&
										quote.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
										this.renderGridProperty(
											<div>
												<br />
												{this.renderCurrencySelect(
													'quotes.incoterm',
													'incoterm',
													optionsIcoterms
												)}
											</div>
										)}
									{isActive &&
										quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_MX &&
										this.renderGridProperty(
											<div>
												<br />
												{this.renderTextField('incoterm', 'quotes.incoterm', isActive)}
											</div>
										)}
									{!isActive &&
										this.renderGridProperty(
											<div>
												<br />
												{this.renderGridProperty(
													this.renderTextField('currency_type', 'quotes.currency', false)
												)}
											</div>
										)}
									{!isActive &&
										this.renderGridProperty(
											<div>
												<br />
												{this.renderGridProperty(
													this.renderTextField('incoterm', 'quotes.incoterm', false)
												)}
											</div>
										)}
								</Grid>
							)}

							{quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_MX &&
								quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_BRASIL &&
								quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_REPARE &&
								this.renderGridProperty(
									<div>
										<Translate id='quotes.tax' />: {quote.tax} %
									</div>,
									6
								)}
							{quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_MX &&
								quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_REPARE &&
								this.renderGridProperty(
									<div>
										<Translate id='quotes.currency' />:{' '}
										{quote.currency_type
											? quote.currency_type
											: partnership && partnership.order_currency}
									</div>,
									6
								)}
							{quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_MX &&
								quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_REPARE &&
								this.renderGridProperty(
									<div>
										<Translate id='quotes.creditTerms' />:{' '}
										{partnership && partnership.condition_credit_full}
									</div>,
									6
								)}
							{quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_MX &&
								quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_REPARE &&
								this.renderGridProperty(
									<div>
										<Translate id='quotes.incoterm' />: {partnership && partnership.incoterm}
									</div>,
									6
								)}
						</Grid>
						{quote.is_supplier_national &&
							quote.company_identifier === COMPANY_IDENTIFIER_IMBERA_BRASIL && (
								<Grid container spacing={24}>
									<Grid item xs={6}>
										{this.renderGridProperty(
											this.renderTextField('tax_ipi', 'quotes.taxIpi', isActive)
										)}
										{this.renderGridProperty(
											this.renderTextField('tax_pis', 'quotes.taxPis', isActive)
										)}
									</Grid>
									<Grid item xs={6}>
										{this.renderGridProperty(
											this.renderTextField('tax_confins', 'quotes.taxConfins', isActive)
										)}
										{this.renderGridProperty(
											this.renderTextField('tax_icms', 'quotes.taxIcms', isActive)
										)}
									</Grid>
								</Grid>
							)}
						{!quote.is_supplier_national &&
							quote.company_identifier === COMPANY_IDENTIFIER_IMBERA_BRASIL && (
								<Grid container spacing={24}>
									<Grid item xs={6}>
										{this.renderGridProperty(
											this.renderTextField('tax_importation', 'quotes.taxImportation', isActive)
										)}
									</Grid>

									{this.renderGridProperty(
										<div>
											<br />
											<Typography variant='body2'>
												{<Translate id='quotes.taxMarine' />}:
											</Typography>{' '}
											{' 25%'}
										</div>,
										6
									)}
								</Grid>
							)}
						{quote.company_identifier === COMPANY_IDENTIFIER_IMBERA_BRASIL && (
							<Grid container spacing={24}>
								<Grid item xs={6}>
									{this.renderGridProperty(
										this.renderTextFieldNumber('tax_price', 'quotes.taxPrice', isActive)
									)}
								</Grid>
								<Grid item xs={6}>
									{this.renderGridProperty(
										this.renderTextField('code_ncm', 'quotes.codeNcm', isActive)
									)}
								</Grid>
							</Grid>
						)}
					</Grid>
					<Grid item xs={7}>
						<Grid container justify='center' spacing={24}>
							{this.renderGridProperty(
								this.renderTextFieldAndSelect(
									'minimum_lot_purchase',
									'quotes.minimumLot',
									'minimum_lot_unit',
									optionsMeasurement
								)
							)}
							{this.renderGridProperty(
								this.renderTextFieldAndSelect(
									'delivery_time',
									'quotes.deliveryTime',
									'delivery_time_unit',
									optionsTime
								)
							)}
							{quote.company_identifier !== COMPANY_IDENTIFIER_IMBERA_MX &&
								this.renderGridProperty(
									<React.Fragment>
										<Typography
											variant={isActive ? 'caption' : ''}
											color={isActive ? 'textSecondary' : 'textPrimary'}
										>
											<Translate id='quotes.deliveryPlace' />
										</Typography>
										<WarehouseSelector
											isDisabled={!isActive}
											companyToken={this.props.quote.company_token}
											onWarehouseSelected={(option) =>
												this.updateQuoteSelectProperty(option, 'place_of_delivery')
											}
											value={this.state.quote ? this.state.quote['place_of_delivery'] : ''}
										/>
									</React.Fragment>
								)}
							{quote.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
								<div style={{ display: 'inline-flex', width: '80%' }}>
									{this.renderGridProperty(
										this.renderTextField('place_of_delivery', 'quotes.deliveryPlace', isActive)
									)}
								</div>
							)}
						</Grid>
					</Grid>
					<ScaleQuoteDialog
						isSaving={this.props.isSaving}
						open={this.state.openModalAddScales}
						onClose={() => this.handleOnCloseItemDialog('openModalAddScales')}
						tableScalesConfiguration={TableScalesConfiguration(isActive)}
						saveScaleItem={this.saveScaleItem}
						deleteScaleItem={this.deleteScaleItem}
						quoteItemToken={this.state.scaleItemToken}
						currency={quote.currency_type ? quote.currency_type : partnership && partnership.order_currency}
						isVisible={isActive}
						detailsList={this.props.quote.details}
						classes={this.props.classes}
					/>
				</Grid>
			</div>
		)
	}

	/**
	 * Method to update quote properties in state and call action
	 *
	 * @param {*} data
	 * @memberof DetailsCard
	 */
	updateQuoteProperty(data) {
		this.setState(
			{
				quote: {
					...this.state.quote,
					[data.id]: data.value,
				},
			},
			() => {
				this.props.updateQuoteProperty(this.props.quote.token, data.id, data.value)
			}
		)
	}

	/**
	 * Method to update property of quote is input is a selector in state and call action
	 *
	 * @param {*} data
	 * @param {*} property
	 * @memberof DetailsCard
	 */
	updateQuoteSelectProperty(data, property) {
		this.setState(
			{
				quote: {
					...this.state.quote,
					[property]: data.value,
				},
			},
			() => {
				this.props.updateQuoteProperty(this.props.quote.token, property, data.value)
			}
		)
	}

	/**
	 * onClickShowDialogEnginner
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onClickShowDialogEnginner(itemToken) {
		this.setState({
			acceptedEnginnerDialog: true,
			itemToken: itemToken,
		})
	}

	/***
	 * updateAcceptedItemProperty
	 */
	updateAcceptedItemProperty(value) {
		this.setState({
			commentEnginner: value,
		})
	}

	onClickAcceptRejectRequote(toStatus) {
		if (this.props.acceptedOrRejectedDetailItemEnginner) {
			if (this.state.itemToken !== 'selection') {
				this.props.acceptedOrRejectedDetailItemEnginner(
					this.state.itemToken,
					this.state.commentEnginner,
					toStatus
				)
			} else {
				this.props.acceptRejectRequoteDetailItemsEnginner(
					this.state.selectedTokens,
					this.state.commentEnginner,
					toStatus
				)
			}
			this.setState({ acceptedEnginnerDialog: false, itemToken: null, selectedTokens: [] })
		}
	}

	onSelectedChecks(selectedTokens) {
		let filteredSelectedTokens = []
		let item
		selectedTokens.forEach((selectedToken) => {
			item = this.props.quote.details.find((detail) => detail.token === selectedToken)
			if (item && item.status !== APPROVAL_DECLINED) filteredSelectedTokens.push(selectedToken)
		})
		this.setState({ selectedTokens: filteredSelectedTokens })
	}

	/**
	 * Set Custom background color foreach detail row
	 *
	 * @memberof DetailsCard
	 */
	setCustomColorRow() {
		this.props.quote.details.map((item) => {
			var customColor
			if (item.status === APPROVAL_ACEPTED) customColor = COLOR_STATUS_ACEPTED
			if (item.status === APPROVAL_REQUOTE) customColor = COLOR_STATUS_REQUOTE
			if (item.status === APPROVAL_DECLINED) customColor = COLOR_STATUS_DECLINED
			if (item.status === APPROVAL_REJECTED) customColor = COLOR_STATUS_REJECTED
			return (item.custom_color = customColor)
		})
	}

	/**
	 * On change files of dropzone
	 *
	 * @param {*} event
	 * @memberof DetailsCard
	 */
	onChangeFile(files) {
		return this.props
			.uploadFiles(this.state.quote.token, files)
			.then(() => {
				this.props.showSuccessToaster()
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.props.showErrorToaster()
				return Promise.reject()
			})
	}

	/**
	 * On remove files of dropzone
	 *
	 * @param {*} event
	 * @memberof DetailsCard
	 */
	onDeleteFile(fileName) {
		return this.props
			.removeFiles(this.state.quote.token, fileName)
			.then((response) => {
				this.props.showSuccessToaster()
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.props.showErrorToaster()
				return Promise.reject()
			})
	}

	/**
	 * Get the details list
	 *
	 * @returns
	 * @memberof DetailsCard
	 */
	renderDetailsList() {
		const { quote } = this.props
		this.setCustomColorRow()

		let isActive =
			IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.state.quote.update_quote_by_supplier
		let showSelectChecks =
			canBeValidateModuleDevelopment(this.props.userRolesByCompany) && this.state.quote.update_quote_by_company
		if (quote) {
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Divider />
						<Grid container spacing={24} justify='flex-end'>
							{showSelectChecks && this.state.selectedTokens.length > 0 && (
								<Grid item xs={0.5}>
									{this.renderOptionsMenu({ token: 'selection' })}
								</Grid>
							)}
						</Grid>
						<DataTable
							data={quote.details}
							configuration={TableDetailsConfiguration(showSelectChecks)}
							onRenderCellItem={this.onRenderCellItem}
							collapsableChild
							aditionalRows={AditionalRows}
							onRenderCellAditionalRowItem={this.onRenderCellAditionalRowItem}
							onSelectedCheck={(selectedTokens) => {
								this.onSelectedChecks(selectedTokens)
							}}
							selectedTokens={this.state.selectedTokens}
						/>
					</Grid>
					<Grid item xs={12}>
						{this.getPartnershipData()}
					</Grid>
					<Grid item xs={12}>
						<HtmlEditor
							id='comments_supplier'
							label={'quotes.supplierObservations'}
							labelVariant={'subtitle1'}
							isDisabled={!isActive}
							initialValue={this.state.comments_supplier}
							onChange={(data) => this.updateQuoteProperty(data)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Typography variant='subtitle1' gutterBottom>
									<Translate id='quotes.quoteArchives' />
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Dropzone
									files={this.props.quote.attachments}
									onChange={this.onChangeFile}
									onDeleteFile={this.onDeleteFile}
									isDisabled={!isActive}
									baseApi={process.env.REACT_APP_SIP_URL_FILES}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)
		} else {
			return <LoadingPage />
		}
	}

	/**
	 * Call action to save tooling data
	 *
	 * @param {*} itemToken
	 * @param {*} toolingData
	 * @memberof DetailsCard
	 */
	saveToolingItem(itemToken, toolingData) {
		var detailToUpdate = this.state.quote.details.filter((x) => x.token === itemToken)
		if (detailToUpdate.length !== 0) {
			detailToUpdate[0]['tooling'] = toolingData
			this.props
				.updateQuoteToolingDetailItem(detailToUpdate[0])
				.then(() => {
					this.props.showSuccessToaster()
					this.handleOnCloseItemDialog('openModalTooling')
					return Promise.resolve()
				})
				.catch(() => {
					this.props.showErrorToaster()
					return Promise.reject()
				})
		}
	}

	/**
	 * Call action to save cubicaje data
	 *
	 * @param {*} itemToken
	 * @param {*} toolingData
	 * @memberof DetailsCard
	 */
	saveCubicajeItem(itemToken, cubicajeData) {
		var detailToUpdate = this.state.quote.details.filter((x) => x.token === itemToken)
		if (detailToUpdate.length !== 0) {
			detailToUpdate[0]['cubicaje'] = cubicajeData
			this.props
				.updateQuoteCubicajeDetailItem(detailToUpdate[0])
				.then(() => {
					this.props.showSuccessToaster()
					this.handleOnCloseItemDialog('openModalCubicaje')
					return Promise.resolve()
				})
				.catch(() => {
					this.props.showErrorToaster()
					return Promise.reject()
				})
		}
	}

	/**
	 * Call action to save scales data
	 *
	 * @param {*} itemToken
	 * @param {*} scalesData
	 * @memberof DetailsCard
	 */
	saveScaleItem(itemToken, scalesData) {
		var detailToUpdate = this.state.quote.details.filter((x) => x.token === itemToken)
		if (detailToUpdate.length !== 0) {
			detailToUpdate[0]['scales'] = scalesData
			this.props
				.addQuoteScalesDetailItem(detailToUpdate[0])
				.then(() => {
					this.props.showSuccessToaster()
					return Promise.resolve()
				})
				.catch(() => {
					this.props.showErrorToaster()
					return Promise.reject()
				})
		}
	}

	/**
	 * Call action to delete scale data
	 *
	 * @param {*} scaleToken
	 * @memberof DetailsCard
	 */
	deleteScaleItem(scaleToken) {
		this.props
			.deleteQuoteScalesDetailItem(scaleToken)
			.then(() => {
				this.props.showSuccessToaster()
				return Promise.resolve()
			})
			.catch(() => {
				this.props.showErrorToaster()
				return Promise.reject()
			})
	}

	render() {
		return (
			<Paper>
				{this.renderDetailsList()}
				{/** Dialog to accepted or rejected detail item from engineer */}
				<AcceptedRejectedDialog
					updateAcceptedItemProperty={this.updateAcceptedItemProperty}
					comments={this.state.commentEnginner}
					open={this.state.acceptedEnginnerDialog}
					onClose={() => {
						this.setState({ acceptedEnginnerDialog: false })
					}}
					onClickAcceptRejectRequote={this.onClickAcceptRejectRequote}
					isSaving={this.props.isSaving}
				/>
				<DeclineQuoteDialog
					isSaving={this.props.isSaving}
					open={this.state.openModalDecline}
					onClose={() => this.handleOnCloseItemDialog('openModalDecline')}
					declineQuoteItem={this.props.declineQuoteItem}
					quoteItemToken={this.state.itemToken}
				/>
				<ToolingQuoteDialog
					isSaving={this.props.isSaving}
					open={this.state.openModalTooling}
					onClose={() => this.handleOnCloseItemDialog('openModalTooling')}
					saveToolingItem={this.saveToolingItem}
					quoteItemToken={this.state.itemToken}
					detailsList={this.state.quote.details}
				/>
				<CubicajeQuoteDialog
					isSaving={this.props.isSaving}
					open={this.state.openModalCubicaje}
					onClose={() => this.handleOnCloseItemDialog('openModalCubicaje')}
					saveCubicajeItem={this.saveCubicajeItem}
					quoteItemToken={this.state.itemToken}
					detailsList={this.state.quote.details}
				/>
				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Paper>
		)
	}
}

function TableDetailsConfiguration(showCheckSelect) {
	let columns = [
		{
			header: '',
			dataSource: 'expand',
		},
		{
			header: <Translate id='quotes.codeSupplier' />,
			dataSource: 'supplier_code',
		},
		{
			header: <Translate id='quotes.partNumberImbera' />,
			dataSource: 'part_number',
		},
		{
			header: <Translate id='quotes.plane' />,
			dataSource: 'plane',
		},
		{
			header: <Translate id='quotes.typeQuote' />,
			dataSource: 'cost_type',
		},
		{
			header: <Translate id='quotes.estimatedVolume' />,
			dataSource: 'volume',
		},
		{
			header: <Translate id='quotes.unitPrice' />,
			dataSource: 'unitary_cost',
		},
		{
			header: <Translate id='quotes.measuremetUnit' />,
			dataSource: 'measurement_unit',
		},
		{
			header: <Translate id='quotes.tooling' />,
			dataSource: 'tooling_total',
		},
		{
			header: <Translate id='quotes.totalPrice' />,
			dataSource: 'total_cost',
		},
		{
			header: '',
			dataSource: 'options',
		},
	]
	if (showCheckSelect)
		columns.push({
			header: '',
			dataSource: 'selectCheck',
		})
	return { columns }
}

const AditionalRows = {
	rows: [
		{
			dataSource: 'tooling',
			propertyInObject: 'description',
			type: 'dataString',
		},
		{
			dataSource: 'scales',
			type: 'dataList',
		},
		{
			dataSource: 'cubicaje',
			type: 'dataObject',
		},
		{
			dataSource: 'accepted_or_reject_date_time',
			type: 'dataString',
		},
		{
			dataSource: 'declined_datetime',
			type: 'dataString',
		},
		{
			dataSource: 'comment_detail',
			type: 'dataString',
		},
	],
}

function TableScalesConfiguration(isActive) {
	let columns = [
		{
			header: <Translate id='quotes.scale' />,
			dataSource: 'scale',
		},
		{
			header: <Translate id='quotes.cost' />,
			dataSource: 'cost',
		},
	]
	if (isActive)
		columns.push({
			header: '',
			dataSource: 'options',
		})
	return { columns }
}

export default DetailsCard
