///Import react section
import { useEffect } from 'react';
import { useSelector, useDispatch, } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { getTranslate } from "react-localize-redux";

/**Import actions sections */
import {
    loadAllIndirectQualificationsFromBackEnd,
    addIndirectQualification,
    downloadReportRatings,
    setToasterOptions
} from '../../../store/indirectqualifications/IndirectqualificationsActions'
/**Import helpers sections */
import { converListToArray } from '../../../store/helpers/ListHelper'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';

export const useIndirectQualificationCustomHookIndex = () => {
    /**use selector information */
    const indirectsQualifications = useSelector((state) => state.indirectQualifications.get('indirectsQualifications') ? converListToArray(state.indirectQualifications.get('indirectsQualifications')) : [])
    const indirectsQualificationsByPeriod = useSelector((state) => state.indirectQualifications.get('indirectsQualificationsByPeriod') ? converListToArray(state.indirectQualifications.get('indirectsQualificationsByPeriod')) : [])
    
    const translate = useSelector((state) => getTranslate(state.localize))

    /**Render hooks section */
    const dispatch = useDispatch()
    let location = useLocation()

    /**Use effect section */
    useEffect(() => {
        const queryParams = queryString.parse(location.search)
        dispatch(loadAllIndirectQualificationsFromBackEnd(queryParams, translate))
    }, [dispatch, location.search])

    const onAddIndirectQualification = (model) => {
        return dispatch(addIndirectQualification(model, translate))
            .then(() => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                }))
            })
            .catch((response) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: (getErrorInChangeStatus(response)),
                    toasterVariant: "error",
                    name: model.name
                }))
            })
    }

     /**
    * Method to show error in import suppliers
    * @param {*} error 
    */
      const getErrorInChangeStatus = (response) => {
        if (!IsNullOrEmpty(response) && typeof response === 'string') {
            if (response.includes("Duplicated"))
                return ("indirectqualifications.errorDuplicatedFolio")
            if (response.includes("indirectqualifications.invaidDateShipping"))
                return ("indirectqualifications.invaidDateShipping")
            else
                return ("common.errorToSave");
        }
        else {
            return ("common.errorToSave");
        }
    }

    const onDownloadReportRatings = (startDate, endDate) => {
        return dispatch(downloadReportRatings(startDate, endDate))
            .then(() => {
            })
            .catch((response) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: "common.errorToSave",
                    toasterVariant: "error",
                }))
            })
    }

    return { onAddIndirectQualification, onDownloadReportRatings, indirectsQualifications, indirectsQualificationsByPeriod }
}