import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import LoadingPage from '../../common/LoadingPage';
import Toaster from '../../common/Toaster';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import DownloadUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';

/** Language imports **/
import { Translate } from "react-localize-redux";

/** Import section actions */
import {updateReleasePlanManuallyFromBack} from '../../../store/planning/ReleasePlanAction';

/**
 * ReleasePlan supplier 
 *
 * @class ReleasePlan list
 * @extends {Component}
 */
class UpdateRelease extends Component {

    /**
     * Create an instance of ReleasePlan list
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
        }
    }
    
    /**
     * Action to add release in plan and update
     */
    onUpdateReleasePlan(){
        this.props.updateReleasePlanManually()
        .then(() => {
            this.setState({
                showToaster: true,
                toasterVariant: "success",
                toasterMessage: <Translate id="common.saveCorrectly" />,
            })
        }).catch(() => {
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="common.errorToSave" />,
                toasterVariant: "error",
            })
        });
    }

    /**
     * Get the card content
     *
     * @memberof ReleasePlan
     */
    getCardContent() {
        if (this.props.isUpdatingReleasePlan === true) {
            return (
                <LoadingPage />
            )
        }
        else {
            const { classes} = this.props;
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Divider />

                        <span>{<Translate id="releasePlan.messageUpdateRelease"/>}</span><br/><br/>

                        <Button variant="contained" onClick={this.onUpdateReleasePlan} color="primary" className={classes.button}>
                            <span><DownloadUploadIcon /> 
                            {"   "} <Translate id="releasePlan.updateRelease"/>
                            </span>
                        </Button>

                    </Grid>
                </Grid>
            )
        }
    }

    /**
     * Render
     *
     * @returns
     * @memberof Supplier
     */
    render() {
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <DownloadUploadIcon />
                        </Avatar>
                    }
                    title={<Typography variant="button">{<Translate id="releasePlan.updateRelease" />} </Typography>}
                />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        );
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        isUpdatingReleasePlan: state.releasePlan.get("isUpdatingReleasePlan"),
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        updateReleasePlanManually:() =>{
           return dispatch(updateReleasePlanManuallyFromBack());
        }
    }
}

const styles = theme => ({
    logo: {
        backgroundColor: theme.palette.secondary.main,
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    showingCounter: {
        color: theme.palette.secondary.main
    }
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(UpdateRelease))));
