import React, { useState } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import ReleaseClockIcon from '@material-ui/icons/Restore'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import DeleteIcon from '@material-ui/icons/Delete'
import Fab from '@material-ui/core/Fab'
import { Tooltip } from '@material-ui/core'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import AddCommonItem from '../../common/AddCommonItem'
import Toaster from '../../common/Toaster'
import { loadReleaseCalendarLockFromBackEnd, removeReleaseCalendarLock } from '../../../store/plannigFersa/ReleaseCalendarAction'
import { useEffect } from 'react'
import InformationPartial from './InformationPartial'
import LoadingPage from '../../common/LoadingPage'

const Show = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	const [state, setEstate] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		openDelete: false,
	})

	const releaseCalendarLock = useSelector((state) =>
		state.releaseCalendarFersaLock.get('releaseCalendarLock')
			? state.releaseCalendarFersaLock.get('releaseCalendarLock').toJS()
			: null
	)
	const isLoading = useSelector((state) => state.releaseCalendarFersaLock.get('isLoading'))
	useEffect(() => {
		const id = props.match.params.token
		dispatch(loadReleaseCalendarLockFromBackEnd(id))
	}, [dispatch, props.match.params.token])

	const getCardContent = () => {
		if (isLoading === true) {
			return <LoadingPage />
		} else if (releaseCalendarLock !== null) {
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Divider />
						<InformationPartial calendarLock={releaseCalendarLock} />
					</Grid>
				</Grid>
			)
		}
	}

	const onRemoveItem = () => {
		setEstate((prev) => ({ ...prev, openDelete: true }))
	}
	const onRemoveReleaseCalendarLock = () => {
		let calendarId = props.match.params.token
		dispatch(removeReleaseCalendarLock(calendarId))
			.then(() => {
				setEstate((prev) => ({ ...prev, openDeleteDraft: false }))
				props.history.push(`/releasecalendarFersa`)
			})
			.catch(() => {
				setEstate((prev) => ({
					...prev,
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				}))
			})
	}

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<ReleaseClockIcon />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						<Tooltip title={<Translate id='releaseCalendarLock.removeTitle' />}>
							<Fab
								size='small'
								color='primary'
								onClick={() => {
									onRemoveItem()
								}}
							>
								<DeleteIcon />
							</Fab>
						</Tooltip>
					</div>
				}
				title={
					<Typography variant='button'>
						<Translate id='releaseCalendarLock.titleCalendar' />
					</Typography>
				}
				subheader={
					<div>
						<Translate id='common.creationDate' />:{' '}
						{releaseCalendarLock ? releaseCalendarLock.creation_date : ''} <br />
						<Translate id='common.createBy' />: {releaseCalendarLock ? releaseCalendarLock.created_by : ''}
					</div>
				}
			/>
			<CardContent>{getCardContent()}</CardContent>

			{/** Dialog to remove calendar lock */}
			<AddCommonItem
				title={<Translate id='releaseCalendarLock.removeTitle' />}
				message={<Translate id='releaseCalendarLock.removeMesage' />}
				open={state.openDelete}
				onClose={() => {
					setEstate((prev) => ({ ...prev, openDelete: false }))
				}}
				onSaveItem={onRemoveReleaseCalendarLock}
				isSaving={props.isSaving}
				isMessage={true}
			/>

			<Toaster
				message={state.toasterMessage}
				open={state.showToaster}
				variant={state.toasterVariant}
				onClose={() => {
					setEstate((prev) => ({ ...prev, showToaster: false }))
				}}
			/>
		</Card>
	)
}

/**
 * Get the card content
 *
 * @memberof Show
 */

const styles = (theme) => ({
	toolbar: {
		display: 'flex',
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	button: {
		margin: theme.spacing.unit,
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	grow: {
		flexGrow: 1,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	supplierBadge: {
		padding: `0 ${theme.spacing.unit * 2}px`,
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
