import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'

/** Material UI Icons imports section */
import AddtIcon from '@material-ui/icons/AddTwoTone'
import SettingsIcon from '@material-ui/icons/Settings'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import AttachIcon from '@material-ui/icons/AttachFile'
import DownloadIcon from '@material-ui/icons/CloudUpload'

/** Import component section */
import DataTable from '../../common/DataTable'
import Toaster from '../../common/Toaster'
import AddItemDialog from './AddItemDialog'
import ModalAddMasivaMaterials from './ModalAddMasivaMaterials'
import FilesLinks from '../../common/FilesLinks'
import OptionsMenu from '../../common/OptionsMenu'

/** Actions and helpers imports section */
import { GetMeasurementUnits } from '../../../store/helpers/SelectOptions'
import { COMPANY_IDENTIFIER_IMBERA_MX, COMPANY_IDENTIFIER_IMBERA_REPARE } from '../../../store/helpers/ResourcesHelper'

/**
 * Component to details proveduries
 */
class ProveduresDetails extends Component {
	/**
	 * Create an instance of ProveduresDetails
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAddItem: false,
			openAddMisiva: false,
			itemToAdd: {
				sample: false,
				quote: false,
				rmi: false,
				partNumber: '',
				sampleNumber: '',
				volumen: '',
				volumnetMeaurement: '',
				observations: '',
				showAttachmentsDialog: false,
			},
			itemToken: '',
		}
	}

	/**
	 * On click to add item in list
	 */
	onClickCreateItem() {
		if (this.props.canBeAddItem) {
			let isValid = this.props.canBeAddItem()
			if (isValid) {
				this.setState({
					openAddItem: true,
				})
			} else {
				this.setState({
					showToView: true,
					showToaster: true,
					toasterVariant: 'error',
					toasterMessage: <Translate id='alternatingProveeduria.messageToAddd' />,
				})
			}
		}
	}

	/**
	 * On change document type
	 *
	 * @param {*} option
	 * @memberof DocumentTypeSelect
	 */
	onChangeMeasurementUnit(option) {
		let updateItemToAdd = { ...this.state.itemToAdd }
		updateItemToAdd['volumnetMeaurement'] = option.value
		this.setState({
			itemToAdd: updateItemToAdd,
		})
	}

	/**
	 * Update property item
	 * @param {*} property
	 * @param {*} value
	 */
	updatePropertyItem(property, value) {
		let updateItemToAdd = { ...this.state.itemToAdd }
		updateItemToAdd[property] = value
		this.setState({
			itemToAdd: updateItemToAdd,
		})
	}

	/**
	 * Function to can be add item
	 */
	canBeAddItem() {
		if (this.state.itemToAdd.quote === false && this.state.itemToAdd.sample === false) return false
		if (this.state.itemToAdd.partNumber.length === 0) return false
		if (this.state.itemToAdd.volumen.length === 0 || this.state.itemToAdd.volumnetMeaurement.length === 0)
			return false
		else return true
	}

	/**
	 * Funtion to add item to provedure list
	 */
	onAddItemInAlternativeProveduria() {
		this.props
			.addItemInAlternativeProveduria(
				this.props.provedureDraft,
				this.state.itemToAdd,
				this.props.supplierSelected
			)
			.then(() => {
				this.setState({
					itemToAdd: {
						sample: false,
						quote: false,
						rmi: false,
						partNumber: '',
						sampleNumber: '',
						volumen: '',
						volumnetMeaurement: '',
						observations: '',
					},
					openAddItem: false,
				})
			})
			.catch((response) => {
				if (response.status === 400) {
					this.setState({
						showToaster: true,
						toasterMessage: response.data.Message,
						toasterVariant: 'error',
					})
				} else {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				}
			})
	}

	/**
	 * Handle state to set token of item to show options menu list
	 *
	 * @param {*} itemToken
	 * @memberof ProveduresDetails
	 */
	handleClickOptionsMenu(itemToken) {
		this.setState({
			itemToken,
		})
	}

	/**
	 * Handle state to show or hide menu options list
	 *
	 * @memberof ProveduresDetails
	 */
	handleCloseOptionsMenu() {
		if (!this.state.showMemoItemDialog || !this.state.showAttachmentsDialog) {
			this.setState((state) => ({
				itemToken: '',
			}))
		}
	}

	/**
	 * Method to render OptionsMenu
	 *
	 * @param {*} item
	 * @returns
	 * @memberof ProveduresDetails
	 */
	renderOptionsMenu(item, optionToShow) {
		var options = []
		if (optionToShow === 1)
			options.push({
				itemClick: () => this.props.onMenuOptionItemClick('showProvedureItemDialog', item),
				tooltipTranslation: <Translate id='common.addInformation' />,
				menuItemIcon: <SettingsIcon color={this.props.isSaving ? 'inherit' : 'secondary'} />,
				isLoading: this.props.isSaving,
			})
		if (optionToShow === 2)
			options.push({
				itemClick: () => this.props.onMenuOptionItemClick('showAttachmentsDialog', item),
				tooltipTranslation: <Translate id='common.addAttachments' />,
				menuItemIcon: <AttachIcon color={this.props.isSaving ? 'inherit' : 'secondary'} />,
				isLoading: this.props.isSaving,
			})
		if (optionToShow === 3)
			options.push({
				itemClick: () => this.props.removeItemInAlternativeProveduria(item.token, this.props.supplierSelected),
				tooltipTranslation: <Translate id='common.delete' />,
				menuItemIcon: <DeleteOutlinedIcon color={this.props.isSaving ? 'inherit' : 'action'} />,
				isLoading: this.props.isSaving,
			})
		return (
			<OptionsMenu
				itemToken={item.token}
				handleClickOptions={this.handleClickOptionsMenu}
				open={this.state.itemToken === item.token}
				handleClickAway={this.handleCloseOptionsMenu}
				options={options}
				xsSize
			/>
		)
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'options') {
			return (
				<React.Fragment>
					{this.renderOptionsMenu(item, 1)}
					{this.renderOptionsMenu(item, 2)}
					{this.renderOptionsMenu(item, 3)}
				</React.Fragment>
			)
		}
		if (dataSource === 'plot_name') {
			return (
				<FilesLinks
					item={item}
					hasPlot
					companyToken={this.props.provedureDraft.company_token}
					companyCode={this.props.provedureDraft.company_identifier}
					kind={this.props.provedureDraft.alternating_proveeduria_type}
					downloadFile={this.props.downloadPlotFiles}
				/>
			)
		}
		if (dataSource === 'volume') {
			let optionsMeasurementUnit = GetMeasurementUnits()
			return (
				<span>
					{item.volume}{' '}
					{optionsMeasurementUnit[item.volume_measurement_unit] &&
						optionsMeasurementUnit[item.volume_measurement_unit].label}
				</span>
			)
		}
		if (dataSource === 'comments') {
			return <div style={{ width: '360px' }}>{item.comments}</div>
		}
	}

	/**
	 * On action to open modal masiva
	 */
	onClickOptionMasivaMaterials() {
		this.setState({ openAddMisiva: true })
	}

	/**
	 * Function to add material masivas
	 * @param {*} attachments
	 */
	onMaterialsMassiva(attachments) {
		return this.props
			.importMaterialListOfAlternativeProveduria(this.props.provedureDraft, attachments)
			.then(() => {
				this.setState({
					openAddMisiva: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
				return Promise.resolve('Ok')
			})
			.catch((response) => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
				return Promise.reject()
			})
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof ProveduresDetails
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					action={
						<div className={classes.actionsToolbar}>
							<Fab
								size='small'
								color='primary'
								onClick={() => {
									this.onClickCreateItem()
								}}
							>
								<AddtIcon />
							</Fab>
							{this.props.provedureDraft.details.length !== 0 && //TO REVIEW
								(this.props.provedureDraft.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
									this.props.provedureDraft.company_identifier ===
										COMPANY_IDENTIFIER_IMBERA_REPARE) && (
									<Tooltip title={<Translate id='alternatingProveeduria.titleAddFileMassive' />}>
										<Fab
											size='small'
											className={classes.fabBotton}
											color='secondary'
											onClick={() => {
												this.onClickOptionMasivaMaterials()
											}}
										>
											<DownloadIcon />
										</Fab>
									</Tooltip>
								)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='alternatingProveeduria.materials' />{' '}
						</Typography>
					}
					subheader={
						<Typography variant='body2'>
							<Translate id='alternatingProveeduria.note' />
						</Typography>
					}
				/>
				<CardContent>
					<DataTable
						data={this.props.provedureDraft.details}
						configuration={TableConfiguration}
						onRenderCellItem={this.onRenderCellItem}
					/>
				</CardContent>
				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
				{/** Dialog to AddItemDialog */}
				<AddItemDialog
					{...this.props}
					dropzoneText={this.props.dropzoneText}
					item={this.state.itemToAdd}
					onChangeMeasurementUnit={this.onChangeMeasurementUnit}
					updatePropertyItem={this.updatePropertyItem}
					open={this.state.openAddItem}
					onClose={() => {
						this.setState({ openAddItem: false })
					}}
					onAddItemProveduria={this.onAddItemInAlternativeProveduria}
					isSaving={this.props.isSaving}
					canBeSaved={this.canBeAddItem()}
				/>

				<ModalAddMasivaMaterials
					dropzoneText={this.props.dropzoneText}
					open={this.state.openAddMisiva}
					onClose={() => {
						this.setState({ openAddMisiva: false })
					}}
					onMaterialsMassiva={this.onMaterialsMassiva}
					isSaving={this.props.isSaving}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='alternatingProveeduria.partNumber' />,
			dataSource: 'part_number',
		},
		{
			header: <Translate id='alternatingProveeduria.description' />,
			dataSource: 'part_description',
		},
		{
			header: <Translate id='alternatingProveeduria.plane' />,
			dataSource: 'plot_name',
		},
		{
			header: <Translate id='alternatingProveeduria.revision' />,
			dataSource: 'revise',
		},
		{
			header: <Translate id='alternatingProveeduria.numberSample' />,
			dataSource: 'sample_number',
		},
		{
			header: <Translate id='alternatingProveeduria.volumen' />,
			dataSource: 'volume',
		},
		{
			header: <Translate id='alternatingProveeduria.observations' />,
			dataSource: 'comments',
		},
		{
			header: '',
			dataSource: 'options',
		},
	],
}

export default ProveduresDetails
