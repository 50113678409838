/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserFiles, getAuthorizedRequestConfigurationUserParameters } from '../helpers/SessionHelper';

export const START_LOADING_MATERIAL_DELIVERIES = "START_LOADING_MATERIAL_DELIVERIES";
export const SET_MATERIAL_DELIVERIES_LIST = "SET_MATERIAL_DELIVERIES_LIST";
export const IS_LOADING_MATERIAL_DELIVERY = "IS_LOADING_MATERIAL_DELIVERY";
export const SET_MATERIAL_DELIVERY = "SET_MATERIAL_DELIVERY";
export const CHANGE_MATERIAL_DELIVERIES_SORT_CRITERIA = "CHANGE_MATERIAL_DELIVERIES_SORT_CRITERIA";
export const CHANGE_MATERIAL_DELIVERIES_QUERY = "CHANGE_MATERIAL_DELIVERIES_QUERY";
export const CHANGE_MATERIAL_DELIVERIES_PAGINATION = "CHANGE_MATERIAL_DELIVERIES_PAGINATION";
export const SET_MATERIAL_DELIVERIES_COUNT = "SET_MATERIAL_DELIVERIES_COUNT";
export const GET_INFORMATION_MATERIAL_DELIVERY = "GET_INFORMATION_MATERIAL_DELIVERY";

export const UPDATE_DETAIL_MATERIAL_SCHEMATIC = "UPDATE_DETAIL_MATERIAL_SCHEMATIC";
export const IS_UPDATING_DETAIL_PLANE_NAME = "IS_UPDATING_DETAIL_PLANE_NAME";
export const DELETE_DETAIL_MATERIAL_SCHEMATIC = "DELETE_DETAIL_MATERIAL_SCHEMATIC";
export const IS_DELETE_DETAIL_MATERIAL_SCHEMATIC = "IS_DELETE_DETAIL_MATERIAL_SCHEMATIC";
export const ADD_SUPPLIER_FROM_MATERIAL_DELIVERY = "ADD_SUPPLIER_FROM_MATERIAL_DELIVERY"
export const REMOVE_SUPPLIER_FROM_MATERIAL_DELIVERY = "REMOVE_SUPPLIER_FROM_MATERIAL_DELIVERY"
export const SET_MATERIAL_ITEM_SELECTED = "SET_MATERIAL_ITEM_SELECTED";
export const ADD_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL = "ADD_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL";
export const ADD_RMI_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL = "ADD_RMI_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL";
export const REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL = "REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL";
export const SEND_QUOTES_REQUEST_MATERIAL_DELIVERY = "SEND_QUOTES_REQUEST_MATERIAL_DELIVERY";
export const IS_SAVING_MATERIAL_DELIVERY = "IS_SAVING_MATERIAL_DELIVERY";
export const UPDATE_MATERIAL_DELIVERY = "UPDATE_MATERIAL_DELIVERY";
export const FINALIZE_MATERIAL_DELIVERY = "FINALIZE_MATERIAL_DELIVERY";
export const UPDATE_COMMENTS_MATERIAL_DELIVERY = "UPDATE_COMMENTS_MATERIAL_DELIVERY";
export const ADD_FILE_MATERIAL_DELIVERY = "ADD_FILE_MATERIAL_DELIVERY";
export const REMOVE_FILE_MATERIAL_DELIVERY = "REMOVE_FILE_MATERIAL_DELIVERY";

/**
 * Action start loading material delivery
 * @param {*} isLoading 
 */
function isLoadingMaterialDeleveries(isLoading) {
    return {
        type: START_LOADING_MATERIAL_DELIVERIES,
        isLoading: isLoading
    }
}

/**
 * Action to set material deliveries list 
 * @param {*} deliveries 
 */
function setMaterialDeliveriesList(deliveries) {
    return {
        type: SET_MATERIAL_DELIVERIES_LIST,
        deliveries: deliveries
    }
}

/**
 * Action to start load material delivery
 */
function startLoadMaterialDevolery(isLoading) {
    return {
        type: IS_LOADING_MATERIAL_DELIVERY,
        isLoadingMaterialDelivery: isLoading
    }
}

/**
 * Action to set material delivery
 * @param {*} materialDelivery 
 */
function setMaterialDelivery(materialDelivery) {
    return {
        type: SET_MATERIAL_DELIVERY,
        materialDelivery: materialDelivery
    }
}

/**
 * Action reducer to set the material deliverie's counter
 *
 * @param {*} count
 * @returns
 */
function setMaterialDeliveriesCount(count) {
    return {
        type: SET_MATERIAL_DELIVERIES_COUNT,
        count
    }
}

/**
 * Action to get informaiton from material Delivery 
 * @param {*} materialDelivery 
 */
function getInformationMaterialDelivery(materialDelivery) {
    return {
        type: GET_INFORMATION_MATERIAL_DELIVERY,
        materialDelivery: materialDelivery,
    }
}

/**
 * updateDetailSchematic
 * @param {*} detailPlane 
 */
export const updateDetailSchematic = (detailPlane) => ({
    type: UPDATE_DETAIL_MATERIAL_SCHEMATIC,
    detailPlane: detailPlane
})

/**
 * isUpdatingPlaneName
 * @param {*} isUploading 
 */
export const isUpdatingPlaneName = (isUploading) => ({
    type: IS_UPDATING_DETAIL_PLANE_NAME,
    isUploading: isUploading
})

/**
 * deleteDetailSchemetic
 * @param {*} deletedToken 
 */
export const deleteDetailSchemetic = (deletedToken) => ({
    type: DELETE_DETAIL_MATERIAL_SCHEMATIC,
    deletedToken: deletedToken
})

/**
 * isDeleteDetailSchematic
 * @param {*} isDeleting 
 */
export const isDeleteDetailSchematic = (isDeleting) => ({
    type: IS_DELETE_DETAIL_MATERIAL_SCHEMATIC,
    isDeleting: isDeleting
})

/**
* Action to add supplier in quote list
* @param {*} supplier 
*/
function addSupplierInMaterialDetails(materialItemToken, quote) {
    return {
        type: ADD_SUPPLIER_FROM_MATERIAL_DELIVERY,
        materialItemToken: materialItemToken,
        quote: quote
    }
}

/**
* Action to remove a supplier from a material delivery
*
* @param {*} materialItemToken
* @param {*} supplierToken
* @returns
*/
function supplierRemovedFromItem(materialItemToken, supplierToken) {
    return {
        type: REMOVE_SUPPLIER_FROM_MATERIAL_DELIVERY,
        materialItemToken,
        supplierToken
    }
}

/**
 * Action to add sample request supplier from a material delivery
 * @param {*} materialItemToken 
 * @param {*} supplierToken 
 */
function addSampleRequestSupplierInMaterialItem(materialItemToken, supplierToken) {
    return {
        type: ADD_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL,
        materialItemToken: materialItemToken,
        supplierToken: supplierToken,
    }
}

/**
 * Action addRMISampleRequestSupplierInMaterialItem
 * @param {*} materialItemToken 
 * @param {*} supplierToken 
 * @param {*} rmi 
 */
function addRMISampleRequestSupplierInMaterialItem(materialItemToken, supplierToken, rmi) {
    return {
        type: ADD_RMI_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL,
        materialItemToken: materialItemToken,
        supplierToken: supplierToken,
        rmi: rmi
    }
}

/**
 * Action to remove sample request supplier from a material delivery
 * @param {*} materialItemToken 
 * @param {*} supplierToken 
 */
function removeSampleRequestSupplierInMaterialItem(materialItemToken, supplierToken) {
    return {
        type: REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_MATERIAL,
        materialItemToken: materialItemToken,
        supplierToken: supplierToken,
    }
}

/**
 * Action to start send  material delivery
 */
function sendMamterialDeliveryRequest(isSaving) {
    return {
        type: SEND_QUOTES_REQUEST_MATERIAL_DELIVERY,
        isSaving: isSaving
    }
}

/**
 * Action saving data material delivery
 */
function isSavingMaterialDelivery(isSaving) {
    return {
        type: IS_SAVING_MATERIAL_DELIVERY,
        isSaving: isSaving
    }
}

/**
 * Action update data of matarial delivery
 * @param {} materialDelivery 
 */
function updateMaterialDelivery(materialDelivery) {
    return {
        type: UPDATE_MATERIAL_DELIVERY,
        materialDelivery: materialDelivery
    }
}

/**
 * Action finalize material delivery
 * @param {*} materialDelivery 
 */
function finalizeMaterialDelivery(materialDelivery) {
    return {
        type: FINALIZE_MATERIAL_DELIVERY,
        materialDelivery: materialDelivery
    }
}

/**
 * Action to update comments of part number in details list 
 *
 * @param {*} partNumberDetail
 * @returns
 */
function updateComments(partNumberDetail) {
    return {
        type: UPDATE_COMMENTS_MATERIAL_DELIVERY,
        partNumberDetail
    }
}

/**
 * Add file
 *
 * @param {*} files
 * @returns
 */
function addFile(files, tokenPartNumber) {
    return {
        type: ADD_FILE_MATERIAL_DELIVERY,
        files,
        tokenPartNumber
    }
}

/**
 * Remove file 
 *
 * @param {*} fileName
 * @returns
 */
function removeFile(fileName, tokenPartNumber) {
    return {
        type: REMOVE_FILE_MATERIAL_DELIVERY,
        fileName,
        tokenPartNumber
    }
}

/**
 * Get all materialDeliveries by company from backend 
 */
export function loadMaterialDeliveriesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {
        dispatch(isLoadingMaterialDeleveries(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/materialdelivery/deliveries`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page+1}`,
            RowsPerPage: rowsPerPage,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setMaterialDeliveriesList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingMaterialDeleveries(false));
                console.error("Error getting the material deliveries by company list", error.response)
            })

    }
}


/**
 * Get material deliverie's count
 *
 * @export
 * @returns
 */
export function getMaterialDeliveriesCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().materialDelivery.get('materialDeliveriesCount');
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/materialdelivery/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setMaterialDeliveriesCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the material deliverie's count", error.response)
            })
    }
}


/**
 * Get and set the material delivery in the estate from read from backend
 * @param {*} token 
 */
export function loadMaterialDeliveryFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(startLoadMaterialDevolery(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/materialdelivery/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setMaterialDelivery(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load material delivery ", error.response)
                dispatch(startLoadMaterialDevolery(false))
                return Promise.reject();
            })
    }
}

/**
 * Action get material delivery information data from backend 
 */
export function getMaterialDeliveryInformationDataFromBackend(folio) {
    return function (dispatch, getState) {
        dispatch(startLoadMaterialDevolery(true))

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/materialdelivery`;
        let data = {
            Folio: folio,
            CompanyToken: selectedCompany.get('token'),
            CompanyIdentifier: selectedCompany.get('get_company_identifier'),
            CompanyCenter: selectedCompany.get('center'),
            CenterFin: selectedCompany.get('center_fin'),
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
        }

        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(getInformationMaterialDelivery(serverResponse.data))
                    return Promise.resolve(serverResponse.data);
                }
            })
            .catch((error) => {
                console.error("Error to get information from material delivery", error.response)
                dispatch(startLoadMaterialDevolery(false))
                return Promise.reject();
            })
    }
}

/**
 *  Action update plane from material item
 * @param {*} detailToken 
 * @param {*} planeName 
 */
export function updateDetailPartnumberPlaneName(detailToken, planeName) {
    return (dispatch, getState) => {

        dispatch(isUpdatingPlaneName(true))
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partNumbers/plots`;
        let data = {
            Name: planeName,
            TokenPartNumber: detailToken,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateDetailSchematic(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error to get information from mateiral delivery", error.response)
                dispatch(isUpdatingPlaneName(false))
                return Promise.reject();
            })
    }
}

/**
 * Action delete plant in item material delivery
 * @param {*} detailToken 
 * @param {*} planeName 
 */
export function deleteDetailPlaneName(detailToken, planeName) {
    return (dispatch, getState) => {
        dispatch(isDeleteDetailSchematic(true))
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partNumbers/plots/remove`;
        let data = {
            Name: planeName,
            TokenPartNumber: detailToken,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(deleteDetailSchemetic(detailToken))
                }
            })
            .catch((error) => {
                console.error("Error to get information from mateiral delivery", error.response)
                dispatch(isDeleteDetailSchematic(false))
                return Promise.reject();
            })
    }
}

/**
 * Get the selected item from a material delivery details
 * @param {*} state 
 */
export const getSelectedMaterialDeliveryItem = (state) => {
    let materialDelivery = state.materialDelivery.get('materialDelivery');
    if (materialDelivery) {
        let selectedMaterialItem = materialDelivery.get('details').find((detail) => {
            return detail.get('is_selected') === true
        })
        if (selectedMaterialItem) {
            return selectedMaterialItem.toJS()
        } else {
            return null
        }
    } else {
        return null
    }
}

/**
 * Add supplier to a material delivery and send a quote request
 *
 * @export
 * @param {*} materialPartNumber
 * @param {*} supplier
 * @returns
 */
export function addSupplierToMaterialDeliveryItem(materialPartNumber, supplier) {
    return (dispatch, getState) => {

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let data = {
            ProjectPartNumberToken: materialPartNumber.token,
            CompanyToken: selectedCompany.get('token'),
            CompanyIdentifier: selectedCompany.get('get_company_identifier'),
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            SupplierToken: supplier.token,
            SupplierName: supplier.name,
            SupplierNumber: supplier.code,
            SupplierCountry: supplier.country,
        }

        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addSupplierInMaterialDetails(materialPartNumber.token, serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error adding a quote for a material delivery", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Remove a supplier from the material delivery requests
 *
 * @export
 * @param {*} materialPartNumber
 * @param {*} supplierToken
 * @returns
 */
export function removeSupplierFromMaterialDeliveryItem(materialPartNumber, supplierToken) {
    return (dispatch, getState) => {

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let selectedCompany = getState().profile.get("selectedCompany");
        let folio = getState().materialDelivery.get('materialDelivery').get('folio');
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${selectedCompany.get('token')}/folio/${folio}/supplier/${supplierToken}/partNumber/${materialPartNumber.token}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(supplierRemovedFromItem(materialPartNumber.token, supplierToken))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error removing a supplier from a material delivery", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Add sample request supplier to a memo
 *
 * @export
 * @param {*} materialPartNumber
 * @param {*} supplier
 * @returns
 */
export function onAddSampleRequestSupplier(materialPartNumber, quote) {
    return (dispatch, getState) => {

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            ProjectPartNumberToken: materialPartNumber.token,
            CompanyToken: selectedCompany.get('token'),
            CompanyIdentifier: selectedCompany.get('get_company_identifier'),
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            SupplierToken: quote.supplier_token,
            SupplierName: quote.supplier_name,
            SupplierNumber: quote.supplier_code,
            IsRequieredRMI: quote.is_rmi_requiered,
        }

        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addSampleRequestSupplierInMaterialItem(materialPartNumber.token, quote.supplier_token))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error adding a sample request for a mateiral delivery", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Add sample request supplier to a memo
 *
 * @export
 * @param {*} materialPartNumber
 * @param {*} supplier
 * @returns
 */
export function onAddRMISampleRequestSupplier(materialPartNumber, quote, rmi) {
    return (dispatch, getState) => {

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/${quote.supplier_token}/partNumber/${materialPartNumber.token}/${rmi}`;
        return axios.put(endPoint, null, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addRMISampleRequestSupplierInMaterialItem(materialPartNumber.token, quote.supplier_token, rmi))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error adding a sample request for a mateiral delivery", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Remove sample request supplier to a memo
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function onRemoveSampleRequestSupplier(materialPartNumber, supplierToken) {
    return (dispatch, getState) => {

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/supplier/${supplierToken}/partNumber/${materialPartNumber.token}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeSampleRequestSupplierInMaterialItem(materialPartNumber.token, supplierToken))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error remove a sample request for a mateiral delivery", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action from send quotes and sample request from mateiral delivery
 */
export function sendQuotesRequestFromMaterialDelivery() {
    return (dispatch, getState) => {
        dispatch(sendMamterialDeliveryRequest(true))

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let folio = getState().materialDelivery.get('materialDelivery').get('folio');
        let companyToken = getState().materialDelivery.get('materialDelivery').get('company_token');
        let documentIdentifier = selectedCompany.get('document_identifier')
        let data = {};

        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/quotes/${folio}/company/${companyToken}/${documentIdentifier}/send`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(sendMamterialDeliveryRequest(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error in send quotes and a sample request for a mateiral delivery", error.response)
                dispatch(sendMamterialDeliveryRequest(false))
                return Promise.reject();
            })
    }
}

export function updateDataMaterialDeliveryFromBackend() {
    return function (dispatch, getState) {
        dispatch(isSavingMaterialDelivery(true))
        let folio = getState().materialDelivery.get('materialDeliveryInformation').get('folio');
        let companyToken = getState().materialDelivery.get('materialDeliveryInformation').get('company_token');
        let data = {
            Folio: folio,
            CompanyToken: companyToken,
        }

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/materialdelivery`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateMaterialDelivery(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error update data of an material delivery", error.response)
                dispatch(isSavingMaterialDelivery(false))
                return Promise.reject();
            })

    }
}

export function finalizeMaterialDeliveryFromBackend(tokenMaterial) {
    return function (dispatch, getState) {
        dispatch(isSavingMaterialDelivery(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/projectdocument/closedproject/${tokenMaterial}`;

        return axios.put(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(finalizeMaterialDelivery(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error finalize material delivery", error.response)
                dispatch(isSavingMaterialDelivery(false))
                return Promise.reject();
            })
    }
}

/**
 * Action to add comment
 *
 * @export
 * @param {*} item
 * @param {*} comments
 * @returns
 */
export function onAddComment(item, comments) {
    return (dispatch, getState) => {
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            Description: comments,
            PartNumberToken: item.token
        }

        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/project`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateComments(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error adding comments", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to upload files 
 *
 * @export
 * @param {*} files
 * @returns
 */
export function onAddFiles(files, tokenPartNumber) {
    return (dispatch, getState) => {
        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUserFiles(user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumbers/upload/${tokenPartNumber}`;
        let formData = new FormData();
        for (var index = 0; index <= files.length; index++) {
            formData.append('file', files[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addFile(serverResponse.data, tokenPartNumber))
                    return Promise.resolve('Ok');
                }
            })
            .catch((error) => {
                console.error("Error to upload file(s) in material delivery", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to remove files
 *
 * @export
 * @param {*} fileName
 * @returns
 */
export function onRemoveFiles(fileName, tokenPartNumber) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partnumber/remove/${tokenPartNumber}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeFile(fileName, tokenPartNumber))
                    return Promise.resolve('Ok');
                }
            })
            .catch((error) => {
                console.error("Error to remove evidence file(s)", error.response)
                return Promise.reject();
            })
    }
}