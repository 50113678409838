/**Import react section */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from 'react-select'
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import {
    withStyles,
    TextField, InputAdornment,
    Grid
} from "@material-ui/core";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { CriteriaType, NumberFormatType } from "../../../../store/helpers/AppConstants";
import { GetBooleanOptions, getSelectedValue } from '../../../../store/helpers/SelectOptions';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'

import HtmlEditor from "../../../common/HtmlEditor";
const SupplierResponseComponent = (props) => {
    const { classes } = props;
    /**String props section */
    // const { } = props
    /**Bools props section */
    const { isSaving, isDisabled, valueResponse, translate } = props
    /**Arrays props section */
    /**Functions props section */
    /**String props section */
    const { item } = props
    const [errors, setErrors] = useState({});

    const onTextFieldTextPropertyChange = (value) => {
        if (props.onChangeValueResponse) {
            props.onChangeValueResponse(value)
        }
    }

    const validate = data => {
        const errors = {};
        if (!IsNullOrEmpty(data.value) && data.name !== "currency") {
            let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (!regexQuantity.test(data.value)) {
                errors.decimals = translate("biddingsModule.maximumTwoDigits");
            }
            if (data.name === "minimumRange" && (data.value < 0 || data.value > 100)) {
                errors.minimumRange = translate("biddingsModule.value0And100");
            }
        }
        return errors;
    }
    const onTextFieldNumberPropertyChange = (event) => {
        let { value, name } = event.target
        const errors = validate(event.target);
        setErrors(errors);
        if (!IsNullOrEmpty(value) && name !== "currency") {
            let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (!regexQuantity.test(value)) return;
            if (name === "minimumRange") {
                if (item.LowerLimit === 0 && item.UpperLimit === 0) {
                    if ((value < 0 || value > 100))
                        errors.minimumRange = translate("biddingsModule.value0And100");
                }
                else {
                    if ((value < item.LowerLimit || value > item.UpperLimit))
                        errors.minimumRange = `${translate("biddingsModule.valueEnter")} ${item.LowerLimit} y ${item.UpperLimit}`
                }
            }
        }
        if (props.onChangeValueResponse) {
            props.onChangeValueResponse(value, errors)
        }
    }

    const onSelectorPropertyChange = (option) => {
        let result = option.value === "1" ? true : false;
        if (props.onChangeValueResponse) {
            props.onChangeValueResponse(result)
        }
    }

    const getComponetByCriteriaType = () => {
        switch (item.CriteriaType) {
            case CriteriaType.BOOLEAN:
                let result = "0";
                if (valueResponse === true) result = "1";
                if (valueResponse === false) result = "2";
                return <Select
                    placeholder={translate("biddingsModule.selectedOption")}
                    options={GetBooleanOptions()}
                    fullWidth
                    onChange={onSelectorPropertyChange}
                    defaultValue={(getSelectedValue(GetBooleanOptions(), result))}
                    styles={{
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                        menuList: (base) => ({ ...base, paddingTop: 0 }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                        control: base => ({
                            ...base,
                            height: 44,
                            minHeight: 44,
                        })
                    }}
                    isDisabled={isSaving || !isDisabled}
                    menuPortalTarget={document.parentNode}
                    menuPlacement={'bottom'}
                />
            case CriteriaType.TEXT:
                return <Grid container>
                    <Grid item xs={12}>
                        <HtmlEditor
                            onEditorChange={onTextFieldTextPropertyChange}
                            initialValue={valueResponse ? valueResponse : ""}
                            isDisabled={!isDisabled}
                        />

                    </Grid>
                </Grid>

            case CriteriaType.MONEY:
                return <CurrencyTextField
                    label={translate("biddingsModule.value")}
                    name='currency'
                    variant="outlined"
                    fullWidth
                    value={valueResponse}
                    defaultValue={valueResponse}
                    currencySymbol="$"
                    outputFormat="string"
                    onChange={onTextFieldNumberPropertyChange}
                    disabled={isSaving || !isDisabled}
                    //size="small"
                    error={errors && Object.entries(errors).length !== 0 && errors.decimals}
                    helperText={(errors && errors.decimals) ? errors.decimals : ""}
                    InputProps={{ classes: { input: classes.inputProps } }}
                    InputLabelProps={valueResponse ? { shrink: true } : null}
                />
            case CriteriaType.NUMERIC:
                let formatNumber = item.Format;
                switch (formatNumber) {
                    case NumberFormatType.CURRENCY:
                    case "$0.0":
                        return <CurrencyTextField
                            label={translate("biddingsModule.value")}
                            name='currency'
                            variant="outlined"
                            fullWidth
                            value={valueResponse}
                            defaultValue={valueResponse}
                            currencySymbol="$"
                            outputFormat="string"
                            onChange={onTextFieldNumberPropertyChange}
                            disabled={isSaving || !isDisabled}
                            error={errors && Object.entries(errors).length !== 0 && errors.decimals}
                            helperText={(errors && errors.decimals) ? errors.decimals : ""}
                            InputProps={{ classes: { input: classes.inputProps } }}
                            InputLabelProps={valueResponse ? { shrink: true } : null}
                        />
                    case NumberFormatType.DECIMAL:
                        return <TextField
                            fullWidth
                            label={translate("biddingsModule.value")}
                            className={classes.textField}
                            onChange={onTextFieldNumberPropertyChange}
                            type='number'
                            step={0.5}
                            name='maximumRange'
                            margin="normal"
                            variant="outlined"
                            value={valueResponse}
                            defaultValue={valueResponse}
                            disabled={isSaving || !isDisabled}
                            InputProps={{ classes: { input: classes.inputProps } }}
                            error={errors && Object.entries(errors).length !== 0 && errors.decimals}
                            helperText={(errors && errors.decimals) ? errors.decimals : ""}
                            InputLabelProps={valueResponse ? { shrink: true } : null}
                        />
                    case NumberFormatType.PERCENT:
                        return <TextField
                            fullWidth
                            label={translate("biddingsModule.value")}
                            className={classes.textField}
                            step={0.5}
                            name='minimumRange'
                            margin="normal"
                            type='result'
                            variant="outlined"
                            onChange={onTextFieldNumberPropertyChange}
                            value={valueResponse}
                            defaultValue={valueResponse}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                classes: { input: classes.inputProps }
                            }}
                            disabled={isSaving || !isDisabled}
                            error={errors && Object.entries(errors).length !== 0 && (errors.decimals || errors.minimumRange)}
                            helperText={(errors && errors.decimals) ? errors.decimals : (errors && errors.minimumRange) ? errors.minimumRange : ""}
                            InputLabelProps={valueResponse ? { shrink: true } : null}
                        />
                    default:
                        return <TextField
                            size="small"
                            fullWidth
                            label={translate("biddingsModule.value")}
                            className={classes.textField}
                            name='name'
                            type='number'
                            margin="normal"
                            variant="outlined"
                            step={0.5}
                            onChange={onTextFieldNumberPropertyChange}
                            disabled={isSaving || !isDisabled}
                            value={valueResponse}
                            defaultValue={valueResponse}
                            // style={{height:5}}
                            // helperText={`min ${2} max ${100} `}
                            InputLabelProps={valueResponse ? { shrink: true } : null}
                            InputProps={{ classes: { input: classes.inputProps } }}
                            error={errors && Object.entries(errors).length !== 0 && errors.decimals}
                            helperText={(errors && errors.decimals) ? errors.decimals : ""}
                        />
                }
            default:
                return null
        }
    };

    return getComponetByCriteriaType()
};

const styles = (theme) => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    textField: {
    },
    inputProps: {
        height: 6,
    }
});

SupplierResponseComponent.propTypes = {
    bidding: PropTypes.object
};

SupplierResponseComponent.defaultProps = {
    bidding: null
};

export default withRouter(
    withStyles(styles, { withTheme: true })(SupplierResponseComponent)
);
