import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
/**Import material UI Section*/
import {
    withStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

/**Import components section*/
import DataTable from '../../../common/DataTable';
import PopperListsComponent from '../PopperListsComponent'

/**Import resources section*/
import { canBeEditBiddingInStatusDraft } from '../../../../store/helpers/BiddingHelper'
import { IsCurrentActiveDirectoryUser } from '../../../../store/helpers/SessionHelper';

const MaterialTable = (props) => {
    // /**Bools props section */
    const { isSaving } = props
    // /**Arrays props section */
    const { userProfileLogged, userRolesByCompany, itemProposal, items } = props
    // /**Functions props section */
    const { onDeleteServiceOrMaterial
    } = props

    /**Objects props section */
    const { bidding, } = props

    let canBeEditedInformation = canBeEditBiddingInStatusDraft(bidding, userRolesByCompany, userProfileLogged ? userProfileLogged.profile : null)
    let isUserActiveDirectory = IsCurrentActiveDirectoryUser(userProfileLogged);

    let buttonsForMoreCard = []
    if (canBeEditedInformation && isUserActiveDirectory) {
        buttonsForMoreCard.push({
            title: <Translate id="common.delete" />,
            icon: <DeleteIcon />,
            onClick: (event) => { onDeleteItem(event, itemProposal.Id) },
            isIconMaterial: true,
            disabled: (isSaving || !canBeEditedInformation)
        })
    }


    const onDeleteItem = (event, id) => {
        if (props.onDeleteServiceOrMaterial) {
            onDeleteServiceOrMaterial(event, id)
        }
    }

    const onRenderMaterialCellItem = (dataSource, item) => {
        if (dataSource === "options") {
            let buttonsForMoreCard = []
            if (canBeEditedInformation && isUserActiveDirectory) {
                buttonsForMoreCard.push({
                    title: <Translate id="common.delete" />,
                    icon: <DeleteIcon />,
                    onClick: (event) => { onDeleteItem(event, item.Id) },
                    isIconMaterial: true,
                    disabled: (isSaving || !canBeEditedInformation)
                })
            }
            return <PopperListsComponent
                title={<Translate id="common.options" />}
                listItems={buttonsForMoreCard}
            />
        }

    }

    const renderContainer = () => {
        return (
            <DataTable
                data={items}
                configuration={MaterialsTableConfiguration()}
                onRenderCellItem={onRenderMaterialCellItem}
            />
        )
    }

    /**
     * Render componet 
     */
    return (renderContainer())
}

const MaterialsTableConfiguration = () => {
    let columns = [{
        header: <Translate id="biddingsModule.code" />,
        dataSource: 'Code',
        backgroundColor: "#106ec3",
        color: "#FFFFFF"
    }, {
        header: <Translate id="biddingsModule.description" />,
        dataSource: 'Name',
        backgroundColor: "#106ec3",
        color: "#FFFFFF"
    }, {
        header: <Translate id="biddingsModule.quantity" />,
        dataSource: 'Quantity',
        backgroundColor: "#106ec3",
        color: "#FFFFFF"
    },  
    {
        header: <Translate id="biddingsModule.UMA" />,
        dataSource: 'MeasurementUnit',
        backgroundColor: "#106ec3",
        color: "#FFFFFF"
    },
    {
        header: <Translate id="biddingsModule.period" />,
        dataSource: 'PurchasePeriod',
        backgroundColor: "#106ec3",
        color: "#FFFFFF"
    },
    {
        header: <Translate id="biddingsModule.baseUnit" />,
        dataSource: 'BaseUnit',
        backgroundColor: "#106ec3",
        color: "#FFFFFF"
    },
    {
        header: <Translate id="biddingsModule.UMA" />,
        dataSource: 'BaseMeasurement',
        backgroundColor: "#106ec3",
        color: "#FFFFFF"
    },
    {
        header: "",
        dataSource: 'options',
        backgroundColor: "#106ec3",
        color: "#FFFFFF"
    }
    ]
    return ({ columns });
}

const styles = (theme) => ({
    tab: {
        minHeight: "auto",
    },
    textTab: {
        fontSize: "0.75rem !important"

    },
    buttonsRightContainer: {
        display: "flex",
        alignItems: 'center'
    },
    tabs: {
        display: "flex",
        justifyContent: 'space-between',
    },
    sectionsContainer: {
        marginTop: "20px",
        maxHeight: `calc(100vh - ${305}px)`,
        minHeight: `calc(100vh - ${305}px)`,
        overflowY: "auto",
        paddingBottom: "15px"
    },
    flexGrow: {
        flexGrow: 1
    },
    accordionSummary: {
        paddingRight: "0px !important",
        display: "flex",
        backgroundColor: "#f8f9fa"

    },
    accordionDetails: {
        backgroundColor: `${theme.palette.common.white} !important`
    },
    buttonsAcordionSummary: {
        display: "flex",
        justifyItems: "flex-end",
        marginRight: "20px"
    }

});

MaterialTable.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
}

MaterialTable.defaultProps = {
    isLoading: false,
}

export default withRouter(
    withStyles(styles, { withTheme: true })(MaterialTable)
);
