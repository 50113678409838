import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-localize-redux'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Divider,
	LinearProgress,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
} from '@material-ui/core'
import { DateRange, Event, Notes, Person, Security } from '@material-ui/icons'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import { getSecurityCourseById, getSecurityCourseVideo } from '../../store/securityCourses/securityCoursesActions'
import * as selectors from '../../store/securityCourses/securityCoursesSelecteor'
import moment from 'moment'
import { FolderName } from '../../store/helpers/SecurityCoursesHelper'

const Detail = ({ classes }) => {
	const dispatch = useDispatch()
	const queryParams = useParams()
	const [video, setVideo] = useState(null)
	const course = useSelector((state) => selectors.getDetailSecurityCourseSelector(state))
	useEffect(() => {
		dispatch(getSecurityCourseById(queryParams.token))
	}, [])
	useEffect(() => {
		if (course && course.id === queryParams.token) {
			dispatch(getSecurityCourseVideo(course.id, course.file, FolderName.SECURITY_CURSES_VIDEO)).then(
				(videoResponse) => {
					setVideo(videoResponse)
					return () => URL.revokeObjectURL(video.url)
				}
			)
		}
	}, [course])
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar className={classes.avatar}>
						<Security />
					</Avatar>
				}
				title={
					<Typography>
						<Translate id={'securityCourses.index.title'} />
					</Typography>
				}
				subheader={
					<Typography variant='caption'>
						<Translate id={'securityCourses.detail.creationDate'} />:{' '}
						{course && course.creationDate ? moment(course.creationDate).format('DD/MM/YYYY') : ''}
					</Typography>
				}
			/>
			{course && (
				<CardContent className={classes.cardContentTemplate}>
					<ListInfoSecurityCourse classes={classes} course={course} />
					<Card className={classes.cardMedia}>
						{video ? (
							<video controls width='600' height='auto' style={{ borderRadius: '8px' }}>
								<source src={video.url} type='video/mp4' />
								Your browser does not support the video tag.
							</video>
						) : (
							<div className={classes.loading}>
								<LinearProgress />
								<Typography>
									<Translate id={'securityCourses.detail.loadingVideo'} />
								</Typography>
							</div>
						)}
						<div className={classes.cardContent}>
							<Typography variant='body2' className={classes.infoTitleCardMedia}>
								<Translate id={'securityCourses.create.videoLoader.name'} />:{' '}
								{course && course.file ? course.file : ''}
							</Typography>
							<div className={classes.infoCardMedia}>
								<Typography variant='caption'>
									<Translate id={'securityCourses.create.videoLoader.type'} />:{' '}
									{video && video.type ? video.type : ''}
								</Typography>
								<Typography variant='caption'>
									<Translate id={'securityCourses.create.videoLoader.size'} />:{' '}
									{video && video.fileSizeMB ? video.fileSizeMB : ''} MB
								</Typography>
							</div>
						</div>
					</Card>
				</CardContent>
			)}
		</Card>
	)
}
const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
		alignItems: 'center',
	},
	cardMedia: {
		backgroundColor: 'rgba(223, 226, 230, 1)',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		borderRadius: '8px',
		padding: '8px',
		maxWidth: '650px',
	},
	actionBtn: {
		width: '453px',
		display: 'flex',
		justifyContent: 'flex-end',
		paddingTop: '5px',
	},
	cardContent: {
		marginTop: '8px',
		padding: '8px',
		backgroundColor: theme.palette.common.white,
		width: '600px',
		borderRadius: '8px',
	},
	listContent: {
		width: '40%',
	},
	cardContentTemplate: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
	},
	infoCardMedia: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	infoTitleCardMedia: {
		color: theme.palette.primary.dark,
		fontWeight: 'bold',
	},
	titleInfo: {
		color: theme.palette.primary.dark,
	},
	notesContent: {
		maxHeight: '200px',
		overflow: 'auto',
	},
	loading: {
		width: '100%',
	},
})
const ListInfoSecurityCourse = ({ classes, course }) => {
	return (
		<List className={classes.listContent}>
			<ListItem>
				<ListItemAvatar>
					<Avatar>
						<Event />
					</Avatar>
				</ListItemAvatar>
				<ListItemText>
					<Typography variant='body1'>
						<b className={classes.titleInfo}>
							<Translate id={'securityCourses.index.table.name'} />:
						</b>{' '}
						{course && course.courseName ? course.courseName : ''}
					</Typography>
				</ListItemText>
			</ListItem>
			<Divider />
			<ListItem>
				<ListItemAvatar>
					<Avatar>
						<DateRange />
					</Avatar>
				</ListItemAvatar>
				<ListItemText>
					<Typography variant='body1'>
						<b className={classes.titleInfo}>
							<Translate id={'securityCourses.index.table.activationDate'} />:
						</b>{' '}
						{course && course.activeDate ? moment(course.activeDate).format('DD/MM/YYYY') : ''}
					</Typography>
					<Typography variant='body1'>
						<b className={classes.titleInfo}>
							<Translate id={'securityCourses.index.table.expirationDate'} />:
						</b>{' '}
						{course && course.expirationDate ? moment(course.expirationDate).format('DD/MM/YYYY') : ''}
					</Typography>
				</ListItemText>
			</ListItem>
			<Divider />
			<ListItem>
				<ListItemAvatar>
					<Avatar>
						<Person />
					</Avatar>
				</ListItemAvatar>
				<ListItemText>
					<Typography variant='body1'>
						<b className={classes.titleInfo}>
							<Translate id={'securityCourses.detail.requested'} />:
						</b>{' '}
						{course && course.createdBy ? course.createdBy : ''}
					</Typography>
				</ListItemText>
			</ListItem>
			<Divider />
			<ListItem>
				<ListItemAvatar>
					<Avatar>
						<Notes />
					</Avatar>
				</ListItemAvatar>
				<ListItemText>
					<Typography className={classes.notesContent} variant='body1'>
						<b className={classes.titleInfo}>Notas:</b>{' '}
						{course && course.courseNotes ? course.courseNotes : ''}
					</Typography>
				</ListItemText>
			</ListItem>
			<Divider />
		</List>
	)
}
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Detail)))
