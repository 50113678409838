import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import { SHOW_EDIT_VIEW } from '../../store/helpers/ResourcesHelper'

/** Language imports **/
import { Translate } from 'react-localize-redux'
/**
 * DialogActions action
 */
class DialogActions extends Component {
	/**
	 *Creates an instance of DialogActions.
	 * @param {*} props
	 * @memberof DialogActions
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * On click save/accept button
	 *
	 * @memberof ProviderActions
	 */
	onClickAccept() {
		if (this.props.view === SHOW_EDIT_VIEW) {
			this.props.onSaveItem()
		}
	}

	/**
	 * Render component
	 *
	 * @returns
	 * @memberof ProviderActions
	 */
	render() {
		const { classes } = this.props
		if (this.props.view === SHOW_EDIT_VIEW) {
			return (
				<React.Fragment>
					<Toolbar>
						<div className={classes.grow}></div>
						{this.props.isSaving === true ? (
							<Button
								variant='outlined'
								style={{ color: 'rgb(18 156 113)' }}
								className={classes.button}
								disabled
							>
								{this.props.loadingItem ? this.props.loadingItem : <Translate id='common.saving' />}
							</Button>
						) : (
							<Button
								variant='outlined'
								style={{
									color: this.props.canBeSaved
										? this.props.colorButton
											? '#DC3545'
											: 'rgb(18 156 113)'
										: '',
									backgroundColor: this.props.canBeSaved ? '' : '#f1eded',
								}}
								className={classes.button}
								data-cy='btn-save'
								onClick={this.onClickAccept}
								disabled={!this.props.canBeSaved}
							>
								<Translate
									id={this.props.saveButtonTranslate ? this.props.saveButtonTranslate : 'common.save'}
								/>
							</Button>
						)}
						<Button
							variant='outlined'
							color='primary'
							className={classes.button}
							data-cy='btn-close'
							disabled={this.props.isSaving}
							onClick={this.props.onCancel}
						>
							<Translate id='common.cancel' />
						</Button>
					</Toolbar>
					{this.props.isSaving === true ? <LinearProgress color='secondary' variant='query' /> : null}
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					<Toolbar className={this.props.errorThrowMessage !== null ? classes.toolBar : null}>
						{this.props.errorThrowMessage !== null ? (
							<Typography color='inherit' className={classes.errorMessage}>
								{this.props.errorThrowMessage}
							</Typography>
						) : null}
						{this.props.isSaving === true ? (
							<Typography color='inherit'>
								<Translate id='common.loadingPleaseWait' />
							</Typography>
						) : null}
						<div className={classes.grow}></div>
						<Button variant='outlined' color='primary' onClick={this.props.onClose}>
							<Translate id='common.close' />
						</Button>
					</Toolbar>
					{this.props.isSaving === true ? <LinearProgress color='secondary' variant='query' /> : null}
				</React.Fragment>
			)
		}
	}
}

/**
 * Styles definition
 */
const dialogStyles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	toolBar: {},
	button: {
		margin: theme.spacing.unit,
	},
	errorMessage: {
		color: theme.palette.common.white,
	},
	textField: {},
	searchInput: {},
	actionsToolbar: {},
	showingUsersCounter: {},
	supplierBadge: {},
	controlCheck: {},
	toolbar: {},
	fabBotton: {},
	formControl: {},
	modal: {},
	checkBox: {},
	iconOption: {},
})
export default withStyles(dialogStyles, { withTheme: true })(DialogActions)
