import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import autoBind from 'auto-bind'

/** Material-UI imports section */
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import { Tooltip } from '@material-ui/core'

import DataTable from '../../common/DataTable'
import LoadingPage from '../../common/LoadingPage'
import DialogRemoveItem from '../../common/AddCommonItem'
import Toaster from '../../common/Toaster'

/** Language imports **/
import { Translate } from 'react-localize-redux'
import { removeMaterialFromReleasePlan } from '../../../store/plannigFersa/ReleasePlanningAction'
import moment from 'moment'

/** Import section actions */


/**
 * Component to add item in release plan supplier
 */
class RemoveItemInRelease extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			openRemoveItem: null,
			itemId: null,
		}
	}

	/**
	 * onRemoveItem
	 */
	onRemoveItem(itemId) {
		this.setState({
			openRemoveItem: true,
			itemId: itemId,
		})
	}

	/**
	 * Onrender items deriver workflows
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'item_type') {
			if (item.item_type === 1) return <spam>{<Translate id='releasePlan.itemSystem' />}</spam>
			else return <spam>{<Translate id='releasePlan.itemUser' />}</spam>
		}
		if (dataSource === 'options') {
			return (
				<Tooltip color='secondary' title={<Translate id='common.delete' />}>
					<DeleteIcon
						color='secondary'
						style={{ zIndex: '90', cursor: 'pointer' }}
						onClick={() => {
							this.onRemoveItem(item.id)
						}}
					/>
				</Tooltip>
			)
		}
	}

	/**
	 * Function on remove material from release
	 */
	onRemoveMaterialFromRelease() {
		this.props
			.removeMaterialFromReleasePlan(this.state.itemId)
			.then(() => {
				this.props.updateRelease()
				this.setState({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
					openRemoveItem: false,
					itemId: '',
				})
			})
			.catch((result) => {
				if (result.status === 400) {
					this.setState({
						showToaster: true,
						toasterMessage: result.data.Message,
						toasterVariant: 'error',
					})
				} else {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				}
			})
	}

	/**
	 * Get the card content materisl to remove
	 *
	 * @memberof ReleasePlan
	 */
	getContent() {
		if (this.props.isLoadingMaterials === true) {
			return <LoadingPage />
		} else if (this.props.materialsRelease === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='releasePlan.notExistRelease' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.materialsRelease}
					configuration={TableMaterials}
					onRenderCellItem={this.onRenderCellItem}
				/>
			)
		}
	}

	/**
	 * Render get contents
	 */
	render() {
		return (
			<Paper>
				{this.getContent()}

				{/** Dialog to remove material */}
				<DialogRemoveItem
					title={<Translate id='releasePlan.confirmDelete' />}
					message={<Translate id='releasePlan.confirmDeleteFersa' />}
					open={this.state.openRemoveItem}
					onClose={() => {
						this.setState({ openRemoveItem: false })
					}}
					onSaveItem={this.onRemoveMaterialFromRelease}
					isSaving={this.props.isSaving}
					isMessage={true}
					saveButtonTranslate={"common.delete"}
				/>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Paper>
		)
	}
}

const TableMaterials = {
	columns: [
		{
			header: <Translate id='releasePlan.code' />,
			dataSource: 'solpedNumber',
		},
		{
			header: <Translate id='releasePlan.purchaseOrder' />,
			dataSource: 'orderBuy',
		},
		{
			header: <Translate id='releasePlan.position' />,
			dataSource: 'position',
		},
		{
			header: <Translate id='releasePlan.quantity' />,
			dataSource: 'total_quantity_material',
		},
		{
			header: <Translate id='releasePlan.deliveryDate' />,
			dataSource: 'dateSolped',
			onRenderProperty: ( item ) =>  moment(item.dateSolped).format('l')
		},
		{
			header: <Translate id='releasePlan.addingBy' />,
			dataSource: 'item_type',
		},
		{
			header: '',
			dataSource: 'options',
		},
	],
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		isSaving: state.releasePlanning.get('isSaving'),
		isLoadingMaterials: state.releasePlanning.get('isLoadingMaterials'),
		materialsRelease: state.releasePlanning.get('materialsRelease')
			? state.releasePlanning.get('materialsRelease').toJS()
			: null,
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		removeMaterialFromReleasePlan: (materialToken) => {
			return dispatch(removeMaterialFromReleasePlan(materialToken))
		},
	}
}

const viewStyles = (theme) => ({
	root: {
		flexGrow: 1,
	},
})

export default withRouter(
	withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RemoveItemInRelease))
)
