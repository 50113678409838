/** React import section */
import React, { Component } from 'react';


/* Material UI component section import  */
import {DialogContentText, DialogContent,DialogActions} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

/** Import imges */
import flagMx from '../../resources/images/lenguage-flag-mexico.png'
import flagBz from '../../resources/images/lenguage-flag-brazil.png'
import flagUs from '../../resources/images/lenguage-flag-united-states.png'

/** Language imports **/
import {Translate} from "react-localize-redux";

/**
 * Component view dialog change language
 */
class DialogChangeLanguage extends Component{

    /**
     * Render dialog
     */
    render(){
        return(
            <Dialog
                open={this.props.openLanguageDialog}
                onClose={() => this.props.setOpelLanguage(false)}
                aria-labelledby="Cambiar Idioma" >
                <DialogTitle id="form-dialog-title"><DialogContentText><Translate id="common.changeLanguage"/></DialogContentText></DialogTitle>
                <Divider />
                <DialogContent className="dialogContent-padding-custom">
                    <List>
                        <ListItem>
                            <Translate id="common.selectedlanguage"/>
                        </ListItem>
                        <Divider light />

                        <ListItem button onClick={() => {this.props.changeLanguage("es")}}>
                            <ListItemIcon><img src={flagMx} alt="flagMx" /></ListItemIcon>
                            <ListItemText primary={<Translate id="common.spanish"/>} />
                        </ListItem>
                        <Divider light />
        
                        <ListItem button onClick={() => {this.props.changeLanguage("en")}}>
                            <ListItemIcon><img src={flagUs} alt="flagUs" /></ListItemIcon>
                            <ListItemText primary={<Translate id="common.english"/>}/>
                        </ListItem>
                        <Divider light />

                        <ListItem button onClick={() => {this.props.changeLanguage("pt")}}>
                            <ListItemIcon><img src={flagBz} alt="flagBz" /></ListItemIcon>
                            <ListItemText primary={<Translate id="common.portugues"/>} />
                        </ListItem>
                        <Divider light />
                    </List>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => this.props.setOpelLanguage(false)} color="secondary">
                        <Translate id="common.cancel"/>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
export default DialogChangeLanguage;