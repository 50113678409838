
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import autoBind from 'auto-bind'

/** Material-UI imports section */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import FormLabel from '@material-ui/core/FormLabel';

/** Import section component */
import Toaster from '../../common/Toaster';
import DialogActions from '@material-ui/core/DialogActions';
import FailureNonConformitySelector from '../../common/FailureNonConformitySelector';
import PreparedBySelector from '../../common/PreparedBySelector';

/**
 * Component Form Raw material non conformity
 */
class FormView extends Component {

    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            preparedBySelected: null,
            failureSelected: null,
            devolution:  {
                invoicedAmount: this.props.rawMaterial.quantity_of_material_in_invoice,
                invoiceNumber: "",
                comments: "",
            }
        }
    }

     /**
     * On seleted prepare by
     */
    onPrepareBySelected(prepareBy){
        this.setState({preparedBySelected: prepareBy});
    }

    /**
     * On seleted failure
     */
    onFailureSelected(failure){
        this.setState({failureSelected: failure});
    }

     /**
     * Update the property of the devolution
     *
     * @param {*} user
     * @param {*} value
     * @memberof View
     */
    updateProperty(property, value) {
        let creatDevolution = {...this.state.devolution};
        creatDevolution[property] = value;
        this.setState({
            devolution: creatDevolution
        })
    }

    /**
     * Can be add item in raw material
     */
    canBeSaved(){
        let devolution = this.state.devolution;
        if(this.state.praparedBy === null)
            return false;
        if(this.state.failureSelected === null)
            return false;
        if(devolution.invoicedAmount === "" || devolution.invoiceNumber === "")
            return false;
        if(this.hasInvalidValueQantity())
            return false;
        else
            return true;
    }

     /**
     * Has valid quantity
     */
    hasInvalidValueQantity() {
        /// Validar que la cantidad inventario no puede ser mayor a la cantiad reportada
        if(this.state.devolution.invoicedAmount !== ""){
            let invoicedAmount = Number(this.state.devolution.invoicedAmount);
            let quantityInspected = Number(this.props.rawMaterial.quantity_of_material_in_invoice);
            if(quantityInspected < invoicedAmount){
                return true;
            }
            else
                return false;
        }
        else 
            return false;
    }


    /**
     * Metho to can be created devolution
     */
    canBeCreateNonconformity(){
        /// Validar que la cantidad inspeccionarda no puede ser mayor a la factuda
        if(this.hasInvalidValueQantity()){ 
            this.setState({
                showToaster:true,
                toasterMessage: <Translate id="devolutions.messageQualityInvalid"/>,
                toasterVariant: "error",
            })
        }
        else if(this.props.canBeCreate){
            this.props.canBeCreate(this.state.preparedBySelected, this.state.failureSelected, this.state.devolution)
        }   
    }

    /**
     * Render show 
     */
    render(){
        const { classes } = this.props;
        let rawMaterial = this.props.rawMaterial;
        let notification = rawMaterial.documents.find(document => (document.document_type) === "RAWMATERIALNONCONFORMITYNOTIFICATION")
        return(
            <div className={classes.container} noValidate autoComplete="off">
                <Grid item xs={6}>

                    <div className={classes.textField} ><br/>
                        <Typography variant="body2" gutterBottom><strong><Translate id="common.partNumber"/></strong>
                        {": "}{rawMaterial.full_part_description}
                        </Typography>
                    </div>

                    <div className={classes.textField} ><br/>
                        <Typography variant="body2" gutterBottom><strong><Translate id="common.supplier"/></strong>
                        {": "}{rawMaterial.full_supplier}
                        </Typography>
                    </div>

                    <div className={classes.textField} ><br/>
                        <Typography variant="body2" gutterBottom><strong><Translate id="rawmaterialnonconformity.descriptionBrach"/></strong>
                        {": "}{rawMaterial.problem_description}
                        </Typography>
                    </div>

                    <div className={classes.textField} ><br/>
                        <Typography variant="body2" gutterBottom><strong><Translate id="rawmaterialnonconformity.developmentEnginner"/></strong>
                        {": "}{notification && notification.development_engineer_name}
                        </Typography>
                    </div>

                    <div className={classes.textField} ><br/>
                        <Typography variant="body2" gutterBottom><strong><Translate id="rawmaterialnonconformity.planningEnginner"/></strong>
                        {": "}{notification && notification.planning_engineer_name}
                        </Typography>
                    </div>

                </Grid>

                <Grid item xs={6}>
                    <TextField
                        id="standard-read-only-input"
                        error = {this.hasInvalidValueQantity()}
                        label={<Translate id="devolutions.qualityMaterial"/>}
                        value={this.state.devolution.invoicedAmount}
                        className={classes.textField}
                        type="number"
                        margin="normal"
                        onChange={(event) => {this.updateProperty("invoicedAmount", event.target.value)}}
                    />

                    <TextField
                        id="standard-read-only-input"
                        error = {this.hasInvalidValueQantity()}
                        label={<Translate id="devolutions.invoiceNumber"/>}
                        value={this.state.devolution.invoiceNumber}
                        className={classes.textField}
                        type="text"
                        margin="normal"
                        onChange={(event) => {this.updateProperty("invoiceNumber", event.target.value)}}
                    />
                    <div className={classes.textField} ><br/>
                        <FormLabel component="legend"><Translate id="common.preparedBy"/> *</FormLabel>
                        <PreparedBySelector
                            className={classes.textField}
                            onUsersQualitySelected = {(prepareBy) => {this.onPrepareBySelected(prepareBy)}}
                        /><br/>
                    </div>

                    <div className={classes.textField} ><br/>
                        <Typography variant="body2" gutterBottom><Translate id="devolutions.nonConformity"/></Typography>
                
                        <FailureNonConformitySelector
                            className={classes.textField}
                            onNonConformitySelected = {(failure) => {this.onFailureSelected(failure)}}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}><br/>
                    <TextField fullWidth required
                        id="outlined-multiline-flexible"
                        label={<Translate id="common.comments"/>}
                        multiline
                        className={classes.tetxtFieldFull} 
                        rows="4"
                        value={this.state.devolution.comments}
                        onChange={(event) => {this.updateProperty("comments", event.target.value)}}
                        margin="normal"
                        variant="outlined"
                    /><br/><br/>
                </Grid>
                
                <Grid container >
                    <DialogActions>
                        <Button 
                            data-cy="btn-save"
                            type="button" 
                            color="primary" 
                            onClick={this.canBeCreateNonconformity}
                            disabled = {!this.canBeSaved() || this.props.isSaving}>
                            {<Translate id="common.save"/>}
                        </Button>
                        <Button onClick={() => {this.props.history.push(`/rawmaterialnonconformity/${rawMaterial.token}`)}} color="secondary">
                            {<Translate id="common.cancel"/>}
                        </Button>
                    </DialogActions>
                </Grid> 

                <Toaster 
                    message = {this.state.toasterMessage}
                    open = {this.state.showToaster}
                    variant = {this.state.toasterVariant}
                    onClose = {() => {this.setState({ showToaster:false})}}
                /> 

            </div>
        )
    }
}

const viewStyles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: "100px"
    },
    tetxtFieldFull:{
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: "150px"
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 450,
    },
    textAreaField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 440,
        height: 80,
      },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    footerForm:{
        display: "flex",
        paddingRight: "60px",
    },
    formControl:{margin: theme.spacing.unit},
    toolbar:{},
    searchInput:{ },
    button:{ },
    actionsToolbar:{ },
    showingUsersCounter:{ },
    grow:{},
    supplierBadge:{}
});

export default withRouter(withStyles(viewStyles, { withTheme:true })(FormView));