import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import PublicIcon from '@material-ui/icons/Public'
import { Translate } from 'react-localize-redux'
import GeneralFiles from './GeneralFiles'
import ListDocumentsComponentAdd from '../../common/ListDocumentsComponentAdd'
import TabPanel from '../../common/TabPanel'
import EmptyView from '../components/EmptyView.js'
import { canBeEditExportRequestInformation } from '../../../store/helpers/ExportRequestHelper'
import { Divider, Fab } from '@material-ui/core'


function TabContainer({ children, dir }) {
	return (
		<Typography component='div' dir={dir} style={{ padding: 8 }}>
			{children}
		</Typography>
	)
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
	dir: PropTypes.string.isRequired,
}

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		// width: '100%',
	},

	titleGral: {
		marginTop: '1rem',
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	titlesTab: {
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	sendReview: {
		display: 'flex',
		justifyContent: 'flex-end',	
	}
})

class TabsInShow extends React.Component {
	state = {
		value: 0,
	}

	static getDerivedStateFromProps(props, state) {
		let tabIndices = []
		let propsArray = [
			'nationalProviderData',
			'mxProviderData',
			'usaForeignProviderData',
			'foreignTransportProviderData',
		]

		for (let i = 0; i < propsArray.length; i++) {
			if (props[propsArray[i]]) {
				tabIndices.push(i)
			}
		}
		return { ...state, tabIndices }
	}

	handleChange = (event, newValue) => {
		this.setState({ value: this.state.tabIndices[newValue] })
	}

	handleChangeIndex = (index) => {
		this.setState({ value: index })
	}

	render() {
		const { classes, dataDetail, userProfile, onUploadFileInItem } = this.props
		const {
			nationalProviderData,
			mxProviderData,
			foreignTransportProviderData,
			usaForeignProviderData,
			onDeleteFilesFromItem,
			onSynchronizeSit,
			isSavingFile
		} = this.props

		let isEditableProviderNational = canBeEditExportRequestInformation(nationalProviderData, userProfile)
		// let isEditableProviderMx = canBeEditExportRequestInformation(mxProviderData, userProfile)
		let isEditableProviderMx = true
		let isEditableProviderForeign = canBeEditExportRequestInformation(usaForeignProviderData, userProfile)
		let isEditableProviderForeignTransport = canBeEditExportRequestInformation(
			foreignTransportProviderData,
			userProfile
		)
		
		return (
			<div className={classes.root}>
				{/* <AppBar position='static' color='default'> */}
				<Tabs
					value={this.state.tabIndices.indexOf(this.state.value)}
					onChange={this.handleChange}
					indicatorColor='primary'
					textColor='primary'
					variant='fullWidth'
					style={{
						height: '5rem',
					}}
				>
					{nationalProviderData && (
						<Tab
							style={{ minWidth: '50px', maxWidth: '25%' }}
							className={classes.tab}
							label={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<PublicIcon />
									<Translate id='common.transportProvider' />
								</div>
							}
						/>
					)}
					{mxProviderData && (
						<Tab
							style={{ minWidth: '50px', maxWidth: '25%' }}
							className={classes.tab}
							label={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<PublicIcon />
									<Translate id='common.nationalProvider' />
								</div>
							}
						/>
					)}
					{usaForeignProviderData && (
						<Tab
							style={{ minWidth: '50px', maxWidth: '25%' }}
							className={classes.tab}
							label={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<PublicIcon />
									<Translate id='common.foreignProvider' />
								</div>
							}
						/>
					)}

					{foreignTransportProviderData && (
						<Tab
							style={{ minWidth: '50px', maxWidth: '25%' }}
							className={classes.tab}
							label={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<PublicIcon />
									<Translate id='common.foreignTransportProvider' />
								</div>
							}
						/>
					)}
				</Tabs>

				<div
					className={classes.sendReview}
				>
					<Fab
						variant='extended'
						size='small'
						color='primary'
						onClick={onSynchronizeSit}
						data-cy='btn-available-operations'
					>
						<Translate id='common.sendToReview' />
					</Fab>
				</div>
				{nationalProviderData || mxProviderData || usaForeignProviderData || foreignTransportProviderData ? (
					<>
						{nationalProviderData && (
							<TabPanel value={this.state.value} index={0}>
								<Typography className={classes.titlesTab}>
									<Translate id='common.transportProviderInfo' />
								</Typography>
								{nationalProviderData && (
									<>
										<Typography>
											{nationalProviderData.SupplierCode} {nationalProviderData.SupplierName}
										</Typography>
										<Typography>{nationalProviderData.SupplierEmail}</Typography>
									</>
								)}
								<ListDocumentsComponentAdd
									folderPathIdentifier='ConsignmentNoteFiles'
									title='common.waybillXmlPdf'
									documents={nationalProviderData.ConsignmentNoteFiles}
									editable={isEditableProviderNational}
									// onLateralPanelConfig={}
									// deleteFiles={}
									item={nationalProviderData}
									onUploadFileInItem={onUploadFileInItem}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
									isSavingFile={isSavingFile}
								/>

								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='InvoiceServicesFiles'
									title='common.serviceInvoice'
									documents={nationalProviderData.InvoiceServicesFiles}
									editable={isEditableProviderNational}
									onUploadFileInItem={onUploadFileInItem}
									// onLateralPanelConfig={}
									// deleteFiles={}
									item={nationalProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<Divider />
								<Typography className={classes.titleGral}>
									<Translate id='common.generalFiles' />
								</Typography>

								<GeneralFiles dataDetail={dataDetail} />
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier={''}
									title='common.salesInvoice'
									documents={dataDetail.salesInvoiceAttachments}
									item={nationalProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<Divider />
								{/* <Typography className={classes.titleGral}>
									<Translate id='common.otherDocuments' />
								</Typography>
								<GeneralFiles dataDetail={dataDetail} /> */}
							</TabPanel>
						)}
						{mxProviderData && (
							<TabPanel value={this.state.value} index={1}>
								<Typography className={classes.titlesTab}>
									<Translate id='common.nationalProviderInfo' />
								</Typography>
								{mxProviderData && (
									<>
										<Typography>
											{mxProviderData.SupplierCode} {mxProviderData.SupplierName}
										</Typography>
										<Typography>{mxProviderData.SupplierEmail}</Typography>
									</>
								)}
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='ExportPedimentoFiles'
									title='common.exportDeclaration'
									documents={mxProviderData.ExportPedimentoFiles}
									editable={isEditableProviderMx}
									onUploadFileInItem={onUploadFileInItem}
									// onLateralPanelConfig={}
									// deleteFiles={}
									item={mxProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='OtherFiles'
									title='common.otherFiles'
									documents={mxProviderData.OtherFiles}
									editable={isEditableProviderMx}
									onUploadFileInItem={onUploadFileInItem}
									// onLateralPanelConfig={}
									// deleteFiles={}
									item={mxProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='DODAFiles'
									title='common.doda'
									documents={mxProviderData.DODAFiles}
									editable={isEditableProviderMx}
									onUploadFileInItem={onUploadFileInItem}
									// onLateralPanelConfig={}
									// deleteFiles={}
									item={mxProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='InvoiceServicesFiles'
									title='common.serviceInvoice'
									documents={mxProviderData.InvoiceServicesFiles}
									editable={isEditableProviderMx}
									onUploadFileInItem={onUploadFileInItem}
									// onLateralPanelConfig={}
									// deleteFiles={}
									item={mxProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>

								<Typography className={classes.titleGral}>
									<Translate id='common.generalFiles' />
								</Typography>

								<GeneralFiles dataDetail={dataDetail} />
								{/* <Typography className={classes.titleGral}>
									<Translate id='common.otherDocuments' />
								</Typography>
								<GeneralFiles dataDetail={dataDetail} /> */}
							</TabPanel>
						)}
						{usaForeignProviderData && (
							<TabPanel value={this.state.value} index={2}>
								<Typography className={classes.titlesTab}>
									<Translate id='common.foreignProviderInfo' />​
								</Typography>
								{usaForeignProviderData && (
									<>
										<Typography>
											{usaForeignProviderData.SupplierCode} {usaForeignProviderData.SupplierName}
										</Typography>
										<Typography>{usaForeignProviderData.SupplierEmail}</Typography>
									</>
								)}
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='EntryFiles'
									title='common.entry'
									documents={usaForeignProviderData.EntryFiles}
									editable={isEditableProviderForeign}
									onUploadFileInItem={onUploadFileInItem}
									// onLateralPanelConfig={}
									// deleteFiles={}
									item={usaForeignProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='InvoiceServicesFiles'
									title='common.serviceInvoice'
									documents={usaForeignProviderData.InvoiceServicesFiles}
									editable={isEditableProviderForeign}
									onUploadFileInItem={onUploadFileInItem}
									// onLateralPanelConfig={}
									// deleteFiles={}
									item={usaForeignProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>

								<Typography className={classes.titleGral}>
									<Translate id='common.generalFiles' />
								</Typography>

								<GeneralFiles dataDetail={dataDetail} />

								{/* <Typography className={classes.titleGral}>
									<Translate id='common.otherDocuments' />
								</Typography>
								<GeneralFiles dataDetail={dataDetail} /> */}
							</TabPanel>
						)}

						{foreignTransportProviderData && (
							<TabPanel value={this.state.value} index={3}>
								<Typography className={classes.titlesTab}>
									<Translate id='common.foreignProviderInfoTab' />​
								</Typography>
								{foreignTransportProviderData && (
									<>
										<Typography>
											{foreignTransportProviderData.SupplierCode}{' '}
											{foreignTransportProviderData.SupplierName}
										</Typography>
										<Typography>{foreignTransportProviderData.SupplierEmail}</Typography>
									</>
								)}
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='PODFiles'
									title='common.POD'
									documents={foreignTransportProviderData.PODFiles}
									editable={isEditableProviderForeignTransport}
									onUploadFileInItem={onUploadFileInItem}
									// onLateralPanelConfig={}
									// deleteFiles={}
									item={foreignTransportProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='AmericanFreightInvoiceFiles'
									title='common.americanFreightInvoice'
									documents={foreignTransportProviderData.AmericanFreightInvoiceFiles}
									editable={isEditableProviderForeignTransport}
									onUploadFileInItem={onUploadFileInItem}
									// onLateralPanelConfig={}
									// deleteFiles={}
									item={foreignTransportProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<Typography className={classes.titleGral}>
									<Translate id='common.generalFiles' />
								</Typography>
								<GeneralFiles dataDetail={dataDetail} />
							</TabPanel>
						)}
					</>
				) : (
					<EmptyView />
				)}
			</div>
		)
	}
}

TabsInShow.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(TabsInShow)
