import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate, getTranslate } from 'react-localize-redux'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Evidence from './../common/Evidence'
import DataTable from '../common/DataTable'
/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import RatesIcon from '@material-ui/icons/BarChartTwoTone'
import ImportIcon from '@material-ui/icons/ImportExport'
import InfoIcon from '@material-ui/icons/Info'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

import Toaster from '../common/Toaster'
import { Divider } from '@material-ui/core'

import templateQualifications from '../../resources/files/templateQualificationsImberaBr.xlsx'

import { GetMonths, GetYears } from '../../store/helpers/SelectOptions'
import { ImportQualifications, getQualificationsBrail, notifySuppliers } from '../../store/ratings/ratingActions'

class Qualifications extends Component {
	/**
	 * Create an instance ofMemos
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			files: [],
			fileBase64: '',
			monthDate: '',
			yearDate: '',
		}
	}

	/**
	 * Handle event on upload files triggered by button
	 *
	 * @param {*} files
	 * @returns
	 * @memberof ImportRating
	 */
	onUploadFiles() {
		if (this.props.ImportQualifications)
			return this.props
				.ImportQualifications(this.state.files, this.state.yearDate, this.state.monthDate)
				.then(() => {
					this.setState({
						showToView: true,
						showToaster: true,
						toasterMessage: <Translate id='common.saveCorrectly' />,
						toasterVariant: 'success',
					})
					return Promise.resolve('Ok')
				})
				.catch(() => {
					let errorMessage =
						this.props.translate('rating.errorImportingRating') +
						' ' +
						this.props.translate('rating.baseErrorRatingsUpload')
					this.setState({
						showToaster: true,
						toasterVariant: 'error',
						toasterMessage: errorMessage,
					})
					return Promise.reject()
				})
	}

	getQualificationsBr() {
		if (this.props.getQualificationsBrail)
			return this.props
				.getQualificationsBrail(this.state.yearDate, this.state.monthDate)
				.then(() => {
					return Promise.resolve('Ok')
				})
				.catch(() => {
					let errorMessage = this.props.translate('rating.errorImportingRating')
					this.setState({
						showToaster: true,
						toasterVariant: 'error',
						toasterMessage: errorMessage,
					})
					return Promise.reject()
				})
	}

	/**
	 * Handle event upload files
	 *
	 * @param {*} files
	 * @returns
	 * @memberof ImportRating
	 */
	handleUploadFiles(files) {
		this.setState({ files })
		return Promise.resolve('Ok')
	}

	/**
	 * Handle event remove file
	 *
	 * @param {*} fileName
	 * @returns
	 * @memberof ImportRating
	 */
	handleRemoveFiles(fileName) {
		this.setState({
			files: this.state.files.filter((file) => {
				return file.name !== fileName
			}),
		})
		return Promise.resolve('Ok')
	}

	/**
	 *Set month to state
	 *
	 * @param {*} date
	 * @memberof Index
	 */
	onSetMonthData(montdte) {
		this.setState({
			monthDate: montdte.value,
		})
	}

	/**
	 * Set year date
	 * @param {*} year
	 */
	onSetYearData(year) {
		this.setState({
			yearDate: year.value,
		})
	}

	calculateAverage() {
		let total = 0
		if (this.props.ratingToPublish && this.props.ratingToPublish.length > 0) {
			let sumPercetage = this.props.ratingToPublish.reduce((sum, item) => sum + item.final_br, 0)
			total = sumPercetage.toFixed(2)
		}
		return total
	}

	notifySuppliers(isDisabledNotificate) {
		if (this.props.notifySuppliers && isDisabledNotificate)
			return this.props
				.notifySuppliers(this.state.yearDate, this.state.monthDate)
				.then(() => {
					this.setState({
						showToView: true,
						showToaster: true,
						toasterMessage: <Translate id='rating.correctlyNotifyingSuppliers' />,
						toasterVariant: 'success',
					})
					return Promise.resolve('Ok')
				})
				.catch(() => {
					let errorMessage = this.props.translate('rating.errorNotifyingSuppliers')
					this.setState({
						showToaster: true,
						toasterVariant: 'error',
						toasterMessage: errorMessage,
					})
					return Promise.reject()
				})
		else {
			this.setState({
				showToaster: true,
				toasterVariant: 'error',
				toasterMessage: this.props.translate('rating.errorValidationNotifySupplier')
			})
		}
	}

	render() {
		const { classes } = this.props
		let isDisabled = this.state.files.length > 0 && this.state.monthDate !== '' && this.state.yearDate !== ''
		let isDisabledGet = this.state.monthDate !== '' && this.state.yearDate !== ''
		let isDisabledNotificate = this.state.yearDate !== '' && this.state.monthDate !== '' && this.props.ratingToPublish && this.props.ratingToPublish.length > 0
		let tableConfiguration = TableratingsTableConfiguration(this.props.translate)
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<RatesIcon />
						</Avatar>
					}
					title={<Typography variant='button'>{<Translate id='rating.importRating' />} </Typography>}
				/>
				<CardContent>
					<Grid container spacing={24}>
						<Grid
							item
							xs={12}
							className='alert alert-info'
							role='alert'
							style={{ marginLeft: '.7rem', marginRight: '.7rem' }}
						>
							<h6 className='alert-heading'>
								<InfoIcon />
								<Translate id='rating.importRating' />
							</h6>
							<p>
								<Translate id='rating.messatoToImportt' />{' '}
							</p>
						</Grid>

						<Grid item xs={12}>
							<Grid container justify='flex-end' alignItems='center' spacing={24}>
								<Grid item xs={2}>
									<MonthsTypeSelect
										monthDate={this.state.monthDate}
										setMonthData={this.onSetMonthData}
									/>
								</Grid>
								<Grid item xs={2}>
									<YearsTypeSelect yearDate={this.state.yearDate} setYearData={this.onSetYearData} />
								</Grid>
								<Grid item xs={3}>
									<Button
										variant='contained'
										color='secondary'
										className={classes.button}
										disabled={!isDisabledGet || this.props.isSaving}
										onClick={() => {
											this.getQualificationsBr()
										}}
									>
										<ImportIcon className={classes.rightIcon} />
										{this.props.isSaving ? (
											'Obtivermos calificaciones...'
										) : (
											<Translate id='rating.getRating' />
										)}
									</Button>
								</Grid>
								<Grid item xs={3}>
									<Button
										variant='contained'
										color='primary'
										className={classes.button}
										disabled={!isDisabled || this.props.isSaving}
										onClick={() => {
											this.onUploadFiles()
										}}
									>
										<ImportIcon className={classes.rightIcon} />
										{this.props.isSaving ? (
											'Importando calificaciones...'
										) : (
											<Translate id='rating.importRating' />
										)}
									</Button>
								</Grid>
								<Grid item xs={2}>
									<Button
										variant='contained'
										color='primary'
										className={classes.button}
										disabled={!isDisabledNotificate || this.props.isSending}
										onClick={() => {
											this.notifySuppliers(isDisabledNotificate)
										}}
									>
										{this.props.isSending ? (
											'Enviando...'
										) : (
											<Translate id='rating.notify' />
										)}
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Evidence
								baseApi={process.env.REACT_APP_SIP_URL_FILES}
								removeFiles={this.handleRemoveFiles}
								uploadFiles={this.handleUploadFiles}
								files={this.state.files}
								acceptedFiles={['excel']}
								maxFiles={1}
								disabledLink
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<Divider />
						<small className='text-muted'>
							<Translate id='rating.uploadTemplate' />
						</small>{' '}
						<small className='text-muted'>
							<a href={templateQualifications}>
								<Translate id='rating.uploadFormatRating' />
							</a>
						</small>
						<Divider />
					</Grid>

					<Grid item xs={12} style={{ marginTop: '.5rem' }}>
						<DataTable
							title={<Translate id='menu.ratings' />}
							data={this.props.ratingToPublish ? this.props.ratingToPublish : []}
							configuration={tableConfiguration}
							onRenderCellItem={this.onRenderRatingsCellItem}
						/>
						<Grid container justify='flex-end' alignItems='flex-end'>
							<Grid item xs={2}>
								<strong>
									<Translate id='rating.average' />:
								</strong>
							</Grid>
							<Grid item xs={1}>
								<Typography align='left'>{this.calculateAverage()}</Typography>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

/**
 * Get the date selection
 *
 * @class MonthsTypeSelect
 * @extends {Component}
 */
class MonthsTypeSelect extends Component {
	/**
	 * Render
	 *
	 * @returns
	 * @memberof MonthsTypeSelect
	 */
	render() {
		let months = GetMonths()
		return (
			<Select
				value={months[this.props.monthDate]}
				options={months}
				onChange={this.props.setMonthData}
				styles={{
					menu: (base) => ({ ...base, zIndex: 1000, height: 150 }),
					menuList: (base) => ({ ...base, height: 150, paddingTop: 0 }),
					menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
				}}
				menuPortalTarget={document.parentNode}
				menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
				menuPlacement={'auto'}
			/>
		)
	}
}

/**
 * Get the date selection
 *
 * @class YearsTypeSelect
 * @extends {Component}
 */
class YearsTypeSelect extends Component {
	/**
	 * Render
	 *
	 * @returns
	 * @memberof YearsTypeSelect
	 */
	render() {
		let years = GetYears()
		return (
			<Select
				value={years[this.props.yearDate]}
				options={years}
				onChange={this.props.setYearData}
				styles={{
					menu: (base) => ({ ...base, zIndex: 1000, height: 150 }),
					menuList: (base) => ({ ...base, height: 150, paddingTop: 0 }),
					menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
				}}
				menuPortalTarget={document.parentNode}
				menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
				menuPlacement={'auto'}
			/>
		)
	}
}

function TableratingsTableConfiguration(translate) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: translate('rating.ratingsTable.month'),
			dataSource: 'rating_date',
		},
		{
			header: translate('deliveryQualifications.supplierNumber'),
			dataSource: 'supplier_number',
		},
		{
			header: translate('common.supplier'),
			dataSource: 'supplier_name',
		},
		{
			header: `${translate && translate('rating.ratingsTable.ppm')} ${'45'}%`,
			dataSource: 'quality_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.rnc')} ${'20'}%`,
			dataSource: 'service_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.deliveries')} ${'25'}%`,
			dataSource: 'delivery_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.warehouse')} ${'10'}%`,
			dataSource: 'development_points',
		},
		{
			header: <Translate id='rating.ratingsTable.finalScore' />,
			dataSource: 'final_score',
		},
		{
			header: <Translate id='rating.ratingsTable.classification' />,
			dataSource: 'description_status',
		},
		{
			header: <Translate id='rating.ratingsTable.pontuationFinal' />,
			dataSource: 'final_br',
		},
	]
	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		translate: getTranslate(state.localize),
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isSaving: state.ratings.get('isSaving'),
		isSending : state.ratings.get('isSending'),
		ratingToPublish: state.ratings.get('ratingToPublish') ? state.ratings.get('ratingToPublish').toJS() : null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		ImportQualifications: (files, year, month) => {
			return dispatch(ImportQualifications(files, year, month))
		},
		getQualificationsBrail: (year, month) => {
			return dispatch(getQualificationsBrail(year, month))
		},
		notifySuppliers: (year, month) => {
			return dispatch(notifySuppliers(year, month))
		},
	}
}

const styles = (theme) => ({
	selectorContainer: {
		paddingTop: '5px',
		paddingBottom: '5px',
	},
	textHelper: {
		paddingBottom: '2px',
	},
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Qualifications)))
)
