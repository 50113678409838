/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux'
/**Import MaterialUi section */
import {
    Grid, Avatar, Typography, Button, TextField
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import SupplierResponseComponent from './SupplierResponseComponent'
import CommentsComponent from '../CommentsComponent'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';
import { supplierCanEditBidding } from '../../../../store/helpers/BiddingHelper'
/**Import helperts section */
import {
    getCriteriaService,
} from '../../../../store/biddings/BindingsSelectors'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import {
    COLOR_STATUS_ALERT_INFO
} from '../../../../store/helpers/StatusColorConstants'
import {
    CriteriaType
} from '../../../../store/helpers/AppConstants'
const AddSupplierResponseGralLateralPanel = (props) => {
    const CHARACTER_LIMIT_DESCRIPTION = 250;
    /**props section */
    const { onCloseButtonClick,
        isSaving, classes, selectedItem, bidding,
        userProfile, userRolesByCompany, isExternalUser,
        onAddResponseSupplier, translate
    } = props
    const evaluationCriteria = getCriteriaService(bidding);
    const [valueResponse, setValueResponse] = useState(null);
    const [comments, setComments] = useState(null);
    const [errors, setErrors] = useState({});

    /**Use effect section */
    useEffect(() => {
        if (props.selectedItem && valueResponse === null) {
            setValueResponse(props.selectedItem.Response)
            setComments(props.selectedItem.Comments)
        }
    }, [selectedItem.Response])

    const onChangeValueResponse = (valueResponse, errors) => {
        setValueResponse(valueResponse)
        setErrors(errors)
    }

    const onSaveSupplierResponse = () => {
        if (props.onAddResponseSupplier) {
            props.onAddResponseSupplier(evaluationCriteria ? evaluationCriteria.Id : "", selectedItem.Id, valueResponse, true, comments)
        }
    }

    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (typeof valueResponse === 'boolean') {
            if (valueResponse === null) return false
            return true
        }
        if (IsNullOrEmpty(valueResponse)) return false
        return true;
    }

    const onPropertyChange = (event) =>{
        setComments(event.target.value)
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<Translate id="biddingsModule.responseSupplier" />}
                // subTitlePanel={``}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    < Grid container >
                        <CommentsComponent
                            title={<Translate id="common.comments" />}
                            comments={selectedItem ? selectedItem.Notes : ""}
                        />
                        <Grid item xs={12} className={classes.titleDisplay} style={{ marginTop: "12px" }}>
                            <Typography color="primary" variant="subtitle2">{selectedItem ? selectedItem.Name : ""}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SupplierResponseComponent
                                item={selectedItem}
                                isSaving={isSaving}
                                onAddResponseSupplier={onAddResponseSupplier}
                                isDisabled={supplierCanEditBidding(bidding, userProfile, userRolesByCompany, isExternalUser)}
                                rowsMax={14}
                                onChangeValueResponse={onChangeValueResponse}
                                valueResponse={valueResponse}
                                translate={translate}
                            />

                        </Grid>

                       { 
                       selectedItem && selectedItem.CriteriaType ===CriteriaType.TEXT &&
                       <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={<Translate id="biddingsModule.commentsFromSupplier" />}
                                className={classes.textField}
                                onChange={onPropertyChange}
                                multiline
                                name='comments'
                                margin="dense"
                                variant="outlined"
                                disabled={!supplierCanEditBidding(bidding, userProfile, userRolesByCompany, isExternalUser)}
                                value={comments ? comments: ''}
                                inputProps={{ maxLength: CHARACTER_LIMIT_DESCRIPTION }}
                                helperText={`${comments ? comments.length : 0}/${CHARACTER_LIMIT_DESCRIPTION}`}
                            />
                        </Grid>
                        }

                    </Grid>
                </div>
                {
                    supplierCanEditBidding(bidding, userProfile, userRolesByCompany, isExternalUser) &&
                    <Grid container>
                        <Grid item xs={12} className={classes.containerBtn}>
                            <Button
                                data-cy="btn-save"
                                type="button"
                                color="primary"
                                variant='outlined'
                                onClick={onSaveSupplierResponse}
                                disabled={isSaving || (errors && Object.entries(errors).length !== 0)|| !canBeSaved()}

                            >
                                {<Translate id="common.save" />}
                            </Button>
                        </Grid>
                    </Grid>
                }

            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "20px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    titleDisplay: {
        display: "flex"
    },
    containerDivisaCode: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center"
    },
    inputProps: {
        height: 6,
    },
    card: {
        backgroundColor: COLOR_STATUS_ALERT_INFO

    },
    fontWeight: {
        fontWeight: "bold"
    }

});

AddSupplierResponseGralLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddSupplierResponseGralLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddSupplierResponseGralLateralPanel))