import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'

export const IS_LOADING_TRAININGS = 'IS_LOADING_TRAININGS'
export const SET_TRAINING_LIST = 'SET_TRAINING_LIST'
export const SET_TRAINING = 'SET_TRAINING'
export const SET_TRAINING_COUNT = 'SET_TRAINING_COUNT'
export const IS_SAVING_TRAINING = 'IS_SAVING_TRAINING'
export const IS_LOADING_TRAINING = 'IS_LOADING_TRAINING'

function isLoadingTrainings(isLoadingTrainings) {
	return {
		type: IS_LOADING_TRAININGS,
		isLoadingTrainings,
	}
}

/**
 * set trainings
 * @param {*} trainings
 * @returns
 */
function setTrainingList(trainingsList) {
	return {
		type: SET_TRAINING_LIST,
		trainingsList,
	}
}

function setTraining(training) {
	return {
		type: SET_TRAINING,
		training,
	}
}

function setTrainingCount(trainingCount) {
	return {
		type: SET_TRAINING_COUNT,
		trainingCount,
	}
}

/**
 * Is saving isSavingTraining
 *
 * @param {*} isSavingTraining
 * @returns
 */
function isSavingTraining(isSavingTraining) {
	return {
		type: IS_SAVING_TRAINING,
		isSavingTraining,
	}
}

function isLoadingTraining(isLoadingTraining) {
	return {
		type: IS_LOADING_TRAINING,
		isLoadingTraining,
	}
}

export function getTrainings(queryParameters) {
	return function (dispatch, getState) {
		dispatch(isLoadingTrainings(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/trainings/search`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
			Page: queryParameters && queryParameters.pageNumber ? parseInt(queryParameters.pageNumber) + 1 : 1,
			RowsPerPage: queryParameters && queryParameters.rowsPerPage ? queryParameters.rowsPerPage : 20,
			SortBy: queryParameters && queryParameters.sortBy ? queryParameters.sortBy : 'CreationDate',
			SortAscending: queryParameters && queryParameters.sortDirection ? queryParameters.sortDirection : 'desc',
			Query: queryParameters && queryParameters.query ? queryParameters.query : '',
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTrainingList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingTrainings(false))
				console.error('Error getting the trainings', error.response)
			})
	}
}

/**
 * Add new training
 *
 * @export
 * @param {*} file
 * @param {*} employee
 * @returns
 */
export function addEmployeeTraining(files, employee, startDate, endDate, name) {
	return (dispatch, getState) => {
		dispatch(isSavingTraining(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (user.profile && user.profile.company_code) {
			supplierCode = user.profile.company_code
		}

		let formData = new FormData()
		files.forEach((file) => {
			formData.append('TrainingFiles', file)
		})

		formData.set('CompanyToken', selectedCompany.get('token'))
		formData.set('SupplierCode', supplierCode)
		formData.set('EmployeedToken', employee ? employee.value : '')
		formData.set('Name', name ? name : '')
		formData.set('StartDate', startDate.format('DD/MM/YYYY'))
		formData.set('EndDate', endDate.format('DD/MM/YYYY'))

		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/trainings`
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingTraining(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingTraining(false))
				console.error('Error adding training', error.response)
				return Promise.reject()
			})
	}
}

export function getTraining(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingTraining(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/trainings/search/${token}`
		let data = {}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingTraining(false))
					dispatch(setTraining(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingTraining(false))
				console.error('Error getting training by token', error.response)
			})
	}
}

export function editEmployeeTraining(startDate, endDate, name, token) {
	return (dispatch, getState) => {
		dispatch(isSavingTraining(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let formData = new FormData()
		formData.set('Name', name ? name : '')
		formData.set('StartDate', startDate.format('DD/MM/YYYY'))
		formData.set('EndDate', endDate.format('DD/MM/YYYY'))
		formData.set('Token', token)

		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/trainings/edit`
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingTraining(false))
					dispatch(setTraining(serverResponse.data))

					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingTraining(false))
				console.error('Error adding training', error.response)
				return Promise.reject()
			})
	}
}

export function getTrainingCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().trainings.get('trainingCount')
		if (actualCounter) {
			return
		}
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/trainings/search/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTrainingCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error getting training count', error.response)
			})
	}
}

export function downloadReportTraining(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(isSavingTraining(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
			StartDate: startDate,
			EndDate: endDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/trainings/downloadReport`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingTraining(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_capacitaciones.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isSavingTraining(false))
				console.error('Error downloading report report by month', error.response)
				return Promise.reject()
			})
	}
}

export function validateTrainingInPlant(token, status, comments) {
	return (dispatch, getState) => {
		dispatch(isSavingTraining(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let data = {
			Token: token,
			Status: status,
			ValidateBy: user.profile.name,
			Comments: comments,
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/trainings/validate`
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTraining(serverResponse.data))
					dispatch(isSavingTraining(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingTraining(false))
				console.error('Error in validate Trainingin plant ', error.response)
				return Promise.reject()
			})
	}
}
