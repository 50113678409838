/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import CreatableSelect from 'react-select/creatable';
/**Import MaterialUi section */
import {
    Grid, Typography, Avatar, Button
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';
import { getCriteriaGroup } from '../../../../store/biddings/BindingsSelectors'

import {
    getOptionsGroupReactSelect,
} from '../../../../store/helpers/SelectOptions'
const EditCriteriaGroupLateralPanel = (props) => {

    /**props section */
    const { onCloseButtonClick,
        isSaving, classes, translate, bidding,
    } = props
    const groups = getCriteriaGroup(bidding);
    let options = getOptionsGroupReactSelect(groups)
    const [biddingModel, setBiddingModel] = useState({ Grouper: null, NewGrouper: null });

    /**Use effect section */
    useEffect(() => {
        if ((biddingModel.Grouper === null) && props.evaluationCriteria && props.evaluationCriteria.Group) {
            const groups = getCriteriaGroup(bidding);
            let optionsReact = getOptionsGroupReactSelect(groups)
            let grouper = optionsReact.find(x => x.label === props.evaluationCriteria.Group)
            setBiddingModel({
                ...biddingModel,
                Grouper: grouper,
                Id: props.evaluationCriteria.Id,
                BiddingId: props.evaluationCriteria.BiddingId
            })
        }
    }, [])

    /**Verify if can be saved data*/
    const canBeSaved = () => {
        if (biddingModel.NewGrouper === null) return false
        if (biddingModel.NewGrouper.label === biddingModel.Grouper.label) return false
        return true
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            props.onSaveButtonClick(biddingModel)
        }
    }
    const onSelectedChange = (name, option) => {
        setBiddingModel({
            ...biddingModel,
            [name]: option
        });
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<Translate id="biddingsModule.evaluationCriteria.editGroup" />}
                subTitlePanel={`${translate("biddingsModule.table.folio")} | ${props.bidding ? props.bidding.FormatedFolio : ""}`}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >
                        <Grid item xs={12} data-tut="reactour-grouper">
                            <Typography color='primary' variant='subtitle2'><Translate id="biddingsModule.categoActual"/></Typography>
                            <CreatableSelect
                                placeholder={<Translate id="biddingsModule.evaluationCriteria.grouper" />}
                                options={options}
                                onChange={(item) => onSelectedChange('Grouper', item)}
                                value={biddingModel ? biddingModel.Grouper : null}
                                isDisabled
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10 }),
                                    menuList: (base) => ({ ...base, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                }}
                            /><br />
                        </Grid>
                        <Grid item xs={12} data-tut="reactour-grouper">
                            <Typography color='primary' variant='subtitle2'><Translate id="biddingsModule.evaluationCriteria.newGroup" /></Typography>
                            <CreatableSelect
                                placeholder={<Translate id="biddingsModule.evaluationCriteria.newGroup" />}
                                options={options}
                                onChange={(item) => onSelectedChange('NewGrouper', item)}
                                value={biddingModel ? biddingModel.NewGrouper : null}
                                isOptionDisabled={(option) => option.label ===(biddingModel.Grouper ? biddingModel.Grouper.label:"")}
                                styles={{
                                    menu: (base) => ({ ...base, zIndex: 10 }),
                                    menuList: (base) => ({ ...base, paddingTop: 0 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                                }}
                            /><br />
                        </Grid>
                    </Grid>
                </div>
                <Grid container >
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-tut="reactour-save"
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            disabled={!canBeSaved() || isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "20px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    }
});

EditCriteriaGroupLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

EditCriteriaGroupLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(EditCriteriaGroupLateralPanel))