import React, {Component} from 'react';
import autoBind from 'auto-bind';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Translate } from 'react-localize-redux';

// import PropTypes from "prop-types";
import { Typography, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

/*Material UI Components*/
import { TextField, InputAdornment } from '@material-ui/core';
import Toaster from '../../common/Toaster';

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import { showWarningToaster ,  showSuccessToaster, showErrorToaster} from '../../../store/helpers/ToasterHelpers';
import {registerArrivalDeliveryOrder}  from '../../../store/planning/DeliveryOrdersAction';

/**
 * Start page container
 *
 * @class UsersContainer
 * @extends {Component}
 */
class RegisterArrival extends Component {    
    /**
     *Creates an instance of RegisterArrival.
     * @param {*} props
     * @memberof c
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            currentFolio: '',
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
        };
    }

       /**
     * Update the value of query on local state
     *
     * @param {*} event
     * @memberof searchDemandsByQuery
     */
    onChangeFolio(event) {
        this.setState({ currentFolio: event.target.value });
    }
     
    /**
     * Function to register folio from delivery order
     * @param {*} event 
     */
    onClickRegisterFolio(event) {
        if (event.key === "Enter") {
            let currentFolio = this.state.currentFolio;
            let folioTrim =   String(currentFolio).trim();;
            if(folioTrim.length === 15){
                return this.props.registerArrivalDeliveryOrder(currentFolio)
                    .then((resonse) => {
                        this.setState({
                            openCancelDialog: false,
                        })
                        this.setState(showSuccessToaster());
                        this.props.history.push(`/planning/deliveryorder/${resonse.token}`)
                        return Promise.resolve('Ok')
                    })
                    .catch((error) => {
                        this.setState(showErrorToaster(error));
                        return Promise.reject();
                    })
            }
            else{
                this.setState(showWarningToaster("deliveryOrderModule.folioInvalid"));
            }
        }
    }

    /**
     * Render
     *
     * @returns
     * @memberof ContractorContainer
     */
    render() {
        return (
            <div className="search-body">
                <Paper elevation={4}>
                    <div className="start-page">
                    <br/><br/>
                        <Typography variant="headline" style={{color:"#428bca"}} align="center">
                            <Translate id="deliveryOrderModule.registerFolioInPlant" />
                        </Typography>
                        <div className="div-search-folio">
                        <TextField
                                autoFocus
                                color="primary"
                                className="search-folio"
                                onKeyPress = {this.onClickRegisterFolio}
                                onChange={this.onChangeFolio}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                    ),
                                    disableUnderline:false
                                }}
                            />
                        </div>
                    </div>
                </Paper>

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </div>
        );
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {

    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        registerArrivalDeliveryOrder:(folio) =>{
            return dispatch(registerArrivalDeliveryOrder(folio))
        }
    }
}

const styles = {
    root: {
      background: 'linear-gradient(45deg, #f9d10d 30%, #f9d10d 90%)',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 10,
      width: 80,
      padding: '0px 9px',
      boxShadow: '0 3px 5px 2px rgba(231, 191, 13, .3)',
    },
  };


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RegisterArrival))));