const modules = {
	administrationModule: {
		id: 'administrationModule',
		items: ['Companies', 'InternalUsers', 'Suppliers', 'OfficialNotice', 'WarehouseStaff'],
	},
	suppliersDevelopmentModule: {
		id: 'suppliersDevelopmentModule',
		items: [
			'Samples',
			'AlternativeProvedure',
			'RequestPriceChange',
			'Quotes',
			'Memos',
			'SacsDevelopment',
			'NoticeChanges',
			'SupplierQualification',
			'Traceability',
		],
	},
	qualityModule: {
		id: 'qualityModule',
		items: [
			'Rework',
			'QualityFailureReports',
			'Deviations',
			'CommonUsersQuality',
			'NonConformities',
			'Devolutions',
			'SacsQuality',
			'QualityInspections',
			'NonConformityMaterial',
		],
	},
	importsModule: {
		id: 'importsModule',
		items: ['ImportRequest'],
	},
	consultationsModule: {
		id: 'consultationsModule',
		items: ['Consultation', 'Inventories', 'PurchaseOrders', 'Consignment', 'WarehouseEntries'],
	},
	planningModule: {
		id: 'planningModule',
		items: ['PlanningModule'],
	},
	accreditedWorkshopsModule: {
		id: 'accreditedWorkshopsModule',
		items: ['AccreditedWorkshopsPayments'],
	},
	sustainability: {
		id: 'sustainability',
		items: ['Sustainability'],
	},
	consignmentNote: {
		id: 'consignmentNote',
		items: ['ConsignmentNote'],
	},
	supplierProspectus: {
		id: 'supplierAndExpedients',
		items: ['SupplierProspectus'],
	},
}

export default modules
