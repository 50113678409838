import { fromJS } from 'immutable';

import * as auditActions from './QualityAuditActions'

/**
 * contraActions initial state
 */
export const auditInitialState = fromJS({
    isLoading: true,
    identifier: "",
    isSaving: false,
    auditList: [],
    audit: null,
    auditCount: 0,
    isSavingTemplate: false,
})

export default function companiesReducer(state = auditInitialState, action) {
    switch (action.type) {
        case auditActions.START_LOADING_AUDIT_LIST: {
            return state.merge({
                isLoading: action.isLoading,
                identifier: action.identifier,
            })
        }
        case auditActions.SET_AUDIT_LIST: {
            return state.merge({
                auditList: fromJS(action.auditList),
                isLoading: false,
                identifier: ""
            })
        }
        case auditActions.SET_AUDIT_COUNT: {
            return state.merge({
                auditCount: action.count
            })
        }
        case auditActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving,
                identifier: action.identifier,
            })
        }
        case auditActions.SET_AUDIT: {
            return state.merge({
                isLoading: false,
                identifier: "",
                audit: action.audit
            })
        }
        case auditActions.IS_SAVINGTEMPLATE: {
            return state.merge({
                isSavingTemplate: action.isSavingTemplate,
                identifier: action.identifier,
            })
        }
        case auditActions.UPLOAD_FILES_AUDIT: {
            let oldList = state.getIn(['audit', 'attachments']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['audit', 'attachments'], fromJS(newList));
        }
        case auditActions.UPLOAD_FILES_PLAN: {
            let oldList = state.getIn(['audit', 'planAction']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['audit', 'planAction'], fromJS(newList));
        }
        case auditActions.UPLOAD_FILES_PLAN_EVIDENCE: {
            let oldList = state.getIn(['audit', 'actionPlanEvidence']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['audit', 'actionPlanEvidence'], fromJS(newList));
        }
        case auditActions.REMOVE_FILES_AUDIT: {
            let fileAction = action.fileType;
            if (fileAction === 1) {
                let oldList = state.getIn(['audit', 'attachments']).toJS();
                let newList = oldList.filter(file => {
                    return file.name !== action.filesName
                })
                return state.setIn(['audit', 'attachments'], fromJS(newList));
            }
            else if (fileAction === 2) {
                let oldList = state.getIn(['audit', 'planAction']).toJS();
                let newList = oldList.filter(file => {
                    return file.name !== action.filesName
                })
                return state.setIn(['audit', 'planAction'], fromJS(newList));
            }
            else if (fileAction === 3) {
                let oldList = state.getIn(['audit', 'actionPlanEvidence']).toJS();
                let newList = oldList.filter(file => {
                    return file.name !== action.filesName
                })
                return state.setIn(['audit', 'actionPlanEvidence'], fromJS(newList));
            }
            return state;
        }
        case auditActions.UPDATE_AUDIT: {
            let property = action.property;
            let updateValue = action.updateValue;
            return state.setIn(['audit', property], updateValue).set('isSaving', false);
        }
        default:
            return state;
    }
}