import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import { fade } from '@material-ui/core/styles/colorManipulator'
import classNames from 'classnames'

import { withLocalize, getTranslate } from 'react-localize-redux'
import { Close } from '@material-ui/icons'

/**
 * Search input
 *
 * @class SearchInput
 * @extends {Component}
 */
class SearchInput extends Component {
	/**
	 *Creates an instance of SearchInput.
	 * @param {*} props
	 * @memberof SearchInput
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			searchQuery: this.props.value ? this.props.value : '',
		}
	}

	/**
	 * On key press
	 *
	 * @param {*} key
	 * @memberof SearchInput
	 */
	onKeyPress(event) {
		if (event.key === 'Enter') {
			this.props.onChangeSearchValue(this.state.searchQuery)
		}
	}

	/**
	 *
	 *
	 * @param {*} event
	 * @memberof SearchInput
	 */
	onChange(event) {
		this.setState({
			searchQuery: event.target.value,
		})
	}
	/**
	 *
	 *
	 * @param {*} event
	 * @memberof SearchInput
	 */
	onDropInput(event) {
		this.setState({
			searchQuery: '',
		})
		this.props.onChangeSearchValue('')
	}
	/**
	 *
	 *
	 * @returns
	 * @memberof SearchInput
	 */
	render() {
		const { classes } = this.props
		return (
			<div className={classNames(this.props.className, classes.search)}>
				<div className={classes.searchIcon}>
					<SearchIcon />
				</div>
				<div className={classes.contentInput}>
					<InputBase
						placeholder={this.props.translate('common.search')}
						onKeyPress={this.onKeyPress}
						onChange={this.onChange}
						value={this.state.searchQuery}
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
					/>
					<div className={classes.closeIcon}>
						{this.state.searchQuery && <Close onClick={this.onDropInput} />}
					</div>
				</div>
			</div>
		)
	}
}

const styles = (theme) => ({
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.primary.main, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.primary.main, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing.unit,
			width: 'auto',
		},
		height: '35px',
	},
	searchIcon: {
		width: theme.spacing.unit * 9,
		color: theme.palette.text.hint,
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	closeIcon: {
		color: theme.palette.text.hint,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
		width: '100%',
	},
	inputInput: {
		paddingTop: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingBottom: theme.spacing.unit,
		paddingLeft: theme.spacing.unit * 10,
		transition: theme.transitions.create('width'),
		width: '100%',
		fontSize: '14px',
		[theme.breakpoints.up('sm')]: {
			width: 120,
			'&:focus': {
				width: 200,
			},
		},
	},
	contentInput: {
		display: 'flex',
	},
})

const mapStateToProps = (state) => ({ translate: getTranslate(state.localize) })
export default withLocalize(withStyles(styles)(connect(mapStateToProps)(SearchInput)))
