import React, {useState} from 'react';
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/**Import MaterialUi section */
import { Grid, Button, Avatar, Typography, TextField } from '@material-ui/core'
import FindInPage from '@material-ui/icons/FindInPage';

/** section import components */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';

/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader';
import SupplierSelector from '../../common/SupplierSelector'


const PanelConsignmentNote = props => {
    /**props section */
    const {
        onCloseButtonClick,
        classes,
        onSaveButtonClick,
        isSaving,
        selectedCompany
    } = props

    const [panelInformation, setPanelInformation] = useState({
        transport: null,
        transportCode: ""
    });

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setPanelInformation({
            ...panelInformation,
            [name]: value
        });
    }

    const onSelectedChange = (option, transport) => {
        setPanelInformation({
            ...panelInformation,
            [transport]: option
        });
    }

    const canBeSaved = () => {
        if(panelInformation.transport === null) return false
        if(panelInformation.transportCode === null) return false
        else return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(panelInformation)
        }
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}>
            <PanelHeader
                titlePanel={<Translate id="consignmentNote.create.addFolioPanel" />}
                icon={<Avatar className={classes.avatar}><FindInPage /></Avatar>}
                onCloseButtonClick={onCloseButtonClick}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container>
                        <Typography color='primary'><Translate id="consignmentNote.create.sender"/></Typography>
                        <Grid item xs={12}>
                             <TextField
                                 fullWidth
                                 disabled={true}
                                 className={classes.textField}
                                 value={selectedCompany ? selectedCompany.name : ""}
                                 onChange={onPropertyChange}
                                 name='name'
                                 margin="normal"
                                 variant="outlined"
                             />
                        </Grid>
                        <br/>
                        <Typography color='primary'>{"Folio transporte"}</Typography>
                        <Grid item xs={12}>
                             <TextField
                                 fullWidth
                                 className={classes.textField}
                                 value={selectedCompany ? selectedCompany.transportCode : ""}
                                 onChange={onPropertyChange}
                                 name='transportCode'
                                 margin="normal"
                                 variant="outlined"
                             />
                        </Grid>
                        <br/>
                        <Typography color='primary'><Translate id="consignmentNote.create.selectTransports"/></Typography>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <SupplierSelector
                                placeholder="consignmentNote.create.searchTransports"
                                onSupplierSelected={(transport) => onSelectedChange(transport, "transport")}/>
                        </Grid>
                    </Grid>
                </div>
                <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            className={classes.saveButton}
                            disabled={!canBeSaved() || isSaving}
                            onClick={onSave}>
                            {<Translate id="common.save" />}
                        </Button>
                        <Button
                            data-cy="btn-cancel"
                            type="button"
                            color="secondary"
                            variant='outlined'
                            className={classes.cancelButton}
                            onClick={onCloseButtonClick}>
                            {<Translate id="common.cancel" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </LateralPanel >
    );
};

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },
    saveButton: {
        bottom: 0,
        margin: 2
    },
    cancelButton:{
        color:theme.palette.error.main,
        bottom: 0,
        margin: 2
    }

});

PanelConsignmentNote.propTypes = {
    /**
     * callback executed add new user in the list
     */
     selectedCompany: propTypes.object,
    /**
     * open and closedlateral panel for add an new user in customer
     */
     onSaveButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onCloseButtonClick: propTypes.func,
};

PanelConsignmentNote.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelConsignmentNote))