import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

/** Material UI import section */
import { Fab, Tooltip, withStyles } from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

const ButtonClearFilters = (props) => {
	const { disabled, classes, onClick } = props;
	return (
		<Tooltip title={<Translate id="accreditedWorkshops.cleanfilters"/>}>
			<div>
				<Fab
					size="small"
					variant="round"
					color="primary"
					disabled={disabled}
					onClick={onClick}
					className={classes.root}
				>
					<DeleteSweepIcon />
				</Fab>
			</div>
		</Tooltip>
	);
};

ButtonClearFilters.propTypes = {
	disabled: true,
	onClick: PropTypes.func
};

ButtonClearFilters.defaultProps = {
	disabled: true,
	onClick: () => console.warn('[onClick] method from ButtonClearFilters is undefined')
};

const styles = (theme) => ({
	root: {
		height: 35,
		width: 35
	}
});

export default withStyles(styles, { withTheme: true })(ButtonClearFilters);
