import React, { Component } from 'react';

/** Material-UI imports section */
import { Translate } from "react-localize-redux";
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import EmailIcon from '@material-ui/icons/Email';
import EnabledIcon from '@material-ui/icons/ThumbUp';
import DisabledIcon from '@material-ui/icons/ThumbDown';

/**
 * User card
 *
 * @class UserCard
 * @extends {Component}
 */
class ProfileUserCard extends Component {
    render() {
        const { internalUser } = this.props;
        return (
            <CardContent>
                <List>
                    <Divider />
                    <ListItem>
                        <ListItemIcon>
                            <EmailIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary={<Translate id="users.email"/>}
                            secondary={internalUser.email} />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemIcon>
                            {internalUser.enabled ? <EnabledIcon color="secondary" /> : <DisabledIcon color="error" />}
                        </ListItemIcon>
                        <ListItemText
                            primary={<Translate id="users.userEnable"/>}
                            secondary={<span>
                                {<Translate id={internalUser.enabled ? "users.enabled" : "users.disabled"} />}
                            </span>} />
                    </ListItem>
                </List>
            </CardContent>
        );
    }
}

export default ProfileUserCard;