/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import { Translate } from 'react-localize-redux'
/**Import material UI Section*/
import {
    Grid, withStyles, TextField
} from '@material-ui/core'
/**Import components section*/

const CreateServicesComponent = (props) => {
    const CHARACTER_LIMIT_NAME = 100;
    const CHARACTER_LIMIT_DESCRIPTION = 250;
    /**Functions props section */
    const { onPropertyChange } = props
    /**Objects props section */
    const { biddingModel, classes } = props
    return (
        <>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={<Translate id="biddingsModule.services.name" />}
                    className={classes.textField}
                    name='name'
                    onChange={onPropertyChange}
                    margin="normal"
                    variant="outlined"
                    multiline
                    value={biddingModel ? biddingModel.name : ""}
                    inputProps={{ maxLength: CHARACTER_LIMIT_NAME }}
                    helperText={`${biddingModel ? biddingModel.name.length : ""}/${CHARACTER_LIMIT_NAME}`}
                />

            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={<Translate id="common.comments" />}
                    className={classes.textField}
                    onChange={onPropertyChange}
                    multiline
                    name='comments'
                    margin="normal"
                    variant="outlined"
                    value={biddingModel ? biddingModel.comments : 0}
                    inputProps={{ maxLength: CHARACTER_LIMIT_DESCRIPTION }}
                    helperText={`${biddingModel ? biddingModel.comments.length : 0}/${CHARACTER_LIMIT_DESCRIPTION}`}
                />

            </Grid>
        </>
    )
}

const styles = theme => ({

});
CreateServicesComponent.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
}

CreateServicesComponent.defaultProps = {
    isLoading: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(CreateServicesComponent))