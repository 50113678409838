/** React import section */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'
import autoBind from 'auto-bind'


/** Import Material-UI */

/** Import components section */
import userManager from '../../store/helpers/UserManager';
import ApplicationBar from './AnonymousApplicationBar'

/** Resources import section */
import '../../resources/styles/main.css'

/**
 * HOC component for wrapp a component in a layout
 *
 * @param {*} WrapperContainer
 * @returns
 */
function withAnonymousLayoutHOC(WrapperContainer) {

    return class extends React.Component {

        /**
         * Constructor
         */
        constructor(props) {
            super(props);
            autoBind(this);
        }


        /**
         * Login request
         *
         */
        onLoginRequest() {
            userManager.signinRedirect();
        }

        /**
         * Render the wrapped component with the anonymous layout
         *
         * @returns
         */
        render() {
            return (
                <div className="authenticated-container">
                    <div id="application-bar">
                        <ApplicationBar
                            {...this.props}
                            onLoginRequest={this.onLoginRequest}
                         />
                    </div>
                    <main>
                        <WrapperContainer />
                    </main>
                </div>
            );
        }
    }
}

/**
 *
 * Defines the properties injecteded from the state to App props
 *
 * @param {object} state
 * @returns
 */
function mapStateToProps(state) {
    return {
        oidc: state.oidc
    }
}

const withAnonymousLayout = compose(connect(mapStateToProps, null), withAnonymousLayoutHOC);
export default withAnonymousLayout;
  