import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { getTranslate, Translate } from 'react-localize-redux'
import moment from 'moment'

/** Import Material UI section */
import {
    Card,
    CardContent,
    Grid,
    AppBar,
    Tabs,
    Tab,
    Divider,
    Paper,
    CardHeader,
    Typography,
    Tooltip,
} from '@material-ui/core'
import ReprobateIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import ApprovedIcon from '@material-ui/icons/SentimentVerySatisfied'
import NeutralIcon from '@material-ui/icons/SentimentSatisfied'
import CalendarIcon from '@material-ui/icons/CalendarToday'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import LoadingPage from '../../common/LoadingPage'
import Toaster from '../../common/Toaster'
import LayoutHeaderWorkFlow from '../../common/LayoutHeaderWorkFlow'
import FloatingButton from '../../common/FloatingButton'
import DialogAvailableOperations from '../../common/DialogAvailableOperations'
import { showSuccessToaster, showErrorToaster } from '../../../store/helpers/ToasterHelpers'
import QuestionsList from './QuestionsList'
import GeneralInformation from './GeneralInformation'
import HistoryCard from '../../common/HistoryCard'
import DataTable from '../../common/DataTable'
import AddCommonItem from '../../common/AddCommonItem'
import CancelWorkflowDialog from '../../common/CancelWorkflowDialog'
import WorkflowCancel from '../../common/WorkflowCancel'
import CommentsComponent from './components/CommentsComponent'
import ActionsPlanTab from './ActionsPlanTab'

/** Import actions and helpers section */
import {
    SUSTAINABILITY_QUESTIONAIRE_NOTIFICAITON,
    SUSTAINABILITY_QUESTIONAIRE_RESPONSE,
    WORKFLOW_CANCELLATION,
    SUSTAINABILITY_QUESTIONNAIRE_RESEND_TO_SUPPLIER,
    SUSTAINABILITY_QUESTIONAIRE_CLOSE,
    QUESTIONNAIRE_ACCEPTED_AND_SEND_SUPPLIER,
    QUESTIONNAIRE_SUPPLIER_ADDING_ACTIONS,
    QUESTIONNAIRE_RESEND_ACTIONS_FROM_SUPPLIER,
} from '../../../store/helpers/WorkFlowDocumentsType'
import {
    COLOR_STATUS_WARNING,
    COLOR_STATUS_SUCCESS,
    COLOR_STATUS_DANGER,
    COLOR_ALTERNATE,
} from '../../../store/helpers/StatusColorConstants'
import * as roles from '../../../store/helpers/RolesHelper'
import { loadCompany } from '../../../store/companies/CompanyActions'
import { loadSupplier } from '../../../store/suppliers/SupplierActions'
import { COLOR_STATUS_INFO } from '../../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { converListToArray } from '../../../store/helpers/ListHelper'
import { Questionnaire_Generated_Actions_Plan } from '../../../store/helpers/DevelopmentHelper'
import { IsCurrentActiveDirectoryUser, IsUserSupplierAdministrator } from '../../../store/helpers/SessionHelper'
import {
    loadQuestionnaireFromBackEnd,
    updatePropertyInListSectionQuestion,
    updateProperty,
    sendQuestionnaireToClientBySupplier,
    resendQuestionnaireToSupplier,
    closeQuestionnaireInPlant,
    updateOptionMuptipleInQuestion,
    uploadFilesInSectionQuestion,
    removeFilesEvidenceInQuestion,
    uploadFilesInQuestionnaire,
    removeFilesInQuestionnaire,
    addQuestionnaireAcceptedToSupplier,
    addQuestionnireSupplierAddingActions,
    resendActionsQuestionnaireToSupplier,
    updatePropertyDeliveryDate,
    updatePropertyScheduledDate,
    onSendQuestionnaireToSuppliers,
    generateQuestionnaireActionsPlans,
} from '../../../store/questionnaires/QuestionnairesAction'
import { cancelWorflowQualityFromBackend } from '../../../store/workflows/workflowActions'
import EditScheduledDateLateralPanel from './components/EditScheduledDateLateralPanel'

class Show extends Component {
    constructor(props) {
        super(props)
        autoBind(this)
        this.state = {
            openAvailableOperationsDialog: false,
            activeTab: 0,
            showToaster: false,
            toasterMessage: null,
            toasterVariant: '',
            openResendToSuppliert: false,
            openCanceQuestionnaire: false,
            openResendActionsToSupplier: false,
            editScheduletDateConfigPanel: {
                opened: false,
                questionnaire: null,
            },
        }
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        let questionnaireToken = this.props.match.params.token
        this.props.loadQuestionnaire(questionnaireToken)
    }

    /**
     * componentDidUpdate
     * @param {*} prevProps
     * @param {*} prevState
     */
    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.isLoadingQuestionnaire !== this.props.isLoadingQuestionnaire &&
            this.props.questionnaire !== null
        ) {
            let questionnaire = this.props.questionnaire
            this.props.loadCompany(questionnaire.company_token)
            this.props.loadSupplier(questionnaire.supplier_token)
        }
    }

    /**
     * changeSelectTab
     * @param {*} index
     */
    changeSelectTab(index) {
        this.setState({ activeTab: Number(index) })
    }

    /**
     * Function to update property question
     * @param {*} sectionToken
     * @param {*} property
     * @param {*} data
     */
    updatePropertyQuestion(sectionToken, property, data, item) {
        this.props.updatePropertyInListQuestion(sectionToken, item.token, property, data)
    }

    /**
     * Function to update property Questionnaire
     * @param {*} sectionToken
     * @param {*} property
     * @param {*} data
     */
    updatePropertyQuestionnaire(property, data) {
        this.props.updateProperty(this.props.match.params.token, property, data)
    }

    /**
     * Function to update
     * @param {*} sectionToken
     * @param {*} questionToken
     * @param {*} optionToken
     * @param {*} property
     * @param {*} data
     */
    updateOptionMuptiple(sectionToken, questionToken, optionToken, property, data) {
        this.props.updateOptionMuptipleInQuestion(sectionToken, questionToken, optionToken, property, data)
    }

    /**
     * uploadFilesInQuestion
     * @param {*} sectionToken
     * @param {*} questionToken
     * @param {*} files
     */
    uploadFilesInQuestion(sectionToken, questionToken, files) {
        let questionnaireToken = this.props.match.params.token
        this.props.uploadFilesInQuestion(questionnaireToken, sectionToken, questionToken, files)
    }

    /**
     * uploadFilesInQuestion
     * @param {*} sectionToken
     * @param {*} questionToken
     * @param {*} fileName
     */
    removeFilesInQuestion(sectionToken, questionToken, fileName) {
        let questionnaireToken = this.props.match.params.token
        this.props.removeFilesInQuestion(questionnaireToken, sectionToken, questionToken, fileName)
    }

    /**
     * Render question list
     */
    renderQuestionsList() {
        /// Validar que existan secciones
        let sections = this.props.questionnaire.sections
        let sectionSelected = sections[this.state.activeTab - 1]
        let canEditSupplier =
            (roles.canBeAdminsitratorSupplier(this.props.userRolesByCompany) ||
                IsUserSupplierAdministrator(this.props.currentUser)) &&
            this.props.questionnaire.can_edit_supplier
        let canEditCommentsInPlant =
            this.props.questionnaire &&
            this.props.questionnaire.actual_operation !== 1600 /*notificacion enviada*/ &&
            this.props.questionnaire.actual_operation !== 1828 /*programada*/ &&
            this.props.questionnaire.actual_operation !== 1602 /*evaluada*/ &&
            this.props.questionnaire.actual_operation !== 21 /*cancelado*/ &&
            roles.canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany)
        let canEditCommentsInAudit =
            this.props.questionnaire &&
            this.props.questionnaire.actual_operation !== 1 &&
            roles.canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany)
        if (sectionSelected !== undefined) {
            return (
                <QuestionsList
                    sectionQuestionnaire={sectionSelected}
                    updateProperty={this.updatePropertyQuestion}
                    canEditSupplier={canEditSupplier}
                    translate={this.props.translate}
                    updateOptionMuptiple={this.updateOptionMuptiple}
                    dropzoneText={this.props.translate('dropzone.dropzoneBase')}
                    uploadFilesInQuestion={this.uploadFilesInQuestion}
                    removeFilesInQuestion={this.removeFilesInQuestion}
                    canEditCommentsInPlant={canEditCommentsInPlant}
                    canEditCommentsInAudit={canEditCommentsInAudit}
                    isActiveInternalUser={IsCurrentActiveDirectoryUser(this.props.currentUser)}
                />
            )
        } else {
            return 'Secciones '
        }
    }

    /**
     * setOpenDialog
     */
    setOpenDialog = (openAvailable) => {
        this.setState({
            openAvailableOperationsDialog: openAvailable,
        })
    }

    /**
     * function to send questionare to client by supplier
     */
    onSendQuestionnaireToClientBySupplier() {
        let questionnaireToken = this.props.match.params.token
        this.props
            .sendQuestionnaireToClientBySupplier(questionnaireToken)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                })
                this.setState(showSuccessToaster('common.saveCorrectly'))
            })
            .catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
            })
    }

    /**
     * Function to resend to supplier
     */
    onResendBySupplierOpen() {
        this.setState({
            openAvailableOperationsDialog: false,
            openResendToSuppliert: true,
        })
    }

    /**
     * resendt questionnare
     * @param {*} commentsResend
     */
    resendQuestionnaireToSupplier(commentsResend) {
        let questionnaireToken = this.props.match.params.token
        this.props
            .resendQuestionnaireToSupplier(questionnaireToken, commentsResend)
            .then(() => {
                this.setState({
                    openResendToSuppliert: false,
                })
                this.setState(showSuccessToaster('common.saveCorrectly'))
            })
            .catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
            })
    }

    /**
     * Function to close questionnaire
     */
    closeQuestionnaireFromPlant() {
        let questionnaireToken = this.props.match.params.token
        this.props
            .closeQuestionnaireInPlant(questionnaireToken)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                })
                this.setState(showSuccessToaster('common.saveCorrectly'))
            })
            .catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
            })
    }

    /**
     * Function to init process to generated action plans
     */
    generateQuestionnaireActionsPlan() {
        let questionnaireToken = this.props.match.params.token
        this.props
            .generateQuestionnaireActionsPlans(questionnaireToken)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                })
                this.setState(showSuccessToaster('common.saveCorrectly'))
            })
            .catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
            })
    }

    /**
     * Function to accepted and send actions to supplier
     */
    acceptedAndSendActionsToSupplier() {
        let questionnaireToken = this.props.match.params.token
        this.props
            .addQuestionnaireAcceptedToSupplier(questionnaireToken)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                })
                this.setState(showSuccessToaster('common.saveCorrectly'))
            })
            .catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
            })
    }

    /**
     * Function to add actions from client
     */
    onAddQuestionnireSupplierAddingActions() {
        let questionnaireToken = this.props.match.params.token
        this.props
            .addQuestionnireSupplierAddingActions(questionnaireToken)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                })
                this.setState(showSuccessToaster('common.saveCorrectly'))
            })
            .catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
            })
    }

    onSendQuestionnaireToSuppliers() {
        let questionnaireToken = this.props.match.params.token
        this.props
            .onSendQuestionnaireToSuppliers(questionnaireToken)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                })
                this.setState(showSuccessToaster('common.saveCorrectly'))
            })
            .catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
            })
    }

    /**
     * Function to resend to supplier
     */
    onResendActionsBySupplierOpen() {
        this.setState({
            openAvailableOperationsDialog: false,
            openResendActionsToSupplier: true,
        })
    }

    /**
     * resendt questionnare
     * @param {*} commentsResend
     */
    resendActionsQuestionnaireToSupplier(commentsResend) {
        let questionnaireToken = this.props.match.params.token
        this.props
            .resendActionsQuestionnaireToSupplier(questionnaireToken, commentsResend)
            .then(() => {
                this.setState({
                    openResendActionsToSupplier: false,
                })
                this.setState(showSuccessToaster('common.saveCorrectly'))
            })
            .catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
            })
    }

    /**
     * Function to cancel questionnaire
     */
    onCancelDocumentOpen() {
        this.setState({
            openAvailableOperationsDialog: false,
            openCanceQuestionnaire: true,
        })
    }

    /**
     * resendt questionnare
     * @param {*} commentsResend
     */
    cancelQuestionnaireInPlant(cancelComment, razonForCancel) {
        let questionnaireToken = this.props.match.params.token
        this.props
            .cancelWorflowQualityFromBackend(questionnaireToken, cancelComment, razonForCancel)
            .then(() => {
                this.props.loadQuestionnaire(questionnaireToken)
                this.setState({ openCanceQuestionnaire: false })
                this.setState(showSuccessToaster('common.saveCorrectly'))
            })
            .catch(() => {
                this.setState(showErrorToaster('common.errorToSave'))
            })
    }

    /**
     * renderAditionalDataNotification
     * @param {*} usersList
     * @param {*} comments
     */
    renderAditionalDataNotification(usersList, comments) {
        return (
            <CardHeader
                style={{ paddingTop: '0px' }}
                title={
                    <Typography gutterBottom component='p'>
                        {comments}
                    </Typography>
                }
                subheader={
                    <React.Fragment>
                        <Typography gutterBottom variant='body2' component='p'>
                            <strong>
                                <Translate id='common.sentTo' />:
                            </strong>
                        </Typography>
                        <DataTable
                            data={usersList}
                            configuration={{
                                columns: [
                                    {
                                        header: <Translate id='officialNotices.name' />,
                                        dataSource: 'full_name',
                                    },
                                ],
                            }}
                        />
                    </React.Fragment>
                }
            />
        )
    }

    /**
     * renderHistory
     */
    renderHistory() {
        let documents = this.props.questionnaire
            ? this.props.questionnaire.documents
                ? this.props.questionnaire.documents
                : []
            : []
        return documents.map((document) => {
            let titleId
            let aditionalData
            if (document.document_type === SUSTAINABILITY_QUESTIONAIRE_NOTIFICAITON) {
                titleId = 'sustainability.notificationBySupplierReport'
                aditionalData = this.renderAditionalDataNotification(document.users_supplier, '')
            }
            if (document.document_type === SUSTAINABILITY_QUESTIONAIRE_RESPONSE) {
                titleId = 'sustainability.responseBySupplierReport'
            }
            if (document.document_type === SUSTAINABILITY_QUESTIONNAIRE_RESEND_TO_SUPPLIER) {
                titleId = 'sustainability.notificationBySupplierResendReport'
                aditionalData = this.renderAditionalDataNotification(document.users_supplier, document.comments)
            }
            if (document.document_type === QUESTIONNAIRE_ACCEPTED_AND_SEND_SUPPLIER) {
                titleId = 'statusDescriptions.questionnaireAcceptAndSendToSupplier'
                aditionalData = this.renderAditionalDataNotification(document.users_supplier, '')
            }
            if (document.document_type === QUESTIONNAIRE_SUPPLIER_ADDING_ACTIONS) {
                titleId = 'statusDescriptions.questionnireSupplierAddingActions'
            }
            if (document.document_type === QUESTIONNAIRE_RESEND_ACTIONS_FROM_SUPPLIER) {
                titleId = 'statusDescriptions.questionnireResendActionsToSupplier'
                aditionalData = this.renderAditionalDataNotification(document.users_supplier, document.comments)
            }
            if (document.document_type === SUSTAINABILITY_QUESTIONAIRE_CLOSE) {
                titleId = 'sustainability.closeReport'
            }
            if (document.document_type === WORKFLOW_CANCELLATION) {
                titleId = 'common.folioCancel'
                aditionalData = <WorkflowCancel document={document} />
            }
            return (
                <HistoryCard
                    key={document.token}
                    title={titleId}
                    userCreator={document.create_by}
                    creationDate={document.creation_date_long}
                    aditionalData={aditionalData}
                />
            )
        })
    }

    /**
     * renderHistory
     */
    renderHistoryScheduledDates() {
        let scheduleDatesHistory = this.props.questionnaire
            ? this.props.questionnaire.schedule_date_historys
                ? this.props.questionnaire.schedule_date_historys
                : []
            : []
        return scheduleDatesHistory.map((history) => {
            return (
                <HistoryCard
                    key={history.token}
                    title={'common.schedulateDate'}
                    userCreator={history.create_by}
                    creationDate={moment(history.creation_date).format('LLLL')}
                    aditionalData={
                        <div className={this.props.classes.paddingCommentsDiv}>
                            <Typography gutterBottom variant='body2' component='p'>
                                <Tooltip title={<Translate id='questionnaire.scheduledDate' />}>
                                    <CalendarIcon color='secondary' />
                                </Tooltip>{' '}
                                {' ' + moment(history.scheduled_date).format('LLLL')}
                            </Typography>

                            <CommentsComponent
                                title={<Translate id='common.comments' />}
                                comments={history.comments}
                                isVisibleHtmlEditor
                            />
                        </div>
                    }
                />
            )
        })
    }

    getStatusData(status) {
        switch (status) {
            case 'A':
                return {
                    color: COLOR_STATUS_SUCCESS,
                    icon: <ApprovedIcon style={{ color: COLOR_STATUS_SUCCESS, fontSize: 30, marginTop: '-3px' }} />,
                }
            case 'B':
                return {
                    color: COLOR_STATUS_WARNING,
                    icon: <NeutralIcon style={{ color: COLOR_STATUS_WARNING, fontSize: 30, marginTop: '-3px' }} />,
                }
            default:
                return {
                    color: COLOR_STATUS_DANGER,
                    icon: <ReprobateIcon style={{ color: COLOR_STATUS_DANGER, fontSize: 30, marginTop: '-3px' }} />,
                }
        }
    }

    onEditScheduledDatePanelConfig() {
        this.setState({
            editScheduletDateConfigPanel: {
                opened: !this.state.editScheduletDateConfigPanel.opened,
                questionnaire: this.props.questionnaire,
            },
        })
    }

    onUpdateScheduledDate(token, questionnaireModel) {
        this.props.updatePropertyScheduledDate(token, questionnaireModel).then((result) => {
            this.setState({
                editScheduletDateConfigPanel: { opened: false, questionnaire: null },
            })
        })
    }

    /**
     * Render
     */
    renderContent() {
        var { questionnaire } = this.props
        let canEditCompany =
            roles.canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany) &&
            questionnaire &&
            questionnaire.can_edit_company
        let canEditSupplier =
            (roles.canBeAdminsitratorSupplier(this.props.userRolesByCompany) ||
                IsUserSupplierAdministrator(this.props.currentUser)) &&
            this.props.questionnaire &&
            this.props.questionnaire.can_be_add_actions
        let statusDataSuppliers = this.getStatusData(questionnaire && questionnaire.classification_supplier)
        let statusDataAudit = this.getStatusData(questionnaire && questionnaire.classification_audit)
        let canEditScheduletDate =
            questionnaire &&
            questionnaire.can_be_edit_scheduled_date &&
            IsCurrentActiveDirectoryUser(this.props.currentUser)
        let tabActionPlan = questionnaire && questionnaire.sections.length + 1
        return (
            <CardContent className={this.props.classes.withoutPaddingTopBottom}>
                {this.props.isLoadingQuestionnaire ? (
                    <LoadingPage />
                ) : (
                    <Grid container spacing={0}>
                        <Grid container fullwidth>
                            {((questionnaire.rating_supplier_total !== 0 && roles.canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany))
                                || questionnaire.is_closed) && (
                                    <Grid item xs={6}>
                                        <Typography
                                            component='span'
                                            variant='h6'
                                            className={this.props.classes.inline}
                                            color='textPrimary'
                                        >
                                            <Translate id='questionnaire.classificationSupplier' />
                                            {': '}
                                            {statusDataSuppliers.icon}
                                            <strong style={{ color: statusDataSuppliers.color }}>
                                                {questionnaire.classification_supplier}{' '}
                                                {` ${questionnaire.rating_supplier_total} % `}
                                            </strong>
                                        </Typography>
                                    </Grid>
                                )}
                            {questionnaire.rating_audit_total !== 0 &&
                                (roles.canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany) || questionnaire.is_closed) && (
                                    <Grid item xs={6}>
                                        <Typography
                                            component='span'
                                            variant='h6'
                                            className={this.props.classes.inline}
                                            color='textPrimary'
                                        >
                                            {'  '}
                                            <Translate id='questionnaire.classificationAudit' />
                                            {': '}
                                            {statusDataAudit.icon}
                                            <strong style={{ color: statusDataAudit.color }}>
                                                {questionnaire.classification_audit}{' '}
                                                {` ${questionnaire.rating_audit_total} % `}
                                            </strong>
                                        </Typography>
                                    </Grid>
                                )}
                        </Grid>

                        <Grid item xs={12}>
                            <AppBar position='static' color='default' style={{ marginTop: '1rem' }}>
                                <Tabs
                                    indicatorColor='primary'
                                    textColor='textPrimary'
                                    value={this.state.activeTab}
                                    onChange={this.onClickToActiveTab}
                                    variant='fullWidth'
                                    scrollButtons='auto'
                                >
                                    <Tab
                                        id={0}
                                        label={<Translate id='suppliers.generalInformation' />}
                                        onClick={(event) => this.changeSelectTab(event.currentTarget.id)}
                                        style={{ backgroundColor: COLOR_STATUS_INFO, color: '#fff' }}
                                        classes={{
                                            root: this.props.classes.tabs,
                                            labelWrapped: this.props.classes.labelWrapped,
                                        }}
                                    />
                                    {questionnaire.sections.map((questionnaireSection, index) => {
                                        return (
                                            <Tab
                                                id={index + 1}
                                                key={questionnaireSection.token}
                                                title={questionnaireSection.short_name}
                                                label={questionnaireSection.short_name}
                                                onClick={(event) => this.changeSelectTab(event.currentTarget.id)}
                                                style={{
                                                    backgroundColor: questionnaireSection.color_section,
                                                    color: '#fff',
                                                }}
                                                classes={{
                                                    root: this.props.classes.tabs,
                                                    labelWrapped: this.props.classes.labelWrapped,
                                                }}
                                            />
                                        )
                                    })}
                                    {questionnaire &&
                                        (questionnaire.actions_plant.length !== 0 ||
                                            questionnaire.actual_operation ===
                                            Questionnaire_Generated_Actions_Plan) && (
                                            <Tab
                                                id={tabActionPlan}
                                                label={<Translate id='questionnaire.actionsPlan' />}
                                                onClick={(event) => this.changeSelectTab(event.currentTarget.id)}
                                                style={{ backgroundColor: COLOR_ALTERNATE, color: '#fff' }}
                                                classes={{
                                                    root: this.props.classes.tabs,
                                                    labelWrapped: this.props.classes.labelWrapped,
                                                }}
                                            />
                                        )}
                                </Tabs>
                            </AppBar>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                            <Paper className={this.props.classes.withoutPaddingTopBottom}>
                                {this.state.activeTab === 0 && (
                                    <GeneralInformation
                                        canEditScheduletDate={canEditScheduletDate}
                                        questionnaire={this.props.questionnaire}
                                        translate={this.props.translate}
                                        canEditSupplier={canEditSupplier}
                                        updateProperty={this.updatePropertyQuestionnaire}
                                        dropzoneText={this.props.translate('dropzone.dropzoneBase')}
                                        uploadFilesInQuestionnaire={this.props.uploadFilesInQuestionnaire}
                                        removeFilesInQuestionnaire={this.props.removeFilesInQuestionnaire}
                                        updatePropertyDeliveryDate={this.props.updatePropertyDeliveryDate}
                                        updatePropertyScheduledDate={this.props.updatePropertyScheduledDate}
                                        onEditScheduledDatePanelConfig={this.onEditScheduledDatePanelConfig}
                                    />
                                )}
                                {this.state.activeTab > 0 &&
                                    this.state.activeTab < tabActionPlan &&
                                    this.renderQuestionsList()}

                                {this.state.activeTab === tabActionPlan && (
                                    <ActionsPlanTab
                                        questionnaire={this.props.questionnaire}
                                        canEditSupplier={canEditSupplier}
                                        canEditCompany={canEditCompany}
                                        translate={this.props.translate}
                                        updateProperty={this.updatePropertyQuestionnaire}
                                        updatePropertyDeliveryDate={this.props.updatePropertyDeliveryDate}
                                        isUserIntarnal={IsCurrentActiveDirectoryUser(this.props.currentUser)}
                                    />
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                )}
                <br />
                <br />
            </CardContent>
        )
    }

    render() {
        var { questionnaire, supplier, company } = this.props
        let availableOptions = this.props.questionnaire ? this.props.questionnaire.get_available_operations : []
        let showHistory = this.props.questionnaire && this.state.activeTab === 0
        return (
            <Card>
                {availableOptions && availableOptions.length !== 0 && <FloatingButton open={this.setOpenDialog} />}
                <LayoutHeaderWorkFlow
                    title={<Translate id='questionnaire.questionnaire' />}
                    isSustentability={this.props.questionnaire ? questionnaire.questionnaire_type === 1 : false}
                    isQuestionnaireSGC={this.props.questionnaire ? questionnaire.questionnaire_type === 2 : false}
                    workFlow={questionnaire}
                    supplier={supplier}
                    company={company}
                />

                <DialogAvailableOperations
                    {...this.props}
                    options={availableOptions}
                    isValidToCancel={roles.canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany)}
                    openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
                    setOpenDialog={this.setOpenDialog}
                    onCancelDocument={this.onCancelDocumentOpen}
                    onSendToQuestionnaireBySupplier={this.onSendQuestionnaireToClientBySupplier}
                    onResendQuestionnaireToSupplier={this.onResendBySupplierOpen}
                    onGenerateQuestionnaireTaingByAudit={this.onGenerateRatingByAudit}
                    onCloseQuestionnaireInPlant={this.closeQuestionnaireFromPlant}
                    onGenerateQuestionnaireActionsPlan={this.generateQuestionnaireActionsPlan}
                    onAddQuestionnaireAcceptedToSupplier={this.acceptedAndSendActionsToSupplier}
                    onAddQuestionnireSupplierAddingActions={this.onAddQuestionnireSupplierAddingActions}
                    onResendQuestionnanreWithActionsToSupplier={this.onResendActionsBySupplierOpen}
                    onSendQuestionnaireToSuppliers={this.onSendQuestionnaireToSuppliers}
                />

                {this.renderContent()}
                {showHistory && this.renderHistory()}
                {showHistory && this.renderHistoryScheduledDates()}

                {/** Dialog to resend Questionnaire from supplier */}
                <AddCommonItem
                    title={<Translate id='sustainability.resendQuestionnaire' />}
                    message={<Translate id='sustainability.messageToResendQuestionnaire' />}
                    open={this.state.openResendToSuppliert}
                    onClose={() => {
                        this.setState({ openResendToSuppliert: false })
                    }}
                    onSaveItem={this.resendQuestionnaireToSupplier}
                    isSaving={this.props.isSaving}
                    isTextArea={true}
                />

                {/** Dialog to cancel questionnaire */}
                <CancelWorkflowDialog
                    open={this.state.openCanceQuestionnaire}
                    onClose={() => {
                        this.setState({ openCanceQuestionnaire: false })
                    }}
                    onSaveItem={this.cancelQuestionnaireInPlant}
                    isSaving={this.props.isCancel}
                />

                {/** Dialog to resend actions Questionnaire from supplier */}
                <AddCommonItem
                    title={<Translate id='questionnaire.resendActionsQuestionnaire' />}
                    message={<Translate id='questionnaire.messageToActionsResendQuestionnaire' />}
                    open={this.state.openResendActionsToSupplier}
                    onClose={() => {
                        this.setState({ openResendActionsToSupplier: false })
                    }}
                    onSaveItem={this.resendActionsQuestionnaireToSupplier}
                    isSaving={this.props.isSaving}
                    isTextArea={true}
                />
                {this.state.editScheduletDateConfigPanel && this.state.editScheduletDateConfigPanel.opened && (
                    <EditScheduledDateLateralPanel
                        questionnaire={this.state.editScheduletDateConfigPanel.questionnaire}
                        onCloseButtonClick={this.onEditScheduledDatePanelConfig}
                        onSaveButtonClick={this.onUpdateScheduledDate}
                        isSaving={this.props.isSaving}
                    />
                )}

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => {
                        this.setState({ showToaster: false })
                    }}
                />
            </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
    let userRolesByCompany = state.profile.get('userRolesByCompany')
    let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
    return {
        currentUser: user,
        isLoadingQuestionnaire: state.questionnaires.get('isLoadingQuestionnaire'),
        questionnaire: state.questionnaires.get('questionnaire')
            ? state.questionnaires.get('questionnaire').toJS()
            : null,
        company: state.companies.get('company') ? state.companies.get('company').toJS() : null,
        supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
        isSaving: state.questionnaires.get('isSavingQuestionnaire'),
        isCancel: state.workflow.get('isSaving'),
    }
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        loadCompany: (token) => {
            return dispatch(loadCompany(token))
        },
        loadSupplier: (token) => {
            return dispatch(loadSupplier(token))
        },
        loadQuestionnaire: (token) => {
            return dispatch(loadQuestionnaireFromBackEnd(token))
        },
        updatePropertyInListQuestion: (sectionToken, questionToken, property, propertyData) => {
            return dispatch(updatePropertyInListSectionQuestion(sectionToken, questionToken, property, propertyData))
        },
        sendQuestionnaireToClientBySupplier: (questonnaireToken) => {
            return dispatch(sendQuestionnaireToClientBySupplier(questonnaireToken))
        },
        resendQuestionnaireToSupplier: (questionnaireToken, commentsResend) => {
            return dispatch(resendQuestionnaireToSupplier(questionnaireToken, commentsResend))
        },
        updateProperty: (questionnaireToken, property, propertyData) => {
            return dispatch(updateProperty(questionnaireToken, property, propertyData))
        },
        updatePropertyDeliveryDate: (questionnaireToken, property, deliveryDate) => {
            return dispatch(updatePropertyDeliveryDate(questionnaireToken, property, deliveryDate))
        },
        updatePropertyScheduledDate: (questionnaireToken, questionnaireModel) => {
            return dispatch(updatePropertyScheduledDate(questionnaireToken, questionnaireModel))
        },
        closeQuestionnaireInPlant: (questionnaireToken) => {
            return dispatch(closeQuestionnaireInPlant(questionnaireToken))
        },
        updateOptionMuptipleInQuestion: (sectionToken, questionToken, optionToken, property, data) => {
            return dispatch(updateOptionMuptipleInQuestion(sectionToken, questionToken, optionToken, property, data))
        },
        cancelWorflowQualityFromBackend: (token, cancelComment, razonForCancel) => {
            return dispatch(cancelWorflowQualityFromBackend(token, cancelComment, razonForCancel))
        },
        uploadFilesInQuestion: (token, sectionToken, questionToken, files) => {
            return dispatch(uploadFilesInSectionQuestion(token, sectionToken, questionToken, files))
        },
        removeFilesInQuestion: (token, sectionToken, questionToken, fileName) => {
            return dispatch(removeFilesEvidenceInQuestion(token, sectionToken, questionToken, fileName))
        },
        uploadFilesInQuestionnaire: (token, isInternal, files) => {
            return dispatch(uploadFilesInQuestionnaire(token, isInternal, files))
        },
        removeFilesInQuestionnaire: (token, isInternal, fileName) => {
            return dispatch(removeFilesInQuestionnaire(token, isInternal, fileName))
        },
        addQuestionnaireAcceptedToSupplier: (token) => {
            return dispatch(addQuestionnaireAcceptedToSupplier(token))
        },
        addQuestionnireSupplierAddingActions: (token) => {
            return dispatch(addQuestionnireSupplierAddingActions(token))
        },
        resendActionsQuestionnaireToSupplier: (questionnaireToken, commentsResend) => {
            return dispatch(resendActionsQuestionnaireToSupplier(questionnaireToken, commentsResend))
        },
        onSendQuestionnaireToSuppliers: (token) => {
            return dispatch(onSendQuestionnaireToSuppliers(token))
        },
        generateQuestionnaireActionsPlans: (token) => {
            return dispatch(generateQuestionnaireActionsPlans(token))
        },
    }
}

const styles = (theme) => ({
    tabs: {
        maxHeight: 40,
        minHeight: 35,
    },
    withoutPaddingTopBottom: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    paddingCommentsDiv: {
        marginTop: '8px',
    },
    labelWrapped: {
        fontSize: '0.7rem !important',
    },
})

export default withauthenticatedLayout(
    withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
