/**Import react section */
import React, { useState } from 'react'
import Tour from 'reactour'
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import { Button } from '@material-ui/core';
const accentColor = "#5cb7b7";
const ReactTour = (props) => {
    /**Arrays props section */
    const { steps } = props
    const [isTourOpen, setIsTourOpen] = useState(false);
    const onOpenTour = ()=>{
        setIsTourOpen(true)
        if(props.setIsVisibleTour){
            props.setIsVisibleTour(true)
        }
    }

    const onCloseTour = ()=>{
        setIsTourOpen(false)
        if(props.setIsVisibleTour){
            props.setIsVisibleTour(false)
        }
    }

    return (
        <>
            <Button color="primary" onClick={()=>onOpenTour()}>Ayuda</Button>

            { /* other stuff */}
            <Tour
                getCurrentStep={curr => console.log(`The current step is ${curr + 1}`)}
                steps={steps}
                isOpen={isTourOpen}
                rounded={5}
                accentColor={accentColor}
                onRequestClose={() => onCloseTour()}
                disableFocusLock
                disableInteraction
                // disableFocusLock
            />
        </>
    )
};

ReactTour.propTypes = {
    steps: PropTypes.array.isRequired
};

ReactTour.defaultProps = {
    steps: []
};

export default withRouter(ReactTour)
