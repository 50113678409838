/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/**Import MaterialUi section */
import {
    Grid, Button, Avatar, Typography, IconButton
} from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload';
import FileIcon from '@material-ui/icons/AttachFile';
/**Import components section */
import LateralPanel from '../LateralPanelComponents/LateralPanel';
import PanelHeader from '../LateralPanelComponents/PanelHeader'
import ListAttachments from './ListAttachments'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';
import Evidence from '../Evidence'

const AddLateralPanel = (props) => {

    /**props section */
    const { onSaveButtonClick,
        onCloseButtonClick,
        isSaving, classes,
        acceptedFiles,
        onDeleteAttachment,
        allAttachments,
        maxFiles,
        title,
        isVisibleTemplate,
        titleTemplate,
        templateFile,
        subtitle,
        onDownloadTemplateFromBackend
    } = props
    const [attachments, setAttachments] = useState([])

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(attachments)
        }
    }

    const onDelete = (attachment) => {
        if (props.onDeleteAttachment) {
            onDeleteAttachment(attachment)
        }
    }


    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (attachments.length === 0) return false
        return true;
    }

    const uploadFiles = (files) => {
        let filesList = attachments.concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
        }
        setAttachments(filesList)
        return Promise.resolve('Ok')
    }


    /**
 * Handle event remove files of evidence componet to remove files of list
 *
 * @param {*} fileName
 * @returns
 * @memberof ModalAddMasivaMaterials
 */
    const removeFiles = (fileName) => {
        let newList = attachments.filter(files => {
            return files.name !== fileName
        })
        setAttachments(newList)
        return Promise.resolve('Ok')
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={title}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><FileIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >
                        {
                            subtitle && <Grid item xs={12}>
                                <Typography color='primary'>{subtitle}</Typography><br />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Typography color='primary'><Translate id="common.selectfiles" /></Typography>
                            <Evidence
                                text={<Translate id="common.selectfiles" />}
                                uploadFiles={uploadFiles}
                                files={attachments}
                                removeFiles={removeFiles}
                                acceptedFiles={acceptedFiles}
                                maxFiles={maxFiles}
                            />

                        </Grid>
                        {
                            isVisibleTemplate && <Grid item xs={12} style={{ marginTop: '1rem' }}>
                                <small className="text-muted">
                                    <DownloadIcon fontSize="small" color="secondary" style={{ margin: '5px', marginBottom: '8px' }} />
                                    {<a href={templateFile}>{titleTemplate}</a>}

                                </small>
                            </Grid>
                        }

                        {
                            onDownloadTemplateFromBackend &&
                            <Grid item xs={12} style={{ marginTop: '1rem', display:"flex" }}>
                                <Typography variant='subtitle2' color="primary">{titleTemplate}</Typography>
                                    <IconButton onClick={onDownloadTemplateFromBackend} disabled={isSaving} style={{marginTop:"-15px"}}>
                                        <DownloadIcon fontSize="small" color="secondary" style={{ margin: '5px', marginBottom: '8px' }} />
                                    </IconButton>
                            </Grid>
                        }
                        {
                            props.children && <Grid item xs={12}>
                                {props.children}
                            </Grid>
                        }
                        {
                            allAttachments.length !== 0 && <Grid item xs={12}>
                                <ListAttachments
                                    baseApi={process.env.REACT_APP_SIP_URL_FILES}
                                    onDelete={onDelete}
                                    attachments={allAttachments}

                                />
                            </Grid>
                        }

                    </Grid>

                </div>
                <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            disabled={!canBeSaved() || isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    }


});

AddLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onValidateTemplate: propTypes.func,
    /**All attachments saved */
    allAttachments: propTypes.array,
    /**
     * Determinate the size accept files
     */
    maxFiles: propTypes.number.isRequired,
    titleTemplate: propTypes.string,
    isVisibleTemplate: propTypes.bool
}

AddLateralPanel.defaultProps = {
    isVisibleTemplate: false,
    title: "",
    titleTemplate: "",
    allAttachments: [],
    maxFiles: 1,
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onValidateTemplate: () =>
        console.warn('Callback [onValidateTemplate] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddLateralPanel))