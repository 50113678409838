/** React imports section */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
/** Material-UI imports section */
import { Card, CardContent } from '@material-ui/core'
/** Component imports section */
import withauthenticated from '../../layouts/withauthenticatedLayout'
import LoadingPage from '../../common/LoadingPage'
import PackageLayouteHeader from './components/PackageLayoutHeaderDetail'
import PackageDetailContent from './components/PackageDetailContent'
import ConfirmationChangeModal from './components/ConfirmationChangeModal'
/** Selector imports section */
import * as selector from '../../../store/specifications/specifications.Selector'
/** Action imports section */
import {
	addItemtoList,
	getPackageDetail,
	updateItemtoList,
	updateVisualAidtoList,
	submitAuthorize,
	deleteItemtoList,
} from '../../../store/specifications/specifications.Action'

const PackageSpecificationDetail = (props) => {
	const dispatch = useDispatch()

	const isLoading = useSelector((state) => selector.getLoadingSelector(state))
	const data = useSelector((state) => selector.getPackageDataSelector(state))
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [isLoadingAuthorize, setIsLoadingAuthorize] = useState(false)
	/** Get user Information*/
	const userProfile = useSelector((state) => state.oidc.user)
	/** Get the information if you are an internal user*/
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')

	const onAddActivity = () => {
		let token = data.token
		let numberList = data.activity_sequences.length + 1
		dispatch(addItemtoList(token, numberList))
			.then(() => {
				dispatch(getPackageDetail(props.match.params.token))
			})
			.catch((error) => {
				console.log('An error ocurred to add item', error)
			})
	}

	const onDeleteActivity = (number) => {
		let token = data.token
		dispatch(deleteItemtoList(token, number))
			.then(() => {
				dispatch(getPackageDetail(token))
			})
			.catch((error) => {
				console.error('An errror ocurred to delete item', error)
			})
	}

	const onUpdateActivity = (number, value) => {
		let token = data.token
		dispatch(updateItemtoList(token, number, value))
			.then(() => {
				dispatch(getPackageDetail(token))
			})
			.catch(() => {
				console.error('error al actualizar')
			})
	}

	const onUpdateVisualAidActivity = (number, value) => {
		let token = data.token
		console.log('vaslue', value)
		dispatch(updateVisualAidtoList(token, number, value))
			.then(() => {
				dispatch(getPackageDetail(token))
			})
			.catch(() => {
				console.error('Error al actualizar')
			})
	}

	const onSumbitAuthorize = () => {
		setIsLoadingAuthorize(true)
		dispatch(submitAuthorize(data.token))
			.then(() => {
				setOpenConfirmModal(false)
				setIsLoadingAuthorize(false)
				dispatch(getPackageDetail(data.token))
			})
			.catch((error) => {
				console.error('Error al enviar a autorizar')
				setIsLoadingAuthorize(false)
			})
	}

	const handleOpenConfirmModal = () => {
		setOpenConfirmModal(true)
	}

	useEffect(() => {
		dispatch(getPackageDetail(props.match.params.token))
	}, [dispatch, props.match.params.token])

	if (isLoading === true) {
		return <LoadingPage />
	}

	return (
		<>
			<Card>
				<PackageLayouteHeader
					{...data}
					submitAuthorize={handleOpenConfirmModal}
					isInternalUser={isInternalUser()}
					totalActivities={
						data.activity_sequences !== null && data.activity_sequences !== undefined
							? data.activity_sequences.length
							: 0
					}
				/>
				<CardContent>
					<PackageDetailContent
						isLoading={isLoading}
						controlChanges={data.control_changes}
						activities={data.activity_sequences}
						authrizations={data.authorizations}
						isInternalUser={isInternalUser()}
						addActivityItem={onAddActivity}
						updateItem={onUpdateActivity}
						updateVisualAid={onUpdateVisualAidActivity}
						status={data.document_status}
						deleteActivity={onDeleteActivity}
					/>
				</CardContent>
			</Card>
			{openConfirmModal && (
				<ConfirmationChangeModal
					open={openConfirmModal}
					onClose={() => setOpenConfirmModal(false)}
					onActionClick={onSumbitAuthorize}
					isLoading={isLoadingAuthorize}
					folio={data.folio}
				/>
			)}
		</>
	)
}

export default withauthenticated(withRouter(PackageSpecificationDetail))
