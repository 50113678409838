///Import react section
import { useEffect } from 'react';
import { useSelector, useDispatch, } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { getTranslate } from "react-localize-redux";

/**Import actions sections */
import {
    setToasterOptions
} from '../../../store/indirectqualifications/IndirectqualificationsActions'
import {
    loadAllBillingsFromBackEnd,
    addBilling,
    getBillingCount
} from '../../../store/billings/BillingsActions'
/**Import helpers sections */
import { converListToArray } from '../../../store/helpers/ListHelper'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';
import { ADD_DUPLICATED_BIDDING_NAME_MESSAGE } from '../../../store/helpers/MessagesError'

export const useBillingsCustomHookIndex = () => {
    /**use selector information */
    const billings = useSelector((state) => state.billings.get('billings') ? converListToArray(state.billings.get('billings')) : [])
    const translate = useSelector((state) => getTranslate(state.localize))

    /**Render hooks section */
    const dispatch = useDispatch()
    let location = useLocation()

    /**Use effect section */
    useEffect(() => {
        const queryParams = queryString.parse(location.search)
        dispatch(loadAllBillingsFromBackEnd(queryParams, translate))
        dispatch(getBillingCount())
    }, [dispatch, location.search])

    const onAddBilling = (model) => {
        return dispatch(addBilling(model, translate))
            .then(() => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: "common.addCorrectly",
                }))
            })
            .catch((response) => {
                dispatch(setToasterOptions({
                    showToaster: true,
                    toasterMessage: (response && !IsNullOrEmpty(response.data) && typeof response.data === 'string' && response.data.includes(ADD_DUPLICATED_BIDDING_NAME_MESSAGE)) ? "biddingsModule.errorMessages.addBiddingError" : "common.errorToSave",
                    toasterVariant: "error",
                    name: model.name
                }))
            })
    }

    return { onAddBilling, billings }
}