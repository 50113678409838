
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import uuidv4 from 'uuid/v4'

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Tooltip from "@material-ui/core/Tooltip";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemSecondaryAction } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ReactiveRMIIcon from '@material-ui/icons/Autorenew';
import Divider from '@material-ui/core/Divider';
import ActionsIcon from '@material-ui/icons/PlaylistAddCheck';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/AddTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import AcceptIcon from '@material-ui/icons/ThumbUp';
import RejectIcon from '@material-ui/icons/ThumbDown';
import { Switch} from '@material-ui/core';

/** import Components */
import AddReferenceDialog from './AddReferenceDialog';
import Toaster from '../../common/Toaster';
import Dropzone from '../../common/Dropzone';
import OptionsMenu from './../../common/OptionsMenu';
import AddCommonItem from '../../common/AddCommonItem';
import DataTable from '../../common/DataTable';

/** import Helpers */
import { IsCurrentActiveDirectoryUser, IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper';
import { APPROVAL_ACEPTED, APPROVAL_REJECTED, COMPANY_FERSA } from '../../../store/helpers/ResourcesHelper';
import { loadSupplier } from '../../../store/suppliers/SupplierActions';
import { addAnnotationToRMIDocument, removeAnnotationToRMIDocument, updateInspectionByClient,
    updateAnnotationToRMIDocument, updateDocumentRMi, acceptedOrRejectedAnnotationByClient,
    uploadFilesBySupplier, removeFilesBySupplier, uploadFilesByCompany, removeFilesByCompany,
    downloadSampleRmiReportPdf, updateDocumentRMiByEngineerObservations,
    acceptRejectAnnotationsRMIFromQuality, reactiveRmiClosed, 
    updateSampleRequest} from '../../../store/samplerequests/SampleRequestAction';
import { canBeAddArriveSampleRequest, canBeValidateModuleDevelopment } from '../../../store/helpers/RolesHelper';
import HtmlEditor from '../../common/HtmlEditor';
import AcceptedRejectedAnnRmi from './AcceptedRejectedAnnRmi';

/** Files import section */
import ThemplateRMITorrey from '../../../resources/files/FO-CA-GE-0001.xlsx'

/**
 * Container to get rim document 
 */
class RMIDocument extends Component {

    /**
     * Create an instance of rmi document
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            openAddReference: false,
            instruments: "",
            observationsSupplier: "",
            observationsEngineer: "",
            referenceToAdd: {
                reference: "",
                specification: "",
                tolerance: "",
                unitMeasure: "",
                piece1: "",
                piece2: "",
                piece3: "",
                piece4: "",
                piece5: "",
                keepAdding: false
            },
            sample: null,
            itemToken: '',
            openReactiveSample: false,
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        if (this.props.sampleRequest) {
            this.setState({ sample: this.props.sampleRequest })
            this.props.loadSupplier(this.props.sampleRequest.supplier_token)
        }
    }

    /**
    * Function to update updatePropertyInstrumentsr
    */
    updatePropertyInstruments(value) {
        this.setState({
            instruments: value
        })
        let rim = this.props.sampleRequest.rmi_document;
        this.props.updateDocumentRMi(rim.token, value, this.state.observationsSupplier)
    }

    /**
     * Function to update observations Supplier
     */
    updatePropertyObservationsSupplier(value) {
        this.setState({
            observationsSupplier: value
        })
        let rim = this.props.sampleRequest.rmi_document;
        this.props.updateDocumentRMi(rim.token, this.state.instruments, value)
    }

    /**
     *  Function to update observations engineer
     * @param {*} value 
     */
    updatePropertyObservationsEngineer(value) {
        let rim = this.props.sampleRequest.rmi_document;
        this.props.updateDocumentRMiByEngineerObservations(rim.token, value)
    }

    /**
    * onClickAddReference
    *
    * @param {*} item
    * @param {*} supplier
    * @memberof Create
    */
    onClickAddReference(itemToken = '') {
        if (itemToken) {
            let itemSelected = this.props.sampleRequest.rmi_document.annotations.find((annotation) => {
                return annotation.token === itemToken
            })
            this.setState({
                referenceToAdd: {
                    token: itemToken,
                    reference: itemSelected.reference,
                    specification: itemSelected.specification,
                    tolerance: itemSelected.tolerance,
                    unitMeasure: itemSelected.measurement_unit,
                    piece1: itemSelected.ispection_result_by_supplier_piece1,
                    piece2: itemSelected.ispection_result_by_supplier_piece2,
                    piece3: itemSelected.ispection_result_by_supplier_piece3,
                    piece4: itemSelected.ispection_result_by_supplier_piece4,
                    piece5: itemSelected.ispection_result_by_supplier_piece5,
                    keepAdding: false
                },
            })
        }
        this.setState({
            openAddReference: true,
            itemToken
        })
    }

    /**
     * Update property reference
     * @param {*} property 
     * @param {*} value 
     */
    updatePropertyReference(property, value) {
        let updatedReference = { ...this.state.referenceToAdd };
        updatedReference[property] = value;
        this.setState({
            referenceToAdd: updatedReference
        })
    }

    /**
     * Method to clear referenceToAdd on state and set openAddReference to false
     *
     * @memberof RMIDocument
     */
    clearStateReference(keepAdding = false) {
        this.setState({
            referenceToAdd: {
                token: '',
                reference: "",
                specification: "",
                tolerance: "",
                unitMeasure: "",
                piece1: "",
                piece2: "",
                piece3: "",
                piece4: "",
                piece5: "",
                keepAdding
            },
            openAddReference: false
        }, () => keepAdding && this.setState({ openAddReference: keepAdding }))
    }

    /***
     * Determine if can be add
     */
    canBeAddReference() {
        if (this.state.referenceToAdd.reference === "" ||
            this.state.referenceToAdd.specification === "" ||
            this.state.referenceToAdd.tolerance === "" ||
            this.state.referenceToAdd.unitMeasure === "" ||
            this.state.referenceToAdd.piece1 === "")
            return false;
        else
            return true;
    }

    /**
     * Funtion to add referene in RMI
     */
    onAddReferenceInRMI() {
        let rmiToken = this.props.sampleRequest.rmi_document.token;
        return this.props.addAnnotationToRMIDocument(rmiToken, this.state.referenceToAdd)
            .then(() => {
                this.clearStateReference(this.state.referenceToAdd.keepAdding);
                return Promise.resolve(this.state.referenceToAdd.keepAdding);
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                });
                return Promise.reject();
            });
    }

    /**
     * Function call action to edit an reference in RMI
     *
     * @param {*} itemToken
     * @memberof RMIDocument
     */
    onSaveEditionReferenceInRMI() {
        this.props.updateAnnotationToRMIDocument(this.state.referenceToAdd)
            .then(() => {
                this.clearStateReference();
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
     * Function to update annotation rmi changes of data
     *
     * @param {*} data
     * @param {*} token
     * @memberof DetailsCard
     */
    updateAnnotationRMI(data, token) {
        let rmi = this.state.sample.rmi_document;
        var detailUpdate = rmi.annotations.filter(x => x.token === token);
        if (detailUpdate.length !== 0) {
            detailUpdate[0][data.id] = data.value;
            this.props.updateInspectionByClient(detailUpdate[0])
        }
    }


    /**
     * On change files of dropzone 
     *
     * @param {*} event
     * @memberof DetailsCard
     */
    onChangeFile(files) {
        return this.props.uploadFilesBySupplier(this.state.sample.token, files)
            .then(() => {
                this.setState({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
                return Promise.resolve('Ok');
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
                return Promise.reject();
            });
    }

    /**
     * On remove files of dropzone 
     *
     * @param {*} event
     * @memberof DetailsCard
     */
    onDeleteFile(fileName) {
        return this.props.removeFilesBySupplier(this.state.sample.token, fileName)
            .then(() => {
                this.setState({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
                return Promise.resolve('Ok');
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
                return Promise.reject();
            });
    }

    /**
    * On change files of dropzone 
    *
    * @param {*} event
    * @memberof DetailsCard
    */
    onChangeFileCompany(files) {
        return this.props.uploadFilesByCompany(this.state.sample.token, files)
            .then(() => {
                this.setState({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
                return Promise.resolve('Ok');
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
                return Promise.reject();
            });
    }

    /**
     * On remove files of dropzone 
     *
     * @param {*} event
     * @memberof DetailsCard
     */
    onDeleteFileCompany(fileName) {
        return this.props.removeFilesByCompany(this.state.sample.token, fileName)
            .then(() => {
                this.setState({
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
                return Promise.resolve('Ok');
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
                return Promise.reject();
            });
    }

    /**
     * Action to open reactive sample request
     */
    onOpenReactiveSample() {
        this.setState({
            openReactiveSample: true,
        })
    }


    /**
     * On click reactive rmi
     */
    onClickReativerMI(commnents) {
        this.props.reactiveRmiClosed(this.props.sampleRequest.token, commnents)
            .then(() => {
                this.setState({
                    openReactiveSample: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

    /**
     * Function to donwload and print report rmi 
     */
    downloadSampleRmiReportPdf() {
        let sampleToken = this.props.match.params.token;
        this.props.downloadSampleRmiReportPdf(sampleToken);
    }

    /**
     * On active by parend code
     * @param {*} isReleasedByParentCode 
     * @param {*} description 
     */
    toggleAddIsReleasedByParentCode(isReleasedByParentCode, description) {
        return this.props.updateSampleRequest(this.props.match.params.token, "is_released_by_parent_code", isReleasedByParentCode)
    }

    /**
     * Change descruption from parent code
     * @param {*} description 
     * @param {*} isReleasedByParentCode 
     */
    updatedParentCode(property, propertyData) {
        return this.props.updateSampleRequest(this.props.match.params.token, property, propertyData)
    }

    /**
     * Rernder RMI document
     */
    render() {
        if (this.props.sampleRequest && this.props.sampleRequest.rmi_document && this.state.sample && this.state.sample.rmi_document) {
            return (
                <Card>
                    <Grid item xs={12}>
                        <List dense>
                            {this.props.sampleRequest.rmi_document && canBeAddArriveSampleRequest(this.props.userRolesByCompany) && <ListItem divider>
                                <ListItemText
                                    secondary={
                                        <Link onClick={() => this.downloadSampleRmiReportPdf()} to={"#"}>
                                            <DownloadIcon /> {" "}{<Translate id="samplesrequest.rmiDownload" />}
                                        </Link>
                                    }
                                />
                                {this.props.sampleRequest.is_closed && canBeValidateModuleDevelopment(this.props.userRolesByCompany) &&  <ListItemSecondaryAction>
                                    <Fab variant="extended" size="small"  color="primary"
                                        onClick={this.onOpenReactiveSample} disabled={this.props.isSaving}>
                                        <ReactiveRMIIcon /><Typography style={{color:"#fff"}} variant="caption">
                                            <Translate id='samplesrequest.reactiverMI' />
                                        </Typography>
                                    </Fab>
                                </ListItemSecondaryAction>}
                            </ListItem>}
                            <ListItem>
                                <HtmlEditor
                                    id="equipment"
                                    label={"samplesrequest.instrumentEquip"}
                                    labelVariant={"body2"}
                                    isDisabled={!(this.props.sampleRequest.can_be_update_supplier && IsCurrentNotActiveDirectoryUser(this.props.currentUser))}
                                    initialValue={this.props.sampleRequest.rmi_document.equipment}
                                    onChange={(data) => this.updatePropertyInstruments(data.value)}
                                />
                            </ListItem>
                             {/** Code parent  */}
                            {this.props.sampleRequest.company_identifier === this.props.company.get_company_identifier && this.props.sampleRequest.can_show_parent_code && <ListItem divider>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <Typography component="span" variant="body2" color="textPrimary">
                                        <strong><Translate id="samplesrequest.isReleasedByParentCode" />{": "} </strong>
                                            <Switch
                                                onChange={(event) => { this.toggleAddIsReleasedByParentCode(event.target.checked) }}
                                                checked={this.props.sampleRequest.is_released_by_parent_code}
                                                disabled={!(this.props.sampleRequest.can_be_update_supplier && IsCurrentNotActiveDirectoryUser(this.props.currentUser))}
                                            />
                                        </Typography>
                                        {"_ "}{<Translate id="samplesrequest.messageDescriptionPartCode" />}
                                        <br/> <br/>
                                        {this.props.sampleRequest.is_released_by_parent_code && <Grid container spacing={3}>
                                            <Grid item xs={3}>
                                                <TextField id="outlined-basic" label={<Translate id="samplesrequest.codeParent" />} variant="outlined" 
                                                    defaultValue={this.props.sampleRequest.name_parent_code}
                                                    disabled={!(this.props.sampleRequest.can_be_update_supplier && IsCurrentNotActiveDirectoryUser(this.props.currentUser))}
                                                    onChange={(event) => this.updatedParentCode("name_parent_code", event.target.value)}
                                                    type={"text"}
                                                    error={this.props.sampleRequest.name_parent_code ? false : true}
                                                />
                                                <br/>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField id="outlined-basic" label={<Translate id="samplesrequest.folioParent" />} variant="outlined" 
                                                    defaultValue={this.props.sampleRequest.folio_parent_code}
                                                    disabled={!(IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.props.sampleRequest.can_be_update_supplier)}
                                                    onChange={(event) => this.updatedParentCode("folio_parent_code", event.target.value)}
                                                    type={"text"}
                                                    error={this.props.sampleRequest.folio_parent_code ? false : true}
                                                />
                                                <br/>
                                            </Grid>
                                        </Grid>}
                                    </React.Fragment>
                                } />
                        </ListItem>}
                        </List>
                        <List dense>
                            <ListItem>
                                <ListItemText primary={
                                    <React.Fragment><Typography variant="h6" noWrap color="textSecondary" gutterBottom>
                                        <strong> <Translate id="samplesrequest.resultInspection" /></strong></Typography>
                                        <br/>
                                    </React.Fragment>
                                } />
                                {this.props.sampleRequest.can_be_update_supplier && (IsCurrentNotActiveDirectoryUser(this.props.currentUser)) && <ListItemSecondaryAction>
                                    <Tooltip title={<Translate id="samplesrequest.addAnnotation" />}>
                                        <Fab size="small" color="primary" onClick={() => { this.onClickAddReference() }}>
                                            <AddIcon />
                                        </Fab>
                                    </Tooltip>
                                </ListItemSecondaryAction>}
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={
                                    <AnnotationsRMI {...this.props}
                                        sample={this.props.sampleRequest}
                                        updateAnnotationRMI={this.updateAnnotationRMI}
                                        onClickAddReference={this.onClickAddReference}
                                    />
                                } />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            <ListItem>
                                <HtmlEditor
                                    id="supplier_observations"
                                    label={"samplesrequest.observationsSupplier"}
                                    labelVariant={"body2"}
                                    isDisabled={!(this.props.sampleRequest.can_be_update_supplier && IsCurrentNotActiveDirectoryUser(this.props.currentUser))}
                                    initialValue={this.props.sampleRequest.rmi_document.supplier_observations}
                                    onChange={(data) => this.updatePropertyObservationsSupplier(data.value)}
                                />
                            </ListItem>
                            <ListItem>
                                <HtmlEditor
                                    id="engineer_observations"
                                    label={"samplesrequest.observacionsInPlant"}
                                    labelVariant={"body2"}
                                    isDisabled={!(canBeAddArriveSampleRequest(this.props.userRolesByCompany) && this.props.sampleRequest.can_be_update_company)}
                                    initialValue={this.props.sampleRequest.rmi_document.engineer_observations}
                                    onChange={(data) => this.updatePropertyObservationsEngineer(data.value)}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Typography variant="subtitle1" gutterBottom><strong><Translate id="samplesrequest.planesReference" /></strong></Typography>}
                                    secondary={
                                        <Dropzone
                                            files={this.props.sampleRequest.attachments_suppliers}
                                            onChange={this.onChangeFile}
                                            onDeleteFile={this.onDeleteFile}
                                            isDisabled={!(IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.props.sampleRequest.can_be_update_supplier)}
                                            baseApi={process.env.REACT_APP_SIP_URL_FILES}
                                        />
                                    }
                                />
                            </ListItem><br /><br />
                            <ListItem>
                                <ListItemText primary={<Typography variant="subtitle1" gutterBottom><strong><Translate id="samplesrequest.filesByPlant" /></strong></Typography>}
                                    secondary={
                                        <Dropzone
                                            files={this.props.sampleRequest.rmi_document.attachments_company}
                                            onChange={this.onChangeFileCompany}
                                            onDeleteFile={this.onDeleteFileCompany}
                                            isDisabled={!(IsCurrentActiveDirectoryUser(this.props.currentUser) && this.props.sampleRequest.can_be_update_company)}
                                            baseApi={process.env.REACT_APP_SIP_URL_FILES}
                                        />
                                    }
                                />
                            </ListItem><br /><br />
                        </List>

                        <OptionsDispositionRMI  {...this.props} />
                    </Grid>

                    {/** Dialog to AddReferenceDialog */}
                    <AddReferenceDialog
                        reference={this.state.referenceToAdd}
                        updatePropertyReference={this.updatePropertyReference}
                        open={this.state.openAddReference}
                        onClose={() => this.clearStateReference()}
                        onAddReference={this.onAddReferenceInRMI}
                        itemToken={this.state.itemToken}
                        onEditReference={this.onSaveEditionReferenceInRMI}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.canBeAddReference()}
                    />

                    {/** Dialog to reactive sample request closed */}
                    < AddCommonItem
                        title={<Translate id="samplesrequest.messageReactiveRmi" />}
                        message={<Translate id="samplesrequest.commentForReactiveRmi" />}
                        open={this.state.openReactiveSample}
                        onClose={() => { this.setState({ openReactiveSample: false }) }}
                        onSaveItem={this.onClickReativerMI}
                        isSaving={this.props.isSaving}
                        isTextArea={true}
                    />

                    <Toaster
                        message={this.state.toasterMessage}
                        open={this.state.showToaster}
                        variant={this.state.toasterVariant}
                        onClose={() => { this.setState({ showToaster: false }) }}
                    />

                </Card>
            )
        }
        else {
            return ("");
        }

    }
}

/**
 * Component to show list the annotations from RMI 
 */
class AnnotationsRMI extends Component {

    /**
     * Create an instance of rmi document
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            sample: null,
            itemToken: '',
            selectedTokens: [],
            acceptedQualityDialog: false,
        }
    }

    /**
     * Function to trigger blur event and update annotation data of input
     * 
     * @param {*} event
     * @memberof AnnotationsRMI
     */
    onKeyDownItem(event) {
        if (event.key === "Enter")
            document.activeElement.blur();
    }

    // /**
    //  * update propert
    //  * @param {*} dateSource 
    //  * @param {*} item 
    //  */
    updateProperty(dateSource, item) {
        let isDisabled = !(IsCurrentActiveDirectoryUser(this.props.currentUser) && this.props.sampleRequest.can_be_update_company);
        if (!isDisabled) {
            return (
                <TextField
                    id={dateSource}
                    defaultValue={item[dateSource]}
                    disabled={isDisabled}
                    type={"text"}
                    onBlur={(event) => this.props.updateAnnotationRMI(event.target, item.token)}
                    onKeyDown={this.onKeyDownItem}
                    autoComplete='off'
                />
            )
        }
        return item[dateSource]
    }

    /**
    * Set Custom background color foreach detail row
    *
    * @memberof AnnotationsRMI
    */
    setCustomColorRow(annotations) {
        annotations.map((item) => {
            var customColor = "";
            if (item.disposition === APPROVAL_ACEPTED)
                customColor = "#dff0d8"
            if (item.disposition === APPROVAL_REJECTED)
                customColor = "#f2dede"
            return item.custom_color = customColor;
        });
    }

    /**
     * Handle state to set token of item to show options menu list
     *
     * @param {*} itemToken
     * @memberof AnnotationsRMI
     */
    handleClickOptionsMenu(itemToken) {
        this.setState({
            itemToken,
        });
    };

    /**
     * Handle state to show or hide menu options list
     *
     * @memberof AnnotationsRMI
     */
    handleCloseOptionsMenu() {
        if (!this.state.openModalAuthorization) {
            this.setState(state => ({
                itemToken: ''
            }));
        }
    };

    acceptOrReject(token, status) {
        this.props.acceptedOrRejectedAnnotationByClient(token, status)
            .then(() => {
                this.handleCloseOptionsMenu()
            })
    }

    /**
     * function to acceted or rejected annotations from 
     * @param {*} toStatus 
     */
    onClickAcceptRejectAnnotationRMI(toStatus) {
        this.props.acceptRejectAnnotationsRMIFromQuality(this.state.selectedTokens, toStatus)
        .then(() => {
            this.setState({ acceptedQualityDialog: false, itemToken: null, selectedTokens: [] })
        }).catch(() => {
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="common.errorToSave" />,
                toasterVariant: "error",
            })
        });
    }

    /**
     * Method to render OptionsMenu
     *
     * @param {*} item
     * @returns
     * @memberof AnnotationsRMI
     */
    renderOptionsMenu(item) {
        var options = [];
        if (item.token !== 'selection') 
        {
            if (this.props.sampleRequest.can_be_update_supplier && (IsCurrentNotActiveDirectoryUser(this.props.currentUser))) {
                options.push({
                    itemClick: () => this.props.removeAnnotationToRMIDocument(item.token),
                    tooltipTranslation: <Translate id="samplesrequest.deleteAnnotation" />,
                    menuItemIcon: <DeleteOutlinedIcon color="error" className={this.props.classes.icon} />
                });
                options.push({
                    itemClick: () => this.props.onClickAddReference(item.token),
                    tooltipTranslation: <Translate id="samplesrequest.editAnnotation" />,
                    menuItemIcon: <EditIcon color="secondary" className={this.props.classes.icon} />
                });
            }
            if (this.props.sampleRequest.can_be_update_company && canBeAddArriveSampleRequest(this.props.userRolesByCompany)) {
                options.push({
                    itemClick: () => this.acceptOrReject(item.token, APPROVAL_ACEPTED),
                    tooltipTranslation: <Translate id="common.accept" />,
                    menuItemIcon: <AcceptIcon color="secondary" className={this.props.classes.icon} />
                });
                options.push({
                    itemClick: () => this.acceptOrReject(item.token, APPROVAL_REJECTED),
                    tooltipTranslation: <Translate id="common.reject" />,
                    menuItemIcon: <RejectIcon color="error" className={this.props.classes.icon} />
                });
            }
        }
        else{
            if (this.props.sampleRequest.can_be_update_company && canBeAddArriveSampleRequest(this.props.userRolesByCompany)) {
                //Modal to accept, or reject rim from add arrive
                options.push({
                    itemClick: () => this.onClickShowDialogQuality(item.token),
                    tooltipTranslation: <Translate id="samplesrequest.accetedOrRejected" />,
                    menuItemIcon: <ActionsIcon />
                })
            }
        }
        return (
            <OptionsMenu
                itemToken={item.token}
                handleClickOptions={this.handleClickOptionsMenu}
                open={this.state.itemToken === item.token}
                handleClickAway={this.handleCloseOptionsMenu}
                options={options}
                xsSize
            />
        )
    }

    /**
    * Onrender items deriver workflows
    */
    onRenderCellItem(dataSource, item) {
        if (dataSource === "inspection_result_by_engineering") {
            return this.updateProperty(dataSource, item);
        }
        if (dataSource === "options") {
            return this.renderOptionsMenu(item);
        }
    }

    onClickShowDialogQuality(itemToken) {
        this.setState({
            acceptedQualityDialog: true,
            itemToken: itemToken,
        })
    }

    /**
     * on selected checks
     * @param {*} selectedTokens 
     */
    onSelectedChecks(selectedTokens) {
        let filteredSelectedTokens = [];
        selectedTokens.forEach(selectedToken => {
            filteredSelectedTokens.push(selectedToken)
        })
        this.setState({ selectedTokens: filteredSelectedTokens });
    }

    /**
     * Render annotations 
     */
    render() {
        this.setCustomColorRow(this.props.sample.rmi_document.annotations);
        let showInspectionInPlant = true;
        let showSelectChecks = canBeAddArriveSampleRequest(this.props.userRolesByCompany) && this.props.sampleRequest.can_be_update_company 
        return (
            <Grid item xs={12}>
                <Divider />
                <Grid container spacing={24} justify="flex-end">
                    {(showSelectChecks && this.state.selectedTokens.length > 0) &&
                        <Grid item xs={.5}>
                            {this.renderOptionsMenu({ token: 'selection' })}
                        </Grid>
                    }
                </Grid>
                <DataTable
                    data={this.props.sample.rmi_document.annotations}
                    configuration={TableAnnotationsRMIConfiguration(showInspectionInPlant, showSelectChecks)}
                    onRenderCellItem={this.onRenderCellItem}
                    onSelectedCheck={(selectedTokens) => { this.onSelectedChecks(selectedTokens) }}
                    selectedTokens={this.state.selectedTokens}
                />

                {/** Dialog to accepted or rejected annotaions rmi from quality */}
                <AcceptedRejectedAnnRmi
                    open={this.state.acceptedQualityDialog}
                    onClose={() => { this.setState({ acceptedQualityDialog: false }) }}
                    onClickAcceptRejectAnnotations={this.onClickAcceptRejectAnnotationRMI}
                    isSaving={this.props.isSaving}
                />
            </Grid>
        )
    }
}


//Work IN Progress
function TableAnnotationsRMIConfiguration(showInspectionInPlant, showCheckSelect) {
    let columns = [
        {
            header: <Translate id="samplesrequest.reference" />,
            dataSource: 'reference'
        }, {
            header: <Translate id="samplesrequest.specification" />,
            dataSource: 'specification',
        }, {
            header: <Translate id="samplesrequest.tolerance" />,
            dataSource: 'tolerance'
        }, {
            header: <Translate id="samplesrequest.medicionUnit" />,
            dataSource: 'measurement_unit'
        }, {
            header: <Translate id="samplesrequest.piece1" />,
            dataSource: 'ispection_result_by_supplier_piece1'
        }, {
            header: <Translate id="samplesrequest.piece2" />,
            dataSource: 'ispection_result_by_supplier_piece2'
        }, {
            header: <Translate id="samplesrequest.piece3" />,
            dataSource: 'ispection_result_by_supplier_piece3'
        }, {
            header: <Translate id="samplesrequest.piece4" />,
            dataSource: 'ispection_result_by_supplier_piece4'
        }, {
            header: <Translate id="samplesrequest.piece5" />,
            dataSource: 'ispection_result_by_supplier_piece5'
        }];
    //Validation to show or hide this column
    if (showInspectionInPlant)
        columns.push({
            header: <Translate id="samplesrequest.inspectionInPlant" />,
            dataSource: 'inspection_result_by_engineering'
        });

    columns.push({
        header: '',
        dataSource: 'options'
    });

    if (showCheckSelect)
        columns.push({
            header: '',
            dataSource: 'selectCheck'
        })
    return ({ columns });
}

/**
 * Component to show the options from rmi document footer
 */
class OptionsDispositionRMI extends Component {

    /**
     * Create an instance of rmi document
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {

        }
    }

    ///Render
    render() {
        const { classes } = this.props;
        return (
            <Grid item xs={12}>
                <Table>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell>{<Typography variant="body1" gutterBottom><Translate id="samplesrequest.optionsDisposition" /></Typography>}</TableCell>
                            <TableCell>{<Typography variant="body1" gutterBottom><Translate id="samplesrequest.optionsProveduria" /></Typography>}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.rowsSection}>
                        <TableRow className={classNames(classes.tableRow)}>
                            <TableCell key={uuidv4()} align="left" padding="dense">
                                {<Typography variant="body2" gutterBottom>
                                    <Translate id="samplesrequest.acceptedA" /><br />
                                    <Translate id="samplesrequest.accetedB" /><br />
                                    <Translate id="samplesrequest.acceptedC" /><br />
                                    <Translate id="samplesrequest.acceptedD" /><br />
                                    <Translate id="samplesrequest.optionsProveduria" /><br /><br />
                                </Typography>
                                }
                            </TableCell>
                            <TableCell key={uuidv4()} align="left" padding="dense">
                                {<div>
                                    <Translate id="samplesrequest.accetedAProveduria" /><br />
                                    <Translate id="samplesrequest.acceptedBProveeduria" /><br />
                                </div>
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow className={classNames(classes.tableRow)}>
                            <TableCell colSpan={2} key={uuidv4()} align="left" padding="dense">
                                {<div><br /><br /><Translate id="samplesrequest.messageRmi" /><br /><br /></div>}
                            </TableCell>
                        </TableRow>
                        {this.props.company.company_code === COMPANY_FERSA &&
                            <TableRow className={classNames(classes.tableRow)}>
                                <TableCell colSpan={2} key={uuidv4()} align="left" padding="dense">
                                    {<div><br />
                                        <Translate id="samplesrequest.downloadRmiThemplate" />
                                        <a href={ThemplateRMITorrey} >
                                            {<Translate id="quotes.clickDonwload" />}
                                        </a>
                                    <br /><br /></div>}
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </Grid>

        )
    }
}

/**
*  Defines the properties injecteded from the store to view container
* @param {*} state 
*/
function mapStateToProps(state) {
    let sampleRequest = state.samplerequests.get("sampleRequest");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    return {
        isLoadingUser: state.oidc.isLoadingUser,
        currentUser: user,
        isLoadingSample: state.samplerequests.get('isLoadingSample'),
        sampleRequest: sampleRequest ? sampleRequest.toJS() : null,
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        isSaving: state.samplerequests.get("isSaving"),

    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        addAnnotationToRMIDocument: (sampleToken, annotation) => {
            return (dispatch(addAnnotationToRMIDocument(sampleToken, annotation)))
        },
        removeAnnotationToRMIDocument: (annotationToken) => {
            return (dispatch(removeAnnotationToRMIDocument(annotationToken)));
        },
        updateAnnotationToRMIDocument: (annotation) => {
            return (dispatch(updateAnnotationToRMIDocument(annotation)))
        },
        updateDocumentRMi: (tokenRMI, equipment, supplierObservations) => {
            return (dispatch(updateDocumentRMi(tokenRMI, equipment, supplierObservations)))
        },
        updateInspectionByClient: (annotation) => {
            return (dispatch(updateInspectionByClient(annotation)))
        },
        acceptedOrRejectedAnnotationByClient: (token, disposition) => {
            return (dispatch(acceptedOrRejectedAnnotationByClient(token, disposition)))
        },
        loadSupplier: (token) => {
            return dispatch(loadSupplier(token));
        },
        uploadFilesBySupplier: (sampleToken, files) => {
            return dispatch(uploadFilesBySupplier(sampleToken, files));
        },
        removeFilesBySupplier: (sampleToken, fileName) => {
            return dispatch(removeFilesBySupplier(sampleToken, fileName));
        },
        uploadFilesByCompany: (sampleToken, files) => {
            return dispatch(uploadFilesByCompany(sampleToken, files));
        },
        removeFilesByCompany: (sampleToken, fileName) => {
            return dispatch(removeFilesByCompany(sampleToken, fileName));
        },
        downloadSampleRmiReportPdf: (sampleToken) => {
            return dispatch(downloadSampleRmiReportPdf(sampleToken));
        },
        updateDocumentRMiByEngineerObservations: (tokenRMI, engineerObservations) => {
            return (dispatch(updateDocumentRMiByEngineerObservations(tokenRMI, engineerObservations)))
        },
        acceptRejectAnnotationsRMIFromQuality:(itemTokens, status) =>{
            return (dispatch(acceptRejectAnnotationsRMIFromQuality(itemTokens, status)))
        },
        reactiveRmiClosed: (sampleToken, commnents) => {
            return dispatch(reactiveRmiClosed(sampleToken, commnents))
        },
        updateSampleRequest:(sampleToken, property, propertyData) =>{
            return (dispatch(updateSampleRequest(sampleToken, property, propertyData)))
        }
    }
}

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    actionsToolbar: {
        display: "flex"
    },
    logoCompany: {
        maxHeight: 100
    },
    tableHead: {
        backgroundColor: "#fff",
        position: "sticky",
        zIndex: '30',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    },
    inline: {},
    paper: {},
    root: {},
    logoSupplier: {}
});


export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RMIDocument)));