import { fromJS, Map } from 'immutable'

import * as deliveryOrdersAction from './DeliveryOrderAction'
export const deliveryOrdersInitialState = fromJS({
	isLoadingDeliveryOrders: false,
	isLoadingDeliveryOrderItems: false,
	isLoadingAddDeliveryOrderItems: false,
	IsSavingLoadingItem: false,
	deliveryOrders: [],
	sortCriteria: {
		by: 'Folio',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 10,
	deliveryOrdersCount: null,
	//---Single delivery order---
	isLoadingDeliveryOrder: false,
	isSavingDeliveryOrder: false,
	deliveryOrderDraft: null,
	deliveryOrder: null,
	isLoadingPartNumberCodes: false,
	partNumberCodes: [],
	isLoadingPurchaseOrders: false,
	release: [],
	items: [],
	isLoadingPositions: false,
	positions: [],
	isSavingMaterial: false,
	isDonwloadingReport: false,
	//---------------------------
	//---FullCalendarDeliveries--
	deliveryOrdersEvents: [],
	isLoadingDeliveryOrdersEvents: false,
	//---------------------------
	isLoadingCertificate: false,
})

export default function deliveryOrderReducer(state = deliveryOrdersInitialState, action) {
	switch (action.type) {
		case deliveryOrdersAction.IS_LOADING_DELIVERY_ORDERS: {
			return state.merge({
				isLoadingDeliveryOrders: action.isLoading,
			})
		}
		case deliveryOrdersAction.IS_LOADING_DELIVERY_ORDER_SHOW_VIEW: {
			return state.merge({
				isLoadingDeliveryShowView: action.isLoading,
			})
		}
		case deliveryOrdersAction.IS_LOADING_DELIVERY_ORDER_ITEMS: {
			return state.merge({
				isLoadingDeliveryOrderItems: action.IsLoading,
			})
		}
		case deliveryOrdersAction.IS_LOADING_ADD_DELIVERY_ORDER_ITEMS: {
			return state.merge({
				IsSavingLoadingItem: action.IsSavingLoadingItem,
			})
		}

		case deliveryOrdersAction.SET_DELIVERYORDERS: {
			return state.merge({
				deliveryOrders: action.deliveryOrders.items,
				deliveryOrdersCount: action.deliveryOrders.total,
				isLoadingDeliveryOrders: false,
			})
		}
		case deliveryOrdersAction.SET_SELECTOR_VALUES: {
			let isLoadingSelector = 'isLoading' + action.selectorName
			return state.merge({
				[isLoadingSelector]: false,
				release: fromJS(action.selectorValues),
				items: fromJS(action.selectorValues.items),
			})
		}
		case deliveryOrdersAction.SET_DELIVERY_ORDER: {
			return state.merge({
				isLoadingDeliveryOrder: false,
				isSavingDeliveryOrder: false,
				[action.deliveryOrderType]: fromJS(action.deliveryOrder),
			})
		}
		case deliveryOrdersAction.IS_SAVING_DELIVERY_ORDER: {
			return state.merge({
				isSavingDeliveryOrder: action.isSavingDeliveryOrder,
			})
		}
		case deliveryOrdersAction.ADD_MATERIAL: {
			let foundMaterial = state
				.get('deliveryOrderDraft')
				.get('materials')
				.find((item) => {
					return item.get('id') === action.material.id
				})
			if (foundMaterial) return state.set('IsSavingLoadingItem', false)
			let material = Map(action.material)
			let newList = state.get('deliveryOrderDraft').get('materials').push(material)
			return state.setIn(['deliveryOrderDraft', 'materials'], fromJS(newList)).set('IsSavingLoadingItem', false)
		}
		case deliveryOrdersAction.UPDATE_MATERIAL: {
			return state
				.updateIn([action.deliveryOrderType, 'materials'], (allMaterials) => {
					let index = allMaterials.findIndex((item) => {
						return item.get('id') === action.material.id
					})
					return allMaterials.setIn([index], fromJS(action.material))
				})
				.set('IsSavingLoadingItem', false)
		}
		case deliveryOrdersAction.UPDATE_DELIVERY: {
			return state
				.updateIn(
					['deliveryOrder', 'has_material_returned_or_missing'],
					action.material.has_material_returned_or_missing
				)
				.set('IsSavingLoadingItem', false)
		}
		case deliveryOrdersAction.REMOVE_MATERIAL: {
			let oldList = state.getIn(['deliveryOrderDraft', 'materials']).toJS()
			let newList = oldList.filter((item) => {
				return item.id !== action.id
			})
			return state.setIn(['deliveryOrderDraft', 'materials'], fromJS(newList)).set('IsSavingLoadingItem', false)
		}
		case deliveryOrdersAction.CHANGE_DELIVERY_ORDERS_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case deliveryOrdersAction.CHANGE_DELIVERY_ORDERS_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case deliveryOrdersAction.CHANGE_DELIVERY_ORDERS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case deliveryOrdersAction.SET_DELIVERY_ORDERS_COUNT: {
			return state.merge({
				deliveryOrdersCount: action.count,
			})
		}
		case deliveryOrdersAction.SET_DELIVERY_ORDERS_EVENTS: {
			let deliveryOrdersEvents = fromJS(action.deliveryOrdersEvents)
			return state.merge({
				deliveryOrdersEvents,
				isLoadingDeliveryOrdersEvents: false,
			})
		}
		case deliveryOrdersAction.ADD_FILE_IN_DELIVERY_ORDER: {
			if (action.isFromViewCreate) {
				let oldList = state.getIn(['deliveryOrderDraft', 'delivery', action.folderName]).toJS()
				let filesToAdd = action.files.filter((newFile) => {
					return !oldList.find((uploadedFile) => {
						return uploadedFile.name === newFile.name
					})
				})
				let newList = [...oldList, ...filesToAdd]
				return state.setIn(['deliveryOrderDraft', 'delivery', action.folderName], fromJS(newList))
			} else {

				let oldList = state.getIn(['deliveryOrder', action.folderName]).toJS()
				let filesToAdd = action.files.filter((newFile) => {
					return !oldList.find((uploadedFile) => {
						return uploadedFile.name === newFile.name
					})
				})
				let newList = [...oldList, ...filesToAdd]
				return state.setIn(['deliveryOrder', action.folderName], fromJS(newList))
			}
		}
		case deliveryOrdersAction.IS_DOWNLOADING_REPORT_ORDERS: {
			return state.merge({
				isDonwloadingReport: action.isDonwloading,
			})
		}
		case deliveryOrdersAction.REMOVE_INDRAFT_FILE_DELIVERY_ORDER: {
			let oldList = state.getIn(['deliveryOrderDraft', action.folderName]).toJS()
			let newList = oldList.filter((file) => {
				return file.name !== action.fileName
			})
			return state.setIn(['deliveryOrderDraft', action.folderName], fromJS(newList))
		}
		case deliveryOrdersAction.REMOVE_FILE_IN_DELIVERY_ORDER: {
			if(action.isFromViewCreate){
				let oldList = state.getIn(['deliveryOrderDraft', 'delivery',action.folderName]).toJS()
				let newList = oldList.filter((file) => {
					return file.name !== action.fileName
				})
				return state.setIn(['deliveryOrderDraft', 'delivery',action.folderName], fromJS(newList))
			}
			else{
				let oldList = state.getIn(['deliveryOrder', action.folderName]).toJS()
				let newList = oldList.filter((file) => {
					return file.name !== action.fileName
				})
				return state.setIn(['deliveryOrder', action.folderName], fromJS(newList))
			}

		}
		default:
			return state
	}
}
