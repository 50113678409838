import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';

/** Material UI import section */
import { AppBar, Tab, Tabs } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddTwoTone';

/** Custom components import section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader';
import LoadingPage from '../../../common/LoadingPage';
import GeneralInformation from '../../components/GeneralInformation';
import MaterialsInformation from '../../components/MaterialsInformation';

/** Actions import section */
import { loadServiceOrderDetail, setServiceOrder } from '../../../../store/accreditedWorkshops/accreditedWorkshopsActions';

/** Redux selectors import section */
import { getServiceOrderSelector } from '../../../../store/accreditedWorkshops/accreditedWorkshopsSelectors';

/**
 * Component to show the service order detail on lateral panel
 */
const ServiceOrderDetail = (props) => {
	const { token } = props;
	const loading = useSelector((state) => state.accreditedWorkshops.get('isLoadingService'));
	const serviceOrder = useSelector((state) => getServiceOrderSelector(state));

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadServiceOrderDetail(token));
		return () => {
			dispatch(setServiceOrder(null));
		};
	}, [token]);

	return (
		<LateralPanel>
			<PanelHeader
				isIconMaterial={true}
				titlePanel={<Translate id={'accreditedWorkshops.serviceOrderDetail.title'} />}
				icon={AddIcon}
				{...props}
			/>
			{
				{
					true: <LoadingPage />,
					false: <PanelContent loading={loading} serviceOrder={serviceOrder} />
				}[loading]
			}
		</LateralPanel>
	);
};

ServiceOrderDetail.propTypes = {
	token: PropTypes.string.isRequired,
	onCloseButtonClick: PropTypes.func.isRequired
};

ServiceOrderDetail.defaultProps = {
	onCloseButtonClick: () => console.warn('[onCloseButtonClick] not defined!')
};

const PanelContent = (props) => {
	/** Desctructuring properties */
	const { loading, serviceOrder } = props;
	/** Define local state */
	const [selectedTab, setValueTab] = useState(0);
	return {
		true: <LoadingPage />,
		false: (
			<div>
				<AppBar position="static" color="default">
					<Tabs
						value={selectedTab}
						indicatorColor="secondary"
						textColor="secondary"
						onChange={(_, value) => setValueTab(value)}
						aria-label="disabled tabs example"
					>
						<Tab label="General" />
						<Tab label="Materiales" />
					</Tabs>
				</AppBar>
				{serviceOrder && (
					<div>
						{
							{
								0: <GeneralInformation service={serviceOrder} />,
								1: (
									<MaterialsInformation
										usedMaterials={serviceOrder.usedMaterials}
										removedMaterials={serviceOrder.removedMaterials}
									/>
								)
							}[selectedTab]
						}
					</div>
				)}
			</div>
		)
	}[loading];
};

export default ServiceOrderDetail;
