export const toPascalCase = (obj) => {
	if (obj === null || obj === undefined) {
		return null
	}
	const newObj = {}
	Object.entries(obj).forEach(([key, value]) => {
		const camelCasePropertyKey = (key.charAt(0).toUpperCase() + key.slice(1) || key).toString()
		newObj[camelCasePropertyKey] = value
	})
	return newObj
}