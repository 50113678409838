import React from 'react';
import PropTypes from 'prop-types';

/**Import material UI Section*/
import Root from '../../../../store/Root';

/**Import components section*/
import ResultInformation from './ResultInformation'
import { COLOR_STATUS_SUCCESS } from '../../../../store/helpers/StatusColorConstants';
import { Document, Page, View, StyleSheet } from '@react-pdf/renderer';
import { withLocalize } from 'react-localize-redux';
/**Import resources section*/


const PdfResultDocument = props => {
    const { sustainability } = props

    return (
    <Root>
        <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
            <View style={styles.panelInformation}>
                <ResultInformation
                    sustainability={sustainability}/>
            </View>
        </View>
      </Page>
    </Document> 
    </Root>
    );
};

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    container: {
        marginBottom: '1px',
        marginTop: '1px',
        marginRight: '1px',
        marginLeft: '1px',
        border: 'solid',
        backgroundColor: COLOR_STATUS_SUCCESS
    },
    panelInformation: {
        backgroundColor: 'white',
        height: '99%',
        width: 'auto',
        marginBottom: '5',
        marginTop: '5',
        marginRight: '5',
        marginLeft: '5',
    }
  });

PdfResultDocument.propTypes = {
    topics:PropTypes.array  
};

PdfResultDocument.defaultProps = {
    topics: [],
    sustainability: {}
};
export default withLocalize(PdfResultDocument);