/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
/**Import material UI Section*/
import {
    withStyles, ExpansionPanel, ExpansionPanelSummary,
    ExpansionPanelDetails, Typography,
    List, ListItem, ListItemText
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
/**Import components section*/
import PopperListsComponent from '../PopperListsComponent'
import CriteriaTable from './CriteriaTable'
import WarningComponent from '../../../common/WarningComponent'
/**import selector section */
import {
    getTotalPercentage, getTotalCompleted, getCriteriaGroup
} from '../../../../store/biddings/BindingsSelectors'
import { percengeFormat } from '../../../../store/helpers/AmountHelper'
import { canBeEditBiddingInStatusDraft } from '../../../../store/helpers/BiddingHelper'
import { IsCurrentActiveDirectoryUser } from '../../../../store/helpers/SessionHelper';

const EvaluationsCriteria = (props) => {
    /**String props section */
    const { classes } = props;
    // /**Bools props section */
    const { isSaving } = props
    // /**Arrays props section */
    const { userProfileLogged, userRolesByCompany } = props
    // /**Functions props section */
    const { onEditEvaluationCriteriaConfigPanel, onDeletedEvaluationFromBackEnd,
        onDeleteServiceOrMaterial, onEditEvaluationCriteriaGroupConfigPanel, translate
    } = props

    /**Objects props section */
    const { bidding } = props
    const evaluationCriteria = getCriteriaGroup(bidding);
    let canBeEditedInformation = canBeEditBiddingInStatusDraft(bidding, userRolesByCompany, userProfileLogged ? userProfileLogged.profile : null)
    let isUserActiveDirectory = IsCurrentActiveDirectoryUser(userProfileLogged);


    /**
     * Render componet 
     */
    return (
        <div className={classes.sectionsContainer}>
            {
                evaluationCriteria.map((group) => {
                    let buttonsForMoreCard = []
                    if (canBeEditedInformation && isUserActiveDirectory) {
                        buttonsForMoreCard.push({
                            title: <Translate id="common.edit" />,
                            icon: <EditIcon />,
                            onClick: (event) => { onEditEvaluationCriteriaGroupConfigPanel(event, group) },
                            isIconMaterial: true,
                            disabled: (isSaving || !canBeEditedInformation)
                        })
                    }
                    return (
                        <>
                            <ExpansionPanel key={group.Id}>
                                <ExpansionPanelSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                                    <Typography color="primary">{group.Group}:{percengeFormat(group.Weighing)}</Typography>
                                    <div className={classes.flexGrow} />
                                    {
                                        canBeEditedInformation && isUserActiveDirectory &&
                                        <div className={classes.buttonsAcordionSummary}>
                                            <PopperListsComponent
                                                title={<Translate id="common.options" />}
                                                listItems={buttonsForMoreCard}
                                            />
                                        </div>
                                    }
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div style={{ flexBasis: '100%' }}>
                                        <CriteriaTable
                                            group={group}
                                            bidding={bidding}
                                            userRolesByCompany={userRolesByCompany}
                                            onDeletedEvaluationFromBackEnd={onDeletedEvaluationFromBackEnd}
                                            onDeleteServiceOrMaterial={onDeleteServiceOrMaterial}
                                            isSaving={isSaving}
                                            userProfileLogged={userProfileLogged}
                                            onEditEvaluationCriteriaConfigPanel={onEditEvaluationCriteriaConfigPanel}
                                        />
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                        </>

                    )
                })
            }
            <List>
                <ListItem>
                    <ListItemText
                        primary={
                            <Typography style={{ color: (getTotalCompleted(evaluationCriteria) ? "green" : "red") }} >
                                {" "}<Translate id={'biddingsModule.services.totalPercetnage'} />{": "}{getTotalPercentage(evaluationCriteria)}
                            </Typography>
                        } />
                </ListItem>
            </List>
            {
                !getTotalCompleted(evaluationCriteria) && <WarningComponent
                    title={translate("biddingsModule.warning")}
                    message={`${translate("biddingsModule.percentajeTotal")} ${getTotalPercentage(evaluationCriteria)}, ${translate("biddingsModule.percentajeTotalAjust")}`}
                />
            }

        </div>
    )
}

const styles = (theme) => ({
    tab: {
        minHeight: "auto",
    },
    textTab: {
        fontSize: "0.75rem !important"

    },
    sectionsContainer: {
        marginTop: "20px",
        maxHeight: `calc(100vh - ${305}px)`,
        minHeight: `calc(100vh - ${305}px)`,
        overflowY: "auto",
        paddingBottom: "15px"
    },
    flexGrow: {
        flexGrow: 1
    },
    accordionSummary: {
        paddingRight: "0px !important",
        display: "flex",
        backgroundColor: "#f8f9fa"

    },
    accordionDetails: {
        backgroundColor: `${theme.palette.common.white} !important`
    },
    buttonsAcordionSummary: {
        display: "flex",
        justifyItems: "flex-end",
        marginRight: "20px"
    }

});

EvaluationsCriteria.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
}

EvaluationsCriteria.defaultProps = {
    isLoading: false,
}

export default withRouter(
    withStyles(styles, { withTheme: true })(EvaluationsCriteria)
);
