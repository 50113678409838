import React from 'react'
import { useSelector } from 'react-redux'

import { converListToArray } from '../../store/helpers/ListHelper'
import { IsUserInRole, ROLE_CO2_COTROL_SERVICE_EDITOR, ROLE_CO2_CONTROL_REQUEST_BLOCK} from '../../store/helpers/RolesHelper'

import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

/** Custom components import section */
import AdministratorIndex from './AdministratorIndex'
import GeneralIndex from './GeneralIndex'

const Index = (props) => {
	/** Connect with redux */
	const userProfile = useSelector((state) => state.oidc.user)
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)

	function isAdminUser() {
		if (IsUserInRole(ROLE_CO2_COTROL_SERVICE_EDITOR, userRolesByCompany)) return true
		return false
	}

	function isBlocked() {
		if (IsUserInRole(ROLE_CO2_CONTROL_REQUEST_BLOCK, userRolesByCompany)) return true
		return false
	}

	function disabledBlock (userName) {
		if(userProfile.profile.username === userName) return true
		return false
	}

	if (!userProfile) return null

	return <>{isAdminUser() 
		? <AdministratorIndex isBlocked={isBlocked()} isAdminUser={isAdminUser()} disabledBlock={disabledBlock}/> 
		: <GeneralIndex isBlocked={isBlocked()} isAdminUser={isAdminUser()} />}</>
}

Index.propTypes = {}

export default withauthenticatedLayout(Index)
