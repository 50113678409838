import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

/** Material UI import section */
import { Tabs, Tab, withStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

/** Custom components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader';
import StatusInSectionTab from './StatusInSectionTab';

/** Actions import section */
import {
	addServiceStatusToSection,
	removeServiceStatusFromSection
} from '../../../../store/accreditedWorkshops/accreditedWorkshopsActions';
import {
	REALEASED_SERVICES_SECTION,
	NOT_REALEASED_SERVICES_SECTION
} from '../../../../store/helpers/AccreditedWorkshopsHelper';

const ServiceStatusInSectionPanel = (props) => {
	/** Destructuring properties */
	const { classes, onClose } = props;

	/** Connect with redux */
	const dispatch = useDispatch();

	const serviceOrderStatus = allServiceOrderStatus;

	/** Defines local state */
	const [activeTab, setActiveTab] = useState(tabs.ReleasedServices);

	const { configuration, rfc } = props;

	/**
	 * Handle the change of tab
	 */
	const chandleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const addStatusToSection = (status) => {
		const sectionName = activeTab === tabs.ReleasedServices ? REALEASED_SERVICES_SECTION : NOT_REALEASED_SERVICES_SECTION;
		dispatch(addServiceStatusToSection(rfc, status.value, sectionName));
	};

	const removeStatusFromRetention = (status) => {
		const sectionName = activeTab === tabs.ReleasedServices ? REALEASED_SERVICES_SECTION : NOT_REALEASED_SERVICES_SECTION;
		dispatch(removeServiceStatusFromSection(rfc, status, sectionName));
	};

	/**
	 * Render content for current selected tab
	 */
	const renderTabContent = () => {
		switch (activeTab) {
			case tabs.ReleasedServices:
				return (
					<StatusInSectionTab
            sectionName={REALEASED_SERVICES_SECTION}
						serviceOrderStatus={serviceOrderStatus}
						statusInSection={configuration.OrderStatusInSections}
						onServiceOrderStatusChange={addStatusToSection}
						onRemoveServiceStatusButtonClick={removeStatusFromRetention}
					/>
				);
			case tabs.NotReleasedServices:
				return (
					<StatusInSectionTab
            sectionName={NOT_REALEASED_SERVICES_SECTION}
						serviceOrderStatus={serviceOrderStatus}
						statusInSection={configuration.OrderStatusInSections}
						onServiceOrderStatusChange={addStatusToSection}
						onRemoveServiceStatusButtonClick={removeStatusFromRetention}
					/>
				);
			default:
				return (
					<StatusInSectionTab
            sectionName={REALEASED_SERVICES_SECTION}
						serviceOrderStatus={serviceOrderStatus}
						statusInSection={configuration.OrderStatusInSections}
						onServiceOrderStatusChange={addStatusToSection}
						onRemoveServiceStatusButtonClick={removeStatusFromRetention}
					/>
				);
		}
	};

	return (
		<LateralPanel onClose={onClose} classes={classes}>
			<PanelHeader
				isIconMaterial
				titlePanel={'Estatus de ordenes de servicios'}
				onCloseButtonClick={onClose}
				icon={<SettingsIcon color="primary" />}
			/>
			<div className={classes.bodyContainer}>
				<Tabs value={activeTab} onChange={chandleTabChange} aria-label="Tabs to show services or configuration">
					<Tab label={<Translate id="accreditedWorkshops.serviceOrders" />} {...a11yProps(tabs.ReleasedServices)} />
					<Tab
						label={<Translate id="accreditedWorkshops.notReleasedServices" />}
						{...a11yProps(tabs.NotReleasedServices)}
					/>
				</Tabs>
				<div className={classes.tabContainer}>{renderTabContent()}</div>
			</div>
		</LateralPanel>
	);
};

ServiceStatusInSectionPanel.propTypes = {
	onClose: PropTypes.func.isRequired
};

const tabs = {
	ReleasedServices: 'releasedServices',
	NotReleasedServices: 'notReleasedServices'
};

const a11yProps = (id) => {
	return {
		id: `tab-${id}`,
		value: `${id}`,
		'aria-controls': `simple-tabpanel-${id}`
	};
};

const styles = (theme) => ({
	bodyContainer: {
		padding: theme.spacing.unit * 2,
		overflowX: 'auto'
	},
	tabContainer: {
		padding: 10
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5
		}
	}
});

export default withStyles(styles, { withTheme: true })(ServiceStatusInSectionPanel);

const allServiceOrderStatus = [
	{ value: '10', label: '10 - Nuevo' }, //New = ;
	{ value: '11', label: '11 - Aceptado' }, //Accepted = ;
	{ value: '12', label: '12 - Rechazado' }, //Rejected = ;
	{ value: '13', label: '13 - Enviado a SAP' }, //SentToSAP = ;
	{ value: '14', label: '14 - En pedido' }, //Request = ;
	{ value: '15', label: '15 - Facturado' }, //Invoiced = ;
	{ value: '16', label: '16 - Con error' }, //Error = ;
	{ value: '17', label: '17 - Incobrable' }, //Uncollectible = ;
	{ value: '27', label: '27 - Cerrado' }, //Closed = ;
	{ value: '20', label: '20 - Archivado' }, //Archived = ;
	{ value: '51', label: '51 - Factura cancelada' } //CanceledInvoice = ;
];
