
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

/** Import component section */
import Select from 'react-select'
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import { GetContainerType } from '../../../store/helpers/SelectOptions';

/**
 * Dialog to add cubicaje dato to quote items
 */
class CubicajeQuoteDialog extends Component {

    /**
   *Creates an instance of DialogActions.
   * @param {*} props
   * @memberof DialogActions
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            cubicaje: {}
        }
    }

    /**
     * React life cycle 
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof CubicajeQuoteDialog
     */
    componentDidUpdate(prevProps, prevState) {
        //Se verifica si esta establecido un token de item, entonces se establece la informacion de cubicaje de ese item
        if (prevProps.quoteItemToken !== this.props.quoteItemToken && Object.keys(prevState.cubicaje).length === 0) {
            let index = this.props.detailsList.findIndex((item) => {
                return item.token === this.props.quoteItemToken
            });
            if (index >= 0) {
                this.setState({
                    cubicaje: this.props.detailsList[index].cubicaje
                })
            }
        }
    }

    /**
     * Function to update cubicaje property of text field in state
     *
     * @param {*} data
     * @memberof CubicajeQuoteDialog
     */
    updateProperty(data) {
        if (data.id === "quantity_per_container"
            || data.id === "number_pieces")
            data.value = parseInt(data.value)
        if (data.value)
            this.setState({
                cubicaje: {
                    ...this.state.cubicaje,
                    [data.id]: data.value,
                }
            })
    }

    /**
     * Function to update cubicaje property of select in state
     *
     * @param {*} data
     * @param {*} property
     * @memberof CubicajeQuoteDialog
     */
    updateSelectProperty(data, property) {
        this.setState({
            cubicaje: {
                ...this.state.cubicaje,
                [property]: data.value
            }
        })
    }

    /**
     * On save cubicaje quote, call saveCubicajeItem action from props
     *
     * @memberof CubicajeQuoteDialog
     */
    onSaveCubicajeQuote() {
        if (this.state.cubicaje) {
            this.props.saveCubicajeItem(this.props.quoteItemToken, this.state.cubicaje)
        }
    }

    /**
     * Handle  Close or cancel event
     *
     * @memberof CubicajeQuoteDialog
     */
    handleCloseCancel() {
        this.setState({ cubicaje: {} }, () => this.props.onClose());
    }

    /**
     * Method to validate if required properties has data
     *
     * @returns
     * @memberof CubicajeQuoteDialog
     */
    isValidToSave() {
        if (this.state.cubicaje === null || this.state.cubicaje === undefined || Object.keys(this.state.cubicaje).length <= 0)
            return false
        if ((this.state.cubicaje.container_type && this.state.cubicaje.container_type !== null && this.state.cubicaje.container_type !== '')
            && (this.state.cubicaje.quantity_per_container && this.state.cubicaje.quantity_per_container !== null && this.state.cubicaje.quantity_per_container !== '')
            && (this.state.cubicaje.number_pieces && this.state.cubicaje.number_pieces !== null && this.state.cubicaje.number_pieces !== '')
            && (this.state.cubicaje.width && this.state.cubicaje.width !== null && this.state.cubicaje.width !== '')
            && (this.state.cubicaje.length && this.state.cubicaje.length !== null && this.state.cubicaje.length !== '')
            && (this.state.cubicaje.heigth && this.state.cubicaje.heigth !== null && this.state.cubicaje.heigth !== '')
            && (this.state.cubicaje.weight && this.state.cubicaje.weight !== null && this.state.cubicaje.weight !== '')) {
            return true;
        }
        return false
    }

    /**
     * Method to render text field 
     *
     * @param {*} textFieldId
     * @param {*} textFieldTranslateId
     * @param {*} required
     * @param {*} type
     * @param {*} multiple
     * @param {*} variant
     * @returns
     * @memberof CubicajeQuoteDialog
     */
    renderOnlyTextField(textFieldId, textFieldTranslateId, required, type, multiple, variant) {
        return (
            <TextField
                id={textFieldId}
                label={<Translate id={textFieldTranslateId}/>}
                value={this.state.cubicaje[textFieldId] ? this.state.cubicaje[textFieldId] : ''}
                margin="normal"
                type={type ? type : "number"}
                fullWidth
                required={required ? required : false}
                onBlur={(event) => this.updateProperty(event.target)}
                onChange={(event) => this.updateProperty(event.target)}
                onKeyDown={this.onKeyDown}
                multiline={multiple ? multiple : false}
                rowsMax={multiple ? 5 : 1}
                variant={variant}
            />
        )
    }

    /**
     * Dialog to update information
     */
    render() {
        const { classes } = this.props;
        let optionsContainerType = GetContainerType();
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleCloseCancel()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id="quotes.containerType" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <div>
                        {!this.isValidToSave() &&
                            <Typography variant="caption" color='error'>
                                {<Translate id="common.requiredFields" />}
                            </Typography>
                        }
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => this.handleCloseCancel()}
                            onClose={() => this.handleCloseCancel()}
                            onSaveItem={this.onSaveCubicajeQuote}
                            isSaving={this.props.isSaving}
                            canBeSaved={this.isValidToSave()}
                        />
                    </div>
                }>

                <form className={classes.modal} >
                    <Select
                        id={'container_type'}
                        fullWidth
                        required
                        options={optionsContainerType}
                        defaultValue={optionsContainerType[this.state.cubicaje['container_type'] ? this.state.cubicaje['container_type'] : '']}
                        onChange={(option) => this.updateSelectProperty(option, 'container_type')}
                    />
                    {this.renderOnlyTextField('quantity_per_container', 'quotes.quantityPerContainer', true)}
                    {this.renderOnlyTextField('number_pieces', 'quotes.numberPieces', true)}
                    {this.renderOnlyTextField('width', 'quotes.width', true, 'text')}
                    {this.renderOnlyTextField('length', 'quotes.length', true, 'text')}
                    {this.renderOnlyTextField('heigth', 'quotes.heigth', true, 'text')}
                    {this.renderOnlyTextField('weight', 'quotes.weight', true, 'text')}
                </form>
            </Dialog>
        )
    }
}

const styles = theme => ({
    modal: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        // width: 500,
        // marginTop: 10,
        // marginBottom: 10
    }
});

export default withRouter(withStyles(styles, { withTheme: true })(CubicajeQuoteDialog));