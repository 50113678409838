import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import { useParams } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

import { AppBar, Avatar, Card, CardContent, CardHeader, Divider, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { CreditCard } from '@material-ui/icons'

import { loadRequestPriceChangeFromBackEnd } from '../../store/requestPriceChange/requestPriceChangeAction'

import * as selectors from '../../store/requestPriceChange/requestPriceChangeSelector'
import LoadingPage from '../common/LoadingPage'
import SuppliersCard from '../alternativeProvedure/components/SuppliersCard'
import CostSummary from '../alternativeProvedure/components/CostSummary'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const RequestPriceChangeDetail = ({ classes }) => {
	const history = useHistory()
	const params = useParams()
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState(0)
	const requestPriceChangeDraft = useSelector((state) => selectors.getRequestPriceChangeDraft(state))
	const userProfile = useSelector((state) => state.oidc.user)
	const isLoading = useSelector((state) => selectors.getIsLoading(state))

	useEffect(() => {
		const token = params.token
		if (token) dispatch(loadRequestPriceChangeFromBackEnd(token))
	}, [])
	const onClickToActiveTab = (event, value) => {
		setActiveTab(value)
	}
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<CreditCard />
					</Avatar>
				}
				title={
					<Typography variant='button'>
						<Translate id='priceChangeRequest.detail.title' />{' '}
						<strong>
							{requestPriceChangeDraft && requestPriceChangeDraft.folio
								? requestPriceChangeDraft.folio
								: ''}
						</strong>
					</Typography>
				}
			/>
			<CardContent>
				{isLoading || !(requestPriceChangeDraft && requestPriceChangeDraft.suppliers) ? (
					<LoadingPage />
				) : (
					<Grid container spacing={24}>
						<Grid item xs={6}>
							<InformationMemoCard request={requestPriceChangeDraft} />
						</Grid>
						<Grid item xs={6}>
							<InformationProgressCard request={requestPriceChangeDraft} />
						</Grid>
						<Grid item xs={12}>
							<AppBar position='static' color='default'>
								<Tabs
									indicatorColor='secondary'
									textColor='secondary'
									className={classes.styleTab}
									value={activeTab}
									onChange={onClickToActiveTab}
								>
									<Tab label={<Translate id='common.supplier' />} />
									<Tab label={<Translate id='common.costSummary' />} />
								</Tabs>
							</AppBar>
							{activeTab === 0 && (
								<Grid item xs={12}>
									<Divider />
									<SuppliersCard provedure={requestPriceChangeDraft} history={history} />
								</Grid>
							)}
							{activeTab === 1 && (
								<Grid item xs={12}>
									<Divider />
									<CostSummary
										provedure={requestPriceChangeDraft}
										user={userProfile}
										history={history}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	)
}
const InformationMemoCard = ({ request }) => {
	return (
		<Paper>
			<List>
				<ListItem divider>
					<ListItemText
						primary={
							<Typography variant='subtitle1' gutterBottom>
								<Translate id='alternatingProveeduria.folio' />
							</Typography>
						}
						secondary={<React.Fragment>{request.folio}</React.Fragment>}
					/>
				</ListItem>
				<ListItem divider>
					<ListItemText
						primary={
							<Typography variant='subtitle1' gutterBottom>
								<Translate id='memos.stage' />
							</Typography>
						}
						secondary={<React.Fragment>{request.stage}</React.Fragment>}
					/>
				</ListItem>
				<ListItem divider>
					<ListItemText
						primary={
							<Typography variant='subtitle1' gutterBottom>
								<Translate id='common.creationDate' />
							</Typography>
						}
						secondary={<React.Fragment>{request.creation_date_long}</React.Fragment>}
					/>
				</ListItem>
			</List>
		</Paper>
	)
}
const InformationProgressCard = ({ request }) => {
	return (
		<Paper>
			<List>
				<ListItem divider>
					<ListItemText
						primary={
							<Typography variant='subtitle1' gutterBottom>
								<Translate id='common.status' />
							</Typography>
						}
						secondary={<React.Fragment>{request.status_description}</React.Fragment>}
					/>
				</ListItem>
			</List>
		</Paper>
	)
}
const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	actionsToolbar: {
		display: 'flex',
	},
	styleTab: {
		backgroundColor: '#fff',
	},
})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(RequestPriceChangeDetail)))
