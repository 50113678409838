import { fromJS} from 'immutable';

import * as failuerGuaranteActions from './failureGuaranteeAction';

/**
 *  Initial state
 */
export const moduleGuaranteInitialState = fromJS({
    isLoadingFailureGuarantee: true,
    failureGuaranteeData: [],
    isSavingFailureGuarantee: false,
    sortCriteria: {
        by: "name",
        ascending: false
    },
    searchQuery: "",
    failureGuaranteeCount: null
})

export default function moduleGuaranteeReducer(state = moduleGuaranteInitialState, action) {
    switch (action.type) {
        case failuerGuaranteActions.ADD_FAILURE_GUARANTEE_TO_LIST: {
            let failureGuarantee = fromJS(action.failureGuarantee);
            let failureGuaranteeList = state.get('failureGuaranteeData').push(failureGuarantee);
            return state.merge({
                failureGuaranteeData: failureGuaranteeList,
                isSavingFailureGuarantee: false
            });
        }
        case failuerGuaranteActions.SET_FAILURE_GUARANTEE_TO_LIST:{
            let failureGuaranteeLists = fromJS(action.failureGuaranteeData)
            return state.merge({
                failureGuaranteeData: failureGuaranteeLists,
                isLoadingFailureGuarantee: false
            })
        }
        case failuerGuaranteActions.SET_FAILURE_GUARANTEE_COUNT:{
            return state.merge({
                failureGuaranteeCount: action.count
            })
        }
        case failuerGuaranteActions.CHANGE_FAILURE_GUARANTEE_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case failuerGuaranteActions.CHANGE_FAILURE_GUARANTEE_SORT_QUERY:{
            return state.merge({
                searchQuery: action.query
            })
        }
        case failuerGuaranteActions.IS_SAVING_FAILURE_GUARANTEE: {
            return state.merge({
                isSavingFailureGuarantee: action.isSaving
            })
        }
        default:
            return state;
    }

}