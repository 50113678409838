///Import react section
import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

/** Material-UI imports section */
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { Translate, getTranslate } from 'react-localize-redux'

/** Import component section */
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import DialogOperation from '../common/DialogOperation'
import AddCommonItem from '../common/AddCommonItem'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import SparePartPriceDetail from './SparePartPriceDetail'
import HeaderRequest from './components/HeaderRequest'

/** Helpers */
import {
	TableConfigurationOperations,
	existListItems,
	onTitleStatusDialog,
	onMessageStatusDialog,
} from '../../store/helpers/SparePartPriceChangeHelper'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'

import {
	loadRequestSparePartPriceChangeFromBackEnd,
	deleteRequest,
	changeStatusFromRequest,
	updateMaterialToChangePrice,
	acceptRejectMaterialInList,
	updateSparePartPriceChange,
	uploadSparePartPriceFiles,
	removeSparePartPriceFiles,
	generatedPDFRequest,
} from '../../store/sparePartPriceChange/sparePartPriceChangeAction'
import { loadPartnership, loadSupplier } from '../../store/suppliers/SupplierActions'

const Show = (props) => {
	/**Render hooks section */
	const dispatch = useDispatch()
	const translate = useSelector((state) => getTranslate(state.localize))

	/**Redux store information section*/
	const [openDialogOperation, setDialogOperation] = useState(false)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	const userProfile = useSelector((state) => state.oidc.user)
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')
	const isLoading = useSelector((state) => state.sparePartPriceChange.get('isLoading'))
	const isSaving = useSelector((state) => state.sparePartPriceChange.get('isSaving'))
	const requestPriceChange = useSelector((state) =>
		state.sparePartPriceChange.get('requestPriceChange')
			? state.sparePartPriceChange.get('requestPriceChange').toJS()
			: null
	)
	const supplier = useSelector((state) =>
		state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null
	)
	const partnership = useSelector((state) =>
		state.suppliers.get('partnership') ? state.suppliers.get('partnership').toJS() : null
	)
	const [openDialogConfirm, setDialogConfirm] = useState({ open: false, status: null })

	/**Use effect section */
	useEffect(() => {
		dispatch(loadRequestSparePartPriceChangeFromBackEnd(props.match.params.token)).then((response) => {
			dispatch(loadPartnership(response.supplierNumber, response.companyCode))
			dispatch(loadSupplier(response.supplierToken))
		})
	}, [dispatch, props.match.params.token])

	const onDialogOperation = () => {
		setDialogOperation(!openDialogOperation)
	}

	const onSetToaster = (status) => {
		setToasterMessageConfig({
			showToaster: true,
			toasterVariant: status === 'success' ? 'success' : 'error',
			toasterMessage: status === 'success' ? 'common.saveCorrectly' : 'common.errorToSave',
		})
	}

	const onOpenConfirmDialog = (status) => {
		setDialogConfirm({ open: true, status: status })
	}

	const onClosedDialog = () => {
		setDialogConfirm({ open: false, status: null })
	}

	const onDeleteRequest = () => {
		return dispatch(deleteRequest(requestPriceChange.token))
			.then(() => {
				onSetToaster('success')
				props.history.push(`/sparePartPriceChange`)
			})
			.catch(() => {
				onSetToaster('error')
			})
	}

	const onUpdateMaterialToPriceChange = (itemToken, item) => {
		return dispatch(updateMaterialToChangePrice(itemToken, requestPriceChange.token, item)).catch(() => {
			onSetToaster('error')
		})
	}

	const onAcceptRejectAction = (itemToken, status, comments) => {
		return dispatch(acceptRejectMaterialInList(requestPriceChange.token, itemToken, status, comments)).catch(() => {
			onSetToaster('error')
		})
	}

	const onUpdateSparePartPriceChange = (property, data) => {
		return dispatch(updateSparePartPriceChange(requestPriceChange.token, property, data)).catch(() => {
			onSetToaster('error')
		})
	}

	const onUploadFile = (files) => {
		return dispatch(uploadSparePartPriceFiles(requestPriceChange.token, files)).catch(() => {
			onSetToaster('error')
		})
	}

	const onDeleteFile = (fileName) => {
		return dispatch(removeSparePartPriceFiles(requestPriceChange.token, fileName))
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch(() => {
				onSetToaster('error')
			})
	}

	const onDownloadPDF = () => {
		dispatch(generatedPDFRequest(requestPriceChange.token, partnership, supplier))
	}

	const onChangeStatusRequest = () => {
		return dispatch(changeStatusFromRequest(requestPriceChange.token, openDialogConfirm.status))
			.then(() => {
				onSetToaster('success')
				onClosedDialog()
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'warning',
					toasterMessage: !IsNullOrEmpty(response)
						? !IsNullOrEmpty(response.data.Message)
							? response.data.Message
							: 'common.errorToSave'
						: 'common.errorToSave',
				})
			})
	}

	return (
		<Card>
			<HeaderRequest
				requestPriceChange={requestPriceChange}
				onDialogOperation={onDialogOperation}
				isInternalUser={isInternalUser()}
				isSaving={isSaving}
				onDownloadPDF={onDownloadPDF}
			/>
			<CardContent>
				{isLoading || !requestPriceChange ? (
					<LoadingPage />
				) : (
					<Grid container spacing={24}>
						<Typography>{'Lista de materiales para realizar el cambio'}</Typography>
						{requestPriceChange && requestPriceChange.status === 1 && (
							<Typography>{'. Seleccione la lista de materiales para actualizar precios'}</Typography>
						)}
						<SparePartPriceDetail
							requestPriceChange={requestPriceChange}
							onUpdateMaterialToPriceChange={onUpdateMaterialToPriceChange}
							onAcceptRejectAction={onAcceptRejectAction}
							isInternalUser={isInternalUser()}
							onUpdateSparePartPriceChange={onUpdateSparePartPriceChange}
							onUploadFile={onUploadFile}
							onDeleteFile={onDeleteFile}
							isSaving={isSaving}
							userProfile={userProfile}
						/>
					</Grid>
				)}
			</CardContent>

			<ComponentChangeStatus
				onDialogOperation={onDialogOperation}
				openDialogOperation={openDialogOperation}
				canbeUpdate={isInternalUser()}
				onTableConfiguration={TableConfigurationOperations}
				status={requestPriceChange ? requestPriceChange.status : null}
				onDeleteRequest={onDeleteRequest}
				onOpenChangeStatus={onOpenConfirmDialog}
				existListItems={existListItems(requestPriceChange)}
			/>
			{openDialogConfirm.open && (
				<AddCommonItem
					title={<Translate id={onTitleStatusDialog(openDialogConfirm.status)} />}
					message={translate(onMessageStatusDialog(openDialogConfirm.status))}
					open={openDialogConfirm.open}
					onClose={onClosedDialog}
					onSaveItem={onChangeStatusRequest}
					isSaving={isSaving}
					saveButtonTranslate={'common.confirm'}
					isMessage={true}
				/>
			)}
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</Card>
	)
}

const ComponentChangeStatus = (props) => {
	const {
		status,
		openDialogOperation,
		onDialogOperation,
		onOpenChangeStatus,
		canbeUpdate,
		onTableConfiguration,
		onDeleteRequest,
		existListItems,
	} = props

	let options = onTableConfiguration(canbeUpdate, status, onOpenChangeStatus, onDeleteRequest, existListItems)

	return (
		<>
			{openDialogOperation && (
				<DialogOperation open={openDialogOperation} onClose={onDialogOperation} options={options} />
			)}
		</>
	)
}
ComponentChangeStatus.propTypes = {
	onOpenChangeStatus: propTypes.func.isRequired,
}

const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
	},
	margin: {
		margin: theme.spacing.unit,
	},
	logoImg: {
		maxHeight: 85,
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
