import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";

/** Import component section */
import LoadingPage from '../../common/LoadingPage'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';

import DataTable from '../../common/DataTable';

/** Material Ui imports section */
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import InvoicesIcon from '@material-ui/icons/ListAltTwoTone';

import { getInventoryMaquilaFromSAP, loadSupplier, donwlodInventoryMaquilaFromSAP } from '../../../store/suppliers/SupplierActions'
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';

/**
 * Supplier list 
 *
 * @class Suppliers list
 * @extends {Component}
 */
class InventoryMaquila extends Component {

    /**
     * Create an instance of suppliers list
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            sortCriteria: {
                by: "status",
                ascending: false
            },
            isSearching: false,
        }
    }

       /**
     * componentDidMount
     */
    componentDidMount(){
        let supplierToken = this.props.match.params.token;
        this.props.loadSupplier(supplierToken).then(()=>{
            if(this.props.supplier){
                let supplierCode = this.props.supplier.code;
                this.props.getInventoryMaquila(supplierCode);
            }
        });
    }

    /**
     * On serch Inventory Maquila
     */
    onSearchClick(){
        if (this.props.supplier) {
            let supplierCode = this.props.supplier.code;
            this.props.getInventoryMaquila(supplierCode);
        }
    }

    /**
     * on downlod click
     */
    onDonwlodClick(){
        if (this.props.supplier) {
            let supplierCode = this.props.supplier.code;
            this.props.donwlodInventoryMaquila(supplierCode);
        }
    }

    /**
     * On change sort criteria
     *
     * @param {*} sortBy
     * @param {*} sortAscending
     * @memberof Index
     */
    onChangeSortCriteria(sortBy, sortAscending) {
        let sortCriteria = {
            by: sortBy,
            ascending: sortAscending
        }
        this.setState({
            sortCriteria
        })
    }

    /**
     * Get card content
     *
     * @returns
     * @memberof Invoices
     */
    getCardContent() {
        const { sortCriteria } =this.state;
        if (this.props.isLoadingInventoryMaquilas) {
            return (
                <LoadingPage />
                )
        } else if (this.props.inventoryMaquilas) {
            return (
                <Paper style={{overflowX:'auto'}}>
                <DataTable 
                    data = {this.props.inventoryMaquilas}
                    configuration = {TableConfiguration}
                    onRenderCellItem={this.onRenderCellItem}
                    mode={"clientSide"}
                    sortBy={sortCriteria.by}
                    sortAscending={sortCriteria.ascending}
                    onChangeSortCriteria={this.onChangeSortCriteria}
                    showFilters
                    widthFilters={200}
                />
                </Paper>
            )
        }

    }

    /**
     * Render
     *
     * @returns
     * @memberof Supplier
     */
    render() {
        const { classes } = this.props;
        return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar color="primary" >
                            <InvoicesIcon />
                        </Avatar>
                    }
                    title={<span><Translate id= "menu.consults"/> | <Translate id= "queries.inventoryMaquila.inventoryMaquila"/></span>}
                    subheader={<span>
                        {this.props.supplier ? this.props.supplier.full_name : ""}
                        <div className={classes.toolbar}>
                            {this.props.inventories === null && 
                            <Button variant="contained" size="small" color="default" disabled={this.props.isLoadingInventoryMaquilas} style={{marginLeft: "3px"}} onClick={this.onSearchClick}> <Translate id="common.getData" /></Button>}
                            <Button variant="contained" size="small" color="default" disabled={this.props.isDownloadQueries} style={{marginLeft: "3px"}} onClick={this.onDonwlodClick}> <Translate id="common.download" /></Button>
                        </div>
                    </span>
                    }
                />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>
            </Card>
        )
    }
}

const TableConfiguration = {
    columns: [{
        header: <Translate id="common.partNumber"/>,
        dataSource: 'part_number',
        isSortable:true
    },{
        header: <Translate id="common.descriptionPartNumber"/>,
        dataSource: 'part_desription',
        isSortable:true
    },{
        header: <Translate id="queries.inventoryMaquila.stockwarehouseSupplier"/>,
        dataSource: 'stock_valued',
        isSortable:true
    },{
        header: <Translate id="common.unitMeasure"/>,
        dataSource: 'unit_measurement'
    }]
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    return {
        supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
        isLoadingInventoryMaquilas: state.suppliers.get('isLoadingInventoryMaquilas'),
        inventoryMaquilas: state.suppliers.get('inventoryMaquilas') ? state.suppliers.get('inventoryMaquilas').toJS(): null,
        isDownloadQueries: state.suppliers.get('isDownloadQueries'),
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadSupplier:(token) =>{
            return dispatch(loadSupplier(token))
        },
        getInventoryMaquila:(supplierCode) => {
            dispatch(getInventoryMaquilaFromSAP(supplierCode))
        },
        donwlodInventoryMaquila:(supplierCode) =>{
            dispatch(donwlodInventoryMaquilaFromSAP(supplierCode))
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex",
        flexGrow: 1,
        width: "750px",
        margin: theme.spacing.unit,
    },
    documentTypeSelect: {
        width: "180px",
        marginRight: "5px"
    }
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(InventoryMaquila))));
