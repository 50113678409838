import { fromJS } from 'immutable'
import * as securityCoursesActions from './securityCoursesActions'

/**
 * contraActions initial state
 */
export const securtyCoursesInitialState = fromJS({
	isLoading: false,
	isLoadingVideo: false,
	identifier: '',
	securityCoursesPaginated: {
		items: [],
		pageNumber: 0,
		pageSize: 20,
		total: 0,
		sort: {
			by: 'ActiveDate',
			direction: 'desc',
		},
	},
	detail: null,
})
export default function companiesReducer(state = securtyCoursesInitialState, action) {
	switch (action.type) {
		case securityCoursesActions.IS_SAVING: {
			return state.merge({
				isLoading: action.isSaving,
				identifier: action.identifier,
			})
		}
		case securityCoursesActions.IS_LOADING_VIDEO: {
            console.log('IS_LOADING')
			return state.merge({
				isLoadingVideo: action.isLoadVideo,
			})
		}
		case securityCoursesActions.SET_SECURITY_COURSES: {
			return state.merge({
				securityCoursesPaginated: fromJS(action.courses),
				isLoading: false,
			})
		}
		case securityCoursesActions.SET_SECURITY_COURSE: {
			const course = fromJS(action.course).toJS()
			let coursesUpdate = state.getIn(['securityCoursesPaginated', 'items']).toJS()
			coursesUpdate.push(course)
			let securityCoursesPaginated = state.get('securityCoursesPaginated').set('items', coursesUpdate)
			return state.merge({
				isLoading: false,
				securityCoursesPaginated: securityCoursesPaginated,
			})
		}
		case securityCoursesActions.SET_DETAIL_COURSE: {
			return state.merge({
				detail: fromJS(action.course),
			})
		}
		default:
			return state
	}
}
