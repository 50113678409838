///Import react section
import React, { useEffect, useState, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Translate, getTranslate } from 'react-localize-redux'
import queryString from 'query-string'
/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AddIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import BillingIcon from '@material-ui/icons/LocalAtm'
import Fab from '@material-ui/core/Fab'
import DownloadIcon from '@material-ui/icons/CloudDownload'
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import SearchInput from '../common/SearchInput/SearchInput'
import OptionsMenu from '../common/OptionsMenu'
import LateralPanelContainer from '../common/LateralPanelComponents/LateralPanelContainer'
import AddBillingComponent from './components/index/AddBilling'
import { searchUsersInRole, loadInternalUsersFromBackEnd } from '../../store/administration/InternalActions'
/**import helper section */
import { converListToArray } from '../../store/helpers/ListHelper'
import { canBeModuleBidding } from '../../store/helpers/RolesHelper'
import { ROLE_BIDDING_RESPONSIBLE, IsUserInRole } from '../../store/helpers/RolesHelper'
import { setToasterOptions } from '../../store/indirectqualifications/IndirectqualificationsActions'
/**Custom hooks index */
import { useBillingsCustomHookIndex } from './customHooks/UseCustomHookIndex'

const IndexView = (props) => {
	const { classes } = props
	/**Render hooks section */
	// const classes = styles()
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()
	const queryParams = queryString.parse(location.search)
	const { billings, onAddBilling } = useBillingsCustomHookIndex()

	/**Redux store information section*/
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const isLoading = useSelector((state) => state.billings.get('isLoading'))
	const isSaving = useSelector((state) => state.billings.get('isSaving'))
	const billingsCount = useSelector((state) => state.billings.get('billingsCount'))
	const isDownloadingReport = useSelector((state) => state.billings.get('isDownloading'))
	const toasterMessageConfig = useSelector((state) => state.indirectQualifications.get('toasterConfiguration').toJS())
	const translate = useSelector((state) => getTranslate(state.localize))

	/**Store local this component */
	const [showMenu, setShowMenu] = useState(true)
	const [itemToken, setItemToken] = useState('')

	const [identifierPanelLateral, setIdentifierPanelLateral] = useState('')

	/**Use effect section */
	useEffect(() => {
		dispatch(loadInternalUsersFromBackEnd())
		dispatch(searchUsersInRole(ROLE_BIDDING_RESPONSIBLE))
	}, [dispatch])

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	const getCounterMessage = () => {
		if (billings) {
			let totalBillings = billings.length
			return (
				<div>
					{<Translate id='common.showing' />}
					<span className={classes.showingCounter}>{totalBillings}</span>&nbsp;
					{<Translate id='common.of' />}
					<span className={classes.showingCounter}>{billingsCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	const handleCloseOptionsMenu = () => {
		setShowMenu(false)
		setItemToken('')
	}

	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof Index
	 */
	const handleClickOptionsMenu = (token) => {
		setItemToken(token)
	}

	const renderAction = () => {
		var options = []
		options.push({
			//itemClick: () => onDownloadReport(),
			tooltipTranslation: <Translate id='common.downloadReport' />,
			menuItemIcon: <DownloadIcon color='secondary' />,
			isLoading: isDownloadingReport,
		})
		if (showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={handleClickOptionsMenu}
					open={itemToken === 'item.token'}
					handleClickAway={handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * onRowClick
	 * @param {*} calendar
	 * @param {*} event
	 */
	const onRowClick = (item, event) => {
		let tagName = event.target.tagName
		if (tagName !== 'svg' && tagName !== 'A') {
			props.history.push(`/billing/${item.token}`)
		}
	}

	const onAddBillingFromBackend = (model) => {
		return onAddBilling(model).then(() => {
			onOpenAndCloseLateralPanel()
		})
	}

	const PanelLateralChildren = useCallback(
		{
			IndexCreateBilling: (
				<AddBillingComponent
					isSaving={isSaving}
					onSaveButtonClick={onAddBillingFromBackend}
					translate={translate}
				/>
			),
		},
		[identifierPanelLateral]
	)

	const { LateralPanelComponent, onOpenAndCloseLateralPanel } = LateralPanelContainer({
		children: PanelLateralChildren[identifierPanelLateral],
		identifier: identifierPanelLateral,
	})

	const onLateralPanelConfig = (identifier) => {
		setIdentifierPanelLateral(identifier)
		onOpenAndCloseLateralPanel()
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	const getCardContent = () => {
		if (isLoading === true) {
			return <LoadingPage />
		} else if (billings === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<>
					<DataTable
						data={billings}
						configuration={TableConfiguration(canBeModuleBidding(userRolesByCompany))}
						onRenderCellItem={onRenderCellItem}
						onChangeSortCriteria={onChangeSortCriteria}
						onRowClick={onRowClick}
						isIndex
						showFilters
						totalRows={billingsCount}
						sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'FormatedFolio'}
						sortAscending={
							queryParams && queryParams.sortDirection
								? queryParams.sortDirection === 'desc'
									? false
									: true
								: false
						}
						page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
						rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				</>
			)
		}
	}
	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'Name') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item.Name}
				</Typography>
			)
		}
	}

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<BillingIcon />
					</Avatar>
				}
				action={
					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '5px' }}>
							<SearchInput
								onChangeSearchValue={onChangeSearchValue}
								value={!!queryParams && queryParams.query ? queryParams.query : ''}
							/>
						</div>

						{IsUserInRole(ROLE_BIDDING_RESPONSIBLE, userRolesByCompany) && (
							<Fab
								size='small'
								color='primary'
								onClick={() => {
									onLateralPanelConfig('IndexCreateBilling')
								}}
							>
								<AddIcon />
							</Fab>
						)}
						{IsUserInRole(ROLE_BIDDING_RESPONSIBLE, userRolesByCompany) && renderAction()}
					</div>
				}
				title={<Typography variant='button'>{<Translate id='billings.title' />}</Typography>}
				subheader={getCounterMessage()}
			/>

			<CardContent>{getCardContent()}</CardContent>

			<Toaster
				message={
					<Translate id={toasterMessageConfig.toasterMessage} data={{ name: toasterMessageConfig.name }} />
				}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					dispatch(
						setToasterOptions({
							showToaster: false,
							toasterMessage: '',
							toasterVariant: '',
						})
					)
				}}
			/>

			<LateralPanelComponent />
		</Card>
	)
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='billings.table.status' />,
			dataSource: 'actual_operation_description',
		},
		{
			header: <Translate id='billings.table.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='billings.table.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='billings.create.reference' />,
			dataSource: 'reference',
			isSortable: true,
		},
		{
			header: <Translate id='billings.create.folioType' />,
			dataSource: 'folio_type_description',
			isSortable: true,
		},
		{
			header: <Translate id='billings.table.createdName' />,
			dataSource: 'create_by',
			isSortable: true,
		},
		{
			header: <Translate id='billings.create.supplier' />,
			dataSource: 'supplier_name',
			isSortable: true,
		},
	]

	return { columns }
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	showingCounter: {
		marginLeft: '5px',
	},
	biddingContainer: {
		maxHeight: `calc(100vh - ${275}px)`,
		minHeight: `calc(100vh - ${275}px)`,
		overflowY: 'hidden',
		backgroundColor: 'red',
	},
	categoryIcon: {
		marginLeft: '5px',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(IndexView)))
