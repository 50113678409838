import { fromJS } from 'immutable';

import * as sustainabilityActions from './SustainabilityActions';

/**
 *  Initial state
 */
export const sustainabilityInitialState = fromJS({
    //Pagination and search
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,

    //Index
    isLoadingQuestionnaires: true,
    questionnaires: [],
    questionnairesCount: 0,

    //Single questionnaire
    isLoadingQuestionnaire: true,
    isSavingQuestionnaire: false,
    questionnaire: null,
})

export default function sustainabilityReducer(state = sustainabilityInitialState, action) {
    switch (action.type) {
        case sustainabilityActions.SET_SUSTAINABILITY_QUESTIONNAIRES_COUNT: {
            return state.merge({
                questionnairesCount: action.count
            })
        }
        case sustainabilityActions.SET_SUSTAINABILITY_QUESTIONNAIRES_LIST: {
            let questionnairesLists = fromJS(action.questionnaires);
            return state.merge({
                questionnaires: questionnairesLists,
                isLoadingQuestionnaires: false
            })
        }
        case sustainabilityActions.CHANGE_SUSTAINABILITY_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case sustainabilityActions.CHANGE_SUSTAINABILITY_QUERY: {
            return state.merge({
                searchQuery: action.query,
                page: 0,
                rowsPerPage: state.rowsPerPage
            })
        }
        case sustainabilityActions.CHANGE_SUSTAINABILITY_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case sustainabilityActions.IS_SAVING_QUESTIONNAIRE: {
            return state.merge({
                isSavingQuestionnaire: action.isSavingQuestionnaire
            })
        }
        case sustainabilityActions.IS_LOADING_QUESTIONNAIRE: {
            return state.merge({
                isLoadingQuestionnaire: action.isLoadingQuestionnaire
            })
        }
        case sustainabilityActions.SET_SUSTAINABILITY_QUESTIONNAIRE: {
            let questionnaire = fromJS(action.questionnaire);
            return state.merge({
                questionnaire,
                isLoadingQuestionnaire: false
            })
        }
        case sustainabilityActions.UPDATE_WORKERS_LIST: {
            return state.updateIn(['questionnaire', 'workers'], (allWorkers) => {
                let index = allWorkers.findIndex((item) => {
                    return item.get('token') === action.worker.token
                });
                return allWorkers.setIn([index], fromJS(action.worker))
            }).set('isSavingQuestionnaire', false);
        }
        case sustainabilityActions.UPDATE_QUESTION_IN_LIST: {
            return state.updateIn(['questionnaire', action.listName], (allQuestions) => {
                let index = allQuestions.findIndex((item) => {
                    return item.get('token') === action.updatedQuestion.token
                });
                return allQuestions.setIn([index], fromJS(action.updatedQuestion))
            }).set('isSavingQuestionnaire', false);
        }
        default:
            return state;
    }

}