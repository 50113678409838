/**Import react section */
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import { Translate } from 'react-localize-redux';
import { Paper } from '@material-ui/core';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";

import DataTable from '../../common/DataTable';
import LoadingPage from '../../common/LoadingPage';
import { IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper';
import { GetTypeQuote, GetMeasurementUnits } from '../../../store/helpers/SelectOptions';


class CostSummary extends Component {
    /**
     * Create an intance of material delivery
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
     * Method to filter details by supplier
     *
     * @returns
     * @memberof CostSummary
     */
    getFilteredDetails(data) {
        return data.details.filter(detail => {
            let detailQuote = detail.quote_details.filter(quoteDetail => {
                return quoteDetail.supplier_code === this.props.user.profile.company_code
            })
            detail.quote_details = detailQuote;
            if (detail.quote_details.length > 0)
                return detail;
            else return '';
        })
    }

    /**
  * On render cell item custom
  * @param {*} dataSource 
  * @param {*} item 
  */
    onRenderCellItem(dataSource, item) {
        let optionsCostType = GetTypeQuote("PTM");
        let optionsUnits = GetMeasurementUnits();
        if (dataSource === "supplier_name") {
            return (`${item.supplier_code} | ${item.supplier_name}`)
        }
        if (dataSource === "total_cost") {
            return (`$${item.total_cost}`)
        }
        if (dataSource === "measurement_unit") {
            if (item.measurement_unit !== null && item.measurement_unit !== 0) {
                return (`${optionsUnits[item.measurement_unit].label}`)
            }
            else {
                return ("Sin unidad")
            }
        }
        if (dataSource === "cost_type") {
            if (item.cost_type !== null && item.cost_type !== "") {
                return (`${optionsCostType[item.cost_type].label}`)
            }
            else {
                return ("")
            }
        }
    }

    /**
     * get cost summary
     *
     * @returns
     * @memberof CostSummary
     */
    getCostSummaryList() {
        const { materialDelivery } = this.props;
        if (materialDelivery) {
            if (IsCurrentActiveDirectoryUser(this.props.user)) {
                return (this.props.materialDelivery.details.map((detail) => {
                    return (
                        <DataTable
                            title={<Typography variant="subtitle2" gutterBottom>{detail.full_part_number.toUpperCase()}</Typography>}
                            data={detail.quote_details}
                            configuration={TableCostSummaryConfiguration}
                            onRenderCellItem={this.onRenderCellItem}
                            onRowClick={(item) => {
                                this.props.history.push(`/quotes/${item.quote_token}`)
                            }}
                        />
                    )
                }))
            }
            else {
                return (this.getFilteredDetails(this.props.materialDelivery).map((detail) => {
                    return (
                        <DataTable
                            title={<Typography variant="subtitle2" gutterBottom>{detail.full_part_number.toUpperCase()}</Typography>}
                            data={detail.quote_details}
                            configuration={TableCostSummaryConfiguration}
                            onRenderCellItem={this.onRenderCellItem}
                            onRowClick={(item) => {
                                this.props.history.push(`/quotes/${item.quote_token}`)
                            }}
                        />
                    )
                }))
            }
        } else {
            return <LoadingPage />
        }
    }

    render() {
        return (
            <Paper>
                {this.getCostSummaryList()}
            </Paper>
        );
    }
}

const TableCostSummaryConfiguration = {
    columns: [{
        header: <Translate id="common.supplier" />,
        dataSource: 'supplier_name'
    }, {
        header: <Translate id="common.cost" />,
        dataSource: 'total_cost'
    }, {
        header: <Translate id="common.unitMeasure" />,
        dataSource: 'measurement_unit'
    }, {
        header: <Translate id="common.quoteType" />,
        dataSource: 'cost_type'
    }, {
        header: <Translate id="common.currency" />,
        dataSource: 'current'
    }]
}

export default CostSummary;