import React, { Component } from 'react'

/** Material-UI import section */
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Loader from 'react-spinners/ScaleLoader'

import '../../resources/styles/loader.css'

/** Language imports **/
import { Translate } from 'react-localize-redux'
import { Grid } from '@material-ui/core'

const styles = (theme) => ({
	message: {
		color: theme.palette.text.hint,
	},
	progress: {
		barColorPrimary: theme.palette.primary.main,
	},
})

const defaultColor = 'rgba(0, 0, 0, 0.38)'

/**
 * Component for showing a waiting message when the app is loading
 *
 * @export
 * @class LoadingPage
 * @extends {Component}
 */
class LoadingPage extends Component {
	/**
	 * Render
	 *
	 * @returns
	 * @memberof LoadingPage
	 */
	render() {
		const { classes } = this.props
		return (
			<div className='loader-container'>
				<Grid container>
					<Grid item xs={12}>
						<Loader
							sizeUnit={'px'}
							height={35}
							width={4}
							margin={'2px'}
							color={this.props.customColor ? this.props.customColor : defaultColor}
							loading={true}
							className='loader'
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography
							className={classes.message}
							variant='subtitle1'
							style={{ color: this.props.customColor ? this.props.customColor : classes.message }}
							gutterBottom
						>
							<Grid container>
								<Grid item xs={12}>
									<Translate
										id={
											this.props.isSavingPage
												? 'common.savingInformation'
												: 'common.loadingPleaseWait'
										}
									/>
								</Grid>
								<Grid item xs={12}>
									{this.props.customMessage}
								</Grid>
							</Grid>
						</Typography>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withStyles(styles, { withTheme: true })(LoadingPage)
