//Imports from React
import React from 'react';
import propTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import Select from 'react-select'
//Imports from material
import { Button, Typography, Grid, Avatar, Divider, TextField } from '@material-ui/core';
import SyncUpIcon from '@material-ui/icons/AutorenewOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withStyles } from '@material-ui/core/styles';
//Import from components
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';

import { GetSyncSapType } from '../../../store/helpers/SelectOptions';
import { COLOR_STATUS_INFO } from '../../../store/helpers/StatusColorConstants'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader';


const PanelSyncUpSap = (props) => {
    const { open, onClose, classes, onSaveItem, isSaving, onSaveItemByOrderNumber } = props

    const [syncMode, setSyncMode] = React.useState({
        syncType: null,
        orderNumber: "",
    });
    let getOptions = GetSyncSapType();

    const canBeSync = () => {
        if (syncMode.syncType) {
            if (syncMode.syncType.value === 2 && syncMode.orderNumber && syncMode.orderNumber.length === 10) return true
            if (syncMode.syncType.value === 1) return true
            if (syncMode.syncType.value !== 1 && syncMode.syncType.value !== 2) return false
        }
        else
            return false
    }

    const onChangeSelect = (name, option) => {
        setSyncMode({
            ...syncMode,
            [name]: option
        });
    }

    const send = () => {
        if (onSaveItem && syncMode.syncType.value === 1) {
            onSaveItem()
        }
        else if (onSaveItemByOrderNumber && syncMode.syncType.value === 2) {
            onSaveItemByOrderNumber(syncMode.orderNumber)
        }
    }

    return (
        <LateralPanel
            open={open}
            onClose={onClose}>
            <PanelHeader
                titlePanel="Sincronizar ordenes de maquila de SAP"
                icon={<Avatar className={classes.avatar}><SyncUpIcon color='red' /></Avatar>}
                isIconMaterial={true} />
            <div className={classes.container}>
                <Typography color="primary" variant="subtitle1" className={classes.containerHeader}>
                    Para poder sincronizar la informaion de las ordenes seleccione una opcion
                </Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <Select
                            value={syncMode ? syncMode.syncType : ""}
                            options={getOptions}
                            onChange={(option) => onChangeSelect("syncType", option)}
                            styles={{
                                menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                                menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                control: base => ({
                                    ...base,
                                    height: 50,
                                    minHeight: 50,
                                })
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container >
                    {syncMode.syncType && syncMode.syncType.value === 1 ?
                        <Grid item xs={12} className={classes.message}>
                            <Typography variant="body1">
                                <InfoOutlinedIcon />
                                Se sincronizan las ordenes de maquila de SAP de 5 días. Este proceso puede tardar varios munitos al obtener la inforamción de SAP.
                            </Typography>
                        </Grid>
                        : ""}
                    {syncMode.syncType && syncMode.syncType.value === 2 ?
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                helperText="Agregue numero de orden a 10 numeros"
                                type="number"
                                id="outlined-basic"
                                label="Numero de orden"
                                variant="outlined"
                                onChange={(event) => onChangeSelect("orderNumber", event.target.value)}
                            />
                        </Grid>
                        : ""}
                    
                </Grid>
            </div>
            <Divider variant="middle" />
            <div disaplay='flex' justifyConstent='right'>
                <Grid container>
                    <Grid item xs={8}>
                        <Button
                            variant="outlined"
                            className={classes.cancelButton}
                            onClick={() => onClose()}>
                            CANCELAR
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="outlined"
                            className={canBeSync() ? classes.syncButton : classes.lockButton}
                            onClick={() => send()}
                            disabled={!canBeSync() || isSaving}>
                            SINCRONIZAR
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </LateralPanel >
    )
}

const styles = theme => ({
    headerAvatar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center !important',
        marginTop: 10
    },
    cancelButton: {
        margin: "10px",
        color: "red",
        marginLeft: "200px !important",
        marginBottom: "50px !important",
        borderColor: "red"
    },
    syncButton: {
        margin: "10px",
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        marginBottom: "50px !important"

    },
    lockButton: {
        margin: "10px",
        backgroundColor: theme.palette.grey[200],
        color: "white",
        marginBottom: "50px !important"
    },
    errorMessage: {
        color: "white"
    },
    textField: {
        marginTop: 30,
        marginBottom: 10
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    container: {
        maxHeight: `calc(100vh - ${185}px)`,
        minHeight: `calc(100vh - ${185}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerHeader: {
        marginBottom: "20px !important"
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        backgroundColor: COLOR_STATUS_INFO,
        height: "90px",
        paddingLeft: 10
    },
});

PanelSyncUpSap.propTypes = {
    onSaveItem: propTypes.func,
    isSaving: propTypes.bool,
}

PanelSyncUpSap.defaultProps = {
    onSaveItem: () =>
        console.warn('Callback [onSaveItem] no defined'),
    isSaving: false
}
export default withRouter(withStyles(styles, { withTheme: true })(PanelSyncUpSap))