import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';
import LoadingPage from '../../common/LoadingPage';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import PurchaseIcon from '@material-ui/icons/CalendarTodayOutlined';
import EditIcon from '@material-ui/icons/BorderColor';
import DeleteIcon from '@material-ui/icons/Delete';

/** Language imports **/
import { Translate, getTranslate } from "react-localize-redux";
import ShowRelease from './ShowRelease';
import AddItemInRelease from './AddItemInRelease';
import RemoveItemInRelease from './RemoveItemInRelease';

/** Import section actions */

import { loadReleasePlanFromBackEnd, loadMaterialsReleaseFromRemove } from '../../../store/planning/ReleasePlanAction';
import { loadSupplierCode } from '../../../store/suppliers/SupplierActions';

import { converListToArray } from '../../../store/helpers/ListHelper'
import { canUpdateAddMaterialsInRelease } from '../../../store/helpers/RolesHelper';

/**
 * ReleasePlan supplier 
 *
 * @class ReleasePlan list
 * @extends {Component}
 */
class ReleasePlan extends Component {

    /**
     * Create an instance of ReleasePlan list
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            activeTab: 0,
        }
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        if (!this.props.isLoadingUser) {
            let supplierCode = this.props.match.params.token;
            this.props.loadReleasePlan(supplierCode);
            this.props.loadMaterialsReelase(supplierCode);
            this.props.loadSupplierCode(supplierCode);
        }
    }

    /**
     * Method to change the active tab
     */
    onClickToActiveTab = (event, value) => {
        this.setState({ activeTab: value });
    };

    /**
     * Action to add release in plan and update
     */
    onUpdateReleasePlan() {
        let supplierCode = this.props.match.params.token;
        this.props.loadReleasePlan(supplierCode);
        this.props.loadMaterialsReelase(supplierCode);
        this.setState({ activeTab: 0 });
    }

    /**
     * Action to remove release in plan and update
     */
    onUpdateRemoveItemReleasePlan() {
        let supplierCode = this.props.match.params.token;
        this.props.loadReleasePlan(supplierCode);
        this.props.loadMaterialsReelase(supplierCode);
    }

    /**
     * Get the card content
     *
     * @memberof ReleasePlan
     */
    getCardContent() {
        if (this.props.isLoadingReleasePlan === true) {
            return (
                <LoadingPage />
            )
        }
        else {
            const { classes } = this.props;
            return (
                <Grid container spacing={24}>
                    {canUpdateAddMaterialsInRelease(this.props.userRolesByCompany) && <Grid item xs={12}>
                        <Divider />
                        <AppBar position="static" color="default">
                            <Tabs indicatorColor="secondary" textColor="secondary"
                                className={classes.styleTab}
                                value={this.state.activeTab}
                                onChange={this.onClickToActiveTab}>
                                <Tab label={<p><PurchaseIcon /> <Translate id="releasePlan.releasePlan" /> </p>} />
                                <Tab label={<p><EditIcon /> <Translate id="releasePlan.addMaterial" /></p>} />
                                <Tab label={<p><DeleteIcon /> <Translate id="releasePlan.removeMaterial" /> </p>} />
                            </Tabs>
                        </AppBar>

                        {this.state.activeTab === 0 && <ShowRelease releasePlan={this.props.releasePlan} />}
                        {this.state.activeTab === 1 && <AddItemInRelease updateRelease={this.onUpdateReleasePlan} />}
                        {this.state.activeTab === 2 && <RemoveItemInRelease updateRelease={this.onUpdateRemoveItemReleasePlan} />}

                    </Grid>}
                    {!canUpdateAddMaterialsInRelease(this.props.userRolesByCompany) &&
                        <Grid item xs={12}>
                            <ShowRelease releasePlan={this.props.releasePlan} />
                        </Grid>
                    }
                </Grid>
            )
        }
    }

    /**
     * Render
     *
     * @returns
     * @memberof Supplier
     */
    render() {
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <PurchaseIcon />
                        </Avatar>
                    }
                    title={<Typography variant="button">{<Translate id="releasePlan.releasePlan" />}: {this.props.supplier ? this.props.supplier.full_name : ""} </Typography>}
                    subheader={<span>{<Translate id="releasePlan.publicationDate" />}: {this.props.releasePlan ? this.props.releasePlan.publication_date : ""}</span>}
                />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>
            </Card>
        );
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        user: user,
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
        isLoadingUser: state.oidc.isLoadingUser,
        isLoadingReleasePlan: state.releasePlan.get("isLoadingReleasePlan"),
        releasePlan: state.releasePlan.get('releasePlan') ? state.releasePlan.get('releasePlan').toJS() : null,
        translate: getTranslate(state.localize)
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadReleasePlan: (supplierConde) => {
            dispatch(loadReleasePlanFromBackEnd(supplierConde));
        },
        loadMaterialsReelase: (supplierCode) => {
            dispatch(loadMaterialsReleaseFromRemove(supplierCode));
        },
        loadSupplierCode: (supplierCode) => {
            dispatch(loadSupplierCode(supplierCode));
        }
    }
}

const styles = theme => ({
    logo: {
        backgroundColor: theme.palette.secondary.main,
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    showingCounter: {
        color: theme.palette.secondary.main
    }
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ReleasePlan))));
