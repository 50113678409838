import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import uuidv4 from 'uuid/v4';
/** Material-UI imports section */
import {
	Typography,
	Paper,
	List,
	ListItem,
	ListItemText,
	IconButton,
	Tooltip,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import AttachFileIcon from '@material-ui/icons/AttachFile';

/** section import components */
import { BACKGROUND_COLOR_GRAY_DARK, COLOR_STATUS_WARNING} from '../../../store/helpers/StatusColorConstants';
import DataTable from '../../common/DataTable';
import Dropzone from '../../common/Dropzone';
import HtmlEditor from '../../common/HtmlEditor';
import PanelFilesMaterial from './PanelFilesMaterial';

import { IsUserInRole, canBeModulePlaneationSupplier,
	 ROLE_MAQUILAORDER_ADMINISTRATION } from '../../../store/helpers/RolesHelper';
import { IsUserSupplierAdministrator } from '../../../store/helpers/SessionHelper';

/**
 * Component ot render Information partial maquila Order
 */
export default class MaterialsSend extends Component {
	/**
	 * Create an instance shipping Order
	 * @param {*} props
	 */
	constructor(props) {
		super(props);
		autoBind(this);
		this.state = {
			openPanelSupplier: false,
			itemSelected: null,
			isEvidence: false
		};
	}

	
	AditionalRowsItems(dataSource, item){
		if(dataSource ===  "price")
		{
			if(item.has_be_price)
				return(item.price)
			else
				return "-";
		}
	}

	/**
	 * On Render aditional row data
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof PurchaseOrders
	 */
	onRenderCellAditionalRowItem(dataSource, item) {
		if (dataSource === 'materials' && item[dataSource]) {
			return (
				<React.Fragment>
					<DataTable 
						data={item[dataSource]}
						configuration={TableConfigurationDetails} 
						onRenderCellItem={this.AditionalRowsItems}
					/>
				</React.Fragment>
			);
		}
	}

	onRenderCellAditionalRowItemProductMaquiled(dataSource, item) {
		if (dataSource === 'items' && item[dataSource]) {
			return (
				<React.Fragment>
					<DataTable 
						data={item[dataSource]}
						configuration={TableConfigurationMachinedsItems} 
						onRenderCellItem={this.onRenderCellItem}
					/>
				</React.Fragment>
			);
		}
	}

	onRenderCellItemDetail(dataSource, item) {
		if(dataSource === "total_price")
		{
			if(item.has_be_price)
				return(item[dataSource])
			else
				return "-";
		}
	}

	/**
	 * On render files shipping order
	 * @param {*} title
	 * @param {*} onChange
	 * @param {*} onDelete
	 * @param {*} items
	 */
	renderFilesMaquilas(titleTranslate, maquilaToken, listName, items, isActive) {
		return (
			<TableRow key={uuidv4()}>
				<TableCell key={uuidv4()} align="left" padding="dense" style={{ maxWidth: '30%' }}>
					{<Translate id={'maquilaOrders.' + titleTranslate} />}
				</TableCell>
				<TableCell key={uuidv4()} align="left" padding="dense" style={{ maxWidth: '40%' }}>
					<Dropzone
						baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
						onChange={(event) => this.onChangeFile(event, maquilaToken, listName)}
						onDeleteFile={(name) => this.onDeleteFile(name, maquilaToken, listName)}
						isDisabled={!isActive}
						files={items}
					/>
				</TableCell>
			</TableRow>
		);
	}

	/**
	 * On upload file in maquila order in list name
	 * @param {*} files
	 * @param {*} maquilaToken
	 * @param {*} listName
	 */
	onChangeFile(files, maquilaToken, listName) {
		return this.props
			.uploadFilesMaquilaOrder(maquilaToken, files, listName)
			.then(() => {
				return Promise.resolve('Ok');
			})
			.catch(() => {
				return Promise.reject();
			});
	}

	/**
	 * On remove files of dropzone
	 *
	 * @param {*} event
	 * @memberof DetailsCard
	 */
	onDeleteFile(fileName, maquilaToken, listName) {
		return this.props
			.removeFilesMaquilaOrder(maquilaToken, fileName, listName)
			.then(() => {
				return Promise.resolve('Ok');
			})
			.catch(() => {
				return Promise.reject();
			});
	}

	/**
	 * Open selected option
	 * @param {*} item
	 */
		onOpenPanelEvidence(item) {
		this.setState({
			openPanelSupplier: true,
			itemSelected: item,
			isEvidence: true
		});
	}
	

	/**
	 * Render material
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'files') {
			if (!this.props.maquilaOrder.can_be_edit) {
				return (
					<span style={{ width: '100px' }}>
						<Tooltip title={<Translate id="maquilaOrders.files" />}>
							<IconButton color="primary" aria-label="More" onClick={() => this.onOpenPanelEvidence(item)}>
								<AttachFileIcon style={{color: item.attachments.length === 0 ? '' : COLOR_STATUS_WARNING}} />
							</IconButton>
						</Tooltip>
					</span>
				);
			} else {
				return '-';
			}
		}
	}

	/**
	 * Render
	 */
	render() {
		const { classes, maquilaOrder, isUserActiveDirectory, userRolesByCompany } = this.props;
		let canBeEditAdmin =
			IsUserInRole(ROLE_MAQUILAORDER_ADMINISTRATION, userRolesByCompany) && maquilaOrder.can_be_add_files;
		const canBeUpdateSupplier =
			(canBeModulePlaneationSupplier(this.props.userRolesByCompany) || IsUserSupplierAdministrator(this.props.currentUser)) &&
			maquilaOrder.can_be_edit_supplier;

		let maquilaToken = maquilaOrder.token;
		return (
			<Paper>
				<List>
					<ListItem divider style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}>
						<ListItemText
							secondary={
								<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
									<strong> {<Translate id="maquilaOrders.maquilaProductControl" />}</strong>
								</Typography>
							}
						/>
					</ListItem>
				</List>

				<DataTable
					configuration={TableConfigurationMachinedsProductsGroups}
					data={maquilaOrder.detail_machineds}
					mode={'clientSide'}
					collapsableChild
					aditionalRows={AditionalRowsItemsProdcutMaquiled}
					onRenderCellAditionalRowItem={this.onRenderCellAditionalRowItemProductMaquiled}
					// onRenderCellItem={this.onRenderCellItem}
					widthFilters={120}

				/>
				<List>
					<ListItem style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}>
						<ListItemText
							secondary={
								<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
									<strong> {<Translate id="maquilaOrders.sendedMaterialsControl" />}</strong>
								</Typography>
							}
						/>
					</ListItem>
					<Divider />
				</List>
				<DataTable
					configuration={TableConfigurationMaterials}
					data={maquilaOrder.detail_materials}
					mode={'clientSide'}
					collapsableChild
					aditionalRows={AditionalRows}
					onRenderCellAditionalRowItem={this.onRenderCellAditionalRowItem}
					onRenderCellItem={this.onRenderCellItemDetail}
					widthFilters={120}
				/>

				<List>
					<ListItem divider style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}>
						<ListItemText
							secondary={
								<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
									<strong> {"Anexos"}</strong>
								</Typography>
							}
						/>
					</ListItem>
				</List>

				<Table style={{ maxWidth: '100%' }}>
					<TableHead className={classes.tableHead}>
						<TableRow>
							<TableCell>
								<Translate id="shippingOrder.documentName" />
							</TableCell>
							<TableCell>
								<Translate id="shippingOrder.documentOptions" />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isUserActiveDirectory &&
							this.renderFilesMaquilas(
								'filesInventory',
								maquilaToken,
								'files_inventories',
								this.props.maquilaOrder.files_inventories,
								canBeEditAdmin
							)}
						{isUserActiveDirectory &&
							this.renderFilesMaquilas(
								'filesFinal',
								maquilaToken,
								'files_consiliation',
								this.props.maquilaOrder.files_consiliation,
								canBeEditAdmin
							)}
					</TableBody>
				</Table>

				<List>
					<ListItem divider style={{ backgroundColor: BACKGROUND_COLOR_GRAY_DARK }}>
						<ListItemText
							secondary={
								<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
									<strong> {<Translate id="maquilaOrders.commentsAdmin" />}</strong>
								</Typography>
							}
						/>
					</ListItem>
					<ListItem divider>
						<ListItemText
							secondary={
								<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
									{<Translate id="maquilaOrders.commentsAdmin" />}
									<HtmlEditor
										id={'comments'}
										labelVariant="caption"
										labelColor="textSecondary"
										isDisabled={!canBeEditAdmin}
										initialValue={maquilaOrder.comments}
										onChange={(data) => this.props.updateCommentsMaquilaOrder('comments', data)}
									/>
								</Typography>
							}
						/>
					</ListItem>
				</List>

				{this.state.openPanelSupplier && (
					<PanelFilesMaterial
						onCloseButtonClick={() => {
							this.setState({ openPanelSupplier: false, itemSelected: null, isEvidence: false });
						}}
						isEvidence={this.state.isEvidence}
						isSaving={this.props.isSavingMaquila}
						itemSelected={this.state.itemSelected}
						dropzoneText={this.props.dropzoneText}
						onSaveItem={this.props.updateMaterialsFromSupplier}
						uploadFilesFromSupplier={this.props.uploadFilesFromSupplier}
						onDeleteAttachment={this.props.removeFilesFromSupplier}
						canBeEdit={canBeUpdateSupplier}
						isMaquilaDraft={this.props.maquilaOrder.can_be_edit}
						uploadFilesDetailMachineds={this.props.uploadFilesDetailMachineds}
						removeFilesDetailMachineds={this.props.removeFilesDetailMachineds}
						updatProductMachineds={this.props.updatProductMachineds}
					/>
				)}

			</Paper>
		);
	}
}

const TableConfigurationMachinedsProductsGroups = {
	columns: [
		{
			header: '',
			dataSource: 'expand'
		},
		{
			header: <Translate id="common.code" />,
			dataSource: 'code'
		},
		{
			header: <Translate id="shippingOrder.productoDescription" />,
			dataSource: 'description'
		},
		{
			header: <Translate id="maquilaOrders.requestedQuantity" />,
			dataSource: 'quantity'
		},
		{
			header: <Translate id="maquilaOrders.receivedAmount" />,
			dataSource: 'recibed_quantity'
		},
		{
			header: <Translate id="maquilaOrders.pendingQuantity" />,
			dataSource: 'quantity_pendient'
		},
		// {
		// 	header: <Translate id="maquilaOrders.price" />,
		// 	dataSource: 'price'
		// },
		// {
		// 	header: <Translate id="maquilaOrders.remisions" />,
		// 	dataSource: 'files'
		// }
	]
};

const TableConfigurationMachinedsItems = {
	columns: [
		{
			header: <Translate id="common.code" />,
			dataSource: 'code'
		},
		{
			header: <Translate id="shippingOrder.productoDescription" />,
			dataSource: 'description'
		},
		{
			header: <Translate id="common.document" />,
			dataSource: 'document_entry'
		},
		{
			header: "Posición",
			dataSource: 'position'
		},
		{
			header: <Translate id="maquilaOrders.requestedQuantity" />,
			dataSource: 'quantity'
		},
		{
			header: <Translate id="maquilaOrders.receivedAmount" />,
			dataSource: 'recibed_quantity'
		},
		{
			header: <Translate id="maquilaOrders.pendingQuantity" />,
			dataSource: 'quantity_pendient'
		},
		{
			header: <Translate id="maquilaOrders.deliveryDate" />,
			dataSource: 'delivery_date'
		},
		{
			header: <Translate id="maquilaOrders.price" />,
			dataSource: 'price'
		},
		{
			header: <Translate id="maquilaOrders.remisions" />,
			dataSource: 'files'
		}
		// {
		// 	header: <Translate id="maquilaOrders.remisions" />,
		// 	dataSource: 'files'
		// }
	]
};

const TableConfigurationMaterials = {
	columns: [
		{
			header: '',
			dataSource: 'expand'
		},
		{
			header: "Línea de pedido",
			dataSource: 'detail_machined_material_code'
		},
		{
			header: "Posición",
			dataSource: 'detail_machined_material_position'
		},
		{
			header: <Translate id="common.code" />,
			dataSource: 'component'
		},
		{
			header: <Translate id="shippingOrder.productoDescription" />,
			dataSource: 'description'
		},
		{
			header: <Translate id="maquilaOrders.sendedTotalAmount" />,
			dataSource: 'quantity_send'
		},
		{
			header: <Translate id="maquilaOrders.requiredTotalAmount" />,
			dataSource: 'required_quantity'
		},
		{
			header: <Translate id="maquilaOrders.consumptionTotalAmount" />,
			dataSource: 'consumption_amount'
		},
		{
			header: <Translate id="maquilaOrders.price" />,
			dataSource: 'total_price'
		}
	]
};

/** Configuration to show additional rows */
const AditionalRows = {
	rows: [
		{
			dataSource: 'materials',
			type: 'dataList'
		}
	]
};

/** Configuration to show additional rows */
const AditionalRowsItemsProdcutMaquiled = {
	rows: [
		{
			dataSource: 'items',
			type: 'dataList'
		}
	]
};

const TableConfigurationDetails = {
	columns: [
		{
			header: <Translate id="common.code" />,
			dataSource: 'component'
		},
		{
			header: <Translate id="shippingOrder.productoDescription" />,
			dataSource: 'description'
		},
		{
			header: <Translate id="maquilaOrders.measurementUnit" />,
			dataSource: 'measurement_unit'
		},
		{
			header: <Translate id="common.document" />,
			dataSource: 'document_output'
		},
		{
			header: <Translate id="maquilaOrders.quantitySend" />,
			dataSource: 'quantity_send'
		},
		{
			header: <Translate id="maquilaOrders.deliveryDate" />,
			dataSource: 'delivery_date'
		},
		{
			header: <Translate id="maquilaOrders.requiredAmount" />,
			dataSource: 'required_quantity'
		},
		{
			header: <Translate id="maquilaOrders.consumptionAmount" />,
			dataSource: 'consumption_amount'
		},
		{
			header: <Translate id="maquilaOrders.price" />,
			dataSource: 'price'
		}
	]
};

/**
 * maquilaOrder
 */
MaterialsSend.propTypes = {
	maquilaOrder: PropTypes.object.isRequired
};
