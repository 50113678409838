import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import {Typography} from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';

/** Import resources */
import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper';
import DatePicker from '../../common/DateTimePicker/DatePicker';
import SupplierSelector from '../../common/SupplierShortSelector';

/**
 * Component o DialogEditDates
 */
class DialogUpdateDate extends Component {

    /**
    * Create an instance ofMemos
    * @param {*} props 
    */
    constructor(props) {
        super(props);
        autoBind(this);
        
    }

    /**
     * Can be save
     */
    canbeSave() {
        if (this.props.itemUpdate.recollection_date === null)
            return true;
        if (this.props.itemUpdate.recollection_date.length !== 0)
            return true;
        else
            return false;
    }

    /**
     *  Dialog to revister arrive date
     */
    render() {
        const { classes, itemUpdate } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                header={
                    <Toolbar>
                        <Typography variant="subtitle1">
                            {<Translate id="maquilaOrders.addHarvestDate"/>}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={this.props.onSaveItem}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.canbeSave()}
                    />
                }>

                <form className={classes.textField}>
                    <Typography variant="caption" style={{marginLeft:"5px"}} >{<Translate id="maquilaOrders.recollectionDate"/>}</Typography>
                        <DatePicker
                            onChangeRange={(event)=> {this.props.updateProperty('recollection_date', event, itemUpdate.token)}}
                            startDate={itemUpdate.recollection_date ? moment(itemUpdate.recollection_date , 'DD/MM/YYYY') : ''}
                            minDate={moment()}
                        />
                        <br />
                        <Typography variant="caption"> {<Translate id="maquilaOrders.addCarrier"/>} </Typography>
                        <SupplierSelector
                            className={classes.textField}
                            onSupplierSelected = {(supplier) => {this.props.updateProperty("supplier", supplier)}}
                        />
                </form>
            </Dialog>
        )
    }
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(DialogUpdateDate));