/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
/**Import MaterialUi section */
import {
    Grid, Avatar, Button, Typography
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import CreateServicesComponent from './CreateServicesComponent'
import CreateMaterialComponent from './CreateMaterialComponent'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { isCategoryService, isCategoryRawMaterials, IS_MATERIAL_FROM_SAP } from '../../../../store/helpers/BiddingHelper';

const AddServicesLateralPanel = (props) => {
    /**props section */
    const { onCloseButtonClick, onSaveButtonClick,
        isSaving, classes, translate, bidding
    } = props

    const [biddingModel, setBiddingModel] = useState({
        type: (bidding ? bidding.CategoryName : ""),
        comments: "",
        name: "",
        partNumber: {
            code: "",
            measurementUnit: "",
            description: ""
        },
        isMaterialSAP: IS_MATERIAL_FROM_SAP
    });

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(biddingModel)
        }
    }


    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if (biddingModel && isCategoryRawMaterials(props.bidding)) {
            //if (IsNullOrEmpty(biddingModel.comments)) return false
            if (IsNullOrEmpty(biddingModel.partNumber.code)) return false
            if (IsNullOrEmpty(biddingModel.partNumber.measurementUnit)) return false
            if (IsNullOrEmpty(biddingModel.partNumber.description)) return false
            if (IsNullOrEmpty(biddingModel.quantity)) return false
            if (IsNullOrEmpty(biddingModel.purchasePeriod)) return false
            if (IsNullOrEmpty(biddingModel.baseUnit)) return false
            if (IsNullOrEmpty(biddingModel.baseMeasurement)) return false
            return true;

        }
        if (biddingModel && isCategoryService(props.bidding)) {
            if (IsNullOrEmpty(biddingModel.name)) return false
            return true;
        }
        return false
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;

        if (name === 'quantity' && !IsNullOrEmpty(value)) {
            let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (!regexQuantity.test(value)) return;
        }
        if (name === 'baseUnit' && !IsNullOrEmpty(value)) {
            let regexQuantity = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (!regexQuantity.test(value)) return;
        }

        setBiddingModel({
            ...biddingModel,
            [name]: value
        });
    }

    const onSelectedPartNumber = (name, option) => {
        setBiddingModel({
            ...biddingModel,
            [name]: option
        });
    }


    const onRadioChange = (event) => {
        setBiddingModel({
            ...biddingModel,
            "isMaterialSAP": event.target.value
        });
    }

    /**On change properties */
    const onPropertyPartNumberChange = event => {
        let { name, value } = event.target;
        biddingModel.partNumber[name] = value
        setBiddingModel({
            ...biddingModel,
            "partNumber": biddingModel.partNumber
        });
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<span><Translate id="biddingsModule.services.titleCreate" />: {props.bidding ? props.bidding.CategoryName : ""} </span>}
                subTitlePanel={`${translate("biddingsModule.table.folio")} | ${props.bidding ? props.bidding.FormatedFolio : ""}`}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><ViewCarouselIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >
                        <Grid item xs={12} className={classes.titleDisplay}>
                            <Typography variant='subtitle1' color='primary' className={classes.margingTopText}>{translate("biddingsModule.create.category")}</Typography>
                            <Typography variant='subtitle1'>{": "}{props.bidding ? props.bidding.CategoryName : ""}</Typography>
                        </Grid>

                        {
                            isCategoryService(props.bidding) &&
                            <CreateServicesComponent
                                onPropertyChange={onPropertyChange}
                                biddingModel={biddingModel}
                            />
                        }

                        {
                            isCategoryRawMaterials(props.bidding) &&
                            <CreateMaterialComponent
                                onPropertyChange={onPropertyChange}
                                biddingModel={biddingModel}
                                onSelectedPartNumber={onSelectedPartNumber}
                                onRadioChange={onRadioChange}
                                onPropertyPartNumberChange={onPropertyPartNumberChange}
                                translate={translate}
                            />
                        }
                       
                    </Grid>

                </div>
                <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSave}
                            disabled={!canBeSaved() || isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>

            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "20px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    titleDisplay: {
        display: "flex"
    }

});

AddServicesLateralPanel.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onSaveButtonClick: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
}

AddServicesLateralPanel.defaultProps = {
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(AddServicesLateralPanel))