/**Import react section */
import React, { useState, useCallback } from 'react'
import { Translate } from "react-localize-redux";
/**Import components section */
import LateralPanel from './LateralPanel';
import PanelHeader from './PanelHeaderComponent'
import { Typography } from '@material-ui/core'
import { PanelLateralTitle, PanelLateralSubTitle, PanelLateralIcon, IsIconMaterial } from '../../../store/helpers/PanelLateralHelper'

export default function LateralPanelContainer({
    children,
    identifier,
    setIdentifierPanelLateral
}) {
    const [isOpen, setIsOpen] = useState(false);
    const onOpenAndCloseLateralPanel = () => {
        setIsOpen(!isOpen);
        if (!isOpen===false && setIdentifierPanelLateral) {
            setIdentifierPanelLateral("")
        }
    };

    const onCloseLateralPanel = () => {
        setIsOpen(false);
        if (setIdentifierPanelLateral) {
            setIdentifierPanelLateral("")
        }
    };

    const LateralPanelComponent = useCallback(
        () => (
            isOpen && <LateralPanel
                onClose={() => onCloseLateralPanel()}
            >
                <PanelHeader
                    titlePanel={<Typography >{ <Translate id={PanelLateralTitle[identifier]} />}</Typography>}
                    subTitlePanel={PanelLateralSubTitle[identifier] ? <Typography component={'span'} >{<Translate id={PanelLateralSubTitle[identifier]} />}</Typography>:""}
                    onCloseButtonClick={() => onCloseLateralPanel()}
                    icon={PanelLateralIcon[identifier]}
                    isIconMaterial={IsIconMaterial[identifier]}
                />
                <>
                    {children}
                </>
            </LateralPanel >
        ),
        [isOpen, children, identifier]
    );

    return {
        LateralPanelComponent,
        onOpenAndCloseLateralPanel,
        onCloseLateralPanel
    };
}