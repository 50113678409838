import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate, getTranslate } from 'react-localize-redux'
// import Dropzone from 'react-dropzone';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Evidence from './../common/Evidence'
import DataTable from '../common/DataTable'
/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import RatesIcon from '@material-ui/icons/BarChartTwoTone'
import ImportIcon from '@material-ui/icons/ImportExport'
import InfoIcon from '@material-ui/icons/Info'
import Grid from '@material-ui/core/Grid'
//import Dropzone from '../common/Dropzone';

import templateRating from '../../resources/files/templateRating.xlsx'
import templateRatingRepare from '../../resources/files/templateRatingRepare.xlsx'
import templateRatingImberaMX from '../../resources/files/templateRatingImberaMX.xlsx'

import { uploadFiles } from '../../store/ratings/ratingActions'
import Toaster from '../common/Toaster'
import { Divider } from '@material-ui/core'

import { COMPANY_IDENTIFIER_IMBERA_MX, COMPANY_IDENTIFIER_IMBERA_REPARE } from '../../store/helpers/ResourcesHelper'

/**
 * Component to Import Rating
 */
class ImportRating extends Component {
	/**
	 * Create an instance ofMemos
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			files: [],
			fileBase64: '',
		}
	}

	/**
	 * Handle event upload files
	 *
	 * @param {*} files
	 * @returns
	 * @memberof ImportRating
	 */
	handleUploadFiles(files) {
		this.setState({ files })
		return Promise.resolve('Ok')
	}

	/**
	 * Handle event on upload files triggered by button
	 *
	 * @param {*} files
	 * @returns
	 * @memberof ImportRating
	 */
	onUploadFiles() {
		if (this.props.uploadFiles)
			return this.props
				.uploadFiles(this.state.files)
				.then(() => {
					this.setState({
						showToView: true,
						showToaster: true,
						toasterMessage: <Translate id='common.saveCorrectly' />,
						toasterVariant: 'success',
					})
					return Promise.resolve('Ok')
				})
				.catch(() => {
					let errorMessage =
						this.props.translate('rating.errorImportingRating') +
						' ' +
						this.props.translate('rating.baseErrorRatingsUpload')
					this.setState({
						showToaster: true,
						toasterVariant: 'error',
						toasterMessage: errorMessage,
					})
					return Promise.reject()
				})
	}

	/**
	 * Handle event remove file
	 *
	 * @param {*} fileName
	 * @returns
	 * @memberof ImportRating
	 */
	handleRemoveFiles(fileName) {
		this.setState({
			files: this.state.files.filter((file) => {
				return file.name !== fileName
			}),
		})
		return Promise.resolve('Ok')
	}

	/**
	 * Render component memo
	 */
	render() {
		const { classes } = this.props
		let isDisabled = this.state.files.length > 0

		let tableConfiguration = TableratingsTableConfiguration(this.props.translate)
		if (this.props.selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE) {
			tableConfiguration = RatingsRepareTableConfiguration(this.props.translate)
		}

		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<RatesIcon />
						</Avatar>
					}
					title={<Typography variant='button'>{<Translate id='rating.importRating' />} </Typography>}
				/>
				<CardContent>
					<Grid container spacing={24}>
						<Grid
							item
							xs={12}
							className='alert alert-info'
							role='alert'
							style={{ marginLeft: '.7rem', marginRight: '.7rem' }}
						>
							<h6 className='alert-heading'>
								<InfoIcon />
								<Translate id='rating.importRating' />
							</h6>
							<p>
								<Translate id='rating.messatoToImport' />{' '}
							</p>
						</Grid>

						<Grid item xs={12}>
							<Grid container justify='flex-end' alignItems='center' spacing={24}>
								<Grid item xs={3}>
									<Button
										variant='contained'
										color='primary'
										className={classes.button}
										disabled={!isDisabled || this.props.isSaving}
										onClick={() => {
											this.onUploadFiles()
										}}
									>
										<ImportIcon className={classes.rightIcon} />
										{this.props.isSaving ? (
											'Importando calificaciones...'
										) : (
											<Translate id='rating.importRating' />
										)}
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Evidence
								baseApi={process.env.REACT_APP_SIP_URL_FILES}
								removeFiles={this.handleRemoveFiles}
								uploadFiles={this.handleUploadFiles}
								files={this.state.files}
								acceptedFiles={['excel']}
								maxFiles={1}
								disabledLink
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<Divider />
						<small className='text-muted'>
							<Translate id='rating.uploadTemplate' />
						</small>{' '}
						<small className='text-muted'>
							{this.props.selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE ? (
								<a href={templateRatingRepare}>
									<Translate id='rating.uploadFormatRating' />
								</a>
							) : this.props.selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ? (
								<a href={templateRatingImberaMX}>
									<Translate id='rating.uploadFormatRating' />
								</a>
							) : (
								<a href={templateRating}>
									<Translate id='rating.uploadFormatRating' />
								</a>
							)}
						</small>
						<Divider />
					</Grid>

					<Grid item xs={12} style={{ marginTop: '.5rem' }}>
						<DataTable
							title={<Translate id='companies.functionModules.importSupplierQualification' />}
							data={this.props.ratingToPublish ? this.props.ratingToPublish : []}
							configuration={tableConfiguration}
							onRenderCellItem={this.onRenderRatingsCellItem}
						/>
					</Grid>
				</CardContent>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

function TableratingsTableConfiguration(translate) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: translate('rating.ratingsTable.month'),
			dataSource: 'rating_date',
		},
		{
			header: `${translate && translate('rating.ratingsTable.deliveries')} ${'25'}%`,
			dataSource: 'delivery_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.development')} ${'25'}%`,
			dataSource: 'development_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.quality')} ${'40'}%`,
			dataSource: 'quality_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.service')} ${'10'}%`,
			dataSource: 'service_points',
		},
		{
			header: <Translate id='rating.ratingsTable.finalScore' />,
			dataSource: 'final_score',
		},
	]
	return { columns }
}

function RatingsRepareTableConfiguration(translate) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: translate('rating.ratingsTable.month'),
			dataSource: 'rating_date',
		},
		{
			header: `${translate && translate('rating.ratingsTable.deliveries')} ${'45'}%`,
			dataSource: 'delivery_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.quality')} ${'45'}%`,
			dataSource: 'quality_points',
		},
		{
			header: `${translate && translate('rating.ratingsTable.service')} ${'10'}%`,
			dataSource: 'service_points',
		},
		{
			header: <Translate id='rating.ratingsTable.finalScore' />,
			dataSource: 'final_score',
		},
	]
	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		translate: getTranslate(state.localize),
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isSaving: state.ratings.get('isSaving'),
		ratingToPublish: state.ratings.get('ratingToPublish') ? state.ratings.get('ratingToPublish').toJS() : null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		uploadFiles: (files) => {
			return dispatch(uploadFiles(files))
		},
	}
}

const styles = (theme) => ({
	selectorContainer: {
		paddingTop: '5px',
		paddingBottom: '5px',
	},
	textHelper: {
		paddingBottom: '2px',
	},
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ImportRating)))
)
