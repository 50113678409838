/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
/**Import material UI Section*/

import { withStyles } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import DocumentsHeaderTable from "./DocumentsHeaderTable";
import DocumentsContentTable from "./DocumentsContentTable";

/**Import components section*/
import { onGetHeaderDocumentsTable } from "../../../../store/helpers/ExpedientSupplier";
const DocumentsContainerTable = (props) => {
  /**String props section */
  // const { } = props
  /**Bools props section */
  const { isOpenLateralMenu, userRolesByCompany } = props;
  /**Arrays props section */
  //const { userRolesByCompany } = props
  /**Functions props section */
  const { onConfirmPassword, handleMoveDocumentToIndex } = props;
  /**Objects props section */
  const {
    classes,
    documents,
    supplierClassifications,
    onLateralPanelConfig,
    identifierPanelLateral,
  } = props;
  let mappedHeadersDocuments = onGetHeaderDocumentsTable(
    supplierClassifications
  );

  return (
    <div className={isOpenLateralMenu ? classes.rootOpen : classes.rootClose}>
      <Table className={classes.table} size="small">
        <DocumentsHeaderTable
          mappedHeadersDocuments={mappedHeadersDocuments}
          onLateralPanelConfig={onLateralPanelConfig}
          userRolesByCompany={userRolesByCompany}
        />
        <DocumentsContentTable
          documents={documents}
          supplierClassifications={supplierClassifications}
          mappedHeadersDocuments={mappedHeadersDocuments}
          onLateralPanelConfig={onLateralPanelConfig}
          identifierPanelLateral={identifierPanelLateral}
          onConfirmPassword={onConfirmPassword}
          userRolesByCompany={userRolesByCompany}
          handleMoveDocumentToIndex={handleMoveDocumentToIndex}
        />
      </Table>
    </div>
  );
};

const styles = (theme) => ({
  rootOpen: {
    maxHeight: `calc(100vh - ${265}px)`,
    minHeight: `calc(100vh - ${265}px)`,
    maxWidth: `calc(100vw - ${335}px)`,
    overflowX: "auto",
    overflowY: "auto",
  },
  rootClose: {
    maxHeight: `calc(100vh - ${265}px)`,
    minHeight: `calc(100vh - ${265}px)`,
    maxWidth: `calc(100vw - ${175}px)`,
    overflowX: "auto",
    overflowY: "auto",
  },
  table: {
    //display:"flex",
    //maxWidth: '100%',
    //minHeight: 90,
    //maxHeight: '100%'
  },
});

DocumentsContainerTable.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  isLoading: PropTypes.bool.isRequired,
};

DocumentsContainerTable.defaultProps = {
  isLoading: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(DocumentsContainerTable)
);
