import React from 'react'
import PropTypes from 'prop-types'
import { Fab } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'

/**
 * Close button component
 *
 */
const ButtonTableColumnSettings = (props) => {
	const { onShowConfiguration } = props

	return (
		<Fab
			size='small'
            color="primary"
			style={{
				boxShadow: 'none',
				width: 35,
				height: 35,
			}}
			onClick={onShowConfiguration}
		>
			<SettingsIcon />
		</Fab>
	)
}

ButtonTableColumnSettings.propTypes = {
    onShowConfiguration: PropTypes.func.isRequired,
}

ButtonTableColumnSettings.defaultProps = {
    onShowConfiguration: () => console.warn('[onShowConfiguration] not defined!')
}

export default ButtonTableColumnSettings