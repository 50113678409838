
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import Select from "react-select";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import { FormControlLabel, Grid, Paper, SnackbarContent, Switch } from '@material-ui/core';

/** Import component section */
import Dialog from '../common/Dialog';
import DialogActions from '../common/DialogActions';

import { SHOW_EDIT_VIEW } from '../../store/helpers/ResourcesHelper';
import Evidence from './../common/Evidence';
import { blue, grey } from '@material-ui/core/colors';
import InfoIcon from '@material-ui/icons/Info';
import { GetLanguageOptions } from '../../store/helpers/SelectOptions';

/**
 * Dialog to AddManual
 */
class AddManual extends Component {

    /**
     *Creates an instance of AddManual.
     * @param {*} props
     * @memberof AddManual
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            name: '',
            annexe: [],
            isActive: true,
            shouldRenderEvidence: true,
            language: null
        }
    }

    /**
     * Set local state to initial values
     *
     * @param {boolean} [checkedValue=false]
     * @memberof AddManual
     */
    setInitialState() {
        this.setState({
            name: '',
            annexe: [],
        }, () => {
            this.setState({
                shouldRenderEvidence: true
            })
        })
    }

    /**
     * Validate if form has full required data to save
     *
     * @returns
     * @memberof AddActionToListDialog
     */
    canBeSaved() {
        if (this.state.name === "")
            return false
        if (this.state.annexe.length <= 0)
            return false
        if (this.props.isPrivacyNotice === true && this.state.language === null)
            return false
        return true
    }

    /**
     * Handle update properties to set data in local state
     *
     * @param {*} property
     * @param {*} data
     * @memberof AddActionToListDialog
     */
    updateProperty(property, data) {
        this.setState({
            [property]: data,
        }
        )
    }

    /**
     * Handle onsave event of modal to send data to backend and save action
     *
     * @memberof AddActionToListDialog
     */
    handleOnSave() {
        this.props.onAddManual(this.state)
            .then(response => {
                this.setInitialState();
                this.props.onClose();
            })
            .catch(error => {
            })
    }

    /**
     * Handle event upload files of devidence componet to add files to list
     *
     * @param {*} files
     * @returns
     * @memberof AddActionToListDialog
     */
    uploadFiles(files) {
        let filesList = this.state.annexe.concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
        }

        this.setState({
            annexe: filesList
        })
        return Promise.resolve('Ok')
    }

    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
     * @memberof AddActionToListDialog
     */
    removeFiles(fileName) {
        let newList = this.state.annexe.filter(files => {
            return files.name !== fileName
        })
        this.setState({
            annexe: newList
        })
        return Promise.resolve('Ok')
    }

    /**
     * handle event on close or on cancel to clean state of modal
     *
     * @memberof AddActionToListDialog
     */
    handleOnCloseCancel() {
        this.setInitialState();
        if (this.props.onClose)
            this.props.onClose();
    }

    /**
     * Render Text Area component
     *
     * @param {*} key
     * @param {*} translationId
     * @returns
     * @memberof AddActionToListDialog
     */
    renderTextArea(key, translationId, xs = 12) {
        return (
            <Grid item xs={xs} className={this.props.classes.textField}>
                <TextField autoFocus={key === 'name'}
                    key={key}
                    fullWidth
                    label={<Translate id={translationId} />}
                    value={this.state[key] ? this.state[key] : ''}
                    onChange={(event) => { this.updateProperty(key, event.target.value) }}
                    margin="normal"
                />
            </Grid>
        )
    }

    /**
     * Render Privacy Area component
     *
     * @param {*} key
     * @param {*} translationId
     * @returns
     * @memberof AddActionToListDialog
     */
    renderPrivacyArea(key, translationId, xs = 12) {
        return (
            <>
                <Grid item xs={xs} className={this.props.classes.noticeTextField}>
                    <TextField autoFocus={key === 'name'}
                        key={key}
                        label={<Translate id={translationId} />}
                        value={this.state[key] ? this.state[key] : ''}
                        onChange={(event) => { this.updateProperty(key, event.target.value) }}
                    />
                    <FormControlLabel 
                        key={'isActive'} 
                        onChange={(event) => {this.updateProperty('isActive', event.target.checked) }}
                        control={
                            <Switch 
                                checked={this.state.isActive}
                                classes={{
                                    switchBase: this.props.classes.colorSwitchBase,
                                }}
                            />
                        }
                        label={<Translate id ="common.active" />} 
                />
                </Grid>
                <Grid item xs={xs} className={this.props.classes.textField}>
                    <Typography variant={'subtitle2'} color={'textSecondary'}>
                        <Translate id='companies.changePrivacyLanguage' />
                    </Typography>
                    <Select
                        options={GetLanguageOptions()}
                        value={this.state.language}
                        onChange={(languageSelected) => this.setState({ language: languageSelected})}
                        styles={{
                            menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                            menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPosition="fixed" 
                        menuPlacement={'auto'}
                    />
                </Grid>
                {this.state.isActive && (
                    <Grid item xs={xs} className={this.props.classes.noticeTextField}>
                    <SnackbarContent 
                        className={this.props.classes.info}
                        message={
                            <span>
                                <InfoIcon color="inherit"/> <Translate id = "companies.activeWarning"/>                    
                            </span>
                        }
                    />
                </Grid>
                )}
            </>
        )
    }
    
    /**
     * Render evidence component
     *
     * @param {*} key
     * @param {*} translationId
     * @returns
     * @memberof AddActionToListDialog
     */
    renderEvidence(key, translationId, xs = 12) {
        return (
            <Grid item xs={xs} className={this.props.classes.checkBox}>
                <Typography variant="subtitle2" color={'textSecondary'}>
                    <Translate id={translationId} />
                </Typography>
                <Evidence
                    text={this.props.text}
                    baseApi={process.env.REACT_APP_SIP_URL_FILES}
                    removeFiles={this.removeFiles}
                    uploadFiles={this.uploadFiles}
                    acceptedFiles={['pdf']}
                    files={this.state[key] ? this.state[key] : []}
                    disabledLink={!this.props.item}
                />
            </Grid>
        )
    }

    /**
     * Dialog to update information
     */
    render() {
        const { classes } = this.props;
        let open = this.props.open ? this.props.open : false;
        return (
            <Paper style={{ overflowX: 'hidden' }}>
                <Dialog
                    style={{ overflowX: 'hidden' }}
                    open={open}
                    onClose={this.props.onClose}
                    header={
                        <Toolbar>
                            <Typography variant="h6">
                                { <Translate id = {this.props.title ? this.props.title :"menu.tutorials"} />}
                            </Typography>
                        </Toolbar>
                    }

                    actions={
                        <DialogActions
                            view={SHOW_EDIT_VIEW}
                            classes={classes}
                            onCancel={() => this.handleOnCloseCancel()}
                            onClose={this.props.onClose}
                            onSaveItem={this.handleOnSave}
                            isSaving={this.props.isSaving}
                            canBeSaved={this.canBeSaved()}
                        />
                    }>
                    <Grid container style={{ maxWidth: 500}} justify='space-between'>
                        {this.renderTextArea("name", this.props.title ? this.props.title :"menu.tutorials")}
                        {this.props.isPrivacyNotice ? this.renderPrivacyArea("version", "companies.version") : ''}
                        {this.renderEvidence("annexe", "sac.actions.attachments")}
                    </Grid>
                </Dialog>
            </Paper>
        )
    }
}

AddManual.defaultProps = {
    open: false,
}


const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        width: 400,
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
    },
    checkBox:{
        margin: '0 10px',
    },
    noticeTextField: {
        width: 400,
        display:'flex',
        justifyContent: 'space-between',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
    },
    colorSwitchBase: {
        color: grey[300],
        '&$colorChecked': {
            color: theme.palette.secondary.main,
            '& + $colorBar': {
                color: theme.palette.secondary.main,
            },
        },
    },
    info: {
        backgroundColor: blue[300],
    },
});

export default withStyles(dialogStyles, { withTheme: true })(AddManual);