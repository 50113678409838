///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'
import queryString from 'query-string'

/** Material-UI imports section */
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import SustainabilityIcon from '@material-ui/icons/NaturePeople'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import Fab from '@material-ui/core/Fab'
import { Typography, Tooltip } from '@material-ui/core'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import { COLOR_SUSTAINABILITY_PLUM, COLOR_SUSTAINABILITY_ROSE } from '../../store/helpers/StatusColorConstants'

/** Import component section */
import Toaster from '../common/Toaster'
import PanelSustainability from './components/PanelSustainability'
import DataTable from '../common/DataTable'
import { converListToArray } from '../../store/helpers/ListHelper'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'

import {
	loadAllSustainabilityFromBackEnd,
	addSustainability,
	ADD_SUSTAINABILITY,
} from '../../store/sustainability2/SustainabilityActions'

import { IsNullOrEmpty } from '../../store/helpers/StringHelper.js'
import { canBeModuleSustantabilityQuestionnaire } from '../../store/helpers/RolesHelper'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'

/**
 * Component load list
 * @param {*} props
 */
const Index = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()
	const queryParams = queryString.parse(location.search)

	const isLoadingList = useSelector((state) => state.sustainabilityV2.get('isLoading'))
	const sustainabilityList = useSelector((state) =>
		state.sustainabilityV2.get('sustainabilityList')
			? converListToArray(state.sustainabilityV2.get('sustainabilityList'))
			: []
	)
	const isSaving = useSelector((state) => state.sustainabilityV2.get('isSaving'))
	const identifier = useSelector((state) => state.sustainabilityV2.get('identifier'))
	const translate = useSelector((state) => getTranslate(state.localize))
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const sustainabilityCount = useSelector((state) => state.sustainabilityV2.get('sustainabilityCount'))
	const userProfileLogged = useSelector((state) => state.oidc.user)

	const [openLateralPanel, setOpenLateralPanel] = useState(false)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	/**Use effect section */
	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(loadAllSustainabilityFromBackEnd(queryParams, translate))
	}, [dispatch, location.search])

	/**
	 * Function to open side panel
	 */
	const onOpenLateralPanel = () => {
		setOpenLateralPanel(!openLateralPanel)
	}

	/**
	 * Function to open side panel
	 */
	const onOpenTemplate = (event) => {
		props.history.push(`/sustainabilityv2/Template`)
	}

	/**
	 * Function to open calendar
	 */
	const onOpenCalendar = (event) => {
		props.history.push(`/sustainabilityv2/calendar`)
	}

	/**
	 * Function to add folio
	 * @param {*} itemModel
	 */
	const onAddSustainability = (itemModel) => {
		return dispatch(addSustainability(itemModel))
			.then((requestToken) => {
				onOpenLateralPanel()
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
				if (!IsNullOrEmpty(requestToken)) {
					props.history.push(`/sustainabilityv2/${requestToken}`)
				}
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: getErrorInChangeStatus(response),
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Method to show error in change status
	 * @param {*} error
	 */
	const getErrorInChangeStatus = (response) => {
		if (!IsNullOrEmpty(response.data) && typeof response.data === 'string') {
			if (response.data.includes('The Total Percentage')) return 'sustainability.error.templatedInvalid'
			else return 'common.errorToSave'
		} else {
			return 'common.errorToSave'
		}
	}

	/**
	 * onRowClick
	 * @param {*} calendar
	 * @param {*} event
	 */
	const onRowClick = (sustainability, event) => {
		let tagName = event.target.tagName
		if (tagName !== 'svg' && tagName !== 'A') {
			props.history.push(`/sustainabilityv2/${sustainability.Id}`)
		}
	}

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * Get quality
	 */
	const getCounterMessage = () => {
		if (sustainabilityList) {
			let sustainabilitiTotal = sustainabilityList.length
			return (
				<div>
					{<Translate id='common.showing' />}&nbsp;
					<span className={classes.totalCounter}>{sustainabilitiTotal}</span>&nbsp;
					{<Translate id='common.of' />}&nbsp;
					<span className={classes.totalCounter}>{sustainabilityCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	const getCardContent = () => {
		if (isLoadingList === true) {
			return <LoadingPage />
		} else if (sustainabilityList === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={sustainabilityList}
					configuration={TableConfigurationSustainability}
					onRowClick={onRowClick}
					onChangeSortCriteria={onChangeSortCriteria}
					isIndex
					showFilters
					totalRows={sustainabilityCount}
					sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'FormatedFolio'}
					sortAscending={
						queryParams && queryParams.sortDirection
							? queryParams.sortDirection === 'desc'
								? false
								: true
							: false
					}
					page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
					rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
					onChangePage={(newPage, newRowsPerPage) => {
						onChangePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	return (
		<Card>
			<CardHeader
				title={
					<Typography variant='button'>
						{<Translate id='sustainability.sustainabilityQuestionnaire' />}
					</Typography>
				}
				avatar={
					<Avatar>
						<SustainabilityIcon />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						<SearchInput
							onChangeSearchValue={onChangeSearchValue}
							value={!!queryParams && queryParams.query ? queryParams.query : ''}
						/>

						{canBeModuleSustantabilityQuestionnaire(userRolesByCompany) && (
							<Tooltip title={<Translate id='sustainability.createNewQuestionnaire' />}>
								<Fab
									className={classes.marginIcon}
									size='small'
									color='primary'
									onClick={() => {
										onOpenLateralPanel()
									}}
								>
									<AddtIcon />
								</Fab>
							</Tooltip>
						)}
						{IsCurrentActiveDirectoryUser(userProfileLogged) && (
							<Tooltip title={<Translate id='sustainability.showCalendar' />}>
								<Fab
									className={classes.marginIconCalendar}
									size='small'
									color='primary'
									onClick={() => {
										onOpenCalendar()
									}}
								>
									<CalendarIcon />
								</Fab>
							</Tooltip>
						)}
						{canBeModuleSustantabilityQuestionnaire(userRolesByCompany) && (
							<Tooltip title={<Translate id='sustainability.showTemplate' />}>
								<Fab
									className={classes.marginIconFile}
									size='small'
									color='primary'
									onClick={() => {
										onOpenTemplate()
									}}
								>
									<FileCopyIcon />
								</Fab>
							</Tooltip>
						)}
					</div>
				}
				subheader={getCounterMessage()}
			/>
			<CardContent>{getCardContent()}</CardContent>

			{openLateralPanel && (
				<PanelSustainability
					onCloseButtonClick={onOpenLateralPanel}
					onSaveButtonClick={onAddSustainability}
					isSaving={identifier === ADD_SUSTAINABILITY && isSaving}
				/>
			)}

			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</Card>
	)
}

const styles = (theme) => ({
	logo: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	marginIcon: {
		marginLeft: '5px',
	},
	marginIconCalendar: {
		marginLeft: '5px',
		backgroundColor: COLOR_SUSTAINABILITY_PLUM,
	},
	marginIconFile: {
		marginLeft: '5px',
		backgroundColor: COLOR_SUSTAINABILITY_ROSE,
	},
	showingCounter: {
		color: theme.palette.secondary.main,
	},
})

const TableConfigurationSustainability = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'Status',
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'FormatedFolio',
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'CreationDate',
		},
		{
			header: <Translate id='common.createBy' />,
			dataSource: 'CreatedName',
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'FullSupplier',
		},
	],
}

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Index)))
