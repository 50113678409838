import React from 'react'
/* Material UI import section */

import { Fab, Card, CardHeader, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AddIcon from '@material-ui/icons/AddTwoTone'
import DeleteIcon from '@material-ui/icons/Delete'
import CommentIcon from '@material-ui/icons/Comment'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'

/** Custom components import section */
import DataTable from '../../common/DataTable'
import SearchInput from '../../common/SearchInput/SearchInput'

const PhoneCategoryTable = (props) => {
	const {
		onOpenCloseCreatePhoneDirectoryLateralPanel,
		onOpenConfirmDeleteDialog,
		onChangeQuery,
		onSetCategoryMaterialsDescription,
		onOpenEditCatDialog,
		canBeEditInformation,
		queryParams,
		onChangePage,
	} = props
	const classes = useStyles()

	const { items, pageNumber, pageSize, total, sort } = props.phoneDirectoriesPaginated

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'activityMaterials') {
			return (
				<div>
					<IconButton size='small' onClick={() => onSetCategoryMaterialsDescription(item.activityMaterials)}>
						<CommentIcon />
					</IconButton>
				</div>
			)
		}
		if (dataSource === 'Options' && canBeEditInformation) {
			return (
				<div>
					<IconButton size='small' onClick={() => onOpenConfirmDeleteDialog(item)}>
						<DeleteIcon />
					</IconButton>
					<IconButton size='small' onClick={() => onOpenEditCatDialog(item)}>
						<EditIcon />
					</IconButton>
				</div>
			)
		}
		if (dataSource === 'name') {
			return item.name
		}
	}

	return (
		<div>
			<Card className={classes.contentColor}>
				<CardHeader
					avatar={<ImportContactsIcon color='primary' />}
					action={
						<div className={classes.flex}>
							<div className={classes.search}>
								<SearchInput
									onChangeSearchValue={onChangeQuery}
									value={!!queryParams && queryParams.query ? queryParams.query : ''}
								/>
							</div>

							{canBeEditInformation && (
								<Fab size='small' color='primary' onClick={onOpenCloseCreatePhoneDirectoryLateralPanel}>
									<AddIcon />
								</Fab>
							)}
						</div>
					}
					title={<Typography variant='button'>Información de contactos agregados</Typography>}
				/>
			</Card>
			<DataTable
				data={items}
				configuration={{ columns: tableConfiguration(canBeEditInformation) }}
				onRenderCellItem={onRenderCellItem}
				isIndex
				page={pageNumber}
				rowsPerPage={pageSize}
				totalRows={total}
				sortBy={sort.by}
				sortAscending={sort.direction}
				onChangePage={onChangePage}
			/>
		</div>
	)
}

/**
 * Set the service orders table configuration
 */
const tableConfiguration = (canBeEditInformation) => {
	let defaultColumns = [
		{
			header: 'Nombre',
			dataSource: 'name',
			isMovable: false,
		},
		{
			header: 'Puesto',
			dataSource: 'position',
			isMovable: false,
		},
		{
			header: 'Correo electrónico',
			dataSource: 'email',
			isMovable: false,
		},
		{
			header: 'Número telefóno',
			dataSource: 'phoneNumber',
			isMovable: false,
		},
		{
			header: 'Jefe directo',
			dataSource: 'directBoss',
			isMovable: false,
		},
		{
			header: 'Categoría y/o materiales',
			dataSource: 'activityMaterials',
			isMovable: false,
		},
	]
	if (canBeEditInformation) {
		defaultColumns.push({
			header: 'Opciones',
			dataSource: 'Options',
			isMovable: false,
		})
	}
	return defaultColumns
}

const useStyles = makeStyles((theme) => ({
	contentColor: {
		// backgroundColor: theme.palette.background.paper,
		// backgroundColor: '#fafafa',
	},
	flex: {
		display: 'flex',
	},
	// dialogTitle: {
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	'& > *': {
	// 		marginLeft: 5 //theme.spacing(0.5)
	// 	}
	// },
	// title: {
	// 	flex: '1 1 0'
	// }
}))

export default PhoneCategoryTable
