import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import SendEmailIcon from '@material-ui/icons/Email'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import DataTable from '../common/DataTable'
import UserSelector from '../common/UserSelector'

import {
	loadQualityFailureReportFromBackEnd,
	sendNotificationQualityFailureReport,
} from '../../store/qualityFailureReport/QualityFailureReportAction'
import UserInRoleSelector from '../common/UserInRoleSelector'
import {
	ROLE_LOGISTICPLANNER,
	ROLE_SUPPLIERDEVELOPMENTENGINEER,
	ROLE_SUPPLIER_QUALITYNGINEER,
} from '../../store/helpers/RolesHelper'
import { searchUsersInRole } from '../../store/administration/InternalActions'
import { COMPANY_PTM } from '../../store/helpers/ResourcesHelper'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'

/**
 * Container to send notification
 */
class SendNotification extends Component {
	/**
	 * Create an instance of raw material
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			planningSelected: null,
			developmentSelected: null,
			users: null,
			usersInternalList: [],
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let qualityFailureToken = this.props.match.params.token
		this.props.loadQualityFailureReport(qualityFailureToken)
	}

	// /**
	//  * componentDidUpdate
	//  * @param {*} prevProps
	//  * @param {*} prevState
	//  * @memberof Index
	//  */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoading !== this.props.isLoading && this.props.qualityFailure !== null) {
			/// Get information full from corrective
			let qualityFailure = this.props.qualityFailure
			/// Get User by role from supplier
			this.props
				.searchUsers(
					ROLE_SUPPLIER_QUALITYNGINEER,
					qualityFailure.supplier_code ? qualityFailure.supplier_code : null
				)
				.then((response) => {
					this.setState({ users: response })
				})
		}
	}

	/**
	 * On seletecd planning Engineer
	 */
	onPlanningSelected(planning) {
		this.setState({ planningSelected: planning })
	}

	/**
	 * On seletecd supplier Development Engineer
	 */
	onDevelopmentSelected(development) {
		this.setState({ developmentSelected: development })
	}

	/**
	 * Can be add item in send notification
	 */
	canBeSaved() {
		if (this.state.planningSelected === null) return false
		if (this.state.developmentSelected === null) return false
		return true
	}

	/**
	 * Get action to send failure quality report
	 */
	sendNotificationQualityReport() {
		this.props
			.sendNotificationQualityFailureReport(
				this.state.planningSelected,
				this.state.developmentSelected,
				this.state.usersInternalList
			)
			.then(() => {
				this.setState({
					showToView: true,
					showToaster: false,
				})
				let qualityFailureToken = this.props.match.params.token
				this.props.history.push(`/qualityfailurereport/${qualityFailureToken}`)
			})
			.catch((error) => {
				this.setState({
					showToaster: true,
					toasterMessage: !IsNullOrEmpty(error) ? error : <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * On send notification users
	 * @param {} user
	 */
	onSelectedSupplierClick(user) {
		if (this.state.usersInternalList) {
			let selectedUser = this.state.usersInternalList.find((userTodd) => {
				return userTodd.user_name === user.user_name
			})
			if (!selectedUser) {
				this.setState({
					usersInternalList: this.state.usersInternalList.concat(user),
				})
			} else {
				this.setState({
					showToaster: true,
					toasterMessage: 'El usuarios ya existe agregue un difernee',
					toasterVariant: 'error',
				})
			}
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { classes } = this.props
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.qualityFailure === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<Grid container>
					<Grid item xs={12} sm={6}>
						<div className={classes.textField}>
							<Typography variant='subtitle1' gutterBottom>
								<Translate id='rawmaterialnonconformity.planningEngineer' />
							</Typography>
							<UserInRoleSelector
								className={classes.textField}
								role={ROLE_LOGISTICPLANNER}
								onUserSelected={(planning) => {
									this.onPlanningSelected(planning)
								}}
							/>
						</div>

						<div className={classes.textField}>
							<Typography variant='subtitle1' gutterBottom>
								<Translate id='rawmaterialnonconformity.supplierDevelopmentEngineer' />
							</Typography>
							<UserInRoleSelector
								className={classes.textField}
								role={ROLE_SUPPLIERDEVELOPMENTENGINEER}
								onUserSelected={(development) => {
									this.onDevelopmentSelected(development)
								}}
							/>
						</div>

						{this.props.qualityFailure.company_identifier === COMPANY_PTM && (
							<div className={classes.textField}>
								<Grid item xs={12}>
									<Typography variant='caption' gutterBottom color={'textSecondary'}>
										{'Agregar usuarios adicionales para enviar notificación'}
									</Typography>
									<UserSelector
										onUserSelected={(sucursalUser) => {
											this.onSelectedSupplierClick(sucursalUser)
										}}
									/>
									<DataTable
										data={this.state.usersInternalList !== null ? this.state.usersInternalList : []}
										configuration={TableConfigurationInternal}
									/>
								</Grid>
							</div>
						)}

						<div className={classes.textField}>
							<Button
								data-cy='btn-save'
								type='button'
								color='primary'
								onClick={this.sendNotificationQualityReport}
								disabled={!this.canBeSaved() || this.props.isSaving}
							>
								{<Translate id='common.save' />}
							</Button>
							<Button
								onClick={() => {
									this.props.history.push(`/qualityfailurereport/${this.props.match.params.token}`)
								}}
								color='secondary'
							>
								{<Translate id='common.cancel' />}
							</Button>
						</div>
					</Grid>

					<Grid item xs={12} sm={6}>
						<div className={classes.textField}>
							<DataTable
								data={this.state.users !== null ? this.state.users : []}
								configuration={TableConfiguration}
							/>
						</div>
					</Grid>
				</Grid>
			)
		}
	}

	render() {
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<SendEmailIcon />
						</Avatar>
					}
					title={
						<Typography variant='button'>
							<Translate id='qualityfailurereport.sendNotificationQuality' />
						</Typography>
					}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='rawmaterialnonconformity.usersSupplierQuality' />,
			dataSource: 'name',
		},
	],
}

const TableConfigurationInternal = {
	columns: [
		{
			header: 'Usuarios para enviar la notificación',
			dataSource: 'full_name',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let qualityFailure = state.qualityFailureReport.get('qualityFailureReport')
	return {
		isLoading: state.qualityFailureReport.get('isLoading'),
		qualityFailure: qualityFailure ? qualityFailure.toJS() : null,
		isSaving: state.qualityFailureReport.get('isSaving'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadQualityFailureReport: (token) => {
			return dispatch(loadQualityFailureReportFromBackEnd(token))
		},
		sendNotificationQualityFailureReport: (planningSelected, developmentSelected, usersInternal) => {
			return dispatch(sendNotificationQualityFailureReport(planningSelected, developmentSelected, usersInternal))
		},
		searchUsers: (role, supplierCode) => {
			return dispatch(searchUsersInRole(role, supplierCode))
		},
	}
}

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	actionsToolbar: {
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 500,
		marginTop: 20,
		marginBottom: 10,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(SendNotification)))
)
