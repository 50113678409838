/** Helpers import section */
import axios from 'axios';

import {
    getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserParameters,
    getAuthorizedRequestConfigurationUserFiles
} from '../helpers/SessionHelper';
import { supplierData } from '../helpers/BasePdfData';
import { underScoreToTitleCase } from '../helpers/StringHelper';

export const START_LOADING_SAMPLE_REQUESTS = "START_LOADING_SAMPLE_REQUESTS";
export const SET_SAMPLE_REQUESTS_LIST = "SET_SAMPLE_REQUESTS_LIST";
export const CHANGE_SAMPLE_REQUESTS_SORT_CRITERIA = "CHANGE_SAMPLE_REQUESTS_SORT_CRITERIA";
export const CHANGE_SAMPLE_REQUESTS_QUERY = "CHANGE_SAMPLE_REQUESTS_QUERY";
export const CHANGE_SAMPLE_REQUESTS_PAGINATION = "CHANGE_SAMPLE_REQUESTS_PAGINATION";
export const SET_SAMPLE_REQUESTS_COUNT = "SET_SAMPLE_REQUESTS_COUNT";
export const IS_LOADING_SAMPLE_REQUEST = "IS_LOADING_SAMPLE_REQUEST";
export const SET_SAMPLE_REQUEST = "SET_SAMPLE_REQUEST";
export const IS_SAVING_SAMPLE_REQUEST = "IS_SAVING_SAMPLE_REQUEST";
export const ADD_ANNOTATION_LIST_TO_RMI = "ADD_ANNOTATION_LIST_TO_RMI";
export const REMOVE_ANNOTATION_LIST_TO_RMI = "REMOVE_ANNOTATION_LIST_TO_RMI";
export const UPDATE_ANNOTATION_LIST_TO_RMI = "UPDATE_ANNOTATION_LIST_TO_RMI";
export const ADD_FILES_SAMPLE_REQUEST_SUPPLIER = "ADD_FILES_SAMPLE_REQUEST_SUPPLIER";
export const REMOVE_FILE_SAMPLE_REQUEST_SUPPLIER = "REMOVE_FILE_SAMPLE_REQUEST_SUPPLIER";
export const ADD_FILES_SAMPLE_REQUEST_COMPANY = "ADD_FILES_SAMPLE_REQUEST_COMPANY";
export const REMOVE_FILES_SAMPLE_REQUEST_COMPANY = "REMOVE_FILES_SAMPLE_REQUEST_COMPANY";

/**
 * Action start loading sample request
 * @param {*} isLoading 
 */
function isLoadingSampleRequest(isLoading) {
    return {
        type: START_LOADING_SAMPLE_REQUESTS,
        isLoading: isLoading
    }
}

/**
 * Action to set sample requests list 
 * @param {*} samples 
 */
function setSampleRequestsList(samples) {
    return {
        type: SET_SAMPLE_REQUESTS_LIST,
        samples: samples
    }
}

/**
 * Action to start load sample
 */
function startLoadSample(isLoading) {
    return {
        type: IS_LOADING_SAMPLE_REQUEST,
        isLoadingSample: isLoading
    }
}

/**
 * Action to set sample
 * @param {*} sample 
 */
function setSampleRequest(sample) {
    return {
        type: SET_SAMPLE_REQUEST,
        sample: sample
    }
}

/**
 * Action reducer to set the sample's counter
 *
 * @param {*} count
 * @returns
 */
function setSampleRequestsCount(count) {
    return {
        type: SET_SAMPLE_REQUESTS_COUNT,
        count
    }
}

/**
 * Action start loading samples
 * @param {*} isLoading 
 */
function isSaving(isSaving) {
    return {
        type: IS_SAVING_SAMPLE_REQUEST,
        isSaving: isSaving
    }
}

/**
 * Action to add anotation from rmi
 * @param {*} annotation 
 */
function addAnnotationFromRMI(annotation) {
    return {
        type: ADD_ANNOTATION_LIST_TO_RMI,
        annotation: annotation
    }
}

/**
 * Action to remove annotation from RMI
 * @param {*} annotationToken 
 */
function removeAnnotationFromRMI(annotationToken) {
    return {
        type: REMOVE_ANNOTATION_LIST_TO_RMI,
        annotationToken: annotationToken
    }
}

/**
 * Action to update annotation fro RMI
 * @param {*} annotation 
 */
function updateAnnotationFromRMI(annotation) {
    return {
        type: UPDATE_ANNOTATION_LIST_TO_RMI,
        annotation: annotation
    }
}

/**
 * Add file to state by supplier
 *
 * @param {*} files
 * @returns
 */
function addFileBySupplier(files) {
    return {
        type: ADD_FILES_SAMPLE_REQUEST_SUPPLIER,
        files
    }
}

/**
 * Remove file of state
 *
 * @param {*} fileName
 * @returns
 */
function removeFileBySupplier(fileName) {
    return {
        type: REMOVE_FILE_SAMPLE_REQUEST_SUPPLIER,
        fileName
    }
}

/**
 * Add file to state by company
 *
 * @param {*} files
 * @returns
 */
function addFileByCompany(files) {
    return {
        type: ADD_FILES_SAMPLE_REQUEST_COMPANY,
        files: files
    }
}

/**
 * Remove file of state company
 *
 * @param {*} fileName
 * @returns
 */
function removeFileByCompany(fileName) {
    return {
        type: REMOVE_FILES_SAMPLE_REQUEST_COMPANY,
        fileName: fileName
    }
}

/**
 * Get all sample request by company from backend 
 */
export function loadSampleRequestsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {
        dispatch(isLoadingSampleRequest(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/samples`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page + 1}`,
            RowsPerPage: rowsPerPage,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSampleRequestsList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingSampleRequest(false));
                console.error("Error getting the sample rquest by company list", error.response)
            })
    }
}


/**
 * Get sample's count
 *
 * @export
 * @returns
 */
export function getSampleRequestsCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().samplerequests.get('samplesCount');
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let supplierToken = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierToken = getState().oidc.user.profile.company_token;
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/count`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SupplierToken: supplierToken,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSampleRequestsCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the sample's count", error.response)
            })
    }
}


/**
 * Get and set the sample in the estate from read from backend
 * @param {*} token 
 */
export function loadSampleRequestFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(startLoadSample(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/sample/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    console.log({data:serverResponse.data})
                    dispatch(setSampleRequest(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load sample", error.response)
                dispatch(startLoadSample(false))
                return Promise.reject();
            })
    }
}



/**
 * Decline quite by supplier
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function declineSampleRequestBySupplier(token, commnents) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            Commnents: commnents,
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/${token}/declinesample`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSampleRequest(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Errorin decline sample request ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to send sample request to client
 */
export function sendSampleRequestToClient(token, deliveryDate, commentsSupplier, releaseLevel) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            Commnents: commentsSupplier,
            DeliveryDate: deliveryDate,
            ReleaseLevel: releaseLevel,
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/${token}/registerdelivery`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSampleRequest(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Errorin decline sample request ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to add annotation to RIM document
 */
export function addAnnotationToRMIDocument(token, reference) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            Reference: reference.reference,
            Specification: reference.specification,
            Tolerance: reference.tolerance,
            MeasurementUnit: reference.unitMeasure,
            InspectionResultBySupplierPiece1: reference.piece1,
            InspectionResultBySupplierPiece2: reference.piece2,
            InspectionResultBySupplierPiece3: reference.piece3,
            InspectionResultBySupplierPiece4: reference.piece4,
            InspectionResultBySupplierPiece5: reference.piece5
        }

        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/anotationrmi/${token}`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addAnnotationFromRMI(serverResponse.data))
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in add annotaation in rmi request ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Remove a annotation to RMI document requests
 * @param {*} annotationToken 
 */
export function removeAnnotationToRMIDocument(annotationToken) {
    return (dispatch, getState) => {

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/anotationrmi/${annotationToken}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeAnnotationFromRMI(annotationToken))
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                console.error("Error removing a annotation from a rmi", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to add annotation to RIM document
 */
export function updateAnnotationToRMIDocument(reference) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            Reference: reference.reference,
            Specification: reference.specification,
            Tolerance: reference.tolerance,
            MeasurementUnit: reference.unitMeasure,
            InspectionResultBySupplierPiece1: reference.piece1,
            InspectionResultBySupplierPiece2: reference.piece2,
            InspectionResultBySupplierPiece3: reference.piece3,
            InspectionResultBySupplierPiece4: reference.piece4,
            InspectionResultBySupplierPiece5: reference.piece5
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/anotationrmi/${reference.token}`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(updateAnnotationFromRMI(serverResponse.data))
                    dispatch(isSaving(false))

                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in update annotaation in rmi request ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Method to update rmi document information basic
 */
export function updateDocumentRMi(tokenRMI, equipment, supplierObservations) {
    return function (dispatch, getState) {

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/rmidocumet/${tokenRMI}`;
        let data = {
            SupplierObservations: supplierObservations,
            Equipment: equipment,
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    /// Actualizar rMI
                }
            })
            .catch((error) => {
                console.error("Error to load quote", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Method to update rmi document information basic by engineerObservations
 */
export function updateDocumentRMiByEngineerObservations(tokenRMI, engineerObservations) {
    return function (dispatch, getState) {
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/rmidocumet/${tokenRMI}`;
        let data = {
            EngineerObservations: engineerObservations
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    /// Actualizar rMI
                }
            })
            .catch((error) => {
                console.error("Error to load quote", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to send rmi to client
 */
export function sendRMIToClient(token) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            IsInlineProductsSupplier: false,
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/${token}/sendrmitoclient`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSampleRequest(serverResponse.data))
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in send rmi to client from sample request ", error.response)
                return Promise.reject(error.response);
            })
    }
}

/**
 * Action to register sample  arrive by client
 */
export function registerSampleArrive(token, arriveDate, numberSample) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            DeliveryDate: arriveDate,
            NumberOfSamplesReceived: numberSample,
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/${token}/registersamplearrive`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    dispatch(setSampleRequest(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in register arrive date to sample request ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to update annotation to rmi document by client add inspection
 */
export function updateInspectionByClient(reference) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            InspectionResultByEngineering: reference.inspection_result_by_engineering,
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/anotationrmi/${reference.token}`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    dispatch(updateAnnotationFromRMI(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in update annotaation in rmi request ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to accepted In Rejected Annotation By Client
 */
export function acceptedOrRejectedAnnotationByClient(token, disposition) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            Disposition: disposition,
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/anotationrmi/${token}`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    dispatch(updateAnnotationFromRMI(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in acceted or rejected annotaation in rmi request ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to acepted RMI nd sample request by client
 */
export function acceptRMISampleRequestByClient(token) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        const user = getState().oidc.user;
        const configuration = getAuthorizedRequestConfigurationUser(user);
        
        const supplier = getState().suppliers.get("supplier");
        const supplierInformation = supplier ? supplier.toJS() : {};
        const data = {
            Supplier: supplierData(supplierInformation),
            CreateBy: user.profile.name
        }

        const endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/${token}/acceptedtrmisample`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    dispatch(setSampleRequest(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in accepted rmi by client from sample request ", error.response)
                return Promise.reject(error.response);
            })
    }
}

/**
 * Action to rejected RMI nd SAmple request by client
 */
export function rejectRMISampleRequestByClient(token) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            CreateBy: user.profile.name,
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/${token}/rejectedrmisample`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSampleRequest(serverResponse.data))
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in rejected rmi by client from sample request ", error.response)
                return Promise.reject(error.response);
            })
    }
}

/**
 * Action to sent rmi active the supplier from modify
 * @param {*} token 
 * @param {*} commnents 
 */
export function rmiActivateToTheSupplierForModify(token, commnents) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            TokenSample: token,
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            Comments: commnents
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/${token}/reactivetosuppier`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSampleRequest(serverResponse.data))
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in sent rmi active the supplier from modify ", error.response)
                return Promise.reject();
            })
    }
}


/**
 * Action to upload files to supplier from sample
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadFilesBySupplier(sampleToken, files) {
    return function (dispatch, getState) {
        let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequest/${sampleToken}/uploadfilebysupplier`;
        let formData = new FormData();
        for (var index = 0; index <= files.length; index++) {
            formData.append('file', files[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addFileBySupplier(serverResponse.data))
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                console.error("Error to upload sample files from supplier file(s)", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to remove files to supplier from saplme
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeFilesBySupplier(sampleToken, fileName) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequest/${sampleToken}/removefilesbysupplier`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeFileBySupplier(fileName))
                }
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to upload quote file(s)", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to upload files to company from sample
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadFilesByCompany(sampleToken, files) {
    return function (dispatch, getState) {
        let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequest/${sampleToken}/filesbycompany`;
        let formData = new FormData();
        for (var index = 0; index <= files.length; index++) {
            formData.append('file', files[index]);
        }
        return axios.post(endPoint, formData, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(addFileByCompany(serverResponse.data))
                    return Promise.resolve();
                }
            })
            .catch((error) => {
                console.error("Error to upload sample files from company file(s)", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to remove files to company from sample
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function removeFilesByCompany(sampleToken, fileName) {
    return function (dispatch, getState) {
        let params = {
            name: fileName
        }
        let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequest/${sampleToken}/filesbycompany`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeFileByCompany(fileName))
                }
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to upload company file(s)", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to Download sample RMI nd sample request 
 */
export function downloadSampleRmiReportPdf(token) {
    return (dispatch, getState) => {

        let supplierInformation = getState().suppliers.get("supplier").toJS();
        let data = {
            Supplier: supplierData(supplierInformation),
        }

        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/${token}/pdf`;
        return axios.post(endPoint, data, { responseType: 'arraybuffer' })
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    var file = new Blob([serverResponse.data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
            })
            .catch((error) => {
                console.error("Error in download rmi document ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to reactive rmi closed
 */
export function reactiveRmiClosed(sampleToken, commnents) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            WorkflowToken: sampleToken,
            CreatorUserName: user.profile.preferred_username,
            CreateBy: user.profile.name,
            Comments: commnents
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sampleresquest/${sampleToken}/reactivermi`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    dispatch(setSampleRequest(serverResponse.data))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in reactive rmi from sample request ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Decline quite by supplier
 *
 * @export
 * @param {*} memoPartNumber
 * @param {*} supplier
 * @returns
 */
export function acceptRejectAnnotationsRMIFromQuality(itemTokens, status) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);
        let data = {
            Disposition: status,
            SelectedTokens: itemTokens,
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/samplerequests/annotations/selectedTokens/updatestatus`;
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    serverResponse.data.forEach(item => {
                        dispatch(updateAnnotationFromRMI(item))
                    });
                    dispatch(isSaving(false))
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error in accepted, rejected items in rmi ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to Download sample request by month report
 *
 * @export
 * @param {*} month
 * @returns
 */
export function downloadReportByDateRange(startDate, endDate) {
    return (dispatch, getState) => {
        dispatch(startLoadSample(true));
        let companyToken = getState().profile.get("selectedCompany").get('token');
        let data = {
            CompanyToken: companyToken,
            StartDate: startDate,
            EndDate: endDate
        }
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sampleresquest/downloadReportByRangeDate`;
        return axios.post(endPoint, data, { responseType: 'arraybuffer' })
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(startLoadSample(false));
                    var blob = new Blob([serverResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reporte_de_tiempos_de_respuesta_SMM.xlsx";
                    a.click();
                    return Promise.resolve('Ok');
                }
            })
            .catch((error) => {
                dispatch(startLoadSample(false));
                console.error("Error in download sample request report by month", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action update sample request
 * @param {*} token 
 * @param {*} isReleasedByParentCode 
 * @param {*} descriptionParentCode 
 */
export function updateSampleRequest(token, property, propertyData) {
    return function (dispatch, getState) {

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/sampleresquest/${token}`;
        let data = {
            [underScoreToTitleCase(property)]: propertyData
        }
        return axios.put(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setSampleRequest(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error in update sample request", error.response)
                return Promise.reject();
            })
    }
}
