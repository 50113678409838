import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { searchShareCompanies } from '../../../store/suppliers/SupplierActions'
import { Translate } from 'react-localize-redux'

import Select from 'react-select/async'
import { CircularProgress } from '@material-ui/core'
import Toaster from '../../common/Toaster'

/**
 * Create Suppliers and Companies selector
 *
 * @class SuppliersCompaniesSelector
 * @extends {Component}
 */
class SuppliersCompaniesSelector extends Component {
	/**
	 *Creates an instance of SuppliersCompaniesSelector.
	 * @param {*} props
	 * @memberof SuppliersCompaniesSelector
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			suppliersList: [],
			isSearching: false,
			selectedSupplier: '',
		}
	}

	/**
	 * React life-cycle
	 * If component did mount then load suppliers list on redux state
	 *
	 * @memberof SuppliersCompaniesSelector
	 */
	componentDidMount() {
		// this.setState({ isSearching: true })
		// this.props.searchShareCompanies('').then(
		//     this.setState({ isSearching: false })
		// )
	}

	/**
	 * React life-cycle
	 * select default option if prop.value is found in list
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof UserSelector
	 */
	// componentDidUpdate(prevProps, prevState) {
	//     if (this.props.suppliers !== prevProps.suppliers) {
	//         let selectedSupplier = this.props.suppliers.find((supplier) => {
	//             return supplier.user_name === this.props.value
	//         })
	//         if (selectedSupplier) {
	//             selectedSupplier = { label: selectedSupplier.full_name, value: selectedSupplier.user_name }
	//             this.setState({ selectedSupplier })

	//         }
	//     }
	// }

	componentDidUpdate(prevProps, prevState) {
		if (this.props.value) {
			if (this.state.suppliers.length !== prevState.suppliers.length || this.props.value !== prevProps.value) {
				let selectedSupplier = this.state.suppliers.find((supplier) => {
					return supplier.token === this.props.value
				})
				if (selectedSupplier) {
					selectedSupplier = { label: selectedSupplier.full_name, value: selectedSupplier.token }
					this.setState({ selectedSupplier })
				}
			}
		}
	}

	/**
	 * handler to search options in suppliers list
	 *
	 * @param {*} query
	 * @returns
	 * @memberof SuppliersCompaniesSelector
	 */
	search(query) {
		this.setState({ isSearching: true })
		let filteredList = this.props.suppliers.filter((supplier) => {
			return supplier.full_name.toLowerCase().includes(query.toLowerCase())
		})
		return Promise.resolve(filteredList)
	}
	/**
	 * Get the supplier matching with a query
	 *
	 * @param {*} query
	 * @returns
	 * @memberof SuppliersCompaniesSelector
	 */
	// getSuppliers(query) {
	//     return this.search(query)
	//         .then((suppliersList) => {
	//             this.setState({
	//                 suppliersList,
	//                 isSearching: false
	//             })
	//             return suppliersList.map((supplier) => {
	//                 return {
	//                     value: supplier.token,
	//                     label: supplier.full_name
	//                 }
	//             })
	//         })
	// }

	getSuppliers(query) {
		return this.props.searchShareCompanies(query).then((suppliersList) => {
			this.setState({
				suppliersList,
				isSearching: false,
			})
			return suppliersList.map((supplier) => {
				return {
					value: supplier.token,
					label: supplier.full_name,
				}
			})
		})
	}

	/**
	 * Method to determine if input value has >=3 characters
	 * then isSearching is true and filter results
	 *
	 * @param {*} value
	 * @returns
	 * @memberof SuppliersCompaniesSelector
	 */
	onInputChange(value) {
		let isSearching = value.length >= 3
		this.setState({ isSearching })
		return value
	}
	/**
	 * On selec a supplier
	 *
	 * @param {*} option
	 * @memberof SuppliersCompaniesSelector
	 */
	onSelectedSupplierClick(option) {
		if (this.state.suppliersList) {
			let selectedSupplier = this.state.suppliersList.find((supplier) => {
				return supplier.token === option.value
			})
			if (selectedSupplier && this.props.onSupplierSelected) {
				this.setState({
					selectedSupplier: { label: selectedSupplier.full_name, value: selectedSupplier.user_name },
				})
				this.props.onSupplierSelected(selectedSupplier)
			} else {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			}
		}
	}

	/**
	 * Method to determine place holder message
	 *
	 * @returns
	 * @memberof SuppliersCompaniesSelector
	 */
	getPlaceholder() {
		let id = 'suppliers.searchSuppliers'
		if (this.state.isSearching || this.props.isLoadingSuppliers) id = 'common.loadingPleaseWait'

		return <Translate id={id} />
	}

	/**
	 *
	 *
	 * @returns
	 * @memberof SuppliersCompaniesSelector
	 */
	render() {
		let selectedSupplier = this.state.suppliersList.find((user) => {
			return user.user_name === this.props.value
		})
		if (selectedSupplier)
			selectedSupplier = { label: selectedSupplier.full_name, value: selectedSupplier.user_name }

		return (
			<React.Fragment>
				<Select
					placeholder={this.getPlaceholder()}
					value={this.state.selectedUser}
					loadOptions={this.state.isSearching ? this.getSuppliers : ''}
					cacheOptions
					onChange={this.onSelectedSupplierClick}
					onInputChange={this.onInputChange}
					isDisabled={
						this.props.isLoadingSuppliers || this.props.isDisabled
							? this.props.isLoadingSuppliers || this.props.isDisabled
							: false
					}
					isLoading={this.props.isLoadingSuppliers || this.state.isSearching}
					styles={customStyles}
					components={{ LoadingIndicator }}
				/>
				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</React.Fragment>
		)
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		isLoadingSuppliers: state.suppliers.get('isLoadingSuppliers'),
		suppliers: state.suppliers.get('suppliers') ? state.suppliers.get('suppliers').toJS() : null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		searchShareCompanies: (query) => {
			return dispatch(searchShareCompanies(query))
		},
	}
}

/** Custom styles for the component */
const customStyles = {
	menu: (base) => ({ ...base, zIndex: 20 }),
	loadingIndicator: (base) => ({ ...base, zIndex: 60 }),
}

/** Custom loading component */
const LoadingIndicator = (props) => {
	return <CircularProgress color='secondary' />
}
export default connect(mapStateToProps, mapDispatchToProps)(SuppliersCompaniesSelector)
