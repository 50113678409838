import React, { Component } from 'react';
import Dialog from '../../../common/Dialog';
import DialogActions from '../../../common/DialogActions';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Translate } from 'react-localize-redux';
import Grid from '@material-ui/core/Grid';
import { SHOW_EDIT_VIEW } from './../../../../store/helpers/ResourcesHelper';
import AlertIcon from '@material-ui/icons/ErrorOutline';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

class DeleteConfirmationModal extends Component {
    render() {
        const { classes, item } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.props.onClose()}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id={this.props.title} />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <DialogActions
                                view={SHOW_EDIT_VIEW}
                                classes={classes}
                                onCancel={() => this.props.onClose()}
                                onClose={() => this.props.onClose()}
                                onSaveItem={this.props.onRemoveMaterial}
                                isSaving={this.props.isSaving}
                                canBeSaved={true}
                                saveButtonTranslate="common.delete"
                            />
                        </Grid>
                    </Grid>
                }>
                <Grid container className={classes.modal} justify="space-between">
                    <Grid item xs={12} >
                        <Typography variant="body1">
                            <AlertIcon color="primary" />
                            <Translate id="deliveryOrderModule.removeMaterialConfirmation" />
                        </Typography>
                        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="body2">
                            <Translate id="deliveryOrderModule.materialsTable.code" />:
                            {` ${item && item.part_number_code}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="body2">
                            <Translate id="deliveryOrderModule.materialsTable.purchaseOrder" />:
                            {` ${item && item.purchase_order}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="body2">
                            <Translate id="deliveryOrderModule.materialsTable.quantity" />:
                            {` ${item && item.quantity_of_material}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

const styles = theme => ({
    modal: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxWidth: "fit-content"
    },
    textField: {
        marginBottom: theme.spacing.unit * 2
    },
    checkBox: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    }
});

export default withStyles(styles, { withTheme: true })(DeleteConfirmationModal);