import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInputOptions from '../common/SearchInput/SearchInputOptions'
import Toaster from '../common/Toaster'
import DataTable from '../common/DataTable'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import SacsQualityIcon from '@material-ui/icons/Report'
import AddIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import {
	loadSacsQualityFromBackEnd,
	getSacsQualityCount,
	CHANGE_SACS_QUALITY_SORT_CRITERIA,
	CHANGE_SACS_QUALITY_QUERY,
	downloadCorrectiveActionReport,
	CHANGE_SACS_QUALITY_PAGINATION,
	IS_LOADING_ALL_SACS_REPORTS,
} from '../../store/sacsQuality/SacsQualityActions'
import TrackingStatus from '../common/TrackingStatus'
import { converListToArray } from '../../store/helpers/ListHelper'
import { canUserCreateReportModuleQuality } from '../../store/helpers/RolesHelper'

/**
 * Corrective actions quality  view
 *
 * @class Corrective actions
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance SacsQuality
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadSacsQuality(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.props.isAllReports
			)
			this.props.getSacsQualityCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadSacsQuality()
			this.props.getSacsQualityCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage ||
			prevProps.isAllReports !== this.props.isAllReports
		) {
			this.props.loadSacsQuality(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage,
				this.props.isAllReports
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.onLoadingAllReports(false)
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * On change get all reports
	 */
	onChangeGetAllReports() {
		this.props.onLoadingAllReports(true)
	}

	/**
	 * Method to custom render in datalist
	 *
	 * @param {*} dataSource
	 * @param {*} item
	 * @returns
	 * @memberof Index
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'tracking') {
			return <TrackingStatus tracking={item.tracings} />
		}
	}

	/**
	 *On download report corrective actions
	 *
	 * @memberof Index
	 */
	handleDownloadReportCorrectiveAction() {
		this.props
			.downloadCorrectiveActionReport()
			.then(() => {
				this.showSuccessToaster('common.downloadCorrectly')
			})
			.catch(() => {
				this.showErrorToaster('common.errorToDownload')
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingSacsQuality === true) {
			return <LoadingPage />
		} else if (this.props.sacsQuality === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			let tableConfiguration = TableInternalConfiguration
			if (!IsCurrentActiveDirectoryUser(this.props.user)) {
				tableConfiguration = TableExternalConfiguration
			}
			return (
				<DataTable
					data={this.props.sacsQuality}
					configuration={tableConfiguration}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(sacQuality) => {
						this.props.history.push(`/sacsquality/${sacQuality.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.sacsQualityCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get sac's quality counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getSacsQualityCounterMessage() {
		if (this.props.sacsQuality) {
			let totalSacsQuality = this.props.sacsQuality.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalSacsQuality}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.sacsQualityCount}</span>&nbsp;
					{this.props.translate('common.records')}
					<br />
					{this.props.translate('sacsQuality.folioTracking')}
				</div>
			)
		}
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "saveCorrectly"
	 *
	 * @param {string} [idTranslation="common.saveCorrectly"]
	 * @memberof Show
	 */
	showSuccessToaster(idTranslation = 'common.saveCorrectly') {
		this.setState({
			showToaster: true,
			toasterVariant: 'success',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "errorToSave"
	 *
	 * @param {string} [idTranslation="common.errorToSave"]
	 * @memberof show
	 */
	showErrorToaster(idTranslation = 'common.errorToSave') {
		this.setState({
			showToaster: true,
			toasterVariant: 'error',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof SacsQuality
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<SacsQualityIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInputOptions
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
								isSearchAllReports
								onSearchAllReports={this.onChangeGetAllReports}
							/>

							{IsCurrentActiveDirectoryUser(this.props.user) &&
								canUserCreateReportModuleQuality(this.props.userRolesByCompany) && (
									<Tooltip title={<Translate id='sacsQuality.addSacQuality' />}>
										<Fab
											size='small'
											color='primary'
											onClick={() => {
												this.props.history.push(`/sacsquality/create`)
											}}
										>
											<AddIcon />
										</Fab>
									</Tooltip>
								)}
							{IsCurrentActiveDirectoryUser(this.props.user) &&
								canUserCreateReportModuleQuality(this.props.userRolesByCompany) && (
									<Tooltip title={<Translate id='sacsQuality.downloadReport' />}>
										<IconButton
											disabled={this.props.isLoading}
											onClick={() => this.handleDownloadReportCorrectiveAction()}
											color='secondary'
										>
											<DownloadIcon />
										</IconButton>
									</Tooltip>
								)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.correctiveActionQuality' />{' '}
						</Typography>
					}
					subheader={this.getSacsQualityCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

const TableInternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'tracking',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='common.supplier' />,
			dataSource: 'full_supplier',
			isSortable: true,
		},
		{
			header: <Translate id='common.partNumber' />,
			dataSource: 'full_part_description',
		},
	],
}

const TableExternalConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: '',
			dataSource: 'tracking',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'actual_operation_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='common.partNumber' />,
			dataSource: 'full_part_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.description' />,
			dataSource: 'problem_description',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingSacsQuality: state.sacsQuality.get('isLoadingSacsQuality'),
		sacsQuality: state.sacsQuality.get('sacsQuality') ? state.sacsQuality.get('sacsQuality').toJS() : null,
		sortCriteria: state.sacsQuality.get('sortCriteria') ? state.sacsQuality.get('sortCriteria').toJS() : null,
		searchQuery: state.sacsQuality.get('searchQuery'),
		page: state.sacsQuality.get('page'),
		rowsPerPage: state.sacsQuality.get('rowsPerPage'),
		sacsQualityCount: state.sacsQuality.get('sacsQualityCount'),
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isLoading: state.sacsQuality.get('isLoading'),
		isAllReports: state.sacsQuality.get('isAllSacsReports'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSacsQuality: (query, orderBy, sortAscending, page, rowsPerPage, isAllReports) => {
			dispatch(loadSacsQualityFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage, isAllReports))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_SACS_QUALITY_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_SACS_QUALITY_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_SACS_QUALITY_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		onLoadingAllReports: (isAllReports) => {
			dispatch({
				type: IS_LOADING_ALL_SACS_REPORTS,
				isAllReports,
			})
		},
		getSacsQualityCount: () => {
			dispatch(getSacsQualityCount())
		},
		downloadCorrectiveActionReport: () => {
			return dispatch(downloadCorrectiveActionReport())
		},
	}
}

const styles = (theme) => ({
	avatar: {},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
