///Import react section
import React, { useState } from 'react';
import { useSelector, useDispatch, } from 'react-redux'
import Select from 'react-select'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import { Translate, getTranslate } from 'react-localize-redux';
/** Material UI imports section */
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

/** Material UI Icons imports section */
import RatesIcon from '@material-ui/icons/BarChartTwoTone';
import InfoIcon from '@material-ui/icons/Info';
import ImportIcon from '@material-ui/icons/ImportExport';
import ReportExcelIcon from '@material-ui/icons/FileCopyTwoTone';
//import components common
import DataTable from '../common/DataTable';
import LoadingPage from '../common/LoadingPage';
//Import data table
import { GetMonths, GetYears } from '../../store/helpers/SelectOptions'

import {
    generateRatingsFERSA,
    generatePDFRatingsPTM
} from '../../store/ratings/ratingActions';

const GenerateRatingFERSA = (props) => {
    /**Render hooks section */
    const dispatch = useDispatch()

    //Use selector options
    const translate = useSelector((state) => getTranslate(state.localize))
    const isSaving = useSelector((state) => state.ratings.get("isSaving"))
    const ratingsFersa = useSelector((state) => state.ratings.get('ratingsFersa') ? state.ratings.get('ratingsFersa').toJS() : [])
    //use state section
    const [monthDate, setMonthDate] = useState(null)
    const [yearDate, setYearDate] = useState(null)


    /**
     * Set year date
     * @param {*} year 
     */
    const onSetYearData = (year) => {
        setYearDate(year.value)
    }

    const onGenerateRatingsFERSA = () => {
        return dispatch(generateRatingsFERSA(monthDate, yearDate))
    }

    const onGeneratePDFRatingsPTM = () => {
        return dispatch(generatePDFRatingsPTM(monthDate, yearDate))
    }

    /**
    *Set month to state
    *
    * @param {*} date
    * @memberof Index
    */
    const onSetMonthData = (monthDate) => {
        setMonthDate(monthDate.value)
    }

    /**
 * Get the card content
 *
 * @memberof Index
 */
    const getCardContent = () => {
        if (props.isSaving === true) {
            return (
                <LoadingPage />
            )
        }
        else {
            return (
                <Grid item xs={12} style={{ marginTop: '.5rem' }}>
                    <DataTable
                        title={<Translate id="companies.functionModules.SupplierQualification" />}
                        data={ratingsFersa}
                        configuration={TableratingsTableConfiguration(translate)}
                    />
                </Grid>
            )
        }
    }

    /**
    * Set buttons for rating
    * @param {*} year 
    */
    const RatingAvaiable = () => {
        if (monthDate && yearDate) return true;
        else return false;
    }

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <RatesIcon />
                    </Avatar>
                }
                title={<Typography variant="button">{<Translate id="rating.generateRating" />} </Typography>}
            />
            <CardContent>
                <Grid container spacing={24} >
                    <Grid item xs={12} className="alert alert-info" role="alert" style={{ marginLeft: '.7rem', marginRight: '.7rem' }}>
                        <React.Fragment>
                            <h6 className="alert-heading">
                                <InfoIcon />
                                <Translate id="rating.generatedRatingptm" />
                                <Typography component="p"><strong> <Translate id="rating.messageToGeneratedRatingPtm" /></strong></Typography>
                            </h6>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: '.7rem', marginRight: '.7rem' }}>

                        <Grid container justify="flex-start" alignItems="center">
                            <Grid item xs={3}>
                                <MonthsTypeSelect
                                    monthDate={monthDate}
                                    setMonthData={onSetMonthData}
                                />
                            </Grid>
                            <Grid item xs={3} style={{ marginLeft: '.7rem', marginRight: '.3rem' }}>
                                <YearsTypeSelect
                                    yearDate={yearDate}
                                    setYearData={onSetYearData}
                                />
                            </Grid>
                            <Grid item xs={5} style={{ marginLeft: '.7rem', marginRight: '.7rem', display: "flex" }}>
                                <Button variant="contained" fullWidth
                                    disabled={isSaving || !RatingAvaiable()}
                                    size="small"
                                    style={{ margin: '0.5em 1%', width: '80%', backgroundColor: isSaving || !RatingAvaiable() ? "#e0e0e0" : "#7d586e", color: "white" }}
                                    onClick={() => onGenerateRatingsFERSA()}>
                                    <ImportIcon /><Translate id={'rating.generateRatings'} />
                                </Button>
                                <Button variant="contained" fullWidth
                                    disabled={isSaving || !RatingAvaiable()}
                                    size="small"
                                    style={{ margin: '0.5em 1%', width: '80%', backgroundColor: (isSaving || !RatingAvaiable()) ? "#e0e0e0" : "#17A2B8", color: "white" }}
                                    onClick={() => { onGeneratePDFRatingsPTM() }}>
                                    <ReportExcelIcon />{"Reporte de calificaciones"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {getCardContent()}
            </CardContent>
        </Card>)

}


/**
 * Get the date selection
 *
 * @class MonthsTypeSelect
 * @extends {Component}
 */
const MonthsTypeSelect = (props) => {
    let months = GetMonths();
    return (
        <Select
            value={months[props.monthDate]}
            options={months}
            onChange={props.setMonthData}
            styles={{
                menu: (base) => ({ ...base, zIndex: 1000, height: 150 }),
                menuList: (base) => ({ ...base, height: 150, paddingTop: 0 }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }) /// THIS IS TO SHOW MENU OVER MODAL
            }}
            menuPortalTarget={document.parentNode}
            menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
            menuPlacement={'auto'}
        />
    );

}



/**
 * Get the date selection
 *
 * @class YearsTypeSelect
 * @extends {Component}
 */
const YearsTypeSelect = (props) => {
    let years = GetYears();
    return (
        <Select
            // label='Year'
            value={years[props.yearDate]}
            options={years}
            onChange={props.setYearData}
            styles={{
                menu: (base) => ({ ...base, zIndex: 1000, height: 150 }),
                menuList: (base) => ({ ...base, height: 150, paddingTop: 0 }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }) /// THIS IS TO SHOW MENU OVER MODAL
            }}
            menuPortalTarget={document.parentNode}
            menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
            menuPlacement={'auto'}
        />
    );

}

const styles = ((theme) => ({
    // avatar: {
    //     backgroundColor: theme.palette.secondary.main,
    // },
    // actionsToolbar: {
    //     display: "flex"
    // },
    // searchInput: {
    //     marginTop: "3px",
    //     marginRight: "15px"
    // },
    // showingUsersCounter: {
    //     color: theme.palette.secondary.main
    // },
    // showingCounter: {
    //     marginLeft: "5px"
    // },
    // biddingContainer: {
    //     maxHeight: `calc(100vh - ${275}px)`,
    //     minHeight: `calc(100vh - ${275}px)`,
    //     overflowY: "hidden",
    //     backgroundColor: "red"
    // },
    // categoryIcon: {
    //     marginLeft: "5px"
    // },
}))


const TableratingsTableConfiguration = (translate) => {
    let columns = [
        {
            header: '',
            dataSource: 'status_indicator',
        }, {
            header: <Translate id="common.supplier" />,
            dataSource: 'supplier_full',
        },
        {
            header: `${translate && translate('rating.ratingsTable.deliveries')} ${'40'}%`,
            dataSource: 'delivery_points',
        }, {
            header: `${translate && translate('rating.ratingsTable.quality')} ${'40'}%`,
            dataSource: 'quality_points',
        }, {
            header: `${translate && translate('rating.ratingsTable.service')} ${'10'}%`,
            dataSource: 'service_points',
        }, {
            header: `${translate && translate('rating.ratingsTable.developmentFersa')} ${'10'}%`,
            // dataSource: 'guarantee_points',
            dataSource: 'development_points',

        }, {
            header: <Translate id="rating.ratingsTable.finalScore" />,
            dataSource: 'final_score',
        }];
    return ({ columns });
}

export default withauthenticatedLayout(withRouter((withStyles(styles, { withTheme: true })(GenerateRatingFERSA))))
