import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate, getTranslate } from 'react-localize-redux'

import {
	Grid,
	Avatar,
	Card,
	CardHeader,
	CardContent,
	IconButton,
	Tooltip,
	CircularProgress,
	Typography,
} from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import DeleteIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/AssignmentReturned'
import AlternativeSupplierIcon from '@material-ui/icons/SwapCallsTwoTone'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import AttachmentsDialog from '../common/AttachmentsDialog'
import ProvedureItemDialog from './components/ProvedureItemDialog'
import SendRequestDialog from './components/SendRequestDialog'
import ProveduresDetails from './components/ProveduresDetails'
import InformationMemoCard from './components/InformationMemoCard'
import AddCommonItem from '../common/AddCommonItem'

/** Actions and helpers imports section */
import {
	addItemInAlternativeProveduria,
	initialAlternativeProvedure,
	getSelectedProvedureItem,
	sendQuotesRequestFromAlternative,
	removeItemInAlternativeProveduria,
	SET_PROVEDURE_ITEM_SELECTED,
	updateDetailPartnumberPlaneName,
	updateAlternativeProvedureProperty,
	onAddComment,
	onAddFiles,
	onRemoveFiles,
	importMaterialListOfAlternativeProveduria,
	removeAllDetailsInProveduriaDraft,
	downloadAlternativeProvedureDraft,
} from '../../store/alternativeProvedure/AlternativeProveduceAction'
import { downloadPlotArtFiles } from '../../store/partNumbers/PartNumberAction'
import { COMPANY_IDENTIFIER_IMBERA_MX } from '../../store/helpers/ResourcesHelper'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import { isNullOrUndefined } from 'util'
import { showSuccessToaster, showErrorToaster, showWarningToaster } from '../../store/helpers/ToasterHelpers'

/**
 * Component to create memo from SIT
 */
class Create extends Component {
	/**
	 * Create an instance ofMemos
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			proveduria: null,
			provedureItemSelected: null,
			showProvedureItemDialog: null,
			showProvedureSendRequests: false,
			dataDelivery: '',
			supplierSelected: null,
			openRemoveAllDetails: false,
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidMount() {
		if (this.props.selectedCompany && this.props.provedureDraft === null) {
			this.props.initialAlternativeProvedure()
		}
	}

	/**
	 * On ChangeAlternativeType
	 *
	 * @param {*} option
	 * @memberof onChangeAlternativeType
	 */
	onChangeAlternativeType(option) {
		let provedurie = this.props.provedureDraft
		this.props.updateAlternativeProvedureProperty(provedurie.token, provedurie.deadline_delivery, option.value)
	}

	/**
	 * On change date delivery
	 * @param {*} dataDelivery
	 */
	onChangeRangeDeliveryDate(dataDelivery) {
		let provedurie = this.props.provedureDraft
		this.props.updateAlternativeProvedureProperty(
			provedurie.token,
			dataDelivery.format('DD/MM/YYYY'),
			provedurie.alternating_proveeduria_type
		)
	}

	/**
	 * On seletecd supplier
	 */
	onSupplierSelected(supplier) {
		this.setState({ supplierSelected: supplier })
	}

	/**
	 * Can be add item in provedurie
	 */
	canBeAddItem() {
		if (
			this.props.provedureDraft.deadline_delivery === '' ||
			this.props.provedureDraft.alternating_proveeduria_type === ''
		)
			return false
		if (this.props.provedureDraft.supplier_token === '' && this.state.supplierSelected == null) return false
		else return true
	}

	/**
	 * On memo item clicked open the dialog
	 *
	 * @param {*} item
	 * @memberof ProveduresDetails
	 */
	onMenuOptionItemClick(modalName, item) {
		const { provedureDraft } = this.props
		let provedureItemSelected = provedureDraft.details.find((provedureItem) => {
			return provedureItem.token === item.token
		})
		this.props.setProvedureItemSelected(item.token)
		this.setState({
			provedureItemSelected,
			[modalName]: true,
		})
	}

	/**
	 * onCloseModalItem
	 *
	 * @memberof ProveduresDetails
	 */
	onCloseModalItem(modalName) {
		this.setState({
			[modalName]: false,
			provedureItemSelected: null,
		})
	}

	/**
	 * onCloseProveduriaItemDialog
	 *
	 * @memberof Create
	 */
	onCloseProveduriaItemDialog() {
		this.setState({
			showProvedureItemDialog: false,
			provedureItemSelected: null,
		})
	}

	/**
	 * On item plot selected
	 *
	 * @param {*} plotSelected
	 * @memberof Create
	 */
	onPlotSelected(item, plotSelected) {
		this.props
			.updateDetailPlane(item.token, plotSelected)
			.then(() => {
				this.setState({
					showProvedureItemDialog: false,
					provedureItemSelected: null,
				})
			})
			.catch(() => {
				console.log('onPlotSelected::failed')
			})
	}

	/**
	 * on Send requests on click notice change
	 */
	onSendRequestClick() {
		this.setState({
			showProvedureSendRequests: true,
		})
	}

	/**
	 * onCloseSendRequestDialog
	 *
	 * @memberof Create
	 */
	onCloseSendRequestDialog() {
		this.setState({
			showProvedureSendRequests: false,
		})
	}

	validateFilesInMaterials() {
		let materialWithoutPlotFile = this.props.provedureDraft.details.find((detail) => {
			return IsNullOrEmpty(detail.plot_name) && detail.attachments.length < 1
		})
		return !isNullOrUndefined(materialWithoutPlotFile)
	}

	/**
	 * Get action to send request notice change
	 */
	sendQuotesRequestFromAlternative() {
		if (this.props.provedureDraft.details.length === 0) {
			this.setState(showErrorToaster('alternatingProveeduria.messageToSendProvure'))
		}
		if (
			this.props.selectedCompany &&
			this.props.selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
			this.validateFilesInMaterials()
		) {
			this.setState(showErrorToaster('alternatingProveeduria.cantSendWithoutPlotOrFile'))
		} else {
			this.props
				.sendQuotesRequestFromAlternative()
				.then(() => {
					this.setState({
						showToView: true,
						showToaster: false,
					})
					this.props.history.push(`/alternativeprovedure/${this.props.provedureDraft.token}`)
				})
				.catch(() => {
					this.setState(showErrorToaster())
				})
		}
	}

	/**
	 * Function to resend to supplier
	 */
	onRemoveallDetailsOpen() {
		this.setState({
			openRemoveAllDetails: true,
		})
	}

	onDownloadDraft() {
		this.props
			.downloadDraft(this.props.provedureDraft)
			.then(() => {
				this.setState(showSuccessToaster('common.downloadingReport'))
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				this.setState(showErrorToaster(error))
				return Promise.reject()
			})
	}
	/**
	 * remove all details in draft
	 * @param {*}
	 */
	removeAllDetailsInDraft() {
		this.props
			.removeAllDetailsInProveduriaDraft()
			.then(() => {
				this.setState({
					openRemoveAllDetails: false,
				})
			})
			.catch(() => {
				this.setState(showErrorToaster())
			})
	}

	onDownloadPlotFile(lot, companyToken, kind, formatype) {
		return this.props
			.downloadPlotArtFiles(lot, companyToken, kind, formatype)
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState(showWarningToaster('common.errorNotExtiFile'))
				return Promise.reject()
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.provedureDraft !== null) {
			return (
				<Grid container spacing={24}>
					<Grid item xs={12} style={{ zIndex: 70 }}>
						<InformationMemoCard
							createProveduria={this.createProveduria}
							onChangeAlternativeType={this.onChangeAlternativeType}
							onChangeRangeDeliveryDate={this.onChangeRangeDeliveryDate}
							dataDelivery={this.state.dataDelivery}
							onSupplierSelected={this.onSupplierSelected}
							provedure={this.props.provedureDraft}
							{...this.props}
						/>
					</Grid>
					<Grid item xs={12}>
						<ProveduresDetails
							canBeAddItem={this.canBeAddItem}
							dropzoneText={this.props.translate('dropzone.dropzoneBaseText')}
							supplierSelected={this.state.supplierSelected}
							onMenuOptionItemClick={this.onMenuOptionItemClick}
							downloadPlotFiles={this.onDownloadPlotFile}
							{...this.props}
						/>
					</Grid>
				</Grid>
			)
		}
	}

	/**
	 * Render component memo
	 */
	render() {
		let isDisabled = this.props.provedureDraft && this.props.provedureDraft.details.length <= 0
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<AlternativeSupplierIcon />
						</Avatar>
					}
					action={
						this.props.provedureDraft !== null && (
							<span>
								{!isDisabled && !this.props.isDownloadingDraft && (
									<Tooltip title={<Translate id='alternatingProveeduria.downloadDraft' />}>
										<IconButton
											value
											onClick={this.onDownloadDraft}
											disabled={this.props.isDownloadingDraft}
										>
											<DownloadIcon color='secondary' />
										</IconButton>
									</Tooltip>
								)}
								{this.props.isDownloadingDraft && <CircularProgress color='secondary' size={30} />}
								<Tooltip title={<Translate id='alternatingProveeduria.removeAlldetails' />}>
									<IconButton
										value
										onClick={() => {
											this.onRemoveallDetailsOpen()
										}}
										disabled={isDisabled}
									>
										<DeleteIcon color='error' />
									</IconButton>
								</Tooltip>
								<Tooltip title={<Translate id='alternatingProveeduria.sendRequest' />}>
									<IconButton
										color='primary'
										value
										onClick={() => {
											this.onSendRequestClick()
										}}
										disabled={isDisabled}
									>
										<SendIcon />
									</IconButton>
								</Tooltip>
							</span>
						)
					}
					title={
						<Typography variant='button'>
							<Translate id='alternatingProveeduria.sampleRequestTitle' />{' '}
						</Typography>
					}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to remove all details in draft */}
				<AddCommonItem
					title={<Translate id='alternatingProveeduria.removeAlldetails' />}
					message={<Translate id='alternatingProveeduria.messageToRemoveAlldetails' />}
					open={this.state.openRemoveAllDetails}
					onClose={() => {
						this.setState({ openRemoveAllDetails: false })
					}}
					onSaveItem={this.removeAllDetailsInDraft}
					isSaving={this.props.isSaving}
					isMessage={true}
				/>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>

				{this.state.provedureItemSelected && (
					<ProvedureItemDialog
						item={this.state.provedureItemSelected}
						show={this.state.showProvedureItemDialog}
						onClose={this.onCloseProveduriaItemDialog}
						onPlotSelected={this.onPlotSelected}
						onRemoveSupplier={this.onRemoveItem}
						onAddComment={this.props.onAddComment}
						kind={this.props.provedureDraft.alternating_proveeduria_type}
						companyImbera={
							this.props.memo &&
							this.props.provedureDraft.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX
						}
					/>
				)}
				<AttachmentsDialog
					dropzoneText={this.props.translate('dropzone.dropzoneBaseText')}
					show={this.state.showAttachmentsDialog}
					item={this.props.selectedProveureItem ? this.props.selectedProveureItem : {}}
					uploadFiles={this.props.onAddFiles}
					removeFiles={this.props.onRemoveFiles}
					onClose={() => this.onCloseModalItem('showAttachmentsDialog')}
				/>

				{this.state.showProvedureSendRequests && (
					<SendRequestDialog
						show={this.state.showProvedureSendRequests}
						onClose={this.onCloseSendRequestDialog}
						sendQuotesRequestFromAlternative={this.sendQuotesRequestFromAlternative}
						isSaving={this.props.isSaving}
					/>
				)}
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		provedureDraft: state.alternativeProvedures.get('provedureDraft')
			? state.alternativeProvedures.get('provedureDraft').toJS()
			: null,
		isLoading: state.alternativeProvedures.get('isLoading'),
		selectedProveureItem: getSelectedProvedureItem(state),
		isSaving: state.alternativeProvedures.get('isSaving'),
		translate: getTranslate(state.localize),
		isDownloadingDraft: state.alternativeProvedures.get('isDownloadingDraft'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		addItemInAlternativeProveduria: (provedureDraft, item, supplierSelected) => {
			return dispatch(addItemInAlternativeProveduria(provedureDraft, item, supplierSelected))
		},
		initialAlternativeProvedure: () => {
			return dispatch(initialAlternativeProvedure())
		},
		setProvedureItemSelected: (itemToken) => {
			dispatch({ type: SET_PROVEDURE_ITEM_SELECTED, itemToken })
		},
		updateDetailPlane(detailToken, planeName) {
			return dispatch(updateDetailPartnumberPlaneName(detailToken, planeName))
		},
		sendQuotesRequestFromAlternative: () => {
			return dispatch(sendQuotesRequestFromAlternative())
		},
		updateAlternativeProvedureProperty: (token, deadlineDelivery, typeProvedurie) => {
			return dispatch(updateAlternativeProvedureProperty(token, deadlineDelivery, typeProvedurie))
		},
		removeItemInAlternativeProveduria: (itemToken, supplierSelected) => {
			return dispatch(removeItemInAlternativeProveduria(itemToken, supplierSelected))
		},
		onAddComment: (item, comments, requieredPlotArt) => {
			return dispatch(onAddComment(item, comments, requieredPlotArt))
		},
		onAddFiles: (files, tokenPartNumber) => {
			return dispatch(onAddFiles(files, tokenPartNumber))
		},
		onRemoveFiles: (fileName, tokenPartNumber) => {
			return dispatch(onRemoveFiles(fileName, tokenPartNumber))
		},
		importMaterialListOfAlternativeProveduria: (provedureDraft, attachments) => {
			return dispatch(importMaterialListOfAlternativeProveduria(provedureDraft, attachments))
		},
		removeAllDetailsInProveduriaDraft: () => {
			return dispatch(removeAllDetailsInProveduriaDraft())
		},
		downloadDraft: (provedureDraft) => {
			return dispatch(downloadAlternativeProvedureDraft(provedureDraft))
		},
		downloadPlotArtFiles: (lot, companyToken, kind, formatype) => {
			return dispatch(downloadPlotArtFiles(lot, companyToken, kind, formatype))
		},
	}
}

const styles = (theme) => ({
	toolbar: {
		display: 'flex',
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	fabBotton: {
		marginLeft: theme.spacing.unit,
	},
	button: {
		margin: theme.spacing.unit,
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	grow: {
		flexGrow: 1,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	supplierBadge: {
		padding: `0 ${theme.spacing.unit * 2}px`,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create)))
)
