import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import moment from 'moment'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import { CardHeader } from '@material-ui/core'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import { SHOW_EDIT_VIEW } from '../../store/helpers/ResourcesHelper'

/** Import actions section */
import Dialog from '../common/Dialog'
import DialogActions from '../common/DialogActions'
import FloatingButton from '../common/FloatingButton'
import DialogAvailableOperations from '../common/DialogAvailableOperations'
import SampleRequested from './components/SampleRequested'
import SendDateSample from './components/SendDateSample'
import RMIDocument from './components/RMIDocument'
import ArriveRegisterDate from './components/ArriveRegisterDate'
import RMIReception from './components/RMIReception'
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow'
import HistoryCard from '../common/HistoryCard'
import AddCommonItem from '../common/AddCommonItem'
import WorkflowCancel from '../common/WorkflowCancel'

import {
	loadSampleRequestFromBackEnd,
	sendSampleRequestToClient,
	sendRMIToClient,
	declineSampleRequestBySupplier,
	registerSampleArrive,
	acceptRMISampleRequestByClient,
	rejectRMISampleRequestByClient,
	rmiActivateToTheSupplierForModify,
} from '../../store/samplerequests/SampleRequestAction'
import { loadSupplier, loadAdvanceOptionsSupplier } from '../../store/suppliers/SupplierActions'
import { downloadPlotArtFiles } from '../../store/partNumbers/PartNumberAction'
import {
	RMI_REQUEST_MODITY,
	WORKFLOW_CANCELLATION,
	WORKFLOWREACTIVE,
	SAMPLEREQUESTDECLINATION,
} from '../../store/helpers/WorkFlowDocumentsType'
import { converListToArray } from '../../store/helpers/ListHelper'
import DataTable from '../common/DataTable'
import { WORKFLOW_REMINDER } from '../../store/helpers/WorkFlowDocumentsType'
import { canSampleRequestBeCanceled } from '../../store/helpers/RolesHelper'

/**
 * Container show memo
 */
class Show extends Component {
	/**
	 * Create an instance of Memos
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAvailableOperationsDialog: false,
			openDeclineRequest: false,
			commentsDecline: '',
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			commentsSupplier: '',
			dataDelivery: moment(),
			openArriveRegisterDialog: false,
			releaseLevel: 0,
			openMidifyRmiDialog: false,
			confirmRmiAccepted: false,
			confirmRMIRejected: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let sampleToken = this.props.match.params.token
		this.props.loadSampleRequest(sampleToken)
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoadingSample !== this.props.isLoadingSample && this.props.sampleRequest !== null) {
			/// Get information full from sampleRequest
			let sampleRequest = this.props.sampleRequest
			this.props.loadSupplier(sampleRequest.supplier_token)
			this.props.loadAdvanceOptionsSupplier(sampleRequest.supplier_code)
		}
	}

	//Cancel a sample request
	onCancelDocument() {
		this.props.history.push(`/samplerequests/cancel/${this.props.sampleRequest.token}`)
	}

	/**
	 * onSendSampleRequestToClient
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onSendSampleRequestToClient() {
		let dataDelivery = this.state.dataDelivery.format('DD/MM/YYYY')
		let releaseLevel = this.props.advanceOptionsSupplier
			? this.props.advanceOptionsSupplier.release_level
			: this.state.releaseLevel
		this.props
			.sendSampleRequestToClient(
				this.props.sampleRequest.token,
				dataDelivery,
				this.state.commentsSupplier,
				releaseLevel
			)
			.then(() => {
				this.setState({
					openAvailableOperationsDialog: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * onDeclineSampleBySupplier
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onDeclineSampleBySupplier() {
		this.setState({
			openAvailableOperationsDialog: false,
			openDeclineRequest: true,
		})
	}

	/**
	 * Function to add comment to decline
	 * @param {*} commentsDecline
	 */
	updateDeclineProperty(commentsDecline) {
		this.setState({
			commentsDecline: commentsDecline,
		})
	}

	/**
	 * onSaveDeclineQuote
	 */
	declineSampleBySupplier() {
		if (this.state.commentsDecline) {
			this.props
				.declineSampleRequestBySupplier(this.props.sampleRequest.token, this.state.commentsDecline)
				.then(() => {
					this.setState({
						openDeclineRequest: false,
						showToView: true,
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: <Translate id='common.saveCorrectly' />,
					})
				})
				.catch(() => {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				})
		}
	}

	/**
	 * On change date delivery
	 * @param {*} dataDelivery
	 */
	onChangeRange(dataDelivery) {
		this.setState({
			dataDelivery,
		})
	}

	/**
	 * Function to update comment supplier
	 */
	updatePropertyCommentSupplier(value) {
		this.setState({
			commentsSupplier: value,
		})
	}

	/**
	 * Function to sent RMI to client
	 */
	sendRMIToClient() {
		this.props
			.sendRMIToClient(this.props.sampleRequest.token)
			.then(() => {
				this.setState({
					openAvailableOperationsDialog: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			})
			.catch((result) => {
				if (result.status === 400) {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id={result.data.Message} />,
						toasterVariant: 'error',
					})
				} else {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				}
			})
	}

	/**
	 * onArriveRegisterDateDialog
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onArriveRegisterDateDialog() {
		this.setState({
			openAvailableOperationsDialog: false,
			openArriveRegisterDialog: true,
		})
	}

	/**
	 * Function to register arrive date by client
	 * @param {*} arriveDate
	 * @param {*} numberSamples
	 */
	onRegisterDateArrriveByClient(arriveDate, numberSamples) {
		this.props
			.registerSampleArrive(this.props.sampleRequest.token, arriveDate, numberSamples)
			.then(() => {
				this.setState({
					openArriveRegisterDialog: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Action to confimrm rmi to accepted
	 */
	onConfirmRmiAccepted() {
		this.setState({
			openAvailableOperationsDialog: false,
			confirmRmiAccepted: true,
		})
	}

	/**
	 * Function to accept RMI sampleRequest By Client
	 */
	acceptRMISampleRequestByClient() {
		this.props
			.acceptRMISampleRequestByClient(this.props.sampleRequest.token)
			.then(() => {
				this.setState({
					confirmRmiAccepted: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
				this.props.history.push(`/${this.props.sampleRequest.url_redirect_project}`)
			})
			.catch((result) => {
				if (result.status === 400) {
					this.setState({
						showToaster: true,
						toasterMessage: result.data.Message,
						toasterVariant: 'error',
					})
				} else {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				}
			})
	}

	/**
	 * Action to confimrm rmi to rejected
	 */
	onConfirmRMIRejected() {
		this.setState({
			openAvailableOperationsDialog: false,
			confirmRMIRejected: true,
		})
	}

	/**
	 * Function to rejected RMI ampleRequest By Client
	 */
	rejectRMISampleRequestByClient() {
		this.props
			.rejectRMISampleRequestByClient(this.props.sampleRequest.token)
			.then(() => {
				this.setState({
					confirmRmiAccepted: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
				this.props.history.push(`/${this.props.sampleRequest.url_redirect_project}`)
			})
			.catch((result) => {
				if (result.status === 400) {
					this.setState({
						showToaster: true,
						toasterMessage: result.data.Message,
						toasterVariant: 'error',
					})
				} else {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				}
			})
	}

	/**
	 * onClickRmiActivateToTheSupplierForModify
	 *
	 * @param {*} item
	 * @memberof
	 */
	onClickRmiActivateToTheSupplierForModify() {
		this.setState({
			openMidifyRmiDialog: true,
			openAvailableOperationsDialog: false,
		})
	}

	/**
	 * Function to sent rmiActivateToTheSupplierForModify
	 * @param {*} commnents
	 */
	rmiActivateToTheSupplierForModify(commnents) {
		this.props
			.rmiActivateToTheSupplierForModify(this.props.sampleRequest.token, commnents)
			.then(() => {
				this.setState({
					openMidifyRmiDialog: false,
					showToView: true,
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	onDownloadPlotFile(lot, companyToken, kind, formatype) {
		return this.props
			.downloadPlotArtFiles(lot, companyToken, kind, formatype)
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorNotExtiFile' />,
					toasterVariant: 'warning',
				})
				return Promise.reject()
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Show
	 */
	getCardContent() {
		if (this.props.isLoadingSample === true) {
			return <LoadingPage />
		} else if (this.props.sampleRequest !== null) {
			return (
				<Grid item xs={12}>
					<SampleRequested {...this.props} downloadPlotFiles={this.onDownloadPlotFile} />
				</Grid>
			)
		}
	}

	/**
	 * Action to set state to open of available operations dialog
	 * @memberof
	 */
	setOpenDialog = (openAvailable) => {
		this.setState({
			openAvailableOperationsDialog: openAvailable,
		})
	}

	/**
	 * Redirects in view of reminders
	 */
	onReminderToSupplier() {
		this.props.history.push(`/samplerequests/reminder/${this.props.sampleRequest.token}`)
	}

	/**
	 * Render send rrquest
	 */
	renderSendRequest() {
		if (this.props.sampleRequest) {
			return (
				<HistoryCard
					key={this.props.sampleRequest.token}
					title={'samplesrequest.dateCompromisseSupplier'}
					userCreator={this.props.sampleRequest.supplier_name}
					creationDate={this.props.sampleRequest.delivery_date_from_supplier}
					aditionalData={
						<SendDateSample
							{...this.props}
							onChangeRange={this.onChangeRange}
							dataDelivery={this.state.dataDelivery}
							commentsSupplier={this.state.commentsSupplier}
							updatePropertyCommentSupplier={this.updatePropertyCommentSupplier}
						/>
					}
				/>
			)
		} else return ''
	}

	/**
	 * Render reception reception
	 */
	renderRMIRereption() {
		if (this.props.sampleRequest && this.props.sampleRequest.rmi_reception) {
			return (
				<HistoryCard
					key={this.props.sampleRequest.rmi_reception.token}
					title={'samplesrequest.registeSampleclient'}
					userCreator={this.props.sampleRequest.rmi_reception.create_by}
					creationDate={this.props.sampleRequest.rmi_reception.creation_date_long}
					aditionalData={<RMIReception {...this.props} />}
				/>
			)
		} else if (
			this.props.sampleRequest &&
			this.props.sampleRequest.stage === 'Dummy 1' &&
			this.props.sampleRequest.actual_operation === 904
		) {
			return (
				<HistoryCard
					key={'token_dummy'}
					title={'samplesrequest.registeSampleclient'}
					userCreator={'Registrada en planta'}
					creationDate={this.props.sampleRequest.reception_sample_date_long}
					aditionalData={<RMIReception {...this.props} />}
				/>
			)
		} else return ''
	}

	/**
	 * Render document RMI
	 */
	renderRMIDocument() {
		if (this.props.sampleRequest && this.props.sampleRequest.rmi_document) {
			return (
				<HistoryCard
					{...this.props}
					key={this.props.sampleRequest.rmi_document.token}
					title={'samplesrequest.rmiDocument'}
					userCreator={this.props.sampleRequest.rmi_document.create_by}
					creationDate={this.props.sampleRequest.rmi_document.creation_date_long}
					aditionalData={<RMIDocument {...this.props} />}
				/>
			)
		} else return ''
	}

	/**
	 * Render hystoryCard for Deviations
	 *
	 * @param {*} folio
	 * @param {*} status
	 * @returns
	 * @memberof Show
	 */
	renderAditionaDataRMIModification(comment) {
		return (
			<Grid container spacing={24}>
				<CardHeader
					style={{ paddingTop: '0px' }}
					title={
						<Typography gutterBottom variant='body2' component='p'>
							<strong>
								<Translate id='samplesrequest.commentForModifyTheRMI' />
							</strong>
						</Typography>
					}
					subheader={
						<Typography gutterBottom variant='body2' component='p'>
							{comment}
						</Typography>
					}
				/>
			</Grid>
		)
	}

	/**
	 * Render hystory section for all documents
	 *
	 * @returns
	 * @memberof Show
	 */
	renderHistory() {
		let documents = this.props.sampleRequest
			? this.props.sampleRequest.documents
				? this.props.sampleRequest.documents
				: []
			: []
		return documents.map((document) => {
			let titleId
			let aditionalData
			if (document.document_type === RMI_REQUEST_MODITY) {
				titleId = 'samplesrequest.sendRMIToSupplierForModify'
				aditionalData = this.renderAditionaDataRMIModification(document.modification_comment)
			}
			if (document.document_type === WORKFLOW_REMINDER) {
				titleId = 'common.reminderToSupplier'
				aditionalData = this.renderAditionalDataReminder(document)
			}
			if (document.document_type === WORKFLOW_CANCELLATION) {
				titleId = 'common.folioCancel'
				aditionalData = <WorkflowCancel document={document} />
			}
			if (document.document_type === WORKFLOWREACTIVE) {
				titleId = 'samplesrequest.sampleReactive'
				aditionalData = document.comments && this.renderAditionaDataRMIModification(document.comments)
			}
			if (document.document_type === SAMPLEREQUESTDECLINATION) {
				titleId = 'samplesrequest.samplerequestDecline'
			}
			return (
				<HistoryCard
					key={document.token}
					title={titleId}
					userCreator={document.create_by}
					creationDate={document.creation_date_long}
					aditionalData={aditionalData}
				/>
			)
		})
	}

	/**
	 * Render hystoryCard for reminder
	 *
	 * @param {*} folio
	 * @param {*} status
	 * @returns
	 * @memberof Show
	 */
	renderAditionalDataReminder(document) {
		return (
			<Grid container spacing={24}>
				<CardHeader
					style={{ paddingTop: '0px' }}
					title={
						<Typography gutterBottom variant='body2' component='p'>
							<strong>
								<Translate id='common.mensajes' />:
							</strong>
						</Typography>
					}
					subheader={
						<Typography gutterBottom variant='body2' component='p'>
							{' ' + document.reminder_decription}
							<br />
							<br />
							<strong>
								<Translate id='common.sendTo' />:
							</strong>
							<DataTable
								data={document.users_to_send_reminder}
								configuration={{
									columns: [
										{
											header: <Translate id='users.name' />,
											dataSource: 'name',
										},
									],
								}}
							/>
						</Typography>
					}
				/>
			</Grid>
		)
	}

	/**
	 * Render sample request
	 */
	render() {
		const { classes } = this.props
		let optonsAvalinable = this.props.sampleRequest ? this.props.sampleRequest.get_available_operations : []
		return (
			<Card>
				{optonsAvalinable.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

				<LayoutHeaderWorkFlow
					{...this.props}
					workFlow={this.props.sampleRequest}
					title={<Translate id='samplesrequest.samplesrequest' />}
				/>
				<Divider />
				<CardContent>{this.getCardContent()}</CardContent>
				{this.renderSendRequest()}
				{this.renderRMIRereption()}
				{this.renderHistory()}
				{this.renderRMIDocument()}

				<DialogAvailableOperations
					{...this.props}
					options={optonsAvalinable}
					isValidToCancel={canSampleRequestBeCanceled(this.props.userRolesByCompany)}
					openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
					setOpenDialog={this.setOpenDialog}
					onSendSampleRequestToClient={this.onSendSampleRequestToClient}
					sendRMIToClient={this.sendRMIToClient}
					onArriveRegisterDateDialog={this.onArriveRegisterDateDialog}
					onAcceptRMISampleRequestByClient={this.onConfirmRmiAccepted}
					onRejectRMISampleRequestByClient={this.onConfirmRMIRejected}
					onRmiActivateToTheSupplierForModify={this.onClickRmiActivateToTheSupplierForModify}
					onDeclineSampleBySupplier={this.onDeclineSampleBySupplier}
					onCancelDocument={this.onCancelDocument}
					onReminderToSupplier={this.onReminderToSupplier}
				/>

				<ArriveRegisterDate
					open={this.state.openArriveRegisterDialog}
					numberSampleRequest={
						this.props.sampleRequest ? this.props.sampleRequest.number_of_samples_requested : ''
					}
					onClose={() => {
						this.setState({ openArriveRegisterDialog: false })
					}}
					onRegisterDateArrriveByClient={this.onRegisterDateArrriveByClient}
					isSaving={this.props.isSaving}
				/>

				{/** Dialog to resent rmi to supplier */}
				<AddCommonItem
					title={<Translate id='samplesrequest.sendRMIToSupplierForModify' />}
					message={<Translate id='samplesrequest.commentForModifyTheRMI' />}
					open={this.state.openMidifyRmiDialog}
					onClose={() => {
						this.setState({ openMidifyRmiDialog: false })
					}}
					onSaveItem={this.rmiActivateToTheSupplierForModify}
					isSaving={this.props.isSaving}
					isTextArea={true}
				/>

				{/** Dialog to confirm accepted RMI  */}
				<AddCommonItem
					title={<Translate id='statusDescriptions.rmiAccepted' />}
					message={
						<strong style={{ color: 'green' }}>
							<Translate id='samplesrequest.confirmRmiAccepted' />
						</strong>
					}
					open={this.state.confirmRmiAccepted}
					onClose={() => {
						this.setState({ confirmRmiAccepted: false })
					}}
					onSaveItem={this.acceptRMISampleRequestByClient}
					isSaving={this.props.isSaving}
					isMessage={true}
				/>

				{/** Dialog to confirm rejected RMI  */}
				<AddCommonItem
					title={<Translate id='statusDescriptions.rmiRejected' />}
					message={
						<strong style={{ color: 'red' }}>
							<Translate id='samplesrequest.confirmRMIRejected' />
						</strong>
					}
					open={this.state.confirmRMIRejected}
					onClose={() => {
						this.setState({ confirmRMIRejected: false })
					}}
					onSaveItem={this.rejectRMISampleRequestByClient}
					isSaving={this.props.isSaving}
					isMessage={true}
				/>

				{/** Dialog to update information */}
				<Dialog
					open={this.state.openDeclineRequest}
					onClose={() => {
						this.setState({ openDeclineRequest: false })
					}}
					header={
						<Toolbar>
							<Typography variant='h6'>{<Translate id='samplesrequest.declineSample' />}</Typography>
						</Toolbar>
					}
					actions={
						<DialogActions
							view={SHOW_EDIT_VIEW}
							classes={classes}
							onCancel={() => {
								this.setState({ openDeclineRequest: false })
							}}
							onClose={() => {
								this.setState({ openDeclineRequest: false })
							}}
							onSaveItem={this.declineSampleBySupplier}
							isSaving={this.props.isSaving}
							canBeSaved={this.state.commentsDecline !== ''}
						/>
					}
				>
					<form>
						<TextField
							fullWidth
							label={<Translate id='samplesrequest.motiveDecline' />}
							className={classes.textField}
							multiline
							rowsMax='8'
							value={this.state.commentsDecline}
							onChange={(event) => {
								this.updateDeclineProperty(event.target.value)
							}}
							onKeyDown={this.onKeyDown}
							margin='normal'
							variant='outlined'
						/>
					</form>
				</Dialog>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let sampleRequest = state.samplerequests.get('sampleRequest')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')

	return {
		isLoadingUser: state.oidc.isLoadingUser,
		currentUser: user,
		isLoadingSample: state.samplerequests.get('isLoadingSample'),
		sampleRequest: sampleRequest ? sampleRequest.toJS() : null,
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		company: selectedCompany ? selectedCompany.toJS() : null,
		advanceOptionsSupplier: state.suppliers.get('advanceOptionsSupplier')
			? state.suppliers.get('advanceOptionsSupplier').toJS()
			: null,
		isSaving: state.samplerequests.get('isSaving'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSampleRequest: (token) => {
			return dispatch(loadSampleRequestFromBackEnd(token))
		},
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		loadAdvanceOptionsSupplier: (supplierCode) => {
			return dispatch(loadAdvanceOptionsSupplier(supplierCode))
		},
		sendSampleRequestToClient: (token, dataDelivery, commentsSupplier, releaseLevel) => {
			return dispatch(sendSampleRequestToClient(token, dataDelivery, commentsSupplier, releaseLevel))
		},
		sendRMIToClient: (token) => {
			return dispatch(sendRMIToClient(token))
		},
		registerSampleArrive(token, arriveDate, numberSamples) {
			return dispatch(registerSampleArrive(token, arriveDate, numberSamples))
		},
		acceptRMISampleRequestByClient: (token) => {
			return dispatch(acceptRMISampleRequestByClient(token))
		},
		rejectRMISampleRequestByClient: (token) => {
			return dispatch(rejectRMISampleRequestByClient(token))
		},
		declineSampleRequestBySupplier: (token, commnents) => {
			return dispatch(declineSampleRequestBySupplier(token, commnents))
		},
		rmiActivateToTheSupplierForModify: (token, commnents) => {
			return dispatch(rmiActivateToTheSupplierForModify(token, commnents))
		},
		downloadPlotArtFiles: (lot, companyToken, kind, formatype) => {
			return dispatch(downloadPlotArtFiles(lot, companyToken, kind, formatype))
		},
	}
}

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	inline: {
		display: 'inline',
	},
	actionsToolbar: {
		flexGrow: 1,
	},
	logoCompany: {
		maxHeight: 100,
	},
	logoSupplier: {
		marginLeft: 80,
		maxHeight: 80,
	},
	tableHead: {
		backgroundColor: '#fff',
		position: 'sticky',
		zIndex: '30',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 500,
		marginTop: 10,
		marginBottom: 10,
	},
	paper: {
		margin: `${theme.spacing.unit}px auto`,
		padding: theme.spacing.unit * 2,
	},
	root: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: `0 ${theme.spacing.unit * 3}px`,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
