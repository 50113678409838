/** Helpers import section */
import axios from 'axios'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
	getAuthorizedRequestConfigurationUserParameters,
} from '../helpers/SessionHelper'
import { IsNullOrEmpty } from '../helpers/StringHelper'

//INDEX CONSTS
export const CHANGE_ETHIC_CODES_SORT_CRITERIA = 'CHANGE_ETHIC_CODES_SORT_CRITERIA'
export const CHANGE_ETHIC_CODES_SORT_QUERY = 'CHANGE_ETHIC_CODES_SORT_QUERY'
export const CHANGE_ETHIC_CODES_PAGINATION = 'CHANGE_ETHIC_CODES_PAGINATION'
export const IS_LOADING_ETHIC_CODES_INDEX = 'IS_LOADING_ETHIC_CODES_INDEX'
export const SET_ETHIC_CODES_LIST = 'SET_ETHIC_CODES_LIST'
export const IS_LOADING_IN_VIEW_HOME = 'IS_LOADING_IN_VIEW_HOME'
export const SET_ETHICS_CODE_IN_HOME = 'SET_ETHICS_CODE_IN_HOME'

export const SET_ETHIC_CODES_COUNT = 'SET_ETHIC_CODES_COUNT'

//SINGLE ETHIC CODE CONSTS
export const IS_LOADING_ETHIC_CODE = 'IS_LOADING_ETHIC_CODE'
export const SET_ETHIC_CODE = 'SET_ETHIC_CODE'
export const IS_SAVING_ETHIC_CODE = 'IS_SAVING_ETHIC_CODE'
export const SET_TEMPLATE_ETHIC_CODE = 'SET_TEMPLATE_ETHIC_CODE'
export const ADD_QUESTION_IN_TEMPLATED = 'ADD_QUESTION_IN_TEMPLATED'
export const REMOVE_QUESTION_IN_TEMPLATED = 'REMOVE_QUESTION_IN_TEMPLATED'
export const UPDATE_QUESTION_DATA = 'UPDATE_QUESTION_DATA'
export const UPDATE_QUESTION_IN_TEMPLATED = 'UPDATE_QUESTION_IN_TEMPLATED'
export const UPDATE_OPTION_IN_QUESTION_TEMPLATE = 'UPDATE_OPTION_IN_QUESTION_TEMPLATE'
export const UPDATE_QUESTIONNAIRE_DATA = 'UPDATE_QUESTIONNAIRE_DATA'

export const ADD_SUPPLIER_TO_EXCLUDE_IN_ETHIC_CODE = 'ADD_SUPPLIER_TO_EXCLUDE_IN_ETHIC_CODE'
export const REMOVE_SUPPLIER_TO_EXCLUDE_IN_ETHIC_CODE = 'REMOVE_SUPPLIER_TO_EXCLUDE_IN_ETHIC_CODE'
function isLoadingIndex(isLoadingIndex) {
	return {
		type: IS_LOADING_ETHIC_CODES_INDEX,
		isLoadingIndex,
	}
}

function setEthicCodesList(ethicCodesList) {
	return {
		type: SET_ETHIC_CODES_LIST,
		ethicCodesList,
	}
}

function setEthicCodesCount(ethicCodesCount) {
	return {
		type: SET_ETHIC_CODES_COUNT,
		ethicCodesCount,
	}
}

function isLoadingEthicCode(isLoadingEthicCode) {
	return {
		type: IS_LOADING_ETHIC_CODE,
		isLoadingEthicCode,
	}
}

function isSavingEthicCode(isSavingEthicCode) {
	return {
		type: IS_SAVING_ETHIC_CODE,
		isSavingEthicCode,
	}
}

function setEthicCode(ethicCode) {
	return {
		type: SET_ETHIC_CODE,
		ethicCode,
	}
}

function isLoadingInHome(isLoadingHome) {
	return {
		type: IS_LOADING_IN_VIEW_HOME,
		isLoadingHome,
	}
}

function setEthicCodesListHome(ethicCodesListHome) {
	return {
		type: SET_ETHICS_CODE_IN_HOME,
		ethicCodesListHome,
	}
}

function setTemplateEthicCode(templeteCode) {
	return {
		type: SET_TEMPLATE_ETHIC_CODE,
		templeteCode,
	}
}

function addQuestionInTemplated(question) {
	return {
		type: ADD_QUESTION_IN_TEMPLATED,
		question: question,
	}
}

function removeQuestionInTemplated(questionToken) {
	return {
		type: REMOVE_QUESTION_IN_TEMPLATED,
		questionToken,
	}
}

function updateQuestionData(questionnaireToken, questionData) {
	return {
		type: UPDATE_QUESTION_DATA,
		questionnaireToken,
		questionData,
	}
}
function updateQuesionInTemplate(question) {
	return {
		type: UPDATE_QUESTION_IN_TEMPLATED,
		question,
	}
}

function updateOptionInQuestionTemplate(optionResponse) {
	return {
		type: UPDATE_OPTION_IN_QUESTION_TEMPLATE,
		optionResponse,
	}
}

function updateQuestionnaire(questionnaireData) {
	return {
		type: UPDATE_QUESTIONNAIRE_DATA,
		questionnaireData,
	}
}

/**
 * Add supplier to list exclide
 * @param {*} supplierCode
 */
function addSupplierToExclude(supplier) {
	return {
		type: ADD_SUPPLIER_TO_EXCLUDE_IN_ETHIC_CODE,
		supplier,
	}
}

/**
 * Remove to list explide
 * @param {*} supplierCode
 */
function removeSupplierToExclude(supplierCode) {
	return {
		type: REMOVE_SUPPLIER_TO_EXCLUDE_IN_ETHIC_CODE,
		supplierCode,
	}
}

/**
 * Action to get a list of ethic codes by filters
 *
 * @export
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @returns
 */
export function loadEthicCodesFromBackEnd(query, orderBy, sortAscending) {
	return function (dispatch, getState) {
		dispatch(isLoadingIndex(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/all`

			let data = {
				CompanyToken: selectedCompany.get('token'),
				SortBy: orderBy,
				SortAscending: sortAscending,
				Query: query,
				Page: 1,
				RowsPerPage: 20,
			}
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setEthicCodesList(serverResponse.data))
					}
				})
				.catch((error) => {
					dispatch(isLoadingIndex(false))
					console.error('Error getting the ethic codes list', error.response)
				})
		} else {
			dispatch(isLoadingIndex(false))
		}
	}
}

/**
 *Action to get ethic codes count
 *
 * @export
 * @returns
 */
export function getEthicCodesCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().ethicCodes ? getState().ethicCodes.get('ethicCodesCount') : null
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		if (selectedCompany) {
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/count`

			let data = {
				CompanyToken: selectedCompany.get('token'),
			}

			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setEthicCodesCount(serverResponse.data))
					}
				})
				.catch((error) => {
					console.error('Error getting the failure guarantee count', error.response)
				})
		}
	}
}

/**
 * Action to create new Ethic code
 *
 * @export
 * @param {*} ethicCode
 * @returns
 */
export function createEthicCodeData(ethicCode) {
	return function (dispatch, getState) {
		dispatch(isSavingEthicCode(true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics`
		let companyToken = getState().profile.get('selectedCompany').get('token')

		let suppliersList = ''
		ethicCode.suppliers.forEach((supplier) => {
			if (!IsNullOrEmpty(suppliersList)) suppliersList += '>'
			suppliersList += supplier.name
		})

		let formData = new FormData()
		ethicCode.attachments.forEach((file) => {
			formData.append('file', file)
		})
		formData.set('CompanyToken', companyToken)
		formData.set('Name', ethicCode.name)
		formData.set('AcceptedBySupplier', ethicCode.acceptedBySupplier)
		formData.set('Nationals', ethicCode.optionNationals)
		formData.set('Foreigns', ethicCode.optionForeigns)
		formData.set('Suppliers', suppliersList)
		formData.set('ActivationDate', ethicCode.activationDate.format('DD/MM/YYYY'))
		formData.set('ExpirationDate', ethicCode.expirationDate.format('DD/MM/YYYY'))
		return axios
			.post(endPoint, formData, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingEthicCode(false))
					return Promise.resolve(response.data)
				} else {
					dispatch(isSavingEthicCode(false))
				}
			})
			.catch((error) => {
				console.error('Error create ethic code', error.response)
				dispatch(isSavingEthicCode(false))
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Get and set the ethic code in the estate from read from backend
 * @param {*} token
 */
export function loadEthicCode(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingEthicCode(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/codeethic/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setEthicCode(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load ethic code', error.response)
				dispatch(isLoadingEthicCode(false))
				return Promise.reject()
			})
	}
}

/**
 * Method to donwload report code ethics
 * @param {*} ethicCodeToken
 */
export function downloadSuppliersCodeEthicsReport(ethicCodeToken) {
	return function (dispatch, getState) {
		dispatch(isSavingEthicCode(true))
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/${ethicCodeToken}/downloadreport`
		return axios
			.get(endPoint, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingEthicCode(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Encuesta_Proveedores.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isSavingEthicCode(false))
				console.error('Error in download report ethic code by supplier ', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to load etichs code to response from supplier
 */
export function loadEthicCodeFromResponseSupplier() {
	return function (dispatch, getState) {
		dispatch(isLoadingInHome(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let supplier = getState().suppliers.get('supplier')
		let supplierCode = undefined
		let supplierName = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		if (supplier) {
			supplierName = supplier.get('name')
		}

		if (selectedCompany) {
			let data = {
				CompanyToken: selectedCompany.get('token'),
				SupplierCode: supplierCode,
				SupplierName: supplierName,
			}

			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/getbysupplier`
			return axios
				.post(endPoint, data, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setEthicCodesListHome(serverResponse.data))
					}
				})
				.catch((error) => {
					dispatch(isLoadingInHome(false))
					console.error("Error getting the offices notices's accpeted", error.response)
				})
		} else {
			return Promise.reject()
		}
	}
}

/**
 * action to get template by token company
 * @param {*} token
 */
export function getTemplateByToken() {
	return function (dispatch, getState) {
		dispatch(isLoadingEthicCode(true))
		let selectedCompany = getState().profile.get('selectedCompany')

		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/company/${selectedCompany.get(
				'token'
			)}/template`
			return axios
				.get(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setTemplateEthicCode(serverResponse.data))
					}
				})
				.catch((error) => {
					console.error('Error to load ethic code', error.response)
					dispatch(isLoadingEthicCode(false))
					return Promise.reject()
				})
		}
	}
}

/**
 * Action to save response questionnarie from supplier
 * @param {*} ethicCodeToken
 * @param {*} questionToken
 * @param {*} result
 */
export function saveResponseInQuestionnaireFromSupplier(ethicCodeToken, questionToken, result, commnets) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/savequestionbysupplier`
		let data = {
			EthicCodeToken: ethicCodeToken,
			OptionResponseToken: questionToken,
			ResponseSupplier: result,
			Commnets: commnets,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateQuestionData(ethicCodeToken, serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in save response from supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to close and save questionnarie from supplier
 * @param {*} ethicCodeToken
 */
export function sendQuestionnarieCompletedFromSupplier(ethicCodeToken) {
	return function (dispatch, getState) {
		dispatch(isSavingEthicCode(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplier = getState().suppliers.get('supplier')
		let sendBy = undefined
		let supplierName = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			sendBy = getState().oidc.user.profile.name
		}
		if (supplier) {
			supplierName = supplier.get('name')
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/sendquestionnariebysupplier`
		let data = {
			EthicCodeToken: ethicCodeToken,
			ResponseBy: sendBy,
			SupplierName: supplierName,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateQuestionnaire(serverResponse.data))
					dispatch(isSavingEthicCode(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingEthicCode(false))
				console.error('Error in save response from supplier', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action add new question from template
 * @param {*} tokenTemplated
 * @param {*} question
 */
export function addQuestionInTemplatedFromBackend(tokenTemplated, question) {
	return function (dispatch, getState) {
		dispatch(isSavingEthicCode(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/questions/add`
		let data = {
			EthicCodeToken: tokenTemplated,
			QuestionToken: question.selectedQuestionToken,
			Name: question.name,
			QuestionType: question.questionType,
			NumberResponse: question.numberResponse,
			Percentage: question.percentage,
			ResponseCorrectly: question.responseCorrectly,
			RequireAttachement: question.requireAttachment,
			Options: question.questionOptions,
			CommentPossibleResponse: question.commentPossibleResponse,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingEthicCode(false))
					dispatch(addQuestionInTemplated(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingEthicCode(false))
				console.error('Error in add question in templated', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to remove question from templated
 * @param {*} tokenTemplated
 * @param {*} questionToken
 */
export function removeQuestionInTemplatedFromBackend(tokenTemplated, questionToken) {
	return (dispatch, getState) => {
		dispatch(isSavingEthicCode(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/${tokenTemplated}/questions/${questionToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingEthicCode(false))
					dispatch(removeQuestionInTemplated(questionToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingEthicCode(false))
				console.error('Error removing question from tempalted', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update question in template from backend
 * @param {*} questionToken
 * @param {*} name
 */
export function updateQuestionInTemplatedFromBackend(questionToken, name) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/questions/update`
		let data = {
			QuestionToken: questionToken,
			Name: name,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateQuesionInTemplate(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in add question in templated', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update survey title
 * @param {*} surveyToken
 * @param {*} name
 * @returns
 */
export function updateSurveyTitle(surveyToken, name) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/surveyTitle/update`
		let data = {
			SurveyToken: surveyToken,
			Name: name,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					//dispatch(updateSurvey(serverResponse.data))
					dispatch(setEthicCode(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in update title of survey', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to create company
 * @param {*} company
 */
export function updateEthicData(notice) {
	return function (dispatch, getState) {
		dispatch(isSavingEthicCode(true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/expirationdates`

		let formData = new FormData()
		formData.set('CodeEthicToken', notice.token)
		formData.set('ActivationDate', notice.activationDate.format('DD/MM/YYYY'))
		formData.set('ExpirationDate', notice.expirationDate.format('DD/MM/YYYY'))

		return axios
			.put(endPoint, formData, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setEthicCode(response.data))
					dispatch(isSavingEthicCode(false))
				} else {
					dispatch(isSavingEthicCode(false))
				}
			})
			.catch((error) => {
				console.error('Error update ethic data', error.response)
				dispatch(isSavingEthicCode(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to add supplier in list exclude from offical notice
 * @param {*} token
 * @param {*} supplierCode
 */
export function addSupplierInListExcludesFromEthicCode(token, supplierCode) {
	return function (dispatch, getState) {
		dispatch(isSavingEthicCode(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/excludesuppliers`
		let data = {
			CodeEthicToken: token,
			SupplierCode: supplierCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					if (serverResponse.data !== '') {
						dispatch(addSupplierToExclude(serverResponse.data))
					}
					dispatch(isSavingEthicCode(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingEthicCode(false))
				console.error('Error in adding supplier exclude in ethic code', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove supplier in list exclude from offical notice
 * @param {*} token
 * @param {*} supplierCode
 */
export function removeSupplierInListExcludesFromEthicCode(token, supplierCode) {
	return function (dispatch, getState) {
		dispatch(isSavingEthicCode(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/${token}/excludesuppliers/${supplierCode}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeSupplierToExclude(supplierCode))
					dispatch(isSavingEthicCode(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingEthicCode(false))
				console.error('Error in remove supplier exclude in ethic code', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update name option response from questionarie template
 * @param {*} questionToken
 * @param {*} name
 */
export function updateResponseInQuestionFromTemplateBackend(optionToken, name) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/questions/update/response`
		let data = {
			OptionToken: optionToken,
			Name: name,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateOptionInQuestionTemplate(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error in add question in templated', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update evicende in question
 *
 * @export
 * @param {*} data
 * @returns
 */
export function uploadFilesEvidenceInQuestion(files, questionToken) {
	return (dispatch, getState) => {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/questions/evidence/${questionToken}/upload`
		let formData = new FormData()
		for (var index = 0; index <= files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to upload evidence file(s) from question report', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove files of evidence
 *
 * @export
 * @param {*} questionToken
 * @param {*} fileName
 * @returns
 */
export function removeFilesEvidenceInQuestion(fileName, questionToken) {
	return function (dispatch, getState) {
		let params = {
			name: fileName,
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(getState().oidc.user, params)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/codeEthics/questions/evidence/${questionToken}/remove`
		return axios
			.delete(endPoint, configuration)
			.then(() => {
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) from question report', error.response)
				return Promise.reject()
			})
	}
}
