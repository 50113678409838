import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import SearchInput from '../../common/SearchInput/SearchInput'

/** Material UI imports section */
import { withStyles } from '@material-ui/core/styles'
import { Card, CardHeader, Avatar, Fab, Typography, CardContent, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'
import SocialInsurancePaymentsIcon from '@material-ui/icons/People'

/** Actions and helpers imports section */
import LoadingPage from '../../common/LoadingPage'
import DataTable from '../../common/DataTable'
import {
	CHANGE_SOCIAL_INSURANCE_PAYMENTS_SORT_CRITERIA,
	CHANGE_SOCIAL_INSURANCE_PAYMENTS_QUERY,
	CHANGE_SOCIAL_INSURANCE_PAYMENTS_PAGINATION,
	getSocialInsurancePaymentsCount,
	addSocialInsurancePayment,
} from '../../../store/contractors/SocialInsurancePaymentsAction'
import AddSocialInsurancePaymentDialog from './components/AddSocialInsurancePaymentDialog'
import { loadSocialInsurancePaymentsFromBackEnd } from './../../../store/contractors/SocialInsurancePaymentsAction'
import Toaster from '../../common/Toaster'
import { loadSupplier } from '../../../store/suppliers/SupplierActions'
import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper'

/**
 * Class container to show sociali nsurance payments index
 *
 * @class Index
 * @extends {Component}
 */
class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAddSocialInsurancePaymentModal: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadSocialInsurancePayments(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getSocialInsurancePaymentsCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (prevProps.isLoadingEmployees !== this.props.isLoadingEmployees && this.props.supplier === null) {
			this.props.loadSupplier(this.props.user.profile.company_token)
		}
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadSocialInsurancePayments()
			this.props.getSocialInsurancePaymentsCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadSocialInsurancePayments(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "saveCorrectly"
	 *
	 * @param {string} [idTranslation="common.saveCorrectly"]
	 * @memberof Show
	 */
	showSuccessToaster(idTranslation = 'common.saveCorrectly') {
		this.setState({
			showToaster: true,
			toasterVariant: 'success',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "errorToSave"
	 *
	 * @param {string} [idTranslation="common.errorToSave"]
	 * @memberof Show
	 */
	showErrorToaster(idTranslation = 'common.errorToSave') {
		this.setState({
			showToaster: true,
			toasterVariant: 'error',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	handleShowAddSocialInsurancePaymentDialog() {
		this.setState({
			openAddSocialInsurancePaymentModal: !this.state.openAddSocialInsurancePaymentModal,
		})
	}

	handleOnAddSocialInsurancePayment(socialInsurancePayment) {
		return this.props
			.onAddSocialInsurancePayment(
				socialInsurancePayment,
				this.props.supplier ? this.props.supplier.full_name : ''
			)
			.then((result) => {
				this.props.history.push(`/socialinsurancepayments/${result.token}`)
				this.showSuccessToaster()
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.showErrorToaster()
				return Promise.reject()
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingSocialInsurancePayments === true) {
			return <LoadingPage />
		} else if (this.props.socialInsurancePayments === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.socialInsurancePayments}
					configuration={this.props.selectedCompany.id === '1' ? TableConfigurationImbMX : TableConfiguration}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(socialInsurancePayment) => {
						this.props.history.push(`/socialinsurancepayments/${socialInsurancePayment.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.socialInsurancePaymentsCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get material deliverie's counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		if (this.props.socialInsurancePayments) {
			let totalSocialInsurancePayments = this.props.socialInsurancePayments.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalSocialInsurancePayments}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.socialInsurancePaymentsCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<SocialInsurancePaymentsIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>
							{IsCurrentNotActiveDirectoryUser(this.props.user) && (
								<Tooltip title={<Translate id='contractorsModule.addSocialSecurityPayment' />}>
									<Fab
										size='small'
										color='primary'
										onClick={() => {
											this.setState({ openAddSocialInsurancePaymentModal: true })
										}}
									>
										<AddIcon />
									</Fab>
								</Tooltip>
							)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.socialSecurity' />
						</Typography>
					}
					subheader={this.getCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>
				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
				<AddSocialInsurancePaymentDialog
					open={this.state.openAddSocialInsurancePaymentModal}
					isSaving={this.props.isSavingSocialInsurancePayment}
					addPayment={this.handleOnAddSocialInsurancePayment}
					onClose={this.handleShowAddSocialInsurancePaymentDialog}
					actualCompany={this.props.selectedCompany}
				/>
			</Card>
		)
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='contractorsModule.startDate' />,
			dataSource: 'date_start',
		},
		{
			header: <Translate id='contractorsModule.endDate' />,
			dataSource: 'date_end',
		},
		{
			header: <Translate id='contractorsModule.paymentDescription' />,
			dataSource: 'payment_description',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'status_description',
		},
		{
			header: <Translate id='contractorsModule.validatedBy' />,
			dataSource: 'validate_by',
		},
	],
}

const TableConfigurationImbMX = {
	columns: [
		{
			header: <Translate id='contractorsModule.startDate' />,
			dataSource: 'date_start',
		},
		{
			header: <Translate id='contractorsModule.endDate' />,
			dataSource: 'date_end',
		},
		{
			header: <Translate id='contractorsModule.documentType' />,
			dataSource: 'document_type',
		},
		{
			header: <Translate id='contractorsModule.paymentDescription' />,
			dataSource: 'payment_description',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'status_description',
		},
		{
			header: <Translate id='contractorsModule.validatedBy' />,
			dataSource: 'validate_by',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingSocialInsurancePayments: state.contractorSocialInsurancePayments.get(
			'isLoadingSocialInsurancePayments'
		),
		isSavingSocialInsurancePayment: state.contractorSocialInsurancePayments.get('isSavingSocialInsurancePayment'),
		socialInsurancePayments: state.contractorSocialInsurancePayments.get('socialInsurancePayments')
			? state.contractorSocialInsurancePayments.get('socialInsurancePayments').toJS()
			: null,
		socialInsurancePaymentsCount: state.contractorSocialInsurancePayments.get('socialInsurancePaymentsCount'),
		sortCriteria: state.contractorSocialInsurancePayments.get('sortCriteria')
			? state.contractorSocialInsurancePayments.get('sortCriteria').toJS()
			: null,
		searchQuery: state.contractorSocialInsurancePayments.get('searchQuery'),
		translate: getTranslate(state.localize),
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		page: state.contractorSocialInsurancePayments.get('page'),
		rowsPerPage: state.contractorSocialInsurancePayments.get('rowsPerPage'),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_SOCIAL_INSURANCE_PAYMENTS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_SOCIAL_INSURANCE_PAYMENTS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_SOCIAL_INSURANCE_PAYMENTS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		loadSocialInsurancePayments: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadSocialInsurancePaymentsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		getSocialInsurancePaymentsCount: () => {
			dispatch(getSocialInsurancePaymentsCount())
		},
		onAddSocialInsurancePayment: (payment, supplierName) => {
			return dispatch(addSocialInsurancePayment(payment, supplierName))
		},
	}
}

const styles = (theme) => ({
	avatar: {},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
