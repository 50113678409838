import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Translate} from "react-localize-redux";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


/**
 * Material delivery send request items from material develiry
 */
class SendRequestDialog extends Component{

    /**
     *Creates an instance of SendRequestDialog.
     * @param {*} props
     * @memberof SendRequestDialog
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
     * On send request to suppliers
     */
    onSendRequesSupplierMaterialClick(){
        if(this.props.sendQuotesRequestFromMaterialDelivery){
            this.props.sendQuotesRequestFromMaterialDelivery()
        }
    }

    render(){
        const { classes } = this.props
        return(
            <Dialog open={this.props.show}>
                <DialogTitle id="form-dialog-title">{<Translate id="materialDelivery.sendQuotes" />}</DialogTitle>
                <DialogContent>
                    <div className={classes.dialogRoot}>
                       
                        <Typography variant="caption" gutterBottom>
                            <Translate id="materialDelivery.sendMemorequest" />
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={() => {this.onSendRequesSupplierMaterialClick()}}  disabled={this.props.isSaving} color="secondary">
                        <Translate id="common.send" />
                    </Button>
                    <Button onClick={this.props.onClose} color="primary">
                        <Translate id="common.close" />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = theme => ({
    dialogRoot: {
        minHeight: "100px"
    },
    sampleRequired: {
        color: theme.palette.primary.light
    },
    deleteButton: {
        color: "red"
    }
})

export default withStyles(styles)(SendRequestDialog);
