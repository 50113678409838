/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
/**Import MaterialUi section */
import { Avatar, Chip, List, ListItem, ListItemText, Typography  } from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/Message'
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'

const HistoryMessagedLateralPanel = (props) => {
	const { loadAlertHistoriesMessages, classes, onCloseButtonClick } = props

	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={<Translate id='loadAlerts.historyMessages' />}
				onCloseButtonClick={onCloseButtonClick}
				icon={
					<Avatar className={classes.avatar}>
						<ViewCarouselIcon />
					</Avatar>
				}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<List dense>
						{loadAlertHistoriesMessages && loadAlertHistoriesMessages.length !== 0
							? loadAlertHistoriesMessages.map((item) => {
									if (!IsNullOrEmpty(item.Comments))
										return (
											<ListItem
												style={{
													display: 'flex',
													flexDirection: 'column',
													width: '100%',
													alignItems: 'flex-start',
												}}
											>
												<Typography color='primary' style={{ width: '100%' }}>{item.CreateBy}</Typography>
												<ListItemText
													primary={
														<Chip
															label={item.Comments}
															style={{ width: '100%', height: '45px' }}
															color={'inherit'}
														/>
													}
												/>
											</ListItem>
										)
									else return null
							  })
							: 'No existe historial de mensajes hasta el momento'}
						<br />
					</List>
				</div>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	expansionPanelSummary: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputProps: {
		height: 6,
	},
})

HistoryMessagedLateralPanel.propTypes = {
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
}

HistoryMessagedLateralPanel.defaultProps = {
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
}

export default withRouter(withStyles(styles, { withTheme: true })(HistoryMessagedLateralPanel))
