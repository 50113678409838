

import React, { useEffect, useState } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PlayIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import { OFFICIAL_NOTICE_VIDEO, OFFICIAL_NOTICE_IMAGE } from '../../store/helpers/ResourcesHelper';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

/**
 * Implement notice carrucel 
 * @param {*} props 
 */
function SwipeableTextMobileStepper(props) {
    const theme = props.theme;
    const [activeStep, setActiveStep] = useState(0);
    const [play, setPlay] = useState(true);
    const maxSteps = props.noticesInitialView ? props.noticesInitialView.length : 0;
    const xsButton = { maxWidth: 30, maxHeight: 30, paddingTop: 3 };
    const [intervalDuration, setDuration] = useState(6000);

    /**
     * Function handleNext
     */
    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    /**
     * Function handleBack
     */
    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    /**
     * Function Function
     * @param {} step 
     */
    function handleStepChange(step) {
        setActiveStep(step);
    }

    function handlePlayStop() {
        setPlay(prevPlay => !prevPlay);
    }

    function openNewTabImage(url) {
        window.open(url, '_blank');
    }

    useEffect(() => { 
        if (props.noticesInitialView && props.noticesInitialView.length !== 0 && props.noticesInitialView[activeStep].notice_type === OFFICIAL_NOTICE_VIDEO) {
            var myVideoPlayer = document.getElementById('videoNotice-' + props.noticesInitialView[activeStep].notice);
            var duration = 0;
            
            ///currently only video tag has id, if Id is added in another tag like img or iframe then validation must change
            if (myVideoPlayer.id !== null) {
                //On loaded data can play the video, then play is triggered and muted is false
                //muted is required to play video automatically, else browser doesn't support programmatically play
                myVideoPlayer.onloadeddata = function () {
                    myVideoPlayer.muted = false;
                    myVideoPlayer.play();
                }

                //When video ended is needed change active step and set step interval to 6000 
                //This is needed to prevent end video manually by user and wait all duration to change step
                myVideoPlayer.onended = function () {
                    myVideoPlayer.pause();
                    myVideoPlayer.muted = true;
                    setDuration(6000);
                    if(activeStep<(maxSteps-1))
                        setActiveStep(activeStep + 1)
                }

                //Added listener to know when metadata has loaded, metadata contains time duration video and other data.
                myVideoPlayer.addEventListener('loadedmetadata', function () {
                    duration = myVideoPlayer.duration.toFixed(0) * 1000;
                    if (duration > 0) {
                        setDuration(duration);
                    }
                });
            }
            else {
                setDuration(6000);
            }
        }
    }, [activeStep]);

    /**
     * Render Carousel
     */
    if (props.noticesInitialView && props.noticesInitialView.length !== 0) {
        const { classes } = props;
        let noticesInitialView = props.noticesInitialView;
        let urlImage = `${process.env.REACT_APP_SIP_URL_ADMINISTRATION}`;
        /**
         * Render
         */
        return (
            <Grid container className={classes.carrousel}>
                <Grid item xs={11} className={classes.header}>
                    <Typography variant="button">
                        <strong><Translate id="noticesCarrousel.notice" />: </strong> {noticesInitialView[activeStep].notice}
                    </Typography>
                </Grid>
                <Grid item xs={1} className={classes.headerButton}>
                    <Tooltip title={<Translate id={play ? "noticesCarrousel.stopTooltip" : "noticesCarrousel.playTooltip"} />} placement="left">
                        <IconButton className={classes.playStopButton} style={xsButton} aria-label="Play/Stop" onClick={handlePlayStop}>
                            {play ? <StopIcon color="primary" /> : <PlayIcon color="secondary" />}
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}
                    className={classes.content}
                >
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        autoplay={play}
                        interval={intervalDuration}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {noticesInitialView.map((step, index) => (
                            <div key={`${step.notice}/${index}`}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <span>
                                        {step.notice_type === OFFICIAL_NOTICE_IMAGE &&
                                            <Tooltip
                                                title={<Translate id="noticesCarrousel.clickToOpen" />}
                                                placement="left">
                                                <img
                                                    className={classes.image}
                                                    src={`${urlImage}/${step.baseUrl}`} alt={noticesInitialView[activeStep].notice}
                                                    onClick={() => openNewTabImage(`${urlImage}/${step.baseUrl}`)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </Tooltip>
                                        }
                                        {(step.notice_type === OFFICIAL_NOTICE_VIDEO && step.notice === noticesInitialView[activeStep].notice) &&
                                            <video
                                                id={"videoNotice-" + step.notice}
                                                title={noticesInitialView[activeStep].notice}
                                                className={classes.image}
                                                src={`${urlImage}/${step.baseUrl}`}
                                                alt={step.notice}
                                                frameBorder="0"
                                                allow="accelerometer; encrypted-media; gyroscope; fullscreen; autoplay;"
                                                allowFullScreen={true}
                                                muted=""
                                                controls
                                            >
                                                Your browser is not compatible with HTML5
                                            </video>
                                        }
                                    </span>
                                ) : null}

                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                </Grid>
                <Grid item xs={12}
                    className={classes.footer}
                >
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="dots"
                        className={classes.footer}
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" className={classes.stepButton} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                <Translate id="noticesCarrousel.next" />{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button size="small" className={classes.stepButton} onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                <Translate id="noticesCarrousel.back" />
                            </Button>
                        }
                    />
                </Grid>
            </Grid>
        );
    }
    else {
        const { classes } = props;
        return (
            <Grid container className={classes.carrousel}>
                <Grid item xs={12} className={classes.header}>
                    <Typography><strong><Translate id="noticesCarrousel.withoutNoticesToShow" /></strong></Typography>
                </Grid>
            </Grid>
        )
    }
}

const carrouselStyles = theme => ({
    carrousel: {
        height: '85vh',
        overflow: 'hidden'
    },
    header: {
        height: '5vh',
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit,
        backgroundColor: "#f1f1f1",
    },
    headerButton: {
        height: '5vh',
        paddingLeft: theme.spacing.unit,
        paddingTop: theme.spacing.unit / 2,
        backgroundColor: "#f1f1f1",
    },
    content: {
        width: "100%",
        height: "75vh",
    },
    image: {
        width: "100%",
        height: "75vh"
    },
    stepButton: {
        paddingTop: 0,
        paddingBottom: 0
    },
    footer: {
        height: '5vh',
        backgroundColor: "#f1f1f1"
    },
    following: {},
    cardContent: {},
    profileOptionTitle: {},
    padingOption: {},
    paper: {},
    listStyle: {},
    logo: {},
    linkStyle: {},
    tabs: {},
    card: {},
    userAvatar: {},
    userIcon: {},
    accountAvatar: {},
    avatarFileInput: {},
    avatar: {}
});

/**
 * SwipeableTextMobileStepper
 */

export default withStyles(carrouselStyles, { withTheme: true })(SwipeableTextMobileStepper);
