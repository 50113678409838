import React, { Component } from "react";
import autoBind from "auto-bind";

/** Material-UI */
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Search from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/AddCircle";
import ClearIcon from "@material-ui/icons/ClearTwoTone";
import { withStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import InputBase from "@material-ui/core/InputBase";

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    spacer: {
        flexGrow: 1
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    },
    inputRoot: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.background.search,
        width: "20%"
    },
    inputInput: {
        background: "rgb(240, 240, 240)",
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            "&:focus": {
                width: "100%"
            }
        }
    },
    clearButton: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.background.search,
        height: 34
    },
    clearArea: {
        padding: '0px !important',
        "&:focus": {
            borderRadius: 0,
        }
    }
});

/**
 *
 *
 * @class TableToolbar
 * @extends {Component}
 */
class DataGridToolbar extends Component {
    /**
     * Creates an instance of TableToolbar.
     * @param {*} props
     * @memberof DataGridToolbar
     */
    constructor(props) {
        super(props);
        autoBind(this);
    }

    /**
     * On click add button
     *
     * @memberof DataGridToolbar
     */
    onClickAddButton() {
        if (this.props.onClickAddButton) {
            this.props.onClickAddButton();
        }
    }

    /**
     * On click clear search button
     *
     * @memberof DataGridToolbar
     */
    onClickClearSearch() {
        if (this.props.onClickClearSearch) {
            this.props.onClickClearSearch();
        }
    }

    /**
     * On click search button
     *
     * @memberof DataGridToolbar
     */
    onClickSearch() {
        if (this.props.onClickSearch) {
            this.props.onClickSearch();
        }
    }

    /**
     * render Data grid toolbar
     *
     * @returns
     * @memberof DataGridToolbar
     */
    render() {
        const { classes } = this.props;
        let titleSize = this.props.titleSize ? this.props.titleSize : "h5";
        return (
            <Toolbar>
                <Typography variant={titleSize} id="tableTitle">
                    {this.props.title}
                </Typography>
                <div className={classes.spacer} />
              
                {this.props.showSearch &&
                    <Tooltip title="Buscar">
                        <IconButton aria-label="Search" onClick={this.onClickSearch}>
                            <Search />
                        </IconButton>
                    </Tooltip>
                }
                {this.props.showSearchBar &&
                    <InputBase
                        placeholder="Buscar"
                        onChange={this.props.onChangeSearch}
                        autoFocus
                        defaultValue={this.props.searchValue}
                        title="Escribe y presiona Enter para buscar"
                        onKeyDown={this.props.onKeyDown}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput
                        }}
                    />
                }
                {this.props.showSearchBar &&
                    <Tooltip title="Limpiar busqueda">
                        <IconButton aria-label="Clear" onClick={this.onClickClearSearch} className={classes.clearArea}>
                            <ClearIcon classes={{ root: classes.clearButton }} />
                        </IconButton>
                    </Tooltip>
                }
                {this.props.showAdd &&
                    <Tooltip title="Agregar">
                        <IconButton aria-label="Add" color="secondary" data-cy="btn-add" onClick={this.onClickAddButton}>
                            <AddIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                }
            </Toolbar>
        );
    }
}

export default withStyles(toolbarStyles)(DataGridToolbar);
