/**Import react section */
import React, {useState} from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Translate } from 'react-localize-redux';

/**Import material UI Section*/
import { TextField, withStyles, Chip } from "@material-ui/core";
import { Typography, IconButton} from '@material-ui/core';
import { BACKGROUND_COLOR_GRAY_DARK, COLOR_STATUS_DANGER, COLOR_STATUS_BLUE,
    COLOR_STATUS_WARNING, COLOR_STATUS_INFO, COLOR_QUESTIONNAIRE_CHERRY } from '../../../store/helpers/StatusColorConstants';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import FileIcon from '@material-ui/icons/AttachFile';

/**Import components section*/
import PanelFiles from './PanelFiles'

/**Import resources section*/
import { SustainabilityStatus } from '../../../store/helpers/AppConstants'
import {canBeEditSupplier, canBeResponsible} from '../../../store/helpers/SustainabilityHelper'
import {getAnswerQuestion, getStatusQuestion} from '../../../store/sustainability2/SustainabilityResults'

/**Import actions section */
const QuestionnaireTable = (props) => {
    const { classes, activeStep, subtopic, sustainability, topicId,
        userRolesByCompany,  userProfile} = props;
    const {updatePropertyQuestion} = props;
    const [openPanelLateral, setOpenPanelLateral] = useState({ opened: false, topicId: 0, subtopicId: 0, questionId: 0 })
    let canBeEdit = (canBeEditSupplier(sustainability, userRolesByCompany, userProfile) && sustainability.Status === SustainabilityStatus.SEND_SUPPLIER);
    let canBeEditResponsible = (canBeResponsible(sustainability, userRolesByCompany, userProfile) && sustainability.Status === SustainabilityStatus.PROGRAM_AUDIT);
    
    const onOpenCloseLateralPanel = (topicId, subtopicId, question) => {
        setOpenPanelLateral({ 
            opened: !openPanelLateral.opened,
            topicId: topicId, 
            subtopicId: subtopicId, 
            question: question});
    }
    return (
        <>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.listHeader}>{subtopic.Name}</TableCell>
                    {(activeStep === 1 || activeStep === 4) && <TableCell className={classes.listHeader}>{<Translate id={"sustainability.supplierAnswer"} />}</TableCell>}
                    {(activeStep === 1 || activeStep === 4) && <TableCell className={classes.listHeader}>{<Translate id={"common.observations"} />}</TableCell>}
                    {(activeStep === 1) && <TableCell className={classes.listHeader}>{<Translate id={"sustainability.uploadEvidence"} />}</TableCell>}
                    {activeStep === 4 && <TableCell className={classes.listHeader}>{<Translate id={"sustainability.internalAnswer"} />}</TableCell>}
                    {activeStep === 4 && <TableCell className={classes.listHeader}>{<Translate id={"sustainability.observations"} />}</TableCell>}
                    {activeStep === 4 && <TableCell className={classes.listHeader}>{<Translate id={"sustainability.compliment"} />}</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {subtopic.Questions.map((question, index) => {
                    return (
                        <TableRow key={question.Id}>
                            <TableCell style={{ minWidth: "230px",  maxWidth: "400px", }}>
                                {question.ClassificationType === 1 && <span style={{ color: COLOR_STATUS_DANGER }}><strong> * </strong></span>}

                                {`${index + 1}. ${question.Name}`}
                                
                                {question.Recommendation && <Typography variant="caption">{<span style={{color:COLOR_QUESTIONNAIRE_CHERRY}} >{"Recomendación"}</span>}: 
                                <span style={{color:COLOR_STATUS_BLUE}} >{question.Recommendation} </span></Typography>}
                                
                                {question.AnswerSupplier === "3" && <span className={classes.warnignFlex}>
                                    <br/> <WarningIcon className={classes.warningColor}/><Typography variant="caption" color="error"><strong> {question.Warning}</strong></Typography>
                                </span>}
                                {(question.RequiredDocument || question.ClassificationType === 1) && <Typography variant="caption">
                                    <span style={{ color: COLOR_STATUS_DANGER }}><strong> * </strong></span>
                                    {<span style={{color:COLOR_QUESTIONNAIRE_CHERRY}} >
                                        {<Translate id={"sustainability.requiredDocuments"} />}
                                    </span>}</Typography>}
                            </TableCell>
                            {
                                (activeStep === 1 || activeStep === 4) &&
                                <TableCell>
                                    {question.QuestionType !== 1 && <div className={classes.flex}>
                                        { SustainabilityStatus.SEND_SUPPLIER === sustainability.Status && activeStep === 1 ?
                                            <div className={classes.flex}>
                                                <IconButton value="1" onClick={(event) => canBeEdit && updatePropertyQuestion("AnswerSupplier", event.currentTarget.value, question.Id, subtopic.Id, topicId)}
                                                    className={question.AnswerSupplier === "1" ? classes.avatarSuccess : classes.avatar}
                                                    style={{cursor: !canBeEdit ? 'context-menu' : 'pointer',}}>
                                                    <Typography variant="caption" style={{ color: "white" }}>{<Translate id={"sustainability.yes"} />}</Typography>
                                                </IconButton >
                                                <IconButton value="2" onClick={(event) => canBeEdit && updatePropertyQuestion("AnswerSupplier", event.currentTarget.value, question.Id, subtopic.Id, topicId)}
                                                    className={question.AnswerSupplier  === "2" ? classes.avatarSuccess : classes.avatar}
                                                    style={{cursor: !canBeEdit ? 'context-menu' : 'pointer',}}>
                                                    <Typography variant="caption" style={{ color: "white" }}>{<Translate id={"sustainability.not"} />}</Typography>
                                                </IconButton>

                                                <IconButton value="3" onClick={(event) => canBeEdit && updatePropertyQuestion("AnswerSupplier", event.currentTarget.value, question.Id, subtopic.Id, topicId)}
                                                className={question.AnswerSupplier === "3"  ? classes.avatarSuccess : classes.avatar}
                                                style={{cursor: !canBeEdit ? 'context-menu' : 'pointer',}}>
                                                    <Typography variant="caption" style={{ color: "white" }}>{<Translate id={"sustainability.na"} />}</Typography>
                                                </IconButton>
                                            </div>
                                            :
                                            <Chip style={{ color: "white" }} 
                                                label={getAnswerQuestion(question.AnswerSupplier)} className={question.AnswerSupplier ? classes.avatarSuccess : classes.avatar}>
                                            </Chip>
                                        }
                                    </div>}
                                </TableCell>
                            }
                            {
                                (activeStep === 1 || activeStep === 4) &&
                                <TableCell>
                                    <div className={classes.textField}>
                                        <TextField
                                            fullWidth
                                            defaultValue={question.CommnetsSupplier}
                                            disabled={!canBeEdit}
                                            multiline
                                            rowsMax={3}
                                            onBlur={(event) => updatePropertyQuestion("CommnetsSupplier", event.target.value, question.Id, subtopic.Id, topicId)}
                                            InputProps={{ classes: { input: classes.inputProps } }}
                                        />
                                    </div>
                                </TableCell>
                            }
                            {
                                (activeStep === 1) &&
                                <TableCell>
                                    <div className={classes.iconFile}>
                                    <IconButton size="small" onClick={() => onOpenCloseLateralPanel(topicId, subtopic.Id, question) }>
                                        <FileIcon  style={{color: question.Attachments.length === 0 ? COLOR_STATUS_INFO: COLOR_STATUS_WARNING}} />
                                    </IconButton>
                                    </div>
                                </TableCell>
                            }
                            { activeStep === 4 &&  <TableCell>
                                {question.QuestionType !== 1 &&
                                <div className={classes.flex}>
                                    <IconButton value="1" onClick={(event) => canBeEditResponsible && updatePropertyQuestion("AnswerPlant", event.currentTarget.value, question.Id, subtopic.Id, topicId)}
                                        className={question.AnswerPlant === "1" ? classes.avatarSuccess : classes.avatar}
                                        style={{cursor: !canBeEditResponsible ? 'context-menu' : 'pointer',}}>
                                        <Typography variant="caption" style={{ color: "white" }}>{<Translate id={"sustainability.yes"} />}</Typography>
                                    </IconButton >
                                    <IconButton value="2" onClick={(event) => canBeEditResponsible && updatePropertyQuestion("AnswerPlant", event.currentTarget.value, question.Id, subtopic.Id, topicId)}
                                        className={question.AnswerPlant  === "2" ? classes.avatarSuccess : classes.avatar}
                                        style={{cursor: !canBeEditResponsible ? 'context-menu' : 'pointer',}}>
                                        <Typography variant="caption" style={{ color: "white" }}>{<Translate id={"sustainability.not"} />}</Typography>
                                    </IconButton>

                                    <IconButton value="3" onClick={(event) => canBeEditResponsible && updatePropertyQuestion("AnswerPlant", event.currentTarget.value, question.Id, subtopic.Id, topicId)}
                                    className={question.AnswerPlant === "3"  ? classes.avatarSuccess : classes.avatar}
                                    style={{cursor: !canBeEditResponsible ? 'context-menu' : 'pointer',}}>
                                        <Typography variant="caption" style={{ color: "white" }}>{<Translate id={"sustainability.na"} />}</Typography>
                                    </IconButton>
                                </div>}
                            </TableCell>
                            }
                            {
                                activeStep === 4 && <TableCell>
                                    <TextField
                                        className={classes.textField}
                                        fullWidth
                                        defaultValue={question.CommnetsPlant}
                                        disabled={!canBeEditResponsible}
                                        multiline
                                        rowsMax={3}
                                        onBlur={(event) => updatePropertyQuestion("CommnetsPlant", event.target.value, question.Id, subtopic.Id, topicId)}
                                        InputProps={{ classes: { input: classes.inputProps } }}
                                    />
                                </TableCell>
                            }
                            {
                                activeStep === 4 && <TableCell>
                                    {getStatusQuestion(question.Status)}
                                </TableCell>
                            }
                        </TableRow>
                    )
                })}
            </TableBody>

            {
                openPanelLateral.opened &&
                <PanelFiles
                    onCloseButtonClick={onOpenCloseLateralPanel}
                    topicId={openPanelLateral.topicId}
                    subtopicId={openPanelLateral.subtopicId}
                    question={openPanelLateral.question}
                    canBeEdit={canBeEdit}
                />
            }
        </>
    )
};

const styles = (theme) => ({
    listHeader: {
        backgroundColor: BACKGROUND_COLOR_GRAY_DARK,
    },
    inputProps: {
        height: 6,
        fontSize: 12
    },
    paddingTopic: {
        padding: "10px"
    },
    flex: {
        display: "flex",
        justifyContent: "center"

    },
    avatar: {
        marginLeft: "3px",
        width: "25px",
        height: "25px",
        fontSize: 11,
        backgroundColor: BACKGROUND_COLOR_GRAY_DARK,
    },
    avatarSuccess: {
        marginLeft: "3px",
        width: "25px",
        height: "25px",
        backgroundColor: "rgba(67, 119, 196, 1)"
    },
    textField: {
        minWidth: "40px",
        maxWidth: "200px",
    },
    iconFile: {
        minWidth: "10px",
        maxWidth: "30px",
    },
    warnignFlex: {
        display: "flex",
    },
    warningColor:{
        color: COLOR_STATUS_WARNING,
        width: "18px",

    }
});

QuestionnaireTable.propTypes = {
    /**
     * Determinate if is loading information from back end
     * and see view skeleton
     */
    isLoading: PropTypes.bool.isRequired,
};

QuestionnaireTable.defaultProps = {
    isLoading: false,
    topic: {
        Subtopics: [],
    }
};

export default withRouter(
    withStyles(styles, { withTheme: true })(QuestionnaireTable)
);
