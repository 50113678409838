/** Helpers import section */
import axios from 'axios';
import { getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserFiles} from '../helpers/SessionHelper';
import { IsNullOrEmpty } from '../helpers/StringHelper';

//INDEX CONSTS
export const CHANGE_CONTACT_SUPPLIERS_SORT_CRITERIA = "CHANGE_CONTACT_SUPPLIERS_SORT_CRITERIA";
export const CHANGE_CONTACT_SUPPLIERS_SORT_QUERY = "CHANGE_CONTACT_SUPPLIERS_SORT_QUERY";
export const CHANGE_CONTACT_SUPPLIERS_PAGINATION = "CHANGE_CONTACT_SUPPLIERS_PAGINATION";
export const IS_LOADING_CONTACT_SUPPLIERS_INDEX = "IS_LOADING_CONTACT_SUPPLIERS_INDEX";
export const SET_CONTACT_SUPPLIERS_LIST = "SET_CONTACT_SUPPLIERS_LIST";
export const SET_CONTACT_SUPPLIERS_COUNT = "SET_CONTACT_SUPPLIERS_COUNT";
export const SET_CONTACTS_LIST_TO_UPDATE = "SET_CONTACTS_LIST_TO_UPDATE";

export const IS_LOADING_CONTACT_SUPPLIER = "IS_LOADING_CONTACT_SUPPLIER";
export const SET_CONTACT_SUPPLIER = "SET_CONTACT_SUPPLIER";
export const IS_SAVING_CONTACT_SUPPLIER= "IS_SAVING_CONTACT_SUPPLIER";
export const REMOVE_CONTACT_UPDATE_SUPPLIER = "REMOVE_CONTACT_UPDATE_SUPPLIER";

function isLoadingList(isLoadingIndex) {
    return {
        type: IS_LOADING_CONTACT_SUPPLIERS_INDEX,
        isLoadingIndex
    }
}

function setContactSuppliersList(contactSuppliersList) {
    return {
        type: SET_CONTACT_SUPPLIERS_LIST,
        contactSuppliersList
    }
}

function setContactSuppliersCount(contactSuppliersCount) {
    return {
        type: SET_CONTACT_SUPPLIERS_COUNT,
        contactSuppliersCount
    }
}

function isLoadingContactSupplier(isLoadingContactSupplier) {
    return {
        type: IS_LOADING_CONTACT_SUPPLIER,
        isLoadingContactSupplier
    }
}


function setContactSupplier(contactSupplier) {
    return {
        type: SET_CONTACT_SUPPLIER,
        contactSupplier
    }
}

function isSavingContactSupplier(isSaving){
    return{
        type: IS_SAVING_CONTACT_SUPPLIER,
        isSaving
    }
}

/**
 * Action to set contacts list to update list 
 * @param {*} users 
 */
function setContactsListToUpdate(contactsList) {
    return {
        type: SET_CONTACTS_LIST_TO_UPDATE,
        contactsList: contactsList
    }
}

/**
 * Action to remove contact update by supplier
 * @param {*} tokenNotice 
 * @param {*} documentName 
 */
function removeContactToUpdateInList(contactToken) {
    return {
        type: REMOVE_CONTACT_UPDATE_SUPPLIER,
        contactToken
    }
}
/**
 * Action to get a list of contact suppliers by filters
 *
 * @export
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @returns
 */
export function loadContactSuppliersFromBackEnd(query, orderBy, sortAscending) {
    return function (dispatch, getState) {

        dispatch(isLoadingList(true));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contactsuppliers/contacts`;

            let data = {
                CompanyToken: selectedCompany.get("token"),
                SortBy: orderBy,
                SortAscending: sortAscending,
                Query: query,
                Page: 1,
                RowsPerPage: 50,
            }
            return axios.post(endPoint, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setContactSuppliersList(serverResponse.data))
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingList(false));
                    console.error("Error getting the contact suppliers list", error.response)
                })
        }
        else {
            dispatch(isLoadingList(false));
        }
    }
}

/**
 *Action to get contact suppliers count
 *
 * @export
 * @returns
 */
export function getContactSuppliersCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().contactSuppliers ? getState().contactSuppliers.get('contactSuppliersCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        if (selectedCompany) {
            let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contactsuppliers/count`;

            let data = {
                CompanyToken: selectedCompany.get("token"),
            }

            return axios.post(endPoint, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setContactSuppliersCount(serverResponse.data))
                    }
                })
                .catch((error) => {
                    console.error("Error getting the contact suppliers count", error.response)
                })
        }
    }
}


/**
 * Method to create contact supplier
 * @param {*} contactSupplier 
 */
export function createContactSupplier(contactSupplier) {
    return function (dispatch, getState) {

       dispatch(isSavingContactSupplier(true));

        let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contactsuppliers`;
        let companyToken = getState().profile.get("selectedCompany").get('token');

        let suppliersList = '';
        contactSupplier.suppliers.forEach((supplier) => {
            if (!IsNullOrEmpty(suppliersList))
                suppliersList += '>'
            suppliersList += supplier.name
        })

        let excludeSuppliersList = '';
        contactSupplier.excludeSuppliers.forEach((supplier) => {
            if (!IsNullOrEmpty(excludeSuppliersList))
            excludeSuppliersList += '>'
                excludeSuppliersList += supplier.code
        })

        let formData = new FormData();
        contactSupplier.attachments.forEach((file) => {
            formData.append('file', file);
        })
        formData.set('CompanyToken', companyToken);
        formData.set('Name', contactSupplier.name);
        formData.set('ContactType', contactSupplier.contact_type.value);
        formData.set('AcceptedBySupplier', contactSupplier.acceptedBySupplier);
        formData.set('Nationals', contactSupplier.optionNationals);
        formData.set('Foreigns', contactSupplier.optionForeigns);
        formData.set('Suppliers', suppliersList);
        formData.set('ExcludeSuppliers', excludeSuppliersList);

        return axios.post(endPoint, formData, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isSavingContactSupplier(false));
                    return Promise.resolve(response.data);
                }
                else {
                    dispatch(isSavingContactSupplier(false));
                }
            })
            .catch((error) => {
                console.error("Error create ethic code", error.response);
                dispatch(isSavingContactSupplier(false));
                return Promise.reject(error.response.data.Message);
            })
    }
}

/**
 * Get and set the contact supplier in the estate from read from backend
 * @param {*} token 
 */
export function loadContactSupplier(token) {
    return function (dispatch, getState) {
        dispatch(isLoadingContactSupplier(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contactsuppliers/contactsupplier/${token}`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setContactSupplier(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load contact supplier", error.response)
                dispatch(isLoadingContactSupplier(false))
                return Promise.reject();
            })
    }
}

/**
 * Method to donwload report list contacts update
 * @param {*} ethicCodeToken 
 */
export function downloadContactSuppliersUpdateExcelReport(contactSupplierToken) {
    return function (dispatch, getState) {
        dispatch(isSavingContactSupplier(true));
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contactsuppliers/${contactSupplierToken}/downloadreport`;
        return axios.get(endPoint, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isSavingContactSupplier(false));
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Contactos_actualizados.xlsx";
                    a.click();
                }
            })
            .catch((error) => {
                dispatch(isSavingContactSupplier(false));
                console.error("Error in download report contact supplier update ", error.response)
                return Promise.reject();
            })
    }
}

/**
 * Action to load contact supplier for update contacts
 */
export function loadContactSupplierForUpdate() {
    return function (dispatch, getState) {

        let contactsList = getState().contactSuppliers ? getState().contactSuppliers.get('contactsListUpdate') : null;
        if (contactsList) {
            return Promise.resolve();
        }

        dispatch(isLoadingList(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let supplierCode = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
            supplierCode = getState().oidc.user.profile.company_code;
        }

        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contactsuppliers/${selectedCompany.get('token')}/updatecontact/${supplierCode}`;
            return axios.get(endPoint, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setContactsListToUpdate(serverResponse.data))
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingList(false));
                    console.error("Error getting the contact supplier's update", error.response)
                })
        }
        else {
            return Promise.reject();
        }
    }
}

/**
 * 
 * @param {*} noticeToken 
 * @param {*} documentName 
 */
export function addContactSupplierUpdate(contactToken, contactStatus, contactUpdate) {
    return function (dispatch, getState) {

        dispatch(isSavingContactSupplier(true));

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let selectedCompany = getState().profile.get("selectedCompany");
        let supplier = getState().suppliers.get("supplier");
        let supplierCode = undefined;
        let supplierName = undefined;
        if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
            supplierCode = getState().oidc.user.profile.company_code;
        }
        if (supplier) {
            supplierName = supplier.get("name");
        }

        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contactsuppliers/addcontactUpdate`;
        let data = {
            CompanyToken: selectedCompany.get('token'),
            SupplierCode: supplierCode,
            SupplierName: supplierName,
            ContactToken: contactToken,
            ContactStatus: contactStatus,
            UpdateBy: user.profile.name,
            SaleName: contactUpdate.sale_name,
            SaleEmail: contactUpdate.sale_email,
            SalePhone: contactUpdate.sale_phone,
            BillingName: contactUpdate.billing_name,
            BillingEmail: contactUpdate.billing_email,
            BillingPhone: contactUpdate.billing_phone,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(removeContactToUpdateInList(contactToken))
                    dispatch(isSavingContactSupplier(false));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSavingContactSupplier(false));
                console.error("Error adding a contact update by supplier", error.response)
                return Promise.reject(error.response.data.Message);
            })
    }
}

/**
 *Action to download report general of contacts by range date
 *
 * @export
 * @param {*} startDate
 * @returns
 */
export function downloadGeneralReportByRangeDate(startDate, endDate) {
    return (dispatch, getState) => {
        dispatch(isSavingContactSupplier(true));
        let companyToken = getState().profile.get("selectedCompany").get('token');
        let data = {
            CompanyToken: companyToken,
            StartDate: startDate,
            EndDate: endDate
        }
        let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contactsuppliers/downloadGeneralReportByRangeDate`;
        return axios.post(endPoint, data, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isSavingContactSupplier(false));
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reportes_general_contactos.xlsx";
                    a.click();
                }
            })
            .catch((error) => {
                dispatch(isSavingContactSupplier(false));
                console.error("Error downloading general contacts report by month", error.response)
                return Promise.reject();
            })
    }
}


