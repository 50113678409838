///Import react section
import React, { useEffect, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { withLocalize } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useQueryParam, DelimitedArrayParam } from 'use-query-params'
import moment from 'moment'
// import data from './components/data.json'
/** Material-UI imports section */
import { Hidden } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

//import components section
import translations from '../../resources/translations.json'
import {
	changeLoadAlertsQuery,
	loadTrackingsFromBackEnd,
	GetLoadlertDisticOptions,
	GetLoadlertItemsDisticOptions,
	changeLoadAlertsPagination,
} from '../../store/loadAlerts/LoadAlertAction'
import IndexDesktopView from './components/IndexDesktopView'
import IndexMobileView from './components/IndexMobileView'
import { mappedCatologOptions } from '../../store/helpers/SelectOptions'
import { FilterCaseOptions } from '../../store/helpers/LoadAlertHelper'
import FilterConfigurationPanel from './components/FilterConfigurationPanel'

const Index = (props) => {
	const dispatch = useDispatch()
	const page = useSelector((state) => state.loadAlert.get('page'))
	const isLoading = useSelector((state) => state.loadAlert.get('isLoading'))
	const rowsPerPage = useSelector((state) => state.loadAlert.get('rowsPerPage'))
	const trackings = useSelector((state) =>
		state.loadAlert.get('trackings') ? state.loadAlert.get('trackings').toJS() : []
	)

	const trackingCount = useSelector((state) =>
		state.loadAlert.get('trackingCount') ? state.loadAlert.get('trackingCount') : 0
	)

	const [dateRangeCreationDate, setDateRangeCreationDate] = useState({
		startDate: moment().subtract(2, 'months').startOf('month'),
		endDate: moment(),
	})

	const [dateRangeETA, setDateRangeETA] = useState({
		startDate: moment().subtract(2, 'months').startOf('month'),
		endDate: moment(),
	})

	const [sitFolioCatalogOptions, setSitFolioCatalogOptions] = useState([])
	const [supplierAduanalCatalogOptions, setSupplierAduanalCatalogOptions] = useState([])
	// const [placesCatalogOptions, setPlacesCatalogOptions] = useState([])
	const [trackingLinkCatalogOptions, setTrackingLinkCatalogOptions] = useState([])
	const [supplierMaterialCatalogOptions, setSupplierMaterialCatalogOptions] = useState([])
	const [referenceInternationalCatalogOptions, setReferenceInternationalCatalogOptions] = useState([])
	const [shippingOrderCatalogOptions, setShippingOrderCatalogOptions] = useState([])
	const [bookingNumberCatalogOptions, setBookingNumberCatalogOptions] = useState([])
	const [filterOpen, setFilterOpen] = useState(false)

	const handleFilterOpen = () => {
		setFilterOpen(true)
	}

	const handleFilterClose = () => {
		setFilterOpen(false)
	}
	//Filters
	const [filterSitFolios, setFilterSitFolios] = useQueryParam('SitFolios', DelimitedArrayParam)
	const [filterStatusOptions, setFilterStatusOptions] = useQueryParam('statusShipsGo', DelimitedArrayParam)
	const [filterTrackingLinks, setFilterTrackingLinksOptions] = useQueryParam('trackingLinks', DelimitedArrayParam)
	const [filterSupplierMaterialsOptions, setFilterSupplierMaterialsOptions] = useQueryParam(
		'supplierMaterials',
		DelimitedArrayParam
	)
	const [filterSupplierAduanalsOptions, setFilterSupplierAduanalsOptions] = useQueryParam(
		'supplierAduanals',
		DelimitedArrayParam
	)

	const [filterReferenceInternationalsOptions, setFilterReferenceInternationalsOptions] = useQueryParam(
		'referenceInternationals',
		DelimitedArrayParam
	)
	const [filterShippingOrdersOptions, setFilterShippingOrdersOptions] = useQueryParam(
		'shippingOrders',
		DelimitedArrayParam
	)
	const [filterBookingNumbersOptions, setFilterBookingNumbersOptions] = useQueryParam(
		'bookingNumbers',
		DelimitedArrayParam
	)
	const [filterVinculatedOptions, setFfilterVinculatedOptions] = useQueryParam('Vinculated', DelimitedArrayParam)

	// const [pageNumber, setPageNumber] = useQueryParam('pageNumber', NumberParam)

	const handleFilterChange = (selectIdentifier, selectedOption) => {
		const newFilters = selectedOption ? selectedOption.map((option) => option.value) : []
		if (selectIdentifier === FilterCaseOptions.FoliosSit) {
			setFilterSitFolios(newFilters, 'pushIn')
		}
		if (selectIdentifier === FilterCaseOptions.StatusShipsGo) {
			setFilterStatusOptions(newFilters, 'pushIn')
		}
		if (selectIdentifier === FilterCaseOptions.TrackingLinks) {
			setFilterTrackingLinksOptions(newFilters, 'pushIn')
		}
		if (selectIdentifier === FilterCaseOptions.SupplierMaterials) {
			setFilterSupplierMaterialsOptions(newFilters, 'pushIn')
		}
		if (selectIdentifier === FilterCaseOptions.ReferenceInternationals) {
			setFilterReferenceInternationalsOptions(newFilters, 'pushIn')
		}
		if (selectIdentifier === FilterCaseOptions.ShippingOrders) {
			setFilterShippingOrdersOptions(newFilters, 'pushIn')
		}
		if (selectIdentifier === FilterCaseOptions.BookingNumbers) {
			setFilterBookingNumbersOptions(newFilters, 'pushIn')
		}
		if (selectIdentifier === FilterCaseOptions.SupplierAduanals) {
			setFilterSupplierAduanalsOptions(newFilters, 'pushIn')
		}
		if (selectIdentifier === FilterCaseOptions.Vinculate) {
			setFfilterVinculatedOptions(newFilters, 'pushIn')
		}
	}

	const sortCriteria = useSelector((state) => state.loadAlert.get('sortCriteria').toJS())
	const query = useSelector((state) => state.loadAlert.get('searchQuery'))
	let defaultLanguage = localStorage.getItem('language')
	if (!defaultLanguage) {
		defaultLanguage = 'es'
	}

	const onChangeSearchValue = (value) => {
		dispatch(changeLoadAlertsQuery(value))
	}

	useEffect(() => {
		//LoadAlert class
		dispatch(GetLoadlertDisticOptions('SITFolio', 'sit_folio')).then((items) => {
			setSitFolioCatalogOptions(items)
		})
		dispatch(GetLoadlertDisticOptions('ReferenceNumber', 'ReferenceNumber')).then((items) => {
			setReferenceInternationalCatalogOptions(items)
		})
		dispatch(GetLoadlertDisticOptions('SupplierCode', 'supplier_code')).then((items) => {
			setSupplierMaterialCatalogOptions(items)
		})
		dispatch(GetLoadlertDisticOptions('ShippingOrder', 'ShippingOrder')).then((items) => {
			setShippingOrderCatalogOptions(items)
		})

		//Load Alert item class

		dispatch(GetLoadlertItemsDisticOptions('SupplierCode', 'supplier_code')).then((items) => {
			setSupplierAduanalCatalogOptions(items)
		})
		dispatch(GetLoadlertItemsDisticOptions('TrackingGuide', 'trackingGuide')).then((items) => {
			setTrackingLinkCatalogOptions(items)
		})
		dispatch(GetLoadlertItemsDisticOptions('BookingNumber', 'booking_number')).then((items) => {
			setBookingNumberCatalogOptions(items)
		})
	}, [dispatch])

	useEffect(() => {
		dispatch(
			loadTrackingsFromBackEnd(
				query,
				sortCriteria.by,
				sortCriteria.ascending,
				page,
				rowsPerPage,
				dateRangeCreationDate,
				dateRangeETA,
				filterSitFolios,
				filterStatusOptions,
				filterTrackingLinks,
				filterSupplierMaterialsOptions,
				filterReferenceInternationalsOptions,
				filterShippingOrdersOptions,
				filterBookingNumbersOptions,
				filterSupplierAduanalsOptions,
				filterVinculatedOptions
			)
		)
	}, [
		dispatch,
		query,
		sortCriteria.by,
		sortCriteria.ascending,
		page,
		rowsPerPage,
		dateRangeCreationDate,
		dateRangeETA,
		filterSitFolios,
		filterStatusOptions,
		filterTrackingLinks,
		filterSupplierMaterialsOptions,
		filterReferenceInternationalsOptions,
		filterShippingOrdersOptions,
		filterBookingNumbersOptions,
		filterSupplierAduanalsOptions,
		filterVinculatedOptions,
	])

	const delay = 600000 // 10 minutes (600,000 milliseconds)

	useEffect(() => {
		const intervalId = setInterval(() => {
			dispatch(
				loadTrackingsFromBackEnd(
					query,
					sortCriteria.by,
					sortCriteria.ascending,
					page,
					rowsPerPage,
					dateRangeCreationDate,
					dateRangeETA,
					[],
					[],
					[],
					[],
					[],
					[],
					[]
				)
			)
		}, delay)

		return () => clearInterval(intervalId)
	}, [
		dispatch,
		query,
		sortCriteria.by,
		sortCriteria.ascending,
		page,
		rowsPerPage,
		dateRangeCreationDate,
		dateRangeETA,
	])
	useEffect(() => {
		props.initialize({
			languages: [
				{ name: 'EspaÃ±ol', code: 'es' },
				{ name: 'English', code: 'en' },
				{ name: 'Portugues', code: 'pt' },
			],
			translation: translations,
			options: {
				renderToStaticMarkup,
				defaultLanguage: defaultLanguage,
			},
		})
	}, [])

	const handleCreationDateRangeChange = (dateRange) => {
		setDateRangeCreationDate(dateRange)
	}

	const onHandleETARangeChange = (dateRange) => {
		setDateRangeETA(dateRange)
	}

	const onChangePage = (page, rowsPerPage) => {
		dispatch(changeLoadAlertsPagination(page, rowsPerPage))
	}

	/** Clean filter */
	const cleanFilter = () => {
		setFilterSitFolios(undefined, 'pushIn')
		setFilterStatusOptions(undefined, 'pushIn')
		setFilterTrackingLinksOptions(undefined, 'pushIn')
		setFilterSupplierMaterialsOptions(undefined, 'pushIn')
		setFilterReferenceInternationalsOptions(undefined, 'pushIn')
		setFilterShippingOrdersOptions(undefined, 'pushIn')
		setFilterBookingNumbersOptions(undefined, 'pushIn')
		setFfilterVinculatedOptions(undefined, 'pushIn')
		setFilterSupplierAduanalsOptions(undefined, 'pushIn')
		setFilterSupplierAduanalsOptions(undefined, 'pushIn')

		dispatch(changeLoadAlertsQuery(''))
		dispatch(changeLoadAlertsPagination(1, 50))
		setDateRangeETA({
			startDate: moment().subtract(2, 'months').startOf('month'),
			endDate: moment(),
		})
		setDateRangeCreationDate({
			startDate: moment().subtract(2, 'months').startOf('month'),
			endDate: moment(),
		})
	}

	const countAppliedFilters = () => {
		let count = 0;
		if (filterSitFolios && filterSitFolios.length > 0) count++;
		if (filterStatusOptions && filterStatusOptions.length > 0) count++;
		if (filterTrackingLinks && filterTrackingLinks.length > 0) count++;
		if (filterSupplierMaterialsOptions && filterSupplierMaterialsOptions.length > 0) count++;
		if (filterReferenceInternationalsOptions && filterReferenceInternationalsOptions.length > 0) count++;
		if (filterShippingOrdersOptions && filterShippingOrdersOptions.length > 0) count++;
		if (filterBookingNumbersOptions && filterBookingNumbersOptions.length > 0) count++;
		if (filterSupplierAduanalsOptions && filterSupplierAduanalsOptions.length > 0) count++;
		if (filterVinculatedOptions && filterVinculatedOptions.length > 0) count++;
		return count;
	};
	return (
		<>
			<Hidden mdUp>
				<IndexMobileView />
			</Hidden>
			<Hidden smDown>
				<IndexDesktopView
					trackings={trackings}
					query={query}
					onChangeSearchValue={onChangeSearchValue}
					handleCreationDateRangeChange={handleCreationDateRangeChange}
					//Array props section

					//Functions
					handleFilterChange={handleFilterChange}
					cleanFilter={cleanFilter}
					onChangePage={onChangePage}
					//
					page={page}
					rowsPerPage={rowsPerPage}
					trackingCount={trackingCount}
					handleFilterOpen={handleFilterOpen}
					//bools
					isLoading={isLoading}
					//int
					countAppliedFilters={countAppliedFilters()}
				/>
				{filterOpen && (
					<FilterConfigurationPanel
						onCloseButtonClick={handleFilterClose}
						open={filterOpen}
						dateRangeETA={dateRangeETA}
						dateRangeCreationDate={dateRangeCreationDate}
						onHandleETARangeChange={onHandleETARangeChange}
						cleanFilter={cleanFilter}
						handleCreationDateRangeChange={handleCreationDateRangeChange}
						//Array props section
						sitFolioCatalogOptions={
							sitFolioCatalogOptions ? mappedCatologOptions(sitFolioCatalogOptions) : []
						}
						supplierAduanalCatalogOptions={
							supplierAduanalCatalogOptions ? mappedCatologOptions(supplierAduanalCatalogOptions) : []
						}
						trackingLinkCatalogOptions={
							trackingLinkCatalogOptions ? mappedCatologOptions(trackingLinkCatalogOptions) : []
						}
						supplierMaterialCatalogOptions={
							supplierMaterialCatalogOptions ? mappedCatologOptions(supplierMaterialCatalogOptions) : []
						}
						referenceInternationalCatalogOptions={
							referenceInternationalCatalogOptions
								? mappedCatologOptions(referenceInternationalCatalogOptions)
								: []
						}
						shippingOrderCatalogOptions={
							shippingOrderCatalogOptions ? mappedCatologOptions(shippingOrderCatalogOptions) : []
						}
						bookingNumberCatalogOptions={
							bookingNumberCatalogOptions ? mappedCatologOptions(bookingNumberCatalogOptions) : []
						}
						handleFilterChange={handleFilterChange}
					/>
				)}
			</Hidden>
		</>
	)
}

const styles = (theme) => ({})
export default withLocalize(withRouter(withStyles(styles)(Index)))
