/** Helpers import section */
import axios from 'axios';
import moment from 'moment';
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';
import { descriptionStatus, backgroundColorTableStatus } from '../helpers/WarehouseWindowsHelper'

export const SET_REQUESTS_COUNT = "SET_REQUESTS_COUNT";
export const START_LOADING_REQUESTS = "START_LOADING_REQUESTS";
export const IS_SAVING = "IS_SAVING";
export const IS_LOADING_REQUEST = "IS_LOADING_REQUEST";
export const SET_DELIVERY_DATE_HISTORY = "SET_DELIVERY_DATE_HISTORY";
export const SET_NEW_STATUS = "SET_NEW_STATUS";
export const IS_DOWNLOADING_REPORT_WAREHOUSE = "IS_DOWNLOADING_REPORT_WAREHOUSE";
/**Add actions section */
export const SET_REQUESTS_LIST = "SET_REQUESTS_LIST";
export const SET_REQUEST = "SET_REQUEST"
export const ADD_REQUEST = "ADD_REQUEST"

/**Update actions section */

/**Delete actions section */

/**
 * Action start loading common data
 * @param {*} isLoading 
 */
function isLoadingCommon(isLoading, identifier) {
    return {
        type: START_LOADING_REQUESTS,
        isLoading: isLoading,
        identifier: identifier
    }
}

/**
 * Action start saving information
 * @param {*} isLoading 
 */
function isSavingCommon(isSaving, identifier) {
    return {
        type: IS_SAVING,
        isSaving: isSaving,
        identifier: identifier
    }
}

/**
 * Action to set BILLINGS list 
 * @param {*} requests 
 */
function setRequestsList(requests) {
    return {
        type: SET_REQUESTS_LIST,
        requests: requests
    }
}

/**
 * Action to set billings count
 * @param {*} count 
 */
function setRequestsCount(count) {
    return {
        type: SET_REQUESTS_COUNT,
        count
    }
}

function setAddRequest(request) {
    return {
        type: ADD_REQUEST,
        request
    }
}

export function setRequest(request) {
    return {
        type: SET_REQUEST,
        request
    }
}

function isLoadingRequest(isLoadingRequest) {
    return {
        type: IS_LOADING_REQUEST,
        isLoadingRequest
    }
}

function setDeliveryDatesHistory(data) {
    return {
        type: SET_DELIVERY_DATE_HISTORY,
        data
    }
}

function isDonwloadingReport(isDonwloading) {
    return {
        type: IS_DOWNLOADING_REPORT_WAREHOUSE,
        isDonwloading
    }
}

function setNewStatus(request) {
    return {
        type: SET_NEW_STATUS,
        request
    }
}

//#region ACTION FOR BILLINGS


/**
 * Action load billing from company backend
 * @param {*} query 
 * @param {*} orderBy 
 * @param {*} sortAscending 
 * @param {*} page 
 * @param {*} rowsPerPage 
 */
export function loadAllRequestsFromBackEnd(queryParameters, translate) {
    return function (dispatch, getState) {

        dispatch(isLoadingCommon(true, START_LOADING_REQUESTS));
        let selectedCompany = getState().profile.get("selectedCompany");
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/warehouseview/all`
            
            let sortAscending = ((queryParameters && queryParameters.sortDirection) ? queryParameters.sortDirection : 'desc')
            if (!sortAscending) sortAscending = false
            let data = {
                CompanyCode: selectedCompany.get("company_code"),
                OrderBy:  ((queryParameters && queryParameters.sortBy) ? queryParameters.sortBy : "CreationDate"),
                AscDesc: sortAscending,
                Query: queryParameters.query ? queryParameters.query : "",
                PageNumber: (queryParameters && queryParameters.pageNumber) ? (parseInt(queryParameters.pageNumber) + 1) : 1,
                PageSize: (queryParameters && queryParameters.rowsPerPage) ? queryParameters.rowsPerPage : 20,
            }
            return axios.post(endPoint, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        let mappedResponse = serverResponse.data.map(request => {

                            request.color_status_table = backgroundColorTableStatus(request.Status)
                            request.status = descriptionStatus(request.Status, translate)
                            request.DeliverDate = moment(request.DeliverDate).format('DD/MM/YYYY')
                            return request
                        })
                        dispatch(setRequestsList(mappedResponse))
                        return Promise.resolve()
                    }
                })
                .catch((error) => {
                    dispatch(isLoadingCommon(false, SET_REQUESTS_LIST));
                    console.error("Error getting the requests list by company", error.response)
                    return Promise.reject(error.response)
                })

        }
        else {
            dispatch(isLoadingCommon(false, SET_REQUESTS_LIST));
        }
    }
}


export function addRequest(requestModel, translate) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, ADD_REQUEST));
        let selectedCompany = getState().profile.get("selectedCompany");
        let user = getState().oidc.user;
        if (selectedCompany) {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/warehouseView/create`;

            let command = {
                PlateNumber: requestModel.plateNumber,
                DeliveryDate: requestModel.deliveryDate ? moment(requestModel.deliveryDate).format('DD/MM/YYYY') : "",
                OperativeUnit: requestModel.operativeUnit,
                TransportType: requestModel.transportType,
                Material: requestModel.material,
                CreatorUserName: user.profile.username,
                CompanyCode: selectedCompany.get('company_code'),
                CreatorName: user.profile.name,
            }
            return axios.post(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        var response = serverResponse.data;
                        response.color_status_table = backgroundColorTableStatus(response.Status)
                        response.status = descriptionStatus(response.Status, translate)
                        dispatch(setAddRequest(response))
                        return Promise.resolve(true)
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, ADD_REQUEST));
                    console.error("Error in adding warehouse windows request", error.response)
                    return Promise.reject(false)
                })
        }
        else {
            dispatch(isSavingCommon(false, ADD_REQUEST));
        }
    }
}

/**
 *Action to get supplier billings count 
 *
 * @export
 * @returns
 */
 export function getRequestCount() {
    return function (dispatch, getState) {

        let selectedCompany = getState().profile.get("selectedCompany");
        let companyCode = selectedCompany.get('company_code');
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/warehouseview/${companyCode}/Count`;
        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setRequestsCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the requests' count", error.response)
            })
    }
}

/**
 * Action to loading Questionnaire
 * @param {*} token 
 */
 export function getDeliveryDateHistory() {
    return function (dispatch, getState) {
        dispatch(isLoadingRequest(true))

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let companyCode = selectedCompany.get('company_code');
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/warehouseview/${companyCode}/InProcess`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setDeliveryDatesHistory(serverResponse.data))
                }
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error getting information delivery dates", error.response)
                dispatch(isLoadingRequest(false))
                return Promise.reject();
            })
    }
}

export function changeStatus(item, newStatus, translate) {
    return function (dispatch, getState) {

        dispatch(isSavingCommon(true, SET_NEW_STATUS));
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/warehouseview/status`;
            let command = {
                Token: item.token ? item.token : "",
                ChangeStatus: newStatus,
            }
            return axios.put(endPoint, command, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        var response = serverResponse.data;
                        response.color_status_table = backgroundColorTableStatus(response.Status)
                        response.status = descriptionStatus(response.Status, translate)
                        dispatch(setNewStatus(response))
                        return Promise.resolve(true)
                    }
                })
                .catch((error) => {
                    dispatch(isSavingCommon(false, SET_NEW_STATUS));
                    console.error("Error in changing status for warehouse windows request", error.response)
                    return Promise.reject(false)
                })
    }
}

export function downloadHistorialWerehouseWindowsExcel(startDate, endDate) {
    return (dispatch, getState) => {
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        dispatch(isDonwloadingReport(true));
        let data = {
            StartDate: startDate,
            EndDate: endDate,
        }
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/warehouseview/reportbyrange`;
        return axios.post(endPoint, data, { responseType: 'arraybuffer', headers: configuration.headers, })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isDonwloadingReport(false));
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Reporte_historial_warehouse.xlsx";
                    a.click();
                }
                return Promise.resolve();

            })
            .catch((error) => {
                dispatch(isDonwloadingReport(false));
                console.error("Error downloading report maquila", error.response)
                return Promise.reject();
            })
    }
}

//#endregion