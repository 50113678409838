import React, { Component } from 'react'
import autoBind from 'auto-bind'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DeliveryOrdersIcon from '@material-ui/icons/DepartureBoard'
import { GetRampsFersa } from '../../../store/helpers/SelectOptions'

import { WORKFLOW_CANCELLATION } from '../../../store/helpers/WorkFlowDocumentsType'

/**
 * Component ot render Information partialr delivery order
 */
export default class InformationPartial extends Component {
	/**
	 * Create an instance delivery order
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * Method to find document in delivery order action by document_type
	 *
	 * @param {*} documentType
	 * @returns
	 * @memberof Show
	 */
	findDocument(documentType) {
		if (
			this.props.deliveryOrder &&
			this.props.deliveryOrder.documents &&
			this.props.deliveryOrder.documents.length > 0
		)
			return this.props.deliveryOrder.documents.find((document) => {
				return document.document_type === documentType
			})
		else return null
	}

	/**
	 * getInformationOrder
	 * @param {*} propertyData
	 * @param {*} textFieldTranslateId
	 */
	getInformationOrder(propertyData, textFieldTranslateId) {
		return (
			<ListItem divider>
				<ListItemText
					secondary={
						<React.Fragment>
							<Typography
								component='span'
								variant='body2'
								className={this.props.classes.inline}
								color='textPrimary'
							>
								<Translate id={textFieldTranslateId} />
							</Typography>
							{': '}
							{propertyData}{' '}
						</React.Fragment>
					}
				/>
			</ListItem>
		)
	}

	/**
	 * Render
	 */
	render() {
		const { classes } = this.props
		let documentCancel = this.findDocument(WORKFLOW_CANCELLATION)

		return (
			<Paper>
				<List>
					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='deliveryOrderModule.printDateTime' />
									</Typography>
									{': '}
									{this.props.deliveryOrder.print_date} {this.props.deliveryOrder.print_time}
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										{' - '}
										<Translate id='deliveryOrderModule.estimatedArrivalDateTime' />
									</Typography>
									{': '}
									{this.props.deliveryOrder.delivery_date} {this.props.deliveryOrder.delivery_time}
								</React.Fragment>
							}
						/>
					</ListItem>
					{this.getInformationOrder(
						this.props.deliveryOrder.hours_entry_plant,
						'deliveryOrderModule.estimatedPlantDischargeTime'
					)}

					{this.getInformationOrder(
						GetRampsFersa(this.props.deliveryOrder.ramp_number),
						'deliveryOrderModule.rampNumber'
					)}

					{this.getInformationOrder(
						this.props.deliveryOrder.company_center_to_sap,
						'deliveryOrderModule.centerSAP'
					)}


					<ListItem divider>
						<ListItemText
							secondary={
								<React.Fragment>
									<Typography
										component='span'
										variant='body2'
										className={classes.inline}
										color='textPrimary'
									>
										<Translate id='deliveryOrderModule.status' />
									</Typography>
									{': '}
									{
										<strong
											style={{ fontSize: '100%', color: 'white' }}
											className={this.props.deliveryOrder.status_color}
										>
											<DeliveryOrdersIcon /> {this.props.deliveryOrder.get_status_in_time}
										</strong>
									}
								</React.Fragment>
							}
						/>
					</ListItem>

					{documentCancel && (
						<span>
							{this.getInformationOrder(
								documentCancel.creation_date_long,
								'deliveryOrderModule.dateCancel'
							)}
							{this.getInformationOrder(documentCancel.create_by, 'deliveryOrderModule.cancelBy')}
							{this.getInformationOrder(
								documentCancel.reason_for_cancellation,
								'deliveryOrderModule.commentCancel'
							)}
						</span>
					)}
				</List>
			</Paper>
		)
	}
}

/**
 * deliveryOrder
 */
InformationPartial.propTypes = {
	deliveryOrder: PropTypes.object.isRequired,
}
