import React, { Component } from 'react';
import autoBind from 'auto-bind';

import { Translate } from "react-localize-redux";
import LinkMaterial from '@material-ui/core/Link';

//// Nomenclatura
export const _Alta = "_Alta";
export const _Aviso = "_Aviso";
export const _Bancario_Cb = "_Bancario_Cb";
export const _CFC = "_CFC";
export const _Código_y_Principios = "_Código y Principios";
export const _Crédito = "_Crédito";
export const _Garantía_Fne = "_Garantía_Fne";
export const _Engagement_Letter = "Carta_compromiso_proveedores";
export const _Manual = "_Manual";
export const _CEI_Ind = "_CEI_Ind";

export const _Compromiso = "_Compromiso";
export const _E_IndyRef = "_E_Ind y Ref";
export const _E_MP = "_E_MP";
export const _Ev_I_Sostenibilidad = "_Ev_I_Sostenibilidad";
export const _Garantía = "_Garantía";
export const _Privacidad = "_Privacidad";

export const _Carta_CRS = "_Carta CRS";
export const _Bancario_Ec = "_Bancario_Ec";
export const _Certificado_V1 = "_Certificado_V1";

export const _C_Resp_IMSS = "_C. Resp. IMSS y Otros";
export const _Consifencialiad = "_C Confidencialidad";
export const _Prov_Serv = "_C. Prov Serv";

/// Files with extension
export const File_Alta =  '_Alta.xlsx';
export const File_Aviso = "_Aviso.pdf";
export const File_Bancario_Cb = "_Bancario_Cb.docx";
export const File_CFC = "_CFC.pdf";
export const File_Código_y_Principios = "_Código y Principios.pdf";
export const File_Crédito = "_Crédito.docx";
export const File_Garantía_Fne = "_Garantía_Fne.docx";
export const File_Engagement_Letter = "Carta_compromiso_proveedores.docx";
export const File_Manual = "_Manual.pdf";
export const Firmar_Manual = "Firmar_manual.pdf";
export const File_Codigo_de_Etica = "Codigo_de_Etica.pdf";
export const File_Principios_Guía_para_proveedores = "Principios_Guía_para_proveedores.pdf";
export const File_CEI_Ind = "_CEI_Ind.xlsx";

export const File_Compromiso = "_Compromiso.docx";
export const File_E_IndyRef = "_E_Ind y Ref.xlsx";
export const File_E_MP = "_E_MP.xls";
export const File_Ev_I_Sostenibilidad = "_Ev_I_Sostenibilidad.xlsx";
export const File_Garantía = "_Garantía.docx";
export const File_Privacidad = "_Privacidad.pdf";

export const File_Carta_CRS = "_Carta CRS.pdf";
export const File_Bancario_Ec = "_Bancario_Ec.docx";
export const File_Certificado_V1 = "_Certificado_V1.docx";
export const File_Código_y_Principios_doc = "_Código y Principios.docx";

// Agregar documentos
export const File_C_Resp_IMSS = "_C. Resp. IMSS y Otros.docx";
export const File_Consifencialiad = "_C Confidencialidad.docx";
export const File_Prov_Serv = "_C. Prov Serv.xlsx";

/**
 * Dialog to ModalUpdateQuoteMasivaBySupplier
 */
class TemplatesExpedient extends Component {

    /**
   *Creates an instance of ModalUpdateQuoteMasivaBySupplier
   * @param {*} props
   * @memberof DialogActions
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
        }
    }

    /**
     * 
     * @param {*} item 
     * @param {*} pathTemplate 
     */
    getFileTemplateByCompanyIbera(item, pathTemplate) {
        /// _Alta
        if (item.nomenclature_name === _Alta) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}${File_Alta}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>  
        ///_Aviso
        if (item.nomenclature_name === _Aviso) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Aviso}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Bancario_Cb
        if (item.nomenclature_name === _Bancario_Cb) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Bancario_Cb}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_CFC
        if (item.nomenclature_name === _CFC) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_CFC}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Código_y_Principios
        ///
        if (item.nomenclature_name === _Código_y_Principios) 
            return (<span>                
                <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Código_y_Principios}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial><br/>
                <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Principios_Guía_para_proveedores}`}>{<Translate id="expedientSupplier.principlesForSuppliers"/>}</LinkMaterial><br/>
                <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Codigo_de_Etica}`}>{<Translate id="expedientSupplier.codeEthics"/>}</LinkMaterial>
            </span>)
        ///_Crédito
        if (item.nomenclature_name === _Crédito) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Crédito}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Garantía_Fne
        if (item.nomenclature_name === _Garantía_Fne) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Garantía_Fne}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Engagement_Letter
        if (item.nomenclature_name === _Engagement_Letter) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Engagement_Letter}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Manual
        if (item.nomenclature_name === _Manual) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Manual}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_CEI_Ind
        if (item.nomenclature_name === _CEI_Ind) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_CEI_Ind}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>            
        else   
            return "";
    }

    /**
     * 
     * @param {*} item 
     * @param {*} pathTemplate 
     */
    getFileTemplateByCompanyRepare(item, pathTemplate, isSupplierForeign) {
        /// _Alta
        if (item.nomenclature_name === _Alta) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}${File_Alta}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>  
        ///_Compromiso
        if (item.nomenclature_name === _Compromiso) {
            return (<span>
                <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Compromiso}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial><br/>
                <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Codigo_de_Etica}`}>{<Translate id="expedientSupplier.codeEthics"/>}</LinkMaterial><br/>
                <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Principios_Guía_para_proveedores}`}> <Translate id="expedientSupplier.principlesForSuppliers"/></LinkMaterial>
            </span>)
        }
        /// _Manual
        if (item.nomenclature_name === _Manual) {
            //return FALTA que se pase este documento por repare
        }
        ///_Crédito
        if (item.nomenclature_name === _Crédito)
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Crédito}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_E_Ind y Ref
        if (!isSupplierForeign && item.nomenclature_name === _E_IndyRef) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_E_IndyRef}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_E_MP
        if (!isSupplierForeign && item.nomenclature_name === _E_MP) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_E_MP}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Ev_I_Sostenibilidad
        if (!isSupplierForeign && item.nomenclature_name === _Ev_I_Sostenibilidad) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Ev_I_Sostenibilidad}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Garantía
        if (!isSupplierForeign && item.nomenclature_name === _Garantía) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Garantía}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Privacidad natioan
        if (!isSupplierForeign && item.nomenclature_name === _Privacidad) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Privacidad}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Privacidad isSupplierForeign
        if (isSupplierForeign && item.nomenclature_name === _Privacidad) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Privacidad}`}>{<Translate id="expedientSupplier.downloadTemplate"/>}</LinkMaterial>
        /// _C. Resp. IMSS y Otros
        if (!isSupplierForeign && item.nomenclature_name === _C_Resp_IMSS) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_C_Resp_IMSS}`}>{<Translate id="expedientSupplier.downloadTemplate"/>}</LinkMaterial>
        /// _C Confidencialidad
        if (!isSupplierForeign && item.nomenclature_name === _Consifencialiad) 
           return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Consifencialiad}`}>{<Translate id="expedientSupplier.downloadTemplate"/>}</LinkMaterial>
        /// _C. Prov Serv
          if (!isSupplierForeign && item.nomenclature_name === _Prov_Serv) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Prov_Serv}`}>{<Translate id="expedientSupplier.downloadTemplate"/>}</LinkMaterial>
        else   
            return "";
    }

    getFileTemplateByCompanyPTM(item, pathTemplate, isSupplierForeign) {
        /// _Alta
        if (item.nomenclature_name === _Alta) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}${File_Alta}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>  
        ///_Aviso
        if (item.nomenclature_name === _Aviso) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Aviso}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Bancario_Cb
        if (item.nomenclature_name === _Bancario_Cb) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Bancario_Cb}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Código_y_Principios
        if (item.nomenclature_name === _Código_y_Principios) 
            if(isSupplierForeign){
                return (<span>   
                    <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Código_y_Principios_doc}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial><br/>             
                    <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Codigo_de_Etica}`}>{<Translate id="expedientSupplier.codeEthics"/>}</LinkMaterial>
                </span>)
            }
            else {
                return (<span>   
                    <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Código_y_Principios_doc}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial><br/>             
                    <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Principios_Guía_para_proveedores}`}>{<Translate id="expedientSupplier.principlesForSuppliers"/>}</LinkMaterial><br/>
                    <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Codigo_de_Etica}`}>{<Translate id="expedientSupplier.codeEthics"/>}</LinkMaterial>
                </span>) 
            }
        ///_Manual
        if (item.nomenclature_name === _Manual){
            return (<span> 
                <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Manual}`}> <Translate id="expedientSupplier.readManual"/></LinkMaterial><br/>
                <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${Firmar_Manual}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
             </span>) 
        }
            ///_Carta_CRS
        if (item.nomenclature_name === _Carta_CRS && !isSupplierForeign) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Carta_CRS}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Crédito
        if (item.nomenclature_name === _Crédito && !isSupplierForeign) 
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Crédito}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        ///_Certificado_V1
        if(item.nomenclature_name === _Certificado_V1 && isSupplierForeign)
            return <LinkMaterial target="_blank" href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${pathTemplate}/${File_Certificado_V1}`}> <Translate id="expedientSupplier.downloadTemplate"/></LinkMaterial>
        
        else   
            return "";
    }
 
    /**
     * Dialog to update information
     */
    render(){
        const { item, pathTemplate, isCompanyRepare, isSupplierForeign, isCompanyPtm } = this.props;
        if(isCompanyRepare){
            return(this.getFileTemplateByCompanyRepare(item, pathTemplate, isSupplierForeign))
        }
        if(isCompanyPtm){
            return(this.getFileTemplateByCompanyPTM(item, pathTemplate, isSupplierForeign))
        }
        else {
            return(this.getFileTemplateByCompanyIbera(item, pathTemplate))
        }
    }
}


export default TemplatesExpedient;
