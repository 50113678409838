import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import FormView from './FormView'
import Toaster from '../common/Toaster'
import DataTable from '../common/DataTable'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'

import AddCompanytIcon from '@material-ui/icons/AddTwoTone'
import CompaniesIcon from '@material-ui/icons/DomainTwoTone'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import {
	loadCompaniesFromBackEnd,
	createCompanyData,
	getCompaniesCount,
	CHANGE_COMPANIES_SORT_CRITERIA,
	CHANGE_COMPANIES_QUERY,
} from '../../store/companies/CompanyActions'

/**
 * Index view
 *
 * @class Index
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance of index
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showCompanies: true,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			createCompany: {
				name: '',
				commercial_name: '',
				rfc: '',
				address: '',
				zip_code: '',
				partnership_code: '',
				center: '',
				center_fin: '',
				administrator_token: '',
				division: '',
				document_identifier: '',
			},
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadCompanies(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
			this.props.getCompaniesCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoadingUser !== this.props.isLoadingUser && this.props.isLoadingUser === false) {
			this.props.loadCompanies()
			this.props.getCompaniesCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery
		) {
			this.props.loadCompanies(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Method to created company
	 */
	setShowToCreateCompany(open) {
		this.setState({
			showCompanies: open,
			showToaster: false,
		})
	}

	/**
	 * Update the property of the product
	 *
	 * @param {*} user
	 * @param {*} value
	 * @memberof View
	 */
	updateProperty(property, value) {
		let createCompany = { ...this.state.createCompany }
		createCompany[property] = value
		this.setState({
			createCompany: createCompany,
		})
	}

	/**
	 * Execute Company data validation
	 */
	validateData() {
		const { createCompany } = this.state
		let validations = []
		if (createCompany) {
			if (IsNullOrEmpty(createCompany.name)) {
				validations.push({
					key: 'name',
				})
			}
			if (IsNullOrEmpty(createCompany.commercial_name)) {
				validations.push({
					key: 'commercial_name',
				})
			}
			if (IsNullOrEmpty(createCompany.rfc)) {
				validations.push({
					key: 'rfc',
				})
			}
			if (IsNullOrEmpty(createCompany.address)) {
				validations.push({
					key: 'address',
				})
			}
			if (IsNullOrEmpty(createCompany.partnership_code)) {
				validations.push({
					key: 'partnership_code',
				})
			}
			if (IsNullOrEmpty(createCompany.center)) {
				validations.push({
					key: 'center',
				})
			}
			if (IsNullOrEmpty(createCompany.center_fin)) {
				validations.push({
					key: 'center_fin',
				})
			}
			if (IsNullOrEmpty(createCompany.division)) {
				validations.push({
					key: 'division',
				})
			}
			if (IsNullOrEmpty(createCompany.administrator_token)) {
				validations.push({
					key: 'administrator_token',
				})
			}
			if (IsNullOrEmpty(createCompany.document_identifier)) {
				validations.push({
					key: 'document_identifier',
				})
			}
		}
		return validations
	}

	/**
	 * Action to can be update information serie
	 */
	canBeSaved() {
		let validations = []
		validations = this.validateData()
		if (this.props.isSavingCompany) return false
		else return validations.length === 0
	}

	/**
	 * Action to save company
	 */
	onCreateCompany() {
		this.props
			.createCompanyData(this.state.createCompany)
			.then(() => {
				this.setState({
					showCompanies: true,
					showToaster: true,
					toasterMessage: <Translate id='common.addCorrectly' />,
					toasterVariant: 'success',
					createCompany: {
						name: '',
						commercial_name: '',
						rfc: '',
						address: '',
						zip_code: '',
						partnership_code: '',
						center: '',
						center_fin: '',
						administrator_token: '',
						division: '',
						document_identifier: '',
					},
				})
			})
			.catch((error) => {
				this.setState({
					showToaster: true,
					toasterMessage: !IsNullOrEmpty(error) ? error : <Translate id='common.errorToAdd' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Get companies counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		if (this.props.companies) {
			let totalCompanies = this.props.companies.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalCompanies}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.companiesCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props

		if (this.props.isLoadingCompanies === true) {
			return <LoadingPage />
		} else if (this.props.companies === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.companies}
					configuration={TableConfiguration}
					onRenderCellItem={this.onRenderCellItem}
					mode={'clientSide'}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(company) => {
						this.props.history.push(`/viewcompany/${company.token}`)
					}}
					showFilters
					isIndex
				/>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof Index
	 */
	render() {
		const { classes } = this.props
		if (this.state.showCompanies) {
			return (
				<Card>
					<CardHeader
						avatar={
							<Avatar>
								<CompaniesIcon />
							</Avatar>
						}
						action={
							<div className={classes.actionsToolbar}>
								<SearchInput
									className={classes.searchInput}
									onChangeSearchValue={this.onChangeSearchValue}
									value={this.props.searchQuery}
								/>
								<Fab
									size='small'
									color='primary'
									onClick={() => {
										this.setShowToCreateCompany(false)
									}}
								>
									<AddCompanytIcon />
								</Fab>
							</div>
						}
						title={
							<Typography variant='button'>
								<Translate id='companies.companies' />{' '}
							</Typography>
						}
						subheader={this.getCounterMessage()}
					/>
					<CardContent>{this.getCardContent()}</CardContent>
				</Card>
			)
		} else {
			return (
				<Card>
					<CardHeader
						avatar={
							<Avatar>
								<CompaniesIcon />
							</Avatar>
						}
						title={
							<Typography variant='button'>
								<Translate id='companies.createCompany' />
							</Typography>
						}
					/>
					<CardContent style={{ paddingLeft: '80px', paddingRight: '80px' }}>
						<FormView
							company={this.state.createCompany}
							setViewInEditMode={this.setShowToCreateCompany}
							showToView={this.state.showCompanies}
							createCompany={true}
							updateProperty={this.updateProperty}
							errors={this.validateData()}
							canBeSaved={this.canBeSaved()}
							onSave={this.onCreateCompany}
						/>
					</CardContent>
					<Toaster
						message={this.state.toasterMessage}
						open={this.state.showToaster}
						variant={this.state.toasterVariant}
						onClose={() => {
							this.setState({ showToaster: false })
						}}
					/>
				</Card>
			)
		}
	}
}

const TableConfiguration = {
	columns: [
		{
			header: <Translate id='companies.name' />,
			dataSource: 'name',
			isSortable: true,
		},
		{
			header: <Translate id='companies.comercialname' />,
			dataSource: 'commercial_name',
			isSortable: true,
		},
		{
			header: <Translate id='companies.rfc' />,
			dataSource: 'rfc',
		},
		{
			header: <Translate id='companies.partnershipCode' />,
			dataSource: 'partnership_code',
		},
	],
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingCompanies: state.companies.get('isLoadingCompanies'),
		companies: state.companies.get('companies') ? state.companies.get('companies').toJS() : null,
		isSavingCompany: state.companies.get('isSavingCompany'),
		sortCriteria: state.companies.get('sortCriteria') ? state.companies.get('sortCriteria').toJS() : null,
		searchQuery: state.companies.get('searchQuery'),
		companiesCount: state.companies.get('companiesCount'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadCompanies: (query, orderBy, sortAscending) => {
			dispatch(loadCompaniesFromBackEnd(query, orderBy, sortAscending))
		},
		createCompanyData: (createCompany) => {
			return dispatch(createCompanyData(createCompany))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_COMPANIES_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_COMPANIES_QUERY,
				query: query,
			})
		},
		getCompaniesCount: () => {
			dispatch(getCompaniesCount())
		},
	}
}
const styles = (theme) => ({
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
