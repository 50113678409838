
import React, { Component } from 'react';
import autoBind from 'auto-bind'

/** Material UI components imports*/
import { withStyles } from '@material-ui/core/styles';
import {
    Paper, ExpansionPanel, ExpansionPanelSummary,
    Typography, ExpansionPanelDetails, List, ListItem,
    ListItemText, ListItemSecondaryAction, Divider
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/** Redux imports */
import { Translate } from 'react-localize-redux';

/**
 * Evaluation supplier rating
 */
class EvaluationSupplier extends Component {

    /**
     *Creates an instance of MenuConfiguration.
     * @param {*} props
     * @param {*} context
     * @memberof MenuConfiguration
     */
    constructor(props, context) {
        super(props, context);
        autoBind(this);
    }

    /**
     * method to render module section
     *
     * @param {*} moduleName
     * @param {*} module
     * @param {*} sections
     * @returns
     * @memberof MenuConfiguration
     */
    renderModule(moduleName, evaluation, module, sections) {
        return (
            <ExpansionPanel key={moduleName}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                    <Typography className={this.props.classes.headerClass}><Translate id={'companies.evaluations.' + moduleName} />: {sections[evaluation]} %</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div style={{ flexBasis: '100%' }}>
                        <List>
                            <Divider />
                            {Object.keys(module).map(section => {
                                return (
                                    <ListItem key={section} divider >
                                        <ListItemText primary={<Translate id={'companies.evaluations.' + module[section]} />} />
                                        <ListItemSecondaryAction>
                                            <span>{sections[section]} %</span>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </div>
                </ExpansionPanelDetails>
                <Divider />
            </ExpansionPanel>
        )
    }

    /**
     * perform render component
     *
     * @returns
     * @memberof MenuConfiguration
     */
    render() {
        const { sections } = this.props;
        if(sections != null){
            return (
                <Paper>
                    {this.renderModule('deliveryPoints', 'delivery_points', deliveryPoints, sections)}
                    {this.renderModule('sourcingPoints','sourcing_points', sourcingPoints, sections)}
                    {this.renderModule('qualityPoints','quality_points', qualityPoints, sections)}
                    {this.renderModule('servicePoints','service_points', servicePoints, sections)}
                    <List>
                        <Divider />
                        <ListItem >
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                         {" "}<Translate id={'companies.evaluations.totalPoints'}/>
                                        {": "}{sections.total_points} %
                                    </React.Fragment>
                                } />
                        </ListItem>
                    </List>
                </Paper>
            );
        }
        else{
            return(<span><Translate id={'companies.evaluations.withountEvalution'} /></span>)
        }
    }
}

/** Const to define deliveryPoints section */
const deliveryPoints = {
    delivery_points: 'deliveryPoints',
}

/** Const to define sourcingPoints section */
const sourcingPoints = {
    quotes_rate: 'quotesRate',
    response_samples_rate:'responseSamplesRate',
    compromise_date_sample_rate: 'compromiseDateSampleRate',
    sample_rejections_rate: 'sampleRejectionsRate',
}

/** Const to define qualityPoints section */
const qualityPoints = {
    quality_failure_process_rate: 'qualityFailureProcessRate',
    quality_online_stop_rate:'qualityOnlineStopRate',
    quality_ppms_rate: 'qualityPPMSRate',
    sac_rate: 'sacRate',
}

/** Const to define servicePoints section */
const servicePoints = {
    service_points: 'servicePoints',
}


const viewStyles = theme => ({
    headerClass: {
        color: theme.palette.primary.main
    },
    grow: {
        flexGrow: 1,
    },
    secondaryHeading: {
        color: theme.palette.text.secondary,
    },
});


export default withStyles(viewStyles, { withTheme: true })(EvaluationSupplier);