/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'

export const SET_LOAD_ALERT_LIST = 'SET_LOAD_ALERT_LIST'
export const SET_TRACKING_LIST = 'SET_TRACKING_LIST'
export const SET_LOAD_ALERT = 'SET_LOAD_ALERT'
export const SET_LOAD_ALERT_COUNT = 'SET_LOAD_ALERT_COUNT'
export const SET_TRACKING_COUNT = 'SET_TRACKING_COUNT'
export const START_LOADING_LOAD_ALERTS = 'START_LOADING_LOAD_ALERTS'
export const CHANGE_LOAD_ALERT_QUERY = 'CHANGE_LOAD_ALERT_QUERY'
export const CHANGE_LOAD_ALERTS_PAGINATION = 'CHANGE_LOAD_ALERTS_PAGINATION'
export const ADD_FILES_LOAD_ALERT = 'ADD_FILES_LOAD_ALERT'
export const IS_SAVING = 'IS_SAVING'
export const CHANGE_LOAD_ALERT_END_DATE = 'CHANGE_LOAD_ALERT_END_DATE'
export const CHANGE_LOAD_ALERT_START_DATE = 'CHANGE_LOAD_ALERT_START_DATE'

function setLoadAlertsList(loadAlerts) {
	return {
		type: SET_LOAD_ALERT_LIST,
		loadAlerts: loadAlerts,
	}
}

function setTrackingsList(trackings) {
	return {
		type: SET_TRACKING_LIST,
		trackings: trackings,
	}
}

function setLoadAlert(loadAlert) {
	return {
		type: SET_LOAD_ALERT,
		loadAlert: loadAlert,
	}
}

function setLoadAlertsCount(count) {
	return {
		type: SET_LOAD_ALERT_COUNT,
		count,
	}
}

function setTrackingsCount(count) {
	return {
		type: SET_TRACKING_COUNT,
		count,
	}
}

function isLoadingLoadAlerts(isLoading) {
	return {
		type: START_LOADING_LOAD_ALERTS,
		isLoading: isLoading,
	}
}

/**
 * Action start saving information
 * @param {*} isSaving
 */
function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
		identifier: identifier,
	}
}
export function changeLoadAlertsQuery(value) {
	return {
		type: CHANGE_LOAD_ALERT_QUERY,
		query: value,
	}
}
export function changeLoadAlertsStartDate(value) {
	return {
		type: CHANGE_LOAD_ALERT_START_DATE,
		startDate: value,
	}
}
export function changeLoadAlertsEndDate(value) {
	return {
		type: CHANGE_LOAD_ALERT_END_DATE,
		endDate: value,
	}
}
export function changeLoadAlertsPagination(page, rowsPerPage) {
	return {
		type: CHANGE_LOAD_ALERTS_PAGINATION,
		page: page,
		rowsPerPage: rowsPerPage,
	}
}

export function loadAlertsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingLoadAlerts(true))

		let selectedCompany = getState().profile.get('selectedCompany')

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/filters`
		let data = {
			CompanyToken: selectedCompany.get('company_code'),
			SupplierCode: supplierCode,
			SortBy: orderBy,
			SortAscending: sortAscending,
			SearchQuery: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlertsList(serverResponse.data.items))
					dispatch(setLoadAlertsCount(serverResponse.data.total))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error getting the Load alerts', error.response)
			})
	}
}

export function loadTrackingsFromBackEnd(
	query,
	orderBy,
	sortAscending,
	page,
	rowsPerPage,
	dateRangeCreationDate,
	dateRangeETA,
	filterSitFolios = [],
	StatusShipsGo = [],
	filterTrackingLinks = [],
	filterSupplierMaterialsOptions = [],
	filterReferenceInternationalsOptions = [],
	filterShippingOrdersOptions = [],
	filterBookingNumbersOptions = [],
	filterSupplierAduanalsOptions = [],
	filterVinculatedOptions = []
) {
	return function (dispatch, getState) {
		dispatch(isLoadingLoadAlerts(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = ''
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/trackings/filters`
		let data = {
			SupplierCode: supplierCode,
			SortBy: orderBy,
			SortAscending: sortAscending,
			SearchQuery: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
			StartDate: dateRangeCreationDate.startDate,
			EndDate: dateRangeCreationDate.endDate,
			StartDateETA: dateRangeETA.startDate,
			EndDateETA: dateRangeETA.endDate,
			FilterSitFolios: filterSitFolios,
			FilterStatusShipsGo: StatusShipsGo,
			FilterTrackingLinks: filterTrackingLinks,
			FilterSupplierMaterials: filterSupplierMaterialsOptions,
			FilterReferenceInternationals: filterReferenceInternationalsOptions,
			FilterShippingOrders: filterShippingOrdersOptions,
			FilterBookingNumbers: filterBookingNumbersOptions,
			FilterSupplierAduanals: filterSupplierAduanalsOptions,
			FilterVinculated: filterVinculatedOptions,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					console.log('🚀 ~ .then ~ serverResponse:', serverResponse)
					dispatch(setTrackingsList(serverResponse.data.items))
					dispatch(setTrackingsCount(serverResponse.data.total))
					dispatch(isLoadingLoadAlerts(false))

					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error getting trackins', error.response)
			})
	}
}

export function addAlerts(alertModel, isInternalUser) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let companyToken = getState().profile.get('selectedCompany').get('token')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierProfile = getState().oidc.user.profile
		let data = {}
		if (!isInternalUser) {
			data.SupplierName = supplierProfile.name
			data.SupplierCode = supplierProfile.company_code
			data.SupplierEmail = supplierProfile.email
			data.CompanyToken = companyToken
			data.IsCreatingInternalUser = false
		} else {
			data.SupplierName = alertModel.SupplierName
			data.SupplierCode = alertModel.SupplierCode
			data.SupplierEmail = alertModel.SupplierEmail
			data.CompanyToken = companyToken
			data.IsCreatingInternalUser = true
		}
		data.ReferenceNumber = alertModel.ReferenceNumber
		data.ShippingOrder = alertModel.ShippingOrder
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/add`

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isSavingCommon(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error getting the Load alerts', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function loadAlertFromBack(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingLoadAlerts(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/get/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isLoadingLoadAlerts(false))

					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error getting the Load alerts', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function loadAlertByFolioSITFromBack(folioSIT) {
	return function (dispatch, getState) {
		dispatch(isLoadingLoadAlerts(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/byfolio/${folioSIT}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isLoadingLoadAlerts(false))

					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error getting the Load alerts', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function toSit(token) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/tosit/${token}`

		return axios
			.post(endPoint, {}, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false))

					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error getting the Load alerts', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function UpdateLoadAlert(data) {
	return function (dispatch, getState) {
		// dispatch(isLoadingLoadAlerts(true))
		const { token, ...dataWithoutToken } = data
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/update/${token}`

		return axios
			.put(endPoint, dataWithoutToken, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isLoadingLoadAlerts(false))

					return Promise.resolve(serverResponse.data)
				} else {
					dispatch(isLoadingLoadAlerts(false))
					return Promise.reject(new Error('Failed to update load alert'))
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error updating Load alert', error.response)
				return Promise.reject(error.response || error.message || 'Unknown error')
			})
	}
}

export function GetLoadlertDisticOptions(fieldName, tableDatabase) {
	return function (dispatch, getState) {
		dispatch(isLoadingLoadAlerts(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/catalogs/${fieldName}/${tableDatabase}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					// dispatch(setLoadAlert(serverResponse.data))
					console.log('serverResponse.data', serverResponse.data)
					dispatch(isLoadingLoadAlerts(false))

					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error updating Load alert', error.response)
			})
	}
}

export function GetLoadlertItemsDisticOptions(fieldName, tableDatabase) {
	return function (dispatch, getState) {
		dispatch(isLoadingLoadAlerts(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/items/catalogs/${fieldName}/${tableDatabase}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					// dispatch(setLoadAlert(serverResponse.data))
					console.log('serverResponse.data', serverResponse.data)
					dispatch(isLoadingLoadAlerts(false))

					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error updating Load alert', error.response)
			})
	}
}

export function AddPreviousOrigin(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingLoadAlerts(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/addpreviousorigin/${token}`

		return axios
			.put(endPoint, {}, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isLoadingLoadAlerts(false))

					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error updating Load alert', error.response)
			})
	}
}

export function UpdateLoadAlertItem(data) {
	return function (dispatch, getState) {
		// dispatch(isLoadingLoadAlerts(true))
		const { token, ...dataWithoutToken } = data
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/updateitem/${token}`

		return axios
			.put(endPoint, dataWithoutToken, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					// dispatch(isLoadingLoadAlerts(false))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				// dispatch(isLoadingLoadAlerts(false))
				console.error('Error updating Load alert item', error.response)
			})
	}
}

export function addContainer(data) {
	return function (dispatch, getState) {
		dispatch(isLoadingLoadAlerts(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/addcontainer`

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isLoadingLoadAlerts(false))

					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error getting the Load alerts', error.response)
			})
	}
}

export function addMaterial(data) {
	return function (dispatch, getState) {
		dispatch(isLoadingLoadAlerts(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/materials`

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isLoadingLoadAlerts(false))

					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error getting the Load alerts', error.response)
			})
	}
}

export function deleteMaterial(data) {
	const { token, materialToken } = data
	return function (dispatch, getState) {
		dispatch(isLoadingLoadAlerts(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/${token}/materials/${materialToken}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isLoadingLoadAlerts(false))

					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error getting the Load alerts', error.response)
			})
	}
}

export function deleteContainer(data) {
	const { token, materialToken: containerToken } = data
	return function (dispatch, getState) {
		dispatch(isLoadingLoadAlerts(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/${token}/container/${containerToken}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isLoadingLoadAlerts(false))

					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingLoadAlerts(false))
				console.error('Error getting the Load alerts', error.response)
			})
	}
}

export function uploadFilesLoadAlert(responseToken, files, folder, listItems) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/${responseToken}/${folder}`
		let formData = new FormData()
		for (var index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false))
					dispatch(setLoadAlert(serverResponse.data))

					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))

				console.error('Error to upload sample files from supplier file(s)', error.response)
				return Promise.reject()
			})
	}
}

export function deleteFilesLoadAlert(responseToken, folder, name) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/${responseToken}/remove/${folder}/${name}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isSavingCommon(false))

					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))

				console.error('Error to upload sample files from supplier file(s)', error.response)
				return Promise.reject()
			})
	}
}

export function changeStatusLoadAlert(responseToken, comments, status) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		dispatch(isSavingCommon(true))
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/status`
		let supplierProfile = getState().oidc.user.profile
		let data = {
			Token: responseToken,
			Comments: comments,
			Status: status,
			CreatorUserName: supplierProfile.preferred_username,
			CreateBy: supplierProfile.name,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isSavingCommon(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error to upload sample files from supplier file(s)', error.response)
				return Promise.reject()
			})
	}
}

export function changeStatusLoadAlertItem(loadAlertToken, loadAlertItemToken, comments, status) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/loadAlertItem/setStatus`
		let supplierProfile = getState().oidc.user.profile
		let data = {
			AlertToken: loadAlertToken,
			AlertItemToken: loadAlertItemToken,
			Comments: comments,
			Status: status,
			CreatorUserName: supplierProfile.preferred_username,
			CreateBy: supplierProfile.name,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isSavingCommon(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))

				console.error('Error to update load alert status', error.response)
				return Promise.reject()
			})
	}
}

export function createTrackingGuide(loadAlertToken, loadAlertItemToken) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/add/trackingGuide`
		let data = {
			AlertToken: loadAlertToken,
			AlertItemToken: loadAlertItemToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setLoadAlert(serverResponse.data))
					dispatch(isSavingCommon(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error to create load alert', error.response)
				return Promise.reject()
			})
	}
}

export function sendNotificationToSupplier(alertToken, itemToken) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let data = {
			Token: alertToken,
			AlertItemToken: itemToken,
		}
		let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/loadalerts/notification`
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error getting the Load alerts', error.response)
				return Promise.reject(error.response)
			})
	}
}
