/** React imports section */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'

import CardTypeShipment from './CardTypeShipment'
import CreateTypeShipmentPanel from './CreateTypeShipmentPanel'
import AssignSupplierTransportTypePanel from './AssignSupplierTransportTypePanel'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'

import CatalogHeader from './CatalogHeader'

const TypeShipmentContent = (props) => {
	const {
		classes,
		typeShipment,
		information,
		onAddCarrier,
		optionsSupplie,
		supplier,
		onSave,
		onDelete,
		onDeleteCarrier,
	} = props
	const {
		openCollapese,
		setOpenCollapese,
		openAssignSupplier,
		setOpenOpenAssignSupplier,
		setInformation,
		setSupplier,
		openTypeShipmentPanel,
		setOpenTypeShipmentPanel,
		isLoading,
		isTransportTenderAdministrator,
	} = props
	return (
		<div>
			<CatalogHeader
				setOpenDialog={() => setOpenTypeShipmentPanel(true)}
				icon={<BubbleChartIcon />}
				isInternalUse={true}
				title={<Translate id={'carrierTenders.configuration.addCarrierType'} />}
				isAdministrator={isTransportTenderAdministrator}
			/>
			{typeShipment.map((x) => {
				return (
					<CardTypeShipment
						key={x.Id}
						typeShipment={x}
						classes={classes}
						openCollapese={openCollapese}
						setOpenCollapese={setOpenCollapese}
						setOpenOpenAssignSupplier={setOpenOpenAssignSupplier}
						isAdministrator={isTransportTenderAdministrator}
						onDelete={() => onDelete(x)}
						onDeleteCarrier={onDeleteCarrier}
					/>
				)
			})}
			{openTypeShipmentPanel && (
				<CreateTypeShipmentPanel
					setInformation={setInformation}
					information={information}
					onClose={() => setOpenTypeShipmentPanel(false)}
					onSave={onSave}
					isLoading={isLoading}
				/>
			)}
			{openAssignSupplier.open && (
				<AssignSupplierTransportTypePanel
					setInformation={setInformation}
					information={information}
					onClose={() => setOpenOpenAssignSupplier({ open: false, Id: null })}
					onSave={onAddCarrier}
					optionsSupplie={optionsSupplie}
					setSupplier={setSupplier}
					supplier={supplier}
					isLoading={isLoading}
				/>
			)}
		</div>
	)
}

const styles = (theme) => ({
	addIcon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		borderRadius: '16px',
		margin: '0px 5px 0px 10px',
	},
	tabColor: {
		color: theme.palette.primary.main,
	},
	noTabColor: {
		color: 'inherit',
	},
	titleTabs: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	containerTitle: {
		margin: '15px',
	},
	flex: {
		display: 'flex',
	},
	typeShipment: {
		backgroundColor: '#DBDBDB',
		margin: '10px 0px 5px',
	},
	containerSupplier: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: '0px 10px 0px 15px',
	},
	dividerMargin: {
		margin: '5px 0px 5px',
	},
	titleSupplier: {
		margin: '5px 10px 0px 10px',
	},
})
export default withRouter(withStyles(styles, { withTheme: true })(TypeShipmentContent))
