
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { CardHeader } from '@material-ui/core';

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LoadingPage from '../common/LoadingPage';
import FloatingButton from '../common/FloatingButton';
import DialogAvailableOperations from '../common/DialogAvailableOperations';
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow';
import Toaster from '../common/Toaster';
import InformationPartial from './components/InformationPartial';
import DocumentsList from './components/DocumentsList';
import HistoryCard from '../common/HistoryCard';
import AddCommonItem from '../common/AddCommonItem';
import WorkflowCancel from '../common/WorkflowCancel';

/** Import action section */
import { SHIPPINGORDER_FORWARDTHESUPPLIER, WORKFLOW_CANCELLATION } from '../../store/helpers/WorkFlowDocumentsType';
import { loadSupplierCode } from '../../store/suppliers/SupplierActions';
import {loadShippingOrderFromBackEnd, uploadFilesShippingOrder, removeFilesShippingOrder, acceptedPriceInItemDetailBySupplier,
    updateShippingResponse, updateShippingOrder, sendSendShippingOrderToClient,
    cancelShippingOrderOrder, forwardTheSupplierToCorrectDocuments} from '../../store/shippingOrders/ShippingOrderAction';

import { converListToArray } from '../../store/helpers/ListHelper'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';
import { showSuccessToaster, showErrorToaster } from '../../store/helpers/ToasterHelpers';

/**
 * Container show shippingOrderToken
 */
class Show extends Component {

    /**
     * Create an instance loadShippingOrderFromBackEnd
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            activeTab: 0,
            openAvailableOperationsDialog: false,
            openForwardDialog: false,
            openCancelDialog: false,
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let shippingOrderToken = this.props.match.params.token;
        this.props.loadShippingOrder(shippingOrderToken);
    }

    /**
     * componentDidUpdate
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof Index
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.shippingOrder !== null) {
            /// Get information full from shippingOrder
            let shippingOrder = this.props.shippingOrder;
            this.props.loadSupplierCode(shippingOrder.supplier_code);
        }
    }

    /**
 * Action to set state to open of available operations dialog
 * @memberof 
 */
    setOpenDialog = (openAvailable) => {
        this.setState({
            openAvailableOperationsDialog: openAvailable,
        });
    }

    /**
     * Method to validate if details list has full required information
     *
     * @returns
     * @memberof Show
     */
    hasValidToSendShippingOrderData() {
        let invalidItem =  this.props.shippingOrder.details.find(detail => {
            return IsNullOrEmpty(detail.status);
        })
        if (!invalidItem)
            return true
        return false
    }

    /**
     * Method to validate documents
     */
    hasValidDocumentsToSend(){
        let shipping = this.props.shippingOrder;
        if(shipping.invoices.length === 0 || shipping.packing.length === 0 || shipping.notification_boarding.length === 0){
            return false;
        }
        if(shipping.is_required_certificate_of_quality && shipping.certificate_quality.length === 0){
            return false;
        }
        if(shipping.is_required_serial_number && shipping.serial_numbers.length <= 0){
            return false;
        }
        else{
            return true;
        }
    }

    /**
   * onSendSendShippingOrderToClient
   *
   * @param {*} item
   * @param {*} supplier
   * @memberof Create
   */
    onSendSendShippingOrderToClient() {
    if(!this.hasValidToSendShippingOrderData()){
        this.setState(showErrorToaster("shippingOrder.validatePriceDetails"));
        return Promise.reject();
    }
    if(!this.hasValidDocumentsToSend()){
        this.setState(showErrorToaster("shippingOrder.validateDocuments"));
        return Promise.reject();
    }
    if(this.hasValidToSendShippingOrderData() && this.hasValidDocumentsToSend()){
        this.props.sendSendShippingOrderToClient(this.props.shippingOrder.token)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                })
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            }).catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            });
        }
    }

      /**
     * onCancelFolio
     */
    onCancelShippingOpen() {
        this.setState({
            openAvailableOperationsDialog: false,
            openCancelDialog: true,
        })
    }

    /**
     * Function to on cancel shipping order
     * @param {*} common 
     */
    onCancelShippingOrderOrder(common) {
        let shippingOrderToken = this.props.match.params.token;
        return this.props.cancelShippingOrderOrder(shippingOrderToken, common)
            .then(() => {
                this.setState({
                    openCancelDialog: false,
                })
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
     * onForwardShippingOpen
     */
    onForwardShippingOpen() {
        this.setState({
            openAvailableOperationsDialog: false,
            openForwardDialog: true,
        })
    }

    /**
     * Function to on forware supplier shipping order
     * @param {*} common 
     */
    onForwardTheSupplierToCorrectDocuments(common) {
        let shippingOrderToken = this.props.match.params.token;
        return this.props.forwardTheSupplierToCorrectDocuments(shippingOrderToken, common)
            .then(() => {
                this.setState({
                    openForwardDialog: false,
                })
                this.setState(showSuccessToaster());
                return Promise.resolve('Ok')
            })
            .catch((error) => {
                this.setState(showErrorToaster(error));
                return Promise.reject();
            })
    }

    /**
     * Function to show foraward supplier
     * @param {*} document 
     */
    renderDocumentForwardTheSupplier(document) {
        return (
            <Grid container spacing={24} >
                <CardHeader
                    style={{ paddingTop: '0px' }}
                    title={
                        <Typography gutterBottom variant="body2" component="p">
                            <strong>
                            <Translate id="shippingOrder.messageForwardTheSupplier"/>:
                            </strong>
                            {' ' + document.observations}
                        </Typography>
                    }
                />
            </Grid>
        )
    }

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {        
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.shippingOrder !== null) {
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <InformationPartial  {...this.props} />
                    </Grid>
                    <Grid item xs={12}>
                        <DocumentsList  {...this.props} />
                    </Grid>
                </Grid>
            )
        }
    }

    /**
     * Render hystory section for all documents
     *
     * @returns
     * @memberof Show
     */
    renderHistory() {
        let documents = this.props.shippingOrder ? this.props.shippingOrder.documents ? this.props.shippingOrder.documents : [] : []
        return (
            documents.map(document => {
                let titleId;
                let aditionalData;
                if (document.document_type === SHIPPINGORDER_FORWARDTHESUPPLIER) {
                    titleId = 'shippingOrder.invalidDocuments';
                    aditionalData = this.renderDocumentForwardTheSupplier(document);
                }
                if (document.document_type === WORKFLOW_CANCELLATION) {
                    titleId = 'common.folioCancel';
                    aditionalData = <WorkflowCancel document={document} />;
                }
                return (
                    <HistoryCard
                        key={document.token}
                        title={titleId}
                        userCreator={document.create_by}
                        creationDate={document.creation_date_long}
                        aditionalData={aditionalData}
                    />
                )

            })
        )
    }

    render() {
        let optonsAvalinable = this.props.shippingOrder ? this.props.shippingOrder.get_available_operations : [];
        let sentDocumentsDate =  (this.props.shippingOrder && !IsNullOrEmpty(this.props.shippingOrder.response.documents_send_to_date_time)) ? `${this.props.translate("shippingOrder.sentDocumentsDate")}: ${this.props.shippingOrder.response.documents_send_to_date_time}`: ''
        return (
            <Card>
                {optonsAvalinable.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

                <LayoutHeaderWorkFlow {...this.props}
                    workFlow={this.props.shippingOrder}
                    title={<Translate id="shippingOrder.shippingOrderTitle"/>}
                    additionalData={sentDocumentsDate}
                />

                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {this.renderHistory()}

                <DialogAvailableOperations
                    {...this.props}
                    options={optonsAvalinable}
                    openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
                    setOpenDialog={this.setOpenDialog}
                    onSendSendShippingOrderToClient={this.onSendSendShippingOrderToClient}
                    onCancelDocument ={this.onCancelShippingOpen}
                    onForwardShippingOpen={this.onForwardShippingOpen}
                />

                {/** Dialog to forward supplier shipping */}
                < AddCommonItem
                    title={<Translate id="statusDescriptions.shippingOrderForwardTheSupplierToCorrectDocuments"/>}
                    message={<Translate id="shippingOrder.messageForwardTheSupplier"/>}
                    open={this.state.openForwardDialog}
                    onClose={() => { this.setState({ openForwardDialog: false }) }}
                    onSaveItem={this.onForwardTheSupplierToCorrectDocuments}
                    isSaving={this.props.isSaving}
                    isTextArea={true}
                />
                
                {/** Dialog to cancel shipping */}
                < AddCommonItem
                    title={<Translate id="shippingOrder.cancelRequest"/>}
                    message={<Translate id="shippingOrder.messageToCancel"/>}
                    open={this.state.openCancelDialog}
                    onClose={() => { this.setState({ openCancelDialog: false }) }}
                    onSaveItem={this.onCancelShippingOrderOrder}
                    isSaving={this.props.isSaving}
                    isTextArea={true}
                />

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        )
    }

}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let shippingOrder = state.shippingOrders.get("shippingOrder");
    let selectedCompany = state.profile.get("selectedCompany");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        currentUser: user,
        isLoading: state.shippingOrders.get('isLoadingShipping'),
        shippingOrder: shippingOrder ? shippingOrder.toJS() : null,
        isSaving: state.shippingOrders.get('isSaving'),
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        company: selectedCompany ? selectedCompany.toJS() : null,
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadShippingOrder: (token) => {
            return dispatch(loadShippingOrderFromBackEnd(token));
        },
        loadSupplierCode: (token) => {
            return (dispatch(loadSupplierCode(token)))
        },
        acceptedPriceInItemDetailBySupplier: (itemToken, status, shippingOrderToken) => {
            return dispatch(acceptedPriceInItemDetailBySupplier(itemToken, status, shippingOrderToken));
        },
        uploadFilesShippingOrder: (responseToken, files, folder, listItems) => {
            return dispatch(uploadFilesShippingOrder(responseToken, files, folder, listItems));
        },
        removeFilesShippingOrder: (responseToken, fileName, folder, listItems) => {
            return dispatch(removeFilesShippingOrder(responseToken, fileName, folder, listItems));
        },
        updateShippingResponse: (responseToken, observtions) => {
            return dispatch(updateShippingResponse(responseToken, observtions));
        },
        updateShippingOrderEta: (token, dateTimeEta) => {
            return dispatch(updateShippingOrder(token, dateTimeEta));
        },
        sendSendShippingOrderToClient:(token) =>{
            return dispatch(sendSendShippingOrderToClient(token));
        },
        cancelShippingOrderOrder:(token, reasonCancellation) => {
            return dispatch(cancelShippingOrderOrder(token, reasonCancellation));
        },
        forwardTheSupplierToCorrectDocuments:(token, observations) =>{
            return dispatch(forwardTheSupplierToCorrectDocuments(token, observations));
        }
    }
}

const styles = theme => ({
    actionsToolbar: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    margin: {
        margin: theme.spacing.unit * 2,
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));