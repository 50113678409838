import axios from 'axios'
import moment from 'moment'
import uuidv4 from 'uuid/v4'

/** Helpers import section */
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import { IsNullOrEmpty } from '../helpers/StringHelper'
import { INVOICING_ERROR, PAYMENT_REQUESTS_CREATION } from '../helpers/AccreditedWorkshopsHelper'
import { toPascalCase } from '../helpers/ObjectHelper'

// Settings
export const SET_BUSINESS_CONFIGURATION = 'SET_BUSINESS_CONFIGURATION'
export const SET_PAYMENT_REQUEST_SETTINGS = 'SET_PAYMENT_REQUEST_SETTINGS'
export const SET_PAYMENT_REQUEST_CREATION_STATUS = 'SET_PAYMENT_REQUEST_CREATION_STATUS'
export const SET_SETTING_BY_CODE_VALUE = 'SET_SETTING_BY_CODE_VALUE'
export const ADD_WORKSHOP_ON_RETENTION = 'ADD_WORKSHOP_ON_RETENTION'
export const REMOVE_WORKSHOP_FROM_RETENTION = 'REMOVE_WORKSHOP_FROM_RETENTION'
export const ADD_SERVICE_TYPE = 'ADD_SERVICE_TYPE'
export const REMOVE_SERVICE_TYPE = 'REMOVE_SERVICE_TYPE'
export const SET_WORKSHOP_CODES = 'SET_WORKSHOP_CODES'
export const ADD_WORKSHOP_IVA = 'ADD_WORKSHOP_IVA'
export const ADD_WORKSHOP_RESICO = 'ADD_WORKSHOP_RESICO'
export const ADD_WORKSHOP = 'ADD_WORKSHOP'
export const REMOVE_WORKSHOP_IVA = 'REMOVE_WORKSHOP_IVA'
export const REMOVE_WORKSHOP_RESICO = 'REMOVE_WORKSHOP_RESICO'

// Service orders
export const CHANGE_ACCREDITED_WORKSHOPS_SORT_CRITERIA = 'CHANGE_ACCREDITED_WORKSHOPS_SORT_CRITERIA'
export const CHANGE_ACCREDITED_WORKSHOPS_QUERY = 'CHANGE_ACCREDITED_WORKSHOPS_QUERY'
export const CHANGE_ACCREDITED_WORKSHOPS_PAGINATION = 'CHANGE_ACCREDITED_WORKSHOPS_PAGINATION'
export const IS_LOADING_SERVICES_INDEX = 'IS_LOADING_SERVICES_INDEX'
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS'
export const IS_LOADING_FILTER_OPTIONS = 'IS_LOADING_FILTER_OPTIONS'
export const SET_SERVICE_ORDERS_LOCK_REASONS = 'SET_SERVICE_ORDERS_LOCK_REASONS'
export const SET_RELEASE_DATE_IN_SERVICE_ORDERS = 'SET_RELEASE_DATE_IN_SERVICE_ORDERS'

export const SET_ACCREDITED_WORKSHOP_STATUS = 'SET_ACCREDITED_WORKSHOP_STATUS'
export const SET_ACCREDITED_WORKSHOPS_SERVICES_LIST = 'SET_ACCREDITED_WORKSHOPS_SERVICES_LIST'
export const SET_ACCREDITED_WORKSHOPS_SERVICES_COUNT = 'SET_ACCREDITED_WORKSHOPS_SERVICES_COUNT'
export const SET_ACCREDITED_WORKSHOPS_SERVICES_AMOUNT = 'SET_ACCREDITED_WORKSHOPS_SERVICES_AMOUNT'

// Single service
export const IS_LOADING_SERVICE = 'IS_LOADING_SERVICE'
export const IS_SAVING_SERVICE = 'IS_SAVING_SERVICE'
export const SET_ACCREDITED_WORKSHOPS_SERVICE = 'SET_ACCREDITED_WORKSHOPS_SERVICE'

export const SET_APPROVED_SERVICE_ITEM_SELECTED = 'SET_APPROVED_SERVICE_ITEM_SELECTED'

// Payment requests
export const IS_LOADING_PAYMENT_REQUESTS = 'IS_LOADING_PAYMENT_REQUESTS'
export const SET_PAYMENT_REQUESTS_LIST = 'SET_PAYMENT_REQUESTS_LIST'
export const SET_ATTEMP_ERRORS = 'SET_ATTEMP_ERRORS'

// Single payment request
export const IS_LOADING_PAYMENT_REQUEST_DETAIL = 'IS_LOADING_PAYMENT_REQUEST_DETAIL'
export const SET_PAYMENT_REQUEST_DETAIL = 'SET_PAYMENT_REQUEST_DETAIL'
export const SET_PAYMENT_REQUEST_AS_SENDING = 'SET_PAYMENT_REQUEST_AS_SENDING'
export const SET_INVOICE = 'SET_INVOICE'
export const SET_ALL_PAYMENT_REQUESTS_ERRORS = 'SET_ALL_PAYMENT_REQUESTS_ERRORS'
export const SET_PAYMENT_REQUESTS_ERRORS = 'SET_PAYMENT_REQUESTS_ERRORS'

// Technicians
export const IS_LOADING_TECHNICIANS = 'IS_LOADING_TECHNICIANS'
export const SET_TECHNICIAN_LIST = 'SET_TECHNICIAN_LIST'
export const ADD_TECHNICIAN_ON_LIST = 'ADD_TECHNICIAN_ON_LIST'
export const UPDATE_TECHNICIAN_ON_LIST = 'UPDATE_TECHNICIAN_ON_LIST'

// Single technician
export const IS_SAVING_TECHNICIAN_DETAIL = 'IS_SAVING_TECHNICIAN_DETAIL'
export const IS_LOADING_TECHNICIAN_DETAIL = 'IS_LOADING_TECHNICIAN_DETAIL'
export const SET_TECHNICIAN_DETAIL = 'SET_TECHNICIAN_DETAIL'

export const SET_ACCREDITED_WORKSHOPS = 'SET_ACCREDITED_WORKSHOPS'
export const SET_TECHNICIANS_IN_ACCREDITED_WORKSHOPS = 'SET_TECHNICIANS_IN_ACCREDITED_WORKSHOPS'
export const ADD_STATUS_ITEM_TO_SECTION = 'ADD_STATUS_ITEM_TO_SECTION'
export const REMOVE_STATUS_ITEM_FROM_SECTION = 'REMOVE_STATUS_ITEM_FROM_SECTION'

const pendingRequests = {}

/**********************************************************************
 ********* Actions for service orders *********************************
 **********************************************************************/

function isLoadingServices(isLoadingServices) {
	return {
		type: IS_LOADING_SERVICES_INDEX,
		isLoadingServices,
	}
}

export function setServicesList(services) {
	return {
		type: SET_ACCREDITED_WORKSHOPS_SERVICES_LIST,
		services,
	}
}

function setServicesCount(count) {
	return {
		type: SET_ACCREDITED_WORKSHOPS_SERVICES_COUNT,
		count,
	}
}

function setServicesAmount(amount) {
	return {
		type: SET_ACCREDITED_WORKSHOPS_SERVICES_AMOUNT,
		amount,
	}
}

export function setServicesPagination(page, rowsPerPage) {
	return {
		type: CHANGE_ACCREDITED_WORKSHOPS_PAGINATION,
		page,
		rowsPerPage,
	}
}

export function setSortCriteria(sortBy, sortAscending) {
	return {
		type: CHANGE_ACCREDITED_WORKSHOPS_SORT_CRITERIA,
		sortCriteria: {
			by: sortBy,
			ascending: sortAscending,
		},
	}
}

export function loadAccreditedWorkshopsServicesFromBackEnd(
	filters,
	page,
	rowsPerPage,
	orderBy,
	orderType,
	releaseDateRequired = false
) {
	return function (dispatch, getState) {
		cancelHttpRequest('serviceOrdersRequest')
		pendingRequests.serviceOrdersRequest = axios.CancelToken.source()
		dispatch(isLoadingServices(true))
		dispatch(setServicesList([]))
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		configuration.cancelToken = pendingRequests.serviceOrdersRequest.token
		const data = {
			PageNumber: page + 1,
			PageSize: rowsPerPage,
			SortingCriteria: {
				OrderBy: orderBy,
				OrderType: orderType,
			},
			Filters: filters,
		}
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}services/search/certifiedworkshop`
		return axios
			.post(endPoint, data, configuration)
			.then(({ status, data }) => {
				pendingRequests.serviceOrdersRequest = null
				if (status === 200) {
					const orders = []
					data.forEach((order) => {
						const indexOfOrder = orders.findIndex((o) => o.token === order.token)
						if (indexOfOrder === -1) {
							orders.push(order)
						}
					})
					dispatch(setServicesList(orders.map((item) => mapServiceOrder(item))))
					if (orders.length > 0) {
						const tokens = orders.map((so) => so.token)
						dispatch(loadServiceOrderReleaseDatesFromBackEnd(tokens))
					}
				}
			})
			.catch(({ response }) => {
				pendingRequests.serviceOrdersRequest = null
				dispatch(isLoadingServices(false))
				console.error('Error getting the accredited workshops services by company list', response)
			})
	}
}

export function loadAccreditedWorkshopsServicesInPs9FromBackEnd(workshopCodes, serviceTypes) {
	return function (dispatch, getState) {
		cancelHttpRequest('serviceOrdersOnPs9Request')
		pendingRequests.serviceOrdersOnPs9Request = axios.CancelToken.source()
		dispatch(isLoadingServices(true))
		dispatch(setServicesList([]))
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		configuration.cancelToken = pendingRequests.serviceOrdersOnPs9Request.token
		const data = { WorkshopCodes: workshopCodes, ServiceTypeIds: serviceTypes }
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}services/search/ps9`
		return axios
			.post(endPoint, data, configuration)
			.then(({ status, data }) => {
				pendingRequests.serviceOrdersOnPs9Request = null
				if (status === 200) {
					dispatch(setServicesList(data.map((item) => mapServiceOrder(item))))
					if (data.length > 0) {
						const tokens = data.map((so) => so.token)
						dispatch(loadServiceOrderReleaseDatesFromBackEnd(tokens))
					}
				}
			})
			.catch(({ response }) => {
				pendingRequests.serviceOrdersOnPs9Request = null
				dispatch(isLoadingServices(false))
				console.error('Error getting the accredited workshops services from ps9', response)
			})
	}
}

export function loadAccreditedWorkshopsServicesTokens(filters) {
	return function (dispatch, getState) {
		cancelHttpRequest('serviceOrdersTokensRequest')
		const cancelToken = axios.CancelToken
		pendingRequests.serviceOrdersTokensRequest = cancelToken.source()
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		configuration.cancelToken = pendingRequests.serviceOrdersTokensRequest.token
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}services/tokens/certifiedworkshop`
		const data = {
			Filters: filters,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				pendingRequests.serviceOrdersTokensRequest = null
				if (serverResponse.status === 200) {
					return serverResponse.data
				}
			})
			.catch((error) => {
				pendingRequests.serviceOrdersTokensRequest = null
				console.error('Error getting the accredited workshops services tokens', error.response)
				return Promise.reject('500')
			})
	}
}

export function loadAccreditedWorkshopsServicesTotalFromBackEnd(filters) {
	return function (dispatch, getState) {
		cancelHttpRequest('serviceOrdersTotalRequest')
		const cancelToken = axios.CancelToken
		pendingRequests.serviceOrdersTotalRequest = cancelToken.source()
		let actualCounter = getState().accreditedWorkshops
			? getState().accreditedWorkshops.get('accreditedWorkshopsServicesCount')
			: null
		if (actualCounter) {
			return
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		configuration.cancelToken = pendingRequests.serviceOrdersTotalRequest.token
		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}services/total/certifiedworkshop`
		let data = {
			PageNumber: 1,
			PageSize: 50,
			Filters: filters,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				pendingRequests.serviceOrdersTotalRequest = null
				if (serverResponse.status === 200) {
					dispatch(setServicesCount(serverResponse.data))
				}
			})
			.catch((error) => {
				pendingRequests.serviceOrdersTotalRequest = null
				console.error('Error getting the accredited workshops services count', error.response)
			})
	}
}

export function loadAccreditedWorkshopsServicesTotalAmountFromBackEnd(filters) {
	return function (dispatch, getState) {
		cancelHttpRequest('serviceOrdersTotalAmountRequest')
		const cancelToken = axios.CancelToken
		pendingRequests.serviceOrdersTotalAmountRequest = cancelToken.source()
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		configuration.cancelToken = pendingRequests.serviceOrdersTotalAmountRequest.token
		const command = {
			Filters: filters,
		}
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}services/totalamount/certifiedworkshop`
		return axios
			.post(endPoint, command, configuration)
			.then(({ status, data: { amount } }) => {
				pendingRequests.serviceOrdersTotalAmountRequest = null
				if (status === 200) {
					dispatch(setServicesAmount(amount))
				}
			})
			.catch((error) => {
				pendingRequests.serviceOrdersTotalAmountRequest = null
				console.error('Error getting the accredited workshops services count', error.response)
			})
	}
}

function setServiceOrdersLockReasons(data) {
	return {
		type: SET_SERVICE_ORDERS_LOCK_REASONS,
		data,
	}
}

export function loadServiceLockReasonsFromBackEnd(tokens) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}services/lockreasons/certifiedworkshop`
		let data = {
			ServiceOrderTokens: tokens,
		}
		return axios
			.post(endPoint, data, configuration)
			.then(({ status, data }) => {
				if (status === 200) {
					dispatch(setServiceOrdersLockReasons(data))
				}
			})
			.catch((error) => {
				console.error('Error getting the accredited workshops services count', error.response)
			})
	}
}

function setServiceOrderReleaseDates(data) {
	return {
		type: SET_RELEASE_DATE_IN_SERVICE_ORDERS,
		data,
	}
}

export function loadServiceOrderReleaseDatesFromBackEnd(tokens) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}services/events`
		let data = {
			EventType: 1,
			ServiceOrderTokens: tokens,
		}
		return axios
			.post(endPoint, data, configuration)
			.then(({ status, data }) => {
				if (status === 200) {
					dispatch(setServiceOrderReleaseDates(data))
				}
			})
			.catch((error) => {
				console.error('Error getting the accredited workshops services count', error.response)
			})
	}
}

function setLoadingServiceFlag(isLoadingService) {
	return {
		type: IS_LOADING_SERVICE,
		isLoadingService,
	}
}

export function setServiceOrder(serviceOrder) {
	return {
		type: SET_ACCREDITED_WORKSHOPS_SERVICE,
		service: serviceOrder,
	}
}

export function loadServiceOrderDetail(token) {
	return function (dispatch, getState) {
		dispatch(setLoadingServiceFlag(true))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/services/${token}/detail`
		return axios
			.get(endPoint, configuration)
			.then(({ data }) => {
				dispatch(setServiceOrder(data))
			})
			.catch(({ response }) => {
				console.error('Error loading service order detail.', response)
				dispatch(setLoadingServiceFlag(false))
			})
	}
}

export function downloadServiceOrdersReport(filters) {
	return function () {
		const url = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/services/certifiedworkshop/report`
		let command = {
			Filters: filters,
		}
		return axios
			.post(url, command, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'ReporteOrdenesServicio.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('positionsActions: error to download level <=> Error: ', error)
				return Promise.reject()
			})
	}
}

export function downloadInvoicedServiceOrdersReport(filters) {
	return function () {
		const url = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/services/certifiedworkshop/invoicedreport`
		let command = {
			Filters: filters,
		}
		return axios
			.post(url, command, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					const blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = 'ReporteOrdenesServicioFacturadas.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('downloadInvoicedServiceOrdersReport::UnexpectedError: ', error)
				return Promise.reject()
			})
	}
}

export function downloadServiceOrdersInPs9Report(workshopCodes, serviceTypeIds) {
	return function () {
		const url = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/services/certifiedworkshop/ps9report`
		const command = {
			WorkshopCodes: workshopCodes,
			ServiceTypeIds: serviceTypeIds,
		}
		return axios
			.post(url, command, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'ReporteOrdenesServicioEnPS9.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('positionsActions: error to download level <=> Error: ', error)
				return Promise.reject()
			})
	}
}

const setLoadingFilterOptionsFlag = (status) => {
	return {
		type: IS_LOADING_FILTER_OPTIONS,
		status,
	}
}

export const setFilterOptions = (filterType, options) => ({
	type: SET_FILTER_OPTIONS,
	filterType,
	options,
})

export const loadFilterOptions = (url, entityName, filterKey, filters) => {
	return function (dispatch, getState) {
		dispatch(setLoadingFilterOptionsFlag(true))
		const loggedUser = getState().oidc.user
		const config = getAuthorizedRequestConfigurationUser(loggedUser)
		const operands = filters.map((filter) => ({
			FieldName: filter.PropertyIdentificator,
			RelationalOperatorType: 'Equal',
			Values: filter.RuleValue,
		}))
		const command = {
			EntityName: entityName,
			FieldName: filterKey,
			Criteria: {
				LogicalOperatorType: 'And',
				Operands: operands,
			},
		}

		return axios
			.post(url, command, config)
			.then(({ data }) => {
				const options = data.map((item) => ({
					...item,
					code: item.key,
					label: IsNullOrEmpty(item.label) ? '(VACIO)' : item.label,
				}))
				dispatch(setFilterOptions(filterKey, options))
				return options
			})
			.catch((error) => {
				console.error(`Error loading filter options for '${filterKey}' filter type`, error)
				dispatch(setLoadingFilterOptionsFlag(false))
			})
	}
}

export const lockServiceOrdersForPaymentRequest = (serviceOrderCodes, comments) => {
	return function (dispatch, getState) {
		//dispatch(setLoadingFilterOptionsFlag(true));
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let command = {
			ServiceOrderCodes: serviceOrderCodes,
			Comments: comments,
		}

		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/services/certifiedworkshop/lock`
		return axios
			.put(endPoint, command, config)
			.then((response) => {
				//console.log(`🚀 ~ file: accreditedWorkshopsActions.js ~ line 323 ~ .then ~ response`, response);
				//dispatch(setFilterOptions(filterType, options));
			})
			.catch((error) => {
				console.error(`Error locking service orders`, error)
				//dispatch(setLoadingFilterOptionsFlag(false));
			})
	}
}

export const unlockServiceOrdersForPaymentRequest = (serviceOrderCodes) => {
	return function (dispatch, getState) {
		//dispatch(setLoadingFilterOptionsFlag(true));
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let command = {
			ServiceOrderCodes: serviceOrderCodes,
		}

		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/services/certifiedworkshop/unlock`
		return axios
			.put(endPoint, command, config)
			.then((response) => {
				//console.log(`🚀 ~ file: accreditedWorkshopsActions.js ~ line 323 ~ .then ~ response`, response);
				//dispatch(setFilterOptions(filterType, options));
			})
			.catch((error) => {
				console.error(`Error locking service orders`, error)
				//dispatch(setLoadingFilterOptionsFlag(false));
			})
	}
}

function mapServiceOrder(serviceOrder) {
	const mappedServiceOrder = { ...serviceOrder }
	mappedServiceOrder.creationDate = moment(serviceOrder.creationDate)
	mappedServiceOrder.attentionDate = moment(serviceOrder.attentionDate)
	mappedServiceOrder.closeDate =
		serviceOrder.closeDate && serviceOrder.closeDate !== 'null' ? moment(serviceOrder.closeDate) : null
	mappedServiceOrder.lastMovementDate = moment(serviceOrder.lastMovementDate)
	mappedServiceOrder.releaseDate = null
	mappedServiceOrder.lockReason = '-'
	return mappedServiceOrder
}

/**********************************************************************
 ********* Actions for payment requests *******************************
 **********************************************************************/

export function validateXML(xmlInvoice) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/accreditedWorkshops/validateXML`
		let formData = new FormData()
		formData.append('file', xmlInvoice)
		// xmlInvoice.forEach((file) => {
		// 	formData.append('file', file);
		// });
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				console.log(
					`🚀 ~ file: accreditedWorkshopsActions.js ~ line 505 ~ .then ~ serverResponse`,
					serverResponse
				)
				console.log(
					`🚀 ~ file: accreditedWorkshopsActions.js ~ line 506 ~ .then ~ serverResponse`,
					process.env.REACT_APP_SIP_SUPPLIERS
				)
				console.log(
					`🚀 ~ file: accreditedWorkshopsActions.js ~ line 507 ~ .then ~ serverResponse`,
					process.env.REACT_APP_SIP_SUPPLIERS.contains('crm.repare.com.mx')
				)
				if (!process.env.REACT_APP_SIP_SUPPLIERS.includes('crm.repare.com.mx')) return true
				if (serverResponse === undefined) return false

				return true
			})
			.catch((error) => {
				const baseUrl = `${process.env.REACT_APP_SIP_SUPPLIERS}`
				console.log(`🚀 ~ file: accreditedWorkshopsActions.js ~ line 516 ~ .catch ~ serverResponse`, baseUrl)
				console.log(
					`🚀 ~ file: accreditedWorkshopsActions.js ~ line 517 ~ .catch ~ serverResponse`,
					baseUrl.includes('crm.repare.com.mx')
				)
				if (!process.env.REACT_APP_SIP_SUPPLIERS.includes('crm.repare.com.mx')) return true
				console.error('Error validating XML.', error.response)
			})
	}
}

function setLoadingPaymenRequestFlag(flagValue) {
	return {
		type: IS_LOADING_PAYMENT_REQUEST_DETAIL,
		flagValue,
	}
}

export function setPaymentRequest(paymentRequest) {
	return {
		type: SET_PAYMENT_REQUEST_DETAIL,
		paymentRequest,
	}
}

export function setPaymentRequestAsSending(paymentRequestToken, status) {
	return {
		type: SET_PAYMENT_REQUEST_AS_SENDING,
		paymentRequestToken,
		status,
	}
}

export function setInvoice(invoice, creditNote) {
	return {
		type: SET_INVOICE,
		invoice,
		creditNote,
	}
}

export function setPaymentRequestErrors(paymentRequestErrors, serviceOrderErrors) {
	return {
		type: SET_PAYMENT_REQUESTS_ERRORS,
		paymentRequestErrors,
		serviceOrderErrors,
	}
}

function getInvoiceSettingsForWorkshop(state, workshopCode) {
	const configuration = state.accreditedWorkshops.getIn(['configuration', 'configuration'])
	const workshopIva = configuration.get('WorkshopsWithIva').find((w) => w.get('WorkshopCode') === workshopCode)
	const ivaPercentage = workshopIva ? workshopIva.get('Percentage') : 0

	const retentionsForWorkshop = configuration
		.get('WorkshopRetentions')
		.filter((wr) => wr.get('Code') === workshopCode)
	const retentions = []
	retentionsForWorkshop.forEach((wr) => {
		const retentionType = wr.get('RetentionType')
		retentions.push({
			Code: retentionType,
			Percent: configuration.get(retentionType),
		})
	})

	const workshopResico = configuration.get('WorkshopsWithResico').find((w) => w.get('WorkshopCode') === workshopCode)
	if (workshopResico) {
		retentions.push({
			Code: 'RESICO',
			Percent: workshopResico.get('Percentage'),
		})
	}

	return {
		RFC: configuration.get('RFC'),
		AllowedInvoicingVariation: configuration.get('InvoiceAmountVariationAllowed'),
		IvaPercentage: ivaPercentage,
		Retentions: retentions,
		MaxNumberOfServicesInPaymentRequest: configuration.get('MaxNumberOfServicesInPaymentRequest'),
	}
}

export function validatePaymentRequest(xmlFiles, chainCodes, selectedServices, workshopCode) {
	return function (dispatch, getState) {
		const configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}paymentrequests/validation`
		let formData = new FormData()
		formData.append('invoice', xmlFiles.invoice)
		formData.append('creditNote', xmlFiles.creditNote)

		const invoiceSettings = getInvoiceSettingsForWorkshop(getState(), workshopCode)
		const command = {
			...invoiceSettings,
			WorkshopCode: workshopCode,
			ServiceOrderCodes: selectedServices,
			ChainCodes: chainCodes,
		}

		formData.append('command', JSON.stringify(command))

		return axios
			.post(endPoint, formData, configuration)
			.then(({ data }) => {
				dispatch(setPaymentRequest(mapPaymentRequest(data.paymentRequest)))
				dispatch(setInvoice(data.invoice, data.creditNote))
				dispatch(setPaymentRequestErrors(data.paymentRequestErrors, data.serviceOrderErrors))
				if (data.success) {
					return Promise.resolve(data.success)
				} else {
					return Promise.resolve(data.success)
				}
			})
			.catch((error) => {
				console.error('Error creating payment request.', error.response)
			})
	}
}

export function makePaymentRequest(
	invoiceFiles,
	creditNoteFiles,
	chainCodes,
	selectedServices,
	workshopCode,
	partnershipCode
) {
	return function (dispatch, getState) {
		const configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}paymentrequests`
		const formData = new FormData()
		formData.append(
			'invoiceXml',
			invoiceFiles.find((file) => file.type === 'text/xml')
		)
		formData.append(
			'invoicePdf',
			invoiceFiles.find((file) => file.type === 'application/pdf')
		)
		formData.append(
			'creditNoteXml',
			creditNoteFiles.find((file) => file.type === 'text/xml')
		)
		formData.append(
			'creditNotePdf',
			creditNoteFiles.find((file) => file.type === 'application/pdf')
		)

		const invoiceSettings = getInvoiceSettingsForWorkshop(getState(), workshopCode)
		const command = {
			...invoiceSettings,
			WorkshopCode: workshopCode,
			ServiceOrderCodes: selectedServices,
			BusinessUnitSapCode: partnershipCode,
			ChainCodes: chainCodes,
		}

		formData.append('command', JSON.stringify(command))

		//Puede necesitarse en ciclo
		// formData.append("ServiceOrderTokens", selectedServices);

		return axios
			.post(endPoint, formData, configuration)
			.then(({ data }) => {
				dispatch(setPaymentRequest(mapPaymentRequest(data.paymentRequest)))
				dispatch(setInvoice(data.invoice, data.creditNote))
				if (data.success) {
					return Promise.resolve(data.paymentRequest)
				} else {
					dispatch(setPaymentRequestErrors(data.paymentRequestErrors, data.serviceOrderErrors))
				}
			})
			.catch((error) => {
				console.error('Error creating payment request.', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function sendPaymentRequestToSap(paymentRequestToken, partnershipCode) {
	return function (dispatch, getState) {
		dispatch(setPaymentRequestAsSending(paymentRequestToken, true))
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}paymentrequests/sap`

		const command = {
			PaymentRequestToken: paymentRequestToken,
			BusinessUnitSapCode: partnershipCode,
		}

		return axios
			.post(endPoint, command, configuration)
			.then(({ data }) => {
				dispatch(setPaymentRequest(mapPaymentRequest(data.paymentRequest)))
				dispatch(setInvoice(data.invoice))
				if (data.success) {
					return Promise.resolve(data.paymentRequest)
				} else {
					dispatch(setPaymentRequestErrors(data.paymentRequestErrors, data.serviceOrderErrors))
				}
			})
			.catch((error) => {
				console.error('Error creating payment request.', error.response)
			})
			.finally(() => dispatch(setPaymentRequestAsSending(paymentRequestToken, false)))
	}
}

function isLoadingPaymentRequests(isLoadingPaymentRequests) {
	return {
		type: IS_LOADING_PAYMENT_REQUESTS,
		isLoadingPaymentRequests,
	}
}

function setPaymentRequestsList(requests, total) {
	return {
		type: SET_PAYMENT_REQUESTS_LIST,
		requests,
		total,
	}
}

export function setAllPaymentRequestErrors(paymentRequestErrors) {
	return {
		type: SET_ALL_PAYMENT_REQUESTS_ERRORS,
		paymentRequestErrors,
	}
}

export function loadPaymentRequestsFromBackEnd(filters, page, rowsPerPage, partnershipCode, workshopCode) {
	return function (dispatch, getState) {
		dispatch(isLoadingPaymentRequests(true))
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const searchUrl = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}paymentrequests/search`
		const searchCommand = {
			BusinessUnitSapCode: partnershipCode,
			PageNumber: page + 1,
			PageSize: rowsPerPage,
			Filters: filters,
			WorshopCode: workshopCode,
		}
		return axios
			.post(searchUrl, searchCommand, configuration)
			.then(({ status, data }) => {
				if (status === 200) {
					const { requests, total } = data
					dispatch(
						setPaymentRequestsList(
							requests.map((item) => mapPaymentRequest(item)),
							total
						)
					)
					const errorsUrl = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}paymentrequests/lasterrors`
					const errorsCommand = {
						Tokens: requests.map((r) => r.token),
					}
					return axios.post(errorsUrl, errorsCommand, configuration)
				}
			})
			.then(({ status, data }) => {
				if (status === 200) {
					dispatch(setAllPaymentRequestErrors(data))
				}
			})
			.catch(({ response }) => {
				dispatch(isLoadingPaymentRequests(false))
				console.error(`Error getting the payment requests`, response)
			})
	}
}

export function loadPaymentRequestDetail(token) {
	return function (dispatch, getState) {
		dispatch(setLoadingPaymenRequestFlag(true))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/paymentrequests/${token}/detail`
		return axios
			.get(endPoint, configuration)
			.then(({ data }) => {
				dispatch(setPaymentRequest(mapPaymentRequest(data)))
			})
			.catch(({ response }) => {
				console.error('Error loading payment request detail.', response)
				dispatch(setLoadingPaymenRequestFlag(false))
			})
	}
}

export function loadServiceOrdersInPaymentRequest(token, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingServices(true))
		dispatch(setServicesList([]))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/paymentrequests/${token}/serviceorders`
		let data = {
			Token: token,
			PageNumber: page + 1,
			PageSize: rowsPerPage,
			Filters: [],
		}
		return axios
			.post(endPoint, data, configuration)
			.then(({ status, data }) => {
				if (status === 200) {
					const orders = []
					data.forEach((order) => {
						const indexOfOrder = orders.findIndex((o) => o.code === order.code)
						if (indexOfOrder === -1) orders.push(order)
					})
					dispatch(setServicesList(orders.map((item) => mapServiceOrder(item))))
				}
			})
			.catch(({ response }) => {
				dispatch(isLoadingServices(false))
				console.error(`Error getting the service orders in request with token [${token}]`, response)
			})
	}
}

const setAttemErrors = (attempDateStr, errors) => ({
	type: SET_ATTEMP_ERRORS,
	attempDateStr,
	errors,
})

export const loadAttempErrors = (requisitionToken, dateStr) => {
	return function (dispatch, getState) {
		const user = getState().oidc.user
		const config = getAuthorizedRequestConfigurationUser(user)
		const command = {
			PaymentToken: requisitionToken,
			DateStr: dateStr,
		}
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/paymentrequests/errors`

		return axios
			.post(endPoint, command, config)
			.then(({ data }) => {
				dispatch(setAttemErrors(dateStr, data))
			})
			.catch((error) => {
				console.error('Error loading the payment reqeust errors', error)
				return Promise.reject()
			})
	}
}

export function downloandPaymentRequestsReport(filters, partnershipCode, workshopCodes) {
	return function () {
		const url = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/paymentrequests/report`
		let command = {
			BusinessUnitSapCode: partnershipCode,
			Filters: filters,
			WorkshopCodes: workshopCodes,
		}
		return axios
			.post(url, command, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'ReporteDeSolicitudesDePago.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('AccreditedWrokshopActions: error to download level <=> Error: ', error)
				return Promise.reject()
			})
	}
}

export function downloandXmlInvoiceFile(paymentRequestToken) {
	return function () {
		const url = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/paymentrequests/${paymentRequestToken}/xmlinvoicefile`
		return axios
			.get(url, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'text/xml',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Factura_${paymentRequestToken}.xml`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('AccreditedWrokshopActions: error to download xml invoice file <=> Error: ', error)
				return Promise.reject()
			})
	}
}

export function downloandXmlCreditNoteFile(paymentRequestToken) {
	return function () {
		const url = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/paymentrequests/${paymentRequestToken}/xmlcreditnotefile`
		return axios
			.get(url, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'text/xml',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Factura_${paymentRequestToken}.xml`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('AccreditedWrokshopActions: error to download xml invoice file <=> Error: ', error)
				return Promise.reject()
			})
	}
}

export function downloandZipInvoiceFile(paymentRequestToken) {
	return function () {
		const url = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/paymentrequests/${paymentRequestToken}/zipinvoicefiles`
		return axios
			.get(url, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/octet-stream',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Factura_${paymentRequestToken}.zip`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('AccreditedWrokshopActions: Error to download zip invoice files. Error: ', error)
				return Promise.reject()
			})
	}
}

export function downloandZipCreditNoteFile(paymentRequestToken) {
	return function () {
		const url = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/paymentrequests/${paymentRequestToken}/zipcreditnotefiles`
		return axios
			.get(url, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/octet-stream',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `NotaDeCredito_${paymentRequestToken}.zip`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('AccreditedWrokshopActions: Error to download zip credit note files. Error: ', error)
				return Promise.reject()
			})
	}
}

export function cancelPaymentrequests(token) {
	return function (dispatch, getState) {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const userName = getState().oidc.user.profile.username
		const url = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/paymentrequests/${token}`
		let command = {
			RequestBy: userName,
			Token: '',
		}
		return axios
			.patch(url, command, configuration)
			.then((response) => {
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				console.error('AccreditedWrokshopActions: Error to cancel Payment requests. Error: ', error)
				return Promise.reject()
			})
	}
}

export function downloandPaymentRequestsViewReport(paymentRequestToken) {
	return function () {
		const url = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/paymentrequests/${paymentRequestToken}/excelreport`
		return axios
			.get(url, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `ReporteDeOrdenesEnSolicitudeDePago_${paymentRequestToken}.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('AccreditedWrokshopActions: error to download level <=> Error: ', error)
				return Promise.reject()
			})
	}
}

function mapPaymentRequest(paymentRequest) {
	const mappedPamentRequest = { ...paymentRequest }
	mappedPamentRequest.creationDate = moment(paymentRequest.creationDate)
	mappedPamentRequest.canceledAt = moment(paymentRequest.canceledAt)
	mappedPamentRequest.lastError = '-'
	mappedPamentRequest.paymentDate = paymentRequest.paymentDate ? moment(paymentRequest.paymentDate) : null
	if (paymentRequest.attemps) {
		mappedPamentRequest.attemps = paymentRequest.attemps.map((attemp) => ({
			key: `attemp-${uuidv4()}`,
			creationDate: attemp,
			errors: [],
		}))
	}
	return mappedPamentRequest
}

/**********************************************************************
 ********* Actions for tehcnicians ************************************
 *********************************************************************/

function setLoadingTechniciansFlag(value) {
	return {
		type: IS_LOADING_TECHNICIANS,
		value,
	}
}

function setTechnicianList(technicians) {
	return {
		type: SET_TECHNICIAN_LIST,
		technicians,
	}
}

export function loadTechniciansFromBackEnd(workshopCode) {
	return function (dispatch, getState) {
		dispatch(setLoadingTechniciansFlag(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}technicians/all`
		let data = {
			WorkshopCode: workshopCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then(({ status, data }) => {
				if (status === 200) {
					dispatch(setTechnicianList(data))
				}
			})
			.catch(({ response }) => {
				dispatch(setLoadingTechniciansFlag(false))
				console.error(`Error getting the technicians for ${workshopCode}`, response)
			})
	}
}

function setSavingTechnicianFlag(value) {
	return {
		type: IS_SAVING_TECHNICIAN_DETAIL,
		value,
	}
}

function addTechnicianOnList(technicianDetail) {
	return {
		type: ADD_TECHNICIAN_ON_LIST,
		technicianDetail,
	}
}

export function addTechnicianOnBackEnd(technician) {
	return function (dispatch, getState) {
		dispatch(setSavingTechnicianFlag(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}technicians`
		let data = {
			Code: technician.code,
			FullName: technician.fullName,
			WorkshopCode: technician.workshopCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then(({ status, data }) => {
				if (status === 200) {
					dispatch(addTechnicianOnList(data))
				}
			})
			.catch(({ response }) => {
				dispatch(setSavingTechnicianFlag(false))
				console.error(`Error adding new technician`, response)
			})
	}
}

function updateTechnicianOnList(technicianDetail) {
	return {
		type: UPDATE_TECHNICIAN_ON_LIST,
		technicianDetail,
	}
}

export function updateTechnicianOnBackEnd(technician) {
	return function (dispatch, getState) {
		dispatch(setSavingTechnicianFlag(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}technicians`
		let data = {
			Token: technician.token,
			Code: technician.code,
			FullName: technician.fullName,
			Active: technician.active,
		}
		return axios
			.put(endPoint, data, configuration)
			.then(({ status, data }) => {
				if (status === 200) {
					dispatch(updateTechnicianOnList(data))
				}
			})
			.catch(({ response }) => {
				dispatch(setSavingTechnicianFlag(false))
				console.error(`Error adding new technician`, response)
			})
	}
}

/**************************************************************************
 ********* Actions for accredited workshops *******************************
 **************************************************************************/

function setAccreditedWorkshopStatus(status) {
	return {
		type: SET_ACCREDITED_WORKSHOP_STATUS,
		status,
	}
}

/**
 * Load the actual business configuration in the store
 * @param {*} rfc
 * @returns
 */
export const loadAccreditedWorkshopStatus = (code) => {
	return async (dispatch, getState) => {
		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}/certifiedworkshops/${code}/verified`
		try {
			const response = await axios.get(endPoint)
			if (response.status === 200) {
				// The configuration alredy exist. Load in the state
				dispatch(setAccreditedWorkshopStatus(response.data))
			} else {
				console.error('Unexpected response status code', response)
				dispatch(setAccreditedWorkshopStatus(false))
			}
		} catch (error) {
			return Promise.reject()
		}
	}
}

function setPaymentRequestSettings(settings) {
	return {
		type: SET_PAYMENT_REQUEST_SETTINGS,
		settings,
	}
}

function setBusinessUnitConfiguration(isLoading, configuration) {
	return {
		type: SET_BUSINESS_CONFIGURATION,
		isLoading,
		configuration,
	}
}

/**
 * Load the actual business configuration in the store
 * @param {*} rfc
 * @returns
 */
export const loadBusinessUnitConfiguration = (rfc) => {
	return async (dispatch, getState) => {
		let endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/configuration`
		try {
			dispatch(setBusinessUnitConfiguration(true, null))
			const response = await axios.get(endPoint)
			if (response.status === 200) {
				// The configuration alredy exist. Load in the state
				dispatch(setBusinessUnitConfiguration(false, response.data))
				return Promise.resolve(response.data)
			} else if (response.status === 204) {
				// The configuration doesn't exist. Create and load in the store
				var configuration = await createDefaultBusinessConfiguration(rfc)
				dispatch(setBusinessUnitConfiguration(false, configuration))
				return Promise.resolve(configuration)
			} else {
				console.error('Unexpected response status code', response)
			}
		} catch (error) {
			return Promise.reject()
		}
	}
}

/**
 * Create a default bussiness configuration
 * @param {*} rfc
 * @returns
 */
const createDefaultBusinessConfiguration = async (rfc) => {
	let endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/configuration`
	let command = { RFC: rfc }

	try {
		const response = await axios.post(endPoint, command)
		if (response.status === 200) {
			return Promise.resolve(response.data)
		}
	} catch (error) {
		console.error(`Error creating a configuration for ${rfc}`)
		return Promise.reject()
	}
}

export function getPaymentRequestSettings() {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}certifiedworkshops/settings`
		return axios
			.get(endPoint, configuration)
			.then(({ status, data }) => {
				if (status === 200) {
					dispatch(setPaymentRequestSettings(data))
				}
			})
			.catch(({ response }) => {
				console.error(`Error getting payement request creation status`, response)
			})
	}
}

function setPaymentRequestCreationStatus(value) {
	return {
		type: SET_PAYMENT_REQUEST_CREATION_STATUS,
		value,
	}
}

export function getPaymentRequestCreationStatus() {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}certifiedworkshops/setting`
		let data = {
			SettingCode: PAYMENT_REQUESTS_CREATION,
		}
		return axios
			.post(endPoint, data, configuration)
			.then(({ status, data }) => {
				if (status === 200) {
					dispatch(setPaymentRequestCreationStatus(data.value))
				}
			})
			.catch(({ response }) => {
				console.error(`Error getting payement request creation status`, response)
			})
	}
}

/**
 * Toggle the payment request creation status
 * @param {*} requestStatus
 * @returns
 */
export function togglePaymentRequestCreationStatus(rfc, requestStatus) {
	return async (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/setting`

		let data = {
			SettingCode: PAYMENT_REQUESTS_CREATION,
			SettingValue: requestStatus,
		}

		try {
			var result = await axios.put(endPoint, data, configuration)
			if (result.status === 200) {
				dispatch(setPaymentRequestCreationStatus(requestStatus))
				return Promise.resolve()
			}
		} catch (error) {
			console.error('Error updating the payment creation status', error)
			return Promise.reject(error)
		}
	}
}

function setSettingValue(retentionType, retentionValue) {
	return {
		type: SET_SETTING_BY_CODE_VALUE,
		retentionType,
		retentionValue,
	}
}

/**
 * Update setting value
 * @param {*} value
 * @param {*} type
 * @returns
 */
export function updateSettingValue(rfc, value, type) {
	return async (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		//Validate if INVOICING_ERROR is null or empty send 0 by default
		if (type === INVOICING_ERROR && IsNullOrEmpty(value)) value = 0

		let endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/setting`
		let data = {
			SettingCode: type,
			SettingValue: value,
		}

		try {
			var result = await axios.put(endPoint, data, configuration)
			if (result.status === 200) {
				dispatch(setSettingValue(type, value))
				return Promise.resolve()
			}
		} catch (error) {
			console.error('Error updating setting value')
		}
	}
}

function addWorkshopCodeOnRetention(retentionType, workshopCode) {
	return {
		type: ADD_WORKSHOP_ON_RETENTION,
		retentionType,
		workshopCode,
	}
}

export function addWorkshopToRetention(rfc, value, type) {
	return (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/workshop`
		let data = {
			Value: true,
			RetentionType: type,
			WorkshopCode: value,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(addWorkshopCodeOnRetention(type, response.data))
				}
			})
			.catch(({ response }) => {
				console.error(`Error changing status`, response)
			})
	}
}

function removeWorkshopCodeFromRetention(retentionType, workshop) {
	return {
		type: REMOVE_WORKSHOP_FROM_RETENTION,
		retentionType,
		workshop,
	}
}

export function removeWorkshopFromRetention(rfc, supplier, type) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/workshop`
		let data = {
			Value: false,
			RetentionType: type,
			WorkshopCode: supplier.Code,
		}
		return axios
			.post(endPoint, data, configuration)
			.then(({ status }) => {
				if (status === 200) {
					dispatch(removeWorkshopCodeFromRetention(type, supplier))
				}
			})
			.catch(({ response }) => {
				console.error(`Error changing status`, response)
			})
	}
}

/**
 * Get service type list
 */
export const getServiceTypeList = () => {
	return async (dispatch, getState) => {
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}certifiedworkshops/catalogoptions`
		const command = {
			CatalogName: 'ServiceType',
		}

		try {
			const result = await axios.post(endPoint, command)
			if (result.status === 200) {
				const data = result.data.map((st) => ({ Type: st.key, Description: st.label }))
				return Promise.resolve(data)
			}
		} catch (error) {
			console.error('Error getting service types', error)
		}
	}
}

export const addServiceTypeAction = (serviceType) => {
	return {
		type: ADD_SERVICE_TYPE,
		serviceType: serviceType,
	}
}

export const removeServiceTypeAction = (serviceType) => {
	return {
		type: REMOVE_SERVICE_TYPE,
		serviceType: serviceType,
	}
}

/**
 * Add a service type
 * @param {*} rfc
 * @param {*} serviceTypeCode
 * @returns
 */
export const addServiceType = (rfc, serviceTypeCode) => {
	return async (dispatch, getState) => {
		let endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/serviceTypes`

		const data = {
			ServiceTypeCode: serviceTypeCode,
		}

		try {
			let result = await axios.post(endPoint, data)
			if (result.status === 200) {
				dispatch(addServiceTypeAction(result.data))
				return Promise.resolve(result.data)
			} else if (result.status === 204) {
				console.warn(
					`No service type added with code [${serviceTypeCode}]. Usually this happen when the service already exist in the business unit`
				)
				return Promise.resolve()
			}
		} catch (error) {
			console.error('Error getting service types', error)
		}
	}
}

/**
 * Add a service type
 * @param {*} rfc
 * @param {*} serviceTypeCode
 * @returns
 */
export const removeServiceType = (rfc, serviceTypeCode) => {
	return async (dispatch, getState) => {
		let endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/serviceTypes/${serviceTypeCode}`
		try {
			let result = await axios.delete(endPoint)
			if (result.status === 200) {
				dispatch(removeServiceTypeAction(serviceTypeCode))
				return Promise.resolve(result.data)
			}
		} catch (error) {
			console.error('Error deleting a service types', error)
		}
	}
}

export const addWorkshop = (workshop) => {
	return {
		type: ADD_WORKSHOP,
		workshop,
	}
}

export const createWorkshop = (workshop) => {
	return async (dispatch, getState) => {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}certifiedworkshops`
		const command = toPascalCase(workshop)

		try {
			const result = await axios.post(endPoint, command, configuration)
			if (result.status === 200) {
				dispatch(addWorkshop(result.data))
			}
		} catch (error) {
			console.error('Error creating new workshop', error)
			return Promise.reject()
		}
	}
}

export const setWorkshopCodes = (workshopCodes) => {
	return {
		type: SET_WORKSHOP_CODES,
		workshopCodes,
	}
}

export const loadWorkshopCodes = () => {
	return async (dispatch, getState) => {
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}certifiedworkshops/search`
		const command = {
			CatalogName: 'Workshops',
		}

		try {
			const result = await axios.post(endPoint, command)
			if (result.status === 200) {
				const data = result.data.map((i) => i.sapCode)
				dispatch(setWorkshopCodes(data))
			}
		} catch (error) {
			console.error('Error getting workshop codes', error)
		}
	}
}

export const setAccreditedWorkshops = (workshops) => {
	return {
		type: SET_ACCREDITED_WORKSHOPS,
		workshops,
	}
}

export const loadAccreditedWorkshops = () => {
	return async (dispatch, getState) => {
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}certifiedworkshops/search`
		const command = {
			CatalogName: 'Workshops',
		}

		try {
			const result = await axios.post(endPoint, command)
			if (result.status === 200) {
				dispatch(setAccreditedWorkshops(result.data.map((i) => ({ ...i, technicians: [] }))))
				const workshopCodes = result.data.map((w) => w.sapCode)
				dispatch(loadTechniciansForWorkshops(workshopCodes))
			}
		} catch (error) {
			console.error('Error getting accredited workshops', error)
		}
	}
}

export const setTechniciansInAccreditedWorkshops = (data) => {
	return {
		type: SET_TECHNICIANS_IN_ACCREDITED_WORKSHOPS,
		data,
	}
}

export const loadTechniciansForWorkshops = (workshopCodes) => {
	return async (dispatch, getState) => {
		const endPoint = `${process.env.REACT_APP_WORKSHOP_ORDERS_BASE_API}certifiedworkshops/technicians`
		const command = {
			WorkshopCodes: workshopCodes,
		}

		try {
			const result = await axios.post(endPoint, command)
			if (result.status === 200) {
				dispatch(setTechniciansInAccreditedWorkshops(result.data))
			}
		} catch (error) {
			console.error('Error getting accredited workshops', error)
		}
	}
}

function addServiceStatusOnSection(data) {
	return {
		type: ADD_STATUS_ITEM_TO_SECTION,
		data,
	}
}

export function addServiceStatusToSection(rfc, status, sectionName) {
	return (dispatch, getState) => {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/serviceOrderStatusInsection`
		let data = {
			RFC: rfc,
			SectionName: sectionName,
			ServiceOrderStatus: status,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(addServiceStatusOnSection(response.data))
				}
			})
			.catch(({ response }) => {
				console.error(`Error changing status`, response)
			})
	}
}

function removeServiceStatusItemFromSection(sectionName, status) {
	return {
		type: REMOVE_STATUS_ITEM_FROM_SECTION,
		sectionName,
		status,
	}
}

export function removeServiceStatusFromSection(rfc, status, sectionName) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/serviceOrderStatusInsection/${sectionName}/${status}`
		return axios
			.delete(endPoint, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(removeServiceStatusItemFromSection(sectionName, status))
				}
			})
			.catch(({ response }) => {
				console.error(`Error removing status from section`, response)
				dispatch(removeServiceStatusItemFromSection(sectionName, status))
			})
	}
}

/**
 * Update setting value
 * @param {*} value
 * @param {*} type
 * @returns
 */
export function updateWorkshopIvaPercentage(rfc, value) {
	return async (dispatch, getState) => {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/workshopiva`
		const data = {
			Percentage: value,
		}

		try {
			var result = await axios.put(endPoint, data, configuration)
			if (result.status === 202) {
				dispatch(setSettingValue('IvaPercentage', value))
				return Promise.resolve()
			}
		} catch (error) {
			console.error('Error updating setting value')
		}
	}
}

function addWorkshopCodeOnIva(rfc, workshopCode, percentage) {
	return {
		type: ADD_WORKSHOP_IVA,
		rfc,
		workshopCode,
		percentage,
	}
}

export function addWorkshopIvaPercentage(rfc, workshopCode, value) {
	return (dispatch, getState) => {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/workshopiva`
		const data = {
			WorkshopCode: workshopCode,
			Percentage: value,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 201) {
					dispatch(addWorkshopCodeOnIva(rfc, workshopCode, value))
				}
			})
			.catch(({ response }) => {
				console.error(`Error changing status`, response)
			})
	}
}

function removeWorkshopCodeFromIVA(workshopCode) {
	return {
		type: REMOVE_WORKSHOP_IVA,
		workshopCode,
	}
}

export function removeWorkshopFromIVA(rfc, workshopCode) {
	return function (dispatch, getState) {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/workshopiva/${workshopCode}`
		return axios
			.delete(endPoint, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(removeWorkshopCodeFromIVA(workshopCode))
				}
			})
			.catch((error) => {
				console.error(`Error removing status from section`, error)
			})
	}
}

/**
 * Update setting value
 * @param {*} rfc
 * @param {*} value
 * @returns
 */
export function updateWorkshopResicoPercentage(rfc, value) {
	return async (dispatch, getState) => {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/workshopresico`
		const data = {
			Percentage: value,
		}

		try {
			var result = await axios.put(endPoint, data, configuration)
			console.log('🚀 ~ file: accreditedWorkshopsActions.js ~ line 1562 ~ return ~ resico', { result })
			if (result.status === 202) {
				dispatch(setSettingValue('ResicoPercentage', value))
				return Promise.resolve()
			}
		} catch (error) {
			console.error('Error updating setting value', error)
		}
	}
}

function addWorkshopCodeOnResico(rfc, workshopCode, percentage) {
	return {
		type: ADD_WORKSHOP_RESICO,
		rfc,
		workshopCode,
		percentage,
	}
}

export function addWorkshopResicoPercentage(rfc, workshopCode, value) {
	return (dispatch, getState) => {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/workshopresico`
		const data = {
			WorkshopCode: workshopCode,
			Percentage: value,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 201) {
					dispatch(addWorkshopCodeOnResico(rfc, workshopCode, value))
				}
			})
			.catch((error) => {
				console.error(`Error addig workshop on resico`, error)
			})
	}
}

function removeWorkshopCodeFromRESICO(workshopCode) {
	return {
		type: REMOVE_WORKSHOP_RESICO,
		workshopCode,
	}
}

export function removeWorkshopFromRESICO(rfc, workshopCode) {
	return function (dispatch, getState) {
		const configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_CERTIFIEDWORKSHOP}/businessunit/${rfc}/workshopresico/${workshopCode}`
		return axios
			.delete(endPoint, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(removeWorkshopCodeFromRESICO(workshopCode))
				}
			})
			.catch((error) => {
				console.error(`Error removing workshop from RESICO`, error)
			})
	}
}

export function cancelHttpRequest(key) {
	if (pendingRequests[key]) {
		pendingRequests[key].cancel()
	}
}
