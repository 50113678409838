import React, { Component } from 'react';
import { Card, CardHeader, Fab, CardContent, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddTwoTone';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DataTable from '../../../common/DataTable';
import { Translate } from 'react-localize-redux';

class Service extends Component {
    render() {
        return (
            <Card>
                <CardHeader
                    action={
                        <React.Fragment>
                            {(this.props.data.length > 0 && this.props.onDownloadMaterialsListReport) &&
                                <Tooltip title={<Translate id="contractorsModule.downloadMaterialsListReport" />}>
                                    <IconButton size="small" color="primary"
                                        component="a"
                                        target="_blank"
                                        onClick={() => { this.props.onDownloadMaterialsListReport() }}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {this.props.isActive &&
                                <Tooltip title={<Translate id={this.props.onDownloadMaterialsListReport ? "contractorsModule.addMaterial" : "contractorsModule.addContact"} />}>
                                    <Fab size="small" color="primary"
                                        onClick={() => { this.props.onAddClick() }}
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>}
                        </React.Fragment>
                    }
                    style={{ paddingBottom: 0 }}
                />
                <CardContent
                    style={{ paddingTop: 0 }}
                >
                    {this.props.data &&
                        <DataTable
                            data={this.props.data}
                            configuration={this.props.configuration}
                            onRenderCellItem={this.props.onRenderCellItem}
                        />
                    }
                </CardContent>
            </Card >
        );
    }
}

Service.propTypes = {

};

export default Service;