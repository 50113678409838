///Import react section
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { getTranslate } from 'react-localize-redux'

/**Import actions sections */
import { setToasterOptions } from '../../../store/indirectqualifications/IndirectqualificationsActions'

/**Import helpers sections */
import { converListToArray, toSafeObject } from '../../../store/helpers/ListHelper'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { addRequest, blockUserFromControlCO2, getRequestCount, loadAllRequests, loadService, updateCheckInDate, updateRequest } from '../../../store/controlCO2/controlCO2Action'

export const useRequestsCustomHookIndex = () => {
	const requests = useSelector((state) =>
		state.controlCO2.get('requests') ? converListToArray(state.controlCO2.get('requests')) : []
	)
	const service = useSelector((state) =>
		state.controlCO2.get('service') ? toSafeObject(state.controlCO2.get('service')) : []
	)
	const translate = useSelector((state) => getTranslate(state.localize))

	/**Render hooks section */
	const dispatch = useDispatch()
	let location = useLocation()

	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(loadAllRequests(queryParams, translate))
		dispatch(getRequestCount())
		dispatch(loadService())
	}, [dispatch, location.search])

	const onAddRequest = (model) => {
		const queryParams = queryString.parse(location.search)
		return dispatch(addRequest(model, translate))
			.then(() => {
				dispatch(loadAllRequests(queryParams, translate))
				dispatch(getRequestCount())
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.addCorrectly',
					})
				)
			})
			.catch((response) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage:
							response &&
							!IsNullOrEmpty(response.data) &&
							typeof response.data.Message === 'string' &&
							response.data.Message.includes('shceduled')
								? 'controlCO2.errorMessages.addRequestErrorSchedule'
								: 'common.errorToSave',
						toasterVariant: 'error',
						name: model.user,
					})
				)
			})
	}

	const onUpdateRequest = (model) => {
		const queryParams = queryString.parse(location.search)
		return dispatch(updateRequest(model, translate))
			.then(() => {
				dispatch(loadAllRequests(queryParams, translate))
				dispatch(getRequestCount())
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.addCorrectly',
					})
				)
			})
			.catch(() => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error',
						name: model.user,
					})
				)
			})
	}

	const onUpdateCheckInDate = (controlId) => {
		const queryParams = queryString.parse(location.search)
		return dispatch(updateCheckInDate(controlId))
			.then(() => {
				dispatch(loadAllRequests(queryParams, translate))
				dispatch(getRequestCount())
				setToasterOptions({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch((response) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error',

					})
				)
			})
	}

	const onBlockControlRequesterUser = (controlRequest) => {
		return dispatch(blockUserFromControlCO2(controlRequest))
			.then(() => {
				setToasterOptions({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'constrolCO2.blockSuccess',
				})
			})
			.catch((response) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'controlCO2.blockError',
						toasterVariant: 'error',

					})
				)
			})
	}

	return { onAddRequest, onUpdateRequest, onUpdateCheckInDate, onBlockControlRequesterUser, requests, service }
}
