import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import QualityFailureReportIcon from  '@material-ui/icons/WarningOutlined';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Toaster from '../common/Toaster';
import FormViewEdit from './components/FormViewEdit';
import LoadingPage from '../common/LoadingPage';

import { converListToArray } from '../../store/helpers/ListHelper'
import {loadQualityFailureReportFromBackEnd, editQualityFailureReportFromBackEnd} from '../../store/qualityFailureReport/QualityFailureReportAction';

/**
 * Component to EditReport corrective action supplier
 */
class EditReport extends Component{

     /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
        }
    }

    /**
     * componentDidMount
     */
    componentDidMount(){
        let qualityFailureToken = this.props.match.params.token;
        this.props.loadQualityFailureReport(qualityFailureToken);
    }


    /**
     * Get action to EditReport quality failure report
     */
    saveEditedQualityFailureReport(supplier, preparedBy, partNumber, failure, reportQuality){
        this.props.editQualityFailureReportFromBackEnd(supplier, preparedBy, partNumber, failure, reportQuality)
        .then(() =>{
            this.setState({
                showToView:true,
                showToaster: false,
            })
            let reportQualityToken = this.props.match.params.token;
            this.props.history.push(`/qualityfailurereport/${reportQualityToken}`)
        }).catch(() =>{
            this.setState({
                showToaster:true,
                toasterMessage: <Translate id="common.errorToSave"/>,
                toasterVariant: "error",
            })
        });
    }

      /**
    * Get the card content
    *
    * @memberof Show
    */
   getCardContent() {
    if (this.props.isLoading === true) {
        return (
            <LoadingPage />
        )
    }
    else if (this.props.qualityFailure !== null) {
        return (
            <FormViewEdit  
                {...this.props}
                isSaving = {this.props.isSaving}
                qualityReport={this.props.qualityFailure}
                canBeSaveToEdit={this.saveEditedQualityFailureReport}
            />
        )
    }
}


    render(){
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <QualityFailureReportIcon />
                    </Avatar>
                }
                title={<Typography variant="button" ><Translate id="qualityfailurereport.titleReport"/></Typography>}
                subheader={<Typography variant="caption" color="textPrimary"><Translate id="common.createBy"/>: {this.props.qualityFailure && this.props.qualityFailure.create_by}
                 <br/><Translate id="common.creationDate"/>: {this.props.qualityFailure && this.props.qualityFailure.creation_date}</Typography>}
            />
            <CardContent>
                {this.getCardContent()}
            </CardContent>

            <Toaster 
                message = {this.state.toasterMessage}
                open = {this.state.showToaster}
                variant = {this.state.toasterVariant}
                onClose = {() => {this.setState({ showToaster:false})}}
            /> 

        </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    let qualityFailure = state.qualityFailureReport.get("qualityFailureReport"); 
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        user: user,
        isLoading: state.qualityFailureReport.get('isLoading'),
        qualityFailure: qualityFailure  ? qualityFailure.toJS() : null,
        isSaving: state.qualityFailureReport.get('isSaving'),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadQualityFailureReport:(token) =>{
            return dispatch(loadQualityFailureReportFromBackEnd(token));
        },
        editQualityFailureReportFromBackEnd: (supplier, preparedBy, partNumber, failure, reportQuality) =>{
            return dispatch(editQualityFailureReportFromBackEnd(supplier, preparedBy, partNumber, failure, reportQuality))
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(EditReport))));