import React from 'react'
import { Translate } from 'react-localize-redux'

export const ServicesTableConfiguration = {
	columns: [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='controlCO2.services.dateOfService' />,
			dataSource: 'lastDateOfService',
		},
		{
			header: <Translate id='controlCO2.services.dateOfVerification' />,
			dataSource: 'lastVerificationDate',
		},
		{
			header: <Translate id='controlCO2.services.dateOfHolding' />,
			dataSource: 'lastHoldingDate',
		},
		{
			header: <Translate id='controlCO2.services.fireExtinguisherInspection' />,
			dataSource: 'fireExtinguisherInspection',
		},
		{
			header: <Translate id='controlCO2.vehicle' />,
			dataSource: 'vehicleEconomicNumber',
		},
		{
			header: <Translate id='controlCO2.services.originOfTransportation' />,
			dataSource: 'originOfTransportation',
		},
		{
			header: <Translate id='controlCO2.services.typeOfTransportation' />,
			dataSource: 'typeOfTransportation',
		},
		{
			header: null,
			dataSource: 'options',
		},
	],
}
