import { fromJS } from 'immutable';

import * as sustainabilityActions from './SustainabilityActions'

/**
 * contraActions initial state
 */
export const sustainabilityInitialState = fromJS({
    isLoading: true,
    identifier: "",
    isSaving: false,
    sustainabilityList: [],
    sustainability: null,
    sustainabilityCount: 0,
    templateQuestionnaire: null,
    totalWorkerDetail: null,
    auditCalendar: [],
    isSavingTemplate: false,
})

export default function companiesReducer(state = sustainabilityInitialState, action) {
    switch (action.type) {
        case sustainabilityActions.START_LOADING_SUSTAINABILITY_LIST: {
            return state.merge({
                isLoading: action.isLoading,
                identifier: action.identifier,
            })
        }
        case sustainabilityActions.SET_SUSTAINABILITY_LIST: {
            return state.merge({
                sustainabilityList: fromJS(action.sustainabilityList),
                isLoading: false,
                identifier: ""
            })
        }
        case sustainabilityActions.SET_SUSTAINABILITY_COUNT: {
            return state.merge({
                sustainabilityCount: action.count
            })
        }
        case sustainabilityActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving,
                identifier: action.identifier,
            })
        }
        case sustainabilityActions.SET_SUSTAINABILITY: {
            return state.merge({
                isLoading: false,
                identifier: "",
                sustainability: action.sustainability
            })
        }
        case sustainabilityActions.UPDATE_SUSTAINABILITY: {
            let property = action.property;
            let updateValue = action.updateValue;
            return state.setIn(['sustainability', property], updateValue).set('isSaving', false);
        }
        case sustainabilityActions.UPDATE_WORKER_DETAIL:{
            return state.updateIn(["sustainability", 'WorkerDetails'], (allDetails) => {
                let index = allDetails.findIndex((detail) => {
                    return detail.get('Id') === action.wordDetail.Id
                });
                return allDetails.setIn([index], fromJS(action.wordDetail));
            }).set('isSaving', false).set('totalWorkerDetail', fromJS(action.wordDetail));
        }
        case sustainabilityActions.UPDATE_QUESTION_IN_SUSTAINABILITY: {
            return state.updateIn(['sustainability', 'Questionnaire', "Topics"], allTopics => updateQuestionsProperty(allTopics, action.topicId, action.subTopicId, action.question)).set('isSaving', false);
        }
        case sustainabilityActions.UPDATE_ACTION_PLAN:{
            let listActions = action.listActions;
            if(listActions === "CloseFindings")
            {
                return state.updateIn(["sustainability", 'CloseFindings'], (allActionsPlan) => {
                    let index = allActionsPlan.findIndex((actionItem) => {
                        return actionItem.get('Id') === action.actionPlan.Id
                    });
                    return allActionsPlan.setIn([index], fromJS(action.actionPlan));
                }).set('isSaving', false);
            }
            else{
                return state.updateIn(["sustainability", 'ActionPlans'], (allActionsPlan) => {
                    let index = allActionsPlan.findIndex((actionItem) => {
                        return actionItem.get('Id') === action.actionPlan.Id
                    });
                    return allActionsPlan.setIn([index], fromJS(action.actionPlan));
                }).set('isSaving', false);
            }
        }
        case sustainabilityActions.SET_QUESTIONNAIRE: {
            return state.merge({
                isLoading: false,
                identifier: "",
                templateQuestionnaire: action.templateQuestionnaire
            })
        }
        case sustainabilityActions.UPDATE_SUPPLIERS_FILES_EVIDENCE: {
            return state.updateIn(["sustainability", 'CloseFindings'], (allActionsPlan) => {
                let index = allActionsPlan.findIndex((actionItem) => {
                    return actionItem.get('Id') === action.actionPlan.Id
                });
                return allActionsPlan.setIn([index], fromJS(action.actionPlan));
            }).set('isSaving', false);
        }
        case sustainabilityActions.SET_AUDIT_CALENDAR_LIST: {
            return state.merge({
                auditCalendar: fromJS(action.auditCalendar),
                isLoading: false,
                identifier: ""
            })
        }
        case sustainabilityActions.UPDATE_QUESTIONNAIRE: {
            let property = action.property;
            let updateValue = action.updateValue;
            return state.setIn(['templateQuestionnaire', property], updateValue).set('isSaving', false);
        }
        case sustainabilityActions.ADD_TOPIC_IN_SUSTAINABILITY:{
            let molde = fromJS(action.molde)
            return state.updateIn(['templateQuestionnaire', "Topics"], (moldes) => moldes.push(molde))
                .set('isSaving', false);
        }
        case sustainabilityActions.UPDATE_TOPIC_IN_SUSTAINABILITY: {
            return state.updateIn(['templateQuestionnaire', 'Topics'], (allTopics) => {
                let index = allTopics.findIndex((item) => {
                    return item.get('Id') === action.model.Id
                });
                return allTopics.setIn([index], fromJS(action.model)).set('isSaving', false);
            },
            );
        }
        case sustainabilityActions.DELETE_TOPIC_IN_SUSTAINABILITY:{
            return state.updateIn(['templateQuestionnaire', 'Topics'], (allTopics) => {
                let index = allTopics.findIndex((item) => {
                    return item.get('Id') === action.topicId
                });
            return allTopics.delete(index).set('isSaving', false);
            })
        }
        case sustainabilityActions.IS_SAVINGTEMPLATE: {
            return state.merge({
                isSavingTemplate: action.isSavingTemplate,
                identifier: action.identifier,
            })
        }
        default:
            return state;
    }
}

const updateQuestionsProperty = (allTopics, topicId, subTopicId, question) => {
    let indexOfTopic = allTopics.findIndex(d => d.get('Id') === topicId);
    if (indexOfTopic >= 0) {
        let newTopicUpdated = allTopics.get(indexOfTopic)
        if (newTopicUpdated !== undefined) {
            let subtopics = newTopicUpdated.get('Subtopics')
            let indexOfSubtopics = subtopics.findIndex(d => d.get('Id') === subTopicId);
            if (indexOfSubtopics >= 0) {
                let subTopic = subtopics.get(indexOfSubtopics)
                if (subTopic !== undefined) {
                    let questions = subTopic.get('Questions')
                    let indexOfQuestion = questions.findIndex(d => d.get('Id') === question.Id);
                    if (indexOfQuestion >= 0) {
                        return allTopics.setIn([indexOfTopic, 'Subtopics', indexOfSubtopics, 'Questions', indexOfQuestion],  fromJS(question));
                    }
                }
            }
        }
    }
    return allTopics
}
