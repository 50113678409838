import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import Toaster from '../common/Toaster';
import SuppliersCard from './componets/SuppliersCard';
import BaseInformation from './componets/BaseInformation';

/** Translations imports section */
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

/** Import section actions */
import { searchBySuppliersByQury } from '../../store/suppliers/SupplierActions';
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';
import { showSuccessToaster, showErrorToaster, showInfoToaster } from '../../store/helpers/ToasterHelpers';
import {createContactSupplier} from '../../store/contactsupplier/ContactSupplierAction';

class Create extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            contactSupplier: {
                suppliers: [],
                attachments: [],
                excludeSuppliers:[],
                name: "",
                contact_type: "",
                acceptedBySupplier: false,
                optionAll: true,
                optionNationals: false,
                optionForeigns: false,
                option: "optionAll",
            }
        }
    }

    validateChecks(contactSupplierUpdate, property, value) {
        if (property === 'optionNationals' && value) {
            contactSupplierUpdate['optionAll'] = false;
            contactSupplierUpdate['optionForeigns'] = false;
        } else if (property === 'optionForeigns' && value) {
            contactSupplierUpdate['optionAll'] = false;
            contactSupplierUpdate['optionNationals'] = false;
        } else if (property === 'optionAll' && value) {
            contactSupplierUpdate['optionNationals'] = false;
            contactSupplierUpdate['optionForeigns'] = false;
        }
        contactSupplierUpdate[property] = value;
        if (!contactSupplierUpdate['optionNationals']
            && !contactSupplierUpdate['optionForeigns'])
            contactSupplierUpdate['optionAll'] = true;

        return contactSupplierUpdate
    }

    updateProperty(property, value) {
        let contactSupplierUpdate = { ...this.state.contactSupplier };
        if (property === 'optionNationals'
            || property === 'optionForeigns'
            || property === 'optionAll')
            contactSupplierUpdate = this.validateChecks(contactSupplierUpdate, property, value)
        else
        contactSupplierUpdate[property] = value;
        this.setState({
            contactSupplier: contactSupplierUpdate
        })
    }

    updateSuppliersList(value) {
        let contactSupplierUpdate = { ...this.state.contactSupplier };
        if (value !== null && value !== undefined) {
            if (!contactSupplierUpdate['suppliers'].find(supplier => { return supplier.token === value.token })) {
                if (value.full_name) {
                    let supplier = { name: value.full_name, token: value.token };
                    contactSupplierUpdate['suppliers'] = contactSupplierUpdate['suppliers'].concat(supplier);
                    this.setState({
                        contactSupplier: contactSupplierUpdate
                    })
                }
                else {
                    let supplier = { name: value.name, token: value.token };
                    contactSupplierUpdate['suppliers'] = contactSupplierUpdate['suppliers'].concat(supplier);
                    this.setState({
                        contactSupplier: contactSupplierUpdate
                    })
                }
            } else {
                this.setState(showInfoToaster("common.repeatedSelection"));
            }
        }
    }

    removeSupplierFromList(supplierToken) {
        let contactSupplierUpdate = { ...this.state.contactSupplier };
        let newSuppliersList = contactSupplierUpdate['suppliers'].filter(supplier => {
            return supplier.token !== supplierToken
        })
        contactSupplierUpdate['suppliers'] = newSuppliersList;
        this.setState({
            contactSupplier: contactSupplierUpdate
        })
    }

    updateExcludeSuppliersList(value) {
        let contactSupplierUpdate = { ...this.state.contactSupplier };
        if (value !== null && value !== undefined) {
            if (!contactSupplierUpdate['excludeSuppliers'].find(supplier => { return supplier.token === value.token })) {
                let supplier = { name: value.full_name, token: value.token, code:value.code };
                contactSupplierUpdate['excludeSuppliers'] = contactSupplierUpdate['excludeSuppliers'].concat(supplier);
                this.setState({
                    contactSupplier: contactSupplierUpdate
                })
            } else {
                this.setState(showInfoToaster("common.repeatedSelection"));
            }
        }
    }

    removeSupplierExclude(supplierToken) {
        let contactSupplierUpdate = { ...this.state.contactSupplier };
        let newSuppliersList = contactSupplierUpdate['excludeSuppliers'].filter(supplier => {
            return supplier.token !== supplierToken
        })
        contactSupplierUpdate['excludeSuppliers'] = newSuppliersList;
        this.setState({
            contactSupplier: contactSupplierUpdate
        })
    }

    canBeSaved() {
        if (this.props.isSaving)
            return false;
        else {
            let contactSupplier = this.state.contactSupplier;
            if (IsNullOrEmpty(contactSupplier.name))
                return false;
            if (IsNullOrEmpty(contactSupplier.contact_type))
                return false;
            if(contactSupplier.contact_type && contactSupplier.contact_type.value === 1){
                if (contactSupplier.attachments.length <= 0)
                    return false;
            }
            if(contactSupplier.contact_type && contactSupplier.contact_type.value === 2){
                if (contactSupplier.suppliers.length <= 0)
                    return false;
            }
        }
        return true;
    }

        /**
     * Remove files
     */
    removeFiles(fileName) {
        let attachments = this.state.contactSupplier.attachments.filter(file => {
            return file.name !== fileName
        })
        this.setState({
            contactSupplier: {
                ...this.state.contactSupplier,
                attachments
            }
        })
        return Promise.resolve('Ok')
    }

    /**
     * Add file
     * @param {*} attachments 
     */
    uploadFiles(files) {
        let filesList = this.state.contactSupplier.attachments;
        if (files.length > 0) {
            for (var index = 0; index < files.length; index++) {
                filesList.push(files[index])
            }
        }
        this.setState({
            contactSupplier: {
                ...this.state.contactSupplier,
                attachments: filesList
            }
        })
        return Promise.resolve('Ok')
    }

    onCreateContactSupplier() {
        this.props.createContactSupplier(this.state.contactSupplier)
            .then((ethicCodeToken) => {
                this.setState(showSuccessToaster("common.addCorrectly"));
                this.props.history.push(`/contactsupplier/${ethicCodeToken}`);
            }).catch((error) => {
                this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : "common.errorToAdd"));
            });
    }

    render() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={6}>
                    <BaseInformation
                        updateProperty={this.updateProperty}
                        contactSupplier={this.state.contactSupplier}
                        onCreate={this.onCreateContactSupplier}
                        excludeSuppliers={this.state.contactSupplier.excludeSuppliers}
                        updateExcludeSuppliersList={this.updateExcludeSuppliersList}
                        removeSupplierExclude={this.removeSupplierExclude}
                        canBeSaved={this.canBeSaved()}
                        
                        {...this.props}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SuppliersCard
                        contactSupplier={this.state.contactSupplier}
                        updateProperty={this.updateProperty}
                        updateSuppliersList={this.updateSuppliersList}
                        suppliers={this.state.contactSupplier.suppliers}
                        removeSupplier={this.removeSupplierFromList}
                        uploadFiles={this.uploadFiles}
                        removeFiles={this.removeFiles}
                        dropzoneText={this.props.translate('dropzone.dropzoneBaseText')}
                        {...this.props}
                    />
                </Grid> 

                <Toaster
                    message={<Translate id={this.state.toasterMessage} />}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
            </Grid>
        )

    }
}

function mapStateToProps(state) {
    return {
        isLoadingSuppliers: state.suppliers.get("isLoadingSuppliers"),
        isSaving: state.contactSuppliers.get('isSavingContactSupplier'),
        translate: getTranslate(state.localize)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        searchBySuppliersByQury: (query) => {
            dispatch(searchBySuppliersByQury(query));
        },
        createContactSupplier: (contactSupplier) => {
            return dispatch(createContactSupplier(contactSupplier));
        },

    }
}

const viewStyles = theme => ({
    userAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 240,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    formatCheck: {
        display: 'flex',
        marginLeft: theme.spacing.unit,
        width: 460,
    },
});

export default withauthenticatedLayout(withRouter(withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create))));