import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import ReleaseClockIcon from '@material-ui/icons/Restore';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import { Tooltip } from '@material-ui/core';

import Toaster from '../../common/Toaster';
import LoadingPage from '../../common/LoadingPage';
import InformationPartial from './InformationPartial';
import AddCommonItem from '../../common/AddCommonItem';

import {loadReleaseCalendarLockFromBackEnd, removeReleaseCalendarLock} from '../../../store/planning/ReleaseCalendarAction';

/**
 * Component to show reelase calendar lock
 */
class Show extends Component{

     /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            openDelete: false,
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let calendarToken = this.props.match.params.token;
        this.props.loadReleaseCalendarLocck(calendarToken);
    }

    /**
    * onRemoveItem
    */
   onRemoveItem(itemId) {
    this.setState({
        openDelete: true,
    })
}

    /**
    * onRemoveReleaseCalendarLock
    */
   onRemoveReleaseCalendarLock() {
    let calendarToken = this.props.match.params.token;
    this.props.removeReleaseCalendarLock(calendarToken)
        .then(() => {
            this.setState({
                openDeleteDraft: false,
            })
            this.props.history.push(`/releasecalendar`)
        }).catch(() => {
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="common.errorToSave" />,
                toasterVariant: "error",
            })
        });
    }

    
    /**
    * Get the card content
    *
    * @memberof Show
    */
   getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.releaseCalendarLock !== null) {
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Divider />
                        <InformationPartial  calendarLock={this.props.releaseCalendarLock} />
                    </Grid>
                </Grid>
            )
        }
    }


    render(){
        const { classes } = this.props;
        return(
            <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <ReleaseClockIcon />
                    </Avatar>
                }
                action={
                    <div className={classes.actionsToolbar}>
                        <Tooltip title= {<Translate id="releaseCalendarLock.removeTitle"/>}>
                            <Fab size="small" color="primary"
                                 onClick={() => { this.onRemoveItem() }}>
                                <DeleteIcon  />
                            </Fab>
                        </Tooltip>
                    </div>
                }
                title={<Typography variant="button" ><Translate id="releaseCalendarLock.titleCalendar"/></Typography>}
                subheader={<div>
                    <Translate id="common.creationDate"/>: {" "} {this.props.releaseCalendarLock ? this.props.releaseCalendarLock.creation_date : ""} <br/>
                    <Translate id="common.createBy"/>: {" "} {this.props.releaseCalendarLock ? this.props.releaseCalendarLock.created_by : ""}
                </div>}
            />
            <CardContent>
                {this.getCardContent()}
            </CardContent>

            
            {/** Dialog to remove calendar lock */}
            < AddCommonItem
                title={<Translate id="releaseCalendarLock.removeTitle"/>}
                message={<Translate id="releaseCalendarLock.removeMesage"/>}
                open={this.state.openDelete}
                onClose={() => { this.setState({ openDelete: false }) }}
                onSaveItem={this.onRemoveReleaseCalendarLock}
                isSaving={this.props.isSaving}
                isMessage={true}
            />

            <Toaster 
                message = {this.state.toasterMessage}
                open = {this.state.showToaster}
                variant = {this.state.toasterVariant}
                onClose = {() => {this.setState({ showToaster:false})}}
            /> 

        </Card>
        )
    }
}


/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    return {
        user: user,
        isLoading: state.releaseCalendarLock.get("isLoading"),
        isSaving: state.releaseCalendarLock.get("isSaving"),
        releaseCalendarLock: state.releaseCalendarLock.get("releaseCalendarLock") ? state.releaseCalendarLock.get("releaseCalendarLock").toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadReleaseCalendarLocck:(token) =>{
            return dispatch(loadReleaseCalendarLockFromBackEnd(token));
        },
        removeReleaseCalendarLock:(token) =>{
            return dispatch(removeReleaseCalendarLock(token));
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(Show))));