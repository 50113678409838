import React, {Component} from 'react';
import PropTypes from 'prop-types';
import autoBind from 'auto-bind'
import uuidv4 from 'uuid/v4'

/** Material-UI */
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

/** Components */
import DataGridToolbar from './DataGridToolbar'

/**
 * Class represeting a data grid
 *
 * @class DataGrid
 * @extends {Component}
 */
class DataGrid extends Component {

    /**
     * Creates an instance of DataGrid.
     * @param {*} props
     * @memberof DataGrid
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            columns: [],
            pager: null,
            noDataMessage: "Sin información por mostrar"
        }
    }

    /**
     * Perform componentDidMount method from react life cycle.
     *
     * @memberof DataGrid
     */
    componentDidMount() {
        this.setColumnsConfigurationState();
        this.setPagerConfiguration();
    }

    /**
     * Perform componentDidUpdate method from react life cycle
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof DataGrid
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.configuration !== this.props.configuration) || (prevProps.data !== this.props.data)) {
            this.setColumnsConfigurationState();
            this.setPagerConfiguration();
        }
    }

    /**
     * Sets the pager configuration
     *
     * @memberof DataGrid
     */
    setPagerConfiguration() {
        let initialPager = null;
        if (this.props.pager) {
            initialPager = new (this.props.pager.pagerType)(this.updatePager, this.props.data, this.props.pager.pagerOptions);
        }
        this.setState({
            pager: initialPager
        })
    }

    /**
     * Set the colums configuration in the state
     *
     * @memberof DataGrid
     */
    setColumnsConfigurationState() {
        let columns = [];
        if (this.props.configuration && this.props.configuration.columns) {
            columns = this.props.configuration.columns;
        } else if (this.props.data.length > 0) {
            columns = Object.keys(this.props.data[0]).map((property) => {
                return {
                    header: property,
                    dataSource: property
                }
            });
        }
        this.setState({
            columns: columns
        })
    }

    /**
     * function to update pager in component state
     *
     * @param {Pager} pager
     * @memberof DataGrid
     */
    updatePager(pager) {
        this.setState({
            pager: pager
        });
    }

    /**
     * Get the colums of the table
     *
     * @returns
     * @memberof DataGrid
     */
    getColumns() {
        return this.state.columns.map((column) => {
            let columnSortCriteria = column.sortCriteria ? column.sortCriteria : column.dataSource;
            return (
                <TableCell key={uuidv4()} className={this.props.classes.tableHead}>
                    {(this.state.pager &&
                        <Tooltip
                            title="Sort"
                            placement={1 ? 'bottom-end' : 'bottom-start'}
                            enterDelay={300}
                        >
                            <TableSortLabel
                                active={columnSortCriteria === this.state.pager.orderBy}
                                direction={this.state.pager.orderType}
                                onClick={() => this.state.pager.onSortSelection(columnSortCriteria)}
                            >
                                {column.header}
                            </TableSortLabel>
                        </Tooltip>
                    ) || column.header}
                </TableCell>
            )
        })
    }

    /**
     * Get the datarows
     *
     * @returns
     * @memberof DataGrid
     */
    getRows() {
        let rowsToDisplay;
        rowsToDisplay = this.props.data;
        if (this.props.pager && this.state.pager && this.state.pager.dataRowsPaginated) {
            rowsToDisplay = this.state.pager.dataRowsPaginated;
        }
        return rowsToDisplay.map((item) => {
            return item ? (
                <DataGridRow
                    classes={this.props.classes.tableRow}
                    key={uuidv4()}
                    item={item}
                    columns={this.state.columns}
                    onCellButtonClick={this.props.onCellButtonClick}
                    onRenderCellItem={this.props.onRenderCellItem}
                    onRowClick={this.props.onRowClick}
                />
            ) : <TableRow key={uuidv4()} style={{ height: '59px' }} />
        })
    }

    renderToolbar() {
        return (<DataGridToolbar
            title={this.props.title}
            titleSize={this.props.titleSize}
            showAdd={this.props.showAdd}
            onClickAddButton={this.props.onClickAddButton}
            showSearch={this.props.showSearch}
            onClickSearch={this.props.onClickSearch}
            showSearchBar={this.props.showSearchBar}
            searchValue={this.props.searchValue}
            onChangeSearch={this.props.onChangeSearch}
            onKeyDown={this.props.onKeyDown}
            onClickClearSearch={this.props.onClickClearSearch}
        />);
    }
    /**
     * Perform render method from react life cycle.
     *
     * @returns
     * @memberof DataGrid
     */
    render() {
        const { classes } = this.props;
        if (this.props.data && this.state.pager && this.props.data.length > 0) {
            return (
                <div className={classes.root} data-cy="datagrid-container">
                    {this.renderToolbar()}
                    <div className={classes.tableContainer}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {this.getColumns()}
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.rowsSection}>
                                {this.getRows()}
                            </TableBody>
                        </Table>
                    </div>

                    {this.props.pager &&
                        <TablePagination
                            component="div"
                            colSpan={this.state.columns.length}
                            count={this.state.pager.totalCount}
                            rowsPerPage={this.state.pager.rowsPerPage}
                            page={this.state.pager.currentPage}
                            onChangePage={this.state.pager.onPageChange}
                            onChangeRowsPerPage={this.state.pager.onPageSizeChange}
                            rowsPerPageOptions={this.state.pager.rowsPerPageOptions}
                        />
                    }
                </div>
            );
        } else {
            return (
                <div className={classes.root}>
                    {this.renderToolbar()}
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {this.state.noDataMessage}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            )
        }
    }
}
DataGrid.propTypes = {
    data: PropTypes.array.isRequired,
    pager: PropTypes.any
};


/**
 * Component representing a data grid row
 *
 * @class DataGridRow
 * @extends {Component}
 */
class DataGridRow extends Component {

    /**
     * Get the cells of the row
     *
     * @memberof DataGridRow
     */
    getCells() {
        let cellContent;
        return this.props.columns.map((column) => {
            const { item } = this.props;
            if (this.props.onRenderCellItem) {
                let overridedContent = this.props.onRenderCellItem(column.dataSource, item);
                if (!overridedContent) {
                    cellContent = item[column.dataSource];
                } else {
                    cellContent = overridedContent;
                }
            } else {
                cellContent = item[column.dataSource];
            }
            return (
                <TableCell key={uuidv4()}>{cellContent}</TableCell>
            )
        })
    }

    /**
     * Get cells
     *
     * @returns
     * @memberof DataGridRow
     */
    render() {
        const { classes } = this.props;
        return (
            <TableRow style={{ cursor: "pointer" }}
                onClick={(event) => {
                    if (this.props.onRowClick) {
                        this.props.onRowClick(this.props.item, event)
                    }
                }}
                hover
                className={classNames(classes.tableRow)}>
                {this.getCells()}
            </TableRow>
        )
    }
}
DataGridRow.propTypes = {
    item: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired
};

const styles = theme => ({
    root: {
        zIndex: '30',
        backgroundColor: "#fff",
    },
    tableContainer: {
        overflowX: 'auto',
        zIndex: '30',
        minWidth: 300,
        minHeight: 200,
        maxHeight: '70vh',
    },
    table: {
        zIndex: '30',
        minWidth: 300,
        minHeight: 200,
    },
    tableHead: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0,
        zIndex: '30',

    },
    tableRow: {
        cursor: "pointer",
    },
    rowsSection: {
        overflowX: 'auto',
    }
});

export default withStyles(styles, { withTheme: true })(DataGrid);