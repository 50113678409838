/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/**Import MaterialUi section */
import {Grid, Button, Avatar, Typography,TextField, Divider} from '@material-ui/core'
import FileIcon from '@material-ui/icons/AttachFile';
/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../store/helpers/StatusColorConstants';
import Evidence from '../../common/Evidence'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'


const PanelFilesMaterial = (props) => {

    /**props section */
    const {onCloseButtonClick,
        isSaving, classes,
        itemSelected,
        onSaveItem,
        canBeEdit,
        isEvidence,
        isMaquilaDraft,
        updatProductMachineds,
        canBeEditWarehouse
    } = props
    const [attachments, setAttachments] = useState(itemSelected.attachments ? itemSelected.attachments : [])
    const [itemModel, setItemModel] = useState(itemSelected);

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setItemModel({
            ...itemModel,
            [name]: value
        });
    }

    /**Verify if can be sabed data*/
    const canBeSaved = () => {
        if(isEvidence){
            if (!IsNullOrEmpty(itemModel.comments))
                return true;
            else
                return false;
        }
        else{
            if (Number(itemModel.quantity_send) > 0 || Number(itemModel.quantity_warehouse) > 0 || !IsNullOrEmpty(itemModel.comments))
                return true;
            else
                return false;
        }
    }
    
    /**
     * Function on update file
     */
    const onSaveItemUpdate = () =>{
        if(isEvidence){
            if(props.updatProductMachineds)
                updatProductMachineds(itemModel);
        }
        else{
            if (props.onSaveItem) 
                onSaveItem(itemModel)
        }
    }

     /**
     * On upload file in maquila order in list name
     * @param {*} files 
     * @param {*} maquilaToken 
     * @param {*} listName 
     */
    const uploadFiles = (files) => {
        let maquilaOrderToken = props.match.params.token;
        let filesList = attachments.concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
            if(isEvidence)
            {
                return props.uploadFilesDetailMachineds(maquilaOrderToken, itemModel.token, files)
                    .then(() => {
                        setAttachments(filesList)
    
                    }).catch(() => {
                        return Promise.reject();
                    });
            }else{
                return props.uploadFilesFromSupplier(maquilaOrderToken, itemModel.token, files)
                    .then(() => {
                        setAttachments(filesList)
    
                    }).catch(() => {
                        return Promise.reject();
                    });
                }
            }
        return Promise.resolve('Ok');
    }
    
    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
    */
    const removeFiles = (fileName) => {
        let maquilaOrderToken = props.match.params.token;
        let newList = attachments.filter(files => {
            return files.name !== fileName
        })
        if (isEvidence) { 
            return props.removeFilesDetailMachineds(maquilaOrderToken, itemModel.token, fileName)
            .then(() => {
                setAttachments(newList)
                return Promise.resolve('Ok');
            }).catch(() => {
                return Promise.reject();
            });
        }else
        {
            return props.onDeleteAttachment(maquilaOrderToken, itemModel.token, fileName)
            .then(() => {
                setAttachments(newList)
                return Promise.resolve('Ok');
            }).catch(() => {
                return Promise.reject();
            });
        }
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                titlePanel={<Translate id={isEvidence ? "maquilaOrders.supplierEvidence" : "maquilaOrders.informationSupplier"}/>}
                onCloseButtonClick={onCloseButtonClick}
                icon={<Avatar className={classes.avatar}><FileIcon /></Avatar>}
                isIconMaterial={true}
            />
            <div>
                <div className={classes.containerInformation}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography color='primary'>{<Translate id={isEvidence ? "maquilaOrders.supplierEvidence" : "maquilaOrders.supplierDocuments"}/>}</Typography>
                            <Evidence
                                text={props.dropzoneText}
                                files={attachments}
                                uploadFiles={uploadFiles}
                                removeFiles={removeFiles}
                                baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
                                isDisabled={!isEvidence ? !canBeEditWarehouse : !canBeEdit}
                            />
                        </Grid>
                        {!isMaquilaDraft && <Grid item xs={12}>
                            <br/>
                            <Typography color='primary'>{<Translate id="maquilaOrders.aditionalInformation"/>}</Typography>
                            <br/>
                            <Divider/>
                            <br/>
                        {!isEvidence &&
                            <div>
                                <Typography>{<Translate id="maquilaOrders.quantitySendConfinrSupplier"/>}</Typography>
                                <TextField
                                    size="small" 
                                    fullWidth
                                    className={classes.textField}
                                    name='quantity_warehouse'
                                    type='number'
                                    margin="dense"
                                    variant="outlined"
                                    step={0.5}
                                    onBlur={onPropertyChange}
                                    disabled={isSaving || !canBeEditWarehouse}
                                    defaultValue={itemModel.quantity_warehouse}
                                    InputProps={{ classes: { input: classes.inputProps } }}
                                />
                                 <br/>
                            </div>
                            }
                        {!isEvidence &&
                            <div>
                                <Typography>{<Translate id="maquilaOrders.quantitySendConfinr"/>}</Typography>
                                <TextField
                                    size="small" 
                                    fullWidth
                                    className={classes.textField}
                                    name='quantity_send'
                                    type='number'
                                    margin="dense"
                                    variant="outlined"
                                    step={0.5}
                                    onBlur={onPropertyChange}
                                    disabled={isSaving || !canBeEdit}
                                    defaultValue={itemModel.quantity_send}
                                    InputProps={{ classes: { input: classes.inputProps } }}
                                />
                            </div>
                            }
                        </Grid>}
                        {!isMaquilaDraft && <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={<Translate id={"maquilaOrders.usedMoldersAndComments"}/>}
                                className={classes.textField}
                                onChange={onPropertyChange}
                                multiline
                                name='comments'
                                margin="normal"
                                variant="outlined"
                                value={itemModel ? itemModel.comments : ''}
                                rows="4"
                                disabled={isSaving || !canBeEdit}
                            />
                        </Grid>}
                    </Grid>
                </div>
                {(canBeEdit || canBeEditWarehouse) && !isMaquilaDraft && <Grid container>
                    <Grid item xs={12} className={classes.containerBtn}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            variant='outlined'
                            onClick={onSaveItemUpdate}
                            disabled={!canBeSaved() || isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>}
            </div>

        </LateralPanel >
    )
}

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    expansionPanelSummary: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center"
    },
    inputProps: {
        height: 6,
    },

});

PanelFilesMaterial.propTypes = {
    /**
     * callback executed add new user in the list
     */
    onDeleteAttachment: propTypes.func,
    /**
     * open and closedlateral panel for add an new user in customer
     */
    onSaveItem: propTypes.func,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Validate if template is correctly
     */
    onCloseButtonClick: propTypes.func,
    /**All attachments saved */
    uploadFilesFromSupplier: propTypes.func,
    /**Detail machineds saved */
    uploadFilesDetailMachineds: propTypes.func,
    /**Delete detail machineds*/
    removeFilesDetailMachineds: propTypes.func,
}

PanelFilesMaterial.defaultProps = {
    onDeleteAttachment: () =>
        console.warn('Callback [onDeleteAttachment] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveItem: () =>
        console.warn('Callback [onSaveItem] no defined'),
    uploadFilesFromSupplier: () =>
        console.warn('Callback [uploadFilesFromSupplier] no defined'),
    uploadFilesDetailMachineds: () =>
        console.warn('Callback [uploadFilesDetailMachineds] no defined'),
    removeFilesDetailMachineds: () =>
        console.warn('Callback [removeFilesDetailMachineds] no defined'),
    isSaving: false
}

export default withRouter(withStyles(styles, { withTheme: true })(PanelFilesMaterial))