import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom"
import { RViewer, RViewerTrigger } from 'react-viewerjs'
import moment from 'moment'
/**Import MaterialUi section */
import {
    List, ListItem, ListItemText, Divider, Tabs, Tab, Grid, TextField, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
/**Import components section */
import ViewServiceComponent from './ViewService'
import Evidence from '../../common/Evidence'
import GreenButton from '../../common/GreenButton'
/**Import store section */
import { useRequestsCustomHookIndex } from '../customHooks/useCustomHooksIndex'
/**import helper section */


const ViewComponent = (props) => {
    const { 
        translate,
        classes, 
        item, 
        service, 
        onCloseServiceButtonClick, 
        modify, 
        setModify, 
        newService, 
        uploadFilesLastVerificationDate,
        removefilesLastVerificationDate,
        uploadFilesLastHoldingDate,
        removeFilesLastHoldingDate,
        uploadFilesLastDateOfServiceAttachments,
        removeFilesLastDateOfServiceAttachments,
        uploadFilesOtherAttachments,
        removeFilesOtherAttachments,
        OnUpdateProperty,
        onCloseModal,
        canBeModify,
        onUpdateRequest,
        isAdminUser,
        disabledBlock,
     } = props
    const [ imageView, setImageView ] = useState(false)
    const [ activeTab, setActiveTab ] = useState(0)
    const { onUpdateCheckInDate, onBlockControlRequesterUser } = useRequestsCustomHookIndex()
    const [control, setcontrol] = useState({
		attachments: [],
        token: item.token,
        kmFinal: 0,
        checkInObservations: '',
	})

    const onUpdateRequestControl = () => {
		onUpdateRequest(control)
        .then(() => {
			onCloseModal()
			setcontrol({
				attachments: [],
			})
		})
        .catch(() => {
            console.error('Error saving delivery image evidence')
        })
        
	}

    const onBlockUser = () => {
        onBlockControlRequesterUser(item)
        onCloseModal()
    }

    const removeFiles = (fileName) => {
		let newControl = control
		let newAttachments = control.attachments.filter((file) => {
			return file.name !== fileName
		})
		newControl.attachments = newAttachments
		setcontrol(newControl)
		return Promise.resolve('Ok')
	}

	const uploadFiles = (files) => {
		let filesList = control
		if (files.length > 0) {
			for (var index = 0; index < files.length; index++) {
				filesList.attachments.push(files[index])
			}
		}
		setcontrol(filesList)

		return Promise.resolve('Ok')
	}

    const updateProperty = (property, value) => {
		let controlUpdate = { ...control }
		controlUpdate[property] = value
		setcontrol(controlUpdate)
	}


    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    const handleImageModal = () => {
        setImageView(!imageView)
    }

    const onClickTabs = (event, value) => {
        setActiveTab(value)
    }

    const canBeUpdate =() => {
        if(item.color_status_table === "success") return false
        if(item.color_status_table === "danger") return false
        if(canBeModify === false) return false
        else return true
    }

    return (
        <div className={classes.containerInformation}>
        <div>
            <Tabs
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
            value={activeTab}
            onChange={onClickTabs}
           >
                <Tab label="Detalle de control" {...a11yProps(0)}/>
                <Tab label="Detalle de servicio" {...a11yProps(1)}/>
           </Tabs>
        </div>
        {activeTab === 0 && <div>
                <List className={classes.listStyle} dense>
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="controlCO2.creationDate" />}
                            secondary={item.creationDate}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider} />
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="controlCO2.user" />}
                            secondary={item.user}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider} />
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="controlCO2.destinationPlace" />}
                            secondary={item.destinationPlace}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider} />
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="controlCO2.km" />}
                            secondary={item.kmInitial}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider} />
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="controlCO2.arrivalLevelsCheck" />}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="controlCO2.gasoline" />}
                            secondary={item.arrivalLevelsCheck.split('|')[0]}
                        />
                        <ListItemText
                            primary={<Translate id="controlCO2.oil" />}
                            secondary={item.arrivalLevelsCheck.split('|')[1]}
                        />
                        <ListItemText
                            primary={<Translate id="controlCO2.water" />}
                            secondary={item.arrivalLevelsCheck.split('|')[2]}
                        />
                        <ListItemText
                            primary={<Translate id="controlCO2.tires" />}
                            secondary={item.arrivalLevelsCheck.split('|')[3]}
                        />
                        <ListItemText
                            primary={<Translate id="controlCO2.license" />}
                            secondary={item.isLicenseVerified ? "OK" : "NOK"}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider} />
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="controlCO2.checkOutDate" />}
                            secondary={moment(item.checkOutDate).format('DD/MM/YYYY, h:mm a')}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider} />
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="controlCO2.checkInDate" />}
                            secondary={moment(item.checkInDate).format('DD/MM/YYYY, h:mm a')}
                        />
                    </ListItem>
                    <Divider component="li" className={classes.divider} />
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="controlCO2.cleaningCheck" />}
                            secondary={item.cleaningCheck}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={<Translate id='controlCO2.leavingEvidence' />}
                        />
                    </ListItem>
                    <ListItem>
                        <RViewer  imageUrls={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item.Image}/${item.id}.jpg?w=164&h=164&fit=crop&auto=format`}>
                            <RViewerTrigger>
                                <button onClick={() => handleImageModal()}>
                                    <img
                                        srcSet={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item.Image}/${item.id}.jpg?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item.Image}/${item.id}.jpg?w=164&h=164&fit=crop&auto=format`}
                                        alt={item.id}
                                        loading="lazy"
                                        width={'400px'}
                                        
                                    />
                                </button>
                            </RViewerTrigger>
                        </RViewer>
                    </ListItem>
                    <Divider component="li" className={classes.divider} />
                    <ListItem>
                        <ListItemText
                            primary={<Translate id="controlCO2.observations" />}
                            secondary={item.observations}
                        />
                    </ListItem>
                    <ListItemText
                            primary={<Translate id='controlCO2.arrivingEvidence' />}
                        />
                    {item.IsDeliveryEvidence ? (
                        <ListItem>
                        <RViewer  imageUrls={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item.Image}/${item.id}_entrada.jpg?w=164&h=164&fit=crop&auto=format`}>
                            <RViewerTrigger>
                                <button onClick={() => handleImageModal()}>
                                    <img
                                        srcSet={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item.Image}/${item.id}_entrada.jpg?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}${item.Image}/${item.id}_entrada.jpg?w=164&h=164&fit=crop&auto=format`}
                                        alt={item.id}
                                        loading="lazy"
                                        width={'400px'}
                                    />
                                </button>
                            </RViewerTrigger>
                        </RViewer>
                    </ListItem>
                    ) : (
                    <ListItem>
                        <Grid item xs={12}>
                            <Evidence
                                    baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                                    removeFiles={removeFiles}
                                    uploadFiles={uploadFiles}
                                    files={control.attachments}
                                    acceptedFiles={['images', 'officeDocuments', 'pdf', 'otherFiles']}
                                    text={'Selecciona o arrastra la imagen'}
                                    disabledLink
                                />
                        </Grid>
                    </ListItem> 
                    )}
                    <br />
                    {(item.color_status_table === "success" || item.color_status_table === "warning") ? (<ListItem>
                        <ListItemText
                            primary={<Translate id='controlCO2.kmFinal'/>} 
                            secondary={
                                <TextField
                                    fullWidth
                                    id='outlined-size-small'
                                    label={''}
                                    type="number"
                                    defaultValue={item.kmFinal ? item.kmFinal : ''}
                                    onChange={(event) => {
                                        updateProperty('kmFinal', event.target.value)
                                    }}
                                    variant='outlined'
                                />}
                        />
                        
                    </ListItem>
                    ) : (
                        <ListItem>
                            <ListItemText
                                primary={<Translate id="controlCO2.kmFinal" />}
                                secondary={item.kmFinal}
                            />
                        </ListItem>
                    )}
                    {(item.color_status_table === "success" || item.color_status_table === "warning") ? (<ListItem>
                            <ListItemText
                                primary={<Translate id='controlCO2.observationsAtSubmmit'/>} 
                                secondary={
                                    <TextField
                                        fullWidth
                                        id='outlined-size-small'
                                        label={''}
                                        defaultValue={item.checkInObservations ? item.checkInObservations : ''}
                                        onChange={(event) => {
                                            updateProperty('checkInObservations', event.target.value)
                                        }}
                                        variant='outlined'
                                />}
                            />
                        
                    </ListItem>
                    ) : (
                        <ListItem>
                            <ListItemText
                                primary={<Translate id="controlCO2.observationsAtSubmmit" />}
                                secondary={item.checkInObservations}
                            />
                        </ListItem>
                    )}
                    {(item.color_status_table === "success" || item.color_status_table === "warning") && 
                    <ListItem className={classes.containerBtn}>
                        <Button 
                            onClick={() => onUpdateRequestControl()} 
                            data-cy='btn-save'
                            type='button'
                            color='primary'
                            variant='outlined'
                            >
                                Guardar evidencia
                        </Button>
                    </ListItem>}
                    <br/>
                    {canBeUpdate() && <ListItem>
                        <Button 
                            onClick={() => onUpdateCheckInDate(item.id)} 
                            data-cy='btn-save'
                            type='button'
                            color='secondary'
                            variant='outlined'
                            >
                                Marcar como entregado
                        </Button>
                    </ListItem>}
                    {item.color_status_table === "success" && !item.isUserBlocked && isAdminUser &&
                    <ListItem>
                        <Button 
                            onClick={() => onBlockUser()} 
                            data-cy='btn-save'
                            type='button'
                            color='primary'
                            variant='contained'
                            disabled={disabledBlock(item.user)}
                            >
                                Bloquear usario solicitante
                        </Button>
                    </ListItem>
                    } 
                    {item.isUserBlocked && isAdminUser &&
                    <ListItem>
                        <GreenButton
                            onClick={() => onBlockUser()} 
                            data-cy='btn-save'
                            type='button'
                            variant='contained'
                            disabled={disabledBlock(item.user)}
                            >
                                Desbloquear usario solicitante
                        </GreenButton>
                    </ListItem>
                    } 
                </List>
                
            </div>}
            {activeTab === 1 && <div>
                <ViewServiceComponent
					translate={translate}
					item={service}
					onCloseButtonClick={onCloseServiceButtonClick}
					modify={modify}
					setModify={setModify}
					newService={newService}
					uploadFilesLastVerificationDate={uploadFilesLastVerificationDate}
					removefilesLastVerificationDate={removefilesLastVerificationDate}
					uploadFilesLastHoldingDate={uploadFilesLastHoldingDate}
					removeFilesLastHoldingDate={removeFilesLastHoldingDate}
					uploadFilesLastDateOfServiceAttachments={uploadFilesLastDateOfServiceAttachments}
					removeFilesLastDateOfServiceAttachments={removeFilesLastDateOfServiceAttachments}
					uploadFilesOtherAttachments={uploadFilesOtherAttachments}
					removeFilesOtherAttachments={removeFilesOtherAttachments}
					OnUpdateProperty={OnUpdateProperty}
					onCloseModal={onCloseModal}
                    canBeModify={canBeModify}
				/>
            </div>}
        </div>



    )

}

const styles = theme => ({
    containerInformation: {
        maxHeight: `calc(100vh - ${165}px)`,
        minHeight: `calc(100vh - ${165}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    widthBtnSaved: {
        minWidth: "200px",
    },
    divider: {
        marginRight: "10px",
    },
    bgRed: {
        backgroundColor:'red'
    }
});

ViewComponent.propTypes = {

    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Selected item data
     */
    item: propTypes.object,
    translate: propTypes.func,
    service: propTypes.object, 
    modify: propTypes.bool, 
    setModify: propTypes.func, 
    newService: propTypes.object, 
    uploadFilesLastVerificationDate: propTypes.func,
    removefilesLastVerificationDate: propTypes.func,
    uploadFilesLastHoldingDate: propTypes.func,
    removeFilesLastHoldingDate: propTypes.func,
    uploadFilesLastDateOfServiceAttachments: propTypes.func,
    removeFilesLastDateOfServiceAttachments: propTypes.func,
    uploadFilesOtherAttachments: propTypes.func,
    removeFilesOtherAttachments: propTypes.func,
    OnUpdateProperty: propTypes.func,
    onCloseModal: propTypes.func,
    canBeModify: propTypes.func,
    onUpdateRequest: propTypes.func,
}

ViewComponent.defaultProps = {
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    translate: () =>
        console.warn('Callback [translate] no defined'),
    item: {},
    service: {},
    modify: false,
    setModify: () =>
        console.warn('Callback [setModify] no defined'),
    newService: {},
    uploadFilesLastVerificationDate: () =>
        console.warn('Callback [uploadFilesLastVerificationDate] no defined'),
    removefilesLastVerificationDate: () =>
        console.warn('Callback [removefilesLastVerificationDate] no defined'),
    uploadFilesLastHoldingDate: () =>
        console.warn('Callback [uploadFilesLastHoldingDate] no defined'),
    removeFilesLastHoldingDate: () =>
        console.warn('Callback [removeFilesLastHoldingDate] no defined'),
    uploadFilesLastDateOfServiceAttachments: () =>
        console.warn('Callback [uploadFilesLastDateOfServiceAttachments] no defined'),
    removeFilesLastDateOfServiceAttachments: () =>
        console.warn('Callback [removeFilesLastDateOfServiceAttachments] no defined'),
    uploadFilesOtherAttachments: () =>
        console.warn('Callback [uploadFilesOtherAttachments] no defined'),
    removeFilesOtherAttachments: () =>
        console.warn('Callback [removeFilesOtherAttachments] no defined'),
    OnUpdateProperty: () =>
        console.warn('Callback [OnUpdateProperty] no defined'),
    onCloseModal: () =>
        console.warn('Callback [onCloseModal] no defined'),
    canBeModify: () =>
        console.warn('Callback [canBeModify] no defined'),
    onUpdateRequest: () =>
        console.warn('Callback [onUpdateRequest] no defined'),
}

export default withRouter(withStyles(styles, { withTheme: true })(ViewComponent))