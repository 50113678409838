import React from 'react'
import { Translate } from 'react-localize-redux'

import { canBeAssignedSupplierInLoadAlert, canBeModuleLoadAlert } from './RolesHelper'
import { IsUserSupplierAdministrator } from './SessionHelper'
import {
	COLOR_STATUS_DANGER,
	COLOR_STATUS_INFO,
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_WARNING,
	COLOR_SUSTAINABILITY_PLUM,
} from './StatusColorConstants'

export function canBeEditSupplierInformation(loadAlert, userRolesByCompany, userProfile, loadAlertItem) {
	if (userRolesByCompany === undefined) return false
	if (userProfile === null || userProfile === undefined) return false
	if (loadAlert === null || loadAlert === undefined) return false
	// if (loadAlert.status !== LoadAlertStatus.DRAFT) return false
	if (!loadAlertItem) return false
	if (userProfile.profile.company_code !== loadAlertItem.supplierCode) return false
	let supplierAdmin = IsUserSupplierAdministrator(userProfile)
	return supplierAdmin || canBeModuleLoadAlert(userRolesByCompany)
}

export function canBeEditInternalUserInformation(loadAlert, userRolesByCompany, userProfile, isInternalUser) {
	if (userRolesByCompany === undefined) return false
	if (userProfile === null || userProfile === undefined) return false
	if (loadAlert === null || loadAlert === undefined) return false
	if (!isInternalUser) return false

	let supplierAdmin = IsUserSupplierAdministrator(userProfile)
	return supplierAdmin || canBeAssignedSupplierInLoadAlert(userRolesByCompany)
}

// export const loadAlertStatus = {
// 	Undefined: 0,
// 	Draft: 1,
// 	ReviewInternationalCarrierInformation: 2,
// 	AcceptedInternationalCarrierInformation: 3,
// 	RejectedInternationalCarrierInformation: 4,
// 	ReviewNacionalCarrierInformation: 5,
// 	AcceptedNacionalCarrierInformation: 6,
// 	RejectedNacionalCarrierInformation: 7,
// 	Finalized: 8,
// 	Cancel:10
// }

export const loadAlertStatus = {
	Undefined: 0,
	Draft: 1,
	Review: 2,
	InProcess: 3,
	Finalized: 4,
	Cancel: 5,
}

export const loadAlertItemStatus = {
	Undefined: 0,
	Draft: 1,
	ReviewInformation: 2,
	AcceptedInformation: 3,
}

export const ShipsGoStatus = {
	Transit: 'transit',
	Sailing: 'Sailing',
	Discharged: 'Discharged',
}

export const FilterCaseOptions = {
	SupplierInItem: 'SupplierInItem',
	FoliosSit: 'FoliosSit',
	Vinculate: 'Vinculate',
	StatusShipsGo: 'StatusShipsGo',
	TrackingLinks: 'trackingLinks',
	SupplierMaterials: 'supplierMaterials',
	ReferenceInternationals: 'referenceInternationals',
	ShippingOrders: 'shippingOrders',
	BookingNumbers: 'bookingNumbers',
	SupplierAduanals: 'supplierAduanals'
}

/**
 * Get title to chage status
 * @returns
 */
export function onGetStatusDescriptionChip(status) {
	switch (status) {
		case loadAlertStatus.Draft:
			return 'loadAlerts.statusDescriptions.draft'
		case loadAlertStatus.Review:
			return 'loadAlerts.statusDescriptions.review'
		case loadAlertStatus.InProcess:
			return 'loadAlerts.statusDescriptions.inProcess'
		case loadAlertStatus.Finalized:
			return 'loadAlerts.statusDescriptions.finalized'
		case loadAlertStatus.Cancel:
			return 'loadAlerts.statusDescriptions.cancel'
		default:
			return ''
	}
}

/**
 * Get title to chage status
 * @returns
 */
export function onGetBackgroundColorChip(status) {
	switch (status) {
		case loadAlertStatus.Draft:
			return COLOR_STATUS_INFO
		case loadAlertStatus.Review:
			return COLOR_STATUS_WARNING
		case loadAlertStatus.InProcess:
			return COLOR_SUSTAINABILITY_PLUM
		case loadAlertStatus.Finalized:
			return COLOR_STATUS_SUCCESS
		case loadAlertStatus.Cancel:
			return COLOR_STATUS_DANGER
		default:
			return ''
	}
}

/**
 * Get title to chage status
 * @returns
 */
export function onTitleStatusDialog(status) {
	switch (status) {
		case loadAlertStatus.Review:
			return 'loadAlerts.dialogsOptions.review'
		case loadAlertStatus.InProcess:
			return 'loadAlerts.dialogsOptions.inProcess'
		case loadAlertStatus.Finalized:
			return 'loadAlerts.dialogsOptions.finalized'
		case loadAlertStatus.Cancel:
			return 'loadAlerts.dialogsOptions.cancel'
		default:
			return ''
	}
}

/**
 * Get message to show in dialog
 * @returns
 */
export function onMessageStatusDialog(status) {
	switch (status) {
		case loadAlertStatus.Review:
			return 'loadAlerts.dialogsOptions.messageToSendToReview'
		case loadAlertStatus.InProcess:
			return 'loadAlerts.dialogsOptions.messageToSendToProcess'
		case loadAlertStatus.Finalized:
			return 'loadAlerts.dialogsOptions.messageToSendToFinalized'
		case loadAlertStatus.Cancel:
			return 'loadAlerts.dialogsOptions.messageToSendToCancel'
		default:
			return 'no options'
	}
}

export function AvailableOperationsConfiguration(
	status,
	onOpenChangeStatus,
	canBeSendToReview,
	canBeSendToProcess,
	canBeFinalized,
	canBeCancelLoadAlert
) {
	console.log('status', status)
	console.log('canBeSendToReview', canBeSendToReview)
	let columns = []
	if (canBeSendToReview && status === loadAlertStatus.Draft) {
		columns.push({
			value: loadAlertStatus.Review,
			title: <Translate id={onTitleStatusDialog(loadAlertStatus.Review)} />,
			onClick: () => onOpenChangeStatus(loadAlertStatus.Review),
			isIconMaterial: true,
			description: <Translate id={onMessageStatusDialog(loadAlertStatus.Review)} />,
		})
	}

	if (canBeSendToProcess && status === loadAlertStatus.Review) {
		columns.push({
			value: loadAlertStatus.InProcess,
			title: <Translate id={onTitleStatusDialog(loadAlertStatus.InProcess)} />,
			onClick: () => onOpenChangeStatus(loadAlertStatus.InProcess),
			isIconMaterial: true,
			description: <Translate id={onMessageStatusDialog(loadAlertStatus.InProcess)} />,
		})
	}
	if (canBeFinalized && status === loadAlertStatus.InProcess) {
		columns.push({
			value: loadAlertStatus.Finalized,
			title: <Translate id={onTitleStatusDialog(loadAlertStatus.Finalized)} />,
			onClick: () => onOpenChangeStatus(loadAlertStatus.Finalized),
			isIconMaterial: true,
			description: <Translate id={onMessageStatusDialog(loadAlertStatus.Finalized)} />,
		})
	}
	if (canBeCancelLoadAlert && status !== loadAlertStatus.Finalized) {
		columns.push({
			value: loadAlertStatus.Cancel,
			title: <Translate id={onTitleStatusDialog(loadAlertStatus.Cancel)} />,
			onClick: () => onOpenChangeStatus(loadAlertStatus.Cancel),
			isIconMaterial: true,
			description: <Translate id={onMessageStatusDialog(loadAlertStatus.Cancel)} />,
		})
	}

	return columns
}
