/**Import react section */
import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import {
    withStyles,
    Grid,
    Typography,
    CardHeader,
    Avatar,
    List
} from "@material-ui/core";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import SideBar from './SideBar'
/**Import components section*/
import BaseList from './BaseList'



const BiddingBaseLeftPanel = (props) => {
    const { classes, translate, bidding } = props;
    /**String props section */
    // const { } = props
    /**Bools props section */
    const { isSaving } = props
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onButtonCreateClick, onEditBaseItemClick, onDeletedBiddingSectionFromBackEnd } = props
    /**Objects props section */
    const { editedBiddingBase } = props
    
    const onDragEnd = (item) => {
        if(props.onUpdatePositionSection){
            props.onUpdatePositionSection(item)
        }
    }
    let orderSections = (bidding && bidding.Sections && bidding.Sections.length !== 0) ? bidding.Sections.sort(function (a, b) { return a.Index - b.Index }) : []
    return (
        <SideBar
            open={props.isOpenDrawer}
            handleDrawerOpenClose={props.handleDrawerOpenClose}
            onButtonCreateClick={onButtonCreateClick}
            sideBarChildren={
                <Grid container>
                    <Grid item xs={12}>
                        <CardHeader
                            avatar={
                                <Avatar className={classes.avatar} onClick={() => props.history.push(`/biddings/${bidding ? bidding.Id : ""}`)}>
                                    <KeyboardReturnIcon />
                                </Avatar>
                            }
                            title={
                                <Typography variant="button">{`${translate("biddingsModule.table.folio")}: ${bidding ? bidding.FormatedFolio : ""}`}</Typography>
                            }
                            subheader={
                                <>
                                    <Typography color='primary'>{translate("biddingsModule.create.projectName")}</Typography>
                                    <Typography className={classes.ellipsisSecondaryText}>{bidding ? bidding.Name : ""}</Typography>
                                </>

                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="characters" >
                                {(provided) => (
                                <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>

                                    <List
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                        className={classes.root}

                                    >
                                        {
                                            orderSections.map((base, index) => {
                                                return (
                                                    <Draggable
                                                        key={base.Id}
                                                        draggableId={base.Id}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                <BaseList
                                                                    base={base}
                                                                    onEditBaseItemClick={onEditBaseItemClick}
                                                                    onDeletedBiddingSectionFromBackEnd={onDeletedBiddingSectionFromBackEnd}
                                                                    isSaving={isSaving}
                                                                    isSelected={editedBiddingBase && (editedBiddingBase.Id === base.Id)}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })
                                        }
                                        {provided.placeholder}
                                    </List>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Grid>

                </Grid>}
        />
    )
};

const styles = (theme) => ({
    ellipsisSecondaryText: {
        maxWidth: "150px",
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    avatar: {
        marginLeft: "-10px",
        cursor: "pointer"
    },
    root: {
        maxWidth: "250px",
        maxHeight: `calc(100vh - ${325}px)`,
        minHeight: `calc(100vh - ${325}px)`,
        overflowY: "auto",
        overflowX: "hidden",
    },
});

BiddingBaseLeftPanel.propTypes = {
    bidding: PropTypes.object
};

BiddingBaseLeftPanel.defaultProps = {
    bidding: null
};

export default withRouter(
    withStyles(styles, { withTheme: true })(BiddingBaseLeftPanel)
);
