import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';


/** Material-UI imports section */
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Typography from "@material-ui/core/Typography";
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';

import Send from '@material-ui/icons/Send';

/** Import component section */
import DataTable from './DataTable';


/**
 * Reminder for an quote
 */
class Reminder extends Component {
  constructor (props) {
    super(props);
    autoBind(this);
    this.state = {
      comment: "",
      }

  }

    /**
     * Function to add Comment
     * @param {*} comment 
     */
    updatetemProperty(comment) {
      this.setState({
        comment: comment
      })
  }

    /**
     * onSave
     */
    onSaveItem() {
      if (this.state.comment && this.state.members) {
          if(this.props.onSaveItem){
              this.props.onSaveItem(this.state.comment);
          }
          this.setState({comment: ""})
      }
  }

  render () {
    const { classes } = this.props;
    return (
        <Card>
             <CardHeader
                title={<Typography variant="button" ><Translate id="common.sendReminderToSupplierTitle"/></Typography>}
                action = {
                  <div className={classes.actionsToolbar}>{
                          <span>
                              <Tooltip title={<Translate id="common.sendReminderToSupplierTitle"/>} ><IconButton color="primary"
                              data-cy="btn-edit"
                              className={classes.edit}
                              onClick={()=>this.props.onSendReminderToSupplier(this.state.comment)}>
                              <Send />
                              </IconButton>
                              </Tooltip>
                          </span>
                      }
                  </div>
              }
              />
              
              <Grid container spacing={24} style={{display:"flex"}}>
                <Grid item xs={6}>
                    <CardContent>
                      <TextField fullWidth required
                        label={<Translate id="common.messageToSupplier"/>}
                        className={classes.textField}
                        multiline
                        rows="3"
                        defaultValue={this.state.comment}
                        onChange={(event) => { this.updatetemProperty(event.target.value) }}
                        margin="normal"
                        variant="outlined"
                        />
                    </CardContent>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom variant="body2" component="p">
                    <strong>
                        <Translate id="common.sendReminderToSupplier" />:
                    </strong>
                  </Typography>
                  {this.props.workflow && this.props.workflow !==null &&
                  <DataTable
                    data={this.props.workflow.users_waiting_next_action}
                    configuration={{
                        columns: [{
                            header: <Translate id="users.name"/>,
                            dataSource: 'name',
                        }]
                    }}
                  />}
                </Grid>
          </Grid>
        </Card>
        
    )
  }
}

const reminderStyles = theme => ({
  textField: {
      marginTop: 10,
      marginBottom: 10
  },
  actionsToolbar: {
      display: "flex"
  },
});

export default withStyles(reminderStyles, { withTheme: true })(Reminder);