///Import react section
import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
/** Import component section */
import Toaster from '../common/Toaster';
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog';
import DialogOperationContainer from '../common/DialogOperations/DialogOperationContainer';
/**import helpers section */
/**Custom hooks index */
import { useExpedientSupplierCustomHookShow } from './customHooks/UseCustomHookShow';
import { ExpedientSupplierStatus } from '../../store/helpers/AppConstants';

const ShowEdit = (props) => {
	const { onCloseToaster, onSetStatusExpedientSupplier, onLoadExpedientSupplier } = useExpedientSupplierCustomHookShow();
	/**String props section */
	/**Bools props section */
	//const { } = props
	/**Arrays props section */
	const { availableOperations } = props
	/**Functions props section */
	const { onConfirmPassword, openAvailableOperationsDialog } = props;

	/**Objects props section */
	const { confirmPasswordConfigModal } = props;
	/**Render hooks section */
	/**Redux store information section*/
	const toasterMessageConfig = useSelector((state) => state.indirectQualifications.get('toasterConfiguration').toJS());
	const expedientSupplier = useSelector((state) =>
		state.expedientSuppliers.get('expedientSupplier') ? state.expedientSuppliers.get('expedientSupplier').toJS() : null
	);
	const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'));
	
	const onCancelFolio = (confirmationModel) => {
		onSetStatusExpedientSupplier(expedientSupplier, ExpedientSupplierStatus.CANCEL_IN_PLANT, confirmationModel.comments).then(
			(response) => {
				if (response === true) {
					onLoadExpedientSupplier(expedientSupplier.token)
					onConfirmPassword();
				}
			}
		);
	};

	const onSendToSupplierFolio = () => {
		onSetStatusExpedientSupplier(expedientSupplier, ExpedientSupplierStatus.SEND_SUPPLIERS).then(
			(response) => {
				if (response === true) {
					onLoadExpedientSupplier(expedientSupplier.token)
					onConfirmPassword();
				}
			}
		);
	};
	
	const getMessageByIdentifier = () => {
		switch (confirmPasswordConfigModal.identifier) {
			case ExpedientSupplierStatus.CANCEL_IN_PLANT:
				return "expedientSupplier.operationsAvailable.messageCancelFolio"
			case ExpedientSupplierStatus.SEND_SUPPLIERS:
				return "expedientSupplier.operationsAvailable.messageSentToSupplierMessage"
			default:
				return '';
		}
	};
	const getCallBackToInvokeByIndentifier = () => {
		switch (confirmPasswordConfigModal.identifier) {
			case ExpedientSupplierStatus.CANCEL_IN_PLANT:
				return onCancelFolio;
			case ExpedientSupplierStatus.SEND_SUPPLIERS:
				return onSendToSupplierFolio
			default:
				return '';
		}
	};

	const getIsRequiredComments = () => {
		switch (confirmPasswordConfigModal.identifier) {
			case ExpedientSupplierStatus.CANCEL_IN_PLANT:
				return true;
			default:
				return false;
		}
	};


	const { DialogOperations, onOpenAndClose, isOpen } = DialogOperationContainer({
		options: availableOperations
	});

	useEffect(() => {
		if (openAvailableOperationsDialog) {
			onOpenAndClose();
		}
		if (isOpen === false) {
			props.onOpenAndClose(false);
		}
	}, [openAvailableOperationsDialog, isOpen]);

	return (
		<>
			{confirmPasswordConfigModal && confirmPasswordConfigModal.opened && (
				<ConfirmPasswordDialog
					open={confirmPasswordConfigModal.opened}
					title={<Translate id="common.confirmOperation" />}
					message1={<Translate id={getMessageByIdentifier()} />}
					message2={<Translate id="common.enterPassword" />}
					onClickButtonConfirm={getCallBackToInvokeByIndentifier()}
					onClose={onConfirmPassword}
					isSaving={isSaving}
					isVisibleComments={getIsRequiredComments()}
					commentRequired={getIsRequiredComments()}
				/>
			)}

			<DialogOperations />

			<Toaster
				message={
					<Translate
						id={toasterMessageConfig.toasterMessage}
						data={{ name: toasterMessageConfig ? toasterMessageConfig.name : '' }}
					/>
				}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => onCloseToaster()}
			/>
		</>
	);
};

export default ShowEdit;
