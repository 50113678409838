/**Import react section */
import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";
/**Import MaterialUi section */
import {
  Grid,
  Avatar,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Button,
} from "@material-ui/core";
import ViewCarouselIcon from "@material-ui/icons/SupervisedUserCircle";
/**Import components section */
import LateralPanel from "../../../common/LateralPanelComponents/LateralPanel";
import PanelHeader from "../../../common/LateralPanelComponents/PanelHeader";
import HtmlEditor from "../../../common/HtmlEditor";
import { intRound } from "../../../../store/helpers/AmountHelper";
import { IsNullOrEmpty } from "../../../../store/helpers/StringHelper";

const AddResponseLateraPanel = (props) => {
  /**props section */
  const { onCloseButtonClick, isSaving, classes, translate, item } = props;
  const [errors, setErrors] = useState({});
  const [value, setValue] = useState("");
  const [comments, setComments] = useState("");

  /**Use effect section */
  useEffect(() => {
    if (props.item && value === "") {
      setComments(props.item.comments);
      if (item.evaluatedWeighing !== 0) {
        let defaultValue = item
          ? item.evaluatedWeighing
            ? intRound(item.evaluatedWeighing * 100)
            : ""
          : "";
        setValue(defaultValue);
      } else if (item.evaluatedWeighing === 0) {
        setValue(item.evaluatedWeighing);
      }
    }
  }, [props.item]);

  const onChangeResponseResult = (weighing, event) => {
    let value = event.target.value;
    const errors = {};
    let weighingPercentage = weighing * 100;
    if (value > weighingPercentage) {
      errors.weighing = `${translate("biddingsModule.criteriaValuePonderation")} ${weighingPercentage}`;
      errors.supplierCode = item.supplierCode;
      errors.evaluationId = item.evaluationId;
    }
    setErrors(errors);
    setValue(value);
  };

  const onSave = () => {
    if (
      props.addEvaluationResponsible &&
      value !== "" &&
      Object.entries(errors).length === 0
    ) {
      props.addEvaluationResponsible(item, value, true);
    }
  };

  /**Verify if can be sabed data*/
  const canBeSaved = () => {
    if (IsNullOrEmpty(value)) return false;
    return true;
  };

  return (
    <LateralPanel onClose={onCloseButtonClick}>
      <PanelHeader
        titlePanel={
          <Typography variant="subtitle2" className={classes.textNoWrap}>
            {item.supplierName ? item.supplierName : ""}{" "}
          </Typography>
        }
        subTitlePanel={`${translate("biddingsModule.table.folio")} | ${
          props.bidding ? props.bidding.FormatedFolio : ""
        }`}
        onCloseButtonClick={onCloseButtonClick}
        icon={
          <Avatar className={classes.avatar}>
            <ViewCarouselIcon />
          </Avatar>
        }
        isIconMaterial={true}
      />
      <div>
        <div className={classes.containerInformation}>
          <Grid container>
            <Grid item xs={12} className={classes.titleDisplay}>
              <Typography variant="subtitle1" color="primary">
                {item ? item.nombreCriteria : ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <HtmlEditor
                id={"id-modeCreate"}
                labelVariant="subtitle1"
                initialValue={item ? item.result : ""}
                isDisabled={true}
              />
            </Grid>
            {comments && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="primary">
                {translate("biddingsModule.commentsFromSupplier")}
                </Typography>
                <Typography>{comments}</Typography>
              </Grid>
            )}
          </Grid>
        </div>

        <Grid container className={classes.footer}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} className={classes.containerBtn}>
            <Typography
              color="primary"
              variant="subtitle2"
              className={classes.marginTop}
            >
              {translate("biddingsModule.assignedPonderation")}
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.containerBtn}>
            <TextField
              fullWidth
              label={translate("biddingsModule.ponderation")}
              name="name"
              type="number"
              margin="normal"
              variant="outlined"
              step={0.5}
              onChange={(event) => onChangeResponseResult(item.weighing, event)}
              disabled={isSaving}
              defaultValue={value}
              helperText={errors.weighing ? errors.weighing : ""}
              error={
                Object.entries(errors).length !== 0 &&
                errors.weighing &&
                errors.supplierCode === item.supplierCode &&
                errors.evaluationId === item.evaluationId
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                classes: { input: classes.inputProps },
              }}
            />
          </Grid>
          <Grid item xs={4} className={classes.containerBtn}>
            <Button
              data-cy="btn-save"
              type="button"
              color="primary"
              variant="outlined"
              onClick={onSave}
              className={classes.btnSave}
              disabled={!canBeSaved() || isSaving}
            >
              {<Translate id="common.save" />}
            </Button>
          </Grid>
        </Grid>
      </div>
    </LateralPanel>
  );
};

const styles = (theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  containerInformation: {
    maxHeight: `calc(100vh - ${230}px)`,
    minHeight: `calc(100vh - ${230}px)`,
    overflowY: "auto",
    padding: "20px",
  },
  containerBtn: {
    display: "flex",
    justifyContent: "center",
    alingSeft: "center",
  },
  footer: {
    paddingLeft: "10px",
    paddingRight: "10px",
    // backgroundColor: theme.palette.secondary.main
  },
  titleDisplay: {
    display: "flex",
  },
  inputProps: {
    //height: 8,
    fontSize: 12,
  },
  marginTop: {
    marginTop: "10px",
  },
  textNoWrap: {
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  btnSave: {
    maxHeight: "50px",
    marginTop: "15px",
  },
});

AddResponseLateraPanel.propTypes = {
  /**
   * callback executed add new user in the list
   */
  onSaveButtonClick: propTypes.func,
  /**
   * open and closedlateral panel for add an new user in customer
   */
  onCloseButtonClick: propTypes.func,
  /**
   * Determinate if is saving data
   */
  isSaving: propTypes.bool.isRequired,
};

AddResponseLateraPanel.defaultProps = {
  onSaveButtonClick: () =>
    console.warn("Callback [onSaveButtonClick] no defined"),
  onCloseButtonClick: () =>
    console.warn("Callback [onCloseButtonClick] no defined"),
  isSaving: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(AddResponseLateraPanel)
);
