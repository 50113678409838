/**Import react section */
import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
/**Import MaterialUi section */
import { Grid, Avatar, TextField, Button, Typography } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
/**Import components section */

/**Import store section */

import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'
import Evidence from '../../common/Evidence'
import { COMPANY_PTM } from '../../../store/helpers/ResourcesHelper'
import DialogProspect from './DialogProspect'

const RegistrationSupplier = (props) => {
	/**props section */
	const { onCloseButtonClick, classes, token, uploadFiles, prospect, removeFiles, onSave, isSaving } = props

	const [values, setValues] = useState({ feedback: '', emails: '' })
	const [open, setOpen] = useState(false)

	const onPropertyChange = (event) => {
		let { value, name } = event.target
		setValues((prev) => ({ ...prev, [name]: value }))
	}

	const handleOpen = () => {
		setOpen(!open)
	}

	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={
					<Typography color='primary'>
						<Translate id='prospects.supplierRegistration.supplierRegistration' />
					</Typography>
				}
				onCloseButtonClick={onCloseButtonClick}
				icon={
					<Avatar className={classes.avatar}>
						<FolderIcon />
					</Avatar>
				}
				isIconMaterial={true}
			/>
			<div className={classes.bodyContent}>
				<div className={classes.margin}>
					<div>
						<Typography variant='h6' color='primary' className={classes.titles}>
							<Translate id='prospects.supplierRegistration.sendConfirmation' />
						</Typography>

						<Typography variant='subtitle1' color={'textSecondary'} className={classes.titles}>
							<Translate id='prospects.supplierRegistration.additionalFiles' />
						</Typography>
					</div>

					<Grid item xs={12} className={classes.marginTop}>
						<Evidence
							text={<Translate id='dropzone.dropzoneBase' />}
							baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
							removeFiles={(files) => removeFiles(files)}
							uploadFiles={(files) => uploadFiles(token, files)}
							files={prospect.send_prospectus_download_files}
							disabledLink
						/>
					</Grid>

					<Grid item xs={12} className={classes.marginTop}>
						<Typography variant='subtitle1' color={'textSecondary'} className={classes.titles}>
							<Translate id='prospects.supplierRegistration.additionalEmails' />
						</Typography>
						<TextField
							fullWidth
							label={'Correo'}
							className={classes.textField}
							onChange={onPropertyChange}
							name='emails'
							margin='normal'
							variant='outlined'
							helperText={
								'Para enviar más de dos correos debe agregar la información como se muestra en el ejemplo: corre@correo.com,correo2correo.com'
							}
							multiline
						/>
						<Typography variant='subtitle1' color={'textSecondary'} className={classes.titles}>
							<Translate id='prospects.supplierRegistration.welcomeMessage' /> <br />
						</Typography>
						{prospect.company_code === COMPANY_PTM && (
							<Button color='primary' size='small' onClick={handleOpen}>
								Ver mensaje de alta proveedor
							</Button>
						)}
						<TextField
							fullWidth
							label={'Mensaje de bienvenida'}
							className={classes.textField}
							onChange={onPropertyChange}
							name='feedback'
							margin='normal'
							variant='outlined'
							multiline
							rows={4}
						/>
					</Grid>
					<Grid container>
						{prospect.company_code === COMPANY_PTM ? (
							<Grid item xs={12} className={classes.containerBtn}>
								<Button
									data-cy='btn-save'
									type='button'
									color='primary'
									variant='outlined'
									onClick={() => onSave(token, values)}
									disabled={isSaving}
								>
									<Translate id='prospects.supplierRegistration.supplierConfirmation' />
								</Button>
							</Grid>
						) : (
							<Grid item xs={12} className={classes.containerBtn}>
								<Button
									data-cy='btn-save'
									type='button'
									color='primary'
									variant='outlined'
									onClick={() => onSave(token, values)}
									disabled={values.feedback === '' || isSaving}
								>
									<Translate id='prospects.supplierRegistration.supplierConfirmation' />
								</Button>
							</Grid>
						)}
					</Grid>
				</div>
				{open && <DialogProspect open={open} handleOpen={handleOpen} prospect={prospect} />}
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.primary.main,
	},

	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputProps: {
		height: 6,
	},
	titles: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: '7px',
		marginBottom: 5,
	},
	bodyContent: {
		overflowX: 'auto !important',
	},
	margin: {
		margin: '15px 15px 15px 15px',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(RegistrationSupplier))
