import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import { Fab, Tooltip, withStyles } from '@material-ui/core'
import FilterAltTwoToneIcon from '@material-ui/icons/Sort'

const ButtonQuickFilters = (props) => {
	const { disabled, classes, onClick } = props
	return (
		<Tooltip title={<Translate id='common.quickFiltering' />}>
			<div>
				<Fab
					size='small'
					variant='round'
					color='primary'
					disabled={disabled}
					onClick={onClick}
					className={classes.root}    
				>
					<FilterAltTwoToneIcon />
				</Fab>
			</div>
		</Tooltip>
	)
}

ButtonQuickFilters.propTypes = {
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
}

ButtonQuickFilters.defaultProps = {
	disabled: false,
	onClick: () => console.warn('[onClick] method from ButtonQuickFilters is undefined'),
}

const styles = (theme) => ({
	root: {
		height: 35,
		width: 35,
	},
})

export default withStyles(styles, { withTheme: true })(ButtonQuickFilters)
