/** React imports section */
import React from 'react'
import { withRouter } from 'react-router-dom'

/** Material UI imports section */
import { Tab, Tabs, withStyles, Typography, Badge, Chip } from '@material-ui/core'
import PlaceIcon from '@material-ui/icons/PlaceTwoTone'
import ProspectSupplierIncon from '@material-ui/icons/LocalShipping'
import AddIcon from '@material-ui/icons/AddTwoTone'
import NewReleasesIcon from '@material-ui/icons/NewReleases'

/** common imports section */
import { Translate } from 'react-localize-redux'
import { TransportTendeStatus } from '../../../store/helpers/AppConstants'

const DetailTabs = (props) => {
	const { classes, setActiveTab, activeTab, setOpenPanel, isInternalUser, transportTenderdetail } = props
	const handleChange = (event, newValue) => {
		setActiveTab(newValue)
	}
	return (
		<div>
			<div className={classes.tabContainer}>
				<Tabs value={activeTab} onChange={handleChange} aria-label='Tabs to show services or configuration'>
					<Tab
						style={{
							height: '5rem',
						}}
						label={
							<div>
								<div>
									<Badge
										badgeContent={
											transportTenderdetail && transportTenderdetail.Destinations
												? transportTenderdetail.Destinations.length
												: 0
										}
										color='primary'
									>
										<PlaceIcon color={activeTab === 0 ? 'primary' : 'inherit'} />
									</Badge>
								</div>
								<div>
									<Translate id={'carrierTenders.deals.dealsTitle'} />
								</div>
							</div>
						}
					/>
					<Tab
						label={
							<div>
								<div>
									<ProspectSupplierIncon color={activeTab === 1 ? 'primary' : 'inherit'} />
								</div>
								<div>
									<Translate id={'carrierTenders.carriers.carriersTitle'} />
								</div>
							</div>
						}
					/>
					{isInternalUser &&
						transportTenderdetail.Status !== TransportTendeStatus.DRAFT &&
						transportTenderdetail.Proposals.filter((x) => x.Status === 2).length > 0 && (
							<Tab
								label={
									<div>
										<div>
											<Badge
												badgeContent={
													transportTenderdetail && transportTenderdetail.Proposals
														? transportTenderdetail.Proposals.filter((x) => x.Status === 2)
																.length
														: 0
												}
												color='primary'
											>
												<NewReleasesIcon color={activeTab === 2 ? 'primary' : 'inherit'} />
											</Badge>
										</div>
										<div>
											{' '}
											<Translate id={'carrierTenders.resultsTender.resultsTendertitle'} />
										</div>
									</div>
								}
							/>
						)}
				</Tabs>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<div className={classes.totalContent}>
						<Typography className={classes.textTotal} variant='subtitle2'>
							<Translate id={'carrierTenders.deals.totalEquipment'} />:
						</Typography>
						<Chip className={classes.totalChip} label={transportTenderdetail.TotalMaterial} />
					</div>
					{activeTab === 0 &&
						isInternalUser &&
						(transportTenderdetail.Status === TransportTendeStatus.DRAFT ||
							transportTenderdetail.Status === TransportTendeStatus.SENT_TO_CARRIER) && (
							<div className={classes.addIconContainer}>
								<Typography variant='body2' display='block'>
									<Translate id={'carrierTenders.deals.dealsTitle'} />
								</Typography>{' '}
								<AddIcon
									className={classes.addIcon}
									onClick={() => setOpenPanel({ open: true, isEdit: false, deal: null })}
								/>
							</div>
						)}
				</div>
			</div>
		</div>
	)
}
const styles = (theme) => ({
	addIcon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		borderRadius: '16px',
		margin: '0px 5px 0px 10px',
	},
	addIconContainer: {
		gridColumnStart: '2/3',
		display: 'flex',
		alignItems: 'center',
		marginRight: '8px',
	},
	tabContainer: {
		display: 'grid',
		gridTemplateColumns: '10fr 1fr ',
	},
	textTotal: {
		whiteSpace: 'nowrap',
		color: '#757575',
	},
	totalContent: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '8px',
		borderRadius: '30px',
		border: 'solid 1px',
		height: '2rem',
		width: '200px',
		color: '#757575',
		marginLeft: '8px',
		marginRight: '8px',
	},
	totalChip: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		height: '1rem',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(DetailTabs))
