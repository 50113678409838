import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import moment from 'moment'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import WarrantyQualityIcon from '@material-ui/icons/VerticalSplit'; 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DialogActions from '@material-ui/core/DialogActions';

import SuppliersSelector from '../common/SupplierSelector';

import {createWarrantyReportPtmFromBackEnd} from '../../store/warrantyReportsPtm/warrantyReportPtmAction';

/**
 * Component to create corrective action supplier
 */
class Create extends Component{

     /**
     * Create an instance ofMemos
     * @param {*} props 
     */
    constructor(props){
        super(props);
        autoBind(this);
        this.state = {
            creationDate: moment().format('DD/MM/YYYY'),
            supplierSelected: null,
            description: "",
        }
    }

    /**
     * On seletecd supplier
     */
    onSupplierSelected(supplier) {
        this.setState({ supplierSelected: supplier });
    }

    /**
        * Update the property of the warranty
        *
        * @param {*} description
        */
    updateProperty(description) {
        this.setState({
            description: description
        })
    }

    /**
     * Can be add item in warranty report
     */
    canBeSaved() {
        if (this.state.supplierSelected === null) return false;
        if (this.state.description === "")
            return false;
        return true;
    }

    /**
     * Get action to create WarrantyReportPtm
     */
    createWarrantyReportPtm(){
        this.props.createWarrantyReportPtm(this.state.supplierSelected, this.state.description)
        .then((warrantyReport) =>{
            this.setState({
                showToView:true,
                showToaster: false
            })
            this.props.history.push(`/warrantyReportsPtm/${warrantyReport.token}`)
        }).catch(() =>{
            this.setState({
                showToaster:true,
                toasterMessage: <Translate id="common.errorToSave"/>,
                toasterVariant: "error",
            })
        });
    }

    render(){
        const { classes} = this.props;
        return(
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <WarrantyQualityIcon />
                        </Avatar>
                    }
                    title={<Typography variant="button" >{<Translate id="warrantyReportsPtm.reportWarranty" />}</Typography>}
                    subheader={<Typography variant="caption" color="textPrimary"><Translate id="common.createBy"/>: {this.props.user.profile.name}
                    <br/><Translate id="common.creationDate"/>: {this.state.creationDate}</Typography>}
                />
                <CardContent>
                    <Grid container spacing={24} autoComplete="off">
                        <Grid item xs={6}>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom><Translate id="common.supplier"/> *</Typography>
                                    <SuppliersSelector
                                        className={classes.textField}
                                        onSupplierSelected={(supplier) => { this.onSupplierSelected(supplier) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={<span>{<Translate id="warrantyReportsPtm.motiveWarranty" />} *</span>}
                                        multiline
                                        rows="5"
                                        value={this.state.description}
                                        onChange={(event) => { this.updateProperty(event.target.value) }}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <DialogActions>
                                        <Button
                                            data-cy="btn-save"
                                            type="button"
                                            color="primary"
                                            onClick={this.createWarrantyReportPtm}
                                            disabled={!this.canBeSaved() || this.props.isSaving}>
                                            {<Translate id="common.save" />}
                                        </Button>
                                        <Button onClick={() => { this.props.history.push(`/warrantyReportsPtm`) }} color="secondary">
                                            {<Translate id="common.cancel" />}
                                        </Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state){
    let user = state.oidc.user ? state.oidc.user: { profile: { name: "", email:"", user_type: ""  } };
    let selectedCompany = state.profile.get('selectedCompany');
    return {
        user: user,
        isSaving: state.warrantyReports.get('isSaving'),
        selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        createWarrantyReportPtm:(supplier, description) =>{
            return dispatch(createWarrantyReportPtmFromBackEnd(supplier, description))
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});



export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme:true })(connect(mapStateToProps, mapDispatchToProps)(Create))));