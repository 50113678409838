export const SecurityCourseStatusEnum = {
	New: 0,
	InProcess: 1,
	Accepted: 2,
	Finalized: 3,
}
export const descriptionSecurityCoursesStatus = (status) => {
	switch (Number(status)) {
		case SecurityCourseStatusEnum.New:
			return 'Nuevo'
		case SecurityCourseStatusEnum.InProcess:
			return 'Reprovado'
		case SecurityCourseStatusEnum.Accepted:
			return 'Aceptado'
		case SecurityCourseStatusEnum.Finalized:
			return 'Expirado'
		default:
			return ''
	}
}
export const FolderName = {
	SECURITY_CURSES_VIDEO: 'Cursos',
	CERTIFICATION: 'Certifications'
}