import React, { PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

/** Material-UI imports section */
import Fab from '@material-ui/core/Fab';

/**
 *Class to create RegisterDeliveryButton component
 *
 * @class RegisterDeliveryButton
 * @extends {RegisterDeliveryButton}
 */
export default class RegisterDeliveryButton extends PureComponent {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.stickyDiv}>
                <Fab
                    variant="extended"
                    size="small"
                    color="secondary"
                    className={classes.margin}
                    onClick={() => this.props.open()}
                    data-cy="btn-available-operations"
                >
                   
                    {<Translate id="deliveryOrderModule.registerArraveFolio"/>}
                </Fab>
            </div>
        )
    }
}

RegisterDeliveryButton.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.func.isRequired, //Function to set state of modal (open/close)
};