import React from 'react';
import { Translate } from 'react-localize-redux';
import propTypes from 'prop-types';

import {
	Typography,
	Divider,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Button,
	withStyles,
	Chip
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';

/**
 * Service type list
 * @param {*} props
 * @returns
 */
const ServiceStatusInSection = (props) => {
	const { classes, onEditButtonClick, serviceStatusInSection } = props;

	const getStatusLabel = (code) => {
		const supplier = allServiceOrderStatus.find((s) => s.value === code);
		return supplier ? supplier.label : `${code} - Desconocido`;
	};

	return (
		<div>
			<div className={classes.alertInfo}>
				<InfoIcon size="small" />{' '}
				<Translate id="accreditedWorkshops.serviceStatusInSection.serviceStatusInSectionInfo" />
			</div>
			<div className={classes.header}>
				<Typography variant="subtitle1" color="primary" fontWeight={500}>
					<Translate id="accreditedWorkshops.setting.serviceStatusInSection" />
				</Typography>
				<Button variant="contained" color="primary" onClick={onEditButtonClick}>
					<EditIcon className={classes.leftIcon} />
					<Translate id="common.edit" />
				</Button>
			</div>
			<Divider />
			<div>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography variant="subtitle2" color="primary" fontWeight={500}>
									<Translate id="accreditedWorkshops.serviceStatusInSection.sections" />
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="subtitle2" color="primary" fontWeight={500}>
									<Translate id="accreditedWorkshops.serviceStatusInSection.showedStatus" />
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								<Typography variant="body2">Servicios Liberados</Typography>
							</TableCell>
							<TableCell>
								{serviceStatusInSection
									.filter((i) => i.SectionName === 'ReleasedServiceOrders')
									.map((i) => (
										<Chip
											key={i.ServiceOrderStatus}
											style={{ marginLeft: 5 }}
											label={getStatusLabel(i.ServiceOrderStatus)}
										/>
									))}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Typography variant="body2">Servicios No Liberados</Typography>
							</TableCell>
							<TableCell>
								{serviceStatusInSection
									.filter((i) => i.SectionName === 'NotReleasedServiceOrders')
									.map((i) => (
										<Chip
											key={i.ServiceOrderStatus}
											style={{ marginLeft: 5 }}
											label={getStatusLabel(i.ServiceOrderStatus)}
										/>
									))}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</div>
		</div>
	);
};

ServiceStatusInSection.propTypes = {
	serviceStatusInSection: propTypes.arrayOf(
		propTypes.shape({
			ServiceOrderStatus: propTypes.string.isRequired,
			SectionName: propTypes.string.isRequired
		})
	).isRequired,
	onEditButtonClick: propTypes.func.isRequired
};
ServiceStatusInSection.defaultProps = {
	serviceStatusInSection: [],
	onEditButtonClick: () => {}
};

const styles = (theme) => ({
	header: {
		padding: 8,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	leftIcon: {
		marginRight: theme.spacing.unit
	},
	alertInfo: {
		margin: '10px 0px',
		padding: '10px 7px 10px 40px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '10px center',
		fontSize: '13px',
		textAlign: 'left',
		width: 'auto',
		color: '#00529B',
		backgroundColor: '#BDE5F8'
	}
});

const allServiceOrderStatus = [
	{ value: '10', label: '10 - Nuevo' }, //New = ;
	{ value: '11', label: '11 - Aceptado' }, //Accepted = ;
	{ value: '12', label: '12 - Rechazado' }, //Rejected = ;
	{ value: '13', label: '13 - Enviado a SAP' }, //SentToSAP = ;
	{ value: '14', label: '14 - En pedido' }, //Request = ;
	{ value: '15', label: '15 - Facturado' }, //Invoiced = ;
	{ value: '16', label: '16 - Con error' }, //Error = ;
	{ value: '17', label: '17 - Incobrable' }, //Uncollectible = ;
	{ value: '27', label: '27 - Cerrado' }, //Closed = ;
	{ value: '20', label: '20 - Archivado' }, //Archived = ;
	{ value: '51', label: '51 - Factura cancelada' } //CanceledInvoice = ;
];

export default withStyles(styles, { withTheme: true })(ServiceStatusInSection);
