/** Helpers import section */
import axios from 'axios';
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';


export const IS_SAVING_CUSTOMER_GUARANTEE = "IS_SAVING_CUSTOMER_GUARANTEE";
export const ADD_CUSTOMER_GUARANTEE_TO_LIST = "ADD_CUSTOMER_GUARANTEE_TO_LIST";
export const SET_CUSTOMER_GUARANTEE_TO_LIST = "SET_CUSTOMER_GUARANTEE_TO_LIST";
export const START_LOADING_CUSTOMER_GUARANTE = "START_LOADING_CUSTOMER_GUARANTE";
export const CHANGE_CUSTOMER_GUARANTEE_SORT_QUERY = "CHANGE_CUSTOMER_GUARANTEE_SORT_QUERY";
export const SET_CUSTOMER_GUARANTEE_COUNT = "SET_CUSTOMER_GUARANTEE_COUNT";
export const CHANGE_CUSTOMER_GUARANTEE_SORT_CRITERIA = "CHANGE_CUSTOMER_GUARANTEE_SORT_CRITERIA";
export const REMOVE_CUSTOMER_GUARANTEE = "REMOVE_CUSTOMER_GUARANTEE";


/**
 * Action to set flag isSaving 
 *
 * @param {*} isSaving
 * @returns
 */
function isSavingCustomerGuarantee(isSaving) {
    return {
        type: IS_SAVING_CUSTOMER_GUARANTEE,
        isSaving
    }
}

/**
 * Action start loading customer guarantee
 * @param {*} isLoading 
 */
function isLoading(isLoading) {
    return {
        type: START_LOADING_CUSTOMER_GUARANTE,
        isLoading
    }
}

/**
 *Action add customer guarantee to list
 *
 * @param {*} customerGuaranteeData
 * @returns
 */
function setCustomerGuaranteeList(customerGuaranteeData) {
    return {
        type: SET_CUSTOMER_GUARANTEE_TO_LIST,
        customerGuaranteeData
    }
}

/**
 * Action to add customer guarantee to the list guarantees
 *
 * @param {*} customerGuarantee
 * @returns
 */
function addCustomerGuaranteeToList(customerGuarantee) {
    return {
        type: ADD_CUSTOMER_GUARANTEE_TO_LIST,
        customerGuarantee
    }
}

/**
 * Action to set customer guarantee count
 */
function setCustomerGuaranteeCount(count) {
    return {
        type: SET_CUSTOMER_GUARANTEE_COUNT,
        count
    }
}

/**Action from remove a customer Guarantee to list */
function removerCustomerGuaranteeToList(tokenGuarantee){
    return{
        type: REMOVE_CUSTOMER_GUARANTEE,
        tokenGuarantee: tokenGuarantee
    }
}


/**
 * Action to add customer to guarantee
 *
 * @export
 * @param {*} customerGuarantee
 * @returns
 */
export function addCustomerGuaranteeData(customerGuarantee) {

    return function (dispatch, getState) {
        dispatch(isSavingCustomerGuarantee(true));
        let selectedCompanyToken = getState().profile.get("selectedCompany").get('token');
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/customerguarantee`;
        let data = {
            Name: customerGuarantee.name,
            NameEnglish: customerGuarantee.name_english,
            NamePortuguese: customerGuarantee.name_portuguese,
            CompanyToken: selectedCompanyToken,
        }
        return axios.post(endPoint, data)
            .then((response) => {
                dispatch(addCustomerGuaranteeToList(response.data))
            })
            .catch((error) => {
                console.error("Error to add customer guarantee to list", error.response);
                dispatch(isSavingCustomerGuarantee(false));
                return Promise.reject();
            })
    }
}


/**
 * Action to get a list of customer guarantee catalog by filters
 */
export function loadCustomerGuaranteeFromBackEnd(query, orderBy, sortAscending) {
    return function (dispatch, getState) {

        dispatch(isLoading(true));
        let selectedCompany = getState().profile.get("selectedCompany");

        if(selectedCompany)
        {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/customerguarantee/filters`;
            let data = {
                CompanyToken: selectedCompany.get("token"),
                SortBy: orderBy,
                SortAscending: sortAscending,
                Query: query,
                Page: 1,
                RowsPerPage: 50,
            }
            return axios.post(endPoint,  data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setCustomerGuaranteeList(serverResponse.data))
                    }
                })
                .catch((error) => {
                    dispatch(isLoading(false));
                    console.error("Error getting the customer guarantee  list", error.response)
                })
        }
        else{
            dispatch(isLoading(false));

        }
    }
}

/**
 *Action to get customer guarantee count
 *
 * @export
 * @returns
 */
export function getCustomerGuaranteeCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().customerGuarantee ? getState().customerGuarantee.get('customerGuaranteeCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        if(selectedCompany)
        {
            let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/customerguarantee/count`;
            let data ={
                CompanyToken: selectedCompany.get("token"),
            }
            return axios.post(endPoint, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setCustomerGuaranteeCount(serverResponse.data))
                    }
                })
                .catch((error) => {
                    console.error("Error getting the customer guarantee count", error.response)
                })
        }
    }
}

/**
 * Action to remove guarantee customer
 * @param {*} company 
 */
export function onRemoveCustomerGuaranteeFromBackEnd(token) {
    return function (dispatch, getState) {

        dispatch(isSavingCustomerGuarantee(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/customerguarantee/${token}`;

        return axios.delete(endPoint, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isSavingCustomerGuarantee(false));
                    dispatch(removerCustomerGuaranteeToList(token));
                }
                else{
                    dispatch(isSavingCustomerGuarantee(false));
                    return Promise.reject(response);

                }
            })
            .catch((error) => {
                dispatch(isSavingCustomerGuarantee(false));
                return Promise.reject(error.response);
            })
    }
}

/**
 * Action to update customer to guarantee
 *
 * @export
 * @param {*} customerGuarantee
 * @returns
 */
export function updateCustomerGuaranteeData(customerGuarantee) {

    return function (dispatch, getState) {
        dispatch(isSavingCustomerGuarantee(true));
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/customerguarantee`;
        let data = {
            TokenCustomer: customerGuarantee.token,
            Name: customerGuarantee.name,
            NameEnglish: customerGuarantee.name_english,
            NamePortuguese: customerGuarantee.name_portuguese,
        }
        return axios.put(endPoint, data)
            .then((response) => {
                dispatch(isSavingCustomerGuarantee(false));
            })
            .catch((error) => {
                console.error("Error to update failure guarantee", error.response);
                dispatch(isSavingCustomerGuarantee(false));
                return Promise.reject();
            })
    }
}




