///Import react section
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import AddCommonItem from '../common/AddCommonItem'
import DialogEditCustomerGuarantee from './components/DialogEditCustomerGuarantee'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'

/** Material-UI imports Icons */
import CustomerGuaranteeIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Tooltip } from '@material-ui/core'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'
import DialogAddGuarantee from '../common/DialogAddGuarantee'
import Toaster from '../common/Toaster'

/**Import actions section*/
import {
	addCustomerGuaranteeData,
	loadCustomerGuaranteeFromBackEnd,
	CHANGE_CUSTOMER_GUARANTEE_SORT_QUERY,
	getCustomerGuaranteeCount,
	CHANGE_CUSTOMER_GUARANTEE_SORT_CRITERIA,
	onRemoveCustomerGuaranteeFromBackEnd,
	updateCustomerGuaranteeData,
} from '../../store/customerGuarantee/customerGuaranteeActions'
/** Import section actions */

/**
 * Customer guarantee view
 */
class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showAddCustomerGuaranteeDialog: false,
			openRemoveItem: false,
			itemId: '',
			openModalEditCustomerGuarantee: false,
			editItem: null,
		}
		base = this.props.translate('moduleGuarantee.customerGuarantee.customer')
		spanish = this.props.translate('common.spanish')
		english = this.props.translate('common.english')
		portuguese = this.props.translate('common.portugues')
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadCustomerGuaranteeFromBackEnd(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
			this.props.getCustomerGuaranteeCount()
		}
	}

	/**
	 *componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadCustomerGuaranteeFromBackEnd()
			this.props.getCustomerGuaranteeCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery
		) {
			this.props.loadCustomerGuaranteeFromBackEnd(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending
			)
		}
	}

	/**
	 * Handle state of any Dialog to show or close
	 *
	 * @param {*} dialogId
	 * @memberof customerGuarantee
	 */
	handleOpenCloseAddCustomerGuaranteeDialog(dialogId) {
		this.setState({ [dialogId]: !this.state[dialogId] })
	}

	/**
	 * Open modal for edit customer guarantee
	 */
	handleOpenModalCustomerGuarantee(item) {
		this.setState({
			openModalEditCustomerGuarantee: true,
			editItem: item,
		})
	}

	canBeSavedGuarantee() {
		if (!this.state.editItem.name) return false
	}

	/**
	 * Handle On save, call action and show toaster if done or not
	 *
	 * @param {*} name
	 * @returns
	 * @memberof Index
	 */
	handleOnSaveAddCustomerGuarantee(name) {
		return this.props
			.addCustomerGuaranteeData(name)
			.then(() => {
				this.setState({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
				})
				this.handleOpenCloseAddCustomerGuaranteeDialog('showAddCustomerGuaranteeDialog')
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
				return Promise.reject()
			})
	}

	/**
	 * call to action to update data guarantee customer
	 *
	 * @memberof FormView
	 */
	updateCustomerGuarantee() {
		if (this.state.editItem !== null) {
			this.props
				.updateCustomerGuaranteeData(this.state.editItem)
				.then((response) => {
					this.setState({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: <Translate id='common.saveCorrectly' />,
						openModalEditCustomerGuarantee: false,
					})
				})
				.catch(() => {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				})
		}
	}

	/**
	 * Function on remove warehouses
	 */
	onRemoveCustomerGuarantee() {
		this.props
			.onRemoveCustomerGuaranteeFromBackEnd(this.state.itemId)
			.then(() => {
				this.setState({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: <Translate id='common.saveCorrectly' />,
					openRemoveItem: false,
					itemId: '',
				})
			})
			.catch((result) => {
				if (result.status === 400) {
					this.setState({
						showToaster: true,
						toasterMessage: result.data.Message,
						toasterVariant: 'error',
					})
				} else {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				}
			})
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * onRemoveItem
	 */
	onRemoveItem(itemId) {
		this.setState({
			openRemoveItem: true,
			itemId: itemId,
		})
	}

	/**
	 *method add properties
	 *
	 * @param {*} userId
	 * @memberof Show
	 */
	updateProperty(property, value) {
		let updatedguarantee = { ...this.state.editItem }
		updatedguarantee[property] = value
		this.setState({
			editItem: updatedguarantee,
		})
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	onRenderCellItem(dataSource, item) {
		if (dataSource === 'edit') {
			return (
				<Tooltip color='secondary' title={<Translate id='common.edit' />}>
					<EditIcon
						color='secondary'
						onClick={() => {
							this.handleOpenModalCustomerGuarantee(item)
						}}
					/>
				</Tooltip>
			)
		} else if (dataSource === 'delete') {
			return (
				<Tooltip color='secondary' title={<Translate id='common.delete' />}>
					<DeleteIcon
						color='secondary'
						onClick={() => {
							this.onRemoveItem(item.token)
						}}
					/>
				</Tooltip>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.customerGuarantee === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.customerGuarantee}
					configuration={TableConfiguration(spanish, english, portuguese)}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
				/>
			)
		}
	}

	/**
	 *Get raw customer guarantee quality counter
	 *
	 * @returns
	 * @memberof Index
	 */
	geCounterMessage() {
		if (this.props.customerGuarantee) {
			let totalCustomerGuarantees = this.props.customerGuarantee.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalCustomerGuarantees}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.customerGuaranteeCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Create instance customer guarantee
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<CustomerGuaranteeIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>
							<Fab
								size='small'
								color='primary'
								onClick={() =>
									this.handleOpenCloseAddCustomerGuaranteeDialog('showAddCustomerGuaranteeDialog')
								}
							>
								<AddtIcon />
							</Fab>
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.customerGuarantee' />{' '}
						</Typography>
					}
					subheader={this.geCounterMessage()}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				{/** Dialog to add non conformity*/}
				<DialogAddGuarantee
					classes={this.props.classes}
					onSave={this.handleOnSaveAddCustomerGuarantee}
					onCloseCancel={this.handleOpenCloseAddCustomerGuaranteeDialog}
					showDialog={this.state.showAddCustomerGuaranteeDialog}
					title={<Translate id='moduleGuarantee.customerGuarantee.addCustomerGuarantee' />}
					isSaving={this.props.isSaving}
				/>

				{/** Dialog to delete a customer guarantee */}
				<AddCommonItem
					title={<Translate id='moduleGuarantee.customerGuarantee.titleDeleteCustomer' />}
					message={<Translate id='moduleGuarantee.customerGuarantee.descriptionCustomer' />}
					open={this.state.openRemoveItem}
					onClose={() => {
						this.setState({ openRemoveItem: false })
					}}
					onSaveItem={this.onRemoveCustomerGuarantee}
					isSaving={this.props.isSaving}
					isMessage={true}
				/>

				<DialogEditCustomerGuarantee
					open={this.state.openModalEditCustomerGuarantee}
					title={<Translate id='moduleGuarantee.customerGuarantee.updateGuaranteeCustomer' />}
					customerGuarantee={this.state.editItem}
					updateCustomerGuaranteeData={this.updateCustomerGuarantee}
					updateProperty={this.updateProperty}
					onClose={() => {
						this.setState({ openModalEditCustomerGuarantee: false, editItem: null })
					}}
					onCancel={() => {
						this.setState({ openModalEditCustomerGuarantee: false, editItem: null })
					}}
					isSaving={this.props.isSaving}
				/>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')

	return {
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		customerGuarantee: state.customerGuarantee.get('customerGuaranteeData')
			? state.customerGuarantee.get('customerGuaranteeData').toJS()
			: null,
		sortCriteria: state.customerGuarantee.get('sortCriteria')
			? state.customerGuarantee.get('sortCriteria').toJS()
			: null,
		searchQuery: state.customerGuarantee.get('searchQuery'),
		isSaving: state.customerGuarantee.get('isSavingCustomerGuarantee'),
		customerGuaranteeCount: state.customerGuarantee.get('customerGuaranteeCount'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		addCustomerGuaranteeData: (name) => {
			return dispatch(addCustomerGuaranteeData(name))
		},
		loadCustomerGuaranteeFromBackEnd: (query, orderBy, sortAscending) => {
			return dispatch(loadCustomerGuaranteeFromBackEnd(query, orderBy, sortAscending))
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_CUSTOMER_GUARANTEE_SORT_QUERY,
				query: query,
			})
		},
		getCustomerGuaranteeCount: () => {
			dispatch(getCustomerGuaranteeCount())
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_CUSTOMER_GUARANTEE_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		onRemoveCustomerGuaranteeFromBackEnd: (token) => {
			return dispatch(onRemoveCustomerGuaranteeFromBackEnd(token))
		},
		updateCustomerGuaranteeData: (data) => {
			return dispatch(updateCustomerGuaranteeData(data))
		},
	}
}

let base
let spanish
let english
let portuguese
function TableConfiguration(spanish, english, portuguese) {
	return {
		columns: [
			{
				header: base + ' (' + spanish + ')',
				dataSource: 'name',
				isSortable: true,
			},
			{
				header: base + ' (' + english + ')',
				dataSource: 'name_english',
			},
			{
				header: base + ' (' + portuguese + ')',
				dataSource: 'name_portuguese',
			},
			{
				header: <Translate id='common.edit' />,
				dataSource: 'edit',
			},
			{
				header: <Translate id='common.delete' />,
				dataSource: 'delete',
			},
		],
	}
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
