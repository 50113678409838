import React from 'react';

/** Custom components import section */
import { DataGridCurrencyCell, DataGridDateCell, DataGridTextCell } from '../../../common/DataGrid/DataGridCells';

/**
 * Custom render for some service order columns
 * @param {string} dataSource
 * @param {object} item
 */
export const renderCustomCellItem = (dataSource, item) => {
    switch (dataSource) {
        case 'attentionDate': {
            const { attentionDate } = item;
            return <DataGridDateCell date={attentionDate} />;
        }
        case 'branchOffice': {
            const { branchOfficeId, branchOfficeName } = item;
            return <DataGridTextCell text={`${branchOfficeId || ''} - ${branchOfficeName || ''}`} />;
        }
        case 'closeDate': {
            const { closeDate } = item;
            return <DataGridDateCell date={closeDate} />;
        }
        case 'corporate': {
            const { corporateId, corporateName } = item;
            return <DataGridTextCell text={`${corporateId || ''} - ${corporateName || ''}`} />;
        }
        case 'creationDate': {
            const { creationDate } = item;
            return <DataGridDateCell date={creationDate} />;
        }
        case 'lockReason': {
            const { lockReason } = item;
            return <DataGridTextCell text={lockReason} variant="inherit" />;
        }
        case 'releaseDate': {
            const { releaseDate } = item;
            return <DataGridDateCell date={releaseDate} format="DD/MM/YYYY HH:mm" />;
        }
        case 'serviceType': {
            const { serviceTypeId, serviceTypeName } = item;
            return <DataGridTextCell text={`${serviceTypeId || ''} - ${serviceTypeName || ''}`} />;
        }
        case 'serviceDescription': {
            const { serviceId, serviceDescription } = item;
            return <DataGridTextCell text={`${serviceId || ''} - ${serviceDescription || ''}`} />;
        }
        case 'tallerImporteServicio': {
            const { tallerImporteServicio } = item;
            return <DataGridCurrencyCell amount={tallerImporteServicio} />;
        }
        case 'totalAmount': {
            const { totalAmount } = item;
            return <DataGridCurrencyCell amount={totalAmount} />;
        }
        case 'tallerImporteGastos': {
            const { tallerImporteGastos } = item;
            return <DataGridCurrencyCell amount={tallerImporteGastos} />;
        }
        default:
            return <DataGridTextCell text={item[dataSource]} />;
    }
};
