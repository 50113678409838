///Import react section
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch, } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { Translate } from "react-localize-redux";

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import Toaster from '../common/Toaster';
import DesktopView from './components/DesktopView'

import {
    loadAuditFromBackEnd,
} from '../../store/qualityaudit/QualityAuditActions'
/**Import actions section */
import { converListToArray } from '../../store/helpers/ListHelper'

const Show = (props) => {
    /**Render hooks section */
    const dispatch = useDispatch()

    /**Use effect section */
    useEffect(() => {
        dispatch(loadAuditFromBackEnd(props.match.params.token))
    }, [dispatch, props.match.params.token])

    /**Redux store information section*/
    const isLoading = useSelector((state) => state.qualityAudit.get('isLoading'))
    const identifier = useSelector((state) => state.qualityAudit.get('identifier'))
    const audit = useSelector((state) => state.qualityAudit.get('audit') ? state.qualityAudit.get('audit').toJS() : null)
    const translate = useSelector((state) => getTranslate(state.localize))
    const userProfile = useSelector((state) => state.oidc.user)
    const userRolesByCompany = useSelector((state) => state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : [])
    const isSaving = useSelector((state) => state.qualityAudit.get('isSaving'))

    /**Store local this component */
    const [toasterMessageConfig, setToasterMessageConfig] = useState({
        showToaster: false,
        toasterMessage: null,
        toasterVariant: "",
        name: ""
    })

    return (
        <>
            <DesktopView
                isLoading={isLoading}
                identifier={identifier}
                audit={audit}
                translate={translate}
                userProfile={userProfile}
                userRolesByCompany={userRolesByCompany}
                isSaving={isSaving}
            />

            <Toaster
                message={<Translate id={toasterMessageConfig.toasterMessage} />}
                open={toasterMessageConfig.showToaster}
                variant={toasterMessageConfig.toasterVariant}
                onClose={() => {
                    setToasterMessageConfig({
                        showToaster: false,
                        toasterMessage: "",
                        toasterVariant: "",
                    })
                }}
            />
        </>
    )
}

const styles = ((theme) => ({

}))

export default withauthenticatedLayout(withRouter((withStyles(styles, { withTheme: true })(Show))))