import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

/** Material UI impot section */
import { Button, DialogActions, Typography } from '@material-ui/core';

/** Custom components import section */
import Dialog from '../../../common/Dialog';

/**
 * Presentional component to render the confimation dialog to lock service orders for payment request.
 * @param {object} props
 */
const DialogConfirmationRemove = (props) => {
    /** Destructuring properties */
    const { opened, action, title, message, onClose } = props;

    const onConfirmAction = () => {
        action();
        onClose();
    }

    return (
        <Dialog
            open={opened}
            onClose={onClose}
            header={<Typography style={{ margin: 10 }} variant="body1">{title}</Typography>}
            actions={
                <DialogActions>
                    <Button onClick={() => { onConfirmAction() }} color="primary">
                        <Translate id="noticeChanges.deactivate" />
                    </Button>
                    <Button onClick={() => onClose()} color="secondary">
                        <Translate id="common.cancel" />
                    </Button>
                </DialogActions>
            }
        >
            <Typography style={{ margin: 10 }} variant="subtitle1" width="250px" >{message}</Typography>
        </Dialog>
    );
};


DialogConfirmationRemove.propTypes = {
    opened: PropTypes.bool.isRequired,
    action: PropTypes.func.isRequired,
    title: PropTypes.element,
    message: PropTypes.element,
    onClose: PropTypes.func.isRequired,
};

DialogConfirmationRemove.defaultProps = {
    executingAction: false,
    title: '',
    message: '',
    action: () => console.warn('[action] not defined'),
    onClose: () => console.warn('[onClose] not defined'),
};

export default DialogConfirmationRemove;
