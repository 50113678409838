import React, { useState } from 'react'
import { useEffect } from 'react'

import DataTable from '../../common/DataTable'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import { withRouter } from 'react-router-dom'
import * as selector from '../../../store/transportTender/transportTenderSelector'
import { useSelector, useDispatch } from 'react-redux'
import * as action from '../../../store/transportTender/transportTenderAction'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CatalogHeader from './components/CatalogHeader'
import DialogCatalogUploadMassive from './components/DialogCatalogUploadMassive'
import { getLoggedUserSelector } from '../../../store/profile/ProfileSelectors'
import { isTransportTenderAdministrator } from '../../../store/helpers/TransportTenderHelper'

import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import queryString from 'query-string'
import LoadingPage from '../../common/LoadingPage'

const TransportZone = (props) => {
	let history = useHistory()
	let location = useLocation()
	const queryParams = queryString.parse(location.search)
	// const transportTenderinfo = useSelector((state) => selector.getTransportTenderfilterSelector(state))
	const isLoadig = useSelector((state) => selector.getIsLoadingSavingSelector(state))
	// const transportZone = useSelector((state) => selector.getTransportZones(state))
	const transportTenderinfo = useSelector((state) => selector.getTransportTenderfilterSelector(state))
	const loggetUser = useSelector((state) => getLoggedUserSelector(state))
	const userRoles = loggetUser && loggetUser.grantedRoles ? loggetUser.grantedRoles : []
	const isTransportTenderAdminitsrator = isTransportTenderAdministrator(userRoles)
	/**Get errors file By State */
	const errorTransportZoneFile = useSelector((state) => selector.getErrorTransportZoneFileSelector(state))
	const dispatch = useDispatch()
	const [openDialog, setOpenDialog] = useState(false)
	const [file, setfile] = useState([])

	useEffect(() => {
		dispatch(action.loadTransportZoneCatalog(queryParams))
	}, [dispatch, location.search])
	useEffect(() => {
		if (file.length > 0) {
			dispatch(action.validateTransportZoneTemplate(file))
		}
	}, [file])
	const onDownloadTemplateFilled = () => {
		return dispatch(action.downloadFilledTransportZoneTemplate())
	}
	const canBeSaved = () => {
		if (errorTransportZoneFile.length > 0) return false
		if (file.length === 0) return false
		return true
	}
	const onSave = () => {
		dispatch(action.uploadTransportZoneTemplate(file))
			.then(() => {
				setOpenDialog(false)
				dispatch(action.loadTransportZoneCatalog(queryParams))
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}
	const onChangeQuery = (query) => {
		if (query) queryParams.query = query
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onChangePage = (page, rowsPerPage) => {
		queryParams.pageNumber = page + 1
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}
	return (
		<div>
			<CatalogHeader
				setOpenDialog={setOpenDialog}
				icon={<LocationOnIcon />}
				isInternalUse={true}
				title={<Translate id={'carrierTenders.configuration.availableTransportZones'} />}
				isAdministrator={isTransportTenderAdminitsrator}
				showSearch
				queryParams={queryParams}
				onChangeSearchValue={onChangeQuery}
			/>
			{isLoadig ? (
				<LoadingPage />
			) : (
				<DataTable
					data={transportTenderinfo.transportZonePagination.items}
					configuration={TableConfiguration()}
					showFilters
					isIndex
					page={transportTenderinfo.transportZonePagination.pageNumber}
					rowsPerPage={transportTenderinfo.transportZonePagination.pageSize}
					totalRows={transportTenderinfo.transportZonePagination.total}
					onChangePage={onChangePage}
				/>
			)}
			<DialogCatalogUploadMassive
				title={'Importación masiva de tipos generales de transporte'}
				typeLoadTranslate={'massiveTransportZones'}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				file={file}
				setfile={setfile}
				onDownloadTemplateFilled={onDownloadTemplateFilled}
				isLoading={isLoadig}
				canBeSaved={canBeSaved()}
				errorList={errorTransportZoneFile}
				onSave={onSave}
			/>
		</div>
	)
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: <Translate id={'carrierTenders.deals.code'} />,
			dataSource: 'code',
		},
		{
			header: <Translate id={'carrierTenders.name'} />,
			dataSource: 'name',
		},
		{
			header: <Translate id={'carrierTenders.deals.address'} />,
			dataSource: 'direction',
		},
		{
			header: 'km',
			dataSource: 'km',
		},
	]

	return { columns }
}

const styles = (theme) => ({
	addIcon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		borderRadius: '16px',
		margin: '0px 5px 0px 10px',
	},
	titleTabs: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	containerTitle: {
		margin: '15px',
	},
})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(TransportZone)))
