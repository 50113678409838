///Import react section
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**Import actions sections */
import { setToasterOptions } from '../../../store/indirectqualifications/IndirectqualificationsActions';

import {
	removeFileInAttachment,
	addEvidenceInAttachment,
	addDocumentInClassification,
	removeDocumentInClassification,
	updateDocumentType,
	removeExpedientDocument,
	createExpedientSupplier,
	downloadSuppliersClassificationsReport,
	deleteAllClassification,
	validateExpedientSuppliersFile
} from '../../../store/expedientSupplier/ExpedientSupplierAction';

import { IsNullOrEmpty } from '../../../store/helpers/StringHelper';

export const useExpedientSupplierCustomHookIndex = () => {
	let history = useHistory();

	const user = useSelector((state) => state.oidc.user);
	const isSaving = useSelector((state) => state.expedientSuppliers.get('isSaving'));

	/**Render hooks section */
	const dispatch = useDispatch();

	const onCloseToaster = () => {
		dispatch(
			setToasterOptions({
				showToaster: false,
				toasterMessage: '',
				toasterVariant: ''
			})
		);
	};

	/**
	 * Method to show error in import suppliers
	 * @param {*} error
	 */
	const getErrorImportStatus = (response) => {
		if (!IsNullOrEmpty(response) && typeof response === 'string') {
			if (response.includes('expedientSupplier.errors.invalidDocumentAlreadyExistInClassification'))
				return 'expedientSupplier.errors.invalidDocumentAlreadyExistInClassification';
			else if (response.includes('expedientSupplier.errors.invalidDocumentNotExtistToRemove'))
				return 'expedientSupplier.errors.invalidDocumentNotExtistToRemove';
			else if (response.includes('expedientSupplier.errors.invalidNomenclatureAlreadyInTemplate'))
				return 'expedientSupplier.errors.invalidNomenclatureAlreadyInTemplate';
			else if (response.includes('expedientSupplier.errors.invalidContaineClassificationsList'))
				return 'expedientSupplier.errors.invalidContaineClassificationsList';
			else return 'common.errorToSave';
		} else {
			return 'common.errorToSave';
		}
	};

	const onRemoveFileInAttachment = (token, fileName) => {
		return dispatch(removeFileInAttachment(token, fileName))
			.then(() => {
				return Promise.resolve(true);
			})
			.catch((_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onAddEvidenceInAttachment = (token, files) => {
		return dispatch(addEvidenceInAttachment(token, files))
			.then((attachments) => {
				return Promise.resolve(attachments);
			})
			.catch((_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: 'common.errorToSave',
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onAddDocumentInClassification = (classificationToken, documentToken) => {
		return dispatch(addDocumentInClassification(classificationToken, documentToken))
			.then(() => {
				return Promise.resolve(true);
			})
			.catch((result) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorImportStatus(result),
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onRemoveDocumentInClassification = (classificationToken, documentToken) => {
		return dispatch(removeDocumentInClassification(classificationToken, documentToken))
			.then(() => {
				return Promise.resolve(true);
			})
			.catch((result) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorImportStatus(result),
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onUpdateDocumentType = (documentModel) => {
		return dispatch(updateDocumentType(documentModel))
			.then(() => {
				return Promise.resolve(true);
			})
			.catch((error_) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorImportStatus(error_),
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onRemoveExpedientDocument = (documentToken) => {
		return dispatch(removeExpedientDocument(documentToken))
			.then(() => {
				return Promise.resolve(true);
			})
			.catch((result) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorImportStatus(result),
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onCreateExpedientSupplier = (expedienteSupplierModel, attachments, isCaptureManual) => {
		return dispatch(createExpedientSupplier(expedienteSupplierModel, attachments, isCaptureManual))
			.then((expedientToken) => {
				history.push(`/expedientsupplier/${expedientToken}`);
				return Promise.resolve(true);
			})
			.catch((result) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: !IsNullOrEmpty(result) ? result : 'common.errorToAdd',
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onDownloadSuppliersClassificationsReport = (classificationName) => {
		return dispatch(downloadSuppliersClassificationsReport(classificationName))
			.then(() => {
				return Promise.resolve(true);
			})
			.catch((result) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorImportStatus(result),
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onDeleteAllClassification = (classificationName) => {
		return dispatch(deleteAllClassification(classificationName))
			.then(() => {
				return Promise.resolve(true);
			})
			.catch((result) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: getErrorImportStatus(result),
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	const onValidateFileExpedientSupplier = (attachments) => {
		return dispatch(validateExpedientSuppliersFile(attachments))
			.then((data) => {
				return Promise.resolve(data);
			})
			.catch((result) => {
				dispatch(
					setToasterOptions({
						showToaster: true,
						toasterMessage: !IsNullOrEmpty(result) ? result : 'common.errorToAdd',
						toasterVariant: 'error'
					})
				);
				Promise.reject(false);
			});
	};

	return {
		user,
		isSaving,
		onCloseToaster,
		onRemoveFileInAttachment,
		onAddEvidenceInAttachment,
		onAddDocumentInClassification,
		onRemoveDocumentInClassification,
		onUpdateDocumentType,
		onRemoveExpedientDocument,
		onCreateExpedientSupplier,
		onDownloadSuppliersClassificationsReport,
		onDeleteAllClassification,
		onValidateFileExpedientSupplier
	};
};
