import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

/** Material UI import section */
import {
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
	withStyles,
} from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import DeleteIcon from '@material-ui/icons/DeleteForever'

/** Custom components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'

/** Actions import section */
import {
	loadAccreditedWorkshops,
	addWorkshopIvaPercentage,
	removeWorkshopFromIVA,
} from '../../../../store/accreditedWorkshops/accreditedWorkshopsActions'
import { getAccreditedWorkshopsSelector } from '../../../../store/accreditedWorkshops/accreditedWorkshopsSelectors'

const IvaPercentagesPanel = (props) => {
	/** Destructuring properties */
	const { classes, onClose, configuration, rfc } = props

	/** Defines local state */
	const [loadingWorkshops, setLoadingWorkshops] = useState(true)

	/** Connect with redux */
	const dispatch = useDispatch()
	const allWorkshops = useSelector((state) => getAccreditedWorkshopsSelector(state))

	useEffect(() => {
		// setLoadingWorkshops(true);
		dispatch(loadAccreditedWorkshops()).finally(() => {
			setLoadingWorkshops(false)
		})
	}, [dispatch])

	const suppliers = allWorkshops
		.map(({ sapCode, name }) => ({ value: sapCode, label: `${sapCode} - ${name}` }))
		.reduce((acc, supplier) => {
			if (acc.some((i) => i.value === supplier.value)) return acc
			acc.push(supplier)
			return acc
		}, [])

	const workshopsWithIva = configuration && configuration.WorkshopsWithIva ? configuration.WorkshopsWithIva : []

	const getSupplierLabel = (code) => {
		const supplier = suppliers.find((s) => s.value === code)
		return supplier ? supplier.label : `${code} - Desconocido`
	}

	const addSupplierToIVA = (supplier) => {
		dispatch(addWorkshopIvaPercentage(rfc, supplier.value, configuration.IvaPercentage))
	}

	const removeSupplierFromIVA = (supplier) => {
		dispatch(removeWorkshopFromIVA(rfc, supplier.WorkshopCode))
	}

	return (
		<LateralPanel onClose={onClose} classes={classes}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id='accreditedWorkshops.iva' />}
				onCloseButtonClick={onClose}
				icon={<SettingsIcon color='primary' />}
			/>
			<div className={classes.bodyContainer}>
				<Typography variant='body2'>
					<Translate id='accreditedWorkshops.setting.assignedSuppliers' />
				</Typography>
				<Select
					className={classes.documentTypeSelect}
					options={suppliers}
					onChange={addSupplierToIVA}
					styles={{
						// menu: (base) => ({ ...base, zIndex: 10 })
						menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
						menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
						menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
					}}
					menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
					menuPlacement={'auto'}
					isLoading={loadingWorkshops}
				/>
				<div>
					{workshopsWithIva.map((supplier) => (
						<ListItem key={supplier.Id} ContainerComponent='div' className={classes.activeItem}>
							<ListItemText
								primary={
									<Typography variant='subtitle2' noWrap>
										{getSupplierLabel(supplier.WorkshopCode)}
									</Typography>
								}
							/>
							<ListItemSecondaryAction className={classes.itemListIcon}>
								<ListItemIcon
									data-code={supplier}
									style={{ cursor: 'pointer' }}
									onClick={() => removeSupplierFromIVA(supplier)}
								>
									<DeleteIcon />
								</ListItemIcon>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</div>
			</div>
		</LateralPanel>
	)
}

IvaPercentagesPanel.propTypes = {
	onClose: PropTypes.func.isRequired,
}

const styles = (theme) => ({
	bodyContainer: {
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
	},
	tabContainer: {
		padding: 10,
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
})

export default withStyles(styles, { withTheme: true })(IvaPercentagesPanel)
