/** React imports section */
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import ImportMaterialIcon from '@material-ui/icons/Category'

/** Material UI imports section */
import { Button, withStyles } from '@material-ui/core'
/** common imports section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import {
	addMaterials,
	getDescriptionByCodeFromSAP,
	loadClientsCatalog,
	loadTransportZoneCatalog,
} from '../../../../store/transportTender/transportTenderAction'

import LoadTypeSelector from '../../common/LoadTypeSelector'
import CreateManualMaterial from './CreateManualMaterial'
import {
	getMaterialSap,
	getIsLoadingCodeFromSAP,
	getIsLoadingSavingSelector,
} from '../../../../store/transportTender/transportTenderSelector'

const CreateMaterialPanel = (props) => {
	const { classes, onClose, openMaterialPanel, setToasterMessageConfig, transportTenderdetail } = props

	const dispatch = useDispatch()

	const isLoadingSAP = useSelector((state) => getIsLoadingCodeFromSAP(state))
	const isLoading = useSelector((state) => getIsLoadingSavingSelector(state))

	const materialFromSAP = useSelector((state) => getMaterialSap(state))

	const [information, setInformation] = useState([])
	const [changeSelect, setChangeSelect] = useState({ value: 0, label: 'Manual' })

	const onSubmit = () => {
		dispatch(addMaterials(information, openMaterialPanel.deliveryId, transportTenderdetail.Id))
			.then(() => {
				''
			})
			.catch((err) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'Error al guardar la información',
					toasterVariant: 'error',
				})
			})
			.finally(() => {
				onClose()
			})
	}
	const canBeSaved = () => {
		if (information.length === 0) return false
		return true
	}
	const onSearchSAPCode = (code) => {
		dispatch(getDescriptionByCodeFromSAP(code))
	}

	useEffect(() => {
		dispatch(loadClientsCatalog())
		dispatch(loadTransportZoneCatalog())
	}, [dispatch])
	return (
		<LateralPanel onClose={onClose} classes={classes} drawerWidth={classes.drawerContent}>
			<PanelHeader
				isIconMaterial
				titlePanel={'Materiales'}
				onCloseButtonClick={onClose}
				icon={<ImportMaterialIcon color='secondary' />}
			/>
			<div className={classes.bodyContainer}>
				{false && (
					<LoadTypeSelector
						setChange={setChangeSelect}
						classes={classes}
						value={changeSelect}
						disabled={true}
					/>
				)}
				{changeSelect && changeSelect.value === 0 && (
					<div>
						<CreateManualMaterial
							classes={classes}
							information={information}
							setInformation={setInformation}
							onSearchSAPCode={onSearchSAPCode}
							isLoadingCodeFromSAP={isLoadingSAP}
							materialFromSAP={materialFromSAP}
							transportTenderdetail={transportTenderdetail}
						/>
					</div>
				)}
			</div>
			<Button
				data-tut='reactour-save'
				data-cy='btn-save'
				type='button'
				color='primary'
				variant='outlined'
				className={classes.btnCreate}
				onClick={onSubmit}
				disabled={!canBeSaved() || isLoading}
			>
				{<Translate id={'carrierTenders.deals.material.addList'} />}
			</Button>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	bodyContainer: {
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
	},
	titleSelect: {
		marginTop: '7px',
	},
	btnCreate: {
		marginTop: '20px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	createContainer: {
		display: 'grid',
		gridTemplateRows: '2fr',
		gridTemplateColumns: '5fr',
		gridGap: '5px  10px',
	},

	texthelpContent: {
		gridRow: '1/2',
		gridColumn: '1/2',
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.common.white,
		borderRadius: '8px',
		padding: '8px',
		display: 'flex',
		marginTop: '8px',
	},
	colorText: {
		color: theme.palette.common.white,
		paddingLeft: '8px',
	},
	codeText: {
		gridRow: '3/4',
		gridColumn: '1/2',
		display: 'flex',
	},
	btnSearch: {
		height: '35px',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		marginTop: '8px',
		marginLeft: '8px',
	},
	descriptionText: {
		gridRow: '4/5',
		gridColumn: '1/2',
	},
	amountText: {
		gridRow: '5/6',
		gridColumn: '1/2',
	},
	addContent: {
		display: 'grid',
		placeItems: 'center',
		gridRow: '6/7',
		gridColumn: '1/2',
	},
	addIcon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		margin: '10px 0px 0px 10px',
		width: '100%',
	},
	tableContainer: {
		margin: '7px',
	},
	deleteColor: {
		color: '#757575',
	},
	drawerContent: {
		width: '680px',
		height: '100vh',
		display: 'grid',
		gridTemplateRows: 'auto 1fr',
		overflowX: 'hidden',
		overflowY: 'hidden',
		backgroundColor: 'white',
	},
})
export default withStyles(styles, { withTheme: true })(CreateMaterialPanel)
