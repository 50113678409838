import {
    FolioType
} from './AppConstants'

export const descriptionFolioType = (type, translate) => {
    switch (Number(type)) {
        case FolioType.IMPORT:
            return translate("billings.create.import")
        case FolioType.EXPORT:
            return translate("billings.create.export")
        default:
            return "-"
    }
};
