import React from 'react'
import { Grid, TextField, Toolbar, Typography, withStyles } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { useState } from 'react'
import DialogActions from '../../../common/DialogActions'
import { SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper'
import Dialog from '../../../common/Dialog'
import DatePicker from '../../../common/DateTimePicker/DatePicker'
import moment from 'moment'

const EditTrainingDialog = (props) => {
	const { classes, open, onClose, editTraining, training } = props
	const [startDate, onStartDate] = useState(moment(`${training.start_date}`, 'DD/MM/YYYY'))
	const [endDate, onEndDate] = useState(moment(`${training.end_date}`, 'DD/MM/YYYY'))
	const [name, onName] = useState(training.name)
	
	
	
	const isValidToSave = () => {
		if (name && name === '') return false
		return true
	}
	const onAddTraining = () => {
		if (editTraining) {
			editTraining(startDate, endDate, name)
			onClose()
		}
	}
	const updateProperty = (key, event) => {
		if (key === 'startDate') onStartDate(event)
		else if (key === 'endDate') onEndDate(event)
		else onName(event)
	}
	return (
		<Dialog
			open={open}
			onClose={onClose}
			header={
				<Toolbar>
					<Typography variant='h6'>
						{<Translate id='contractorsModule.medicalchecks.addTraining' />}
					</Typography>
				</Toolbar>
			}
			actions={
				<div>
					{!isValidToSave() && (
						<Typography variant='caption' color='error'>
							{<Translate id='common.requiredFields' />}
						</Typography>
					)}
					<DialogActions
						view={SHOW_EDIT_VIEW}
						classes={classes}
						onCancel={onClose}
						onClose={onClose}
						onSaveItem={() => onAddTraining()}
						// isSaving={this.props.isSaving}
						canBeSaved={isValidToSave()}
					/>
				</div>
			}
		>
			<Grid container className={classes.modal} justify='space-between'>
				<TextField
					id='name'
					label={<Translate id='officialNotices.name' />}
					value={name}
					margin='normal'
					type={'text'}
					fullWidth
					required
					onBlur={(event) => updateProperty('name', event.target.value)}
					onChange={(event) => updateProperty('name', event.target.value)}
					multiline
					variant='outlined'
					rowsMax={4}
				/>
				<Grid item xs={5}>
					<Typography variant='caption' color={'textSecondary'}>
						<Translate id='common.startDate' />*
					</Typography>
					<DatePicker
						onChangeRange={(event) => {
							updateProperty('startDate', event)
						}}
						startDate={startDate}
					/>
				</Grid>
				<Grid item xs={1}></Grid>
				<Grid item xs={5}>
					<Typography variant='caption' color={'textSecondary'}>
						<Translate id='common.endDate' />*
					</Typography>
					<DatePicker
						onChangeRange={(event) => {
							updateProperty('endDate', event)
						}}
						startDate={endDate}
					/>
					<br />
				</Grid>
				
			</Grid>
		</Dialog>
	)
}
const styles = (theme) => ({
	modal: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
		width: 520,
	},
})
export default withRouter(withStyles(styles, { withTheme: true })(EditTrainingDialog))
