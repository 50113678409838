import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import LoadingPage from '../../common/LoadingPage'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import InventoryIcon from '@material-ui/icons/StorageTwoTone';

import { loadSupplier, getInventoriesFromSAP, donwlodInventoriesFromSAP } from '../../../store/suppliers/SupplierActions'
import DataTable from '../../common/DataTable';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';

/**
 * Inventories list 
 *
 * @class Inventories list
 * @extends {Component}
 */
class Inventories extends Component {

    /**
     * Create an instance of Inventories list
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            sortCriteria: {
                by: "status",
                ascending: false
            },
            isSearching: false
        }
    }

    /**
  * componentDidMount
  */
    componentDidMount() {
        let supplierToken = this.props.match.params.token;
        this.props.loadSupplier(supplierToken);
        if (this.props.supplier) {
            let supplierCode = this.props.supplier.code;
            this.props.getInventories(supplierCode);
        }
    }

    /**
     * On change sort criteria
     *
     * @param {*} sortBy
     * @param {*} sortAscending
     * @memberof Index
     */
    onChangeSortCriteria(sortBy, sortAscending) {
        let sortCriteria = {
            by: sortBy,
            ascending: sortAscending
        }
        this.setState({
            sortCriteria
        })
    }

    /**
    * On render cell item custom
    * @param {*} dataSource 
    * @param {*} item 
    */
    onRenderCellItem(dataSource, item) {
        if (dataSource === "existence") {
            if (item.is_negative_existence < 0) {
                return (<Typography color="error" variant="body2" gutterBottom>{item.existence} </Typography>)
            }
            else {
                return `${item.existence}`
            }
        }
    }

    /**
     * On Render aditional row data
     *
     * @param {*} dataSource
     * @param {*} item
     * @returns
     * @memberof DetailsCard
     */
    onRenderCellAditionalRowItem(dataSource, item) {
        if (dataSource === 'warehouses' && item[dataSource]) {
            return (
                <React.Fragment>
                    <DataTable
                        data={item[dataSource]}
                        configuration={TableConfigurationWarehouses}
                    />
                </React.Fragment>
            )
        }
    }

    /**
     * On serch invonces
     */
    onSearchClick(){
        if (this.props.supplier) {
            let supplierCode = this.props.supplier.code;
            this.props.getInventories(supplierCode);
        }
    }

    /**
     * on downlod click
     */
    onDonwlodClick(){
        if (this.props.supplier) {
            let supplierCode = this.props.supplier.code;
            this.props.donwlodInventories(supplierCode);
        }
    }

    /**
     * Get card content
     *
     * @returns
     * @memberof Stocks
     */
    getCardContent() {
        const { sortCriteria } = this.state;
        if (this.props.isLoadingInventories) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.inventories) {
            return (
                <Paper style={{overflowX:'auto'}}>
                    <DataTable
                        data={this.props.inventories}
                        configuration={TableConfiguration}
                        onRenderCellItem={this.onRenderCellItem}
                        mode={"clientSide"}
                        sortBy={sortCriteria.by}
                        sortAscending={sortCriteria.ascending}
                        onChangeSortCriteria={this.onChangeSortCriteria}
                        collapsableChild
                        aditionalRows={AditionalRows}
                        onRenderCellAditionalRowItem={this.onRenderCellAditionalRowItem}
                        showFilters
                        widthFilters={110}
                    />
                </Paper>
            )
        }
    }

    /**
     * Render
     *
     * @returns
     * @memberof Supplier
     */
    render() {
        const { classes } = this.props;
        return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar color="primary" >
                            <InventoryIcon />
                        </Avatar>
                    }
                    title={<span><Translate id= "menu.consults"/> | <Translate id= "menu.inventory"/></span>}
                    subheader={<span>
                        {this.props.supplier ? this.props.supplier.full_name : ""}
                        <div className={classes.toolbar}>
                            {this.props.inventories === null && 
                            <Button variant="contained" size="small" color="default" disabled={this.props.isLoadingInventories} style={{marginLeft: "3px"}} onClick={this.onSearchClick}> <Translate id="common.getData" /></Button>}
                            <Button variant="contained" size="small" color="default" disabled={this.props.isDownloadQueries} style={{marginLeft: "3px"}} onClick={this.onDonwlodClick}> <Translate id="common.download" /></Button>
                        </div>
                    </span>
                    }
                />
                <CardContent>
                    {this.getCardContent()}
                </CardContent>
            </Card>
        )
    }
}


const TableConfiguration = {
    columns: [{
        header: '',
        dataSource: 'expand'
    }, {
        header: <Translate id="queries.inventories.partNumber" />,
        dataSource: 'part_number',
        isSortable: true
    }, {
        header: <Translate id="queries.inventories.description" />,
        dataSource: 'part_desription',
        isSortable: true
    }, {
        header: <Translate id="queries.inventories.unitMeasure" />,
        dataSource: 'unit_measurement'
    }, {
        header: <Translate id="queries.inventories.maximumQuantity" />,
        dataSource: 'maximum'
    }, {
        header: <Translate id="queries.inventories.minimunQuantity" />,
        dataSource: 'minimum'
    }, {
        header: <Translate id="queries.inventories.physicalInventory" />,
        dataSource: 'physical',
        isSortable: true
    }, {
        header: <Translate id="queries.inventories.consignment" />,
        dataSource: 'consignment'
    }, {
        header: <Translate id="queries.inventories.reserved" />,
        dataSource: 'reserved',
    }, {
        header: <Translate id="queries.inventories.total" />,
        dataSource: 'existence',
        isSortable: true
    }]
}

/** Configuration to show additional rows */
const AditionalRows = {
    rows: [{
        dataSource: 'warehouses',
        type: 'dataList'
    }]
}

/** Table configuration to render Datatable of aditional row */
const TableConfigurationWarehouses = {
    columns: [{
        header: <Translate id="queries.inventories.warehouseCode" />,
        dataSource: 'warehouse_code'
    }, {
        header: <Translate id="queries.inventories.physicalInventory" />,
        dataSource: 'physical'
    }, {
        header: <Translate id="queries.inventories.consignment" />,
        dataSource: 'consignment'
    }]
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        isLoadingInventories: state.suppliers.get('isLoadingInventories'),
        supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
        inventories: state.suppliers.get('inventories') ? state.suppliers.get('inventories').toJS() : null,
        isDownloadQueries: state.suppliers.get('isDownloadQueries'),
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadSupplier: (token) => {
            dispatch(loadSupplier(token))
        },
        getInventories: (supplierCode) => {
            dispatch(getInventoriesFromSAP(supplierCode))
        },
        donwlodInventories:(supplierCode) =>{
            return dispatch(donwlodInventoriesFromSAP(supplierCode))
        }
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex",
        flexGrow: 1,
        width: "750px",
        margin: theme.spacing.unit,
    },
    documentTypeSelect: {
        width: "150px",
        marginRight: "5px"
    }
});

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Inventories))));
