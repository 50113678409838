import React from 'react'
import Select from 'react-select'
import propTypes from 'prop-types'

import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import { Add } from '@material-ui/icons'
import { Avatar, Button, Grid, TextField, Typography } from '@material-ui/core'

import LateralPanel from '../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader'

import { GetMeasurementUnits } from '../../../store/helpers/SelectOptions'
import PartNumberSelector from '../../common/PartNumberSelector'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper.js'

const AddMaterialPanel = ({
	open,
	onClose,
	classes,
	developmentSelected,
	setMaterialDataPanel,
	materialDataPanel,
	onAddMaterial,
	isLoading,
}) => {
	let measurements = GetMeasurementUnits()
	const canBeSaved = () => {
		if (!developmentSelected) return false
		if (!materialDataPanel.partNumber) return false
		if (IsNullOrEmpty(materialDataPanel.partNumber)) return false
		if (materialDataPanel.volumen && materialDataPanel.volumen.length === 0) return false
		if (materialDataPanel.observations && materialDataPanel.observations.length === 0) return false
		if (!materialDataPanel.volumnetMeaurement) return false
		if (IsNullOrEmpty(materialDataPanel.volumnetMeaurement)) return false
		else return true
	}
	const onAddProp = (event) => {
		const value = event.target.value
		const label = event.target.name
		let updateData = { ...materialDataPanel }
		updateData[label] = value
		setMaterialDataPanel(updateData)
	}
	const setOptionMeasurement = (option) => {
		let updateDate = { ...materialDataPanel }
		updateDate.volumnetMeaurement = option.value
		setMaterialDataPanel(updateDate)
	}
	const onSelectedPartNumber = (partNumber) => {
		let updateDate = { ...materialDataPanel }
		updateDate.partNumber = partNumber
		setMaterialDataPanel(updateDate)
	}
	return (
		<LateralPanel
			open={open}
			onClose={onClose}
			children={
				<div className={classes.containerPanel}>
					<PanelHeader
						titlePanel={<Translate id={'priceChangeRequest.create.materials.addPanel.title'} />}
						icon={
							<Avatar>
								<Add />
							</Avatar>
						}
						onCloseButtonClick={onClose}
						isIconMaterial={true}
					/>
					<Grid container spacing={8} className={classes.content}>
						<Grid item xs={12}>
							<Typography>
								<Translate id={'priceChangeRequest.create.materials.addPanel.partNumber'} />
							</Typography>
							<PartNumberSelector
								onPartNumberSelected={(partNumber) => onSelectedPartNumber(partNumber)}
							/>
							<Typography>
								<Translate id={'priceChangeRequest.create.materials.addPanel.unitMeasurement'} />
							</Typography>
							<Select
								options={measurements}
								styles={{
									menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
									menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
									menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
								}}
								onChange={setOptionMeasurement}
							/>
							<TextField
								variant='outlined'
								fullWidth
								name='volumen'
								margin='dense'
								type='number'
								label={<Translate id={'priceChangeRequest.create.materials.addPanel.volume'} />}
								onChange={onAddProp}
							/>
							<Typography>
								<Translate id={'priceChangeRequest.create.materials.addPanel.observations'} />
							</Typography>
							<TextField
								fullWidth
								margin='normal'
								variant='outlined'
								multiline
								rowsMax='5'
								name='observations'
								onChange={onAddProp}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Button
								className={classes.btnAdd}
								disabled={!canBeSaved() || isLoading}
								onClick={() => onAddMaterial()}
							>
								<Translate id={'priceChangeRequest.create.materials.add'} />
							</Button>
						</Grid>
					</Grid>
				</div>
			}
		/>
	)
}
AddMaterialPanel.propTypes = {
	open: propTypes.bool,
	materialDataPanel: propTypes.object,
	setMaterialDataPanel: propTypes.func,
	onAddMaterial: propTypes.func,
	onClose: propTypes.func,
}
AddMaterialPanel.defaultProps = {
	open: false,
	materialDataPanel: { partNumber: '', volumnetMeaurement: '', volumen: '', observations: '' },
	setMaterialDataPanel: () => console.warn('No [setMaterialDataPanel] CallBack defined'),
	onAddMaterial: () => console.warn('No [onAddMaterial] CallBack defined'),
	onClose: () => console.warn('No [onClose] CallBack defined'),
}
const styles = (theme) => ({
	containerPanel: {
		display: 'flex',
		flexDirection: 'column',
		padding: '8px',
		height: '100vh',
	},
	content: {
		flexGrow: 1,
	},
	btnAdd: {
		width: '100%',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
})
export default withRouter(withStyles(styles, { withTheme: true })(AddMaterialPanel))
