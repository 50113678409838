import React from 'react'
import propTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import DialogComponent from '../../common/DialogComponent'
import { Button, LinearProgress, Typography } from '@material-ui/core'
import DataTable from '../../../common/DataTable'
import moment from 'moment'
import { Translate } from 'react-localize-redux'

const DialogSynchronizeWinners = ({
	classes,
	open,
	onClose,
	getAllWinners,
	winnersTransportTender,
	isLoadingWinners,
	onSendBinnacleAndSyncSAP,
	isLoading,
}) => {
	const canBeSaved = !isLoadingWinners && winnersTransportTender.length > 0
	return (
		<DialogComponent
			title={<Translate id={'carrierTenders.sendBinnacleSyncSAP.title'} />}
			open={open}
			onClose={onClose}
			canBeSaved={canBeSaved}
			onSave={() => onSendBinnacleAndSyncSAP()}
			isSaving={isLoading}
			view={'SHOW_EDIT_VIEW'}
			savingTranslate={'carrierTenders.synchroSAP'}
			children={
				<div>
					<div className={classes.actionContent}>
						<Button
							className={classes.btnGetWinners}
							onClick={() => getAllWinners()}
							disabled={isLoadingWinners}
						>
							<Translate id={'carrierTenders.sendBinnacleSyncSAP.getWinners'} />
						</Button>
					</div>
					<Typography>
						<Translate id={'carrierTenders.sendBinnacleSyncSAP.listWinners'} />:{' '}
					</Typography>
					<div>
						{isLoadingWinners ? (
							<LinearProgress />
						) : (
							<div>
								<DataTable
									data={winnersTransportTender}
									configuration={TableConfiguration()}
									onRenderCellItem={onRenderCellItem}
								/>
							</div>
						)}
					</div>
				</div>
			}
		/>
	)
}
const onRenderCellItem = (dataSource, item) => {
	if (dataSource === 'carrierBiddingCode') {
		return <Typography variant='caption'>{`${item.carrierBiddingCode} | ${item.carrierBiddingName}`}</Typography>
	}
	if (dataSource === 'trasportTypeCode') {
		return <Typography variant='caption'>{`${item.trasportTypeCode} | ${item.transportTypeName}`}</Typography>
	}
	if (dataSource === 'dataServiceRequest') {
		return (
			<div>
				<Typography variant='caption'>{`${moment(item.dataServiceRequest).format('DD/MM/YYYY')}`}</Typography>
				<Typography variant='caption'>{`
					${moment(item.dataTimeRequestStart).format('HH:mm A')} - ${moment(item.dataTimeRequestEnd).format(
					'HH:mm A'
				)}`}</Typography>
			</div>
		)
	}
	if (dataSource === 'maxTransportZoneDirection') {
		return (
			<div>
				<Typography variant='caption'>{`${item.maxTransportZoneDirection.direction}`}</Typography>
				<Typography variant='caption'>{`${item.maxTransportZoneDirection.km} KM`}</Typography>
			</div>
		)
	}
}
function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: <Translate id={'carrierTenders.sendBinnacleSyncSAP.table.folioLetter'} />,
			dataSource: 'folioLetter',
		},
		{
			header: <Translate id={'carrierTenders.sendBinnacleSyncSAP.table.supplier'} />,
			dataSource: 'carrierBiddingCode',
		},
		{
			header: <Translate id={'carrierTenders.sendBinnacleSyncSAP.table.dateCellar'} />,
			dataSource: 'dataServiceRequest',
		},
		{
			header: <Translate id={'carrierTenders.sendBinnacleSyncSAP.table.transportType'} />,
			dataSource: 'trasportTypeCode',
		},
		{
			header: <Translate id={'carrierTenders.sendBinnacleSyncSAP.table.finalDestination'} />,
			dataSource: 'maxTransportZoneDirection',
		},
	]
	return { columns }
}
DialogSynchronizeWinners.propTypes = {
	open: propTypes.bool,
	isLoadingWinners: propTypes.bool,
	winnersTransportTender: propTypes.array,
	onClose: propTypes.func,
	getAllWinners: propTypes.func,
	onSendBinnacleAndSyncSAP: propTypes.func,
}
DialogSynchronizeWinners.defaultProps = {
	open: false,
	isLoadingWinners: false,
	winnersTransportTender: [],
	onClose: () => console.warn('No [OnClose] CallBack defined'),
	getAllWinners: () => console.warn('No [getAllWinners] CallBack defined'),
	onSendBinnacleAndSyncSAP: () => console.warn('No [onSendBinnacleAndSyncSAP] CallBack defined'),
}

const styles = (theme) => ({
	actionContent: {
		display: 'flex',
		justifyContent: 'center',
	},
	btnGetWinners: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
})
export default withStyles(styles, { withTheme: true })(DialogSynchronizeWinners)
