import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'auto-bind';

/** Material-UI imports section */
import {
    Card, Grid, Divider,
    CardContent, CardHeader, Typography,
    Collapse, CardActionArea, Tooltip
} from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import PersonIcon from '@material-ui/icons/Person';
import HystoryIcon from '@material-ui/icons/History';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Translate } from 'react-localize-redux';
import ProcessIcon from '@material-ui/icons/ThumbsUpDownOutlined';
import AceptedIcon from '@material-ui/icons/ThumbUp';
import RejectedIcon from '@material-ui/icons/ThumbDown';
import { IsNullOrEmpty } from '../../store/helpers/StringHelper';

import yourlogohere from '../../resources/images/yourlogohere.png';

/**
 * Class component to show hystory data card 
 *
 * @class HistoryCard
 * @extends {PureComponent}
 */
class HistoryCard extends PureComponent {

    /**
     *Creates an instance of HistoryCard.
     * @param {*} props
     * @memberof HistoryCard
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            expanded: true
        }
    }

    /**
     * Handle state to expand or collapse card content
     *
     * @memberof HistoryCard
     */
    handleExpand() {
        this.setState({ expanded: !this.state.expanded })
    }

    /**
     * Perform the render lifcycle
     *
     * @returns
     * @memberof HistoryCard
     */
    render() {
        const { classes } = this.props;
        let supplierLogo = this.props.supplier ? this.props.supplier.logo : "";
        let urlImageSupplier = `${process.env.REACT_APP_IDENTITY_SERVER}/${supplierLogo}`;
        let translationId = 'common.defaultNotification'
        if (this.props.title && !IsNullOrEmpty(this.props.title))
            translationId = this.props.title
        return (
            <Card>
                {/** Section of title, this section is clickable and expand or collapse the content */}
                <CardActionArea onClick={() => this.handleExpand()} >
                    <Divider />
                    <CardHeader
                        style={{ paddingBottom: '0px', paddingTop: '8px', backgroundColor: '#e8eff2f6' }}
                        action={
                            this.state.expanded ? <ExpandLessIcon style={{ marginTop: '10px', marginRight: '10px' }} /> :
                                <ExpandMoreIcon style={{ marginTop: '10px', marginRight: '10px' }} />
                        }
                        title={
                            <Typography gutterBottom variant="subtitle1"
                                component="span" color="textPrimary">
                                <HystoryIcon color='primary' />
                                {' '}<Translate id={translationId} />
                            </Typography>
                        }
                    />
                </CardActionArea>
                {/** Content of card*/}
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        {/** section to show basic creation data */}
                        <CardHeader
                            style={{ paddingTop: '0px' }}
                            title={
                                <Typography gutterBottom variant="body2" component="p">
                                    <Tooltip title={<Translate id='common.creationDate' />}>
                                        <CalendarIcon color='secondary' />
                                    </Tooltip> {' ' + this.props.creationDate}
                                </Typography>
                            }
                            action={
                                <span>{this.props.supplier && classes &&
                                    <div className={classes.actionsToolbar}>
                                        <img src={supplierLogo ? urlImageSupplier : yourlogohere} alt="logoSupplier" className={classes.logoSupplier} />
                                    </div>}
                                </span>
                            }
                            subheader={
                                <Typography gutterBottom variant="body2" component="p">
                                    <Tooltip title={<Translate id='common.createBy' />}>
                                        <PersonIcon color='secondary' />
                                    </Tooltip>{' ' + this.props.userCreator}

                                    {this.props.status && <p>
                                        {this.props.status === 1 &&
                                            <p>
                                                <Tooltip title={<Translate id='common.status' />}>
                                                    <ProcessIcon color='primary' />
                                                </Tooltip>{' '}<Translate id='common.inProcess' />
                                            </p>}
                                        {this.props.status === 2 &&
                                            <p>
                                                <Tooltip title={<Translate id='common.status' />}>
                                                    <AceptedIcon color='secondary' />
                                                </Tooltip>{' '}<Translate id='common.auhtorized' />
                                            </p>}
                                        {this.props.status === 3 &&
                                            <p>
                                                <Tooltip title={<Translate id='common.status' />}>
                                                    <RejectedIcon color='error' />
                                                </Tooltip>{' '}<Translate id='common.reject' />
                                            </p>}
                                    </p>}
                                </Typography>
                            }
                        />
                        {/** Section to show all aditional data */}
                        {this.props.aditionalData && <Divider />}
                        {this.props.aditionalData &&
                            <CardContent>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        {this.props.aditionalData}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        }
                    </CardContent>
                </Collapse>
                <Divider />
            </Card>
        );
    }
}

HistoryCard.propTypes = {
    title: PropTypes.string.isRequired, //Trnslation ID
    creationDate: PropTypes.string.isRequired,
    userCreator: PropTypes.string.isRequired
};

export default (HistoryCard);