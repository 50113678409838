/**Import react section */
import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

/** Material UI import section */
import { Typography, Divider, Avatar } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Clear";

const PanelHeader = (props) => {
	/** Destructiring properties */
	const { titlePanel, subTitlePanel, icon, isIconMaterial, classes, onCloseButtonClick } = props;
	return (
		<div className={classes.panelHeader}>
			<div className={classes.flex}>
				<div className={classes.titleContainer}>
					<div className={classes.headerIcon}>{isIconMaterial ? icon : <img src={icon} alt="budgetImage" />}</div>
					<div className={classes.headerText}>
						<Typography variant="h6"> {titlePanel} </Typography>
					</div>
					{
						subTitlePanel && <div className={classes.headerText}>
							<Typography> {subTitlePanel} </Typography>
						</div>
					}
				</div>
			</div>
			{!!onCloseButtonClick && (
				<Avatar size="small" className={classes.buttonClose} onClick={onCloseButtonClick}>
					<DeleteIcon />
				</Avatar>
			)}
			<Divider className={classes.marginTop} />
		</div>
	);
};

const styles = {
	panelHeader: {
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingTop: '10px',
		paddingBottom: '10px',
		backgroundColor: 'white !important',
	},
	flex: {
		display: 'flex'
	},
	titleContainer: {
		marginTop: '10px',
		flex: '1 0 0'
	},
	headerIcon: {
		width: '100%',
		justifyContent: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '-5px',
		display: ' inline-flex'
	},
	headerText: {
		width: '100%',
		justifyContent: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: ' inline-flex',
		color: `red !important`
	},
	buttonClose: {
		width: "25px",
		height: "25px",
		position: "fixed",
		top: "13px",
		right: "10px",
		cursor:"pointer"
	},
	marginTop: {
		marginTop: "5px"
	}
};

PanelHeader.propTypes = {
	/**
	 * Determine if is saving
	 */
	isSaving: propTypes.bool,
	/**
	 * Determine if is mode saved
	 */
	isModeSaved: propTypes.bool,
	/**
	 * On change button saved
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * On change button edit
	 */
	onEditButtonClick: propTypes.func
};
PanelHeader.defaultProps = {
	isLoading: false
};

export default withStyles(styles, { withTheme: true })(PanelHeader);
