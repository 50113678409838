/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
/**Import actions sections */
import { confirmUserPassword } from '../../store/profile/ProfileActions'
/** Material UI import section */
import { DialogContent, TextField, IconButton, Typography, InputAdornment, withStyles } from '@material-ui/core'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

/** Custom components import section */
import Dialog from './Dialog'
import DialogActions from './DialogActions'
import { SHOW_EDIT_VIEW } from '../../store/helpers/ResourcesHelper'

import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import Toaster from '../common/Toaster'
import { COLOR_STATUS_SUCCESS, COLOR_STATUS_DANGER } from '../../store/helpers/StatusColorConstants'
const CHARACTER_COMMENTS = 255
/** Resources import section */
/**
 * @param {*} props
 * @returns
 */
const ConfirmPasswordDialog = (props) => {
	const dispatch = useDispatch()
	const { classes } = props
	const translate = useSelector((state) => getTranslate(state.localize))

	const {
		commentRequired,
		isSaving,
		onClickButtonConfirm,
		isVisibleComments,
		message1: alertMesage,
		message2,
		title,
		message3,
		saveButtonTranslate,
	} = props
	const [showPassword, setShowPassword] = useState(false)
	const [passwordModel, setPasswordModel] = useState({ password: '', comments: '' })
	const [toasterOptions, setToasterOptions] = useState({ open: false, variant: '', message: null })
	const [showHelperPassword, setShowHelperPassword] = useState(false)

	useEffect(() => {
		setShowPassword(false)
		setPasswordModel({ password: '', comments: '', identifier: '' })
	}, [])

	/**
	 * Determine if the accepted button can be pressed
	 */
	const canBeSaved = () => {
		if (commentRequired) {
			if (
				commentRequired &&
				(IsNullOrEmpty(passwordModel ? passwordModel.comments : '') ||
					(passwordModel ? passwordModel.comments.trim().length < 20 : ''))
			) {
				return false
			}
			if (
				IsNullOrEmpty(passwordModel ? passwordModel.password : '') ||
				(passwordModel ? passwordModel.password.length < 6 : '')
			) {
				return false
			}
		} else {
			if (
				IsNullOrEmpty(passwordModel ? passwordModel.password : '') ||
				(passwordModel ? passwordModel.password.length < 6 : '')
			) {
				return false
			}
		}
		return true
	}

	/**On change properties for the confirm password */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		if (name === 'comments' && value.length !== 0) {
			var regexAlphanumeric = new RegExp(/[A-Za-z0-9]+/g)
			if (!regexAlphanumeric.test(value)) return false
			//value = value.trim()
		}
		setPasswordModel({
			...passwordModel,
			[name]: value,
		})
	}

	/**
	 * Hanlde click event from accept button
	 */
	const onAcceptClick = () => {
		if (onClickButtonConfirm) {
			onClickButtonConfirm(passwordModel)
		}
	}

	const onPasswordConfirm = () => {
		let resultResponse = false
		return dispatch(confirmUserPassword(passwordModel.password))
			.then((result) => {
				resultResponse = result.Success
				if (resultResponse) {
					onAcceptClick(passwordModel)
				} else {
					setShowHelperPassword(true)
				}
			})
			.catch(() => {
				setToasterOptions({ open: true, message: <Translate id='common.errorConfirm' />, variant: 'error' })
				console.error('Error to confirm password')
			})
	}

	/**
	 * Handle view actual password
	 */
	const onToggleShowPassword = () => {
		setShowPassword(!showPassword)
	}

	let placeholder = translate('common.comments')
	if (commentRequired) {
		placeholder = placeholder.concat(translate('common.commentsRequired'))
	} else {
		placeholder = placeholder.concat(translate('common.comments'))
	}

	return (
		<>
			<Dialog
				open={props.open}
				onClose={props.onClose}
				isSaving={isSaving}
				maxWidth={props.maxWidth}
				header={
					<Typography color='primary' align='center' variant='h6'>
						{title}
					</Typography>
				}
				actions={
					<DialogActions
						view={SHOW_EDIT_VIEW}
						classes={classes}
						onCancel={props.onClose}
						onClose={props.onClose}
						onSaveItem={onPasswordConfirm}
						isSaving={props.isSaving}
						canBeSaved={canBeSaved()}
						saveButtonTranslate={saveButtonTranslate}
					/>
				}
			>
				<DialogContent>
					<Typography color='primary'>{alertMesage}</Typography>
					<br />
					{isVisibleComments && (
						<TextField
							fullWidth
							name='comments'
							variant='outlined'
							margin='dense'
							label={<Translate id='common.comments' />}
							placeholder={placeholder}
							multiline
							rows={4}
							onChange={onPropertyChange}
							value={passwordModel ? passwordModel.comments : ''}
							inputProps={{ maxLength: CHARACTER_COMMENTS }}
							helperText={
								<div>
									{passwordModel && passwordModel.comments.trim().length < 20 && (
										<Typography align='left' color='error'>
											{translate('common.minimum')}
										</Typography>
									)}
									<Typography
										align='right'
										style={{
											marginTop:
												passwordModel && passwordModel.comments.trim().length < 20
													? '-20px'
													: '',
										}}
									>{`${
										passwordModel ? passwordModel.comments.length : 0
									}/${CHARACTER_COMMENTS}`}</Typography>
								</div>
							}
						/>
					)}
					{message3 && (
						<>
							{message3}
							<br />
						</>
					)}
					{message2 && (
						<>
							<Typography variant='subtitle2'>{message2}</Typography>
							<br />
						</>
					)}
					<TextField
						fullWidth
						autoComplete='off'
						label={<Translate id='common.password' />}
						variant='outlined'
						data-cy='txt-confirm-password'
						value={passwordModel ? passwordModel.password : ''}
						onChange={onPropertyChange}
						type={showPassword ? 'text' : 'password'}
						name='password'
						helperText={
							showHelperPassword ? (
								<Typography color='error' variant='subtitle2'>
									<strong>{<Translate id='common.incorrectPassword' />}</strong>
								</Typography>
							) : (
								''
							)
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										edge='end'
										aria-label='toggle password visibility'
										onClick={onToggleShowPassword}
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
							inputMode: 'text',
							autoComplete: 'off',
						}}
					/>
				</DialogContent>
			</Dialog>

			{/** Toaster */}
			{toasterOptions.open && (
				<Toaster toasterOptions={toasterOptions} onClose={() => setToasterOptions({ open: false })} />
			)}
		</>
	)
}
const styles = (theme) => ({
	btnSaved: {
		backgroundColor: COLOR_STATUS_SUCCESS,
		color: 'white',
		'&:hover': {
			backgroundColor: COLOR_STATUS_SUCCESS,
			color: 'white',
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_STATUS_SUCCESS,
			color: 'white',
			opacity: 0.5,
		},
	},
	btnCancel: {
		marginRight: '10px',
		backgroundColor: COLOR_STATUS_DANGER,
		color: 'white',
		'&:hover': {
			backgroundColor: COLOR_STATUS_DANGER,
			color: 'white',
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_STATUS_DANGER,
			color: 'white',
			opacity: 0.5,
		},
	},
	btnConfirm: {
		backgroundColor: COLOR_STATUS_SUCCESS,
		color: 'white',
		'&:hover': {
			backgroundColor: COLOR_STATUS_SUCCESS,
			color: 'white',
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_STATUS_SUCCESS,
			color: 'white',
			opacity: 0.5,
		},
	},
	btnWithIcon: {
		backgroundColor: `white" !important`,
	},
})

ConfirmPasswordDialog.propTypes = {
	/**Confirm the operation to be performed*/
	onClickButtonConfirm: propTypes.func,
	/**Determinate if saving an operation */
	isSaving: propTypes.bool,
	/**Determinate if is visible the textbox commnets */
	isVisibleComments: propTypes.bool,
	/**Determinate if the commnets are required*/
	commentRequired: propTypes.bool,
	/**Determinate if dialog password is open or close */
	open: propTypes.bool.isRequired,
	/**Determinate if close the dialog  */
	onClose: propTypes.func.isRequired,
	/**See mensaje of operation alert*/
	alertMesage: propTypes.string,
	/**See mensaje of operation*/
	message2: propTypes.oneOfType([propTypes.string, propTypes.node]),
	/**translate for button save */
	saveButtonTranslate: propTypes.string,
}

ConfirmPasswordDialog.defaultProps = {
	isSaving: false,
	isVisibleComments: false,
	commentRequired: false,
	alertMesage: '',
	message2: '',
	onClickButtonConfirm: () => console.warn('Callback [onClickButtonConfirm] no defined'),
	saveButtonTranslate: 'common.save',
}

export default withRouter(withStyles(styles, { withTheme: true })(ConfirmPasswordDialog))
