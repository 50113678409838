import React from 'react';
import propTypes from 'prop-types'
/** Material UI import section */
import {
    Drawer,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const LateralPanel = props => {
    const { onClose, children, classes, drawerWidth } = props;
    return (
        <Drawer
            anchor="right"
            open={props.open}
            onClose={onClose}
        >
            <div
                className={drawerWidth ? drawerWidth: classes.drawerContent}
                tabIndex={0}
            >
                {children}
            </div>

        </Drawer>
    );
}

const styles = {
    drawerContent: {
        width: "480px",
        height: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflowX: 'hidden',
        overflowY: 'hidden',
        backgroundColor: 'white'
    },
};

LateralPanel.propTypes = {
    open: propTypes.bool.isRequired
}
LateralPanel.defaultProps = {
    open: true
}

export default withStyles(styles, { withTheme: true })(LateralPanel)