/**Import react section */
import React from "react";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import AttachmentFileIcon from "@material-ui/icons/AttachFile";
import { Translate } from "react-localize-redux";

/** Import component section */
import DataTable from '../../../common/DataTable';
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants';

/**import helpers section */
/**Custom hooks index */

import { useBillingCustomHookShow } from '../../customHooks/UseCustomHookShow'

const ShowDesktopViewInternalUser = (props) => {
    const { billing } = useBillingCustomHookShow()

    /**String props section */
    //const { } = props
    /**Bools props section */
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onLateralPanelConfig } = props
    /**Objects props section */
    const { classes } = props
    /**Render hooks section */
    /**Redux store information section*/

    /**
 * On render cell item custom
 * @param {*} dataSource 
 * @param {*} item 
 */
    const onRenderCellItem = (dataSource, item) => {
        if (dataSource === "description") {
            return <Typography className={classes.descriptionName}>{item.description}</Typography>
        }
        if (dataSource === "attachment") {
            return <IconButton onClick={()=>onLateralPanelConfig("ShowAddBillingFile")}><AttachmentFileIcon color="primary" /></IconButton>
        }
    }

    return (
        <Grid container>
            <Grid item xs={11} className={classes.grayColor}>
                <Typography className={classes.itemTitle}>ITEMS DE FACTURAS</Typography>
            </Grid>
            <Grid item xs={1} className={classes.btnAdd} >
                <IconButton onClick={()=>onLateralPanelConfig("ShowAddItemBilling")}><AddIcon color="primary" /></IconButton>
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    data={billing ? billing.items : []}
                    configuration={TableConfiguration(false)}
                    onRenderCellItem={onRenderCellItem}
                />
            </Grid>
        </Grid>
    )
}

function TableConfiguration(isDisabled) {
    let columns = [
        {
            header: <Translate id="billings.tableItem.description" />,
            dataSource: 'description',
            isSortable: false,
        }, {
            header: <Translate id="billings.tableItem.price" />,
            dataSource: 'amount',
            isSortable: false,
        },
        {
            header: <Translate id="billings.tableItem.measurementUnit" />,
            dataSource: 'measurement_unit',
            isSortable: false,
        },
        {
            header: <Translate id="billings.tableItem.flete" />,
            dataSource: 'flete',
            isSortable: false,
        },
        //{
          //  header: "",
            //dataSource: 'attachment',
            //isSortable: false,
        //}
    ]

    return ({ columns });
}

const styles = (theme) => ({
    papper: {
        marginTop: "10px"
    },
    root: {
        maxHeight: `calc(100vh - ${265}px)`,
        minHeight: `calc(100vh - ${265}px)`,
        overflowY: "auto",
        padding: "10px",
        overflowX: "auto"
    },
    grayColor: {
        backgroundColor: BACKGROUND_COLOR_GRAY
    },
    btnAdd: {
        backgroundColor: BACKGROUND_COLOR_GRAY,
        display:"flex",
        justifyContent:"flex-end"
    },
    itemTitle:{
        marginTop:"11px",
        marginLeft:"15px",
        fontWeight:"bold"
    },
    descriptionName:{
        maxWidth: "200px",
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }

});

export default withRouter(
    withStyles(styles, { withTheme: true })(ShowDesktopViewInternalUser)
);
