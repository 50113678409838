import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import DataTable from '../common/DataTable'
import SearchInput from '../common/SearchInput/SearchInput'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import MemosIcon from '@material-ui/icons/FileCopyTwoTone'
import AddIcon from '@material-ui/icons/AddTwoTone'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Fab from '@material-ui/core/Fab'

/** Language imports **/
import { Translate, getTranslate } from 'react-localize-redux'

/** Import section actions */
import {
	loadMaterialDeliveriesFromBackEnd,
	getMaterialDeliveriesCount,
	CHANGE_MATERIAL_DELIVERIES_SORT_CRITERIA,
	CHANGE_MATERIAL_DELIVERIES_QUERY,
	CHANGE_MATERIAL_DELIVERIES_PAGINATION,
} from '../../store/materialDelivery/MaterialDeliveryAction'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'

/**
 * material deliverie's view
 *
 * @class material deliverie's
 * @extends {Component}
 */
class Index extends Component {
	/**
	 * Create an instance material deliverie's
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadMaterialDeliveries(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getMaterialDeliveriesCount()
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadMaterialDeliveries()
			this.props.getMaterialDeliveriesCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadMaterialDeliveries(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingMaterialDeliveries === true) {
			return <LoadingPage />
		} else if (this.props.materialDeliveries === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.props.materialDeliveries}
					configuration={TableConfiguration(IsCurrentActiveDirectoryUser(this.props.user))}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRowClick={(materialdelivery) => {
						this.props.history.push(`/materialdelivery/${materialdelivery.token}`)
					}}
					showFilters
					isIndex
					totalRows={this.props.materialDeliveriesCount}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get material deliverie's counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		if (this.props.materialDeliveries) {
			let totalMaterials = this.props.materialDeliveries.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalMaterials}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>{this.props.materialDeliveriesCount}</span>&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof material deliverie's
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<MemosIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>
							{IsCurrentActiveDirectoryUser(this.props.user) && (
								<Fab
									size='small'
									color='primary'
									onClick={() => {
										this.props.history.push(`/materialDelivery/create`)
									}}
								>
									<AddIcon />
								</Fab>
							)}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.materialDelivery' />{' '}
						</Typography>
					}
					subheader={this.getCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>
			</Card>
		)
	}
}

function TableConfiguration(isUserInternal) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'status_description',
			isSortable: true,
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'folio',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='memos.stage' />,
			dataSource: 'stage',
			isSortable: true,
		},
		{
			header: <Translate id='memos.developmentEngineer' />,
			dataSource: 'development_name',
			isSortable: true,
		},
	]

	if (isUserInternal) {
		columns.push({
			header: <Translate id='common.supplier' />,
			dataSource: 'supplier_name',
			isSortable: true,
		})
	}

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingUser: state.oidc.isLoadingUser,
		isLoadingMaterialDeliveries: state.materialDelivery.get('isLoadingMaterialDeliveries'),
		materialDeliveries: state.materialDelivery.get('materialDeliveries')
			? state.materialDelivery.get('materialDeliveries').toJS()
			: null,
		sortCriteria: state.materialDelivery.get('sortCriteria')
			? state.materialDelivery.get('sortCriteria').toJS()
			: null,
		searchQuery: state.materialDelivery.get('searchQuery'),
		page: state.materialDelivery.get('page'),
		rowsPerPage: state.materialDelivery.get('rowsPerPage'),
		materialDeliveriesCount: state.materialDelivery.get('materialDeliveriesCount'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadMaterialDeliveries: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadMaterialDeliveriesFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_MATERIAL_DELIVERIES_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_MATERIAL_DELIVERIES_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_MATERIAL_DELIVERIES_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		getMaterialDeliveriesCount: () => {
			dispatch(getMaterialDeliveriesCount())
		},
	}
}

const styles = (theme) => ({
	avatar: {},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
