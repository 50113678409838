/** react imports section */
import React, { useState } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import { TransportTendeStatus } from '../../../../store/helpers/AppConstants'
/** Material-UI imports section */
import CheckIcon from '@material-ui/icons/Check'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import WarningIcon from '@material-ui/icons/Warning'
import { Typography, withStyles, CardHeader, Card, Collapse, Tooltip, IconButton, Divider } from '@material-ui/core'
import { Add, AssistantPhoto, Info, Report } from '@material-ui/icons'
import WinnerLogo from '../../../../resources/images/winners/winner-proposal.png'
import Badge from '@material-ui/core/Badge'
import DataTable from '../../../common/DataTable'
const ResultsTender = (props) => {
	const { proposals, classes, transportTenderdetail, setOpenDialog, onAddIncidence, onOpenCalculateTarif } = props

	const [openCollapese, setOpenCollapese] = useState({ open: false, proposalsId: null })
	const isTypeTransportTrailer = transportTenderdetail.IsTypeTransportTrailer

	return (
		<div>
			{proposals &&
				proposals.length > 0 &&
				proposals
					.filter((x) => x.Status === 2)
					.map((itemProposal) => {
						const link = itemProposal && itemProposal.League ? itemProposal.League : ''
						const cleanUrl = link ? link.trim() : ''
						// Verificar si la URL comienza con "http" o "https"
						const finalUrl = cleanUrl.startsWith('http') ? cleanUrl : `http://${cleanUrl}`
						const totalIncident = itemProposal.ProposalIncidents.length
						return (
							<Card>
								<CardHeader
									className={itemProposal.Winning ? classes.borderLeftCard : classes.cardContent}
									avatar={
										itemProposal.Winning ? (
											<img alt='WinnerIcon' className={classes.winnerSize} src={WinnerLogo} />
										) : (
											<AssignmentIndIcon color='secondary' fontSize='large' />
										)
									}
									title={
										<div style={{ display: 'grid', gridTemplateColumns: '1fr 20px' }}>
											<div
												style={{
													gridColumn: '1/2',
												}}
											>
												<div className={classes.supplierIformation}>
													<Typography
														gutterBottom
														variant='body2'
														component='p'
														className={itemProposal.Winning && classes.supplierName}
													>
														{itemProposal.CarrierBidding.Code} |{' '}
														{itemProposal.CarrierBidding.Name}
													</Typography>
													<Typography gutterBottom variant='body2' component='p'>
														<strong>
															<Translate id='carrierTenders.resultsTender.proposedShippingCost' />{' '}
															:
														</strong>{' '}
														{itemProposal.NewAmount
															? itemProposal.NewAmount.toLocaleString('es-MX', {
																	style: 'currency',
																	currency: 'MXN',
															  })
															: '$0 MXN'}
													</Typography>
													<Typography gutterBottom variant='body2' component='p'>
														<strong>
															<Translate id='carrierTenders.resultsTender.rating' /> :
														</strong>
														{itemProposal.Rating}
														<Tooltip
															title={
																itemProposal.DateRating ? (
																	`${moment(itemProposal.DateRating).format(
																		'DD/MM/YYYY'
																	)}`
																) : (
																	<Translate id='carrierTenders.resultsTender.dontHaveinfoDate' />
																)
															}
														>
															<Info className={classes.infoIcon} />
														</Tooltip>
													</Typography>
													{(transportTenderdetail.Status ===
														TransportTendeStatus.SENT_TO_CARRIER ||
														transportTenderdetail.Status ===
															TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER ||
														transportTenderdetail.Status ===
															TransportTendeStatus.IN_EVALUATION) &&
														!itemProposal.Winning && (
															<div
																className={
																	itemProposal.Winning
																		? classes.winning
																		: classes.selectWinner
																}
																onClick={() =>
																	transportTenderdetail.Status !==
																		TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER &&
																	!itemProposal.Winning
																		? setOpenDialog({
																				open: true,
																				supplier:
																					itemProposal.CarrierBidding.Name,
																				proposalId: itemProposal.Id,
																				status: itemProposal.Winning
																					? false
																					: true,
																		  })
																		: null
																}
															>
																<Typography
																	gutterBottom
																	variant='body2'
																	component='p'
																	className={
																		itemProposal.Winning ? classes.titleWinning : ''
																	}
																>
																	{itemProposal.Winning
																		? 'Ganador'
																		: 'Elegir ganador'}
																</Typography>
																<CheckIcon
																	className={
																		itemProposal.Winning
																			? classes.assignmentWinning
																			: classes.assignmentTurnedInIcon
																	}
																/>
															</div>
														)}
													{itemProposal.Winning && (
														<div
															className={classes.incidenceContent}
															onClick={() => onAddIncidence(itemProposal.Id)}
														>
															<Add fontSize='small' />
															<Typography style={{ color: 'white' }}>
																<Translate id='carrierTenders.carrierInformation.addIncidence' />
															</Typography>
														</div>
													)}
												</div>
												<div className={classes.commentsIformation}>
													<Typography gutterBottom variant='body2' component='p'>
														<strong>
															<Translate id='carrierTenders.carrierInformation.comments' />{' '}
															:
														</strong>
														{itemProposal.Comments}
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='p'
														className={classes.shipmentsWon}
													>
														<strong>
															<Translate id='carrierTenders.resultsTender.shipmentsWon' />{' '}
															:
														</strong>{' '}
														{itemProposal.TotalWinning}
													</Typography>
													<div className={classes.flexSpaceBetween}>
														<Typography gutterBottom variant='body2' component='p'>
															<strong>
																<Translate id='carrierTenders.resultsTender.tariffAmount' />{' '}
																:
															</strong>{' '}
															{itemProposal.TariffAmount
																? itemProposal.TariffAmount.toLocaleString('es-MX', {
																		style: 'currency',
																		currency: 'MXN',
																  })
																: '$0 MXN'}
															<Tooltip
																title={
																	itemProposal.TariffAmount === 0 ? (
																		<Translate id='carrierTenders.resultsTender.missinTariffAmount' />
																	) : (
																		<Translate id='carrierTenders.resultsTender.seeTariffAmount' />
																	)
																}
															>
																<IconButton
																	onClick={() =>
																		onOpenCalculateTarif(
																			true,
																			itemProposal.TariffAmount
																		)
																	}
																	fontSize={'small'}
																>
																	{itemProposal.TariffAmount === 0 ? (
																		<WarningIcon
																			className={classes.colorIconWarning}
																			fontSize='small'
																		/>
																	) : (
																		<Info
																			className={classes.colorIcon}
																			fontSize='small'
																		/>
																	)}
																</IconButton>
															</Tooltip>
														</Typography>
													</div>
													<div
														style={{
															display: 'flex',
														}}
													>
														<Tooltip
															title={`Este proveedor ha participado ${itemProposal.NumberTimesParticipated} veces en la licitación`}
														>
															<Badge
																badgeContent={itemProposal.NumberTimesParticipated}
																color='primary'
																classes={{ badge: classes.badge }}
															>
																<AssistantPhoto />
															</Badge>
														</Tooltip>
														{itemProposal.ProposalIncidents.length > 0 && (
															<div
																className={classes.contentIncidens}
																onClick={() =>
																	setOpenCollapese({
																		open: !openCollapese.open,
																		proposalsId: itemProposal.Id,
																	})
																}
															>
																<Tooltip title={`Ver incidencias`}>
																	<Badge
																		badgeContent={totalIncident}
																		color='primary'
																		classes={{ badge: classes.badge }}
																	>
																		<Report />
																	</Badge>
																</Tooltip>
															</div>
														)}
													</div>
												</div>
											</div>
											<div
												style={{
													gridColumn: '2/3',
													cursor: 'pointer',
												}}
												onClick={() =>
													setOpenCollapese({
														open: !openCollapese.open,
														proposalsId: itemProposal.Id,
													})
												}
											>
												{openCollapese.open ? (
													<ExpandLessIcon className={classes.expandIcon} />
												) : (
													<ExpandMoreIcon className={classes.expandIcon} />
												)}
											</div>
										</div>
									}
								/>
								<Collapse
									className={classes.collapseContent}
									in={openCollapese.open && openCollapese.proposalsId === itemProposal.Id}
									timeout='auto'
									unmountOnExit
								>
									<div className={classes.margin}>
										<div className={classes.driverInformation}>
											<Typography gutterBottom variant='body2' component='p'>
												<strong>
													<Translate id='carrierTenders.carrierInformation.driverName' />
												</strong>{' '}
												{itemProposal.DriverName}
											</Typography>
											<Typography gutterBottom variant='body2' component='p'>
												<strong>
													<Translate id='carrierTenders.carrierInformation.driverPhoneNumber.title' />
													:
												</strong>{' '}
												{itemProposal.DriverPhoneNumber}
											</Typography>
											<Typography gutterBottom variant='body2' component='p'>
												<strong>
													<Translate id='carrierTenders.carrierInformation.bearingPlates' />:
												</strong>{' '}
												{itemProposal.BearingPlates}
											</Typography>
										</div>
										{isTypeTransportTrailer && (
											<div className={classes.transportationInformation}>
												<Typography gutterBottom variant='body2' component='p'>
													<strong>
														<Translate id='carrierTenders.carrierInformation.economicUnit' />
														:
													</strong>{' '}
													{itemProposal.EconomicUnit}
												</Typography>
												<Typography gutterBottom variant='body2' component='p'>
													<strong>
														<Translate id='carrierTenders.carrierInformation.platesBox' />:{' '}
													</strong>
													{itemProposal.PlatesBox}
												</Typography>
												<Typography gutterBottom variant='body2' component='p'>
													<strong>
														<Translate id='carrierTenders.carrierInformation.economicalBox' />
														:
													</strong>
													{itemProposal.EconomicalBox}
												</Typography>
											</div>
										)}

										<div className={classes.transportationInformation}>
											<Typography gutterBottom variant='body2' component='p'>
												<strong>
													<Translate id='carrierTenders.carrierInformation.link' />:{' '}
												</strong>
												<a href={finalUrl} target='_blank' rel='noopener noreferrer'>
													{itemProposal.League
														? 'Ver ubicación de transporte en mapa'
														: 'Link no compartido'}
												</a>
											</Typography>
											<div>
												<Typography gutterBottom variant='body2' component='p'>
													<strong>
														<Translate id='carrierTenders.carrierInformation.user' />:{' '}
													</strong>
													{itemProposal && itemProposal.UserName
														? itemProposal.UserName
														: '-'}
												</Typography>
											</div>
											<div>
												<Typography gutterBottom variant='body2' component='p'>
													<strong>
														<Translate id='carrierTenders.carrierInformation.password' />:{' '}
													</strong>
													{itemProposal && itemProposal.Password
														? itemProposal.Password
														: '-'}
												</Typography>
											</div>
										</div>
										<div className={classes.transportationInformation}>
											<Typography gutterBottom variant='body2' component='p'>
												<strong>
													<Translate id='carrierTenders.carrierInformation.linkDescription' />
													:
												</strong>
												{itemProposal.League ? itemProposal.League : 'Liga no compartida'}
											</Typography>
										</div>
									</div>
									<Divider />
									<div className={classes.incidents}>
										<Typography>
											<strong>
												<Translate id='carrierTenders.carrierInformation.incidents' />:{' '}
											</strong>
										</Typography>
										{itemProposal.ProposalIncidents.length > 0 ? (
											<DataTable
												configuration={configTable()}
												onRenderCellItem={onRenderCellItem}
												data={itemProposal.ProposalIncidents}
											/>
										) : (
											<div className={classes.emptyMessage}>
												<Typography>
													<Translate
														id={'carrierTenders.carrierInformation.notHaveIncidents'}
													/>
												</Typography>
											</div>
										)}
									</div>
								</Collapse>
							</Card>
						)
					})}
		</div>
	)
}
const onRenderCellItem = (dataSource, item) => {
	if (dataSource === 'CreationDate') {
		return <Typography variant='caption'>{moment(item.CreationDate).format('DD/MM/YYYY HH:mm A')}</Typography>
	}
}
function configTable() {
	let columns = [
		{
			header: 'Fecha incidente',
			dataSource: 'CreationDate',
		},
		{
			header: 'Comentario',
			dataSource: 'Comments',
		},
		{
			header: 'Creada por',
			dataSource: 'CreatedByName',
		},
	]
	return { columns }
}
const styles = (theme) => ({
	cardContent: {
		borderRadius: '8px',
		backgroundColor: '#F9F9F9',
		borderBottom: '2px solid #e0e0e0',
		borderRight: '2px solid #e0e0e0',
		margin: '8px',
	},
	collapseContent: {
		backgroundColor: '#F9F9F9',
		margin: '8px',
		borderRadius: '8px',
	},
	supplierIformation: {
		display: 'grid',
		gridTemplateColumns: '4fr  3fr  2fr  2fr 1fr 20px ',
		gridGap: '10px',
		margin: '5px 10px 5px 15px',
	},
	commentsIformation: {
		display: 'grid',
		gridTemplateColumns: '4fr 3fr 2fr 2fr 1fr 20px',
		gridGap: '10px',
		margin: '5px 10px 5px 15px',
	},
	selectWinner: {
		display: 'flex',
		cursor: 'pointer',
		border: '2px solid rgba(121, 169, 216, 1)' /* You can adjust the width and color */,
		justifyContent: 'center',
		alignItems: 'center',
		height: '35px',
		borderRadius: '4px',
	},
	winning: {
		display: 'flex',
		cursor: 'pointer',
		backgroundColor: '#28A745',
		justifyContent: 'center',
		alignItems: 'center',
		height: '35px',
		borderRadius: '4px',
		color: theme.palette.colors.white,
	},
	assignmentTurnedInIcon: {
		marginLeft: '15px',
		color: theme.palette.primary.main,
	},
	assignmentWinning: {
		color: theme.palette.colors.white,
		marginLeft: '15px',
	},
	titleWinning: {
		color: theme.palette.colors.white,
		marginTop: '4px',
	},
	expandIcon: {
		marginTop: '10px',
		marginRight: '10px',
	},
	driverInformation: {
		display: 'grid',
		gridTemplateColumns: '2fr  2fr  2fr  ',
		gridGap: '10px',
		margin: '5px 10px 5px 15px',
	},
	transportationInformation: {
		display: 'grid',
		gridTemplateColumns: '2fr  2fr 2fr ',
		gridGap: '10px',
		margin: '5px 10px 5px 15px',
	},
	margin: {
		margin: '5px 10px 5px 15px',
	},
	infoIcon: {
		color: theme.palette.primary.main,
		paddingLeft: '8px',
	},
	winnerSize: {
		width: '20px',
		height: 'auto',
	},
	supplierName: {
		color: '#28A745 !important',
		fontWeight: 'bold',
	},
	borderLeftCard: {
		borderLeft: '5px solid #28A745',
		borderRadius: '8px',
		backgroundColor: '#F9F9F9',
		borderBottom: '2px solid #e0e0e0',
		borderRight: '2px solid #e0e0e0',
		margin: '8px',
	},
	badge: {
		top: '50%',
		right: -3,
		// The border color match the background color.
		border: `2px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]}`,
	},
	incidenceContent: {
		height: '35px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		borderRadius: '8px',
		padding: '8px',
		color: theme.palette.common.white,
		backgroundColor: 'orange',
	},
	contentIncidens: {
		paddingLeft: '16px',
		cursor: 'pointer',
	},
	incidents: {
		marginTop: '10px',
		padding: '8px',
	},
	emptyMessage: {
		display: 'flex',
		justifyContent: 'center',
	},
	flexSpaceBetween: {
		display: 'flex',
		alignItems: 'center',
	},
	colorIcon: {
		color: theme.palette.primary.main,
	},
	colorIconWarning: {
		color: 'orange',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(ResultsTender))
