import { fromJS } from 'immutable';

import * as expedientSupplier from './ExpedientSupplierAction';

/**
 *  Initial state
 */
export const expedientSupplierInitialState = fromJS({
	isLoadingExpedientSuppliers: true,
	expedientSuppliers: null,
	expedientSupplierData: null,
	sortCriteria: {
		by: 'id',
		ascending: false
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	expedientSupplierCount: 0,
	isLoadingExpedientSupplier: false,
	expedientSupplier: null,
	isSavingExpedientSupplier: false,
	supplierExpedient: null,
	documents: [],
	supplierClassifications: [],
	isSaving: false,
	isValidateExpedient:false,
	identifier: ''
});

export default function moduleExpedientSupplierReducer(state = expedientSupplierInitialState, action) {
	switch (action.type) {
		case expedientSupplier.IS_LOADING_EXPEDIENT_SUPPLIERS_INDEX: {
			return state.merge({
				isLoadingExpedientSuppliers: action.isLoadingIndex
			});
		}
		case expedientSupplier.IS_SAVING: {
			return state.merge({
				isSaving: action.isSaving,
				identifier: action.identifier
			});
		}
		case expedientSupplier.SET_EXPEDIENT_SUPPLIERS_LIST: {
			return state.merge({
				expedientSuppliers: fromJS(action.expedientSuppliersList),
				isLoadingExpedientSuppliers: false
			});
		}
		case expedientSupplier.SET_DOCUMENTS_CLASSIFICATIONS: {
			return state.merge({
				documents: fromJS(action.documents),
				isLoadingExpedientSuppliers: false
			});
		}
		case expedientSupplier.SET_SUPPLIERS_CLASSIFICATIONS: {
			return state.merge({
				supplierClassifications: fromJS(action.classifications),
				isLoadingExpedientSuppliers: false
			});
		}
		case expedientSupplier.SET_EXPEDIENT_SUPPLIERS_COUNT: {
			return state.merge({
				expedientSupplierCount: action.count
			});
		}
		case expedientSupplier.CHANGE_EXPEDIENT_SUPPLIERS_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria
			});
		}
		case expedientSupplier.CHANGE_EXPEDIENT_SUPPLIERS_SORT_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: state.rowsPerPage
			});
		}
		case expedientSupplier.CHANGE_EXPEDIENT_SUPPLIERS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage
			});
		}
		case expedientSupplier.IS_SAVING_EXPEDIENT_SUPPLIER: {
			return state.merge({
				isSavingExpedientSupplier: action.isSavingExpedientSupplier
			});
		}
		case expedientSupplier.IS_VALIDATE_EXPEDIENT_SUPPLIER: {
			return state.merge({
				isValidateExpedient: action.isSaving
			});
		}
		
		case expedientSupplier.IS_LOADING_EXPEDIENT_SUPPLIER: {
			return state.merge({
				isLoadingExpedientSupplier: action.isLoadingExpedient
			});
		}
		case expedientSupplier.SET_EXPEDIENT_SUPPLIER: {
			return state.merge({
				isLoadingExpedientSupplier: false,
				expedientSupplier: fromJS(action.expedientSupplier)
			});
		}
		case expedientSupplier.SET_SUPPLIER_EXPEDIENT: {
			return state.merge({
				isLoadingExpedientSupplier: false,
				supplierExpedient: fromJS(action.supplierExpedient)
			});
		}
		case expedientSupplier.UPLOAD_FILES_EXPEDIENT_SUPPLIER: {
			return state.updateIn(['supplierExpedient', 'file_update_suppliers'], (files) => {
				let index = files.findIndex((item) => {
					return item.get('token') === action.fileExpedient.token;
				});
				return files.setIn([index], fromJS(action.fileExpedient)).set('isSavingExpedientSupplier', false);
			});
		}
		case expedientSupplier.ADD_DOCUMENT_TYPE: {
			let data = fromJS(action.documentClassification);
			return state.updateIn(['documents'], (allDocuments) => pushDocument(allDocuments, data)).set('isSaving', false);
		}
		case expedientSupplier.ADD_DOCUMENT_IN_CLASSIFICATION: {
			let classificationToken = action.classificationToken;
			let data = fromJS(action.newDocuement);
			return state
				.updateIn(['supplierClassifications'], (allSupplierClassification) =>
					pushDocumentInClassification(allSupplierClassification, classificationToken, data)
				)
				.set('isSaving', false);
		}
		case expedientSupplier.REMOVE_DOCUMENT_IN_CLASSIFICATION: {
			let classificationToken = action.classificationToken;
			let documentToken = action.documentToken;
			return state
				.updateIn(['supplierClassifications'], (allSupplierClassification) =>
					deleteDocumentInClassification(allSupplierClassification, classificationToken, documentToken)
				)
				.set('isSaving', false);
		}
		case expedientSupplier.REMOVE_EXPEDIENT_DOCUMENT: {
			return state
				.updateIn(['documents'], (allDocuments) => allDocuments.filter((x) => x.get('token') !== action.token))
				.set('isSaving', false);
		}
		case expedientSupplier.UPDATE_PROPERTY_EXPEDIENT_FILE: {
			let supplierToken = action.supplierToken;
			let fileToken = action.fileToken;
			let propertyName = action.propertyName;
			let propertyValue = fromJS(action.propertyValue);
			return state
				.updateIn(['expedientSupplier', 'expedient_update_for_suppliers'], (allExpedientUpdate) =>
					updateExpedientSupplier(allExpedientUpdate, supplierToken, fileToken, propertyName, propertyValue)
				)
				.set('isSaving', false);
		}
		case expedientSupplier.UPDATE_FILE_IN_EXPEDIENT_SUPPLIER: {
			let token = action.token;
			let propertyName = action.propertyName;
			let propertyValue = fromJS(action.propertyValue);
			return state
				.updateIn(['documents'], (allDocuments) =>
					updatePropertyDocuments(allDocuments, token, propertyName, propertyValue)
				)
				.set('isSaving', false);
		}
		case expedientSupplier.REMOVE_EXPEDIENT_CLASSIFICATION:{
			return state.updateIn(['supplierClassifications',], allSupplierClassification => allSupplierClassification.filter(classification => classification.get('token') !== action.token)).set('isSaving', false);
		}
		default:
			return state;
	}
}

const pushDocument = (allDocuments, newDocument) => {
	let indexOfDocument = allDocuments.findIndex((d) => d.get('token') === newDocument.get('token'));
	if (indexOfDocument >= 0) {
		return allDocuments.setIn([indexOfDocument], newDocument);
	}
	return allDocuments.push(newDocument);
};

const updateExpedientSupplier = (allExpedientUpdate, supplierToken, fileToken, propertyName, propertyValue) => {
	let indexOfDocument = allExpedientUpdate.findIndex((d) => d.get('token') === supplierToken);
	if (indexOfDocument >= 0) {
		let allFileUpdateSuppliers = allExpedientUpdate.get(indexOfDocument).get('file_update_suppliers');
		let indexOfFile = allFileUpdateSuppliers.findIndex((d) => d.get('token') === fileToken);
		if (indexOfFile >= 0) {
			return allExpedientUpdate.setIn(
				[indexOfDocument, 'file_update_suppliers', indexOfFile, propertyName],
				propertyValue
			);
		}
	}
	return allExpedientUpdate;
};

const pushDocumentInClassification = (allSupplierClassification, classificationToken, newDocument) => {
	let indexOfClassification = allSupplierClassification.findIndex((d) => d.get('token') === classificationToken);
	if (indexOfClassification >= 0) {
		let classificationSupplier = allSupplierClassification.get(indexOfClassification);
		if (classificationSupplier !== undefined) {
			let allDocuments = classificationSupplier.get('Documents');
			allDocuments = allDocuments.push(newDocument);
			return allSupplierClassification.setIn([indexOfClassification, 'Documents'], allDocuments);
		}
	}
	return allSupplierClassification;
};

const deleteDocumentInClassification = (allSupplierClassification, classificationToken, documentToken) => {
	let indexOfClassification = allSupplierClassification.findIndex((d) => d.get('token') === classificationToken);
	if (indexOfClassification >= 0) {
		let classificationSupplier = allSupplierClassification.get(indexOfClassification);
		if (classificationSupplier !== undefined) {
			let allDocuments = classificationSupplier.get('Documents');
			allDocuments = allDocuments.filter((x) => x.get('token') !== documentToken);
			return allSupplierClassification.setIn([indexOfClassification, 'Documents'], allDocuments);
		}
	}
	return allSupplierClassification;
};

const updatePropertyDocuments = (allDocumnets, token, propertyName, propertyValue) => {
	let indexOfDocument = allDocumnets.findIndex((d) => d.get('token') === token);
	if (indexOfDocument >= 0) {
        return allDocumnets.setIn([indexOfDocument, propertyName], propertyValue);
	}
	return allDocumnets;
};
