/**
 * Function to handle onKeyDown Enter event
 *
 * @export
 * @param {*} event
 */
export function onKeyDown(event) {
    if (event.key === "Enter")
        document.activeElement.blur();
}

/**
 * Function to handle onFocus event in input and autoselect all text
 *
 * @export
 * @param {*} event
 */
export function handleFocus(event) {
    event.preventDefault();
    const { target } = event;
    let currentInputType = target.type;
    if (currentInputType !== "text")
        target.type = "text";
    const extensionStarts = target.value.lastIndexOf('.');
    target.setSelectionRange(0, extensionStarts);
    target.type = currentInputType;
}
