/** Helpers import section */
import axios from 'axios';
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper';

export const IS_SAVING_FAILURE_GUARANTEE = "IS_SAVING_FAILURE_GUARANTEE";
export const ADD_FAILURE_GUARANTEE_TO_LIST = "ADD_FAILURE_GUARANTEE_TO_LIST";
export const START_LOADING_FAILURE_GUARANTE = "START_LOADING_FAILURE_GUARANTE";
export const SET_FAILURE_GUARANTEE_TO_LIST = "SET_FAILURE_GUARANTEE_TO_LIST";
export const SET_FAILURE_GUARANTEE_COUNT = "SET_FAILURE_GUARANTEE_COUNT";
export const CHANGE_FAILURE_GUARANTEE_SORT_CRITERIA = "CHANGE_FAILURE_GUARANTEE_SORT_CRITERIA";
export const CHANGE_FAILURE_GUARANTEE_SORT_QUERY = "CHANGE_FAILURE_GUARANTEE_SORT_QUERY";

/**
 * Action to set flag isSaving 
 *
 * @param {*} isSaving
 * @returns
 */
function isSavingFailureGuarantee(isSaving) {
    return {
        type: IS_SAVING_FAILURE_GUARANTEE,
        isSaving
    }
}

/**
 * Action to add customer guarantee to the list guarantees
 *
 * @param {*} nonConformity
 * @returns
 */
function addFailureGuaranteeToList(failureGuarantee) {
    return {
        type: ADD_FAILURE_GUARANTEE_TO_LIST,
        failureGuarantee
    }
}


/**
 * Action start loading failure guarantee
 * @param {*} isLoading 
 */
function isLoading(isLoading) {
    return {
        type: START_LOADING_FAILURE_GUARANTE,
        isLoading
    }
}

/**
 *Action add customer guarantee to list
 *
 * @param {*} customerGuaranteeData
 * @returns
 */
function setCustomerGuaranteeList(failureGuaranteeData) {
    return {
        type: SET_FAILURE_GUARANTEE_TO_LIST,
        failureGuaranteeData
    }
}

/**
 * Action to set failure guarantee count
 */
function setFailureGuaranteeCount(count) {
    return {
        type: SET_FAILURE_GUARANTEE_COUNT,
        count
    }
}


/**
 * Action to add customer to guarantee
 *
 * @export
 * @param {*} customerGuarantee
 * @returns
 */
export function addFailureGuaranteeData(failureGuarantee) {

    return function (dispatch, getState) {
        dispatch(isSavingFailureGuarantee(true));
        let selectedCompanyToken = getState().profile.get("selectedCompany").get('token');
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/failureguarantee`;
        let data = {
            Name: failureGuarantee.name,
            NameEnglish: failureGuarantee.name_english,
            NamePortuguese: failureGuarantee.name_portuguese,
            CompanyToken: selectedCompanyToken,
        }
        return axios.post(endPoint, data)
            .then((response) => {
                dispatch(addFailureGuaranteeToList(response.data))
            })
            .catch((error) => {
                console.error("Error to add failure guarantee to list", error.response);
                dispatch(isSavingFailureGuarantee(false));
                return Promise.reject();
            })
    }
}

/**
 * Action to get a list of failure guarantee catalog by filters
 */
export function loadFailureGuaranteeFromBackEnd(query, orderBy, sortAscending) {
    return function (dispatch, getState) {

        dispatch(isLoading(true));
        let selectedCompany = getState().profile.get("selectedCompany");

        if(selectedCompany)
        {
            let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
            let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/failureguarantee/filters`;
            let data = {
                CompanyToken: selectedCompany.get("token"),
                SortBy: orderBy,
                SortAscending: sortAscending,
                Query: query,
                Page: 1,
                RowsPerPage: 50,
            }
            return axios.post(endPoint, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setCustomerGuaranteeList(serverResponse.data))
                    }
                })
                .catch((error) => {
                    dispatch(isLoading(false));
                    console.error("Error getting the failure guarantee  list", error.response)
                })
        }
        else{
            dispatch(isLoading(false));

        }
    }
}

/**
 *Action to get failure guarantee count
 *
 * @export
 * @returns
 */
export function getFailureGuaranteeCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().failureGuarantee ? getState().failureGuarantee.get('failureGuaranteeCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        if(selectedCompany)
        {
            let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/failureguarantee/count`;
            let data ={
                CompanyToken: selectedCompany.get("token")
            }
            return axios.post(endPoint, data, configuration)
                .then((serverResponse) => {
                    if (serverResponse.status === 200) {
                        dispatch(setFailureGuaranteeCount(serverResponse.data))
                    }
                })
                .catch((error) => {
                    console.error("Error getting the failure guarantee count", error.response)
                })
        }
    }
}

/**
 * Action to update failureguarantee
 *
 * @export
 * @param {*} customerGuarantee
 * @returns
 */
export function updateFailureGuaranteeData(customerGuarantee) {

    return function (dispatch, getState) {
        dispatch(isSavingFailureGuarantee(true));
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/failureguarantee`;
        let data = {
            TokenFailure: customerGuarantee.token,
            Name: customerGuarantee.name,
            NameEnglish: customerGuarantee.name_english,
            NamePortuguese: customerGuarantee.name_portuguese,
        }
        return axios.put(endPoint, data)
            .then((response) => {
                dispatch(isSavingFailureGuarantee(false));
            })
            .catch((error) => {
                console.error("Error to update failure guarantee", error.response);
                dispatch(isSavingFailureGuarantee(false));
                return Promise.reject();
            })
    }
}


/**
 * Action to remove failure guarantee
 * @param {*} company 
 */
export function onRemoveFailureGuaranteeFromBackEnd(token) {
    return function (dispatch, getState) {

        dispatch(isSavingFailureGuarantee(true));

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_QUALITY}/failureguarantee/${token}`;

        return axios.delete(endPoint, configuration)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(isSavingFailureGuarantee(false));
                }
                else{
                    dispatch(isSavingFailureGuarantee(false));
                    return Promise.reject(response);

                }
            })
            .catch((error) => {
                dispatch(isSavingFailureGuarantee(false));
                return Promise.reject(error.response);
            })
    }
}
