import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import { Button, LinearProgress, TextField, withStyles } from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'

/** Custom components import section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import Toaster from '../../../common/Toaster'

/** Redux import section */
import { useDispatch } from 'react-redux'
import { createWorkshop } from '../../../../store/accreditedWorkshops/accreditedWorkshopsActions'

/** Resources import section */
import { showErrorToaster } from '../../../../store/helpers/ToasterHelpers'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'

const AddingWorkshopPanel = (props) => {
	const { opened, onClose = () => console.warn('[onClose] not defined!'), classes } = props
	/** Defines local state */
	const [saving, setSaving] = useState(false)
	const [workshop, setWorkshop] = useState({ sapCode: '', name: '' })
	const [toasterState, setToasterState] = useState({ showToaster: false })

	const dispatch = useDispatch()

	const updateProperty = (event) => {
		const { value, name } = event.target
		setWorkshop((prevState) => ({ ...prevState, [name]: value }))
	}

	const isValidWorkshop = () => {
		const { sapCode, name } = workshop
		if (IsNullOrEmpty(sapCode) || IsNullOrEmpty(name)) return false
		return true
	}

	const addWorkshop = (event) => {
		setSaving(true)
		event.preventDefault()
		dispatch(createWorkshop(workshop))
			.then(() => onClose())
			.catch(() => setToasterState(showErrorToaster()))
			.finally(() => setSaving(false))
	}

	if (!opened) return null
	return (
		<LateralPanel onClose={onClose}>
			<PanelHeader
				isIconMaterial
				titlePanel={<Translate id='accreditedWorkshops.addWorkshop' />}
				onCloseButtonClick={onClose}
				icon={<BuildIcon color='primary' />}
			/>
			<div className={classes.formContainer}>
				<form onSubmit={addWorkshop} autoComplete='off'>
					<TextField
						fullWidth
						label={<Translate id='accreditedWorkshops.workshopCode' />}
						name='sapCode'
						onChange={updateProperty}
						className={classes.textField}
					/>
					<TextField
						fullWidth
						label={<Translate id='accreditedWorkshops.workshopName' />}
						name='name'
						onChange={updateProperty}
						className={classes.textField}
					/>
					{saving && <LinearProgress />}
					<Button
						className={classes.btnSubmit}
						type='submit'
						variant='contained'
						disabled={saving || !isValidWorkshop()}
						fullWidth
					>
						<Translate id={'accreditedWorkshops.create'} />
					</Button>
				</form>
			</div>
			<Toaster
				message={<Translate id={toasterState.toasterMessage} />}
				open={toasterState.showToaster}
				variant={toasterState.toasterVariant}
				onClose={() => setToasterState({ showToaster: false })}
			/>
		</LateralPanel>
	)
}

AddingWorkshopPanel.propTypes = {
	opened: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
}

const styles = (theme) => ({
	formContainer: {
		padding: theme.spacing.unit,
	},
	textField: {
		marginBottom: theme.spacing.unit,
	},
	btnSubmit: {
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
})

export default withStyles(styles, { withTheme: true })(AddingWorkshopPanel)
