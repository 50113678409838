import React, {useState} from 'react';
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

/**Import MaterialUi section */
import {Grid, Button, Avatar, Typography,TextField} from '@material-ui/core'
import AddNoticetIcon from '@material-ui/icons/AddTwoTone';

/** section import components */

/**Import components section */
import LateralPanel from '../../common/LateralPanelComponents/LateralPanel';
import PanelHeader from '../../common/LateralPanelComponents/PanelHeader';
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import Evidence from '../../common/Evidence'


const AddToolingModal = props => {
    /**props section */
    const {
        onCloseButtonClick,
        classes,
        isSaving,
        onSaveItem,
        canBeAddFiles,
        itemToolingSelected,
        canBeEditWarehouse
    } = props


    const [itemModel, setItemModel] = useState(
        {
            code: '',
            description: '',
            quality: '',
            position: '',
            mu: ''
        }
    );
    const [attachments, setAttachments] = useState(itemToolingSelected ? (itemToolingSelected.attachments ? itemToolingSelected.attachments : []): [])

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setItemModel({
            ...itemModel,
            [name]: value
        });
    }

    /**
     * can be add
     */
    const canBeSaved =() =>{
        if(IsNullOrEmpty(itemModel.code))
        return false;
        if(IsNullOrEmpty(itemModel.description))
            return false;
        if (IsNullOrEmpty(itemModel.position))
            return false;
        if (IsNullOrEmpty(itemModel.mu))
            return false;
        else
            return true;
    }

    /**
     * Function on update
     */
     const onSaveItemUpdate = () =>{
        if(props.onSaveItem)
        onSaveItem(itemModel);
    }

    const uploadFiles = (files) => {
        let maquilaOrderToken = props.match.params.token;
        let filesList = attachments.concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
            return props.onUploadFilesToolings(maquilaOrderToken, itemToolingSelected.token, files)
                .then(() => {
                    setAttachments(filesList)
                }).catch(() => {
                    return Promise.reject();
                });
            }
        return Promise.resolve('Ok');
    }
    
    /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
    */
    const removeFiles = (fileName) => {
        let maquilaOrderToken = props.match.params.token;
        let newList = attachments.filter(files => {
            return files.name !== fileName
        })
        return props.onDeleteFilesTooling(maquilaOrderToken, itemToolingSelected.token, fileName)
        .then(() => {
            setAttachments(newList)
            return Promise.resolve('Ok');
        }).catch(() => {
            return Promise.reject();
        });
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}>
            <PanelHeader
                titlePanel={canBeAddFiles ? "Marchivos Herramentes" : <Translate id="maquilaOrders.addTooling" />}
                icon={<Avatar className={classes.avatar}><AddNoticetIcon /></Avatar>}
                onCloseButtonClick={onCloseButtonClick}
                isIconMaterial={true}
            />
            {!canBeAddFiles && <div>
                <div className={classes.containerInformation}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{"Material"}</Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                onChange={onPropertyChange}
                                name='code'
                                margin="normal"
                                variant="outlined"
                                defaultValue={itemModel.code}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{"Descripción"}</Typography>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                onChange={onPropertyChange}
                                name='description'
                                margin="normal"
                                variant="outlined"
                                defaultValue={itemModel.description}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{"Posición"}</Typography>
                            <TextField
                                fullWidth
                                label={"Posición"}
                                className={classes.textField}
                                onChange={onPropertyChange}
                                name='position'
                                margin="normal"
                                variant="outlined"
                                defaultValue={itemModel.position}
                            />
                        </Grid>
                        <Grid container xs={12} justifyContent="center" alignItems="center">
                            <Grid item xs={6}>
                                <Typography>{"Cantindad"}</Typography>
                                <TextField
                                    className={classes.textField}
                                    onChange={onPropertyChange}
                                    name='quality'
                                    margin="normal"
                                    type='number'
                                    step={0.5}
                                    variant="outlined"
                                    defaultValue={itemModel.quality}                               
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>{"Unidad de medida"}</Typography>
                                <TextField
                                    label={"Unidad de medida"}
                                    className={classes.textField}
                                    onChange={onPropertyChange}
                                    name='mu'
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue={itemModel.mu}   
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Grid container>
                    <Grid item xs={12}>
                        <Button
                            data-cy="btn-save"
                            type="button"
                            color="primary"
                            fullWidth
                            variant='outlined'
                            className={classes.saveButton}
                            onClick={onSaveItemUpdate}
                            disabled={!canBeSaved() || isSaving}>
                            {<Translate id="common.save" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>}
            {canBeAddFiles && <div className={classes.containerInformation}>
                <Grid item xs={12}>
                    <Typography color='primary'>{"Archivos de herramentales"}</Typography>
                    <Evidence
                        text={props.dropzoneText}
                        files={attachments}
                        uploadFiles={uploadFiles}
                        removeFiles={removeFiles}
                        baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
                        isDisabled={!canBeEditWarehouse}
                    />
                </Grid>
            </div>}
        </LateralPanel >
    );
};

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    containerInformation: {
        maxHeight: `calc(100vh - ${155}px)`,
        minHeight: `calc(100vh - ${155}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    saveButton: {
        bottom: 0
    },
    textTitle:{
        marginLeft:"5px",
    }
});

AddToolingModal.propTypes = {
    /**
     * close modal
     */
    onCloseButtonClick: propTypes.func,
};

AddToolingModal.defaultProps = {
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
}

export default withRouter(withStyles(styles, { withTheme: true })(AddToolingModal))