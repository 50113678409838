/**Import react section */
import React, {useState} from "react";

//import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { Card, withStyles, Fab, Typography } from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { Translate } from 'react-localize-redux';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/*Import pdf result */
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfResultDocument from '../PDFs/PDFRecognition/PdfResultDocument';

/**Import components section*/
import PercentageComponent from './PercentageComponent';
import HeaderResult from '../results/HeaderResult';
import PanelRight from '../results/PanelRight'

/**Import resources section*/
import SummaryView from '../../../resources/images/SummaryView.png'

/**Import actions section */
const Summary = (props) => {
  const { classes, sustainability, questionnaire,
          isAudit, totalWorkDetail, updateProperty, canBeActiveResult } = props;

  const [checked, setChecked] = useState(sustainability.IsActiveRecognition); 
  const [canBeDownloadPdf, setCanBeDownloadPdf] = useState(false); 
  
  const toggleChecked = () => {
    setChecked(!checked);
    updateProperty('IsActiveRecognition', !checked)
  };

return (
  <Card>
    <div className={classes.summaryView}>
      <div className={classes.panelRight}>
        <PanelRight 
          totalRatingSupplier={questionnaire.TotalRatingSupplier}
          totalWorkDetail={totalWorkDetail}
          updateProperty={updateProperty}
          sustainability={sustainability}
          isSummary={true}
          canBeActiveResult={canBeActiveResult}
          isApproved={sustainability.IsValidAprovedSupplier}
        />
      </div>

      <div className={classes.cardRight}>
        <HeaderResult
          topics={questionnaire.Topics}
          isSummary={true}/>

        <div className={classes.panelInformation}>
          <div className={classes.totalRactingTable}>
            <div className={classes.ractingColumn}>
              <PercentageComponent
                totalPercentage={sustainability.TotalRatingActionPlan}
                title={<Translate id='sustainability.percentageActionPlan'/>}
                isApproved={sustainability.IsAprovedActionPlan}
                isSummary={true}
              />
            </div>
            <div className={classes.ractingColumn}>
              {isAudit &&  <PercentageComponent
                totalPercentage={questionnaire.RatingAuditor}
                title={<Translate id="sustainability.ratingAudit" />}
                isApproved={sustainability.IsValidAprovedAudit}
                isSummary={true}
              />}
            </div>
            <div className={classes.ractingColumn}>
              <PercentageComponent
                totalPercentage={sustainability.TotalRatingFinding}
                title={<Translate id='sustainability.percentageFidding'  />}
                isApproved={sustainability.IsAprovedCloseFindings}
                isSummary={true}
              />
            </div>
          </div>
          <div className={classes.panelImagen}>
            <img src={SummaryView} alt="certificate" className={classes.certificateImg}/>
            
            {canBeActiveResult && 
            <div className={classes.switchPanel}>
              <div className={classes.switchName}>
                {<Translate id='sustainability.switchCertificate'/>}
              </div>
              <div className={classes.switchMaterial}>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={checked}
                      onChange={toggleChecked}/>}/>
              </div>
            </div>}
          </div>
        </div>

        <div className={classes.certificateDownload}>
          <div className={classes.certificateSummary}>
                {<Translate id='sustainability.resultCertificate'/>}
          </div>
            <div className={classes.certificateButtonSummary}>
              {canBeActiveResult && 
                <>
                 {!canBeDownloadPdf ? 
                  <Fab variant="extended" size="small" color="secondary" onClick={() => setCanBeDownloadPdf(true)}>
                    <DownloadIcon className={classes.downloadIcon}/><Typography className={classes.tetButb} variant="caption" >{<Translate id='sustainability.generateRecognition'/>}</Typography> 
                  </Fab>
                  :
                  <PDFDownloadLink
                      document={<PdfResultDocument
                      sustainability={sustainability}/>} 
                      fileName={`Reconocimiento.pdf`}>
                        {({ blob, url, loading, error }) => (<Fab variant="extended" size="small" color="secondary">
                      {loading ? "Cargando..." : <DownloadIcon className={classes.downloadIcon}/>}{
                        <Typography className={classes.tetButb} variant="caption" >  {<Translate id='sustainability.downloadRecognitionReport'/>}</Typography> }
                  </Fab>)}
                  </PDFDownloadLink>
                }
                </>
              }
              {!canBeActiveResult && checked &&
                <>
                 {!canBeDownloadPdf ? 
                  <Fab variant="extended" size="small" color="secondary" onClick={() => setCanBeDownloadPdf(true)}>
                    <DownloadIcon className={classes.downloadIcon}/><Typography className={classes.tetButb} variant="caption" >{<Translate id='sustainability.generateRecognition'/>}</Typography> 
                  </Fab>
                  :
                  <PDFDownloadLink
                      document={<PdfResultDocument
                      sustainability={sustainability}/>} 
                      fileName={`Reconocimiento.pdf`}>
                        {({ blob, url, loading, error }) => (<Fab variant="extended" size="small" color="secondary">
                    {loading ? "Cargando..." : <DownloadIcon className={classes.downloadIcon}/>}
                    {<Typography className={classes.tetButb} variant="caption" >  {<Translate id='sustainability.downloadRecognitionReport'/>}</Typography> }
                  </Fab>)}
                  </PDFDownloadLink>
                }
                </>
              }
            </div> 
        </div>

      </div>
    </div>
  </Card>
)};

const styles = (theme) => ({
  panelRight: {
    marginTop: '2px',
    display: "flex",
  },
  cardRight: {
  },
  tetButb: {
    marginLeft: '2px',
    color:"white"
  },
  panelInformation: {
    display: 'flex',    
  },
  panelImagen: {
    flex: '1 1 0',
    textAlign: 'center',
  },
  totalRactingTable: {
    marginLeft: '8px',
    flex: '1 1 0',
  },
  summaryView: {
    display: "flex",
  },
  ractingColumn: {
    marginTop: '20px',
  },
  certificateImg: {
    width: '65%',
    marginTop: "20px",
  },
  switchPanel: {
    display: 'row',
  },
  switchName: {
    textAlign: 'center',
    color: 'black',
    fontSize: '10',
    marginTop: '3px',
    marginBottom: '3px',
  },
  switchMaterial: {
    textAlign: 'center',
  },
  certificateDownload: {
    textAlign: 'center',
    marginTop: '10px',
    marginLeft: '8px',
    marginBottom: '5px',
    display: 'flex',
  },
  certificateSummary: {
    color: 'gold',
    fontWeight: 'bold',
    fontSize: '16px',
    marginTop: '25px',
    marginBottom: '10px',
    marginLeft: '20px',
    textAlign: 'Left',
  },
  certificateButtonSummary: {
    marginTop: '20px',
    marginLeft: '90px',
  },
});

Summary.propTypes = {
 
};


export default withRouter(
  withStyles(styles, { withTheme: true })(Summary)
);
