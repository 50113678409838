
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import {Grid, Paper } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';

/** Import component section */
import Dialog from '../../common/Dialog';
import DialogActions from '../../common/DialogActions';
import Evidence from './../../common/Evidence';
import {SHOW_EDIT_VIEW} from '../../../store/helpers/ResourcesHelper';
import downloadTemplate from '../../../resources/files/Formato_Cargar_Proveedores.xlsx'

/**
 * Dialog to UploadSuppliersExclude
 */
class UploadSuppliersExclude extends Component {

      /**
     *Creates an instance of UploadSuppliersExclude.
     * @param {*} props
     * @memberof DialogActions
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            attachments: [],
        }
    }

        /**
     * Validate if form has full required data to save
     *
     * @UploadSuppliersExclude
     */
    canBeSaved() {
        if (this.state.attachments.length <= 0)
            return false
        return true
    }


    /**
     * Handle event upload files of devidence componet to add files to list
     *
     * @param {*} files
     * @returns
     * @memberof UploadSuppliersExclude
     */
    uploadFiles(files) {
        let filesList = this.state.attachments.concat(files[0]);
        if (files.length > 0) {
            for (var index = 1; index < files.length; index++) {
                filesList.push(files[index])
            }
        }

        this.setState({
            attachments: filesList
        })
        return Promise.resolve('Ok')
    }

        /**
     * Handle event remove files of evidence componet to remove files of list
     *
     * @param {*} fileName
     * @returns
     * @memberof UploadSuppliersExclude
     */
    removeFiles(fileName) {
        let newList = this.state.attachments.filter(files => {
            return files.name !== fileName
        })
        this.setState({
            attachments: newList
        })
        return Promise.resolve('Ok')
    }

        /**
     * Handle onsave event of modal to send data to backend and save action
     *
     * @memberof AddActionToListDialog
     */
    handleOnSave() {
        if (this.canBeSaved()){
            this.props.uploadListSuppliersExclude(this.state.attachments)
            .then(response => {
               this.setState({ attachments: []})
            })
            .catch(error => {
            })
        }
    }


    /**
     * Dialog to update information
     */
    render(){
        const { classes } = this.props;
        return(
            <Paper style={{ overflowX: 'hidden' }}>
            <Dialog
                style={{ overflowX: 'hidden' }}
                open={this.props.open}
                onClose={this.props.onClose}
                header={
                    <Toolbar>
                        <Typography variant="h6">
                            {<Translate id="officialNotices.downloadTemplate" />}
                        </Typography>
                    </Toolbar>
                }

                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={() => this.handleOnSave()}
                        isSaving={this.props.isSaving}
                        canBeSaved={!this.state.attachments.length <= 0 || this.props.isSaving}
                    />
                }>
                <Grid container style={{ maxWidth: 500}} justify='space-between'>
                    <Grid item xs={12} >
                        <Typography variant="subtitle2" color={'textSecondary'}> 
                        <span className="text-muted"> 
                            <DownloadIcon fontSize="small" color="secondary" style={{ margin: '5px', marginBottom:'8px' }} /> 
                            <a href={downloadTemplate}><Translate id="officialNotices.downloadTemplate"/></a>
                        </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Evidence
                            text={this.props.dropzoneText}
                            baseApi={process.env.REACT_APP_SIP_URL_FILES}
                            removeFiles={this.removeFiles}
                            uploadFiles={this.uploadFiles}
                            files={this.state.attachments}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Paper>
        )
    }
}


const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "red"
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    // searchInput:{ },
    // actionsToolbar:{ },
    // showingUsersCounter:{ },
    // supplierBadge:{},
});


export default withStyles(dialogStyles, { withTheme: true })(UploadSuppliersExclude);