/**Import react section */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
/**Import MaterialUi section */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, List } from '@material-ui/core'
import LinkMaterial from '@material-ui/core/Link'
/**Import components section */

/**Import store section */

const DialogProspect = (props) => {
	/**props section */
	const { handleOpen, open, prospect } = props

	return (
		<Dialog
			open={open}
			onClose={<Button onClick={handleOpen}>Cerrar</Button>}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>
				<strong style={{ color: '#3b73af' }}>{'Mensaje que se enviara de alta proveedor'}</strong>
			</DialogTitle>
			<DialogContent dividens>
				<div>
					<strong style={{ color: '#3b73af' }}>ESTIMADO PROVEEDOR:</strong>
					<br />
					<br />
					Le damos la bienvenida a nuestro sistema, le informamos que su razón social quedó registrada bajo el{' '}
					<strong> No. De proveedor:</strong> {prospect.supplier_code}, <br />
					<strong> RAZON SOCIAL:</strong> {prospect.prospect_name}
					<br />
					<br />
					<div>Aquí la información para gestión de facturas.</div>
					<br />
					<div>Favor de coordinarse con su contacto sobre la entrada de sus facturas en almacén.</div>
					<br />
					<div>
						<strong style={{ color: '#3b73af' }}> XPERTAL:</strong>
					</div>
					<div>
						1. Ingresar a la liga:
						<a href='https://proveedores.cscsa.com.mx/Page/frmLogin.aspx'>
							https://proveedores.cscsa.com.mx/Page/frmLogin.aspx
						</a>
						<br />
						2. Para más detalles ver el manual adjunto ( EMPAQUE PLUS: manual de portal de proveedores
						versión 1.0, EMPAQUE: Guía rápida del portal de proveedores adenda manual).
					</div>
					<br />
					<br />
					<div>En este portal podrá realizar las siguientes tareas:</div>
					<br />
					<div>
						<strong style={{ color: '#3b73af' }}>
							○ Subir facturas para pago. <br />
							○ Consultar estatus de las facturas.
							<br />
							○ Subir notas de crédito
							<br />
							○ Generar ticket para aclaraciones
							<br />
						</strong>
						<br />
						<br />
						1. Ingreso al portal:{' '}
						<a href='https://www.youtube.com/watch?v=gGIshXKn6co'>
							{' '}
							https://www.youtube.com/watch?v=gGIshXKn6co{' '}
						</a>{' '}
						<br />
						2. Carga de factura:{' '}
						<a href='https://www.youtube.com/watch?v=ZrC-Jvs5Y1'>
							{' '}
							https://www.youtube.com/watch?v=ZrC-Jvs5Y1{' '}
						</a>{' '}
						<br />
						3. Consulta de factura:{' '}
						<a href='https://www.youtube.com/watch?v=g1vlcCO0l0Q'>
							{' '}
							https://www.youtube.com/watch?v=g1vlcCO0l0Q{' '}
						</a>
						<br />
						4. Aclaraciones:{' '}
						<a href='https://www.youtube.com/watch?v=yt_8nca7Usk&t=18s'>
							{' '}
							https://www.youtube.com/watch?v=yt_8nca7Usk&t=18s{' '}
						</a>{' '}
						<br />
						5. Canal de YouTube:{' '}
						<a href='https://www.youtube.com/channel/UCZ9OJdrwO5jK9r-0b4oiJNQ'>
							{' '}
							https://www.youtube.com/channel/UCZ9OJdrwO5jK9r-0b4oiJNQ{' '}
						</a>{' '}
						<br />
						<br />○ Si tienes alguna duda puedes llamar al Call Center de proveedores 8112921000 ext.1423
						opción 2.
					</div>
					<br />
					<div>
						<strong style={{ color: '#3b73af' }}>SIP, (Sistema de Información de Proveedores)</strong>
					</div>
					<br />
					<div>
						1. Ingresar a la liga:
						<a href='https://sip.efemsa.com/v2.0/portal/'> https://sip.efemsa.com/v2.0/portal/ </a> <br />
						2. El usuario del administrador es:{' '}
						<strong style={{ color: '#DC3545' }}>número de proveedor-admin</strong> <br />
						3. Solicitar restauración de contraseña.
						<br />
						4. La contraseña llegará al correo electrónico del contacto de ventas.
						<br />
						5. Para más detalles ver el manual adjunto.
						<br />
					</div>
					<br />
					<div>En este portal podrá realizar las siguientes tareas:</div>
					<div>
						○ Consultar entradas de almacén para captura de factura. <br />
						○ Seguimiento a pagos. <br />
						○ Consulta de órdenes de compra. <br />
						○ Enviar cotizaciones. <br />○ Ordenes de entrega (requisito indispensable para entrega, ver
						manual anexo){' '}
						<strong style={{ color: '#DC3545' }}>
							(requisito indispensable para entrega, ver manual anexo)
						</strong>{' '}
						<br />
						○ Acciones Correctivas. <br />
						○ Seguimiento a Evaluación de Proveedores. <br />
					</div>
					<br />
					<br />
					<div>
						Le agradecemos registrase en ambos portales.
						<br />
						<br />
						Cualquier duda o comentario estamos a sus órdenes.
						<br />
						<br />
						Saludos!!!
						<br />
						<br />
					</div>
					<List component='div' disablePadding>
						<ListItem component='li'>
							<strong style={{ color: '#3b73af' }}>Documentos a Enviar</strong>
						</ListItem>
						{prospect.aditional_files.map((document) => {
							return (
								<React.Fragment key={document.name}>
									<ListItem key={document.name} component='li'>
										<LinkMaterial
											component={'a'}
											style={{ marginLeft: '3rem' }}
											target='_blank'
											href={`${process.env.REACT_APP_SIP_URL_ADMINISTRATION}/${document.relativePath}`}
										>
											{'  '}
											{document.name}
										</LinkMaterial>
									</ListItem>
								</React.Fragment>
							)
						})}
					</List>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleOpen}>Cerrar</Button>
			</DialogActions>
		</Dialog>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.primary.main,
	},

	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputProps: {
		height: 6,
	},
	titles: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: '7px',
		marginBottom: 5,
	},
	margin: {
		margin: '15px 15px 15px 15px',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(DialogProspect))
