/**Import react section */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**Import material UI Section*/
import { withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import ImportMaterialIcon from "@material-ui/icons/Category";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import DownloadIcon from "@material-ui/icons/CloudDownload";
/**Import components section*/
import LoadingPage from "../../common/LoadingPage";
import TabPanel from "../../common/TabPanel";
import ServicesOrMaterialContainer from "./materials/ServicesOrMaterialContainer";
import AddSuppliersComponent from "./suppliers/SuppliersComponent";
import DivisaComponent from "./suppliers/DivisaComponent";
import BasesContainer from "./sections/BasesContainer";
import ShowDesktopViewHeader from "./ShowDesktopViewHeader";
import ShowDesktopViewRightPanel from "./ShowDesktopViewRightPanel";
import EvaluationsCriteria from "./criterias/EvaluationsCriteria";
import QuestionContainer from "./questionsAndAnswers/QuestionContainer";
import ResultTableGeneral from "./results/ResultTableGeneral";
import ResultsTableGeneralV2 from "./results/ResultsTableGeneralV2";
/**Import resources section*/
import {
  canBeEditBiddingInStatusDraft,
  isVisibleTabQuestionsAndAnswers,
  isCategoryRawMaterials,
  onMappedDataResultsGeneralsTableV2,
  onMappedDataResultsItemTableV2,
  onGetHeaderReportTableV2,
} from "../../../store/helpers/BiddingHelper";
import { IsCurrentActiveDirectoryUser } from "../../../store/helpers/SessionHelper";
import { BiddingStatus, ScopeType } from "../../../store/helpers/AppConstants";

/**Import actions section */
const ShowDesktopView = (props) => {
  const { classes, bidding } = props;
  /**String props section */
  const { identifier } = props;
  /**Bools props section */
  const {
    isLoading,
    isSaving,
    canBePublicationEnabled,
    canBeSentProposalEnabled,
    canBeCalculateEnabled,
    canBePublicationResultsEnabled,
    isOpenLateralMenu,
  } = props;
  /**Arrays props section */
  const { internalUsers, userRolesByCompany, divisas } = props;
  /**Functions props section */
  const {
    onOpenCloseEditLateralPanel,
    translate,
    onOpenCloseEditSuppliersLateralPanel,
    onDeletedSupplierBiddingFromBackEnd,
    onCancelSupplierBiddingFromBackEnd,
    onUpdateContactEmailFromBackEnd,
    onOpenCloseCreateServicesLateralPanel,
    onCreateEvaluationCriteriaConfigPanel,
    onDeletedEvaluationFromBackEnd,
    onDeleteServiceOrMaterial,
    onEditEvaluationCriteriaConfigPanel,
    onAddResponseSupplier,
    onEditEvaluationCriteriaGroupConfigPanel,
    onAddQuestion,
    onAddAnswer,
    onConfirmPassword,
    onAddWeighingConfigPanel,
    onOpenCloseUploadAttachmentsLateralPanel,
    onOpenCloseExchangeRatesLateralPanel,
    onAddDivisaInBiddingsResultsFromBackEnd,
    onDownloadBiddingResultsReport,
    onAddWinnerConfigPanel,
    onAddWinnerInItemFromBackend,
    onAddCommentsSupplierConfigPanel,
    onUploadAdditionalCostsConfigPanel,
    onSendEmailConfigPanel,
    // addEvaluationResponsibler
  } = props;
  /**Objects props section */
  const { userProfile, userProfileLogged } = props;
  const [tabSelectedIndex, setTabSelectedIndex] = useState(0);
  let isVisibleTabResults =
    IsCurrentActiveDirectoryUser(userProfileLogged) &&
    bidding &&
    (bidding.Status === BiddingStatus.CLOSED ||
      bidding.Status === BiddingStatus.RESOLVED ||
      bidding.Status === BiddingStatus.FINISHED);
  const onChangeTab = (indexTab) => {
    setTabSelectedIndex(indexTab);
  };

  const onDownloaderReportResult = () => {
    let criteriasResponses =
      bidding.CriteriasResponses.length >= 1
        ? bidding.CriteriasResponses[0].criterias.length >= 1
          ? bidding.CriteriasResponses[0].criterias[0]
          : []
        : [];
    let headersTable = onGetHeaderReportTableV2(
      criteriasResponses,
      bidding.Suppliers,
      "",
      true
    );
    let globalsResults = onMappedDataResultsGeneralsTableV2(
      bidding,
      ScopeType.GENERAL
    );
    let itemsResult = onMappedDataResultsItemTableV2(
      bidding,
      ScopeType.ITEM,
      bidding.Status
    );
    if (props.onDownloadBiddingResultsReport) {
      onDownloadBiddingResultsReport(globalsResults, itemsResult, headersTable);
    }
  };

  /**
   * Get the card content
   *
   * @memberof Index
   */
  const getCardContent = () => {
    if (isLoading === true) {
      return <LoadingPage />;
    } else {
      return (
        <Grid container spacing={8}>
          <ShowDesktopViewHeader
            bidding={bidding}
            identifier={identifier}
            isSaving={isSaving}
            canBePublicationEnabled={canBePublicationEnabled}
            userRolesByCompany={userRolesByCompany}
            translate={translate}
            userProfileLogged={userProfileLogged}
            userProfile={userProfile}
            canBeSentProposalEnabled={canBeSentProposalEnabled}
            onOpenCloseExchangeRatesLateralPanel={
              onOpenCloseExchangeRatesLateralPanel
            }
            canBeCalculateEnabled={canBeCalculateEnabled}
            canBePublicationResultsEnabled={canBePublicationResultsEnabled}
            onConfirmPassword={onConfirmPassword}
          />
          <Grid item xs={tabSelectedIndex === 0 ? 8 : 12}>
            <Card className={classes.leftPanel}>
              <div className={classes.tabs}>
                <div
                  className={
                    tabSelectedIndex === 0 ? classes.containerTabs : null
                  }
                >
                  <Tabs
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    aria-label="disabled tabs example"
                    onChange={(event, index) => onChangeTab(index)}
                    value={tabSelectedIndex}
                  >
                    <Tab
                      style={{ minWidth: "50px" }}
                      className={classes.tab}
                      label={translate("biddingsModule.apart")}
                    />
                    {
                      <Tab
                        style={{ minWidth: "50px" }}
                        className={classes.tab}
                        label={translate("biddingsModule.evaluationsCriteria")}
                      />
                    }
                    {
                      <Tab
                        style={{ minWidth: "50px" }}
                        className={classes.tab}
                        label={
                          isCategoryRawMaterials(bidding)
                            ? translate("biddingsModule.materials")
                            : translate("biddingsModule.service")
                        }
                      />
                    }
                    {IsCurrentActiveDirectoryUser(userProfileLogged) && (
                      <Tab
                        style={{ minWidth: "50px" }}
                        className={classes.tab}
                        label={translate("biddingsModule.suppliers")}
                      />
                    )}
                    {isVisibleTabResults && (
                      <Tab
                        style={{ minWidth: "50px" }}
                        className={classes.tab}
                        label={translate("biddingsModule.results")}
                      />
                    )}
                    {isVisibleTabQuestionsAndAnswers(bidding) && (
                      <Tab
                        style={{ minWidth: "50px" }}
                        className={classes.tab}
                        label={translate("biddingsModule.questions.title")}
                      />
                    )}
                  </Tabs>
                </div>
                <div className={classes.buttonsRightContainer}>
                  {tabSelectedIndex === 0 &&
                    canBeEditBiddingInStatusDraft(
                      bidding,
                      userRolesByCompany,
                      userProfile
                    ) && (
                      <Tooltip title={translate("common.edit")}>
                        <IconButton
                          color="primary"
                          aria-label="More"
                          onClick={() =>
                            props.history.push(
                              `/biddings/base/create/${bidding.Id}`
                            )
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  {tabSelectedIndex === 2 &&
                    canBeEditBiddingInStatusDraft(
                      bidding,
                      userRolesByCompany,
                      userProfileLogged ? userProfileLogged.profile : null
                    ) && (
                      <Tooltip title={translate("common.add")}>
                        <IconButton
                          color="primary"
                          aria-label="More"
                          onClick={() =>
                            onOpenCloseCreateServicesLateralPanel()
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                  {tabSelectedIndex === 2 &&
                    canBeEditBiddingInStatusDraft(
                      bidding,
                      userRolesByCompany,
                      userProfileLogged ? userProfileLogged.profile : null
                    ) &&
                    isCategoryRawMaterials(bidding) && (
                      <Tooltip title={translate("biddingsModule.importMaterials")}>
                        <IconButton
                          color="primary"
                          aria-label="More"
                          onClick={() =>
                            onOpenCloseUploadAttachmentsLateralPanel()
                          }
                        >
                          <ImportMaterialIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                  {tabSelectedIndex === 1 &&
                    canBeEditBiddingInStatusDraft(
                      bidding,
                      userRolesByCompany,
                      userProfile
                    ) && (
                      <Tooltip title={translate("common.add")}>
                        <IconButton
                          color="primary"
                          aria-label="More"
                          onClick={() =>
                            onCreateEvaluationCriteriaConfigPanel()
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  {tabSelectedIndex === 3 &&
                    canBeEditBiddingInStatusDraft(
                      bidding,
                      userRolesByCompany,
                      userProfile
                    ) && (
                      <Tooltip title={translate("common.add")}>
                        <IconButton
                          color="primary"
                          aria-label="More"
                          onClick={onOpenCloseEditSuppliersLateralPanel}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                  {tabSelectedIndex === 4 &&
                    bidding &&
                    (bidding.Status === BiddingStatus.RESOLVED ||
                      bidding.Status === BiddingStatus.FINISHED) && (
                      <Tooltip title={translate("biddingsModule.downloadReportResult")}>
                        <IconButton
                          color="primary"
                          aria-label="More"
                          onClick={onDownloaderReportResult}
                          disabled={isSaving}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                  {tabSelectedIndex === 4 && false && (
                    <DivisaComponent
                      divisas={divisas}
                      onDivisaInBiddingFromBackEnd={
                        onAddDivisaInBiddingsResultsFromBackEnd
                      }
                      isSaving={isSaving}
                    />
                  )}
                </div>
              </div>
              <TabPanel value={tabSelectedIndex} index={0}>
                <div className={classes.sectionsContainer}>
                  <BasesContainer translate={translate} bidding={bidding} />
                </div>
              </TabPanel>

              <TabPanel value={tabSelectedIndex} index={1}>
                {
                  <EvaluationsCriteria
                    translate={translate}
                    bidding={bidding}
                    userRolesByCompany={userRolesByCompany}
                    onDeletedEvaluationFromBackEnd={
                      onDeletedEvaluationFromBackEnd
                    }
                    onDeleteServiceOrMaterial={onDeleteServiceOrMaterial}
                    isSaving={isSaving}
                    onEditEvaluationCriteriaConfigPanel={
                      onEditEvaluationCriteriaConfigPanel
                    }
                    userProfileLogged={userProfileLogged}
                    onEditEvaluationCriteriaGroupConfigPanel={
                      onEditEvaluationCriteriaGroupConfigPanel
                    }
                  />
                }
              </TabPanel>
              <TabPanel value={tabSelectedIndex} index={2}>
                {
                  <ServicesOrMaterialContainer
                    translate={translate}
                    bidding={bidding}
                    userRolesByCompany={userRolesByCompany}
                    onCreateEvaluationCriteriaConfigPanel={
                      onCreateEvaluationCriteriaConfigPanel
                    }
                    onDeletedEvaluationFromBackEnd={
                      onDeletedEvaluationFromBackEnd
                    }
                    onDeleteServiceOrMaterial={onDeleteServiceOrMaterial}
                    isSaving={isSaving}
                    userProfileLogged={userProfileLogged}
                    onEditEvaluationCriteriaConfigPanel={
                      onEditEvaluationCriteriaConfigPanel
                    }
                    onAddResponseSupplier={onAddResponseSupplier}
                    userProfile={userProfile}
                  />
                }
              </TabPanel>
              <TabPanel value={tabSelectedIndex} index={3}>
                <Grid item xs={12}>
                  <Grid container>
                    <AddSuppliersComponent
                      translate={translate}
                      bidding={bidding}
                      onDeletedSupplierBiddingFromBackEnd={
                        onDeletedSupplierBiddingFromBackEnd
                      }
                      onCancelSupplierBiddingFromBackEnd={
                        onCancelSupplierBiddingFromBackEnd
                      }
                      onUpdateContactEmailFromBackEnd={
                        onUpdateContactEmailFromBackEnd
                      }
                      userRolesByCompany={userRolesByCompany}
                      isSaving={isSaving}
                      userProfile={userProfile}
                      onSendEmailConfigPanel={onSendEmailConfigPanel}
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              {bidding && (
                <TabPanel value={tabSelectedIndex} index={4}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      maxHeight: `calc(100vh - ${320}px)`,
                      overflowY: "auto",
                    }}
                  >
                    <Grid container>
                      {bidding &&
                        bidding.Status !== BiddingStatus.RESOLVED &&
                        bidding.CriteriaResponses && (
                          <Grid item xs={12}>
                            <ResultTableGeneral
                              bidding={bidding}
                              isOpenLateralMenu={isOpenLateralMenu}
                              isSaving={isSaving}
                              onAddWeighingConfigPanel={
                                onAddWeighingConfigPanel
                              }
                              onUploadAdditionalCostsConfigPanel={
                                onUploadAdditionalCostsConfigPanel
                              }
                              translate={translate}
                            />
                          </Grid>
                        )}
                      <Grid item xs={12}>
                        {bidding &&
                          (bidding.Status === BiddingStatus.RESOLVED ||
                            bidding.Status === BiddingStatus.FINISHED) &&
                          bidding.CriteriasResponses && (
                            <ResultsTableGeneralV2
                              bidding={bidding}
                              onConfirmPassword={onConfirmPassword}
                              onAddWinnerConfigPanel={onAddWinnerConfigPanel}
                              isSaving={isSaving}
                              onAddWinnerInItemFromBackend={
                                onAddWinnerInItemFromBackend
                              }
                              userRolesByCompany={userRolesByCompany}
                              userProfile={userProfile}
                              onAddCommentsSupplierConfigPanel={
                                onAddCommentsSupplierConfigPanel
                              }
                              isCurrentActiveDirectoryUser={IsCurrentActiveDirectoryUser(
                                userProfileLogged
                              )}
                              isOpenLateralMenu={isOpenLateralMenu}
                              isExternalUser={false}
                              translate={translate}
                            />
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
              )}
              <TabPanel
                value={tabSelectedIndex}
                index={isVisibleTabResults ? 5 : 4}
              >
                <QuestionContainer
                  onAddQuestion={onAddQuestion}
                  onAddAnswer={onAddAnswer}
                  isSaving={isSaving}
                  bidding={bidding}
                  usersByRole={internalUsers}
                  isExternalUser={false}
                  isDisabledAddQuestion={false}
                  preferred_username={
                    userProfile ? userProfile.preferred_username : ""
                  }
                  isCurrentActiveDirectoryUser={IsCurrentActiveDirectoryUser(
                    userProfileLogged
                  )}
                />
              </TabPanel>
            </Card>
          </Grid>
          {tabSelectedIndex === 0 && (
            <ShowDesktopViewRightPanel
              bidding={bidding}
              internalUsers={internalUsers}
              userRolesByCompany={userRolesByCompany}
              onOpenCloseEditLateralPanel={onOpenCloseEditLateralPanel}
              translate={translate}
              userProfile={userProfile}
              tabSelectedIndex={tabSelectedIndex}
            />
          )}
        </Grid>
      );
    }
  };
  return getCardContent();
};

const styles = (theme) => ({
  leftPanel: {
    maxHeight: `calc(100vh - ${245}px)`,
    minHeight: `calc(100vh - ${245}px)`,
    overflowY: "hidden",
    padding: "10px",
  },
  sectionsContainer: {
    maxHeight: `calc(100vh - ${305}px)`,
    minHeight: `calc(100vh - ${305}px)`,
    overflowY: "auto",
    paddingBottom: "15px",
  },
  grow: {
    flexGrow: 1,
  },
  flex: {
    display: "flex",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  buttonsRightContainer: {
    display: "flex",
    alignItems: "center",
  },
  tabs: {
    display: "flex",
    justifyContent: "space-between",
  },
  containerTabs: {
    maxWidth: `calc(100vw - ${715}px)`,
  },
});

ShowDesktopView.propTypes = {
  /**
   * Determinate if is loading information from back end
   * and see view skeleton
   */
  isLoading: PropTypes.bool.isRequired,
};

ShowDesktopView.defaultProps = {
  isLoading: false,
  canBeSentProposalEnabled: false,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(ShowDesktopView)
);
