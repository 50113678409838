/** Import ReactJS */
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import { 
	Card,
	Grid,
	Divider,
	Typography,
	IconButton,
	Tooltip,
	CircularProgress,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	SnackbarContent,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	CardHeader,
	Avatar,
	CardContent
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import LocalPlayIcon from '@material-ui/icons/LocalPlay'
import AssignmentIcon from '@material-ui/icons/Assignment'
import MoneyIcon from '@material-ui/icons/Money'
import SendSapIcon from '@material-ui/icons/Autorenew'
import ImportContactsIcons from '@material-ui/icons/ImportContacts'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import PaymentIcon from '@material-ui/icons/Payment'
import BusinessIcon from '@material-ui/icons/Business'
import WarningIcon from '@material-ui/icons/Warning';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import amber from '@material-ui/core/colors/amber';
import ErrorIcon from '@material-ui/icons/Error';
import VpnLockIcon from '@material-ui/icons/VpnLock'
import AttachEmailIcon from '@material-ui/icons/Email'

/**Import resources section */
import { BACKGROUND_COLOR_GRAY, COLOR_STATUS_ALERT_INFO, COLOR_STATUS_BLUE, COLOR_STATUS_DANGER } from '../../../../store/helpers/StatusColorConstants'
import { IsUserInRole, ROLE_FACTORING_AFFILIATION_ADMINISTRATOR } from '../../../../store/helpers/RolesHelper'

const ConvenantInformation = (props) => {
	const { classes, factoring } = props
	//Array props section
	const { userRolesByCompany } = props
	//Function props section
	const { SynchronizeSapFactoring, onSendNotificationToSupplier } = props
	//Bool props section
	const { isSaving, isSavingNotification } = props

	let isUserAdministrator = IsUserInRole(ROLE_FACTORING_AFFILIATION_ADMINISTRATOR, userRolesByCompany)

	const GetErrorList =(list) =>{
		if(list !== "" && list !== null){
			let errors = list.split('.')
			errors = errors.filter(x=> x.trim() !== "")
			return(
				<div style={{ flexBasis: '100%' }}>
					<Divider />
					<List>
						{errors.map(item =>{
								return(
									<>
										<ListItem>
										<ListItemIcon>
											<ErrorIcon className={classes.errorIcon}/>
										</ListItemIcon>
											<ListItemText
												inset
												primary={
													<Typography className={classes.textTitle}>
														{item.trim()}
													</Typography>
												}
											/>
										</ListItem>
										<Divider />
									</>
								)
						})}
					</List>
				</div>
			)
		}
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<Card className={classes.card}>
					<CardHeader
						className={classes.cardHeader}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={'Convenios generados en el proceso de afilación de factoraje'}
						action = {
							isUserAdministrator && (
								<Tooltip title='Notificar al proveedor'>
									<IconButton 
										onClick={() => onSendNotificationToSupplier(factoring.Id)}
										disabled = {isSavingNotification}
									>
										<AttachEmailIcon  />
									</IconButton>
								</Tooltip>
							)
						}
					/>
					<Divider />

					<CardContent>
						<Grid container direction='row' justify='center' alignItems='flex-start' spacing={8}>
							<Grid item xs={12}>
								{factoring &&
									factoring.Covenants.map((covenant) => {
										const hasError = covenant.SapError !== "" && covenant.SapError !== null;
										return (
											<Card className={classes.cardContainer}>
												<CardHeader
													avatar={
														<LocalPlayIcon color='primary' />
													}
													title={'Información del pago y convenio asignado'}
													action={
														isUserAdministrator && (
															<>	
																<Tooltip title='Sincronizar información con sap'>
																	<IconButton 
																		onClick={() => SynchronizeSapFactoring(factoring.Id, covenant.Id)}
																		disabled ={isSaving}
																	>
																		{isSaving ? <CircularProgress size={20} />: <SendSapIcon />	}	
																	</IconButton>
																</Tooltip>
															</>
														)
													}
												/>

												{(isUserAdministrator && covenant.ResponseSap !== "" && covenant.ResponseSap !== null) && (
													<>
														<Divider />
														<ExpansionPanel>
															<ExpansionPanelSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
																<ListItemText
																	inset
																	secondary={
																		<>
																			{moment(covenant.DateSynchronizeSap).format('DD/MM/YYYY  HH:mm:ss')} {<Translate id="quotes.requotedBy" />} {covenant.UserSynchronizeSap}
																		</>
																	}
																/>
																<SnackbarContent  className={classes[hasError? 'warning':'info']}
																	message={
																	<span>
																		{hasError ? 
																			<WarningIcon  className={classes.iconAlert} />:
																			<InfoIcon className={classes.iconAlert}/>
																		}
																		{covenant.ResponseSap}                     
																	</span>} 
																/>
															</ExpansionPanelSummary>
															<ExpansionPanelDetails>
																	{hasError? GetErrorList(covenant.SapError) : <></>}
															</ExpansionPanelDetails>
														</ExpansionPanel>
													</>
												)} 
												<Divider />
												<Grid container>
													<Grid item xs={6} className={classes.padding}>
														<Typography align='center' variant='h6'>
															<strong>Convenio:</strong>
														</Typography>
														<Divider />
														<List component='nav' className={classes.root}>
															<ListItem>
																<ListItemIcon>
																	<BusinessIcon color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Compañía
																		</Typography>
																	}
																	secondary={covenant.Covenant.CompanyName}
																/>
															</ListItem>
															<Divider />
															<ListItem>
																<ListItemIcon>
																	<VpnLockIcon color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Convenio
																		</Typography>
																	}
																	secondary={covenant.Covenant.CovenantCode}
																/>
															</ListItem>
															<Divider />
															<ListItem>
																<ListItemIcon>
																	<AttachMoneyIcon color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Moneda
																		</Typography>
																	}
																	secondary={covenant.Covenant.Currency}
																/>
															</ListItem>
															<Divider />
															<ListItem>
																<ListItemIcon>
																	<MoneyIcon color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Condiciones de pago:
																		</Typography>
																	}
																	secondary={covenant.Covenant.PaymentConditions}
																/>
															</ListItem>
															<Divider />
															<ListItem>
																<ListItemIcon>
																	<VpnLockIcon color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Tipo
																		</Typography>
																	}
																	secondary={covenant.Covenant.Type}
																/>
															</ListItem>
															<Divider />
															<ListItem>
																<ListItemIcon>
																	<VpnLockIcon color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Organización de compra
																		</Typography>
																	}
																	secondary={covenant.Covenant.PurchasingOrganization}
																/>
															</ListItem>
															<Divider />
														</List>
													</Grid>
													<Grid item xs={6} className={classes.padding}>
														<Typography align='center' variant='h6'>
															<strong>Método de pago:</strong>
														</Typography>
														<Divider />

														<List component='nav' className={classes.root}>
															<ListItem>
																<ListItemIcon>
																	<BusinessIcon color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Compania
																		</Typography>
																	}
																	secondary={covenant.PaymentMethod.CompanyName}
																/>
															</ListItem>
															<Divider />

															<ListItem>
																<ListItemIcon>
																	<PaymentIcon color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Metodo de pago código:
																		</Typography>
																	}
																	secondary={covenant.PaymentMethod.PaymentMethodCode}
																/>
															</ListItem>

															<Divider />
															<ListItem>
																<ListItemIcon>
																	<AttachMoneyIcon color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Moneda
																		</Typography>
																	}
																	secondary={covenant.PaymentMethod.Currency}
																/>
															</ListItem>
															<Divider />
															<ListItem>
																<ListItemIcon>
																	<MoneyIcon color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Condiciones de pago
																		</Typography>
																	}
																	secondary={covenant.PaymentMethod.PaymentConditions}
																/>
															</ListItem>
															<Divider />
															<ListItem>
																<ListItemIcon>
																	<ImportContactsIcons color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Banco
																		</Typography>
																	}
																	secondary={covenant.PaymentMethod.OwnBank}
																/>
															</ListItem>
															<Divider />
															<ListItem>
																<ListItemIcon>
																	<VpnLockIcon color='primary' />
																</ListItemIcon>
																<ListItemText
																	inset
																	primary={
																		<Typography className={classes.textTitle}>
																			Tipo
																		</Typography>
																	}
																	secondary={covenant.PaymentMethod.Type}
																/>
															</ListItem>
															<Divider />
														</List>
													</Grid>
												</Grid>
											</Card>
										)
									})}
								{factoring && factoring.Covenants.length === 0 && 'No existen convenios generados.'}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)
}

const styles = (theme) => ({
	card: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	cardHeader: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
	},
	cardContainer: {
		marginTop: '10px',
		padding: '10px',
		borderWidth: '5px',
		borderLeftColor: 'black',
	},
	padding: {
		padding: '10px',
	},
	errorIcon:{
		color: COLOR_STATUS_DANGER,
	},
	accordionSummary: {
        paddingRight: "0px !important",
        display: "flex",
        backgroundColor: "#f8f9fa"
    },
	info: {
        backgroundColor: COLOR_STATUS_BLUE,
		width: '100%',
    },
    warning: {
		backgroundColor: amber[700],
		width: '100%',
    },
	iconAlert: {
		opacity: 0.9,
		marginRight: '8px',
		color: BACKGROUND_COLOR_GRAY,
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(ConvenantInformation))
