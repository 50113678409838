import propTypes from 'prop-types'
import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
/**Import MaterialUi section */
import { Button, List, ListItem, ListItemText, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import ReactSelect from 'react-select'
import { containerOptions } from '../../../store/helpers/SelectOptions'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
import { addContainer } from '../../../store/loadAlerts/LoadAlertAction'
/**Import components section */

const ViewComponent = (props) => {
	const { onCloseModal } = props
	const params = useParams()
	const dispatch = useDispatch()
	const [control, setcontrol] = useState({
		Token: params.token,
		Code: '',
		Quantity: '',
		TypeContainer: ''
	})

	const onUpdateRequestControl = () => {
		dispatch(addContainer(control)).then(() => {
			onCloseModal()
		})
	}

	const updateProperty = (property, value) => {
		let controlUpdate = { ...control }
		controlUpdate[property] = value
		setcontrol(controlUpdate)
	}

	const handleOptionChange = (value) => {
		updateProperty('TypeContainer', value)
	}
	const canSave = () => {
        return IsNullOrEmpty(control.Quantity) ||
            IsNullOrEmpty(control.TypeContainer)
    }
	return (
		<div>
			<List dense>
				<ListItem>
					<ListItemText
						primary={<Translate id='loadAlerts.unitType' />}
						secondary={
							<ReactSelect
							options={containerOptions}
							onChange={(selectedOption) => {
								handleOptionChange(selectedOption.value)
							}}
						/>
						}
					/>
				</ListItem>
				{control['TypeContainer'] === 'ContainerConsolidated' ||
				control['TypeContainer'] === 'ContainerAereo' ? (
					<ListItem>
						<ListItemText
							primary={<Translate id='loadAlerts.type' />}
							secondary={
								<TextField
									fullWidth
									id='outlined-size-small'
									label={''}
									onChange={(event) => {
										updateProperty('Code', event.target.value)
									}}
									variant='outlined'
								/>
							}
						/>
					</ListItem>
				) : null}
				<ListItem>
					<ListItemText
						primary={<Translate id='loadAlerts.quantity' />}
						secondary={
							<TextField
								component='span'
								fullWidth
								id='outlined-size-small'
								label={''}
								type='int'
								onChange={(event) => {
									updateProperty('Quantity', event.target.value)
								}}
								variant='outlined'
							/>
						}
					/>
				</ListItem>
				<ListItem>
					<Button
						onClick={() => onUpdateRequestControl()}
						data-cy='btn-save'
						type='button'
						color='primary'
						variant='outlined'
						fullWidth
						disabled={canSave()}
					>
						Guardar
					</Button>
				</ListItem>
				<br />
			</List>
		</div>
	)
}

const styles = (theme) => ({
	containerInformation: {
		maxHeight: `calc(100vh - ${165}px)`,
		minHeight: `calc(100vh - ${165}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	widthBtnSaved: {
		minWidth: '200px',
	},
	divider: {
		marginRight: '10px',
	},
	bgRed: {
		backgroundColor: 'red',
	},
})

ViewComponent.propTypes = {
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseModal: propTypes.func,
	/**
	 * Selected item data
	 */
	item: propTypes.object,
}

ViewComponent.defaultProps = {
	onCloseModal: () => console.warn('Callback [onCloseModal] no defined'),
	isSaving: false,
	item: {},
}

export default withRouter(withStyles(styles, { withTheme: true })(ViewComponent))
