import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core'
import React from 'react'
import logo from '../../../resources/images/logotype-sip.png'
import SettingsIcon from '@material-ui/icons/Settings'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

const LoadAlertTrackingToolbar = (props) => {
	const { classes } = props
	return (
		<AppBar position='fixed' className={classes.appBarBgColor}>
			<Toolbar>
				<img src={logo} alt='Logo' className={classes.img} />
				<Typography variant='h6' className={classes.titleBar}>
					<Translate id='common.alertTracking' />
				</Typography>

				<IconButton color='inherit' className={classes.SettingsIcon}>
					<SettingsIcon color='white' />
				</IconButton>
			</Toolbar>
		</AppBar>
	)
}
const styles = (theme) => ({
	img: {
		marginRight: '16px',
		height: '40px',
		margin: '0 2rem 0 1rem',
	},
	appBarBgColor: {
		// backgroundColor: '#f9f9f9',
		backgroundColor: '#4377C4',

	},
	titleBar: {
		flexGrow: 1,
		color: 'white',
		textTransform: 'uppercase',
	},
	SettingsIcon: {
		color: 'white',
	},
})

export default withStyles(styles)(LoadAlertTrackingToolbar)
