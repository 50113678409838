
import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import Select from 'react-select'

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

/** Import component section */
import Dialog from './Dialog';
import DialogActions from './DialogActions';
import { SHOW_EDIT_VIEW } from '../../store/helpers/ResourcesHelper';
import {GetCancelFolio} from '../../store/helpers/SelectOptions';

/**
 * Dialog to CancelWorkflowDialog
 */
class CancelWorkflowDialog extends Component {

    /**
   *Creates an instance of AddCommonItem.
   * @param {*} props
   * @memberof AddEmergentAction
   */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            cancelComment: "", 
            razonForCancel:"",
        }
    }

    /**
     * Function to add cancelComment
     * @param {*} comment 
     */
    updatetemProperty(cancelComment) {
        this.setState({
            cancelComment: cancelComment
        })
    }

    /**
     * On change document type
     *
     * @param {*} option
     * @memberof DocumentTypeSelect
     */
    onChangeCancelTypeOptions(option) {
        this.setState({
            razonForCancel: option.value
        })
    }

     /**
     * onSave
     */
    onSaveItem() {
        if (this.state.cancelComment && this.state.razonForCancel) {
            if(this.props.onSaveItem){
                this.props.onSaveItem(this.state.cancelComment,this.state.razonForCancel);
            }
            this.setState({cancelComment: "", razonForCancel:""})
        }
    }

    /**
     * canBeSaved
     */
    canBeSaved(){
        if(this.state.cancelComment === "")
            return false;
        if(this.state.razonForCancel === "")
            return false;
        else
            return true;
    }

    /**
     * Dialog information
     */
    render() {
        const { classes } = this.props;
        let open = this.props.open ? this.props.open : false;
        let cancelTypeOptions = GetCancelFolio();
        return (
            <Dialog
            open = {open}
            onClose={this.props.onClose}
                header={
                    <Toolbar >
                        <Typography variant="h6">
                            {<Translate id="common.cancelWorkflows" />}
                        </Typography>
                    </Toolbar>
                }
                actions={
                    <DialogActions
                        view={SHOW_EDIT_VIEW}
                        classes={classes}
                        onCancel={this.props.onClose}
                        onClose={this.props.onClose}
                        onSaveItem={this.onSaveItem}
                        isSaving={this.props.isSaving}
                        canBeSaved={this.canBeSaved()}
                    />
                }>

                <form>
                    <div className={classes.textField}>
                        <Typography variant="body2" gutterBottom> 
                        {<Translate id="common.ReasonForCancellation"/>}</Typography>
                        <Select 
                            defaultValue={cancelTypeOptions[0]}
                            options={cancelTypeOptions}
                            fullWidth
                            onChange={this.onChangeCancelTypeOptions}
                            styles={{
                                menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
                                menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
                            }}
                            menuPortalTarget={document.parentNode}
                            menuPosition="fixed"//THIS IS REQUIRED IN MODAL!!
                            menuPlacement={'auto'}
                        />
                       <br/>
                    </div>
                    <TextField fullWidth
                        label={<Translate id="common.ReasonForCancellation"/>}
                        className={classes.textField}
                        multiline
                        rows="3"
                        defaultValue={this.state.cancelComment}
                        onChange={(event) => { this.updatetemProperty(event.target.value) }}
                        margin="normal"
                        variant="outlined"
                    />
                </form>
            </Dialog>
        )
    }
}

CancelWorkflowDialog.defaultProps = {
    open: false,
}

const dialogStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: "#ea0b34",
    },
    button: {
        margin: theme.spacing.unit,
    },
    errorMessage: {
        color: theme.palette.common.white
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 500,
        marginTop: 10,
        marginBottom: 10
    }
});

export default withRouter(withStyles(dialogStyles, { withTheme: true })(CancelWorkflowDialog));