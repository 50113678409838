import { encodeDelimitedArray, decodeDelimitedArray } from 'use-query-params'

/** Uses a pipe to delimit entries. e.g. ['a', 'b'] => qp?=a|b */
export const DelimitedPipeArrayParam = {
	encode: (array) => encodeDelimitedArray(array, '|'),
	decode: (arrayStr) => decodeDelimitedArray(arrayStr, '|'),
}

export const ecodeDelimitedPipeArray = (arrayStr) => encodeDelimitedArray(arrayStr, '|')

export const decodeDelimitedPipeArray = (arrayStr) => decodeDelimitedArray(arrayStr, '|')

// /** Uses a pipe to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
// const DelimitedPipeArrayParam = {
// 	encode: (array) => encodeDelimitedArray(array, ','),
// 	decode: (arrayStr) => decodeDelimitedArray(arrayStr, ','),
// }
