import React from 'react';
import { Translate } from "react-localize-redux";
import Typography from "@material-ui/core/Typography";

export const BTN_DELETE = "BTN_DELETE";

export const mappedAdicionalDocumentColumn = (tableConfiguration, supplierClassifications) => {
	if (supplierClassifications && supplierClassifications.length !== 0) {
		supplierClassifications.forEach((supplierClassification) => {
			tableConfiguration.push({
				title: supplierClassification.ClassificationCode,
				token: supplierClassification.token,
				width: "120px"
			});
		});
	}
	return tableConfiguration;
};

export const onGetHeaderDocumentsTable = (supplierClassifications) => {
	let tableConfiguration = [
		{
			title: '',
			width: "30px"
		},
		{
			title: 'Nomenclatura',
			width: "150px"
		},
		{
			title: 'Nombre del documento',
			width: "200px"

		},
		{
			title: 'Notas',
			width: "200px"

		},
		{
			title: 'Formato',
			width: "60px"

		},
		{
			title: 'Archivos',
			width: "60px"
		},

	];
	tableConfiguration = mappedAdicionalDocumentColumn(tableConfiguration, supplierClassifications);
	tableConfiguration.push({
		title: BTN_DELETE,
		width: "120px"
	})
	return tableConfiguration;
};

export const onMappedDocumentsItems = (documents, supplierClassifications) => {
	mappedAdicionalDocumentColumn([], supplierClassifications);
	return documents;
};

export const onGetHeaderSuppliersDocumentsTable = (supplierClassifications) => {
	if (supplierClassifications.length === 0) return [];
	let filesInSupplier = supplierClassifications[0].file_update_suppliers
	let tableConfiguration = [
		{
			title: ''
		},
		{
			title: 'Proveedor'
		},
		{
			title: 'Tipo'
		},
		{
			title: 'Eliminar'
		}

	];
	filesInSupplier.forEach(f =>
		tableConfiguration.push({
			title: f.nomenclature_name,
			nomenclatureName: f.nomenclature_name,
		})
	)
	return tableConfiguration;
};

export const GetSupplierTypeDescription = (supplierType, supplierClassification) => {
	switch (Number(supplierType)) {
		case 1:
			return <Translate id="expedientSupplier.supplierTypeDescription.rawMaterial" />
		case 2:
			return <Translate id="expedientSupplier.supplierTypeDescription.indirect" />
		case 3:
			return <Translate id="expedientSupplier.supplierTypeDescription.filial" />
		case 4:
			return <Translate id="expedientSupplier.supplierTypeDescription.governmantAgent" />
		case 5:
			return <Translate id="expedientSupplier.supplierTypeDescription.foreign" />
		case 6:
			return <Translate id="expedientSupplier.supplierTypeDescription.supplierPM" />
		case 7:
			return <Translate id="expedientSupplier.supplierTypeDescription.supplierPF" />
		default:
			return supplierClassification;
	}
};

export const GetClassifcationType = (classificationType) => {
	switch (Number(classificationType)) {
		case 1:
			return <Translate id="expedientSupplier.treatmentDescription.isService" />
		case 2:
			return <Translate id="expedientSupplier.treatmentDescription.isSpareParts" />
		case 3:
			return <Translate id="expedientSupplier.treatmentDescription.isAdministration" />
		case 4:
			return <Translate id="expedientSupplier.treatmentDescription.isSalesMachineryEquipment" />
		case 5:
			return <Translate id="expedientSupplier.treatmentDescription.isWorkshopsCustomer" />
		case 6:
			return <Translate id="expedientSupplier.treatmentDescription.isManufacturer" />
		case 7:
			return <Translate id="expedientSupplier.treatmentDescription.isDistributorMarketer" />
		case 8:
			return <Translate id="expedientSupplier.treatmentDescription.isMaquilador" />
		case 9:
			return <Translate id="expedientSupplier.treatmentDescription.isFilial" />
		case 10:
			return <Translate id="expedientSupplier.treatmentDescription.isGovernmantAgent" />
		case 11:
			return <Translate id="expedientSupplier.treatmentDescription.isForeign" />
		default:
			return '';
	}
}

export const GetColorStatus = (status, colorStatus) => {
	switch (Number(status)) {
		case 2:
			return (<Typography style={{ color: colorStatus, width: "62px" }} variant="caption"><Translate id="expedientSupplier.fileExpedientStatus.documentAdded" /></Typography>)
		case 3:
			return (<Typography style={{ color: colorStatus, width: "62px" }} variant="caption"><Translate id="expedientSupplier.fileExpedientStatus.documentAccepted" /></Typography>)
		case 4:
			return (<Typography style={{ color: colorStatus, width: "62px" }} variant="caption"><Translate id="expedientSupplier.fileExpedientStatus.documentRejected" /></Typography>)
		case 5:
			return (<Typography style={{ color: colorStatus, width: "62px" }} variant="caption"><Translate id="expedientSupplier.fileExpedientStatus.documentInWaiting" /></Typography>)
		case 6:
			return (<Typography style={{ color: colorStatus, width: "62px" }} variant="caption"><Translate id="expedientSupplier.fileExpedientStatus.documentAlreadyValid" /></Typography>)
		default:
			return (<Typography style={{ color: colorStatus, width: "62px" }} variant="caption"><Translate id="expedientSupplier.fileExpedientStatus.documentRequest" /></Typography>)
	}
}

export const GettipeFile = (fileType) => {
	if (fileType === 'EXCEL')
		return ['Excel']
	else if (fileType === 'WORD')
		return ['Word']
	else if (fileType === 'IMAGEN')
		return ['images']
	else if (fileType === 'PDF_O_IMAGEN')
		return ['images', 'pdf']
	else if (fileType === 'ZIP')
		return ['OtherFiles']
	else
		return ['pdf']
}

export const GettipeFileProspects = (fileType) => {
	if (fileType === 'EXCEL')
		return ['Excel']
	else if (fileType === 'WORD')
		return ['Word']
	else if (fileType === 'IMAGEN')
		return ['images']
	else if (fileType === 'PDF_O_IMAGEN')
		return ['images', 'pdf']
	else if (fileType === 'ZIP')
		return ['OtherFiles']
	else
		return ['pdf', 'images']
}

