import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

import { withStyles } from '@material-ui/core/styles'
import Toaster from '../common/Toaster'

import {
	Divider,
	Grid,
	CardContent,
	Card,
	Paper,
	CardHeader,
	List,
	Typography,
	ListItemIcon,
	ListItemText,
	ListItem,
} from '@material-ui/core'
import CalendarActiveIcon from '@material-ui/icons/EventAvailableOutlined'
import Avatar from '@material-ui/core/Avatar'
import SuppliersIcon from '@material-ui/icons/Group'
import DataTable from '../common/DataTable'
import { getDeliveryQualificationByToken } from '../../store/deliveryQualifications/deliveryQualificationsActions'
import { CheckCircle, Person } from '@material-ui/icons'
import { FormatListNumbered } from '@material-ui/icons'
import { getColorStatusFolios } from '../../store/helpers/DeliveryQualificationsHelper'

class Show extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
		}
	}

	renderItemList(idTranslation, value, icon) {
		return (
			<ListItem divider key={idTranslation}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText
					secondary={
						<Typography
							component='span'
							variant='body2'
							className={this.props.classes.inline}
							color='textPrimary'
						>
							<Translate id={idTranslation} />: {value ? value : ''}
						</Typography>
					}
				/>
			</ListItem>
		)
	}
	componentDidMount() {
		let deliveryToken = this.props.match.params.token
		if (!this.props.isLoadingUser) {
			this.props.getDeliveryQualificationByToken(deliveryToken)
		}
	}
	renderDeliveryqualification() {
		const { deliveryQualification } = this.props
		let format = deliveryQualification
		let supplier = deliveryQualification
			? `${
					deliveryQualification[0].breach_details[0] != null
						? deliveryQualification[0].breach_details[0].supplier_code
						: ''
			  } | ${
					deliveryQualification[0].breach_details[0] != null
						? deliveryQualification[0].breach_details[0].supplier_name
						: ''
			  }`
			: ''
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar
							style={{
								backgroundColor: getColorStatusFolios(format && format[0].status),
							}}
						>
							<FormatListNumbered />
						</Avatar>
					}
					title={
						<Typography variant='button'>
							{<Translate id='deliveryQualifications.titleDeliveryQualifications' />}{' '}
						</Typography>
					}
					subheader={
						deliveryQualification && (
							<Grid container spacing={0}>
								<Grid item xs={3}>
									<Translate id='deliveryQualifications.folio' />
									:&nbsp;
									{deliveryQualification[0].folio}
									<br />
									<Translate id='deliveryQualifications.status' />
									:&nbsp;
									{format[0].status_translated}
								</Grid>
							</Grid>
						)
					}
				/>
				<CardContent style={{ height: '83%' }}>
					{deliveryQualification && (
						<Grid container spacing={24}>
							<Grid item xs={12}>
								<Paper>
									<List>
										{this.renderItemList(
											'deliveryQualifications.RequestBy',
											deliveryQualification[0].created_by,
											<Person />
										)}
										{this.renderItemList(
											'common.area',
											deliveryQualification[0].area_name,
											<CheckCircle />
										)}
										{this.renderItemList(
											'common.creationDate',
											deliveryQualification[0].creation_date,
											<CalendarActiveIcon />
										)}
										{this.renderItemList('common.supplier', supplier, <SuppliersIcon />)}
									</List>
								</Paper>
							</Grid>
							<Divider />
						</Grid>
					)}
					<br />
					<DataTable
						data={deliveryQualification ? deliveryQualification[0].breach_details : []}
						configuration={RatingsTableConfiguration}
					/>
				</CardContent>
			</Card>
		)
	}

	render() {
		return (
			<Grid container spacing={24}>
				<Grid item xs={12}>
					{this.renderDeliveryqualification()}
				</Grid>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>
			</Grid>
		)
	}
}

function mapStateToProps(state) {
	let selectedCompany = state.profile.get('selectedCompany')
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	return {
		user: user,
		isLoadingUser: state.oidc.isLoadingUser,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		deliveryQualification: state.deliveryQualifications.get('deliveryQualification')
			? state.deliveryQualifications.get('deliveryQualification').toJS()
			: null,
		translate: getTranslate(state.localize),
	}
}

const RatingsTableConfiguration = {
	columns: [
		{
			header: <Translate id='deliveryQualifications.deliveryDate' />,
			dataSource: 'delivery_date',
		},
		{
			header: 'No. orden de compra',
			dataSource: 'purchase_order',
		},
		{
			header: <Translate id='deliveryQualifications.sapNumber' />,
			dataSource: 'sap_number',
		},
		{
			header: <Translate id='deliveryQualifications.material' />,
			dataSource: 'material',
		},
		{
			header: <Translate id='deliveryQualifications.amount' />,
			dataSource: 'quantity',
		},
		{
			header: <Translate id='deliveryQualifications.um' />,
			dataSource: 'measure_unit',
		},
		{
			header: <Translate id='deliveryQualifications.comments' />,
			dataSource: 'comments',
		},
	],
}

const styles = (theme) => ({})

const mapDispatchToProps = (dispatch) => {
	return {
		getDeliveryQualificationByToken: (token) => {
			return dispatch(getDeliveryQualificationByToken(token))
		},
	}
}

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
