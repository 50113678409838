
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import PropTypes from 'prop-types'
import { Translate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import uuidv4 from 'uuid/v4'

/** section import components */
import Dropzone from '../../common/Dropzone';
import { IsCurrentNotActiveDirectoryUser } from '../../../store/helpers/SessionHelper';
import HtmlEditor from '../../common/HtmlEditor';

import moment from 'moment';

/**
 * Component ot render Documents List shipping Order
 */
export default class DocumentsList extends Component {

    /**
     * Create an instance shipping Order
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
        }
    }

    /**
    * On change files of dropzone 
    *
    * @param {*} event
    * @memberof DetailsCard
    */
    onChangeFileShipping(files, folder, listItems) {
        if (this.props.shippingOrder && this.props.shippingOrder.response) {
            return this.props.uploadFilesShippingOrder(this.props.shippingOrder.response.token, files, folder, listItems)
                .then(() => {
                    return Promise.resolve('Ok');
                }).catch(() => {
                    return Promise.reject();
                });
        }
    }

    /**
     * On remove files of dropzone 
     *
     * @param {*} event
     * @memberof DetailsCard
     */
    onDeleteFileShipping(fileName, folder, listItems) {
        if (this.props.shippingOrder && this.props.shippingOrder.response) {
            return this.props.removeFilesShippingOrder(this.props.shippingOrder.response.token, fileName, folder, listItems)
                .then(() => {
                    return Promise.resolve('Ok');
                }).catch(() => {
                    return Promise.reject();
                });
        }
    }

    /**
     * onChangeFileInvoices
     * @param {*} files 
     */
    onChangeFileInvoices(files) {
        return this.onChangeFileShipping(files, "Invoices", "invoices")
    }

    /**
     * onDeleteFileInvoices
     * @param {*} fileName 
     */
    onDeleteFileInvoices(fileName) {
        return this.onDeleteFileShipping(fileName, "Invoices", "invoices")
    }

    /**
     * onChangeFilePacking
     * @param {*} files 
     */
    onChangeFilePacking(files) {
        return this.onChangeFileShipping(files, "Packing", "packing")
    }

    /**
     * onDeleteFilePacking
     * @param {*} fileName 
     */
    onDeleteFilePacking(fileName) {
        return this.onDeleteFileShipping(fileName, "Packing", "packing")
    }

    /**
     * onChangeFileCertificateOfOrigin
     * @param {*} files 
     */
    onChangeFileCertificateOfOrigin(files) {
        return this.onChangeFileShipping(files, "CertificateOfOrigin", "certificate_origin")
    }

    /**
     * onDeleteFileCertificateOfOrigin
     * @param {*} fileName 
     */
    onDeleteFileCertificateOfOrigin(fileName) {
        return this.onDeleteFileShipping(fileName, "CertificateOfOrigin", "certificate_origin")
    }

    /**
     * onChangeFileCertificateOfQuality
     * @param {*} files 
     */
    onChangeFileCertificateOfQuality(files) {
        return this.onChangeFileShipping(files, "CertificateOfQuality", "certificate_quality")
    }

    /**
     * onDeleteFileCertificateOfQuality
     * @param {*} fileName 
     */
    onDeleteFileCertificateOfQuality(fileName) {
        return this.onDeleteFileShipping(fileName, "CertificateOfQuality", "certificate_quality")
    }

    /**
     * onChangeFileNotificationBoarding
     * @param {*} files 
     */
    onChangeFileNotificationBoarding(files) {
        return this.onChangeFileShipping(files, "NotificationBoarding", "notification_boarding")
    }

    /**
     * onDeleteFileNotificationBoarding
     * @param {*} fileName 
     */
    onDeleteFileNotificationBoarding(fileName) {
        return this.onDeleteFileShipping(fileName, "NotificationBoarding", "notification_boarding")
    }

    /**
     * onChangeFileSerialNumber
     * @param {*} files 
     */
    onChangeFileSerialNumber(files) {
        return this.onChangeFileShipping(files, "SerialNumber", "serial_numbers")
    }

    /**
     * onDeleteFileSerialNumber
     */
    onDeleteFileSerialNumber(fileName) {
        return this.onDeleteFileShipping(fileName, "SerialNumber", "serial_numbers")
    }

    /**
     * On render files shipping order
     * @param {*} title 
     * @param {*} onChange 
     * @param {*} onDelete 
     * @param {*} items 
     */
    renderFilesShippings(titleTranslate, onChange, onDelete, items, fileName) {
        let isActive = (IsCurrentNotActiveDirectoryUser(this.props.currentUser) && this.props.shippingOrder.can_be_update_supplier)
        let isrequieresQuality = '';
        let isRequieredSerial = '';
        if (this.props.shippingOrder.is_required_certificate_of_quality && titleTranslate === "certifiedOfQuality") {
            isrequieresQuality = "*** ";
        }
        if (this.props.shippingOrder.is_required_serial_number && titleTranslate === "serialNumberList") {
            isRequieredSerial = "*** ";
        }
        return (
            <TableRow key={uuidv4()} >
                <TableCell key={uuidv4()} align="left" padding="dense" style={{ maxWidth: '20%' }}>{isRequieredSerial}{isrequieresQuality}{<Translate id={'shippingOrder.' + titleTranslate} />}</TableCell>
                <TableCell key={uuidv4()} align="left" padding="dense" style={{ maxWidth: '30%' }}>{this.renderUploadDates(items)}</TableCell>
                <TableCell key={uuidv4()} align="left" padding="dense" style={{ maxWidth: '50%' }}>
                    <Dropzone
                        baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
                        onChange={onChange}
                        onDeleteFile={onDelete}
                        isDisabled={!isActive}
                        files={items}
                    />
                    {fileName && <HtmlEditor
                        id={fileName}
                        labelVariant={"body2"}
                        isDisabled
                        initialValue={fileName}
                    />}
                </TableCell>
            </TableRow>
        )
    }

    /**
     * Method to render files dates section
     *
     * @param {*} files
     * @returns
     * @memberof DocumentsList
     */
    renderUploadDates(files) {
        let rows = [];
        files.forEach(file => {
            rows.push(<div style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: "ellipsis" }}><Typography noWrap title={file.name}>{`${moment(file.creation_date).format("DD-MM-YYYY hh:mm")} | ${file.name}`}</Typography><br /></div>)
        });
        return (rows)
    }

    /**
     * Render
     */
    render() {
        const { classes } = this.props;
        return (
            <Paper>
                <List>
                    <ListItem>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                                        <strong> <Translate id="shippingOrder.documents" /></strong>
                                    </Typography>
                                </React.Fragment>
                            } />
                    </ListItem>
                </List>

                <Table style={{ maxWidth: '100%' }}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell><Translate id="shippingOrder.documentName" /></TableCell>
                            <TableCell><Translate id="shippingOrder.uploadDates" /></TableCell>
                            <TableCell><Translate id="shippingOrder.documentOptions" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderFilesShippings("invoices", this.onChangeFileInvoices, this.onDeleteFileInvoices, this.props.shippingOrder.invoices, this.props.shippingOrder.documents_to_invoices)}
                        {this.renderFilesShippings("packingList", this.onChangeFilePacking, this.onDeleteFilePacking, this.props.shippingOrder.packing, this.props.shippingOrder.documents_to_packing)}
                        {this.renderFilesShippings("certifiedOfOrigin", this.onChangeFileCertificateOfOrigin, this.onDeleteFileCertificateOfOrigin, this.props.shippingOrder.certificate_origin, "")}
                        {this.renderFilesShippings("certifiedOfQuality", this.onChangeFileCertificateOfQuality, this.onDeleteFileCertificateOfQuality, this.props.shippingOrder.certificate_quality, this.props.shippingOrder.document_certificate_of_quality)}
                        {this.renderFilesShippings("notificationOfShiping", this.onChangeFileNotificationBoarding, this.onDeleteFileNotificationBoarding, this.props.shippingOrder.notification_boarding, "")}
                        {this.renderFilesShippings("serialNumberList", this.onChangeFileSerialNumber, this.onDeleteFileSerialNumber, this.props.shippingOrder.serial_numbers, "")}
                    </TableBody>
                </Table>

            </Paper>
        );
    }
}

/**
 * shippingOrder
 */
DocumentsList.propTypes = {
    shippingOrder: PropTypes.object.isRequired
}