
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind';
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate } from "react-localize-redux";

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { CardHeader } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import WarrantyQualityIcon from '@material-ui/icons/VerticalSplit'; 

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import LoadingPage from '../common/LoadingPage';
import FloatingButton from '../common/FloatingButton';
import DialogAvailableOperations from '../common/DialogAvailableOperations';
import LayoutHeaderWorkFlow from '../common/LayoutHeaderWorkFlow';
import Toaster from '../common/Toaster';
import HistoryCard from '../common/HistoryCard';
import DataTable from '../common/DataTable';
import InformationPartial from './InformationPartial';
import WorkflowCancel from '../common/WorkflowCancel';
import Evidence from '../common/Evidence';
import AddCommonItem from '../common/AddCommonItem';

/** Import action section */
import { loadSupplier } from '../../store/suppliers/SupplierActions';
import {loadWarrantyReportPtmFromBackEnd, sendWarrantyReportPtmToSupplier,
uploadFilesEvidence, removeFilesEvidence, deleteWarrantyInDraft} from '../../store/warrantyReportsPtm/warrantyReportPtmAction';

import { WARRANTY_REPORT_PTM_NOTIFICATION, WORKFLOW_CANCELLATION} from '../../store/helpers/WorkFlowDocumentsType';
import { converListToArray } from '../../store/helpers/ListHelper'
import { canUserValideModuleQuality } from '../../store/helpers/RolesHelper';
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper';

/**
 * Container show WarrantyReportPtm
 */
class Show extends Component {

    /**
     * Create an instance WarrantyReportPtm
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            activeTab: 0,
            openAvailableOperationsDialog: false,
            openDeleteDraft: false,
        }
    }

    /**
    * componentDidMount
    */
    componentDidMount() {
        let rawMaterialToken = this.props.match.params.token;
        this.props.loadWarrantyReportPtm(rawMaterialToken);
    }

    /**
     * componentDidUpdate
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof Index
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoading !== this.props.isLoading) && this.props.warrantyReport !== null) {
            /// Get information full from WarrantyReportPtm
            let warrantyReport = this.props.warrantyReport;
            this.props.loadSupplier(warrantyReport.supplier_token);
        }
    }

    /**
     * Method to change the active tab
     */
    onClickToActiveTab = (event, value) => {
        this.setState({ activeTab: value });
    };

    /**
     * Action to set state to open of available operations dialog
     * @memberof 
     */
    setOpenDialog = (openAvailable) => {
        this.setState({
            openAvailableOperationsDialog: openAvailable,
        });
    }


       /**
     * Action on onSendWarrantyReportPtmToSupplier
     */
    onSendWarrantyReportPtmToSupplier() {
        this.props.sendWarrantyReportPtmToSupplier(this.props.match.params.token)
            .then(() => {
                this.setState({
                    openAvailableOperationsDialog: false,
                    showToView: true,
                    showToaster: true,
                    toasterVariant: "success",
                    toasterMessage: <Translate id="common.saveCorrectly" />,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }

      //Cancel a warrantyReportsPtm
      onCancelDocument() {
        this.props.history.push(`/warrantyReportsPtm/cancel/${this.props.match.params.token}`)
    }

      /**
   * onDeleteDraft
   *
   * @param {*} item
   * @param {*} supplier
   * @memberof Create
   */
  onDeleteDraft() {
    this.setState({
        openAvailableOperationsDialog: false,
        openDeleteDraft: true,
    })
}

    /**
    * onDeleteWarrantyInDraft
    */
   onDeleteWarrantyInDraft() {
    this.props.deleteWarrantyInDraft(this.props.match.params.token)
        .then(() => {
            this.setState({
                openDeleteDraft: false,
            })
            this.props.history.push(`/warrantyReportsPtm`)
        }).catch(() => {
            this.setState({
                showToaster: true,
                toasterMessage: <Translate id="common.errorToSave" />,
                toasterVariant: "error",
            })
        });
}

    /**
    * Get the card content
    *
    * @memberof Show
    */
    getCardContent() {
        if (this.props.isLoading === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.warrantyReport !== null) {
            const { classes } = this.props;
            return (
                <Grid container spacing={24}>
                     <Grid item xs={12}>
                        <Divider />
                        <AppBar position="static" color="default">
                            <Tabs indicatorColor="secondary" textColor="secondary"
                                className={classes.styleTab}
                                value={this.state.activeTab}
                                onChange={this.onClickToActiveTab}>
                                <Tab label={<p><WarrantyQualityIcon />{" Información"} </p>} />
                                <Tab label={"Evidencias"} />
                            </Tabs>
                        </AppBar>
                        {this.state.activeTab === 0 && <InformationPartial  {...this.props} />}
                        {this.state.activeTab === 1 &&
                            <Evidence
                                isDisabled={this.props.warrantyReport.is_closed || !IsCurrentActiveDirectoryUser(this.props.currentUser)}
                                baseApi={process.env.REACT_APP_SIP_QUALITY_URL_FILES}
                                removeFiles={this.props.removeFiles}
                                uploadFiles={this.props.uploadFiles}
                                files={this.props.warrantyReport.attachments}
                            />
                        }
                    </Grid>
                </Grid>
            )
        }
    }

    /**
     * Render hystoryCard for WarrantyReportPtm notification
     *
     * @param {*} planningEngineerName
     * @param {*} developmenEngineerName
     * @param {*} qualityUsersList
     * @returns
     * @memberof Show
     */
    renderAditionalData(qualityUsersList) {
        return (
            <Grid container spacing={24}>
                <CardHeader
                    style={{ paddingTop: '0px' }}
                    title={
                        <Typography gutterBottom variant="body2" component="p">
                            <strong>
                                <Translate id="common.sentTo" />:
                            </strong>
                        </Typography>
                    }
                />
                <Grid item xs={12}>
                    <DataTable
                        title={
                            <Typography gutterBottom variant="body2" component="p">
                                <strong>
                                    <Translate id="rawmaterialnonconformity.qualityUsersSupplier" />:
                                </strong>
                            </Typography>
                        }
                        data={qualityUsersList}
                        configuration={{
                            columns: [{
                                header: <Translate id="officialNotices.name" />,
                                dataSource: 'full_name',
                            }]
                        }}
                    />
                </Grid>

            </Grid>
        )
    }

    /**
     * Render hystory section for all documents
     *
     * @returns
     * @memberof Show
     */
    renderHistory() {
        let documents = this.props.warrantyReport ? this.props.warrantyReport.documents ? this.props.warrantyReport.documents : [] : []
        return (
            documents.map(document => {
                let titleId;
                let aditionalData;
                if (document.document_type === WARRANTY_REPORT_PTM_NOTIFICATION) {
                    //titleId = 'menu.rawMaterialsNotification';
                    aditionalData = this.renderAditionalData(document.users_quality);
                }
                if (document.document_type === WORKFLOW_CANCELLATION) {
                    titleId = 'common.folioCancel';
                    aditionalData = <WorkflowCancel document={document} />;
                }
                return (
                    <HistoryCard
                        key={document.token}
                        title={titleId}
                        userCreator={document.create_by}
                        creationDate={document.creation_date_long}
                        aditionalData={aditionalData}
                    />
                )

            })
        )
    }

    render() {
        let optonsAvalinable = this.props.warrantyReport ? this.props.warrantyReport.get_available_operations : [];
        return (
            <Card>
                {optonsAvalinable.length !== 0 && <FloatingButton open={this.setOpenDialog} />}

                <LayoutHeaderWorkFlow {...this.props}
                    workFlow={this.props.warrantyReport}
                    title={"Reporte de Garantía"}
                />

                <CardContent>
                    {this.getCardContent()}
                </CardContent>

                {this.renderHistory()}

                <DialogAvailableOperations
                    {...this.props}
                    isValidToCancel={canUserValideModuleQuality(this.props.userRolesByCompany)}
                    options={optonsAvalinable}
                    openAvailableOperationsDialog={this.state.openAvailableOperationsDialog}
                    setOpenDialog={this.setOpenDialog}
                    onSendWarrantyReportPtmToSupplier={this.onSendWarrantyReportPtmToSupplier}
                    onCancelDocument={this.onCancelDocument}
                    onDeleteDraft={this.onDeleteDraft}
                />

                {/** Dialog to delete draft */}
                < AddCommonItem
                    title={<Translate id="warrantyReportsPtm.removeWarranty"/>}
                    message={<Translate id="warrantyReportsPtm.messageToRemove"/>}
                    open={this.state.openDeleteDraft}
                    onClose={() => { this.setState({ openDeleteDraft: false }) }}
                    onSaveItem={this.onDeleteWarrantyInDraft}
                    isSaving={this.props.isSaving}
                    isMessage={true}
                />

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />

            </Card>
        )
    }

}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let warrantyReport = state.warrantyReports.get("warrantyReport");
    let selectedCompany = state.profile.get("selectedCompany");
    let user = state.oidc.user ? state.oidc.user : { profile: { name: "", email: "", user_type: "" } };
    let userRolesByCompany = state.profile.get('userRolesByCompany');
    return {
        currentUser: user,
        isLoading: state.warrantyReports.get('isLoading'),
        warrantyReport: warrantyReport ? warrantyReport.toJS() : null,
        isSaving: state.warrantyReports.get('isSaving'),
        supplier: state.suppliers.get("supplier") ? state.suppliers.get("supplier").toJS() : null,
        company: selectedCompany ? selectedCompany.toJS() : null,
        translate: getTranslate(state.localize),
        userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        loadWarrantyReportPtm: (token) => {
            return dispatch(loadWarrantyReportPtmFromBackEnd(token));
        },
        loadSupplier: (token) => {
            return (dispatch(loadSupplier(token)))
        },
        sendWarrantyReportPtmToSupplier:(token) =>{
            return dispatch(sendWarrantyReportPtmToSupplier(token));
        },
        uploadFiles: (files) => {
            return dispatch(uploadFilesEvidence(files));
        },
        removeFiles: (fileName) => {
            return dispatch(removeFilesEvidence(fileName));
        },
        deleteWarrantyInDraft: (token) => {
            return dispatch(deleteWarrantyInDraft(token))
        },
    }
}

const styles = theme => ({
    actionsToolbar: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    inline: {
        display: 'inline',
    },
    logoCompany: {
        maxHeight: 100
    },
    logoSupplier: {
        marginLeft: 80,
        maxHeight: 80
    },
    styleTab: {
        backgroundColor: "#fff",
    },
    margin: {
        margin: theme.spacing.unit * 2,
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show))));