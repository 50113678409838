import React from 'react'
import Select from 'react-select'
/** Material UI imports section */
import { Typography, withStyles } from '@material-ui/core'
const SelectorComponent = ({
	title,
	placeholder,
	options,
	onChange,
	classes,
	isLoading,
	value,
	isDisabled = false,
}) => {
	return (
		<div>
			<div className={classes.createManualStyles}>
				<Typography variant='subtitle2'>{title}</Typography>
			</div>
			<Select
				placeholder={placeholder}
				options={options}
				className={classes.selectStyles}
				onChange={(option) => onChange(option)}
				styles={{
					menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
					menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
					menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
				}}
				menuPlacement={'auto'}
				isLoading={isLoading}
				value={value}
				isDisabled={isDisabled}
			/>
		</div>
	)
}

const styles = (theme) => ({
	createManualStyles: {
		margin: '6px 0px 6px',
	},
})
export default withStyles(styles, { withTheme: true })(SelectorComponent)
