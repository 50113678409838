import React from 'react'
import { Translate } from 'react-localize-redux'
import { IsUserSupplierAdministrator } from './SessionHelper'
import { canBeModuleIndirectRatingSupplier } from './RolesHelper'
import { getColorStatusAction } from './SustainabilityHelper'
import {
	IndirectQualificationsStatus,
	ItemType,
	SectionType,
	SupplierType,
	IndirectQualificationType,
	IndirectQualificationsSupplierStatus,
	ActionPlantStatus,
	ClassificationType,
	Evaluationclassification,
} from './AppConstants'

import {
	COLOR_PRODUCT,
	COLOR_SUBSECTION_PRODUCT,
	COLOR_SERVICE,
	COLOR_SUBSECTION_SERVICE,
	COLOR_EXTRA,
	COLOR_SUBSECTION_EXTRA,
	COLOR_FLEXIBILITY,
	COLOR_SUBSECTION_FLEXIBILITY,
	COLOR_AVAILABILITY,
	COLOR_SUBSECTION_AVAILABILITY,
	COLOR_EFFECTIVE_COMUNICATION,
	COLOR_SUBSECTION_EFFECTIVE_COMUNICATION,
	COLOR_COMPLIANCE_PROCESSES,
	COLOR_SUBSECTION_COMPLIANCE_PROCESSES,
	COLOR_INCIDENCTS,
	COLOR_SUBSECTION_INCIDENCTS,
	COLOR_UNITS,
	COLOR_SUBSECTION_UNITS,
	COLOR_STATUS_DANGER,
	COLOR_STATUS_GRAY,
	COLOR_STATUS_WARNING,
	COLOR_SECONDARY_BLUE_LIGHT,
	COLOR_STATUS_LIGHT,
	COLOR_STATUS_INFO,
	COLOR_STATUS_SUCCESS,
	RELEASE_PLAN_COLOR_BLUE_GRAY,
} from './StatusColorConstants'

export const GetColorEvaluation = (description) => {
	switch (description) {
		case Evaluationclassification.RELIABILITY_SUPPLIER:
		case Evaluationclassification.RELIABILITY_WHITH_OPPORTUNITY:
			return COLOR_STATUS_SUCCESS
		case Evaluationclassification.REGURAR_WHITH_OPPORTUNITY:
			return COLOR_STATUS_WARNING
		case Evaluationclassification.UNREALIABLE_SUPPLIER:
			return COLOR_STATUS_DANGER
		default:
			return ''
	}
}

export const GetDescriptionEvaluation = (description, translate) => {
	switch (description) {
		case Evaluationclassification.RELIABILITY_SUPPLIER:
			return translate('indirectqualifications.clasification.reliableSupplier')
		case Evaluationclassification.RELIABILITY_WHITH_OPPORTUNITY:
			return translate('indirectqualifications.clasification.reliableWithOpportunity')
		case Evaluationclassification.REGURAR_WHITH_OPPORTUNITY:
			return translate('indirectqualifications.clasification.regularWithOpportunity')
		case Evaluationclassification.UNREALIABLE_SUPPLIER:
			return translate('indirectqualifications.clasification.unreliableSupplier')
		default:
			return translate('indirectqualifications.clasification.withoutEvaluating')
	}
}

export const canBeEditInStatusDraft = (status) => {
	if (status === IndirectQualificationsStatus.DRAFT) return true
	return false
}

export const canBeEditInStatusGenerateRatings = (status) => {
	if (status === IndirectQualificationsStatus.GENERATE_RATING) return true
	return false
}

export const canBeEditInStatusPublishRatings = (status) => {
	if (status === IndirectQualificationsStatus.PUBLISH_RATINGS) return true
	return false
}

export const canBeEditInStatusSendToEvaluate = (status) => {
	if (status === IndirectQualificationsStatus.SEND_TO_EVALUATE) return true
	return false
}

export const canBeRemoveFolioCancel = (status) => {
	if (status === IndirectQualificationsStatus.CANCEL) return true
	return false
}

export const colorStatusIndirectQualification = (status) => {
	switch (Number(status)) {
		case IndirectQualificationsStatus.DRAFT:
			return COLOR_STATUS_GRAY
		case IndirectQualificationsStatus.SEND_TO_EVALUATE:
			return COLOR_STATUS_WARNING
		case IndirectQualificationsStatus.CANCEL:
			return COLOR_STATUS_DANGER
		case IndirectQualificationsStatus.GENERATE_RATING:
			return COLOR_STATUS_LIGHT
		case IndirectQualificationsStatus.PUBLISH_RATINGS:
			return COLOR_STATUS_SUCCESS
		default:
			return COLOR_STATUS_GRAY
	}
}

export const backgroundColorTableStatus = (status) => {
	switch (Number(status)) {
		case IndirectQualificationsStatus.DRAFT:
			return 'secondary'
		case IndirectQualificationsStatus.SEND_TO_EVALUATE:
			return 'warning'
		case IndirectQualificationsStatus.CANCEL:
			return 'danger'
		case IndirectQualificationsStatus.GENERATE_RATING:
			return 'primary'
		case IndirectQualificationsStatus.PUBLISH_RATINGS:
			return 'success'
		default:
			return ''
	}
}

export const getColorTableGraph = (description) => {
	switch (description) {
		case Evaluationclassification.RELIABILITY_SUPPLIER:
		case Evaluationclassification.RELIABILITY_WHITH_OPPORTUNITY:
			return 'success'
		case Evaluationclassification.REGURAR_WHITH_OPPORTUNITY:
			return 'warning'
		case Evaluationclassification.UNREALIABLE_SUPPLIER:
			return 'danger'
		default:
			return ''
	}
}

export const actionPlantStatus = (status) => {
	switch (Number(status)) {
		case ActionPlantStatus.PENDIENT:
			return <Translate id='indirectqualifications.actionPlansStatus.pendient' />
		case ActionPlantStatus.OPEN:
			return <Translate id='indirectqualifications.actionPlansStatus.open' />
		case ActionPlantStatus.PROCESS:
			return <Translate id='indirectqualifications.actionPlansStatus.process' />
		case ActionPlantStatus.AUTHORIZER:
			return <Translate id='indirectqualifications.actionPlansStatus.authorizer' />
		case ActionPlantStatus.CLOSE:
			return <Translate id='indirectqualifications.actionPlansStatus.close' />
		default:
			return ''
	}
}

export const descriptionStatus = (status, translate) => {
	switch (Number(status)) {
		case IndirectQualificationsStatus.DRAFT:
			return translate('indirectqualifications.status.draft')
		case IndirectQualificationsStatus.SEND_TO_EVALUATE:
			return translate('indirectqualifications.status.sendToEvaluate')
		case IndirectQualificationsStatus.CANCEL:
			return translate('indirectqualifications.status.cancel')
		case IndirectQualificationsStatus.GENERATE_RATING:
			return translate('indirectqualifications.status.generateRating')
		case IndirectQualificationsStatus.PUBLISH_RATINGS:
			return translate('indirectqualifications.status.publish')
		default:
			return ''
	}
}

export const descriptionIndirectEvaluationType = (evaluationType) => {
	switch (Number(evaluationType)) {
		case IndirectQualificationType.SERVICE:
			return <Translate id='indirectqualifications.evaluationType.service' />
		case IndirectQualificationType.CARRIER:
			return <Translate id='indirectqualifications.evaluationType.sparePart' />
		default:
			return ''
	}
}

export const descriptionIndirectEvaluationTypeTranslate = (evaluationType, translate) => {
	switch (Number(evaluationType)) {
		case IndirectQualificationType.SERVICE:
			return translate('indirectqualifications.evaluationType.service')
		case IndirectQualificationType.CARRIER:
			return translate('indirectqualifications.evaluationType.sparePart')
		default:
			return ''
	}
}

export const descriptionItemType = (itemType) => {
	switch (Number(itemType)) {
		case ItemType.DELIVERY:
			return <Translate id='indirectqualifications.itemType.delivery' />
		case ItemType.SPECIFICATION_COMPLIANCE:
			return <Translate id='indirectqualifications.itemType.specificationCompliance' />
		case ItemType.PRICE:
			return <Translate id='indirectqualifications.itemType.price' />
		case ItemType.DELIVERY_DOCUMENTATION:
			return <Translate id='indirectqualifications.itemType.deliveryDocumentation' />
		case ItemType.CONFIRMATION:
			return <Translate id='indirectqualifications.itemType.confirmation' />
		case ItemType.RESPONSE_TIMES:
			return <Translate id='indirectqualifications.itemType.responseTimes' />
		case ItemType.RELIABILITY:
			return <Translate id='indirectqualifications.itemType.reliability' />
		case ItemType.CONTROL_PROCESS:
			return <Translate id='indirectqualifications.itemType.controlProcess' />
		case ItemType.CHANGES:
			return <Translate id='indirectqualifications.itemType.changes' />
		case ItemType.EMERGENCIES:
			return <Translate id='indirectqualifications.itemType.mergencies' />
		case ItemType.UNITS:
			return <Translate id='indirectqualifications.itemType.units' />
		case ItemType.NOTIFICATION_TRAVEL:
			return <Translate id='indirectqualifications.itemType.notificationsTravel' />
		case ItemType.ROUTE_CHANGE:
			return <Translate id='indirectqualifications.itemType.routeChanges' />
		case ItemType.NOTIFICATION_INCIDENCE:
			return <Translate id='indirectqualifications.itemType.notificationIncidence' />
		case ItemType.CARRIERS:
			return <Translate id='indirectqualifications.itemType.carriers' />
		case ItemType.REGULATIONS:
			return <Translate id='indirectqualifications.itemType.regulations' />
		case ItemType.DOCUMENTS_ADMINISTRATION:
			return <Translate id='indirectqualifications.itemType.docuemntsAdministration' />
		case ItemType.LOADING_PUNCTUALITY:
			return <Translate id='indirectqualifications.itemType.loadingPunctuality' />
		case ItemType.PUNTUALITY_DELIVERY:
			return <Translate id='indirectqualifications.itemType.puntalityDelivery' />
		case ItemType.TARIFF_COST:
			return <Translate id='indirectqualifications.itemType.tariffCost' />
		case ItemType.QUALITY_SERCICE:
			return <Translate id='indirectqualifications.itemType.qualityService' />
		case ItemType.CARRIER_DAMAGE:
			return <Translate id='indirectqualifications.itemType.carrierDamage' />
		case ItemType.DEVOLUTIONS:
			return <Translate id='indirectqualifications.itemType.devolutions' />
		case ItemType.SINISTER:
			return <Translate id='indirectqualifications.itemType.sinister' />
		case ItemType.CRITICAL:
			return <Translate id='indirectqualifications.itemType.critical' />
		case ItemType.OTHERS:
			return <Translate id='indirectqualifications.itemType.others' />
		case ItemType.CLEAN:
			return <Translate id='indirectqualifications.itemType.clean' />
		case ItemType.CONDITIONS:
			return <Translate id='indirectqualifications.itemType.conditions' />
		case ItemType.REQUESTED_EQUIPMENT:
			return <Translate id='indirectqualifications.itemType.requestedEquipment' />
		case ItemType.INFORMATION_AVAILABILITY:
			return <Translate id='indirectqualifications.itemType.informationAvailability' />
		case ItemType.UNITS_CANCELLATION:
			return <Translate id='indirectqualifications.itemType.unitsCancellation' />
		case ItemType.REJECTED:
			return <Translate id='indirectqualifications.itemType.rejected' />
		case ItemType.MECHANICAL_FAILURE:
			return <Translate id='indirectqualifications.itemType.mechanicalFailure' />
		case ItemType.UNITS_STATUS:
			return <Translate id='indirectqualifications.itemType.unitsStatus' />
		case ItemType.GPS:
			return <Translate id='indirectqualifications.itemType.gps' />
		case ItemType.EXPENSE_LETTER:
			return <Translate id='indirectqualifications.itemType.expenseLetter' />
		case ItemType.DELIVERY_ACKNOWLEDGMENT:
			return <Translate id='indirectqualifications.itemType.deliveryAcknowledgment' />
		case ItemType.UPDATED_STATEMENTS:
			return <Translate id='indirectqualifications.itemType.updatedStatements' />
		case ItemType.PHYSICAL_STATE:
			return <Translate id='indirectqualifications.itemType.physicalState' />
		case ItemType.AIR_SUSPENSION:
			return <Translate id='indirectqualifications.itemType.airSuspension' />
		case ItemType.FASTENING_EQUIPMENT:
			return <Translate id='indirectqualifications.itemType.fasteningEquipment' />
		case ItemType.CIRCULATION_CARD:
			return <Translate id='indirectqualifications.itemType.circulationCard' />
		case ItemType.LICENSE:
			return <Translate id='indirectqualifications.itemType.license' />
		default:
			return ''
	}
}

export const descriptionWarningItemType = (itemType) => {
	switch (Number(itemType)) {
		case ItemType.DELIVERY:
			return <Translate id='indirectqualifications.warnings.delivery' />
		case ItemType.SPECIFICATION_COMPLIANCE:
			return <Translate id='indirectqualifications.warnings.specificationCompliance' />
		case ItemType.PRICE:
			return <Translate id='indirectqualifications.warnings.price' />
		case ItemType.DELIVERY_DOCUMENTATION:
			return <Translate id='indirectqualifications.warnings.deliveryDocumentation' />
		case ItemType.CONFIRMATION:
			return <Translate id='indirectqualifications.warnings.confirmation' />
		case ItemType.RESPONSE_TIMES:
			return <Translate id='indirectqualifications.warnings.responseTimes' />
		case ItemType.RELIABILITY:
			return <Translate id='indirectqualifications.warnings.reliability' />
		case ItemType.CONTROL_PROCESS:
			return <Translate id='indirectqualifications.warnings.controlProcess' />
		case ItemType.CHANGES:
			return <Translate id='indirectqualifications.warnings.changes' />
		case ItemType.EMERGENCIES:
			return <Translate id='indirectqualifications.warnings.mergencies' />
		case ItemType.UNITS:
			return <Translate id='indirectqualifications.warnings.units' />
		case ItemType.NOTIFICATION_TRAVEL:
			return <Translate id='indirectqualifications.warnings.notificationsTravel' />
		case ItemType.ROUTE_CHANGE:
			return <Translate id='indirectqualifications.warnings.routeChanges' />
		case ItemType.NOTIFICATION_INCIDENCE:
			return <Translate id='indirectqualifications.warnings.notificationIncidence' />
		case ItemType.CARRIERS:
			return <Translate id='indirectqualifications.warnings.carriers' />
		case ItemType.REGULATIONS:
			return <Translate id='indirectqualifications.warnings.regulations' />
		case ItemType.DOCUMENTS_ADMINISTRATION:
			return <Translate id='indirectqualifications.warnings.docuemntsAdministration' />
		case ItemType.LOADING_PUNCTUALITY:
			return <Translate id='indirectqualifications.warnings.loadingPunctuality' />
		case ItemType.PUNTUALITY_DELIVERY:
			return <Translate id='indirectqualifications.warnings.puntalityDelivery' />
		case ItemType.TARIFF_COST:
			return <Translate id='indirectqualifications.warnings.tariffCost' />
		case ItemType.QUALITY_SERCICE:
			return <Translate id='indirectqualifications.warnings.qualityService' />
		case ItemType.CARRIER_DAMAGE:
			return <Translate id='indirectqualifications.warnings.carrierDamage' />
		case ItemType.DEVOLUTIONS:
			return <Translate id='indirectqualifications.warnings.devolutions' />
		case ItemType.SINISTER:
			return <Translate id='indirectqualifications.warnings.sinister' />
		case ItemType.CRITICAL:
			return <Translate id='indirectqualifications.warnings.critical' />
		case ItemType.OTHERS:
			return <Translate id='indirectqualifications.warnings.others' />
		case ItemType.CLEAN:
			return <Translate id='indirectqualifications.warnings.clean' />
		case ItemType.CONDITIONS:
			return <Translate id='indirectqualifications.warnings.conditions' />
		case ItemType.REQUESTED_EQUIPMENT:
			return <Translate id='indirectqualifications.warnings.requestedEquipment' />
		case ItemType.INFORMATION_AVAILABILITY:
			return <Translate id='indirectqualifications.warnings.informationAvailability' />
		case ItemType.UNITS_CANCELLATION:
			return <Translate id='indirectqualifications.warnings.unitsCancellation' />
		case ItemType.REJECTED:
			return <Translate id='indirectqualifications.warnings.rejected' />
		case ItemType.MECHANICAL_FAILURE:
			return <Translate id='indirectqualifications.warnings.mechanicalFailure' />
		case ItemType.UNITS_STATUS:
			return <Translate id='indirectqualifications.warnings.unitsStatus' />
		case ItemType.GPS:
			return <Translate id='indirectqualifications.warnings.gps' />
		case ItemType.EXPENSE_LETTER:
			return <Translate id='indirectqualifications.warnings.expenseLetter' />
		case ItemType.DELIVERY_ACKNOWLEDGMENT:
			return <Translate id='indirectqualifications.warnings.deliveryAcknowledgment' />
		case ItemType.UPDATED_STATEMENTS:
			return <Translate id='indirectqualifications.warnings.updatedStatements' />
		case ItemType.PHYSICAL_STATE:
			return <Translate id='indirectqualifications.warnings.physicalState' />
		case ItemType.AIR_SUSPENSION:
			return <Translate id='indirectqualifications.warnings.airSuspension' />
		case ItemType.FASTENING_EQUIPMENT:
			return <Translate id='indirectqualifications.warnings.fasteningEquipment' />
		case ItemType.CIRCULATION_CARD:
			return <Translate id='indirectqualifications.warnings.circulationCard' />
		case ItemType.LICENSE:
			return <Translate id='indirectqualifications.warnings.license' />
		default:
			return ''
	}
}

export const descriptionSectionType = (sectionType) => {
	switch (Number(sectionType)) {
		case SectionType.PRODUCT:
			return <Translate id='indirectqualifications.sectionType.product' />
		case SectionType.SERVICE:
			return <Translate id='indirectqualifications.sectionType.service' />
		case SectionType.EXTRA:
			return <Translate id='indirectqualifications.sectionType.extra' />
		case SectionType.FLEXIBILITY:
			return <Translate id='indirectqualifications.sectionType.flexibility' />
		case SectionType.AVAILABILITY:
			return <Translate id='indirectqualifications.sectionType.availability' />
		case SectionType.EFFECTIVE_COMUNICATION:
			return <Translate id='indirectqualifications.sectionType.comunication' />
		case SectionType.COMPLIANCE_PROCESSES:
			return <Translate id='indirectqualifications.sectionType.processes' />
		case SectionType.SERVICE_TRANSPORT:
			return <Translate id='indirectqualifications.sectionType.service' />
		case SectionType.INCIDENCTS:
			return <Translate id='indirectqualifications.sectionType.incidencts' />
		case SectionType.UNITS:
			return <Translate id='indirectqualifications.sectionType.units' />
		case SectionType.COMPLIANCE_ADMINISTRATIVE:
			return <Translate id='indirectqualifications.sectionType.administrative' />
		case SectionType.DOCUMENTATION:
			return <Translate id='indirectqualifications.sectionType.doumentation' />
		default:
			return ''
	}
}

export const descriptionSupplierType = (supplierType, translate) => {
	switch (Number(supplierType)) {
		case SupplierType.PRODUCT:
			return translate('indirectqualifications.suplierType.product')
		case SupplierType.SERVICE:
			return translate('indirectqualifications.suplierType.service')
		case SupplierType.CARRIER:
			return translate('indirectqualifications.suplierType.carrier')
		default:
			return ''
	}
}

export const getBackgroundColorBySectionType = (sectionType) => {
	switch (Number(sectionType)) {
		case SectionType.PRODUCT:
			return COLOR_PRODUCT
		case SectionType.SERVICE:
			return COLOR_SERVICE
		case SectionType.EXTRA:
			return COLOR_EXTRA
		case SectionType.FLEXIBILITY:
			return COLOR_FLEXIBILITY
		case SectionType.AVAILABILITY:
			return COLOR_AVAILABILITY
		case SectionType.EFFECTIVE_COMUNICATION:
			return COLOR_EFFECTIVE_COMUNICATION
		case SectionType.COMPLIANCE_PROCESSES:
			return COLOR_COMPLIANCE_PROCESSES
		case SectionType.SERVICE_TRANSPORT:
			return COLOR_SERVICE
		case SectionType.INCIDENCTS:
			return COLOR_INCIDENCTS
		case SectionType.UNITS:
			return COLOR_UNITS
		case SectionType.COMPLIANCE_ADMINISTRATIVE:
			return COLOR_SERVICE
		case SectionType.DOCUMENTATION:
			return COLOR_AVAILABILITY
		default:
			return ''
	}
}

export const getBackgroundColorInItemType = (sectionType) => {
	switch (Number(sectionType)) {
		case SectionType.PRODUCT:
			return COLOR_SUBSECTION_PRODUCT
		case SectionType.SERVICE:
			return COLOR_SUBSECTION_SERVICE
		case SectionType.EXTRA:
			return COLOR_SUBSECTION_EXTRA
		case SectionType.FLEXIBILITY:
			return COLOR_SUBSECTION_FLEXIBILITY
		case SectionType.AVAILABILITY:
			return COLOR_SUBSECTION_AVAILABILITY
		case SectionType.EFFECTIVE_COMUNICATION:
			return COLOR_SUBSECTION_EFFECTIVE_COMUNICATION
		case SectionType.COMPLIANCE_PROCESSES:
			return COLOR_SUBSECTION_COMPLIANCE_PROCESSES
		case SectionType.SERVICE_TRANSPORT:
			return COLOR_SUBSECTION_SERVICE
		case SectionType.INCIDENCTS:
			return COLOR_SUBSECTION_INCIDENCTS
		case SectionType.UNITS:
			return COLOR_SUBSECTION_UNITS
		case SectionType.COMPLIANCE_ADMINISTRATIVE:
			return COLOR_SUBSECTION_SERVICE
		case SectionType.DOCUMENTATION:
			return COLOR_SUBSECTION_AVAILABILITY
		default:
			return ''
	}
}

export function orderGroups(result, propertyName) {
	result.sort(function (a, b) {
		var x = a[propertyName]
		var y = b[propertyName]
		if (x < y) {
			return -1
		}
		if (x > y) {
			return 1
		}
		return 0
	})
	return result
}

export const onGetHeaderQualificationsTableBySectionType = (indirectQualification) => {
	let mappedInformation = []

	if (indirectQualification === null) return []
	if (indirectQualification.Suppliers.length === 0) return []
	let supplier = indirectQualification.Suppliers[0]
	supplier.Items.forEach((item) => {
		let existInformation = mappedInformation.find((x) => x.SectionType === item.SectionType)
		if (existInformation === undefined) {
			item.ColumnSpam = 1
			item.SectionTypeDescription = descriptionSectionType(item.SectionType)
			item.BackgroundColor = getBackgroundColorBySectionType(item.SectionType)
			mappedInformation.push(item)
		} else {
			existInformation.ColumnSpam++
		}
	})
	return orderGroups(mappedInformation, 'SectionType')
	//return mappedInformation
}

export const onGetHeaderQualificationsTableByItemType = (indirectQualification, isUserNotActiveDirectory) => {
	let mappedInformation = []

	if (indirectQualification === null) return []
	if (indirectQualification.Suppliers.length === 0) return []
	let supplier = indirectQualification.Suppliers[0]
	supplier.Items.forEach((item) => {
		let existInformation = mappedInformation.find(
			(x) => x.ItemType === item.ItemType && x.SectionType === item.SectionType
		)
		if (existInformation === undefined || existInformation === null) {
			mappedInformation.push(item)
			item.ItemTypeDescription = descriptionItemType(item.ItemType)
			item.BackgroundColor = getBackgroundColorInItemType(item.SectionType)
		}
	})
	let headerOptions = orderGroups(mappedInformation, 'SectionType')

	/**Add columns (rating, action plant) aditionales when rating is generated */
	if (indirectQualification.Status >= IndirectQualificationsStatus.GENERATE_RATING) {
		headerOptions.push({
			ItemTypeDescription: 'Calificación final',
			BackgroundColor: COLOR_SECONDARY_BLUE_LIGHT,
		})
		headerOptions.push({
			ItemTypeDescription: 'Clasificación',
			BackgroundColor: COLOR_SECONDARY_BLUE_LIGHT,
		})
	}

	/**Add columns (action plant) aditionales when rating is publish */
	if (indirectQualification.Status >= IndirectQualificationsStatus.PUBLISH_RATINGS && !isUserNotActiveDirectory) {
		headerOptions.push({
			ItemTypeDescription: 'Plan de acciones',
			BackgroundColor: COLOR_SECONDARY_BLUE_LIGHT,
		})
	} else if (
		indirectQualification.Status >= IndirectQualificationsStatus.PUBLISH_RATINGS &&
		isUserNotActiveDirectory
	) {
		headerOptions.push({
			ItemTypeDescription: 'Plan de acciones',
			BackgroundColor: COLOR_SECONDARY_BLUE_LIGHT,
		})
	}
	return headerOptions
}

export const onGetHeaderTableIndirectQualificationItemTypeType = (indirectQualification, isUserNotActiveDirectory) => {
	let mappedHeadersBySection = onGetHeaderQualificationsTableByItemType(
		indirectQualification,
		isUserNotActiveDirectory
	)
	let tableConfiguration = [
		{
			title: '',
		},
		{
			title: '-',
		},
		{
			title: 'Estatus',
		},
		{
			title: 'Proveedor',
		},
		{
			title: 'Tipo de proveedor',
		},
		{
			title: 'Concepto',
		},
	]
	mappedHeadersBySection.forEach((column) => {
		tableConfiguration.push({
			title: column.ItemTypeDescription,
			dataSource: column.Name,
			backgroundColor: column.BackgroundColor,
		})
	})
	return tableConfiguration
}

export const onGetHeaderTableIndirectQualificationSectionType = (indirectQualification) => {
	let mappedHeadersBySection = onGetHeaderQualificationsTableBySectionType(indirectQualification)
	let tableConfiguration = []
	mappedHeadersBySection.forEach((column) => {
		tableConfiguration.push({
			title: column.SectionTypeDescription,
			dataSource: column.Name,
			columnSpam: column.ColumnSpam,
			backgroundColor: column.BackgroundColor,
		})
	})
	return tableConfiguration
}

export const descriptionSupplierStatus = (status, translate) => {
	switch (Number(status)) {
		case IndirectQualificationsSupplierStatus.DRAFT:
			return translate('indirectqualifications.supplierStatus.draft')
		case IndirectQualificationsSupplierStatus.SEND_EVALUATE:
			return translate('indirectqualifications.supplierStatus.sendEvaluate')
		case IndirectQualificationsSupplierStatus.EVALUATE_RESPONSIBLE:
			return translate('indirectqualifications.supplierStatus.evaluateResponsible')
		case IndirectQualificationsSupplierStatus.GENERATE_RATING:
			return translate('indirectqualifications.supplierStatus.generateRatings')
		case IndirectQualificationsSupplierStatus.PUBLISH_RATING:
			return translate('indirectqualifications.supplierStatus.publishRatings')
		case IndirectQualificationsSupplierStatus.GENERATE_ACTION_PLANS:
			return translate('indirectqualifications.supplierStatus.generatPlantAction')
		case IndirectQualificationsSupplierStatus.SEND_ACTION_PLANT_TO_SUPPLIER:
			return translate('indirectqualifications.supplierStatus.sendToSupplier')
		case IndirectQualificationsSupplierStatus.ACTION_PLANT_RESPONSE:
			return translate('indirectqualifications.supplierStatus.actionPlantResponse')
		case IndirectQualificationsSupplierStatus.CLOSE:
			return translate('indirectqualifications.supplierStatus.close')
		case IndirectQualificationsSupplierStatus.CANCEL:
			return translate('indirectqualifications.supplierStatus.cancel')
		default:
			return ''
	}
}

export const backgroundColorTableSupplierStatus = (status) => {
	switch (Number(status)) {
		case IndirectQualificationsSupplierStatus.DRAFT:
			return COLOR_STATUS_GRAY
		case IndirectQualificationsSupplierStatus.SEND_EVALUATE:
			return COLOR_STATUS_WARNING
		case IndirectQualificationsSupplierStatus.EVALUATE_RESPONSIBLE:
			return COLOR_STATUS_INFO
		case IndirectQualificationsSupplierStatus.GENERATE_RATING:
			return COLOR_STATUS_LIGHT
		case IndirectQualificationsSupplierStatus.PUBLISH_RATING:
			return COLOR_STATUS_SUCCESS
		case IndirectQualificationsSupplierStatus.GENERATE_ACTION_PLANS:
			return COLOR_STATUS_WARNING
		case IndirectQualificationsSupplierStatus.SEND_ACTION_PLANT_TO_SUPPLIER:
			return RELEASE_PLAN_COLOR_BLUE_GRAY
		case IndirectQualificationsSupplierStatus.ACTION_PLANT_RESPONSE:
			return COLOR_STATUS_WARNING
		case IndirectQualificationsSupplierStatus.CLOSE:
			return COLOR_STATUS_SUCCESS
		case IndirectQualificationsSupplierStatus.CANCEL:
			return COLOR_STATUS_DANGER
		default:
			return ''
	}
}

export const onGetItemsTableIndirectQualification = (indirectQualification, translate) => {
	let mappedInformation = []

	if (indirectQualification === null) return []
	if (indirectQualification.Suppliers.length === 0) return []
	indirectQualification.Suppliers.forEach((supplier) => {
		let orderItems = orderGroups(supplier.Items, 'SectionType')
		orderItems.forEach((item) => {
			let existSupplier = mappedInformation.find((x) => x.Code === supplier.Code && x.Id === supplier.Id)
			if (existSupplier === undefined) {
				let mappedSupplier = supplier
				mappedSupplier.IndirectQualificationId = indirectQualification.Id
				mappedSupplier.EvaluationType = indirectQualification.EvaluationType
				mappedSupplier.StatusDescription = descriptionSupplierStatus(supplier.Status, translate)
				mappedSupplier.ColorStatustable = backgroundColorTableSupplierStatus(supplier.Status)
				mappedSupplier.Items = []
				let mappedItem = item
				mappedItem.IndirectQualificationId = indirectQualification.Id
				mappedItem.SupplierId = supplier.Id
				mappedItem.SupplierCode = supplier.Code
				mappedSupplier.Items.push(mappedItem)
				mappedInformation.push(mappedSupplier)
			} else {
				let mappedItem = item
				mappedItem.IndirectQualificationId = indirectQualification.Id
				mappedItem.SupplierCode = supplier.Code
				mappedItem.SupplierId = supplier.Id
				existSupplier.Items.push(mappedItem)
			}
		})
	})
	return mappedInformation
}

/**
 * Get total ratin by supplier
 * @param {*} indirectQualification
 * @returns
 */
export const GetTotalRatingSupplier = (indirectQualification) => {
	let mappedInformation = {
		Code: '',
		TotalRating: '',
		DescriptionEvaluation: '',
	}

	if (indirectQualification === null) return mappedInformation
	if (indirectQualification.Suppliers.length === 0) return mappedInformation

	indirectQualification.Suppliers.forEach((supplier) => {
		if (mappedInformation.Code === '') {
			mappedInformation.Code = supplier.Code
			mappedInformation.TotalRating = supplier.TotalRating
			mappedInformation.DescriptionEvaluation = supplier.DescriptionEvaluation
		}
	})
	return mappedInformation
}

/**
 * Action to determine if the user supplier is administrator on is role supplier
 * @param {*} supplier
 * @param {*} userRolesByCompany
 * @param {*} userProfile
 */
export function canBeEditSupplier(supplier, userRolesByCompany, userProfile) {
	if (userRolesByCompany === undefined) return false
	if (userProfile === null || userProfile === undefined) return false
	if (userRolesByCompany.length === 0) return false
	if (supplier === null || supplier === undefined) return false
	else {
		let supplierAdmin = IsUserSupplierAdministrator(userProfile)
		return supplierAdmin || canBeModuleIndirectRatingSupplier(userRolesByCompany)
	}
}

/**
 * Determne can be edti responsible in plant
 * @param {*} item
 * @param {*} userRolesByCompany
 * @param {*} userProfile
 */
export function canBeResponsible(item, userRolesByCompany, userProfile) {
	if (userRolesByCompany === undefined) return false
	if (userProfile === null || userProfile === undefined) return false
	if (userRolesByCompany.length === 0) return false
	if (item === null || item === undefined) return false
	return true
}

/**
 * Selection list by classification
 * @param {*} items
 */
export const getActionsByCriticalList = (supplier) => {
	if (supplier === null) return []
	let itemsCritical = supplier
		? supplier.Actions.filter((x) => x.Item.ClassificationType === ClassificationType.CRITICAL)
		: []
	let mappedResponse = itemsCritical.map((action) => {
		action.color_status_table = getColorStatusAction(action.Status)
		return action
	})
	return mappedResponse
}

/**
 * This method get filter OpportunityArea
 * @param {*} groupName
 * @param {*} items
 */
export const getActionsByOpportunityList = (supplier) => {
	if (supplier === null) return []
	let itemsOpportunity = supplier
		? supplier.Actions.filter((x) => x.Item.ClassificationType === ClassificationType.OPPORTUNITYAREA)
		: []

	let mappedResponse = itemsOpportunity.map((action) => {
		action.color_status_table = getColorStatusAction(action.Status)
		return action
	})
	return mappedResponse
}

export const onGetHeaderEvaluatorsTableByItemType = (evaluators, isUserNotActiveDirectory, indirectQualification) => {
	let mappedInformation = []

	if (evaluators === null) return []
	if (evaluators === null) return []
	if (indirectQualification === null) return []
	let evaluator = evaluators[0]
	evaluator.Items.forEach((item) => {
		let existInformation = mappedInformation.find(
			(x) => x.ItemType === item.ItemType && x.SectionType === item.SectionType
		)
		if (existInformation === undefined || existInformation === null) {
			item.ItemTypeDescription = descriptionItemType(item.ItemType)
			item.DescriptionWarningItemType = descriptionWarningItemType(item.ItemType)
			//item.BackgroundColor = descriptionItemType(item.ItemType)
			item.BackgroundColor = getBackgroundColorInItemType(item.SectionType)
			mappedInformation.push(item)
		}
	})
	let headerOptions = orderGroups(mappedInformation, 'SectionType')

	/**Add columns (rating, action plant) aditionales when rating is generated */
	if (indirectQualification.Status >= IndirectQualificationsStatus.GENERATE_RATING) {
		headerOptions.push({
			ItemTypeDescription: 'Calificación',
			BackgroundColor: COLOR_SECONDARY_BLUE_LIGHT,
		})
		headerOptions.push({
			ItemTypeDescription: 'Clasificación',
			BackgroundColor: 'white',
		})
	}

	/**Add columns (action plant) aditionales when rating is publish */
	if (indirectQualification.Status >= IndirectQualificationsStatus.PUBLISH_RATINGS && !isUserNotActiveDirectory) {
		headerOptions.push({
			ItemTypeDescription: 'Plan de acciones',
			BackgroundColor: 'white',
		})
	} else if (
		indirectQualification.Status >= IndirectQualificationsStatus.PUBLISH_RATINGS &&
		isUserNotActiveDirectory
	) {
		headerOptions.push({
			ItemTypeDescription: 'Plan de acciones',
			BackgroundColor: 'white',
		})
	}
	return headerOptions
}

export const onGetHeaderTableEvaluatorsByItemType = (evaluators, isUserNotActiveDirectory, indirectQualification) => {
	let mappedHeadersBySection = onGetHeaderEvaluatorsTableByItemType(
		evaluators,
		isUserNotActiveDirectory,
		indirectQualification
	)
	let tableConfiguration = [
		{
			title: '',
		},
		{
			title: '-',
		},
		{
			title: 'Estatus',
		},
		{
			title: isUserNotActiveDirectory ? '' : 'Departamento responsable',
		},
		{
			title: isUserNotActiveDirectory ? '' : 'Socio evaluador',
		},
		{
			title: 'Comentario',
		},
	]
	mappedHeadersBySection.forEach((column) => {
		tableConfiguration.push({
			title: column.ItemTypeDescription,
			dataSource: column.Name,
			backgroundColor: column.BackgroundColor,
			warningDescription: column.DescriptionWarningItemType,
			isVisibleWarning: column.DescriptionWarningItemType
				? indirectQualification.EvaluationType === IndirectQualificationType.CARRIER
				: false,
		})
	})
	return tableConfiguration
}
