/**Import react section */
import React from "react";
import { useSelector } from 'react-redux'
import moment from 'moment';
import { Translate } from "react-localize-redux";
import { withRouter } from "react-router-dom";
/**Import material UI Section*/
import { Typography, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
/**import helpers section */
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import RateReviewTwoToneIcon from '@material-ui/icons/RateReviewTwoTone';
/**Import components sections */
import FloatingButton from '../FloatingButton';
import DialogOperationContainer from '../../../common/DialogOperations/DialogOperationContainer';
import { IndirectQualificationsSupplierStatus, Evaluationclassification } from '../../../../store/helpers/AppConstants'
import {
    backgroundColorTableSupplierStatus, descriptionSupplierStatus, descriptionIndirectEvaluationTypeTranslate,
    canBeEditSupplier
} from '../../../../store/helpers/IndirectQualificationsHelpers'
import DialogRatingInformationContainer from '../informationRating/DialogRatingInformationContainer'
/**import helper section */
import {
    COLOR_STATUS_SUCCESS, COLOR_STATUS_WARNING, COLOR_STATUS_DANGER
} from '../../../../store/helpers/StatusColorConstants'
/**Custom hooks index */
import { useIndirectQualificationCustomHookShow } from '../../customHooks/UseCustomHookShow'
import { converListToArray } from '../../../../store/helpers/ListHelper'

const ShowActionPlantViewHeader = (props) => {
    /**String props section */
    const { classes } = props
    /**Arrays props section */
    // const { } = props
    /**Functions props section */
    const { onConfirmPassword } = props
    /**Objects props section */
    const { translate, supplier,  } = props
    /**Render hooks section */
    const userRolesByCompany = useSelector((state) => state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : [])
    const { user } = useIndirectQualificationCustomHookShow()
    let rating = (supplier ? (supplier.Rating ? supplier.Rating : null) : null)
    let isUserCreator = (rating && (user && (user.profile.username === rating.CreatedBy)))

    const { DialogRatingInformation, onOpenAndCloseDialogInformation } = DialogRatingInformationContainer({})



    let availableOperationsInStatusGenerateActionPlant = []
    let cancepOption = {
        title: <Translate id="indirectqualifications.operationsAvailable.messageActionPlanToCancelTitle" />,
        onClick: () => onConfirmPassword(supplier, IndirectQualificationsSupplierStatus.CANCEL),
        isIconMaterial: true,
        description: <Translate id="indirectqualifications.operationsAvailable.messageActionPlanToCancelResponse" />
    }

    if (supplier && supplier.Status === IndirectQualificationsSupplierStatus.GENERATE_ACTION_PLANS && isUserCreator) {
        availableOperationsInStatusGenerateActionPlant.push({
            title: <Translate id="indirectqualifications.operationsAvailable.messageActionPlanToSupplierTitle" />,
            onClick: () => onConfirmPassword(supplier, IndirectQualificationsSupplierStatus.SEND_ACTION_PLANT_TO_SUPPLIER),
            isIconMaterial: true,
            description: <Translate id="indirectqualifications.operationsAvailable.messageActionPlanToSupplier" />
        })
        availableOperationsInStatusGenerateActionPlant.push(cancepOption)
    }

    if (supplier && supplier.Status === IndirectQualificationsSupplierStatus.SEND_ACTION_PLANT_TO_SUPPLIER && canBeEditSupplier(supplier, userRolesByCompany, user)) {
        availableOperationsInStatusGenerateActionPlant.push({
            title: <Translate id="indirectqualifications.operationsAvailable.messageActionPlanToResponseTitle" />,
            onClick: () => onConfirmPassword(supplier, IndirectQualificationsSupplierStatus.ACTION_PLANT_RESPONSE),
            isIconMaterial: true,
            description: <Translate id="indirectqualifications.operationsAvailable.messageActionPlanToResponse" />
        })
    }
    
    if (supplier && supplier.Status === IndirectQualificationsSupplierStatus.ACTION_PLANT_RESPONSE && isUserCreator) {
        availableOperationsInStatusGenerateActionPlant.push({
            title: <Translate id="indirectqualifications.operationsAvailable.messageActionPlanToCloseTitle" />,
            onClick: () => onConfirmPassword(supplier, IndirectQualificationsSupplierStatus.CLOSE),
            isIconMaterial: true,
            description: <Translate id="indirectqualifications.operationsAvailable.messageActionPlanToCloseResponse" />
        })
        availableOperationsInStatusGenerateActionPlant.push(cancepOption)
    }

    const onGetOperationsAvailable = () => {
        switch (supplier ? supplier.Status : 0) {
            case IndirectQualificationsSupplierStatus.GENERATE_ACTION_PLANS:
            case IndirectQualificationsSupplierStatus.SEND_ACTION_PLANT_TO_SUPPLIER:
            case IndirectQualificationsSupplierStatus.ACTION_PLANT_RESPONSE:
                return availableOperationsInStatusGenerateActionPlant
            default:
                return []
        }
    }

    const { DialogOperations, onOpenAndClose, } = DialogOperationContainer({
        options: onGetOperationsAvailable()
    })

    const GetColorEvaluation = (description) => {
        switch (description) {
            case Evaluationclassification.RELIABILITY_SUPPLIER:
            case Evaluationclassification.RELIABILITY_WHITH_OPPORTUNITY:
                return COLOR_STATUS_SUCCESS;
            case Evaluationclassification.REGURAR_WHITH_OPPORTUNITY:
                return COLOR_STATUS_WARNING;
            case Evaluationclassification.UNREALIABLE_SUPPLIER:
                return COLOR_STATUS_DANGER;
            default:
                return "";
        }
    }

    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <CardHeader
                    avatar={
                        <Tooltip title={descriptionSupplierStatus(supplier ? supplier.Status : "", translate)}>
                            <Avatar aria-label="recipe" className={classes.avatar} style={{ backgroundColor: backgroundColorTableSupplierStatus(supplier ? supplier.Status : "") }}>
                                <RateReviewTwoToneIcon />
                            </Avatar>
                        </Tooltip>
                    }
                    action={
                        <div>
                            <div className={classes.flex}>
                                {
                                    onGetOperationsAvailable().length !== 0 && <div className={classes.operationAvailable}>
                                        <FloatingButton
                                            onOpenAndCloseDialog={onOpenAndClose}
                                        />
                                    </div>
                                }
                                <div className={classes.displayAlignRight}>
                                    <Typography className={classes.indicadorText}>{translate("indirectqualifications.indicatorRating")}</Typography>
                                    <InfoIcon className={classes.inforIcon} onClick={()=>onOpenAndCloseDialogInformation()}/>
                                </div>
                            </div>
                        </div>
                    }
                    title={`Calificaciones de indirectos | Tipo de evaluación: ${descriptionIndirectEvaluationTypeTranslate(rating ? rating.EvaluationType : "", translate)}`}
                    subheader={
                        <div>
                            {supplier && <Typography>
                                {`Proveedor: ${supplier.Code} |  ${supplier.Name}`}
                            </Typography>}
                            <Typography className={classes.subtitleText}>
                            {`${translate("indirectqualifications.show.folio")} ${rating ? rating.FormatedFolio : ""} ${translate("indirectqualifications.show.evaluationDate")} ${rating ? moment(rating.EvaluationDate).format("LL") : ""}`}
                            </Typography>
                            {rating && rating.ShippingDate && <Typography className={classes.subtitleText}>
                                {`Fecha de envío: ${moment(rating.ShippingDate).format("L")}`}
                            </Typography>}

                            {supplier && supplier.CommentsToCancel && <Typography className={classes.subtitleText}>
                                {`Comentarios de cancelación: ${supplier.CommentsToCancel}`}
                            </Typography>}
                            {supplier && <Typography style={{ color: GetColorEvaluation(supplier.DescriptionEvaluation) }}>
                                {`Calificación final: ${supplier.TotalRating}`}
                            </Typography>}

                        </div>}
                />
            </Grid>

            <DialogOperations />
            <DialogRatingInformation/>

        </Grid>
    )
}

const styles = (theme) => ({
    flex: {
        justifyContent: "flex-end"
    },
    reportBtn: {
        marginLeft: "5px"
    },
    operationAvailable: {
        marginTop: "-6px"
    },
    subtitleText: {
        fontSize: "12px"
    },
    displayAlignRight: {
        display: "flex",
        justifyContent: "flex-end"
    },
    inforIcon:{
        marginLeft:"10px",
        cursor:"pointer",
        color:COLOR_STATUS_WARNING

    },
    indicadorText:{
        color:COLOR_STATUS_WARNING
    }
});

export default withRouter(
    withStyles(styles, { withTheme: true })(ShowActionPlantViewHeader)
);
