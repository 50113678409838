import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import {
	Typography,
	List,
	ListItemText,
	ListItem,
	ListItemSecondaryAction,
	Tooltip,
	Avatar,
	Chip,
	withStyles,
	Fab,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'
import FileIcon from '@material-ui/icons/AttachFile'
import RemoveIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Custom component import section */
import PanelNoteFiles from './PanelNoteFiles'
import WarningComponent from '../../common/WarningComponent'

/** Resources import section */
import { COLOR_CART_PORT } from '../../../store/helpers/StatusColorConstants'
import { ConsingmentNoteFilesType } from '../../../store/helpers/AppConstants'

const ConsignmentNoteAttachments = (props) => {
	const {
		title,
		attachments = [],
		onUploadFiles = () => console.warn('onUploadFiles is not defined!'),
		onSeeGetAcknowledgmentFileByName = () => console.warn('onSeeGetAcknowledgmentFileByName is not defined!'),
		onDownloadFiles = () => console.warn('onDownloadFiles is not defined'),
		downloadingFiles = false,
		onRemoveFiles = () => console.warn('onRemoveFiles is not defined'),
		classes,
		canBeAdd,
		isConsignmentNote,
		isUpdatingConsignmentNote,
		isSaving,
	} = props

	/** Defienes local state */
	const [openDialogOperation, setDialogOperation] = useState(false)

	const onDialogOperation = () => {
		setDialogOperation(!openDialogOperation)
	}

	const optionsDisplayed = attachments && attachments.length > 0

	return (
		<>
			<List>
				<ListItem divider style={{ backgroundColor: COLOR_CART_PORT }}>
					<ListItemText
						secondary={
							<Typography component='span' variant='body2' color='textPrimary'>
								<strong>{title}</strong>
							</Typography>
						}
					/>
					<ListItemSecondaryAction>
						{canBeAdd && (
							<Tooltip title={isConsignmentNote ? 'Agregar carta porte' : 'Agregar gastos adicionales'}>
								<Fab
									className={classes.marginIcon}
									size='small'
									color='primary'
									onClick={() => onDialogOperation()}
								>
									<AddIcon />
								</Fab>
							</Tooltip>
						)}
						{optionsDisplayed && (
							<Tooltip title={<Translate id='common.downloadReport' />}>
								<Fab
									className={classes.marginIcon}
									size='small'
									color='secondary'
									onClick={onDownloadFiles}
									disabled={downloadingFiles}
								>
									<DownloadIcon />
								</Fab>
							</Tooltip>
						)}
						{canBeAdd && optionsDisplayed && (
							<Tooltip title={<Translate id='consignmentNote.show.removeAttachments' />}>
								<Fab
									className={classes.marginIcon}
									size='small'
									color='primary'
									onClick={onRemoveFiles}
								>
									<RemoveIcon />
								</Fab>
							</Tooltip>
						)}
					</ListItemSecondaryAction>
				</ListItem>
				{attachments.length === 0 && (
					<ListItem divider>
						<ListItemText
							secondary={
								<Typography component='span' variant='body2' color='textPrimary'>
									<Translate id='consignmentNote.show.addFiles' />
								</Typography>
							}
						/>
					</ListItem>
				)}
				{isUpdatingConsignmentNote && isConsignmentNote && attachments.length !== 0 && (
					<ListItem>
						<ListItemText
							secondary={
								<WarningComponent
									title={
										<strong>
											<Translate id='consignmentNote.show.warmingRequest' />
										</strong>
									}
									message={<Translate id='consignmentNote.show.messageWarmingRequest' />}
									warningError={true}
								/>
							}
						/>
					</ListItem>
				)}
				<div className={classes.attachmentsContainer}>
					{attachments.map((evidence, index) => {
						const extension = evidence.split('.').pop().toLowerCase()
						let canBeDownload = extension !== 'xml'
						return (
							<Chip
								key={index}
								avatar={
									<Avatar size='small'>
										<FileIcon />
									</Avatar>
								}
								label={<Typography color='primary'>{evidence}</Typography>}
								style={{ margin: '5px' }}
								onClick={
									canBeDownload
										? () =>
												onSeeGetAcknowledgmentFileByName(
													'',
													'',
													'',
													evidence,
													isConsignmentNote
														? ConsingmentNoteFilesType.CONSIGNMENT_NOTE
														: ConsingmentNoteFilesType.ADDITIONAL_EXPENSES
												)
										: null
								}
							/>
						)
					})}
				</div>
			</List>

			{openDialogOperation && (
				<PanelNoteFiles
					title={
						isConsignmentNote ? (
							<Translate id='consignmentNote.show.title' />
						) : (
							<Translate id='consignmentNote.show.additionalExpenses' />
						)
					}
					message={
						isConsignmentNote
							? 'Cargar archivo pdf y xml de carta porte'
							: 'Cargar archivo pdf y xml de gastos adicionales'
					}
					text={isConsignmentNote ? 'Seleccionar PDF y XML carta porte' : 'Seleccionar PDF y XML'}
					open={openDialogOperation}
					onClose={onDialogOperation}
					attachments={attachments}
					onUploadFiles={onUploadFiles}
					isConsignmentNote={isConsignmentNote}
					isSaving={isSaving}
				/>
			)}
		</>
	)
}

ConsignmentNoteAttachments.propTypes = {
	title: PropTypes.node,
	attachments: PropTypes.array.isRequired,
	onUploadFiles: PropTypes.func.isRequired,
	onDownloadFiles: PropTypes.func.isRequired,
	downloadingFiles: PropTypes.bool.isRequired,
	onRemoveFiles: PropTypes.func.isRequired,
}

const styles = (theme) => ({
	attachmentsContainer: {
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: 4,
		paddingLeft: theme.spacing.unit,
	},
	optionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: 80,
	},
	marginIcon: {
		marginLeft: '5px',
	},
})

export default withStyles(styles, { withTheme: true })(ConsignmentNoteAttachments)
