/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser,
    getAuthorizedRequestConfigurationUserParameters } from '../helpers/SessionHelper';


export const START_LOADING_RELEASE_CALENDARS = "START_LOADING_RELEASE_CALENDARS";
export const SET_RELEASE_CALENDARS_LIST = "SET_RELEASE_CALENDARS_LIST";
export const CHANGE_RELEASE_CALENDARS_SORT_CRITERIA = "CHANGE_RELEASE_CALENDARS_SORT_CRITERIA";
export const CHANGE_RELEASE_CALENDARS_PAGINATION = "CHANGE_RELEASE_CALENDARS_PAGINATION";
export const CHANGE_RELEASE_CALENDARS_QUERY = "CHANGE_RELEASE_CALENDARS_QUERY";
export const SET_RELEASE_CALENDARS_COUNT = "SET_RELEASE_CALENDARS_COUNT";

export const IS_SAVING = "IS_SAVING";
export const IS_LOADING = "IS_LOADING";
export const SET_RELEASE_CALENDAR_LOCK = "SET_RELEASE_CALENDAR_LOCK";
export const REMOVE_RELEASE_CALENDAR_FROM_LIST = "REMOVE_RELEASE_CALENDAR_FROM_LIST";

/**
 * Action start loading release calendars list 
 * @param {*} isLoading 
 */
function isLoadingReleaseCalendars(isLoading) {
    return {
        type: START_LOADING_RELEASE_CALENDARS,
        isLoading: isLoading
    }
}

/**
 * Action to set release calendars list 
 * @param {*} calendarsLocks 
 */
function setReleaseCalendarsList(calendarsLocks) {
    return {
        type: SET_RELEASE_CALENDARS_LIST,
        calendarsLocks: calendarsLocks
    }
}

/**
 * Action reducer to set the release calendars counter
 *
 * @param {*} count
 * @returns
 */
function setReleaseCalendarListCount(count) {
    return {
        type: SET_RELEASE_CALENDARS_COUNT,
        count
    }
}

/**
 * Action to is saving release calendar lock
 * @param {*} isSaving 
 */
function isSaving(isSaving) {
    return {
        type: IS_SAVING,
        isSaving: isSaving
    }
}

/**
 * Action to is loaidng  release calendar lock
 * @param {*} isLoading 
 */
function isLoading(isLoading) {
    return {
        type: IS_LOADING,
        isLoading: isLoading
    }
}


/**
 * Action to set  release calendar lock
 * @param {*} rawMaterial 
 */
function setReleaseCalenarLock(calendarLock) {
    return {
        type: SET_RELEASE_CALENDAR_LOCK,
        calendarLock: calendarLock
    }
}

/**
 * Action to remove a relase calendar in list 
 *
 * @param {*} memoItemToken
 * @param {*} supplierToken
 * @returns
 */
function calendarRemovedFromList(calendaroken) {
    return {
        type: REMOVE_RELEASE_CALENDAR_FROM_LIST,
        calendaroken,
    }
}

/**
 * Get all release calendars lock list by company from backend 
 * DONE
 */
export function loadReleaseCanledarsLockFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
    return function (dispatch, getState) {
        dispatch(isLoadingReleaseCalendars(true));

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/releasecalendarlock/filters`;
        let data = {
            CompanyToken: selectedCompany.get("token"),
            SortBy: orderBy,
            SortAscending: sortAscending,
            Query: query,
            Page: `${page + 1}`,
            RowsPerPage: rowsPerPage,
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setReleaseCalendarsList(serverResponse.data))
                }
            })
            .catch((error) => {
                dispatch(isLoadingReleaseCalendars(false));
                console.error("Error getting the release calendars lock by company list", error.response)
            })
    }
}


/**
 * Get release clendar's lock count
 * @export
 * @returns
 */
export function getReleaseCalendarLockCount() {
    return function (dispatch, getState) {

        let actualCounter = getState().releaseCalendarLock ? getState().releaseCalendarLock.get('releaseCalendarLocksCount') : null;
        if (actualCounter) {
            return;
        }

        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);

        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/releasecalendarlock/count`;
        let data ={
            CompanyToken: selectedCompany.get("token"),
        }
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setReleaseCalendarListCount(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error getting the release calendar lock count", error.response)
            })
    }
}

/**
 * Action to create release calendar lock from backend
 */
export function createReleaseCalendarLockFromBackEnd(calendarLock) {
    return (dispatch, getState) => {

        dispatch(isSaving(true))

        let user = getState().oidc.user;
        let selectedCompany = getState().profile.get("selectedCompany");
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let data = {
            CompanyToken: selectedCompany.get('token'),
            CompanyIdentifier: selectedCompany.get("get_company_identifier"),

            Message: calendarLock ? calendarLock.message : "",
            NumberRamps: calendarLock ? calendarLock.number_ramps : "",
            IsAllDays: calendarLock ? calendarLock.all_days : "",
            ByDate: calendarLock ? calendarLock.by_date : "",
            ByPeriod: calendarLock ? calendarLock.by_period : "",
            IsMonday: calendarLock ? calendarLock.is_monday : "",
            IsTuesday: calendarLock ? calendarLock.is_tuesday : "",
            IsWednesday: calendarLock ? calendarLock.is_wednesday : "",
            IsThursday: calendarLock ? calendarLock.is_thursday : "",
            IsFriday: calendarLock ? calendarLock.is_friday : "",
            IsSaturday: calendarLock ? calendarLock.is_saturday : "",
            TimeHourLockStart: calendarLock ? calendarLock.time_hour_lock_start ? calendarLock.time_hour_lock_start.format("DD/MM/YYYY") : "" : "",
            TimeHourLockEnd: calendarLock ? calendarLock.time_hour_lock_end ? calendarLock.time_hour_lock_end.format("DD/MM/YYYY") : "": "",
            TimeHourStart: calendarLock ? calendarLock.time_hour_start : "",
            TimeHourEnd: calendarLock ? calendarLock.time_hour_end : "",
            CreatedBy: user.profile.name,
        }
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/releasecalendarlock`;
        return axios.post(endPoint, data, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                    return Promise.resolve(serverResponse.data)
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error adding a release calendar lock from company", error.response)
                return Promise.reject(error.response);
            })
    }
}


/**
 * Get and set the ReleaseCalendarLock in the estate from read from backend
 * @param {*} token 
 */
export function loadReleaseCalendarLockFromBackEnd(token) {
    return function (dispatch, getState) {
        dispatch(isLoading(true))

        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/releasecalendarlock/calendarlock/${token}`;

        return axios.get(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setReleaseCalenarLock(serverResponse.data))
                }
            })
            .catch((error) => {
                console.error("Error to load release calendar lock", error.response)
                dispatch(isLoading(false))
                return Promise.reject();
            })
    }
}

/**
 * Remove a release calendar lock from backend
 * @param {*} calendarLockToken 
 */
export function removeReleaseCalendarLock(calendarLockToken) {
    return (dispatch, getState) => {
        dispatch(isSaving(true));

        let user = getState().oidc.user;
        let configuration = getAuthorizedRequestConfigurationUser(user);

        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/releasecalendarlock/${calendarLockToken}`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(isSaving(false))
                }
                else{
                    dispatch(isSaving(false));
                    return Promise.reject(serverResponse);
                }
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error removing a release calendar lock", error.response)
                return Promise.reject(error.response);
            })
    }
}

/**
 * Function to remove calensars massivament
 * @param {*} selectedTokens 
 */
export function removeReleaseCalendarsMassive(selectedTokens) {
    return (dispatch, getState) => {

        dispatch(isSaving(true));
        let user = getState().oidc.user;
        let data = {
            SelectedCalendarsTokens: selectedTokens,
        }
        let configuration = getAuthorizedRequestConfigurationUserParameters(user, data);

        let endPoint = `${process.env.REACT_APP_SIP_PLANNING}/releasecalendarlock/deleteMultipleLocks`;
        return axios.delete(endPoint, configuration)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    selectedTokens.forEach(releaseCalendarToken => {
                        dispatch(calendarRemovedFromList(releaseCalendarToken))
                    });
                    dispatch(isSaving(false));
                    return Promise.resolve()
                }
            })
            .catch((error) => {
                dispatch(isSaving(false));
                console.error("Error removing a multiple releases calendar items", error.response)
                return Promise.reject();
            })
    }
}


