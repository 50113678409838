import React, { Component } from 'react'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import Switch from '@material-ui/core/Switch'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import CompanyIcon from '@material-ui/icons/DomainTwoTone'
import AddAllRolesIcon from '@material-ui/icons/PlaylistAddCheckOutlined'

/** Actions and helpers imports section */
import { getDescriptionRole } from '../../../store/helpers/RolesHelper'

/** Components imports section */
import LoadingPage from '../../common/LoadingPage'

/**
 * User card
 *
 * @class UserCard
 * @extends {Component}
 */
class CompaniesCard extends Component {
	/**
	 * Get the secondary action button for granted companies
	 *
	 * @returns
	 * @memberof CompaniesCard
	 */
	getSecondaryActionForGrantedCompany(company) {
		const { grantingAccessToCompany } = this.props
		let disabled = grantingAccessToCompany !== null
		return (
			<React.Fragment>
				<Button
					variant='outlined'
					size='small'
					disabled={disabled}
					onClick={() => {
						this.props.onRemoveAccessRequest(company)
					}}
				>
					<Translate id='companies.removeUserAccess' />
				</Button>
			</React.Fragment>
		)
	}

	/**
	 * Get the secondary action button for non granted companies
	 *
	 * @returns
	 * @memberof CompaniesCard
	 */
	getSecondaryActionForNonGrantedCompany(company) {
		const { grantingAccessToCompany } = this.props
		let disabled = grantingAccessToCompany !== null
		return (
			<React.Fragment>
				<Button
					variant='outlined'
					size='small'
					color='secondary'
					disabled={disabled}
					onClick={() => {
						this.props.onGrantAccessRequest(company)
					}}
				>
					<Translate id='companies.grantUserAccess' />
				</Button>
			</React.Fragment>
		)
	}

	/**
	 * Toggle the role assigned
	 *
	 * @param {*} assigned
	 * @param {*} company
	 * @param {*} role
	 * @memberof CompaniesCard
	 */
	toggleRoleAssignent(assigned, company, role) {
		const { internalUser } = this.props
		return this.props.toggleRoleAssignent(internalUser, assigned, company, role)
	}

	/**
	 * Get roles
	 *
	 * @returns
	 * @memberof CompaniesCard
	 */
	getRolesList(company) {
		const { companies, rolesForCompanies, isLoadingCompaniesRoles, isLoadingInternalUserRoles, internalUserRoles } =
			this.props
		if (isLoadingCompaniesRoles === false && isLoadingInternalUserRoles === false) {
			let selectedCompany = companies.find((cmp) => cmp.token === company.token)
			if (selectedCompany) {
				let companyRoles = rolesForCompanies.filter((role) => role.company_token === company.token)
				return (
					<List dense>
						{companyRoles.map((role) => {
							let assignedRole = internalUserRoles.find(
								(internalUserRole) =>
									internalUserRole.id === role.id && internalUserRole.company_token === company.token
							)
							let roleDescription = getDescriptionRole(role.name)
							return (
								<ListItem key={role.id} divider>
									{roleDescription && (
										<ListItemText
											primary={<Translate id={`rolesDescription.${roleDescription}`} />}
										/>
									)}
									{!roleDescription && <ListItemText primary={role.name} />}
									<ListItemSecondaryAction>
										<Switch
											onChange={(event) => {
												this.toggleRoleAssignent(event.target.checked, selectedCompany, role)
											}}
											checked={assignedRole ? true : false}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							)
						})}
					</List>
				)
			}
		} else {
			return <LoadingPage />
		}
	}

	/**
	 * Get the companies list
	 *
	 * @returns
	 * @memberof CompaniesCard
	 */
	getCompaniesList() {
		const { companies, grantedCompanies, classes } = this.props
		if (companies && grantedCompanies) {
			return this.props.companies.map((company) => {
				let grantedCompany = grantedCompanies.find((gntdCompany) => {
					return gntdCompany.token === company.token
				})
				let headerClass = grantedCompany ? classes.companyGrantedHeader : null
				return (
					<ExpansionPanel key={company.token}>
						<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
							<Typography className={headerClass}>{company.commercial_name}</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<div style={{ flexBasis: '100%' }}>{this.getRolesList(company)}</div>
						</ExpansionPanelDetails>
						<ExpansionPanelActions>
							{grantedCompany
								? this.getSecondaryActionForGrantedCompany(company)
								: this.getSecondaryActionForNonGrantedCompany(company)}
						</ExpansionPanelActions>
					</ExpansionPanel>
				)
			})
		} else {
			return <LoadingPage />
		}
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof CompaniesCard
	 */
	render() {
		const { classes } = this.props
		return (
			<Paper>
				<Card className={classes.card}>
					<CardHeader
						avatar={
							<Avatar className={classes.companyAvatar}>
								<CompanyIcon />
							</Avatar>
						}
						action={
							<div className={classes.secondaryToolbar}>
								{this.props.grantingAccessToCompany && (
									<Typography variant='caption' color='secondary' className={classes.waitMessage}>
										<Translate id='common.waitMessage' />{' '}
									</Typography>
								)}
								<Tooltip title={<Translate id='companies.addRolesToAllCompanies' />}>
									<IconButton onClick={this.props.addRolesInCompanies} color='primary'>
										<AddAllRolesIcon color='inherit' />
									</IconButton>
								</Tooltip>
							</div>
						}
						title={
							<Typography variant='subtitle2' gutterBottom>
								<Translate id='companies.companies' />
							</Typography>
						}
					/>
					<CardContent>{this.getCompaniesList()}</CardContent>
					<CardActions className={classes.actions} disableActionSpacing></CardActions>
				</Card>
			</Paper>
		)
	}
}

export default CompaniesCard
