import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import { Translate, getTranslate} from "react-localize-redux";

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout';
import FilesLinks from '../common/FilesLinks';
import DataTable from '../common/DataTable';
import LoadingPage from '../common/LoadingPage';
import Toaster from '../common/Toaster';
import MaterialItemDialog from './components/MaterialItemDialog';
import SendRequestDialog from './components/SendRequestDialog';

/** Material-UI imports section */
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import MaterialIcon from '@material-ui/icons/FileCopyTwoTone';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Badge from '@material-ui/core/Badge';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';

import {
    getMaterialDeliveryInformationDataFromBackend, updateDetailPartnumberPlaneName,
    deleteDetailPlaneName, IS_LOADING_MATERIAL_DELIVERY,
    addSupplierToMaterialDeliveryItem, removeSupplierFromMaterialDeliveryItem,
    SET_MATERIAL_ITEM_SELECTED, getSelectedMaterialDeliveryItem,
    onAddSampleRequestSupplier, onRemoveSampleRequestSupplier,
    sendQuotesRequestFromMaterialDelivery, onAddComment, onAddFiles, onRemoveFiles,
    onAddRMISampleRequestSupplier
} from '../../store/materialDelivery/MaterialDeliveryAction';


/**
 * Component to create material delivery from SIT
 */
class Create extends Component {

    /**
     * Create an instance material
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            folioDelivery: "",
            showToaster: false,
            toasterMessage: null,
            toasterVariant: "",
            showDeletePlaneName: false,
            // Material delivery item options
            showMaterialItemDialog: false,
            showMaterialSendRequests: false,
        }
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        this.props.startMateriaDelivery();
    }

    /**
     * Funcion on update folio to get material delivery
     * @param {*} folio 
     */
    onUpdateFolioMaterialDelivery(folio) {
        this.setState({
            folioDelivery: folio
        })
    }

    /**
     * on key Down to get folio
     * @param {*} event 
     */
    onKeyDown(event) {
        if (event.key === "Enter") {
            this.getInformationMaterialDelivery();
        }
    }


    /**
     * Get action to get information material delivery 
     */
    getInformationMaterialDelivery() {
        this.props.getMaterialDeliveryInformationData(this.state.folioDelivery)
            .then(() => {
                this.setState({
                    showAddWorker: false,
                    showToView: true,
                    showToaster: false
                })
            }).catch(() => {
                this.showErrorToaster("common.errorToAdd");
            });
    }

    /**
     * On material delivery item clicked open the dialog
     *
     * @param {*} item
     * @memberof Create
     */
    onMaterialDeliveryItemClick(item) {
        const { materialDelivery } = this.props;
        let materialItemSelected = materialDelivery.details.find((materialItem) => {
            return materialItem.token === item.token
        })
        this.props.setMaterialDeliveryItemSelected(item.token);
        this.setState({
            materialItemSelected,
            showMaterialItemDialog: true
        })
    }

    /**
     * onCloseMaterialDeliveryItemDialog
     *
     * @memberof Create
     */
    onCloseMaterialDeliveryItemDialog() {
        this.setState({
            showMaterialItemDialog: false,
            materialItemSelected: null
        })
    }

    /**
     * Method to show succes toaster 
     * with custom message or default "saveCorrectly"
     *
     * @param {string} [idTranslation="common.saveCorrectly"]
     * @memberof Create
     */
    showSuccessToaster(idTranslation = "common.saveCorrectly") {
        this.setState({
            showToaster: true,
            toasterVariant: "success",
            toasterMessage: <Translate id={idTranslation} />,
        })
    }

    /**
     * Method to show succes toaster 
     * with custom message or default "errorToSave"
     *
     * @param {string} [idTranslation="common.errorToSave"]
     * @memberof Create
     */
    showErrorToaster(idTranslation = "common.errorToSave") {
        this.setState({
            showToaster: true,
            toasterVariant: "error",
            toasterMessage: <Translate id={idTranslation} />,
        })
    }

    /**
     * Get the card content
     *
     * @memberof Index
     */
    getCardContent() {
        if (this.props.isLoadingMaterialDelivery === true) {
            return (
                <LoadingPage />
            )
        }
        else if (this.props.materialDelivery !== null) {
            return (
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <InformationMaterialCard
                            {...this.props}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MaterialDeliveryDetails
                            onMaterialDeliveryItemClick={this.onMaterialDeliveryItemClick}
                            {...this.props}
                        />
                    </Grid>
                </Grid>
            )
        }
    }

    /**
     * On item plot selected
     *
     * @param {*} plotSelected
     * @memberof Create
     */
    onPlotSelected(item, plotSelected) {
        this.props.updateDetailPlane(item.token, plotSelected)
            .then(() => {

            })
            .catch(() => {
                console.log("onPlotSelected::failed")
            })
    }

    /**
     * onSupplierSelected
     *
     * @param {*} item
     * @param {*} supplier
     * @memberof Create
     */
    onSupplierSelected(item, supplier) {
        this.props.addSupplierToMaterialDeliveryItem(item, supplier);
    }

    /**
     * On remove supplier
     *
     * @param {*} item
     * @param {*} supplierToken
     * @memberof Create
     */
    onRemoveSupplier(item, supplierToken) {
        this.props.removeSupplierFromMaterialDeliveryItem(item, supplierToken);
    }

    /**
   * On add sample request to supplier
   */
    onAddSampleRequestSupplier(item, quote) {
        this.props.onAddSampleRequestSupplier(item, quote)
    }

    /**
     * On remove sample request to supplier
     * @param {*} item 
     * @param {*} supplierToken 
     */
    onRemoveSampleRequestSupplier(item, supplierToken) {
        this.props.onRemoveSampleRequestSupplier(item, supplierToken);
    }

    /**
     * on add rmi requiered 
     * @param {*} item 
     * @param {*} quote 
     * @param {*} rmi 
     */
    onAddRMISampleRequestSupplier(item, quote, rmi){
        this.props.onAddRMISampleRequestSupplier(item, quote, rmi)
    }

    /**
     * on Send requests on click material delivery
     */
    onSendRequestClick() {
        this.setState({
            showMaterialSendRequests: true
        })
    }

    /**
  * onCloseSendRequestDialog
  *
  * @memberof Create
  */
    onCloseSendRequestDialog() {
        this.setState({
            showMaterialSendRequests: false
        })
    }

    /**
 * Get action to send request memo 
 */
    sendQuotesRequestFromMaterialDelivery() {
        this.props.sendQuotesRequestFromMaterialDelivery()
            .then(() => {
                this.setState({
                    showAddWorker: false,
                    showToView: true,
                    showToaster: false
                })
                this.props.history.push(`/materialdelivery/${this.props.materialDelivery.token}`);
            }).catch(() => {
                this.showErrorToaster();
            });
    }

    /**
     * Render component material delivery
     */
    render() {
        const { classes } = this.props;
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <MaterialIcon />
                        </Avatar>
                    }
                    action={
                        <Tooltip title={<Translate id="materialDelivery.sendQuotes" />}>
                            <IconButton color="primary" value onClick={() => { this.onSendRequestClick() }} >
                                <SendIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    title={<Typography variant="button"><Translate id="materialDelivery.materialDelivery"/> </Typography>}
                />
                <CardContent>
                    <Grid container spacing={24}>
                        <List>
                            <ListItem>
                                <ListItemText primary={
                                    <React.Fragment>
                                        <TextField fullWidth autoFocus
                                            className={classes.textField}
                                            label={<Translate id="materialDelivery.folioMaterial" />}
                                            onKeyDown={this.onKeyDown}
                                            onChange={(event) => { this.onUpdateFolioMaterialDelivery(event.target.value) }}
                                        /><br /><br />
                                    </React.Fragment>
                                }
                                    secondary={
                                        <React.Fragment>
                                            {<Translate id="materialDelivery.messageToAddMaterial"/>}
                                        </React.Fragment>
                                    } />
                            </ListItem>
                        </List>
                    </Grid>
                    {this.getCardContent()}
                </CardContent>

                <Toaster
                    message={this.state.toasterMessage}
                    open={this.state.showToaster}
                    variant={this.state.toasterVariant}
                    onClose={() => { this.setState({ showToaster: false }) }}
                />
                {
                    this.props.selectedMaterialDeliveryItem &&
                    <MaterialItemDialog
                        item={this.props.selectedMaterialDeliveryItem}
                        show={this.state.showMaterialItemDialog}
                        onClose={this.onCloseMaterialDeliveryItemDialog}
                        onPlotSelected={this.onPlotSelected}
                        onSupplierSelected={this.onSupplierSelected}
                        onRemoveSupplier={this.onRemoveSupplier}
                        onAddSampleRequestSupplier={this.onAddSampleRequestSupplier}
                        onAddRMISampleRequestSupplier={this.onAddRMISampleRequestSupplier}
                        onRemoveSampleRequestSupplier={this.onRemoveSampleRequestSupplier}
                        onAddComment={this.props.onAddComment}
                        uploadFiles={this.props.onAddFiles}
                        removeFiles={this.props.onRemoveFiles}
                        dropzoneText={this.props.translate('dropzone.dropzoneBaseText')}
                    />
                }

                { this.state.showMaterialSendRequests &&
                    <SendRequestDialog
                        show={this.state.showMaterialSendRequests}
                        onClose={this.onCloseSendRequestDialog}
                        sendQuotesRequestFromMaterialDelivery={this.sendQuotesRequestFromMaterialDelivery}
                        isSaving={this.props.isSaving}
                    />
                }

            </Card>
        )
    }
}

/**
 * Information InformationMaterialCard
 *
 * @class InformationMaterialCard
 * @extends {Component}
 */
class InformationMaterialCard extends Component {
    render() {
        return (
            <Paper>
                <List>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="memos.project"/></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.materialDelivery.project_name}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="materialDelivery.deliveryDate"/></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.materialDelivery.mem_com_date}
                                </React.Fragment>
                            } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Typography variant="subtitle1" gutterBottom><Translate id="memos.developerComments"/></Typography>}
                            secondary={
                                <React.Fragment>
                                    {this.props.materialDelivery.developer_comments}
                                </React.Fragment>
                            } />
                    </ListItem>
                </List>
            </Paper>
        );
    }
}


class MaterialDeliveryDetails extends Component {
    /**
     * Create an instance of Internal user
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            showspecPlano: false,
            planeToChange: null,
            revisionToChange: null,
            selectedPlaneValue: null,
            selectedDetailPlaneToken: null,
            showDeletePlaneName: false,
        }
    }


    onDeleteDetailPlane(item) {
        this.setState({
            showDeletePlaneName: true,
            revisionToChange: item.revise,
            planeToChange: item.plot_name,
            selectedDetailPlaneToken: item.token,
        });
    }
    /**
     * On render cell item custom
     * @param {*} dataSource 
     * @param {*} item 
     */
    onRenderCellItem(dataSource, item) {

        const { classes } = this.props;

        if (dataSource === "material") {
            return (<div>
                <List>
                    <ListItem>
                        <ListItemText primary={<React.Fragment>
                            <Typography variant="caption">
                                {item.part_number}{" | "}{item.part_description}<br />
                                {<Translate id="common.schema"/>}{": "}{item.plano_number}
                                {" | "}{<Translate id="officialNotices.revision"/>}{": "}{item.review}
                            </Typography>
                        </React.Fragment>}
                        />
                    </ListItem>
                </List>
            </div>)
        }
        else if (dataSource === "plot_name") {
            return (
                <FilesLinks
                    item={item}
                    hasPlot
                    companyToken={this.props.materialDelivery.company_token}
                    kind={1}
                />
            )
        }
        else if (dataSource === "options") {
            return (
                <IconButton
                    data-cy="btn-edit"
                    className={classes.edit}
                    onClick={() => { this.props.onMaterialDeliveryItemClick(item) }}>
                    <SettingsIcon />
                </IconButton>
            )
        }
        else if (dataSource === "suppliers") {
            if (item.quotes && item.quotes.length) {
                if (item.quotes.length === 1) {
                    return (
                        <Typography noWrap variant="overline" gutterBottom>{<React.Fragment>{item.quotes[0].supplier_code} | {item.quotes[0].supplier_name} </React.Fragment>}</Typography>
                    )
                } else {
                    return (
                        <Badge color="primary" badgeContent={`+${item.quotes.length - 1}`} className={classes.supplierBadge}>
                            <Typography noWrap variant="overline" gutterBottom>{<React.Fragment>{item.quotes[0].supplier_code} | {item.quotes[0].supplier_name} </React.Fragment>}</Typography>
                        </Badge>
                    )
                }
            } else {
                return (
                    <span>{<Translate id="common.withoutSuppliers"/>}</span>
                )
            }
        }
        else if(dataSource === "comments"){
            return(<div style={{width:"280px"}}>
                    {item.comments}
            </div>)    
        }
    }

    /**
     * Handle change plane 
     */
    handleChange = event => {
        this.setState({ selectedPlaneValue: event.target.value });
    };

    /**
     * Function from open spec planos
     */
    getSppectList() {
        const { classes } = this.props;
        if (this.props.specPlanos) {
            return (
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend"></FormLabel>
                    <RadioGroup
                        aria-label="Gender"
                        name="gender1"
                        className={classes.group}
                        value={this.state.value}
                        onChange={this.handleChange}
                    >
                        {this.props.specPlanos.map((spect) => <FormControlLabel value={spect} control={<Radio />} label={spect} />
                        )}
                    </RadioGroup>
                </FormControl>
            )
        }
        else {
            return (<LoadingPage />)
        }
    }
    onDeleteConfirm() {
        this.props.deleteDetailPlaneName(this.state.selectedDetailPlaneToken, this.state.planeToChange)
            .then(() => {
                this.setState({
                    showDeletePlaneName: false,
                })
            }).catch(() => {
                this.setState({
                    showToaster: true,
                    toasterMessage: <Translate id="common.errorToSave" />,
                    toasterVariant: "error",
                })
            });
    }
    /**
     * Render
     *
     * @returns
     * @memberof MaterialDetails
     */
    render() {
        return (
            <Paper>
                <DataTable
                    data={this.props.materialDelivery.details}
                    configuration={TableConfiguration}
                    onRenderCellItem={this.onRenderCellItem}
                />
            </Paper>
        )
    }
}

const TableConfiguration = {
    columns: [{
        header: <Translate id="memos.material" />,
        dataSource: 'material'
    }, {
        header: <Translate id="memos.assignedTo" />,
        dataSource: 'assigned_development_name'
    }, {
        header: <Translate id="memos.requiredSamples" />,
        dataSource: 'sample_number'
    }, {
        header: <Translate id="memos.plotNumber" />,
        dataSource: 'plot_name'
    }, {
        header: <Translate id="common.comments" />,
        dataSource: 'comments'
    }, {
        header: <Translate id="menu.suppliers" />,
        dataSource: 'suppliers'
    }, {
        header: '',
        dataSource: 'options'
    }]
}


/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state 
 */
function mapStateToProps(state) {
    let materialDelivery = state.materialDelivery.get("materialDelivery");
    return {
        isLoadingMaterialDelivery: state.materialDelivery.get('isLoadingMaterialDelivery'),
        materialDelivery: materialDelivery ? materialDelivery.toJS() : null,
        selectedMaterialDeliveryItem: getSelectedMaterialDeliveryItem(state),
        specPlanos: state.partNumbers.get("specPlanos") ? state.partNumbers.get('specPlanos') : null,
        isUploading: state.materialDelivery.get('isUploading'),
        isSaving: state.materialDelivery.get('isSaving'),
        translate: getTranslate(state.localize)
    }
}

/**
 * Defines the actions injectes to the component 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => {
    return {
        getMaterialDeliveryInformationData: (folio) => {
            return dispatch(getMaterialDeliveryInformationDataFromBackend(folio));
        },
        startMateriaDelivery: () => {
            dispatch({
                type: IS_LOADING_MATERIAL_DELIVERY,
                isLoadingMaterialDelivery: false
            })
        },
        updateDetailPlane(detailToken, planeName) {
            return dispatch(updateDetailPartnumberPlaneName(detailToken, planeName));
        },
        deleteDetailPlaneName: (detailToken, planeName) => {
            return dispatch(deleteDetailPlaneName(detailToken, planeName))
        },
        addSupplierToMaterialDeliveryItem: (item, supplier) => {
            return dispatch(addSupplierToMaterialDeliveryItem(item, supplier))
        },
        removeSupplierFromMaterialDeliveryItem: (materialPartNumber, supplierToken) => {
            return dispatch(removeSupplierFromMaterialDeliveryItem(materialPartNumber, supplierToken))
        },
        setMaterialDeliveryItemSelected: (materialItemToken) => {
            dispatch({ type: SET_MATERIAL_ITEM_SELECTED, materialItemToken })
        },
        onAddSampleRequestSupplier: (materialPartNumber, quote) => {
            return dispatch(onAddSampleRequestSupplier(materialPartNumber, quote));
        },
        onRemoveSampleRequestSupplier: (materialPartNumber, supplierToken) => {
            return dispatch(onRemoveSampleRequestSupplier(materialPartNumber, supplierToken))
        },
        onAddRMISampleRequestSupplier:(item, quote, rmi) =>{
            return dispatch(onAddRMISampleRequestSupplier(item, quote, rmi))
        },
        sendQuotesRequestFromMaterialDelivery: () => {
            return dispatch(sendQuotesRequestFromMaterialDelivery())
        },
        onAddComment: (item, comments) => {
            return (dispatch(onAddComment(item, comments)))
        },
        onAddFiles: (files, tokenPartNumber) => {
            return (dispatch(onAddFiles(files, tokenPartNumber)))
        },
        onRemoveFiles: (fileName, tokenPartNumber) => {
            return (dispatch(onRemoveFiles(fileName, tokenPartNumber)))
        },
    }
}

const styles = theme => ({
    toolbar: {
        display: "flex"
    },
    actionsToolbar: {
        display: "flex"
    },
    searchInput: {
        marginTop: "5px"
    },
    button: {
        margin: theme.spacing.unit,
    },
    showingUsersCounter: {
        color: theme.palette.secondary.main
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    supplierBadge: {
        padding: `0 ${theme.spacing.unit * 2}px`,
    }
});


export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create))));