import React, { Component } from 'react';
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Translate } from "react-localize-redux";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import SampleIcon from '@material-ui/icons/LocalShipping';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Grid from '@material-ui/core/Grid';

import Tooltip from '@material-ui/core/Tooltip';

import PlotSelector from '../../common/PlotSelector'
import SuppliersSelector from '../../common/SupplierSelector'
import Evidence from '../../common/Evidence';
import { TextField, Paper } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';

/**
 * Material item lateral options panels
 *
 * @class MaterialItemDialog
 * @extends {Component}
 */
class MaterialItemDialog extends Component {

    /**
     *Creates an instance of MaterialItemDialog.
     * @param {*} props
     * @memberof MaterialItemDialog
     */
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            comments: this.props.item ? this.props.item.attachments : []
        }
    }

    /**
     * React life-cycle method
     * Update status if props has change
     *
     * @param {*} prevProps
     * @param {*} prevState
     * @memberof NoticeItemDialog
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.item !== prevProps.item) {
            this.setState({
                comments: this.props.item.comments,
                attachments: this.props.item.attachments,
                isRmi: this.props.item.is_rmi_requiered,
            })
        }
    }

    /**
     * On remove supplier
     *
     * @memberof MaterialItemDialog
     */
    onRemoveSupplierClick(item, quote) {
        if (this.props.onRemoveSupplier) {
            this.props.onRemoveSupplier(item, quote.supplier_token)
        }
    }

    /**
     * On add sample request to supplier
     */
    onAddSampleRequestSupplierClick(item, quote) {
        if (this.props.onAddSampleRequestSupplier) {
            this.props.onAddSampleRequestSupplier(item, quote)
        }
    }

    /**
     * On remove sample request to supplier
     */
    onRemoveSampleRequestSupplierClick(item, quote) {
        if (this.props.onRemoveSampleRequestSupplier) {
            this.props.onRemoveSampleRequestSupplier(item, quote.supplier_token)
        }
    }

    /**
     * Handle event to upload files
     * @param {*} files 
     */
    handleUploadFiles(files) {
        if (this.props.uploadFiles)
            return this.props.uploadFiles(files, this.props.item.token)
                .then(() => {
                    return Promise.resolve('Ok')
                })
                .catch(() => {
                    return Promise.reject()
                })
    }

    /**
     * Handle event to remove files
     * @param {*} fileName 
     */
    handleRemoveFiles(fileName) {
        if (this.props.removeFiles)
            return this.props.removeFiles(fileName, this.props.item.token)
                .then(() => {
                    return Promise.resolve('Ok')
                })
                .catch(() => {
                    return Promise.reject()
                })
    }

    /**
     * Update property in state
     *
     * @param {*} property
     * @param {*} value
     * @memberof MaterialItemDialog
     */
    updateProperty(property, value) {
        this.setState({
            [property]: value
        })
    }

    /**
     * on Add comment to item
     *
     * @param {*} item
     * @memberof MaterialItemDialog
     */
    onAddComment(item) {
        if (this.props.onAddComment)
            this.props.onAddComment(item, this.state.comments);
    }

    /**
     * onRequieredRMI
     * @param {*} item 
     * @param {*} quote 
     * @param {*} value 
     */
    onRequieredRMI(item, quote, value) {
        if (this.props.onAddRMISampleRequestSupplier) {
            this.props.onAddRMISampleRequestSupplier(item, quote, value)
        }
    }

    /**
     *
     *
     * @memberof MaterialItemDialog
     */
    getSelectedSuppliers() {
        const { classes, item } = this.props
        return item.quotes.map((quote) => {
            return (
                <ListItem key={quote.quote_token} dense divider>
                    <ListItemText primary={<React.Fragment>{quote.supplier_code} | {quote.supplier_name} </React.Fragment>} />
                    <ListItemSecondaryAction>
                        <React.Fragment>
                            {
                                quote.is_sample_requiered === true &&
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        control={<Checkbox checked={quote.is_rmi_requiered}
                                            onChange={(event) => { this.onRequieredRMI(item, quote, event.target.checked) }} />}
                                        label={"RMI"}
                                    />
                                </FormControl>
                            }
                            {
                                quote.is_sample_requiered === true ?
                                    <Tooltip title={<Translate id="memos.removeRequestSampleTooltipMessage" />}>
                                        <IconButton color="primary"
                                            onClick={() => {
                                                this.onRemoveSampleRequestSupplierClick(
                                                    item, quote
                                                )
                                            }}
                                        >
                                            <SampleIcon />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title={<Translate id="memos.requestSampleTooltipMessage" />}>
                                        <IconButton
                                            onClick={() => {
                                                this.onAddSampleRequestSupplierClick(
                                                    item, quote
                                                )
                                            }}
                                        >
                                            <SampleIcon />
                                        </IconButton>
                                    </Tooltip>
                            }
                            <Tooltip title={<Translate id="memos.removeSupplierRequestTooltipMessage" />}>
                                <IconButton
                                    className={classes.deleteButton}
                                    onClick={() => {
                                        this.onRemoveSupplierClick(
                                            item, quote
                                        )
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    </ListItemSecondaryAction>
                </ListItem>
            )
        })
    }

    /**
     *
     *
     * @returns
     * @memberof MaterialItemDialog
     */
    render() {
        const { item, classes } = this.props;
        return (
            <Dialog
                open={this.props.show}
            >
                <DialogTitle id="form-dialog-title">
                    <Translate id="deliveryOrderModule.materialsTable.plot" />: {item.part_number} Rev. {item.review}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={0}>
                        <Grid item xs={12} className={classes.gridItem}>
                            <PlotSelector
                                specPlano={item.plano_number}
                                revision={item.review}
                                selectedPlotName={item.plot_name}
                                formatype="Memos"                                
                                onPlotSelected={(plotSelectd) => { this.props.onPlotSelected(this.props.item, plotSelectd) }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.gridItem}>
                            <Typography variant="caption"><Translate id="plotSelector.addComments" /></Typography>
                            <TextField
                                key={'comments'}
                                fullWidth
                                variant='outlined'
                                multiline
                                rowsMax='3'
                                value={this.state['comments'] ? this.state['comments'] : ''}
                                onChange={(event) => { this.updateProperty('comments', event.target.value) }}
                                onBlur={() => this.onAddComment(this.props.item)}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.gridItem}>
                            <Typography variant="caption" gutterBottom>
                                <Translate id="memos.addSupplierToMemoMessage" />
                            </Typography>
                            <SuppliersSelector
                                onSupplierSelected={(supplier) => { this.props.onSupplierSelected(this.props.item, supplier) }}
                            />
                            <Paper style={{ maxHeight: 110, minHeight: 0, overflowY: 'auto', marginTop: 5}}>
                                <List dense>
                                    {this.getSelectedSuppliers()}
                                </List>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} className={classes.gridItem}>
                            <Typography variant="caption"><Translate id="common.attachments" /></Typography>
                            <Evidence
                                text={this.props.dropzoneText}
                                baseApi={process.env.REACT_APP_SIP_URL_FILES}
                                removeFiles={this.handleRemoveFiles}
                                uploadFiles={this.handleUploadFiles}
                                files={this.props.item.attachments}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        <Translate id="common.close" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = theme => ({
    deleteButton: {
        color: "red"
    },
    gridItem:{
        marginBottom: 15,
    }
})

export default withStyles(styles, { withTheme: true })(MaterialItemDialog);