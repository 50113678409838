/** Material UI import section */
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

/**
 * Custom switch
 */
const StatusSwitch = withStyles((theme) => ({
	switchBase: {
		color: theme.palette.primary.light,
		'&$checked': {
			color: theme.palette.primary.main
		}
	},
	thumb: {
		width: 12,
		height: 12,
		boxShadow: 'none'
	},
	checked: {
	}
}))(Switch);

export default StatusSwitch;
