import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'

/** Import component section */
import DataTable from '../../common/DataTable'
import { backgroundColorSelect } from '../../../store/helpers/ProspectHelper'

/**
 * Container show prospect
 */
class ProspectStatusHistory extends Component {
	/**
	 * Create an instance prospect
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAcepted: false,
			openRejected: false,
		}
	}

	onRenderCellItem(dataSource, item) {
		if (dataSource === 'statusDescription') {
			return (
				<Chip
					style={{ backgroundColor: backgroundColorSelect(item.status) }}
					key={item.statusDescription}
					label={item.statusDescription}
				/>
			)
		}
	}
	render() {
		const { prospect } = this.props
		return (
			<Grid item xs={12}>
				<DataTable
					data={prospect}
					configuration={TableFilesConfiguration()}
					onRenderCellItem={this.onRenderCellItem}
				/>
			</Grid>
		)
	}
}

function TableFilesConfiguration() {
	let columns = [
		{
			header: <Translate id='prospects.status' />,
			dataSource: 'statusDescription',
		},
		{
			header: <Translate id='biddingsModule.table.creationDate' />,
			dataSource: 'creationDate',
		},
		{
			header: <Translate id='prospects.modifiedBy' />,
			dataSource: 'userBy',
		},
		{
			header: <Translate id='common.comments' />,
			dataSource: 'prospectComment',
		},
	]

	return { columns }
}

export default ProspectStatusHistory
