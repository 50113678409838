import React from 'react';
import PropTypes from 'prop-types';

/**Import material UI Section*/
import { Translate } from 'react-localize-redux';
import Root from '../../../../store/Root';

/**Import components section*/
import HeaderResultPdf from './HeaderResultPdf'
import PanelRightPdf from './PanelRightPdf'
import RecommendationsPdf from './RecommendationsPdf'
import { BACKGROUND_COLOR_GRAY_BIDDING } from '../../../../store/helpers/StatusColorConstants';
import { Document, Page, View, StyleSheet, Text } from '@react-pdf/renderer';
import { withLocalize } from 'react-localize-redux';


const PdfDocument = props => {
    const { topics, totalRatingSupplier,sustainability, totalWorkDetail } = props

    return (
    <Root>
        <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.tittle}>
            <Text>{<Translate id='sustainability.documentReportTittle'/>}</Text>
            <Text>{`${sustainability.SupplierCode} | ${sustainability.SupplierName}`}</Text>
        </View>
        <View style={styles.container}>
        <View style={styles.panelLeft}>
            <PanelRightPdf
                totalRatingSupplier={totalRatingSupplier}
                totalWorkDetail={totalWorkDetail}
                />
        </View>
        <View style={styles.panelRight}>
            <View>
                <HeaderResultPdf
                    topics = {topics}/>
            </View>
            <View>
                <RecommendationsPdf
                    topics = {topics}/>
            </View>
        </View>
        </View>
      </Page>
    </Document> 
    </Root>
    );
};

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    container: {
        display: "flex",
        flexDirection: 'row',
    },
    panelLeft: {
        backgroundColor: BACKGROUND_COLOR_GRAY_BIDDING,
        flex: 1,
		flexShrink: 1,
		flexBasis: 0,
        margin: '5px',
        height: '80%'
    },
    panelRight: {
        height: 'auto',
        flexGrow: 3,
		flexShrink: 3,
		flexBasis: 0,
        display: 'flex',
        flexDirection: 'column',
        margin: '10px',
    },
    tittle: {
        textAlign: 'center',
        fontSize: '13',
        fontWeight: 'bold',
        marginTop: '3px',
        marginBottom: '5px',
    }
  });

PdfDocument.propTypes = {
    topics:PropTypes.array  
};

PdfDocument.defaultProps = {
    topics: [],
    sustainability: {}
};
export default withLocalize(PdfDocument);