import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'

/** Material-UI import section*/
import { Avatar, Card, CardContent, CardHeader, Fab, Typography, Tooltip, withStyles } from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'
import AddIcon from '@material-ui/icons/AddTwoTone'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'

/** Redux import section*/
import { useDispatch, useSelector } from 'react-redux'
import { loadVehicles, deleteVehicle } from '../../store/controlCO2/controlCO2Action'
import { getVehiclesSelector } from '../../store/controlCO2/controlCO2Selectors'

/** Custom components import section*/
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import VehicleAddingPanel from './components/VehicleAddingPanel'
import VehicleEditingPanel from './components/VehicleEditingPanel'
import AddCommonItem from '../common/AddCommonItem'

/** Resources import section */
import { vehiclesTableConfiguration } from './helpers/Vehicles.helper'

const VehiclesIndex = (props) => {
	const { classes } = props
	/** Defeines local state */
	const [loading, setLoading] = useState(true)
	const [addingVehiclePanelConfig, setAddingVehiclePanelConfig] = useState({ opened: false })
	const [editingVehiclePanelConfig, setEditingVehiclePanelConfig] = useState({ opened: false, vehicle: null })
	const [deletingVehiclePanelConfig, setDeletingVehiclePanelConfig] = useState({
		opened: false,
		deleting: false,
		vehicleToken: null,
	})

	/** Connect with redux */
	const dispatch = useDispatch()
	const allVehicles = useSelector((state) => getVehiclesSelector(state))

	/** Load vehicles data */
	useEffect(() => {
		setLoading(true)
		dispatch(loadVehicles()).finally(() => {
			setLoading(false)
		})
	}, [dispatch])

	/**
	 * Show the lateral panel to add new vehicle.
	 */
	const openVehicleAddingPanel = () => {
		setAddingVehiclePanelConfig({ opened: true })
	}

	/**
	 * Hide the lateral panel to add new vehicle.
	 */
	const closeVehicleAddingPanel = () => {
		setAddingVehiclePanelConfig({ opened: false })
	}

	/**
	 * Show the lateral panel to edit a vehicle.
	 */
	const openVehicleEditingPanel = (vehicle) => {
		setEditingVehiclePanelConfig({ opened: true, vehicle })
	}

	/**
	 * Hide the lateral panel to edit a vehicle.
	 */
	const closeVehicleEditingPanel = () => {
		setEditingVehiclePanelConfig({ opened: false, vehicle: null })
	}

	/**
	 * Show the confimation modal to add new vehicle.
	 */
	const openVehicleDeleteConfirmationModal = (event) => {
		const { id } = event.currentTarget
		setDeletingVehiclePanelConfig((prevStatus) => ({ ...prevStatus, opened: true, vehicleToken: id }))
	}

	const handleDeleteVehicleConfirmation = () => {
		setDeletingVehiclePanelConfig((prevStatus) => ({ ...prevStatus, deleting: true }))
		dispatch(deleteVehicle(deletingVehiclePanelConfig.vehicleToken)).finally(() => {
			setDeletingVehiclePanelConfig({
				opened: false,
				deleting: false,
				vehicleToken: null,
			})
		})
	}

	const onRenderCellItem = (columnCode, item) => {
		switch (columnCode) {
			case 'options':
				return (
					<Tooltip title={<Translate id='controlCO2.vehicles.deleteVehicle' />}>
						<DeleteOutlinedIcon
							id={item.token}
							onClick={openVehicleDeleteConfirmationModal}
							color='error'
						/>
					</Tooltip>
				)
			default:
				return null
		}
	}

	return (
		<>
			<Card>
				<CardHeader
					className={classes.header}
					avatar={
						<Avatar
							style={{
								height: 35,
								width: 35,
							}}
						>
							<BuildIcon />
						</Avatar>
					}
					title={
						<Typography>
							<Translate id='controlCO2.tabs.vehicles' />
						</Typography>
					}
					action={
						<div className={classes.actionsContainer}>
							<Fab
								size='small'
								color='primary'
								style={{
									height: 35,
									width: 35,
								}}
								onClick={openVehicleAddingPanel}
							>
								<AddIcon />
							</Fab>
						</div>
					}
				/>
				<CardContent>
					{
						{
							true: <LoadingPage />,
							false: (
								<DataTable
									data={allVehicles}
									configuration={vehiclesTableConfiguration}
									onRenderCellItem={onRenderCellItem}
									// onChangeSortCriteria={onChangeSortCriteria}
									onRowClick={openVehicleEditingPanel}
									// isIndex
								/>
							),
						}[loading]
					}
				</CardContent>
			</Card>
			<VehicleAddingPanel {...addingVehiclePanelConfig} onClose={closeVehicleAddingPanel} />
			<VehicleEditingPanel {...editingVehiclePanelConfig} onClose={closeVehicleEditingPanel} />
			{/** Dialog to remove destination */}
			<AddCommonItem
				title={<Translate id='controlCO2.vehicles.deleteVehicle' />}
				message={<Translate id='controlCO2.vehicles.deleteVehicleMessageConfirmation' />}
				open={deletingVehiclePanelConfig.opened}
				onClose={() => {
					setDeletingVehiclePanelConfig({ opened: false, vehicleToken: null })
				}}
				onSaveItem={handleDeleteVehicleConfirmation}
				isSaving={deletingVehiclePanelConfig.deleting}
				isMessage={true}
			/>
		</>
	)
}

const styles = (theme) => ({
	header: {
		padding: theme.spacing.unit,
	},
	actionsContainer: {
		display: 'flex',
		marginTop: theme.spacing.unit,
	},
})

export default withStyles(styles, { withTheme: true })(VehiclesIndex)
