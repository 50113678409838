import {fromJS } from 'immutable';

import * as reportGuaranteeActions from './guaranteeReportAction';

/**
 * Companies initial state
 */
export const guaranteeReportInitialState = fromJS({
    isLoadingReportGuarantee: true,
    sortCriteria: {
        by: "creation_date",
        ascending: false
    },
    searchQuery: "",
    page: 0,
    rowsPerPage: 20,
    guaranteeReportCount: null,
    guaranteeReportList: [],
    guaranteeReport: null,
    isSaving: false,
    isLoadingGuarantee: false,
})

export default function companiesReducer(state = guaranteeReportInitialState, action) {
    switch (action.type) {
        case reportGuaranteeActions.START_LOADING_CUSTOMER_GUARANTEE: {
            return state.merge({
                isLoadingReportGuarantee: action.isLoadingCustomer
            })
        }
        case reportGuaranteeActions.START_LOADING_FAILURE_GUARANTEE: {
            return state.merge({
                isLoadingReportGuarantee: action.isLoadingFailure
            })
        }
        case reportGuaranteeActions.SET_GUARANTEE_REPORT_TO_LIST:{
            let guaranteeReportLists = fromJS(action.guaranteeReportList)
            return state.merge({
                guaranteeReportList: guaranteeReportLists,
                isLoadingReportGuarantee: false
            })
        }
        case reportGuaranteeActions.SET_GUARANTEE_REPORT_COUNT:{
            return state.merge({
                guaranteeReportCount: action.count
            })
        }
        case reportGuaranteeActions.CHANGE_GUARANTEE_REPORT_SORT_QUERY:{
            return state.merge({
                searchQuery: action.query
            })
        }
        case reportGuaranteeActions.CHANGE_GUARANTEE_REPORT_SORT_CRITERIA: {
            return state.merge({
                sortCriteria: action.sortCriteria
            })
        }
        case reportGuaranteeActions.CHANGE_GUARANTEE_PAGINATION: {
            return state.merge({
                page: action.page,
                rowsPerPage: action.rowsPerPage
            })
        }
        case reportGuaranteeActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving
            })
        }
        case reportGuaranteeActions.SET_GUARANTEE_REPORT:{
            return state.merge({
                isLoadingGuarantee: false,
                isSaving: false,
                guaranteeReport: fromJS(action.guaranteeReport)
            })
        }
        case reportGuaranteeActions.IS_LOADING: {
            return state.merge({
                isLoadingGuarantee: action.isLoading
            })
        }
        case reportGuaranteeActions.ADD_FILE_GUARRANTEE_REPORT: {
            let oldList = state.getIn(['guaranteeReport', 'attachments']).toJS();
            let filesToAdd = action.files.filter(newFile => {
                return !oldList.find(uploadedFile => {
                    return uploadedFile.name === newFile.name
                })
            })
            let newList = [...oldList, ...(filesToAdd)];
            return state.setIn(['guaranteeReport', 'attachments'], fromJS(newList));
        }
        case reportGuaranteeActions.REMOVE_FILE_GUARRANTEE_REPORT: {
            let oldList = state.getIn(['guaranteeReport', 'attachments']).toJS();
            let newList = oldList.filter(file => {
                return file.name !== action.fileName
            })
            return state.setIn(['guaranteeReport', 'attachments'], fromJS(newList));
        }
        default:
            return state;
    }
}