

/**
 * The method get details materials from order maquila 
 * @param {*} bidding 
 */
export const getProductsMaterials = (ordenMaquila, material) => {
    let items = [];
    if (ordenMaquila === null || ordenMaquila === undefined)
        return items;
    if (material === null || material === undefined)
        return items;
    if (ordenMaquila.detail_machineds.length === 0)
        return items;
    else {
        ordenMaquila.detail_machineds.forEach(detailMachined => {
            detailMachined.items.forEach(item => {
                items.push({
                    ...item,
                    value: item.code,
                    label: `${item.code} | Posición. ${item.position ? item.position.replace(/^(0+)/g, '') : "-"} | ${item.description} `,
                    position: item.position,
                    code: item.code,
                })
            })
        });
    }
    return items;
}

/**
 * The method get details materials from order maquila 
 * @param {*} bidding 
 */
 export const getConsumsMaterials = (ordenMaquila, product) => {
    let items = [];
    if (product === null || product === undefined)
        return items;
    if (ordenMaquila === null || ordenMaquila === undefined)
        return items;
    if (ordenMaquila.detail_materials.length === 0)
        return items;
    else {
        ordenMaquila.detail_materials.forEach(item => {
             if(item.detail_machined_material_code === product.code && item.detail_machined_material_position === product.position) {
                var existMaterial = items.find(d=> d.value === item.component);
                if(existMaterial === undefined){
                    items.push({
                        value: item.component,
                        label: item.measurement_unit ? `${item.component} | ${item.description} | ${item.measurement_unit}` : `${item.component} | ${item.description}`,
                        name: item.description,
                        quantity: item.required_quantity,
                        detail_machined_material_code: item.detail_machined_material_code,
                        detail_machined_material_position: item.detail_machined_material_position,
                        measurement_unit: item.measurement_unit
                    })
                }
            }
        });
    }
    return items;
}
